export const CREATE_IMAGE_API_HASH = "CREATE_IMAGE_API_HASH";
export const CREATE_IMAGE_API_HASH_RESPONSE = "CREATE_IMAGE_API_HASH_RESPONSE";
export const GET_IMAGE_API_HASH = "GET_IMAGE_API_HASH";
export const GET_IMAGE_API_HASH_RESPONSE = "GET_IMAGE_API_HASH_RESPONSE";
export const DELETE_IMAGE_API_HASH = "DELETE_IMAGE_API_HASH";
export const REQUEST_MEDIA_STUDIO = "REQUEST_MEDIA_STUDIO";
export const REQUEST_MEDIA_STUDIO_STATUS = "REQUEST_MEDIA_STUDIO_STATUS";
export const GET_MEDIA_STUDIO_FOR_AD = "GET_MEDIA_STUDIO_FOR_AD";
export const SET_MEDIA_STUDIO_REQUEST_STATUS =
  "SET_MEDIA_STUDIO_REQUEST_STATUS";
export const GET_MEDIA_STUDIO_FOR_AD_RESPONSE =
  "GET_MEDIA_STUDIO_FOR_AD_RESPONSE";

export const POST_MEDIA_STUDIO_PROCESSING = "POST_MEDIA_STUDIO_PROCESSING";

export const GET_MEDIA_STUDIO_PROCESSING = "GET_MEDIA_STUDIO_PROCESSING";
export const GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE =
  "GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE";
export const MEDIA_STUDIO_IMAGE_PROCESSING_SUCCESS =
  "MEDIA_STUDIO_IMAGE_PROCESSING_SUCCESS";

export const GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO =
  "GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO";
export const MEDIA_STUDIO_IMAGE_PROCESSING_LOADER =
  "MEDIA_STUDIO_IMAGE_PROCESSING_LOADER";
export const MEDIA_STUDIO_VIDEO_PROCESSING_LOADER =
  "MEDIA_STUDIO_VIDEO_PROCESSING_LOADER";

export const MEDIA_STUDIO_VIDEO_PROCESSING_FAILURE =
  "MEDIA_STUDIO_VIDEO_PROCESSING_FAILURE";
export const MEDIA_STUDIO_IMAGE_PROCESSING_FAILURE =
  "MEDIA_STUDIO_IMAGE_PROCESSING_FAILURE";
export const IMAGE_PREVIEW_URL = "IMAGE_PREVIEW_URL";
export const POST_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE =
  "POST_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE";
export const POST_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO =
  "POST_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO";

export const SET_IMAGE_VGS_TIMER = "SET_IMAGE_VGS_TIMER";
export const SET_VIDEO_VGS_TIMER = "SET_VIDEO_VGS_TIMER";

export const SET_FRAME_THUMBNAIL_DATA = "SET_FRAME_THUMBNAIL_DATA";
export const SET_FRAME_VGS_DATA = "SET_FRAME_VGS_DATA";

export const SET_FRAME_THUMBNAIL_VGS_DATA_REDUX =
  "SET_FRAME_THUMBNAIL_VGS_DATA_REDUX";

export const GET_FRAME_VGS_REQUEST_ID_LOADER =
  "GET_FRAME_VGS_REQUEST_ID_LOADER";
export const GET_FRAME_VGS_REQUEST_ID_FAILURE =
  "GET_FRAME_VGS_REQUEST_ID_FAILURE";

export const GET_FRAME_VGS_REQUEST_ID_REDUX = "GET_FRAME_VGS_REQUEST_ID_REDUX";

export const MEDIA_STUDIO_BUNDLE_PROCESSIONG_LOADING =
  "MEDIA_STUDIO_BUNDLE_PROCESSIONG_LOADING";
export const MEDIA_STUDIO_BUNDLE_PROCESSIONG_FAILURE =
  "MEDIA_STUDIO_BUNDLE_PROCESSIONG_FAILURE";
export const GET_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE =
  "GET_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE";
export const POST_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE =
  "POST_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE";
export const GET_FACEBOOK_IMAGE_URL = "GET_FACEBOOK_IMAGE_URL";
export const GET_FACEBOOK_VIDEO_URL = "GET_FACEBOOK_VIDEO_URL";
export const RESTORE_INITIAL_MEDIA = "RESTORE_INITIAL_MEDIA";
export const GET_FB_IMAGE_URL_ASSET_FEED_SPEC =
  "GET_FB_IMAGE_URL_ASSET_FEED_SPEC";

// media studio
export type GetMediaStudioProcessing = {
  type: typeof GET_MEDIA_STUDIO_PROCESSING;
  payload: GetMediaStudioProcessingPayloadType;
  callback: any;
};

export type SetFrameThumbnailVGSData = {
  type: typeof SET_FRAME_THUMBNAIL_DATA;
  payload: FrameThumbnailVGSPayloadType;
};

export interface FrameThumbnailVGSPayloadType {
  index: number;
  data: FrameThumbnailsItemType;
}

export interface GetMediaStudioProcessingPayloadType {
  reqId: string[];
  reduxActionType?:
    | "MEDIA_STUDIO_IMAGE_PROCESSING_LOADER"
    | "MEDIA_STUDIO_VIDEO_PROCESSING_LOADER"
    | "GET_FRAME_VGS_REQUEST_ID_LOADER"
    | "MEDIA_STUDIO_BUNDLE_PROCESSIONG_LOADING";
  failureReduxType?:
    | "MEDIA_STUDIO_VIDEO_PROCESSING_FAILURE"
    | "MEDIA_STUDIO_IMAGE_PROCESSING_FAILURE"
    | "GET_FRAME_VGS_REQUEST_ID_FAILURE"
    | "MEDIA_STUDIO_BUNDLE_PROCESSIONG_FAILURE";
  storeReduxType?:
    | "GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE"
    | "GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO"
    | "SET_FRAME_THUMBNAIL_VGS_DATA_REDUX"
    | "GET_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE";
  timerRedux?: "SET_IMAGE_VGS_TIMER" | "SET_VIDEO_VGS_TIMER";
  type?: string;
}

export type GetMediaStudioProcessingReduxImage = {
  type: typeof GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE;
  payload: GetMediaStudioProcessingReduxType[];
};

export type GetMediaStudioProcessingReduxVideo = {
  type: typeof GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO;
  payload: GetMediaStudioProcessingReduxType[];
};

export type GetMediaStudioProcessingReduxBundle = {
  type: typeof GET_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE;
  payload: GetMediaStudioProcessingReduxType[];
};

export interface GetMediaStudioProcessingReduxType {
  id: string;
  status?: string;
  url: string;
}

export type MediaStudioVideoProcessingFailure = {
  type: typeof MEDIA_STUDIO_VIDEO_PROCESSING_FAILURE;
  payload: boolean;
};
export type MediaStudioImageProcessingFailure = {
  type: typeof MEDIA_STUDIO_IMAGE_PROCESSING_FAILURE;
  payload: boolean;
};

export type MediaStudioBundleProcessingFailure = {
  type: typeof MEDIA_STUDIO_BUNDLE_PROCESSIONG_FAILURE;
  payload: boolean;
};

export type MediaStudioImageProcessingLoader = {
  type: typeof MEDIA_STUDIO_IMAGE_PROCESSING_LOADER;
  payload: boolean;
};

export type MediaStudioVideoProcessingLoader = {
  type: typeof MEDIA_STUDIO_VIDEO_PROCESSING_LOADER;
  payload: boolean;
};

export type MediaStudioBundleProcessingLoader = {
  type: typeof MEDIA_STUDIO_BUNDLE_PROCESSIONG_LOADING;
  payload: boolean;
};

export type PostMediaStudioProcessing = {
  type: typeof POST_MEDIA_STUDIO_PROCESSING;
  payload: MediaStudioProcessingPostPayloadType;
};

export type PostMediaStudioProcessingReduxImage = {
  type: typeof POST_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE;
  payload: string[];
};
export type PostMediaStudioProcessingReduxVideo = {
  type: typeof POST_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO;
  payload: string[];
};
export type PostMediaStudioProcessingReduxBundle = {
  type: typeof POST_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE;
  payload: string[];
};

export type GetFacebookImageUrl = {
  type: typeof GET_FACEBOOK_IMAGE_URL;
  payload: any;
  callback?: any;
};

export type GetFacebookVideoUrl = {
  type: typeof GET_FACEBOOK_VIDEO_URL;
  payload: any;
  callback?: any;
};

export type GetFacebookImageUrlForAssetFeedSpec = {
  type: typeof GET_FB_IMAGE_URL_ASSET_FEED_SPEC;
  payload: GetFacebookImageUrlForAssetFeedSpecPayload;
};
export interface GetFacebookImageUrlPayloadType {
  adId: string;
  callback?: any;
}
export interface GetFacebookVideoUrlPayloadType {
  adId: string;
  callback?: any;
}

export interface GetFacebookImageUrlForAssetFeedSpecPayload {
  adAccountId: string;
  imageHash: string;
  callback?: any;
}

export interface MediaStudioProcessingPostPayloadType {
  adId: string;
  variations: MediaStudioProcessingPostType[];
  reduxActionType:
    | "MEDIA_STUDIO_IMAGE_PROCESSING_LOADER"
    | "MEDIA_STUDIO_VIDEO_PROCESSING_LOADER"
    | "GET_FRAME_VGS_REQUEST_ID_LOADER"
    | "MEDIA_STUDIO_BUNDLE_PROCESSIONG_LOADING";
  failureReduxType:
    | "MEDIA_STUDIO_VIDEO_PROCESSING_FAILURE"
    | "MEDIA_STUDIO_IMAGE_PROCESSING_FAILURE"
    | "GET_FRAME_VGS_REQUEST_ID_FAILURE"
    | "MEDIA_STUDIO_BUNDLE_PROCESSIONG_FAILURE";
  reduxStoreType:
    | "POST_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE"
    | "POST_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO"
    | "POST_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE"
    | "GET_FRAME_VGS_REQUEST_ID_REDUX";
  finalAction?: any;
  frameIndex?: number; // needed for video frame image polling
}
export interface MediaStudioProcessingPostType {
  variation_type: string;
  layer_name: string;
  text: string;
}
export type SetImageVGSTimer = {
  type: typeof SET_IMAGE_VGS_TIMER;
  payload: any;
};

///
//actions types
export type CreateImageAPIHash = {
  type: typeof CREATE_IMAGE_API_HASH;
  payload: CreateImageHashPayloadType;
};

export type CreateImageAPIHashResponse = {
  type: typeof CREATE_IMAGE_API_HASH_RESPONSE;
  payload: string;
};
export type GetImageAPIHash = {
  type: typeof GET_IMAGE_API_HASH;
  payload: string[];
};

export type GetImageAPIHashResponse = {
  type: typeof GET_IMAGE_API_HASH_RESPONSE;
  payload: string;
};

export type DeleteImageAPIHash = {
  type: typeof DELETE_IMAGE_API_HASH;
  payload: string[];
};

export type RequestMediaStudio = {
  type: typeof REQUEST_MEDIA_STUDIO;
  payload: RequestMediaStudioPayloadType;
};

export type RequestMediaStudioStatus = {
  type: typeof REQUEST_MEDIA_STUDIO_STATUS;
  payload: RequestMediaStudioPayload;
};

export type GetMediaStudioForAd = {
  type: typeof GET_MEDIA_STUDIO_FOR_AD;
  payload: RequestMediaStudioPayload;
};

export type GetMediaStudioForAdResponse = {
  type: typeof GET_MEDIA_STUDIO_FOR_AD_RESPONSE;
  payload: {
    data?: GETMediaStudioResponseType;
    isData: boolean;
  };
};

export type SetMediaStudioRequestStatus = {
  type: typeof SET_MEDIA_STUDIO_REQUEST_STATUS;
  payload: boolean;
};
export type SetImagePreviewUrl = {
  type: typeof IMAGE_PREVIEW_URL;
  payload: string;
};
// payload types
export interface CreateImageHashPayloadType {
  adId: string;
  text: string;
}
export interface RequestMediaStudioPayloadType {
  adId: string;
  brandId: string;
  payload: {
    brandName: string;
    aiGroupId: string;
    aiGroupName: string;
    adAccountId: string;
    adAccountName: string;
    creativeType: string;
  };
}

export interface RequestMediaStudioPayload {
  adId: string;
  brandId: string;
  finalAction?: any;
}

export interface RequestMediaStudioStatusPayloadType {
  adId: string;
  brandId: string;
}

// state types

export interface InitialMediaStudioType {
  reqImageIds: string[];
  reqVideoIds: string[];
  reqBundleIds: string[];
  imageHash: string[];
  getMediaStudioData?: {
    data?: GETMediaStudioResponseType;
    isData: boolean;
    isLoading?: boolean;
  };
  mediaStudioRequestStatus: boolean;
  isImageMediaStudioProcessing: boolean;
  isVideoMediaStudioProcessing: boolean;
  isVideoMediaStudioProcessingFailure: boolean;
  isImageMediaStudioProcessingFailure: boolean;
  imageVgsTimer: any;
  videoVgsTimer: any;
  imageContext?: GetMediaStudioProcessingReduxType;
  videoContext?: GetMediaStudioProcessingReduxType;
  imagePreviewUrl?: string;
  videoFrameRelatedData: VideoFrameObject;
  frameVGSReqId: string[];
  frameVGSReqIdLoading: boolean;
  frameVGSReqIdFailure: boolean;
  selectedFrameIndex?: number;
  videoVGSFrame: VideoVGSFrameObject;
  bundleContext?: GetMediaStudioProcessingReduxType[];
  isBundleMediaStudioProcessingFailure?: boolean;
  isImageProcessedSuccessfully?: boolean;
  isBundleMediaStudioProcessing?: boolean;
}

export interface VideoFrameObject {
  [key: number]: FrameThumbnailsItemType;
}

export interface VideoVGSFrameObject {
  [key: number]: FrameVGSItemType;
}
export interface GETMediaStudioResponseType {
  tag: string;
  usageType: "IMAGE_TEXT" | "VIDEO_TEXT";
  videoBlenderFile?: URLFileType;
  frames: [
    {
      previewFile: URLFileType;
      blenderFile: URLFileType;
      timeFrame: TimeFrameType;
      layers: [
        {
          name: string;
          maxCharacters: number;
          minCharacters: number;
          originalText: string;
        }
      ];
    }
  ];
}

export interface URLFileType {
  name: string;
  value: string;
}

export interface TimeFrameType {
  minutes: number;
  seconds: number;
}

export interface FrameThumbnailsItemType {
  thumbnailUrl?: string;
  isThumbnailUrlFailed?: boolean;
  isThumbnailLoading?: boolean;
}

export interface FrameVGSItemType {
  vgsPreviewUrl?: GetMediaStudioProcessingReduxType;
  isEditing?: boolean;
}
