import { StyledText } from "components/shared";
import { Box } from "@mui/material";
import { LightTooltip } from "components/shared/LightTooltip";
import { ReactComponent as InfoIcon } from "../../../../assets/svg/info.svg";
import { Fragment } from "react";

export default function CRTooltipWithAdDetails({
  withInfoIcon,
  component,
  width,
  data,
  assetColor,
}: {
  withInfoIcon?: boolean;
  component?: any;
  width?: string;
  data?: {
    adName: string;
    adId: string;
    adsetName: string;
    campaign: string;
    campaignId: string;
    adsetId: string;
  };
  assetColor?: string;
}) {
  return (
    <LightTooltip
      placement="right"
      arrow
      title={
        <Box
          display="flex"
          flexDirection="column"
          width={width ?? "16em"}
          padding=".25em"
        >
          {component ? (
            component
          ) : (
            <Fragment>
              <StyledText fontSize="0.75em" lineHeight="16px" fontWeight={400}>
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  Ad Name:
                </span>{" "}
                {data?.adName}
              </StyledText>
              <StyledText fontSize="0.625em" lineHeight="16px" color="#999999">
                Ad Id: {data?.adId}
              </StyledText>

              <StyledText fontSize="0.75em" lineHeight="16px" fontWeight={400}>
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  Adset Name:
                </span>{" "}
                {data?.adsetName}
              </StyledText>
              <StyledText fontSize="0.625em" lineHeight="16px" color="#999999">
                Adset Id:{data?.adsetId}
              </StyledText>

              <StyledText fontSize="0.75em" lineHeight="16px" fontWeight={400}>
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  Campaign:
                </span>{" "}
                {data?.campaign}
              </StyledText>
              <StyledText fontSize="0.625em" lineHeight="16px" color="#999999">
                Campaign Id:{data?.campaignId}
              </StyledText>
            </Fragment>
          )}
        </Box>
      }
    >
      {withInfoIcon ? (
        <InfoIcon
          style={{
            height: "1em",
            width: "1em",
          }}
        />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          pl=".75em"
          pr=".75em"
          gap='0.5rem'
          flex={1}
          sx={{
            overflow: "hidden",
          }}
        >
          <StyledText
            fontSize="0.75em"
            lineHeight="15px"
            color="#33343D"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Ad Name : {data?.adName}
          </StyledText>
          <StyledText
            fontSize="0.75em"
            lineHeight="15px"
            color="#33343D"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Ad ID : {data?.adId}
          </StyledText>
          {/* <StyledText
            fontSize="0.75em"
            lineHeight="15px"
            color="#33343D"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Adset Name:{" "}
            <span
              style={{
                color: assetColor,
              }}
            >
              {data?.adsetName}
            </span>
          </StyledText> */}
        </Box>
      )}
    </LightTooltip>
  );
}
