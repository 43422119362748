import React, { useState } from "react";
import { Box } from "@mui/material";
import SelectAccount from "./SelectAccount";
import SelectProperties from "./selectProperties";
import { useDispatch } from "react-redux";
import { linkAdAccountsToBrands } from "store/actions/thirdPartyChannels";
import ModalWrapper from "components/Modal/ModalWrapper";
import ModalContainer from "components/Modal/ModalContainer";
import useThirdPartyAccount from "../hooks";
const GoogleAnalytics: React.FC<{ platform: any; code: any }> = ({
  platform,
  code,
}) => {
  const dispatch=useDispatch()
  // const tokenUID:string=useSelector((state:any)=>state.thirdPartyChannels?.UIDTOKEN?.tokenId)
  const { platformId, tokenUID,state,modal,ErrorFooter } = useThirdPartyAccount();
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const onClickNext = (value: any) => {
    dispatch(
      linkAdAccountsToBrands(
        {
          params: {
            brandId: platform.brand_id,
            platformId: platformId[platform.platform],
            platform: platform.platform,
            parentPlatformId: platformId[platform.platform],
            accounts: [
              {
                accountId: value.accountId,
              },
            ],
          },
          tokenUID,
        },
        () => {
          setSelectedAccount(value);
        }
      )
    );
  };
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
      }}
    >
      {modal && (
        <ModalWrapper>
          {modal.key === 'error' && (
            <ModalContainer
              title='Error occured'
              width='469px'
              footerComponent={ErrorFooter}
            >
              <div className='flex-center'>
                <p className='m-0 confirm-text'>
                  Something went wrong. Please try agin after sometime.
                </p>
              </div>
            </ModalContainer>
          )}
        </ModalWrapper>
      )}
      {selectedAccount ? (
        <SelectProperties
          tokenUID={tokenUID}
          platformId={platformId}
          platform={platform}
          brandId={platform.brand_id}
          selectedAccount={selectedAccount}
          brandName={state.brandName}
        />
      ) : (
        <SelectAccount
          platform={platform}
          code={code}
          onClickNext={onClickNext}
          tokenUID={tokenUID}
          brandName={state.brandName}
        />
      )}
    </Box>
  );
};
export default GoogleAnalytics;
