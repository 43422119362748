import React from "react";
import {
  StyledAppBarBox,
  StyledAppBarBoxItem2,
  StyledAppBarBoxItem3,
  StyledTextBox,
  StyledTextBoxItem1,
} from "./styled";

import { ReactComponent as Warning } from "../../../assets/svg/warning.svg";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import { ReactComponent as CheckedGreen } from "../../../assets/svg/checked_green.svg";

import { LightTooltip } from "components/shared/LightTooltip";
import { StyledDivider, StyledText } from "components/shared";
import { FbReviewAppBarType } from "../ReviewLayout.types";

export default function AppBar({
  appBarData,
}: {
  appBarData: FbReviewAppBarType;
}) {
  const {
    adAccountName,
    adSetName,
    brandName,
    campaignName,
    noOfAction,
    noOfVariant,
    platformName,
    requestedAt,
    requestedBy,
  } = appBarData;
  return (
    <StyledAppBarBox>
      <StyledTextBox>
        <StyledTextBoxItem1 pb="4px">
          <StyledText
            fontFamily="Lato"
            fontSize="1.25em"
            fontWeight={700}
            lineHeight="24px"
            color="#222222"
          >
            Creative AI /
          </StyledText>
          <StyledText
            fontFamily="Lato"
            fontSize="1.25em"
            fontWeight={500}
            lineHeight="24px"
            color="#222222"
            pl=".1em"
          >
            Review Request /
          </StyledText>
          <StyledText
            fontFamily="Lato"
            fontSize="1em"
            fontWeight={500}
            lineHeight="19px"
            color="#222222"
            pl=".1em"
          >
            {brandName ? `${brandName} /` : ""}
          </StyledText>
          <StyledText
            fontFamily="Lato"
            fontSize="1em"
            fontWeight={400}
            lineHeight="19px"
            color="#222222"
            pl=".1em"
          >
            {platformName
              ? `${platformName === "FACEBOOK" ? "META" : platformName} /`
              : ""}
            {adAccountName ? `${adAccountName} /` : ""}
            {campaignName ? `${campaignName} /` : ""}
          </StyledText>
          <StyledText
            fontFamily="Lato"
            fontSize="1em"
            fontWeight={300}
            lineHeight="19px"
            color="#222222"
            pl=".1em"
            flex={1}
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {adSetName ? `${adSetName}` : ""}
          </StyledText>
        </StyledTextBoxItem1>
        <StyledText
          fontFamily="Lato"
          fontSize="0.875em"
          fontWeight={500}
          lineHeight="17px"
          color="#0869FB"
        >
          Requested by @{requestedBy} on {requestedAt}
        </StyledText>
      </StyledTextBox>
      <StyledAppBarBoxItem2>
        <StyledTextBoxItem1>
          <StyledText fontSize="1.375em" fontWeight={400} lineHeight="27px">
            {noOfVariant}
          </StyledText>
          <StyledText
            fontSize="1.125em"
            lineHeight="22px"
            color="#666666"
            fontWeight={400}
            pl="0.375em"
          >
            Ad Variant Review
          </StyledText>
        </StyledTextBoxItem1>
        <StyledDivider />
        <StyledAppBarBoxItem3>
          {noOfAction === 0 ? (
            <CheckedGreen
              style={{
                height: "1em",
                width: "1em",
              }}
            />
          ) : (
            <Warning
              style={{
                height: "1em",
                width: "1em",
              }}
            />
          )}
          <StyledText
            fontSize="0.875em"
            fontWeight={400}
            lineHeight="17px"
            color={noOfAction === 0 ? "#12C8B2" : "#EE7875"}
            p="0em .5em"
            sx={{
              textAlign: "center",
            }}
          >
            {noOfAction === 0
              ? "No Pending Actions"
              : `${noOfAction} Actions pending`}
          </StyledText>
          <LightTooltip
            arrow={true}
            placement="bottom-start"
            title="Each variant requires 1 actions to complete the review process"
          >
            <Info
              style={{
                height: "1em",
                width: "1em",
              }}
            />
          </LightTooltip>
        </StyledAppBarBoxItem3>
      </StyledAppBarBoxItem2>
    </StyledAppBarBox>
  );
}
