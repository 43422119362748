import { Box, IconButton } from "@mui/material";
import styled from "styled-components";

export const StyledAdVariantPreview = styled.div`
  position: relative;
  width: 100%;
`;
export const StyledAnimatorTagContainer = styled.div`
  height: 32px;
  width: 32px;
  position: absolute;
  top: -10px;
  left: -10px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #D9E8FF;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledAdVariantListCard = styled.div`
  width: 100%;
  min-width: 335px;
  min-height: 450px;
  height: 100%;
  padding-bottom: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .holder {
    background: red;
    padding: 20px;
    height: 20px;
  }
`;

export const StyledMainCreativeHead = styled.div`
  background: #f2f2f2;
  border: 1px solid #dadcde;
  border-radius: 10px 10px 0px 0px;
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0px;
  }
`;
export const StyledMainCreativeImage = styled.img`
  height: 85%;
  width: 100%;
  object-fit: fill;
`;

export const StyledAdVariantListButton = styled(IconButton)`
  width: 30px !important;
  height: 30px !important;
  background: #f8f8f8 !important;
  border-radius: 9px !important;
  font-size: 12px !important;
`;

export const StyledVariantItemPara = styled.p`
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  max-lines: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
  font-family: Inter;
`;
export const StyledHeading4New = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
  min-width: 14rem;
  font-family: "Inter";
  margin: revert;
  margin-bottom: unset;
  position: relative;
`;

export const StyledAdVariantBottomBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 335px;
  margin-top: 8px;
`;

export const StyledFrameHolder = styled.div`
  position: relative;
  z-index: 9999999999999999999999999999999;
  background-color: #fff;
  width: 370px;
  max-width: 500px;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  min-height: 450px;
  height: 100%;
  /* border-radius: 20px; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
export const StyledFrame = styled.iframe`
  z-index: 9999;
  width: 370px;
  max-width: 500px;
  min-height: 580px;
`;

export const StyledNewTagContainer = styled.span`
  position: absolute;
  top: -2px;
  padding-left: 2px;
`;

export const StyledNewTag = styled.a`
  background: #0869fb;
  border-radius: 3px 0 0 3px;
  color: white;
  font-weight: bold;
  display: inline-block;
  height: 18px;
  width: 30px;
  font-size: 10px;
  line-height: 18px;
  padding: 0 5px 0 6px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  animation: left-to-right-animate 1s ease 5;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  pointer-events: none;

  ::after {
    border-bottom: 8.5px solid transparent;
    border-left: 9px solid #0869fb;
    border-top: 8.5px solid transparent;
    content: "";
    position: absolute;
    right: -8px;
    top: 0.5px;
  }

  :hover {
    text-decoration: none;
    color: white;
  }

  @keyframes left-to-right-animate {
    0% {
      width: 0px;
    }
    100% {
      transform: 15px;
    }
  }
`;

export const StyledLoaderBox = styled(Box)`
  min-height: 444px;
  max-height: 444px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledStatusTagContainer = styled.span`
  position: absolute;
  top: -1px;
  padding-left: 12px;
`;
