import styled from "styled-components";

export const StyledMainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export const StyledSvgContainer = styled.div`
  margin-bottom: 1em;
`;
export const StyledMessageContainer = styled.div`
  font-size: 0.875em;
  color: #666666;
  width: 21.86em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 1.42em;
  text-align: center;
  margin-bottom: 1.2em;
`;
