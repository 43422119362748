import "./index.scss";
import useWarningMessage from "./hooks";
import { ReactComponent as WarningIcon } from "../../../assets/svg/warning-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import React, { Fragment } from "react";
import { useHref } from "../hooks";
import { useSearchParams } from "react-router-dom-v5-compat";

export function WarningMessage({ tokenWarnings }: { tokenWarnings: any }) {
  return (
    <Fragment>
      <WarningIcon className="color-fill mr-2" />
      <div className="text-md mr-2 color-text">{tokenWarnings?.message}</div>
    </Fragment>
  );
}

export function ReConnectURL({
  platform,
  type,
  brand_id,
  brandName,
}: {
  platform: string;
  type: string;
  brand_id: string;
  brandName: string;
}) {

  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  const href = useHref({
    platform,
    brandName,
    brand_id,
    source: window.location.href,
  });
  return (
    <>
      {type === "multiple" && (
        <a href={href} target={isViewedFromBeta?'_parent':''} className="text-sm">
          Reconnect
        </a>
      )}
      {type === "single" && (
        <a
          type="button"
          href={platform && href}
          target={isViewedFromBeta?'_parent':''}
          className="btn primary-reverse bg-transparent"
        >
          Reconnect
        </a>
      )}
    </>
  );
}

const WarningMessages: React.FC<{
  adAccountId: string;
  type: string;
}> = ({ adAccountId, type }) => {
  const { viewToggle, setViewToggle, tokenWarnings, platform, selectedBrand } =
    useWarningMessage(adAccountId);

  if (!tokenWarnings?.message) return null;

  return (
    <div className="warning-message-container">
      {type === "multiple" && platform && (
        <div
          className={
            (tokenWarnings && tokenWarnings.className === "warning"
              ? "bg-yellow"
              : "bg-red") + " bg-grey flex-center justify-content-start p-2"
          }
        >
          <WarningMessage tokenWarnings={tokenWarnings} />

          <ReConnectURL
            platform={platform}
            type={type}
            brand_id={selectedBrand?.id}
            brandName={selectedBrand?.name}
          />
        </div>
      )}
      {type === "single" && platform && viewToggle && (
        <div
          className={
            (tokenWarnings && tokenWarnings.className === "warning"
              ? "bg-yellow"
              : "bg-red") +
            " d-flex justify-content-between p-2 mb-2 boreder-present"
          }
        >
          <div className="flex-row flex-center">
            <WarningIcon className="mx-2 color-fill" />
            <div className="text-xl mr-2 color-text">
              <strong>{tokenWarnings?.message}</strong>
            </div>
            <div className="text-md mr-2 color-text">
              Please Reconnect Your Ad Account
            </div>
          </div>
          <div className="mr-2">
            <ReConnectURL
              platform={platform}
              type={type}
              brand_id={selectedBrand?.id}
              brandName={selectedBrand?.name}
            />
            <CloseIcon
              fill="#FFF"
              className="ml-2 close-icon"
              onClick={() => setViewToggle(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default WarningMessages;
