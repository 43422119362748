import { Box } from "@mui/material";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { ReactComponent as DownIcon } from "../../../assets/svg/down.svg";

function CustomToggle({ children, eventKey, defaultText, setIcon, icon }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setIcon(eventKey === icon ? -1 : eventKey);
  });
  return (
    <button
      type="button"
      className={`border-0 w-100 text-left ${
        icon === eventKey ? "down-arrow-icon" : "up-arrow-icon"
      }`}
      style={{
        background: "transparent",
      }}
      onClick={decoratedOnClick}
    >
      <div className="d-flex">
        {typeof children === "string" ? (
          <div className="p-2" style={{ width: "95%" }}>
            {children}
          </div>
        ) : (
          <>
            {icon === eventKey ? (
              <div className="p-2" style={{ width: "95%" }}>
                {defaultText}
              </div>
            ) : (
              children
            )}
          </>
        )}
        <div className="p-2 ml-auto my-auto" style={{ width: "5%" }}>
          <DownIcon className="sidebar-dropdown align-self-center" />
        </div>
      </div>
      {icon !== eventKey && <hr className="m-0" />}
    </button>
  );
}

function CustomAccordian({ cards }) {
  const [icon, setIcon] = useState(-1);

  return (
    <div className="w-100 font-body h-100" style={{ overflowY: "scroll" }}>
      <Accordion className="border-0 rounded-0" defaultActiveKey="0">
        {cards ? (
          cards.map((card, index) => (
            <div key={card.key}>
              <Card
                key={index}
                className="bg-white mb-2 rounded border-0"
                style={{ borderColor: "#D6E2E9" }}
              >
                <Card.Header
                  className="bg-white p-0 my-1 weight-500"
                  style={{ border: "0px", borderRadius: "0px" }}
                >
                  <CustomToggle
                    eventKey={index}
                    defaultText={card.additional}
                    setIcon={setIcon}
                    icon={icon}
                  >
                    {card.additional}
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={index}>
                  <Card.Body className="p-0">{card.description}</Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          ))
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            Nothing to show
          </div>
        )}
      </Accordion>
    </div>
  );
}

export default CustomAccordian;
