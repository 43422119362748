import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { ReactComponent as CloseIcon } from "./assests/close.svg";
import { ReactComponent as Warning } from "./assests/warning.svg";
import { IconButton } from "@mui/material";

function RejectModal({
  open,
  data,
  title,
  description,
  handleSubmit,
  handleCancel,
}: {
  open: boolean;
  data: {element: any, elementIndex: number, isStrategicRecommendation: boolean},
  title: string;
  description: string;
  handleCancel: Function;
  handleSubmit: Function;
}) {
  return (
    <Modal open={open} onClose={() => handleCancel()}>
      <Stack
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "28.125rem",
          bgcolor: "background.paper",
          borderRadius: "0.75rem",
          p: 4,
        }}
      >
        <Grid container mb={4}>
          <Grid xs={11}>
            <Warning />
          </Grid>
          <Grid xs={1} display="flex" alignItems="center" justifyContent="end">
            <IconButton
              onClick={() => {
                handleCancel();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography
          component="div"
          sx={{
            fontFamily: "Inter",
            fontSize: "1.25rem",
            fontWeight: 600,
            lineHeight: "2rem",
            color: "#000",
            py: 1,
          }}
        >
          {title}
        </Typography>
        <Typography
          component="div"
          sx={{
            fontFamily: "Inter",
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "1.375rem",
            letterSpacing: "0.00875rem",
            color: "#5C6674",
          }}
        >
          {description}
        </Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                mr: 1,
                textTransform: "none",
                bgcolor: "#fff",
                border: "1px solid #E0E3E5",
                color: "#394455",
                borderRadius: "0.5rem",
                textAlign: "center",
                fontFamily: "Inter",
                fontSize: "0.875rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "1.375rem",
                letterSpacing: "0.01094rem",
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                mr: 1,
                textTransform: "none",
                bgcolor: "#0869FB",
                color: "#fff",
                borderRadius: "0.5rem",
                textAlign: "center",
                fontFamily: "Inter",
                fontSize: "0.875rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "1.375rem",
                letterSpacing: "0.01094rem",
              }}
              onClick={() => handleSubmit(data)}
            >
              Reject
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Modal>
  );
}

export default RejectModal;
