import styled from "styled-components";
import { styled as MUIstyled } from "@mui/material/styles";
import { Button } from "components/shared";

export const StyledButtonContainer = styled.div`
  position: relative;
  display: flex;
  width: 9.5em;
  height: 1.625em;
`;
export const StyledButton = MUIstyled(Button)`
`;
