import CalibrationGS from "../GoogleSearch/components/CalibrationNode/index";
import CalibrationCollapsedGS from "../GoogleSearch/components/CalibrationNode/CalibrationCollapsedGS";
import LoaderCalibration from "../GoogleSearch/components/LoaderNodes/LoaderCalibrationNode";
import LoaderKeywordExplorer from "../GoogleSearch/components/LoaderNodes/LoaderKeywordExplorerNode";
import LoaderAudienceExplorerCollapsed from "../ReactFlowComponents/LoaderAudienceExplorerCollapsed";
import LoaderCalibrationCollapse from "../ReactFlowComponents/LoaderCalibrationCollapse";
import LoaderKeywordTuning from "../GoogleSearch/components/LoaderNodes/LoaderKeywordTuningNode";
import LoaderKeywordTuningCollapsed from "../ReactFlowComponents/loaderKeywordTuningCollapsed";
import KeywordTuning from "../GoogleSearch/components/KeywordTuningNode";
import KeywordTuningCollapsed from "../GoogleSearch/components/KeywordTuningNode/KeywordTuningCollapsed";
import KeywordExplorer from "../GoogleSearch/components/KeywordExplorerNode/KeywordContextProvider";
import {
  setNodeCollapse,
  setCurrentPage,
} from "../../../store/actions/targetingAI";

export const GS_NODE_TYPES = {
  nodeCalibrationExpanded: CalibrationGS,
  nodeCalibrationCollapsed: CalibrationCollapsedGS,
  nodeKeywordTuningExpanded: KeywordTuning,
  nodeKeywordTuningCollapsed: KeywordTuningCollapsed,
  nodeKeywordExplorerExpanded: KeywordExplorer,
  loaderCalibrationExpanded: LoaderCalibration,
  loaderCalibrationCollapsed: LoaderCalibrationCollapse,
  loaderKeywordTuningExpanded: LoaderKeywordTuning,
  loaderKeywordTuningCollapsed: LoaderKeywordTuningCollapsed,
  loaderKeywordExplorerExpanded: LoaderKeywordExplorer,
  loaderKeywordExplorerCollapsed: LoaderAudienceExplorerCollapsed,
};
export const GS_EDGES_LIST: any = {
  // aiinitiation to loader
  aiGroupToLoaderCalibrationExpanded: {
    id: "aiGroupToLoaderCalibrationExpanded",
    // source: "AIGroupSelectionParent",
    source: "aiInitiationParent",
    target: "loaderCalibrationExpanded",
    data: {
      page: 1,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  aiGroupToLoaderCalibrationCollapsed: {
    id: "aiGroupToLoaderCalibrationCollapsed",
    // source: "AIGroupSelectionParent",
    source: "aiInitiationParent",
    target: "loaderCalibrationCollapsed",
    data: {
      page: 1,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  // loader calibration to node calibration
  loaderCalibrationExpandedToNodeCalibrationExpandedExplore: {
    id: "loaderCalibrationExpandedToNodeCalibrationExpandedExplore",
    source: "loaderCalibrationExpanded",
    target: "nodeCalibrationExpanded",
    targetHandle: "explore",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderCalibrationExpandedToNodeCalibrationExpandedExploit: {
    id: "loaderCalibrationExpandedToNodeCalibrationExpandedExploit",
    source: "loaderCalibrationExpanded",
    target: "nodeCalibrationExpanded",
    targetHandle: "exploit",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderCalibrationCollapsedToNodeCalibrationExpandedExplore: {
    id: "loaderCalibrationCollapsedToNodeCalibrationExpandedExplore",
    source: "loaderCalibrationCollapsed",
    target: "nodeCalibrationExpanded",
    targetHandle: "explore",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderCalibrationCollapsedToNodeCalibrationExpandedExploit: {
    id: "loaderCalibrationCollapsedToNodeCalibrationExpandedExploit",
    source: "loaderCalibrationCollapsed",
    target: "nodeCalibrationExpanded",
    targetHandle: "exploit",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderCalibrationCollapsedToNodeCalibrationCollapsed: {
    id: "loaderCalibrationCollapsedToNodeCalibrationCollapsed",
    source: "loaderCalibrationCollapsed",
    target: "nodeCalibrationCollapsed",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderCalibrationExpandedToNodeCalibrationCollapsed: {
    id: "loaderCalibrationExpandedToNodeCalibrationCollapsed",
    source: "loaderCalibrationExpanded",
    target: "nodeCalibrationCollapsed",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  // node calibration to loader keywordTuning
  nodeCalibrationExpandedToLoaderKeywordTuningExpanded: {
    id: "nodeCalibrationExpandedToLoaderKeywordTuningExpanded",
    source: "nodeCalibrationExpanded",
    target: "loaderKeywordTuningExpanded",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  nodeCalibrationExpandedToLoaderKeywordTuningCollapsed: {
    id: "nodeCalibrationExpandedToLoaderKeywordTuningCollapsed",
    source: "nodeCalibrationExpanded",
    target: "loaderKeywordTuningCollapsed",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  nodeCalibrationCollapsedToLoaderKeywordTuningExpanded: {
    id: "nodeCalibrationCollapsedToLoaderKeywordTuningExpanded",
    source: "nodeCalibrationCollapsed",
    target: "loaderKeywordTuningExpanded",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  nodeCalibrationCollapsedToLoaderKeywordTuningCollapsed: {
    id: "nodeCalibrationCollapsedToLoaderKeywordTuningCollapsed",
    source: "nodeCalibrationCollapsed",
    target: "loaderKeywordTuningCollapsed",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  // loader keywordTuning to node keywordTuning
  loaderKeywordTuningExpandedToNodeKeywordTuningExpanded: {
    id: "loaderKeywordTuningExpandedToNodeKeywordTuningExpanded",
    source: "loaderKeywordTuningExpanded",
    target: "nodeKeywordTuningExpanded",
    data: {
      page: 4,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderKeywordTuningExpandedToNodeKeywordTuningCollapsed: {
    id: "loaderKeywordTuningExpandedToNodeKeywordTuningCollapsed",
    source: "loaderKeywordTuningExpanded",
    target: "nodeKeywordTuningCollapsed",
    data: {
      page: 4,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderKeywordTuningCollapsedToNodeKeywordTuningExpanded: {
    id: "loaderKeywordTuningCollapsedToNodeKeywordTuningExpanded",
    source: "loaderKeywordTuningCollapsed",
    target: "nodeKeywordTuningExpanded",
    data: {
      page: 4,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderKeywordTuningCollapsedToNodeKeywordTuningCollapsed: {
    id: "loaderKeywordTuningCollapsedToNodeKeywordTuningCollapsed",
    source: "loaderKeywordTuningCollapsed",
    target: "nodeKeywordTuningCollapsed",
    data: {
      page: 4,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  // node keywordTuning to loader keywordExplorer
  nodeKeywordTuningExpandedToLoaderKeywordExplorerExpanded: {
    id: "nodeKeywordTuningExpandedToLoaderKeywordExplorerExpanded",
    source: "nodeKeywordTuningExpanded",
    target: "loaderKeywordExplorerExpanded",
    data: {
      page: 5,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  nodeKeywordTuningExpandedToLoaderKeywordExplorerCollapsed: {
    id: "nodeKeywordTuningExpandedToLoaderKeywordExplorerCollapsed",
    source: "nodeKeywordTuningExpanded",
    target: "loaderKeywordExplorerCollapsed",
    data: {
      page: 5,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  nodeKeywordTuningCollapsedToLoaderKeywordExplorerExpanded: {
    id: "nodeKeywordTuningCollapsedToLoaderKeywordExplorerExpanded",
    source: "nodeKeywordTuningCollapsed",
    target: "loaderKeywordExplorerExpanded",
    data: {
      page: 5,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  nodeKeywordTuningCollapsedToLoaderKeywordExplorerCollapsed: {
    id: "nodeKeywordTuningCollapsedToLoaderKeywordExplorerCollapsed",
    source: "nodeKeywordTuningCollapsed",
    target: "loaderKeywordExplorerCollapsed",
    data: {
      page: 5,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  // loader keywordExplorer to node keywordExplorer
  loaderKeywordExplorerExpandedToNodeKeywordExplorerExpanded: {
    id: "loaderKeywordExplorerExpandedToNodeKeywordExplorerExpanded",
    source: "loaderKeywordExplorerExpanded",
    target: "nodeKeywordExplorerExpanded",
    data: {
      page: 6,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderKeywordExplorerExpandedToNodeKeywordExplorerCollapsed: {
    id: "loaderKeywordExplorerExpandedToNodeKeywordExplorerCollapsed",
    source: "loaderKeywordExplorerExpanded",
    target: "nodeKeywordExplorerCollapsed",
    data: {
      page: 6,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderKeywordExplorerCollapsedToNodeKeywordExplorerExpanded: {
    id: "loaderKeywordExplorerCollapsedToNodeKeywordExplorerExpanded",
    source: "loaderKeywordExplorerCollapsed",
    target: "nodeKeywordExplorerExpanded",
    data: {
      page: 6,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderKeywordExplorerCollapsedToNodeKeywordExplorerCollapsed: {
    id: "loaderKeywordExplorerCollapsedToNodeKeywordExplorerCollapsed",
    source: "loaderKeywordExplorerCollapsed",
    target: "nodeKeywordExplorerCollapsed",
    data: {
      page: 6,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
};
const nodePosition: any = {
  aiInitiationParent: function () {
    return { x: 80, y: 240 };
  },
  aiInitiation: function () {
    return { x: 10, y: 0 };
  },
  loaderCalibrationExpanded: function () {
    return { x: 500, y: 135 };
  },
  loaderCalibrationCollapsed: function () {
    return { x: 450, y: 420 };
  },
  nodeCalibrationExpanded: function (loaderState: any) {
    const loaderCalibrationCollapsed =
      loaderState["loaderCalibrationCollapsed"];
    return { x: loaderCalibrationCollapsed ? 700 : 2000, y: 10 };
  },
  nodeCalibrationCollapsed: function (loaderState: any) {
    const loaderCalibrationCollapsed =
      loaderState["loaderCalibrationCollapsed"];
    return { x: loaderCalibrationCollapsed ? 550 : 1950, y: 230 };
  },
  loaderKeywordTuningExpanded: function (loaderState: any) {
    const loaderCalibrationCollapsed =
      loaderState["loaderCalibrationCollapsed"];
    const nodeCalibrationCollapsed = loaderState["nodeCalibrationCollapsed"];
    if (nodeCalibrationCollapsed)
      return { x: loaderCalibrationCollapsed ? 1000 : 2400, y: 40 };
    if (loaderCalibrationCollapsed) return { x: 1950, y: 40 };
    return { x: 3000, y: 40 };
  },
  loaderKeywordTuningCollapsed: function (loaderState: any) {
    const loaderCalibrationCollapsed =
      loaderState["loaderCalibrationCollapsed"];
    const nodeCalibrationCollapsed = loaderState["nodeCalibrationCollapsed"];
    if (nodeCalibrationCollapsed)
      return { x: loaderCalibrationCollapsed ? 900 : 2300, y: 410 };
    return { x: loaderCalibrationCollapsed ? 1800 : 3100, y: 390 };
  },
  nodeKeywordTuningExpanded: function (loaderState: any) {
    const loaderCalibrationCollapsed =
      loaderState["loaderCalibrationCollapsed"];
    const nodeCalibrationCollapsed = loaderState["nodeCalibrationCollapsed"];
    const loaderKeywordTuningCollapsed =
      loaderState["loaderKeywordTuningCollapsed"];
    if (loaderKeywordTuningCollapsed && loaderCalibrationCollapsed)
      return { x: nodeCalibrationCollapsed ? 1050 : 1950, y: 5 };
    else if (loaderKeywordTuningCollapsed)
      return { x: nodeCalibrationCollapsed ? 2500 : 3300, y: 5 };
    else if (loaderCalibrationCollapsed)
      return { x: nodeCalibrationCollapsed ? 2200 : 3300, y: 5 };
    else if (nodeCalibrationCollapsed) return { x: 3450, y: 5 };
    else return { x: 4500, y: 5 };
  },
  nodeKeywordTuningCollapsed: function (loaderState: any) {
    const loaderCalibrationCollapsed =
      loaderState["loaderCalibrationCollapsed"];
    const nodeCalibrationCollapsed = loaderState["nodeCalibrationCollapsed"];
    const loaderKeywordTuningCollapsed =
      loaderState["loaderKeywordTuningCollapsed"];
    if (loaderKeywordTuningCollapsed && loaderCalibrationCollapsed)
      return {
        x: nodeCalibrationCollapsed ? 1050 : 1950,
        y: loaderKeywordTuningCollapsed ? 290 : 270,
      };
    else if (loaderKeywordTuningCollapsed)
      return { x: nodeCalibrationCollapsed ? 2500 : 3350, y: 290 };
    else if (loaderCalibrationCollapsed)
      return {
        x: nodeCalibrationCollapsed ? 2200 : 3200,
        y: loaderKeywordTuningCollapsed ? 290 : 270,
      };
    else if (nodeCalibrationCollapsed)
      return { x: 3685, y: loaderKeywordTuningCollapsed ? 290 : 270 };
    else return { x: 4300, y: loaderKeywordTuningCollapsed ? 290 : 270 };
  },
  loaderKeywordExplorerExpanded: function (loaderState: any) {
    const loaderCalibrationCollapsed =
      loaderState["loaderCalibrationCollapsed"];
    const nodeCalibrationCollapsed = loaderState["nodeCalibrationCollapsed"];
    const loaderKeywordTuningCollapsed =
      loaderState["loaderKeywordTuningCollapsed"];
    const nodeKeywordTuningCollapsed =
      loaderState["nodeKeywordTuningCollapsed"];
    if (loaderCalibrationCollapsed && loaderKeywordTuningCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 1550 : 2350, y: 20 };
      if (nodeKeywordTuningCollapsed) return { x: 2460, y: 20 };
      return { x: 3300, y: 20 };
    } else if (loaderCalibrationCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 2600 : 3400, y: 20 };
      if (nodeKeywordTuningCollapsed) return { x: 3850, y: 20 };
      return { x: 4700, y: 20 };
    } else if (loaderKeywordTuningCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 3000 : 3500, y: 20 };
      if (nodeKeywordTuningCollapsed) return { x: 3850, y: 20 };
      return { x: 4500, y: 20 };
    } else if (nodeKeywordTuningCollapsed && nodeCalibrationCollapsed) {
      return { x: 4150, y: 20 };
    } else if (nodeKeywordTuningCollapsed) {
      return { x: 4850, y: 20 };
    } else if (nodeCalibrationCollapsed) {
      return { x: 4750, y: 20 };
    } else return { x: 6000, y: 20 };
  },
  loaderKeywordExplorerCollapsed: function (loaderState: any) {
    const loaderCalibrationCollapsed =
      loaderState["loaderCalibrationCollapsed"];
    const nodeCalibrationCollapsed = loaderState["nodeCalibrationCollapsed"];
    const loaderKeywordTuningCollapsed =
      loaderState["loaderKeywordTuningCollapsed"];
    const nodeKeywordTuningCollapsed =
      loaderState["nodeKeywordTuningCollapsed"];
    if (loaderCalibrationCollapsed && loaderKeywordTuningCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 1400 : 2200, y: 410 };
      if (nodeKeywordTuningCollapsed) return { x: 2295, y: 410 };
      return { x: 3100, y: 410 };
    } else if (loaderCalibrationCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 2600 : 3600, y: 410 };
      if (nodeKeywordTuningCollapsed) return { x: 3650, y: 410 };
      return { x: 4600, y: 410 };
    } else if (loaderKeywordTuningCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 2850 : 3650, y: 410 };
      if (nodeKeywordTuningCollapsed) return { x: 3750, y: 410 };
      return { x: 4550, y: 410 };
    } else if (nodeKeywordTuningCollapsed && nodeCalibrationCollapsed) {
      return { x: 4050, y: 410 };
    } else if (nodeKeywordTuningCollapsed) {
      return { x: 4700, y: 410 };
    } else if (nodeCalibrationCollapsed) {
      return { x: 4850, y: 410 };
    } else return { x: 5700, y: 410 };
  },
  nodeKeywordExplorerExpanded: function (loaderState: any) {
    const loaderCalibrationCollapsed =
      loaderState["loaderCalibrationCollapsed"];
    const nodeCalibrationCollapsed = loaderState["nodeCalibrationCollapsed"];
    const loaderKeywordTuningCollapsed =
      loaderState["loaderKeywordTuningCollapsed"];
    const nodeKeywordTuningCollapsed =
      loaderState["nodeKeywordTuningCollapsed"];
    const loaderKeywordExplorerCollapsed =
      loaderState["loaderKeywordExplorerCollapsed"];
    if (
      loaderCalibrationCollapsed &&
      loaderKeywordTuningCollapsed &&
      loaderKeywordExplorerCollapsed
    ) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 1830 : 2400, y: 170 };
      if (nodeKeywordTuningCollapsed) return { x: 2460, y: 170 };
      return { x: 3300, y: 170 };
    } else if (loaderCalibrationCollapsed && loaderKeywordTuningCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 2950 : 3800, y: 170 };
      if (nodeKeywordTuningCollapsed) return { x: 4100, y: 170 };
      return { x: 5000, y: 170 };
    } else if (loaderCalibrationCollapsed && loaderKeywordExplorerCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 3050 : 3700, y: 170 };
      if (nodeKeywordTuningCollapsed) return { x: 3800, y: 170 };
      return { x: 4900, y: 170 };
    } else if (loaderKeywordTuningCollapsed && loaderKeywordExplorerCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 3000 : 3850, y: 170 };
      if (nodeKeywordTuningCollapsed) return { x: 3960, y: 170 };
      return { x: 4900, y: 170 };
    } else if (loaderCalibrationCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 4000 : 4850, y: 170 };
      if (nodeKeywordTuningCollapsed) return { x: 5300, y: 170 };
      return { x: 6100, y: 170 };
    } else if (loaderKeywordTuningCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 4050 : 4900, y: 170 };
      if (nodeKeywordTuningCollapsed) return { x: 5000, y: 170 };
      return { x: 6100, y: 170 };
    } else if (loaderKeywordExplorerCollapsed) {
      if (nodeCalibrationCollapsed)
        return { x: nodeKeywordTuningCollapsed ? 4200 : 5300, y: 170 };
      if (nodeKeywordTuningCollapsed) return { x: 5200, y: 170 };
      return { x: 6000, y: 170 };
    } else if (nodeKeywordTuningCollapsed && nodeCalibrationCollapsed) {
      return { x: 4950, y: 170 };
    } else if (nodeKeywordTuningCollapsed) {
      return { x: 6000, y: 170 };
    } else if (nodeCalibrationCollapsed) {
      return { x: 6250, y: 170 };
    } else {
      return { x: 7500, y: 170 };
    }
  },
};
export const changePosition = (
  page: number,
  setNodes: Function,
  loaderState: any
) => {
  setNodes((prevNodes: any) => {
    let newNodes = prevNodes.map((node: any) => {
      if (
        (node.data.page < page || node.parentNode) &&
        node.id !== "nodeKeywordExplorerExpanded"
      ) {
        return node;
      } else {
        node.position = nodePosition[node.id](loaderState);
        node.data.position = nodePosition[node.id](loaderState);
        return node;
      }
    });
    return [...newNodes];
  });
};

export const GS_NODES_LIST: any = {
  loaderCalibrationExpanded: {
    id: "loaderCalibrationExpanded",
    type: "loaderCalibrationExpanded",
    position: { x: 900, y: 0 },
    className: "light",
    style: {
      display: "flex",
      width: 1300,
      height: "95vh",
      borderRadius: "20px",
    },
    sourcePosition: "right",
    targetPosition: "left",
    draggable: false,
    data: {
      index: 2,
      page: 1,
      label: "Calibration Loader Expanded",
      loaderContinue: (
        dispatch: Function,
        loaderState: any,
        setNodes: Function,
        setEdges: Function
      ) => {
        const activePageLocal = localStorage.getItem("currentPage") as string;
        const activePageLocalInt = parseInt(activePageLocal);

        setNodes((nodes: any) => {
          return activePageLocalInt <= 2
            ? [
                ...nodes.filter(
                  (node: any) => node.id !== "loaderCalibrationExpanded"
                ),
                GS_NODES_LIST["nodeCalibrationExpanded"],
                GS_NODES_LIST["loaderCalibrationCollapsed"],
              ]
            : [
                ...nodes.filter(
                  (node: any) => node.id !== "loaderCalibrationExpanded"
                ),
                GS_NODES_LIST["loaderCalibrationCollapsed"],
              ];
        });
        setEdges((edges: any) => {
          let filteredEdges = edges.filter(
            (e: any) =>
              ![e?.source, e?.target].includes("loaderCalibrationExpanded") ||
              e.targetHandle
          );
          return [
            ...filteredEdges,
            GS_EDGES_LIST["aiGroupToLoaderCalibrationCollapsed"],
            ...(loaderState["nodeCalibrationCollapsed"] &&
            activePageLocalInt > 2
              ? [
                  GS_EDGES_LIST[
                    "loaderCalibrationCollapsedToNodeCalibrationCollapsed"
                  ],
                ]
              : [
                  GS_EDGES_LIST[
                    "loaderCalibrationCollapsedToNodeCalibrationExpandedExplore"
                  ],
                  GS_EDGES_LIST[
                    "loaderCalibrationCollapsedToNodeCalibrationExpandedExploit"
                  ],
                ]),
          ];
        });
        dispatch(
          setNodeCollapse({
            type: "loaderCalibrationCollapsed",
            collapse: true,
          })
        );
        changePosition(1, setNodes, {
          ...loaderState,
          loaderCalibrationCollapsed: true,
        });
        dispatch(
          setCurrentPage(activePageLocalInt > 2 ? activePageLocalInt : 3)
        );
      },
      textBtn: "Continue",
      position: { x: 600, y: 20 },
    },
  },
  loaderCalibrationCollapsed: {
    id: "loaderCalibrationCollapsed",
    type: "loaderCalibrationCollapsed",
    data: {
      page: 1,
      label: "Calibration Loader Collapsed",
      position: { x: 600, y: 410 },
    },
    position: { x: 600, y: 410 },
    className: "light",
    style: {
      display: "flex",
      width: "2.90rem",
      height: "2.90rem",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50px",
      backgroundColor: "#fff",
      boxShadow: "0px 4.83018px 16.9056px rgba(0, 0, 0, 0.05)",
    },
    sourcePosition: "right",
    targetPosition: "left",
    draggable: false,
  },
  nodeCalibrationExpanded: {
    id: "nodeCalibrationExpanded",
    type: "nodeCalibrationExpanded",
    position: { x: 800, y: 0 },
    className: "light",
    style: {
      width: "1px",
      height: "42.37rem",
      zIndex: 0,
      borderRadius: "20px",
    },
    draggable: false,
    sourcePosition: "right",
    targetPosition: "left",
    data: {
      index: 3,
      page: 2,
      label: "Calibration Expanded",
      nextPage: (
        dispatch: Function,
        loaderState: any,
        setNodes: Function,
        setEdges: Function,
        isEdit: boolean = false
      ) => {
        const activePageLocal = localStorage.getItem("currentPage") as string;
        const activePageLocalInt = parseInt(activePageLocal);
        if (loaderState["loaderCalibrationCollapsed"]) {
          GS_NODES_LIST["loaderCalibrationCollapsed"].position = {
            x: 900,
            y: 100,
          };
          GS_NODES_LIST["loaderCalibrationCollapsed"].data.position = {
            x: 900,
            y: 100,
          };
        }

        setNodes((nodes: any) => {
          let nodeSpread = [...nodes];
          const filteredNodes = isEdit
            ? nodeSpread.filter(
                (node: any) => node.id !== "nodeCalibrationExpanded" // current node
              )
            : nodeSpread.filter(
                (
                  node: any // includes all the previous nodes
                ) =>
                  [
                    "aiInitiationParent",
                    "aiInitiation",
                    "loaderCalibrationCollapsed",
                    "loaderCalibrationExpanded",
                  ].includes(node.id)
              );
          return [
            ...filteredNodes,
            GS_NODES_LIST["nodeCalibrationCollapsed"],
            ...(!isEdit ? [GS_NODES_LIST["loaderKeywordTuningExpanded"]] : []), // next node which is to be added
          ];
        });
        setEdges((edges: any) => {
          let filteredEdges = isEdit
            ? edges.filter(
                (e: any) =>
                  ![e?.source, e?.target].includes(`nodeCalibrationExpanded`) &&
                  !e.targetHandle // current node
              )
            : edges.filter(
                (
                  e: any // next nodes to be connected
                ) =>
                  [
                    "aiGroupToLoaderCalibrationExpanded",
                    "aiGroupToLoaderCalibrationCollapsed",
                  ].includes(e.id)
              );

          return [
            ...filteredEdges,
            loaderState["loaderCalibrationCollapsed"]
              ? GS_EDGES_LIST[
                  "loaderCalibrationCollapsedToNodeCalibrationCollapsed"
                ]
              : GS_EDGES_LIST[
                  "loaderCalibrationExpandedToNodeCalibrationCollapsed"
                ],
            loaderState["loaderKeywordTuningCollapsed"] && isEdit
              ? GS_EDGES_LIST[
                  "nodeCalibrationCollapsedToLoaderKeywordTuningCollapsed"
                ]
              : GS_EDGES_LIST[
                  "nodeCalibrationCollapsedToLoaderKeywordTuningExpanded"
                ],
          ];
        });

        dispatch(
          setNodeCollapse({
            type: "nodeCalibrationCollapsed",
            collapse: true,
          })
        );
        changePosition(1, setNodes, {
          ...loaderState,
          nodeCalibrationCollapsed: true,
        });
        dispatch(
          setCurrentPage(activePageLocalInt > 3 ? activePageLocalInt : 4)
        );
      },
      position: { x: 800, y: 10 },
    },
  },
  nodeCalibrationCollapsed: {
    id: "nodeCalibrationCollapsed",
    type: "nodeCalibrationCollapsed",
    position: { x: 900, y: 240 },
    className: "light",
    style: {
      display: "flex",
      width: "1px",
      height: "9.68rem",
      backgroundColor: "#FFF",
      borderRadius: "20px",
    },
    sourcePosition: "right",
    targetPosition: "left",
    draggable: false,
    data: {
      page: 2,
      popover: true,
      label: "Calibration Collapsed",
      onEditClick: (
        dispatch: Function,
        loaderState: any,
        setNodes: Function,
        setEdges: Function
      ) => {
        setNodes((nodes: any) => {
          let nodeSpread = [...nodes];
          let filteredNodes = nodeSpread.filter(
            (node: any) => !["nodeCalibrationCollapsed"].includes(node.id)
          );
          return [...filteredNodes, GS_NODES_LIST["nodeCalibrationExpanded"]];
        });

        setEdges((edges: any) => {
          let filteredEdges = edges.filter(
            (e: any) =>
              ![e?.source, e?.target].includes(`nodeCalibrationCollapsed`)
          );
          return [
            ...filteredEdges,
            ...(loaderState["loaderCalibrationCollapsed"]
              ? [
                  GS_EDGES_LIST[
                    "loaderCalibrationCollapsedToNodeCalibrationExpandedExplore"
                  ],
                  GS_EDGES_LIST[
                    "loaderCalibrationCollapsedToNodeCalibrationExpandedExploit"
                  ],
                ]
              : [
                  GS_EDGES_LIST[
                    "loaderCalibrationExpandedToNodeCalibrationExpandedExplore"
                  ],
                  GS_EDGES_LIST[
                    "loaderCalibrationExpandedToNodeCalibrationExpandedExploit"
                  ],
                ]),
            loaderState["loaderKeywordTuningCollapsed"]
              ? GS_EDGES_LIST[
                  "nodeCalibrationExpandedToLoaderKeywordTuningCollapsed"
                ]
              : GS_EDGES_LIST[
                  "nodeCalibrationExpandedToLoaderKeywordTuningExpanded"
                ],
          ];
        });

        dispatch(
          setNodeCollapse({
            type: "nodeCalibrationCollapsed",
            collapse: false,
          })
        );
        changePosition(1, setNodes, {
          ...loaderState,
          nodeCalibrationCollapsed: false,
        });
      },
      position: { x: 900, y: 240 },
    },
  },
  loaderKeywordTuningExpanded: {
    id: "loaderKeywordTuningExpanded",
    type: "loaderKeywordTuningExpanded",
    position: { x: 1300, y: 0 },
    className: "light",
    style: {
      display: "flex",
      width: 1000,
      height: "90vh",
      borderRadius: "20px",
    },
    sourcePosition: "right",
    targetPosition: "left",
    draggable: false,
    data: {
      index: 4,
      page: 3,
      label: "Keyword Tuning Loader Expanded",
      loaderContinue: (
        dispatch: Function,
        loaderState: any,
        setNodes: Function,
        setEdges: Function
      ) => {
        const activePageLocal = localStorage.getItem("currentPage") as string;
        const activePageLocalInt = parseInt(activePageLocal);

        setNodes((nodes: any) => {
          return activePageLocalInt <= 4
            ? [
                ...nodes.filter(
                  (node: any) => node.id !== "loaderKeywordTuningExpanded"
                ),
                GS_NODES_LIST["loaderKeywordTuningCollapsed"],
                GS_NODES_LIST["nodeKeywordTuningExpanded"],
              ]
            : [
                ...nodes.filter(
                  (node: any) => node.id !== "loaderKeywordTuningExpanded"
                ),
                GS_NODES_LIST["loaderKeywordTuningCollapsed"],
              ];
        });
        setEdges((edges: any) => {
          const addEdge =
            loaderState["nodeKeywordTuningCollapsed"] && activePageLocalInt > 4
              ? GS_EDGES_LIST[
                  "loaderKeywordTuningCollapsedToNodeKeywordTuningCollapsed"
                ]
              : GS_EDGES_LIST[
                  "loaderKeywordTuningCollapsedToNodeKeywordTuningExpanded"
                ];
          return [
            ...edges.filter((e: any) => {
              const sourceTarget = [e?.source, e?.target];
              return (
                !sourceTarget.includes("loaderKeywordTuningExpanded") &&
                !sourceTarget.includes("loaderKeywordTuningCollapsed")
              );
            }),
            loaderState["nodeCalibrationCollapsed"]
              ? GS_EDGES_LIST[
                  "nodeCalibrationCollapsedToLoaderKeywordTuningCollapsed"
                ]
              : GS_EDGES_LIST[
                  "nodeCalibrationExpandedToLoaderKeywordTuningCollapsed"
                ],
            addEdge,
          ];
        });

        dispatch(
          setNodeCollapse({
            type: "loaderKeywordTuningCollapsed",
            collapse: true,
          })
        );
        changePosition(1, setNodes, {
          ...loaderState,
          loaderKeywordTuningCollapsed: true,
        });
        dispatch(
          setCurrentPage(activePageLocalInt > 4 ? activePageLocalInt : 5)
        );
      },
      textBtn: "Continue",
      position: { x: 1300, y: 240 },
    },
  },
  loaderKeywordTuningCollapsed: {
    id: "loaderKeywordTuningCollapsed",
    type: "loaderKeywordTuningCollapsed",
    position: { x: 1300, y: 240 },
    className: "light",
    style: {
      display: "flex",
      width: "2.90rem",
      height: "2.90rem",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50px",
      backgroundColor: "#fff",
      boxShadow: "0px 4.83018px 16.9056px rgba(0, 0, 0, 0.05)",
    },
    targetPosition: "left",
    sourcePosition: "right",
    draggable: false,
    data: {
      page: 3,
      label: "KeywordTuning Loader Collapsed",
      position: { x: 1700, y: 240 },
    },
  },
  nodeKeywordTuningExpanded: {
    id: "nodeKeywordTuningExpanded",
    type: "nodeKeywordTuningExpanded",
    position: { x: 1800, y: 0 },
    draggable: false,
    style: {
      width: 1,
      height: 850,
      zIndex: 0,
    },
    data: {
      index: 5,
      page: 4,
      label: "Keyword Tuning Expanded",
      position: { x: 2000, y: 240 },
      nextPage: (
        dispatch: Function,
        loaderState: any,
        setNodes: Function,
        setEdges: Function,
        isEdit: boolean = false
      ) => {
        const activePageLocal = localStorage.getItem("currentPage") as string;
        const activePageLocalInt = parseInt(activePageLocal);
        setNodes((nodes: any) => {
          let nodeSpread = [...nodes];
          let filteredNodes = isEdit
            ? nodeSpread.filter(
                (node: any) => node.id !== "nodeKeywordTuningExpanded"
              )
            : nodeSpread.filter((node: any) =>
                [
                  "aiInitiationParent",
                  "aiInitiation",
                  "loaderCalibrationCollapsed",
                  "loaderCalibrationExpanded",
                  "nodeCalibrationExpanded",
                  "nodeCalibrationCollapsed",
                  "loaderKeywordTuningExpanded",
                  "loaderKeywordTuningCollapsed",
                ].includes(node.id)
              );
          return [
            ...filteredNodes,
            GS_NODES_LIST["nodeKeywordTuningCollapsed"],
            ...(!isEdit
              ? [GS_NODES_LIST["loaderKeywordExplorerExpanded"]]
              : []),
          ];
        });
        setEdges((edges: any) => {
          let filteredEdges = isEdit
            ? edges.filter(
                (e: any) =>
                  ![e?.source, e?.target].includes(`nodeKeywordTuningExpanded`)
              )
            : edges.filter((e: any) =>
                [
                  "aiGroupToLoaderCalibrationExpanded",
                  "aiGroupToLoaderCalibrationCollapsed",
                  "loaderCalibrationExpandedToNodeCalibrationExpandedExplore",
                  "loaderCalibrationExpandedToNodeCalibrationExpandedExploit",
                  "loaderCalibrationCollapsedToNodeCalibrationExpandedExplore",
                  "loaderCalibrationCollapsedToNodeCalibrationExpandedExploit",
                  "loaderCalibrationCollapsedToNodeCalibrationCollapsed",
                  "loaderCalibrationExpandedToNodeCalibrationCollapsed",
                  "nodeCalibrationExpandedToLoaderKeywordTuningExpanded",
                  "nodeCalibrationExpandedToLoaderKeywordTuningCollapsed",
                  "nodeCalibrationCollapsedToLoaderKeywordTuningExpanded",
                  "nodeCalibrationCollapsedToLoaderKeywordTuningCollapsed",
                  // prev nodes to be connected
                ].includes(e.id)
              );
          return [
            ...filteredEdges,
            loaderState["loaderKeywordTuningCollapsed"]
              ? GS_EDGES_LIST[
                  "loaderKeywordTuningCollapsedToNodeKeywordTuningCollapsed"
                ]
              : GS_EDGES_LIST[
                  "loaderKeywordTuningExpandedToNodeKeywordTuningCollapsed"
                ],
            loaderState["loaderKeywordExplorerCollapsed"] && isEdit
              ? GS_EDGES_LIST[
                  "nodeKeywordTuningCollapsedToLoaderKeywordExplorerCollapsed"
                ]
              : GS_EDGES_LIST[
                  "nodeKeywordTuningCollapsedToLoaderKeywordExplorerExpanded"
                ],
          ];
        });

        dispatch(
          setNodeCollapse({
            type: "nodeKeywordTuningCollapsed",
            collapse: true,
          })
        );
        changePosition(1, setNodes, {
          ...loaderState,
          nodeKeywordTuningCollapsed: true,
        });
        dispatch(
          setCurrentPage(activePageLocalInt > 5 ? activePageLocalInt : 6)
        );
      },
    },
  },
  nodeKeywordTuningCollapsed: {
    id: "nodeKeywordTuningCollapsed",
    type: "nodeKeywordTuningCollapsed",
    position: { x: 1800, y: 240 },
    className: "light",
    targetPosition: "left",
    sourcePosition: "right",
    draggable: false,
    data: {
      page: 4,
      label: "Keyword Tuning Loader Collapsed",
      position: { x: 1800, y: 240 },

      onEditClick: (
        dispatch: Function,
        loaderState: any,
        setNodes: Function,
        setEdges: Function
      ) => {
        setNodes((nodes: any) => {
          let nodeSpread = [...nodes];
          let filteredNodes = nodeSpread.filter(
            (node: any) => !["nodeKeywordTuningCollapsed"].includes(node.id)
          );
          return [...filteredNodes, GS_NODES_LIST["nodeKeywordTuningExpanded"]];
        });

        setEdges((edges: any) => {
          let filteredEdges = edges.filter(
            (e: any) =>
              ![e?.source, e?.target].includes(`nodeKeywordTuningCollapsed`)
          );
          return [
            ...filteredEdges,
            loaderState["loaderKeywordTuningCollapsed"]
              ? GS_EDGES_LIST[
                  "loaderKeywordTuningCollapsedToNodeKeywordTuningExpanded"
                ]
              : GS_EDGES_LIST[
                  "loaderKeywordTuningExpandedToNodeKeywordTuningExpanded"
                ],
            loaderState["loaderKeywordExplorerCollapsed"]
              ? GS_EDGES_LIST[
                  "nodeKeywordTuningExpandedToLoaderKeywordExplorerCollapsed"
                ]
              : GS_EDGES_LIST[
                  "nodeKeywordTuningExpandedToLoaderKeywordExplorerExpanded"
                ],
          ];
        });

        dispatch(
          setNodeCollapse({
            type: "nodeKeywordTuningCollapsed",
            collapse: false,
          })
        );
        changePosition(1, setNodes, {
          ...loaderState,
          nodeKeywordTuningCollapsed: false,
        });
      },
    },
  },
  loaderKeywordExplorerExpanded: {
    id: "loaderKeywordExplorerExpanded",
    type: "loaderKeywordExplorerExpanded",
    position: { x: 2500, y: 0 },
    className: "light",
    targetPosition: "left",
    sourcePosition: "right",
    draggable: false,
    style: {
      display: "flex",
      width: 1000,
      height: "90vh",
      borderRadius: "20px",
    },
    data: {
      index: 6,
      page: 5,
      label: "Keyword Explorer Loader Expanded ",
      loaderContinue: (
        dispatch: Function,
        loaderState: any,
        setNodes: Function,
        setEdges: Function
      ) => {
        const activePageLocal = localStorage.getItem("currentPage") as string;
        const activePageLocalInt = parseInt(activePageLocal);
        setNodes((nodes: any) => {
          let nodeSpread = [...nodes];
          let filteredNodes = nodeSpread.filter(
            (node: any) => !["loaderKeywordExplorerExpanded"].includes(node.id)
          );
          return [
            ...filteredNodes,
            GS_NODES_LIST["loaderKeywordExplorerCollapsed"],
            GS_NODES_LIST["nodeKeywordExplorerExpanded"],
          ];
        });
        setEdges((edges: any) => {
          let filteredEdges = edges.filter(
            (e: any) =>
              ![e?.source, e?.target].includes(`loaderKeywordExplorerExpanded`)
          );
          return [
            ...filteredEdges,
            loaderState["nodeKeywordTuningCollapsed"]
              ? GS_EDGES_LIST[
                  "nodeKeywordTuningCollapsedToLoaderKeywordExplorerCollapsed"
                ]
              : GS_EDGES_LIST[
                  "nodeKeywordTuningExpandedToLoaderKeywordExplorerCollapsed"
                ],
            GS_EDGES_LIST[
              "loaderKeywordExplorerCollapsedToNodeKeywordExplorerExpanded"
            ],
          ];
        });
        dispatch(
          setNodeCollapse({
            type: "loaderKeywordExplorerCollapsed",
            collapse: true,
          })
        );
        changePosition(1, setNodes, {
          ...loaderState,
          loaderKeywordExplorerCollapsed: true,
        });
        dispatch(
          setCurrentPage(activePageLocalInt > 6 ? activePageLocalInt : 7)
        );
      },
      textBtn: "Continue",
      position: { x: 2700, y: 240 },
    },
  },
  loaderKeywordExplorerCollapsed: {
    id: "loaderKeywordExplorerCollapsed",
    type: "loaderKeywordExplorerCollapsed",
    data: {
      page: 5,
      label: "Keyword Explorer Loader Collapsed",
      position: { x: 2500, y: 200 },
    },
    position: { x: 2500, y: 200 },
    className: "light",
    style: {
      display: "flex",
      width: "2.90rem",
      height: "2.90rem",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50px",
      backgroundColor: "#fff",
      boxShadow: "0px 4.83018px 16.9056px rgba(0, 0, 0, 0.05)",
    },
    targetPosition: "left",
    sourcePosition: "right",
    draggable: false,
  },
  nodeKeywordExplorerExpanded: {
    id: "nodeKeywordExplorerExpanded",
    type: "nodeKeywordExplorerExpanded",
    position: { x: 3000, y: 0 },
    className: "light",
    style: {
      width: "63rem",
      height: "40rem",
      backgroundColor: "#FFF",
      borderRadius: "20px",
    },
    sourcePosition: "right",
    draggable: false,
    data: {
      index: 7,
      page: 6,
      label: "Keyword Explorer Expanded",
      position: { x: 4700, y: 10 },
      disabledFields: false,
    },
  },
};
