import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  CircularProgress,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { LightTooltip } from "components/CreativeAI_Revamp/shared";
import { Button, CheckBox, StyledText } from "components/shared";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postExecution,
  setAutoExecution,
} from "store/actions/ActionDebugger/CreativeRotation/creativeRotation.actions";
import {
  CreativeRotationInitialState,
  PostExecutionItemPayload,
} from "store/types/ActionDebugger/CreativeRotation/creativeRotation.types";
import { AdAccountState } from "store/types/adaccount";
import { getPlatformName, getRolesHierarchy } from "utils/commonFunctions";
import { mixPanelAdEvent } from "utils/mixpanel";
import { RolesWrapper } from "utils/rolesWrapper";
import LoadGif from "../../../assets/Gif/Loaded.gif";
import { ReactComponent as FilterIcon } from "../../../assets/svg/Filter-new.svg";
import { ReactComponent as ArrowDownIcon } from "../../../assets/svg/arrowdown.svg";
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";
import DropdownUI from "../../../ui/Dropdown/Dropdown";
import AdvancedConfiguration from "./components/AdvancedConfiguration";
import CRAccordionItem, {
  CRAccordionItemLoader,
} from "./components/CRAccordionItem";
import IntroPopup from "./components/IntroPopup";
import NoRecommendationModal from "./components/NoRecommendationModal";
import RejectionModal from "./components/RejectionModal";
import { useCreativeRotation } from "./hooks/useCreativeRotation";
import { StyledCreativeRotationMainBox, StyledOverflowBox } from "./styled";

export interface BatchSelectionType {
  [key: string]: string[];
}

export default function CreativeRotation() {
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showAdvanceSettingOptions, setShowAdvanceSettingOptions] =
    useState(false);
  const [showAutoApproveIntro, setShowAutoApproveIntro] = useState(
    !localStorage.getItem("gotAutoApprove")
  );
  const [showNoRecommendationIntro, setShowNoRecommendationIntro] = useState(
    !localStorage.getItem("gotNoRecommendation")
  );
  const [openedAdvancedSettingOption, setOpenedAdvancedSettingOption] =
    useState(-1);
  const { nonRecommendedAds }: any = useSelector(
    (state: { creativeRotationReducer: CreativeRotationInitialState }) => {
      return state.creativeRotationReducer;
    }
  );

  const handleRejectModelClose = () => {
    setShowRejectModal(false);
  };
  const {
    batchList,
    elementId,
    isApproveExecution,
    isRejectExecution,
    setPageNumber,
    pageNumber,
    batchSelection,
    setBatchSelection,
    disableAdvanceSetting,
    expanded,
    setExpanded,
    autoApprove,
    setAutoApprove,
    refreshDataCallback,
    loadingNonRecommendedAds,
    selectedAdsets,
    setSelectedAdsets,
    unexpiredBatchData,
  } = useCreativeRotation();

  const advancedSettingOptions: any = [
    {
      heading: "Model Tuning Parameters",
      subHeading: "You can tune model output by changing these params",
      disable: disableAdvanceSetting,
    },
    {
      heading: `No recommendation list (${nonRecommendedAds?.length} ad${nonRecommendedAds?.length > 1 ? "s" : ""
        })`,
      subHeading: "List of ads that you dont want recommendations on",
      disable: loadingNonRecommendedAds,
      loading: loadingNonRecommendedAds,
    },
  ];

  const dispatch = useDispatch();
  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;
  console.log("LL: brandRoles", brandRoles);

  // const isBrandViewer = useMemo(
  //   () => brandRoles?.length === 1 && brandRoles[0]?.role === "brand-viewer",
  //   [brandRoles]
  // );
  const { user } = useAuth0();

  const {
    selectedBrand,
    selectedAdAccount,
    selectedGroup,
    selectedPlatform,
    selecteGroupAdset,
  } = adaccount;

  return (
    <StyledCreativeRotationMainBox>
      <Box display="flex" justifyContent="space-between" pb="1.125em">
        {/* <SearchFilter /> */}
        <Box />
        <Box display="flex" gap="10px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="40px"
            width="40px"
            border="1px solid #E0E3E5"
            borderRadius="0.25rem"
            sx={{ cursor: "pointer" }}
          >
            <FilterDropdown
              setShowFilterDropdown={setShowFilterDropdown}
              showFilterDropdown={showFilterDropdown}
              allAdsets={selecteGroupAdset}
              selectedAdsets={selectedAdsets}
              setSelectedAdsets={setSelectedAdsets}
            />
          </Box>
          <IntroPopup
            heading={"Introducing ‘No-Recommendation List’"}
            subHeading={"A list of ads you dont want recommendations for"}
            intro={
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: "400",
                  width: "299px",
                  color: "#5C6674",
                }}
              >
                Now, by adding a list of ads to{" "}
                <span style={{ fontWeight: "700" }}>
                  No-Recommendation List
                </span>{" "}
                in advanced settings, you won’t have any actions being
                recommended on those ads. You can always remove them from this
                list to rotate them.
              </Typography>
            }
            open={showNoRecommendationIntro}
            onButtonClick={() => {
              setShowNoRecommendationIntro(false);
              localStorage.setItem(
                "gotNoRecommendation",
                new Date().toLocaleDateString()
              );
            }}
            placement="left"
          >
            <Box>
              <DropdownUI
                className="ml-2 dropdown-up-ui hoverable customise-column"
                isDropDownVisible={showAdvanceSettingOptions}
                setIsDropDownVisible={setShowAdvanceSettingOptions}
                toggelButton={
                  <Button
                    style={{ margin: "0" }}
                    onClick={() =>
                      setShowAdvanceSettingOptions(!showAdvanceSettingOptions)
                    }
                    sx={{
                      fontSize: "1em",
                      height: "40px",
                      fontWeight: "600",
                      padding: "0.5em 1.125em",
                      gap: "0.5rem",
                      border: "1px solid #E0E3E5",
                      borderRadius: "0.5rem",
                      color: "black",
                    }}
                  >
                    Advanced Setting
                    <ArrowDownIcon />
                  </Button>
                }
              >
                <Box sx={{ padding: "0.75rem", width: "max-content" }}>
                  {advancedSettingOptions.map((item: any, index: number) => (
                    <Box
                      sx={{
                        padding: "1rem",
                        borderRadius: "0.5rem",
                        opacity: item.disable ? 0.5 : 1,
                        pointerEvents: item.disable ? "none" : "all",
                        ":hover": { background: "#E3EEFF" },
                      }}
                      onClick={() => {
                        setOpenedAdvancedSettingOption(index);
                        setShowAdvanceSettingOptions(false);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "0.875rem", fontWeight: "400" }}
                        >
                          {item?.heading}
                        </Typography>
                        {item?.loading && (
                          <img
                            src={LoadGif}
                            style={{ height: 20, width: 20 }}
                          />
                        )}
                      </div>
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: "400",
                          color: "#5C6674",
                        }}
                      >
                        {item?.subHeading}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </DropdownUI>
            </Box>
          </IntroPopup>
          {/* Commenting this out as it can be used later when enabled from EE */}
          {/* <IntroPopup
            heading={"Introducing ‘Auto-Approve’"}
            subHeading={"Execute actions without approval"}
            intro={
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: "400",
                  width: "299px",
                  color: "#5C6674",
                }}
              >
                You dont have have to approve each action any more! When turned
                on, approve & disapprove buttons will be disabled & all actions
                will start getting executed automatically.
              </Typography>
            }
            open={showAutoApproveIntro}
            onButtonClick={() => {
              setShowAutoApproveIntro(false);
              localStorage.setItem(
                "gotAutoApprove",
                new Date().toLocaleDateString()
              );
            }}
            placement="top"
          >
            <RolesWrapper
              show={true}
              // disabled={isViewerRole(brandRoles) || isEditorRole(brandRoles)}
              disabled={
                getRolesHierarchy(brandRoles) === "viewer" ||
                getRolesHierarchy(brandRoles) === "editor"
              }
            >
              <Box>
                <LightTooltip
                  title={
                    !autoApprove &&
                    "Once turned ON, all recommendations including the latest ones will be executed automatically, if not already executed"
                  }
                >
                  <Box
                    sx={{
                      padding: "0em 1.125em",
                      display: "flex",
                      gap: "0.5rem",
                      border: "1px solid #E0E3E5",
                      borderRadius: "0.5rem",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "1em", fontWeight: "600" }}>
                      Auto Approve
                    </Typography>
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setAutoApprove(!autoApprove);
                        dispatch(
                          setAutoExecution({
                            value: !autoApprove,
                            elementId: elementId,
                            callback: refreshDataCallback.callback,
                          })
                        );
                      }}
                    >
                      <AntSwitch checked={autoApprove} />
                    </Box>
                  </Box>
                </LightTooltip>
              </Box>
            </RolesWrapper>
          </IntroPopup> */}
          <RolesWrapper
            show={true}
            // disabled={isViewerRole(brandRoles) || isEditorRole(brandRoles)}
            disabled={
              getRolesHierarchy(brandRoles) === "viewer" ||
              getRolesHierarchy(brandRoles) === "editor"
            }
          >
            <LightTooltip
              title={
                autoApprove
                  ? "Disable Auto-Approve to approve actions"
                  : // : // : isBrandViewer
                  // isViewerRole(brandRoles)
                  // ? brandViewerAccessText
                  ""
              }
            >
              <Box>
                <Button
                  style={{
                    // cursor: isBrandViewer ? "not-allowed" : "pointer",
                    cursor: "pointer",
                    //  isViewerRole(brandRoles)
                    //   ? "not-allowed"
                    //   :
                    margin: "0px",
                  }}
                  sx={{
                    background: "#0869FB",
                    borderRadius: ".5em",
                    padding: "0.875em 1.125em",
                    opacity:
                      batchList?.isLoading ||
                        batchList?.data?.length === 0 ||
                        batchSelection?.length === 0 ||
                        autoApprove ||
                        isApproveExecution.isLoading ||
                        isRejectExecution.isLoading
                        ? 0.5
                        : 1,
                    pointerEvents:
                      batchList?.isLoading ||
                        batchList?.data?.length === 0 ||
                        autoApprove ||
                        batchSelection?.length === 0 ||
                        isApproveExecution.isLoading ||
                        isRejectExecution.isLoading
                        ? "none"
                        : "all",
                    height: "40px",
                    "&:hover": {
                      backgroundColor: "#86A7D8",
                    },
                  }}
                  onClick={
                    // isBrandViewer
                    // isViewerRole(brandRoles)
                    //   ? undefined
                    //   :
                    () => {
                      const body: PostExecutionItemPayload[] =
                        batchSelection.map((item) => ({
                          recommendationId: item,
                          userAction: "APPROVED",
                        }));
                      dispatch(
                        postExecution({
                          elementId,
                          body,
                          userAction: "APPROVED",
                        })
                      );
                      mixPanelAdEvent("CAI_Fb - Approval Flow Action", {
                        username: user?.email,
                        agency_id: selectedBrand?.agencyId,
                        agency_name: selectedBrand?.agency_name,
                        brand_id: selectedBrand?.id,
                        brand_name: selectedBrand?.name,
                        brand_type: selectedBrand?.brandType,
                        ad_account_id: selectedAdAccount?.id,
                        ad_account_name: selectedAdAccount?.name,
                        ai_group_name: selectedGroup?.name,
                        platform_name: getPlatformName(
                          selectedPlatform?.name
                        ),
                        number_of_actions: batchSelection?.length,
                      });
                      setBatchSelection([]);
                    }
                  }
                >
                  {isApproveExecution.isLoading ? (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress
                        sx={{
                          color: "white",
                        }}
                        size={20}
                      />
                    </Box>
                  ) : (
                    <StyledText
                      fontSize="1em"
                      lineHeight="1.5em"
                      color="#FFFFFF"
                    >
                      {`    Approve
              ${!batchList?.isLoading &&
                          batchList?.data?.length !== 0 &&
                          batchSelection?.length !== 0
                          ? batchSelection?.length
                          : ""
                        }
              Actions`}
                    </StyledText>
                  )}
                </Button>
              </Box>
            </LightTooltip>
          </RolesWrapper>
          <RolesWrapper
            show={true}
            // disabled={isViewerRole(brandRoles) || isEditorRole(brandRoles)}
            disabled={
              getRolesHierarchy(brandRoles) === "viewer" ||
              getRolesHierarchy(brandRoles) === "editor"
            }
          >
            <LightTooltip
              title={
                autoApprove ? "Disable Auto-Approve to reject actions" : ""
              }
            >
              <Box>
                <Button
                  style={{ margin: "0" }}
                  sx={{
                    background: "#E84B47",
                    borderRadius: ".5em",
                    padding: "0.875em 1.125em",
                    opacity:
                      batchList?.isLoading ||
                        batchList?.data?.length === 0 ||
                        batchSelection?.length === 0 ||
                        autoApprove ||
                        isRejectExecution.isLoading ||
                        isRejectExecution.isLoading
                        ? 0.5
                        : 1,
                    pointerEvents:
                      batchList?.isLoading ||
                        batchList?.data?.length === 0 ||
                        batchSelection?.length === 0 ||
                        autoApprove ||
                        isRejectExecution.isLoading ||
                        isRejectExecution.isLoading
                        ? "none"
                        : "all",
                    height: "40px",
                    "&:hover": {
                      backgroundColor: "#e37876",
                    },
                  }}
                  onClick={() => {
                    setShowRejectModal(true);
                  }}
                >
                  <StyledText fontSize="1em" lineHeight="1.5em" color="#FFFFFF">
                    {`    Reject
              ${!batchList?.isLoading &&
                        batchList?.data?.length !== 0 &&
                        batchSelection?.length !== 0
                        ? batchSelection?.length
                        : ""
                      }
              Actions`}
                  </StyledText>
                </Button>
              </Box>
            </LightTooltip>
          </RolesWrapper>
        </Box>
      </Box>
      {!batchList.isLoading && batchList?.data?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          flex={1}
          alignItems="center"
        >
          <StyledText fontSize="1em" lineHeight="1.5em" color="#000000">
            No recommendation data found
          </StyledText>
        </Box>
      ) : (
        <StyledOverflowBox>
          {batchList?.isLoading && batchList?.data?.length === 0 ? (
            <CRAccordionItemLoader />
          ) : (
            batchList.data.map((item, index) => (
              <CRAccordionItem
                expanded={expanded}
                setExpanded={setExpanded}
                index={index}
                key={item.batch_id + item.asset_l1_id}
                batchItem={item}
                elementId={elementId}
                selected={batchSelection}
                setBatchSelection={setBatchSelection}
                isExpired={!(item.pipeline_id==unexpiredBatchData.pipeline_id)}
                autoExecute={autoApprove}
              />
            ))
          )}
          {batchList.haveNextPage &&
            (!selectedAdsets.length ||
              batchList.totalPage
                ) && ( //changes needed as per new reqs
              // (batchList?.isLoading && batchList?.data?.length > 0)) && (
              <Button
                sx={{
                  background: "#0869FB",
                  borderRadius: ".5em",
                  padding: "0.875em 1.125em",
                  margin: ".5em 0em",
                  width: "11em",
                  opacity: batchList.isLoading ? 0.5 : 1,
                  pointerEvents: batchList.isLoading ? "none" : "all",
                  height: "40px",
                  "&:hover": {
                    backgroundColor: "#5d91de",
                  },
                  "&.Mui-disabled":{
                    opacity:0.5
                  }
                }}
                onClick={() => {
                  setPageNumber((prev) =>
                    selectedAdsets.length ? prev + 1 : prev - 1
                  );
                  mixPanelAdEvent("Creative Rotation - Load More", {
                    username: user?.email,
                    agency_name: selectedBrand?.agency_name,
                    brand_name: selectedBrand?.name,
                    ad_account_id: selectedAdAccount?.id,
                    ad_account_name: selectedAdAccount?.name,
                    ai_group_name: selectedGroup?.name,
                    platform_name: getPlatformName(selectedPlatform?.name),
                  });
                }}
                disabled={!(batchList.totalPage && batchList.totalPage > pageNumber + 1)|| false}
              >
                {batchList.isLoading ? (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress
                      sx={{
                        color: "white",
                      }}
                      size={20}
                    />
                  </Box>
                ) : (
                  <StyledText fontSize="1em" lineHeight="1.5em" color="#FFFFFF">
                    Load More
                  </StyledText>
                )}
              </Button>
            )}
        </StyledOverflowBox>
      )}
      {openedAdvancedSettingOption === 0 && (
        <AdvancedConfiguration
          showAdvanceSettings={openedAdvancedSettingOption === 0}
          onAdvanceSettingClose={() => setOpenedAdvancedSettingOption(-1)}
        />
      )}
      {openedAdvancedSettingOption === 1 && (
        <NoRecommendationModal
          showNoRecommendation={openedAdvancedSettingOption === 1}
          handleClose={() => setOpenedAdvancedSettingOption(-1)}
          batchList={batchList}
          elementId={elementId}
        />
      )}
      {showRejectModal && (
        <RejectionModal
          onClose={handleRejectModelClose}
          showRejectModal={showRejectModal}
          selected={batchSelection}
          setBatchSelection={setBatchSelection}
        />
      )}
    </StyledCreativeRotationMainBox>
  );
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"]),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const FilterDropdown = ({
  showFilterDropdown,
  setShowFilterDropdown,
  allAdsets,
  selectedAdsets,
  setSelectedAdsets,
}: any) => {
  const [localSelectedAds, setLocalSelectedAds] = useState([...selectedAdsets]);
  const [searchedAds, setSearchedAds] = useState([...allAdsets]);
  const [searchedTerm, setSearchedTerm] = useState("");
    useEffect(() => {
      setLocalSelectedAds([...selectedAdsets]);
    }, [selectedAdsets]);
  
      useEffect(() => {
      setSearchedAds([...allAdsets]);
    }, [allAdsets]);

    useEffect(() => {
      if (searchedTerm)
        setSearchedAds(
          allAdsets.filter(
            (i: any) =>
              i.name.toLowerCase().includes(searchedTerm.toLowerCase()) ||
              i.adsetId.toLowerCase().includes(searchedTerm.toLowerCase())
          )
        );
      else setSearchedAds([...allAdsets]);
    }, [searchedTerm]);

    useEffect(()=>{setSearchedTerm('')},[showFilterDropdown])

  return (
    <DropdownUI
      className="dropdown-up-ui hoverable customise-column"
      isDropDownVisible={showFilterDropdown}
      setIsDropDownVisible={setShowFilterDropdown}
      dropdownItemStyle={{ right: "0", maxHeight: "54vh", minHeight: "30vh" }}
      toggelButton={
        <Tooltip
          title="You can filter your recommendations on the basis of ad sets from here"
          arrow
          placement="top"
        >
          <FilterIcon style={{ cursor: "pointer" }} />
        </Tooltip>
      }
    >
      <Box
        sx={{
          padding: "0.75rem",
          width: "400px",
          minHeight: "30vh",
          height: "100%",
        }}
      >
        <Box
          sx={{
            minHeight: "30vh",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "0.875rem", fontWeight: "600" }}>
              Filter By Ad Sets
            </Typography>

            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              mt="0.5rem"
            >
              <TextField
                onChange={(e: any) => setSearchedTerm(e.target.value)}
                value={searchedTerm}
                sx={{
                  width: "90%",
                  float: "right",
                  "& .MuiOutlinedInput-input": {
                    padding: 0,
                    height: "2rem",
                  },
                  "& .MuiOutlinedInput-input::placeholder": {
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "italic",
                    fontWeight: "500",
                    lineHeight: "20px",
                    letterSpacing: "0px",
                    textAlign: "left",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={"Search"}
              />
            </Box>
            <Box
              sx={{
                maxHeight: "35.5vh",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {searchedAds?.map((adset: any, index: number) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "1rem 0 0",
                  }}
                >
                  <CheckBox
                    checked={localSelectedAds?.includes(adset.adsetId)}
                    onClick={() => {
                      localSelectedAds?.includes(adset.adsetId)
                        ? setLocalSelectedAds(
                          localSelectedAds.filter((i) => i != adset.adsetId)
                        )
                        : setLocalSelectedAds([
                          ...localSelectedAds,
                          adset.adsetId,
                        ]);
                    }}
                  />
                  <Box>
                    <Tooltip title={adset.name} placement="top" arrow>
                      <Typography
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: "600",
                          maxWidth: "332px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "pre",
                        }}
                      >
                        {adset.name}
                      </Typography>
                    </Tooltip>
                    <Typography
                      sx={{
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        color: "#5C6674",
                        maxWidth: "370px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "pre",
                      }}
                    >
                      {adset.adsetId}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "12px",
              display: "flex",
              justifyContent: "end",
              gap: "1rem",
            }}
          >
            <Button
              sx={{
                fontSize: "0.75rem",
                fontWeight: "600",
                padding: "14pxrem 24pxrem",
                borderRadius: "0.375rem",
                border: "1.5px solid #E0E3E5",
                color: "#000",
              }}
              onClick={() => {
                // setSelectedAdsets(selectedAdsets)
                setShowFilterDropdown(false);
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                fontSize: "0.75rem",
                fontWeight: "600",
                padding: "14pxrem 24pxrem",
                background: "#0869FB",
                color: "#fff",
                ":hover": { background: "#0869FB" },
              }}
              onClick={() => {
                setSelectedAdsets(localSelectedAds);
                setShowFilterDropdown(false);
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
    </DropdownUI>
  );
};
