import {
  GetMediaData,
  GetMediaList,
  GET_MEDIA_DATA,
  GET_MEDIA_LIST,
  MediaStudioAdminState,
  ON_SAVE_UPDATE,
  SetMediaList,
  SET_FETCH_DATA_LOADING,
  SET_FETCH_LOADING,
  SET_MEDIA_DATA,
  SET_MEDIA_LIST,
  SET_UPLOAD_TOGGLE,
} from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";

const initialState: MediaStudioAdminState | any = {
  mediaList: [],
  fetchinMediaList: false,
  fetchingMediaData: false,
  mediaData: {},
  addingMediaStudio: false,
  isMediaUploading: false,
};

const mediaStudioAdminReducer = (
  state = initialState,
  action: SetMediaList
) => {
  switch (action.type) {
    case GET_MEDIA_LIST: {
      return {
        ...state,
        fetchingMediaList: true,
      };
    }
    case GET_MEDIA_DATA: {
      return {
        ...state,
        fetchingMediaData: true,
      };
    }
    case ON_SAVE_UPDATE: {
      return {
        ...state,
        addingMediaStudio: action.payload,
      };
    }
    case SET_FETCH_LOADING: {
      return {
        ...state,
        fetchingMediaList: false,
      };
    }
    case SET_FETCH_DATA_LOADING: {
      return {
        ...state,
        fetchingMediaData: false,
      };
    }
    case SET_MEDIA_LIST: {
      return {
        ...state,
        mediaList: action.payload,
      };
    }
    case SET_MEDIA_DATA: {
      return {
        ...state,
        mediaData: action.payload,
      };
    }
    case SET_UPLOAD_TOGGLE: {
      return {
        ...state,
        isMediaUploading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default mediaStudioAdminReducer;
