export const SET_MONTAGE = "SET_MONTAGE";
export const GET_MONTAGE = "GET_MONTAGE";
export const POST_MONTAGE_ADS = "POST_MONTAGE_ADS";
export const GET_MONTAGE_CAMPAIGNS = "GET_MONTAGE_CAMPAIGNS";
export const GET_S3_URL = "GET_S3_URL";
export const UPLOAD_TO_S3_URL = "UPLOAD_TO_S3_URL";
export const SET_MONTAGE_CAMPAIGNS = "SET_MONTAGE_CAMPAIGNS";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const TOGGLE_VIDEO_PANEL = "TOGGLE_VIDEO_PANEL";
export const GET_MONTAGE_COUNTRY = "GET_MONTAGE_COUNTRY";
export const SET_SELECTED_MONTAGE_CAMPAIGN = "SET_SELECTED_MONTAGE_CAMPAIGN"

export type MontageState = {
    montageAdsCampaigns: any;
    montageCampaigns:MontageCampaigns[],
    toggleVideoPanel:Boolean,
    selectedMontageCampaign:any
}

export type MontageCampaigns = {
    id?: string,
    name: string,
    creativesCount?: any,
    productName: string,
    productCategory: string,
    productValue?: any,
    productCurrency: String,
    productUrl: string,
    productFiles: any[],
    targetGender: string,
    targetAgeMin: number,
    targetAgeMax: number,
    country: string,
    language: string,
    interests: string,
    videoLayout: string,
    videoLength: string,
    videoStyle: string,
    contentGuidelinesDos: any[],
    contentGuidelinesDonts: any[],
    styleGuide: string,
    brandId?: string,
    videos?: any[],
    createdAt?: string,
    updatedAt?: string
}

export type Downloading = {
    [key: number]: boolean;
  }
export type SetDefaultValues={
    type: typeof SET_MONTAGE,
    payload:any
}

export type GetMontageValues = {
    type: typeof GET_MONTAGE
    callback: Function;
};

export type GetMontageCountry = {
    type: typeof GET_MONTAGE_COUNTRY
    callback: Function;
};

export type PostMontageAds={
    type: typeof POST_MONTAGE_ADS,
    payload: {
        selectedBrandId: string;
        user: any;
        payload: any;},
    callback?: Function;
};

export type GetMontageCampaigns = {
    type: typeof GET_MONTAGE_CAMPAIGNS;
    payload: {
        brandId: string;
        user: any;
    };
    callback?: Function
}

export type GetS3Url = {
    type: typeof GET_S3_URL;
    payload: {
        mimetype: string;
    };
    callback?: Function
}

export type UploadToS3Url = {
    type: typeof UPLOAD_TO_S3_URL;
    payload: {
        url: string;
        file: any;
        user : any;
    };
    callback?: Function
}

export type DeleteCampaign = {
    type: typeof DELETE_CAMPAIGN;
    payload:{
        campaignId: string;
        user: any;
    };
    callback?: Function;
}

export type GetCampaign = {
    type: typeof GET_CAMPAIGN;
    payload:{
        campaignId: string;
        user: any;
    };
    callback?: Function;
}

export type SetMontageCampaigns = {
    type: typeof SET_MONTAGE_CAMPAIGNS;
    payload: MontageCampaigns[];
}

export type ToggleVideoPanel = {
    type: typeof TOGGLE_VIDEO_PANEL;
    payload: Boolean;
}

export type SetSelectedMontageCampaign = {
    type: typeof SET_SELECTED_MONTAGE_CAMPAIGN;
    payload: MontageCampaigns;
}
