import HttpService from "HttpService/HttpService";
import {
  call,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  createImageAPIHashResponse,
  getMediaStudioForAdResponse,
  setMediaStudioRequestStatus,
} from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import { SET__SELECTED_ADS } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import {
  CreateImageAPIHash,
  CREATE_IMAGE_API_HASH,
  DeleteImageAPIHash,
  DELETE_IMAGE_API_HASH,
  GetFacebookImageUrl,
  GetFacebookImageUrlForAssetFeedSpec,
  GetFacebookVideoUrl,
  GetImageAPIHash,
  GetMediaStudioForAd,
  GetMediaStudioProcessing,
  GET_FACEBOOK_IMAGE_URL,
  GET_FACEBOOK_VIDEO_URL,
  GET_FB_IMAGE_URL_ASSET_FEED_SPEC,
  GET_IMAGE_API_HASH,
  GET_MEDIA_STUDIO_FOR_AD,
  GET_MEDIA_STUDIO_PROCESSING,
  MEDIA_STUDIO_IMAGE_PROCESSING_SUCCESS,
  PostMediaStudioProcessing,
  POST_MEDIA_STUDIO_PROCESSING,
  RequestMediaStudio,
  RequestMediaStudioStatus,
  REQUEST_MEDIA_STUDIO,
  REQUEST_MEDIA_STUDIO_STATUS,
  SET_MEDIA_STUDIO_REQUEST_STATUS,
} from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { BASE_URL } from "utils/path";

const apiVersion = "v2";

function* createImageAPIHash(action: CreateImageAPIHash): any {
  const {
    payload: { adId, text },
  } = action;
  try {
    const urlParams = `/v1/api/media-studios/ads/${adId}/fb-hash`;
    const response = yield call(HttpService.post, BASE_URL, urlParams, "", {
      text,
    });
    if (response.data.success) {
      put(createImageAPIHashResponse("ok"));
    } else {
      // handle failure
    }
  } catch (error: any) {
    // handle failure
  }
}

function* getImageAPIHash(action: GetImageAPIHash): any {
  const { payload: reqIds } = action;
  try {
    const urlParams = `/v1/api/media-studios/fb-hash/${reqIds}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      put(createImageAPIHashResponse("ok"));
    } else {
      // handle failure
    }
  } catch (error: any) {
    // handle failure
  }
}

function* deleteImageAPIHash(action: DeleteImageAPIHash): any {
  const { payload: hashes } = action;
  try {
    const urlParams = `/v1/api/media-studios/fb-hash`;
    const response = yield call(HttpService.delete, BASE_URL, urlParams, "", {
      hashes,
    });
    if (response.data.success) {
      put(createImageAPIHashResponse("ok"));
    } else {
      // handle failure
    }
  } catch (error: any) {
    // handle failure
  }
}

function* requestMediaStudio(action: RequestMediaStudio): any {
  const {
    payload: { adId, brandId, payload },
  } = action;
  try {
    const urlParams = `/v1/api/media-studios/ads/${adId}/brands/${brandId}/request`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      "",
      payload
    );
    if (response.data.success) {
      yield put({
        type: SET_MEDIA_STUDIO_REQUEST_STATUS,
        payload: response.data.success,
      });
    } else {
      // handle failure
    }
  } catch (error: any) {
    // handle failure
  }
}

function* requestMediaStudioStatus(action: RequestMediaStudioStatus): any {
  const {
    payload: { adId, brandId },
  } = action;
  try {
    const urlParams = `/v1/api/media-studios/ads/${adId}/brands/${brandId}/request/status`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (response.data.success) {
      yield put({
        type: SET_MEDIA_STUDIO_REQUEST_STATUS,
        payload: response.data?.data ? true : false,
      });
    } else {
      // handle failure
    }
  } catch (error: any) {
    // handle failure
  }
}

function* getMediaStudioForAd(action: GetMediaStudioForAd): any {
  const {
    payload: { adId, brandId, finalAction },
  } = action;
  try {
    const urlParams = `/v1/api/media-studios/ads/${adId}/brands/${brandId}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (response.data.success) {
      yield yield put(
        getMediaStudioForAdResponse(
          response.data?.data
            ? { data: response.data?.data, isData: true, isLoading: false }
            : { isData: false, isLoading: false }
        )
      );
      finalAction && finalAction(response.data?.data, false);
    } else {
      yield yield put(
        getMediaStudioForAdResponse({ isData: false, isLoading: false })
      );
      finalAction && finalAction({}, true);
    }
  } catch (error: any) {
    yield yield put(
      getMediaStudioForAdResponse({ isData: false, isLoading: false })
    );
    finalAction && finalAction({}, true);
  }
}

function* postMediaStudioProcessingSaga(
  action: PostMediaStudioProcessing
): any {
  const {
    payload: {
      adId,
      variations,
      reduxActionType,
      failureReduxType,
      reduxStoreType,
      finalAction,
      frameIndex,
    },
  } = action;
  yield put({
    type: reduxActionType,
    payload: true,
  });
  yield put({
    type: failureReduxType,
    payload: false,
  });
  try {
    let urlParams = `/v1/api/media-studios/ads/${adId}/media`;
    if (frameIndex !== undefined) {
      urlParams = urlParams + `?frameId=${frameIndex}`;
    }
    const response = yield call(HttpService.post, BASE_URL, urlParams, "", {
      variations,
    });
    if (response.data.success) {
      yield put({
        type: reduxStoreType,
        payload:
          frameIndex !== undefined
            ? { ids: response.data.data.ids, frameIndex }
            : response.data.data.ids,
      });
      finalAction && finalAction(response.data.data.ids, false);
    } else {
      finalAction && finalAction({}, true);
      yield put({
        type: reduxActionType,
        payload: false,
      });
      yield put({
        type: failureReduxType,
        payload: true,
      });
    }
  } catch (error: any) {
    console.log("error occoured", error);
    finalAction && finalAction({}, true);
    yield put({
      type: reduxActionType,
      payload: false,
    });
    yield put({
      type: failureReduxType,
      payload: true,
    });
  }
}

function* getMediaStudioProcessingSaga(action: GetMediaStudioProcessing): any {
  const {
    payload: { reqId, failureReduxType, reduxActionType, type },
    callback,
  } = action;

  try {
    let urlParams = `/v1/api/media-studios/media/${reqId.join(",")}`;
    if (type) {
      urlParams += "?type=billboard";
    }

    const { response, cancel } = yield race({
      response: call(HttpService.get, BASE_URL, urlParams, ""),
      cancel: take(SET__SELECTED_ADS),
    });
    if (!cancel) {
      if (response.data.success) {
        yield put({
          type: MEDIA_STUDIO_IMAGE_PROCESSING_SUCCESS,
          payload: true,
        });
        callback && callback(response.data.data.mediaStudioFiles);
      } else {
        yield put({
          type: reduxActionType,
          payload: false,
        });
        yield put({
          type: failureReduxType,
          payload: true,
        });
      }
    }
  } catch (error: any) {
    console.log("error occoured", error);
    yield put({
      type: reduxActionType,
      payload: false,
    });
    yield put({
      type: failureReduxType,
      payload: true,
    });
  }
}

function* getFacebookImageUrl(action: GetFacebookImageUrl): any {
  const {
    payload: { adId, callback },
  } = action;

  try {
    const urlParams = `/v1/api/media-studios/fb-page-details/${adId}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      callback &&
        callback(
          {
            avatarUrl: response.data?.data?.picture?.data?.url,
            avatarName: response.data?.data?.name,
          },
          false
        );
    } else {
      callback && callback({}, true);
      console.log("error occured at get FB Image URL");
    }
  } catch (error: any) {
    callback && callback({}, true);
    console.log("error occured at get FB Image URL", error);
  }
}

function* getFacebookVideoUrl(action: GetFacebookVideoUrl): any {
  const {
    payload: { adId, callback },
  } = action;

  try {
    const urlParams = `/v1/api/media-studios/video-details/${adId}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      callback && callback(response.data.data);
    } else {
      callback && callback({}, true);
      console.log("error occured at get FB Image URL");
    }
  } catch (error: any) {
    callback && callback({}, true);
    console.log("error occured at get FB Image URL", error);
  }
}

function* getFacebookImageUrlForAssetFeedSpec(
  action: GetFacebookImageUrlForAssetFeedSpec
): any {
  const {
    payload: { adAccountId, imageHash, callback },
  } = action;

  try {
    const urlParams = `/v1/api/media-studios/ad-accounts/${adAccountId}/image-details/${imageHash}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      callback && callback(response.data.data.data);
    } else {
      callback && callback({}, true);
      console.log("error occured at get FB Image asset feed spec URL");
    }
  } catch (error: any) {
    callback && callback({}, true);
    console.log(
      "error occured at get FB Image asset feed spec Image URL",
      error
    );
  }
}

export function* mediaStudioWatcher() {
  yield takeLatest(CREATE_IMAGE_API_HASH, createImageAPIHash);
  yield takeLatest(GET_IMAGE_API_HASH, getImageAPIHash);
  yield takeLatest(DELETE_IMAGE_API_HASH, deleteImageAPIHash);
  yield takeLatest(REQUEST_MEDIA_STUDIO, requestMediaStudio);
  yield takeLatest(REQUEST_MEDIA_STUDIO_STATUS, requestMediaStudioStatus);
  yield takeLatest(GET_MEDIA_STUDIO_FOR_AD, getMediaStudioForAd);
  yield takeEvery(POST_MEDIA_STUDIO_PROCESSING, postMediaStudioProcessingSaga);
  yield takeEvery(GET_MEDIA_STUDIO_PROCESSING, getMediaStudioProcessingSaga);
  yield takeEvery(GET_FACEBOOK_IMAGE_URL, getFacebookImageUrl);
  yield takeEvery(GET_FACEBOOK_VIDEO_URL, getFacebookVideoUrl);
  yield takeEvery(
    GET_FB_IMAGE_URL_ASSET_FEED_SPEC,
    getFacebookImageUrlForAssetFeedSpec
  );
}
