import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Grid } from "@mui/material";
import TileDropdown from "../common/TileDropdown/TileDropdown";
import Sort from "Pages/AudienceManager/common/Sort/Sort";

import { ReactComponent as Facebook } from "../../../components/SideBar/assets/Facebook.svg";
import {
  getTileOptions,
  SORT_OPTIONS,
  getTileOptionsYoutube,
} from "../constants/subheader";
import FilterWrapper from "Pages/AudienceManager/common/Filter/FilterWrapper";
import Filter from "Pages/AudienceManager/common/Filter/Filter";
import AdvanceFilter from "Pages/AudienceManager/common/Filter/AdvanceFilter";
import Stack from "@mui/material/Stack";
import SingleTile from "Pages/SmbDashboard/Components/SingleTile";
import {
  getPublishedCohorts,
  getDraftedCohorts,
  setSelectedCohortType,
  setSelectedSortType,
  setLoading,
} from "../../../store/actions/AudienceManager/index";
import { equalsCheckArray } from "utils/commonFunctions";
import {
  handlePayloadForLive,
  handlePayloadForDrafted,
} from "../utils/subheader";
import {
  TilesProps,
  AudienceManagerState,
  PublishedCohortsFilter,
  DraftedCohortFilters,
  SelectedCohortTypeEnum,
} from "../../../store/types/AudienceManager/index";
import { COHORT_TYPES } from "../constants";
import { mixPanelAdEvent } from "utils/mixpanel";
import { AdAccountState } from "store/types/adaccount";
import { useSearchParams } from "react-router-dom-v5-compat";

interface Props {
  isFilterApplied:boolean;
  setIsFilterApplied:React.Dispatch<React.SetStateAction<boolean>>;
  
}

const Subheader: React.FC<Props> = ({
  isFilterApplied,
  setIsFilterApplied,
}) => {
  const dispatch = useDispatch();
  const {
    selectedCohortType,
    isLoading,
    selectedSortType,
    selectedAiGroup,
    commonFilterData,
    publishedCohortsFilterData,
    draftedCohortsFiltersData,
    recommendedCohortsFilterData,
    length,
    selectedPlatform,
  } = useSelector(
    (state: { audienceManager: AudienceManagerState }) => state.audienceManager
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand, selectedTargetingAdaccount } = adaccount;
  const [clearAllclicked, setClearAllClicked] = useState(0);
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");

  const [cohortFilterData, setCohortFilterData] = useState({
    live: publishedCohortsFilterData,
    drafted: draftedCohortsFiltersData,
    recommended: recommendedCohortsFilterData,
  });

  useEffect(() => {
    setCohortFilterData({
      ...cohortFilterData,
      live: publishedCohortsFilterData,
      drafted: draftedCohortsFiltersData,
      recommended: recommendedCohortsFilterData,
    });
  }, [
    publishedCohortsFilterData,
    draftedCohortsFiltersData,
    recommendedCohortsFilterData,
  ]);

  const disabled =
    !!!selectedAiGroup ||
    Object.keys(cohortFilterData[selectedCohortType]).length === 0;

  const [advancedFilterData, updateAdvancedFilterData] = useState(
    cohortFilterData[selectedCohortType]
  );

  const [filterData, updateFilterData] = useState({ ...commonFilterData });
  useEffect(() => {
    updateFilterData({ ...commonFilterData });
  }, [commonFilterData]);
  const changeAdvanceFilterData = () => {
    if (!disabled) {
      updateAdvancedFilterData(cohortFilterData[selectedCohortType]);
    }
  };

  const changeAdvanceFilterDataOnClearAll = () => {
    const setDefaultSetting = (presentType: any) => {
      switch (presentType) {
        case "live":
          return publishedCohortsFilterData;
        case "drafted":
          return draftedCohortsFiltersData;
        case "recommended":
          return recommendedCohortsFilterData;
        default:
          return {};
      }
    };
    if (!disabled) {
      updateAdvancedFilterData(setDefaultSetting(selectedCohortType));
    }
  };

  useEffect(() => {
    if (!disabled) {
      updateFilterData(commonFilterData);
    }
  }, [commonFilterData]);

  useEffect(() => {
    changeAdvanceFilterData();
  }, [
    selectedCohortType,
    publishedCohortsFilterData,
    draftedCohortsFiltersData,
    recommendedCohortsFilterData,
  ]);

  const [showFilterDropdown, setShowFilterDropdown] = useState(false);

  const [selectedSortOption, setSelectedOption] = useState(selectedSortType);
  const updateSelectedOption = (item: string) => {
    dispatch(
      setSelectedSortType({ ...selectedSortOption, [selectedCohortType]: item })
    );
    setSelectedOption({ ...selectedSortOption, [selectedCohortType]: item });
  };

  const toCall: any = {
    live: getPublishedCohorts,
    drafted: getDraftedCohorts,
    recommended: getDraftedCohorts,
  };
  const isCommonChanged = () => {
    if (!equalsCheckArray(filterData.type, commonFilterData.type)) {
      return true;
    }
    if (filterData.selectedBrand.length === filterData.brands.length) {
      filterData.selectedBrand = "all";
      return true;
    }
    if (
      filterData.selectedBrandWebsite.length ===
      filterData.brand_websites.length
    ) {
      filterData.selectedBrandWebsite = "all";
      return true;
    }
    if (
      new Date(filterData.selectedDateRange?.startDate).getTime() !==
        new Date(commonFilterData.selectedDateRange?.startDate).getTime() &&
      new Date(filterData.selectedDateRange?.endDate).getTime() !==
        new Date(commonFilterData.selectedDateRange?.endDate).getTime()
    ) {
      return true;
    }
    if (
      !equalsCheckArray(
        filterData.selectedBrand,
        commonFilterData.selectedBrand
      )
    ) {
      return true;
    }
    if (
      !equalsCheckArray(filterData.selectedCohortTypes, filterData.cohorts_type)
    ) {
      return true;
    }
    if (
      !equalsCheckArray(
        filterData.selectedBrandWebsite,
        commonFilterData.selectedBrandWebsite
      )
    ) {
      return true;
    }

    return false;
  };
  const handleApplyFilter = () => {
    setIsFilterApplied(true);
    if (selectedAiGroup?.id) {
      const isCommonAffected = isCommonChanged();

      if (isCommonAffected || clearAllclicked) {
        dispatch(
          setLoading({
            live: true,
            drafted: true,
            recommended: true,
          })
        );
        dispatch(
          toCall.live({
            aiGroupId: selectedAiGroup.id,
            ...handlePayloadForLive(
              selectedSortOption[selectedCohortType],
              filterData,
              (selectedCohortType === "live"
                ? advancedFilterData
                : publishedCohortsFilterData) as PublishedCohortsFilter,
                isFilterApplied
            ),
          })
        );

        dispatch(
          toCall.drafted({
            aiGroupId: selectedAiGroup.id,
            status: "isDrafted",
            ...handlePayloadForDrafted(
              selectedSortOption[selectedCohortType],
              "drafted",
              filterData,
              (selectedCohortType === "drafted"
                ? advancedFilterData
                : draftedCohortsFiltersData) as DraftedCohortFilters,
                isFilterApplied
            ),
          })
        );
        dispatch(
          toCall.recommended({
            aiGroupId: selectedAiGroup.id,
            status: "isRecommended",
            ...handlePayloadForDrafted(
              selectedSortOption[selectedCohortType],
              "recommended",
              filterData,
              (selectedCohortType === "recommended"
                ? advancedFilterData
                : recommendedCohortsFilterData) as DraftedCohortFilters,
                isFilterApplied
            ),
          })
        );
      } else {
        dispatch(
          setLoading({
            ...isLoading,
            [selectedCohortType]: true,
          })
        );

        const payload =
          selectedCohortType === "live"
            ? handlePayloadForLive(
                selectedSortOption[selectedCohortType],
                filterData,
                advancedFilterData as PublishedCohortsFilter,
                isFilterApplied
              )
            : {
                status:
                  selectedCohortType === "drafted"
                    ? "isDrafted"
                    : "isRecommended",
                ...handlePayloadForDrafted(
                  selectedSortOption[selectedCohortType],
                  selectedCohortType,
                  filterData,
                  advancedFilterData as DraftedCohortFilters,
                  isFilterApplied
                ),
              };

        dispatch(
          toCall[selectedCohortType]({
            aiGroupId: selectedAiGroup.id,

            ...payload,
          })
        );
      }
    }
    if (clearAllclicked) {
      setCohortFilterData({
        live: publishedCohortsFilterData,
        drafted: draftedCohortsFiltersData,
        recommended: recommendedCohortsFilterData,
      });
      updateFilterData(commonFilterData);
      changeAdvanceFilterDataOnClearAll();
    }

    if (
      !disabled &&
      Object.keys(advancedFilterData).length &&
      !clearAllclicked
    ) {
      setCohortFilterData({
        ...cohortFilterData,
        [selectedCohortType]: advancedFilterData,
      });
    }
    setClearAllClicked(0);
    setShowFilterDropdown(false);
    updateFilterData({
      ...filterData,
      cohorts_type: filterData.selectedCohortTypes,
    });
  };

  useEffect(() => {
    dispatch(
      setLoading({
        ...isLoading,
        [selectedCohortType]: true,
      })
    );
    if (selectedAiGroup?.id) {
      const payload =
        selectedCohortType === "live"
          ? handlePayloadForLive(
              selectedSortOption[selectedCohortType],
              filterData,
              advancedFilterData as PublishedCohortsFilter,
              isFilterApplied
            )
          : handlePayloadForDrafted(
              selectedSortOption[selectedCohortType],
              selectedCohortType,
              filterData,
              advancedFilterData as DraftedCohortFilters,
              isFilterApplied
            );

      dispatch(
        toCall[selectedCohortType]({
          aiGroupId: selectedAiGroup.id,
          ...payload,
        })
      );
    }
  }, [selectedSortOption,isFilterApplied]);

  let filterOptions: any[] = [],
    advanceFilterOptions: any[] = [];
  if (!disabled) {
    filterOptions = [
      {
        heading: "Date Range",
        subHeading: "",
        type: "DatePicker",
        startDate: new Date(filterData.selectedDateRange?.startDate),
        endDate: new Date(filterData.selectedDateRange?.endDate),
        onChange: (value: any) => {
          setClearAllClicked(0);
          updateFilterData({
            ...filterData,
            selectedDateRange: {
              startDate: value[0].startDate,
              endDate: value[0].endDate,
            },
          });
        },
      },
      {
        heading: "Brand Name",
        subHeading: "Select input brand to view cohorts generated by it",
        type: "Dropdown",
        selectedItems: filterData.selectedBrand ?? [],
        items: filterData.brands,
        setSelectedItem: (value: any) => {
          setClearAllClicked(0);
          const valueToSet =
            typeof value === "string" ? value.split(",") : value;
          let selectedBrand = valueToSet;
          if (valueToSet.includes(0)) {
            selectedBrand =
              valueToSet.length > filterData.brands.length
                ? [filterData.brands[0]]
                : filterData.brands;
          } else {
            selectedBrand = valueToSet;
          }
          updateFilterData({
            ...filterData,
            selectedBrand,
          });
        },
      },
      {
        heading: "Brand Website",
        subHeading:
          "Select input brand website to view cohorts generated by it",
        type: "Dropdown",
        selectedItems: filterData.selectedBrandWebsite ?? [],
        items: filterData.brand_websites,
        setSelectedItem: (value: any) => {
          setClearAllClicked(0);
          const valueToSet =
            typeof value === "string" ? value.split(",") : value;
          let selectedBrandWebsite = valueToSet;
          if (valueToSet.includes(0)) {
            selectedBrandWebsite =
              valueToSet.length > filterData.brand_websites.length
                ? [filterData.brand_websites[0]]
                : filterData.brand_websites;
          } else {
            selectedBrandWebsite = valueToSet;
          }

          updateFilterData({
            ...filterData,
            selectedBrandWebsite,
          });
        },
      },
      {
        heading: "Cohort Type",
        subHeading: "Select the type of Cohort to view",
        type: "Dropdown",
        selectedItems: filterData.selectedCohortTypes ?? [...COHORT_TYPES],
        items: COHORT_TYPES,
        setSelectedItem: (value: any) => {
          setClearAllClicked(0);
          const valueToSet =
            typeof value === "string" ? value.split(",") : value;
          let selectedCohortTypes = valueToSet;
          if (valueToSet.includes(0)) {
            selectedCohortTypes =
              valueToSet.length > COHORT_TYPES.length
                ? [COHORT_TYPES[0]]
                : COHORT_TYPES;
          } else {
            selectedCohortTypes = valueToSet;
          }

          updateFilterData({
            ...filterData,
            selectedCohortTypes,
          });
        },
      },
    ];
    if (selectedCohortType === "live") {
      const newAdvanceFilterData = advancedFilterData as PublishedCohortsFilter;
      let newlist = newAdvanceFilterData?.ad_accounts ?? [];
      if (!newlist.includes("All")) {
        newlist.unshift("All");
      }
      advanceFilterOptions = [
        {
          heading: "Ad Account",
          subHeading: "Select an Ad account to view published adsets",
          type: "Dropdown",
          selectedItem: newAdvanceFilterData?.selectedAdAccount ?? "All",
          items: newlist,
          setSelectedItem: (value: any) => {
            setClearAllClicked(0);
            updateAdvancedFilterData({
              ...newAdvanceFilterData,
              selectedAdAccount: value === "All" ? null : value,
            });
          },
          visible: true,
        },
        {
          type: "RangeSlider",
          heading: "Spends",
          min: newAdvanceFilterData?.spends?.min ?? 0,
          max: newAdvanceFilterData?.spends?.max ?? 0,
          selected: newAdvanceFilterData?.spends?.selected ?? [
            newAdvanceFilterData?.spends?.min ?? 0,
            newAdvanceFilterData?.spends?.max ?? 0,
          ],
          onChange: (val: number[]) => {
            setClearAllClicked(0);
            updateAdvancedFilterData({
              ...advancedFilterData,
              spends: {
                ...newAdvanceFilterData.spends,
                selected: [...val],
              },
            });
          },
        },
        {
          type: "RangeSlider",
          heading: "Results",
          min: newAdvanceFilterData?.results?.min ?? 0,
          max: newAdvanceFilterData?.results?.max ?? 0,
          selected: newAdvanceFilterData?.results?.selected ?? [
            newAdvanceFilterData?.results?.min ?? 0,
            newAdvanceFilterData?.results?.max ?? 0,
          ],
          onChange: (val: number[]) => {
            setClearAllClicked(0);
            updateAdvancedFilterData({
              ...advancedFilterData,
              results: {
                ...newAdvanceFilterData.results,
                selected: [...val],
              },
            });
          },
        },
        {
          type: "RangeSlider",
          heading: "CPR",
          min: newAdvanceFilterData?.cpr?.min ?? 0,
          max: newAdvanceFilterData?.cpr?.max ?? 0,
          selected: newAdvanceFilterData?.cpr?.selected ?? [
            newAdvanceFilterData?.cpr?.min ?? 0,
            newAdvanceFilterData?.cpr?.max ?? 0,
          ],
          onChange: (val: number[]) => {
            setClearAllClicked(0);
            updateAdvancedFilterData({
              ...advancedFilterData,
              cpr: {
                ...newAdvanceFilterData.cpr,
                selected: [...val],
              },
            });
          },
        },
      ];
    } else {
      const newAdvanceFilterData = advancedFilterData as DraftedCohortFilters;

      advanceFilterOptions = [
        {
          type: "RangeSlider",
          heading: "Audience Size",
          min: newAdvanceFilterData?.audience_size?.min ?? 0,
          max: newAdvanceFilterData?.audience_size?.max ?? 0,
          selected: newAdvanceFilterData?.audience_size?.selected ?? [
            newAdvanceFilterData?.audience_size?.min ?? 0,
            newAdvanceFilterData?.audience_size?.max ?? 0,
          ],
          onChange: (val: number[]) => {
            setClearAllClicked(0);
            updateAdvancedFilterData({
              ...advancedFilterData,
              audience_size: {
                ...newAdvanceFilterData.audience_size,
                selected: [...val],
              },
            });
          },
        },
        {
          type: "RangeSlider",
          heading: "Relevence Score",
          min: newAdvanceFilterData?.relevance_score?.min ?? 0,
          max: newAdvanceFilterData?.relevance_score?.max ?? 0,
          selected: newAdvanceFilterData?.relevance_score?.selected ?? [
            newAdvanceFilterData?.relevance_score?.min ?? 0,
            newAdvanceFilterData?.relevance_score?.max ?? 0,
          ],
          step: 0.1,
          onChange: (val: number[]) => {
            setClearAllClicked(0);
            updateAdvancedFilterData({
              ...advancedFilterData,
              relevance_score: {
                ...newAdvanceFilterData.relevance_score,
                selected: [...val],
              },
            });
          },
        },
      ];
    }
  }
  const { isLoadingYoutube, audienceCohorts, videoCohorts, channelCohorts } =
    useSelector(
      (state: { audienceManagerYoutube: any }) => state.audienceManagerYoutube
    );
  const mixPanelEventsTriggerForSidebarTile = (selectedCohortType: any) => {
    switch (selectedCohortType) {
      case SelectedCohortTypeEnum.live:
        mixPanelAdEvent("Published cohorts clicked", {
          module_name: "TAI Audience Manager",
          ai_group_name: selectedAiGroup?.name,
          ai_group_id: selectedAiGroup?.id,
          adaccount_name: selectedAiGroup?.adAccounts,
          agency_id: selectedBrand?.agencyId,
          agency_name: selectedBrand?.agency_name,
          brand_id: selectedBrand?.id,
          brand_name: selectedBrand?.name,
          brand_type: selectedBrand?.brandType,
        });
        return;
      case SelectedCohortTypeEnum.drafted:
        mixPanelAdEvent("Saved cohorts clicked", {
          module_name: "TAI Audience Manager",
          ai_group_name: selectedAiGroup?.name,
          ai_group_id: selectedAiGroup?.id,
          adaccount_name: selectedAiGroup?.adAccounts,
          agency_id: selectedBrand?.agencyId,
          agency_name: selectedBrand?.agency_name,
          brand_id: selectedBrand?.id,
          brand_name: selectedBrand?.name,
          brand_type: selectedBrand?.brandType,
        });
        return;
      case SelectedCohortTypeEnum.recommended:
        mixPanelAdEvent("Pixis Recommended clicked", {
          module_name: "TAI Audience Manager",
          ai_group_name: selectedAiGroup?.name,
          ai_group_id: selectedAiGroup?.id,
          adaccount_name: selectedAiGroup?.adAccounts,
          agency_id: selectedBrand?.agencyId,
          agency_name: selectedBrand?.agency_name,
          brand_id: selectedBrand?.id,
          brand_name: selectedBrand?.name,
          brand_type: selectedBrand?.brandType,
        });
        return;
      default:
        return;
    }
  };
  const {agencyAssets} = useSelector((state:any) => state.common);

  return (
    <Grid container sx={{ px: 2 }}>
      <Grid item xs={7.5} sm={7.5} md={7.5} lg={7.5} xl={7.5}>
        <Stack spacing={1.5} direction={"row"} alignItems={"center"}>
          {!isViewedFromBeta && (
            <TileDropdown Icon={<Facebook />} label={"Facebook"} />
          )}
          {(selectedPlatform === "facebook"
            ? getTileOptions(agencyAssets)
            : getTileOptionsYoutube(agencyAssets)
          ).map(({ icon, text, key }: TilesProps, index) => (
            <Box width={"26%"} className="audience-secondary-tiles" key={key}>
              <SingleTile
                value={
                  (selectedPlatform === "facebook" && isLoading[key]) ||
                  (selectedPlatform === "youtube" && isLoadingYoutube)
                    ? "-"
                    : selectedPlatform === "facebook"
                    ? length[key]
                    : audienceCohorts.length +
                      videoCohorts.length +
                      channelCohorts.length
                }
                label={text}
                Icon={<Box sx={{ pl: 1 }}>{icon}</Box>}
                tileKey={"explorer"}
                isSelected={key === selectedCohortType}
                selectedClassName="audience-secondary-tile-selected"
                clickHandler={() => {
                  dispatch(
                    setSelectedCohortType(
                      key,
                      mixPanelEventsTriggerForSidebarTile
                    )
                  );
                }}
              />
            </Box>
          ))}
        </Stack>
      </Grid>
      <Grid
        item
        xs={4.5}
        sm={4.5}
        md={4.5}
        lg={4.5}
        xl={4.5}
        className="gap-2 d-flex align-items-center justify-content-end"
      >
        {selectedPlatform === "facebook" && (
          <>
            <Sort
              selectedOption={selectedSortOption[selectedCohortType]}
              updateOption={updateSelectedOption}
              optionsList={SORT_OPTIONS[selectedCohortType]}
              disabled={disabled}
            />
            <FilterWrapper
              showFilterDropdown={showFilterDropdown}
              onExitClick={() => {
                setShowFilterDropdown(false);
              }}
              setShowFilterDropdown={setShowFilterDropdown}
              onApplyClick={() => {
                handleApplyFilter();
              }}
              onClearAllClick={() => {
                setIsFilterApplied(false);
                setClearAllClicked(1);
                updateFilterData(commonFilterData);
                changeAdvanceFilterDataOnClearAll();
              }}
              clearAll={true}
              disabled={disabled}
            >
              <>
                {!disabled && (
                  <>
                    <Filter
                      optionsList={filterOptions}
                      selectedTabs={filterData.type}
                      setSelectedTabs={(types) => {
                        updateFilterData({
                          ...filterData,
                          type: types,
                        });
                      }}
                    />
                    <Divider variant="middle" sx={{ mt: 2, mb: 0.25 }} />
                    <AdvanceFilter renderList={advanceFilterOptions} />
                  </>
                )}
              </>
            </FilterWrapper>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Subheader;
