import { Box, IconButton } from "@mui/material";
import { StyledTitle } from "../styled";
import { ReactComponent as ArrowBackCircle } from "../../../../assets/svg/arrow_back_circle.svg";
import { ReactComponent as ArrowForwardCircle } from "../../../../assets/svg/arrow_forward_circle.svg";
import { StyledPaginatedText } from "./styled";
import KeyIndicatorItems from "./KeyIndicatorItems";
import { useEffect, useState } from "react";
import {
  KeyIndicatorDataType,
  KeyIndicatorItemType,
} from "../../shared/sharedTypes";
import KeyIndicatorSkeleton from "./KeyIndicatorSkeleton";
import { ActionDebuggerInitStateType } from "../../../../store/types/ActionDebugger/ActionDebugger";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionSummaryInitStateType,
  KeyIndicatorItemsType,
} from "../../../../store/types/ActionDebugger/ActionSummary";
import {
  getKeyIndicators,
  setKeyIndicatorsLoader,
  setPreviousKeyIndicatorsLoader,
} from "../../../../store/actions/ActionDebugger/ActionSummary";
import {
  calculatePercentageValue,
  getPaginatedData,
} from "../../shared/utils/common.utils";
import { getCurrencySymbol } from "../../../../utils/commonFunctions";
import { initialState } from "../../../../store/types/auth";
import { ActionDebuggerHelperInitState } from "../../../../store/types/ActionDebugger/ActionDebuggerHelper";
import { yyyyMMDDFormat } from "../../../../utils/date.util";

const noOfElementToShow = 3;

export default function KeyIndicator({
  parentLoading,
}: {
  parentLoading?: boolean;
}) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState<KeyIndicatorItemType[]>();
  const [paginatedKeyIndicators, setPaginatedKeyIndicators] = useState<
    KeyIndicatorDataType[]
  >([]);
  const {
    isCompare,
    previousPeriod,
    metricMapper,
    currentPeriod,
    elementMetrics,
    attributionWindow,
    changeMetricFinish,
    attributionWindowElementId,
  } = useSelector(
    (state: { ActionDebuggerReducer: ActionDebuggerInitStateType }) =>
      state.ActionDebuggerReducer
  );
  const {
    keyIndicators,
    previousKeyIndicators,
    keyIndicatorsLoader,
    previousKeyIndicatorsLoader,
  } = useSelector(
    (state: { ActionSummaryReducer: ActionSummaryInitStateType }) =>
      state.ActionSummaryReducer
  );
  const {
    selectedAdAccount,
    selectedCampaign,
    selectedAdSet,
    selectedAIGroup,
  } = useSelector(
    (state: { ActionDebuggerHelperReducer: ActionDebuggerHelperInitState }) =>
      state.ActionDebuggerHelperReducer
  );

  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const showSkeletonBGColor = [
    "rgba(8, 105, 251, 0.05)",
    "#FFFFFF",
    "rgba(8, 105, 251, 0.05)",
  ];
  const dispatch = useDispatch();

  const fetchCurrentKeyIndicator = () => {
    dispatch(setKeyIndicatorsLoader(true));
    dispatch(
      getKeyIndicators({
        elementId: selectedAdAccount?.selectedAdAcc?.elementId,
        start_date: yyyyMMDDFormat(currentPeriod.startDate),
        end_date: yyyyMMDDFormat(currentPeriod.endDate),
        typeOfData: "SET_KEY_INDICATORS",
        typeOfLoader: "SET_KEY_INDICATORS_LOADER",
        adsetIds:
          selectedAdAccount?.platform?.name &&
          (selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") ||
            selectedAdAccount?.platform.name?.toLowerCase().includes("tiktok"))
            ? selectedAdSet
            : [],
        campaignIds:
          selectedAdAccount?.platform?.name &&
          !selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") &&
          !selectedAdAccount?.platform.name?.toLowerCase().includes("tiktok")
            ? selectedCampaign
            : [],
        attributionWindow:
          selectedAdAccount?.platform?.name &&
          selectedAdAccount?.platform.name?.toLowerCase().includes("facebook")
            ? attributionWindow
            : "",
      })
    );
    dispatch({
      type: "SET_CHANGE_METRIC_FINISH",
      payload: false,
    });
  };
  const fetchPreviousKeyIndicator = () => {
    dispatch(setPreviousKeyIndicatorsLoader(true));
    dispatch(
      getKeyIndicators({
        elementId: selectedAdAccount?.selectedAdAcc?.elementId,
        start_date: yyyyMMDDFormat(previousPeriod.startDate),
        end_date: yyyyMMDDFormat(previousPeriod.endDate),
        typeOfData: "SET_PREVIOUS_KEY_INDICATORS",
        typeOfLoader: "SET_PREVIOUS_KEY_INDICATORS_LOADER",
        adsetIds:
          selectedAdAccount?.platform?.name &&
          (selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") ||
            selectedAdAccount?.platform.name?.toLowerCase().includes("tiktok"))
            ? selectedAdSet
            : [],
        campaignIds:
          selectedAdAccount?.platform?.name &&
          !selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") &&
          !selectedAdAccount?.platform.name?.toLowerCase().includes("tiktok")
            ? selectedCampaign
            : [],
        attributionWindow:
          selectedAdAccount?.platform?.name &&
          selectedAdAccount?.platform.name?.toLowerCase().includes("facebook")
            ? attributionWindow
            : "",
      })
    );
  };

  useEffect(() => {
    if (
      keyIndicators &&
      // keyIndicators.length > 0 &&
      previousKeyIndicators &&
      // Object.keys(previousKeyIndicators).length > 0 &&
      Object.keys(metricMapper).length > 0
    ) {
      let newData: KeyIndicatorItemType[] = [];
      keyIndicators.map((data: KeyIndicatorItemsType) => {
        newData.push({
          keyName: metricMapper[data.metric]?.name,
          metricValue: data.value,
          id: data.metric,
          metricFormat: metricMapper[data.metric]?.metricFormat,
          previousValue: previousKeyIndicators[data.metric],
          percentageDifference: calculatePercentageValue(
            Number(
              (metricMapper[data.metric]?.metricFormat === "Percentage" &&
              selectedAdAccount?.platform?.name
              .toLowerCase()
              .includes("google") 
                ? data.value * 100
                : data.value
              )?.toFixed(2)
            ),
            Number(
              (metricMapper[data.metric]?.metricFormat === "Percentage" &&
              selectedAdAccount?.platform?.name
                                          .toLowerCase()
                                          .includes("google") 
                ? previousKeyIndicators[data.metric] * 100
                : previousKeyIndicators[data.metric]
              )?.toFixed(2)
            )
          ),
        });
      });

      let sortedData = newData.sort((a, b) =>
        metricMapper[b.id]?.growth === "Negative"
          ? metricMapper[a.id]?.growth === "Negative"
            ? b.percentageDifference * -1 - a.percentageDifference * -1
            : b.percentageDifference * -1 - a.percentageDifference
          : metricMapper[a.id]?.growth === "Negative"
          ? b.percentageDifference - a.percentageDifference * -1
          : b.percentageDifference - a.percentageDifference
      );
      setPaginatedData(sortedData);
    }
  }, [keyIndicators, previousKeyIndicators, metricMapper]);

  useEffect(() => {
    if (selectedAdAccount?.selectedAdAcc?.elementId && isInitialized) {
      setCurrentPage(1);
      fetchCurrentKeyIndicator();
    }
  }, [currentPeriod]);

  useEffect(() => {
    if (
      selectedAdAccount?.selectedAdAcc?.elementId &&
      isInitialized &&
      isCompare
    ) {
      setCurrentPage(1);
      fetchPreviousKeyIndicator();
    }
  }, [previousPeriod]);

  useEffect(() => {
    let platformValue = (selectedAdAccount?.platform?.name as string) ?? "";
    platformValue = platformValue.toLowerCase();
    if (
      platformValue.includes("facebook") &&
      selectedAdSet.length > 0 &&
      attributionWindow !== "" &&
      attributionWindowElementId === selectedAdAccount?.selectedAdAcc?.elementId
    ) {
      setCurrentPage(1);
      fetchCurrentKeyIndicator();
      fetchPreviousKeyIndicator();
      setIsInitialized(true);
    }
  }, [selectedAdSet, attributionWindow, attributionWindowElementId]);

  useEffect(() => {
    let platformValue = (selectedAdAccount?.platform?.name as string) ?? "";
    platformValue = platformValue.toLowerCase();
    if ((platformValue.includes("tiktok")) && selectedAdSet.length > 0) {
      fetchCurrentKeyIndicator();
      fetchPreviousKeyIndicator();
      setIsInitialized(true);
    }
  }, [selectedAdSet]);

  useEffect(() => {
    let platformValue = (selectedAdAccount?.platform?.name as string) ?? "";
    platformValue = platformValue.toLowerCase();

    if ((platformValue.includes("google") || platformValue.includes("apple")|| platformValue.includes("dv360")) && selectedCampaign?.length > 0) {
      setCurrentPage(1);
      fetchCurrentKeyIndicator();
      fetchPreviousKeyIndicator();
      setIsInitialized(true);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (elementMetrics.length > 0 && changeMetricFinish) {
      setCurrentPage(1);
      fetchCurrentKeyIndicator();
      fetchPreviousKeyIndicator();
    }
  }, [changeMetricFinish]);

  useEffect(() => {
    if (paginatedData) {
      let formatedData = getPaginatedData(paginatedData, noOfElementToShow);
      setTotalPage(formatedData.totalPage);
      setPaginatedKeyIndicators(formatedData.paginatedKeydata);
    }
  }, [paginatedData]);

  const onBackClick = () => {
    setCurrentPage((prev) => prev - 1);
  };
  const OnForwardClick = () => {
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    setCurrentPage(1);
    if (!selectedAIGroup?.id) {
      dispatch({ type: "SET_KEY_INDICATORS", payload: [] });
      dispatch({ type: "SET_PREVIOUS_KEY_INDICATORS", payload: [] });
      setPaginatedData([]);
    } else if (!selectedAdAccount?.selectedAdAcc?.elementId) {
      dispatch({ type: "SET_KEY_INDICATORS", payload: [] });
      dispatch({ type: "SET_PREVIOUS_KEY_INDICATORS", payload: [] });
      setPaginatedData([]);
    } else if (
      selectedAdAccount?.platform?.name &&
      (!selectedAdAccount?.platform?.name.toLowerCase().includes("google") ||
        !selectedAdAccount?.platform?.name.toLowerCase().includes("facebook") ||
        !selectedAdAccount?.platform?.name.toLowerCase().includes("tiktok"))
    ) {
      dispatch({ type: "SET_KEY_INDICATORS", payload: [] });
      dispatch({ type: "SET_PREVIOUS_KEY_INDICATORS", payload: [] });
      setPaginatedData([]);
    }
  }, [selectedAIGroup, selectedAdAccount]);

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <StyledTitle>Key Indicators</StyledTitle>
        <Box display="flex" alignItems="center" justifySelf="center">
          <IconButton
            color="primary"
            onClick={onBackClick}
            disabled={currentPage === 1}
          >
            <ArrowBackCircle />
          </IconButton>
          <StyledPaginatedText>
            {currentPage} of {totalPage}
          </StyledPaginatedText>
          <IconButton
            color="primary"
            onClick={OnForwardClick}
            disabled={currentPage === totalPage}
          >
            <ArrowForwardCircle />
          </IconButton>
        </Box>
      </Box>
      {keyIndicatorsLoader || previousKeyIndicatorsLoader || parentLoading ? (
        showSkeletonBGColor.map((backgroundColor: string) => (
          <KeyIndicatorSkeleton backgroundColor={backgroundColor} />
        ))
      ) : (
        <Box pt=".75em" pb="1.5em" minHeight={"16em"}>
          {paginatedKeyIndicators.length !== 0
            ? paginatedKeyIndicators[currentPage - 1].map(
                (item: KeyIndicatorItemType, index: number) => {
                  const currency =
                    metricMapper[item.id]?.metricFormat === "Currency"
                      ? authState?.accountDetails?.currency
                        ? getCurrencySymbol(authState?.accountDetails?.currency)
                        : ""
                      : "";
                  return (
                    <KeyIndicatorItems
                      keyName={item.keyName}
                      backgroundColor={
                        (index + 1) % 2 ? "rgba(8, 105, 251, 0.05)" : "#FFFFFF"
                      }
                      metricValue={
                        item.metricValue != null
                          ? currency +
                            Math.abs(
                              Number(
                                (item.metricFormat === "Percentage" &&
                                selectedAdAccount?.platform?.name
                                  .toLowerCase()
                                  .includes("google")
                                  ? item.metricValue * 100
                                  : item.metricValue
                                ).toFixed(2)
                              )
                            )
                          : "-"
                      }
                      isPercentage={item.metricFormat === "Percentage"}
                      // isValuePositive={growthFlag}
                      comparingData={
                        isCompare && previousKeyIndicators[item.id]
                          ? {
                              loss: !(item.percentageDifference > 0),
                              isZero: item.percentageDifference === 0,
                              value: `${Math.abs(item.percentageDifference)}%`,
                              previousPeriod: previousPeriod,
                              previousValue:
                                previousKeyIndicators[item.id] != null
                                  ? currency +
                                    Math.abs(
                                      Number(
                                        (item.metricFormat === "Percentage" &&
                                        selectedAdAccount?.platform?.name
                                        .toLowerCase()
                                        .includes("google")
                                          ? previousKeyIndicators[item.id] * 100
                                          : previousKeyIndicators[item.id]
                                        )?.toFixed(2)
                                      )
                                    )
                                  : "-",
                              difference:
                                currency +
                                Math.abs(
                                  Number(
                                    (item.metricFormat === "Percentage" &&
                                    selectedAdAccount?.platform?.name
                                    .toLowerCase()
                                    .includes("google")
                                      ? item.metricValue * 100
                                      : item.metricValue
                                    ).toFixed(2)
                                  ) -
                                    Number(
                                      (item.metricFormat === "Percentage" &&
                                      selectedAdAccount?.platform?.name
                                      .toLowerCase()
                                      .includes("google")
                                        ? previousKeyIndicators[item.id] * 100
                                        : previousKeyIndicators[item.id]
                                      ).toFixed(2)
                                    )
                                ).toFixed(2),
                              // growthFlagPreviousPeriod:
                              //   growthFlagPreviousPeriod,
                              metricGrowthFlag: metricMapper[item.id]?.growth,
                            }
                          : undefined
                      }
                    />
                  );
                }
              )
            : showSkeletonBGColor.map((backgroundColor: string) => (
                <KeyIndicatorItems
                  metricValue="-"
                  keyName="-"
                  backgroundColor={backgroundColor}
                  hideTooltip
                />
              ))}
        </Box>
      )}
    </Box>
  );
}
