import { useDispatch, useSelector } from "react-redux";
import { initialState } from "store/reducers/CreativeAI/GsaiPlayground";
import type { GsaiState } from "../../../../../../store/types/CreativeAI/GsaiPlayground";

export function useCreativeReplacementConfig(
  type: "headlines" | "descriptions"
) {
  const dispatch = useDispatch();

  const replacementConfig = useSelector(
    (state: { gsaiPlayground: GsaiState }) =>
      state.gsaiPlayground.replacementConfig
  );

  function toggleReplacementConfig(isEnabled: boolean) {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        replacementConfig: {
          ...initialState.replacementConfig,
          isEnabled,
        },
      },
    });
  }

  function saveReplacementConfig(
    payload: GsaiState["replacementConfig"]["headlines"]
  ) {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: { replacementConfig: { ...replacementConfig, [type]: payload } },
    });
  }

  return {
    isEnabled: replacementConfig?.isEnabled,
    replacementConfig: replacementConfig?.[type],
    toggleReplacementConfig,
    saveReplacementConfig,
  };
}
