import { useState } from "react";
import Box from "@mui/material/Box";
import {
  StyledContainer,
  StyledOnBoardingHeaderContainerLarge,
  StyledOnBoardingHeaderContainerSmall,
  StyledOnBoardingHeaderMainContainer,
  StyledOnBoardingOtherMethodsContainer,
} from "./styled";
import UTGTextFieldWithPopup from "./UTGTextFieldWithPopup";
import SelectMethodCard from "../PreferredMethodModal/SelectMethodCard";
import { ReactComponent as CreativeAnimator } from "../../../../../assets/cai/svg/CreativeAnimator.svg";
import { ReactComponent as SurpriseMe } from "../../../../../assets/cai/svg/SurpriseMe.svg";
import { useSearchParams } from "react-router-dom-v5-compat";

function OnBoardingScreen({
  openCreateVariantModal,
  setOpenCreateVariantModal,
  generateCAIRecommendations,
  setOpenAutoGenerateModal,
  onClickCreateAnimater,
  billboardDisabled,
  autogenerateDisabled,
  adId,
  generateUTGRecommendations,
  utgDisabled,
}: any) {
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  const [active, setActive] = useState(false);
  const [text, setText] = useState("");
  const data = [
    {
      title: "Surprise Me!",
      subTitle:
        "Sit back & relax, the system will generate multiple variants for you",
      icon: <SurpriseMe height={"3.5em"} width={"4.69em"} />,
      onClick: () => {
        if(isViewedFromBeta)
          {
          window.parent.postMessage(
            {
              type: 'FLOW-STARTED',
            },
            '*'
          );
        }
        generateCAIRecommendations("Manual");
        setOpenCreateVariantModal(false);
        setOpenAutoGenerateModal(true);
        setText("");
        // setSelected(-1);
        // dispatch(setVariantsNewTag({ adId, isNew: false }));
      },
    },
    {
      title: "Creative Animator",
      subTitle:
        "Use creativity and artistic abilities to create impactful ads with our animation offerings.",
      icon: <CreativeAnimator height={"3.5em"} width={"4.69em"} />,
      onClick: () => {
        setOpenCreateVariantModal(false);
        onClickCreateAnimater();
        setText("");
        // setSelected(-1);
      },
    },
  ];
  return (
    <StyledContainer>
      <StyledOnBoardingHeaderMainContainer>
        <StyledOnBoardingHeaderContainerSmall>
          Unleash Limitless Creativity
        </StyledOnBoardingHeaderContainerSmall>
        <StyledOnBoardingHeaderContainerLarge>
          Discover the Power of Our Innovative Text Creative Generator!
        </StyledOnBoardingHeaderContainerLarge>
      </StyledOnBoardingHeaderMainContainer>
      <Box
        height={"55%"}
        display={"flex"}
        justifyContent={"flex-start"}
        flexDirection={"column"}
        gap={"1em"}
      >
        <UTGTextFieldWithPopup
          active={active}
          setActive={setActive}
          text={text}
          setText={setText}
          onGenerate={() => {
            generateUTGRecommendations(text);
            setOpenAutoGenerateModal(true);
            setText("");
          }}
          disabled={utgDisabled}
        />
        {!active && (
          <StyledOnBoardingOtherMethodsContainer>
            <Box
              display={"flex"}
              flexDirection={"row"}
              gap={"1.38em"}
              alignItems={"center"}
            >
              <Box
                sx={{
                  width: "14.75em",
                  height: "0.0625em",
                  background: "#c8ced7",
                }}
              ></Box>

              <Box
                sx={{
                  fontFamily: "Inter",
                  fontSize: "0.875em",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "1.375em",
                  letterSpacing: "0.00875em",
                }}
              >
                OR
              </Box>
              <Box
                sx={{
                  width: "14.75em",
                  height: "0.0625em",
                  background: "#c8ced7",
                }}
              ></Box>
            </Box>
            <Box display={"flex"} flexDirection={"row"} gap={"0.75em"}>
              {data.map((method, index) => {
                return (
                  <SelectMethodCard
                    disabled={
                      index == 1
                        ? billboardDisabled
                        : index == 0 && autogenerateDisabled
                        ? 3
                        : 0
                    }
                    selected={index == 0}
                    index={index}
                    setSelected={() => {}}
                    title={method.title}
                    subTitle={method.subTitle}
                    children={method.icon}
                    onClick={method.onClick}
                  />
                );
              })}
            </Box>
          </StyledOnBoardingOtherMethodsContainer>
        )}
      </Box>
    </StyledContainer>
  );
}

export default OnBoardingScreen;
