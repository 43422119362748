export const GET_COHORT_LIST = "GET_COHORT_LIST";
export const SET_COHORT_LIST = "SET_COHORT_LIST";
export const GET_COHORT = "GET_COHORT";
export const GET_RECOMMENDATION_METADATA_TEMPLATE =
  "GET_RECOMMENDATION_METADATA_TEMPLATE";

export const SET_RECOMMENDATION_METADATA_TEMPLATE =
  "SET_RECOMMENDATION_METADATA_TEMPLATE";

export const SET_RECOMMENDATION_TIMER = "SET_RECOMMENDATION_TIMER";

export type GetCohortListValues = {
  type: typeof GET_COHORT_LIST;
  payload: any;
};
export type GetCohortValues = {
  type: typeof GET_COHORT;
  payload: any;
};
export type SetCohortList = {
  type: typeof SET_COHORT_LIST;
  payload: any;
};
export type SetRecommendationTimer = {
  type: typeof SET_RECOMMENDATION_TIMER;
  payload: any;
};

export type GetRecommendationMetadataTemplate = {
  type: typeof GET_RECOMMENDATION_METADATA_TEMPLATE;
  payload: any;
  callback?: Function;
};

export type SetRecommendationMetadataTemplate = {
  type: typeof SET_RECOMMENDATION_METADATA_TEMPLATE;
  payload: any;
};
