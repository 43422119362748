import {
  Box,
  IconButton,
  InputLabel,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as DropDownArrow } from "assets/svg/dropdownarrow.svg";
import { ReactComponent as InfoIcon } from "assets/svg/info_grey.svg";
import { ReactComponent as Warning } from "../../../assets/Danger-Circle.svg";

import "./index.scss";
import { PacingType } from "../../Types";
import { getDecimalValues } from "utils/commonFunctions";
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  // width: " 27.625em",
  backgroundColor: "#EDF3FE",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "none",
}));

export const BudgetPlannerLimitEdit: React.FC<{
  selectedMode: any;
  selectedPacing: PacingType | null;
  setSelectedMode: any;
  difference: any;
  selectedDaysBudget: any;
  parameter: any;
  setParameter: any;
  factor: any;
  setFactor: any;
  enableUpdate: any;
  setEnableUpdate: any;
  updateValue: any;
  setUpdateValue: any;
  handleUpdate: Function;
  getAllCalendarDaysForSelectedPacing: any;
  selectedDatesFiltered: any;
  showWarningModalOnChange: Function;
  isCalendarUpdated: any;
  maxBudget: any;
  selectedBrand: any;
}> = ({
  selectedPacing,
  selectedMode,
  setSelectedMode,
  difference,
  selectedDaysBudget,
  parameter,
  setParameter,
  factor,
  setFactor,
  enableUpdate,
  setEnableUpdate,
  updateValue,
  setUpdateValue,
  handleUpdate,
  getAllCalendarDaysForSelectedPacing,
  selectedDatesFiltered,
  showWarningModalOnChange,
  isCalendarUpdated,
  maxBudget,
  selectedBrand,
}) => {
  return (
    <Box
      sx={{
        width: "27.125rem",
        maxHeight: "70vh",
        border: "1.20168px solid #D9D9D9",
        boxShadow: "0px 4.80671px 16.8235px rgba(0, 0, 0, 0.05)",
        borderRadius: "14.4201px",
        p: "1rem",
        mr: "20px",
      }}
    >
      <Stack sx={{ height: "100%" }}>
        <Typography
          sx={{ fontSize: "0.8rem", fontWeight: "600", mb: "0.4rem" }}
        >
          Actions
        </Typography>
        <Item sx={{ height: "6.5%", mb: "0.4rem" }}>
          <Typography sx={{ fontWeight: "600", fontSize: "0.8em" }}>
            Budget Redistribution Type
          </Typography>
          <Box
            sx={{
              fontSize: "0.8rem",
              width: "50%",
              display: "flex",
              justifyContent: "space-evenly",
              alignSelf: "auto",
            }}
          >
            <input
              type="radio"
              id="automatic"
              name="action"
              value={"automatic"}
              checked={selectedMode === 0}
              onChange={() => {
                if (selectedDatesFiltered.length > 0 && isCalendarUpdated) {
                  showWarningModalOnChange();
                } else {
                  setSelectedMode(0);
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
            <label htmlFor="automatic" style={{ marginBottom: "0px" }}>
              Automatic
            </label>
            <input
              type="radio"
              id="manual"
              name="action"
              value={"manual"}
              checked={selectedMode === 1}
              onChange={() => {
                if (selectedDatesFiltered.length > 0 && isCalendarUpdated) {
                  showWarningModalOnChange();
                } else {
                  setSelectedMode(1);
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
            <label htmlFor="manual" style={{ marginBottom: "0px" }}>
              Manual
            </label>
          </Box>
        </Item>
        <Stack sx={{ mb: "2rem", height: "50%" }}>
          <Item sx={{ borderBottom: "1.20168px solid #DDDDDD" }}>
            <Typography component={"span"} sx={{ fontSize: "0.8rem" }}>
              Total Budget for the Pacing Period
              <Tooltip
                title="This is the Total Budget setup for this particular Pacing. To change the Total Budget User can go back to the Pacing screen"
                arrow
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>

            <Typography component={"p"}>
              {selectedBrand?.currencySymbol}
              {getDecimalValues(
                selectedPacing?.pacingType === "PERIODIC"
                  ? parseFloat(selectedPacing?.spends?.toString() || "")
                  : selectedDatesFiltered[0]?.totalBudgetCalculatedBydays
              )}
            </Typography>
          </Item>
          <Item sx={{ borderBottom: "1.20168px solid #DDDDDD" }}>
            <Typography component={"span"} sx={{ fontSize: "0.8rem" }}>
              Total Budget of selected days
              <Tooltip
                title="This is the sum of all the Daily Budgets selected. "
                arrow
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography component={"p"}>
              {selectedMode === 0
                ? selectedBrand?.currencySymbol +
                  getDecimalValues(selectedDaysBudget)
                : "-"}
            </Typography>
          </Item>
          <Item
            sx={{ flexDirection: "column", alignItems: "flex-start" }}
            style={{ opacity: selectedMode == 1 ? 0.5 : 1 }}
          >
            <Box
              className="d-flex justify-content-between"
              sx={{ width: "98%" }}
            >
              <Dropdown
                className="hoverable position-relative navigation-dropdown-btn ml-1"
                style={{
                  width: "150px",
                  background: "white",
                  pointerEvents: selectedMode ? "none" : "auto",
                }}
              >
                <Dropdown.Toggle
                  id="dropdown-toggle-white"
                  style={{ width: "150px" }}
                >
                  <Typography
                    component={"div"}
                    className="text-dark text-left text-truncate"
                  >
                    {parameter}
                  </Typography>
                  <Typography component={"span"} className="dropdown_arrow">
                    <DropDownArrow />
                  </Typography>
                </Dropdown.Toggle>
                <Dropdown.Menu id="parameter">
                  <Dropdown.Item onClick={() => setParameter("Increase by")}>
                    Increase by
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setParameter("Decrease by")}>
                    Decrease by
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setParameter("Set To");
                      setFactor("$");
                    }}
                  >
                    Set To
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown
                className="hoverable position-relative navigation-dropdown-btn ml-1"
                style={{
                  borderRadius: "none",
                  pointerEvents: selectedMode ? "none" : "auto",
                  marginLeft: "5px",
                }}
              >
                <Dropdown.Toggle
                  id="dropdown-toggle-white"
                  style={{
                    width: "60px",
                    background: "white",
                    borderRadius: "none",
                  }}
                >
                  <Typography
                    component={"div"}
                    className="text-dark text-left text-truncate"
                  >
                    {factor}
                  </Typography>
                  <Typography component={"div"} className="dropdown_arrow">
                    <DropDownArrow />
                  </Typography>
                </Dropdown.Toggle>
                <Dropdown.Menu id="menu">
                  <Dropdown.Item
                    onClick={() => setFactor("%")}
                    disabled={parameter.includes("Set")}
                  >
                    %
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFactor("$")}>
                    $
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <TextField
                id="input-btn"
                hiddenLabel
                type="number"
                size="small"
                value={updateValue}
                disabled={selectedMode === 1}
                onChange={(e) => {
                  setUpdateValue(parseFloat(e.target.value));
                  if (e.target.value) setEnableUpdate(true);
                  else setEnableUpdate(false);
                }}
                sx={{
                  backgroundColor: "white",
                  border: "none",
                  outline: "none",
                }}
              />
            </Box>

            <Typography
              component={"span"}
              sx={{ ml: "0.5rem", color: "#1C1E21", fontSize: "0.75rem" }}
            >
              This will{" "}
              {parameter.includes("Increase")
                ? "increase"
                : parameter.includes("Decrease")
                ? "decrease"
                : "set the"}{" "}
              budget for all the selected days{" "}
              {parameter.includes("Set") ? " to" : "by"} this
              {parameter.includes("Set") ? " absolute" : ""}{" "}
              {factor.includes("$") ? "value" : "percentage"}
            </Typography>

            <Box
              sx={{
                backgroundColor: "white",
                width: "23rem",
                height: "48%",
                p: "0.5rem",
                borderRadius: "9.61343px",
                display: "flex",
                gap: "0.5rem",
                flexDirection: "column",
                m: "0.2rem",
              }}
            >
              <Typography component={"span"} sx={{ fontSize: "0.8rem" }}>
                Updated Budget of selected days
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant={"h6"}>
                  {selectedMode === 0
                    ? selectedBrand?.currencySymbol +
                      getDecimalValues(selectedDaysBudget)
                    : "-"}
                </Typography>
                <Typography
                  component={"button"}
                  disabled={!enableUpdate}
                  onClick={() => handleUpdate()}
                  sx={{
                    width: "5.1875rem",
                    height: "2rem",
                    borderRadius: "7.21007px",
                    backgroundColor: "#0869FB",
                    color: "white",
                    border: "none",
                    fontSize: "0.8rem",
                  }}
                  style={{ opacity: enableUpdate ? 1 : 0.5 }}
                >
                  Update
                </Typography>
              </Box>
            </Box>
          </Item>
        </Stack>
        <Stack
          sx={{
            backgroundColor: "white",
            height: "13%",
            border: "1.20168px solid #D9D9D9",
            borderRadius: "14.4201px",
            flexDirection: "column",
            mb: "0.2rem",
            textAlign: "center",
          }}
        >
          <Typography
            component={"div"}
            className="d-flex align-items-center justify-content-center"
            sx={{
              borderBottom: "1.20168px solid #DDDDDD",
              fontSize: "0.7rem",
            }}
          >
            Please deselect the days where you dont want to
            <br /> adjust these changes
          </Typography>
          <Typography
            component={"div"}
            sx={{ fontSize: "0.7rem", m: "0.3125rem" }}
          >
            Caution: The maximum change that will take place is of{" "}
            {selectedBrand?.currencySymbol}
            {maxBudget}.
          </Typography>
        </Stack>
        <Item
          sx={{
            height: "23%",
            alignItems: "flex-start",
            flexDirection: "column",
            backgroundColor: "#F5F5F5",
          }}
          style={{ opacity: selectedMode == 0 ? 0.5 : 1 }}
        >
          <Typography component={"span"} sx={{ fontSize: "0.8rem" }}>
            Difference from total budget
            <Tooltip
              title="This is the change in the Total Budget that has occurred while editing the Daily budgets. You will need to reduce it to zero in order to Save your calendar setup."
              arrow
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography
            variant={"h6"}
            style={{ color: difference === 0 ? "black" : "red" }}
          >
            {selectedBrand?.currencySymbol}
            {selectedMode === 1 ? getDecimalValues(difference) : 0}
          </Typography>
          <Typography
            sx={{
              color: "red",
              fontSize: "0.6rem",
            }}
            style={{ display: difference === 0 ? "none" : "flex" }}
            className="align-items-center justify-content-center"
          >
            <Warning className="d-flex align-items-center justify-content-center mr-1" />
            Please make the difference as {selectedBrand?.currencySymbol}0
          </Typography>
        </Item>
      </Stack>
    </Box>
  );
};
