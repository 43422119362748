import {
  StyledMessageContainer,
  StyledOpenCAICardContainer,
  StyledSvgContainer,
} from "./styled";
import { ReactComponent as SVG } from "../../../../assets/cai/svg/OpenCAISetup.svg";
import { Box } from "@mui/material";
import { Button } from "components/shared";
import { Fragment, useState } from "react";
import CreateVariantModal from "components/CreativeAI/Facebook/Main/CreateVariantModal";
function OpenCAISetup() {
  const [showUnitForm, setShowUnitForm] = useState(false);

  return (
    <Fragment>
      <StyledOpenCAICardContainer>
        <StyledSvgContainer>
          <SVG height={"16.875em"} />
        </StyledSvgContainer>
        <StyledMessageContainer>
          Looks like the creative setup for this AI Group is pending. Click
          below to get started
        </StyledMessageContainer>
        <Button
          variant="contained"
          sx={{
            width: "13.57em",
            backgroundColor: "#0869FB",
            color: "#FFF",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "0.875em",
            lineHeight: "18px",
            borderRadius: "0.533em",
            padding: "0.78em 1.14em",
            ":hover": { backgroundColor: "#0869FB" },
          }}
          onClick={() => setShowUnitForm(true)}
        >
          <Box display={"flex"} justifyContent="center" width={"100%"}>
            Open Creative AI Setup{" "}
          </Box>
        </Button>
      </StyledOpenCAICardContainer>

      {showUnitForm && (
        <CreateVariantModal
          onHide={() => {
            setShowUnitForm(false);
          }}
          variantModalType="Setting"
          setShowUniteForm={setShowUnitForm}
          showUnitForm={showUnitForm}
        />
      )}
    </Fragment>
  );
}

export default OpenCAISetup;
