import {
  DeleteToken,
  DELETE_TOKEN_FOR_THIRD_PARTY,
  EnableMasterAccessToAccounts,
  ENABLE_MASTER_ACCESS_TO_ACCOUNTS,
  GenerateAccessAndRefreshToken,
  GENERATE_ACCESS_AND_REFRESH_TOKEN,
  GetClientIdForSpecificPlatform,
  GetConfigDetailsOfThirdParty,
  GetConfigsOfGAAccount,
  GetEligibilityOfAccounts,
  GetPropertiesOfGAAccount,
  GetThirdPartyAccounts,
  GetTokenStatsSpecificForAdAccounts,
  GetTokenStatusBasedOnBrandId,
  GET_CLIENT_ID_FOR_SPECIFIC_PLATFORM,
  GET_CONFIGS_OF_GA_ACCOUNT,
  GET_CONFIG_DETAILS_OF_THIRD_PARTY_ACCOUNT,
  GET_ELIGIBILITY_OF_ACCOUNTS,
  GET_PROPERTIES_OF_GA_ACCOUNT,
  GET_THIRD_PARTY_ACCOUNTS,
  GET_TOKEN_STATS_SPECIFIC_FOR_AD_ACCOUNTS,
  GET_TOKEN_STATUS_BASED_ON_BRAND_ID,
  IDeleteToken,
  IEnableMasterAccessToAccounts,
  IGenerateAccessAndRefreshToken,
  IGetClientIdForSpecificPlatform,
  IGetConfigDetailsOfThirdParty,
  IGetConfigsOfGAAccount,
  IGetEligibilityOfAccounts,
  IGetPropertiesOfGAAccount,
  IGetTokenStatsSpecificForAdAccounts,
  IGetTokenStatusBasedOnBrandId,
  ILinkAdAccountsToBrands,
  IThirdPartyAccounts,
  IUpdatePrimaryTokenForThirdParty,
  LinkAdAccountsToBrands,
  LINK_AD_ACCOUNTS_TO_BRANDS,
  SetPropertiesOfGAAccount,
  SET_PROPERTIES_OF_GA_ACCOUNT,
  SetError,
  SET_ERROR,
  SET_THIRD_PARTY_ACCOUNTS,
  UpdateConfigDetailsFromAccountId,
  UpdatePrimaryTokenForThirdParty,
  UPDATE_CONFIG_DETAILS_FROM_ACCOUNT_ID,
  UPDATE_PRIMARY_TOKEN_FOR_THIRD_PARTY,
  IGSheetsDataSource,
  GET_G_SHEETS_DATA_SOURCE,
  GetGSheetsDataSource,
  IGSheetURLValidation,
  GET_G_SHEET_URL_VALIDATION,
  GetGSheetURLValidation,
  GetGSheetSyncHistory,
  IGSheetSyncHistory,
  GET_G_SHEET_SYNC_HISTORY,
  IGSheetTimezone,
  GetGSheetTimezone,
  GET_G_SHEET_TIMEZONE,
  IGSheetResyncData,
  GetGResyncData,
  GET_G_SHEET_RESYNC_DATA,
  IAddGSheetConfiguration,
  AddGSheetConfiguration,
  ADD_G_SHEET_CONFIGURATION,
  IUpdateGSheetConfiguration,
  UpdateGSheetConfiguration,
  UPDATE_G_SHEET_CONFIGURATION,
} from "store/types/thirdPartyChannels";

export const getClientIdForSpecificPlatform = (
  payload: IGetClientIdForSpecificPlatform
): GetClientIdForSpecificPlatform => ({
  type: GET_CLIENT_ID_FOR_SPECIFIC_PLATFORM,
  payload,
});

export const getEligibilityOfAccounts = (
  payload: IGetEligibilityOfAccounts
): GetEligibilityOfAccounts => ({
  type: GET_ELIGIBILITY_OF_ACCOUNTS,
  payload,
});

export const getTokenStatsSpecificForAdAccounts = (
  payload: IGetTokenStatsSpecificForAdAccounts
): GetTokenStatsSpecificForAdAccounts => ({
  type: GET_TOKEN_STATS_SPECIFIC_FOR_AD_ACCOUNTS,
  payload,
});

export const getTokenStatusBasedOnBrandId = (
  payload: IGetTokenStatusBasedOnBrandId,
  callback?: CallableFunction
): GetTokenStatusBasedOnBrandId => ({
  type: GET_TOKEN_STATUS_BASED_ON_BRAND_ID,
  payload,
  callback,
});

export const getPropertiesOfGAAccount = (
  payload: IGetPropertiesOfGAAccount,
  callback?: CallableFunction
): GetPropertiesOfGAAccount => ({
  type: GET_PROPERTIES_OF_GA_ACCOUNT,
  payload,
  callback,
});

export const setPropertiesOfGAAccount = (
  payload: any
): SetPropertiesOfGAAccount => ({
  type: SET_PROPERTIES_OF_GA_ACCOUNT,
  payload,
});

export const setError = (payload: any): SetError => ({
  type: SET_ERROR,
  payload,
});

export const getConfigsOfGAAccount = (
  payload: IGetConfigsOfGAAccount,
  callback?: CallableFunction
): GetConfigsOfGAAccount => ({
  type: GET_CONFIGS_OF_GA_ACCOUNT,
  payload,
  callback,
});

export const setThirdPartyAccount = (payload: any) => ({
  type: SET_THIRD_PARTY_ACCOUNTS,
  payload,
});

export const getConfigDetailsOfThirdPartyAccount = (
  payload: IGetConfigDetailsOfThirdParty
): GetConfigDetailsOfThirdParty => ({
  type: GET_CONFIG_DETAILS_OF_THIRD_PARTY_ACCOUNT,
  payload,
});

export const generateAccessAndRefreshToken = (
  payload: IGenerateAccessAndRefreshToken
): GenerateAccessAndRefreshToken => ({
  type: GENERATE_ACCESS_AND_REFRESH_TOKEN,
  payload,
});

export const linkAdAccountsToBrands = (
  payload: ILinkAdAccountsToBrands,
  callback?: CallableFunction
): LinkAdAccountsToBrands => ({
  type: LINK_AD_ACCOUNTS_TO_BRANDS,
  payload,
  callback,
});

export const getThirdPartyAccounts = (
  payload: IThirdPartyAccounts
): GetThirdPartyAccounts => ({
  type: GET_THIRD_PARTY_ACCOUNTS,
  payload,
});

export const enableMasterAccessToAccounts = (
  payload: IEnableMasterAccessToAccounts
): EnableMasterAccessToAccounts => ({
  type: ENABLE_MASTER_ACCESS_TO_ACCOUNTS,
  payload,
});

export const updatePrimaryTokenForThirdParty = (
  payload: IUpdatePrimaryTokenForThirdParty,
  callback?: CallableFunction
): UpdatePrimaryTokenForThirdParty => ({
  type: UPDATE_PRIMARY_TOKEN_FOR_THIRD_PARTY,
  payload,
  callback,
});

export const updateConfigDetailsFromAccountId = (
  queryParams: any,
  payload: any,
  callback?: CallableFunction
): UpdateConfigDetailsFromAccountId => ({
  type: UPDATE_CONFIG_DETAILS_FROM_ACCOUNT_ID,
  queryParams,
  payload,
  callback,
});

export const deleteToken = (
  payload: IDeleteToken,
  callback?: CallableFunction
): DeleteToken => ({
  type: DELETE_TOKEN_FOR_THIRD_PARTY,
  payload,
  callback,
});

export const getGoogleSheetsDataSource = (
  payload: IGSheetsDataSource,
  callback?: CallableFunction
): GetGSheetsDataSource => ({
  type: GET_G_SHEETS_DATA_SOURCE,
  payload,
  callback,
});

export const getGoogleSheetsURLValidation = (
  payload: IGSheetURLValidation,
  callback?: CallableFunction
): GetGSheetURLValidation => ({
  type: GET_G_SHEET_URL_VALIDATION,
  payload,
  callback,
});

export const getGoogleSheetsSyncHistory = (
  payload: IGSheetSyncHistory,
  callback?: CallableFunction
): GetGSheetSyncHistory => ({
  type: GET_G_SHEET_SYNC_HISTORY,
  payload,
  callback,
});

export const getGoogleSheetsTimezone = (
  payload: IGSheetTimezone,
  callback?: CallableFunction
): GetGSheetTimezone => ({
  type: GET_G_SHEET_TIMEZONE,
  payload,
  callback,
});

export const getGoogleSheetsResyncData = (
  payload: IGSheetResyncData,
  callback?: CallableFunction
): GetGResyncData => ({
  type: GET_G_SHEET_RESYNC_DATA,
  payload,
  callback,
});

export const addGoogleSheetsConfiguration = (
  queryParams: any,
  payload: IAddGSheetConfiguration,
  callback?: CallableFunction
): AddGSheetConfiguration => ({
  type: ADD_G_SHEET_CONFIGURATION,
  queryParams,
  payload,
  callback,
});

export const updateGoogleSheetConfiguration = (
  queryParams: any,
  payload: IUpdateGSheetConfiguration,
  callback?: CallableFunction
): UpdateGSheetConfiguration => ({
  type: UPDATE_G_SHEET_CONFIGURATION,
  queryParams,
  payload,
  callback,
});
