import {
  NavigationInitialState,
  SET_SOCKET_INSTANCE,
  SetSocketInstance,
} from "store/types/Navigation/navigation.types";

const initialState: NavigationInitialState = {};

export const navigationReducer = (
  state = initialState,
  { type, payload }: SetSocketInstance
) => {
  switch (type) {
    case SET_SOCKET_INSTANCE:
      return { ...state, ...payload };

    default:
      return state;
  }
};
