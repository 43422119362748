import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import { ReactComponent as CloseIconDark } from "../../../../../assets/svg/CloseIconDark.svg";

function IndividualSuggestion({
  chipData,
  keywordType,
  handleDelete,
  handleAdd,
  title,
  index,
}: {
  chipData: any;
  keywordType: string;
  handleDelete: any;
  handleAdd: any;
  title: string;
  index: number;
}) {
  const [inputText, setInputText] = useState("");

  const handleInputChange = (text: string) => {
    setInputText(text);
  };

  return (
    <Box
      className={"individual-suggestion"}
      sx={{
        padding: "0.75em 0.875em",
        background: "#F6F7F8",
        borderRadius: "7px",
        flex: "1 1 0",
      }}
    >
      <Typography
        sx={{
          fontSize: "0.875em",
          lineHeight: "1.5em",
          fontWeight: 600,
          fontFamily: "Inter",
          color: "#5C6674",
          marginBottom: "0.5em",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          height: "120px",
          overflow: "auto",
        }}
      >
        <Box
          className={"keywords-listing"}
          display={"flex"}
          alignItems={"center"}
          flexWrap={"wrap"}
          sx={{
            marginBottom: "0.5em",
          }}
        >
          {chipData.map((data: any, dataIndex: number) => {
            return (
              <ListItem
                key={data}
                sx={{
                  padding: "0.25em 0.5em 0.25em 0",
                  width: "auto",
                }}
              >
                <Chip
                  label={data}
                  variant="outlined"
                  onDelete={() => handleDelete(dataIndex, keywordType, index)}
                  sx={{
                    fontSize: "0.75em",
                    fontWeight: "500",
                    lineHeight: "1em",
                    backgroundColor: "#FFFFFF",
                    border: "none",
                  }}
                  deleteIcon={
                    <CloseIconDark
                      style={{
                        marginRight: "10px",
                      }}
                    />
                  }
                />
              </ListItem>
            );
          })}
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          marginTop: 1.5,
        }}
      >
        <Typography
          sx={{
            fontSize: "0.75rem",
            lineHeight: "1.25em",
            fontWeight: "600",
            fontFamily: "Inter",
            color: "#0869FB",
          }}
        >
          Add your Keyword
        </Typography>
        <Paper
          component="form"
          onSubmit={(event) => {
            event.preventDefault();
            handleAdd(inputText, keywordType, index);
            setInputText("");
          }}
          sx={{
            p: "0.5em 1em",
            display: "flex",
            alignItems: "center",
            width: "auto",
            marginTop: 0.75,
            boxShadow: "none",
            border: "1px solid #EAEAEC",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Write your keywords"
            onChange={(e: any) => handleInputChange(e.target.value)}
            value={inputText}
          />

          <Button
            variant="contained"
            type="submit"
            sx={{
              fontSize: "0.875em",
              fontWeight: "500",
              lineHeight: "1em",
              backgroundColor: "#F6F7F8",
              padding: "0.5em 0.25em",
              color: "#222222",
              textTransform: "capitalize",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#F6F7F8",
                color: "#222222",
                boxShadow: "none",
              },
            }}
          >
            Add
          </Button>
        </Paper>
      </Box>
    </Box>
  );
}

export default IndividualSuggestion;
