import { Box } from "@mui/material";
import styled from "styled-components";
export const StyledResultInfo = styled(Box)`
  position: relative;
  padding: 1em;
  background-color: #e4ebfa;
  width: auto;
  min-width: 17.8125em;
  border-radius: 0.625em;
  padding: 1em 1em 1.5em 1em;
`;
export const StyledPointer = styled.div`
  background: #e4ebfa;
  position: absolute;
  width: 3em;
  height: 1.8em;
  transform: rotate(45deg);
  /* z-index: -1; */
  right: -1em;
  top: 2em;
`;

export const StyledDivider = styled.div`
  border: 1px solid #d6e2e9;
  margin-top: 0.9em;
`;

export const StyledToolTipTitle = styled.p`
  color: #000;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.1875em;
  display: flex;
  align-items: center;
  color: #000000;
`;
export const StyledIndicatorComponent = styled.div`
  display: inline-block;
  width: 0.625em;
  height: 0.625em;
  border-radius: 50%;
  background-color: ${({ backgroundColor }: { backgroundColor: string }) =>
    backgroundColor};
  margin-right: 0.625em;
`;

export const StyledSubTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1em;
`;

export const StyledCountText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875em;
  padding-left: 1.5em;
  line-height: 1em;
  color: #333333;
  display: flex;
  align-items: center;
`;

export const StyledCurrentPeriodInfo = styled.div`
  display: flex;
  align-items: center;
`;
