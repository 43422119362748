import {
  SET_YOUTUBE_RECOMMENDED_AUDIENCE_COHORTS,
  SET_YOUTUBE_RECOMMENDED_VIDEO_COHORTS,
  SET_YOUTUBE_RECOMMENDED_CHANNEL_COHORTS,
  SET_YOUTUBE_TYPE,
  SET_YOUTUBE_LOADING,
} from "../../types/AudienceManager/youtube";

const initialState: any = {
  audienceCohorts: [],
  videoCohorts: [],
  channelCohorts: [],
  selectedYoutubeCohortType: "video",
  isLoadingYoutube: false,
};

export default function audienceManager(
  state = initialState,
  action: any
): any {
  switch (action.type) {
    case SET_YOUTUBE_RECOMMENDED_AUDIENCE_COHORTS: {
      return { ...state, audienceCohorts: [...action.payload] };
    }
    case SET_YOUTUBE_RECOMMENDED_VIDEO_COHORTS: {
      return { ...state, videoCohorts: [...action.payload] };
    }
    case SET_YOUTUBE_RECOMMENDED_CHANNEL_COHORTS: {
      return { ...state, channelCohorts: [...action.payload] };
    }
    case SET_YOUTUBE_TYPE: {
      return { ...state, selectedYoutubeCohortType: action.payload };
    }
    case SET_YOUTUBE_LOADING: {
      return { ...state, isLoadingYoutube: action.payload };
    }

    default:
      return state;
  }
}
