import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { setSocketInstance } from "store/actions/Navigation/navigation.actions";
import { fetchToken } from "utils/axios";
import { getLocalStorageData } from "utils/commonFunctions";
import { user_session_key } from "utils/constants";
import { WEBSOCKET_BASE_URL } from "utils/path";

export const useSocketConnection = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    handleSocketConnection();
  }, []);

  const handleSocketConnection = async () => {
    const token = await getTokenData();
    const socket = io(WEBSOCKET_BASE_URL, {
      auth: { token },
    });

    socket.on("connect", () => {
      socket.emit("join");
    });

    socket.on("message", (msg) => {
      dispatch(setSocketInstance({ socket }));
    });

    socket.on("disconnect", () => {
      console.log("see", socket.connected); // false
    });

    socket.on("connect_error", () => {
      // socket.auth.token = "abcd";
      socket.connect();
    });

    socket.io.on("reconnect_attempt", () => {
      // ...
    });

    socket.io.on("reconnect", () => {
      // ...
    });
  };

  const getTokenData = async () => {
    const session = getLocalStorageData(user_session_key);
    const tokenIsExpired =
      parseInt(session?.expiresAt || 0) < new Date().getTime();
    if (tokenIsExpired) {
      return await fetchToken();
    } else {
      return session.accessToken;
    }
  };
};
