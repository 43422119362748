import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../../../../shared";

export const StyledMainDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 0.3125rem;
  flex-direction: row;
  overflow: hidden;
`;

export const StyledRecommendationTitle = styled.h3`
  font-family: Lato;
  font-weight: 700;
  padding-bottom: 4px;
`;

export const StyledTabPanelMainDiv = styled.div`
  // margin-top: 24px;
  // padding-top: 4px;
  // padding-bottom: 10px;
  overflow: scroll;
  // height: 100vh;
  width: 100%;
  flex: 1;
  min-height: 0px;
  max-width: 843px;
`;

export const StyledOptionsContainer = styled.article`
  position: relative;
  width: 100%;
`;

export const StyledIntroText = styled.p`
  font-family: "Lato";
  font-style: normal;
  // font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-bottom: 3px;
  margin-top: 2rem;
  position: relative;
  left: calc(38px + 0.25rem);
  width: calc(100% - (1.75rem + 102px));
`;

export const StyledButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 28px;
`;

export const StyledPreviewAndAdSettings = styled.div`
  // margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 8px;
  width: 30%;
  height: 100%;
  box-shadow: 0px 4.83018px 16.9056px rgba(0, 0, 0, 0.05);
  position: relative;
`;

export const StyledShowMore = styled.a`
  margin-left: 5px;
  font-size: 15px;
  font-family: "Lato";
  font-style: normal;
  // font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: #0869fb !important;
  border-bottom: 1px solid #0869fb;
  cursor: pointer;
`;

export const StyledNewIcon = styled.div`
  margin-top: 1rem;
`;
export const StyledButton = styled(Button)`
  font-size: 0.875rem;
`;

//----imageComponent

// export const StyledImageHolder = styled.div`
// // margin-top: 5rem;
// // padding-bottom: 0.5rem;
// height: 50%;
// `;

export const StyledText = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  color: rgb(0, 0, 0);
  padding: 1rem 0rem 0rem 0rem;
`;

export const StyledImageContainer = styled.div`
  aspect-ratio: 2/1;
  background-color: rgba(242, 242, 242, 0.5);
  flex-direction: column;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: relative;
  left: calc(38px + 0.25rem);
  width: calc(100% - (1.75rem + 102px));
  padding: 24px;
`;
// export const StyledImage = styled.img`
//  border-radius:0.5rem;
// `
// export const StyledImagebox = styled.div`
// // padding:2rem;
// margin: 2rem;
// display:flex;
//  align-items:center;
//  justify-content:space-between;
//  flex-direction:column;
// `

// export const StyledToolBoxContainer=styled.div`
//   flex:0.5;
//   height:100%;
//   padding:2% 0px 2vh 0px;
// `;
export const StyledToolBox = styled.div`
  height: 100%;
  max-width: 9em;
  min-width: 7.6em;
  // width: 7.5625em;
  border: 0.0625em solid #efefef;
  border-radius: 0.5em;
  box-shadow: 0em 0.3125em 1em rgba(0, 0, 0, 0.05);
  padding: 0.75em;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  h3 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0.5em;
    color: #575757;
    padding-top: 0.75em;
  }
  .configurationButton {
    border-radius: 0.5em;
    font-family: Inter;
    .MuiButton-startIcon {
      margin-right: 0.3em;
    }
    svg {
      width: 0.7em;
      height: 0.7em;
    }
  }
  .configurationButton-label {
    text-transform: capitalize;
    font-size: 0.7em;
    font-weight: 600;
  }
`;
interface ToolbarItemProps {
  active?: boolean;
  disabled?: boolean;
}
export const StyledToolbarItem = styled.div<ToolbarItemProps>`
  flex: 1;
  padding: 0.625em;
  max-height: 6.5625em;
  background-color: ${({ active }) => (active ? "#F4F7FF" : "#F9F9F9")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5em;
  margin-bottom: 0.625em;
  mouse-pointer: cursor;
  color: ${({ active, disabled }) =>
    active ? "#0869FB" : disabled ? "#D9D9D9" : "#242424"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  h6 {
    font-size: 0.75em;
    margin-bottom: 0px;
  }
  svg {
    margin-bottom: 0.5em;
    path {
      fill: ${({ active, disabled }) =>
        active ? "#0869FB" : disabled ? "#D9D9D9" : ""};
    }
  }
  .toolbox-heading-2 {
    margin-top: 1.25em;
  }
`;
export const StyledEditor = styled.div`
  flex: 1;
  height: 100%;
  padding: 0px 3vw 0px 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    font-size: 1.25rem;
    text-align: center;
  }
  scroll-behavior: smooth;
  overflow: scroll;
`;
// export const StyledPreview=styled.div`
//     flex:1.5;
//     height:100%;
//     // border:1px solid red;
//     display:flex;
//     flex-direction:column;
//     align-items:center;
// `

// welcomemediascreen

export const StyledMediaCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 21px 6px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 100%;
  min-height: 73%;
  display: flex;
  justify-content: center;
`;
export const StyledMediaStudioHeader = styled.p`
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2.125rem;
  text-align: center;
  margin-top: 4rem;
`;
export const StyledMediaCardContent = styled.div`
  padding: 2rem 2rem 4rem 2rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
export const StyledMediaStudioSubTitle = styled.p`
  text-align: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 26px;
  text-align: center;
  color: #7a7a7a;
  width: 65%;
  margin-right: auto;
  margin-left: auto;
  align-text: center;
`;

export const StyledMediaStudioImage = styled.img`
  vertical-align: middle;
  align-items: center;
  align-self: center;
  display: block;
  width: 55%;
  margin-right: auto;
  margin-left: auto;
  //     width: 454px;
  // height: 300px;
`;
export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  padding-bottom: 0.5rem;
`;
