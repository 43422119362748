import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function SelectUI({
  data,
  value,
  handleChange,
}: {
  data: any;
  value: any;
  handleChange: any;
}) {
  return (
    <Select
      sx={{
        fontFamily: "Inter",
        fontSize: "0.75em",
        fontWeight: 700,
        lineHeight: "1.25em",
        color: "#0869FB",
        borderColor: "#E2E2E2",
        svg: {
          color: "#0869FB",
        },
        ".MuiInputBase-input": {
          p: 0.5,
          paddingLeft: 1,
          minHeight: "auto",
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "#E2E2E2 !important",
          borderWidth: "1px !important",
        },
      }}
      value={value}
      onChange={handleChange}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
    >
      {data?.length ? (
        data.map((eachData: any) => {
          return (
            <MenuItem
              sx={{
                fontFamily: "Inter",
                fontSize: "0.75em",
                fontWeight: 700,
                lineHeight: "1.25em",
              }}
              value={eachData?.value || ""}
            >
              {eachData?.label}
            </MenuItem>
          );
        })
      ) : (
        <MenuItem
          sx={{
            fontFamily: "Inter",
            fontSize: "0.75em",
            fontWeight: 700,
            lineHeight: "1.25em",
          }}
          value=""
        >
          None
        </MenuItem>
      )}
    </Select>
  );
}

export default SelectUI;
