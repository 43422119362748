import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import {
  StyledAnimatorTagContainer,
  StyledNewTag,
  StyledNewTagContainer,
  StyledVariantBottom,
  StyledVariantHeader,
} from "./styled";
import {
  LightTooltip,
  TextWithTooltip,
} from "components/CreativeAI_Revamp/shared";
import { CheckBox, ConfirmDialog } from "components/shared";
import StatusTag from "components/shared/Input/StatusTag/StatusTag";
import { ReactComponent as Edit } from "../../../../../assets/cai/svg/edit_icon.svg";
import { ReactComponent as Delete } from "../../../../../assets/cai/svg/delete_icon.svg";
import { ReactComponent as Copy } from "../../../../../assets/cai/svg/copy_icon.svg";
import { ReactComponent as CreativeAnimator } from "../../../../../assets/svg/CreativeAnimator.svg";
import { ReactComponent as UTG } from "../../../../../assets/svg/sparkles_tooltip.svg";
import { ReactComponent as CopyDark } from "../../../../../assets/svg/copy_dark.svg";

import InHousePreview from "components/CreativeAI/Facebook/shared/InHousePreview/InHousePreview";
import { useVariantCard } from "../hooks/useVariantCard";
import { checkAdType } from "../../utils/common.utils_old";
import { StyledLoaderBox } from "components/CreativeAI/Facebook/CreateVariants/AdPreview/styled";
import PerformanceTooltip from "components/CreativeAI_Revamp/shared/PerformanceTooltip/PerformanceTooltip";
import VarinatSpedometer from "components/CreativeAI/Facebook/shared/PerformanceScore/VarinatSpedometer";
import { CustomizedSnackBars } from "components/shared/AlertSnackBar/AlertSnackBar";
import { useSearchParams } from "react-router-dom-v5-compat";
import { REACT_APP_BASE_URL_PIXIS2 } from "utils/path";

export default function VariantCard({
  id,
  item,
  index,
  isNew,
}: {
  id: string;
  item: any;
  index: number;
  isNew?: boolean;
}) {
  const {
    deleteVariant,
    onEdit,
    onCheckBoxClick,
    isDeletingVariant,
    createSpec,
    mediaUrl,
    setMediaUrl,
    showDeletePopUp,
    setShowDeletePopUp,
    isMediaUrlLoading,
    isChecked,
    onCopy,
    adAdditionalInfoData,
  } = useVariantCard({ item, index });
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");

  // performance tooltip related code
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handlePerformancePopoverOpen = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };

  const handlePerformancePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPerformanceTooltip = Boolean(anchorEl);

  // tooltip code end here
  const [copied, setCopied] = useState<boolean>(false);
  const copyText = () => {
    if(isViewedFromBeta)
      {
      window.parent.postMessage(
        {
          type: 'COPY-TO-CLIPBOARD',
          copiedText: item?.payload?.userPromptText,
        },
        '*'
      );
    }
    setCopied(true);
    navigator.clipboard.writeText(item?.payload?.userPromptText);
  };

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" minWidth={0} id={id}>
        <StyledVariantHeader>
          {item?.CreativeAiData?.billboardMediaId && (
            <LightTooltip
              title="This ad has been created using creative animator"
              arrow
              sx={{ width: "18em" }}
            >
              <StyledAnimatorTagContainer>
                <CreativeAnimator />
              </StyledAnimatorTagContainer>
            </LightTooltip>
          )}
          {item?.variantType == "UTG" && (
            <LightTooltip
              bgColor="black"
              placement="top"
              title={
                <Box
                  sx={{
                    background: "black",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    paddingRight={"0.75em"}
                    onClick={copyText}
                    sx={{ cursor: "pointer" }}
                  >
                    <CopyDark height={"0.75em"} />
                  </Box>
                  <Typography
                    noWrap
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "0.875em",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "1.375em",
                      letterSpacing: "0.00875em",
                      color: "white",
                    }}
                  >
                    {item?.payload?.userPromptText}
                  </Typography>
                </Box>
              }
              arrow
              sx={{ width: "18em" }}
            >
              <StyledAnimatorTagContainer>
                <UTG />
              </StyledAnimatorTagContainer>
            </LightTooltip>
          )}
          <VarinatSpedometer
            handlePerformancePopoverOpen={handlePerformancePopoverOpen}
            handlePerformancePopoverClose={handlePerformancePopoverClose}
            index={index}
            infoItem={item?.performanceScore}
            isBillboard={item?.variantType === "billboard"}
            mediaURL={mediaUrl}
          />
          <Box display="flex" flexDirection="column" flex={1} minWidth={0}>
            <TextWithTooltip
              text={item?.payload?.settings?.variantName ?? "-"}
              fontSize="0.75em"
              lineHeight="1.25em"
              fontWeight={400}
              color="#262626"
            />
            <Box pt="0.125em" />
            {isNew ? (
              <StyledNewTagContainer>
                <StyledNewTag href="#">New</StyledNewTag>
              </StyledNewTagContainer>
            ) : (
              item?.reviewStatus && (
                <StatusTag
                  // href={`${window.location.origin}/review/cai/facebook/${item?.variantInfo?.reviewRequestHash}?variantId=${item?.id}`}
                  href={`${isViewedFromBeta ? REACT_APP_BASE_URL_PIXIS2: window.location.origin}/review/cai/meta/${item?.variantInfo?.reviewRequestHash}?variantId=${item?.id}`}
                  status={item?.reviewStatus}
                  isViewedFromBeta={isViewedFromBeta}
                />
              )
            )}
          </Box>
          <CheckBox checked={isChecked} onClick={onCheckBoxClick} />
        </StyledVariantHeader>
        <StyledVariantBottom>
          {item?.id &&
            (isMediaUrlLoading ? (
              <StyledLoaderBox>
                <CircularProgress />
              </StyledLoaderBox>
            ) : (
              <InHousePreview
                displayURL={
                  item?.payload?.settings?.displayUrl.length
                    ? item?.payload?.settings?.displayUrl
                    : item?.payload?.settings?.url
                }
                CTA_button={
                  createSpec?.call_to_action_type ??
                  createSpec?.asset_feed_spec?.call_to_action_types?.[0]
                }
                previewType={
                  item?.variantType === "billboard"
                    ? "video"
                    : checkAdType(createSpec) === "link_data"
                    ? "image"
                    : "video"
                }
                profileAvatar={adAdditionalInfoData?.avatarUrl ?? ""}
                profileName={adAdditionalInfoData?.avatarName ?? ""}
                recommendationData={{
                  primary_text: item.CreativeAiData.primaryText.name,
                  description: item.CreativeAiData.description.name,
                  headline: item.CreativeAiData.headline.name,
                }}
                mediaURL={mediaUrl}
                setMediaURL={setMediaUrl}
                item={item}
                origin="homescreen"
              />
            ))}
          <Box
            display="flex"
            justifyContent="space-between"
            pt=".25em"
            mb="-.15em"
          >
            <LightTooltip
              title={
                item?.variantType === "billboard"
                  ? "Currently, you cannot edit Billboard Ads"
                  : ""
              }
              arrow
              placement="top-end"
            >
              <Box>
                <IconButton
                  onClick={onEdit}
                  disabled={item?.variantType === "billboard"}
                  sx={{
                    ":disabled": {
                      opacity: 0.5,
                    },
                  }}
                >
                  <Edit />
                </IconButton>
              </Box>
            </LightTooltip>
            <LightTooltip
              title={
                item?.variantType === "billboard"
                  ? "Currently, you cannot copy Billboard Ads"
                  : ""
              }
              arrow
              placement="top-start"
            >
              <Box>
                <IconButton
                  onClick={onCopy}
                  disabled={item?.variantType === "billboard"}
                  sx={{
                    ":disabled": {
                      opacity: 0.5,
                    },
                  }}
                >
                  <Copy />
                </IconButton>
              </Box>
            </LightTooltip>
            <IconButton onClick={() => setShowDeletePopUp(true)}>
              <Delete />
            </IconButton>
          </Box>
        </StyledVariantBottom>
      </Box>
      <PerformanceTooltip
        open={openPerformanceTooltip}
        anchorEl={anchorEl}
        infoItem={item?.performanceScore}
        place="variant"
      />
      {showDeletePopUp && (
        <ConfirmDialog
          show={showDeletePopUp}
          onClose={() => setShowDeletePopUp(false)}
          title="Are you sure you wanted to delete this variant"
          onComplete={deleteVariant}
          completeButtonText="Confirm"
          loading={isDeletingVariant}
        />
      )}
      {copied && (
        <CustomizedSnackBars
          handleClose={setCopied(false)}
          open={copied}
          msg="Text copied to clipboard"
        />
      )}
    </Fragment>
  );
}
