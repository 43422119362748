import httpService from "HttpService/HttpService";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getCohort } from "store/actions/CreativeAI/Facebook/SelectCohort/selectCohort";
import {
  GetAdsetById,
  GET_ADSET_BY_ID,
  SET_ADSET_DETAILS,
  TriggerContactUs,
  TRIGGER_CONTACT,
  TRIGGER_CONTACT_US,
} from "store/types/CreativeAI_Revamp/facebookTypes";
import { CROSS_PLATFORM_URL } from "utils/path";

function* getAdsetById(action: GetAdsetById): any {
  const {
    payload: { user, adsetId },
    callback,
  } = action;
  try {
    const urlParams = `/v1/api/adset-creative-ai/adsets/${adsetId}`;
    const response = yield call(
      httpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (response.data.success) {
      // callback && callback(response.data.data, false)
      yield put({ type: SET_ADSET_DETAILS, payload: response.data.data });
      put(getCohort(response.data.data));
    } else {
      // callback && callback(response.data, true)
      yield put({ type: SET_ADSET_DETAILS, payload: {} });
    }
  } catch (error) {
    console.log("error at bundle status");
    yield put({ type: SET_ADSET_DETAILS, payload: {} });
  }
}

function* triggerContactUs(action: TriggerContactUs): any {
  const {
    payload: { user, data },
    callback,
  } = action;
  try {
    yield put({
      type: TRIGGER_CONTACT,
      payload: { isLoading: true, isFailed: false },
    });
    const urlParams = `/v1/api/creative-variants/contact-support`;
    const response = yield call(
      httpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      data
    );
    if (response.data.success) {
      yield put({
        type: TRIGGER_CONTACT,
        payload: {
          isLoading: false,
          message: response.data?.message,
          isFailed: false,
        },
      });
    } else {
      yield put({
        type: TRIGGER_CONTACT,
        payload: { isLoading: false, isFailed: true },
      });
    }
  } catch (error) {
    yield put({
      type: TRIGGER_CONTACT,
      payload: { isLoading: false, isFailed: true },
    });
  }
}
export function* caiFBWatcher() {
  yield takeLatest(GET_ADSET_BY_ID, getAdsetById);
  yield takeLatest(TRIGGER_CONTACT_US, triggerContactUs);
}
