import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import ModalContainer from "components/Modal/ModalContainer";
import ModalWrapper from "components/Modal/ModalWrapper";
import { ReactComponent as Tick } from "../assets/Tick.svg";
import { ReactComponent as Error } from "../assets/Error.svg";
import { FREQUENCY, TIMEPERIOD } from "./constants";
import DateTimePickerValue from "./DateTimePicker";
import { useSelector, useDispatch } from "react-redux";
import SelectDropdown from "./SelectDropdown";
import Popover from "@mui/material/Popover";
import {
  getGoogleSheetsDataSource,
  getGoogleSheetsURLValidation,
  getGoogleSheetsTimezone,
  addGoogleSheetsConfiguration,
  updateGoogleSheetConfiguration,
  getThirdPartyAccounts,
  setThirdPartyAccount,
  setError,
} from "store/actions/thirdPartyChannels";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { isAdminRole, isViewerRole } from "utils/commonFunctions";
dayjs.extend(utc);
dayjs.extend(timezone);
function GoogleSheetsModal({
  title,
  subtitle,
  closeModal,
  openedAs,
  editData,
  updateRows,
  setIsLoading,
}: {
  title: string;
  subtitle: string;
  closeModal: Function;
  openedAs?: string;
  editData?: any;
  updateRows?: any;
  setIsLoading?: Function;
}) {
  const adaccountState = useSelector((state: any) => state.adaccount);
  const thirdPartyChannelsState = useSelector(
    (state: any) => state.thirdPartyChannels
  );
  const brandId = adaccountState.selectedBrand.id;
  const { brandRoles } = adaccountState;
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: any) => state.userInfoReducer);
  // const isAdmin = userInfo?.roles?.includes("super-admin");
  const styles = {
    inputBoxHeading: {
      fontSize: "14px",
      fontWeight: "400",
      fontFamily: "Inter",
      color: "#666666",
      marginBottom: "6px",
    },
  };
  const [sheetURL, setSheetURL] = useState<string>("");
  const [dataSourceArray, setDataSourceArray] = useState<any[]>([]);
  const [selectedDataSource, setSelectedDataSource] = useState<any>({});
  const [sheetNameArray, setSheetNameArray] = useState<any[]>([]);
  const [selectedSheetName, setSelectedSheetName] = useState<any>({});
  const [timezoneArray, setTimezoneArray] = useState<any[]>([]);
  const [selectedTimezone, setSelectedTimezone] = useState<any>({});
  const [isSheetURLValid, setIsSheetURLValid] = useState<boolean | undefined>(
    undefined
  );
  const [sheetURLValidationIcon, setSheetURLValidationIcon] =
    useState<boolean>(false);
  const [selectedFrequency, setSelectedFrequency] = useState<any>({});
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<any>({});
  const editInitialData = editData?.config;
  const [selectedDateTime, setSelectedDateTime] = useState<Dayjs | any>(
    openedAs === "edit" && editInitialData?.scheduleDetails?.date
      ? dayjs(editInitialData.scheduleDetails.date)
      : undefined
  );
  const [configDisabled, setConfigDisabled] = useState<boolean>(false);
  const [scheduleFieldsEnabled, setScheduleFieldsEnabled] =
    useState<boolean>(false);
  const [disableSaveButtonOnAPISubmit, setDisableSaveButtonOnAPISubmit] =
    useState<boolean>();
  const [sheetURLFontColor, setSheetURLFontColor] = useState("#999");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const callDataSourceAPI = () => {
    dispatch(
      getGoogleSheetsDataSource({ brandId: brandId }, (response: any) => {
        if (response?.success && response?.data) {
          setDataSourceArray(response.data);
        }
      })
    );
  };

  const callTimezoneAPI = () => {
    dispatch(
      getGoogleSheetsTimezone({ brandId: brandId }, (response: any) => {
        if (response?.success && response?.data) {
          setTimezoneArray(response.data);
        }
      })
    );
  };

  const callSheetURLValidationAPI = () => {
    dispatch(
      getGoogleSheetsURLValidation({ brandId, sheetURL }, (response: any) => {
        if (response?.success && response?.data) {
          setSheetURLValidationIcon(true);
          if (response.data.accessible) {
            setIsSheetURLValid(true);
            setSheetURLFontColor("#999");
          } else {
            setIsSheetURLValid(false);
            setSheetURLFontColor("#E84B47");
          }
          setSheetNameArray(response.data.sheetData);
        }
      })
    );
  };

  const handleEditCase = () => {
    handleMandatoryFieldsValidation();
    handleNonMandatoryFieldsValidation();
    if (Object.keys(editInitialData || {}).length > 0) {
      if (Object.keys(editInitialData.scheduleDetails).length === 0) {
        setScheduleFieldsEnabled(false);
      } else {
        setScheduleFieldsEnabled(true);
      }
      setSheetURL(editInitialData.sheetUrl);
      setConfigDisabled(editInitialData?.disable);
      if (editInitialData?.scheduleDetails?.timePeriod) {
        const timeperiod = TIMEPERIOD.find(
          (item: any) => item.id === editInitialData.scheduleDetails.timePeriod
        );
        setSelectedTimePeriod(timeperiod);
        if (timeperiod) {
          const frequencyList = FREQUENCY(timeperiod.id);
          const frequency = frequencyList.find(
            (item: any) => item.id === editInitialData.scheduleDetails.frequency
          );
          setSelectedFrequency(frequency);
        }
      }
    }
  };
  const handleHistoricalDateTimeValidation = () => {
    if (scheduleFieldsEnabled) {
      if (selectedTimezone?.timezone && selectedDateTime) {
        const selectedDateTimeGMT = selectedDateTime.toLocaleString("en-US", {
          timeZone: selectedTimezone?.timezone && selectedTimezone?.timezone,
        });
        const selectedTimeZoneCurrentTime = new Date().toLocaleString("en-US", {
          timeZone: selectedTimezone?.timezone && selectedTimezone?.timezone,
        });
        const selectedTimeZoneCurrentTimeGMT = new Date(
          selectedTimeZoneCurrentTime
        ).toUTCString();

        if (
          Date.parse(selectedDateTimeGMT) >
          Date.parse(selectedTimeZoneCurrentTimeGMT)
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const handleSave = () => {
    setDisableSaveButtonOnAPISubmit(true);
    const addGSheetsConfigurationPayload = {
      sheetUrl: sheetURL,
      sheetName: selectedSheetName?.name,
      dataSource: selectedDataSource?.abbreviation,
      scheduleDetails: scheduleFieldsEnabled
        ? {
            date: selectedDateTime.format("YYYY-MM-DDTHH:mm:ss"),
            timeZone: selectedTimezone?.timezone,
            timePeriod: selectedTimePeriod?.id,
            frequency: selectedFrequency?.id,
          }
        : {},
    };
    const editGsheetsConfigurationPayload = {
      sheetName: selectedSheetName?.name,
      scheduleDetails: scheduleFieldsEnabled
        ? {
            date: selectedDateTime.format("YYYY-MM-DDTHH:mm:ss"),
            timeZone: selectedTimezone?.timezone,
            timePeriod: selectedTimePeriod?.id,
            frequency: selectedFrequency?.id,
          }
        : {},
      disable: configDisabled,
    };

    if (openedAs === "add") {
      dispatch(
        addGoogleSheetsConfiguration(
          { brandId },
          addGSheetsConfigurationPayload,
          (response: any) => {
            setDisableSaveButtonOnAPISubmit(false);
            if (response.success) {
              setIsLoading && setIsLoading(true);
              dispatch(
                getThirdPartyAccounts({
                  brandId,
                  callback: (value: any) => {
                    dispatch(setThirdPartyAccount(value));
                    if (value?.data) {
                      setIsLoading && setIsLoading(false);
                      updateRows(value?.data);
                      // window.location.reload(); // temp-fix
                    }
                  },
                })
              );
              closeModal();
            }
          }
        )
      );
    } else if (openedAs === "edit") {
      dispatch(
        updateGoogleSheetConfiguration(
          { brandId, sheetId: editData.id, sheetName: editData.name },
          editGsheetsConfigurationPayload,
          (response: any) => {
            setDisableSaveButtonOnAPISubmit(false);
            if (response.success) {
              setIsLoading && setIsLoading(true);
              dispatch(
                getThirdPartyAccounts({
                  brandId,
                  callback: (value: any) => {
                    dispatch(setThirdPartyAccount(value));
                    if (value?.data) {
                      setIsLoading && setIsLoading(false);
                      updateRows(value?.data);
                      // window.location.reload(); // temp-fix
                    }
                  },
                })
              );
              closeModal();
            }
          }
        )
      );
    }
  };
  useEffect(() => {
    console.log("LL: anchorEl", anchorEl);
  }, [anchorEl]);

  useEffect(() => {
    callDataSourceAPI();
    callTimezoneAPI();
    if (openedAs === "add") {
      setSheetURL("");
    } else {
      handleEditCase();
    }
  }, [openedAs]);

  useEffect(() => {
    if (openedAs === "edit") {
      if (dataSourceArray?.length > 0) {
        const dataSrc = dataSourceArray?.find(
          (item: any) => item.abbreviation === editInitialData.dataSource
        );
        setSelectedDataSource(dataSrc);
      }
    }
  }, [dataSourceArray]);

  useEffect(() => {
    if (openedAs === "edit") {
      if (timezoneArray?.length > 0) {
        const timezone = timezoneArray?.find(
          (item: any) =>
            item.timezone === editInitialData?.scheduleDetails?.timeZone
        );
        setSelectedTimezone(timezone);
      }
    }
  }, [timezoneArray]);

  useEffect(() => {
    if (openedAs === "edit") {
      callSheetURLValidationAPI();
    }
  }, [sheetURL]);

  useEffect(() => {
    if (openedAs === "edit") {
      if (sheetNameArray?.length > 0) {
        const sheetName = sheetNameArray?.find(
          (item: any) => item.name === editInitialData?.sheetName
        );
        setSelectedSheetName(sheetName);
      }
    }
  }, [sheetNameArray]);

  useEffect(() => {
    handleHistoricalDateTimeValidation();
  }, [selectedTimezone, selectedDateTime, scheduleFieldsEnabled]);

  const handleTextFieldBlur = () => {
    callSheetURLValidationAPI();
  };

  const handleSelection = (key: any) => (value: any) => {
    switch (key) {
      case "Tab Name":
        setSelectedSheetName(value);
        break;
      case "Data Source":
        setSelectedDataSource(value);
        break;
      case "Time Zone":
        setSelectedTimezone(value);
        break;
      case "Time Period":
        setSelectedTimePeriod(value);
        break;
      case "Frequency":
        setSelectedFrequency(value);
        break;
      default:
        break;
    }
  };

  const handleMandatoryFieldsValidation = () => {
    if (
      sheetURL.startsWith(" ") ||
      sheetURL.length === 0 ||
      Object.keys(selectedSheetName || {})?.length === 0 ||
      Object.keys(selectedDataSource || {})?.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleNonMandatoryFieldsValidation = () => {
    // if (!scheduleFieldsEnabled) return false;
    if (
      !selectedDateTime &&
      Object.keys(selectedFrequency || {})?.length === 0 &&
      Object.keys(selectedTimePeriod || {})?.length === 0 &&
      Object.keys(selectedTimezone || {})?.length === 0 &&
      !scheduleFieldsEnabled
    ) {
      return false;
    } else if (
      selectedDateTime &&
      Object.keys(selectedFrequency || {})?.length > 0 &&
      Object.keys(selectedTimePeriod || {})?.length > 0 &&
      Object.keys(selectedTimezone || {})?.length > 0 &&
      !scheduleFieldsEnabled
    ) {
      return false;
    } else if (
      selectedDateTime &&
      Object.keys(selectedFrequency || {})?.length > 0 &&
      Object.keys(selectedTimePeriod || {})?.length > 0 &&
      Object.keys(selectedTimezone || {})?.length > 0 &&
      scheduleFieldsEnabled
    ) {
      return false;
    } else {
      return true;
    }
  };

  const open = Boolean(anchorEl);

  return (
    <ModalWrapper>
      <ModalContainer
        className="third-party-modal"
        width="696px"
        title={title}
        subtitle={subtitle}
        handleClose={() => {
          if (thirdPartyChannelsState?.error?.isError) {
            dispatch(setError({ isError: false, error: {} }));
          }
          closeModal();
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              fontFamily: "Inter",
              color: "#333333",
              marginBottom: "16px",
            }}
          >
            Input Details&nbsp;
            <Typography
              sx={{ fontWeight: 300, fontSize: "10px", color: "#999" }}
            >
              <i>(All Input Details fields are required)</i>
            </Typography>
          </Typography>

          <Stack
            justifyContent="space-between"
            sx={{ marginBottom: "24px" }}
            direction="row"
          >
            <Box>
              <Typography style={styles.inputBoxHeading}>Sheet URL*</Typography>
              <Box
                sx={{
                  opacity: openedAs === "edit" ? 0.4 : 1,
                  cursor: openedAs === "edit" ? "not-allowed" : "text",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "310px",
                  height: "42px",
                  padding: "11px 12px 11px 16px",
                  border: "1px solid #DDDDDD",
                  borderRadius: "8px",
                }}
              >
                <TextField
                  disabled={openedAs === "edit"}
                  onBlur={handleTextFieldBlur}
                  title={sheetURL}
                  sx={{
                    cursor: openedAs === "edit" ? "not-allowed" : "text",
                    padding: "0",
                    height: "18px",
                    width: "100%",
                    marginRight: "10px",
                    "& fieldset": { border: "none" },
                    "& .MuiInputBase-input": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: 0,
                      color: sheetURLFontColor,
                    },
                    "& .MuiInputBase-root": {
                      fontSize: "12px",
                      fontFamily: "Inter",
                      fontWeight: 400,
                    },
                  }}
                  value={sheetURL}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  onChange={(event: any) => {
                    setSheetURL(event.target.value);
                  }}
                />
                {sheetURLValidationIcon &&
                  (isSheetURLValid ? (
                    <Box>
                      <Tick />
                    </Box>
                  ) : (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onMouseEnter={(event: any) => {
                        setAnchorEl(event.currentTarget);
                      }}
                      onMouseLeave={() => {
                        setAnchorEl(null);
                      }}
                    >
                      <Error style={{ height: "16px", width: "16px" }} />
                    </Box>
                  ))}
                <Popover
                  style={{ pointerEvents: "none" }}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Typography
                    sx={{
                      width: "14rem",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      letterSpacing: "0px",
                      textAlign: "left",
                      p: 2,
                    }}
                  >
                    We are unable to access the link. Please share access on our
                    service email. The link may be incorrect or expired.
                  </Typography>
                </Popover>
              </Box>
            </Box>
            <Box>
              <Typography style={styles.inputBoxHeading}>
                Sheet Name*
              </Typography>
              <SelectDropdown
                heading="Sheet Name"
                selectedValue={selectedSheetName}
                changeSelectedValue={(value: any) => {
                  setSelectedSheetName(value);
                }}
                data={sheetNameArray}
                onChange={handleSelection("Tab Name")}
              />
            </Box>
          </Stack>
          <Stack
            justifyContent="space-between"
            sx={{ marginBottom: "24px" }}
            direction="row"
          >
            <Box>
              <Typography style={styles.inputBoxHeading}>
                Data Source*
              </Typography>
              <SelectDropdown
                disabled={openedAs === "edit"}
                heading="Data Source"
                selectedValue={selectedDataSource}
                changeSelectedValue={(value: any) => {
                  selectedDataSource(value);
                }}
                data={dataSourceArray}
                onChange={handleSelection("Data Source")}
              />
            </Box>
            {/* {selectedDataSource?.name === "Others" && (
              <Box>
                <Typography style={styles.inputBoxHeading}>
                  Data Source Name
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "310px",
                    height: "42px",
                    padding: "11px 12px 11px 16px",
                    border: "1px solid #DDDDDD",
                    borderRadius: "8px",
                  }}
                >
                  <TextField
                    sx={{
                      padding: "0",
                      height: "18px",
                      width: "100%",
                      marginRight: "10px",
                      "& fieldset": { border: "none" },
                      "& .MuiInputBase-input": {
                        padding: 0,
                      },
                      "& .MuiInputBase-root": {
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: 400,
                      },
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    onChange={(event: any) => {
                      console.log(event.target.value);
                    }}
                  />
                </Box>
              </Box>
            )} */}
          </Stack>
        </Box>
        <Box>
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Inter",
                color: "#333333",
                marginBottom: "16px",
              }}
            >
              Schedule&nbsp;
              <Typography
                sx={{ fontWeight: 300, fontSize: "10px", color: "#999" }}
              >
                <i>(All Schedule fields must be filled or left empty)</i>
              </Typography>
            </Typography>
            <Box>
              <Switch
                checked={scheduleFieldsEnabled}
                onClick={() => {
                  setScheduleFieldsEnabled(!scheduleFieldsEnabled);
                }}
              />
            </Box>
          </Stack>
          <Stack sx={{ marginBottom: "24px" }}>
            <Stack justifyContent="space-between" direction="row">
              <Box>
                <Typography style={styles.inputBoxHeading}>
                  Select Date
                </Typography>
                <DateTimePickerValue
                  setSelectedDateTime={setSelectedDateTime}
                  selectedDateTime={selectedDateTime}
                  disabled={!scheduleFieldsEnabled}
                />
              </Box>
              <Box>
                <Typography style={styles.inputBoxHeading}>
                  Select Time Zone
                </Typography>
                <SelectDropdown
                  heading="Time Zone"
                  selectedValue={selectedTimezone}
                  changeSelectedValue={(value: any) => {
                    setSelectedTimezone(value);
                  }}
                  data={timezoneArray}
                  onChange={handleSelection("Time Zone")}
                  disabled={!scheduleFieldsEnabled}
                />
              </Box>
            </Stack>
            {handleHistoricalDateTimeValidation() && scheduleFieldsEnabled && (
              <Typography
                sx={{ fontWeight: 300, fontSize: "10px", color: "#E84B47" }}
              >
                Historical date cannot be selected
              </Typography>
            )}
          </Stack>
          <Stack
            justifyContent="space-between"
            sx={{ marginBottom: "24px" }}
            direction="row"
          >
            <Box>
              <Typography style={styles.inputBoxHeading}>
                Time Period
              </Typography>
              <SelectDropdown
                heading="Time Period"
                selectedValue={selectedTimePeriod}
                changeSelectedValue={setSelectedTimePeriod}
                data={TIMEPERIOD}
                onChange={handleSelection("Time Period")}
                disabled={!scheduleFieldsEnabled}
              />
            </Box>
            <Box>
              <Typography style={styles.inputBoxHeading}>Frequency</Typography>
              <SelectDropdown
                heading="Frequency"
                selectedValue={selectedFrequency}
                changeSelectedValue={setSelectedFrequency}
                data={
                  selectedTimePeriod &&
                  Object.keys(selectedTimePeriod || {}).length > 0
                    ? FREQUENCY(selectedTimePeriod?.id || 0)
                    : []
                }
                onChange={handleSelection("Frequency")}
                disabled={!scheduleFieldsEnabled}
              />
            </Box>
          </Stack>
        </Box>
        <Stack
          direction="row"
          justifyContent={
            // isAdmin && openedAs === "edit" ? "space-between" : "end"
            isAdminRole(brandRoles) && openedAs === "edit"
              ? "space-between"
              : "end"
          }
          alignItems="center"
        >
          {/* {isAdmin && openedAs === "edit" && ( */}
          {isAdminRole(brandRoles) && openedAs === "edit" && (
            <Stack direction="row" alignItems="center">
              <Typography
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="600"
                color="#0869FB"
              >
                Disable
              </Typography>
              <Switch
                checked={configDisabled}
                onClick={() => {
                  setConfigDisabled(!configDisabled);
                }}
              />
            </Stack>
          )}
          <Button
            onClick={handleSave}
            sx={{
              background: "#0869FB",
              padding: "10px 16px",
              float: "right",
              margin: "4px 0px",
              color: "white",
              borderRadius: "8px",
              fontSize: "12px",
              fontWeight: "600",
              fontFamily: "Inter",
              textTransform: "unset",
              "&:hover": { background: "#0869FB" },
              ":disabled": {
                opacity: "0.5",
                color: "white",
                cursor: "not-allowed",
              },
            }}
            disabled={
              handleMandatoryFieldsValidation() ||
              handleNonMandatoryFieldsValidation() ||
              handleHistoricalDateTimeValidation() ||
              disableSaveButtonOnAPISubmit
              // ||
              // isViewerRole(brandRoles)
            }
          >
            {scheduleFieldsEnabled ? "Schedule" : "Save"}
          </Button>
        </Stack>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default GoogleSheetsModal;
