import { Box } from "@mui/material";
import { useState } from "react";
import { PxDialog } from "../../../shared/PxDialog/PxDialog";
import SelectMethodCard from "./SelectMethodCard";
import {
  StyledModalContainer,
  StyledSubTitleBox,
  StyledTitleBox,
  StyledTitleBoxContainer,
} from "./styled";
import { ReactComponent as SurpriseMe } from "../../../../../assets/cai/svg/SurpriseMe.svg";
import { ReactComponent as CreativeAnimator } from "../../../../../assets/cai/svg/CreativeAnimator.svg";
import { useDispatch } from "react-redux";
import { setVariantsNewTag } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import UTGTextFieldWithPopup from "../OnBoardingScreen/UTGTextFieldWithPopup";
import { StyledOnBoardingOtherMethodsContainer } from "../OnBoardingScreen/styled";
import { useSearchParams } from "react-router-dom-v5-compat";

const PreferredMethodTitle = () => (
  <StyledTitleBoxContainer>
    <StyledTitleBox>Choose a preferred method</StyledTitleBox>
    <StyledSubTitleBox>
      Create infinite ad copies using manual or auto options
    </StyledSubTitleBox>
  </StyledTitleBoxContainer>
);
function PreferredMethodModal({
  openCreateVariantModal,
  setOpenCreateVariantModal,
  generateCAIRecommendations,
  setOpenAutoGenerateModal,
  onClickCreateAnimater,
  billboardDisabled,
  autogenerateDisabled,
  adId,
  generateUTGRecommendations,
  utgDisabled,
}: {
  openCreateVariantModal: boolean;
  setOpenCreateVariantModal: any;
  generateCAIRecommendations: any;
  setOpenAutoGenerateModal: any;
  onClickCreateAnimater: any;
  billboardDisabled: number;
  autogenerateDisabled?: boolean;
  adId: string;
  generateUTGRecommendations: any;
  utgDisabled: boolean;
}) {
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  const [selected, setSelected] = useState<Number>(-1);
  const [active, setActive] = useState(false);
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const data = [
    {
      title: "Surprise Me!",
      subTitle:
        "Sit back & relax, the system will generate multiple variants for you",
      icon: <SurpriseMe height={"3.5em"} width={"4.69em"} />,
      onClick: () => {
        if(isViewedFromBeta)
          {
          window.parent.postMessage(
            {
              type: 'FLOW-STARTED',
            },
            '*'
          );
        }
        generateCAIRecommendations("Manual");
        setOpenCreateVariantModal(false);
        setOpenAutoGenerateModal(true);
        setSelected(-1);
        dispatch(setVariantsNewTag({ adId, isNew: false }));
        setText("");
      },
    },
    {
      title: "Creative Animator",
      subTitle: "Add life to your ads using interesting animations",
      icon: <CreativeAnimator height={"3.5em"} width={"4.69em"} />,
      onClick: () => {
        setOpenCreateVariantModal(false);
        onClickCreateAnimater();
        setSelected(-1);
        setText("");
      },
    },
  ];
  return (
    <PxDialog
      open={openCreateVariantModal}
      onClose={() => {
        setOpenCreateVariantModal(false);
        setText("");
      }}
      titleComponent={<PreferredMethodTitle />}
      sx={{
        padding: "0px 0px",
        ".MuiDialog-paper": {
          maxWidth: "56.8vw",
        },
        ".MuiDialogTitle-root": {
          fontSize: "1.25em",
        },
        ".MuiDialogContent-root": {
          padding: "0em",
        },
      }}
      fullWidth
      maxWidth="lg"
    >
      <StyledModalContainer>
        <Box
          // height={"5%"}
          display={"flex"}
          justifyContent={"flex-start"}
          flexDirection={"column"}
          gap={"1em"}
        >
          <UTGTextFieldWithPopup
            active={active}
            setActive={setActive}
            text={text}
            setText={setText}
            disabled={utgDisabled}
            onGenerate={() => {
              generateUTGRecommendations(text);
              setOpenCreateVariantModal(false);
              setOpenAutoGenerateModal(true);
              setText("");
            }}
          />
          {!active && (
            <StyledOnBoardingOtherMethodsContainer>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1.38em"}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    width: "14.75em",
                    height: "0.0625em",
                    background: "#c8ced7",
                  }}
                ></Box>

                <Box
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "0.875em",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "1.375em",
                    letterSpacing: "0.00875em",
                  }}
                >
                  OR
                </Box>
                <Box
                  sx={{
                    width: "14.75em",
                    height: "0.0625em",
                    background: "#c8ced7",
                  }}
                ></Box>
              </Box>
              <Box display={"flex"} flexDirection={"row"} gap={"0.75em"}>
                {data.map((method, index) => {
                  return (
                    <SelectMethodCard
                      disabled={
                        index == 1
                          ? billboardDisabled
                          : index == 0 && autogenerateDisabled
                          ? 3
                          : 0
                      }
                      selected={index == 0}
                      index={index}
                      setSelected={() => {}}
                      title={method.title}
                      subTitle={method.subTitle}
                      children={method.icon}
                      onClick={method.onClick}
                    />
                  );
                })}
              </Box>
            </StyledOnBoardingOtherMethodsContainer>
          )}
        </Box>
      </StyledModalContainer>
    </PxDialog>
  );
}

export default PreferredMethodModal;
