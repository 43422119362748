import { DynamicFilterItemType } from "components/CreativeAI_Revamp/shared/DynamicFilterBox/types";
import { LooseObject } from "types/GlobalTypes";

export const GET_AI_GROUPS = "GET_AI_GROUPS";
export const SET_AI_GROUPS = "SET_AI_GROUPS";
export const GET_AD_ACCOUNTS_FB = "GET_AD_ACCOUNTS_FB";
export const SET_AD_ACCOUNTS = "SET_AD_ACCOUNTS";
export const GET_ADSETS_FB = "GET_ADSETS_FB";
export const SET_ADSETS_FB = "SET_ADSETS_FB";
export const GET_ADS_FB = "GET_ADS_FB";
export const SET_ADS_FB = "SET_ADS_FB";
export const GET_FILTERS_UPDATED = "GET_FILTERS_UPDATED";
export const SET_FILTERED_DATA = "SET_FILTERED_DATA";
export const SET_AUTOGENERATE_DATA = "SET_AUTOGENERATE_DATA";
export const SET_AUTOGENERATE_AD_ID = "SET_AUTOGENERATE_AD_ID";
export const SET_UTG_DATA = "SET_UTG_DATA";
export const SET_UTG_AD_ID = "SET_UTG_AD_ID";
// groups
export interface GetAIGroups {
  type: typeof GET_AI_GROUPS;
  payload: GetAIGroupsPayloadType;
}

export interface SetAIGroups {
  type: typeof SET_AI_GROUPS;
  payload: SetAIGroupPayloadType;
}

export interface GetAIGroupsPayloadType {
  params?: LooseObject;
  brandId: string;
  isSearching?: boolean;
}

export interface SetAIGroupPayloadType {
  isFailed?: boolean;
  isLoading?: boolean;
  data: AIgroupMetaDataType[];
  selection?: AIgroupMetaDataType;
  selectedAIGroup?: AIgroupMetaDataType;
  isSearching?: boolean;
  selectedItem?: any;
}

// adaccounts

export interface GetAdAccounts {
  type: typeof GET_AD_ACCOUNTS_FB;
  payload: GetAdAccountsPayloadType;
}

export interface SetAdAccounts {
  type: typeof SET_AD_ACCOUNTS;
  payload: SetAdAccountsPayloadType;
}
export interface GetAdAccountsPayloadType {
  params?: LooseObject;
  aiGroupId: string;
}

export interface SetAdAccountsPayloadType {
  isFailed?: boolean;
  isLoading?: boolean;
  data: AdAccountsType[];
}

// adsets

export interface GetAdSetsFB {
  type: typeof GET_ADSETS_FB;
  payload: GetAdSetsPayloadType;
}

export interface SetAdSetsFB {
  type: typeof SET_ADSETS_FB;
  payload: SetAdSetsPayloadType;
}

export interface GetAdSetsPayloadType {
  params?: LooseObject;
  elementId: string;
}

export interface SetAdSetsPayloadType {
  isFailed?: boolean;
  isLoading?: boolean;
  data: AdSetItemType[];
}

// reffrence ads

export interface GetAdsFB {
  type: typeof GET_ADS_FB;
  payload: GetAdsPayloadType;
}

export interface SetAdsFB {
  type: typeof SET_ADS_FB;
  payload: SetAdsPayloadType;
}

export interface GetAdsPayloadType {
  params?: LooseObject;
  adSetId: string;
}

export interface SetAdsPayloadType {
  isFailed?: boolean;
  isLoading?: boolean;
  data: AdsItemType[];
  filteredData: AdsItemType[];
}

// initials states
export interface FacebookInitialState {
  aiGroupList: SetAIGroupPayloadType;
  adAccountList: SetAdAccountsPayloadType;
  adSetList: SetAdSetsPayloadType;
  adsList: SetAdsPayloadType;
  parentPlatformId?: string;
  filter: DynamicFilterItemType[];
  autogenerate: LooseObject;
  utgData: LooseObject;
  triggerUTGAdId?: string;
  triggerAutogenerateAdId?: string;
}

export interface AIgroupMetaDataType {
  name: string;
  id: string;
  Elements?: AIgroupElementType[];
}

export interface AIgroupElementType {
  id: string;
  platformId: string;
  adAccountId: string;
  Platform: {
    id: string;
  };
  ElementCreativeAiData: [
    {
      id: string;
    }
  ];
}

export interface AdAccountsType {
  id: string;
  Platform: {
    id: string;
    type: string;
  };
  name: string;
  adAccountId: string;
  AdAccount: {
    id: string;
    name: string;
    adAccountId: string;
  };
}

export interface AdSetItemType {
  id: string;
  name: string;
  adsetId: string;
  adCount?: number;
}

export interface AdsItemType {
  id: string;
  name: string;
  status: string;
  creative: {
    id: string;
  };
  tracking_specs: LooseObject;
  adcreatives: LooseObject;
  previews: LooseObject;
  metrics: {
    Spends: number;
    CPR: number;
    CTR: number;
  };
  publishedVariants: number;
  draftVariants: number;
  billboardVariants: number;
  isMediaStudioAvailable: boolean;
  underReviewVariants: number;
  approvedVariants: number;
  rejectedVariants: number;
  hasAutogenerateTriggered: boolean;
  adType: string;
  publishingSource: string;
  variantList?: LooseObject[];
}

export interface AdKeys {
  Spend: number;
  CPR: number;
  CTR: number;
}

export interface GetFiltersUpdated {
  type: typeof GET_FILTERS_UPDATED;
  payload: any;
}

export interface SetFilteredData {
  type: typeof SET_FILTERED_DATA;
  payload: any;
}

export interface SetAutogenerateData {
  type: typeof SET_AUTOGENERATE_DATA;
  payload: any;
}

export interface SetAutogenerateAdId {
  type: typeof SET_AUTOGENERATE_AD_ID;
  payload: any;
}

export interface SetUTGAdId {
  type: typeof SET_UTG_AD_ID;
  payload: any;
}

export interface SetUTGData {
  type: typeof SET_UTG_DATA;
  payload: any;
}
