import { Box, Skeleton } from "@mui/material";
import React from "react";

export default function ActionDebuggerChartSkeletonItem() {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        margin: "1.5em 1em ",
      }}
    >
      <Skeleton
        width="19%"
        variant="rectangular"
        height="4em"
        sx={{
          marginRight: "1em",
          borderRadius: ".5em",
        }}
      />
      <Box flex="1">
        <Skeleton
          height="9em"
          variant="rectangular"
          sx={{
            borderRadius: ".5em",
          }}
        />
      </Box>
    </Box>
  );
}
