import React, { useRef, useEffect } from "react";
import PinPosition0 from "../../../assets/png/pin-position-0.png";
import PinPosition1 from "../../../assets/png/pin-position-1.png";
import PinPosition2 from "../../../assets/png/pin-position-2.png";
import PinPosition3 from "../../../assets/png/pin-position-3.png";
import HandleClickOutside from "../../common/HandleClickOutside";
import "./index.scss";

const PinPopup: React.FC<{
  id:number,
  pinningPositionId:number,
  open:boolean,
  header:string,
  setOpen:(value: boolean) => void;
  setPinningPosition:(id: number, header:string, pinningPosition:number) => void;
}> = ({open,header,setOpen,id,pinningPositionId,setPinningPosition}): JSX.Element => {


  const getPosArray = (header:string)=>{
    let arr:Array<any> = [
      {
        label:"Show in any unpinned position",
        position:0
      },
      {
        label:"Show only in position 1",
        position:1
      },
      {
        label:"Show only in position 2",
        position:2
      },
      {
        label:"Show only in position 3",
        position:3
      }
    ];
    if(header==="headline"){
      return arr;
    }else{
      return arr.slice(0,3);
    }
  }
  const posArray:Array<any> = [
    {
      label:"Show in any unpinned position",
      position:0
    },
    {
      label:"Show only in position 1",
      position:1
    },
    {
      label:"Show only in position 2",
      position:2
    },
    {
      label:"Show only in position 3",
      position:3
    }
  ]
  const renderImage = (position: number) => {
    switch (position) {
      case 0:
        return <img src={PinPosition0} alt="None" />;
      case 1:
        return <img src={PinPosition1} alt="PinPosition 1" />;
      case 2:
        return <img src={PinPosition2} alt="PinPosition 2" />;
      case 3:
        return <img src={PinPosition3} alt="PinPosition 3" />;
      default:
        return <img src={PinPosition0} alt="PinPosition 0" />;
    }
  }

  const wrapperRef = useRef(null);
  HandleClickOutside(wrapperRef,[setOpen]);

  return (<>
    {open ? (
      <div className="pin-popup" ref={wrapperRef}>
        <div className="header">
          <div className="title">Pin this headline</div>
          <div className="subtitle">Choose a position</div>
        </div>
        <div className="choose-pin--container">
          {
            getPosArray(header).map((p, index) => {
              return (
                <div className={`choose-pin--position ${pinningPositionId===p.position?'active':''}`} key={index} onClick={()=>{
                  setPinningPosition(id,header,p.position)
                  setOpen(false)
                  }}>
                  <div className="left">
                    <div className="title">{p.label}</div>
                    {p.position===0 && (<div style={{"color":"#3C7D40"}}>Recommended</div>)}
                  </div>
                  <div className="right">
                    {renderImage(p.position)}
                  </div>
                </div>
              ) 
            })
          }
        </div>
      </div>
    ) : ('')
    }
  </>
  );
};

export default PinPopup;
