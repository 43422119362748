import { StyledChoiceButtonBox, StyledChoiceSelectionBox } from "./styled";

export default function ChoiceButton({
  onChange,
  value,
}: {
  onChange: (...event: any[]) => void;
  value: string;
}) {
  return (
    <StyledChoiceButtonBox>
      <StyledChoiceSelectionBox
        selected={value === "Unrelated"}
        onClick={() => onChange("Unrelated")}
      >
        Unrelated
      </StyledChoiceSelectionBox>
      <StyledChoiceSelectionBox
        selected={value === "Related"}
        ml="12px"
        onClick={() => onChange("Related")}
      >
        Related
      </StyledChoiceSelectionBox>
    </StyledChoiceButtonBox>
  );
}
