import {
  ActionSummaryInitStateType,
  KeyIndicatorItemsType,
  LooseObject,
  SetActionSummary,
  SetActionSummaryLoader,
  SetKeyIndicators,
  SetKeyIndicatorsLoader,
  SetPerformanceSummary,
  SetPerformanceSummaryLoader,
  SetPreviousActionSummary,
  SetPreviousActionSummaryLoader,
  SetPreviousKeyIndicators,
  SetPreviousKeyIndicatorsLoader,
  SetPreviousPerformanceSummary,
  SetPreviousPerformanceSummaryLoader,
  SET_ACTION_SUMMARY,
  SET_ACTION_SUMMARY_LOADER,
  SET_KEY_INDICATORS,
  SET_KEY_INDICATORS_LOADER,
  SET_PERFORMANCE_SUMMARY,
  SET_PERFORMANCE_SUMMARY_LOADER,
  SET_PREVIOUS_ACTION_SUMMARY,
  SET_PREVIOUS_ACTION_SUMMARY_LOADER,
  SET_PREVIOUS_KEY_INDICATORS,
  SET_PREVIOUS_KEY_INDICATORS_LOADER,
  SET_PREVIOUS_PERFORMANCE_SUMMARY,
  SET_PREVIOUS_PERFORMANCE_SUMMARY_LOADER,
} from "../../types/ActionDebugger/ActionSummary";

const initialState: ActionSummaryInitStateType = {
  actionSummary: [],
  previousActionSummary: [],
  actionSummaryLoader: false,
  previousActionSummaryLoader: false,
  performanceSummary: {},
  previousPerformanceSummary: {},
  performanceSummaryLoader: false,
  previousPerformanceSummaryLoader: false,
  keyIndicators: [],
  previousKeyIndicators: {},
  keyIndicatorsLoader: false,
  previousKeyIndicatorsLoader: false,
};

const ActionSummaryReducer = (
  state = initialState,
  action:
    | SetActionSummary
    | SetPreviousActionSummary
    | SetActionSummaryLoader
    | SetPreviousActionSummaryLoader
    | SetPerformanceSummary
    | SetPreviousPerformanceSummary
    | SetPerformanceSummaryLoader
    | SetPreviousPerformanceSummaryLoader
    | SetKeyIndicators
    | SetPreviousKeyIndicators
    | SetKeyIndicatorsLoader
    | SetPreviousKeyIndicatorsLoader
) => {
  switch (action.type) {
    case SET_ACTION_SUMMARY: {
      return {
        ...state,
        actionSummary: action.payload,
      };
    }
    case SET_PREVIOUS_ACTION_SUMMARY: {
      return {
        ...state,
        previousActionSummary: action.payload,
      };
    }
    case SET_ACTION_SUMMARY_LOADER: {
      return {
        ...state,
        actionSummaryLoader: action.payload,
      };
    }
    case SET_PREVIOUS_ACTION_SUMMARY_LOADER: {
      return {
        ...state,
        previousActionSummaryLoader: action.payload,
      };
    }
    case SET_PERFORMANCE_SUMMARY: {
      return {
        ...state,
        performanceSummary: action.payload,
      };
    }
    case SET_PREVIOUS_PERFORMANCE_SUMMARY: {
      return {
        ...state,
        previousPerformanceSummary: action.payload,
      };
    }
    case SET_PERFORMANCE_SUMMARY_LOADER: {
      return {
        ...state,
        performanceSummaryLoader: action.payload,
      };
    }
    case SET_PREVIOUS_PERFORMANCE_SUMMARY_LOADER: {
      return {
        ...state,
        previousPerformanceSummaryLoader: action.payload,
      };
    }
    case SET_KEY_INDICATORS: {
      return {
        ...state,
        keyIndicators: action.payload,
      };
    }
    case SET_PREVIOUS_KEY_INDICATORS: {
      const formattedPrevIndicator: LooseObject = {};
      action.payload?.forEach((item: KeyIndicatorItemsType) => {
        formattedPrevIndicator[item.metric] = item.value;
      });

      return {
        ...state,
        previousKeyIndicators: formattedPrevIndicator,
      };
    }
    case SET_KEY_INDICATORS_LOADER: {
      return {
        ...state,
        keyIndicatorsLoader: action.payload,
      };
    }
    case SET_PREVIOUS_KEY_INDICATORS_LOADER: {
      return {
        ...state,
        previousKeyIndicatorsLoader: action.payload,
      };
    }
    default:
      return state;
  }
};
export default ActionSummaryReducer;
