import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import BrandRow from "../common/BrandRow";
import Header from "../Components/Header";
import TilesRow from "../Components/TilesRow";
import {
  getAllBrandsDetails,
  getBrandMetrics,
} from "../../../store/actions/SmbDashboard/brand";
import { Loader } from "../../../components/shared";
import {
  setSelectedBrand,
  resetSelectedBrand,
} from "../../../store/actions/SmbDashboard/selectedBrand";
import { getAgenciesSmb } from "../../../store/actions/SmbDashboard/agencies";
import { getAllPlatforms } from "store/actions/SmbDashboard/common";
import EditBrand from "../EditBrand";
import SuccessModel from "../../../common/SuccessModal/index";
import { AdAccountState } from "../../../store/types/adaccount";
import { setActiveBrand } from "../../../store/actions/adaccount";
import { setSelectedAgency } from "../../../store/actions/common";
import { updateSearchParams } from "utils/commonFunctions";
import { mixPanelAdEvent } from "utils/mixpanel";
import { useSearchParams } from "react-router-dom-v5-compat";

function AgencySummary() {
  const history = useHistory();
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const agencyId = params.get("agency-id");
  const adAccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brands } = adAccount;
  const authState = useSelector((state: any) => state.auth);
  const { user } = authState;
  const brandState: any = useSelector((state: any) => state.smb.brand);
  const agenciesState: any = useSelector((state: any) => state.smb.agencies);
  const commonState: any = useSelector((state: any) => state.smb.common);

  const [showOptimizer, setShowOptimizer] = useState(null);
  const [showPlatform, setShowPlatform] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTileKey, setActiveTileKey] = useState("total_brands");

  useEffect(() => {
    if (agenciesState.data.length === 0) {
      dispatch(getAgenciesSmb({ user, agencyId }));
    } else if (agencyId) {
      const selectedAgency = agenciesState.data.find(
        (agency: any) => agency.id === agencyId
      );
      dispatch(setSelectedAgency(selectedAgency));
    }
    if (!commonState.platforms) dispatch(getAllPlatforms());
  }, []);

  useEffect(() => {
    if (agenciesState.selected) {
      dispatch(getAllBrandsDetails({ agencyId: agenciesState.selected.id }));
      updateSearchParams("agency-id", agencyId?.toString() || "");
      mixPanelAdEvent("Page View - Dashboard", {
        agency_name: agenciesState.selected.name,
        agency_id: agenciesState.selected.id,
      });
    }
    return () => {
      // cancel request - requestId
    };
  }, [agenciesState]);

  useEffect(() => {
    const brandIds =
      brandState && brandState.data ? Object.keys(brandState.data) : [];
    if (brandIds.length > 0) {
      // setLoading(true);
      dispatch(
        getBrandMetrics({
          brandIds,
          selectedMonth: commonState.calendar.selectedMonth,
          selectedYear: commonState.calendar.selectedYear,
        })
      );
    }
    return () => {
      // cancel request - requestId
    };
  }, [commonState.calendar]);

  const handleBrandClick = (event: any, brandId: any, name: string) => {
    if (
      !event?.target?.classList.contains("handle-click") &&
      !showOptimizer &&
      !showPlatform
    ) {
      dispatch(
        setSelectedBrand({
          id: brandId,
          name,
        })
      );
      let brand: any = brands.find((brand: any) => brand.id === brandId);
      dispatch(resetSelectedBrand());
      dispatch(setActiveBrand(brand));
      mixPanelAdEvent("SMB Brand Summary Clicked", {
        brand_name: name,
        brand_id: brandId,
        agency_name: agenciesState.selected.name,
        agency_id: agenciesState.selected.id,
        dashboard_view: "Agency Summary View",
      });
      history.push(
        `/smb-dashboard/brand?${
          agencyId ? "agency-id=" + agencyId + "&" : ""
        }brand_id=${brandId}${isViewedFromBeta?'&beta=true':''}`
      );
    }
  };
  const [editBrandOpen, setEditBrandOpen] = useState(null);
  const [success, setSuccess] = useState(false);

  return (
    <Box className="font-inter" sx={{ height: "100vh" }}>
      <Header
        type="Agency"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <Box
        sx={{
          position: "relative",
          minHeight: "calc(100vh - 17vh)",
          background: "#FFFFFF",
          borderRadius: "9px",
          p: 2,
          m: 2,
        }}
      >
        <TilesRow
          type="Agency"
          activeTileKey={activeTileKey}
          setActiveTileKey={setActiveTileKey}
          agencyId={agencyId}
        />
        <Box
          sx={
            brandState?.isLoading
              ? { position: "absolute", top: "50%", left: "50%" }
              : { overflowY: "scroll", height: "62vh" }
          }
          id="scrollableDiv"
        >
          {brandState?.isLoading ? (
            <Loader />
          ) : (
            <BrandRow
              searchTerm={searchTerm}
              handleBrandClick={handleBrandClick}
              showOptimizer={showOptimizer}
              setShowOptimizer={setShowOptimizer}
              showPlatform={showPlatform}
              setShowPlatform={setShowPlatform}
              setEditBrandOpen={setEditBrandOpen}
              activeTileKey={activeTileKey}
            />
          )}
        </Box>
      </Box>
      {editBrandOpen && (
        <EditBrand
          setOpen={setEditBrandOpen}
          open={editBrandOpen}
          setSuccess={setSuccess}
        />
      )}
      {success && (
        <SuccessModel
          setOpen={setSuccess}
          open={success}
          heading={`The Brand Updated Successfully`}
          onSuccess={() => {
            setSuccess(false);
          }}
        />
      )}
    </Box>
  );
}

export default AgencySummary;
