import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StyledReviewContainer = styled(Box)`
  padding: 0rem 1rem;
  width: 75%;
`;

export const StyledReviewSectionContainer = styled(Box)`
  display: flex;
  padding: 0.625rem 0rem 0rem;
  height: 86%;
`;

export const StyledVerticalDivider = styled(Box)`
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
`;

export const StyledReviewContainerHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
`;

export const StyledReviewHeader = styled.span`
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledReviewRequestType = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #333333;
`;

export const StyledReviewRequest = styled.span`
  font-size: 0.875rem;
  font-style: italic;
  color: #999999;
`;
