import React from "react";
import "./index.scss";

const TargetKeywordInfo: React.FC = (): JSX.Element => {
  return (
    <div className="target-keyword-info">
      <div className="target-keyword-info--container">
        <div className="target-keyword-info--title">What is target interest keyword?</div>
        <div className="target-keyword-info--desc">
          A target interest keyword is a word or phrase, usually the cluster name, that describes
          the user’s search intent or interest. Target interest keywords are used to deliver
          targeted responsive search or display ads to users. Keywords closely matching the search
          query are considered an exact match and are more likely to have positive outcomes.
        </div>
      </div>
      <div className="target-keyword-info--container">
        <div className="target-keyword-info--title">What is reference headline/description? </div>
        <div className="target-keyword-info--desc">
          A reference headline/description is the starting point for responsive ad creation. Like a
          typical headlines, it summarizes the product benefits, offers, and can include the brand
          name. Using reference text, the system can create personalized headlines or descriptions
          depending on the product type and active offers.
        </div>
      </div>
      <div className="target-keyword-info--container">
        <div className="target-keyword-info--title">What is generation type?</div>
        <div className="target-keyword-info--desc">
          Using reference headline, headlines are generated by the system. These headlines are
          categorized as:
          <ol>
            <li>
              <span className="desc-title">Interest specific:</span> Headlines that capture the
              interest or intent of the user.
            </li>
            <li>
              <span className="desc-title">Brand specific:</span> Headlines that focus on the
              building brand awareness by having brand specific keywords.
            </li>
            <li>
              <span className="desc-title">Generic:</span> Headlines that are follow the
              ‘one-size-fits-all’ rule.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TargetKeywordInfo;
