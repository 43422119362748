import { PxDialog } from "components/CreativeAI_Revamp/shared";
import { useEffect, useState } from "react";
import LoaderComponent from "./LoaderComponent";
import {
  StyledLoaderModelContainer,
  StyledModalContainer,
  StyledTipContainer,
  StyledTitleBox,
  StyledTitleBoxContainer,
} from "./styled";
import Analyze from "../../../../../assets/cai/gif/analyze.gif";
import Generate from "../../../../../assets/cai/gif/generate.gif";
import Create from "../../../../../assets/cai/gif/create.gif";
import { useAutogenerateLoader } from "../hooks/useAutogenerateLoader";
const PreferredMethodTitle = () => (
  <StyledTitleBoxContainer>
    <StyledTitleBox>
      Autogenerating Ad Variants , Sit back and Relax ...{" "}
    </StyledTitleBox>
  </StyledTitleBoxContainer>
  //   <></>
);
const tips = [
  // "We recommend always keeping Business Unit details updated.",

  "Refreshing your ad variants might boost the performance by 60%",

  "Content specialization in ads can give ~ 30% boost in performance",

  "Media personalization always makes you stand out!",

  "It is recommended to get approval for ad creatives before publishing them.",

  "To generate more recommendations, try Configure button inside the Creative Studio.",
];
const data = [
  {
    message: "Analysing reference ad setup and targeted audience details",
    src: Analyze,
    width: "14.375em",
  },
  {
    message:
      "Generating AI based recommendations basis interest and brand profiles",
    src: Generate,
    width: "17.5em",
  },
  {
    message: "Creating best Ad Variants for your brand ",
    src: Create,
    width: "14.375em",
  },
];
function AutogenerateLoader({
  openAutoGenerateModal,
  setOpenAutoGenerateModal,
  adId,
}: {
  openAutoGenerateModal: boolean;
  setOpenAutoGenerateModal: any;
  adId: string;
}) {
  const { selected, progress } = useAutogenerateLoader({ adId });
  const [tipTimer, setTipTimer] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTipTimer((prev) => (prev == tips.length - 1 ? 0 : prev + 1));
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    return () => {
      setOpenAutoGenerateModal(false);
    };
  }, [setOpenAutoGenerateModal]);

  return (
    <PxDialog
      open={openAutoGenerateModal}
      titleComponent={<PreferredMethodTitle />}
      onClose={() => setOpenAutoGenerateModal(false)}
      sx={{
        padding: "0px 0px",
        ".MuiDialog-paper": {
          maxWidth: "73vw",
        },
        ".MuiDialogTitle-root": {
          fontSize: "1.25em",
        },
        ".MuiDialogContent-root": {
          padding: "0em",
        },
      }}
      fullWidth
      maxWidth="lg"
      minimize
    >
      <StyledModalContainer>
        <StyledLoaderModelContainer>
          {data.map((step, index) => (
            <LoaderComponent
              selected={selected}
              width={step.width}
              src={step.src}
              message={step.message}
              index={index}
              progress={progress}
            />
          ))}
        </StyledLoaderModelContainer>
        <StyledTipContainer>
          <span>Tips</span>
          {tips[tipTimer]}
        </StyledTipContainer>
      </StyledModalContainer>
    </PxDialog>
  );
}

export default AutogenerateLoader;
