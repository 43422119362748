export const GET_YOUTUBE_RECOMMENDED_COHORTS =
  "GET_YOUTUBE_RECOMMENDED_COHORTS";
export const UPLOAD_YOUTUBE_DATA = "UPLOAD_YOUTUBE_DATA";
export const DELETE_YOUTUBE_COHORTS = "DELETE_YOUTUBE_COHORTS";

export const SET_YOUTUBE_RECOMMENDED_VIDEO_COHORTS =
  "SET_YOUTUBE_RECOMMENDED_VIDEO_COHORTS";

export const SET_YOUTUBE_RECOMMENDED_CHANNEL_COHORTS =
  "SET_YOUTUBE_RECOMMENDED_CHANNEL_COHORTS";

export const SET_YOUTUBE_RECOMMENDED_AUDIENCE_COHORTS =
  "SET_YOUTUBE_RECOMMENDED_AUDIENCE_COHORTS";
export const SET_YOUTUBE_TYPE = "SET_YOUTUBE_TYPE";

export const SET_YOUTUBE_LOADING = "SET_YOUTUBE_LOADING";
export const SET_SELECTED_PLATFORM = "SET_SELECTED_PLATFORM";

export type ISetRecommendedChannelCohortsData = {
  channel_id: string;
  channel_title: string;
  channel_view_count: string;
  channel_description: string;
  channel_video_count: string;
  selected_video_tags: string;
  selected_video_count: number;
  selected_video_links: string[];
  channel_subscriber_count: string;
  channel_topic_categories: string;
  selected_video_relevance: number;
};

export type ISetRecommendedVideosCohortsData = {
  titles: string;
  relevance: number;
  video_ids: string[];
  cluster_id: string;
  view_count: number;
  video_count: number;
  video_links: string;
  mixed_clus_ind: string;
};

export type ISetRecommendedAudienceCohortsData = {
  type: string;
  score: number;
  segment_name: string;
  child_segment: string;
  parent_segment: string;
  child_cosine_score: number;
  parent_cosine_score: number;
};

export type IGetRecommendedCohortsPayload = {
  aiGroupId?: string;
  type?: "video" | "audience" | "channel" | undefined;
};
export type IGetRecommendedChannelCohorts = {
  type: typeof GET_YOUTUBE_RECOMMENDED_COHORTS;
  payload: IGetRecommendedCohortsPayload;
};
