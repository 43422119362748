import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/system/Stack";
import Typography from "@mui/material/Typography";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import LoginSuccessFull from "../../assets/png/LoginSuccessful.png";

import {
  getSessionData,
  postMessage,
  setLocalStorageData,
} from "../../utils/commonFunctions";
import { mixPanelAdEvent } from "../../utils/mixpanel";
import { user_session_key } from "utils/constants";
import { setSession } from "../../store/actions/auth";
import { AdAccountState } from "store/types/adaccount";

function LoginSuccessful() {
  const [timer, setTimer] = useState(3);
  const [token, setToken] = useState<string | null>(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const adAccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adAccount;

  let interval: any;
  // useEffect(() => {
  //   (async () => {
  //     const token = await getAccessTokenSilently();
  //     setToken(token);
  //   })();
  // }, []);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        interval = setInterval(() => {
          setTimer((t) => {
            if (t === 1) {
              clearInterval(interval);
              window.close();
              setToken(null);
            }
            return t - 1;
          });
        }, 1000);

        const sessionData: any = getSessionData();
        let session: any = {
          refreshToken: sessionData.body.refresh_token,
          accessToken: sessionData.body.access_token,
          expiresAt: sessionData.expiresAt * 1000,
        };

        setLocalStorageData(user_session_key, session);
        postMessage({ from: "PYXIS_DASHBOARD", data: { session, user } });

        dispatch(setSession(session));
        mixPanelAdEvent("Login Successful", {
          email: user?.email,
          username: user?.name,
          agency_name: selectedBrand.agency_name,
          agency_id: selectedBrand.agencyId,
          brand_id: selectedBrand?.id,
          brand_name: selectedBrand?.name,
          brand_type: selectedBrand?.brandType,
        });
      })();
    }
  }, [isAuthenticated]);

  return (
    <Stack
      className="font-name-inter"
      sx={{
        background: "url(../static/onboarding/images/LoginSuccessfulBg.svg)",
        backgroundSize: "cover",
        backgroundPositionY: "-120px",
        backgroundColor: "white",
        height: "100vh",
        width: "100vw",
        "& *": {
          fontFamily: "Inter",
        },
      }}
      alignItems="center"
      pt={4}
    >
      <img src={LoginSuccessFull} alt="success" />

      <Typography
        sx={{ fontSize: "40px", fontWeight: 700, color: "#535353", mt: 4 }}
      >
        Login Successful!
      </Typography>

      <Typography
        sx={{ fontSize: "14px", fontWeight: 500, color: "#6B6B6B", mt: 2 }}
      >
        The tab will close in {timer} seconds
      </Typography>
      <Button
        className="text-white border-0 rounded btn-primary "
        variant="contained"
        sx={{
          background: "#0869FB",
          textTransform: "none",
          mt: 2,
          fontSize: "14px",
          fontWeight: 600,
          width: "auto",
          px: 4,
          py: 2,
        }}
        onClick={() => window.close()}
        disableRipple
      >
        Close Tab
      </Button>
    </Stack>
  );
}

export default LoginSuccessful;
