import { memo, useContext, useEffect, useState } from "react";
import { Handle } from "react-flow-renderer";
import { useDispatch, useSelector } from "react-redux";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import { ReactComponent as EditTai } from "../ReactFlowComponents/Assets/svgs/EditTai.svg";
import { ReactComponent as Close } from "../ReactFlowComponents/Assets/svgs/close.svg";
import { ReactComponent as Show } from "../ReactFlowComponents/Assets/svgs/Show.svg";
import { ReactComponent as CloseIconDark } from "../../../assets/svg/CloseIconDark.svg";
import { KEYWORDLOADERLABELS } from "./constants/index";

import "./KeywordSetup.scss";
import { TaiContext } from "../TaiContextProvider";
import { genderMapper } from "../utils";
import useFocusToNode from '../useFocusToNode';

export default memo(({ data }) => {
  const dispatch = useDispatch();
  const { setNodes, setEdges, setPopover, setPopoverFlag } =
    useContext(TaiContext);

  const calibrationData = useSelector((state) => state.calibration.apiData);
  const calibrationFormData = useSelector(
    (state) => state.calibration.formData
  );
  const loaderState = useSelector((state) => state.taiCommon.loader);

  const [show, setShow] = useState(-1);

  const dataToRender = [
    calibrationData.Brand,
    calibrationData.Generic,
    calibrationData.Lateral,
    calibrationData.Custom,
  ];
  function listener(e) {
    const tooltips = document.querySelectorAll("[role=tooltip]");
    const eyeButtons = document.querySelectorAll(".collapsed-show");
    if (show > -1 && tooltips.length > 0) {
      let eyeClicked = false;
      for (let index = 0; index < eyeButtons.length; index++) {
        const element = eyeButtons[index];
        if (element.contains(e.target)) {
          eyeClicked = true;
          break;
        }
      }
      for (let index = 0; index < tooltips.length; index++) {
        const element = tooltips[index];
        if (!element.contains(e.target) && !eyeClicked) {
          setShow(-1);
          document.body.removeEventListener("click", listener);
          break;
        }
      }
    }
  }

  useEffect(() => {
    if (show > -1) {
      setPopoverFlag(true);
      document.querySelector("body").addEventListener("click", listener);
    } else {
      document.body.removeEventListener("click", listener);
    }
  }, [show, setShow]);

  const focusToNode = useFocusToNode();

  return (
    <div className="loaderCollapsed keyword-collapsed">
      <Handle type="target" position="left" />
      <Handle type="source" position="right" />
      <div className="collapsed-header">
        <div className="collapsed-heading">Keyword Tuning</div>
        <div
          className="header-icon"
          onClick={() =>
            data.onEditClick(dispatch, loaderState, setNodes, setEdges, focusToNode)
          }
        >
          <EditTai />
        </div>
      </div>
      {calibrationFormData?.TypeOfCohort === "ICP" ? (
        <div
          style={{
            maxHeight: "300px",
            minHeight:"300px",
            overflow: "auto",
          }}
        >
          {calibrationData?.icps?.map((eachICP, index) => (
            <div
              className={`collapsed-content`}
            >
              <div className="collapsed-label"> {eachICP?.icp_name}</div>
              <div className="collapsed-data">
                <div className="collapsed-length mr-1">
                  {(eachICP?.interests_keywords?.length ||
                    eachICP?.demographics_keywords?.length ||
                    eachICP?.behaviors_keywords?.length) && (
                    <div>
                      {eachICP?.interests_keywords?.length +
                        eachICP?.demographics_keywords?.length +
                        eachICP?.behaviors_keywords?.length}
                    </div>
                  )}
                  <div className="collapsed-keywords">Keywords</div>
                </div>
                <OverlayTrigger
                  id={`keywordOverlay${index}`}
                  show={show === index && data.popover}
                  placement="right"
                  rootClose
                  rootCloseEvent="mousedown"
                  onExited={() => {
                    setPopoverFlag(false);
                    setShow(-1);
                    setPopover(true);
                  }}
                  overlay={
                    <Popover
                      id={`popoverKeywordSetup${index}`}
                      className="popover-keyword-setup"
                    >
                      <Popover.Header
                        as="h3"
                        className="custom-popover-header"
                        sx={{
                          justifyContent: "space-between",
                        }}
                      >
                      <div style={{display:"flex", gap:"10px"}}>
                  
                        <div
                          style={{
                            fontSize: "0.75em",
                            lineHeight: "1.25em",
                            color: "#AEB6C4",
                          }}
                        >
                          Age -{" "}
                          <span
                            style={{
                              color: "#000",
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            {eachICP?.age?.age_min} - {eachICP?.age?.age_max}
                          </span>
                        </div>
                        <div
                          style={{
                            fontSize: "0.75em",
                            lineHeight: "1.25em",
                            color: "#AEB6C4",
                          }}
                        >
                          {" "}
                          Gender -{" "}
                          <span
                            style={{
                              color: "#000",
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            {eachICP?.gender === null
                              ? "All"
                              : eachICP?.gender?.genders?.length === 1
                              ? genderMapper[eachICP?.gender?.genders]
                              : "-"}
                          </span>
                        </div>
                        </div>
                        <div className="popover-header-close">
                              <Close
                                onClick={() => {
                                  setShow(false);
                                }}
                              />
                        </div>
                        
                      </Popover.Header>

                      <Popover.Body>
                        <div
                          className="custom-popover-body"
                          style={{
                            background: "transparent",
                            display: "block",
                          }}
                        >
                          {[
                            { key: "interests_keywords", label: "Interests" },
                            {
                              key: "demographics_keywords",
                              label: "Demographic Keywords",
                            },
                            { key: "behaviors_keywords", label: "Behaviour" },
                          ]?.map((eachType) => {
                            return (
                              <Box
                                className={"individual-suggestion"}
                                sx={{
                                  padding: "0.75em 0.875em",
                                  backgroundColor: "#F6F7F8",
                                  borderRadius: "7px",
                                  flex: "1 1 0",
                                  marginBottom: "10px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "0.875em",
                                    lineHeight: "1.5em",
                                    fontWeight: 600,
                                    fontFamily: "Inter",
                                    color: "#5C6674",
                                    marginBottom: "0.5em",
                                  }}
                                >
                                  {eachType?.label}
                                </Typography>
                                <Box
                                  sx={{
                                    height: "120px",
                                    overflow: "auto",
                                  }}
                                >
                                  {!!eachICP?.[eachType?.key]?.length && (
                                    <Box
                                      className={"keywords-listing"}
                                      display={"flex"}
                                      alignItems={"center"}
                                      flexWrap={"wrap"}
                                      sx={{
                                        marginBottom: "0.5em",
                                      }}
                                    >
                                      {eachICP?.[eachType?.key].map((data) => {
                                        return (
                                          <ListItem
                                            key={data}
                                            sx={{
                                              padding: "0.25em 0.5em 0.25em 0",
                                              width: "auto",
                                            }}
                                          >
                                            <Chip
                                              label={data}
                                              variant="outlined"
                                              onDelete={() => {}}
                                              sx={{
                                                fontSize: "0.75em",
                                                fontWeight: "500",
                                                lineHeight: "1em",
                                                backgroundColor: "#FFFFFF",
                                                border: "none",
                                                borderRadius: "1.25em",
                                                color: "#6D6E7C",
                                                borderRadius: "20px",
                                              }}
                                              deleteIcon={
                                                <CloseIconDark
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                              }
                                            />
                                          </ListItem>
                                        );
                                      })}
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            );
                          })}
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div
                    className="collapsed-show"
                    id={`svgEye${index}`}
                    onClick={
                      eachICP?.interests_keywords?.length ||
                      eachICP?.demographics_keywords?.length ||
                      eachICP?.behaviors_keywords?.length
                        ? () => {
                            setShow(index);
                          }
                        : () => {}
                    }
                  >
                    <Show />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          {KEYWORDLOADERLABELS.map((label, index) => (
            <div
              className={`collapsed-content
            ${
              dataToRender[index] && dataToRender[index]?.length === 0
                ? "opacity-50"
                : ""
            }
          `}
            >
              <div className="collapsed-label"> {label}</div>
              <div className="collapsed-data">
                <div className="collapsed-length mr-1">
                  {dataToRender[index] && (
                    <div>{dataToRender[index]?.length}</div>
                  )}
                  <div className="collapsed-keywords">Keywords</div>
                </div>
                <OverlayTrigger
                  id={`keywordOverlay${index}`}
                  show={show === index && data.popover}
                  placement="right"
                  rootClose
                  rootCloseEvent="mousedown"
                  onExited={() => {
                    setPopoverFlag(false);
                    setShow(-1);
                    setPopover(true);
                  }}
                  overlay={
                    <Popover
                      id={`popoverKeywordSetup${index}`}
                      className="popover-keyword-setup"
                    >
                      <Popover.Header as="h3" className="custom-popover-header">
                        <div>{label}</div>
                        <div className="popover-header-close">
                          <Close
                            onClick={() => {
                              setShow(-1);
                            }}
                          />
                        </div>
                      </Popover.Header>

                      <Popover.Body>
                        <div className="custom-popover-body">
                          {dataToRender[index]?.map((keyword) => (
                            <div className="custom-popover-keyword">
                              {keyword.keyword}
                            </div>
                          ))}
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div
                    className="collapsed-show"
                    id={`svgEye${index}`}
                    onClick={
                      dataToRender[index] && dataToRender[index]?.length > 0
                        ? () => {
                            setShow(index);
                          }
                        : () => {}
                    }
                  >
                    <Show />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
});
