import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import ActionImpactAnalysis from "../../Pages/ActionImpactAnalysis";
import AIModelConfidence from "../../Pages/AIModelConfidence";
import AllPlatform from "../../Pages/AllPlatform/AllPlatform";
import Campaigns from "../../Pages/Campaigns";
import CreativeAI from "../../Pages/CreativeAI/Facebook/Facebook";
import GoogleSearchPlayground from "../../Pages/CreativeAI/GsaiPlayground";
import GoogleSearchAI from "../../Pages/GoogleSearchAI/GoogleSearchAI";
import LaunchCampaigns from "../../Pages/LaunchCampaigns";
import MontageAds from "../../Pages/MontageAds";
import OutlineScreen from "../../Pages/OutlineScreen/OutlineScreen";
import TargetingAI from "../../Pages/TargetingAI";
import { setTargetingLinkClicks } from "../../store/actions/actionImpact";
import {
  getAllBrands,
  getGroups,
  setActiveBrand,
  setBrandLoading,
  setGroupId,
  setGroups,
  setGroupsLoading,
  setPlatform,
  setSelectedAdaccounts,
  setSelectedAdSet,
  setSelectedBrandAdaccounts,
  setSelectedElementId,
  setSelectedTargetingAdaccount,
  setSelectedGroup,
  getAllPlatforms,
  setActivePlatform,
  setSelectedCampaign,
  getBrandAdAccounts,
  setSelectedGroupAdaccounts,
  setAdAccountLoading,
  getGroupAdAccounts,
  // getCalculatorData,
  setSelectedGSAIAdaccount,
  setSelectedBrandGSAIAdaccounts,
  setGSAIPlatform,
  setSelectedGroupCampaign,
  setBrands,
  getBrandSources,
  setSelectedGroupAdset,
  setBrandRoles,
  setContextInfo,
  setPermissions,
  // setSavingCalculatorData,
} from "../../store/actions/adaccount";
import { getAccountDetails } from "../../store/actions/auth";
import { ActionImpactState } from "../../store/types/actionImpact";
import { initialState } from "../../store/types/auth";
import { CampaignState } from "../../store/types/campaign";
import { ClusterState } from "../../store/types/cluster";
import ButtonUI from "../../ui/ButtonUI/ButtonUI";
import CheckBox from "../../ui/CheckBox/CheckBox";
import DropdownUI from "../../ui/Dropdown/Dropdown";
import {
  checkPlatformSubType,
  getPlatformName,
} from "../../utils/commonFunctions";
import {
  getSearchParams,
  updateSearchParams,
} from "../../utils/commonFunctions";
import { getModuleName, mixPanelAdEvent } from "../../utils/mixpanel";
import AIGroupSettings from "../AIGroupSettings/AIGroupSettings";
import Loader from "../common/Loader";
import CreateAdAndPublish from "../CreateAdAndPublish/CreateAdAndPublish";
import DateRangeFilter from "../DateRange";
import KeywordsClustering from "../KeywordsClustering/KeywordsClustering";
import "./index.scss";
import { AdAccountState } from "../../store/types/adaccount";
import {
  FACEBOOK_PLATFORM_NAME,
  GOOGLE_SEARCH_PLATFORM_NAME,
} from "../../utils/constants";
import {
  getGoogleOnBoarding,
  getTargetingAI,
  setGoogleOnboarding,
  setGoogleOnBoardingLoading,
  setTargetingAI,
  setTargetingAILoading,
} from "../../store/actions/form";
import { FormState } from "../../store/types/form";
// import SavingCalculator from "../SavingCalculator/SavingCalculator";
import UploadKeywords from "../UploadKeywords/UploadKeywords";
import UserDetails from "../UserDetails/UserDetails";
import "./index.scss";
import NavigationDropDownItem from "./NavigationDropDownItem";
import CreateVariants from "../../Pages/CreativeAI/Facebook/CreateVariants/VariantsLayout";
import AccountsManager from "Pages/TokenManagementSystem/AccountsManager";
import BrandSetting from "../CreativeAI/AdminControl/BrandSetting";
import {
  setActivePlatformDebugger,
  setAdSet,
  setAIGroups,
  setAIGroupsLoading,
  setSelectedAdAccount,
  setSelectedAdAccountLoading,
  setSelectedCampaignDebugger,
} from "../../store/actions/ActionDebugger/ActionDebuggerHelper";
import ActionDebuggerCard from "components/ActionDebugger/ActionDebuggerCard/ActionDebuggerCard";
import BudgetPlanner from "Pages/BudgetPlanner";
import { ParentAiGroupDropdown } from "./ParentAIGroupDropdown/ParentAIGroupDropDown";
import ButtonComponent from "common/modal/ButtonComponent";

import CreateParentModal from "Pages/BudgetPlanner/pages/CreateParentModal";

import SuccessModal from "../../common/SuccessModal/index";
import SmbDashboard from "Pages/SmbDashboard/SmbDashboard";
import {
  setAiGroupItems,
  setSelectedAiGroup,
} from "../../store/actions/BudgetPlannerAiGroup";
import { Userpilot } from "userpilot";
import { useLocation } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import AudienceManagerAI from "../../Pages/AudienceManager";
import { BudgetPlannerHolder } from "Pages/BudgetPlanner/BudgetPlannerHolder/BudgetPlannerHolder";
import Facebook from "Pages/CreativeAI_Revamp/Facebook/Facebook";
import { useSocketConnection } from "./hooks/useSocketConnection";
import {
  setParentAiGroupItems,
  setSelectedParentAiGroup,
} from "store/actions/ParentAiGroup";
import { useBGAutoGenerate } from "./hooks/useBGAutogenerate";
import { ReactComponent as BackArrow } from "../../assets/svg/back_arrow.svg";
import { setActionDebuggerSwitch } from "store/actions/ActionDebugger/ActionDebugger";
import CreativeInsights from "Pages/AIInsights/CreativeInsights";
import AudienceInsights from "Pages/AIInsights/AudienceInsights";
import PerformanceInsights from "Pages/AIInsights/PerformanceInsights";
import ManageAgency from "Pages/ManageAgency/ManageAgency";
import {
  getContextInfoAPI,
  getGlobalPermissionsAPI,
  getMyRolesAPI,
} from "Pages/ManageAgency/UserRoles/API/getMyRoles";
import { useBGUTG } from "./hooks/useBGUTG";
import { DUMMYROLESDATA } from "utils/constants/dummyRolesData";
import { collator } from "utils/strings";
import mixpanel from "mixpanel-browser";

const Navigation: React.FC<unknown> = () => {
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isAdSet, setIsAdSet] = useState(false);
  const [showPlatformDropdown, setShowPlatformDropdown] = useState(false);
  const [showAdAccDropdown, setShowAdAccDropdown] = useState(false);
  const [showAdSetDropdown, setShowAdSetDropdown] = useState(false);
  const [showCampaignDropdown, setShowCampaignDropdown] = useState(false);
  const [showCalenderDropdown, setShowCalenderDropdown] = useState(true);
  const [selectedAds, setSelectedAds] = useState<any>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<any>([]);
  const [isParentGroupView, setIsParentGroupView] = useState<boolean>(false);
  const dispatch = useDispatch();
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const {
    groups,
    selectedGroup,
    platforms,
    selectedPlatform,
    selectedGroupAdaccounts,
    selectedAdAccount,
    selectedBrand,
    groupId,
    selectedBrandAdaccounts,
    selectedBrandGSAIAdaccounts,
    selectedElementId,
    groupsLoading,
    selecteGroupAdset,
    selecteGroupCamp,
    selectedTargetingAdaccount,
    selectedGSAIAdaccount,
    selectedBrandTargetingAdaccounts,
    adaccountLoading,
    selectedCampaign,
    selectedAdSet,
  } = adaccount;

  const actionImpact = useSelector(
    (state: { actionImpact: ActionImpactState }) => state.actionImpact
  );
  const { targetingLinkClicks } = actionImpact;
  const campaign = useSelector(
    (state: { campaign: CampaignState }) => state.campaign
  );
  const { spinner } = campaign;
  const formState = useSelector((state: { form: FormState }) => state.form);
  // const { savingCalculator: savingCalculatorData } = useSelector(
  //   (state: { adaccount: AdAccountState }) => state.adaccount
  // );
  const { targetingAI, googleOnboarding } = formState;
  const clusterState = useSelector(
    (state: { cluster: ClusterState }) => state.cluster
  );
  const { data } = useSelector(
    (state: { ParentAiGroup: any }) => state.ParentAiGroup
  );
  const { switchStatus } = useSelector((state: any) => ({
    switchStatus: state.ActionDebuggerReducer.actionDebuggerSwitch,
  }));
  const { gsaiDropdownDisabled } = clusterState;
  const [isGSAIDropdownVisible, setIsGSAIDropdownVisible] = useState(false);
  const [createAiGroupVisible, setCreateAiGroupVisible] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMeta, setSuccessMeta] = useState({
    heading: "",
    subHeading: "",
    onSuccess: null,
  });

  const [isAllPlatformDropDownVisible, setIsAllPlatformDropDownVisible] =
    useState(false);
  const [isTargetAIDropDownVisible, setIsTargetAIDropDownVisible] =
    useState(false);
  const [isDropdownGroupSelected, setIsDropdownGroupSelected] = useState(false);
  const { user } = useAuth0();

  const onDateRangeChange = (value: any) => {
    dispatch(setTargetingLinkClicks(value));
  };

  const elementID = selectedAdAccount?.elementId;
  const allPlatformCheck =
    history.location.pathname.includes("actionImpactAnalysis") ||
    history.location.pathname === "/";
  const location = useLocation();
  // import socket related code
  useSocketConnection();
  useBGAutoGenerate();
  useBGUTG();
  // useEffect(() => {
  //   allPlatformCheck &&
  //     groupId &&
  //     dispatch(
  //       getCalculatorData({ elementID: groupId, platformType: "aigroup" })
  //     );
  // }, [groupId, allPlatformCheck]);

  // useEffect(() => {
  //   if (selectedAdAccount?.elementId) {
  //     !allPlatformCheck &&
  //       elementID &&
  //       dispatch(
  //         getCalculatorData({ elementID: elementID, platformType: "element" })
  //       );
  //   } else {
  //     dispatch(setSavingCalculatorData({}));
  //   }
  // }, [selectedAdAccount, allPlatformCheck]);

  useEffect(() => {
    dispatch(
      getAllPlatforms(
        { user: authState.user },
        (response: any, error: boolean) => {
          if (!error) {
            const platformData = response.data;
            platformData.map((platform: any) => {
              return (platform.name = platform.type);
            });
            dispatch(setPlatform(platformData));
          }
        }
      )
    );
  }, []);

  useEffect(() => {
    dispatch(setBrandLoading(true));
    dispatch(
      getAllBrands(
        { user: authState.user },
        (response: any, error: boolean) => {
          if (!error && response?.data?.length > 0) {
            const { data } = response;
            const brand: any =
              data.find((g: any) => g.id === getSearchParams("brand_id")) ||
              data[0];
            dispatch(setActiveBrand(brand));
            dispatch(setBrands(data));
            dispatch(setBrandLoading(false));
          }
        }
      )
    );
  }, []);

  useEffect(() => {
    dispatch(setAdAccountLoading(true));
    dispatch(
      getBrandAdAccounts(
        { brandId: selectedBrand.id },
        (adaccRes: any, adaccError: boolean) => {
          if (!adaccError || adaccRes.data.length > 0) {
            adaccRes.data.map((adcc: any) => {
              adcc.name = adcc.adAccountId;
              adcc.id = adcc.adAccountId;
            });
            dispatch(setSelectedBrandAdaccounts(adaccRes.data));
            dispatch(setSelectedTargetingAdaccount({}));
            dispatch(setSelectedGSAIAdaccount({}));
            dispatch(setAdAccountLoading(false));
          } else {
            dispatch(setAdAccountLoading(false));
            dispatch(setSelectedBrandAdaccounts([]));
          }
        }
      )
    );
  }, [selectedBrand]);

  useEffect(() => {
    // Here
    if (selectedBrand?.id) {
      getMyRolesAPI(selectedBrand?.id, selectedBrand?.agencyId).then(
        (data: any) => {
          dispatch(setBrandRoles(data?.data?.userRoles || []));
          dispatch(setPermissions(data?.data?.permissions || []));
          mixpanel.people.set({ userType: data?.data?.user?.userType });
        }
      );
      getGlobalPermissionsAPI(null).then((data: any) => {
        dispatch(setPermissions(data?.data?.permissions || []));
      });
      adaccount?.contextInfo?.userManagementRoleContext ||
        getContextInfoAPI().then((data: any) => {
          dispatch(setContextInfo(data?.data || {}));
          // dispatch(setBrandRoles(DUMMYROLESDATA));
        });
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (selectedBrand && platforms.length && selectedBrandAdaccounts.length) {
      const selectedPlatform: any = platforms.find(
        (platform: any) =>
          platform.type.toLowerCase() ===
          GOOGLE_SEARCH_PLATFORM_NAME.toLowerCase()
      );
      const gsaiAdaccountsList = selectedBrandAdaccounts.filter(
        (adAcc: any) => adAcc.platformId == selectedPlatform?.id
      );
      dispatch(setGSAIPlatform(selectedPlatform));
      dispatch(setSelectedBrandGSAIAdaccounts(gsaiAdaccountsList));
      if (!selectedGSAIAdaccount?.id) {
        dispatch(setSelectedGSAIAdaccount(gsaiAdaccountsList[0]));
      }
    }
  }, [selectedBrand, platforms, selectedBrandAdaccounts]);

  useEffect(() => {
    if (history.location.pathname === "/targeting-ai") {
      dispatch(setTargetingAILoading(true));
      dispatch(
        getTargetingAI(
          { brandId: selectedBrand.id, user: authState.user },
          (response: any, error: boolean) => {
            dispatch(setTargetingAILoading(false));
            if (!error) {
              dispatch(
                setTargetingAI(
                  response.data.taiOnBoarding &&
                    response.data.taiOnBoarding === true
                )
              );
            }
          }
        )
      );
    }
  }, [selectedBrand]);

  useEffect(() => {
    //get google onboarding
    dispatch(setGoogleOnBoardingLoading(true));
    dispatch(
      getGoogleOnBoarding(
        { brandId: selectedBrand.id, user: authState.user },
        (response: any, error: boolean) => {
          dispatch(setGoogleOnBoardingLoading(false));
          if (response?.success) {
            if (response.data) {
              dispatch(setGoogleOnboarding(!!response.data.googleOnBoarding));
            }
          }
        }
      )
    );
  }, [selectedBrand]);

  useEffect(() => {
    dispatch(setGroupsLoading(true));
    dispatch(setAIGroupsLoading(true));
    dispatch(setParentAiGroupItems({}));
    dispatch(setSelectedParentAiGroup(null));
    dispatch(
      getGroups(
        { brandId: selectedBrand?.id, user: authState.user },
        (grpResponse: any, grpError: boolean) => {
          dispatch(setGroupsLoading(false));
          // needed for action debugger
          dispatch(setAIGroupsLoading(false));
          if (!grpError) {
            const groups = [...grpResponse.data];
            dispatch(setGroups(groups));
            const group: any =
              groups.find((g: any) => g.id === getSearchParams("group_id")) ||
              groups[0];
            dispatch(setSelectedGroup(group));
            // needed for action debugger
            dispatch(setAIGroups(group));
            dispatch(setGroupId(group?.id));

            //needed for Budget Planner
            const data = groups.reduce((pre: any, cur: any) => {
              pre[cur.id] = cur;
              return pre;
            }, {});
            dispatch(setAiGroupItems(data));
            dispatch(setSelectedAiGroup(group?.id));
          } else {
          }
        }
      )
    );
    dispatch(getBrandSources({ brandId: selectedBrand?.id }));
  }, [selectedBrand]);

  useEffect(() => {
    if (selectedGroup?.id && groups?.length > 0) {
      dispatch(setAdAccountLoading(true));
      dispatch(setSelectedAdAccountLoading(true));
      dispatch(setSelectedCampaignDebugger([]));
      dispatch(setAdSet([]));
      dispatch(
        getGroupAdAccounts(
          { groupId: selectedGroup?.id },
          (response: any, error: boolean) => {
            if (!error && response.data.length > 0) {
              const data = [...response.data];
              data.map((addata: any, index: number) => {
                data[index].name = addata.name || "NA";
                data[index].id = addata.adAccountId;
              });
              let selectedAdAcc =
                data.find((addData: any) =>
                  selectedElementId
                    ? addData.elementId === selectedElementId
                    : addData.adAccountId === getSearchParams("account_id")
                ) || data[0];
              updateSearchParams("account_id", selectedAdAcc.adAccountId);
              const platform: any = platforms.find(
                (g: any) => g.id === selectedAdAcc.platformId
              );
              dispatch(setActivePlatform(platform));
              dispatch(setSelectedAdaccounts(selectedAdAcc));
              dispatch(setSelectedAdAccount({ selectedAdAcc, platform }));
              dispatch(setSelectedElementId(selectedAdAcc.elementId));
              dispatch(setSelectedGroupAdaccounts(data));
              dispatch(setAdAccountLoading(false));
              dispatch(setSelectedAdAccountLoading(false));
              if (isDropdownGroupSelected) {
                mixPanelAdEvent("AI Group Selected", {
                  module_name: getModuleName(history.location.pathname),
                  brand_id: selectedBrand?.id,
                  brand_name: selectedBrand?.name,
                  ai_group_name: selectedGroup?.name,
                  brand_type: selectedBrand?.brandType,
                  ai_group_id: selectedGroup?.id,
                  ad_account_id: selectedAdAcc.id,
                  ad_account_name: selectedAdAcc.name,
                  agency_id: selectedBrand?.agencyId,
                  agency_name: selectedBrand?.agency_name,
                });
                setIsDropdownGroupSelected(false);
              }
            } else if (isDropdownGroupSelected) {
              mixPanelAdEvent("AI Group Selected", {
                module_name: getModuleName(history.location.pathname),
                brand_id: selectedBrand?.id,
                brand_name: selectedBrand?.name,
                brand_type: selectedBrand?.brandType,
                ai_group_name: selectedGroup?.name,
                ai_group_id: selectedGroup?.id,
                ad_account_id: null,
                ad_account_name: null,
                agency_id: selectedBrand?.agencyId,
                agency_name: selectedBrand?.agency_name,
              });
              dispatch(setSelectedGroupAdaccounts([]));
              dispatch(setSelectedAdaccounts({}));
              dispatch(setSelectedAdAccount({}));
              dispatch(setAdAccountLoading(false));
              dispatch(setSelectedAdAccountLoading(false));
              setIsDropdownGroupSelected(false);
            } else {
              dispatch(setSelectedGroupAdaccounts([]));
              dispatch(setSelectedAdaccounts({}));
              dispatch(setSelectedAdAccount({}));
              dispatch(setAdAccountLoading(false));
              dispatch(setSelectedAdAccountLoading(false));
            }
          }
        )
      );
      dispatch(setSelectedGroupCampaign([]));
    } else {
      dispatch(setSelectedGroupAdaccounts([]));
      dispatch(setSelectedAdaccounts({}));
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedGroup && selecteGroupAdset && selecteGroupAdset.length > 0) {
      const adsetList: any = selecteGroupAdset?.map((d: any) => d?.adsetId);
      setSelectedAds(adsetList);
      dispatch(setSelectedAdSet(adsetList));
      dispatch(setAdSet(adsetList));
    }
  }, [selecteGroupAdset]);

  useEffect(() => {
    if (selecteGroupAdset?.length > 0) {
      setIsAdSet(true);
    } else {
      setIsAdSet(false);
    }
  }, [selecteGroupAdset?.length > 0]);

  //campaigns
  useEffect(() => {
    const campaignsList = selecteGroupCamp?.map((d: any) => d?.campaignId);
    setSelectedCampaigns(campaignsList);
    dispatch(setSelectedCampaign({ selectedCampaign: campaignsList }));
    dispatch(setSelectedCampaignDebugger(campaignsList));
  }, [selecteGroupCamp]);

  useEffect(() => {
    if (selectedGroupAdaccounts.length > 0) {
      platforms.forEach((platform: any) => {
        platform.adaccountDetail = [];
        selectedGroupAdaccounts.forEach((adacc: any) => {
          if (
            platform.id === adacc.platformId &&
            !platform.adaccountDetail.some(
              (g: any) => g.adAccountId === adacc.adAccountId
            )
          ) {
            platform.adaccountDetail.push(adacc);
          }
        });
      });
    } else {
      platforms.forEach((platform: any) => {
        platform.adaccountDetail = [];
      });
    }
    dispatch(setPlatform([...platforms]));
  }, [selectedGroupAdaccounts, history.location]);

  useEffect(() => {
    const val = history.location.pathname === "/targeting-ai";
    setShowCalenderDropdown(val);
    const unlisten = history.listen((location: any, action: any) => {
      const val = location.pathname === "/targeting-ai";
      setShowCalenderDropdown(val);
    });

    return () => {
      if (unlisten) unlisten();
    };
  }, []);

  const noDropdownRoutes = React.useMemo(
    () => [
      "/targeting-ai",
      "/googlesearchai",
      "/googlesearchai/upload-keywords",
      "/googlesearchai/keywords-clustering",
      "/googlesearchai/create-ad-and-publish",
      "/googlesearchai-dashboard",
      "/user-details",
      "/montage-ads",
      "/launch-campaigns",
      "/outline-screen",
      "/googleSearchTargetAi",
      "/accounts-manager",
      "/brand-setting",
      "/google-search-playground",
      "/targeting-ai-audience-manager",
      "/creative-ai",
      "/creative-insights",
      "/audience-insights",
      "/performance-insights",
      "/manage-agency",
    ],
    []
  );

  const noAdAccDropdownRoutes = React.useMemo(
    () => [
      "/",
      "/user-details",
      "/aiModelConfidence",
      "/group-settings",
      "/singlePlatform",
      "/actionImpactAnalysis",
      "/montage-ads",
      "/outline-screen",
      "/accounts-manager",
      "/budget-planner",
      "/targeting-ai-audience-manager",
      "/creative-ai",
      "/creative-insights",
      "/audience-insights",
      "/performance-insights",
      "/manage-agency",
    ],
    []
  );

  const noPlatformDropdownRoutes = React.useMemo(
    () => [
      "/",
      "/targeting-ai",
      "/googlesearchai",
      "/googlesearchai/upload-keywords",
      "/googlesearchai/keywords-clustering",
      "/googlesearchai/create-ad-and-publish",
      "/googlesearchai-dashboard",
      "/user-details",
      "/aiModelConfidence",
      "/group-settings",
      "/actionImpactAnalysis",
      "/creative-ai",
      "/montage-ads",
      "/launch-campaigns",
      "/outline-screen",
      "/googleSearchTargetAi",
      "/accounts-manager",
      "/brand-setting",
      "/google-search-playground",
      "/budget-planner",
      "/targeting-ai-audience-manager",
      "/creative-insights",
      "/audience-insights",
      "/performance-insights",
      "/manage-agency",
    ],
    []
  );

  const noCampaignDropdownRoutes = React.useMemo(
    () => [
      "/",
      "/targeting-ai",
      "/googlesearchai",
      "/googlesearchai/upload-keywords",
      "/googlesearchai/keywords-clustering",
      "/googlesearchai/create-ad-and-publish",
      "/googlesearchai-dashboard",
      "/user-details",
      "/aiModelConfidence",
      "/group-settings",
      "/actionImpactAnalysis",
      "/creative-ai",
      "/montage-ads",
      "/launch-campaigns",
      "/outline-screen",
      "/googleSearchTargetAi",
      "/accounts-manager",
      "/brand-setting",
      "/google-search-playground",
      "/budget-planner",
      "/targeting-ai-audience-manager",
      "/creative-insights",
      "/audience-insights",
      "/performance-insights",
      "/manage-agency",
    ],
    []
  );

  const noAdsetsDropdownRoutes = React.useMemo(
    () => [
      "/",
      "/targeting-ai",
      "/googlesearchai",
      "/googlesearchai/upload-keywords",
      "/googlesearchai/keywords-clustering",
      "/googlesearchai/create-ad-and-publish",
      "/googlesearchai-dashboard",
      "/user-details",
      "/aiModelConfidence",
      "/group-settings",
      "/actionImpactAnalysis",
      "/creative-ai",
      "/montage-ads",
      "/launch-campaigns",
      "/outline-screen",
      "/googleSearchTargetAi",
      "/accounts-manager",
      "/brand-setting",
      "/google-search-playground",
      "/budget-planner",
      "/targeting-ai-audience-manager",
      "/creative-insights",
      "/audience-insights",
      "/performance-insights",
      "/manage-agency",
    ],
    []
  );

  const getDropDownRoutes = () => {
    if (!noDropdownRoutes.includes(history.location.pathname)) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
    if (!noAdAccDropdownRoutes.includes(history.location.pathname)) {
      setShowAdAccDropdown(true);
    } else {
      setShowAdAccDropdown(false);
    }
    if (!noPlatformDropdownRoutes.includes(history.location.pathname)) {
      setShowPlatformDropdown(true);
    } else {
      setShowPlatformDropdown(false);
    }
    if (!noCampaignDropdownRoutes.includes(history.location.pathname)) {
      setShowCampaignDropdown(true);
    } else {
      setShowCampaignDropdown(false);
    }
    if (!noAdsetsDropdownRoutes.includes(history.location.pathname)) {
      setShowAdSetDropdown(true);
    } else {
      setShowAdSetDropdown(false);
    }
  };

  useEffect(() => {
    getDropDownRoutes();
    // history.listen((loc) => {
    //   getDropDownRoutes();
    // });
    history.listen(() => {
      getDropDownRoutes();
    });
  }, [history, history.location]);

  const getPageTitle = () => {
    let path = history.location.pathname;
    if (path.includes("/googlesearchai")) return "Google Search AI";
    switch (path) {
      case "/targeting-ai":
        return "Targeting AI";
      case "/user-details":
        return "User Details";
      default:
        return "";
    }
  };

  const handleAllCheckbox = (type: "adset" | "campaign") => {
    if (type === "adset") {
      let AdsId = selecteGroupAdset.map((a: any) => a.adsetId);
      if (selectedAds.length === 0) {
        setSelectedAds(AdsId);
      } else if (selectedAds.length === selecteGroupAdset.length) {
        setSelectedAds([]);
      } else {
        const unSelectedAdIds = AdsId.filter(
          (element: any) => !selectedAds.includes(element)
        );
        setSelectedAds((ads: any) => [...ads, ...unSelectedAdIds]);
      }
    } else {
      let campIds = selecteGroupCamp.map((a: any) => a.campaignId);
      if (selectedCampaigns.length === 0) {
        setSelectedCampaigns(campIds);
      } else if (selectedCampaigns.length === selecteGroupCamp.length) {
        setSelectedCampaigns([]);
      } else {
        const unSelectedCampIds = campIds.filter(
          (element: any) => !selectedCampaigns.includes(element)
        );
        setSelectedCampaigns((camp: any) => [...camp, ...unSelectedCampIds]);
      }
    }
  };

  const handleCheckbox = (element: any, type: "adset" | "campaign") => {
    if (type === "adset") {
      const adIds = selectedAds.find((a: any) => a === element.adsetId);
      if (selectedAds.length === 0) {
        setSelectedAds([element.adsetId]);
      } else if (adIds) {
        setSelectedAds((ads: any) =>
          ads.filter((a: any) => a !== element.adsetId)
        );
      } else {
        setSelectedAds((ads: any) => [...ads, element.adsetId]);
      }
    } else {
      const campIds = selectedCampaigns.find(
        (a: any) => a === element.campaignId
      );
      if (selectedCampaigns.length === 0) {
        setSelectedCampaigns([element.campaignId]);
      } else if (campIds) {
        setSelectedCampaigns((camp: any) =>
          camp.filter((a: any) => a !== element.campaignId)
        );
      } else {
        setSelectedCampaigns((camp: any) => [...camp, element.campaignId]);
      }
    }
  };

  useEffect(() => {
    if (selectedAdAccount?.id) {
      dispatch(getAccountDetails());
    } else {
      dispatch(setActivePlatform({}));
    }
  }, [selectedAdAccount]);

  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  useEffect(() => {
    if (user?.email) {
      Userpilot.identify(user?.email, {
        name: user?.name,
        email: user?.email,
      });
    }
  }, [user]);

  return (
    <>
      {history.location.pathname !== "/creative-ai" &&
        history.location.pathname !== "/audience-insights" &&
        history.location.pathname !== "/creative-insights" &&
        history.location.pathname !== "/performance-insights" &&
        !history.location.pathname.startsWith("/smb-dashboard") && (
          <div
            className={`flex-row w-100 py-2 navigation  ${
              (history.location.pathname === "/creative-ai" ||
                history.location.pathname === "/montage-ads" ||
                history.location.pathname === "/outline-screen") &&
              "no-navigation"
            }`}
          >
            <div className="navigation--container">
              <div
                className={`flex-center flex-row flex-wrap  ${
                  !(
                    history.location.pathname === "/creative-ai" ||
                    history.location.pathname === "/montage-ads" ||
                    history.location.pathname === "/outline-screen"
                  ) && "navigation_dropdown_content justify-content-between"
                }`}
              >
                {switchStatus &&
                history.location.pathname === "/singlePlatform" ? (
                  <div
                    className="flex-center go-back-text mr-3"
                    onClick={() => {
                      dispatch(setActionDebuggerSwitch(false));
                      mixPanelAdEvent("AIA - Go Back Clicked", {
                        username: user?.name,
                        agency_id: selectedBrand?.agencyId,
                        agency_name: selectedBrand?.agency_name,
                        brand_id: selectedBrand?.id,
                        brand_name: selectedBrand?.name,
                        brand_type: selectedBrand?.brandType,
                        ad_account_id: selectedAdAccount?.id,
                        ad_account_name: selectedAdAccount?.name,
                        ai_group_name: selectedGroup?.name,
                        platform_name: getPlatformName(selectedPlatform?.name),
                      });
                    }}
                  >
                    {" "}
                    <BackArrow /> Go Back
                  </div>
                ) : (
                  ""
                )}

                {history.location.pathname === "/group-settings" && (
                  <ParentAiGroupDropdown
                    setIsParentGroupView={setIsParentGroupView}
                    isParentGroupView={isParentGroupView}
                  />
                )}
                {(history.location.pathname === "/group-settings"
                  ? !isParentGroupView
                  : !history.location.pathname.startsWith("/smb-dashboard/") &&
                    !history.location.pathname.startsWith("/budget-planner") &&
                    true) && (
                  <div className="flex-center navigation-dropdown ">
                    <Row className="dropdown-row">
                      {showDropdown &&
                        history.location.pathname !== "/group-settings" && (
                          <Col lg={4} className="d-flex align-items-center">
                            <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
                              AI Group
                            </h4>
                          </Col>
                        )}
                      {showDropdown && (
                        <Col lg={8}>
                          <span className="w-auto">
                            <div>
                              <DropdownUI
                                className="dropdown-ui"
                                isDropDownVisible={isAllPlatformDropDownVisible}
                                setIsDropDownVisible={
                                  setIsAllPlatformDropDownVisible
                                }
                                toggelButton={
                                  <ButtonUI className="btn secondary group-btn">
                                    <div className="btn-container">
                                      <div className="text-dark text-left text-truncate">
                                        {groupsLoading ? (
                                          <Loader
                                            height="20px"
                                            animation="grow"
                                            size="sm"
                                          />
                                        ) : selectedGroup ? (
                                          selectedGroup.name
                                        ) : (
                                          "No Groups Found"
                                        )}
                                      </div>
                                      {selectedGroup?.name && (
                                        <span className="dropdown_arrow">
                                          <svg
                                            width="12"
                                            height="8"
                                            viewBox="0 0 12 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                                              fill="black"
                                            />
                                          </svg>
                                        </span>
                                      )}
                                    </div>
                                  </ButtonUI>
                                }
                              >
                                {groups && (
                                  <NavigationDropDownItem
                                    data={groups.sort((group1, group2) =>
                                      collator.compare(
                                        group1.name.trim(),
                                        group2.name.trim()
                                      )
                                    )}
                                    onSelect={(id: any) => {
                                      if (id !== groupId) {
                                        const group = groups.find(
                                          (g: any) => g.id === id
                                        );
                                        dispatch(
                                          setSelectedGroup(group as any)
                                        );
                                        dispatch(setAIGroups(group as any));
                                        dispatch(setGroupId(id as any));
                                        dispatch(setSelectedAiGroup(id));
                                        dispatch(setSelectedGroupAdset([]));
                                        dispatch(setSelectedGroupCampaign([]));
                                        updateSearchParams(
                                          "group_id",
                                          id as any
                                        );
                                        if (
                                          history.location.pathname ===
                                          "/singlePlatform"
                                        ) {
                                          setIsDropdownGroupSelected(true);
                                        } else {
                                          mixPanelAdEvent("AI Group Selected", {
                                            module_name: getModuleName(
                                              history.location.pathname
                                            ),
                                            brand_id: selectedBrand?.id,
                                            brand_name: selectedBrand?.name,
                                            brand_type:
                                              selectedBrand?.brandType,
                                            ai_group_name: group?.name,
                                            ai_group_id: group?.id,
                                            ad_account_id: "ALL",
                                            ad_account_name: "ALL",
                                            agency_id: selectedBrand?.agencyId,
                                            agency_name:
                                              selectedBrand?.agency_name,
                                          });
                                        }
                                      }
                                    }}
                                    setVisible={setIsAllPlatformDropDownVisible}
                                  />
                                )}
                              </DropdownUI>
                            </div>
                          </span>
                        </Col>
                      )}
                    </Row>
                  </div>
                )}
                {showAdAccDropdown &&
                  history.location.pathname === "/targeting-ai" &&
                  targetingAI && (
                    <div className="flex-center navigation-dropdown ">
                      <Row className="dropdown-row">
                        <Col lg={4} className="d-flex align-items-center">
                          <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
                            {`AdAccounts`}
                          </h4>
                        </Col>
                        <Col lg={8}>
                          <span className="w-auto">
                            <div>
                              <DropdownUI
                                className="dropdown-ui"
                                isDropDownVisible={isTargetAIDropDownVisible}
                                setIsDropDownVisible={
                                  setIsTargetAIDropDownVisible
                                }
                                toggelButton={
                                  <ButtonUI className="btn secondary group-btn">
                                    <div className="btn-container">
                                      <div className="text-dark text-left text-truncate">
                                        {selectedTargetingAdaccount ? (
                                          (selectedTargetingAdaccount as any)
                                            .adAccountId || `No Adaccount Found`
                                        ) : adaccountLoading ? (
                                          <Loader
                                            height="20px"
                                            animation="grow"
                                            size="sm"
                                          />
                                        ) : (
                                          `No adaccount Present`
                                        )}
                                      </div>
                                      <span className="dropdown_arrow">
                                        <svg
                                          width="12"
                                          height="8"
                                          viewBox="0 0 12 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </ButtonUI>
                                }
                              >
                                {selectedBrandTargetingAdaccounts && (
                                  <NavigationDropDownItem
                                    data={selectedBrandTargetingAdaccounts}
                                    onSelect={(id: number) => {
                                      const adacc =
                                        selectedBrandTargetingAdaccounts.find(
                                          (g: any) => g.id === id
                                        );
                                      mixPanelAdEvent("Ad Account Selected", {
                                        module_name: "Targeting AI",
                                        brand_id: selectedBrand?.id,
                                        brand_name: selectedBrand?.name,
                                        brand_type: selectedBrand?.brandType,
                                        ad_account_name: adacc?.adAccount,
                                        ad_account_id: adacc?.id,
                                        platform_name: "Facebook",
                                        agency_id: selectedBrand?.agencyId,
                                        agency_name: selectedBrand?.agency_name,
                                      });
                                      dispatch(
                                        setSelectedTargetingAdaccount(adacc)
                                      );
                                    }}
                                    setVisible={setIsTargetAIDropDownVisible}
                                  />
                                )}
                              </DropdownUI>
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </div>
                  )}

                {showAdAccDropdown &&
                  (history.location.pathname.includes("/googlesearchai") ||
                    history.location.pathname === "/googleSearchTargetAi") &&
                  googleOnboarding && (
                    <div className="flex-center navigation-dropdown ">
                      <Row className="dropdown-row">
                        <Col lg={4} className="d-flex align-items-center">
                          <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
                            {`AdAccounts`}
                          </h4>
                        </Col>
                        <Col lg={8}>
                          <span className="w-auto">
                            <div>
                              <DropdownUI
                                isDropDownVisible={isGSAIDropdownVisible}
                                setIsDropDownVisible={setIsGSAIDropdownVisible}
                                className="dropdown-ui"
                                disabled={gsaiDropdownDisabled}
                                toggelButton={
                                  <ButtonUI
                                    className="btn secondary group-btn"
                                    disabled={gsaiDropdownDisabled}
                                  >
                                    <div className="btn-container">
                                      <div className="text-dark text-left text-truncate">
                                        {selectedGSAIAdaccount ? (
                                          (selectedGSAIAdaccount as any)
                                            .adAccountId || `No Adaccount Found`
                                        ) : adaccountLoading ? (
                                          <Loader
                                            height="20px"
                                            animation="grow"
                                            size="sm"
                                          />
                                        ) : (
                                          `No adaccount Present`
                                        )}
                                      </div>
                                      <span className="dropdown_arrow">
                                        <svg
                                          width="12"
                                          height="8"
                                          viewBox="0 0 12 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </ButtonUI>
                                }
                              >
                                {selectedBrandGSAIAdaccounts && (
                                  <NavigationDropDownItem
                                    data={selectedBrandGSAIAdaccounts}
                                    onSelect={(id: number) => {
                                      const adacc =
                                        selectedBrandGSAIAdaccounts.find(
                                          (g: any) => g.id === id
                                        );
                                      dispatch(setSelectedGSAIAdaccount(adacc));
                                      mixPanelAdEvent("Ad Account Selected", {
                                        module_name: "Google Search AI",
                                        brand_id: selectedBrand?.id,
                                        brand_name: selectedBrand?.name,
                                        brand_type: selectedBrand?.brandType,
                                        ad_account_name: adacc?.adAccount,
                                        ad_account_id: adacc?.id,
                                        platform_name: "Google",
                                        agency_id: selectedBrand?.agencyId,
                                        agency_name: selectedBrand?.agency_name,
                                      });
                                    }}
                                    setVisible={setIsGSAIDropdownVisible}
                                  />
                                )}
                              </DropdownUI>
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </div>
                  )}

                {showPlatformDropdown &&
                  platforms &&
                  platforms.length > 0 &&
                  !history.location.pathname.startsWith("/smb-dashboard/") &&
                  !history.location.pathname.startsWith("/budget-planner") && (
                    <div className="flex-center navigation-dropdown ">
                      <Row className="dropdown-row">
                        <Col lg={4} className="d-flex align-items-center">
                          <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
                            {`Platforms`}
                          </h4>
                        </Col>
                        <Col lg={8}>
                          {showPlatformDropdown && (
                            <span className="w-auto">
                              <div>
                                <Dropdown className="hoverable position-relative navigation-dropdown-btn">
                                  <Dropdown.Toggle>
                                    <div className="text-dark text-left text-truncate">
                                      {(selectedAdAccount?.adAccountId &&
                                        (selectedAdAccount.name
                                          ? selectedAdAccount.name + " - "
                                          : "") +
                                          selectedAdAccount.adAccountId) ||
                                        ` No Adaccount Found`}
                                    </div>
                                    <span className="dropdown_arrow">
                                      <svg
                                        width="12"
                                        height="8"
                                        viewBox="0 0 12 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu style={{ minWidth: "280px" }}>
                                    {platforms.map((platform: any) => {
                                      if (platform.adaccountDetail?.length) {
                                        return (
                                          <>
                                            <Dropdown.Item
                                              disabled={true}
                                              className="text-lg"
                                              style={{
                                                fontSize: "0.9rem !important",
                                                marginBottom: "0",
                                              }}
                                            >
                                              {" "}
                                              {platform.name === 'FACEBOOK AD' ? 'META AD' : platform.name }{" "}
                                            </Dropdown.Item>
                                            {platform.adaccountDetail
                                              .sort(
                                                (
                                                  adAccount1: any,
                                                  adAccount2: any
                                                ) =>
                                                  collator.compare(
                                                    adAccount1?.name?.trim(),
                                                    adAccount2?.name?.trim()
                                                  )
                                              )
                                              .map((data: any) => {
                                                return (
                                                  <Dropdown.Item
                                                    className={"py-0 px-3"}
                                                  >
                                                    <label
                                                      className="flex-center"
                                                      onClick={(e) => {
                                                        const adacc =
                                                          platform.adaccountDetail.find(
                                                            (g: any) =>
                                                              g.adAccountId ===
                                                              data.adAccountId
                                                          );
                                                        dispatch(
                                                          setActivePlatform(
                                                            platform
                                                          )
                                                        );
                                                        dispatch(
                                                          setSelectedAdaccounts(
                                                            adacc
                                                          )
                                                        );
                                                        dispatch(
                                                          setSelectedAdAccount({
                                                            selectedAdAcc:
                                                              adacc,
                                                            platform,
                                                          })
                                                        );
                                                        dispatch(
                                                          setSelectedElementId(
                                                            data.elementId
                                                          )
                                                        );
                                                        updateSearchParams(
                                                          "account_id",
                                                          adacc.adAccountId as any
                                                        );
                                                        mixPanelAdEvent(
                                                          "Ad Account Selected",
                                                          {
                                                            module_name:
                                                              getModuleName(
                                                                history.location
                                                                  .pathname
                                                              ),
                                                            brand_id:
                                                              selectedBrand?.id,
                                                            brand_name:
                                                              selectedBrand?.name,
                                                            brand_type:
                                                              selectedBrand?.brandType,
                                                            ad_account_name:
                                                              adacc?.name,
                                                            ad_account_id:
                                                              adacc?.adAccountId,
                                                            platform_name:
                                                              platform.name,
                                                            agency_id:
                                                              selectedBrand?.agencyId,
                                                            agency_name:
                                                              selectedBrand?.agency_name,
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        name="paltform-nav"
                                                        value={
                                                          data.selectedElementId
                                                        }
                                                        checked={
                                                          selectedElementId ===
                                                          data.elementId
                                                        }
                                                        defaultChecked={
                                                          selectedElementId ===
                                                          data.elementId
                                                        }
                                                      />
                                                      <div className="radio-label text-md ml-1">
                                                        <div className="account-name">
                                                          {data.name !== "NA"
                                                            ? data.name
                                                            : data.adAccountId}
                                                        </div>
                                                        <div className="account-id">
                                                          {data.adAccountId}
                                                        </div>
                                                      </div>
                                                    </label>
                                                  </Dropdown.Item>
                                                );
                                              })}
                                          </>
                                        );
                                      }
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </span>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}

                {!history.location.pathname.startsWith("/smb-dashboard/") &&
                  !history.location.pathname.startsWith("/budget-planner") && (
                    <div className="flex-center navigation-dropdown ">
                      {!groupsLoading &&
                        selectedPlatform &&
                        checkPlatformSubType(selectedPlatform?.name) ===
                          "adset" &&
                        showAdSetDropdown &&
                        isAdSet && (
                          <>
                            <Row className="dropdown-row">
                              <Col lg={4} className="d-flex align-items-center">
                                <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
                                  Ad Sets
                                </h4>{" "}
                              </Col>
                              <Col lg={8}>
                                <span className="w-auto">
                                  <div>
                                    <Dropdown className="hoverable position-relative navigation-dropdown-btn">
                                      <Dropdown.Toggle>
                                        <div className="text-dark text-left text-truncate">
                                          {selectedAdSet.length > 0
                                            ? `${selectedAdSet?.length} ad set selected`
                                            : "AD Sets"}
                                        </div>
                                        <span className="dropdown_arrow">
                                          <svg
                                            width="12"
                                            height="8"
                                            viewBox="0 0 12 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                                              fill="black"
                                            />
                                          </svg>
                                        </span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        style={{
                                          minWidth: "280px",
                                          minHeight:
                                            selectedAdSet?.length <= 2
                                              ? "auto"
                                              : "208px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            maxHeight: "152px",
                                            overflow: "auto",
                                          }}
                                        >
                                          <Dropdown.Item
                                            onClick={(e: any) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                            }}
                                            className="navigation-adset-option adset-all px-2"
                                          >
                                            <CheckBox
                                              className="navigation-adset-checkbox"
                                              onChange={() =>
                                                handleAllCheckbox("adset")
                                              }
                                              value={
                                                selectedAds?.length ===
                                                selecteGroupAdset?.length
                                              }
                                            />
                                            All
                                          </Dropdown.Item>
                                          {selecteGroupAdset
                                            ?.sort((adset1: any, adset2: any) =>
                                              collator.compare(
                                                adset1?.name?.trim(),
                                                adset2?.name?.trim()
                                              )
                                            )
                                            ?.map(
                                              (element: any, index: number) => (
                                                <Dropdown.Item
                                                  disabled={spinner}
                                                  className="navigation-adset-option px-2 flex-center"
                                                  eventKey={index}
                                                  key={element.adsetId}
                                                  style={{
                                                    backgroundColor: "#E9E9E9",
                                                  }}
                                                  onClick={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  <CheckBox
                                                    className="navigation-adset-checkbox"
                                                    onChange={() => {
                                                      handleCheckbox(
                                                        element,
                                                        "adset"
                                                      );
                                                    }}
                                                    value={selectedAds?.includes(
                                                      element.adsetId
                                                    )}
                                                  />
                                                  <div>
                                                    <div
                                                      className="adset-name"
                                                      title={element.name}
                                                    >
                                                      {element.name
                                                        ? element.name
                                                        : element.adsetId}
                                                    </div>
                                                    <div
                                                      className="adset-ids"
                                                      title={element.adsetId}
                                                    >
                                                      {element.adsetId}
                                                    </div>
                                                  </div>
                                                </Dropdown.Item>
                                              )
                                            )}
                                        </div>
                                        <Dropdown.Item
                                          onClick={(e: any) => {
                                            // on change of SelectedAdSet API is called from ActionImpactAnalysis
                                            if (selectedAds.length) {
                                              dispatch(
                                                setSelectedAdSet(selectedAds)
                                              );
                                              dispatch(setAdSet(selectedAds));
                                            } else {
                                              e.preventDefault();
                                              e.stopPropagation();
                                            }
                                          }}
                                          className="navigation-adset-option adset-apply"
                                        >
                                          <button
                                            disabled={!selectedAds?.length}
                                            className="btn primary apply-btn"
                                          >
                                            Apply
                                          </button>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </span>
                              </Col>
                            </Row>
                          </>
                        )}
                    </div>
                  )}

                {!history.location.pathname.startsWith("/smb-dashboard/") &&
                  !history.location.pathname.startsWith("/budget-planner") && (
                    <div className="flex-center navigation-dropdown ">
                      {selectedPlatform?.name &&
                        checkPlatformSubType(selectedPlatform?.name) ===
                          "campaign" &&
                        showCampaignDropdown && (
                          <>
                            {" "}
                            <Row className="dropdown-row">
                              <Col lg={4} className="d-flex align-items-center">
                                <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
                                  Campaign
                                </h4>
                              </Col>
                              <Col lg={8}>
                                <span className="w-auto">
                                  <div>
                                    <Dropdown className="hoverable position-relative navigation-dropdown-btn">
                                      <Dropdown.Toggle>
                                        <div className="text-dark text-left text-truncate">
                                          {selectedCampaign.length > 0
                                            ? `${selectedCampaign?.length} campaigns selected`
                                            : "Campaigns"}
                                        </div>
                                        <span className="dropdown_arrow">
                                          <svg
                                            width="12"
                                            height="8"
                                            viewBox="0 0 12 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                                              fill="black"
                                            />
                                          </svg>
                                        </span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        style={{
                                          minWidth: "280px",
                                          minHeight:
                                            selectedCampaign?.length <= 2
                                              ? "auto"
                                              : "208px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            maxHeight: "152px",
                                            overflow: "auto",
                                          }}
                                        >
                                          <Dropdown.Item
                                            onClick={(e: any) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                            }}
                                            className="navigation-adset-option adset-all"
                                          >
                                            <CheckBox
                                              className="navigation-adset-checkbox"
                                              onChange={() =>
                                                handleAllCheckbox("campaign")
                                              }
                                              value={
                                                selectedCampaigns?.length ===
                                                selecteGroupCamp?.length
                                              }
                                            />
                                            All
                                          </Dropdown.Item>
                                          {selecteGroupCamp
                                            ?.sort((camp1: any, camp2: any) =>
                                              collator.compare(
                                                camp1?.name?.trim() ||
                                                  camp1?.campaignId,
                                                camp2?.name?.trim() ||
                                                  camp2?.campaignId
                                              )
                                            )
                                            ?.map(
                                              (element: any, index: number) => (
                                                <Dropdown.Item
                                                  disabled={spinner}
                                                  className="navigation-adset-option flex-center"
                                                  eventKey={index}
                                                  key={element.campaignId}
                                                  style={{
                                                    backgroundColor: "#E9E9E9",
                                                  }}
                                                  onClick={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  <CheckBox
                                                    className="navigation-adset-checkbox"
                                                    onChange={() => {
                                                      handleCheckbox(
                                                        element,
                                                        "campaign"
                                                      );
                                                    }}
                                                    value={selectedCampaigns?.includes(
                                                      element.campaignId
                                                    )}
                                                  />
                                                  <div>
                                                    <div
                                                      className="adset-name"
                                                      title={element.name}
                                                    >
                                                      {element.name
                                                        ? element.name
                                                        : element.campaignId}
                                                    </div>
                                                    <div
                                                      className="adset-ids"
                                                      title={element.campaignId}
                                                    >
                                                      {element.campaignId}
                                                    </div>
                                                  </div>
                                                </Dropdown.Item>
                                              )
                                            )}
                                        </div>
                                        <Dropdown.Item
                                          onClick={(e: any) => {
                                            // on change of SelectedAdSet API is called from ActionImpactAnalysis
                                            if (selectedCampaigns.length) {
                                              dispatch(
                                                setSelectedCampaign({
                                                  selectedCampaign:
                                                    selectedCampaigns,
                                                  loadCampaignsData: true,
                                                })
                                              );
                                              dispatch(
                                                setSelectedCampaignDebugger(
                                                  selectedCampaigns
                                                )
                                              );
                                              mixPanelAdEvent(
                                                "Campaign Selected",
                                                {
                                                  module_name:
                                                    "Action Impact Analysis - Single platform",
                                                  brand_id: selectedBrand?.id,
                                                  brand_name:
                                                    selectedBrand?.name,
                                                  brand_type:
                                                    selectedBrand?.brandType,
                                                  ad_account_name:
                                                    selectedAdAccount?.name,
                                                  ad_account_id:
                                                    selectedAdAccount?.id,
                                                  agency_id:
                                                    selectedBrand?.agencyId,
                                                  agency_name:
                                                    selectedBrand?.agency_name,
                                                  campaign_id:
                                                    selectedCampaigns,
                                                  campaign_name:
                                                    selecteGroupCamp
                                                      ?.filter((camp: any) =>
                                                        selectedCampaigns.includes(
                                                          camp.campaignId
                                                        )
                                                      )
                                                      .map(
                                                        (selectedCamp: any) =>
                                                          selectedCamp.name
                                                            ? selectedCamp.name
                                                            : "NA"
                                                      ),
                                                }
                                              );
                                            } else {
                                              e.preventDefault();
                                              e.stopPropagation();
                                            }
                                          }}
                                          className="navigation-adset-option adset-apply"
                                        >
                                          <button
                                            disabled={
                                              !selectedCampaigns?.length
                                            }
                                            className="btn primary apply-btn"
                                          >
                                            Apply
                                          </button>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </span>
                              </Col>
                            </Row>
                          </>
                        )}
                    </div>
                  )}
              </div>
              {/* {history.location.pathname === "/" ? (
            <SavingCalculator
              module="actionImpactAnalysis"
              data={savingCalculatorData}
            />
          ) : (
            ["actionImpactAnalysis", "singlePlatform"].map((value, index) => {
              if (history.location.pathname.includes(value)) {
                return (
                  <SavingCalculator
                    module={value}
                    data={savingCalculatorData}
                  />
                );
              }
            })
          )} */}
              {history.location.pathname === "/singlePlatform" ? (
                <ActionDebuggerCard />
              ) : (
                <></>
              )}
              {showCalenderDropdown && (
                <span className="w-auto m-0 p-0">
                  <div>
                    <DateRangeFilter
                      defaultSelection={targetingLinkClicks}
                      callback={onDateRangeChange}
                    />
                  </div>
                </span>
              )}
              {history.location.pathname === "/group-settings" && (
                <ButtonComponent
                  label="+ Create Parent Ai Group"
                  variant="contained"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    ml: "auto",
                  }}
                  disabled={groups.length === 0}
                  onClick={() => setCreateAiGroupVisible(true)}
                />
              )}
              {createAiGroupVisible &&
                history.location.pathname === "/group-settings" && (
                  <CreateParentModal
                    open={createAiGroupVisible}
                    setOpen={setCreateAiGroupVisible}
                    setShowSuccess={setShowSuccess}
                    setSuccessMeta={setSuccessMeta}
                    setIsParentGroupView={setIsParentGroupView}
                  />
                )}
              {showSuccess &&
                history.location.pathname === "/group-settings" && (
                  <SuccessModal
                    open={showSuccess}
                    setOpen={setShowSuccess}
                    {...successMeta}
                  />
                )}
            </div>
          </div>
        )}
      <div
        className="d-flex flex-column"
        style={{
          flexGrow: 1,
          overflow:
            history.location.pathname === "/creative-ai" ? "hidden" : "",
        }}
      >
        <Switch>
          <Route path="/actionImpactAnalysis" component={AllPlatform} />
          <Route path="/singlePlatform" component={ActionImpactAnalysis} />
          <Route
            path="/group-settings"
            component={isParentGroupView ? BudgetPlanner : AIGroupSettings}
          />
          <Route path="/montage-ads" component={MontageAds} />
          <Route path="/campaigns" component={Campaigns} />
          <Route path="/aiModelConfidence" component={AIModelConfidence} />
          <Route path="/targeting-ai" component={TargetingAI} />
          <Route path="/user-details" component={UserDetails} />
          <Route path="/googlesearchai" exact component={GoogleSearchAI} />
          <Route path="/creative-ai" exact component={Facebook} />
          <Route
            path="/googleSearchTargetAi"
            exact
            component={GoogleSearchAI}
          />
          <Route
            path="/targeting-ai-audience-manager"
            exact
            component={AudienceManagerAI}
          />
          <Route path="/montage-ads" component={MontageAds} />
          <Route path="/launch-campaigns" component={LaunchCampaigns} />
          <Route path="/outline-screen" component={OutlineScreen} />
          <Route path="/accounts-manager" component={AccountsManager} />

          <Route path="/googlesearchai" exact component={GoogleSearchAI} />
          <Route
            path="/googlesearchai/upload-keywords"
            exact
            component={UploadKeywords}
          />
          <Route
            path="/googlesearchai/keywords-clustering"
            exact
            component={KeywordsClustering}
          />
          <Route
            path="/googlesearchai/create-ad-and-publish"
            exact
            component={CreateAdAndPublish}
          />
          <Route path="/brand-setting" exact component={BrandSetting} />
          <Route
            path="/google-search-playground"
            exact
            component={GoogleSearchPlayground}
          />
          <Route
            path="/budget-planner/:plannerid/:aigroupType"
            component={BudgetPlannerHolder}
          />
          <Route path="/creative-insights" component={CreativeInsights} />
          <Route path="/audience-insights" component={AudienceInsights} />
          <Route path="/performance-insights" component={PerformanceInsights} />
          <Route path="/manage-agency" component={ManageAgency} />
          <SmbDashboard />
        </Switch>
      </div>
    </>
  );
};

export default Navigation;
