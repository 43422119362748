import { Box, Button, CircularProgress, InputProps } from "@mui/material";
import { useState } from "react";

import {
  Controller,
  FieldValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  setUploadToggle,
  uploadMedia,
} from "store/actions/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import { initialState } from "store/types/auth";
import InputLabel from "../../InputLabel/InputLabel";
import { StyledErrorMessage } from "../../Typography/Heading/styled";
import {
  StyledInputContainer,
  StyledTextInputWithLabel,
} from "../Input/styled";

interface UploadInputProps extends InputProps {
  label?: string;
  required?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  isBorder?: boolean;
  tooltipText?: string;
  control?: any;
  registeredName?: any;
  helperFunction?: (registeredName: string) => void;
  acceptFileTypes?: string;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  textInputDisabled?: boolean;
}

export function UploadInputFormControl({
  required,
  label,
  tooltipText,
  control,
  registeredName,
  inputProps,
  helperFunction,
  acceptFileTypes,
  setValue,
  textInputDisabled,
  setError,
  ...props
}: UploadInputProps) {
  const [loader, setLoader] = useState<boolean>(false);
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const dispatch = useDispatch();
  const onClick = async (e: any, onChange: any) => {
    if (!e.target.files) {
      return;
    }
    const file = await e.target.files[0];
    const { name } = file;
    let type = "";
    if (registeredName.includes("previewFile")) {
      type = "preview";
      // if (file.type !== "image/png") {
      //   setError(registeredName + ".name", {
      //     type: "fileError",
      //     message: "Upload correct format",
      //   });
      // }
    } else {
      type = "blender";
      // if (file.type !== "application/zip") {
      //   setError(registeredName + ".name", {
      //     type: "fileError",
      //     message: "Upload correct format",
      //   });
      // }
    }
    if (file && file?.size < 50000000) {
      setLoader(true);
      dispatch(setUploadToggle(true));
      onChange(name);
      dispatch(
        uploadMedia({
          file: await e.target.files[0],
          user: auth.user,
          type,
          finalAction: (response: any, error: boolean) => {
            setLoader(false);
            dispatch(setUploadToggle(false));
            if (!error) {
              setValue(registeredName + ".value", response.id ?? null);
            } else {
              onChange(null);
              setValue(registeredName + ".value", null);
              setError(registeredName + ".name", {
                type: "uploadError",
                message: "Error while uploading the file",
              });
              //handle error
            }
          },
        })
      );
    } else {
      setError(registeredName + ".name", {
        type: "overSize",
        message: "Max. upload file size is 50Mb",
      });
      setLoader(false);
    }
  };
  return (
    <Controller
      control={control}
      name={registeredName + ".name"}
      rules={{
        required,
        ...inputProps,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <StyledInputContainer>
          {label && (
            <InputLabel
              required={required}
              label={label}
              tooltipText={tooltipText}
            />
          )}
          <Box display="flex">
            <StyledTextInputWithLabel
              onChange={(value) => {
                helperFunction && helperFunction(registeredName);
                onChange(value);
              }}
              value={value}
              disabled={textInputDisabled}
              {...props}
            />
            <Button
              sx={{
                background: "#E2EEFF",
                borderRadius: "8px",
                marginLeft: "12px",
                color: "#0869FB",
                fontFamily: "Inter",
                padding: "0px 24px",
                // fontWeight: "600",
                fontStyle: "normal",
                lineHeight: "24px",
                textTransform: "none",
                fontSize: "14px",
              }}
              component="label"
              disabled={loader}
            >
              {loader ? (
                <span>
                  <CircularProgress style={{ color: "#0869fb" }} size={15} />
                </span>
              ) : (
                `Browse`
              )}
              <input
                type="file"
                accept={acceptFileTypes}
                hidden
                onChange={(e) => onClick(e, onChange)}
              />
            </Button>
          </Box>
          {error?.type === "required" && (
            <StyledErrorMessage>This field is required *</StyledErrorMessage>
          )}
          {error?.type === "uploadError" && (
            <StyledErrorMessage>* {error.message}</StyledErrorMessage>
          )}
          {error?.type === "apiFailure" && (
            <StyledErrorMessage>* {error.message}</StyledErrorMessage>
          )}
          {error?.type === "overSize" && (
            <StyledErrorMessage>* {error.message}</StyledErrorMessage>
          )}
          {error?.type === "fileError" && (
            <StyledErrorMessage>* {error.message}</StyledErrorMessage>
          )}
        </StyledInputContainer>
      )}
    />
  );
}
