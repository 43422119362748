import Loader from "components/common/Loader";
import { payloadOfCreateDraft } from "components/CreativeAI/shared/commonFunctions";
import { Button } from "components/shared";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createDraftAds,
  updateDraftAds,
} from "store/actions/CreativeAI/GsaiPlayground";
import { AdAccountState } from "store/types/adaccount";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { GsaiState } from "store/types/CreativeAI/GsaiPlayground";
import { usePreview, usePublish } from "./hooks";
import { mixPanelAdEvent } from "utils/mixpanel";

const SaveAdVariants: React.FC<{ adUnit: any }> = ({ adUnit }): JSX.Element => {
  const dispatch = useDispatch();
  const gsaiPlayground = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );
  const { approverLink } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;
  const {
    isCreate,
    isCreateVariant,
    adAccount,
    campaign,
    dnd,
    recommendations,
    replacementConfig,
    existingDescriptions,
    existingHeadlines,
    path1,
    path2,
  } = gsaiPlayground;
  const { finalUrl } = usePublish();
  const { headlines, descriptions, publishDisabled } = usePreview();
  const [loading, setLoading] = useState(false);
  const handleSaveAdVariant = () => {
    const payload = payloadOfCreateDraft(
      isCreate,
      adAccount.adAccountId,
      campaign.id,
      adUnit,
      dnd,
      replacementConfig,
      recommendations,
      existingHeadlines,
      existingDescriptions,
      finalUrl,
      path1,
      path2
    );

    const onDraftSuccess = (res: any) => {
      dispatch({
        type: "UPDATE_GSAI_STORE",
        payload: {
          screen: 0,
        },
      });
      setLoading(false);
    };
    if (adUnit.isDraft && isCreateVariant === false) {
      mixPanelAdEvent("CAI GS Variant Updated", {
        module_name: "Google Search Playground",
        sub_module_name: gsaiPlayground.isCreate
          ? "Create Variant"
          : "Refresh Ad",
        brand_name: selectedBrand?.name,
        brand_id: selectedBrand?.id,
        brand_type: selectedBrand?.brandType,
        agency_id: selectedBrand?.agencyId,
        agency_name: selectedBrand?.agency_name,
        reference_ad_id: gsaiPlayground?.adUnit?.adGroupAd?.ad?.id,
        reference_ad_group_id: gsaiPlayground?.adUnit?.adGroup?.id,
        reference_campaign_id: gsaiPlayground?.adUnit?.campaign?.id,
        reference_account_id: gsaiPlayground?.adAccount?.adAccountId,
        ai_group_name: gsaiPlayground?.aiGroups?.selected?.name,
        ai_group_id: gsaiPlayground?.aiGroups?.selected?.id,
        ad_account_id: gsaiPlayground?.adAccounts?.selected?.adAccountId,
        ad_account_name: gsaiPlayground?.adAccounts?.selected?.name,
      });
    }

    setLoading(true);
    adUnit.isDraft && isCreateVariant === false
      ? dispatch(
          updateDraftAds({
            params: payload,
            id: adUnit.variant_id,
            callback: (res: any) => {
              onDraftSuccess(res);
            },
          })
        )
      : dispatch(
          createDraftAds({
            params: payload,
            callback: (res: any) => {
              onDraftSuccess(res);
            },
          })
        );
  };
  return (
    <Button
      buttonVariant="outlined"
      className="save-variant"
      onClick={handleSaveAdVariant}
      disabled={
        headlines.length < 3 ||
        descriptions.length < 2 ||
        finalUrl.length < 1 ||
        loading ||
        publishDisabled
      }
    >
      {(adUnit.isDraft && isCreateVariant === false ? "Update" : "Save") +
        " Variant"}{" "}
      {loading && <Loader height="18px" width="30px" size="sm" />}
    </Button>
  );
};

export default SaveAdVariants;
