import styled from "styled-components";
import { Button, styled as MUIstyled } from "@mui/material";

export const UnderLineTextButton = styled.div`
  font-family: "Lato";
  font-style: normal;
  // font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  cursor: pointer;
  color: #0869fb;
`;

export const StyledOutlinedButton = MUIstyled(Button)`
border-radius:12px;
color:#0869FB;
padding: 8px 24px 8px 24px;
text-transform:none;
`;
export const StyledSolidButton = MUIstyled(Button)`
border-radius:12px;
background:#0869FB;
padding: 8px 22px 8px 22px;
text-transform:none;
`;

export const StyleTextButton = MUIstyled(Button)`
text-transform:none;
font-size: 0.839vw;
@media screen and (max-width: 1280px) {
  font-size: 12px;
}
@media screen and (min-width: 1700px) {
  margin: 0.8125em 1em 0em 0em;
}
`;

export const ButtonContainer = styled(Button)`
  // padding: 8px 20px !important;
  border-radius: 12px !important;
  text-transform: none !important;
  font-size: 14px !important;
  outline: none !important;
  // background-color: #0869fb !important;
`;
