import httpService from "HttpService/HttpService";
import {
  call,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  postExecutionLoader,
  setAdvanceSetting,
  setAutoExecution,
  setBatchData,
  setBatchDetails,
  setNoRecommendationList,
  setNonRecommendedAds,
  setPreviewData,
} from "store/actions/ActionDebugger/CreativeRotation/creativeRotation.actions";
import {
  GetBatch,
  GetBatchDetails,
  Get_BATCH_DATA,
  Get_BATCH_DATA_DETAILS,
  GET_ADVANCE_SETTINGS,
  POST_ADVANCE_SETTINGS,
  SetBatchDetails,
  PostAdvanceSetting,
  PostExecution,
  POST_EXECUTION,
  GET_PREVIEW_DATA,
  GetPreviewData,
  CANCEL_PREVIEW_DATA,
  CANCEL_BATCH_DATA_DETAILS,
  SET_AUTO_EXECUTION,
  GET_NO_RECOMMENDATION_LIST,
  SET_NON_RECOMMENDED_ADS,
} from "store/types/ActionDebugger/CreativeRotation/creativeRotation.types";
import { BASE_URL } from "utils/path";

function* getBatchData({ payload: { elementId, pageNumber, adIds,  } }: GetBatch): any {
  try {
    // const url = `/v2/api/recommendation/creative-rotation/${elementId}?page=${1}&pageSize=10`;
    const url = adIds.length?`/v3/api/recommendation/creative-rotation/${elementId}?elementId=${elementId}&page=${pageNumber}&page_size=10&asset_l1_ids=${adIds.join(',')}`:
    `/v3/api/recommendation/creative-rotation/${elementId}?elementId=${elementId}&pipeline_anchor=${pageNumber}`;
    const response = yield call(httpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put(
        setBatchData({
          isLoading: false,
          isFailed: false,
          haveNextPage: true,
          data: response.data.data.results,
          auto_execution:response.data.data.auto_execution,
          nonRecommendedAds: response.data.data.non_recommended_ads,
          totalPage: response.data.data.total_pages,
        })
      );
    } else {
      yield put(
        setBatchData({
          isLoading: false,
          isFailed: true,
          haveNextPage: false,
          data: [],
        })
      );
    }
  } catch (error) {
    yield put(
      setBatchData({
        isLoading: false,
        isFailed: true,
        data: [],
      })
    );
  }
}

function* postAutoExecution({payload}:any): any {
  try {
    const url = `/v2/api/recommendation/creative-rotation-auto-approve/${payload.elementId}`;
    const response = yield call(httpService.post, BASE_URL, url, "", {action: payload.value});
    if (response.success) {
      // yield put(
      //   setAutoExecution({
      //     value:payload.value,
      //     elementId:payload.elementId,
      //     // callback:payload.callback
      //   })
      //   );
      payload.callback()
    } else {
      console.log("failed due to reason",response)
    }
  }catch{

  }
}

function* getNoRecommendationList({payload}:any): any {
  try {
    const url = `/v2/api/recommendation/creative-rotation-non-recommended/${payload.elementId}`;
    const response = yield call(httpService.get, BASE_URL, url);
    if (response?.data?.success) {
      yield put(
        setNoRecommendationList({
          data: response.data,
        })
      );
      if(payload.callback)
      {
        payload.callback()
      }
    } else {
      console.log("failed due to reason",response)
    }
  }catch{
    
  }
}

function* putNonRecommendedAds({payload}:any): any {
  try {
    const url = `/v2/api/recommendation/creative-rotation-non-recommended/${payload.elementId}`;
    const response = yield call(httpService.put, BASE_URL, url, "", {ads: payload.data});
    if (response.data.success) {
    } else {
      console.log("failed due to reason",response)
      alert(response?.message)
    }
  }catch(err){
    alert(err)
  }
}


function* getBatchDataDetails({
  payload: { elementId, batchId, asset_l1_id },
}: GetBatchDetails): any {
  try {
    // const url = `/v2/api/recommendation/creative-rotation-batch/${elementId}/${pipeline_id}?page=0&pageSize=-1`;
    const url = `/v3/api/recommendation/creative-rotation-batch/${elementId}/${batchId}?page=0&pageSize=-1&elementId=${elementId}&batchId=${batchId}&asset_l1_id=${asset_l1_id}`;
    const { response, cancel } = yield race({
      response: call(httpService.get, BASE_URL, url),
      cancel: take(CANCEL_BATCH_DATA_DETAILS),
    });
    if (!cancel) {
      if (response.data.success) {
        yield put(
          setBatchDetails({
            details: {
              isLoading: false,
              isFailed: false,
              data: response.data.data.results,
            },
            batchId,
            asset_l1_id,
          })
        );
      } else {
        yield put(
          setBatchDetails({
            details: {
              isLoading: false,
              isFailed: true,
              data: [],
            },
            batchId,
            asset_l1_id
          })
        );
      }
    }
  } catch (error) {
    yield put(
      setBatchDetails({
        details: {
          isLoading: false,
          isFailed: true,
          data: [],
        },
        batchId,
        asset_l1_id
      })
    );
  }
}

function* getAdvanceSetting({ payload }: SetBatchDetails): any {
  try {
    const url = `/v2/api/recommendation/creative-rotation-setting/${payload}`;
    const response = yield call(httpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put(
        setAdvanceSetting({
          isLoading: false,
          isFailed: false,
          data: response.data.data,
        })
      );
    } else {
      yield put(
        setAdvanceSetting({
          isLoading: false,
          isFailed: true,
          data: undefined,
        })
      );
    }
  } catch (error) {
    yield put(
      setAdvanceSetting({
        isLoading: false,
        isFailed: true,
        data: undefined,
      })
    );
  }
}

function* postAdvanceSetting({
  payload: { body, elementId },
}: PostAdvanceSetting): any {
  try {
    const url = `/v2/api/recommendation/creative-rotation-setting/${elementId}`;
    const response = yield call(httpService.post, BASE_URL, url, "", body);
    if (response.data.success) {
      yield put(
        setAdvanceSetting({
          isUpdating: false,
        })
      );
    } else {
      yield put(
        setAdvanceSetting({
          isFailed: true,
          isUpdating: false,
        })
      );
    }
  } catch (error) {
    yield put(
      setAdvanceSetting({
        isFailed: true,
        isUpdating: false,
      })
    );
  }
}

function* postExecution({
  payload: { body, elementId, userAction, batchId },
}: PostExecution): any {
  try {
    const url = `/v2/api/recommendation/creative-rotation-execution/${elementId}`;
    const response = yield call(httpService.post, BASE_URL, url, "", {
      data: body,
    });
    if (response.data.success) {
      yield put(
        postExecutionLoader({
          userAction,
          batchId,
          isLoading: false,
          isFailed: false,
        })
      );
    } else {
      yield put(
        postExecutionLoader({
          userAction,
          batchId,
          isLoading: false,
          isFailed: true,
        })
      );
    }
  } catch (error) {
    yield put(
      postExecutionLoader({
        userAction,
        batchId,
        isLoading: false,
        isFailed: true,
      })
    );
  }
}

function* getPreviewData({
  payload: { data, elementId, batchId, asset_l1_id },
}: GetPreviewData): any {
  try {
    const url = `/v2/api/recommendation/creative-rotation-preview/${elementId}`;

    const { response, cancel } = yield race({
      response: call(httpService.post, BASE_URL, url, "", { data }),
      cancel: take(CANCEL_PREVIEW_DATA),
    });

    if (!cancel) {
      if (response.data.success) {
        yield put(
          setPreviewData({
            data: response.data.data,
            isLoading: false,
            isFailed: false,
            batchId,
            asset_l1_id
          })
        );
      } else {
        yield put(
          setPreviewData({
            // isLoading: false,
            isFailed: true,
            batchId,
            asset_l1_id
          })
        );
      }
    }
  } catch (error) {
    yield put(
      setPreviewData({
        // isLoading: false,
        isFailed: true,
        batchId,
        asset_l1_id
      })
    );
  }
}

export function* CreativeRotationSagaWatcher() {
  yield takeLatest(Get_BATCH_DATA, getBatchData);
  yield takeLatest(SET_AUTO_EXECUTION, postAutoExecution);
  yield takeLatest(GET_NO_RECOMMENDATION_LIST, getNoRecommendationList);
  yield takeLatest(SET_NON_RECOMMENDED_ADS, putNonRecommendedAds);
  yield takeEvery(Get_BATCH_DATA_DETAILS, getBatchDataDetails);
  yield takeLatest(GET_ADVANCE_SETTINGS, getAdvanceSetting);
  yield takeLatest(POST_ADVANCE_SETTINGS, postAdvanceSetting);
  yield takeLatest(POST_EXECUTION, postExecution);
  yield takeEvery(GET_PREVIEW_DATA, getPreviewData);
}
