import {
  SET_PARENT_AI_GROUP_PLANNERS,
  SET_PARENT_AI_GROUP_PLANNERS_DETAILS,
  SET_PARENT_AI_GROUP_ITEMS,
  GET_PARENT_AI_GROUP_ITEMS,
  GET_PARENT_AI_GROUP_ITEM,
  DELETE_PARENT_AI_GROUP_ITEM,
  GET_PARENT_AI_GROUP_PLANNERS,
  GET_PARENT_AI_GROUP_PLANNER_DETAILS,
  GET_PARENT_AIGROUP_PLANNER_DETAILS_FAKE,
  CREATE_PARENT_AI_GROUP,
  CREATE_PARENT_AI_GROUP_PLANNER,
  UPDATE_PARENT_AI_GROUP_PLANNER,
  SetParentAiGroupPlanners,
  SetParentAiGroupItems,
  SetParentAiGroupPlannerDetails,
  GetParentAiGroupItems,
  GetParentAiGroupItem,
  DeleteParentAiGroupItem,
  GetParentAiGroupPlanners,
  GetParentAiGroupPlannerDetails,
  GetParentAigroupPlannerDetailsFake,
  CreateParentAiGroup,
  CreateParentAiGroupPlanner,
  UpdateParentAiGroupPlanner,
  DeleteBudgetPlanner,
  DELETE_BUDGET_PLANNER,
  SET_SELECTED_PARENT_AI_GROUP,
  SetSelectedParentAiGroup,
  AddSingleParentAiGroup,
  ADD_SINGLE_PARENT_AI_GROUP,
  DeleteSingleParentAiGroup,
  DELETE_SINGLE_PARENT_AI_GROUP,
  SET_PARENT_AI_GROUP_LOADER,
  SetParentAiGroupLoader,
} from "../../types/parentAiGroup/index";

export const setParentAiGroupPlanners = (
  payload: any
): SetParentAiGroupPlanners => ({
  type: SET_PARENT_AI_GROUP_PLANNERS,
  payload: payload,
});

export const setParentAiGroupItems = (payload: any): SetParentAiGroupItems => ({
  type: SET_PARENT_AI_GROUP_ITEMS,
  payload: payload,
});

export const setParentAiGroupPlannerDetails = (
  payload: any
): SetParentAiGroupPlannerDetails => ({
  type: SET_PARENT_AI_GROUP_PLANNERS_DETAILS,
  payload: payload,
});

export const getParentGroupAiItems = (
  payload: any,
  callback: Function
): GetParentAiGroupItems => ({
  type: GET_PARENT_AI_GROUP_ITEMS,
  payload: payload,
  callback,
});
export const getParentAiGroupItem = (
  payload: any,
  callback: Function
): GetParentAiGroupItem => ({
  type: GET_PARENT_AI_GROUP_ITEM,
  payload: payload,
  callback,
});
export const deleteParentAiItem = (
  payload: any,
  callback: Function
): DeleteParentAiGroupItem => ({
  type: DELETE_PARENT_AI_GROUP_ITEM,
  payload: payload,
  callback,
});

export const deleteBudgetPlanner = (
  payload: any,
  callback: Function
): DeleteBudgetPlanner => ({
  type: DELETE_BUDGET_PLANNER,
  payload: payload,
  callback,
});

export const getParentAiGroupPlanners = (
  payload: any,
  callback: Function
): GetParentAiGroupPlanners => ({
  type: GET_PARENT_AI_GROUP_PLANNERS,
  payload: payload,
  callback,
});
export const getParentAiGroupPlannerDetails = (
  payload: any,
  callback: Function
): GetParentAiGroupPlannerDetails => ({
  type: GET_PARENT_AI_GROUP_PLANNER_DETAILS,
  payload: payload,
  callback,
});
export const getParentAigroupPlannerDetailsFake = (
  payload: any,
  callback: Function
): GetParentAigroupPlannerDetailsFake => ({
  type: GET_PARENT_AIGROUP_PLANNER_DETAILS_FAKE,
  payload: payload,
  callback,
});
export const createParentAiGroup = (
  payload: any,
  callback: Function
): CreateParentAiGroup => ({
  type: CREATE_PARENT_AI_GROUP,
  payload: payload,
  callback,
});
export const createParentAiGroupPlanner = (
  payload: any,
  callback: Function
): CreateParentAiGroupPlanner => ({
  type: CREATE_PARENT_AI_GROUP_PLANNER,
  payload: payload,
  callback,
});
export const updateParentAiGroupPlanner = (
  payload: any,
  callback: Function
): UpdateParentAiGroupPlanner => ({
  type: UPDATE_PARENT_AI_GROUP_PLANNER,
  payload: payload,
  callback,
});

export const setSelectedParentAiGroup = (
  payload: string | null
): SetSelectedParentAiGroup => ({
  type: SET_SELECTED_PARENT_AI_GROUP,
  payload: payload,
});

export const addSingleParentAiGroup = (
  payload: any
): AddSingleParentAiGroup => ({
  type: ADD_SINGLE_PARENT_AI_GROUP,
  payload: payload,
});

export const deleteSingleParentAiGroup = (
  payload: any
): DeleteSingleParentAiGroup => ({
  type: DELETE_SINGLE_PARENT_AI_GROUP,
  payload: payload,
});

export const setParentAiGroupLoader = (
  payload: any
): SetParentAiGroupLoader => ({
  type: SET_PARENT_AI_GROUP_LOADER,
  payload: payload,
});
