import { combineReducers } from "redux";
import gsCalibration from "./gsCalibration";
import gsKeywordTuning from "./gsKeywordTuning";

const gsTargetingAI = combineReducers({
  gsCalibration,
  gsKeywordTuning,
});

export default gsTargetingAI;
