import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { useState } from "react";
import { ReactComponent as Arrow } from "../assests/bulk-action-arrow.svg";
import { KeywordContext } from "../KeywordContextProvider";
import { useContext, useEffect } from "react";
import InputField from "../../../../../SmbDashboard/common/InputField";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";

import {
  ADGROUP_ID_KEY,
  ADGROUP_NAME_KEY,
  BUCKET_NAMES,
  BULK_ACTIONS,
  MATCH_TYPES,
} from "../../../constants/KeywordExplorer";

const BulkAction = ({
  type,
  setSelectedAction,
  disabled,
}: {
  type: string;
  setSelectedAction: Function;
  disabled?: boolean;
}) =>
  disabled ? (
    <Tooltip
      sx={{ top: "-20px" }}
      title="Select keywords from Positive keywords or Negative keywords"
    >
      <Box
        sx={{
          textTransform: "none",
          py: 1.5,
          px: 2,
          cursor: "not-allowed",
          opacity: "0.5",
        }}
        onClick={() => setSelectedAction(type)}
      >
        <Grid container className="w-100">
          <Grid
            item
            xs={10}
            className="d-flex justify-content-start align-items-center"
          >
            <Typography className="font-10">{type}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            className="d-flex justify-content-end align-items-center"
          >
            <Arrow />
          </Grid>
        </Grid>
      </Box>
    </Tooltip>
  ) : (
    <Button
      sx={{
        textTransform: "none",
        py: 1.5,
        px: 2,
      }}
      onClick={() => setSelectedAction(type)}
    >
      <Grid container className="w-100">
        <Grid
          item
          xs={10}
          className="d-flex justify-content-start align-items-center"
        >
          <Typography className="font-10">{type}</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          className="d-flex justify-content-end align-items-center"
        >
          {!type.includes("Save Selected") && <Arrow />}
        </Grid>
      </Grid>
    </Button>
  );

const BulkActions = ({
  handleActionsClose,
  actionsId,
  actionsOpen,
  actionsAnchorEl,
}: any) => {
  const [keywordIdeas, positive, negetive] = BUCKET_NAMES;
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedMatchType, setSelectedMatchType] = useState("PHRASE");
  const [selectedBehaviour, setSelectedBehaviour] = useState(positive.type);

  const [selectedCampaign, setSelectedCampaign] = useState(null) as any;
  const [selectedAdgroup, setSelectedAdgroup] = useState(null) as any;
  const [createNewAdGroup, setCreateNewAdGroup] = useState(false);

  const {
    isAnythingSelected,
    deleteBulkClick,
    saveBulkClick,
    duplicateBulkClick,
    changeBulkProperty,
    behaviourBulkClick,
    moveBulkClick,
    campaignDetails,
    checkDeselectAllBucket,
  } = useContext(KeywordContext);

  useEffect(() => {
    if (selectedAction === BULK_ACTIONS[2]) {
      duplicateBulkClick();
      handleActionsClose();
      setSelectedAction(null);
    }
    if (selectedAction === BULK_ACTIONS[4]) {
      deleteBulkClick();
      handleActionsClose();
      setSelectedAction(null);
    }
    if (selectedAction === BULK_ACTIONS[5]) {
      saveBulkClick();
      handleActionsClose();
      setSelectedAction(null);
    }
  }, [selectedAction]);

  return (
    <Popover
      sx={{ borderRadius: "1rem" }}
      id={actionsId}
      open={actionsOpen}
      anchorEl={actionsAnchorEl}
      onClose={handleActionsClose}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
    >
      <Stack
        sx={{
          width: isAnythingSelected() ? "14rem" : "10rem",
          "& *": {
            fontFamily: "inter",
          },
        }}
      >
        {!selectedAction && isAnythingSelected() && (
          <>
            {BULK_ACTIONS.map((action) => (
              <BulkAction
                type={action}
                setSelectedAction={setSelectedAction}
                disabled={
                  action === "Behavior" &&
                  checkDeselectAllBucket(keywordIdeas.type)
                }
              />
            ))}
          </>
        )}
        {!isAnythingSelected() && (
          <Stack sx={{ p: 2 }}>
            <Typography
              sx={{ mb: 1 }}
              className="font-black font-12 font-weight-600"
            >
              Actions
            </Typography>
            <Typography className="font-10 font-weight-400 font-black-333">
              Please select at least one Keyword to view actions
            </Typography>
          </Stack>
        )}
        {selectedAction === BULK_ACTIONS[0] && (
          <>
            <Stack p={2} pb={4}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  p: "0 0 0.75rem 0",
                }}
                className="font-12"
              >
                Match Type
              </Typography>
              <RadioGroup
                value={selectedMatchType}
                name="radio-buttons-match"
                onChange={(e: any) => setSelectedMatchType(e.target.value)}
              >
                {MATCH_TYPES.map((matchType) => (
                  <FormControlLabel
                    key={matchType.value}
                    sx={{
                      height: "1rem",
                      "& .MuiFormControlLabel-labelPlacementEnd": {
                        marginBottom: 0,
                      },
                      "&  .MuiFormControlLabel-label": {
                        fontSize: "0.625rem",
                      },
                    }}
                    value={matchType.value}
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "0.875rem",
                          },
                        }}
                      />
                    }
                    label={matchType.label}
                  />
                ))}
              </RadioGroup>
              <Grid container spacing={2} className="w-100 justify-content-end">
                <Grid item xs={5} sx={{ paddingTop: 0 }}>
                  <Button
                    sx={{
                      textTransform: "none",
                    }}
                    onClick={handleActionsClose}
                  >
                    <Typography
                      className="font-10 font-weight-600"
                      sx={{ color: "#0869FB" }}
                    >
                      <Typography
                        className="font-10 font-weight-600"
                        sx={{ color: "#0869FB" }}
                      >
                        Cancel
                      </Typography>
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={5} sx={{ paddingTop: 0 }}>
                  <Button
                    variant="contained"
                    disableRipple
                    sx={{
                      padding: "0.25rem 1.25rem",
                      borderRadius: "0.5rem",
                      borderColor: "#0869FB",
                      textTransform: "none",
                      backgroundColor: "#0869FB",
                    }}
                    onClick={() => {
                      changeBulkProperty(
                        "segments.keyword.info.matchType",
                        selectedMatchType
                      );
                      handleActionsClose();
                    }}
                  >
                    <Typography className="text-white font-10 font-weight-600">
                      Apply
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </>
        )}
        {selectedAction === BULK_ACTIONS[1] && (
          <>
            <Stack p={2}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  p: "0 0 0.75rem 0",
                }}
                className="font-12"
              >
                Behavior
              </Typography>
              <RadioGroup
                value={selectedBehaviour}
                name="radio-buttons-behaviour"
                onChange={(e: any) => setSelectedBehaviour(e.target.value)}
              >
                <FormControlLabel
                  sx={{
                    height: "1rem",
                    "& .MuiFormControlLabel-label": {
                      fontSize: "0.625rem",
                    },
                  }}
                  value={positive.type}
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "0.875rem",
                        },
                      }}
                    />
                  }
                  label={positive.name}
                />
                <FormControlLabel
                  sx={{
                    height: "1rem",
                    "& .MuiFormControlLabel-label": {
                      fontSize: "0.625rem",
                    },
                  }}
                  value={negetive.type}
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "0.875rem",
                        },
                      }}
                    />
                  }
                  label={negetive.name}
                />
              </RadioGroup>
              <Grid
                container
                spacing={2}
                className="w-100 justify-content-end"
                sx={{ marginTop: "0.1rem" }}
              >
                <Grid item xs={5} sx={{ paddingTop: 0 }}>
                  <Button
                    sx={{
                      textTransform: "none",
                    }}
                    onClick={handleActionsClose}
                  >
                    <Typography
                      className="font-10 font-weight-600"
                      sx={{ color: "#0869FB" }}
                    >
                      <Typography
                        className="font-10 font-weight-600"
                        sx={{ color: "#0869FB" }}
                      >
                        Cancel
                      </Typography>
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={5} sx={{ paddingTop: 0 }}>
                  <Button
                    variant="contained"
                    disableRipple
                    sx={{
                      padding: "0.25rem 1.25rem",
                      borderRadius: "0.5rem",
                      borderColor: "#0869FB",
                      textTransform: "none",
                      backgroundColor: "#0869FB",
                    }}
                    disabled={!selectedBehaviour}
                    onClick={() => {
                      behaviourBulkClick(selectedBehaviour);
                      handleActionsClose();
                    }}
                  >
                    <Typography className="text-white font-10 font-weight-600">
                      Apply
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </>
        )}
        {selectedAction === BULK_ACTIONS[3] && (
          <Stack p={2} spacing="0.5rem">
            <Typography
              sx={{ fontFamily: "Inter", fontWeight: 600, pb: "0.25rem" }}
              className="font-12"
            >
              Move
            </Typography>
            <Select
              id="select-campaign"
              displayEmpty={true}
              renderValue={() =>
                selectedCampaign &&
                campaignDetails &&
                campaignDetails[selectedCampaign.id]
                  ? campaignDetails[selectedCampaign.id].name
                  : "Select Current Campaign"
              }
              // defaultValue="Select Current Campaign"
              // placeholder="Select Current Campaign"
              size="small"
              sx={{
                fontSize: "0.75rem",
                backgroundColor: "#F2F2F2",
                border: "none",
                "& fieldset": { top: 0 },
                "& legend": {
                  display: "none",
                },
              }}
              onChange={(event: any) => {
                let selectedCamp = campaignDetails[event.target.value];
                setSelectedCampaign({
                  id: selectedCamp.id,
                  name: selectedCamp.name,
                });
                setSelectedAdgroup(null);
              }}
            >
              {Object.values(campaignDetails).map((item: any) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
            {createNewAdGroup ? (
              <InputField
                autoFocus={true}
                value={selectedAdgroup ? selectedAdgroup : ""}
                setValue={setSelectedAdgroup}
              />
            ) : (
              <Select
                id="select-ad-group"
                size="small"
                displayEmpty={true}
                renderValue={() =>
                  selectedAdgroup ? selectedAdgroup.name : "Select Ad Group "
                }
                sx={{
                  fontSize: "0.75rem",
                  backgroundColor: "#F2F2F2",
                  border: "none",
                  "& fieldset": { top: 0 },
                  "& legend": {
                    display: "none",
                  },
                }}
                onChange={(event: any) => {
                  const selectedGroup =
                    campaignDetails[selectedCampaign.id].adGroups[
                      event.target.value
                    ];
                  setSelectedAdgroup({
                    id: selectedGroup[ADGROUP_ID_KEY],
                    name: selectedGroup[ADGROUP_NAME_KEY],
                    isNew: selectedGroup.isNew || false,
                  });
                }}
                disabled={!!!selectedCampaign}
              >
                {selectedCampaign &&
                  Object.values(
                    campaignDetails[selectedCampaign.id].adGroups
                  ).map((item: any) => (
                    <MenuItem value={item[ADGROUP_ID_KEY]}>
                      {item[ADGROUP_NAME_KEY]}
                    </MenuItem>
                  ))}
              </Select>
            )}

            <Button
              className="m-0 justify-content-start"
              sx={{
                textTransform: "none",
              }}
              disableRipple
              disabled={createNewAdGroup || selectedCampaign === null}
              onClick={() => {
                setCreateNewAdGroup(true);
                setSelectedAdgroup(null);
              }}
            >
              <Typography className="font-600 font-10">
                + New Ad Group
              </Typography>
            </Button>
            <Grid
              container
              spacing={2}
              className="mt-0 w-100 justify-content-end"
            >
              <Grid item xs={5} sx={{ pt: 1 }}>
                <Button
                  variant="text"
                  sx={{
                    textTransform: "none",
                  }}
                  onClick={handleActionsClose}
                >
                  <Typography
                    className="font-10 font-weight-600"
                    sx={{ color: "#0869FB" }}
                  >
                    Cancel
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={5} sx={{ pt: 1 }}>
                <Button
                  variant="contained"
                  disableRipple
                  sx={{
                    padding: "0.25rem 1.25rem",
                    borderRadius: "0.5rem",
                    borderColor: "#0869FB",
                    textTransform: "none",
                    backgroundColor: "#0869FB",
                  }}
                  onClick={() => {
                    moveBulkClick(
                      selectedCampaign.id,
                      selectedCampaign.name,
                      createNewAdGroup
                        ? Math.floor(Math.random() * 100000000 + 1)
                        : selectedAdgroup.id,
                      createNewAdGroup ? selectedAdgroup : selectedAdgroup.name,
                      createNewAdGroup,
                      selectedAdgroup.isNew
                    );
                    handleActionsClose();
                  }}
                  disabled={
                    selectedCampaign === null || selectedAdgroup === null
                  }
                >
                  <Typography className="text-white font-10 font-weight-600">
                    Apply
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Stack>
    </Popover>
  );
};
const BulkActionsPopover = () => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const handleActionsClick = (event: any) => {
    setActionsAnchorEl(event.currentTarget);
  };
  const actionsOpen = Boolean(actionsAnchorEl);
  const actionsId = actionsOpen ? "actions-simple-popover" : undefined;
  return (
    <>
      <Button
        variant="outlined"
        sx={{ textTransform: "none" }}
        onClick={handleActionsClick}
      >
        Bulk Actions
      </Button>
      {actionsOpen && (
        <BulkActions
          handleActionsClose={handleActionsClose}
          actionsId={actionsId}
          actionsOpen={actionsOpen}
          actionsAnchorEl={actionsAnchorEl}
        />
      )}
    </>
  );
};

export default BulkActionsPopover;
