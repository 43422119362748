import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdAccountState } from "store/types/adaccount";
import { mixPanelAdEvent } from "utils/mixpanel";

import type { GsaiState } from "../../../../../store/types/CreativeAI/GsaiPlayground";

function useDataList(type: "headlines" | "descriptions") {
  const dispatch = useDispatch();
  const gsaiPlayground = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const [dragElement, setDragElement] = React.useState<{
    id: string;
    source: GSAI_SOURCE;
  }>({ id: "", source: "recommended" });
  const { isCreate } = gsaiPlayground;

  const dnd = gsaiPlayground.dnd;
  const prefillRecommended = gsaiPlayground[type].data;

  useEffect(() => {
    const {
      dnd = {
        headlines: { toBePublished: [] },
        descriptions: { toBePublished: [] },
      },
    } = gsaiPlayground;

    const { headlines = [], descriptions = [] } = gsaiPlayground.hnd;
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        dnd: {
          ...dnd,
          headlines: {
            ...dnd.headlines,
            toBePublished: isCreate
              ? dnd.headlines.toBePublished
              : [...dnd.headlines.toBePublished, ...headlines],
          },
          descriptions: {
            ...dnd.descriptions,
            toBePublished: isCreate
              ? dnd.descriptions.toBePublished
              : [...dnd.descriptions.toBePublished, ...descriptions],
          },
        },
      },
    });
  }, [gsaiPlayground.hnd]);

  useEffect(() => {
    const data = dnd[type];
    const fetchedRecommendation = data.recommended.map(
      (recommended: any) => recommended.id
    );
    const filteredRecommendation = <any>[];
    prefillRecommended.forEach((recommendation: any) => {
      if (!fetchedRecommendation.includes(recommendation.id)) {
        filteredRecommendation.push(recommendation);
      }
    });
    if (
      data.recommended.length &&
      !data.more.length &&
      filteredRecommendation.length
    ) {
      data.recommended.push({ id: "new-value-segration", isNewVal: true });
      data.more = prefillRecommended;
    }
    data.recommended = data.recommended.concat(filteredRecommendation);
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        dnd: {
          ...dnd,
          [type]: {
            ...dnd[type],
            ...data,
          },
        },
      },
    });
  }, [prefillRecommended]);

  function onDragStart(id: string, source: GSAI_SOURCE) {
    setDragElement({ id, source });
  }

  function onDrop(key: string | number, target: GSAI_SOURCE) {
    const { source, id } = dragElement;
    console.log(source, target, id);
    const tempDnd: any = {
      source: [],
      target: [],
    };
    if (source === target) return;
    let _dragItemObj: any = null;
    tempDnd.source = dnd[type][source].filter((item: any) => {
      if (item.id === id) {
        _dragItemObj = item;
        return false;
      }
      return true;
    });
    if (key) {
      for (const item of dnd[type][target]) {
        if (item.id === key) {
          tempDnd.target.push(_dragItemObj);
        }
        tempDnd.target.push(item);
      }
    } else {
      tempDnd.target = [...dnd[type][target], _dragItemObj];
    }

    let commonKeys = {
      module_name: "Google Search Playground",
      sub_module_name: "Create Variant",
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      brand_id: selectedBrand?.id,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      reference_ad_id: gsaiPlayground?.adUnit?.adGroupAd?.ad?.id, // Need to check
      reference_ad_group_id: gsaiPlayground?.adUnit?.adGroup?.id,
      reference_campaign_id: gsaiPlayground?.adUnit?.campaign?.id,
      reference_account_id: gsaiPlayground?.adAccount?.adAccountId,
      ai_group_name: gsaiPlayground?.aiGroup?.selected?.name,
      ai_group_id: gsaiPlayground?.aiGroup?.selected?.id,
    };

    mixPanelAdEvent(
      `CAI GS Create Variant :${
        source === "recommended"
          ? "Recommendation to be published"
          : `${type} moved from To Be Published into Creative Replacement`
      } `,
      type === "headlines"
        ? {
            ...commonKeys,
            recommended_headline_text: _dragItemObj?.value,
          }
        : {
            ...commonKeys,
            recommended_description_text: _dragItemObj?.value,
          }
    );

    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        dnd: {
          ...dnd,
          [type]: {
            ...dnd[type],
            [source]: tempDnd.source,
            [target]: tempDnd.target,
          },
        },
      },
    });
  }

  const prefix = {
    headlines: "HEADLINE_",
    descriptions: "DESCRIPTION_",
  };

  function handlePinning(
    position: number,
    id: string,
    subtype: "toBePublished" | "replacements"
  ) {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        dnd: {
          ...dnd,
          [type]: {
            ...dnd[type],
            [subtype]: dnd[type][subtype]?.map((item: any) => {
              if (item.id === id) {
                return {
                  ...item,
                  pinnedField: position
                    ? `${prefix[type]}${position}`
                    : "UNSPECIFIED",
                };
              }
              return item;
            }),
          },
        },
      },
    });
  }

  function handleDelete(id: string, subtype: GSAI_SOURCE) {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        dnd: {
          ...dnd,
          [type]: {
            ...dnd[type],
            [subtype]: dnd[type][subtype].filter((item: any) => item.id !== id),
          },
        },
      },
    });
  }

  function handleInputChange(id: string, subtype: GSAI_SOURCE, value: string) {
    mixPanelAdEvent(
      `CAI GS Create Variant : Recommended ${type} Edited`,
      type === "headlines"
        ? {
            initial_headline: dnd?.headlines[subtype]?.find((item: any) => {
              if (item.id === id) {
                return item.value;
              }
            }),
            edited_headline: value,
          }
        : {
            initial_description: dnd?.descriptions[subtype]?.find(
              (item: any) => {
                if (item.id === id) {
                  return item.value;
                }
              }
            ),
            edited_description: value,
          }
    );
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        dnd: {
          ...dnd,
          [type]: {
            ...dnd[type],
            [subtype]: dnd[type][subtype].map((item: any) => {
              if (item.id === id) {
                return {
                  ...item,
                  value,
                };
              }
              return item;
            }),
          },
        },
      },
    });
  }

  return {
    dnd,
    onDragStart,
    onDrop,
    handlePinning,
    handleDelete,
    handleInputChange,
  };
}

export default useDataList;
