import React from "react";
import "./index.scss";

const ButtonUI: React.FC<{
  className?: string;
  type?: any;
  disabled?: boolean;
  onClick?: (value: any) => void | Function;
}> = ({ className, type, disabled, onClick, children }) => {
  return (
    <button
      className={`btnUI ${className}`}
      type={type ? type : "button"}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default ButtonUI;
