import FBApprover from "components/CreativeAI/Facebook/Review/FBApprover";
import ReviewLayout from "components/ReviewLayout/ReviewLayout";

export default function FacebookReview() {
  return (
    <ReviewLayout type="facebook">
      <FBApprover />
    </ReviewLayout>
  );
}
