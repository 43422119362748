import { ReactComponent as PixisLogoImage } from "assets/svg/logo.svg";
import { useHistory } from "react-router-dom";
import { setLocalStorageData } from "utils/commonFunctions";
import { tms_skip_brand_name } from "utils/constants";
import Container from "../Container";
import { useBrandDetails, usePlatform, useSupportedPlatforms } from "../hooks";
import Option from "./Option";
import "./styles.scss";
import { Divider } from "@mui/material";
import { supportedPlatforms } from "../hooks";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

type Props = {
  href: Record<string, string>;
  brandName: string;
};

export function SocialLogin({ href, brandName }: Props): JSX.Element {
  const history = useHistory();
  const navigate = useNavigate();
  const location = useLocation();
  const onSkipClick = () => {
    setLocalStorageData(tms_skip_brand_name, { name: brandName });
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('accessToken');
    navigate(`/accounts-manager?${searchParams.toString()}`);
  };
  const {agencyAssets} = useSelector((state:any) => state.common);

  return (
    <div className="tms-social-login">
      {agencyAssets?.logo ? <img src={agencyAssets?.logo} className="pixis-logo" style={{width:'43px', height:'43px'}}/>:<PixisLogoImage className="pixis-logo" />}
      <p className="content-header">Do more with {agencyAssets?.longTitle || 'Pixis.AI'}</p>
      <p className="content-msg">
        Beat your competitors at the 'numbers' game by targeting specific
        segments and exploring
        <br />
        complementary industry-related audiences.!
      </p>
      <p className="content-brand">
        Connect Ad Account with Brand : <span>{brandName}</span>
      </p>
      <div className="content-options">
        {supportedPlatforms.map((platform, index) => {
          return (
            platform.key !== "google analytics" && (
              <>
                <Option
                  platform={platform}
                  href={href}
                  buttonText={
                    platform.key === "google"
                      ? "Connect"
                      : "Connect Existing Accounts"
                  }
                />
                {index !== supportedPlatforms.length - 1 && index !== 2 && (
                  <AccountManagerDivider />
                )}
              </>
            )
          );
        })}
      </div>
      <a onClick={onSkipClick} className="skip-link">
        Skip, I will do it later
      </a>
    </div>
  );
}

function SocialLoginWithContainer(): JSX.Element {
  const { brand_id, brandName, source } = useBrandDetails();
  const redirect = usePlatform();
  const href = useSupportedPlatforms({
    brandName,
    brand_id,
  });

  if (!href.google || !href.facebook || !href.linkedin || !href.tiktok) {
    return (
      <Container>
        <div className="tms-loading">
          {redirect ? "Redirecting" : "Loading"}...
        </div>
      </Container>
    );
  }
  return (
    <Container>
      <SocialLogin href={href} brandName={brandName} />
    </Container>
  );
}

export const AccountManagerDivider = () => {
  return (
    <Divider
      orientation="vertical"
      variant="middle"
      flexItem
      style={{
        background: "#C9C9C9",
      }}
    />
  );
};

export default SocialLoginWithContainer;
