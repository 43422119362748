export const mapFactors = (items: any[]) => {
  const ROIResponse =
    items &&
    items
      .filter((item) => item.type === "roi")
      .map((item) => {
        return {
          id: item.id,
          metric: item.name,
          weight: item.weight,
          filter: item.filter,
          error: false,
          errorMessage: "",
        };
      });

  const QFResponse =
    items &&
    items
      .filter((item) => item.type === "quality")
      .map((item) => {
        return {
          id: item.id,
          metric: item.name,
          weight: item.weight,
          filter: item.filter,
          error: false,
          errorMessage: "",
        };
      });

  return {
    ROIResponse,
    QFResponse,
  };
};

export const mapFactorToResponse = (
  ROIMetrics: any[],
  QFMetrics: any[],
  ROIFactor: number
) => {
  const ROIResponse =
    ROIMetrics &&
    ROIMetrics.map((item) => {
      return {
        name: item.metric,
        filter: item.filter,
        weight: item.weight,
        type: "roi",
      };
    });

  const QFResponse =
    QFMetrics &&
    QFMetrics.map((item) => {
      return {
        name: item.metric,
        filter: item.filter,
        weight: item.weight,
        type: "quality",
      };
    });

  return {
    metrics: [...ROIResponse, ...QFResponse],
    weight_distribuition: {
      roi: ROIFactor,
      quality: 100 - ROIFactor,
    },
  };
};

export const mapStoryPayload = (activeAdAccount: any, elementId: string) => {
  return {
    fake_story_in: {
      target_cpr: activeAdAccount[elementId]?.target_cpr,
      target_result: activeAdAccount[elementId]?.target_result,
      result_metric_key: activeAdAccount[elementId]?.result_metric_key,
      is_attribuation_metric:
        activeAdAccount[elementId]?.is_attribuation_metric,
    },
  };
};

export const mapStoryResponse = (data: any) => {
  const activeAdAccount = {
    target_cpr: data.target_cpr
      ? data.target_cpr
      : data.target_cpr === 0
      ? 0
      : "",
    target_result: data.target_result
      ? data.target_result
      : data.target_result === 0
      ? 0
      : "",
    result_metric_key: data.result_metric_key ? data.result_metric_key : "",
    id: data.id ? data.id : "",
    is_attribuation_metric: data?.is_attribuation_metric
      ? data?.is_attribuation_metric
      : false,
  };
  return activeAdAccount;
};
export const mapTokenPayload = () => {
  return {
    message: "updated successfully",
  };
};
