import { Box, Switch, SwitchProps } from "@mui/material";
import { Controller } from "react-hook-form";
import InputLabel from "../../InputLabel/InputLabel";
import { StyledErrorMessage } from "../../Typography/Heading/styled";
import { styled } from "@mui/material/styles";

interface SwitchFormControlProps extends SwitchProps {
  label: string;
  required?: boolean;
  tooltipText?: string;
  registeredName?: any;
  control?: any;
  labelStyle?: any;
  helperFunction?: (registeredName: string) => void;
}

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#DAE3F3",
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#507AD7",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#ffffff",
  },
  ".MuiSwitch-thumb::before": {
    position: "absolute",
    width: "58px",
    left: "-10px",
    height: "20px",
    zIndex: 99999999,
    content: '""',
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#A1A1A1",
    borderRadius: 20 / 2,
  },
}));

export function SwitchFormControl({
  label,
  required,
  tooltipText,
  registeredName,
  control,
  helperFunction,
  labelStyle,
  ...props
}: SwitchFormControlProps) {
  return (
    <Controller
      control={control}
      name={registeredName}
      rules={{
        required,
      }}
      render={({
        field: { onChange, value },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <Box width="100%">
          <Box display="flex" width="100%">
            <Box mt="-10px">
              <StyledSwitch
                onClick={() => {
                  helperFunction && helperFunction(registeredName);
                  onChange(!value);
                }}
                checked={value}
                {...props}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Box>
            <InputLabel
              required={required}
              label={label}
              tooltipText={tooltipText}
              style={labelStyle}
            />
          </Box>
          {error && (
            <StyledErrorMessage>This field is required *</StyledErrorMessage>
          )}
        </Box>
      )}
    />
  );
}
