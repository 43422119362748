import { Box, CircularProgress } from "@mui/material";
import {
  generateConfigurePayload,
  getFormValues,
} from "components/CreativeAI/Facebook/utils/form.utils";
import { Loader } from "components/shared";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfigurationState,
  updateRecommendationSelectionState,
} from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  postMediaStudioProcessing,
  setImagePreviewUrl,
} from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import { getPresignedUrl } from "store/actions/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import { AdAccountState } from "store/types/adaccount";
import { initialState } from "store/types/auth";
import {
  CreateVariantsState,
  GENERATE_VARIANT_METADATA_STATE_VARIANT,
  ImageLayerType,
  Image_Recommendation,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
} from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { InitialMediaStudioType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import EditableMultiTextRadio from "../EditableTextRadio/EditableMultiTextRadio";
import { HashType, ItemType } from "../Editor.types";
import NoRecommendations from "../Recommendations/NoRecommendations";
import RecommendationImageComponent from "../Recommendations/RecommendationImageComponent";
import LayerOptionsComponent from "./LayerOptionsComponent";
import { imageMediaProcessingPayloadMapping } from "./shared/mediaCommon.utils";
import { StyledMultiOptionMainContanier } from "./styled";
import { filterVariantData } from "components/CreativeAI/Facebook/utils/filterLevers.utils";
import { APIGenerationType } from "components/CreativeAI_Revamp/Facebook/facebook.types";
import { mapAdAdSetToElement } from "components/CreativeAI/Facebook/utils/common.utils";
import { SuccessLoader } from "./shared/Loader/Loader";
import { StyledMediaLoader } from "../styled";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";

interface ImgTextProps {
  selectedTool: ItemType;
  mixPanelCreativeStudioSeeMore: any;
}

export default function ImageTextComponent({
  selectedTool,
  mixPanelCreativeStudioSeeMore,
}: ImgTextProps) {
  const [isRefreshLoading, toggleRefreshLoading] = useState(false);
  const [isDirty, toggleDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recommendationLoading, setRecommendationLoading] = useState(false);
  const {
    recommendationMetaData,
    recommendationSelectionState,
    createSpec,
    selectedAd,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const { imagePreviewUrl, getMediaStudioData } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { BUSINESS_UNIT_BY_ID_DATA, LEVERS } = useSelector((state: any) => ({
    BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
    LEVERS: state.MainReducer.defaultLevers,
  }));
  const { CREATIVE_ELEMENT_AI } = useSelector(
    (state: { selectCohortReducer: any; MainReducer: any }) => ({
      CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi,
    })
  );
  const { adAccountsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );

  // lever filteration
  const leverData: APIGenerationType[] = useMemo(
    () =>
      filterVariantData(LEVERS, [
        "user",
        "ad",
        "adset",
        "campaign",
        "ad_video",
        "ad_image",
      ]),
    [LEVERS]
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      getPresignedUrl({
        mediaId: getMediaStudioData?.data?.frames[0]?.previewFile.value,
        type: "preview",
        finalAction: (data: any, error: boolean) => {
          setLoading(false);
          dispatch(setImagePreviewUrl(data?.url));
        },
      })
    );
  }, []);
  const handleChange = (
    value: string,
    type: HashType,
    valueObject?: Image_Recommendation
  ) => {
    if (valueObject) {
      const variations = imageMediaProcessingPayloadMapping(valueObject.data);
      dispatch(
        postMediaStudioProcessing({
          adId: selectedAd?.id,
          failureReduxType: "MEDIA_STUDIO_IMAGE_PROCESSING_FAILURE",
          reduxActionType: "MEDIA_STUDIO_IMAGE_PROCESSING_LOADER",
          reduxStoreType: "POST_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE",
          variations,
        })
      );
    }

    dispatch(
      updateRecommendationSelectionState({
        isChanged: true,
        image: value,
      })
    );
  };

  useEffect(() => {
    if (!recommendationMetaData?.image) {
      setRecommendationLoading(true);
      dispatch(setConfigurationState(true));
      const createSpecAPI = selectedAd?.adcreatives?.data[0];
      let creativeElementData = { ...CREATIVE_ELEMENT_AI.data };
      const newLeverData = leverData.filter(
        (item: any) => item.generation_type !== "video"
      );
      const { newCreativeElementAI } = mapAdAdSetToElement({
        createSpec: createSpecAPI,
        targeting: {},
        creativeElementAI: creativeElementData,
        levers: newLeverData,
        getMediaStudioData,
      });

      const payload = generateConfigurePayload(
        {
          ...getFormValues(newCreativeElementAI, true),
          businessUnit: BUSINESS_UNIT_BY_ID_DATA?.businessUnit,
          businessUnitDetail: BUSINESS_UNIT_BY_ID_DATA?.businessUnitDetail,
        },
        LEVERS,
        "generate",
        "link_data",
        selectedBrand?.id,
        selectedAd?.id,
        adAccountsSelection?.selectedAdAccountId
      );

      delete payload.input_data?.description;
      delete payload.input_data?.headline;
      delete payload.input_data?.primary_text;
      delete payload.input_data?.video;

      dispatch({
        type: "GENERATE_VARIANT_METADATA",
        payload: {
          user: auth.user,
          data: payload,

          finalAction: GENERATE_VARIANT_METADATA_STATE_VARIANT,
        },
        callback: (response: any) => {
          if (response?.error) {
            setRecommendationLoading(false);
          }
        },
      });
    } else {
      setRecommendationLoading(false);
    }
  }, [recommendationMetaData?.image]);

  if (recommendationLoading) {
    return (
      <>
        <StyledMediaLoader id="load_editor"></StyledMediaLoader>
        <Box
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SuccessLoader />
        </Box>
      </>
    );
  }

  return recommendationMetaData?.image?.data ? (
    <StyledMultiOptionMainContanier id="panel">
      <RecommendationImageComponent
        url={imagePreviewUrl ?? ""}
        loading={loading}
      />
      <LayerOptionsComponent
        mixPanelCreativeStudioSeeMore={mixPanelCreativeStudioSeeMore}
        hash={selectedTool.hash}
        value={recommendationSelectionState.image}
        handleChange={handleChange}
        options={recommendationMetaData.image}
        titleText={selectedTool?.titleText}
      />
    </StyledMultiOptionMainContanier>
  ) : (
    <NoRecommendations />
  );
}
