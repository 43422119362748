import {
  GENERATE_COHORTS_ICP,
  GET_TAI_COHORT_ICP,
  GET_TAI_KWS_ICP,
  GenerateCohortsICP,
  GetTAICohortICP,
  GetTAIKWSICP,
  REFRESH_COHORTS_ICP,
  RefreshCohortICP,
  STORE_FAVOURITE_COHORT_ICP,
  STORE_TAI_KWS_ICP,
  StoreFavouriteCohortICP,
  StoreTAIKWSICP,
  UPDATE_COHORTS_ICP,
  UpdateCohortICP,
} from "store/types/targetingICP";

export const storeTargetingAIKWSICP = (
  payload: any,
  callback: Function
): StoreTAIKWSICP => ({
  type: STORE_TAI_KWS_ICP,
  payload,
  callback,
});

export const getTargetingAIKWSICP = (
  payload: any,
  callback: Function
): GetTAIKWSICP => ({
  type: GET_TAI_KWS_ICP,
  payload,
  callback,
});

export const generateCohortsICP = (
  payload: {
    kwsData: any;
    aiGroupId: string;
    platformId: string;
  },
  callback: Function
): GenerateCohortsICP => ({
  type: GENERATE_COHORTS_ICP,
  payload,
  callback,
});

export const getTAICohortICP = (
  payload: any,
  callback: Function
): GetTAICohortICP => ({
  type: GET_TAI_COHORT_ICP,
  payload,
  callback,
});

export const storeFavouriteCohortICP = (
  payload: {
    favouriteCohort: [{}];
    aiGroupId: any;
  },
  callback: Function
): StoreFavouriteCohortICP => ({
  type: STORE_FAVOURITE_COHORT_ICP,
  payload,
  callback,
});

export const updateCohortICP = (
  payload: {
    payloadData: any;
  },
  callback: Function
): UpdateCohortICP => ({
  type: UPDATE_COHORTS_ICP,
  payload,
  callback,
});

export const refreshCohortICP = (
  payload: {
    payloadData: any;
  },
  callback: Function
): RefreshCohortICP => ({
  type: REFRESH_COHORTS_ICP,
  payload,
  callback,
});
