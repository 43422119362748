import styled from "styled-components";

export const StyledConfigFooter = styled.div`
    width:100%;
    background:white;
    position:absolute;
    bottom:0;
    left:0;
    height:64px;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
    border-top: 1px solid #EFEFEF;
    z-index:2;
    padding:0px 20px;
`

export const ConfigKeywordInfoDetail=styled.div`
    flex:1;
    min-height:0px;
    background:#FAFAFA;
    padding:24px;
    border-radius:8;
    border:1px solid #EFEFEF;
`
export const StyledConfig=styled.div`
    height:100%;
    alignItems:center;
    overflow:hidden;
    display:flex;
    className:overflow-scroll;
    flex:1;
    min-width:0px;
    padding-top:4vh;
    .left-box{
        width:55%;
        height:100%;
        display:flex;
        flex-direction:column;
        margin:0px 2vw 0px 3vw;
    }
    .right-box{
        display:flex;
        flex-direction:column;
        height:100%;
        flex:1;
    }
    .right-box-heading{
        display:flex;
        justify-content:center;
        visibility:collapse;
    }
`