import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { StyledApproverBodyBox, StyledApproverBox } from "./styled";
import { StyledVerticalDivider } from "components/shared";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedVariantId } from "store/actions/CreativeAI/shared/Review/review";
import { ReviewInitialState } from "store/types/CreativeAI/shared/Review/review";
import { useSearchParams } from "react-router-dom-v5-compat";
import PaginationReview from "./PaginationReview/PaginationReview";

export default function FBApprover() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const {
    requestedVariantsMetaData: { data },
    selectedVariantRequestedId,
  } = useSelector(
    (state: { reviewReducer: ReviewInitialState }) => state.reviewReducer
  );
  const onPageClick = (index: number) => {
    dispatch(updateSelectedVariantId(index));
  };
  useEffect(() => {
    if (data?.variants.length && selectedVariantRequestedId === undefined) {
      let initialIndex = 0;
      const variantId = searchParams.get("variantId");
      if (variantId) {
        initialIndex = data?.variants.findIndex(
          (item) => item.id === variantId
        );
      }
      dispatch(updateSelectedVariantId(initialIndex >= 0 ? initialIndex : 0));
    }
  }, [data?.variants]);

  return (
    <StyledApproverBox flex={1}>
      <StyledApproverBodyBox flex={1}>
        <LeftPanel />
        <StyledVerticalDivider
          height="100%"
          sx={{
            margin: "0em .8em",
          }}
        />
        <RightPanel />
      </StyledApproverBodyBox>
      <Box alignSelf="center" pt="1em">
        <PaginationReview
          count={data?.variants?.length}
          onChange={(event: React.ChangeEvent<unknown>, page: number) => {
            onPageClick(page - 1);
          }}
          page={
            selectedVariantRequestedId !== undefined
              ? selectedVariantRequestedId + 1
              : 1
          }
        />
      </Box>
    </StyledApproverBox>
  );
}
