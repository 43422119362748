import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/common/Loader";
import GroupsListing from "../components/GroupsListing";
import MessageContainer from "../components/MessageContainer/MessageContainer";
import Notification from "../components/Notification";
import {
  getTargetingAI,
  setTargetingAI,
  setTargetingAILoading,
  updateTargetingAI,
} from "../store/actions/form";
import { initialState } from "../store/types/auth";
import { FormState } from "../store/types/form";
import { onBoardingData } from "../utils/constants";
import { AdAccountState } from "../store/types/adaccount";
import { mixPanelAdEvent } from "../utils/mixpanel";

const TargetingAI: React.FC = (): JSX.Element => {
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { targetingAI, targetingLoading } = formState;
  const showListing = useMemo(() => targetingAI, [targetingAI]);
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  const dispatch = useDispatch();

  useEffect(() => {
    mixPanelAdEvent("Page View - Dashboard", {
      module_name: "Targeting AI",
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_name: selectedBrand?.agency_name,
      agency_id: selectedBrand?.agencyId,
    });
  }, []);

  useEffect(() => {
    dispatch(setTargetingAILoading(true));
    dispatch(
      getTargetingAI(
        { brandId: selectedBrand.id, user: auth.user },
        (response: any, error: boolean) => {
          dispatch(setTargetingAILoading(false));
          if (!error) {
            dispatch(
              setTargetingAI(
                response.data.taiOnBoarding &&
                  response.data.taiOnBoarding === true
              )
            );
          }
        }
      )
    );
  }, [selectedBrand]);

  return (
    <>
      {!targetingLoading && showListing && auth?.learningPhase && (
        <Notification
          bgColor="#000"
          color="#fff"
          text="Targeting AI is in learning phase. Targeting recommendations will start getting generated after the learning phase ends."
        />
      )}
      {targetingLoading && (
        <div className="position-absolute d-flex align-items-center justify-content-center w-100 h-100">
          <Loader />
        </div>
      )}
      {!targetingLoading && auth && (
        <>
          {!showListing ? (
            <MessageContainer
              data={onBoardingData}
              submitText="Unlock Targeting AI"
              handleNext={() => {
                dispatch(
                  updateTargetingAI(
                    {
                      brandId: selectedBrand.id,
                      user: auth?.user,
                      enable: true,
                    },
                    (response: any, error: boolean) => {
                      if (!error) {
                        dispatch(setTargetingAI(!targetingAI));
                        mixPanelAdEvent(
                          "Unlock_onboarding_targeting_AI_click",
                          {
                            module_name: "Targeting AI",
                            brand_name: selectedBrand?.name,
                            platform_name: "Facebook",
                            brand_id: selectedBrand?.id,
                            brand_type: selectedBrand?.brandType,
                            agency_name: selectedBrand?.agency_name,
                            agency_id: selectedBrand?.agencyId,
                          }
                        );
                      }
                    }
                  )
                );
              }}
            />
          ) : (
            <GroupsListing />
          )}
        </>
      )}
    </>
  );
};

export default TargetingAI;
