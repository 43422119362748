import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Popover,
  Button,
  IconButton,
} from "@mui/material";
import { ReactComponent as LeftArrow } from "../../../assets/smb/ArrowWithRoundLeft.svg";
import { ReactComponent as RightArrow } from "../../../assets/smb/ArrowWithRoundRight.svg";
import ButtonComponent from "../common/ButtonComponent";
import { ReactComponent as Calendar } from "../assets/calendarIcon.svg";
import { Months } from "../../../utils/constants/index";

interface IMonthPickerProps {
  currentYear: any;
  currentMonth: any;
  onChange: Function;
}

function MonthPicker(props: IMonthPickerProps) {
  const { currentYear, currentMonth, onChange } = props;

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-month" : undefined;

  return (
    <>
      <Button
        className="d-flex align-items-center w-100 justify-content-space-between"
        onClick={handleClick}
        disableRipple
        variant="outlined"
        sx={{
          width: "8.25rem",
          height: "2.5rem",
          padding: 1,
          borderRadius: 2,
          gap: 1,
          border: "1px solid #D4D4D4",
          "&:hover": {
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "1px solid #D4D4D4",
          },
        }}
      >
        <Calendar />
        <Typography
          variant="caption"
          sx={{
            color: "#848484",
            textTransform: "capitalize",
            fontWeight: 600,
          }}
        >
          {Months[currentMonth]}&nbsp;:&nbsp;{currentYear}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          mt: 1,
          ml: -2,
          p: 0,
        }}
      >
        <Box
          className="border"
          sx={{
            height: "17.75rem",
            width: "19.93rem",
            borderRadius: 2,
            p: 0,
          }}
        >
          <Stack spacing={1} sx={{ p: 0 }}>
            <Box
              className="d-flex justify-content-around align-items-center"
              sx={{ padding: 1.5, backgroundColor: "#ECECEC" }}
            >
              <IconButton
                disableRipple
                onClick={() => {
                  setSelectedYear(selectedYear - 1);
                  console.log("LL: MonthPicker -> selectedYear", selectedYear);
                }}
              >
                <LeftArrow />
              </IconButton>
              <Typography
                className="text-center w-75"
                sx={{ fontWeight: 600, fontSize: 18, fontFamily: "Inter" }}
              >
                {selectedYear}
              </Typography>
              <IconButton
                disableRipple
                onClick={() => {
                  setSelectedYear(selectedYear + 1);
                }}
              >
                <RightArrow />
              </IconButton>
            </Box>
            <Box
              className="flex-wrap d-flex justify-content-center w-100 "
              sx={{ mx: 2 }}
            >
              {Object.keys(Months).map((key) => (
                <Button
                  disableRipple
                  variant="text"
                  key={key}
                  onClick={() => {
                    setSelectedMonth(parseInt(key));
                  }}
                  className="text-center cursor-pointer "
                  sx={{
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: `${
                      parseInt(key) === selectedMonth ? "#ECECEC" : ""
                    }`,
                    color: "#000",
                    "&:hover":
                      parseInt(key) === selectedMonth
                        ? { background: "#ECECEC" }
                        : {},
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Inter !important",
                      fontWeight: "400",
                      textTransform: "none",
                    }}
                  >
                    {Months[parseInt(key)]}
                  </Typography>
                </Button>
              ))}
            </Box>
            <Box>
              <Stack
                spacing={2}
                direction="row"
                pr={3}
                className="d-flex justify-content-end"
              >
                <ButtonComponent
                  variant="outlined"
                  label="Cancel"
                  onClick={handleClose}
                  sx={{ px: 2 }}
                />
                <ButtonComponent
                  variant="contained"
                  label="Save"
                  sx={{ px: 2.5 }}
                  onClick={() => {
                    onChange(selectedMonth, selectedYear);
                    handleClose();
                  }}
                />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}

export default MonthPicker;
