import React, { useState } from "react";
import { StyledPopover, StyledToolTipWithBlock } from "./styled";
import tipImage from "../../../../../../assets/svg/info.svg";
import { PopoverOrigin } from "@mui/material";

export function Popover({
  children,
  style,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
}: {
  children: any;
  style?: any;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <StyledToolTipWithBlock style={style} className="flex flex-center">
      <img
        src={tipImage}
        aria-owns={open ? "mouse-over-popover" : undefined}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        alt=""
        style={{ cursor: "pointer", width: ".8125em", height: ".8125em" }}
      />
      <StyledPopover
        id="mouse-over-popover"
        className="content"
        sx={{
          pointerEvents: "none",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {children}
      </StyledPopover>
    </StyledToolTipWithBlock>
  );
}
