import React from "react";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "../../../../../ui/InputLabel/InputLabel";
import { ReactComponent as Circle } from "../../../../../assets/svg/circle.svg";
import { ReactComponent as Preview } from "../../../../../assets/svg/preview.svg";
import { ReactComponent as Separator } from "../../../../../assets/svg/Separator.svg";
import type { GsaiState } from "../../../../../store/types/CreativeAI/GsaiPlayground";
import "./index.scss";
import { usePreview } from "../CreateAdVariants/hooks";
import { StyledToolTipContent } from "../../Shared/GsaiLandingPageCard/styled";
import TooltipWithInfo from "components/CreativeAI/Facebook/shared/TooltipWithInfo/TooltipWithInfo";

const CreateAdPreview: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { finalUrl, path1, path2 } = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );

  const displayPath = React.useMemo(
    () => `${finalUrl.split("/")[2]} ${path1 ? "/" : ""} ${path1 || ""}
    ${path2 ? "/" : ""}  ${path2 || ``}`,
    [finalUrl, path1, path2]
  );

  const { headlines, descriptions } = usePreview();

  const DescPreview = (descriptions: any) => {
    if (descriptions.length > 0) {
      let descPre: any = [];
      descriptions.map((descEach: any) => {
        descPre.push(
          descEach.charAt(descEach.length - 1) !== "."
            ? descEach + "."
            : descEach
        );
      });
      return descPre;
    }
  };

  return (
    <div className="gsai-playground-variant-preview">
      {/* Preview */}
      <div className="gsai-playground-preview--body">
        {/* Header */}
        <div>
          {/* Final URL */}
          <div className="gsai-playground-selection--heading d-flex align-items-center">
            Final URL
            <span className="gsai-playground--icon-container">
              <StyledToolTipContent style={{ cursor: "pointer" }}>
                <TooltipWithInfo
                  top={"-10px"}
                  info=" The Final URL is the URL that people reach after clicking your
                ad. It should match what your ad promotes. If you use a
                cross-domain redirect, enter it in a tracking template."
                />
              </StyledToolTipContent>
            </span>
          </div>
          <InputLabel
            placeholder="https://www.example.com"
            onInputChange={(e) => {
              dispatch({
                type: "UPDATE_GSAI_STORE",
                payload: {
                  finalUrl: e.target.value,
                },
              });
            }}
            name="finalURL"
            value={finalUrl}
            inputClassName="gsai-playground-final-url"
          />
          {/* Display URL */}
          <div className="gsai-playground-selection--heading d-flex align-items-center">
            Display URL
            <span className="gsai-playground--icon-container">
              <StyledToolTipContent style={{ cursor: "pointer" }}>
                <TooltipWithInfo
                  top={"-10px"}
                  info="The 'Path' fields are part of your Display URL (the green text
                    under your headline text) and can have up to 15 characters each.
                    
                    The Display URL gives potential customers a clear idea of the
                    web page that they'll reach once they've clicked your ad, so
                    your path text should describe your ad's landing page"
                />
              </StyledToolTipContent>
            </span>
          </div>
          <div className="gsai-playground-input-container">
            <label className="gsai-playground-display-url">
              {" "}
              {finalUrl.split("/")[2]}
            </label>
            <div className="input-separator">
              <Separator />
            </div>
            <InputLabel
              limit={15}
              placeholder="path1"
              onInputChange={(e) => {
                dispatch({
                  type: "UPDATE_GSAI_STORE",
                  payload: {
                    path1: e.target.value,
                  },
                });
              }}
              name="path1"
              value={path1}
              inputClassName="gsai-playground-display-url-path"
            />
            <div className="input-separator">
              <Separator />
            </div>
            <InputLabel
              limit={15}
              placeholder="path2"
              onInputChange={(e) => {
                dispatch({
                  type: "UPDATE_GSAI_STORE",
                  payload: {
                    path2: e.target.value,
                  },
                });
              }}
              name="path2"
              value={path2}
              inputClassName="gsai-playground-display-url-path"
            />
          </div>
        </div>
        <div>
          <div className="gsai-playground-preview--header">
            <div className="perview">Preview</div>
            <Preview />
          </div>
          <div className="gsai-playground-preview-container">
            <div className="preview--header">
              <div className="preview--header--title">
                <div className="preview--header--container">
                  <b>Ad</b> <Circle /> <span>{displayPath}</span>
                </div>
              </div>
              <div className="preview--header--heading">
                {headlines.length > 0
                  ? headlines.join(" | ")
                  : "Headline 1 | Headline 2 | Headline 3"}
              </div>
              <div className="preview--header--desc">
                {descriptions.length
                  ? DescPreview(descriptions).join(" ")
                  : "Description 1 . Description 2"}
              </div>
            </div>
          </div>
        </div>

        <div className="preview-description">
          This preview shows potential ads assembled using your assets. Not all
          combinations are shown. Assets can be shown in any order, so make sure
          that they make sense individually or in combination, and don’t violate
          our policies or local law. Some shortening may also occur in some
          formats. You can make sure certain text appears in your ad.
        </div>
      </div>
    </div>
  );
};

export default CreateAdPreview;
