import { Radio } from "@mui/material";
import { StyledFormControlLabel, StyledRadioGroup } from "./styled";
import { OptionType } from "..";

interface PxRadioGroupProps {
  options: OptionType[];
  onChange: (item: any) => void;
  selected?: OptionType;
}

export function PxRadioGroup({
  options,
  onChange,
  selected,
}: PxRadioGroupProps) {
  return (
    <StyledRadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="female"
      name="radio-buttons-group"
      // onClick={()=>     onChange(item);}
    >
      {options.map((item, index) => (
        <StyledFormControlLabel
          onClick={() => {
            onChange(item);
          }}
          control={
            <Radio
              size="small"
              // eslint-disable-next-line eqeqeq
              checked={selected?.value == item?.value}
              sx={{
                color: "#A1A1A1",
                "&.Mui-checked": {
                  color: "#0869FB",
                },
              }}
            />
          }
          key={item.value}
          label={item.desc}
        />
      ))}
    </StyledRadioGroup>
  );
}
