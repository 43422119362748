import styled from "styled-components";
import { ReactComponent as DeleteIconWithBorder } from "assets/svg/DeleteIconWithBorder.svg";

export const StyledDeleteIcon = styled(DeleteIconWithBorder)`
  height: 1.875rem;
  width: 1.875rem;
  cursor: pointer;
`;

export const StyledEmptyRecord = styled.span`
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-size: 0.75rem;
  color: #313131;
`;
