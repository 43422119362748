import { MenuItem } from "@mui/material";
import styled, { css } from "styled-components";
import { styled as MUIStyled } from "@mui/material/styles";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 25.3125rem;

  label {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #1c1e21;
    margin-bottom: 0.5rem;
  }

  input {
    width: 100%;
    height: 3.125rem;
    border: 0.0625rem solid #dddddd;
    border-radius: 0.5rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #333333;
    padding: 0.9375rem;
  }
`;

export const StyledVariantContainer = styled.div`
  margin-bottom: 1.25rem;
  width: 100%;
`;

export const StyledCreateNewText = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: #7a7a7a;
  text-align: center;
  margin-bottom: 1.125rem;
`;

export const StyleNewVariantInput = styled.input`
  height: 3rem !important;
`;

export const StyledHeaderTitle = styled.span`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.15rem;
  line-height: 1.5rem;
  color: #1c1e21;
`;

export const StyledDropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 0.75rem;
`;

interface menuItemProps {
  ads: any;
}

export const StyledSelectMenuItem = MUIStyled(MenuItem)<menuItemProps>`
  ${({ ads }) =>
    ads &&
    css`
      opacity: 0.5;
      cursor: progress;
      background: "black";
    `}
`;
