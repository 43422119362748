import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { BudgetPlannerProgressbar } from "./BudgetPlannerProgressbar/BudgetPlannerProgressbar";
import { ReactComponent as InfoIcon } from "assets/svg/info_grey.svg";
import { ReactComponent as LockedIcon } from "assets/svg/lockedIcon.svg";
import {
  PacingsStatusColors,
  PacingType,
  ProgressBarBackgroundColors,
  ProgressBarColors,
} from "../../Types";
import moment from "moment";
import { checkIsBeforeToday } from "utils/date.util";
import { getDecimalValues } from "utils/commonFunctions";

export const BudgetPlannerDay: React.FC<{
  day: any;
  isPacingNameShow: boolean;
  selectDate: Function;
  weekIndex: number;
  dayIndex: number;
  selectedPacing: PacingType | null;
  updateCalendarDay: Function;
  selectedMode: number;
  calDifference: any;
  handleUnlockBuchgetDistribution: (
    weekIndex: number,
    dayIndex: number
  ) => void;
  getAllCalendarDaysForSelectedPacing: any;
  showNew: any;
  festivalDays: any;
  selectedBrand: any;
}> = ({
  day,
  isPacingNameShow,
  selectDate,
  weekIndex,
  dayIndex,
  selectedPacing,
  updateCalendarDay,
  selectedMode,
  calDifference,
  handleUnlockBuchgetDistribution,
  getAllCalendarDaysForSelectedPacing,
  showNew,
  festivalDays,
  selectedBrand,
}): JSX.Element => {
  const getProgressbarData = () => {
    let colorName = "";
    let backgroundColor = "";
    let newValue = day?.dailyLimitUpdated || day?.value;
    let percentageValue = (day?.actualSpend * 100) / newValue;
    if (percentageValue < 80) {
      colorName = ProgressBarColors.underUse;
      backgroundColor = ProgressBarBackgroundColors.underUse;
    } else if (percentageValue >= 80 && percentageValue <= 120) {
      colorName = ProgressBarColors.exactUse;
      backgroundColor = ProgressBarBackgroundColors.exactUse;
    } else if (percentageValue > 120) {
      colorName = ProgressBarColors.overUse;
      backgroundColor = ProgressBarBackgroundColors.overUse;
    }
    return { colorName, percentageValue, backgroundColor };
  };

  const getPacingColor = () => {
    let colorName = "";
    if (day) {
      if (day.status === "COMPLETED") {
        colorName = PacingsStatusColors.COMPLETED;
      } else if (day.status === "ACTIVE") {
        colorName = PacingsStatusColors.ACTIVE;
      } else if (day.status === "SCHEDULE") {
        colorName = PacingsStatusColors.SCHEDULE;
      } else if (day.status === "UNCONFIGURED") {
        colorName = PacingsStatusColors.UNCONFIGURED;
      } else {
        colorName = "tranparent";
      }
    }
    return colorName;
  };

  return (
    <div
      className="calendar-cell"
      style={{
        display: "inline-block",
        padding: "10px 0",
        position: "relative",
        width: "130px",
        maxWidth: "130px",
        visibility: day ? "visible" : "hidden",
      }}
      onClick={() => {
        if (
          selectedPacing?.id &&
          day?.id &&
          selectedPacing.id === day.id &&
          day._status != "LOCKED" &&
          day.status != "UNCONFIGURED" &&
          day.status != "ABORTED"
        ) {
          selectDate(weekIndex, dayIndex);
        }
      }}
    >
      {day ? (
        <>
          <div
            style={{
              fontSize: "10px",
              position: "absolute",
              top: "40px",
              right: "10px",
              border: "1.22159px solid #F0F0F0",
              borderRadius: "50%",

              width: "1rem",
              height: "1rem",
              textAlign: "center",
              borderColor:
                day.fullDateString ===
                moment(day?.toDateString).format("YYYY-MM-DD")
                  ? "#0869FB"
                  : "#F0F0F0",
              backgroundColor:
                day.fullDateString ===
                moment(day?.toDateString).format("YYYY-MM-DD")
                  ? "#E6F0FF"
                  : "transparent",
              color:
                day.fullDateString ===
                moment(day?.toDateString).format("YYYY-MM-DD")
                  ? "#0869FB"
                  : "#333333",
            }}
          >
            {day.day}
          </div>
          <div
            className={`${day._status === "LOCKED" ? "visible" : "invisible"}`}
            style={{
              position: "absolute",
              top: "38px",
              left: "15px",
              cursor: "pointer",
              zIndex: "9",
            }}
            onClick={(e) => {
              if (
                selectedPacing?.id === day.id &&
                !checkIsBeforeToday(
                  moment(day.fullDateString),
                  moment(day?.toDateString)
                )
              ) {
                e.preventDefault();
                e.stopPropagation();
                handleUnlockBuchgetDistribution(weekIndex, dayIndex);
              }
            }}
          >
            <LockedIcon />
          </div>

          <div
            className={`${day.startDate === day.date ? "ml-2" : ""} ${
              day.endDate === day.date ? "mr-2" : ""
            }`}
            style={{
              background: day?.name ? getPacingColor() : "transparent",
              height: "1rem",
              color: "white",
              textAlign: "left",
              paddingLeft: "1em",
              paddingRight: "1em",
              fontSize: "0.75em",
              lineHeight: "0.875em",
              fontWeight: "700",
              borderTopLeftRadius: day.startDate === day.date ? "0.7em" : "0",
              borderBottomLeftRadius:
                day.startDate === day.date ? "0.7em" : "0",
              display: "flex",
              alignItems: "center",
              borderTopRightRadius: day.endDate === day.date ? "0.7em" : "0",
              borderBottomRightRadius: day.endDate === day.date ? "0.7em" : "0",
              opacity: day?.id === selectedPacing?.id ? "1" : "0.5",
            }}
          >
            <Tooltip
              title={<div style={{ position: "relative" }}>{day?.name}</div>}
            >
              <div>
                {isPacingNameShow
                  ? day?.name?.length > 11
                    ? day.name.substring(0, 11) + "..."
                    : day.name
                  : ""}
              </div>
            </Tooltip>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "1em",
          }}
        ></div>
      )}
      <div
        style={{
          border: day?.isSelected
            ? day.status === "COMPLETED"
              ? "1.83238px grey"
              : day.status === "ACTIVE"
              ? "1.83238px solid #28B49B"
              : "1.83238px solid #FABF28"
            : "1.83238px solid #F0F0F0",
          borderRadius: "9.7727px",
          background: day?.isSelected ? "#FFF" : "#FAFAFA",
          width: "110px",
          maxWidth: "110px",
          overflow: "hidden",
          margin: "10px auto 0",
        }}
      >
        <div
          style={{
            height: "80px",
            marginBottom: "7px",
          }}
        >
          <div
            className={`holiday-tag text-center invisible`}
            style={{
              opacity: festivalDays[day?.fullDateString] ? "1" : "0",
            }}
            title={festivalDays[day?.fullDateString]}
          >
            {festivalDays[day?.fullDateString] || "NA"}
          </div>
          {day &&
          day.id &&
          day.id === selectedPacing?.id &&
          ((selectedPacing?.pacingType === "DAILY" && day.isSelected) ||
            (selectedPacing?.pacingType !== "DAILY" && selectedMode)) ? (
            <div
              className="align-middle mx-1"
              style={{
                fontSize: "0.8em",
                fontWeight: "700",
                lineHeight: "1.1em",
                marginTop: "0.8em",
              }}
            >
              <span className="currency-prefix">
                {selectedBrand?.currencySymbol}
              </span>
              <input
                type="number"
                disabled={!day?.isSelected}
                className="editable-amount form-control"
                name="dailyLimit"
                value={
                  day?.isSelected
                    ? getDecimalValues(day?.dailyLimitUpdated)
                    : getDecimalValues(day?.value)
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  updateCalendarDay(
                    weekIndex,
                    dayIndex,
                    "dailyLimitUpdated",
                    parseFloat(e.target.value)
                  );
                  calDifference(parseFloat(e.target.value), day);
                }}
              />
            </div>
          ) : (
            <div
              className="amount align-middle mx-1 flex-center"
              style={{
                fontSize: "0.8em",
                fontWeight: "700",
                lineHeight: "1.1em",
                marginTop: "0.8em",
                color:
                  day &&
                  day.id &&
                  day.status != "UNCONFIGURED" &&
                  day.status != "ABORTED"
                    ? (day?.value !== day?.dailyLimitUpdated ||
                        day?.old_value) &&
                      day?.isSelected == false &&
                      day?._status !== "LOCKED" &&
                      showNew == true
                      ? "#0869FB"
                      : "#000000"
                    : "#DDDDDD",
              }}
            >
              {selectedBrand?.currencySymbol}
              {day &&
              day.id &&
              day.status != "UNCONFIGURED" &&
              day.status != "ABORTED"
                ? day?.dailyLimitUpdated !== day?.value
                  ? getDecimalValues(day?.dailyLimitUpdated)
                  : getDecimalValues(day?.value)
                : "-"}
              <Tooltip
                title={
                  <div style={{ position: "relative" }}>
                    The actual spends may vary from the linearly distributed
                    budget maximum by +25%
                    <p
                      style={{
                        height: "2px",
                        marginBottom: "0px",
                        color: "white",
                        position: "absolute",
                        left: "38%",
                        top: "70%",
                      }}
                    >
                      -
                    </p>
                  </div>
                }
                arrow
                className={`${day?.value ? "visible" : "invisible"}`}
              >
                <IconButton
                  style={{
                    padding: "0.2rem",
                    display:
                      (day?.value !== day?.dailyLimitUpdated ||
                        day?.old_value) &&
                      day?.isSelected == false &&
                      day?._status !== "LOCKED" &&
                      showNew == true
                        ? "none"
                        : day?.status === "UNCONFIGURED" ||
                          day?.status === "ABORTED"
                        ? "none"
                        : "block",
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
              <div
                style={{
                  display:
                    (day?.value !== day?.dailyLimitUpdated || day?.old_value) &&
                    day?.isSelected == false &&
                    day?._status !== "LOCKED" &&
                    showNew == true
                      ? "block"
                      : "none",
                  color: "#0869FB",
                  fontSize: "0.5rem",
                  marginLeft: "0.25rem",
                }}
              >
                New
              </div>
            </div>
          )}

          <div
            className={`mx-2 ${
              day?.value !== day?.dailyLimitUpdated ||
              day?.old_value !== undefined
                ? "visible"
                : "invisible"
            }`}
            style={{
              fontSize: "9px",
              lineHeight: "1em",
              fontWeight: "500",
              color: "#999999",
              textDecorationLine: "line-through",
              textDecorationStyle: "solid",
              marginBottom: "0.5em",
            }}
          >
            {/* {day?.value !== day?.dailyLimitUpdated ||
              (day?.old_value !== day?.value && */}
            {selectedBrand?.currencySymbol +
              getDecimalValues(day?.old_value || day?.value)}
          </div>
          <BudgetPlannerProgressbar
            colors={
              day && day?.actualSpend
                ? getProgressbarData().colorName
                : ProgressBarColors.disabled
            }
            value={day?.actualSpend}
            day={day}
            percentageValue={getProgressbarData().percentageValue}
            backgroundColor={getProgressbarData().backgroundColor}
            selectedBrand={selectedBrand}
          />
        </div>
      </div>
    </div>
  );
};
