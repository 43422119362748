// @ts-nocheck
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Accordion } from "react-bootstrap";
import AdAccountAccordion from "./AdAccountAccordion/AdAccountAccordion";
import AIGroupSettingForm from "./AIGroupSettingForm/AIGroupSettingForm";
import { useDispatch, useSelector } from "react-redux";
import { AdAccountState } from "../../store/types/adaccount";
import {
  getFBAdsLink,
  getPlatformName,
  isViewerRole,
  isEditorRole,
  getRolesHierarchy,
} from "../../utils/commonFunctions";
import { initialState } from "../../store/types/auth";
import {
  getOptimizerDetails,
  getResultMetricsData,
  getStoryData,
  postStoryData,
  putStoryData,
  setSpinner,
  setSuccess,
} from "../../store/actions/campaign";
import { CampaignState } from "../../store/types/campaign";
import { mapStoryPayload, mapStoryResponse } from "../../utils/mapFactors";
import "./style.scss";
import Loader from "./../common/Loader";
import NoData from "../../components/common/NoData";
import {
  getAdsetDetails,
  getCampaignDetails,
  getAdAccountDetails,
  setSelectedGroup,
} from "../../store/actions/adaccount";
import { mixPanelAdEvent } from "../../utils/mixpanel";
import Grid from "@mui/material/Grid";
import BudgetConstraints from "./../../Pages/BudgetPlanner/sections/BudgetConstraints/BudgetConstraints";
import { BudgetPacing } from "./../../Pages/BudgetPlanner/sections/BudgetPacing/BudgetPacing";
import Box from "@mui/system/Box";
import CampaignAdsetListing from "./CampaignAdsetListing";
import { getGroupConfig } from "../../store/actions/BudgetPlannerAiGroup";
import { LightTooltip } from "components/CreativeAI_Revamp/shared";
import { brandViewerAccessText } from "utils/constants/commonConsts";
import { ReactComponent as WarningIcon } from "./../../assets/svg/warning-icon.svg";
import { RolesWrapper } from "utils/rolesWrapper";

const initialStoryData = {
  target_cpr: "",
  target_result: "",
  result_metric_key: "-1",
  is_attribuation_metric: false,
};

const AIGroupSettings: React.FC<unknown> = () => {
  const target = useRef(null);
  const dispatch = useDispatch();
  const [activeAdAccount, setActiveAdAccount] = React.useState([]);
  const [adAccountsList, setAdAccountsList] = React.useState({
    facebook: [],
    google: [],
  });
  const [adsets, setAdsets] = React.useState([]);
  const [campaigns, setCampaigns] = React.useState([]);
  const [currency, setCurrency] = React.useState({});
  const adAccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const {
    groups,
    selectedGroup,
    selectedGroupAdaccounts,
    platformId,
    adaccountLoading,
    platforms,
    selectedBrand,
    brandRoles,
  } = adAccount;
  const campaign = useSelector(
    (state: { campaign: CampaignState }) => state.campaign
  );
  const commonState: any = useSelector((state: any) => state.smb.common);
  const { metricList, success } = campaign;

  const { data } = useSelector(
    (state: { BudgetPlannerAiGroup: any }) => state.BudgetPlannerAiGroup
  );
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const [storyData, setStoryData] = React.useState({});
  const [disableBudgetPlanner, setDisableBudgetPlanner] = React.useState(false);
  const { user } = authState;
  const [isEdit, setisEdit] = useState(false);
  const [disableOptimize, setDisableOptimize] = useState(false);
  const { resultMetrics } = useSelector((state: any) => state.campaign);
  // const isBrandViewer = useMemo(
  //   () => brandRoles?.length === 1 && brandRoles[0]?.role === "brand-viewer",
  //   [brandRoles]
  // );
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    mixPanelAdEvent("Page View - Dashboard", {
      module_name: "AI Settings",
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
    });
  }, []);

  useEffect(() => {
    setActiveAdAccount([]);
    setStoryData(initialStoryData);
  }, []);

  useEffect(() => {
    if (selectedGroupAdaccounts.length > 0) {
      const adsList: any = {
        google: [],
        facebook: [],
      };
      selectedGroupAdaccounts.map((adAcc: any) => {
        if (adAcc.platformId === platformId.googleSearchId) {
          adsList.google.push(adAcc);
        }
        if (adAcc.platformId === platformId.facebookId) {
          adsList.facebook.push(adAcc);
        }
      });
      setAdAccountsList(adsList);
    } else {
      setAdAccountsList({
        google: [],
        facebook: [],
      });
    }
  }, [selectedGroupAdaccounts]);

  useEffect(() => {
    if (success) {
      alert("The data has been saved");
      dispatch(setSuccess(false));
    }
  }, [success]);

  const validateStory = (elementId: any) => {
    if (
      !activeAdAccount[elementId]?.target_cpr ||
      activeAdAccount[elementId]?.target_cpr === ""
    ) {
      alert("Target Cost Per Optimisation Event can not be empty");
      return false;
    } else if (
      !activeAdAccount[elementId]?.target_result ||
      activeAdAccount[elementId]?.target_result === ""
    ) {
      alert("Target Daily Results(Optimisation Event) can not be empty");
      return false;
    } else if (
      !activeAdAccount[elementId]?.result_metric_key ||
      activeAdAccount[elementId]?.result_metric_key === ""
    ) {
      alert("Optimisation Event can not be empty");
      return false;
    }
    return true;
  };

  const submitResultStory = (
    elementId: any,
    isFactor: boolean,
    params: any
  ) => {
    const isValid = validateStory(elementId);
    if (isValid && activeAdAccount[elementId]) {
      dispatch(setSpinner(true));
      if (storyData.id) {
        dispatch(
          putStoryData(
            {
              elementId,
              user,
              payload: mapStoryPayload(activeAdAccount, elementId),
            },
            (response: any, error: boolean) => {
              if (!error) {
                checkAIGroupConfig();
                dispatch(setSuccess(true));
              } else {
                dispatch(setSuccess(false));
                alert(response.data.message);
              }
            }
          )
        );
      } else {
        dispatch(
          postStoryData(
            {
              elementId,
              user,
              payload: mapStoryPayload(activeAdAccount, elementId),
            },
            (response: any, error: boolean) => {
              if (!error) {
                checkAIGroupConfig();
                dispatch(setSuccess(true));
                setStoryData(mapStoryResponse(response));
              } else {
                dispatch(setSuccess(false));
                alert(response.data.message);
              }
            }
          )
        );
      }
      mixPanelAdEvent("AI Group Settings Updated", {
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        agency_id: selectedBrand?.agencyId,
        agency_name: selectedBrand?.agency_name,
        ai_group_id: selectedGroup?.id,
        ai_group_name: selectedGroup?.name,
        // minimum_budget: activeAdAccount[elementId]?.cross_budget_min,
        // maximum_budget: activeAdAccount[elementId]?.cross_budget_max,
        optimization_events: activeAdAccount[elementId]?.result_metric_key,
        target_cost_per_optimization_event:
          activeAdAccount[elementId]?.target_cpr,
        target_daily_results: activeAdAccount[elementId]?.target_result,
        metric_weight_array_roi: isFactor ? ROIMetrics : "",
        metric_weight_array_quality_factor: isFactor ? QFMetrics : "",
        roi_quality_weight_distribution_bar: isFactor
          ? { roi: ROIFactor, quality: 100 - ROIFactor }
          : "",
        ad_set_ids: params.ad_set_ids,
        ad_account_id: params.ad_account_id,
        ad_account_name: params.ad_account_name,
        facebook_google: isFactor ? "Google" : "Facebook",
        campaign_ids: params.campaign_ids,
      });
    }
  };

  const handleOpenForSmb = (group: any) => {
    if (commonState.selectedOptimizerToRedirect) {
      if (commonState.selectedOptimizerToRedirect.openBudgetPlanner) {
        if (!group.parentAiGroupId && group.optimisationSettingExist) {
          document.getElementById("budget-planner-group")?.click();
        }
      } else {
        handleOpen(
          {
            adAccountId: commonState.selectedOptimizerToRedirect?.adAccountId,
            platformId:
              commonState.selectedOptimizerToRedirect?.platform?.platformId,
            platformType:
              commonState.selectedOptimizerToRedirect?.platform?.platformType,
          },
          commonState.selectedOptimizerToRedirect?.optimizerId
        );
      }
    }
    return;
  };

  const handleOpen = (params: any, elementId: string, callback: any) => {
    dispatch(setSpinner(true));
    dispatch(
      getAdAccountDetails(
        {
          adaccountId: params.adAccountId,
          platformType: params.platformType.toLowerCase(),
        },
        (response: any, error: boolean) => {
          if (!error && response.data && response.data.currency) {
            setCurrency({ ...currency, [elementId]: response.data.currency });
          } else {
            setCurrency({ ...currency, [elementId]: "" });
          }
        }
      )
    );

    // Profitability Index Metric Fetching API
    // dispatch(
    //   getMetricsList(
    //     { elementId: elementId, user },
    //     (response: any, error: boolean) => {
    //       dispatch(setSpinner(false));
    //       if (!error) {
    //         dispatch(setMetricList(response.data));
    //       } else {
    //         dispatch(setMetricList([]));
    //       }
    //     }
    //   )
    // );

    //for result metrics
    dispatch(getResultMetricsData({ adAccountId: params.adAccountId, user }));
    //for result metrics

    // Profitability Index Factor Fetchiing API
    // dispatch(setSpinner(true));
    // dispatch(
    //   getCampaignsFactors(
    //     { elementId: elementId, user },
    //     (response: any, error: boolean) => {
    //       dispatch(setSpinner(false));
    //       if (!error) {
    //         dispatch(setCampaignsFactors(response));
    //       } else {
    //         dispatch(setCampaignsFactors({}));
    //       }
    //     }
    //   )
    // );
    dispatch(
      getStoryData({ elementId, user }, (response: any, error: boolean) => {
        if (!error) {
          setActiveAdAccount({
            ...activeAdAccount,
            [elementId]: mapStoryResponse(response),
          });
          setStoryData(mapStoryResponse(response));
        } else {
          setActiveAdAccount({ ...activeAdAccount });
          setStoryData(initialStoryData);
        }
      })
    );
    if (
      params.platformType === "Facebook" ||
      params.platformType.toLowerCase() === "dv360" ||
      params.platformType.toLowerCase() === "pinterest"
    ) {
      dispatch(
        getAdsetDetails(
          {
            group_id: commonState.selectedOptimizerToRedirect?.groupId
              ? commonState.selectedOptimizerToRedirect?.groupId
              : selectedGroup?.id,
            user: user,
          },
          (adsetRes: any, adsetErr: boolean) => {
            if (!adsetErr && adsetRes.data.length > 0) {
              const newAdSets = adsetRes.data.filter(
                (adset: any) => adset.adAccountId === params.adAccountId
              );
              setAdsets({ ...adsets, [elementId]: newAdSets });
            } else {
              setAdsets([]);
            }
          }
        )
      );
    } else {
      dispatch(
        getCampaignDetails(
          {
            group_id: commonState.selectedOptimizerToRedirect?.groupId
              ? commonState.selectedOptimizerToRedirect?.groupId
              : selectedGroup?.id,
            user: user,
          },
          (campRes: any, campErr: boolean) => {
            if (!campErr && campRes.data.length > 0) {
              const filteredData: any = campRes.data.filter(
                (campaign: any) =>
                  campaign.platformId === params.platformId &&
                  campaign.adAccountId === params.adAccountId
              );
              setCampaigns({ ...campaigns, [elementId]: filteredData });
            } else {
              setCampaigns([]);
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    if (commonState?.selectedOptimizerToRedirect && groups.length) {
      const group: any =
        groups.find(
          (g: any) => g.id === commonState.selectedOptimizerToRedirect?.groupId
        ) || groups[0];
      dispatch(setSelectedGroup(group));
      handleOpenForSmb(group);
    }
  }, [commonState, groups]);

  const aiGroupPlatforms1 = useMemo(() => {
    const data = platforms.sort((a: any, b: any) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name == b.name) {
        return 0;
      }
    });
    const capitalize = function (type: string) {
      const arr = type.split(" ");
      arr.forEach(
        (word, index) =>
          (arr[index] = word.charAt(0) + word.slice(1).toLowerCase())
      );
      return arr.join(" ");
    };

    data.forEach((platform: any, index: number) => {
      data[index].type = capitalize(platform.type);
    });
    return data;
  }, [platforms]);

  const handleChanage = (
    key: string,
    value: any,
    elementId: string,
    adAccountId: string
  ) => {
    setActiveAdAccount({
      ...activeAdAccount,
      [elementId]: {
        ...activeAdAccount[elementId],
        [key]: value,
        is_attribuation_metric:
          key === "result_metric_key"
            ? findIsMetricAttribution(value, resultMetrics[adAccountId])
            : activeAdAccount[elementId]?.is_attribuation_metric,
      },
    });
  };
  const handleAdsView = useCallback(
    (id: string, elementId: any) => {
      window.open(
        getFBAdsLink(
          id,
          adsets[elementId].map((item) => item.adsetId)
        )
      );
    },
    [adsets]
  );

  useEffect(() => {
    if (selectedGroup?.id) {
      setIsAccordionOpen(false);
      setDisableBudgetPlanner(
        selectedGroup.optimisationSettingExist ? false : true
      );
      setDisableOptimize(selectedGroup?.optimizerMode === "NUM");
    } else {
      setDisableBudgetPlanner(true);
    }
  }, [selectedGroup]);

  const checkAIGroupConfig = () => {
    dispatch(
      getGroupConfig(
        {
          aiGroupId: selectedGroup?.id,
        },
        (response: any, error: boolean) => {
          if (!error) {
            setDisableBudgetPlanner(!response.data);
          }
        }
      )
    );
  };

  useEffect(() => {
    if (
      data?.selectedPlannerId &&
      data.AiGroups?.[data.selectedAiGroup]?.planners?.[data.selectedPlannerId]
        ?.status === "SCHEDULE"
    ) {
      setisEdit(true);
    } else {
      setisEdit(false);
    }
  }, [data]);

  const findIsMetricAttribution = (metricId: string, metricList: any) => {
    if (metricList.length && metricId) {
      return metricList.find((each) => each.metricKey === metricId)
        ?.isAttribuationMetric;
    }
  };

  return (
    <>
      <div className="bg-white rounded d-flex flex-column box-shadow">
        <Accordion key={selectedGroup?.id}>
          <div>
            <AdAccountAccordion
              data={{}}
              isAdvance={true}
              index={1}
              eventKey={1}
              onOpen={(callback: any) => {
                setIsAccordionOpen(true);
              }}
              disableBudgetPlanner={disableBudgetPlanner}
              selectedGroup={selectedGroup}
              handleClose={() => {
                setTimeout(() => {
                  setIsAccordionOpen(false);
                }, 0);
              }}
            >
              <Box
                sx={{
                  height: "85vh",
                  background: "white",
                  fontFamily: "Inter",
                  borderRadius: "0.625em",
                  "& *": {
                    fontFamily: "Inter",
                  },
                }}
              >
                <Grid container sx={{ height: "100%" }}>
                  <Grid item xs={4} md={4} sx={{ height: "100%", zIndex: 45 }}>
                    <BudgetPacing type={"group"} />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    md={8}
                    sx={{ height: "100%" }}
                    position={"relative"}
                  >
                    <BudgetConstraints
                      isEdit={isEdit}
                      type={"group"}
                      isAccordionOpen={isAccordionOpen}
                    />
                  </Grid>
                </Grid>
              </Box>
            </AdAccountAccordion>
          </div>
        </Accordion>
      </div>
      <div className="mt-2 bg-white rounded d-flex flex-column box-shadow">
        <div className="border-bottom">
          <div className="p-3 d-flex">
            <div className="pr-3">Optimization Settings</div>
          </div>
        </div>
        <div className="ai_model_confidence_style">
          {selectedGroupAdaccounts.length > 0 ? (
            (aiGroupPlatforms1 || []).map(
              (platform) =>
                platform?.adaccountDetail?.length > 0 && (
                  <>
                    <h4 className="p-0 pt-4 mb-4 ml-4 campaigns_header fs-6 d-flex">
                      {platform.type === 'Facebook Ad' ? 'Meta Ad' : platform.type }
                    </h4>
                    <Accordion
                      key={
                        selectedGroup?.id ||
                        commonState.selectedOptimizerToRedirect?.groupId
                      }
                      className="p-4"
                      defaultActiveKey={
                        commonState.selectedOptimizerToRedirect?.optimizerId
                      }
                    >
                      {(platform.adaccountDetail || []).map((detail, index) => (
                        <div className="mb-2">
                          <AdAccountAccordion
                            data={detail}
                            isAdvance={false}
                            index={index}
                            onOpen={(callback: any) =>
                              handleOpen(
                                {
                                  adAccountId: detail.id,
                                  platformId: detail.platformId,
                                  platformType: getPlatformName(platform.type),
                                },
                                detail.elementId,
                                callback
                              )
                            }
                            platformName={platform.type}
                          >
                            <div className="p-4 pt-0 d-flex flex-column">
                              <div className="formData">
                                <div className="aiGroupSettingsForm">
                                  <div className="storyData">
                                    <AIGroupSettingForm
                                      data={
                                        activeAdAccount[detail.elementId] ||
                                        initialStoryData
                                      }
                                      handleChanage={handleChanage}
                                      adAccountId={detail.id}
                                      elementId={detail.elementId}
                                      metricList={metricList || []}
                                      platform={platform.type}
                                      currency={currency}
                                      platformId={platform.id}
                                      parentPlatformId={
                                        platform.parentPlatformId
                                      }
                                      disableOptimize={disableOptimize}
                                    />
                                  </div>
                                  <div
                                    className="campaign-footer"
                                    style={{
                                      justifyContent: disableOptimize
                                        ? "space-between"
                                        : "flex-end",
                                    }}
                                  >
                                    {disableOptimize && (
                                      <div className="flex-row flex-center">
                                        <WarningIcon className="mx-2 color-fill" />
                                        <div className="mr-2 text-lg color-text">
                                          Note : Your Optimisation strategy has
                                          been Updated
                                        </div>
                                      </div>
                                    )}

                                    {/* <LightTooltip
                                      title={
                                        // isBrandViewer
                                        isViewerRole(brandRoles)
                                          ? brandViewerAccessText
                                          : ""
                                      }
                                      placement="top-end"
                                    > */}
                                    <RolesWrapper
                                      show={true}
                                      disabled={
                                        getRolesHierarchy(brandRoles) ===
                                          "viewer" ||
                                        getRolesHierarchy(brandRoles) ===
                                          "editor"
                                      }
                                      // disabled={
                                      //   isViewerRole(brandRoles) ||
                                      //   isEditorRole(brandRoles)
                                      // }
                                    >
                                      <button
                                        className="btn-apply"
                                        style={{
                                          cursor:
                                            // isBrandViewer ||
                                            disableOptimize
                                              ? // isViewerRole(brandRoles) ||
                                                // isEditorRole(brandRoles)
                                                "not-allowed"
                                              : "pointer",
                                          opacity: disableOptimize
                                            ? "0.5"
                                            : "1",
                                        }}
                                        onClick={() => {
                                          if (
                                            // (!isBrandViewer &&
                                            //   !disableOptimize) ||
                                            !disableOptimize
                                            // ||
                                            // !isViewerRole(brandRoles) ||
                                            // !isEditorRole(brandRoles)
                                          ) {
                                            submitResultStory(
                                              detail.elementId,
                                              platform.type.includes("Google")
                                                ? true
                                                : false,
                                              {
                                                ad_account_id: detail.id,
                                                ad_account_name: detail.name,
                                                ad_set_ids: (
                                                  adsets[detail.elementId] || []
                                                ).map(
                                                  (adset: any) => adset.adsetId
                                                ),
                                                campaign_ids: (
                                                  campaigns[detail.elementId] ||
                                                  []
                                                ).map(
                                                  (camp: any) => camp.campaignId
                                                ),
                                              }
                                            );
                                          }
                                        }}
                                      >
                                        Apply
                                      </button>
                                    </RolesWrapper>
                                    {/* </LightTooltip> */}
                                  </div>{" "}
                                </div>

                                {/* {platform.type === "Google Search Ad" && (
                                  <ProfitabilityIndex
                                    platform={platform}
                                    detail={detail}
                                  />
                                )} */}
                                <CampaignAdsetListing
                                  handleAdsView={handleAdsView}
                                  platform={platform}
                                  campaigns={campaigns ? campaigns : []}
                                  adsets={adsets ? adsets : []}
                                  detail={detail}
                                />
                              </div>
                            </div>
                          </AdAccountAccordion>
                        </div>
                      ))}
                    </Accordion>
                  </>
                )
            )
          ) : adaccountLoading ? (
            <Loader />
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </>
  );
};

export default AIGroupSettings;
