import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F7F7F7",
  ...theme.typography.body2,
  textAlign: "center",
  boxShadow: "none",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  background: "#F7F7F7",
  padding: "5px",
  margin: "5px 0",
}));

function PlatformDetails({ icon, shortName, name, active_models }: any) {
  return (
    <Item
      sx={{
        "&:not(style)+:not(style)": {
          marginLeft: 0,
        },
        "& svg": {
          height: "2rem",
          width: "2rem",
        },
      }}
    >
      <Box>{icon}</Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "5px",
          textAlign: "left",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            variant="caption"
            sx={{
              color: "#333333",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "5rem",
            }}
          >
            {name}
          </Typography>
          {shortName && shortName !== "" && (
            <Chip
              sx={{
                fontSize: "8px",
                p: 0,
                borderRadius: "2px",
                height: "fit-content",
                py: 0.5,
                background: "#E0E0E0 !important",
                fontWeight: 400,
                ml: 1,
              }}
              label={shortName}
            />
          )}
        </Box>
        <Typography variant="caption">
          <span
            style={{ color: "#0869FB", marginRight: "3px", fontWeight: "700" }}
          >
            {active_models}
          </span>
          Active models
        </Typography>
      </Box>
    </Item>
  );
}

export default PlatformDetails;
