/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import { endOfDay, startOfMonth, startOfWeek } from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { defaultStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch, useSelector } from "react-redux";
import { setCPRDetails } from "../store/actions/common";
import { ActionImpactState, LinkClicks } from "../store/types/actionImpact";
import { getDateRange } from "../utils/commonFunctions";
import { useOnClickOutside } from "./onClickOutside";
function getSelectedRange(defaultSelection: {
  start: string | number | Date;
  end: string | number | Date;
}) {
  return defaultSelection
    ? {
        startDate: endOfDay(new Date(defaultSelection.start)),
        endDate: endOfDay(new Date(defaultSelection.end)),
        key: "selection",
      }
    : { startDate: endOfDay(new Date()), endDate: endOfDay(new Date()), key: "selection" };
}

function getStaticRanges() {
  const staticRanges = cloneDeep(defaultStaticRanges);
  const thisMonthIndex = staticRanges.findIndex((range) => range.label === "This Month");
  const thisWeekIndex = staticRanges.findIndex((range) => range.label === "This Week");

  staticRanges[thisMonthIndex].range = () => ({
    startDate: endOfDay(startOfMonth(new Date())),
    endDate: endOfDay(new Date()),
  });

  staticRanges[thisWeekIndex].range = () => ({
    startDate: endOfDay(startOfWeek(new Date())),
    endDate: endOfDay(new Date()),
  });

  return staticRanges;
}
type DateRangeFilterProps = {
  defaultSelection: LinkClicks | null;
  callback: (data: LinkClicks) => void;
};

function DateRangeFilter({ defaultSelection, callback }: DateRangeFilterProps) {
  const actionImpact = useSelector((state: { actionImpact: ActionImpactState }) => state.actionImpact);
  const { linkClicks } = actionImpact;
  const ref = useRef();
  const dispatch = useDispatch();
  const [showCalendar, setShowCalendar] = useState(false);
  const [tempSelection, setTempSelection] = useState([
    { startDate: linkClicks.start, endDate: linkClicks.end, key: "selection" },
  ]);
  const [daterange, setRange] = useState("last7");
  const handleSelect = (ranges: any) => {
    setTempSelection([ranges.selection]);
  };

  const handleClose = useCallback(() => setShowCalendar(false), []);
  useOnClickOutside(ref, handleClose);

  useEffect(() => {
    if (showCalendar) {
      const defaultSelectedRange = getSelectedRange(defaultSelection as LinkClicks);
      if (!isEqual(tempSelection[0], defaultSelectedRange)) {
        setTempSelection([defaultSelectedRange]);
      }
    }
  }, [showCalendar]);

  const setDateRange = (value: string) => {
    dispatch(setCPRDetails(null));
    callback(getDateRange(value));
    setRange(value);
    // callback({
    //   start: tempSelection[0].startDate,
    //   end: tempSelection[0].endDate,
    // });
    // setShowCalendar(false);
  };

  return (
    <div className="d-flex date-range-btn">
      <button
        className={
          daterange === "last7" ? "performance_button active_button" : "performance_button"
        }
        onClick={() => {
          if (daterange !== "last7") {
            setDateRange("last7");
          }
        }}
      >
        Last 7 Days
      </button>
      <button
        className={
          daterange === "last14" ? "performance_button active_button" : "performance_button"
        }
        onClick={() => {
          if (daterange !== "last14") {
            setDateRange("last14");
          }
        }}
      >
        Last 14 Days
      </button>
    </div>
    // <Dropdown ref={ref as any} show={showCalendar} className="position-relative">
    //   <Dropdown.Toggle variant="default">
    //     <div
    //       onClick={() => setShowCalendar(!showCalendar)}
    //       className="calenderbox mr-4 d-flex align-items-center"
    //     >
    //       <FontAwesomeIcon icon={faCalendarAlt} />
    //       <span className="font-semibold ml-3 mr-3">
    //         {moment(linkClicks.start).format('MMM DD, YYYY')}
    //         {' '}
    //         -
    //         {' '}
    //         {moment(linkClicks.end).format('MMM DD YYYY')}
    //       </span>
    //       <span>
    //         <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    //           <path d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z" fill="black" />
    //         </svg>
    //       </span>

    //     </div>
    //   </Dropdown.Toggle>
    //   <Dropdown.Menu>
    //     <Dropdown.Item>
    //       <div className="publish-calendarWrapper">
    //         <DateRangePicker
    //           className="hide-futureSelection"
    //           onChange={handleSelect}
    //           showSelectionPreview
    //           moveRangeOnFirstSelection={false}
    //           months={2}
    //           ranges={tempSelection}
    //           direction="horizontal"
    //           maxDate={endOfDay(new Date())}
    //           staticRanges={getStaticRanges()}
    //         />
    //         <div className="rangeButtons">
    //           <button className="performance_button" onClick={() => setShowCalendar(false)}>
    //             Cancel
    //           </button>
    //           <button className="performance_button" onClick={setDateRange}>
    //             Set
    //           </button>
    //         </div>
    //       </div>
    //     </Dropdown.Item>
    //   </Dropdown.Menu>
    // </Dropdown>
  );
}
export default DateRangeFilter;
