import { Box } from "@mui/material";
import styled from "styled-components";
export const StyledMetricInfo = styled(Box)`
  position: relative;
  padding: 1em;
  width: 12.895625em;
  border-radius: 0.625em;
  padding: 1em;
  background: #ffffff;
`;

export const StyledContent = styled.div<{ top: any; right: any }>`
  display: none;
  position: absolute;
  max-width: 400px;
  background-size: cover;
  color: #333333;
  padding: 22px;
  z-index: 2;
  top: ${(props) => (props.top ? props.top : "10px")};
  right: ${(props) => (props.right ? props.right : "0px")};
  min-width: 400px;
  background-color: white;
  font-size: 12px;
  box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.25em;
`;

export const StyledToolTipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30px;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  z-index: 1;
  &:hover ${StyledContent} {
    display: block;
  }
`;

export const StyledErrorMsgContainer = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.215em;
  color: #ef5854;
`;
