import React, { useState } from "react";
import CustomizedTable from "../../../shared/Table/CustomizedTable";
import {
  StyledPlainTableCell,
  StyledPlainTableRow,
} from "../../../shared/Table/styled";
import { StyledAdVariantListButton } from "../../Facebook/CreateVariants/AdPreview/styled";
import { ReactComponent as EditIcon } from "../../../../assets/svg/buEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/svg/buDeleteIcon.svg";
import { StyledOverflowedText } from "./styled";
import { ConfirmDialog } from "../../../shared";
import { LightTooltip } from "../../../shared/LightTooltip";
import { mediaStudioTableHead } from "./mediaStudioConstFile";
import { useSelector } from "react-redux";
import { MediaStudioAdminState } from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import MediaStudioPlaceHolder from "./MediaStudioPlaceHolder";

//need to remove once we get actual data

const dummyMediaStudioData = [
  {
    blenderFile: "Blender File 1",
    layerName: "Layer Name 1",
    originalText: "Elit laborum sunt mollit ",
    minMaxChar: "12-30",
  },
  {
    blenderFile: "Blender File 1",
    layerName: "Layer Name 1",
    originalText:
      "Elit laborum sunt mollit qui anim. Quis do qui occaecat Lorem cillum laborum. Sint quis et officia ut esse reprehenderit irure. Laboris Lorem est adipisicing eu. Ex deserunt exercitation minim cillum proident consectetur pariatur quis do aliquip non dolore.",
    minMaxChar: "12-30",
  },
  {
    blenderFile: "Blender File 1",
    layerName: "Layer Name 1",
    originalText:
      "Elit laborum sunt mollit qui anim. Quis do qui occaecat Lorem cillum laborum. Sint quis et officia ut esse reprehenderit irure. Laboris Lorem est adipisicing eu. Ex deserunt exercitation minim cillum proident consectetur pariatur quis do aliquip non dolore.",
    minMaxChar: "12-30",
  },
  {
    blenderFile: "Blender File 1",
    layerName: "Layer Name 1",
    originalText:
      "Elit laborum sunt mollit qui anim. Quis do qui occaecat Lorem cillum laborum. Sint quis et officia ut esse reprehenderit irure. Laboris Lorem est adipisicing eu. Ex deserunt exercitation minim cillum proident consectetur pariatur quis do aliquip non dolore.",
    minMaxChar: "12-30",
  },
];

interface MediaStudioAdminTableProps {
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  selectedMediaStudio: string;
  setSelectedMediaStudio: (id: string) => void;
}

const MediaStudioAdminTable = ({
  onEdit,
  onDelete,
  selectedMediaStudio,
  setSelectedMediaStudio,
}: MediaStudioAdminTableProps) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const { mediaList } = useSelector(
    (state: { mediaStudioAdminReducer: MediaStudioAdminState }) =>
      state.mediaStudioAdminReducer
  );
  const onClose = () => {
    setShowDeleteAlert(false);
  };
  return (
    <React.Fragment>
      <CustomizedTable
        tableHeadValues={mediaStudioTableHead}
        tableWidth="100%"
        height="100%"
        tableType="plainTable"
      >
        {mediaList?.length === 0 ? (
          <StyledPlainTableRow>
            <StyledPlainTableCell align="center" colSpan={7} width="90%">
              <MediaStudioPlaceHolder />
            </StyledPlainTableCell>
          </StyledPlainTableRow>
        ) : (
          mediaList?.map((item, index: number) => {
            return (
              <StyledPlainTableRow key={index}>
                <StyledPlainTableCell component="th" scope="row" align="left">
                  {index + 1}
                </StyledPlainTableCell>
                <StyledPlainTableCell align="left">
                  {item?.tag}
                </StyledPlainTableCell>
                <StyledPlainTableCell align="left">
                  {item?.usageType}
                </StyledPlainTableCell>
                <StyledPlainTableCell align="left" style={{ width: "25%" }}>
                  {/* <LightTooltip title={item.originalText} arrow placement="right">
                  <StyledOverflowedText> */}
                  {item?.framesCount}{" "}
                  {item?.framesCount == 1 ? "Frame" : "Frames"}
                  {/* </StyledOverflowedText>
                </LightTooltip> */}
                </StyledPlainTableCell>
                <StyledPlainTableCell align="left">
                  {item?.layersCount}{" "}
                  {item?.layersCount == 1 ? "Layer" : "Layers"}
                </StyledPlainTableCell>
                <StyledPlainTableCell align="left">
                  <StyledAdVariantListButton
                    className="mr-4"
                    onClick={() => {
                      onEdit(item?.id);
                      setSelectedMediaStudio(item?.id);
                    }}
                  >
                    <EditIcon />
                  </StyledAdVariantListButton>
                  <StyledAdVariantListButton
                    onClick={() => {
                      setShowDeleteAlert(true);
                      setSelectedMediaStudio(item?.id);
                    }}
                  >
                    <DeleteIcon />
                  </StyledAdVariantListButton>
                </StyledPlainTableCell>
              </StyledPlainTableRow>
            );
          })
        )}
      </CustomizedTable>
      <ConfirmDialog
        show={showDeleteAlert}
        onClose={onClose}
        onComplete={() => {
          onDelete(selectedMediaStudio);
          setShowDeleteAlert(false);
        }}
        title="Are you sure you want to delete this blender file?"
        completeButtonText="Yes, Delete"
      />
    </React.Fragment>
  );
};

export default MediaStudioAdminTable;
