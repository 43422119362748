// import { CROSS_PLATFORM_URL } from "utils/path";
import HttpService from "../../HttpService/HttpService";
import { crossPlatform } from "MappedAPI/MappedAPI";
import { takeLatest, call, put } from "redux-saga/effects";
import {
  DeleteToken,
  DELETE_TOKEN_FOR_THIRD_PARTY,
  EnableMasterAccessToAccounts,
  ENABLE_MASTER_ACCESS_TO_ACCOUNTS,
  GenerateAccessAndRefreshToken,
  GENERATE_ACCESS_AND_REFRESH_TOKEN,
  GetClientIdForSpecificPlatform,
  GetConfigDetailsOfThirdParty,
  GetConfigsOfGAAccount,
  GetEligibilityOfAccounts,
  GetPropertiesOfGAAccount,
  GetTokenStatsSpecificForAdAccounts,
  GetTokenStatusBasedOnBrandId,
  GET_CLIENT_ID_FOR_SPECIFIC_PLATFORM,
  GET_CONFIGS_OF_GA_ACCOUNT,
  GET_CONFIG_DETAILS_OF_THIRD_PARTY_ACCOUNT,
  GET_ELIGIBILITY_OF_ACCOUNTS,
  GET_THIRD_PARTY_ACCOUNTS,
  GET_PROPERTIES_OF_GA_ACCOUNT,
  GET_TOKEN_STATS_SPECIFIC_FOR_AD_ACCOUNTS,
  GET_TOKEN_STATUS_BASED_ON_BRAND_ID,
  LinkAdAccountsToBrands,
  LINK_AD_ACCOUNTS_TO_BRANDS,
  UpdateConfigDetailsFromAccountId,
  UpdatePrimaryTokenForThirdParty,
  UPDATE_CONFIG_DETAILS_FROM_ACCOUNT_ID,
  UPDATE_PRIMARY_TOKEN_FOR_THIRD_PARTY,
  GetGSheetsDataSource,
  GET_G_SHEETS_DATA_SOURCE,
  GetGSheetURLValidation,
  GET_G_SHEET_URL_VALIDATION,
  GetGSheetSyncHistory,
  GET_G_SHEET_SYNC_HISTORY,
  GetGSheetTimezone,
  GET_G_SHEET_TIMEZONE,
  GetGResyncData,
  GET_G_SHEET_RESYNC_DATA,
  AddGSheetConfiguration,
  ADD_G_SHEET_CONFIGURATION,
  UpdateGSheetConfiguration,
  UPDATE_G_SHEET_CONFIGURATION,
} from "store/types/thirdPartyChannels";
import { BASE_URL } from "../../utils/path";
import { setError } from "store/actions/thirdPartyChannels";

const CROSS_PLATFORM_URL = BASE_URL;
function* getClientIdForSpecificPlatform(
  action: GetClientIdForSpecificPlatform
): any {
  let {
    payload: { platform, auth },
    callback,
  } = action;

  try {
    const urlParams = `${crossPlatform}/token/oauth/${platform}`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      "",
      { platform, auth }
    );

    if (response.data.success) {
      callback && callback(response.data);
      console.log("LL: response.data", response.data);
    }
  } catch (error: any) {
    console.log("getClientIdForSpecificPlatform ~ error", error);
  }
}

function* getEligibilityOfAccounts(action: GetEligibilityOfAccounts): any {
  let {
    payload: { tokenUID, brandId, platform },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/token/${tokenUID}/third-party-channel-accounts`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      "",
      { brandId, platform }
    );

    if (response.data.success) {
      callback && callback(response.data);
      yield put({
        type: GET_ELIGIBILITY_OF_ACCOUNTS,
        payload: response.data.data,
      });
    }
  } catch (error: any) {
    console.log("getEligibilityOfAccounts ~ error", error);
  }
}

// function* getThirdPartyAccounts(action: any): any {
//   let { payload } = action;
//   try {
//     const urlParams = `${crossPlatform}/brand/${payload.brandId}/third-party-accounts`;
//     const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
//     if (response.data.success) {
//       payload.callback && payload.callback(response.data);
//     }
//   } catch (error: any) {
//     console.log("getTokenStatsSpecificForAdAccounts ~ error", error);
//   }
//   return;
// }

function* getTokenStatsSpecificForAdAccounts(
  action: GetTokenStatsSpecificForAdAccounts
): any {
  let {
    payload: { accountId, auth },
    callback,
  } = action;

  try {
    const urlParams = `${crossPlatform}/token/third-party-channel-accounts/${accountId}/token-stats`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      "",
      { accountId, auth }
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("getTokenStatsSpecificForAdAccounts ~ error", error);
  }
}

function* getTokenStatusBasedOnBrandId(
  action: GetTokenStatusBasedOnBrandId
): any {
  let {
    payload: { brandId },
    callback,
  } = action;

  try {
    const urlParams = `${crossPlatform}/token/brand/${brandId}/token-stats`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      ""
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("getTokenStatusBasedOnBrandId ~ error", error);
  }
}

function* getPropertiesOfGAAccount(action: GetPropertiesOfGAAccount): any {
  let {
    payload: { gaAccountId },
    callback,
  } = action;
  try {
    // const urlParams = `${crossPlatform}/token/brand/${gaAccountId}/token-stats`;
    const urlParams = `${crossPlatform}/third-party-channels/ga/${gaAccountId}/properties`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (response.data.success && callback) {
      callback(response.data);
    }
  } catch (error: any) {
    console.log("getPropertiesOfGAAccount ~ error", error);
  }
}

function* getConfigsOfGAAccount(action: GetConfigsOfGAAccount): any {
  let {
    payload: { gaAccountId, propertyId },
    callback,
  } = action;

  try {
    // const urlParams = `${crossPlatform}/third-party-channels/ga/${gaAccountId}/properties/${propertyId}/views`;
    const urlParams = `${crossPlatform}/third-party-channels/ga/${gaAccountId}/properties/${propertyId}/views`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      "",
      { gaAccountId, propertyId }
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("getConfigsOfGAAccount ~ error", error);
  }
}

function* getConfigDetailsOfThirdPartyAccount(
  action: GetConfigDetailsOfThirdParty
): any {
  let {
    payload: { accountId, brandId, auth },
    callback,
  } = action;

  try {
    const urlParams = `${crossPlatform}/brands/${brandId}/third-party-channel-accounts/${accountId}/configs`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      "",
      { gaAccountId: accountId, brandId, auth }
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("getConfigDetailsOfThirdPartyAccount ~ error", error);
  }
}

function* generateAccessAndRefreshToken(
  action: GenerateAccessAndRefreshToken
): any {
  let {
    payload: { params },
  } = action;

  try {
    const urlParams = `${crossPlatform}/token`;
    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      params
    );

    if (response.data.success) {
      // callback && callback(response.data);
      yield put({ type: "UIDTOKEN", payload: response.data.data });
    }
  } catch (error: any) {
    console.log("generateAccessAndRefreshToken ~ error", error);
  }
}

function* linkAdAccountsToBrands(action: LinkAdAccountsToBrands): any {
  let {
    payload: { params, tokenUID },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/token/${tokenUID}/third-party-channel-accounts`;
    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      params
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("linkAdAccountsToBrands ~ error", error);
  }
}

function* enableMasterAccessToAccounts(
  action: EnableMasterAccessToAccounts
): any {
  let {
    payload: { params, tokenUID },
    callback,
  } = action;

  try {
    const urlParams = `${crossPlatform}/token/${tokenUID}/third-party-channel-accounts/master-access`;
    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      params
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("enableMasterAccessToAccounts ~ error", error);
  }
}

function* updatePrimaryTokenForThirdParty(
  action: UpdatePrimaryTokenForThirdParty
): any {
  let {
    payload: { params, tokenUID, accountId },
    callback,
  } = action;

  try {
    const urlParams = `${crossPlatform}/token/${tokenUID}/third-party-channel-accounts/${accountId}/primary`;
    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      params
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("updatePrimaryToken ~ error", error);
  }
}

function* updateConfigDetailsFromAccountId(
  action: UpdateConfigDetailsFromAccountId
): any {
  let {
    queryParams: { brandId, gaAccountId },
    callback,
    payload,
  } = action;

  try {
    const urlParams = `${crossPlatform}/third-party-channels/brand/${brandId}/ga/${gaAccountId}`;
    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      payload
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("updateConfigDetailsFromAccountId ~ error", error);
  }
}

function* deleteToken(action: DeleteToken): any {
  let {
    payload: { params, tokenUID, accountId },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/token/${tokenUID}/third-party-channel-accounts/${accountId}`;
    const response = yield call(
      HttpService.delete,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      params
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("deleteToken ~ error", error);
  }
}

function* getGoogleSheetsDataSource(action: GetGSheetsDataSource): any {
  let {
    payload: { brandId },
    callback,
  } = action;

  try {
    const urlParams = `/v2/api/brand/${brandId}/google-sheet/data-source`;
    const response = yield call(
      HttpService.get,

      CROSS_PLATFORM_URL,
      urlParams,
      "",
      ""
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("getGoogleSheetsDataSource ~ error", error);
  }
}
function* getGoogleSheetsURLValidation(action: GetGSheetURLValidation): any {
  let {
    payload: { brandId, sheetURL },
    callback,
  } = action;

  try {
    const urlParams = `/v2/api/brand/${brandId}/google-sheet/validation?sheetUrl=${sheetURL}`;
    const response = yield call(
      HttpService.get,

      CROSS_PLATFORM_URL,
      urlParams,
      "",
      ""
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("getGoogleSheetsURLValidation ~ error", error);
  }
}

function* getGoogleSheetsSyncHistory(action: GetGSheetSyncHistory): any {
  let {
    payload: { brandId, sheetId, page, pageSize, sheetName },
    callback,
  } = action;
  sheetName = encodeURIComponent(sheetName);
  try {
    const urlParams = `/v2/api/brand/${brandId}/google-sheet/${sheetId}/sync-history?page=${page}&pageSize=${pageSize}&sheetName=${sheetName}`;
    const response = yield call(
      HttpService.get,

      CROSS_PLATFORM_URL,
      urlParams,
      "",
      ""
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("getGoogleSheetsSyncHistory ~ error", error);
  }
}

function* getGoogleSheetsTimezone(action: GetGSheetTimezone): any {
  let {
    payload: { brandId },
    callback,
  } = action;

  try {
    const urlParams = `/v2/api/brand/${brandId}/timezone`;
    const response = yield call(
      HttpService.get,

      CROSS_PLATFORM_URL,
      urlParams,
      "",
      ""
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("getGoogleSheetsTimezone ~ error", error);
  }
}

function* getThirdPartyAccounts(action: any): any {
  let { payload } = action;
  try {
    const urlParams = `/v2/api/brand/${payload.brandId}/third-party-accounts`;
    const response = yield call(
      HttpService.get,

      CROSS_PLATFORM_URL,
      urlParams,
      "",
      ""
    );
    if (response.data.success) {
      payload.callback && payload.callback(response.data);
    }
  } catch (error: any) {
    console.log("getThirdPartyAccounts ~ error", error);
  }
  return;
}

function* getGoogleSheetsResyncData(action: GetGResyncData): any {
  let {
    payload: { brandId, sheetId, sheetName },
    callback,
  } = action;
  sheetName = encodeURIComponent(sheetName);

  try {
    const urlParams = `/v2/api/brand/${brandId}/google-sheet/${sheetId}/resync?sheetName=${sheetName}`;
    const response = yield call(
      HttpService.get,

      CROSS_PLATFORM_URL,
      urlParams,
      "",
      ""
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("getGoogleSheetsTimezone ~ error", error);
  }
}

function* addGoogleSheetsConfiguration(action: AddGSheetConfiguration): any {
  let {
    payload,
    queryParams: { brandId },
    callback,
  } = action;

  try {
    const urlParams = `/v2/api/brand/${brandId}/google-sheet`;
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      payload
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    yield put(setError({ isError: true, error: error }));
    console.log("addGoogleSheetsConfiguration ~ error", error);
  }
}
function* updateGoogleSheetConfiguration(
  action: UpdateGSheetConfiguration
): any {
  let {
    payload,
    queryParams: { brandId, sheetId, sheetName },
    callback,
  } = action;
  sheetName = encodeURIComponent(sheetName);
  try {
    const urlParams = `/v2/api/brand/${brandId}/google-sheet/${sheetId}?sheetName=${sheetName}`;
    const response = yield call(
      HttpService.put,

      CROSS_PLATFORM_URL,
      urlParams,
      "",
      payload
    );

    if (response.data.success) {
      callback && callback(response.data);
    }
  } catch (error: any) {
    console.log("updateGoogleSheetsConfiguration ~ error", error);
  }
}
export function* thirdPartyChannelsWatcher() {
  yield takeLatest(
    GET_CLIENT_ID_FOR_SPECIFIC_PLATFORM,
    getClientIdForSpecificPlatform
  );
  yield takeLatest(GET_ELIGIBILITY_OF_ACCOUNTS, getEligibilityOfAccounts);
  yield takeLatest(
    GET_TOKEN_STATS_SPECIFIC_FOR_AD_ACCOUNTS,
    getTokenStatsSpecificForAdAccounts
  );
  yield takeLatest(
    GET_TOKEN_STATUS_BASED_ON_BRAND_ID,
    getTokenStatusBasedOnBrandId
  );
  yield takeLatest(GET_PROPERTIES_OF_GA_ACCOUNT, getPropertiesOfGAAccount);
  yield takeLatest(GET_CONFIGS_OF_GA_ACCOUNT, getConfigsOfGAAccount);
  yield takeLatest(
    GET_CONFIG_DETAILS_OF_THIRD_PARTY_ACCOUNT,
    getConfigDetailsOfThirdPartyAccount
  );
  yield takeLatest(
    GENERATE_ACCESS_AND_REFRESH_TOKEN,
    generateAccessAndRefreshToken
  );
  yield takeLatest(LINK_AD_ACCOUNTS_TO_BRANDS, linkAdAccountsToBrands);
  yield takeLatest(
    ENABLE_MASTER_ACCESS_TO_ACCOUNTS,
    enableMasterAccessToAccounts
  );
  yield takeLatest(
    UPDATE_PRIMARY_TOKEN_FOR_THIRD_PARTY,
    updatePrimaryTokenForThirdParty
  );
  yield takeLatest(
    UPDATE_CONFIG_DETAILS_FROM_ACCOUNT_ID,
    updateConfigDetailsFromAccountId
  );
  yield takeLatest(DELETE_TOKEN_FOR_THIRD_PARTY, deleteToken);
  yield takeLatest(GET_THIRD_PARTY_ACCOUNTS, getThirdPartyAccounts);
  yield takeLatest(GET_G_SHEETS_DATA_SOURCE, getGoogleSheetsDataSource);
  yield takeLatest(GET_G_SHEET_URL_VALIDATION, getGoogleSheetsURLValidation);
  yield takeLatest(GET_G_SHEET_SYNC_HISTORY, getGoogleSheetsSyncHistory);
  yield takeLatest(GET_G_SHEET_TIMEZONE, getGoogleSheetsTimezone);
  yield takeLatest(GET_G_SHEET_RESYNC_DATA, getGoogleSheetsResyncData);
  yield takeLatest(ADD_G_SHEET_CONFIGURATION, addGoogleSheetsConfiguration);
  yield takeLatest(
    UPDATE_G_SHEET_CONFIGURATION,
    updateGoogleSheetConfiguration
  );
}
