import React, { Fragment, useEffect, useState } from "react";

import {
  StyledVariantItemParaTitle,
  StyledAdsListContainer,
  StyledAdsImage,
  StyledAdsListContainerTitle,
  StyledAdsInDraft,
  StyledAdsValues,
  StyledBackDrop,
  ImageEditIconContainer,
  StyledAdStatusContainer,
  StyledStatus,
  StyledAdIdSpan,
  StyledAdsSpendFields,
  StyledAdSpendsContainer,
  StyledAdsSpendIcon,
  StyledCurveWithIcons,
  StyledIconContainer,
  StyledAdsListMainContainer,
  StyledCopyContainer,
  StyledMainCreativeContainer,
  StyledAdsCrousel,
} from "./styled";

import Divider from "@mui/material/Divider";
import { Box, IconButton, Typography } from "@mui/material";
import {} from "./styled";
import MainCreativeCard from "../../../CreativeAI/Facebook/CreateVariants/AdPreview/MainCreativeCard";
import imagePlaceholder from "../../../../assets/png/no_image_placeholder.png";
import { ReactComponent as ImageEditIcon } from "../../../../assets/svg/edit-image.svg";
import { ReactComponent as Spends } from "../../../../assets/cai/svg/Spends.svg";
import { ReactComponent as CPR } from "../../../../assets/cai/svg/CPR.svg";
import { ReactComponent as CTR } from "../../../../assets/cai/svg/CTR.svg";
import { ReactComponent as Pixis } from "../../../../assets/cai/svg/PixisAd.svg";
import { ReactComponent as MediaStudio } from "../../../../assets/cai/svg/MediaStudio.svg";
import { ReactComponent as Copy } from "../../../../assets/cai/svg/copy_ad.svg";
import { StyledToolTip } from "components/CreativeAI/Facebook/CreateVariants/Editor/styled";
import { LightTooltip } from "components/CreativeAI_Revamp/shared";
import { AdsItemType } from "store/types/CreativeAI_Revamp/facebook.types";
import { CustomizedSnackBars } from "components/shared/AlertSnackBar/AlertSnackBar";
import { Carousel, CarouselItem } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom-v5-compat";

const AdCard: React.FC<{
  item: AdsItemType;
  activeAd: any;
  index: number;
  previewLoading: any;
  adPreviewLink: any;
  adType?: string;
  currency: string;
  handelAdsSelect: (item: AdsItemType, index: number) => void;
}> = ({
  item,
  activeAd,
  index,
  // setShowMainCreativeAi,
  // showMainCreativeUI,
  previewLoading,
  adPreviewLink,
  adType,
  currency,
  handelAdsSelect,
}) => {
  const [showMainCreativeUI, setShowMainCreativeAi] = useState(false);
  const [hoverDelay, setHoverDelay] = useState<any>("");
  const [showToast, setShowToast] = useState(false);
  const [showAdIdToast, setShowAdIdToast] = useState(false);
  const {agencyAssets} = useSelector((state:any) => state.common);
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");

  //   if (!item) {
  //     return <div>Loading...</div>;
  //   }
  const selectedCount = item?.variantList?.filter(
    (element: any) => element.checked
  )?.length;
  const handleSnackBarClose = () => {
    setShowToast(false);
  };

  return (
    <StyledAdsListMainContainer>
      <StyledAdsListContainer
        onClick={() => {
          handelAdsSelect(item, index);
        }}
        active={activeAd}
      >
        <StyledCurveWithIcons>
          <StyledIconContainer>
            <LightTooltip
              title={`Media Studio ${
                item?.isMediaStudioAvailable ? "Available " : "Unavailable"
              }`}
              arrow
            >
              <MediaStudio
                width="50%"
                height="100%"
                opacity={item?.isMediaStudioAvailable ? 1 : 0.5}
              />
            </LightTooltip>
            <LightTooltip
              title={`${
                item?.publishingSource == "PIXIS"
                  ? "Published"
                  : "Not published"
              } through ${agencyAssets.longTitle || 'Pixis AI'}`}
              arrow
            >
              {/* <Pixis
                width="50%"
                height="100%"
                opacity={item?.publishingSource == "PIXIS" ? 1 : 0.5}
              /> */}
              {agencyAssets.logo ? <Box width="50%" height="100%" display='flex' justifyContent='center' pt='3px'>
               <Box 
                display='flex' 
                justifyContent='center' 
                alignItems='center' 
                width="30.93px" 
                height="30.93px" 
                sx={{background:'white', 
                    borderRadius:'30px', 
                    boxShadow:'0px 5.28552px 3.52368px rgba(0, 0, 0, 0.05)', 
                    opacity: item?.publishingSource == "PIXIS" ? 1 : 0.5}}
                >
                 <img
                   src={agencyAssets.logo}
                   style={{ width: "19px", height: "19px" }}
                   alt="logo-icon"
                 />
                </Box>
              </Box>:
              <Pixis
                width="50%"
                height="100%"
                opacity={item?.publishingSource == "PIXIS" ? 1 : 0.5}
              />
              }
            </LightTooltip>
          </StyledIconContainer>
        </StyledCurveWithIcons>

        <div className="ads-list-text">
          <div
            onMouseOver={(e) => {
              if (item?.id) {
                setHoverDelay(
                  setTimeout(() => {
                    e.stopPropagation();
                    setShowMainCreativeAi(true);
                  }, 500)
                );
              }
            }}
            onMouseOut={(e) => {
              e.stopPropagation();
              setHoverDelay(clearTimeout(hoverDelay));
            }}
          >
            {item?.adcreatives?.data[0]?.asset_feed_spec?.videos?.length > 0 ? (
              <StyledAdsCrousel>
                <Carousel controls={false} indicators={false} fade>
                  {item?.adcreatives?.data[0]?.asset_feed_spec?.videos?.map(
                    (video: any) => (
                      <CarouselItem interval={2000}>
                        <StyledAdsImage
                          src={video?.thumbnail_url ?? imagePlaceholder}
                          alt=""
                        />
                      </CarouselItem>
                    )
                  )}
                </Carousel>
              </StyledAdsCrousel>
            ) : (
              <StyledAdsImage
                src={item?.adcreatives?.data[0]?.image_url ?? imagePlaceholder}
                alt=""
              />
            )}
            {showMainCreativeUI && (
              <StyledBackDrop
                className="dropback"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMainCreativeAi(false);
                  setHoverDelay(clearTimeout(hoverDelay));
                }}
              >
                <StyledMainCreativeContainer key={activeAd.id}>
                  <div className="mainContainer">
                    <MainCreativeCard
                      item={item}
                      previewLoading={previewLoading}
                    />
                  </div>
                </StyledMainCreativeContainer>
              </StyledBackDrop>
            )}
          </div>
          <StyledAdsListContainerTitle>
            <StyledAdStatusContainer>
              Ad Status -{" "}
              <StyledStatus status={item?.status === "ACTIVE"}>
                {item?.status === "ACTIVE" ? "ON" : "OFF"}{" "}
              </StyledStatus>
            </StyledAdStatusContainer>
            <LightTooltip title={item.name} arrow>
              <Fragment>
                <StyledVariantItemParaTitle className="fw-bold">
                  {item.name}
                  <StyledCopyContainer>
                    &#8230;
                    <IconButton
                      onClick={(e) => {
                        if(isViewedFromBeta)
                          {
                          window.parent.postMessage(
                            {
                              type: 'COPY-TO-CLIPBOARD',
                              copiedText: item.name,
                            },
                            '*'
                          );
                        }
                        navigator.clipboard.writeText(item.name);
                        setShowToast(true);
                        e.stopPropagation();
                      }}
                      sx={{ fontSize: "1em", padding: 0 }}
                    >
                      <Copy width="1em" height="100%" />
                    </IconButton>
                  </StyledCopyContainer>
                </StyledVariantItemParaTitle>
              </Fragment>
            </LightTooltip>
            <StyledAdIdSpan>
              Ad Id: {item?.id}{" "}
              <IconButton
                onClick={(e) => {
                  if(isViewedFromBeta)
                    {
                    window.parent.postMessage(
                      {
                        type: 'COPY-TO-CLIPBOARD',
                        copiedText: item.id,
                      },
                      '*'
                    );
                  }
                  navigator.clipboard.writeText(item.id);
                  setShowAdIdToast(true);
                  e.stopPropagation();
                }}
                sx={{ fontSize: "1em", padding: 0 }}
              >
                <Copy width="1em" height="100%" />
              </IconButton>
            </StyledAdIdSpan>
          </StyledAdsListContainerTitle>
        </div>
        <StyledAdsSpendFields className="mt-2">
          <StyledAdSpendsContainer>
            <StyledAdsSpendIcon>
              <Spends width="100%" height="100%" />
            </StyledAdsSpendIcon>
            <span>
              <StyledAdsInDraft>SPENDS</StyledAdsInDraft>
              <LightTooltip
                title={
                  item?.metrics?.Spends
                    ? `${currency} ${item?.metrics?.Spends}`
                    : ""
                }
                arrow
              >
                <StyledAdsValues>
                  {" "}
                  {currency}{" "}
                  {item?.metrics?.Spends !== undefined
                    ? item?.metrics?.Spends.toFixed(2)
                    : "-"}
                </StyledAdsValues>
              </LightTooltip>
            </span>
          </StyledAdSpendsContainer>
          <Divider
            orientation="vertical"
            flexItem
            style={{ height: "35px", color: "#EFEFEF" }}
          />
          <StyledAdSpendsContainer>
            <StyledAdsSpendIcon>
              <CPR width="100%" height="100%" />
            </StyledAdsSpendIcon>
            <span>
              <StyledAdsInDraft>CPR</StyledAdsInDraft>
              <LightTooltip
                title={
                  item?.metrics?.CPR ? `${currency} ${item?.metrics?.CPR}` : ""
                }
                arrow
              >
                <StyledAdsValues>
                  {currency}{" "}
                  {item?.metrics?.CPR !== undefined
                    ? item?.metrics?.CPR?.toFixed(2)
                    : "-"}
                </StyledAdsValues>
              </LightTooltip>
            </span>
          </StyledAdSpendsContainer>
          <Divider
            orientation="vertical"
            flexItem
            style={{ height: "35px", color: "#EFEFEF" }}
          />
          <StyledAdSpendsContainer>
            <StyledAdsSpendIcon>
              <CTR width="100%" height="100%" />
            </StyledAdsSpendIcon>
            <span>
              <StyledAdsInDraft>CTR</StyledAdsInDraft>
              <LightTooltip
                title={item?.metrics?.CTR ? `${item?.metrics?.CTR}%` : ""}
                arrow
              >
                <StyledAdsValues>
                  {item?.metrics?.CTR
                    ? item?.metrics?.CTR?.toFixed(2) + "%"
                    : item?.metrics?.CTR === 0
                    ? 0
                    : "-"}
                </StyledAdsValues>
              </LightTooltip>
            </span>
          </StyledAdSpendsContainer>
        </StyledAdsSpendFields>
      </StyledAdsListContainer>
      <CustomizedSnackBars
        handleClose={handleSnackBarClose}
        open={showToast}
        msg="Ad name copied to clipboard"
      />
      <CustomizedSnackBars
        handleClose={() => {
          setShowAdIdToast(false);
        }}
        open={showAdIdToast}
        msg="Ad id copied to clipboard"
      />
    </StyledAdsListMainContainer>
  );
};

export default React.memo(AdCard);
