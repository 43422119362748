import { aiGroups, parentAiGroups } from "MappedAPI/MappedAPI";
import HttpService from "HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "utils/path";
import { GroupType } from "types/GlobalTypes";

export const putPacingDates = async (
  aiGroupId: string,
  budgetPlannerId: string,
  payload: [],
  type: GroupType
) => {
  let response = {
    success: false,
    message: "",
    data: [],
  };
  let updatedPayload = {
    selectedDates: payload,
  };

  let urlParams = `${
    type === GroupType.group ? aiGroups : parentAiGroups
  }${aiGroupId}/budget-planners/${budgetPlannerId}`;
  response = await HttpService.put(
    CROSS_PLATFORM_URL,
    urlParams,
    "",
    updatedPayload
  )
    .then((data: any) => {
      return data.data;
    })
    .catch(() => {
      throw new Error("error updating budget");
    });
  return response;
};
