import HttpService from "HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "utils/path";

export const addBrandAPI = async (addBrandPayload: {}) => {
  let response = {
    success: false,
    message: "",
    data: {},
  };

  try {
    let urlParams = `/v4/api/brands`;
    response = await HttpService.post(
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      addBrandPayload
    ).then((data: any) => {
      return data.data;
    });
  } catch (error: any) {
    throw Error(error);
    console.log("LL: addBrandAPI -> error", error);
  }
  return response;
};
