import { Input, InputProps, styled } from "@mui/material";

export interface TextInputProps extends InputProps {
  isBorder?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  height?: string;
}

export const StyledTextInput = styled(Input)`
  font-family: "Inter";
  background: ${(props: TextInputProps) => props.backgroundColor ?? "#FFFFFF"};
  border-radius: 0.25em;
  padding: 0.875em 0.75em;
  font-size: 0.625em;
  line-height: 12px;
  font-weight: 400;
  font-style: normal;
  height: ${(props: TextInputProps) => props.height ?? undefined};
  border: ${(props: TextInputProps) =>
    props.isBorder ? `1px solid ${props.borderColor ?? "#E5E5E5"}` : "white"};

  .MuiInput-input {
    cursor: ${({ disabled }: TextInputProps) =>
      disabled ? "not-allowed" : undefined};
  }
`;
