/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import { getAllPlatformLabels, getPlatformName } from './commonFunctions';

export const currencyConverter = (data: any, labels: any, selectedGroupAdaccounts: any, platforms: any, currencyIdentifier: any) => {
    let updatedData = platforms.map((platform:any) => {
        if (labels.indexOf(platform.name) > -1) {
            let filterAdacc = selectedGroupAdaccounts.find((o:any) => o.platformId === platform.id);
            let filteredData = data.find((plat:any)=> plat.label === platform.name)
            if (filterAdacc?.id) {
                let newData = [];
                let currencyValue = currencyIdentifier.find((adacc: any) => adacc.id === filterAdacc.id)
                if (currencyValue) {
                    filteredData.data.forEach((currentData: any) => {
                        newData.push(parseFloat(currentData * currencyValue.value));
                    })
                    return ({
                        data: newData,
                        label: filteredData.label
                    });
                }
            }
            return ({
                data: filteredData.data,
                label: filteredData.label
            })
        } else {
            return {};
        }
    })
    let filteredData = updatedData.filter((newData) => newData.label);
    return (filteredData);
}

export const calculateCurrencyValueForMetaBrand = (data:any,platforms: any, selectedGroupAdaccounts: any, currencyIdentifier: any) =>{

    const fbPlatformDetails = platforms.find(platform=> platform.type === 'FACEBOOK AD')
    let filterAdacc = selectedGroupAdaccounts.find((o:any) => o.platformId === fbPlatformDetails?.id);
    const newData = data['META AD']?.spend
    if (filterAdacc?.id) {
        let currencyValue = currencyIdentifier.find((adacc: any) => adacc.id === filterAdacc.id)
        if (currencyValue) {
            const ob = parseFloat(newData * currencyValue.value);
            return ({
                label: 'META AD',
                data: ob
            });
        }
        
    }
}

export const calculateCurrencyValue = (data: any, platformLables: any, platforms: any, selectedGroupAdaccounts: any, currencyIdentifier: any) => {
    let updatedData = platforms.map((platform:any) => {
        if (platformLables.indexOf(platform.name) > -1) {
            let filterAdacc = selectedGroupAdaccounts.find((o:any) => o.platformId === platform.id);
            let newData = data[platform.name].spend  ? data[platform.name].spend  : data[platform.name].Spend  ? data[platform.name].Spend  : data[platform.name].localSpend ? data[platform.name].localSpend :data[platform.name].metric_total_media_cost_advertiser ? data[platform.name].metric_total_media_cost_advertiser : data[platform.name].costInLocalCurrency ? data[platform.name].costInLocalCurrency : data[platform.name].cost_micros ? parseFloat((data[platform.name].cost_micros/1_000_000)) : 0;
            if (filterAdacc?.id) {
                let currencyValue = currencyIdentifier.find((adacc: any) => adacc.id === filterAdacc.id)
                if (currencyValue) {
                    const ob = parseFloat(newData * currencyValue.value);
                    return ({
                        label: platform.name,
                        data: ob
                    });
                }
                
            }
            return ({
                label: platform.name,
                data: newData
            }); 
        }else if(platformLables.indexOf('META AD') > -1 && platform.name === 'FACEBOOK AD'){
            return calculateCurrencyValueForMetaBrand(data,platforms, selectedGroupAdaccounts, currencyIdentifier)
        }
    });
    let filteredData = updatedData.filter((newData) => newData && newData.label);
    return (filteredData);
}

export const getAdaccountList = (adaccounts: any, data: any, platforms: any) => {
    let list: any = [];
    let labels = getAllPlatformLabels(data);
    platforms.map((platform:any) => {
        if(labels.indexOf(platform.name) > -1) {
            let filterAdacc = adaccounts.find((o:any) => o.platformId === platform.id);
            if (filterAdacc?.id && !list.some((adacc: any) => adacc.id === filterAdacc.id) ) {
                list.push({id: filterAdacc.id, platformType : getPlatformName(platform.name)});
            }
        }
    });
    return list;
}