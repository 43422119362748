import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./index.scss";

export type TargetSliderMark = {
  value: number;
  label: string;
};

function calculateValue(value: number) {
  return 2 ** value;
}

const TargetSlider: React.FC<{
  sliderMarks: TargetSliderMark[];
  handelOnChange: (data: any, newValue: number | number[]) => void;
  sliderRange: any;
  sliderRestriction: number;
  valueLabelDisplay: any; //************ IT will be --> on || off || auto
}> = ({
  sliderMarks,
  handelOnChange,
  sliderRange,
  sliderRestriction,
  valueLabelDisplay = "on",
}) => {
  const [items, setItems] = React.useState<any>(false);

  const handleChange = useCallback(
    (event: Event, newValue: any) => {
      var obj = { targetAgeMin: "", targetAgeMax: "" };
      obj.targetAgeMin = newValue[0];
      obj.targetAgeMax = newValue[1];
      if (newValue[0] < sliderRestriction) {
        return null;
      }else if(newValue[0]===newValue[1]){return null} else {
        handelOnChange(obj, newValue);
        setItems(true);
      }
    },
    [items, handelOnChange]
  );
  return (
    <div>
      <Box className="slider-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <p className="mr-3 slider-para">{sliderRestriction}</p> */}
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={sliderRange}
            onChange={handleChange}
            valueLabelDisplay={items ? valueLabelDisplay : "auto"}
            marks={sliderMarks}
            disableSwap
            min={sliderRestriction ?? 10}
            max={100}
            //   scale={calculateValue}
          />
        </div>
      </Box>
    </div>
  );
};

export default TargetSlider;
