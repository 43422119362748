export const BASE_URL: string = process.env.REACT_APP_BASE_URL as string;
export const CROSS_PLATFORM_URL: string = process.env
  .REACT_APP_CROSS_PLATFORM_BASE_URL as string;
export const TARGETING_API: string = process.env
  .REACT_APP_TARGETING_API as string;
export const EXTENSION_ID: string = process.env
  .REACT_APP_EXTENSION_ID as string;
export const AUTH0_DOMAIN: string = process.env
  .REACT_APP_AUTH0_DOMAIN as string;
export const AUTH0_CLIENT_ID: string = process.env
  .REACT_APP_AUTH0_CLIENT_ID as string;
export const AUTH0_AUDIENCE: string = process.env
  .REACT_APP_AUTH0_AUDIENCE as string;
export const MIXPANEL_UNIQUE_CODE: string = process.env
  .REACT_APP_MIXPANEL_UNIQUE_CODE as string;
export const CROSS_PLATFORM_MOCK: string = process.env
  .REACT_APP_CROSS_PLATFORM_MOCK_URL as string;
export const BILLING_DASHBOARD_URL: string = process.env
  .REACT_APP_BILLING_DASHBOARD_URL as string;
export const WEBSOCKET_BASE_URL: string = process.env
  .REACT_APP_WEBSOCKET_BASE_URL as string;
export const REACT_APP_BASE_URL_PIXIS2: string = process.env
  .REACT_APP_BASE_URL_PIXIS2 as string;
export const AUTH0_COOKIE_DOMAIN: string = process.env
  .REACT_APP_AUTH0_COOKIE_DOMAIN as string;
export const TIKTOK_APP_ID: string = process.env
  .REACT_APP_TIKTOK_APP_ID as string;
