import { useState, useRef, FC } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import HandleClickOutside from "components/common/HandleClickOutside";
import "./SearchableSelectNew.scss";

const SearchableSelectNew: FC<{
  customProps: Record<string, any>;
  value?: string;
  data: any;
  onChange: (value: any) => void;
  filterOptions: (value: any) => void;
  labelComponent: FC<any>;
  className?: string;
}> = ({
  customProps = {},
  value,
  onChange,
  data,
  filterOptions,
  labelComponent: Component,
}) => {
  const [show, setShow] = useState(false);
  const [filterText, setFilterText] = useState("");
  const wrapperRef = useRef(null);

  const onClickShowHide = () => {
    setShow(!show);
    setFilterText("");
    filterOptions("");
  };

  HandleClickOutside(wrapperRef, [setShow]);

  return (
    <Accordion
      className="w-100 searchable-select-accordian"
      sx={{ background: "transparent", border: "0px", boxShadow: "none" }}
      disableGutters={true}
      expanded={show}
      ref={wrapperRef}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          background: "white",
          border: "1px solid #E7E9F1",
          borderRadius: 2,
        }}
        onClick={onClickShowHide}
        {...customProps}
      >
        <Typography>{value}</Typography>
        <ExpandMoreIcon className="ml-auto expand-more" />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          mt: 2,
          background: "white",
          boxShadow: "0px 0px 15px rgb(0 0 0 / 10%)",
          position: "absolute",
          zIndex: "999",
          width: "100%",
        }}
      >
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <InputBase
            value={filterText}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search..."
            inputProps={{ "aria-label": "Search..." }}
            onChange={(e) => {
              setFilterText(e.target.value);
              filterOptions(e.target.value);
            }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon className="expand-more" />
          </IconButton>
        </Paper>
        <Box sx={{ maxHeight: "14rem", overflowY: "scroll", mt: 1 }}>
          {data?.filter((item:any)=>item && item?.name?.toLowerCase().includes(filterText?.toLowerCase())).map((dataPoint: any) => {
            return (
              <label
                key={dataPoint.id}
                className="flex-center py-2 mb-0"
                onClick={() => {
                  if (show) {
                    setShow(false);
                  }
                  onChange(dataPoint);
                }}
              >
                <Component value={value} dataPoint={dataPoint} />
              </label>
            );
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchableSelectNew;
