import { GetMontageCountry, GetS3Url, GET_S3_URL, UploadToS3Url, UPLOAD_TO_S3_URL, GET_MONTAGE_COUNTRY } from './../types/montageAds';
import { call, takeLatest ,takeEvery} from "redux-saga/effects";
import HttpService from "../../HttpService/HttpService";
import { GetMontageValues, GET_MONTAGE, MontageState,PostMontageAds, POST_MONTAGE_ADS } from "../types/montageAds";
import { CROSS_PLATFORM_URL } from "../../utils/path";
import { crossPlatform } from "../../MappedAPI/MappedAPI";
import { DeleteCampaign, DELETE_CAMPAIGN, GetCampaign, GetMontageCampaigns, GET_CAMPAIGN, GET_MONTAGE_CAMPAIGNS } from "../types/montageAds";


function* getMontageValues(action: GetMontageValues): any {
    let {
      callback,
    } = action;
    try {
      const urlParams = `${crossPlatform}/montage-campaign/default-values`;
      const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
      if (!response.data.error) {
        callback && callback(response.data, false);
      } else {
        callback && callback(response, true);
      }
    } catch (error: any) {
      console.log("function*getMontageValues ~ error", error);
      callback && callback(error.response, true);
    }
}

function* getMontageCountry(action: GetMontageCountry): any {
  let {
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/montage-campaign/countries`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getMontageCountry ~ error", error);
    callback && callback(error.response, true);
  }
}

  function* postMontageAds(action: PostMontageAds): any {
    let {
      payload:{selectedBrandId, user,payload},
      callback,
    } = action;
    try {
      const urlParams = `${crossPlatform}/montage-campaign/${selectedBrandId}`;
      const response = yield call(HttpService.post, CROSS_PLATFORM_URL, urlParams, user, payload);
      if (response.data.code === "101") {
        return;
      }
      if (!response.data.error) {
        callback && callback(response.data, false);
      } else {
        callback && callback(response, true);
      }
    } catch (error: any) {
      console.log("function*postCampaignFactors ~ error", error)
      callback && callback(error.response, true);
    }
  }
  

function* getMontageCompaigns(action:GetMontageCampaigns):any{
    let {
        payload:{brandId,user},
        callback
    }=action
    try {
        const urlParams= `${crossPlatform}/montage-campaign/brand/${brandId}`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, user);
        if(!response.data.error){
            callback && callback(response.data, false);
        } else{
            callback && callback(response, true);
        }
    } catch (error:any) {
        callback && callback(error.response, true);
    }
}

function* getS3Url(action:GetS3Url):any{
  let {
      payload:{mimetype},
      callback
  }=action
  try {
      const urlParams= `${crossPlatform}/media/upload-url?mimetype=${mimetype}`;
      const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
      if(!response.data.error){
          callback && callback(response.data, false);
      } else{
          callback && callback(response, true);
      }
  } catch (error:any) {
      callback && callback(error.response, true);
  }
}

function* uploadToS3Url(action:UploadToS3Url):any{
  let {
      payload:{file,url ,user},
      callback
  }=action
  try {
      const response = yield call(HttpService.put, '', url, user, file);
      if(!response.data.error){
          callback && callback(response.data, false);
      } else{
          callback && callback(response, true);
      }
  } catch (error:any) {
      callback && callback(error.response, true);
  }
}

function* deleteCampaign(action:DeleteCampaign):any{
    let{
        payload:{campaignId,user},
        callback
    }=action
    try {
        const urlParams = `${crossPlatform}/montage-campaign/id/${campaignId}/delete/true`
        const response = yield call(HttpService.put, CROSS_PLATFORM_URL, urlParams, user,{})
        if(!response.data.error){
            callback && callback(response.data, false);
        }
        else{
            callback && callback(response, true)
        }
    } catch (error:any) {
        callback && callback(error.response, true);
    }
}

function* getCampaign(action:GetCampaign):any{
    let{
        payload:{campaignId,user},
        callback
    }=action
    try {
        const urlParams = `${crossPlatform}/montage-campaign/id/${campaignId}`
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, user)
        if(!response.data.error){
            callback && callback(response.data, false);
        }
        else{
            callback && callback(response, true)
        }
    } catch (error:any) {
        callback && callback(error.response, true);
    }
}

export function* MontageWatcher(){
  yield takeLatest(GET_MONTAGE, getMontageValues);
  yield takeLatest(GET_MONTAGE_COUNTRY, getMontageCountry);
  yield takeLatest(POST_MONTAGE_ADS,postMontageAds);
  yield takeEvery(GET_MONTAGE_CAMPAIGNS, getMontageCompaigns);
  yield takeEvery(GET_S3_URL, getS3Url);
  yield takeEvery(UPLOAD_TO_S3_URL, uploadToS3Url);
  yield takeLatest(DELETE_CAMPAIGN, deleteCampaign);
  yield takeEvery(GET_CAMPAIGN, getCampaign);
}