import { Box, Skeleton } from "@mui/material";
import React from "react";
import ActionDebuggerChartSkeletonItem from "./ActionDebuggerChartSkeletonItem";

export default function ActionDebuggerChartSkeleton() {
  return (
    <Box
      sx={{
        overflow: "hidden",
        height: "100%",
      }}
    >
      <Skeleton
        variant="rectangular"
        height="4em"
        sx={{
          margin: ".5em 1em",
          borderRadius: ".5em",
        }}
      />
      <ActionDebuggerChartSkeletonItem />
      <ActionDebuggerChartSkeletonItem />
      <ActionDebuggerChartSkeletonItem />
      <ActionDebuggerChartSkeletonItem />
      <ActionDebuggerChartSkeletonItem />
      <ActionDebuggerChartSkeletonItem />
      <ActionDebuggerChartSkeletonItem />
    </Box>
  );
}
