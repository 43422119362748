import { Box } from "@mui/material";
import { StyledHeading2 } from "../../../../../shared";
import ConfigureFields from "./ConfigureFields";
import ConfigureKeywordInfo from "./ConfigureKeywordInfo";
import { ConfigureFooterProps } from "../EditorFooter/ConfigureFooter";
import { StyledConfig } from "./styled";

interface ConfigureProps {
  control: any;
  getValues: any;
  setValue: any;
  reset: any;
  watch: any;
  errors: any;
  setError: any;
  clearErrors: any;
  footerProps: ConfigureFooterProps;
  variantItem: any;
  mixPanelCreativeStudioConfigureClicked: any;
}

export default function Configure({
  control,
  getValues,
  setValue,
  reset,
  watch,
  errors,
  setError,
  clearErrors,
  footerProps,
  variantItem,
  mixPanelCreativeStudioConfigureClicked,
}: ConfigureProps) {
  return (
    <StyledConfig>
      <Box className="left-box">
        <div className="d-flex justify-content-center mb-3">
          <StyledHeading2>Configure</StyledHeading2>
        </div>
        <ConfigureFields
          mixPanelCreativeStudioConfigureClicked={
            mixPanelCreativeStudioConfigureClicked
          }
          variantItem={variantItem}
          control={control}
          getValues={getValues}
          setValue={setValue}
          reset={reset}
          watch={watch}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          footerProps={footerProps}
        />
      </Box>
      <Box className="right-box drawer-panel drawer-right-panel">
        <div className="right-box-heading mb-3">
          <StyledHeading2>-</StyledHeading2>
        </div>
        <ConfigureKeywordInfo />
      </Box>
    </StyledConfig>
  );
}
