import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { setGSCalibrationData } from "store/actions/gsTargetingAI";
import { useDispatch, useSelector } from "react-redux";
import CustomSlider from "../CustomSlider";
import HeadingClibrationsGS from "./HeadingClibrationsGS";

const SLIDER_VALUE_TO_LOOK_BACK_PERIOD: any = {
  1: "7 Days",
  2: "14 Days",
  3: "30 Days",
  4: "90 Days",
};
const LOOK_BACK_PERIOD_TO_SLIDER_VALUE: any = {
  "7 Days": 1,
  "14 Days": 2,
  "30 Days": 3,
  "90 Days": 4,
};

function DataRegencyGS() {
  const dispatch = useDispatch();
  const { LookbackPeriod } = useSelector(
    (state: any) => state.gsTargetingAI.gsCalibration.data
  );

  const getSelectedDays = (sliderValue: number) => {
    dispatch(
      setGSCalibrationData({
        LookbackPeriod:
          SLIDER_VALUE_TO_LOOK_BACK_PERIOD[sliderValue] || "0 days",
      })
    );
  };
  return (
    <Box
      className="node-container-common-style"
      sx={{
        position: "absolute",
        top: "11%",
        right: "3%",
        width: "21rem",
        height: "12.5rem",
        px: 2,
      }}
    >
      <Box sx={{ pt: 1 }}>
        <HeadingClibrationsGS
          heading="Data Recency"
          subHeading="Select the Date range"
        />
      </Box>
      <hr style={{ margin: "0.5rem 0" }} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="caption">Lookback period</Typography>
        <Typography
          variant="caption"
          align="center"
          sx={{
            fontWeight: 600,
            color: "#0869FB",
            width: "3.87rem",
            height: "1.25rem",
            backgroundColor: "#EAF1F7",
            borderRadius: "0.31rem",
          }}
        >
          {LookbackPeriod}
        </Typography>
      </Stack>
      <Box
        sx={{
          width: "19rem",
          height: "5.50rem",
          border: "0.047rem solid #EFEFEF",
          boxShadow: "0 0.187rem 0.656rem rgba(0, 0, 0, 0.05)",
          borderRadius: "0.468rem",
          marginY: 2,
        }}
      >
        <Box sx={{ marginTop: "35px", px: 1.5 }}>
          <CustomSlider
            getSelectedDays={getSelectedDays}
            sliderValue={LOOK_BACK_PERIOD_TO_SLIDER_VALUE[LookbackPeriod]}
          />
          <Stack
            className="font-10 font-weight-400"
            direction="row"
            justifyContent={"space-between"}
            sx={{
              color: "#152370",
            }}
          >
            <Box>7 Days</Box>
            <Box>14 Days</Box>
            <Box>30 Days</Box>
            <Box>90 Days</Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default DataRegencyGS;
