import React from "react";
import { Box, Stack } from "@mui/material";

function LoaderCardGrid() {
  const backgroungColor =
    "linear-gradient(90deg, #F1EFEF -24.18%, #F9F8F8 50.26%, #E7E5E5 114.84%);";
  return (
    <Box
      sx={{
        width: "19.25rem",
        height: "16.75rem",
        border: "0.06rem solid #CCECFE",
        boxShadow: "0 0.625rem 1.5rem rgba(35, 40, 60, 0.05)",
        borderRadius: "0.5rem",
        background: "#fff",
      }}
    >
      <Stack margin={2} marginBottom={4}>
        <Stack
          sx={{
            width: "16rem",
            height: "1.06rem",
            background: backgroungColor,
            borderRadius: "0.375rem",
          }}
        ></Stack>
        <Stack
          sx={{
            my: 1,
            width: "15rem",
            height: "0.75rem",
            background: backgroungColor,
            borderRadius: "0.375rem",
          }}
        ></Stack>
      </Stack>
      <Stack>
        <Stack direction="row" sx={{ width: "100%" }}>
          <Stack
            padding={1}
            paddingLeft={2}
            spacing={2.5}
            sx={{ width: "45%" }}
          >
            <Stack direction="row" spacing={0.75} alignItems="center">
              <Box
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                  background: backgroungColor,
                  borderRadius: "0.375rem",
                }}
              ></Box>
              <Stack spacing={0.5}>
                <Stack
                  sx={{
                    width: "3.125rem",
                    height: "0.75rem",
                    borderRadius: "0.375rem",
                    background: backgroungColor,
                  }}
                ></Stack>
                <Stack
                  sx={{
                    width: "4.5rem",
                    height: "1.06rem",
                    borderRadius: "0.375rem",
                    background: backgroungColor,
                  }}
                ></Stack>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={0.75} alignItems="center">
              <Box
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                  background: backgroungColor,
                  borderRadius: "0.375rem",
                }}
              ></Box>
              <Stack spacing={0.5}>
                <Stack
                  sx={{
                    width: "3.125rem",
                    height: "0.75rem",
                    borderRadius: "0.375rem",
                    background: backgroungColor,
                  }}
                ></Stack>
                <Stack
                  sx={{
                    width: "4.5rem",
                    height: "1.06rem",
                    borderRadius: "0.375rem",
                    background: backgroungColor,
                  }}
                ></Stack>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={0.75} alignItems="center">
              <Box
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                  background: backgroungColor,
                  borderRadius: "0.375rem",
                }}
              ></Box>
              <Stack spacing={0.5}>
                <Stack
                  sx={{
                    width: "3.125rem",
                    height: "0.75rem",
                    borderRadius: "0.375rem",
                    background: backgroungColor,
                  }}
                ></Stack>
                <Stack
                  sx={{
                    width: "4.5rem",
                    height: "1.06rem",
                    borderRadius: "0.375rem",
                    background: backgroungColor,
                  }}
                ></Stack>
              </Stack>
            </Stack>
          </Stack>
          <Box
            sx={{
              width: "11.18rem",
              height: "9.125rem",
              background: backgroungColor,
            }}
          ></Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default LoaderCardGrid;
