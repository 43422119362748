import React, { useEffect, useState } from "react";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import { Button } from "@mui/material";
import {
  StyledArrowImage,
  StyledDateContent,
  StyledDatePara,
  StyledDateParavs,
  StyledDateRangeContainer,
} from "./styled";
import DownArrow from "../../../assets/svg/actionDebuggerDownArrow.svg";
import {
  ActionDebuggerInitStateType,
  EditDateType,
  SelectedDateType,
} from "../../../store/types/ActionDebugger/ActionDebugger";
import { useDispatch, useSelector } from "react-redux";
import { MMMDDYYYYFormat } from "../../../utils/date.util";
import { calculatePreviousPeriod } from "../shared/utils/common.utils";
import {
  setCurrentPeriod,
  setPreviousPeriod,
} from "../../../store/actions/ActionDebugger/ActionDebugger";
import HandleClickOutside from "../../common/HandleClickOutside";
import moment from "moment";
import { differenceInCalendarDays } from "date-fns";
import { ActionDebuggerHelperInitState } from "store/types/ActionDebugger/ActionDebuggerHelper";

export default function ActionDebuggerDateRange() {
  const { isCompare, currentPeriod, previousPeriod, compareType } = useSelector(
    (state: { ActionDebuggerReducer: ActionDebuggerInitStateType }) =>
      state.ActionDebuggerReducer
  );
  const { selectedAdAccount } = useSelector(
    (state: { ActionDebuggerHelperReducer: ActionDebuggerHelperInitState }) =>
      state.ActionDebuggerHelperReducer
  );
  const [showRange, setShowRange] = useState(false);
  const [dateSelection, setDateSelection] = useState({
    startDate: currentPeriod.startDate,
    endDate: currentPeriod.endDate,
    editedBy: "Calender" as EditDateType,
  });
  const dispatch = useDispatch();

  // const [dateSelection, setDateSelection] =
  //   React.useState<SelectedDateType>(currentPeriod);

  const onDateChange = (rangesByKey: RangeKeyDict) => {
    let n: number = differenceInCalendarDays(
      rangesByKey.range1.endDate &&
        moment(rangesByKey.range1.endDate).isBefore(new Date())
        ? rangesByKey.range1.endDate
        : new Date(),
      rangesByKey.range1.startDate ?? new Date()
    );
    // console.log("Days", n);
    if (
      selectedAdAccount?.platform?.name?.toLowerCase().includes("tiktok") &&
      n >= 24
    ) {
      alert("For platform Tiktok, Maximum range of 24 days can be selected");
    } else if (
      selectedAdAccount?.platform?.name?.toLowerCase().includes("apple") &&
      n > 74
    ) {
      alert("For platform Apple, Maximum range of 74 days can be selected");
    } else {
      setDateSelection({
        startDate: rangesByKey.range1.startDate ?? new Date(), // to handle undefined issue
        endDate:
          rangesByKey.range1.endDate &&
          moment(rangesByKey.range1.endDate).isBefore(new Date())
            ? rangesByKey.range1.endDate
            : new Date(), // to handle undefined issue
        editedBy: "Calender" as EditDateType,
      });
    }

    /**
     * * still in a discussion do we need to put current period on local state or not
     */

    // setDateSelection(localCurrentPeriod);
  };

  useEffect(() => {
    setDateSelection({
      startDate: currentPeriod.startDate,
      endDate: currentPeriod.endDate,
      editedBy: "Calender" as EditDateType,
    });
  }, [currentPeriod, showRange]);

  const onSubmit = () => {
    dispatch(setCurrentPeriod(dateSelection));
    dispatch(
      setPreviousPeriod(
        calculatePreviousPeriod(compareType, dateSelection, "Calender")
      )
    );
  };

  const wrapperRef = React.useRef(null);
  HandleClickOutside(wrapperRef, [setShowRange]);

  return (
    <StyledDateRangeContainer isCompare={isCompare} ref={wrapperRef}>
      <StyledDateContent onClick={() => setShowRange(!showRange)}>
        <div>
          <StyledDatePara>
            {MMMDDYYYYFormat(currentPeriod.startDate)} -{" "}
            {MMMDDYYYYFormat(currentPeriod.endDate)}
          </StyledDatePara>
          {isCompare && (
            <StyledDateParavs className="mb-0">
              VS &nbsp;{MMMDDYYYYFormat(previousPeriod.startDate)} -{" "}
              {MMMDDYYYYFormat(previousPeriod.endDate)}
            </StyledDateParavs>
          )}
        </div>
        <StyledArrowImage src={DownArrow} alt="" />{" "}
      </StyledDateContent>
      {showRange && (
        <div className="daterangeSelector">
          <DateRangePicker
            ranges={[dateSelection]}
            onChange={onDateChange}
            direction="horizontal"
            maxDate={new Date()}
            minDate={new Date("2015-01-01")}
            moveRangeOnFirstSelection={false}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowRange(false);
              onSubmit();
            }}
            sx={{ float: "right", textTransform: "capitalize" }}
          >
            Submit
          </Button>
        </div>
      )}
    </StyledDateRangeContainer>
  );
}
