import "./index.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Card from "./components/Card";
import CustomDrawer from "./components/Drawer";
import LoaderCardGrid from "./components/LoaderCardGrid";
import Header from "./components/Header";
import Subheader from "./components/Subheader";
import NoDataScreen from "./common/NoDataScreen/index";
import Footer from "./components/Footer";
import AudienceManagerSuccessModal from "./components/AudienceManagerSuccessModal";

import {
  getDraftedCohorts,
  getDraftedCohortFilters,
  getPublishedCohorts,
  getPublishedCohortsFilter,
  setDraftedCohorts,
  setRecommendedCohorts,
  setLoading,
  setBucketLength,
  resetAllData,
  setSelectedAiGroupAudienceManager,
  setSelectedPlatform,
  setDraftedCohortsFilters,
  setRecommendedCohortsFilters,
} from "../../store/actions/AudienceManager/index";
import {
  deleteTAIFavouriteCohort,
  setSelectedCohortsForPublish,
} from "../../store/actions/targetingAI";

import { handleAdsmanagerRedirection } from "./utils";

import StayHereModal from "Pages/TargetingAIPlaygroundBeta/components/StayHereModal";
import PublishModal from "Pages/TargetingAIPlaygroundBeta/components/PublishModal";

import {
  AudienceManagerState,
  SelectedCohortTypeEnum,
} from "../../store/types/AudienceManager/index";
import SubTabs from "./youtube/components/SubTabs";
import FileDropModal from "./youtube/components/FileUpload";
import {
  deleteYoutubeCohorts,
  getRecommendedCohorts,
  setYoutubeLoading,
  uploadYoutubeFile,
} from "store/actions/AudienceManager/youtube";
import VideoCard from "./youtube/components/VideoCard";
import ChannelCard from "./youtube/components/ChannelCard";
import AudienceCard from "./youtube/components/AudienceCard";
import { CohortDetails, MAX_RELEVANCE_SCORE, MIN_AUDIENCE_SIZE, MIN_RELEVANCE_SCORE, TDetailsPayload, TFiltersPayload, TSetDraftedCohortsFilters, TSetRecommendedCohortsFilters, YOUTUBE_SUB_TAB_VALUES } from "./constants/index";
import YtDrawer from "./youtube/components/YtDrawer";
import NoDataScreenYt from "./youtube/components/NoDataScreenYt";
import ErrorModal from "./components/ErrorModal";
import { mixPanelAdEvent } from "utils/mixpanel";
import { AdAccountState, groups } from "store/types/adaccount";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom-v5-compat";
import useFacebookTargetingEnumMapping from 'utils/useFacebookTargetingsEnumMapping';

const getLoaderCards = () => {
  const loaderCards = [];
  for (let index = 0; index < 8; index++) {
    loaderCards.push(
      <Grid item key={index}>
        <LoaderCardGrid />
      </Grid>
    );
  }
  return loaderCards;
};

const AudienceManagerAI = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  const [ai_group_id, incoming_platform] = [
    searchParams.get("ai_group_id"),
    searchParams.get("incoming_platform"),
  ];
  const {
    isLoading,
    publishedCohortsData,
    draftedCohortsData,
    recommendedCohortsData,
    selectedCohortType,
    selectedAiGroup,
    length,
    commonFilterData,
    selectedPlatform,
  } = useSelector(
    (state: { audienceManager: AudienceManagerState }) => state.audienceManager
  );

  const adaccountState = useSelector((state: any) => state.adaccount);
  const { isLoadingYoutube, audienceCohorts, videoCohorts, channelCohorts } =
    useSelector(
      (state: { audienceManagerYoutube: any }) => state.audienceManagerYoutube
    );
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isYtDrawerOpen, setIsYtDrawerOpen] = useState(false);
  const [isQuickPublishClicked, setIsQuickPublishClicked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const findLargestPotentialAudienceSize = (data: CohortDetails[]): CohortDetails => {
  return data.reduce((largest, item) => 
    item.potential_audience_size > largest.potential_audience_size ? item : largest, data[0]);
};

  const audienceDataLoadCallback= (data:any,success:boolean, reduxAction: TSetDraftedCohortsFilters | TSetRecommendedCohortsFilters)=>{
    if (success && data.data.length) {
      const audienceCohort = findLargestPotentialAudienceSize(data.data)
      dispatch(reduxAction({
        audience_size:{
          min:MIN_AUDIENCE_SIZE,
          max:audienceCohort?.potential_audience_size
        },
        relevance_score:{
          min:MIN_RELEVANCE_SCORE,
          max:MAX_RELEVANCE_SCORE
        }
      }))
    }
  }

  const fetchCohorts = (status:"isDrafted" | "isRecommended", filtersPayload:TFiltersPayload, detailsPayload: TDetailsPayload, reduxAction: TSetDraftedCohortsFilters | TSetRecommendedCohortsFilters) => {
    dispatch(getDraftedCohortFilters({ ...filtersPayload, status }));
    dispatch(getDraftedCohorts({
      ...detailsPayload,
      status,
      callback: ({ data }, success) => {
        audienceDataLoadCallback(data, success, reduxAction);
      }
    }));
  };

  const handleFileUpload = (files: File[]) => {
    dispatch(setYoutubeLoading(true));
    setModalOpen(false);
    dispatch(
      uploadYoutubeFile(
        {
          aiGroupId: selectedAiGroup?.id,
          file: files[0],
        },
        (response: any, error: boolean) => {
          if (!error) {
            dispatch(
              getRecommendedCohorts({
                aiGroupId: selectedAiGroup?.id,
                type: YOUTUBE_SUB_TAB_VALUES[youtubeTab] as
                  | "video"
                  | "audience"
                  | "channel"
                  | undefined,
              })
            );
          } else {
            setErrorModalOpen(true);
          }
        }
      )
    );
  };

  const [selectedCohort, setSelectedCohort] = useState({}) as any;
  const [selectedYtCohort, setSelectedYtCohort] = useState({}) as any;
  const { user } = useAuth0();
  const [checkedCohorts, setCheckedCohorts] = useState({
    drafted: {},
    recommended: {},
    live: {},
  }) as any;
  const [ytCheckedCohorts, setYtCheckedCohorts] = useState({
    video: {},
    channel: {},
    audience: {},
  }) as any;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishAudienceManagerModal, setShowPublishAudienceManagerModal] =
    useState(false);
  const [showAudiencePublishSuccessModal, setShowAudiencePublishSuccessModal] =
    useState(false);
  const [youtubeTab, setSelectedYoutubeTab] = useState(0);

  const handleCardClick = (data: any) => {
    setIsDrawerOpen(true);
    setSelectedCohort(data);
    if (selectedCohortType === SelectedCohortTypeEnum.live) {
      mixPanelAdEvent("Cohort card in Published clicked", {
        module_name: "TAI Audience Manager",
        ai_group_name: selectedAiGroup?.name,
        ai_group_id: selectedAiGroup?.id,
        // adaccount_name:selectedTargetingAdaccount?.,
        agency_id: selectedBrand?.agencyId,
        agency_name: selectedBrand?.agency_name,
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
      });
    } else if (selectedCohortType === SelectedCohortTypeEnum.drafted) {
      mixPanelAdEvent("Cohort card in Saved clicked", {
        module_name: "TAI Audience Manager",
        ai_group_name: selectedAiGroup?.name,
        ai_group_id: selectedAiGroup?.id,
        // adaccount_name:selectedTargetingAdaccount?.,
        agency_id: selectedBrand?.agencyId,
        agency_name: selectedBrand?.agency_name,
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
      });
    } else if (selectedCohortType === SelectedCohortTypeEnum.recommended) {
      mixPanelAdEvent("Cohort card in Pixis Recommended clicked", {
        module_name: "TAI Audience Manager",
        ai_group_name: selectedAiGroup?.name,
        ai_group_id: selectedAiGroup?.id,
        // adaccount_name:selectedTargetingAdaccount?.,
        agency_id: selectedBrand?.agencyId,
        agency_name: selectedBrand?.agency_name,
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
      });
    }
  };
  const handleYtCardClick = (data: any) => {
    setIsYtDrawerOpen(true);
    setSelectedYtCohort(data);
  };

  useEffect(() => {
    if (selectedPlatform === "youtube" && selectedAiGroup?.id) {
      dispatch(setYoutubeLoading(true));
      dispatch(
        getRecommendedCohorts({
          aiGroupId: selectedAiGroup?.id,
          type: YOUTUBE_SUB_TAB_VALUES[youtubeTab] as
            | "video"
            | "audience"
            | "channel"
            | undefined,
        })
      );
      dispatch(resetAllData());

      dispatch(
        setLoading({
          [SelectedCohortTypeEnum.live]: true,
          [SelectedCohortTypeEnum.recommended]: true,
          [SelectedCohortTypeEnum.drafted]: true,
        })
      );
      setSelectedCohort({});
      setCheckedCohorts({
        drafted: {},
        recommended: {},
        live: {},
      });
    } else {
      setSelectedYoutubeTab(0);
      setYtCheckedCohorts({
        video: {},
        channel: {},
        audience: {},
      });
    }
  }, [youtubeTab, selectedPlatform, selectedAiGroup?.id]);

  const handleYoutubeTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedYoutubeTab(newValue);
  };
  const deleteModalProps = {
    text: "Are you sure you want to delete the cohort ",
    exitButtonText: "Yes",
    stayButtonText: "Cancel",
    btnWidth: "9rem",
    onExitClick: () => {
      if (selectedPlatform === "facebook") handleDelete();
      if (selectedPlatform === "youtube") handleYotubeDelete();
    },
    onStayClick: () => {
      setShowDeleteModal(false);
    },
  };

  const successModalProps = {
    startAgainClick: () => {
      setShowAudiencePublishSuccessModal(false);
    },
    keepExploringClick: () => {
      setShowAudiencePublishSuccessModal(false);
    },
    handleOutsideClick: () => {
      setShowAudiencePublishSuccessModal(false);
    },
  };

  useEffect(() => {
    if (ai_group_id) {
      dispatch(
        setSelectedAiGroupAudienceManager(
          adaccountState?.groups?.find(
            (group: groups) => group.id === ai_group_id
          )
        ) ||
        adaccountState?.groups?.[0] ||
        null
      );
    }
  }, [ai_group_id, adaccountState]);

  useEffect(() => {
    if (incoming_platform === "facebook" || incoming_platform === "youtube") {
      dispatch(setSelectedPlatform(incoming_platform));
      if (searchParams.get("brand_id") !== "null") {
        setSearchParams((prev) => {
          return {
            ...prev,
            brand_id: searchParams.get("brand_id"),
            ai_group_id: searchParams.get("ai_group_id"),
          };
        });
      }
    }
  }, [incoming_platform]);

  useEffect(() => {
    if (selectedPlatform === "facebook") {
      dispatch(resetAllData());

      dispatch(
        setLoading({
          [SelectedCohortTypeEnum.live]: true,
          [SelectedCohortTypeEnum.recommended]: true,
          [SelectedCohortTypeEnum.drafted]: true,
        })
      );
      if (selectedAiGroup && selectedAiGroup.id) {
        const filtersPayload: TFiltersPayload = {
          aiGroupId: selectedAiGroup.id,
        };
        const detailsPayload: TDetailsPayload = {
          aiGroupId: selectedAiGroup.id,
          date_range: {
            start: commonFilterData.selectedDateRange.startDate,
            end: commonFilterData.selectedDateRange.endDate,
          },
          brand_name: JSON.stringify(commonFilterData.selectedBrand),
          brand_website: JSON.stringify(commonFilterData.selectedBrandWebsite),
        };
        dispatch(getPublishedCohortsFilter({ ...filtersPayload }));
        dispatch(getPublishedCohorts({ ...detailsPayload }));
        dispatch(
          getDraftedCohortFilters({
            status: "isDrafted",
            ...filtersPayload,
          })
        );
        fetchCohorts("isDrafted",filtersPayload,detailsPayload,setDraftedCohortsFilters)
        dispatch(
          getDraftedCohortFilters({
            ...filtersPayload,
            status: "isRecommended",
          })
        );
        fetchCohorts("isRecommended",filtersPayload,detailsPayload,setRecommendedCohortsFilters)
      }
    }
  }, [selectedAiGroup, selectedPlatform]);

  const callRelevantMethods = () => {
    if (selectedAiGroup && selectedPlatform === "facebook") {
      const filtersPayload = {
        aiGroupId: selectedAiGroup.id,
      };

      const detailsPayload = {
        aiGroupId: selectedAiGroup.id,
        date_range: {
          start: commonFilterData.selectedDateRange.startDate,
          end: commonFilterData.selectedDateRange.endDate,
        },
        brand_name: JSON.stringify(commonFilterData.selectedBrand),
        brand_website: JSON.stringify(commonFilterData.selectedBrandWebsite),
        type: commonFilterData.type,
      };
      if (selectedCohortType === SelectedCohortTypeEnum.live) {
        dispatch(getPublishedCohortsFilter(filtersPayload));
        dispatch(getPublishedCohorts(detailsPayload));
      } else if (selectedCohortType === SelectedCohortTypeEnum.drafted) {
        dispatch(
          getDraftedCohortFilters({ ...filtersPayload, status: "isDrafted",  })
        );
        dispatch(getDraftedCohorts({ ...detailsPayload, status: "isDrafted",             
          callback:({data},success)=>{
            audienceDataLoadCallback(data,success,setDraftedCohortsFilters)
          } 
        }));
      } else {
        dispatch(
          getDraftedCohortFilters({
            ...filtersPayload,
            status: "isRecommended",
          })
        );
        dispatch(
          getDraftedCohorts({ ...detailsPayload, status: "isRecommended", 
            callback:({data},success)=>{
              audienceDataLoadCallback(data,success,setRecommendedCohortsFilters)
            }
           })
        );
      }
    }
  };

  const types: any = {
    [SelectedCohortTypeEnum.live]: publishedCohortsData,
    [SelectedCohortTypeEnum.recommended]: recommendedCohortsData,
    [SelectedCohortTypeEnum.drafted]: draftedCohortsData,
  };

  const toggleCohortCheck = (data: any) => {
    if (checkedCohorts[selectedCohortType][data.id]) {
      const newCheckedCohorts = { ...checkedCohorts };
      delete newCheckedCohorts[selectedCohortType][data.id];
      setCheckedCohorts({ ...newCheckedCohorts });
    } else {
      setCheckedCohorts({
        ...checkedCohorts,
        [selectedCohortType]: {
          ...checkedCohorts[selectedCohortType],
          [data.id]: data,
        },
      });
    }
  };

  const toggleCohortCheckYt = (data: any) => {
    const selectedTab = YOUTUBE_SUB_TAB_VALUES[youtubeTab];
    if (ytCheckedCohorts[selectedTab][data.id]) {
      const newCheckedCohorts = { ...ytCheckedCohorts };
      delete newCheckedCohorts[selectedTab][data.id];
      setYtCheckedCohorts({ ...newCheckedCohorts });
    } else {
      setYtCheckedCohorts({
        ...ytCheckedCohorts,
        [selectedTab]: {
          ...ytCheckedCohorts[selectedTab],
          [data.id]: data,
        },
      });
    }
  };
  const handlePayloadForDrafted = (data?: any) => {
    const cohorts = Object.values(
      data ? data : checkedCohorts[selectedCohortType]
    ).map((cohort: any) => {
      const cohortConfigData = cohort.configuration;
      const cohortCalibrationData = cohort.configuration;
      if (cohort?.type === "ICP") {
        return {
          type: "ICP",
          cohort_name: cohort.cohort_name,
          cohort_id: cohort.cohort_id,
          audience_size: cohort.potential_audience_size,
          relevance_score: cohort.relevance_score,
          performance_score: cohort.performance_score,
          brandId: cohort?.brand_id,
          brand_name: cohortConfigData?.userInputBrandName || cohort.brand_name,
          brand_website:
            cohortConfigData?.userInputBrandWebsite || cohort.brand_website,
          explore: cohortCalibrationData.explore,
          exploit: cohortCalibrationData.exploit,
          targeting: {
            ...cohort?.icp_targeting,
            genders:
              cohort?.icp_targeting?.genders || cohort?.gender?.genders || [], // empty array signifies all
          },
        };
      } else {
        return {
          cohort_name: cohort.cohort_name,
          cohort_id: cohort.cohort_id,
          facebook_json: cohort.keywords,
          audience_size: cohort.potential_audience_size,
          relevance_score: cohort.relevance_score,
          interest_keywords: cohort.keywords.map(
            (keyword: { name: string }) => keyword.name
          ),
          brandId: cohort?.brand_id,
          brand_name: cohortConfigData?.userInputBrandName || cohort.brand_name,
          brand_website:
            cohortConfigData?.userInputBrandWebsite || cohort.brand_website,
          calibrationData: {
            explore: cohortCalibrationData.explore,
            exploit: cohortCalibrationData.exploit,
            intent: cohortCalibrationData.intent,
            traffic: cohortCalibrationData.traffic,
            brand_website_config: cohortCalibrationData.brand_website_config,
            competitor_webstie: cohortCalibrationData.competitor_website,
            custom_keywords: cohortCalibrationData.custom_keywords,
          },
        };
      }
    });
    if (cohorts && cohorts.length > 0) {
      dispatch(setSelectedCohortsForPublish(cohorts));
      setShowPublishAudienceManagerModal(true);
      data !== undefined
        ? setIsQuickPublishClicked(true)
        : setIsQuickPublishClicked(false);
      setIsDrawerOpen(false);
    }
  };
  const handleYotubeDelete = () => {
    if (selectedAiGroup) {
      dispatch(setYoutubeLoading(true));
      const selectedTab = YOUTUBE_SUB_TAB_VALUES[youtubeTab];
      dispatch(
        deleteYoutubeCohorts(
          {
            cohortIds: Object.keys(ytCheckedCohorts[selectedTab]),
            aiGroupId: selectedAiGroup.id,
          },
          () => {
            dispatch(
              getRecommendedCohorts({
                aiGroupId: selectedAiGroup?.id,
                type: selectedTab as
                  | "video"
                  | "audience"
                  | "channel"
                  | undefined,
              })
            );
            setYtCheckedCohorts({
              ...ytCheckedCohorts,
              [selectedTab]: {},
            });
          }
        )
      );
      setShowDeleteModal(false);
    }
  };

  const handleDelete = () => {
    if (selectedAiGroup) {
      setLoading({ [selectedCohortType]: true });
      dispatch(
        deleteTAIFavouriteCohort(
          {
            deleteFavouriteCohortIds: Object.keys(
              checkedCohorts[selectedCohortType]
            ),
            aiGroupId: selectedAiGroup.id,
          },
          () => {
            callRelevantMethods();
            setLoading({ [selectedCohortType]: false });
            setCheckedCohorts({ ...checkedCohorts, [selectedCohortType]: {} });
          }
        )
      );
      setShowDeleteModal(false);
    }
  };
  const handlePublishForLive = (selectedCohort: any) => {
    const adAccountId = selectedCohort.summary.ad_account_id;
    const adSetId = selectedCohort.summary.adset_id;
    handleAdsmanagerRedirection(adAccountId, adSetId);
  };

  const handleYtClick = (e: any, ref: any, data: any) => {
    if (e.target.classList.contains("cohort-card-checkbox")) {
      toggleCohortCheckYt(data);
      return;
    }
    for (let index = 0; index < ref.current.children.length; index++) {
      const element = ref.current.children[index];

      if (
        element.className === e.target.classList.value ||
        (element.className.baseVal &&
          element.className.baseVal === e.target.classList.value) ||
        (e.target.parentElement.className.baseVal &&
          element.className.baseVal &&
          e.target.parentElement.className.baseVal ===
            element.className.baseVal)
      ) {
        toggleCohortCheckYt(data);

        return;
      }
    }
    if (youtubeTab === 0 || youtubeTab === 1) handleYtCardClick(data);
  };

  const handleDraftedCohortClick = (e: any, ref: any, data: any) => {
    if (e.target.classList.contains("cohort-card-checkbox")) {
      toggleCohortCheck(data);
      return;
    }
    for (let index = 0; index < ref.current.children.length; index++) {
      const element = ref.current.children[index];

      if (
        element.className === e.target.classList.value ||
        (element.className.baseVal &&
          element.className.baseVal === e.target.classList.value) ||
        (e.target.parentElement.className.baseVal &&
          element.className.baseVal &&
          e.target.parentElement.className.baseVal ===
            element.className.baseVal)
      ) {
        toggleCohortCheck(data);

        return;
      }
    }
    handleCardClick(data);
  };
  const handlePublishedCohortClick = (e: any, data: any) => {
    if (
      e.target?.dataset?.testid === "OpenInNewOutlinedIcon" ||
      e.target?.parentElement?.dataset?.testid === "OpenInNewOutlinedIcon"
    ) {
      return;
    }

    handleCardClick(data);
  };
  let CardComponent: any;
  let cardDetails: any[] = [];
  if (selectedPlatform === "youtube") {
    if (YOUTUBE_SUB_TAB_VALUES[youtubeTab] === "video") {
      CardComponent = VideoCard;
      cardDetails = videoCohorts;
    } else if (YOUTUBE_SUB_TAB_VALUES[youtubeTab] === "channel") {
      CardComponent = ChannelCard;
      cardDetails = channelCohorts;
    } else if (YOUTUBE_SUB_TAB_VALUES[youtubeTab] === "audience") {
      CardComponent = AudienceCard;
      cardDetails = audienceCohorts;
    }
  }

  useEffect(() => {
    mixPanelAdEvent("Page View - Dashboard", {
      module_name: "TAI",
      sub_module_name: "Audience Manager",
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_name: selectedBrand?.agency_name,
      agency_id: selectedBrand?.agencyId,
      email: user?.email,
      username: user?.name,
    });
  }, []);

  const facebookTargetingEnumMapping = useFacebookTargetingEnumMapping();
  const [isFilterApplied,setIsFilterApplied] = useState<boolean>(false); 
  return (
    <>
      <Stack className="bg-white h-100 message-container audience-manager font-inter">
        {!isViewedFromBeta && (
          <Box sx={{ height: "9vh", py: 1.5 }}>
            <Header modalOpen={modalOpen} setModalOpen={setModalOpen} />
          </Box>
        )}
        <Box sx={{ height: selectedPlatform === "facebook" ? "15vh" : "12vh" }}>
          <Subheader 
               isFilterApplied={isFilterApplied}
               setIsFilterApplied={setIsFilterApplied}
          />
        </Box>
        {selectedPlatform === "youtube" && (
          <Box sx={{ height: "10vh", px: 2 }}>
            <SubTabs value={youtubeTab} handleChange={handleYoutubeTabChange} />
          </Box>
        )}
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          columns={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          px={2}
          sx={{
            overflowY:
              (isViewedFromBeta ? length[selectedCohortType] > 0 : length[selectedCohortType] > 4) ||
              isLoading[selectedCohortType] ||
              cardDetails.length > 4
                ? "scroll"
                : "none",
            height: selectedPlatform === "facebook" ? (isViewedFromBeta? "75vh" : "66vh") : "56vh",
            width: "10vh",
            mb: 0.5,
          }}
        >
          {(selectedPlatform === "facebook" && isLoading[selectedCohortType]) ||
          (selectedPlatform === "youtube" && isLoadingYoutube) ? (
            <>{getLoaderCards()}</>
          ) : (
            <>
              {selectedPlatform === "facebook" &&
                types[selectedCohortType].map((data: any) => {
                  const keywords = data.keywords
                    ? data.keywords.map(
                        (keyword: { name: string }) => keyword.name
                      )
                    : [];
                  return (
                    <>
                      {
                        <Grid item key={data.id}>
                          {selectedCohortType ===
                          SelectedCohortTypeEnum.live ? (
                            <Card
                              type={selectedCohortType}
                              metrics={[
                                data.spends ?? "-",
                                data.results ?? "-",
                                data.cpr ?? "-",
                              ]}
                              currency={data?.currency || null}
                              adAccountName={data.ad_account_name ?? "-"}
                              adAccountId={data.summary.ad_account_id}
                              adSetName={data.summary.adset_name ?? "-"}
                              adSetId={data.summary.adset_id}
                              publishedDate={data.published_date ?? "-"}
                              kwsArray={keywords.length > 0 ? keywords : "-"}
                              cohortType={data.cohort_type}
                              onClick={(e: any) => {
                                handlePublishedCohortClick(e, data);
                              }}
                              publishType={data.type}
                              icpTargettings={data.icp_targeting}
                              facebookTargetingEnumMapping={facebookTargetingEnumMapping}
                            />
                          ) : (
                            <Card
                              currency={data?.currency || null}
                              checked={
                                !!checkedCohorts[selectedCohortType][data.id]
                              }
                              type={selectedCohortType}
                              metrics={[
                                data.potential_audience_size ?? "-",
                                data.relevance_score ?? "-",
                              ]}
                              adSetName={data.cohort_name ?? "-"}
                              publishedDate={data.saved_on ?? "-"}
                              kwsArray={keywords.length > 0 ? keywords : "-"}
                              cohortType={data.cohort_type}
                              onClick={(e: any, ref: any) => {
                                handleDraftedCohortClick(e, ref, data);
                              }}
                              publishType={data.type}
                              icpTargettings={data.icp_targeting}
                              facebookTargetingEnumMapping={facebookTargetingEnumMapping}
                            />
                          )}
                        </Grid>
                      }
                    </>
                  );
                })}
              {selectedPlatform === "youtube" &&
                cardDetails.map(
                  ({ id, data, updatedAt, aiGroupId }: any, index) => {
                    if (data.type) {
                      data.dataType = data.type;
                      delete data.type;
                    }
                    const props = {
                      ...data,
                      id,
                      updatedAt,
                      aiGroupId,
                      checked:
                        !!ytCheckedCohorts[YOUTUBE_SUB_TAB_VALUES[youtubeTab]][
                          id
                        ],
                      onClick: (e: any, ref: any) =>
                        handleYtClick(e, ref, cardDetails[index]),
                    };
                    return (
                      <Grid item key={id}>
                        <CardComponent {...props} />
                      </Grid>
                    );
                  }
                )}
            </>
          )}

          {selectedPlatform === "facebook" &&
            selectedCohortType &&
            length[selectedCohortType] === 0 &&
            !isLoading[selectedCohortType] && (
              <Box className="d-flex justify-content-center align-items-center w-100">
                <NoDataScreen selectedCohortType={selectedCohortType} />
              </Box>
            )}
          {selectedPlatform === "youtube" &&
            !isLoadingYoutube &&
            cardDetails.length === 0 && (
              <Box className="d-flex justify-content-center align-items-center w-100">
                <NoDataScreenYt />
              </Box>
            )}
        </Grid>
        <CustomDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          type={selectedCohortType}
          selectedCohort={selectedCohort}
          handleButtonClick={() => {
            selectedCohortType === SelectedCohortTypeEnum.live
              ? handlePublishForLive(selectedCohort)
              : handlePayloadForDrafted({
                  [selectedCohort.id]: { ...selectedCohort },
                });
          }}
        />

        {isYtDrawerOpen && (
          <YtDrawer
            isOpen={isYtDrawerOpen}
            setIsOpen={setIsYtDrawerOpen}
            type={YOUTUBE_SUB_TAB_VALUES[youtubeTab]}
            selectedCohort={selectedYtCohort}
          />
        )}
        {((selectedPlatform === "facebook" && length[selectedCohortType] > 0) ||
          (selectedPlatform === "youtube" && cardDetails.length > 0)) && (
          <Box sx={{ height: "7vh", pb: 2 }}>
            <Footer
              variant={
                selectedPlatform === "facebook"
                  ? selectedCohortType
                  : YOUTUBE_SUB_TAB_VALUES[youtubeTab]
              }
              selectedCohortsLength={
                selectedPlatform === "facebook"
                  ? Object.keys(checkedCohorts[selectedCohortType]).length
                  : Object.keys(
                      ytCheckedCohorts[YOUTUBE_SUB_TAB_VALUES[youtubeTab]]
                    ).length
              }
              totalCohortsLength={
                selectedPlatform === "facebook"
                  ? length[selectedCohortType]
                  : cardDetails.length
              }
              onDeleteClick={() => setShowDeleteModal(true)}
              onPublishClick={() => handlePayloadForDrafted()}
            />
          </Box>
        )}
        {showDeleteModal && <StayHereModal {...deleteModalProps} />}
        {showPublishAudienceManagerModal && (
          <PublishModal
            opendFrom="audienceManager"
            setShowPublishAudienceManagerModal={(isShowModal: any) => {
              setShowPublishAudienceManagerModal(isShowModal);
              return undefined;
            }}
            setShowAudiencePublishSuccessModal={(isShow) => {
              setShowAudiencePublishSuccessModal(isShow);
              return undefined;
            }}
            setIsQuickPublishClicked={(isClicked) => {
              setIsQuickPublishClicked(isClicked);
              return undefined;
            }}
            showPublishAudienceManagerModal={showPublishAudienceManagerModal}
            deleteCohortIds={
              isQuickPublishClicked && selectedCohort
                ? [selectedCohort.id]
                : Object.keys(checkedCohorts[selectedCohortType])
            }
            handleSuccessResponse={() => {
              const selectIds =
                isQuickPublishClicked && selectedCohort
                  ? [selectedCohort.id]
                  : Object.keys(checkedCohorts[selectedCohortType]);
              const newCohortsData = [...types[selectedCohortType]];
              const newCheckedCohorts = {
                ...checkedCohorts[selectedCohortType],
              };
              selectIds.forEach((id: any) => {
                delete newCheckedCohorts[id];
              });
              const methodToCall =
                selectedCohortType === SelectedCohortTypeEnum.drafted
                  ? setDraftedCohorts
                  : setRecommendedCohorts;
              dispatch(
                methodToCall(
                  newCohortsData.filter(
                    (cohort) => !selectIds.includes(cohort.id)
                  )
                )
              );
              dispatch(
                setBucketLength({
                  type: selectedCohortType,
                  length: length[selectedCohortType] - selectIds.length,
                })
              );
              setIsQuickPublishClicked(false);
              setCheckedCohorts({
                ...checkedCohorts,
                [selectedCohortType]: newCheckedCohorts,
              });
              return undefined;
            }}
          />
        )}
        {showAudiencePublishSuccessModal && (
          <AudienceManagerSuccessModal {...successModalProps} />
        )}
        {modalOpen && (
          <FileDropModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onFileUpload={handleFileUpload}
          />
        )}
      </Stack>
      {errorModalOpen && (
        <ErrorModal
          buttonClickHandler={() => {
            setModalOpen(true);
            setErrorModalOpen(false);
          }}
          setModalOpen={setErrorModalOpen}
        />
      )}
    </>
  );
};

export default AudienceManagerAI;