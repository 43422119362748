import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

interface TooltipPropsLocal extends TooltipProps {
  bgColor?: string;
}
export const LightTooltip = styled(
  ({ className, bgColor, ...props }: TooltipPropsLocal) => {
    const { title, children } = props;
    if (!title) return children;
    return <Tooltip {...props} classes={{ popper: className }} />;
  }
)(({ theme, bgColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bgColor ?? "#EBEBEB", //can be made into a prop
    color: "#242424",
    boxShadow: theme.shadows[1],
    fontSize: "0.75em",
    padding: "0.375em 0.75em",
    // maxWidth: "none", // to fixed the width
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      backgroundColor: bgColor ?? "#F5F5F5",
      boxShadow: theme.shadows[1],
    },
  },
}));
