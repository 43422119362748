import React, { Fragment, useRef, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import RadioGroup from "@mui/material/RadioGroup";
import {
  Autocomplete,
  Box,
  Fade,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  Skeleton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { LightTooltip } from "components/CreativeAI_Revamp/shared";
import Stack from "@mui/material/Stack";
import CheckBox from "ui/CheckBox/CheckBox";
import { Button as MuiButton } from "@mui/material";
import {
  ActionButton,
  ActiveActionButton,
  ArrowDown,
  Publish,
  SearchItem,
} from "./styled";
import { ReactComponent as ViewMore } from "assets/svg/view_more.svg";
import SearchIcon from "../../assets/svg/searchIcon.svg";
import { ReactComponent as CloseSvg } from "../../assets/svg/CloseSvgNew.svg";
import { ReactComponent as InfoSvg } from "../../assets/svg/InfoNew.svg";
import BackButton from "./BackButton";
import xorBy from "lodash/xorBy";
import { useSelector } from "react-redux";
import { AdAccountState } from "store/types/adaccount";
import {
  getRolesHierarchy,
  isEditorRole,
  isViewerRole,
} from "utils/commonFunctions";
import { RolesWrapper } from "utils/rolesWrapper";

const StyledSelect = styled(Select)({
  borderRadius: ".5em",
  color: "inherit",
  fontSize: "1em",

  "& legend": { display: "none" },
  "& fieldset": { top: 0 },
  "&[data-error='true']": {
    color: "#C8CED6",
  },
  "& .MuiSelect-select": {
    padding: ".75em 1em",
  },
});

const StyledOutlinedInput = styled(OutlinedInput)({
  borderRadius: ".5em",
  color: "inherit",
  fontSize: "1em",
});

const StyledAutoComplete = styled(Autocomplete<any>)({
  "& .MuiInputBase-root": {
    borderRadius: ".5em",
    color: "inherit",
    fontSize: "1em",
    padding: 0,
  },

  "& legend": { display: "none" },
  "& fieldset": { top: 0 },
  "&[data-error='true']": {
    color: "#C8CED6",
  },
  "&  .MuiInputBase-root .MuiInputBase-input": {
    padding: ".75em 1em",
  },
});

const StyledTextField = styled(TextField)({
  fontSize: "1em",
});

const StyledMenuItem = styled(MenuItem)({
  fontSize: "1em",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "&.padded": { padding: "1em" },
  "&[data-selected='true']": {
    background: "var(--color-palette-blue-50, #E3EEFF)",
  },
});

const ROOT_FONT_SIZE = "14px";
const SEARCH_DEBOUNCE_DELAY = 700;

const loaderSkeleton = [...Array(2).keys()]
  .map((key) => ({ loader: true, key }))
  .map(({ key }) => (
    <Fragment key={key}>
      <Skeleton variant="rectangular" height={50} />
      <Skeleton animation="wave" />
      <Skeleton sx={{ marginBottom: ".625em" }} animation={false} />
    </Fragment>
  ));

const TRUNCATE_TEXT_LENGTH = 40;

const SingleBulkActionModal = ({
  handleClose,
  level,
  errors,
  register,
  handleChange,
  levelsOptions,
  levelOptionSelected,
  searchValue = "",
  handleCampaignSearch,
  isReadMore,
  selectedItem,
  setselectedItem,
  textTruncateMore,
  platform,
  isEmpty,
  wrapperRef,
  openSearchAiData,
  openSearchAiDataState,
  getVariable,
  CampaignInfoTooltip,
  toggleReadMore,
  actionButtons,
  BootstrapTooltip,
  handleActionButton,
  tooltipClasses,
  actionActionButton,
  budgetAction,
  budgetActionType,
  budgetActionValueAbsolute,
  setBudgetActionValueAbsolute,
  getCurrencySymbol,
  accountDetails,
  budgetActionValuePercent,
  setBudgetActionValuePercent,
  changeStatus,
  actionBasis,
  setActionBasis,
  confidenceScore,
  setConfidenceScore,
  submitManualActionState,
  isBrandViewer,
  brandViewerAccessText,
  setConfirmModal,
  setExecutionText,
  validValues,
  onBack,
}: any) => {
  const [_searchTerm, _setSearchTerm] = useState(searchValue);
  const [isTyping, setIsTypeing] = useState(false);
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  const onSearchStringChange = (value: string) => {
    setIsTypeing(true);
    _setSearchTerm(value);
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      setIsTypeing(false);
      handleCampaignSearch(value);
    }, SEARCH_DEBOUNCE_DELAY);
  };

  const renderOption = (option: any) => (
    <>
      <Typography
        sx={{
          fontSize: "1em",
          width: "100%",
          lineHeight: ".9375em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span title={option.name}>
            {textTruncateMore(option.name, TRUNCATE_TEXT_LENGTH)}
          </span>
          <span>{option.name_id}</span>
        </Box>
      </Typography>
      {option.adset && (
        <Typography
          sx={{
            fontSize: ".875em",
            width: "100%",
            color: "#7a7a7a",
            lineHeight: ".9075em",
            marginTop: ".5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span title={option.adset}>
              {platform === "google" ? "AdGroup" : "Adset"}:{" "}
              {textTruncateMore(option.adset, TRUNCATE_TEXT_LENGTH)}
            </span>
            <span>{option.adset_id}</span>
          </Box>
        </Typography>
      )}
      {option.campaign && (
        <Typography
          sx={{
            fontSize: ".875em",
            width: "100%",
            color: "#7a7a7a",
            lineHeight: ".9075em",
            marginTop: ".5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span title={option.campaign}>
              Campaign:{" "}
              {textTruncateMore(option.campaign, TRUNCATE_TEXT_LENGTH)}
            </span>
            <span>{option.campaign_id}</span>
          </Box>
        </Typography>
      )}
    </>
  );

  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;

  return (
    <div
      style={{
        width: "42.5em",
        background: "white",
        padding: "1.5em",
        borderRadius: ".75em",
        fontSize: ROOT_FONT_SIZE,
      }}
    >
      <div
        className="header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h1 style={{ fontSize: "1.25em", fontWeight: "600" }}>
          Single/Bulk Actions{" "}
        </h1>
        <CloseSvg style={{ cursor: "pointer" }} onClick={handleClose} />
      </div>
      <p
        style={{
          fontSize: ".875em",
          fontWeight: "400",
          color: "#5C6674",
          width: "95%",
        }}
      >
        Select the Level and type of Action Manually and Execute them in all
        your Assets present inside this{" "}
        <span style={{ fontWeight: "600" }}>Platform</span> and{" "}
        <span style={{ fontWeight: "600" }}>AI group</span>
      </p>
      <div style={{ maxHeight: "65vh", overflow: "auto" }}>
        <InputLabel
          sx={{
            color: "#000",
            fontSize: ".875em",
            fontWeight: "400",
            fontFamily: "Inter",
            marginbottom: "0.5em",
          }}
          id="demo-simple-select-label"
        >
          Level
          <CampaignInfoTooltip title="Select the Level at which you want to take Actions">
            <InfoSvg
              width="1em"
              height="1em"
              style={{ marginLeft: ".375em", cursor: "pointer" }}
            />
          </CampaignInfoTooltip>
        </InputLabel>
        <FormControl
          fullWidth
          sx={{
            marginBottom: "1.25em",
            borderRadius: ".5em",
            outline: "none",
            boxShadow: "none",
          }}
        >
          <StyledSelect
            placeholder="select"
            labelId="select level"
            id="selectLevel"
            {...register("selectLevel", {
              required: "Please Select Level", // JS only: <p>error message</p> TS only support string
            })}
            name="selectLevel"
            value={level}
            onChange={(e) => handleChange(e)}
            displayEmpty
            renderValue={level !== "" ? undefined : () => "Select Level"}
            label=" "
            data-error={!level}
            MenuProps={{
              style: { fontSize: ROOT_FONT_SIZE },
            }}
          >
            {levelsOptions?.map((level: any) => {
              return (
                <StyledMenuItem value={level.value} key={level.value}>
                  {level.name}
                </StyledMenuItem>
              );
            })}
          </StyledSelect>

          {errors.selectLevel && (
            <Alert
              sx={{ marginTop: ".3125em" }}
              severity="error"
              variant="standard"
            >
              {errors?.selectLevel?.message}
            </Alert>
          )}
        </FormControl>
        {level ? (
          <>
            <InputLabel
              sx={{
                color: "#000",
                fontSize: ".875em",
                fontWeight: "400",
                fontFamily: "Inter",
              }}
              id="demo-simple-select-label"
            >
              Select {levelOptionSelected?.name}
              <CampaignInfoTooltip title="Select the Assets for which you want to take the Actions. You can select multiple Assets at a time">
                <InfoSvg
                  width="1em"
                  height="1em"
                  style={{ marginLeft: ".375em", cursor: "pointer" }}
                />
              </CampaignInfoTooltip>
            </InputLabel>
            <FormControl
              fullWidth
              sx={{
                marginBottom: "1.25em",
                borderRadius: ".5em",
                outline: "none",
                boxShadow: "none",
              }}
            >
              <StyledAutoComplete
                options={openSearchAiData[`${getVariable()}`] || []}
                getOptionLabel={(option) => option.name}
                noOptionsText={"No Data Available..."}
                loading={openSearchAiDataState?.loading || isTyping}
                loadingText={loaderSkeleton}
                inputValue={_searchTerm}
                onInputChange={(_e, value) => onSearchStringChange(value)}
                filterOptions={(options, state) => {
                  return options.filter(
                    (option: any) =>
                      option.name
                        ?.toLowerCase()
                        .includes(state.inputValue.toLowerCase()) ||
                      option.name_id
                        ?.toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderOption={(props, option, state) => {
                  return (
                    <StyledMenuItem
                      onClick={() => {
                        setselectedItem((items: any) => xorBy(
                          [option],
                          items,
                          (value) => `${value.name_id}:${value.adset_id}:${value.campaign_id}`)
                        );
                      }}
                      data-selected={selectedItem.some((item: any) => (
                        item.name_id === option.name_id &&
                        item.adset_id === option.adset_id &&
                        item.campaign_id === option.campaign_id
                      ))}
                      key={`${option.name}_${option.name_id}_${props.id}`}
                      id={props.id}
                      className="padded"
                    >
                      {renderOption(option)}
                    </StyledMenuItem>
                  );
                }}
                renderInput={(params) => (
                  <StyledTextField {...params} placeholder="Search..." />
                )}
              />
            </FormControl>
            <Stack
              sx={{
                width: "100%",
                marginTop: "1.25em",
                maxHeight: isReadMore ? "16.875em" : "5.625em",
                overflow: isReadMore ? "auto" : "hidden",
              }}
              spacing={2}
            >
              {selectedItem.length
                ? selectedItem.map((eachItem: any) => {
                    return (
                      <Alert
                        title={
                          (eachItem.primary_text && eachItem.primary_text) ||
                          (eachItem.secondary_text &&
                            eachItem.secondary_text) ||
                          (eachItem.ternery_text && eachItem.ternery_text)
                        }
                        sx={{
                          backgroundColor: "#F2E5FD",
                          color: "#000",
                          borderRadius: ".5em",
                          "& .MuiAlert-message": {
                            width: "100% !important",
                          },
                        }}
                        variant="filled"
                        icon={false}
                        onClose={() => {
                          if (
                            selectedItem.filter(
                              (item: any) => item.name_id === eachItem.name_id
                            ).length > 0
                          ) {
                            setselectedItem((item: any) =>
                              item.filter(
                                (av: any) => av.name_id !== eachItem.name_id
                              )
                            );
                          }
                        }}
                      >
                        {renderOption(eachItem)}
                      </Alert>
                    );
                  })
                : ""}
            </Stack>
          </>
        ) : null}
        <Box>
          <input
            type="hidden"
            value={selectedItem}
            {...register("selectedItem", {
              required: `Please select one of the ${level}`,
            })}
          />
          {errors.selectLevel && (
            <Alert
              sx={{ marginTop: ".3125em" }}
              severity="error"
              variant="standard"
            >
              {errors?.selectedItem?.message}
            </Alert>
          )}
        </Box>
        {selectedItem.length > 1 ? (
          <span
            onClick={toggleReadMore}
            className="read-or-hide"
            style={{
              zIndex: "9",
              fontSize: ".75em",
              lineHeight: "1em",
              color: "#0869FB",
              cursor: "pointer",
            }}
          >
            {!isReadMore ? (
              <>
                <ViewMore /> View {selectedItem.length - 1} more
              </>
            ) : (
              <>
                <ViewMore
                  style={{
                    rotate: "180deg",
                  }}
                />{" "}
                View less
              </>
            )}
          </span>
        ) : (
          ""
        )}

        {level && !isEmpty(selectedItem) && (
          <Box
            sx={{
              marginBottom: "1.25em",
              minWidth: "25em",
              border: ".0625em solid #ECEEF1",
              borderRadius: ".375em",
              padding: "1em",
              marginTop: "1.25em",
            }}
          >
            <Box>
              <InputLabel
                sx={{
                  color: "#000",
                  fontSize: ".875em",
                  fontWeight: "400",
                  fontFamily: "Inter",
                  marginBottom: "1em",
                }}
                id="demo-simple-select-label"
              >
                Select Action
                <CampaignInfoTooltip title="Choose the Type of Action and its Value">
                  <InfoSvg
                    width="1em"
                    height="1em"
                    style={{ marginLeft: ".375em", cursor: "pointer" }}
                  />
                </CampaignInfoTooltip>
              </InputLabel>
              <Box
                style={{
                  display: "flex",
                  gap: "1.5em",
                  // justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {actionButtons.map((v: any, i: any) => {
                  const content = (
                    <p
                      // disabled={!v.show}
                      onClick={() => {
                        if (v.show) {
                          handleActionButton(v?.id);
                        } else {
                          return;
                        }
                      }}
                      style={{
                        fontSize: "0.875em",
                        fontWeight: v.selected ? "600" : "400",
                        cursor: "pointer",
                        color: v.selected
                          ? "#0869FB"
                          : v.show
                          ? "#000"
                          : "#E0E3E5",
                        marginBottom: "0.5em",
                      }}
                    >
                      {v.label}
                    </p>
                  );
                  return (
                    <div style={{ position: "relative" }}>
                      {v.show ? (
                        content
                      ) : (
                        <CampaignInfoTooltip
                          placement="top"
                          title="This action is not allowed"
                        >
                          {content}
                        </CampaignInfoTooltip>
                      )}
                      {v.selected && (
                        <div
                          style={{
                            width: "100%",
                            height: ".1875em",
                            background: "blue",
                            margin: "0",
                            position: "absolute",
                            top: "1.8125em",
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </Box>
              <div
                style={{
                  width: "100%",
                  height: ".125em",
                  background: "#ECEEF1",
                  margin: "0",
                }}
              />
            </Box>
            {!isEmpty(selectedItem) && actionActionButton && (
              <Box
                sx={{
                  padding: "1.5em 0",
                  // backgroundColor: "#EDF3FE",
                  // border: ".0625em solid #0869FB",
                  // bordergaRadius: ".5em",
                  // height: "4.875em",
                  display: "flex",
                  gap: "0.75em",
                  alignItems: "center",
                }}
              >
                {actionButtons[0]?.selected || actionButtons[1]?.selected ? (
                  <>
                    <FormControl sx={{ width: "11.93em" }}>
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <StyledSelect
                        // defaultOpen
                        labelId="demo-simple-select-autowidth-label"
                        name="budgetAction"
                        value={budgetAction}
                        onChange={handleChange}
                        label=" "
                        sx={{
                          border: 0,
                          outline: 0,
                          height: "2.8125em",
                          borderRadius: ".5em",
                          backgroundColor: "#fff",
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                      >
                        {!(level === "keyword") && (
                          <MenuItem value="changeByValue">
                            Change By Value
                          </MenuItem>
                        )}
                        {!(level === "keyword") && (
                          <MenuItem value="changeByPercent">
                            Change By %
                          </MenuItem>
                        )}
                        <MenuItem value="setValue">Set Value</MenuItem>
                      </StyledSelect>
                    </FormControl>
                    {budgetAction === "changeByPercent" ||
                    budgetAction === "changeByValue" ? (
                      <FormControl sx={{ width: "11.93em" }}>
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <StyledSelect
                          // defaultOpen
                          labelId="demo-simple-select-autowidth-label"
                          name="budgetActionType"
                          value={budgetActionType}
                          onChange={handleChange}
                          label=" "
                          sx={{
                            backgroundColor: "#fff",
                            border: 0,
                            height: "2.8125em",
                            outline: 0,
                            borderRadius: ".5em",
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                        >
                          <MenuItem value="increase">Increase</MenuItem>
                          <MenuItem value="decrease">Decrease</MenuItem>
                        </StyledSelect>
                      </FormControl>
                    ) : (
                      ""
                    )}
                    {budgetAction === "setValue" ||
                    budgetAction === "changeByValue" ? (
                      <FormControl sx={{ width: "11.93em", margin: "0" }}>
                        <StyledOutlinedInput
                          type="number"
                          sx={{
                            height: "2.8125em",
                            backgroundColor: "#fff",
                            borderRadius: ".5em",
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          id="outlined-adornment-amount"
                          value={budgetActionValueAbsolute}
                          required={true}
                          onChange={(e) =>
                            setBudgetActionValueAbsolute(e.target.value)
                          }
                          startAdornment={
                            <InputAdornment position="start">
                              {`${
                                getCurrencySymbol(accountDetails?.currency) ||
                                ""
                              }`}
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    ) : (
                      ""
                    )}

                    {budgetAction === "changeByPercent" && (
                      <FormControl sx={{ width: "11.93em", margin: "0" }}>
                        <StyledOutlinedInput
                          type="number"
                          sx={{
                            backgroundColor: "#fff",
                            borderRadius: ".5em",
                            height: "2.8125em",
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          id="outlined-adornment-amount"
                          value={budgetActionValuePercent}
                          onChange={(e) =>
                            setBudgetActionValuePercent(e.target.value)
                          }
                          // startAdornment={
                          //   <InputAdornment position="end">%</InputAdornment>
                          // }
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                        />
                      </FormControl>
                    )}
                  </>
                ) : (
                  ""
                )}

                {actionButtons[2]?.selected ? (
                  <FormControl
                    sx={{
                      position: "relative",
                      left: "1.5625em",
                      top: "0.5em",
                    }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="changeStatus"
                      value={changeStatus}
                      onChange={handleChange}
                      sx={{ gap: "1.5em" }}
                    >
                      <FormControlLabel
                        sx={{
                          color: changeStatus === "start" ? "#0869FB" : "#000",
                          fontSize: "0.875em",
                          fontWeight: "400",
                        }}
                        value="start"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": { color: "#0869FB" },
                              color:
                                changeStatus === "start"
                                  ? "#0869FB"
                                  : "#C8CED7",
                              height: "1.25em",
                              width: "1.25em",
                              marginRight: "0.62em",
                            }}
                          />
                        }
                        label="Start"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        sx={{
                          color: changeStatus === "pause" ? "#0869FB" : "#000",
                          fontSize: "0.875em",
                          fontWeight: "400",
                        }}
                        value="pause"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": { color: "#0869FB" },
                              color:
                                changeStatus === "pause"
                                  ? "#0869FB"
                                  : "#C8CED7",
                              height: "1.25em",
                              width: "1.25em",
                              marginRight: "0.62em",
                            }}
                          />
                        }
                        label="Pause"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                ) : (
                  ""
                )}
              </Box>
            )}
          </Box>
        )}

        {level && !isEmpty(selectedItem) && (
          <>
            <Box sx={{ mt: 2 }}>
              <InputLabel
                sx={{
                  color: "#000",
                  fontSize: ".875em",
                  fontWeight: "400",
                  fontFamily: "Inter",
                }}
                id="demo-simple-select-label"
              >
                Action basis
                <CampaignInfoTooltip title="You can add the reason behind taking Actions. These will be reflected in the Action Recommendation section on the Dashboard">
                  <InfoSvg
                    width="1em"
                    height="1em"
                    style={{ marginLeft: ".375em", cursor: "pointer" }}
                  />
                </CampaignInfoTooltip>
              </InputLabel>
              <FormControl sx={{ minWidth: 120, width: "100%" }}>
                <StyledOutlinedInput
                  type="text"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: ".5em",
                    height: "2.8125em",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  id="outlined-adornment-amount"
                  value={actionBasis}
                  onChange={(e) => setActionBasis(e.target.value)}
                />
              </FormControl>
            </Box>
            <Box sx={{ mt: 2 }}>
              <InputLabel
                sx={{
                  color: "#000",
                  fontSize: ".875em",
                  fontWeight: "400",
                  fontFamily: "Inter",
                }}
                id="demo-simple-select-label"
              >
                Confidence Score
                <CampaignInfoTooltip title="You can add the Confidence Score for the Actions. These will be reflected in the Action Recommendation section on the Dashboard">
                  <InfoSvg
                    width="1em"
                    height="1em"
                    style={{ marginLeft: ".375em", cursor: "pointer" }}
                  />
                </CampaignInfoTooltip>
              </InputLabel>
              <FormControl sx={{ minWidth: 120, width: "100%" }}>
                <StyledOutlinedInput
                  type="number"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: ".5em",
                    height: "2.8125em",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  id="outlined-adornment-amount"
                  value={confidenceScore}
                  onChange={(e) => setConfidenceScore(e.target.value)}
                />
              </FormControl>
            </Box>
          </>
        )}
      </div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "1.5em .9375em 0 .9375em",
          gap: "0.8em",
        }}
      >
        {submitManualActionState?.loading === false &&
          submitManualActionState?.success === true && (
            <Box sx={{ fontSize: "0.9em", color: "#0869FB" }}>
              Recommendation Published. Please visit “Action Recommendation” to
              track status.
            </Box>
          )}
        {submitManualActionState?.loading === false &&
          submitManualActionState?.success === false && (
            <Box sx={{ fontSize: "0.9em", color: "#fb0863" }}>
              Something went wrong. Please try again after sometime.
            </Box>
          )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {onBack ? <BackButton onClick={onBack} /> : <div />}
          {/* <LightTooltip
            title={isBrandViewer ? brandViewerAccessText : ""}
            sx={{ width: "14.375em" }}
            placement="top-end"
          > */}
          <Stack direction={"row"}>
            <RolesWrapper disabled={getRolesHierarchy(brandRoles) === "viewer"}>
              <MuiButton
                variant="outlined"
                sx={{
                  height: "2.5em",
                  textTransform: "capitalize",
                  mr: 1.5,
                  color: "#394455",
                  borderRadius: ".5em",
                  borderColor: "#E0E3E5",
                  "&:hover": {
                    boxShadow: "none",
                    borderColor: "#E0E3E5",
                  },
                }}
                onClick={() => {
                  // if (!isViewerRole(brandRoles)) {
                  setConfirmModal(true);
                  setExecutionText("Recommend");
                  // }
                }}
                // disabled={isViewerRole(brandRoles)}
              >
                Recommend
              </MuiButton>
            </RolesWrapper>
            <RolesWrapper
              disabled={
                getRolesHierarchy(brandRoles) === "viewer" ||
                getRolesHierarchy(brandRoles) === "editor"
              }
            >
              <Publish
                disabled={
                  validValues ||
                  // isViewerRole(brandRoles) ||
                  // isEditorRole(brandRoles) ||
                  isEmpty(selectedItem) ||
                  submitManualActionState?.loading
                }
                variant="contained"
                onClick={() => {
                  // if (!isBrandViewer || !isEditorRole(brandRoles)) {
                  setConfirmModal(true);
                  setExecutionText("Execute");
                  // }
                }}
                sx={{
                  height: "2.5em",
                  "&:hover": {
                    backgroundColor: "#0869fb",
                  },
                  margin: "auto",
                  // cursor: isBrandViewer ? "not-allowed !important " : "pointer",
                  cursor: "pointer",
                }}
                disableElevation
                style={{ margin: "0em" }}
              >
                Execute
              </Publish>
            </RolesWrapper>
          </Stack>
          {/* </LightTooltip> */}
        </Box>
      </Box>
    </div>
  );
};

export default SingleBulkActionModal;
