import { ReactComponent as CreativeAIOnBoarding1 } from "../../assets/svg/creativeAIOnBoarding.svg";
import { ReactComponent as CreativeAIOnBoarding2 } from "../../assets/svg/creativeAIOnBoarding2.svg";
import { ReactComponent as Frame1 } from "../../assets/svg/googleframe1.svg";
import { ReactComponent as Frame2 } from "../../assets/svg/googleframe2.svg";
import { ReactComponent as Frame3 } from "../../assets/svg/googleframe3.svg";
import { ReactComponent as OnBoardIcon1 } from "../../assets/svg/onboard1.svg";
import { ReactComponent as OnBoardIcon2 } from "../../assets/svg/onboard2.svg";
import OnBoard3 from "../../assets/svg/onboard3.png";

export const CreativeAIOnBoarding = [
  {
    title: "Transform your Brand from Generalized to Personalized",
    description: `Automate delivery of hyper-personalised Ads to improve campaign outcomes using Artificial Intelligence`,
    icon: <CreativeAIOnBoarding1 width="40vw" height="40vh" />,
  },
  {
    title: "Scale your campaigns with an AI powered Creative Library",
    description: `Create alternate versions by tweaking your ads without any dependencies on the designer. After all, variety is the spice of life (and marketing!)`,
    icon: <CreativeAIOnBoarding2 width="40vw" height="40vh" />,
  },
];

export const termsAndCondition =
  "https://qa-fb-groups.pyxispm.com/admin/t_c_-_aiquire__agency_model_.pdf";

export const tableData = {
  group: {
    header: ["AI Group Name", "No. of Ad Sets"],
  },
  adset: {
    header: ["", "Ad Set Name", "Ad Set ID", " "],
  },
};

export const onBoardingData = [
  {
    title: "Find your niche market",
    description: `Say goodbye to the cookie-cutter template and discover your target audience with powerful <br>
    analytics and Artificial Intelligence.`,
    icon: <OnBoardIcon1 width="40vw" height="40vh" />,
  },
  {
    title: "Multiply your reach",
    description: `Beat your competitors at the 'numbers' game by targeting specific segments and exploring <br>
    complementary industry-related audiences.`,
    icon: <OnBoardIcon2 width="40vw" height="40vh" />,
  },
  {
    title: "Reduce your Cost per Result",
    description: `Stop making the "tight" marketing budget excuse and start executing campaigns that deliver results.`,
    icon: (
      <img
        alt=""
        src={OnBoard3}
        style={{ width: "40vw", height: "40vh", objectFit: "contain" }}
      />
    ),
  },
];

export const onBoardingDataGoogle = [
  {
    title: "Use Artificial Intelligence to execute your strategy",
    description: `Share insights from your primary research, then sit back and watch the algorithm do its magic`,
    icon: <Frame1 width="40vw" height="40vh" />,
  },
  {
    title: "Bid on multiple related keywords",
    description: `Optimize your ad spend by bidding on keyword clusters, made of similar or related phrases, using industry data and audience behavior`,
    icon: <Frame2 width="40vw" height="40vh" />,
  },
  {
    title: "Create ads that are too good to skip",
    description: `Harness the power of machine learning to create hyper-personalized ad copies that deliver results `,
    icon: <Frame3 width="40vw" height="40vh" />,
  },
];

export const user_session_key = "p_user_session";
export const tms_skip_brand_name = "p_user_session_tms_brand_name";

export const commonAuth =
  "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Imw1Nk9PX3EtblRqRmRPYWtvczRBWSJ9.eyJpc3MiOiJodHRwczovL2Nyb3NzLXBsYXRmb3JtLXFhLnVzLmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw2MjE3NTI3N2UyYjE3ODAwNmFjNzgwZTQiLCJhdWQiOlsiaHR0cHM6Ly9jcm9zcy1wbGF0Zm9ybS1xYS51cy5hdXRoMC5jb20vYXBpL3YyLyIsImh0dHBzOi8vY3Jvc3MtcGxhdGZvcm0tcWEudXMuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTY0NTY5NjQ3MCwiZXhwIjoxNjQ1NzgyODcwLCJhenAiOiJYU1NTZ1pycjRuTUIyYmFsYkh5SlpnQ3BQOHUxdGM3MCIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwgb2ZmbGluZV9hY2Nlc3MifQ.JX4YerYjD4Ym4nOyzGTqlSTTJkiEotzVLFYmar3Iv7COqVNLo7TIJWKUmkrwCdw-m3znOW81IzSseZRoSVkuUYbUqILwiLyfOtCwtVVgJQwuJS0u-wPBswiJhXXsGG2xO4sbP6T3LNKrf1qEJ3LfC9Y8Zl8MFIQKom-tLD2Op9W_fkZMGGI4LauZiXVKeaBJrocgZM6BuN--X0iw16MuV5Tfhvol4zPuJ-uLg_40nU_6SSc7qzhWTOfl3eTLgZPdFwFGdj3Lrc405eqakp6w4Z7TDFlM6uzkBMck6j9Y92noh08ZJa7-MKa_OR-2YwzNemHJRgHckVzsVhgBfVGqIQ";

export const FACEBOOK_PLATFORM_NAME = "FACEBOOK AD";
export const GOOGLE_SEARCH_PLATFORM_NAME = "GOOGLE SEARCH AD";
export const GOOGLE_VIDEO_PLATFORM_NAME = "GOOGLE VIDEO AD";
export const GOOGLE_DISPLAY_PLATFORM_NAME = "GOOGLE DISPLAY AD";
export const GOOGLE_SHOPPING_PLATFORM_NAME = "GOOGLE SHOPPING AD";
export const GOOGLE_DISCOVERY_PLATFORM_NAME = "GOOGLE DISCOVERY AD";
export const GOOGLE_APP_PLATFORM_NAME = "GOOGLE APP AD";
export const GOOGLE_SMART_PLATFORM_NAME = "GOOGLE SMART AD";
export const GOOGLE_LOCAL_PLATFORM_NAME = "GOOGLE LOCAL AD";
export const GOOGLE_PERFORMANCE_MAX_PLATFORM_NAME = "GOOGLE PERFORMANCE MAX AD";
export const MockAPI =
  "https://6a5e97b7-2879-4c0b-8197-9c7de9721e3c.mock.pstmn.io/";

export const Months: any = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};
export const TIKTOK_PLATFORM_NAME = "TikTok";
export const DV360_PLATFORM_NAME = "DV360";
