import {
    SET_KEYWORD_TUNING_JSON,
    SetKeywordTuningJson,
  } from "../../types/targetingAI";
  
  export default function keywordTuningJson(
    state = {},
    action: SetKeywordTuningJson
  ) {
    switch (action.type) {
      case SET_KEYWORD_TUNING_JSON: {
        return action.payload;
      }
      default:
        return state;
    }
  }