import { Box, IconButton } from "@mui/material";
import { InputFormControl, SelectFormControl } from "components/shared";
import {
  Control,
  FieldValues,
  useFieldArray,
  UseFormGetValues,
  UseFormWatch,
  useWatch,
} from "react-hook-form";
import { StyledLayerBox, StyledLayerTitleText } from "./styled";
import { ReactComponent as BinIcon } from "../../../../../assets/svg/bin_transparent.svg";
import ChoiceButtonFormController from "../components/ChoiceButton/ChoiceButtonFormController";
import MediaAdminRadioButtonController from "../components/MediaAdminRadioButton/MediaAdminRadioButtonController";
import { useEffect, useState } from "react";

interface LayerFormProps {
  control: Control<FieldValues, object>;
  registeredName: string;
  index: number;
  watch: UseFormWatch<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  deleteLayer: (index: number) => void;
  layerId: string;
  unregister: any;
}

export default function LayerForm({
  control,
  registeredName,
  watch,
  getValues,
  layerId,
  index,
  deleteLayer,
  unregister,
}: LayerFormProps) {
  const globalLabelStyle = {
    fontSize: "12px",
  };
  const [layersDropDown, setLayersDropDown] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [relation, setRelation] = useState("");
  const [choices, setChoices] = useState("");

  // const { fields } = useFieldArray({
  //   control,
  //   name: registeredName,
  // });

  const framesData = useWatch({
    name: "frames",
    control,
    defaultValue: "",
  });

  const fields = useWatch({
    name: registeredName,
    control,
    defaultValue: "",
  });

  useEffect(() => {
    if (framesData) {
      const allLayers = Array.prototype.concat.apply(
        [],
        framesData.map((frame: any) => frame.layers.map((layer: any) => layer))
      );
      const localLayers = allLayers.filter(
        (item: any) => item.name && item.frontEndId !== layerId
      );

      const prepLayer = localLayers.map((item: any) => {
        return {
          label: item.name ?? "",
          value: item.name ?? "",
        };
      });
      setLayersDropDown([
        { label: "Starting layer", value: "START" },
        ...prepLayer,
      ]);
    }
  }, [framesData, layerId]);

  useEffect(() => {
    if (fields) {
      // const localLayers = fields.filter(
      //   (item: any) => item.name && item.frontEndId !== layerId
      // );

      // const prepLayer = localLayers.map((item: any) => {
      //   return {
      //     label: item.name ?? "",
      //     value: item.name ?? "",
      //   };
      // });
      // setLayersDropDown([
      //   { label: "Starting layer", value: "starting_layer" },
      //   ...prepLayer,
      // ]);
      setRelation(fields[index].is_sentence_related);
      setChoices(fields[index].is_partial_sentence);
    }
  }, [fields]);

  registeredName = `${registeredName}.${index}`;

  useEffect(() => {
    if (relation === "Unrelated") {
      unregister(`${registeredName}.is_partial_sentence`);
      unregister(`${registeredName}.related_to`);
    }
  }, [relation]);

  return (
    <StyledLayerBox
      width="100%"
      display="flex"
      flexDirection="column"
      mt={index > 0 ? "16px" : "0px"}
    >
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        pb="3px"
      >
        <StyledLayerTitleText>Layer {index + 1}</StyledLayerTitleText>
        <Box display="flex">
          <ChoiceButtonFormController
            control={control}
            registeredName={`${registeredName}.is_sentence_related`}
          />
          <IconButton
            sx={{
              marginLeft: "20px",
            }}
            onClick={() => deleteLayer(index)}
          >
            <BinIcon />
          </IconButton>
        </Box>
      </Box>
      {relation === "Related" && (
        <MediaAdminRadioButtonController
          control={control}
          registeredName={`${registeredName}.is_partial_sentence`}
        />
      )}
      {relation === "Related" && choices !== "Full Sentence" && (
        <Box pt="6px" mb="-10px" width="40%">
          <SelectFormControl
            fullWidth
            label="Previous Text Layer"
            tooltipText="coming soon"
            sx={{
              height: "40px",
              fontSize: "12px",
            }}
            options={layersDropDown}
            placeholder={"Select layer"}
            control={control}
            registeredName={`${registeredName}.related_to`}
            // customDefaultValue="image"
            // defaultValue="image"
            isBorder
            field="label"
            valueKey="value"
            backgroundColor="#FFFFFF"
            borderColor="#DDDDDD"
            labelProps={globalLabelStyle}
          />
        </Box>
      )}
      <Box display="flex" width="100%" pt="12px">
        <Box flex={1}>
          <InputFormControl
            label="Layer ID/Name"
            tooltipText="Enter a Name or ID of the layer It can be text, number or a combination"
            disableUnderline
            control={control}
            registeredName={`${registeredName}.name`}
            required
            fullWidth
            placeholder="Enter Layer ID/Name"
            sx={{ height: "40px", fontSize: "12px" }}
            isBorder
            backgroundColor="#FFFFFF"
            borderColor="#DDDDDD"
            labelProps={globalLabelStyle}
          />
        </Box>
        <Box flex={1} pl="12px">
          <InputFormControl
            label="Max Characters"
            inputType="int"
            tooltipText="Maximum character limit, based on the design"
            disableUnderline
            control={control}
            registeredName={`${registeredName}.maxCharacters`}
            required
            fullWidth
            placeholder="Enter Layer Max Character"
            sx={{ height: "40px", fontSize: "12px" }}
            isBorder
            backgroundColor="#FFFFFF"
            borderColor="#DDDDDD"
            labelProps={globalLabelStyle}
          />{" "}
        </Box>
        <Box flex={1} pl="12px">
          <InputFormControl
            label="Min Characters"
            inputType="int"
            tooltipText="Minimum character limit, based on the design"
            disableUnderline
            control={control}
            registeredName={`${registeredName}.minCharacters`}
            required
            fullWidth
            placeholder="Enter Layer Min Character"
            sx={{ height: "40px", fontSize: "12px" }}
            isBorder
            backgroundColor="#FFFFFF"
            borderColor="#DDDDDD"
            labelProps={globalLabelStyle}
          />
        </Box>
      </Box>
      <InputFormControl
        label="Layer Original Text"
        tooltipText="Enter the text present on the original Image"
        disableUnderline
        control={control}
        registeredName={`${registeredName}.originalText`}
        required
        fullWidth
        placeholder="Enter Layer Original Text"
        sx={{ height: "40px", fontSize: "12px" }}
        isBorder
        backgroundColor="#FFFFFF"
        borderColor="#DDDDDD"
        labelProps={globalLabelStyle}
      />
    </StyledLayerBox>
  );
}
