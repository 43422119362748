import React from "react";

type NoDataProps = {
  height?: string;
  width?: string;
};
const NoData: React.FC<NoDataProps> = ({ height, width }) => (
  <div
    className="text-center align-middle d-flex align-items-center justify-content-center no-data"
    style={{
      height: height || "200px",
      width: width || "100%",
      zIndex: 9999999,
      minHeight: "200px",
    }}
  >
    No Data Present
  </div>
);

export default NoData;
