import HttpService from "HttpService/HttpService";
import { BASE_URL } from "utils/path";
import { GroupType } from "types/GlobalTypes";

export const postLinks = async (brandId: string, payload: {}) => {
  let response = {
    success: false,
    message: "",
    data: [],
  };

  let urlParams = `/v1/api/brands/${brandId}/adaccounts/insights`;
  response = await HttpService.post(BASE_URL, urlParams, "", payload)
    .then((data: any) => {
      return data.data;
    })
    .catch((error: any) => {
      throw new Error("postLinks=>", error.message);
    });
  return response;
};
