import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ReactComponent as ArrowDown } from "../../../../assets/svg/AccordionArrowDown.svg";
import { Dispatch, useEffect, useState } from "react";
import { CheckBox, StyledText } from "components/shared";
import ImageCollection from "./ImageCollection";
import { truncate } from "lodash";
import {
  StyledAccordion,
  StyledTableContainer,
  StyledVerticalDivider,
} from "../styled";
import CRAccordionItemTable, {
  CRAccordionTableItemLoader,
} from "./CRAccordionItemTable";
import ActionSummaryItem, {
  ActionSummaryChildrenItem,
} from "./ActionSummaryItem";
import { ReactComponent as DownIcon } from "../../../../assets/svg/down_green.svg";
import { ReactComponent as RedArrow } from "../../../../assets/svg/red_arrow.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/svg/info.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  BatchItemType,
  GetBatchDetailsItemType,
  StatusType,
} from "store/types/ActionDebugger/CreativeRotation/creativeRotation.types";
import {
  getBatchDataDetails,
  getPreviewData,
} from "store/actions/ActionDebugger/CreativeRotation/creativeRotation.actions";
import { crTimezone } from "utils/date.util";
import { BatchSelectionType } from "../CreativeRotation";
import { ReactComponent as StarIcon } from "../../../../assets/svg/star.svg";
import { ReactComponent as LoadingIcon } from "../../../../assets/svg/loading.svg";
import imagePlaceholder from "../../../../assets/png/no_image_placeholder.png";
import { ReactComponent as Refresh } from "../../../../assets/cai/svg/refresh_status.svg";
import { getCurrencySymbol } from "utils/commonFunctions";

const ACCORDION_HEIGHT = 6.8;

export function CRAccordionItemLoader() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      minWidth={0}
      gap="1.8em"
    >
      {Array(5)
        .fill(0)
        .map(() => (
          <Skeleton
            variant="rectangular"
            height={`6em`}
            sx={{
              borderRadius: "6px",
            }}
          />
        ))}
    </Box>
  );
}

export default function CRAccordionItem({
  expanded,
  setExpanded,
  index,
  isExpired,
  batchItem,
  elementId,
  setBatchSelection,
  selected = [],
  autoExecute,
}: {
  expanded: string | false;
  setExpanded: Dispatch<React.SetStateAction<string | false>>;
  index: number;
  isExpired?: boolean;
  batchItem: BatchItemType;
  elementId: string;
  setBatchSelection: any;
  selected: string[];
  autoExecute: boolean;
}) {
  const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setBatchSelection(newSelected);
  };

  const handleSelectAllClick = (
    event: any,
    rows: GetBatchDetailsItemType[]
  ) => {
    if (rows.length !== selected.length) {
      const newSelected: any = [];
      if (
        selected.length +
        (batchItem?.detailsList?.data
          ? batchItem?.detailsList?.data?.filter(
            (item) =>
              item?.status !== "Recommended" ||
              item?.phase === "recommendation" ||
              item?.phase === "observation"
          )?.length
          : 0) !==
        batchItem.detailsList?.data?.length
      ) {
        rows.forEach((item) => {
          if (item.status === "Recommended") {
            newSelected.push(item.recommendation_id);
          }
        });
      }

      setBatchSelection(newSelected);
    } else {
      setBatchSelection([]);
    }
    event.stopPropagation();
  };

  useEffect(() => {
    if (index === 0) {
      dispatch(
        getBatchDataDetails({
          batchId: batchItem.batch_id,
          elementId,
          asset_l1_id: batchItem.asset_l1_id,
        })
      );
    }
    if (batchItem.batch_id) {
      dispatch(
        getPreviewData({
          batchId: batchItem.batch_id,
          elementId,
          data: batchItem?.ad_ids || [],
          asset_l1_id: batchItem.asset_l1_id,
        })
      );
    }
  }, []);

  const recommendationStatus = () => {
    if (batchItem?.status_counts?.Processing) {
      return (
        <Box display="flex" pl=".75em">
          <StyledText fontSize="0.75em" lineHeight="15px" color="#33343D">
            Processing
          </StyledText>

          <LoadingIcon
            style={{
              marginLeft: ".5em",
            }}
          />
        </Box>
      );
    } else if (
      !batchItem?.status_counts?.Executed &&
      !batchItem?.status_counts?.Rejected &&
      !batchItem?.status_counts?.Failed
    ) {
      if (isExpired) {
        return (
          <StyledText
            fontSize="0.75em"
            lineHeight="15px"
            color="#33343D"
            pl=".75em"
          >
            No Actions Taken
          </StyledText>
        );
      }
      return (
        <Box display="flex" pl=".75em">
          <StyledText fontSize="0.75em" lineHeight="15px" color="#33343D">
            Recommended
          </StyledText>
          <StarIcon />
        </Box>
      );
    } else {
      const stringArray: any = [];
      Object.keys(batchItem?.status_counts).forEach((item: any) => {
        if (batchItem?.status_counts?.[item as StatusType]) {
          let newValue = item;
          if ((newValue as StatusType) === "Recommended" && isExpired) {
            newValue = "No Action Taken";
          }

          stringArray.push(
            `${batchItem?.status_counts[item as StatusType]} ${newValue}`
          );
        }
      });
      return (
        <StyledText
          fontSize="0.75em"
          lineHeight="15px"
          color="#33343D"
          pl=".75em"
        >
          {stringArray.join(", ")}
        </StyledText>
      );
    }
  };

  return (
    <StyledAccordion
      expanded={expanded === index.toString()}
      onChange={() => {
        if (expanded === index.toString()) {
          setExpanded("-1");
        } else {
          setExpanded(index.toString());
          if (batchItem?.detailsList?.data === undefined) {
            dispatch(
              getBatchDataDetails({
                batchId: batchItem.batch_id,
                elementId,
                asset_l1_id: batchItem.asset_l1_id,
              })
            );
          }
        }
      }}
      sx={{
        "& .MuiAccordionSummary-expandIconWrapper": {
          position: "absolute",
          right: "0.5rem",
        },
        "&:before": {
          display: "none",
        },
        boxShadow: "none",
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDown />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          height: `${ACCORDION_HEIGHT}em`,
          opacity: isExpired ? 0.5 : 1,
          padding: "0 !important",
          "& .MuiAccordionSummary-content": { width: "100%" },
        }}
      >
        <StyledTableContainer
          padding="none"
          sx={{
            height: `${ACCORDION_HEIGHT}em`,
            width: "100%",
            marginBottom: "0px",
            padding: "0 16px !important",
          }}
        >
          <Table
            sx={{
              height: "100%",
              minWidth: "100%",
              marginBottom: "0px",
              paddingBottom: "0px",
              tableLayout: "fixed",
            }}
            aria-labelledby="tableTitle"
            size="medium"
            padding="none"
          >
            <TableBody>
              <TableRow
                sx={{
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
              >
                {/* Col1 */}
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    borderRight: "1px solid #e5e5e5",
                    width: "17% !important",
                    minWidth: "17% !important",
                    maxWidth: "17% !important",
                    // borderBottom: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      // padding: ".5em 0em",
                      // alignItems: "center",
                      overflow: "hidden",
                      // width: "20rem",
                    }}
                  >
                    <CheckBox
                      checked={
                        // batchItem.detailsList?.data &&

                        !isExpired &&
                        (batchItem.detailsList?.data?.length ?? 0) > 0 &&
                        (selected?.length ===
                          batchItem.detailsList?.data?.length ||
                          (selected.length > 0 &&
                            selected.length +
                            (batchItem?.detailsList?.data
                              ? batchItem?.detailsList?.data?.filter(
                                (item) =>
                                  item?.status !== "Recommended" ||
                                  item?.phase === "recommendation" ||
                                  item?.phase === "observation"
                              )?.length
                              : 0) ===
                            batchItem.detailsList?.data?.length))
                      }
                      onClick={(e: any) => {
                        handleSelectAllClick(
                          e,
                          batchItem.detailsList?.data ?? []
                        );
                      }}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                      sx={{
                        pointerEvents: isExpired ? "none" : "stroke",
                      }}
                    />

                    {!batchItem?.imageData ||
                      batchItem?.imageData?.isLoading ||
                      !batchItem?.imgArray ? (
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          height: "3.25em",
                          width: "6.5em",
                          borderRadius: "1.55981px",
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <ImageCollection
                          imgArray={
                            batchItem?.imgArray?.length > 0
                              ? batchItem?.imgArray
                              : [imagePlaceholder]
                          }
                        />
                      </Box>
                    )}
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      {/* <StyledText
                        fontSize="0.75em"
                        color="#33343D"
                        pl=".75em"
                      // lineHeight="141.52%"
                      >
                        <span
                          style={{
                            color: "#0869FB",
                          }}
                        >
                          {" "}
                          {batchItem?.ad_action_counts?.total} AD Creatives
                        </span>{" "}
                        with Recommendations
                      </StyledText>
                      <StyledText
                        fontSize="0.75em"
                        color="#33343D"
                        pl=".75em"
                        lineHeight="141.52%"
                      >
                        Date : {crTimezone(batchItem.created_at)}
                      </StyledText> */}
                      <Tooltip
                        title={`Ad Set : ${batchItem.asset_l1_name}`}
                        arrow
                        placement="bottom-end"
                      >
                        <StyledText p="0 .75em">
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "0.65rem",
                              fontWeight: "600",
                            }}
                          >
                            Ad Set :
                          </span>
                          <span
                            style={{
                              wordBreak: "break-word",
                              fontSize: "0.65rem",
                              fontWeight: "600",
                              color: "#0869FB",
                            }}
                          >
                            {truncate(batchItem.asset_l1_name, { length: 60 })}
                          </span>
                        </StyledText>
                      </Tooltip>

                      <StyledText
                        fontSize="0.75em"
                        color="#33343D"
                        pl=".75em"
                        lineHeight="141.52%"
                      >
                        Id : {batchItem.asset_l1_id}
                      </StyledText>
                      {isExpired && (
                        <StyledText
                          fontSize="0.625em"
                          color="#E84B47"
                          m=".25em .5em"
                          sx={{
                            background: "#FFEBEB",
                            padding: ".4em",
                            borderRadius: "4px",
                            alignSelf: "flex-start",
                            // opacity: 9,
                            // zIndex: 999,
                          }}
                        >
                          Expired
                        </StyledText>
                      )}
                    </Box>
                  </Box>
                </TableCell>
                {/* Col2 */}
                <TableCell
                  sx={{
                    borderRight: "1px solid #e5e5e5",
                    width: "12% !important",
                    minWidth: "12% !important",
                    maxWidth: "12% !important",
                    // borderBottom: "none",
                  }}
                  align="center"
                >
                  <Box display="flex" flexDirection="column">
                    <StyledText
                      fontSize="0.75em"
                      color="#B0B0B0"
                      lineHeight="24px"
                      fontWeight={600}
                    >
                      Date of Recommendation
                    </StyledText>
                    {/* <StyledText
                      fontSize="0.75em"
                      color="#33343D"
                      lineHeight="141.52%"
                    >
                      {batchItem.action_basis_summary.off_actions.counts} Turn
                      off ad
                    </StyledText> */}
                    <StyledText
                      fontSize="0.75em"
                      color="#33343D"
                      lineHeight="141.52%"
                    >
                      {batchItem.created_at?.length > 2
                        ? crTimezone(batchItem.created_at)
                        : batchItem.created_at}
                    </StyledText>
                  </Box>
                </TableCell>

                {/* col3 */}
                <TableCell
                  align="left"
                  sx={{
                    width: "12% !important",
                    minWidth: "12% !important",
                    maxWidth: "12% !important",
                    // borderBottom: "none",
                  }}
                >
                  <StyledText
                    fontSize="0.75em"
                    color="#33343D"
                    lineHeight="24px"
                    pl="1em"
                  // fontWeight={600}
                  >
                    Total Ads: {batchItem?.ad_action_counts?.total}
                    <br />
                    Recommended Actions:{" "}
                    {batchItem?.ad_action_counts?.on +
                      batchItem?.ad_action_counts?.off || "-"}
                  </StyledText>
                </TableCell>
                {/* Col4 */}
                <TableCell
                  // align="center"
                  sx={{
                    width: "12% !important",
                    minWidth: "12% !important",
                    maxWidth: "12% !important",
                    textAlign: "center",
                    position: "relative",
                    // borderBottom: "none",
                  }}
                >
                  <StyledText
                    fontSize="0.75em"
                    color="#33343D"
                    lineHeight="24px"
                  >
                    Ad set Daily Avg. Spends
                  </StyledText>
                  {getValueWithSign({
                    amount: batchItem?.batch_metrics?.spend_7day_avg,
                    currency:
                      getCurrencySymbol(authState?.accountDetails?.currency) ||
                      "$",
                    type: "spend",
                    batchMetrics: batchItem?.batch_metrics,
                  })}
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    sx={{
                      position: "absolute",
                      right: "0",
                      height: "60%",
                      top: "13%",
                      background: "#e5e5e5",
                    }}
                  />
                  {/* <ActionSummaryItem
                    actionName="ON Actions"
                    children1={{
                      "cpr": {
                        value1: batchItem.action_basis_summary.on_actions.cpr_abs,
                        value2: batchItem.batch.cpr_abs,
                        percentValue: batchItem.action_basis_summary.on_actions.cpr_rel,
                        title: `Cost per ${batchItem.result_metrix}`,
                      },
                      "roas": {
                        value1: batchItem.action_basis_summary.on_actions.roas_abs,
                        value2: batchItem.batch.roas_abs,
                        percentValue: batchItem.action_basis_summary.on_actions.roas_rel,
                        title: "ROAS",
                      },
                      "null": {
                        value1: batchItem.action_basis_summary.on_actions.cpr_abs ? batchItem.action_basis_summary.on_actions.cpr_abs : batchItem.action_basis_summary.on_actions.roas_abs,
                        value2: batchItem.batch.cpr_abs ? batchItem.batch.cpr_abs : batchItem.batch.roas_abs,
                        percentValue: batchItem.action_basis_summary.on_actions.cpr_rel ? batchItem.action_basis_summary.on_actions.cpr_rel : batchItem.action_basis_summary.on_actions.roas_rel,
                        title: batchItem.action_basis_summary.on_actions.cpr_abs ? `Cost per ${batchItem.result_metrix}` : "ROAS",
                      },
                    }[batchItem.batch.metric_type || "null"]}
                  /> */}
                </TableCell>
                {/* col5 */}
                <TableCell
                  sx={{
                    borderRight: "1px solid #e5e5e5",
                    width: "9% !important",
                    minWidth: "9% !important",
                    maxWidth: "9% !important",
                    textAlign: "center",
                    // borderBottom: "none",
                  }}
                >
                  <StyledText
                    fontSize="0.75em"
                    color="#33343D"
                    lineHeight="24px"
                  >
                    {batchItem?.metric_type === "cpr" ? "CPR" : "ROAS"}
                  </StyledText>
                  {getValueWithSign({
                    amount:
                      batchItem?.batch_metrics?.[
                      `${batchItem.metric_type}_7day_avg`
                      ],
                    type: batchItem.metric_type,
                    batchMetrics: batchItem?.batch_metrics,
                  })}
                  {/* <Box
                    display="flex"
                    flex={1}
                    alignSelf="flex-end"
                    alignItems="flex-end"
                    // flexDirection="column"
                    sx={{
                      // background: "red",
                      height: "3.8em",
                    }}
                  >
                    <ActionSummaryChildrenItem
                      {...{
                        value1:
                          batchItem.action_basis_summary.on_actions.spend_abs,
                        value2: batchItem.batch.spend_abs,
                        isSpends: true,
                        percentValue:
                          batchItem.action_basis_summary.on_actions.spend_rel,
                        title: "Spend ratio: ",
                      }}
                    />
                  </Box> */}
                </TableCell>

                {/* col6 */}
                <TableCell
                  sx={{
                    width: "9% !important",
                    minWidth: "9% !important",
                    maxWidth: "9% !important",
                    paddingLeft: ".5em",
                    textAlign: "center",
                    position: "relative",
                    // borderBottom: "none",
                  }}
                >
                  <StyledText
                    fontSize="0.75em"
                    color="#33343D"
                    lineHeight="24px"
                  >
                    Frequency
                  </StyledText>
                  {getValueWithSign({
                    amount: batchItem?.batch_metrics?.frequency_7day_avg,
                    type: "frequency",
                    batchMetrics: batchItem?.batch_metrics,
                  })}
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    sx={{
                      position: "absolute",
                      right: "0",
                      height: "60%",
                      top: "13%",
                      background: "#e5e5e5",
                    }}
                  />
                  {/* <ActionSummaryItem
                    actionName="OFF Actions"
                    children1={{
                      value1:
                        batchItem.action_basis_summary.off_actions.spend_abs,
                      value2: batchItem.batch.spend_abs,
                      isSpends: true,

                      percentValue:
                        batchItem.action_basis_summary.off_actions.spend_rel,
                      title: "Spend ratio:",
                    }}
                  /> */}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #e5e5e5",
                    width: "9% !important",
                    minWidth: "9% !important",
                    maxWidth: "9% !important",
                    textAlign: "center",
                    // borderBottom: "none",
                  }}
                >
                  <StyledText
                    fontSize="0.75em"
                    color="#33343D"
                    lineHeight="24px"
                  >
                    CTR
                  </StyledText>
                  {getValueWithSign({
                    amount: batchItem?.batch_metrics?.ctr_7day_avg,
                    type: "ctr",
                    batchMetrics: batchItem?.batch_metrics,
                  })}
                  {/* <Box
                    display="flex"
                    flex={1}
                    alignSelf="flex-end"
                    alignItems="flex-end"
                    sx={{
                      height: "3.8em",
                    }}
                  >
                    <ActionSummaryChildrenItem
                      {...({
                        "cpr": {
                          value1: batchItem.action_basis_summary.off_actions.cpr_abs,
                          value2: batchItem.batch.cpr_abs,
                          percentValue: batchItem.action_basis_summary.off_actions.cpr_rel,
                          title: `Cost per ${batchItem.result_metrix}`,
                        },
                        "roas": {
                          value1: batchItem.action_basis_summary.off_actions.roas_abs,
                          value2: batchItem.batch.roas_abs,
                          percentValue: batchItem.action_basis_summary.off_actions.roas_rel,
                          title: "ROAS",
                        },
                        "null": {
                          value1: batchItem.action_basis_summary.off_actions.cpr_abs ? batchItem.action_basis_summary.off_actions.cpr_abs : batchItem.action_basis_summary.off_actions.roas_abs,
                          value2: batchItem.batch.cpr_abs ? batchItem.batch.cpr_abs : batchItem.batch.roas_abs,
                          percentValue: batchItem.action_basis_summary.off_actions.cpr_rel ? batchItem.action_basis_summary.off_actions.cpr_rel : batchItem.action_basis_summary.off_actions.roas_rel,
                          title: batchItem.action_basis_summary.off_actions.cpr_abs ? `Cost per ${batchItem.result_metrix}` : 'ROAS',
                        },
                      }[batchItem.batch.metric_type || "null"])}
                    />
                  </Box> */}
                </TableCell>
                {/* col7 */}
                <TableCell
                  sx={{
                    borderRight: "1px solid #e5e5e5",
                    width: "10% !important",
                    minWidth: "10% !important",
                    maxWidth: "10% !important",
                    // borderBottom: "none",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignSelf="flex-start"
                  >
                    {/* <StyledText
                      fontSize="0.75em"
                      color="#B0B0B0"
                      pl=".75em"
                      lineHeight="24px"
                      fontWeight={600}
                    >
                      Last Execution Time
                    </StyledText>
                    <StyledText
                      fontSize="0.75em"
                      color="#33343D"
                      pl=".75em"
                      lineHeight="141.52%"
                    >
                      {batchItem.updated_at?.length > 2
                        ? crTimezone(batchItem.updated_at)
                        : batchItem.updated_at}
                    </StyledText> */}
                  </Box>
                </TableCell>
                {/* col8 */}
                <TableCell
                  sx={{
                    width: "10% !important",
                    minWidth: "10% !important",
                    maxWidth: "10% !important",
                    // borderBottom: "none",
                  }}
                  align="left"
                >
                  <Box display="flex" flexDirection="column">
                    <StyledText
                      fontSize="0.75em"
                      color="#B0B0B0"
                      pl=".9em"
                      lineHeight="24px"
                      fontWeight={600}
                    >
                      Status
                    </StyledText>
                    {recommendationStatus()}
                    {(batchItem?.status_counts?.Processing ||
                      batchItem?.status_counts.Recommended &&
                      autoExecute) &&
                      !isExpired ? (
                      <Box
                        onClick={(event: any) => {
                          dispatch(
                            getBatchDataDetails({
                              batchId: batchItem.batch_id,
                              elementId,
                              asset_l1_id: batchItem.asset_l1_id,
                            })
                          );
                          event.stopPropagation();
                        }}
                        display="flex"
                        alignItems="center"
                        pl=".7em"
                      >
                        <Refresh width=".8em" height=".8em" />{" "}
                        <StyledText
                          fontSize="0.625em"
                          color="#0869FB"
                          pl=".3em"
                          lineHeight="16px"
                        >
                          {batchItem?.detailsList?.isLoading
                            ? "Refreshing"
                            : "Refresh"}
                        </StyledText>
                      </Box>
                    ) : (
                      <Box />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
        }}
      >
        {batchItem?.detailsList?.isLoading ||
          batchItem?.detailsList === undefined ? (
          <CRAccordionTableItemLoader />
        ) : (
          <CRAccordionItemTable
            handleClick={handleClick}
            handleSelectAllClick={handleSelectAllClick}
            selected={selected}
            rows={batchItem?.detailsList.data ?? []}
            isExpired={isExpired}
            imageLoading={
              !batchItem?.imageData || batchItem?.imageData?.isLoading
            }
            imageData={batchItem?.imageData?.data}
            metricType={batchItem.metric_type === "cpr" ? "CPR" : "ROAS"}
            batchItem={batchItem}
          />
        )}

        <StyledText
          p="1em"
          fontSize="0.75em"
          color="#33343D"
          pl=".75em"
          lineHeight="15px"
        >
          <span>
            <InfoIcon height="1.6em" width="1.6em" />
          </span>{" "}
          All{" "}
          <span className="rotated180">
            <DownIcon />
          </span>{" "}
          <span>
            <RedArrow />
          </span>{" "}
          metrics are based on a time series comparison with the previous
          lookback window
        </StyledText>
      </AccordionDetails>
    </StyledAccordion>
  );
}

export const getValueWithSign = ({
  amount,
  currency,
  percent,
  type,
  batchMetrics,
}: {
  amount: number | string;
  currency?: boolean;
  percent?: boolean;
  batchMetrics: any;
  type: "cpr" | "ctr" | "frequency" | "spend" | "roas";
}) => {
  const mapName = {
    cpr: "CPR",
    roas: "ROAS",
    frequency: "Frequency",
    spend: "Spend",
    ctr: "CTR",
  };
  const messageMapWithMetricType = {
    cpr: "Last 7 days' CPR",
    roas: "Last 7 days' ROAS",
    frequency: "Last 7 days' avg frequency",
    spend: "Last 7 days' daily spend average",
    ctr: "Last 7 days' CTR",
  };
  let difference: any = (
    parseFloat(batchMetrics?.[`${type}_3day_avg`]) -
    parseFloat(batchMetrics?.[`${type}_7day_avg`])
  )?.toFixed(2);
  let percentChange: any = (
    (difference / parseFloat(batchMetrics?.[`${type}_7day_avg`])) *
    100
  ).toFixed(2);
  return (
    <div
      style={{
        display: "flex",
        gap: "0.31rem",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Tooltip
        title={`${messageMapWithMetricType[type]}`}
        arrow
        placement="top"
        disableHoverListener={amount == "-"}
      >
        <StyledText fontSize="0.75em" color="#33343D" lineHeight="15px">
          {currency && amount !== "-" ? currency : ""}
          {amount}
          {percent ? "%" : ""}
        </StyledText>
      </Tooltip>
      {amount !== "-" && (
        (parseFloat(batchMetrics?.[`${type}_pct_chg`])) ? (<Tooltip
          arrow
          title={`Your ${mapName[type]
            } over the last 3 days is ${!isNaN(percentChange) ? percentChange : '- '}% ${difference > 0 ? "more" : "less"
            } on average over the last 7 days.`}
        >
          <div>{getArrow(type, difference)}</div>
        </Tooltip>) : <>-</>
      )}
    </div>
  );
};

const getArrow = (type: any, difference: any) => {
  switch (type) {
    case "cpr":
      return difference > 0 ? (
        <RedArrow style={{ transform: `rotate(180deg)` }} />
      ) : (
        <DownIcon />
      );
    case "roas":
      return difference > 0 ? (
        <DownIcon style={{ transform: `rotate(180deg)` }} />
      ) : (
        <RedArrow />
      );
    case "spend":
      return difference > 0 ? (
        <CustomArrowDown
          style={{ transform: `rotate(180deg)` }}
          color="black"
        />
      ) : (
        <CustomArrowDown color="black" />
      );
    case "frequency":
      return difference > 0 ? (
        <RedArrow style={{ transform: `rotate(180deg)` }} />
      ) : (
        <DownIcon />
      );
    case "ctr":
      return difference > 0 ? (
        <DownIcon style={{ transform: `rotate(180deg)` }} />
      ) : (
        <RedArrow />
      );
    default:
      return <>-</>;
  }
};

const CustomArrowDown = ({ color, style }: any) => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0.409291 4.22931L5.67208 9.4921L10.9391 4.22931H8.26298V0.0830078H3.08117V4.22931H0.409291Z"
        fill={color || "red"}
      />
    </svg>
  );
};
