import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCreateAdAds, setCreateAdAds } from "../../../store/actions/cluster";
import { ClusterState } from "../../../store/types/cluster";
import { initialState } from "../../../store/types/auth";
import Radio from "../../../ui/Radio/Radio";
import Loader from "../../common/Loader";
import "./campaignAccordian.scss";
import { AdAccountState } from "../../../store/types/adaccount";

const CampaignAccordion: React.FC<{
  data: any;
  index: number;
  refAdGroup: any;
  setRefCampaign: Function;
  setRefAdGroup: Function;
  setAdLoading: Function;
  loading: boolean;
}> = ({
  data,
  index,
  setRefAdGroup,
  setRefCampaign,
  refAdGroup,
  setAdLoading,
  loading,
}): JSX.Element => {
  const dispatch = useDispatch();
  const clusterState = useSelector((state: { cluster: ClusterState }) => state.cluster);
  const { createAdAds, createAdAdGroup } = clusterState;
  const auth = useSelector((state: { auth:initialState }) => state.auth);
  const { selectedAdAccount,selectedGSAIAdaccount } = useSelector((state: { adaccount: AdAccountState }) => state.adaccount);
  const formattedCreateAdGroup = (createAdGroup: any) => {
    if (createAdGroup) {
      return createAdGroup.map((ad: any) => ({
        id: ad.id,
        name: ad.name,
        value: ad.id,
      }));
    }
  };

  const handleRadioChange = (e: any, campaign: any) => {
    const adGroupId = e.target.getAttribute("data-id");
    const reAdGrp:any = createAdAdGroup[campaign.id].find((g:any)=>g.id===e.target.value);
    setRefCampaign(campaign);
    setRefAdGroup({
      name: reAdGrp.name,
      id: adGroupId,
    });
    if (createAdAds=== null || !createAdAds[adGroupId]) {
      setAdLoading(true);
      dispatch(getCreateAdAds(
        {accountID:selectedGSAIAdaccount?.id, campaignId: campaign.id, adGroupId: adGroupId, user: auth?.user},
        (response:any, error: boolean) => {
          if (!error) {
            let formattedRes = response.data.map((r: any) => r.ad);
            let formattedResdata = formattedRes.map((r: any) => ({
              ...r,
              headlines: r?.responsiveSearchAd?.headlines?.map((h: any) => h.text).join(" | "),
              descriptions: r?.responsiveSearchAd?.descriptions?.map((h: any) => h.text).join(" | "),
            }));
            dispatch(setCreateAdAds({ ...createAdAds, [adGroupId]: formattedResdata }));
            setAdLoading(false);
          }
        }
      ));
    }
  };

  return loading? (
    <Loader height="20px" animation="grow" size="sm" />
  ) : (
    <div className="mt-2 color-light-grey">
      {createAdAdGroup && createAdAdGroup[data.id] && createAdAdGroup[data.id].length > 0 ? (
        <Radio
          name="create-ad-ad-group"
          data={formattedCreateAdGroup(createAdAdGroup[data.id])}
          onChange={(e) => handleRadioChange(e, data)}
          defaultSelected={refAdGroup.id}
          className="create-ad-radio"
        />
      ) : (
        <div className="no-ad-group">No Ad Group</div>
      )}
    </div>
  );
};

export default CampaignAccordion;
