import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import Levers from "../Levers/Levers";
import { Box, Typography } from "@mui/material";
import { StyledAccordionText, StyledFormLeversContainer } from "./styled";
import { ReactComponent as ArrowDown } from "../../../../../assets/svg/AccordionArrowDown.svg";
import { useEffect, useState } from "react";

function FormLevers({
  data,
  control,
  getValues,
  setValue,
  headlineDescriptionLevers,
  formType,
  errors,
  setError,
  clearErrors,
}: any) {
  const [primaryTextAccordionHandler, setPrimaryTextAccordionHandler] =
    useState(false);
  const [headDescAccordionHandler, setHeadDescAccordionHandler] =
    useState(false);
  const [imageAccordionHandler, setImageAccordionHandler] = useState(false);
  const [videoAccordionHandler, setVideoAccordionHandler] = useState(false);

  useEffect(() => {
    if (
      errors &&
      errors?.primaryText &&
      Object.keys(errors?.primaryText).length
    ) {
      setPrimaryTextAccordionHandler(true);
    }
    if (
      errors &&
      errors?.headlineDescription &&
      Object.keys(errors?.headlineDescription).length
    ) {
      setHeadDescAccordionHandler(true);
    }
    if (errors && errors?.image && Object.keys(errors?.image).length) {
      setImageAccordionHandler(true);
    }
    if (errors && errors?.video && Object.keys(errors?.video).length) {
      setVideoAccordionHandler(true);
    }
  }, [errors]);

  return (
    <div>
      <StyledFormLeversContainer>
        <Accordion
          expanded={primaryTextAccordionHandler}
          onChange={() => {
            setPrimaryTextAccordionHandler(!primaryTextAccordionHandler);
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <StyledAccordionText>Primary Text</StyledAccordionText>
          </AccordionSummary>
          <AccordionDetails>
            {data.find((g: any) => g.generation_type === "primary_text")?.levers
              .length ? (
              data
                .find((g: any) => g.generation_type === "primary_text")
                ?.levers.map((lever: any) => (
                  <Levers
                    lever={lever}
                    key={lever.lever_name}
                    registeredName={"primaryText"}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    formType={formType}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                ))
            ) : (
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                width="100%"
                color="#333333"
                sx={{
                  // paddingTop: "-10px",
                  paddingBottom: "10px",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "22px",
                }}
              >
                Primary Text Recommendations will be generated based on the
                details added above along with the reference ad's content during
                variant creation process.
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </StyledFormLeversContainer>
      <StyledFormLeversContainer className="mt-3">
        <Accordion
          expanded={headDescAccordionHandler}
          onChange={() => {
            setHeadDescAccordionHandler(!headDescAccordionHandler);
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDown />}
            aria-controls="panel1a-content1"
            id="panel1a-header1"
          >
            <StyledAccordionText>Headline/Description</StyledAccordionText>
          </AccordionSummary>
          <AccordionDetails>
            {headlineDescriptionLevers?.map((lever: any) => (
              <Levers
                lever={lever}
                key={lever.lever_name}
                registeredName={"headlineDescription"}
                control={control}
                getValues={getValues}
                setValue={setValue}
                formType={formType}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </StyledFormLeversContainer>
      <StyledFormLeversContainer className="mt-3">
        <Accordion
          expanded={imageAccordionHandler}
          onChange={() => {
            setImageAccordionHandler(!imageAccordionHandler);
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDown />}
            aria-controls="panel1a-content1"
            id="panel1a-header1"
          >
            <StyledAccordionText>Image</StyledAccordionText>
          </AccordionSummary>
          <AccordionDetails>
            {data.find((g: any) => g.generation_type === "image")?.levers
              .length ? (
              data
                .find((g: any) => g.generation_type === "image")
                ?.levers.map((lever: any) => (
                  <Levers
                    lever={lever}
                    key={lever.lever_name}
                    registeredName={"image"}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    formType={formType}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                ))
            ) : (
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                width="100%"
                color="#333333"
                sx={{
                  // paddingTop: "-10px",
                  paddingBottom: "10px",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "22px",
                }}
              >
                Image Text Recommendations will be generated based on the
                details added above along with the reference ad's content during
                variant creation process.
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </StyledFormLeversContainer>
      <StyledFormLeversContainer className="mt-3">
        <Accordion
          expanded={videoAccordionHandler}
          onChange={() => {
            setVideoAccordionHandler(!videoAccordionHandler);
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDown />}
            aria-controls="panel1a-content1"
            id="panel1a-header1"
          >
            <StyledAccordionText>Video</StyledAccordionText>
          </AccordionSummary>
          <AccordionDetails>
            {data.find((g: any) => g.generation_type === "video")?.levers
              .length ? (
              data
                .find((g: any) => g.generation_type === "video")
                ?.levers.map((lever: any) => (
                  <Levers
                    lever={lever}
                    key={lever.lever_name}
                    registeredName={"video"}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    formType={formType}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                ))
            ) : (
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                width="100%"
                color="#333333"
                sx={{
                  // paddingTop: "-10px",
                  paddingBottom: "10px",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "22px",
                }}
              >
                Video Text Recommendations will be generated based on the
                details added above along with the reference ad's content during
                variant creation process.
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </StyledFormLeversContainer>
    </div>
  );
}

export default FormLevers;
