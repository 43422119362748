import React from "react";
import info from "../../../assets/svg/smallinfo.svg";
import ButtonUI from "../../../ui/ButtonUI/ButtonUI";
import { Table } from "react-bootstrap";

export default function CampaignAdsetListing(props: any) {
  const target = React.useRef(null);
  return (
    <div className="aiGroupSettingsForm">
      {(!props.platform.type.includes("Facebook") && !props.platform.type.toLowerCase().includes("dv360") && !props.platform.type.toLowerCase().includes("pinterest")) ? (
        <p className="formName">
          All Campaigns
          <div className="tooltip-container">
            <i ref={target}>
              <img className="ml-2" src={info} alt="info_style" />
            </i>
            <div className="custom-tooltip-layout tooltip">
              <div className="tootltip-wrapper">
                <div className="tooltip-header">Campaign</div>
                <span> Campaign</span>
              </div>
            </div>
          </div>
        </p>
      ) : (
        <p className="formName platformType">
          <div>
            All Adsets
            <div className="tooltip-container">
              <i ref={target}>
                <img className="ml-2" src={info} alt="info_style" />
              </i>
              <div className="custom-tooltip-layout tooltip">
                <div className="tootltip-wrapper">
                  <div className="tooltip-header">Adset</div>
                  <span> Adset</span>
                </div>
              </div>
            </div>
          </div>
          <ButtonUI
            className="view-btn"
            onClick={() =>
              props.handleAdsView(props.detail?.id, props.detail?.elementId)
            }
          >
            View in Ads Manager
          </ButtonUI>
        </p>
      )}

      <div className="adset-table m-3">
        {(props.platform.type === "Facebook Ad" || props.platform.type === "Dv360" || props.platform.type.toLowerCase() === "pinterest ads") ? (
          <Table className="w-100 table-striped border rounded">
            <thead className="">
              <th className="text-md rounded">S.No.</th>
              <th className="text-md">Ad Set ID</th>
              <th className="text-md">Ad Set Name</th>
            </thead>
            <tbody>
              {props.adsets &&
                (props.adsets[props.detail.elementId] || []).map(
                  (adsetDetail: any, index: number) => (
                    <tr>
                      <td className="text-md">{index + 1}</td>
                      <td className="text-md">{adsetDetail.adsetId}</td>
                      <td
                        className="text-md w-100"
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          title={adsetDetail.name}
                          style={{
                            position: "absolute",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            top: "12px",
                            left: "12px",
                            right: "22px",
                          }}
                        >
                          {adsetDetail.name}
                        </div>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </Table>
        ) : (
          <Table className="w-100 table-striped border rounded">
            <thead className="">
              <th className="text-md rounded">S.No.</th>
              <th className="text-md">Campaign ID</th>
              <th className="text-md">Campaign Name</th>
            </thead>
            <tbody>
              {props.campaigns &&
                (props.campaigns[props.detail.elementId] || []).map(
                  (adsetDetail: any, index: number) => (
                    <tr>
                      <td className="text-md">{index + 1}</td>
                      <td className="text-md">{adsetDetail.campaignId}</td>
                      <td
                        className="text-md w-100"
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          title={adsetDetail.name}
                          style={{
                            position: "absolute",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            top: "12px",
                            left: "12px",
                            right: "22px",
                          }}
                        >
                          {adsetDetail.name}
                        </div>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
}
