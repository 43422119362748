import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Typography, Select, MenuItem, Grid } from "@mui/material";
import SingleTile from "./SingleTile";
import { TAGS } from "../constants/index";
import { ReactComponent as Total } from "../../../assets/smb/TotalBrands.svg";
import { mixPanelAdEvent } from "utils/mixpanel";

function TilesRow({
  type,
  activeTileKey,
  setActiveTileKey,
  agencyId = "",
}: any) {
  const brandState: any = useSelector((state: any) => state.smb.brand);
  const agenciesState: any = useSelector((state: any) => state.smb.agencies);
  const adAccountLength = useSelector(
    (state: any) =>
      state?.smb?.adAccount?.adAccountMetricsData?.adAccountCount || 0
  );
  const adAccountTypes = useSelector(
    (state: any) =>
      state?.smb?.adAccount?.adAccountMetricsData?.adAccountTypes || {}
  );
  const history = useHistory();

  const changeSelectedSummary = () => {
    mixPanelAdEvent("SMB Dashboard View Changed", {
      dashboard_view:
        type === "Agency" ? "Agency Summary View" : "Ad Account Summary View",
      agency_name: agenciesState.selected.name,
      agency_id: agenciesState.selected.id,
    });
    if (type === "Agency")
      history.push(`/smb-dashboard/adAccount${window.location.search}`);
    if (type === "Ad Account")
      history.push(`/smb-dashboard/agency${window.location.search}`);
  };

  return (
    <Grid container>
      <Grid item xs={2.6}>
        <Select
          className="d-flex align-items-center justify-content-center"
          sx={{
            borderRadius: 1,
            mr: 2,
            my: 2,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.06);",
            py: 3,
            px: 2,
            "& .MuiSelect-select": {
              p: 0,
            },
          }}
          renderValue={() => (
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {type} Summary
            </Typography>
          )}
          value={type}
        >
          <MenuItem onClick={changeSelectedSummary}>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {type === "Agency" ? "Ad Account" : "Agency"} Summary
            </Typography>
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={2.4}>
        <SingleTile
          isSelected={activeTileKey === "total_brands"}
          isLoading={brandState.isLoading}
          label={type === "Agency" ? "Total Brands" : "Total Ad Accounts"}
          Icon={
            <div
              className="rounded-circle"
              style={{
                backgroundColor: "rgba(135, 135, 135, 0.1)",
                padding: "0.6rem",
              }}
            >
              <Total />
            </div>
          }
          value={
            type === "Agency"
              ? brandState.data
                ? Object.keys(brandState.data).length
                : 0
              : adAccountLength
          }
          tileKey="total_brands"
          clickHandler={(key: string) => {
            setActiveTileKey(key);
          }}
        />
      </Grid>
      {type !== "Agency"
        ? Object.keys(adAccountTypes).map((type) => (
            <Grid item xs={1.75}>
              <SingleTile
                isSelected={activeTileKey === type}
                isLoading={brandState.isLoading}
                key={type}
                label={type}
                Icon={TAGS[type].svg({ height: "32px", width: "32px" })}
                value={adAccountTypes[type]}
                tileKey={type}
                clickHandler={(key: string) => {
                  setActiveTileKey(key);
                }}
              />
            </Grid>
          ))
        : brandState &&
          brandState.types &&
          Object.keys(brandState.types).map((type: string) => (
            <Grid item xs={1.75}>
              <SingleTile
                isSelected={activeTileKey === type}
                isLoading={brandState.isLoading}
                key={type}
                label={type}
                Icon={TAGS[type].svg({ height: "32px", width: "32px" })}
                value={brandState.types[type].brandIds.length}
                tileKey={type}
                clickHandler={(key: string) => {
                  setActiveTileKey(key);
                }}
              />
            </Grid>
          ))}
    </Grid>
  );
}

export default TilesRow;
