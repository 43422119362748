import * as React from "react";
import { Theme, useTheme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Typography } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const StyledSelect = styled(Select)(({ theme }) => ({
  color: "inherit",
  borderRadius: "8px !important",
  width: "100%",
  "& input::placeholder": {
    opacity: 1,
    color: "#333 !important",
  },
  "& input": {
    fontFamily: "Inter !important",
    fontSize: "12px",
    fontWeight: 400,
  },
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.4),
  },
  "& fieldset": { top: 0, border: "1px solid #DDDDDD !important" },
  "& legend": { display: "none" },
}));
const MenuProps = {
  id: "menu-back-drop",
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
  name: string,
  selected: readonly any[],
  theme: Theme,
  disabledIds: any
) {
  let style: any = {
    fontWeight:
      selected.findIndex((selectedItem: any) => selectedItem.key === name) ===
      -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    opacity: "1",
  };
  if (disabledIds.includes(name)) {
    style.color = "rgb(0 0 0 / 38%)";
  }
  return style;
}

export default function MultipleSelectChip({
  selected,
  setSelected,
  items,
  disabled,
  disabledIds,
}: any) {
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;

    const added = value[value.length - 1];

    const foundIndex = selected.findIndex(
      (selectedValue: typeof selected) => selectedValue.key === added.key
    );
    if (foundIndex > -1) {
      setSelected(
        value.filter((val: any) => val.key !== selected[foundIndex].key)
      );
    } else {
      setSelected(value);
    }
  };
  const handleDelete = (value: string) => () => {
    setSelected(() =>
      selected.filter(
        (selectedValue: typeof selected) => selectedValue.key !== value
      )
    );
  };

  return (
    <StyledSelect
      fullWidth
      labelId="demo-multiple-chip-label"
      id="demo-multiple-chip"
      multiple
      value={selected}
      onChange={handleChange}
      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
      MenuProps={MenuProps}
      disabled={disabled}
      renderValue={() => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {selected.map((value: any) => (
            <Chip
              sx={{
                color: "#0869FB",
                border: "1px solid #0869FB !important",
                "& .MuiSvgIcon-root": {
                  fill: "#0869FB",
                },
              }}
              deleteIcon={<CloseIcon sx={{ height: 15, width: 15 }} />}
              key={value.key}
              label={value.value}
              variant="outlined"
              onDelete={handleDelete(value.key)}
            />
          ))}
        </Box>
      )}
      open={open}
      onClick={(e) => {
        const target = e.target as HTMLElement;

        let nodeToCheck = (
          target.firstChild ? target.firstChild.parentNode : target.parentNode
        ) as HTMLElement;

        if (
          e.currentTarget.contains(target) ||
          nodeToCheck.id === "menu-" ||
          nodeToCheck.id === "menu-back-drop"
        ) {
          setOpen(!open);
        }
      }}
      defaultOpen={true}
    >
      {items.map((item: any) => (
        <MenuItem
          key={item.key}
          value={item}
          style={getStyles(item.key, selected, theme, disabledIds)}
          id="menu-to-check"
          disabled={disabledIds.includes(item.key)}
        >
          <Checkbox
            style={
              disabledIds.includes(item.key)
                ? {
                    color: "rgb(0 0 0 / 38%)",
                  }
                : {}
            }
            checked={
              selected.findIndex(
                (selectedItem: any) => selectedItem.key === item.key
              ) > -1
            }
          />
          <ListItemText
            primary={item.value}
            sx={{
              "& span": {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
            }}
          />
          {(item.parentkey || !item.config) && (
            <Typography
              sx={{
                fontSize: "0.625em",
                lineHeight: "0.75em",
                fontFamily: "Inter",
                color: `${item.parentkey ? "#F99F35" : "#E84B47"}`,
                border: `1px solid ${item.parentkey ? "#F99F35" : "#E84B47"}`,
                padding: "0.5em",
                borderRadius: "0.4em",
              }}
            >
              {" "}
              {item.parentkey ? `Already in use` : `Not Configured`}
            </Typography>
          )}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}
