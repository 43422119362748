import { Modal } from "@mui/material";
import React from "react";
import PublishLoader from "../../../assets/Gif/PublishLoader.gif";
import "./LoaderModal.scss";

function LoaderModal({ text, gifIcon = PublishLoader, loading }) {
  return (
    <Modal open={loading}>
      <div
        className="container-overlay success-modal font-body"
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.7)",
          zIndex: "1032",
          top: "0",
          left: "0",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div
            className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex flex-column focus:outline-none bg-white"
            style={{
              borderRadius: "8px",
              width: "21.875rem",
              height: "24.375rem",
              padding: "2.5rem",
            }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "80%" }}
              >
                <img
                  src={gifIcon}
                  alt="success"
                  style={{ height: "10.25rem", width: "10.25rem" }}
                />
              </div>
              <div
                className="d-flex flex-column  w-100 align-items-center justify-content-center"
                style={{ height: "20%" }}
              >
                <h3 className="font-lato" style={{ fontWeight: 700 }}>
                  Please Wait!
                </h3>
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "0.875rem",
                    color: "#727272",
                    textAlign: "center",
                    marginBottom: "0.87rem",
                  }}
                >
                  {text}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LoaderModal;
