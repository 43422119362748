import httpService from "HttpService/HttpService";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  setAd,
  setRequestedVariant,
  setRequestedVariants,
  updateComments,
} from "store/actions/CreativeAI/shared/Review/review";
import {
  GetAdData,
  GetRequestedVariant,
  GetRequestedVariants,
  GET_AD_DATA,
  GET_REQUESTED_VARIANT,
  GET_REQUESTED_VARIANTS_DATA,
  PostReviewResponse,
  POST_REVIEW_RESPONSE,
} from "store/types/CreativeAI/shared/Review/review";
import { BASE_URL } from "utils/path";

function* getRequestedVariants(action: GetRequestedVariants): any {
  const {
    payload: { hashId },
  } = action;
  try {
    const urlPrams = `/v1/api/reviews/${hashId}/variants`;
    const response = yield call(httpService.get, BASE_URL, urlPrams);
    if (response.data.success) {
      yield put(
        setRequestedVariants({
          data: response.data.data,
          isFailed: false,
          isLoading: false,
        })
      );
    } else {
      yield put(
        setRequestedVariants({
          data: undefined,
          isFailed: true,
          isLoading: false,
        })
      ); // setting error
    }
  } catch (error: any) {
    yield put(
      setRequestedVariants({
        data: undefined,
        isFailed: true,
        isLoading: false,
      })
    ); // setting  error

    // will remove at deployment
    throw new Error("Network Error", error);
  }
}

function* getRequestedVariant(action: GetRequestedVariant): any {
  const {
    payload: { hashId, variantId },
  } = action;
  try {
    const urlPrams = `/v1/api/reviews/${hashId}/variants/${variantId}`;
    const response = yield call(httpService.get, BASE_URL, urlPrams);
    if (response.data.success) {
      yield put(
        setRequestedVariant({
          isFailed: false,
          isLoading: false,
          data: response.data.data,
        })
      );
    } else {
      yield put(
        setRequestedVariant({
          isFailed: true,
          isLoading: false,
          data: undefined,
        })
      );
    }
  } catch (error) {
    yield put(
      setRequestedVariant({
        isFailed: true,
        isLoading: false,
        data: undefined,
      })
    );
  }
}

function* getAdData(action: GetAdData): any {
  const {
    payload: { adId, adAccountId, hashId },
  } = action;
  try {
    const urlPrams = `/v1/api/reviews/${hashId}/ads/${adId}?adAccountId=${adAccountId}&adFormat=MOBILE_FEED_STANDARD`;
    const response = yield call(httpService.get, BASE_URL, urlPrams);
    if (response.data.success) {
      yield put(
        setAd({
          isFailed: false,
          isLoading: false,
          data: response.data.data,
        })
      );
    } else {
      yield put(
        setAd({
          isFailed: true,
          isLoading: false,
          data: undefined,
        })
      );
    }
  } catch (error) {
    yield put(
      setAd({
        isFailed: true,
        isLoading: false,
        data: undefined,
      })
    );
  }
}

function* postReviewResponse(action: PostReviewResponse): any {
  const {
    payload: { hashId, payload, variantId, commentKey },
  } = action;

  try {
    const urlPrams = `/v1/api/reviews/${hashId}/variants/${variantId}`;
    const response = yield call(
      httpService.put,
      BASE_URL,
      urlPrams,
      "",
      payload
    );
    if (response.data.success && commentKey !== "reviewStatus") {
      yield put(
        updateComments({
          key: commentKey,
          payloadData: {
            isLoading: false,
          },
        })
      );
    } else if (commentKey !== "reviewStatus") {
      yield put(
        updateComments({
          key: commentKey,
          payloadData: {
            isLoading: false,
          },
        })
      );
    }
  } catch (error) {
    if (commentKey !== "reviewStatus") {
      yield put(
        updateComments({
          key: commentKey,
          payloadData: {
            isLoading: false,
          },
        })
      );
    }
  }
}

export function* reviewSagaWatcher() {
  yield takeLatest(GET_REQUESTED_VARIANTS_DATA, getRequestedVariants);
  yield takeLatest(GET_REQUESTED_VARIANT, getRequestedVariant);
  yield takeLatest(GET_AD_DATA, getAdData);
  yield takeEvery(POST_REVIEW_RESPONSE, postReviewResponse);
}
