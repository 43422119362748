export const GET_PUBLISHED_COHORTS = "GET_PUBLISHED_COHORTS";
export const GET_DRAFTED_COHORTS = "GET_DRAFTED_COHORTS";
export const GET_DRAFTED_COHORT_FILTERS = "GET_DRAFTED_COHORTS_FILTERS";
export const GET_PUBLISHED_COHORTS_FILTER = "GET_PUBLISHED_COHORTS_FILTERS";

export const SET_COMMON_FILTERS = "SET_COMMON_FILTERS";
export const SET_PUBLISHED_COHORTS = "SET_PUBLISHED_COHORTS";
export const SET_DRAFTED_COHORTS = "SET_DRAFTED_COHORTS";
export const SET_RECOMMENDED_COHORTS = "SET_RECOMMENDED_COHORTS";

export const SET_PUBLISHED_COHORTS_FILTER = "SET_PUBLISHED_COHORTS_FILTERS";
export const SET_RECOMMENDED_COHORTS_FILTERS =
  "SET_RECOMMENDED_COHORTS_FILTERS";
export const SET_DRAFTED_COHORT_FILTERS = "SET_DRAFTED_COHORTS_FILTERS";
export const SET_LOADING_AUDIENCE_MANAGER = "SET_LOADING_AUDIENCE_MANAGER";
export const SET_SELECTED_COHORT_TYPE = "SET_SELECTED_COHORT_TYPE";
export const SET_SELECTED_SORT_TYPE = "SET_SELECTED_SORT_TYPE";
export const SET_SELECTED_AI_GROUP_AUDIENCE_MANAGER =
  "SET_SELECTED_AI_GROUP_AUDIENCE_MANAGER";
export const SET_COMMON_FILTERS_BRAND = "SET_COMMON_FILTERS_BRAND";
export const SET_COMMON_FILTERS_BRAND_WEBSITE =
  "SET_COMMON_FILTERS_BRAND_WEBSITE";
export const SET_BUCKET_LENGTH = "SET_BUCKET_LENGTH";
export const SET_BUCKET_SKIP = "SET_BUCKET_SKIP";
export const RESET_ALL_AUDIENCE_MANAGER_DATA =
  "RESET_ALL_AUDIENCE_MANAGER_DATA";

export const SET_SELECTED_PLATFORM = "SET_SELECTED_PLATFORM";
export const SET_SELECTED_TYPES = "SET_SELECTED_TYPES";

export type ISort = {
  field: string;
  order: "asc" | "desc";
};

export type IRange = {
  min: number;
  max: number;
};

export type IDateRange = {
  start: Date;
  end: Date;
};

export type IGetPublishedCohortsPayload = {
  aiGroupId?: string;
  sortBy?: ISort;
  brand_name?: string;
  brand_website?: string;
  cpr?: IRange;
  date_range?: IDateRange;
  results?: IRange;
  skip?: string;
  spend?: IRange;
  limit?: number;
};

export type IGetDraftedCohortsPayload = {
  aiGroupId?: string;
  status: "isDrafted" | "isRecommended";
  sortby?: ISort;
  brand_name?: string;
  brand_website?: string;
  date_range?: IDateRange;
  audience_size?: IRange;
  skip?: string;
  relevance_score?: IRange;
  limit?: number;
  performance_score?: "low" | "med" | "high";
  callback?: (data: any, success: boolean)=>void
};

export type ISetPublishedCohortsData = {
  cohort_name: string;
  ad_account_name: string;
  published_date: string;
  spends: number;
  results: number;
  cpr: number;
  keywords: string[];
  summary: {
    published_on: string;
    ad_account_name: string;
    ad_account_id: string;
    campaign_name: string;
    campaign_id: string;
    adset_name: string;
    adset_id: string;
    interest_keywords: string[];
  };
  configuration: {
    brand_name: string;
    brand_website: string;
    explore: string;
    exploit: string;
    brand_website_per: string;
    competitor_website: string;
    intent: string;
    traffic: string;
    custom_keywords: string[];
  };
};

export type ISetDraftedCohortsData = {
  cohort_name: string;
  saved_on: string;
  potential_audience_size: number;
  relevance_score: number;
  performance_score: number;
  keywords: string[];
  summary: {
    saved_on: string;
    potential_audience_size: number;
    relevance_score: number;
    performance_score: number;
    interest_keywords: string[];
  };
  configuration: {
    brand_name: string;
    brand_website: string;
    explore: string;
    exploit: string;
    brand_website_per: string;
    competitor_website: string;
    intent: string;
    traffic: string;
    custom_keywords: string[];
  };
};
export enum SelectedCohortTypeEnum {
  live = "live",
  drafted = "drafted",
  recommended = "recommended",
}
export type SelectedCohortType = keyof typeof SelectedCohortTypeEnum;

export interface TilesProps {
  text: string;
  icon: any;
  key: SelectedCohortType;
}

export enum PublishSortTypeEnum {
  "Published Date : Newest First" = "Published Date : Newest First",
  "Published Date : Oldest First" = "Published Date : Oldest First",
  "Spends : Low to High" = "Spends : Low to High",
  "Spends : High to Low" = "Spends : High to Low",
  "Results : Low to High" = "Results : Low to High",
  "Results : High to Low" = "Results : High to Low",
  "CPR : Low to High" = "CPR : Low to High",
  "CPR : High to Low" = "CPR : High to Low",
}

export enum DraftedSortTypeEnum {
  "Saved/recommendation date : Newest First" = "Saved/recommendation date : Newest First",
  "Saved/recommendation date : Oldest First" = "Saved/recommendation date : Oldest First",
  "Potential Audience size : Low to High" = "Potential Audience size : Low to High",
  "Potential Audience size : High to Low" = "Potential Audience size : High to Low",
  "Relevance Score : Low to High" = "Relevance Score : Low to High",
  "Relevance Score : High to Low" = "Relevance Score : High to Low",
  "Performance Score : Low to High" = "Performance Score : Low to High",
  "Performance Score : High to Low" = "Performance Score : High to Low",
}
export type PublishSortType = keyof typeof PublishSortTypeEnum;

export type DraftedSortType = keyof typeof DraftedSortTypeEnum;

export type SelectedSortType = {
  live: PublishSortType;
  drafted: DraftedSortType;
  recommended: DraftedSortType;
};

export type CommonFilters = {
  date_range: {
    startDate: Date;
    endDate: Date;
  };
  brands: string[];
  brand_websites: string[];
  cohorts_type: string[];
  selectedBrand: "all" | string[];
  selectedBrandWebsite: "all" | string[];
  selectedCohortTypes: string[];
  selectedDateRange: {
    startDate: Date;
    endDate: Date;
  };
  type: string[];
};

export type ISetCommonFilters = {
  key: ("date_range" | "brands" | "brand_websites")[];
  value: (
    | {
        startDate: string;
        endDate: string;
      }
    | string[]
  )[];
};
export type PublishedCohortsFilter = {
  spends: {
    min: number;
    max: number;
    selected?: number[];
  };
  results: {
    min: number;
    max: number;
    selected?: number[];
  };
  cpr: {
    min: number;
    max: number;
    selected?: number[];
  };
  ad_accounts: string[];
  selectedAdAccount?: string;
};

export type ISetPublishedCohortsFilter = PublishedCohortsFilter | {};

export type DraftedCohortFilters = {
  audience_size: {
    min: number;
    max: number;
    selected?: number[];
  };
  relevance_score: {
    min: number;
    max: number;
    selected?: number[];
  };
  performance_score: string[];
  cohort_type: string[];
  selectedCohortTypes: "all" | string[];
};

export type ISetDraftedCohortsFilter = DraftedCohortFilters | {};

export type GetPublishedCohorts = {
  type: typeof GET_PUBLISHED_COHORTS;
  payload: IGetPublishedCohortsPayload;
};

export type GetDraftedCohorts = {
  type: typeof GET_DRAFTED_COHORTS;
  payload: IGetDraftedCohortsPayload;
};

export type GetDraftedCohortFilters = {
  type: typeof GET_DRAFTED_COHORT_FILTERS;
  payload: {
    aiGroupId: string;
    status: "isDrafted" | "isRecommended";
  };
};

export type GetPublishedCohortsFilter = {
  type: typeof GET_PUBLISHED_COHORTS_FILTER;
  payload: {
    aiGroupId: string;
  };
};

export type SetPublishedCohorts = {
  type: typeof SET_PUBLISHED_COHORTS;
  payload: ISetPublishedCohortsData[];
};

export type SetDraftedCohorts = {
  type: typeof SET_DRAFTED_COHORTS;
  payload: ISetDraftedCohortsData[];
};

export type SetRecommendedCohorts = {
  type: typeof SET_RECOMMENDED_COHORTS;
  payload: ISetDraftedCohortsData[];
};

export type SetDraftedCohortsFilters = {
  type: typeof SET_DRAFTED_COHORT_FILTERS;
  payload: ISetDraftedCohortsFilter;
};

export type SetRecommendedCohortsFilters = {
  type: typeof SET_RECOMMENDED_COHORTS_FILTERS;
  payload: ISetDraftedCohortsFilter;
};

export type SetPublishedCohortsFilters = {
  type: typeof SET_PUBLISHED_COHORTS_FILTER;
  payload: ISetPublishedCohortsFilter;
};

export type SetSelectedCohortType = {
  type: typeof SET_SELECTED_COHORT_TYPE;
  payload: {
    cohortType: SelectedCohortType;
    callback: any;
  };
};

export type SetSelectedSortType = {
  type: typeof SET_SELECTED_SORT_TYPE;
  payload: SelectedSortType;
};

export type SetLoading = {
  type: typeof SET_LOADING_AUDIENCE_MANAGER;
  payload: any;
};

export type SetSelectedAiGroupAudienceManager = {
  type: typeof SET_SELECTED_AI_GROUP_AUDIENCE_MANAGER;
  payload: any;
};
export type SetCommonFilters = {
  type: typeof SET_COMMON_FILTERS;
  payload: ISetCommonFilters;
};
export type SetCommonFiltersBrand = {
  type: typeof SET_COMMON_FILTERS_BRAND;
  payload: string[];
};
export type SetCommonFiltersBrandWebsite = {
  type: typeof SET_COMMON_FILTERS_BRAND_WEBSITE;
  payload: string[];
};
export type SetBucketLength = {
  type: typeof SET_BUCKET_LENGTH;
  payload: {
    type: string;
    length: number;
  };
};
export type SetBucketSkip = {
  type: typeof SET_BUCKET_SKIP;
  payload: {
    type: string;
    length: number;
  };
};
export type ResetAllAudienceManegerData = {
  type: typeof RESET_ALL_AUDIENCE_MANAGER_DATA;
};
export type SetSelectedPlatform = {
  type: typeof SET_SELECTED_PLATFORM;
  payload: "facebook" | "youtube";
};

export type SetSelectedTypes = {
  type: typeof SET_SELECTED_TYPES;
  payload: string[];
};

export type AudienceManagerState = {
  selectedAiGroup: null | {
    id: string;
    name: string;
    adAccounts?: any[];
    brandId?: string;
  };
  selectedPlatform: "facebook" | "youtube";
  selectedCohort: null | ISetPublishedCohortsData | ISetDraftedCohortsData;
  publishedCohortsData: ISetPublishedCohortsData[];
  draftedCohortsData: ISetDraftedCohortsData[];
  recommendedCohortsData: ISetDraftedCohortsData[];
  commonFilterData: CommonFilters;
  publishedCohortsFilterData: PublishedCohortsFilter | {};
  draftedCohortsFiltersData: DraftedCohortFilters | {};
  recommendedCohortsFilterData: DraftedCohortFilters | {};
  selectedCohortType: SelectedCohortType;
  isLoading: {
    live: boolean;
    recommended: boolean;
    drafted: boolean;
  };
  length: {
    live: number;
    recommended: number;
    drafted: number;
  };
  skip: {
    live: number;
    recommended: number;
    drafted: number;
  };
  selectedSortType: {
    live: PublishSortType;
    drafted: DraftedSortType;
    recommended: DraftedSortType;
  };
};
