import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { Box, PaginationProps } from "@mui/material";
import { StyledButton, StyledEclipse, StyledNextButton } from "./styled";
import { ReactComponent as Check } from "../../../../../assets/svg/check_small.svg";
import { useSelector } from "react-redux";
import { ReviewInitialState } from "store/types/CreativeAI/shared/Review/review";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

interface PaginationReviewProps extends PaginationProps {}

export default function PaginationReview({ ...props }: PaginationReviewProps) {
  const { items } = usePagination({
    ...props,
  });
  const {
    requestedVariantsMetaData: { data },
  } = useSelector(
    (state: { reviewReducer: ReviewInitialState }) => state.reviewReducer
  );
  return (
    <nav>
      <List>
        {items.map(
          ({ page, type, selected, onClick, disabled, ...item }, index) => {
            let children = null;
            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = <StyledEclipse>…</StyledEclipse>;
            } else if (type === "page") {
              children = (
                <Box display="flex" alignItems="center" flexDirection="column">
                  <StyledButton selected={selected} onClick={(e) => onClick(e)}>
                    {page}
                  </StyledButton>
                  {data?.variants &&
                  page &&
                  data?.variants?.[page - 1]?.reviewStatus !==
                    "UNDER_REVIEW" ? (
                    <Check
                      style={{
                        height: "12px",
                        width: "12px",
                        marginTop: ".3em",
                      }}
                    />
                  ) : (
                    <Box />
                  )}
                </Box>
              );
            } else {
              children = (
                <StyledNextButton
                  disabled={disabled}
                  onClick={(e) => onClick(e)}
                >
                  {type === "previous" ? "Prev" : "Next"}
                </StyledNextButton>
              );
            }

            return <li key={index}>{children}</li>;
          }
        )}
      </List>
    </nav>
  );
}
