import { SetMontageCampaigns, SetSelectedMontageCampaign, SET_MONTAGE_CAMPAIGNS, SET_SELECTED_MONTAGE_CAMPAIGN, ToggleVideoPanel, TOGGLE_VIDEO_PANEL } from "../types/montageAds"


const initialState={
    montageCampaigns:[],
    toggleVideoPanel:false,
    selectedMontageCampaign:{}
}

export default function montageReducer (state=initialState, action: SetMontageCampaigns
    |ToggleVideoPanel
    |SetSelectedMontageCampaign
    ){
    switch(action.type){
        case SET_MONTAGE_CAMPAIGNS:{
            return{
                ...state,
                montageCampaigns: action.payload
            }
        }
        case TOGGLE_VIDEO_PANEL:{
            return{
                ...state,
                toggleVideoPanel: action.payload
            }
        }
        case SET_SELECTED_MONTAGE_CAMPAIGN:{
            return{
                ...state,
                selectedMontageCampaign: action.payload
            }
        }
        default: return state;
    }
}

