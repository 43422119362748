import { Box, Stack, Typography, Button, Modal } from "@mui/material";
import React from "react";
import saved from "../../../assets/tai/savedModalImg.svg";

interface ISaveModalProps {
  buttonClickHandler?: React.MouseEventHandler<any>;
  descriptionElement?: React.ReactElement;
}
function SaveModal(props: ISaveModalProps) {
  const { buttonClickHandler = undefined } = props;
  return (
    <Modal open={true} onClose={buttonClickHandler}>
      <Box
        className="container-overlay"
        sx={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.7)",
          zIndex: "1032",
          top: "0",
          left: "0",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex flex-column focus:outline-none"
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "0.5rem",
              width: "24.25rem",
              height: "25.81rem",
              padding: "2.5rem",
            }}
          >
            <Stack
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={saved} alt="success" />
              <Typography className="font-30 font-600" sx={{ color: "#333" }}>
                Saved!
              </Typography>
              {props.descriptionElement ? (
                props.descriptionElement
              ) : (
                <Typography
                  className="font-14 font-500"
                  textAlign="center"
                  sx={{
                    color: "#727272",
                    marginBottom: "2rem !important",
                  }}
                >
                  You can view all your saved Cohorts in Audience Manager under
                  Targeting AI
                </Typography>
              )}

              <Button
                sx={{
                  backgroundColor: "#0869FB",
                  width: "11.75rem",
                }}
                variant="contained"
                onClick={buttonClickHandler}
              >
                Got it
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default SaveModal;
