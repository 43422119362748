import { Box, Button, styled } from "@mui/material";

export const StyledButton = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 15px;
  color: ${({ selected }: { selected?: boolean }) =>
    selected ? "#ffffff" : "#BCBCBC"};
  background: ${({ selected }: { selected?: boolean }) =>
    selected ? "#0869fb" : "#ffffff"};
  margin: 0em 0.5em;
  border: ${({ selected }: { selected?: boolean }) =>
    selected ? "none" : "1px solid #bcbcbc"};
  border-radius: 6px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledEclipse = styled(Box)`
  padding: 8px;
  background: transparent;
`;

export const StyledNextButton = styled(Button)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0869fb;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0em -0.4em;
  text-transform: none;
`;
