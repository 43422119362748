//*******DO NOT REFER THIS FILE FOR SAGA IMPLEMENTATION*******//

import {
  FETCH_ALLOW_REJECT_RECOMMENDATION,
  FETCH_PHASE,
  FETCH_LEARNING_PHASE,
  initialState,
  FETCH_ACCOUNT_DETAILS,
  REFRESH_TOKEN,
} from "./../types/auth";
import { takeLatest, call, select, put } from "redux-saga/effects";
import { BASE_URL, CROSS_PLATFORM_URL } from "../../utils/path";
import HttpService from "../../HttpService/HttpService";
import {
  setAccountDetails,
  setActivePhase,
  setAllowRejectRecommendation,
  setIsRefreshTokenExpired,
  setLearningPhase,
  setPhase,
  setSession,
} from "../actions/auth";
import axios from "axios";
import {
  getLocalStorageData,
  postMessage,
  setLocalStorageData,
} from "../../utils/commonFunctions";
import {
  FACEBOOK_PLATFORM_NAME,
  user_session_key,
} from "../../utils/constants";
import { AdAccountState } from "../types/adaccount";
import { crossPlatform } from "../../MappedAPI/MappedAPI";

const getUser = (state: { auth: initialState }) => state?.auth?.user;
const getAccountId = (state: { adaccount: AdAccountState }) =>
  state.adaccount?.selectedAdAccount?.id;
const getTragetingAccountId = (state: { adaccount: AdAccountState }) =>
  state.adaccount?.selectedTargetingAdaccount?.id;
const getBrandId = (state: { adaccount: AdAccountState }) =>
  state.adaccount.selectedBrand?.id;
const getPlatformId = (state: { adaccount: AdAccountState }) =>
  state.adaccount.selectedPlatform?.id;
const getPlatforms = (state: { adaccount: AdAccountState }) =>
  state.adaccount.platforms;

function* getAccountDetails(payload: any): any {
  let accountIdd = payload.payload;
  try {
    const user: any = yield select(getUser);
    const accountId = accountIdd ? accountIdd : yield select(getAccountId);
    const platformId = yield select(getPlatformId);
    const platforms = yield select(getPlatforms);
    const foundPlatform = platforms.find((pl: any) => pl.id === platformId);
    let platformType: any = "";
    // platformType = getPlatformName(foundPlatform?.name)?.toLocaleLowerCase();
    // console.log(platformType,foundPlatform?.name,"");
    if (foundPlatform?.name.toLowerCase().includes("facebook")) {
      platformType = "facebook";
    } else if (foundPlatform?.name.toLowerCase().includes("linkedin")) {
      platformType = "linkedin";
    } else if (foundPlatform?.name.toLowerCase().includes("google")) {
      platformType = "google";
    } else if (foundPlatform?.name.toLowerCase().includes("microsoft")) {
      platformType = "microsoft";
    } else if (foundPlatform?.name.toLowerCase().includes("dv360")) {
      platformType = "dv360";
    } else if (foundPlatform?.name.toLowerCase().includes("apple")) {
      platformType = "apple";
    } else if (foundPlatform?.name.toLowerCase().includes("pinterest")) {
      platformType = "pinterest";
    } else {
      platformType = "";
    }
    const path = `/v1/api/ad-account/${accountId}/platform/${platformType}/details`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      path,
      user
    );
    if (response.data.code === "101") {
      return;
    }

    yield put(
      setAccountDetails(
        Object.assign({}, response.data.data, { platform: platformType })
      )
    );
    //console.log("AcoountDetails",AccountDetails);
  } catch (error) {
    console.log("Logger: GetAccountDetails -> error", error);
  }
}

function* getPhase(): any {
  try {
    // const user = yield select(getUser);
    const brandID = yield select(getBrandId);
    const path = `/v1/api/phases/${brandID}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, path);
    if (response.data.code === "101") {
      return;
    }
    const phases = [
      response.data.phase1,
      response.data.phase2,
      response.data.phase3,
      response.data.phase4,
      response.data.phase5,
    ];

    yield put(setPhase(response.data));
    yield put(setActivePhase(phases.findIndex((element) => element === 1) + 1));
  } catch (error) {
    console.log("Logger: GetPhases -> error", error);
  }
}

function* getLearningPhase(): any {
  try {
    const user = yield select(getUser);
    const accountId = yield select(getTragetingAccountId);
    const brandId = yield select(getBrandId);
    const path = `/v1/api/brand-ad-account-settings/${brandId}/${accountId}/tai-learning-phase`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      path,
      user
    );
    if (response.data.code === "101") {
      return;
    }
    yield put(
      setLearningPhase(
        !response.data ? true : response.data.data.learningPhase !== false
      )
    );
  } catch (error) {
    console.log("Logger: ", error);
  }
}

function* getAllowRejectRecommendation(): any {
  try {
    const user = yield select(getUser);
    const accountId = yield select(getAccountId);
    const path = `${crossPlatform}/recommendation/${accountId}/detail`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      path,
      user
    );
    const allowValue: boolean =
      typeof response?.data?.data?.automatic_execution === "boolean"
        ? response?.data?.data?.automatic_execution
        : true;
    yield put(setAllowRejectRecommendation(allowValue));
  } catch (error) {
    yield put(setAllowRejectRecommendation(true));
    console.log("Logger: ", error);
  }
}

function* refreshToken({ callback }: any): any {
  try {
    const token = getLocalStorageData(user_session_key)?.refreshToken;
    const response = yield axios.post(`${BASE_URL}/api/v3/auth/refresh-token`, {
      refreshToken: token,
    });
    let session: any = {
      refreshToken: response.data.data.refresh_token,
      accessToken: response.data.data.access_token,
      expiresAt: new Date().getTime() + response.data.data.expires_in,
    };
    yield put(setSession(session));

    setLocalStorageData(user_session_key, session);
    setTimeout(() => {
      postMessage({ from: "PYXIS_DASHBOARD", data: session });
    }, 2000);
    callback && callback(session);
  } catch (error) {
    yield put(setIsRefreshTokenExpired(true));
    yield put(setSession(null));
    callback && callback();
    localStorage.removeItem(user_session_key);
    console.log("Logger: ", error);
  }
}

export function* authWatcher() {
  yield takeLatest(FETCH_PHASE, getPhase);
  yield takeLatest(
    FETCH_ALLOW_REJECT_RECOMMENDATION,
    getAllowRejectRecommendation
  );
  yield takeLatest(FETCH_LEARNING_PHASE, getLearningPhase);
  yield takeLatest(REFRESH_TOKEN, refreshToken);
  yield takeLatest(FETCH_ACCOUNT_DETAILS, getAccountDetails);
}
