import React, { memo } from "react";
import { DateRangePicker } from "react-date-range";
import { Button } from "@mui/material";

function LogScreenDateRange({
  dateSelection,
  handleSelect,
  setShowRange,
}: any) {
  return (
    <div className="daterangeSelector">
      <DateRangePicker
        ranges={[dateSelection]}
        onChange={(e) => handleSelect(e)}
        direction="horizontal"
        editableDateInputs={true}
        moveRangeOnFirstSelection={false}
      />
      {/* <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setShowRange(false);
        }}
        sx={{ float: "right", textTransform: "capitalize" }}
      >
        Submit
      </Button> */}
    </div>
  );
}

export const LogScreenDateRangeMemo = memo(LogScreenDateRange);
