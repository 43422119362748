export const SET_CREATIVE_AI_ACCOUNT = "SET_CREATIVE_AI_ACCOUNT";
export const GET_BRAND_ADACCOUNTS = "GET_BRAND_ADACCOUNTS";

export type creativeAiState = {
    creativeAiAccount: [];
}

export type SetCreativeAiAccount = {
    type: typeof SET_CREATIVE_AI_ACCOUNT,
    payload: [];
}
