import { SetCreativeAI, SetCreativeAILoading, SetCreativeRotationToggle, SetFormLoading, SetForms, SetFormsLoading, SetGoogleOnboarding, SetGoogleOnBoardingLoading, SetIsOnboarding, SetModal, SetOnBoardingLoading, SetPdfFile, SetTargetingAI, SetTargetingAILoading, SetTargetingForm, SET_CREATIVEAI_LOADING, SET_CREATIVE_AI, SET_CREATIVE_ROTATION_TOGGLE, SET_FORMS, SET_FORMS_LOADING, SET_FORM_LOADING, SET_GOOGLE_ONBOARDING, SET_GOOGLE_ONBOARDING_LOADING, SET_MODAL, SET_ONBOARDING, SET_ONBOARDING_LOADING, SET_PDF_FILE, SET_TARGETING_AI, SET_TARGETING_FORM, SET_TARGETING_LOADING } from "../types/form";

const initialState = {
    forms: null,
    formLoading: false,
    targetingForm: null,
    isOnBoarding: false,
    pdfFile: null,
    targetingAI: false,
    creativeAI:null,
    googleOnboarding:false,
    modal: null,
    creativeRotationToggle:{},
    targetingLoading: false,
    creativeAILoading:false,
    onBoardingLoading: false,
    googleOnboardingLoading:false,
    fetchedGoolgeOnboardingStatus: false
};

const formReducer = (state = initialState, action: SetForms
    | SetFormsLoading
    | SetTargetingForm
    | SetIsOnboarding
    | SetPdfFile
    | SetTargetingAI
    | SetCreativeAI
    | SetCreativeRotationToggle
    | SetGoogleOnboarding
    | SetModal
    | SetFormLoading
    | SetTargetingAILoading
    | SetCreativeAILoading
    | SetOnBoardingLoading
    | SetGoogleOnBoardingLoading) => {
    switch (action.type) {
        case SET_FORMS: {
            return {
                ...state,
                forms: action.payload
            }
        }
        case SET_FORMS_LOADING: {
            return {
                ...state,
                formLoading: action.payload
            }
        }
        case SET_TARGETING_FORM: {
            return {
                ...state,
                targetingForm: action.payload
            }
        }
        case SET_ONBOARDING: {
            return {
                ...state,
                isOnBoarding: action.payload
            }
        }
        case SET_PDF_FILE: {
            return {
                ...state,
                pdfFile: action.payload
            }
        }
        case SET_TARGETING_AI: {
            return {
                ...state,
                targetingAI: action.payload
            }
        }
        case SET_CREATIVE_AI: {
            return {
                ...state,
                creativeAI: action.payload
            }
        }
        case SET_GOOGLE_ONBOARDING: {
            return {
                ...state,
                googleOnboarding: action.payload,
                fetchedGoolgeOnboardingStatus: true
            }
        }
        case SET_MODAL: {
            return {
                ...state,
                modal: action.payload
            }
        }
        case SET_FORM_LOADING: {
            return {
                ...state,
                formLoading: action.payload
            }
        }
        case SET_TARGETING_LOADING: {
            return {
                ...state,
                targetingLoading: action.payload
            }
        }
        case SET_CREATIVEAI_LOADING: {
            return {
                ...state,
                creativeAILoading: action.payload
            }
        }
        case SET_ONBOARDING_LOADING: {
            return {
                ...state,
                onBoardingLoading: action.payload
            }
        }
        case SET_GOOGLE_ONBOARDING_LOADING: {
            return {
                ...state,
                googleOnBoardingLoading: action.payload
            }
        }
        // case SET_CREATIVE_ROTATION_TOGGLE:{
        //     return{
        //         ...state,
        //         creativeRotationToggle:
        //     }
        // }
        default:
            return state;
    }
};

export default formReducer;