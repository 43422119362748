import { Box, CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import HomePage from "./common/HomePage";
import "./index.scss";
import { AdAccountState } from "store/types/adaccount";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Settings } from "assets/svg/insights_settings.svg";
import SuperAdminSettings from "components/DataStudio/SuperAdminSettings";
import InsightsNavigation from "./InsightsNavigation";
import { getAdAccounts } from "./API/api.getAdAccounts";
import { setParentPlatform } from "store/actions/adaccount";
import { postLinks } from "./API/api.postLinks";
import { resetLinks } from "./API/api.resetLinks";
import { getParentPlatform } from "./API/api.getParentPlarform";
import BudgetPacingLoader from "Pages/BudgetPlanner/loader";
import {
  getSearchParams,
  isAdminRole,
  updateSearchParams,
} from "utils/commonFunctions";

function Insights(props: any) {
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { parentPlatforms, selectedBrand, brandRoles } = adaccount;
  const [selectedAdAcc, setSelectedAdAcc] = useState<any>(null);
  const [listOfAdAcc, setListOfAdAcc] = useState([]);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { userInfo } = useSelector((state: any) => state.userInfoReducer);
  // const isAdmin = userInfo?.roles?.includes("super-admin");

  useEffect(() => {
    getCall();
  }, [selectedBrand]);

  const getCall = () => {
    if (selectedBrand?.id) {
      setLoading(true);
      getAdAccounts(selectedBrand?.id, props.type)
        .then((result: any) => {
          if (parentPlatforms.length) {
            const data = result.data.insights;
            setListOfAdAcc(data.filter((item: any) => item.parentPlatformId));
            parentPlatforms.map((eachParent: any) => {
              eachParent.addAccounts = [];
              data.map((eachAcc: any) => {
                if (
                  eachAcc.parentPlatformId &&
                  eachParent.id === eachAcc.parentPlatformId &&
                  !eachParent.addAccounts.some(
                    (g: any) => g.adAccountId === eachAcc.adAccountId
                  )
                ) {
                  eachParent.addAccounts.push(eachAcc);
                }
              });
            });
            if (getSearchParams("insight_adacc")) {
              setSelectedAdAcc(
                data.find(
                  (eachAdd: any) =>
                    eachAdd.adAccountId?.toString() ===
                    getSearchParams("insight_adacc")
                )
              );
            } else {
              setSelectedAdAcc(
                data.find((eachAdd: any) => eachAdd.link) || data[0]
              );
            }
            dispatch(setParentPlatform([...parentPlatforms]));
          } else {
            getParentPlatform().then((parentPlatfrom: any) => {
              const data = result.data.insights;
              const newData = parentPlatfrom.data;
              setListOfAdAcc(data.filter((item: any) => item.parentPlatformId));
              newData.map((eachParent: any) => {
                eachParent.addAccounts = [];
                data.map((eachAcc: any) => {
                  if (
                    eachAcc.parentPlatformId &&
                    eachParent.id === eachAcc.parentPlatformId &&
                    !eachParent.addAccounts.some(
                      (g: any) => g.adAccountId === eachAcc.adAccountId
                    )
                  ) {
                    eachParent.addAccounts.push(eachAcc);
                  }
                });
              });
              if (getSearchParams("insight_adacc")) {
                setSelectedAdAcc(
                  data.find(
                    (eachAdd: any) =>
                      eachAdd.adAccountId?.toString() ===
                      getSearchParams("insight_adacc")
                  ) || data[0]
                );
              } else {
                setSelectedAdAcc(
                  data.find((eachAdd: any) => eachAdd.link) || data[0]
                );
              }
              dispatch(setParentPlatform([...newData]));
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onInputChange = (index: number, value: string) => {
    let templistOfAdAcc: any = [...listOfAdAcc];
    templistOfAdAcc[index].link = value;
    setListOfAdAcc(templistOfAdAcc);
  };

  const handleSubmit = () => {
    if (listOfAdAcc.length) {
      let payload = {
        type: props.type,
        data: listOfAdAcc.map((each: any) => {
          return {
            adAccountId: each.adAccountId,
            link: each.link,
          };
        }),
      };
      postLinks(selectedBrand?.id, payload).then((data) => {
        setModal(false);
      });
    }
  };

  const onResetLink = () => {
    resetLinks(selectedBrand?.id, props.type).then((data) => {
      getCall();
    });
  };

  useEffect(() => {
    if (selectedAdAcc)
      updateSearchParams("insight_adacc", selectedAdAcc?.adAccountId || "");
  }, [selectedAdAcc]);

  return (
    <>
      {loading && <BudgetPacingLoader />}
      <div className="flex-center navigation-dropdown justify-content-between my-2">
        <InsightsNavigation
          parentPlatforms={parentPlatforms}
          selectedAdAcc={selectedAdAcc}
          setSelectedAdAcc={setSelectedAdAcc}
        />
        <Box
          sx={{
            background: "#FFF",
            borderRadius: "6px",
          }}
        >
          {/* {isAdmin ? ( */}
          {isAdminRole(brandRoles) ? (
            <IconButton
              onClick={() => {
                setModal(true);
              }}
            >
              <Settings />
            </IconButton>
          ) : (
            ""
          )}
        </Box>
      </div>
      {selectedAdAcc?.link ? (
        <iframe
          width="100%"
          height="100%"
          src={selectedAdAcc.link}
          frameBorder={0}
          style={{
            border: "0",
            display: "block",
          }}
          allowFullScreen
        ></iframe>
      ) : (
        <Box
          className="insights font-inter"
          sx={{ height: "100%", background: "#fff" }}
        >
          <HomePage
            type={props.type}
            selectedAdAcc={selectedAdAcc}
            selectedBrand={selectedBrand}
            getCall={getCall}
          />
        </Box>
      )}
      {modal ? (
        <SuperAdminSettings
          setModal={setModal}
          listOfAdAcc={listOfAdAcc}
          onInputChange={onInputChange}
          handleSubmit={handleSubmit}
          onResetLink={onResetLink}
          parentPlatforms={parentPlatforms}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Insights;
