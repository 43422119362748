import { Box } from "@mui/material";
import { useEffect } from "react";
import { CheckBoxFormControl } from "../../../../shared";
import { LeversType } from "../SharedTypes";
import LeverField from "./LeverField";

export default function Levers({
  lever,
  isConfigure,
  registeredName,
  control,
  getValues,
  setValue,
  formType,
  errors,
  setError,
  clearErrors,
  helperFunction,
}: {
  lever: LeversType;
  isConfigure?: boolean;
  registeredName: any;
  control: any;
  getValues: any;
  setValue: any;
  formType?: string;
  errors: any;
  setError: any;
  clearErrors: any;
  helperFunction?: (registeredName: string) => void;
}) {
  const finalRegisteredName = registeredName + "." + lever.lever_name;
  const checkedFieldName = finalRegisteredName + ".isChecked";
  useEffect(() => {
    if (formType === "BU") {
      setValue(checkedFieldName, false);
    }
  }, []);

  return (
    <>
      {lever.is_generate_supported && (
        <Box display="flex" flexDirection="column" width="100%" mb="10px">
          {formType !== "BU" && (
            <CheckBoxFormControl
              label={lever.lever_label}
              tooltipText={lever.helper_text}
              checkedFieldName={checkedFieldName}
              getValues={getValues}
              setValue={setValue}
              helperFunction={helperFunction}
            />
          )}

          {(getValues(checkedFieldName) || formType === "BU") && (
            <Box
              display="flex"
              width="100%"
              pt="5px"
              pl="10px"
              flexWrap="wrap"
              flexDirection={isConfigure ? "row" : "column"}
            >
              {lever.fields.length !== 0 &&
                lever.fields.map((field) => (
                  <LeverField
                    field={field}
                    control={control}
                    isConfigure={isConfigure}
                    registeredName={finalRegisteredName}
                    setValue={setValue}
                    getValues={getValues}
                    formType={formType}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    helperFunction={helperFunction}
                  />
                ))}

              {/* let's keep it there for future */}
              {/* : (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="start"
    width="100%"
    color="red"
    pl="3px"
    sx={{
      paddingBottom: "10px",
      fontSize: "13px",
    }}
  >
    We are taking care of this field for you
  </Box>
)} */}
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
