import { Box, IconButton } from "@mui/material";
import { TextInput } from "components/shared";
import React, { useEffect, useState } from "react";
import { ReactComponent as Save } from "../../../../../../../assets/svg/save_transparent.svg";
import { ReactComponent as Edit } from "../../../../../../../assets/svg/edit_transparent.svg";
import { StyledInfoCharText, StyledInfoErrorText } from "./styeld";
import { useSelector } from "react-redux";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";

interface EditableInputProps {
  onSave?: (index: number, value: string, frameIndex: number) => void;
  index: number;
  value: string;
  frameIndex: number;
  onImageClick: (index: number) => void;
  disabled: boolean;
  setEditContext: any;
  id: string;
  min: number;
  max: number;
  isFirst: boolean;
  isLast: boolean;
  iteration: number;
}

export default function EditableInput({
  onSave,
  index,
  value,
  frameIndex,
  onImageClick,
  disabled,
  setEditContext,
  id,
  min,
  max,
  isFirst,
  isLast,
  iteration,
}: EditableInputProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputState, setInputState] = useState("");
  const {
    recommendationSelectionState: { video },
    extraFields: { selectedVariantIndex },
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );

  const onChange = (event: any) => {
    setInputState(event.target.value);
  };

  useEffect(() => {
    setInputState(value);
  }, [value]);

  useEffect(() => {
    setIsEditing(false);
    setEditContext({
      id: "",
      iteration: -1,
    });
  }, [video, selectedVariantIndex]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          background: "#FFFFFF",
          paddingLeft: isFirst ? "10px" : "0px",
          margin: "4px 0px",
          borderRadius: isFirst
            ? "6px 0px 0px 6px"
            : isLast
            ? "0px 6px 6px 0px"
            : "0px",
        }}
      >
        <Box display="flex" alignItems="center">
          <TextInput
            onChange={onChange}
            value={inputState}
            disableUnderline
            readOnly={!isEditing}
            isBorder={isEditing}
            sx={{
              height: "32px",
              borderRadius: "6px 0px 0px 6px",
              fontSize: "13px",
              color: isEditing ? "#1E9FDC" : "#FFFFFF",
              fontWeight: 500,
              width: `calc(8px + ${inputState.length + 1 + "ch"})`,
              padding: "8px 0px 8px 12px",
            }}
            backgroundColor={isEditing ? "#FFFFFF" : "#2BB3F4"}
            borderColor={isEditing ? "#2BB3F4" : undefined}
            inputProps={{
              maxLength: max,
            }}
          />
          <IconButton
            sx={{
              padding: "0px",
            }}
            disabled={disabled || inputState.length < min}
            onClick={() => {
              if (isEditing) {
                setIsEditing(false);
                setEditContext({
                  id: "",
                  iteration: -1,
                });
                onSave && onSave(index, inputState, frameIndex);
              } else {
                setEditContext({ id, iteration });
                onImageClick(frameIndex);
                setIsEditing(true);
              }
            }}
          >
            {" "}
            <Box
              sx={{
                width: "32px",
                height: "32px",
                background: "#1E9FDC",
                borderRadius: "0px 6px 6px 0px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {isEditing ? <Save /> : <Edit />}
            </Box>
          </IconButton>
        </Box>
      </Box>
      {isEditing && (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <StyledInfoCharText>
            {inputState.length}/{max} Characters
          </StyledInfoCharText>

          {inputState.length < min ? (
            <StyledInfoErrorText>Min {min} Char required *</StyledInfoErrorText>
          ) : (
            <Box
              sx={{
                padding: "6px",
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
}
