import React from "react";
import "./index.scss";
import { useDispatch } from "react-redux";
import { setSelectedTabIndex } from "../../store/actions/cluster";
const Tab: React.FC<{
  data?: any;
  selectedTabIndex?:number
  onClick: (value: any) => void | Function;
}> = ({ data,selectedTabIndex, onClick }) => {
  const dispatch = useDispatch();
  return (
    <div className="d-flex flex-row tabs">
         {data.map((d: any,i:number) => (
            <div key={i} className={"tab-item"+(selectedTabIndex===i?' active':'')} onClick={(e: any)=>{
              dispatch(setSelectedTabIndex(i));
              onClick(i);
            }}>{d}</div>
          ))}
    </div>
  );
};

export default Tab;
