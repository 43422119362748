export const SET_ACTION_DEBUGGER_SWITCH = "SET_ACTION_DEBUGGER_SWITCH";
export const TOGGLE_COMPARE_FLAG = "TOGGLE_COMPARE_FLAG";
export const SET_CURRENT_PERIOD = "SET_CURRENT_PERIOD";
export const SET_PREVIOUS_PERIOD = "SET_PREVIOUS_PERIOD";
export const SET_COMPARE_TYPE = "SET_COMPARE_TYPE";
export const GET_ALL_METRICS = "GET_ALL_METRICS";
export const SET_ALL_METRICS = "SET_ALL_METRICS";
export const GET_ELEMENT_METRICS = "GET_ELEMENT_METRICS";
export const SET_ELEMENT_METRICS = "SET_ELEMENT_METRICS";
export const GET_METRIC_GRAPH_DATA = "GET_METRIC_GRAPH_DATA";
export const SET_METRIC_GRAPH_DATA = "SET_METRIC_GRAPH_DATA";
export const SET_METRIC_OLD_GRAPH_DATA = "SET_METRIC_OLD_GRAPH_DATA";
export const SAVE_METRIC_ELEMENT = "SAVE_METRIC_ELEMENT";
export const SET_METRIC_MAPPER = "SET_METRIC_MAPPER";
export const SET_GRAPH_UPDATE_LOADER = "SET_GRAPH_UPDATE_LOADER";
export const SET_METRIC_LOADER = "SET_METRIC_LOADER";
export const SET_ATTRIBUTION_WINDOW = "SET_ATTRIBUTION_WINDOW";
export const SET_METRIC_GRAPH_DATA_LOADER = "SET_METRIC_GRAPH_DATA_LOADER";
export const SET_METRIC_OLD_GRAPH_DATA_LOADER =
  "SET_METRIC_OLD_GRAPH_DATA_LOADER";
export const SET_IS_DEFAULT = "SET_IS_DEFAULT";
export const SET_GRAPH_METRICS = "SET_GRAPH_METRICS";
export const SET_ATTRIBUTION_WINDOW_TYPE = "SET_ATTRIBUTION_WINDOW_TYPE";
export const GET_ATTRIBUTION_WINDOW_TYPE = "GET_ATTRIBUTION_WINDOW_TYPE";
export const GET_ELEMENT_ATTRIBUTION_WINDOW = "GET_ELEMENT_ATTRIBUTION_WINDOW";
export const UPDATE_ELEMENT_ATTRIBUTION_WINDOW =
  "UPDATE_ELEMENT_ATTRIBUTION_WINDOW";
export const SET_ATTRIBUTION_WINDOW_LOADER = "SET_ATTRIBUTION_WINDOW_LOADER";
export const SET_CHANGE_METRIC_FINISH = "SET_CHANGE_METRIC_FINISH";
export const SET_ATTRIBUTION_WINDOW_ELEMENT_ID =
  "SET_ATTRIBUTION_WINDOW_ELEMENT_ID";

export interface ActionDebuggerInitStateType {
  actionDebuggerSwitch: boolean;
  isCompare: boolean;
  currentPeriod: SelectedDateType;
  previousPeriod: SelectedDateType;
  compareType: number;
  additionalDateInfo: {
    paddedDay: number;
    differenceInCalenderDay: number;
  };
  allMetrics: AllMetricsType[];
  elementMetrics: MetricsType[];
  metricGraphData: any;
  metricOldGraphData: any;
  metricMapper: LooseObject;
  graphUpdateLoader: Boolean;
  metricLoader: boolean;
  attributionWindow: string;
  currentGraphLoading: boolean;
  previousGraphLoading: boolean;
  isDefault: boolean;
  graphMetrics: MetricsType[];
  attributionWindowType: AttributionWindow[];
  attributionLoader: boolean;
  changeMetricFinish: boolean;
  attributionWindowElementId: string;
}

export type SetActionDebuggerSwitchType = {
  type: typeof SET_ACTION_DEBUGGER_SWITCH;
  payload: { payload: boolean };
};

export type ToggleCompareFlag = {
  type: typeof TOGGLE_COMPARE_FLAG;
  payload: boolean;
};

export type SetCurrentPeriod = {
  type: typeof SET_CURRENT_PERIOD;
  payload: SelectedDateType;
};

export type SetPreviousPeriod = {
  type: typeof SET_PREVIOUS_PERIOD;
  payload: SelectedDateType;
};
export type SetCompareType = {
  type: typeof SET_COMPARE_TYPE;
  payload: number;
};
export type GetAllMetrics = {
  type: typeof GET_ALL_METRICS;
  payload: {
    elementId: string;
  };
};
export type SetAllMetrics = {
  type: typeof SET_ALL_METRICS;
  payload: MetricsType[];
};
export type GetElementMetrics = {
  type: typeof GET_ELEMENT_METRICS;
  payload: {
    elementId: string;
  };
};
export type SetElementMetrics = {
  type: typeof SET_ELEMENT_METRICS;
  payload: MetricsType[];
};
export type GetMetricGraphData = {
  type: typeof GET_METRIC_GRAPH_DATA;
  payload: {
    typeOfData: string;
    elementId: string;
    start_date: string;
    end_date: string;
    adsetIds?: any;
    campaignIds?: any;
    adMetricIds?: string;
    data?: any;
    callback?: any;
    loaderAction?: string;
    attributionWindow: string;
    getMetricGraphData?: string;
    resultMetricId?: string;
  };
};
export type SetMetricGraphData = {
  type: typeof SET_METRIC_GRAPH_DATA;
  payload: any;
};
export type SetMetricOldGraphData = {
  type: typeof SET_METRIC_OLD_GRAPH_DATA;
  payload: any;
};
export type SaveMetricElement = {
  type: typeof SAVE_METRIC_ELEMENT;
  payload: {
    elementId: string;
    params: UpdateMetricPriority[];
    value: MetricsType[];
  };
  callback: Function;
};
export type SetMetricMapper = {
  type: typeof SET_METRIC_MAPPER;
  payload: any;
};
export type SetGraphUpdateLoader = {
  type: typeof SET_GRAPH_UPDATE_LOADER;
  payload: number;
};
export type SetMetricLoader = {
  type: typeof SET_METRIC_LOADER;
  payload: boolean;
};

export type SetCurrentGraphLoader = {
  type: typeof SET_METRIC_GRAPH_DATA_LOADER;
  payload: boolean;
};

export type SetPreviousGraphLoader = {
  type: typeof SET_METRIC_OLD_GRAPH_DATA_LOADER;
  payload: boolean;
};
export type SetAttributionWindow = {
  type: typeof SET_ATTRIBUTION_WINDOW;
  payload: string;
};

export type SetIsDefault = {
  type: typeof SET_IS_DEFAULT;
  payload: boolean;
};
export type SetGraphMetrics = {
  type: typeof SET_GRAPH_METRICS;
  payload: MetricsType[];
};
export type SetAttributionWindowType = {
  type: typeof SET_ATTRIBUTION_WINDOW_TYPE;
  payload: AttributionWindow[];
};
export type GetAttributionWindowType = {
  type: typeof GET_ATTRIBUTION_WINDOW_TYPE;
  payload: {
    elementId: string;
  };
};
export type GetElementAttributionWindow = {
  type: typeof GET_ELEMENT_ATTRIBUTION_WINDOW;
  payload: any;
};
export type UpdateElementAttributionWindow = {
  type: typeof UPDATE_ELEMENT_ATTRIBUTION_WINDOW;
  payload: any;
};
export type SetAttributionWindowLoader = {
  type: typeof SET_ATTRIBUTION_WINDOW_LOADER;
  payload: boolean;
};
export type SetChangeMetricFinish = {
  type: typeof SET_CHANGE_METRIC_FINISH;
  payload: boolean;
};
export type SetAttributionWindowElementId = {
  type: typeof SET_ATTRIBUTION_WINDOW_ELEMENT_ID;
  payload: string;
};
//common types

export type EditDateType = "Calender" | "Slider";

export interface SelectedDateType {
  startDate: Date;
  endDate: Date;
  editedBy: EditDateType;
}
export interface AllMetricsType {
  id: number;
  name: string;
  shortName: string;
  description: string;
  growth: string;
  metricFormat: string;
  value?: string;
  label?: string;
}
export interface MetricsType {
  id: number;
  name: string;
  shortName: string;
  description: string;
  growth: string;
  metricFormat: string;
  value?: string;
  label?: string;
  graph?: boolean;
  isEmpty?: boolean;
}
export interface UpdateMetricPriority {
  id: number;
}
export interface LooseObject {
  [key: number]: MetricMapperType;
}

export interface MetricMapperType {
  name: string;
  shortName: string;
  growth: string;
  metricFormat: string;
}

export interface AttributionWindow {
  label: string;
  value: string | number;
}
