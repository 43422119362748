import React, { useState } from "react";
import { StyledAdTabs, StyledTab } from "../../../../../shared/Tabs/styled";
import AdSettings from "../AdSettings/AdSettings";
import Preview from "../Preview/Preview";
import { RecommendationTab } from "./RecommendationTab";
import { StyledPreviewAndAdSettings, StyledEditor } from "./styled";
import { AdSettingsTypes, ItemType } from "../Editor.types";
import { Box } from "@mui/material";
import MediaStudio from "../MediaStudio/MediaStudio";
import { useCaiFbMixPanel } from "components/CreativeAI_Revamp/Facebook/HomeScreen/hooks/useCaiFbMixPanel";
interface RecommendationsProps {
  textFields: AdSettingsTypes[];
  onAdSettingChange: (e: any, index: number) => void;
  selectedTool: ItemType | null;
  variantItem: any;
  mixPanelCreativeStudioSeeMore: any;
  mixPanelCreativeStudioAdSettingButtonClick: any;
  mixPanelCreativeStudioRenderUpdate: any;
}
export default function Recommendations({
  textFields,
  onAdSettingChange,
  selectedTool,
  variantItem,
  mixPanelCreativeStudioSeeMore,
  mixPanelCreativeStudioAdSettingButtonClick,
  mixPanelCreativeStudioRenderUpdate,
}: RecommendationsProps) {
  const [value, setValue] = useState("preview");
  const [isRefreshLoading, toggleRefreshLoading] = useState(false);
  const [isDirty, toggleDirty] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const showRefreshBtn = selectedTool?.hash === "video" && isDirty;
  if (!selectedTool) return null;

  return (
    <Box
      // width="100%"
      height="100%"
      alignItems="center"
      display="flex"
      overflow="hidden"
      style={{ flex: 1, minWidth: "0px" }}
    >
      {/* <ErrorDialog show={true} onHide={()=>{}}/> */}
      <StyledEditor>
        {selectedTool?.hash === "image" || selectedTool?.hash === "video" ? (
          <MediaStudio
            selectedTool={selectedTool}
            toggleDirty={toggleDirty}
            mixPanelCreativeStudioSeeMore={() =>
              mixPanelCreativeStudioSeeMore(variantItem, selectedTool?.hash)
            }
          />
        ) : (
          <RecommendationTab
            mixPanelCreativeStudioSeeMore={() =>
              mixPanelCreativeStudioSeeMore(variantItem, selectedTool?.hash)
            }
            selected={selectedTool}
            isRefreshLoading={isRefreshLoading}
          />
        )}
      </StyledEditor>
      <StyledPreviewAndAdSettings>
        <StyledAdTabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ children: <div /> }}
        >
          <StyledTab label="Preview" value="preview" />
          <StyledTab label="Ad Settings" value="ad-settings" />
        </StyledAdTabs>
        {value === "preview" ? (
          <Preview
            value={value}
            textFields={textFields}
            showRefreshBtn={showRefreshBtn}
            isRefreshLoading={isRefreshLoading}
            toggleDirty={toggleDirty}
            mixPanelCreativeStudioRenderUpdate={() => {
              mixPanelCreativeStudioRenderUpdate(variantItem);
            }}
          />
        ) : (
          <AdSettings
            value={value}
            textFields={textFields}
            onAdSettingChange={onAdSettingChange}
            mixPanelCreativeStudioAdSettingButtonClick={
              mixPanelCreativeStudioAdSettingButtonClick
            }
            variantItem={variantItem}
            hash={selectedTool?.hash}
          />
        )}
      </StyledPreviewAndAdSettings>
    </Box>
  );
}
