import styled from "styled-components";

export const StyledOpenCAICardContainer = styled.div`
  positon: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const StyledSvgContainer = styled.div``;

export const StyledMessageContainer = styled.div`
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.428em;
  height: 4.28em;
  width: 27.36em;
`;
