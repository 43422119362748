import MenuItem from "@mui/material/MenuItem";
import { Theme, useTheme } from "@mui/material/styles";
import { Controller } from "react-hook-form";
import { StyledSelect, StyledSelectContainer } from "./Select.styled";
import { SelectProps as MUISelectProps } from "@mui/material/Select";
import InputLabel from "../../InputLabel/InputLabel";
import { StyledErrorMessage } from "../../Typography/Heading/styled";

export interface MultiSelectProps extends MUISelectProps {
  label?: string;
  backgroundColor?: string;
  isBorder?: boolean;
  borderColor?: string;
  tooltipText?: string;
  options?: Array<string>;
  height?: string;
  value?: Array<string>;
  control?: any;
  registeredName?: any;
  helperFunction?: (registeredName: string) => void;
}

function getStyles(
  option: string,
  selectedOptions: readonly string[],
  theme: Theme
) {
  return {
    fontWeight:
      selectedOptions.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function MultiSelectFormControl({
  label,
  required,
  tooltipText,
  options,
  placeholder,
  control,
  helperFunction,
  registeredName,
  ...props
}: MultiSelectProps) {
  const theme = useTheme();

  return (
    <Controller
      control={control}
      name={registeredName}
      rules={{
        required,
      }}
      render={({
        field: { onChange, value },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <StyledSelectContainer>
          {label && (
            <InputLabel
              required={required}
              label={label}
              tooltipText={tooltipText}
            />
          )}
          <StyledSelect
            value={value ?? []}
            variant="standard"
            disableUnderline
            displayEmpty
            multiple
            onChange={(value) => {
              helperFunction && helperFunction(registeredName);
              onChange(value);
            }}
            renderValue={(selected: any) => {
              if (!selected || (selected && selected.length === 0)) {
                return <em>{placeholder}</em>;
              }
              return selected.join(", ");
            }}
            {...props}
          >
            {options &&
              options.map((option) => (
                <MenuItem
                  value={option}
                  key={option}
                  style={getStyles(option, options, theme)}
                >
                  {option}
                </MenuItem>
              ))}
          </StyledSelect>
          {error && (
            <StyledErrorMessage>This field is required *</StyledErrorMessage>
          )}
        </StyledSelectContainer>
      )}
    />
  );
}
