import { Box, Popover, styled } from "@mui/material";

export const StyledDynamicFilterOverflowBox = styled(Box)`
  display: flex;
  /* flex: 1; */
  overflow-x: scroll;
  overflow-y: hidden;
  min-width: 0;
  padding-bottom: 0.5%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 0.25em;
    padding-bottom: -1em;
    margin-bottom: -1em;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    padding-bottom: -1em;
    margin-bottom: -1em;
  }
`;

export const StyledAddButton = styled(Box)`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  height: 2em;
  width: 3.625em;
  border-radius: 6px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledFilterDivider = styled(Box)`
  height: 100%;
  width: 0px;
  border: 1px solid #ececec;
  min-height: 0;
  /* margin: 0px 0.75em; */
`;

export const StyledPopover = styled(Popover)`
  font-size: 1.1112vw;
  @media screen and (max-width: 1280px) {
    font-size: 11px;
  }
`;
