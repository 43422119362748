export enum ColorMapping {
  high = "linear-gradient(90deg, #3ABE7F 6.86%, #77DDDD 109.31%)",
  medium = "linear-gradient(90deg, #F18333 6.86%, #F5C23C 109.31%)",
  low = "linear-gradient(90deg, #F13333 6.86%, #F5743C 109.31%)",
}

export const performanceScoreNullState = {
  performance_labels: null,
  readability_score: null,
  brand_centric: null,
  customer_centric: null,
  benefit_label: null,
  offer_label: null,
  performance_score: null,
  performance_probabilities: null,
};
