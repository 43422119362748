import { Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";
// import { LightTooltip } from "./Data/Tooltip/LightTooltip";
// import CustomTooltip from "./Data/Tooltip/Tooltip";

export const RolesWrapper = ({
  show = true,
  disabled,
  children,
  parentDivStyle = {},
}: any) => {
  const containerRef = useRef(null);

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed !important" : "auto",
        ...parentDivStyle,
      }}
    >
      <>
        <Tooltip
          title={disabled ? "You do not have enough permissions." : ""}
          placement="top-end"
        >
          <div
            style={{
              display: disabled ? "block" : "none",
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0",
              left: 0,
              zIndex: 3,
              cursor: "not-allowed",
            }}
          ></div>
        </Tooltip>
        {show ? children : null}
      </>
    </div>
  );
};
