import { isNumber, isObject, omit } from 'lodash';

export default function getDemographicsKeywordsFromFlexibleSpecObject(flexible_Spec_object: any, facebook_targetings_enum_mapping: any) {
  return Object
    .entries(omit(flexible_Spec_object, ["interests", "behaviors"]))
    .flatMap(([key, value]) => {
      const first = value?.[0];
      // if value is array of objects with id and name
      if (isObject(first)) return value;
      // if value is array of ids(string/number)
      if (isNumber(first) || isNumber(first)) return value
        .map((id: string | number) => facebook_targetings_enum_mapping[key][id])
        .filter(Boolean)

      return [];
    });
}