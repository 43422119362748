import styled from "styled-components";

export const StyledContent = styled.div<{ top: any; left: any }>`
  display: none;
  position: absolute;
  max-width: 300px;
  background-size: cover;
  color: #333333;
  padding: 10px;
  z-index: 2;
  border-radius: 10px;
  top: ${(props) => (props.top ? props.top : "20px")};
  left: ${(props) => (props.left ? props.left : "30px")};
  min-width: 200px;
  background-color: white;
  font-size: 12px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
`;

export const StyledToolTipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30px;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  z-index: 1;
  &:hover ${StyledContent} {
    display: block;
  }
`;

export const StyledIImage = styled.img`
  // height: 12px;
  // width: 15px;
`;
