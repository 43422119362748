import React from "react";
import { Box, Button, TablePagination, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ReactComponent as Edit } from "../assets/editIcon.svg";
import { BRANDSTABLEHEADERS } from "../constants";
import { useManageAgencyBrandsState } from "./useManageAgnecyBrandsState";
import EditBrand from "./EditBrand";
import { Stack } from "@mui/system";
import AddBrand from "./AddBrand";
import moment from "moment";
import Loader from "components/common/Loader";

function Brands() {
  const {
    isLoading,
    isError,
    brandsListData,
    page,
    setPage,
    limit,
    setLimit,
    totalBrandsCount,
    getBrands,
    editBrand,
    addBrand,
  } = useManageAgencyBrandsState();
  const [open, setOpen] = React.useState(false);
  const [openBrand, setOpenBrand] = React.useState(false);
  const [selectedBrandData, setSelectedBrandData] = React.useState({});

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    getBrands(1, event.target.value);
    setPage(0);
  };

  const handleActionClick = (brandsData: any) => {
    setSelectedBrandData(brandsData);
    setOpen(true);
  };

  const addBrandClick = () => {
    setOpenBrand(true);
  };

  return (
    <>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          px: "20px",
        }}
      >
        <Typography className="font-18 font-600">Brand Overview</Typography>
        <Button
          sx={{
            borderRadius: "0.5rem",
            color: "white",
            textTransform: "capitalize",
            background: "#0869FB",
            "&:hover": {
              backgroundColor: "#0869FB",
              boxShadow: "none",
            },
          }}
          onClick={addBrandClick}
        >
          + Add Brand
        </Button>
      </Stack>
      <Box
        className="bg-white border d-flex align-items-center justify-content-start"
        sx={{
          m: 2,
          borderRadius: 2,
          flexDirection: "column",
        }}
      >
        {isLoading ? (
          <Box sx={{ padding: "1rem" }}>
            <Loader height="20px" animation="grow" />
          </Box>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "8px",
              boxShadow: "none",
            }}
          >
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {BRANDSTABLEHEADERS.map((header, index) => (
                    <TableCell
                      key={index}
                      align="left"
                      sx={{
                        "&:last-child td, &:last-child th": {
                          textAlign: "center",
                        },
                      }}
                    >
                      <Typography className="font-600 font-14 font-color-header">
                        {header}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {brandsListData.map((brand: any, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ width: "10%" }}>
                      <Typography className=" font-14 font-400">
                        {page * limit + index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "20%", wordBreak: "break-all" }}
                    >
                      <Typography
                        className=" font-14 font-color-1 font-600"
                        sx={{ width: "90%" }}
                      >
                        {brand.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "20%", wordBreak: "break-all" }}
                    >
                      <Typography
                        className=" font-14 font-color-2 font-500"
                        sx={{ lineHeight: "20px" }}
                      >
                        {brand.brandWebsite ?? "-"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "20%", wordBreak: "break-all" }}
                    >
                      <Typography className=" font-14 font-color-2 font-500">
                        {brand.brandDetails ?? "-"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "20%", wordBreak: "break-all" }}
                    >
                      <Typography className=" font-14 font-color-2 font-500">
                        {moment(brand.createdAt).format("DD, MMM YYYY") ?? "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ width: "10%" }}>
                      <Box sx={{ cursor: "pointer", width: "fit-content" }}>
                        <Edit onClick={() => handleActionClick(brand)} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {brandsListData.length > 0 && (
              <TablePagination
                sx={{
                  "& .MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                  },
                }}
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={totalBrandsCount ?? -1}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>
        )}
        {open && (
          <EditBrand
            open={open}
            setOpen={setOpen}
            selectedBrandData={selectedBrandData}
            editBrand={editBrand}
            getBrands={getBrands}
            page={page}
            limit={limit}
          />
        )}
        {openBrand && (
          <AddBrand
            open={openBrand}
            setOpen={setOpenBrand}
            addBrand={addBrand}
            getBrands={getBrands}
            page={page}
            limit={limit}
          />
        )}
      </Box>
    </>
  );
}

export default Brands;
