import {
  SetKeywordTuningData,
  SET_KEYWORD_TUNING_DATA,
} from "../../types/targetingAI";

export default function keywordTuning(
  state = null,
  action: SetKeywordTuningData
) {
  switch (action.type) {
    case SET_KEYWORD_TUNING_DATA: {
      return action.payload;
    }
    default:
      return state;
  }
}
