export const STORE_TAI_KWS_ICP = "STORE_TAI_KWS_ICP";
export const GET_TAI_KWS_ICP = "GET_TAI_KWS_ICP";
export const GENERATE_COHORTS_ICP = "GENERATE_COHORTS_ICP";
export const GET_TAI_COHORT_ICP = "GET_TAI_COHORT_ICP";
export const STORE_FAVOURITE_COHORT_ICP = "STORE_FAVOURITE_COHORT_ICP";
export const UPDATE_COHORTS_ICP = "UPDATE_COHORTS_ICP";
export const REFRESH_COHORTS_ICP = "REFRESH_COHORTS_ICP";

export type StoreTAIKWSICP = {
  type: typeof STORE_TAI_KWS_ICP;
  payload: any;
  callback?: Function;
};

export type GetTAIKWSICP = {
  type: typeof GET_TAI_KWS_ICP;
  payload: {
    request_ids: string;
  };
  callback?: Function;
};

export type GenerateCohortsICP = {
  type: typeof GENERATE_COHORTS_ICP;
  payload: {
    kwsData: any;
    aiGroupId: string;
    platformId: string;
  };
  callback?: Function;
};

export type GetTAICohortICP = {
  type: typeof GET_TAI_COHORT_ICP;
  payload: {
    request_ids: string;
  };
  callback?: Function;
};

export type StoreFavouriteCohortICP = {
  type: typeof STORE_FAVOURITE_COHORT_ICP;
  payload: {
    favouriteCohort: [{}];
    aiGroupId: any;
  };
  callback?: Function;
};

export type UpdateCohortICP = {
  type: typeof UPDATE_COHORTS_ICP;
  payload: {
    payloadData: any;
  };
  callback?: Function;
};

export type RefreshCohortICP = {
  type: typeof REFRESH_COHORTS_ICP;
  payload: {
    payloadData: any;
  };
  callback?: Function;
};
