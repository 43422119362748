import { InputProps } from "@mui/material";
import { Controller } from "react-hook-form";
import InputLabel from "../../InputLabel/InputLabel";
import {
  StyledInputContainer,
  StyledTagContainer,
  StyledTags,
  StyledTextInputWithTag,
} from "./styled";
import { StyledErrorMessage } from "../../Typography/Heading/styled";
import { ReactComponent as CrossIcon } from "../../../../assets/svg/crossSVG.svg";
import { useEffect, useState } from "react";
import InputComponent from "./InputComponent";

interface InputWithLabelProps extends InputProps {
  label?: string;
  required?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  isBorder?: boolean;
  tooltipText?: string;
  inputType?: "alpha" | "float" | "int" | undefined;
  control?: any;
  registeredName?: any;
  helperFunction?: (registeredName: string) => void;
  ruleFunction?: (event: any) => void;
  placeholder: string;
}
export interface TextInputProps extends InputProps {
  isBorder?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  height?: string;
}

export function InputTagFormControl({
  required,
  label,
  tooltipText,
  inputType = undefined,
  control,
  registeredName,
  inputProps,
  helperFunction,
  ruleFunction,
  placeholder,
  ...props
}: InputWithLabelProps) {
  return (
    <Controller
      control={control}
      name={registeredName}
      rules={{
        required,
        ...inputProps,
      }}
      render={({
        field: { onChange, value },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <InputComponent
          label={label}
          required={required}
          tooltipText={tooltipText}
          onChange={onChange}
          value={value}
          error={error}
          inputProps={inputProps}
          placeholder={placeholder}
          {...props}
        />
      )}
    />
  );
}
