import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  crReducerReset,
  getBatchData,
  getNoRecommendationList,
} from "store/actions/ActionDebugger/CreativeRotation/creativeRotation.actions";
import { getStoryData } from "store/actions/campaign";
import { ActionDebuggerHelperInitState } from "store/types/ActionDebugger/ActionDebuggerHelper";
import {
  CANCEL_BATCH_DATA_DETAILS,
  CANCEL_PREVIEW_DATA,
  CreativeRotationInitialState,
} from "store/types/ActionDebugger/CreativeRotation/creativeRotation.types";
import { AdAccountState } from "store/types/adaccount";

export const useCreativeRotation = () => {
  const [batchSelection, setBatchSelection] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selecteGroupAdset } =
    adaccount;

  const {
    selectedAdAccount: { selectedAdAcc },
  } = useSelector(
    (state: { ActionDebuggerHelperReducer: ActionDebuggerHelperInitState }) =>
      state.ActionDebuggerHelperReducer
  );

  const { batchList, isApproveExecution, isRejectExecution, auto_execution, noRecommendationList } = useSelector(
    (state: { creativeRotationReducer: CreativeRotationInitialState }) => {
      return state.creativeRotationReducer;
    }
  );

  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const [disableAdvanceSetting, setDisableAdvanceSetting] = useState(true);
  const [autoApprove, setAutoApprove] = useState(auto_execution);
  const [loadingNonRecommendedAds,setLoadingNonRecommendedAds]=useState<boolean>(false)
  const [refreshDataCallback,setRefreshDataCallback]=useState<any>(null)
  const [selectedAdsets, setSelectedAdsets]= useState(selecteGroupAdset?.map((i:any)=>i.adsetId))
  const [unexpiredBatchData, SetUnexpiredBatchData]= useState({pipeline_id:'', created_at:''})

  useEffect(()=>{setAutoApprove(auto_execution)},[auto_execution])
  // useEffect(()=>{
    // const allAdsLength=noRecommendationList?.reduce((acc,item:any)=>{
    //   return acc+(item?.adSets.reduce((acc:number,i:any)=>{return acc+i.ads.length},0));
    // },0)
    // setTotalNonRecommendedListAds(allAdsLength ? allAdsLength: 0 )
  // },[noRecommendationList])

  useEffect(()=>{
    setSelectedAdsets(selecteGroupAdset?.map((i:any)=>i.adsetId))
  },[selecteGroupAdset])

  useEffect(() => {
    if (selectedAdAcc?.elementId) {
      dispatch({
        type: CANCEL_BATCH_DATA_DETAILS,
      });
      dispatch({
        type: CANCEL_PREVIEW_DATA,
      });
      setBatchSelection([]);
      dispatch(crReducerReset(""));
      setPageNumber(0);
      setExpanded(false);
      dispatch(
        getBatchData({ elementId: selectedAdAcc?.elementId, pageNumber: 0, adIds:[...selectedAdsets] })
      );
      setLoadingNonRecommendedAds(true)
      dispatch(getNoRecommendationList({elementId:selectedAdAcc?.elementId, callback:()=>{setLoadingNonRecommendedAds(false)}}))

      dispatch(
        getStoryData(
          { elementId: selectedAdAcc?.elementId, user: "" },
          (response: any, error: boolean) => {
            if (!error) {
              setDisableAdvanceSetting(false);
            } else {
              setDisableAdvanceSetting(true);
            }
          }
        )
      );
      setRefreshDataCallback({callback:()=>{
        setLoadingNonRecommendedAds(true)
        dispatch(getNoRecommendationList({elementId:selectedAdAcc?.elementId, callback:()=>{setLoadingNonRecommendedAds(false)}}))
        dispatch(
          getBatchData({ elementId: selectedAdAcc?.elementId, pageNumber: 0, adIds:[...selectedAdsets] })
        );
      }})   
    }
  }, [selectedAdAcc?.elementId, selectedAdsets]);

  useEffect(() => {
    if (selectedAdAcc?.elementId && pageNumber) {
      dispatch(
        getBatchData({ elementId: selectedAdAcc?.elementId, pageNumber, adIds:[...selectedAdsets] })
      );
    }
  }, [pageNumber]);

  useEffect(()=>{
    SetUnexpiredBatchData({pipeline_id:batchList?.data?.[0]?.pipeline_id, created_at:batchList?.data?.[0]?.created_at})
  },[batchList])

  return {
    batchList,
    elementId: selectedAdAcc?.elementId,
    isApproveExecution,
    isRejectExecution,
    setPageNumber,
    pageNumber,
    batchSelection,
    setBatchSelection,
    disableAdvanceSetting,
    expanded,
    setExpanded,
    autoApprove,
    setAutoApprove,
    refreshDataCallback,
    loadingNonRecommendedAds,
    selectedAdsets,
    setSelectedAdsets,
    unexpiredBatchData,
    SetUnexpiredBatchData
  };
};
