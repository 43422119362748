import React, { useContext } from "react";
import { Position, useReactFlow } from "react-flow-renderer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import KeywordCost from "./KeywordCost";
import ImpressionSignificance from "./ImpressionSignificance";
import CompetitionIndex from "./CompetitionIndex";
import AvgSearch from "./AvgSearch";
import "../../style/global.scss";
import { useDispatch, useSelector } from "react-redux";
import { TaiContext } from "Pages/TargetingAIPlaygroundBeta/TaiContextProvider";
import { Handle } from "react-flow-renderer";
import { storeGSTargetingAIGenerateKeywords } from "../../../../../store/actions/gsTargetingAI";
import { setPolling } from "../../../../../store/actions/targetingAI";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as Continue } from "../../assets/svg/Continue.svg";
import { initialState } from "../../../../../store/types/auth";
import { TAIGSearchMixPanel, convertRange } from "../../utils";
import { mixPanelAdEvent } from "utils/mixpanel";
import { AdAccountState } from "store/types/adaccount";
import { CommonState } from "store/types/common";

const convertImpressionSignificanceValue = convertRange([0, 100], [90, 99]);
const convertKeywordCostValue = convertRange([0, 100], [30, 70]);

function KeywordTuning({
  data,
  isConnectable,
}: {
  data: any;
  isConnectable: boolean;
}) {
  const dispatch = useDispatch();
  const { setCenter } = useReactFlow();
  const { setNodes, setEdges, checkStepAndComplete, changePublishModal } =
    useContext(TaiContext) as any;
  const loaderState = useSelector((state: any) => state.taiCommon.loader);
  const { gsCalibration, gsKeywordTuning } = useSelector(
    (state: any) => state.gsTargetingAI
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { selectedAgency } = common;
  const gsKeywordTuningData = useSelector(
    (state: any) => state.gsTargetingAI.gsKeywordTuning.data
  );
  const { currentPage } = useSelector((state: any) => state.taiCommon);
  const { user } = useSelector((state: { auth: initialState }) => state.auth);
  const { apiData } = useSelector((state: any) => state.aiInitiation);
  const aiInitiationFormData = useSelector(
    (state: any) => state.aiInitiation.formData
  );

  const { selectedControlGroup } = useSelector(
    (state: any) => state.taiCommon?.campaignAudienceDetail
  );
  const handleGenerateKeywordsClick = () => {
    TAIGSearchMixPanel(
      "TAI-Gsearch Keyword Tuning Step Done",
      selectedBrand?.name,
      selectedAgency?.name,
      {
        keyword_cost: gsKeywordTuningData?.keywordCostValue,
        impressions_significance: gsKeywordTuningData?.impressionSignificance,
        monthly_average_search: gsKeywordTuningData?.monthlyAvgSearch,
        competation_index_check: gsKeywordTuningData?.competationIndexCheck,
        competation_index: gsKeywordTuningData?.competationIndex,
        keyword_cost_check: gsKeywordTuningData?.keywordCostCheck,
        ai_group_id: aiInitiationFormData?.selectedAiGroup?.id,
        ai_group_name: aiInitiationFormData?.selectedAiGroup?.name,
        ad_account_name: apiData?.adAccount?.name,
        ad_account_id: apiData?.adAccount?.id,
        agency_id: selectedAgency.id,
        brand_id: selectedBrand.id,
        brand_type: selectedBrand.brandType,
      }
    );
    const payload = {
      service: "google_search_recms",
      version: "v2",
      exposedRecommendation: selectedControlGroup?.id ? true : false,
      payload_data: {
        ai_group_id: apiData.aiGroup.id,
        ad_account_id: apiData.adAccount.id || apiData.adAccount.adAccountId,
        brand_name: apiData.name,
        website_url: apiData.website,
        sqr_range: gsCalibration.data.LookbackPeriod.replace(
          " ",
          "_"
        ).toLowerCase(),
        cost_weightage: convertKeywordCostValue(
          gsKeywordTuning.data.keywordCostValue
        ),
        impression_percentile_filter: convertImpressionSignificanceValue(
          gsKeywordTuning.data.impressionSignificance
        ),
        custom_keywords: [],
        google_kw_planner: gsCalibration.data.GoogleKwsPlannerCheck,
      },
    };

    dispatch(
      storeGSTargetingAIGenerateKeywords(
        { payload, user },
        (response: any, error: boolean) => {
          if (!error && response?.requestid) {
            dispatch(
              setPolling({
                name: "keywordTuningGS",
                requestId: response.requestid,
                // requestId: "1",
              })
            );
          } else {
            changePublishModal("failed");
          }
        }
      )
    );
  };
  return (
    <Box
      className="font-inter"
      sx={{
        position: "absolute",
        width: "46rem",
        background: "#FFFFFF",
        border: "1px solid #EFEFEF",
        boxShadow: "0px 2.99792px 10.4927px rgba(0, 0, 0, 0.05)",
        borderRadius: "8px",
        m: 2,
        p: 2,
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Grid container sx={{ width: "100%", p: 2 }}>
        <Grid item xs={10}>
          <Typography className="font-16 font-700">Keyword Tuning</Typography>
        </Grid>
        <Grid item xs={2} textAlign="end">
          {currentPage > data.index && (
            <Box
              onClick={() => {
                // dispatch(setAllCalibrationFormData(currentData));
                data.nextPage(dispatch, loaderState, setNodes, setEdges, true);
              }}
            >
              <Continue />
            </Box>
          )}
        </Grid>
      </Grid>
      <Stack
        sx={{
          width: "95%",
          background: "#FFFFFF",
          border: "1px solid #F0F0F0",
          borderRadius: "8px",
          m: 2,
        }}
      >
        <Box className="font-lato font-14 font-600" sx={{ px: 2, py: 1 }}>
          Exploit
        </Box>
        <Stack direction="row">
          <KeywordCost />
          <ImpressionSignificance />
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: "95%",
          background: "#FFFFFF",
          border: "1px solid #F0F0F0",
          borderRadius: "8px",
          m: 2,
        }}
      >
        <Box className="font-lato font-14 font-600" sx={{ px: 2, py: 1 }}>
          Explore
        </Box>
        <Stack direction="row">
          <CompetitionIndex />
          <AvgSearch />
        </Stack>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 2 }}>
        <Button
          onClick={() => {
            // handleGenerateKeywordsClick();
            checkStepAndComplete([1, 2], data.index, () => {
              handleGenerateKeywordsClick();
              data.nextPage(dispatch, loaderState, setNodes, setEdges);
            });
          }}
          variant="contained"
          className="font-14 font-600"
          sx={{
            background: "#0869FB",
            borderRadius: "8px",
            textTransform: "capitalize",
          }}
        >
          Generate Keywords
        </Button>
      </Box>
    </Box>
  );
}

export default KeywordTuning;
