import React from 'react'

function EditIcon() {
  return (
    <svg width='13' height='15' viewBox='0 0 13 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.12935 11.7505L1.23522 11.6442C1.17356 11.5828 1.12622 11.5085 1.09656 11.4267C1.06698 11.3451 1.05572 11.258 1.06355 11.1716C1.06357 11.1714 1.0636 11.1711 1.06362 11.1708L1.3824 8.07956L1.38245 8.07905C1.41277 7.77461 1.54687 7.48986 1.76223 7.27257C1.76231 7.27249 1.76239 7.27241 1.76247 7.27233L8.43285 0.601953C8.68201 0.393405 8.99882 0.282963 9.32377 0.291514C9.65 0.300099 9.96173 0.428023 10.1999 0.651012L12.2305 2.68159L12.2305 2.68162L12.2331 2.68413C12.4773 2.91672 12.6192 3.2366 12.6279 3.57369C12.6366 3.9101 12.5119 4.23627 12.2809 4.48099L5.6075 11.1544C5.60742 11.1545 5.60735 11.1546 5.60727 11.1547C5.39011 11.3699 5.10556 11.504 4.80132 11.5344C4.80114 11.5344 4.80096 11.5344 4.80078 11.5345L1.71589 11.8156L1.65596 11.8156L1.65509 11.8156C1.57722 11.816 1.50002 11.8011 1.42793 11.7717C1.35584 11.7422 1.29027 11.6988 1.23498 11.644L1.12935 11.7505ZM1.12935 11.7505C1.19865 11.8192 1.28084 11.8736 1.37121 11.9105C1.46158 11.9474 1.55835 11.9661 1.65596 11.9656L0.91425 11.1571C0.904259 11.2657 0.918355 11.3752 0.955535 11.4778C0.992714 11.5804 1.05206 11.6735 1.12935 11.7505ZM9.37939 1.47557L9.27197 1.36815L9.16592 1.47692L7.71959 2.96034L7.61619 3.06639L7.72092 3.17112L9.7087 5.1589L9.81344 5.26363L9.91949 5.16023L11.4029 3.7139L11.5117 3.60785L11.4043 3.50043L9.37939 1.47557ZM2.56642 8.08382L2.52763 8.12234L2.52271 8.17678L2.32245 10.3945L2.30623 10.5741L2.48578 10.5573L4.71091 10.3497L4.76478 10.3446L4.80304 10.3064L8.95661 6.15279L9.06268 6.04673L8.95661 5.94066L6.954 3.93804L6.84831 3.83235L6.74224 3.93767L2.56642 8.08382ZM1.23756 13.7723C1.34853 13.6613 1.49903 13.599 1.65596 13.599H12.0399C12.1968 13.599 12.3473 13.6613 12.4583 13.7723C12.5693 13.8833 12.6316 14.0338 12.6316 14.1907C12.6316 14.3476 12.5693 14.4981 12.4583 14.6091C12.3473 14.7201 12.1968 14.7824 12.0399 14.7824H1.65596C1.49903 14.7824 1.34853 14.7201 1.23756 14.6091C1.12659 14.4981 1.06425 14.3476 1.06425 14.1907C1.06425 14.0338 1.12659 13.8833 1.23756 13.7723Z'
        fill='black'
        fillOpacity='0.4'
        stroke='white'
        strokeWidth='0.3'
      />
    </svg>
  )
}

export default EditIcon