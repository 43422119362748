import "./KwywordExplorer.scss";
import { useContext, useState, useRef, useEffect } from "react";
import { Handle, useReactFlow, Position } from "react-flow-renderer";
import { createPortal } from "react-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import CircularProgress from "@mui/material/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";

import { ReactComponent as ViewGrid } from "./assests/view-grid.svg";
import { ReactComponent as ViewList } from "./assests/view-list.svg";
import { ReactComponent as InfoIcon } from "./assests/info-icon.svg";
import { ReactComponent as SelectAll } from "./assests/select-all.svg";
import { ReactComponent as DeselectAll } from "./assests/deselect-all.svg";
import { ReactComponent as Search } from "./assests/search.svg";
import { ReactComponent as NoDataFound } from "./assests/no-data-found.svg";
import { ReactComponent as Download } from "./assests/cloud-download.svg";

import FilterPopover from "./components/FilterPopover";
import BulkActionsPopover from "./components/BulkActionsPopover";
import PublishModal from "./components/PublishModal";
import { KeywordContext } from "./KeywordContextProvider";
import { useSelector } from "react-redux";
import NoKWData from "./components/NoKWData";
import StayHereModal from "../../../../TargetingAIPlaygroundBeta/components/StayHereModal";
import {
  METRIC_NAMES,
  SEARCH_METRIC_NAME,
  BUCKET_NAMES,
} from "../../constants/KeywordExplorer";
import {
  LeftGrid,
  KeywordsBox,
  KeywordValueBox,
  PrettoSlider,
} from "./styled/index";
import Keywords from "./components/Keywords";
import { amountValue } from "../../../../../utils/commonFunctions";
import {
  BUCKET_PAGINATION_LENGTH,
  NEXT_PAGE_TIMEOUT,
} from "../../constants/KeywordExplorer";
import { TAIGSearchMixPanel, tooLongDecimal } from "../../utils";
import { AdAccountState } from "store/types/adaccount";
import { CommonState } from "store/types/common";
// import { tooLongDecimal } from "../../utils/index";

export default function KeywordExplorer({
  data,
  isConnectable,
}: {
  data: any;
  isConnectable: boolean;
}) {
  const { setCenter } = useReactFlow();
  const {
    keywords,
    setKeywords,
    starredItems,
    setStarredItems,
    selectedKeywordsBucket,
    setSelectedKeywordsBucket,
    getSelectedBucketData,
    isClusterView,
    setIsClusterView,
    adGroupDetails,
    clusterDetails,
    getLengthForBucket,
    getTotalLength,
    selectedFilters,
    appliedSelectedFilters,
    handleDeleteClick,
    handleDuplicateClick,
    changeSingleProperty,
    handleSelectKeywords,
    checkSelectAll,
    getBucketSelectdItemsLength,
    checkSelectAllBucket,
    handleSelectAllKeywords,
    checkDeselectAllBucket,
    handleDeselectAllKeywords,
    filterText,
    setFilterText,
    filteredIds,
    handleSensitivityChange,
    kSensitivity,
    getLoaderForBucket,
    selectedIds,
    exportToCsv,
    wastedSpends,
  } = useContext(KeywordContext);

  const { monthlyAvgSearchCheck, competationIndexCheck } = useSelector(
    (state: any) => state.gsTargetingAI.gsKeywordTuning.data
  );
  const [dataLength, setDataLength] = useState(BUCKET_PAGINATION_LENGTH);

  const [showSaveModal, setShowSaveModal] = useState({
    value: false,
    shownOnce: false,
  });
  const currencySymbol = useSelector(
    (state: any) => state?.taiCommon?.Account_Details?.currency || ""
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { selectedAgency } = common;
  const [showDeselectModal, setShowDeselectModal] = useState(false);
  const [showNoKwData, setShowNoKwData] = useState(false);
  const selectedBucketData = getSelectedBucketData();
  const viewDetails = isClusterView ? clusterDetails : adGroupDetails;
  const selectedBucketKeys = Object.keys(selectedBucketData);

  const parent = useRef(null) as any;
  const [showIconsOnHover, setShowIconsOnHover] = useState(-1);
  const newObj: any = {};
  BUCKET_NAMES.forEach((item) => {
    newObj[item.type] = null;
  });
  const [bucktwiseFilteredIds, setBucktwiseFilteredIds] = useState(newObj);
  const [noDataFound, setNoDataFound] = useState({
    negative_kw: false,
    positive_kw: false,
    kw_ideas: false,
  });
  useEffect(() => {
    if (
      selectedKeywordsBucket === "positive_kw" ||
      selectedKeywordsBucket === "negative_kw"
    )
      setBucktwiseFilteredIds({
        ...bucktwiseFilteredIds,
        positive_kw: filteredIds,
        negative_kw: filteredIds,
      });
    else
      setBucktwiseFilteredIds({
        ...bucktwiseFilteredIds,
        [selectedKeywordsBucket]: filteredIds,
      });
  }, [filteredIds]);

  const handleNextPage = () => {
    const diff = selectedBucketKeys.length - dataLength;
    if (diff >= BUCKET_PAGINATION_LENGTH) {
      setTimeout(() => {
        setDataLength(dataLength + BUCKET_PAGINATION_LENGTH);
      }, NEXT_PAGE_TIMEOUT);
    } else if (diff < BUCKET_PAGINATION_LENGTH && diff > 0) {
      setTimeout(() => {
        setDataLength(dataLength + diff);
      }, NEXT_PAGE_TIMEOUT);
    }
  };

  const deselectModalProps = {
    text: "Are you sure you want to Deselect the selected keywords ",
    exitButtonText: "Clear Selection",
    stayButtonText: "Cancel",
    btnWidth: "9rem",
    onExitClick: () => {
      handleDeselectAllKeywords();
      setShowDeselectModal(false);
    },
    onStayClick: () => {
      setShowDeselectModal(false);
    },
  };

  useEffect(() => {
    let found = false;
    if (filteredIds) {
      const container = parent.current?._infScroll;
      if (container) {
        for (let index = 0; index < container.children.length; index++) {
          const element = container.children[index];
          found = element.classList.contains("keywords-accordian-tai");
          if (found) {
            setShowNoKwData(false);
            container?.classList?.remove("d-none");
            break;
          }
        }
      }
      if (!found) {
        container?.classList?.add("d-none");
        setShowNoKwData(true);
      }
    } else {
      setShowNoKwData(false);
      parent?.current?.classList?.remove("d-none");
    }
  }, [filteredIds]);

  useEffect(() => {
    setDataLength(BUCKET_PAGINATION_LENGTH);
  }, [selectedKeywordsBucket]);

  const loader = getLoaderForBucket(selectedKeywordsBucket);
  const bucketLength = getLengthForBucket(selectedKeywordsBucket);

  const [a, b] = useState(true);
  useEffect(() => {
    b(!a);
  }, [selectedKeywordsBucket]);

  useEffect(() => {
    selectedBucketKeys.slice(0, dataLength).map((item) => {
      const parseItem: any = isClusterView ? item : item;
      const items = getFilteredItems(
        selectedBucketData,
        parseItem,
        filteredIds,
        appliedSelectedFilters,
        selectedKeywordsBucket,
        keywords
      );
      if (items.length === 0) {
        setNoDataFound({ ...noDataFound, [selectedKeywordsBucket]: true });
      } else {
        setNoDataFound({ ...noDataFound, [selectedKeywordsBucket]: false });
      }
    });
  }, [filteredIds, appliedSelectedFilters]);

  return (
    <Box
      className="inset-0 overflow-y-auto outline-none d-flex focus:outline-none font-inter"
      sx={{
        position: "absolute",
        zIndex: "100",
        width: "100%",
        height: "100%",
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />

      <Stack className="bg-white border border-2 rounded shadow w-100 d-flex flex-column focus:outline-none border-primary h-100">
        <Grid container alignItems="center" width="100%" height="8%" p={2}>
          <Grid item xs={4} display="flex" alignItems="center">
            <Typography className="font-lato font-16 font-weight-700">
              Keyword Explorer
            </Typography>
            <IconButton
              sx={{
                background: !isClusterView ? "#F8F9FE" : "#FAFAFA",
                border: !isClusterView
                  ? "1px solid #0869FB"
                  : "1px solid #F0F0F0",
                borderRadius: "6px",
                ml: 2,
                opacity: !isClusterView ? 1 : 0.3,
              }}
              onClick={() => setIsClusterView(false)}
              disableRipple
            >
              <ViewGrid />
            </IconButton>
            <IconButton
              sx={{
                background: isClusterView ? "#F8F9FE" : "#FAFAFA",
                border: isClusterView
                  ? "1px solid #0869FB"
                  : "1px solid #F0F0F0",
                borderRadius: "6px",
                ml: 2,
                opacity: isClusterView ? 1 : 0.3,
              }}
              onClick={() => setIsClusterView(true)}
              disableRipple
            >
              <ViewList />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={8}
            className="d-flex align-items-center justify-content-end"
          >
            <Typography className="font-12 font-weight-500" sx={{ mr: 0.5 }}>
              K-Sensitivity
            </Typography>
            <InfoIcon style={{ marginRight: "0.25rem" }} />
            <Box
              className="d-flex align-items-center"
              sx={{
                width: "20%",
                mr: 2,
              }}
            >
              <PrettoSlider
                valueLabelDisplay="off"
                aria-label="pretto slider"
                value={kSensitivity}
                onChange={handleSensitivityChange}
              />
            </Box>
            <Box
              className="font-10 font-weight-400"
              sx={{
                background: "rgba(8, 105, 251, 0.1)",
                p: 0.5,
                borderRadius: 1,
                color: "#0869FB",
              }}
            >
              {kSensitivity}%
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} height="85%" p={2}>
          <LeftGrid
            item
            xs={3}
            height={"100%"}
            justifyContent={"space-between"}
            gap={"1rem"}
          >
            {!loader ? (
              <>
                {BUCKET_NAMES.map((item: any) => (
                  <KeywordsBox
                    key={item.type}
                    sx={{
                      background:
                        selectedKeywordsBucket === item.type ? "#F8F9FE" : "",
                      border:
                        selectedKeywordsBucket === item.type
                          ? "1px solid #0869FB"
                          : "",
                      // '&::after': {

                      // }
                    }}
                    onClick={() => {
                      TAIGSearchMixPanel(
                        `Gsearch - ${item?.name} bucket selected`,
                        selectedBrand?.name,
                        selectedAgency?.name,
                        {
                          keyword_type: item?.name,
                        }
                      );
                      setSelectedKeywordsBucket(item.type);
                    }}
                  >
                    <Typography
                      className="font-14 font-weight-600"
                      sx={{ marginBottom: "0.75rem" }}
                    >
                      {item.name}
                    </Typography>
                    <KeywordValueBox>
                      <Typography className="font-14 font-weight-600">
                        {getBucketSelectdItemsLength(item.type)}/
                        {getLengthForBucket(item.type)}
                      </Typography>
                      <Typography className="font-8 font-weight-400">
                        Selected Keywords
                      </Typography>
                    </KeywordValueBox>
                    {item.type === "negative_kw" && (
                      <>
                        <Grid
                          container
                          className="font-10 font-weight-500"
                          sx={{
                            color: "#F98181",
                            background: "#FFF4F4",
                            border: "0.7px solid #F98181",
                            borderRadius: "4px",
                            p: 1,
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            className="d-flex justify-content-center"
                          >
                            Potential Wasted Spend: {amountValue(wastedSpends)}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </KeywordsBox>
                ))}
              </>
            ) : (
              <Box className="h-100 w-100 d-flex align-items-center justify-content-center">
                <CircularProgress />
              </Box>
            )}
          </LeftGrid>
          <Grid
            item
            xs={9}
            height="100%"
            sx={{
              ml: "auto",
              display: "flex !important",
              justifyContent: "end !important",
            }}
          >
            <Box
              sx={{
                p: 2,
                border: "1px solid #F0F0F0",
                borderRadius: "0.5rem",
                background: "#FAFAFA",
                height: "100%",
                width: "100%",
                overflow: "auto",
              }}
            >
              {!loader ? (
                <>
                  <Box
                    className="bg-white d-flex w-100"
                    sx={{
                      mb: 2,
                      p: 2,
                      border: "1px solid #F0F0F0",
                      borderRadius: "0.5rem",
                      height: "12%",
                    }}
                  >
                    <OutlinedInput
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                      id="outlined-adornment-password"
                      startAdornment={
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      }
                      size="small"
                      placeholder="Search Term"
                      sx={{
                        fontStyle: "italic",
                        "& fieldset": { top: 0 },
                        "& legend": {
                          display: "none",
                        },
                      }}
                    />
                    <Box className="ml-auto">
                      {!showNoKwData && (
                        <>
                          {checkSelectAllBucket() ? (
                            <IconButton
                              disableRipple
                              onClick={() => {
                                handleSelectAllKeywords();
                              }}
                            >
                              <SelectAll />
                            </IconButton>
                          ) : (
                            <></>
                          )}
                          {checkDeselectAllBucket() ? (
                            <IconButton
                              disableRipple
                              className="ml-auto"
                              onClick={() => {
                                setShowDeselectModal(true);
                              }}
                            >
                              <DeselectAll />
                            </IconButton>
                          ) : (
                            <></>
                          )}
                          <BulkActionsPopover />
                        </>
                      )}
                      <FilterPopover />
                    </Box>
                  </Box>
                  {showDeselectModal &&
                    createPortal(
                      <StayHereModal {...deselectModalProps} />,
                      document.body
                    )}
                  {(showNoKwData || bucketLength === 0) && (
                    <NoKWData
                      description={
                        selectedKeywordsBucket === "kw_ideas" &&
                        !competationIndexCheck &&
                        !monthlyAvgSearchCheck ? (
                          <>
                            You haven't confgured the sources for exploring new
                            keywords.
                            <br /> Please go back and reconfigure calibration
                            step to view recommendations here.
                          </>
                        ) : filterText.length > 0 ? (
                          <>
                            There is no matching keyword found for the seatch
                            term you tried.
                            <br /> Please try changing the search term.
                          </>
                        ) : undefined
                      }
                      heading={
                        selectedKeywordsBucket === "kw_ideas" &&
                        !competationIndexCheck &&
                        !monthlyAvgSearchCheck
                          ? "Explore flow is not configured"
                          : filterText.length > 0
                          ? "No matching keywords found"
                          : undefined
                      }
                      svg={
                        (selectedKeywordsBucket === "kw_ideas" &&
                          !competationIndexCheck &&
                          !monthlyAvgSearchCheck) ||
                        filterText.length > 0 ? (
                          <NoDataFound />
                        ) : undefined
                      }
                    />
                  )}
                  <Box
                    id="keywords-data"
                    sx={{
                      height: "88%",
                      overflowY: "scroll",
                      overflowX: "none",
                      pb: 1,
                      display: showNoKwData || bucketLength === 0 ? "none" : "",
                    }}
                  >
                    <InfiniteScroll
                      scrollableTarget="keywords-data"
                      dataLength={dataLength}
                      next={handleNextPage}
                      hasMore={
                        selectedBucketKeys.length - dataLength > 0
                          ? true
                          : false
                      }
                      loader={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            py: 1,
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      }
                      className="d-flex flex-column"
                      style={{ overflow: "none", gap: "0.5rem" }}
                      ref={parent}
                    >
                      {selectedBucketKeys.slice(0, dataLength).map((item) => {
                        const parseItem: string | number = item;

                        // const items = selectedBucketData[parseItem];
                        const items = getFilteredItems(
                          selectedBucketData,
                          parseItem,
                          filteredIds,
                          appliedSelectedFilters,
                          selectedKeywordsBucket,
                          keywords
                        );
                        return (
                          <>
                            {items.length > 0 && (
                              <Keywords
                                key={parseItem}
                                showSaveModal={showSaveModal}
                                setShowSaveModal={setShowSaveModal}
                                keywords={keywords}
                                setKeywords={setKeywords}
                                starredItems={starredItems}
                                setStarredItems={setStarredItems}
                                showIconsOnHover={showIconsOnHover}
                                setShowIconsOnHover={setShowIconsOnHover}
                                isClusterView={isClusterView}
                                items={items}
                                viewDetails={viewDetails[parseItem]}
                                selectedFilter={
                                  selectedFilters &&
                                  selectedFilters[selectedKeywordsBucket]
                                    ? selectedFilters[selectedKeywordsBucket]
                                    : {}
                                }
                                selectedMetrics={
                                  METRIC_NAMES[selectedKeywordsBucket] as any
                                }
                                searchKey={
                                  SEARCH_METRIC_NAME[selectedKeywordsBucket]
                                }
                                currencySymbol={currencySymbol}
                                handleDeleteClick={handleDeleteClick}
                                handleDuplicateClick={handleDuplicateClick}
                                changeSingleProperty={changeSingleProperty}
                                handleSelectKeywords={handleSelectKeywords}
                                checkSelectAll={checkSelectAll}
                              />
                            )}
                          </>
                        );
                      })}
                    </InfiniteScroll>
                  </Box>
                </>
              ) : (
                <Box className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            boxShadow: "0px -2px 12px rgba(0, 0, 0, 0.05)",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "7%",
            px: 2,
          }}
        >
          <Grid item xs={11} className="font-lato font-12 font-weight-500">
            Total Keywords Generated: {getTotalLength()}
          </Grid>
          <Grid
            item
            xs={1}
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <IconButton
              disableRipple
              sx={{
                p: 2,
                display: "flex",
              }}
              onClick={exportToCsv}
            >
              <Download style={{ margin: "0px 8px" }} />
              <Grid
                className="font-600 font-12 font-inter"
                sx={{ fontStyle: "normal", color: "#0869FB" }}
              >
                Export as CSV
              </Grid>
            </IconButton>
            <PublishModal />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}

const getFilteredItems = (
  selectedBucketData: any,
  parseItem: any,
  filteredIds: any,
  appliedSelectedFilters: any,
  selectedKeywordsBucket: any,
  keywords: any
) => {
  const returnVal = selectedBucketData[parseItem]
    .filter((data: any) =>
      filteredIds !== null ? filteredIds?.includes(data) : true
    )
    ?.filter((data: any, index: number, arr: any) => {
      let foundingData: any = keywords[data];

      let filteringData: any = appliedSelectedFilters[selectedKeywordsBucket];
      if (!foundingData) {
        return false;
      }
      if (foundingData && selectedKeywordsBucket === "kw_ideas") {
        return (
          tooLongDecimal(foundingData["3m_change"]) >=
            tooLongDecimal(filteringData["3m_change"].selected[0]) &&
          tooLongDecimal(foundingData["3m_change"]) <=
            tooLongDecimal(filteringData["3m_change"].selected[1]) &&
          tooLongDecimal(foundingData["competitionIndex"]) >=
            tooLongDecimal(filteringData["competitionIndex"].selected[0]) &&
          tooLongDecimal(foundingData["competitionIndex"]) <=
            tooLongDecimal(filteringData["competitionIndex"].selected[1]) &&
          tooLongDecimal(foundingData["avgMonthlySearches"]) >=
            tooLongDecimal(filteringData["avgMonthlySearches"].selected[0]) &&
          tooLongDecimal(foundingData["avgMonthlySearches"]) <=
            tooLongDecimal(filteringData["avgMonthlySearches"].selected[1])
        );
      }
      if (foundingData && selectedKeywordsBucket === "positive_kw") {
        return (
          tooLongDecimal(foundingData["metrics.averageCpc"]) >=
            tooLongDecimal(filteringData["metrics.averageCpc"].selected[0]) &&
          tooLongDecimal(foundingData["metrics.averageCpc"]) <=
            tooLongDecimal(filteringData["metrics.averageCpc"].selected[1]) &&
          tooLongDecimal(foundingData["metrics.costPerConversion"]) >=
            tooLongDecimal(
              filteringData["metrics.costPerConversion"].selected[0]
            ) &&
          tooLongDecimal(foundingData["metrics.costPerConversion"]) <=
            tooLongDecimal(
              filteringData["metrics.costPerConversion"].selected[1]
            ) &&
          tooLongDecimal(foundingData["metrics.impressions"]) >=
            tooLongDecimal(filteringData["metrics.impressions"].selected[0]) &&
          tooLongDecimal(foundingData["metrics.impressions"]) <=
            tooLongDecimal(filteringData["metrics.impressions"].selected[1])
        );
      }
      if (foundingData && selectedKeywordsBucket === "negative_kw") {
        return (
          tooLongDecimal(foundingData["metrics.averageCpc"]) >=
            tooLongDecimal(filteringData["metrics.averageCpc"].selected[0]) &&
          tooLongDecimal(foundingData["metrics.averageCpc"]) <=
            tooLongDecimal(filteringData["metrics.averageCpc"].selected[1]) &&
          tooLongDecimal(foundingData["metrics.costPerConversion"]) >=
            tooLongDecimal(
              filteringData["metrics.costPerConversion"].selected[0]
            ) &&
          tooLongDecimal(foundingData["metrics.costPerConversion"]) <=
            tooLongDecimal(
              filteringData["metrics.costPerConversion"].selected[1]
            ) &&
          tooLongDecimal(foundingData["metrics.impressions"]) >=
            tooLongDecimal(filteringData["metrics.impressions"].selected[0]) &&
          tooLongDecimal(foundingData["metrics.impressions"]) <=
            tooLongDecimal(filteringData["metrics.impressions"].selected[1])
        );
      }
      return true;
    });
  return returnVal;
};
