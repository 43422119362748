import React, { useCallback } from "react";
import PinGrey from "../../../assets/png/pin-grey.png";
import PinBlue from "../../../assets/png/pin-blue.png";
import { ReactComponent as Delete } from "../../../assets/svg/delete.svg";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import LockGrey from "../../../assets/png/lock-grey.png";
import LockBlue from "../../../assets/png/lock-blue.png";
import ButtonUI from "../../../ui/ButtonUI/ButtonUI";
import CheckBox from "../../../ui/CheckBox/CheckBox";
import InputLabel from "../../../ui/InputLabel/InputLabel";
import "./index.scss";
import Loader from "./../../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ClusterState } from "../../../store/types/cluster";
import { initialState } from "../../../store/types/auth";
import {
  createDescription,
  createHeadline,
  setCreateAdDesc,
  setCreateAdHeadline,
  setCreateDescError,
  setCreateHeadlineError,
  setDescriptionID,
  setGenerateDescriptionTimeStamp,
  setGenerateHeadlineTimestamp,
  setHeadlineID,
  setLoadHeadLineDesc,
} from "../../../store/actions/cluster";
import PinPopup from "../PinPopup";
import { AdAccountState } from "../../../store/types/adaccount";
import HandleGSAIErrorWithRetry from "./HandleGSAIErrorWithRetry";


const Selection: React.FunctionComponent<{
  header: string;
  data: any;
  limit: number;
  popupInfo: string;
  setDeleteId: Function;
  headlinePriority?: number;
  setLock: Function;
  setHeadlinePriority?: Function;
  descPriority?: number;
  setDescPriority?: Function;
  loader?: boolean;
  totalLength: number;
}> = ({
  header,
  data,
  limit,
  popupInfo,
  setDeleteId,
  headlinePriority,
  setLock,
  setHeadlinePriority,
  descPriority,
  setDescPriority,
  loader,
  totalLength,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const [pagination, setPagination] = React.useState<number>(1);
  const [pinningPopup, setPinningPopup] = React.useState<any>({
    open: false,
    id: -1,
    pinningPositionId: 0,
  });
  const [lockedHeadlinesCount, setLockedHeadlinesCount] =
    React.useState<number>(0);
  const [lockedDescCount, setLockedDescCount] = React.useState<number>(0);
  const clusterState = useSelector(
    (state: { cluster: ClusterState }) => state.cluster
  );
  const {
    createHeadlineParam,
    createDescParam,
    clusterDetails,
    createAdHeadline,
    createAdDesc,
    createHeadlineError,
    createDescError,
  } = clusterState;
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedGSAIAdaccount } = adaccount;


  const openDeleteModal = (id: number) => {
    setDeleteId({
      type: header,
      id,
    });
  };

  const generateMore = (type: string, data:any) => {
    if (type === "headline") {
      let headlinePayload: any = { ...createHeadlineParam };
      headlinePayload.no_of_outputs = !data || data.length===0?5:2;
      dispatch(
        setLoadHeadLineDesc({ ...{ headline: true, description: false } })
      );
      dispatch(
        createHeadline(
          {
            accountID: selectedGSAIAdaccount?.id,
            params: headlinePayload,
            clusterID: clusterDetails?.cluster_req_id,
            user: auth?.user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              dispatch(setGenerateHeadlineTimestamp(+ new Date())); // generate timestamp
              dispatch(setHeadlineID(response.data.id));
            }
            else{
              dispatch(setCreateHeadlineError(true));
              dispatch(setGenerateHeadlineTimestamp(null)); 
            }
          }
        )
      );
    } else {
      let descPayload: any = { ...createDescParam };
      descPayload.no_of_outputs = !data || data.length===0?4:2;
      dispatch(
        setLoadHeadLineDesc({
          ...{ headline: false, description: true },
        })
      );
      dispatch(
        createDescription(
          {
            accountID: selectedGSAIAdaccount?.id,
            params: descPayload,
            clusterID: clusterDetails?.cluster_req_id,
            user: auth?.user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              dispatch(setGenerateDescriptionTimeStamp( + new Date())); // generate timestamp
              dispatch(setDescriptionID(response.data.id));
            }
            else{
              dispatch(setCreateDescError(true));
              dispatch(setGenerateDescriptionTimeStamp(null)); 
            }
          }
        )
      );
    }
  };

  const setPinPopup = useCallback(
    (val) => {
      setPinningPopup({
        open: true,
        id: val.id,
        pinningPositionId: val.pinningPositionId,
      });
    },
    [pinningPopup]
  );

  return (
    <>
      {/* Header */}
      <div className="create-ad-selection--heading">
        {header}: {data && data.filter((d: any) => d.selected).length} selected,{" "}
        {data && data.filter((d: any) => d.isPublished).length}/{totalLength} to
        Publish
        <span className="create-ad--icon-container">
          <Info
            style={{
              height: 16,
              width: 16,
              marginLeft: 8,
            }}
          />
          <div className="create-ad-heading--popup">{popupInfo}</div>
        </span>
      </div>

      {/* Chechbox and input */}
      {loader ? (
        <Loader />
      ) : (
        <>

          {data &&
            data.map((val: any, index: number) => {
              if (index < pagination * 15) {
                return (
                  <div className="flex selection-container" key={index}>
                    <CheckBox
                      onChange={(value) => {
                        const newCreateAd =
                          header === "headline"
                            ? [...createAdHeadline]
                            : [...createAdDesc];
                        if (value) {
                          let priority =
                            header === "headline"
                              ? headlinePriority! + 1
                              : descPriority! + 1;
                          newCreateAd[index].priority = priority;
                          if (header === "headline") {
                            setHeadlinePriority!(priority);
                          } else {
                            setDescPriority!(priority);
                          }
                        } else {
                          val.isPublished = false;
                        }
                        newCreateAd[index].selected = value;
                        header === "headline"
                          ? dispatch(setCreateAdHeadline(newCreateAd))
                          : dispatch(setCreateAdDesc(newCreateAd));
                      }}
                      value={val.selected}
                    />
                    {val.isLocked ? (
                      <img
                        src={LockBlue}
                        alt="Locked"
                        onClick={() => {
                          setLock(val.id, header);
                        }}
                      />
                    ) : (
                      <img
                        src={LockGrey}
                        style={{ width: "16px" }}
                        className="create-ad--lock-icon"
                        alt="Lock to publish"
                        title="Lock to publish"
                        onClick={() => {
                          setLock(val.id, header);
                        }}
                      />
                    )}
                    <InputLabel
                      label={header === "headline" ? val.label : ""}
                      limit={limit}
                      onInputChange={(e) => {
                        const newCreateAd =
                          header === "headline"
                            ? [...createAdHeadline]
                            : [...createAdDesc];
                        newCreateAd[index].value = e.target.value;
                        header === "headline"
                          ? dispatch(setCreateAdHeadline(newCreateAd))
                          : dispatch(setCreateAdDesc(newCreateAd));
                      }}
                      value={val.value}
                    />
                    <div className="icon-container">
                      {val.selected && (
                        <>
                          <div
                            className="d-flex align-items-center"
                            style={{ width: 27 }}
                          >
                            {val.pinningPositionId === 0 ? (
                              <img
                                src={PinGrey}
                                alt="Pin"
                                className={
                                  val.pinningPositionId === 0
                                    ? "create-ad--pin-icon"
                                    : ""
                                }
                                onClick={() => {
                                  setPinPopup(val);
                                }}
                              />
                            ) : (
                              <img
                                src={PinBlue}
                                alt="Pin"
                                className="create-ad--pin-icon-blue"
                                style={{ width: "11px" }}
                                onClick={() => {
                                  setPinningPopup({
                                    open: true,
                                    id: val.id,
                                    pinningPositionId: val.pinningPositionId,
                                  });
                                }}
                              />
                            )}

                            <span
                              className="ml-1"
                              style={{ color: "#3975ea", marginBottom: "4px" }}
                            >
                              {val.pinningPositionId > 0
                                ? val.pinningPositionId
                                : ""}
                            </span>
                          </div>
                        </>
                      )}
                      <Delete
                        className="create-ad--delete-icon"
                        style={{ width: "13px" }}
                        onClick={() => {
                          openDeleteModal(val.id);
                        }}
                      />
                    </div>
                  </div>
                );
              }
            })}
        {header === "headline" && createHeadlineError &&
        <HandleGSAIErrorWithRetry errorMsg="headlines" onClick={()=>generateMore(header, data)}/>
        }
        {header !== "headline" && createDescError  &&
        <HandleGSAIErrorWithRetry errorMsg="descriptions" onClick={()=>generateMore(header, data)}/>
        }
        </>
      )}

      {/* Generate more button */}
      <div className="create-ad-selection--footer">
        <ButtonUI
          type="button"
          className="secondary btn-generate-more"
          onClick={() => {
            generateMore(header,data);
            setPagination((p: number) => p + 1);
          }}
          disabled={loader}
        >
          Generate more
        </ButtonUI>
      </div>
      {pinningPopup.open ? (
        <PinPopup
          open={pinningPopup.open}
          id={pinningPopup.id}
          pinningPositionId={pinningPopup.pinningPositionId}
          setPinningPosition={(id, type, pinningPosition) => {
            const hIndex = data.findIndex((h: any) => h.id == id);

            data[hIndex].pinningPositionId = pinningPosition;
            if (type === "headline") {
              dispatch(setCreateAdHeadline([...data]));
            } else {
              dispatch(setCreateAdDesc([...data]));
            }
          }}
          header={header}
          setOpen={(e) => {
            setPinningPopup({
              open: e,
              id: -1,
              pinningPositionId: 0,
            });
          }}
        ></PinPopup>
      ) : (
        ""
      )}
    </>
  );
};

export default Selection;