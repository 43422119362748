import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
} from "@mui/material";
import {
  StyledAccordionText,
  StyledFormLeversContainer,
} from "components/CreativeAI/Facebook/shared/FormLevers/styled";
import { Button } from "components/shared";
import { UploadInputFormControl } from "components/shared/Input/UploadInput/UploadInput";
import {
  Control,
  FieldValues,
  useFieldArray,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { ReactComponent as ArrowDown } from "../../../../../../assets/svg/AccordionArrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../../../../assets/svg/accord_arrow_up.svg";

import LayerForm from "../LayerForm";
import { ReactComponent as BinIcon } from "../../../../../../assets/svg/bin_transparent.svg";
import TimeFrameInputController from "../../components/TimeFrameInput/TimeFrameInputController";
import { useState } from "react";
import { nanoid } from "nanoid";

interface FrameFormProps {
  control: Control<FieldValues, object>;
  registeredName: string;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  index: number;
  watch: UseFormWatch<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  deleteFrame: (index: number) => void;
  unregister: any;
}

export default function FrameForm({
  control,
  registeredName,
  setValue,
  index,
  getValues,
  watch,
  deleteFrame,
  setError,
  unregister,
}: FrameFormProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${registeredName}.${index}.layers`,
  });

  const deleteLayer = (layerIndex: number) => {
    remove(layerIndex);
  };

  const addLayer = (layerIndex: number) => {
    append({
      frontEndId: nanoid(),
    });
  };

  const usageType = watch("usageType");
  return (
    <StyledFormLeversContainer
      style={{
        marginTop: index > 0 ? "16px" : "0px",
      }}
    >
      <Accordion expanded={isExpanded}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <StyledAccordionText>Frame {index + 1}</StyledAccordionText>{" "}
              {usageType === "VIDEO_TEXT" && (
                <TimeFrameInputController
                  control={control}
                  registeredName={`${registeredName}.${index}.timeFrame`}
                />
              )}
            </Box>
            <Box display="flex" alignItems="center">
              {usageType === "VIDEO_TEXT" && (
                <IconButton
                  sx={{
                    marginRight: "10px",
                  }}
                  onClick={() => deleteFrame(index)}
                >
                  <BinIcon />
                </IconButton>
              )}
              <IconButton onClick={() => setIsExpanded((prev) => !prev)}>
                {isExpanded ? <ArrowUp /> : <ArrowDown />}
              </IconButton>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            marginTop: "-8px",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex" width="100%">
              <Box flex="1" pr="12px">
                <UploadInputFormControl
                  label="Preview File (png)"
                  tooltipText="Upload the image file in .png format"
                  disableUnderline
                  control={control}
                  registeredName={`${registeredName}.${index}.previewFile`}
                  required
                  fullWidth
                  placeholder="Upload Preview File"
                  sx={{ height: "40px", fontSize: "12px" }}
                  isBorder
                  backgroundColor="#fffffff"
                  acceptFileTypes=".png"
                  setValue={setValue}
                  setError={setError}
                  textInputDisabled
                />
              </Box>
              <Box flex="1" pl="12px">
                <UploadInputFormControl
                  label="Image Blender File"
                  tooltipText="Upload the image Blender file in .zip format"
                  disableUnderline
                  control={control}
                  registeredName={`${registeredName}.${index}.blenderFile`}
                  required
                  fullWidth
                  placeholder="Upload Blender File"
                  sx={{ height: "40px", fontSize: "12px" }}
                  isBorder
                  backgroundColor="#fffffff"
                  acceptFileTypes=".zip"
                  setValue={setValue}
                  setError={setError}
                  textInputDisabled
                />
              </Box>
            </Box>

            {fields.map((data: any, layerIndex: number) => (
              <LayerForm
                index={layerIndex}
                control={control}
                layerId={data.frontEndId}
                registeredName={`${registeredName}.${index}.layers`}
                watch={watch}
                getValues={getValues}
                deleteLayer={deleteLayer}
                key={data.id}
                unregister={unregister}
              />
            ))}
            <Button
              sx={{
                color: "#0869FB",
                marginTop: "10px",
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "13px",
                lineHeight: "17px",
              }}
              onClick={() => addLayer(index)}
            >
              + Add Layer
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </StyledFormLeversContainer>
  );
}
