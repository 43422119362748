import {
  BatchListPayloadType,
  CrReducerReset,
  GET_ADVANCE_SETTINGS,
  GET_NO_RECOMMENDATION_LIST,
  GET_PREVIEW_DATA,
  GetAdvanceSetting,
  GetBatch,
  GetBatchDetails,
  GetBatchDetailsPayload,
  GetBatchPayload,
  GetNoRecommendationList,
  GetPreviewData,
  GetPreviewDataPayload,
  Get_BATCH_DATA,
  Get_BATCH_DATA_DETAILS,
  POST_ADVANCE_SETTINGS,
  POST_EXECUTION,
  POST_EXECUTION_LOADER,
  PostAdvanceSetting,
  PostAdvanceSettingPayload,
  PostExecution,
  PostExecutionLoader,
  PostExecutionLoaderPayload,
  PostExecutionPayload,
  RESET_CR_REDUCER,
  SET_ADVANCE_SETTINGS,
  SET_AUTO_EXECUTION,
  SET_NON_RECOMMENDED_ADS,
  SET_NO_RECOMMENDATION_LIST,
  SET_PREVIEW_DATA,
  SetAdvanceSetting,
  SetAdvanceSettingPayload,
  SetAutoExecution,
  SetBatch,
  SetBatchDetails,
  SetBatchDetailsPayload,
  SetNoRecommendationList,
  SetNonRecommendedAds,
  SetPreviewData,
  SetPreviewPayload,
  Set_BATCH_DATA,
  Set_BATCH_DATA_DETAILS,
} from "store/types/ActionDebugger/CreativeRotation/creativeRotation.types";

export function getBatchData(payload: GetBatchPayload): GetBatch {
  return {
    type: Get_BATCH_DATA,
    payload,
  };
}

export function setAutoExecution(payload: {value:boolean,elementId:string, callback?:any}): SetAutoExecution {
  return {
    type: SET_AUTO_EXECUTION,
    payload,
  };
}

export function getNoRecommendationList(payload: {elementId:string, callback?:any}): GetNoRecommendationList {
  return {
    type: GET_NO_RECOMMENDATION_LIST,
    payload,
  };
}

export function setNoRecommendationList(payload: any): SetNoRecommendationList {
  return {
    type: SET_NO_RECOMMENDATION_LIST,
    payload,
  };
}

export function setNonRecommendedAds(payload: any): SetNonRecommendedAds {
  return {
    type: SET_NON_RECOMMENDED_ADS,
    payload,
  };
}

export function setBatchData(payload: BatchListPayloadType): SetBatch {
  return {
    type: Set_BATCH_DATA,
    payload,
  };
}

export function getBatchDataDetails(
  payload: GetBatchDetailsPayload
): GetBatchDetails {
  return {
    type: Get_BATCH_DATA_DETAILS,
    payload,
  };
}

export function setBatchDetails(
  payload: SetBatchDetailsPayload
): SetBatchDetails {
  return {
    type: Set_BATCH_DATA_DETAILS,
    payload,
  };
}

export const getAdvanceSetting = (payload: string): GetAdvanceSetting => ({
  type: GET_ADVANCE_SETTINGS,
  payload,
});

export const setAdvanceSetting = (
  payload: SetAdvanceSettingPayload
): SetAdvanceSetting => ({
  type: SET_ADVANCE_SETTINGS,
  payload,
});

export const postAdvanceSetting = (
  payload: PostAdvanceSettingPayload
): PostAdvanceSetting => ({
  type: POST_ADVANCE_SETTINGS,
  payload,
});

export const postExecution = (
  payload: PostExecutionPayload
): PostExecution => ({
  type: POST_EXECUTION,
  payload,
});

export const postExecutionLoader = (
  payload: PostExecutionLoaderPayload
): PostExecutionLoader => ({
  type: POST_EXECUTION_LOADER,
  payload,
});

export const crReducerReset = (payload: any): CrReducerReset => ({
  type: RESET_CR_REDUCER,
  payload,
});

export const getPreviewData = (
  payload: GetPreviewDataPayload
): GetPreviewData => ({
  type: GET_PREVIEW_DATA,
  payload,
});

export const setPreviewData = (payload: SetPreviewPayload): SetPreviewData => ({
  type: SET_PREVIEW_DATA,
  payload,
});
