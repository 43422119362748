import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box, CircularProgress } from "@mui/material";
import NoDataScreen from "components/ReviewLayout/NoDataScreen/NoDataScreen";
import { StyledDivider } from "components/shared";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getRequestedVariants,
  setHashId,
} from "store/actions/CreativeAI/shared/Review/review";
import { ReviewInitialState } from "store/types/CreativeAI/shared/Review/review";
import { localTimeFormat } from "utils/date.util";
import AppBar from "./AppBar/AppBar";
import { FbReviewAppBarType } from "./ReviewLayout.types";
import SideBar from "./SideBar/SideBar";
import { StyledBodyBox } from "./styled";
import { calculateNoOfAction } from "./utils/common.utils";

export default function ReviewLayout({
  children,
  type,
}: {
  children: ReactJSXElement;
  type: string;
}) {
  const dispatch = useDispatch();
  let { urlHash }: any = useParams();
  const [appBarData, setAppBarData] = useState<FbReviewAppBarType>({
    requestedBy: "",
    requestedAt: "",
    noOfVariant: 0,
    noOfAction: 0,
  });
  const {
    requestedVariantsMetaData: { data, isLoading, isFailed },
    requestedVariantData,
    selectedVariantRequestedId,
  } = useSelector(
    (state: { reviewReducer: ReviewInitialState }) => state.reviewReducer
  );

  useEffect(() => {
    dispatch(setHashId(urlHash));
    dispatch(
      getRequestedVariants({
        hashId: urlHash,
      })
    );
  }, []);

  useEffect(() => {
    if (
      selectedVariantRequestedId !== undefined &&
      data?.variants &&
      data?.variants?.[selectedVariantRequestedId]?.variantInfo
    ) {
      setAppBarData({
        brandName:
          data?.variants?.[selectedVariantRequestedId]?.variantInfo?.brandName,
        platformName: data?.platform,
        adAccountName:
          data?.variants?.[selectedVariantRequestedId]?.variantInfo
            ?.adAccountName,
        campaignName:
          data?.variants?.[selectedVariantRequestedId]?.variantInfo?.campaign,
        adSetName:
          data?.variants?.[selectedVariantRequestedId]?.variantInfo?.adset ??
          data?.variants?.[selectedVariantRequestedId]?.variantInfo?.adgroup,
        requestedBy: data?.reviewRequestedBy,
        requestedAt: localTimeFormat(new Date(data?.reviewRequestedAt)),
        noOfVariant: data?.variants.length,
        noOfAction: calculateNoOfAction(data?.variants),
      });
    }
  }, [selectedVariantRequestedId, data?.variants]);

  return (
    <Box
      display="flex"
      sx={{
        background: "#CCCCCC",
        fontSize: "1vw", // parent need to have a fix fontsize
      }}
    >
      <SideBar />
      {isLoading ? (
        <StyledBodyBox justifyContent="center" alignItems="center">
          <CircularProgress thickness={5} />
        </StyledBodyBox>
      ) : (
        <StyledBodyBox>
          {isFailed ? (
            <NoDataScreen />
          ) : (
            <>
              <AppBar appBarData={appBarData} />
              <StyledDivider m="1.2em 0em" color="#F0F0F0" />
              {children}
            </>
          )}
        </StyledBodyBox>
      )}
    </Box>
  );
}
