import { MenuItem } from "@mui/material";
import { SelectProps as MUISelectProps } from "@mui/material/Select";
import { Select } from "components/shared";
import {
  StyledMenuContent,
  StyledMenuItem,
  StyledMenuLabel,
} from "components/shared/Input/Select/Select.styled";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdGroups } from "store/actions/GsaiPlayground/CreateAdVariant";
import { initialState } from "store/types/auth";
import { GsaiState } from "store/types/CreativeAI/GsaiPlayground";
import { ReactComponent as CloseIcon } from "../../../../../assets/svg/close.svg";
import Button from "../../../../../ui/ButtonUI/ButtonUI";
import Modal from "../index";
import {
  Content,
  StyledCreateNewText,
  StyledDropdownContainer,
  StyledHeaderTitle,
  StyledVariantContainer,
  StyleNewVariantInput,
} from "./styled";

function NewVariant({
  handleClose,
  handleConfirm,
  handleNameChange,
  variantName,
  adGroupName,
  handleAdGroupNameChange,
  handleIsCreateNew,
  adGroups,
}: any) {
  const [dropdownOption, setDropdownOption] = React.useState("Select Ad Group");
  const [selectedCampaign, setSelectedCampaign] = React.useState(
    "Select Existing Ad Group"
  );
  const dispatch = useDispatch();
  const { user } = useSelector((state: { auth: initialState }) => state.auth);
  const gsaiPlayground = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );

  const { adAccount, campaigns } = gsaiPlayground;
  const { adAccountId: adaccountId = "" } = adAccount;

  useEffect(() => {
    dispatch(
      getAdGroups({ user, adaccountId, campaignId: campaigns?.selected?.id })
    );
  }, []);

  const isNew = React.useMemo(
    () =>
      dropdownOption.includes("New")
        ? 1
        : dropdownOption.includes("Existing")
        ? 0
        : -1,
    [dropdownOption]
  );

  const AdGroupMap = adGroups?.data?.map((data: any) => ({
    value: data.id,
    label: data.adscount,
  }));

  const handleCreateNewDropdownChange = (e: React.SyntheticEvent) => {
    let target = e.target as HTMLInputElement;
    setDropdownOption(target.value);
    handleIsCreateNew(target.value.toLowerCase().includes("new"));
  };

  const handleAdGroupSelection = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    setSelectedCampaign(target.value);
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        selectedCampaign: target.value,
      },
    });
  };

  const newVariantDropdownOption = [
    "Create New Ad Group",
    "Existing Ad Group ",
  ];

  return (
    <Modal
      header={
        <>
          <StyledHeaderTitle>New Variant</StyledHeaderTitle>
          <Button className="close" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </>
      }
      footer={
        <>
          <button className="transparent" onClick={handleClose}>
            Cancel
          </button>
          <button
            disabled={
              !variantName ||
              (dropdownOption.toLowerCase().includes("new")
                ? !adGroupName
                : isNaN(Number(selectedCampaign)))
            }
            className="primary"
            onClick={handleConfirm}
          >
            Publish
          </button>
        </>
      }
    >
      <Content>
        <StyledDropdownContainer>
          <NewVariantDropdown
            options={newVariantDropdownOption}
            value={dropdownOption}
            onChange={handleCreateNewDropdownChange}
          />
        </StyledDropdownContainer>
        {dropdownOption !== "Select Ad Group" ? (
          isNew === 1 ? (
            <StyledCreateNewText>
              The system will create a new ad group in the existing campaign and
              publish your variant ad under it.
            </StyledCreateNewText>
          ) : (
            <StyledCreateNewText>
              The system will duplicate the existing ad, make your changes and
              publish it in your existing ad group.
            </StyledCreateNewText>
          )
        ) : (
          ""
        )}
        <StyledVariantContainer>
          <label>Enter New Variant Name</label>
          <StyleNewVariantInput
            value={variantName}
            onChange={handleNameChange}
            placeholder="New variant name"
          />
        </StyledVariantContainer>

        {isNew === 1 && (
          <>
            <label>New Ad Group Name</label>
            <StyleNewVariantInput
              value={adGroupName}
              onChange={handleAdGroupNameChange}
              placeholder="Ad group name"
            />
          </>
        )}

        {isNew === 0 && (
          <>
            <label>Select Exisiting Ad Group</label>
            <NewVariantDropdown
              options={AdGroupMap}
              value={selectedCampaign}
              onChange={handleAdGroupSelection}
              style={{ width: "100%" }}
              sx={{
                "& .MuiSelect-select": {
                  background: "white",
                  border: "1px solid #DDDDDD",
                  boxShadow: "none",
                },
              }}
            >
              {AdGroupMap &&
                AdGroupMap.map((element: any) => {
                  return (
                    <MenuItem
                      disabled={element.label >= 3}
                      value={element.value}
                    >
                      <StyledMenuItem>
                        <StyledMenuContent>{element.value}</StyledMenuContent>
                        <StyledMenuLabel>
                          {element.label + "/3"}
                        </StyledMenuLabel>
                      </StyledMenuItem>
                    </MenuItem>
                  );
                })}
            </NewVariantDropdown>
          </>
        )}
      </Content>
    </Modal>
  );
}

export default NewVariant;

export interface newVariantDropdownProps extends MUISelectProps {
  options: any;
  value: string | number;
  onChange: any;
  style?: Object;
}

export const NewVariantDropdown = ({
  options,
  value,
  onChange,
  style,
  ...props
}: newVariantDropdownProps) => {
  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      className="new-variant-dropdown"
      sx={{
        "& .MuiSelect-select": {
          background: "rgba(228, 228, 228, 0.21)",
          borderRadius: ".375rem;",
        },
      }}
      MenuProps={{
        style: {
          zIndex: 2100,
        },
        PaperProps: {
          sx: {
            "& .MuiList-root.MuiList-padding.MuiMenu-list": {
              padding: "0rem",
            },
            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: "white",
            },
            "& .MuiMenuItem-root.Mui-selected:hover": {
              backgroundColor: "white",
            },
            background: "#FFFFFF",
            border: ".0625rem solid #DDDDDD",
            boxShadow: ".0625rem .125rem .6875rem rgba(0, 0, 0, 0.05)",
            borderRadius: ".375rem",
          },
        },
      }}
      menuItemStyle={{
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: ".875rem",
        lineHeight: "1.0625rem",
        color: "#313131",
        padding: ".625rem",
        borderBottom: ".0625rem solid #EFEFEF",
        margin: "0px .625rem",
        background: "white",
      }}
      style={{
        width: "12rem",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: ".875rem",
        lineHeight: "1.0625rem",
        color: "#1C1E21",
        background: "#f9f9f9",
        border: ".0625rem solid #dddddd",
        ...style,
      }}
      {...props}
      label={props.label ? (props.label as string) : undefined}
    />
  );
};
