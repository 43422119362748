import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import {
  CreateMediaStudio,
  CREATE_MEDIA_STUDIO,
  DeleteMediaStudio,
  DELETE_MEDIA_STUDIO,
  GetMediaData,
  GetMediaList,
  GetPresignedUrl,
  GET_MEDIA_DATA,
  GET_MEDIA_LIST,
  GET_PRESIGNED_URL,
  ON_SAVE_UPDATE,
  SET_FETCH_DATA_LOADING,
  SET_FETCH_LOADING,
  SET_MEDIA_DATA,
  SET_MEDIA_LIST,
  UpdateMediaStudio,
  UPDATE_MEDIA_STUDIO,
  UploadMedia,
  UPLOAD_MEDIA,
} from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import HttpService from "../../../../../HttpService/HttpService";
import { BASE_URL } from "../../../../../utils/path";

function* createMediaStudioAdmin(action: CreateMediaStudio): any {
  let {
    payload: { brandId, user, payload, finalAction },
  } = action;

  try {
    const urlParams = `/v1/api/media-studios/brands/${brandId}`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      user,
      payload
    );

    if (response.data.success) {
      const data = response.data.data;
      yield put({ type: ON_SAVE_UPDATE, payload: false });
      finalAction && finalAction(data,false);
      // yield put({ type: , payload: { data } });
    } else {
      finalAction && finalAction(response,true)
      yield put({ type: ON_SAVE_UPDATE, payload: false });
      console.log("got error in get createMediaStudio");
    }
  } catch (error: any) {
    finalAction && finalAction(error,true)
    yield put({ type: ON_SAVE_UPDATE, payload: false });
    console.log("got error in get createMediaStudio", error);
  }
}

function* getMediaList(action: GetMediaList): any {
  let {
    payload: { brandId },
  } = action;

  try {
    const urlParams = `/v1/api/media-studios/brands/${brandId}?skip=0&limit=100`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (response.data.success) {
      const data = response.data.data.mediaStudios;
      yield put({ type: SET_MEDIA_LIST, payload: data });
      yield put({ type: SET_FETCH_LOADING });
    } else {
      console.log("got error in get getMediaList");
      yield put({ type: SET_FETCH_LOADING });
    }
  } catch (error: any) {
    console.log("got error in get getMediaList", error);
    yield put({ type: SET_FETCH_LOADING });
  }
}

function* getMediaData(action: GetMediaData): any {
  let {
    payload: { brandId, mediaId },
  } = action;

  try {
    const urlParams = `/v1/api/media-studios/${mediaId}/brands/${brandId}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);

    if (response.data.success) {
      const data = response.data.data;
      yield put({ type: SET_MEDIA_DATA, payload: data });
      yield put({ type: SET_FETCH_DATA_LOADING });
    } else {
      console.log("got error in get getMediaData");
      yield put({ type: SET_FETCH_DATA_LOADING });
    }
  } catch (error: any) {
    console.log("got error in get getMediaData", error);
    yield put({ type: SET_FETCH_DATA_LOADING });
  }
}

function* deleteMediaStudio(action: DeleteMediaStudio): any {
  let {
    payload: { brandId, mediaId, user },
  } = action;

  try {
    const urlParams = `/v1/api/media-studios/${mediaId}/brands/${brandId}`;
    const response = yield call(HttpService.delete, BASE_URL, urlParams, user);

    yield put({
      type: GET_MEDIA_LIST,
      payload: { brandId: brandId },
    });
  } catch (error: any) {
    console.log("got error in get deleteMediaStudio", error);
  }
}

function* updateMediaStudio(action: UpdateMediaStudio): any {
  let {
    payload: { brandId, mediaId, user, payload, finalAction },
  } = action;

  try {
    const urlParams = `/v1/api/media-studios/${mediaId}/brands/${brandId}`;
    const response = yield call(
      HttpService.put,
      BASE_URL,
      urlParams,
      user,
      payload
    );

    if (response.data.success) {
      const data = response.data.data;
      yield put({ type: SET_MEDIA_DATA, payload: { data } });
      yield put({ type: ON_SAVE_UPDATE, payload: false });
      finalAction && finalAction(data,false);
    } else {
      finalAction && finalAction(response,true)
      yield put({ type: ON_SAVE_UPDATE, payload: false });
      console.log("got error in get updateMediaStudio");
    }
  } catch (error: any) {
    finalAction && finalAction(error,true)
    yield put({ type: ON_SAVE_UPDATE, payload: false });
    console.log("got error in get updateMediaStudio", error);
  }
}

function* getPresignedUrl(action: GetPresignedUrl): any {
  let {
    payload: { mediaId, finalAction, type },
  } = action;

  try {
    const urlParams = `/v1/api/media-assets/${mediaId}?file=${type}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);

    if (response.data.success) {
      const data = response.data.data;
      finalAction && finalAction(data, false);
    } else {
      console.log("got error in get getPresignedUrl");
      finalAction && finalAction({}, true);
    }
  } catch (error: any) {
    console.log("got error in get getPresignedUrl", error);
    finalAction && finalAction({}, true);
  }
}

function* uploadMedia(action: UploadMedia): any {
  let {
    payload: { file, user, finalAction, type },
  } = action;

  try {
    const urlParams = `/v1/api/media-assets/upload?file=${type}`;
    let formData = new FormData();
    formData.append("file", file);
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      user,
      formData
    );

    if (response.data.success) {
      const data = response.data.data;
      finalAction && finalAction(data, false);
      // yield put({ type: , payload: { data } });
    } else {
      console.log("got error in get uploadMedia");
      finalAction && finalAction(response, true);
    }
  } catch (error: any) {
    finalAction && finalAction({}, true);
    console.log("got error in get uploadMedia", error);
  }
}
export function* mediaStudioAdminWatcher() {
  yield takeLatest(GET_MEDIA_LIST, getMediaList);
  yield takeLatest(GET_MEDIA_DATA, getMediaData);
  yield takeLatest(DELETE_MEDIA_STUDIO, deleteMediaStudio);
  yield takeLatest(UPDATE_MEDIA_STUDIO, updateMediaStudio);
  yield takeEvery(GET_PRESIGNED_URL, getPresignedUrl);
  yield takeEvery(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest(CREATE_MEDIA_STUDIO, createMediaStudioAdmin);
}
