import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

export default function AdPreviewFrame({ previewLink }: { previewLink: any }) {
  const [iframeSrc, setIframeSrc] = useState("");
  const [previewDelay, setPreviewDelay] = useState(false);

  useEffect(() => {
    var doc: any = new DOMParser().parseFromString(previewLink, "text/xml");
    const iframSrc = doc?.firstChild.getAttribute("src");
    setIframeSrc(iframSrc);
  }, [previewLink]);

  useEffect(() => {
    loadingFunction();
  }, []);

  const loadingFunction = () => {
    setTimeout(() => {
      setPreviewDelay(true);
    }, 2500);
  };

  return (
    <Box
      display="flex"
      flex={1}
      sx={{
        border: "0.81724px solid #E5E5E5",
        bordeRadius: "3.26896px",
        marginTop: "1.5em",
      }}
      alignItems="center"
      justifyContent="center"
    >
      {!previewDelay && (
        <Skeleton
          animation="wave"
          height="100%"
          variant="rectangular"
          width="100%"
          sx={{
            bordeRadius: "3.26896px",
          }}
        />
      )}
      <iframe
        title="preview frame"
        style={{
          display: previewDelay ? "block" : "none",
          width: "100%",
          minHeight: "100%",
        }}
        src={iframeSrc}
      ></iframe>
    </Box>
  );
}
