import { Box } from "@mui/material";
import styled from "styled-components";

interface ActiveProp {
  active?: boolean;
}
interface Status {
  status?: boolean;
}

export const StyledAdStatusContainer = styled.span`
  width: 8.5em;
  height: 2.75em;
  font-size: 0.5em;
  background: #f7f7f7;
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.75em;
`;
export const StyledStatus = styled.span<Status>`
  padding-left: 0.25em;
  color: ${(props) => (props.status == true ? "#1ABFA1" : "#0869FB")};
`;
export const StyledCopyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1.3em;
  right: 0;
  height: 1.2em;
  z-index: 10;
  background-color: white;
  width: 3em;
`;
export const StyledVariantItemParaTitle = styled.p`
  position: relative;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.625em;
  color: #333333;
  // display: flex;
  // justify-content: start;
  // align-items: center;
  margin-bottom: 0em;
  width: 11.4em;
  height: 2.4em;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
`;
export const StyledAdIdSpan = styled.span`
  font-size: 0.5em;
  width: 10em;
  height: 1.25em;
  color: #999999;
`;
export const StyledAdsListMainContainer = styled(Box)`
  width: 19.75em;
  padding: 0.375em;
  @media (max-width: 1280px) {
    width: 95%;
  }
`;

export const StyledAdsListContainer = styled.div<ActiveProp>`
  background: #ffffff;
  position: relative;
  border: 0.25em solid #fafafa;
  outline: ${(props) => (props.active == false ? "" : "0.125em solid #0869FB")};
  border-radius: 0.5em;
  /* width: 100%;
  height: 100%; */
  height: 9.125em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow: hidden;
  .pointer {
    position: absolute;
    width: 0.625em;
    height: 0.625em;
    border-top: ${(props) =>
      props.active == false
        ? "0.125em solid #dddddd"
        : "0.125em solid #0869FB"};
    border-right: ${(props) =>
      props.active == false
        ? "0.125em solid #dddddd"
        : "0.125em solid #0869FB"};
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    background: white;
    right: -6px;
    display: ${(props) => (props.active == false ? "none" : "block")};
  }

  .ads-list-text {
    display: flex;
    justify-content: flex-start;
    // flex-direction: column;
    height: 5em;
  }
`;

export const StyledCurveWithIcons = styled.div`
  position: absolute;
  height: 9.3em;
  width: 9.3em;
  background: #fafafa;
  top: -6em;
  right: -3em;
  border-radius: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0.5em 1.5em;
`;
export const StyledIconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 5em;
  height: 2.5em;
  right: 0.3em;
  top: -0.3em;
`;

export const StyledAdsImage = styled.img`
  width: 5em;
  height: 5em;
  margin-left: 0.5em;
`;

export const StyledAdsCrousel = styled(Box)`
  width: 5em;
  height: 5em;
  margin-left: 0.5em;
`;

export const StyledAdsListContainerTitle = styled.div`
  height: 90%;
  margin-left: 0.75em;
  padding-top: 0.28em;
  width: 59%;
`;
export const StyledMainCreativeContainer = styled.div`
  /* position: fixed; */
  position: absolute;
  top: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* position: fixed;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999999999999999999; */
`;
export const StyledAdsSpendIcon = styled.div`
  height: 1.875em;
  width: 1.875em;
  margin-right: 0.5em;
`;
export const StyledAdsInDraft = styled.div`
  color: #7a7a7a;
  font-size: 0.625em;
  font-weight: 400;
  font-family: Inter;
`;
export const StyledAdsValues = styled.div`
  color: #333333;
  font-size: 0.625em;
  font-weight: bold;
  font-family: Inter;
`;

export const StyledAdsSpendFields = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
export const StyledAdSpendsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledBackDrop = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999;
`;

export const ImageEditIconContainer = styled.div`
  background: #ffffff;
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  paddding: 2rem;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  position: absolute;
  margin-right: 1.75rem;
  margin-top: 0.2rem;
`;
