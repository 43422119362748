import React from "react";

const ChartLabel: React.FC<{
  data: {
    name: string;
    color: string;
  }[];
}> = ({ data }) => {
  return (
    <div className="d-flex flex-column justify-content-center my-auto ml-auto">
      {data.map((label) => (
        <div className="d-flex mb-2">
          <div className="color-chip mr-2" style={{ background: label.color }} />
          <span className="text-sm font-weight-bold">{label.name}</span>
        </div>
      ))}
    </div>
  );
};

export default ChartLabel;
