import React from "react";
import AgencySummary from "./pages/AgencySummary";
import BrandSummary from "./pages/BrandSummary";
import AdAccountSummary from "./pages/AdAccountSummary";
import { Route } from "react-router-dom";
import "./SmbDashboard.scss";

function SmbDashboard() {
  return (
    <>
      <Route path="/smb-dashboard/brand" component={BrandSummary} />
      <Route path="/smb-dashboard/agency" component={AgencySummary} />
      <Route path="/smb-dashboard/adAccount" component={AdAccountSummary} />
    </>
  );
}

export default SmbDashboard;
