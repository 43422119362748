import { Typography } from "@mui/material";
import React from "react";

function DailyPacingText(props: any) {
  const checkConstraintType = (value: string) => {
    return value === "DAILY";
  };
  return checkConstraintType(props.subPacingType) ? (
    <Typography
      sx={{
        fontSize: "0.75em",
        lineHeight: "0.9375em",
        color: "#9D9D9D",
        paddingTop: "5px",
      }}
    >
      (Daily)
    </Typography>
  ) : (
    <Typography></Typography>
  );
}

export default DailyPacingText;
