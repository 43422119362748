import { sub, addDays, subDays, differenceInCalendarDays } from "date-fns";
import {
  EditDateType,
  SelectedDateType,
} from "../../../../store/types/ActionDebugger/ActionDebugger";
import { floor } from "lodash";
import { KeyIndicatorDataType } from "../sharedTypes";

export const calculatePreviousPeriod = (
  compareType: number,
  currentPeriod: SelectedDateType,
  editedBy: EditDateType
): SelectedDateType => {
  const { startDate, endDate } = currentPeriod;
  switch (compareType) {
    case 0: {
      const period = differenceInCalendarDays(endDate, startDate) + 1;

      return {
        startDate: sub(startDate, { days: period }),
        endDate: sub(endDate, { days: period }),
        editedBy,
      };
    }
    case 1: {
      return {
        startDate: sub(startDate, {
          days: daysInLastMonth(startDate),
        }),
        endDate: sub(endDate, {
          days: daysInLastMonth(endDate),
        }),
        editedBy,
      };
    }
    case 2: {
      return {
        startDate: sub(startDate, {
          days: isLeapYear(startDate.getFullYear()) ? 366 : 365,
        }),
        endDate: sub(endDate, {
          days: isLeapYear(endDate.getFullYear()) ? 366 : 365,
        }),
        editedBy,
      };
    }
    default:
      return {
        startDate: sub(new Date(), { days: 29 }),
        endDate: sub(new Date(), { days: 15 }),
        editedBy,
      };
  }
};

function daysInLastMonth(date: Date) {
  const month = date.getMonth();
  const year = date.getFullYear();
  if (month === 0) {
    return new Date(year - 1, 12, 0).getDate();
  }
  return new Date(year, month, 0).getDate();
}

function isLeapYear(year: number) {
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
}

export const calculatePaddedDate = (
  period: SelectedDateType,
  paddedDateNo: number
) => {
  const { startDate, endDate } = period;
  return {
    startDate: subDays(startDate, paddedDateNo),
    endDate: addDays(endDate, paddedDateNo),
  };
};
export const calculatePercentageValue = (
  currentValue: number,
  previiousValue: number
): number => {
  return previiousValue === 0
    ? currentValue === 0
      ? 0
      : currentValue
    : ((currentValue - previiousValue) / previiousValue) * 100 >= -0.5 &&
      ((currentValue - previiousValue) / previiousValue) * 100 <= 0.5
    ? Number(
        (((currentValue - previiousValue) / previiousValue) * 100).toFixed(1)
      )
    : Math.round(((currentValue - previiousValue) / previiousValue) * 100);
};

export const getPaginatedData = (
  paginatedData: any,
  noOfElementToShow: number
) => {
  let totalPage = 0;
  let paginatedKeyIndicators: KeyIndicatorDataType[] = [];
  for (
    let page = 0;
    page < floor(paginatedData.length / noOfElementToShow);
    page++
  ) {
    let listOfData = [];
    const initialIndex = page * noOfElementToShow;
    for (
      let itemIndex = initialIndex;
      itemIndex < initialIndex + noOfElementToShow;
      itemIndex++
    ) {
      listOfData.push(paginatedData[itemIndex]);
    }
    paginatedKeyIndicators.push(listOfData);
    totalPage++;
  }
  if (paginatedData.length % noOfElementToShow !== 0) {
    let listOfData = [];
    for (
      let itemIndex = totalPage * noOfElementToShow;
      itemIndex < paginatedData.length;
      itemIndex++
    ) {
      listOfData.push(paginatedData[itemIndex]);
    }
    totalPage++;
    paginatedKeyIndicators.push(listOfData);
  }
  return {
    totalPage: totalPage ? totalPage : 1,
    paginatedKeydata: paginatedKeyIndicators,
  };
};

export const isActionDebuggerOn = (platform: string) => {
  return (
    platform.includes("facebook") ||
    platform.includes("google") ||
    platform.includes("tiktok") ||
    platform.includes("apple") ||
    platform.includes("dv360")
  );
};

export const currencyFormatter = (num: number) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(2) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(2) + "M"; // convert to M for number from > 1 million
  } else if (num > 1000000000) {
    return (num / 1000000000).toFixed(2) + "B"; // convert to M for number from > 1 million
  } else if (num > 1000000000000) {
    return (num / 1000000000000).toFixed(2) + "T"; // convert to M for number from > 1 million
  } else {
    return num?.toFixed(2);
  }
};

export const getTooltipDate = (
  compareType: number,
  date: Date,
  differenceInCalenderDay: number
): Date => {
  switch (compareType) {
    case 0: {
      return subDays(date, differenceInCalenderDay);
    }
    case 1: {
      return sub(date, {
        months: 1,
      });
    }
    case 2: {
      return sub(date, {
        years: 1,
      });
    }
    default:
      return subDays(date, differenceInCalenderDay);
  }
};

export const valueFormatter = (num: number) => {
  if (num > 999 && num < 1000000) {
    return num / 1000 + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000 && num < 999999999) {
    return num / 1000000 + "M"; // convert to M for number from > 1 million
  } else if (num >= 1000000000 && num < 999999999999) {
    return num / 1000000000 + "B"; // convert to M for number from > 1 million
  } else if (num >= 1000000000000 && num < 999999999999) {
    return num / 1000000000000 + "T"; // convert to M for number from > 1 million
  } else {
    return num;
  }
};
