import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAutogenerateData,
  setUTGData,
} from "store/actions/CreativeAI_Revamp/facebook.actions";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";

export const useAutogenerateLoader = ({ adId }: { adId: string }) => {
  const { autogenerate, utgData } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );
  const [selected, setSelected] = useState<number>(
    utgData[adId]?.status?.step ?? autogenerate[adId]?.status?.step ?? 0
  );
  const ref = useRef({ progress: 0, step: 0 });
  const [progress, setProgress] = useState(
    utgData[adId]?.status?.progress ?? autogenerate[adId]?.status?.progress ?? 0
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setProgress(
      utgData[adId]?.status?.progress ??
        autogenerate[adId]?.status?.progress ??
        0
    );
    const timer = setInterval(
      () => {
        setProgress((prevProgress: number) =>
          prevProgress >= 99 ? (selected == 2 ? 99 : 100) : prevProgress + 1
        );
      },
      false ? 5 : 50
    );

    return () => {
      clearInterval(timer);
    };
  }, [selected]);

  useEffect(() => {
    if (progress > 99) {
      const timeout = setTimeout(() => {
        setSelected((prev: number) => prev + 1);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [progress, setSelected]);

  useEffect(() => {
    ref.current = { progress, step: selected };
  }, [progress, selected]);

  useEffect(() => {
    dispatch(
      setAutogenerateData({
        path: [adId, "status"],
        value: {
          progress: 0,
        },
      })
    );
    dispatch(
      setUTGData({
        path: [adId, "status"],
        value: {
          progress: 0,
        },
      })
    );
    return () => {
      const newStatus = {
        progress: ref.current.progress + 47,
        step: ref.current.step,
      };
      if (newStatus.progress > 99) {
        if (newStatus.step < 2) {
          newStatus.step += 1;
          newStatus.progress %= 100;
        } else {
          newStatus.progress = 99;
        }
      }
      dispatch(
        setAutogenerateData({
          path: [adId, "status"],
          value: newStatus,
        })
      );
      dispatch(
        setUTGData({
          path: [adId, "status"],
          value: newStatus,
        })
      );
    };
  }, []);

  return {
    selected,
    progress,
  };
};
