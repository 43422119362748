import React, { useCallback, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons/faUpload";
import "./index.scss";

const UploadFileButton: React.FC<{
  handelOnChange: (value: any) => void;
  buttonText: string;
  buttonIcon: any;
  buttonStyle: any;
  buttonContainerStyle: any;
}> = ({
  handelOnChange,
  buttonText,
  buttonIcon,
  buttonStyle,
  buttonContainerStyle,
}) => {
  const fileInput = useRef<any>();

  const uploadFileTarget = () => {
    fileInput.current.click();
  };

  const handleChange = useCallback(
    (event: any) => {
      if (event.target.files) {
        if (event.target.files[0].size > 1000000000) {
          alert("File size must not be greater then 1 GB");
        } else {
          const fileArray = Array.from(event.target.files).map((file) => file);

          const tempArr = fileArray;

          handelOnChange(tempArr);
        }
      }
    },
    [handelOnChange]
  );

  return (
    <div className="upload-file-container">
      <input
        type="file"
        name="profilePicture"
        accept="image/jpeg, image/png, video/mp4, video/x-matroska, .mkv"
        style={{ display: "none" }}
        ref={fileInput}
        onChange={(e) => handleChange(e)}
        multiple
        onClick={(e: any) => (e.target.value = null)}
      />
      <div
        className="upload-file-content"
        onClick={() => uploadFileTarget()}
        style={buttonContainerStyle}
      >
        {buttonIcon ? (
          buttonIcon
        ) : (
          <FontAwesomeIcon icon={faUpload} className="check mr-2" />
        )}
        <button className="file-upload-btn" style={buttonStyle}>
          {buttonText ?? "Upload"}
        </button>
      </div>
    </div>
  );
};

export default UploadFileButton;
