import { FunctionComponent, useCallback, useState } from "react";
import {
  StyledAdsManagerButton,
  StyledArrow,
  StyledCollapsibleToggleButton,
  StyledCollapsibleVariantsPreviewBox,
  StyledExpandText,
  StyledPreviewBox,
  StyledTabButton,
  StyledTabButtons,
  StyledVariantsPreview,
} from "./styled";
import { ReactComponent as Redirect } from "../../../../../assets/cai/svg/redirect.svg";
import { StyledText } from "components/shared";
import { Box } from "@mui/material";
import InHousePreview from "components/CreativeAI/Facebook/shared/InHousePreview/InHousePreview";
import { checkAdType, fetchAdTypeField } from "../../utils/common.utils_old";
import VariantCardLoading from "../../HomeScreen/VariantCard/VariantCardLoading";
import { LightTooltip } from "components/shared/LightTooltip";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { useSelector } from "react-redux";
import ErrorScreen from "./ErrorScreen";

const CollapsibleVariantsPreview: FunctionComponent<any> = ({
  fetchingAdDetails,
  adDetailsError,
  imageAvatarData,
  createSpecAPI,
  fbVideoData,
  fbImageData,
  selectedAdData,
  isMediaLoading,
  mediaUrl,
  data,
  drawerOpen,
  toggleDrawer,
  variantPreviewError,
}) => {
  const [activeTab, setActiveTab] = useState<number>(1);

  function handleTabChange(event: React.MouseEvent, tabIndex: number) {
    setActiveTab(tabIndex);
  }

  const { adsListLog } = useSelector(
    (state: { fbActivityLogReducer: any }) => state.fbActivityLogReducer
  );
  const { adAccountList, adSetList } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );

  const getDisplayUrlAndUrl = useCallback(() => {
    const { asset_feed_spec, object_story_spec } = createSpecAPI;
    const { video_data, link_data } = object_story_spec || {};
    if (link_data) {
      return {
        displayUrl: link_data?.caption ?? "",
        url: createSpecAPI?.object_story_spec?.link_data?.link ?? "",
      };
    } else if (video_data) {
      return {
        displayUrl: video_data?.call_to_action?.value?.link_caption ?? "",
        url: video_data?.call_to_action?.value?.link ?? "",
      };
    } else if (asset_feed_spec) {
      const { link_urls } = asset_feed_spec;
      return {
        displayUrl: link_urls?.[0]?.display_url,
        url: link_urls?.[0]?.website_url,
      };
    }
    return { displayUrl: "", url: "" };
  }, [createSpecAPI]);

  const loading =
    isMediaLoading ||
    fetchingAdDetails ||
    imageAvatarData?.isLoading ||
    fbVideoData?.isLoading ||
    fbImageData?.isLoading ||
    adAccountList?.isLoading ||
    adSetList.isLoading ||
    adsListLog?.isLoading;

  // console.log(
  //   "her are loading states",
  //   isMediaLoading,
  //   fetchingAdDetails,
  //   imageAvatarData?.isLoading,
  //   fbVideoData?.isLoading,
  //   fbImageData?.isLoading,
  //   adAccountList?.isLoading,
  //   adSetList.isLoading,
  //   adsListLog?.isLoading
  // );

  return (
    <StyledCollapsibleVariantsPreviewBox drawerOpen={drawerOpen}>
      {drawerOpen ? (
        <StyledVariantsPreview>
          <StyledTabButtons>
            <StyledTabButton
              selected={activeTab === 1}
              onClick={(event) => handleTabChange(event, 1)}
            >
              <StyledText
                fontSize="0.75em"
                lineHeight="0.9375em"
                color={activeTab === 1 ? "#0869FB" : "#333"}
              >
                Variant Ad
              </StyledText>
            </StyledTabButton>
            <StyledTabButton
              selected={activeTab === 2}
              onClick={(event) => handleTabChange(event, 2)}
            >
              <StyledText
                fontSize="0.75em"
                lineHeight="0.9375em"
                color={activeTab === 2 ? "#0869FB" : "#333"}
              >
                Reference Ad
              </StyledText>
            </StyledTabButton>
          </StyledTabButtons>
          {activeTab === 1 &&
            (loading ? (
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                width="100%"
                justifyContent="center"
                p=".8em 0em"
              >
                <VariantCardLoading />
              </Box>
            ) : (
              <StyledPreviewBox
                display="flex"
                alignItems="center"
                flex={1}
                justifyContent="center"
                p=".8em 0em"
              >
                {variantPreviewError ||
                (!variantPreviewError &&
                  (fbImageData.isFailed ||
                    fbVideoData.isFailed ||
                    (!mediaUrl &&
                      !fbImageData?.data?.imageUrl &&
                      !fbVideoData?.data?.videoUrl))) ? (
                  <ErrorScreen />
                ) : (
                  <InHousePreview
                    displayURL={
                      selectedAdData?.display_url ?? selectedAdData?.url
                    }
                    CTA_button={createSpecAPI?.call_to_action_type}
                    previewType={
                      selectedAdData?.ad_type === "SINGLE_IMAGE_AD" &&
                      data?.["Variant.variantType"] !== "billboard"
                        ? "image"
                        : "video"
                    }
                    profileAvatar={imageAvatarData?.data?.avatarUrl ?? ""}
                    profileName={imageAvatarData?.data?.avatarName ?? ""}
                    recommendationData={{
                      primary_text: selectedAdData?.primary_text,
                      description: selectedAdData?.description,
                      headline: selectedAdData?.headline,
                    }}
                    mediaURL={
                      mediaUrl.length > 0
                        ? mediaUrl
                        : fbImageData?.data?.imageUrl ??
                          fbVideoData?.data?.videoUrl
                    }
                    item={{}}
                    setMediaURL={() => {}}
                    origin="editor"
                  />
                )}
              </StyledPreviewBox>
            ))}
          {activeTab === 2 &&
            (loading ? (
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                width="100%"
                justifyContent="center"
                p=".8em 0em"
              >
                <VariantCardLoading />
              </Box>
            ) : (
              <StyledPreviewBox
                display="flex"
                alignItems="center"
                flex={1}
                justifyContent="center"
                p=".8em 0em"
              >
                {!createSpecAPI ||
                fbImageData.isFailed ||
                fbVideoData.isFailed ? (
                  <ErrorScreen />
                ) : (
                  <InHousePreview
                    displayURL={
                      getDisplayUrlAndUrl()?.displayUrl ??
                      getDisplayUrlAndUrl()?.url
                    }
                    CTA_button={createSpecAPI?.call_to_action_type}
                    previewType={
                      checkAdType(createSpecAPI) === "link_data"
                        ? "image"
                        : "video"
                    }
                    profileAvatar={imageAvatarData?.data?.avatarUrl ?? ""}
                    profileName={imageAvatarData?.data?.avatarName ?? ""}
                    recommendationData={{
                      primary_text: fetchAdTypeField(
                        createSpecAPI,
                        "primary_text"
                      ),
                      description: fetchAdTypeField(
                        createSpecAPI,
                        "description"
                      ),
                      headline: fetchAdTypeField(createSpecAPI, "headline"),
                    }}
                    mediaURL={
                      fbImageData?.data?.imageUrl ?? fbVideoData?.data?.videoUrl
                    }
                    item={{}}
                    setMediaURL={() => {}}
                    origin="editor"
                  />
                )}
              </StyledPreviewBox>
            ))}
          <LightTooltip
            title={
              activeTab === 1 && data?.status !== "published"
                ? "Kindly wait, your ad is not yet published"
                : ""
            }
            arrow
            color="#fffff"
          >
            <StyledAdsManagerButton
              disabled={
                (activeTab === 1 && data?.status !== "published") || loading
              }
              style={{
                opacity:
                  (activeTab === 1 && data?.status !== "published") || loading
                    ? 0.5
                    : 1,
              }}
              onClick={() => {
                const adLink = `https://business.facebook.com/adsmanager/manage/ads?act=${
                  selectedAdData?.ad_account_id
                }&filter_set=SEARCH_BY_ADGROUP_IDS-STRING_SET%1EANY%1E[%22${
                  activeTab === 1 ? data?.data?.new_ad_id : data?.data?.ad_id
                }%22]`;

                window.open(adLink, "_blank");
              }}
            >
              <Redirect />
              &nbsp;&nbsp;
              <StyledText lineHeight="0.75em" color="#0869fb">
                View in ads Manager
              </StyledText>
            </StyledAdsManagerButton>
          </LightTooltip>
        </StyledVariantsPreview>
      ) : (
        <StyledExpandText>
          <StyledText font-weight={400} line-height="0.75em" color="#AEAEAE">
            Expand to view Preview
          </StyledText>
        </StyledExpandText>
      )}
      <StyledCollapsibleToggleButton onClick={() => toggleDrawer()}>
        <StyledArrow drawerOpen={drawerOpen}></StyledArrow>
      </StyledCollapsibleToggleButton>
    </StyledCollapsibleVariantsPreviewBox>
  );
};

export default CollapsibleVariantsPreview;
