import React, { Component, createElement } from "react";

const PRECISION = 0.0001;
const isEqual = (n1, n2) => Math.abs(n1 - n2) < PRECISION;

export default class TruncateText extends Component {
  static defaultProps = {
    element: "div",
    line: 1,
    text: "",
    textElement: "span",
    truncateText: "…",
    maxCalculateTimes: 10,
  };

  componentDidMount() {
    const canvas = document.createElement("canvas");
    const docFragment = document.createDocumentFragment();
    docFragment.appendChild(canvas);
    this.canvas = canvas.getContext("2d");
    this.forceUpdate();
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    if (this.rafId) {
      window.cancelAnimationFrame(this.rafId);
    }
  }

  measureWidth(text) {
    return this.canvas.measureText(text).width;
  }

  getRenderText() {
    const {
      element,
      line,
      text,
      textElement,
      textTruncateChild,
      truncateText,
      maxCalculateTimes,
      ...props
    } = this.props;
    let swidth = 0;
    let scopeWidth = this.scope.getBoundingClientRect().width || swidth;
    if (scopeWidth) swidth = scopeWidth;
    // return if display:none
    if (scopeWidth === 0) {
      return null;
    }

    const fullTextWidth = this.measureWidth(text);
    // return if all of text can be displayed
    if (scopeWidth > fullTextWidth || isEqual(scopeWidth, fullTextWidth)) {
      return createElement(textElement, props, text);
    }

    let childText = "";
    if (textTruncateChild && typeof textTruncateChild.type === "string") {
      let type = textTruncateChild.type;
      if (type.indexOf("span") >= 0 || type.indexOf("a") >= 0) {
        childText = textTruncateChild.props.children;
      }
    }

    let currentPos = 1;
    let maxTextLength = text.length;
    let truncatedText = "";
    let splitPos = 0;
    let startPos = 0;
    let displayLine = line;
    let width = 0;
    let lastIsEng = false;
    let isPrevLineWithoutSpace = false;
    let lastPos = 0;
    let lastSpaceIndex = -1;
    let ext = "";
    let loopCnt = 0;
    while (displayLine-- > 0) {
      ext = displayLine
        ? ""
        : truncateText + (childText ? " " + childText : "");
      while (currentPos <= maxTextLength) {
        truncatedText = text.substr(startPos, currentPos);
        width = this.measureWidth(truncatedText + ext);
        if (width < scopeWidth) {
          splitPos = text.indexOf(" ", currentPos + 1);
          if (splitPos === -1) {
            currentPos += 1;
            lastIsEng = false;
          } else {
            lastIsEng = true;
            currentPos = splitPos;
          }
        } else {
          do {
            if (loopCnt++ >= maxCalculateTimes) {
              break;
            }
            truncatedText = text.substr(startPos, currentPos);
            if (!displayLine) {
              currentPos--;
            }
            if (truncatedText[truncatedText.length - 1] === " ") {
              truncatedText = text.substr(startPos, currentPos - 1);
            }
            if (lastIsEng) {
              lastSpaceIndex = truncatedText.lastIndexOf(" ");
              if (lastSpaceIndex > -1) {
                currentPos = lastSpaceIndex;
                if (displayLine) {
                  currentPos++;
                }
                truncatedText = text.substr(startPos, currentPos);
              } else {
                currentPos--;
                truncatedText = text.substr(startPos, currentPos);
              }
            } else {
              currentPos--;
              truncatedText = text.substr(startPos, currentPos);
            }
            width = this.measureWidth(truncatedText + ext);
          } while (
            (width > scopeWidth || isEqual(width, scopeWidth)) &&
            truncatedText.length > 0
          );
          startPos += currentPos;
          break;
        }
      }

      if (currentPos >= maxTextLength) {
        startPos = maxTextLength;
        break;
      }

      if (
        lastIsEng &&
        !isPrevLineWithoutSpace &&
        text.substr(lastPos, currentPos).indexOf(" ") === -1
      ) {
        isPrevLineWithoutSpace =
          text.substr(lastPos, currentPos).indexOf(" ") === -1;
        displayLine--;
      }
      lastPos = currentPos + 1;
    }

    if (startPos === maxTextLength) {
      return createElement(textElement, props, text);
    }

    return (
      <span {...props}>
        <span style={{whiteSpace:"pre-wrap"}}>
          {text.substr(0, startPos).split(" ").slice(0, -1).join(" ") +
            truncateText +
            " "}
        </span>
        {textTruncateChild}
      </span>
    );
  }

  render() {
    const {
      element,
      text,
      style = {},
      line,
      textElement,
      textTruncateChild,
      truncateText,
      maxCalculateTimes,
      ...props
    } = this.props;

    const { fontWeight, fontStyle, fontSize, fontFamily } = style;

    const renderText =
      this.scope && line ? this.getRenderText() : <span style={{whiteSpace:"pre-wrap"}}>{text}</span>;
    const rootProps = {
      ref: (el) => {
        this.scope = el;
      },
      id: "truncateTextComponent",
      style: {
        overflow: "hidden",
        whiteSpace:"pre-wrap",
        fontWeight,
        fontStyle,
        fontSize,
        fontFamily,
      },
    };
    return createElement(element, rootProps, renderText);
  }
}
