import {
  MediaStudioTestReducerInitialState,
  TestMediaStudioPost,
  TestMediaStudioPostResponse,
  CREATE_TEST_MEDIA_STUDIO,
  CREATE_TEST_MEDIA_STUDIO_RESPONSE,
  SetTestMediaStudioTimer,
  SET_TEST_MEDIA_STUDIO_TIMER,
  SET_ERROR_PRESIGINED_URL,
  SetErrorPresignedUrl,
} from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";

const initialState: MediaStudioTestReducerInitialState = {
  runTestPostResponse: {
    data: [],
    isFailed: false,
    isLoading: true,
  },
  previewError:false,
  testMediaStudioTimer: null,
};

export const mediaStudioTestReducer = (
  state = initialState,
  {
    type,
    payload,
  }: TestMediaStudioPost | TestMediaStudioPostResponse | SetTestMediaStudioTimer | SetErrorPresignedUrl
) => {
  switch (type) {
    case CREATE_TEST_MEDIA_STUDIO:
      return {
        ...state,
        runTestPostResponse: {
          ...state.runTestPostResponse,
          isLoading: true,
        },
      };

    case CREATE_TEST_MEDIA_STUDIO_RESPONSE:
      return {
        ...state,
        runTestPostResponse: payload,
      };

      case SET_ERROR_PRESIGINED_URL:
        return {
          ...state,
          previewError: payload,
        };
  
    case SET_TEST_MEDIA_STUDIO_TIMER:
      return {
        ...state,
        testMediaStudioTimer: payload,
      };

    default:
      return state;
  }
};
