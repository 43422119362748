import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Toggle from "react-toggle";
import { useSelector, useDispatch } from "react-redux";
import { setGSKeywordTuningData } from "store/actions/gsTargetingAI";
import { ReactComponent as InfoIcon } from "../../assets/svg/infoIcon.svg";

function CompetitionIndex() {
  const dispatch = useDispatch();
  const { competationIndexCheck, competationIndex } = useSelector(
    (state: any) => state.gsTargetingAI.gsKeywordTuning.data
  );
  const { GoogleKwsPlannerCheck, CalibrationCustomKeywordsCheck } = useSelector(
    (state: any) => state.gsTargetingAI.gsCalibration.data
  );

  const handleCompetationIndex = (switchClicked: string) => {
    switch (switchClicked) {
      case "high":
        if (competationIndex.high) {
          dispatch(
            setGSKeywordTuningData({
              competationIndex: {
                ...competationIndex,
                high: false,
              },
            })
          );
        } else {
          dispatch(
            setGSKeywordTuningData({
              competationIndex: {
                ...competationIndex,
                high: true,
              },
            })
          );
        }
        break;
      case "med":
        if (competationIndex.medium) {
          dispatch(
            setGSKeywordTuningData({
              competationIndex: { ...competationIndex, medium: false },
            })
          );
        } else {
          dispatch(
            setGSKeywordTuningData({
              competationIndex: { ...competationIndex, medium: true },
            })
          );
        }
        break;
      case "low":
        if (competationIndex.low) {
          dispatch(
            setGSKeywordTuningData({
              competationIndex: { ...competationIndex, low: false },
            })
          );
        } else {
          dispatch(
            setGSKeywordTuningData({
              competationIndex: { ...competationIndex, low: true },
            })
          );
        }
        break;
      default:
        break;
    }
  };
  return (
    <Stack
      spacing={1}
      sx={{
        width: "50%",
        background: "#FFFFFF",
        border: "1px solid #EFEFEF",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
        borderRadius: "10px",
        m: 2,
        p: 2,
        opacity: `${
          (!GoogleKwsPlannerCheck && !CalibrationCustomKeywordsCheck) ||
          !competationIndexCheck
            ? 0.5
            : 1
        }`,
      }}
    >
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="font-12 font-600">
          Competition Index <InfoIcon />
        </Box>
        <Toggle
          disabled={!GoogleKwsPlannerCheck && !CalibrationCustomKeywordsCheck}
          checked={competationIndexCheck}
          className="right-0 custom-react-toggle"
          icons={false}
          id="biscuit-status"
          aria-labelledby="biscuit-label"
          onChange={() => {
            if (competationIndexCheck) {
              // false case
              dispatch(
                setGSKeywordTuningData({
                  competationIndexCheck: false,
                  competationIndex: { high: false, medium: false, low: false },
                })
              );
            } else {
              // true case
              dispatch(
                setGSKeywordTuningData({
                  competationIndexCheck: true,
                  competationIndex: { high: true, medium: true, low: true },
                })
              );
            }
          }}
        />
      </Stack>
      <Box className="font-10">
        Select the level of competition index to explore desired keywords
      </Box>
      <Divider
        variant="middle"
        style={{ borderColor: "#EFEFEF", opacity: "0.1" }}
      />
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="font-12 font-400">High</Box>
        <Toggle
          disabled={!competationIndexCheck}
          checked={competationIndex.high}
          className="right-0 custom-react-toggle"
          icons={false}
          id="biscuit-status"
          aria-labelledby="biscuit-label"
          onChange={() => {
            handleCompetationIndex("high");
          }}
        />
      </Stack>
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="font-12 font-400">Medium</Box>
        <Toggle
          disabled={!competationIndexCheck}
          checked={competationIndex.medium}
          className="right-0 custom-react-toggle"
          icons={false}
          id="biscuit-status"
          aria-labelledby="biscuit-label"
          onChange={() => {
            handleCompetationIndex("med");
          }}
        />
      </Stack>
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="font-12 font-400">Low</Box>
        <Toggle
          disabled={!competationIndexCheck}
          checked={competationIndex.low}
          className="right-0 custom-react-toggle"
          icons={false}
          id="biscuit-status"
          aria-labelledby="biscuit-label"
          onChange={() => {
            handleCompetationIndex("low");
          }}
        />
      </Stack>
    </Stack>
  );
}

export default CompetitionIndex;
