import {
  GetAdData,
  GetAdDataResponseType,
  GetRequestedVariant,
  GetRequestedVariantPayload,
  GetRequestedVariants,
  GET_AD_DATA,
  GET_REQUESTED_VARIANT,
  GET_REQUESTED_VARIANTS_DATA,
  PostReviewResponse,
  POST_REVIEW_RESPONSE,
  RequestedVariantResponseType,
  RequestedVariantsType,
  ResponsePayload,
  ReviewStatusType,
  SetAdData,
  SetHashID,
  SetRequestedVariant,
  SetRequestedVariants,
  SET_AD_DATA,
  SET_HASH_ID,
  SET_REQUESTED_VARIANT,
  SET_REQUESTED_VARIANTS_DATA,
  UpdateComments,
  UpdateCommentsPayload,
  UpdateReviewStatus,
  UpdateSelectedVariantId,
  UPDATE_COMMENTS,
  UPDATE_REVIEW_STATUS,
  UPDATE_SELECTED_VARIANT_ID,
} from "store/types/CreativeAI/shared/Review/review";

export const setHashId = (payload: string): SetHashID => ({
  type: SET_HASH_ID,
  payload,
});

export const getRequestedVariants = (payload: {
  hashId: string;
}): GetRequestedVariants => ({
  type: GET_REQUESTED_VARIANTS_DATA,
  payload,
});

export const setRequestedVariants = (
  payload: RequestedVariantsType
): SetRequestedVariants => ({
  type: SET_REQUESTED_VARIANTS_DATA,
  payload,
});

export const getRequestedVariant = (
  payload: GetRequestedVariantPayload
): GetRequestedVariant => ({
  type: GET_REQUESTED_VARIANT,
  payload,
});

export const setRequestedVariant = (
  payload: RequestedVariantResponseType
): SetRequestedVariant => ({
  type: SET_REQUESTED_VARIANT,
  payload,
});

export const getAd = (payload: {
  adId: string;
  hashId: string;
  adAccountId: string;
}): GetAdData => ({
  type: GET_AD_DATA,
  payload,
});

export const setAd = (payload: GetAdDataResponseType): SetAdData => ({
  type: SET_AD_DATA,
  payload,
});

export const postReviewResponse = (
  payload: ResponsePayload
): PostReviewResponse => ({
  type: POST_REVIEW_RESPONSE,
  payload,
});

export const updateComments = (
  payload: UpdateCommentsPayload
): UpdateComments => ({
  type: UPDATE_COMMENTS,
  payload,
});

export const updateSelectedVariantId = (
  payload?: number
): UpdateSelectedVariantId => ({
  type: UPDATE_SELECTED_VARIANT_ID,
  payload,
});

export const updateReviewStatus = (
  payload: ReviewStatusType
): UpdateReviewStatus => ({
  type: UPDATE_REVIEW_STATUS,
  payload,
});
