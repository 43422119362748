import { textTruncateMore } from "components/CreativeAI/Facebook/utils/text.utils";
import React from "react";
import { useSelector } from "react-redux";
import { ReviewInitialState } from "store/types/CreativeAI/shared/Review/review";
import {
  StyledFirstPara,
  StyledHeadlineContainer,
  StyledParaContent,
  StyledSecondPara,
  StyledThirdPara,
} from "../../Shared/GsaiLandingPageCard/styled";
import PreviewDetails from "./PreviewDetails/PreivewDetails";
import {
  StyledPreviewContainer,
  StyledPreviewDetails,
  StyledPreviewHeader,
  StyledPreviewSectionBody,
  StyledPreviewText,
} from "./styles";

const PreviewSection: React.FC<{}> = (): JSX.Element => {
  const {
    requestedVariantData: { data, isLoading },
  } = useSelector(
    (state: { reviewReducer: ReviewInitialState }) => state.reviewReducer
  );

  const variantMetaInfo = data?.variantMetaInfo?.data;
  const headlines = (variantMetaInfo?.existingHeadlines || []).map(
    (item: any) => item.value
  );
  const headlineText = headlines.join(" | ");

  const descriptions = (variantMetaInfo?.existingDescriptions || []).map(
    (item: any) => item.value
  );
  const descriptionText = descriptions.join(" | ");
  return (
    <StyledPreviewSectionBody>
      <StyledPreviewHeader>Existing Ad</StyledPreviewHeader>
      <StyledPreviewContainer>
        <StyledParaContent>
          <StyledHeadlineContainer>
            <StyledFirstPara>
              <StyledPreviewText>{headlineText}</StyledPreviewText>
            </StyledFirstPara>
          </StyledHeadlineContainer>
          <StyledSecondPara>
            <StyledPreviewText>
              {textTruncateMore(
                (data?.variantMetaInfo?.meta_data?.adGroupAd?.ad?.finalUrls &&
                  data?.variantMetaInfo?.meta_data?.adGroupAd?.ad?.finalUrls
                    .length > 0 &&
                  data?.variantMetaInfo?.meta_data?.adGroupAd?.ad?.finalUrls[0].split(
                    "/"
                  )[2]) +
                  (data?.variantMetaInfo?.meta_data?.adGroupAd?.ad
                    ?.responsiveSearchAd?.path1
                    ? `/${data?.variantMetaInfo?.meta_data?.adGroupAd?.ad?.responsiveSearchAd?.path1}`
                    : "") +
                  (data?.variantMetaInfo?.meta_data?.adGroupAd?.ad
                    ?.responsiveSearchAd?.path2
                    ? `/${data?.variantMetaInfo?.meta_data?.adGroupAd?.ad?.responsiveSearchAd?.path2}`
                    : ""),
                40
              )}
            </StyledPreviewText>
          </StyledSecondPara>
          <StyledThirdPara>
            <StyledPreviewText>{descriptionText}</StyledPreviewText>
          </StyledThirdPara>
        </StyledParaContent>
      </StyledPreviewContainer>
      <StyledPreviewDetails>
        <PreviewDetails title="Headline" data={headlines} />
        <PreviewDetails title="Description" data={descriptions} />
      </StyledPreviewDetails>
    </StyledPreviewSectionBody>
  );
};

export default PreviewSection;
