import React, { useEffect, useState } from "react";
import TooltipWithInfo from "../../Facebook/shared/TooltipWithInfo/TooltipWithInfo";
import {
  StyledHeaderContainer,
  StyledHeadTitle,
  StyledLinkItem,
} from "../styled";
import { ReactComponent as AddIcon } from "../../../../assets/svg/buAddIcon.svg";
import MediaStudioAdminTable from "./MediaStudioAdminTable";
import { Box } from "@mui/material";
import MediaStudioModal from "./MediaStudioModal";
import { AdAccountState } from "store/types/adaccount";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMediaStudio,
  getMediaData,
  getMediaList,
} from "store/actions/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import Loader from "components/common/Loader";

export default function MediaStudioAdmin() {
  const [showMediaStudioModal, setShowMediaStudioModal] = useState(false);
  const [selectedMediaStudio, setSelectedMediaStudio] = useState<string>("");
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { fetchingMediaList, fetchingMediaData, addingMediaStudio, mediaList } =
    useSelector((state: any) => state.mediaStudioAdminReducer);
  const [isEdit, setIsEdit] = useState(false);
  const { selectedBrand } = adaccount;
  const dispatch = useDispatch();
  const onClose = () => {
    setShowMediaStudioModal(false);
  };
  const onEdit = (id: string) => {
    setIsEdit(true);
    setSelectedMediaStudio(id);
    dispatch(getMediaData({ mediaId: id, brandId: selectedBrand?.id }));
    setShowMediaStudioModal(true);
  };
  useEffect(() => {
    dispatch(getMediaList({ brandId: selectedBrand?.id }));
  }, []);

  const onDelete = (id: string) => {
    dispatch(deleteMediaStudio({ mediaId: id, brandId: selectedBrand?.id }));
  };
  return (
    <Box height="50%" pt="20px">
      <StyledHeaderContainer>
        <StyledHeadTitle>
          Media Studio
          <TooltipWithInfo info="Configure and upload Media Studio files for specific Ads and view their creatives in the Dashboard." />
        </StyledHeadTitle>
        <StyledLinkItem
          onClick={() => {
            setShowMediaStudioModal(true);
            setIsEdit(false);
          }}
        >
          <AddIcon style={{ marginRight: "10px" }} />
          Add Media Studio File
        </StyledLinkItem>
      </StyledHeaderContainer>
      {fetchingMediaList ? (
        <Loader />
      ) : (
        <Box height="85%" overflow="scroll">
          <MediaStudioAdminTable
            onEdit={onEdit}
            selectedMediaStudio={selectedMediaStudio}
            setSelectedMediaStudio={setSelectedMediaStudio}
            onDelete={onDelete}
          />
        </Box>
      )}

      <MediaStudioModal
        onClose={onClose}
        showMediaStudioModal={showMediaStudioModal}
        selectedMediaStudio={selectedMediaStudio}
        setSelectedMediaStudio={setSelectedMediaStudio}
        isEdit={isEdit}
        fetchingMediaData={fetchingMediaData}
        addingMediaStudio={addingMediaStudio}
      />
    </Box>
  );
}
