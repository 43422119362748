import { Box, Skeleton } from "@mui/material";
import { LightTooltip } from "components/shared/LightTooltip";
import React from "react";

export default function LoadingPerformanceScore() {
  return (
    <LightTooltip
      title="Calculating the Performance Score"
      arrow
      placement="right"
    >
      <Box
        display="flex"
        gap=".25em"
        p=".27em 0em 0em .27em"
        alignSelf="flex-start"
      >
        <Skeleton
          variant="rectangular"
          width="8.125em"
          height="1em"
          sx={{
            borderRadius: "4px",
          }}
        />
        <Skeleton
          variant="rectangular"
          width="4.3125em"
          height="1em"
          sx={{
            borderRadius: "4px",
          }}
        />
      </Box>
    </LightTooltip>
  );
}
