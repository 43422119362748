import {
  SET_CREATIVE_ROTATION_LOGS,
  SET_CREATIVE_ROTATION_LOGS_DATE_RANGE,
  SET_CREATIVE_VARIANTS_LOGS,
  SET_CREATIVE_VARIATION_LOGS_DATE_RANGE,
} from "../../../../types/CreativeAI/Facebook/CreateVariant/createVariantType";

const initialState = {
  creativeRotations: {},
  creativeVariantsLogs: {},
  creativeRotationDateRange: {},
  creativeVariationDateRange: {},
  getActivityLogsState: {
    loading: false,
    success: "",
    error: "",
  },
};

const activityLogsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CREATIVE_ROTATION_LOGS: {
      return {
        ...state,
        creativeRotations: action.payload,
      };
    }
    case SET_CREATIVE_VARIANTS_LOGS: {
      return {
        ...state,
        creativeVariantsLogs: { ...action.payload },
      };
    }
    case SET_CREATIVE_ROTATION_LOGS_DATE_RANGE: {
      return {
        ...state,
        creativeRotationDateRange: { ...action.payload },
      };
    }
    case SET_CREATIVE_VARIATION_LOGS_DATE_RANGE: {
      return {
        ...state,
        creativeVariationDateRange: { ...action.payload },
      };
    }
    case "GET_CREATIVE_VARIATION_LOGS_STATE": {
      return {
        ...state,
        getActivityLogsState: { ...action.payload },
      };
    }

    default:
      return state;
  }
};
export default activityLogsReducer;
