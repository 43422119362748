import { SxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import React, { FC } from "react";
import { Theme } from "@mui/material/styles";
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  background: "white",
  border: "1px solid #DDDDDD !important",
  borderRadius: "8px !important",
  width: "100%",
  "& input::placeholder": {
    opacity: 1,
    color: "#333 !important",
  },
  "& input": {
    fontFamily: "Inter !important",
    fontSize: "12px",
    fontWeight: 400,
  },
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.4),
  },
  "& fieldset": { top: 0 },
  "& legend": { display: "none" },
}));
const InputField: FC<{
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  variant?: "outlined" | "filled" | "standard" | undefined;
  sx?: SxProps<Theme> | undefined;
  size?: "small" | "medium" | undefined;
  placeholder?: string | undefined;
  type?: any;
  inputProps?: any;
  otherProps?: any;
  autoFocus?:any
}> = ({
  value = "",
  sx = undefined,
  size = undefined,
  placeholder = undefined,
  setValue,
  type,
  inputProps,
  otherProps,
  autoFocus=false
}) => {
  const handleChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (event) => {
    setValue(event.target.value);
  };

  return (
    <StyledInputBase
      id="outlined-basic"
      sx={sx}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      size={size}
      type={type}
      inputProps={inputProps}
      autoFocus={autoFocus}
      {...otherProps}
    />
  );
};

export default InputField;
