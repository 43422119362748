import { Box, styled } from "@mui/system";

export const StyledClipBoardBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  background: rgba(8, 105, 251, 0.1);
  border-radius: 8px;
  align-items: center;
  padding-left: 12px;
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  width: 100%;
  margin: 12px 0px;
`;
