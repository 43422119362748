import { aiGroups, parentAiGroups } from "MappedAPI/MappedAPI";
import HttpService from "HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "utils/path";

export const getFestivals = async (startDate: string, endDate: string) => {
  let response = {
    success: false,
    message: "",
    data: [],
  };
  try {
    let urlParams = `/v1/api/common/holidays?startDate=${startDate}&endDate=${endDate}`;
    response = await HttpService.get(CROSS_PLATFORM_URL, urlParams).then(
      (data: any) => {
        return data.data;
      }
    );
    return response;
  } catch (error: any) {
    console.log("getFestivals => ", error.message);
  }
  return response;
};
