export const SIDEBAR_STEPS = [
  {
    id: 1,
    text: "AI Initiation",
    subText:
      "Get started by feeding your brand details and AI Group to setup the Playground",
    index: 1,
  },
  {
    id: 2,
    text: "Calibration",
    subText:
      "Configure the data sources using smart levers to optimize audience discovery",
    index: 3,
  },
  {
    id: 3,
    text: "Keyword Tuning",
    subText:
      "Modify intermediate keyword outputs that act as seed data for the Targeting AI models",
    index: 5,
  },
  {
    id: 4,
    text: "Audience Explorer",
    subText:
      "Explore & publish audiences recommended by the AI models basis quality indicators",
    index: 7,
  },
];

export const KEYWORDLOADERLABELS = [
  "Brand",
  "Generic",
  "Lateral",
  "Custom",
];

export const SPECIAL_AD_CATEGORIES = [
  "Housing",
  "Employment",
  "Credit",
  "Social Issues, Elections, Politics",
];

export const CLUS_TYPE = {
  Direct: {
    background: "#EDF6FE",
    textColor: "#4CA8F5",
    borderColor: "#B7DCFB",
    tooltipText: "Direct cohorts are cohorts that are semantically related to your brand and industry",
    name: "Direct",
  },
  merged: {
    background: "#EEF6F6",
    textColor: "#55A8A0",
    borderColor: "#BBDCD9",
    tooltipText: "2 cohorts are merged into a single Cohort",
    name: "Merged",
  },
  Insights_Direct: {
    background: "#FEF3EA",
    textColor: "#F8862D",
    borderColor: "#FDDBC0",
    tooltipText:
      "Cohorts that shows good overlap with the selected Custom Audience and are semantically related to your brand and industry",
    name: "Insights Direct",
  },
  Insights_Lateral: {
    background: "#F6EEFE",
    textColor: "#A754F0",
    borderColor: "#DCBBF9",
    tooltipText:
      "Cohorts that shows good overlap with the selected Custom Audience and are not semantically related to your brand and industry",
    name: "Insights Lateral",
  },
  direct: {
    background: "#EDF6FE",
    textColor: "#4CA8F5",
    borderColor: "#B7DCFB",
    tooltipText: "Direct cohorts are cohorts that are semantically related to your brand and industry",
    name: "Direct",
  },
  lateral: {
    background: "#FEF3EA",
    textColor: "#F8862D",
    borderColor: "#FDDBC0",
    tooltipText:
      "This is a new type of Cohort which are relevant to brand and high performing but could not be classified under Direct Type because of low semantic mapping ",
    name: "Lateral",
  },
  insights: {
    background: "#F6EEFE",
    textColor: "#A754F0",
    borderColor: "#DCBBF9",
    tooltipText:
      "This is a new type of Cohort which are generated using Audience Insights ",
    name: "Insights Driven",
  },
};


export const cohortTypes = {
  Direct: {
    uiName: "Direct",
    color: "#B7DCFB",
  },
  Insights_Direct: {
    uiName: "Insights Direct",
    color: "#F8862D",
  },
  Insights_Lateral: {
    uiName: "Insights Lateral",
    color: "#A754F0",
  },
  merged: {
    uiName: "Merged",
    color: "#55A8A0",
  },
};