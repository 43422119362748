import { Button } from "@mui/material";
import React from "react";
import CustomizedDialogs from "../../../shared/Modal/CustomizedDialogs";
import { ReactComponent as CloseSvg } from "../../../../assets/svg/CloseSvgNew.svg";

import {
  StyledVariantItemPara,
  StyledDetailedTargetingContent,
  StyleViewMoreHead,
  StyleViewMoreTitle,
  StyledViewModalSubHead,
  StyledVariantItemParaViewMore,
  StyledCloseIconContainer,
} from "./styled";
import { getComaSeparatedDate } from "../utils/common.utils";

const ViewMoreModal: React.FC<{
  show: boolean;
  onHide: () => void;
  selectedAdSet?: any;
}> = ({ show, onHide, selectedAdSet }) => {
  return (
    <CustomizedDialogs
      show={show}
      handleClose={() => onHide()}
      type="ConfirmDialog"
      footer={false}
      title="Detailed Targeting"
      titleSize={20}
      fullScreen={false}
      maxWidth="sm"
      buttons={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button variant="contained" onClick={() => alert("")}>
            Contained
          </Button>
          <Button>Agree</Button>
        </div>
      }
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <StyleViewMoreHead>
          <StyleViewMoreTitle className="mb-0">
            Detailed Targeting
          </StyleViewMoreTitle>
          <StyledCloseIconContainer onClick={() => onHide()}>
            <CloseSvg />
          </StyledCloseIconContainer>
        </StyleViewMoreHead>
        <StyledDetailedTargetingContent>
          <StyledViewModalSubHead>Interests</StyledViewModalSubHead>
          <StyledVariantItemParaViewMore>
            {selectedAdSet?.facebookData?.targeting?.flexible_spec
              ? selectedAdSet?.facebookData?.targeting?.flexible_spec[0]
                  ?.interests
                ? getComaSeparatedDate(
                    selectedAdSet?.facebookData?.targeting?.flexible_spec[0]
                      ?.interests
                  )
                : "-"
              : "-"}
          </StyledVariantItemParaViewMore>
        </StyledDetailedTargetingContent>
        <StyledDetailedTargetingContent>
          <StyledViewModalSubHead>Behaviour</StyledViewModalSubHead>
          <StyledVariantItemParaViewMore>
            {selectedAdSet?.facebookData?.targeting?.flexible_spec
              ? selectedAdSet?.facebookData?.targeting?.flexible_spec[0]
                  ?.behaviors
                ? getComaSeparatedDate(
                    selectedAdSet?.facebookData?.targeting?.flexible_spec[0]
                      ?.behaviors
                  )
                : "-"
              : "-"}
          </StyledVariantItemParaViewMore>
        </StyledDetailedTargetingContent>
      </div>
    </CustomizedDialogs>
  );
};

export default ViewMoreModal;
