import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import GreyLoader from "../assets/gif/GreyLoader.gif";
import { ReactComponent as GoodIcon } from "../assets/goodIcon.svg";
import { ReactComponent as EscalationIcon } from "../assets/escalationIcon.svg";
import { ReactComponent as InlineIcon } from "../assets/inlineIcon.svg";
import { ReactComponent as AlarmingIcon } from "../assets/alarmingIcon.svg";
import { ReactComponent as Fb } from "assets/svg/platforms/icons8-facebook.svg";
import { ReactComponent as Google } from "assets/svg/platforms/icons8-google.svg";
import { ReactComponent as Linkedin } from "assets/svg/platforms/icons8-linkedin.svg";
import { ReactComponent as Tiktok } from "assets/svg/platforms/icons8-tiktok.svg";
import { ReactComponent as Microsoft } from "assets/svg/platforms/icons8-microsoft.svg";
import { ReactComponent as Dv360 } from "assets/svg/platforms/icons8-dv360.svg";

import { AD_ACCOUNT_SUMMARY } from "../constant";
import { getBrandLevelMetrics } from "store/actions/SmbDashboard/adAccount";
import {
  getActualSpendsAndBudgetDeviationColor,
  getDeviationArrow,
} from "../utils";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { getCurrencySymbol, getRoundedValue } from "utils/commonFunctions";
import { Tooltip } from "@mui/material";
const Item = styled(Paper)(() => ({
  backgroundColor: "#fff",
  width: "100%",
  textAlign: "left",
  color: "#464646",
  boxShadow: "none",
  margin: "2px",
  padding: "2px",
  fontSize: "12px",
  fontWeight: "500",
}));

const Loader = () => (
  <Item sx={{ px: 2.5 }}>
    <img
      src={GreyLoader}
      alt="Loading"
      style={{ height: "4rem", width: "auto" }}
    />
  </Item>
);

function AdAccountRow({ brandId, onClick, showOptimizer, rowData }: any) {
  const brandState: any = useSelector((state: any) => state.smb.brand);
  let brandDetails: any;
  if (brandState.data && brandState.data[brandId]) {
    brandDetails = brandState.data[brandId]?.details;
  }
  return (
    <>
      <Box
        key={brandDetails?.id}
        onClick={onClick}
        sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        <Stack style={{ width: "200px" }}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Item sx={{ width: "35%" }}>
              {platformIcon(rowData?.parentPlatformId)}
            </Item>
            <Box sx={{ width: "65%" }}>
              <Item
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#333333",
                  whiteSpace: "nowrap",
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Tooltip
                  title={rowData?.adAccountName || "-"}
                  arrow
                  placement="top"
                >
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {rowData?.adAccountName || "-"}
                  </div>
                </Tooltip>
              </Item>
              <Box sx={{ display: "flex" }}>
                <Item
                  sx={{
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#464646",
                    whiteSpace: "nowrap",
                  }}
                >
                  {rowData?.adAccountId || ""}
                </Item>
              </Box>
              <Box sx={{ display: "inline-block" }}>
                {getAccountStatus("")}
                {/* rowData?.brandStatus */}
              </Box>
            </Box>
          </Box>
        </Stack>
        <Stack
          direction="row"
          divider={
            <Divider
              orientation="vertical"
              flexItem
              style={{ height: "50px", alignSelf: "center" }}
            />
          }
          spacing={1}
          style={{
            background: "#FFFFFF",
            padding: "10px",
            alignItems: "center",
            overflowX: "auto",
            width: "100%",
          }}
          className="scrollbar-hide"
        >
          {AD_ACCOUNT_SUMMARY.map((item) => {
            return (
              <Stack sx={{ minWidth: "11.3%", maxWidth: "11.3%" }}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box display="flex">
                      <Item
                        sx={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#333333",
                        }}
                      >
                        {item.currencySymbol &&
                          (rowData[item.key][item.type] ||
                            rowData[item.key][item.type] === 0) &&
                          getCurrencySymbol(rowData.currency || "INR")}
                        {rowData[item.key][item.type] ||
                        rowData[item.key][item.type] === 0
                          ? rowData[item.key][item.type]?.toFixed(2)
                          : "-"}
                      </Item>
                      {(item.type === "actual" ||
                        item.type === "actualCost") && (
                        <Stack
                          sx={{
                            // minWidth: "6vw",
                            // maxWidth: "6vw",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {/* <DaviationItem>
                          {brandMetrics?.roas?.actual
                            ? getRoundedValue(brandMetrics?.roas?.actual)
                            : "0.00"}
                        </DaviationItem> */}
                            {rowData[item.key].deviation ? (
                              <>
                                {getDeviationArrow(
                                  rowData[item.key].deviation
                                ) === "up" ? (
                                  <ArrowUpwardIcon
                                    sx={{
                                      fontSize: "1rem",
                                      color:
                                        getActualSpendsAndBudgetDeviationColor(
                                          rowData[item.key].deviation || 0
                                        ),
                                    }}
                                  />
                                ) : (
                                  <ArrowDownwardIcon
                                    sx={{
                                      fontSize: "1rem",
                                      color:
                                        getActualSpendsAndBudgetDeviationColor(
                                          rowData[item.key].deviation || 0
                                        ),
                                    }}
                                  />
                                )}
                                <Typography
                                  fontSize="0.625rem"
                                  sx={{
                                    color:
                                      getActualSpendsAndBudgetDeviationColor(
                                        rowData[item.key].deviation || 0 // ADD Deviation key here
                                      ),
                                  }}
                                >
                                  {/* ADD Deviation key here*/}
                                  {getRoundedValue(
                                    rowData[item.key].deviation || 0
                                  )}
                                  %
                                </Typography>
                              </>
                            ) : null}
                          </Box>
                        </Stack>
                      )}
                    </Box>
                    <Item
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#464646",
                        width: "95px",
                      }}
                    >
                      {item.key === "metricPayload"
                        ? item?.name + (rowData?.[item.key]?.name || "")
                        : item?.name || ""}
                    </Item>
                  </Box>
                </Box>
              </Stack>
            );
          })}
        </Stack>
      </Box>
    </>
  );
}

const getAccountStatus = (status: string) => {
  const getBackground = (status: any) => {
    switch (status?.toLowerCase()) {
      case "good":
        return "#D8E0FF";
      case "inline":
        return "#DAFFD4";
      case "alarming":
        return "#FFEBB6";
      case "escalation":
        return "#FCCFD2";
      default:
        return "#EAEAEA";
    }
  };

  const getColor = (status: any) => {
    switch (status?.toLowerCase()) {
      case "good":
        return "#002CDC";
      case "inline":
        return "#006C04";
      case "alarming":
        return "#000000";
      case "escalation":
        return "#006C04";
      default:
        return "#000000";
    }
  };

  const getIcon = (status: any) => {
    const iconStyle = { height: "12px", width: "12px" };
    switch (status?.toLowerCase()) {
      case "good":
        return <GoodIcon style={iconStyle} />;
      case "inline":
        return <InlineIcon style={iconStyle} />;
      case "alarming":
        return <AlarmingIcon style={iconStyle} />;
      case "escalation":
        return <EscalationIcon style={iconStyle} />;
      default:
        return <>-</>;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "6px",
        padding: "4.5px 6px",
        background: getBackground(status),
        borderRadius: "3px",
        alignItems: "center",
      }}
    >
      {getIcon(status)}
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: "400",
          color: getColor(status),
        }}
      >
        {status || ""}
      </Typography>
    </Box>
  );
};

const platformIcon=(platform:string)=>{
  switch(platform){
    case "aa59a6e2-843b-4633-5621-6fa93ce6c001":
      return <Fb style={{height:"45px",width:"45px"}}/>
    case "aa59a6e2-843b-4633-5621-6fa93ce6c002":
      return <Google style={{height:"45px",width:"45px"}}/>
    case "aa59a6e2-843b-4633-5621-6fa93ce6c003":
      return <Linkedin style={{height:"45px",width:"45px"}}/>
    case "aa59a6e2-843b-4633-5621-6fa93ce6c006":
        return <Tiktok style={{height:"45px",width:"45px"}}/>
    case "aa59a6e2-843b-4633-5621-6fa93ce6c004":
        return <Microsoft style={{height:"45px",width:"45px"}}/>
    case "aa59a6e2-843b-4633-5621-6fa93ce6c005":
        return <Dv360 style={{height:"45px",width:"45px"}}/>
  }  
}

export default AdAccountRow;
