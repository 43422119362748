import { VariationType } from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";
import { ModalLayerItemType } from "../mediaStudio.types";
const loremText =
  "Est Lorem qui non aliqua minim irure laboris ipsum sit culpa elit ullamco tempor aute. Culpa dolore non eu tempor minim deserunt exercitation adipisicing esse. Pariatur culpa mollit consectetur nisi et sit mollit elit ad consectetur. Enim esse ex labore esse.";

export const layersVariationMapping = (
  layers: ModalLayerItemType[]
): VariationType[] => {
  return layers.map((item) => ({
    layer_name: item.name,
    text: loremText.slice(0, Number(item.maxCharacters)),
    variation_type: "text",
  }));
};
