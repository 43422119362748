import { Box, Button, Typography } from "@mui/material";
import HandleClickOutside from "components/common/HandleClickOutside";
import Loader from "components/common/Loader";
import NoData from "components/common/NoData";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountsStats, setTokenStats } from "store/actions/accountsManager";
import { AccountsManagerState } from "store/types/accountsManager";
import { AdAccountState } from "store/types/adaccount";
import {
  getLocalStorageData,
  getRolesHierarchy,
  isViewerRole,
} from "utils/commonFunctions";
import { tms_skip_brand_name } from "utils/constants";
import { SocialLogin } from "../SocialLogin";
import { supportedPlatforms, useSupportedPlatforms } from "../hooks";
import { getWarningMessage, handleSort } from "../utils";
import GoogleSheetsModal from "./ThirdPartyPlatforms/GoogleSheets/GoogleSheetsModal";
import ThirdParty from "./ThirdPartyPlatforms/ThirdParty";
import { ReactComponent as Down } from "./ThirdPartyPlatforms/assets/Down.svg";
import { ReactComponent as GoogleAnalyticsIcon } from "./ThirdPartyPlatforms/assets/GoogleAnalyticsIcon.svg";
import { ReactComponent as GoogleSheetsIcon } from "./ThirdPartyPlatforms/assets/GoogleSheetsIcon.svg";
import "./style.scss";
import TableRow from "./tableRow";
import { RolesWrapper } from "utils/rolesWrapper";
import { useSearchParams } from "react-router-dom-v5-compat";

function normalizeData(data: any) {
  data.map((item: any) => {
    item.tokenWarnings = getWarningMessage(item.tokens);
    item.availableTokens = item.tokenWarnings.availableTokens;
  });
  return data;
}

function useAccountsManager() {
  const dispatch = useDispatch();
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand: { id: brand_id = "", name: brandName = "" } = {} } =
    adaccount;
  const href = useSupportedPlatforms({
    brandName,
    brand_id,
  });
  const [accountDetails, setAccountDetails] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sortOrder, setSortOrder] = React.useState<boolean>(false);
  const [counter, setCounter] = React.useState<Number>(0);
  const [isDeleteLoaderId, setIsDeleteLoaderId] = React.useState<string>("");
  const [showAccounts, setShowAccounts] = React.useState<boolean>(false);
  const wrapperModalRef = React.useRef(null);

  const accountsManager = useSelector(
    (state: { accountsManager: AccountsManagerState }) => state.accountsManager
  );
  const { tokenStats = [] } = accountsManager;

  HandleClickOutside(wrapperModalRef, [setShowAccounts]);

  const _handleSort = (key: string) => {
    const _sortedList = handleSort(accountDetails, key, sortOrder);
    setSortOrder((value) => !value);
    setAccountDetails(normalizeData(_sortedList));
  };

  useEffect(() => {
    if (tokenStats.length) {
      setAccountDetails(normalizeData(tokenStats));
    }
  }, [tokenStats]);

  useEffect(() => {
    if (brand_id && counter > 0) {
      setLoading(true);
      dispatch(
        getAccountsStats({ brandId: brand_id }, (response: any, error: any) => {
          setLoading(false);
          if (
            !error &&
            response.data &&
            response.data.adAccounts &&
            response.data.adAccounts.length > 0
          ) {
            console.log("line 81 hide delete loader");
            setIsDeleteLoaderId("");
            dispatch(setTokenStats(response.data.adAccounts));
          } else {
            dispatch(setTokenStats([]));
          }
        })
      );
    }
  }, [counter, brand_id]);

  return {
    showModal: accountsManager.isAppDisabled,
    accountDetails,
    loading,
    _handleSort,
    setCounter,
    showAccounts,
    setShowAccounts,
    wrapperModalRef,
    href,
    brandName,
    isDeleteLoaderId,
    setIsDeleteLoaderId,
  };
}

function useTMSFlag() {
  const socialLoginFlag = getLocalStorageData(tms_skip_brand_name);
  return { socialLoginFlag };
}

const AccountsManager: React.FC = (): JSX.Element => {
  const {
    showModal,
    accountDetails,
    loading,
    _handleSort,
    setCounter,
    showAccounts,
    setShowAccounts,
    wrapperModalRef,
    href,
    brandName,
    isDeleteLoaderId,
    setIsDeleteLoaderId,
  } = useAccountsManager();

  const { socialLoginFlag } = useTMSFlag();
  const [showAccountsDropdown, setShowAccountsDropdown] = React.useState(false);
  const [showGoogleSheetsModal, setShowGoogleSheetsModal] =
    React.useState<any>(false);
  const [rows, updateRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  HandleClickOutside(wrapperModalRef, [setShowAccountsDropdown]);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;
  const styles = {
    tableDataFonts: {
      fontSize: "12px",
      fontWeight: "400",
      fontFamily: "Inter",
      color: "#111827",
    },
    tableHeaderFonts: {
      fontSize: "12px",
      fontWeight: "700",
      fontFamily: "Inter",
      color: "#111827",
    },
  };
  const betaStyles={height:'91vh', margin:'0px' }
  return (
    <>
      <div className="accounts-manager-box" style={{ marginBottom: isViewedFromBeta?'':"30px", ...(isViewedFromBeta && betaStyles)}}>
        <div
          style={{
            backgroundColor: "#fff",
            width: "100%",
            height: isViewedFromBeta ? '51%' : "50%",
            marginBottom: "6px",
            borderRadius: "8px",
          }}
        >
          <div className="accounts-manager-header">
            <div
              style={{
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Inter",
              }}
            >
              Your linked accounts
            </div>
            <div style={{ position: "relative" }}>
              <Button
                onClick={() => setShowAccounts(!showAccounts)}
                endIcon={<Down />}
                sx={{
                  height: "40px",
                  width: "130px",
                  background: "#0869FB",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "8px",
                  fontWeight: "600",
                  fontFamily: "Inter",
                  textTransform: "unset",
                  "&:hover": {
                    background: "#0869FB",
                  },
                }}
              >
                Add Account
              </Button>
              {showAccounts && (
                <div className="ad-accounts" ref={wrapperModalRef}>
                  {supportedPlatforms.map(
                    (platform) =>
                      platform.key !== "google analytics" && (
                        <PlatformItem platform={platform} href={href} isViewedFromBeta={isViewedFromBeta} />
                      )
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="adaccount-stats-table">
            <div className="table-row table-head">
              <div style={styles.tableHeaderFonts}>Account &ensp;</div>
              <div style={styles.tableHeaderFonts}>Account Id</div>
              <div style={styles.tableHeaderFonts}>Channel &ensp;</div>
              <div style={styles.tableHeaderFonts}>Active Tokens</div>
              <div style={styles.tableHeaderFonts}>Reconnect</div>
            </div>
            {accountDetails?.length === 0 ? (
              loading ? (
                <div className="loader">
                  <Loader />
                </div>
              ) : (
                <NoData />
              )
            ) : (
              accountDetails?.map((account: any) => {
                const platform:
                  | "google"
                  | "facebook"
                  | "linkedin"
                  | "google analytics" = account.parentPlatformName || "";
                return (
                  <TableRow
                    key={account.adAccountId}
                    data={account}
                    setCounter={setCounter}
                    setIsDeleteLoaderId={setIsDeleteLoaderId}
                    isDeleteLoaderId={isDeleteLoaderId}
                    href={href[platform]}
                  />
                );
              })
            )}
          </div>
          {socialLoginFlag && brandName
            ? socialLoginFlag.name !== brandName &&
              showModal && (
                <div
                  className="account-manager-modal"
                  style={{ left: isViewedFromBeta ? "0" : "5%" }}
                >
                  <SocialLogin href={href} brandName={brandName} />
                </div>
              )
            : brandName &&
              showModal && (
                <div
                  className="account-manager-modal"
                  style={{ left: isViewedFromBeta ? "0" : "5%" }}
                >
                  <SocialLogin href={href} brandName={brandName} />
                </div>
              )}
        </div>
        <Box
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            height: isViewedFromBeta ? '52%' : "50%",
            marginTop: "6px",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              background: "#F8F9FE",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px 8px 0 0",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "56px",
                background: "#F8F9FE",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0 24px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "Inter",
                }}
              >
                3rd Party Attribution Platforms
              </Typography>
              <Box sx={{ position: "relative" }}>
                <Button
                  onClick={() => setShowAccountsDropdown(!showAccountsDropdown)}
                  endIcon={<Down />}
                  sx={{
                    height: "40px",
                    width: "130px",
                    background: "#0869FB",
                    color: "white",
                    fontSize: "12px",
                    borderRadius: "8px",
                    fontWeight: "600",
                    fontFamily: "Inter",
                    textTransform: "unset",
                    "&:hover": {
                      background: "#0869FB",
                    },
                  }}
                >
                  Add Account
                </Button>
                {showAccountsDropdown && (
                  <>
                    <Box
                      ref={wrapperModalRef}
                      sx={{
                        position: "absolute",
                        top: "48px",
                        width: "150px",
                        padding: "10px",
                        background: "white",
                        border: "1px solid #ECECEC",
                        boxShadow: "0px 0px 10.5651px rgba(0, 0, 0, 0.04)",
                        borderRadius: "7px",
                        zIndex: "10",
                      }}
                    >
                      <RolesWrapper
                        show={true}
                        // disabled={isViewerRole(brandRoles)}
                        disabled={getRolesHierarchy(brandRoles) === "viewer"}
                      >
                        <a
                          style={{ textDecoration: "none" }}
                          href={href["google analytics"]}
                          target={isViewedFromBeta?'_parent':''}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              cursor: "pointer",
                              marginBottom: "10px",
                            }}
                          >
                            <GoogleAnalyticsIcon />
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                color: "black",
                              }}
                            >
                              Google Analytics
                            </Typography>
                          </Box>
                        </a>
                      </RolesWrapper>
                      <RolesWrapper
                        show={true}
                        // disabled={isViewerRole(brandRoles)}
                        disabled={getRolesHierarchy(brandRoles) === "viewer"}
                      >
                        <Box
                          onClick={() => {
                            setShowAccountsDropdown(false);
                            setShowGoogleSheetsModal(true);
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              cursor: "pointer",
                            }}
                          >
                            <GoogleSheetsIcon />
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                color: "black",
                              }}
                            >
                              Google Sheets
                            </Typography>
                          </Box>
                        </Box>
                      </RolesWrapper>
                    </Box>
                  </>
                )}
                {showGoogleSheetsModal && (
                  <GoogleSheetsModal
                    title="Add Google Sheets"
                    subtitle="Add data from your attribution platforms here. Use our standard sheet template"
                    closeModal={() => {
                      setShowGoogleSheetsModal(false);
                    }}
                    openedAs="add"
                    updateRows={updateRows}
                    setIsLoading={setIsLoading}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <div
            style={{
              width: "100%",
              maxHeight: "82%",
              overflow: "scroll",
            }}
          >
            <ThirdParty
              href={href}
              updateRows={updateRows}
              rows={rows}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </div>
        </Box>
      </div>
      {/* <PrivacyPolicy /> */}
    </>
  );
};

const PlatformItem: React.FC<{ platform: any; href: any; isViewedFromBeta: string | null }> = ({
  platform,
  href,
  isViewedFromBeta
}): JSX.Element => {
  return (
    <a href={href[platform.key]} target={isViewedFromBeta?'_parent':''}>
      <div className="ad-accounts-icon">{platform.logo}</div>
      <span style={{ color: "black" }}>
        {platform.title === "Facebook" ? "Meta" : platform.title} Ads
      </span>
    </a>
  );
};

export default AccountsManager;
