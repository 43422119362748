import { SetError, SET_ERROR } from "../../types/SmbDashboard/error";
import {
  BrandState,
  SetAllBrandDetails,
  SetAllBrandTypes,
  SetBrandMetrics,
  SET_ALL_BRAND_DETAILS,
  SET_BRAND_METRICS,
  SET_BRAND_TYPE,
  SET_ALL_BRAND_TYPES,
  SetBrandType,
  RESET_BRAND_METRICS,
  SET_TOTAL_BRANDS_LOADING,
  RESET_BRAND_DETAILS,
} from "../../types/SmbDashboard/brand";

const initialBrandState: BrandState = {
  data: null,
  isLoading: true,
  types: {
    good: { brandIds: [], isLoading: true },
    alarming: { brandIds: [], isLoading: true },
    escalation: { brandIds: [], isLoading: true },
    inline: { brandIds: [], isLoading: true },
  },
  error: null,
};

const actions: any = {
  [SET_ALL_BRAND_DETAILS]: (state: any, payload: any) => ({
    ...state,
    data: payload,
    isLoading: false,
  }),

  [SET_BRAND_METRICS]: (state: any, payload: any) => ({
    ...state,
    data: {
      ...state.data,
      [payload.brandId]: {
        ...(state.data && state.data[payload.brandId]
          ? state.data[payload.brandId]
          : {}),
        metrics: payload.metrics,
        type: payload.type,
      },
    },
  }),

  [SET_BRAND_TYPE]: (state: any, payload: any) => ({
    ...state,
    types: {
      ...state.types,
      ...(payload.brandStatus && payload.brandStatus !== ""
        ? {
            [payload.brandStatus]: {
              brandIds: Array.from(
                new Set([
                  ...state.types[payload.brandStatus].brandIds,
                  payload.brandId,
                ])
              ),
              isLoading: false,
            },
          }
        : {}),
    },
  }),

  [SET_ALL_BRAND_TYPES]: (state: any, payload: any) => ({
    ...state,
    types: payload,
  }),

  [SET_TOTAL_BRANDS_LOADING]: (state: any, payload: boolean) => ({
    ...state,
    isLoading: payload,
  }),

  [RESET_BRAND_DETAILS]: (state: any) => ({
    ...initialBrandState,
  }),

  [RESET_BRAND_METRICS]: (state: any, payload: any) => ({
    ...state,
    types: { ...initialBrandState.types },
    data: {
      ...state.data,
      [payload.brandId]: {
        ...state.data[payload.brandId],
        metrics: { isLoading: true },
        type: null,
      },
    },
  }),

  [SET_ERROR]: (state: any, payload: any) => ({
    ...state,
    error: payload,
  }),
};

export default function brandReducer(
  state = initialBrandState,
  action:
    | SetAllBrandDetails
    | SetBrandMetrics
    | SetBrandType
    | SetAllBrandTypes
    | SetError
) {
  if (actions[action.type]) return actions[action.type](state, action.payload);
  return state;
}
