import { Button } from "@mui/material";
import { ReactComponent as Fb } from "assets/svg/platforms/icons8-facebook.svg";
import { ReactComponent as Google } from "assets/svg/platforms/icons8-google.svg";
import { ReactComponent as Linkedin } from "assets/svg/platforms/icons8-linkedin.svg";
import { ReactComponent as Tiktok } from "assets/svg/platforms/icons8-tiktok.svg";
import { ReactComponent as Microsoft } from "assets/svg/platforms/icons8-microsoft.svg";
import { ReactComponent as Dv360 } from "assets/svg/platforms/icons8-dv360.svg";
import { ReactComponent as Pinterest } from "assets/svg/pinterest-logo.svg";
import { useSearchParams } from "react-router-dom-v5-compat";

const BLOCKED_MESSAGE: Record<string, string> = {
  "microsoft advertising":
    "Direct authentication with Microsoft Advertising is comming soon.",
};

function Reconnect({
  count,
  isBlocked,
  href,
  platform,
}: {
  count: number;
  isBlocked: boolean;
  href: string;
  platform: string;
}): JSX.Element {

  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");

  const platformName = (platform: string) => {
    switch (platform) {
      case "facebook":
        return "Meta";
      case "google":
        return "Google";
      case "linkedin":
        return "Linkedin";
      case "tiktok":
        return "Tiktok";
      case "microsoft advertising":
        return "Microsoft";
      case "display and video 360":
        return "DV360";
      case "pinterest ads":
        return "Pinterest";
      default:
        return "";
    }
  };
  const platformIcon = (platform: string) => {
    switch (platform) {
      case "facebook":
        return <Fb style={{ height: "18px", width: "18px" }} />;
      case "google":
        return <Google style={{ height: "18px", width: "18px" }} />;
      case "linkedin":
        return <Linkedin style={{ height: "18px", width: "18px" }} />;
      case "tiktok":
        return <Tiktok style={{ height: "18px", width: "18px" }} />;
      case "microsoft advertising":
        return <Microsoft style={{ height: "18px", width: "18px" }} />;
      case "display and video 360":
        return <Dv360 style={{ height: "18px", width: "18px" }} />;
      case "pinterest ads":
        return <Pinterest style={{ height: "18px", width: "18px" }} />;
    }
  };
  return (
    <a href={href} style={{ textDecoration: "none" }} target={isViewedFromBeta?'_parent':''}>
      <Button
        startIcon={platformIcon(platform)}
        sx={{
          padding: "8px 12px",
          background: "#ffffff",
          borderRadius: "4px",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
          textTransform: "none",
          fontSize: "10px",
          fontWeight: "600",
          fontFamily: "Inter",
          color: "#757575",
        }}
      >
        {`${count > 0 ? "Reconnect" : "Connect"} with ${platformName(
          platform
        )}`}
      </Button>
    </a>
  );
}

export default Reconnect;
