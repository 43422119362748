import React from "react";
import { useFieldArray } from "react-hook-form";
import { Descriptions as Container } from "./styles.ConfigureAd";
import Input from "./Input";
import GenerationType from "./GenerationType";

type Props = {
  register: any;
  control: any;
  errors: any;
  watch: any;
};

function Descriptions({ register, control, errors, watch }: Props) {
  const { fields, append } = useFieldArray({
    control,
    name: "reference_description",
  });

  const values = watch("generation_type.descriptions");

  return (
    <Container>
      <div>Reference Description</div>
      {fields.map((field, index) => (
        <Input
          key={field.id}
          name={`reference_description.${index}.value`}
          register={register}
          placeholder="Enter reference Description"
          type="textarea"
          error={
            errors?.reference_description &&
            errors?.reference_description.length &&
            errors.reference_description[index]
          }
        />
      ))}
      <GenerationType
        dataList={[
          {
            key: "generic",
            label: "Generic",
          },
          {
            key: "interest",
            label: "Interest Based",
          },
          {
            key: "usp",
            label: "Unique Selling Point Specific",
            placeholder: "Enter USP",
          },
          {
            key: "benefit",
            label: "Benefit Based",
            placeholder: "Enter Benefit",
          },
        ]}
        name="generation_type.descriptions"
        register={register}
        type="descriptions"
        errors={errors}
        values={values}
      />
    </Container>
  );
}

export default Descriptions;
