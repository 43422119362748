import MenuItem from "@mui/material/MenuItem";
import { RadioCheckSelectItem } from "../RadioCheckSelect/RadioCheckSelect";
import { StyledPxSelect } from "./styled";
import TagWithTitle from "../TagWithTitle/TagWithTitle";
import { ReactComponent as DropdownArrow } from "../../../../assets/cai/svg/drop_down_arrow.svg";
import { ReactComponent as Search } from "../../../../assets/cai/svg/search.svg";
import { CheckBox, TextInput } from "components/shared";
import { Box } from "@mui/system";
import { ClickAwayListener, Radio, SelectProps } from "@mui/material";
import { ReactNode } from "react";
import { StyledTextProps } from "components/shared/";
import { LooseObject } from "types/GlobalTypes";
import LoadingSelect from "./LoadingSelect";
import { debounce } from "lodash";

export interface PxSelectProps extends SelectProps {
  options: LooseObject;
  onSearch?: (text?: string) => void;
  dropDownContainerWidth?: string;
  heading?: string;
  onFilterClose?: () => void;
  paperWidth?: string;
  dropDownContainerHeight?: string;
  selection: any;
  handlePropChange: (value: any, allSelectClicked?: boolean) => void;
  hideSubtitle?: boolean;
  renderComponent?: (value: unknown) => ReactNode;
  titleSXProps?: StyledTextProps;
  subtitleSXProps?: StyledTextProps;
  trailingComponent?: (trailingValue: any) => ReactNode;
  hideLeading?: boolean;
  hideTooltip?: boolean;
  titleKey?: string;
  subTitleKey?: string;
  paperMaxHeight?: string;
  selectLoading?: boolean;
  trailingKey?: string;
  showTop?: boolean;
}

export function PxSelect({
  options,
  onSearch,
  multiple,
  heading,
  onFilterClose,
  paperWidth,
  selection,
  handlePropChange,
  hideSubtitle,
  renderComponent,
  titleSXProps,
  subtitleSXProps,
  trailingComponent,
  hideLeading,
  hideTooltip,
  titleKey = "desc",
  subTitleKey = "value",
  paperMaxHeight,
  selectLoading,
  showTop,
  trailingKey,
  ...props
}: PxSelectProps) {
  const handleChange = (item: any, selected: boolean) => {
    if (multiple) {
      if (selected) {
        if (selection.length > 1) {
          handlePropChange(
            selection.filter(
              (item2: LooseObject) => item2[subTitleKey] !== item[subTitleKey]
            )
          );
        }
      } else {
        const newSelection = [...selection, item];
        handlePropChange(newSelection);
      }
    } else {
      handlePropChange(item);
      // ClickAwayListener();
    }
  };

  const renderDefaultComponent = (value: any) => {
    let finalText = "";

    if (multiple) {
      if (options.length === selection.length) {
        finalText = " All Selected";
      } else {
        finalText = selection[0]?.[titleKey] ?? selection[0]?.[subTitleKey];
        if (selection.length > 1) {
          finalText = finalText + ` (+${selection.length - 1})`;
        }
      }
    } else {
      finalText = selection?.[titleKey] ?? selection?.[subTitleKey];
    }
    return (
      <TagWithTitle text={finalText} title={heading} onClose={onFilterClose} />
    );
  };

  return (
    <StyledPxSelect
      value={
        multiple
          ? selection.map(
              (item: any) =>
                item?.[subTitleKey]?.toString() ?? item?.[titleKey]?.toString()
            )
          : selection?.[subTitleKey]?.toString() ??
            selection?.[titleKey]?.toString()
      }
      renderValue={(value: any) => {
        if (!value || (value && value.length === 0)) {
          return (
            <em
              style={{
                fontSize: "0.725em",
                textAlign: "center",
                width: "100%",
              }}
            >
              No selection
            </em>
          );
        }
        return renderComponent
          ? renderComponent(value)
          : renderDefaultComponent(value);
      }}
      disableUnderline
      variant="standard"
      // IconComponent={() => {
      //   return (
      //     <Box mr=".5em">
      //       <DropdownArrow />
      //     </Box>
      //   );
      // }}
      MenuProps={{
        PaperProps: {
          sx: {
            width: paperWidth ?? "12.125em",
            fontSize: "1em",
            marginTop: "0.625em",
            borderRadius: "6px",
            maxHeight: paperMaxHeight ?? "17.5em",
          },
        },
        variant: showTop ? "menu" : undefined,
        MenuListProps: {
          sx: {
            padding: "0em",
          },
        },
      }}
      displayEmpty
      multiple={multiple}
      {...props}
    >
      {onSearch && (
        <Box
          sx={{
            position: "sticky",
            paddingTop: "0.4em",
            paddingBottom: ".2em",
            top: "0em",
            zIndex: 999,
            width: "100%",
            background: "white",
          }}
        >
          <TextInput
            isBorder
            borderColor="#DDDDDD"
            backgroundColor="#FFFFFF"
            height="1.5em"
            disableUnderline
            placeholder="Search"
            autoFocus
            fullWidth
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
            startAdornment={
              <Box ml="-.3em" mr=".8em" mt="-.2em">
                <Search />
              </Box>
            }
            sx={{
              margin: "0.5em 2em .8em 2.5em",
              fontSize: "0.625em",
              width: "83%",
            }}
            onChange={(e) => {
              debounce(() => onSearch(e.target.value), 500)();
            }}
          />
        </Box>
      )}

      {multiple && (
        <MenuItem
          key="all"
          selected={selection.length === options.length}
          onClick={() => {
            handlePropChange(options, selection.length === options.length);
          }}
        >
          <RadioCheckSelectItem
            title="All Selected"
            leading={<CheckBox checked={selection.length === options.length} />}
            titleSXProps={titleSXProps}
            subtitleSXProps={subtitleSXProps}
            hideTooltip={hideTooltip}
          />
        </MenuItem>
      )}

      {selectLoading ? (
        <LoadingSelect />
      ) : (
        options.map((item: LooseObject, index: number) => {
          let leading;
          const title = item[titleKey];
          const subTitle = item[subTitleKey];
          let selected = multiple
            ? selection?.find(
                (item2: LooseObject) => item2[subTitleKey] === item[subTitleKey]
              )
            : item[subTitleKey] === selection[subTitleKey];
          if (!hideLeading) {
            leading = multiple ? (
              <CheckBox checked={selected ? true : false} />
            ) : (
              <Radio
                size="small"
                // eslint-disable-next-line eqeqeq
                checked={selected}
                sx={{
                  color: "#A1A1A1",
                  "&.Mui-checked": {
                    color: "#0869FB",
                  },
                }}
              />
            );
          }
          return (
            <MenuItem
              key={item[subTitleKey]}
              selected={selected}
              onClick={() => {
                handleChange(item, selected);
              }}
              value={
                item?.[subTitleKey]?.toString() ?? item?.[titleKey]?.toString()
              }
            >
              <RadioCheckSelectItem
                title={title ?? subTitle}
                subtitle={hideSubtitle ? undefined : subTitle}
                leading={leading}
                trailing={
                  trailingComponent &&
                  trailingKey &&
                  trailingComponent(item?.[trailingKey])
                }
                titleSXProps={titleSXProps}
                subtitleSXProps={subtitleSXProps}
                hideTooltip={hideTooltip}
              />
            </MenuItem>
          );
        })
      )}
    </StyledPxSelect>
  );
}
