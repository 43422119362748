import styled from "styled-components";

export const StyledReferenceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 24px;
`;

export const StyledReferenceTitle = styled.p`
  padding-top: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
`;

export const StyledReferenceSubtitle = styled.p`
  padding-top: 8px;
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #494949;
`;

export const StyledSubmitTextContainer = styled.div`
  display: flex;
  margin-bottom: 0px;
`;
