import { Box, Dialog, FormControlLabel, IconButton, Radio, RadioGroup, makeStyles, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { ReactComponent as IconCsv } from "./assets/csv-file.svg";
import { ReactComponent as IconBulkActions } from "./assets/single-bulk-actions.svg";
import { ReactComponent as IconClose } from "./assets/close.svg";
import { ReactComponent as IconUncheck } from "./assets/uncheck.svg";
import { ReactComponent as IconCheck } from "./assets/check.svg";
import {
  getRolesHierarchy,
} from "../../utils/commonFunctions";
import { AdAccountState } from "../../store/types/adaccount";

// styled components
const StyledHeaderText = styled("h1")({
  color: "var(--color-light-theme-content-primary, #000)",
  fontFamily: "Inter",
  fontSize: "1.25em",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "2em",
  letterSpacing: "-0.0187em",
  margin: 0,
});

const StyledDescription = styled("p")({
  color: "var(--color-light-theme-content-secondary, #5C6674)",
  fontFamily: "Inter",
  fontSize: ".875em",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1.375em",
  letterSpacing: ".0088em",
  margin: 0,
  textAlign: "left",
  "& b": {
    fontWeight: 600,
    lineHeight: "1.375em",
    letterSpacing: ".0109em",
    color: "var(--color-light-theme-content-secondary, #5C6674)"
  },
});

const StyledCard = styled("button")({
  display: "flex",
  padding: "1.25em",
  flexDirection: "column",
  gap: ".5em",
  alignSelf: "stretch",
  borderRadius: ".75em",
  border: ".125em solid var(--color-palette-gray-100, #ECEEF1)",
  background: "var(--color-palette-white, #FFF)",
  boxShadow: "0em .0625em .125em 0em rgba(16, 24, 40, 0.05)",
  "&[data-selected='true']": {
    border: ".125em solid var(--color-light-theme-background-positive-high-priority, #97D9B5)",
  },
});

const StyledIconContainer = styled("div")({
  width: "3em",
  height: "3em",
  flexShrink: 0,
  borderRadius: ".625em",
  border: ".0625em solid var(--color-palette-gray-200, #E0E3E5)",
  background: "var(--color-palette-white, #FFF)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&[data-selected='true']": {
    background: "var(--color-palette-green-50, #EAF7F0)",
  }
});

const StyledCloseIconContainer = styled(IconButton)({
  width: "40px",
  height: "40px",
  padding: "8px",
  borderRadius: "8px",
  background: "var(--color-palette-gray-50, #F6F7F8)",
});

const StyledCardTitle = styled("h2")({
  color: "var(--color-light-theme-content-primary, #000)",
  fontFamily: "Inter",
  fontSize: "1.125em",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "1.5em",
  letterSpacing: "-0.0112em",
});

// types
export enum CardType {
  SINGLE_BULK_ACTIONS = "SINGLE_BULK_ACTIONS",
  CSV_UPLOAD = "CSV_UPLOAD",
  STRATEGIC_CSV_UPLOAD = "STRATEGIC_CSV_UPLOAD",
};

export type CardSelectionProps = {
  onSelect: (value: CardType | null) => void;
  open: boolean;
  onClose: () => void;
  isGoogleVideo: boolean
}

// constants
const REDIRECTION_DELAY_IN_MS = 700;

let CARDS = [{
  title: "Single/Bulk Actions",
  description: (
    <>
      Select the Level and Type of Action to execute on one or more
      assets for the selected <b>Platform</b> and <b>AI Group</b>
    </>
  ),
  key: CardType.SINGLE_BULK_ACTIONS,
  icon: <IconBulkActions />
}, {
  title: "Upload CSV",
  description: (
    <>
      Upload a .CSV file containing all the actions that you want to execute
      on any assets for the selected <b>Platform</b> and <b>Brand</b>
    </>
  ),
  key: CardType.CSV_UPLOAD,
  icon: <IconCsv />
}];

// component
export default function CardSelection({
  onSelect,
  open,
  onClose,
  isGoogleVideo
}: CardSelectionProps) {
  const [selectedCardType, setSelectedCardType] = useState<CardType | null>(null);
  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const {
    brandRoles,
  } = adaccount;
  const _onClose = () => {
    onSelect(null);
    setSelectedCardType(null);
    onClose();
  };

if(isGoogleVideo) {
  if(!CARDS.some(el => el.key === CardType.STRATEGIC_CSV_UPLOAD) && getRolesHierarchy(brandRoles) === "admin") {
    CARDS.push({
      title: "Upload Strategic actions",
      description: (
        <>
          Upload a .CSV file containing all the strategic actions that you want to recommend
          on any assets for the selected <b>Platform</b> and <b>Brand</b>
        </>
      ),
      key: CardType.STRATEGIC_CSV_UPLOAD,
      icon: <IconCsv />
    })
  }
} else {
  CARDS = CARDS.filter(el => el.key !== CardType.STRATEGIC_CSV_UPLOAD)
}


  const onCardClickCurried = (type: CardType) => () => setSelectedCardType(type);

  useEffect(() => {
    if (!selectedCardType) return;
    const timeoutId = setTimeout(() => {
      onSelect(selectedCardType);
    }, REDIRECTION_DELAY_IN_MS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [selectedCardType]);

  return (
    <Dialog
      open={open}
      onClose={_onClose}
      PaperProps={{
        sx: {
          p: 2,
          borderRadius: "12px",
          ...(CARDS.length === 3 && {maxWidth: "730px"})
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          fontSize: "14px",
        }}
      >
        <Box
          component="header"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <StyledHeaderText>Manual Actions</StyledHeaderText>
            <StyledDescription>Choose a card and go to next step</StyledDescription>
          </Box>
          <StyledCloseIconContainer onClick={_onClose} disableRipple disableTouchRipple>
            <IconClose />
          </StyledCloseIconContainer>
        </Box>
        <Box sx={{ display: "flex", gap: "1em" }}>
          {CARDS.map(card => (
            <StyledCard
              data-selected={selectedCardType === card.key} key={card.key}
              onClick={onCardClickCurried(card.key)}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                  marginBottom: "1em"
                }}
              >
                <StyledIconContainer data-selected={selectedCardType === card.key} key={card.key}>
                  {card.icon}
                </StyledIconContainer>
                {selectedCardType === card.key ? <IconCheck /> : <IconUncheck />}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <StyledCardTitle>{card.title}</StyledCardTitle>
                <StyledDescription>{card.description}</StyledDescription>
              </Box>
            </StyledCard>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};