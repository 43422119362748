import { actionChannel } from "redux-saga/effects";
import { mapStoryPayload } from "../../../../../utils/mapFactors";
import { BUNDLE_GENERATE, GetBundleGenerate, SetFakeGenerateLoading, SetPreview, SetRequestId, SET_FAKEGENERATE_LOADING, SET_PREVIEW, SET_REQUEST_ID } from "../../../../types/CreativeAI/Facebook/BundleGeneration/bundleGeneration";

const initialState = {
    requestId:"",
    fakeLoader:false
}

const bundleGenerationReducer = (
    state=initialState,
    action:
    SetRequestId|
    SetPreview|
    SetFakeGenerateLoading
    )=>{
    switch(action.type){
        case SET_REQUEST_ID: {
            return {
                ...state,
                requestId: action.payload?.requestid
            };
        }
        case SET_PREVIEW:{
            return{
                ...state,
                adPreview: action.payload?.adPreviewLink
            }
        }
        case SET_FAKEGENERATE_LOADING:{
            return{
                ...state,
                fakeLoader: action.payload
            }
        }
        default:{
            return state;
        }
    }
}

export default bundleGenerationReducer;