import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Handle, Position } from "react-flow-renderer";
import { Box, Stack, Typography } from "@mui/material";
import { ReactComponent as Edit } from "../../assets/svg/editIcon.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg/infoIcon.svg";
import { useContext } from "react";
import { TaiContext } from "../../../TaiContextProvider";
import { useDispatch, useSelector } from "react-redux";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ReactComponent as Close } from "../../assets/svg/close.svg";

function CalibrationCollapsedGS({ data }: { data: any }) {
  const dispatch = useDispatch();
  const loaderState = useSelector((state: any) => state.taiCommon.loader);
  const {
    CalibrationCustomKeywords,
    LookbackPeriod,
    CalibrationSources,
    GoogleKwsPlannerCheck,
    ExploreValueCheck,
  } = useSelector((state: any) => state?.gsTargetingAI?.gsCalibration?.data);

  const { setNodes, setEdges, setPopover, setPopoverFlag } = useContext(
    TaiContext
  ) as any;
  const [showSourcesPopover, setShowSourcesPopover] = useState(false);
  const [showCustomKWPopover, setShowCustomKWPopover] = useState(false);

    
  const target = document.querySelector('.react-flow__viewport');
  const options = {
    attributes: true,
    characterData: true,
  }

useEffect(()=>{
  if (target) {
    const observer = new MutationObserver(()=>setShowCustomKWPopover(false));
    if(showCustomKWPopover)
    observer.observe(target, options);
    return () => observer.disconnect();
  }

},[showCustomKWPopover])

useEffect(()=>{
  if (target) {
    const observer = new MutationObserver(()=>setShowSourcesPopover(false));
    if(showSourcesPopover)
    observer.observe(target, options);
    return () => observer.disconnect();
  }

},[showSourcesPopover])
    

  return (
    <Box
      className="node-container-common-style"
      style={{
        position: "absolute",
        zIndex: "100",
      }}
      // className="node-container-common-style"
      sx={{
        height: "9.68rem",
        width: "12.43rem",
        padding: 1,
        background: "#fff",
        position: "absolute",
      }}
    >
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: 1 }}
      >
        <Typography className="font-12 font-600">Calibration</Typography>
        <Edit
          onClick={() =>
            data.onEditClick(dispatch, loaderState, setNodes, setEdges)
          }
        />
      </Stack>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: "1.87rem",
            border: "0.751412px solid #DBDDDF",
            borderRadius: "0.37rem",
            padding: 1,
          }}
        >
          <Typography sx={{ fontWeight: 400, fontSize: "0.625rem" }}>
            Data Recency&nbsp;
            <InfoIcon />
          </Typography>
          <Typography sx={{ fontWeight: 800, fontSize: "0.5rem" }}>
            <b> Last {LookbackPeriod}</b>
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: "1.87rem",
            border: "0.751412px solid #DBDDDF",
            borderRadius: "0.37rem",
            padding: 1,
            opacity: `${
              !GoogleKwsPlannerCheck || !ExploreValueCheck ? 0.5 : 1
            }`,
          }}
        >
          <Typography sx={{ fontWeight: 400, fontSize: "0.625rem" }}>
            Sources&nbsp;
            <InfoIcon />
          </Typography>

          <OverlayTrigger
            show={showSourcesPopover && data.popover}
            placement="right"
            rootClose
            rootCloseEvent="mousedown"
            onExited={() => {
              setPopoverFlag(false);
              setShowSourcesPopover(false);
              setPopover(true);
            }}
            overlay={
              <Popover
                id={`popoverKeywordSetup`}
                className="popover-keyword-setup"
              >
                <Popover.Header as="h3" className="custom-popover-header">
                  <div>Sources</div>
                  <div className="popover-header-close">
                    <Close
                      onClick={() => {
                        setShowSourcesPopover(false);
                      }}
                    />
                  </div>
                </Popover.Header>
                <Popover.Body>
                  <div className="custom-popover-body">
                    {CalibrationSources?.map((keyword: any) => (
                      <div key={keyword}>{keyword}</div>
                    ))}
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <Button
              disabled={!GoogleKwsPlannerCheck || !ExploreValueCheck}
              onClick={() => {
                setShowSourcesPopover(!showSourcesPopover);
                if (showCustomKWPopover) setShowCustomKWPopover(false);
              }}
              variant="text"
              sx={{
                textTransform: "none",
                fontWeight: 500,
                fontSize: "8px",
                justifyContent: "end",
                padding: 0,
                "&:hover": { background: "none" },
              }}
              disableRipple
            >
              View
            </Button>
          </OverlayTrigger>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: "1.87rem",
            border: "0.751412px solid #DBDDDF",
            borderRadius: "0.37rem",
            padding: 1,
            opacity: `${
              CalibrationCustomKeywords.length === 0 || !ExploreValueCheck
                ? 0.5
                : 1
            }`,
          }}
        >
          <Typography sx={{ fontWeight: 400, fontSize: "0.625rem" }}>
            Custom Keywords&nbsp;
            <InfoIcon />
          </Typography>

          <OverlayTrigger
            show={showCustomKWPopover && data.popover}
            placement="right"
            rootClose
            rootCloseEvent="mousedown"
            onExited={() => {
              setPopoverFlag(false);
              setShowCustomKWPopover(false);
              setPopover(true);
            }}
            overlay={
              <Popover
                id={`popoverKeywordSetup`}
                className="popover-keyword-setup"
              >
                <Popover.Header as="h3" className="custom-popover-header">
                  <div>Custom Keywords</div>
                  <div className="popover-header-close">
                    <Close
                      onClick={() => {
                        setShowCustomKWPopover(false);
                      }}
                    />
                  </div>
                </Popover.Header>
                <Popover.Body>
                  <div className="custom-popover-body">
                    {CalibrationCustomKeywords?.map((keyword: any) => (
                      <div key={keyword}>{keyword}</div>
                    ))}
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <Button
              // className="collapsed-show"
              disabled={
                CalibrationCustomKeywords.length === 0 || !ExploreValueCheck
              }
              id="collapsed-show"
              onClick={() => {
                setShowCustomKWPopover(!showCustomKWPopover);
                if (showSourcesPopover) setShowSourcesPopover(false);
              }}
              variant="text"
              sx={{
                textTransform: "none",
                fontWeight: 500,
                fontSize: "8px",
                justifyContent: "end",
                padding: 0,
                "&:hover": { background: "none" },
              }}
              disableRipple
            >
              View
            </Button>
          </OverlayTrigger>
        </Stack>
      </Stack>
    </Box>
  );
}

export default CalibrationCollapsedGS;
