import React, { useState, useMemo, useEffect } from "react";
import { StyledLogScreenFilterBox } from "../styled";
import { Box, Skeleton } from "@mui/material";
import {
  DropDown,
  DynamicFilterBoxWithMemo,
} from "components/CreativeAI_Revamp/shared";
import {
  StyledDateRangeBox,
  StyledDateRangeBoxContainer,
  StyledHomeDivider,
} from "./styled";
import { DynamicFilterItemType } from "components/CreativeAI_Revamp/shared/DynamicFilterBox/types";
import { useSelector } from "react-redux";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { ReactComponent as DropdownArrow } from "../../../../../assets/cai/svg/drop_down_arrow.svg";
import { StyledText } from "components/shared";
import HandleClickOutside from "components/common/HandleClickOutside";
import { LogScreenDateRangeMemo } from "./LogScreenDateRange";
import { collator } from "utils/strings";

const dynamicFilterArray: DynamicFilterItemType[] = [
  {
    name: "Publishing Status",
    type: "check",
    adKey: "publishingStatus",
    options: [
      {
        value: "PUBLISHED",
        desc: "Published",
      },
      {
        value: "PROCESSING",
        desc: "Processing",
      },
    ],
    width: "13.3em",
  },

  {
    name: "Review State",
    type: "check",
    adKey: "reviewStatus",
    options: [
      {
        value: "APPROVED",
        desc: "Approved",
      },
      {
        value: "REJECTED",
        desc: "Rejected",
      },
      {
        value: "UNDER_REVIEW",
        desc: "In Review",
      },
      {
        value: "NOT_RAISED",
        desc: "Not Raised",
      },
    ],
    width: "12.6em",
  },
];

export default function LogScreenFilterBox({
  setAdAccountSelection,
  dateSelection,
  setDateSelection,
  setAdsetSelection,
  setAdSelection,
  setFilterState,
}: any) {
  // redux states
  const {
    adsSelection,
    adSetSelection,
    adAccountsSelection,
    adsListLog,
    adSetListLog,
  } = useSelector(
    (state: { fbActivityLogReducer: any }) => state.fbActivityLogReducer
  );
  const { adAccountList } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );

  const [showRange, setShowRange] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [adSetSearch, setadSetSearch] = useState("");
  const [selectionLocal, setSelectionLocal] = useState(dateSelection);

  const handleSelect = (ranges: any) => {
    setSelectionLocal((selectionLocal: any) => ({
      ...selectionLocal,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      count:
        ranges.selection.startDate === ranges.selection.endDate
          ? selectionLocal.count + 1
          : 2,
    }));
  };

  useEffect(() => {
    if (selectionLocal.count === 2) {
      setShowRange(false);
      setSelectionLocal((selectionLocal: any) => ({
        ...selectionLocal,
        count: 0,
      }));
      setDateSelection(selectionLocal);
    }
  }, [selectionLocal, setDateSelection]);

  const wrapperRef = React.useRef(null);
  HandleClickOutside(wrapperRef, [setShowRange]);

  const displayAdsList = useMemo(() => {
    if (searchText.length > 0) {
      return adsListLog?.data.filter(
        (option: any) =>
          option.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          option.id.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
    } else {
      return adsListLog?.data;
    }
  }, [searchText, adsListLog?.data]);

  const displayAdsetList = useMemo(() => {
    if (adSetSearch.length > 0) {
      return adSetListLog.data.filter(
        (option: any) =>
          option.name.toLowerCase().indexOf(adSetSearch.toLowerCase()) > -1 ||
          option.adsetId.toLowerCase().indexOf(adSetSearch.toLowerCase()) > -1
      );
    } else {
      return adSetListLog.data;
    }
  }, [adSetSearch, adSetListLog.data]);

  return (
    <StyledLogScreenFilterBox>
      <Box display="flex" gap=".75em">
        <DropDown
          title="Ad Account"
          options={adAccountList.data.sort((a, b) =>
            collator.compare(a.name.trim(), b.name.trim())
          )}
          selection={adAccountsSelection?.selection}
          handlePropChange={setAdAccountSelection}
          isLoading={
            adAccountList.isLoading ||
            adAccountsSelection?.selection === undefined
          }
          isFailed={adAccountList.isFailed}
          titleKey="name"
          subTitleKey="adAccountId"
          key="ad-accounts"
        />
        <DropDown
          title="Ad Set"
          selection={adSetSelection?.selectedAdSets}
          multiple
          options={displayAdsetList.sort((adset1: any, adset2: any) =>
            collator.compare(adset1?.name?.trim(), adset2?.name?.trim())
          )}
          handlePropChange={setAdsetSelection}
          onSearch={(value) => setadSetSearch(value ?? "")}
          onClose={() => {
            setadSetSearch("");
          }}
          titleKey="name"
          subTitleKey="adsetId"
          isLoading={
            adSetListLog.isLoading ||
            adSetSelection?.selectedAdSets?.length === 0
          }
          isFailed={adSetListLog.isFailed}
          key="adset"
          showTop
        />
        <DropDown
          title="Reference Ad"
          options={displayAdsList?.sort((a: any, b: any) =>
            collator.compare(a?.name?.trim(), b?.name?.trim())
          )}
          selection={adsSelection?.selectedAds}
          handlePropChange={(items, allSelectClicked) => {
            if (allSelectClicked) {
              setAdSelection([displayAdsList[0]]);
            } else {
              setAdSelection(items);
            }
          }}
          onSearch={(value) => setSearchText(value ?? "")}
          titleKey="name"
          subTitleKey="id"
          isLoading={
            adsListLog?.isLoading || adsSelection.selectedAds.length === 0
          }
          isFailed={adsListLog?.isFailed}
          multiple
          key="ads"
          onClose={() => {
            setSearchText("");
          }}
          showTop
        />
      </Box>
      <StyledHomeDivider />
      <DynamicFilterBoxWithMemo
        dynamicFilterArray={dynamicFilterArray}
        handleFilterChange={(filterState: any) => {
          setFilterState(filterState);
        }}
      />
      <StyledHomeDivider />
      {adAccountList?.isLoading ||
      adSetListLog.isLoading ||
      adsListLog?.isLoading ? (
        <Skeleton
          variant="rectangular"
          width="15em"
          height="2.2em"
          sx={{
            borderRadius: "6px",
          }}
        />
      ) : (
        <StyledDateRangeBoxContainer ref={wrapperRef}>
          <StyledDateRangeBox onClick={() => setShowRange(!showRange)}>
            <StyledText fontSize="12px" lineHeight="20px" color="#333333">
              {dateSelection?.startDate.toDateString()}-{" "}
              {dateSelection?.endDate.toDateString()}
            </StyledText>

            <Box pr=".7em" />
            <DropdownArrow />
          </StyledDateRangeBox>
          {showRange && (
            <LogScreenDateRangeMemo
              dateSelection={selectionLocal}
              handleSelect={handleSelect}
              setShowRange={setShowRange}
            />
          )}
        </StyledDateRangeBoxContainer>
      )}
    </StyledLogScreenFilterBox>
  );
}
