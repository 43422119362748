import {
  SET_ERROR,
  SetError,
  RESET_ERROR,
  ResetError,
} from "../../types/SmbDashboard/error";

export default function errorReducer(
  state: any = null,
  action: SetError | ResetError
): any {
  switch (action.type) {
    case SET_ERROR: {
      return {
        ...action.payload,
      };
    }
    case RESET_ERROR: {
      return null;
    }
    default: {
      return state;
    }
  }
}
