import {
  SetCalibrationData,
  ResetCalibrationData,
  SetAllCalibrationFormData,
  SET_CALIBRATION_DATA,
  SET_CALIBRATION_FORM_DATA,
  RESET_CALIBRATION_FORM_DATA,
  SET_ALL_CALIBRATION_FORM_DATA,
  SET_LOADED_KEYWORDS,
  SetLoadedKeywords,
} from "../../types/targetingAI";
import produce from 'immer';

const initialData = {
  BrandWebsiteValue: 100,
  CompetitorWebsiteValue: 100,
  ExploitValue: 50,
  CalibrationCustomKeywords: [],
  BrandWebsiteValueCheck: true,
  CompetitorWebsiteValueCheck: true,
  ExploitValueCheck: true,
  CalibrationCustomKeywordsCheck: false,
  TypeOfCohort: "INTEREST",
  ICPContextDescription: "",
  customAudiences:[],
  selectedCustomAudience:[]
};

export default function calibration(
  state: any = {
    apiData: {loadedKeywords: {
      brandLoadedKeywords: 7,
      lateralLoadedKeywords: 7,
      genericLoadedKeywords: 7,
    }},
    formData: initialData,
  },
  action: SetCalibrationData | ResetCalibrationData | SetLoadedKeywords | SetAllCalibrationFormData
) {
  switch (action.type) {
    case SET_CALIBRATION_DATA: {
      return {
        apiData: { ...action.payload },
        formData: { ...state.formData },
      };
    }
    case SET_LOADED_KEYWORDS: {
      return produce(state, (draft:any) => {
        draft.apiData.loadedKeywords[action.payload.keywordType] = action.payload.loadedKeywords;
      });
    }
    case SET_CALIBRATION_FORM_DATA: {
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case RESET_CALIBRATION_FORM_DATA: {
      return { ...state, formData: initialData };
    }
    case SET_ALL_CALIBRATION_FORM_DATA: {
      return {
        ...state,
        formData: action.payload,
      };
    }
    default:
      return state;
  }
}
