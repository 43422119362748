import {
  GenerateBillboardLink,
  GenerateBillboardLinkPayloadType,
  GENERATE_BILLBOARD_LINK,
  GetBillboardVideoUrl,
  GetBillboardVideoUrlPayloadType,
  GET_BILLBOARD_VIDEO_URL,
} from "store/types/CreativeAI/Facebook/CreateVariant/creativeAnimater";

export const generateBillboardLink = (
  payload: GenerateBillboardLinkPayloadType
): GenerateBillboardLink => ({
  type: GENERATE_BILLBOARD_LINK,
  payload,
});

export const getBillboardVideoUrl = (
  payload: GetBillboardVideoUrlPayloadType
): GetBillboardVideoUrl => ({
  type: GET_BILLBOARD_VIDEO_URL,
  payload,
});
