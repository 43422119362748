import HttpService from "HttpService/HttpService";
import { crossPlatform } from "MappedAPI/MappedAPI";
import { BASE_URL } from "utils/path";

export const getParentPlatform = async () => {
  let response = {
    success: false,
    message: "",
    data: [],
  };
  try {
    let urlParams = `${crossPlatform}/parent-platform`;
    response = await HttpService.get(BASE_URL, urlParams).then((data: any) => {
      return data.data;
    });
    return response;
  } catch (error: any) {
    console.log("getAdAccounts => ", error.message);
  }
  return response;
};
