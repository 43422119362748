import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as Gravtar } from "../../../../assets/svg/GravatarTAIICP.svg";
import { ReactComponent as ICPSelect } from "../../assets/svgs/ICPSelect.svg";
import { ReactComponent as EditIcon } from "../../assets/svgs/EditIcon.svg";
import { currencyFormatter } from "components/ActionDebugger/shared/utils/common.utils";
import {
  genderMapper,
  getgravtarValue,
} from "Pages/TargetingAIPlaygroundBeta/utils";
import CohortsModal from "Pages/TargetingAIPlaygroundBeta/ReactFlowComponents/CohortsModal/CohortsModal";
import { gravtar } from "Pages/TargetingAIPlaygroundBeta/ReactFlowComponents/constants/gravtar";
import getDemographicsKeywordsFromFlexibleSpecObject from 'utils/getDemographicsKeywordsFromFlexibleSpecObject';
import type useFacebookTargetingEnumMapping from 'utils/useFacebookTargetingsEnumMapping';

function ICPAudienceCohort({
  cohortData,
  handleFavAPI,
  isFav,
  cohortIndex,
  handleUpdateCohort,
  handleOnChange,
  checkedState,
  facebookTargetingEnumMappings,
}: {
  cohortData: any;
  handleFavAPI: any;
  isFav: any;
  cohortIndex: number;
  handleUpdateCohort: any;
  handleOnChange: any;
  checkedState: any;
  facebookTargetingEnumMappings: ReturnType<typeof useFacebookTargetingEnumMapping>;
}) {
  const [open, setOpen] = useState(false);

  const demographics = getDemographicsKeywordsFromFlexibleSpecObject(cohortData?.demographics, facebookTargetingEnumMappings);
  return (
    <>
      <div
        className="cohort-header"
        style={{
          background:
            "linear-gradient(0deg, #ECEEF1, #ECEEF1), linear-gradient(0deg, #F6F7F8, #F6F7F8)",
          borderRadius: "12px",
          margin: "1.25em 1em",
          padding: "1.25em 1em",
        }}
      >
        <Box display={"flex"} gap={1}>
          <div className="gravtar">
            <img
              width={"64px"}
              src={
                gravtar[
                getgravtarValue(
                  {
                    first: cohortData?.age?.age_min,
                    second: cohortData?.age?.age_max,
                  },
                  cohortData?.gender
                )
                ]
              }
            />
          </div>
          <div
            className="cohort-header-details"
            style={{
              fontSize: "12px",
              lineHeight: "20px",
              color: "#5C6674",
              width: "100%",
            }}
          >
            {" "}
            <Box display={"flex"} justifyContent={"space-between"}>
              <div style={{ fontWeight: 700 }}>
                {" "}
                {`Cohort C${cohortData.cohort_id}`}
              </div>
              <Checkbox
                disableRipple
                sx={{
                  padding: 0,
                  paddingRight: 0.5,
                }}
                icon={
                  <StarBorderIcon
                    fontSize="small"
                    sx={{
                      color: "#7D899C",
                    }}
                  />
                }
                checkedIcon={
                  <StarIcon
                    fontSize="small"
                    sx={{
                      color: "#7D899C",
                    }}
                  />
                }
                // checked={isFav.includes(index)}
                // onChange={() => handleFavAPI(index, cohortData)}
                checked={isFav.includes(cohortData.cohort_name)}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleFavAPI(
                    cohortData.cohort_id,
                    cohortData.cohort_name,
                    cohortData
                  );
                }}
              />
            </Box>
            <div
              style={{
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: "600",
                color: "#000000",
              }}
            >
              {cohortData?.cohort_name}
            </div>
            <Typography
              style={{
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "20px",
              }}
              mb={1.5}
            >
              <span
                style={{
                  color: "#ECEEF1",
                  padding: "0 4px",
                  fontWeight: "700",
                  background: "#5C6674",
                  borderRadius: "4px",
                }}
              >
                ICP
              </span>{" "}
              {cohortData?.icp_name}{" "}
              {cohortData?.edited_cohort_flag && (
                <span
                  style={{
                    fontWeight: "400",
                    fontStyle: "italic",
                    fontSize: "10px",
                    lineHeight: "16px",
                  }}
                >
                  (Edited)
                </span>
              )}
            </Typography>
            <Box display={"flex"} justifyContent={"space-between"}>
              <div>
                <div
                  style={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: 700,
                  }}
                >
                  {currencyFormatter(cohortData?.audience_size)}
                </div>
                <div>Potential Audience</div>
              </div>
              <div>
                <div
                  style={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: 700,
                  }}
                >
                  {cohortData?.relevance}
                </div>
                <div>Relevance Score</div>
              </div>
            </Box>
          </div>
        </Box>
      </div>
      <div
        className="cohort-body"
        style={{
          padding: "0px 15px 5px",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            paddingBottom: "5px",
            borderBottom: "1px dashed #AEB6C4",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "20px",
            }}
          >
            Targeting:
          </Typography>
          <Button
            variant="text"
            startIcon={<EditIcon />}
            sx={{
              textTransform: "capitalize",
              "&:hover": {
                background: "transparent",
              },
            }}
            onClick={() => setOpen(true)}
          >
            Edit
          </Button>
        </Box>
        <Box className="cohort-list" mt={3}>
          <Box mb={1.25}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "20px",
                  fontWeight: 500,
                  width: "30%",
                  color: "#7D899C",
                }}
              >
                Age, Gender
              </Typography>
              <Box
                className="keyword-list"
                display={"flex"}
                gap={1.25}
                width={"70%"}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 500,
                    padding: "4px 8px",
                    background: "#F6F7F8",
                    borderRadius: "4px",
                    color: "#5C6674",
                  }}
                >
                  {`${cohortData?.age?.age_min}-${cohortData?.age?.age_max} yrs`}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 500,
                    padding: "4px 8px",
                    background: "#F6F7F8",
                    borderRadius: "4px",
                    color: "#5C6674",
                  }}
                >
                  {cohortData?.gender?.genders === null
                    ? "All"
                    : cohortData?.gender?.genders?.length === 1
                      ? genderMapper[cohortData?.gender?.genders]
                      : "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
          {cohortData?.interests?.length && (
            <Box mb={1.25}>
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 500,
                    width: "30%",
                    color: "#7D899C",
                  }}
                >
                  Interests
                </Typography>
                <Box
                  className="keyword-list"
                  display={"flex"}
                  gap={1.25}
                  width={"70%"}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: "20px",
                      fontWeight: 500,
                      padding: "4px 8px",
                      background: "#F6F7F8",
                      borderRadius: "4px",
                      color: "#5C6674",
                      flexShrink: 0,
                      width:
                        cohortData?.interests?.[0]?.name.length > 20
                          ? "150px"
                          : "auto",
                      whiteSpace:
                        cohortData?.interests?.[0]?.name.length > 20
                          ? "nowrap"
                          : "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {cohortData?.interests?.[0]?.name}
                  </Typography>
                  <Button
                    variant="text"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#0869FB",
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {`${cohortData?.interests?.length - 1}+ More`}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          {cohortData?.behaviors?.length && (
            <Box mb={1.25}>
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 500,
                    width: "30%",
                    color: "#7D899C",
                  }}
                >
                  Behaviour
                </Typography>
                <Box
                  className="keyword-list"
                  display={"flex"}
                  gap={1.25}
                  width={"70%"}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: "20px",
                      fontWeight: 500,
                      padding: "4px 8px",
                      background: "#F6F7F8",
                      borderRadius: "4px",
                      color: "#5C6674",
                      flexShrink: 0,
                    }}
                  >
                    {cohortData?.behaviors?.[0]?.name}
                  </Typography>

                  <Button
                    variant="text"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#0869FB",
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {`${cohortData?.behaviors?.length - 1}+ More`}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          {!!demographics?.length && (
            <Box mb={1.25}>
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 500,
                    width: "30%",
                    color: "#7D899C",
                  }}
                >
                  Demographics
                </Typography>
                <Box
                  className="keyword-list"
                  display={"flex"}
                  gap={1.25}
                  width={"70%"}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: "20px",
                      fontWeight: 500,
                      padding: "4px 8px",
                      background: "#F6F7F8",
                      borderRadius: "4px",
                      color: "#5C6674",
                      flexShrink: 0,
                    }}
                  >
                    {demographics?.[0]?.name}
                  </Typography>

                  <Button
                    variant="text"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#0869FB",
                      "&:hover": {
                        background: "transparent",
                      },
                      flexShrink: 0,
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {`${demographics?.length - 1}+ More`}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </div>
      <div
        className="cohort-footer"
        style={{
          margin: "10px 15px 20px",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            width: "100%",
            border: "1.5px solid #0869FB",
            textTransform: "capitalize",
            color: "#0869FB",
            ":hover": {
              border: "1.5px solid #0869FB",
              color: "#0869FB",
              background: "transparent",
              boxShadow: "none",
            },
          }}
          onClick={() => handleOnChange(cohortIndex)}
          startIcon={checkedState[cohortIndex] ? <ICPSelect height={15} /> : ""}
        >
          {checkedState[cohortIndex] ? "Selected" : "Select"}
        </Button>
      </div>
      {open && (
        <CohortsModal
          open={open}
          setOpen={setOpen}
          cohortData={cohortData}
          handleUpdateCohort={handleUpdateCohort}
          cohortIndex={cohortIndex}
          facebookTargetingEnumMapping={facebookTargetingEnumMappings}
        />
      )}
    </>
  );
}

export default ICPAudienceCohort;
