import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle, Position, useReactFlow } from "react-flow-renderer";
import Box from "@mui/material/Box";
import LoaderContainer from "../../../common/LoaderContainer";
import LoaderRightStrip from "../../../common/LoaderRightStrip";
import { ReactComponent as KeywordCost } from "../../assets/svg/keywordTuning/keywordCost.svg";
import { ReactComponent as CompetitionIndex } from "../../assets/svg/keywordTuning/competitionIndex.svg";
import { ReactComponent as ImpressionSignificance } from "../../assets/svg/keywordTuning/impressionSignificance.svg";
import { ReactComponent as MonthlyAvgSearches } from "../../assets/svg/keywordTuning/monthlyAvgSearches.svg";
import { ReactComponent as Line } from "../../../ReactFlowComponents/Assets/svgs/str-line.svg";
import { keywordTuningData } from "../../constants/loader";
import { TaiContext } from "Pages/TargetingAIPlaygroundBeta/TaiContextProvider";
import { CommonState } from "store/types/common";
import { AdAccountState } from "store/types/adaccount";
import { TAIGSearchMixPanel } from "../../utils";

export default function LoaderKeywordTuning({ data }: { data: any }) {
  const dispatch = useDispatch();
  const { setCenter } = useReactFlow();

  const { setNodes, setEdges } = useContext(TaiContext) as any;
  const gsCalibrationState = useSelector(
    (state: any) => state?.gsTargetingAI?.gsCalibration?.data
  );

  const loaderState = useSelector((state: any) => state.taiCommon.loader);
  const currentPage = useSelector((state: any) => state.taiCommon.currentPage);
  const [loadingId, setLoadingId] = useState(0);
  const polling = useSelector((state: any) => state.taiCommon.polling);
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { selectedAgency } = common;
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;
  const aiInitiation = useSelector((state: any) => state.aiInitiation.apiData);
  const aiInitiationFormData = useSelector(
    (state: any) => state.aiInitiation.formData
  );

  const keywordTuningContinueClicked = () => {
    TAIGSearchMixPanel(
      "TAI-Gsearch Keyword Loader Clicked",
      selectedBrand?.name,
      selectedAgency?.name,
      {
        ai_group_id: aiInitiationFormData?.selectedAiGroup?.id,
        ai_group_name: aiInitiationFormData?.selectedAiGroup?.name,
        ad_account_name: aiInitiation?.adAccount?.name,
        ad_account_id: aiInitiation?.adAccount?.id,
        agency_id: selectedAgency.id,
        brand_id: selectedBrand.id,
        brand_type: selectedBrand.brandType,
      }
    );
    data.loaderContinue(dispatch, loaderState, setNodes, setEdges);
  };
  document.addEventListener("visibilitychange", function () {
    // this event will work for all the loaders
    if (document.visibilityState === "hidden") {
      if (loadingId < 4) {
        setTimeout(() => {
          setLoadingId(loadingId + 1);
        }, 2000);
      }
    }
  });

  useEffect(() => {
    if (loadingId < 4 && currentPage === data.index) {
      setTimeout(() => {
        setLoadingId(loadingId + 1);
      }, 2000);
    } else {
      setLoadingId(5);
    }
  }, [loadingId, currentPage]);

  useEffect(() => {
    if (data.position) {
      setCenter(data.position.x + 200, data.position.y + 300, {
        zoom: 0.7,
        duration: 1000,
      });
    }
  }, []);

  useEffect(() => {
    if (polling.name === "calibrationGS" && currentPage === data.index) {
      setLoadingId(0);
    }
  }, [polling, currentPage]);

  return (
    <>
      <div
        className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex focus:outline-none w-100 h-100"
        style={{
          position: "absolute",
          zIndex: "100",
        }}
      >
        <Handle
          type="target"
          position={Position.Left}
          style={{ background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          // isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position={Position.Right}
          style={{ background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          // isConnectable={isConnectable}
        />
        <LoaderContainer
          steps={keywordTuningData.steps}
          loadingId={loadingId}
          continueProps={{
            onClick: keywordTuningContinueClicked,
            disabled: loadingId < 4,
            className: `${
              loadingId < 4 ? "disable-continue" : "enable-continue"
            } ${currentPage === data.index ? "" : "d-none"}`,
          }}
          loaderGifProps={{
            src: keywordTuningData.gif,
            alt: "Keyword Tuning",
            style: { height: "15rem", width: "12rem" },
          }}
          closeProps={{
            className: currentPage > data.index ? "" : "d-none",
            onClick: () =>
              data.loaderContinue(dispatch, loaderState, setNodes, setEdges),
          }}
          header="Keyword Tuning"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Line />
            <LoaderRightStrip
              items={[
                {
                  title: "Keyword Cost",
                  text: "Set the weightage for Cost, in calculation of Performance Score",
                  svg: <KeywordCost />,
                },
                {
                  title: "Competition Index",
                  text: "Select the level of competition index to filter the keywords based on your requirement",
                  svg: <CompetitionIndex />,
                },
              ]}
            />
            <Line />
            <LoaderRightStrip
              items={[
                {
                  title: "Impressions significance",
                  text: "Select the weightage of Impressions in terms of quality and quantity",
                  svg: <ImpressionSignificance />,
                },
                {
                  title: "Monthly Avg Searches",
                  text: "Select the range for the monthly average search volume to filter the keywords",
                  svg: <MonthlyAvgSearches />,
                },
              ]}
            />
          </Box>
        </LoaderContainer>
      </div>
    </>
  );
}
