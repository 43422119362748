import moment from "moment";
import React from "react";
import "./index.scss";
import ButtonUI from "../../ui/ButtonUI/ButtonUI";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getElementLogs,
  getPublishClusters,
  setClusterDetails,
  setClusterLoader,
  setGSAIDropdownDisabled,
  updateAdRotation,
} from "../../store/actions/cluster";
import { initialState } from "../../store/types/auth";
import { ReactComponent as Timer } from "../../assets/svg/timer.svg";
import Switch from "../../ui/Switch/Switch";
import ModalWrapper from "../Modal/ModalWrapper";
import ModalContainer from "../Modal/ModalContainer";
import Table from "../../ui/Table/Table";
import TableElement from "../../ui/Table/TableElement";
import { mixPanelAdEvent } from "../../utils/mixpanel";
import { AdAccountState } from "../../store/types/adaccount";
import NoData from "components/common/NoData";

const logsHeaders = [
  "Date",
  "Campaign name",
  "Ad group name",
  "Ad",
  "Asset Type",
  "Original Assets",
  "Position Pinning",
  "Performance",
  "New Assets",
];

const KeywordRecommendation: React.FC<{
  data?: any;
}> = ({ data }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [replacementLogs, setReplacementLogs] = React.useState([]);
  const [showLogsTable, setShowLogsTable] = React.useState<any>({
    open: false,
    data: {},
  });
  const [confirmationModal, setConfirmationModal] = React.useState<any>({
    open: false,
    text: "",
    data: {},
    flag: false,
  });
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand, selectedGSAIAdaccount } = adaccount;
  const showLogsTableModal = (elementId: any) => {
    //setReplacementLogs(logsList);

    dispatch(
      getElementLogs(
        { elementId: elementId, user: auth.user },
        (response: any, error: boolean) => {
          if (!error) {
            let arr: Array<any> = [];
            response.data.forEach((cluster: any, index: number) => {
              if (cluster.ReplacementHeadlineLogs.length > 0) {
                cluster.ReplacementHeadlineLogs.forEach(
                  (h: any, index: number) => {
                    arr.push({
                      campaign_name: cluster.campaignName,
                      ad_group_name: cluster.adGroupName,
                      ad: "",
                      asset_type: "Headline",
                      replacementDate: cluster.createdAt,
                      previousAsset: h.previousAsset,
                      pinnedField: "",
                      performance: h.performance,
                      newAsset: h.newAsset,
                    });
                  }
                );
              } else {
                arr.push({
                  campaign_name: cluster.campaignName,
                  ad_group_name: cluster.adGroupName,
                  ad: "",
                  asset_type: "Headline",
                  replacementDate: "",
                  previousAsset: "",
                  pinnedField: "",
                  performance: "",
                  newAsset: "",
                });
              }
              if (cluster.ReplacementDescriptionLogs.length > 0) {
                cluster.ReplacementDescriptionLogs.forEach(
                  (d: any, index: number) => {
                    arr.push({
                      campaign_name: cluster.campaignName,
                      ad_group_name: cluster.adGroupName,
                      ad: "",
                      asset_type: "Description",
                      replacementDate: cluster.createdAt,
                      previousAsset: d.previousAsset,
                      pinnedField: "",
                      performance: d.performance,
                      newAsset: d.newAsset,
                    });
                  }
                );
              } else {
                arr.push({
                  campaign_name: "",
                  ad_group_name: "",
                  ad: "",
                  asset_type: "Description",
                  replacementDate: "",
                  previousAsset: "",
                  pinnedField: "",
                  performance: "",
                  newAsset: "",
                });
              }
            });
            //console.log(arr);
            //console.log(replacementLogs);
            setShowLogsTable({
              open: true,
              data: arr,
            });
            dispatch(setClusterLoader(false));
          }
        }
      )
    );
  };

  const handleModalSubmit = () => {
    //const flag = confirmationModal.flag;

    let params = {
      elementId: confirmationModal.data.id,
      creativeReplacement: confirmationModal.flag,
    };

    //console.log(params);

    dispatch(
      updateAdRotation(
        { params: params, user: auth?.user },
        (response: any, error: boolean) => {
          if (response && !error) {
            //console.log("response>> ",response);
          }
        }
      )
    );

    setConfirmationModal({
      open: false,
      text: "",
      data: {},
      flag: false,
    });
  };

  const handleModalClose = () => {
    setConfirmationModal({
      open: false,
      text: "",
      data: {},
      flag: false,
    });
  };

  const onAdRotationChange = (group: any, flag: boolean) => {
    mixPanelAdEvent("GSAI Ad Copy Rotation Toggled", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      ad_account_id: selectedGSAIAdaccount?.id,
      ad_account_name: selectedGSAIAdaccount?.adAccount,
      ai_group_id: group?.id,
      ai_group_name: group.AiGroup?.name,
      gsai_ad_copy_toggle_on: flag,
      active_ads_for_rotation: group?.PublishClusters?.reduce(
        (count: any, item: any) => {
          return count + (item["value"] === true ? 1 : 0);
        },
        0
      ),
      recommendation_status:
        group?.show_recommendations == "true" ? "success" : "failed",
      platform_name: "Google",
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
    });
    setConfirmationModal({
      open: true,
      text: `Are you sure you want to turn ${
        flag ? "on" : "off"
      } Ad Copy Rotation?`,
      data: group,
      flag: flag,
    });
  };

  return (
    <>
      <div className="d-flex flex-column list">
        {data.length > 0 ? (
          data.map((group: any, i: number) => (
            <div
              key={i}
              className="list-item d-flex flex-row align-items-center"
            >
              <div className="column text-left" style={{ width: "16.6%" }}>
                <b>{group.AiGroup?.name}</b>
              </div>
              <div className="column text-left" style={{ width: "16.6%" }}>
                <div className="status">
                  Recommendation status
                  <span className="information">i</span>
                </div>
                {group.show_recommendations == "true" ? (
                  <div className="recommendations">
                    Keyword Recommendations Generated
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className="column text-left"
                style={{
                  pointerEvents:
                    group.PublishClusters.length > 0 &&
                    group.PublishClusters.every(
                      (c: any) => c.creativeReplacement
                    )
                      ? "auto"
                      : "none",
                  width: "16.6%",
                }}
              >
                <div className="status">
                  {" "}
                  Ad Copy Rotation
                  <span className="information">i</span>
                </div>
                <Switch
                  value={
                    group.PublishClusters.length > 0 &&
                    group.PublishClusters.every(
                      (c: any) => c.creativeReplacement
                    )
                  }
                  onChange={(e) => {
                    onAdRotationChange(group, e);
                  }}
                ></Switch>
              </div>
              <div className="column text-left" style={{ width: "16.6%" }}>
                <div className="status">
                  {" "}
                  Active ads for Rotation
                  <span className="information">i</span>
                </div>
                {group.PublishClusters.reduce((count: any, item: any) => {
                  return count + (item["value"] === true ? 1 : 0);
                }, 0)}{" "}
                of {group.PublishClusters.length}
              </div>
              <div className="column text-left" style={{ width: "10.6%" }}>
                <ButtonUI
                  onClick={() => {
                    showLogsTableModal(group.id);
                    mixPanelAdEvent("GSAI Activity Log Clicked", {
                      brand_id: selectedBrand?.id,
                      brand_name: selectedBrand?.name,
                      brand_type: selectedBrand?.brandType,
                      ad_account_id: selectedGSAIAdaccount?.id,
                      ad_account_name: selectedGSAIAdaccount?.adAccount,
                      ai_group_id: group?.id,
                      ai_group_name: group.AiGroup?.name,
                      platform_name: "Google",
                      agency_id: selectedBrand?.agencyId,
                      agency_name: selectedBrand?.agency_name,
                      recommendation_status:
                        group?.show_recommendations == "true"
                          ? "success"
                          : "failed",
                      gsai_ad_copy_toggle_on:
                        group?.PublishClusters?.length > 0 &&
                        group?.PublishClusters?.every(
                          (c: any) => c.creativeReplacement
                        ),
                      active_ads_for_rotation: group?.PublishClusters?.reduce(
                        (count: any, item: any) => {
                          return count + (item["value"] === true ? 1 : 0);
                        },
                        0
                      ),
                    });
                  }}
                >
                  <Timer></Timer>
                </ButtonUI>
              </div>
              <div className="column text-right" style={{ width: "20.6%" }}>
                <ButtonUI
                  type="button"
                  className={`btn width-secondary ${
                    group.recommendation_json ? "primary" : "secondary"
                  }`}
                  disabled={!group.recommendation_json}
                  onClick={() => {
                    dispatch(
                      setClusterDetails({
                        url: group.recommendation_json,
                        group_id: group.id,
                      })
                    );
                    dispatch(setGSAIDropdownDisabled(true));
                    history.push(
                      `/googlesearchai/keywords-clustering${history.location.search}`
                    );
                    mixPanelAdEvent("GSAI View Recommendations Clicked", {
                      brand_id: selectedBrand?.id,
                      brand_name: selectedBrand?.name,
                      brand_type: selectedBrand?.brandType,
                      ad_account_id: selectedGSAIAdaccount?.id,
                      ad_account_name: selectedGSAIAdaccount?.adAccount,
                      ai_group_id: group?.id,
                      ai_group_name: group.AiGroup?.name,
                      gsai_ad_copy_toggle_on:
                        group?.PublishClusters?.length > 0 &&
                        group?.PublishClusters?.every(
                          (c: any) => c.creativeReplacement
                        ),
                      active_ads_for_rotation: group?.PublishClusters?.reduce(
                        (count: any, item: any) => {
                          return count + (item["value"] === true ? 1 : 0);
                        },
                        0
                      ),
                      recommendation_status:
                        group?.show_recommendations == "true"
                          ? "success"
                          : "failed",
                      platform_name: "Google",
                      agency_id: selectedBrand?.agencyId,
                      agency_name: selectedBrand?.agency_name,
                    });
                  }}
                >
                  View Recommendations
                </ButtonUI>
              </div>
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
      {showLogsTable.open && (
        <ModalWrapper>
          <ModalContainer
            width="70vw"
            title="Replacement logs table"
            handleClose={() => {
              setShowLogsTable({
                open: false,
                data: {},
              });
            }}
          >
            <div className="table-container" style={{ height: "70vh" }}>
              <Table
                headers={logsHeaders}
                stickyTop={true}
                topPosition={"-12px"}
              >
                {showLogsTable.data.map((c: any, index: number) => {
                  return (
                    <TableElement
                      type="tr"
                      className="publish-cluster-tr position-relative"
                    >
                      <TableElement type="td">
                        {c.replacementDate
                          ? moment(c.replacementDate).format("MMM DD, YYYY")
                          : "-"}
                      </TableElement>
                      <TableElement type="td">{c.campaign_name}</TableElement>
                      <TableElement type="td">{c.ad_group_name}</TableElement>
                      <TableElement type="td">{c.ad}</TableElement>
                      <TableElement type="td">{c.asset_type}</TableElement>
                      <TableElement type="td">{c.previousAsset}</TableElement>
                      <TableElement type="td">
                        {c.pinnedField == "UNSPECIFIED"
                          ? 0
                          : c.pinnedField.split("_")[1]}
                      </TableElement>
                      <TableElement type="td">{c.performance}</TableElement>
                      <TableElement type="td">{c.newAsset}</TableElement>
                    </TableElement>
                  );
                })}
              </Table>
            </div>
          </ModalContainer>
        </ModalWrapper>
      )}

      {confirmationModal.open && (
        <ModalWrapper>
          <ModalContainer
            title="Confirmation"
            submitText="Yes"
            cancelText="Cancel"
            handleSubmit={handleModalSubmit}
            handleClose={handleModalClose}
          >
            <p>{confirmationModal.text}</p>
          </ModalContainer>
        </ModalWrapper>
      )}
    </>
  );
};

export default KeywordRecommendation;
