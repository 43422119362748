import React from "react";
import noAdsImage from "../../../../../assets/png/noGsaiAdsAvailable.png";
import {
  StyledNoAdsAvailableImage,
  StyledNoAdsPara,
  StyledNoAdsSubPara,
  StyledNoAdsAvailableMainDiv,
  StyledNoAdsAvailableContentMainDiv,
} from "./styled";

const NoAdsAvailable = ({
  imageSrc,
  title,
  subTitle,
  paddingNeeded,
}: {
  imageSrc?: string;
  title?: string;
  subTitle?: string;
  paddingNeeded?: boolean;
}) => {
  return (
    <StyledNoAdsAvailableMainDiv
      style={{
        marginTop: paddingNeeded ? "-5rem" : "0em",
      }}
    >
      <StyledNoAdsAvailableImage src={imageSrc ?? noAdsImage} alt="No_Img" />
      <StyledNoAdsAvailableContentMainDiv>
        <StyledNoAdsPara>
          {title ?? "No ads available for selected filters."}
        </StyledNoAdsPara>
        <StyledNoAdsSubPara>
          {subTitle ??
            " Try modifying the filters to view ads for optimising ad copies using AI-led recommendations"}
        </StyledNoAdsSubPara>
      </StyledNoAdsAvailableContentMainDiv>
    </StyledNoAdsAvailableMainDiv>
  );
};

export default NoAdsAvailable;
