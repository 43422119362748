import styled from "styled-components";

export const StyledMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
`;

export const StyledMainMultiContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 18px;
`;

export const StyledMultiInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  font-size: 14px;
  color: #dddddd;
  width:100%;
  box-shadow: 0px 4.83018px 16.9056px rgba(0, 0, 0, 0.05);
`;

export const StyledTextAreaContainer = styled.div`
  display: flex;
  background-color: #fff;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  color: #9e9e9e;
  font-weight: 600;
`;
export const StyledCharCount = styled.div`
  display: flex;
  justify-content: end;
  padding: 0px 12px;
  color: #797979;
  font-weight: 400;
  font-size: 10px;
  float: right;
  width: 100%;
`;

export const StyledCharContainer = styled.div`
  display: flex;
`;
export const StyledInputWithCharCount = styled.div`
  @media screen and (max-width: 1280px) {
    width: 75%;
  }
  @media screen and (min-width: 1280px) and (max-width: 1500px) {
    width: 78%;
  }
  @media screen and (min-width: 1500px) and (max-width: 1680px) {
    width: 82%;
  }
  @media screen and (min-width: 1680px) {
    width: 85%;
  }
`;

export const StyledTextContainer = styled.div`
  padding: 8px 0px 8px 5px;
  color: black;
  font-weight: 400;
  font-size: 14px;
  font-family: Inter;
  // width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledSuccessLoaderContainer = styled.div`
  display: flex;
  width: 93%;
  justify-content: space-between;
  align-items: center;
`;
