import {
  SetAiInitiationData,
  SET_AI_INITIATION_DATA,
  SetAiInitiationValue,
  SET_AI_INITIATION_VALUE,
  ResetAiInitiation,
  RESET_AI_INITIATION,
  SET_UPDATED_RECOMMENDED_EXCLUSIONS_STATE,
  SetUpdatedRecommendedExclusions,
} from "../../types/targetingAI";
const initialState = {
  formData: {
    dropdown: -1,
    aiGroupOptions: null,
    countriesOptions: null,
    regionOptions: null,
    selectedAiGroup: null,
    selectedCountry: [],
    selectedRegion: [],
    disabled: false,
  },
  apiData: {},
  updateRecommendedExclusions:{
    loading: false,
    success: false,
    error: null,
  }
};
export default function aiInitiation(
  state = initialState,
  action: SetAiInitiationData | SetAiInitiationValue | ResetAiInitiation | SetUpdatedRecommendedExclusions
) {
  switch (action.type) {
    case SET_AI_INITIATION_DATA: {
      return { ...state, apiData: { ...action.payload } };
    }
    case SET_AI_INITIATION_VALUE: {
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case SET_UPDATED_RECOMMENDED_EXCLUSIONS_STATE: {
      return {
        ...state,
        updateRecommendedExclusions: action.payload
      };
    }
    case RESET_AI_INITIATION: {
      return initialState;
    }
    default:
      return state;
  }
}
