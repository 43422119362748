export const intValue = (val: string) => {
  const fields = val.split("%");
  return parseInt(fields[0]);
};

export const getPreviewImage = (createSpec: any) => {
  if (createSpec?.image_url) {
    return createSpec?.image_url;
  } else if (createSpec?.asset_feed_spec?.videos?.length > 0) {
    return createSpec?.asset_feed_spec?.videos[0]?.thumbnail_url;
  }
};

export const getAssetColorMapping = (assetList: string[]) => {
  const uniqueAssetName = new Set(assetList);
  const assetColorMappingObject: any = {};
  const globalAssetColor = [
    "#4CA8F5",
    "#F0967D",
    "#55A8A0",
    "#F8862D",
    "#A754F0",
    "#EF64B3",
    "#D97C81",
  ];
  let index = 0;

  for (const assetName of uniqueAssetName) {
    assetColorMappingObject[assetName] =
      globalAssetColor[index % globalAssetColor.length];
    index++;
  }
  return assetColorMappingObject;
};
