import httpService from "HttpService/HttpService";
import { getPreviewImage } from "components/ActionImpactTable/CreativeRotation/common.utils";
import { call, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CreativeRotationInitialState,
  GET_ADVANCE_SETTINGS,
  GetAdvanceSetting,
  GetBatch,
  GetBatchDetails,
  Get_BATCH_DATA,
  Get_BATCH_DATA_DETAILS,
  POST_ADVANCE_SETTINGS,
  POST_EXECUTION,
  POST_EXECUTION_LOADER,
  PostAdvanceSetting,
  PostExecution,
  PostExecutionLoader,
  SET_ADVANCE_SETTINGS,
  SetAdvanceSetting,
  SetBatch,
  SetBatchDetails,
  Set_BATCH_DATA,
  Set_BATCH_DATA_DETAILS,
  CrReducerReset,
  RESET_CR_REDUCER,
  GET_PREVIEW_DATA,
  GetPreviewData,
  SET_PREVIEW_DATA,
  SetPreviewData,
  SET_AUTO_EXECUTION,
  SetAutoExecution,
  SET_NO_RECOMMENDATION_LIST,
  SetNoRecommendationList,
  SetNonRecommendedAds,
  SET_NON_RECOMMENDED_ADS,
  GetBatchDetailsItemType,
} from "store/types/ActionDebugger/CreativeRotation/creativeRotation.types";

const initialState: CreativeRotationInitialState = {
  batchList: {
    data: [],
  },
  advanceSettings: {},
  isApproveExecution: {
    isFailed: false,
    isLoading: false,
  },
  auto_execution: false,
  noRecommendationList: null,
  isRejectExecution: {
    isFailed: false,
    isLoading: false,
  },
  nonRecommendedAds: [],
  adToBatchMapping: {},
};

export const creativeRotationReducer = (
  state = initialState,
  {
    type,
    payload,
  }:
    | GetBatch
    | GetBatchDetails
    | SetBatch
    | SetBatchDetails
    | GetAdvanceSetting
    | SetAdvanceSetting
    | PostAdvanceSetting
    | PostExecution
    | PostExecutionLoader
    | CrReducerReset
    | GetPreviewData
    | SetPreviewData
    | SetAutoExecution
    | SetNoRecommendationList
    | SetNonRecommendedAds
) => {
  switch (type) {
    case Get_BATCH_DATA:
      return {
        ...state,
        batchList: {
          ...state.batchList,
          isLoading: true,
        },
      };

    case RESET_CR_REDUCER:
      return {
        ...state,
        batchList: {
          data: [],
        },
        advanceSettings: {},
        isApproveExecution: {
          isFailed: false,
          isLoading: false,
        },
        isRejectExecution: {
          isFailed: false,
          isLoading: false,
        },
      };

    case Set_BATCH_DATA:
      {
        const modifiedPayloadData=payload.data.map((item)=>{
          return {
            ...item,
            batch_id:item.pipeline_id,
            ad_ids:item.asset_l0_details?.map(i=>({ad_id:i.asset_l0_ad_id,ad_account_id:i.ad_account_id})||[])}
        })

        return {
        ...state,
        batchList: {
          ...payload,
          data: [...state.batchList.data, ...modifiedPayloadData],
        },
        auto_execution: payload.auto_execution,
        nonRecommendedAds: payload.nonRecommendedAds || [],
      };}

    case SET_AUTO_EXECUTION:
      return {
        ...state,
        auto_execution: payload.value,
      };

    case SET_NO_RECOMMENDATION_LIST:
      return {
        ...state,
        noRecommendationList: payload.data.data,
      };

    case SET_NON_RECOMMENDED_ADS:
        return {
        ...state,
        nonRecommendedAds: payload.data
      }

    case Get_BATCH_DATA_DETAILS: {
      const data = [...state.batchList.data];
      const batchIndex = data.findIndex(
        (item) => item.asset_l1_id+item.batch_id === payload.asset_l1_id+payload.batchId
      );
      data[batchIndex].detailsList = {
        ...data[batchIndex].detailsList,
        data: [...(data[batchIndex].detailsList?.data ?? [])],
        isLoading: true,
      };
      return {
        ...state,
        batchList: {
          ...state.batchList,
          data,
        },
      };
    }

    case Set_BATCH_DATA_DETAILS: {
      const data = [...state.batchList.data];
      const newAdToBatchMapping:any={...state.adToBatchMapping}
      const batchIndex = data.findIndex(
        (item) => item.asset_l1_id+item.batch_id === payload.asset_l1_id+payload.batchId
      );
      const sortActionsBySpend=(actions:GetBatchDetailsItemType[])=>actions.sort((a, b) => {
          if (a.action_to_do === 'Turn ON' && b.action_to_do === 'Turn OFF') {
            return -1;
          }
          if (a.action_to_do === 'Turn OFF' && b.action_to_do === 'Turn ON') {
            return 1;
          }
          return parseInt(a.metrics.spend_pct_chg) - parseInt(b.metrics.spend_pct_chg);
        });
      const sortedPayloadDetailsData=sortActionsBySpend(payload.details.data)
      data[batchIndex].detailsList = {
        ...payload.details,
        data: [...sortedPayloadDetailsData],
      };
      payload.details.data.forEach((item)=>{
        newAdToBatchMapping[item.recommendation_id]={...item,pipelineId:payload.batchId, uniqueParentId:payload.asset_l1_id+payload.batchId}
      })
      let status_counts = { ...data[batchIndex].status_counts };
      // if (batchIndex === 0) {
        status_counts = {
          Executed:
            payload.details.data.filter((item) => item.status === "Executed")
              .length ?? undefined,
          Rejected:
            payload.details.data.filter((item) => item.status === "Rejected")
              .length ?? undefined,
          Failed:
            payload.details.data.filter((item) => item.status === "Failed")
              .length ?? undefined,
          Recommended:
            payload.details.data.filter((item) => item.status === "Recommended")
              .length ?? undefined,
          Processing:
            payload.details.data.filter((item) => item.status === "Processing")
              .length ?? undefined,
        };
      // }
      data[batchIndex].status_counts = status_counts;
      return {
        ...state,
        adToBatchMapping:newAdToBatchMapping,
        batchList: {
          ...state.batchList,
          ...payload,
          data,
        },
      };
    }

    case GET_ADVANCE_SETTINGS:
      return {
        ...state,
        advanceSettings: {
          isLoading: true,
        },
      };

    case SET_ADVANCE_SETTINGS:
      return {
        ...state,
        advanceSettings: {
          ...state.advanceSettings,
          ...payload,
        },
      };
    case POST_ADVANCE_SETTINGS:
      return {
        ...state,
        advanceSettings: {
          ...state.advanceSettings,
          isUpdating: true,
        },
      };

    case POST_EXECUTION: {
      let isApproveExecution = {};
      let isRejectExecution = {};

      if (payload.userAction == "APPROVED") {
        isApproveExecution = {
          isLoading: true,
          isFailed: false,
        };
      } else {
        isRejectExecution = {
          isLoading: true,
          isFailed: false,
        };
      }
      const batchList = { ...state.batchList };
      payload.body.forEach((item) => {
        const findBatchIndex=batchList.data.findIndex((i)=>i.asset_l1_id+i.batch_id===state.adToBatchMapping?.[item.recommendationId]?.uniqueParentId)
        const ind = batchList.data[findBatchIndex].detailsList?.data?.findIndex(
          (item2) => item2.recommendation_id === item.recommendationId
        );
        if (batchList.data[findBatchIndex].detailsList?.data && ind !== undefined) {
          batchList.data[findBatchIndex].detailsList.data[ind]= {
            ...batchList.data[findBatchIndex].detailsList?.data[ind],
            status: "Processing",
          };
        }
        batchList.data[findBatchIndex].status_counts.Processing = (batchList.data[findBatchIndex]?.status_counts?.Processing || 0)+1;
      });
      // batchList.data[0].status_counts.Processing = payload.body.length;

      return {
        ...state,
        isApproveExecution,
        isRejectExecution,
        batchList,
      };
    }

    case GET_PREVIEW_DATA: {
      const data = [...state.batchList.data];
      const batchIndex = data.findIndex(
        (item) => item.batch_id+item.asset_l1_id === payload.batchId+payload.asset_l1_id
      );
      if (data[batchIndex]) {
        data[batchIndex].imageData = {
          ...data[batchIndex]?.imageData,
          isLoading: true,
          asset_l1_id:payload.asset_l1_id
        };
      }

      return {
        ...state,
        batchList: {
          ...state.batchList,
          data,
        },
      };
    }

    case SET_PREVIEW_DATA: {
      const data = [...state.batchList.data];
      let imgArray: string[] = [];
      const batchIndex = data.findIndex(
        (item) => item.batch_id+item.asset_l1_id === payload.batchId+payload.asset_l1_id
      );
      data[batchIndex].imageData = {
        ...payload,
      };
      Object.keys(payload.data ?? {}).forEach((item) => {
        let image = getPreviewImage(
          payload.data?.[item]?.adcreatives?.data?.[0]
        );
        if (image) {
          imgArray.push(image);
        }
      });

      data[batchIndex].imgArray = imgArray;
      return {
        ...state,
        batchList: {
          ...state.batchList,
          data,
        },
      };
    }

    case POST_EXECUTION_LOADER: {
      let isApproveExecution = {};
      let isRejectExecution = {};

      if (payload.userAction == "APPROVED") {
        isApproveExecution = {
          isLoading: payload.isLoading,
          isFailed: payload.isFailed,
        };
      } else {
        isRejectExecution = {
          isLoading: payload.isLoading,
          isFailed: payload.isFailed,
        };
      }

      return {
        ...state,
        isApproveExecution,
        isRejectExecution,
      };
    }

    default:
      return state;
  }
};
