import {
  RadioGroup as MUIRadioGroup,
  RadioGroupProps as MUIRadioGroupProps,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledErrorMessage } from "../../Typography/Heading/styled";
import { Box } from "@mui/system";
import { FieldType } from "../../../CreativeAI/Facebook/shared/SharedTypes";
import { Radio } from "./Radio";
import InputLabel from "../../InputLabel/InputLabel";

interface RadioGroupProps extends MUIRadioGroupProps {
  field: FieldType;
  control?: any;
  registeredName?: any;
  required?: boolean;
  helperFunction?: (registeredName: string) => void;
}

export function RadioGroupForm({
  field,
  control,
  registeredName,
  helperFunction,
  required,
  ...props
}: RadioGroupProps) {
  return (
    <Controller
      control={control}
      name={registeredName}
      rules={{
        required,
      }}
      render={({
        field: { onChange, value },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <Box display="flex" flexDirection="column">
          <InputLabel
            label={field.field_label}
            tooltipText={field.helper_text}
            required={required}
          />
          <Box mb="15px" />
          <MUIRadioGroup
            onChange={(value) => {
              helperFunction && helperFunction(registeredName);
              onChange(value);
            }}
            value={value}
          >
            {field.choices.map((element) => (
              <Box mt="-8px">
                {" "}
                <Radio label={element} key={element} value={element} />
              </Box>
            ))}
          </MUIRadioGroup>
          <Box mb="-15px" />

          {error && (
            <StyledErrorMessage>This field is required *</StyledErrorMessage>
          )}
        </Box>
      )}
    />
  );
}
