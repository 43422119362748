import { Box } from "@mui/material";
import styled from "styled-components";

export const StyledDateRangeContainer = styled(Box)`
  position: relative;
  background-color: #e9e9e9;
  width: 12.4375em;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.6em;
  margin-right: 2em;
  border-radius: 0.5em;
  padding-right: 0.125em;
  padding: ${({ isCompare }: { isCompare: boolean }) =>
    isCompare ? undefined : ".8em .25em .8em .25em"};

  top: -0.25em;
  .daterangeSelector {
    z-index: 9999999999999999;
    position: absolute;
    padding: 1.25em;

    top: 2.5em;
    right: 1.25em;
    background-color: white;
    font-size: 0.875em;
    box-shadow: 0em 0.0625em 0.75em rgb(0 0 0 / 30%);
    border-radius: 0.625em;
    .rdrInputRange:nth-last-of-type(1) {
      display: none;
    }
  }
`;

export const StyledDateContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const StyledDatePara = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.6875em;
  line-height: 1em;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 0em;
`;

export const StyledDateParavs = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.625em;
  line-height: 1em;
  display: flex;
  align-items: center;
  color: #999999;
  margin-top: 0.35em;
`;

export const StyledArrowImage = styled.img`
  margin-left: 1.0625em;
`;
