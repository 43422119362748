import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import StatusTag from "./StatusTag";
import Configure from "./Configure";
import { Tooltip, Typography } from "@mui/material";
import PlatformRow from "./PlatformRow";
import { getRoundedValue } from "utils/commonFunctions";
import { getCurrency } from "../utils/index";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOptimizer } from "store/actions/SmbDashboard/common";
import { useHistory } from "react-router-dom";

const Item: any = styled(Paper)(() => ({
  backgroundColor: "#fff",
  width: "100%",
  textAlign: "center",
  color: "#0869FB",
  boxShadow: "none",
  margin: "2px",
  padding: "2px",
  fontSize: "16px",
  fontWeight: "500",
  alignItems: "flex-start",
}));

function GroupRow({ optimizer }: any) {
  const dispatch = useDispatch();
  const commonState = useSelector((state: any) => state.smb.common);
  const history = useHistory();
  // platform Id to Platform Name mapping
  const platforms = commonState?.platforms;
  const params = new URLSearchParams(window.location.search);
  const brandId = params.get("brand_id");
  const handleClick = (groupId: string) => {
    dispatch(
      setSelectedOptimizer({
        groupId,
        brandId,
        openBudgetPlanner: true,
      })
    );
    history.push(`/group-settings?group_id=${groupId}&brand_id=${brandId}`);
  };

  return (
    <>
      {Object.keys(optimizer).map((optimizerId: any) => {
        const currentOptimizerDetails = optimizer[optimizerId];

        const {
          sum = "",
          Platforms = [],
          currency,
        } = Object.keys(currentOptimizerDetails?.subOptimizers).reduce(
          (prev: any, current: any, index: number) => {
            const currentSubOptimizerMetrics =
              currentOptimizerDetails?.subOptimizers[current].metrics;

            if (
              currentSubOptimizerMetrics &&
              !currentSubOptimizerMetrics.isLoading
            ) {
              prev.sum += currentSubOptimizerMetrics?.spends.actual;

              if (
                currentSubOptimizerMetrics.currency &&
                currentSubOptimizerMetrics.currency !== "" &&
                prev.currency === " "
              ) {
                prev.currency = getCurrency(
                  currentSubOptimizerMetrics.currency
                );
              }
            }

            prev.Platforms.push(
              <PlatformRow
                key={current.id}
                platform={
                  currentOptimizerDetails?.subOptimizers[current].details
                }
                metrics={currentSubOptimizerMetrics}
                last={
                  currentOptimizerDetails?.subOptimizers.length - 1 === index
                }
                currency={
                  getCurrency(currentSubOptimizerMetrics.currency) || " "
                }
                groupId={currentOptimizerDetails.id}
                currentOptimizerDetails={currentOptimizerDetails}
              />
            );

            return prev;
          },
          {
            currency: " ",
            sum: 0,
            Platforms: [],
          }
        ) || {};

        return (
          <Box key={optimizerId}>
            <Stack
              style={{
                background: "#FFFFFF",
                border: "1px solid #E7E7E7",
                boxShadow: "0px 3.71014px 16.0773px #F2F2F2",
                borderRadius: "11px",
                margin: "10px",
                padding: "10px",
                borderLeft: `20px solid ${currentOptimizerDetails?.color}`,
              }}
            >
              <Item>
                <Stack
                  direction="row"
                  spacing={1}
                  style={
                    Platforms.length > 0
                      ? { borderBottom: "1px solid #F0F0F0" }
                      : {}
                  }
                >
                  <Item
                    sx={{
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#333",
                      minWidth: "15%",
                      maxWidth: "15%",
                      p: 0,
                    }}
                  >
                    <Item
                      sx={{
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Tooltip title={currentOptimizerDetails?.name} arrow placement="top">
                        <div style={{width:"fit-content"}}>
                          {currentOptimizerDetails?.name}
                        </div>
                      </Tooltip>
                    </Item>
                    <Item sx={{ display: "flex" }}>
                      <StatusTag type={currentOptimizerDetails?.type} />
                    </Item>
                  </Item>
                  <Item>
                    <Item>
                      {currency ? currency : ""}
                      {getRoundedValue(sum)}
                    </Item>
                    <Typography
                      variant="caption"
                      color="#464646"
                      component="div"
                    >
                      Total Spends
                    </Typography>
                  </Item>
                  <Item sx={{ display: "flex", justifyContent: "center" }}>
                    <Configure
                      value={
                        currentOptimizerDetails?.budgetPacingActive
                          ? "Active"
                          : "Inactive"
                      }
                      label="Bugdet Pacing"
                      isActive={currentOptimizerDetails?.budgetPacingActive}
                      isConfigurable={true}
                      onClick={() => handleClick(currentOptimizerDetails?.id)}
                    />
                  </Item>
                  <Item></Item>
                  <Item></Item>
                  <Item></Item>
                </Stack>
              </Item>
              {Platforms.length > 0 && <Box>{Platforms}</Box>}
            </Stack>
          </Box>
        );
      })}
    </>
  );
}

export default GroupRow;
