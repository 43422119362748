export const GET_ACTION_SUMMARY = "GET_ACTION_SUMMARY";
export const SET_ACTION_SUMMARY = "SET_ACTION_SUMMARY";
export const SET_PREVIOUS_ACTION_SUMMARY = "SET_PREVIOUS_ACTION_SUMMARY";
export const SET_ACTION_SUMMARY_LOADER = "SET_ACTION_SUMMARY_LOADER";
export const SET_PREVIOUS_ACTION_SUMMARY_LOADER =
  "SET_PREVIOUS_ACTION_SUMMARY_LOADER";
export const GET_PERFORMANCE_SUMMARY = "GET_PERFORMANCE_SUMMARY";
export const SET_PERFORMANCE_SUMMARY = "SET_PERFORMANCE_SUMMARY";
export const SET_PREVIOUS_PERFORMANCE_SUMMARY =
  "SET_PREVIOUS_PERFORMANCE_SUMMARY";
export const SET_PERFORMANCE_SUMMARY_LOADER = "SET_PERFORMANCE_SUMMARY_LOADER";
export const SET_PREVIOUS_PERFORMANCE_SUMMARY_LOADER =
  "SET_PREVIOUS_PERFORMANCE_SUMMARY_LOADER";
export const GET_KEY_INDICATORS = "GET_KEY_INDICATORS";
export const SET_KEY_INDICATORS = "SET_KEY_INDICATORS";
export const SET_PREVIOUS_KEY_INDICATORS = "SET_PREVIOUS_KEY_INDICATORS";
export const SET_KEY_INDICATORS_LOADER = "SET_KEY_INDICATORS_LOADER";
export const SET_PREVIOUS_KEY_INDICATORS_LOADER =
  "SET_PREVIOUS_KEY_INDICATORS_LOADER";

export interface ActionSummaryInitStateType {
  actionSummary: ActionSummaryData[];
  actionSummaryLoader: Boolean;
  previousActionSummary: ActionSummaryData[];
  previousActionSummaryLoader: Boolean;
  performanceSummary: LooseObject;
  previousPerformanceSummary: LooseObject;
  performanceSummaryLoader: Boolean;
  previousPerformanceSummaryLoader: Boolean;
  keyIndicators: KeyIndicatorItemsType[];
  previousKeyIndicators: LooseObject;
  keyIndicatorsLoader: Boolean;
  previousKeyIndicatorsLoader: Boolean;
}

export type GetActionSummary = {
  type: typeof GET_ACTION_SUMMARY;
  payload: {
    elementId: string;
    start_date: string;
    end_date: string;
    typeOfData: string;
    typeOfLoader: string;
  };
};
export type SetActionSummary = {
  type: typeof SET_ACTION_SUMMARY;
  payload: ActionSummaryData[];
};

export type SetPreviousActionSummary = {
  type: typeof SET_PREVIOUS_ACTION_SUMMARY;
  payload: ActionSummaryData[];
};
export type SetActionSummaryLoader = {
  type: typeof SET_ACTION_SUMMARY_LOADER;
  payload: Boolean;
};

export type SetPreviousActionSummaryLoader = {
  type: typeof SET_PREVIOUS_ACTION_SUMMARY_LOADER;
  payload: Boolean;
};
export type GetPerformanceSummary = {
  type: typeof GET_PERFORMANCE_SUMMARY;
  payload: {
    elementId: string;
    start_date: string;
    end_date: string;
    typeOfData: string;
    typeOfLoader: string;
    adsetIds?: any;
    campaignIds?: any;
    adMetricIds?: any;
    attributionWindow: string;
  };
};
export type SetPerformanceSummary = {
  type: typeof SET_PERFORMANCE_SUMMARY;
  payload: any;
};
export type SetPreviousPerformanceSummary = {
  type: typeof SET_PREVIOUS_PERFORMANCE_SUMMARY;
  payload: any;
};
export type SetPerformanceSummaryLoader = {
  type: typeof SET_PERFORMANCE_SUMMARY_LOADER;
  payload: Boolean;
};
export type SetPreviousPerformanceSummaryLoader = {
  type: typeof SET_PREVIOUS_PERFORMANCE_SUMMARY_LOADER;
  payload: Boolean;
};
export type GetKeyIndicators = {
  type: typeof GET_KEY_INDICATORS;
  payload: {
    elementId: string;
    start_date: string;
    end_date: string;
    typeOfData: string;
    typeOfLoader: string;
    adsetIds?: any;
    campaignIds?: any;
    attributionWindow: string;
  };
};
export type SetKeyIndicators = {
  type: typeof SET_KEY_INDICATORS;
  payload: any;
};
export type SetPreviousKeyIndicators = {
  type: typeof SET_PREVIOUS_KEY_INDICATORS;
  payload: any;
};
export type SetKeyIndicatorsLoader = {
  type: typeof SET_KEY_INDICATORS_LOADER;
  payload: Boolean;
};
export type SetPreviousKeyIndicatorsLoader = {
  type: typeof SET_PREVIOUS_KEY_INDICATORS_LOADER;
  payload: Boolean;
};
//common types
export interface ActionSummaryData {
  name: string;
  type: string;
  value: number;
}
export interface LooseObject {
  [key: number]: number;
}

export interface KeyIndicatorItemsType {
  metric: number;
  percentageDiff: number;
  value: number;
}
