import {
  GET_UTG_BUNDLE,
  GetUtgBundle,
} from "store/types/CreativeAI_Revamp/UniTextGen/uniTextGen";

export const getUtgBundle = (payload: any, callback?: any): GetUtgBundle => {
  return {
    type: GET_UTG_BUNDLE,
    payload,
    callback,
  };
};
