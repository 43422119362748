import styled from "styled-components";

export const StyledMainContainer = styled.div`
  positon: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const StyledSvgContainer = styled.div`
  height: 14.875em;
  width: 15.275em;
  margin-right: 3.2125em;
`;

export const StyledMessageMainContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  height: 11.0625em;
`;
export const StyledHeaderMessage = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.625em;
`;
export const StyledMessageContainer = styled.div`
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.43em;
  margin-bottom: 1.5em;
  height: 2.93em;
  width: 26.21em;
`;
