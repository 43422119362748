import moment from "moment";

export function getFirstDayOfTheMonth(month: number, year: number) {
  let d = new Date(year, month, 1);
  let updatedDay = moment(d).add({});
  return d.getDay();
}

export function getLastDateOfTheMonth(month: number, year: number) {
  if (moment({ month }).date(31).month() === month) {
    return 31;
  } else if (moment({ month }).date(30).month() === month) {
    return 30;
  } else if (moment({ month }).date(29).month() === month) {
    return 29;
  } else {
    return 28;
  }
}

export function buildMonthDaysList(
  month: number,
  year: number,
  timezoneOffset: number
): any[] {
  // month starts from 0
  // debugger;
  let daysList: any = [];
  let d = new Date();
  let todate = new Date(
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset() + timezoneOffset / 60)
  );
  // debugger;
  let firstDay = getFirstDayOfTheMonth(month, year);
  let lastDate = getLastDateOfTheMonth(month, year);

  let noOfWeeks = 5;
  if ((lastDate === 31 && firstDay > 4) || (lastDate === 30 && firstDay > 5)) {
    noOfWeeks = 6;
  }
  let currentDate = 1;
  for (let i = 0; i < noOfWeeks; i++) {
    let temp = [];

    for (let j = 0; j < 7; j++) {
      let monthText = month + 1;
      let toDateMonthText = todate.getMonth() + 1;
      let currentFullDate =
        year +
        "-" +
        (monthText >= 10 ? monthText : "0" + monthText) +
        "-" +
        (currentDate >= 10 ? currentDate : "0" + currentDate);

      let toDateFullDate =
        todate.getFullYear() +
        "-" +
        (toDateMonthText >= 10 ? toDateMonthText : "0" + toDateMonthText) +
        "-" +
        (todate.getDate() >= 10 ? todate.getDate() : "0" + todate.getDate());
      if (i === 0) {
        // firstweek
        if (firstDay === j || firstDay < j) {
          // firstday found

          temp[j] = {
            fullDateString: currentFullDate,
            fullDateNumber: parseInt(currentFullDate.split("-").join("")),
            day: currentDate,
            month,
            year,
            monthText,
            toDateNumber: parseInt(toDateFullDate.split("-").join("")),
            toDateString: toDateFullDate,
            todate,
            isSelected: false,
          };
          currentDate = currentDate + 1;
        } else {
          temp[j] = null;
        }
      } else if (i === noOfWeeks - 1) {
        if (lastDate >= currentDate) {
          // firstday found

          temp[j] = {
            fullDateString: currentFullDate,
            fullDateNumber: parseInt(currentFullDate.split("-").join("")),
            toDateNumber: parseInt(toDateFullDate.split("-").join("")),
            toDateString: toDateFullDate,
            day: currentDate,
            todate,
            isSelected: false,
          };
          currentDate = currentDate + 1;
        } else {
          temp[j] = null;
        }
      } else {
        temp[j] = {
          fullDateString: currentFullDate,
          fullDateNumber: parseInt(currentFullDate.split("-").join("")),
          day: currentDate,
          toDateNumber: parseInt(toDateFullDate.split("-").join("")),
          toDateString: toDateFullDate,
          todate,
          isSelected: false,
        };
        currentDate = currentDate + 1;
      }
      // if (firstDay === j || lastDate === j || j < lastDate) {
      // } else {
      //   temp[j] = null;
      // }
    }
    daysList.push(temp);
  }

  return daysList;
}

export function buildMonthDaysListFlat(
  month: number,
  year: number,
  timezoneOffset: number
): any[] {
  // month starts from 0
  // debugger;
  let daysList: any = [];
  let d = new Date();
  let todate = new Date(
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset() + timezoneOffset / 60)
  );
  // debugger;
  let firstDay = getFirstDayOfTheMonth(month, year);
  let lastDate = getLastDateOfTheMonth(month, year);

  let noOfWeeks = 5;
  if ((lastDate === 31 && firstDay > 4) || (lastDate === 30 && firstDay > 5)) {
    noOfWeeks = 6;
  }
  let currentDate = 1;
  for (let i = 0; i < noOfWeeks; i++) {
    let temp = [];

    for (let j = 0; j < 7; j++) {
      let monthText = month + 1;
      let toDateMonthText = todate.getMonth() + 1;
      let currentFullDate =
        year +
        "/" +
        (monthText >= 10 ? monthText : "0" + monthText) +
        "/" +
        (currentDate >= 10 ? currentDate : "0" + currentDate);

      let toDateFullDate =
        todate.getFullYear() +
        "/" +
        (toDateMonthText >= 10 ? toDateMonthText : "0" + toDateMonthText) +
        "/" +
        (todate.getDate() >= 10 ? todate.getDate() : "0" + todate.getDate());
      if (i === 0) {
        // firstweek
        if (firstDay === j || firstDay < j) {
          // firstday found

          daysList.push({
            fullDateString: currentFullDate,
            fullDateNumber: parseInt(currentFullDate.split("/").join("")),
            day: currentDate,
          });
          currentDate = currentDate + 1;
        } else {
          daysList.push(null);
        }
      } else if (i === noOfWeeks - 1) {
        if (lastDate >= currentDate) {
          // firstday found

          daysList.push({
            fullDateString: currentFullDate,
            fullDateNumber: parseInt(currentFullDate.split("/").join("")),
            day: currentDate,
          });
          currentDate = currentDate + 1;
        } else {
          daysList.push(null);
        }
      } else {
        daysList.push({
          fullDateString: currentFullDate,
          fullDateNumber: parseInt(currentFullDate.split("/").join("")),
          day: currentDate,
        });
        currentDate = currentDate + 1;
      }
    }
  }

  return daysList;
}
