import { Box } from "@mui/material";
import { TextWithTooltip } from "components/CreativeAI_Revamp/shared";
import { StyledText } from "components/shared";
import React from "react";

export default function TargetingItem({
  title,
  subTitle,
}: {
  title: string;
  subTitle: string;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      pt="0.5em"
      flex={1}
      minWidth={0}
      pr="1em"
    >
      <StyledText
        fontSize="0.625em"
        fontWeight={600}
        lineHeight="0.75em"
        pb=".35em"
      >
        {title}
      </StyledText>
      <TextWithTooltip
        fontSize="0.625em"
        fontWeight={500}
        lineHeight="0.75em"
        color="#666666"
        text={subTitle}
      />
    </Box>
  );
}
