import moment from "moment";
import { call, put, takeEvery, takeLatest } from "@redux-saga/core/effects";
import HttpService from "../../../../HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "../../../../utils/path";
import { initialState } from "store/reducers/CreativeAI/GsaiPlayground";
import {
  GENERATE_DATA,
  GENERATE_DATA_SUCCEEDED,
  GENERATE_DATA_FAILED,
  GET_DATA,
  GET_DATA_SUCCEEDED,
  GET_DATA_FAILED,
  GET_BUNITS,
  GET_HEADLINES_AND_DESCRIPTIONS,
  UPDATE_GSAI_STORE,
  GET_KEYWORDS,
  GENERATE_RECOMMENDATIONS,
  GET_RECOMMENDATIONS,
  UPDATE_RECOMMENDATIONS,
  PUBLISH_AD,
  BUSINESSUNIT_LOADER,
  HEADLINEDES_LOADER,
  KEYWORD_LOADER,
  DESCRIPTION_LOADER,
  GET_CREATIVE_REPLACEMENT,
  GetAdGroups,
  GET_AD_GROUPS,
  CreateDraftAds,
  CREATE_DRAFT_ADS,
  UPDATE_DRAFT_ADS,
  DELETE_DRAFT_ADS,
  UpdateDraftAds,
  DeleteDraftAds,
  KEYWORD_FAILER,
} from "../../../types/CreativeAI/GsaiPlayground";
import type {
  GeneratData,
  GetData,
  GetBussinessUnits,
  GetHeadlinesAndDescription,
  GetKeywords,
  GeneratRecommendations,
  GetRecommendations,
  PublishAd,
  GetCreativeReplacement,
} from "../../../types/CreativeAI/GsaiPlayground";
const { v4: uuidv4 } = require("uuid");

function getFormattedData(data: any) {
  const results: any = [];
  for (const key in data) {
    if (
      data[key] &&
      data[key].status === "completed" &&
      data[key].generations?.length
    ) {
      data[key].generations.forEach((value: string) => {
        results.push({
          id: uuidv4(),
          value,
          type: key,
          position: "",
          pinnedField: "UNSPECIFIED",
        });
      });
    }
  }
  return results;
}

function formatHND(data: any) {
  const results: any = { headlines: [], descriptions: [] };
  for (const headline of data.headlines) {
    results.headlines.push({
      ...headline,
      id: uuidv4(),
      value: headline.headline,
      type: "",
      position: "",
      pinnedField:
        headline.headline_position === "--"
          ? "UNSPECIFIED"
          : headline.headline_position,
    });
  }
  for (const description of data.descriptions) {
    results.descriptions.push({
      ...description,
      id: uuidv4(),
      value: description.description,
      type: "",
      position: "",
      pinnedField:
        description.headline_position === "--"
          ? "UNSPECIFIED"
          : description.headline_position,
    });
  }
  return results;
}

function* generatData(action: GeneratData): any {
  let {
    payload: { user, settings, type },
  } = action;
  try {
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      `/v1/api/gsai/generate-${type}`,
      user,
      settings
    );
    yield put({
      type: GENERATE_DATA_SUCCEEDED,
      payload: {
        requestId: response.data.data.requestId || response.data.data.requestid,
        status: "polling",
        type,
      },
    });
  } catch (e: any) {
    yield put({
      type: GENERATE_DATA_FAILED,
      payload: { error: e.message, type },
    });
  }
}

function* getData(action: GetData): any {
  let {
    payload: { user, requestId, type },
  } = action;

  try {
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      `/v1/api/gsai/get-${type}/${requestId}`,
      user
    );
    const {
      data: {
        data: { result: data = [], error_desc = "", status = "" } = {},
      } = {},
    } = response;
    if (error_desc || status === "failed") {
      throw new Error(error_desc);
    }

    if (status === "completed") {
      yield put({
        type: GET_DATA_SUCCEEDED,
        payload: { status: "completed", data: getFormattedData(data), type },
      });
      if (type === "headlines") {
        yield put({
          type: HEADLINEDES_LOADER,
          payload: false,
        });
      }
      if (type === "descriptions") {
        yield put({
          type: DESCRIPTION_LOADER,
          payload: false,
        });
      }
    }
  } catch (e: any) {
    yield put({ type: GET_DATA_FAILED, payload: { error: e.message } });
  }
}

function* getBussinessUnits(action: GetBussinessUnits): any {
  let {
    payload: { user, brandId },
  } = action;

  try {
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      `/v1/api/gsai/${brandId}/businessunits`,
      user
    );
    const { data: { data: bUnits = [] } = {} } = response;
    yield put({
      type: UPDATE_GSAI_STORE,
      payload: { bUnits },
    });
    yield put({
      type: BUSINESSUNIT_LOADER,
      payload: false,
    });
  } catch (e: any) {
    yield put({
      type: UPDATE_GSAI_STORE,
      payload: { error: e.message },
    });
  }
}

function* getAdGroups(action: GetAdGroups): any {
  let {
    payload: { user, adaccountId, campaignId },
  } = action;

  try {
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      `/v1/api/gsai/${adaccountId}/${campaignId}/adgroups`,
      user
    );
    const { data: { data: adGroups = [] } = {} } = response;
    yield put({
      type: UPDATE_GSAI_STORE,
      payload: { adGroups: { isLoading: false, data: adGroups } },
    });
  } catch (e: any) {
    yield put({
      type: UPDATE_GSAI_STORE,
      payload: { error: e.message },
    });
  }
}

function* getHeadlinesAndDescription(action: GetHeadlinesAndDescription): any {
  let {
    payload: { user, adId, adaccountId, adgroupId },
  } = action;

  try {
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      `/v1/api/gsai/ad/get-headline-description?adaccountId=${adaccountId}&adId=${adId}&adgroupId=${adgroupId}`,
      user
    );
    const { data: { data: hnd = [] } = {} } = response;
    const formattedHnd = formatHND(hnd);
    yield put({
      type: UPDATE_GSAI_STORE,
      payload: {
        hnd: formattedHnd,
        existingHeadlines: formattedHnd.headlines,
        existingDescriptions: formattedHnd.descriptions,
      },
    });
  } catch (e: any) {
    yield put({
      type: UPDATE_GSAI_STORE,
      payload: { error: e.message },
    });
  }
}

function* getKeyWords(action: GetKeywords): any {
  let {
    payload: { user, adaccountId, adgroupId },
  } = action;

  try {
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      `/v1/api/gsai/${adaccountId}/${adgroupId}/keywords`,
      user
    );
    const { data: { data: keywords = [] } = {} } = response;
    yield put({
      type: UPDATE_GSAI_STORE,
      payload: { keywords },
    });
    if (keywords.length) {
      yield put({
        type: KEYWORD_LOADER,
        payload: false,
      });
    } else {
      yield put({
        type: KEYWORD_LOADER,
        payload: false,
      });
      yield put({
        type: KEYWORD_FAILER,
        payload: true,
      });
    }
  } catch (e: any) {
    yield put({
      type: UPDATE_GSAI_STORE,
      payload: { error: e.message },
    });
  }
}

function* generatRecommendation(action: GeneratRecommendations): any {
  let {
    payload: { user, payload },
  } = action;
  try {
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      "/v1/api/gsai/generate-recommendations",
      user,
      payload
    );
    yield put({
      type: UPDATE_RECOMMENDATIONS,
      payload: {
        state: {},
        recommendations: {
          requestId: response.data.data.requestId,
          status: "polling",
        },
      },
    });
  } catch (e: any) {
    yield put({
      type: UPDATE_RECOMMENDATIONS,
      payload: {
        state: { modal: "generate-recommendation-failed" },
        recommendations: { error: e.message, status: "failed" },
      },
    });
  }
}

function* getRecommendation(action: GetRecommendations): any {
  let {
    payload: { user, requestId, type },
  } = action;

  try {
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      `/v1/api/gsai/get-recommendations/${requestId}`,
      user
    );
    const {
      data: {
        data: { result: data = {}, error_desc = "", status = "" } = {},
      } = {},
    } = response;
    if (error_desc || status === "failed") {
      throw new Error(error_desc);
    }

    if (status === "completed") {
      yield put({
        type: UPDATE_RECOMMENDATIONS,
        payload: {
          state: {},
          recommendations: { status: "completed", data },
        },
      });
    }
  } catch (e: any) {
    yield put({
      type: UPDATE_RECOMMENDATIONS,
      payload: {
        state: { modal: "configure-ad" },
        recommendations: { error: e.message, status: "failed" },
      },
    });
  }
}

function* publishAd(action: PublishAd): any {
  const { user, payload } = action.payload;

  try {
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      "/v2/api/gsai/ad/publish",
      user,
      payload
    );

    // Throw error in case success is false
    if (!response.data.success) {
      throw new Error("Something went wrong");
    }

    yield put({
      type: UPDATE_GSAI_STORE,
      payload: {
        modal: "published-success",
      },
    });
  } catch (e: any) {
    yield put({
      type: UPDATE_GSAI_STORE,
      payload: {
        modal: "published-fail",
        error: e.message,
      },
    });
  }
}

function* getCreativeReplacement(action: GetCreativeReplacement): any {
  const { adId, user, dnd } = action.payload;

  try {
    const { data: { data: response = {} } = {} } = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      `/v1/api/gsai/creative-replacement/assets/${adId}`,
      user
    );

    const replacementConfig = formatReplacementConfig(response);

    dnd.headlines.replacements = response.headlines.map((item: any) => ({
      id: item.id,
      value: item.text,
      type: item.assetType,
      position: "",
      pinnedField: item.pinnedField,
    }));
    dnd.descriptions.replacements = response.descriptions.map((item: any) => ({
      id: item.id,
      value: item.text,
      type: item.assetType,
      position: "",
      pinnedField: item.pinnedField,
    }));

    yield put({
      type: UPDATE_GSAI_STORE,
      payload: {
        replacementConfig,
        dnd,
      },
    });
  } catch (e: any) {
    console.log(e);
  }
}

function* createDraftAds(action: CreateDraftAds): any {
  let {
    payload: { params, callback },
  } = action;
  try {
    const url = `/v1/api/gsai/draft/save`;
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      url,
      "",
      params
    );
    if (response.data.success) {
      callback && callback(response);
    } else {
      console.log("Create draft error");
    }
  } catch (error: any) {
    console.log("Create draft", error);
  }
}

function* updateDraftAds(action: UpdateDraftAds): any {
  let {
    payload: { id, params, callback },
  } = action;
  try {
    const url = `/v1/api/gsai/draft/${id}`;
    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      url,
      "",
      params
    );
    if (response.data.success) {
      callback && callback(response);
    } else {
      console.log("Update draft error");
    }
  } catch (error: any) {
    console.log("Update draft", error);
  }
}

function* deleteDraftAds(action: DeleteDraftAds): any {
  const { id, callback } = action.payload;

  try {
    const response = yield call(
      HttpService.delete,
      CROSS_PLATFORM_URL,
      `/v1/api/gsai/draft/${id}`,
      ""
    );
    if (response.data.success) {
      callback && callback();
    } else {
      console.log("Delete draft error");
    }
  } catch (e: any) {}
}

export function* GsaiPlaygroundWatcher() {
  yield takeEvery(GENERATE_DATA, generatData);
  yield takeEvery(GET_DATA, getData);
  yield takeEvery(GET_BUNITS, getBussinessUnits);
  yield takeEvery(GET_HEADLINES_AND_DESCRIPTIONS, getHeadlinesAndDescription);
  yield takeEvery(GET_KEYWORDS, getKeyWords);
  yield takeEvery(GENERATE_RECOMMENDATIONS, generatRecommendation);
  yield takeEvery(GET_RECOMMENDATIONS, getRecommendation);
  yield takeEvery(PUBLISH_AD, publishAd);
  yield takeEvery(GET_CREATIVE_REPLACEMENT, getCreativeReplacement);
  yield takeEvery(GET_AD_GROUPS, getAdGroups);
  yield takeEvery(CREATE_DRAFT_ADS, createDraftAds);
  yield takeEvery(UPDATE_DRAFT_ADS, updateDraftAds);
  yield takeEvery(DELETE_DRAFT_ADS, deleteDraftAds);
}

const performanceTypeMap: Record<string, string> = {
  LOW: "lowPerformance",
  GOOD: "goodPerformance",
  BEST: "bestPerformance",
};

function formatReplacementConfig(config: any) {
  const replacementConfig: any = initialState.replacementConfig;
  replacementConfig.isEnabled =
    config.headlines.length > 0 || config.descriptions.length > 0;

  for (let i = 0; i < config.headlineIntervals.length; i += 1) {
    const item = config.headlineIntervals[i];
    replacementConfig.headlines[performanceTypeMap[item.performance]] = {
      isChecked: true,
      value: item.interval,
    };
  }

  for (let i = 0; i < config.descriptionIntervals.length; i += 1) {
    const item = config.descriptionIntervals[i];
    replacementConfig.descriptions[performanceTypeMap[item.performance]] = {
      isChecked: true,
      value: item.interval,
    };
  }

  return replacementConfig;
}
