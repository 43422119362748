import { Link } from "react-router-dom";
import styled from "styled-components";
import { CheckBox } from "../../../shared";
import { Box } from "@mui/material";

interface ButtonFooterProp {
  content?: string;
}

// Brand Setting Styles
export const StyledCheckboxItem = styled(CheckBox)`
  input[type="checkbox"] {
    display: block;
    z-index: 9999;
  }
`;
export const StyledPlaceholderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 60vh;
  margin: 0 auto;
  img {
    display: block;
  }
`;

export const StyledCreateVariantModalContainer = styled.div`
  /* width: 692px; */
  // height: 600px;
  // height: 700px;
  overflow: auto;
  overflow-x: hidden;
`;
export const StyledRestoredDefault = styled.button`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #0869fb;
  margin-left: 12px;
  font-family: "Inter";
  font-style: normal;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  background: transparent;
  border: none;
  color: #0869fb;
`;
export const StyledCVButton = styled.div`
  .save-btn {
    padding: 16px 24px !important;
    color:#ffffff;
    margin-right:12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px!important;
    line-height: 24px;
`;
export const StyledButtonFooter = styled.div<ButtonFooterProp>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${(props) => props.content};
`;
export const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 692px;
  height: 700px;
`;
export const StyledCreateVariantSettingTxt = styled.p`
  margin-bottom: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #1c1e21;
`;
export const StyledForSpacing = styled.p`
  height: 20px;
`;
export const StyleAiGroupDuplicatePara = styled.p`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  color: #444444;
`;
export const StyledFooter = styled(Box)`
  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
  /* border-radius: 8px 8px 0px 0px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1em 1.25em;
`;
