import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./index.scss";

const MessageContainer: React.FC<{
  data: {
    title: string;
    description: string;
    icon: any;
  }[];
  submitText: string;
  handleNext: () => void;
}> = ({ data, submitText, handleNext }): JSX.Element => (
  <div className="d-flex flex-column p-3 bg-white flex-grow-1 message-container">
    <div
      className="d-flex flex-column align-items-center p-4 flex-grow-1 overflow-hidden"
      style={{ background: "#f2f2f2" }}
    >
      <Carousel autoPlay infiniteLoop swipeable transitionTime={1200} emulateTouch>
        {data.map(({ title, description, icon }) => (
          <div>
            <h4 className="font-weight-bold fs-10 m-0 w-auto mt-5">{title}</h4>
            <p className="text-center mt-4 px-5">
              <small dangerouslySetInnerHTML={{ __html: description }}></small>
            </p>
            <div className="my-3">{icon}</div>
          </div>
        ))}
      </Carousel>
    </div>
    <div className="d-flex justify-content-center mt-4">
      <button
        className="text-white px-4 py-2 rounded border-0"
        style={{ background: "#0869FB" }}
        onClick={handleNext}
      >
        {submitText}
      </button>
    </div>
  </div>
);

export default MessageContainer;
