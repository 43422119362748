import { Box, styled } from "@mui/system";

export const StyledSummaryBoardCard = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1em 1.125em 1.75em 1.125em;
  margin: 0.5em 0.725em 0.725em 0.725em;
`;

export const StyledTitle = styled(Box)`
  color: #000000;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 1.375em;
  padding-bottom: 0.6em;
`;

export const StyledTitleVariant2 = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.1875em;
  color: #454256;
  padding-bottom: 0.6em;
`;

export const CustomizedDivider = styled(Box)`
  border: 0.5px solid #d6e2e9;
  margin-top: 0.25em;
  margin-bottom: 1.25em;
`;

export const StyledGainLoss = styled(Box)`
  color: ${({
    isZero,
    loss,
    growthFlag,
  }: {
    isZero: boolean | undefined;
    loss: boolean;
    growthFlag: string | undefined;
  }) =>
    isZero
      ? "#000"
      : growthFlag
      ? growthFlag === "Positive"
        ? !loss
          ? "#0EB8A3"
          : "#F15A5A"
        : !loss
        ? "#F15A5A"
        : "#0EB8A3"
      : !loss
      ? "#0EB8A3"
      : "#F15A5A"};
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: ${({
    isPerformance,
  }: {
    isZero: boolean | undefined;
    loss: boolean;
    isPerformance: boolean | undefined;
    growthFlag: string | undefined;
  }) => (isPerformance ? "0.75em" : "0.875em")};
  line-height: ${({
    isPerformance,
  }: {
    isZero: boolean | undefined;
    loss: boolean;
    isPerformance: boolean | undefined;
    growthFlag: string | undefined;
  }) => (isPerformance ? "0.9075em" : "1.0625em")};
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;
