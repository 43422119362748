import { Box, CircularProgress } from "@mui/material";
import {
  generateConfigurePayload,
  getFormValues,
} from "components/CreativeAI/Facebook/utils/form.utils";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfigurationState,
  updateRecommendationMetadata,
  updateRecommendationSelectionState,
} from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  postMediaStudioProcessing,
  setFrameVGSData,
} from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import { AdAccountState } from "store/types/adaccount";
import { initialState } from "store/types/auth";
import {
  CreateVariantsState,
  GENERATE_VARIANT_METADATA_STATE_VARIANT,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
  VideoLayerType,
  VideoRecommendationItem,
} from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import {
  InitialMediaStudioType,
  RESTORE_INITIAL_MEDIA,
} from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { useVGSPollingHook } from "../../hooks/useVgsPollingHook";
import NoRecommendations from "../../Recommendations/NoRecommendations";
import {
  getTimeStamp,
  imageMediaProcessingPayloadMapping,
} from "../shared/mediaCommon.utils";
import PaginationComponent from "../shared/PaginationComponent/PaginationComponent";
import { StyledGenerationButton } from "../shared/PaginationComponent/styled";
import EditableInput from "./EditableInput";
import PreviewVideoItem from "./PreviewVideoItem";
import RecommendationVideoImageComponent from "./RecommendationVideoImageComponent";
import {
  StyledItalicText,
  StyledVideoRecommendationContainer,
  StyledVideoRecommendationItemContainer,
} from "./styeld";
import { mapAdAdSetToElement } from "components/CreativeAI/Facebook/utils/common.utils";
import { APIGenerationType } from "components/CreativeAI_Revamp/Facebook/facebook.types";
import { filterVariantData } from "components/CreativeAI/Facebook/utils/filterLevers.utils";
import { SuccessLoader } from "../shared/Loader/Loader";
import { StyledMediaLoader } from "../../styled";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { useCaiFbMixPanel } from "components/CreativeAI_Revamp/Facebook/HomeScreen/hooks/useCaiFbMixPanel";

export default function VideoTextComponent({
  toggleDirty,
}: {
  toggleDirty: any;
}) {
  const [showPagination, setShowPagination] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [recommendationLoading, setRecommendationLoading] = useState(false);
  const [editContext, setEditContext] = useState({
    id: "",
    iteration: -1,
  });
  const {
    selectedAd,
    recommendationMetaData,
    recommendationSelectionState: { video },
    extraFields: { selectedVariantIndex },
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  let noOfBeforeBlanks = 0;
  let noOfTotalElement =
    recommendationMetaData?.video?.data?.[video]?.noOfElements;
  const { getVGSProcessingResponse } = useVGSPollingHook();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { BUSINESS_UNIT_BY_ID_DATA, LEVERS } = useSelector((state: any) => ({
    BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
    LEVERS: state.MainReducer.defaultLevers,
  }));
  const { CREATIVE_ELEMENT_AI } = useSelector(
    (state: { selectCohortReducer: any; MainReducer: any }) => ({
      CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi,
    })
  );
  const { adAccountsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const { mixPanelSeeMoreRecommendation } = useCaiFbMixPanel();
  const dispatch = useDispatch();

  const onPaginationClick = (index: number) => {
    dispatch(
      updateRecommendationSelectionState({
        video: index - 1,
        isChanged: true,
      })
    );
    toggleDirty(true);
  };

  const {
    getMediaStudioData,
    videoFrameRelatedData,
    frameVGSReqId,
    videoVGSFrame,
  } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;
  const onSave = (index: number, value: string, frameIndex: number) => {
    const localRecommendationData = {
      ...recommendationMetaData,
      isEditing: true,
      isVideoImageEdit: true,
    };
    localRecommendationData.video.data[video].data[frameIndex][
      index
    ].layer_text = value;
    dispatch(
      updateRecommendationMetadata({
        data: localRecommendationData,
      })
    );
    dispatch(
      setFrameVGSData({
        data: {
          isEditing: true,
        },
        index: selectedImage,
      })
    );
    dispatch(
      updateRecommendationSelectionState({
        isChanged: true,
      })
    );
    toggleDirty(true);
  };

  const onImageClick = (index: number) => {
    setSelectedImage(index);
    if (!videoVGSFrame[index]?.vgsPreviewUrl?.url) {
      dispatch(
        setFrameVGSData({
          data: {
            isEditing: true,
          },
          index,
        })
      );
    }
  };

  useEffect(() => {
    dispatch({
      type: RESTORE_INITIAL_MEDIA,
      payload: {
        frameVGSReqIdLoading: false,
        frameVGSReqIdFailure: false,
      },
    });
    if (
      (recommendationMetaData?.video &&
        !videoVGSFrame[selectedImage] &&
        !videoVGSFrame[selectedImage]?.vgsPreviewUrl?.url) ||
      videoVGSFrame[selectedImage]?.isEditing
    ) {
      const variations = imageMediaProcessingPayloadMapping(
        recommendationMetaData?.video?.data?.[video]?.data[selectedImage]
      );

      dispatch(
        postMediaStudioProcessing({
          adId: selectedAd?.id,
          failureReduxType: "GET_FRAME_VGS_REQUEST_ID_FAILURE",
          reduxActionType: "GET_FRAME_VGS_REQUEST_ID_LOADER",
          reduxStoreType: "GET_FRAME_VGS_REQUEST_ID_REDUX",
          frameIndex: selectedImage,
          variations,
        })
      );
    }
  }, [videoVGSFrame]);

  // useEffect(() => {
  //   if (frameVGSReqId.length > 0) {
  //     getVGSProcessingResponse(
  //       {
  //         failureReduxType: "GET_FRAME_VGS_REQUEST_ID_FAILURE",
  //         reduxActionType: "GET_FRAME_VGS_REQUEST_ID_LOADER",
  //         storeReduxType: "SET_FRAME_THUMBNAIL_VGS_DATA_REDUX",
  //         timerRedux: "SET_IMAGE_VGS_TIMER",
  //         reqId: frameVGSReqId,
  //       },
  //       +new Date()
  //     );
  //   }
  // }, [frameVGSReqId]);

  useEffect(() => {
    setSelectedImage(0);
    if (Object.keys(videoVGSFrame).length > 0) {
      dispatch({
        type: RESTORE_INITIAL_MEDIA,
        payload: {
          videoVGSFrame: {},
        },
      });
    }
  }, [video, selectedVariantIndex]);

  // lever filteration
  const leverData: APIGenerationType[] = useMemo(
    () =>
      filterVariantData(LEVERS, [
        "user",
        "ad",
        "adset",
        "campaign",
        "ad_video",
        "ad_image",
      ]),
    [LEVERS]
  );

  useEffect(() => {
    if (!recommendationMetaData?.video) {
      setRecommendationLoading(true);
      dispatch(setConfigurationState(true));
      const createSpecAPI = selectedAd?.adcreatives?.data[0];
      let creativeElementData = { ...CREATIVE_ELEMENT_AI.data };
      const newLeverData = leverData.filter(
        (item: any) => item.generation_type !== "image"
      );
      const { newCreativeElementAI } = mapAdAdSetToElement({
        createSpec: createSpecAPI,
        targeting: {},
        creativeElementAI: creativeElementData,
        levers: newLeverData,
        getMediaStudioData,
      });

      const payload = generateConfigurePayload(
        {
          ...getFormValues(newCreativeElementAI, true),
          businessUnit: BUSINESS_UNIT_BY_ID_DATA?.businessUnit,
          businessUnitDetail: BUSINESS_UNIT_BY_ID_DATA?.businessUnitDetail,
        },
        LEVERS,
        "generate",
        "link_data",
        selectedBrand?.id,
        selectedAd?.id,
        adAccountsSelection?.selectedAdAccountId
      );

      // keeping this for debugging
      console.log("payload", payload, newCreativeElementAI, newLeverData);

      delete payload.input_data?.description;
      delete payload.input_data?.headline;
      delete payload.input_data?.primary_text;
      delete payload.input_data?.image;

      dispatch({
        type: "GENERATE_VARIANT_METADATA",
        payload: {
          user: auth.user,
          data: payload,

          finalAction: GENERATE_VARIANT_METADATA_STATE_VARIANT,
        },
        callback: (response: any) => {
          if (!videoVGSFrame[selectedImage]?.vgsPreviewUrl?.url) {
            dispatch(
              setFrameVGSData({
                data: {
                  isEditing: true,
                },
                selectedImage,
              })
            );
          }
          if (response?.error) {
            setRecommendationLoading(false);
          }
        },
      });
    } else {
      setRecommendationLoading(false);
    }
  }, [recommendationMetaData?.video]);

  if (recommendationLoading) {
    return (
      <>
        <StyledMediaLoader id="load_editor"></StyledMediaLoader>
        <Box
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SuccessLoader />
        </Box>
      </>
    );
  }

  return recommendationMetaData?.video?.data ? (
    <Box display="flex" flexDirection="column">
      <RecommendationVideoImageComponent
        url={videoVGSFrame[selectedImage]?.vgsPreviewUrl?.url ?? ""}
      />
      <StyledItalicText pt="16px">
        <span
          style={{
            color: "#FF3333",
          }}
        >
          *
        </span>{" "}
        Select a frame to preview the updated text
      </StyledItalicText>

      <StyledVideoRecommendationContainer mt="10px">
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            position: "relative",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: "0px",
              top: "0px",
              zIndex: "1",
              // background: "red",
              width: "100%",
              height: "30vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box mt="10px" />
              <Box
                sx={{
                  background: "#E9E9E9",
                  borderRadius: "6px",
                  height: "63.6px",
                }}
                mb="12px"
                display="flex"
                alignItems="center"
              ></Box>
              {Array.from(Array(noOfTotalElement), (e, i) => {
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                      flex: "100%",
                    }}
                  >
                    <Box
                      key={i}
                      sx={{
                        background: "#FFFFFF",
                        margin: "4px 0px",
                        height: "32px",
                        borderRadius: "6px",
                      }}
                    />

                    {i + 1 === editContext.iteration && (
                      <Box
                        sx={{
                          padding: "12px",
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: "0px",
              top: "0px",
              zIndex: "999",
              // background: "red",
              width: "100%",
              height: "30vh",
            }}
          >
            <Box mt="10px" />
            <StyledVideoRecommendationItemContainer>
              {recommendationMetaData.video.data &&
                recommendationMetaData.video.data[video].data.map(
                  (item: VideoRecommendationItem, index: number) => {
                    let iteration = 0;
                    const showDot = noOfBeforeBlanks;
                    const blankArray = Array.from(
                      {
                        length: noOfBeforeBlanks,
                      },
                      function () {
                        return { isBlank: true };
                      }
                    );
                    noOfBeforeBlanks = noOfBeforeBlanks + item.length;
                    const blankArrayAfter = Array.from(
                      {
                        length: noOfTotalElement - noOfBeforeBlanks,
                      },
                      function () {
                        return { isBlank: true };
                      }
                    );
                    const timeFrame =
                      getMediaStudioData?.data?.frames[index].timeFrame;
                    const layers =
                      getMediaStudioData?.data?.frames[index].layers;
                    return (
                      <Box position="relative">
                        <Box
                          sx={{
                            position: "absolute",
                            border: "1px dashed #2BB3F4",
                            width: "1px",
                            height:
                              editContext.iteration >= 0 && index >= 0
                                ? 24 + 7 + 40 * showDot + "px"
                                : 7 + 40 * showDot + "px",
                            top: "68px",
                            left: "52px",
                          }}
                        />
                        <Box display="flex" flexDirection="column">
                          <Box
                            sx={{
                              background: "#E9E9E9",
                              paddingLeft: index === 0 ? "10px" : "0px",
                              borderRadius:
                                index === 0
                                  ? "6px 0px 0px 6px"
                                  : index ===
                                    recommendationMetaData.video.data[video]
                                      .data.length -
                                      1
                                  ? "0px 6px 6px 0px"
                                  : "0px",
                            }}
                            mb="12px"
                            display="flex"
                            alignItems="center"
                          >
                            <PreviewVideoItem
                              key={index}
                              selected={index === selectedImage}
                              onClick={onImageClick}
                              index={index}
                              timeStamp={getTimeStamp(timeFrame)}
                              thumbnailData={videoFrameRelatedData[index]}
                            />
                            {index !==
                              recommendationMetaData.video.data[video].data
                                .length -
                                1 && (
                              <Box
                                display="flex"
                                flex={1}
                                alignItems="center"
                                p="0px 3px"
                              >
                                <Box
                                  sx={{
                                    color: "#C9C9C9",
                                    fontSize: "13px",
                                  }}
                                >
                                  {"<"}
                                </Box>
                                <Box
                                  flex={1}
                                  sx={{
                                    border: "1px dashed #C9C9C9",
                                    height: "1px",
                                  }}
                                />
                                <Box
                                  sx={{
                                    color: "#C9C9C9",
                                    fontSize: "13px",
                                  }}
                                >
                                  {">"}
                                </Box>
                              </Box>
                            )}
                          </Box>
                          {blankArray.map((_) => {
                            iteration += 1;
                            return (
                              <Box
                                display="flex"
                                flexDirection="column"
                                sx={{
                                  flex: "100%",
                                }}
                              >
                                {" "}
                                <Box
                                  sx={{
                                    background: "#FFFFFF",
                                    paddingLeft: index === 0 ? "10px" : "0px",
                                    margin: "4px 0px",
                                    height: "32px",
                                    borderRadius:
                                      index === 0
                                        ? "6px 0px 0px 6px"
                                        : index ===
                                          recommendationMetaData.video.data[
                                            video
                                          ].data.length -
                                            1
                                        ? "0px 6px 6px 0px"
                                        : "0px",
                                  }}
                                />
                                {iteration === editContext.iteration && (
                                  <Box
                                    sx={{
                                      padding: "12px",
                                    }}
                                  />
                                )}
                              </Box>
                            );
                          })}
                          {item.map(
                            (item: VideoLayerType, layerIndex: number) => {
                              iteration += 1;

                              return (
                                <EditableInput
                                  isFirst={index === 0}
                                  isLast={
                                    index ===
                                    recommendationMetaData.video.data[video]
                                      .data.length -
                                      1
                                  }
                                  index={layerIndex}
                                  value={item.layer_text}
                                  key={layerIndex}
                                  frameIndex={index}
                                  onSave={onSave}
                                  onImageClick={onImageClick}
                                  min={
                                    layers
                                      ? layers[layerIndex].minCharacters
                                      : 0
                                  }
                                  max={
                                    layers
                                      ? layers[layerIndex].maxCharacters
                                      : 20
                                  }
                                  disabled={
                                    editContext.id.length > 0 &&
                                    editContext.id !==
                                      `frame${index}layer${layerIndex}`
                                  }
                                  setEditContext={setEditContext}
                                  id={`frame${index}layer${layerIndex}`}
                                  iteration={iteration}
                                />
                              );
                            }
                          )}

                          {blankArrayAfter.map((_) => {
                            iteration += 1;

                            return (
                              <Box display="flex" flexDirection="column">
                                <Box
                                  sx={{
                                    background: "#FFFFFF",
                                    paddingLeft: index === 0 ? "10px" : "0px",
                                    margin: "4px 0px",
                                    height: "32px",
                                    borderRadius:
                                      index === 0
                                        ? "6px 0px 0px 6px"
                                        : index ===
                                          recommendationMetaData.video.data[
                                            video
                                          ].data.length -
                                            1
                                        ? "0px 6px 6px 0px"
                                        : "0px",
                                  }}
                                />
                                {iteration === editContext.iteration && (
                                  <Box
                                    sx={{
                                      padding: "12px",
                                    }}
                                  />
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  }
                )}
            </StyledVideoRecommendationItemContainer>
          </Box>
        </Box>
        {!showPagination ? (
          <Box display="flex" justifyContent="center" pt="1rem">
            <StyledGenerationButton
              variant="contained"
              onClick={() => {
                setShowPagination(true);
                mixPanelSeeMoreRecommendation();
              }}
            >
              Generate More Recommendations
            </StyledGenerationButton>
          </Box>
        ) : (
          <PaginationComponent
            length={recommendationMetaData.video.data.length}
            onClick={onPaginationClick}
            currentPage={Number(video) + 1}
          />
        )}
      </StyledVideoRecommendationContainer>
    </Box>
  ) : (
    <NoRecommendations />
  );
}
