import { Tabs, Tab } from "@mui/material";

function SelectableTabs({
  selectedTabs,
  setSelectedTabs,
}: {
  selectedTabs: string[];
  setSelectedTabs: Function;
}) {
  const handleTabClick = (tabIndex: string) => {
    if (selectedTabs.includes(tabIndex)) {
      if (selectedTabs.length > 1)
        setSelectedTabs(selectedTabs.filter((tab) => tab !== tabIndex));
    } else {
      setSelectedTabs([...selectedTabs, tabIndex]);
    }
  };

  return (
    <Tabs
      variant="standard"
      sx={{
        "& button": {
          minHeight: "0px",
          px: 0,
          py: 1,
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "0.625rem",
          borderRadius: "6px",
        },
      }}
    >
      <Tab
        label="ICP"
        disableRipple
        sx={{
          border: selectedTabs.includes("ICP")
            ? "2px solid #0869FB"
            : "1px solid #EAE0FE",
          mr: 2,
        }}
        onClick={() => handleTabClick("ICP")}
        value={"ICP"}
      />
      <Tab
        label="Interest"
        sx={{
          textTransform: "none",
          border: selectedTabs.includes("INTEREST")
            ? "2px solid #0869FB"
            : "1px solid #FFDFC6",
        }}
        disableRipple
        onClick={() => handleTabClick("INTEREST")}
        value={"INTEREST"}
      />
    </Tabs>
  );
}

export default SelectableTabs;
