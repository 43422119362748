import { AdAccountsType, AdSetItemType, AdsItemType } from "../facebook.types";

export const SET_AD_ACCOUNTS_SELECTION = "SET_AD_ACCOUNTS_SELECTION";
export const Set_ADSET_SELECTION = "Set_ADSET_SELECTION";
export const SET_ADS_SELECTION = "SET_ADS_SELECTION";
export const SET_AD_ACCOUNT_DETAILS = "SET_AD_ACCOUNT_DETAILS";

export const SET_AD_ADDITIONAL_INFO = "SET_AD_ADDITIONAL_INFO";
export const SET_AD_ADDITIONAL_INFO_RESET = "SET_AD_ADDITIONAL_INFO_RESET";

export interface SetAdAccountsSelection {
  type: typeof SET_AD_ACCOUNTS_SELECTION;
  payload: SetAdAccountsSelectionPayload;
}

export interface SetAdSetSelection {
  type: typeof Set_ADSET_SELECTION;
  payload: SetAdSetSelectionPayload;
}

export interface SetAdsSelection {
  type: typeof SET_ADS_SELECTION;
  payload: SetAdsSelectionPayload;
}

export interface SetAdAccountsSelectionPayload {
  selection?: AdAccountsType;
  selectedAdAccountId: string;
  selectedElementId: string;
}

export interface SetAdSetSelectionPayload {
  selection?: AdSetItemType;
  selectedAdSetId: string;
}

export interface SetAdsSelectionPayload {
  selectedAds: AdsItemType[];
}

export interface SetAdAccountDetails {
  type: typeof SET_AD_ACCOUNT_DETAILS;
  payload: any;
}
export interface FBHomeScreenInitialStateType {
  adAccountsSelection?: SetAdAccountsSelectionPayload;
  adSetSelection?: SetAdSetSelectionPayload;
  adsSelection: SetAdsSelectionPayload;
  adAccountDetails?: any;
  adAdditionalInfo: AdAdditionalInfo;
}

// additional info types
export interface AdAdditionalInfo {
  [key: string]: AdInfoItemType;
}

export interface AdInfoItemType {
  avatarName?: string;
  avatarUrl?: string;
  videoUrl?: string;
  image_url?: string;
}

export interface AdInfoPayloadType {
  adId: string;
  adInfo: AdInfoItemType;
}

export interface SetAdAdditionalInfo {
  type: typeof SET_AD_ADDITIONAL_INFO;
  payload: AdInfoPayloadType;
}

export interface SetAdAdditionalInfoReset {
  type: typeof SET_AD_ADDITIONAL_INFO_RESET;
  payload: any;
}
