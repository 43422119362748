import { Fragment } from "react";
import DateRangePicker from "./DateRangePicker";
import { Box, Stack, Typography } from "@mui/material";
import MultipleSelectCheckbox from "../../../../common/MultipleSelectCheckbox";
import SelectableTabs from "Pages/AudienceManager/components/SelectableTabs";
interface FilterDropdownProps {
  optionsList: any[];
  selectedTabs: string[];
  setSelectedTabs: (param: string[]) => void;
}

const Filter: React.FC<FilterDropdownProps> = ({
  optionsList,
  selectedTabs,
  setSelectedTabs,
}) => {
  const inputHandler = (type: string, props: any) => {
    switch (type) {
      case "Dropdown":
        return (
          <Box key={props.heading}>
            <Stack>
              {props.heading && (
                <Typography sx={{ color: "#000" }} className="font-10 font-500">
                  {props.heading}
                </Typography>
              )}
              {props.subHeading && (
                <Typography sx={{ color: "#999" }} className="font-8 font-500">
                  {props.subHeading}
                </Typography>
              )}

              <MultipleSelectCheckbox
                items={props.items}
                selectedItems={props.selectedItems}
                handleChange={(e: any) => props.setSelectedItem(e.target.value)}
                sx={{
                  my: 1,
                  p: 0,
                  "& .MuiSelect-select": {
                    p: 0,
                    px: 1,
                    py: 0.5,
                    fontSize: "0.625rem",
                    color: "#999",
                  },
                }}
              />
            </Stack>
          </Box>
        );
      case "DatePicker":
        return <DateRangePicker {...props} />;
    }
  };
  return (
    <Box className="m-auto w-224 max-height-208">
      {optionsList.map((item) => (
        <Fragment key={item.heading}>{inputHandler(item.type, item)}</Fragment>
      ))}
      <Typography
        sx={{
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "0.625rem",
          mb: 1,
        }}
      >
        Type of Cohorts
      </Typography>
      <SelectableTabs
        selectedTabs={selectedTabs}
        setSelectedTabs={setSelectedTabs}
      />
    </Box>
  );
};
export default Filter;
