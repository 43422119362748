import React from "react";
import Chip from "@mui/material/Chip";

interface ICustomChipProps {
  label: string;
  color?: string;
  bgColor?: string;
  margin?: string;
}

function CustomChip(props: ICustomChipProps) {
  const { label, color = "#000", bgColor = "#fff", margin = "0.1rem" } = props;
  return (
    <Chip
      title={label}
      size="small"
      label={label}
      sx={{
        backgroundColor: bgColor,
        fontSize: "10px",
        fontWeight: "400",
        margin: margin,
        "& .MuiChip-label": {
          color: { color },
        },
      }}
    />
  );
}

export default CustomChip;
