import React, { useState, useRef } from "react";
import { InputContainer } from "./styles.ConfigureAd";
import EditIcon from "./Edit";

type Props = {
  className?: string;
  label?: string;
  size?: "large" | "regular";
  placeholder?: string;
  type?: "textarea" | "input";
  name: string;
  register: any;
  error?: any;
  isEditbutton?: boolean;
};

function Input({
  className = "",
  label,
  size = "regular",
  placeholder = "",
  type = "input",
  name,
  register,
  error,
  isEditbutton = true,
}: Props) {
  const input = useRef<HTMLDivElement>(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const { onChange, onBlur, ref } = register(name);

  function handleBlur(...rest: any) {
    onBlur(...rest);
    setDisabled(true);
  }

  return (
    <InputContainer className={className} size={size}>
      {label ? <label>{label}</label> : null}
      <div ref={input}>
        {type === "input" ? (
          <input
            onChange={onChange}
            onBlur={handleBlur}
            name={name}
            ref={ref}
            placeholder={placeholder}
            disabled={disabled}
          />
        ) : (
          <textarea
            onChange={onChange}
            onBlur={handleBlur}
            name={name}
            ref={ref}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
        {isEditbutton && (
          <button
            type="button"
            onClick={() => {
              setDisabled(false);
              setTimeout(() => {
                if (input.current?.children?.length) {
                  (input.current.children[0] as HTMLInputElement).focus();
                }
              }, 0);
            }}
          >
            <EditIcon />
          </button>
        )}
      </div>
      <span className="error">{error ? error.message : null}</span>
    </InputContainer>
  );
}

export default Input;
