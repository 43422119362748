import React from 'react'
import ModalContainer from '../../../components/Modal/ModalContainer'
import { ReactComponent as MessageIcon } from '../../../assets/svg/message-Icon.svg'

const nextBtn: Record<string, string> = {
  google: 'Next',
  linkedin: 'Confirm',
  facebook: 'Confirm',
  tiktok: 'Confirm'
}

const getFooterComponent = (onNextClick: () => void, platform: string) => {
  return () => {
    return (
      <div className='modal-footer d-flex justify-content-end'>
        <button
          type='button'
          className='modal-button primary'
          onClick={onNextClick}
        >
          {nextBtn[platform]}
        </button>
      </div>
    )
  }
}

function EmailModal({
  platform,
  onNextClick
}: {
  platform: string
  onNextClick: () => void
}): JSX.Element {
  if (platform === 'facebook') {
    return (
      <ModalContainer
        title='Add to Business Manager'
        width='469px'
        footerComponent={getFooterComponent(onNextClick, platform)}
      >
        <div className='flex-center'>
          <p className='m-0 confirm-text'>
            These Ad Accounts would be added to our Business Manager.
            <br />
            Please confirm to proceed with linking these accounts.
          </p>
        </div>
      </ModalContainer>
    )
  }
  if (platform === 'linkedin') {
    return (
      <ModalContainer
        title='Add user'
        width='469px'
        footerComponent={getFooterComponent(onNextClick, platform)}
      >
        <div className='flex-center'>
          <p className='m-0 confirm-text'>
            Pixis user needs to be added to act as a fallback mechanism for the
            system. Please confirm to proceed with linking these accounts.
          </p>
        </div>
      </ModalContainer>
    )
  }

  return (
    <ModalContainer
      title='Add to Manager Account'
      width='469px'
      footerComponent={getFooterComponent(onNextClick, platform)}
    >
      <div className='flex-center'>
        <div className='message-icon-bg p-3 mr-3'>
          <MessageIcon />
        </div>
        <p className='m-0 confirm-text'>
          Please check your email and accept the connection request from our
          Manager Account to complete the linking.
        </p>
      </div>
    </ModalContainer>
  )
}

export default EmailModal
