import {
  IGetRecommendedCohortsPayload,
  IGetRecommendedChannelCohorts,
  ISetRecommendedAudienceCohortsData,
  ISetRecommendedChannelCohortsData,
  ISetRecommendedVideosCohortsData,
  SET_YOUTUBE_RECOMMENDED_VIDEO_COHORTS,
  SET_YOUTUBE_RECOMMENDED_CHANNEL_COHORTS,
  SET_YOUTUBE_RECOMMENDED_AUDIENCE_COHORTS,
  GET_YOUTUBE_RECOMMENDED_COHORTS,
  UPLOAD_YOUTUBE_DATA,
  SET_YOUTUBE_LOADING,
  DELETE_YOUTUBE_COHORTS,
} from "../../types/AudienceManager/youtube";

export const getRecommendedCohorts = (
  payload: IGetRecommendedCohortsPayload
): IGetRecommendedChannelCohorts => ({
  type: GET_YOUTUBE_RECOMMENDED_COHORTS,
  payload,
});

export const uploadYoutubeFile = (payload: any, callback: Function): any => ({
  type: UPLOAD_YOUTUBE_DATA,
  payload: {
    ...payload,
    callback,
  },
});

export const setRecommendedAudienceCohorts = (
  payload: ISetRecommendedAudienceCohortsData[]
): any => ({
  type: SET_YOUTUBE_RECOMMENDED_AUDIENCE_COHORTS,
  payload,
});

export const setRecommendedChannelCohorts = (
  payload: ISetRecommendedChannelCohortsData[]
): any => ({
  type: SET_YOUTUBE_RECOMMENDED_CHANNEL_COHORTS,
  payload,
});

export const setRecommendedVideosCohorts = (
  payload: ISetRecommendedVideosCohortsData[]
): any => ({
  type: SET_YOUTUBE_RECOMMENDED_VIDEO_COHORTS,
  payload,
});

export const setYoutubeLoading = (payload: boolean): any => ({
  type: SET_YOUTUBE_LOADING,
  payload,
});
export const deleteYoutubeCohorts = (
  payload: any,
  callback: Function
): any => ({
  type: DELETE_YOUTUBE_COHORTS,
  payload,
  callback,
});
