import React, { useCallback } from "react";
import videoPlay from "../../assets/png/videoPlay.png";
import "./index.scss";
import markImage from "../../assets/png/markImage.png";
import ViewVideo from "../ViewVideo/ViewVideo";

const VideoCard: React.FC<{
  content: any;
  handelOnChange: (value: any) => void;
  activeValue: any;
}> = ({ content, handelOnChange, activeValue }) => {
  const [showVideo, setShowVideo] = React.useState(false);
  const [videoData, setVideoData] = React.useState({});

  const handleChange = useCallback(
    (value: any) => {
      handelOnChange(value);
    },
    [handelOnChange]
  );

  const handelViewVideo = useCallback(
    (item: any) => {
      setShowVideo(!showVideo);
      setVideoData(item);
    },
    [showVideo]
  );

  return (
    <div>
      <div className="image-video-card-container">
        <div className="row">
          {content &&
            content?.length !== 0 &&
            content?.map((item: any) => {
              return (
                <div
                  // className="image-video-content col-lg-2"
                  className={
                    activeValue == item?.name
                      ? "image-video-content-selected col-lg-2"
                      : "image-video-content col-lg-2"
                  }
                  onClick={() => handleChange(item?.name)}
                >
                  <div className="image-video-content-div">
                    {activeValue == item?.name && (
                      <img
                        src={markImage}
                        alt=""
                        className="image-video-mark"
                      />
                    )}
                    <video
                      // controls
                      // className="image-video-card-file"
                      className={
                        activeValue == item?.name
                          ? "image-video-card-file-selected"
                          : "image-video-card-file"
                      }
                      src={item?.video}
                    >
                      <source />
                    </video>
                    <span
                      className="video-play-btn"
                      onClick={() => handelViewVideo(item)}
                    >
                      <img src={videoPlay} alt="" />{" "}
                    </span>
                  </div>
                  {/* <p className="mt-2 mb-2 image-video-para">{item?.name}</p> */}
                  <p className="image-video-para" title={item?.name}>{item?.name}</p>
                </div>
              );
            })}
        </div>
      </div>
      {showVideo && (
        <ViewVideo
          video={videoData}
          cancelText={"Close"}
          setShowVideo={setShowVideo}
          fileType="url"
        />
      )}
    </div>
  );
};

export default VideoCard;
