import {
  Box,
  Card,
  Pagination,
  Switch,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import styled from "styled-components";
export const StyledTableCellInfo = styled(TableCell)`
  font-size: 14px;
  min-width: 150px;
  border: 1px solid #dadcde;
`;

export const StyledTableRow = styled(TableRow)`
  th {
    font-size: 18px;
    font-weight: 900;
  }
`;
export const StyledTableHeadInfo = styled(TableCell)`
  border: 1px solid #dadcde;
  font-size: 18px;
  font-weight: 900;
`;
export const StyledDateRangeContainer = styled(Box)`
  position: relative;
  height: 100%;
  top: -10px;
  background-color: #f2f2f2;
  font-size: 14px;
  padding: 8px 12px;
  text-align: center;
  border-radius: 4px;
  .daterangeSelector {
    z-index: 9999999999999999;
    position: absolute;
    padding: 20px;
    top: 40px;
    /* transform: scale(1.4); */
    right: 20px;
    border: 1px solid black;
    background-color: white;
    font-size: 14px;
  }
  .addRange {
  }
`;
export const StyledDatePara = styled(Typography)`
  font-family: "Inter" !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #000000 !important;
  font-style: normal !important;
  font-weight: 400 !important;
`;

export const StyledTable = styled(Table)`
  font-size: 16px;
  border-radius: 10px 10px 0px 0px;

  .tableDataRow {
    height: 110px;
    border-right: #dadcde;
  }

  .tableDataRow:nth-child(even) {
    background-color: #f5f6f7;
  }
`;

export const StyledGreenOnline = styled.span`
  width: 8px;
  height: 8px;
  background-color: #12c8b2;
  display: inline-block;
  border-radius: 50%;
  top: -1px;
  margin-right: 5px;
`;
export const StyledPhaseText = styled.span`
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  font-family: "Inter";
  font-style: normal;
  line-height: 17px;
  text-transform: capitalize;
`;
export const StyledDownloadButton = styled.img`
  position: relative;
  margin-left: 15px;
  top: -9px;
  cursor: pointer;
`;

export const StyledExportContainer = styled.div`
  position: relative;
  top: -50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledPagination = styled(Pagination)`
  margin-top: 30px;

  ul {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;

    button {
      width: 50px;
      height: 50px;
      color: #bcbcbc;
      font-weight: 700;
      font-size: 18px;
    }
  }
  li:first-child {
    button {
      border: 1px solid #ffffff !important;
    }
  }
  li:last-child {
    button {
      border: 1px solid #ffffff !important;
    }
  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #0869fb;
    color: white;
  }
`;
export const StyledModalHeightFix = styled.div`
  // height: 600px;
  overflow: auto;
  // width: 1100px;
  width: 100%;
  min-width: 1017px;
`;
export const StyledTableHeightFix = styled.div`
  // height: 500px;
  height: 59vh;
  min-height: 579px;

  overflow: auto;
`;
export const StyledOptimizationPhase = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #999999;
`;

export const StyledCustomMuiPagination = styled.div``;

export const StyledArrowImage = styled.img`
  margin-left: 7px;
`;
