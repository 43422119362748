import httpService from "HttpService/HttpService";
import { call, put, takeLatest } from "redux-saga/effects";
import { testMediaStudioPostResponse } from "store/actions/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";
import {
  TestMediaStudioPost,
  CREATE_TEST_MEDIA_STUDIO,
  GET_TEST_MEDIA_STUDIO_PROCESSING,
  GetTestMediaStudioProcessing,
} from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";
import { BASE_URL } from "utils/path";

function* testMediaStudioPost({ payload }: TestMediaStudioPost): any {
  try {
    const endpoint = "/v1/api/media-studios/media/run-test";
    const response = yield call(
      httpService.post,
      BASE_URL,
      endpoint,
      "",
      payload
    );
    if (response.data.success) {
      yield put(
        testMediaStudioPostResponse({
          isFailed: false,
          isLoading: false,
          data: response.data.data.ids.map((id: String) => ({
            id,
          })),
          readyForPolling: true,
        })
      );
    } else {
      yield put(
        testMediaStudioPostResponse({
          isFailed: true,
          isLoading: false,
          data: [],
        })
      );
    }
  } catch (error) {
    console.log("got error during run test post call", error);
    yield put(
      testMediaStudioPostResponse({
        isFailed: true,
        isLoading: false,
        data: [],
      })
    );
  }
}

function* getTestMediaStudioProcessing(
  action: GetTestMediaStudioProcessing
): any {
  const { payload, callback } = action;

  try {
    const urlParams = `/v1/api/media-studios/media/${payload.join(",")}`;
    const response = yield call(httpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      callback &&
        callback({ data: response.data.data.mediaStudioFiles, error: false });
    } else {
      callback && callback({ data: [], error: true });
    }
  } catch (error: any) {
    callback && callback({ data: [], error: true });
  }
}

export function* mediaStudioTestingSagaWatcher() {
  yield takeLatest(CREATE_TEST_MEDIA_STUDIO, testMediaStudioPost);
  yield takeLatest(
    GET_TEST_MEDIA_STUDIO_PROCESSING,
    getTestMediaStudioProcessing
  );
}
