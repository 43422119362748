import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../../../../HttpService/HttpService";
import { BASE_URL } from "../../../../../utils/path";
import { SET_RECOMMENDATION_LOADING } from "../../../../types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import {
  GetCohortListValues,
  GetRecommendationMetadataTemplate,
  GET_COHORT_LIST,
  GET_RECOMMENDATION_METADATA_TEMPLATE,
  SET_COHORT_LIST,
} from "../../../../types/CreativeAI/Facebook/SelectCohort/selectCohortType";

function* getCohortList(action: GetCohortListValues): any {
  let {
    payload: { elementId, page, limit, isRecommended, hasVariantsUnderReview },
  } = action;
  yield put({
    type: "GET_COHORT_START",
    payload: true,
  });

  try {
    const url = `/v1/api/adset-creative-ai/adset/${elementId}?page=${page}&limit=${limit}&isRecommended=${
      isRecommended ? true : false
    }&hasVariantsUnderReview=${hasVariantsUnderReview ? true : false}`;
    const response = yield call(HttpService.get, BASE_URL, url);
    if (response.data.success) {
      if (isRecommended || hasVariantsUnderReview) {
        yield put({
          type: "RESET_COHORT_LIST",
          payload: response.data?.data,
        });
      } else {
        yield put({
          type: SET_COHORT_LIST,
          payload: response.data?.data,
        });
      }
      yield put({
        type: "GET_COHORT_START",
        payload: false,
      });
    } else {
      console.log("cohort error");
    }
  } catch (error: any) {
    console.log("cohort error", error);
  }
}

function* getRecommendationMetadataTemplate(
  action: GetRecommendationMetadataTemplate
): any {
  let {
    payload: { executionId },
    callback,
  } = action;

  yield put({ type: SET_RECOMMENDATION_LOADING, payload: true });
  try {
    const urlParams = `/v1/api/creative-variants/variant-metadata/${executionId}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);

    if (response.data.success) {
      callback && callback({ response: response.data.data, error: false });
    } else {
      callback && callback({ response: response.data, error: true });
      // api error
    }
  } catch (error: any) {
    //catch error
  }
}

export function* selectCohortWatcher() {
  yield takeLatest(GET_COHORT_LIST, getCohortList);
  yield takeLatest(
    GET_RECOMMENDATION_METADATA_TEMPLATE,
    getRecommendationMetadataTemplate
  );
}
