import { Box, styled } from "@mui/material";

export const ViewAdSetDetailsModalButton = styled(Box)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const ViewAdSetDetailsModalButtonDivider = styled(Box)`
  border: 1px solid #0869fb;
`;

export const StyledViewAdSetDetailsContent = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 1.5625em 0em;
`;

export const StyledAdSetModalDivider = styled(Box)`
  height: 1.2px;
  background: linear-gradient(
    270deg,
    rgba(223, 223, 223, 0) -0.95%,
    #efefef 50.6%,
    rgba(223, 223, 223, 0) 103.93%
  );

  margin: 1em 0em;
`;
