import { Box } from "@mui/material";
import SuccessGif from "../../../../../../../../assets/Gif/blueCircularLoader.gif";

export const SuccessLoader = () => {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <img src={SuccessGif} alt="" style={{ height: 30, width: 30 }} />
    </Box>
  );
};
