import { InputProps } from "@mui/material";
import InputLabel from "../../InputLabel/InputLabel";
import { StyledInputContainer, StyledTextInputWithLabel } from "./styled";
import { StyledErrorMessage } from "../../Typography/Heading/styled";

interface InputWithLabelProps extends InputProps {
  label?: string;
  required?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  isBorder?: boolean;
  tooltipText?: string;
  inputType?: "alpha" | "float" | "int" | undefined;
}

//refactoring needed
export function InputWithLabel({
  required,
  label,
  tooltipText,
  inputType = undefined,
  error,
  ...props
}: InputWithLabelProps) {
  // need to change to  the pattern basis
  const alphaInput = (event: any) => {
    if (event) {
      var char = String.fromCharCode(event.which);
      if (!/^[a-zA-Z ]+$/.test(char)) {
        event.preventDefault();
      }
    }
  };

  const floatInput = (event: any) => {
    if (event) {
      var char = String.fromCharCode(event.which);
      if (!/^\d*(\.\d{0,2})?$/.test(char)) {
        event.preventDefault();
      }
    }
  };

  const integerInput = (event: any) => {
    if (event) {
      var char = String.fromCharCode(event.which);
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    }
  };

  return (
    <StyledInputContainer>
      {label && (
        <InputLabel
          required={required}
          label={label}
          tooltipText={tooltipText}
        />
      )}
      <StyledTextInputWithLabel
        onKeyPress={(event) =>
          inputType === "alpha"
            ? alphaInput(event)
            : inputType === "int"
            ? integerInput(event)
            : inputType === "float"
            ? floatInput(event)
            : null
        }
        {...props}
      />
      {error && (
        <StyledErrorMessage>This field is required *</StyledErrorMessage>
      )}
    </StyledInputContainer>
  );
}
