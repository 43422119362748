import {
  CANCEL_PERFORMANCE_SCORE,
  FETCH_PERFORMANCE_SCORE,
  FetchPerformanceScore,
  FetchPerformanceScorePayload,
  FetchPerformanceScoreNewPayload,
  FetchPerformanceScoreNew,
  FETCH_PERFORMANCE_SCORE_NEW,
} from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";

export const fetchPerformanceScore = (
  payload: FetchPerformanceScorePayload
): FetchPerformanceScore => ({
  type: FETCH_PERFORMANCE_SCORE,
  payload,
});

export const fetchPerformanceScoreNew = (
  payload: FetchPerformanceScoreNewPayload
): FetchPerformanceScoreNew => ({
  type: FETCH_PERFORMANCE_SCORE_NEW,
  payload,
});

export const cancelPerformanceScore = () => ({
  type: CANCEL_PERFORMANCE_SCORE,
});

/*

kept for future use
export const storeVariantPerformanceScore = (
  payload: StoreVariantPerformanceScorePayload
): StoreVariantPerformanceScore => ({
  type: STORE_VARIANT_PERFORMANCE_SCORE,
  payload,
});

export const storeRecommendationPerformanceScore = (
  payload: StoreRecommendationPerformanceScorePayload
): StoreRecommendationPerformanceScore => ({
  type: STORE_RECOMMENDATION_PERFORMANCE_SCORE,
  payload,
});

*/
