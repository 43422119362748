import {
  Box,
  Dialog,
  DialogContent,
  Skeleton,
  Typography,
} from "@mui/material";
import ReviewApprovalAction from "components/CreativeAI/shared/ReviewApprovalAction/ReviewApprovalAction";
import { Comment, StyledText, StyledVerticalDivider } from "components/shared";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRequestedVariant,
  updateComments,
  updateReviewStatus,
} from "store/actions/CreativeAI/shared/Review/review";
import {
  CommentKey,
  ReviewInitialState,
  ReviewStatusType,
} from "store/types/CreativeAI/shared/Review/review";
import FBApproverSections from "./FBApproverSections/FBApproverSections";
import { usePostResponseHook } from "./hooks/postHook";

import {
  StyledImageWrapper,
  StyledLeftPanelBox,
  StyledMainMediaBox,
  StyledMediaBox,
  StyledPlaceHolderBox,
  StyledPlaceHolderImageBox,
  StyledSubApproverBox,
  StyledSubApproverMainBox,
} from "./styled";

import { ReactComponent as MediaPlaceholder } from "../../../../assets/svg/media_placeholder.svg";
import VarinatSpedometer from "../shared/PerformanceScore/VarinatSpedometer";
import PerformanceTooltip from "components/CreativeAI_Revamp/shared/PerformanceTooltip/PerformanceTooltip";
import { LightTooltip } from "components/CreativeAI_Revamp/shared";
import { truncate } from "lodash";
import { mixPanelAdEvent } from 'utils/mixpanel';

export default function RightPanel() {
  const [imageLoading, setImageLoading] = useState(false);
  const [modal, showModal] = useState(false);
  const [isLandscape, setIsLandscape] = useState(true);
  const [commentState, setCommentState] = useState<
    {
      title: string;
      subtitle: string;
      commentKey: CommentKey;
      mainKey: string;
    }[]
  >([]);
  const dispatch = useDispatch();
  const { updatePostComments } = usePostResponseHook();

  const {
    requestedVariantData: { data, isLoading },
    selectedVariantRequestedId,
    requestedVariantsMetaData,
    hashId,
  } = useSelector(
    (state: { reviewReducer: ReviewInitialState }) => state.reviewReducer
  );

  useEffect(() => {
    if (
      data?.payload?.settings?.adType &&
      data?.payload?.settings?.adType === "SINGLE_IMAGE_AD"
    ) {
      setImageLoading(true);
    }
  }, [data?.mediaUrl]);

  useEffect(() => {
    setCommentState([
      {
        title: "Primary Text",
        subtitle: data?.variantMetaInfo?.primaryText?.name ?? "",
        commentKey: "primaryTextReviewComments",
        mainKey: "primary_text",
      },
      {
        title: "Headline",
        subtitle: data?.variantMetaInfo?.headline?.name ?? "",
        commentKey: "headlineReviewComments",
        mainKey: "headline",
      },
      {
        title: "Description",
        subtitle: data?.variantMetaInfo?.description?.name ?? "",
        commentKey: "descriptionReviewComments",
        mainKey: "description",
      },
    ]);
  }, [data?.variantMetaInfo]);

  useEffect(() => {
    if (
      selectedVariantRequestedId !== undefined &&
      hashId &&
      requestedVariantsMetaData.data?.variants[selectedVariantRequestedId].id
    ) {
      dispatch(
        getRequestedVariant({
          hashId,
          variantId:
            requestedVariantsMetaData.data?.variants[selectedVariantRequestedId]
              .id,
        })
      );
    }
  }, [selectedVariantRequestedId]);

  const currentReviewStatus = selectedVariantRequestedId !== undefined &&
    requestedVariantsMetaData.data
    ? requestedVariantsMetaData.data.variants[
      selectedVariantRequestedId
    ].reviewStatus
    : "UNDER_REVIEW";

  const handleActionClick = (value: ReviewStatusType) => {
    mixPanelAdEvent("CAI_Fb - Approval Flow Action", {
      ad_account_id: data?.variantInfo?.adAccountId,
      ad_account_name: data?.variantInfo?.adAccountName,
      // ai_group_id: , // not available
      // ai_group_name: , // not available
      // brand_id: , // not available
      brand_name: data?.variantInfo?.brandName,
      // agency_name: , // not available
      ad_set_id: data?.adsetId,
      selected_reference_ad_id: data?.adId,
      previous_status: currentReviewStatus,
      new_status: value,
    });
    dispatch(updateReviewStatus(value));
    updatePostComments({ commentKey: "reviewStatus", value });
  };

  // performance tooltip related code
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePerformancePopoverOpen = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };

  const handlePerformancePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPerformanceTooltip = Boolean(anchorEl);
  // tooltip code end here

  return (
    <StyledLeftPanelBox
      flex={7.5}
      sx={{
        minWidth: 0,
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap=".75em">
          <VarinatSpedometer
            handlePerformancePopoverOpen={handlePerformancePopoverOpen}
            handlePerformancePopoverClose={handlePerformancePopoverClose}
            index={0}
            infoItem={data?.performanceScore}
            isLoading={isLoading}
          />

          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            sx={{
              minWidth: 0,
            }}
          >
            <StyledText
              fontFamily="Lato"
              fontSize="1.125em"
              fontWeight={700}
              lineHeight="22px"
              color="##000003"
              pb="4px"
            >
              Ad Variants
            </StyledText>
            <Box display="flex" alignItems="start">
              <StyledText
                fontSize="0.875em"
                fontWeight={600}
                lineHeight="22px"
                color="##000003"
                pr=".4em"
              >
                Ad Name:{" "}
              </StyledText>

              {isLoading ? (
                <Skeleton animation="wave" height={20} width="30%" />
              ) : (
                <LightTooltip
                  title={
                    data?.payload?.settings?.variantName?.length > 85
                      ? data?.payload?.settings?.variantName
                      : ""
                  }
                >
                  <StyledText
                    fontSize="0.875em"
                    fontWeight={400}
                    lineHeight="22px"
                    color="##000003"
                    flex={1}
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      wordBreak: "break-all",
                    }}
                  >
                    {truncate(data?.payload?.settings?.variantName, {
                      length: 85,
                    })}
                  </StyledText>
                </LightTooltip>
              )}
            </Box>
          </Box>
        </Box>
        <ReviewApprovalAction
          handleClick={handleActionClick}
          initialState={currentReviewStatus}
        />
      </Box>

      <StyledSubApproverBox flex={1}>
        {isLoading ? (
          <StyledMediaBox flex={4}>
            <StyledText fontSize="1.5em" lineHeight="19px">
              <Skeleton width="60%" />
            </StyledText>

            <StyledMainMediaBox
              flex={1}
              sx={{
                padding: ".8em",
              }}
            >
              <Skeleton
                animation="wave"
                height="100%"
                width="100%"
                variant="rectangular"
                sx={{
                  borderRadius: "6px",
                }}
              />
            </StyledMainMediaBox>
            <Box
              sx={{
                background: "#FAFAFA",
                borderRadius: "0.25em",
                padding: "0.875em 0.75em",
                border: "1px solid #F0F0F0",
              }}
            >
              <Typography variant="caption">
                <Skeleton width="100%" />
              </Typography>
              <Typography variant="caption">
                <Skeleton width="80%" />
              </Typography>
            </Box>
          </StyledMediaBox>
        ) : (
          <StyledMediaBox flex={4}>
            <StyledText fontSize="1em" lineHeight="19px">
              Variant Media/Creative
            </StyledText>
            <StyledMainMediaBox
              flex={1}
              sx={{
                padding: ".8em",
              }}
            >
              {data?.billboardMediaUrl || data?.mediaUrl ? (
                <StyledImageWrapper>
                  {data?.payload?.settings?.adType === "SINGLE_IMAGE_AD" &&
                    data?.variantType !== "billboard" ? (
                    <Box
                      component="img"
                      height={isLandscape ? "auto" : "100%"}
                      width={isLandscape ? "100%" : "auto"}
                      onClick={() => showModal(true)}
                      style={{ cursor: "zoom-in" }}
                      src={data?.mediaUrl}
                      alt=""
                      onLoad={(e: any) => {
                        setIsLandscape(
                          e.target?.clientWidth > e.target?.clientHeight
                        );
                        setImageLoading(false);
                      }}
                      sx={{
                        borderRadius: ".5em",
                        position: "absolute",
                        zIndex: 1,
                      }}
                    />
                  ) : (
                    <div>
                      <Box
                        component="video"
                        key={data?.mediaUrl}
                        controls
                        style={{ width: "100%" }}
                        id="video"
                        disablePictureInPicture
                        controlsList="nodownload noplaybackrate"
                        sx={{
                          borderRadius: ".5em",
                        }}
                      >
                        <source
                          src={data?.billboardMediaUrl ?? data?.mediaUrl}
                          type="video/mp4"
                        ></source>
                      </Box>
                    </div>
                  )}
                  {data?.payload?.settings?.adType === "SINGLE_IMAGE_AD" &&
                    imageLoading && (
                      <Skeleton
                        animation="wave"
                        height="100%"
                        width="100%"
                        variant="rectangular"
                        sx={{
                          borderRadius: "6px",
                          top: 0,
                          position: "absolute",
                          zIndex: 999,
                        }}
                      />
                    )}
                </StyledImageWrapper>
              ) : (
                <StyledPlaceHolderBox>
                  <StyledPlaceHolderImageBox>
                    <MediaPlaceholder />
                    <StyledText
                      fontSize="0.875em"
                      fontWeight={600}
                      lineHeight="21px"
                      pt="1.5em"
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      Your original media file has been used for this Ad
                      variant. You can check your Reference Ad to verify the
                      same.
                    </StyledText>
                  </StyledPlaceHolderImageBox>
                  <Box>
                    <StyledText
                      fontSize="0.75em"
                      fontWeight={400}
                      lineHeight="20px"
                      fontStyle="italic"
                      display="inline"
                    >
                      Note:
                    </StyledText>
                    <StyledText
                      fontSize="0.75em"
                      fontWeight={400}
                      lineHeight="20px"
                      color="#686868"
                      display="inline"
                      pl=".4em"
                      fontStyle="italic"
                    >
                      Media Studio is a one-stop-shop where you can edit media
                      files for your Ad. Access Media Studio and edit/re-create
                      the media files for your Ad. You can enable this feature
                      from the Pixis Dashboard or reach out to the CSM team for
                      more information
                    </StyledText>
                  </Box>
                </StyledPlaceHolderBox>
              )}
            </StyledMainMediaBox>

            {(data?.mediaUrl || data?.billboardMediaUrl) && (
              <Comment
                backgroundColor="#FAFAFA"
                isBorder
                borderColor="#F0F0F0"
                minRows={3}
                onBlurFunction={(value) => {
                  if (
                    value !== data?.variantMetaInfo?.mediaReviewComments?.text
                  ) {
                    const commentKey = "mediaReviewComments";
                    dispatch(
                      updateComments({
                        key: commentKey,
                        payloadData: {
                          text: value,
                          isLoading: true,
                        },
                      })
                    );
                    updatePostComments({ commentKey, value });
                  }
                }}
                localValue={
                  data?.variantMetaInfo?.mediaReviewComments?.text ?? ""
                }
                loading={data?.variantMetaInfo?.mediaReviewComments?.isLoading}
              />
            )}
          </StyledMediaBox>
        )}

        {isLoading || data?.mediaUrl ? (
          <StyledVerticalDivider
            height="100%"
            sx={{
              margin: "0 2%",
            }}
          />
        ) : (
          <Box m="0 .25em" />
        )}
        <StyledSubApproverMainBox flex={6}>
          <StyledText
            fontSize="1em"
            fontWeight={400}
            lineHeight="19px"
            pb=".5em"
          >
            Variant Ad Copies
          </StyledText>
          <Box flex={1} overflow="scroll">
            {commentState.map((item, index) => (
              <FBApproverSections {...item} key={index} />
            ))}
          </Box>
        </StyledSubApproverMainBox>
      </StyledSubApproverBox>
      {showModal && (
        <Dialog
          open={modal}
          onClose={() => showModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEscapeKeyDown={false}
          fullScreen={false}
        >
          <DialogContent>
            <div>
              <img
                height="auto"
                width="100%"
                onClick={() => showModal(true)}
                style={{ cursor: "zoom-in" }}
                src={data?.mediaUrl}
                alt=""
              />
            </div>
          </DialogContent>
        </Dialog>
      )}
      {data?.performanceScore && (
        <PerformanceTooltip
          open={openPerformanceTooltip}
          anchorEl={anchorEl}
          infoItem={data?.performanceScore}
          place="variant"
        />
      )}
    </StyledLeftPanelBox>
  );
}
