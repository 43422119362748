export const CREATE_TEST_MEDIA_STUDIO = "CREATE_TEST_MEDIA_STUDIO";
export const CREATE_TEST_MEDIA_STUDIO_RESPONSE =
  "CREATE_TEST_MEDIA_STUDIO_RESPONSE";
export const SET_ERROR_PRESIGINED_URL = "SET_ERROR_PRESIGINED_URL";
export const GET_TEST_MEDIA_STUDIO_PROCESSING =
  "GET_TEST_MEDIA_STUDIO_PROCESSING";
export const GET_TEST_MEDIA_STUDIO_PROCESSING_RESPONSE =
  "GET_TEST_MEDIA_STUDIO_PROCESSING_RESPONSE";
export const SET_TEST_MEDIA_STUDIO_TIMER = "SET_TEST_MEDIA_STUDIO_TIMER";

// post run test api
export interface BlenderInfoPayloadType {
  blenderFile: String;
  usageType: String;
  variations: VariationType[];
}

export interface VariationType {
  variation_type: String;
  layer_name: String;
  text: String;
}

export interface TestMediaStudioPost {
  type: typeof CREATE_TEST_MEDIA_STUDIO;
  payload: BlenderInfoPayloadType[];
}
export interface TestMediaStudioPostResponse {
  type: typeof CREATE_TEST_MEDIA_STUDIO_RESPONSE;
  payload: RunTestPostResponseType;
}

export interface MediaStudioTestReducerInitialState {
  runTestPostResponse: RunTestPostResponseType;
  testMediaStudioTimer: any;
  previewError: boolean;
}

export interface RunTestPostResponseType {
  isLoading: boolean;
  isFailed: boolean;
  data: TestMediaStudioResponseType[];
  readyForPolling?: Boolean;
}

export interface TestMediaStudioResponseType {
  status?: string;
  id: string;
  url?: string;
}

// media studio processing
export interface GetTestMediaStudioProcessing {
  type: typeof GET_TEST_MEDIA_STUDIO_PROCESSING;
  payload: string[];
  callback: any;
}

// timer

export type SetTestMediaStudioTimer = {
  type: typeof SET_TEST_MEDIA_STUDIO_TIMER;
  payload: any;
};

export interface SetErrorPresignedUrl {
  type: typeof SET_ERROR_PRESIGINED_URL;
  payload: boolean;
}
