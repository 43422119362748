import {
  Box,
  Stack,
  Typography,
  Button,
  Modal,
  Grid,
  IconButton,
} from "@mui/material";
import React from "react";
import ErrorModalSvg from "../../../assets/tai/ErrorModal.svg";
import { useSelector } from "react-redux";
import { isAdminRole } from "utils/commonFunctions";

interface IErrorModal {
  buttonClickHandler?: React.MouseEventHandler<any>;
  setModalOpen: any;
}
function ErrorModal(props: IErrorModal) {
  const { userInfo } = useSelector((state: any) => state.userInfoReducer);
  // const isAdmin = userInfo?.roles?.includes("super-admin");
  const adaccountState = useSelector((state: any) => state.adaccount);
  const { brandRoles } = adaccountState;
  const { buttonClickHandler = undefined, setModalOpen } = props;
  return (
    <Modal
      open={true}
      onClose={() => {
        setModalOpen(false);
      }}
    >
      <Box
        className="container-overlay"
        sx={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.7)",
          zIndex: "1032",
          top: "0",
          left: "0",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex flex-column focus:outline-none"
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "0.5rem",
              width: "30rem",
              height: "25.81rem",
              px: 2,
              py: 3,
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={11}>
                <Typography
                  sx={{
                    /* Heading/H4 */
                    fontFamily: "Inter",
                    fontSize: "1.5rem",
                    fontWeight: 500,
                  }}
                >
                  Error
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => setModalOpen(false)}>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 4.51123L4 12.5112"
                      stroke="#5C6674"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 4.51123L12 12.5112"
                      stroke="#5C6674"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </IconButton>
              </Grid>
            </Grid>
            <Stack
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={ErrorModalSvg}
                alt="success"
                style={{
                  height: "200px",
                  width: "200px",
                }}
              />

              <Typography
                className="font-14 font-500"
                textAlign="center"
                sx={{
                  fontFamily: "Inter",
                  fontSize: "1.125rem",
                  fontWeight: 700,
                  color: "#000",
                  marginBottom: "2rem !important",
                }}
              >
                The template of .xlsx is not as per expectation
              </Typography>
              {/* {isAdmin && ( */}
              {isAdminRole(brandRoles) && (
                <Button
                  sx={{
                    backgroundColor: "#0869FB",
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={buttonClickHandler}
                >
                  Re-Upload
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default ErrorModal;
