import { Dispatch, SetStateAction } from 'react';
import ButtonEdge from "../ReactFlowComponents/ButtonEdge";
import uniqBy from 'lodash/uniqBy';
import { Edge, Node } from 'react-flow-renderer';

export const EDGE_TYPES = {
  buttonedge: ButtonEdge,
};

export const REACT_FLOW_STYLE = {
  backgroundColor: "#F9F9F9",
  height: "100%",
  width: "100%",
  borderRadius: "8px",
  border: "1px dashed #B4B4B4",
  borderRight: "none",
};

export const convertToInt = (value: any) => {
  return value ? parseInt(value) : 0;
};

export const toggleArrayElement = (array: any, element: any) => {
  if (array.indexOf(element) > -1) {
    return array.filter((el: any) => el !== element);
  } else {
    return array.concat(element);
  }
};

export const getgravtarValue = (age: any, gender: any) => {
  let genderType = "";
  let ageType = "";
  if (age?.second <= 30) {
    ageType = "30";
  } else if (age?.second <= 45) {
    ageType = "45";
  } else {
    ageType = "65";
  }
  if (gender && gender?.genders?.length) {
    genderType = gender?.genders[0];
  } else {
    genderType = "all";
  }
  return ageType + "_" + genderType;
};

export const genderMapper: any = {
  1: "Male",
  2: "Female",
};

export const getAgeList = () => {
  let age = [];
  for (let index = 18; index < 66; index++) {
    if (index === 65) {
      age.push({ label: "65+", value: "65" });
    } else {
      age.push({ label: index, value: index });
    }
  }
  return age;
};

export type SetEdges = Dispatch<SetStateAction<Edge[]>>;
/**
 * note: the number of edges tried to be inserted to the dom shall always be more than required due to not consideration of whether
 * collapsed node or expanded node is present. but the react-flow library is smart enough to insert only the edge for which both source and 
 * target nodes are present in the dom/canvas. making it easier for us as users of the library to not have to worry about
 * collapsed/expanded nodes.
 * @param edgesList - FB / GS edges list
 */
export const cleanupEdgesGeneric = (edgesList: Record<string, Node>) =>
  (nodeIdsToInsert: string[], nodeIdsToRemove: string[], setEdges: SetEdges) => {
    setEdges((edges: any[]) => {
      let addEdges: any = Object.values(edgesList).filter((e: any) =>
        nodeIdsToInsert.includes(e?.source) || nodeIdsToInsert.includes(e?.target)
      );
      let filteredEdges = edges.filter(edge => !nodeIdsToRemove.includes(edge?.source) || !nodeIdsToRemove.includes(edge?.target));
      const newEdges = uniqBy([...addEdges, ...filteredEdges], "id");
      return newEdges;
    });
  };

export type SetNodes = Dispatch<SetStateAction<Node[]>>;
/**
 * @param nodesList - FB / GS nodes list 
 */
export const cleanupNodesGeneric = (nodesList: Record<string, Node>) =>
  (nodeIdsToInsert: string[], nodeIdsToRemove: string[], setNodes: SetNodes) => {
    setNodes((nodes: Node[]) => {
      return uniqBy([
        ...nodes.filter(node => !nodeIdsToRemove.includes(node.id)), // remove unwanted nodes
        ...nodeIdsToInsert.map(nodeId => nodesList[nodeId]),
      ], "id");
    });
  };