import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as MenuIcon } from "../../../assets/menuIcon.svg";
import { ReactComponent as DailyBudget } from "../../../assets/dailyBudgetV3.svg";
import { ReactComponent as PixisLogo } from "../../../assets/pixisLogo.svg";
import { PacingType } from "../../Types";

interface IProps {
  selectedPacing: PacingType | null;
}

export const BudgetPlannerStaticTemplate = ({
  selectedPacing,
}: IProps): JSX.Element => {
  return (
    <Box
      sx={{
        width: "28.8125rem",
        maxHeight: "70vh",
        zIndex: "1",
        background: "white",
        // pt: "0.1rem",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <PixisLogo />
        <Typography variant="h5" sx={{ fontWeight: "700", m: "0.4rem" }}>
          {selectedPacing?.pacingType === "DAILY" ? "Daily" : "Periodic"} Budget
          Planner
        </Typography>
        <Typography
          sx={{
            color: "#5A5A5A",
            textAlign: "center",
            mb: "1rem",
            fontSize: "0.8rem",
            width: "100%",
            padding: "0 3rem",
          }}
        >
          {selectedPacing?.pacingType === "DAILY"
            ? "Manage and monitor daily budget effortlessly with the Daily Budget Planner. Set your daily budget thresholds and assess performance and utilization on a daily basis."
            : "Distribute and manage your total budget for a selected time frame."}
        </Typography>
        <Stack
          sx={{ alignItems: "flex-start", padding: "0 4rem", width: "100%" }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.125rem",
              mb: "0.5rem",
            }}
          >
            <Box>
              <MenuIcon />
            </Box>
            <Box>
              <Typography
                component="span"
                sx={{
                  color: "#5A5A5A",
                  fontSize: "0.8rem",
                }}
              >
                {selectedPacing?.pacingType === "DAILY"
                  ? "Set specific budget target for each day, for a required time frame."
                  : "Equal budget threshold every day during the campaign period."}
              </Typography>
            </Box>
          </Box>
          <Box
            component="div"
            className="d-flex align-items-center justify-content-center"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.125rem",
              mb: "0.5rem",
            }}
          >
            <Box>
              <MenuIcon />
            </Box>
            <Box>
              <Typography
                component="span"
                sx={{
                  color: "#5A5A5A",
                  fontSize: "0.8rem",
                }}
              >
                {selectedPacing?.pacingType === "DAILY"
                  ? "Monitor day-wise spends and performance."
                  : "View and assess under-utilization and overspend of budget."}
              </Typography>
            </Box>
          </Box>
          <Box
            component="div"
            className="d-flex align-items-center justify-content-center"
            sx={{
              display: "flex",
              gap: "1.125rem",
              mb: "0.5rem",
            }}
          >
            <Box>
              <MenuIcon />
            </Box>
            <Box>
              <Typography
                component="span"
                sx={{
                  color: "#5A5A5A",
                  fontSize: "0.8rem",
                }}
              >
                {selectedPacing?.pacingType === "DAILY"
                  ? "View projected and actual spends."
                  : "Under-utilized and overspent funds are adjusted equally over the balance days."}
              </Typography>
            </Box>
          </Box>
          <Box
            component="div"
            className="d-flex align-items-center justify-content-center"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.125rem",
            }}
          >
            <Box>
              <MenuIcon />
            </Box>
            <Box>
              <Typography
                component="span"
                sx={{
                  color: "#5A5A5A",
                  fontSize: "0.8rem",
                }}
              >
                {selectedPacing?.pacingType === "DAILY"
                  ? "Set different Custom Budgets for different days as required."
                  : "Add custom budget to any day, based on your requirement."}
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Box
          component="div"
          sx={{
            m: "0.25rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="div"
            sx={{
              height: "10rem",
              width: "100%",
            }}
          >
            <DailyBudget height={"100%"} width={"100%"} />
          </Box>
        </Box>
        <Typography
          component="p"
          sx={{
            color: "#0869FB",
            fontSize: "0.8rem",
            textAlign: "center",
            padding: "0 3rem",
          }}
        >
          To begin exploring, please select the Day you want to add Custom
          Budgets
        </Typography>
      </Stack>
    </Box>
  );
};
