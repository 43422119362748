import {
  AdInfoPayloadType,
  SET_ADS_SELECTION,
  SET_AD_ACCOUNTS_SELECTION,
  SET_AD_ADDITIONAL_INFO,
  SET_AD_ADDITIONAL_INFO_RESET,
  SetAdAccountsSelection,
  SetAdAccountsSelectionPayload,
  SetAdAdditionalInfo,
  SetAdAdditionalInfoReset,
  SetAdSetSelection,
  SetAdSetSelectionPayload,
  SetAdsSelection,
  SetAdsSelectionPayload,
  Set_ADSET_SELECTION,
} from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";

export const setAdAccountsSelection = (
  payload: SetAdAccountsSelectionPayload
): SetAdAccountsSelection => ({
  type: SET_AD_ACCOUNTS_SELECTION,
  payload,
});

export const setAdSetSelection = (
  payload: SetAdSetSelectionPayload
): SetAdSetSelection => ({
  type: Set_ADSET_SELECTION,
  payload,
});

export const setAdsSelection = (
  payload: SetAdsSelectionPayload
): SetAdsSelection => ({
  type: SET_ADS_SELECTION,
  payload,
});

export const setAdAdditionalInfo = (
  payload: AdInfoPayloadType
): SetAdAdditionalInfo => {
  return {
    type: SET_AD_ADDITIONAL_INFO,
    payload,
  };
};

export const setAdAdditionalInfoReset = (
  payload: any
): SetAdAdditionalInfoReset => {
  return {
    type: SET_AD_ADDITIONAL_INFO_RESET,
    payload,
  };
};
