import styled from "styled-components";

export const StyledDiv = styled.span`
  position: relative;
  .under-review {
    background-color: #ff87021a !important;
    color: #ff8702 !important;
    &:hover {
      color: #ff8702;
    }
    path {
        stroke: #ff8702;
    }
    }
  }
  .approved {
    background-color: #10ab991a !important;
    color: #10ab99 !important;
    &:hover {
      color: #10ab99 !important;
    }
  }
  .rejected {
    background-color: #e84b471a !important;
    color: #e84b47 !important;
    &:hover {
      color: #e84b47 !important;
    }
    path {
        stroke: #e84b47;
    }
  }
`;
export const StyledStatusTag = styled.a`
  padding: 0.8334em;
  height: 1.91em;
  width: 7.5em;
  padding: 0.334em 0.667em;
  border-radius: 0.667em;
  gap: 0.5em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.25em;
  text-decoration: none !important;
  span {
    padding-right: 0.5em;
  }
`;

export const StyledStatusContainer = styled.div`
  padding: 0.8334em;
  height: 1.91em;
  width: 7.5em;
  padding: 0.334em 0.667em;
  border-radius: 0.667em;
  gap: 0.5em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.25em;
  text-decoration: none !important;
  span {
    padding-right: 0.5em;
  }
`;

export const StyledSVGContainer = styled.div`
  width: 0.80833em;
  height: 0.80833em;
`;
