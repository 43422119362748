import {
  GetOptimizerMetrics,
  GetAllOptimizersDetails,
  GET_OPTIMIZER_METRICS,
  GET_ALL_OPTIMIZERS_DETAILS,
  PostResultMetrics,
  POST_RESULT_METRICS,
  GET_MULTIPLE_OPTIMIZER_METRICS,
  GetMultipleOptimizerMetrics,
} from "../../types/SmbDashboard/selectedBrand";
import { call, takeLatest, put, select, fork } from "redux-saga/effects";
import HttpService from "../../../HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "../../../utils/path";
import { smbDashboardApi } from "MappedAPI/MappedAPI";
import {
  setSubOptimizerMetrics,
  setAllOptimizersDetails,
} from "../../actions/SmbDashboard/selectedBrand";
import {
  resetErrorForSelectedBrand,
  setErrorForGetOptimizerDetails,
} from "store/actions/SmbDashboard/error";

function* fetchOptimizerMetrics(urlParams: any): any {
  try {
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    let optimizerId = response.data.data.optimizerId;
    for (
      let index = 0;
      index < response.data.data.metricsData.length;
      index++
    ) {
      const element = response.data.data.metricsData[index];
      yield put(
        setSubOptimizerMetrics({
          optimizerId,
          subOptimizerId: element.subOptimizerId,
          metrics: element,
        })
      );
    }
  } catch (error) {
    console.log("LL: function*fetchOptimizerMetrics -> error", error);
  }
}

function* getMultipleOptimizerMetrics(
  action: GetMultipleOptimizerMetrics
): any {
  let {
    payload: { brandId, selectedMonth, selectedYear, optimizerIds },
  } = action;
  for (let index = 0; index < optimizerIds.length; index++) {
    const urlParams = `${smbDashboardApi}/brands/${brandId}/optimizers/${optimizerIds[index]}/dashboard/metrics-data?month=${selectedMonth}&year=${selectedYear}`;

    yield fork(fetchOptimizerMetrics, urlParams);
  }
}

function* getAllOptimizersDetails(action: GetAllOptimizersDetails): any {
  let {
    payload: { brandId },
  } = action;
  try {
    const urlParams = `${smbDashboardApi}/brands/${brandId}/dashboard-summary`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (response.data.success) {
      const optimizers = response?.data?.data?.gaiSummary?.optimizers;
      const reducedOptimizers = optimizers.reduce(
        (obj: any, item: any) => ({
          ...obj,
          [item.id]: {
            ...item,
            subOptimizers: item.subOptimizers.reduce((prev: any, curr: any) => {
              prev[curr.id] = {
                details: curr,
                metrics: {
                  isLoading: true,
                },
              };
              return prev;
            }, {}),
          },
        }),
        {}
      );
      yield put(resetErrorForSelectedBrand());
      yield put(
        setAllOptimizersDetails({
          optimizers: reducedOptimizers,
          name: response?.data?.data?.name,
        })
      );
      const state = yield select();
      const commonState = state.smb.common;
      yield call(getMultipleOptimizerMetrics, {
        payload: {
          brandId,
          optimizerIds: Object.keys(reducedOptimizers),
          selectedMonth: commonState.calendar.selectedMonth,
          selectedYear: commonState.calendar.selectedYear,
        },
        type: "GET_MULTIPLE_OPTIMIZER_METRICS",
      });
    } else {
      yield put(
        setErrorForGetOptimizerDetails(
          response?.data?.message || "Something went wrong"
        )
      );
    }
  } catch (error: any) {
    yield put(
      setErrorForGetOptimizerDetails(error?.message || "Something went wrong")
    );
  }
}

function* getOptimizerMetrics(action: GetOptimizerMetrics): any {
  let {
    payload: { brandId, selectedMonth, selectedYear, optimizerId },
    callback,
  } = action;
  try {
    const urlParams = `${smbDashboardApi}/brands/${brandId}/optimizers/${optimizerId}/dashboard/metrics-data?month=${selectedMonth}&year=${selectedYear}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

export function* SelectedBrandWatcher() {
  yield takeLatest(GET_ALL_OPTIMIZERS_DETAILS, getAllOptimizersDetails);
  yield takeLatest(GET_OPTIMIZER_METRICS, getOptimizerMetrics);
  yield takeLatest(GET_MULTIPLE_OPTIMIZER_METRICS, getMultipleOptimizerMetrics);
}
