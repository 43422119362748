export const payloadOfCreateDraft = (
  isCreate: boolean,
  adAccountId: string,
  campaignId: string,
  adUnit: any,
  dnd: any,
  replacementConfig: any,
  recommendations: any,
  existingHeadlines: any,
  existingDescriptions: any,
  finalUrl: string,
  path1: string,
  path2: string
) => {
  return {
    isCreate,
    ad_account_id: adAccountId,
    campaign_id: campaignId,
    ad_group_id: adUnit?.adGroup?.id,
    ad_id: adUnit?.adGroupAd?.ad?.id,
    meta_data: {
      ...adUnit,
      headline: [
        ...dnd.headlines.toBePublished.map((h: any) => ({ text: h.value })),
      ],
      description: [
        ...dnd.descriptions.toBePublished.map((d: any) => ({ text: d.value })),
      ],
      adGroupAd: {
        ...adUnit.adGroupAd,
        ad: {
          ...adUnit.adGroupAd.ad,
          finalUrls: [finalUrl],
          responsiveSearchAd: {
            path1,
            path2,
          },
        },
      },
    },
    data: {
      dnd,
      replacementConfig,
      existingHeadlines,
      existingDescriptions,
      recommendations: { ...recommendations, status: "fetched" },
    },
  };
};

export const payloadForSkip = (adUnit: any) => {
  return {
    dnd: adUnit.editorData.dnd,
    recommendations: adUnit.editorData.recommendations,
    existingHeadlines: adUnit.editorData.existingHeadlines,
    existingDescriptions: adUnit.editorData.existingDescriptions,
    path1: adUnit.adGroupAd.ad.responsiveSearchAd?.path1,
    path2: adUnit.adGroupAd.ad.responsiveSearchAd?.path2,
    finalUrls: adUnit.adGroupAd.ad.finalUrls[0],
    creativeReplacement: adUnit.creativeReplacement,
    replacementConfig: adUnit.editorData.replacementConfig
  };
};
