export const GET_MEDIA_LIST = "GET_MEDIA_LIST";
export const GET_MEDIA_DATA = "GET_MEDIA_DATA";
export const DELETE_MEDIA_STUDIO = "DELETE_MEDIA_STUDIO";
export const UPDATE_MEDIA_STUDIO = "UPDATE_MEDIA_STUDIO";
export const GET_PRESIGNED_URL = "GET_PRESIGNED_URL";
export const CREATE_MEDIA_STUDIO = "CREATE_MEDIA_STUDIO";
export const SET_MEDIA_LIST = "SET_MEDIA_LIST";
export const SET_MEDIA_DATA = "SET_MEDIA_DATA";
export const SET_FETCH_LOADING = "SET_FETCH_LOADING";
export const SET_FETCH_DATA_LOADING = "SET_FETCH_DATA_LOADING";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const ON_SAVE_UPDATE = "ON_SAVE_UPDATE";
export const SET_UPLOAD_TOGGLE = "SET_UPLOAD_TOGGLE";
export type CreateMediaStudio = {
  type: typeof CREATE_MEDIA_STUDIO;
  payload: {
    brandId: string;
    user: any;
    payload: any;
    finalAction?: any;
  };
};

export type GetMediaList = {
  type: typeof GET_MEDIA_LIST;
  payload: {
    brandId: string;
  };
};

export type GetMediaData = {
  type: typeof GET_MEDIA_DATA;
  payload: {
    brandId: string;
    mediaId: string;
  };
};

export type DeleteMediaStudio = {
  type: typeof DELETE_MEDIA_STUDIO;
  payload: {
    adId: string;
    brandId: string;
    mediaId: string;
    user: any;
  };
};

export type UpdateMediaStudio = {
  type: typeof UPDATE_MEDIA_STUDIO;
  payload: {
    adId: string;
    brandId: string;
    mediaId: string;
    user: any;
    payload: any;
    finalAction?: any;
  };
};

export type GetPresignedUrl = {
  type: typeof GET_PRESIGNED_URL;
  payload: {
    mediaId: string;
    user?: any;
    finalAction?: any;
    type: string;
  };
};

export type UploadMedia = {
  type: typeof UPLOAD_MEDIA;
  payload: {
    file: any;
    user: any;
    finalAction: any;
    type: string;
  };
};

export type MediaList = {
  tag: string;
  layersCount: string | number;
  usageType: string;
  framesCount: string | number;
  id: any;
};

export type MediaStudioAdminState = {
  mediaList: MediaList[];
  mediaData: MediaList;
  addingMediaStudio: boolean;
  isMediaUploading: boolean;
};

export type SetMediaList = {
  payload: MediaList;
  type: any;
};

export type SetUploadToggle = {
  payload: boolean;
  type: typeof SET_UPLOAD_TOGGLE;
};
