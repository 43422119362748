export const SET_PARENT_AI_GROUP_PLANNERS = "SET_PARENT_AI_GROUP_PLANNERS";
export const SET_PARENT_AI_GROUP_PLANNERS_DETAILS =
  "SET_PARENT_AI_GROUP_PLANNERS_DETAILS";
export const SET_PARENT_AI_GROUP_ITEMS = "SET_PARENT_AI_GROUP_ITEMS";
export const GET_PARENT_AI_GROUP_ITEMS = "GET_PARENT_AI_GROUP_ITEMS";
export const GET_PARENT_AI_GROUP_ITEM = "GET_PARENT_AI_GROUP_ITEM";
export const DELETE_PARENT_AI_GROUP_ITEM = "DELETE_PARENT_AI_GROUP_ITEM";
export const DELETE_BUDGET_PLANNER = "DELETE_BUDGET_PLANNER";
export const GET_PARENT_AI_GROUP_PLANNERS = "GET_PARENT_AI_GROUP_PLANNERS";
export const GET_PARENT_AI_GROUP_PLANNER_DETAILS =
  "GET_PARENT_AI_GROUP_PLANNER_DETAILS";
export const GET_PARENT_AIGROUP_PLANNER_DETAILS_FAKE =
  "GET_PARENT_AIGROUP_PLANNER_DETAILS_FAKE";
export const CREATE_PARENT_AI_GROUP = "CREATE_PARENT_AI_GROUP";
export const CREATE_PARENT_AI_GROUP_PLANNER = "CREATE_PARENT_AI_GROUP_PLANNER";
export const UPDATE_PARENT_AI_GROUP_PLANNER = "UPDATE_PARENT_AI_GROUP_PLANNER";
export const SET_SELECTED_PLANNER_ID_PARENT = "SET_SELECTED_PLANNER_ID_PARENT";
export const SET_SELECTED_PARENT_AI_GROUP = "SET_SELECTED_PARENT_AI_GROUP";
export const ADD_SINGLE_PARENT_AI_GROUP = "ADD_SINGLE_PARENT_AI_GROUP";
export const DELETE_SINGLE_PARENT_AI_GROUP = "DELETE_SINGLE_PARENT_AI_GROUP";
export const SET_PARENT_AI_GROUP_LOADER = "SET_PARENT_AI_GROUP_LOADER";

export type ParentAiGroupState = {
  data: {
    parentAiGroups: any;
    selectedParentAiGroup: null | string;
    selectedPlannerId: null | string;
    loader: boolean;
  };
};

export type SetParentAiGroupPlanners = {
  type: typeof SET_PARENT_AI_GROUP_PLANNERS;
  payload: any;
};

export type SetParentAiGroupItems = {
  type: typeof SET_PARENT_AI_GROUP_ITEMS;
  payload: any;
};

export type SetParentAiGroupPlannerDetails = {
  type: typeof SET_PARENT_AI_GROUP_PLANNERS_DETAILS;
  payload: any;
};

export type GetParentAiGroupItems = {
  type: typeof GET_PARENT_AI_GROUP_ITEMS;
  payload: any;
  callback: Function;
};
export type GetParentAiGroupItem = {
  type: typeof GET_PARENT_AI_GROUP_ITEM;
  payload: any;
  callback: Function;
};
export type DeleteParentAiGroupItem = {
  type: typeof DELETE_PARENT_AI_GROUP_ITEM;
  payload: any;
  callback: Function;
};

export type DeleteBudgetPlanner = {
  type: typeof DELETE_BUDGET_PLANNER;
  payload: any;
  callback: Function;
};

export type GetParentAiGroupPlanners = {
  type: typeof GET_PARENT_AI_GROUP_PLANNERS;
  payload: any;
  callback: Function;
};
export type GetParentAiGroupPlannerDetails = {
  type: typeof GET_PARENT_AI_GROUP_PLANNER_DETAILS;
  payload: any;
  callback: Function;
};
export type GetParentAigroupPlannerDetailsFake = {
  type: typeof GET_PARENT_AIGROUP_PLANNER_DETAILS_FAKE;
  payload: any;
  callback: Function;
};
export type CreateParentAiGroup = {
  type: typeof CREATE_PARENT_AI_GROUP;
  payload: any;
  callback: Function;
};
export type CreateParentAiGroupPlanner = {
  type: typeof CREATE_PARENT_AI_GROUP_PLANNER;
  payload: any;
  callback: Function;
};
export type UpdateParentAiGroupPlanner = {
  type: typeof UPDATE_PARENT_AI_GROUP_PLANNER;
  payload: any;
  callback: Function;
};

export type SetSelectedPlannerIdParentGroup = {
  type: typeof SET_SELECTED_PLANNER_ID_PARENT;
  payload: any;
};

export type SetSelectedParentAiGroup = {
  type: typeof SET_SELECTED_PARENT_AI_GROUP;
  payload: any;
};

export type AddSingleParentAiGroup = {
  type: typeof ADD_SINGLE_PARENT_AI_GROUP;
  payload: any;
};
export type DeleteSingleParentAiGroup = {
  type: typeof DELETE_SINGLE_PARENT_AI_GROUP;
  payload: any;
};
export type SetParentAiGroupLoader = {
  type: typeof SET_PARENT_AI_GROUP_LOADER;
  payload: any;
};
