import { Box } from "@mui/material";
import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import ChoiceButton from "./ChoiceButton";

interface ChoiceButtonFormControllerProps {
  control: Control<FieldValues, object>;
  registeredName: string;
  required?: boolean;
}

export default function ChoiceButtonFormController({
  control,
  registeredName,
  required,
}: ChoiceButtonFormControllerProps) {
  return (
    <Controller
      control={control}
      name={registeredName}
      defaultValue="Unrelated"
      rules={{
        required,
      }}
      render={({
        field: { onChange, value },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <Box>
          <ChoiceButton onChange={onChange} value={value} />
        </Box>
      )}
    />
  );
}
