import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AdAccountState } from '../../store/types/adaccount';
import { initialState } from '../../store/types/auth';
import { MontageState } from '../../store/types/montageAds';
import LookInto from "../../assets/svg/LookInto.svg"
import DeleteIcon from "../../assets/svg/deleteIcon.svg"
import { deleteCampaign, getMontageCompaigns, setMontageCampaigns, setSelectedMontageCampaign, toggleVideoPanelView } from '../../store/actions/montageAds';
import ModalContainer from '../Modal/ModalContainer';
import VideoListing from './VideoListing';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
const MontageAdsListing:React.FC<any> =():JSX.Element => {
    const auth = useSelector((state: { auth:initialState }) => state.auth);
    const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
    const montageAds = useSelector((state:{ montageAds:MontageState}) => state.montageAds)
    const {montageCampaigns, toggleVideoPanel, selectedMontageCampaign}= montageAds;
    const { selectedBrand } = adaccount;
    const [logNumber, setLogNumber] = React.useState<Number>(-1);
    const [howUGCWorks, setHowUGCWorks] = React.useState<Number>(-1);
    const dispatch = useDispatch();
    const handleDelete= (id:string)=>{
        dispatch(deleteCampaign({campaignId:id,user:auth.user},(response:any, error:boolean)=>{
            setLogNumber(-1)
            if(!error){
                dispatch(getMontageCompaigns({brandId:selectedBrand.id,user:auth.user},(response:any,error:boolean)=>{
                    if(!error){
                        dispatch(setMontageCampaigns([...response?.data]))
                    }
                }))
            }
            else{
                alert(response.data?.message)
            }
        }))
    }
  return (
        <>
        {!toggleVideoPanel ? 
        <>
            <>
            <p className="montage-heading">Montage Ads</p>
        <div className="montage-campaign">
            <div className="launch-campaigns">
                <NavLink className="campaign-btn1" to={'/outline-screen'}>
                <small>+ Create Campaigns</small>
                </NavLink>
                <button className="campaign-btn2" onClick={()=>setHowUGCWorks(1)}>
                    <small><b> ? How UGC Works</b></small>
                </button>
            </div>
           {montageCampaigns.map((campaign:any,index:Number)=>{
               return(
                <div>
                <ul className="campaign-ul">
                    
                    <li className="campaign-list">
                        <div className="campaign-name">
                            <img src={campaign.productImage}/>
                            <span>{campaign.name}</span>
                        </div>
                        <div className="campaign-details"> 
                            <span>LAUNCH DATE</span>
                            <small>{moment(campaign.createdAt).format('lll')}</small>
                        </div>

                        <div className="campaign-details"> 
                            <span>NO. OF ASSETS </span>
                            <small>{campaign.creativesCount}</small>
                        </div>

                        <div className="campaign-details">
                            <span>VIDEO STYLE</span>
                            <small>{campaign.videoStyle}</small>
                        </div>
                        
                        <div className="creative-library">
                            <button onClick={(e)=>{
                                dispatch(toggleVideoPanelView(!toggleVideoPanel))
                                dispatch(setSelectedMontageCampaign(campaign))
                                }}>
                                <i>
                                    <img src={LookInto} alt="View Videos"/>
                                </i>
                            </button>
                            <button onClick={(e)=>{
                                setLogNumber(index)
                                dispatch(setSelectedMontageCampaign(campaign))
                            }}>
                                <i>
                                    <img src={DeleteIcon} alt="delete_campaign"/>
                                </i>
                        </button>
                        </div>
                    </li>
                </ul>
            </div>
               )
           })}
        </div>
            </>
        {logNumber != -1 && (
            <ModalContainer
            title={`Delete Campaign`}
            submitText={"Delete"}
            width={"20%"}
            cancelText={'Cancel'}
            handleClose={() => {
                setLogNumber(-1)
                dispatch(setSelectedMontageCampaign({}))
            }}
            handleSubmit={() => {
                handleDelete(selectedMontageCampaign?.id)
            }}
            tagline={""}>
                <>
                <h6>Are you sure you want to delete the campaign {selectedMontageCampaign?.name}</h6>
                </>
            </ModalContainer>
        )}
        {howUGCWorks!==-1 && 
            <ModalContainer
            title='Video Player'
            handleClose={()=>{setHowUGCWorks(-1)}}
            submitText={""}
            cancelText={""}
            width={"80%"}
            height={"90%"}
            >
               <video controls height={"100%"} width={"100%"} autoPlay >
                    <source src={"https://performance-targeting.s3.ap-south-1.amazonaws.com/fa059c96-f74a-47db-82b8-d428195e9ee7.mp4"} type="video/mp4"></source>
                </video>
            </ModalContainer>
        }
        
        
        </>:<><VideoListing/></>}
        </>
    )
}
export default MontageAdsListing