import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrand,
  getGPT3Reference,
  setBrand,
  setClusterLoader,
  setTargetKeyword,
  setTimer,
} from "../../../store/actions/cluster";
import { ClusterState } from "../../../store/types/cluster";
import { initialState } from "../../../store/types/auth";
import CheckBox from "../../../ui/CheckBox/CheckBox";
import InputText from "../../../ui/InputText/InputText";
import RadioInput from "../../../ui/RadioInput/RadioInput";
import Loader from "../../common/Loader";
import "./index.scss";
import { AdAccountState } from "../../../store/types/adaccount";

const TargetKeyword: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const timerRef = useRef<any>(null);
  const clusterState = useSelector(
    (state: { cluster: ClusterState }) => state.cluster
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const {
    selectedBrand,
    selectedPlatform,
    selectedAdAccount,
    selectedGSAIAdaccount,
    selectedGSAIPlatform,
  } = adaccount;
  const { targetKeyword, clusterLoader, timer } = clusterState;

  React.useEffect(() => {
    if (
      !targetKeyword.referenceHeadline ||
      !targetKeyword.referenceDescription
    ) {
      dispatch(setClusterLoader(true));

      dispatch(
        getGPT3Reference(
          {
            platformId: selectedGSAIPlatform?.id,
            brandId: selectedBrand.id,
            accountID: selectedGSAIAdaccount?.id,
            user: auth?.user,
          },
          (response: any, error: boolean) => {
            if (response?.success) {
              let updatedTarget = targetKeyword;
              let data = response.data[0];
              var headlines = data.referenceHeadline.map((value: string) => {
                return {
                  name: value,
                  value: value,
                  selected: false,
                };
              });
              var description = data.referenceDescription.map(
                (value: string) => {
                  return {
                    name: value,
                    value: value,
                    selected: false,
                  };
                }
              );
              updatedTarget.referenceHeadline = headlines;
              updatedTarget.referenceDescription = description;
              dispatch(setTargetKeyword(updatedTarget));
            }
            dispatch(setClusterLoader(false));
          }
        )
      );
    }
  }, []);

  useEffect(() => {
    timerRef.current = timer;
  }, [timer]);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        dispatch(setTimer(null));
      }
    },
    []
  );

  const handleInputChange = (e: any) => {
    const newTKeywords = targetKeyword;
    newTKeywords[e.target.name] = e.target.value;
    dispatch(setTargetKeyword(newTKeywords));
  };

  const handleRadioInputChange = (
    e: any,
    index: number,
    radioInputTitle: string
  ) => {
    const newTKeywords = targetKeyword;
    newTKeywords[radioInputTitle][index].value = e.target.value;
    dispatch(setTargetKeyword(newTKeywords));
  };

  const handleRadioChange = (index: number, radioInputTitle: string) => {
    const newTKeywords = targetKeyword;
    newTKeywords[
      radioInputTitle === "referenceHeadline"
        ? "selectedReferenceHeadline"
        : "selectedReferenceDescription"
    ] = index;
    dispatch(setTargetKeyword(newTKeywords));
  };

  return (
    <div className="target-keyword">
      {/* Target */}
      <div className="target-keyword-title">Target Interest Keywords</div>
      <InputText
        value={targetKeyword.title}
        name="title"
        onChange={(e: any) => {
          handleInputChange(e);
        }}
      />
      {clusterLoader && <Loader />}
      {/* Headline */}
      <div className="target-keyword-title mt-4">Reference Headline</div>
      <RadioInput
        data={
          targetKeyword.referenceHeadline && targetKeyword.referenceHeadline
        }
        name="headline"
        inputClassName="target-radio-input"
        onInputChange={handleRadioInputChange}
        radioInputTitle="referenceHeadline"
        onChange={handleRadioChange}
        defaultSelected={targetKeyword.selectedReferenceHeadline}
      />

      {/* Generation type */}
      <div className="target-keyword-title">Generation Type</div>
      <div className="generation-container">
        {targetKeyword.generationType &&
          targetKeyword.generationType.map((type: any, index: number) => (
            <label>
              <CheckBox
                onChange={(value) => {
                  const newTKeywords = targetKeyword;
                  newTKeywords.generationType[index].selected = value;
                  dispatch(setTargetKeyword(newTKeywords));
                }}
                value={type.selected}
              />{" "}
              {type.label}
            </label>
          ))}
      </div>

      {/* Description */}
      <div className="target-keyword-title">Reference Description</div>
      <RadioInput
        data={
          targetKeyword.referenceDescription &&
          targetKeyword.referenceDescription
        }
        name="description"
        inputClassName="target-radio-input"
        onInputChange={handleRadioInputChange}
        radioInputTitle="referenceDescription"
        onChange={handleRadioChange}
        defaultSelected={targetKeyword.selectedReferenceDescription}
      />
    </div>
  );
};

export default TargetKeyword;
