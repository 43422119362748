import Box from "@mui/system/Box";
import styled from "@mui/system/styled";

interface SelectedProps {
  selected: boolean;
  disabled?: boolean;
}

export const StyledModalContainer = styled(Box)`
  display: flex;
  padding: 1.81em 1.81em 1.92em 1.5em;
  height: 22.1875em;
  @media (max-width: 1280px) {
    width: 100%;
  }
`;
export const StyledTitleBoxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 51.125em;
`;
export const StyledTitleBox = styled(Box)`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.2em;
`;
export const StyledSubTitleBox = styled(Box)`
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 0.6em;
  line-height: 1.5em;
`;

export const StyledSelectCardContainer = styled(Box)<SelectedProps>`
  cursor: pointer;
  display: flex;
  width: 23.5em;
  padding: 0.75em 0.5em;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5em;
  border-radius: 0.25em;
  border: 0.0625em solid #f2f2f2;
  background: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "default")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  :hover {
    outline: 0.1875em solid #3482f7;
  }
`;
export const StyledSelectCard = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledSelectCardMessage = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-family: Inter;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375em;
  letter-spacing: 0.01094em;
  span {
    font-family: "Inter";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0075rem;
    color: #555555;
    width: 21.08em;
  }
`;
