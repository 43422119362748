import React from "react";
import { Tooltip as MUITooltip, TooltipProps } from "@mui/material";
import { useSelector } from "react-redux";
import { AdAccountState } from "store/types/adaccount";

interface GsaiTooltipProps extends TooltipProps {
  title: any;
  children: any;
  maxWidth?: number;
}

const GsaiCardTooltip: React.FC<GsaiTooltipProps> = ({
  title,
  children,
  maxWidth,
  ...props
}): JSX.Element => {
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  return (
    <MUITooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.white",
            border: ".0625rem solid #EFEFEF",
            boxShadow: "0rem .0625rem .75rem rgba(0, 0, 0, 0.1)",
            color: "black",
            textAlign: "center",
            maxWidth: maxWidth ? maxWidth : 260,
            "& .MuiTooltip-arrow": {
              color: "common.white",
            },
          },
        },
      }}
      title={title}
      arrow
      disableFocusListener
      disableTouchListener
      {...props}
    >
      {children}
    </MUITooltip>
  );
};

export default GsaiCardTooltip;
