import { Buckets } from "../components/KeywordExplorerNode/types";

export const BUCKET_NAMES: Buckets[] = [
  {
    type: "kw_ideas",
    name: "New Keywords Recommended",
  },
  { type: "positive_kw", name: "Positive Keywords" },
  { type: "negative_kw", name: "Negative Keywords" },
];
export const EXPLORE_METRICS = {
  "metrics.impressions": "Impressions",
  "metrics.averageCpc": "Avg CPC",
  "metrics.costPerConversion": "Cost/Conversion",
};

export const METRIC_NAMES: any = {
  kw_ideas: {
    avgMonthlySearches: "Monthly Avg searches",
    competitionIndex: "Competition Index",
    "3m_change": "Three Month Change",
  },
  positive_kw: {
    ...EXPLORE_METRICS,
  },
  negative_kw: {
    ...EXPLORE_METRICS,
  },
};
export const SELECTED_EXPLORE_METRICS = {
  "metrics.impressions": {
    min: Infinity,
    max: -Infinity,
    selected: [Infinity, -Infinity],
  },
  "metrics.averageCpc": {
    min: Infinity,
    max: -Infinity,
    selected: [Infinity, -Infinity],
  },
  "metrics.costPerConversion": {
    min: Infinity,
    max: -Infinity,
    selected: [Infinity, -Infinity],
  },
};
export const SELECTED_METRICS: any = {
  [BUCKET_NAMES[0].type]: {
    avgMonthlySearches: {
      min: Infinity,
      max: -Infinity,
      selected: [Infinity, -Infinity],
    },
    competitionIndex: {
      min: Infinity,
      max: -Infinity,
      selected: [Infinity, -Infinity],
    },
    "3m_change": {
      min: Infinity,
      max: -Infinity,
      selected: [Infinity, -Infinity],
    },
  },
  [BUCKET_NAMES[1].type]: {
    ...SELECTED_EXPLORE_METRICS,
  },
  [BUCKET_NAMES[2].type]: {
    ...SELECTED_EXPLORE_METRICS,
  },
};
export const CLUSTER_ID_KEY = "cluster_id";
export const CLUSTER_NAME_KEY = "cluster_name";

export const ADGROUP_ID_KEY = "adGroup.id";
export const ADGROUP_NAME_KEY = "adGroup.name";

export const CAMPAIGN_ID_KEY = "campaign.id";
export const CAMPAIGN_NAME_KEY = "campaign.name";

export const BULK_ACTIONS = [
  "Match Type",
  "Behavior",
  "Duplicate",
  "Move",
  "Delete Selected",
  "Save Selected",
];

export const MATCH_TYPES = [
  { value: "PHRASE", label: "Phrase" },
  { value: "BROAD", label: "Broad" },
  { value: "EXACT", label: "Exact" },
];

export const INIT_PAYLOAD = {
  [BUCKET_NAMES[0].type]: {},
  [BUCKET_NAMES[1].type]: {},
  [BUCKET_NAMES[2].type]: {},
};

export const SEARCH_METRIC_NAME: any = {
  [BUCKET_NAMES[0].type]: "new_kw.text",
  [BUCKET_NAMES[1].type]: "searchTermView.searchTerm",
  [BUCKET_NAMES[2].type]: "searchTermView.searchTerm",
};

export const KEYWORD_PAGINATION_LENGTH = 5;
export const NEXT_PAGE_TIMEOUT = 2000;
export const BUCKET_PAGINATION_LENGTH = 8;
export const METRICS_CONFIG: any = {
  "metrics.costPerConversion": {
    currency: true,
    amountValue: true,
  },
  "metrics.averageCpc": {
    currency: true,
    amountValue: true,
    divideByValue: 1000000,
  },
  "metrics.impressions": {
    amountValue: true,
  },
  competitionIndex: {
    amountValue: true,
  },
  "3m_change": {
    tobeFixedNumber: true,
  },
  avgMonthlySearches: {
    amountValue: true,
  },
};
export const MICRON_METRICS = ["metrics.costPerConversion"];
export const MICRON_VALUE = Math.pow(10, -6);
