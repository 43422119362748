import { cloneDeep } from "lodash";
import {
  GetAdData,
  GetRequestedVariant,
  GetRequestedVariants,
  GET_AD_DATA,
  GET_REQUESTED_VARIANT,
  GET_REQUESTED_VARIANTS_DATA,
  ReviewInitialState,
  SetAdData,
  SetHashID,
  SetRequestedVariant,
  SetRequestedVariants,
  SET_AD_DATA,
  SET_HASH_ID,
  SET_REQUESTED_VARIANT,
  SET_REQUESTED_VARIANTS_DATA,
  UpdateComments,
  UpdateReviewStatus,
  UpdateSelectedVariantId,
  UPDATE_COMMENTS,
  UPDATE_REVIEW_STATUS,
  UPDATE_SELECTED_VARIANT_ID,
} from "store/types/CreativeAI/shared/Review/review";

const initialState: ReviewInitialState = {
  requestedVariantsMetaData: {
    isFailed: false,
    isLoading: true,
  },
  requestedVariantData: {
    isFailed: false,
    isLoading: true,
  },
  adData: {
    isFailed: false,
    isLoading: true,
  },
};

const reviewReducer = (
  state = initialState,
  action:
    | GetRequestedVariants
    | SetRequestedVariants
    | SetHashID
    | GetRequestedVariant
    | SetRequestedVariant
    | GetAdData
    | SetAdData
    | UpdateComments
    | UpdateSelectedVariantId
    | UpdateReviewStatus
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_HASH_ID:
      return {
        ...state,
        hashId: payload,
      };

    case GET_REQUESTED_VARIANTS_DATA:
      return {
        ...state,
        requestedVariantsMetaData: {
          ...state.requestedVariantsMetaData,
          isLoading: true,
        },
      };

    case SET_REQUESTED_VARIANTS_DATA: {
      return {
        ...state,
        requestedVariantsMetaData: payload,
      };
    }
    case GET_REQUESTED_VARIANT:
      return {
        ...state,
        requestedVariantData: {
          ...state.requestedVariantData,
          isLoading: true,
        },
      };

    case SET_REQUESTED_VARIANT:
      return {
        ...state,
        requestedVariantData: payload,
      };

    case GET_AD_DATA:
      return {
        ...state,
        adData: {
          ...state.adData,
          isLoading: true,
        },
      };

    case SET_AD_DATA:
      return {
        ...state,
        adData: payload,
      };
    case UPDATE_COMMENTS: {
      const { key, payloadData } = payload;
      return {
        ...state,
        requestedVariantData: {
          ...state.requestedVariantData,
          data: {
            ...state.requestedVariantData.data,
            variantMetaInfo: {
              ...state.requestedVariantData.data?.variantMetaInfo,
              [key]: {
                ...state.requestedVariantData.data?.variantMetaInfo[key],
                ...payloadData,
              },
            },
          },
        },
      };
    }
    case UPDATE_SELECTED_VARIANT_ID: {
      return {
        ...state,
        selectedVariantRequestedId: payload,
      };
    }
    case UPDATE_REVIEW_STATUS: {
      const requestedVariantsMetaData = cloneDeep(
        state.requestedVariantsMetaData
      );
      if (
        requestedVariantsMetaData?.data?.variants &&
        state.selectedVariantRequestedId !== undefined
      ) {
        requestedVariantsMetaData.data.variants[
          state.selectedVariantRequestedId
        ].reviewStatus = payload;
      }
      return {
        ...state,
        requestedVariantsMetaData,
      };
    }
    default:
      return state;
  }
};

export default reviewReducer;
