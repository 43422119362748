import { SECONDARY_TILES_DATA_CURRENCY_MAP } from "../constants/index";
export const getStatus = (value1: any, value2: any) => {
  if (!value1 || !value2) return "";

  if (value1 < -20) {
    if (value2 < -20) return "alarming";
    if (value2 > -20 && value2 < -10) return "alarming";
    if (value2 > -10 && value2 < 0) return "alarming";
    if (value2 > 0 && value2 < 5) return "escalation";
    if (value2 > 5 && value2 < 10) return "escalation";
    if (value2 > 10 && value2 < 20) return "escalation";
    if (value2 > 20) return "escalation";
  }

  if (value1 > -20 && value1 < -10) {
    if (value2 < -20) return "good";
    if (value2 > -20 && value2 < -10) return "inline";
    if (value2 > -10 && value2 < 0) return "inline";
    if (value2 > 0 && value2 < 5) return "alarming";
    if (value2 > 5 && value2 < 10) return "alarming";
    if (value2 > 10 && value2 < 20) return "escalation";
    if (value2 > 20) return "escalation";
  }

  if (value1 > -10 && value1 < 0) {
    if (value2 < -20) return "good";
    if (value2 > -20 && value2 < -10) return "good";
    if (value2 > -10 && value2 < 0) return "inline";
    if (value2 > 0 && value2 < 5) return "alarming";
    if (value2 > 5 && value2 < 10) return "alarming";
    if (value2 > 10 && value2 < 20) return "escalation";
    if (value2 > 20) return "escalation";
  }

  if (value1 > 0 && value1 < 5) {
    if (value2 < -20) return "good";
    if (value2 > -20 && value2 < -10) return "good";
    if (value2 > -10 && value2 < 0) return "good";
    if (value2 > 0 && value2 < 5) return "inline";
    if (value2 > 5 && value2 < 10) return "inline";
    if (value2 > 10 && value2 < 20) return "escalation";
    if (value2 > 20) return "escalation";
  }

  if (value1 > 5 && value1 < 10) {
    if (value2 < -20) return "good";
    if (value2 > -20 && value2 < -10) return "good";
    if (value2 > -10 && value2 < 0) return "good";
    if (value2 > 0 && value2 < 5) return "inline";
    if (value2 > 5 && value2 < 10) return "inline";
    if (value2 > 10 && value2 < 20) return "escalation";
    if (value2 > 20) return "escalation";
  }

  if (value1 > 10 && value1 < 20) {
    if (value2 < -20) return "good";
    if (value2 > -20 && value2 < -10) return "good";
    if (value2 > -10 && value2 < 0) return "inline";
    if (value2 > 0 && value2 < 5) return "inline";
    if (value2 > 5 && value2 < 10) return "alarming";
    if (value2 > 10 && value2 < 20) return "alarming";
    if (value2 > 20) return "escalation";
  }

  if (value1 > 20) {
    if (value2 < -20) return "alarming";
    if (value2 > -20 && value2 < -10) return "alarming";
    if (value2 > -10 && value2 < 0) return "alarming";
    if (value2 > 0 && value2 < 5) return "alarming";
    if (value2 > 5 && value2 < 10) return "alarming";
    if (value2 > 10 && value2 < 20) return "alarming";
    if (value2 > 20) return "escalation";
  }
};

const COLORS_HEX = {
  green: "#24B632",
  orange: "#FF9C02",
  red: "#ED3900",
};

// Common deviation logic for up & down arrow
export const getDeviationArrow = (deviation: number) => {
  if (deviation >= 0) return "up";
  if (deviation < 0) return "down";
};

// Deviation colors for Actual Spends & Daily Budget
export const getActualSpendsAndBudgetDeviationColor = (deviation: number) => {
  if (deviation >= -5 && deviation <= 5) return COLORS_HEX["green"];
  if (
    (deviation >= -10 && deviation < -5) ||
    (deviation > 5 && deviation <= 10)
  )
    return COLORS_HEX["orange"];
  if (deviation > 10 || deviation < -10) return COLORS_HEX["red"];
};

// Deviation colors for Actual Cost per RM
export const getActualCostDeviationColor = (deviation: number) => {
  if (deviation <= 0) return COLORS_HEX["green"];
  if (deviation > 0 && deviation <= 5) return COLORS_HEX["orange"];
  if (deviation > 5) return COLORS_HEX["red"];
};

export const getYearsArray = () => {
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let index = currentYear - 50; index < currentYear + 50; index++) {
    years.push(index);
  }
  return years;
};

export const getCurrency = (currency?: string) =>
  currency && currency !== ""
    ? SECONDARY_TILES_DATA_CURRENCY_MAP[currency] || "$"
    : " ";

export const getPlatformNameSmb = (platform: string) => {
  if (platform.toLowerCase().includes("linkedin")) {
    return "Linkedin";
  } else if (platform.toLowerCase().includes("facebook")) {
    return "Meta";
  } else if (platform.toLowerCase().includes("microsoft")) {
    return "Microsoft";
  } else if (platform.toLowerCase().includes("google")) {
    return "Google";
  } else if (platform.toLowerCase().includes("dv360")) {
    return "Dv360";
  } else {
    return "";
  }
};

export const sumObjectsByKey = (...objs:any):any => {
  const res = objs.reduce((a:any, b:any) => {
     for (let k in b) {
        if (b.hasOwnProperty(k))
        a[k] = (a[k] || 0) + b[k];
     }
     return a;
  }, {});
  return res;
}