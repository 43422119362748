import { Dialog, DialogTitle, styled } from "@mui/material";

export const StyledPxDialogTitle = styled(DialogTitle)`
  display: flex;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px 8px 0px 0px;
  font-size: ${({ isFrom1920Screen }: { isFrom1920Screen?: boolean }) =>
    isFrom1920Screen ? "0.839vw" : "1.1112vw"};
  @media screen and (max-width: 1280px) {
    font-size: ${({ isFrom1920Screen }: { isFrom1920Screen?: boolean }) =>
      isFrom1920Screen ? "12px" : "11px"};
  }
`;

export const StyledDialog = styled(Dialog)`
  font-size: ${({ isFrom1920Screen }: { isFrom1920Screen?: boolean }) =>
    isFrom1920Screen ? "0.839vw" : "1.1112vw"};
  @media screen and (max-width: 1280px) {
    font-size: ${({ isFrom1920Screen }: { isFrom1920Screen?: boolean }) =>
      isFrom1920Screen ? "12px" : "11px"};
  }
`;

export const StyledMinimizeWrapper = styled("span")`
  width: 32px;
  height: 32px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
