import {
  ADD_BUSINESS_UNIT,
  ADD_BUSINESS_UNIT_STATE,
  DELETE_BUSINESS_UNIT_STATE,
  GET_ALL_BUSINESS_UNITS,
  GET_BUSINESS_UNIT_BY_ID,
  SET_ALL_BUSINESS_UNITS,
  SET_BUSINESS_UNIT_BY_ID,
  UPDATE_BUSINESS_UNIT_STATE,
} from "../../../../types/CreativeAI/Facebook/CreateVariant/createVariantType";

const initialState = {
  businessUnits: { loading: false, data: [] },
  readyToUseBUs: [],
  singleBusinessUnit: null,
  addBusinessUnitState: {
    loading: false,
    success: "",
    error: "",
  },
  updateBusinessUnitState: {
    loading: false,
    success: "",
    error: "",
  },
  deleteBusinessUnitState: {
    loading: false,
    success: "",
    error: "",
  },
};

const businessUnitsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_BUSINESS_UNITS: {
      return {
        ...state,
        businessUnits: { loading: true },
      };
    }
    case SET_ALL_BUSINESS_UNITS: {
      return {
        ...state,
        businessUnits: { loading: false, data: action.payload },
        readyToUseBUs: {
          loading: false,
          data: action.payload.filter((bu: any) => bu.status === "readyToUse"),
        },
      };
    }
    case GET_BUSINESS_UNIT_BY_ID: {
      return {
        ...state,
        singleBusinessUnit: null,
      };
    }
    case SET_BUSINESS_UNIT_BY_ID: {
      return {
        ...state,
        singleBusinessUnit: action.payload,
      };
    }
    case ADD_BUSINESS_UNIT_STATE: {
      return {
        ...state,
        addBusinessUnitState: { ...action.payload },
      };
    }
    case UPDATE_BUSINESS_UNIT_STATE: {
      return {
        ...state,
        updateBusinessUnitState: { ...action.payload },
      };
    }
    case DELETE_BUSINESS_UNIT_STATE: {
      return {
        ...state,
        deleteBusinessUnitState: { ...action.payload },
      };
    }

    default:
      return state;
  }
};
export default businessUnitsReducer;
