import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTargetingForm } from "../../store/actions/form";
import { FormState } from "../../store/types/form";
import { CommonState } from "../../store/types/common";
import { defaultTargetingFormValues, UnlockTargetingAI } from "../../utils/constants/targetingAI";
import FormInput from "./FormInput";
import "./index.scss";
import { initialState } from "../../store/types/auth";
import { AdAccountState } from "../../store/types/adaccount";

type FormProps = {
  formType: "form" | "switchauto" | "addKeyword";
  modalProps?: any;
  onInputChange: (key: string, value: string | number, elementId?: string) => void;
};

const Form: React.FC<FormProps> = ({ formType, onInputChange, modalProps }) => {
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { industryType } = common;
  const auth = useSelector((state: { auth:initialState }) => state.auth);
  const [formFields, setFormFields] = React.useState(UnlockTargetingAI[formType]);
  const elementId: any = modalProps && modalProps.elementId ? (modalProps.elementId as string) : "";
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { forms, isOnBoarding } = formState;
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { selectedTargetingAdaccount, targetingGroups } = adaccount;
  const dispatch = useDispatch();

  const handleChanage = (key: string, value: any, groupId?: string) => {
    switch (key) {
      case "industryType":
        handleIndustryChange(value, formFields);
        break;

      case "modeOfApplication":
        let newFormFields: any = handleModeOfApplication(value);
        setFormFields(newFormFields);
        break;

      default:
        break;
    }
    onInputChange(key, value, groupId || "");
  };

  const handleIndustryChange = (value: any, newFormField: any) => {
    if (industryType) {
      let updatedForm:any = newFormField.map((val: any) =>
          val.key === "industryType"
            ? { ...val, options: industryType }
            : val);
      let industryIndex = industryType.findIndex((val: any) => val.value === value);
      if (industryIndex >= 0) {
        let newformFields: any = updatedForm.map((val: any) =>
          val.key === "industrySubtype"
            ? { ...val, options: industryType[industryIndex].subIndustries }
            : val
        );
        // console.log("New form",newformFields);
        setFormFields(newformFields);
      }
    }
  };

  const handleModeOfApplication = (value: any) => {
    if (value === "Manual") {
      return formFields.map((val) =>
        val.key === "audienceRange" || val.key === "relevanceRange"
          ? { ...val, show: false }
          : val
      );
    } else {
      return formFields.map((val) =>
        val.key === "audienceRange" || val.key === "relevanceRange" ? { ...val, show: true } : val
      );
    }
  };

  useEffect(() => {
    let newFormField: any;
    if (
      forms &&
      forms[elementId] &&
      forms[elementId].modeOfApplication
    ) {
      newFormField = handleModeOfApplication(forms[elementId].modeOfApplication);
    }
    if (
      forms &&
      forms[elementId] &&
      forms[elementId].industryType &&
      forms[elementId].industrySubtype &&
      industryType &&
      newFormField
    ) {
      handleIndustryChange(forms[elementId].industryType, newFormField);
    }
  }, [elementId]);

  useEffect(() => {
    if (
      elementId &&
      forms &&
      (forms[elementId])
    ) {
      dispatch(setTargetingForm(forms[elementId]));
    } else if (!elementId || (elementId && !forms[elementId])) {
      if (targetingGroups?.length > 0 && !elementId) {
        const elementIds:any = [];
        targetingGroups.forEach((a: any) => a.targtingElementID ? elementIds.push(a.targtingElementID) : "");
        defaultTargetingFormValues.elementIds= elementIds;
      }
      dispatch(setTargetingForm(defaultTargetingFormValues));
    }
    
  }, [elementId,modalProps,selectedTargetingAdaccount]);

  useEffect(() => {
    if (industryType && industryType[0] && (!elementId || !forms[elementId])) {
      let updatedForm:any = formFields.map((val: any) =>
        val.key === "industryType"
          ? { ...val, options: industryType }
          : val);
      let newformFields: any = updatedForm.map((val: any) =>
        val.key === "industrySubtype"
          ? { ...val, options: industryType[0].subIndustries }
          : val
      );
      setFormFields(newformFields);
    }
  }, [industryType])

  return (
    <>
      <div className="unlock-targeting-form">
        {formFields.map((value) => {
          return (
            <FormInput
              formKey={value.key}
              data={value}
              elementId={elementId}
              handleChanage={handleChanage}
            />
          );
        })}
      </div>
    </>
  );
};

export default Form;
