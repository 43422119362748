import { Box, Button } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../../../../../assets/cai/svg/arrow_collapsible.svg";

export const StyledCollapsibleVariantsPreviewBox = styled(Box)<{
  drawerOpen: boolean;
}>`
  height: 100%;
  background: #ffffff;
  box-shadow: 0em 0em 0.625em 0.3125em rgba(0, 0, 0, 0.02);
  border-radius: 0.5em;
  transform: ${(props) =>
    props.drawerOpen ? "translateX(0)" : "translateX(0.5em)"};
  width: ${(props) => (props.drawerOpen ? "22.1875em" : "2.5em")};
  position: relative;
  // transition: width 150ms, transform 150ms;
`;

export const StyledCollapsibleToggleButton = styled(Button)`
  box-sizing: border-box;
  position: absolute;
  width: 1.4em;
  height: 4.9em;
  left: -0.6875em;
  top: 0;
  background: #ffffff;
  border: 0.0625em solid #d7d7d7;
  border-radius: 0.25em;
  padding: 0em;
  min-width: auto;
  bottom: 0;
  margin: auto;
`;

export const StyledArrow = styled(Arrow)<{
  drawerOpen: boolean;
}>`
  transform: ${(props) =>
    props.drawerOpen ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 150ms;
`;

export const StyledVariantsPreview = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-x: scroll;
`;

export const StyledTabButtons = styled.div`
  border: 0.0625em solid #e5e5e5;
  border-radius: 0.5em;
  padding: 0.25em;
  display: flex;
  justify-content: space-evenly;
`;

export const StyledTabButton = styled.button<{ selected: boolean }>`
  padding: 0.625em;
  background: ${(props) => (props.selected ? "#e7f0ff" : "#fff")};
  border: 0.0625em solid;
  border-color: ${(props) => (props.selected ? "#0869fb" : "#fff")};
  box-shadow: 0px 0px 0.625em 0.3125em rgba(0, 0, 0, 0.02);
  border-radius: 0.25em;
  width: 50%;
  height: 2em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAdsManagerButton = styled.button`
  padding: 0.5em;
  width: 100%;
  height: 2.5em;
  background: #ffffff;
  border: 0.0625em solid #dddddd;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledExpandText = styled.span`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  height: 100%;
  text-align: center;
  letter-spacing: 0.125em;
  margin-left: 0.7em;
`;

export const StyledPreviewBox = styled(Box)``;

export const StyledErrorScreenBox = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledErrorContainer = styled(Box)`
  height: 10.9375em;
  width: 15.5625em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const StyledErrorSVG = styled(Box)`
  height: 7.6875em;
  width: 12.875em;
`;
export const StyledMessageContainer = styled(Box)`
  width: 100%;
  height: 1.875em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.25em;
  text-align: center;
  color: #666666;
`;
