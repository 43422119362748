import React, { useEffect, useRef } from "react";
import "./index.scss";

const InputLabel: React.FC<{
  limit?: number;
  label?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  inputClassName?: string;
  labelClassName?: string;
  validationClassName?: string;
  metricComponent?: any;
  onInputChange: (e: any) => void;
  data?: any;
}> = ({
  value,
  name,
  placeholder,
  inputClassName,
  labelClassName,
  validationClassName,
  onInputChange,
  limit,
  label,
  metricComponent: Component,
  data,
}) => {
  return (
    <div className="input-label-container">
      <input
        name={name}
        value={value}
        className={`input-label--input ${inputClassName}`}
        onChange={(e) => {
          onInputChange(e);
        }}
        maxLength={limit}
        placeholder={placeholder}
      />
      <div className="flex">
        {label && (
          <span className={`input-label--label flex-center ${labelClassName}`}>
            {label}
            {Component && <Component value={data} />}
          </span>
        )}

        <span
          className={`input-label--validation ${
            limit === value?.length ? "limit-reached" : ""
          } ${validationClassName}`}
        >
          {limit &&
            (value?.length === undefined ? 0 : value?.length) + `/` + limit}
        </span>
      </div>
    </div>
  );
};

export default InputLabel;
