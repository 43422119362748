import { Box, Pagination } from "@mui/material";
import { styled } from "@mui/system";
import { Button } from "components/shared";

export const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-outlined {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: -1px;
    background: #ffffff;
    border-radius: 0px;
    padding: 16px;
  }

  .Mui-selected {
    background: #e9f2ff !important;
    color: #0869fb;
  }
  .MuiPaginationItem-ellipsis {
    padding: 8px;
    background: transparent;
  }
`;

export const StyledGenerateMoreText = styled(Box)`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #0869fb;
`;

export const StyledGenerationButton = styled(Button)`
  background: #0869fb;
  border-radius: 10px;
  padding: 12px 24px;
  /* width: 300px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
`;
