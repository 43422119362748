import React, { useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import { ReactComponent as InfoIcon } from "../../assets/svg/infoIcon.svg";
import CustomSlider from "../../common/CustomSlider";
import { useSelector, useDispatch } from "react-redux";
import { setGSKeywordTuningData } from "store/actions/gsTargetingAI";

function ImpressionSignificance() {
  const dispatch = useDispatch();
  const { impressionSignificance } = useSelector(
    (state: any) => state.gsTargetingAI.gsKeywordTuning.data
  );
  const [selectedValue, setSelectedValue] = useState(impressionSignificance);

  const changeSelectedValue = (selectedValue: number) => {
    setSelectedValue(selectedValue);
    dispatch(
      setGSKeywordTuningData({
        impressionSignificance: selectedValue,
      })
    );
  };

  return (
    <Stack
      spacing={1}
      sx={{
        width: "50%",
        background: "#FFFFFF",
        border: "1px solid #EFEFEF",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
        borderRadius: "10px",
        m: 2,
        p: 2,
      }}
    >
      <Box className="font-12 font-600">
        Impressions Significance <InfoIcon />
      </Box>
      <Box className="font-10">
        Configure the balance of quality and quantity while considering
        Impressions
      </Box>
      <Divider
        variant="middle"
        style={{ borderColor: "#EFEFEF", opacity: "0.1" }}
      />
      <CustomSlider
        minValue={0}
        maxValue={100}
        selectedValue={selectedValue}
        setSelectedValue={changeSelectedValue}
        sliderFor="impressionSignificance"
      />
    </Stack>
  );
}

export default ImpressionSignificance;
