import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Toggle from "react-toggle";
import { ReactComponent as InfoIcon } from "../../assets/svg/infoIcon.svg";
import CustomRangeSlider from "../../common/CustomRangeSlider";
import { useSelector, useDispatch } from "react-redux";
import { setGSKeywordTuningData } from "store/actions/gsTargetingAI";

function AvgSearch() {
  const { monthlyAvgSearchCheck, monthlyAvgSearch } = useSelector(
    (state: any) => state.gsTargetingAI.gsKeywordTuning.data
  );
  const { GoogleKwsPlannerCheck, CalibrationCustomKeywordsCheck } = useSelector(
    (state: any) => state.gsTargetingAI.gsCalibration.data
  );

  const dispatch = useDispatch();

  const changeSelectedValue = ([min, max]: [number, number]) => {
    dispatch(
      setGSKeywordTuningData({
        monthlyAvgSearch: { min, max },
      })
    );
  };

  return (
    <Stack
      spacing={1}
      sx={{
        width: "50%",
        background: "#FFFFFF",
        border: "1px solid #EFEFEF",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
        borderRadius: "10px",
        m: 2,
        p: 2,
        opacity: `${
          (!GoogleKwsPlannerCheck && !CalibrationCustomKeywordsCheck) ||
          !monthlyAvgSearchCheck
            ? 0.5
            : 1
        }`,
      }}
    >
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="font-12 font-600">
          Monthly Average Search <InfoIcon />
        </Box>
        <Toggle
          disabled={!GoogleKwsPlannerCheck && !CalibrationCustomKeywordsCheck}
          checked={monthlyAvgSearchCheck}
          className="right-0 custom-react-toggle"
          icons={false}
          id="biscuit-status"
          aria-labelledby="biscuit-label"
          onChange={() => {
            if (monthlyAvgSearchCheck) {
              dispatch(
                setGSKeywordTuningData({
                  monthlyAvgSearchCheck: false,
                  monthlyAvgSearch: { min: 2000, max: 8000 },
                })
              );
            } else {
              dispatch(
                setGSKeywordTuningData({
                  monthlyAvgSearchCheck: true,
                  monthlyAvgSearch: {
                    min: monthlyAvgSearch.min,
                    max: monthlyAvgSearch.max,
                  },
                })
              );
            }
          }}
        />
      </Stack>
      <Box className="font-10">
        Filter the keywords on basis of monthly average search volume range
      </Box>
      <Divider
        variant="middle"
        style={{ borderColor: "#EFEFEF", opacity: "0.1" }}
      />
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="font-12 font-600">Selected Value</Box>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            className="font-12 font-600"
            sx={{
              background: "#EAF1F7",
              color: "#0869FB",
              borderRadius: "5px",
              p: 1,
            }}
          >
            {monthlyAvgSearch.min}
          </Box>
          <Box sx={{ p: 1, color: "#DDDDDD" }}>-</Box>
          <Box
            className="font-12 font-600"
            sx={{
              background: "#EAF1F7",
              color: "#0869FB",
              borderRadius: "5px",
              p: 1,
            }}
          >
            {monthlyAvgSearch.max >= 10000 ? "10000+" : monthlyAvgSearch.max}
          </Box>
        </Stack>
      </Stack>
      <CustomRangeSlider
        minValue={10}
        maxValue={10000}
        selectedValue={[monthlyAvgSearch.min, monthlyAvgSearch.max]}
        setSelectedValue={changeSelectedValue}
        showTooltip
        disabled={!monthlyAvgSearchCheck}
        showMarks
      />
    </Stack>
  );
}

export default AvgSearch;
