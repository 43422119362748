import Select from "@mui/material/Select";
import { styled as MUIStyled } from "@mui/material/styles";
import styled from "styled-components";
import { SelectProps } from "./Select";

export const StyledSelect = MUIStyled(Select)`
border: ${(props: SelectProps) =>
  props.isBorder
    ? `.0625rem solid ${props.borderColor ?? "#E5E5E5"}`
    : "white"};
  background: ${(props: SelectProps) =>
    props.backgroundColor ?? "rgba(242, 242, 242, 0.5)"};
  border-radius: .5rem;
  height: ${(props: SelectProps) => props.height ?? "2.6875rem"};
  color:#333333;  
.MuiSelect-standard {
  border-radius: .5rem;
  padding: .75rem 1rem;
}
`;

export const StyledMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const StyledMenuContent = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #313131;
`;

export const StyledMenuLabel = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: #4f4f4f;
`;

export const StyledSelectContainer = styled.div`
  margin-bottom: 20px;
  height: 43;
`;
