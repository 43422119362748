import React from "react";
import {
  StyledMainContainer,
  StyledMessageContainer,
  StyledSvgContainer,
} from "./styled";
import { ReactComponent as Issue1 } from "../../../../assets/cai/svg/TechnicalIssue1.svg";
import { ReactComponent as Issue2 } from "../../../../assets/cai/svg/TechnicalIssue2.svg";
import { ReactComponent as Refresh } from "../../../../assets/cai/svg/Refresh.svg";
import { Button } from "components/shared";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAdsFB } from "store/actions/CreativeAI_Revamp/facebook.actions";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import {
  getAds,
  getVariants,
} from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { OUTCOME_SALES_AD_LIMIT } from "../utils/const";
interface Props {
  type: "Ad" | "Variant";
}
function TechnicalIssue({ type }: Props) {
  const dispatch = useDispatch();
  const { adAccountsSelection, adSetSelection, adsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );

  const { adsetDetails } = useSelector(
    (state: { caiFBReducer: any }) => state.caiFBReducer
  );

  const { selectedAd } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  return (
    <StyledMainContainer>
      <StyledSvgContainer>
        {type == "Variant" ? <Issue1 /> : <Issue2 />}
      </StyledSvgContainer>
      <StyledMessageContainer>
        There's some technical Issue , kindly refresh
      </StyledMessageContainer>
      <Button
        variant="contained"
        sx={{
          fontFamily: "Inter",
          backgroundColor: "#E6F6FF",
          color: "#0869FB",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "0.875em",
          lineHeight: "1.43em",
          width: "8.28em",
          height: "2.85em",
          padding: "0.571em 1.715em",
          boxShadow: "none",
          ":hover": {
            backgroundColor: "#E6F6FF",
            color: "#0869FB",
            boxShadow: "none",
          },
        }}
        onClick={() => {
          type == "Ad"
            ? dispatch(
                getAds({
                  adSetId: adSetSelection?.selectedAdSetId,
                  adAccountId: adAccountsSelection?.selectedAdAccountId,
                  ...(adsetDetails?.facebookData?.campaign_objective ==
                  "OUTCOME_SALES"
                    ? { adsCount: OUTCOME_SALES_AD_LIMIT }
                    : {}),
                })
              )
            : dispatch(getVariants({ adId: selectedAd.id }));
        }}
      >
        <Box display={"flex"} justifyContent="center" alignItems={"center"}>
          <Refresh style={{ height: "0.857em", marginRight: "0.571em" }} />{" "}
          Refresh
        </Box>
      </Button>
    </StyledMainContainer>
  );
}
export default TechnicalIssue;
