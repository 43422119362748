import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";

interface IButtonProps {
  variant: "text" | "contained" | "outlined";
  label: string;
  type?: "button" | "submit";
  onClick?: React.MouseEventHandler<any>;
  size?: "small" | "medium" | "large";
  form?: string;
  sx?: any;
}
function ButtonComponent(props: IButtonProps) {
  const {
    variant,
    label,
    type = "button",
    onClick = undefined,
    size = "medium",
    form,
    sx,
  } = props;

  const ButtonStyle = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: "center",
    boxShadow: "none",
    fontFamily: "Inter",
    color: `${
      variant === "text" || variant === "outlined" ? "#3F64FA" : "#FFFFFF"
    }`,
    backgroundColor: `${
      variant === "text"
        ? ""
        : variant === "contained"
        ? "#3F64FA"
        : "transparent"
    }`,
    border: `${variant === "outlined" ? "1px solid #3F64FA" : "transparent"}`,
    borderRadius: "8px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: `${
        variant === "text" || variant === "outlined"
          ? "transparent"
          : "rgba(63,100,250,0.8)"
      }`,
      boxShadow: "none",
    },
  }));

  return (
    <ButtonStyle
      disableRipple
      variant={variant}
      type={type}
      onClick={onClick}
      size={size}
      form={form}
      sx={sx}
    >
      <Typography
        variant="button"
        sx={{
          textTransform: "none",
          fontWeight: 600,
          fontFamily: "Inter !important",
        }}
      >
        {label}
      </Typography>
    </ButtonStyle>
  );
}

export default ButtonComponent;
