import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactComponent as NoData } from "../assests/no-data-man.svg";

function NoKWData({ svg, heading, description }: any) {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        backgroundColor: "white",
        borderRadius: "0.5rem",
        border: "0.0625rem solid #DDDDDD",
        height: "88%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {svg || <NoData />}
      </Box>
      <Box>
        <Typography align="center" className="font-18 font-weight-700">
          {heading || "No Data found"}
        </Typography>
        <Typography
          align="center"
          padding="0.5rem 3rem 1rem 3rem"
          className="font-14"
          sx={{ color: "#9E9E9E" }}
        >
          {description || (
            <>
              No Data found for the selected lookback period <br />
              Please go to the calibration and reconfigure
            </>
          )}
        </Typography>
      </Box>
    </Stack>
  );
}

export default NoKWData;
