export const STORE_GS_TAI_GENERATE_KEYWORDS = "STORE_GS_TAI_GENERATE_KEYWORDS";
export const GET_GS_TAI_GENERATED_KEYWORDS = "GET_GS_TAI_GENERATED_KEYWORDS";
export const SET_GS_CALIBRATION_DATA = "SET_GS_CALIBRATION_DATA";
export const SET_GS_KEYWORD_TUNING_DATA = "SET_GS_KEYWORD_TUNING_DATA";
export const RESET_GS_CALIBRATION_DATA = "RESET_GS_CALIBRATION_DATA";
export const RESET_GS_KEYWORD_TUNING_DATA = "RESET_GS_KEYWORD_TUNING_DATA";
export const RESET_ADDITIONAL_INPUT_DATA = "RESET_ADDITIONAL_INPUT_DATA";

export type StoreGSTaiGenerateKeywords = {
  type: typeof STORE_GS_TAI_GENERATE_KEYWORDS;
  payload: any;
  callback?: Function;
};

export type GetGSTaiGeneratedKeywords = {
  type: typeof GET_GS_TAI_GENERATED_KEYWORDS;
  payload: {
    request_ids: string;
  };
  callback?: Function;
};

export type SetGSCalibrationData = {
  type: typeof SET_GS_CALIBRATION_DATA;
  payload: {
    ExploreValueCheck?: boolean;
    LookbackPeriod?: string;
    GoogleKwsPlannerCheck?: boolean;
    CalibrationCustomKeywordsCheck?: boolean;
    CalibrationCustomKeywords?: string[];
  };
};

export type SetGSKeywordTuningData = {
  type: typeof SET_GS_KEYWORD_TUNING_DATA;
  payload: {
    keywordCostCheck?: boolean;
    keywordCostValue?: number;
    impressionSignificance?: number;
    competationIndexCheck?: boolean;
    competationIndex?: {
      high?: boolean;
      medium?: boolean;
      low?: boolean;
    };
    monthlyAvgSearchCheck?: boolean;
    monthlyAvgSearch?: {
      min?: number;
      max?: number;
    };
  };
};

export type ResetGSCalibrationData = {
  type: typeof RESET_GS_CALIBRATION_DATA;
};

export type ResetGSKeywordTuningData = {
  type: typeof RESET_GS_KEYWORD_TUNING_DATA;
};

export type ResetAdditionalInputData = {
  type: typeof RESET_ADDITIONAL_INPUT_DATA;
};
