import React, { useEffect } from "react";
import { Handle, useReactFlow } from "react-flow-renderer";
import { ReactComponent as LoaderCollapsed } from "./Assets/svgs/loaderCollapsed.svg";

function LoaderAudienceExplorerCollapsed({ data }) {
  return (
    <div className="loaderCollapse">
      <Handle type="target" position="left" />
      <Handle type="source" position="right" />
      <div style={{ position: "absolute", top: "0.625rem", left: "0.688rem" }}>
        <LoaderCollapsed />
      </div>
    </div>
  );
}

export default LoaderAudienceExplorerCollapsed;
