import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { TAGS } from "../constants";

function StatusTag({ type }: any) {
  return (
    <Card
      sx={{
        display: "flex",
        backgroundColor: TAGS[type]?.bgcolor,
        borderRadius: "4px",
        alignItems: "center",
        justifyContent: type === "" ? "center" : "space-between",
        padding: "0 10px",
      }}
    >
      <Box sx={{ display: "flex", marginRight: "5px" }}>
        {type !== "" && TAGS[type]?.svg({ height: "16px" })}
      </Box>
      <Box sx={{ display: "flex", width: "5rem" }}>
        <CardContent sx={{ flex: "1 0 auto", p: "0 !important" }}>
          <Typography
            variant="subtitle1"
            color={TAGS[type]?.color}
            component="div"
            textAlign="center"
          >
            {TAGS[type]?.text}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}

export default StatusTag;
