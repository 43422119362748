import SearchComponent from 'Pages/SmbDashboard/common/SearchComponent';
import React,{useRef,useState} from 'react'
import { useDispatch } from 'react-redux';
import { setAiInitiationValue } from 'store/actions/targetingAI';
import CheckBox from 'ui/CheckBox/CheckBox';
import taiDropdownCheckmark from "../../../assets/tai/taiDropdownCheckmark.svg";
import { Checkbox } from '@mui/material';
import {ReactComponent as CheckedIcon} from '../assets/svgs/checkedIcon.svg';
import {ReactComponent as UncheckedIcon } from '../assets/svgs/uncheckedIcon.svg';
import { StyledCheckedIcon } from 'components/shared/Input/CheckBox/styled';
let selectedVals = {
  selectedRegionVals:[],
  selectedCountryVals:[]
};
function TAIMultiSelectDropdown({
    optionsList,
    dropdown,
    selectedAiGroup,
    selectedCountry,
    selectedRegion,
    searchBar = true,
    multiselect = false,
    classes,
    loading,
    customDropdown = false,
    selectedAudience,
    setSelectedAdAccount,
    selectAll,
    setSelectAll
}) {

    const dispatch = useDispatch();
    const [dropdownOptions, setDropdownOptions] = React.useState(optionsList);

    const [filterText, setFilterText] = React.useState("");
    const wrapperRef = useRef(null);
    const handleSearch = (filterText) => {
      setFilterText(filterText);
      const filteredOptionsList = {};
      for (let item in optionsList) {
        if (
          optionsList[item]["name"]
            .toLowerCase()
            .includes(filterText.toLowerCase())
        ) {
          filteredOptionsList[item] = optionsList[item];
        }
      }
      setDropdownOptions(filteredOptionsList);
    };
    const handleClickOutside = (e) => {
      const {classList} = e.target;
      const isInputBase = classList.contains("MuiInputBase-input");
      const isBtnAiGrp = classList.contains("btn-aigroup");
      const isMuiCheckbox = classList.contains("MuiCheckbox-root");
      const isOutsideNodeBlock = (classList.contains("react-flow__container") || classList.contains("react-flow__node"));
      if (
        !isInputBase &&
        !isBtnAiGrp && 
        !isMuiCheckbox &&
        !classList.value === "label-text" &&
        classList.length || isOutsideNodeBlock
      ) {
        dispatch(
          setAiInitiationValue({
            value: -1,
            key: "dropdown",
          })
        );
      }
    };
  
    React.useEffect(() => {
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);

    const segregateSelectedChecboxValues = (segregateValueKey,option)=> { 
      if(!selectedVals[segregateValueKey]?.length){
        selectedVals[segregateValueKey] = [...selectedVals[segregateValueKey],option];
     }
     else if(!selectedVals[segregateValueKey]?.some((selectedVal) => selectedVal.name === option.name)){
        selectedVals[segregateValueKey]=[...selectedVals[segregateValueKey],option];
     }
     else{
        if(segregateValueKey === "selectedRegionVals"){
            setSelectAll(false);
        }
        selectedVals[segregateValueKey] = selectedVals[segregateValueKey]?.filter((selectedVal)=>selectedVal.name !== option.name);
     }
    }
    const selectedValuesFromCheckbox = (option) => {
      if(dropdown === 2){
        segregateSelectedChecboxValues("selectedCountryVals",option);
      }else {
        segregateSelectedChecboxValues("selectedRegionVals",option);
      }
       
    }
    const updateData = (e, option, selectAll) => {
        if (dropdown === 2) {
        selectedVals.selectedRegionVals = [];
        dispatch(
          setAiInitiationValue({
            value:selectedVals.selectedCountryVals,
            key: "selectedCountry",
          })
        );
        dispatch(
            setAiInitiationValue({
              value: null,
              key: "regionOptions",
            })
          );
          dispatch(
            setAiInitiationValue({
              value: [],
              key: "selectedRegion",
            })
          );
          // setSelectAll(false);
      } else if (dropdown === 3) {
        dispatch(
          setAiInitiationValue({
            value:selectedVals.selectedRegionVals,
            key: "selectedRegion",
          })
        );
      } else {
        selectedAiGroup(option);
      }
    };
    React.useEffect(()=>{
      if(Object.keys(dropdownOptions).length === selectedRegion.length){
        setSelectAll(true);
        selectedVals.selectedRegionVals = selectedRegion;
        return;
      }
      if(!selectAll && Object.keys(dropdownOptions).length !== selectedRegion.length){
        selectedVals.selectedRegionVals = selectedRegion;
        return;
      }
   },[selectAll,setSelectAll,selectedRegion]);
  return (
    <>
    <div
      className={`cursor-pointer ${classes} scroll-bar-hidden`}
      style={{
        backgroundColor: "#FAFAFA",
        zIndex: "50",
        border: "1px solid #F0F0F0",
        borderTop: "0px",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
        borderRadius: "8px",
        maxWidth: "95%",
        maxHeight: `${loading ? "5.5rem" : "12.5rem"}`,
        height: "100%",
        position:'relative'
      }}
    >
      {searchBar ? (
        <div
          style={{
            backgroundColor: "#FAFAFA",
            position:'fixed',
            zIndex:2,
          }}
        >
          <SearchComponent
            searchTerm={filterText}
            setSearchTerm={handleSearch}
            placeholder={"Search Term"}
            width="100%"
            custom={true}
            autoFocus
          />
        </div>
      ) : (
        <></>
      )}
      {(!dropdownOptions || Object.keys(dropdownOptions).length <= 0) && (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          No Data Found
        </div>
      )}
      {dropdownOptions && Object.keys(dropdownOptions).length > 0 && (
        <ul
          className="w-100 scroll-bar-hidden"
          style={{ listStyle: "none", height: "100%",overflowY: "auto",padding:'48px 4px 4px 4px' }}
        >
          {dropdown===3 && <li>
            <div>
            <Checkbox
                checkedIcon={<CheckedIcon/>}
                icon={<UncheckedIcon/>}
                checked={selectAll}
                onClick={(e) => {
                  setSelectAll((prev) => {
                    if(!prev){
                      dispatch(
                        setAiInitiationValue({
                          value:[...Object.values(dropdownOptions)],
                          key: "selectedRegion",
                        })
                      );
                    }else{
                      dispatch(
                        setAiInitiationValue({
                          value:[],
                          key: "selectedRegion",
                        })
                      );
                    }
                    return !prev;
                  });
                }}
                id="select_all"
            />
            <label className="label-text" htmlFor="select_all">Select All</label>
          </div>
          </li>}
          {!customDropdown &&
            dropdownOptions &&
            Object.keys(dropdownOptions).map((option, index) => (
              <li
                id={`select-option-${index}`}
                className="py-0.5 pr-2 text-sm select-option w-100 select-option-aigroup flex justify-between"
                data-name={option}
                key={option}
                role="presentation"
              >
                <div className="flex items-center">
                <Checkbox
                    checkedIcon={<CheckedIcon/>}
                    icon={<UncheckedIcon/>}
                    checked={(selectedCountry.map((country)=>country.name).includes(dropdownOptions[option].name) || selectedRegion.map((country)=>country.name).includes(dropdownOptions[option].name))}
                    onClick={(e) => {
                        selectedValuesFromCheckbox(dropdownOptions[option]);
                        updateData(e, dropdownOptions[option]);
                    }}
                    id={dropdownOptions[option]?.name}
                />
                <label className="label-text pt-2" htmlFor={dropdownOptions[option].name}>{dropdownOptions[option].name}</label>
                </div>
                {dropdown===3 && <div>{dropdownOptions[option].country_name}</div>}
              </li>
            ))}
        </ul>
      )}
    </div>
  </>
  )
}

export default TAIMultiSelectDropdown
