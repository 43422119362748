import {
  AddVarianButton,
  AddVariant,
  ADD_VARIANT,
  ADD_VARIANT_BUTTON,
  COPY_VARIANT,
  DeleteVariantAPICall,
  DELETE_VARIANT_API_CALL,
  DiscardDraftVariant,
  DISCARD_DRAFT_VARIANT,
  GenerateAdPreview,
  GENERATE_AD_PREVIEW,
  GetAdPreviewLink,
  GetCreateSpec,
  GetRecommendationMetadata,
  GetVariantList,
  GET_ADS_LIST,
  GET_AD_PREVIEW_LINK,
  GET_CREATE_SPEC,
  GET_RECOMMENDATION_METADATA,
  GET_VARIANT_LIST,
  PublishVariant,
  PUBLISH_VARIANT,
  ResetState,
  RESET_STATE,
  SetCreateSpec,
  SetSelectedAds,
  SetVariantList,
  SET_CREATE_SPEC,
  SET_RECOMMENDATION_LOADING,
  SET_VARIANT_LIST,
  SET__SELECTED_ADS,
  UpdateAds,
  UpdateCreateSpec,
  UpdateExtraField,
  UpdateRecommendationMetadata,
  UpdateRecommendationSelectionState,
  UpdateTotalVariantSelected,
  UpdateVariant,
  UpdateVariantState,
  UPDATE_ADS,
  UPDATE_CREATE_SPEC,
  Is_Show_SaveVariant_Prompt,
  UPDATE_EXTRA_FIELD,
  UPDATE_RECOMMENDATION_METADATA,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
  UPDATE_TOTAL_VARIANT_SELECTED,
  UPDATE_VARIANT,
  UPDATE_VARIANT_STATE,
  GENERATE_VARIANT_METADATA,
  IS_CONFIGURING_STATE,
  SET_UPDATE_AI_GROUP_PROMPT,
  SET_RECOMMENDATION_METADATA_ERROR,
  SET_TIMESTAMP,
  UPDATE_CONFIGURE_STATE,
  ADD_UPDATE_VARIANT_LOADING_STATE,
  SetChangedFieldList,
  SET_CHANGED_FIELD_LIST,
  SetAutoGenerateLoading,
  SET_AUTOGENERATE_LOADING,
  GenerateAdPreviewBundle,
  GENERATE_AD_PREVIEW_BUNDLE,
  SET_LOADING_COMPLETE,
  SetLoadingComplete,
  SetFetchingComplete,
  SET_FETCHING_COMPLETE,
  SubmitForReview,
  SUBMIT_FOR_REVIEW,
  APPROVER_LINK,
  SubmitForReviewError,
  SUBMIT_FOR_REVIEW_ERROR,
  SetApproverLink,
  RE_PUBLISH_VARIANT,
  RePublishVariant,
  DeleteSelectedVariants,
  DeleteSelectedVariantsPayload,
  DELETE_SELECTED_VARIANT,
  SET_DELETE_SELECTED_VARIANT,
  SetDeleteSelectedVariantsPayload,
  SetDeleteSelectedVariants,
  SetFilteredVariants,
  SET_FILTERED_VARIANT_LIST,
  SetUnselectTotalVariants,
  UNSELECT_TOTAL_VARIANTS,
  SET_ADS_OTHER_STATE,
  SET_VARIANTS_NEW_TAGS,
  SetVariantsNewTag,
} from "../../../../types/CreativeAI/Facebook/CreateVariant/createVariant.types";

export const getAds = (payload?: any) => {
  return {
    type: GET_ADS_LIST,
    payload,
  };
};
export const getAdsnew = (payload?: any) => {
  return {
    type: GET_ADS_LIST,
    payload,
  };
};

export const getVariants = (payload: any): GetVariantList => {
  return {
    type: GET_VARIANT_LIST,
    payload,
  };
};

export const resetState = (payload: any): ResetState => {
  return {
    type: RESET_STATE,
    payload,
  };
};

export const setVariants = (payload: any): SetVariantList => {
  return {
    type: SET_VARIANT_LIST,
    payload,
  };
};

export const getRecommendationMetadata = (
  payload: any
): GetRecommendationMetadata => {
  return {
    type: GET_RECOMMENDATION_METADATA,
    payload,
  };
};

export const updateRecommendationMetadata = (
  payload: any
): UpdateRecommendationMetadata => {
  return {
    type: UPDATE_RECOMMENDATION_METADATA,
    payload,
  };
};

export const addVariant = (payload: any): AddVariant => {
  return {
    type: ADD_VARIANT,
    payload,
  };
};
export const addVarianButton = (payload: any): AddVarianButton => {
  return {
    type: ADD_VARIANT_BUTTON,
    payload,
  };
};

export const setRecommendationLoading = (payload: any): any => {
  return {
    type: SET_RECOMMENDATION_LOADING,
    payload,
  };
};

export const getCreateSpec = (payload: any): GetCreateSpec => {
  return {
    type: GET_CREATE_SPEC,
    payload,
  };
};

export const setAutoGenerateLoading = (
  payload: any
): SetAutoGenerateLoading => {
  return {
    type: SET_AUTOGENERATE_LOADING,
    payload,
  };
};

export const setLoadingComplete = (payload: any): SetLoadingComplete => {
  return {
    type: SET_LOADING_COMPLETE,
    payload,
  };
};

export const setFetchingComplete = (payload: any): SetFetchingComplete => {
  return {
    type: SET_FETCHING_COMPLETE,
    payload,
  };
};

export const setSelectedAd = (payload: any): SetSelectedAds => {
  return {
    type: SET__SELECTED_ADS,
    payload,
  };
};

export const getAdPreviewLink = (payload: any): GetAdPreviewLink => {
  return {
    type: GET_AD_PREVIEW_LINK,
    payload,
  };
};

export const deleteVariantAPICall = (payload: any): DeleteVariantAPICall => {
  return {
    type: DELETE_VARIANT_API_CALL,
    payload,
  };
};
export const updateExtraField = (payload: any): UpdateExtraField => {
  return {
    type: UPDATE_EXTRA_FIELD,
    payload,
  };
};

export const updateVariant = (payload: any): UpdateVariant => {
  return {
    type: UPDATE_VARIANT,
    payload,
  };
};

export const copyVariant = (payload: any): any => {
  return {
    type: COPY_VARIANT,
    payload,
  };
};

export const updateVariantState = (payload: any): UpdateVariantState => {
  return {
    type: UPDATE_VARIANT_STATE,
    payload,
  };
};

export const updateRecommendationSelectionState = (
  payload: any
): UpdateRecommendationSelectionState => {
  return {
    type: UPDATE_RECOMMENDATION_SELECTION_STATE,
    payload,
  };
};

export const generateAdPreview = (payload: any): GenerateAdPreview => {
  return {
    type: GENERATE_AD_PREVIEW,
    payload,
  };
};

export const generateAdPreviewBundle = (
  payload: any
): GenerateAdPreviewBundle => {
  return {
    type: GENERATE_AD_PREVIEW_BUNDLE,
    payload,
  };
};

export const updateAds = (payload: any): UpdateAds => {
  return {
    type: UPDATE_ADS,
    payload,
  };
};
export const updateTotalVariantSelected = (
  payload: number
): UpdateTotalVariantSelected => {
  return {
    type: UPDATE_TOTAL_VARIANT_SELECTED,
    payload,
  };
};

export const setUnselectTotalVariants = (): SetUnselectTotalVariants => {
  return {
    type: UNSELECT_TOTAL_VARIANTS,
  };
};
export const discardDraftVariant = (payload: any): DiscardDraftVariant => {
  return {
    type: DISCARD_DRAFT_VARIANT,
    payload,
  };
};

export const publishVariant = (payload: any): PublishVariant => {
  return {
    type: PUBLISH_VARIANT,
    payload,
  };
};
export const rePublishVariant = (payload: any): RePublishVariant => {
  return {
    type: RE_PUBLISH_VARIANT,
    payload,
  };
};

export const submitForReview = (payload: {
  params: any;
  callback?: any;
}): SubmitForReview => {
  return {
    type: SUBMIT_FOR_REVIEW,
    payload,
  };
};

export const setApproverLink = (payload: {
  urlHash: string;
}): SetApproverLink => {
  return {
    type: APPROVER_LINK,
    payload,
  };
};

export const submitForReviewError = (payload: any): SubmitForReviewError => {
  return {
    type: SUBMIT_FOR_REVIEW_ERROR,
    payload,
  };
};

export const generateVariantMatadata = (payload?: any) => {
  return {
    type: GENERATE_VARIANT_METADATA,
    payload,
  };
};
export const updateCreateSpec = (payload: any): UpdateCreateSpec => {
  return {
    type: UPDATE_CREATE_SPEC,
    payload,
  };
};

export const setCreateSpec = (payload: any): SetCreateSpec => {
  return {
    type: SET_CREATE_SPEC,
    payload,
  };
};

export const setConfigurationState = (payload: any): any => {
  return {
    type: IS_CONFIGURING_STATE,
    payload,
  };
};
export const isShowSaveVariantPromptAction = (payload: any): any => {
  return {
    type: Is_Show_SaveVariant_Prompt,
    payload,
  };
};

export const showUpdateAISuccessMsgAction = (payload: any): any => {
  return {
    type: SET_UPDATE_AI_GROUP_PROMPT,
    payload,
  };
};

export const setRecommendationError = (payload: any): any => {
  return {
    type: SET_RECOMMENDATION_METADATA_ERROR,
    payload,
  };
};

export const setTimeStamp = (payload: any): any => {
  return {
    type: SET_TIMESTAMP,
    payload,
  };
};

export const updateConfigureState = (payload: any): any => {
  return {
    type: UPDATE_CONFIGURE_STATE,
    payload,
  };
};
export const addUpdateVariantLoadingState = (payload: any): any => {
  return {
    type: ADD_UPDATE_VARIANT_LOADING_STATE,
    payload,
  };
};

export const setChangedFieldList = (payload: any): SetChangedFieldList => {
  return {
    type: SET_CHANGED_FIELD_LIST,
    payload,
  };
};

export const deleteSelectedVariants = (
  payload: DeleteSelectedVariantsPayload
): DeleteSelectedVariants => ({
  type: DELETE_SELECTED_VARIANT,
  payload,
});

export const setDeleteSelectedVariants = (
  payload: SetDeleteSelectedVariantsPayload
): SetDeleteSelectedVariants => ({
  type: SET_DELETE_SELECTED_VARIANT,
  payload,
});
export const setFileredVariants = (payload: any): SetFilteredVariants => {
  return {
    type: SET_FILTERED_VARIANT_LIST,
    payload,
  };
};

export const setAdsOtherState = (payload: any) => ({
  type: SET_ADS_OTHER_STATE,
  payload,
});

export const setVariantsNewTag = (payload: any): SetVariantsNewTag => ({
  type: SET_VARIANTS_NEW_TAGS,
  payload,
});
