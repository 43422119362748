import { Box, CircularProgress, IconButton } from "@mui/material";
import { LightTooltip } from "components/shared/LightTooltip";
import { StyledBlurBox, StyledErrorScreenBox } from "./styled";
import { ReactComponent as RefreshIcon } from "../../../../.././assets/svg/refresh_flow.svg";
import { ReactComponent as LaunchBlueIcon } from "../../../../.././assets/svg/launch_blue_new.svg";
import { useDispatch, useSelector } from "react-redux";
import { getMediaStudioProcessing } from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import { GetMediaStudioProcessingReduxType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { useEffect, useState } from "react";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { NavigationInitialState } from "store/types/Navigation/navigation.types";
import { setVariants } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";

export default function BlurComponent({
  willTransform,
  item,
  setMediaURL,
}: {
  willTransform: any;
  item: any;
  setMediaURL: any;
}) {
  const [isMediaUrlLoading, setIsMediaUrlLoading] = useState(false);
  const { createSpec, variantList } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );

  const dispatch = useDispatch();
  const onRefresh = () => {
    if (item?.CreativeAiData?.billboardMediaId) {
      setIsMediaUrlLoading(true);
      dispatch(
        getMediaStudioProcessing(
          {
            reqId: [item?.CreativeAiData?.billboardMediaId],
            type: "billBoard",
          },
          (response: GetMediaStudioProcessingReduxType[]) => {
            if (response[0]?.url) {
              setMediaURL(response[0]?.url);
              const localVariantList = [...variantList];
              const index = localVariantList.findIndex(
                (variant) => variant?.id == item?.id
              );
              if (
                localVariantList[index]?.CreativeAiData?.billboardMediaId &&
                !localVariantList[index]?.CreativeAiData?.billboardMediaData
                  ?.videoUrl
              ) {
                localVariantList[index] = {
                  ...localVariantList[index],
                  CreativeAiData: {
                    ...localVariantList[index].CreativeAiData,
                    billboardMediaData: {
                      ...localVariantList[index].CreativeAiData
                        .billboardMediaData,
                      videoUrl: response[0]?.url,
                    },
                  },
                };
                dispatch(setVariants({ data: localVariantList }));
              }
            }
            setIsMediaUrlLoading(false);
          }
        )
      );
    }
  };

  const onRedirectUrl = () => {
    if (item?.CreativeAiData?.billboardMediaData?.redirectUrl) {
      window.open(
        item?.CreativeAiData?.billboardMediaData?.redirectUrl,
        "_blank"
      );
    }
  };

  return (
    <StyledErrorScreenBox>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          minWidth: 0,
        }}
      >
        <img
          height="auto"
          width="100%"
          src={createSpec?.image_url}
          alt="blur-original"
          onLoad={() => {
            willTransform();
          }}
        />
      </Box>
      <StyledBlurBox>
        <LightTooltip title="Refresh Media File" arrow placement="bottom-end">
          <IconButton onClick={onRefresh}>
            {isMediaUrlLoading ? (
              <Box
                height={"36px"}
                width={"36px"}
                display="flex"
                justifyContent={"center"}
                alignItems="center"
              >
                <CircularProgress
                  size={25}
                  sx={{
                    color: "#0869FB",
                  }}
                  thickness={5}
                />
              </Box>
            ) : (
              <RefreshIcon
                style={{
                  height: "36px",
                  width: "36px",
                }}
              />
            )}
          </IconButton>
        </LightTooltip>
        <LightTooltip
          title="Open Creative Animator"
          arrow
          placement="bottom-start"
        >
          <IconButton onClick={onRedirectUrl}>
            <LaunchBlueIcon
              style={{
                height: "36px",
                width: "36px",
              }}
            />
          </IconButton>
        </LightTooltip>
      </StyledBlurBox>
    </StyledErrorScreenBox>
  );
}
