import { ReactComponent as Success } from "../assets/Success.svg";
import { ReactComponent as Error } from "../assets/Error.svg";
import { ReactComponent as Pending } from "../assets/Pending.svg";
import { ReactComponent as InProgress } from "../assets/InProgress.svg";
import { ReactComponent as Adjust } from "../assets/dataSource/Adjust.svg";
import { ReactComponent as Appsflyer } from "../assets/dataSource/Appsflyer.svg";
import { ReactComponent as CRM } from "../assets/dataSource/CRM.svg";
import { ReactComponent as GoogleAnalytics } from "../assets/dataSource/GoogleAnalytics.svg";
import { ReactComponent as Salesforce } from "../assets/dataSource/Salesforce.svg";
import { ReactComponent as Shopify } from "../assets/dataSource/Shopify.svg";
import { ReactComponent as Others } from "../assets/dataSource/Others.svg";
import { ReactComponent as Zoho } from "../assets/dataSource/Zoho.svg";

export const STATUS: any = {
  SUCCESS: {
    svg: <Success />,
    title: "Success",
    background: "#E8F9F6",
    color: "#1ABFA1",
  },
  ERROR: {
    svg: <Error />,
    title: "Error",
    background: "#FDEEEE",
    color: "#EF5854",
  },
  PENDING: {
    svg: <Pending />,
    title: "Pending",
    background: "#FEF5EB",
    color: "#F99F35",
  },
  IN_PROGRESS: {
    svg: <InProgress />,
    title: "In Progress",
    background: "#EDF6FE",
    color: "#4CA8F5",
  },
};

export const FREQUENCY = (id: number) => {
  return [...Array(48).keys()].map((key) => {
    return {
      id: +key + 1,
      name: `${+key + 1} times in ${id} days`,
    };
  });
};

export const TIMEPERIOD = [...Array(30).keys()].map((key) => {
  return {
    id: +key + 1,
    name: `${+key + 1} days`,
  };
});
