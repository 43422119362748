import { BudgetPlannerCalendar } from "./BudgetPlannerCalendar/BudgetPlannerCalendar";
import { BudgetPlannerHeader } from "./BudgetPlannerHeader/BudgetPlannerHeader";
import React, { useEffect, useMemo, useState } from "react";
import { Cell_Day, PacingType } from "./Types";
import { BudgetPlannerLimitEdit } from "./BudgetPlannerRightContent/BudgetPlannerLimitEdit/BudgetPlannerLimitEdit";
import { BudgetPlannerStaticTemplate } from "./BudgetPlannerRightContent/BudgetPlannerStaticTemplate/BudgetPlannerStaticTemplate";
import { Box, Grid } from "@mui/material";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { getPacingsList } from "./API/api.getPacingsList";
import { getPacingsDayWise } from "./API/api.getPacingsDayWise";
import { checkIsBeforeToday, isLastDate } from "utils/date.util";
import BudgetPlannerFooter from "./BudgetPlannerFooter";
import { buildMonthDaysList } from "./helpers";
import { GroupType } from "types/GlobalTypes";
import BudgetPacingLoader from "../loader";
import { putPacingDates } from "./API/api.putPacing";
import { getSearchParams } from "utils/commonFunctions";
import SuccessModel from "../sections/BudgetConstraints/SuccessModal/SuccessModal";
import { setSelectedOptimizer } from "store/actions/SmbDashboard/common";
import { useDispatch, useSelector } from "react-redux";
import { getFestivals } from "./API/api.getFestivals";
import { isNull } from "lodash";
import { AdAccountState } from "store/types/adaccount";
let weekSelection: number[] = [];
let tabSelection: number[] = [];
export const BudgetPlannerHolder: React.FC = (): JSX.Element => {
  useEffect(() => {
    let params = window.location.search
      .substring(1)
      .split("&")
      .map((item) => item.split("=")[0]);
    if (
      ["brand_id", "parent_ai_group", "group_id"].filter((item) =>
        params.find((i) => i === item)
      ).length < 2 ||
      window.location.pathname.split("/").length < 3
    ) {
      alert("Something went wrong. Please revert back to previous screen");
      history.replace("/group-settings" + window.location.search);
    }
  }, []);
  const [selectedMode, setSelectedmode] = useState(0);
  // 0 - automatic
  // 1 - manual
  const [pacinglist, setPacingList] = useState([]);
  const [difference, setDifference] = useState(0);
  const [selectedPacing, setSelectedPacing] = useState<PacingType | null>(null);
  const [calendarDays, setCalendarDays] = useState<Cell_Day[][]>([]);
  const [selectedDaysBudget, setSelectedDaysBudget] = useState(0);
  let params: any = useParams<any>();
  const [plannerId, setPlannerId] = useState<any>();
  const [type, setType] = useState(GroupType.parentGroup);
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [loader, setLoader] = useState(true);
  const [factor, setFactor] = useState("%");
  const [parameter, setParameter] = useState("Increase by");
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [updateValue, setUpdateValue] = useState("");
  const [modal, setModal] = useState<{ key: string; props: any }>({
    key: "",
    props: {},
  });
  const [showCalculator, setShowCalculator] = useState(false);
  useEffect(() => {
    params.plannerid && setPlannerId(params.plannerid);
    params.aigroupType && setType(params.aigroupType);
  }, [params]);
  const dispatch = useDispatch();
  const parameters = new URLSearchParams(window.location.search);
  const brandId = parameters.get("brand_id");
  const groupId = parameters.get("group_id");
  const [maxBudget, setMaxBudget] = useState(0);
  const [festivalDays, setFestivalDays] = useState({});
  const [showNew, setShowNew] = useState(false);
  const { data } = useSelector(
    (state: { ParentAiGroup: any; BudgetPlannerAiGroup: any }) =>
      type === "parentGroup" ? state.ParentAiGroup : state.BudgetPlannerAiGroup
  );
  const groups = type === "parentGroup" ? data.parentAiGroups : data.AiGroups;
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const history = useHistory();
  const [updateTriggered, setUpdateTriggered] = useState(false);
  const selectedGroup =
    type === GroupType.parentGroup
      ? getSearchParams("parent_ai_group")
      : getSearchParams("group_id");
  const getAllCalendarDaysForSelectedPacing = useMemo(
    () =>
      calendarDays
        .flat()
        .filter((item) => item && item.id === selectedPacing?.id),
    [calendarDays]
  );

  let d = new Date();
  let todate = new Date(
    d.setMinutes(
      d.getMinutes() +
        d.getTimezoneOffset() +
        (groups[selectedGroup]?.timeZoneOffSet || -18000) / 60
    )
  );
  const calcBudget = () => {
    if (selectedDatesFiltered.length) {
      let sum = 0;
      let budget = "";
      selectedDatesFiltered.forEach((item) => {
        if (item) {
          sum = sum + +item.dailyLimitUpdated;
          budget = parseFloat(sum.toString()).toFixed(2);
        }
      });
      setSelectedDaysBudget(+budget);
    }
  };
  const calDifference = (val: any, day: any) => {
    if (selectedPacing?.pacingType === "DAILY") {
      setDifference(0);
    } else if (val) {
      let updatedSum = 0;
      let prevSum = 0;
      let budgetDiff = "";
      for (var key in selectedDates) {
        let obj = selectedDates[key];
        if (obj.dailyLimitUpdated !== null)
          updatedSum += +obj.dailyLimitUpdated;
        else updatedSum = parseFloat(obj.value);
        prevSum += parseFloat(obj.value);
      }
      let diff = +(updatedSum - prevSum);
      budgetDiff = parseFloat(diff.toString()).toFixed(2);
      setDifference(+budgetDiff);
    }
  };

  function selectDate(weekIndex: number, dayIndex: number) {
    if (selectedPacing?.pacingType === "DAILY") {
      setSelectedmode(1);
    }
    let toSelect = true;
    if (
      calendarDays[weekIndex][dayIndex].dailyLimitUpdated !==
        calendarDays[weekIndex][dayIndex].value &&
      calendarDays[weekIndex][dayIndex].isSelected
    ) {
      toSelect = calendarDays[weekIndex][dayIndex].isSelected;
    } else {
      toSelect = !calendarDays[weekIndex][dayIndex].isSelected;
    }
    if (selectedMode && calendarDays[weekIndex][dayIndex].isSelected) {
      toSelect = true;
    }
    if (updateTriggered) {
      toSelect = calendarDays[weekIndex][dayIndex].isSelected;
    }
    if (
      calendarDays[weekIndex][dayIndex].dailyLimitUpdated ===
        calendarDays[weekIndex][dayIndex].value &&
      selectedPacing?.pacingType === "DAILY" &&
      calendarDays[weekIndex][dayIndex].isSelected
    ) {
      toSelect = false;
    }
    if (validateValidDateOfSelection(weekIndex, dayIndex)) {
      updateCalendarDay(weekIndex, dayIndex, "isSelected", toSelect);
      // setSelectedDates({
      //   ...selectedDates,
      //   [calendarDays[weekIndex][dayIndex].fullDateString]:
      //     calendarDays[weekIndex][dayIndex],
      // });
    }
  }

  const handleUpdate = () => {
    setUpdateTriggered(true);
    let calendarDaysCopy = JSON.parse(JSON.stringify(calendarDays));
    let calendarDaysCopy2 = JSON.parse(JSON.stringify(calendarDays));
    let selectedBudgetCopy = +selectedDaysBudget.toString();

    let selectedTrue = getAllCalendarDaysForSelectedPacing.filter(
      (item) =>
        item.isSelected == true &&
        item.fullDateNumber > item.toDateNumber &&
        item._status !== "LOCKED"
    );
    let selectedFalse = getAllCalendarDaysForSelectedPacing.filter(
      (item) =>
        item.isSelected !== true &&
        item.fullDateNumber > item.toDateNumber &&
        item._status !== "LOCKED"
    );
    let daysNotLocked = Object.values(pacingsDayWise).filter(
      (item: any) =>
        item.id === selectedPacing?.id &&
        !selectedTrue.some((each) => each.date === item.date) &&
        parseInt(item.date.split("-").join("")) >
          parseInt(
            getAllCalendarDaysForSelectedPacing[0]?.toDateString
              .split("-")
              .join("")
          ) &&
        item._status !== "LOCKED"
    );
    if (daysNotLocked.length === 0) {
      alert("action can't be performed because lack of unlocked dates");
      return;
    }
    let notEnoughFunds = false;
    //increase value by %
    if (parameter.includes("Increase") && factor == "%") {
      selectedFalse.forEach((a) => {
        let temp = parseFloat(
          parseFloat(
            (
              (+a.dailyLimitUpdated as number) -
              (selectedDaysBudget * +updateValue) /
                100 /
                (returnAllDatesOfSelectedPacingUnlockedOtherMonthsFuture()
                  .length +
                  selectedFalse.length)
            ).toString()
          ).toFixed(2)
        );
        if (temp < 0) {
          alert(
            "there is not enough funds in remaining dates to destribute for selected date: " +
              calendarDaysCopy[a.weekIndex][a.dayIndex].fullDateString
          );
          notEnoughFunds = true;
          return;
        }

        calendarDaysCopy[a.weekIndex][a.dayIndex].dailyLimitUpdated = temp;
        calendarDaysCopy[a.weekIndex][a.dayIndex].old_value =
          calendarDaysCopy[a.weekIndex][a.dayIndex].old_value;

        notEnoughFunds = false;
      });
      if (!notEnoughFunds) {
        selectedTrue.forEach((a) => {
          let temp = parseFloat(
            parseFloat(
              (
                (+a.dailyLimitUpdated as number) +
                ((a.dailyLimitUpdated as number) / 100) * +updateValue
              ).toString()
            ).toFixed(2)
          );
          calendarDaysCopy[a.weekIndex][a.dayIndex].dailyLimitUpdated = temp;
        });

        setSelectedDaysBudget(
          (prev: any) => prev + (selectedDaysBudget * +updateValue) / 100
        );
        setCalendarDays(calendarDaysCopy);
      }
    }
    //increase value by $
    else if (parameter.includes("Increase") && factor == "$") {
      setSelectedDaysBudget(
        (prev: any) => prev + +updateValue * Object.keys(selectedDates).length
      );

      selectedTrue.forEach((a) => {
        calendarDaysCopy[a.weekIndex][a.dayIndex].dailyLimitUpdated =
          parseFloat(
            parseFloat(
              ((+a.dailyLimitUpdated as number) + +updateValue).toString()
            ).toFixed(2)
          );
      });
      selectedFalse.forEach((a) => {
        calendarDaysCopy[a.weekIndex][a.dayIndex].dailyLimitUpdated =
          parseFloat(
            parseFloat(
              (
                (+a.dailyLimitUpdated as number) -
                (+updateValue * Object.keys(selectedDates).length) /
                  (returnAllDatesOfSelectedPacingUnlockedOtherMonthsFuture()
                    .length +
                    selectedFalse.length)
              ).toString()
            ).toFixed(2)
          );
      });
      setCalendarDays(calendarDaysCopy);
    }
    //decrease value by %
    else if (parameter.includes("Decrease") && factor == "%") {
      setSelectedDaysBudget(
        (prev: any) => prev - (selectedDaysBudget * +updateValue) / 100
      );
      selectedTrue.forEach((a) => {
        updateCalendarDay(
          a.weekIndex,
          a.dayIndex,
          "dailyLimitUpdated",
          parseFloat(
            parseFloat(
              (
                (+a.dailyLimitUpdated as number) -
                ((a.dailyLimitUpdated as number) * +updateValue) / 100
              ).toString()
            ).toFixed(2)
          )
        );
      });
      selectedFalse.forEach((a) => {
        updateCalendarDay(
          a.weekIndex,
          a.dayIndex,
          "dailyLimitUpdated",
          parseFloat(
            parseFloat(
              (
                (+a.dailyLimitUpdated as number) +
                ((selectedDaysBudget as number) * +updateValue) /
                  100 /
                  (returnAllDatesOfSelectedPacingUnlockedOtherMonthsFuture()
                    .length +
                    selectedFalse.length)
              ).toString()
            ).toFixed(2)
          )
        );
      });
    }
    //decrease value by $
    else if (parameter.includes("Decrease") && factor == "$") {
      setSelectedDaysBudget(
        (prev: any) => prev - +updateValue * Object.keys(selectedDates).length
      );
      selectedTrue.forEach((a) => {
        updateCalendarDay(
          a.weekIndex,
          a.dayIndex,
          "dailyLimitUpdated",
          parseFloat(
            ((+a.dailyLimitUpdated as number) - +updateValue).toString()
          ).toFixed(2)
        );
      });
      selectedFalse.forEach((a) => {
        updateCalendarDay(
          a.weekIndex,
          a.dayIndex,
          "dailyLimitUpdated",

          parseFloat(
            (
              (+a.dailyLimitUpdated as number) +
              (+updateValue * Object.keys(selectedDates).length) /
                (returnAllDatesOfSelectedPacingUnlockedOtherMonthsFuture()
                  .length +
                  selectedFalse.length)
            ).toString()
          ).toFixed(2)
        );
      });
    }
    // set value by $
    else {
      let currentValueOfSelectedDates = 0;
      selectedTrue.forEach((eachDay: Cell_Day) => {
        currentValueOfSelectedDates =
          currentValueOfSelectedDates + +eachDay.value;
      });
      let selectedDaysBudgetLocal =
        +updateValue * Object.keys(selectedDates).length;
      setSelectedDaysBudget(+updateValue * Object.keys(selectedDates).length);
      selectedTrue.forEach((a) => {
        updateCalendarDay(
          a.weekIndex,
          a.dayIndex,
          "dailyLimitUpdated",
          parseFloat(parseFloat(updateValue).toFixed(2))
        );
      });

      let notSelectedBudget =
        selectedFalse.reduce((a: any, b: any) => a + +b.value, 0) +
        (+currentValueOfSelectedDates - +selectedDaysBudgetLocal) +
        returnAllDatesOfSelectedPacingUnlockedOtherMonthsFuture().reduce(
          (a: any, b: any) => a + +b.value,
          0
        );
      selectedFalse.forEach((a) => {
        updateCalendarDay(
          a.weekIndex,
          a.dayIndex,
          "dailyLimitUpdated",
          parseFloat(
            parseFloat(
              (
                +notSelectedBudget /
                (returnAllDatesOfSelectedPacingUnlockedOtherMonthsFuture()
                  .length +
                  selectedFalse.length)
              ).toString()
            ).toFixed(2)
          )
        );
      });
    }
    setUpdateValue("");
    setEnableUpdate(false);
    setShowNew(true);

    setTimeout(() => {
      if (
        (parameter.includes("Increase")
          ? calendarDaysCopy || calendarDays
          : calendarDays
        )
          .flat()
          .filter(
            (item: Cell_Day) =>
              item &&
              item.name === selectedPacing?.name &&
              item.toDateNumber <= item.fullDateNumber &&
              Number(item.dailyLimitUpdated) < 0
          ).length
      ) {
        alert("You cannot go beyond the maximum allowed budget");
        setCalendarDays(calendarDaysCopy2);
        setSelectedDaysBudget(selectedBudgetCopy);
      }
    }, 10);
  };
  const isCalendarUpdated = useMemo(() => {
    return calendarDays
      .flat()
      .find((item) => item && item.value !== item.dailyLimitUpdated);
  }, [calendarDays]);

  function updateChanges() {
    let payloadDates: any = [];
    setUpdateTriggered(false);
    getAllCalendarDaysForSelectedPacing.map((date) => {
      if (
        !checkIsBeforeToday(
          moment(date.fullDateString),
          moment(date.toDateString)
        ) &&
        (date.isSelected || date._status === "LOCKED")
      ) {
        payloadDates.push({
          date: date.date,
          value: date.dailyLimitUpdated,
          status: "LOCKED",
        });
      }
    });
    returnAllDatesOfSelectedPacingLockedOtherMonthsFuture().forEach(
      (element: any) => {
        payloadDates.push({
          date: element.date,
          value: element.value,
          status: "LOCKED",
        });
      }
    );
    setLoader(true);
    putPacingDates(selectedGroup, plannerId, payloadDates, type)
      .then((res) => {
        let temp = JSON.parse(JSON.stringify(calendarDays));
        let tempPacingsDayWise = JSON.parse(JSON.stringify(pacingsDayWise));
        temp.flat().forEach((item: Cell_Day) => {
          if (item && item.id === selectedPacing?.id) {
            if (item.value !== item.dailyLimitUpdated) {
              item.old_value = item.value.toString();
            }
            if (item.isSelected) {
              item._status = "LOCKED";
              tempPacingsDayWise[item.date]._status = "LOCKED";
            }
            if (item.isSelected) item._status = "LOCKED";
            item.value = item.dailyLimitUpdated;
            item.isSelected = false;
            tempPacingsDayWise[item.date].value = item.dailyLimitUpdated;
          }
        });
        setPacingsDayWise(tempPacingsDayWise);

        setCalendarDays(temp);
        // setSelectedDates({});
        setModal({
          key: "success",
          props: {
            message: "'" + selectedPacing?.name + "' has been saved",
          },
        });
        setShowNew(false);
      })
      .catch((error) => {
        setModal({
          key: "error",
          props: {
            message:
              "'" +
              selectedPacing?.name +
              "' has not been saved due to server error",
          },
        });
      })
      .finally(() => {
        setLoader(false);
      });
  }

  function validateValidDateOfSelection(weekIndex: number, dayIndex: number) {
    if (
      calendarDays[weekIndex][dayIndex] &&
      calendarDays[weekIndex][dayIndex].name === selectedPacing?.name &&
      calendarDays[weekIndex][dayIndex]._status !== "LOCKED"
    ) {
      let day = moment(calendarDays[weekIndex][dayIndex].fullDateString);
      let endDay = moment(calendarDays[weekIndex][dayIndex].endDate);
      if (
        parseFloat(
          getAllCalendarDaysForSelectedPacing.reduce(
            (a: any, b: any) => a + b.value,
            0
          )
        ) <= 0
      ) {
        alert(
          "Can not select date because Total spends is not defined while pacing is created."
        );
        return false;
      }
      if (
        checkIsBeforeToday(
          day,
          moment(calendarDays[weekIndex][dayIndex].toDateString)
        )
      ) {
        alert(
          "Can not select the previous Dates or Today. Please select the future dates."
        );
        return false;
      } else if (
        isLastDate(
          moment(calendarDays[weekIndex][dayIndex].toDateString),
          endDay
        )
      ) {
        alert(
          "Can not select last date of pacing because budget can not be adjusted."
        );
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
  function selectMultipleDates(type: "week" | "days", index: number) {
    let dates: any = {};

    if (type === "week") {
      let toSelect = true;
      if (weekSelection.includes(index)) {
        toSelect = false;
        weekSelection = weekSelection.filter((i) => i !== index);
      } else {
        weekSelection.push(index);
      }

      calendarDays[index].forEach((eachDay: Cell_Day, dayIndex: number) => {
        if (eachDay) {
          let startDate = moment(eachDay.startDate);
          let endDate = moment(eachDay.endDate);
          if (
            moment(eachDay.fullDateString).isBetween(
              startDate,
              endDate,
              "day",
              "[]"
            ) &&
            !checkIsBeforeToday(
              moment(eachDay.fullDateString),
              moment(eachDay?.toDateString)
            ) &&
            (isLastDate(moment(eachDay.toDateString), endDate)
              ? selectedPacing?.status !== "ACTIVE" ||
                (eachDay.fullDateNumber === eachDay.toDateNumber
                  ? false
                  : selectedMode)
              : true) &&
            validateValidDateOfSelection(index, dayIndex) &&
            !updateTriggered
          ) {
            updateCalendarDay(index, dayIndex, "isSelected", toSelect);
            dates = {
              ...dates,
              [calendarDays[index][dayIndex].fullDateString]:
                calendarDays[index][dayIndex],
            };
            // setSelectedDates({
            //   ...selectedDates,
            //   ...dates,
            // });
            setSelectedDaysBudget(0);
          }
        }
      });
    } else {
      let toSelect = true;
      if (tabSelection.includes(index)) {
        toSelect = false;
        tabSelection = tabSelection.filter((i) => i !== index);
      } else {
        tabSelection.push(index);
      }
      calendarDays.forEach((week: any, weekIndex: number) => {
        if (week[index]) {
          let startDate = moment(week[index].startDate);
          let endDate = moment(week[index].endDate);
          if (
            moment(week[index].fullDateString).isBetween(
              startDate,
              endDate,
              "day",
              "[]"
            ) &&
            !checkIsBeforeToday(
              moment(week[index].fullDateString),
              moment(week[index]?.toDateString)
            ) &&
            (isLastDate(moment(week[index].toDateString), endDate)
              ? selectedPacing?.status !== "ACTIVE" ||
                (week[index].fullDateNumber === week[index].toDateNumber
                  ? false
                  : selectedMode)
              : true) &&
            validateValidDateOfSelection(weekIndex, index) &&
            !updateTriggered
          ) {
            updateCalendarDay(weekIndex, index, "isSelected", toSelect);
            dates = {
              ...dates,
              [calendarDays[weekIndex][index].fullDateString]:
                calendarDays[weekIndex][index],
            };
            // setSelectedDates({
            //   ...selectedDates,
            //   ...dates,
            // });
            setSelectedDaysBudget(0);
          }
        }
      });
    }
  }

  const selectedDatesFiltered = useMemo(() => {
    return calendarDays.flat().filter((item) => item && item.isSelected);
  }, [calendarDays]);

  const selectedDates = useMemo(() => {
    return selectedDatesFiltered.reduce((a: any, b: any) => {
      a[b.fullDateString] = b;
      return a;
    }, {});
  }, [selectedDatesFiltered]);

  useEffect(() => {
    calcBudget();
    maximumAllowed();
  }, [selectedDates]);

  const maximumAllowed = () => {
    let pastDates: any = [];
    if (
      getAllCalendarDaysForSelectedPacing.length &&
      pacingsDayWise &&
      selectedPacing
    ) {
      if (
        getAllCalendarDaysForSelectedPacing[0]?.status === "ACTIVE" &&
        pacingsDayWise
      ) {
        Object.keys(pacingsDayWise).map((item: any) => {
          if (
            pacingsDayWise[item].id === selectedPacing.id &&
            parseInt(pacingsDayWise[item].date.split("-").join("")) <
              parseInt(
                getAllCalendarDaysForSelectedPacing[0]?.toDateString
                  .split("-")
                  .join("")
              )
          ) {
            pastDates.push(pacingsDayWise[item]);
          }
        });
      }

      const totalBudget = (selectedPacing && selectedPacing.spends) || 0;
      // : getAllCalendarDaysForSelectedPacing.reduce(
      //     (a: any, b: any) => a + b.value,
      //     0
      //   );
      const modifiedBudget: any = Object.values(pacingsDayWise)
        .filter(
          (item: any) =>
            item.id === selectedPacing.id &&
            item._status === "LOCKED" &&
            parseInt(item.date.split("-").join("")) >
              parseInt(
                getAllCalendarDaysForSelectedPacing[0]?.toDateString
                  .split("-")
                  .join("")
              )
        )
        .reduce((acc: any, curr: any) => acc + +curr.value, 0);
      const spends = pastDates.length
        ? pastDates.reduce((acc: any, curr: any) => acc + curr.actualSpend, 0)
        : 0;

      const currentDateValue: any =
        pacingsDayWise[moment(todate).format("YYYY-MM-DD")]?.id ===
        selectedPacing?.id
          ? pacingsDayWise[moment(todate).format("YYYY-MM-DD")]?.value || 0
          : 0;
      const max = parseFloat(
        (totalBudget - modifiedBudget - spends - currentDateValue).toString()
      ).toFixed(2);
      setMaxBudget(+max);
    }
  };

  function updateCalendarDay(
    weekIndex: number,
    dayIndex: number,
    key: any,
    value: any
  ) {
    let cell: any = calendarDays[weekIndex][dayIndex];
    if (cell) {
      cell[key] = value;
      setCalendarDays([...calendarDays]);
    }
  }
  const [pacingsDayWise, setPacingsDayWise] = useState<any>([]);

  useEffect(() => {
    setLoader(true);
    if (selectedGroup && plannerId) {
      !selectedPacing &&
        getPacingsList(selectedGroup, type).then((data: any) => {
          setPacingList(data.data);
          let selected = data.data.find((d: any) => d.id == params.plannerid);
          setSelectedPacing(selected);
        });
    }
  }, [selectedGroup, plannerId]);

  function toNextMonth() {
    if (month + 1 === 12) {
      setMonth(0);
      setYear(year + 1);
      updateCalendarIfMonthUpdated(0, year + 1);
    } else {
      setMonth(month + 1);
      updateCalendarIfMonthUpdated(month + 1, year);
    }
    setSelectedmode(0);
    discardChanges();
  }
  function toPrevMonth() {
    if (month - 1 < 0) {
      setMonth(11);
      setYear(year - 1);
      updateCalendarIfMonthUpdated(11, year - 1);
    } else {
      setMonth(month - 1);
      updateCalendarIfMonthUpdated(month - 1, year);
    }
    setSelectedmode(0);
    discardChanges();
  }

  const returnAllDatesOfSelectedPacingUnlockedOtherMonthsFuture = () => {
    let temp: any = [];
    Object.values(pacingsDayWise).forEach((item: any) => {
      if (
        parseInt(item.date.split("-")[1]) !== month + 1 &&
        item._status === "UNLOCKED" &&
        item.id === selectedPacing?.id &&
        new Date(item.date) > todate
      ) {
        temp.push({ ...item });
      }
    });
    return temp;
  };

  const returnAllDatesOfSelectedPacingLockedOtherMonthsFuture = () => {
    let temp: any = [];
    Object.values(pacingsDayWise).forEach((item: any) => {
      if (
        parseInt(item.date.split("-")[1]) !== month + 1 &&
        item._status === "LOCKED" &&
        item.id === selectedPacing?.id &&
        new Date(item.date) > todate
      ) {
        temp.push(item);
      }
    });
    return temp;
  };

  const updateCalendarIfMonthUpdated = (month: any, year: any) => {
    let _calendarDays = buildMonthDaysList(
      month,
      year,
      groups[selectedGroup]?.timeZoneOffSet || -18000
    );
    // setCalendarDays(JSON.parse(JSON.stringify(_calendarDays)));
    setLoader(true);
    getPacingsDayWise(selectedGroup, type)
      .then((data: any) => {
        let calendarDaysData = data.data;
        if (data.data) {
          data.data.forEach((pacing: any) => {
            pacing.totalBudgetCalculatedBydays = (
              parseFloat(pacing.spends) * pacing.dayWiseSplit.length
            ).toFixed(2);
          });
        }
        calendarDaysData = calendarDaysData
          .map((pacing: any) =>
            pacing.dayWiseSplit.map((item: any) => {
              return {
                [item.date]: {
                  ...item,
                  ...pacing,
                  dailyLimitUpdated: item.value,
                  _status: item.status,
                },
              };
            })
          )
          .flat();
        let tempData: any = {};
        calendarDaysData.forEach((eachItem: any) => {
          tempData[Object.keys(eachItem)[0]] =
            eachItem[Object.keys(eachItem)[0]];
        });
        setPacingsDayWise(tempData);
        let tempPacingsDayWise = JSON.parse(JSON.stringify(tempData));
        let tempCalendarDays = JSON.parse(JSON.stringify(_calendarDays));
        tempCalendarDays.forEach((week: any, weekIndex: number) =>
          week.forEach((day: any, dayIndex: number) => {
            if (day && tempPacingsDayWise[day.fullDateString]) {
              week[dayIndex] = {
                ...day,
                ...tempPacingsDayWise[day.fullDateString],
                weekIndex,
                dayIndex,
              };
            }
          })
        );
        setCalendarDays(tempCalendarDays);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (
      selectedPacing &&
      selectedPacing.startDate &&
      selectedGroup &&
      (type === "parentGroup" || (groups && Object.keys(groups).length))
    ) {
      if (params.plannerid !== selectedPacing.id) {
        history.push(
          "/budget-planner/" +
            selectedPacing.id +
            "/" +
            type +
            window.location.search
        );
      }
      let date = selectedPacing.startDate.split("-");
      let monthTemp = parseInt(date[1]) - 1;
      let yearTemp = parseInt(date[0]);
      let monthOrYearChanged = false;
      if (month !== monthTemp) {
        setMonth(monthTemp);
        monthOrYearChanged = true;
      }
      if (year !== yearTemp) {
        setYear(yearTemp);
        monthOrYearChanged = true;
      }
      updateCalendarIfMonthUpdated(monthTemp, yearTemp);
      setSelectedmode(0);
    }
  }, [selectedPacing, selectedGroup, groups]);

  const onSelectMonth = (month: number, year: number) => {
    updateCalendarIfMonthUpdated(month, year);
  };

  const showWarningModal = (isRedirect: boolean) => {
    setModal({
      key: "warning",
      props: {
        message: "Your changes will be lost",
        isRedirect: isRedirect,
      },
    });
  };
  const onSubmit = () => {
    discardChanges(true);
    if (modal?.props?.isRedirect) {
      dispatch(
        setSelectedOptimizer({
          groupId,
          brandId,
          openBudgetPlanner: true,
        })
      );
      history.push("/group-settings" + window.location.search);
    }
    setModal({ key: "", props: {} });
  };

  const discardChanges = (forceLoadAPI = false) => {
    console.log(forceLoadAPI);
    let newArr = JSON.parse(JSON.stringify(calendarDays));
    newArr.forEach((week: any) => {
      week?.forEach((day: any) => {
        if (day) {
          day.isSelected = false;
          day.dailyLimitUpdated = day.value;
        }
      });
    });
    setSelectedDaysBudget(0);
    setSelectedmode(0);
    setCalendarDays(newArr);
    setShowCalculator(false);
    setDifference(0);
    setMaxBudget(0);
    forceLoadAPI && updateCalendarIfMonthUpdated(month, year);
    setModal({ key: "", props: {} });
    weekSelection = [];
    tabSelection = [];
    setUpdateTriggered(false);
  };

  const showWarningModalOnChange = () => {
    setModal({
      key: "warningModeChange",
      props: {
        message: "Your changes will be lost",
      },
    });
  };

  const errorSubmit = () => {
    setModal({ key: "", props: {} });
  };

  const handleSubmit = () => {
    onSubmit();
    setSelectedmode(selectedMode === 1 ? 0 : 1);
    setShowCalculator(true);
  };

  const showWarningModalOnPacingChange = (pacing: any) => {
    setModal({
      key: "onPacingChange",
      props: {
        message: "Your changes will be lost",
        pacing,
      },
    });
  };
  const showWarningModalOnMonthChange = (
    month: number,
    year: number,
    functionName?: any
  ) => {
    setModal({
      key: "onMonthChange",
      props: {
        message: "Your changes will be lost",
        month,
        year,
        functionName,
      },
    });
  };

  const handleSubmitOnPacingChange = () => {
    if (modal?.props?.pacing) {
      setSelectedPacing(modal?.props?.pacing);
    }
    discardChanges();
  };
  const handleSubmitOnMonthChange = () => {
    if (modal?.props?.month && modal?.props?.year) {
      setMonth(parseInt(modal?.props?.month));
      onSelectMonth(parseInt(modal?.props?.month), modal?.props?.year);
      setYear(modal?.props?.year);
    } else if (modal?.props?.functionName) {
      if (modal?.props?.functionName === "toPrevMonth") {
        toPrevMonth();
      } else {
        toNextMonth();
      }
    }
    discardChanges();
  };

  const handleUnlockBuchgetDistribution = (
    weekIndex: number,
    dayIndex: number
  ) => {
    let calendarDaysCopy = JSON.parse(JSON.stringify(calendarDays));
    if (calendarDaysCopy[weekIndex][dayIndex].id) {
      let daysToCalculate = getAllCalendarDaysForSelectedPacing.filter(
        (day: any) =>
          (day._status !== "LOCKED" ||
            calendarDaysCopy[weekIndex][dayIndex].fullDateString ===
              day.fullDateString) &&
          !checkIsBeforeToday(
            moment(day.fullDateString),
            moment(day?.toDateString)
          )
      );

      let totalBudget = 0;
      [
        ...daysToCalculate,
        ...returnAllDatesOfSelectedPacingUnlockedOtherMonthsFuture(),
      ].forEach((eachDay: any) => {
        totalBudget =
          +totalBudget + +(eachDay?.dailyLimitUpdated || eachDay?.value);
      });
      let updatedLimit =
        totalBudget /
        (daysToCalculate.length +
          returnAllDatesOfSelectedPacingUnlockedOtherMonthsFuture().length);
      if (selectedPacing?.pacingType === "DAILY") {
        calendarDaysCopy[weekIndex][dayIndex].dailyLimitUpdated =
          calendarDaysCopy[weekIndex][dayIndex].spends;
      }
      daysToCalculate.forEach((eachDay: any) => {
        selectedPacing?.pacingType !== "DAILY" &&
          (calendarDaysCopy[eachDay.weekIndex][
            eachDay.dayIndex
          ].dailyLimitUpdated = updatedLimit.toFixed(2));
        calendarDaysCopy[eachDay.weekIndex][eachDay.dayIndex]._status =
          "UNLOCKED";
      });
      setCalendarDays(calendarDaysCopy);
      let tempPacingsDayWise = JSON.parse(JSON.stringify(pacingsDayWise));
      Object.keys(tempPacingsDayWise).forEach((element: any) => {
        if (element === calendarDaysCopy[weekIndex][dayIndex].date) {
          tempPacingsDayWise[element]._status = "UNLOCKED";
        }
      });
      setPacingsDayWise(tempPacingsDayWise);
    }
  };

  useEffect(() => {
    if (year) {
      let startDate = moment([year]).startOf("year").format("YYYY-MM-DD");
      let endDate = moment([year]).endOf("year").format("YYYY-MM-DD");
      getFestivals(startDate, endDate).then((data: any) => {
        const newData = data.data.reduce((pre: any, cur: any) => {
          pre[cur.start.date] = cur.summary;
          return pre;
        }, {});
        setFestivalDays(newData);
      });
    }
  }, [year]);

  return (
    <div style={{ minWidth: "1400px" }}>
      {loader && <BudgetPacingLoader />}
      <Box
        sx={{
          flexGrow: 1,
          maxHeight: "100vh",
          background: "white",
          fontFamily: "Inter",
          borderRadius: "10px",
          pb: 1,
          "& *": {
            fontFamily: "Inter",
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BudgetPlannerHeader
              toPrevMonth={toPrevMonth}
              toNextMonth={toNextMonth}
              month={month}
              year={year}
              setYear={setYear}
              setMonth={setMonth}
              selectedDatesFiltered={selectedDatesFiltered}
              pacinglist={pacinglist}
              selectedPacing={selectedPacing}
              setSelectedPacing={setSelectedPacing}
              onSelectMonth={onSelectMonth}
              showWarningModal={showWarningModal}
              selectedDates={selectedDates}
              modal={modal.key}
              showWarningModalOnPacingChange={showWarningModalOnPacingChange}
              showWarningModalOnMonthChange={showWarningModalOnMonthChange}
              isCalendarUpdated={isCalendarUpdated}
            />
          </Grid>
          <Grid item xs={8}>
            <BudgetPlannerCalendar
              calendarDays={calendarDays}
              selectDate={selectDate}
              selectedPacing={selectedPacing}
              updateCalendarDay={updateCalendarDay}
              selectedMode={selectedMode}
              selectMultipleDates={selectMultipleDates}
              calDifference={calDifference}
              handleUnlockBuchgetDistribution={handleUnlockBuchgetDistribution}
              getAllCalendarDaysForSelectedPacing={
                getAllCalendarDaysForSelectedPacing
              }
              showNew={showNew}
              festivalDays={festivalDays}
              selectedBrand={selectedBrand}
            />
          </Grid>
          <Grid item xs={4}>
            <div className="d-flex pt-3">
              {selectedPacing?.pacingType === "PERIODIC" &&
              (selectedDatesFiltered.length > 0 || showCalculator) ? (
                <BudgetPlannerLimitEdit
                  selectedPacing={selectedPacing}
                  selectedMode={selectedMode}
                  setSelectedMode={setSelectedmode}
                  difference={difference}
                  selectedDaysBudget={selectedDaysBudget}
                  parameter={parameter}
                  setParameter={setParameter}
                  factor={factor}
                  setFactor={setFactor}
                  enableUpdate={enableUpdate}
                  setEnableUpdate={setEnableUpdate}
                  updateValue={updateValue}
                  setUpdateValue={setUpdateValue}
                  handleUpdate={handleUpdate}
                  getAllCalendarDaysForSelectedPacing={
                    getAllCalendarDaysForSelectedPacing
                  }
                  selectedDatesFiltered={selectedDatesFiltered}
                  showWarningModalOnChange={showWarningModalOnChange}
                  isCalendarUpdated={isCalendarUpdated}
                  maxBudget={maxBudget}
                  selectedBrand={selectedBrand}
                />
              ) : (
                <BudgetPlannerStaticTemplate selectedPacing={selectedPacing} />
              )}
            </div>
          </Grid>
          <Grid sx={{ width: "100%" }}>
            <BudgetPlannerFooter
              isCalendarUpdated={isCalendarUpdated}
              difference={difference}
              updateChanges={updateChanges}
              type={type}
              groups={groups}
              selectedGroup={selectedGroup}
            />
          </Grid>
        </Grid>
      </Box>
      {modal.key === "success" && (
        <SuccessModel
          setModal={setModal}
          modal={modal}
          props={modal.props}
          title={"Planner Saved"}
          key={modal.key}
        />
      )}
      {modal.key === "error" && (
        <SuccessModel
          setModal={setModal}
          modal={modal}
          props={modal.props}
          title={"error!"}
          key={modal.key}
          onSubmit={errorSubmit}
        />
      )}
      {modal.key === "warning" && (
        <SuccessModel
          setModal={setModal}
          modal={modal}
          props={modal.props}
          title={"Warning!"}
          key={modal.key}
          onSubmit={onSubmit}
        />
      )}
      {modal.key === "warningModeChange" && (
        <SuccessModel
          setModal={setModal}
          modal={modal}
          props={modal.props}
          title={"Warning!"}
          key={modal.key}
          onSubmit={handleSubmit}
        />
      )}
      {modal.key === "onPacingChange" && (
        <SuccessModel
          setModal={setModal}
          modal={modal}
          props={modal.props}
          title={"Warning!"}
          key={modal.key}
          onSubmit={handleSubmitOnPacingChange}
        />
      )}
      {modal.key === "onMonthChange" && (
        <SuccessModel
          setModal={setModal}
          modal={modal}
          props={modal.props}
          title={"Warning!"}
          key={modal.key}
          onSubmit={handleSubmitOnMonthChange}
        />
      )}
    </div>
  );
};
