import pixisWordmarkDarkSrc from "../../assets/logos/pixis_wordmark_dark.svg";
import pixisWordmarkLightSrc from "../../assets/logos/pixis_wordmark_light.svg";
import PixisLogo from "../../components/SideBar/assets/PixisLogo.svg";
import {
  SetBarChartData,
  SetChartLoading,
  SetCPRDetails,
  SetCPRGraphDetails,
  SetCPRRecommendation,
  SetCPRRecommendationLoading,
  SetGroupRecommendationDetailLoading,
  SetGroupRecommendationLoading,
  SetIndustryType,
  SetModelText,
  SetRecommendationDetails,
  SetRecommendations,
  SetSelectedAgency,
  SET_BAR_CHART_DATA,
  SET_CHART_LOADING,
  SET_CPR_DETAILS,
  SET_CPR_GRAPH_DETAILS,
  SET_CPR_RECOMMENDATION,
  SET_CPR_RECOMMENDATION_LOADING,
  SET_GROUP_RECOMMENDATION_DETAIL_LOADING,
  SET_GROUP_RECOMMENDATION_LOADING,
  SET_INDUSTRY_TYPE,
  SET_MODEL_TEXT,
  SET_RECOMMENDATIONS,
  SET_RECOMMENDATION_DETAILS,
  SET_SELECTED_AGENCY,
  SET_AGENCY_ASSETS,
  SetAgencyAssets,
  SET_DEFAULT_AGENCY_ASSETS,
  SetDefaultAgencyAssets,
} from "../types/common";

const initialState = {
  modelText: {},
  selectedAgency: {},
  industryType: [],
  recommendations: {},
  recommendationDetails: {},
  cprDetails: {},
  cprGraphDetails: {},
  barChartData: null,
  cprRecommendation: null,
  chartLoading: false,
  cprRecommendationLoading: false,
  groupRecommendationLoading: false,
  groupRecommendationDetailLoading: false,
  loadingAgencyAssets: true,
  agencyAssets: {
    id: "default",
    agencyId: "default",
    logo: PixisLogo,
    logoWithHorizontalName: null,
    logoWithVerticalName: null,
    title: "Pixis",
    longTitle: "Pixis AI",
    textLogo: pixisWordmarkLightSrc,
    textLogoDark: pixisWordmarkDarkSrc
  }
};

const commonReducer = (
  state = initialState,
  action:
    | SetModelText
    | SetSelectedAgency
    | SetIndustryType
    | SetRecommendations
    | SetRecommendationDetails
    | SetCPRDetails
    | SetCPRGraphDetails
    | SetBarChartData
    | SetCPRRecommendation
    | SetChartLoading
    | SetCPRRecommendationLoading
    | SetGroupRecommendationLoading
    | SetGroupRecommendationDetailLoading
    | SetAgencyAssets
    | SetDefaultAgencyAssets
) => {
  switch (action.type) {

    case SET_SELECTED_AGENCY: {
      return {
        ...state,
        selectedAgency: action.payload,
      };
    }

    case SET_AGENCY_ASSETS: {
      return {
        ...state,
        agencyAssets: action.payload,
        loadingAgencyAssets: false,
      };
    }

    case SET_DEFAULT_AGENCY_ASSETS: {
      return {
        ...state,
        agencyAssets: initialState.agencyAssets,
        loadingAgencyAssets: false
      };
    }

    case SET_MODEL_TEXT: {
      return {
        ...state,
        modelText: action.payload,
      };
    }
    case SET_INDUSTRY_TYPE: {
      return {
        ...state,
        industryType: action.payload,
      };
    }
    case SET_RECOMMENDATIONS: {
      return {
        ...state,
        recommendations: action.payload,
      };
    }
    case SET_RECOMMENDATION_DETAILS: {
      return {
        ...state,
        recommendationDetails: action.payload,
      };
    }
    case SET_CPR_DETAILS: {
      return {
        ...state,
        cprDetails: action.payload,
      };
    }
    case SET_CPR_GRAPH_DETAILS: {
      return {
        ...state,
        cprGraphDetails: action.payload,
      };
    }
    case SET_BAR_CHART_DATA: {
      return {
        ...state,
        barChartData: action.payload,
      };
    }
    case SET_CPR_RECOMMENDATION: {
      return {
        ...state,
        cprRecommendation: action.payload,
      };
    }
    case SET_CHART_LOADING: {
      return {
        ...state,
        chartLoading: action.payload,
      };
    }
    case SET_CPR_RECOMMENDATION_LOADING: {
      return {
        ...state,
        cprRecommendationLoading: action.payload,
      };
    }
    case SET_GROUP_RECOMMENDATION_LOADING: {
      return {
        ...state,
        groupRecommendationLoading: action.payload,
      };
    }
    case SET_GROUP_RECOMMENDATION_DETAIL_LOADING: {
      return {
        ...state,
        groupRecommendationDetailLoading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default commonReducer;
