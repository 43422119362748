import React, { useCallback } from "react";
import ModalContainer from "../../components/Modal/ModalContainer";
import ModalWrapper from "../../components/Modal/ModalWrapper";

const ViewVideo: React.FC<{
  video: any;
  cancelText: string;
  setShowVideo: any;
  fileType: any;
  title?:any
}> = ({ video, cancelText, setShowVideo, fileType = "blob",title }) => {

  return (
    <ModalWrapper>
      <ModalContainer
        title={video?.name ? video?.name :title?title: ""}
        handleClose={() => setShowVideo(false)}
        cancelText={cancelText ? cancelText : ""}
      >
        <div>
          <video
            style={{ width: "100%", height: "400px" }}
            controls
            autoPlay
            src={
              video
                ? fileType == "blob"
                  ? URL.createObjectURL(video)
                  : video.video
                : null
            }
          />
        </div>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default ViewVideo;
