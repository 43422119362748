import { nanoid } from "nanoid";
import {
  ImageLayerType,
  Image_Recommendation,
  Image_Recommendation_List,
  VideoLayerType,
  VideoRecommendationItem,
} from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import {
  GETMediaStudioResponseType,
  MediaStudioProcessingPostType,
  TimeFrameType,
} from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { HashType } from "../../Editor.types";

export const imageMediaProcessingPayloadMapping = (
  data: ImageLayerType[] | VideoLayerType[]
): MediaStudioProcessingPostType[] => {
  return data?.map((item: ImageLayerType | VideoLayerType) => ({
    layer_name: item.layer_name,
    text: item.layer_text,
    variation_type: "text",
  }));
};

export const getTimeStamp = (timeFrame: TimeFrameType | undefined) => {
  if (timeFrame) {
    const minutes =
      timeFrame.minutes < 9 ? "0" + timeFrame.minutes : timeFrame.minutes;
    const seconds =
      timeFrame.seconds < 9 ? "0" + timeFrame.seconds : timeFrame.seconds;

    return `${minutes}:${seconds}`;
  }
  return "00:00";
};

export const videoMediaProcessingPayloadMapping = (
  data: VideoRecommendationItem[]
): MediaStudioProcessingPostType[] => {
  let outputPayload: MediaStudioProcessingPostType[] = [];
  for (let index in data) {
    const layerPayload = imageMediaProcessingPayloadMapping(data?.[index]);
    outputPayload = [...outputPayload, ...layerPayload];
  }
  return outputPayload;
};

export const convertVideoFrameData = (
  generations: any,
  getMediaStudioData?: {
    data?: GETMediaStudioResponseType;
    isData: boolean;
  }
) => {
  return generations.map((item: VideoLayerType[]) => {
    const frameRec = getMediaStudioData?.data?.frames.map((_, index) => {
      return item.filter((item) => item.frame_name === `Frame_${index + 1}`);
    });
    return {
      data: frameRec,
      noOfElements: item.length,
    };
  });
};

export const layerRecommendationMapping = (
  first: ImageLayerType[] | VideoLayerType[],
  data: ImageLayerType[][] | VideoLayerType[][]
) => {
  let j = 0;
  let local = data;
  for (let gen of local) {
    let i = 0;
    for (let layer of gen) {
      if (first[i].layer_text !== layer.layer_text) {
        j++;
        break;
      } else {
        if (i == first.length - 1) {
          const dropData = local.splice(j, 1);
          return [first, ...local];
        } else {
          i++;
          continue;
        }
      }
    }
  }
  return [first, ...local];
};

export const getImageData = (data: ImageLayerType[][]) => {
  let output: Image_Recommendation_List = {
    data: [],
    noOfElementToShow: 4,
    showMore: false,
    selected: "",
  };
  data.forEach((arr: ImageLayerType[]) => {
    output.data = [...output?.data, { data: arr, id: nanoid() }];
  });
  output.noOfElementToShow = data.length + 1 > 4 ? 4 : data.length + 1;
  output.showMore = data.length + 1 > 4;
  output.selected = output.data[0].id;
  return output;
};
