import {
  AgenciesState,
  SetAgenciesSmb,
  SetSelectedAgencySmb,
  SET_AGENCIES_SMB,
  SET_SELECTED_AGENCY_SMB,
} from "../../types/SmbDashboard/agencies";

const initialAgencyState: AgenciesState = {
  data: [],
  selected: null,
  error: null,
};

const actions: any = {
  [SET_AGENCIES_SMB]: (state: any, payload: any) => ({
    ...state,
    data: payload,
  }),

  [SET_SELECTED_AGENCY_SMB]: (state: any, payload: any) => ({
    ...state,
    selected: payload,
  }),
};

export default function agenciesReducer(
  state = initialAgencyState,
  action: SetAgenciesSmb | SetSelectedAgencySmb
) {
  if (actions[action.type]) return actions[action.type](state, action.payload);
  return state;
}
