import styled from "styled-components";

export const Container = styled.form``;

export const Backdrop = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2021;
`;

export const Content = styled.div`
  border-radius: 10px;
  position: fixed;
  background: #fff;
  z-index: 2022;
  width: 1060px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Scrollable = styled.div`
  max-height: calc(100vh - 158px);
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'Details Details'
    'Headlines Descriptions';

  .generation-type {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .pixis-checkbox-container {
      min-width: 150px;
      padding: 3px 0;
    }

    input.type-input {
      min-width: 250px;
      height: 30px;
      line-height: 1;
      background: #ffffff;
      border: 1px solid #dadcde;
      border-radius: 4px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #333333;
      padding: 5px 16px;
    }
  }

  input.type-input-error {
    border: 1px solid red !important;
  }
}
`;

export const Header = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  padding: 18px 24px;
  grid-area: Header;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  button {
    border: none;
    height: 34px;
    width: 34px;
    background: #f2f2f2;
    border-radius: 4px;
  }
`;

export const Details = styled.div`
  border-bottom: 1px solid #dddddd;
  padding: 24px;
  grid-area: Details;
  display: flex;
  flex-direction: row;
  jusify-content: flex-start;
  align-items: flex-start;
  gap: 20px 32px;
  flex-wrap: wrap;
  .error-message-box {
    .error-message {
      padding: 10px;
      background: #fff4f6;
      border-radius: 4px;
      .title {
        font-size: 14px;
        line-height: 17px;
        color: #fa3030;
      }
      .error-close-btn {
        cursor: pointer;
      }
    }
  }
`;

export const Headlines = styled.div`
  grid-area: Headlines;
  padding: 24px;
  border-right: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .error {
    color: red;
    font-size: 12px;
  }

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Descriptions = styled.div`
  grid-area: Descriptions;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .error {
    color: red;
    font-size: 12px;
  }

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Footer = styled.div`
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  padding: 18px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-area: Footer;

  .help {
    display: flex;
    flex-direction: row;
    jusify-content: flex-start;
    align-items: center;
    gap: 10px;
    position: relative;

    .glossary-container {
      display: none;
    }

    &:hover {
      .glossary-container {
        display: block;
      }
    }
  }

  button {
    width: 86px;
    height: 40px;
    background: #0869fb;
    border-radius: 6px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }
`;

type InputContainerProps = {
  className: string;
  size: string;
};

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 20px;
  width: 100%;

  &.keyword,
  &.brand-name,
  &.bu-name {
    width: 48%;
  }

  &.brand-details {
    width: 100%;
  }

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  input {
    height: ${({ size }) => (size === "regular" ? "35" : "50")}px;
    line-height: 1;
    border: 1px solid #dadcde;
    border-radius: ${({ size }) => (size === "regular" ? "4" : "6")}px;
    padding: 16px 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    min-width: 290px;
    width: 100%;
  }

  textarea {
    height: 65px;
    border-radius: 4px;
    border: 1px solid #dadcde;
    padding: 12px;
    resize: none;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  textarea + svg {
    bottom: 25px !important;
  }

  button {
    border: 0;
    outline: none;
    background: transparent;
  }

  .error {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 12px;
    color: red;
  }
`;

export const CheckboxContainer = styled.div`
  user-select: none;
  width: 100%;

  &.is-disabled {
    opacity: 0.5;
  }

  &.override-disable-styles {
    opacity: 1;
  }

  input {
    display: none;
  }

  label.pixis-checkbox-label {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    .pixis-checkbox {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1.3219px solid #c7cad6;
      height: 20px;
      width: 20px;
      border-radius: 4px;

      .check-icon {
        display: none;
      }

      .intermediate-line {
        display: none;
        width: 5px;
        background: #3975e9;
        height: 1px;

        &.intermediate {
          display: block;
        }
      }
    }

    .label-content {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #555555;
    }
    .warning-message {
      font-size: 8px;
      line-height: 10px;
    }
  }

  &.intermediate {
    .intermediate-line {
      display: block;
    }
  }

  input:checked + .pixis-checkbox-label {
    .pixis-checkbox {
      background: #3975e9;
      border: 1.3219px solid #3975e9;
    }

    .check-icon {
      display: block;
      path {
        stroke: white;
      }
    }

    .intermediate-line {
      display: none !important;
    }
  }

  input:disabled + .pixis-checkbox-label {
    cursor: not-allowed;
  }
`;

export const HelpGlossary = styled.div`
  position: absolute;
  background: #efefef;
  border: 1px solid #dadce0;
  border-radius: 4px;
  bottom: calc(100% + 4px);
  left: 0;
  padding: 0 24px;
  width: 609px;
  max-height: calc(100vh - 80px);
  overflow-y: scroll;

  .block {
    padding: 24px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #dadce0;
    }

    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      margin-bottom: 8px;
    }

    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #666666;

      ul {
        padding: 0;
        padding-left: 12px;
        margin: 0;

        strong {
          font-weight: 700;
          color: #333;
        }
      }
    }
  }
`;
