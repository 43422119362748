import { Box } from "@mui/material";
import { CompareDataType } from "../../shared/sharedTypes";
import { StyledGainLoss } from "../styled";
import { ReactComponent as ArrowUp } from "../../../../assets/svg/arrow_up.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/svg/arrow_down.svg";
import "../index.scss";

interface ComparingMetricsTextProps {
  comparingData: CompareDataType;
  isPerformance?: boolean;
}

export default function ComparingMetricsText({
  comparingData,
  isPerformance,
}: ComparingMetricsTextProps) {
  const { loss, value, isZero, metricGrowthFlag } = comparingData;
  return (
    <Box alignItems="center" display="flex">
      {isZero ? (
        ""
      ) : loss ? (
        <ArrowDown
          style={{ height: isPerformance ? "0.5625em" : "0.6875em" }}
          className={`${
            metricGrowthFlag
              ? metricGrowthFlag === "Positive"
                ? !loss
                  ? "color-green"
                  : "color-red"
                : !loss
                ? "color-red"
                : "color-green"
              : !loss
              ? "color-green"
              : "color-red"
          }`}
        />
      ) : (
        <ArrowUp
          style={{ height: isPerformance ? "0.5625em" : "0.6875em" }}
          className={`${
            metricGrowthFlag
              ? metricGrowthFlag === "Positive"
                ? !loss
                  ? "color-green"
                  : "color-red"
                : !loss
                ? "color-red"
                : "color-green"
              : !loss
              ? "color-green"
              : "color-red"
          }`}
        />
      )}{" "}
      <StyledGainLoss
        isZero={isZero}
        loss={loss}
        isPerformance={isPerformance}
        growthFlag={metricGrowthFlag}
      >
        {value}
      </StyledGainLoss>
    </Box>
  );
}
