import React, { useCallback, useEffect, useMemo, useState } from "react";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import Modal from "@mui/material/Modal";
import CustomizedDialogs from "../shared/Modal/CustomizedDialogs";
import { useForm } from "react-hook-form";
import { ManualAction } from "./styled";
import { styled } from "@mui/material";
import { textTruncateMore } from "../CreativeAI/Facebook/utils/text.utils";
import { isEmpty, isString } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AdAccountState } from "../../store/types/adaccount";
import {
  getOpenSearchAIData,
  resetFormDataState,
  submitFileManualAction,
  submitManualActionData,
} from "../../store/actions/manualAction";
import { initialState } from "../../store/types/auth";
import {
  getCurrencySymbol,
  isViewerRole,
  isEditorRole,
  getRolesHierarchy,
} from "../../utils/commonFunctions";
import CheckBox from "ui/CheckBox/CheckBox";
import HandleClickOutside from "components/common/HandleClickOutside";
import { generateParamBody } from "./ManualActionCommonFunction";
import { brandViewerAccessText } from "utils/constants/commonConsts";
import { mixPanelAdEvent } from "utils/mixpanel";
import ConfirmationModal from "Pages/SinglePlatform/ConfirmationModal";
import TriggerActionsFileUpload from "Pages/SinglePlatform/TriggerActionsFileUpload";
import { RolesWrapper } from "utils/rolesWrapper";
import CardSelection, { CardType } from "./CardSelection";
import SingleBulkActionModal from "./SingleBulkActionModal";

const ManualActionButton: React.FC<unknown> = () => {
  const dispatch = useDispatch();

  //selecters
  const auth = useSelector((state: { auth: initialState }) => state.auth);

  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const { accountDetails } = useSelector(
    (state: { auth: initialState }) => state.auth
  );
  const userDetailInfo = useSelector(
    (state: any) => state.userInfoReducer.userInfo
  );
  const {
    selectedAdAccount,
    selectedPlatform,
    groupId,
    brandRoles,
    selectedGroup,
    selectedBrand,
  } = adaccount;
  //selecters
  const { openSearchAiData, openSearchAiDataState, submitManualActionState } =
    useSelector((state: any) => state.manualActionReducer);

  //toolTip css
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#eee",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#eee",
      color: "#000000",
      fontWeight: "500",
      fontSize: "12px",
    },
  }));
  const CampaignInfoTooltip = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip
        placement="right-end"
        {...props}
        arrow
        classes={{ popper: className }}
      />
    )
  )(({ theme }) => ({
    // whiteSpace: "nowrap",
    [`& .${tooltipClasses.arrow}`]: {
      color: "#eee",
      // marginLeft: "-40%",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#eee",
      maxWidth: "17rem",
      color: "#000000",
      fontWeight: "500",
      fontSize: "12px",
      // marginLeft: "-100px",
      // borderRadius: "16px",
    },
  }));
  // const isBrandViewer = useMemo(
  //   () => brandRoles?.length === 1 && brandRoles[0]?.role === "brand-viewer",
  //   [brandRoles]
  // );
  //local data states (Will be replaced by redux useSelector states)
  const [campaignsData, setCampaignsData] = useState<any>([]);

  //states for action
  const [budgetAction, setBudgetAction] = useState<any>("changeByPercent");
  const [budgetActionType, setBudgetActionType] = useState<any>("increase");
  const [budgetActionValueAbsolute, setBudgetActionValueAbsolute] =
    useState<any>("");
  const [budgetActionValuePercent, setBudgetActionValuePercent] =
    useState<any>("");
  const [changeStatus, setChangeStatus] = useState<any>("");
  const [actionBasis, setActionBasis] = useState("");
  const [confidenceScore, setConfidenceScore] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [executionText, setExecutionText] = useState("");
  const [fileUploaded, setFileUploaded] = useState<any>(null);
  //states for action

  //data listing state
  const [openDataList, setOpenDataList] = useState<any>(false);
  //data listing state

  const [levelsOptions, setlevelsOptions] = useState<any>([]);
  const [levelOptionSelected, setLevelOptionSelected] = useState<any>({});
  const [platform, setPlatform] = useState<any>("");
  const [isGoogleVideo, setIsGoogleVideo] = useState<boolean>(false);
  const [actionActionButton, setactionActionButton] = useState<any>("");
  const [searchValue, setSearchValue] = useState<any>("");
  const [selectedItem, setselectedItem] = useState<any>([]);
  const [level, setLevel] = React.useState("");
  const wrapperRef = React.useRef(null);
  HandleClickOutside(wrapperRef, [setOpenDataList]);
  const [isReadMore, setIsReadMore] = useState(true);

  const [actionButtons, setActionButtons] = useState<any>([
    {
      platforms: ["facebook", "google", "linkedin", "bing"],
      levels: ["campaign", "adset", "adgroup", "ad"],
      label: "Change Budget",
      labelkey: "changeBudgetAction",
      selected: false,
      id: 1,
      show: true,
    },
    {
      platforms: ["facebook", "google", "linkedin", "bing"],
      levels: ["campaign", "adset", "adgroup", "ad"],
      label: "Change Bid",
      labelkey: "changeBidAction",
      selected: false,
      id: 2,
      show: true,
    },
    {
      platforms: ["facebook"],
      levels: ["campaign", "adset", "adgroup", "ad"],
      label: "Change Status",
      labelkey: "changeStatusAction",
      selected: false,
      id: 3,
      show: true,
    },
  ]);

  useEffect(() => {
    if (level === "keyword") {
      setBudgetAction("setValue");
    }
    // const temp = [
    //   {
    //     platforms: ["facebook", "google", "linkedin", "bing"],
    //     levels: ["campaign", "adset", "adgroup", "ad"],
    //     label: "Change Budget",
    //     labelkey: "changeBudgetAction",
    //     selected: false,
    //     id: 1,
    //     show: true,
    //   },
    //   {
    //     platforms: ["facebook", "google", "linkedin", "bing"],
    //     levels: ["campaign", "adset", "adgroup", "ad"],
    //     label: "Change Bid",
    //     labelkey: "changeBidAction",
    //     selected: false,
    //     id: 2,
    //     show: true,
    //   },
    //   {
    //     platforms: ["facebook"],
    //     levels: ["campaign", "adset", "adgroup", "ad"],
    //     label: "Change Status",
    //     labelkey: "changeStatusAction",
    //     selected: false,
    //     id: 3,
    //     show: true,
    //   },
    // ];
    // setActionButtons(temp);
  }, [level]);

  useEffect(() => {
    if(selectedPlatform.name === "GOOGLE VIDEO AD") {
      setIsGoogleVideo(true);
    } else {
      setIsGoogleVideo(false);
    }

    if (
      selectedPlatform?.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c001"
    )
      return setPlatform("facebook");
    if (
      selectedPlatform?.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c002"
    )
      return setPlatform("google");
    if (
      selectedPlatform?.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c003"
    )
      return setPlatform("linkedin");
    if (
      selectedPlatform?.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c004"
    )
      return setPlatform("bing");
    if (
      selectedPlatform.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c006"
    )
      return setPlatform("tiktok");
    if (
      selectedPlatform.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c009"
    )
      return setPlatform("apple");
    if (
      selectedPlatform.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c005"
    )
      return setPlatform("dv360");

    if (
      selectedPlatform.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c010"
    )
      return setPlatform("pinterest");
  }, [selectedPlatform]);

  useEffect(() => {
    setselectedItem([]);
    setSearchValue("");
    setIsReadMore(false);
  }, [level]);

  useEffect(() => {
    setLevel("");
  }, [selectedAdAccount]);

  const [clickaway, setClickaway] = useState<any>(false);

  useEffect(() => {
    if (!!level) {
      setTimeout(() => {
        handleCampaignSearch(""); // fetch all data when level changes
      }, 0); // complete all the set states before invoking this block
    }
    if (platform === "facebook" && level === "campaign") {
      const temp = actionButtons.map((v: any, i: any) => {
        if (v.label === "Change Budget") return { ...v, show: true };
        if (v.label === "Change Bid") return { ...v, show: false };
        if (v.label === "Change Status") return { ...v, show: true };
      });
      setActionButtons(temp);
    }
    if (platform === "facebook" && level === "ad") {
      const temp = actionButtons.map((v: any, i: any) => {
        if (v.label === "Change Budget") return { ...v, show: false };
        if (v.label === "Change Bid") return { ...v, show: false };
        if (v.label === "Change Status") return { ...v, show: true };
      });
      setActionButtons(temp);
    }
    if (platform === "facebook" && level === "adset") {
      const temp = actionButtons.map((v: any, i: any) => {
        if (v.label === "Change Budget") return { ...v, show: true };
        if (v.label === "Change Bid") return { ...v, show: true };
        if (v.label === "Change Status") return { ...v, show: true };
      });
      setActionButtons(temp);
    }
    if (platform === "google" && level === "campaign") {
      const temp = actionButtons.map((v: any, i: any) => {
        if (v.label === "Change Budget") return { ...v, show: true };
        if (v.label === "Change Bid") return { ...v, show: true };
        if (v.label === "Change Status") return { ...v, show: true };
      });
      setActionButtons(temp);
    }
    if (platform === "google" && level === "adset") {
      const temp = actionButtons.map((v: any, i: any) => {
        if (v.label === "Change Budget") return { ...v, show: false };
        if (v.label === "Change Bid") return { ...v, show: true };
        if (v.label === "Change Status") return { ...v, show: true };
      });
      setActionButtons(temp);
    }
    if (platform === "google" && level === "keyword") {
      const temp = actionButtons.map((v: any, i: any) => {
        if (v.label === "Change Budget") return { ...v, show: false };
        if (v.label === "Change Bid") return { ...v, show: true };
        if (v.label === "Change Status") return { ...v, show: true };
      });
      setActionButtons(temp);
    }
    if (platform === "google" && level === "ad") {
      const temp = actionButtons.map((v: any, i: any) => {
        if (v.label === "Change Budget") return { ...v, show: false };
        if (v.label === "Change Bid") return { ...v, show: false };
        if (v.label === "Change Status") return { ...v, show: true };
      });
      setActionButtons(temp);
    }
    if (platform === "bing" && level === "campaign") {
      const temp = actionButtons.map((v: any, i: any) => {
        if (v.label === "Change Budget") return { ...v, show: true };
        if (v.label === "Change Bid") return { ...v, show: false };
        if (v.label === "Change Status") return { ...v, show: false };
      });
      setActionButtons(temp);
    }
    if (platform === "bing" && level === "adset") {
      const temp = actionButtons.map((v: any, i: any) => {
        if (v.label === "Change Budget") return { ...v, show: false };
        if (v.label === "Change Bid") return { ...v, show: true };
        if (v.label === "Change Status") return { ...v, show: false };
      });
      setActionButtons(temp);
    }
    if (platform === "bing" && level === "keyword") {
      const temp = actionButtons.map((v: any, i: any) => {
        if (v.label === "Change Budget") return { ...v, show: false };
        if (v.label === "Change Bid") return { ...v, show: true };
        if (v.label === "Change Status") return { ...v, show: false };
      });
      setActionButtons(temp);
    }
    if (platform === "linkedin" && level === "adset") {
      const temp = actionButtons.map((v: any, i: any) => {
        if (v.label === "Change Budget") return { ...v, show: true };
        if (v.label === "Change Bid") return { ...v, show: true };
        if (v.label === "Change Status") return { ...v, show: false };
      });
      setActionButtons(temp);
    }
    // if (platform === "google" && level === "campaign") {
    //   setActionButtons(
    //     actionButtons.map((v: any, i: any) => {
    //       if (v.label === "Change Bid" && level.search(/\bcampaign\b/) > -1) {
    //         return { ...v, show: false };
    //       } else {
    //         return v;
    //       }
    //     })
    //   );
    // }
    // if (platform === "bing" && level === "campaign") {
    //   setActionButtons(
    //     actionButtons.map((v: any, i: any) => {
    //       if (v.label === "Change Bid" && level.search(/\bcampaign\b/) > -1) {
    //         return { ...v, show: false };
    //       } else {
    //         return v;
    //       }
    //     })
    //   );
    // }
    // if (platform === "facebook" && level === "ad") {
    //   setActionButtons(
    //     actionButtons.map((v: any, i: any) => {
    //       if (v.label === "Change Budget" && level.search(/\bad\b/) > -1) {
    //         return { ...v, show: false };
    //       } else {
    //         return v;
    //       }
    //     })
    //   );
    // }
    // if (platform === "google" && level === "adset") {
    //   setActionButtons(
    //     actionButtons.map((v: any, i: any) => {
    //       if (v.label === "Change Budget" && level.search(/\badset\b/) > -1) {
    //         console.log("level inside", v);
    //         return { ...v, show: false };
    //       } else {
    //         return v;
    //       }
    //     })
    //   );
    // }
    // if (platform === "google" && level === "keyword") {
    //   setActionButtons(
    //     actionButtons.map((v: any, i: any) => {
    //       if (v.label === "Change Budget" && level.search(/\bkeyword\b/) > -1) {
    //         console.log("level inside", v);
    //         return { ...v, show: false };
    //       } else {
    //         return v;
    //       }
    //     })
    //   );
    // }

    // if (platform === "bing" && level === "adset") {
    //   setActionButtons(
    //     actionButtons.map((v: any, i: any) => {
    //       if (v.label === "Change Budget" && level.search(/\badset\b/) > -1) {
    //         console.log("level inside", v);
    //         return { ...v, show: false };
    //       } else {
    //         return v;
    //       }
    //     })
    //   );
    // }
    // if (platform === "bing" && level === "keyword") {
    //   setActionButtons(
    //     actionButtons.map((v: any, i: any) => {
    //       if (v.label === "Change Budget" && level.search(/\bkeyword\b/) > -1) {
    //         console.log("level inside", v);
    //         return { ...v, show: false };
    //       } else {
    //         return v;
    //       }
    //     })
    //   );
    // }

    // if (platform.search(/google|bing/) > -1) {
    // if (platform === "google" && level === "campaign") {
    //   setActionButtons(
    //     actionButtons.map((v: any, i: any) => {
    //       if (
    //         v.label === "Change Status" &&
    //         level.search(/\bcampaign\b/) > -1
    //       ) {
    //         return { ...v, show: false };
    //       } else {
    //         return v;
    //       }
    //     })
    //   );
    // }
    // if (platform === "bing" && level === "campaign") {
    //   setActionButtons(
    //     actionButtons.map((v: any, i: any) => {
    //       if (
    //         v.label === "Change Status" &&
    //         level.search(/\bcampaign\b/) > -1
    //       ) {
    //         return { ...v, show: false };
    //       } else {
    //         return v;
    //       }
    //     })
    //   );
    // }

    // if (platform === "linkedin") {
    //   setActionButtons(
    //     actionButtons.map((v: any, i: any) => {
    //       if (v.label === "Change Status" && level.search(/\bad\b/) > -1) {
    //         return { ...v, show: false };
    //       } else {
    //         return v;
    //       }
    //     })
    //   );
    // }
  }, [level]);

  const handleChange = (event: any) => {
    if (event.target.name === "selectLevel") {
      setCampaignsData([]);

      setLevel(event.target.value);

      let tempItem = levelsOptions.find(
        (v: any) => v.value === event.target.value
      );
      setLevelOptionSelected(tempItem);
      clearErrors(["selectLevel"]);
      const temp = [
        {
          platforms: ["facebook", "google", "linkedin", "bing"],
          levels: ["campaign", "adset", "adgroup", "ad"],
          label: "Change Budget",
          labelkey: "changeBudgetAction",
          selected: false,
          id: 1,
          show: true,
        },
        {
          platforms: ["facebook", "google", "linkedin", "bing"],
          levels: ["campaign", "adset", "adgroup", "ad"],
          label: "Change Bid",
          labelkey: "changeBidAction",
          selected: false,
          id: 2,
          show: true,
        },
        {
          platforms: ["facebook"],
          levels: ["campaign", "adset", "adgroup", "ad"],
          label: "Change Status",
          labelkey: "changeStatusAction",
          selected: false,
          id: 3,
          show: true,
        },
      ];
      setActionButtons(temp);
      return;
    }
    if (event.target.name === "budgetAction") {
      setBudgetAction(event.target.value);
      return;
    }
    if (event.target.name === "budgetActionType") {
      setBudgetActionType(event.target.value);
      return;
    }
    if (event.target.name === "changeStatus") {
      setChangeStatus(event.target.value);
      return;
    }
  };

  const {
    handleSubmit,
    register,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      selectLevel: "",
      selectedOption: "",
    },
  });

  const getPlatformLevels = useCallback((platform: string) => {
    switch (platform) {
      case "facebook":
        setlevelsOptions([
          { name: "Campaign", value: "campaign" },
          { name: "Ad Set", value: "adset" },
          { name: "Ad", value: "ad" },
        ]);
        break;
      case "google":
        setlevelsOptions([
          { name: "Campaign", value: "campaign" },
          { name: "Ad Group", value: "adset" },
          { name: "Keywords", value: "keyword" },
          { name: "Ad", value: "ad" },
        ]);
        break;
      case "linkedin":
        setlevelsOptions([{ name: "Campaign", value: "adset" }]);
        break;
      case "bing":
        setlevelsOptions([
          { name: "Campaign", value: "campaign" },
          { name: "Ad Group", value: "adset" },
          { name: "Keywords", value: "keyword" },
        ]);
        break;
      default:
        setlevelsOptions([]);
    }
  }, []);

  useEffect(() => {
    getPlatformLevels(platform);
  }, [platform]);

  useEffect(() => {
    let activeButton = actionButtons.find((v: any) => v.selected);
    setactionActionButton(activeButton?.labelkey);
  }, [actionButtons]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setFileUploaded(null);
    dispatch(resetFormDataState());
  };
  const handleClose = () => {
    setOpen(false);
    setFileUploaded(null);
    setSelectedCardType(null);
    dispatch(resetFormDataState());
  };

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_OPEN_SEARCH_AI_DATA" });
      setLevel("");
    };
  }, [open]);

  const statusMapper: any = { recommend: "IGNORED", execute: "WAITING" };

  const mixpanelExecuteOrRecommendCallback = (
    response: any,
    errorMessage: any
  ) => {
    return executionText.toLowerCase() === Object.keys(statusMapper)[0]
      ? sendMixpanelForSingleBulkActionsRecommendEvent(response, errorMessage)
      : sendMixpanelForSingleBulkActionsExecuteEvent(response, errorMessage);
  };

  //form submission
  const handleLeversSubmit = () => {
    sendMixpanelManualActionPublishedEvent();
    // console.log("selectedItem :: ", selectedItem);
    let concept_id = generateParamBody(level, selectedItem, platform);
    setConfirmModal(false);
    if (level === "keyword") {
      if (actionActionButton === "changeStatusAction") {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: changeStatus === "start" ? "START" : "PAUSE",
              level: level,
              concept_ids: concept_id,
              adset_id: selectedItem?.adset_id,
              value_absolute: null,
              value_percent: null,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              action_basis: actionBasis !== "" ? actionBasis : null,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
        return;
      }
      dispatch(
        submitManualActionData({
          user: auth.user,
          formData: {
            action_to_do: "SET_BID",
            level: level,
            concept_ids: concept_id,
            adset_id: selectedItem?.adset_id,
            value_absolute: budgetActionValueAbsolute,
            value_percent: null,
            ad_account_id: selectedAdAccount?.adAccountId,
            platform_id: getPlatformID(),
            elementid: selectedAdAccount?.elementId,
            confidence_score: confidenceScore !== "" ? confidenceScore : null,
            action_basis: actionBasis !== "" ? actionBasis : null,
            status: statusMapper[executionText.toLowerCase()],
          },
          callback: mixpanelExecuteOrRecommendCallback,
        })
      );
      return;
    }
    if (actionActionButton === "changeBudgetAction") {
      if (
        budgetAction === "changeByPercent" &&
        budgetActionType === "increase"
      ) {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "INCREASE_BUDGET",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: null,
              value_percent: budgetActionValuePercent,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
      if (
        budgetAction === "changeByPercent" &&
        budgetActionType === "decrease"
      ) {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "DECREASE_BUDGET",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: null,
              value_percent: budgetActionValuePercent,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
      if (budgetAction === "changeByValue" && budgetActionType === "increase") {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "INCREASE_BUDGET",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: budgetActionValueAbsolute,
              value_percent: null,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
      if (budgetAction === "changeByValue" && budgetActionType === "decrease") {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "DECREASE_BUDGET",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: budgetActionValueAbsolute,
              value_percent: null,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
      if (budgetAction === "setValue") {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "SET_BUDGET",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: budgetActionValueAbsolute,
              value_percent: null,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
    } else if (actionActionButton === "changeBidAction") {
      if (
        budgetAction === "changeByPercent" &&
        budgetActionType === "increase"
      ) {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "INCREASE_BID",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: null,
              value_percent: budgetActionValuePercent,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
      if (
        budgetAction === "changeByPercent" &&
        budgetActionType === "decrease"
      ) {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "DECREASE_BID",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: null,
              value_percent: budgetActionValuePercent,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
      if (budgetAction === "changeByValue" && budgetActionType === "increase") {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "INCREASE_BID",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: budgetActionValueAbsolute,
              value_percent: null,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
      if (budgetAction === "changeByValue" && budgetActionType === "decrease") {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "DECREASE_BID",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: budgetActionValueAbsolute,
              value_percent: null,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
      if (budgetAction === "setValue") {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "SET_BID",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: budgetActionValueAbsolute,
              value_percent: null,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
    } else {
      if (changeStatus === "start") {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "START",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: null,
              value_percent: null,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
      if (changeStatus === "pause") {
        dispatch(
          submitManualActionData({
            user: auth.user,
            formData: {
              action_to_do: "PAUSE",
              action_basis: actionBasis !== "" ? actionBasis : null,
              level: level,
              confidence_score: confidenceScore !== "" ? confidenceScore : null,
              concept_ids: concept_id,
              adset_id: null,
              value_absolute: null,
              value_percent: null,
              ad_account_id: selectedAdAccount?.adAccountId,
              platform_id: getPlatformID(),
              elementid: selectedAdAccount?.elementId,
              status: statusMapper[executionText.toLowerCase()],
            },
            callback: mixpanelExecuteOrRecommendCallback,
          })
        );
      }
    }
  };

  const handleActionButton = (id: any) => {
    let temp = actionButtons.map((value: any, index: any) => {
      if (value.id === id) {
        return { ...value, selected: true };
      } else {
        return { ...value, selected: false };
      }
    });
    setActionButtons(temp);
  };

  const handleCampaignSearch = (searchString: string) => {
    setSearchValue(searchString);
    setOpenDataList(true);
    dispatch(
      getOpenSearchAIData({
        user: auth.user,
        metaData: {
          platform_id: getPlatformID(),
          element_id: selectedAdAccount?.elementId,
          desired_asset_levels: level,
          search_key: searchString,
          skip: 0,
          limit: 100,
        },
      })
    );
    setClickaway(true);
  };

  const getPlatformID = () => {
    if (platform === "facebook") return 1;
    if (platform === "google") return 2;
    if (platform === "linkedin") return 3;
    if (platform === "bing") return 4;
    if (platform === "tiktok") return 5;
    if (platform === "dv360") return 6;
    if (platform === "apple") return 7;
    if (platform === "pinterest") return 23;
  };

  const getVariable = () => {
    if ("campaign" in openSearchAiData) return "campaign";
    if ("adset" in openSearchAiData) return "adset";
    if ("ad" in openSearchAiData) return "ad";
    if ("keyword" in openSearchAiData) return "keyword";
  };

  const [validValues, setValidValues] = useState<any>(true);

  useEffect(() => {
    if (actionActionButton === "changeBudgetAction") {
      if (budgetAction === "changeByPercent") {
        if (budgetActionValuePercent !== "") {
          setValidValues(false);
        } else {
          setValidValues(true);
        }
      }
      if (budgetAction === "setValue") {
        if (budgetActionValueAbsolute !== "") {
          setValidValues(false);
        } else {
          setValidValues(true);
        }
      }
      if (budgetAction === "changeByValue") {
        if (budgetActionValueAbsolute !== "") {
          setValidValues(false);
        } else {
          setValidValues(true);
        }
      }
    }

    if (actionActionButton === "changeBidAction") {
      if (budgetAction === "changeByPercent") {
        if (budgetActionValuePercent !== "") {
          setValidValues(false);
        } else {
          setValidValues(true);
        }
      }
      if (budgetAction === "setValue") {
        if (budgetActionValueAbsolute !== "") {
          setValidValues(false);
        } else {
          setValidValues(true);
        }
      }
      if (budgetAction === "changeByValue") {
        if (budgetActionValueAbsolute !== "") {
          setValidValues(false);
        } else {
          setValidValues(true);
        }
      }
    }
    if (actionActionButton === "changeStatusAction") {
      if (changeStatus !== "") {
        setValidValues(false);
      } else {
        setValidValues(true);
      }
    }
  }, [
    actionActionButton,
    budgetAction,
    budgetActionValuePercent,
    budgetActionValueAbsolute,
    changeStatus,
  ]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const sendMixpanelManualActionPublishedEvent = () => {
    mixPanelAdEvent("Manual Action Published", {
      module_name: "Action Impact Analysis - Single platforms",
      platform_name: platform,
      ad_account_id: selectedAdAccount?.adAccountId,
      ad_account_name: selectedAdAccount?.name,
      ai_group_id: selectedGroup?.id,
      ai_group_name: selectedGroup?.name,
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      level: level,
      action_type:
        actionActionButton === "changeBudgetAction"
          ? "Change Budget"
          : actionActionButton === "changeBidAction"
          ? "Change Bid"
          : "Change Status",
      sub_action_type:
        budgetAction === "setValue"
          ? "Set"
          : actionActionButton === "changeBudgetAction" ||
            actionActionButton === "changeBidAction"
          ? budgetActionType
          : changeStatus,
    });
  };

  const sendMixpanelForSingleBulkActionsRecommendEvent = (
    response: any,
    errorMessage: any
  ) => {
    mixPanelAdEvent("Manual Actions Recommended", {
      username: userDetailInfo?.name,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      email: userDetailInfo?.email,
      brand_id: selectedBrand?.id,
      ai_group_name: selectedGroup?.name,
      platform_selected: selectedPlatform?.name,
      ai_group_id: selectedGroup?.id,
      asset: selectedItem,
      asset_type: level,
    });
  };

  const sendMixpanelForSingleBulkActionsExecuteEvent = (
    response: any,
    errorMessage: any
  ) => {
    mixPanelAdEvent("Manual Actions Executed", {
      username: userDetailInfo?.name,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      email: userDetailInfo?.email,
      brand_id: selectedBrand?.id,
      ai_group_name: selectedGroup?.name,
      platform_selected: selectedPlatform?.name,
      ai_group_id: selectedGroup?.id,
      asset: selectedItem,
      asset_type: level,
    });
  };

  const sendMixpanelForUploadingCSVManualActions = (
    response: any,
    errorMessage: any
  ) => {
    mixPanelAdEvent("Manual Action CSV uploaded", {
      username: userDetailInfo?.name,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      email: userDetailInfo?.email,
      brand_id: selectedBrand?.id,
      platform_selected: selectedPlatform?.name,
      status: errorMessage ? "FAILED" : "SUCCESS",
      error_message: errorMessage,
    });
  };
  
  const sendMixpanelForUploadingCSVStrategicActions = (
    response: any,
    errorMessage: any
  ) => {
    mixPanelAdEvent("Manual Action Strategic Actions uploaded", {
      username: userDetailInfo?.name,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      email: userDetailInfo?.email,
      brand_id: selectedBrand?.id,
      platform_selected: selectedPlatform?.name,
      status: errorMessage ? "FAILED" : "SUCCESS",
      error_message: errorMessage,
    });
  };

  const fileUploadSubmit = (file: any, isStrategicAction: boolean) => {
    dispatch(
      submitFileManualAction({
        brandId: selectedBrand?.id,
        platformId: getPlatformID(),
        file: fileUploaded[0],
        callback: isStrategicAction ? sendMixpanelForUploadingCSVStrategicActions : sendMixpanelForUploadingCSVManualActions,
        isStrategicAction,
        aiGroupId: groupId,
      })
    );
    /*Commenting this out as here uploading csv mix panel event will be triggered*/
    // sendMixpanelManualActionPublishedEvent();
    // setOpen(false);
  };

  const [selectedCardType, setSelectedCardType] = useState<CardType | null>(
    null
  );
  const onCardSelect = (type: CardType | null) => setSelectedCardType(type);

  const renderSingleBulkActionModal = () => (
    <Modal
      open={open}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <SingleBulkActionModal
        handleClose={handleClose}
        level={level}
        errors={errors}
        register={register}
        handleChange={handleChange}
        levelsOptions={levelsOptions}
        levelOptionSelected={levelOptionSelected}
        searchValue={searchValue}
        handleCampaignSearch={handleCampaignSearch}
        isReadMore={isReadMore}
        selectedItem={selectedItem}
        setselectedItem={setselectedItem}
        textTruncateMore={textTruncateMore}
        platform={platform}
        isEmpty={isEmpty}
        wrapperRef={wrapperRef}
        openSearchAiData={openSearchAiData}
        openSearchAiDataState={openSearchAiDataState}
        getVariable={getVariable}
        CampaignInfoTooltip={CampaignInfoTooltip}
        toggleReadMore={toggleReadMore}
        actionButtons={actionButtons}
        BootstrapTooltip={BootstrapTooltip}
        handleActionButton={handleActionButton}
        tooltipClasses={tooltipClasses}
        actionActionButton={actionActionButton}
        budgetAction={budgetAction}
        budgetActionType={budgetActionType}
        budgetActionValueAbsolute={budgetActionValueAbsolute}
        setBudgetActionValueAbsolute={setBudgetActionValueAbsolute}
        getCurrencySymbol={getCurrencySymbol}
        accountDetails={accountDetails}
        budgetActionValuePercent={budgetActionValuePercent}
        setBudgetActionValuePercent={setBudgetActionValuePercent}
        changeStatus={changeStatus}
        actionBasis={actionBasis}
        setActionBasis={setActionBasis}
        confidenceScore={confidenceScore}
        setConfidenceScore={setConfidenceScore}
        submitManualActionState={submitManualActionState}
        // isBrandViewer={isBrandViewer}
        isBrandViewer={isViewerRole(brandRoles)}
        brandViewerAccessText={brandViewerAccessText}
        setConfirmModal={setConfirmModal}
        setExecutionText={setExecutionText}
        validValues={validValues}
        onBack={!!selectedCardType ? onCardSelect.bind(null, null) : undefined}
      />
    </Modal>
  );

  const renderCsvUploadModal = () => (
    <TriggerActionsFileUpload
      title="Upload CSV"
      description="Upload a CSV file containing a list of all your Assets within this
      Brand and Platform and execute your Manual Action"
      open={open}
      onClose={handleClose}
      fileUploaded={fileUploaded}
      onFileUpload={(value) => setFileUploaded(value)}
      onSubmit={fileUploadSubmit}
      onBack={!!selectedCardType ? onCardSelect.bind(null, null) : undefined}
      isManualActionButton={true}
      isStrategicAction={false}
    />
  );

  const renderCsvUploadStrategicModal = () => (
    <TriggerActionsFileUpload
      title="Upload Strategic Actions"
      description="Please upload a .CSV file with your recommended strategic actions for any assets on
      the Google video platform. Note: This file should contain maximum of 20 actions"
      open={open}
      onClose={handleClose}
      fileUploaded={fileUploaded}
      onFileUpload={(value) => setFileUploaded(value)}
      onSubmit={fileUploadSubmit}
      onBack={!!selectedCardType ? onCardSelect.bind(null, null) : undefined}
      isManualActionButton={true}
      isStrategicAction={true}
    />
  );

  return (
    <>
      <ManualAction
        sx={{
          boxShadow: "none",
          fontSize: "0.8rem",
          fontWeight: "400",
          height: "41px",
          margin: "0 !important",
          "&:hover": {
            backgroundColor: "#0869fb",
          },
        }}
        disableElevation
        onClick={() => {
          handleOpen();
          mixPanelAdEvent("Manual Action Clicked", {
            module_name: "Action Impact Analysis - Single platforms",
            platform_name: platform,
            ad_account_id: selectedAdAccount?.adAccountId,
            ad_account_name: selectedAdAccount?.name,
            ai_group_id: selectedGroup?.id,
            ai_group_name: selectedGroup?.name,
            brand_id: selectedBrand?.id,
            brand_name: selectedBrand?.name,
            brand_type: selectedBrand?.brandType,
            agency_id: selectedBrand?.agencyId,
            agency_name: selectedBrand?.agency_name,
          });
        }}
        variant="contained"
      >
        Manual Action
      </ManualAction>
      {
        ["facebook", "google"].includes(platform)
          ? {
              null: () => (
                <CardSelection
                  open={open}
                  onClose={handleClose}
                  onSelect={onCardSelect}
                  isGoogleVideo={isGoogleVideo}
                />
              ),
              [CardType.STRATEGIC_CSV_UPLOAD]: renderCsvUploadStrategicModal,
              [CardType.CSV_UPLOAD]: renderCsvUploadModal,
              [CardType.SINGLE_BULK_ACTIONS]: renderSingleBulkActionModal,
            }[selectedCardType || "null"]()
          : renderCsvUploadModal() // show csv modal for all the other platforms
      }
      {confirmModal && (
        <ConfirmationModal
          open={confirmModal}
          handleCancel={() => setConfirmModal(false)}
          handleSubmit={() => handleLeversSubmit()}
          confirmText={executionText}
        />
      )}
    </>
  );
};

export default ManualActionButton;
