import React from "react";
import ActiveToken from "./activeToken";
import Reconnect from "./reconnect";
import { ReactComponent as Fb } from "assets/svg/platforms/icons8-facebook.svg";
import { ReactComponent as Google } from "assets/svg/platforms/icons8-google.svg";
import { ReactComponent as Linkedin } from "assets/svg/platforms/icons8-linkedin.svg";
import { ReactComponent as Tiktok } from "assets/svg/platforms/icons8-tiktok.svg";
import { ReactComponent as Microsoft } from "assets/svg/platforms/icons8-microsoft.svg";
import { ReactComponent as Dv360 } from "assets/svg/platforms/icons8-dv360.svg";
import { ReactComponent as Pinterest } from "assets/svg/pinterest.svg";
import { Modal } from "@mui/material";

const SUPPORTED_PLATFORMS = ["google", "facebook", "linkedin"];

const TableRow: React.FC<{
  data?: any;
  setCounter: any;
  href: string;
  isDeleteLoaderId: any;
  setIsDeleteLoaderId: any;
}> = ({ data, setCounter, href, isDeleteLoaderId, setIsDeleteLoaderId }) => {
  const [showToken, setShowToken] = React.useState<boolean>(false);

  const platformName = (platform: string) => {
    switch (platform) {
      case "facebook":
        return "Meta";
      case "google":
        return "Google";
      case "linkedin":
        return "Linkedin";
      case "tiktok":
        return "Tiktok";
      case "microsoft advertising":
        return "Bing";
      case "display and video 360":
        return "DV360";
      case "pinterest ads":
        return "Pinterest";
    }
  };
  const platformIcon = (platform: string) => {
    switch (platform) {
      case "facebook":
        return <Fb style={{ height: "18px", width: "18px" }} />;
      case "google":
        return <Google style={{ height: "18px", width: "18px" }} />;
      case "linkedin":
        return <Linkedin style={{ height: "18px", width: "18px" }} />;
      case "tiktok":
        return <Tiktok style={{ height: "18px", width: "18px" }} />;
      case "microsoft advertising":
        return <Microsoft style={{ height: "18px", width: "18px" }} />;
      case "display and video 360":
        return <Dv360 style={{ height: "18px", width: "18px" }} />;
      case "pinterest ads":
        return <Pinterest style={{ height: "18px", width: "18px" }} />;
    }
  };
  return (
    <>
      <div className="table-row">
        <div className="account-name">
          <span className="account-name-text">
            {data.adAccountName || data.adAccountId}
          </span>
          {data.tokenWarnings.message ? (
            <div
              className={`token-message token-${data.tokenWarnings.className}`}
            >
              <span>{data.tokenWarnings.message}</span>
            </div>
          ) : null}
        </div>
        <div>{data.adAccountId}</div>
        <div>
          <div style={{ width: "100px", display: "flex", gap: "10px" }}>
            <div>{platformIcon(data.parentPlatformName)}</div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "Inter",
                color: "#111827",
              }}
            >
              {platformName(data.parentPlatformName)}
            </div>
          </div>
        </div>
        <div
          className={`tokens-stat${
            data.tokens.length === 0 ? " zero-tokens" : ""
          }`}
          onClick={() => {
            if (data.tokens.length) {
              setShowToken(true);
            }
          }}
        >
          {data.tokens.length
            ? `${data.availableTokens} out of ${data.tokens.length}`
            : 0}
        </div>
        <div>
          <Reconnect
            count={data.tokens.length}
            platform={data.parentPlatformName}
            isBlocked={
              SUPPORTED_PLATFORMS.indexOf(data.parentPlatformName) === -1
            }
            href={href}
          />
        </div>
      </div>
      {showToken && (
        <Modal open={showToken}>
          <ActiveToken
            data={data}
            handleClose={() => {
              setShowToken(false);
            }}
            setCounter={setCounter}
            isDeleteLoaderId={isDeleteLoaderId}
            setIsDeleteLoaderId={setIsDeleteLoaderId}
          />
        </Modal>
      )}
    </>
  );
};

export default TableRow;
