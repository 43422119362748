import { memo, useCallback, useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import ForceGraph3D from "react-force-graph-3d";
import * as THREE from "three";
import SpriteText from "three-spritetext";
// import rawData from './data'
// import createNodesAndLinks from './helper'
import { cloneDeep } from "lodash";
import normalEllipse from "../../../assets/png/normalEllipse.png";
import sphere from "../../../assets/png/sphere.png";
import { cohortTypes } from "../components/constants";
import { useSearchParams } from "react-router-dom-v5-compat";

const changeSelectedNodeColors = (nodesData, clickedNode) => {
  if (clickedNode) {
    const allConnectedNodesIds = [clickedNode["id"]];
    nodesData.links.forEach((l) => {
      let source = l.source["id"] || l.source;
      let target = l.target["id"] || l.target;

      if (source === clickedNode["id"]) {
        allConnectedNodesIds.push(target);
      } else if (target === clickedNode["id"]) {
        allConnectedNodesIds.push(source);
      }
    });

    //if connected nodes are child
    //remove all child except the selected nodes
    const allChildNodes = [];
    const nodes = nodesData.nodes
      .map((n) => {
        if (n.type === "child") {
          allChildNodes.push(n.id);
        }
        // if (allConnectedNodesIds.includes(n.id)) {
        if (n.id === clickedNode["id"]) {
          // n.nodeColor = "blue";
          n.image = sphere;
          n.textColor = "white";
        }
        return n;
      })
      .filter((nf) => {
        if (nf.type !== "child") {
          return nf;
        } else if (allConnectedNodesIds.includes(nf.id)) {
          return nf;
        }
        return false;
      });
    const allPopulatedNodes = nodes.map((n) => n.id);

    const links = nodesData.links

      .map((li) => {
        let source = li.source["id"] || li.source;
        let target = li.target["id"] || li.target;
        if (allChildNodes.includes(source) || allChildNodes.includes(target)) {
          li.color = "blue";
        }
        return li;
      })
      .filter((nl) => {
        let source = nl.source["id"] || nl.source;
        let target = nl.target["id"] || nl.target;
        if(nl.color=="blue")
        {
          return  allPopulatedNodes.includes(source) &&
          allPopulatedNodes.includes(target) && (clickedNode.id==nl.source)
        }
        else if (
          allPopulatedNodes.includes(source) &&
          allPopulatedNodes.includes(target)
        ) {
          return nl;
        }
        return false;
      });

    const updatedData = {
      nodes,
      links,
    };
    return updatedData;
  }
};

const showMainNodes = (allNodesData) => {
  const nodes = allNodesData.nodes.filter((n) => n.type === "Category");
  const nodesIds = nodes.map((n) => n["id"]);
  const links = [];

  for (let index = 0; index < allNodesData.links.length; index++) {
    const element = allNodesData.links[index];

    if (
      nodesIds.includes(element.source["id"] || element.source) &&
      nodesIds.includes(element.target["id"] || element.target)
    ) {
      links.push(element);
    }
  }

  return {
    nodes,
    links,
  };
};

// const formattedData = createNodesAndLinks(rawData)
export default memo(({ isConnectable }) => {

  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  const [graphDataFromBeta, setGraphDataFromBeta] = useState(false)

    useEffect(() => {
      const handleMessage = (event) => {
        const data = event?.data;
        if(data?.message==='COHORTS-DATA')
            setGraphDataFromBeta(data?.data)
        }
    
      window.addEventListener('message', handleMessage);
    
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, []);
    
  let parsedData = JSON.parse(localStorage.getItem("newTabGraphData"));
  if(isViewedFromBeta && graphDataFromBeta)
    parsedData = JSON.parse(graphDataFromBeta);

  const fgRef = useRef();

  const [graphData, setGraphData] = useState({ nodes: [], links: [] });

  const handleClick = useCallback(
    (node) => {
      if (node) {
        const distance = 250;
        const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

        fgRef.current.cameraPosition(
          {
            x: node.x * distRatio,
            y: node.y * distRatio,
            z: node.z * distRatio,
          }, // new position
          node, // lookAt ({ x, y, z })
          1500 // ms transition duration
        );
        //changeColors of selected node and its children

        const newData = changeSelectedNodeColors(
          cloneDeep(parsedData?.formattedData),
          node
        );
        const uniqueNodes = [
          ...new Map(newData?.nodes.map((item) => [item.id, item])).values(),
        ];
        setGraphData({ ...newData, nodes: uniqueNodes });

        // const indexNodes = newData.nodes.findIndex(
        //   (e) => Number(e.id.split("-")[1]) === parsedData.graphNodeLength
        // );
        // const nodes = [
        //   ...newData.nodes.slice(0, indexNodes + 1),
        //   ...newData.nodes.filter((e) => e.type === "child"),
        // ];
        // const links = getLinks(newData.links);
        // setGraphData({ links, nodes });
      }
    },
    [fgRef,graphDataFromBeta]
  );

  const getLinks = (graphDataLinks) => {
    let links;
    links = graphDataLinks.filter((e) => {
      let source;
      let target;
      if (typeof e.source === "string") source = Number(e.source.split("-")[1]);
      else source = Number(e.source.id.split("-")[1]);
      if (typeof e.target === "string")
        target =
          e.target.split("-").length > 1
            ? Number(e.target.split("-")[1])
            : e.target;
      else
        target =
          e.target.id.split("-").length > 1
            ? Number(e.target.id.split("-")[1])
            : e.target;
      if (typeof target === "string")
        return parsedData?.shownClusterIDs?.includes(`${source}`);
      return (
        parsedData?.shownClusterIDs?.includes(`${source}`) &&
        parsedData?.shownClusterIDs?.includes(`${target}`)
      );
    });
    return links;
  };

  useEffect(() => {
    if(parsedData && (!isViewedFromBeta ||  graphDataFromBeta))
    {
    const wholeData = showMainNodes(cloneDeep(parsedData?.formattedData));
    localStorage.removeItem("newTabGraphData");
    
    if(isViewedFromBeta)
    {
      window.parent.postMessage(
      {
        type: 'DELETE-FROM-LOCAL',
        key: ''
      },
      '*'
      );
    }

    let links;
    if (
      wholeData.nodes &&
      parsedData?.graphNodeLength > wholeData.nodes.length
    ) {
      setGraphData(wholeData);
    } else {
      if (wholeData.links) links = getLinks(wholeData.links);
      if (wholeData.nodes && wholeData.nodes.length > 4)
        setGraphData({
          links,
          nodes: wholeData.nodes.slice(0, parsedData?.graphNodeLength),
        });
    }

    if (fgRef.current) {
      fgRef.current.d3Force("link").distance((link) => {
        return link.distance ? link.distance * 10 : 60;
      });
    }}
  }, [graphDataFromBeta]);

  const handleBackgroundClick = useCallback(() => {
    setGraphData(showMainNodes(cloneDeep(parsedData?.formattedData)));
    d3.selectAll("#node-info-container").remove();
    fgRef.current.cameraPosition({ x: -500, y: -500, z: -500 }, 0, 0);
  }, []);

  const drawImageNode = useCallback((node) => {
    const { image, textColor, type } = node;

    if (type === "child") {
      const spriteText = new SpriteText(node.id);
      spriteText.color = "black";
      spriteText.textHeight = 10;

      return spriteText;
    } else {
      const imgTexture = new THREE.TextureLoader().load(
        image.trim() === "" ? normalEllipse : image
      );
      const material = new THREE.SpriteMaterial({
        color:
          node.textColor === "white" ? cohortTypes[node.clus_type]?.color : "",
        map: imgTexture,
      });
      const sprite = new THREE.Sprite(material);
      const group = new THREE.Group();
      const spriteText = new SpriteText(node.id);
      spriteText.color = textColor;
      spriteText.fontWeight = 700;
      spriteText.textHeight = 10;
      sprite.scale.set(65, 65);

      group.add(sprite);
      group.add(spriteText);

      return group;
    }
  }, []);

  const nodeThreeObject = useCallback(
    (node) => {
      fgRef.current.cameraPosition({ x: -500, y: -500, z: -500 }, 0, 0);

      return drawImageNode(node);
    },
    [drawImageNode]
  );

  if(isViewedFromBeta && !graphDataFromBeta)
    return <>Loading...</>

  return (
    <ForceGraph3D
      ref={fgRef}
      backgroundColor="#F5F9FF"
      nodeOpacity={1}
      graphData={graphData}
      nodeThreeObject={nodeThreeObject}
      nodeLabel={(node) => `<span style="color: black">${node.label}</span>`}
      linkLabel={(link) =>
        `<span style="color: black">relevance : ${
          Math.round(link.relevance * 100) / 100
        }</span>`
      }
      nodeVal={(node) => node.size}
      linkOpacity={1}
      linkWidth={"width"}
      onNodeClick={handleClick}
      onBackgroundClick={handleBackgroundClick}
      onNodeDragEnd={(node) => {
        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
      }}
      showNavInfo={false}
      zoomToFit={true}
    />
  );
});
