import React, { memo, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import {
  setSelectedCohortsForPublish,
  storeTAIFavouriteCohort,
  deleteTAIFavouriteCohort,
  getTAIDraftedAndRecommendedCohorts,
  mergeCohorts,
  setKeywordTuningData,
  setKeywordTuningJson,
} from "../../../store/actions/targetingAI";
import "./CohortProcessInfo.scss";
import { mixPanelAdEvent } from "../../../utils/mixpanel";
import { mixPanelInputCommonFunction } from "../utils/MixpanelInputCommonFunction";
import CohortInfoModel from "../CohortInfoModel";
import { TaiContext } from "../TaiContextProvider";
import { convertToInt } from "../utils/index";
import SaveModal from "../common/SaveModal";
import PublishModal from "./PublishModal";
import AudienceManagerSuccessModal from "Pages/AudienceManager/components/AudienceManagerSuccessModal";
import CohortType from "common/CohortType";
import { ReactComponent as InfoIcon } from "../assets/svgs/info.svg";
import MergeCohortModal from "./MergeCohortModal";
import ICPAudienceCohort from "./subcomponents/ICPAudienceCohort";
import {
  refreshCohortICP,
  storeFavouriteCohortICP,
  updateCohortICP,
} from "store/actions/targetingICP";
import { getJSONDataFromURL } from "utils/jsonFromURL";
import { isAdminRole } from "utils/commonFunctions";
import useFacebookTargetingEnumMapping from "utils/useFacebookTargetingsEnumMapping";

export default memo(({ data, isConnectable }) => {
  const dispatch = useDispatch();
  const {
    setNodes,
    startAgainFlow,
    setCheckedCohorts,
    setShownClusterIDs,
    cohortsJSON,
    setCohortsJSON,
    showMerged,
    setShowMerged,
    shownClusterData,
    setShownClusterData,
    cohortType,
  } = useContext(TaiContext);
  const authState = useSelector((state) => state.auth);
  const aiInitiationData = useSelector((state) => state.aiInitiation.apiData);
  const keywordTuningData = useSelector((state) => state.keywordTuning);
  const keywordTuningJson= useSelector((state) => state.keywordTuningJson);
  const calibrationData = useSelector((state) => state.calibration.formData);
  const { userInfo } = useSelector((state) => state.userInfoReducer);
  const { selectedAgency } = useSelector((state) => state.common);
  const [unsortedClusterData, setUnsortedClusterData] = useState([]);
  const [clusterData, setClusterData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [nameIdMapping, setNameIdMapping] = useState({});
  const [shownNameIdMapping, setShownNameIdMapping] = useState({});
  const [selectedCohorts, setSelectedCohorts] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const adaccount = useSelector((state) => state.adaccount);
  const { brandRoles } = adaccount;
  const [selectedCohort, setSelectedCohort] = useState(-1);
  const [isFav, setIsFav] = useState([]);
  const [isFavRef, setIsFavRef] = useState({});
  const [showSaveModal, setShowSaveModal] = useState({
    value: false,
    shownOnce: false,
  });
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [prevLength, setPrevLength] = useState(0);

  const [disableMerge, setDisableMerge] = useState({
    disable: true,
    text: "Select the 2 cohorts that you want to merge together",
  });
  const [showMergeModal, setShowMergeModal] = useState(false);

  const prefetchedJsonForInterestBased =useSelector((state)=>state.keywordTuningJson)

  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;
  // const isAdmin = userInfo?.roles?.includes("super-admin");

  const handleOnChange = (id) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === id ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const handlePublish = () => {
    const cohorts = selectedCohorts.map((cluster) => {
      if (calibrationData?.TypeOfCohort === "ICP") {
        return {
          type: "ICP",
          cohort_name: cluster.cohort_name,
          cohort_id: cluster.cohort_id,
          audience_size: cluster.audience_size,
          relevance_score: cluster.relevance,
          performance_score: cluster.performance_score,
          brandId: "",
          brand_name: aiInitiationData?.name,
          brand_website: aiInitiationData?.website,
          explore: 100 - calibrationData?.ExploitValue,
          exploit: calibrationData?.ExploitValue,
          icp_context_description: calibrationData?.ICPContextDescription,
          targeting: {
            ...cluster?.targeting_spec,
            genders: cluster?.gender?.genders ? cluster?.gender?.genders : [],
          },
        };
      } else {
        return {
          id:
            isFavRef && isFavRef[cluster.cohort_name]
              ? isFavRef[cluster.cohort_name]
              : undefined,
          cohort_name: cluster.cohort_name,
          cohort_id: cluster.cohort_id,
          facebook_json: cluster.facebook_json,
          audience_size: cluster.audience_size,
          relevance_score: cluster.relevance,
          performance_score: cluster.performance_score,
          interest_keywords:
            cluster?.targetings !== "" ? cluster.targetings.split(",") : [],
          brandId: "",
          brand_name: aiInitiationData?.name,
          brand_website: aiInitiationData?.website,
          calibrationData: {
            explore: calibrationData.ExploitValueCheck
              ? 100 - calibrationData?.ExploitValue
              : 0,
            exploit: calibrationData.ExploitValueCheck
              ? calibrationData?.ExploitValue
              : 0,
            brand_website_config: calibrationData.BrandWebsiteValueCheck
              ? calibrationData?.BrandWebsiteValue
              : 0,
            competitor_webstie: calibrationData.CompetitorWebsiteValueCheck
              ? calibrationData?.CompetitorWebsiteValue
              : 0,
            custom_keywords:
              calibrationData.CalibrationCustomKeywordsCheck &&
              calibrationData.CalibrationCustomKeywords.length > 0
                ? calibrationData.CalibrationCustomKeywords
                : [],
          },
        };
      }
    });
    if (cohorts && cohorts.length > 0) {
      dispatch(setSelectedCohortsForPublish(cohorts));
      setShowPublishModal(true);
      mixPanelAdEvent("TAI Review and Publish Clicked", {
        module_name: "Facebook Playground",
        agency_id: selectedAgency?.id,
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        agency_name: selectedAgency?.name,
        ai_group_name: aiInitiationData.aiGroup.name,
        ai_group_id: aiInitiationData.aiGroup.id,
        number_of_cohorts: cohorts?.length,
        cohort_details: cohorts,
        ad_account_id: aiInitiationData?.adAccount?.id,
        ad_account_name: aiInitiationData?.adAccount?.name,
        tai_flow: cohortType,
        cohort_list: cohorts?.reduce((prev, cohort) => {
          return {
            ...prev,
            [cohort.cohort_name]: cohort.cohort_id,
          };
        }, {}),
      });
    }
  };

  const onViewMoreClick = (clusterData) => {
    setSelectedCohort(clusterData);
  };

  useEffect(() => {
    const aiGroupId = aiInitiationData?.aiGroup?.id;
    // let type = isAdmin ? "isRecommended" : "isDrafted";
    let type = isAdminRole(brandRoles) ? "isRecommended" : "isDrafted";
    dispatch(
      getTAIDraftedAndRecommendedCohorts(
        { type, aiGroupId },
        (response, error) => {
          if (!error) {
            const isFavTemp = [...isFav];
            const isFavRefTemp = {
              ...isFavRef,
            };
            response?.data?.data.forEach((cohort) => {
              isFavTemp.push(cohort.cohort_name);
              isFavRefTemp[cohort.cohort_name] = cohort.id;
            });
            setIsFav(isFavTemp);
            setIsFavRef(isFavRefTemp);
          } else {
            console.log("failure get cohort", error);
          }
        }
      )
    );
  }, []);

  useEffect(() => {
    if (clusterData?.length > 0) {
      setCheckedState(new Array(clusterData?.length).fill(false));
    }
  }, [clusterData]);

  useEffect(() => {
    const data = checkedState
      .map((isChecked, index) => (isChecked ? shownClusterData[index] : null))
      .filter(Boolean);

    setSelectedCohorts(data);
    setCheckedCohorts(data);
    setShownClusterIDs([
      ...shownClusterData.map((i) => {
        if (!showMerged && i.clus_type === "merged") {
          setShowMerged(true);
        }
        return i.cohort_id;
      }),
    ]);

    if (data.length > 2)
      setDisableMerge({
        disable: true,
        text: "You can only merge 2 cohorts at a time",
      });
    if (data.length < 2)
      setDisableMerge({
        disable: true,
        text: "Select the 2 cohorts that you want to merge together",
      });
    if (data.length === 2 && data.find((i) => i.clus_type === "merged"))
      setDisableMerge({
        disable: true,
        text: "You cannot further merged a cohort again",
      });
    else if (data.length === 2) setDisableMerge({ disable: false, text: "" });
  }, [checkedState, shownClusterData]);

  useEffect(() => {
    const ele = document.getElementById(data.focusedCohort);
    if (ele) {
      if (![7, 8].includes(data.focusedCohort)) {
        ele.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  }, [data.focusedCohort]);

  const paginateCluster = () => {
    mixPanelAdEvent("TAI Generate More Clicked", {
      module_name: "Facebook Playground",
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_name: selectedAgency?.name,
      agency_id: selectedAgency?.id,
      ai_group_name: aiInitiationData?.aiGroup?.name,
      ai_group_id: aiInitiationData?.aiGroup?.id,
      ad_account_name: aiInitiationData?.adAccount?.name,
      ad_account_id: aiInitiationData?.adAccount?.id,
    });
    data.setGraphNodeLength(shownClusterData.length + 4, setNodes);
    const dummyClusterData = [...clusterData];
    const shownDataLength = shownClusterData.length;
    const firstCLusters = dummyClusterData.splice(shownDataLength, 4);
    let keyValue = {};

    firstCLusters.forEach((element) => {
      keyValue[element.cohort_name] = element.cohort_id;
    });
    setShownNameIdMapping({ ...shownNameIdMapping, ...keyValue });
    setShownClusterData([...shownClusterData, ...firstCLusters]);
    setPrevLength(shownDataLength + 4);
  };

  useEffect(async () => {
    if (keywordTuningData) {
      if (calibrationData?.TypeOfCohort === "ICP") {
        setCohortsJSON(keywordTuningData);
        // let jsonData = await getJSONDataFromURL(keywordTuningData);
        // BE sends decoded json along the json_url, so no need to make a get request to the s3 bucket from FE to get the json data
        const jsonData = keywordTuningJson; 
        const mergedData = [];
        const unMergedData = [];
        jsonData?.icps_targetings.forEach((item) => {
          if (item.edited_targetings_flag === true) mergedData.push(item);
          else unMergedData.push(item);
        });
        setClusterData([...mergedData, ...unMergedData]);
        let newData = [...jsonData?.icps_targetings];
        const firstClusters = newData.splice(
          0,
          prevLength > 0 ? prevLength : 4
        );
        setShownClusterData(firstClusters);
        setPrevLength(firstClusters.length);
        let shownNameIdMapping = {};
        const sortedGraphData = jsonData?.icps_targetings.map((i, index) => {
          if (index <= 3) {
            shownNameIdMapping[i.cohort_name] = i.cohort_id;
          }
          let graphItem = {};
          graphItem.interests = jsonData.graph_data?.interests.find(
            (item) => item?.cohort_id === i?.cohort_id
          );
          graphItem.demographics = jsonData.graph_data?.demographics.find(
            (item) => item?.cohort_id === i?.cohort_id
          );
          graphItem.behaviors = jsonData.graph_data?.behaviors.find(
            (item) => item?.cohort_id === i?.cohort_id
          );
          // graphItem.clus_type = i.clus_type;
          return graphItem;
        });
        setGraphData(sortedGraphData);
      } else {
        const getclusterData =  () => {
          setShowMerged(false);
          try {
            // const response = await fetch(keywordTuningData);
            setPrevLength(0);
            setCohortsJSON(keywordTuningData);
          } catch (e) {
            console.log(e);
          }
        };
        getclusterData();
      }
    }
  }, []);

  const handleUpdateCohort = (cohortData) => {
    setShownClusterData([]);
    setPrevLength(0);
    let newCohortData = { ...cohortData };
    if (newCohortData.targeting_spec?.genders?.[0] == "all") {
      newCohortData.targeting_spec.genders = null;
    }

    newCohortData.cohort_targeting_spec = cohortData?.targeting_spec;
    dispatch(
      updateCohortICP(
        {
          payloadData: {
            cohorts_json: keywordTuningData,
            updated_cohorts: [newCohortData],
          },
        },
        async (response, error) => {
          const {jsonUrl, jsonData} = response;
          // let jsonData = await getJSONDataFromURL(response);
          dispatch(setKeywordTuningData(jsonUrl));
          const mergedData = [];
          const unMergedData = [];
          jsonData?.icps_targetings.forEach((item) => {
            if (item.edited_targetings_flag === true) mergedData.push(item);
            else unMergedData.push(item);
          });
          let newData = [...mergedData, ...unMergedData];
          setClusterData(newData);
          const firstClusters = newData.splice(
            0,
            prevLength > 0 ? prevLength : 4
          );
          setShownClusterData(firstClusters);
          setPrevLength(firstClusters.length);
          let shownNameIdMapping = {};
          const sortedGraphData = jsonData?.icps_targetings.map((i, index) => {
            if (index <= 3) {
              shownNameIdMapping[i.cohort_name] = i.cohort_id;
            }
            let graphItem = {};
            graphItem.interests = jsonData.graph_data?.interests.find(
              (item) => item?.cohort_id === i?.cohort_id
            );
            graphItem.demographics = jsonData.graph_data?.demographics.find(
              (item) => item?.cohort_id === i?.cohort_id
            );
            graphItem.behaviors = jsonData.graph_data?.behaviors.find(
              (item) => item?.cohort_id === i?.cohort_id
            );
            // graphItem.clus_type = i.clus_type;
            return graphItem;
          });
          setGraphData(sortedGraphData);
        }
      )
    );
  };

  useEffect(() => {
    if (calibrationData?.TypeOfCohort !== "ICP") {
      const getclusterData =  () => {
            setUnsortedClusterData(prefetchedJsonForInterestBased.cluster_data);
            let mergedData = [];
            let unmergedData = [];
            let keyValue = {};

            prefetchedJsonForInterestBased.cluster_data.forEach((element) => {
              keyValue[element.cohort_name] = element.cohort_id;
              if (
                element.clus_type === "merged" ||
                element.edited_targetings_flag === true
              ) {
                mergedData.push(element);
              } else {
                unmergedData.push(element);
              }
            });
            setNameIdMapping(keyValue);
            const sortedMergedData = mergedData.sort(
              (a, b) => b.relevance - a.relevance
            );
            const sortedUnmergedData = unmergedData.sort(
              (a, b) => b.relevance - a.relevance
            );
            const sortedData = [...sortedMergedData, ...sortedUnmergedData];
            let shownNameIdMapping = {};
            const sortedGraphData = sortedData.map((i, index) => {
              // if (index <= 3) {
              //   shownNameIdMapping[i.cohort_name] = i.cohort_id;
              // }
              const graphItem = prefetchedJsonForInterestBased.graph_data.find(
                (item) => item?.cohort_id === i?.cohort_id
              );
              // graphItem.clus_type = i.clus_type;
              return graphItem;
            });
            setGraphData(sortedGraphData);
            const firstClusters = sortedData.splice(
              0,
              prevLength > 0 ? prevLength : 4
            );
            firstClusters.forEach((i, index) => {
              if (index <= firstClusters.length) {
                shownNameIdMapping[i.cohort_name] = i.cohort_id;
              }
            });
            setShownNameIdMapping(shownNameIdMapping);

            setShownClusterData(firstClusters);
      };
      if (cohortsJSON === "" && shownClusterData.length > 0) {
        setPrevLength(shownClusterData.length);
      }
      setUnsortedClusterData([]);
      setClusterData([]);
      setShownClusterData([]);
      setGraphData([]);
      setNameIdMapping({});
      setShownNameIdMapping({});
      data.setFocusedCohort(
        null,
        "cohortNode",
        setNodes,
        selectedBrand,
        selectedAgency,
        cohortType
      );
      if (cohortsJSON !== "") {
        getclusterData();
      }
    }
  }, [cohortsJSON]);

  useEffect(()=>{
    if(calibrationData?.TypeOfCohort !== "ICP")
    {setCohortsJSON(keywordTuningData)}
  },[prefetchedJsonForInterestBased])

  useEffect(() => {
    const mergedData = [];
    const unMergedData = [];
    unsortedClusterData?.forEach((item) => {
      if (item.clus_type === "merged" || item.edited_targetings_flag === true)
        mergedData.push(item);
      else unMergedData.push(item);
    });
    mergedData.sort((a, b) => b.relevance - a.relevance);
    unMergedData.sort((a, b) => b.relevance - a.relevance);
    setClusterData([...mergedData, ...unMergedData]);
  }, [unsortedClusterData]);

  const apiOtherRequiredDataObject = {
    brand_id: selectedBrand?.id,
    brand_name: selectedBrand?.name,
    brand_website: selectedBrand?.brandWebsite,
    userInputBrandName: aiInitiationData?.name,
    userInputBrandWebsite: aiInitiationData?.website,
    explore: 100 - calibrationData?.ExploitValue,
    exploit: convertToInt(calibrationData?.ExploitValue),
    intent: convertToInt(calibrationData?.IntentValue),
    traffic: convertToInt(calibrationData?.TrafficValue),
    brand_website_config: convertToInt(calibrationData?.BrandWebsiteValue),
    competitor_website: convertToInt(calibrationData?.CompetitorWebsiteValue),
    custom_keywords: calibrationData?.CalibrationCustomKeywords,
    ad_account_id: aiInitiationData?.adAccount?.id,
  };

  const apiOtherRequiredDataObjectForICP = {
    brand_id: selectedBrand?.id,
    brand_name: selectedBrand?.name,
    brand_website: selectedBrand?.brandWebsite,
    userInputBrandName: aiInitiationData?.name,
    userInputBrandWebsite: aiInitiationData?.website,
    explore: 100 - calibrationData?.ExploitValue,
    exploit: convertToInt(calibrationData?.ExploitValue),
    icp_context_description: calibrationData?.ICPContextDescription,
    ad_account_id: aiInitiationData?.adAccount?.id,
    clus_type: "direct",
    type: "ICP",
  };

  const handleFavAPI = (id, name, data) => {
    const user = authState?.user;
    const aiGroupId = aiInitiationData?.aiGroup?.id;
    mixPanelAdEvent("TAI Cohort Saved", {
      module_name: "Facebook Playground",
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_name: selectedAgency?.name,
      agency_id: selectedAgency?.id,
      ai_group_name: aiInitiationData.aiGroup.name,
      ai_group_id: aiInitiationData.aiGroup.id,
      cohort_details: data,
      ad_account_id: aiInitiationData?.adAccount?.id,
      ad_account_name: aiInitiationData?.adAccount?.name,
    });

    if (isFav.includes(name)) {
      dispatch(
        deleteTAIFavouriteCohort(
          { deleteFavouriteCohortIds: [isFavRef[name]], aiGroupId, user },
          (response, error) => {
            if (!error) {
              let newIsFavRef = { ...isFavRef };
              setIsFav(isFav.filter((el) => el !== name));
              delete newIsFavRef[name];
              setIsFavRef({
                ...newIsFavRef,
              });
            } else {
              console.log("failure delete cohort", error);
            }
          }
        )
      );
    } else {
      const favouriteCohort = [];
      if (calibrationData?.TypeOfCohort === "ICP") {
        const dataObject = {
          ...apiOtherRequiredDataObjectForICP,
          targetings: data?.cohort_name,
          facebook_json: data?.targeting_spec,
          cohort_id: data?.cohort_id,
          cohort_name: data?.cohort_name,
          edited_targetings_flag: false,
          audience_size: data?.audience_size,
          relevance: data?.relevance,
        };
        favouriteCohort.push(dataObject);
      } else {
        const dataObject = {
          ...data,
          ...apiOtherRequiredDataObject,
          interest_cluster: convertToInt(data.interest_cluster) || 1,
          performance_score:
            data.performance_score === "MEDIUM"
              ? "MED"
              : data.performance_score,
        };
        favouriteCohort.push(dataObject);
      }
      dispatch(
        storeTAIFavouriteCohort(
          { favouriteCohort, aiGroupId, user },
          (response, error) => {
            if (!error) {
              const favCohortslength = isFav.length;
              if (!showSaveModal.shownOnce) {
                setShowSaveModal({ ...showSaveModal, value: true });
              }
              setIsFav([...isFav, name]);
              setIsFavRef({
                ...isFavRef,
                [name]: response.data[0].id,
              });
            } else {
              console.log("failure save cohort", error);
            }
          }
        )
      );
    }
  };

  const scrollToRecomendedCohort = (cohort, e) => {
    e.stopPropagation();
    data.setFocusedCohort(
      cohort,
      "threeDchart",
      setNodes,
      selectedBrand,
      selectedAgency,
      cohortType
    );
  };

  const successModalProps = {
    startAgainClick: startAgainFlow,
    keepExploringClick: () => {
      setShowSuccessModal(false);
    },
    handleOutsideClick: () => {
      setShowSuccessModal(false);
    },
    subText: "Audiences will be published in the next 24 hours",
  };

  const handleMerge = () => {
    setShowMergeModal(false);
    setCohortsJSON("");
    dispatch(
      mergeCohorts({ selectedCohorts, cohortsJSON:keywordTuningData }, (link, jsonData) => {
        dispatch(setKeywordTuningData(link));
        dispatch(setKeywordTuningJson(jsonData));})
    );
    mixPanelAdEvent("TAI Cohorts Merged", {
      module_name: "Facebook Playground",
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_name: selectedAgency?.name,
      agency_id: selectedAgency?.id,
      ai_group_name: aiInitiationData.aiGroup.name,
      ai_group_id: aiInitiationData.aiGroup.id,
      cohort_details: selectedCohorts,
      ad_account_id: aiInitiationData?.adAccount?.id,
      ad_account_name: aiInitiationData?.adAccount?.name,
    });
  };

  const handleApplyCallback = (link,data) => {
    console.log("LL: handleApplyCallback -> link", link);
    dispatch(setKeywordTuningData(link));
    dispatch(setKeywordTuningJson(data));
    setSelectedCohort(-1);
  };

  const facebookTargetingEnumMappings = useFacebookTargetingEnumMapping();

  return (
    <>
      <div
        className="flex p-2 rounded-lg audience-container flex-column"
        style={{ height: "100%", width: "25rem" }}
      >
        <div className="flex justify-between w-100 align-items-center">
          <span
            className="p-1"
            style={{
              fontWeight: "700",
              fontSize: "1.25rem",
              fontFamily: "Lato",
            }}
          >
            Audiences
          </span>
          <div
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            {calibrationData?.TypeOfCohort !== "ICP" && (
              <Tooltip
                title={disableMerge.text}
                arrow
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      padding: "8px 10px",
                      border: "1px solid rgba(239, 239, 239, 1)",
                      borderRadius: "8px",
                      color: "#5C6674",
                      bgcolor: "#FFFFFF",
                      "& .MuiTooltip-arrow": {
                        "&::before": {
                          border: "1px solid rgba(239, 239, 239, 1)",
                          color: "white",
                        },
                      },
                    },
                  },
                }}
              >
                <button
                  onClick={
                    !disableMerge.disable
                      ? () => setShowMergeModal(true)
                      : undefined
                  }
                  style={{
                    width: "80px",
                    height: "34px",
                    borderRadius: "6px",
                    border: "0px",
                    background: `${
                      disableMerge.disable ? "#EBF3FF" : "#0869FB"
                    }`,
                    color: `${disableMerge.disable ? "#9CC3FE" : "#FFFFFF"}`,
                    cursor: disableMerge.disable ? "not-allowed" : "pointer",
                  }}
                >
                  Merge
                </button>
              </Tooltip>
            )}
          </div>
        </div>
        <div
          style={{
            height: "83%",
            overflow: "auto",
            border:
              shownClusterData.length !== 0 ? "1px solid #EFEFEF" : undefined,
            boxSizing: "0px 0px 6px rgba(0, 0, 0, 0.05)",
            borderRadius: "0.5rem",
            padding: "0.5rem",
            margin: "5px",
          }}
        >
          {shownClusterData.length === 0 && (
            <Box className="d-flex align-items-center justify-content-center h-100 w-100">
              <CircularProgress />
            </Box>
          )}
          {shownClusterData.map((cohortData, index) => {
            const similarCohort = cohortData.similar_cohorts?.[0];
            return (
              <>
                {calibrationData?.TypeOfCohort === "ICP" ? (
                  <div
                    className="cohort-details"
                    key={cohortData.cohort_id}
                    id={cohortData.cohort_id}
                    onClick={() => {
                      if (data.focusedCohort === cohortData.cohort_id) {
                        data.setFocusedCohort(
                          null,
                          "cohortNode",
                          setNodes,
                          selectedBrand,
                          selectedAgency,
                          cohortType
                        );
                      }

                      data.setFocusedCohort(
                        data.focusedCohort === cohortData.cohort_id
                          ? null
                          : cohortData.cohort_id,
                        "threeDchart",
                        setNodes,
                        selectedBrand,
                        selectedAgency,
                        cohortType
                      );
                      mixPanelInputCommonFunction(
                        "TAI List Cohort Clicked",
                        selectedBrand?.name,
                        selectedAgency?.name,
                        {
                          brand_id: selectedBrand?.id,
                          agency_id: selectedBrand?.agencyId,
                          tai_flow: cohortType,
                        }
                      );
                    }}
                    style={{
                      boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.05)",
                      border:
                        data.focusedCohort &&
                        data.focusedCohort === cohortData.cohort_id
                          ? "1px solid #3F64FA"
                          : "1px solid #EFEFEF",
                      borderRadius: "12px",
                      border: "1px solid #ECEEF1",
                    }}
                  >
                    <ICPAudienceCohort
                      cohortData={cohortData}
                      handleFavAPI={handleFavAPI}
                      isFav={isFav}
                      cohortIndex={index}
                      handleUpdateCohort={handleUpdateCohort}
                      handleOnChange={handleOnChange}
                      checkedState={checkedState}
                      facebookTargetingEnumMappings={
                        facebookTargetingEnumMappings
                      }
                    />
                  </div>
                ) : (
                  <div
                    className="cohort-details"
                    key={cohortData.cohort_id}
                    id={cohortData.cohort_id}
                    onClick={() => {
                      if (data.focusedCohort === cohortData.cohort_id) {
                        data.setFocusedCohort(
                          null,
                          "cohortNode",
                          setNodes,
                          selectedBrand,
                          selectedAgency,
                          cohortType
                        );
                      }

                      data.setFocusedCohort(
                        data.focusedCohort === cohortData.cohort_id
                          ? null
                          : cohortData.cohort_id,
                        "threeDchart",
                        setNodes,
                        selectedBrand,
                        selectedAgency,
                        cohortType
                      );
                      mixPanelInputCommonFunction(
                        "TAI List Cohort Clicked",
                        selectedBrand?.name,
                        selectedAgency?.name,
                        {
                          brand_id: selectedBrand?.id,
                          agency_id: selectedBrand?.agencyId,
                          tai_flow: cohortType,
                        }
                      );
                    }}
                    style={{
                      boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.05)",
                      border:
                        data.focusedCohort &&
                        data.focusedCohort === cohortData.cohort_id
                          ? "1px solid #3F64FA"
                          : "1px solid #EFEFEF",
                      borderRadius: "6px",
                    }}
                  >
                    <div className="cohort w-100">
                      <div className="p-2 d-flex justify-content-between w-100">
                        <div className="id-name">
                          <div style={{ display: "flex", gap: "8px" }}>
                            <span
                              style={{
                                color: "#0869FB",
                                fontSize: "1rem",
                                fontWeight: "600",
                                fontFamily: "Inter",
                              }}
                            >
                              {`Cohort C${cohortData.cohort_id}`} &nbsp;
                            </span>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CohortType type={cohortData.clus_type} />
                            </div>
                            {cohortData.edited_targetings_flag === true && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#82838F",
                                  fontSize: "0.75rem",
                                  fontWeight: "400",
                                  fontFamily: "Inter",
                                  fontStyle: "italic",
                                }}
                              >
                                (Edited)
                              </div>
                            )}
                          </div>
                          <p
                            style={{
                              color: "#666666",
                              fontStyle: "italic",
                              fontSize: "1rem",
                              fontWeight: "600",
                              fontFamily: "Inter",
                              marginBottom: "0px",
                            }}
                          >
                            {cohortData.cohort_name}
                          </p>
                        </div>
                        <div className="d-flex align-items-start">
                          <Checkbox
                            disableRipple
                            sx={{
                              padding: 0,
                              paddingRight: 0.5,
                            }}
                            icon={
                              <StarBorderIcon
                                fontSize="small"
                                sx={{
                                  color: "#0869FB",
                                }}
                              />
                            }
                            checkedIcon={
                              <StarIcon
                                fontSize="small"
                                sx={{
                                  color: "#0869FB",
                                }}
                              />
                            }
                            // checked={isFav.includes(index)}
                            // onChange={() => handleFavAPI(index, cohortData)}
                            checked={isFav.includes(cohortData.cohort_name)}
                            onChange={() => {
                              handleFavAPI(
                                cohortData.cohort_id,
                                cohortData.cohort_name,
                                cohortData
                              );
                              // Can be used for saving cohort event saving
                              // mixPanelAdEvent("keyword saved in Playground", {
                              //   brand_name: selectedBrand?.name,
                              //   brand_type: selectedBrand?.brandType,
                              //   agency_name: selectedBrand?.agency_name,
                              //   ai_group_name: aiInitiationData.aiGroup.name,
                              //   ai_group_id: aiInitiationData.aiGroup.id,
                              //   // cohort_details: data,
                              //   ad_account_id: aiInitiationData?.adAccount?.id,
                              //   ad_account_name:
                              //     aiInitiationData?.adAccount?.name,
                              // });
                            }}
                          />
                          <input
                            style={{ marginTop: "4px" }}
                            type="checkbox"
                            id="cohort"
                            name={cohortData.cohort_name}
                            value={cohortData.cohort_name}
                            checked={checkedState[index]}
                            onChange={() => handleOnChange(index)}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="p-2 d-flex w-100 justify-content-evenly"
                      style={{ borderBottom: "1px solid #efefef" }}
                    >
                      <div className="py-1 break-words d-flex flex-column align-items-center">
                        <span
                          className="font-weight-bold"
                          style={{ fontSize: "1rem" }}
                        >
                          {cohortData.audience_size}
                        </span>
                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.688rem",
                            textAlign: "center",
                            color: "#7D7D7D",
                          }}
                        >
                          Potential Audience size
                        </span>
                      </div>
                      <div className="hstack border-end"></div>
                      <div className="py-1 break-words d-flex flex-column align-items-center">
                        <span
                          className="font-weight-bold"
                          style={{ fontSize: "1rem" }}
                        >
                          {cohortData.relevance.toFixed(2)}
                        </span>
                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.688rem",
                            textAlign: "center",
                            color: "#7D7D7D",
                          }}
                        >
                          Relevance Score
                        </span>
                      </div>
                    </div>
                    <div className="interests-container d-flex">
                      <div
                        className="d-flex flex-column w-100"
                        style={{
                          backgroundColor: "#ffffff",
                          borderRadius: "0 0 8px 8px",
                        }}
                      >
                        <div className="px-2 pt-1">
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "600",
                              fontSize: "0.75rem",
                            }}
                          >
                            Interests
                          </span>
                        </div>
                        <div className="pb-1 tags d-flex justify-content-between align-items-center">
                          <div
                            className="w-3/4 px-1 overflow-x-auto d-flex"
                            style={{ height: "fit-content", maxWidth: "75%" }}
                          >
                            {cohortData.targetings !== "" &&
                              cohortData?.facebook_json
                                ?.slice(0, 3)
                                ?.map((interest) => (
                                  <span className="mx-1 text-center cohort-keys">
                                    {interest.name}
                                  </span>
                                ))}
                          </div>
                          {cohortData.targetings !== "" && (
                            <div className="w-1/4 pt-6 text-center view-more-section">
                              <button
                                className="text-blue-600 text-xs mx-0.5 px-auto btn info text-primary m-0 p-0"
                                style={{ color: "#0869FB" }}
                                onClick={() => onViewMoreClick(index)}
                              >
                                View More
                              </button>
                            </div>
                          )}
                        </div>
                        {similarCohort && (
                          <Grid
                            className="d-flex justify-column-center align-items-center"
                            sx={{
                              py: 1,
                              backgroundColor: "#EEF6F6",
                              borderBottomLeftRadius: "0.375rem",
                              borderBottomRightRadius: "0.375rem",
                            }}
                            container
                          >
                            <Grid
                              item
                              xs={1}
                              pl={0.75}
                              className="d-flex justify-column-center align-items-center"
                            >
                              <InfoIcon />
                            </Grid>
                            <Grid item xs={11}>
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  fontFamily: "Inter",
                                }}
                              >
                                We recommend you to merge with{" "}
                                <Button
                                  variant="text"
                                  onClick={
                                    !!shownNameIdMapping[
                                      similarCohort.cohort_name
                                    ]
                                      ? (e) =>
                                          scrollToRecomendedCohort(
                                            shownNameIdMapping[
                                              similarCohort.cohort_name
                                            ],
                                            e
                                          )
                                      : undefined
                                  }
                                  sx={{
                                    p: 0,
                                    width: 0,
                                    minWidth: "fit-content",
                                    fontFamily: "Inter",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    ...(!!shownNameIdMapping[
                                      similarCohort.cohort_name
                                    ]
                                      ? {
                                          color: "#0869FB !important",
                                          textDecoration:
                                            "underline !important",
                                        }
                                      : {
                                          color: "#000 !important",
                                        }),
                                    "&:hover": {
                                      ...(!!shownNameIdMapping[
                                        similarCohort.cohort_name
                                      ]
                                        ? {
                                            color: "#0869FB",
                                            textDecoration:
                                              "underline !important",
                                          }
                                        : {
                                            color: "#000 !important",
                                          }),
                                    },
                                  }}
                                  size="small"
                                  disableRipple
                                >
                                  C{similarCohort.cohort_id}
                                </Button>{" "}
                                for better results
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
          })}
          {shownClusterData.length > 0 && (
            <div>
              <button
                className="btn w-100"
                style={{
                  background: "#F8FAFD",
                  color: "#0869FB",
                  fontWeight: "600",
                  fontFamily: "Inter",
                }}
                onClick={paginateCluster}
                hidden={shownClusterData.length >= clusterData.length}
              >
                Generate More
              </button>
            </div>
          )}
        </div>
        <div
          className="d-flex justify-content-end align-items-center w-100"
          style={{
            backgroundColor: "#FFFFFF",
            padding: "1rem 1rem 0 1rem",
            bottom: 0,
            position: "sticky",
            height: "3.5rem",
            gap: "0.7rem",
          }}
        >
          {calibrationData.TypeOfCohort === "ICP" && (
            <span
              style={{
                color: "#AEB6C4",
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.12px",
              }}
            >
              Selected:{" "}
              {shownClusterData?.length ? selectedCohorts?.length ?? "-" : "-"}/
              {shownClusterData?.length || "-"}
            </span>
          )}
          <button
            className="text-white btn primary"
            style={{
              padding: "0 !important",
              width: calibrationData.TypeOfCohort === "ICP" ? "50%" : "100%",
            }}
            onClick={handlePublish}
            disabled={selectedCohorts?.length === 0}
          >
            Review and Publish
            {calibrationData.TypeOfCohort === "ICP" ? "" : " Audiences"}
          </button>
        </div>
        {calibrationData.TypeOfCohort !== "ICP" && (
          <div style={{ marginLeft: "-40px" }}>
            <p
              style={{
                margin: "0",

                color: "#999999",
                fontSize: "12px",
              }}
            >
              <b style={{ fontWeight: 700, color: "#333" }}>*Note:&nbsp;</b>
              <span>Exclusions will be recommended in the next step</span>
            </p>
          </div>
        )}
      </div>
      {selectedCohort >= 0 && (
        <Modal
          open={selectedCohort >= 0}
          onClose={() => setSelectedCohort(-1)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: "0.5rem",
            }}
          >
            <CohortInfoModel
              index={selectedCohort}
              setShowModel={setSelectedCohort}
              shownClusterData={shownClusterData}
              setShownClusterData={setShownClusterData}
              setSelectedCohortData={data.setSelectedCohortData}
              graphData={graphData}
              handleApplyCallback={handleApplyCallback}
            />
          </Box>
        </Modal>
      )}
      {showPublishModal && (
        <PublishModal
          showPublishAudienceManagerModal={showPublishModal}
          setShowPublishAudienceManagerModal={setShowPublishModal}
          setShowAudiencePublishSuccessModal={setShowSuccessModal}
          handleSuccessResponse={() => {
            const newRef = { ...isFavRef };
            const deleteIds = [];

            selectedCohorts.forEach((cohort) => {
              if (newRef[cohort.cohort_name]) {
                delete newRef[cohort.cohort_name];
                deleteIds.push(cohort.cohort_name);
              }
            });
            setIsFav(isFav.filter((fav) => !deleteIds.includes(fav)));
            setIsFavRef(newRef);
          }}
        />
      )}
      {showSaveModal.value && !showSaveModal.shownOnce && (
        <SaveModal
          buttonClickHandler={() => {
            setShowSaveModal({ value: false, shownOnce: true });
          }}
        />
      )}
      {showSuccessModal && (
        <AudienceManagerSuccessModal {...successModalProps} />
      )}
      {showMergeModal && (
        <MergeCohortModal
          open={showMergeModal}
          setOpen={setShowMergeModal}
          onSubmit={handleMerge}
          values={selectedCohorts}
        />
      )}
    </>
  );
});
