import React, { useEffect } from "react";
import { useForm, Resolver } from "react-hook-form";
import {
  Container,
  Backdrop,
  Content,
  Header,
  Details,
  Footer,
  Scrollable,
} from "./styles.ConfigureAd";
import Input from "./Input";
import HelpGlossary from "./HelpGlossary";
import Headlines from "./Headlines";
import Descriptions from "./Descriptions";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/close.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/svg/gsai-info.svg";
import { ReactComponent as HelpIcon } from "../../../../assets/svg/gsai-help.svg";
import { useDispatch, useSelector } from "react-redux";
import ErrorMeesage from "./ErrorMeesage";
import { RolesWrapper } from "utils/rolesWrapper";
import { getRolesHierarchy, isViewerRole } from "utils/commonFunctions";
import { AdAccountState } from "store/types/adaccount";

type Props = {
  handleClose: () => void;
  showClose: boolean;
  generateData: any;
  adUnit: any;
  recommendations: any;
  dnd: any;
};

type GenerationType = {
  headlines: Record<string, boolean | string>;
  descriptions: Record<string, boolean | string>;
};

type FormValues = {
  bu_name: string;
  brand_name: string;
  bu_detail: string;
  interest_keyword: string;
  reference_headline: Record<string, string>[];
  reference_description: Record<string, string>[];
  generation_type: GenerationType;
};

const required = { type: "required", message: "This is required." };

const resolver: Resolver<FormValues> = async (values) => {
  const errors: any = {};
  if (!values.bu_name) {
    errors.bu_name = required;
  }
  if (!values.bu_detail) {
    errors.bu_detail = required;
  }
  if (!values.interest_keyword) {
    errors.interest_keyword = required;
  }
  const reference_headline: any = [];
  let valid = true;
  for (const index in values.reference_headline) {
    if (!values.reference_headline[index].value) {
      valid = false;
      reference_headline.push(required);
    } else reference_headline.push("");
  }
  if (!valid) errors.reference_headline = reference_headline;

  valid = true;
  const reference_description = [];
  for (const index in values.reference_description) {
    if (!values.reference_description[index].value) {
      valid = false;
      reference_description.push(required);
    } else reference_description.push("");
  }
  if (!valid) errors.reference_description = reference_description;

  valid = false;
  for (const key in values.generation_type.headlines) {
    if (values.generation_type.headlines[key]) {
      valid = true;
      break;
    }
  }
  if (!valid) {
    errors.generation_type = {};
    errors.generation_type.headlines = required;
  }

  valid = false;
  for (const key in values.generation_type.descriptions) {
    if (values.generation_type.descriptions[key]) {
      valid = true;
      break;
    }
  }
  if (!valid) {
    errors.generation_type = errors.generation_type || {};
    errors.generation_type.descriptions = required;
  }

  if (
    values.generation_type.headlines["benefit"] &&
    typeof values.generation_type.headlines?.benefitValue === "string" &&
    values.generation_type.headlines?.benefitValue?.length === 0
  ) {
    errors.benefit = {};
    errors.benefit.headlines = true;
  }

  if (
    values.generation_type.descriptions["benefit"] &&
    typeof values.generation_type.descriptions?.benefitValue === "string" &&
    values.generation_type.descriptions?.benefitValue?.length === 0
  ) {
    errors.benefit = errors.benefit || {};
    errors.benefit.descriptions = true;
  }

  if (
    values.generation_type.descriptions["usp"] &&
    typeof values.generation_type.descriptions?.uspValue === "string" &&
    values.generation_type.descriptions?.uspValue?.length === 0
  ) {
    errors.usp = {};
    errors.usp.descriptions = true;
  }

  return {
    values,
    errors,
  };
};

function getGenerationTypes(data: Record<string, boolean | string>) {
  const result = [];
  for (const key in data) {
    if (key === "uspValue" || key === "benefitValue") continue;
    if (data[key]) result.push(key);
  }
  return result;
}

function ConfigureAd({
  handleClose,
  showClose,
  generateData,
  adUnit,
  recommendations,
  dnd,
}: Props) {
  const adAccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adAccount;
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<FormValues>({
    resolver,
    defaultValues: {
      reference_headline: [{ value: "" }, { value: "" }, { value: "" }],
      reference_description: [{ value: "" }, { value: "" }],
      generation_type: {
        headlines: {
          generic: true,
          brand: true,
          interest: true,
          benefit: false,
        },
        descriptions: {
          generic: true,
          interest: true,
          usp: false,
          benefit: false,
        },
      },
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const bu_name = recommendations?.data?.recommended_business_units?.bu_name;
    const brand_name =
      recommendations?.data?.recommended_business_units?.brand_name;
    const bu_detail =
      recommendations?.data?.recommended_business_units?.bu_detail;
    const interest_keyword =
      recommendations?.data?.recommended_interest_keyword;
    const reference_headline = recommendations?.data?.recommended_headlines;
    const reference_description =
      recommendations?.data?.recommended_descriptions;
    const generation_type = recommendations?.data?.generation_type;
    const benefit = recommendations?.data?.benefit;
    const usp = recommendations?.data?.usp;

    if (bu_name) {
      setValue("bu_name", bu_name);
    }
    if (brand_name) {
      setValue("brand_name", brand_name);
    }
    if (bu_detail) {
      setValue("bu_detail", bu_detail);
    }
    if (interest_keyword) {
      setValue("interest_keyword", interest_keyword);
    }
    if (reference_headline?.length) {
      setValue(
        "reference_headline",
        reference_headline.map((value: string) => ({ value }))
      );
    }
    if (reference_description?.length) {
      setValue(
        "reference_description",
        reference_description.map((value: string) => ({ value }))
      );
    }
    if (
      generation_type?.headlines?.length ||
      generation_type?.descriptions?.length
    ) {
      const data: any = {
        headlines: {
          generic: false,
          brand: false,
          interest: false,
          benefit: false,
        },
        descriptions: {
          generic: false,
          interest: false,
          usp: false,
          benefit: false,
        },
      };
      for (const kw of generation_type.headlines) {
        data.headlines[kw] = true;
      }
      for (const kw of generation_type.descriptions) {
        data.descriptions[kw] = true;
      }
      if (benefit?.headlines?.length) {
        data.headlines.benefitValue = benefit.headlines;
      }
      if (benefit?.descriptions?.length) {
        data.descriptions.benefitValue = benefit.descriptions;
      }
      if (usp?.descriptions?.length) {
        data.descriptions.uspValue = usp.descriptions;
      }
      setValue("generation_type", data);
    }
  }, []);

  const onSubmit = handleSubmit((values) => {
    const payload = {
      modal: "configure-ad",
      hnd: {
        headlines: [],
        descriptions: [],
      },
      dnd: {
        headlines: {
          recommended: [],
          toBePublished: dnd?.headlines?.toBePublished || [],
          replacements: dnd?.headlines?.replacements || [],
          more: [],
        },
        descriptions: {
          recommended: [],
          toBePublished: dnd?.descriptions?.toBePublished || [],
          replacements: dnd?.descriptions?.replacements || [],
          more: [],
        },
      },
      headlinedesLoader: true,
      descriptionLoader: true,
    };
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload,
    });
    const configData = {
      generation_type: {
        headlines: getGenerationTypes(values.generation_type.headlines),
        descriptions: getGenerationTypes(values.generation_type.descriptions),
      },
      recommended_business_units: {
        bu_name: values.bu_name,
        bu_detail: values.bu_detail,
      },
      recommended_interest_keyword: values.interest_keyword,
      recommended_headlines: values.reference_headline.map(
        (item) => item.value
      ),
      recommended_descriptions: values.reference_description.map(
        (item) => item.value
      ),
      benefit: {
        headlines: values?.generation_type?.headlines?.benefitValue,
        descriptions: values?.generation_type?.descriptions?.benefitValue,
      },
      usp: {
        descriptions: values?.generation_type?.descriptions?.uspValue,
      },
    };
    generateData(configData);
  });

  return (
    <Container onSubmit={onSubmit}>
      <Backdrop />
      <Content>
        <Header>
          <div className="title">
            Configure Ad : {adUnit?.campaign?.name} <InfoIcon />
          </div>
          {showClose ? (
            <button type="button" onClick={handleClose}>
              <CloseIcon />
            </button>
          ) : null}
        </Header>
        <Scrollable>
          <Details>
            {!recommendations?.data?.recommended_business_units?.brand_name && (
              <ErrorMeesage />
            )}
            <Input
              name="bu_name"
              register={register}
              label="Display Business Unit"
              size="large"
              className="bu-name"
              placeholder="Enter brand name here"
              error={errors.bu_name}
              isEditbutton={false}
            />
            <Input
              name="brand_name"
              register={register}
              label="Display Brand Name"
              size="large"
              className="brand-name"
              placeholder="Enter brand name here"
              error={errors.bu_name}
            />
            <Input
              name="bu_detail"
              register={register}
              label="Brand Details"
              size="large"
              className="brand-details"
              placeholder="Tell us about your brand or company. What is your product or service ?"
              error={errors.bu_detail}
            />
            <Input
              name="interest_keyword"
              register={register}
              label="Target Interest Keyword"
              size="large"
              className="keyword"
              placeholder="Enter one keyword here, like Pizza"
              error={errors.interest_keyword}
            />
          </Details>
          <Headlines
            register={register}
            control={control}
            errors={errors}
            watch={watch}
          />
          <Descriptions
            register={register}
            control={control}
            errors={errors}
            watch={watch}
          />
        </Scrollable>
        <Footer>
          <div className="help">
            <HelpIcon />
            Help
            <HelpGlossary />
          </div>
          {/* <RolesWrapper show={true} disabled={isViewerRole(brandRoles)}> */}
          <RolesWrapper
            show={true}
            disabled={getRolesHierarchy(brandRoles) === "viewer"}
          >
            <button type="submit">Generate</button>
          </RolesWrapper>
        </Footer>
      </Content>
    </Container>
  );
}

export default ConfigureAd;
