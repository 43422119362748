import {
  StyledOutlinedButton,
  StyledSolidButton,
  StyleTextButton,
  UnderLineTextButton,
} from "./styled";
import { ButtonProps as MUIButtonProps } from "@mui/material";

interface ButtonProps extends MUIButtonProps {
  buttonVariant?: "contained" | "outlined" | "text" | "underline"; // used because I have added another variant
  onClick?: () => void;
}

export function Button({
  children,
  onClick,
  buttonVariant = "text",
  ...props
}: ButtonProps) {
  const renderButton = () => {
    switch (buttonVariant) {
      case "contained":
        return (
          <StyledSolidButton
            onClick={onClick}
            variant={buttonVariant}
            {...props}
          >
            {children}
          </StyledSolidButton>
        );
      case "outlined":
        return (
          <StyledOutlinedButton
            onClick={onClick}
            variant={buttonVariant}
            {...props}
          >
            {children}
          </StyledOutlinedButton>
        );

      case "text":
        return (
          <StyleTextButton onClick={onClick} variant={buttonVariant} {...props}>
            {children}
          </StyleTextButton>
        );

      case "underline":
        return (
          <UnderLineTextButton onClick={onClick}>
            {children}
          </UnderLineTextButton>
        );
      default:
        break;
    }
  };

  return <>{renderButton()}</>;
}
