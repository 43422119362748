import {
  GetAdsetById,
  GET_ADSET_BY_ID,
  TriggerContactUs,
  TRIGGER_CONTACT_US,
} from "store/types/CreativeAI_Revamp/facebookTypes";

export const getAdsetById = (payload: any, callback?: any): GetAdsetById => {
  return {
    type: GET_ADSET_BY_ID,
    payload,
    callback,
  };
};
export const triggerContactUs = (
  payload: any,
  callback?: any
): TriggerContactUs => {
  return {
    type: TRIGGER_CONTACT_US,
    payload,
    callback,
  };
};
