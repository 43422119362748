import ButtonUI from "../../../ui/ButtonUI/ButtonUI";
import { ReactComponent as Error } from "./svgs/Error_Grey.svg"

export default function HandleGSAIErrorWithRetry({errorMsg,onClick}:{errorMsg:string, onClick:(value:number)=>void}) {
    return (
      <div className="handle-gsai-error">
          <Error
            style={{
              height: 33,
              width: 33,
              marginTop:20,
            }}
          />
          <h6>Error while generating {errorMsg}</h6>
          <ButtonUI onClick={onClick}> Tap to retry</ButtonUI>
      </div>
    )
  }
  