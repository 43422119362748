import { ReactComponent as PixisLogoImage } from "assets/svg/pixis.svg";
import { ReactComponent as FileImage } from "assets/svg/fileicon.svg";
import { useSearchParams } from "react-router-dom-v5-compat";

type Props = {
  state: Record<string, string>;
  email: string;
};

function NoAdaccount({ state, email }: Props) {
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  return (
    <>
      <div className="tms-no-adaccount">
        <PixisLogoImage className="ml-4 mt-2" />
        <div className="text-center my-5">
          <h1 className="mb-4">{state.brandName}</h1>
          <FileImage className="mb-4" />
          <div className="text-medium font-600 mb-5">No Accounts Found</div>
          <div className="text-medium width-80">
            There are no ad accounts associated with{" "}
            <span className="tms-no-adaccount-email">{email}</span>. Please try
            a different login.
          </div>
        </div>
        <div className="pt-5 text-right">
          <button
            type="button"
            className="success-buttons primary-reverse"
            onClick={() => {
              if (isViewedFromBeta) {
                const queryString = window.location.search;
                const params = new URLSearchParams(queryString);
                const brandId = params.get('brand_id');
                window.parent.postMessage(
                  {
                    type: 'REDIRECT',
                    url: `/aia-single-platform?brandId=${brandId}`
                  },
                  '*'
                );
              }
              else
              window.location.href = "/singlePlatform" + window.location.search;
            }}
          >
            Close Tab
          </button>
          <button
            type="button"
            className="success-buttons primary"
            onClick={() => {
              if (isViewedFromBeta) {
                window.parent.postMessage(
                  {
                    type: 'REDIRECT',
                    url: `/social-login?brandId=${state.brand_id}&source=${state.source}`
                  },
                  '*'
                );
              }
              else
              window.location.href = `/social-login?brand_id=${state.brand_id}&source=${state.source}`;
            }}
          >
            Use Another Account
          </button>
        </div>
      </div>
    </>
  );
}

export default NoAdaccount;
