import { groups, platform } from "../adaccount";
export const SET_AI_GROUP = "SET_AI_GROUP";
export const SET_AI_GROUP_LOADING = "SET_AI_GROUP_LOADING";
export const SET_SELECTED_AD_ACCOUNT = "SET_SELECTED_AD_ACCOUNT";
export const SET_AD_ACCOUNT_LOADING = "SET_AD_ACCOUNT_ID_LOADING";
export const SET_ACTIVE_PLATFORM_DEBUGGER = "SET_ACTIVE_PLATFORM_DEBUGGER";

export const SET_AD_SET = "SET_AD_SET";
export const SET_AD_SET_LOADING = "SET_AD_SET_LOADING";
export const SET_SELECTED_CAMPAIGN_DEBUGGER = "SET_SELECTED_CAMPAIGN_DEBUGGER";
export const SET_SELECTED_CAMPAIGN_DEBUGGER_LOADING =
  "SET_SELECTED_CAMPAIGN_DEBUGGER_LOADING";

export interface ActionDebuggerHelperInitState {
  aiGroupLoading: boolean;
  selectedAIGroup?: groups;
  adAccountLoading: boolean;
  selectedAdAccount?: any;
  activePlatform?: platform;
  selectedCampaign?: any;
  selectedCampaignLoading: boolean;
  selectedAdSet: [];
  adSetLoading: boolean;
}

export type SetAIGroupsLoading = {
  type: typeof SET_AI_GROUP_LOADING;
  payload: boolean;
};

export type SetAIGroups = {
  type: typeof SET_AI_GROUP;
  payload: groups;
};

export type SetSelectedAdAccountLoading = {
  type: typeof SET_AD_ACCOUNT_LOADING;
  payload: boolean;
};

export type SetSelectedAdAccount = {
  type: typeof SET_SELECTED_AD_ACCOUNT;
  payload: any;
};

export type SetActivePlatformDebugger = {
  type: typeof SET_ACTIVE_PLATFORM_DEBUGGER;
  payload: platform;
};

export type SetAdSet = {
  type: typeof SET_AD_SET;
  payload: any;
};

export type SetAdSetLoading = {
  type: typeof SET_AD_SET_LOADING;
  payload: boolean;
};

export type SetSelectedCampaignDebugger = {
  type: typeof SET_SELECTED_CAMPAIGN_DEBUGGER;
  payload: any;
};

export type SetCampaignDebuggerLoading = {
  type: typeof SET_SELECTED_CAMPAIGN_DEBUGGER_LOADING;
  payload: boolean;
};
