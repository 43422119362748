import { InputLabelProps as MUIInputLabelProps } from "@mui/material";
import Tooltip from "../../../ui/Tooltip/Tooltip";
import { StyledLabel, StyledLabelContainer } from "./InputLabel.styled";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";

export interface InputLabelProps extends MUIInputLabelProps {
  label?: string;
  tooltipText?: string;
  textColor?: string;
}

export default function InputLabel({
  tooltipText,
  label,
  ...props
}: InputLabelProps) {
  return (
    <StyledLabelContainer>
      <StyledLabel {...props}>{label} </StyledLabel>
      {tooltipText && tooltipText?.length > 0 && (
        <Tooltip
          tooltipContainer={
            <Info
              style={{
                height: 14,
                width: 16,
                marginLeft: 4,
                marginTop: -10,
              }}
            />
          }
        >
          {tooltipText}
        </Tooltip>
      )}
    </StyledLabelContainer>
  );
}
