import React from "react";
import { useSelector } from "react-redux";
import ActionImpactHeader from "../../components/ActionImpactHeader/ActionImpactHeader";
import AllActionImpactTable from "../../components/AllActionImpactTable/AllActionImpactTable";
import AllPlatformGraph from "../../components/AllPlatformGraph/AllPlatformGraph";
import Loader from "../../components/common/Loader";
import { setAllPlatformLinkClicks } from "../../store/actions/actionImpact";
import { ActionImpactState } from "../../store/types/actionImpact";
import "./AllPlatform.scss";

const AllPlatform: React.FC<unknown> = () => {
  const actionImpact = useSelector(
    (state: { actionImpact: ActionImpactState }) => state.actionImpact
  );
  const { allPlatformLinkClicks, allPlatformGraphSpinner, pieSpinner } =
    actionImpact;

  return (
    <>
      <div
        className="performance_analysis"
        style={{ paddingBottom: "20px", minHeight: "200px" }}
      >
        <ActionImpactHeader
          title="Cross Platform Spends Analysis"
          localStorageKey="all-platform-graph-date"
          linkClicks={allPlatformLinkClicks}
          setLinkClicks={setAllPlatformLinkClicks}
        />
        {(allPlatformGraphSpinner || pieSpinner) && (
          <div className="text-center">
            <Loader />
          </div>
        )}
        <AllPlatformGraph />
      </div>
      <AllActionImpactTable />
    </>
  );
};

export default AllPlatform;
