import { StyledMediaCard, StyledMediaCardContent, StyledMediaStudioHeader, StyledMediaStudioImage, StyledMediaStudioSubTitle } from "./styled";
import { ReactComponent as WelcomeMediaStudioImage } from "../../../../../../../src/assets/svg/celebrate.svg"

const RequestMediaStudio = (props: any) => {


  return (
    <StyledMediaCard>
      <StyledMediaCardContent>
        <WelcomeMediaStudioImage style={{alignSelf:"center"}}/>
        <StyledMediaStudioHeader>Media Studio Requested</StyledMediaStudioHeader>
        <StyledMediaStudioSubTitle>You have already requested Media Studio for your ad. 
Your request is being processed by our team and you 
will be onboarded shortly
</StyledMediaStudioSubTitle>
      </StyledMediaCardContent>
    </StyledMediaCard>

  );
};

export default RequestMediaStudio;
