import HttpService from "../../../HttpService/HttpService";
import {
  GetAllBrandDetails,
  GetBrandMetrics,
  GET_ALL_BRAND_DETAILS,
  GET_BRAND_METRICS,
} from "../../types/SmbDashboard/brand";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { CROSS_PLATFORM_URL } from "../../../utils/path";
import { smbDashboardApi } from "MappedAPI/MappedAPI";
import {
  SetEditBrandDetails,
  SET_EDIT_BRAND_DETAILS,
} from "../../types/SmbDashboard/brand";
import {
  GetEditBrandDetails,
  GET_EDIT_BRAND_DETAILS,
} from "../../types/SmbDashboard/brand";
import {
  resetBrandDetails,
  setAllBrandsDetails,
  setBrandMetrics,
  setBrandType,
  setTotalBrandsLoading,
} from "../../actions/SmbDashboard/brand";
import {
  setErrorForAllBrandDetails,
  setErrorForBrandMetrics,
} from "../../actions/SmbDashboard/error";
import { getStatus } from "Pages/SmbDashboard/utils";

function* fetchBrandMetrics(urlParams: any): any {
  try {
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    const metricsPayload = {
      brandId: response.data.data.brandId,
      metrics: response.data.data,
    };
    const brandStatus: string =
      metricsPayload.metrics &&
      getStatus(
        metricsPayload.metrics.spends.deviation,
        metricsPayload.metrics.metrics[0].deviation
      );
    yield put(setBrandMetrics({ ...metricsPayload, type: brandStatus }));
    yield put(setBrandType({ brandId: metricsPayload.brandId, brandStatus }));
  } catch (error) {
    yield put(
      setErrorForBrandMetrics({
        type: GET_BRAND_METRICS,
        message: error,
      })
    );
  }
}

function* getBrandMetrics(action: GetBrandMetrics): any {
  let {
    payload: { brandIds, selectedMonth, selectedYear },
  } = action;
  for (let index = 0; index < brandIds.length; index++) {
    const brandId = brandIds[index];
    const urlParams = `${smbDashboardApi}/brands/${brandId}/dashboard/metrics-data?month=${selectedMonth}&year=${selectedYear}`;
    yield fork(fetchBrandMetrics, urlParams);
  }
}

function* getAllBrandDetails(action: GetAllBrandDetails): any {
  let {
    payload: { agencyId,adAccountSummaryScreen=false },
  } = action;
  try {
    yield put(resetBrandDetails());
    const urlParams = `${smbDashboardApi}/agencies/${agencyId}/dashboard-summary`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      const brandsPayload =
        response.data.data.length > 0
          ? response.data.data.reduce((prev: any, brandData: any) => {
              prev[brandData.id] = {
                details: brandData,
                metrics: { isLoading: true },
              };
              return prev;
            }, {})
          : {};
      yield put(setAllBrandsDetails(brandsPayload));
      yield put(setTotalBrandsLoading(false));
      const state = yield select();
      const brandState = state.smb.brand;
      const commonState = state.smb.common;
      if(!adAccountSummaryScreen){
        yield call(getBrandMetrics, {
          payload: {
            brandIds: Object.keys(brandState.data),
            selectedMonth: commonState.calendar.selectedMonth,
            selectedYear: commonState.calendar.selectedYear,
          },
          type: GET_BRAND_METRICS,
        });
      }
    } else {
      yield put(
        setErrorForAllBrandDetails({
          type: GET_ALL_BRAND_DETAILS,
          message: response.data.message || "Something went wrong",
        })
      );
    }
  } catch (error: any) {
    yield put(
      setErrorForAllBrandDetails({
        type: GET_ALL_BRAND_DETAILS,
        message: error,
      })
    );
  }
}

function* getEditBrandDetails(action: GetEditBrandDetails): any {
  let {
    payload: { brandId },
    callback,
  } = action;
  try {
    const urlParams = `${smbDashboardApi}/brands/${brandId}/dashboard/result-metrics`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    callback && callback(error.response, true);
  }
}

function* setEditBrandDetails(action: SetEditBrandDetails): any {
  let {
    payload: { brandId, data },
    callback,
  } = action;
  try {
    const urlParams = `${smbDashboardApi}/brands/${brandId}/dashboard/result-metrics`;
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      null,
      data
    );

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    callback && callback(error.response, true);
  }
}

export function* BrandsWatcher() {
  yield takeLatest(GET_ALL_BRAND_DETAILS, getAllBrandDetails);
  yield takeLatest(GET_BRAND_METRICS, getBrandMetrics);
  yield takeLatest(GET_EDIT_BRAND_DETAILS, getEditBrandDetails);
  yield takeLatest(SET_EDIT_BRAND_DETAILS, setEditBrandDetails);
}
