import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { StyledText } from "components/shared";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import {
  getFacebookImageUrlForAssetFeedSpec,
  getFacebookVideoUrl,
} from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

export interface InfoDialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface InfoDialogProps {
  variantInfo: VariantInfoProps[];
  videoId?: string;
  imageHash?: string;
  adType: "image" | "video";
}

export interface VariantInfoProps {
  keyText: string;
  value: string;
}

function InfoDialogTitle(props: InfoDialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function InfoDialog({
  variantInfo,
  imageHash,
  adType,
  videoId,
}: InfoDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [isMediaLoading, setIsMediaLoading] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const [isImageLoading, setIsImageLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { adAccountsSelection } = useSelector(
    (state: { fbActivityLogReducer: any }) => state.fbActivityLogReducer
  );

  const fetchMedia = () => {
    if (adType === "image" && open) {
      if (imageHash) {
        setIsMediaLoading(true);
        dispatch(
          getFacebookImageUrlForAssetFeedSpec({
            adAccountId: adAccountsSelection?.selectedAdAccountId,
            imageHash,
            callback: (response: any, status: boolean) => {
              setMediaUrl(response?.[0]?.permalink_url);
              setIsMediaLoading(false);
              setIsImageLoading(true);
            },
          })
        );
      }
    } else if (open) {
      if (videoId) {
        setIsMediaLoading(true);
        dispatch(
          getFacebookVideoUrl({
            adId: videoId, /// actually video id
            callback: (response: any, status: boolean) => {
              setMediaUrl(response?.source);
              setIsMediaLoading(false);
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchMedia();
  }, [open]);

  return (
    <div
      style={{
        marginLeft: "5px",
      }}
    >
      <IconButton onClick={handleClickOpen} size="small">
        <InfoIcon
          fontSize="small"
          sx={{
            height: "18px",
            width: "18px",
          }}
        />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <InfoDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Variant Info data
        </InfoDialogTitle>
        <DialogContent dividers>
          {variantInfo.map((item) => {
            return item.value !== null && <SingleTextComponent {...item} />;
          })}

          {isMediaLoading ? (
            <Box
              height="200px"
              width="100%"
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <CircularProgress />
            </Box>
          ) : (
            (imageHash || videoId) && (
              <Box position="relative">
                {adType === "image" ? (
                  <img
                    height="auto"
                    width="100%"
                    // onClick={() => showModal(true)}
                    //   style={{ cursor: "zoom-in" }}
                    src={mediaUrl}
                    alt=""
                    onLoad={() => {
                      setIsImageLoading(false);
                    }}
                  />
                ) : (
                  <div>
                    <video
                      key={mediaUrl}
                      controls
                      style={{ width: "100%" }}
                      id="video"
                      disablePictureInPicture
                      controlsList="nodownload noplaybackrate"
                      onLoadedData={() => {}}
                    >
                      <source src={mediaUrl} type="video/mp4"></source>
                    </video>
                  </div>
                )}
                {isImageLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                    }}
                    height="200px"
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            )
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

const SingleTextComponent = ({ keyText, value }: VariantInfoProps) => {
  return (
    <Box display="flex" alignItems="start" pb="10px">
      <StyledText fontWeight={600} fontSize="16px" mr="8px">
        {keyText}:
      </StyledText>
      <Box flex={1}>
        <StyledText
          fontSize="14px"
          fontWeight={400}
          sx={{
            wordBreak: "break-all",
          }}
        >
          {value?.length ? value : "-"}
        </StyledText>
      </Box>
    </Box>
  );
};
