import styled from "styled-components";

export const StyledNoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;
`;

export const StyledHeadMessageContainer = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4em;
  line-height: 1.8em;
  text-align: center;
  opacity: 0.5;
`;
export const StyledMainMessageContainer = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4em;
  line-height: 1.8em;
  text-align: center;
  width: 30em;
  opacity: 0.5;
`;
