import { Box } from "@mui/material";
import { getMediaStudioFormValues } from "components/CreativeAI/Facebook/utils/form.utils";
import { InputTagFormControl } from "components/shared/Input/InputWithTags/InputTagFormControl";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  MediaStudioAdminState,
  ON_SAVE_UPDATE,
} from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import {
  Button,
  InputFormControl,
  Loader,
  SelectFormControl,
} from "../../../shared";
import CustomizedDialogs from "../../../shared/Modal/CustomizedDialogs";
import FrameForm from "./LayerForm/FrameForm/FrameForm";
import { StyledLabelText, StyledLoaderContainer } from "./styled";
import { ReactComponent as AddIcon } from "../../../../assets/svg/add_icon.svg";
import { UploadInputFormControl } from "components/shared/Input/UploadInput/UploadInput";
import { nanoid } from "nanoid";
import {
  createMediaStudio,
  getMediaList,
  updateMediaStudio,
} from "store/actions/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import { AdAccountState } from "store/types/adaccount";
import { initialState } from "store/types/auth";
import {
  BlenderInfoPayloadType,
  MediaStudioTestReducerInitialState,
  VariationType,
} from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";
import { layersVariationMapping } from "./utils/common.utils";
import {
  setErrorPresignedUrl,
  testMediaStudioPost,
  testMediaStudioPostResponse,
} from "store/actions/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";
import MediaStudioTesting from "./MediaStudioTesting";
import MediaStudioTestingButtons from "./MediaStudioTestingButtons";
import MediaStudioFormButtons from "./MediaStudioFormButtons";

interface MediaStudioModalProps {
  showMediaStudioModal: boolean;
  onClose: () => void;
  isEdit: boolean;
  selectedMediaStudio: string;
  setSelectedMediaStudio: (id: string) => void;
  fetchingMediaData: boolean;
  addingMediaStudio: boolean;
}

const MediaStudioModal = ({
  showMediaStudioModal,
  onClose,
  isEdit,
  selectedMediaStudio,
  fetchingMediaData,
  addingMediaStudio,
}: MediaStudioModalProps) => {
  const {
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    unregister,
    getValues,
    setError,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      frames: [
        {
          layers: [
            {
              frontEndId: nanoid(),
            },
          ],
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "frames",
  });
  const [isRunTest, setIsRunTest] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { mediaData, isMediaUploading } = useSelector(
    (state: { mediaStudioAdminReducer: MediaStudioAdminState }) =>
      state.mediaStudioAdminReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const { runTestPostResponse, previewError } = useSelector(
    (state: { mediaStudioTestReducer: MediaStudioTestReducerInitialState }) =>
      state.mediaStudioTestReducer
  );
  const { selectedBrand } = adaccount;
  const auth = useSelector((state: { auth: initialState }) => state.auth);

  useEffect(() => {
    dispatch(setErrorPresignedUrl(false));
    dispatch(
      testMediaStudioPostResponse({
        data: [],
        isFailed: false,
        isLoading: true,
      })
    );
  }, [isRunTest]);

  useEffect(() => {
    if (isEdit) {
      reset(getMediaStudioFormValues(mediaData));
    } else {
      reset({
        adIds: [],
        tag: "",
        usageType: "IMAGE_TEXT",
        frames: [
          {
            layers: [
              {
                frontEndId: nanoid(),
              },
            ],
          },
        ],
      });
    }
    watch();
  }, [mediaData, isEdit]);

  const handleClose = () => {
    reset();
    reset({
      adIds: [],
      tag: "",
      usageType: "IMAGE_TEXT",
      frames: [
        {
          blenderFile: { name: "", value: null },
          previewFile: { name: "", value: null },
          layers: [
            {
              frontEndId: nanoid(),
              is_sentence_related: "Unrelated",
              maxCharacters: "",
              minCharacters: "",
              name: "",
              originalText: "",
            },
          ],
        },
      ],
    });

    onClose();
  };

  const onRunTestSubmit = (data: any) => {
    const finalPayload: BlenderInfoPayloadType[] = [];
    let finalVideoPayload: VariationType[] = []; /// it will be utilize when we have usagetype as VIDEO_TEXT
    for (const frame of data.frames) {
      const variations = layersVariationMapping(frame.layers);
      if (data.usageType === "VIDEO_TEXT") {
        finalVideoPayload = [...finalVideoPayload, ...variations];
      }
      finalPayload.push({
        blenderFile: frame.blenderFile.value,
        usageType: "IMAGE",
        variations,
      });
    }

    if (data.usageType === "VIDEO_TEXT") {
      finalPayload.push({
        blenderFile: data.videoBlenderFile.value,
        usageType: "VIDEO",
        variations: finalVideoPayload,
      });
    }
    dispatch(testMediaStudioPost(finalPayload));
    setIsRunTest(true);
  };
  const onSubmit = (data: any) => {
    if (data.usageType == "IMAGE_TEXT") {
      delete data["videoBlenderFile"];
    }
    if (!isEdit) {
      dispatch({ type: ON_SAVE_UPDATE, payload: true });
      dispatch(
        createMediaStudio({
          brandId: selectedBrand?.id,
          user: auth.user,
          payload: data,
          finalAction: (response: any, error: boolean) => {
            if (!error) {
              handleClose();
              dispatch(getMediaList({ brandId: selectedBrand?.id }));
            } else {
              response.data.errors.forEach((error: any) => {
                if (error?.field.includes("File")) {
                  error.field = error.field + "name";
                }
                setError(error?.field, {
                  type: "apiFailure",
                  message: error?.message,
                });
              });
            }
          },
        })
      );
    } else {
      dispatch({ type: ON_SAVE_UPDATE, payload: true });
      dispatch(
        updateMediaStudio({
          mediaId: selectedMediaStudio,
          brandId: selectedBrand?.id,
          user: auth.user,
          payload: data,
          finalAction: (response: any, error: boolean) => {
            if (!error) {
              handleClose();
              dispatch(getMediaList({ brandId: selectedBrand?.id }));
            } else {
              response.data.errors.forEach((error: any) => {
                if (error?.field.includes("File")) {
                  error.field = error.field + "name";
                }
                setError(error?.field, {
                  type: "apiFailure",
                  message: error?.message,
                });
              });
            }
          },
        })
      );
    }
  };

  const addFrame = () => {
    append({
      layers: [
        {
          frontEndId: nanoid(),
        },
      ],
    });
  };

  const deleteFrame = (frameIndex: number) => {
    remove(frameIndex);
  };

  const usageType = watch("usageType");

  useEffect(() => {
    if (usageType === "IMAGE_TEXT") {
      const frames = getValues("frames");
      setValue("frames", [frames?.[0]]);
      unregister("videoBlenderFile");
    }
  }, [usageType]);

  return (
    <CustomizedDialogs
      type="modal"
      title={
        !isRunTest
          ? "Creative AI Setup for Media Studio"
          : "Testing your Media Studio Entry ..."
      }
      maxWidth="lg"
      subTitle={""}
      show={showMediaStudioModal}
      handleClose={!isRunTest && handleClose}
      titleSize={20}
      headerHeight={"7vh"}
      subTitleSize={16}
      buttons={
        !isRunTest ? (
          <MediaStudioFormButtons
            onRunTest={handleSubmit(onRunTestSubmit)}
            onSubmit={handleSubmit(onSubmit)}
            isEdit={isEdit}
            addingMediaStudio={addingMediaStudio}
            errors={errors}
          />
        ) : (
          <MediaStudioTestingButtons
            setIsRunTest={setIsRunTest}
            isError={
              previewError ||
              runTestPostResponse.isFailed ||
              runTestPostResponse.data.some((item) => item?.status === "FAILED")
            }
            onRefreshClick={handleSubmit(onRunTestSubmit)}
          />
        )
      }
    >
      {!isRunTest ? (
        fetchingMediaData ? (
          <StyledLoaderContainer>
            <Loader />
          </StyledLoaderContainer>
        ) : (
          <Box width="692px">
            <InputTagFormControl
              label="Ad IDs"
              tooltipText="ID(s) of the Ad(s) for which you want to set up Media Studio. You can upload an individual ID or more IDs in bulk"
              disableUnderline
              control={control}
              registeredName="adIds"
              required
              fullWidth
              placeholder="Enter Ad IDs (21425374, 462474824, 235843287, ....)"
              sx={{ height: "40px", fontSize: "12px" }}
              isBorder
              backgroundColor="#fffffff"
            />
            <Box display="flex">
              <Box flex={1} pr="6px">
                <InputFormControl
                  label="Tag"
                  tooltipText="Give your entry an unique identifier for quick reference in future"
                  disableUnderline
                  control={control}
                  registeredName="tag"
                  required
                  fullWidth
                  placeholder="Enter Tag"
                  sx={{ height: "40px", fontSize: "12px" }}
                  isBorder
                  backgroundColor="#fffffff"
                />
              </Box>
              <Box flex={1} pl="6px">
                <SelectFormControl
                  fullWidth
                  label="Choose File Usage"
                  tooltipText="Choose the format of Media Studio you wish to enable"
                  required
                  sx={{
                    height: "40px",
                    fontSize: "12px",
                  }}
                  options={[
                    { label: "Image Text", value: "IMAGE_TEXT" },
                    { value: "VIDEO_TEXT", label: "Video Text" },
                  ]}
                  placeholder="Select format"
                  control={control}
                  registeredName="usageType"
                  customDefaultValue="IMAGE_TEXT"
                  defaultValue="IMAGE_TEXT"
                  isBorder
                  field="label"
                  valueKey="value"
                  backgroundColor="#fffffff"
                />
              </Box>
            </Box>
            {usageType === "VIDEO_TEXT" && (
              <Box flex="1">
                <UploadInputFormControl
                  label="Video Blender File"
                  tooltipText="Upload the video Blender file in .zip format"
                  disableUnderline
                  control={control}
                  registeredName="videoBlenderFile"
                  required
                  fullWidth
                  placeholder="Upload Video Blender File"
                  sx={{ height: "40px", fontSize: "12px" }}
                  isBorder
                  backgroundColor="#fffffff"
                  acceptFileTypes=".zip"
                  setValue={setValue}
                  setError={setError}
                  textInputDisabled
                />
              </Box>
            )}
            <StyledLabelText>Frame(s) Info</StyledLabelText>
            {fields.map((data, index) => {
              return (
                <FrameForm
                  control={control}
                  registeredName="frames"
                  setValue={setValue}
                  setError={setError}
                  index={index}
                  watch={watch}
                  getValues={getValues}
                  deleteFrame={deleteFrame}
                  key={data.id}
                  unregister={unregister}
                />
              );
            })}

            {usageType === "VIDEO_TEXT" && (
              <Button
                sx={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#0869FB",
                  marginTop: "14px",
                  marginLeft: "-4px",
                  marginBottom: "4px",
                }}
                onClick={addFrame}
              >
                <Box display="flex" alignItems="center">
                  <AddIcon
                    style={{
                      marginRight: "10px",
                      width: "18px",
                      height: "18px",
                    }}
                  />{" "}
                  Add Frame
                </Box>
              </Button>
            )}
          </Box>
        )
      ) : (
        <Box width="692px" display={"flex"} justifyContent="center">
          <MediaStudioTesting
            usageType={usageType}
            frames={getValues("frames")}
          />
        </Box>
      )}
    </CustomizedDialogs>
  );
};

export default MediaStudioModal;
