export const BUNDLE_GENERATE = "BUNDLE_GENERATE";
export const BUNDLE_GENERATE_STATUS = "BUNDLE_GENERATE_STATUS";
export const SET_REQUEST_ID = "SET_REQUEST_ID";
export const SET_PREVIEW = "SET_PREVIEW";
export const SET_FAKEGENERATE_LOADING = "SET_FAKEGENERATE_LOADING";
export const GET_BILLBOARD_BUNDLE = "GET_BILLBOARD_BUNDLE";
export type GetBundleGenerate = {
  type: typeof BUNDLE_GENERATE;
  payload: any;
  callback?: Function;
};

export type GetBillboardBundle = {
  type: typeof GET_BILLBOARD_BUNDLE;
  payload: any;
  callback?: Function;
};

export type GetBundleGenerateStatus = {
  type: typeof BUNDLE_GENERATE_STATUS;
  payload: any;
  callback?: Function;
};

export type SetRequestId = {
  type: typeof SET_REQUEST_ID;
  payload: any;
  callback?: Function;
};

export type SetPreview = {
  type: typeof SET_PREVIEW;
  payload: any;
};

export type StatusList = {
  status: string;
  result: {
    bundles: Bundle[];
    description: any[];
    headline: any[];
    primary_text: any[];
    image?: {
      layer_name: string;
      layer_text: string;
    }[][];
    video?: {
      frame_name: string;
      layer_name: string;
      layer_text: string;
    }[][];
  };
};
export type ResponseType = {
  status_list: StatusList[];
  status: any;
};
export type Bundle = {
  primary_text: any;
  headline: any;
  description: any;
  image?: {
    layer_name: string;
    layer_text: string;
  }[];
  video?: {
    frame_name: string;
    layer_name: string;
    layer_text: string;
  }[];
  payload?: any;
  mediaFileId?: string;
};
export type BundleType = {
  headline: string;
  primary_text: string;
  description: string;
  adPreviewLink: string;
};

export type SetFakeGenerateLoading = {
  type: typeof SET_FAKEGENERATE_LOADING;
  payload: Boolean;
};
