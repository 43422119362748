import React from "react";
import {
  StyledCardContainer,
  StyledCardHeadTitle,
  StyledCardHeadSubTitle,
  StyledCenterContent,
  StyledHeaderContent,
  StyledParaContent,
  StyledFirstPara,
  StyledSecondPara,
  StyledThirdPara,
  StyledViewPara,
  StyledPriceContent,
  StyledPriceSpan,
  StyledPriceDiv,
  StyledPriceDivLast,
  StyledPriceNo,
  StyledPriceText,
  StyledQualityText,
  StyledToolTipContent,
  StyledAverageContaner,
  StyledAvarageText,
  StyledButtonContainer,
} from "./styled";
import TooltipWithInfo from "../../../Facebook/shared/TooltipWithInfo/TooltipWithInfo";
import CustomizedButtonProps from "../../../../shared/Button/CustomizedButton";
import { Skeleton } from "@mui/material";

const GsaiCardSkeleton: React.FC = (): JSX.Element => {
  return (
    <>
      <StyledCardContainer>
        <StyledHeaderContent>
          <StyledCardHeadTitle>
            <Skeleton variant="text" width={250} height={25} />
          </StyledCardHeadTitle>
          <StyledCardHeadSubTitle>
            <Skeleton variant="text" width={150} height={15} />
          </StyledCardHeadSubTitle>
        </StyledHeaderContent>

        <StyledCenterContent>
          <StyledParaContent>
            <Skeleton variant="rectangular" width={"100%"} height={68} />
          </StyledParaContent>
        </StyledCenterContent>
        <StyledPriceContent>
          <StyledPriceSpan>
            <StyledPriceDiv>
              <StyledPriceNo>
                {" "}
                <Skeleton variant="text" width={20} height={15} />
              </StyledPriceNo>

              <StyledPriceText>
                {" "}
                <Skeleton variant="text" width={45} height={15} />
              </StyledPriceText>
            </StyledPriceDiv>
            <StyledPriceDiv>
              <StyledPriceNo>
                {" "}
                <Skeleton variant="text" width={20} height={15} />
              </StyledPriceNo>

              <StyledPriceText>
                {" "}
                <Skeleton variant="text" width={45} height={15} />
              </StyledPriceText>
            </StyledPriceDiv>
            <StyledPriceDivLast>
              <StyledPriceNo>
                {" "}
                <Skeleton variant="text" width={20} height={15} />
              </StyledPriceNo>
              <StyledPriceText>
                {" "}
                <Skeleton variant="text" width={45} height={15} />
              </StyledPriceText>
            </StyledPriceDivLast>
          </StyledPriceSpan>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledQualityText>
              <div className="mr-2">
                <Skeleton variant="text" width={50} height={35} />
              </div>
              <StyledToolTipContent style={{ cursor: "pointer" }}>
                <Skeleton variant="circular" width={15} height={15} />
              </StyledToolTipContent>
            </StyledQualityText>
            <div className="ml-2">
              {" "}
              <Skeleton
                variant="text"
                width={80}
                height={30}
                style={{ borderRadius: "8px" }}
              />
            </div>
          </div>
        </StyledPriceContent>

        <StyledButtonContainer>
          <Skeleton variant="rectangular" width={150} height={31} />
          <span style={{ width: "20px" }} />
          <Skeleton variant="rectangular" width={150} height={31} />
        </StyledButtonContainer>
      </StyledCardContainer>
    </>
  );
};

export default GsaiCardSkeleton;
