import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle, Position, useReactFlow } from "react-flow-renderer";

import Box from "@mui/material/Box";
import Stack from "@mui/system/Stack";

import LoaderContainer from "../../common/LoaderContainer";
import LoaderRightStrip from "../../common/LoaderRightStrip";

import { ReactComponent as ExploreCalibration } from "../Assets/svgs/calibration/ExploreCalibration.svg";
import { ReactComponent as BrandCalibration } from "../Assets/svgs/calibration/BrandCalibration.svg";
import { ReactComponent as CompetitiorCalibration } from "../Assets/svgs/calibration/CompetitiorCalibration.svg";
import { ReactComponent as CustomKeywordsCalibration } from "../Assets/svgs/calibration/CustomKeywordsCalibration.svg";
import { ReactComponent as IntentCalibration } from "../Assets/svgs/calibration/IntentCalibration.svg";
import { ReactComponent as TrafficCalibration } from "../Assets/svgs/calibration/TrafficCalibration.svg";
import { ReactComponent as TAIIcon } from "../Assets/svgs/TAITargetIcon.svg";
import { ReactComponent as Line } from "../Assets/svgs/str-line.svg";
import { ReactComponent as TiltedLine } from "../Assets/svgs/str-tilted-line.svg";
import { ReactComponent as Divider } from "../Assets/svgs/divider.svg";
import {ReactComponent as InterestIcon} from "../Assets/svgs/interest-in-callibration.svg";
import {ReactComponent as ExploreExploitIcon} from "../Assets/svgs/explore-exploit-in-callibration.svg";
import {ReactComponent as CustomAudienceIcon} from "../Assets/svgs/custom-audience-callibration.svg";
import {ReactComponent as DescribeProductIcon} from "../Assets/svgs/describe-product-calibration.svg";

import { calibrationData } from "../constants/loader";

import { mixPanelInputCommonFunction } from "../../utils/MixpanelInputCommonFunction";
import { TaiContext } from "Pages/TargetingAIPlaygroundBeta/TaiContextProvider";
import useFocusToNode from "Pages/TargetingAIPlaygroundBeta/useFocusToNode";
import { CommonState } from "store/types/common";
import { getCustomAudiences, setAllCalibrationFormData, setCalibrationFormData } from "store/actions/targetingAI";
import { useLocation, useParams } from "react-router-dom";

export default function LoaderCalibration({
  data,
  isConnectable,
}: {
  data: any;
  isConnectable: boolean;
}) {
  const dispatch = useDispatch();
  const { setCenter } = useReactFlow();
  const { setNodes, setEdges, cohortType } = useContext(TaiContext) as any;
  const location = useLocation();
  const brandId = new URLSearchParams(location.search).get('brand_id');
  const aiInitiation = useSelector((state: any) => state.aiInitiation.apiData);
  const aiInitiationFormData = useSelector(
    (state: any) => state.aiInitiation.formData
  );
  const adaccount = useSelector((state: any) => state.adaccount);
  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;
  const { selectedAgency } = useSelector(
    (state: { common: CommonState }) => state.common
  );
  const currentPage = useSelector((state: any) => state.taiCommon.currentPage);
  const loaderState = useSelector((state: any) => state.taiCommon.loader);

  const [loadingId, setLoadingId] = useState(0);
  const [isCustomAudienceFetched,setIsCustomAudienceFetched] = useState(false);
  const [isBrandOnboarded,setIsBrandOnboarded] = useState(true);
  // document.addEventListener("visibilitychange", function () {
  //   // this event will work for all the loaders
  //   if (document.visibilityState === "hidden") {
  //     if (loadingId <= 4) {
  //       setTimeout(() => {
  //         setLoadingId(loadingId + 1);
  //       }, 2000);
  //     }
  //   }
  // });

  useEffect(() => {
    if (aiInitiation) {
      setLoadingId(0);
    }
  }, [aiInitiation]);

  useEffect(() => {
    if (currentPage > data.index) {
      setLoadingId(4);
    }
    else if (loadingId < 3 && currentPage === data.index) {
      setTimeout(() => {
        setLoadingId(loadingId + 1);
      }, 2000);
    }
    else if(isCustomAudienceFetched){
      setLoadingId(4);
    }
  }, [loadingId, currentPage,isCustomAudienceFetched]);

  const focusToNode = useFocusToNode();
  useEffect(() => {
    focusToNode("loaderExpanded");
  }, []);
  useEffect(()=>{
    dispatch(getCustomAudiences({
      "ad_account_id": aiInitiation?.adAccount?.id,
      "brand_name": aiInitiation.name,
      "brand_id": brandId, 
      "brand_website": aiInitiation.website 
    },(response:any,error:any)=>{
        setLoadingId(3);
        setIsCustomAudienceFetched(true);
        if(!error){
          setIsBrandOnboarded(response.data.message?.brand_onboarded);
          dispatch(setCalibrationFormData({
            value:response.data.message.custom_audiences,
            key:"customAudiences"
          }))
        }
    }))
  },[]);
  const calibrationContinueClicked = () => {
    // focus on next node, so pass focusToNode, optional otherwise
    data.loaderContinue(
      dispatch,
      loaderState,
      setNodes,
      setEdges,
      cohortType,
      focusToNode
    );
    mixPanelInputCommonFunction(
      "TAI Calibration Loader Clicked",
      selectedBrand?.name,
      selectedAgency?.name,
      {
        brand_id: selectedBrand?.id,
        agency_id: selectedBrand?.agencyId,
        ai_group_id: aiInitiationFormData?.selectedAiGroup?.id,
        ai_group_name: aiInitiationFormData?.selectedAiGroup?.name,
        ad_account_name: aiInitiation?.adAccount?.name,
        ad_account_id: aiInitiation?.adAccount?.id,
        tai_flow: cohortType,
      }
    );
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div
        className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex focus:outline-none w-100 h-100"
        style={{
          position: "absolute",
          zIndex: "100",
        }}
      >
        <LoaderContainer
          steps={calibrationData.steps}
          loadingId={loadingId}
          continueProps={{
            onClick: calibrationContinueClicked,
            disabled: !isCustomAudienceFetched,
            className: currentPage === data.index ? "" : "d-none",
          }}
          loaderGifProps={{
            src: calibrationData.gif,
            alt: "Calibration",
            style: { height: "15rem", width: "12rem" },
          }}
          closeProps={{
            className: currentPage > data.index ? "" : "d-none",
            onClick: () =>
              data.loaderContinue(
                dispatch,
                loaderState,
                setNodes,
                setEdges,
                cohortType
              ),
          }}
          header="Calibration"
          isBrandOnboarded={isBrandOnboarded}
        >
          <Box
            sx={{
              display: "flex",
              // alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Divider
                style={{
                  height: "20rem",
                }}
              />
              <Stack spacing={2}>
                <LoaderRightStrip
                  width="18rem"
                  gap={2}
                  items={[
                    {
                      title: "Interest",
                      text: "Use interest based flow to generate cohorts with only interest keyword",
                      svg: <InterestIcon/>,
                    },
                  ]}
                />

                <LoaderRightStrip
                  width="18rem"
                  items={[
                    {
                      title: "Ideal Customer Persona",
                      text: "Select ICP to generate Ideal Customer Persona most relevant to  your brand",
                      svg: <TAIIcon />,
                    },
                  ]}
                />
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Stack spacing={15}>
                <Line
                  style={{
                    marginTop: "5em",
                  }}
                />
                <TiltedLine />
              </Stack>
              <Stack spacing={2}>
                <LoaderRightStrip
                  gap={2}
                  items={[
                    {
                      title: "Explore vs Exploit",
                      text: "Configure the balance between historic performances and exploring new audiences",
                      svg: <ExploreExploitIcon/>,
                    },
                    {
                      title: "Custom Audience",
                      text: "Add Custom Audiences for which relevant lookalike interest cohorts will be generated",
                      svg: <CustomAudienceIcon/>,
                    },
                  ]}
                />
                <LoaderRightStrip
                  gap={2}
                  items={[
                    {
                      title: "Explore vs Exploit",
                      text: "Configure the balance between historic performances and exploring new audiences",
                      svg: <ExploreExploitIcon/>,
                    },
                    {
                      title: "Describe the product or persona",
                      text: "Configure the extent of exploring Brand Specific audiences on a scale of 0-100%",
                      svg: <DescribeProductIcon/>,
                    },
                  ]}
                />
              </Stack>
            </Box>
          </Box>
        </LoaderContainer>
      </div>
    </>
  );
}
