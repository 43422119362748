import { useMemo,memo, useState } from "react";
import { Handle } from "react-flow-renderer";
// import Toggle from "react-toggle";
import "./explore-exploit.scss";
import "../index.scss";
// import { useDispatch, useSelector } from "react-redux";
import { setCalibrationFormData } from "../../../store/actions/targetingAI";
import NoAudienceSvg from '../ReactFlowComponents/Assets/svgs/no-audience-found.svg';
import SearchComponent from "Pages/SmbDashboard/common/SearchComponent";
import { Checkbox, Toolbar, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { convertToKFormat, granularInsightsValues } from "../utils/facebook";
import { ReactComponent as CheckedIcon } from '../assets/svgs/checkedIcon.svg';
import { ReactComponent as UncheckedIcon } from '../assets/svgs/uncheckedIcon.svg';
import { debounce } from "lodash";

export default memo(({ data, isConnectable }) => {
  const [filterText, setFilterText] = useState("");
  const dispatch = useDispatch();
  const customAudiences = useSelector((state) => state.calibration.formData.customAudiences);
  const selectedCustomAudiences = useSelector((state) => state.calibration.formData.selectedCustomAudience);
  const handleSearch = (filterText) => {
    setFilterText(filterText);
  };

  const filterCustomAudiences = useMemo(() => customAudiences.filter((customAudience)=>customAudience.id.includes(filterText) || customAudience.name.toLowerCase().includes(filterText.toLowerCase())).sort((customAudience1,customAudience2)=>customAudience2.audience_size - customAudience1.audience_size), [customAudiences,filterText]);
  const recommendedCustomAudiences = filterCustomAudiences?.filter(({ granular_insights }) => granular_insights === granularInsightsValues.SYNCED);
  const otherAudiences = filterCustomAudiences?.filter(({ granular_insights }) => granular_insights !== granularInsightsValues.SYNCED);
  const onAudienceChecked = (customAudience) => {
    if (selectedCustomAudiences.map((customAudience) => customAudience.id).includes(customAudience.id)) {
      dispatch(setCalibrationFormData({
        value: selectedCustomAudiences.filter(selectedAudience => selectedAudience.id !== customAudience.id),
        key: "selectedCustomAudience"
      }))
    } else {
      dispatch(setCalibrationFormData({
        value: [...selectedCustomAudiences, customAudience],
        key: "selectedCustomAudience"
      }))
    }
  }
  return (
    <div className="p-3 bg-white card-style">
      {data.data.map((element, index) => {
        return (
          <div key={index}>
            <Handle
              type="target"
              position="left"
              style={{ background: "#0869FB" }}
              onConnect={(params) => console.log("handle onConnect", params)}
              isConnectable={isConnectable}
            />
            <div className="flex justify-between font-weight-bold">
              <div className="text-lg">{element.label}</div>
            </div>
            <hr />
            {/*No audiences found case */}
            {customAudiences?.length ? (<>
              <div className="flex justify-center mb-3" style={{
                marginLeft: '-24px'
              }}>
                <SearchComponent
                  searchTerm={filterText}
                  setSearchTerm={handleSearch}
                  width={"322px"}
                  placeholder="Search"
                />
              </div>
              <div style={{
                minHeight:'300px',
                maxHeight:'300px',
                overflowY:'scroll'
              }}>
              {/*Recommended Audiences*/}
              {recommendedCustomAudiences.length > 0 && <div>
                <div className="font-inter font-12 font-800 pl-0">Recommended Audiences</div>
                <hr style={{ margin: '0.5rem 0' }} />
                <div className="mb-6">
                  <table className="table-auto border-separate border-spacing-6 w-100 ">
                    <thead>
                      <tr>
                        <th className="font-inter font-12 font-400 py-2 pl-0 pr-2 pt-0 pl-0" style={{
                          width: "60%",
                          color:'#5C6674'
                        }}>Name</th>
                        <th className="font-inter font-12 font-400 py-2 px-2 pt-0" style={{
                          width: "20%",
                          color:'#5C6674'
                        }}>Size</th>
                        <th className="font-inter font-12 font-400 py-2 px-2 pt-0" style={{
                          width: "20%",
                          color:'#5C6674'
                        }}>Country</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div>
                  {recommendedCustomAudiences.map((recommendedCustomAudience) => {
                    return (<div className="mt-2" style={{
                      background: "#F6F7F8",
                      borderRadius: 6
                    }} key={recommendedCustomAudience.id}>
                      <table className="table-fixed border-separate border-spacing-6 w-100">
                        <tbody >
                          <tr>
                            <td className="font-inter font-12 font-500 rounded-l-md py-2 px-2 flex items-center justify-start rounded-lg pl-0" style={{
                              width: "60%"
                            }}>
                              <Checkbox
                                checkedIcon={<CheckedIcon />}
                                icon={<UncheckedIcon />}
                                checked={selectedCustomAudiences.map(selectedAudience => selectedAudience.id).includes(recommendedCustomAudience.id)}
                                onClick={() => onAudienceChecked(recommendedCustomAudience)}
                                style={{
                                  paddingLeft:0
                                }}
                              />
                              <div className="flex flex-column" style={{
                                alignItems: "flex-start"
                              }}>
                                <Tooltip
                                  title={recommendedCustomAudience.name}
                                  arrow
                                  placement="bottom"
                                >
                                  <div className="text-truncate" style={{
                                    maxWidth: 132
                                  }}>{recommendedCustomAudience.name}</div>
                                </Tooltip>

                                <div className="font-400 text-[#5C6674] text-truncate">{recommendedCustomAudience.id}</div>
                              </div>
                            </td>
                            <td className="font-inter font-12 font-500 py-2 px-2 text-truncate" style={{
                              width: "20%"
                            }}>{convertToKFormat(recommendedCustomAudience.audience_size)}</td>
                            <Tooltip
                              title={recommendedCustomAudience.countries.join(",")}
                              arrow
                              placement="bottom"
                            >
                              <td className="font-inter font-12 font-500 py-2 px-2 rounded-r-md text-truncate" style={{
                                maxWidth: "56px",
                                textAlign: "center",

                              }}>{recommendedCustomAudience.countries.join(",")}</td>
                            </Tooltip>
                          </tr>
                        </tbody>
                      </table>
                    </div>)
                  })}
                </div>


              </div>}
              {/*Other Audiences*/}
              <div className="other mt-2">
                {recommendedCustomAudiences.length ?
                  <>
                    <div className="font-inter font-12 font-700 pl-0">Other</div>
                    <hr style={{ margin: '0.5rem 0' }} />
                  </> : <></>
                }
                <div className="mb-6">
                  <table className="table-auto border-separate border-spacing-6 w-100 ">
                    <thead>
                      <tr>
                        <th className="font-inter font-12 font-400 pt-0 py-2 pl-0 pr-2" style={{
                          width: "60%",
                          color:'#5C6674'
                        }}>Name</th>
                        <th className="font-inter font-12 font-400 pt-0 py-2 pr-2" style={{
                          width: "20%",
                          color:'#5C6674',
                          paddingLeft:'13px'
                        }}>Size</th>
                        <th className="font-inter font-12 font-400 pt-0 py-2 px-2" style={{
                          width: "20%",
                          color:'#5C6674'
                        }}>Country</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div>
                  {otherAudiences.map((otherAudience) => {
                    return (<div className="mt-2" style={{
                      background: "#F6F7F8",
                      borderRadius: 6
                    }} key={otherAudience.id}>
                      <table className="table-fixed border-separate border-spacing-6 w-100">
                        <tbody >
                          <tr>
                            <td className="font-inter font-12 font-500 rounded-l-md py-2 px-2 flex items-center justify-start rounded-lg pl-0" style={{
                              width: "60%"
                            }}>
                              <Checkbox
                                checkedIcon={<CheckedIcon />}
                                icon={<UncheckedIcon />}
                                checked={selectedCustomAudiences.map(selectedAudience => selectedAudience.id).includes(otherAudience.id)}
                                onClick={() => onAudienceChecked(otherAudience)}
                                style={{
                                  paddingLeft:0
                                }}
                              />
                              <div className="flex flex-column" style={{
                                alignItems: "flex-start"
                              }}>
                                <Tooltip
                                  title={otherAudience.name}
                                  arrow
                                  placement="bottom"
                                >
                                  <div className="text-truncate" style={{
                                    maxWidth: 132
                                  }}>{otherAudience.name}</div>
                                </Tooltip>

                                <div className="font-400 text-[#5C6674] text-truncate">{otherAudience.id}</div>
                              </div>
                            </td>
                            <td className="font-inter font-12 font-500 py-2 pr-2 text-truncate" style={{
                              width: "20%",
                              paddingLeft:'15px'
                            }}>{convertToKFormat(otherAudience.audience_size)}</td>
                            <td className="font-inter font-12 font-500 py-2 px-2 rounded-r-md text-truncate" style={{
                              width: "20%",
                              textAlign: "center"
                            }}>-</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>)
                  })}
                </div>
              </div>
              </div>
             
            </>) : (
              <div className="flex flex-column justify-center gap-3 px-2 py-5">
                <img src={NoAudienceSvg} alt="No Audience" />
                <div className="flex flex-column justify-center">
                  <div className="font-inter font-14 font-600">No audiences found</div>
                  <div className="font-inter font-12 font-400 font-gray-76">Sandbox for Custom audience generation</div>
                </div>
              </div>
            )}
            {data.targetHandle && (
              <Handle
                type="source"
                position="right"
                style={{ background: "#0869FB" }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
              />
            )}
          </div>
        );
      })}
    </div>
  );
});
