import { ReactComponent as NoFilter } from "../../../../assets/cai/svg/no_dynamic_filter.svg";
import { Box } from "@mui/material";
import { StyledText } from "components/shared";

export default function NoDynamicFilterSelected() {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <NoFilter />
      <StyledText
        color="#999999"
        fontWeight={400}
        fontSize="0.625em"
        lineHeight="12px"
        pt=".5em"
        textAlign="center"
      >
        Seems like no filter is selected, kindly select one
      </StyledText>
    </Box>
  );
}
