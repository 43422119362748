import React from "react";
import { ReactComponent as MinusIcon } from "../assets/svg/minus.svg";

const Notification: React.FC<{
  bgColor: string;
  color: string;
  boldText?:string;
  text: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}> = ({ bgColor, color, boldText, text, icon }) => {
  return (
    <div
      className="w-100 px-2 py-3 d-flex rounded my-2 align-items-center"
      style={{ background: bgColor }}
    >
      <MinusIcon className="ml-2" />
      <span className="ml-2 text-md fw-bold" style={{ color }}>
        {boldText?(<strong className='mr-1' style={{"fontSize":"15px"}}>{boldText}</strong>):''}{text}
      </span>
    </div>
  );
};

export default Notification;
