export const commonDataOptions = {
    label: "",
    data: [],
    fill: false,
    backgroundColor: "rgb(255, 99, 132)",
    borderColor: "rgba(255, 99, 132, 0.2)",
    yAxisID: "",
    lineTension: 0.0,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
  };
  
  export const commonYAxisOptions = {
    type: "linear",
    display: true,
    position: "left",
    id: "",
  };
  
  export const commonOptions = {
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 80,
        top: 10,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label(tooltipItem: any, data: any) {
          const currency = data.datasets[tooltipItem.datasetIndex].currency;
          const label = data.datasets[tooltipItem.datasetIndex].label;
          return ` ${label} : ${currency} ${tooltipItem.yLabel.toFixed(2)}`;
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  export const colors = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255,1)",
    "rgba(255, 159, 64, 1)",
    "rgba(73, 39, 245, 1)",
    "rgba(39, 245, 215, 1)",
    "rgba(26, 184, 53, 1)",
    "rgba(255, 25, 25, 1)",
    "rgba(177, 25, 188, 1)",
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
];
  
export const campaignFirst = {
    key: "Campaign",
    value: "Campaign",
    disabled: true,
  };
  
export const adsetFirst = {
    key: "Adset",
    value: "Adset",
    disabled: true,
};

export const searchHead = [
    {
      key: "item_to_change",
      value: "Ad set ID/Campaign ID",
      disabled: false,
    },
    {
      key: "status",
      value: "Status",
      disabled: false,
    },
    {
      key: "phase",
      value: "Phase",
      disabled: false,
    },
    {
      key: "confidence_score",
      value: "Confidence Score",
      disabled: false,
    },
];
  
export const phaseList = [
    {
      key: "observation",
      value: "Observation",
      disabled: false,
    },
    {
      key: "recommendation",
      value: "Recommendation",
      disabled: false,
    },
    {
      key: "calibration",
      value: "Calibration",
      disabled: false,
    },
    {
      key: "incubation",
      value: "Incubation",
      disabled: false,
    },
    {
      key: "exploration",
      value: "Exploration",
      disabled: false,
    },
];
  
export const statusList = [
    {
      key: "recommended",
      value: "Recommended",
      disabled: false,
    },
    {
      key: "completed",
      value: "Completed",
      disabled: false,
    },
    {
      key: "rejected",
      value: "Rejected",
      disabled: false,
    },
];


export const confRadioInput = [
    {
      value: "gt",
      name: "is greater than",
      disabled: false,
    },
    {
      value: "lt",
      name: "is less than",
      disabled: false,
    },
    {
      value: "ib",
      name: "is between",
      disabled: false,
    },
    {
      value: "nib",
      name: "is not between",
      disabled: false,
    },
];
  