import { Box, styled } from "@mui/system";

interface InProgress {
  inProgress: boolean;
}
export const StyledTitleBoxContainer = styled(Box)`
  width: 56.15em;
`;
export const StyledTitleBox = styled(Box)`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.2em;
`;

export const StyledLoaderContainer = styled(Box)`
  width: 14.375em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const StyledLoaderGifContainer = styled("img")<InProgress>`
  width: 6.25em;
  margin-bottom: 1em;
  height: 6.25em;
  opacity: ${(props) => (props.inProgress ? "1" : "0.5")};
`;
export const StyledProgressContainer = styled(Box)`
  height: 2.5em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledProgress = styled(Box)`
  height: 2.5em;
  width: 2.5em;
  margin-right: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledMessage = styled(Box)<InProgress>`
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.6667em;
  color: ${(props) => (props.inProgress ? "#0869fb" : "#000000")};
  opacity: ${(props) => (props.inProgress ? "1" : "0.5")};
`;
export const StyledTipContainer = styled(Box)`
  color: #666666;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.6667em;
  width: 100%;
  text-align: center;
  span {
    color: #0869fb;
    margin-right: 0.33em;
  }
`;
export const StyledModalContainer = styled(Box)`
  width: 72vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 2em;
`;
export const StyledLoaderModelContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2.5em;
`;
