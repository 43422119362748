import React from "react";
import { ReactComponent as Close } from "../ReactFlowComponents/Assets/svgs/close.svg";

function StayHereModal(props) {
  const {
    onExitClick,
    onStayClick,
    text,
    exitButtonText,
    stayButtonText,
    btnWidth,
  } = props;

  return (
    <div>
      <div
        className="container-overlay"
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.7)",
          zIndex: "1032",
          top: "0",
          left: "0",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div
            className="p-3"
            style={{
              width: "463px",
              height: "165px",
              backgroundColor: "#ffffff",
              border: "1px solid #5E9EFE",
              boxShadow: "0px 8px 12px rgba(0, 31, 79, 0.08)",
              borderRadius: "8px",
            }}
          >
            <div className="heading d-flex align-items-center justify-content-between">
              <div className="font-weight-bold" style={{ fontSize: "18px" }}>
                Wait
              </div>
              <button
                className="text-black bg-transparent border-0 "
                onClick={onStayClick}
              >
                <Close />
              </button>
            </div>
            <div className="body">
              <div
                style={{
                  fontSize: "14px",
                  padding: "1rem 0",
                  color: "#666666",
                }}
              >
                {text}
              </div>
            </div>
            <div className="action-btns d-flex justify-content-end align-items-center">
              <button
                className="mr-2 bg-transparent border-0"
                onClick={onStayClick}
              >
                <span style={{ fontSize: "0.75rem", color: "#0869FB" }}>
                  {stayButtonText}
                </span>
              </button>
              <button
                style={{
                  width: { btnWidth } ?? "111px",
                  backgroundColor: "#0869FB",
                  height: "27px",
                  borderRadius: "8px",
                  fontSize: "12px",
                  padding: "13.8317px 19.7595px",
                  color: "#fff",
                }}
                className="mr-2 border-0 d-flex align-items-center justify-content-center"
                onClick={onExitClick}
              >
                <span>{exitButtonText}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StayHereModal;
