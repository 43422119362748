export const videoCardData = [
  {
    name: "Product Review",
    video:
      "https://performance-targeting.s3.ap-south-1.amazonaws.com/ed9b5922-3c96-4679-9bef-6f82ea348bb2.mp4",
  },
  {
    name: "Product Testimonial",
    video:
      "https://performance-targeting.s3.ap-south-1.amazonaws.com/6bf13d6f-69b1-40b7-9c4a-905f9dc03c9b.mp4",
  },
  {
    name: "Promotional",
    video:
      "https://performance-targeting.s3.ap-south-1.amazonaws.com/fd905216-beaf-4325-8b4d-dd5d7438b299.mp4",
  },
  {
    name: "Product comparison",
    video:
      "https://performance-targeting.s3.ap-south-1.amazonaws.com/615cb34d-ded2-4ee7-8e97-9586dc89a0dd.mp4",
  },
  {
    name: "Product walkthrough",
    video:
      "https://performance-targeting.s3.ap-south-1.amazonaws.com/e256fecd-95c1-4396-9793-d098a6d8d4fc.mp4",
  },
  {
    name: "Product Unboxing",
    video:
      "https://performance-targeting.s3.ap-south-1.amazonaws.com/fd32bd75-4f95-4df6-afff-aaeafe1b46f7.mp4",
  },
];
