import SelectableCards from "../../ui/SelectableCards/SelectableCards";
import VideoCard from "../../ui/VideoCard/VideoCard";
import OutlineModal from "./OutlineModal";
import { ReactComponent as Guidelines_Do } from "../../assets/svg/guideline_DOs.svg";
import { ReactComponent as Guidelines_Dont } from "../../assets/svg/guideline_DONTs.svg";
import { ReactComponent as SideDots } from "../../assets/svg/side_dots.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit.svg";
import { ReactComponent as Delete } from "../../assets/svg/delete.svg";
import { ReactComponent as CreativeBrief } from "../../assets/svg/creativeBrief.svg";
import { ReactComponent as VideoLayoutSquare } from "../../assets/svg/square_layout.svg";
import { ReactComponent as VideoLayoutVertical } from "../../assets/svg/vertical_layout.svg";
import { ReactComponent as VideoLayoutHorizontal } from "../../assets/svg/horizontal_layout.svg";
import { ReactComponent as VideoLength_15s } from "../../assets/svg/15s_length.svg";
import { ReactComponent as VideoLength_30s } from "../../assets/svg/30s_length.svg";
import { ReactComponent as VideoLength_60s } from "../../assets/svg/60s_length.svg";
import { videoCardData } from "../../ui/VideoCard/AllVideos";
import React, { useState, useEffect, useRef } from "react";
import { MontageCampaigns } from "../../store/types/montageAds";
import info from "../../assets/svg/smallinfo.svg";
import HandleClickOutside from "../../components/common/HandleClickOutside";
import { getCurrencySymbol } from "../../utils/commonFunctions";
import { currency } from "../../utils/constants/motageAds";
import RadioInputGuideline from "./RadioInputGuideline";

const MontageCreativeBrief: React.FC<{
  handleInputChange: (value: any) => void;
  montageAds: MontageCampaigns;
  setMontageAds: any;
  montageGuidelines: any;
  setMontageGuidelines: any;
  montageCurrency: any;
  handleCreative: (value: any, name: any) => void;
  showModal: any;
  setShowModal: any;
}> = ({
  handleInputChange,
  montageAds,
  setMontageAds,
  montageGuidelines,
  setMontageGuidelines,
  montageCurrency,
  handleCreative,
  showModal,
  setShowModal,
}) => {
  const target = useRef(null);
  const [accordion, setAccordion] = React.useState("");
  const [guideline, setGuideline] = useState<any>();
  const [guidelineCopy, setGuidelineCopy] = useState<any>();
  const [emptyGuideline, setEmptyGuideline] = useState<any>();
  const [showMore, setShowMore] = useState(false);
  const [isEditVisible, setIsEditVisible] = React.useState<boolean>(false);
  const [isEditMenuVisible, setIsEditMenuVisible] =
    React.useState<boolean>(false);
  const [editIndex, setEditIndex] = React.useState(0);
  const wrapperEditRef = React.useRef(null);
  const wrapperEditMenuRef = React.useRef(null);
  HandleClickOutside(wrapperEditRef, [setIsEditVisible]);
  HandleClickOutside(wrapperEditMenuRef, [setIsEditMenuVisible]);
  const [showMenu, setShowMenu] = useState({
    id: "",
    isOpen: false,
  });
  const [showViewMenu, setShowViewMenu] = useState({
    id: "",
    isOpen: false,
  });

  const handleChange = (e: any) => {
    handleEmptyGuideline(e);
    handleDo(e.target.value, "text");
  };

  const handleDo = (value: any, key: string) => {
    setGuideline((guideline: any) => ({ ...guideline, [key]: value }));
  };

  const handleEmptyGuideline = (e: any) => {
    e.target.value.trim() === ""
      ? setEmptyGuideline(true)
      : setEmptyGuideline(false);
  };
  const handleEditGuideline = (guideline: any, index: number) => {
    setAccordion("edit");
    setShowModal(true);
    setEditIndex(index);
    setGuideline({
      id: guideline.id,
      shouldDo: guideline.shouldDo,
      text: guideline.text,
    });
  };
  const handleDeleteGuideline = (id: any, setter: any, getter: any) => {
    let localGuideline = [...getter];
    localGuideline = getter.filter((guideline: any) => guideline.id !== id);
    setter(localGuideline);
    setShowMenu({ id, isOpen: false });
    setShowViewMenu({ id: "", isOpen: false });
    setAccordion("");
  };

  const handleAddGuideline = (value: any) => {
    setAccordion("add");
    setGuideline({ shouldDo: true, text: "" });
    setShowModal(true);
  };

  const handleSubmit = (value: any, index: any, setter: any, getter: any) => {
    if (value?.text == "" || value?.text == null) {
      setEmptyGuideline(true);
      return;
    }
    const localGuidelines = [...getter];
    localGuidelines[index] = {
      shouldDo: value.shouldDo,
      text: value.text,
      id: value.id,
    };
    setter(localGuidelines);
    setShowViewMenu({ id: "", isOpen: false });
    setShowMenu({ id: "", isOpen: false });
    setAccordion("");
  };

  const handleAddSubmit = (value: any) => {
    if (value?.text == "" || value?.text == null) {
      setEmptyGuideline(true);
      return;
    }
    if (value.shouldDo == null) {
      return;
    }
    const guideline = {
      shouldDo: value.shouldDo,
      text: value.text,
      id: montageGuidelines.length + 1,
    };
    setMontageGuidelines((guidelines: any) => [...guidelines, guideline]);
    setAccordion("");
  };

  const handleShowMenu = (e: any, id: any, name: any) => {
    name({ id: id, isOpen: true });
  };

  const handleViewGuidelines = () => {
    setGuidelineCopy(montageGuidelines);
    setAccordion("viewMore");
    setShowMore(true);
    setShowModal(true);
  };

  useEffect(() => {
    const localGuidelinesDO: any[] = [];
    const localGuidelinesDONT: any[] = [];
    montageGuidelines.map((guideline: any) => {
      if (guideline.shouldDo) {
        localGuidelinesDO.push(guideline.text);
      } else {
        localGuidelinesDONT.push(guideline.text);
      }
    });

    montageCurrency.map((list: any, index: number) => {
      if (list === "Euro")
        currency[index].name =
          getCurrencySymbol("EUR") + " " + list.toUpperCase();
      else if (list === "Pound")
        currency[index].name =
          getCurrencySymbol("GBP") + " " + list.toUpperCase();
      else
        currency[index].name =
          getCurrencySymbol(list.toUpperCase()) + " " + list.toUpperCase();
      currency[index].value = list.toUpperCase();
    });

    setMontageAds((montageAds: MontageCampaigns) => ({
      ...montageAds,
      contentGuidelinesDos: localGuidelinesDO,
      contentGuidelinesDonts: localGuidelinesDONT,
    }));
  }, [montageGuidelines, montageCurrency, guideline]);

  return (
    <div className="third-row setup">
      <div className="creative-brief">
        <div className="setup-heading">
          <div className="heading-icon">
            <CreativeBrief />
          </div>
          <h6>Creative Brief</h6>
        </div>
      </div>

      <div className="creative-details">
        <div className="creative-layout">
          <div key={`choose-layout`}>
            <label className="text-md">
              Choose your Layout
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">Choose your layout</div>
                    <span>
                      {" "}
                      Layouts help to define the frame of your video{" "}
                    </span>
                  </div>
                </div>
              </div>
            </label>
            <div className="layouts">
              <SelectableCards
                content={[
                  { name: "Square", cardIcon: <VideoLayoutSquare /> },
                  { name: "Vertical", cardIcon: <VideoLayoutVertical /> },
                  { name: "Horizontal", cardIcon: <VideoLayoutHorizontal /> },
                ]}
                cardStyle={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "#F2F2F2",
                }}
                handelOnChange={(value) => handleCreative(value, "videoLayout")}
                activeValue={montageAds.videoLayout}
              />
            </div>
          </div>
        </div>

        <div className="creative-layout">
          <div key={`video-length`}>
            <label className="text-md">
              Select Video Length
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">Select Video Length</div>
                    <span> For better engagement, keep your video crisp </span>
                  </div>
                </div>
              </div>
            </label>
            <div className="layouts">
              <SelectableCards
                content={[
                  { name: "Upto 15s", cardIcon: <VideoLength_15s /> },
                  { name: "15s-30s", cardIcon: <VideoLength_30s /> },
                  { name: "30s-60s", cardIcon: <VideoLength_60s /> },
                ]}
                cardStyle={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "#F2F2F2",
                }}
                handelOnChange={(value) => handleCreative(value, "videoLength")}
                activeValue={montageAds.videoLength}
              />
            </div>
          </div>
        </div>

        <div className="creative-layout">
          <div key={`video-style`}>
            <label className="text-md">
              Define Video Style
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">Define Video Style</div>
                    <span> Match the style to your campaign goals</span>
                  </div>
                </div>
              </div>
            </label>

            <div className="layouts">
              <VideoCard
                content={videoCardData}
                handelOnChange={(value) => handleCreative(value, "videoStyle")}
                activeValue={montageAds.videoStyle}
              />
            </div>
          </div>
        </div>

        <div className="creative-layout">
          <div key={`content-guidelines`}>
            <div className="contentGuidelines">
              <label className="text-md">
                Content Guidelines(Dos and Donts)
                <div className="tooltip-container">
                  <i ref={target}>
                    <img className="ml-2" src={info} alt="info_style" />
                  </i>
                  <div className="custom-tooltip-layout tooltip">
                    <div className="tootltip-wrapper">
                      <div className="tooltip-header">
                        Content Guidelines(Dos and Donts)
                      </div>
                      <span>
                        {" "}
                        Provide content guidelines to ensure the creators
                        produce what you are looking for
                      </span>
                    </div>
                  </div>
                </div>
              </label>
              <div className="add-new-guideline" onClick={handleAddGuideline}>
                +Add New Guideline
              </div>
            </div>
            {accordion === "add" && (
              <OutlineModal
                title="Add New Guideline"
                handleSubmit={() => handleAddSubmit(guideline)}
                handleClose={() => {
                  setEmptyGuideline(false);
                  setShowModal(false);
                }}
                showModal={showModal}
                text={
                  <RadioInputGuideline
                    handleDo={handleDo}
                    handleChange={handleChange}
                    guideline={guideline}
                    emptyGuideline={emptyGuideline}
                  />
                }
              />
            )}

            <div className="guidelines">
              {montageGuidelines
                .slice(0, 3)
                .map((guidelines: any, index: any) => (
                  <div id={"" + index} className="singleGuideline">
                    {guidelines.shouldDo ? (
                      <Guidelines_Do />
                    ) : (
                      <Guidelines_Dont />
                    )}
                    <div>{guidelines.text}</div>
                    <SideDots
                      className="sideDots"
                      onClick={(e) => {
                        setIsEditVisible(!isEditVisible);
                        handleShowMenu(e, index.toString(), setShowMenu);
                      }}
                    />
                    {isEditVisible &&
                      showMenu.isOpen &&
                      showMenu.id === index.toString() && (
                        <div className="sideMenu" ref={wrapperEditRef}>
                          <button
                            onClick={() => {
                              setIsEditVisible(false);
                              handleEditGuideline(guidelines, index);
                            }}
                          >
                            <Edit className="sideIcon" />
                            Edit
                          </button>
                          <button
                            onClick={() => {
                              setIsEditVisible(false);
                              handleDeleteGuideline(
                                guidelines.id,
                                setMontageGuidelines,
                                montageGuidelines
                              );
                              setEditIndex(index);
                            }}
                          >
                            <Delete className="sideIcon" />
                            Delete
                          </button>
                        </div>
                      )}
                    {accordion === "edit" && editIndex === index && (
                      <OutlineModal
                        title="Update Guideline"
                        handleSubmit={() =>
                          handleSubmit(
                            guideline,
                            editIndex,
                            setMontageGuidelines,
                            montageGuidelines
                          )
                        }
                        handleClose={() => {
                          setShowMenu({ id: "", isOpen: false });
                          setShowModal(false);
                          setEmptyGuideline(false);
                        }}
                        showModal={showModal}
                        text={
                          <RadioInputGuideline
                            handleDo={handleDo}
                            handleChange={handleChange}
                            guideline={guideline}
                            emptyGuideline={emptyGuideline}
                            guidelines={guidelines}
                          />
                        }
                      />
                    )}
                  </div>
                ))}
              <div className="view-more" onClick={handleViewGuidelines}>
                View more..
              </div>
              {showMore && (
                <OutlineModal
                  width={"40%"}
                  title="Content Guidelines"
                  handleSubmit={() => {
                    setShowMore(false);
                    setShowViewMenu({ id: "", isOpen: false });
                    setMontageGuidelines(guidelineCopy);
                  }}
                  handleClose={() => {
                    setShowMore(false);
                    setShowViewMenu({ id: "", isOpen: false });
                    setShowModal(false);
                  }}
                  showModal={showModal}
                  text={
                    <div className="modal-guidelines">
                      {guidelineCopy.map((guidelines: any, index: any) => (
                        <div id={"" + index} className="modal-singleGuideline">
                          {guidelines.shouldDo ? (
                            <Guidelines_Do />
                          ) : (
                            <Guidelines_Dont />
                          )}
                          <div>{guidelines.text}</div>
                          <SideDots
                            className="sideDots"
                            onClick={(e) => {
                              setIsEditMenuVisible(!isEditMenuVisible);
                              handleShowMenu(
                                e,
                                index.toString(),
                                setShowViewMenu
                              );
                            }}
                          />
                          {isEditMenuVisible &&
                            showViewMenu.isOpen &&
                            showViewMenu.id == index.toString() && (
                              <div
                                className="viewMoreMenu"
                                ref={wrapperEditMenuRef}
                              >
                                <button
                                  onClick={() => {
                                    handleEditGuideline(guidelines, index);
                                    setIsEditMenuVisible(false);
                                  }}
                                >
                                  <Edit className="sideIcon" />
                                  Edit
                                </button>
                                <button
                                  onClick={() => {
                                    handleDeleteGuideline(
                                      guidelines.id,
                                      setGuidelineCopy,
                                      guidelineCopy
                                    );
                                    setIsEditMenuVisible(false);
                                  }}
                                >
                                  <Delete className="sideIcon" />
                                  Delete
                                </button>
                              </div>
                            )}
                          {accordion === "edit" && editIndex === index && (
                            <OutlineModal
                              title="Update Guideline"
                              handleSubmit={() => {
                                handleSubmit(
                                  guideline,
                                  editIndex,
                                  setGuidelineCopy,
                                  guidelineCopy
                                );
                                setAccordion("");
                              }}
                              handleClose={() => {
                                setAccordion("");
                                setEmptyGuideline(false);
                              }}
                              showModal={showModal}
                              text={
                                <RadioInputGuideline
                                  handleDo={handleDo}
                                  handleChange={handleChange}
                                  guideline={guideline}
                                  emptyGuideline={emptyGuideline}
                                  guidelines={guidelines}
                                />
                              }
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </div>

        <div className="creative-layout">
          <div key={`style-guide`}>
            <label className="text-md">
              Styleguide (Website Link/Post)
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">
                      Styleguide (Website Link/Post)
                    </div>
                    <span>
                      {" "}
                      Add images/videos so the creators know what kind of
                      content to create
                    </span>
                  </div>
                </div>
              </div>
            </label>
            <div className="style-guide">
              <textarea
                id="styleguide"
                name="styleGuide"
                className="styleguide"
                rows={5}
                cols={3}
                placeholder={`www.linkhere.com`}
                value={montageAds.styleGuide}
                onChange={(e) => handleInputChange(e)}
                maxLength={999}
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MontageCreativeBrief;
