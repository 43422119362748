import { Box } from "@mui/material";
import { ReactComponent as EmptyGraph } from "../../../../assets/svg/ad-empty-graph.svg";
import { StyledNoGraphHeading, StyledNoGraphParagraph } from "./styled";

export default function ActionDebuggerEmptyChart() {
  return (
    <Box width="100%" height="90%" display="flex">
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <EmptyGraph />
        <StyledNoGraphHeading>Data not found </StyledNoGraphHeading>
        <StyledNoGraphParagraph>
          Please refresh the dashboard or you can also change the selected date
          range
        </StyledNoGraphParagraph>
      </Box>
    </Box>
  );
}
