import { Stack } from "@mui/material";
import styled from "styled-components";

interface Props {
  height?: number;
}

export const StyledPlaceholderContainer = styled(Stack)<Props>`
  .title {
    margin-top: 30px;
    font-size: 20px;
    // font-weight: 500;
    font-family: Lato;
    font-style: normal;
    line-height: 24px;
    color: #000000;
  }
  .description {
    width: 420px;
    color: #5a5a5a;
    line-height: 26px;
    margin-top: 12px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    font-family: Inter;
    font-style: normal;
    font-size: 18px;
    text-align: center;
  }
`;
