import React, { useCallback } from "react";
import "./index.scss";
import { Row } from "react-bootstrap";
import ViewVideo from "../ViewVideo/ViewVideo";
import ImageVideoCardItem from "./ImageVideoCardItem";
import { ImageVideoCardItemType } from "./ImageVideoCard.types";

const ImageVideoCard: React.FC<{
  selectedFile: any;
  dropdownData: any;
}> = ({ selectedFile, dropdownData }) => {
  const [showVideo, setShowVideo] = React.useState(false);
  const [videoData, setVideoData] = React.useState({});
  const [videoName, setVideoName] = React.useState<any>("");

  const handelViewVideo = useCallback(
    (item: any) => {
      setShowVideo(!showVideo);
      setVideoData({ video: item?.url });
      setVideoName(item?.name);
    },
    [showVideo]
  );
  return (
    <div>
      <div className="image-video-card-container">
        <Row>
          {selectedFile &&
            selectedFile?.length !== 0 &&
            selectedFile?.map((item: ImageVideoCardItemType, index: number) => (
              <ImageVideoCardItem
                dropdownData={dropdownData}
                handelViewVideo={handelViewVideo}
                item={item}
                showVideo={showVideo}
                index={index}
                key={index}
              />
            ))}
        </Row>
      </div>

      {showVideo && (
        <ViewVideo
          video={videoData}
          cancelText={"Close"}
          setShowVideo={setShowVideo}
          fileType="url"
          title={videoName}
        />
      )}
    </div>
  );
};

export default ImageVideoCard;
