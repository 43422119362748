import { ReactComponent as GovernanceAI } from "./assets/GovernanceAI.svg";
import { ReactComponent as ActionImpactAnalysis } from "./assets/ActionImpactAnalysis.svg";
import { ReactComponent as AdminControl } from "./assets/AdminControl.svg";
import { ReactComponent as BrandSettingsIcon } from "./assets/BrandSettingsIcon.svg";
import { ReactComponent as SinglePlatform } from "./assets/SinglePlatform.svg";
import { ReactComponent as AllPlatform } from "./assets/AllPlatforms.svg";
import { ReactComponent as TargetingAI } from "./assets/TargetingAI.svg";
import { ReactComponent as Facebook } from "./assets/icons8-facebook.svg";
import { ReactComponent as Google } from "./assets/icons8-google.svg";
import { ReactComponent as CreativeAI } from "./assets/CreativeAI.svg";
import { ReactComponent as MontageAds } from "./assets/MontageAds.svg";
import { ReactComponent as AIModelCalibrationNew } from "./assets/AIModelCalibrationNew.svg";
import { ReactComponent as GroupSettingsIcon } from "./assets/AIGroupSettings.svg";
import { ReactComponent as PlaygroundIcon } from "./assets/PlaygroundIcon.svg";
import { ReactComponent as AudienceIcon } from "./assets/audience.svg";
import { ReactComponent as BetaIcon } from "./assets/BetaIcon.svg";
import { ReactComponent as PerformanceInsights } from "assets/svg/increase-value-Dark.svg";
import { Box } from "@mui/system";
import { StyledText } from "components/shared";
import { ReactComponent as LaunchIcon } from "../../assets/svg/launch_grey.svg";
import { ReactComponent as PixisIcon } from "../../assets/svg/pixis_grey.svg";
import { ReactComponent as CreativeInsightIcon } from "../../assets/svg/creative_insight.svg";
import { ReactComponent as Optimize } from "../../assets/svg/optimize.svg";
import { ReactComponent as AudienceInsightIcon } from "../../assets/svg/audience_insight.svg";
import { ReactComponent as ReportingIcon } from "../../assets/svg/reporting.svg";
import { ReactComponent as Cfg } from "../../assets/svg/CreativeFeedGenerator.svg";
import { ReactComponent as BDIcon } from "../../assets/svg/BDIcon.svg";
import { ReactComponent as SandboxIcon } from "../../assets/svg/Crown.svg";
import { AgencyAssets } from "store/types/common";
import { Skeleton } from "@mui/material";
import {
  hasFinancePermission,
  hasAISandboxPermission,
} from "utils/commonFunctions";

export const tabs = (
  agencyAssets: AgencyAssets,
  loadingAgencyAssets: boolean
) => [
  {
    title: loadingAgencyAssets ? (
      <Skeleton variant="text" sx={{ fontSize: "1rem", width: "5rem" }} />
    ) : agencyAssets?.longTitle ? (
      agencyAssets.id === "default" ? (
        "Pixis+"
      ) : (
        agencyAssets?.longTitle
      )
    ) : (
      "Pixis+"
    ),
    id: "0",
    icon: loadingAgencyAssets ? (
      <Skeleton variant="circular" width={"33px"} height={"33px"} />
    ) : agencyAssets?.logo ? (
      agencyAssets?.id === "default" ? (
        <PixisIcon />
      ) : (
        <img
          src={agencyAssets?.logo}
          style={{ height: "33px", width: "33px" }}
          alt="logo"
        />
      )
    ) : (
      <PixisIcon />
    ),
    items: [
      {
        id: "0.1",
        name: "Creative Insights",
        module_name: "Smart Insights",
        icon: (
          <CreativeInsightIcon
            style={{
              height: "30px",
              width: "30px",
            }}
          />
        ),
        title: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr="0.625rem"
          >
            <StyledText fontSize="16px">Creative Insights</StyledText>
            <LaunchIcon />
          </Box>
        ),
        pathname: "/insights/cai",
        isPixis2Url: true,
      },
      {
        id: "0.2",
        name: "Audience Insights",
        module_name: "Smart Insights",
        title: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr="1.5625rem"
          >
            <StyledText fontSize="16px">Audience Insights</StyledText>
            <LaunchIcon />
          </Box>
        ),
        icon: (
          <AudienceInsightIcon
            style={{
              height: "1.5625rem",
              width: "1.5625rem",
            }}
          />
        ),
        isPixis2Url: true,
        pathname: "/insights/audience",
        parentClass: "no-padding-right",
      },
      {
        id: "0.6",
        name: "Performance Insights",
        module_name: "Smart Insights",
        icon: (
          <PerformanceInsights
            style={{
              height: "30px",
              width: "30px",
            }}
          />
        ),
        title: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr="0.625rem"
          >
            <StyledText fontSize="16px">Performance Insights</StyledText>
            <LaunchIcon />
          </Box>
        ),
        pathname: "/insights/performance",
        isPixis2Url: true,
      },
      {
        id: "0.4",
        name: "Optimize",
        module_name: "Optimize",
        icon: (
          <Optimize
            color="#000"
            style={{
              height: "1.5625rem",
              width: "1.5625rem",
            }}
          />
        ),
        title: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr="0.625rem"
          >
            <StyledText fontSize="16px">Optimize</StyledText>
            <LaunchIcon />
          </Box>
        ),
        pathname: "/optimize/v1/configuration",
        isPixis2Url: true,
      },
      {
        id: "0.3",
        name: "Reporting",
        module_name: "Analyze",
        title: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr="25px"
          >
            <StyledText fontSize="16px">Reporting</StyledText>
            <LaunchIcon />
          </Box>
        ),
        icon: (
          <ReportingIcon
            style={{
              height: "25px",
              width: "25px",
            }}
          />
        ),
        isPixis2Url: true,
        pathname: "/analytics/boards",
        parentClass: "no-padding-right",
      },
      {
        id: "0.7",
        name: "Audience Manager",
        module_name: "Analyze",
        title: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr="25px"
          >
            <StyledText fontSize="16px">Audience Manager</StyledText>
            <LaunchIcon />
          </Box>
        ),
        icon: <AudienceIcon />,
        isPixis2Url: true,
        pathname: "/audience-manager",
        parentClass: "no-padding-right",
      },
      {
        id: "0.5",
        name: "Feed Generator",
        module_name: "Explore",
        title: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr="25px"
          >
            <StyledText fontSize="16px">Feed Generator</StyledText>
            <LaunchIcon />
          </Box>
        ),
        icon: (
          <Cfg
            style={{
              height: "25px",
              width: "25px",
            }}
          />
        ),
        isPixis2Url: true,
        pathname: "/cfg",
        parentClass: "no-padding-right",
      },
      {
        id: "0.6",
        name: "Billing Dashboard",
        module_name: "Explore",
        title: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr="25px"
          >
            <StyledText fontSize="16px">Billing Dashboard</StyledText>
            <LaunchIcon />
          </Box>
        ),
        icon: (
          <BDIcon
            style={{
              height: "25px",
              width: "25px",
            }}
          />
        ),
        isPixis2Url: true,
        pathname: "/billingdashboard",
        parentClass: "no-padding-right",
        hasPermissionsCheckCallback: hasFinancePermission,
      },
      {
        id: "0.8",
        name: "AI Sandbox",
        module_name: "AI Sandbox",
        title: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr="25px"
          >
            <StyledText fontSize="16px">AI Sandbox</StyledText>
            <LaunchIcon />
          </Box>
        ),
        icon: (
          <SandboxIcon
            style={{
              height: "25px",
              width: "25px",
            }}
          />
        ),
        isPixis2Url: true,
        pathname: "/ai_sandbox",
        parentClass: "no-padding-right",
        hasPermissionsCheckCallback: hasAISandboxPermission,
      },
    ],
  },
  {
    title: "Performance AI",
    id: "1",
    icon: <GovernanceAI />,
    items: [
      {
        id: "1.1",
        title: "Action Impact Analysis",
        icon: <ActionImpactAnalysis />,
        items: [
          {
            id: "1.1.1",
            title: "Single Platform",
            icon: <SinglePlatform />,
            pathname: "/singlePlatform",
          },
          {
            id: "1.1.2",
            title: "All Platform ",
            icon: <AllPlatform />,
            pathname: "/actionImpactAnalysis",
          },
        ],
      },
      {
        id: "1.2",
        title: "Cross Platform Settings ",
        icon: <GroupSettingsIcon />,
        pathname: "/group-settings",
      },
      {
        id: "1.3",
        title: "AI Model Calibration",
        icon: <AIModelCalibrationNew />,
        pathname: "/aiModelConfidence",
      },
      // {
      //   id: "1.4",
      //   title: "Performance Insights",
      //   icon: <PerformanceInsights />,
      //   pathname: "/performance-insights",
      // },
    ],
  },
  {
    title: "Targeting AI",
    id: "2",
    icon: <TargetingAI />,
    items: [
      {
        id: "2.1",
        title: (
          <div className="d-flex align-items-center">
            Playground
            {/* <BetaIcon className="ml-1" /> */}
          </div>
        ),
        icon: <PlaygroundIcon />,
        pathname: "/targeting-ai-playground-beta",
      },

      {
        id: "2.2",
        title: (
          <div className="d-flex align-items-center">Audience Manager</div>
        ),
        icon: <AudienceIcon />,
        pathname: "/targeting-ai-audience-manager",
      },
    ],
  },
  {
    title: "Creative AI",
    id: "3",
    icon: <CreativeAI />,
    items: [
      {
        id: "3.1",
        title: "Meta",
        icon: <Facebook />,
        pathname: "/creative-ai",
      },
      {
        id: "3.2",
        title: <div className="d-flex align-items-center">Google Search</div>,
        icon: <Google />,
        pathname: "/google-search-playground",
        parentClass: "no-padding-right",
      },
      // {
      //   id: "3.4",
      //   title: "Montage Ads",
      //   icon: <MontageAds />,
      //   pathname: "/montage-ads",
      // },
      {
        id: "3.5",
        title: "Admin Control",
        icon: <AdminControl />,
        items: [
          {
            id: "3.4.1",
            title: "Brand Settings",
            icon: <BrandSettingsIcon />,
            pathname: "/brand-setting",
          },
        ],
        admin: true,
      },
    ],
  },
];

export const pathValues: any = {
  singlePlatform: ["1", "1.1", "1.1.1"],
  actionImpactAnalysis: ["1", "1.1", "1.1.2"],
  "group-settings": ["1", "1.2"],
  aiModelConfidence: ["1", "1.3"],
  "performance-insights": ["1", "1.4"],
  "targeting-ai-playground-beta": ["2", "2.1"],
  "targeting-ai-audience-manager": ["2", "2.2"],
  googleSearchTargetAi: ["2", "2.3"],
  "creative-ai": ["3", "3.1"],
  "google-search-playground": ["3", "3.2"],
  "montage-ads": ["3", "3.4"],
  "brand-setting": ["3", "3.5", "3.5.1"],
};
