import React, { useState } from "react";
import { useEffect } from "react";
import { ReactComponent as FileIcon } from "../../assets/svg/file-icon.svg";
import { ReactComponent as SucessIcon } from "../../assets/svg/sucess-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/svg/upload-icon.svg";
import "./index.scss";

const FileUpload: React.FC<{
  disabled?: boolean;
  value?: File | null;
  accept?: string;
  onChange?: (value: boolean,isWrongType?: boolean) => void;
}> = ({ value, onChange, disabled, accept }) => {
  const [fileName, setFileName] = useState("");

  const handleChange = (e: any) => {
    let file = e.target.files[0];
    var ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    if (file && accept?.includes(ext)) {
      setFileName(e.target.files[0].name);
      onChange && onChange(e.target.files);
    } else {
      setFileName("");
      onChange && onChange(e.target.files,true);
    }
  };

  useEffect(() => {
    if(value?.name)
    setFileName(value?.name)
  },[])
  
  return (
    <div className="bg-grey position-relative">
      <input
        className="file-upload-input"
        type="file"
        onChange={(e) => handleChange(e)}
        accept={accept}
      />
      {!fileName && (
        <div className="file-upload-button">
          <div className="text">
            <label>
              <UploadIcon className="mr-2" /> Upload a .csv/.xlsx file with keywords and average
              monthly searches
            </label>
          </div>
        </div>
      )}
      {fileName && (
        <div className="file-uploaded-button">
          <div className="success-msg text-center">
            <SucessIcon /> File Uploaded Successfully
          </div>
          <div className="file-container">
            <div className="text">
              <div className="file-name">
                <FileIcon className="mr-2" />
                {fileName}
              </div>
              <div className="update-button">
                <button className="btn light">Update File</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
