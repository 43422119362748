import FBIcon from "../assets/platforms/facebook.svg";
import GoogleIcon from "../assets/platforms/google.svg";
import LinkedInIcon from "../assets/platforms/linkedIn.svg";
import TikTokIcon from "../assets/platforms/tiktok.svg";
import DV360 from "../assets/platforms/dv-360.svg";
import Bing from "../assets/platforms/bing.svg";
import { ReactComponent as Optimise } from "../assets/creative-icon-1.svg";
import { ReactComponent as Maximize } from "../assets/creative-icon-2.svg";
import { ReactComponent as Boost } from "../assets/audience-icon-1.svg";
import { ReactComponent as Customer } from "../assets/audience-icon-2.svg";
import { ReactComponent as Campaign } from "../assets/performance-icon-1.svg";
import { ReactComponent as Elevate } from "../assets/performance-icon-2.svg";

export const paths = {
  GOOGLE: GoogleIcon,
  TWITTER: GoogleIcon,
  "GOOGLE ANALYTICS": GoogleIcon,
  FACEBOOK: FBIcon,
  LINKEDIN: LinkedInIcon,
  "MICROSOFT ADVERTISING": Bing,
  TikTok: TikTokIcon,
  "Display and Video 360": DV360,
};

export const insightTitle: any = {
  CAI: "Creative",
  TAI: "Audience",
  GAI: "Performance",
};

export const insightsBody: any = {
  GAI: "Let our AI-powered solutions give you the edge you need. By gaining valuable insights into how your bids and budgets are affected by our cutting-edge technology, you can streamline your campaign creation process and achieve optimal outcomes",
  CAI: "Enhance your creative performance with our AI system's in-depth analysis. Get valuable insights on text, creatives, and more to create ads that effectively resonate with your target audience.",
  TAI: "Use our AI system's audience insights to optimise ad targeting. Our analysis of psychographics, like interests and attitudes, provides valuable information for understanding your audience. Fine-tune your ad targeting strategy to reach the right customers and improve ad network performance.",
};

export const firstPopupTitle: any = {
  GAI: "Maximise Your Campaign Success",
  CAI: "Optimise Creative Performance",
  TAI: "Boost Ad Account Performance",
};

export const secondPopupTitle: any = {
  GAI: "Elevate Your Strategies ",
  CAI: "Maximize Creative Ideation",
  TAI: "Customer-Centric Planning",
};

export const firstPopupBody: any = {
  GAI: "Discover the Power of Bid-Budget Insights!",
  CAI: "Utilise AI-Generated Creative Insights for Optimal Performance of your Ads",
  TAI: "Reach Potential Customers and Enhance Your Brand's Effectiveness",
};

export const secondPopupBody: any = {
  GAI: "Track Performance of AI driven actions and Gain Valuable Insights!",
  CAI: "Refine your Creatives creation process using insights and findings from our AI system.",
  TAI: "Tailoring Your Business Strategy to Meet Customer Interests",
};

export const firstIcon: any = {
  CAI: <Optimise />,
  TAI: <Boost />,
  GAI: <Campaign />,
};

export const SecondIcon: any = {
  CAI: <Maximize />,
  TAI: <Customer />,
  GAI: <Elevate />,
};
