import React from "react";
import { Box, Stack } from "@mui/material";

import { ReactComponent as CreativeScrollLeft } from "../assets/creative-scroll/scroll-left.svg";
import { ReactComponent as AudienceScrollLeft } from "../assets/audience-scroll/scroll-left.svg";

function ImagesScrollLeft({ imagesType }: { imagesType: string }) {
  return (
    <Stack spacing={2} className="scroll-item">
      {imagesType === "creative" ? (
        <>
          <CreativeScrollLeft /> <CreativeScrollLeft />
        </>
      ) : (
        <>
          <AudienceScrollLeft /> <AudienceScrollLeft />
        </>
      )}
    </Stack>
  );
}

export default ImagesScrollLeft;
