import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StyledPreviewDetail = styled(Box)`
  padding-bottom: 0.8rem;
  border-bottom: 0.0625rem solid #f0f0f0;
`;

export const StyledPreviewHeader = styled.span`
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #444444;
  display: block;
  padding: 0.8rem 0rem 0.5rem;
`;

export const StyledPreivewContainer = styled(Box)`
  background: #fcfcfc;
  border: 0.0625rem solid #f0f0f0;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
`;

export const StyledPreviewIndex = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #444444;
  margin-right: 0.25rem;
`;

export const StyledPreviewText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #777777;
`;

export const StyledPreview = styled.div`
  max-height: 22vh;
  overflow: scroll;
`;
