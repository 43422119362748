import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { ReactComponent as CloseIconDark } from "../../../../../assets/svg/CloseIconDark.svg";

function IndividualKeywords({
  title,
  typeKey,
  chipData,
  handleDelete,
}: {
  title: string;
  typeKey: string;
  chipData: any;
  handleDelete: any;
}) {
  return (
    <Box
      className={"individual-suggestion"}
      sx={{
        padding: "0.75em 0.875em",
        borderRadius: "7px",
        backgroundColor: "#F6F7F8",
        flex: "1 1 0",
      }}
    >
      <Typography
        sx={{
          fontSize: "1em",
          lineHeight: "1.5em",
          fontWeight: 600,
          fontFamily: "Inter",
          color: "#000000",
          marginBottom: "0.5em",
        }}
      >
        {title}
      </Typography>
      <Box sx={{ height: "23em", overflow: "auto" }}>
        <Box
          className={"keywords-listing"}
          display={"flex"}
          alignItems={"center"}
          flexWrap={"wrap"}
          sx={{
            marginBottom: "0.5em",
          }}
        >
          {chipData?.length &&
            chipData.map((data: any) => {
              return (
                <ListItem
                  key={data.key}
                  sx={{
                    padding: "0.25em 0.5em 0.25em 0",
                    width: "auto",
                  }}
                >
                  <Chip
                    label={data.name}
                    variant="outlined"
                    onDelete={() => handleDelete(data.id, typeKey)}
                    sx={{
                      fontSize: "0.75em",
                      fontWeight: "500",
                      lineHeight: "1em",
                      backgroundColor: "#FFFFFF",
                      border: "none",
                    }}
                    deleteIcon={
                      <CloseIconDark
                        style={{
                          marginRight: "10px",
                        }}
                      />
                    }
                  />
                </ListItem>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
}

export default IndividualKeywords;
