export const SUBMIT_MANUAL_ACTION_FORM_DATA = "SUBMIT_MANUAL_ACTION_FORM_DATA";
export const SUBMIT_FORM_DATA_STATE = "SUBMIT_FORM_DATA_STATE";
export const GET_OPEN_SEARCH_AI_DATA = "GET_OPEN_SEARCH_AI_DATA";
export const SET_OPEN_SEARCH_AI_DATA = "SET_OPEN_SEARCH_AI_DATA";
export const SUBMIT_FILE_MANUAL_ACTION = "SUBMIT_FILE_MANUAL_ACTION";
export const SUBMIT_FILE_RECOMMENDATION_FOR_DV360 = "SUBMIT_FILE_RECOMMENDATION_FOR_DV360";
export const DOWNLOAD_CSV_RECOMMENDATION_FOR_DV360 = "DOWNLOAD_CSV_RECOMMENDATION_FOR_DV360";
export type SubmitManualAction = {
  type: typeof SUBMIT_MANUAL_ACTION_FORM_DATA;
  payload: {
    scheduling: string; //manual action button
    action_to_do: string; //select action
    action_basis: string; //irrelevant
    level: string; //select level of action
    confidence_score?: any; //irrelevant
    concept_id: any; //select concept
    value_absolute: any; //select action
    value_percent: any; //select action
    ad_account_id: string; //manual action button
    platform_id: string; //manual action button
    adset_id: string; //select concept
  };
};

export type SubmitFileManualAction = {
  type: typeof SUBMIT_FILE_MANUAL_ACTION;
  payload: {
    brandId: string;
    platformId: string;
    file: any;
  };
};

export type SubmitFileForRecommendationDV360 = {
  type:typeof SUBMIT_FILE_RECOMMENDATION_FOR_DV360;
  payload:{
    file: any;
  },
  callback:Function;
}

export type DownloadCSVForRecommendationsDV360 = {
  type: typeof DOWNLOAD_CSV_RECOMMENDATION_FOR_DV360;
  payload:{
    aggregation_concept_id:string;
    start_date:Date,
    end_date:Date,
  },
  callback:Function;
}

