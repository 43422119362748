import { Fragment, FunctionComponent, useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import {
  StyledLogScreenTable,
  StyledPublishedVariantData,
  StyledReviewStatus,
  StyledStatus,
  StyledTableContainer,
  StyledTableData,
  StyledTableHead,
  StyledTableHeaderColumn,
  StyledTableRow,
} from "./styled";
import { ReactComponent as ApprovedIcon } from "../../../../../assets/cai/svg/approved.svg";
import { ReactComponent as RejectedIcon } from "../../../../../assets/cai/svg/rejected.svg";
import { ReactComponent as InReviewIcon } from "../../../../../assets/cai/svg/in_review.svg";
import { ReactComponent as Sort } from "../../../../../assets/cai/svg/sort_table.svg";
import { ReactComponent as Refresh } from "../../../../../assets/cai/svg/refresh_status.svg";
import { useDispatch, useSelector } from "react-redux";
import { localTimeFormat } from "utils/date.util";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import InfoDialog from "components/CreativeAI/Facebook/Main/ActivityLog/CreativeVariants/InfoDialog";
import { LightTooltip } from "components/CreativeAI_Revamp/shared";
import { ConfirmDialog, StyledText } from "components/shared";
import { rePublishVariant } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { initialState } from "store/types/auth";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { ReactComponent as InfoIcon } from "../../../../../assets/cai/svg/info_icon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { isAdminRole } from "utils/commonFunctions";
import { AdAccountState } from "store/types/adaccount";
interface LogScreenTableProps {
  handleItemClick: any;
  fetchActivityLog: any;
  drawerOpen: boolean;
  toggleDrawer: any;
  selectedAdData: any;
  selectedAdIndex: number;
}

const DataSubData: FunctionComponent<{ data: string; subdata: string }> = ({
  data,
  subdata,
}) => (
  <div>
    <span
      style={{
        fontWeight: 500,
        marginBottom: "0.25em",
        display: "inline-block",
      }}
    >
      {data}
    </span>
    <br></br>
    <span style={{ color: "#999999" }}>{subdata}</span>
  </div>
);

const LogScreenTable: FunctionComponent<LogScreenTableProps> = ({
  handleItemClick,
  fetchActivityLog,
  drawerOpen,
  toggleDrawer,
  selectedAdData,
  selectedAdIndex,
}) => {
  const { activityLogsList } = useSelector(
    (state: { fbActivityLogReducer: any }) => state.fbActivityLogReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;

  const { userInfo } = useSelector((state: any) => state.userInfoReducer);
  const { isRePublishingVariant } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );

  // const isAdmin = userInfo?.roles?.includes("super-admin");
  const [showError, setShowError] = useState<string>("");
  const dispatch = useDispatch();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const handleRetryPublish = (id: string) => {
    setShowError("");
    dispatch(
      rePublishVariant({
        id,
        user: auth.user,
        callback: (data: any, error: boolean) => {
          if (!error) {
            fetchActivityLog();
          } else {
            setShowError(id);
          }
        },
      })
    );
  };

  const data = useMemo(
    () =>
      activityLogsList.data.map((item: any, index: number) => {
        const reviewStatus = item["Variant.reviewStatus"];
        const isVideo =
          item.data.ad_type === "VIDEO_AD" ||
          item.data.destination_ad_type === "VIDEO_AD";
        return {
          col1: (
            <StyledPublishedVariantData>
              <DataSubData
                data={item?.data?.name}
                subdata={
                  item?.data?.new_ad_id?.length
                    ? item?.data?.new_ad_id
                    : item?.variantId
                }
              />
              {reviewStatus && (
                <StyledReviewStatus
                  background={
                    reviewStatus === "APPROVED"
                      ? "rgba(16, 171, 153, 0.1)"
                      : reviewStatus === "REJECTED"
                      ? "rgba(232, 75, 71, 0.1)"
                      : "rgba(255, 135, 2, 0.1)"
                  }
                >
                  {reviewStatus === "APPROVED" ? (
                    <ApprovedIcon />
                  ) : reviewStatus === "REJECTED" ? (
                    <RejectedIcon />
                  ) : (
                    <InReviewIcon />
                  )}
                </StyledReviewStatus>
              )}
            </StyledPublishedVariantData>
          ),
          col2: (
            <DataSubData
              data={item?.data?.adName}
              subdata={item?.data?.ad_id}
            />
          ),
          col3: (
            <DataSubData
              data={item?.data?.adsetName}
              subdata={item?.data?.adset_id}
            />
          ),
          col4: localTimeFormat(new Date(item?.publishedAt)),
          col5: (
            <>
              {/* {isAdmin ? ( */}
              {isAdminRole(brandRoles) ? (
                <Box display="flex" alignItems="center">
                  <StyledStatus type={item.status}>
                    {item.status[0].toUpperCase() + item.status.substring(1)}
                  </StyledStatus>
                  {item.status === "failed" && (
                    <LightTooltip title="Click here to Retry" arrow>
                      <IconButton
                        sx={{
                          borderRadius: "12px",
                          fontSize: "16px",
                          fontFamily: "Inter",
                          fontWeight: 400,
                          color: "#0869FB",
                        }}
                        onClick={() => {
                          handleRetryPublish(item.id);
                        }}
                      >
                        {isRePublishingVariant === item.id ? (
                          <CircularProgress
                            size={15}
                            sx={{
                              color: "#0869FB",
                            }}
                            thickness={5}
                          />
                        ) : (
                          <Refresh width="1em" height="1em" />
                        )}
                      </IconButton>
                    </LightTooltip>
                  )}
                  <InfoDialog
                    variantInfo={[
                      {
                        keyText: "Primary Text",
                        value: item.data?.primary_text,
                      },
                      {
                        keyText: "Headline",
                        value: item.data?.headline,
                      },
                      {
                        keyText: "Description",
                        value: item.data?.description,
                      },
                      {
                        keyText: "Ad type",
                        value: isVideo ? "video" : "image",
                      },
                      {
                        keyText: "Display Url",
                        value: item.data?.display_url,
                      },
                      {
                        keyText: "Url",
                        value: item.data?.url,
                      },
                      {
                        keyText: "Error Message",
                        value:
                          item?.status === "failed"
                            ? item.data?.errorMessage
                            : null,
                      },
                    ]}
                    adType={isVideo ? "video" : "image"}
                    imageHash={item.data?.image_data?.image_hash}
                    videoId={item.data?.video_data?.video_id}
                  />
                </Box>
              ) : (
                <StyledStatus type={item.status}>
                  {item.status !== "failed"
                    ? item.status[0].toUpperCase() + item.status.substring(1)
                    : "Processing"}
                </StyledStatus>
              )}
            </>
          ),
        };
      }),
    [activityLogsList.data]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Ad Variant Published",
        accessor: "col1" as const,
      },
      {
        Header: "Reference Ad Used",
        accessor: "col2" as const,
      },
      {
        Header: "Published Under Ad Set",
        accessor: "col3" as const,
      },
      {
        Header: "Date & Time",
        accessor: "col4" as const,
      },
      {
        Header: "Status",
        accessor: "col5" as const,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Fragment>
      <StyledTableContainer id="scrollableDiv">
        {activityLogsList.isLoading ? (
          <Box
            flex={1}
            width="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <CircularProgress thickness={6} />
          </Box>
        ) : activityLogsList.data.length ? (
          // <InfiniteScroll
          //   dataLength={10}
          //   next={() => {}}
          //   hasMore={true}
          //   loader={<h4>Loading...</h4>}
          //   scrollableTarget="scrollableDiv"
          // >
          <StyledLogScreenTable {...getTableProps()}>
            <StyledTableHead>
              {headerGroups.map((headerGroup) => (
                <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <StyledTableHeaderColumn
                      {...column.getHeaderProps(
                        column.Header !== "Status"
                          ? column.getSortByToggleProps()
                          : undefined
                      )}
                    >
                      {column.render("Header")}
                      &nbsp;
                      {column.Header !== "Status" ? (
                        <Sort width="0.84375em" height="0.65625em"></Sort>
                      ) : (
                        <Tooltip
                          title="Publish status of your Ad Variants"
                          arrow
                        >
                          <InfoIcon
                            style={{
                              marginLeft: "4px",
                            }}
                          />
                        </Tooltip>
                      )}
                    </StyledTableHeaderColumn>
                  ))}
                </StyledTableRow>
              ))}
            </StyledTableHead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <StyledTableRow
                    greyBg={!(index % 2)}
                    {...row.getRowProps()}
                    onClick={() => {
                      handleItemClick(activityLogsList.data[index], index);
                      toggleDrawer(true);
                    }}
                    style={{
                      backgroundColor:
                        drawerOpen && selectedAdIndex === index
                          ? "#DEECFF"
                          : "",
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <StyledTableData {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </StyledTableData>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
            </tbody>
          </StyledLogScreenTable>
        ) : (
          // </InfiniteScroll>
          <Box
            flex={1}
            width="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <StyledText>
              Publish your 1st Ad Variant to see the Logs here
            </StyledText>
          </Box>
        )}
      </StyledTableContainer>
      <ConfirmDialog
        show={showError.length > 0}
        onClose={() => setShowError("")}
        title="Unable to publish the variant"
        onComplete={() => handleRetryPublish(showError)}
        completeButtonText="Retry"
      />
    </Fragment>
  );
};

export default LogScreenTable;
