import { ReactComponent as MetricIcon } from "../../../../assets/svg/metric-decision-arrow.svg";
import { ReactComponent as WarningMetricIcon } from "../../../../assets/svg/warning-metric-icon.svg";

export default function MetricValueComponent(props) {
  if (props.values) {
    const growthFlag = props?.metric?.growth
      ? props.metric.growth === "Positive"
        ? props.values.maxValue - props.values.minValue >= 0
        : !(props.values.maxValue - props.values.minValue > 0)
      : props.values.maxValue - props.values.minValue >= 0;
    return (
      <div className="d-flex align-items-center justify-content-between">
        {growthFlag ? (
          <MetricIcon
            className={`mr-1 metric-icon`}
            style={{ minWidth: "11px" }}
          />
        ) : (
          <WarningMetricIcon
            className={`mr-1 metric-icon`}
            style={{ minWidth: "11px" }}
          />
        )}
        <div className="text-truncate font-weight-bold">
          {props.metric.name}
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="text-truncate font-weight-bold">
          {props.metric.name ?? ""}
        </div>
      </div>
    );
  }
}
