import React from "react";
import Box from "@mui/system/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { YOUTUBE_SUB_TABS } from "Pages/AudienceManager/constants";
function a11yProps(index: number) {
  return {
    id: `youtube-tab-${index}`,
    "aria-controls": `youtube-tabpanel-${index}`,
  };
}
function SubTabs({
  value,
  handleChange,
}: {
  value: number;
  handleChange:
    | ((event: React.SyntheticEvent<Element, Event>, value: any) => void)
    | undefined;
}) {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "#EAEAEC" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {YOUTUBE_SUB_TABS.map((label) => (
          <Tab
            label={label}
            {...a11yProps(0)}
            key={label}
            sx={{
              textTransform: "none",
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default SubTabs;
