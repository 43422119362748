import React, { useRef } from "react";
import { useSelector } from "react-redux";
import info from "../../assets/svg/smallinfo.svg";
import { FormState } from "../../store/types/form";
import InputText from "../../ui/InputText/InputText";
import Radio from "../../ui/Radio/Radio";
import Select from "../../ui/Select/Select";

type InputProps = {
  data: any;
  formKey: string;
  elementId: string;
  handleChanage: (
    key: string,
    value: string | number,
    elementId?: string
  ) => void;
};

const FormInput: React.FC<InputProps> = ({
  data,
  formKey,
  handleChanage,
  elementId,
}) => {
  const target = useRef(null);
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { targetingForm } = formState;

  return (
    <>
      {data.type === "text" && (
        <div key={formKey} className="form-group">
          <label className="text-md">
            {data.label}
            {(data.tooltipTitle || data.tooltipText) && (
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    {data.tooltipTitle && (
                      <div className="tooltip-header">{data.tooltipTitle}</div>
                    )}
                    {data.tooltipText && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data.tooltipText }}
                      ></span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </label>
          <InputText
            name={formKey}
            placeholder={data.placeholder}
            value={targetingForm && targetingForm[formKey]}
            onChange={(e: any) =>
              handleChanage(formKey, e.target.value, elementId || "")
            }
          />
        </div>
      )}
      {data.type === "select" && (
        <div key={formKey} className="form-group">
          <label htmlFor="example2" className="text-md">
            {data.label}
            {(data.tooltipTitle || data.tooltipText) && (
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    {data.tooltipTitle && (
                      <div className="tooltip-header">{data.tooltipTitle}</div>
                    )}
                    {data.tooltipText && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data.tooltipText }}
                      ></span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </label>
          <Select
            name={formKey}
            value={(targetingForm && targetingForm[formKey]) || ""}
            options={data.options.length !== 0 ? data.options : [formKey] || []}
            onChange={(e) =>
              handleChanage(formKey, e.target.value, elementId || "")
            }
          />
        </div>
      )}
      {data.type === "radio" && data.show && (
        <div
          key={formKey}
          className={data.dependentField ? "form-group pl-3" : "form-group"}
        >
          <label className="text-md">
            {data.label}
            {(data.tooltipTitle || data.tooltipText) && (
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    {data.tooltipTitle && (
                      <div className="tooltip-header">{data.tooltipTitle}</div>
                    )}
                    {data.tooltipText && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data.tooltipText }}
                      ></span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </label>
          <Radio
            name={formKey}
            data={data.options}
            defaultSelected={(targetingForm && targetingForm[formKey]) || ""}
            onChange={(e) =>
              handleChanage(formKey, e.target.value, elementId || "")
            }
          />
        </div>
      )}
    </>
  );
};

export default FormInput;
