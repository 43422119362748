import { useState, useEffect } from "react";
import "./ActionDebuggerChart.scss";
import { addDays, subDays } from "date-fns";
import MultiRangeSlider from "multi-range-slider-react";
import Charts from "./Chart/Chart";
import SearchableSelect from "../../../ui/SearchableSelect/SearchableSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  setPreviousPeriod,
  setCurrentPeriod,
  saveMetricElement,
  setGraphUpdateLoader,
  getMetricGraphData,
  setIsDefault,
} from "../../../store/actions/ActionDebugger/ActionDebugger";
import DropDownTooltip from "../shared/TooltipActionDebugger/DropDownTooltip";
import MetricValueComponent from "../shared/MetricValueComponent/MetricValueComponent";
import { useDebouncedEffect } from "../../shared/hooks/useDebouncedEffect";
import { getCurrencySymbol } from "../../../utils/commonFunctions";
import {
  calculatePaddedDate,
  calculatePreviousPeriod,
} from "../shared/utils/common.utils";
import { yyyyMMDDFormat } from "../../../utils/date.util";
import ActionDebuggerChartSkeletonItem from "./ActionDebuggerChartSkeleton/ActionDebuggerChartSkeletonItem";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import { mixPanelAdEvent } from "utils/mixpanel";
import InfoPopup from "./../../AIGroupSettings/AIGroupSettingForm/InfoPopup";

function ActionDebuggerChart(props) {
  const {
    currentPeriod,
    previousPeriod,
    additionalDateInfo,
    graphUpdateLoader,
    metricGraphData,
    metricOldGraphData,
    compareType,
    isDefault,
  } = useSelector((state) => state.ActionDebuggerReducer);
  const {
    selectedAdAccount,
    selectedAdSet,
    selectedCampaign,
    selectedAIGroup,
  } = useSelector((state) => state.ActionDebuggerHelperReducer);
  const { selectedBrand } = useSelector((state) => state.adaccount);
  const { user } = useAuth0();
  const authState = useSelector((state) => state.auth);
  const { paddedDay, differenceInCalenderDay } = additionalDateInfo;
  const [minValue, set_minValue] = useState(1);
  const [maxValue, set_maxValue] = useState(14);
  const [localMinValue, setLocalMinValue] = useState(1);
  const [localMaxValue, setLocalMaxValue] = useState(14);
  const dispatch = useDispatch();
  const [metricOptions, setMetricOptions] = useState([]);
  const [allmetricOptions, setAllMetricOptions] = useState([]);
  const [isMetricUpdate, setIsMetricUpdate] = useState(null);
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  const selectedOptions = (val, idx) => {
    setIsMetricUpdate(idx);
    dispatch(setGraphUpdateLoader(idx));
    let newMetrics = [...props.elementMetrics];
    newMetrics[idx] = val;
    newMetrics[idx].isEmpty = true;
    let params = newMetrics.map((metric) => ({
      id: metric.id,
      isAttribuationMetric: metric?.isAttribuationMetric,
    }));
    dispatch(
      saveMetricElement(
        {
          params: params,
          elementId: selectedAdAccount?.selectedAdAcc?.elementId,
          value: newMetrics,
        },
        (response, error) => {
          if (!error) {
            mixPanelAdEvent("AD - Graph Metric Changed", {
              module_name: "Action Debugger",
              username: user?.name,
              agency_id: selectedBrand?.agencyId,
              agency_name: selectedBrand?.agency_name,
              brand_id: selectedBrand?.id,
              brand_name: selectedBrand?.name,
              brand_type: selectedBrand?.brand_type,
              ad_account_id: selectedAdAccount?.selectedAdAcc?.id,
              ad_account_name: selectedAdAccount?.selectedAdAcc?.name,
              ai_group_name: selectedAIGroup?.name,
              platform_name: "Facebook",
              initial_metric: props.elementMetrics[idx]?.name,
              new_metric: val?.name,
            });
            if (isDefault) {
              dispatch(setIsDefault(false));
            }

            let currentPadedDays = calculatePaddedDate(
              currentPeriod,
              additionalDateInfo.paddedDay
            );
            let previousPadedDays = calculatePaddedDate(
              previousPeriod,
              additionalDateInfo.paddedDay
            );
            dispatch(
              getMetricGraphData({
                elementId: selectedAdAccount?.selectedAdAcc?.elementId,
                start_date: yyyyMMDDFormat(currentPadedDays.startDate),
                end_date: yyyyMMDDFormat(currentPadedDays.endDate),
                adsetIds:
                  selectedAdAccount?.platform?.name &&
                  (selectedAdAccount?.platform.name
                    ?.toLowerCase()
                    .includes("facebook") ||
                    selectedAdAccount?.platform.name
                      ?.toLowerCase()
                      .includes("dv360"))
                    ? selectedAdSet
                    : [],
                campaignIds:
                  selectedAdAccount?.platform?.name &&
                  !selectedAdAccount?.platform.name
                    ?.toLowerCase()
                    .includes("facebook")
                    ? selectedCampaign
                    : [],
                typeOfData: "SET_METRIC_GRAPH_DATA",
                adMetricIds: val?.isAttribuationMetric ? null : val.id,
                resultMetricId: val?.isAttribuationMetric ? val.id : null,
                data: metricGraphData,
                attributionWindow:
                  selectedAdAccount?.platform?.name &&
                  selectedAdAccount?.platform.name
                    ?.toLowerCase()
                    .includes("facebook")
                    ? props.attributionWindow
                    : "",
                callback: (metricGraphDataNew) => {
                  dispatch(
                    getMetricGraphData({
                      elementId: selectedAdAccount?.selectedAdAcc?.elementId,
                      start_date: yyyyMMDDFormat(previousPadedDays.startDate),
                      end_date: yyyyMMDDFormat(previousPadedDays.endDate),
                      adsetIds:
                        selectedAdAccount?.platform?.name &&
                        (selectedAdAccount?.platform.name
                          ?.toLowerCase()
                          .includes("facebook") ||
                          selectedAdAccount?.platform.name
                            ?.toLowerCase()
                            .includes("dv360"))
                          ? selectedAdSet
                          : [],
                      campaignIds:
                        selectedAdAccount?.platform?.name &&
                        !selectedAdAccount?.platform.name
                          ?.toLowerCase()
                          .includes("facebook")
                          ? selectedCampaign
                          : [],
                      typeOfData: "SET_METRIC_OLD_GRAPH_DATA",
                      adMetricIds: val?.isAttribuationMetric ? null : val.id,
                      resultMetricId: val?.isAttribuationMetric ? val.id : null,
                      data: metricOldGraphData,
                      attributionWindow:
                        selectedAdAccount?.platform?.name &&
                        selectedAdAccount?.platform.name
                          ?.toLowerCase()
                          .includes("facebook")
                          ? props.attributionWindow
                          : "",
                      callback: (metricOldGraphDataNew) => {
                        const {
                          transformedCurrentData,
                          transformedPreviousData,
                        } = props.transformGraphData(
                          [val],
                          metricGraphDataNew,
                          metricOldGraphDataNew
                        );

                        props.setGraphOldData({
                          ...props.graphOldData,
                          ...transformedPreviousData,
                        }); // need to changes name
                        props.setGraphCurrentData({
                          ...props.graphCurrentData,
                          ...transformedCurrentData,
                        });
                        dispatch(setGraphUpdateLoader(null));
                      },
                    })
                  );
                },
              })
            );
            setIsMetricUpdate(null);
            dispatch({
              type: "SET_CHANGE_METRIC_FINISH",
              payload: true,
            });
          } else {
            setIsMetricUpdate(null);
            dispatch(setGraphUpdateLoader(null));
            dispatch({
              type: "SET_CHANGE_METRIC_FINISH",
              payload: true,
            });
          }
        }
      )
    );
  };

  const filterOptions = (value) => {
    if (value) {
      let newOptions = allmetricOptions.filter((option) =>
        option?.label?.toLowerCase().includes(value?.toLowerCase())
      );
      setMetricOptions(newOptions);
    } else {
      setMetricOptions(allmetricOptions);
    }
  };

  const ChildComponent = (prop) => {
    return (
      <>
        <input
          type="radio"
          name="action-debugger"
          className="cursor-pointer"
          value={prop.value}
          checked={prop.value === prop.dataPoint.value}
          defaultChecked={prop.value === prop.dataPoint.value}
        />
        <div className="radio-label d-flex justify-content-between w-100">
          <div className="text-lg ml-2">{prop.dataPoint.label}</div>
          {prop.dataPoint?.isAttribuationMetric ? (
            <div className="mr-2">
              <InfoPopup
                source={props.brandSources.find(
                  (each) => each.id === prop.dataPoint?.sourceId
                )}
              />
            </div>
          ) : (
            <div class="customised-tooltip mr-3">
              <DropDownTooltip
                title={prop.dataPoint?.label}
                subTitle={prop.dataPoint?.description}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    set_minValue(paddedDay);
    set_maxValue(paddedDay + differenceInCalenderDay - 1);
    setLocalMinValue(paddedDay);
    setLocalMaxValue(paddedDay + differenceInCalenderDay - 1);
  }, [paddedDay, differenceInCalenderDay, selectedAdAccount?.platform]);

  useEffect(() => {
    if (props.allMetrics.length > 0) {
      const newMetrics = props.allMetrics.map((element) => {
        element.value = element.id;
        element.label = element.shortName;
        return element;
      });
      setAllMetricOptions(newMetrics);
      setMetricOptions(newMetrics);
    }
  }, [props.allMetrics]);

  useDebouncedEffect(
    () => {
      if (minValue !== localMinValue || maxValue !== localMaxValue) {
        if (
          selectedAdAccount?.platform?.name?.toLowerCase().includes("tiktok") &&
          maxValue - minValue >= 24
        ) {
          alert(
            "For platform Tiktok, Maximum range of 24 days can be selected"
          );
          set_minValue(localMinValue);
          set_maxValue(localMaxValue);
        } else {
          const startDate = addDays(
            currentPeriod.startDate,
            minValue - localMinValue
          );
          const endDate = subDays(
            currentPeriod.endDate,
            localMaxValue - maxValue
          );
          dispatch(
            setCurrentPeriod({
              startDate,
              endDate,
              editedBy: "Calender",
            })
          );
          dispatch(
            setPreviousPeriod(
              calculatePreviousPeriod(
                compareType,
                {
                  startDate,
                  endDate,
                  editedBy: "Calender",
                },
                "Calender"
              )
            )
          );
          setLocalMinValue(minValue);
          setLocalMaxValue(maxValue);
        }
      }
    },
    [minValue, maxValue],
    800
  );

  return (
    <div className="action_debugger_chart">
      <div className="layout">
        <div className="inner_layout">
          {props.elementMetrics.length > 0 &&
            props.elementMetrics.map((metric, index) => {
              let maxGraphValue = null;
              let minGraphValue = null;
              let i = maxValue;
              while (i >= minValue) {
                // ...
                if (
                  props.graphCurrentData &&
                  props.graphCurrentData[metric.id] &&
                  props.graphCurrentData[metric.id][i] &&
                  (props.graphCurrentData[metric.id][i][1] ||
                    props.graphCurrentData[metric.id][i][1] === 0)
                ) {
                  maxGraphValue = props.graphCurrentData[metric.id][i][1];
                  break;
                }
                i--;
              }
              let j = minValue;
              while (props.graphCurrentData[metric.id] && j <= maxValue) {
                // ...
                if (
                  props.graphCurrentData &&
                  props.graphCurrentData[metric.id] &&
                  props.graphCurrentData[metric.id][j] &&
                  (props.graphCurrentData[metric.id][j][1] ||
                    props.graphCurrentData[metric.id][j][1] === 0)
                ) {
                  minGraphValue = props.graphCurrentData[metric.id][j][1];
                  break;
                }
                j++;
              }

              return graphUpdateLoader === index || isMetricUpdate === index ? (
                index === 0 ? (
                  <Box
                    sx={{
                      overflow: "hidden",
                      height: "100%",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="4em"
                      sx={{
                        margin: ".5em 1em",
                        borderRadius: ".5em",
                      }}
                    />
                    <ActionDebuggerChartSkeletonItem />
                  </Box>
                ) : (
                  <ActionDebuggerChartSkeletonItem />
                )
              ) : (
                <div>
                  {index === 0 && (
                    <div className="metrics-name-header">Metrics</div>
                  )}
                  <div className="chart_layout">
                    <div className="chart_select">
                      <SearchableSelect
                        data={metricOptions}
                        value={metric.id}
                        onChange={(value) => {
                          selectedOptions(value, index);
                        }}
                        filterOptions={(e) => filterOptions(e)}
                        labelComponent={ChildComponent}
                        className={"p-2"}
                        valueComponent={
                          <MetricValueComponent
                            metric={metric}
                            values={
                              props.graphCurrentData[metric.id] && {
                                maxValue: maxGraphValue,
                                minValue: minGraphValue,
                              }
                            }
                          />
                        }
                        disable={graphUpdateLoader}
                      />
                    </div>
                    <div className="chart_container">
                      <Charts
                        RangeItem={index === 0 ? true : false}
                        name={metric.name}
                        currency={
                          metric.metricFormat === "Currency"
                            ? authState?.accountDetails?.currency
                              ? getCurrencySymbol(
                                  authState?.accountDetails?.currency
                                )
                              : ""
                            : ""
                        }
                        type={"1"}
                        data={
                          props.graphCurrentData[metric.id] && [
                            ...props.graphCurrentData[metric.id],
                          ]
                        }
                        oppositeData={
                          props.graphOldData[metric.id] && [
                            ...props.graphOldData[metric.id],
                          ]
                        }
                        bgColor="#fff"
                        dateRange={props.dateRange}
                        tooltipExtraInfo={props.tooltipExtraInfo}
                        isPercentage={metric.metricFormat === "Percentage"}
                      />
                      <div className="divider-chart" />
                    </div>
                  </div>
                </div>
              );
            })}

          <MultiRangeSlider
            min={0}
            max={2 * paddedDay + differenceInCalenderDay - 1}
            step={1}
            ruler={false}
            label={false}
            preventWheel={false}
            minValue={minValue}
            maxValue={maxValue}
            onInput={(e) => {
              handleInput(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ActionDebuggerChart;
