import { useRef } from "react";
import { ReactComponent as CheckIcon } from "../../../../assets/svg/gsai-check.svg";
import { CheckboxContainer } from "./styles.ConfigureAd";
const { v4: uuidv4 } = require("uuid");

interface Props {
  className?: string;
  disabled?: boolean;
  isIntermediate?: boolean;
  label?: string;
  labelComponent?: React.ReactNode;
  onChange?: (value: boolean, e: any) => void;
  value?: boolean;
  name: string;
  register: any;
  warningMessage?: string;
}

function CheckBox({
  className = "",
  disabled,
  isIntermediate = false,
  label = "",
  labelComponent = null,
  name,
  register,
  warningMessage = "",
}: Props): JSX.Element {
  const uuid = useRef<string>(uuidv4());

  return (
    <CheckboxContainer
      className={`pixis-checkbox-container ${className}${
        disabled ? " is-disabled" : ""
      }`}
    >
      <input
        {...register(name)}
        disabled={disabled}
        id={uuid.current}
        type="checkbox"
      />
      <label className="pixis-checkbox-label d-flex" htmlFor={uuid.current}>
        <div className="pixis-checkbox">
          <CheckIcon className="check-icon" />
          <div
            className={`intermediate-line${
              isIntermediate ? " intermediate" : ""
            }`}
          />
        </div>
        <div>
          {" "}
          {labelComponent ? (
            labelComponent
          ) : (
            <div className="label-content">{label}</div>
          )}
          <div className="warning-message">{warningMessage}</div>
        </div>
      </label>
    </CheckboxContainer>
  );
}

export default CheckBox;
