import Button from "@mui/material/Button";
import Stack from "@mui/system/Stack";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import axios from "axios";
import LoginRejected from "../../assets/png/LoginRejected.png";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "../../utils/path";
import { getSearchParams } from "../../utils/commonFunctions";

function PasswordExpired() {
  const history = useHistory();

  const sendEmail = async () => {
    try {
      const email = getSearchParams("email");
      if (email) {
        await axios.post(
          `https://${AUTH0_DOMAIN}/dbconnections/change_password`,
          {
            client_id: AUTH0_CLIENT_ID,
            email,
            connection: "Username-Password-Authentication",
          }
        );
      }
      history.push("/auth/login");
    } catch (error) {
      console.log("LL: sendEmail -> error", error);
    }
  };
  return (
    <Stack
      className="font-name-inter"
      sx={{
        background:
          "url(https://app-dev.pixis.ai/static/onboarding/images/LoginSuccessfulBg.svg)",
        backgroundSize: "cover",
        backgroundPositionY: "-120px",
        backgroundColor: "white",
        height: "100vh",
        width: "100vw",
        "& *": {
          fontFamily: "Inter",
        },
      }}
      alignItems="center"
      pt={4}
    >
      <img alt="LoginRejected" src={LoginRejected} />

      <Typography
        sx={{ fontSize: "40px", fontWeight: 700, color: "#535353", mt: 4 }}
      >
        Link Expired
      </Typography>

      <Typography
        sx={{ fontSize: "14px", fontWeight: 500, color: "#6B6B6B", mt: 2 }}
      >
        To reset your password, return to the login page and
        <br />
        select "Forgot Your Password" to send a new email.
      </Typography>
      <Button
        className="text-white border-0 rounded btn-primary"
        variant="contained"
        sx={{
          background: "#0869FB",
          textTransform: "none",
          mt: 2,
          fontSize: "14px",
          fontWeight: 600,
          width: "auto",
          px: 4,
          py: 2,
        }}
        onClick={() => sendEmail()}
        disableRipple
      >
        Request New Link
      </Button>
    </Stack>
  );
}

export default PasswordExpired;
