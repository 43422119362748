import "./NoDataScreen.scss";

import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NoData from "../../../../assets/Gif/NoDataFound.gif";

const NoDataScreenYt: React.FC<{}> = () => {
  return (
    <Box className="no_result_container">
      <Box sx={{ height: "17.125rem", width: "17.125rem" }}>
        <img
          src={NoData}
          alt="no-result-img"
          style={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Typography className="font-18 font-700 text-black mb-2">
        No Recommendations
      </Typography>

      <Typography className="font-12 font-500">
        Pixis AI hasn't recommended any videos, channels or audience segments
        yet.
      </Typography>
      <Typography className="font-12 font-500">
        Contact our customer support for more information
      </Typography>
    </Box>
  );
};

export default NoDataScreenYt;
