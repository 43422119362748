import React from "react";
import Chip from "@mui/material/Chip";
import { SxProps, Typography } from "@mui/material";
import { Theme } from "@mui/system";

interface ICustomChipProps {
  label: string;
  color?: string;
  bgColor?: string;
  margin?: string;
  sx?: SxProps<Theme>;
}

function CustomChip(props: ICustomChipProps) {
  const { label, color = "#000", bgColor = "#fff", margin = "0.1rem" } = props;
  return (
    <Chip
      title={label}
      size="small"
      label={
        <Typography
          noWrap
          sx={{
            fontSize: "0.75rem",
            fontFamily: "inter",
            fontWeight: 500,
          }}
        >
          {label}
        </Typography>
      }
      sx={{
        backgroundColor: bgColor,
        fontSize: "10px",
        fontWeight: "400",
        margin: margin,
        "& .MuiChip-label": {
          color: { color },
        },
        ...props.sx,
      }}
    />
  );
}

export default CustomChip;
