import React from "react";
import { StyledDiv, StyledStatusTag } from "./styled";
import { ReactComponent as RedirectIcon } from "../../../../assets/svg/redirect.svg";
interface Props {
  href: string;
  status: "APPROVED" | "UNDER_REVIEW" | "REJECTED";
  isViewedFromBeta?: null | string;
}
enum StatusNames {
  APPROVED = "Approved",
  REJECTED = "Rejected",
  UNDER_REVIEW = "In Review",
}
enum StatusClasses {
  APPROVED = "approved",
  REJECTED = "rejected",
  UNDER_REVIEW = "under-review",
}

function StatusTag({ href, status, isViewedFromBeta }: Props) {
  return (
    <StyledDiv>
      {isViewedFromBeta ?
        <StyledStatusTag
          className={StatusClasses?.[status]}
          onClick={()=>{
            window.parent.postMessage(
              {
                type: 'REVIEW-REDIRECT',
                href
              },
              '*'
              );
          }}
        >
          <span>{StatusNames?.[status]}</span>
          <RedirectIcon width="8%" height="10%" />
        </StyledStatusTag>
        : <StyledStatusTag
          className={StatusClasses?.[status]}
          href={href}
          target="_blank"
        >
          <span>{StatusNames?.[status]}</span>
          <RedirectIcon width="8%" height="10%" />
        </StyledStatusTag>}
    </StyledDiv>
  );
}

export default StatusTag;
