import { Box, IconButton } from "@mui/material";
import CustomizedDialogs from "../../../../../shared/Modal/CustomizedDialogs";
import AdSetDetailsModalItem from "./AdSetDetailsModalItem";
import { ReactComponent as CrossIcon } from "../../../../../../assets/svg/close.svg";
import { useSelector } from "react-redux";
import {
  StyledCreateVariantSpan,
  StyledLinkViewMore,
  StyledVariantItem,
  StyledVariantItemPara,
  StyledVariantItemParaTitle,
} from "../../../shared/AdSets/styled";
import { getCohortName } from "../../../utils/common.utils";
import { Button } from "../../../../../shared";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import InputLabel from "components/shared/InputLabel/InputLabel";

export default function AdSetDetailsModal({
  show,
  onHide,
  toggleDetailedTargetModal,
}: {
  show: boolean;
  onHide: () => void;
  toggleDetailedTargetModal: any;
}) {
  const { selectedAd } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const getComaSeparatedDate = (fields: any) => {
    const value = fields.map((element: any) => element.name);
    return value.splice(0, 3).join(",");
  };
  const { adAccountsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );

  const adLink = `https://business.facebook.com/adsmanager/manage/ads?act=${adAccountsSelection?.selectedAdAccountId}&filter_set=SEARCH_BY_ADGROUP_IDS-STRING_SET%1EANY%1E[%22${selectedAd.id}%22]`;
  const { adsetDetails } = useSelector(
    (state: { caiFBReducer: any }) => state.caiFBReducer
  );

  return (
    <CustomizedDialogs
      show={show}
      handleClose={onHide}
      type="modal"
      footer={true}
      maxWidth="sm"
      title={
        selectedAd?.name.length > 35
          ? selectedAd?.name?.slice(0, 35) + "..."
          : selectedAd?.name
      }
      titleSize={22}
      subTitle={`Part of Ad Set: ${adsetDetails?.facebookData?.name ?? "-"}`}
      subTitleSize={18}
      isHeightRequired={false}
      buttons={
        <Button
          buttonVariant="contained"
          sx={{ margin: "0.5rem" }}
          onClick={() => {
            window.open(adLink, "_blank");
          }}
        >
          View Ad in Meta
        </Button>
      }
      dialogStyleProp={{ padding: "20px 20px 0px 20px", borderRadius: "10px" }}
    >
      <Box display="flex" flexDirection="column" mt="-8px" width="450px">
        {/* <AdSetDetailsModalItem
          heading="Ad-set"
          tooltipText="Testing again"
          subHeading={[COHORT_DATA?.adset?.name]}
        /> */}
        <Box display="flex" mb="20px" mt="20px">
          <AdSetDetailsModalItem
            heading="Cohort"
            tooltipText="Cohort details"
            subHeading={[
              adsetDetails?.adset?.cohortName.length
                ? adsetDetails?.adset?.cohortName
                : "-",
            ]}
          />
          <AdSetDetailsModalItem
            heading="Age"
            tooltipText="Age"
            subHeading={[
              `${adsetDetails?.facebookData?.targeting?.age_min ?? ""} - ${
                adsetDetails?.facebookData?.targeting?.age_max ?? ""
              }`,
            ]}
          />
        </Box>
        <Box display="flex">
          <AdSetDetailsModalItem
            heading="Gender"
            tooltipText="Gender"
            subHeading={[
              adsetDetails?.facebookData?.targeting?.genders &&
              adsetDetails?.facebookData?.targeting?.genders[0] === 1
                ? "Male"
                : adsetDetails?.facebookData?.targeting?.genders &&
                  adsetDetails?.facebookData?.targeting?.genders[0] === 2
                ? "Female"
                : "All",
            ]}
          />
          <StyledVariantItem>
            <InputLabel
              label="Detailed Targeting"
              tooltipText="Detailed Targeting"
              textColor="#7A7A7A"
            />

            {adsetDetails?.facebookData?.targeting?.flexible_spec ? (
              <span className="d-flex">
                <StyledVariantItemPara>
                  {adsetDetails?.facebookData?.targeting?.flexible_spec[0]
                    ?.interests &&
                    getComaSeparatedDate(
                      adsetDetails?.facebookData?.targeting?.flexible_spec[0]
                        ?.interests
                    )}
                </StyledVariantItemPara>
                <StyledLinkViewMore
                  to="#"
                  onClick={() => toggleDetailedTargetModal()}
                >
                  {" "}
                  View more
                </StyledLinkViewMore>
              </span>
            ) : (
              "-"
            )}
          </StyledVariantItem>
        </Box>
      </Box>
    </CustomizedDialogs>
  );
}
