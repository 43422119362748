import {
  Box,
  IconButton,
  Popover,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as FilterArrow } from "../../assets/filter.svg";

import { ReactComponent as RightArrow } from "../../assets/Next.svg";
import { Grid, Divider } from "@mui/material";

interface Props {
  setShowFilterDropdown: (val: boolean) => void;
  onExitClick: () => void;
  onApplyClick: () => void;
  clearAll?: boolean;
  onClearAllClick?: () => void;
  showFilterDropdown: boolean;
  disabled?: boolean;
}

const FilterWrapper: React.FC<Props> = ({
  children,
  clearAll = false,
  onClearAllClick,
  onApplyClick,
  disabled,
}) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState<any>(null);
  const actionsOpen = Boolean(actionsAnchorEl);
  const actionsId = actionsOpen ? "actions-simple-popover" : null;
  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };
  const handleFilterClick = (event: any) => {
    setActionsAnchorEl(event.currentTarget);
  };
  return (
    <>
      <IconButton
        onClick={handleFilterClick}
        sx={{
          borderRadius: "4px",
          border: "1px solid #DDDDDD",
          p: 0,
          height: "2.5rem",
          px: 1,
          opacity: disabled ? 0.5 : 1,
        }}
        disabled={disabled}
      >
        <FilterArrow />
      </IconButton>
      {!disabled && (
        <Popover
          sx={{
            "& .MuiPaper-elevation8": { borderRadius: "0.5rem" },
          }}
          id={actionsId || undefined}
          open={actionsOpen}
          anchorEl={actionsAnchorEl}
          onClose={handleActionsClose}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
        >
          <Stack sx={{ width: "18rem" }}>
            <Box>
              <Grid
                container
                px={2}
                py={0.5}
                sx={{ width: "100% !important" }}
                alignItems="center"
              >
                <Grid item xs={10}>
                  <Typography className="font-12 font-weight-600">
                    Filters
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      handleActionsClose();
                    }}
                  >
                    <RightArrow />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider
                variant="middle"
                sx={{
                  mx: 2,
                }}
              />
              <Box sx={{ p: 2 }}>{children}</Box>
              <Grid
                container
                sx={{
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "end",
                  boxShadow: "0px -1px 12px rgba(0, 0, 0, 0.08)",
                }}
              >
                {clearAll && (
                  <Grid item xs={6}>
                    <Button
                      className="font-12 font-600"
                      sx={{ textTransform: "none" }}
                      onClick={onClearAllClick}
                    >
                      Clear All
                    </Button>
                  </Grid>
                )}
                <Grid item xs={6} textAlign="end">
                  <Button
                    variant="contained"
                    className="font-12 font-600"
                    disableRipple
                    sx={{
                      padding: "0.25rem 1.25rem",
                      borderRadius: "0.5rem",
                      background: "#0869FB",
                      color: "#fff",
                    }}
                    onClick={() => {
                      onApplyClick();
                      handleActionsClose();
                    }}
                  >
                    <Typography
                      className="font-14 font-weight-600"
                      sx={{ color: "#FFF", textTransform: "none" }}
                    >
                      Apply
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Popover>
      )}
    </>
  );
};

export default FilterWrapper;
