import React from "react";
import Tooltip from "../Tooltip/Tooltip";
import "./Radio.scss";

const Radio: React.FC<{
  data: any;
  name: string;
  defaultSelected: any;
  onChange: (value: any, data?: any) => void;
  className?: string;
  disable?: boolean;
  showNameTooltip?: boolean;
  showExtraLabelTooltip?: boolean;
}> = ({
  data,
  name,
  defaultSelected,
  onChange,
  className,
  disable,
  showNameTooltip,
  showExtraLabelTooltip,
}) => {
  return (
    <div className={`${className} radio`}>
      {data.map((value: any) => {
        return (
          <label className="text-md radio-container">
            <div className="radio-label-container">
              <input
                data-id={value.id}
                type="radio"
                checked={defaultSelected === value.value}
                name={name}
                id={value.value}
                value={value.value}
                className="radio-input"
                disabled={disable}
                onChange={(e: any) => {
                  onChange(e, data);
                }}
                defaultChecked={value.defaultSelected ? true : false}
              />
              {showNameTooltip ? (
                <div className="radio-label">
                  <div className="radio-heading text-truncate">{value.name} </div>
                  <div className="radio-description text-md text-truncate">{value.extralabel}</div>
                  <div className="radio-popup">
                    <div className="radio-heading">{value.name}</div>
                    <div className="radio-description text-md">{value.extralabel}</div>
                  </div>
                </div>
              ): (
              <div className="radio-label">
                {value.name}
              </div>)}
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default Radio;
