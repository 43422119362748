import React from "react";
import { useFieldArray } from "react-hook-form";
import { Headlines as Container } from "./styles.ConfigureAd";
import Input from "./Input";
import GenerationType from "./GenerationType";

type Props = {
  register: any;
  control: any;
  errors: any;
  watch: any;
};

function Headlines({ register, control, errors, watch }: Props) {
  const { fields } = useFieldArray({
    control,
    name: "reference_headline",
  });

  const values = watch("generation_type.headlines");

  return (
    <Container>
      <div>Reference Headline</div>
      {fields.map((field, index) => (
        <Input
          key={field.id}
          name={`reference_headline.${index}.value`}
          register={register}
          placeholder="Enter reference Headline"
          error={
            errors?.reference_headline &&
            errors?.reference_headline.length &&
            errors.reference_headline[index]
          }
        />
      ))}
      <GenerationType
        dataList={[
          {
            key: "generic",
            label: "Generic",
          },
          {
            key: "brand",
            label: "Brand Focussed",
          },
          {
            key: "interest",
            label: "Interest Based",
          },
          {
            key: "benefit",
            label: "Benefit Based",
            placeholder: "Enter Benefit",
          },
        ]}
        name="generation_type.headlines"
        register={register}
        type="headlines"
        errors={errors}
        values={values}
      />
    </Container>
  );
}

export default Headlines;
