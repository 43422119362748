import {
  StyledLoaderContainer,
  StyledLoaderGifContainer,
  StyledMessage,
  StyledProgress,
  StyledProgressContainer,
} from "./styled";
import { ReactComponent as SandLoader } from "../../../../../assets/cai/svg/SandTimer.svg";
import { ReactComponent as Success } from "../../../../../assets/cai/svg/success.svg";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
interface LoaderProps {
  src: any;
  message: string;
  index: number;
  width: string;
  selected: number;
  progress: number;
}
function LoaderComponent({
  src,
  message,
  index,
  width,
  selected,
  progress,
}: LoaderProps) {
  return (
    <StyledLoaderContainer style={{ width }}>
      <StyledLoaderGifContainer inProgress={index === selected} src={src} />
      <StyledProgressContainer>
        {index === selected ? (
          <StyledProgress>
            {progress !== 100 ? (
              <CircularProgressWithLabel
                value={progress}
                sx={{ width: "100%" }}
              />
            ) : (
              <Success width="100%" />
            )}
          </StyledProgress>
        ) : index < selected ? (
          <StyledProgress>
            <Success width="100%" />
          </StyledProgress>
        ) : (
          <StyledProgress>
            <SandLoader width="90%" />
          </StyledProgress>
        )}
        <StyledMessage inProgress={index === selected}>{message}</StyledMessage>
      </StyledProgressContainer>
    </StyledLoaderContainer>
  );
}

export default LoaderComponent;
