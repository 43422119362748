import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .selected-value {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #ebebeb;
    padding: 12px 10px;
    background: #fff;
    border-radius: 8px;
    width: auto;
    cursor: pointer;
    gap: 14px;
    user-select: none;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 51px;
      gap: 6px;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        color: #000000;
        width: 100%;
      }
    }
  }

  div.list-container {
    position: relative;
    z-index: 2023;
    position: absolute;
    top: calc(100% + 1px);
    right: 0px;
    width: 267px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #ffffff;
    border: 1px solid #e7e9f1;
    box-shadow: 1px 2px 11px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    div.name-container {
      display: flex;
      align-items: center;
      color: #333333;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      width: 90%;
      padding: 16px 16px 8px;
      margin: 0px 10px;
      border-bottom: 1px solid #ebebeb;
    }
    div.footer {
      position: absolute;
      bottom: -2px;
      margin-bottom: 3px;
      margin-top: 3px;
      background: #ffffff;
      height: 44px;
      text-align: right;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      min-height: 27px;
      width: 100%;
      padding: 5px 16px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top: 1px solid #ebebeb;
    }
  }
`;
