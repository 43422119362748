import React from "react";
import Drawer from "@mui/material/Drawer";
import { Box, Grid, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import { amountValue } from "utils/commonFunctions";
import CustomChip from "./Chip";
import { TABSHEADING } from "../../constants/youtubeIndex";
import { ReactComponent as YTRound } from "../../assets/youtube/YTRound.svg";
import { Months } from "utils/constants";

// import CohortType from "common/CohortType";

const TabsStyle = styled(Tab)(({ theme }) => ({
  marginRight: "0.5rem",
  textTransform: "none",
  fontSize: "0.75rem",

  "&.Mui-selected": {
    borderBottom: "1.5px solid #0869FB",
    borderRadius: "0.5rem",
    backgroundColor: "rgba(8, 105, 251, 0.1)",
  },
}));
function IndividualStackRowSummary({ title, value }: any) {
  return (
    <Stack
      direction="row"
      sx={{ alignItems: "center", justifyContent: "space-between" }}
    >
      <Typography className="font-400 font-12 " sx={{ color: "#666666" }}>
        {title}
      </Typography>
      <Stack>
        <Typography
          className={`font-500 font-14 text-align-right`}
          sx={{ color: "#333333", width: "13rem", textAlign: "right" }}
          title={value}
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
}

function IndividualStackRow({ title, value }: any) {
  return (
    <Stack
      direction="row"
      sx={{ alignItems: "center", justifyContent: "space-between" }}
    >
      <Typography className="font-400 font-12" sx={{ color: "#666666" }}>
        {title}
      </Typography>
      <Typography className="font-500 font-14" sx={{ color: "#333333" }}>
        {value}
      </Typography>
    </Stack>
  );
}

function SummaryBlock({ type, selectedCohort }: any) {
  const getDate = () => {
    const publishDate = new Date(selectedCohort.updatedAt);
    return (
      publishDate.getDate() +
      " " +
      Months[publishDate.getMonth() + 1] +
      ", " +
      publishDate.getFullYear()
    );
  };
  return (
    <Stack
      spacing={3}
      sx={{
        px: 1.5,
      }}
    >
      <IndividualStackRow title="Recommended On" value={getDate()} />
      <IndividualStackRow
        title="Relevance Score"
        value={(
          selectedCohort.data.relevance ??
          selectedCohort.data.selected_video_relevance
        ).toFixed(2)}
      />

      {type === "video" ? (
        <IndividualStackRow
          title="Total Videos"
          value={selectedCohort.data.video_count}
        />
      ) : (
        <IndividualStackRowSummary
          title="Total Subscribers"
          value={selectedCohort.data.channel_subscriber_count}
        />
      )}
      {type === "video" ? (
        <IndividualStackRowSummary
          title="View Count"
          value={selectedCohort.data.view_count}
        />
      ) : (
        <IndividualStackRowSummary
          title="Total Videos & Views"
          value={`${selectedCohort.data.channel_video_count} (${amountValue(
            selectedCohort.data.channel_view_count
          )})`}
        />
      )}
      <Stack sx={{ mt: 2 }}>
        <Box
          sx={{
            minHeight: "12rem",
            maxHeight: "12rem",
            backgroundColor: "#F5F9FF",
            borderRadius: "0.5rem",
            padding: "0.5rem",
            overflow: "auto",
          }}
        >
          {type === "channel" &&
            Object.keys(
              JSON.parse(
                typeof selectedCohort.data.selected_video_tags === "string"
                  ? selectedCohort.data.selected_video_tags.replaceAll(`'`, `"`)
                  : "{}"
              )
            ).map((key: string) => (
              <CustomChip
                color="#0869FB"
                bgColor="#0869fb1a"
                margin="0.25rem"
                label={key}
                key={key}
              />
            ))}
          {type === "video" &&
            selectedCohort.data.titles
              .split(";")
              .map((key: string, index: number) => (
                <Grid container spacing={0.5}>
                  <Grid item xs={11}>
                    <CustomChip
                      color="#0869FB"
                      bgColor="#0869fb1a"
                      margin="0.25rem"
                      label={key.trim()}
                      key={key.trim()}
                      sx={{ width: "100%", textAlign: "left" }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <OpenInNewOutlinedIcon
                      sx={{
                        zIndex: "2",
                        color: "#0869FB",
                        background: "#E6F6FF",
                        padding: "0.21rem 0.25rem",
                        borderRadius: "0.25rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(selectedCohort.data.video_links[index]);
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
        </Box>
      </Stack>
    </Stack>
  );
}

function YtDrawer({ isOpen, setIsOpen, type, selectedCohort }: any) {
  const title =
    type === "video"
      ? selectedCohort.data.titles.split(";")[0].trim()
      : selectedCohort.data.channel_title;
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sx={{
        "& .MuiPaper-elevation": {
          width: "22rem",
          top: "20%",
          borderRadius: "0.5rem",
          border: "0.063rem solid #99D9FD",
          marginRight: "1rem",
        },
      }}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "80%",
          maxHeight: "80%",
          py: 1.5,
        }}
      >
        <Box
          sx={{
            minHeight: "80%",
            maxHeight: "80%",
            width: "100%",
            pb: 2,
          }}
        >
          <Stack direction="row" sx={{ alignItems: "center", pb: 2, px: 1.5 }}>
            <YTRound />
            <Typography
              paddingX={1.5}
              className="font-16 font-600"
              sx={{ width: "100%", color: "#333" }}
              noWrap={true}
              title={title}
            >
              {title}
            </Typography>
            <CloseIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </Stack>
          <Stack>
            <Tabs
              value={"summary"}
              sx={{
                px: 1.5,
                minHeight: "0 !important",
                "& *": { minHeight: "0 !important" },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                "& button": {
                  py: 2,
                  height: "10px",
                },
              }}
            >
              {TABSHEADING.map((tab: any) => (
                <TabsStyle
                  key={tab.value}
                  disableRipple
                  value={tab.value}
                  label={tab.label}
                />
              ))}
            </Tabs>
          </Stack>
          <Divider
            sx={{ border: "0.063rem solid #F0F0F0", marginY: "0.5rem" }}
          />
          <SummaryBlock type={type} selectedCohort={selectedCohort} />
        </Box>
      </Stack>
    </Drawer>
  );
}

export default YtDrawer;
