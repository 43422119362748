import { Box } from "@mui/material";
import LoaderGif from "./assets/loader.gif";

export default function BudgetPacingLoader() {
  return (
    <>
      <Box
        className="container-overlay"
        sx={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: "1033",
          top: "0",
          left: "0",
        }}
      >
        <Box
          className="d-flex align-items-center justify-content-center"
          sx={{
            width: "6em",
            height: "6em",
            border: "10px solid #E7F0FF",
            borderRadius: "50%",
            backgroundColor: "#0869FB",
            position: "absolute",
            top: "45%",
            left: "50%",
            zIndex: "9999999999",
          }}
        >
          <img
            src={LoaderGif}
            alt="loader"
            style={{ width: "3.5em", height: "3.5em" }}
          ></img>
        </Box>
      </Box>
    </>
  );
}
