import { sumObjectsByKey } from 'Pages/SmbDashboard/utils';
import {
  GET_ADACCOUNT_SUMMARY,
  GetAdAccountSummary,
  GET_BRAND_LEVEL_METRICS,
  GetBrandLevelMetrics,
  UPDATE_RESULT_METRICS_AND_BUDGET,
  UpdateResultMetricsAndBudget,
  SET_AD_ACCOUNT_METRICS,
  SetAdAccountMetrics,
  RESET_AD_ACCOUNT_METRICS,
  ResetAdAccountMetrics,
} from '../../types/SmbDashboard/adAccount';

const initialState: any = {
  adAccountMetricsData: {
    adAccountCount: 0,
    adAccountTypes: {
      good: 0,
      alarming: 0,
      escalation: 0,
      inline: 0,
    },
  },
};

export default function audienceManager(
  state = initialState,
  action:
    | GetAdAccountSummary
    | GetBrandLevelMetrics
    | UpdateResultMetricsAndBudget
    | SetAdAccountMetrics
    | ResetAdAccountMetrics
): any {
  switch (action.type) {
    case GET_ADACCOUNT_SUMMARY: {
      return { ...state };
    }
    case GET_BRAND_LEVEL_METRICS: {
      return { ...state };
    }
    case UPDATE_RESULT_METRICS_AND_BUDGET: {
      return { ...state };
    }
    case RESET_AD_ACCOUNT_METRICS: {
      return {
        ...state,
        adAccountMetricsData: {
          adAccountCount: 0,
          adAccountTypes: {
            good: 0,
            alarming: 0,
            escalation: 0,
            inline: 0,
          },
        },
      };
    }
    case SET_AD_ACCOUNT_METRICS: {
      return {
        ...state,
        adAccountMetricsData: {
          ...state.adAccountMetricsData,
          adAccountCount:
            (action?.payload?.accountDetails?.length || 0) +
            state?.adAccountMetricsData?.adAccountCount,
          [action.payload.brandId]: action.payload,
          // adAccountTypes: sumObjectsByKey(
          //   state?.adAccountMetricsData?.adAccountTypes,
          //   action.payload.adAccountTypes
          // ),
        },
      };
    }
    default:
      return state;
  }
}
