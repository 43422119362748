import React from "react";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import Typography from "@mui/material/Typography";
import success from "../../assets/tai/success.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: 24,
  borderRadius: "8px",
  p: 2,
  pb: 4,
};
const CreateParentModal: React.FC<any> = ({
  open,
  setOpen,
  heading,
  subHeading,
  onSuccess,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Stack
        spacing={1}
        alignItems={"center"}
        justifyContent={"center"}
        height={290}
        width={412}
        sx={style}
      >
        <Box className="d-flex justify-content-end align-items-center ml-auto">
          <IconButton
            sx={{
              background: "#F2F2F2",
              borderRadius: "0.25rem",
              textAlign: "center",
            }}
            color="primary"
            aria-label="Close"
            component="label"
            onClick={() => {
              onSuccess();
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <img
          src={success}
          style={{ height: "7.25rem", width: "7.25rem" }}
          alt="success"
        />
        <Typography
          sx={{
            fontSize: "1.875rem",
            fontFamily: "Lato",
            color: "#333333",
            textAlign: "center",
            fontWeight: 700,
          }}
        >
          {heading}
        </Typography>
        {subHeading && (
          <Typography
            sx={{
              fontSize: "0.75rem",
              fontFamily: "Lato",
              px: 4,
              textAlign: "center",
              color: "#6F6F6F",
              fontWeight: 500,
            }}
          >
            {subHeading}
          </Typography>
        )}
      </Stack>
    </Modal>
  );
};

export default CreateParentModal;
