import styled from "styled-components";
import { ConfirmDialog } from "../../../../shared";
import CustomizedDialogs from "../../../../shared/Modal/CustomizedDialogs";

export const StyledDrawerDiv = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-height: calc(100% - 8.4375em);
  padding-top: 1.5em;
  .drawer-panel {
    // border: 1px solid #dadce0;
    border: 0.0625em solid #ebebeb;
    box-sizing: border-box;
    // box-shadow: 0em 0.25em 0.25em rgba(0, 0, 0, 0.08);
    box-shadow: 0em 0.25em 0.375em rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    /* width: 100%; */
    padding: 1.5em;
    overflow: scroll;
  }
  .drawer-right-panel {
    padding: 0em 1.5em 1.5em;
    margin-left: 1.5em;
    background: #f5f6f7;
  }
`;

export const StyledEditorGifContainer = styled.div`
  // width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const StyledEditorGif = styled.img`
  width: 21.875em;
  height: 21.875em;
`;
export const StyledGreatBestTxt = styled.p`
  margin-bottom: 0.5em;
  font-family: Lato;
  font-style: normal;
  // font-weight: 500;
  font-size: 1.25em;
  // width: 414px;
`;
export const StyledAISettingTxt = styled.p`
  margin-bottom: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125em;
  color: #a5a5a5;
  // width: 328px;
`;
export const StyledAIProvideTxt = styled.p`
  margin-bottom: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125em;
  color: #a5a5a5;
  // width: 201px;
`;
export const StyledDrawer = styled.div`
  position: fixed !important;
  width: 96%;

  &.full-width {
    width: 100%;
  }
`;

export const StyledMediaLoader = styled.div`
  position: absolute;
  height: 105vh;
  width: 110vw;
  top:0;
  left:-24vw;
  opacity:0;
  cursor:wait;
  display:flex;
  justify-content:center;
  align-items:center;
  z-index: 2000000;
}}`;
// Error Screen

export const StyledCustomizedDialog = styled(ConfirmDialog)`
  width: 50%;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
  width: 100%;
  .RestartButton {
    border-radius: 0.5em;
    font-family: Inter;
    border: 0.125em solid #0869fb;
    width: 50%;
    &:hover {
      border: 0.125em solid #0869fb;
    }
  }
  .RevertButton {
    width: 50%;
    margin-left: 1em;
  }
`;
export const StyledErrorImage = styled.img`
  margin-right: auto;
  margin-left: auto;
`;
export const StyledDialogContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  h3 {
    text-align: center;
    margin-top: 1rem;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 1.375em;
  }
  p {
    // display:block;
    text-align: center;
    width: 100%;
    color: #6f6f6f;
  }
`;
export const StyledEditorBody = styled.div`
  flex: 1;
  min-height: 0px;
  display: flex;
  padding: 3.3vh 0px;
`;
export const StyledToolTip = styled.div`
  position: absolute;
  top: 2.5rem;
  left: 110%;
`;
