import React from "react";
import { addDays, sub } from "date-fns";
import moment from "moment/moment";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./index.scss";

const DateRangePickerUI: React.FC<{
  className?: string;
  dateSelection?: any;
  setDateSelection: (value: any) => void;
  onSelect: (value: any) => void;
  maxLimit: number;
  minLimit: number;
  isPlatformTikTok?: boolean;
}> = ({
  className,
  dateSelection,
  setDateSelection,
  maxLimit,
  minLimit,
  onSelect,
  isPlatformTikTok,
}) => {
  return (
    <div
      className={`${className} date-range-picker-container`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DateRangePicker
        onChange={(item: any) => {
          if (
            isPlatformTikTok &&
            moment(item.selection.endDate).diff(
              moment(item.selection.startDate),
              "days"
            ) > 24
          ) {
            alert(
              "For platform Tiktok, Maximum range of 25 days can be selected"
            );
            setDateSelection(dateSelection);
          } else {
            if (moment(item.selection.endDate).isAfter(new Date(), "days")) {
              setDateSelection([{ ...item.selection, endDate: new Date() }]);
            } else if (
              moment(item.selection.startDate).isBefore(
                sub(new Date(), { days: minLimit })
              )
            ) {
              setDateSelection([
                {
                  ...item.selection,
                  startDate: sub(new Date(), { days: minLimit }),
                },
              ]);
            } else {
              setDateSelection([item.selection]);
            }
          }
        }}
        moveRangeOnFirstSelection={false}
        months={2}
        minDate={addDays(new Date(), -minLimit)}
        maxDate={addDays(new Date(), maxLimit)}
        ranges={dateSelection}
        direction="horizontal"
      />
      <div className="select-date-container">
        <button className="select-date btn primary" onClick={onSelect}>
          Select
        </button>
      </div>
    </div>
  );
};

export default DateRangePickerUI;
