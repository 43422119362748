import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { GroupType } from "types/GlobalTypes";
import { ReactComponent as InfoIcon } from "assets/svg/info_grey.svg";
import moment from "moment";
import { calcualteTimeOffset } from "utils/date.util";
import {
  getRolesHierarchy,
  isEditorRole,
  isViewerRole,
} from "utils/commonFunctions";
import { AdAccountState } from "store/types/adaccount";
import { RolesWrapper } from "utils/rolesWrapper";

export default function BudgetPlannerFooter({
  difference,
  updateChanges,
  isCalendarUpdated,
  type,
  selectedGroup,
  groups,
}: any) {
  const { brandRoles } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const isDisabled = difference !== 0 || !isCalendarUpdated;
  // ||
  // isViewerRole(brandRoles) ||
  // isEditorRole(brandRoles);
  return (
    <Box
      className={"d-flex align-items-center justify-content-between"}
      sx={{
        width: "95%",
        height: "4.375rem",
        boxShadow: "0px 0px 14.6591px rgba(0, 0, 0, 0.08)",
        borderRadius: "0px 0px 12.2159px 12.2159px",
        position: "fixed",
        bottom: "0px",
        background: "white",
      }}
    >
      <Typography component={"p"} className="ml-3">
        <InfoIcon className="mr-2" />
        This feature is working in{" "}
        <b>
          {type === GroupType.group ? groups[selectedGroup]?.timeZone : "EST"}:
          UTC
          {type === GroupType.group
            ? calcualteTimeOffset(groups[selectedGroup]?.timeZoneOffSet)
            : "-05:00"}
        </b>
      </Typography>
      <RolesWrapper
        disabled={
          getRolesHierarchy(brandRoles) === "viewer" ||
          getRolesHierarchy(brandRoles) === "editor"
        }
      >
        <Typography
          component={"button"}
          className="d-flex align-items-center justify-content-center"
          sx={{
            width: "11.0625rem",
            height: "2.5rem",
            background: "#0869FB",
            borderRadius: "5px",
            p: "14.6591px 29.3181px",
            gap: "9.77px",
            color: "white",
            textTransform: "capitalize",
            mr: "1.2rem",
            border: "none",
          }}
          style={{ opacity: isDisabled ? 0.5 : 1 }}
          disabled={isDisabled}
          onClick={() => {
            updateChanges();
          }}
        >
          Save Changes
        </Typography>
      </RolesWrapper>
    </Box>
  );
}
