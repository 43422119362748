import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Typography } from "@mui/material";
import { ReactComponent as InfoIcon } from "assets/svg/info_grey.svg";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";

type TypeOfBudgetConstraintType = {
  control: any;
  subPacingType: string;
  setSubPacingType: Function;
  setValue: Function;
  status: string;
  onConstraintTypeChange: Function;
};

function BudgetConstraintType({
  control,
  subPacingType,
  setSubPacingType,
  setValue,
  status,
  onConstraintTypeChange,
}: TypeOfBudgetConstraintType) {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    onConstraintTypeChange(newAlignment);
    setValue("subPacingType", newAlignment);
    setSubPacingType(newAlignment);
  };

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <div className="constraint-type">
      <Typography className="title-text">
        Enter Budget
        <BootstrapTooltip
          placement="top"
          title="You can set your constraints either for the Entire Period or on a Daily Basis"
        >
          <InfoIcon
            style={{
              marginLeft: "10px",
            }}
          />
        </BootstrapTooltip>
      </Typography>
      <Controller
        name={`subPacingType`}
        control={control}
        render={({ field }: any) => {
          // sending integer instead of string.
          return (
            <ToggleButtonGroup
              color="primary"
              value={subPacingType}
              exclusive
              onChange={handleChange}
              aria-label="subPacingType"
              disabled={status === "ACTIVE" || status === "UNCONFIGURED"}
            >
              <ToggleButton value="DAILY">Daily</ToggleButton>
              <ToggleButton value="PERIODIC">Entire Period</ToggleButton>
            </ToggleButtonGroup>
          );
        }}
      ></Controller>
    </div>
  );
}

export default BudgetConstraintType;
