import { differenceInCalendarDays, sub } from "date-fns";
import {
  ActionDebuggerInitStateType,
  SetActionDebuggerSwitchType,
  SetAllMetrics,
  SetAttributionWindow,
  SetAttributionWindowElementId,
  SetAttributionWindowLoader,
  SetAttributionWindowType,
  SetChangeMetricFinish,
  SetCompareType,
  SetCurrentGraphLoader,
  SetCurrentPeriod,
  SetElementMetrics,
  SetGraphMetrics,
  SetGraphUpdateLoader,
  SetIsDefault,
  SetMetricGraphData,
  SetMetricLoader,
  SetMetricMapper,
  SetMetricOldGraphData,
  SetPreviousGraphLoader,
  SetPreviousPeriod,
  SET_ACTION_DEBUGGER_SWITCH,
  SET_ALL_METRICS,
  SET_ATTRIBUTION_WINDOW,
  SET_ATTRIBUTION_WINDOW_ELEMENT_ID,
  SET_ATTRIBUTION_WINDOW_LOADER,
  SET_ATTRIBUTION_WINDOW_TYPE,
  SET_CHANGE_METRIC_FINISH,
  SET_COMPARE_TYPE,
  SET_CURRENT_PERIOD,
  SET_ELEMENT_METRICS,
  SET_GRAPH_METRICS,
  SET_GRAPH_UPDATE_LOADER,
  SET_IS_DEFAULT,
  SET_METRIC_GRAPH_DATA,
  SET_METRIC_GRAPH_DATA_LOADER,
  SET_METRIC_LOADER,
  SET_METRIC_MAPPER,
  SET_METRIC_OLD_GRAPH_DATA,
  SET_METRIC_OLD_GRAPH_DATA_LOADER,
  SET_PREVIOUS_PERIOD,
  ToggleCompareFlag,
  TOGGLE_COMPARE_FLAG,
} from "../../types/ActionDebugger/ActionDebugger";

const initialState: ActionDebuggerInitStateType = {
  actionDebuggerSwitch: false,
  isCompare: true,
  currentPeriod: {
    startDate: sub(new Date(), { days: 14 }),
    endDate: new Date(),
    editedBy: "Calender",
  },
  previousPeriod: {
    startDate: sub(new Date(), { days: 29 }),
    endDate: sub(new Date(), { days: 15 }),
    editedBy: "Calender",
  },
  compareType: 0,
  additionalDateInfo: {
    differenceInCalenderDay: 15,
    paddedDay: 2,
  },
  allMetrics: [],
  elementMetrics: [],
  metricGraphData: [],
  metricOldGraphData: [],
  metricMapper: {},
  graphUpdateLoader: false,
  metricLoader: false,
  attributionWindow: "",
  currentGraphLoading: false,
  previousGraphLoading: false,
  isDefault: true,
  graphMetrics: [],
  attributionWindowType: [],
  attributionLoader: false,
  changeMetricFinish: false,
  attributionWindowElementId: "",
};

const ActionDebuggerReducer = (
  state = initialState,
  action:
    | ToggleCompareFlag
    | SetActionDebuggerSwitchType
    | SetCurrentPeriod
    | SetPreviousPeriod
    | SetCompareType
    | SetAllMetrics
    | SetElementMetrics
    | SetMetricGraphData
    | SetMetricOldGraphData
    | SetMetricMapper
    | SetGraphUpdateLoader
    | SetMetricLoader
    | SetAttributionWindow
    | SetCurrentGraphLoader
    | SetPreviousGraphLoader
    | SetIsDefault
    | SetGraphMetrics
    | SetAttributionWindowType
    | SetAttributionWindowLoader
    | SetChangeMetricFinish
    | SetAttributionWindowElementId
) => {
  switch (action.type) {
    case SET_ACTION_DEBUGGER_SWITCH: {
      return {
        ...state,
        actionDebuggerSwitch: action.payload,
      };
    }
    case TOGGLE_COMPARE_FLAG: {
      return {
        ...state,
        isCompare: action.payload,
      };
    }

    case SET_CURRENT_PERIOD: {
      const { startDate, endDate, editedBy } = action.payload;
      let additionalDateInfo = { ...state.additionalDateInfo };
      if (editedBy === "Calender") {
        const n = differenceInCalendarDays(endDate, startDate) + 1;
        const nPercentage = Math.round(n * 0.1);
        additionalDateInfo = {
          differenceInCalenderDay: n,
          paddedDay: nPercentage > 0 ? nPercentage : 1,
        };
      }

      return {
        ...state,
        currentPeriod: action.payload,
        additionalDateInfo,
      };
    }

    case SET_PREVIOUS_PERIOD: {
      return {
        ...state,
        previousPeriod: action.payload,
      };
    }
    case SET_COMPARE_TYPE: {
      return {
        ...state,
        compareType: action.payload,
      };
    }
    case SET_ALL_METRICS: {
      return {
        ...state,
        allMetrics: action.payload,
      };
    }
    case SET_ELEMENT_METRICS: {
      return {
        ...state,
        elementMetrics: action.payload,
      };
    }
    case SET_METRIC_GRAPH_DATA: {
      return {
        ...state,
        metricGraphData: action.payload,
        currentGraphLoading: false,
      };
    }
    case SET_METRIC_OLD_GRAPH_DATA: {
      return {
        ...state,
        metricOldGraphData: action.payload,
        previousGraphLoading: false,
      };
    }
    case SET_METRIC_GRAPH_DATA_LOADER: {
      return {
        ...state,
        currentGraphLoading: action.payload,
      };
    }

    case SET_METRIC_OLD_GRAPH_DATA_LOADER: {
      return {
        ...state,
        previousGraphLoading: action.payload,
      };
    }
    case SET_METRIC_MAPPER: {
      return {
        ...state,
        metricMapper: action.payload,
      };
    }
    case SET_GRAPH_UPDATE_LOADER: {
      return {
        ...state,
        graphUpdateLoader: action.payload,
      };
    }
    case SET_METRIC_LOADER: {
      return {
        ...state,
        metricLoader: action.payload,
      };
    }

    case SET_ATTRIBUTION_WINDOW: {
      return {
        ...state,
        attributionWindow: action.payload,
      };
    }
    case SET_IS_DEFAULT: {
      return {
        ...state,
        isDefault: action.payload,
      };
    }
    case SET_GRAPH_METRICS: {
      return {
        ...state,
        graphMetrics: action.payload,
      };
    }
    case SET_ATTRIBUTION_WINDOW_TYPE: {
      return {
        ...state,
        attributionWindowType: action.payload,
      };
    }
    case SET_ATTRIBUTION_WINDOW_LOADER: {
      return {
        ...state,
        attributionLoader: action.payload,
      };
    }
    case SET_CHANGE_METRIC_FINISH: {
      return {
        ...state,
        changeMetricFinish: action.payload,
      };
    }
    case SET_ATTRIBUTION_WINDOW_ELEMENT_ID: {
      return {
        ...state,
        attributionWindowElementId: action.payload,
      };
    }
    default:
      return state;
  }
};
export default ActionDebuggerReducer;
