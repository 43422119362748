import { Box, Pagination, Table, TableCell, TableRow } from "@mui/material";
import styled from "styled-components";

export const StyledTableCellInfo = styled(TableCell)`
  min-width: 200px;
  border: 1px solid #dadcde;
`;
export const StyledTableHeadInfo = styled(TableCell)`
  border: 1px solid #dadcde;
`;

export const StyledPhaseText = styled.span`
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledTableRow = styled(TableRow)`
  th {
    font-size: 18px;
    font-weight: 900;
  }
`;

export const StyledTable = styled(Table)`
  font-size: 16px;
  border-radius: 10px 10px 0px 0px;

  .tableDataRow {
    height: 110px;
    border-right: #dadcde;
  }

  .tableDataRow:nth-child(even) {
    background-color: #f5f6f7;
  }
`;

export const StyledDownloadButton = styled.img`
  position: relative;
  margin-left: 15px;
  top: -9px;
  cursor: pointer;
`;

export const StyledExportContainer = styled.div`
  position: relative;
  top: -50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledDateRangeContainer = styled(Box)`
  position: relative;
  top: -10px;
  height: 100%;

  background-color: #f2f2f2;
  font-size: 14px;
  // padding: 5px 10px;
  padding: 8px 12px;
  text-align: center;
  border-radius: 4px;
  .daterangeSelector {
    z-index: 9999999999999999;

    position: absolute;
    padding: 20px;
    top: 40px;
    /* transform: scale(1.4); */
    right: 20px;
    border: 1px solid black;
    background-color: white;
  }
`;
export const StyledPagination = styled(Pagination)`
  margin-top: 30px;

  ul {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;

    button {
      width: 50px;
      height: 50px;
      color: #bcbcbc;
      font-weight: 700;
      font-size: 18px;
    }
  }
  li:first-child {
    button {
      border: 1px solid #ffffff !important;
    }
  }
  li:last-child {
    button {
      border: 1px solid #ffffff !important;
    }
  }
  .MuiPaginationItem-root.Mui-selected,
  .MuiPaginationItem-root.Mui-selected:hover {
    background-color: #0869fb;
    color: white;
  }
`;
