import {
  GetActionSummary,
  GetKeyIndicators,
  GetPerformanceSummary,
  GET_ACTION_SUMMARY,
  GET_KEY_INDICATORS,
  GET_PERFORMANCE_SUMMARY,
  SetActionSummaryLoader,
  SetKeyIndicatorsLoader,
  SetPerformanceSummaryLoader,
  SetPreviousActionSummaryLoader,
  SetPreviousKeyIndicatorsLoader,
  SetPreviousPerformanceSummaryLoader,
  SET_ACTION_SUMMARY_LOADER,
  SET_KEY_INDICATORS_LOADER,
  SET_PERFORMANCE_SUMMARY_LOADER,
  SET_PREVIOUS_ACTION_SUMMARY_LOADER,
  SET_PREVIOUS_KEY_INDICATORS_LOADER,
  SET_PREVIOUS_PERFORMANCE_SUMMARY_LOADER,
} from "../../types/ActionDebugger/ActionSummary";

export const getActionSummary = (payload: {
  elementId: string;
  start_date: string;
  end_date: string;
  typeOfData: string;
  typeOfLoader: string;
}): GetActionSummary => {
  return {
    type: GET_ACTION_SUMMARY,
    payload,
  };
};

export const setActionSummaryLoader = (
  payload: Boolean
): SetActionSummaryLoader => {
  return {
    type: SET_ACTION_SUMMARY_LOADER,
    payload,
  };
};

export const setPreviousActionSummaryLoader = (
  payload: Boolean
): SetPreviousActionSummaryLoader => {
  return {
    type: SET_PREVIOUS_ACTION_SUMMARY_LOADER,
    payload,
  };
};

export const getPerformanceSummary = (payload: {
  elementId: string;
  start_date: string;
  end_date: string;
  typeOfData: string;
  typeOfLoader: string;
  adsetIds?: any;
  campaignIds?: any;
  adMetricIds?: any;
  attributionWindow: string;
}): GetPerformanceSummary => {
  return {
    type: GET_PERFORMANCE_SUMMARY,
    payload,
  };
};

export const setPerformanceSummaryLoader = (
  payload: Boolean
): SetPerformanceSummaryLoader => {
  return {
    type: SET_PERFORMANCE_SUMMARY_LOADER,
    payload,
  };
};

export const setPreviousPerformanceSummaryLoader = (
  payload: Boolean
): SetPreviousPerformanceSummaryLoader => {
  return {
    type: SET_PREVIOUS_PERFORMANCE_SUMMARY_LOADER,
    payload,
  };
};

export const getKeyIndicators = (payload: {
  elementId: string;
  start_date: string;
  end_date: string;
  typeOfData: string;
  typeOfLoader: string;
  adsetIds?: any;
  campaignIds?: any;
  attributionWindow: string;
}): GetKeyIndicators => {
  return {
    type: GET_KEY_INDICATORS,
    payload,
  };
};

export const setKeyIndicatorsLoader = (
  payload: Boolean
): SetKeyIndicatorsLoader => {
  return {
    type: SET_KEY_INDICATORS_LOADER,
    payload,
  };
};

export const setPreviousKeyIndicatorsLoader = (
  payload: Boolean
): SetPreviousKeyIndicatorsLoader => {
  return {
    type: SET_PREVIOUS_KEY_INDICATORS_LOADER,
    payload,
  };
};
