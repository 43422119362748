import MenuItem from "@mui/material/MenuItem";
import { Controller } from "react-hook-form";
import { StyledSelect, StyledSelectContainer } from "./Select.styled";
import { SelectProps as MUISelectProps } from "@mui/material/Select";
import InputLabel from "../../InputLabel/InputLabel";
import { StyledErrorMessage } from "../../Typography/Heading/styled";
import { ReactComponent as DownIcon } from "../../../../assets/svg/down.svg";
import { SxProps, Theme } from "@mui/material";

export interface SelectProps extends MUISelectProps {
  label?: string;
  backgroundColor?: string;
  isBorder?: boolean;
  helperFunction?: (registeredName: string) => void;
  borderColor?: string;
  tooltipText?: string;
  options?: any;
  height?: string;
  control?: any;
  registeredName?: any;
  field?: string;
  valueKey?: string;
  customDefaultValue?: string;
  labelProps?: SxProps<Theme>;
}

// In case options in an Array of Objects, field prop is required;
export function SelectFormControl({
  label,
  required,
  tooltipText,
  options,
  helperFunction,
  placeholder,
  control,
  registeredName,
  field,
  valueKey,
  customDefaultValue,
  labelProps,
  ...props
}: SelectProps) {
  return (
    <Controller
      control={control}
      name={registeredName}
      defaultValue={customDefaultValue}
      rules={{
        required,
      }}
      render={({
        field: { onChange, value },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <StyledSelectContainer>
          {label && (
            <InputLabel
              required={required}
              label={label}
              tooltipText={tooltipText}
              sx={labelProps}
            />
          )}
          <StyledSelect
            value={value}
            variant="standard"
            onChange={(value) => {
              helperFunction && helperFunction(registeredName);
              onChange(value);
            }}
            disableUnderline
            displayEmpty
            IconComponent={(props) => (
              <i
                {...props}
                className={`material-icons ${props.className}`}
                style={{ top: "12px", right: "11px" }}
              >
                <DownIcon />
              </i>
            )}
            renderValue={(value: any) => {
              if (!value) {
                return <em>{placeholder}</em>;
              } else {
                if (field && valueKey) {
                  const mappedValue = options.find(
                    (option: any) => option[valueKey] === value
                  );
                  return mappedValue ? mappedValue[field] : value;
                }
                return value;
              }
            }}
            {...props}
          >
            {options &&
              options.map((element: any) => (
                <MenuItem value={valueKey ? element[valueKey] : element}>
                  {field ? element[field] : element}
                </MenuItem>
              ))}
          </StyledSelect>
          {error && (
            <StyledErrorMessage>This field is required *</StyledErrorMessage>
          )}
          {error?.type === "apiFailure" && (
            <StyledErrorMessage>{error.message} *</StyledErrorMessage>
          )}
        </StyledSelectContainer>
      )}
    />
  );
}
