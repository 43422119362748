import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  copyVariant,
  deleteVariantAPICall,
  setVariants,
  updateExtraField,
  updateTotalVariantSelected,
  updateVariant,
} from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { getMediaStudioProcessing } from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import {
  COPY_VARIANT,
  CreateVariantsState,
} from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { GetMediaStudioProcessingReduxType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { initialState } from "store/types/auth";
import { checkAdType } from "../../utils/common.utils_old";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { useCaiFbMixPanel } from "./useCaiFbMixPanel";
import { NavigationInitialState } from "store/types/Navigation/navigation.types";
import { copyVariantNameLogic } from "../../utils/common.utils";

export const useVariantCard = ({
  item,
  index,
}: {
  item: any;
  index: number;
}) => {
  //local states
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const [isMediaUrlLoading, setIsMediaUrlLoading] = useState(false);

  const dispatch = useDispatch();

  // redux states
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { adSetSelection, adAccountsSelection, adAdditionalInfo } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const { socket } = useSelector(
    (state: { navigationReducer: NavigationInitialState }) =>
      state.navigationReducer
  );

  const {
    variantList,
    isDeletingVariant,
    totalSelectedVariant,
    createSpec,
    autoGenerateLoading,
    selectedAd,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const { mixPanelCloneVariant, mixPanelDeleteVariant, mixPanelEditVariant } =
    useCaiFbMixPanel();
  useEffect(() => {
    setIsChecked(totalSelectedVariant.includes(item?.id));
  }, [item?.id, totalSelectedVariant]);

  useEffect(() => {
    if (item?.id && selectedAd?.id) {
      if (item?.CreativeAiData?.mediaFileId) {
        setIsMediaUrlLoading(true);
        dispatch(
          getMediaStudioProcessing(
            {
              reqId: [item?.CreativeAiData?.mediaFileId],
            },
            (response: GetMediaStudioProcessingReduxType[]) => {
              setMediaUrl(response[0]?.url);
              setIsMediaUrlLoading(false);
            }
          )
        );
      } else if (item?.CreativeAiData?.billboardMediaId) {
        setMediaUrl(item?.CreativeAiData?.billboardMediaData?.videoUrl);
      } else {
        if (checkAdType(createSpec) === "link_data") {
          setMediaUrl(adAdditionalInfo[selectedAd?.id]?.image_url ?? "");
        } else {
          setMediaUrl(adAdditionalInfo[selectedAd?.id]?.videoUrl ?? "");
        }
      }
    }
  }, [item?.id, item?.CreativeAiData?.mediaFileId]);

  useEffect(() => {
    if (socket) {
      socket.on("CaiBillboardVideoUrl", function (data: any) {
        if (
          data?.mediaStudioFiles[0]?.id ===
          item?.CreativeAiData?.billboardMediaId
        ) {
          setMediaUrl(data?.mediaStudioFiles[0]?.url);
          const localVariantList = [...variantList];

          if (localVariantList[index]?.CreativeAiData?.billboardMediaId) {
            localVariantList[index].variantType =
              data?.mediaStudioFiles[0]?.type;
            if (
              !localVariantList[index]?.CreativeAiData?.billboardMediaData
                ?.videoUrl
            ) {
              localVariantList[index] = {
                ...localVariantList[index],
                CreativeAiData: {
                  ...localVariantList[index].CreativeAiData,
                  billboardMediaData: {
                    ...localVariantList[index].CreativeAiData
                      .billboardMediaData,
                    videoUrl: data?.mediaStudioFiles[0]?.url,
                  },
                },
              };
            }
            dispatch(setVariants({ data: localVariantList }));
            dispatch(
              updateVariant({
                user: auth.user,
                params: {
                  headline: item?.CreativeAiData?.headline,
                  description: item?.CreativeAiData?.description,
                  primaryText: item?.CreativeAiData?.primaryText,
                  adId: selectedAd?.id,
                  variantType: data?.type ?? "billboard",
                  mediaFileId: item?.CreativeAiData?.mediaFileId,
                  payload: { ...item?.payload, isNew: false },
                  performanceScore: item?.performanceScore,
                },
                variantId: item?.id,
              })
            );
          }
        }
      });
    }
  }, [socket]);
  // useEffect(() => {
  //   if (
  //     item?.CreativeAiData?.mediaFileId === null &&
  //     item?.CreativeAiData?.billboardMediaData === null
  //   ) {
  //     if (checkAdType(createSpec) === "link_data") {
  //       setMediaUrl(createSpec?.image_url);
  //     } else {
  //       if (createSpec?.videoUrl) {
  //         setMediaUrl(createSpec?.videoUrl);
  //       }
  //     }
  //   } else if (item?.CreativeAiData?.billboardMediaId) {
  //     setMediaUrl(item?.CreativeAiData?.billboardMediaData?.videoUrl);
  //   }
  // }, [createSpec?.videoUrl, createSpec?.image_url, createSpec]);

  useEffect(() => {
    // TBO
  }, [mediaUrl]);

  const deleteVariant = () => {
    // if (isNew) {
    //   setAutoRecommendationCount((prev: number) => prev - 1);
    // }
    dispatch(
      deleteVariantAPICall({ item: { ...item, index }, user: auth.user })
    );
    mixPanelDeleteVariant(item);
    setShowDeletePopUp(false);
  };

  const onEdit = () => {
    dispatch(
      updateExtraField({
        isVariantEditing: true,
        selectedVariantIndex: index,
        showEditor: true,
        isCloned: false,
      })
    );
    mixPanelEditVariant();
  };

  const onCheckBoxClick = () => {
    const localVariantList = [...variantList];

    localVariantList[index] = {
      ...localVariantList[index],
      checked: !isChecked,
    };
    // TBO
    dispatch(setVariants({ data: localVariantList }));
    dispatch(updateTotalVariantSelected(item?.id));
    setIsChecked(!isChecked);
  };

  const onCopy = () => {
    dispatch(
      copyVariant({
        user: auth.user,
        params: {
          headline: variantList[index].CreativeAiData.headline,
          description: variantList[index].CreativeAiData.description,
          primaryText: variantList[index].CreativeAiData.primaryText,
          mediaFileId:
            variantList[index].CreativeAiData.mediaFileId ?? undefined,
          payload: copyVariantNameLogic(variantList[index].payload),
          adId: selectedAd?.id,
          adsetId: adSetSelection?.selectedAdSetId,
          elementId: adAccountsSelection?.selectedElementId,
          adAccountId: adAccountsSelection?.selectedAdAccountId,
          performanceScore: variantList[index]?.performanceScore,
        },
        adAccountId: adAccountsSelection?.selectedAdAccountId,
        finalAction: COPY_VARIANT,
      })
    );
    mixPanelCloneVariant(variantList[index]);
  };

  return {
    deleteVariant,
    onEdit,
    onCheckBoxClick,
    isDeletingVariant,
    createSpec,
    mediaUrl,
    setMediaUrl,
    showDeletePopUp,
    setShowDeletePopUp,
    isMediaUrlLoading,
    isChecked,
    onCopy,
    adAdditionalInfoData: adAdditionalInfo[selectedAd?.id ?? ""],
  };
};
