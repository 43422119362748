import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreateAdCampaign,
  setClusterSpinner,
  setCreateAdCampaign,
  getCreateAdGroup,
  getCreateAdAds,
  setCreateAdAds,
  setClusterTable,
  setCreateAdAdGroup,
} from "../../../store/actions/cluster";
import { setModal } from "../../../store/actions/form";
import { ClusterState } from "../../../store/types/cluster";
import { FormState } from "../../../store/types/form";
import { initialState } from "../../../store/types/auth";
import Accordion from "../../../ui/Accordion/Accordion";
import InputText from "../../../ui/InputText/InputText";
import Radio from "../../../ui/Radio/Radio";
import Loader from "../../common/Loader";
import ModalContainer from "../../Modal/ModalContainer";
import ModalWrapper from "../../Modal/ModalWrapper";
import CampaignAccordion from "../CampaignAccordion/CampaignAccordion";
import "./createAdGroup.scss";
import { AdAccountState } from "../../../store/types/adaccount";

const CreateAdGroup: React.FC<{
  tooltip: any;
  currentCluster: any;
  currentClusterIndex: number;
}> = ({ tooltip, currentCluster, currentClusterIndex }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const [groupName, setGroupName] = React.useState<string>("New Ad Group 1");
  const [refAdGroup, setRefAdGroup] = React.useState<any>({});
  const [refCampaign, setRefCampaign] = React.useState<any>({});
  const [refAdId, setRefAdId] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [adLoading, setAdLoading] = React.useState<boolean>(false);
  const [accordionIndex, setAccordionIndex] = React.useState<number>(-1);
  const [showError, setShowError] = React.useState<boolean>(false);
  const clusterState = useSelector(
    (state: { cluster: ClusterState }) => state.cluster
  );
  const {
    createAdCampaign,
    clusterSpinner,
    createAdAds,
    createAdAdGroup,
    publishCluster,
    clusterTable,
  } = clusterState;
  const { selectedBrand, selectedAdAccount, selectedGSAIAdaccount } =
    useSelector((state: { adaccount: AdAccountState }) => state.adaccount);
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { modal } = formState;
  const formattedCreateAdAds = (createAdAds: any) => {
    if (createAdAds) {
      return createAdAds.map((ad: any) => ({
        id: ad.id,
        value: ad.id,
        name: ad.headlines,
        extralabel: ad.descriptions,
      }));
    }
  };
  const [getAdGroupName, setGetAdGroupName] = React.useState<any>([]);
  React.useEffect(() => {
    // Get all campaign on modal open
    dispatch(setClusterSpinner(true));
    dispatch(
      getCreateAdCampaign(
        { accountID: selectedGSAIAdaccount?.id, user: auth?.user },
        (response: any, error: boolean) => {
          if (!error) {
            const aiGroupAvailable = response.data.filter(
              (r: any) => r.aiGroupAvailable
            );
            dispatch(setCreateAdCampaign(aiGroupAvailable));
            dispatch(setClusterSpinner(false));
          }
        }
      )
    );
    // cluster?.getCreateAdCampaign(auth?.accountIdVal!, auth?.user);

    // Prefill field in update ad group
    if (modal.key === "updateAdGroup") {
      setGroupName(currentCluster?.ad_group?.update_name!);
      setRefAdGroup(currentCluster?.ref_ad_group!);
      setRefAdId(currentCluster?.ref_ad_id!);
      setRefCampaign(currentCluster?.ref_campaign!);
      // Get selected campaign's adGroup
      dispatch(
        getCreateAdGroup(
          {
            accountID: selectedGSAIAdaccount?.id,
            campaignId: currentCluster?.ref_campaign?.id!,
            user: auth?.user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              dispatch(
                setCreateAdAdGroup({
                  ...createAdAdGroup,
                  [currentCluster?.ref_campaign?.id!]: response.data,
                })
              );
            }
          }
        )
      );

      // Get selected adgroup's ads
      dispatch(
        getCreateAdAds(
          {
            accountID: selectedGSAIAdaccount?.id,
            campaignId: currentCluster?.ref_campaign?.id!,
            adGroupId: currentCluster?.ref_ad_group?.id!,
            user: auth?.user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              let formattedRes = response.map((r: any) => r.ad);
              let formattedResdata = formattedRes.map((r: any) => ({
                ...r,
                headlines: r?.responsiveSearchAd?.headlines
                  ?.map((h: any) => h.text)
                  .join(" | "),
                descriptions: r?.responsiveSearchAd?.descriptions
                  ?.map((h: any) => h.text)
                  .join(" | "),
              }));
              dispatch(
                setCreateAdAds({
                  ...createAdAds,
                  [currentCluster?.ref_ad_group?.id!]: formattedResdata,
                })
              );
            }
          }
        )
      );
    }
  }, []);

  const validateAdGroupName = () => {
    if (publishCluster) {
      const getExistingAdGroupName = publishCluster.map((data: any) => {
        if (data.ad_group && data.ad_group.update_name) {
          return data.ad_group.update_name;
        } else {
          return "";
        }
      });
      setGetAdGroupName(getExistingAdGroupName);

      if (getAdGroupName.includes(groupName)) {
        setShowError(true);
      }
    }
  };

  const handleModalSubmit = () => {
    validateAdGroupName();
    let isCreate: boolean = true;

    // Error handling
    if (
      // When modal is open to update group and isCreate
      // is false, we don't need to check for group name
      (modal.key === "updateAdGroup"
        ? currentCluster?.isCreate
          ? !groupName
          : false
        : !groupName) ||
      Object.keys(refAdGroup).length === 0 ||
      !refAdId
    ) {
      setShowError(true);
      return;
    }

    // Flag to indicate New/Existing ad group
    if (modal.key === "existingAdGroup" || modal.key === "existingAdGroup") {
      isCreate = false;
    }

    // Set value of current cluster on submit
    const clusterData: any[] = [...clusterTable!];

    // Group name testing (New/Existing)
    if (modal.key !== "existingAdGroup") {
      clusterData[currentClusterIndex].ad_group = {
        update_name: groupName,
      };
    } else {
      delete clusterData[currentClusterIndex].ad_group;
    }

    clusterData[currentClusterIndex].isCreate = isCreate;
    clusterData[currentClusterIndex].ref_ad_group = refAdGroup;
    clusterData[currentClusterIndex].ref_ad_id = refAdId;
    clusterData[currentClusterIndex].ref_campaign = refCampaign;

    clusterData[currentClusterIndex].ref_ad_group_id = refAdGroup.id;
    clusterData[currentClusterIndex].ad_group_name = refAdGroup.name;
    clusterData[currentClusterIndex].campaign_name = refCampaign.name;
    clusterData[currentClusterIndex].ai_group = refCampaign.aiGroupName;

    dispatch(setClusterTable(clusterData));

    dispatch(setModal(null));
  };

  React.useEffect(() => {
    // Open selected campaign's accordion by default in case of update
    if (modal.key === "updateAdGroup") {
      setAccordionIndex(
        createAdCampaign.findIndex(
          (c: any) => c.id === currentCluster?.ref_campaign?.id
        )
      );
    }
  }, [createAdCampaign]);

  const handleOpen = (e: any, campaign: any) => {
    // On accordion open fetch campaign's adgroup
    if (createAdAdGroup === null || !createAdAdGroup[campaign.id]) {
      setLoading(true);
      dispatch(
        getCreateAdGroup(
          {
            accountID: selectedGSAIAdaccount?.id,
            campaignId: campaign.id,
            user: auth?.user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              dispatch(
                setCreateAdAdGroup({
                  ...createAdAdGroup,
                  [campaign.id]: response.data,
                })
              );
              setLoading(false);
            }
          }
        )
      );
    }
  };

  return (
    <>
      <ModalWrapper className="create-ad-modal">
        <ModalContainer
          title={modal.title}
          formDisable={false}
          submitText={"Submit"}
          handleSubmit={handleModalSubmit}
          width={"25vw"}
          handleClose={() => dispatch(setModal(null))}
        >
          {/* Show group name input field in case of 
          1. Create ad group
          2. Edit created ad group */}
          {(modal.key === "createAdGroup"
            ? true
            : modal.key === "updateAdGroup"
            ? currentCluster?.isCreate
            : false) && (
            <div className="adgroup-name">
              <label className="text-lg">
                New Ad group Name
                {tooltip({
                  title: "New Ad group Name",
                  subtitle: "New Ad group Name",
                })}
              </label>
              <InputText
                value={groupName}
                onChange={(e: any) => {
                  setGroupName(e.target.value);
                }}
              />
            </div>
          )}
          <div className="reference-ad-group">
            <label className="text-lg">
              Select Reference Ad Group
              {tooltip({
                title: "Select Reference Ad Group",
                subtitle: "Select Reference Ad Group",
              })}
            </label>
            <div className="ad-group-campaign">
              {clusterSpinner ? (
                <Loader animation="grow" size="sm" />
              ) : (
                createAdCampaign?.map((data: any, index: number) => (
                  <Accordion
                    data={data}
                    index={index}
                    header={
                      <div className="ad-group-header-container">
                        <span className="fw-bold text-lg text-truncate">
                          {data.name}
                        </span>
                        <span className="ai-group-name">
                          {data.aiGroupName}
                        </span>
                      </div>
                    }
                    body={
                      <CampaignAccordion
                        data={data}
                        index={index}
                        refAdGroup={refAdGroup}
                        setRefCampaign={setRefCampaign}
                        setRefAdGroup={setRefAdGroup}
                        setAdLoading={setAdLoading}
                        loading={loading}
                      />
                    }
                    handleOpen={handleOpen}
                    accordionIndex={accordionIndex}
                  />
                ))
              )}
            </div>
            <div className="select-ad-container">
              <label className="select-ref-ad">
                Select Reference ad
                {tooltip({
                  title: "Select Reference ad",
                  subtitle: "Select Reference ad",
                })}
              </label>
              <div className="select-ad-list">
                {adLoading ? (
                  <Loader height="60px" animation="grow" size="sm" />
                ) : createAdAds && createAdAds[refAdGroup.id]?.length > 0 ? (
                  <Radio
                    name="create-ad-ads"
                    data={formattedCreateAdAds(
                      (createAdAds as any)[refAdGroup.id]
                    )}
                    onChange={(e: any) => {
                      setRefAdId(e.target.getAttribute("data-id"));
                    }}
                    defaultSelected={refAdId}
                    showNameTooltip={true}
                    showExtraLabelTooltip={true}
                    className="create-ad-ads-radio"
                  />
                ) : (
                  <span className="color-light-grey text-sm">
                    Select reference ad group to view it's ads
                  </span>
                )}
              </div>
            </div>
          </div>
        </ModalContainer>
      </ModalWrapper>
      {showError && (
        <ModalWrapper>
          <ModalContainer
            title="Error"
            formDisable={false}
            submitText="Close"
            handleSubmit={() => {
              setShowError(false);
            }}
            width={"25vw"}
            handleClose={() => {
              setShowError(false);
            }}
          >
            <ul className="create-ad-drawer-error">
              {!groupName && <li>Please add group name.</li>}
              {Object.keys(refAdGroup).length === 0 && (
                <li>Please select ad group.</li>
              )}
              {!refAdId && <li>Please select ad id.</li>}
              {getAdGroupName.includes(groupName) && (
                <li>
                  Group name already exist in Published Ads, please update the
                  name.
                </li>
              )}
            </ul>
          </ModalContainer>
        </ModalWrapper>
      )}
    </>
  );
};

export default CreateAdGroup;
