import { usePostResponseHook } from "components/CreativeAI/Facebook/Review/hooks/postHook";
import ReviewApprovalAction from "components/CreativeAI/shared/ReviewApprovalAction/ReviewApprovalAction";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateReviewStatus } from "store/actions/CreativeAI/shared/Review/review";
import {
  ReviewInitialState,
  ReviewStatusType,
} from "store/types/CreativeAI/shared/Review/review";
import ReviewSection from "../ReviewSection/ReviewSection";
import {
  StyledReviewContainer,
  StyledReviewContainerHeader,
  StyledReviewHeader,
  StyledReviewRequest,
  StyledReviewRequestType,
  StyledReviewSectionContainer,
  StyledVerticalDivider,
} from "./styles";
import { mixPanelAdEvent } from "utils/mixpanel";
import { GsaiState } from "store/types/CreativeAI/GsaiPlayground";
import { AdAccountState } from "store/types/adaccount";

const ReviewContainer: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const { updatePostComments } = usePostResponseHook();
  const gsaiPlayground = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const {
    requestedVariantData: { data, isLoading },
    selectedVariantRequestedId,
    requestedVariantsMetaData,
  } = useSelector(
    (state: { reviewReducer: ReviewInitialState }) => state.reviewReducer
  );
  const variantMetaInfo = data?.variantMetaInfo?.data?.dnd;
  const headlines = (variantMetaInfo?.headlines?.toBePublished || []).map(
    (item: any) => item.value
  );
  const descriptions = (variantMetaInfo?.descriptions?.toBePublished || []).map(
    (item: any) => item.value
  );

  const currentReviewState =
    selectedVariantRequestedId !== undefined && requestedVariantsMetaData.data
      ? requestedVariantsMetaData.data.variants[selectedVariantRequestedId]
          .reviewStatus
      : "UNDER_REVIEW";

  const handleActionClick = (value: ReviewStatusType) => {
    dispatch(updateReviewStatus(value));
    updatePostComments({ commentKey: "reviewStatus", value });
    mixPanelAdEvent("CAI GS Approval Flow Action", {
      module_name: "Google Search Playground",
      sub_module_name: gsaiPlayground.isCreate
        ? "Create Variant"
        : "Refresh Ad",
      brand_name: selectedBrand?.name,
      brand_id: selectedBrand?.id,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      reference_ad_id: gsaiPlayground?.adUnit?.adGroupAd?.ad?.id,
      reference_ad_group_id: gsaiPlayground?.adUnit?.adGroup?.id,
      reference_campaign_id: gsaiPlayground?.adUnit?.campaign?.id,
      reference_account_id: gsaiPlayground?.adAccount?.adAccountId,
      ai_group_name: gsaiPlayground?.aiGroups?.selected?.name,
      ai_group_id: gsaiPlayground?.aiGroups?.selected?.id,
      ad_account_id: gsaiPlayground?.adAccounts?.selected?.adAccountId,
      ad_account_name: gsaiPlayground?.adAccounts?.selected?.name,
      previous_status: currentReviewState,
      new_status: value,
    });
  };

  return (
    <StyledReviewContainer>
      <StyledReviewContainerHeader>
        <StyledReviewHeader>Review Ad</StyledReviewHeader>
        <StyledReviewHeader>
          <ReviewApprovalAction
            handleClick={handleActionClick}
            initialState={currentReviewState}
          />
          <div>
            <StyledReviewRequestType>Request type : </StyledReviewRequestType>
            <StyledReviewRequest>
              {data?.isCreate ? "Create Variant" : "Refresh Ad"}
            </StyledReviewRequest>
          </div>
        </StyledReviewHeader>
      </StyledReviewContainerHeader>
      <StyledReviewSectionContainer>
        <ReviewSection
          title="Heading"
          data={headlines}
          comment={data?.variantMetaInfo?.headlineReviewComments}
          commentKey="headlineReviewComments"
        />
        <StyledVerticalDivider
          height="100%"
          sx={{
            margin: "0em .8em",
          }}
        />
        <ReviewSection
          title="Description"
          data={descriptions}
          comment={data?.variantMetaInfo?.descriptionReviewComments}
          commentKey="descriptionReviewComments"
        />
      </StyledReviewSectionContainer>
    </StyledReviewContainer>
  );
};

export default ReviewContainer;
