import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";

import {
  AdGroupDetailsElement,
  ClusterDetailsElement,
  KeywordsType,
  SaveModalType,
  StarType,
} from "../types/index";
import SelectText from "Pages/SmbDashboard/common/SelectField";
import {
  getCurrencySymbol,
  amountValue,
} from "../../../../../../utils/commonFunctions";
import { TAIGSearchMixPanel, tobeFixedNumber } from "../../../utils/index";
import {
  CLUSTER_ID_KEY,
  ADGROUP_ID_KEY,
  KEYWORD_PAGINATION_LENGTH,
  NEXT_PAGE_TIMEOUT,
} from "../../../constants/KeywordExplorer";
import { useRef, useState, useEffect, useContext } from "react";
import { Fragment } from "react";
import { METRICS_CONFIG } from "../../../constants/KeywordExplorer";
import { CommonState } from "store/types/common";
import { AdAccountState } from "store/types/adaccount";
import { useDispatch, useSelector } from "react-redux";
import { KeywordContext } from "../KeywordContextProvider";
import SaveModal from "Pages/TargetingAIPlaygroundBeta/common/SaveModal";
import {
  deleteRecommendedKeywords,
  storeRecommendedKeywords,
} from "store/actions/targetingAI";
import { mixPanelAdEvent } from "utils/mixpanel";

export function formatMetricValue({
  key,
  value,
  currencySymbol,
}: {
  key: string;
  value: any;
  currencySymbol: string;
}) {
  if (!METRICS_CONFIG[key]) return value;
  const symbolPrefix = METRICS_CONFIG[key].currency
    ? getCurrencySymbol(currencySymbol || "USD")
    : "";
  const _amountValue = METRICS_CONFIG[key].amountValue
    ? METRICS_CONFIG[key]?.divideByValue
      ? amountValue(
          tobeFixedNumber(value / METRICS_CONFIG[key]?.divideByValue || 1)
        )
      : amountValue(tobeFixedNumber(value))
    : "";
  const _fixedValue = METRICS_CONFIG[key].tobeFixedNumber
    ? tobeFixedNumber(value)
    : "";

  return `${symbolPrefix}${_amountValue}${_fixedValue}`;
}

function Keywords({
  items,
  viewDetails,
  showIconsOnHover,
  setShowIconsOnHover,
  showSaveModal,
  setShowSaveModal,
  keywords,
  setKeywords,
  starredItems,
  setStarredItems,
  isClusterView,
  selectedFilter,
  selectedMetrics,
  currencySymbol,
  searchKey,
  handleDeleteClick,
  handleDuplicateClick,
  changeSingleProperty,
  handleSelectKeywords,
  checkSelectAll,
}: {
  items: (number | string)[];
  viewDetails: AdGroupDetailsElement | ClusterDetailsElement;
  showIconsOnHover: number;
  setShowIconsOnHover: React.Dispatch<React.SetStateAction<number>>;
  showSaveModal: SaveModalType;
  setShowSaveModal: Function;
  keywords: KeywordsType;
  setKeywords: any;
  starredItems: StarType;
  setStarredItems: Function;
  isClusterView: boolean;
  selectedFilter: any;
  selectedMetrics: any;
  currencySymbol: string;
  searchKey: "new_kw.text" | "searchTermView.searchTerm";
  handleDeleteClick: (
    adGroupId: number,
    clusterId: number | string,
    index: number[]
  ) => void;
  handleDuplicateClick: (
    adGroupId: number,
    clusterId: number | string,
    index: number
  ) => void;
  changeSingleProperty: (
    indices: string[],
    prop: string,
    value: string
  ) => void;
  handleSelectKeywords: (indices: any) => void;
  checkSelectAll: (indices: string[]) => number;
}) {
  const parent = useRef(null) as any;
  const dispatch = useDispatch();
  const [dataLength, setDataLength] = useState(KEYWORD_PAGINATION_LENGTH);
  const [open, setOpen] = useState(false);
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { selectedAgency } = common;
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const aiInitiationData = useSelector(
    (state: any) => state.aiInitiation.apiData
  );
  const { selectedKeywordsBucket } = useContext(KeywordContext);
  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;
  useEffect(() => {
    if (!open) {
      setDataLength(KEYWORD_PAGINATION_LENGTH);
    }
  }, [open]);

  const handleNextPage = () => {
    const diff = items.length - dataLength;
    if (diff >= KEYWORD_PAGINATION_LENGTH) {
      setTimeout(() => {
        setDataLength(dataLength + KEYWORD_PAGINATION_LENGTH);
      }, NEXT_PAGE_TIMEOUT);
    } else if (diff < KEYWORD_PAGINATION_LENGTH && diff > 0) {
      setTimeout(() => {
        setDataLength(dataLength + diff);
      }, NEXT_PAGE_TIMEOUT);
    }
  };
  const handleToggleStar = (data: string) => {
    const basePayload = {
      adAccountId: aiInitiationData.adAccount.adAccountId,
      adAccountName: aiInitiationData.adAccount.name,
      brandName: selectedBrand.name,
      brandId: selectedBrand.id,
      aiGroupId: aiInitiationData.aiGroup.id,
      aiGroupName: aiInitiationData.aiGroup.name,
    };

    if (!starredItems[parseInt(data)]) {
      const payloadData: any = [
        {
          ...basePayload,
          campaignName: keywords[data]["campaign.name"],
          campaignId: keywords[data]["campaign.id"],
          // adGroupId: keywords[data]["adGroup.id"],
          adGroupName: keywords[data]["adGroup.name"],
          keywords: [
            {
              matchType:
                keywords[data]["segments.keyword.info.matchType"] || "EXACT",
              negative: keywords[data].bucket === "negative_kw",
              // text: keywords[data]["searchTermView.searchTerm"],
              text: keywords[data][searchKey],
              new: keywords[data].bucket === "kw_ideas",
              // new: true,
              metadata: {
                ...keywords[data],
              },
            },
          ],
        },
      ];
      if (!keywords[data]["isNew"]) {
        payloadData[0]["adGroupId"] = keywords[data]["adGroup.id"];
      }
      dispatch(
        storeRecommendedKeywords(
          {
            data: payloadData,
            brandId: selectedBrand.id,
            aigroupId: aiInitiationData.aiGroup.id,
          },
          (res: any, err: any) => {
            if (!err) {
              setKeywords((prev: KeywordsType) => {
                const tempKeywords = { ...prev };
                tempKeywords[data]["saveId"] = res.data[0][0].id;
                return tempKeywords;
              });
              setStarredItems((prevSavedItems: any) => {
                const isStarred = !prevSavedItems[data];
                return { ...prevSavedItems, [data]: isStarred };
              });
              if (!showSaveModal.shownOnce) {
                setShowSaveModal({ ...showSaveModal, value: true });
              }
            }
          }
        )
      );
    } else {
      const payloadData: any = [
        {
          campaignId: keywords[data]["campaign.id"],
          // adGroupId: `${keywords[data]["adGroup.id"]}`,
          kwIds: [keywords[data]["saveId"]],
        },
      ];
      if (!keywords[data]["isNew"]) {
        payloadData[0]["adGroupId"] = keywords[data]["adGroup.id"];
      } else {
        payloadData[0]["adGroupName"] = keywords[data]["adGroup.name"];
      }
      dispatch(
        deleteRecommendedKeywords(
          {
            data: payloadData,
            brandId: selectedBrand.id,
            aiGroupId: aiInitiationData.aiGroup.id,
            platformId: aiInitiationData.adAccount.platformId,
          },
          (res: any, err: any) => {
            if (!err) {
              setStarredItems((prevSavedItems: any) => {
                const isStarred = !prevSavedItems[data];
                return { ...prevSavedItems, [data]: isStarred };
              });
            }
          }
        )
      );
    }

    // setStarredItems((prevStarredItems: any) => {
    //   const isStarred = !prevStarredItems[data];
    //   return { ...prevStarredItems, [data]: isStarred };
    // });
    // if (!showSaveModal.shownOnce) {
    //   setShowSaveModal({ ...showSaveModal, value: true });
    // }
  };

  const saveMessageElemant = (
    <Typography
      className="font-14 font-500"
      textAlign="center"
      sx={{
        color: "#727272",
        marginBottom: "1rem !important",
      }}
    >
      You can view all your saved keywords <br /> with the ad groups in{" "}
      <a
        href={`${process.env.REACT_APP_BASE_URL_PIXIS2}/audience-manager/google-search?brandId=${selectedBrand.id}&ai_group_id=${aiInitiationData.aiGroup.id}`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        Audience Manager
      </a>
      <br /> under Analyze
    </Typography>
  );
  const selectedLength = checkSelectAll(items as any);

  return (
    <>
      <Accordion
        key={viewDetails?.id}
        disableGutters
        className="w-100 keywords-accordian-tai"
        ref={parent}
        TransitionProps={{ unmountOnExit: true }}
        expanded={open}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="w-100"
          onClick={() => {
            if (open === false) {
              TAIGSearchMixPanel(
                "TAI-Gsearch List Cohort Clicked",
                selectedBrand?.name,
                selectedAgency?.name,
                {
                  action_name: viewDetails?.name,
                  bucket_name: selectedKeywordsBucket,
                  ad_account_id: selectedAdAccount?.id,
                  ai_group_id: selectedGroup?.id,
                  ai_group_name: selectedGroup?.name,
                  ad_account_name: selectedAdAccount?.name,
                  agency_id: selectedAgency.id,
                  brand_id: selectedBrand.id,
                  brand_type: selectedBrand.brandType,
                }
              );
            }
            setOpen(!open);
          }}
        >
          <Stack
            direction="row"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  height: "1rem",
                  alignSelf: "center",
                }}
              />
            }
            className="w-100"
          >
            <Grid container>
              <Grid item xs={2}>
                <Checkbox
                  className="chk-header"
                  inputProps={{ className: "chk-header" }}
                  onClick={() => {
                    const itemsToSelect = items.reduce((prev: any, next) => {
                      prev[next] = {
                        index: next,
                        [ADGROUP_ID_KEY]: keywords[next][ADGROUP_ID_KEY],
                        [CLUSTER_ID_KEY]: keywords[next][CLUSTER_ID_KEY],
                      };
                      return prev;
                    }, {});
                    handleSelectKeywords(itemsToSelect);
                  }}
                  indeterminate={
                    selectedLength > 0 && selectedLength < items.length
                  }
                  checked={selectedLength === items.length}
                />
              </Grid>
              <Grid
                className="d-flex flex-column justify-content-center"
                item
                xs={10}
              >
                <Typography
                  className="font-10 font-weight-400"
                  sx={{ color: "#999999" }}
                >
                  {isClusterView ? "Cluster" : "Ad Group"} Name
                </Typography>
                <Typography className="font-12 font-weight-400">
                  {viewDetails?.name || ""}
                </Typography>
              </Grid>
            </Grid>

            <Box className="d-flex flex-column justify-content-center w-50">
              <Box className="font-10 font-weight-400">
                {selectedLength}/{items?.length}
              </Box>
              <Box
                sx={{ color: "#999999" }}
                className="font-10 font-weight-400"
              >
                Keywords Selected
              </Box>
            </Box>
          </Stack>
        </AccordionSummary>
        {open && (
          <AccordionDetails
            id={viewDetails.id.toString()}
            className="w-100"
            sx={{
              maxHeight: "15rem",
              overflowY: "scroll",
              overflowX: "none",
            }}
          >
            <InfiniteScroll
              scrollableTarget={viewDetails.id.toString()}
              dataLength={dataLength}
              next={handleNextPage}
              hasMore={items.length - dataLength > 0 ? true : false}
              style={{ overflow: "none" }}
              loader={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 1,
                  }}
                >
                  <CircularProgress />
                </Box>
              }
            >
              {items.slice(0, dataLength).map((data: any, index) => {
                return (
                  <Fragment key={data}>
                    <Stack
                      direction="row"
                      divider={
                        <Divider
                          orientation="vertical"
                          flexItem
                          style={{
                            height: "2rem",
                            alignSelf: "center",
                            color: "#F0F0F0",
                          }}
                        />
                      }
                      spacing={1}
                      className="bg-white w-100 align-items-center"
                      sx={{
                        border: "1px solid #F0F0F0",
                        borderRadius: "8px",
                        py: 2,
                        borderLeft: "6px solid #C9CBF8",
                        mt: 2,
                      }}
                      onMouseEnter={() => {
                        setShowIconsOnHover(data);
                      }}
                      onMouseLeave={() => {
                        setShowIconsOnHover(-1);
                      }}
                    >
                      <Grid
                        container
                        sx={{ width: "40% !important" }}
                        alignItems="center"
                      >
                        <Grid item xs={2}>
                          <Checkbox
                            onClick={() => {
                              handleSelectKeywords({
                                [data]: {
                                  index: data,
                                  [CLUSTER_ID_KEY]:
                                    keywords[data][CLUSTER_ID_KEY],
                                  [ADGROUP_ID_KEY]:
                                    keywords[data][ADGROUP_ID_KEY],
                                },
                              });
                            }}
                            checked={checkSelectAll([data]) === 1}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          className="d-flex align-items-center"
                        >
                          <Typography
                            ml={2}
                            className="font-10 font-weight-500 w-100"
                          >
                            {keywords[data] ? keywords[data][searchKey] : ""}
                          </Typography>
                          {showIconsOnHover === data && (
                            <Stack direction="row">
                              <Tooltip title="Duplicate">
                                <IconButton
                                  disableRipple
                                  sx={{
                                    margin: 0,
                                    padding: 0.5,
                                    color: "#0869FB",
                                  }}
                                  onClick={() => {
                                    handleDuplicateClick(
                                      keywords[data]["adGroup.id"],
                                      keywords[data].cluster_id,
                                      keywords[data].index
                                    );
                                  }}
                                >
                                  <AddToPhotosOutlinedIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  disableRipple
                                  sx={{
                                    margin: 0,
                                    padding: 0.5,
                                    color: "#0869FB",
                                  }}
                                  onClick={() => {
                                    handleDeleteClick(
                                      keywords[data]["adGroup.id"],
                                      keywords[data].cluster_id,
                                      [keywords[data].index]
                                    );
                                  }}
                                >
                                  <DeleteOutlinedIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={starredItems[data] ? "Unsave" : "Save"}
                              >
                                <IconButton
                                  disableRipple
                                  sx={{
                                    margin: 0,
                                    padding: 0.5,
                                    color: "#0869FB",
                                  }}
                                  onClick={() => {
                                    handleToggleStar(data);
                                    mixPanelAdEvent(
                                      "keyword saved in Playground",
                                      {
                                        module_name: "TAI Playground",
                                        brand_id: selectedBrand?.id,
                                        agency_id: selectedAgency?.id,
                                        brand_name: selectedBrand?.name,
                                        brand_type: selectedBrand?.brandType,
                                        agency_name: selectedAgency?.name,
                                        ai_group_name:
                                          aiInitiationData.aiGroup.name,
                                        ai_group_id:
                                          aiInitiationData.aiGroup.id,
                                        // cohort_details: data,
                                        ad_account_id:
                                          aiInitiationData?.adAccount?.id,
                                        ad_account_name:
                                          aiInitiationData?.adAccount?.name,
                                      }
                                    );
                                  }}
                                >
                                  {starredItems[data] ? (
                                    <StarOutlinedIcon fontSize="small" />
                                  ) : (
                                    <StarOutlineOutlinedIcon fontSize="small" />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          )}
                        </Grid>
                      </Grid>
                      <Box sx={{ width: "15% !important" }}>
                        <SelectText
                          sx={{
                            "& *": {
                              fontSize: "0.625rem",
                              fontWeight: "500",
                            },
                            "& .MuiSelect-select": {
                              py: "0.25rem !important",
                              textTransform: "capitalize",
                            },
                          }}
                          items={["PHRASE", "BROAD", "EXACT"]}
                          selectedItem={
                            keywords[data] &&
                            keywords[data]["segments.keyword.info.matchType"]
                              ? keywords[data][
                                  "segments.keyword.info.matchType"
                                ]
                              : "EXACT"
                          }
                          // setSelectedItem={() => null}
                          setSelectedItem={(e: any) => {
                            changeSingleProperty(
                              [data],
                              "segments.keyword.info.matchType",
                              e
                            );
                          }}
                        />
                      </Box>
                      <Box sx={{ width: "15% !important" }}>
                        <Box
                          sx={{
                            p: "4px 8px",
                            background: "rgba(8, 105, 251, 0.1)",
                            borderRadius: "4px",
                            width: "fit-content",
                            color: "#0869FB",
                          }}
                          className="font-10 font-weight-400"
                        >
                          {keywords[data]
                            ? isClusterView
                              ? keywords[data]["adGroup.name"]
                              : keywords[data]["cluster_name"]
                            : ""}
                        </Box>
                      </Box>
                      {selectedFilter &&
                        Object.keys(selectedFilter).map((filter: any) => {
                          const metric = keywords[data] as any;
                          return (
                            <Box
                              className="d-flex flex-column align-items-center"
                              key={filter}
                              sx={{ width: "10% !important" }}
                            >
                              <Box className="font-12 font-weight-600">
                                {formatMetricValue({
                                  key: filter,
                                  value: metric[filter],
                                  currencySymbol,
                                })}
                              </Box>
                              <Box
                                sx={{ color: "#999" }}
                                className="font-8 font-weight-400 text-align-center"
                              >
                                {selectedMetrics[filter] || filter}
                              </Box>
                            </Box>
                          );
                        })}
                    </Stack>
                  </Fragment>
                );
              })}
            </InfiniteScroll>
          </AccordionDetails>
        )}
        {showSaveModal.value && !showSaveModal.shownOnce && (
          <SaveModal
            buttonClickHandler={() => {
              setShowSaveModal({ value: false, shownOnce: true });
            }}
            descriptionElement={saveMessageElemant}
          />
        )}
      </Accordion>
    </>
  );
}

export default Keywords;
