import { Box } from "@mui/material";
import { StyledText } from "components/shared";
import React from "react";
import { TestMediaStudioResponseType } from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";
import ImageComponent from "../ImageComponent/ImageComponent";

export default function FrameImageComponent({
  index,
  item,
}: {
  index: number;
  item: TestMediaStudioResponseType;
}) {
  return (
    <Box display="flex" flexDirection="column" pb="24px">
      <StyledText lineHeight="20px" fontSize="14px" pb="8px">
        Frame {index + 1}
      </StyledText>
      <ImageComponent
        src={item.url}
        isFailed={item.status === "FAILED"}
        isLoading={item.status === "INITIATED"}
      />
    </Box>
  );
}
