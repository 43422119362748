import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { ReactComponent as GoogleIcon } from "./assets/google-budget-icon.svg";
import { ReactComponent as InfoIcon } from "./assets/info_grey.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { ReactComponent as LinkIcon } from "./assets/link.svg";
import InputText from "ui/InputText/InputText";
import { paths } from "Pages/AIInsights/common/platformIcons";

function SuperAdminSettings(props) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
    borderRadius: "0.5rem",
  }));

  const [resetModal, setResetModal] = useState(false);

  const [link, setLink] = useState("");
  return (
    <Box
      className="container-overlay d-flex align-items-center justify-content-center"
      sx={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: "1032",
        top: "0",
        left: "0",
      }}
    >
      <Box
        sx={{
          width: "38.5rem",
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
      >
        <Stack spacing={2} sx={{ mb: "0.625rem", display: "flex" }}>
          <Item
            className="d-flex align-items-center justify-content-between"
            sx={{
              height: "4.125rem",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
              fontSize: "1rem",
              fontWeight: 600,
              color: "#333333",
              textAlign: "left",
              p: "1rem",
            }}
          >
            <span style={{ fontFamily: "Inter" }}>Audience Insights Setup</span>
            <IconButton
              sx={{
                background: "#F2F2F2",
                borderRadius: "0.25rem",
                textAlign: "center",
              }}
              color="primary"
              aria-label="Close"
              component="label"
              onClick={() => {
                props.setModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Item>
        </Stack>
        <Stack spacing={2} sx={{ padding: "0 1.5rem" }}>
          <Grid container rowSpacing={2}>
            <Grid
              item
              xs={6}
              sx={{
                p: 0,
              }}
            >
              <span
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  color: "#666666",
                  fontFamily: "Inter",
                }}
              >
                Ad Account
              </span>{" "}
              <InfoIcon />
            </Grid>
            <Grid item xs={6}>
              <span
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  color: "#666666",
                  marginRight: "5px",
                  fontFamily: "Inter",
                }}
              >
                Data Studio Report Link
              </span>
              <InfoIcon />
            </Grid>
          </Grid>
        </Stack>
        <Stack
          spacing={2}
          sx={{ padding: "1.5rem", maxHeight: "270px", overflow: "auto" }}
        >
          {props.listOfAdAcc?.length
            ? props.listOfAdAcc?.map((eachAd, index) => {
                const platform = props.parentPlatforms.find(
                  (platform) => platform.id === eachAd.parentPlatformId
                );

                return (
                  <Grid container rowSpacing={2}>
                    <Grid item xs={6}>
                      <Item
                        sx={{
                          width: "15.44rem",
                          p: "8px 16px 8px 16px",
                          border: "1px solid #DDDDDD",
                        }}
                      >
                        <Box className="d-flex align-items-center">
                          <img
                            src={paths[platform?.name]}
                            style={{ height: "20px" }}
                          />

                          <Box sx={{ textAlign: "left", ml: 2 }}>
                            <span
                              style={{
                                fontSize: "0.75rem",
                                fontWeight: 600,
                                color: "#2E3044",
                                fontFamily: "Inter",
                              }}
                            >
                              {eachAd?.adAccountName
                                ? eachAd?.adAccountName
                                : eachAd?.adAccountId}
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "0.625rem",
                                fontWeight: 400,
                                color: "#575C67",
                                fontFamily: "Inter",
                              }}
                            >
                              {eachAd?.adAccountId}
                            </span>
                          </Box>
                        </Box>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "18.18rem",
                          height: "3rem",
                          p: "8px 16px 8px 16px",
                          border: "1px solid #DDDDDD",
                        }}
                      >
                        <LinkIcon />
                        <InputText
                          type={"text"}
                          style={{
                            color: "#999999",
                            fontSize: "14px",
                            lineHeight: "20px",
                            marginLeft: "0.7em",
                            border: "none",
                          }}
                          autoFocus={eachAd.adAccountId === link}
                          value={eachAd?.link}
                          onChange={(e) => {
                            props.onInputChange(index, e.target.value);
                          }}
                          onFocus={(e) => {
                            setLink(eachAd.adAccountId);
                          }}
                          onBlur={() => {
                            setLink("");
                          }}
                        />
                      </Item>
                    </Grid>
                  </Grid>
                );
              })
            : ""}
        </Stack>
        <hr style={{ color: "grey" }}></hr>
        <Stack
          className="d-flex align-items-center justify-content-center"
          sx={{
            p: "1.5rem",
            pt: "0.625rem",
          }}
        >
          <Grid
            className="d-flex align-items-center"
            container
            rowSpacing={2}
            columnSpacing={{ xs: 4, sm: 2, md: 2 }}
          >
            <Grid item xs={6}>
              <p
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  color: "#0869FB",
                  fontFamily: "Inter",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setResetModal(true);
                }}
              >
                Reset
              </p>
            </Grid>
            <Grid
              className="d-flex align-items-center justify-content-end"
              item
              xs={6}
            >
              <Button
                sx={{
                  width: "6.625rem",
                  height: "2.5rem",
                  backgroundColor: "#0869FB",
                  color: "white",
                  fontFamily: "Inter",
                  "&:hover": {
                    backgroundColor: "#0869FB",
                  },
                }}
                onClick={props.handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      {resetModal ? (
        <Box
          sx={{
            background: "#FFF",
            padding: "2em",
            boxShadow: "0px 10px 24px rgba(35, 40, 60, 0.05)",
            borderRadius: "8px",
            position: "absolute",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              padding: "0.8em",
            }}
          >
            Are you sure you want to reset ?
          </Typography>
          <Box className="flex-center justify-content-end">
            <Typography
              sx={{
                color: "#0869FB",
                cursor: "pointer",
              }}
              onClick={() => {
                setResetModal(false);
              }}
            >
              Cancel
            </Typography>
            <Button
              sx={{
                width: "3.625rem",
                height: "2.5rem",
                backgroundColor: "#0869FB",
                color: "white",
                fontFamily: "Inter",
                marginLeft: "1.5em",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "rgb(10 105 251)",
                },
              }}
              onClick={() => {
                props.onResetLink();
                setResetModal(false);
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

export default SuperAdminSettings;
