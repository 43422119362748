import React from "react";

import { useIntervalWhen } from "rooks";

export function usePolling(interval = 1000, callback: Function) {
  const [startPolling, setStartPolling] = React.useState(false);
  // let stop = count

  useIntervalWhen(
    () => {
      // if (stop === 0) {
      //     setStartPolling(false)
      //     callback(true, {
      //         message:
      //             'Your request has been timed out. Please try again later!',
      //     })
      // } else {
      callback();
      // stop -= 1
      // }
    },
    interval,
    startPolling,
    true
  );

  return { startPolling, setStartPolling };
}
