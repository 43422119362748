import DeleteModal from "Pages/BudgetPlanner/sections/BudgetPacing/DeleteModal/DeleteModal";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGroups, setSelectedGroup } from "store/actions/adaccount";
import {
  deleteParentAiItem,
  getParentGroupAiItems,
  setParentAiGroupItems,
  setSelectedParentAiGroup,
  deleteSingleParentAiGroup,
} from "store/actions/ParentAiGroup";
import { AdAccountState } from "store/types/adaccount";
import styled from "styled-components";
import {
  getRolesHierarchy,
  getSearchParams,
  updateSearchParams,
} from "utils/commonFunctions";
import { ReactComponent as DownArrow } from "../../../assets/svg/dropdown_arrow.svg";
import { ReactComponent as UpArrow } from "../../../assets/svg/up_dropdown_arrow.svg";
import {
  StyledDeleteIcon,
  StyledEmptyRecord,
} from "./ParentAIGroupDropDown.styled";
import { RolesWrapper } from "utils/rolesWrapper";
import { collator } from "utils/strings";

const Dropdowns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.25rem;
  margin-right: 1rem;
`;
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #000000;
    margin-bottom: 5px;
    margin-left: 2px;
  }

  .selected-value {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px;
    background: #fff;
    border-radius: 8px;
    width: 13.75rem;
    height: 2.25rem;
    cursor: pointer;
    background: #e9e9e9;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      color: #000000;
      width: 100%;
    }
    .parent-ai-group {
      font-weight: 700;
      font-size: 1rem;
    }
  }

  div.list-container {
    z-index: 2023;
    position: absolute;
    top: calc(100% + 8px);
    width: 13.75rem;
    max-height: 179px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #ffffff;
    border: 1px solid #e7e9f1;
    box-shadow: 1px 2px 11px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    div.footer {
      box-shadow: 0px -2px 4px rgba(199, 199, 199, 0.25);
      background: #ffffff;
      height: 27px;
      text-align: right;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      min-height: 27px;
      width: 100%;
      padding: 5px 16px;

      button {
        border: none;
        background: #fff;
        cursor: pointer;
        color: #0869fb;
      }
    }

    ul {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      list-style-type: none;
      margin: 0;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;

      li {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 17px;
        color: #313131;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        cursor: pointer;

        div.radio {
          border: 1px solid #e7e9f1;
          border-radius: 100%;
          width: 12px;
          height: 12px;
          min-width: 12px;
          min-height: 12px;
        }

        div.content {
          width: 100%;
        }

        &.active {
          div.radio {
            border: 4px solid #3f64fa;
            border-radius: 100%;
          }
        }
      }
    }
  }
`;

type Props = {
  record: any;
  getComponent: any;
  selectedName: string;
  className?: string;
};

function Dropdown({ record, getComponent, selectedName, className }: Props) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  function handleClose() {
    setIsOpen(false);
  }

  useEffect(() => {
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  return (
    <Container
      onClick={(event: any) => {
        event.stopPropagation();
      }}
    >
      <div
        className="selected-value"
        onClick={(event: any) => {
          event.stopPropagation();
          setIsOpen((value) => !value);
        }}
      >
        <>
          <span className={className}>{selectedName}</span>
          {isOpen ? <UpArrow /> : <DownArrow />}
        </>
      </div>
      {isOpen ? (
        <div className="list-container">
          <ul>
            {record.length ? (
              record.map((item: any) => <>{getComponent(item, handleClose)}</>)
            ) : (
              <StyledEmptyRecord>No Parent AI Groups Present</StyledEmptyRecord>
            )}
          </ul>
        </div>
      ) : null}
    </Container>
  );
}

function getComponent(selected: any, setComponentData: any) {
  return (props: any, callback?: any) => {
    return (
      <li
        className={props?.id === selected ? "active" : ""}
        key={selected?.id}
        onClick={() => {
          setComponentData(props);
          if (callback && typeof callback === "function") callback();
        }}
      >
        <div className="radio" />
        {props.name}
      </li>
    );
  };
}

export const ParentAiGroupDropdown: React.FC<{
  setIsParentGroupView: any;
  isParentGroupView: boolean;
}> = ({ setIsParentGroupView, isParentGroupView }) => {
  const [selectedView, setSelectedView] = React.useState(
    isParentGroupView ? "Parent AI Group View" : "AI Group view"
  );
  const [allParentAiGroup, setAllParentAiGroup] = React.useState([]);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand, groups: aiGroup, brandRoles } = adaccount;
  const [isDelete, setIsDelete] = React.useState(false);

  useEffect(() => {
    setIsParentGroupView(
      isParentGroupView || selectedView.toLowerCase()?.includes("parent")
    );
  }, []);

  const { data } = useSelector(
    (state: { ParentAiGroup: any; BudgetPlannerAiGroup: any }) =>
      selectedView.toLowerCase()?.includes("parent")
        ? state.ParentAiGroup
        : state.BudgetPlannerAiGroup
  );

  const groups = selectedView.toLowerCase()?.includes("parent")
    ? data?.parentAiGroups
    : data?.AiGroups;
  const selectedGroup = selectedView.toLowerCase()?.includes("parent")
    ? data?.selectedParentAiGroup
    : data?.selectedAiGroup;

  const dispatch = useDispatch();

  const handleAIGroupViewSelection = (props: any) => {
    setSelectedView(props.id);
    setIsParentGroupView(props?.name?.toLowerCase()?.includes("parent"));
  };

  const handleParentAiGroupSelection = (props: any) => {
    dispatch(setSelectedParentAiGroup(props.id));
    dispatch({
      type: "SET_SELECTED_PLANNER_ID_PARENT",
      payload: null,
    });
  };

  useEffect(() => {
    if (Object.keys(groups).length === 0 && isParentGroupView) {
      dispatch(
        getParentGroupAiItems(
          { brandId: selectedBrand.id },
          (res: any, err: boolean) => {
            if (!err) {
              setAllParentAiGroup(res.data);
              const data = res.data.reduce((pre: any, cur: any) => {
                pre[cur.id] = cur;
                return pre;
              }, {});
              dispatch(setParentAiGroupItems(data));
            }
          }
        )
      );
    }
  }, [isParentGroupView]);

  const aiGroups = [
    {
      id: "AI Group view",
      name: "AI Group view",
    },
    {
      id: "Parent AI Group View",
      name: "Parent AI Group View",
    },
  ];

  const handleParentAiGroupDelete = () => {
    dispatch(
      deleteParentAiItem(
        { parentAiGroupId: selectedGroup },
        (res: any, err: boolean) => {
          if (!err) {
            let grpArr = groups[selectedGroup].aiGroups.map(
              (grp: any) => grp.id
            );
            let newGroups = aiGroup.map((group: any) => {
              if (grpArr.includes(group.id)) {
                group.parentAiGroupId = null;
              }
              return group;
            });
            const group: any = aiGroup.find((g: any) => g.id === grpArr[0]);
            updateSearchParams("group_id", group.id as any);
            dispatch(setSelectedGroup(group));
            setSelectedView("AI Group view");
            setIsParentGroupView(false);
            dispatch(setGroups(newGroups));
            const newAllAiGroup = allParentAiGroup.filter(
              (all: any) => all.id !== selectedGroup
            );
            setAllParentAiGroup(newAllAiGroup);
            const newFilteredData = newAllAiGroup.reduce(
              (pre: any, cur: any) => {
                pre[cur.id] = cur;
                return pre;
              },
              {}
            );
            dispatch(
              deleteSingleParentAiGroup({
                newData: newFilteredData,
                selectedId: groups[Object.keys(groups)[0]].id,
              })
            );
          }
        }
      )
    );
  };

  useEffect(() => {
    if (isParentGroupView && Object.keys(groups).length > 0) {
      let groupId =
        getSearchParams("parent_ai_group") != null
          ? Object.keys(groups).findIndex(
              (key) => key === getSearchParams("parent_ai_group")
            ) >= 0
            ? getSearchParams("parent_ai_group")
            : groups[Object.keys(groups)[0]].id
          : groups[Object.keys(groups)[0]].id;
      dispatch(setSelectedParentAiGroup(groupId));
      setAllParentAiGroup(Object.values(groups));
    } else {
      dispatch(setSelectedParentAiGroup(null));
    }
    if (isParentGroupView) {
      setSelectedView("Parent AI Group View");
    } else {
      setSelectedView("AI Group view");
    }
  }, [groups, isParentGroupView]);

  useEffect(() => {
    if (isParentGroupView && selectedGroup) {
      updateSearchParams("parent_ai_group", selectedGroup);
    }
  }, [selectedGroup]);
  // console.log("All parent AI group :: ", allParentAiGroup);
  return (
    <Dropdowns>
      <Dropdown
        record={aiGroups}
        getComponent={getComponent(selectedView, handleAIGroupViewSelection)}
        selectedName={selectedView}
        className="parent-ai-group"
      />
      {isParentGroupView && (
        <Dropdown
          record={allParentAiGroup.sort(
            (parentAigroup1: any, parentAigroup2: any) =>
              collator.compare(
                parentAigroup1?.name?.trim(),
                parentAigroup2?.name?.trim()
              )
          )}
          getComponent={getComponent(
            selectedGroup,
            handleParentAiGroupSelection
          )}
          selectedName={selectedGroup && groups[selectedGroup]?.name}
        />
      )}
      <RolesWrapper
        disabled={
          getRolesHierarchy(brandRoles) === "viewer" ||
          getRolesHierarchy(brandRoles) === "editor"
        }
      >
        {isParentGroupView && selectedGroup && (
          <StyledDeleteIcon
            onClick={() => {
              setIsDelete(true);
            }}
          />
        )}
      </RolesWrapper>
      <DeleteModal
        heading="Delete Parent AI Group"
        subheading="Are you sure you want to Delete Parent AI Group ?"
        open={isDelete}
        setOpen={setIsDelete}
        onSubmit={handleParentAiGroupDelete}
      />
    </Dropdowns>
  );
};
