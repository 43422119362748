import GoogleAnalytics from "Pages/ThirdPartyAttribution/Components/GoogleAnalytics";
import SelectAdAccount from "Pages/TokenManagementSystem/SelectAdAccounts";
import { useEffect, useState } from "react";

const SelectedAdAccountWrapper = () => {
  const [platformData, setPlatformData] = useState<any>(null);
  useEffect(() => {
    let pathArray = window.location.search.slice(1).split("&");
    let val = pathArray.reduce((acc: any, item: any) => {
      let itemArray = item.split("=");
      acc[itemArray[0]] = itemArray[1];
      return acc;
    }, {});
    const decodedPlatform: any = window.atob(decodeURIComponent(val.state));
    setPlatformData({
      decodedPlatform: JSON.parse(decodedPlatform),
      code: val.code,
    });
  }, []);
  return (
    <>
      {platformData?.decodedPlatform["platform"] === "google analytics" ? (
        <GoogleAnalytics
          platform={platformData.decodedPlatform}
          code={platformData.code}
        />
      ) : (
        <SelectAdAccount />
      )}
    </>
  );
};

export default SelectedAdAccountWrapper;