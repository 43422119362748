export const GET_CREATEAD_CAMPAIGN = "GET_CREATEAD_CAMPAIGN";
export const SET_CREATEAD_CAMPAIGN = "SET_CREATEAD_CAMPAIGN";
export const SET_CLUSTER_SPINNER = "SET_CLUSTER_SPINNER";
export const SET_SELECTED_TAB_INDEX = "SET_SELECTED_TAB_INDEX";
export const GET_CREATEAD_GROUP = "GET_CREATEAD_GROUP";
export const GET_CREATEAD_ADS = "GET_CREATEAD_ADS";
export const CREATE_CLUSTER = "CREATE_CLUSTER";
export const GET_ALL_CLUSTER = "GET_ALL_CLUSTER";
export const GET_BRAND = "GET_BRAND";
export const GET_GPT3_REFERENCE = "GET_GPT3_REFERENCE";
export const UPDATE_GPT3_REFERENCE = "UPDATE_GPT3_REFERENCE";
export const GET_ARTICLE_HEADLINE = "GET_ARTICLE_HEADLINE";
export const GET_ARTICLE_DESCRIPTION = "GET_ARTICLE_DESCRIPTION";
export const CREATE_HEADLINE = "CREATE_HEADLINE";
export const CREATE_DESCRIPTION = "CREATE_DESCRIPTION";
export const GET_HEADLINE = "GET_HEADLINE";
export const GET_DESCRIPTION = "GET_DESCRIPTION";
export const GET_ELEMENT_LOGS = "GET_ELEMENT_LOGS";
export const GET_CLUSTER_LOGS = "GET_CLUSTER_LOGS";
export const GET_CLUSTER_DETAILS = "GET_CLUSTER_DETAILS";
export const GET_PUBLISHED_CLUSTER_DETAILS = "GET_PUBLISHED_CLUSTER_DETAILS";
export const GET_PUBLISH_CLUSTER = "GET_PUBLISH_CLUSTER";
export const PUBLISH_CLUSTER_DATA = "PUBLISH_CLUSTER_DATA";
export const UPDATE_PUBLISHED_CLUSTER = "UPDATE_PUBLISHED_CLUSTER";
export const UPDATE_CREATIVE_REPLACEMENT = "UPDATE_CREATIVE_REPLACEMENT";
export const UPDATE_AD_ROTATION = "UPDATE_AD_ROTATION";
export const SET_ACTIVE_CLUSTER_STEP = "SET_ACTIVE_CLUSTER_STEP";
export const SET_SHOW_DRAWER = "SET_SHOW_DRAWER";
export const SET_CREATE_GROUP_FORM = "SET_CREATE_GROUP_FORM";
export const SET_CLUSTER_NAME = "SET_CLUSTER_NAME";
export const SET_CLUSTER_TABLE = "SET_CLUSTER_TABLE";
export const SET_PUBLISH_CLUSTER = "SET_PUBLISH_CLUSTER";
export const SET_EDIT_CLUSTER = "SET_EDIT_CLUSTER";
export const SET_SAVE_CLUSTER = "SET_SAVE_CLUSTER";
export const SET_CREATEAD_GROUP = "SET_CREATEAD_GROUP";
export const SET_CREATEAD_ADS = "SET_CREATEAD_ADS";
export const SET_CLUSTER_KEYWORDS = "SET_CLUSTER_KEYWORDS";
export const SET_HEADLINE_ID = "SET_HEADLINE_ID";
export const SET_GET_DETAILS = "SET_GET_DETAILS";
export const SET_DESCRIPTION_ID = "SET_DESCRIPTION_ID";
export const SET_BRAND = "SET_BRAND";
export const SET_CLUSTER_LOADER = "SET_CLUSTER_LOADER";
export const SET_GROUPS_LOADER = "SET_GROUPS_LOADER";
export const SET_HEADER_LOADER = "SET_HEADER_LOADER";
export const SET_LOAD_HEADLINE_DESC = "SET_LOAD_HEADLINE_DESC";
export const SET_DESC_LOADER = "SET_DESC_LOADER";
export const SET_CLUSTERS = "SET_CLUSTERS";
export const SET_CLUSTER_DETAILS = "SET_CLUSTER_DETAILS";
export const SET_KEYWORD_FILE = "SET_KEYWORD_FILE";
export const SET_TARGET_KEYWORD = "SET_TARGET_KEYWORD";
export const SET_CREATE_AD_HEADLINE = "SET_CREATE_AD_HEADLINE";
export const SET_CREATE_AD_DESC = "SET_CREATE_AD_DESC";
export const SET_CREATE_HEADLINE_PARAM = "SET_CREATE_HEADLINE_PARAM";
export const SET_CREATE_DESC_PARAM = "SET_CREATE_DESC_PARAM";
export const SET_PINNING_POSITIONS = "SET_PINNING_POSITIONS";
export const SET_CREATIVE_REPLACEMENT = "SET_CREATIVE_REPLACEMENT";
export const CREATE_HEADLINE_ERROR = "CREATE_HEADLINE_ERROR";
export const CREATE_DESCRIPTION_ERROR = "CREATE_DESCRIPTION_ERROR";
export const SET_TIMER = "SET_TIMER";
export const GENERATE_HEADLINE_TIMESTAMP = "GENERATE_HEADLINE_TIMESTAMP";
export const GENERATE_DESCRIPTION_TIMESTAMP = "GENERATE_DESCRIPTION_TIMESTAMP";
export const GSAI_DROPDOWN_DISABLED = "GSAI_DROPDOWN_DISABLED";

export type ClusterState = {
    createAdCampaign: any,
    clusterSpinner: boolean,
    selectedTabIndex:number,
    activeClusterStep: any,
    showDrawer: any,
    createGroupForm: any,
    clusterName: any,
    clusterTable: any,
    publishCluster: any,
    editCluster:any,
    saveCluster:any,
    createAdAdGroup: any,
    createAdAds: any,
    clusterKeywords: any,
    getDetails: any,
    headlineID: any,
    descriptionID: any,
    brand: any,
    clusterLoader: any,
    groupsLoader:any;
    clusters: any,
    headerLoader: any,
    loadHeadlineDesc:any,
    descLoader: any,
    clusterDetails: any,
    keyWordsFile: any,
    targetKeyword: any,
    createAdHeadline: any,
    createAdDesc: any,
    createHeadlineParam: any,
    createDescParam: any,
    pinningPositions:any,
    creativeReplacement:any,
    timer: any,
    createHeadlineError:any,
    createDescError:any,
    generateHeadlineTimestamp:any,
    generateDescriptionTimestamp:any,
    gsaiDropdownDisabled:boolean;
}

export type SetCreateAdCampaign = {
    type: typeof SET_CREATEAD_CAMPAIGN;
    payload: any;
};

export type GetCreateAdCampaign = {
    type: typeof GET_CREATEAD_CAMPAIGN;
    payload: {
        accountID: string;
        user: any;
    };
    callback?: Function;
};

export type SetClusterSpinner = {
    type: typeof SET_CLUSTER_SPINNER;
    payload: any;
};

export type SetSelectedTabIndex = {
    type: typeof SET_SELECTED_TAB_INDEX;
    payload: any;
};

export type GetCreateAdGroup = {
    type: typeof GET_CREATEAD_GROUP;
    payload: {
        accountID: string;
        campaignId: string;
        user: any;
    };
    callback?: Function;
};

export type GetCreateAdAds = {
    type: typeof GET_CREATEAD_ADS;
    payload: {
        accountID: string;
        campaignId: string;
        adGroupId: string;
        user: any;
    };
    callback?: Function;
};

export type CreateCluster = {
    type: typeof CREATE_CLUSTER;
    payload: {
        accountID: string;
        file: any;
        user: any;
    };
    callback?: Function;
};

export type GetAllClusters = {
    type: typeof GET_ALL_CLUSTER;
    payload: {
        accountID: string;
        user: any;
    };
    callback?: Function;
};

export type GetBrand = {
    type: typeof GET_BRAND;
    payload: {
        accountID: string;
        user: any;
    };
    callback?: Function;
};

export type GetGPT3Reference = {
    type: typeof GET_GPT3_REFERENCE;
    payload: {
        platformId: string;
        brandId: string;
        accountID: string;
        user: any;
    };
    callback?: Function;
};

export type UpdateGPT3Reference = {
    type: typeof UPDATE_GPT3_REFERENCE;
    payload: {
        accountID: string;
        params: any;
        user: any;
    };
    callback?: Function;
};

export type GetArticleHeadline = {
    type: typeof GET_ARTICLE_HEADLINE;
    payload: {
        brandId: string,
        platformId: string,
        accountID: string;
        user: any;
    };
    callback?: Function;
};

export type GetArticleDescription = {
    type: typeof GET_ARTICLE_DESCRIPTION;
    payload: {
        brandId: string,
        platformId: string,
        accountID: string;
        user: any;
    };
    callback?: Function;
};

export type CreateHeadline = {
    type: typeof CREATE_HEADLINE;
    payload: {
        accountID: string;
        params: any;
        clusterID: string;
        user: any;
    };
    callback?: Function;
};

export type CreateDescription = {
    type: typeof CREATE_DESCRIPTION;
    payload: {
        accountID: string;
        params: any;
        clusterID: string;
        user: any;
    };
    callback?: Function;
};

export type GetHeadline = {
    type: typeof GET_HEADLINE;
    payload: {
        accountID: string;
        headlineId: any;
        clusterID: string;
        user: any;
    };
    callback?: Function;
};

export type GetDescription = {
    type: typeof GET_DESCRIPTION;
    payload: {
        accountID: string;
        descriptionId: any;
        clusterID: string;
        user: any;
    };
    callback?: Function;
};

export type GetElementLogs = {
    type: typeof GET_ELEMENT_LOGS;
    payload: {
        elementId: string;
        user:any;
    };
    callback?: Function;
};

export type GetClusterLogs = {
    type: typeof GET_CLUSTER_LOGS;
    payload: {
        publishClusterId: string;
        user:any;
    };
    callback?: Function;
};

export type GetClusterDetails = {
    type: typeof GET_CLUSTER_DETAILS;
    payload: {
        url: string;
    };
    callback?: Function;
};

export type GetPublishedClusterDetails = {
    type: typeof GET_PUBLISHED_CLUSTER_DETAILS;
    payload: {
        id: any;
        user: any;
    };
    callback?: Function;
};

export type GetPublishClusters = {
    type: typeof GET_PUBLISH_CLUSTER;
    payload: {
        accountID: string;
        user: any;
    };
    callback?: Function;
};

export type PublishClusterData = {
    type: typeof PUBLISH_CLUSTER_DATA;
    payload: {
        accountID: string;
        params: any;
        user: any;
    };
    callback?: Function;
};

export type UpdatePublishedCluster = {
    type: typeof UPDATE_PUBLISHED_CLUSTER;
    payload: {
        accountID: string;
        params: any;
        user: any;
    };
    callback?: Function;
};

export type UpdateCreativeReplacement = {
    type: typeof UPDATE_CREATIVE_REPLACEMENT;
    payload: {
        params: any;
        user: any;
    };
    callback?: Function;
};

export type UpdateAdRotation = {
    type: typeof UPDATE_AD_ROTATION;
    payload: {
        params: any;
        user: any;
    };
    callback?: Function;
};

export type SetActiveClusterStep = {
    type: typeof SET_ACTIVE_CLUSTER_STEP;
    payload: any;
};

export type SetShowDrawer = {
    type: typeof SET_SHOW_DRAWER;
    payload: any;
};

export type SetCreateGroupForm = {
    type: typeof SET_CREATE_GROUP_FORM;
    payload: any;
};

export type SetClusterName = {
    type: typeof SET_CLUSTER_NAME;
    payload: any;
};

export type SetClusterTable = {
    type: typeof SET_CLUSTER_TABLE;
    payload: any;
};

export type SetPublishCluster = {
    type: typeof SET_PUBLISH_CLUSTER;
    payload: any;
};

export type SetEditCluster = {
    type: typeof SET_EDIT_CLUSTER;
    payload: any;
};

export type SetSaveCluster = {
    type: typeof SET_SAVE_CLUSTER;
    payload: any;
};

export type SetCreateAdAdGroup = {
    type: typeof SET_CREATEAD_GROUP;
    payload: any;
};

export type SetCreateAdAds = {
    type: typeof SET_CREATEAD_ADS;
    payload: any;
};

export type SetClusterKeywords = {
    type: typeof SET_CLUSTER_KEYWORDS;
    payload: any;
};

export type SetGetDetails = {
    type: typeof SET_GET_DETAILS;
    payload: any;
};

export type SetHeadlineID = {
    type: typeof SET_HEADLINE_ID;
    payload: any;
};

export type SetDescriptionID = {
    type: typeof SET_DESCRIPTION_ID;
    payload: any;
};

export type SetBrand = {
    type: typeof SET_BRAND;
    payload: any;
};

export type SetClusterLoader = {
    type: typeof SET_CLUSTER_LOADER;
    payload: any;
};

export type SetGroupsLoader  = {
    type: typeof SET_GROUPS_LOADER;
    payload: any;
};

export type SetHeaderLoader = {
    type: typeof SET_HEADER_LOADER;
    payload: any;
};

export type SetLoadHeadLineDesc = {
    type: typeof SET_LOAD_HEADLINE_DESC;
    payload: any;
};

export type SetDescLoader = {
    type: typeof SET_DESC_LOADER;
    payload: any;
};

export type SetClusters = {
    type: typeof SET_CLUSTERS;
    payload: any;
};

export type SetClusterDetails = {
    type: typeof SET_CLUSTER_DETAILS;
    payload: any;
};

export type SetKeyWordsFile = {
    type: typeof SET_KEYWORD_FILE;
    payload: any;
};

export type SetTargetKeyword = {
    type: typeof SET_TARGET_KEYWORD;
    payload: any;
};

export type SetCreateAdHeadline = {
    type: typeof SET_CREATE_AD_HEADLINE;
    payload: any;
};

export type SetCreateAdDesc = {
    type: typeof SET_CREATE_AD_DESC;
    payload: any;
};

export type SetCreateHeadlineParam = {
    type: typeof SET_CREATE_HEADLINE_PARAM;
    payload: any;
};

export type SetCreateDescParam = {
    type: typeof SET_CREATE_DESC_PARAM;
    payload: any;
};

export type SetPinningPositions = {
    type: typeof SET_PINNING_POSITIONS;
    payload: any;
};

export type SetCreativeReplacement = {
    type: typeof SET_CREATIVE_REPLACEMENT;
    payload: any;
};

export type SetTimer = {
    type: typeof SET_TIMER;
    payload: any;
};

export type SetCreateHeadlineError ={
    type: typeof CREATE_HEADLINE_ERROR;
    payload:any;
}

export type SetCreateDescError ={
    type: typeof CREATE_DESCRIPTION_ERROR;
    payload:any;
}
export type SetGenerateHeadlineTimestamp ={
    type: typeof GENERATE_HEADLINE_TIMESTAMP;
    payload:any;
}
export type SetGenerateDescriptionTimeStamp ={
    type: typeof GENERATE_DESCRIPTION_TIMESTAMP;
    payload:any;
}

export type SetGSAIDropdownDisabled ={
    type: typeof GSAI_DROPDOWN_DISABLED;
    payload:boolean;
}

