import React, { useEffect, useState } from "react";
import "./index.scss";

const Switch: React.FC<{
  initialValue?: boolean;
  value?: boolean;
  onChange?: (value: boolean) => void;
}> = ({ value, onChange, initialValue }): JSX.Element => {
  const [checked, setChecked] = useState(!!initialValue);

  useEffect(() => {
    if (typeof value === "boolean") setChecked(value);
  }, [value]);

  return (
    <>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          e.stopPropagation();
          onChange && onChange(e.target.checked);
        }}
      />
      <label
        className="label-switch"
        onClick={(e) => {
          e.stopPropagation();
          setChecked((c) => {
            onChange && onChange(!c);
            return !c;
          });
        }}
      >
        <span className="switch"></span>
      </label>
    </>
  );
};

export default Switch;
