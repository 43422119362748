import { Box } from "@mui/material";
import { nanoid } from "nanoid";
import { useState, useEffect } from "react";
import { Button } from "../../../../shared";
import InputLabel from "../../../../shared/InputLabel/InputLabel";
import { FieldType } from "../SharedTypes";
import ListicleItem from "./ListicleItem";

export interface ListicleType {
  value: string;
  id: string;
}
export default function Listicle({
  maxValue,
  minValue,
  field,
  onChange,
  stateValue,
}: {
  maxValue: number;
  minValue: number;
  field: FieldType;
  onChange: any;
  stateValue: any;
}) {
  const [listicleState, setListicleState] = useState<ListicleType[]>([]);

  const addListicle = () => {
    if (listicleState.length < maxValue) {
      const newItem = {
        value: "",
        id: nanoid(),
      };
      setListicleState([...listicleState, newItem]);
    }
  };

  useEffect(() => {
    if (stateValue) {
      let localStateValue = stateValue;
      if (stateValue.length > maxValue) {
        localStateValue = stateValue.splice(0, maxValue);
      }
      setListicleState(
        localStateValue.map((value: string) => {
          return {
            value,
            id: nanoid(),
          };
        })
      );
    } else {
      const InitialItems = [];
      for (let i = 0; i < minValue; i++) {
        InitialItems.push({
          value: "",
          id: nanoid(),
        });
      }
      setListicleState(InitialItems);
    }
  }, []);

  useEffect(() => {
    onChange(listicleState.map((l) => l.value));
  }, [listicleState]);

  const onCross = (id: string) => {
    if (listicleState.length > minValue) {
      const localListicleState = listicleState.filter((element) => {
        return id !== element.id;
      });
      setListicleState(localListicleState);
    } else {
      resetValue(id);
    }
  };

  const resetValue = (id: string) => {
    const localListicleState = [...listicleState];
    const index = localListicleState.findIndex(
      (item: ListicleType) => item.id === id
    );
    localListicleState[index].value = "";
    setListicleState(localListicleState);
  };
  const onInputChange = (value: string, index: number) => {
    const localListicleState = [...listicleState];
    localListicleState[index].value = value;
    setListicleState(localListicleState);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="start"
      width="100%"
    >
      <InputLabel
        required={field.is_required}
        label={field.field_label}
        tooltipText={field.helper_text}
      />
      {listicleState.map((item: ListicleType, index: number) => (
        <ListicleItem
          onCross={onCross}
          item={item}
          onInputChange={onInputChange}
          index={index}
          key={item.id}
        />
      ))}

      <Button
        sx={{
          marginTop: "5px",
        }}
        onClick={addListicle}
      >
        + Add Line
      </Button>
    </Box>
  );
}
