import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import GreyLoader from "../assets/gif/GreyLoader.gif";
import AdAccountRow from "./AdAccountRow";

function BrandAdAccountRow({
  brandId,
  onClick,
  showOptimizer,
  setShowOptimizer,
  showPlatform,
  setShowPlatform,
  setEditBrandOpen,
  brandName,
  brandData,
  activeTileKey,
}: any) {
  return (
    <>
      {!brandData?.accountDetails && (
        <Box onClick={onClick} sx={{ cursor: "pointer", width: "100%" }}>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #E7E7E7",
              boxShadow: "0px 3.71014px 16.0773px #F2F2F2",
              borderRadius: "11px",
              margin: "10px 10px 10px 0px",
              minHeight: "145px",
              padding: "25px 10px 10px 10px",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              display: `${
                !brandData?.accountDetails || !brandData?.accountDetails?.length
                  ? "flex"
                  : ""
              }`,
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                background: "#EAEAEA",
                height: "59px",
                width: "auto",
                top: "-21px",
                left: "-35px",
                borderRadius: "59.5px",
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
                padding: "0px 11px 0px 39px",
              }}
            >
              <Box
                sx={{
                  width: "auto",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2px 13px 2px 9px",
                }}
              >
                <Typography
                  sx={{ fontSize: "10px", fontWeight: "600", color: "#000000" }}
                >
                  {brandName}
                </Typography>
              </Box>
            </Box>
            {!brandData && (
              <img
                src={GreyLoader}
                alt="Loading"
                style={{
                  height: "8rem",
                  width: "auto",
                  position: "absolute",
                  top: "9%",
                  left: "43%",
                }}
              />
            )}
          </Box>
        </Box>
      )}
      {brandData?.accountDetails?.filter(
        (item: any) =>
          "total_brands" === activeTileKey ||
          item?.brandStatus === activeTileKey
      ).length > 0 && (
        <Box onClick={onClick} sx={{ cursor: "pointer", width: "100%" }}>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #E7E7E7",
              boxShadow: "0px 3.71014px 16.0773px #F2F2F2",
              borderRadius: "11px",
              margin: "10px 10px 10px 0px",
              minHeight: "145px",
              padding: "25px 10px 10px 10px",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              paddingTop: "46px",
              display: `${
                !brandData?.accountDetails || !brandData?.accountDetails?.length
                  ? "flex"
                  : ""
              }`,
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                background: "#EAEAEA",
                height: "59px",
                // width: "166px",
                top: "-21px",
                left: "-35px",
                borderRadius: "59.5px",
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
                padding: "0px 11px 0px 39px",
              }}
            >
              <Box
                sx={{
                  // width: "130px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2px 13px 2px 9px",
                }}
              >
                <Typography
                  sx={{ fontSize: "10px", fontWeight: "600", color: "#000000" }}
                >
                  {brandName}
                </Typography>
              </Box>
            </Box>
            {!brandData ? (
              <img
                src={GreyLoader}
                alt="Loading"
                style={{
                  height: "8rem",
                  width: "auto",
                  position: "absolute",
                  top: "9%",
                  left: "43%",
                }}
              />
            ) : (
              <>
                {!brandData?.accountDetails ||
                !brandData?.accountDetails?.length ? (
                  <div>No Ad Account Found</div>
                ) : (
                  brandData?.accountDetails
                    ?.filter(
                      (item: any) =>
                        "total_brands" === activeTileKey ||
                        item?.brandStatus === activeTileKey
                    )
                    ?.map((item: any) => (
                      <AdAccountRow
                        rowData={item}
                        key={brandId}
                        brandId={brandId}
                        showOptimizer={showOptimizer}
                        setShowOptimizer={setShowOptimizer}
                        showPlatform={showPlatform}
                        setShowPlatform={setShowPlatform}
                        setEditBrandOpen={setEditBrandOpen}
                      />
                    ))
                )}
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default BrandAdAccountRow;
