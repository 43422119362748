import { Box, Dialog, FormControlLabel, IconButton, Radio, RadioGroup, makeStyles, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactComponent as IconCsv } from "../../ManualActionButton/assets/csv-file.svg";
import { ReactComponent as IconBulkActions } from "../../ManualActionButton/assets/single-bulk-actions.svg";
import { ReactComponent as IconClose } from "../../ManualActionButton/assets/close.svg";
import { ReactComponent as IconUncheck } from "../../ManualActionButton/assets/uncheck.svg";
import { ReactComponent as IconCheck } from "../../ManualActionButton/assets/check.svg";
import {ReactComponent as IconCSVDownload} from './assets/download-csv-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFileForRecommendationDV360 } from 'store/actions/manualAction';
import { recommendationKeyFromBackend, recommendationKeyValueMapping } from './recommendationKeyHeaderMapping';
import { exportToCsv } from 'utils/commonFunctions';
import { AdAccountState } from 'store/types/adaccount';
import moment from 'moment';
// styled components
const StyledHeaderText = styled("h1")({
  color: "var(--color-light-theme-content-primary, #000)",
  fontFamily: "Inter",
  fontSize: "1.25em",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "2em",
  letterSpacing: "-0.0187em",
  margin: 0,
});

const StyledDescription = styled("p")({
  color: "var(--color-light-theme-content-secondary, #5C6674)",
  fontFamily: "Inter",
  fontSize: ".875em",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1.375em",
  letterSpacing: ".0088em",
  margin: 0,
  textAlign: "left",
  "& b": {
    fontWeight: 600,
    lineHeight: "1.375em",
    letterSpacing: ".0109em",
    color: "var(--color-light-theme-content-secondary, #5C6674)"
  },
});

const StyledCard = styled("button")({
  display: "flex",
  padding: "1.25em",
  flexDirection: "column",
  gap: ".5em",
  alignSelf: "stretch",
  borderRadius: ".75em",
  border: ".125em solid var(--color-palette-gray-100, #ECEEF1)",
  background: "var(--color-palette-white, #FFF)",
  boxShadow: "0em .0625em .125em 0em rgba(16, 24, 40, 0.05)",
  "&[data-selected='true']": {
    border: ".125em solid var(--color-light-theme-background-positive-high-priority, #97D9B5)",
  },
});

const StyledIconContainer = styled("div")({
  width: "3em",
  height: "3em",
  flexShrink: 0,
  borderRadius: ".625em",
  border: ".0625em solid var(--color-palette-gray-200, #E0E3E5)",
  background: "var(--color-palette-white, #FFF)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&[data-selected='true']": {
    background: "var(--color-palette-green-50, #EAF7F0)",
  }
});

const StyledCloseIconContainer = styled(IconButton)({
  width: "40px",
  height: "40px",
  padding: "8px",
  borderRadius: "8px",
  background: "var(--color-palette-gray-50, #F6F7F8)",
});

const StyledCardTitle = styled("h2")({
  color: "var(--color-light-theme-content-primary, #000)",
  fontFamily: "Inter",
  fontSize: "1.125em",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "1.5em",
  letterSpacing: "-0.0112em",
});

// types
export enum CardType {
  DOWNLOAD_RECOMMENDATION = "DOWNLOAD_RECOMMENDATION",
  CSV_UPLOAD = "CSV_UPLOAD",
};

export type CardSelectionProps = {
  onSelect: (value: CardType | null) => void;
  open: boolean;
  onClose: () => void;
  setActions:(value:string)=>void;
  rangeState:any;
}

// constants
const REDIRECTION_DELAY_IN_MS = 700;

const CARDS = [{
  title: "Download .CSV",
  description: (
    <>
     Download the recommendations that have been generated for the AI group and time period that has been selected
    </>
  ),
  key: CardType.DOWNLOAD_RECOMMENDATION,
  icon: <IconCSVDownload />
}, {
  title: "Upload .CSV",
  description: (
    <>
      Upload a .CSV file containing all the actions that you want to execute on any assets for the selected AI group and platform
    </>
  ),
  key: CardType.CSV_UPLOAD,
  icon: <IconCsv />
}]

// component
export default function UploadDownloadCardSelection({
  onSelect,
  open,
  onClose,
  setActions,
  rangeState
}: CardSelectionProps) {
  const [selectedCardType, setSelectedCardType] = useState<CardType | null>(null);
  const _onClose = () => {
    onSelect(null);
    setSelectedCardType(null);
    onClose();
  };
  const dispatch = useDispatch();
  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const {
    selectedPlatform,
    selectedAdAccount
  } = adaccount;
  const onCardClickCurried = (type: CardType) => () => setSelectedCardType(type);

  useEffect(() => {
    if (!selectedCardType) return;
    const timeoutId = setTimeout(() => {
      onSelect(selectedCardType);
    }, REDIRECTION_DELAY_IN_MS);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [selectedCardType]);
    //This will be used for download csv on modal when download upload is selected from actions based on api call for dv360
  useEffect(()=>{
    if(selectedCardType === CardType.DOWNLOAD_RECOMMENDATION){
     dispatch(downloadFileForRecommendationDV360({
       aggregation_concept_id:selectedAdAccount?.elementId,
       start_date:moment(rangeState[0].startDate).format("YYYY-MM-DD"),
       end_date: moment(rangeState[0].endDate).format("YYYY-MM-DD"),
     },(response:any,error:boolean)=>{
         if(!error){
           if(response.data.data.data.recommendations.length){
             const result = [
               [...Object.values(recommendationKeyValueMapping)],
               ...response.data.data.data.recommendations.map((element: any) =>
               recommendationKeyFromBackend.map((keyword: any) => element[keyword])
               ),
             ];
             exportToCsv("recommendation_result.csv", result);
           }else{
             alert("No recommendations found.");
           }
         }
         setSelectedCardType(null);
         setActions("");
     }))
    }
 },[selectedCardType]);
  return (
    <Dialog
      open={open}
      onClose={_onClose}
      PaperProps={{
        sx: {
          p: 2,
          borderRadius: "12px",
          width: "530px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          fontSize: "14px",
        }}
      >
        <Box
          component="header"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <StyledHeaderText>Download & Update Recommendations</StyledHeaderText>
            <StyledDescription>Download the generated recommendations and upload csv file with changes in recommendations and/or execution status</StyledDescription>
          </Box>
          <StyledCloseIconContainer onClick={_onClose} disableRipple disableTouchRipple>
            <IconClose />
          </StyledCloseIconContainer>
        </Box>
        <Box sx={{ display: "flex", gap: "1em" }}>
          {CARDS.map(card => (
            <StyledCard
              data-selected={selectedCardType === card.key} key={card.key}
              onClick={onCardClickCurried(card.key)}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                  marginBottom: "1em"
                }}
              >
                <StyledIconContainer data-selected={selectedCardType === card.key} key={card.key}>
                  {card.icon}
                </StyledIconContainer>
                {selectedCardType === card.key ? <IconCheck /> : <IconUncheck />}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <StyledCardTitle>{card.title}</StyledCardTitle>
                <StyledDescription>{card.description}</StyledDescription>
              </Box>
            </StyledCard>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};