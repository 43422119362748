import {
  CreateMediaStudio,
  CREATE_MEDIA_STUDIO,
  DeleteMediaStudio,
  DELETE_MEDIA_STUDIO,
  GetMediaData,
  GetMediaList,
  GetPresignedUrl,
  GET_MEDIA_DATA,
  GET_MEDIA_LIST,
  GET_PRESIGNED_URL,
  SetUploadToggle,
  SET_UPLOAD_TOGGLE,
  UpdateMediaStudio,
  UPDATE_MEDIA_STUDIO,
  UploadMedia,
  UPLOAD_MEDIA,
} from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";

export const getMediaList = (payload?: any): GetMediaList => {
  return {
    type: GET_MEDIA_LIST,
    payload,
  };
};

export const getMediaData = (payload?: any): GetMediaData => {
  return {
    type: GET_MEDIA_DATA,
    payload,
  };
};

export const updateMediaStudio = (payload?: any): UpdateMediaStudio => {
  return {
    type: UPDATE_MEDIA_STUDIO,
    payload,
  };
};

export const deleteMediaStudio = (payload?: any): DeleteMediaStudio => {
  return {
    type: DELETE_MEDIA_STUDIO,
    payload,
  };
};

export const createMediaStudio = (payload?: any): CreateMediaStudio => {
  return {
    type: CREATE_MEDIA_STUDIO,
    payload,
  };
};

export const uploadMedia = (payload?: any): UploadMedia => {
  return {
    type: UPLOAD_MEDIA,
    payload,
  };
};

export const getPresignedUrl = (payload: any): GetPresignedUrl => {
  return {
    type: GET_PRESIGNED_URL,
    payload,
  };
};

export const setUploadToggle = (payload: boolean): SetUploadToggle => {
  return {
    type: SET_UPLOAD_TOGGLE,
    payload,
  };
};
