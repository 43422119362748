import React from "react";
import { Spinner } from "react-bootstrap";

type LoaderProps = {
  height?: string;
  width?: string;
  animation?: "border" | "grow";
  size?: "sm" | undefined;
};
const Loader: React.FC<LoaderProps> = ({ height, width, animation, size }) => (
  <div
    className="text-center align-middle d-flex align-items-center justify-content-center"
    style={{
      height: height || "200px",
      width: width || "100%",
      zIndex: 999,
    }}
  >
    <Spinner animation={animation || "border"} role="status" size={size}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

export default Loader;
