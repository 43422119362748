import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback, useState } from "react";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { getRoundedValue } from "utils/commonFunctions";

import SingleTile from "./SingleTile";
import {
  getBrandMetrics,
  resetBrandMetrics,
} from "../../../store/actions/SmbDashboard/brand";
import { SECONDARY_TILES_DATA_ICON_MAP } from "../constants/index";
import { useLocation } from "react-router-dom";
import {
  getActualCostDeviationColor,
  getActualSpendsAndBudgetDeviationColor,
  getCurrency,
  getDeviationArrow,
} from "../utils";

function SecondaryTilesRow() {
  const dispatch = useDispatch();
  const selectedBrandState = useSelector(
    (state: any) => state.smb.selectedBrand
  );
  const commonState = useSelector((state: any) => state.smb.common);
  const brandState = useSelector((state: any) => state.smb.brand);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const brandId = selectedBrandState?.id || params.get("brand_id");

  const [isLoading, setIsloading] = useState(false);

  const getBrandMetricsCallBack = useCallback(() => {
    dispatch(
      getBrandMetrics({
        brandIds: [brandId],
        selectedMonth: commonState.calendar.selectedMonth,
        selectedYear: commonState.calendar.selectedYear,
      })
    );
  }, [brandId, commonState.calendar]);

  useEffect(() => {
    getBrandMetricsCallBack();
    return () => {
      if (brandState && brandState.data) {
        Object.keys(brandState.data).forEach((brandId: any) => {
          dispatch(
            resetBrandMetrics({
              brandId,
            })
          );
        });
      }
    };
  }, []);

  const selectedBrandData =
    (brandState.data && brandState.data[brandId]?.metrics) || null;

  const currency =
    selectedBrandData && selectedBrandData.currency
      ? getCurrency(selectedBrandData.currency)
      : " ";

  useEffect(() => {
    selectedBrandData === null ? setIsloading(true) : setIsloading(false);
  }, [brandState]);

  const getDeviation = (deviationValue: any, getDeviationColor: Function) => {
    return (
      <>
        {getDeviationArrow(deviationValue) === "up" ? (
          <ArrowUpwardIcon
            sx={{
              fontSize: "1rem",
              color: getDeviationColor(deviationValue),
            }}
          />
        ) : (
          <ArrowDownwardIcon
            sx={{
              fontSize: "1rem",
              color: getDeviationColor(deviationValue),
            }}
          />
        )}
        <Typography
          sx={{
            fontSize: "1rem",
            color: getDeviationColor(deviationValue),
          }}
        >
          {getRoundedValue(deviationValue)}%
        </Typography>
      </>
    );
  };

  return (
    <Grid
      container
      display="grid"
      direction={{
        xs: "column",
        md: "column",
        lg: "row",
        xl: "row",
      }}
      sx={{
        height:"auto",
        gridAutoFlow: {
          xs: "row",
          sm: "row",
          md: "row",
          lg: "column",
          xl: "column",
        },
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(4, 1fr)",
        },
      }}
    >
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <SingleTile
          value={
            selectedBrandData?.spends?.target
              ? getRoundedValue(selectedBrandData?.spends?.target)
              : "-"
          }
          label={`Target Spends`}
          tileKey={"target_spends"}
          Icon={SECONDARY_TILES_DATA_ICON_MAP["target_spends"]}
          isSecondaryTile
          currencyType={currency}
          isLoading={
            isLoading || !selectedBrandData || selectedBrandData?.isLoading
          }
        />
        <SingleTile
          value={
            selectedBrandData?.spends?.actual
              ? getRoundedValue(selectedBrandData?.spends?.actual)
              : "-"
          }
          label={`Actual Spends`}
          tileKey={"actual_spends"}
          Icon={SECONDARY_TILES_DATA_ICON_MAP["actual_spends"]}
          deviation={
            selectedBrandData?.spends?.deviation
              ? getDeviation(
                  selectedBrandData.spends.deviation,
                  getActualSpendsAndBudgetDeviationColor
                )
              : null
          }
          isSecondaryTile
          currencyType={currency}
          isLoading={
            isLoading || !selectedBrandData || selectedBrandData?.isLoading
          }
        />
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <SingleTile
          value={
            (selectedBrandData?.dailyBudget?.target || selectedBrandData?.dailyBudget?.target==0)
              ? getRoundedValue(selectedBrandData?.dailyBudget?.target)
              : "-"
          }
          label={`Target Daily Budget`}
          tileKey={"target_daily_budget"}
          Icon={SECONDARY_TILES_DATA_ICON_MAP["target_daily_budget"]}
          isSecondaryTile
          currencyType={currency}
          isLoading={
            isLoading || !selectedBrandData || selectedBrandData?.isLoading
          }
        />
        <SingleTile
          value={
            (selectedBrandData?.dailyBudget?.actual || selectedBrandData?.dailyBudget?.actual==0)
              ? getRoundedValue(selectedBrandData?.dailyBudget?.actual)
              : "-"
          }
          label={`Actual Daily Budget`}
          tileKey={"actual_daily_budget"}
          Icon={SECONDARY_TILES_DATA_ICON_MAP["actual_daily_budget"]}
          deviation={
            selectedBrandData?.dailyBudget?.deviation
              ? getDeviation(
                  selectedBrandData.dailyBudget.deviation,
                  getActualSpendsAndBudgetDeviationColor
                )
              : null
          }
          isSecondaryTile
          currencyType={currency}
          isLoading={
            isLoading || !selectedBrandData || selectedBrandData?.isLoading
          }
        />
      </Grid>

      {selectedBrandData?.metrics?.map((el: any, index: number) => (
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} key={index}>
          <SingleTile
            value={ getRoundedValue(el?.targetCost) ??  "-"}
            label={`Target Cost Per ${
              el.name && el.name !== "" ? el.name : "Result"
            }`}
            tileKey={"target_cost_per_metric"}
            Icon={SECONDARY_TILES_DATA_ICON_MAP["target_cost_per_metric"]}
            isSecondaryTile
            currencyType={currency}
            isLoading={
              isLoading || !selectedBrandData || selectedBrandData?.isLoading
            }
          />
          <SingleTile
            value={getRoundedValue(el?.actualCost) ??  "-"}
            label={`Actual Cost Per ${
              el.name && el.name !== "" ? el.name : "Result"
            }`}
            tileKey={"actual_cost_per_metric"}
            Icon={SECONDARY_TILES_DATA_ICON_MAP["actual_cost_per_metric"]}
            deviation={
              el.deviation
                ? getDeviation(el.deviation, getActualCostDeviationColor)
                : null
            }
            isSecondaryTile
            currencyType={currency}
            isLoading={
              isLoading || !selectedBrandData || selectedBrandData?.isLoading
            }
          />
        </Grid>
      ))}
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <SingleTile
          value={
            (selectedBrandData?.roas?.target || selectedBrandData?.roas?.target==0)
              ? getRoundedValue(selectedBrandData?.roas?.target)
              : "-"
          }
          label={`Target ROAS`}
          tileKey={"target_daily_budget"}
          Icon={SECONDARY_TILES_DATA_ICON_MAP["target_daily_budget"]}
          isSecondaryTile
          isLoading={
            isLoading || !selectedBrandData || selectedBrandData?.isLoading
          }
        />
        <SingleTile
          value={
           ( selectedBrandData?.roas?.actual || selectedBrandData?.roas?.actual==0 )
              ? getRoundedValue(selectedBrandData?.roas?.actual)
              : "-"
          }
          label={`Actual ROAS`}
          tileKey={"actual_daily_budget"}
          Icon={SECONDARY_TILES_DATA_ICON_MAP["actual_daily_budget"]}
          deviation={
            selectedBrandData?.roas?.deviation
              ? getDeviation(
                  selectedBrandData.roas.deviation,
                  getActualSpendsAndBudgetDeviationColor
                )
              : null
          }
          isSecondaryTile
          isLoading={
            isLoading || !selectedBrandData || selectedBrandData?.isLoading
          }
        />
      </Grid>
    </Grid>
  );
}

export default SecondaryTilesRow;
