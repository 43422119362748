import { SetAccountDetails, SET_ACCOUNT_DETAILS } from "store/types/auth";
import {
  DELETE_RECOMMENDED_KEYWORDS,
  DeleteRecommendedKeywords,
  GET_CUSTOM_AUDIENCES,
  GetCustomAudiences,
  RESET_NODES_LOADER_STATE,
  SET_ENABLE_CUSTOM_KEYWORDS,
  SET_KEYWORD_TUNING_JSON,
  SET_LOADED_KEYWORDS,
  SET_NEGATE_TARGETING,
  SET_TYPE_OF_COHORT,
  STORE_RECOMMENDED_KEYWORDS,
  SUBMIT_COHORTS,
  SetEnableCustomKeywords,
  SetKeywordTuningJson,
  SetLoadedKeywords,
  SetNegateTargeting,
  SetTypeOfCohort,
  StoreRecommendedKeywords,
  SubmitCohorts,
} from "../types/targetingAI";
import {
  GET_BRAND_AIGROUP_META,
  GetBrandAIGroupsMeta,
  GET_COUNTRIES,
  GetCountries,
  GET_REGIONS,
  GetRegions,
  GET_TAI_CLUSTER,
  GetTAICluster,
  GetTAIKWS,
  GET_TAI_KWS,
  STORE_TAI_CLUSTER,
  StoreTAICluster,
  STORE_TAI_KWS,
  StoreTAIKWS,
  SET_AI_INITIATION_DATA,
  SetAiInitiationData,
  SET_CALIBRATION_DATA,
  SetCalibrationData,
  SET_KEYWORD_TUNING_DATA,
  SetKeywordTuningData,
  STORE_TAI_COHORT,
  StoreTAICohort,
  SET_CALIBRATION_FORM_DATA,
  ResetCalibrationData,
  RESET_CALIBRATION_FORM_DATA,
  SetCurrentPage,
  SET_CURRENT_PAGE,
  SetPolling,
  SET_POLLING,
  SetAiInitiationValue,
  SET_AI_INITIATION_VALUE,
  UpdatedRecommendedExclusions,
  UPDATE_RECOMMENDED_EXCLUSIONS_STATE,
  SetUpdatedRecommendedExclusions,
  SET_UPDATED_RECOMMENDED_EXCLUSIONS_STATE,
  Cohort,
  GetAdAccounts,
  GET_AD_ACCOUNTS,
  SetSelectedCohorts,
  SET_SELECTED_COHORTS,
  ResetAiInitiation,
  RESET_AI_INITIATION,
  SetAllCalibrationFormData,
  SET_ALL_CALIBRATION_FORM_DATA,
  SetNodeCollapse,
  SET_NODE_COLLAPSE,
  GET_CAMPAIGN_DETAILS_TAI,
  GetCampaignDetailsTAI,
  SET_CAMPAIGN_DETAILS_TAI,
  SetCampaignDetailsTAI,
  GET_AUDIENCES,
  GetAudiences,
  GET_CAMPAIGN_AUDIENCES,
  SET_CAMPAIGN_AUDIENCES,
  SetCampaignAudience,
  GetCampaignAudience,
  StoreKeywords,
  STORE_KEYWORDS,
  SET_CAMPAIGN_AUDIENCE_DETAILS,
  STORE_FAVOURITE_COHORT,
  StoreFavouriteCohort,
  DELETE_FAVOURITE_COHORT,
  DeleteFavouriteCohort,
  GET_DRAFTED_RECOMMENDED_COHORTS,
  GetDraftedAndRecommendedCohorts,
  SetAdditionalInputs,
  SET_ADDITIONAL_INPUTS,
  MERGE_COHORTS,
  REFRESH_COHORTS,
  RefreshCohorts,
} from "../types/targetingAI";

export const getCampaignDetailsTAI = (payload: any): GetCampaignDetailsTAI => ({
  type: GET_CAMPAIGN_DETAILS_TAI,
  payload,
});
export const setCampaignDetailsTAI = (payload: any): SetCampaignDetailsTAI => ({
  type: SET_CAMPAIGN_DETAILS_TAI,
  payload,
});

export const getBrandAIGroups = (
  payload: any,
  callback: Function
): GetBrandAIGroupsMeta => ({
  type: GET_BRAND_AIGROUP_META,
  payload,
  callback,
});

export const getTargetingAICountries = (callback: Function): GetCountries => ({
  type: GET_COUNTRIES,
  callback,
});

export const getTargetingAIRegions = (
  payload: any,
  callback: Function
): GetRegions => ({
  type: GET_REGIONS,
  payload,
  callback,
});

export const getTargetingAIAudienes = (
  payload: any,
  callback: Function
): GetAudiences => ({
  type: GET_AUDIENCES,
  payload,
  callback,
});

export const getCustomAudiences = (payload:any,callback:Function) : GetCustomAudiences => ({
  type:GET_CUSTOM_AUDIENCES,
  payload,
  callback,
})

export const getCampaignAudience = (
  payload: any,
  aiGroupId: string,
  user: any,
  callback: Function
): GetCampaignAudience => ({
  type: GET_CAMPAIGN_AUDIENCES,
  payload,
  aiGroupId,
  user,
  callback,
});

export const setAiInitiationData = (payload: any): SetAiInitiationData => ({
  type: SET_AI_INITIATION_DATA,
  payload,
});

export const setCampaignAudience = (payload: any): SetCampaignAudience => {
  return {
    type: SET_CAMPAIGN_AUDIENCES,
    payload,
  };
};

export const setAdAccountDetails = (payload: any): SetAccountDetails => {
  return {
    type: SET_ACCOUNT_DETAILS,
    payload,
  };
};

export const saveCampaignAudience = (
  payload: any,
  aiGroupId: any,
  callback: any
) => ({
  type: SET_CAMPAIGN_AUDIENCE_DETAILS,
  payload,
  aiGroupId,
  callback,
});

export const storeTargetingAIKWS = (
  payload: {
    kwsData: {
      name: string;
      website: string;
      setup: any;
      country: string;
      ad_account_id?:string;
    };

    user: any;
  },
  callback: Function
): StoreTAIKWS => ({
  type: STORE_TAI_KWS,
  payload,
  callback,
});

export const getTargetingAIKWS = (
  payload: any,
  callback: Function
): GetTAIKWS => ({
  type: GET_TAI_KWS,
  payload,
  callback,
});

export const setCalibrationData = (payload: any): SetCalibrationData => ({
  type: SET_CALIBRATION_DATA,
  payload,
});

export const setCalibrationFormData = (payload: any): SetCalibrationData => ({
  type: SET_CALIBRATION_FORM_DATA,
  payload,
});

export const setLoadedKeywords = (payload: {keywordType:string, loadedKeywords: number}): SetLoadedKeywords => ({
  type: SET_LOADED_KEYWORDS,
  payload,
});

export const setTypeOfCohort = (payload: string): SetTypeOfCohort => ({
  type: SET_TYPE_OF_COHORT,
  payload,
});

export const resetCalibrationData = (): ResetCalibrationData => ({
  type: RESET_CALIBRATION_FORM_DATA,
});

export const getTargetingAICluster = (
  payload: any,
  callback: Function
): GetTAICluster => ({
  type: GET_TAI_CLUSTER,
  payload,
  callback,
});

export const storeTargetingAICluster = (
  payload: {
    clusterData: {
      keywords: any;
      geo_locations: any;
      brand: string;
      custom_audience_details: any;
    };
    user: any;
    aiGroupId: any;
    platformId: any;
  },
  callback: Function
): StoreTAICluster => ({
  type: STORE_TAI_CLUSTER,
  payload,
  callback,
});

export const setKeywordTuningData = (payload: any): SetKeywordTuningData => ({
  type: SET_KEYWORD_TUNING_DATA,
  payload,
});

export const setKeywordTuningJson = (payload: any): SetKeywordTuningJson => {
  console.log("in action set",payload)
  return({
  type: SET_KEYWORD_TUNING_JSON,
  payload,
})};

export const storeTargetingAICohort = (
  payload: {
    action_list: Cohort[];
    email_data: any;
    delete_cohorts: string[];
    user: any;
  },
  callback: Function
): StoreTAICohort => ({
  type: STORE_TAI_COHORT,
  payload,
  callback,
});

export const setCurrentPage = (payload: number): SetCurrentPage => ({
  type: SET_CURRENT_PAGE,
  payload,
});

export const getAdAccounts = (
  payload: any,
  callback: Function
): GetAdAccounts => ({
  type: GET_AD_ACCOUNTS,
  payload,
  callback,
});

export const setSelectedCohortsForPublish = (
  payload: any
): SetSelectedCohorts => ({
  type: SET_SELECTED_COHORTS,
  payload,
});

export const setPolling = (payload: {
  name: string;
  requestId: string;
}): SetPolling => ({
  type: SET_POLLING,
  payload,
});

export const setAiInitiationValue = (payload: {
  value: any;
  key: string;
}): SetAiInitiationValue => ({
  type: SET_AI_INITIATION_VALUE,
  payload,
});

export const updateTAIRecommendedExclusions = (
  payload: {
    data: any;
    adAccountId?: any;
    user?: any;
  },
  callback: Function
): UpdatedRecommendedExclusions => ({
  type: UPDATE_RECOMMENDED_EXCLUSIONS_STATE,
  payload,
  callback,
});

export const setTAIUpdatedRecommendedExclusions = (
  payload: any
): SetUpdatedRecommendedExclusions => ({
  type: SET_UPDATED_RECOMMENDED_EXCLUSIONS_STATE,
  payload,
});

export const resetAiInitiation = (): ResetAiInitiation => ({
  type: RESET_AI_INITIATION,
});

export const setAllCalibrationFormData = (payload: {
  value: any;
  key: string;
}): SetAllCalibrationFormData => ({
  type: SET_ALL_CALIBRATION_FORM_DATA,
  payload,
});

export const setNodeCollapse = (payload: {
  type: string;
  collapse: boolean;
}): SetNodeCollapse => ({
  type: SET_NODE_COLLAPSE,
  payload,
});

export const resetNodesLoaderState = () => ({ type: RESET_NODES_LOADER_STATE });

export const storeKeywords = (
  payload: any,
  callback: Function
): StoreKeywords => ({
  type: STORE_KEYWORDS,
  payload,
  callback,
});

export const storeRecommendedKeywords = (
  payload: any,
  callback: Function
): StoreRecommendedKeywords => ({
  type: STORE_RECOMMENDED_KEYWORDS,
  payload,
  callback,
});

export const deleteRecommendedKeywords = (
  payload: any,
  callback: Function
): DeleteRecommendedKeywords => ({
  type: DELETE_RECOMMENDED_KEYWORDS,
  payload,
  callback,
});

export const storeTAIFavouriteCohort = (
  payload: {
    favouriteCohort: [{}];
    aiGroupId: any;
    user: any;
  },
  callback: Function
): StoreFavouriteCohort => ({
  type: STORE_FAVOURITE_COHORT,
  payload,
  callback,
});

export const deleteTAIFavouriteCohort = (
  payload: {
    deleteFavouriteCohortIds: any[];
    aiGroupId: any;
    user?: any;
  },
  callback: Function
): DeleteFavouriteCohort => ({
  type: DELETE_FAVOURITE_COHORT,
  payload,
  callback,
});

export const getTAIDraftedAndRecommendedCohorts = (
  payload: {
    type: any;
    aiGroupId: any;
  },
  callback: Function
): GetDraftedAndRecommendedCohorts => ({
  type: GET_DRAFTED_RECOMMENDED_COHORTS,
  payload,
  callback,
});

export const setAdditionalInputs = (payload: {
  locationFilter: boolean;
  specialAdCategories: string[];
}): SetAdditionalInputs => ({
  type: SET_ADDITIONAL_INPUTS,
  payload,
});

export const setNegateTargeting = (payload: {
  negateTargeting: boolean;
}): SetNegateTargeting => ({
  type: SET_NEGATE_TARGETING,
  payload,
});

export const setEnableCustomKeywords = (payload: {
  enableCustomKeywords: boolean;
}): SetEnableCustomKeywords => ({
  type: SET_ENABLE_CUSTOM_KEYWORDS,
  payload,
});

export const mergeCohorts = (payload: any, callback: any): any => ({
  type: MERGE_COHORTS,
  payload,
  callback,
});

export const refreshCohorts = (
  payload: {
    data: {
      cohorts_json: string;
      updated_cohorts: any[];
    };
    user: any;
  },
  callback: any
): RefreshCohorts => ({
  type: REFRESH_COHORTS,
  payload,
  callback,
});

export const submitCohorts = (
  payload: {
    data: {
      cohorts_json: string;
      updated_cohorts: any[];
    };
    user: any;
  },
  callback: any
): SubmitCohorts => ({
  type: SUBMIT_COHORTS,
  payload,
  callback,
});
