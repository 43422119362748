export type ExploitKeyword = {
  ["customer.id"]: number;
  ["campaign.id"]: number;
  ["campaign.name"]: string;
  ["adGroup.id"]: number;
  ["adGroup.name"]: string;
  ["segments.keyword.info.matchType"]: string;
  ["segments.keyword.info.text"]: string;
  ["adGroupAd.status"]: string;
  ["searchTermView.searchTerm"]: string;
  ["searchTermView.status"]: string;
  ["metrics.clicks"]: number;
  ["metrics.conversions"]: number;
  ["metrics.conversions_ile"]: number;
  ["metrics.impressions"]: number;
  ["metrics.costPerConversion"]: number;
  ["metrics.costMicros"]: number;
  ["cost_metric"]: number;
  ["cost_metric_ile"]: number;
  ["date_range_type"]: string;
  ["per_score"]: number;
  ["metrics.averageCpc"]: number;
  ["cluster_id"]: number | string;
  ["cluster_size"]: number;
  ["cluster_name"]: string;
  ["index"]: number;
  ["saveId"]: number; // this is id coming after saving keyword in backend for recommendation or draft
  ["new_kw.text"]: undefined;
  ["isNew"]: boolean;
  bucket?: string;
};

export type NewKeywordType = {
  "customer.id": string;
  "adGroup.id": string;
  "adGroup.name": string;
  "campaign.id": string;
  "campaign.name": string;
  competition: string;
  avgMonthlySearches: number;
  competitionIndex: number;
  "new_kw.text": string;
  "was_input?": boolean;
  source_kw_list: string;
  "min of adgrp monthly searches": number;
  adg_cost_avg: number;
  "3m_change": number;
  annotation_type: string;
  seed_kwd_type: string;
  cluster_id: number;
  cluster_size: number;
  cluster_name: string;
};

export enum CompetitionIndexEnum  {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}

export type MonthlyAvgSearch = {
  min?: number;
  max?: number;
};

export type CompetitionIndex = {
  high?: boolean;
  medium?: boolean;
  low?: boolean;
};

export type ExploreKeyword = {
  ["customer.id"]: number;
  ["adGroup.id"]: number;
  ["adGroup.name"]: undefined;
  ["competition"]: string;
  ["avgMonthlySearches"]: number;
  ["competitionIndex"]: number;
  ["new_kw.text"]: string;
  ["was_input"]?: number;
  ["source_kw_list"]: string;
  ["min of adgrp monthly searches"]: number;
  ["adg_cost_avg"]: number;
  ["annotation_type"]: string;
  ["3m_change"]: number;
  ["searches_more_than_Adgroup_min"]: number;
  ["cluster_id"]: number | string;
  ["cluster_size"]: number;
  ["cluster_name"]: string;
  ["segments.keyword.info.matchType"]: undefined;
  ["index"]: number;
  ["per_score"]: undefined;
  ["searchTermView.searchTerm"]: undefined;
  ["campaign.id"]: undefined;
  ["campaign.name"]: undefined;
  ["saveId"]: number; // this is id coming after saving keyword in backend for recommendation or draft
  ["searchTermView.searchTerm"]: undefined;
  ["isNew"]: boolean;
  bucket?: string;
};

export type KeywordsData = {
  kw_ideas: ExploreKeyword[];
  positive_kw: ExploitKeyword[];
  negative_kw: ExploitKeyword[];
};

export type KeywordsDataMetrics = {
  kw_ideas: {
    avgMonthlySearches: string;
    competitionIndex: string;
    "3m_change": string;
  };
  positive_kw: {
    "metrics.impressions": string;
    "metrics.averageCpc": string;
    "metrics.costPerConversion": string;
  };
  negative_kw: {
    "metrics.impressions": string;
    "metrics.averageCpc": string;
    "metrics.costPerConversion": string;
  };
};
export type KeywordDataTypes = "kw_ideas" | "positive_kw" | "negative_kw";

export type Buckets = {
  type: KeywordDataTypes;
  name: string;
};

export type BucketIds = {
  [id: number | string]: (number | string)[];
};

export type KeywordsType = {
  [id: number | string]: ExploitKeyword | ExploreKeyword;
};
export type ClusterDetailsElement = {
  id: number | string;
  name: string;
};

export type ClusterDetailsType = {
  [id: number | string]: ClusterDetailsElement;
};
export type AdGroupDetailsElement = {
  id: number;
  name: string;
  "campaign.id": number;
  "campaign.name": string;
};
export type AdGroupDetailsType = {
  [id: number | string]: AdGroupDetailsElement;
};
export type StarType = {
  [id: number | string]: boolean;
};

export type SaveModalType = {
  value: boolean;
  shownOnce: boolean;
};
export type KeywordContextType = {
  keywords: KeywordsType;
  campaignDetails: any;
  setKeywords: React.Dispatch<React.SetStateAction<KeywordsType>>;
  starredItems: StarType;
  setStarredItems: Function;
  adGroupDetails: AdGroupDetailsType;
  clusterDetails: ClusterDetailsType;
  // positiveAdGroup: BucketIds;
  // positiveCluster: BucketIds;
  setPositiveAdGroup: React.Dispatch<React.SetStateAction<BucketIds>>;
  setPositiveCluster: React.Dispatch<React.SetStateAction<BucketIds>>;
  // negativeAdGroup: BucketIds;
  // negativeCluster: BucketIds;
  setNegativeAdGroup: React.Dispatch<React.SetStateAction<BucketIds>>;
  setNegativeCluster: React.Dispatch<React.SetStateAction<BucketIds>>;
  // newKwsAdGroup: BucketIds;
  // newKwsCluster: BucketIds;
  setNewKwsAdGroup: React.Dispatch<React.SetStateAction<BucketIds>>;
  setNewKwsCluster: React.Dispatch<React.SetStateAction<BucketIds>>;
  selectedKeywordsBucket: KeywordDataTypes;
  setSelectedKeywordsBucket: React.Dispatch<
    React.SetStateAction<KeywordDataTypes>
  >;
  getLengthForBucket: (key: string) => number;
  isClusterView: boolean;
  setIsClusterView: React.Dispatch<React.SetStateAction<boolean>>;
  getSelectedBucketData: () => BucketIds;
  selectedFilters: any;
  setSelectedFilters: Function;
  appliedSelectedFilters: any;
  setAppliedSelectedFilters: Function;
  getTotalLength: () => number;
  handleDeleteClick: (
    adGroupId: number,
    clusterId: number | string,
    indices: number[],
    type?: KeywordDataTypes
  ) => void;
  handleDuplicateClick: (
    adGroupId: number,
    clusterId: number | string,
    indices: number
  ) => void;
  changeSingleProperty: (
    indices: string[],
    prop: string,
    value: string
  ) => void;
  handleSelectKeywords: (indices: number[]) => void;
  checkSelectAll: (indices: string[]) => number;
  getBucketSelectdItemsLength: (bucket: KeywordDataTypes) => number;
  checkSelectAllBucket: () => boolean;
  handleSelectAllKeywords: () => void;
  checkDeselectAllBucket: (bucket?: KeywordDataTypes) => boolean;
  handleDeselectAllKeywords: () => void;
  deleteBulkClick: () => void;
  saveBulkClick: () => void;
  duplicateBulkClick: () => void;
  changeBulkProperty: (key: string, value: any) => void;
  behaviourBulkClick: (bucket: KeywordDataTypes) => void;
  moveBulkClick: (
    campaignId: number,
    campaignName: string,
    adGroupId: number,
    adGroupName: string,
    isNew: boolean | undefined,
    toNew: boolean | undefined
  ) => void;
  isAnythingSelected: () => boolean;
  getDisplayObjectForPublish: () => any;
  getPublishPayload: () => any;
  filteredIds: null | (number | string)[];
  filterText: string;
  setFilterText: React.Dispatch<React.SetStateAction<string>>;
  onFilterChange: (key: string, values: any, type: string) => void;
  applyFilters: () => void;
  kSensitivity: number;
  handleSensitivityChange: (event: any, newValue: number | number[]) => void;
  positiveLoader: boolean;
  negativeLoader: boolean;
  newKwsLoader: boolean;
  getLoaderForBucket: (key: string) => boolean;
  selectedIds: any;
  exportToCsv: (e: any) => void;
  wastedSpends: number;
};

export type Types =
  | "avgMonthlySearches"
  | "competitionIndex"
  | "3m_change"
  | "metrics.impressions"
  | "metrics.costPerConversion"
  | "metrics.averageCpc";