import React from "react";
import InputText from "../InputText/InputText";
import "./RadioInput.scss";

const RadioInput: React.FC<{
  data: any;
  name?: string;
  defaultSelected?: any;
  onChange: (index:number, radioInputTitle: any) => void;
  onInputChange: (value: any, index: number, radioInputTitle: any) => void;
  inputClassName?: string;
  radioInputTitle?: string;
}> = ({
  data,
  name,
  defaultSelected,
  inputClassName,
  radioInputTitle,
  onChange,
  onInputChange,
}) => {
  return (
    <div className="radio-input">
      {data && data.length > 0 && data.map((value: any, index: number) => {
        return (
          <label className="display-flex text-md">    
            <input
              type="radio"
              name={name}
              id={value.value}
              value={value.value}
              className="radio-input"
              checked={index===defaultSelected}
              onChange={() => onChange(index,radioInputTitle)}
            />
            <InputText
              value={value.value}
              className={`radio-input-input ${inputClassName}`}
              onChange={(e: any) => {
                onInputChange(e, index, radioInputTitle);
              }}
            />
          </label>
        );
      })}
    </div>
  );
};

export default RadioInput;
