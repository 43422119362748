export const GENERATE_DATA = "GENERATE_DATA";
export const GENERATE_DATA_SUCCEEDED = "GENERATE_DATA_SUCCEEDED";
export const GENERATE_DATA_FAILED = "GENERATE_DATA_FAILED";

export const GET_DATA = "GET_DATA";
export const GET_DATA_SUCCEEDED = "GET_DATA_SUCCEEDED";
export const GET_DATA_FAILED = "GET_DATA_FAILED";

export const UPDATE_GSAI_STORE = "UPDATE_GSAI_STORE";

export const GET_BUNITS = "GET_BUNITS";

export const GET_HEADLINES_AND_DESCRIPTIONS = "GET_HEADLINES_AND_DESCRIPTIONS";

export const GET_KEYWORDS = "GET_KEYWORDS";
export const GET_AD_GROUPS = "GET_AD_GROUPS";
export const SET_AD_GROUPS = "SET_AD_GROUPS";

export const SET_NO_ADS_AVAILABLE = "SET_NO_ADS_AVAILABLE";

export const BUSINESSUNIT_LOADER = "BUSINESSUNIT_LOADER";
export const KEYWORD_LOADER = "KEYWORD_LOADER";
export const KEYWORD_FAILER = "KEYWORD_FAILER";
export const HEADLINEDES_LOADER = "HEADLINEDES_LOADER";
export const DESCRIPTION_LOADER = "DESCRIPTION_LOADER";

export const GET_CREATIVE_REPLACEMENT = "GET_CREATIVE_REPLACEMENT";
export const SET_FILTERED_ADS_LOADER = "SET_FILTERED_ADS_LOADER";
export const CREATE_DRAFT_ADS = "CREATE_DRAFT_ADS";
export const UPDATE_DRAFT_ADS = "UPDATE_DRAFT_ADS";
export const DELETE_DRAFT_ADS = "DELETE_DRAFT_ADS";

export interface DraftAdsInterface {
  isCreate: boolean;
  ad_account_id: string;
  campaign_id: string;
  ad_group_id: string;
  ad_id: string;
  data: any;
  meta_data: any;
}

export type GsaiState = {
  platform: any;
  aiGroups: any;
  adAccounts: any;
  campaigns: any;
  variantName: string;
  adGroupName: string;
  isNewAdGroup: boolean;
  selectedCampaign: string;
  finalUrl: string;
  path1: string;
  path2: string;
  screen: 0 | 1;
  isCreate: boolean;
  isCreateVariant?: boolean;
  ads: any;
  adUnit: any;
  error: any;
  filteredAds: any;
  filteredAdsLoader: boolean;
  aiGroup: any;
  adAccount: any;
  campaign: any;
  adGroup: any;
  bUnits: any;
  initialState: any;
  hnd: {
    headlines: any;
    descriptions: any;
  };
  keywords: any;
  modal: string;
  recommendations: {
    requestId: string;
    status: "initial" | "polling" | "completed" | "failed";
    error: string;
    data: any;
  };
  headlines: {
    requestId: string;
    status: "initial" | "polling" | "completed" | "failed";
    error: string;
    data: any;
  };
  descriptions: {
    requestId: string;
    status: "initial" | "polling" | "completed" | "failed";
    error: string;
    data: any;
  };
  dnd: {
    headlines: {
      recommended: any;
      toBePublished: any;
      replacements: any;
      more: any;
      toBePublishedError: boolean;
      creativeReplacementError: boolean;
    };
    descriptions: {
      recommended: any;
      replacements: any;
      toBePublished: any;
      more: any;
      toBePublishedError: boolean;
      creativeReplacementError: boolean;
    };
  };
  existingHeadlines: any;
  existingDescriptions: any;
  noAdsAvailable: boolean;
  businessUnitLoader: boolean;
  keywordLoader: boolean;
  keywordFailer: boolean;
  headlinedesLoader: boolean;
  descriptionLoader: boolean;
  replacementConfig: {
    isEnabled: boolean;
    headlines: {
      lowPerformance: { isChecked: boolean; value: number };
      goodPerformance: { isChecked: boolean; value: number };
      bestPerformance: { isChecked: boolean; value: number };
    };
    descriptions: {
      lowPerformance: { isChecked: boolean; value: number };
      goodPerformance: { isChecked: boolean; value: number };
      bestPerformance: { isChecked: boolean; value: number };
    };
  };
  adGroups: any;
};

export type GeneratData = {
  type:
    | typeof GENERATE_DATA
    | typeof GENERATE_DATA_SUCCEEDED
    | typeof GENERATE_DATA_FAILED;
  payload: any;
};

export type GetData = {
  type: typeof GET_DATA | typeof GET_DATA_SUCCEEDED | typeof GET_DATA_FAILED;
  payload: any;
};

export type UpdateGsaiStore = {
  type: typeof UPDATE_GSAI_STORE;
  payload: any;
};

export type GetBussinessUnits = {
  type: typeof GET_BUNITS;
  payload: any;
};

export type GetHeadlinesAndDescription = {
  type: typeof GET_HEADLINES_AND_DESCRIPTIONS;
  payload: any;
};

export type GetKeywords = {
  type: typeof GET_KEYWORDS;
  payload: any;
};

export type GetAdGroups = {
  type: typeof GET_AD_GROUPS;
  payload: any;
};

export type SetAdGroups = {
  type: typeof SET_AD_GROUPS;
  payload: any;
};

export const GENERATE_RECOMMENDATIONS = "GENERATE_RECOMMENDATIONS";
export const GENERATE_RECOMMENDATIONS_SUCCEEDED =
  "GENERATE_RECOMMENDATIONS_SUCCEEDED";
export const GENERATE_RECOMMENDATIONS_FAILED =
  "GENERATE_RECOMMENDATIONS_FAILED";

export const GET_RECOMMENDATIONS = "GET_GSAI_RECOMMENDATIONS";
export const GET_RECOMMENDATIONS_SUCCEEDED = "GET_RECOMMENDATIONS_SUCCEEDED";
export const GET_RECOMMENDATIONS_FAILED = "GET_RECOMMENDATIONS_FAILED";
export const UPDATE_RECOMMENDATIONS = "UPDATE_RECOMMENDATIONS";

export type GeneratRecommendations = {
  type: typeof GENERATE_RECOMMENDATIONS | typeof UPDATE_RECOMMENDATIONS;
  payload: any;
};

export type GetRecommendations = {
  type: typeof GET_RECOMMENDATIONS | typeof UPDATE_RECOMMENDATIONS;
  payload: any;
};

export const PUBLISH_AD = "PUBLISH_AD";

export type PublishAd = {
  type: typeof PUBLISH_AD;
  payload: any;
};

export type SetNoAdsAvailable = {
  type: typeof SET_NO_ADS_AVAILABLE;
  payload: any;
};

export type BusinnessUnitLoader = {
  type: typeof BUSINESSUNIT_LOADER;
  payload: boolean;
};

export type KeywordLoader = {
  type: typeof KEYWORD_LOADER;
  payload: boolean;
};

export type GetKeyWords = {
  type: typeof GET_KEYWORDS;
  payload: boolean;
};

export type KeywordFailer = {
  type: typeof KEYWORD_FAILER;
  payload: boolean;
};

export type HeadlinedesLoader = {
  type: typeof HEADLINEDES_LOADER;
  payload: boolean;
};

export type DescriptionLoader = {
  type: typeof DESCRIPTION_LOADER;
  payload: boolean;
};

export type GetCreativeReplacement = {
  type: typeof GET_CREATIVE_REPLACEMENT;
  payload: any;
};

export type SetFilteredAdsLoader = {
  type: typeof SET_FILTERED_ADS_LOADER;
  payload: any;
};

export type CreateDraftAds = {
  type: typeof CREATE_DRAFT_ADS;
  payload: {
    params: DraftAdsInterface;
    callback: any;
  };
};

export type UpdateDraftAds = {
  type: typeof UPDATE_DRAFT_ADS;
  payload: {
    id: string;
    params: DraftAdsInterface;
    callback: any;
  };
};

export type DeleteDraftAds = {
  type: typeof DELETE_DRAFT_ADS;
  payload: {
    id: string;
    callback: any;
  };
};
