import { Box } from "@mui/material";
import { StyledText } from "components/shared";
import React from "react";
import { StyledLinearProgress } from "./styled";

export default function PerformanceTooltipItem({
  headTitle,
  headSubtitle,
  midTitle,
  progressValue,
  isShowFooter,
  progressColor,
}: {
  headTitle: string;
  headSubtitle: string;
  midTitle: string;
  progressValue: number;
  isShowFooter?: boolean;
  progressColor: string;
}) {
  return (
    <Box display="flex" flexDirection="column" pb="1.25em">
      <Box display="flex" alignItems="center">
        <StyledText
          fontSize="0.875em"
          fontFamily="Lato"
          lineHeight="14px"
          fontWeight={600}
        >
          {headTitle}
        </StyledText>
        <StyledText
          fontSize="0.75em"
          fontFamily="Lato"
          lineHeight="14px"
          fontWeight={400}
          color="#858585"
          pl=".4em"
        >
          ( {headSubtitle} )
        </StyledText>
      </Box>
      <StyledText
        pt=".49em"
        pb=".24em"
        fontSize="1.375em"
        fontFamily="Lato"
        lineHeight="26px"
        fontWeight={700}
      >
        {midTitle}
      </StyledText>
      <StyledLinearProgress
        variant="determinate"
        value={progressValue}
        bgColor={progressColor}
      />
      {isShowFooter && (
        <Box display="flex" justifyContent="space-between" pt=".5em">
          <StyledText
            fontSize="0.625em"
            lineHeight="12px"
            fontWeight={600}
            color="#858585"
            textTransform="uppercase"
          >
            Low
          </StyledText>
          <StyledText
            fontSize="0.625em"
            lineHeight="12px"
            fontWeight={600}
            color="#858585"
            textTransform="uppercase"
          >
            Medium
          </StyledText>
          <StyledText
            fontSize="0.625em"
            lineHeight="12px"
            fontWeight={600}
            color="#858585"
            textTransform="uppercase"
          >
            High
          </StyledText>
        </Box>
      )}
    </Box>
  );
}
