import { Box, Skeleton } from "@mui/material";
import React from "react";
import { StyledKeyIndicatorItemCard } from "./styled";

export default function KeyIndicatorSkeleton({
  backgroundColor,
}: {
  backgroundColor: string;
}) {
  return (
    <StyledKeyIndicatorItemCard backgroundColor={backgroundColor}>
      <Skeleton
        variant="circular"
        width="2.5em"
        height="2.5em"
        sx={{
          margin: ".2em 0em .2em 0em",
        }}
      />
      <Box flex="1" pl="1em" pr="1em">
        <Skeleton variant="text" height="2.8em" />
      </Box>
    </StyledKeyIndicatorItemCard>
  );
}
