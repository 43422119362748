import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MultiplePersona from "./MultiplePersona/MultiplePersona";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CommonLoader from "../../../../assets/Gif/CommonLoader.gif";
import { useDispatch, useSelector } from "react-redux";
import {
  getTargetingAIKWSICP,
  storeTargetingAIKWSICP,
} from "store/actions/targetingICP";
import { setCalibrationData, setPolling } from "store/actions/targetingAI";
import { usePolling } from "Pages/TargetingAIPlaygroundBeta/hooks/usePolling";
import { getJSONDataFromURL } from "utils/jsonFromURL";
import { ReactComponent as Continue } from "../Assets/svgs/continue.svg";
import { Handle } from "react-flow-renderer";

const PersonaTuner = ({
  handleSubmit,
  currentPage,
  data,
  loaderState,
  setNodes,
  setEdges,
  isConnectable,
}: {
  handleSubmit: Function;
  currentPage: any;
  data: any;
  loaderState: any;
  setNodes: any;
  setEdges: any;
  isConnectable: any;
}) => {
  const calibrationAPIData = useSelector(
    (state: any) => state.calibration.apiData
  );
  const callibrationData = useSelector(
    (state: any) => state.calibration.formData
  );
  const aiInitiationData = useSelector(
    (state: any) => state.aiInitiation.apiData
  );
  const polling = useSelector((state: any) => state.taiCommon.polling);
  const [generateState, setGenerateState] = React.useState<
    "generateMore" | "generating" | "generated"
  >("generateMore");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState<any>(null);

  const { setStartPolling } = usePolling(2000, (error: boolean) => {
    if (error) {
      //error
      return;
    }
    if (polling.requestId !== "") {
      dispatch(
        getTargetingAIKWSICP(polling.requestId, async (res: any, err: any) => {
          const status = res?.data?.status_list[0].status;
          if (status === "failed") {
            setStartPolling(false);
            dispatch(setPolling({ name: "", requestId: "" }));
            setGenerateState("generateMore");
          }
          if (!err && status === "completed") {
            setStartPolling(false);
            dispatch(setPolling({ name: "", requestId: "" }));
            // let jsonData = await getJSONDataFromURL(
            //   res.data.status_list[0].result?.json_url
            // );
            const jsonData = res.data.status_list[0].result_json_decoded;
            jsonData.icps = jsonData.icps.map((eachICP: any) => {
              eachICP.isNew = true;
              return eachICP;
            });
            jsonData.icps = calibrationAPIData?.icps.concat(jsonData.icps);
            dispatch(setCalibrationData(jsonData));
            if (jsonData.icp_gen_exhaustion_dict?.icp_gen_exhausted) {
              setGenerateState("generated");
            } else {
              setGenerateState("generateMore");
            }
          }
        })
      );
    }
  });

  const handleDelete = (dataIndex: string, key: string, index: number) => {
    if (calibrationAPIData?.icps?.[index]?.[key]?.length) {
      let newData = { ...calibrationAPIData };
      newData?.icps?.[index]?.[key]?.splice(dataIndex, 1);
      dispatch(setCalibrationData(newData));
    }
  };

  const handleAdd = (label: string, key: string, index: number) => {
    let newData = { ...calibrationAPIData };
    newData?.icps?.[index]?.[key]?.push(label);
    dispatch(setCalibrationData(newData));
  };

  const handleClose = () => {
    setOpen(null);
  };

  const getICPKwsData =()=>{ 

    const IcpDataWithoutIsNewKey = calibrationAPIData?.icps.map((icp:any)=>{
      const icpClone={...icp}

      if('isNew' in icpClone )
      delete icpClone.isNew
    
      return icpClone
    })

    return {
    // service: "icp_keywords_recms",
    // version: "v0.1.0",
    payload_data: {
      brand_details: {
        brand_name: aiInitiationData.name,
        brand_website: aiInitiationData.website,
      },
      geo_locations: {
        countries: aiInitiationData.region.length ? [] : aiInitiationData.country.map((country:any)=>country.code),
        regions_ids: aiInitiationData.region.map((region:any) => region.key)
      },
      icp_context_description: callibrationData?.ICPContextDescription,
      existing_icps_data: IcpDataWithoutIsNewKey,
    },
  }};

  const handleGenerateClick = () => {
    if (generateState === "generateMore") {
      let oldData: any = [...calibrationAPIData.icps];
      calibrationAPIData.icps = oldData?.map((eachICP: any) => {
        eachICP.isNew = false;
        return eachICP;
      });
      dispatch(setCalibrationData(calibrationAPIData));
      setGenerateState("generating");
      dispatch(
        storeTargetingAIKWSICP(
          { kwsData: getICPKwsData() },
          (response: any, error: any) => {
            if (!error && response?.data?.requestid) {
              dispatch(
                setPolling({
                  name: "icp",
                  requestId: response?.data?.requestid,
                })
              );
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    console.log("LL: calibrationAPIData?.icps", calibrationAPIData?.icps);
  }, [calibrationAPIData?.icps]);

  useEffect(() => {
    if (polling.name === "calibration" || polling.name === "icp") {
      setStartPolling(true);
    }
  }, [polling, setStartPolling]);

  const handleDeletePersona = (index: number) => {
    setOpen(index);
  };

  const onDelete = () => {
    if (calibrationAPIData?.icps?.[open]) {
      let newData = { ...calibrationAPIData };
      newData.icps.splice(open, 1);
      dispatch(setCalibrationData(newData));
      setOpen(null);
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        background: "#FFF",
        borderRadius: "10px",
        boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.05)",
        border: "1px solid rgba(239, 239, 239, 1)",
        height: "100%",
      }}
    >
      <Box sx={{ height: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              color: "rgba(8, 105, 251, 1)",
              fontFamily: "Lato",
              fontSize: "1.25em",
              lineHeight: "1.5em",
              fontWeight: "700",
              mb: 2,
            }}
          >
            Persona Tuner
          </Typography>
          {currentPage > data.index && (
            <Continue
              onClick={() =>
                data.onEditClick(dispatch, loaderState, setNodes, setEdges)
              }
            />
          )}
        </Box>
        <Box
          sx={{
            background:
              "linear-gradient(0deg, #F6F7F8, #F6F7F8), linear-gradient(0deg, #ECEEF1, #ECEEF1)",
            border: "1px solid rgba(236, 238, 241, 1)",
            borderRadius: "8px",
            overflow: "auto",
            height: "calc(100% - 6.5rem)",
          }}
        >
          {calibrationAPIData?.icps?.map((ecpData: any, index: number) => {
            return (
              <MultiplePersona
                handleAdd={handleAdd}
                handleDelete={handleDelete}
                ecpData={ecpData}
                index={index}
                handleDeletePersona={handleDeletePersona}
              />
            );
          })}

          <Box
            className="paginated-view"
            textAlign={"center"}
            sx={{
              my: 10,
            }}
          >
            {!calibrationAPIData?.icp_gen_exhaustion_dict
              ?.icp_gen_exhausted && (
              <Button
                variant="outlined"
                sx={{
                  fontSize: "0.875em",
                  textTransform: "capitalize",
                  lineHeight: "1em",
                  borderRadius: "6px",
                  borderColor: "#0869FB",
                  color: `${
                    generateState === "generateMore" ||
                    generateState === "generated"
                      ? "#0869FB"
                      : "#000"
                  }`,
                  fontStyle: `${
                    generateState === "generating" ? "italic" : "normal"
                  }`,
                  padding: "1em 1.5em",
                  "&:hover": {
                    borderColor: "#0869FB",
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleGenerateClick}
                // disabled={true}
              >
                {(generateState === "generateMore" ||
                  generateState === "generated") &&
                  "+ Generate More"}
                {generateState === "generating" && (
                  <span>
                    <img
                      src={CommonLoader}
                      style={{
                        height: "1.3em",
                        width: "1.3em",
                        marginRight: "0.5em",
                      }}
                    />{" "}
                    Generating...
                  </span>
                )}
              </Button>
            )}

            {calibrationAPIData?.icp_gen_exhaustion_dict?.icp_gen_exhausted && (
              <Typography
                sx={{
                  mt: 1,
                  fontSize: "0.75em",
                  lineHeight: "1.25em",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  color: "#AEB6C4",
                }}
              >
                You have reached the end of recommendations
              </Typography>
            )}
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              padding: "1em 1.5em",
              fontFamily: "Inter",
              fontSize: "0.875em",
              lineHeight: "1em",
              borderRadius: "6px",
              backgroundColor: "#0869FB",
            }}
            onClick={() => handleSubmit()}
            disabled={
              generateState === "generating" ||
              !calibrationAPIData?.icps?.length
            }
          >
            Generate Audiences
          </Button>
        </Box>
      </Box>
      <Modal open={open !== null} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: 24,
            borderRadius: "8px",
            width: "30rem",
          }}
        >
          <Box
            sx={{
              width: "18rem",
              margin: "0 auto",
              textAlign: "center",
              py: 10,
            }}
          >
            <Typography
              sx={{
                fontSize: "1.875em",
                lineHeight: "1.25em",
                fontFamily: "Lato",
                fontWeight: "700",
                color: "#333333",
                marginBottom: "0.8em",
              }}
            >
              Remove Persona?
            </Typography>
            <Typography
              sx={{
                fontSize: "0.875em",
                lineHeight: "1.25em",
                fontFamily: "Inter",
                fontWeight: "500",
                color: "#727272",
                marginBottom: "1em",
              }}
            >
              The selected Persona will be removed and this action can’t be
              undone!{" "}
            </Typography>
            <Box
              sx={{
                width: "13.5rem",
                display: "flex",
                justifyContent: "space-between",
                margin: "0 auto",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  fontFamily: "0.875em",
                  lineHeight: "1em",
                  borderColor: "#D9D9D9",
                  color: "#D9D9D9",
                  textTransform: "capitalize",
                  padding: "0.7em 2em",
                  borderRadius: "6px",
                  "&: hover": {
                    borderColor: "#D9D9D9",
                    color: "#D9D9D9",
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  fontFamily: "0.875em",
                  lineHeight: "1em",
                  backgroundColor: "#EE4E4A",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                  padding: "0.7em 2em",
                  boxShadow: "none",
                  borderRadius: "6px",
                  "&: hover": {
                    backgroundColor: "#EE4E4A",
                    color: "#FFFFFF",
                    boxShadow: "none",
                  },
                }}
                variant="contained"
                onClick={onDelete}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PersonaTuner;
