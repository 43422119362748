import { Box } from "@mui/material";
import { StyledImageMainBox } from "../styled";

export default function ImageCollection({ imgArray }: { imgArray: string[] }) {
  const viewImage = () => {
    const newImgArray = imgArray.length > 4 ? imgArray.slice(0, 4) : imgArray;


    if (newImgArray.length === 1) {
      return (
        <Box
          component="img"
          src={newImgArray[0]}
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
      );
    } else if (newImgArray.length === 2) {
      return newImgArray.map((item) => (
        <Box
          component="img"
          src={item}
          sx={{
            width: "1.5em",
            height: "1.8em",
          }}
        />
      ));
    } else {
      return newImgArray.map((item) => (
        <Box
          component="img"
          src={item}
          sx={{
            width: "1.5em",
            height: "1.5em",
          }}
        />
      ));
    }
  };

  return (
    <StyledImageMainBox
      sx={{
        height: "3.125em",
        maxHeight: "3.125em",
        width: "3.25em",
        maxWidth: "3.25em",
        display: "flex",
        flexWrap: "wrap",
        gap: "0.125em",
        overflow: "hidden",
        alignItems: "center",
      }}
    >
      {viewImage()}
    </StyledImageMainBox>
  );
}
