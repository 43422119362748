import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFacebookImageUrl,
  getFacebookImageUrlForAssetFeedSpec,
  getFacebookVideoUrl,
} from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import { getAdDetails } from "store/actions/CreativeAI_Revamp/LogScreen/logscreen.actions";

export interface LocalDataType {
  isFailed: boolean;
  isLoading: boolean;
  data?: any;
}

export const useHandlePreview = () => {
  const [selectedAdData, setSelectedAdData] = useState<any>();
  const [selectedAdIndex, setSelectedAdIndex] = useState<any>(undefined);
  const [fetchingAdDetails, setFetchingAdDetails] = useState(true);
  const [adDetailsError, setAdDetailsError] = useState(false);
  const [createSpecAPI, setCreateSpecAPI] = useState<any>();
  const [variantPreviewError, setVariantPreviewError] = useState(false);
  const [data, setData] = useState<any>();
  const [imageAvatarData, setImageAvatarData] = useState<LocalDataType>({
    isFailed: false,
    isLoading: true,
    data: undefined,
  });

  const [fbVideoData, setFbVideoData] = useState<LocalDataType>({
    isFailed: false,
    isLoading: false,
    data: undefined,
  });

  const [fbImageData, setFbImageData] = useState<LocalDataType>({
    isFailed: false,
    isLoading: false,
    data: undefined,
  });

  const [isMediaLoading, setIsMediaLoading] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const { activityLogsList } = useSelector(
    (state: { fbActivityLogReducer: any }) => state.fbActivityLogReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (activityLogsList.data.length > 0) {
      setSelectedAdData(activityLogsList.data[0].data);
      setData(activityLogsList.data[0]);
    } else {
      setSelectedAdData(undefined);
    }
  }, [activityLogsList.data]);

  useEffect(() => {
    if (selectedAdData?.ad_id) {
      setVariantPreviewError(false);
      setImageAvatarData({
        isFailed: false,
        isLoading: false,
        data: undefined,
      });
      setFbImageData({
        isFailed: false,
        isLoading: false,
        data: undefined,
      });
      setFbVideoData({
        isFailed: false,
        isLoading: false,
        data: undefined,
      });
      setFetchingAdDetails(true);
      dispatch(
        getAdDetails({
          adId: selectedAdData?.ad_id,
          params: {
            adFormat: "MOBILE_FEED_STANDARD",
            adAccountId: selectedAdData?.ad_account_id,
          },
          callback: (data: any, error: boolean) => {
            setCreateSpecAPI(data.data.adcreatives?.data[0]);
            setFetchingAdDetails(false);
          },
        })
      );
      setMediaUrl("");
      fetchMedia(selectedAdData);
    }
  }, [selectedAdData?.new_ad_id, selectedAdIndex]);

  useEffect(() => {
    if (createSpecAPI?.object_story_spec?.page_id) {
      setImageAvatarData({
        isFailed: false,
        isLoading: true,
        data: undefined,
      });
      dispatch(
        getFacebookImageUrl({
          adId: createSpecAPI?.object_story_spec?.page_id, /// actually fb page id
          callback: (response: any, error: boolean) => {
            if (!error) {
              setImageAvatarData({
                isFailed: false,
                isLoading: false,
                data: response,
              });
            } else {
              setImageAvatarData({
                isFailed: true,
                isLoading: false,
                data: undefined,
              });
            }
          },
        })
      );
    }

    if (
      createSpecAPI?.object_story_spec?.video_data?.video_id ||
      createSpecAPI?.asset_feed_spec?.videos?.[0]?.video_id
    ) {
      setFbVideoData({
        isFailed: false,
        isLoading: true,
        data: undefined,
      });
      dispatch(
        getFacebookVideoUrl({
          adId:
            createSpecAPI?.object_story_spec?.video_data?.video_id ??
            createSpecAPI?.asset_feed_spec?.videos?.[0]?.video_id, /// actually video id
          callback: (response: any, error: boolean) => {
            if (!error) {
              setFbVideoData({
                isFailed: false,
                isLoading: false,
                data: { videoUrl: response?.source },
              });
            } else {
              setFbVideoData({
                isFailed: true,
                isLoading: false,
                data: undefined,
              });
            }
          },
        })
      );
    }
    if (createSpecAPI?.image_url) {
      setFbImageData({
        isFailed: false,
        isLoading: false,
        data: { imageUrl: createSpecAPI?.image_url },
      });
    } else if (createSpecAPI?.asset_feed_spec?.images?.[0]?.hash) {
      setFbImageData({
        isFailed: false,
        isLoading: true,
        data: undefined,
      });
      dispatch(
        getFacebookImageUrlForAssetFeedSpec({
          adAccountId: selectedAdData?.ad_account_id,
          imageHash: createSpecAPI?.asset_feed_spec?.images?.[0]?.hash,
          callback: (response: any, error: boolean) => {
            if (!error) {
              setFbImageData({
                isFailed: false,
                isLoading: false,
                data: { imageUrl: response?.[0]?.permalink_url },
              });
            } else {
              setFbImageData({
                isFailed: true,
                isLoading: false,
                data: undefined,
              });
            }
          },
        })
      );
    } else {
      setFbImageData({
        isFailed: false,
        isLoading: false,
        data: undefined,
      });
    }
  }, [createSpecAPI]);

  const handleItemClick = (item: any, index: number) => {
    setSelectedAdIndex(index);
    setSelectedAdData(item.data);
    setData(item);
  };

  const fetchMedia = (adData: any) => {
    if (
      adData?.ad_type === "SINGLE_IMAGE_AD" &&
      data?.["Variant.variantType"] === null
    ) {
      if (adData?.image_data?.image_hash) {
        setIsMediaLoading(true);
        dispatch(
          getFacebookImageUrlForAssetFeedSpec({
            adAccountId: adData?.ad_account_id,
            imageHash: adData?.image_data?.image_hash,
            callback: (response: any, error: boolean) => {
              if (!error) {
                setMediaUrl(response?.[0]?.permalink_url);
              } else {
                setVariantPreviewError(true);
              }
              setIsMediaLoading(false);
            },
          })
        );
      }
    } else {
      if (adData?.video_data?.video_id) {
        setIsMediaLoading(true);
        dispatch(
          getFacebookVideoUrl({
            adId: adData?.video_data?.video_id, /// actually video id
            callback: (response: any, error: boolean) => {
              if (!error) {
                setMediaUrl(response?.source);
              } else {
                setVariantPreviewError(true);
              }
              setIsMediaLoading(false);
            },
          })
        );
      } else {
        setVariantPreviewError(true);
      }
    }
  };

  return {
    selectedAdIndex,
    variantPreviewError,
    handleItemClick,
    fetchingAdDetails,
    adDetailsError,
    imageAvatarData,
    createSpecAPI,
    fbVideoData,
    fbImageData,
    selectedAdData,
    isMediaLoading,
    mediaUrl,
    data,
  };
};
