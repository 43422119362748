import {
  EnableMasterAccess,
  ENABLE_MASTER_ACCESS,
  GetAdAccountDetails,
  GetAdsetDetails,
  GetAllBrands,
  GetAllPlatforms,
  GetBrandAdAccounts,
  GetCampaignDetails,
  GetElementId,
  GetEligibleAdAccounts,
  GetGroupAdAccounts,
  GetGroups,
  GetSavingCalculatorData,
  GET_ADACCOUNT_DETAIL,
  GET_ADSET_DETAILS,
  GET_ALL_BRANDS,
  GET_ALL_PLATFORMS,
  GET_BRAND_ADACCOUNTS,
  GET_CAMPAIGN_DETAILS,
  GET_ELEMENT_ID,
  GET_ELIGIBLE_ADACCOUNTS,
  GET_GROUPS,
  GET_GROUP_ADACCOUNTS,
  GET_SAVINGCALCULATOR_DATA,
  LinkTokenToAdAccounts,
  LINK_TOKEN_TO_ADACCOUNTS,
  SET_SAVINGCALCULATOR_DATA,
  GetTokenUID,
  GET_TOKENUID,
  GetBrandSources,
  GET_BRAND_SOURCES,
  SET_BRAND_SOURCES,
} from "./../types/adaccount";
import { takeLatest, call, takeEvery, put, all } from "redux-saga/effects";
import { BASE_URL, CROSS_PLATFORM_URL } from "../../utils/path";
import { crossPlatform, groupV2API } from "../../MappedAPI/MappedAPI";
import HttpService from "../../HttpService/HttpService";
import {
  GetMultipleAdAccountDetails,
  GET_MULTIPLE_ADACCOUNT_DETAIL,
} from "../types/adaccount";

function* getGroups(action: GetGroups): any {
  let {
    payload: { brandId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/brand/${brandId}/ai-group`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAdsetDetails(action: GetAdsetDetails): any {
  let {
    payload: { group_id, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/ai-group/${group_id}/adsets`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAdsetDetails ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getCampaignDetails(action: GetCampaignDetails): any {
  let {
    payload: { group_id, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/ai-group/${group_id}/campaigns`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAdsetDetails ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAllBrands(action: GetAllBrands): any {
  let { callback } = action;
  try {
    const urlParams = `${crossPlatform}/brand`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllBrands ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAllPlatforms(action: GetAllPlatforms): any {
  let { callback } = action;
  try {
    const urlParams = `${crossPlatform}/platform`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getSavingCalculatorData(action: GetSavingCalculatorData): any {
  const { payload, callback } = action;
  try {
    const urlParams = `${crossPlatform}/savings-calculator/${payload?.elementID}/${payload.platformType}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);

    if (!response.data.error) {
      yield put({
        type: SET_SAVINGCALCULATOR_DATA,
        payload: response.data.data,
      });
      // callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    yield put({ type: SET_SAVINGCALCULATOR_DATA, payload: {} });
    console.log(
      "function*getAllPlatforms ~ error",
      error.response.data.message
    );
    callback && callback(error.response, true);
  }
}

function* getElementId(action: GetElementId): any {
  let {
    payload: { adAccountId, aiGroupId, platformId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/element/${platformId}/${aiGroupId}/${adAccountId}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getBrandAdAccounts(action: GetBrandAdAccounts): any {
  let {
    payload: { brandId },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/brand/${brandId}/ad-account`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getBrandAdAccounts ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getGroupAdAccounts(action: GetGroupAdAccounts): any {
  let {
    payload: { groupId, platformId },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/ai-group/${groupId}/ad-accounts${
      platformId ? `?platformId=${platformId}` : ""
    }`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getGroupAdAccounts ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAdAccountDetails(action: GetAdAccountDetails): any {
  let {
    payload: { adaccountId, platformType },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/ad-account/${adaccountId}/platform/${platformType}/details`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAdAccountDetails ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getMultipleAdAccountDetails(
  action: GetMultipleAdAccountDetails
): any {
  let {
    payload: { adaccountIds, platformType },
    callback,
  } = action;
  try {
    const resultArray = yield all(
      adaccountIds.map((adaccountId) =>
        call(
          HttpService.get,
          CROSS_PLATFORM_URL,
          `${crossPlatform}/ad-account/${adaccountId}/platform/${platformType}/details`
        )
      )
    );
    if (resultArray) {
      callback &&
        callback(
          resultArray.map((result: any) => result.data.data),
          false
        );
    } else {
      callback && callback("Failed", true);
    }
  } catch (error: any) {
    console.log("function*getMultipleAdAccountDetails ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getTokenUID(action: GetTokenUID): any {
  const {
    payload: { user, oauthCode, brandId, platform },
    callback,
  } = action;

  try {
    const url = `${crossPlatform}/token`;
    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      url,
      user,
      { oauthCode, brandId, platform }
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getEligibleAdAccounts(action: GetEligibleAdAccounts): any {
  let {
    payload: { brandId, tokenUID, platform },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/token/${tokenUID}/adAccounts?brandId=${brandId}&platform=${platform}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    callback && callback(error.response, true);
  }
}

function* linkTokenToAdAccounts(action: LinkTokenToAdAccounts): any {
  let {
    payload: { params, tokenUID },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/token/${tokenUID}/adAccounts`;
    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      params
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    callback && callback(error.response, true);
  }
}

function* enableMasterAccess(action: EnableMasterAccess): any {
  let {
    payload: { params, tokenUID },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/token/${tokenUID}/adAccounts/master-access`;
    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      params
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getBrandSources(action: GetBrandSources): any {
  let {
    payload: { brandId },
  } = action;
  try {
    const urlParams = `/v2/api/brand/${brandId}/google-sheet/data-source`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      yield put({ type: SET_BRAND_SOURCES, payload: response.data.data });
    } else {
      yield put({ type: SET_BRAND_SOURCES, payload: [] });
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    yield put({ type: SET_BRAND_SOURCES, payload: [] });
  }
}

export function* adAccountWatcher() {
  yield takeLatest(GET_GROUPS, getGroups);
  yield takeEvery(GET_ADSET_DETAILS, getAdsetDetails);
  yield takeEvery(GET_CAMPAIGN_DETAILS, getCampaignDetails);
  yield takeLatest(GET_ALL_BRANDS, getAllBrands);
  yield takeLatest(GET_ALL_PLATFORMS, getAllPlatforms);
  yield takeLatest(GET_SAVINGCALCULATOR_DATA, getSavingCalculatorData);
  yield takeEvery(GET_ELEMENT_ID, getElementId);
  yield takeLatest(GET_BRAND_ADACCOUNTS, getBrandAdAccounts);
  yield takeLatest(GET_GROUP_ADACCOUNTS, getGroupAdAccounts);
  yield takeEvery(GET_ADACCOUNT_DETAIL, getAdAccountDetails);
  yield takeEvery(GET_MULTIPLE_ADACCOUNT_DETAIL, getMultipleAdAccountDetails);
  yield takeLatest(GET_ELIGIBLE_ADACCOUNTS, getEligibleAdAccounts);
  yield takeLatest(LINK_TOKEN_TO_ADACCOUNTS, linkTokenToAdAccounts);
  yield takeLatest(ENABLE_MASTER_ACCESS, enableMasterAccess);
  yield takeLatest(GET_TOKENUID, getTokenUID);
  yield takeLatest(GET_BRAND_SOURCES, getBrandSources);
}
