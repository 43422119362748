import AllFirst from "../../ReactFlowComponents/Assets/svgs/PeronsaTunerGravtar/FirstAll.svg";
import MaleFirst from "../../ReactFlowComponents/Assets/svgs/PeronsaTunerGravtar/FirstMale.svg";
import FemaleFirst from "../../ReactFlowComponents/Assets/svgs/PeronsaTunerGravtar/FirstFemale.svg";
import AllSecond from "../../ReactFlowComponents/Assets/svgs/PeronsaTunerGravtar/SecondAll.svg";
import MaleSecond from "../../ReactFlowComponents/Assets/svgs/PeronsaTunerGravtar/SecondMale.svg";
import FemaleSecond from "../../ReactFlowComponents/Assets/svgs/PeronsaTunerGravtar/SecondFemale.svg";
import AllThird from "../../ReactFlowComponents/Assets/svgs/PeronsaTunerGravtar/ThirdAll.svg";
import MaleThird from "../../ReactFlowComponents/Assets/svgs/PeronsaTunerGravtar/ThirdMale.svg";
import FemaleThird from "../../ReactFlowComponents/Assets/svgs/PeronsaTunerGravtar/ThirdFemale.svg";

export const gravtar: any = {
  "30_all": AllFirst,
  "30_1": MaleFirst,
  "30_2": FemaleFirst,
  "45_all": AllSecond,
  "45_1": MaleSecond,
  "45_2": FemaleSecond,
  "65_all": AllThird,
  "65_1": MaleThird,
  "65_2": FemaleThird,
};
