import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { amountValue } from "utils/commonFunctions";

function valuetext(value: any) {
  return `${value}°C`;
}

export default function RangeSlider({
  min,
  max,
  selectedValue,
  onChange,
  step = 1,
  isAvgCpc=false
}: any) {

  const handleChange = (event: any, newValue: any) => {
    onChange(newValue);
  };

  return (
    <Box sx={{ width: "100%", px: 1, pt: 0.5 }}>
      <Slider
        getAriaLabel={() => "Temperature range"}
        value={selectedValue}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value:number)=>amountValue(Math.floor(isAvgCpc?value/1000000:value))}
        getAriaValueText={valuetext}
        min={Math.floor(min)}
        max={Math.ceil(max)}
        step={step}
        sx={{
          pb: 0,
          "& .MuiSlider-track": {
            background: "#0869FB",
          },
          "& .MuiSlider-rail, & .MuiSlider-mark": {
            opacity: 1,
            background: "#F2F2F2",
          },
          "& .MuiSlider-thumb": {
            height: "1rem",
            width: "1rem",
            background:
              "linear-gradient(#fff,#fff) padding-box, linear-gradient(90deg, #4BA2E9 3.51%, #6DDE9F 127.19%) border-box",
            border: "3px solid transparent",
            borderRadius: "0.75rem",
          },
          "& .MuiSlider-thumb::after": {
            height: "1rem",
            width: "1rem",
          },
          "& .MuiSlider-valueLabel": {
            background: "#1E2244",
            fontSize: "0.75rem",
            fontWeight: 400,
            borderRadius: "4px",
            color: "#fff",
            fontFamily: "Inter",
          },
        }}
      />
    </Box>
  );
}
