import React from "react";
import { StyledHighlightItem } from "./styled";

export interface HighlightItemProp {
  bgColor: string;
  textColor: string;
  text: string;
  icon: any;
}

export default function HighlightItem({
  text,
  icon,
  ...props
}: HighlightItemProp) {
  return (
    <StyledHighlightItem {...props}>
      {icon}
      {text}
    </StyledHighlightItem>
  );
}
