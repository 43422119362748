import React, { useEffect } from "react";

import Modal from "./modal";
import { useCreativeReplacementConfig } from "./hooks";

import { ReactComponent as Replacement } from "../../../../../../assets/svg/replacement.svg";
import { ReactComponent as Dot } from "../../../../../../assets/svg/3-dot.svg";
import { ReactComponent as Info } from "../../../../../../assets/svg/gsai-cr-info.svg";
import { ReactComponent as Enabled } from "../../../../../../assets/svg/gsai-cr-enabled.svg";
import { ReactComponent as Edit } from "../../../../../../assets/cai/svg/edit_icon.svg";
import "./styles.scss";
import { IconButton } from "@mui/material";

type Props = {
  type: "headlines" | "descriptions";
  children: React.ReactNode;
};

function CreativeReplacement({ type, children }: Props) {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const {
    isEnabled,
    toggleReplacementConfig,
    replacementConfig,
    saveReplacementConfig,
  } = useCreativeReplacementConfig(type);

  function handleDocumentClick() {
    setShowModal(false);
  }
  useEffect(() => {
    let show = isEnabled;
    if (
      replacementConfig?.lowPerformance?.isChecked &&
      replacementConfig?.lowPerformance?.value > 0
    ) {
      show = false;
    }
    if (
      replacementConfig?.goodPerformance?.isChecked &&
      replacementConfig?.goodPerformance?.value > 0
    ) {
      show = false;
    }
    if (
      replacementConfig?.bestPerformance?.isChecked &&
      replacementConfig?.bestPerformance?.value > 0
    ) {
      show = false;
    }
    setShowModal(show);
  }, [replacementConfig, isEnabled]);

  return (
    <div className="creative-replacement-container">
      <div className="cr-header">
        <Replacement className="cr-replacement-icon" />
        Creative Replacement
        {isEnabled ? (
          <IconButton
            onClick={() => {
              setOpenModal((val) => !val);
            }}
            className="cr-dots"
            sx={{
              padding: 0,
            }}
          >
            <Edit />
          </IconButton>
        ) : null}
      </div>

      {isEnabled ? (
        showModal || openModal ? (
          <Modal
            hideModal={() => {
              setOpenModal(false);
            }}
            replacementConfig={replacementConfig}
            toggleReplacementConfig={toggleReplacementConfig}
            saveReplacementConfig={saveReplacementConfig}
            type={type}
          />
        ) : (
          children
        )
      ) : (
        <div className="cr-content">
          <button
            className="enable-cr"
            type="button"
            onClick={() => {
              toggleReplacementConfig(true);
            }}
          >
            Enable Creative Replacement
          </button>
          <Info />
          <span className="cr-info-message">
            Turn on Creative Replacement from the toggle from top right side.
            Creative AI analyses the performance of the published headlines and
            replaces them based on your preference.
          </span>
        </div>
      )}
    </div>
  );
}

export default CreativeReplacement;
