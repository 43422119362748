import { Box } from "@mui/material";
import styled from "styled-components";

interface PeriodProps {
  color?: string;
}

export const StyledOverFlow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  font-size: 0.82vw;
  height: calc(100vh - 130px);
  @media screen and (max-width: 1280px) {
    font-size: 11px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

export const StyledCardBox = styled.div`
  background: #ffffff;
  display: flex;
  flex-flow: column;
  height: calc(100vh - (140px));
  padding: 0.625em 0.625em 0.625em 1em;
  border-radius: 0.625em;
  @media screen and (max-width: 1280px) {
    width: 970px;
  }
`;

//head part styled components
export const StyledActionDebuggerHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  height: 6%;
`;

export const StyledTitlePara = styled.p`
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.8125em;
  color: #000000;
  padding-left: 0.5em;
  margin: 0;
`;

export const StyledRightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 0.625em;
`;
export const StyledPeriodPara = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 1em;
  display: flex;
  align-items: flex-start;
  color: #313131;
  margin-bottom: 0.625em;
`;
export const StyledPhasesContent = styled.div`
  margin-right: 2em;
`;

// body part styled components
export const StyledActionDebuggerBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  height: 90%;
  width: 100%;
`;

export const StyledSummaryBoardBox = styled(Box)`
  width: 36%;
  height: calc(100%- 1.125em);
  margin-top: 1.6em;
  overflow-y: auto;

  @media screen and (max-width: 1280px) {
    width: 343px;
  }
`;

export const StyledChartBox = styled(Box)`
  width: 64%;
  height: calc(100%- 1.125em);
  margin-top: 1.7em;
  overflow-y: auto;
  border-top: 1px solid #d6e2e9;
  @media screen and (max-width: 1280px) {
    width: 616px;
  }
`;

export const StyledPeriodSpan = styled.span<PeriodProps>`
  width: 0.875em;
  height: 0.875em;
  background: ${(props) => props.color};
  margin-right: 0.625em;
`;

export const StyledComparePara = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.1875em;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
  margin-bottom: 0em;
`;

export const StyledBtnContainer = styled.p`
  margin: 0;
  .radio-dropdown-btn {
    background: #f0f0f0;
    border: 0.0625em solid #e9e9e9;
    border-radius: 0.25em;
    box-shadow: none;
    text-transform: capitalize;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.75em;
    line-height: 0.9375em;
    display: flex;
    align-items: center;
    color: #000000;
    position: relative;
    top: -0.25em;
  }
  .radio-dropdown-btn:hover {
    background: #f0f0f0;
    border: 0.0625em solid #e9e9e9;
    border-radius: 0.25em;
    box-shadow: none;
    text-transform: capitalize;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.75em;
    line-height: 0.9375em;
    display: flex;
    align-items: center;
    color: #000000;
    position: relative;
    top: -0.25em;
  }
`;

// footer style

export const StyledAttributionTitleText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 0.9375em;
  color: #828282;
  padding-left: 0.5em;
`;
export const StyledAttributionSummaryText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 0.9375em;
  color: #999999;
  padding-left: 0.4em;
`;
