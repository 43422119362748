import React, { useEffect, useMemo } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GET_ELEMENT_RESULT_METRICS } from "store/types/CreativeAI/Facebook/Main/Main";
import { AdAccountState } from "store/types/adaccount";
import { LightTooltip } from "components/CreativeAI_Revamp/shared";
import { brandViewerAccessText } from "utils/constants/commonConsts";
import { AudienceManagerState } from "store/types/AudienceManager";
import {
  getRolesHierarchy,
  isEditorRole,
  isViewerRole,
} from "utils/commonFunctions";
import { RolesWrapper } from "utils/rolesWrapper";
interface IFooterProps {
  variant: string;
  selectedCohortsLength?: number;
  totalCohortsLength?: number;
  onDeleteClick?: Function;
  onPublishClick?: Function;
}
function Footer(props: IFooterProps) {
  const {
    variant,
    selectedCohortsLength,
    totalCohortsLength,
    onDeleteClick,
    onPublishClick,
  } = props;
  const condition = variant === "live";
  const fbPlatformId = "1c59a6e2-843b-4634-92a0-6fa93ce6c761";
  const { selectedAiGroup, selectedPlatform } = useSelector(
    (state: { audienceManager: AudienceManagerState }) => state.audienceManager
  );

  const { brandRoles } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  // const isBrandViewer = useMemo(
  //   () => brandRoles?.length === 1 && brandRoles[0]?.role === "brand-viewer",
  //   [brandRoles]
  // );

  const dispatch = useDispatch();

  const { resultMetrics } = useSelector(
    (state: { MainReducer: any }) => state.MainReducer
  );

  useEffect(() => {
    if (
      selectedAiGroup?.adAccounts?.length &&
      selectedAiGroup?.adAccounts?.length > 0
    ) {
      const element = selectedAiGroup.adAccounts.find(
        (item: any) => item.platformId === fbPlatformId
      );
      dispatch({
        type: GET_ELEMENT_RESULT_METRICS,
        payload: {
          elementId: element?.elementId,
        },
      });
    }
  }, [selectedAiGroup]);

  return (
    <Box
      sx={{
        backgroundColor: "#FFF",
        width: "100%",
        height: "100%",
        p: 2,
        borderRadius: "0 0 0.5rem 0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: `${condition ? "" : "flex-end"}`,
      }}
    >
      {condition && resultMetrics?.data && (
        <Typography className="font-10 font-400">
          Note : This is the performance of the adset from publish to till date.
          *Result is {resultMetrics?.data?.label}
        </Typography>
      )}
      {!condition && (
        <Stack direction="row" alignItems="center">
          <Typography className="font-10 font-400" fontStyle="italic">
            {selectedCohortsLength}/{totalCohortsLength} Selected Audience
          </Typography>
          <Stack direction="row">
            <RolesWrapper disabled={getRolesHierarchy(brandRoles) === "viewer"}>
              <Button
                variant="text"
                className="font-14"
                sx={{ color: "#E84B47", textTransform: "none" }}
                // disabled={selectedCohortsLength === 0 || isViewerRole(brandRoles)}
                disabled={
                  selectedCohortsLength === 0 ||
                  getRolesHierarchy(brandRoles) === "viewer"
                }
                onClick={() => onDeleteClick && onDeleteClick()}
              >
                Delete
              </Button>
            </RolesWrapper>
            <RolesWrapper
              disabled={
                getRolesHierarchy(brandRoles) === "viewer" ||
                getRolesHierarchy(brandRoles) === "editor"
              }
            >
              {/* <LightTooltip
              // title={isBrandViewer ? brandViewerAccessText : ""}
              title={isViewerRole(brandRoles) ? brandViewerAccessText : ""}
              placement="top-end"
            > */}
              <Button
                variant="contained"
                className="font-14"
                sx={{
                  backgroundColor: "#0869FB",
                  borderRadius: "0.5rem",
                  textTransform: "none",
                  // cursor: isBrandViewer ? "not-allowed !important" : "pointer",
                  // cursor: isViewerRole(brandRoles)
                  //   ? "not-allowed !important"
                  //   : "pointer",
                }}
                disabled={
                  selectedCohortsLength === 0 || selectedPlatform === "youtube"
                  //  ||
                  // isViewerRole(brandRoles) ||
                  // isEditorRole(brandRoles)
                }
                // onClick={() => {
                //   // if (!isBrandViewer && onPublishClick) {
                //   if (isViewerRole(brandRoles) && onPublishClick) {
                //     onPublishClick();
                //   }
                // }}
                onClick={() => {
                  // if (!isBrandViewer && onPublishClick) {
                  if (onPublishClick) {
                    onPublishClick();
                  }
                }}
              >
                Publish
              </Button>
              {/* </LightTooltip> */}
            </RolesWrapper>
          </Stack>
        </Stack>
      )}
    </Box>
  );
}

export default Footer;
