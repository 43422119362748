import React from "react";
import Drawer from "@mui/material/Drawer";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { ReactComponent as FbRound } from "../assets/fbRound.svg";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomChip from "./Chip";
import CircularProgress from "../../TargetingAIPlaygroundBeta/common/CircularProgress";
import { TABSHEADING } from "../constants/index";
import { styled } from "@mui/material/styles";
import { amountValue, getRoundedValue } from "utils/commonFunctions";
import CohortType from "common/CohortType";
import { genderMapper } from "Pages/TargetingAIPlaygroundBeta/utils";
import useFacebookTargetingEnumMapping from "utils/useFacebookTargetingsEnumMapping";
import getDemographicsKeywordsFromFlexibleSpecObject from "utils/getDemographicsKeywordsFromFlexibleSpecObject";

const TabsStyle = styled(Tab)(({ theme }) => ({
  marginRight: "0.5rem",
  textTransform: "none",
  fontSize: "0.75rem",

  "&.Mui-selected": {
    borderBottom: "1.5px solid #0869FB",
    borderRadius: "0.5rem",
    backgroundColor: "rgba(8, 105, 251, 0.1)",
  },
}));
function IndividualStackRowSummary({
  title,
  value,
  respectiveId = undefined,
}: any) {
  return (
    <Stack
      direction="row"
      sx={{ alignItems: "center", justifyContent: "space-between" }}
    >
      <Typography className="font-400 font-12 " sx={{ color: "#666666" }}>
        {title}
      </Typography>
      <Stack>
        <Typography
          className={`font-500 font-14 text-align-right ${
            value.length > 30 ? "overflow" : ""
          } `}
          sx={{ color: "#333333", width: "13rem", textAlign: "right" }}
          title={value}
        >
          {value.substring(0, 30)}
        </Typography>
        {respectiveId && (
          <Typography
            textAlign="end"
            className="font-400 font-10"
            sx={{ color: "#999999" }}
          >
            {respectiveId}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
function CircularProgressBlock({ percentage, color }: any) {
  return (
    <Stack direction="row" alignItems="center">
      {percentage && percentage !== "" ? (
        <>
          <Typography
            marginRight={0.5}
            className="font-500 font-14"
            sx={{ color: "#333" }}
          >
            {percentage}%
          </Typography>
          <CircularProgress
            sqSizePr={30}
            percentage={percentage}
            strokeWidth={5}
            strokeColor={color}
          />
        </>
      ) : (
        "-"
      )}
    </Stack>
  );
}
function IndividualStackRow({ title, value }: any) {
  return (
    <Stack
      direction="row"
      sx={{ alignItems: "center", justifyContent: "space-between" }}
    >
      <Typography className="font-400 font-12" sx={{ color: "#666666" }}>
        {title}
      </Typography>
      <Typography className="font-500 font-14" sx={{ color: "#333333" }}>
        {value}
      </Typography>
    </Stack>
  );
}

// for rows consisiting circular progression
function IndividualStackRowCP({ title, percentage, color }: any) {
  return (
    <Stack
      direction="row"
      sx={{ alignItems: "center", justifyContent: "space-between" }}
    >
      <Typography className="font-400 font-12" sx={{ color: "#666666" }}>
        {title}
      </Typography>
      <CircularProgressBlock percentage={percentage} color={color} />
    </Stack>
  );
}

function KeywordsBlock({
  interest_keywords,
  header,
}: {
  interest_keywords: any;
  header?: string;
}) {
  return (
    <Stack>
      {(interest_keywords && interest_keywords.length > 0) || header ? (
        <Box
          sx={{
            minHeight: "8.25rem",
            maxHeight: "8.25rem",
            backgroundColor: "#F5F9FF",
            borderRadius: "0.5rem",
            padding: "0.5rem",
            overflow: "auto",
          }}
        >
          {header && (
            <Typography
              component="div"
              color="#666"
              fontFamily="Inter"
              fontWeight="400"
              width="100%"
            >
              {header}
            </Typography>
          )}

          {interest_keywords && interest_keywords.length > 0
            ? interest_keywords.map(
                (element: { name: string }, index: number) => {
                  return (
                    <CustomChip
                      color="#0869FB"
                      bgColor="#0869fb1a"
                      margin="0.25rem"
                      label={element.name}
                      key={index}
                    />
                  );
                }
              )
            : "-"}
        </Box>
      ) : (
        "-"
      )}
    </Stack>
  );
}
function SummaryBlock({ type, summaryDetails, selectedCohort }: any) {
  const targetingMappings = useFacebookTargetingEnumMapping();
  return (
    <Stack
      spacing={2}
      sx={{
        px: 1.5,
      }}
    >
      {type !== "live" && (
        <IndividualStackRow
          title="Cohort Type"
          value={<CohortType type={selectedCohort.cohort_type} />}
        />
      )}
      {type === "live" ? (
        <IndividualStackRow
          title="Published On"
          value={summaryDetails.published_on.substring(0, 10) || "-"}
        />
      ) : (
        <IndividualStackRow
          title="Saved On"
          value={summaryDetails.saved_on?.substring(0, 10) || "-"}
        />
      )}
      {type === "live" ? (
        <IndividualStackRowSummary
          title="Ad Account Name"
          value={summaryDetails.ad_account_name || "-"}
          respectiveId={summaryDetails.ad_account_id || "-"}
        />
      ) : (
        <IndividualStackRowSummary
          title="Audience Size"
          value={amountValue(summaryDetails.potential_audience_size) || "-"}
        />
      )}
      {type === "live" ? (
        <IndividualStackRowSummary
          title="Campaign Name"
          value={summaryDetails.campaign_name || "-"}
          respectiveId={summaryDetails.campaign_id || "-"}
        />
      ) : (
        <IndividualStackRowSummary
          title="Relevance Score"
          value={getRoundedValue(summaryDetails.relevance_score) || "-"}
        />
      )}
      {type === "live" && (
        <IndividualStackRowSummary
          title="Adset Name"
          value={summaryDetails.adset_name || "-"}
          respectiveId={summaryDetails.adset_id || "-"}
        />
      )}
      {type === "live" && (
        <IndividualStackRowSummary
          title="Cohort Name"
          value={selectedCohort?.cohort_name ?? "-"}
        />
      )}
      {selectedCohort.type === "ICP" && (
        <IndividualStackRowSummary
          title="Age"
          value={`${selectedCohort?.icp_targeting?.age_min} - ${selectedCohort?.icp_targeting?.age_max} yrs`}
        />
      )}
      {selectedCohort.type === "ICP" && (
        <IndividualStackRowSummary
          title="Gender"
          value={`${
            selectedCohort?.icp_targeting?.genders?.length > 0
              ? genderMapper[selectedCohort?.icp_targeting?.genders]
              : "ALL"
          }`}
        />
      )}
      {selectedCohort.type === "INTEREST" && (
        <KeywordsBlock interest_keywords={summaryDetails.interest_keywords} />
      )}

      {selectedCohort.type === "ICP" && (
        <>
          <KeywordsBlock
            interest_keywords={selectedCohort?.icp_targeting?.flexible_spec.reduce(
              (prev: string[], curr: { interests?: string[] }) => {
                if (curr.interests) {
                  prev.push(...curr.interests);
                }
                return prev;
              },
              []
            )}
            header="Interests Keywords"
          />
          <KeywordsBlock
            interest_keywords={selectedCohort?.icp_targeting?.flexible_spec.reduce(
              (prev: string[], curr: Record<string, any>) => {
                const data = getDemographicsKeywordsFromFlexibleSpecObject(
                  curr,
                  targetingMappings
                );
                return prev.concat(...data);
              },
              []
            )}
            header="Demographics Keywords"
          />
          <KeywordsBlock
            interest_keywords={selectedCohort?.icp_targeting?.flexible_spec.reduce(
              (prev: string[], curr: { behaviors: string[] }) => {
                if (curr.behaviors) {
                  prev.push(...curr.behaviors);
                }
                return prev;
              },
              []
            )}
            header="Behaviour Keywords"
          />
        </>
      )}
    </Stack>
  );
}
function ConfigurationBlock({ configurationDetails, selectedCohort }: any) {
  return (
    <Stack
      spacing={1.5}
      sx={{
        px: 1.5,
      }}
    >
      <IndividualStackRow
        title="Brand Name"
        value={
          configurationDetails?.userInputBrandName ||
          configurationDetails?.brand_name ||
          selectedCohort?.brand_name ||
          "-"
        }
      />
      <IndividualStackRow
        title="Brand Website"
        value={
          configurationDetails?.userInputBrandWebsite ||
          configurationDetails?.brand_website ||
          selectedCohort?.brand_website ||
          "-"
        }
      />
      <Divider sx={{ border: "0.063rem solid #F0F0F0", marginY: "0.5rem" }} />
      <IndividualStackRowCP
        title="Explore"
        percentage={configurationDetails.explore}
        color="#619BF3"
      />
      <IndividualStackRowCP
        title="Exploit"
        percentage={configurationDetails.exploit}
        color="#0869FB"
      />
      <Divider sx={{ border: "0.063rem solid #F0F0F0", marginY: "0.5rem" }} />
      {selectedCohort.type === "INTEREST" && (
        <>
          <IndividualStackRowCP
            title="Brand Website"
            percentage={configurationDetails.brand_website_config}
            color="#F99F35"
          />
          <IndividualStackRowCP
            title="Competitor Website"
            percentage={configurationDetails.competitor_website}
            color="#FBB681"
          />
          <Divider
            sx={{ border: "0.063rem solid #F0F0F0", marginY: "0.5rem" }}
          />
          <IndividualStackRowCP
            title="Intent"
            percentage={configurationDetails.intent}
            color="#12C8B2"
          />
          <IndividualStackRowCP
            title="Traffic"
            percentage={configurationDetails.traffic}
            color="#71DED1"
          />
          <Divider
            sx={{ border: "0.063rem solid #F0F0F0", marginY: "0.5rem" }}
          />
          <Stack
            sx={
              !configurationDetails.custom_keywords ||
              configurationDetails.custom_keywords.length === 0
                ? { flexDirection: "row", width: "100%" }
                : {}
            }
          >
            <Typography
              marginBottom={"0.5rem"}
              className="font-400 font-12 w-100"
              sx={{ color: "#666666" }}
            >
              Custom Keywords
            </Typography>
            {configurationDetails.custom_keywords &&
            configurationDetails.custom_keywords.length > 0 ? (
              <Box
                sx={{
                  minHeight: "8.25rem",
                  maxHeight: "8.25rem",
                  backgroundColor: "#F5F9FF",
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                  overflow: "auto",
                }}
              >
                {configurationDetails.custom_keywords.map(
                  (element: string, index: number) => {
                    return (
                      <CustomChip
                        color="#0869FB"
                        bgColor="#0869fb1a"
                        margin="0.25rem"
                        label={element}
                        key={index}
                      />
                    );
                  }
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                -
              </Box>
            )}
          </Stack>
        </>
      )}
      {selectedCohort.type === "ICP" && (
        <>
          <Stack sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography
              component="div"
              className="w-100 font-400 font-12"
              sx={{ color: "#666", mb: 1 }}
            >
              Description Added:
            </Typography>

            {configurationDetails.icp_context_description &&
            configurationDetails.icp_context_description !== "" ? (
              <Box
                sx={{
                  border: "1px solid #EFEFEF",
                  width: "100%",
                  p: 1.5,
                  borderRadius: 1,
                }}
              >
                <Typography
                  component="div"
                  className="w-100 font-400 font-14 "
                  sx={{ color: "#5A5A5A" }}
                >
                  {configurationDetails.icp_context_description}
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                }}
              >
                -
              </Box>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
}
function CustomDrawer({
  isOpen,
  setIsOpen,
  type,
  selectedCohort,
  handleButtonClick,
}: any) {
  const summaryDetails = selectedCohort.summary;
  const configurationDetails = selectedCohort.configuration;
  const [selectedTab, setSelectedTab] = useState("summary");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const showNote = type !== "live" && selectedTab !== "summary";

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sx={{
        "& .MuiPaper-elevation": {
          width: "22rem",
          top: "20%",
          borderRadius: "0.5rem",
          border: "0.063rem solid #99D9FD",
          marginRight: "1rem",
          height: "calc(80% - 1rem)",
          overflow: "hidden",
        },
      }}
    >
      <Stack
        sx={{
          pt: 1.5,
          height: "100%",
          flexGrow: 1,
          width: "100%",
          position: "relative",
        }}
      >
        <Stack direction="row" sx={{ alignItems: "center", pb: 2, px: 1.5 }}>
          <FbRound />
          <Typography
            paddingX={1.5}
            className="font-16 font-600"
            sx={{ width: "100%", color: "#333" }}
            noWrap={true}
            title={
              type === "live"
                ? summaryDetails?.adset_name ?? "-"
                : selectedCohort?.cohort_name ?? "-"
            }
          >
            {type === "live"
              ? summaryDetails?.adset_name ?? "-"
              : selectedCohort?.cohort_name ?? "-"}
          </Typography>
          <CloseIcon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </Stack>
        <Stack>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            sx={{
              px: 1.5,
              minHeight: "0 !important",
              "& *": { minHeight: "0 !important" },
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& button": {
                py: 2,
                height: "10px",
              },
            }}
          >
            {TABSHEADING.map((tab: any) => (
              <TabsStyle
                key={tab.value}
                disableRipple
                value={tab.value}
                label={tab.label}
              />
            ))}
          </Tabs>
        </Stack>
        <Divider sx={{ border: "0.063rem solid #F0F0F0", marginY: "0.5rem" }} />
        <Box
          sx={{
            overflowY: "auto",
            height: showNote ? "calc(100% - 12.8rem)" : "calc(100% - 10.8rem)",
          }}
        >
          {selectedTab === "summary" ? (
            <SummaryBlock
              type={type}
              summaryDetails={summaryDetails}
              selectedCohort={selectedCohort}
            />
          ) : (
            <ConfigurationBlock
              configurationDetails={configurationDetails}
              selectedCohort={selectedCohort}
            />
          )}
        </Box>
        <Box
          textAlign="center"
          sx={{
            background: "white",
            width: "100%",
            p: 1.5,
            position: "absolute",
            zIndex: 1,
            bottom: 0,
            left: 0,
          }}
        >
          {showNote && (
            <Typography
              className="font-500 font-10"
              textAlign="left"
              sx={{ color: "#999999", pb: 0.5 }}
            >
              Note: Cohort has been generated by above mentioned brand and
              calibration parameters
            </Typography>
          )}
          <Button
            className="font-14 font-600"
            variant="outlined"
            sx={{
              width: "100%",
              borderRadius: "0.5rem",
              color: "#0869FB",
              border: "0.063rem solid #0869FB",
            }}
            onClick={handleButtonClick}
          >
            {type === "live" ? "View in Ads Manager" : "Quick Publish"}
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
}

export default CustomDrawer;
