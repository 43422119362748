import {
  FBHomeScreenInitialStateType,
  SET_ADS_SELECTION,
  SET_AD_ACCOUNTS_SELECTION,
  SetAdAccountsSelection,
  SetAdSetSelection,
  SetAdsSelection,
  Set_ADSET_SELECTION,
  SET_AD_ACCOUNT_DETAILS,
  SetAdAccountDetails,
  SET_AD_ADDITIONAL_INFO_RESET,
  SetAdAdditionalInfo,
  SetAdAdditionalInfoReset,
  SET_AD_ADDITIONAL_INFO,
} from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import {
  GET_ADSETS_FB,
  GET_AD_ACCOUNTS_FB,
  GetAdAccounts,
  GetAdSetsFB,
} from "store/types/CreativeAI_Revamp/facebook.types";

const initialState: FBHomeScreenInitialStateType = {
  adsSelection: {
    // selection: [],
    selectedAds: [],
  },
  adAdditionalInfo: {},
};

export const fbHomeScreenReducer = (
  state = initialState,
  {
    type,
    payload,
  }:
    | SetAdAccountsSelection
    | SetAdSetSelection
    | SetAdsSelection
    | GetAdAccounts
    | GetAdSetsFB
    | SetAdAccountDetails
    | SetAdAdditionalInfo
    | SetAdAdditionalInfoReset
) => {
  switch (type) {
    // case GET_AD_ACCOUNTS_FB: {
    //   return {
    //     ...state,
    //   };
    // }

    // case GET_ADSETS_FB: {
    //   return {
    //     ...state,
    //     adSetSelection: {
    //       selection: undefined,
    //       selectedAdSetId: "",
    //     },
    //   };
    // }

    // case

    case SET_AD_ACCOUNTS_SELECTION:
      return { ...state, adAccountsSelection: payload };
    case Set_ADSET_SELECTION:
      return { ...state, adSetSelection: payload };

    case SET_ADS_SELECTION:
      return { ...state, adsSelection: payload };
    case SET_AD_ACCOUNT_DETAILS:
      return { ...state, adAccountDetails: payload };

    case SET_AD_ADDITIONAL_INFO: {
      const adAdditionalInfo = { ...state.adAdditionalInfo };
      adAdditionalInfo[payload.adId] = {
        ...adAdditionalInfo[payload.adId],
        ...payload.adInfo,
      };
      return {
        ...state,
        adAdditionalInfo,
      };
    }
    case SET_AD_ADDITIONAL_INFO_RESET: {
      return {
        ...state,
        adAdditionalInfo: {},
      };
    }
    default:
      return state;
  }
};
