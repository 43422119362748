import React, { useEffect, useState } from "react";
import { StyledListVariantsBox } from "../styled";
import VariantCard from "./VariantCard";
import { useVariantListing } from "../hooks/useVariantsListing";
import VariantCardLoading from "./VariantCardLoading";
import { StyledText } from "components/shared";
import { Box } from "@mui/material";
import LoadingVariants from "./LoadingVariants";

export default function VariantListing() {
  const {
    variantList,
    filteredVariantList,
    selectedAd,
    addUpdateVariantLoading,
    adsList,
    imageAvatarLoading,
    videoDataFbLoading,
    isFetchingImageForAssetFeedSpec,
  } = useVariantListing();
  const [variantLength, setVariantLength] = useState(variantList?.length);
  useEffect(() => {
    if (variantLength < variantList?.length) {
      setTimeout(function () {
        const ref = document.getElementById(`variant_${variantLength}`);
        ref!;
        ref!.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
      setVariantLength(variantList.length);
    }
  }, [variantList?.length]);

  if (
    imageAvatarLoading ||
    videoDataFbLoading ||
    isFetchingImageForAssetFeedSpec
  ) {
    return <LoadingVariants />;
  }

  return (
    <StyledListVariantsBox>
      {filteredVariantList?.length > 0 ? (
        filteredVariantList?.map((item: any, index: number) => (
          <VariantCard
            id={`variant_${index}`}
            item={item}
            index={index}
            isNew={item?.payload?.isNew ? true : false}
          />
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StyledText>There are no variants to show as of now</StyledText>
        </Box>
      )}
      {addUpdateVariantLoading && <VariantCardLoading />}
    </StyledListVariantsBox>
  );
}
