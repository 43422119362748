import {
  SetAdsetById,
  SET_ADSET_DETAILS,
  TriggerContactUs,
  TRIGGER_CONTACT,
} from "store/types/CreativeAI_Revamp/facebookTypes";

const initialState = {
  adsetDetails: {},
  contactUs: { isLoading: false, message: "", isFailed: false },
};

const caiFBReducer = (
  state = initialState,
  action: SetAdsetById | TriggerContactUs | any
) => {
  switch (action.type) {
    case SET_ADSET_DETAILS: {
      return {
        ...state,
        adsetDetails: action.payload,
      };
    }
    case TRIGGER_CONTACT: {
      return {
        ...state,
        contactUs: { ...state.contactUs, ...action.payload },
      };
    }
    default: {
      return state;
    }
  }
};

export default caiFBReducer;
