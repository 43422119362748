import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CROSS_PLATFORM_URL, REACT_APP_BASE_URL_PIXIS2, TIKTOK_APP_ID } from "utils/path";
import HttpService from "HttpService/HttpService";
import { getBrandDetails } from "../../store/actions/accountsManager";
import { useDispatch } from "react-redux";
import { getLocalStorageData } from "utils/commonFunctions";
import { tms_skip_brand_name } from "utils/constants";
import { ReactComponent as Facebook } from "assets/svg/facebook-logo.svg";
import { ReactComponent as GoogleDrive } from "assets/svg/google-ads-logo.svg";
import { ReactComponent as LinkedIn } from "assets/svg/linkedin-logo.svg";
import { ReactComponent as Tiktok } from "assets/svg/tiktok-log.svg";
import { ReactComponent as Pinterest } from "assets/svg/pinterest-logo.svg";
import { useSearchParams } from "react-router-dom-v5-compat";

export function useQuery() {
  const [state, setState] = useState<any>({});
  const [token, setToken] = useState<string>("");

  const { search } = useLocation();
  useEffect(() => {
    if (window?.location?.href?.indexOf("#") > -1) {
      let href = window.location.href.replace("#_=_", "");
      href = href.replace("#", "?");
      window.location.replace(href);
    } else {
      let query = new URLSearchParams(search);
      const _decoded = window.atob(query.get("state") || "e30=");
      const _state = JSON.parse(_decoded);
      setState(_state);
      const _token = query.get("code") || "";
      setToken(_token);
    }
  }, []);
  return [state, token];
}

interface Href {
  [key: string]: {
    authBase: string;
    query: string[];
  };
}

export interface SupportedPlatforms {
  key: string;
  logo: React.ReactNode;
  title: string;
}

export const supportedPlatforms: SupportedPlatforms[] = [
  { key: "google", logo: <GoogleDrive />, title: "Google" },
  { key: "facebook", logo: <Facebook />, title: "Facebook" },
  { key: "linkedin", logo: <LinkedIn />, title: "LinkedIn" },
  { key: "google analytics", logo: <GoogleDrive />, title: "Google Analytics" },
  { key: "tiktok", logo: <Tiktok />, title: "TikTok" },
  { key: "pinterest ads", logo: <Pinterest />, title: "Pinterest" },
];

const platformQuery: Href = {
  google: {
    authBase: "https://accounts.google.com/o/oauth2/v2/auth?",
    query: [
      "scope=https://www.googleapis.com/auth/adwords profile https://www.googleapis.com/auth/userinfo.email",
      "response_type=code",
      "prompt=consent",
      "access_type=offline",
    ],
  },
  facebook: {
    authBase: "https://www.facebook.com/v14.0/dialog/oauth?",
    query: [
      "scope=email manage_fundraisers read_insights publish_video catalog_management pages_manage_cta pages_manage_instant_articles pages_show_list ads_management ads_read business_management instagram_basic instagram_manage_comments instagram_manage_insights instagram_content_publish leads_retrieval whatsapp_business_management instagram_manage_messages page_events pages_read_engagement pages_manage_metadata pages_read_user_content pages_manage_ads pages_manage_posts pages_manage_engagement whatsapp_business_messaging instagram_shopping_tag_products public_profile",
    ],
  },
  linkedin: {
    authBase: "http://www.linkedin.com/oauth/v2/authorization?",
    query: [
      "scope=r_liteprofile r_emailaddress rw_ads r_ads_reporting",
      "response_type=code",
    ],
  },
  tiktok: {
    authBase: "https://business-api.tiktok.com/portal/auth?",
    query: [`app_id=${TIKTOK_APP_ID}`],
  },
  "pinterest ads": {
    authBase: " https://www.pinterest.com/oauth?",
    query: [
      // "scope=boards:read pins:read ads:read user_accounts:read ads:write",
      "scope=boards:read,pins:read,ads:read,user_accounts:read,ads:write",
      "response_type=code",
    ],
  },
  "google analytics": {
    authBase:
      "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?",
    query: [
      "scope=https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
      "response_type=code",
      "prompt=consent",
      "access_type=offline",
    ],
  },
};

export function useSupportedPlatforms({
  brandName,
  brand_id,
}: {
  brandName: string;
  brand_id: string;
}) {
  const source = window.location.href;
  return {
    google: useHref({ platform: "google", brandName, brand_id, source }),
    facebook: useHref({ platform: "facebook", brandName, brand_id, source }),
    linkedin: useHref({ platform: "linkedin", brandName, brand_id, source }),
    "google analytics": useHref({
      platform: "google analytics",
      brandName,
      brand_id,
      source,
    }),
    tiktok: useHref({ platform: "tiktok", brandName, brand_id, source }),
    "pinterest ads": useHref({
      platform: "pinterest ads",
      brandName,
      brand_id,
      source,
    }),
  };
}

export function useHref({
  platform,
  brandName,
  brand_id,
  redirect,
  source = "",
}: {
  platform: string;
  brandName: string;
  brand_id: string;
  redirect?: string;
  source?: string;
}) {
  const [href, setHref] = useState<string>("");
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");

  useEffect(() => {
    if (brandName && brand_id) {
      let fetchClientId = false;
      if (!redirect) fetchClientId = true;
      else if (redirect === platform) fetchClientId = true;

      if (fetchClientId) {
        HttpService.get(CROSS_PLATFORM_URL, `/v1/api/token/oauth/${platform}`)
          .then(({ data }) => {
            const { data: { clientId = "" } = {} } = data;
            const urlObj = new URL(source);
            urlObj.searchParams.delete('accessToken');
            const state = { platform, brand_id, brandName, source: urlObj?.toString() };
            let _encoded = window.btoa(JSON.stringify(state));
            const commonQuery: string[] = [
              `redirect_uri=${isViewedFromBeta ? REACT_APP_BASE_URL_PIXIS2 : window.location.origin}/select-accounts`,
              `state=${_encoded}`,
              `client_id=${clientId}`,
            ];
            let _href = platformQuery[platform].authBase;
            _href += commonQuery
              .concat(platformQuery[platform].query)
              .join("&");
            if (redirect) {
              window.location.href = _href;
            } else {
              setHref(_href);
            }
          })
          .catch((err) => console.log("in catch block", err));
      }
    }
  }, [brandName, brand_id]);

  return href;
}

export function useBrandDetails() {
  const [brandName, setBrandName] = useState<string>("");
  const { search } = useLocation();
  let query = new URLSearchParams(search);
  const dispatch = useDispatch();
  let brand_id = query.get("brand_id") || "";
  let source = query.get("source") || "";
  const socialLoginFlag = getLocalStorageData(tms_skip_brand_name);

  useEffect(() => {
    if (brand_id) {
      dispatch(
        getBrandDetails(
          { brandId: brand_id },
          (response: any, error: boolean) => {
            if (!error) {
              setBrandName(response.data.name);
            }
          }
        )
      );
    }
  }, []);

  return { brand_id, brandName, source, socialLoginFlag };
}

export function usePlatform() {
  const { search } = useLocation();
  let query = new URLSearchParams(search);
  return query.get("platform") || "";
}
