import React, { useEffect, useState } from "react";
import { addDays } from "date-fns";
import { AddPacing } from "./AddPacing/AddPacing";
import {
  StyledPacingContainer,
  StyledPacingHeader,
} from "./BudgetPacing.styled";
import { EmptyPacing } from "./EmptyPacing/EmptyPacing";
import { useSelector, useDispatch } from "react-redux";
import { ParentAiGroupState } from "store/types/parentAiGroup";
import {
  getParentAiGroupPlanners,
  setParentAiGroupPlanners,
} from "store/actions/ParentAiGroup";
import BudgetType from "./BudgetType/BudgetType";
import {
  getAiGroupPlanners,
  setAiGroupPlanners,
  setSelectedAiGroup,
} from "store/actions/BudgetPlannerAiGroup";
import { getFirstSequentialDaysRange } from "../../../../utils/date.util";
import { getDaysBetweenDates } from "../../../../utils/commonFunctions";
import { GroupType } from "types/GlobalTypes";
import { AdAccountState } from "store/types/adaccount";
import moment from "moment";

const { v4: uuidv4 } = require("uuid");
export interface PacingType {
  id: any;
  isAdded: boolean;
  name?: string;
  startDate?: Date | string;
  endDate?: Date | string;
  isSaved: boolean;
  pacingType: string;
  status?: string;
}

export const BudgetPacing = ({
  type,
}: {
  type: GroupType.group | GroupType.parentGroup;
}) => {
  const [pacings, setPacings] = React.useState<PacingType[]>([]);

  const [pacingType, setPacingType] = useState<any>("Periodic Pacing");
  const [showType, setShowType] = React.useState(false);
  const [blockedDates, setBlockedDates] = React.useState<any>([]);
  const [currentDate, setCurrentDate] = useState("");

  const handleTypeShow = () => {
    setShowType(true);
  };
  const actions =
    type === "parentGroup"
      ? {
          getAllAiGroupPlanners: getParentAiGroupPlanners,
          setAiGroupPlanners: setParentAiGroupPlanners,
        }
      : {
          getAllAiGroupPlanners: getAiGroupPlanners,
          setAiGroupPlanners: setAiGroupPlanners,
        };
  const groupDetails = useSelector(
    (state: { ParentAiGroup: ParentAiGroupState; BudgetPlannerAiGroup: any }) =>
      type === "parentGroup" ? state.ParentAiGroup : state.BudgetPlannerAiGroup
  );
  const groups =
    type === "parentGroup"
      ? groupDetails.data.parentAiGroups
      : groupDetails.data.AiGroups;
  const selectedGroup =
    type === "parentGroup"
      ? groupDetails.data.selectedParentAiGroup
      : groupDetails.data.selectedAiGroup;
  const selectedPlannerId = groupDetails.data.selectedPlannerId;
  const keyName = type === "parentGroup" ? "parentAiGroupId" : "aiGroupId";
  const dispatch = useDispatch();
  const { selectedGroup: selectedGroupAdacc } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const timeZoneOffSet = groups[selectedGroup]?.timeZoneOffSet || -18000;
  let d = new Date();
  useEffect(() => {
    d = new Date(
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset() + timeZoneOffSet / 60)
    );
    setCurrentDate(moment(d).format("YYYY/MM/DD"));
  }, [timeZoneOffSet]);

  useEffect(() => {
    if (selectedGroup && groups[selectedGroup]?.planners) {
      const myData = Object.keys(groups[selectedGroup].planners).map((key) => {
        return {
          ...groups[selectedGroup].planners[key],
          startDate: new Date(groups[selectedGroup].planners[key].startDate),
          endDate: new Date(groups[selectedGroup].planners[key].endDate),
          isSaved: true,
          isAdded: false,
        };
      });
      setPacings(myData);
    } else if (selectedGroup && !groups[selectedGroup]?.planners) {
      setPacings([]);
    }
  }, [groups]);

  const handlePacing = () => {
    let dates = getFirstSequentialDaysRange(
      new Date(currentDate),
      blockedDates,
      7
    );
    let id = uuidv4();
    const newPacing = {
      isAdded: true,
      id: id,
      startDate: dates[0],
      endDate: dates[dates.length - 1],
      isSaved: false,
      pacingType: pacingType.includes("PERIODIC") ? "PERIODIC" : "DAILY",
    };
    setPacings((p) => [...p, newPacing]);
    dispatch({
      type:
        type === GroupType.parentGroup
          ? "SET_SELECTED_PLANNER_ID_PARENT"
          : "SET_SELECTED_PLANNER_ID",
      payload: id,
    });
  };

  const fetchPacings = async (
    selectedGroup: any,
    type: any,
    pacingId?: string
  ) => {
    if (selectedGroup) {
      dispatch(
        actions.getAllAiGroupPlanners(
          { [keyName]: selectedGroup },
          (res: any, err: boolean) => {
            if (!err) {
              dispatch({
                type:
                  type === GroupType.parentGroup
                    ? "SET_SELECTED_PLANNER_ID_PARENT"
                    : "SET_SELECTED_PLANNER_ID",
                payload: pacingId
                  ? pacingId
                  : res.data.length
                  ? res.data[0].id
                  : null,
              });
              const { planners, newBlockDates } = res.data.reduce(
                (pre: any, cur: any) => {
                  pre.planners[cur.id] = cur;
                  const dates: any = getDaysBetweenDates({
                    start: cur.startDate,
                    end: cur.endDate,
                  }).map((data: any) => new Date(data));
                  pre.newBlockDates = [...pre.newBlockDates, ...dates];
                  return pre;
                },
                { planners: {}, newBlockDates: [] }
              );
              setBlockedDates(newBlockDates);
              dispatch(
                actions.setAiGroupPlanners({
                  [keyName]: selectedGroup,
                  planners,
                })
              );
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    if (selectedGroupAdacc?.id) {
      dispatch(setSelectedAiGroup(selectedGroupAdacc?.id));
    }
  }, [selectedGroupAdacc]);

  useEffect(() => {
    if (selectedGroup) {
      fetchPacings(selectedGroup, type);
    }
  }, [selectedGroup, type]);

  return (
    <StyledPacingContainer>
      <StyledPacingHeader>
        {(
          groups[selectedGroup]?.planners?.[selectedPlannerId]?.pacingType ||
          pacingType
        ).includes("PERIODIC")
          ? "Periodic Pacing"
          : "Daily Budget Optimisation"}
      </StyledPacingHeader>
      {pacings.length === 0 ? (
        <EmptyPacing
          handlePacing={handlePacing}
          handleTypeShow={handleTypeShow}
          showType={showType}
          type={type}
        />
      ) : (
        <AddPacing
          type={type}
          pacings={pacings}
          fetchPacings={fetchPacings}
          setPacings={setPacings}
          handlePacing={handlePacing}
          pacingType={pacingType}
          showType={showType}
          handleTypeShow={handleTypeShow}
          blockedDates={blockedDates}
          setBlockedDates={setBlockedDates}
        />
      )}
      {showType && (
        <BudgetType
          open={showType}
          setOpen={setShowType}
          setPacingType={setPacingType}
        />
      )}
    </StyledPacingContainer>
  );
};
