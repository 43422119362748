/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useCallback, useEffect, useRef } from "react";
import Slider from "react-custom-slider";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import info from "../assets/svg/smallinfo.svg";
import Metrics from "../components/Campaigns/Metrics";
import Loader from "../components/common/Loader";
import NoData from "../components/common/NoData";
import PyxisTable from "../components/common/PyxisTable/PyxisTable";
import { getCampaignsDetails, getCampaignsFactors, getMetricsList, postCampaignFactors, setCampaignsDetails, setCampaignsFactors, setMetricList, setSpinner, setSuccess } from "../store/actions/campaign";
import { CampaignState } from "../store/types/campaign";
import { CommonState } from "../store/types/common";
import { initialState } from "../store/types/auth";
import ButtonUI from "../ui/ButtonUI/ButtonUI";
import { getFBAdsLink } from "../utils/commonFunctions";
import { mapFactors, mapFactorToResponse } from "../utils/mapFactors";
import useSortableData from "../utils/useSortableData";
import "./styles.scss";


const SliderWithLabel: React.FC<{
  setROIFactor: (number) => {};
  ROIFactor: number;
}> = ({ setROIFactor, ROIFactor }) => {
  const [value, setValue] = React.useState(50);
  const history = useHistory();

  useEffect(() => {
    setValue(ROIFactor);
  }, [ROIFactor]);

  return (
    <div className="slider-container">
      <div className="slider-percent-label">
        <p style={{ left: `${value * 2.5}px` }}>{`${value}%`}</p>
        <p style={{ left: `${(100 + value) * 2.5}px` }}>{`${100 - value}%`}</p>
      </div>
      <Slider
        value={ROIFactor}
        defaultValue={ROIFactor}
        showValue={false}
        fillTrack={false}
        trackLength={500}
        trackThickness={10}
        handlerWidth={18}
        handlerHeight={18}
        markers={0}
        onChange={(value) => {
          setROIFactor(value);
        }}
        valueRenderer={(value) => `${value}%`}
      />
    </div>
  );
};

const Campaigns: React.FC<unknown> = () => {
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { groupId } = common;
  const campaign = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const { campaignsDetails, campaignsFactors, metricList, success } = campaign;
  const { user, accountDetails, accountIdVal } = useSelector((state: { auth:initialState }) => state.auth);
  const target = useRef(null);
  const container = useRef(null);
  const dispatch = useDispatch();
  const { items, requestSort } = useSortableData(campaignsDetails || []);
  const { ROIResponse, QFResponse } = mapFactors((campaignsFactors?.metrics) || []);

  const [ROIMetrics, setROIMetrics] = React.useState([]);
  const [QFMetrics, setQFMetrics] = React.useState([]);
  const [ROIFactor, setROIFactor] = React.useState(50);
  const [QFError, setQFError] = React.useState(false);
  const [ROIError, SetROIError] = React.useState(false);

  const label = React.useMemo(
    () => (accountDetails ? (accountDetails.platform === "google" ? "Campaign" : "Ad Sets") : ""),
    [accountDetails]
  );

  const isGooglePlatform = React.useMemo(
    () => (accountDetails ? (accountDetails.platform === "google" ? true : false) : ""),
    [accountDetails]
  );

  useEffect(() => {
    const weightDistribuition = campaignsFactors?.weight_distribuition;
    setROIFactor((weightDistribuition?.roi || weightDistribuition?.quality) ? weightDistribuition?.roi : 50);
    setROIMetrics(ROIResponse);
    setQFMetrics(QFResponse);
  }, [campaignsFactors]);

  useEffect(() => {
    if (success) {
      alert("The data has been saved");
      dispatch(setSuccess(false));
    }
  }, [success]);

  useEffect(() => {
    if (groupId) {
      dispatch(setSpinner(true));
      dispatch(getCampaignsDetails({ groupId, user },
          (response: any, error: boolean) => {
          dispatch(setSpinner(false));
          if (!error) {
            dispatch(setCampaignsDetails(response))
          } else {
            dispatch(setCampaignsDetails([]))
            console.log(error);
          }
        }
      ));

      dispatch(setSpinner(true));
      dispatch(getCampaignsFactors({ groupId, user },
        (response: any, error: boolean) => {
        dispatch(setSpinner(false));
        if (!error) {
          dispatch(setCampaignsFactors(response))
        } else {
          dispatch(setCampaignsFactors({}))
          console.log(error);
        }
        }
      ));
    }
  }, [groupId]);

  useEffect(() => {
    dispatch(setSpinner(true));
    dispatch(getMetricsList({ groupId, user },
      (response: any, error: boolean) => {
      dispatch(setSpinner(false));
      if (!error) {
        dispatch(setMetricList(response));
      } else {
        dispatch(setMetricList([]))
        console.log(error);
      }
      }
    ));
  }, [user, groupId]);

  const metricsList = React.useMemo(() => !!metricList && metricList, [metricList]);

  const campaigTableHeader = React.useMemo(
    () => [
      {
        name: "S.no",
        isSortable: false,
      },
      {
        name: `${label} ID`,
        isSortable: true,
        sortKey: "id",
      },
      {
        name: `${label} Name`,
        isSortable: true,
        sortKey: "name",
      },
    ],
    []
  );

  const addMetric = (isROI) => {
    removeAllErrors();

    if (isROI) {
      setROIMetrics((ROIMetrics) => {
        const newMetric = {
          metric: "",
          weight: 0,
          error: false,
          errorMessage: "",
        };

        return [...ROIMetrics, newMetric];
      });
    } else {
      setQFMetrics((QFMetrics) => {
        const newMetric = {
          metric: "",
          weight: 0,
          error: false,
          errorMessage: "",
        };

        return [...QFMetrics, newMetric];
      });
    }

    setTimeout(() => {
      container.current.scroll({ top: container.current.scrollHeight, behavior: "smooth" })
    }, 0);
  }

  const updateMetrics = (index, value, type, isROI, filter) => {
    removeAllErrors();

    if (isROI) {
      setROIMetrics((ROIMetrics) => {
        const updatedMetrics = [...ROIMetrics];
        updatedMetrics[index][type] = value;
        updatedMetrics[index]['filter'] = filter;
        return updatedMetrics;
      });
    } else {
      setQFMetrics((QFMetrics) => {
        const updatedMetrics = [...QFMetrics];
        updatedMetrics[index][type] = value;
        updatedMetrics[index]['filter'] = filter;
        return updatedMetrics;
      });
    }
  };

  const validateAllInput = () => {
    let errorFound = false;

    if (!QFMetrics.length) {
      alert("Quality Factor Metric should not be empty");
      return;
    } else if (!ROIMetrics.length) {
      alert("ROI Factor Metric should not be empty");
      return;
    }

    const QFTestResult = QFMetrics.map((metrics, index) => {
      if (metrics.metric === "") {
        errorFound = true;
        return {
          ...metrics,
          error: true,
          errorMessage: "Metric Field Cannot be Left Empty",
        };
      } else if (metrics.weight === 0) {
        errorFound = true;
        return {
          ...metrics,
          error: true,
          errorMessage: "Weight Field Cannot be 0",
        };
      } else return metrics;
    });

    const ROITestResult = ROIMetrics.map((metrics, index) => {
      if (metrics.metric === "") {
        errorFound = true;
        return {
          ...metrics,
          error: true,
          errorMessage: "Metric Field Cannot be Left Empty",
        };
      } else if (metrics.weight === 0) {
        errorFound = true;
        return {
          ...metrics,
          error: true,
          errorMessage: "Weight Field Cannot be 0",
        };
      } else return metrics;
    });

    if (errorFound) {
      setQFMetrics(QFTestResult);
      setROIMetrics(ROITestResult);
      return false;
    }

    const QFTotalCount = QFMetrics.reduce((prev, current) => prev + parseInt(current.weight), 0);
    const ROITotalCount = ROIMetrics.reduce((prev, current) => prev + parseInt(current.weight), 0);
    QFTotalCount !== 100 && setQFError(true);
    ROITotalCount !== 100 && SetROIError(true);

    if (QFTotalCount !== 100 || ROITotalCount !== 100) return false;

    return true;
  };

  const setWeightError = (errorText, index, isROI) => {
    if (isROI) {
      setROIMetrics((ROIMetrics) => {
        const ErrorMetric = ROIMetrics.map((metric, i) => {
          if (index === i) {
            return {
              ...metric,
              error: true,
              errorMessage: errorText,
            };
          } else return metric;
        });

        return ErrorMetric;
      });
    } else {
      setQFMetrics((QFMetrics) => {
        const ErrorMetric = QFMetrics.map((metric, i) => {
          if (index === i) {
            return {
              ...metric,
              error: true,
              errorMessage: errorText,
            };
          } else return metric;
        });

        return ErrorMetric;
      });
    }
  };

  const removeAllErrors = () => {
    setQFMetrics((QFMetrics) => {
      const removeError = QFMetrics.map((metrics) => {
        return {
          ...metrics,
          error: false,
          errorMessage: "",
        };
      });

      return removeError;
    });

    setROIMetrics((ROIMetrics) => {
      const removeError = ROIMetrics.map((metrics) => {
        return {
          ...metrics,
          error: false,
          errorMessage: "",
        };
      });

      return removeError;
    });

    setQFError(false);
    SetROIError(false);
  };

  const submitResult = () => {
    dispatch(setSpinner(true));
    dispatch(postCampaignFactors({ groupId, user, payload: mapFactorToResponse(ROIMetrics, QFMetrics, ROIFactor)} ,
      (response: any, error: boolean) => {
      dispatch(setSpinner(false));
      if (!error) {
        dispatch(setCampaignsFactors(response.message))
        dispatch(setSuccess(true));
      } else {
        dispatch(setSuccess(false));
        alert(response.data.message);
        dispatch(setCampaignsFactors({}));
      }}
    ));
  };

  const campaignInfoTooltip = (props) => (
    <div {...props} className="custom-tooltip-layout tooltip">
      <div className="tootltip-wrapper">
        <div className="tooltip_header">{isGooglePlatform ? `${label}s` : label}</div>
        This shows a list of {label}s grouped under a particular AI Group
      </div>
    </div>
  );

  const handleAdsView = useCallback(() => {
    window.open(getFBAdsLink(accountIdVal, items.map((item) => item.id)));
  }, [accountIdVal, items])

  return (
    <div className="campaigns-container">
      <div className="campaigns" ref={container}>
        <div className="d-flex align-items-center justify-content-between w-100 pr-4">
          <h4 className="campaigns_header fs-6 p-0 pt-4 ml-4 d-flex">
            {isGooglePlatform ? `${label}s` : label}
            <div className="position-relative tooltip-container">
              <i ref={target}>
                <img className="ml-2" src={info} alt="info_style" />
              </i>
              {campaignInfoTooltip()}
            </div>
          </h4>
          {!isGooglePlatform && items.length > 0 && <ButtonUI className="btn secondary" onClick={handleAdsView}>
            View in Ads Manager
          </ButtonUI>}
        </div>
        <div className={`campaign-table ml-4 ${isGooglePlatform ? 'campaign-table-google' : ''}`}>
          {campaignsDetails ? campaignsDetails.length > 0 ? (
            <PyxisTable responsiveType={"md"} tableHeader={campaigTableHeader} type={"campaign"} data={items && items} requestSort={requestSort} />
          ) : (<NoData />) : (
              <Loader />
            )}
        </div>

        {isGooglePlatform ? (
          <div className="profitability">
            <h4 className="profitability_header fs-6 p-0 pt-4 ml-4 d-flex">
              Profitability Index
              <div className="position-relative tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                {campaignInfoTooltip()}
              </div>
            </h4>
            <p className="campaign_sub_heading">
              Define metrices and weight of metrics for ROI and quality factor
            </p>
            <hr />
            <h4 className="profitability_header fs-6 p-0 pt-4 ml-4 d-flex">
              ROI Factor
              <div className="position-relative tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                {campaignInfoTooltip()}
              </div>
            </h4>
            <p className="metric_sub_heading">Sum of all the weights should be 100</p>
            <div className="profitability_roi_metric">
              {!ROIMetrics.length ? (
                <button className="btn-primary btn-add-more" onClick={() => addMetric(true)}>
                  Click to add metric <span className="btn-plus">+</span>
                </button>
              ) : (
                <Metrics
                  setMetricData={setROIMetrics}
                  metricsData={ROIMetrics}
                  metricList={metricsList}
                  isROI={true}
                  updateMetrics={updateMetrics}
                  addMetric={addMetric}
                  sumError={ROIError}
                  setWeightError={setWeightError}
                />
              )}
            </div>
            <hr />
            <h4 className="profitability_header fs-6 p-0 pt-4 ml-4 d-flex">
              Quality Factor
              <div className="position-relative tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                {campaignInfoTooltip()}
              </div>
            </h4>
            <p className="metric_sub_heading">Sum of all the weights should be 100</p>
            <div className="profitability_roi_metric">
              {!QFMetrics.length ? (
                <button className="btn-primary btn-add-more" onClick={() => addMetric(false)}>
                  Click to add metric <span className="btn-plus">+</span>
                </button>
              ) : (
                <Metrics
                  setMetricData={setQFMetrics}
                  metricsData={QFMetrics}
                  metricList={metricsList}
                  isROI={false}
                  updateMetrics={updateMetrics}
                  addMetric={addMetric}
                  sumError={QFError}
                  setWeightError={setWeightError}
                />
              )}
            </div>
            <hr />
            <h4 className="profitability_header fs-6 p-0 pt-4 ml-4 d-flex">
              ROI factor and quality factor weight distribuition
            </h4>

            <div className="slider-rc">
              <p>ROI Factor</p>
              <SliderWithLabel setROIFactor={setROIFactor} ROIFactor={ROIFactor} />
              <p>Quality Factor</p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {isGooglePlatform ? (
        <div className="campaign-footer">
          <button className="btn-apply" onClick={() => validateAllInput() && submitResult()}>
            Apply
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Campaigns;
