import {
  AddBusinessUnit_CreativeAi,
  ADD_BUSINESS_UNIT,
  ADD_BUSINESS_UNIT_STATE,
  AigroupCreativeRotationStatusUpdate_CreativeAi,
  AIGROUP_CREATIVE_ROTATION_STATUS_UPDATE,
  DeleteBusinessUnitById_CreativeAi,
  DELETE_BUSINESS_UNIT_BY_ID,
  DownloadCreativeRotationLogs_CreativeAi,
  DownloadCreativeVariantsLogs_CreativeAi,
  DOWNLOAD_CREATIVE_ROTATION_LOGS,
  DOWNLOAD_CREATIVE_VARIANTS_LOGS,
  GetAdsetById_CreativeAi,
  GetAdsetsList_CreativeAi,
  GetAdsList_CreativeAi,
  GetAigroupById_CreativeAi,
  GetAigroupList_CreativeAi,
  GetAllBusinessUnits_CreativeAi,
  GetBusinessUnitById_CreativeAi,
  GetCreativeRotationLogs_CreativeAi,
  GetCreativeVariantsLogs_CreativeAi,
  GET_ADSETS_LIST,
  GET_ADSET_BY_ID,
  GET_ADS_LIST,
  GET_AIGROUP_BY_ID,
  GET_AIGROUP_LIST,
  GET_ALL_BUSINESS_UNITS,
  GET_BUSINESS_UNIT_BY_ID,
  GET_CREATIVE_ROTATION_LOGS,
  GET_CREATIVE_VARIANTS_LOGS,
  GET_USER_INFO,
  SET_ADS_LIST,
  SET_AIGROUP_LIST,
  SET_ALL_BUSINESS_UNITS,
  SET_BUSINESS_UNIT_BY_ID,
  SET_CREATIVE_ROTATION_LOGS,
  UpdateBusinessUnit_CreativeAi,
  UPDATE_BUSINESS_UNIT,
  UPDATE_BUSINESS_UNIT_STATE,
} from "../types/CreativeAI/Facebook/CreateVariant/createVariantType";
import { takeLatest, call, takeEvery, put } from "redux-saga/effects";
import { BASE_URL, CROSS_PLATFORM_URL } from "../../utils/path";
import { crossPlatform, groupV2API } from "../../MappedAPI/MappedAPI";
import HttpService from "../../HttpService/HttpService";
import {
  businessUnitList,
  creativeRotationsLogsDummy,
  creativeVariantsLogsDummy,
  getAdList,
  getAdSetList,
  variantsLogs,
} from "../../Dummy/JSON/ConstantData";
import {
  GET_COHORT,
  GET_COHORT_LIST,
} from "../types/CreativeAI/Facebook/SelectCohort/selectCohortType";
import { downloadCsv, downloadFile } from "../../utils/commonFunctions";

function* GetAdsListRequest(action: GetAdsList_CreativeAi): any {
  const adSetID = action.payload.adSetID;
  try {
    // const urlParams = `${crossPlatform}/brand/${brandId}/ai-group`;
    // const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    const { data } = yield getAdList.data;
    yield put({ type: SET_ADS_LIST, payload: data });
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}
function* GetAdsetsListRequest(action: GetAdsetsList_CreativeAi): any {
  const aigroup = action.payload;

  try {
    // const urlParams = `${crossPlatform}/brand/${brandId}/ai-group`;
    // const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    const { adsets: data } = yield getAdSetList.data;
    yield put({ type: GET_COHORT_LIST, payload: data });
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}
function* GetAdsetByIdRequest(action: GetAdsetById_CreativeAi): any {
  const { adSetID } = action.payload;

  try {
    // const urlParams = `${crossPlatform}/brand/${brandId}/ai-group`;
    // const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    const { adsets: data } = yield getAdSetList.data;
    let adSet = data.find(
      (item: any, index: number) => item.adset.id === adSetID
    );
    yield put({ type: GET_COHORT, payload: adSet });
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}
function* GetAigroupListRequest(action: GetAigroupList_CreativeAi): any {
  const { adAccountId, platformID } = action.payload;
  yield put({ type: SET_AIGROUP_LIST, payload: { loading: true } });

  try {
    const urlParams = `${crossPlatform}/ai-group/${adAccountId}/${platformID}/get-aigroups`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    const { data } = yield response.data;

    yield put({ type: SET_AIGROUP_LIST, payload: { loading: false, data } });
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}
function* GetAigroupByIDRequest(action: GetAigroupById_CreativeAi): any {
  try {
    // const urlParams = `${crossPlatform}/brand/${brandId}/ai-group`;
    // const urlParams = `${crossPlatform}/ai-group/875ab4a9-ee29-4715-8998-4bee61fdbc37`;
    // const urlParams = `${crossPlatform}/ai-group`;
    // // const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    // const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    // console.log("aiGroup API response", response);
    // const { data } = yield response.data;
    // yield put({ type: SET_AIGROUP_LIST, payload: data });
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}
function* GetCreativeRotationLogsRequest(
  action: GetCreativeRotationLogs_CreativeAi
): any {
  const { elementId, user, ad_account, params } = action.payload;
  try {
    if (!params?.download) {
      yield put({
        type: "GET_CREATIVE_VARIATION_LOGS_STATE",
        payload: { loading: true },
      });
      const urlParams = `${crossPlatform}/creative-rotation/${elementId}/activity-logs?original_ad_account_id=${ad_account}&end_date=${params?.end_date}&download=${params?.download}&page=${params?.page}&page_size=${params?.page_size}&start_date=${params?.start_date}`;
      const response = yield call(
        HttpService.get,
        CROSS_PLATFORM_URL,
        urlParams,
        user
      );
      if (response?.data?.error === false) {
        yield put({
          type: "SET_CREATIVE_ROTATION_LOGS",
          payload: response?.data?.data?.data,
        });
        yield put({
          type: "GET_CREATIVE_VARIATION_LOGS_STATE",
          payload: { loading: false },
        });
      }
    } else {
      const urlParams = `${crossPlatform}/creative-rotation/${elementId}/activity-logs?original_ad_account_id=${ad_account}&end_date=${params?.end_date}&download=${params?.download}&page=${params?.page}&page_size=${params?.page_size}&start_date=${params?.start_date}`;
      const response = yield call(
        HttpService.get,
        CROSS_PLATFORM_URL,
        urlParams,
        user
      );

      // downloadFile(response?.data?.data?.data?.file_url, "cv_activity_logs");
      const downloadFileInReact = () => {
        var file_path = `${response?.data?.data?.data?.file_url}`;
        var a: any = document.createElement("A");
        a.href = file_path;
        a.download = true;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      downloadFileInReact();
    }
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
    yield put({
      type: "GET_CREATIVE_VARIATION_LOGS_STATE",
      payload: { loading: false },
    });
  }
}
function* GetCreativeVariantsLogsRequest(
  action: GetCreativeVariantsLogs_CreativeAi
): any {
  const { elementId, user, ad_account, params } = action.payload;

  try {
    if (!params?.download) {
      yield put({
        type: "GET_CREATIVE_VARIATION_LOGS_STATE",
        payload: { loading: true },
      });
      // const urlParams = `${crossPlatform}/creative-rotation/${elementId}/activity-logs?original_ad_account_id=${original_ad_account_id}&end_date=${end_date}&download=${download}&page=${page}&page_size=${page_size}&start_date=${start_date}`;
      const urlParams = `${crossPlatform}/variant/publish-variant?to=${
        params?.end_date
      }&type=${params?.download ? "download" : ""}&page=${params?.page}&limit=${
        params?.limit
      }&from=${params?.start_date}&elementId=${elementId}`;
      const response = yield call(
        HttpService.get,
        CROSS_PLATFORM_URL,
        urlParams
      );

      yield put({
        type: "GET_CREATIVE_VARIATION_LOGS_STATE",
        payload: { loading: false },
      });
      yield put({
        type: "SET_CREATIVE_VARIANTS_LOGS",
        payload: response.data.data.data,
      });
    } else {
      const urlParams = `${crossPlatform}/variant/publish-variant?to=${
        params?.end_date
      }&type=${params?.download ? "download" : ""}&page=${params?.page}&limit=${
        params?.limit
      }&from=${params?.start_date}&elementId=${elementId}`;
      const response = yield call(
        HttpService.get,
        CROSS_PLATFORM_URL,
        urlParams
      );

      const downloadFileInReact = () => {
        var file_path = `${response?.data?.data?.url}`;
        var a: any = document.createElement("A");
        a.href = file_path;
        a.download = true;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      downloadFileInReact();
    }
  } catch (error: any) {
    yield put({
      type: "GET_CREATIVE_VARIATION_LOGS_STATE",
      payload: { loading: false },
    });
    console.log("function*getGroups ~ error", error);
  }
}
function* GetAllBusinessUnitsRequest(
  action: GetAllBusinessUnits_CreativeAi
): any {
  const {
    searchField = "",
    search = "",
    order = "ASC",
    orderBy = "businessUnit",
    limit = "50",
    page = "",
    brandId,
  } = action.payload;

  try {
    const urlParams = `${crossPlatform}/business-unit?searchField=${searchField}&search=${search}&order=${order}&orderBy=${orderBy}&limit=${limit}&page=${page}&brandId=${brandId}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    const { rows } = response.data.data;
    yield put({ type: SET_ALL_BUSINESS_UNITS, payload: rows });
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}
function* GetBusinessUnitByIDRequest(
  action: GetBusinessUnitById_CreativeAi
): any {
  const { id } = action.payload;
  try {
    const urlParams = `${crossPlatform}/business-unit/${id}`;

    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);

    const { data } = response.data;
    yield put({ type: SET_BUSINESS_UNIT_BY_ID, payload: data });
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}
function* AddBusinessUnitRequest(action: AddBusinessUnit_CreativeAi): any {
  const { formData, user, brandId } = action.payload;

  try {
    yield put({ type: ADD_BUSINESS_UNIT_STATE, payload: { loading: true } });
    const urlParams = `${crossPlatform}/business-unit`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      `${urlParams}`,
      user,
      formData
    );
    // const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (response.data.success) {
      yield put({ type: ADD_BUSINESS_UNIT_STATE, payload: { loading: false } });
      yield put({
        type: GET_ALL_BUSINESS_UNITS,
        payload: { order: "ASC", orderBy: "businessUnit", limit: 50, brandId },
      });
    }
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}
function* UpdateBusinessUnitRequest(
  action: UpdateBusinessUnit_CreativeAi
): any {
  const { formData, user, id, creativeAiId, brandId } = action.payload;

  try {
    yield put({
      type: UPDATE_BUSINESS_UNIT_STATE,
      payload: { loading: true },
    });

    const urlParams = `${crossPlatform}/business-unit/${id}/${creativeAiId}`;
    const response = yield call(
      HttpService.put,
      BASE_URL,
      `${urlParams}`,
      user,
      { ...formData, brandId }
    );

    if (response.data.success) {
      yield put({
        type: UPDATE_BUSINESS_UNIT_STATE,
        payload: { loading: false, success: true },
      });
    }

    yield put({
      type: GET_ALL_BUSINESS_UNITS,
      payload: { order: "ASC", orderBy: "businessUnit", limit: 50, brandId },
    });

    // yield put({ type: ADD_BUSINESS_UNIT_STATE, payload: { loading: false } });

    // const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}
function* DeleteBusinessUnitRequest(
  action: DeleteBusinessUnitById_CreativeAi
): any {
  const { id, user, brandId } = action.payload;
  try {
    const urlParams = `${crossPlatform}/business-unit/${id}`;

    const response = yield call(
      HttpService.delete,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    yield put({
      type: GET_ALL_BUSINESS_UNITS,
      payload: { order: "ASC", orderBy: "businessUnit", limit: 50, brandId },
    });
  } catch (error: any) {
    console.log("function*getGroups ~ error", error?.response);
    const { data } = error?.response;
    alert(data.message);
  }
}

function* DownloadCreativeRotationLogsRequest(
  action: DownloadCreativeRotationLogs_CreativeAi
): any {
  const {
    elementId,
    original_ad_account_id,
    start_date,
    end_date,
    download,
    page,
    page_size,
    user,
  } = action.payload;
  const urlParams = `${crossPlatform}/creative-rotation/${elementId}/activity-logs?original_ad_account_id=${original_ad_account_id}&end_date=${end_date}&download=${download}&page=${page}&page_size=${page_size}&start_date=${start_date}`;
  const response = yield call(
    HttpService.get,
    CROSS_PLATFORM_URL,
    urlParams,
    user
  );

  try {
    // const urlParams = `${crossPlatform}/brand/${brandId}/ai-group`;
    // const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}

function* DownloadCreativeVariantsLogsRequest(
  action: DownloadCreativeVariantsLogs_CreativeAi
): any {
  const {
    elementId,
    original_ad_account_id,
    start_date,
    end_date,
    download,
    page,
    page_size,
    user,
  } = action.payload;
  const urlParams = `${crossPlatform}/creative-rotation/${elementId}/activity-logs?original_ad_account_id=${original_ad_account_id}&end_date=${end_date}&download=${download}&page=${page}&page_size=${page_size}&start_date=${start_date}`;
  const response = yield call(
    HttpService.get,
    CROSS_PLATFORM_URL,
    urlParams,
    user
  );

  try {
    // const urlParams = `${crossPlatform}/brand/${brandId}/ai-group`;
    // const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}
function* AigroupCreativeRotationStatusUpdateRequest(
  action: AigroupCreativeRotationStatusUpdate_CreativeAi
): any {
  const { id, user, status } = action.payload;

  try {
    // const urlParams = `No API FOR this Action ${crossPlatform}/aigroup/status/${id} `;
    let urlParams;
    if (status) {
      urlParams = `${crossPlatform}/element/${id}/disable-creative-rotation`;
      // https://core-performance-dev.pixis.ai/v1/api/element/8ab585fa-6876-40f9-a633-a02bcfb18d0e/disable-creative-rotation
    } else {
      urlParams = `${crossPlatform}/element/${id}/enable-creative-rotation`;
    }
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      `${urlParams}`,
      user,
      {}
    );
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}
function* GetUserInfoRequest(
  action: AigroupCreativeRotationStatusUpdate_CreativeAi
): any {
  try {
    const urlParams = `${crossPlatform}/auth/validate-token`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);

    yield put({ type: "SET_USER_INFO", payload: response.data.data });
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
  }
}

export function* creativeAIWatcher() {
  yield takeLatest(GET_ADSETS_LIST, GetAdsetsListRequest);
  yield takeLatest(GET_ADSET_BY_ID, GetAdsetByIdRequest);
  yield takeLatest(GET_AIGROUP_LIST, GetAigroupListRequest);
  yield takeLatest(GET_AIGROUP_BY_ID, GetAigroupByIDRequest);
  yield takeLatest(GET_CREATIVE_ROTATION_LOGS, GetCreativeRotationLogsRequest);
  yield takeLatest(GET_CREATIVE_VARIANTS_LOGS, GetCreativeVariantsLogsRequest);
  yield takeLatest(
    DOWNLOAD_CREATIVE_ROTATION_LOGS,
    DownloadCreativeRotationLogsRequest
  );
  yield takeLatest(
    DOWNLOAD_CREATIVE_VARIANTS_LOGS,
    DownloadCreativeVariantsLogsRequest
  );
  yield takeLatest(GET_ALL_BUSINESS_UNITS, GetAllBusinessUnitsRequest);
  yield takeLatest(GET_BUSINESS_UNIT_BY_ID, GetBusinessUnitByIDRequest);
  yield takeLatest(ADD_BUSINESS_UNIT, AddBusinessUnitRequest);
  yield takeLatest(UPDATE_BUSINESS_UNIT, UpdateBusinessUnitRequest);
  yield takeLatest(DELETE_BUSINESS_UNIT_BY_ID, DeleteBusinessUnitRequest);
  yield takeLatest(
    AIGROUP_CREATIVE_ROTATION_STATUS_UPDATE,
    AigroupCreativeRotationStatusUpdateRequest
  );
  yield takeLatest(GET_USER_INFO, GetUserInfoRequest);
}
