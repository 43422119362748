import HttpService from "../../../HttpService/HttpService";
import { call, put, takeLatest } from "redux-saga/effects";
import { targetingAiRecommendation } from "MappedAPI/MappedAPI";
import {
  GetPublishedCohorts,
  GetDraftedCohorts,
  GetDraftedCohortFilters,
  GetPublishedCohortsFilter,
  GET_PUBLISHED_COHORTS,
  GET_DRAFTED_COHORTS,
  GET_DRAFTED_COHORT_FILTERS,
  GET_PUBLISHED_COHORTS_FILTER,
} from "store/types/AudienceManager";
import { CROSS_PLATFORM_URL } from "utils/path";
import { queryParamsMaker } from "Pages/TokenManagementSystem/utils";
import {
  setLoading,
  setCommonFilters,
  setDraftedCohorts,
  setDraftedCohortsFilters,
  setRecommendedCohorts,
  setRecommendedCohortsFilters,
  setPublishedCohorts,
  setPublishedCohortsFilters,
} from "../../actions/AudienceManager/index";
import { takeEvery } from "@redux-saga/core/effects";
import { setBucketLength } from "../../actions/AudienceManager/index";

function* getDraftedCohortsDetails(action: GetDraftedCohorts): any {
  let {
    payload: { aiGroupId, ...restArgs },
  } = action;

  const loaderArgs =
    restArgs.status === "isDrafted"
      ? {
          drafted: false,
        }
      : { recommended: false };
  try {
    const urlParams = `${targetingAiRecommendation}/${aiGroupId}/get-saved-cohorts-details?${queryParamsMaker(
      restArgs
    )}&limit=1000`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);

    if (response.data.success) {
      const methodToCall =
        restArgs.status === "isDrafted"
          ? setDraftedCohorts
          : setRecommendedCohorts;
      yield put(methodToCall(response.data.data.data));
      yield put(
        setBucketLength({
          type: restArgs.status === "isDrafted" ? "drafted" : "recommended",
          length: response.data.data.counts,
        })
      );
      yield put(setLoading(loaderArgs));
      restArgs.callback && restArgs.callback(response.data,response.data.success)
    } else {
      yield put(
        setLoading({
          drafted: false,
        })
      );
    }
  } catch (error: any) {
    console.log("getDraftedCohortsDetails ~ error", error);
    yield put(setLoading(loaderArgs));
  }
}
function* getDraftededCohortFilters(action: GetDraftedCohortFilters): any {
  let {
    payload: { aiGroupId, status },
  } = action;
  try {
    const urlParams = `${targetingAiRecommendation}/${aiGroupId}/get-saved-cohort-filters?status=${status}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (response.data.success && Object.keys(response.data.data).length > 0) {
      const filters = response.data.data;
      const methodToCall =
        status === "isDrafted"
          ? setDraftedCohortsFilters
          : setRecommendedCohortsFilters;
      const uniqueBrands = (
        filters.brands.length > 0 ? [...new Set(filters.brands)] : []
      ) as string[];

      const uniqueWebsites = (
        filters.brand_websites.length > 0
          ? [...new Set(filters.brand_websites)]
          : []
      ) as string[];

      yield put(
        setCommonFilters({
          key: ["brands", "brand_websites"],
          value: [uniqueBrands, uniqueWebsites],
        })
      );
      // yield put(
      //   methodToCall({
      //     audience_size: filters.audience_size,
      //     relevance_score: filters.relevance_score,
      //     performance_score: filters.performance_score,
      //   })
      // );
    } else {
      // callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("getPublishedCohortsDetails ~ error", error);
  }
}
function* getPublishedCohortsFilter(action: GetPublishedCohortsFilter): any {
  let {
    payload: { aiGroupId },
  } = action;
  try {
    const urlParams = `${targetingAiRecommendation}/${aiGroupId}/get-published-cohort-filters`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (response.data.success && Object.keys(response.data.data).length > 0) {
      const filters = response.data.data;
      yield put(
        setPublishedCohortsFilters({
          spends: filters.spends,
          results: filters.results,
          cpr: filters.cpr,
          ad_accounts: filters.ad_accounts,
        })
      );
      const uniqueBrands = (
        filters.brands.length > 0 ? [...new Set(filters.brands)] : []
      ) as string[];
      const uniqueWebsites = (
        filters.brand_websites.length > 0
          ? [...new Set(filters.brand_websites)]
          : []
      ) as string[];
      yield put(
        setCommonFilters({
          key: ["brands", "brand_websites"],
          value: [uniqueBrands, uniqueWebsites],
        })
      );
    } else {
      // callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("getPublishedCohortsDetails ~ error", error);
    // callback && callback(error.response, true);
  }
}
function* getPublishedCohortsDetails(action: GetPublishedCohorts): any {
  let {
    payload: { aiGroupId, ...restArgs },
  } = action;

  try {
    const urlParams = `${targetingAiRecommendation}/${aiGroupId}/get-published-cohorts-details?${queryParamsMaker(
      restArgs
    )}&limit=1000`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);

    if (response.data.success) {
      yield put(setPublishedCohorts(response.data.data.data));
      yield put(
        setBucketLength({ type: "live", length: response.data.data.counts })
      );
      yield put(
        setLoading({
          live: false,
        })
      );
    } else {
      yield put(
        setLoading({
          live: false,
        })
      );
    }
  } catch (error: any) {
    console.log("getPublishedCohortsDetails ~ error", error);
    yield put(
      setLoading({
        live: false,
      })
    );
  }
}

export function* AudienceManagerWatcher() {
  yield takeEvery(GET_DRAFTED_COHORTS, getDraftedCohortsDetails);
  yield takeLatest(GET_DRAFTED_COHORT_FILTERS, getDraftededCohortFilters);
  yield takeLatest(GET_PUBLISHED_COHORTS, getPublishedCohortsDetails);
  yield takeEvery(GET_PUBLISHED_COHORTS_FILTER, getPublishedCohortsFilter);
}
