import { Fragment } from "react";

import VariantCardLoading from "./VariantCardLoading";
import { StyledListVariantsBox } from "../styled";
export default function LoadingVariants() {
  return (
    <StyledListVariantsBox>
      <VariantCardLoading />
      <VariantCardLoading />
      <VariantCardLoading />
      <VariantCardLoading />
      <VariantCardLoading />
      <VariantCardLoading />
    </StyledListVariantsBox>
  );
}
