import { Box, Divider, Skeleton } from "@mui/material";
import React, { Fragment } from "react";
import {
  StyledAdsListContainer,
  StyledAdsListContainerTitle,
  StyledAdsListMainContainer,
  StyledCurveWithIcons,
  StyledIconContainer,
} from "./styled";

export default function AdCardLoading() {
  return (
    <Fragment>
      <AdCardLoadingItem />
      <AdCardLoadingItem />
      <AdCardLoadingItem />
      <AdCardLoadingItem />
    </Fragment>
  );
}

export function AdCardLoadingItem() {
  // will change layout later
  return (
    <StyledAdsListMainContainer>
      <StyledAdsListContainer active={false}>
        <StyledCurveWithIcons>
          <StyledIconContainer
            style={{
              right: "-.8em",
              top: " -0.8em",
            }}
          >
            <Skeleton
              sx={{
                borderRadius: "20px",
                height: "25px",
                width: "25px",
              }}
              variant="circular"
            />
            <Box pr=".6em" />

            <Skeleton
              sx={{
                borderRadius: "20px",
                height: "25px",
                width: "25px",
              }}
              variant="circular"
            />
          </StyledIconContainer>
        </StyledCurveWithIcons>

        <Box display="flex">
          <Skeleton
            sx={{
              width: "5em",
              height: "5em",
              marginLeft: "0.5em",
              borderRadius: "4px",
            }}
            variant="rectangular"
          />

          <StyledAdsListContainerTitle>
            <Skeleton
              width="50%"
              height="1.2em"
              variant="rectangular"
              sx={{
                borderRadius: "4px",
              }}
            />
            <Box pt=".5em" />
            <Skeleton width="70%" height=".8em" />
            <Skeleton width="40%" height=".8em" />
          </StyledAdsListContainerTitle>
        </Box>
        <Box display="flex" gap=".4em" p=".4em">
          <BottomItem />
          <Divider
            orientation="vertical"
            flexItem
            style={{ height: "35px", color: "#EFEFEF" }}
          />
          <BottomItem />

          <Divider
            orientation="vertical"
            flexItem
            style={{ height: "35px", color: "#EFEFEF" }}
          />
          <BottomItem />
        </Box>
      </StyledAdsListContainer>
    </StyledAdsListMainContainer>
  );
}

const BottomItem = () => {
  return (
    <Box flex={1} display="flex" alignItems="center">
      <Skeleton
        sx={{
          borderRadius: "20px",
          height: "25px",
          width: "25px",
        }}
        variant="circular"
      />
      <Box display="flex" flexDirection="column" flex={1} p="0em .5em">
        <Skeleton width="90%" height="10px" />
        <Skeleton width="50%" height="10px" />
      </Box>
    </Box>
  );
};
