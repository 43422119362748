export const Months = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const ProgressBarColors = {
  disabled: "#DDDDDD",
  underUse: "#FABF28",
  exactUse: "#28B49B",
  overUse: "#FB5348",
};

export const ProgressBarBackgroundColors = {
  underUse: "rgba(250, 191, 40, 0.2)",
  exactUse: "rgba(82, 182, 132, 0.2)",
  overUse: "rgba(217, 103, 84, 0.2)",
};

export const PacingsStatusColors = {
  ACTIVE: "#28B49B",
  SCHEDULE: "#FABF28",
  COMPLETED: "#DDDDDD",
  UNCONFIGURED: "#EF5854",
  ABORTED: "#EF5854",
};

export type Cell_Day = {
  month: number;
  year: number;
  monthText: string;
  ISODate: string;
  dailyLimit: number;
  value: number | string;
  amountSpent: number;
  progress_percentage: number;
  info: string;
  fullDateString: string; //
  fullDateNumber: number;
  day: number;
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  pacingType: string;
  spends: number | string;
  isSelected: boolean;
  dailyLimitUpdated: number | string;
  weekIndex: number;
  dayIndex: number;
  _status: string;
  date: string;
  toDateNumber: number;
  old_value: string | number;
  toDateString: string;
  todate: Date;
  totalBudgetCalculatedBydays: number | string;
  status?: string;
};

export type pacingsListDayWiseAPIType = {
  success: boolean;
  message: string;
  data: {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    pacingType: string;
    dayWiseSplit: {
      date: string;
      value: string;
      action: string;
      actualSpend: 0;
    }[];
  }[];
};

export type PacingType = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  pacingType: string;
  spends: number;
  status: string;
};
