import React, { useEffect, useState } from "react";
import "./index.scss";

const CheckBox: React.FC<{
  disabled?: boolean;
  value?: boolean;
  onChange?: (value: boolean, e: any) => void;
  className?: string;
  style?: any;
}> = ({ value, onChange, disabled, className, style }) => {
  const [checked, setChecked] = useState<boolean>(!!value);

  useEffect(() => {
    setChecked(!!value);
  }, [value]);

  return (
    <span className={`${className} checkbox`}>
      <input checked={checked} type="checkbox" disabled={disabled} onChange={e => {}} />
      <label
        style={style}
        onClick={(e: any) =>
          !disabled &&
          setChecked((c) => {
            onChange && onChange(!c, e);
            return !c;
          })
        }
      ></label>
    </span>
  );
};

export default CheckBox;