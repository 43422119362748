export const UnlockTargetingAI = {
  form: [
    {
      type: "text",
      label: "Brand Name",
      key: "brandName",
      initialvalue: "",
      tooltipText:
        "This refers to the name of your brand or registered company name.",
    },
    {
      type: "text",
      label: "Brand Website",
      key: "brandWebsite",
      initialvalue: "",
      tooltipText:
        "This refers to the domain where you host your products or offer your services.",
    },
    {
      type: "text",
      label: "Brand Keywords",
      key: "brandKeywords",
      initialvalue: "",
      tooltipText:
        "This refers to the primary keywords that you are targeting to reach your potential customers. Eg. If you are a cosmetic brand, your brand keywords would be 'skin care for women', 'moisturizers for oily skin', etc.",
    },
    {
      type: "text",
      label: "Other Keywords",
      key: "otherKeywords",
      initialvalue: "",
      tooltipText:
        "This refers to all the secondary keywords, including names of your competitor brands, to improve targeting within your niche.",
    },
    {
      type: "select",
      key: "industryType",
      label: "Industries",
      defaultDisableOption: "Select Industry",
      initialvalue: "",
      tooltipText:
        "This refers to the specific sector that your business belongs to. Eg: If your brand designs and sells clothes online, your industry will be 'Ecommerce'.",
      options: [],
    },
    {
      type: "select",
      key: "industrySubtype",
      label: "Sub Industry",
      defaultDisableOption: "Select Industry First",
      initialvalue: "",
      tooltipText:
        "This refers to the pre-defined sub-categories under each industry.",
      options: [],
    },
    {
      type: "select",
      label: "Recommendation Frequency",
      key: "recommendationFrequency",
      tooltipText:
        "This defines the intervals at which the system will generate targeting recommendations to reach high-converting audience segments.",
      options: [
        {
          name: "Once every week",
          value: "EVERY_WEEK",
        },
        {
          name: "Once in 2 weeks",
          value: "EVERY_TWO_WEEKS",
        },
        {
          name: "Once in 3 weeks",
          value: "EVERY_THREE_WEEKS",
        },
        {
          name: "Once in a month",
          value: "EVERY_MONTH",
        },
        {
          name: "Auto mode",
          value: "Auto",
        },
      ],
      initialvalue: "",
    },
    {
      type: "radio",
      label: "Mode of Application",
      show: true,
      dependentField: false,
      key: "modeOfApplication",
      tooltipText:
        "This allows you to choose the method of execution for the recommended actions.",
      options: [
        {
          name: "Manual",
          value: "Manual",
          defaultSelected: true,
        },
        {
          name: "Auto",
          value: "Auto",
          defaultSelected: false,
        },
      ],

      initialvalue: "",
    },
    {
      type: "radio",
      key: "audienceRange",
      show: false,
      dependentField: true,
      label: "Potential Audience Size Range",
      options: [
        {
          name: "Broad",
          value: "Broad",
          defaultSelected: true,
        },
        {
          name: "Average",
          value: "Average",
          defaultSelected: false,
        },
        {
          name: "Niche",
          value: "Niche",
          defaultSelected: false,
        },
      ],
      initialvalue: "",
    },
    {
      type: "radio",
      key: "relevanceRange",
      show: false,
      dependentField: true,
      label: "Relevance Score Range",
      options: [
        {
          name: "Low (<0.4)",
          value: "<0.4",
          defaultSelected: true,
        },
        {
          name: "Medium (0.4 - 0.7)",
          value: "0.4-0.7",
          defaultSelected: false,
        },
        {
          name: "High (>0.7)",
          value: ">0.7",
          defaultSelected: false,
        },
      ],
      initialvalue: "",
    },
  ],
  switchauto: [
    {
      type: "radio",
      key: "audienceRange",
      show: true,
      label: "Potential Audience Size Range",
      tooltipText:
        "This is an estimated size of the audience that fits your targeting criteria. It is calculated using:" +
        "<br>1. The cohort details." +
        "<br>2. The reference Ad set, for each AI Group, selected at the time of Targeting AI setup.",
      options: [
        {
          name: "Broad",
          value: "Broad",
          defaultSelected: true,
        },
        {
          name: "Average",
          value: "Average",
          defaultSelected: false,
        },
        {
          name: "Niche",
          value: "Niche",
          defaultSelected: false,
        },
      ],
      initialvalue: "",
    },
    {
      type: "radio",
      key: "relevanceRange",
      show: true,
      label: "Relevance Score Range",
      tooltipText:
        "This refers to the relevance of the audience within your selected cohort. Relevance score is calculated based on interests, buying behavior, age, gender, etc. The value is between 0 and 1 where 1 is the highest chance of conversion.",
      options: [
        {
          name: "Low (<0.4)",
          value: "<0.4",
          defaultSelected: true,
        },
        {
          name: "Medium (0.4 - 0.7)",
          value: "0.4-0.7",
          defaultSelected: false,
        },
        {
          name: "High (>0.7)",
          value: ">0.7",
          defaultSelected: false,
        },
      ],
      initialvalue: "",
    },
  ],
  addKeyword: [
    {
      type: "text",
      label: "Add Keyword",
      key: "keyword",
      initialvalue: "",
      placeholder: "Type keyword to add in cluster",
      tooltipText: "Add a new keyword.",
    },
    {
      type: "number",
      label: "Average monthly searches",
      key: "sv",
      initialvalue: "",
      placeholder: "Average monthly searches",
      tooltipText: "This refers to the average monthly searches.",
    },
    {
      type: "multiselect",
      label: "Match Type",
      key: "match_type",
      tooltipText:
        "This defines the intervals at which the system will generate targeting recommendations to reach high-converting audience segments.",
      options: [
        {
          isSelected: false,
          name: "Exact",
          value: "exact",
        },
        {
          isSelected: false,
          name: "Phrase",
          value: "phrase",
        },
        {
          isSelected: false,
          name: "Broad",
          value: "broad",
        },
      ],
      initialvalue: "",
    },
  ],
};

export const defaultTargetingFormValues: any = {
    industryType: "1",
    industrySubtype: "1",
    recommendationFrequency: "EVERY_WEEK",
    modeOfApplication: "Manual",
    audienceRange: "All",
    relevanceRange: "All",
};


export const TargetingFormValidationFields = [
  "brandName",
  "brandWebsite",
  "brandKeywords",
  "industryType",
  "industrySubtype",
  "recommendationFrequency",
  "modeOfApplication",
];

export const dateRangeOptions = [
  {
    name: "Last 1 Week",
  },
  {
    name: "Last 2 Weeks",
  },
  {
    name: "Last 3 Weeks",
  },
  {
    name: "Last 4 Weeks",
  },
];
  