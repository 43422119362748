import { Button } from "@mui/material";
import { Box, styled } from "@mui/system";

interface ToggleProps {
  open: boolean;
}
export const StyledHomeScreenBox = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
`;

export const StyledHomeScreenFilterBox = styled(Box)`
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  margin-bottom: 0.78125em;
  height: 4.5em;
  justify-content: space-between;
  align-items: center;
  padding: 0px 1.1875em;
`;

export const StyledHomeScreenBodyBox = styled(Box)`
  display: flex;
  height: 100%;
  min-height: 0;
`;

export const StyledHomeScreenBodyBoxEmpty = styled(Box)`
  display: flex;
  height: 100%;
  min-height: 0;
  background: #ffffff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  align-items: center;
`;

export const StyledHomeScreenLeftBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(28% - 1.4375em);
  height: 100%;
  margin-right: 1.4375em;
  background: #ffffff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  min-height: 0;
`;

export const StyledHomeScreenLeftScrollBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: auto;
  padding-bottom: 0.8em;
`;

export const StyledFooterBox = styled(Box)`
  background-color: white;
  position: absolute;
  bottom: 0em;
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 0.5em;
  line-height: 1.5em;
  text-align: left;
  min-height: 4.625em;
  width: 100%;
  z-index: 10;
  padding: 0.8em 1em;
  box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);
  border-radius: 0px 0px 1em 1em;
`;

export const StyledHomeScreenRightBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 72%;
  height: 100%;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  border-radius: 8px;
`;
export const StyledHomeScreenRightBoxHeader = styled(Box)`
  display: flex;
  min-width: 0;
  padding: 0.75em 1em;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.11);
  border-radius: 8px 8px 0px 0px;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
  gap: 10;
`;

export const StyledHomeScreenRightBoxHeaderInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10%;
  min-width: 0;
`;

export const StyledVariantInfoBox = styled(Box)`
  display: flex;
  background: #fafafa;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  align-items: center;
  margin-top: 0.5em;
  padding: 0.46875em 0.625em;
  align-self: flex-start;
`;

export const StyledHomeDivider = styled(Box)`
  height: 2.9em;
  width: 0px;
  border: 1px solid #eeeeee;
  margin: 0px 0.75em;
`;

export const StyledDraggableComponent = styled(Box)<ToggleProps>`
  position: absolute;
  top: 80%;
  left: 30%;
  z-index: 10;
  background-color: ${(props) => (props.open ? "#f8fbff" : "#f0f0f0")};
  height: 3em;
  width: ${(props) => (props.open ? "26.875em" : "4.69em")};
  border-radius: 4px;
  border: 0.125em solid ${(props) => (props.open ? "#f0f0f0" : "#f8fbff")};
  box-shadow: 101px 83px 52px rgba(0, 0, 0, 0.01),
    57px 46px 44px rgba(0, 0, 0, 0.05), 25px 21px 33px rgba(0, 0, 0, 0.09),
    6px 5px 18px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
`;

export const StyledToggleIcon = styled(Box)<ToggleProps>`
  height: 100%;
  width: ${(props) => (props.open ? "1.25em" : "1.875em")};
  display: flex;
  background-color: ${(props) => (props.open ? "white" : "#f0f0f0")};
  justify-content: center;
  align-items: center;
  border-radius: 4px 0px 0px 4px;
  border-right: ${(props) => (props.open ? "" : "2px solid #f8fbff")};
  svg {
    transform: ${(props) => (props.open ? "" : "rotate(180deg)")};
  }
`;

export const StyledNumberContainer = styled(Box)`
  width: 2.78625em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    background-color: #e4efff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.083em;
    height: 1.916em;
    font-size: 0.75em;
    color: #0869fb;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    line-height: 1.166em;
    border-radius: 6px;
  }
`;

export const StyledContainer = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledDragButton = styled(Box)`
  cursor: move;
  padding: 0em 0.5em;
  width: 2.1em;
  height: 50%;
  display: grid;
  grid-template-columns: auto auto;
`;
export const StyledDragComponent = styled(Box)`
  width: 0.2125em;
  height: 0.2125em;
  border-radius: 50%;
  background-color: #666666;
`;

export const StyledSelectedContent = styled(Box)`
  background-color: #e4efff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11.1521em;
  height: 2.212em;
  font-size: 0.67825em;
  color: #0869fb;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  line-height: 1.29em;
  border-radius: 6px;
`;
export const StyledVerticalDivider = styled(Box)`
  width: 1.865625em;
  hieght: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const StyledVerticalLine = styled(Box)`
  height: 2em;
  width: 1px;
  border: 1px solid #f0f0f0;
  margin-left: 0.865625em;
`;
export const StyledSVGContainer = styled(Box)`
  font-size: 1em !important;
`;

export const StyledSortButtonBox = styled(Box)`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0px 6px 6px 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  height: 1.75em;
  .up {
    transform: rotate(180deg);
    transition: transform 0.5s linear;
  }
  .down {
    transform: rotate(0deg);
    transition: transform 0.5s linear;
  }
`;

export const StyledListVariantsBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: ##f1f1f1;
  height: 100%;
  padding: 0.875em;
  gap: 0.6em;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const StyledDropdown = styled(Box)`
  position: absolute;
  top: 2em;
  z-index: 10;
  width: 8.5em;
  height: 28px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledStatusWrapper = styled(Box)`
  margin: 0.625em 0.875em 0.3125em;
  background: rgba(8, 105, 251, 0.05);
  border-radius: 0.5em;
  padding: 0.2em 0.625em;
  display: flex;
  align-items: center;
`;

export const StyledInProgressLoader = styled("img")`
  margin-right: 0.3125em;
  width: 2.5em;
`;

export const StyledCheckStatusButton = styled("button")`
  padding: 0;
  background: none;
  border: none;
  text-decoration: underline;
  color: #0869fb;
`;
