import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import BudgetConstraints from "./sections/BudgetConstraints/BudgetConstraints";
import { experimentalStyled as styled } from "@mui/material/styles";
import { BudgetPacing } from "./sections/BudgetPacing/BudgetPacing";
import { GroupType } from "../../types/GlobalTypes";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
}));

function BudgetPlanner() {
  const [isEdit, setisEdit] = useState(false);
  const { data } = useSelector(
    (state: { ParentAiGroup: any }) => state.ParentAiGroup
  );

  useEffect(() => {
    if (
      data?.selectedPlannerId &&
      data.parentAiGroups?.[data.selectedParentAiGroup]?.planners?.[
        data.selectedPlannerId
      ]?.status === "SCHEDULE"
    ) {
      setisEdit(true);
    } else {
      setisEdit(false);
    }
  }, [data]);

  return (
    <Box
      sx={{
        height: "95vh",
        background: "white",
        fontFamily: "Inter",
        borderRadius: "10px",
        pb: 1,
        "& *": {
          fontFamily: "Inter",
        },
      }}
    >
      <Box
        className="d-flex align-items-center"
        sx={{ height: "5%", py: 4, borderBottom: "1px solid #DDDDDD", pl: 3 }}
      >
        <Typography variant="h6" className="d-flex align-items-center">
          Budget Planner
          <Typography
            sx={{
              color: "#0869FB",
              fontSize: "1rem",
              fontWeight: 600,
            }}
            className="ml-2"
          >
            New
          </Typography>
        </Typography>
      </Box>
      <Grid container sx={{ height: "95%" }}>
        <Grid item xs={4} md={4} sx={{ height: "100%" }}>
          <BudgetPacing type={GroupType.parentGroup} />
        </Grid>
        <Grid item xs={8} md={8} sx={{ height: "100%", position: "relative" }}>
          <BudgetConstraints isEdit={isEdit} type={GroupType.parentGroup} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default BudgetPlanner;
