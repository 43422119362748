export const GET_ALL_BRAND_DETAILS = "GET_ALL_BRAND_DETAILS";
export const GET_BRAND_METRICS = "GET_BRAND_METRICS";
export const SET_ALL_BRAND_DETAILS = "SET_ALL_BRAND_DETAILS";
export const SET_BRAND_METRICS = "SET_BRAND_METRICS";
export const SET_BRAND_TYPE = "SET_BRAND_TYPE";
export const SET_ALL_BRAND_TYPES = "SET_ALL_BRAND_TYPES";
export const RESET_BRAND_DETAILS = "RESET_BRAND_DETAILS";
export const RESET_BRAND_METRICS = "RESET_BRAND_METRICS";
export const GET_EDIT_BRAND_DETAILS = "GET_EDIT_BRAND_DETAILS";
export const SET_EDIT_BRAND_DETAILS = "SET_EDIT_BRAND_DETAILS";
export const SET_TOTAL_BRANDS_LOADING = "SET_TOTAL_BRANDS_LOADING";

export type BrandDetails = any;

export type BrandMetrics = {
  brandId: string;
  metrics: any;
};

export type BrandType = {
  brandIds: string[];
  isLoading: boolean;
};

export type BrandTypes = {
  good: BrandType;
  alarming: BrandType;
  escalation: BrandType;
  inline: BrandType;
};

export type GetAllBrandDetails = {
  type: typeof GET_ALL_BRAND_DETAILS;
  payload: { agencyId: string,adAccountSummaryScreen?:boolean };
};

export type GetBrandMetrics = {
  type: typeof GET_BRAND_METRICS;
  payload: {
    brandIds: any;
    selectedMonth: string;
    selectedYear: string;
  };
};

export type SetAllBrandDetails = {
  type: typeof SET_ALL_BRAND_DETAILS;
  payload: BrandDetails;
};

export type SetBrandMetrics = {
  type: typeof SET_BRAND_METRICS;
  payload: BrandMetrics;
};

export type SetBrandType = {
  type: typeof SET_BRAND_TYPE;
  payload: {
    brandId: any;
    brandStatus: string;
  };
};

export type SetAllBrandTypes = {
  type: typeof SET_ALL_BRAND_TYPES;
  payload: { types: BrandTypes };
};

export type ResetBrandDetails = {
  type: typeof RESET_BRAND_DETAILS;
};

export type ResetBrandMetrics = {
  type: typeof RESET_BRAND_METRICS;
  payload: BrandMetrics;
};

export type BrandState = {
  data: {} | null;
  isLoading: boolean;
  types: BrandTypes;
  error: any;
};

export type GetEditBrandDetails = {
  type: typeof GET_EDIT_BRAND_DETAILS;
  payload: { brandId: string };
  callback: Function;
};

export type SetEditBrandDetails = {
  type: typeof SET_EDIT_BRAND_DETAILS;
  payload: { brandId: string; data: any };
  callback: Function;
};

export type SetTotalBrandsLoading = {
  type: typeof SET_TOTAL_BRANDS_LOADING;
  payload: boolean;
};
