import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from 'react-redux';
import { deleteCampaignFactors } from '../../store/actions/campaign';
import { useSelector } from 'react-redux';
import { initialState } from '../../store/types/auth';
import Tooltip from "../../ui/Tooltip/Tooltip";
import './styles.scss';


type MetricsProps = {
  setMetricData: any;
  metricsData: any[];
  metricList: any[];
  isROI: boolean;
  updateMetrics: (index: number, metric: string, metricType: string, isROI: boolean, filter?: string) => void;
  addMetric: (isROI: boolean) => void;
  sumError: boolean;
  setWeightError: (errorText: string, index: number, isROI: boolean) => void;
};

const Metrics: React.FC<MetricsProps> = ({ metricsData, metricList, isROI, updateMetrics, addMetric, sumError, setWeightError, setMetricData }) => {
  const dispatch = useDispatch();
  const authState = useSelector((state: { auth:initialState }) => state.auth);
  const metricsName = metricsData.map((m:any)=>m.metric);

  const validate = (input: any) => {
    if (!isNaN(input) && input <= 100) return true;
    else return false;
  };

  const setErrorText = (input: any) => {
    if (isNaN(input)) return "Provided Weight should be a number";
    else if (input > 100) return "Weight should not exceed 100";
    return "";
  };

  return (
    <>
      <div className="metric_container">
        {metricsData.map((metric, index) => {
          return (
            <div key={`${metric.metric}-${index}`}>
              <div className="metric_column">
                <div className="metric_type">
                  <h5>Metric</h5>
                  <Dropdown className="hoverable position-relative">
                    <Dropdown.Toggle>
                      <div className="text-dark text-left text-truncate">{metric.metric}</div>
                      <span className="dropdown_arrow">
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {metricList.filter((l:any)=>!metricsName.includes(l.label)).map((metric, i) => (
                          <Dropdown.Item className="text-truncate" key={`${metric.metric}-${index}`} eventKey={i + 1} onClick={() => {
                            updateMetrics(index, metric.label, 'metric', isROI, metric.filter)
                          }}>
                             <Tooltip className="metric-tooltip" tooltipContainer={metric.label}>
                              {metric.label}
                            </Tooltip>
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="metric_weight">
                  <h5>Weight</h5>
                  <div className="rt-input-input">
                    <input
                      type="text"
                      className="form-control form-control-md text-md"
                      value={metric.weight}
                      onChange={(e) => {
                        if (validate(e.target.value)) {
                          updateMetrics(index, e.target.value, "weight", isROI, metric.filter);
                        } else {
                          setWeightError(setErrorText(e.target.value), index, isROI);
                        }
                      }}
                    />
                  </div>
                </div>

                <div
                  className="delete-container"
                  onClick={() => {
                    let metrics = [...metricsData];
                    if (metric.id) {
                      dispatch(deleteCampaignFactors({ factorId: metric.id, user: authState?.user },
                          (response: any, error: boolean) => {
                          if (!error) {
                            setMetricData((metricData:any)=>metricData.filter((m:any)=>m.id!==metric.id))
                          } else {
                            console.log(error);
                          }
                        }
                      ));
                      
                    } else {
                      metrics.splice(index, 1);
                      setMetricData(metrics);
                    }
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.04232 7.01432L10.2852 4.77147L11.2519 5.73821L9.00907 7.98107L11.5502 10.5222L10.5227 11.5497L7.98156 9.00858L5.7387 11.2514L4.77195 10.2847L7.01481 8.04183L4.66699 5.69402L5.69451 4.6665L8.04232 7.01432Z"
                      fill="#999999"
                    />
                    <circle cx="8" cy="8" r="7.5" stroke="#C4C4C4" />
                  </svg>
                </div>
              </div>
              {metric.error && <p className="error">{`ERROR: ${metric.errorMessage}`}</p>}
            </div>
          );
        })}
        {
          !!metricList.filter((l:any)=>!metricsName.includes(l.label)).length &&
          <button className="btn-primary btn-add-plus" onClick={() => addMetric(isROI)}>
            +
          </button>
        }
        
      </div>
      {/* {sumError ? (
        <p className="error">ERROR: Please make sure the sum is 100%</p>
      ) : (
        <p className="info-urgent">Please make sure the sum is 100%</p>
      )} */}
    </>
  );
};

export default Metrics;
