import {
  SET_AI_GROUP_PLANNERS,
  SET_AI_GROUP_PLANNERS_DETAILS,
  GET_AIGROUP_PLANNER_DETAILS_FAKE,
  SET_AI_GROUP_ITEMS,
  GET_AI_GROUP_ITEMS,
  GET_AI_GROUP_ITEM,
  GET_AI_GROUP_PLANNERS,
  GET_AI_GROUP_PLANNER_DETAILS,
  CREATE_AI_GROUP_PLANNER,
  UPDATE_AI_GROUP_PLANNER,
  SetAiGroupPlanners,
  SetAiGroupItems,
  SetAiGroupPlannerDetails,
  GetAiGroupItems,
  GetAiGroupItem,
  GetAiGroupPlanners,
  GetAiGroupPlannerDetails,
  GetAiGroupPlannerDetailsFake,
  CreateAiGroupPlanner,
  UpdateAiGroupPlanner,
  DeleteAiGroupBudgetPlanner,
  DELETE_AI_GROUP_BUDGET_PLANNER,
  SetSelectedAiGroup,
  SET_SELECTED_AI_GROUP,
  GetGroupConfig,
  GET_GROUP_CONFIG,
  SET_AI_GROUP_LOADER,
  SetAiGroupLoader,
} from "../../types/BudgetPlannerAiGroup/index";

export const setAiGroupPlanners = (payload: any): SetAiGroupPlanners => ({
  type: SET_AI_GROUP_PLANNERS,
  payload: payload,
});

export const setAiGroupItems = (payload: any): SetAiGroupItems => ({
  type: SET_AI_GROUP_ITEMS,
  payload: payload,
});

export const setAiGroupPlannerDetails = (
  payload: any
): SetAiGroupPlannerDetails => ({
  type: SET_AI_GROUP_PLANNERS_DETAILS,
  payload: payload,
});

export const getGroupAiItems = (
  payload: any,
  callback: Function
): GetAiGroupItems => ({
  type: GET_AI_GROUP_ITEMS,
  payload: payload,
  callback,
});
export const getAiGroupItem = (
  payload: any,
  callback: Function
): GetAiGroupItem => ({
  type: GET_AI_GROUP_ITEM,
  payload: payload,
  callback,
});

export const deleteAiGroupBudgetPlanner = (
  payload: any,
  callback: Function
): DeleteAiGroupBudgetPlanner => ({
  type: DELETE_AI_GROUP_BUDGET_PLANNER,
  payload: payload,
  callback,
});

export const getAiGroupPlanners = (
  payload: any,
  callback: Function
): GetAiGroupPlanners => ({
  type: GET_AI_GROUP_PLANNERS,
  payload: payload,
  callback,
});
export const getAiGroupPlannerDetails = (
  payload: any,
  callback: Function
): GetAiGroupPlannerDetails => ({
  type: GET_AI_GROUP_PLANNER_DETAILS,
  payload: payload,
  callback,
});
export const getAiGroupPlannerDetailsFake = (
  payload: any,
  callback: Function
): GetAiGroupPlannerDetailsFake => ({
  type: GET_AIGROUP_PLANNER_DETAILS_FAKE,
  payload: payload,
  callback,
});
export const createAiGroupPlanner = (
  payload: any,
  callback: Function
): CreateAiGroupPlanner => ({
  type: CREATE_AI_GROUP_PLANNER,
  payload: payload,
  callback,
});
export const updateAiGroupPlanner = (
  payload: any,
  callback: Function
): UpdateAiGroupPlanner => ({
  type: UPDATE_AI_GROUP_PLANNER,
  payload: payload,
  callback,
});
export const setSelectedAiGroup = (
  payload: string | null
): SetSelectedAiGroup => ({
  type: SET_SELECTED_AI_GROUP,
  payload: payload,
});
export const getGroupConfig = (
  payload: any,
  callback: Function
): GetGroupConfig => ({
  type: GET_GROUP_CONFIG,
  payload: payload,
  callback,
});
export const setAiGroupLoader = (payload: any): SetAiGroupLoader => ({
  type: SET_AI_GROUP_LOADER,
  payload: payload,
});
