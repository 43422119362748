import * as React from "react";
import { Box, RadioGroup } from "@mui/material";
import {
  StyledFormControlLabel,
  StyledMenu,
  StyledMenuItem,
  StyledRadio,
} from "./styled";

const RadioMenu: React.FC<{
  show: any;
  onHide: any;
  menuData?: any;
  contentStyle?: any;
  handelClick?: any;
  selectedField?: any;
}> = ({
  children,
  show,
  onHide,
  menuData,
  contentStyle,
  handelClick,
  selectedField,
  ...props
}) => {
  return (
    <Box display="flex" justifyContent="center" mr="1em">
      {children}
      <StyledMenu
        id="basic-menu"
        anchorEl={show}
        open={show}
        onClose={(e: any) => {
          e.stopPropagation();
          onHide();
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        {...props}
      >
        <div style={contentStyle ? contentStyle : { width: "15.625em" }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={selectedField}
            // onChange={handelClick}
            onClick={(e) => {
              onHide();
            }}
          >
            {menuData?.map((item: any, index: any) => {
              return (
                <StyledMenuItem
                  selected={selectedField === item?.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    onHide();
                    handelClick(item?.value);
                  }}
                  sx={{
                    display: "block",
                    padding: "0",
                  }}
                >
                  <StyledFormControlLabel
                    value={item?.value}
                    sx={{
                      margin: "0",
                      display: "block",
                    }}
                    control={
                      <StyledRadio
                        size="small"
                        sx={{
                          color: "#A1A1A1",
                          "&.Mui-checked": {
                            color: "#0869FB",
                          },
                        }}
                      />
                    }
                    label={item?.label}
                  />
                </StyledMenuItem>
              );
            })}
          </RadioGroup>
        </div>
      </StyledMenu>
    </Box>
  );
};

export default RadioMenu;
