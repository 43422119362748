import { creativeAiState, SetCreativeAiAccount, SET_CREATIVE_AI_ACCOUNT } from "../types/creativeAi";

const initailState: creativeAiState = {
  creativeAiAccount: [],
};

export default function creativeAiReducer(state = initailState, action: SetCreativeAiAccount) {
  switch (action.type) {
    case SET_CREATIVE_AI_ACCOUNT: { return { ...state, creativeAiAccount: action.payload } }
    default: return state;
  }
}
