import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import HttpService from "../../../HttpService/HttpService";
import { BASE_URL } from "../../../utils/path";
import {
  GetAllMetrics,
  GetAttributionWindowType,
  GetElementAttributionWindow,
  GetElementMetrics,
  GetMetricGraphData,
  GET_ALL_METRICS,
  GET_ATTRIBUTION_WINDOW_TYPE,
  GET_ELEMENT_ATTRIBUTION_WINDOW,
  GET_ELEMENT_METRICS,
  GET_METRIC_GRAPH_DATA,
  SaveMetricElement,
  SAVE_METRIC_ELEMENT,
  SET_ALL_METRICS,
  SET_ATTRIBUTION_WINDOW,
  SET_ATTRIBUTION_WINDOW_ELEMENT_ID,
  SET_ATTRIBUTION_WINDOW_LOADER,
  SET_ATTRIBUTION_WINDOW_TYPE,
  SET_ELEMENT_METRICS,
  SET_GRAPH_UPDATE_LOADER,
  SET_IS_DEFAULT,
  SET_METRIC_LOADER,
  UpdateElementAttributionWindow,
  UPDATE_ELEMENT_ATTRIBUTION_WINDOW,
} from "../../types/ActionDebugger/ActionDebugger";

function* getAllMetrics(action: GetAllMetrics): any {
  let {
    payload: { elementId },
  } = action;

  try {
    const urlParams = `/v1/api/action-debugger/elements/${elementId}/default-metrics`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      const data = response.data.data;
      yield put({ type: SET_ALL_METRICS, payload: data });
    } else {
      console.log("got error in getAllMetrics");
    }
  } catch (error: any) {
    console.log("got error in getAllMetrics", error);
  }
}

function* getElementMetrics(action: GetElementMetrics): any {
  let {
    payload: { elementId },
  } = action;

  try {
    const urlParams = `/v1/api/action-debugger/elements/${elementId}/element-metrics`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      const data = response.data.data.metrics;
      data.map((_: any, index: number) => {
        data[index].isEmpty = true;
      });
      yield put({ type: SET_ELEMENT_METRICS, payload: data });
      yield put({
        type: SET_IS_DEFAULT,
        payload: response.data.data.isDefault,
      });
    } else {
      console.log("got error in getElementMetrics");
    }
  } catch (error: any) {
    console.log("got error in getElementMetrics", error);
  }
}

function* getMetricGraphData(action: GetMetricGraphData): any {
  let {
    payload: {
      elementId,
      start_date,
      end_date,
      adMetricIds,
      campaignIds,
      adsetIds,
      typeOfData,
      data,
      callback,
      loaderAction,
      attributionWindow,
      resultMetricId,
    },
  } = action;
  if (loaderAction) {
    yield put({ type: loaderAction, payload: true });
  }
  try {
    const urlParams = `/v1/api/action-debugger/elements/${elementId}/get-graph-data?startDate=${start_date}&endDate=${end_date}${
      adMetricIds ? "&adMetricIds=" + adMetricIds : ""
    }${
      attributionWindow
        ? "&attributionWindow=" + encodeURIComponent(attributionWindow)
        : ""
    }${
      resultMetricId
        ? "&resultMetricIds=" + encodeURIComponent(resultMetricId)
        : ""
    }`;
    const response = yield call(HttpService.post, BASE_URL, urlParams, "", {
			...(adsetIds?.length ? {adsetIds} : {}),
			...(campaignIds?.length ? {campaignIds} : {})
		});
    if (response.data.success) {
      if (adMetricIds || resultMetricId) {
        callback && callback(response.data.data.graphData);
      } else {
        const data = response.data.data.graphData ?? [];
        yield put({ type: typeOfData, payload: data });
      }
    } else {
      console.log("got error in getMetricGraphData");
      yield put({ type: SET_GRAPH_UPDATE_LOADER, payload: null });
      yield put({ type: loaderAction, payload: false });
      yield put({ type: typeOfData, payload: [] });
    }
  } catch (error: any) {
    if (adMetricIds || resultMetricId) {
      callback && callback([]);
    } else {
      console.log("got error in getMetricGraphData", error);
      yield put({ type: SET_GRAPH_UPDATE_LOADER, payload: null });
      yield put({ type: loaderAction, payload: false });
      yield put({ type: typeOfData, payload: [] });
    }
  }
}

function* saveMetricElement(action: SaveMetricElement): any {
  let {
    payload: { elementId, params, value },
    callback,
  } = action;
  try {
    const url = `/v1/api/action-debugger/elements/${elementId}/metrics`;
    const response = yield call(HttpService.post, BASE_URL, url, "", params);
    if (response.data.success) {
      yield put({ type: SET_ELEMENT_METRICS, payload: response.data.data });
      callback && callback(response.data.data, false);
    } else {
      console.log("Save Metric error");
      callback && callback(response.data, true);
    }
  } catch (error: any) {
    console.log("Save Metric Element", error);
    callback && callback(error, true);
  }
}

function* getAttributionWindow(action: GetAttributionWindowType): any {
  let {
    payload: { elementId },
  } = action;

  try {
    const urlParams = `/v1/api/element/${elementId}/action-debugger/attribution-window/default`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      const data = response.data.data;
      let newData: any = [];
      data.map((item: any) => {
        newData.push({ label: item, value: item });
      });
      yield put({ type: SET_ATTRIBUTION_WINDOW_TYPE, payload: newData });
    } else {
      console.log("got error in getAttributionWindow");
    }
  } catch (error: any) {
    console.log("got error in getAttributionWindow", error);
  }
}

function* getElementAttributionWindow(
  action: GetElementAttributionWindow
): any {
  let {
    payload: { elementId },
  } = action;

  try {
    const urlParams = `/v1/api/element/${elementId}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      yield put({
        type: SET_ATTRIBUTION_WINDOW,
        payload: response.data.data.ADAttributionWindow
          ? response.data.data.ADAttributionWindow
          : "default",
      });
      yield put({
        type: SET_ATTRIBUTION_WINDOW_ELEMENT_ID,
        payload: response.data.data.id,
      });
      yield put({ type: SET_ATTRIBUTION_WINDOW_LOADER, payload: false });
    } else {
      console.log("got error in getAttributionWindow");
      yield put({ type: SET_ATTRIBUTION_WINDOW_LOADER, payload: false });
    }
  } catch (error: any) {
    console.log("got error in getAttributionWindow", error);
    yield put({ type: SET_ATTRIBUTION_WINDOW_LOADER, payload: false });
  }
}

function* updateElementAttributionWindow(
  action: UpdateElementAttributionWindow
): any {
  let {
    payload: { elementId, payload },
  } = action;
  try {
    const urlParams = `/v1/api/element/${elementId}/action-debugger/attribution-window`;

    const response = yield call(
      HttpService.put,
      BASE_URL,
      urlParams,
      "",
      payload
    );

    if (response.data.success) {
      yield put({
        type: SET_ATTRIBUTION_WINDOW,
        payload: payload.attributionWindow,
      });
      yield put({ type: SET_ATTRIBUTION_WINDOW_LOADER, payload: false });
    } else {
      console.log("got error in updateElementAttributionWindow");
      yield put({ type: SET_ATTRIBUTION_WINDOW_LOADER, payload: false });
    }
  } catch (error: any) {
    console.log("got error in updateElementAttributionWindow", error);
    yield put({ type: SET_ATTRIBUTION_WINDOW_LOADER, payload: false });
  }
}

export function* ActionDebuggerWatcher() {
  yield takeLatest(GET_ALL_METRICS, getAllMetrics);
  yield takeLatest(GET_ELEMENT_METRICS, getElementMetrics);
  yield takeEvery(GET_METRIC_GRAPH_DATA, getMetricGraphData);
  yield takeLatest(SAVE_METRIC_ELEMENT, saveMetricElement);
  yield takeLatest(GET_ATTRIBUTION_WINDOW_TYPE, getAttributionWindow);
  yield takeLatest(GET_ELEMENT_ATTRIBUTION_WINDOW, getElementAttributionWindow);
  yield takeLatest(
    UPDATE_ELEMENT_ATTRIBUTION_WINDOW,
    updateElementAttributionWindow
  );
}
