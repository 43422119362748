export const recommendationKeyFromBackend = ["recommendation_id","concept_id","concept_type","concept_name","ad_account_id","ad_account_name","action_to_do","updated_value","previous_value","status"];
export const recommendationKeyValueMapping = {
    recommendation_id: "Recommendation ID"
    , concept_id: "Asset ID"
    , concept_level: "Asset Level (L2- Line Item, L1- Ad group)"
    , concept_name: "Asset Name"
    , ad_account_id: "Ad Account ID"
    , ad_account_name: "Ad Account Name"
    , action_to_do: "Recommended Action"
    , updated_value: "Recommended Value"
    , previous_value: "Current Value"
    , status: "Status"
}