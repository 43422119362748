import { Box, Stack, Typography, Button } from "@mui/material";
import { ReactComponent as TakeMeThere } from "../assets/TakeMeThere.svg";
import { Link } from "react-router-dom";
import { REACT_APP_BASE_URL_PIXIS2 } from "utils/path";
import { useSelector } from "react-redux";
import { AdAccountState } from "store/types/adaccount";
import { getSearchParams } from "utils/commonFunctions";

function UsersTakeMeThere() {
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  return (
    <>
      <Stack height={"80vh"} justifyContent={"center"} alignItems={"center"}>
        <Stack
          gap={"2rem"}
          height={"29.8rem"}
          width={"20rem"}
          alignItems={"center"}
        >
          <TakeMeThere width={"100%"} height={"100%"} />
          <Stack>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "1.25rem",
                fontWeight: "600",
                lineHeight: "2rem",
                letterSpacing: "-0.01875rem",
                width: "15rem",
                height: "4rem",
              }}
            >
              This feature has been moved to Pixis 2.0.
            </Typography>
            <Typography
              sx={{
                color: "#5C6674",
                textAlign: "center",
                fontSize: "0.875rem",
                fontWeight: "400",
                lineHeight: "1.375rem",
                letterSpacing: "0.00875rem",
              }}
            >
              Please redirect by clicking below
            </Typography>
          </Stack>

          <Button
            onClick={() => {
              window.open(
                `${REACT_APP_BASE_URL_PIXIS2}${"/adminpanel"}?brandId=${
                  selectedBrand?.id
                }${
                  getSearchParams("group_id")
                    ? "&aiGroupId=" + getSearchParams("group_id")
                    : ""
                }`,
                "_blank"
              );
            }}
            disableRipple
            variant="contained"
            sx={{
              minWidth: "165px",
              width: "20rem",
              height: "auto",
              padding: 1,
              borderRadius: 2,
              gap: 1,
              boxShadow: "none",
              background: "#0869FB",
              textTransform: "none",
            }}
          >
            Take me there
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

export default UsersTakeMeThere;
