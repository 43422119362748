import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import IndividualSuggestion from "../IndividualSuggestion/IndividualSuggestion";
import { ReactComponent as DeleteIcon } from "../../../../../assets/svg/DeleteBrownIcon.svg";
import { genderMapper } from "Pages/TargetingAIPlaygroundBeta/utils";

function MultiplePersona({
  handleDelete,
  handleAdd,
  ecpData,
  index,
  handleDeletePersona,
}: {
  handleDelete: any;
  handleAdd: any;
  ecpData: any;
  index: number;
  handleDeletePersona: any;
}) {
  return (
    <Box
      sx={{
        m: 2,
        background: "#FFF",
        border: "1px solid rgba(236, 238, 241, 1)",
        padding: "0.75em",
        borderRadius: "8px",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontFamily: "Inter",
              color: "#000000",
            }}
          >
            {ecpData?.icp_name}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.75em",
              lineHeight: "1.25em",
              fontFamily: "Inter",
              fontWeight: 500,
              ml: 4,
              color: "#AEB6C4",
            }}
          >
            Age{" "}
            <span
              style={{
                color: "#0869FB",
                fontWeight: 700,
              }}
            >
              {`${ecpData?.age?.age_min} - ${ecpData?.age?.age_max} yrs`}
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.75em",
              lineHeight: "1.25em",
              fontFamily: "Inter",
              fontWeight: 500,
              ml: 4,
              color: "#AEB6C4",
            }}
          >
            Gender{" "}
            <span
              style={{
                color: "#0869FB",
                fontWeight: 700,
              }}
            >
              {ecpData?.gender === null
                ? "All"
                : ecpData?.gender?.genders?.length === 1
                ? genderMapper[ecpData?.gender?.genders]
                : "-"}
            </span>
          </Typography>
          {ecpData?.isNew && (
            <Button
              variant="contained"
              sx={{
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                background: "#0869FB",
                cursor: "default",
                padding: "5px 12px",
                minWidth: "auto",
                lineHeight: "15px",
                ml: 3,
                "&:hover": {
                  boxShadow: "none",
                  background: "#0869FB",
                },
              }}
            >
              New
            </Button>
          )}
        </Box>
        <IconButton onClick={() => handleDeletePersona(index)}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box
        display={"flex"}
        sx={{
          gap: 1.5,
        }}
      >
        <IndividualSuggestion
          chipData={ecpData?.interests_keywords}
          keywordType={"interests_keywords"}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          title={"Interests"}
          index={index}
        />
        <IndividualSuggestion
          chipData={ecpData?.demographics_keywords}
          keywordType={"demographics_keywords"}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          title={"Demographic Keywords"}
          index={index}
        />
        <IndividualSuggestion
          chipData={ecpData?.behaviors_keywords}
          keywordType={"behaviors_keywords"}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          title={"Behaviour"}
          index={index}
        />
      </Box>
    </Box>
  );
}

export default MultiplePersona;
