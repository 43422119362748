import React from "react";

const InputText: React.FC<any> = (props) => {
  return (
    <input
      {...props}
      className={`form-control form-control-md text-md ${props.className}`}
    />
  );
};

export default InputText;
