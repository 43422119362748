import {
  ResetSelectedBrand,
  RESET_SELECTED_BRAND,
} from "../../types/SmbDashboard/selectedBrand";
import {
  ResetSubOptimizerMetrics,
  RESET_SUBOPTIMIZER_METRICS,
} from "../../types/SmbDashboard/selectedBrand";
import {
  GetOptimizerMetrics,
  GetAllOptimizersDetails,
  GetMultipleOptimizerMetrics,
  SetMultipleOptimizerMetrics,
  GET_OPTIMIZER_METRICS,
  GET_ALL_OPTIMIZERS_DETAILS,
  SetSubOptimizerMetrics,
  SetAllOptimizersDetails,
  SetSelectedBrand,
  SET_SUBOPTIMIZER_METRICS,
  SET_ALL_OPTIMIZERS_DETAILS,
  SET_SELECTED_BRAND,
  GET_MULTIPLE_OPTIMIZER_METRICS,
  SET_MULTIPLE_OPTIMIZER_METRICS,
} from "../../types/SmbDashboard/selectedBrand";

export const getAllOptimizersDetails = (payload: {
  brandId: any;
}): GetAllOptimizersDetails => ({
  type: GET_ALL_OPTIMIZERS_DETAILS,
  payload,
});

export const getOptimizerMetrics = (
  payload: {
    optimizerId: any;
    selectedMonth: string;
    selectedYear: any;
    brandId: any;
  },
  callback: Function
): GetOptimizerMetrics => ({
  type: GET_OPTIMIZER_METRICS,
  payload,
  callback,
});

export const getMulipleOptimizerMetrics = (payload: {
  optimizerIds: any[];
  selectedMonth: string;
  selectedYear: any;
  brandId: any;
}): GetMultipleOptimizerMetrics => ({
  type: GET_MULTIPLE_OPTIMIZER_METRICS,
  payload,
});

export const setMulipleOptimizerMetrics = (payload: {
  subOptimizerMetrics: any;
}): SetMultipleOptimizerMetrics => ({
  type: SET_MULTIPLE_OPTIMIZER_METRICS,
  payload,
});

export const setSelectedBrand = (payload: any): SetSelectedBrand => ({
  type: SET_SELECTED_BRAND,
  payload,
});
export const setAllOptimizersDetails = (
  payload: any
): SetAllOptimizersDetails => ({
  type: SET_ALL_OPTIMIZERS_DETAILS,
  payload,
});
export const setSubOptimizerMetrics = (
  payload: any
): SetSubOptimizerMetrics => ({
  type: SET_SUBOPTIMIZER_METRICS,
  payload,
});
export const resetSubOptimizerMetrics = (): ResetSubOptimizerMetrics => ({
  type: RESET_SUBOPTIMIZER_METRICS,
  payload: null,
});

export const resetSelectedBrand = (): ResetSelectedBrand => ({
  type: RESET_SELECTED_BRAND,
  payload: null,
});
