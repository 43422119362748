import React, { Fragment } from "react";
import {
  StyledInfoError,
  StyledSpeedoContent,
  StyledSpeedoLoader,
  StyledVariantTag,
} from "./styled";
import { ReactComponent as Speedometer } from "../../../../../assets/cai/svg/performance_speedo.svg";
import LoadGif from "../../../../../assets/Gif/blueCircularLoaderTransparent.gif";
import { LightTooltip } from "components/shared/LightTooltip";
import { StyledText } from "components/shared";
import { PerformanceScoreType } from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";

export default function VarinatSpedometer({
  index,
  handlePerformancePopoverOpen,
  handlePerformancePopoverClose,
  infoItem,
  isLoading,
  isBillboard,
  mediaURL,
}: {
  index: number;
  handlePerformancePopoverClose: any;
  handlePerformancePopoverOpen: any;
  infoItem?: PerformanceScoreType;
  isLoading?: boolean;
  isBillboard?: boolean;
  mediaURL?: string;
}) {
  const showMeter = () => {
    if (isLoading || (isBillboard && !mediaURL)) {
      return (
        <LightTooltip
          title="Calculating the Performance Score"
          arrow
          placement="right"
        >
          <StyledSpeedoLoader src={LoadGif} alt="Loader Gif" />
        </LightTooltip>
      );
    } else if (
      infoItem === null ||
      infoItem?.performance_score === null ||
      infoItem?.error
    ) {
      return (
        <LightTooltip
          title="Something went wrong, couldn't calculate the Performance Score!"
          arrow
          placement="right"
        >
          <StyledInfoError />
        </LightTooltip>
      );
    } else if (
      infoItem?.performance_score ||
      infoItem?.performance_score === 0
    ) {
      return (
        <StyledSpeedoContent
          onMouseEnter={handlePerformancePopoverOpen}
          onMouseLeave={handlePerformancePopoverClose}
        >
          <StyledText fontWeight={600} fontSize="0.6875em" color={"#1ABFA1"}>
            {infoItem?.performance_score === 0
              ? 0.1
              : infoItem?.performance_score?.toFixed(1)}
          </StyledText>
          <StyledText fontWeight={600} fontSize="0.453125em">
            /10
          </StyledText>
        </StyledSpeedoContent>
      );
    } else if(
      infoItem?.data?.performance_score ||
      infoItem?.data?.performance_score === 0
    ) {
      return     <StyledSpeedoContent
      onMouseEnter={handlePerformancePopoverOpen}
      onMouseLeave={handlePerformancePopoverClose}
    >
      <StyledText fontWeight={600} fontSize="0.6875em" color={"#1ABFA1"}>
        {infoItem?.data?.performance_score === 0
          ? 0.1
          : infoItem?.data?.performance_score?.toFixed(1)}
      </StyledText>
      <StyledText fontWeight={600} fontSize="0.453125em">
        /10
      </StyledText>
    </StyledSpeedoContent>;
    } else {
      return <Fragment />;
    }
  };

  return (
    <StyledVariantTag>
      <Speedometer />
      {showMeter()}
    </StyledVariantTag>
  );
}
