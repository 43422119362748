import * as React from "react";
import { Box, Checkbox as AntDCheckbox, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function Checkbox({
  options = [],
  heading,
  onChange,
  values,
}: any) {
  return (
    <Box
      className="w-100"
      sx={{ height: "32px", marginTop: "4px" }}
      key={heading}
    >
      <Typography className="font-10" sx={{ color: "#999999" }}>
        {heading}
      </Typography>
      <Box
        className=" d-flex justify-content-between"
        sx={{ height: "22px", margin: "4px auto", width: "95%" }}
      >
        {options.map((item: string) => (
          <FormControlLabel
            key={item}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "10px",
                color: "rgba(76, 163, 234, 1)",
              },
              "& .css-s4j5zr-MuiButtonBase-root-MuiCheckbox-root": {
                padding: "4px",
              },
            }}
            value={item}
            control={
              <AntDCheckbox
                size="small"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                checked={values.includes(item)}
                onChange={() => {
                  if (
                    values.length > 0 &&
                    values.includes(item)
                  ) {
                    let selectedValue = values.filter(
                      (selectedItem: any) => selectedItem !== item
                    );
                    onChange(selectedValue);
                  } else {
                    onChange([...values, item]);
                  }
                }}
              />
            }
            label={
              item[0].toLocaleUpperCase() + item.substring(1).toLowerCase()
            }
            labelPlacement="end"
          />
        ))}
      </Box>
    </Box>
  );
}
