import React from "react";
import { Box, Typography } from "@mui/material";
import HeadingClibrationsGS from "./HeadingClibrationsGS";
import { Handle, Position } from "react-flow-renderer";

function ExploitGS({ isConnectable }: any) {
  return (
    <Box
      className="node-container-common-style"
      sx={{
        position: "absolute",
        top: "30%",
        left: "3%",
        width: "18.37rem",
        height: "8.7rem",
        px: 2,
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
        id="exploit"
      />
      <Box sx={{ pt: 1 }}>
        <HeadingClibrationsGS heading="Exploit" subHeading="" />
      </Box>
      <hr style={{ margin: "0.5rem 0" }} />
      <Typography
        className="font-inter"
        sx={{
          fontWeight: 400,
          fontSize: 10,
          lineHeight: "0.75rem",
          width: "15.7rem",
        }}
      >
        <span>
          Exploit refers to the keywords recommendation by the AI, using
          historical performance.
        </span>
        <br />
        <br />
        <span>
          Calibrate the lookback period by selecting the date range to be
          considered for computation.
        </span>
      </Typography>
    </Box>
  );
}

export default ExploitGS;
