import { Box, Typography } from "@mui/material";
import { ReactComponent as NoDataIcon } from "./assets/EmptyBox.svg";
const NoData = ({ type }: { type: string }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <NoDataIcon />
        <Box
          sx={{
            height: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Box>
            <Typography
              sx={{ fontSize: "16px", fontWeight: "600", fontFamily: "Inter" }}
            >
              No data
            </Typography>
            <Typography sx={{ color: "#999999" }}>
              {type === "sync"
                ? "There is no sync data"
                : "There is no data for third party attribution platforms."}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NoData;
