import { call, takeEvery, takeLatest } from "@redux-saga/core/effects";
import httpService from "../../HttpService/HttpService";
import { BASE_URL } from "../../utils/path";
import moment from "moment";
import {
  GetAllPlatfromRecommendation,
  GetCurrencyDetails,
  GetPaginatedRecommendations,
  GET_ALL_PLATFROM_RECOMMENDATION,
  GET_CURRENCY_DETAILS,
  GET_PAGINATED_RECOMMENDATIONS,
  SetStatus,
  SET_STATUS,
  GET_SUPER_ADMIN_EMAILS,
} from "../types/actionImpact";
import { currencyUrl } from "../../utils/constants/motageAds";
import axios from "axios";

function* getAllPlatformRecommendation(
  action: GetAllPlatfromRecommendation
): any {
  let {
    payload: { groupId, dates, download },
    callback,
  } = action;
  try {
    let end_time = "23:59:59";
    const end_date = moment(dates.end).format("YYYY-MM-DD");
    const urlParams = `/v2/api/cross-platform-recommendation?ai_group=${groupId}&start_date=${moment
      .utc(moment(dates.start))
      .format("YYYY-MM-DDTHH:mm:ss")}&end_date=${moment
      .utc(moment(end_date + " " + end_time))
      .format("YYYY-MM-DDTHH:mm:ss")}&download=${download}`;
    const response = yield call(httpService.get, BASE_URL, urlParams, "");
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatformRecommendation ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getPaginatedRecommendations(
  action: GetPaginatedRecommendations
): any {
  let {
    payload: {
      elementId,
      dates,
      user,
      activeHeader,
      pageNumber,
      sort_by,
      sort_order,
      pageSize,
      isSuperAdmin,
    },
    callback,
  } = action;
  try {
    const start_date = moment(dates.start).format("YYYY-MM-DD");
    const end_date = moment(dates.end).format("YYYY-MM-DD");
    let end_time = "23:59:59";
    const start_utc = moment
      .utc(moment(start_date))
      .format("YYYY-MM-DDTHH:mm:ss")
      .toString();
    const end_utc = moment
      .utc(moment(end_date + " " + end_time))
      .format("YYYY-MM-DDTHH:mm:ss");
    /*
     status=SUCCESS,IGNORED,REJECTED,OUTLIER_REJECTED,OUTLIER_APPROVED,OUTLIER_RECOMMENDATION_DETECTED,WAITING,${isSuperAdmin?'PROCESSING,FAIL':''}`;
      Removing super admin condition so that it will be visible for all...
   */
    const urlParams = `/v2/api/recommendation/${elementId}?
		startDate=${start_utc}&endDate=${end_utc}${
      !!activeHeader
        ? `&fields=${activeHeader},id,concept_type,campaign_name,concept_name,concept_id,parent,version,message,recommended_action_details,executed_action_details,action_to_do`
        : ""
    }&page=${pageNumber}&pageSize=${
      pageSize ?? "10"
    }&sort_by=${sort_by}&sort_order=${sort_order}&status=SUCCESS,IGNORED,REJECTED,OUTLIER_REJECTED,OUTLIER_APPROVED,OUTLIER_RECOMMENDATION_DETECTED,WAITING,PROCESSING,PENDING,FAIL,WAITING_FOR_MANUAL_EXECUTION`;
    const response = yield call(httpService.get, BASE_URL, urlParams, user);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getPaginatedRecommendations ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getCurrencyDetails(action: GetCurrencyDetails): any {
  let {
    payload: { currencyName },
    callback,
  } = action;
  try {
    const urlParams = `${currencyUrl + currencyName}.json`;
    const response = yield call(axios.get, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getCurrencyDetails ~ error", error);
    callback && callback(error.response, true);
  }
}

function* updateStatus(action: SetStatus): any {
  let { callback } = action;
  try {
    const urlParams = `/v2/api/recommendation/update/status`;
    const response = yield call(
      httpService.post,
      BASE_URL,
      urlParams,
      action.payload.user,
      [...action.payload.data]
    );
    if (!response.data.error) {
      callback && callback();
    } else {
      // callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("err", error);
    // callback && callback(error.response, true);
  }
}

function* getSuperAdminEmails(action: any): any {
  let { callback } = action;
  try {
    const urlParams = `/v2/api/recommendation/super-admin/emails`;
    const response = yield call(httpService.get, BASE_URL, urlParams, "");
    if (!response.data.error) {
      callback && callback(response.data.data.emails);
    } else {
      // callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("err", error);
  }
}



export function* actionImpactWatcher() {
  yield takeLatest(
    GET_ALL_PLATFROM_RECOMMENDATION,
    getAllPlatformRecommendation
  );
  yield takeEvery(GET_CURRENCY_DETAILS, getCurrencyDetails);
  yield takeEvery(GET_PAGINATED_RECOMMENDATIONS, getPaginatedRecommendations);
  yield takeEvery(SET_STATUS, updateStatus);
  yield takeEvery(GET_SUPER_ADMIN_EMAILS, getSuperAdminEmails);
}
