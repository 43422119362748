import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CreateVariantsState } from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { Button } from "../../../../../shared";
import ViewMoreModal from "../../../SelectCohort/ViewMoreModal";
import { textTruncateMore } from "../../../utils/text.utils";
import { IconButton } from "@mui/material";
import { ReactComponent as BackArrow } from "../../../../../../assets/cai/svg/backArrow.svg";
import AdSetDetailsModal from "./AdSetDetailsModal";
import {
  StyledEditorHeaderContainer,
  StyledHeading,
  StyledHeadingContainer,
  StyledViewAdSetDetail,
} from "./styled";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ClassNames } from "@emotion/react";
export default function EditorHeader({
  variantItem,
  mixPanelCreativeStudioOriginalAdDetailsClicked,
  onCrossClick,
}: {
  variantItem: any;
  mixPanelCreativeStudioOriginalAdDetailsClicked: any;
  onCrossClick: any;
}) {
  const [showModal, setShowModal] = useState(false);
  const { selectedAd } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const [showDetailedTarget, setShowDetailedTarget] = useState(false);
  const toggleDetailedTargetModal = () => {
    setShowDetailedTarget(!showDetailedTarget);
  };
  const { adsetDetails } = useSelector(
    (state: { caiFBReducer: any }) => state.caiFBReducer
  );

  return (
    <>
      <StyledEditorHeaderContainer>
        <StyledHeadingContainer>
          <StyledHeading>
            {" "}
            <IconButton onClick={onCrossClick}>
              <BackArrow height="1.2em" width="1em" />
            </IconButton>{" "}
            Creative Studio
          </StyledHeading>
        </StyledHeadingContainer>
        <StyledViewAdSetDetail
          onClick={() => {
            setShowModal(true);
            mixPanelCreativeStudioOriginalAdDetailsClicked();
          }}
        >
          Original Ad Details
        </StyledViewAdSetDetail>
      </StyledEditorHeaderContainer>
      {showModal && (
        <AdSetDetailsModal
          show={showModal}
          toggleDetailedTargetModal={toggleDetailedTargetModal}
          onHide={() => setShowModal(false)}
        />
      )}
      {showDetailedTarget && (
        <ViewMoreModal
          selectedAdSet={adsetDetails}
          show={showDetailedTarget}
          onHide={() => toggleDetailedTargetModal()}
        />
      )}
    </>
  );
}
