import React, { useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Divider from "@mui/material/Divider";

// import CustomChip from "./Chip";
import {
  AUDIENCE_CARD_LABELS,
  VIDEO_CARD_SVGS,
} from "../../constants/youtubeIndex";
import { Months } from "../../../../utils/constants/index";
import AudienceChip from "./AudienceChip";

interface ICardProps {
  id: string;
  type: "AUDIENCE";
  aiGroupId: string;
  createdAt: string;
  updatedAt: string;
  dataType:
    | "Affinity"
    | "In Market"
    | "Life Events"
    | "In-Market Audiences"
    | "Affinity Audiences";
  score: number;
  segment_name: string;
  child_segment: string;
  parent_segment: string;
  child_cosine_score: number;
  parent_cosine_score: number;
  checked: boolean;
  onClick: Function;
}

function AudienceCard(props: ICardProps) {
  const {
    segment_name,
    parent_segment,
    score,
    updatedAt,
    dataType,
    checked,
    onClick,
  } = props;
  const checkBoxRef = useRef(null);

  const getDate = () => {
    const publishDate = new Date(updatedAt);
    return (
      publishDate.getDate() +
      " " +
      Months[publishDate.getMonth() + 1] +
      ", " +
      publishDate.getFullYear()
    );
  };

  return (
    <Box
      component="div"
      onClick={(e: any) => {
        onClick(e, checkBoxRef);
      }}
      className="font-inter"
      sx={{
        width: "19.25rem",
        height: "19rem",
        border: "0.06rem solid #CCECFE",
        boxShadow: "0 0.625rem 1.5rem rgba(35, 40, 60, 0.05)",
        borderRadius: "0.5rem",
        background: "#fff",
      }}
    >
      <Stack>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              padding: "0.5rem 0.75rem 0.75rem",
              width: "70%",
              background: "#F1F7FF",
              borderRadius: "0.5rem 0 3.28rem 0",
            }}
          >
            <Tooltip
              title={segment_name.length > 18 ? segment_name : segment_name}
            >
              <Typography
                className={`font-600 font-14 ${
                  segment_name.length > 18 ? "overflow" : ""
                }`}
                sx={{
                  color: "#0869FB",
                  minWidth: "10.1rem",
                  maxWidth: "10.1rem",
                  marginBottom: "5px",
                }}
              >
                {segment_name}
              </Typography>
            </Tooltip>
            <AudienceChip type={dataType} />
          </Box>
          <Box>
            <Checkbox
              ref={checkBoxRef}
              className="cohort-card-checkbox"
              size="small"
              checked={checked}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ padding: 1 }}
          alignItems="center"
        >
          <CalendarTodayOutlinedIcon
            fontSize="small"
            sx={{ height: "0.75rem", width: "0.75rem" }}
          />
          <Typography className="font-10 font-400">
            Recommended on&nbsp;
            {getDate()}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
          <Box>
            <Stack
              spacing={1.2}
              divider={
                <Divider
                  sx={{
                    border: "0.063rem solid #EFEFEF",
                    marginTop: "0.625rem",
                  }}
                />
              }
            >
              {[score.toFixed(2), parent_segment].map((metric, index) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  key={index}
                >
                  <Box
                    sx={{
                      padding: "0.25rem 0.5rem",
                      background: "#F5FFF7",
                      borderRadius: "0.21rem",
                    }}
                  >
                    {VIDEO_CARD_SVGS[index]}
                  </Box>
                  <Stack sx={{ margin: "0px 0px 0px 8px" }}>
                    <Typography
                      sx={{ color: "#333" }}
                      className="font-10 font-400"
                    >
                      {AUDIENCE_CARD_LABELS[index]}
                    </Typography>
                    <Typography
                      title={metric}
                      sx={{ color: "#00261C" }}
                      className="font-14 font-500"
                    >
                      {metric ?? "-"}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default AudienceCard;
