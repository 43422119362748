import { Box, Checkbox, Typography } from "@mui/material";
import { ReactComponent as BackIcon } from "assets/svg/backIcon.svg";

import { ReactComponent as DropDownArrow } from "assets/svg/dropdownarrow.svg";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSelectedOptimizer } from "store/actions/SmbDashboard/common";
import styled from "styled-components";
import { Months, PacingType } from "../Types";
import MonthPicker from "./MonthPicker/MonthPicker";

const StyledStatus = styled.span<{ status: string }>`
  color: ${(props) =>
    props.status === "ACTIVE"
      ? "#34A853"
      : props.status === "COMPLETED"
      ? "#DDDDDD"
      : props.status === "UNCONFIGURED" || props.status === "ABORTED"
      ? "#EF5854"
      : "#FBBC04"};
  font-size: 0.5625em;
  margin-right: 1em;
`;

export const BudgetPlannerHeader: React.FC<{
  month: number;
  year: number;
  pacinglist: PacingType[];
  selectedPacing: PacingType | null;
  setSelectedPacing: Function;
  toNextMonth: () => void;
  toPrevMonth: () => void;
  showWarningModal: Function;
  selectedDates: Function;
  selectedDatesFiltered: any;
  setMonth: Function;
  setYear: Function;
  onSelectMonth: (month: number, year: number) => void;
  modal: any;
  showWarningModalOnPacingChange: (pacing: any) => void;
  showWarningModalOnMonthChange: (
    month: number,
    year: number,
    funtion?: any
  ) => void;
  isCalendarUpdated: any;
}> = ({
  pacinglist,
  selectedPacing,
  setSelectedPacing,
  month,
  year,
  toNextMonth,
  toPrevMonth,
  showWarningModal,
  selectedDates,
  selectedDatesFiltered,
  setMonth,
  setYear,
  onSelectMonth,
  modal,
  showWarningModalOnPacingChange,
  showWarningModalOnMonthChange,
  isCalendarUpdated,
}): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const brandId = params.get("brand_id");
  const groupId = params.get("group_id");

  return (
    <Box className="flex-center justify-content-between mt-2">
      <Box className="m-3 flex-center align-items-center justify-content-between">
        <BackIcon
          onClick={() => {
            if (isCalendarUpdated && selectedDatesFiltered.length > 0) {
              showWarningModal(true);
            } else {
              dispatch(
                setSelectedOptimizer({
                  groupId,
                  brandId,
                  openBudgetPlanner: true,
                })
              );
              history.push("/group-settings" + window.location.search);
            }
          }}
          className="mr-3"
          style={{
            cursor: "pointer",
          }}
        />
        <Typography
          sx={{
            lineHeight: "2.125em",
            fontWeight: "600",
            color: "#000",
          }}
          variant={"h6"}
        >
          Budget Planner
        </Typography>
        <Box className="flex-center align-items-center ml-5">
          <MonthPicker
            year={year}
            month={month}
            setYear={setYear}
            setMonth={setMonth}
            toPrevMonth={toPrevMonth}
            toNextMonth={toNextMonth}
            onSelectMonth={onSelectMonth}
            selectedDatesFiltered={selectedDatesFiltered}
            showWarningModalOnMonthChange={showWarningModalOnMonthChange}
            isCalendarUpdated={isCalendarUpdated}
          />
        </Box>
        <Box className="flex-center ml-5">
          <Typography
            sx={{
              fontSize: "1.2em",
              lineHeight: "1.625em",
              fontWeight: "700",
              color: "#000",
            }}
          >
            Pacing
          </Typography>
          <Dropdown className="hoverable position-relative navigation-dropdown-btn ml-3">
            <Dropdown.Toggle>
              <div className="text-dark text-left text-truncate">
                {selectedPacing?.name || ` No Pacings Found`}
              </div>
              <span className="dropdown_arrow">
                <DropDownArrow />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                width: "auto",
              }}
            >
              {pacinglist.map((pacing: PacingType) => {
                return (
                  <Dropdown.Item
                    className="flex-center"
                    onClick={() => {
                      if (
                        selectedDatesFiltered.length > 0 &&
                        isCalendarUpdated
                      ) {
                        showWarningModalOnPacingChange(pacing);
                      } else {
                        setSelectedPacing(pacing);
                      }
                    }}
                  >
                    <Checkbox checked={pacing.id === selectedPacing?.id} />
                    <Box>
                      <div>
                        <StyledStatus status={pacing.status}>
                          &#11044;
                        </StyledStatus>{" "}
                        {pacing.name}
                      </div>
                      <div>
                        {moment(pacing.startDate).format("DD MMM, YYYY")} -{" "}
                        {moment(pacing.endDate).format("DD MMM, YYYY")}
                      </div>
                    </Box>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Box>
      </Box>

      {selectedDatesFiltered.length > 0 && isCalendarUpdated && (
        <Box
          className="d-flex align-items-center justify-content-center mr-4"
          onClick={() => showWarningModal(false)}
          sx={{
            width: "9rem",
            height: "3rem",
            border: "1px solid #EE5353",
            borderRadius: "0.5rem",
            color: "#EE5353",
          }}
        >
          <Typography>Discard Changes</Typography>
        </Box>
      )}
    </Box>
  );
};
