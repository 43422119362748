import React, { useCallback } from "react";
import "./index.scss";

const SelectableCards: React.FC<{
  content: any;
  cardStyle: any;
  handelOnChange: (value: any) => void;
  activeValue: any;
}> = ({ content, cardStyle, handelOnChange, activeValue }) => {
  const handleChange = useCallback(
    (value: any) => {
      handelOnChange(value);
    },
    [handelOnChange]
  );
  return (
    <div className="selectable-card-container">
      <div className="row">
        {content &&
          content?.length !== 0 &&
          content?.map((item: any, index: any) => {
            return (
              <div
                // className="col-lg-3 selectable-card-content mr-2 mb-2"
                className={
                  activeValue == item?.name
                    ? "col-lg-3 selectable-card-content-selected mr-2 mb-2"
                    : "col-lg-3 selectable-card-content mr-2 mb-2"
                }
                style={cardStyle ? cardStyle : {}}
                onClick={() => handleChange(item?.name)}
                key={index}
              >
                <div className="selectable-card-content-div">
                  {item?.cardIcon}
                  <p
                    // className="selectable-card-content-para"
                    className={
                      activeValue == item?.name
                        ? "selectable-card-content-para-selected mt-2 mb-0"
                        : "selectable-card-content-para mt-2 mb-0"
                    }
                  >
                    {item?.name}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SelectableCards;
