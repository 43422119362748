import {
  BlenderInfoPayloadType,
  RunTestPostResponseType,
  TestMediaStudioPost,
  TestMediaStudioPostResponse,
  CREATE_TEST_MEDIA_STUDIO,
  CREATE_TEST_MEDIA_STUDIO_RESPONSE,
  GetTestMediaStudioProcessing,
  GET_TEST_MEDIA_STUDIO_PROCESSING,
  SetTestMediaStudioTimer,
  SET_TEST_MEDIA_STUDIO_TIMER,
  SetErrorPresignedUrl,
  SET_ERROR_PRESIGINED_URL,
} from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";

export const testMediaStudioPost = (
  payload: BlenderInfoPayloadType[]
): TestMediaStudioPost => ({
  type: CREATE_TEST_MEDIA_STUDIO,
  payload,
});

export const testMediaStudioPostResponse = (
  payload: RunTestPostResponseType
): TestMediaStudioPostResponse => ({
  type: CREATE_TEST_MEDIA_STUDIO_RESPONSE,
  payload,
});

export const getTestMediaStudioProcessing = (
  payload: string[],
  callback: any
): GetTestMediaStudioProcessing => ({
  type: GET_TEST_MEDIA_STUDIO_PROCESSING,
  payload,
  callback,
});

export const setTestMediaStudioTimer = (
  payload: any
): SetTestMediaStudioTimer => ({
  type: SET_TEST_MEDIA_STUDIO_TIMER,
  payload,
});

export const setErrorPresignedUrl = (
  payload: any
): SetErrorPresignedUrl => ({
  type: SET_ERROR_PRESIGINED_URL,
  payload,
});