import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import IconButton from "@mui/material/IconButton";
import ButtonComponent from "./ButtonComponent";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: 24,
  borderRadius: "8px",
};

const BasicModal: React.FC<{
  title: string;
  children: any;
  open: boolean;
  setOpen: any;
  showButton?: boolean;
  height?: any;
  width?: any;
  onSubmit?: Function;
  disabledButton?: boolean;
  titleHeight?: any;
  footerHeight?: any;
  maxHeight?: any;
  headerSpacing?: any;
  bodySpacing?: any;
  hideCloseButton?: boolean;
  loading?: boolean;
}> = ({
  title,
  children,
  showButton = true,
  open,
  setOpen,
  height,
  width,
  onSubmit,
  disabledButton,
  titleHeight,
  footerHeight,
  maxHeight,
  headerSpacing = { px: 2 },
  bodySpacing = { px: 4, mt: 2, pb: 2 },
  hideCloseButton,
}) => {
  const handleClose = () => setOpen(false);
  return (
    <Box>
      <Modal open={open}>
        <Stack
          sx={{
            ...style,
            width: width || 600,
            height: height || 700,
            maxHeight: maxHeight,
          }}
        >
          <Box sx={{ height: titleHeight || "15%" }}>
            <Typography variant="h6" component="h2" sx={{ height: "100%" }}>
              <Box
                className="d-flex justify-content-between align-items-center"
                sx={{
                  height: "100%",
                  fontWeight: "700",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                  borderRadius: "8px 8px 0px 0px",
                  ...headerSpacing,
                }}
              >
                <span
                  style={{
                    fontFamily: "Lato",
                    fontSize: "20px",
                  }}
                >
                  {title}
                </span>
                {hideCloseButton ? (
                  ""
                ) : (
                  <IconButton
                    sx={{
                      background: "#F2F2F2",
                      borderRadius: "0.25rem",
                      textAlign: "center",
                    }}
                    color="primary"
                    aria-label="Close"
                    component="label"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Box>
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: "scroll",
            }}
          >
            <Typography
              sx={{
                height: "100%",
                ...bodySpacing,
              }}
              component="div"
            >
              {children}
            </Typography>
          </Box>

          {showButton && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                height: footerHeight || "15%",
                p: 4,
                pr: 2,
                m: 0,
                mt: "auto",
                borderTop: "1px solid #F0F0F0",
              }}
            >
              <ButtonComponent
                variant="contained"
                label="Submit"
                sx={{ px: 3, py: 1, "& span": { fontSize: "16px" } }}
                onClick={onSubmit as any}
                disabled={!!disabledButton}
              />
            </Box>
          )}
        </Stack>
      </Modal>
    </Box>
  );
};

export default BasicModal;
