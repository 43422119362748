import { Box } from "@mui/system";
import ComparingMetricsText from "../ComparingMetricsText/ComparingMetricsText";
import {
  StyledPerformanceMetricText,
  StyledPerformanceSummaryItemCard,
  StyledPerformanceSummaryText,
} from "./styled";
import { CompareDataType } from "../../shared/sharedTypes";
import TooltipActionDebugger from "../../shared/TooltipActionDebugger/TooltipActionDebugger";
import { ActionDebuggerInitStateType } from "../../../../store/types/ActionDebugger/ActionDebugger";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

interface PerformanceSummaryCardProps {
  summaryText: string;
  value: any;
  comparingData?: CompareDataType;
  hideTooltip?: boolean;
}

export default function PerformanceSummaryCard({
  summaryText,
  value,
  comparingData,
  hideTooltip = false,
}: PerformanceSummaryCardProps) {
  const { currentPeriod } = useSelector(
    (state: { ActionDebuggerReducer: ActionDebuggerInitStateType }) =>
      state.ActionDebuggerReducer
  );
  return (
    <StyledPerformanceSummaryItemCard>
      <Box width="100%">
        <Tooltip
          title={summaryText?.length > 16 ? summaryText.toUpperCase() : ""}
          arrow
          placement="top"
        >
          <StyledPerformanceSummaryText>
            {summaryText}
          </StyledPerformanceSummaryText>
        </Tooltip>

        <Box display="flex" pt=".5em" alignItems="center">
          <StyledPerformanceMetricText>{value}</StyledPerformanceMetricText>
          {comparingData && (
            <Box pl="0.425em">
              <ComparingMetricsText
                comparingData={comparingData}
                isPerformance={true}
              />
            </Box>
          )}
          {!hideTooltip && (
            <TooltipActionDebugger
              title={summaryText}
              comparingData={comparingData}
              style={{
                marginLeft: ".3em",
              }}
              currentPeriod={currentPeriod}
              currentRecord={value}
            />
          )}
        </Box>
      </Box>
    </StyledPerformanceSummaryItemCard>
  );
}
