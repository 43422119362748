import { getLinkClicksRange, getAllPlatformLinkClicksRange, getTargetingLinkClicksRange } from "../../utils/commonFunctions";
import { SetLinkClicks, SET_LINK_CLICKS, SetAllPlatformspinner, SetAllPlatformRecommendation, SET_ALL_PLATFORM_SPINNER, SET_ALL_PLATFORM_RECOMMENDATION, SET_ALL_PLATFORM_LINK_CLICKS, SetAllPlatformLinkClicks, SetTargetingLinkClicks, SET_TARGETING_LINK_CLICKS, SetAllPlatformGraphSpinner, SetAllPlatformPieSpinner, SetAllPlatformChartData, SetAllPlatformChartOption, SetAllPlatformChartCordsData, SET_ALL_PLATFORM_GRAPH_SPINNER, SET_ALL_PLATFORM_PIE_SPINNER, SET_ALL_PLATFORM_CHART_DATA, SET_ALL_PLATFORM_CHART_OPTION, SET_ALL_PLATFORM_CHART_CORDS_DATA, SET_ALL_PLATFORM_ADACCOUNT_DETAILS, SetAllPlatformAdaccountDetails, SET_CURRENCY_IDENTIFIER, SetCurrencyIdentifier } from "../types/actionImpact";

const initialState = {
  linkClicks: getLinkClicksRange(),
  allPlatformLinkClicks: getAllPlatformLinkClicksRange(),
  targetingLinkClicks: getTargetingLinkClicksRange(),
  allPlatformspinner: false,
  allPlatformRecommendation: null,
  allPlatformGraphSpinner: false,
  pieSpinner: false,
  allPlatformChartData: {},
  allPlatformChartOption: [],
  allPlatformChartCordsData: {},
  allPlatformAdaccountDetails: [],
  currencyIdentifier: [],
};

const actionReducer = (state = initialState, action: SetLinkClicks | SetAllPlatformspinner
  | SetAllPlatformRecommendation | SetAllPlatformLinkClicks | SetTargetingLinkClicks
  | SetAllPlatformGraphSpinner | SetAllPlatformPieSpinner
  | SetAllPlatformChartData | SetAllPlatformChartOption | SetAllPlatformChartCordsData | SetAllPlatformAdaccountDetails | SetCurrencyIdentifier
) => {
  switch (action.type) {
    case SET_ALL_PLATFORM_LINK_CLICKS: { return { ...state, allPlatformLinkClicks: action.payload } }
    case SET_TARGETING_LINK_CLICKS: { return { ...state, targetingLinkClicks: action.payload } }
    case SET_ALL_PLATFORM_SPINNER: { return { ...state, allPlatformspinner: action.payload } }
    case SET_ALL_PLATFORM_RECOMMENDATION: { return { ...state, allPlatformRecommendation: action.payload } }
    case SET_ALL_PLATFORM_GRAPH_SPINNER: { return { ...state, allPlatformGraphSpinner: action.payload } }
    case SET_ALL_PLATFORM_PIE_SPINNER: { return { ...state, pieSpinner: action.payload } }
    case SET_ALL_PLATFORM_CHART_DATA: { return { ...state, allPlatformChartData: action.payload } }
    case SET_ALL_PLATFORM_CHART_OPTION: { return { ...state, allPlatformChartOption: action.payload } }
    case SET_ALL_PLATFORM_CHART_CORDS_DATA: { return { ...state, allPlatformChartCordsData: action.payload } }
    case SET_ALL_PLATFORM_ADACCOUNT_DETAILS: { return { ...state, allPlatformAdaccountDetails: action.payload } }
    case SET_CURRENCY_IDENTIFIER: { return { ...state, currencyIdentifier: action.payload } }
    case SET_LINK_CLICKS: {
      return {
        ...state,
        linkClicks: action.payload
      }
    }
    default:
      return state;
  }
};

export default actionReducer;