import { Box, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HashType } from "../Editor.types";
import {
  StyledButtonDiv,
  StyledIntroText,
  StyledMultiOptionsContainer,
  StyledNewIcon,
  StyledRecommendationTitle,
  StyledShowMore,
} from "./styled";
import { ReactComponent as NewIcon } from "../../../../../../../src/assets/svg/new.svg";
import EditableMultiTextRadio from "../EditableTextRadio/EditableMultiTextRadio";
import {
  CreateVariantsState,
  ImageLayerType,
  Image_Recommendation,
  Image_Recommendation_List,
  RecommendationMetaData,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
} from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { useDispatch, useSelector } from "react-redux";
import { updateRecommendationMetadata } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { Loader } from "components/shared";

interface LayerProps {
  hash: HashType;
  value: string;
  handleChange: (
    value: string,
    type: HashType,
    valueObject?: Image_Recommendation
  ) => void;
  options: Image_Recommendation_List;
  titleText: string;
  mixPanelCreativeStudioSeeMore:any;
}
export default function LayerOptionsComponent({
  hash,
  value,
  handleChange,
  options,
  titleText,
  mixPanelCreativeStudioSeeMore
}: LayerProps) {
  const [optionToBeShown, setOptionsToBeShown] =
    useState<Image_Recommendation[]>();
  const [editingIndex, setEditingIndex] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { recommendationMetaData, recommendationSelectionState, createSpec } =
    useSelector(
      (state: { createVariantReducer: CreateVariantsState }) =>
        state.createVariantReducer
    );
  useEffect(() => {
    const updatedOptions = options?.data?.slice(0, options?.noOfElementToShow);
    setOptionsToBeShown(updatedOptions);
    setIsDeleteDisabled(!(updatedOptions?.length > 1));
  }, [recommendationMetaData]);

  const showMoreData = (hash: HashType) => {
    const localRecommendationData: RecommendationMetaData = {
      ...recommendationMetaData,
      isEditing: true,
      isDeleting: false,
      isVideoImageEdit: true,
    };
    const ref = document.getElementById(
      hash + (localRecommendationData[hash].noOfElementToShow - 1)
    );
    if (
      localRecommendationData[hash].noOfElementToShow + 4 <
      localRecommendationData[hash].data.length
    ) {
      ref!;
      setTimeout(function () {
        ref!.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
      localRecommendationData[hash].noOfElementToShow += 4;
    } else {
      setTimeout(function () {
        ref!.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);

      localRecommendationData[hash].noOfElementToShow =
        localRecommendationData[hash].data.length;
      localRecommendationData[hash].showMore = false;
    }
    setOptionsToBeShown(
      localRecommendationData["image"]?.data.slice(
        0,
        localRecommendationData["image"]?.noOfElementToShow
      )
    );
    dispatch(
      updateRecommendationMetadata({
        data: localRecommendationData,
      })
    );
  };
  const onDelete = (index: number, isSelected: boolean) => {
    const localData = recommendationMetaData[hash].data.filter(
      (item: string) => item !== recommendationMetaData[hash].data[index]
    );
    if (localData.length <= 1) {
      setIsDeleteDisabled(true);
    }

    dispatch(
      updateRecommendationMetadata({
        data: {
          ...recommendationMetaData,
          [hash]: {
            ...recommendationMetaData[hash],
            data: localData,
            showMore:
              localData.length > recommendationMetaData[hash].noOfElementToShow,
          },
          isDeleting: true,
          isEditing: true,
          isVideoImageEdit: true,
        },
      })
    );

    if (isSelected) {
      if (localData[index]) {
        handleChange(localData[index].id, hash, localData[index]);
      } else {
        handleChange(localData[index - 1].id, hash, localData[index]);
      }
    } else {
      dispatch({
        type: UPDATE_RECOMMENDATION_SELECTION_STATE,
        payload: {
          isChanged: true,
        },
      });
    }
  };

  const onSave = (
    index: number,
    value: Image_Recommendation,
    isSelected: boolean
  ) => {
    const localRecommendationData = {
      ...recommendationMetaData,
      isEditing: true,
      isVideoImageEdit: true,
    };
    localRecommendationData[hash].data[index] = value;
    dispatch(
      updateRecommendationMetadata({
        data: localRecommendationData,
      })
    );
    if (isSelected) {
      handleChange(value.id, hash, value);
    } else {
      dispatch({
        type: UPDATE_RECOMMENDATION_SELECTION_STATE,
        payload: {
          isChanged: true,
        },
      });
    }
  };
  const onEdit = (index: number) => {
    if (index >= 0) {
      setEditingIndex(index);
    } else {
      setEditingIndex(-1);
    }
  };
  const displayNewTag = function (l: number, i: number) {
    let options = Array.from({ length: l }, (_, i) => i + 1)
      .filter((i) => i % 4 == 0)
      .reverse();
    if (i == options[0] - 4 && i != 0)
      return (
        <StyledNewIcon>
          <NewIcon />
        </StyledNewIcon>
      );
    else if (options.length == 1 && i == options[0])
      return (
        <StyledNewIcon>
          <NewIcon />
        </StyledNewIcon>
      );
    else return null;
  };
  return (
    <StyledMultiOptionsContainer id={hash} key={hash}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e) => {
          setIsLoading(true);
          const localValue = (e.target as HTMLInputElement).value;
          const ValueObject = optionToBeShown?.find(
            (item) => item.id === localValue
          );
          handleChange(localValue, hash, ValueObject);
        }}
        className="p-0 m-0"
      >
        <StyledIntroText>{titleText}</StyledIntroText>
        {optionToBeShown && optionToBeShown.length ? (
          optionToBeShown.map((option, index) => (
            <>
              {displayNewTag(optionToBeShown.length, index)}
              <EditableMultiTextRadio
                value={option}
                radioValue={option.id}
                isSelected={option.id === value}
                key={index}
                hash={hash}
                index={index}
                onSave={onSave}
                onEdit={onEdit}
                disabled={editingIndex != -1 && index != editingIndex}
                isLoading={isLoading}
                isDeleteDisabled={isDeleteDisabled}
                onDelete={onDelete}
                //   setDirty={setDirty}
              />
            </>
          ))
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            color="red"
            sx={{
              paddingTop: "25px",
            }}
          >
            <Loader />
            {/* Sorry there is no recommendation to be shown */}
          </Box>
        )}

        <StyledButtonDiv>
          {optionToBeShown && optionToBeShown.length > 0 && options.showMore && (
            <StyledShowMore
              onClick={() => {
                showMoreData(hash);
                mixPanelCreativeStudioSeeMore()
                // setMoreData(true);
              }}
            >
              Show More
            </StyledShowMore>
            // <Button
            //   buttonVariant="underline"
            //   onClick={() => showMoreData(hash)}
            // >
            //   Show More
            // </Button>
          )}
        </StyledButtonDiv>
      </RadioGroup>
    </StyledMultiOptionsContainer>
  );
}
