import { mixPanelAdEvent } from "utils/mixpanel";

export const tobeFixedNumber = (n: string | number) => {
  if (typeof n === "number") {
    if (n === 0) return n;
    let numString = n.toString();
    if (numString.includes(".")) {
      let splitData = numString.split(".");
      return parseFloat(splitData[0] + "." + splitData[1].substring(0, 2));
    }
  }
  return n;
};

export const findMinMax = (
  valueToCompare: number,
  metricToCompare: { min: number; max: number; selected: number[] }
) => {
  if (valueToCompare < metricToCompare.min) {
    metricToCompare.min = valueToCompare;
    metricToCompare.selected[0] = valueToCompare;
  }
  if (valueToCompare > metricToCompare.max) {
    metricToCompare.max = valueToCompare;
    metricToCompare.selected[1] = valueToCompare;
  }
};

export const findTopPercentage = (
  valueToCompare: number,
  metricToCompare: {
    min: number;
    max: number;
    selected: number[];
  },
  percentValue: number
) => {
  const top = 100 - percentValue;

  const percentage =
    ((valueToCompare - metricToCompare.min) * 100) /
    (metricToCompare.max - metricToCompare.min);

  return top >= percentage;
};

export const convertRange =
  (r1: [number, number], r2: [number, number]) => (value: number) => {
    return Math.round(
      ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0]
    );
  };

export const TAIGSearchMixPanel = (
  title: string,
  brandName: string,
  agencyName: string,
  params?: any
) => {
  mixPanelAdEvent(title, {
    module_name: "TAI",
    sub_module_name: "Playground",
    brand_name: brandName,
    agency_name: agencyName,
    ...params,
  });
};

export const tooLongDecimal = (num: number) => {
  const strNum = num.toString();
  const indexOfDot = strNum.indexOf(".");
  if (indexOfDot > 0) {
    const precision = `${num >= 0 ? "+" : "-"}${parseInt(
      strNum,
      10
    )}.${strNum.substring(indexOfDot + 1, 2)}`;
    return parseFloat(precision);
  }
  return num;
};
