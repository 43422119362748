import moment from "moment";
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import {
  createAiGroupPlanner,
  deleteAiGroupBudgetPlanner,
  setAiGroupItems,
  setAiGroupPlanners,
} from "store/actions/BudgetPlannerAiGroup";
import {
  createParentAiGroupPlanner,
  deleteBudgetPlanner,
  setParentAiGroupItems,
  setParentAiGroupPlanners,
} from "store/actions/ParentAiGroup";
import { initialState } from "store/types/auth";
import { setAiGroupLoader } from "../../../../../store/actions/BudgetPlannerAiGroup";
import { setParentAiGroupLoader } from "../../../../../store/actions/ParentAiGroup";
import {
  checkDifferenceBetweenDates,
  MMMDDYYYYFormat,
} from "../../../../../utils/date.util";
import { PacingType } from "../BudgetPacing";
import DeleteModal from "../DeleteModal/DeleteModal";
import {
  StyledAddPacingBtn,
  StyledAddPacingBtnContainer,
  StyledAddPacingContainer,
  StyledDateRangeFooter,
  StyledDateRangePickerContainer,
  StyledDateRangeSave,
  StyledDeleteIcon,
  StyledPacing,
  StyledPacingDate,
  StyledPacingName,
  StyledPacingNameInput,
  StyledStatus,
  StyledAbortedText,
} from "./AddPacing.styled";
import { GroupType } from "types/GlobalTypes";
import { DateRangePicker } from "./DateRangePicker/DateRangePicker";
import WarningPopover from "./WarningPopover";
import { ReactComponent as AbortIcon } from "./../../../assets/AbortIcon.svg";
import { RolesWrapper } from "utils/rolesWrapper";
import {
  getRolesHierarchy,
  isEditorRole,
  isViewerRole,
} from "utils/commonFunctions";
import { AdAccountState } from "store/types/adaccount";

const Pacing: React.FC<{
  selectedParentAiGroup: any;
  selectedPlannerId: any;
  pacings: PacingType[];
  pacing: PacingType;
  setPacings: any;
  setDeletePacing: Function;
  setIsDelete: Function;
  groupType: GroupType;
}> = ({
  pacings,
  pacing,
  setPacings,
  selectedPlannerId,
  setDeletePacing,
  setIsDelete,
  groupType,
}) => {
  const currentPacingIndex = React.useMemo(() => {
    return pacings.findIndex((p: PacingType) => p.id === pacing.id);
  }, [pacing]);
  const dispatch = useDispatch();

  const handleDelete = (pacing: any) => {
    setDeletePacing(pacing);
    setIsDelete(true);
  };

  const setSelectedBudgetPlanner = (e: any, id: number) => {
    dispatch({
      type:
        groupType === GroupType.parentGroup
          ? "SET_SELECTED_PLANNER_ID_PARENT"
          : "SET_SELECTED_PLANNER_ID",
      payload: id,
    });
  };

  const { brandRoles } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  return (
    <StyledPacing
      sx={
        selectedPlannerId === pacing.id
          ? { border: "0.08rem solid #0869fb" }
          : { border: "0.08rem solid transparent" }
      }
      onClick={(e) => {
        setSelectedBudgetPlanner(e, pacing.id);
      }}
    >
      <div>
        {!pacing.isSaved && (
          <StyledPacingNameInput
            placeholder="Enter Pacing Name"
            value={pacing.name}
            onChange={(e) => {
              const dummyPacing = [...pacings];
              dummyPacing[currentPacingIndex].name = e.target.value;
              setPacings(dummyPacing);
            }}
            id="pacing-name-input"
          />
        )}
        {pacing.isSaved && <StyledPacingName> {pacing.name}</StyledPacingName>}
        {pacing.startDate !== undefined && pacing.endDate !== undefined && (
          <StyledPacingDate>
            {" "}
            {MMMDDYYYYFormat(pacing.startDate)} -{" "}
            {MMMDDYYYYFormat(pacing.endDate)}
          </StyledPacingDate>
        )}
        {pacing.startDate === undefined && pacing.endDate === undefined && (
          <StyledPacingDate> Select Date Range</StyledPacingDate>
        )}
      </div>
      <div className="flex-center">
        {pacing.status === "UNCONFIGURED" && <WarningPopover />}
        {pacing.status === "ABORTED" && (
          <StyledAbortedText>
            {" "}
            <AbortIcon
              style={{
                marginRight: "3px",
              }}
            />{" "}
            Pacing aborted
          </StyledAbortedText>
        )}
        {pacing.status !== "ABORTED" && (
          <StyledStatus status={pacing.status}>&#11044;</StyledStatus>
        )}
        <RolesWrapper
          show={true}
          // disabled={isViewerRole(brandRoles) || isEditorRole(brandRoles)}
          disabled={
            getRolesHierarchy(brandRoles) === "viewer" ||
            getRolesHierarchy(brandRoles) === "editor"
          }
        >
          <StyledDeleteIcon onClick={() => handleDelete(pacing)} />
        </RolesWrapper>
      </div>
    </StyledPacing>
  );
};
export const AddPacing: React.FC<{
  pacings: PacingType[];
  setPacings: any;
  handlePacing: any;
  pacingType: any;
  showType: any;
  handleTypeShow: any;
  type: GroupType.group | GroupType.parentGroup;
  blockedDates: any;
  setBlockedDates: Function;
  fetchPacings: Function;
}> = ({
  pacings,
  setPacings,
  handlePacing,
  pacingType,
  showType,
  handleTypeShow,
  type,
  blockedDates,
  setBlockedDates,
  fetchPacings,
}) => {
  const getlastPacing = React.useMemo(() => {
    return pacings[pacings.length - 1];
  }, [pacings]);
  const lastPacing = {
    ...getlastPacing,
    pacingType: pacingType.includes("PERIODIC") ? "PERIODIC" : "DAILY",
  };

  const [isDelete, setIsDelete] = React.useState(false);
  const [deletePacing, setDeletePacing] = React.useState<PacingType>();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const dispatch = useDispatch();
  const addPacingRef = useRef<null | HTMLDivElement>(null);
  const [currentDate, setCurrentDate] = useState("");
  const { data } = useSelector(
    (state: { ParentAiGroup: any; BudgetPlannerAiGroup: any }) =>
      type === GroupType.parentGroup
        ? state.ParentAiGroup
        : state.BudgetPlannerAiGroup
  );

  const groups =
    type === GroupType.parentGroup ? data?.parentAiGroups : data?.AiGroups;
  const selectedGroup =
    type === GroupType.parentGroup
      ? data?.selectedParentAiGroup
      : data?.selectedAiGroup;
  const timeZoneOffSet = groups[selectedGroup]?.timeZoneOffSet || -18000;
  let d = new Date();
  useEffect(() => {
    d = new Date(
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset() + timeZoneOffSet / 60)
    );
    setCurrentDate(moment(d).format("YYYY/MM/DD"));
  }, [timeZoneOffSet]);

  const selectedPlannerId = data?.selectedPlannerId;
  const keyName =
    type === GroupType.parentGroup ? "parentAiGroupId" : "aiGroupId";

  const actions =
    type === GroupType.parentGroup
      ? {
          deleteAiGroupPlanner: deleteBudgetPlanner,
          createGroupPlanner: createParentAiGroupPlanner,
          setAiGroupPlanner: setParentAiGroupPlanners,
          setGroupItems: setParentAiGroupItems,
          setLoader: setParentAiGroupLoader,
        }
      : {
          deleteAiGroupPlanner: deleteAiGroupBudgetPlanner,
          createGroupPlanner: createAiGroupPlanner,
          setAiGroupPlanner: setAiGroupPlanners,
          setGroupItems: setAiGroupItems,
          setLoader: setAiGroupLoader,
        };

  const scrollToBottom = () => {
    setTimeout(function () {
      addPacingRef?.current?.scrollIntoView(false);
      document.getElementById("pacing-name-input")?.focus();
    }, 100);
  };

  useEffect(() => {
    if (showType && !lastPacing.isAdded) {
      handlePacing();
    }
    if (!showType) {
      scrollToBottom();
    }
  }, [addPacingRef, showType]);

  const onDateSelect = (item: any) => {
    const dummyPacing = [...pacings];
    dummyPacing[pacings.length - 1].startDate = item[0];
    dummyPacing[pacings.length - 1].endDate = item[1];
    setPacings(dummyPacing);
  };

  const deleteLocalPacing = () => {
    if (typeof deletePacing === "object") {
      setPacings((pacingData: PacingType[]) =>
        pacingData.filter((p) => p.id !== deletePacing.id)
      );
    }
  };

  const handlePlannerDelete = () => {
    if (typeof deletePacing === "object") {
      if (deletePacing.isAdded) {
        deleteLocalPacing();
      }
      if (deletePacing.isSaved) {
        dispatch(actions.setLoader(true));
        dispatch(
          actions.deleteAiGroupPlanner(
            {
              [keyName]: selectedGroup,
              budgetPlannerId: deletePacing.id,
            },
            (res: any, err: boolean) => {
              if (!err) {
                dispatch({
                  type:
                    type === GroupType.parentGroup
                      ? "SET_SELECTED_PLANNER_ID_PARENT"
                      : "SET_SELECTED_PLANNER_ID",
                  payload: null,
                });
                fetchPacings(selectedGroup, type);
                dispatch(actions.setLoader(false));
              } else {
                dispatch(actions.setLoader(false));
              }
            }
          )
        );
      }
    }
  };

  return (
    <>
      <StyledAddPacingContainer
        sx={!lastPacing.isAdded ? { height: "100%" } : { height: "30%" }}
      >
        <div
          id="scrolling_div"
          style={{
            overflow: "auto",
          }}
        >
          {pacings.map((p: PacingType) => {
            return (
              <Pacing
                pacings={pacings}
                pacing={p}
                setPacings={setPacings}
                selectedParentAiGroup={selectedGroup}
                selectedPlannerId={selectedPlannerId}
                setDeletePacing={setDeletePacing}
                setIsDelete={setIsDelete}
                groupType={type}
              />
            );
          })}
          <div ref={addPacingRef} id="scroll-ref" />
        </div>
        <StyledAddPacingBtnContainer>
          <StyledAddPacingBtn
            disabled={
              (type === GroupType.group &&
                groups[selectedGroup]?.optimizerMode === "NUM") ||
              lastPacing.isAdded
            }
            onClick={() => {
              handleTypeShow();
            }}
          >
            + Add pacing
          </StyledAddPacingBtn>
        </StyledAddPacingBtnContainer>
      </StyledAddPacingContainer>

      {lastPacing.isAdded && (
        <StyledDateRangePickerContainer>
          <DateRangePicker
            onSelection={(val: any) => {
              onDateSelect([moment(val[0]).toDate(), moment(val[1]).toDate()]);
            }}
            currentDate={currentDate}
            value={[
              moment(lastPacing.startDate).format("YYYY/MM/DD"),
              moment(lastPacing.endDate).format("YYYY/MM/DD"),
            ]}
            disableDates={blockedDates.map(
              (item: any) => item && moment(item).format("YYYY/MM/DD")
            )}
            disablePast={true}
            disableToday={true}
            timeZoneOffSet={timeZoneOffSet}
          />
          <StyledDateRangeFooter>
            <Tooltip
              title="Please enter the Name of the pacing "
              arrow
              placement="top"
              disableHoverListener={
                (lastPacing?.name && lastPacing.name.length > 0) || false
              }
            >
              <span>
                <StyledDateRangeSave
                  disabled={!lastPacing?.name?.length}
                  onClick={() => {
                    if (
                      checkDifferenceBetweenDates(
                        lastPacing.startDate,
                        lastPacing.endDate,
                        6
                      )
                    ) {
                      delete lastPacing.id;
                      dispatch(actions.setLoader(true));
                      let payload = lastPacing;
                      payload.startDate = moment(lastPacing.startDate).format(
                        "YYYY-MM-DD"
                      );
                      payload.endDate = moment(lastPacing.endDate).format(
                        "YYYY-MM-DD"
                      );
                      dispatch(
                        actions.createGroupPlanner(
                          {
                            payload: payload,
                            user: auth.user,
                            [keyName]: selectedGroup,
                          },
                          (res: any, err: boolean) => {
                            try {
                              if (!err) {
                                dispatch(actions.setLoader(false));
                                const dummyPacing = [...pacings];
                                dummyPacing[pacings.length - 1].isSaved = true;
                                dummyPacing[pacings.length - 1].isAdded = false;
                                setPacings(dummyPacing);
                                fetchPacings(selectedGroup, type, res.data.id);
                              } else {
                                alert(res?.data?.message);
                                dispatch(actions.setLoader(false));
                              }
                            } catch (err) {
                              console.log(err, "err!!");
                            }
                          }
                        )
                      );
                    } else {
                      alert(
                        "Minimum 7 days of range should be selected to create pacing."
                      );
                    }
                  }}
                >
                  Save
                </StyledDateRangeSave>
              </span>
            </Tooltip>
          </StyledDateRangeFooter>
        </StyledDateRangePickerContainer>
      )}

      <DeleteModal
        heading="Delete Budget Pacing "
        subheading={
          "Are you sure you want to delete Budget Pacing" +
          ` "${deletePacing?.name}" ?`
        }
        open={isDelete}
        setOpen={setIsDelete}
        onSubmit={handlePlannerDelete}
      />
    </>
  );
};
