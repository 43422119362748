import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ClusterState } from "../../../store/types/cluster";
import AdSection from "../AdSection";
import CreateAdPreview from "../CreateAdPreview";
import DrawerFooter from "../DrawerFooter";
import TargetKeyword from "../TargetKeyword";
import TargetKeywordInfo from "../TargetKeywordInfo";
import "./index.scss";

const DrawerBody: React.FC<{
  setOpen:(value: boolean) => void;
}> = ({setOpen}): JSX.Element => {
  const clusterState = useSelector((state: { cluster: ClusterState }) => state.cluster);
  const { editCluster } = clusterState;
  const [adStep, setAdStep] = React.useState<number>(0);


  useEffect(()=>{
    if(editCluster.ext_id){
      setAdStep(0);
    }
  },[editCluster]);

  return (
    <>
      {/* Drawer body */}
      <div className="createad-drawer-body">
        <div className="drawer-left-panel drawer-panel">
          {adStep === 0 ? <TargetKeyword /> : <AdSection />}
        </div>
        <div className="drawer-right-panel drawer-panel">
          {adStep === 0 ? <TargetKeywordInfo /> : <CreateAdPreview />}
        </div>
      </div>

      {/* Drawer footer */}
      <DrawerFooter setOpen={setOpen} adStep={adStep} setAdStep={setAdStep} />
    </>
  );
};

export default DrawerBody;
