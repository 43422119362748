import React from "react";
import CheckBox from "../CheckBox/CheckBox";
import "./index.scss";
import { ReactComponent as Info } from "../../assets/svg//info.svg";
import { TableHeadTypes } from "./Table.type";
import Tooltip from "../Tooltip/Tooltip";

const Table: React.FC<{
  headers: string[] | Array<TableHeadTypes>;
  allowSelection?: boolean;
  isSelected?: boolean;
  handleSelection?: () => void;
  stickyTop?: boolean;
  topPosition?: string;
}> = ({
  headers,
  handleSelection,
  children,
  allowSelection,
  isSelected,
  stickyTop,
  topPosition,
}) => {
  return (
    <table className="google-table overflow-auto">
      <thead
        className={stickyTop ? "position-sticky zindex-sticky" : ""}
        style={{ top: topPosition, zIndex: 10 }}
      >
        <tr>
          {allowSelection && (
            <th className="table-head-checkbox">
              <span className="">
                <CheckBox value={isSelected} onChange={handleSelection} />
              </span>
            </th>
          )}
          {headers.map((head: TableHeadTypes | string, index) => (
            <th key={index}>
              {typeof head === "object" ? (
                <div
                  className="table-head-tooltip "
                >
                  <span>{head.value}</span>
                  <Tooltip
                    tooltipContainer={
                      <Info
                        style={{
                          height: 16,
                          width: 16,
                          marginLeft: 12,
                        }}
                      />
                    }
                  >
                    {head.tooltipValue}
                  </Tooltip>
                </div>
              ) : (
                <span>{head}</span>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default Table;
