import { Box, styled } from "@mui/system";

export const StyledAIModalDivider = styled(Box)`
  border-bottom: 1px solid #dddddd;
  transform: rotate(-180deg);
  margin: 1em 0em;
`;

interface ConfiguredBoxProp {
  type: "configured" | "unconfigured";
}

export const StyledConfiguredBox = styled(Box)<ConfiguredBoxProp>`
  position: relative;
  border-radius: 4px;
  padding: 6px 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.625em;
  line-height: 12px;
  background-color: ${({ type }) =>
    type === "configured" ? "#fef3ea" : "#e5e5e5"};
  color: ${({ type }) => (type === "configured" ? "#f8862d" : "#999999")};
`;
