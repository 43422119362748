import CallibrationGif from "../Assets/Gif/Calibration.gif";
import KeywordTuning from "../Assets/Gif/KeywordTuning.gif";
import AudienceExplorer from "../Assets/Gif/AudienceExplorer.gif";

export const calibrationData = {
  gif: CallibrationGif,
  steps: [
    "Fetching historical performance of AI Group",
    "Extracting brand details",
    "Extracting competitor details",
    "Setting up the Calibration system",
  ],
};

export const keywordTuningData = {
  gif: KeywordTuning,
  steps: [
    "Analysing historical performance and exploring new interests",
    "Analysing Brand and Competitor specific Interests",
    "Exploring intent & traffic based interests",
    "Generating the most relevant interest keywords",
  ],
};

export const audienceExplorerData = {
  gif: AudienceExplorer,
  steps: [
    "Analysing the keywords input",
    "Expanding interest based keywords based on your setup",
    "Performing keyword matching with Meta Ad Platform",
    "Computing the quality indicators",
    "Generating relevant audience cohorts",
  ],
};

export const personaTuningData = {
  gif: KeywordTuning,
  steps: [
    "Analysing historical performance and exploring new interests",
    "Analysing Brand details",
    "Exploring relevant persona",
    "Generating persona with interest, Deomgraphics and behaviour keywords",
  ],
};
