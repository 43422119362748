import { Box, IconButton } from "@mui/material";
import { TextInput } from "../../../../shared";
import { ReactComponent as CrossIcon } from "../../../../../assets/svg/close.svg";
import { ListicleType } from "./Listicle";

interface ListicleItemProps {
  item: ListicleType;
  onCross?: (id: string) => void;
  onInputChange?: (value: string, index: number) => void;
  index: number;
}

export default function ListicleItem({
  onCross,
  item,
  onInputChange,
  index,
}: ListicleItemProps) {
  return (
    <Box display="flex" mb="4px" mt="4px" width="100%">
      <TextInput
        disableUnderline
        height="40px"
        sx={{
          width: "100%",
          fontSize: "12px",
        }}
        value={item.value}
        onChange={(e) => {
          onInputChange && onInputChange(e.target.value, index);
        }}
      />
      <IconButton
        onClick={() => {
          onCross && onCross(item.id);
        }}
        color="primary"
        aria-label="cross"
        className="ml-1"
      >
        <CrossIcon
          style={{
            height: 12,
            width: 12,
          }}
        />
      </IconButton>
    </Box>
  );
}
