import { Box, Skeleton } from "@mui/material";
import { StyledText } from "components/shared";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAd } from "store/actions/CreativeAI/shared/Review/review";
import { ReviewInitialState } from "store/types/CreativeAI/shared/Review/review";
import AdPreviewFrame from "./AdPreviewFrame";
import { StyledIframeBox, StyledRightPanelBox } from "./styled";
import { PreviewFailed } from "../CreateVariants/Editor/Preview/Preview";

export default function LeftPanel() {
  const {
    adData: { data, isLoading },
    hashId,
    requestedVariantsMetaData,
    selectedVariantRequestedId,
  } = useSelector(
    (state: { reviewReducer: ReviewInitialState }) => state.reviewReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      selectedVariantRequestedId !== undefined &&
      requestedVariantsMetaData?.data?.variants &&
      requestedVariantsMetaData?.data?.variants?.[selectedVariantRequestedId]
        ?.variantInfo &&
      hashId
    ) {
      dispatch(
        getAd({
          hashId,
          adId: requestedVariantsMetaData?.data?.variants?.[
            selectedVariantRequestedId
          ]?.variantInfo?.ad,
          adAccountId:
            requestedVariantsMetaData?.data?.variants?.[
              selectedVariantRequestedId
            ]?.variantInfo?.adAccountId,
        })
      );
    }
  }, [selectedVariantRequestedId]);

  return (
    <StyledRightPanelBox flex={2.5}>
      <StyledText
        fontFamily="Lato"
        fontSize="1.125em"
        fontWeight={700}
        lineHeight="22px"
        color="##000003"
        pb="4px"
      >
        Reference Ad
      </StyledText>
      <Box display="flex" alignItems="center">
        <StyledText
          fontSize="0.875em"
          fontWeight={600}
          lineHeight="22px"
          color="##000003"
          pr=".4em"
        >
          Ad Name:{" "}
        </StyledText>
        {isLoading ? (
          <Skeleton animation="wave" height={20} width="70%" />
        ) : (
          <StyledText
            fontSize="0.875em"
            fontWeight={400}
            lineHeight="22px"
            flex={1}
            color="##000003"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {data?.name}
          </StyledText>
        )}
      </Box>
      <StyledIframeBox>
        <StyledText fontFamily="Lato" fontSize="1em" lineHeight="19px">
          Original Ad Preview
        </StyledText>
        {isLoading ? (
          <Skeleton
            animation="wave"
            height="100%"
            variant="rectangular"
            sx={{
              bordeRadius: "3.26896px",
              marginTop: "1.5em",
            }}
          />
        ) : data?.previews?.data?.[0]?.body ? (
          <AdPreviewFrame previewLink={data?.previews?.data?.[0]?.body} />
        ) : (
          <PreviewFailed />
        )}
      </StyledIframeBox>
    </StyledRightPanelBox>
  );
}
