import DragAndDrop from "../../../../../assets/png/DragAndDrop.png";

export default function EmptyToBePublished({}: {}) {
  return (
    <div className="ad-container-body-empty">
      <img src={DragAndDrop} />
      <div className="drag-and-drop-desc">
        Drag and drop recommended headlines to this section in order to be
        published.
      </div>
    </div>
  );
}
