import { ReactComponent as Error } from "../../../../../assets/cai/svg/previewError.svg";
import {
  StyledErrorContainer,
  StyledErrorScreenBox,
  StyledErrorSVG,
  StyledMessageContainer,
} from "./styled";

function ErrorScreen() {
  return (
    <StyledErrorScreenBox>
      <StyledErrorContainer>
        <StyledErrorSVG>
          <Error height={"100%"} width={"100%"} />
        </StyledErrorSVG>
        <StyledMessageContainer>
          Something went wrong , kindly check the ad through the below button
        </StyledMessageContainer>
      </StyledErrorContainer>
    </StyledErrorScreenBox>
  );
}

export default ErrorScreen;
