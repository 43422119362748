import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActionDebuggerSwitch } from "../../../store/actions/ActionDebugger/ActionDebugger";
import { ActionDebuggerHelperInitState } from "../../../store/types/ActionDebugger/ActionDebuggerHelper";
import { isActionDebuggerOn } from "../shared/utils/common.utils";
import { mixPanelAdEvent } from "utils/mixpanel";
import { AdAccountState } from "store/types/adaccount";
import { useAuth0 } from "@auth0/auth0-react";
import { getPlatformName } from "utils/commonFunctions";

const ActionDebuggerCard: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedAdAccount, selectedAIGroup } = useSelector(
    (state: { ActionDebuggerHelperReducer: ActionDebuggerHelperInitState }) =>
      state.ActionDebuggerHelperReducer
  );
  const { selectedBrand, selectedPlatform } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { user } = useAuth0();
  //All Selectors;
  const { switchStatus } = useSelector((state: any) => ({
    switchStatus: state.ActionDebuggerReducer.actionDebuggerSwitch,
  }));

  const handelChangeSwitch = () => {
    mixPanelAdEvent("View Recommendations Clicked", {
      username: user?.name,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      ad_account_id: selectedAdAccount?.selectedAdAcc?.id,
      ad_account_name: selectedAdAccount?.selectedAdAcc?.name,
      ai_group_name: selectedAIGroup?.name,
      platform_name: getPlatformName(selectedPlatform?.name || ""),
    });
    dispatch(setActionDebuggerSwitch(!switchStatus));
  };

  // useEffect(() => {
  //   if (selectedAdAccount && selectedAdAccount?.platform?.name) {
  //     const flag = isActionDebuggerOn(
  //       (selectedAdAccount.platform.name as string)?.toLowerCase()
  //     );
  //     if (flag) {
  //       dispatch(setActionDebuggerSwitch(false));
  //     }
  //   }
  // }, [selectedAdAccount]);

  return (
    <div>
      {!switchStatus && (
        <button className="btn primary" onClick={() => handelChangeSwitch()}>
          View Recommendation
        </button>
      )}
    </div>
  );
};

export default ActionDebuggerCard;
