import { Box } from "@mui/material";
import { StyledText } from "components/shared";
import React from "react";

interface AdSetDetailItemProps {
  svg: any;
  title: string;
  subTitle: string;
  color: string;
}

export default function AdSetDetailItem({
  svg,
  title,
  subTitle,
  color,
}: AdSetDetailItemProps) {
  return (
    <Box display="flex" alignItems="center" flex={1}>
      {svg}
      <Box display="flex" flexDirection="column" pl=".35em">
        <StyledText
          color={color}
          fontWeight={400}
          fontSize="0.625em"
          lineHeight="1.2em"
          pb=".5em"
        >
          {title}
        </StyledText>
        <StyledText
          color="#00261C"
          fontWeight={500}
          fontSize="0.75em"
          lineHeight="1.25em"
        >
          {subTitle}
        </StyledText>
      </Box>
    </Box>
  );
}
