import { Box, CircularProgress } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChangedFieldList } from "../../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { getBusinessUintById } from "../../../../../../store/actions/CreativeAI/Facebook/Main/Main";
import { SelectFormControl, InputFormControl } from "../../../../../shared";
import GenerationTypeComponent from "../../../shared/GenerationTypeComponent/GenerationTypeComponent";
import { APIGenerationType } from "../../../shared/SharedTypes";
import { filterVariantData } from "../../../utils/filterLevers.utils";
import { getBUFormValues, getFormValues } from "../../../utils/form.utils";
import { StyledConfigFooter } from "./styled";
import ConfigureFooter, {
  ConfigureFooterProps,
} from "../EditorFooter/ConfigureFooter";
import { checkAdType } from "components/CreativeAI/Facebook/utils/common.utils";
import { AdAccountState } from "store/types/adaccount";
import { InitialMediaStudioType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
interface ConfigureFieldsProps {
  control: any;
  getValues: any;
  setValue: any;
  reset: any;
  watch: any;
  errors: any;
  setError: any;
  clearErrors: any;
  footerProps: ConfigureFooterProps;
  mixPanelCreativeStudioConfigureClicked: any;
  variantItem: any;
}

export default function ConfigureFields({
  control,
  getValues,
  setValue,
  reset,
  watch,
  errors,
  setError,
  clearErrors,
  footerProps,
  mixPanelCreativeStudioConfigureClicked,
  variantItem,
}: ConfigureFieldsProps) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const {
    LEVERS,
    CREATIVE_ELEMENT_AI,
    BUSINESS_UNIT_List,
    BUSINESS_UNIT_BY_ID_DATA,
    adAdSetFieldList,
  } = useSelector((state: any) => ({
    LEVERS: state.MainReducer.defaultLevers,
    CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi.data,
    BUSINESS_UNIT_List: state.MainReducer.AllBusinessUnitList,
    BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
    adAdSetFieldList: state.MainReducer.adAdSetFieldList,
  }));
  const data: APIGenerationType[] = useMemo(
    () => filterVariantData(LEVERS, ["user", "ad", "adset", "campaign"]),
    [LEVERS]
  );
  const { configureMetaData, changedFieldList, createSpec } = useSelector(
    (state: { createVariantReducer: any }) => state.createVariantReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { getMediaStudioData } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );
  const { selectedBrand } = adaccount;
  const businessUnitID = watch("businessUnitId");
  useEffect(() => {
    dispatch({
      type: "GET_ALL_BUSINESS_UNIT_LIST",
      payload: {
        brandId: selectedBrand.id,
      },
    });
  }, []);

  useEffect(() => {
    if (CREATIVE_ELEMENT_AI && BUSINESS_UNIT_List) {
      const data =
        configureMetaData ?? getFormValues(CREATIVE_ELEMENT_AI, true);
      reset(data);
      watch();
      setShow(true);
    }
  }, [CREATIVE_ELEMENT_AI, BUSINESS_UNIT_List]);

  const businessUnits = useMemo(
    () =>
      BUSINESS_UNIT_List.map((bu: any) => ({
        id: bu.id,
        name: bu.businessUnit,
      })),
    [BUSINESS_UNIT_List]
  );

  useEffect(() => {
    if (businessUnitID) {
      setShow(false);
      dispatch(
        getBusinessUintById({
          businessUnitId: businessUnitID,
        })
      );
    }
  }, [businessUnitID]);

  useEffect(() => {
    if (BUSINESS_UNIT_BY_ID_DATA) {
      const data =
        configureMetaData ?? getFormValues(CREATIVE_ELEMENT_AI, true);
      if (
        data?.businessUnitId !== businessUnitID &&
        businessUnitID !== undefined
      ) {
        reset(getBUFormValues(BUSINESS_UNIT_BY_ID_DATA, false, true));
        for (let item in adAdSetFieldList) {
          setValue(item, adAdSetFieldList[item]);
        }
      } else {
        reset(data);
      }
      watch();
      setShow(true);
    }
  }, [BUSINESS_UNIT_BY_ID_DATA, CREATIVE_ELEMENT_AI]);

  const configureHelperFunction = (registeredName: string) => {
    if (!changedFieldList.includes(registeredName)) {
      dispatch(setChangedFieldList([...changedFieldList, registeredName]));
    }
  };

  useEffect(() => {
    if (show) {
      let primarychecked: any = {};
      let headlinechecked: any = {};
      let descriptionchecked: any = {};
      Object.keys(getValues("primary_text")).forEach(
        (key: any) =>
          (primarychecked[key] = getValues("primary_text")?.[key]?.isChecked)
      );
      Object.keys(getValues("headline")).forEach(
        (key: any) =>
          (headlinechecked[key] = getValues("headline")?.[key]?.isChecked)
      );
      Object.keys(getValues("description")).forEach(
        (key: any) =>
          (descriptionchecked[key] = getValues("description")?.[key]?.isChecked)
      );
      mixPanelCreativeStudioConfigureClicked(
        variantItem,
        businessUnits.find(
          (item: any) => item?.id == getValues("businessUnitId")
        )?.name,
        getValues("brandName"),
        getValues("interestKeyword"),
        headlinechecked,
        descriptionchecked,
        primarychecked,
        checkAdType(createSpec) === "video_data"
          ? getValues("video")?.paraphrased_video_text?.isChecked
          : getValues("image")?.paraphrased_image_text?.isChecked,
        checkAdType(createSpec) === "video_data"
          ? "video_checked"
          : "image_checked"
      );
    }
  }, [show]);

  return (
    <Box
      width="100%"
      alignItems="start"
      display="flex"
      flexDirection="column"
      className="drawer-panel position-relative"
      style={{
        overflow: "hidden",
        flex: 1,
        minHeight: 0,
        border: "1px solid #EFEFEF",
        borderRadius: 8,
        padding: "24px",
        paddingBottom: "24px",
      }}
    >
      {show ? (
        <>
          <div className="overflow-scroll h-100 w-100">
            <Box display="flex" width="100%">
              <Box width="50%" pr="12px">
                <SelectFormControl
                  options={businessUnits}
                  field="name"
                  valueKey="id"
                  tooltipText="Business unit refers to an independent vertical of the brand. For example, if the brand is a bank, then, personal loan or credit card is a business unit."
                  placeholder="Select Business Unit"
                  required
                  fullWidth
                  control={control}
                  registeredName="businessUnitId"
                  label="Business Unit"
                  height="40px"
                  sx={{
                    fontSize: "12px",
                  }}
                  helperFunction={configureHelperFunction}
                />
              </Box>
              <Box width="50%" pl="12px">
                <InputFormControl
                  label="Display Brand Name"
                  tooltipText="Display brand name refers to how you want the brand name to be displayed in the ad copy."
                  disableUnderline
                  control={control}
                  registeredName="brandName"
                  required
                  fullWidth
                  placeholder="Enter Business Unit"
                  sx={{
                    height: "40px",
                    fontSize: "12px",
                  }}
                  helperFunction={configureHelperFunction}
                />
              </Box>
            </Box>
            <Box width="50%" pt="10px">
              <InputFormControl
                label="Interest Keyword"
                tooltipText="Interest keyword refers to the crux of the ad generations. It indicates what the ad should deliver based on the product/service category and what the ad generations should be based on."
                disableUnderline
                required
                fullWidth
                control={control}
                registeredName="interestKeyword"
                placeholder="Enter Interest Keyword"
                sx={{
                  height: "40px",
                  fontSize: "12px",
                }}
                helperFunction={configureHelperFunction}
              />
            </Box>
            {data.map((item) => {
              if (
                item.generation_type === "image" ||
                item.generation_type === "video"
              ) {
                return (item.generation_type === "image" &&
                  checkAdType(createSpec) === "link_data" &&
                  getMediaStudioData?.isData) ||
                  (item.generation_type === "video" &&
                    checkAdType(createSpec) === "video_data" &&
                    getMediaStudioData?.isData) ? (
                  <GenerationTypeComponent
                    item={item}
                    key={item.generation_type}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    helperFunction={configureHelperFunction}
                  />
                ) : (
                  <Box />
                );
              } else {
                return (
                  <GenerationTypeComponent
                    item={item}
                    key={item.generation_type}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    helperFunction={configureHelperFunction}
                  />
                );
              }
            })}
          </div>
        </>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="80%"
        >
          <CircularProgress thickness={5} />
        </Box>
      )}
    </Box>
  );
}
