import { Box, Popover } from "@mui/material";
import React, { useState } from "react";
import { StyledAddButton, StyledFilterDivider, StyledPopover } from "./styled";
import { StyledText } from "components/shared";
import { ReactComponent as Add } from "../../../../assets/cai/svg/add_black.svg";
import { ReactComponent as ArrowForward } from "../../../../assets/cai/svg/arrow_forward.svg";
import { DynamicFilterItemType } from "./types";
import NoDynamicFilterSelected from "./NoDynamicFilterSelected";
import {
  PxCheckGroup,
  PxRadioGroup,
} from "components/CreativeAI_Revamp/shared";
import { cloneDeep } from "lodash";
import { handlingCheck } from "../../Facebook/utils/common.utils";

export default function DynamicFilterPopover({
  filterState,
  setFilterState,
  mixPanelFilterAdded,
  mixPanelFilterRemoved,
}: {
  filterState: DynamicFilterItemType[];
  setFilterState: any;
  mixPanelFilterAdded: (filter_name: any, subFilterNames: any) => void;
  mixPanelFilterRemoved: (filter_name: any, subFilterNames: any) => void;
}) {
  const [clickedFilterIndex, setClickedFilterIndex] = useState<
    number | undefined
  >();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRadioChange = (item: any) => {
    if (clickedFilterIndex !== undefined) {
      const localFilterState = cloneDeep(filterState);
      localFilterState[clickedFilterIndex].selected = item;
      setFilterState(localFilterState);
      mixPanelFilterAdded(localFilterState[clickedFilterIndex].name, [
        item.desc,
      ]);
    }
  };
  const handleCheckChange = (item: any, checked: boolean) => {
    if (clickedFilterIndex !== undefined) {
      const value = item;
      const localFilterState = handlingCheck({
        value,
        filterState,
        index: clickedFilterIndex,
        checked,
      });
      if (!checked) {
        mixPanelFilterAdded(
          localFilterState[clickedFilterIndex].name,
          localFilterState[clickedFilterIndex].selected.map(
            (item: any) => item.desc
          )
        );
      } else {
        mixPanelFilterRemoved(localFilterState[clickedFilterIndex].name, [
          item.desc,
        ]);
      }
      setFilterState(localFilterState);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "dynamic-filter" : undefined;
  return (
    <Box flex={1}>
      <StyledAddButton aria-describedby={id} onClick={handleClick}>
        <Add />
        <StyledText fontSize="0.625em" lineHeight="12px" pl=".5em">
          Add
        </StyledText>
      </StyledAddButton>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: "23.9375em",
            backgroundColor: "#FFFFFF",
            border: "1px solid #EEEEEE",
            boxShadow: "0px 10px 24px rgba(35, 40, 60, 0.05)",
            borderRadius: 4,
            marginTop: ".3em",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <StyledText
          fontSize="12px"
          lineHeight="15px"
          fontWeight={600}
          sx={{
            background: "#F8F8FA",
            padding: "1em",
          }}
        >
          Filter Ads with (1)
        </StyledText>
        <Box display="flex">
          <Box display="flex" flex={1} flexDirection="column" p="1em">
            {filterState.map((item, index) => (
              <Box
                display="flex"
                p="0.2em .5em"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => setClickedFilterIndex(index)}
                key={index}
                sx={{
                  background:
                    clickedFilterIndex === index ? "#F5F8FF" : "#FFFFFF",
                  borderRadius: "3px",
                  cursor: "pointer",
                }}
              >
                <StyledText
                  fontSize="0.625em"
                  fontWeight={400}
                  lineHeight="24px"
                >
                  {item.name}
                </StyledText>
                <ArrowForward />
              </Box>
            ))}
          </Box>
          <StyledFilterDivider />
          <Box display="flex" flex={1} flexDirection="column" p="1em">
            {clickedFilterIndex !== undefined ? (
              filterState[clickedFilterIndex].type === "radio" ? (
                <PxRadioGroup
                  options={filterState[clickedFilterIndex].options}
                  onChange={handleRadioChange}
                  selected={filterState[clickedFilterIndex].selected}
                />
              ) : (
                <PxCheckGroup
                  options={filterState[clickedFilterIndex].options}
                  onChange={handleCheckChange}
                  selected={filterState[clickedFilterIndex].selected}
                />
              )
            ) : (
              <NoDynamicFilterSelected />
            )}
          </Box>
        </Box>
      </StyledPopover>
    </Box>
  );
}
