import styled from "styled-components";
import { Button } from "../Button/Button";

export const StyledMessageContainer = styled.div`
  width: 80%;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  margin-top: 1em;
`;

export const StyledActionMessageContainer = styled.div`
  width: 65%;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;P
  
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 2em;
`;

export const StyledButtonWithText = styled(Button)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;
export const StyledButtonWithOutline = styled(Button)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: solid 2px #0869fb;
`;
