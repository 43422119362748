import { useDispatch, useSelector } from "react-redux";
import { postReviewResponse } from "store/actions/CreativeAI/shared/Review/review";
import {
  CommentKey,
  ReviewInitialState,
} from "store/types/CreativeAI/shared/Review/review";

export const usePostResponseHook = () => {
  const {
    hashId,
    requestedVariantData: { data },
    selectedVariantRequestedId,
    requestedVariantsMetaData,
  } = useSelector(
    (state: { reviewReducer: ReviewInitialState }) => state.reviewReducer
  );

  const dispatch = useDispatch();

  function updatePostComments({
    commentKey,
    value,
  }: {
    commentKey: CommentKey | "reviewStatus";
    value: string;
  }) {
    if (hashId && data?.id) {
      dispatch(
        postReviewResponse({
          commentKey,
          hashId,
          variantId: data?.id,
          payload: {
            descriptionReviewComments:
              data?.variantMetaInfo?.descriptionReviewComments?.text,
            headlineReviewComments:
              data?.variantMetaInfo?.headlineReviewComments?.text,
            primaryTextReviewComments:
              data?.variantMetaInfo?.primaryTextReviewComments?.text,
            mediaReviewComments:
              data?.variantMetaInfo?.mediaReviewComments?.text,
            reviewStatus:
              selectedVariantRequestedId !== undefined &&
              requestedVariantsMetaData.data
                ? requestedVariantsMetaData.data.variants[
                    selectedVariantRequestedId
                  ].reviewStatus
                : "UNDER_REVIEW",
            [commentKey]: value,
          },
        })
      );
    }
  }
  return { updatePostComments };
};
