import { Box, styled } from "@mui/system";

export const StyledTag = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f3f3f3;
  border-radius: 4px;
  flex: 1;
  height: 100%;
  text-align: center;
  min-width: 0;
  padding: 0em 0.3em;
`;

export const StyledTagMainBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 1.2em;
  margin: 0 0.5em;
  min-width: 0;
  overflow: hidden;
`;
