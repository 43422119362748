import { Box, styled } from "@mui/system";

export const StyledErrorScreenBox = styled(Box)`
  position: relative;
  height: 244px;
  width: 100%;
  flex: 1;
  min-height: 0;
  overflow: hidden;
`;

export const StyledBlurBox = styled(Box)`
  background: rgba(197, 197, 197, 0.25);
  backdrop-filter: blur(26px);
  padding: 0px 20px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
`;
