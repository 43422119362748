import { Box } from "@mui/material";
import React from "react";
import { StyledHeading3 } from "../../../../shared";
import Levers from "../Levers/Levers";
import {
  GenerationType,
  GenerationTypeTitle,
  APIGenerationType,
} from "../SharedTypes";

interface GenerationTypeComponentProps {
  item: APIGenerationType;
  control: any;
  getValues: any;
  setValue: any;
  errors: any;
  setError: any;
  clearErrors: any;
  helperFunction?: (registeredName: string) => void;
}

export default function GenerationTypeComponent({
  item,
  control,
  getValues,
  setValue,
  errors,
  setError,
  clearErrors,
  helperFunction,
}: GenerationTypeComponentProps) {
  const { generation_type, levers } = item;
  return (
    <Box width="100%">
      <StyledHeading3>
        {GenerationTypeTitle[generation_type as GenerationType]}
      </StyledHeading3>
      <Box mt="16px" />
      {levers.length ? (
        levers.map((lever) => (
          <Levers
            lever={lever}
            key={lever.lever_name}
            isConfigure
            registeredName={generation_type}
            control={control}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            helperFunction={helperFunction}
          />
        ))
      ) : (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          width="100%"
          color="#333333"
          sx={{
            // paddingTop: "-10px",
            paddingBottom: "10px",
            fontSize: "14px",
          }}
        >
          Primary Text Recommendations will be generated based on the details
          added above along with the reference ad's content during variant
          creation process.
        </Box>
      )}
    </Box>
  );
}
