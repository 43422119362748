import React from "react";

interface NavigationDropDownItemProps {
  data: Array<Object>;
  onSelect: any;
  setVisible: any;
}

export default function NavigationDropDownItem({
  data,
  onSelect,
  setVisible,
}: NavigationDropDownItemProps) {
  return (
    <>
      {data.map((d: any, index: any) => (
        <div
          onClick={() => {
            setVisible(false);
            onSelect(d.id);
          }}
          className="dropdown-item"
          key={index}
          title={d.name}
        >
          {d?.dropDownIcon && d?.dropDownIcon} {d.name}
        </div>
      ))}
    </>
  );
}
