import {
  SetParentAiGroupPlanners,
  SetParentAiGroupItems,
  SetParentAiGroupPlannerDetails,
  SET_PARENT_AI_GROUP_ITEMS,
  SET_PARENT_AI_GROUP_PLANNERS,
  SET_PARENT_AI_GROUP_PLANNERS_DETAILS,
  SET_SELECTED_PARENT_AI_GROUP,
  ADD_SINGLE_PARENT_AI_GROUP,
  DELETE_SINGLE_PARENT_AI_GROUP,
  SetParentAiGroupLoader,
  SET_PARENT_AI_GROUP_LOADER,
  SET_SELECTED_PLANNER_ID_PARENT,
} from "../../types/parentAiGroup/index";

export const initialParentAIGroupState: any = {
  data: {
    parentAiGroups: {},
    selectedParentAiGroup: null,
    selectedPlannerId: null,
    loader: false,
  },
};

const actions: any = {
  [SET_PARENT_AI_GROUP_ITEMS]: (state: any, payload: any) => ({
    ...state,
    data: {
      ...state.data,
      parentAiGroups: payload,
    },
  }),

  [SET_SELECTED_PARENT_AI_GROUP]: (state: any, payload: any) => ({
    ...state,
    data: {
      ...state.data,
      selectedParentAiGroup: payload,
    },
  }),

  [SET_SELECTED_PLANNER_ID_PARENT]: (state: any, payload: any) => ({
    ...state,
    data: {
      ...state.data,
      selectedPlannerId: payload,
    },
  }),

  [SET_PARENT_AI_GROUP_PLANNERS]: (state: any, payload: any) => ({
    data: {
      ...state.data,
      parentAiGroups: {
        ...state.data.parentAiGroups,
        [payload.parentAiGroupId]: {
          ...state.data.parentAiGroups[payload.parentAiGroupId],
          planners: payload.planners,
        },
      },
    },
  }),
  [ADD_SINGLE_PARENT_AI_GROUP]: (state: any, payload: any) => ({
    data: {
      ...state.data,
      parentAiGroups: {
        ...state.data.parentAiGroups,
        [payload.id]: { ...payload },
      },
      selectedParentAiGroup: payload.id,
    },
  }),

  [DELETE_SINGLE_PARENT_AI_GROUP]: (state: any, payload: any) => {
    const dataAfterDelete = { ...state.data.parentAiGroups };
    delete dataAfterDelete[payload.deleteId];
    return {
      ...state,
      data: {
        ...state.data,
        parentAiGroups: payload.newData,
        selectedParentAiGroup: payload.selectedId,
      },
    };
  },
  [SET_PARENT_AI_GROUP_PLANNERS_DETAILS]: (state: any, payload: any) => ({
    data: {
      ...state.data,
      parentAiGroups: {
        ...state.parentAiGroups,
        [payload.parentAiGroupId]: {
          ...state.parentAiGroups[payload.parentAiGroupId],
          planners: {
            ...state.parentAiGroups[payload.parentAiGroupId].planners,
            [payload.plannerId]: {
              ...state.parentAiGroups[payload.parentAiGroupId].planners[
                payload.plannerId
              ],
              details: payload.details,
            },
          },
        },
      },
    },
  }),
  [SET_PARENT_AI_GROUP_LOADER]: (state: any, payload: any) => ({
    ...state,
    data: {
      ...state.data,
      loader: payload,
    },
  }),
};
export default function parentAIGroupReducer(
  state = initialParentAIGroupState,
  action:
    | SetParentAiGroupPlanners
    | SetParentAiGroupItems
    | SetParentAiGroupPlannerDetails
    | SetParentAiGroupLoader
) {
  if (actions[action.type]) return actions[action.type](state, action.payload);
  return state;
}
