export interface FieldType {
  field_name: string;
  field_label: string;
  helper_text: string;
  is_required: boolean;
  fetch_value_from: string;
  is_visible_in_UI: boolean;
  facebook_internal_key: string;
  field_type: string;
  choices: Array<string>;
  min_value: string;
  max_value: string;
  default_value: string;
  origin?: string;
  is_dependent: boolean;
  dependent_on: string[];
}

export interface LeversType {
  lever_name: string;
  lever_label: string;
  helper_text: string;
  fields: FieldType[];
  is_bundle_supported: boolean;
  is_generate_supported: boolean;
}

export interface APIGenerationType {
  generation_type: string;
  levers: LeversType[];
}

export type GenerationType =
  | "primary_text"
  | "headline"
  | "description"
  | "image"
  | "video";

export enum GenerationTypeTitle {
  "primary_text" = "Primary Text:",
  "headline" = "Headline:",
  "description" = "Description:",
  "image" = "Image Text:",
  "video" = "Video:",
}

export enum GeneratePreviewImageType {
  "primary_text" = "message",
  "headline" = "name",
  "description" = "description",
  "image" = "image",
  "video" = "video",
}

export enum GeneratePreviewVideoType {
  "primary_text" = "message",
  "headline" = "title",
  "description" = "link_description",
  "image" = "image",
  "video" = "video",
}

export const GenderEnum = ["Male", "Female"];
/// fetch value from ad type
export type FetchValueFromAdType =
  | "primary_text"
  | "headline"
  | "description"
  | "ad_url"
  | "image_url"
  | "call_to_action";

export enum FetchValueFormAdEnum {
  "primary_text" = "object_story_spec.link_data.message|object_story_spec.video_data.message|asset_feed_spec.bodies",
  "headline" = "object_story_spec.link_data.name|object_story_spec.video_data.title|asset_feed_spec.titles",
  "description" = "object_story_spec.link_data.description|object_story_spec.video_data.link_description|asset_feed_spec.descriptions",
  "ad_url" = "object_story_spec.link_data.link|object_story_spec.video_data.call_to_action.value.link",
  "image_url" = "object_story_spec.link_data.picture|object_story_spec.video_data.image_url",
  "call_to_action" = "object_story_spec.link_data.call_to_action.type|object_story_spec.video_data.call_to_action.type",
}

// fetch value from adset

export type FetchValueFromAdSetType =
  | "age_min"
  | "age_max"
  | "interests"
  | "behaviors"
  | "genders";

// map element
export enum GenerationTypeConvertEnum {
  "primary_text" = "primaryText",
  "headline" = "headline",
  "description" = "description",
  "image" = "image",
  "video" = "video",
}
