import { memo, useEffect, useState } from "react";
import { Handle } from "react-flow-renderer";
import "./explore-exploit.scss";
import "../index.scss";
import { useDispatch, useSelector } from "react-redux";
import { setCalibrationFormData } from "../../../store/actions/targetingAI";

const text = [
  "I want to target females who are in their 25-45 age group interested in buying office & casual office wear",
  "I wish to target young mothers interested in baby care products",
  "I wish to target males in 35-45 age group who invested in stock market",
];

export default memo(({ data, isConnectable }) => {
  const { ICPContextDescription } = useSelector(
    (state) => state.calibration.formData
  );
  const dispatch = useDispatch();
  const [placeholder, setPlaceholder] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index == text.length) setIndex(0);
    let timerId = setTimeout(() => {
      setIndex(index + 1);
    }, 3000);
    return () => {
      clearTimeout(timerId);
    };
  }, [index]);

  useEffect(() => {
    setPlaceholder(text[index]);
  }, [index]);

  return (
    <div className="p-3 bg-white card-style">
      {data.data.map((element, index) => {
        return (
          <div key={index}>
            <Handle
              type="target"
              position="left"
              style={{ background: "#0869FB" }}
              onConnect={(params) => console.log("handle onConnect", params)}
              isConnectable={isConnectable}
            />
            <Handle
              type="source"
              position="right"
              style={{ background: "#0869FB" }}
              onConnect={(params) => console.log("handle onConnect", params)}
              isConnectable={isConnectable}
            />
            <div
              className={`flex justify-between font-weight-bold ${
                index === 0 ? "border-separator" : ""
              }`}
              style={{
                paddingTop: index === 0 ? "0" : "1em",
                paddingBottom: index === 0 ? "1em" : "0",
              }}
            >
              <div className="text-lg">{element.label}</div>
            </div>
            <textarea
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "24px",
                padding: "20px 10px 10px",
                width: "100%",
                minHeight: "200px",
                border: "1px solid #EFEFEF",
                "::placeholder": {
                  fontStyle: "italic",
                  color: "#ABABAB",
                },
              }}
              onChange={(e) => {
                dispatch(
                  setCalibrationFormData({
                    key: "ICPContextDescription",
                    value: e.target.value,
                  })
                );
              }}
              placeholder={placeholder}
              value={ICPContextDescription}
            />
          </div>
        );
      })}
    </div>
  );
});
