import {
  SetGSCalibrationData,
  SET_GS_CALIBRATION_DATA,
  RESET_GS_CALIBRATION_DATA,
  ResetGSCalibrationData,
} from "../../types/gsTargetingAI";

const initialData = {
  ExploreValueCheck: true,
  LookbackPeriod: "90 Days",
  GoogleKwsPlannerCheck: true,
  CalibrationCustomKeywordsCheck: true,
  CalibrationSources: ["Google Keyword Planner"],
  CalibrationCustomKeywords: [],
};

export default function gsCalibration(
  state: any = {
    data: initialData,
  },
  action: SetGSCalibrationData | ResetGSCalibrationData
) {
  switch (action.type) {
    case SET_GS_CALIBRATION_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };

    case RESET_GS_CALIBRATION_DATA:
      return {
        ...state.data,
        data: initialData,
      };
    default:
      return state;
  }
}
