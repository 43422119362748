import React from "react";
import "./index.scss";

const ViewMore: React.FC<{
  popupTitle?: string;
  popupData?: string;
}> = ({ popupTitle, popupData }) => {
    return (<div className="view-more">
        <span className="view-more-title noselect">
            {` View More`}
        </span>
        <div className="keywords-popup keywords">
        <div className="keyword-popup-title">{popupTitle}</div>
            {popupData}
        </div>
    </div>
  );
};

export default ViewMore;
