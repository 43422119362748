import { FC, ReactElement } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import "../index.scss";

type StripItem = {
  title: string;
  text: string;
  svg: ReactElement<any, any>;
  width?: string;
};
type StripContent = {
  items: StripItem[];
  width?: string;
  gap?: number;
  mainItem: StripItem[];
};

const Strip: FC<StripItem> = ({ title, text, svg, width }) => (
  <Box width={width} mb={2}>
    <Grid container spacing={2} mt={0} ml={0} mr={1} pr={2.5}>
      <Grid
        item
        xs={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {svg}
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Stack spacing={1}>
          <Box
            sx={{
              fontFamily: "Lato",
              fontSize: "0.925rem",
              color: "#000",
            }}
          >
            {title}
          </Box>
          <Box
            sx={{
              fontSize: "0.925rem",
              color: "#5A5A5A",
            }}
          >
            {text}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  </Box>
);

const PersonaTunerLoaderStep: FC<StripContent> = ({
  items,
  width,
  gap,
  mainItem,
}) => {
  return (
    <Stack
      sx={{
        py: 3,
        px: 1,
        border: "1px solid #EFEFEF",
        // boxShadow: "0px 0.25rem 0.875rem rgba(0, 0, 0, 0.05)",
        // gap: gap ?? 5,
        width: width ? width : "22.5rem",
        height: "fit-content",
        borderRadius: "10px",
        boxShadow: "0px 4px 14px 0px #0000000D",
      }}
    >
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        className="border-separator"
        pb={2}
      >
        {mainItem.map((item) => (
          <Strip {...item} key={item.title} width={"100%"} />
        ))}
      </Box>
      <Box display={"flex"} flexWrap={"wrap"} mt={2}>
        {items.map((item) => (
          <Strip {...item} key={item.title} width={"48%"} />
        ))}
      </Box>
    </Stack>
  );
};

export default PersonaTunerLoaderStep;
