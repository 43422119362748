import HttpService from "HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "utils/path";

export const editBrandAPI = async (editBrandPayload: {}, brandId: string) => {
  let response = {
    success: false,
    message: "",
    data: {},
  };
  try {
    let urlParams = `/v4/api/brands/${brandId}`;
    response = await HttpService.put(
      CROSS_PLATFORM_URL,
      urlParams,
      "",
      editBrandPayload
    ).then((data: any) => {
      return data.data;
    });
  } catch (error) {
    console.log("LL: editBrandAPI -> error", error);
  }
  return response;
};
