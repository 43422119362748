import {
  SetPublishedCohorts,
  SelectedCohortType,
  ISetPublishedCohortsData,
  ISetDraftedCohortsFilter,
  ISetCommonFilters,
  GET_PUBLISHED_COHORTS,
  GET_DRAFTED_COHORTS,
  GET_DRAFTED_COHORT_FILTERS,
  GET_PUBLISHED_COHORTS_FILTER,
  SET_PUBLISHED_COHORTS,
  SET_PUBLISHED_COHORTS_FILTER,
  SET_LOADING_AUDIENCE_MANAGER,
  SET_SELECTED_COHORT_TYPE,
  SET_SELECTED_SORT_TYPE,
  SET_SELECTED_AI_GROUP_AUDIENCE_MANAGER,
  SET_DRAFTED_COHORTS,
  SET_RECOMMENDED_COHORTS,
  SET_COMMON_FILTERS_BRAND,
  SET_COMMON_FILTERS_BRAND_WEBSITE,
  SET_BUCKET_LENGTH,
  SET_BUCKET_SKIP,
  SET_DRAFTED_COHORT_FILTERS,
  SET_RECOMMENDED_COHORTS_FILTERS,
  SET_COMMON_FILTERS,
  RESET_ALL_AUDIENCE_MANAGER_DATA,
  GetPublishedCohorts,
  GetDraftedCohorts,
  GetDraftedCohortFilters,
  GetPublishedCohortsFilter,
  IGetPublishedCohortsPayload,
  IGetDraftedCohortsPayload,
  SetPublishedCohortsFilters,
  ISetPublishedCohortsFilter,
  SetLoading,
  SetSelectedCohortType,
  SetSelectedSortType,
  SetSelectedAiGroupAudienceManager,
  SetRecommendedCohorts,
  SetDraftedCohorts,
  ISetDraftedCohortsData,
  SetBucketSkip,
  ResetAllAudienceManegerData,
  SetBucketLength,
  SetCommonFiltersBrand,
  SetCommonFiltersBrandWebsite,
  SetRecommendedCohortsFilters,
  SetDraftedCohortsFilters,
  SetCommonFilters,
  SET_SELECTED_PLATFORM,
  SetSelectedPlatform,
  SetSelectedTypes,
  SET_SELECTED_TYPES,
} from "../../types/AudienceManager/index";

export const getPublishedCohorts = (
  payload: IGetPublishedCohortsPayload
): GetPublishedCohorts => ({
  type: GET_PUBLISHED_COHORTS,
  payload,
});

export const getDraftedCohorts = (
  payload: IGetDraftedCohortsPayload
): GetDraftedCohorts => ({
  type: GET_DRAFTED_COHORTS,
  payload,
});

export const getDraftedCohortFilters = (payload: {
  aiGroupId: string;
  status: "isDrafted" | "isRecommended";
}): GetDraftedCohortFilters => ({
  type: GET_DRAFTED_COHORT_FILTERS,
  payload,
});

export const getPublishedCohortsFilter = (payload: {
  aiGroupId: string;
}): GetPublishedCohortsFilter => ({
  type: GET_PUBLISHED_COHORTS_FILTER,
  payload,
});

export const setPublishedCohortsFilters = (
  payload: ISetPublishedCohortsFilter
): SetPublishedCohortsFilters => ({
  type: SET_PUBLISHED_COHORTS_FILTER,
  payload,
});

export const setPublishedCohorts = (
  payload: ISetPublishedCohortsData[]
): SetPublishedCohorts => ({
  type: SET_PUBLISHED_COHORTS,
  payload,
});

export const setDraftedCohorts = (
  payload: ISetDraftedCohortsData[]
): SetDraftedCohorts => ({
  type: SET_DRAFTED_COHORTS,
  payload,
});

export const setDraftedCohortsFilters = (
  payload: ISetDraftedCohortsFilter
): SetDraftedCohortsFilters => ({
  type: SET_DRAFTED_COHORT_FILTERS,
  payload,
});

export const setRecommendedCohorts = (
  payload: ISetDraftedCohortsData[]
): SetRecommendedCohorts => ({
  type: SET_RECOMMENDED_COHORTS,
  payload,
});

export const setRecommendedCohortsFilters = (
  payload: ISetDraftedCohortsFilter
): SetRecommendedCohortsFilters => ({
  type: SET_RECOMMENDED_COHORTS_FILTERS,
  payload,
});

export const setLoading = (payload: any): SetLoading => ({
  type: SET_LOADING_AUDIENCE_MANAGER,
  payload,
});

export const setSelectedCohortType = (
  payload: SelectedCohortType,
  callback: any
): SetSelectedCohortType => ({
  type: SET_SELECTED_COHORT_TYPE,
  payload: {
    cohortType: payload,
    callback,
  },
});

export const setSelectedSortType = (payload: any): SetSelectedSortType => ({
  type: SET_SELECTED_SORT_TYPE,
  payload,
});

export const setSelectedAiGroupAudienceManager = (
  payload: string
): SetSelectedAiGroupAudienceManager => ({
  type: SET_SELECTED_AI_GROUP_AUDIENCE_MANAGER,
  payload,
});

export const setCommonFilters = (
  payload: ISetCommonFilters
): SetCommonFilters => ({
  type: SET_COMMON_FILTERS,
  payload,
});

export const setCommonFiltersBrand = (
  payload: string[]
): SetCommonFiltersBrand => ({
  type: SET_COMMON_FILTERS_BRAND,
  payload,
});

export const setCommonFiltersBrandWebsite = (
  payload: string[]
): SetCommonFiltersBrandWebsite => ({
  type: SET_COMMON_FILTERS_BRAND_WEBSITE,
  payload,
});

export const setBucketLength = (payload: {
  type: string;
  length: number;
}): SetBucketLength => ({
  type: SET_BUCKET_LENGTH,
  payload,
});

export const setBucketSkip = (payload: {
  type: string;
  length: number;
}): SetBucketSkip => ({
  type: SET_BUCKET_SKIP,
  payload,
});

export const resetAllData = (): ResetAllAudienceManegerData => ({
  type: RESET_ALL_AUDIENCE_MANAGER_DATA,
});

export const setSelectedPlatform = (
  payload: "facebook" | "youtube"
): SetSelectedPlatform => ({
  type: SET_SELECTED_PLATFORM,
  payload,
});

export const setSelectedTypes = (payload: string[]): SetSelectedTypes => ({
  type: SET_SELECTED_TYPES,
  payload,
});
