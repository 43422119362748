import { Checkbox as MUICheckBox, CheckboxProps } from "@mui/material";
import { StyledCheckedIcon, StyledIcon } from "./styled";

export function CheckBox(props: CheckboxProps) {
  return (
    <MUICheckBox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<StyledCheckedIcon />}
      icon={<StyledIcon />}
      {...props}
    />
  );
}
