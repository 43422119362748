import { Popover } from "@mui/material";
import React from "react";
import { ReactComponent as DownIcon } from "../../assets/svg/down.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import HandleClickOutside from "../../components/common/HandleClickOutside";
import "./index.scss";

const SearchableSelect: React.FC<{
  customProps: Record<string, any>;
  value?: string;
  data: any;
  onChange: (value: any) => void;
  filterOptions: (value: any) => void;
  labelComponent: any;
  className?: string;
  valueComponent?: any;
  disable?: any;
}> = ({
  customProps = {},
  value,
  onChange,
  data,
  filterOptions,
  labelComponent: Component,
  className,
  valueComponent: ValueComponent,
  disable,
}) => {
  const [show, setShow] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const wrapperRef = React.useRef(null);

  HandleClickOutside(wrapperRef, [setShow]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    if (typeof disable === "number" ? !(disable >= 0) : !disable) {
      setAnchorEl(event.currentTarget);
      setFilterText("");
      filterOptions("");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterText("");
    filterOptions("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="position-relative searchable-select m-1" ref={wrapperRef}>
      <div
        className={
          `background-white flex-center justify-content-between selected-value ` +
          className
        }
        onClick={handleClick}
        aria-describedby={id}
      >
        <div {...customProps} className="text-dark text-left text-truncate">
          {ValueComponent ? ValueComponent : value}
        </div>
        <DownIcon className="down-icon" />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={"popover-action mt-2"}
      >
        <div className={`select-dropdown`}>
          <div className="flex-center justify-content-between position-relative m-3">
            <input
              type="text"
              value={filterText}
              placeholder="Search..."
              onChange={(e) => {
                setFilterText(e.target.value);
                filterOptions(e.target.value);
              }}
              className="w-100 p-2 pl-4 input-search"
            />
            <SearchIcon
              fill="#e9e9e9"
              className="position-absolute search-icon"
            />
          </div>
          {data.map((dataPoint: any) => {
            return (
              <label
                key={dataPoint.id}
                className="flex-center px-3 py-2 mb-0"
                onClick={() => {
                  if (open) {
                    setAnchorEl(null);
                  }
                  onChange(dataPoint);
                }}
              >
                <Component value={value} dataPoint={dataPoint} />
              </label>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export default SearchableSelect;
