import {
  ActionRecommendations,
  ACTION_RECOMMENDATIONS,
  adset,
  GetCPRDetails,
  GetCPRRecommendations,
  GetIndustry,
  GetModelText,
  GetRecommendationDetails,
  GetRecommendations,
  GetLearningPhaseFlag,
  GET_CPR_DETAILS,
  GET_CPR_RECOMMENDATIONS,
  GET_INDUSTRY,
  GET_MODEL_TEXT,
  GET_RECOMMENDATIONS,
  GET_RECOMMENDATION_DETAILS,
  industry_type,
  SetBarChartData,
  SetChartLoading,
  SetCPRDetails,
  SetCPRGraphDetails,
  SetCPRRecommendation,
  SetCPRRecommendationLoading,
  SetIndustryType,
  SetModelText,
  SetRecommendationDetails,
  SetRecommendations,
  SET_BAR_CHART_DATA,
  SET_CHART_LOADING,
  SET_CPR_DETAILS,
  SET_CPR_GRAPH_DETAILS,
  SET_CPR_RECOMMENDATION,
  SET_CPR_RECOMMENDATION_LOADING,
  SET_INDUSTRY_TYPE,
  SET_MODEL_TEXT,
  SET_RECOMMENDATIONS,
  SET_RECOMMENDATION_DETAILS,
  GET_LEARNING_PHASE_FLAG,
  GetKeywordRecommendations,
  GET_KEYWORD_RECOMMENDATIONS,
  SetGroupRecommendationLoading,
  SET_GROUP_RECOMMENDATION_LOADING,
  SetGroupRecommendationDetailLoading,
  SET_GROUP_RECOMMENDATION_DETAIL_LOADING,
  GetAgency,
  GET_AGENCY,
  SetSelectedAgency,
  SET_SELECTED_AGENCY,
  Agency,
  GetAccountDetails,
  GET_ACCOUNT_DETAILS,
  DisableDashboard,
  DISABLE_DASHBOARD,
  GetAgencyAssets,
  GET_AGENCY_ASSETS,
  AgencyAssets,
  SetAgencyAssets,
  SET_AGENCY_ASSETS,
  SET_DEFAULT_AGENCY_ASSETS,
  SetDefaultAgencyAssets,
} from "./../types/common";

// State
export const setModelText = (payload: {}): SetModelText => ({
  type: SET_MODEL_TEXT,
  payload,
});

export const setSelectedAgency = (payload: Agency): SetSelectedAgency => ({
  type: SET_SELECTED_AGENCY,
  payload,
});

export const setIndustryType = (payload: industry_type[]): SetIndustryType => ({
  type: SET_INDUSTRY_TYPE,
  payload,
});

export const setRecommendations = (payload: any): SetRecommendations => ({
  type: SET_RECOMMENDATIONS,
  payload,
});

export const setRecommendationDetails = (
  payload: any
): SetRecommendationDetails => ({
  type: SET_RECOMMENDATION_DETAILS,
  payload,
});

export const setCPRDetails = (payload: any): SetCPRDetails => ({
  type: SET_CPR_DETAILS,
  payload,
});

export const setCPRGraphDetails = (payload: any): SetCPRGraphDetails => ({
  type: SET_CPR_GRAPH_DETAILS,
  payload,
});

export const setBarChartData = (payload: any): SetBarChartData => ({
  type: SET_BAR_CHART_DATA,
  payload,
});

export const setCPRRecommendation = (payload: any): SetCPRRecommendation => ({
  type: SET_CPR_RECOMMENDATION,
  payload,
});

export const setChartLoading = (payload: boolean): SetChartLoading => ({
  type: SET_CHART_LOADING,
  payload,
});

export const setCPRRecommendationLoading = (
  payload: boolean
): SetCPRRecommendationLoading => ({
  type: SET_CPR_RECOMMENDATION_LOADING,
  payload,
});

export const setGroupRecommendationLoading = (
  payload: boolean
): SetGroupRecommendationLoading => ({
  type: SET_GROUP_RECOMMENDATION_LOADING,
  payload,
});

export const setGroupRecommendationDetailLoading = (
  payload: boolean
): SetGroupRecommendationDetailLoading => ({
  type: SET_GROUP_RECOMMENDATION_DETAIL_LOADING,
  payload,
});

// API

export const getModelText = (
  payload: { user: any },
  callback: Function
): GetModelText => ({
  type: GET_MODEL_TEXT,
  payload,
  callback,
});

export const getAccountDetails = (
  payload: { account_id: any },
  callback: Function
): GetAccountDetails => ({
  type: GET_ACCOUNT_DETAILS,
  payload,
  callback,
});

export const getIndustry = (
  payload: { user: any },
  callback: Function
): GetIndustry => ({
  type: GET_INDUSTRY,
  payload,
  callback,
});

export const getRecommendations = (
  payload: { elementId: string; user: any },
  callback: Function
): GetRecommendations => ({
  type: GET_RECOMMENDATIONS,
  payload,
  callback,
});

export const getKeywordRecommendations = (
  payload: { adAccountId: string; user: any },
  callback: Function
): GetKeywordRecommendations => ({
  type: GET_KEYWORD_RECOMMENDATIONS,
  payload,
  callback,
});

export const getRecommendationDetails = (
  payload: { elementId: string; recommendation_id: string; user: any },
  callback: Function
): GetRecommendationDetails => ({
  type: GET_RECOMMENDATION_DETAILS,
  payload,
  callback,
});

export const actionRecommendations = (
  payload: {
    elementId: string;
    recommendation_ids: any;
    user: any;
    recommendation_action: "approve" | "deny";
  },
  callback: Function
): ActionRecommendations => ({
  type: ACTION_RECOMMENDATIONS,
  payload,
  callback,
});

export const getCPRDetails = (
  payload: { elementId: string; start_date: any; end_date: any; user: any },
  callback: Function
): GetCPRDetails => ({
  type: GET_CPR_DETAILS,
  payload,
  callback,
});

export const getCPRRecommendations = (
  payload: { elementId: string; start_date: any; end_date: any; user: any },
  callback: Function
): GetCPRRecommendations => ({
  type: GET_CPR_RECOMMENDATIONS,
  payload,
  callback,
});

export const getLearningPhaseFlag = (
  payload: { brandId: string; accountId: string; user: any },
  callback: Function
): GetLearningPhaseFlag => ({
  type: GET_LEARNING_PHASE_FLAG,
  payload,
  callback,
});

export const getAgency = (
  payload: { agencyId: string; user: any },
  callback: Function
): GetAgency => ({
  type: GET_AGENCY,
  payload,
  callback,
});

export const getAgencyAssets = (
  payload: { agencyId: string, brandId: string },
  callback: Function
): GetAgencyAssets => ({
  type: GET_AGENCY_ASSETS,
  payload,
  callback,
});

export const setAgencyAssets = (payload: AgencyAssets): SetAgencyAssets => ({
  type: SET_AGENCY_ASSETS,
  payload,
});
export const setDefaultAgencyAssets = (): SetDefaultAgencyAssets => ({
  type: SET_DEFAULT_AGENCY_ASSETS,
});

export const disableDashboard = (
  payload: { isDisableDashboard: boolean; user: any; brandId: string },
  callback: Function
): DisableDashboard => ({
  type: DISABLE_DASHBOARD,
  payload,
  callback,
});
