import { Box, IconButton } from "@mui/material";
import React, { Fragment, useState } from "react";
import { ReactComponent as Toggle } from "../../../../../assets/cai/svg/toggleCTA.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/cai/svg/crossIcon.svg";
import { ReactComponent as Delete } from "../../../../../assets/cai/svg/deleteIconCTA.svg";
import { ReactComponent as Share } from "../../../../../assets/cai/svg/shareIcon.svg";
import { ReactComponent as DropDown } from "../../../../../assets/cai/svg/dropDown.svg";
import {
  StyledContainer,
  StyledDragButton,
  StyledDragComponent,
  StyledDraggableComponent,
  StyledDropdown,
  StyledNumberContainer,
  StyledSelectedContent,
  StyledSVGContainer,
  StyledToggleIcon,
  StyledVerticalDivider,
  StyledVerticalLine,
} from "../styled";
import Draggable from "react-draggable";
import { Button, ConfirmDialog } from "components/shared";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSelectedVariants,
  getAds,
  setUnselectTotalVariants,
  submitForReview,
} from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { getAdsFB } from "store/actions/CreativeAI_Revamp/facebook.actions";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import ReviewModal from "../../ReviewModals/ReviewModal";
import { useCaiFbMixPanel } from "../hooks/useCaiFbMixPanel";
import { LightTooltip } from "components/CreativeAI_Revamp/shared";
import { initialState } from "store/types/auth";
import { AdAccountState } from "store/types/adaccount";
import { OUTCOME_SALES_AD_LIMIT } from "../../utils/const";

function CTAFloater({
  noOfVariantsSelected,
  setOpenReviewModal,
  refreshAds,
  mixPanelReview,
  setOpenReviewFlow,
}: {
  noOfVariantsSelected: string[];
  setOpenReviewModal: any;
  refreshAds: any;
  mixPanelReview: any;
  setOpenReviewFlow: any;
}) {
  const [open, setOpen] = useState<boolean>(true);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();

  const { adAccountsSelection, adSetSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );

  const { adsetDetails } = useSelector(
    (state: { caiFBReducer: any }) => state.caiFBReducer
  );

  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const { isDeletingMultipleVariant, ads, approverLink } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const { mixPanelDeleteVariantsCTA } = useCaiFbMixPanel();
  const onSubmitForReview = () => {
    mixPanelReview("Review - Publish Modal");
    dispatch(
      submitForReview({
        params: {
          platform: "FACEBOOK",
          type: "CREATIVE",
          brandId: selectedBrand?.id,
          variants: noOfVariantsSelected?.map((item: any) => {
            return { id: item };
          }),
          user: auth.user,
          adAccountId: adAccountsSelection?.selectedAdAccountId,
        },
      })
    );
    setOpenReviewFlow(true);
    refreshAds();
  };
  const onDeleteAll = () => {
    mixPanelDeleteVariantsCTA();
    dispatch(
      deleteSelectedVariants({
        variantIds: noOfVariantsSelected,
        callback: () => {
          setShowDeletePopUp(false);
          if (
            adSetSelection?.selectedAdSetId &&
            adAccountsSelection?.selectedAdAccountId &&
            adsetDetails?.adset?.adsetId == adSetSelection?.selectedAdSetId
          ) {
            dispatch(
              getAds({
                adSetId: adSetSelection?.selectedAdSetId,
                adAccountId: adAccountsSelection?.selectedAdAccountId,
                ...(adsetDetails?.facebookData?.campaign_objective ==
                "OUTCOME_SALES"
                  ? { adsCount: OUTCOME_SALES_AD_LIMIT }
                  : {}),
              })
            );
          }
        },
      })
    );
  };
  const onReviewPublishClick = () => {
    setOpenReviewModal(true);
  };

  const adCount = (ads?.length ?? 0) + noOfVariantsSelected.length;

  const disabled = adCount > 50;

  return (
    <Fragment>
      <Draggable bounds="parent">
        <StyledDraggableComponent open={open}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
          >
            <StyledToggleIcon
              open={open}
              onClick={() => setOpen((prev) => !prev)}
            >
              <StyledSVGContainer width="0.375em">
                <Toggle width="100%" height="100%" />
              </StyledSVGContainer>
            </StyledToggleIcon>
            {open ? (
              <StyledContainer>
                <StyledDragButton>
                  {Array(6)
                    .fill(undefined)
                    .map((index) => (
                      <Box
                        display={"flex"}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <StyledDragComponent />
                      </Box>
                    ))}
                </StyledDragButton>
                <StyledSelectedContent>
                  <span style={{ marginRight: "1.1em" }}>
                    {noOfVariantsSelected.length} Ads Selected{" "}
                  </span>
                  <StyledSVGContainer width="0.6em">
                    <IconButton
                      onClick={() => {
                        dispatch(setUnselectTotalVariants());
                      }}
                      sx={{ fontSize: "1em", padding: 0 }}
                    >
                      <CrossIcon width="0.8em" height={"100%"} />
                    </IconButton>
                  </StyledSVGContainer>
                </StyledSelectedContent>
                <StyledVerticalDivider>
                  <StyledVerticalLine />
                </StyledVerticalDivider>
                <Button
                  variant="text"
                  sx={{
                    height: "2.4em",
                    width: "6.4em",
                    background: "#f8fbff",
                    color: "#e84b47",
                    fontWeight: 600,
                    fontSize: "0.625em",
                    lineHeight: "1.2em",
                    padding: "0px 0px",
                    ":hover": {
                      background: "#f8fbff",
                    },
                  }}
                  startIcon={
                    <StyledSVGContainer width="1.2em">
                      <Delete width="100%" height="100%" />
                    </StyledSVGContainer>
                  }
                  onClick={() => setShowDeletePopUp(true)}
                >
                  Delete
                </Button>
                {/* <LightTooltip
                  title={
                    disabled
                      ? `The ad limit of 50 has been reached; Kindly delete some ads or uncheck at least ${
                          adCount - 50
                        } ad variants`
                      : ""
                  }
                > */}
                <Box
                  display={"flex"}
                  width={"8.5em"}
                  marginLeft={"0.8em"}
                  position="relative"
                  alignItems={"end"}
                >
                  <IconButton
                    sx={{
                      height: "2.4em",
                      background: "#0869FB",
                      borderRadius: "0.5em 0 0 0.5em",
                      borderRight: "0.1em solid rgba(255, 255, 255, .2)",
                      fontSize: "0.625em",
                      fontWeight: 600,
                      lineHeight: "1.2em",
                      color: "#fffff",
                      ":hover": {
                        background: "#0869FB",
                      },
                      svg: {
                        transform: showDropdown ? "rotate(180deg)" : "",
                      },
                    }}
                    // disabled={disabled}
                    onClick={() => {
                      setShowDropdown((prev: any) => !prev);
                    }}
                  >
                    <DropDown width="0.8em" height={"100%"} />
                  </IconButton>
                  <Button
                    variant="contained"
                    sx={{
                      height: "2.4em",
                      background: "#0869FB",
                      borderRadius: "0 0.5em 0.5em 0",
                      fontSize: "0.625em",
                      fontWeight: 600,
                      lineHeight: "1.2em",
                      color: "#fffff",
                      boxShadow: "none",
                      ":hover": {
                        boxShadow: "none",
                        background: "#0869FB",
                      },
                      ".MuiButton-startIcon": {
                        marginLeft: "-0.8em",
                        marginRight: "0.4em",
                      },
                    }}
                    onClick={onReviewPublishClick}
                    startIcon={
                      <StyledSVGContainer width="1.25em">
                        <Share width="100%" height="100%" />
                      </StyledSVGContainer>
                    }
                    // disabled={disabled}
                  >
                    Review/Publish
                  </Button>
                  {showDropdown && (
                    <StyledDropdown>
                      <Button
                        sx={{
                          color: "black",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "0.625em",
                          lineHeight: "1.2em",
                        }}
                        disabled={approverLink?.isLoading}
                        onClick={onSubmitForReview}
                      >
                        Request Quick Review
                      </Button>
                    </StyledDropdown>
                  )}
                </Box>
                {/* </LightTooltip> */}
                {/* <Button
                variant="contained"
                sx={{
                  height: "2.8em",
                  background: "#0869FB",
                  borderRadius: "0.5em",
                  fontSize: "0.625em",
                  fontWeight: 600,
                  lineHeight: "1.2em",
                  color: "#fffff",
                  //   padding: "0.5em 1.3125em 0.5em 1em",
                }}
                startIcon={<Share />}
              >
                Create Variants
              </Button> */}
              </StyledContainer>
            ) : (
              <StyledNumberContainer>
                <span>{noOfVariantsSelected.length}</span>
              </StyledNumberContainer>
            )}
          </Box>
        </StyledDraggableComponent>
      </Draggable>

      {showDeletePopUp && (
        <ConfirmDialog
          show={showDeletePopUp}
          onClose={() => setShowDeletePopUp(false)}
          title="Are you sure you wanted to delete selected variants"
          onComplete={onDeleteAll}
          completeButtonText="Confirm"
          loading={isDeletingMultipleVariant}
        />
      )}
    </Fragment>
  );
}

export default CTAFloater;
