import React from "react";
import { useForm, Resolver } from "react-hook-form";
import CheckBox from "../../../ConfigureAd/Checkbox";
import type { GsaiState } from "../../../../../../store/types/CreativeAI/GsaiPlayground";

type ReplacementConfig = {
  isChecked: boolean;
  value: number;
};

type FormValues = {
  lowPerformance: ReplacementConfig;
  goodPerformance: ReplacementConfig;
  bestPerformance: ReplacementConfig;
};

type Props = {
  replacementConfig: GsaiState["replacementConfig"]["headlines"];
  toggleReplacementConfig: (isEnabled: boolean) => void;
  saveReplacementConfig: (
    conig: GsaiState["replacementConfig"]["headlines"]
  ) => void;
  hideModal: () => void;
  type: string;
};

function Modal({
  replacementConfig,
  toggleReplacementConfig,
  saveReplacementConfig,
  hideModal,
  type,
}: Props) {
  const { register, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      lowPerformance: { isChecked: false, value: 0 },
      goodPerformance: { isChecked: false, value: 0 },
      bestPerformance: { isChecked: false, value: 0 },
    },
  });

  const lowPerformance = watch("lowPerformance");
  const goodPerformance = watch("goodPerformance");
  const bestPerformance = watch("bestPerformance");

  const enableSaveButton = () => {
    let isSaveButtonEnabled =
      (lowPerformance.isChecked && lowPerformance.value > 0) ||
      (goodPerformance.isChecked && goodPerformance.value > 0) ||
      (bestPerformance.isChecked && bestPerformance.value > 0);

    if (lowPerformance.isChecked && lowPerformance.value == 0) {
      isSaveButtonEnabled = false;
    }

    if (goodPerformance.isChecked && goodPerformance.value == 0) {
      isSaveButtonEnabled = false;
    }

    if (bestPerformance.isChecked && bestPerformance.value == 0) {
      isSaveButtonEnabled = false;
    }

    return isSaveButtonEnabled;
  };

  React.useEffect(() => {
    setValue("lowPerformance", replacementConfig?.lowPerformance);
    setValue("goodPerformance", replacementConfig?.goodPerformance);
    setValue("bestPerformance", replacementConfig?.bestPerformance);
  }, []);

  return (
    <div className="cr-config-modal">
      <div className="cr-modal-content">
        <div className="cr-modal-title">
          Replace <span className="text-capitalize">{type}</span>
        </div>
        <div className="cr-modal-subtitle">
          Which {type} to replace based on Google Performing?
        </div>
        <div className="cr-input">
          <CheckBox
            name="lowPerformance.isChecked"
            register={register}
            label="Low Performance - Replace in every"
            warningMessage={
              lowPerformance.isChecked && Number(lowPerformance.value) === 0
                ? "Select more than zero days"
                : ""
            }
          />
          <div className="cr-input-days">
            <input
              type="number"
              min={0}
              {...register("lowPerformance.value")}
            />
            days
          </div>
        </div>

        <div className="cr-input">
          <CheckBox
            name="goodPerformance.isChecked"
            register={register}
            label="Good Performance - Replace in every"
            warningMessage={
              goodPerformance.isChecked && Number(goodPerformance.value) === 0
                ? "Select more than zero days"
                : ""
            }
          />
          <div className="cr-input-days">
            <input
              type="number"
              min={0}
              {...register("goodPerformance.value")}
            />
            days
          </div>
        </div>

        <div className="cr-input">
          <CheckBox
            name="bestPerformance.isChecked"
            register={register}
            label="Best Performance - Replace in every"
            warningMessage={
              bestPerformance.isChecked && Number(bestPerformance.value) === 0
                ? "Select more than zero days"
                : ""
            }
          />
          <div className="cr-input-days">
            <input
              type="number"
              min={0}
              {...register("bestPerformance.value")}
            />
            days
          </div>
        </div>
      </div>
      <div className="cr-modal-footer">
        <button
          className="cr-modal-btn"
          onClick={() => {
            hideModal();
            toggleReplacementConfig(false);
          }}
        >
          Disable Creative Replacement
        </button>
        <button
          className="cr-modal-save"
          disabled={!enableSaveButton()}
          onClick={() => {
            hideModal();
            saveReplacementConfig({
              lowPerformance,
              goodPerformance,
              bestPerformance,
            });
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default Modal;
