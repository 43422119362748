import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: "1px solid lightGray",
  borderRight: "1px solid lightGray",
  borderRadius: "10px",
}));

export const StyledColoredTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F2F2F2",
    color: "#000000",
    fontWeight: "700",
    height: "10px",
    fontFamily: "Inter",
  },
}));

// FOR STRIPPED TABLE
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F5F5F5",
    borderRight: "1px solid lightGray",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
    borderRight: "1px solid lightGray",
  },
}));

// FOR STRIPPED TABLE
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    fontWeight: "600",
    borderRight: "1px solid lightGray",
    height: "90px",
    fontFamily: "Inter",
    fontSize: 14,
    fontStyle: "normal",
    lineHeight: "17px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "90px",
    borderRight: "1px solid lightGray",
    fontFamily: "Inter",
    fontWeight: 400,
  },
}));

// FOR PLAIN TABLE
export const StyledPlainTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    border: "none",
  },
  "&:last-child td, &:last-child th": {
    border: "none",
  },
}));

// FOR PLAIN TABLE
export const StyledPlainTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#FFFFFF",
    color: "#000000",
    fontWeight: "700",
    height: "50px",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    width: "56px",
    height: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#000000",
    fontFamily: "Inter",
  },
}));
