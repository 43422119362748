import { Box } from "@mui/material";
import { StyledText } from "components/shared";
import React from "react";
import { TextWithTooltip } from "../TextWithTooltip/TextWithTooltip";
import { RadioSelectItemType } from "../types/types";

export const RadioCheckSelectItem = ({
  title,
  subtitle,
  leading,
  trailing,
  subtitleSXProps,
  titleSXProps,
  hideTooltip,
}: RadioSelectItemType) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      {leading}
      <Box
        display="flex"
        flexDirection="column"
        gap=".3em"
        minWidth={0}
        flex={1}
      >
        {title && (
          <TextWithTooltip
            fontSize="0.625em"
            // lineHeight="0.75em"
            text={title}
            hideTooltip={hideTooltip}
            {...titleSXProps}
          />
        )}
        {subtitle && (
          <StyledText
            color="#666666"
            fontWeight={400}
            fontSize="0.625em"
            lineHeight="0.75em"
            {...subtitleSXProps}
          >
            {subtitle}
          </StyledText>
        )}
      </Box>
      {trailing && <Box pl="1em">{trailing}</Box>}
    </Box>
  );
};
