import { Box, CircularProgress, Popover } from "@mui/material";
import React, { useState } from "react";
import { FrameThumbnailsItemType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import DemoImage from "../../../../../../../../src/assets/png/demoVideoImage.png";

import {
  StyledArrowBox,
  StyledPreviewVideoItem,
  StyledTimeStampText,
} from "./styeld";

interface PreviewVideoItemProps {
  selected?: boolean;
  onClick: (index: number) => void;
  index: number;
  timeStamp: string;
  thumbnailData: FrameThumbnailsItemType;
}

export default function PreviewVideoItem({
  selected,
  onClick,
  index,
  timeStamp,
  thumbnailData,
}: PreviewVideoItemProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      {" "}
      <Popover
        id="mouse-over-popover"
        className="content"
        sx={{
          pointerEvents: "none",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box
          sx={{
            height: "127px",
            borderRadius: "2px",
            background: "white",
            position: "relative",
          }}
        >
          <Box
            height="100%"
            component="img"
            src={thumbnailData?.thumbnailUrl}
            sx={{
              padding: "1px",
              borderRadius: "4px",
            }}
          />
          <StyledTimeStampText>{timeStamp}</StyledTimeStampText>
        </Box>
      </Popover>
      <StyledPreviewVideoItem
        selected={selected}
        onClick={() => onClick(index)}
        sx={{
          cursor: "pointer",
        }}
        onMouseEnter={
          !thumbnailData?.isThumbnailLoading ? handlePopoverOpen : undefined
        }
        onMouseLeave={
          !thumbnailData?.isThumbnailLoading ? handlePopoverClose : undefined
        }
      >
        {thumbnailData?.isThumbnailLoading ? (
          <Box
            height="60px"
            width="104px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box height="20px" width="20px">
              <CircularProgress size="25px" />
            </Box>
          </Box>
        ) : (
          <Box
            height="60px"
            width="104px"
            component="img"
            src={thumbnailData?.thumbnailUrl}
            alt="background"
          />
        )}
        {!thumbnailData?.isThumbnailLoading && (
          <StyledTimeStampText>{timeStamp}</StyledTimeStampText>
        )}
      </StyledPreviewVideoItem>
    </React.Fragment>
  );
}
