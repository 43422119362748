import React from "react";
import OptionsComponent from "./OptionsComponent";
import { StyledTabPanelMainDiv } from "./styled";
import { ItemType, HashType } from "../Editor.types";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateVariantsState,
  RecommendationMetaData,
} from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import {
  generateAdPreview,
  updateRecommendationMetadata,
  updateRecommendationSelectionState,
} from "../../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  checkAdType,
  getCreativeSPecFieldName,
} from "../../../utils/common.utils";


interface RecommendationTabProp {
  selected: ItemType | null;
  isRefreshLoading: boolean;
  mixPanelCreativeStudioSeeMore: any;
}
export const RecommendationTab = ({
  selected,
  isRefreshLoading,
  mixPanelCreativeStudioSeeMore,
}: RecommendationTabProp) => {
  const { recommendationMetaData: unFilteredecommendationMetaData, recommendationSelectionState, createSpec, } = 
  useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const recommendationMetaData = uniqueRecommendationData(
    JSON.parse(JSON.stringify(unFilteredecommendationMetaData))
  );
  const dispatch = useDispatch();

  const {
    selectedAdAccount: { adAccountId },
  } = useSelector((state: any) => state.aiGroupReducer);

  const handleChange = (value: string | any, type: HashType) => {
    dispatch(
      updateRecommendationSelectionState({
        [type]: typeof value === 'string' ? value : value?.text,
        isChanged: true,
      })
    );
  };

  const showMoreData = (hash: HashType) => {
    const localRecommendationData: RecommendationMetaData = {
      ...recommendationMetaData,
      isEditing: true,
      isDeleting: false,
    };
    const ref = document.getElementById(
      hash + (localRecommendationData[hash].noOfElementToShow - 1)
    );
    if (
      localRecommendationData[hash].noOfElementToShow + 4 <
      localRecommendationData[hash].data.length
    ) {
      ref!;
      setTimeout(function () {
        ref!.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
      localRecommendationData[hash].noOfElementToShow += 4;
    } else {
      setTimeout(function () {
        ref!.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);

      localRecommendationData[hash].noOfElementToShow =
        localRecommendationData[hash].data.length;
      localRecommendationData[hash].showMore = false;
    }
    dispatch(
      updateRecommendationMetadata({
        data: localRecommendationData,
      })
    );
  };
  if (!selected) return null;
  return (
    <StyledTabPanelMainDiv id="panel">
      <OptionsComponent
        mixPanelCreativeStudioSeeMore={mixPanelCreativeStudioSeeMore}
        value={recommendationSelectionState[selected.hash]}
        options={
          recommendationMetaData?.[selected.hash] ?? {
            data: [],
            selected: "",
            noOfElementToShow: 0,
            showMore: false,
          }
        }
        handleChange={handleChange}
        titleText={selected.titleText}
        hash={selected.hash}
        key={selected.hash}
        showMoreData={showMoreData}
        isLoading={isRefreshLoading}
      />
    </StyledTabPanelMainDiv>
  );
};

const uniqueRecommendationData = (recommendationMetaData: any) => {
  const unique: any = JSON.parse(JSON.stringify(recommendationMetaData));
  Object.keys(recommendationMetaData).forEach((key) => {
    const data: any = [];
    if (recommendationMetaData[key] && recommendationMetaData[key].data) {
      for (let i = 0; i < recommendationMetaData[key].data.length; i++) {
        if (
          !data.find(
            (item: any) =>
              item?.text === recommendationMetaData[key].data[i]?.text
          )
        ){
          data.push(recommendationMetaData[key].data[i]);
        }
      }
      unique[key] = { ...recommendationMetaData[key], data };
    }
  });
  return unique;
};
