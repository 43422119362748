import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactComponent as CheckmarkIcon } from "../assets/checkmarkIcon.svg";
import PlatformDetails from "./PlatformDetails";
import { PLATFORMS_MAPPER } from "../constants";

function OptimizerDetails({ optimizer }: any) {
  const { name, pbcActive, budgetPacingActive, subOptimizers } = optimizer;

  const commonState = useSelector((state: any) => state.smb.common);
  const platforms = commonState?.platforms?.platforms;

  return (
    <Box
      sx={{ py: 1 }}
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "5px",
        maxWidth: "20rem",
        borderBottom: "1px solid #DDDDDD",
      }}
    >
      <Typography variant="body2">
        {name}{" "}
        {subOptimizers.length > 0 &&
        subOptimizers.every(
          (subOptimizer: any) => subOptimizer.models.length > 0
        ) ? (
          <CheckmarkIcon />
        ) : (
          ""
        )}
      </Typography>
      <Stack
        direction="row"
        divider={
          <Divider
            orientation="vertical"
            flexItem
            style={{ height: "12px", alignSelf: "center" }}
          />
        }
        spacing={1}
      >
        <Typography variant="caption">
          <span style={{ fontSize: "0.75rem" }}>PBC</span>
          <span
            style={{
              color: pbcActive ? "#0869FB" : "#EA4335",
              fontSize: "0.75rem",
              fontWeight: "700",
              marginLeft: "0.5rem",
            }}
          >
            {pbcActive ? "Active" : "Inactive"}
          </span>
        </Typography>
        <Typography variant="caption">
          <span style={{ fontSize: "0.75rem" }}>Budget Pacing</span>
          <span
            style={{
              color: budgetPacingActive ? "#0869FB" : "#EA4335",
              fontSize: "0.75rem",
              fontWeight: "700",
              marginLeft: "0.5rem",
            }}
          >
            {budgetPacingActive ? "Active" : "Inactive"}
          </span>
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        {subOptimizers.length > 0 &&
          subOptimizers.map((subOptimizer: any) => {
            const platformDetails =
              PLATFORMS_MAPPER[platforms[subOptimizer.platformId]?.type];
            return (
              platformDetails && (
                <PlatformDetails
                  key={subOptimizer.id}
                  icon={platformDetails?.icon}
                  shortName={platformDetails?.shortName}
                  name={subOptimizer.adAccountName}
                  active_models={
                    subOptimizer.models ? subOptimizer.models.length : 0
                  }
                />
              )
            );
          })}
      </Stack>
    </Box>
  );
}

export default OptimizerDetails;
