import {
  StoreGSTaiGenerateKeywords,
  STORE_GS_TAI_GENERATE_KEYWORDS,
  GetGSTaiGeneratedKeywords,
  GET_GS_TAI_GENERATED_KEYWORDS,
  SET_GS_CALIBRATION_DATA,
  SetGSCalibrationData,
  SET_GS_KEYWORD_TUNING_DATA,
  SetGSKeywordTuningData,
  RESET_GS_CALIBRATION_DATA,
  ResetGSCalibrationData,
  RESET_GS_KEYWORD_TUNING_DATA,
  ResetGSKeywordTuningData,
  ResetAdditionalInputData,
  RESET_ADDITIONAL_INPUT_DATA,
} from "../types/gsTargetingAI";

export const storeGSTargetingAIGenerateKeywords = (
  payload: {},
  callback: Function
): StoreGSTaiGenerateKeywords => ({
  type: STORE_GS_TAI_GENERATE_KEYWORDS,
  payload,
  callback,
});

export const getGSTargetingAIGeneratedKeywords = (
  payload: any,
  callback: Function
): GetGSTaiGeneratedKeywords => ({
  type: GET_GS_TAI_GENERATED_KEYWORDS,
  payload,
  callback,
});

export const setGSCalibrationData = (
  payload: SetGSCalibrationData["payload"]
): SetGSCalibrationData => ({
  type: SET_GS_CALIBRATION_DATA,
  payload,
});

export const setGSKeywordTuningData = (
  payload: SetGSKeywordTuningData["payload"]
): SetGSKeywordTuningData => ({
  type: SET_GS_KEYWORD_TUNING_DATA,
  payload,
});

export const resetGSCalibrationData = (): ResetGSCalibrationData => ({
  type: RESET_GS_CALIBRATION_DATA,
});

export const resetGSKeywordTuningData = (): ResetGSKeywordTuningData => ({
  type: RESET_GS_KEYWORD_TUNING_DATA,
});

export const resetAdditionalInputData = (): ResetAdditionalInputData => ({
  type: RESET_ADDITIONAL_INPUT_DATA,
});
