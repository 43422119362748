import { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { pathValues, tabs } from "./SideBarData";

import "./SideBarAccordian.scss";
import { setSelectedOptimizer } from "store/actions/SmbDashboard/common";
import { REACT_APP_BASE_URL_PIXIS2 } from "utils/path";
import { AdAccountState } from "store/types/adaccount";
import { mixPanelAdEvent } from "utils/mixpanel";
import {
  getSearchParams,
  isEditorRole,
  isViewerRole,
  isPublisherRole,
  isAdminRole,
} from "utils/commonFunctions";

const OPTIMIZE_V0_ACCESS_BY_EMAIL = (
  process.env.REACT_APP_OPTIMIZE_V0_ACCESS_BY_EMAIL || ""
).split(",");

function renderAccordian(
  element: any,
  ids: any[],
  expanded: any[],
  setExpanded: any,
  active: boolean,
  setActive: any,
  open: boolean,
  setOpen: any,
  disabled: boolean,
  history: any,
  isSuperAdmin: boolean, // returns true if super admin else returns false
  selectedBrand: any,
  user: any,
  isAgencyAdmin: boolean,
  userDetailInfo: any,
  brandRoles: any
) {
  let checkExpanded = expanded && expanded.includes(element.id);
  const items =
    // isSuperAdmin && isAdminRole(brandRoles)
    isAdminRole(brandRoles)
      ? element.items
      : element.items?.filter((el: any) => !el.admin);

  const hideItem = element.hasPermissionsCheckCallback
    ? !element.hasPermissionsCheckCallback?.(brandRoles)
    : false;
  if (hideItem) return null;
  return (
    <ListItem
      className="w-100"
      disablePadding
      sx={{ display: "block" }}
      key={element.id}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 0,
          py: 0,
        }}
        className={`w-100 sidebar-accordian-button ${
          checkExpanded && !open ? "closed-active-item" : ""
        }`}
        disableRipple={true}
        disabled={disabled}
      >
        <Accordion
          className={`side-bar-acordian ${
            element.parentClass ? element.parentClass : ""
          }`}
          disableGutters={true}
          sx={{ boxShadow: "none", border: "none", p: 0, width: "100%" }}
          expanded={checkExpanded}
        >
          <AccordionSummary
            expandIcon={element.items && open ? <ArrowDropDownIcon /> : null}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              border: "none",
              px: 2,
            }}
            className={`side-bar-accordian w-100 ${
              checkExpanded && active
                ? `active-nav-item ${element.pathname ? "bg-active-tab" : ""}`
                : ``
            }`}
            disableRipple={true}
            onClick={() => {
              if (checkExpanded) {
                let deleteIndex = -1;
                const updatedActive = expanded.filter((id, index) => {
                  if (id === element.id) {
                    deleteIndex = index;
                  }
                  return deleteIndex < index;
                });
                setExpanded(updatedActive);
              } else {
                setExpanded([...ids, element.id]);
              }
              if (element.pathname) {
                setActive(true);
                setOpen(false);
                if (
                  element?.id ===
                    pathValues["targeting-ai-audience-manager"][1] ||
                  element?.id === pathValues["targeting-ai-playground-beta"][1]
                ) {
                  mixPanelAdEvent("Playground Clicked", {
                    module_name: "TAI Audience Manager",
                    username: userDetailInfo?.name,
                    brand_id: selectedBrand?.id,
                    brand_name: selectedBrand?.name,
                    brand_type: selectedBrand?.brandType,
                    agency_name: selectedBrand?.agency_name,
                    agency_id: selectedBrand?.agencyId,
                    email: userDetailInfo?.email,
                  });
                }
                if (element?.isPixis2Url) {
                  mixPanelAdEvent("Pixis+ Section Clicked", {
                    module_name: element?.module_name,
                    sub_module_name: element?.name,
                    brand_name: selectedBrand?.name,
                    brand_type: selectedBrand?.brandType,
                    agency_name: selectedBrand?.agency_name,
                    agency_id: selectedBrand?.agencyId,
                    email: user?.email,
                    brand_id: selectedBrand?.id,
                  });
                  window.open(
                    `${REACT_APP_BASE_URL_PIXIS2}${element.pathname}?brandId=${
                      selectedBrand?.id
                    }${
                      getSearchParams("group_id")
                        ? "&aiGroupId=" + getSearchParams("group_id")
                        : ""
                    }`,
                    "_blank"
                  );
                } else {
                  history.push(`${element.pathname}${history.location.search}`);
                }
              }
            }}
          >
            {(open || element.id.length === 1) && (
              <ListItemIcon
                sx={{
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  height: "2rem",
                  width: "2rem",
                  maxHeight: "2rem",
                  maxWidth: "2rem",
                }}
                className={`d-flex align-items-center ${open ? "" : "w-100 "}`}
              >
                {element.icon}
              </ListItemIcon>
            )}
            {open && (
              <ListItemText
                primary={element.title}
                sx={{ opacity: open ? 1 : 0 }}
              />
            )}
          </AccordionSummary>

          {open && element.items && (
            <AccordionDetails className="pt-0 pr-0 side-bar-accordian">
              <List>
                {items.map((item: any) =>
                  renderAccordian(
                    item,
                    [...ids, element.id],
                    expanded,
                    setExpanded,
                    active,
                    setActive,
                    open,
                    setOpen,
                    disabled,
                    history,
                    isSuperAdmin,
                    selectedBrand,
                    user,
                    isAgencyAdmin,
                    userDetailInfo,
                    brandRoles
                  )
                )}
              </List>
            </AccordionDetails>
          )}
        </Accordion>
      </ListItemButton>
    </ListItem>
  );
}

function SideBarAccordian({
  open,
  setOpen,
  disabled = false,
  isSuperAdmin,
  user,
  isAgencyAdmin,
}: {
  open: boolean;
  setOpen: any;
  disabled: boolean;
  isSuperAdmin: any;
  user: any;
  isAgencyAdmin: boolean | any;
}) {
  const [expanded, setExpanded] = useState(null) as any;
  const [active, setActive] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand, brandRoles } = adaccount;
  const userDetailInfo = useSelector(
    (state: any) => state.userInfoReducer.userInfo
  );
  const { agencyAssets, loadingAgencyAssets } = useSelector(
    (state: any) => state.common
  );

  const onLocationChange = useCallback(
    (key) => {
      if (key === "") {
        setExpanded(pathValues.actionImpactAnalysis);
        setActive(true);
        return;
      }
      if (!disabled && pathValues[key]) {
        setExpanded(pathValues[key]);
        setActive(true);
      } else {
        setExpanded(null);
        setActive(false);
      }
    },
    [disabled]
  );

  useEffect(() => {
    const unListenHistory = history.listen((location) => {
      if (!location.pathname.includes("/group-settings")) {
        dispatch(setSelectedOptimizer(null));
      }
      onLocationChange(location.pathname.substring(1));
    });
    return () => {
      unListenHistory();
    };
  }, []);
  let location = useLocation();

  useEffect(() => {
    onLocationChange(location.pathname.substring(1));
  }, [open]);

  return (
    <Box
      className="side-bar-accordian-container"
      sx={{
        maxHeight: "55%",
        overflow: "auto",
      }}
    >
      <List>
        {tabs(agencyAssets, loadingAgencyAssets).map((element: any) =>
          renderAccordian(
            element,
            [],
            disabled ? null : expanded,
            setExpanded,
            active,
            setActive,
            open,
            setOpen,
            disabled,
            history,
            isSuperAdmin,
            selectedBrand,
            user,
            isAgencyAdmin,
            userDetailInfo,
            brandRoles
          )
        )}
      </List>
    </Box>
  );
}

export default SideBarAccordian;
