import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { getRoundedValue } from "utils/commonFunctions";
import Configure from "./Configure";
import GreyLoader from "../assets/gifs/GreyLoader.gif";
import { setSelectedOptimizer } from "store/actions/SmbDashboard/common";
import { PARENT_PLATFORMS_MAPPER } from "../constants";
import { useSelector } from "react-redux";
import { getPlatformNameSmb } from "../utils";
import { PLATFORMS_MAPPER } from "../constants/index";
import {
  getActualSpendsAndBudgetDeviationColor,
  getDeviationArrow,
} from "../utils";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Item = styled(Paper)(() => ({
  backgroundColor: "#fff",
  width: "100%",
  textAlign: "center",
  boxShadow: "none",
  margin: "2px",
  padding: "2px",
  fontSize: "16px",
  fontWeight: "500",
  alignItems: "flex-start",
}));

function PlatformRow({
  platform,
  metrics,
  last,
  currency,
  groupId,
  currentOptimizerDetails,
}: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const brandId = params.get("brand_id");

  const commonState = useSelector((state: any) => state.smb.common);

  // platform Id to Platform Name mapping
  const platforms = commonState?.platforms;

  const parentPlatformId =
    platforms?.platforms[platform?.platformId]?.parentPlatformId;

  const handleClick = (adAccountId: any, optimizerId?: any) => {
    dispatch(
      setSelectedOptimizer({
        groupId,
        optimizerId,
        brandId,
        adAccountId,
        platform: {
          platformId: platform?.platformId,
          platformType: getPlatformNameSmb(
            platforms?.platforms[platform?.platformId]?.type
          ),
        },
      })
    );
    history.push(`/group-settings?group_id=${groupId}&brand_id=${brandId}`);
  };

  return (
    <>
      <Item key={platform?.id}>
        <Stack
          direction="row"
          divider={
            <Divider
              orientation="vertical"
              flexItem
              style={{ height: "38px", alignSelf: "center" }}
            />
          }
          spacing={1}
          sx={{
            overflowX: {
              xs: "scroll",
              sm: "scroll",
              md: "none",
            },
          }}
          style={
            !last
              ? { borderBottom: "1px solid #F0F0F0", width: "100%" }
              : { width: "100%" }
          }
        >
          <Item
            sx={{
              // width: "14.3%",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "20px",
              color: "#333333",
              display: "flex",
              alignItems: "center",
              margin: 0,
              minWidth: {
                xs: "30%",
                sm: "30%",
                md: "20%",
              },
              maxWidth: {
                xs: "30%",
                sm: "30%",
                md: "20%",
              },
            }}
          >
            <Box
              sx={{
                "& svg": {
                  height: "3rem",
                  width: "3rem",
                },
              }}
            >
              {platforms &&
                platforms.parentPlatforms &&
                PARENT_PLATFORMS_MAPPER[
                  platforms.parentPlatforms[parentPlatformId]
                ]}
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "inherit",
                  textAlign: "left",
                  lineHeight: "1",
                  mb: 0.5,
                }}
                title={platform?.adAccountId}
              >
                <Typography
                  variant="caption"
                  color="#464646"
                  noWrap
                  width="100%"
                >
                  {platform?.adAccountId}
                </Typography>
                {platform?.platformId &&
                  platforms?.platforms[platform?.platformId] &&
                  PLATFORMS_MAPPER[
                    platforms?.platforms[platform?.platformId].type
                  ] &&
                  PLATFORMS_MAPPER[
                    platforms?.platforms[platform?.platformId].type
                  ].shortName !== "" &&
                  platforms?.parentPlatforms[parentPlatformId] === "GOOGLE" && (
                    <Chip
                      sx={{
                        fontSize: "0.75rem",
                        borderRadius: "0.5rem",
                        height: "fit-content",
                        background: "#F3F3F3 !important",
                        fontWeight: 400,
                        p: 1,
                        ml: 1,
                        "& .MuiChip-label": {
                          p: 0,
                        },
                      }}
                      label={
                        PLATFORMS_MAPPER[
                          platforms.platforms[platform?.platformId].type
                        ].shortName
                      }
                    />
                  )}
              </Box>
              <Typography
                variant="subtitle1"
                color="#333"
                component="div"
                title={platform?.adAccountName}
                noWrap
                textAlign="left"
                lineHeight="1.2"
              >
                {platform?.adAccountName}
              </Typography>
            </Box>
          </Item>
          <Item sx={{ display: "flex", justifyContent: "center" }}>
            <Configure
              value={platform?.models?.length}
              label="Active Models"
              isActive={platform?.models?.length > 0}
              isConfigurable={true}
              onClick={() => handleClick(platform?.adAccountId)}
            />
          </Item>
          <Item sx={{ display: "flex", justifyContent: "center" }}>
            <Configure
              value={currentOptimizerDetails?.pbcActive ? "Active" : "Inactive"}
              label="PBC"
              isActive={currentOptimizerDetails?.pbcActive}
              isConfigurable={true}
              onClick={() => handleClick(platform?.adAccountId, platform?.id)}
            />
          </Item>
          {platform?.type === "TikTok" ? (
            <>
              <Item>--</Item>
              <Item>--</Item>
              <Item>--</Item>
            </>
          ) : (
            <>
              {metrics.isLoading ? (
                <Item>
                  <img
                    src={GreyLoader}
                    alt="Loading"
                    style={{ height: "4rem", width: "auto" }}
                  />
                </Item>
              ) : (
                <Item
                  sx={{
                    minWidth: {
                      xs: "15%",
                      sm: "15%",
                      md: "10%",
                    },
                    maxWidth: {
                      xs: "15%",
                      sm: "15%",
                      md: "10%",
                    },
                  }}
                >
                  <Item title={currency + metrics?.spends.actual}>
                    {currency}
                    {metrics?.spends
                      ? getRoundedValue(metrics?.spends.actual)
                      : "0.00"}
                  </Item>
                  <Typography variant="caption" color="#464646" component="div">
                    Spends
                  </Typography>
                </Item>
              )}

              {metrics.isLoading ? (
                <Item>
                  <img
                    src={GreyLoader}
                    alt="Loading"
                    style={{ height: "4rem", width: "auto" }}
                  />
                </Item>
              ) : (
                metrics?.metrics?.map((metric: any) => {
                  return (
                    <Item
                      key={metric.subOptimizerId + metric.name}
                      sx={{
                        minWidth: {
                          xs: "15%",
                          sm: "15%",
                          md: "10%",
                        },
                        maxWidth: {
                          xs: "15%",
                          sm: "15%",
                          md: "10%",
                        },
                      }}
                    >
                      <Item>
                        {`${
                          typeof metric.actual === "number"
                            ? `${getRoundedValue(metric.actual)}`
                            : "0.00"
                        }`}
                      </Item>
                      <Typography
                        variant="caption"
                        color="#464646"
                        component="div"
                      >
                        {metric.name && metric.name !== ""
                          ? metric.name
                          : "Results"}
                      </Typography>
                    </Item>
                  );
                })
              )}
              {metrics.isLoading ? (
                <Item>
                  <img
                    src={GreyLoader}
                    alt="Loading"
                    style={{ height: "4rem", width: "auto" }}
                  />
                </Item>
              ) : (
                metrics?.metrics?.map((metric: any) => {
                  return (
                    <Item
                      sx={{
                        minWidth: {
                          xs: "15%",
                          sm: "15%",
                          md: "10%",
                        },
                        maxWidth: {
                          xs: "15%",
                          sm: "15%",
                          md: "10%",
                        },
                      }}
                      key={metric.subOptimizerId + metric.name}
                    >
                      <Item
                        title={currency + metric.actualCost}
                        sx={{ display: "flex", gap: "5px", justifyContent:"center" }}
                      >
                        {currency}
                        {typeof metric.actualCost === "number"
                          ? getRoundedValue(metric.actualCost || 0)
                          : "0.00"}
                        {metric?.deviation ? (
                          <Box sx={{ display: "flex" }}>
                            <>
                              {getDeviationArrow(metric?.deviation) ===
                              "up" ? (
                                <ArrowUpwardIcon
                                  sx={{
                                    fontSize: "1rem",
                                    color:
                                      getActualSpendsAndBudgetDeviationColor(
                                        metric?.deviation || 0
                                      ),
                                  }}
                                />
                              ) : (
                                <ArrowDownwardIcon
                                  sx={{
                                    fontSize: "1rem",
                                    color:
                                      getActualSpendsAndBudgetDeviationColor(
                                        metric?.deviation || 0
                                      ),
                                  }}
                                />
                              )}
                              <Typography
                                fontSize="0.625rem"
                                sx={{
                                  color: getActualSpendsAndBudgetDeviationColor(
                                    metric?.deviation || 0 // ADD Deviation key here
                                  ),
                                }}
                              >
                                {/* ADD Deviation key here*/}
                                {getRoundedValue(metric?.deviation || 0)}%
                              </Typography>
                            </>
                          </Box>
                        ) : <></>}
                      </Item>
                      <Typography
                        variant="caption"
                        color="#464646"
                        component="div"
                      >
                        {`Actual Cost Per ${
                          metric.name && metric.name !== ""
                            ? metric.name
                            : "Result"
                        }`}
                      </Typography>
                    </Item>
                  );
                })
              )}

              {metrics.isLoading ? (
                <Item>
                  <img
                    src={GreyLoader}
                    alt="Loading"
                    style={{ height: "4rem", width: "auto" }}
                  />
                </Item>
              ) : (
                <Item
                  sx={{
                    minWidth: {
                      xs: "15%",
                      sm: "15%",
                      md: "10%",
                    },
                    maxWidth: {
                      xs: "15%",
                      sm: "15%",
                      md: "10%",
                    },
                  }}
                >
                  <Item>
                    {/* {console.log(getRoundedValue(metrics?.roas.target),">>>>>>>>LL: metrics", metrics.roas.target)} */}
                    {metrics?.roas
                      ? getRoundedValue(metrics?.roas.target) ?? "-"
                      : "0.00"}
                  </Item>
                  <Typography variant="caption" color="#464646" component="div">
                    Target ROAS
                  </Typography>
                </Item>
              )}
            </>
          )}
          {metrics.isLoading ? (
            <Item>
              <img
                src={GreyLoader}
                alt="Loading"
                style={{ height: "4rem", width: "auto" }}
              />
            </Item>
          ) : (
            <Item
              sx={{
                minWidth: {
                  xs: "15%",
                  sm: "15%",
                  md: "10%",
                },
                maxWidth: {
                  xs: "15%",
                  sm: "15%",
                  md: "10%",
                },
              }}
            >
              <Item sx={{ display: "flex", gap: "5px", justifyContent:"center" }}>
                {metrics?.roas
                  ? getRoundedValue(metrics?.roas.actual) || "-"
                  : "0.00"}
                   {metrics?.roas.deviation ? (
                <Box sx={{ display: "flex" }}>
                    <>
                      {getDeviationArrow(metrics?.roas.deviation) === "up" ? (
                        <ArrowUpwardIcon
                          sx={{
                            fontSize: "1rem",
                            color: getActualSpendsAndBudgetDeviationColor(
                              metrics?.roas.deviation || 0
                            ),
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            fontSize: "1rem",
                            color: getActualSpendsAndBudgetDeviationColor(
                              metrics?.roas.deviation || 0
                            ),
                          }}
                        />
                      )}
                      <Typography
                        fontSize="0.625rem"
                        sx={{
                          color: getActualSpendsAndBudgetDeviationColor(
                            metrics?.roas.deviation || 0 // ADD Deviation key here
                          ),
                        }}
                      >
                        {/* ADD Deviation key here*/}
                        {getRoundedValue(metrics?.roas.deviation || 0)}%
                      </Typography>
                    </>
                </Box>
                ) : <></>}
              </Item>
              <Typography variant="caption" color="#464646" component="div">
                Actual ROAS
              </Typography>
            </Item>
          )}
        </Stack>
      </Item>
    </>
  );
}

// export const amountValueForBrandSumary = (num:any):any => {
//   if (num < 1) {
//     return getRoundedValue(num);
//   } else if (num < 1000000) return `${num.toFixed(2)}`;
//   // else if (num >= 1000 && num < 1000000)
//   //   return `${parseInt(num / 1000)}.${parseInt((num % 1000) / 100)}K`;
//   else if (num >= 1000000 && num < 1000000000) {
//     return `${+num / 1000000}.${((+num % 1000000) / 10000)}M`;
//   } else if (num >= 1000000000)
//     return `${(+num / 1000000000)}.${((+num % 1000000000) / 10000000)}B`;
//   return num;
// };



export default PlatformRow;
