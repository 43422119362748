import httpService from "HttpService/HttpService";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  setAdAccountsLog,
  setAdSetsFBLog,
  setAdsFBLog,
  setFbActivityLogs,
} from "store/actions/CreativeAI_Revamp/LogScreen/logscreen.actions";
import {
  GET_ADACCOUNTS_LOG,
  GET_ADSET_LOG,
  GET_ADS_LOG,
  GET_AD_DETAILS,
  GET_FB_ACTIVITY_LOGS,
} from "store/types/CreativeAI_Revamp/LogScreen/logscreen.types";
import { LooseObject } from "types/GlobalTypes";
import { BASE_URL } from "utils/path";

const appendParam = (params: LooseObject) => {
  return (
    "?" +
    Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")
  );
};

function* getFbActivityLogs({ payload }: any): any {
  const { params, callback } = payload;

  try {
    let url = `/v1/api/creative-variants/get-publish-variant`;
    const {to, from, limit, page}=params
    const tempParams={...params}
    delete tempParams.to
    delete tempParams.from
    delete tempParams.limit
    delete tempParams.page
    const formattedPayload={ to, from, limit, page, filters:{...tempParams}}
    const response = yield call(httpService.post, BASE_URL, url,'',formattedPayload);
    if (response.data.success) {
      if (callback) {
        callback(response.data.data.url);
      } else {
        yield put(
          setFbActivityLogs({
            isFailed: false,
            isLoading: false,
            data: response.data.data,
          })
        );
      }
    } else {
      yield put(
        setFbActivityLogs({
          isFailed: true,
          isLoading: false,
          data: [],
        })
      );
    }
  } catch (error) {
    yield put(
      setFbActivityLogs({
        isFailed: true,
        isLoading: false,
        data: [],
      })
    );
  }
}

// TBO - because we already had this in home page

// will remove at the time of refactoring
function* fetchAdAccounts({ payload }: any): any {
  const { aiGroupId, params } = payload;

  try {
    let url = `/v2/api/ai-groups/${aiGroupId}/ad-accounts`;
    if (params) {
      url += appendParam(params);
    }
    const response = yield call(httpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put(
        setAdAccountsLog({
          isFailed: false,
          isLoading: false,
          data: response.data.data.adAccounts,
        })
      );
    } else {
      yield put(
        setAdAccountsLog({
          isFailed: true,
          isLoading: false,
          data: [],
        })
      );
    }
  } catch (error) {
    yield put(
      setAdAccountsLog({
        isFailed: true,
        isLoading: false,
        data: [],
      })
    );
  }
}

// will remove at the time of refactoring

function* fetchAdSets({ payload }: any): any {
  const { elementId, params } = payload;

  try {
    let url = `/v1/api/adset-creative-ai/elements/${elementId}/adsets`;
    if (params) {
      url += appendParam(params);
    }
    const response = yield call(httpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put(
        setAdSetsFBLog({
          isFailed: false,
          isLoading: false,
          data: response.data.data.adsets,
        })
      );
    } else {
      yield put(
        setAdSetsFBLog({
          isFailed: true,
          isLoading: false,
          data: [],
        })
      );
    }
  } catch (error) {
    yield put(
      setAdSetsFBLog({
        isFailed: true,
        isLoading: false,
        data: [],
      })
    );
  }
}

function* fetchAds({ payload }: any): any {
  const { adSetId, params } = payload;
	const { adsetIds, ...restParams} = params || {}

  try {
    let url = `/v1/api/ad/get-ads`;
    if (Object.keys(restParams).length) {
      url += appendParam(restParams);
    }
    const response = yield call(httpService.post, BASE_URL, url, "", {
			...(adsetIds?.length ? {adsetIds}: {}),
		});
    if (response.data.success) {
      yield put(
        setAdsFBLog({
          isFailed: false,
          isLoading: false,
          data: response.data.data,
        })
      );
    } else {
      yield put(
        setAdsFBLog({
          isFailed: true,
          isLoading: false,
          data: [],
        })
      );
    }
  } catch (error) {
    yield put(
      setAdsFBLog({
        isFailed: true,
        isLoading: false,
        data: [],
      })
    );
  }
}

function* getAdDetails({ payload }: any): any {
  const { adId, params, callback } = payload;

  try {
    let url = `/v1/api/ad/${adId}`;
    if (params) {
      url += appendParam(params);
    }
    const response = yield call(httpService.get, BASE_URL, url);
    if (response.data.success) {
      callback && callback(response.data, false);
    } else {
      callback && callback({}, true);
    }
  } catch (error) {
    callback && callback({}, true);
  }
}

export function* logScreenSagaWatcher() {
  yield takeLatest(GET_FB_ACTIVITY_LOGS, getFbActivityLogs);
  yield takeLatest(GET_ADACCOUNTS_LOG, fetchAdAccounts);
  yield takeLatest(GET_ADSET_LOG, fetchAdSets);
  yield takeLatest(GET_ADS_LOG, fetchAds);
  yield takeLatest(GET_AD_DETAILS, getAdDetails);
}
