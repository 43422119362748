export const GET_CLIENT_ID_FOR_SPECIFIC_PLATFORM =
  "GET_CLIENT_ID_FOR_SPECIFIC_PLATFORM";
export const GET_ELIGIBILITY_OF_ACCOUNTS = "GET_ELIGIBILITY_OF_ACCOUNTS";
export const GET_TOKEN_STATS_SPECIFIC_FOR_AD_ACCOUNTS =
  "GET_TOKEN_STATS_SPECIFIC_FOR_AD_ACCOUNTS";
export const GET_TOKEN_STATUS_BASED_ON_BRAND_ID =
  "GET_TOKEN_STATUS_BASED_ON_BRAND_ID";
export const GET_PROPERTIES_OF_GA_ACCOUNT = "GET_PROPERTIES_OF_GA_ACCOUNT";
export const GET_CONFIGS_OF_GA_ACCOUNT = "GET_CONFIGS_OF_GA_ACCOUNT";
export const GET_CONFIG_DETAILS_OF_THIRD_PARTY_ACCOUNT =
  "GET_CONFIG_DETAILS_OF_THIRD_PARTY_ACCOUNT";
export const GENERATE_ACCESS_AND_REFRESH_TOKEN =
  "GENERATE_ACCESS_AND_REFRESH_TOKEN";
export const LINK_AD_ACCOUNTS_TO_BRANDS = "LINK_AD_ACCOUNTS_TO_BRANDS";
export const ENABLE_MASTER_ACCESS_TO_ACCOUNTS =
  "ENABLE_MASTER_ACCESS_TO_ACCOUNTS";
export const UPDATE_PRIMARY_TOKEN_FOR_THIRD_PARTY =
  "UPDATE_PRIMARY_TOKEN_FOR_THIRD_PARTY";
export const UPDATE_CONFIG_DETAILS_FROM_ACCOUNT_ID =
  "UPDATE_CONFIG_DETAILS_FROM_ACCOUNT_ID";
export const DELETE_TOKEN_FOR_THIRD_PARTY = "DELETE_TOKEN_FOR_THIRD_PARTY";
export const GET_THIRD_PARTY_ACCOUNTS = "GET_THIRD_PARTY_ACCOUNTS";
export const SET_THIRD_PARTY_ACCOUNTS = "SET_THIRD_PARTY_ACCOUNTS";
export const SET_PROPERTIES_OF_GA_ACCOUNT = "SET_PROPERTIES_OF_GA_ACCOUNT";
export const GET_G_SHEETS_DATA_SOURCE = "GET_G_SHEETS_DATA_SOURCE";
export const GET_G_SHEET_URL_VALIDATION = "GET_G_SHEET_URL_VALIDATION";
export const GET_G_SHEET_SYNC_HISTORY = "GET_G_SHEET_SYNC_HISTORY";
export const GET_G_SHEET_TIMEZONE = "GET_G_SHEET_TIMEZONE";
export const GET_G_SHEET_RESYNC_DATA = "GET_G_SHEET_RESYNC_DATA";
export const ADD_G_SHEET_CONFIGURATION = "ADD_G_SHEET_CONFIGURATION";
export const UPDATE_G_SHEET_CONFIGURATION = "UPDATE_G_SHEET_CONFIGURATION";
export const SET_ERROR = "SET_ERROR";

export type IGetClientIdForSpecificPlatform = {
  platform: string;
  auth: string;
};
export type IGetEligibilityOfAccounts = {
  tokenUID: string;
  brandId: string;
  platform: string;
};
export type IGetTokenStatsSpecificForAdAccounts = {
  accountId: string;
  auth: string;
};
export type IGetTokenStatusBasedOnBrandId = {
  brandId: string;
  auth: string;
};
export type IGetPropertiesOfGAAccount = {
  gaAccountId: string;
};
export type IGetConfigsOfGAAccount = {
  gaAccountId: string;
  propertyId: string;
};
export type IGetConfigDetailsOfThirdParty = {
  accountId: string;
  brandId: string;
  auth: string;
};
export type IGenerateAccessAndRefreshToken = {
  params: any;
};
export type ILinkAdAccountsToBrands = {
  params: any;
  tokenUID: string;
};
export type IThirdPartyAccounts = {
  brandId: any;
  callback: CallableFunction;
};
export type IEnableMasterAccessToAccounts = {
  params: any;
  tokenUID: string;
};
export type IUpdatePrimaryTokenForThirdParty = {
  params: any;
  tokenUID: string;
  accountId: string;
};
export type IUpdateConfigDetailsFromAccountId = {
  params: any;
  brandId: string;
  accountId?: string;
  gaAccountId: string;
};
export type IDeleteToken = {
  params: any;
  tokenUID: string;
  accountId: string;
};

export type IGSheetsDataSource = {
  brandId: string;
};

export type IGSheetURLValidation = {
  brandId: string;
  sheetURL: string;
};

export type IGSheetSyncHistory = {
  brandId: string;
  sheetId: string;
  page: number;
  pageSize: number;
  sheetName: string;
};

export type IGSheetTimezone = {
  brandId: string;
};

export type IGSheetResyncData = {
  brandId: string;
  sheetId: string;
  sheetName: string;
};

export type IAddGSheetConfiguration = {
  sheetUrl: string;
  sheetName: string;
  dataSource: string;
  scheduleDetails?:
    | {
        date: any;
        timeZone: string;
        timePeriod: number;
        frequency: number;
      }
    | {};
};

export type IUpdateGSheetConfiguration = {
  sheetName: string;
  scheduleDetails?:
    | {
        date: any;
        timeZone: string;
        timePeriod: number;
        frequency: number;
      }
    | {};
  disable: boolean;
};

export type GetClientIdForSpecificPlatform = {
  type: typeof GET_CLIENT_ID_FOR_SPECIFIC_PLATFORM;
  payload: IGetClientIdForSpecificPlatform;
  callback?: Function;
};

export type GetEligibilityOfAccounts = {
  type: typeof GET_ELIGIBILITY_OF_ACCOUNTS;
  payload: IGetEligibilityOfAccounts;
  callback?: Function;
};

export type GetTokenStatsSpecificForAdAccounts = {
  type: typeof GET_TOKEN_STATS_SPECIFIC_FOR_AD_ACCOUNTS;
  payload: IGetTokenStatsSpecificForAdAccounts;
  callback?: Function;
};

export type GetTokenStatusBasedOnBrandId = {
  type: typeof GET_TOKEN_STATUS_BASED_ON_BRAND_ID;
  payload: IGetTokenStatusBasedOnBrandId;
  callback?: Function;
};

export type GetPropertiesOfGAAccount = {
  type: typeof GET_PROPERTIES_OF_GA_ACCOUNT;
  payload: IGetPropertiesOfGAAccount;
  callback?: Function;
};

export type SetPropertiesOfGAAccount = {
  type: typeof SET_PROPERTIES_OF_GA_ACCOUNT;
  payload: any;
};

export type SetError = {
  type: typeof SET_ERROR;
  payload: any;
};

export type GetConfigsOfGAAccount = {
  type: typeof GET_CONFIGS_OF_GA_ACCOUNT;
  payload: IGetConfigsOfGAAccount;
  callback?: Function;
};

export type GetConfigDetailsOfThirdParty = {
  type: typeof GET_CONFIG_DETAILS_OF_THIRD_PARTY_ACCOUNT;
  payload: IGetConfigDetailsOfThirdParty;
  callback?: Function;
};

export type GenerateAccessAndRefreshToken = {
  type: typeof GENERATE_ACCESS_AND_REFRESH_TOKEN;
  payload: IGenerateAccessAndRefreshToken;
  callback?: Function;
};

export type LinkAdAccountsToBrands = {
  type: typeof LINK_AD_ACCOUNTS_TO_BRANDS;
  payload: ILinkAdAccountsToBrands;
  callback?: Function;
};

export type GetThirdPartyAccounts = {
  type: typeof GET_THIRD_PARTY_ACCOUNTS;
  payload: IThirdPartyAccounts;
  callback?: Function;
};

export type EnableMasterAccessToAccounts = {
  type: typeof ENABLE_MASTER_ACCESS_TO_ACCOUNTS;
  payload: IEnableMasterAccessToAccounts;
  callback?: Function;
};

export type UpdatePrimaryTokenForThirdParty = {
  type: typeof UPDATE_PRIMARY_TOKEN_FOR_THIRD_PARTY;
  payload: IUpdatePrimaryTokenForThirdParty;
  callback?: Function;
};

export type UpdateConfigDetailsFromAccountId = {
  type: typeof UPDATE_CONFIG_DETAILS_FROM_ACCOUNT_ID;
  queryParams: any;
  payload: IUpdateConfigDetailsFromAccountId;
  callback?: Function;
};

export type DeleteToken = {
  type: typeof DELETE_TOKEN_FOR_THIRD_PARTY;
  payload: IDeleteToken;
  callback?: Function;
};

export type UUIDTOKEN = {
  type: string;
  payload: any;
  callback?: Function;
};

export type GetGSheetsDataSource = {
  type: typeof GET_G_SHEETS_DATA_SOURCE;
  payload: IGSheetsDataSource;
  callback?: Function;
};

export type GetGSheetURLValidation = {
  type: typeof GET_G_SHEET_URL_VALIDATION;
  payload: IGSheetURLValidation;
  callback?: Function;
};

export type GetGSheetSyncHistory = {
  type: typeof GET_G_SHEET_SYNC_HISTORY;
  payload: IGSheetSyncHistory;
  callback?: Function;
};

export type GetGSheetTimezone = {
  type: typeof GET_G_SHEET_TIMEZONE;
  payload: IGSheetTimezone;
  callback?: Function;
};

export type GetGResyncData = {
  type: typeof GET_G_SHEET_RESYNC_DATA;
  payload: IGSheetResyncData;
  callback?: Function;
};

export type AddGSheetConfiguration = {
  type: typeof ADD_G_SHEET_CONFIGURATION;
  queryParams: any;
  payload: IAddGSheetConfiguration;
  callback?: Function;
};

export type UpdateGSheetConfiguration = {
  type: typeof UPDATE_G_SHEET_CONFIGURATION;
  queryParams: any;
  payload: IUpdateGSheetConfiguration;
  callback?: Function;
};
