import { SET_TOKEN_STATS, AccountsManagerState } from '../types/accountsManager'

const initailState: AccountsManagerState = {
  isAppDisabled: false,
  tokenStats: []
}

function getTokenCount(payload: any) {
  if (payload.length > 0) {
    return false
  } else {
    return true
  }
}

export default function accountsManager(state = initailState, action: any) {
  switch (action.type) {
    case SET_TOKEN_STATS:
      return {
        ...state,
        tokenStats: action.payload,
        isAppDisabled: getTokenCount(action.payload)
      }
    default:
      return state
  }
}
