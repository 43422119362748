import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateVariantsState } from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import Loader from "../../../../../common/Loader";
import { Button } from "../../../../../shared";
import { StyledHeading4Extended } from "../../../../../shared/Typography/Heading/styled";
import { StyledParagraph1 } from "../../../../../shared/Typography/Paragraph/styled";
import {
  StyledDisclaimerText,
  StyledErrorSubTitle,
  StyledHeadTitle,
  StyledPreviewDiv,
  StyledPreviewHeadingCard,
} from "./styled";
import { ReactComponent as ReloadIcon } from "../../../../../../../src/assets/Reload-icon.svg";
import { ReactComponent as DangerCircle } from "../../../../../../../src/assets/svg/Danger-Circle.svg";
import { RefreshBtn } from "./styled";
import { ReactComponent as RefreshIcon } from "../../../../../../assets/svg/refresh.svg";
import InHousePreview from "components/CreativeAI/Facebook/shared/InHousePreview/InHousePreview";
import { InitialMediaStudioType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import {
  imageMediaProcessingPayloadMapping,
  videoMediaProcessingPayloadMapping,
} from "../MediaStudio/shared/mediaCommon.utils";
import { postMediaStudioProcessing } from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import { checkAdType } from "components/CreativeAI/Facebook/utils/common.utils";
import { SuccessLoader } from "../MediaStudio/shared/Loader/Loader";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
export interface PreviewProps {
  value: string;
  textFields: any;
  showRefreshBtn: boolean;
  isRefreshLoading?: boolean;
  toggleDirty: any;
  mixPanelCreativeStudioRenderUpdate?: any;
}

export default function Preview({
  value,
  textFields,
  showRefreshBtn,
  isRefreshLoading,
  toggleDirty,
  mixPanelCreativeStudioRenderUpdate,
}: PreviewProps) {
  const {
    extraFields: { selectedVariantIndex },
    recommendationSelectionState,
    isGeneratingPreview,
    recommendationMetaData,
    createSpec,
    selectedAd,
    variantList,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );

  const {
    imageContext,
    isImageMediaStudioProcessing,
    videoContext,
    isVideoMediaStudioProcessing,
    isVideoMediaStudioProcessingFailure,
    isImageMediaStudioProcessingFailure,
  } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );
  const [mediaUrl, setMediaUrl] = useState("");

  const currentPage =
    selectedVariantIndex !== undefined && selectedVariantIndex + 1;

  const { adAdditionalInfo } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );

  const dispatch = useDispatch();

  const onRefreshVideo = () => {
    const variations = videoMediaProcessingPayloadMapping(
      recommendationMetaData.video.data[recommendationSelectionState.video].data
    );
    mixPanelCreativeStudioRenderUpdate && mixPanelCreativeStudioRenderUpdate();
    dispatch(
      postMediaStudioProcessing({
        adId: selectedAd?.id,
        failureReduxType: "MEDIA_STUDIO_VIDEO_PROCESSING_FAILURE",
        reduxActionType: "MEDIA_STUDIO_VIDEO_PROCESSING_LOADER",
        reduxStoreType: "POST_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO",
        variations,
      })
    );
    toggleDirty(false);
  };

  useEffect(() => {
    const item = variantList[currentPage - 1];
    // edit case
    if (
      (item.id && item?.CreativeAiData?.mediaFileId) ||
      imageContext?.url ||
      videoContext?.url
    ) {
      if (checkAdType(createSpec) === "link_data") {
        setMediaUrl(imageContext?.url ?? "");
      } else {
        setMediaUrl(videoContext?.url ?? "");
      }
    } else {
      if (checkAdType(createSpec) === "link_data") {
        setMediaUrl(adAdditionalInfo[selectedAd?.id]?.image_url ?? "");
      } else {
        setMediaUrl(adAdditionalInfo[selectedAd?.id]?.videoUrl ?? "");
      }
    }
  }, [selectedVariantIndex, videoContext?.url, imageContext?.url]);

  useEffect(() => {
    toggleDirty(false);
  }, [selectedVariantIndex]);

  const adType = checkAdType(createSpec);

  return (
    <StyledPreviewDiv id="preview">
      <div
        id="greybox"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <StyledPreviewHeadingCard>
          <StyledHeading4Extended style={{ backgroundColor: "white" }}>
            Variant #{currentPage}
          </StyledHeading4Extended>
          <StyledParagraph1 style={{ marginBottom: "0px" }}>
            {textFields[0].value}
          </StyledParagraph1>
        </StyledPreviewHeadingCard>

        {(adType === "video_data" && isVideoMediaStudioProcessingFailure) ||
        (adType === "link_data" && isImageMediaStudioProcessingFailure) ? (
          <PreviewFailed />
        ) : (adType === "link_data" && isImageMediaStudioProcessing) ||
          (adType === "video_data" && isVideoMediaStudioProcessing) ? (
          <Box minHeight="444px" maxHeight="444px">
            <SuccessLoader />
          </Box>
        ) : (
          <InHousePreview
            displayURL={
              textFields[1].value.length
                ? textFields[1].value
                : textFields[2].value
            }
            CTA_button={
              createSpec?.call_to_action_type ??
              createSpec?.asset_feed_spec?.call_to_action_types?.[0]
            }
            previewType={adType === "link_data" ? "image" : "video"}
            profileAvatar={adAdditionalInfo[selectedAd?.id]?.avatarUrl ?? ""}
            profileName={adAdditionalInfo[selectedAd?.id]?.avatarName ?? ""}
            recommendationData={recommendationSelectionState}
            // mediaURL={
            //   (checkAdType(createSpec) === "link_data"
            //     ? imageContext?.url
            //     : videoContext?.url) ?? createSpec?.image_url
            // }
            mediaURL={mediaUrl}
            origin="editor"
          />
        )}
        <Box
          flex={1}
          sx={{
            display: "flex",
            alignItems: "flex-end",
            width: "100%",
            padding: "10px 0px",
            justifyContent: "center",
          }}
        >
          <StyledDisclaimerText>
            <span
              style={{
                color: "#E84B47",
              }}
            >
              *
            </span>
            Disclaimer - Actual Meta preview might differ
          </StyledDisclaimerText>
        </Box>
      </div>

      <RefreshBtn
        variant="contained"
        disableElevation
        show={showRefreshBtn}
        disabled={!!isRefreshLoading}
        onClick={onRefreshVideo}
        startIcon={<RefreshIcon className="mr-2" />}
        classes={{ root: "root" }}
      >
        Render & Update
      </RefreshBtn>
    </StyledPreviewDiv>
  );
}

export const PreviewFailed = () => {
  return (
    <Box
      width="100%"
      // height="50vh"
      minHeight="444px"
      maxHeight="444px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      // marginTop="40%"
    >
      <DangerCircle />
      <StyledHeadTitle>Preview Failed</StyledHeadTitle>
      {/* <StyledErrorSubTitle>
        Kindly refresh to see last preview
      </StyledErrorSubTitle>
      <Button
        buttonVariant="outlined"
        startIcon={<ReloadIcon />}
        onClick={() => {}}
        className="RestartButton"
      >
        <span>Restart</span>
      </Button> */}
    </Box>
  );
};
