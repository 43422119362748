import { FC, useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { SelectChangeEvent, SxProps, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import InfoPopup from "components/AIGroupSettings/AIGroupSettingForm/InfoPopup";

const SelectText: FC<{
  items: any[];
  selectedItem: any;
  setSelectedItem: any;
  size?: "small" | "medium" | undefined;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  valueKey?: string;
  displayKey?: string;
  menuProps?: any;
  IconComponent?: any;
  showIcon?: boolean;
  sources?: any;
  className?: string;
}> = ({
  items,
  selectedItem,
  setSelectedItem,
  size,
  sx,
  valueKey,
  displayKey,
  disabled = false,
  menuProps,
  IconComponent,
  showIcon = false,
  sources,
  className,
}) => {
  const [selectedItemDD, setSelectedItemDD] = useState(selectedItem || "");

  const handleChange = (event: SelectChangeEvent) => {
    const changedItem = valueKey
      ? items.find((item) => item[valueKey] === event.target.value)
      : event.target.value;
    setSelectedItemDD(changedItem);
    setSelectedItem(changedItem);
  };

  useEffect(() => {
    let selectedValue;
    if (valueKey && selectedItem) {
      selectedValue =
        selectedItem && selectedItem[valueKey]
          ? selectedItem[valueKey]
          : items.length > 0
          ? items[0][valueKey]
          : "";
      setSelectedItemDD(selectedValue);
    } else {
      selectedValue = selectedItem || (items.length > 0 ? items[0] : "");
      setSelectedItemDD(selectedValue);
    }
  }, [selectedItem]);

  return (
    <Select
      defaultValue={selectedItemDD}
      value={selectedItemDD}
      onChange={(e) => handleChange(e)}
      size={size}
      disabled={disabled || items.length === 0}
      MenuProps={menuProps}
      IconComponent={IconComponent}
      sx={{
        cursor: "pointer",
        border: "1px solid #DDD !important",
        borderRadius: "8px !important",
        "& legend": { display: "none" },
        "& fieldset": { top: 0, border: "none" },
        "& .MuiSelect-select": {
          fontSize: "0.75rem !important",
          fontFamily: "Inter !important",
          fontWeight: "400 !important",
          border: "none !important",
        },
        "& input": {
          border: "none !important",
          fontSize: "0.75rem",
          fontFamily: "Inter",
          fontWeight: 400,
        },
        "& ul": {
          left: "0px",
        },
        "& svg": {
          color: "#000",
        },
        "& p": {
          fontSize: "0.75rem",
          fontFamily: "Inter",
          fontWeight: 400,
        },
        ...sx,
      }}
      className={`select-options ` + className}
    >
      {items.map((item) => (
        <MenuItem
          title={item?.name?.length >= 15 && item?.name}
          key={valueKey ? item[valueKey] : item}
          sx={{
            "& p": {
              fontSize: "0.75rem",
              fontFamily: "Inter",
              fontWeight: 400,
              display: "flex",
              justifyContent: "space-between",
            },
          }}
          value={valueKey ? item[valueKey] : item}
        >
          <Typography
            noWrap={true}
            title={displayKey ? item[displayKey] : item}
          >
            {displayKey ? item[displayKey] : item}
            {showIcon && item?.isAttribuationMetric ? (
              <InfoPopup
                source={sources.find((each: any) => each.id === item?.sourceId)}
              />
            ) : (
              ""
            )}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectText;
