import { Box } from "@mui/material";
import React from "react";
import {
  StyledPreivewContainer,
  StyledPreviewHeader,
  StyledPreviewIndex,
  StyledPreviewText,
  StyledPreviewDetail,
  StyledPreview,
} from "./styles";

const PreviewDetails: React.FC<{ title: string; data: any }> = ({
  title,
  data,
}): JSX.Element => {
  return (
    <StyledPreviewDetail>
      <StyledPreviewHeader>Existing {title}</StyledPreviewHeader>
      <StyledPreview>
        {data.map((d: string, index: number) => (
          <StyledPreivewContainer>
            <StyledPreviewIndex>
              {`${title.charAt(0).toUpperCase()}${index + 1} : `}
            </StyledPreviewIndex>
            <StyledPreviewText>{d}</StyledPreviewText>
          </StyledPreivewContainer>
        ))}
      </StyledPreview>
    </StyledPreviewDetail>
  );
};

export default PreviewDetails;
