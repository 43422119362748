// import DemoImage from "../../../../../../../src/assets/png/demoImage.png";
import { Loader } from "components/shared";
import { StyledImageContainer, StyledText } from "./styled";

interface RecommendationImageComponentProps {
  url: string;
  loading: boolean;
}
const RecommendationImageComponent = ({
  url,
  loading,
}: RecommendationImageComponentProps) => {
  return (
    <StyledImageContainer>
      {loading ? (
        <Loader height="280px" />
      ) : (
        <img
          src={url}
          alt="img"
          style={{ maxHeight: "301px", minHeight: "280px" }}
        />
      )}
      <StyledText>Original Image</StyledText>
    </StyledImageContainer>
  );
};

export default RecommendationImageComponent;
