import { call, takeLatest, put, takeEvery } from "redux-saga/effects";
import HttpService from "../../HttpService/HttpService";
import {
  GET_BRAND_AIGROUP_META,
  GET_TAI_CLUSTER,
  GET_TAI_KWS,
  STORE_TAI_CLUSTER,
  STORE_TAI_KWS,
  GET_COUNTRIES,
  GET_REGIONS,
  STORE_TAI_COHORT,
  GET_AD_ACCOUNTS,
  GET_AUDIENCES,
  GetCampaignAudience,
  GET_CAMPAIGN_AUDIENCES,
  SaveCampaignAudience,
  SET_CAMPAIGN_AUDIENCE_DETAILS,
  MERGE_COHORTS,
  RefreshCohorts,
  REFRESH_COHORTS,
  SUBMIT_COHORTS,
  StoreRecommendedKeywords,
  STORE_RECOMMENDED_KEYWORDS,
  DeleteRecommendedKeywords,
  DELETE_RECOMMENDED_KEYWORDS,
  GetCustomAudiences,
  GET_CUSTOM_AUDIENCES,
} from "../types/targetingAI";
import { BASE_URL } from "../../utils/path";
import { crossPlatform, crossPlatformV2, crossPlatformV3 } from "../../MappedAPI/MappedAPI";
import {
  GetAdAccounts,
  STORE_KEYWORDS,
  StoreKeywords,
} from "../types/targetingAI";
import {
  GetBrandAIGroupsMeta,
  StoreTAICluster,
  StoreTAIKWS,
  GetTAIKWS,
  GetTAICluster,
  GetCountries,
  GetRegions,
  StoreTAICohort,
  GetAudiences,
  GetCampaignDetailsTAI,
  GET_CAMPAIGN_DETAILS_TAI,
  StoreFavouriteCohort,
  STORE_FAVOURITE_COHORT,
  DELETE_FAVOURITE_COHORT,
  DeleteFavouriteCohort,
  GET_DRAFTED_RECOMMENDED_COHORTS,
  GetDraftedAndRecommendedCohorts,
  UpdatedRecommendedExclusions,
  UPDATE_RECOMMENDED_EXCLUSIONS_STATE,
} from "../types/targetingAI";
import { setCampaignDetailsTAI } from "../actions/targetingAI";

function* getCampaignDetailsTAI(action: GetCampaignDetailsTAI): any {
  const {
    payload: { adAccountId },
  } = action;
  try {
    const urlParams = `/v1/api/ad-account/${adAccountId}/campaigns`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    yield put(setCampaignDetailsTAI(response.data.data));
  } catch (error) {
    console.log("LL: error", error);
  }
}

function* getAIGroups(action: GetBrandAIGroupsMeta): any {
  let {
    callback,
    payload: { brandId },
  } = action;
  try {
    const urlParams = `${crossPlatform}/brand/${brandId}/ai-group?showTerminated=false`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAIGroups ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getCountriesList(action: GetCountries): any {
  let { callback } = action;
  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/fb/country`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getRegionsList(action: GetRegions): any {
  let { payload:countryCodes, callback } = action;
  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/fb/geolocation?country=${countryCodes.join(",")}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getCustomAudiencesList(action: GetCustomAudiences): any {
  let { callback, payload: { ad_account_id, brand_name, brand_website, brand_id } } = action;
  const searchParamas = new URLSearchParams({
    ad_account_id, 
    brand_name,
    brand_website,
    brand_id,
  });
  
  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/playground/custom-audiences?${searchParamas.toString()}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getAudiencesList(action: GetAudiences): any {
  let {
    callback,
    payload: { aiGroupId },
  } = action;
  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/ai-groups/${aiGroupId}/custom-audience?platformId=1c59a6e2-843b-4634-92a0-6fa93ce6c761`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getCampaignAudiencesList(action: GetCampaignAudience): any {
  let {
    callback,
    user,
    payload: { custom_audience_details, website, brand },
    aiGroupId,
  } = action;
  try {
    let data = { custom_audience_details, website, brand };
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/playground/ai-groups/${aiGroupId}/cluster-data?platformId=1c59a6e2-843b-4634-92a0-6fa93ce6c761`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      user,
      data
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* storeTAIKWS(action: StoreTAIKWS): any {
  let {
    payload: { kwsData, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatformV2}/targeting-ai-recommendation/playground/kws`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      user,
      {
        ad_account_id:kwsData.ad_account_id,
        brand_website:kwsData.website,
        brand_name:kwsData.name,
      }
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getTAIKWS(action: GetTAIKWS): any {
  let { payload: request_ids, callback } = action;
  try {
    const urlParams = `${crossPlatformV2}/targeting-ai-recommendation/playground/kws/status?request_ids=${request_ids}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getTAIKWS ~ error", error);
    callback && callback(error.response, true);
  }
}

function* storeTAICluster(action: StoreTAICluster): any {
  let {
    payload: { clusterData, user, aiGroupId, platformId },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatformV3}/targeting-ai-recommendation/playground/ai-groups/${aiGroupId}/cluster-data?platformId=${platformId}`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      user,
      clusterData
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*storeTAICluster ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getTAICluster(action: GetTAICluster): any {
  let { payload: request_ids, callback } = action;
  try {
    const urlParams = `${crossPlatformV2}/targeting-ai-recommendation/playground/cluster-data/status?request_ids=${request_ids}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getTAICluster ~ error", error);
    callback && callback(error.response, true);
  }
}

function* saveCampaignAudience(action: SaveCampaignAudience): any {
  let { payload, aiGroupId, callback, user } = action;
  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/ai-groups/${aiGroupId}/save-custom-audience`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      user,
      payload
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("saveCampaignAudience ~ error", error);
    callback && callback(error.response, true);
  }
}

function* storeTAICohort(action: StoreTAICohort): any {
  let { payload, callback } = action;
  const { action_list, email_data, user, delete_cohorts } = payload;
  try {
    const urlParams = `/v2/api/targeting-ai-recommendation/playground/cluster-data/publish`;
    const response = yield call(HttpService.post, BASE_URL, urlParams, user, {
      action_list,
      email_data,
      delete_cohorts,
    });
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*storeTAICohort ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAdAccounts(action: GetAdAccounts): any {
  let {
    payload: { aiGroupId, platformId },
    callback,
  } = action;

  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/playground/aigroups/${aiGroupId}/data?platformId=${platformId}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* storeKeywords(action: StoreKeywords): any {
  let { payload, callback } = action;
  const { user, data, validate } = payload;
  try {
    const urlParams = `/v1/api/targeting-ai-recommendation/google-ads/keywords/publish${
      validate ? "/validate" : ""
    }`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      // `https://fffb-202-83-18-3.ngrok-free.app`,
      urlParams,
      user,
      data
    );
    if (response.data.success) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*storeTAICohort ~ error", error);
    callback && callback(error.response, true);
  }
}

function* storeRecommendedKeywords(action: StoreRecommendedKeywords): any {
  let { payload, callback } = action;
  const { user, data, brandId, aigroupId } = payload;
  try {
    const urlParams = `/v4/api/audience-manager/brands/${brandId}/ai-groups/${aigroupId}/keywords`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      // `https://fffb-202-83-18-3.ngrok-free.app`,
      urlParams,
      user,
      data
    );
    if (response.data.success) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*storeRecommendedKeywords ~ error", error);
    callback && callback(error.response, true);
  }
}

function* deleteRecommendedKeyword(action: DeleteRecommendedKeywords): any {
  let {
    payload: { data, aiGroupId, brandId, platformId, user },
    callback,
  } = action;
  try {
    const urlParams = `/v4/api/audience-manager/brands/${brandId}/ai-groups/${aiGroupId}/keywords?platformId=${platformId}`;
    const response = yield call(
      HttpService.delete,
      BASE_URL,
      // `https://fffb-202-83-18-3.ngrok-free.app`,
      urlParams,
      user,
      data
    );
    if (response.data.success) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}
function* storeFavouriteCohort(action: StoreFavouriteCohort): any {
  let {
    payload: { favouriteCohort, aiGroupId, user },
    callback,
  } = action;
  try {
    delete (favouriteCohort[0] as any).semantics_relevance_score;
    delete (favouriteCohort[0] as any).audience_relevance_score;
    delete (favouriteCohort[0] as any).similar_cohorts;
    delete (favouriteCohort[0] as any).edited_cohort_flag;
    delete (favouriteCohort[0] as any).interest_cluster;
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/ai-groups/${aiGroupId}/save-cohorts`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      user,
      favouriteCohort
    );
    if (response.data.success) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* deleteFavouriteCohort(action: DeleteFavouriteCohort): any {
  let {
    payload: { deleteFavouriteCohortIds, aiGroupId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/ai-groups/${aiGroupId}/delete-cohorts?cohortIds=${deleteFavouriteCohortIds}`;
    const response = yield call(HttpService.delete, BASE_URL, urlParams, user);
    if (response.data.success) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getDraftedAndRecommendedCohorts(
  action: GetDraftedAndRecommendedCohorts
): any {
  let {
    payload: { type, aiGroupId },
    callback,
  } = action;

  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/ai-groups/${aiGroupId}/get-saved-cohorts-details?status=${
      type === "isDrafted" ? "isDrafted" : "isRecommended"
    }`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (response.data.success) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* updateRecommendedExclusions(
  action: UpdatedRecommendedExclusions
): any {
  const {
    payload: { data, user, adAccountId },
    callback,
  } = action;
  try {
    const urlParams = `/v1/api/adset/update-recommended-exclusions?adAccountId=${adAccountId}`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      user,
      data
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("update recommendation ~ error", error);
    callback && callback(error.response, true);
  }
}

function* refreshCohorts(action: RefreshCohorts): any {
  const {
    payload: { data, user },
    callback,
  } = action;
  try {
    const urlParams = `/v2/api/targeting-ai-recommendation/playground/cohorts/edit`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      user,
      data
    );
    if (response.data.success && !response.data.data.error) {
      callback && callback(response.data.data.message.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("update recommendation ~ error", error);
    callback && callback(error.response, true);
  }
}

function* mergeCohorts(action: any): any {
  let { payload, callback } = action;
  const { selectedCohorts, cohortsJSON } = payload;
  const cohortsName = selectedCohorts.map((i: any) => i.cohort_name);
  const newCohortName = `${selectedCohorts[0].cohort_name}-${selectedCohorts[1].cohort_name}`;
  try {
    const urlParams = `${crossPlatformV2}/targeting-ai-recommendation/playground/merge-cohorts`;
    const response = yield call(HttpService.post, BASE_URL, urlParams, "", {
      cohorts_json: cohortsJSON,
      merge_info: [
        { merged_cohort_name: newCohortName, cohorts_to_combine: cohortsName },
      ],
    });
    if (!response.data.error) {
      const link = response?.data?.data?.message?.data?.result_file_url;
      if (link) {callback && callback(link,response?.data?.data?.message?.data?.result);}
      else {console.log("Error : Link not found, please try again");}
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* submitCohorts(action: any): any {
  const {
    payload: { data, user },
    callback,
  } = action;
  try {
    const urlParams = `/v2/api/targeting-ai-recommendation/playground/cohorts/submit`;
    const response = yield call(
      HttpService.put,
      BASE_URL,
      urlParams,
      user,
      data
    );
    if (response.data.success && !response.data.data.error) {
      callback &&
        callback(response.data.data.message.data,  false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("update recommendation ~ error", error);
    callback && callback(error.response, true);
  }
}

export function* TargetingAIWatcher() {
  yield takeLatest(GET_BRAND_AIGROUP_META, getAIGroups);
  yield takeLatest(GET_TAI_KWS, getTAIKWS);
  yield takeLatest(GET_TAI_CLUSTER, getTAICluster);
  yield takeLatest(STORE_TAI_CLUSTER, storeTAICluster);
  yield takeLatest(STORE_TAI_KWS, storeTAIKWS);
  yield takeLatest(GET_COUNTRIES, getCountriesList);
  yield takeLatest(GET_REGIONS, getRegionsList);
  yield takeLatest(GET_AUDIENCES, getAudiencesList);
  yield takeLatest(GET_CUSTOM_AUDIENCES,getCustomAudiencesList);
  yield takeLatest(GET_CAMPAIGN_AUDIENCES, getCampaignAudiencesList);
  yield takeLatest(STORE_TAI_COHORT, storeTAICohort);
  yield takeLatest(GET_AD_ACCOUNTS, getAdAccounts);
  yield takeEvery(STORE_KEYWORDS, storeKeywords);
  yield takeEvery(STORE_RECOMMENDED_KEYWORDS, storeRecommendedKeywords);
  yield takeEvery(DELETE_RECOMMENDED_KEYWORDS, deleteRecommendedKeyword);
  yield takeLatest(GET_CAMPAIGN_DETAILS_TAI, getCampaignDetailsTAI);
  yield takeLatest(SET_CAMPAIGN_AUDIENCE_DETAILS, saveCampaignAudience);
  yield takeLatest(STORE_FAVOURITE_COHORT, storeFavouriteCohort);
  yield takeLatest(DELETE_FAVOURITE_COHORT, deleteFavouriteCohort);
  yield takeLatest(
    GET_DRAFTED_RECOMMENDED_COHORTS,
    getDraftedAndRecommendedCohorts
  );
  yield takeLatest(
    UPDATE_RECOMMENDED_EXCLUSIONS_STATE,
    updateRecommendedExclusions
  );
  yield takeLatest(MERGE_COHORTS, mergeCohorts);
  yield takeLatest(REFRESH_COHORTS, refreshCohorts);
  yield takeLatest(SUBMIT_COHORTS, submitCohorts);
}
