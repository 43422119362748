import React, { useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Divider from "@mui/material/Divider";

import CustomChip from "./Chip";
import {
  VIDEO_CARD_SVGS,
  VIDEO_CARD_LABELS,
} from "../../constants/youtubeIndex";
import { Months } from "../../../../utils/constants/index";
// import CohortType from "common/CohortType";

interface ICardProps {
  id: string;
  titles: string;
  relevance: number;
  video_ids: string;
  cluster_id: number;
  view_count: number;
  video_count: number;
  video_links: string[];
  mixed_clus_ind: number;
  type: "VIDEO";
  aiGroupId: string;
  createdAt: string;
  updatedAt: string;
  checked: boolean;
  onClick: Function;
}

function VideoCard(props: ICardProps) {
  const {
    titles,
    relevance,
    view_count,
    video_count,
    checked,
    onClick,
    updatedAt,
  } = props;
  const checkBoxRef = useRef(null);

  const kwsArray = titles.split("; ");
  const getDate = () => {
    const publishDate = new Date(updatedAt);
    return (
      publishDate.getDate() +
      " " +
      Months[publishDate.getMonth() + 1] +
      ", " +
      publishDate.getFullYear()
    );
  };

  return (
    <Box
      component="div"
      onClick={(e: any) => {
        onClick(e, checkBoxRef);
      }}
      className="font-inter"
      sx={{
        width: "19.25rem",
        height: "16.5rem",
        border: "0.06rem solid #CCECFE",
        boxShadow: "0 0.625rem 1.5rem rgba(35, 40, 60, 0.05)",
        borderRadius: "0.5rem",
        background: "#fff",
      }}
    >
      <Stack>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              padding: "0.5rem 0.75rem 0.75rem",
              width: "70%",
              background: "#F1F7FF",
              borderRadius: "0.5rem 0 3.28rem 0",
            }}
          >
            <Tooltip
              title={kwsArray[0].length > 18 ? kwsArray[0] : kwsArray[0]}
            >
              <Typography
                className={`font-600 font-14 ${
                  kwsArray[0].length > 18 ? "overflow" : ""
                }`}
                sx={{
                  color: "#0869FB",
                  minWidth: "10.1rem",
                  maxWidth: "10.1rem",
                  marginBottom: "5px",
                }}
              >
                {kwsArray.length > 0 && kwsArray[0]}
              </Typography>
            </Tooltip>
          </Box>
          <Box>
            <Checkbox
              ref={checkBoxRef}
              className="cohort-card-checkbox"
              size="small"
              checked={checked}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ padding: 1 }}
          alignItems="center"
        >
          <CalendarTodayOutlinedIcon
            fontSize="small"
            sx={{ height: "0.75rem", width: "0.75rem" }}
          />
          <Typography className="font-10 font-400">
            Recommended on&nbsp;
            {getDate()}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
          <Box sx={{ minWidth: "50%", maxWidth: "50%" }}>
            <Stack
              spacing={1.2}
              divider={
                <Divider
                  sx={{
                    border: "0.063rem solid #EFEFEF",
                    marginTop: "0.625rem",
                  }}
                />
              }
            >
              {[relevance.toFixed(2), video_count, view_count].map(
                (metric, index) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    key={index}
                  >
                    <Box
                      sx={{
                        padding: "0.25rem 0.5rem",
                        background: "#F5FFF7",
                        borderRadius: "0.21rem",
                      }}
                    >
                      {VIDEO_CARD_SVGS[index]}
                    </Box>
                    <Stack sx={{ margin: "0px 0px 0px 8px" }}>
                      <Typography
                        sx={{ color: "#333" }}
                        className="font-10 font-400"
                      >
                        {VIDEO_CARD_LABELS[index]}
                      </Typography>
                      <Typography
                        title={metric.toString()}
                        sx={{ color: "#00261C" }}
                        className="font-14 font-500"
                      >
                        {metric}
                      </Typography>
                    </Stack>
                  </Stack>
                )
              )}
            </Stack>
          </Box>
          <Box
            sx={{
              background: "#F7F7F7",
              borderRadius: 1,
              minWidth: "50%",
              maxWidth: "50%",
              padding: "0.25rem 0 0 0.25rem",
            }}
          >
            <Box sx={{ minHeight: "8rem", overflowX: "hidden", pr: 1 }}>
              {kwsArray.slice(0, 4).map((element: string, index: number) => {
                return <CustomChip label={element} key={index} />;
              })}
            </Box>
            {kwsArray.length > 4 && (
              <Typography
                className="font-10 font-500"
                sx={{
                  color: "#0869FB",
                  float: "right",
                  margin: "0.2rem 0.5rem",
                }}
              >
                +{kwsArray.length - 4}&nbsp;More
              </Typography>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default VideoCard;
