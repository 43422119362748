import { Box } from "@mui/material";
import React from "react";

import { ReactComponent as InfoError } from "../../../../../assets/cai/svg/info_red.svg";
import { LightTooltip } from "components/shared/LightTooltip";
import { StyledPerformanceText } from "./styled";
import { Button } from "components/shared";
import { fetchPerformanceScore } from "store/actions/CreativeAI_Revamp/HomeScreen/Editor/editor.actions";
import {
  CreateVariantsState,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
} from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { useDispatch, useSelector } from "react-redux";
import { AdAccountState } from "store/types/adaccount";
import { performanceScoreNullState } from "components/CreativeAI_Revamp/shared/PerformanceTooltip/performance.constant";
import { updateRecommendationMetadata } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";

export default function ErrorPerformanceScore({
  index,
  hash,
  value,
}: {
  index: number;
  hash: string;
  value: string;
}) {
  const dispatch = useDispatch();
  const { recommendationMetaData } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );

  // redux state
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  return (
    <LightTooltip
      title="Something went wrong, couldn't calculate the Performance Score !"
      arrow
      placement="right"
    >
      <Box
        display="flex"
        sx={{
          padding: "0.2em .5em 0.2em 1em",
          background: "rgba(232, 75, 71, 0.1)",
          borderRadius: "7px 0px",
          gap: ".5em",
        }}
        alignItems="center"
        alignSelf="flex-start"
      >
        <StyledPerformanceText color="#E84B47">
          Performance Score:
        </StyledPerformanceText>
        <InfoError />
        <div
          onClick={() => {
            const localRecommendationData = {
              ...recommendationMetaData,
              isEditing: true,
              isDeleting: false,
            };
            localRecommendationData[hash].data[index] = {
              ...localRecommendationData[hash].data[index],
              text: value,
              isLoading: true,
            };

            dispatch(
              updateRecommendationMetadata({
                data: localRecommendationData,
              })
            );

            dispatch(
              fetchPerformanceScore({
                body: {
                  brand_name: selectedBrand?.name,
                  [hash]: value,
                },
                callback: ({ error, performanceScore, cancel }) => {
                  if (cancel) {
                    localRecommendationData[hash].data[index] = {
                      text: localRecommendationData[hash].data[index]?.text,
                      ...performanceScoreNullState,
                      isFailed: true,
                    };
                    dispatch(
                      updateRecommendationMetadata({
                        data: localRecommendationData,
                      })
                    );
                  }
                  if (error) {
                    localRecommendationData[hash].data[index] = {
                      ...localRecommendationData[hash].data[index],
                      isFailed: true,
                    };
                  } else {
                    localRecommendationData[hash].data[index] = {
                      ...localRecommendationData[hash].data[index],
                      ...performanceScore,
                    };
                    delete localRecommendationData[hash].data[index]?.isFailed;
                  }
                  delete localRecommendationData[hash].data[index]?.isLoading;
                  dispatch(
                    updateRecommendationMetadata({
                      data: localRecommendationData,
                    })
                  );
                  dispatch({
                    type: UPDATE_RECOMMENDATION_SELECTION_STATE,
                    payload: {
                      isChanged: true,
                    },
                  });
                },
              })
            );
          }}
          style={{
            fontFamily: "Inter",
            fontStyle: "italic",
            fontWeight: 500,
            fontSize: "0.625em",
            lineHeight: "20px",
            color: "#0869FB",
            textDecorationLine: "underline",
            cursor: "pointer",
          }}
        >
          Retry
        </div>
      </Box>
    </LightTooltip>
  );
}
