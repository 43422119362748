import React from "react";
import { ReactComponent as DropDownArrow } from "../../assets/svg/dropdownarrow.svg";
import "./accordion.scss";

const Accordion: React.FC<{
  header: any;
  body: any;
  index: number;
  handleOpen: (e: any, data: any) => void;
  className?: string;
  data: any;
  accordionIndex?: number;
}> = ({ header, body, index, className, data, accordionIndex, handleOpen }) => {
  const [openAccordion, setOpenAccordion] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (accordionIndex !== -1) {
      setOpenAccordion((a: any) => [...a, accordionIndex]);
    }
  }, [accordionIndex]);

  return (
    <div className={`${className} accordion-ui-container`}>
      <div
        className="accordion-header"
        onClick={(e: any) => {
          if (openAccordion.includes(index)) {
            setOpenAccordion((acc: any) => acc.filter((a: any) => a !== index));
          } else {
            setOpenAccordion((a: any) => [...a, index]);
            handleOpen(e, data);
          }
        }}
      >
        {header}
        <DropDownArrow
          style={{
            transform: openAccordion.includes(index) ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </div>
      <div className={` ${openAccordion.includes(index) ? "accordion-open" : ""} accordion-body`}>
        <div className="accordion-body-container">{body}</div>
      </div>
    </div>
  );
};

export default Accordion;
