import React from "react";
import GsaiDropdowns from "../../../components/CreativeAI/GsaiPlayground/GsaiDropdowns";
import GsaiCard from "../../../components/CreativeAI/GsaiPlayground/Shared/GsaiLandingPageCard/GsaiCard";
import {
  StyledTitle,
  StyledGsaiHeader,
  StyledBetaLogo,
  StyledGSAIContainer,
  StyledCardContainer,
  StyledMainGsaiDiv,
  StyledCardHeader,
  StyledNoAdsImageContainer,
  StyledHeaderContainer,
  StyledErrorBanner,
  StyledFooterBox,
  StyledErrorSVG,
} from "./styled";
import { useSelector } from "react-redux";
import GsaiCardSkeleton from "../../../components/CreativeAI/GsaiPlayground/Shared/GsaiLandingPageCard/GsaiCardSkeleton";
import NoAdsAvailable from "../../../components/CreativeAI/GsaiPlayground/Shared/GsaiLandingPageCard/NoAdsAvailable";
import { GsaiState } from "store/types/CreativeAI/GsaiPlayground";
import noAIGroup from "../../../assets/svg/no_ai_group.svg";
import { ReactComponent as Error } from "../../../assets/svg/error_exclamation.svg";

const GsaiAdsListing = ({ screen }: { screen: number }) => {
  const {
    filteredAds,
    filteredAdsLoader,
    noAdsAvailable,
    aiGroups,
    adAccounts,
    campaigns,
    adGroups,
  } = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );
  const { readyToUseBUs } = useSelector(
    (state: any) => state.businessUnitsReducer
  );

  const showUI = () => {
    if (aiGroups.data.length === 0 && !aiGroups.isLoading) {
      return (
        <NoAdsAvailable
          imageSrc={noAIGroup}
          title="No Data Present"
          subTitle="Oops! Looks like you have not linked your Google ad account, to start using this feature please contact the customer support team. 
"
        />
      );
    } else if (
      filteredAdsLoader ||
      aiGroups.isLoading ||
      adAccounts.isLoading ||
      campaigns.isLoading ||
      adGroups.isLoading
    ) {
      return Array(8)
        .fill("")
        .map((index) => {
          return <GsaiCardSkeleton />;
        });
    } else if (filteredAds && filteredAds.length !== 0) {
      return filteredAds.map((item: any, index: number) => {
        if (item?.isVisible) {
          return <GsaiCard data={item} index={index} />;
        }
      });
    }
    return Array(8)
      .fill("")
      .map((index) => {
        return <GsaiCardSkeleton />;
      });
  };

  return (
    <StyledMainGsaiDiv hidden={screen === 1}>
      <StyledHeaderContainer>
        <StyledGsaiHeader>
          <StyledTitle>{`Creative AI : Google Search`}</StyledTitle>{" "}
          {/* <StyledBetaLogo /> */}
        </StyledGsaiHeader>
        <div>
          <GsaiDropdowns />
        </div>
        <StyledCardHeader>Responsive Search Ads</StyledCardHeader>
      </StyledHeaderContainer>
      <StyledGSAIContainer height={200}>
        {!filteredAdsLoader && readyToUseBUs?.data?.length === 0 && (
          <StyledErrorBanner>
            <StyledErrorSVG>
              <Error width={"1.5em"} height={"1.5em"} />
            </StyledErrorSVG>
            Our AI cannot find your 'Business Unit' at this moment. Kindly
            contact the support team immediately !
          </StyledErrorBanner>
        )}
        <StyledCardContainer>
          {noAdsAvailable ? (
            <StyledNoAdsImageContainer>
              <NoAdsAvailable />
            </StyledNoAdsImageContainer>
          ) : (
            showUI()
          )}
        </StyledCardContainer>
        <StyledFooterBox>
          *Showing last 14 days Ad performance data
        </StyledFooterBox>
      </StyledGSAIContainer>
    </StyledMainGsaiDiv>
  );
};

export default GsaiAdsListing;
