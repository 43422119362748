import { useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Popover from "@mui/material/Popover";
import Switch from "@mui/material/Switch";
import Box from "@mui/system/Box";
import styled from "@mui/material/styles/styled";

import { SPECIAL_AD_CATEGORIES } from "../constants/index";
import { setAdditionalInputs } from "store/actions/targetingAI";
import { useDispatch } from "react-redux";

const AntSwitch = styled(Switch)(({ theme }) => ({
  height: "26px",
  padding: "0.3rem",
  "&:active": {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#0869FB",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#E9EDFF",
      },
      "& .MuiSwitch-thumb": {
        opacity: 1,
        backgroundColor: "#0869FB !important",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    borderRadius: 11,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
    background: "#999999",
    height: "18px",
    width: "18px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#ECEEF5",
    boxSizing: "border-box",
    py: 0.5,
  },
}));

function AdditionalInputs({
  specialAdCategorySelection,
  setSpecialAdCategorySelection,
  advanceSwitches,
  setAdvanceSwitches,
  locationFilter,
}: any) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event: any) => {
    if (specialAdCategorySelection.includes(event.target.name.toUpperCase())) {
      setSpecialAdCategorySelection(
        specialAdCategorySelection.filter(
          (category: any) => category !== event.target.name.toUpperCase()
        )
      );
    } else {
      setSpecialAdCategorySelection([
        ...specialAdCategorySelection,
        event.target.name.toUpperCase(),
      ]);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          textTransform: "none",
          color: "#0869FB",
          borderColor: "#0869FB",
          borderRadius: "0.5em",
          fontWeight: "600",
          fontFamily: "inter !important",
          fontSize: "14px",
        }}
        aria-describedby={id}
        onClick={handleClick}
      >
        Advanced Settings
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "0.5rem",
            border: "1px solid #F0F0F0",
            width: "16  rem",
            pt: 2,
            px: 2,
            pb: 3,
          },
          "& *": {
            fontFamily: "Inter !important",
            fontSize: "0.75rem",
            fontWeight: 500,
            color: "#575C67 !important",
          },
          "& .MuiFormControlLabel-label": {
            ml: 1,
          },
          "& .MuiFormControlLabel-root": {
            m: 0,
            mb: 1,
          },
        }}
      >
        <>
          <FormControlLabel
            control={
              <AntSwitch
                checked={locationFilter}
                onChange={() => {
                  dispatch(
                    setAdditionalInputs({
                      locationFilter: !locationFilter,
                      specialAdCategories: specialAdCategorySelection,
                    })
                  );
                  setAdvanceSwitches({
                    ...advanceSwitches,
                    countrySpecificTargeting:
                      !advanceSwitches.countrySpecificTargeting,
                  });
                }}
                name="country-specific-targeting"
                size="small"
              />
            }
            sx={{ px: 0.5 }}
            label="Country Specific Targeting"
          />
          <Box
            sx={{
              background: advanceSwitches.specialAdCategories
                ? "#FAFAFA"
                : "#FFF",
              borderRadius: "0.5rem",
              p: 0.5,
            }}
          >
            <FormControlLabel
              control={
                <AntSwitch
                  checked={advanceSwitches.specialAdCategories}
                  onChange={() => {
                    setAdvanceSwitches({
                      ...advanceSwitches,
                      specialAdCategories: !advanceSwitches.specialAdCategories,
                    });
                    
                  }}
                  name="special-ad-category"
                  size="small"
                />
              }
              label="Special Ad Category"
            />
            {advanceSwitches.specialAdCategories && (
              <>
                <Divider variant="middle" sx={{ m: 0 }} />
                <Box>
                  <FormGroup
                    sx={{
                      "& .MuiTypography-root": { fontSize: "10px" },
                      "& .MuiFormControlLabel-root": { mb: "0 !important" },
                      "& .MuiFormControlLabel-label": { ml: "0 !important" },
                    }}
                  >
                    {SPECIAL_AD_CATEGORIES.map((categoty) => (
                      <FormControlLabel
                        sx={{
                          "& .Mui-checked .MuiSvgIcon-root": {
                            fill: "#0869FB",
                          },
                        }}
                        control={
                          <Checkbox
                            checked={specialAdCategorySelection.includes(
                              categoty.includes(",")?categoty.replaceAll(", ","_").replaceAll("Social ","").toUpperCase():categoty.toUpperCase()
                            )}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 16,
                                fill: "#D1D1D1",
                              },
                            }}
                            onChange={handleChange}
                            name={categoty.includes(",")?categoty.replaceAll(", ","_").replaceAll("Social ",""):categoty}
                          />
                        }
                        label={categoty}
                      />
                    ))}
                  </FormGroup>
                </Box>
              </>
            )}
          </Box>
        </>
      </Popover>
    </>
  );
}

export default AdditionalInputs;
