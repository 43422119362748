import React, { useEffect, useRef } from "react";
import "./index.scss";

const TextAreaLabel: React.FC<{
  limit?: number;
  label?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  inputClassName?: string;
  labelClassName?: string;
  validationClassName?: string;
  metricComponent?: any;
  onInputChange: (e: any) => void;
  data?: any;
}> = ({
  value,
  name,
  placeholder,
  inputClassName,
  labelClassName,
  validationClassName,
  onInputChange,
  limit,
  label,
  metricComponent: Component,
  data,
}) => {
  const textareaRef = useRef<any>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  useEffect(() => {
    const handleResize = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "inherit";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="textarea-label-container">
      <textarea
        ref={textareaRef}
        rows={1}
        name={name}
        value={value}
        className={`textarea-label--textarea ${inputClassName}`}
        onChange={(e) => {
          onInputChange(e);
        }}
        maxLength={limit}
        placeholder={placeholder}
      />
      <div className="flex">
        {label && (
          <span
            className={`textarea-label--label flex-center ${labelClassName}`}
          >
            {label}
            {Component && <Component value={data} />}
          </span>
        )}

        <span
          className={`textarea-label--validation ${
            limit === value?.length ? "limit-reached" : ""
          } ${validationClassName}`}
        >
          {limit &&
            (value?.length === undefined ? 0 : value?.length) + `/` + limit}
        </span>
      </div>
    </div>
  );
};

export default TextAreaLabel;
