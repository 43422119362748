import {
  SetError,
  SET_ERROR,
  ResetError,
  RESET_ERROR,
  SetErrorForAllBrandDetails,
  errorTypes,
  SetErrorForBrandMetrics,
  SetErrorForAgencies,
  SetErrorForGetOptimizerDetails,
  SET_ERROR_SELECTED_BRAND,
} from "../../types/SmbDashboard/error";

export const setError = (payload: any): SetError => ({
  type: SET_ERROR,
  payload,
});

export const setErrorForAgencies = (payload: any): SetErrorForAgencies => ({
  type: SET_ERROR,
  payload: {
    type: errorTypes.GET_AGENCIES,
    message: payload.message,
  },
});

export const setErrorForAllBrandDetails = (
  payload: any
): SetErrorForAllBrandDetails => ({
  type: SET_ERROR,
  payload: {
    type: errorTypes.GET_ALL_BRAND_DETAILS,
    message: payload.message,
  },
});

export const setErrorForBrandMetrics = (
  payload: any
): SetErrorForBrandMetrics => ({
  type: SET_ERROR,
  payload: {
    type: errorTypes.GET_BRAND_METRICS,
    message: payload.message,
  },
});

export const resetError = (): ResetError => ({
  type: RESET_ERROR,
});

export const setErrorForGetOptimizerDetails = (
  payload: any
): SetErrorForGetOptimizerDetails => ({
  type: SET_ERROR_SELECTED_BRAND,
  payload: {
    type: errorTypes.GET_OPTIMIZER_DETAILS,
    message: payload.message,
  },
});

export const resetErrorForSelectedBrand = (): ResetError => ({
  type: RESET_ERROR,
});
