import React, { ReactElement } from "react";
import ButtonUI from "../../../../ui/ButtonUI/ButtonUI";
import { ReactComponent as SortingIcon } from "../../../../assets/svg/sorting-icon.svg";
import HandleClickOutside from "components/common/HandleClickOutside";
import "./style.scss";
import { gsaiSortingData } from "utils/constants/GSAI";
import { useDispatch, useSelector } from "react-redux";
import { updateGsaiStore } from "store/actions/CreativeAI/GsaiPlayground";

interface NavigationDropDownItemProps {
  data: Array<Object>;
  onSelect: any;
  setVisible: any;
  defaultDisabledTitle?: string;
}

function NavigationDropDownItem({
  data,
  onSelect,
  setVisible,
  defaultDisabledTitle,
}: NavigationDropDownItemProps) {
  return (
    <>
      {defaultDisabledTitle ? (
        <div className="default-disabled">{defaultDisabledTitle}</div>
      ) : (
        ""
      )}
      {data.map((d: any) => (
        <div
          onClick={() => {
            setVisible(false);
            onSelect(d);
          }}
          className="dropdown-item"
          title={d.name}
        >
          {d.name}
        </div>
      ))}
    </>
  );
}

const Dropdown: React.FC<{
  toggelButton?: ReactElement;
  isDropDownVisible: boolean;
  setIsDropDownVisible: any;
  className: string;
}> = ({
  children,
  toggelButton,
  isDropDownVisible,
  setIsDropDownVisible,
  className,
}) => {
  const wrapperRef = React.useRef(null);
  HandleClickOutside(wrapperRef, [setIsDropDownVisible]);

  return (
    <div className={`${className} dropdown-container`} ref={wrapperRef}>
      <div
        className="dropdow-btn"
        onClick={() => {
          setIsDropDownVisible(!isDropDownVisible);
        }}
      >
        {toggelButton}
      </div>
      {isDropDownVisible && <div className="sorting-dropdown">{children}</div>}
    </div>
  );
};

function Sort() {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const CARD_DATA = useSelector(
    (state: any) => state.gsaiPlayground.filteredAds
  );
  const dispatch = useDispatch();

  const customSortData = (data: any, key: string) => {
    return data.sort((item1: any, item2: any) => {
      if (item1.metrics[key] && item2.metrics[key]) {
        if (item1.metrics[key] > item2.metrics[key]) {
          return 1;
        }
        if (item1.metrics[key] < item2.metrics[key]) {
          return -1;
        }
        if (item1.metrics[key] == item2.metrics[key]) {
          return 0;
        }
      } else if (item1.metrics[key]) {
        return 1;
      } else if (item2.metrics[key]) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  return (
    <Dropdown
      isDropDownVisible={isOpen}
      setIsDropDownVisible={setIsOpen}
      className={"sorting-dropdown-component"}
      toggelButton={
        <ButtonUI className="sort-btn-bg-clr">
          <div className="btn-container flex-center">
            <span className="dropdown_arrow">
              <SortingIcon />
            </span>
            <div className="text-dark text-left text-truncate ml-1">Sort</div>
          </div>
        </ButtonUI>
      }
    >
      <NavigationDropDownItem
        data={gsaiSortingData}
        onSelect={(item: any) => {
          if (item.order === "asc") {
            let newadGroups = customSortData(CARD_DATA, item.value);
            dispatch(
              updateGsaiStore({
                filteredAds: newadGroups,
              })
            );
          } else {
            let newadGroups = customSortData(CARD_DATA, item.value).reverse();
            dispatch(
              updateGsaiStore({
                filteredAds: newadGroups,
              })
            );
          }
        }}
        setVisible={setIsOpen}
        defaultDisabledTitle={"SORT BY"}
      />
    </Dropdown>
  );
}

export default Sort;
