import { BUNDLE_GENERATE, GetBillboardBundle, GetBundleGenerate, GET_BILLBOARD_BUNDLE, SetFakeGenerateLoading, SetPreview, SetRequestId, SET_FAKEGENERATE_LOADING, SET_PREVIEW, SET_REQUEST_ID } from "../../../../types/CreativeAI/Facebook/BundleGeneration/bundleGeneration";


export const getBundleGenerate = (payload: any, callback?:any) : GetBundleGenerate => {
    return {
      type: BUNDLE_GENERATE,
      payload,
      callback
    };
  };

  export const getBillboardBundle = (payload: any, callback?:any) : GetBillboardBundle => {
    return {
      type: GET_BILLBOARD_BUNDLE,
      payload,
      callback
    };
  };

  export const setRequestId = (payload: any) : SetRequestId =>{
    return {
        type: SET_REQUEST_ID,
        payload,
      };
  }

  export const setAdPreview = (payload: any) : SetPreview =>{
    return {
        type: SET_PREVIEW,
        payload,
      };
  }
  export const setFakeGenerateLoading = (payload: any): SetFakeGenerateLoading =>{
    return{
      type: SET_FAKEGENERATE_LOADING,
      payload
    }
  }