import styled, { StyledComponent } from "styled-components";
import { Button, Input, styled as MUIstyled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { DateRange } from "react-date-range";
import { ReactComponent as DeleteIcon } from "assets/svg/DeleteBlueIcon.svg";

export const StyledAddPacingContainer = MUIstyled(Box)`
    padding-top: .9375rem;
    background: #FFFFFF;
    border-radius: .625rem;
    width:100%;
    display: flex;
    flex-direction: column;
    margin-bottom: .625rem;
    justify-content: space-between;
    height: 30%;
    position: relative;
`;

export const StyledPacingNameInput = MUIstyled(Input)`
  border: 1px solid #D1D1D1;
  border-radius: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  &:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  &:before {
    border-bottom: none;
  }
`;

export const StyledPacing = MUIstyled(Box)`
  margin: 0px 0.9375rem;
  background: #f3f8ff;
  border: 0.08rem solid transparent;
  border-radius: 0.5rem;
  padding: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
  cursor: pointer;
`;

export const StyledPacingName = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  color: #000000;
`;

export const StyledPacingDate = styled.span`
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 0.6rem;
  color: #000000;
`;

export const StyledAddPacingBtn = MUIstyled(Button)`
    margin: 0.5rem .9375rem;
    background: #EDF4FF;
    border-radius: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    color: #0869FB;
    text-transform: capitalize;
    width: fit-content;
    &:disabled {
      color: #0869FB;
      opacity: 0.5;
    }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
`;

export const StyledDateRangePickerContainer = styled.div`
  background: white;
  width: 100%;
  box-shadow: 0px 0.0625rem 0.75rem rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem;
  overflow: hidden;
  height: 25.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 23rem;
  overflow: scroll;
`;

export const StyledDateRangeSave = MUIstyled(Button)`
    background: #0869FB;
    border-radius: .375rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    color: #FFFFFF;
    text-transform: capitalize;
    width: fit-content;
    &:disabled {
      color: white;
      opacity: 0.5;
    }
    &:hover {
      background: #0869FB;
    }
`;

export const StyledAddPacingBtnContainer = styled.div`
  background: white;
  position: sticky;
  bottom: 0%;
`;

export const StyledDateRangeFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px 10px 0px;
  width: 100%;
`;

export const StyledDateRangePicker = styled(DateRange)`
  .rdrDateDisplayWrapper {
    background-color: white;
  }
  .rdrDateDisplayItemActive {
    box-shadow: 0 1px 2px 0 rgb(35 57 66 / 21%);
    border: 1px solid transparent;
  }
  .rdrMonthAndYearWrapper {
    padding-top: 0px;
  }
  .rdrDateDisplay {
    margin-bottom: 0px;
  }
  .rdrMonthAndYearPickers select:hover {
    background-color: white;
  }
  .rdrMonthPicker {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
  }
  .rdrYearPicker {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
  }
  .rdrNextPrevButton {
    background: white;
  }
  .rdrDayNumber {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 0.7813rem;
    color: #000000;
  }
  .rdrMonth {
    padding: 0 0.833em 0.833em 0.833em;
  }
`;

export const StyledStatus = styled.span<{ status: string | undefined }>`
  color: ${({ status }) =>
    status === "ACTIVE"
      ? "#34A853"
      : status === "UNCONFIGURED"
      ? "#EF5854"
      : "#FBBC04"};
  font-size: 0.5625em;
  margin-right: 1em;
`;

export const StyledAbortedText = styled(Typography)`
  font-size: 0.65em;
  line-height: 1.25em;
  color: #ef5854;
  padding: 4px 8px;
  border-radius: 6px;
  background: #fdeeee;
  align-items: center;
  display: flex;
  margin-right: 5px;
`;
