import { useRef} from "react";
import { ReactComponent as CheckIcon } from "assets/svg/tms-check.svg";
const { v4: uuidv4 } = require("uuid");

interface Props {
  className?: string;
  disabled?: boolean;
  isIntermediate?: boolean;
  label?: string;
  labelComponent: React.ReactNode;
  onChange?: (value: boolean, e: any) => void;
  value?: boolean;
}

function CheckBox({
  className = "",
  disabled,
  isIntermediate = false,
  label = "",
  labelComponent = null,
  onChange,
  value,
}: Props): JSX.Element {
  const uuid = useRef<string>(uuidv4());

  function handleClick(e: any) {
    e.preventDefault();
    const isChecked = isIntermediate ? false : !value;
    !disabled && onChange && onChange(isChecked, e);
  }

  return (
    <div
      className={`pixis-checkbox-container ${className}${
        disabled ? " disabled-cls" : ""
      }`}
    >
      <input
        checked={Boolean(value)}
        disabled={disabled}
        id={uuid.current}
        onChange={() => {}}
        type="checkbox"
      />
      <label
        className="pixis-checkbox-label"
        htmlFor={uuid.current}
        onClick={handleClick}
      >
        <div className="pixis-checkbox">
          <CheckIcon className="check-icon" />
          <div
            className={`intermediate-line${
              isIntermediate ? " intermediate" : ""
            }`}
          />
        </div>
        {labelComponent ? (
          labelComponent
        ) : (
          <div className="label-content">{label}</div>
        )}
      </label>
    </div>
  );
}

export default CheckBox;
