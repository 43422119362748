/* eslint-disable import/no-anonymous-default-export */
import normalEllipse from "../../../assets/png/normalEllipse.png";
import { cohortTypes } from "../components/constants";

export default (data, view="") => {
  const nodeFormat = {
    id: "",
    type: "Category",
    label: "",
    size: 10,
    nodeColor: "#E3EEFF",
    textColor: "blue",
    image: normalEllipse,
  };

  const linkFormat = {
    source: "",
    target: "",
    distance: 50,
    width: 0.8,
    color: "E3EEFF",
  };

  const nodes = [];
  const links = [];
  const allCohorts = data.map((c) => view !== "" ? c?.[view]?.["cohort_id"] : c["cohort_id"]);
  data?.forEach((catNode) => {
    const catNodeId = view !== "" ? `C-${catNode?.[view]?.cohort_id}` : `C-${catNode.cohort_id}`;
    nodes.push({
      ...nodeFormat,
      id: catNodeId,
      label: view !== "" ? catNode?.[view]?.cohort_name:  catNode.cohort_name,
      textColor: cohortTypes[catNode?.clus_type]?.color,
      clus_type:  view !== "" ? catNode?.[view]?.clus_type :catNode?.clus_type,
    });
    
    if ((view !== "" && catNode?.[view]?.interest_relevance && catNode?.[view]?.interest_relevance.length) || (catNode.interest_relevance && catNode.interest_relevance.length)) {
      (view !== "" ? catNode?.[view]?.interest_relevance : catNode.interest_relevance)?.forEach((childNode) => {
        const entries = Object.entries(childNode);
        let childId = entries[0][0];
        let childDistance = parseInt((1 - entries[0][1]) * 100);
        nodes.push({ ...nodeFormat, id: childId, type: "child" });
        links.push({
          ...linkFormat,
          source: catNodeId,
          target: childId,
          distance: 8, //childDistance,
          relevance: entries[0][1],
        });
      });
    }
    if ((view !== "" && catNode?.[view]?.cohort_relevance && catNode?.[view]?.cohort_relevance.length) || (catNode.cohort_relevance && catNode.cohort_relevance.length)) {
      //create link for the maximum distance nodes only
      const ascSortedList = (view !== "" ? catNode?.[view]?.cohort_relevance : catNode.cohort_relevance).sort((a, b) => {
        let aDis = Object.values(a)[0];
        let bDis = Object.values(b)[0];
        return bDis - aDis;
      });

      const maxDistanceNode = ascSortedList[0];
      const entries = Object.entries(maxDistanceNode);
      const linkClust = entries[0][0];

      if (allCohorts.includes(linkClust)) {
        const targetClustId = `C-${linkClust}`;
        const targetClustDistance = parseInt((1 - entries[0][1]) * 100 + 50);

        links.push({
          ...linkFormat,
          source: catNodeId,
          target: targetClustId,
          distance: targetClustDistance,
          relevance: entries[0][1],
        });
      }
      // catNode.cohort_relevance.forEach((linkedCluster) => {
      //   const entries = Object.entries(linkedCluster);
      //   const linkClut = entries[0][0];

      //   // if linkClut exist as a cohort then only
      //   if (allCohorts.includes(linkClut)) {
      //     const targetClustId = `C-${linkClut}`;
      //     const targetClustDistance = parseInt(entries[0][1] * 100);

      //     links.push({
      //       ...linkFormat,
      //       source: catNodeId,
      //       target: targetClustId,
      //       distance: targetClustDistance,
      //     });
      //   }
      // });
    }
  });
  return {
    nodes,
    links,
  };
};
