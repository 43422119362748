import BasicModal from "common/modal";

function MergeCohortModal({ open, setOpen, onSubmit, values }) {
  return (
    <BasicModal
      title="Wait"
      open={open}
      setOpen={setOpen}
      showButton={true}
      width="450px"
      height={"auto"}
      titleHeight={"60px"}
      footerHeight={"60px"}
      buttonText="Proceed"
      showCancel={true}
      onSubmit={onSubmit}
    >
      <p style={{ fontFamily: "Inter", color: "#666666" }}>
        Do you want to merge Cohort{" "}
        <span
          style={{ fontWeight: 700, fontFamily: "Inter" }}
        >{`C${values[0].cohort_id}`}</span>{" "}
        and Cohort{" "}
        <span
          style={{ fontWeight: 700, fontFamily: "Inter" }}
        >{`C${values[1].cohort_id}`}</span>
        . This action cannot be undone.
      </p>
    </BasicModal>
  );
}

export default MergeCohortModal;
