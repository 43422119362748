import {
  GET_ACCOUNTS_STATS,
  GetAccountsStats,
  GetStatsOnAdaccount,
  GET_STATS_ON_ADACCOUNT,
  DELETE_TOKEN,
  DeleteToken,
  UPDATE_PRIMARY_TOKEN,
  UpdatePrimaryToken,
  GetBrandDetails,
  GET_BRAND_DETAILS,
  SET_TOKEN_STATS
} from '../types/accountsManager'

export const getAccountsStats = (
  payload: { brandId: string },
  callback: Function
): GetAccountsStats => ({
  type: GET_ACCOUNTS_STATS,
  payload,
  callback
})

export const getStatsOnAdaccount = (
  payload: { adaccountId: string },
  callback: Function
): GetStatsOnAdaccount => ({
  type: GET_STATS_ON_ADACCOUNT,
  payload,
  callback
})

export const deleteToken = (
  payload: { user: any; tokenUID: any; adAccountId: any },
  callback: Function
): DeleteToken => ({
  type: DELETE_TOKEN,
  payload,
  callback
})

export const updatePrimaryToken = (
  payload: { user: any; tokenUID: any; adAccountId: any; payload: any },
  callback: Function
): UpdatePrimaryToken => ({
  type: UPDATE_PRIMARY_TOKEN,
  payload,
  callback
})

export const getBrandDetails = (
  payload: { brandId: string },
  callback: Function
): GetBrandDetails => ({
  type: GET_BRAND_DETAILS,
  payload,
  callback
})

export const setTokenStats = (payload: any) => ({ type: 'SET_TOKEN_STATS', payload })
