export const groupV1API = "/api/v1/groups";
export const crossPlatform = "/v1/api";
export const crossPlatformV2 = "/v2/api";
export const crossPlatformV3 = "/v3/api";
export const groupV2API = "/api/v2/groups";
export const phasesV1API = "/api/v1/phases";
export const adAccountsV1API = "/api/v1/adaccounts";
export const smbDashboardApi = "/v4/api";
export const parentAiGroups = "/v1/api/parent-ai-groups/";
export const aiGroups = "/v1/api/ai-group/";
export const targetingAiRecommendation = "/v1/api/targeting-ai-recommendation/ai-groups";
