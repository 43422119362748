import {
  SetAiGroupPlanners,
  SetAiGroupItems,
  SetAiGroupPlannerDetails,
  SET_AI_GROUP_ITEMS,
  SET_AI_GROUP_PLANNERS,
  SET_AI_GROUP_PLANNERS_DETAILS,
  SET_SELECTED_AI_GROUP,
  SET_SELECTED_PLANNER_ID,
  SET_AI_GROUP_LOADER,
  SetAiGroupLoader,
} from "../../types/BudgetPlannerAiGroup";

export const initialAiGroupState: any = {
  data: {
    AiGroups: {},
    selectedAiGroup: null,
    selectedPlannerId: null,
  },
};

const actions: any = {
  [SET_AI_GROUP_ITEMS]: (state: any, payload: any) => ({
    ...state,
    data: {
      ...state.data,
      AiGroups: payload,
    },
  }),

  [SET_SELECTED_AI_GROUP]: (state: any, payload: any) => ({
    ...state,
    data: {
      ...state.data,
      selectedAiGroup: payload,
    },
  }),

  [SET_SELECTED_PLANNER_ID]: (state: any, payload: any) => ({
    ...state,
    data: {
      ...state.data,
      selectedPlannerId: payload,
    },
  }),

  [SET_AI_GROUP_PLANNERS]: (state: any, payload: any) => ({
    data: {
      ...state.data,
      AiGroups: {
        ...state.data.AiGroups,
        [payload.aiGroupId]: {
          ...state.data.AiGroups[payload.aiGroupId],
          planners: payload.planners,
        },
      },
    },
  }),

  [SET_AI_GROUP_PLANNERS_DETAILS]: (state: any, payload: any) => ({
    data: {
      ...state.data,
      AiGroups: {
        ...state.AiGroups,
        [payload.AiGroupId]: {
          ...state.AiGroups[payload.AiGroupId],
          planners: {
            ...state.AiGroups[payload.AiGroupId].planners,
            [payload.plannerId]: {
              ...state.AiGroups[payload.AiGroupId].planners[payload.plannerId],
              details: payload.details,
            },
          },
        },
      },
    },
  }),

  [SET_AI_GROUP_LOADER]: (state: any, payload: any) => ({
    ...state,
    data: {
      ...state.data,
      loader: payload,
    },
  }),
};
export default function BudgetPlannerAiGroupReducer(
  state = initialAiGroupState,
  action:
    | SetAiGroupPlanners
    | SetAiGroupItems
    | SetAiGroupPlannerDetails
    | SetAiGroupLoader
) {
  if (actions[action.type]) return actions[action.type](state, action.payload);
  return state;
}
