import { Node, useReactFlow } from 'react-flow-renderer';
import { resolveDimentionToPixel } from './utils/facebook';
import max from 'lodash/max';

const DEFAULT_CANVAS_REPOSITION_DELAY_IN_MS = 1000;
const MIN_HEIGHT = 600;
const MIN_WIDTH = 600;

/** make sure to call the return function only after changePosition util function is called, as it updates the node positions in the canvas */
export default function useFocusToNode() {
  const reactFlow = useReactFlow();

  return (nodeId: string, repositionDelay = DEFAULT_CANVAS_REPOSITION_DELAY_IN_MS) => {
    // make sure the UI is rendered with other setNodes calls and then execute this block to reposition the nodes in view
    setTimeout(() => {
      const node = reactFlow.getNode(nodeId);
      if (!node) return;
      reactFlow.fitBounds({
        x: node.position.x,
        y: node.position.y,
        height: max([resolveDimentionToPixel(node.style?.height || 0), MIN_HEIGHT])!,
        width: max([resolveDimentionToPixel(node.style?.width || 0), MIN_WIDTH])!,
      }, {
        duration: repositionDelay,
      });
    }, 0);
  }
}

export type FocusToNode = ReturnType<typeof useFocusToNode>;