import {
  SetGSKeywordTuningData,
  SET_GS_KEYWORD_TUNING_DATA,
  RESET_GS_KEYWORD_TUNING_DATA,
  ResetGSKeywordTuningData,
} from "../../types/gsTargetingAI";
const initialData = {
  keywordCostCheck: true,
  keywordCostValue: 50,
  impressionSignificance: 50,
  competationIndexCheck: true,
  competationIndex: {
    high: true,
    medium: true,
    low: true,
  },
  monthlyAvgSearchCheck: true,
  monthlyAvgSearch: {
    min: 2000,
    max: 8000,
  },
};

export default function gsKeywordTuning(
  state: any = {
    data: initialData,
  },
  action: SetGSKeywordTuningData | ResetGSKeywordTuningData
) {
  switch (action.type) {
    case SET_GS_KEYWORD_TUNING_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          competationIndex: {
            ...(action.payload.competationIndex
              ? action.payload.competationIndex
              : state.data.competationIndex),
          },
          monthlyAvgSearch: {
            ...(action.payload.monthlyAvgSearch
              ? action.payload.monthlyAvgSearch
              : state.data.monthlyAvgSearch),
          },
        },
      };
    case RESET_GS_KEYWORD_TUNING_DATA:
      return {
        ...state.data,
        data: initialData,
      };
    default:
      return state;
  }
}
