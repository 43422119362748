import { GET_MONTAGE_COUNTRY } from './../types/montageAds';
import { DeleteCampaign, DELETE_CAMPAIGN, GetCampaign, GetMontageCampaigns, GET_CAMPAIGN,
    GET_MONTAGE_CAMPAIGNS, MontageCampaigns, SetMontageCampaigns, SetSelectedMontageCampaign,
    SET_MONTAGE_CAMPAIGNS, SET_SELECTED_MONTAGE_CAMPAIGN, ToggleVideoPanel, TOGGLE_VIDEO_PANEL,
    UploadToS3Url, GetS3Url, GET_S3_URL, GetMontageValues, GET_MONTAGE, PostMontageAds, 
    POST_MONTAGE_ADS, SET_MONTAGE, UPLOAD_TO_S3_URL, GetMontageCountry } from "../types/montageAds";

export const setDefaultValues=(payload?:any)=>({
    type: SET_MONTAGE,
    payload
})

export const getDefaultValues=(payload?:any)=>{
    console.log("payload",payload);
    return{
    type: GET_MONTAGE,
    payload
}}

export const getMontageValues = (
    callback: Function
  ): GetMontageValues => ({
    type: GET_MONTAGE,
    callback,
});

export const getMontageCountry = (
    callback: Function
  ): GetMontageCountry => ({
    type: GET_MONTAGE_COUNTRY,
    callback,
});

export const postMontageAds = (
    payload:{ selectedBrandId: string, user: any, payload: any },
    callback: Function
  ): PostMontageAds => ({
    type: POST_MONTAGE_ADS,
    payload,
    callback,
  });

export const getMontageCompaigns = (payload:{brandId:string,user:any},callback:Function):GetMontageCampaigns=>({
    type: GET_MONTAGE_CAMPAIGNS,
    payload,
    callback
})

export const getS3Url = (payload:{mimetype:string},callback:Function):GetS3Url=>({
    type: GET_S3_URL,
    payload,
    callback
})

export const uploadToS3Url = (payload:{url:string,file:any, user:any},callback:Function):UploadToS3Url=>({
    type: UPLOAD_TO_S3_URL,
    payload,
    callback
})

export const setMontageCampaigns = (payload:MontageCampaigns[]):SetMontageCampaigns=>({
    type: SET_MONTAGE_CAMPAIGNS,
    payload,
})

export const setSelectedMontageCampaign = (payload:MontageCampaigns|any):SetSelectedMontageCampaign=>({
    type: SET_SELECTED_MONTAGE_CAMPAIGN,
    payload,
})

export const toggleVideoPanelView = (payload:Boolean):ToggleVideoPanel=>({
    type: TOGGLE_VIDEO_PANEL,
    payload,
})

export const deleteCampaign = (payload: {campaignId: string, user:any}, callback:Function):DeleteCampaign=>({
    type: DELETE_CAMPAIGN,
    payload,
    callback
})

export const getCampaign = (payload: {campaignId: string, user:any}, callback:Function):GetCampaign=>({
    type: GET_CAMPAIGN,
    payload,
    callback
})
