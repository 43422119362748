import { Box, MenuItem, Skeleton } from "@mui/material";
import React from "react";

export default function LoadingSelect() {
  return (
    <Box display="column">
      <LoadingSelectItem />
      <LoadingSelectItem />
      <LoadingSelectItem />
      <LoadingSelectItem />
      <LoadingSelectItem />
    </Box>
  );
}

export function LoadingSelectItem() {
  return (
    <Box
      display="flex"
      sx={{
        margin: ".5em 1.5em 1em  1.5em",
      }}
      alignItems="center"
    >
      <Skeleton
        width="1.2em"
        sx={{
          borderRadius: "3em",
        }}
        height="1.2em"
        variant="circular"
      />
      <Box display="flex" flexDirection="column" flex={1} minWidth={0} pl="1em">
        <Skeleton height="1em" width="90%" variant="text" />
        <Skeleton height=".6em" width="60%" variant="text" />
      </Box>
    </Box>
  );
}
