import { StyledText, StyledTextProps } from "components/shared";
import React from "react";
import { LightTooltip } from "../LightTooltip/LightTooltip";
import { BoxProps } from "@mui/system";
import { truncate } from "lodash";

interface TextWithTooltipProps extends StyledTextProps {
  text: string;
  hideTooltip?: boolean;
  boxProps?: BoxProps;
  truncateLength?: number;
}

export function TextWithTooltip({
  text,
  hideTooltip,
  truncateLength,
  boxProps,
  ...props
}: TextWithTooltipProps) {
  return (
    <LightTooltip title={hideTooltip ? "" : text}>
      <StyledText
        {...props}
        flex={1}
        minWidth={0}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...boxProps,
        }}
      >
        {truncateLength ? truncate(text, { length: truncateLength }) : text}
      </StyledText>
    </LightTooltip>
  );
}
