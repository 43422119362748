export const GET_ALL_OPTIMIZERS_DETAILS = "GET_ALL_OPTIMIZERS_DETAILS";
export const GET_OPTIMIZER_METRICS = "GET_OPTIMIZER_METRICS";
export const SET_SELECTED_BRAND = "SET_SELECTED_BRAND";
export const SET_ALL_OPTIMIZERS_DETAILS = "SET_ALL_OPTIMIZERS_DETAILS";
export const SET_SUBOPTIMIZER_METRICS = "SET_SUBOPTIMIZER_METRICS";
export const POST_RESULT_METRICS = "POST_RESULT_METRICS";
export const GET_MULTIPLE_OPTIMIZER_METRICS = "GET_MULTIPLE_OPTIMIZER_METRICS";
export const SET_MULTIPLE_OPTIMIZER_METRICS = "SET_MULTIPLE_OPTIMIZER_METRICS";
export const RESET_SUBOPTIMIZER_METRICS = "RESET_SUBOPTIMIZER_METRICS";
export const RESET_SELECTED_BRAND = "RESET_SELECTED_BRAND";

export type BrandId = string;

export type OptimizerDetails = {};

export type OptimizerMetrics = {
  optimizerId: any;
  metrics: any;
};

export type GetAllOptimizersDetails = {
  type: typeof GET_ALL_OPTIMIZERS_DETAILS;
  payload: { brandId: any };
};

export type GetOptimizerMetrics = {
  type: typeof GET_OPTIMIZER_METRICS;
  payload: {
    optimizerId: any;
    selectedMonth: string;
    selectedYear: any;
    brandId: any;
  };
  callback: Function;
};

export type GetMultipleOptimizerMetrics = {
  type: typeof GET_MULTIPLE_OPTIMIZER_METRICS;
  payload: {
    optimizerIds: any[];
    selectedMonth: string;
    selectedYear: any;
    brandId: any;
  };
};
export type SetMultipleOptimizerMetrics = {
  type: typeof SET_MULTIPLE_OPTIMIZER_METRICS;
  payload: {
    subOptimizerMetrics: any;
  };
};

export type SetSelectedBrand = {
  type: typeof SET_SELECTED_BRAND;
  payload: {
    id: string;
    name: string;
  };
};

export type SetAllOptimizersDetails = {
  type: typeof SET_ALL_OPTIMIZERS_DETAILS;
  payload: any;
};

export type SetSubOptimizerMetrics = {
  type: typeof SET_SUBOPTIMIZER_METRICS;
  payload: OptimizerMetrics;
};

export type PostResultMetrics = {
  type: typeof POST_RESULT_METRICS;
  payload: {
    brandId: any;
    user: any;
    payload: any;
  };
  callback: Function;
};

export type SelectedBrandState = {
  id: "";
  name: "";
  optimizers: any;
  error: any;
};

export type ResetSubOptimizerMetrics = {
  type: typeof RESET_SUBOPTIMIZER_METRICS;
  payload: null;
};

export type ResetSelectedBrand = {
  type: typeof RESET_SELECTED_BRAND;
  payload: null;
};
