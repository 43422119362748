import {
  SetSelectedCohorts,
  SET_SELECTED_COHORTS,
} from "../../types/targetingAI";

export default function cohorts(state = [], action: SetSelectedCohorts) {
  switch (action.type) {
    case SET_SELECTED_COHORTS: {
      return action.payload;
    }
    default:
      return state;
  }
}
