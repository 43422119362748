import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.8rem;
  .drawer-header-title {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #000000;
    display: flex;
    align-items: center;
    padding: 0rem;
    .ad-title {
      margin-right: 0.5rem;
    }
  }
  .drawer-header-action-bar {
    button:disabled {
      opacity: 0.5;
    }

    display: flex;
    .publish-btn {
      background: #0869fb;
      border-radius: 0.375rem;
      margin-left: 0.625rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: #ffffff;
      padding: 0.625rem 2.125rem;
    }
    .save-variant {
      border: 1px solid #0869fb;
      border-radius: 6px;
      margin-left: 12px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      :disabled {
        border-color: rgba(0, 0, 0, 0.26);
      }
    }
  }
  .variant-configure-btn {
    border: none;
    background: white;
    padding: 0.5rem 0.75rem;
    border-radius: 0.375rem;
    cursor: pointer;
    svg {
      path {
        stroke: #0869fb;
      }
    }
  }
`;
