import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("modal-root");

const ModalWrapper: React.FC<{ children: any; className?: string }> = ({ children, className }) => {
  const modalContainer = document.createElement("div");
  if (className) {
    modalContainer.className = className;
  }
  const el = useRef<HTMLDivElement>(modalContainer);

  useEffect(() => {
    const elRef: HTMLDivElement = el.current;
    modalRoot?.appendChild(elRef);
    return () => {
      modalRoot?.removeChild(elRef);
    };
  }, []);

  return ReactDOM.createPortal(children, el.current);
};

export default ModalWrapper;
