import { Box } from "@mui/material";
import React from "react";
import { StyledLoaderText } from "../../styled";
import LoadGif from "../../../../../../assets/Gif/blueCircularLoaderTransparent.gif";

export default function Loading() {
  return (
    <Box
      width="340px"
      height="114px"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <img
        src={LoadGif}
        alt=""
        style={{ height: 50, width: 50, marginBottom: "4px" }}
      />
      <StyledLoaderText>
        Please wait while we generate your test results. Clicking "Back" will
        cancel the testing process.
      </StyledLoaderText>
    </Box>
  );
}
