import { useState } from "react";

import { Button, Grid, IconButton, Modal, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import ButtonComponent from "../../../../../common/modal/ButtonComponent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "33rem",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
  maxHeight: "21rem",
  borderRadius: "0.5rem",
};

const DeleteModal: React.FC<{
  heading: string;
  subheading: string;
  open: boolean;
  setOpen: any;
  onSubmit: Function;
}> = ({ heading, subheading, open, setOpen, children, onSubmit }) => {
  const handleClose = () => setOpen(false);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ fontFamily: "Inter" }}
      >
        <Stack sx={style} spacing={1}>
          <Box sx={{ height: "30%" }}>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                py: 1,
              }}
            >
              <Grid item xs={11}>
                <Typography variant="h5" component="div" className="font-lato">
                  <b>{heading}</b>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  sx={{
                    borderRadius: "0.5rem",
                    background: "#F2F2F2",
                    p: 0.5,
                  }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={15} sx={{ marginTop: "15px" }}>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{ color: "#6E6E6E", fontSize: "1rem" }}
                >
                  <b>{subheading}</b>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ height: "60%", overflowY: "scroll", pb: 2 }}>
            {children}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              pr: 0,
              pt: 2,
              mt: "auto",
              height: "10%",
              borderTop: "1px solid #DDDDDD",
            }}
          >
            <Button
              size="small"
              sx={{ textTransform: "none", mr: 1 }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <ButtonComponent
              variant="contained"
              label="Delete"
              sx={{ px: 2, py: 1 }}
              onClick={() => {
                onSubmit();
                setOpen(false);
              }}
              disabled={false}
            />
          </Box>
        </Stack>
      </Modal>
    </>
  );
};
export default DeleteModal;
