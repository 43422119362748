import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import BasicModal from "common/modal";
import { LightTooltip } from "components/shared/LightTooltip";
import { useDispatch, useSelector } from "react-redux";
import { setDisableDashboard } from "store/actions/adaccount";
import { disableDashboard } from "store/actions/common";
import { AdAccountState } from "store/types/adaccount";
import { initialState } from "store/types/auth";

import { ReactComponent as DisableIcon } from "../../assets/svg/disableDashboard.svg";

const DisableDashboard = ({ open, setOpen }: any) => {
  const dispatch = useDispatch();
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { selectedBrand } = adaccount;
  const handleEnable = () => {
    dispatch(
      disableDashboard(
        {
          isDisableDashboard: false,
          user: auth?.user,
          brandId: selectedBrand?.id,
        },
        (response: any, error: any) => {
          if (error) console.log(error, "error in disabling dashboard");
        }
      )
    );
  };
  return (
    <BasicModal
      open={open}
      setOpen={setOpen}
      title=""
      width="24rem"
      height="auto"
      showButton={false}
      hideCloseButton
    >
      {" "}
      <Stack
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
        }}
      >
        <DisableIcon />
        <Box>
          <Typography
            component={"h3"}
            textAlign="center"
            style={{
              fontFamily: "Inter",
              lineHeight: "2rem",
              fontSize: "1.25rem",
              fontWeight: 600,
            }}
          >
            Pixis AI Disabled
          </Typography>
          <Typography
            textAlign="center"
            component={"p"}
            style={{ color: "#5C6674", fontSize: "0.875rem", fontWeight: 400 }}
          >
            Your brand has been disabled. You can Notify the Support Team to
            Activate this Brand by clicking on Enable AI button.
          </Typography>
        </Box>
        <LightTooltip
          title="This will send an Email to Pixis' Support Team to Enable AI"
          placement="top"
          arrow
        >
          <Button
            style={{
              width: "100%",
              height: "2.5rem",
              backgroundColor: "#0869FB",
              padding: "0.875rem 1.5rem",
              borderRadius: "0.5rem",
            }}
            onClick={handleEnable}
          >
            <Typography
              sx={{
                fontSize: "0.875rem",
                fontWeight: 600,
                color: "white",
                textTransform: "capitalize",
              }}
            >
              Enable AI
            </Typography>
          </Button>
        </LightTooltip>
      </Stack>
    </BasicModal>
  );
};

export default DisableDashboard;
