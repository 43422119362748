import { Box, styled } from "@mui/material";

export const StyledToggleBox = styled(Box)`
  font-size: 0.839vw;

  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1700px) {
    margin: 0.8125em 1em 0em 0em;
  }
`;
