import { Box, LinearProgress, Paper, Popover, styled } from "@mui/material";

export const StyledLinearProgress = styled(LinearProgress)<{ bgColor: string }>`
  margin-top: 0.3em;
  height: 0.48em;
  border-radius: 0.5em;
  .MuiLinearProgress-bar {
    border-radius: 0.5em;
    background: ${({ bgColor }) => bgColor};
  }
`;

export const StyledPopover = styled(Popover)<{
  place: "editor" | "variant";
}>`
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));

  .MuiPopover-paper::before {
    content: " ";
    position: absolute;
    top: ${({ place }) => (place === "variant" ? "-11px" : ".75em")};
    left: ${({ place }) => (place === "variant" ? "1em" : "0px")};
    margin-left: ${({ place }) => (place === "variant" ? "0.75em" : "-5px")};
    border-width: 13px;
    border-style: solid;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
    border-color: ${({ place }) =>
      place === "variant"
        ? "transparent transparent #ffffff transparent"
        : "   transparent #ffffff  transparent  transparent "};
  }
`;

export const StyledPaper = styled(Paper)`
  font-size: 1vw;
  @media screen and (max-width: 1280px) {
    font-size: 11px;
  }
`;

export const StyledFooter = styled(Box)`
  /* position: absolute;
  bottom: 0px; */
  display: flex;
  background: #fff8f0;
  align-items: center;
  padding: 0.5em;
  justify-content: center;
`;

export const StyledHighlightItem = styled(Box)<{
  bgColor: string;
  textColor: string;
}>`
  display: flex;
  gap: 0.5em;
  font-family: "Inter";
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  mix-blend-mode: multiply;
  border-radius: 12.0664px;
  padding: 0.3em 0.7em;
  background: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
`;
