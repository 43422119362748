import { memo } from "react";
import { Handle } from "react-flow-renderer";
import Toggle from "react-toggle";
import "./explore-exploit.scss";
import "../index.scss";
import { useDispatch, useSelector } from "react-redux";
import { setCalibrationFormData } from "../../../store/actions/targetingAI";

export default memo(({ data, isConnectable }) => {
  const { ExploitValue: exploit, ExploitValueCheck } = useSelector(
    (state) => state.calibration.formData
  );
  const dispatch = useDispatch();

  return (
    <div className="p-3 bg-white card-style">
      {data.data.map((element, index) => {
        return (
          <div key={index}>
            <Handle
              type="target"
              position="left"
              style={{ background: "#0869FB" }}
              onConnect={(params) => console.log("handle onConnect", params)}
              isConnectable={isConnectable}
            />
            <div className="flex justify-between font-weight-bold">
              <div className="text-lg">{element.label}</div>
            </div>
            <div className="p-2 mt-1 border rounded shadow-sm w-100">
              <div className="flex justify-between w-100">
                <div
                  className={"text-md"}
                >
                  {100 - exploit}%
                </div>
                <div
                  className={"text-md"}
                >
                  {exploit}%
                </div>
              </div>
              <div className="slider-parent w-100">
                <input
                  aria-valuetext=""
                  className="slider-input w-100"
                  type="range"
                  disabled={false}
                  min="0"
                  max="100"
                  defaultValue={exploit}
                  onChange={({ target: { value: radius } }) => {
                    // temporary fix (not ideal, has to be maintained through state)
                    dispatch(
                      setCalibrationFormData({
                        key: "ExploitValue",
                        value: radius,
                      })
                    );
                  }}
                />
              </div>
              <div className="flex justify-between w-100">
                <div
                  className={`${
                    !element.checkBoxValue ? "disabled-ui text-md" : "text-md"
                  }`}
                >
                  Explore
                </div>
                <div
                  className={`${
                    !element.checkBoxValue ? "disabled-ui text-md" : "text-md"
                  }`}
                >
                  Exploit
                </div>
              </div>
            </div>
            {data.targetHandle && (
              <Handle
                type="source"
                position="right"
                style={{ background: "#0869FB" }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
              />
            )}
          </div>
        );
      })}
    </div>
  );
});
