import { StyledText } from "components/shared";
import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as Eye } from "../../../../../assets/cai/svg/eye.svg";
import { ReactComponent as Launch } from "../../../../../assets/cai/svg/launch.svg";
import { ReactComponent as Person } from "../../../../../assets/cai/svg/person.svg";
import { ReactComponent as Gender } from "../../../../../assets/cai/svg/gender.svg";
import { ReactComponent as Pixis } from "../../../../../assets/cai/svg/pixis.svg";
import { ReactComponent as Review } from "../../../../../assets/cai/svg/review.svg";
import { ReactComponent as Info } from "../../../../../assets/cai/svg/info_solid.svg";
import { ReactComponent as Target } from "../../../../../assets/cai/svg/target.svg";
import { getAdsetById } from "store/actions/CreativeAI_Revamp/facebookActions";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "store/types/auth";
import {
  StyledAdSetModalDivider,
  StyledViewAdSetDetailsContent,
  ViewAdSetDetailsModalButton,
  ViewAdSetDetailsModalButtonDivider,
} from "./styled";
import { Box } from "@mui/system";
import { PxDialog, TextWithTooltip } from "components/CreativeAI_Revamp/shared";
import { IconButton, Skeleton } from "@mui/material";
import AdSetDetailItem from "./AdsetDetailItem";
import TargetingItem from "./TargetingItem";
import { getComaSeparatedDate } from "../../utils/common.utils_old";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { useCaiFbMixPanel } from "../hooks/useCaiFbMixPanel";

// Modal Title

const ViewAdSetDetailsModalTitle = ({ adLink }: { adLink: string }) => {
  const { adsetDetails } = useSelector(
    (state: { caiFBReducer: any }) => state.caiFBReducer
  );
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <StyledText
          fontWeight={700}
          fontSize="1.25em"
          lineHeight="1.5em"
          pr=".25em"
        >
          Adset Details
        </StyledText>
        <IconButton onClick={() => window.open(adLink)}>
          <Launch />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center">
        <StyledText
          fontWeight={400}
          fontSize="0.75em"
          lineHeight="0.75em"
          pr=".25em"
          color="#949494"
        >
          Ad Count :
        </StyledText>
        <StyledText
          fontWeight={500}
          fontSize="0.75em"
          lineHeight="0.9375em"
          color="#4D4D4D"
        >
          {adsetDetails?.adsCount}
          {adsetDetails?.facebookData?.campaign_objective == "OUTCOME_SALES"
            ? `/150`
            : `/50`}
        </StyledText>
      </Box>
    </Box>
  );
};

export default function ViewAdSetDetailsModal() {
  const { adAccountsSelection, adSetSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const { selectedAd } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const adLink = `https://business.facebook.com/adsmanager/manage/adsets?act=${adAccountsSelection?.selectedAdAccountId}&filter_set=SEARCH_BY_CAMPAIGN_IDS-STRING_SET%1EANY%1E[%22${adSetSelection?.selectedAdSetId}%22]`;
  const [openModal, setOpenModal] = useState(false);
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { adsetDetails } = useSelector(
    (state: { caiFBReducer: any }) => state.caiFBReducer
  );
  const {agencyAssets} = useSelector((state:any) => state.common);
  const { mixPanelAdSetDetailsViewed } = useCaiFbMixPanel();
  const dispatch = useDispatch();
  useEffect(() => {
    if (adSetSelection?.selectedAdSetId)
      dispatch(
        getAdsetById({
          adsetId: adSetSelection?.selectedAdSetId,
          user: auth.user,
        })
      );
  }, [adSetSelection?.selectedAdSetId]);
  return adSetSelection?.selectedAdSetId ? (
    <Fragment>
      <ViewAdSetDetailsModalButton
        onClick={() => {
          setOpenModal(true);
          mixPanelAdSetDetailsViewed();
        }}
      >
        <Box display="flex" alignItems="center" pb=".18em">
          <Eye />
          <StyledText
            fontSize="0.75em"
            fontWeight={600}
            lineHeight="0.9375em"
            letterSpacing="0.016"
            color="#0869fb"
            pl=".35em"
          >
            View Adset Details
          </StyledText>
        </Box>
        <ViewAdSetDetailsModalButtonDivider />
      </ViewAdSetDetailsModalButton>
      {openModal && (
        <PxDialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          titleComponent={<ViewAdSetDetailsModalTitle adLink={adLink} />}
          maxWidth="xs"
          fullWidth
        >
          <StyledViewAdSetDetailsContent>
            <Box display="flex" alignItems="center">
              <Info />
              <Box
                display="flex"
                flexDirection="column"
                pl=".5em"
                minWidth={0}
                flex={1}
              >
                <StyledText
                  fontSize="0.625em"
                  fontWeight={400}
                  lineHeight="1.2em"
                  color="#333333"
                  pb=".35em"
                >
                  {adsetDetails?.facebookData?.id}
                </StyledText>
                <TextWithTooltip
                  fontSize="0.75em"
                  fontWeight={500}
                  lineHeight="1.25em"
                  text={adsetDetails?.facebookData?.name}
                />
              </Box>
            </Box>
            <StyledAdSetModalDivider />

            <Box display="flex" alignItems="start">
              <Box mt="-.5em">
                <Target />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                pl=".5em"
                flex={1}
                minWidth={0}
              >
                <StyledText
                  fontSize="0.625em"
                  fontWeight={400}
                  lineHeight="1.2em"
                  color="#64C073"
                  pb=".75em"
                >
                  Detailed Targeting
                </StyledText>
                <Box display="flex" flex={1} minWidth={0}>
                  <TargetingItem
                    title="Interests: "
                    subTitle={
                      adsetDetails?.facebookData?.targeting?.flexible_spec
                        ? adsetDetails?.facebookData?.targeting
                            ?.flexible_spec[0]?.interests
                          ? getComaSeparatedDate(
                              adsetDetails?.facebookData?.targeting
                                ?.flexible_spec[0]?.interests
                            )
                          : "-"
                        : "-"
                    }
                  />
                  <TargetingItem
                    title="Behaviour: "
                    subTitle={
                      adsetDetails?.facebookData?.targeting?.flexible_spec
                        ? adsetDetails?.facebookData?.targeting
                            ?.flexible_spec[0]?.behaviors
                          ? getComaSeparatedDate(
                              adsetDetails?.facebookData?.targeting
                                ?.flexible_spec[0]?.behaviors
                            )
                          : "-"
                        : "-"
                    }
                  />
                  {/* <TargetingItem
                    title="Demographic:"
                    subTitle={[
                      "Cricket",
                      "BCCI",
                      "ok",
                      "Cricket",
                      "BCCI",
                      "ok",
                      "Cricket",
                      "BCCI",
                      "ok",
                      "Cricket",
                      "BCCI",
                      "ok",
                    ].join(",")}
                  /> */}
                </Box>
              </Box>
            </Box>
            <StyledAdSetModalDivider />

            <Box display="flex">
              <AdSetDetailItem
                svg={<Person />}
                title="Age Group"
                subTitle={`${adsetDetails?.facebookData?.targeting?.age_min}-${adsetDetails?.facebookData?.targeting?.age_max}`}
                color="#F99E57"
              />

              <AdSetDetailItem
                svg={<Gender />}
                title="Gender"
                subTitle={
                  adsetDetails?.facebookData?.targeting?.genders &&
                  adsetDetails?.facebookData?.targeting?.genders[0] === 1
                    ? "Male"
                    : adsetDetails?.facebookData?.targeting?.genders &&
                      adsetDetails?.facebookData?.targeting?.genders[0] === 2
                    ? "Female"
                    : "All"
                }
                color="#EB59AC
              "
              />
            </Box>
            <StyledAdSetModalDivider />
            <Box display="flex">
              <AdSetDetailItem
                svg={agencyAssets.logo ?
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#F5F9FF",
                      borderRadius: "8px"
                    }}
                  >
                    <img
                      src={agencyAssets.logo}
                      style={{ width: "10px", height: "10px" }}
                      alt="logo-icon"
                    />
                  </div> : <Pixis />}
                title={`Published using ${agencyAssets.title || 'Pixis'}`}
                subTitle={adsetDetails?.pixisPublishedVariants}
                color="#0869FB"
              />
              <AdSetDetailItem
                svg={<Review />}
                title="Under Review Ads"
                subTitle={adsetDetails?.adset?.underReviewVariants}
                color="#8832F5"
              />
            </Box>
          </StyledViewAdSetDetailsContent>
        </PxDialog>
      )}
    </Fragment>
  ) : (
    <Skeleton width="8em" height="1.5em" />
  );
}
