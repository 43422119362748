import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CheckBox } from "../Input";
import { Box } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "17px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    // borderBottom: "1px solid lightGray",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "red",
  boxShadow: "0px 1px 12px rgba(0, 0, 0, 0.1)",
  border: "1px solid #DDDDDD",
  borderRadius: "8px 8px 0px 0px",
}));
const StyledTableHead = styled(TableHead)(({ theme }) => ({
  boxShadow: "0px 1px 12px rgba(0, 0, 0, 0.1)",
  background: "#FFFFFF",
  borderRadius: "8px 8px 0px 0px",
  // border: "0.2px solid rgb(0 0 0 / 10%)",
  borderBottom: "none",

  "&:first-child td, &:first-child th": {
    border: 0,
  },
}));

interface tableHeadProp {
  name: string;
  align: any;
}

const SelectableTable: React.FC<{
  tableHead: tableHeadProp[];

  checkboxVisible: boolean;
  children?: React.ReactNode;
  masterCheck?: any;
  handleMasterCheckbox?: any;
}> = ({
  tableHead,
  masterCheck,
  checkboxVisible,
  children,
  handleMasterCheckbox,
}) => {
  return (
    <TableContainer
      component={Paper}
      // style={{ border: "1px solid lightGray" }}
    >
      <Table sx={{ minWidth: "100%" }} aria-label="customized table">
        <StyledTableHead style={{ background: "red" }}>
          <StyledTableRow>
            {checkboxVisible && (
              <StyledTableCell>
                <Box>
                  {/* <CheckBox checked={masterCheck} onChange={handleMasterCheckbox} />
                   */}
                  <CheckBox
                    checked={masterCheck}
                    onClick={(e) => handleMasterCheckbox()}
                  />
                </Box>
              </StyledTableCell>
            )}
            {tableHead?.map((value, index) => {
              return (
                <StyledTableCell align={value.align} key={index}>
                  {value.name}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        </StyledTableHead>

        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default SelectableTable;
