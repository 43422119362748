import ActionSummary from "./ActionSummary/ActionSummary";
import KeyIndicator from "./KeyIndicator/KeyIndicator";
import PerformanceSummary from "./PerformanceSummary/PerformanceSummary";
import ComingSoon from "./ComingSoon/ComingSoon";
import { CustomizedDivider, StyledSummaryBoardCard } from "./styled";

export default function SummaryBoard({
  parentLoading,
}: {
  parentLoading?: boolean;
}) {
  return (
    <StyledSummaryBoardCard>
      <PerformanceSummary parentLoading={parentLoading} />
      <CustomizedDivider />
      <KeyIndicator parentLoading={parentLoading} />
      <CustomizedDivider />
      <ActionSummary parentLoading={parentLoading} />
      {/* <ComingSoon /> */}
    </StyledSummaryBoardCard>
  );
}
