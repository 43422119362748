import { Box, styled } from "@mui/system";

export const StyledAppBarBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  min-width: 0;
`;

export const StyledTextBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`;

export const StyledTextBoxItem1 = styled(Box)`
  display: flex;
  align-items: center;
  min-width: 0;
`;

export const StyledAppBarBoxItem2 = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 3em;
`;

export const StyledAppBarBoxItem3 = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.4em;
`;
