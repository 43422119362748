import { Box, styled } from "@mui/material";

export const StyledVerticalDivider = styled(Box)`
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
`;

export const StyledDivider = styled(Box)`
  border-top: ${({ color }: { color?: string }) =>
    `1px solid ${color ?? "#dddddd"}`};
  border-bottom: ${({ color }: { color?: string }) =>
    `1px solid ${color ?? "#dddddd"}`};
`;
