import React from "react";
import { IDayCell } from "../DateRangePicker";

export const DayCell: React.FC<{
  day: any;
  onSelectDate: Function;
  value: any;
  onHover: Function;
  hoveredCell: IDayCell | null;
  isDisabled: boolean;
  isToday: boolean;
}> = ({
  day,
  onSelectDate,
  value,
  onHover,
  hoveredCell,
  isDisabled,
  isToday,
}) => {
  let styles = {
    color: "#000",
    borderTop: "0",
    borderBottom: "0",
  };

  const isSelected = () => {
    return (
      value?.[0] === day.fullDateString || value?.[1] === day.fullDateString
    );
  };

  const isInBetweenStartAndHovered = () => {
    return (
      value?.[1] === null &&
      hoveredCell &&
      value?.[0] &&
      day.fullDateNumber > parseInt(value?.[0].split("/").join("")) &&
      day.fullDateNumber <= hoveredCell.fullDateNumber
    );
  };
  const isInBetweenSelection = () => {
    return (
      value?.[0] &&
      value?.[1] &&
      day.fullDateNumber > parseInt(value?.[0].split("/").join("")) &&
      day.fullDateNumber < parseInt(value?.[1].split("/").join(""))
    );
  };

  return day ? (
    <div
      style={{
        opacity: isDisabled ? "0.3" : "1",
        background:
          isSelected() || isInBetweenSelection()
            ? "rgb(61, 145, 255)"
            : isInBetweenStartAndHovered()
            ? "#EEE"
            : "white",
        color: !isToday
          ? isSelected() || isInBetweenSelection()
            ? "white"
            : "black"
          : " rgb(61, 145, 255)",

        cursor: isDisabled ? "not-allowed" : "pointer",
        pointerEvents: isDisabled ? "none" : "auto",
        borderBottom: isToday ? "3px solid rgb(61, 145, 255)" : "none",
      }}
      className={`each-cell`}
      onClick={() => day && onSelectDate(day)}
      onMouseEnter={() => onHover(day)}
    >
      {day?.day}
    </div>
  ) : (
    <div className="each-cell" style={{ pointerEvents: "none" }}></div>
  );
};
