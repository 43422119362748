import React, { useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Toggle from "react-toggle";
import { useSelector, useDispatch } from "react-redux";
import { setGSKeywordTuningData } from "store/actions/gsTargetingAI";

import { ReactComponent as InfoIcon } from "../../assets/svg/infoIcon.svg";
import CustomSlider from "../../common/CustomSlider";

function KeywordCost() {
  const dispatch = useDispatch();
  const { keywordCostCheck, keywordCostValue } = useSelector(
    (state: any) => state.gsTargetingAI.gsKeywordTuning.data
  );
  const [checked, setChecked] = useState(keywordCostCheck);
  const [selectedValue, setSelectedValue] = useState(keywordCostValue);

  const changeSelectedValue = (selectedValue: number) => {
    setSelectedValue(selectedValue);
    dispatch(
      setGSKeywordTuningData({
        keywordCostValue: selectedValue,
      })
    );
  };

  return (
    <Stack
      spacing={1}
      sx={{
        width: "50%",
        background: "#FFFFFF",
        border: "1px solid #EFEFEF",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
        borderRadius: "10px",
        m: 2,
        p: 2,
      }}
    >
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="font-12 font-600">
          Keyword Cost <InfoIcon />
        </Box>
        <Toggle
          checked={checked}
          className="right-0 custom-react-toggle"
          icons={false}
          id="biscuit-status"
          aria-labelledby="biscuit-label"
          onChange={() => {
            if (checked) {
              setChecked(false);
              setSelectedValue(50);
              dispatch(
                setGSKeywordTuningData({
                  keywordCostCheck: false,
                  keywordCostValue: 50,
                })
              );
            } else {
              setChecked(true);
              dispatch(
                setGSKeywordTuningData({
                  keywordCostCheck: true,
                })
              );
            }
          }}
        />
      </Stack>
      <Box className="font-10">
        Set the weightage for Cost on the scale of 0 to 100, in calculation of
        Performance Score
      </Box>
      <Divider
        variant="middle"
        style={{ borderColor: "#EFEFEF", opacity: "0.1" }}
      />
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="font-12 font-600">Selected Value</Box>
        <Box
          className="font-12 font-600"
          sx={{
            background: "#EAF1F7",
            color: "#0869FB",
            borderRadius: "5px",
            p: 1,
          }}
        >
          {selectedValue}%
        </Box>
      </Stack>
      <CustomSlider
        minValue={0}
        maxValue={100}
        selectedValue={selectedValue}
        setSelectedValue={changeSelectedValue}
        showTooltip
        disabled={!checked}
        sliderFor="keywordCost"
      />
    </Stack>
  );
}

export default KeywordCost;
