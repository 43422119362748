import React from "react";
import CustomizedDialogs from "./CustomizedDialogs";
import { DialogTitle } from "./styled";
import { Button } from "../Button/Button";
import { Box, CircularProgress } from "@mui/material";

interface ConfirmDialogProps {
  onHandel: any;
  bodyText: string;
  completeButtonText?: string;
  closeButtonText?: string;
  loading?: boolean;
  dialogType: String;
  headerText?: String;
  id?: string;
}

export const AlertConfirm = ({
  onHandel,
  bodyText,
  completeButtonText,
  closeButtonText,
  loading = false,
  dialogType = "confirm",
  headerText = dialogType === "confirm"
    ? "Are You Sure"
    : dialogType === "alert"
    ? "Alert"
    : "",
  id,
}: ConfirmDialogProps) => {
  return (
    <CustomizedDialogs
      show={true}
      type={
        dialogType === "confirm"
          ? "modal"
          : dialogType === "alert"
          ? "modal"
          : "ConfirmDialog"
      }
      fullScreen={false}
      maxWidth="sm"
      title={
        dialogType === "confirm"
          ? headerText
          : dialogType === "alert"
          ? headerText
          : ""
      }
      footer={false}
      titleSize={20}
      isHeightRequired={false}
    >
      <Box width="350px">
        <DialogTitle className="mb-0">{bodyText}</DialogTitle>
        <Box
          display="flex"
          justifyContent="flex-end"
          mt="32px"
          alignItems="center"
        >
          <Box mr="20px">
            {dialogType !== "alert" && (
              <Button
                onClick={() => onHandel({ state: false, sText: "cancel" }, id)}
                buttonVariant="outlined"
              >
                {closeButtonText ?? "Cancel"}
              </Button>
            )}
          </Box>

          <Button
            buttonVariant="contained"
            className="button-base-mui"
            onClick={() =>
              onHandel(
                {
                  state: dialogType === "alert" ? false : true,
                  sText: "ok",
                },
                id
              )
            }
          >
            {completeButtonText ?? "OK"}
            {loading && (
              <CircularProgress
                size={15}
                sx={{
                  color: "white",
                }}
                thickness={5}
                style={{ marginLeft: "10px" }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </CustomizedDialogs>
  );
};
