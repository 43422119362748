import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as Success } from "../../../../../assets/svg/pacing-success.svg";
import { ReactComponent as Warning } from "../../../assets/warning-icon-gsai.svg";
import "../../index.scss";

function SuccessModel({ setModal, modal, props, title, key, onSubmit = {} }) {
  return (
    <div
      className="container-overlay"
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0,0,0,0.7)",
        zIndex: "1032",
        top: "0",
        left: "0",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex flex-column focus:outline-none"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            width: "25.75em",
            height: "17.8325em",
            padding: "1.2rem",
          }}
        >
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal({ key: "", props: {} });
                }}
              ></button>
            </div>
            <div className="d-flex justify-content-center">
              {modal.key === "success" ? (
                <Success />
              ) : (
                <Warning className="warning-icon" />
              )}
            </div>
            <h3
              className="d-flex flex-column justify-content-center "
              style={{
                fontWeight: "700",
                fontSize: "1.875rem",
                textAlign: "center",
                color: "#333333",
                padding: "0.5rem",
              }}
            >
              {title}
            </h3>
            <span
              className="d-flex flex-column justify-content-center "
              style={{
                fontWeight: "500",
                fontSize: "0.875rem",
                color: "#727272",
                padding: "0.5rem",
                textAlign: "center",
                marginBottom: "0.8rem",
              }}
            >
              {props.message}
            </span>
            {console.log(key, "key")}
            {modal.key !== "success" && (
              <Box className="d-flex flex-column align-items-center justify-content-center">
                <Typography
                  component={"button"}
                  sx={{
                    width: "7.2rem",
                    height: "2.5rem",
                    backgroundColor: "#0869FB",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: "0.5rem",
                    border: "none",
                    fontWeight: 600,
                    fontFamily: "Inter",
                    mb: "0.5rem",
                  }}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Continue
                </Typography>{" "}
                <p
                  style={{
                    width: "5rem",
                    color: "#0869FB",
                    cursor: "pointer",
                    textAlign: "center",
                    fontWeight: 600,
                    fontFamily: "Inter",
                  }}
                  onClick={() => setModal({ key: "", props: {} })}
                >
                  Cancel
                </p>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessModel;
