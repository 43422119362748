import React, { useRef } from "react";
import TableElement from "../../ui/Table/TableElement";
import Table from "../../ui/Table/Table";
import info from "../../assets/svg/smallinfo.svg";
import InputText from "../../ui/InputText/InputText";
import MultiSelect from "../../ui/MultiSelect/MultiSelect";
import { UnlockTargetingAI } from "../../utils/constants/targetingAI";
import ModalContainer from "../Modal/ModalContainer";
import ModalWrapper from "../Modal/ModalWrapper";
import StepwiseFormContainer from "../StepwiseForm/StepwiseFormContainer";
import StepwiseFormWrapper from "../StepwiseForm/StepwiseFormWrapper";
import "./index.scss";
import CheckBox from "../../ui/CheckBox/CheckBox";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrowdown.svg";
import { ReactComponent as PlusCircle } from "../../assets/svg/pluscircle.svg";
import { ReactComponent as Delete } from "../../assets/svg/delete.svg";
import { useEffect } from "react";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../store/actions/form";
import { ClusterState } from "../../store/types/cluster";
import { AdAccountState } from "../../store/types/adaccount";
import {
  getClusterDetails,
  setClusterKeywords,
  setClusterLoader,
  setTimer,
  setClusterTable,
} from "../../store/actions/cluster";
import axios from "axios";
import { FormState } from "../../store/types/form";

import { initialState } from "../../store/types/auth";
import { mixPanelAdEvent } from "../../utils/mixpanel";

const headers = ["Keywords Clusters", "Average monthly searches", "Match type"];
// "Competition","Competition Index","Top of bid (Low range)","Top of bid (High range)"];
const matchTypeOptions = [
  {
    name: "Exact",
    value: "exact",
  },
  {
    name: "Phrase",
    value: "phrase",
  },
  {
    name: "Broad",
    value: "broad",
  },
];

type ClusterKeyword = {
  keyword: string;
  match_type: Array<string>;
  sv: number;
};
type Cluster = {
  cluster_type: string;
  cluster_sv: number;
  cluster_label: string;
  cluster_keywords: Array<ClusterKeyword>;
};

const KeywordsClustering: React.FC = (): JSX.Element => {
  const target = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const [showKeyWords, setShowKeywords] = React.useState(-1);
  const [keywordDetails, setKeywordDetails] = React.useState<any>(null);
  const [selectedClusters, setSelectedClusters] = React.useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = React.useState<boolean>(false);
  const [dontAskForDelete, setDontAskForDelete] =
    React.useState<boolean>(false);
  const clusterState = useSelector(
    (state: { cluster: ClusterState }) => state.cluster
  );
  const {
    clusterKeywords,
    clusterDetails,
    timer,
    clusterLoader,
    clusterName,
    clusterTable,
  } = clusterState;
  const { selectedAdAccount, selectedGSAIAdaccount, selectedBrand } =
    useSelector((state: { adaccount: AdAccountState }) => state.adaccount);
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { modal } = formState;
  const timerRef = useRef<any>(null);
  const params = new URLSearchParams(history.location.search);
  const getKeyWords = (index: number) => {
    const clusterData = clusterKeywords?.[index];
    let keywords = [];
    for (let i = 0; i < 3; i++) {
      keywords.push(clusterData?.cluster_keywords[i]?.keyword || "");
    }
    return keywords.length === 0 ? "No Keywords" : keywords.join(",") + ",...";
  };

  const reformatMatchType = (kmt: string) => {
    let mtArray: Array<string> = [];
    if (kmt != "") {
      let mt = kmt.replace(/'/g, '"');
      mt = JSON.parse(mt);
      mtArray = [...mt, ...mtArray];
    }
    return mtArray;
  };

  const reformatJSON = (data: any) => {
    let indexMap: any = {};
    let result: any[] = [];
    data.forEach((keyword: any) => {
      if (indexMap[keyword.cluster_id] != undefined) {
        result[keyword.cluster_id].cluster_keywords.push({
          keyword: keyword.keyword,
          match_type: reformatMatchType(keyword.keyword_match_type),
          sv: keyword.keyword_sv,
        });
      } else {
        indexMap[keyword.cluster_id] = result.length;
        result.push({
          cluster_label: keyword.cluster_label,
          cluster_type: keyword.cluster_type,
          cluster_sv: keyword.cluster_sv,
          cluster_id: keyword.cluster_id,
          cluster_keywords: [
            {
              keyword: keyword.keyword,
              match_type: reformatMatchType(keyword.keyword_match_type),
              sv: keyword.keyword_sv,
            },
          ],
        });
      }
    });
    return result;
  };

  useEffect(() => {
    if (selectedGSAIAdaccount?.id && clusterDetails?.url) {
      dispatch(setClusterLoader(true));
      //fetch json from url
      dispatch(
        getClusterDetails(
          { url: clusterDetails.url },
          (response: any, error: boolean) => {
            if (response) {
              let data = reformatJSON(response);
              dispatch(setClusterKeywords(data));

              //console.log("dispatch -> setclusterkeywords",data);
              dispatch(setClusterLoader(false));
            }
          }
        )
      );
    }
  }, [clusterDetails, selectedGSAIAdaccount]);

  useEffect(() => {
    if (clusterKeywords) {
      const indexes: number[] = clusterKeywords.reduce(
        (acc: any, val: any, index: number) => {
          if (clusterTable) {
            const found: any = clusterTable.find(
              (data: any) => data.cluster_id === val.cluster_id
            );
            if (found) {
              return [...acc, index];
            }
          }
          return acc;
        },
        []
      );
      setSelectedClusters(indexes);
    }
  }, [clusterKeywords, clusterTable]);

  useEffect(() => {
    timerRef.current = timer;
  }, [timer]);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        dispatch(setTimer(null));
      }
    },
    []
  );

  useEffect(() => {
    if (clusterKeywords?.length !== selectedClusters?.length) {
      setIsAllSelected(false);
    } else if (clusterKeywords?.length === selectedClusters?.length) {
      setIsAllSelected(true);
    }
  }, [selectedClusters]);

  const getValueFormat = (options: any[]) => {
    return matchTypeOptions.map((option) => ({
      name: option.name,
      value: option.value,
      isSelected: options.includes(option.value),
    }));
  };

  const handleKeywordDelete = useCallback((params: any) => {
    const props = params;

    //console.log("clusterKeywords>> ",clusterKeywords);
    if (props) {
      const { clusterIndex, keywordIndex, clusterKeywords } = props;
      const newClusterDetails = (clusterKeywords || []).map(
        (cluster: any, index: number) => {
          if (index === clusterIndex) {
            if (keywordIndex > -1) {
              cluster.cluster_sv -= cluster.cluster_keywords[keywordIndex].sv;
            }
            cluster.cluster_keywords = cluster.cluster_keywords.filter(
              (keyword: any, kIndex: number) => kIndex !== keywordIndex
            );
          }
          return cluster;
        }
      );
      dispatch(setClusterKeywords(newClusterDetails));
      //console.log("dispatch -> setclusterkeywords",newClusterDetails);
      dispatch(setModal(null));
    }
  }, []);

  const handleKeywordAdd = useCallback(() => {
    const props = modal?.props;
    if (props && keywordDetails) {
      const { clusterIndex } = props;
      const newClusterDetails = clusterKeywords.map(
        (cluster: any, index: number) => {
          if (index === clusterIndex) {
            cluster.cluster_sv =
              parseInt(cluster.cluster_sv) + parseInt(keywordDetails.sv);
            cluster.cluster_keywords = [
              keywordDetails,
              ...cluster.cluster_keywords,
            ];
          }
          return cluster;
        }
      );
      dispatch(setClusterKeywords(newClusterDetails));

      //console.log("dispatch -> setclusterkeywords",newClusterDetails);
      dispatch(setModal(null));
    }
  }, [keywordDetails]);

  const handleDisable = useCallback(() => {
    return !(
      keywordDetails &&
      (keywordDetails.match_type || []).length > 0 &&
      keywordDetails.keyword &&
      keywordDetails.sv
    );
  }, [keywordDetails]);

  const handleKeywordDetailsUpdate = useCallback(
    (key, value) => {
      let matchType: any[] = value;
      if (key === "match_type") {
        const selected = matchType.filter((type: any) => type.isSelected);
        matchType = selected.map((type: any) => type.value);
      }
      setKeywordDetails((details: any) => ({
        ...details,
        [key]: key === "match_type" ? matchType : value,
      }));
    },
    [setKeywordDetails]
  );

  const handleClusterMatchUpdate = useCallback(
    (value, clusterIndex, keywordIndex, clusterKeywords) => {
      //console.log("clusterKeywords ",clusterKeywords);
      const selected = value.filter((type: any) => type.isSelected);
      const matchType = selected.map((type: any) => type.value);
      const newClusterKeywords = (clusterKeywords || []).map(
        (cluster: any, index: number) => {
          if (index === clusterIndex) {
            cluster.cluster_keywords.map((keyword: any, kIndex: number) => {
              if (kIndex === keywordIndex) {
                keyword["match_type"] = matchType;
              }
              return keyword;
            });
          }
          return cluster;
        }
      );
      dispatch(setClusterKeywords(newClusterKeywords));
      //console.log("dispatch -> setclusterkeywords",newClusterKeywords);
    },
    []
  );

  const handleClusterSelect = useCallback(
    (index: number) => {
      if (selectedClusters.includes(index)) {
        setSelectedClusters((clusters: any[]) =>
          clusters.filter((v) => v !== index)
        );
      } else {
        setSelectedClusters((clusters: any[]) => [...clusters, index]);
      }
    },
    [selectedClusters]
  );

  const handleSubmit = useCallback(() => {
    dispatch(
      setClusterTable(
        clusterKeywords
          .filter(
            (_: any, index: number) =>
              selectedClusters.includes(index) || isAllSelected
          )
          .map((cluster: any) => {
            let newK: any = [];
            let c = { ...cluster };
            cluster.cluster_keywords.forEach((keyword: any) => {
              if (keyword.match_type.length > 1) {
                keyword.match_type.forEach((match: any) => {
                  newK.push({
                    match_type: match,
                    text: keyword.keyword,
                    negative: false,
                  });
                });
              } else {
                newK.push({
                  match_type: keyword.match_type[0],
                  text: keyword.keyword,
                  negative: false,
                });
              }
            });
            c.keywords = newK;
            c.cluster_keywords = cluster.cluster_keywords.map(
              (k: any) => k.keyword
            );
            return c;
          })
      )
    );
    mixPanelAdEvent("Continue_click_keyword_clustering_gsai", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      brand_type: selectedBrand?.brandType,
      platform_name: "Google",
      ad_account_id: selectedGSAIAdaccount?.id,
      ad_account_name: selectedGSAIAdaccount?.adAccount,
      cluster_names: clusterKeywords
        ?.filter(
          (_: any, index: number) =>
            selectedClusters.includes(index) || isAllSelected
        )
        .map((newK: any) => newK.cluster_label),
      number_of_clusters: clusterKeywords?.filter(
        (_: any, index: number) =>
          selectedClusters.includes(index) || isAllSelected
      ).length,
    });
    history.push(
      `/googlesearchai/create-ad-and-publish${history.location.search}`
    );
  }, [history, isAllSelected, selectedClusters]);

  const handleClose = useCallback(() => {
    dispatch(setClusterTable([]));
    setSelectedClusters([]);
    setIsAllSelected(false);
    dispatch(setModal({ key: "closeCluster", title: "Close cluster creator" }));
  }, []);

  return (
    <div className="keyword-cluster">
      <StepwiseFormWrapper>
        <StepwiseFormContainer
          loading={clusterLoader}
          disabled={!isAllSelected && selectedClusters.length === 0}
          steps={["Keyword Clustering", "Create Ad & Publish"]}
          initialActive={1}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        >
          <div
            className="d-flex flex-column p-3 bg-white message-container flex-grow-1 mt-2"
            style={{ height: "calc(100vh - 257px)" }}
          >
            <div className="d-flex flex-column m-3 bg-white message-container flex-grow-1 mt-2 overflow-auto">
              {!clusterLoader && (
                <Table
                  headers={headers}
                  allowSelection
                  stickyTop={true}
                  topPosition={"0"}
                  isSelected={isAllSelected}
                  handleSelection={() => {
                    if (isAllSelected) {
                      setSelectedClusters([]);
                      setIsAllSelected(false);
                    } else {
                      setSelectedClusters([
                        ...Array(clusterKeywords?.length).keys(),
                      ]);
                      setIsAllSelected(true);
                    }
                  }}
                >
                  {(clusterKeywords || []).map(
                    (cluster: any, clusterIndex: number) => (
                      <>
                        <TableElement type="tr">
                          <TableElement type="td" className="d-flex">
                            <CheckBox
                              value={
                                selectedClusters.includes(clusterIndex) ||
                                isAllSelected
                              }
                              onChange={() => handleClusterSelect(clusterIndex)}
                            />
                            <span
                              className={`action ${
                                showKeyWords === clusterIndex ? "active" : ""
                              }`}
                              onClick={() =>
                                setShowKeywords(
                                  showKeyWords !== clusterIndex
                                    ? clusterIndex
                                    : -1
                                )
                              }
                            >
                              <ArrowDown />
                            </span>
                          </TableElement>
                          <TableElement
                            type="td"
                            className="cell position-relative"
                          >
                            <span>
                              {cluster.cluster_label}(
                              {cluster.cluster_keywords.length})
                            </span>
                            <span>{getKeyWords(clusterIndex)}</span>
                            <span
                              className="action-float position-absolute"
                              onClick={() => {
                                const blankKeywordDetails = {
                                  keyword: "",
                                  keywordDetails: "",
                                  match_type: [],
                                };
                                setKeywordDetails(blankKeywordDetails);
                                UnlockTargetingAI.addKeyword[2].options =
                                  UnlockTargetingAI.addKeyword[2].options?.map(
                                    (option) => {
                                      option.isSelected = false;
                                      return option;
                                    }
                                  );
                                dispatch(
                                  setModal({
                                    key: "clusterName",
                                    title: cluster.cluster_label,
                                    props: { clusterIndex },
                                  })
                                );
                              }}
                            >
                              <PlusCircle />
                            </span>
                          </TableElement>
                          <TableElement type="td">
                            {cluster.cluster_sv}
                          </TableElement>
                          <TableElement type="td">-</TableElement>
                        </TableElement>
                        {showKeyWords === clusterIndex &&
                          cluster.cluster_keywords.map(
                            (keyword: any, keywordIndex: number) => (
                              <TableElement type="tr" key={keywordIndex}>
                                <TableElement type="td"></TableElement>
                                <TableElement
                                  type="td"
                                  className="cell position-relative"
                                >
                                  {keyword.keyword}
                                  <span
                                    className="action action-float position-absolute"
                                    onClick={() =>
                                      !dontAskForDelete
                                        ? dispatch(
                                            setModal({
                                              key: "deleteKeyword",
                                              title: `Delete Keyword: ${cluster.cluster_label}`,
                                              props: {
                                                clusterIndex,
                                                keywordIndex,
                                                clusterKeywords,
                                              },
                                            })
                                          )
                                        : handleKeywordDelete({
                                            clusterIndex: clusterIndex,
                                            keywordIndex: keywordIndex,
                                            clusterKeywords: clusterKeywords,
                                          })
                                    }
                                  >
                                    <Delete />
                                  </span>
                                </TableElement>
                                <TableElement type="td">
                                  {keyword.sv}
                                </TableElement>
                                <TableElement type="td">
                                  <MultiSelect
                                    placeholder="Match Type"
                                    value={getValueFormat(keyword.match_type)}
                                    onChange={(value) =>
                                      handleClusterMatchUpdate(
                                        value,
                                        clusterIndex,
                                        keywordIndex,
                                        clusterKeywords
                                      )
                                    }
                                  />
                                </TableElement>
                              </TableElement>
                            )
                          )}
                      </>
                    )
                  )}
                </Table>
              )}
            </div>
          </div>
        </StepwiseFormContainer>
      </StepwiseFormWrapper>
      {modal && modal.key === "clusterName" && (
        <ModalWrapper>
          <ModalContainer
            title={modal?.title || "Keyword: Cluster name"}
            formDisable={handleDisable()}
            submitText={"Add"}
            width={"25vw"}
            handleSubmit={handleKeywordAdd}
            handleClose={() => dispatch(setModal(null))}
            error={handleDisable() ? "Please fill All fields" : ""}
          >
            <div className="cluster-name">
              {UnlockTargetingAI.addKeyword.map((data: any) => {
                return (
                  <>
                    {(data.type === "text" || data.type === "number") && (
                      <div key={data.key} className="form-group">
                        <label className="text-md">
                          {data.label}
                          {(data.tooltipTitle || data.tooltipText) && (
                            <div className="tooltip-container">
                              <i ref={target}>
                                <img
                                  className="ml-2"
                                  src={info}
                                  alt="info_style"
                                />
                              </i>
                              <div className="custom-tooltip-layout tooltip">
                                <div className="tootltip-wrapper">
                                  {data.tooltipTitle && (
                                    <div className="tooltip-header">
                                      {data.tooltipTitle}
                                    </div>
                                  )}
                                  {data.tooltipText && (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: data.tooltipText,
                                      }}
                                    ></span>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </label>
                        <InputText
                          name={data.key}
                          placeholder={data.placeholder}
                          value={clusterName && clusterName[data.key]}
                          onChange={(e: any) =>
                            handleKeywordDetailsUpdate(data.key, e.target.value)
                          }
                        />
                      </div>
                    )}
                    {data.type === "multiselect" && (
                      <div key={data.key} className="form-group">
                        <label htmlFor="example2" className="text-md">
                          {data.label}
                          {(data.tooltipTitle || data.tooltipText) && (
                            <div className="tooltip-container">
                              <i ref={target}>
                                <img
                                  className="ml-2"
                                  src={info}
                                  alt="info_style"
                                />
                              </i>
                              <div className="custom-tooltip-layout tooltip">
                                <div className="tootltip-wrapper">
                                  {data.tooltipTitle && (
                                    <div className="tooltip-header">
                                      {data.tooltipTitle}
                                    </div>
                                  )}
                                  {data.tooltipText && (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: data.tooltipText,
                                      }}
                                    ></span>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </label>
                        <MultiSelect
                          placeholder="Select Match Type"
                          value={data.options.length !== 0 ? data.options : []}
                          onChange={(value) =>
                            handleKeywordDetailsUpdate("match_type", value)
                          }
                        />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </ModalContainer>
        </ModalWrapper>
      )}
      {modal && modal.key === "closeCluster" && (
        <ModalWrapper>
          <ModalContainer
            title={modal?.title || "Close cluster creator"}
            formDisable={false}
            submitText={"Close and Exit"}
            handleSubmit={() => {
              dispatch(setClusterTable([]));
              history.push(`/googlesearchai${history.location.search}`);
              dispatch(setModal(null));
            }}
            handleClose={() => {
              dispatch(setModal(null));
            }}
          >
            <p>
              All data in the current cluster flow will be deleted. Are you sure
              you want to close the flow?
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
      {modal && modal.key === "deleteKeyword" && (
        <ModalWrapper>
          <ModalContainer
            title={modal?.title || "Delete Keyword"}
            dontAsk={(value) => setDontAskForDelete(value)}
            formDisable={false}
            submitText={"Delete"}
            handleSubmit={() => handleKeywordDelete(modal.props)}
            width={"25vw"}
            handleClose={() => {
              setDontAskForDelete(false);
              dispatch(setModal(null));
            }}
          >
            <p className="text-lg">
              Are you sure you want to delete this keyword ?
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
    </div>
  );
};

export default KeywordsClustering;
