import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { ReactComponent as CloseIcon } from "./assests/close.svg";
import { ReactComponent as Warning } from "./assests/warning.svg";
import { IconButton } from "@mui/material";

function ApproveModal({
  open,
  data,
  title,
  description,
  handleSubmit,
  handleCancel,
}: {
  open: boolean;
  data: {element: any, elementIndex: number, isStrategicRecommendation: boolean},
  title: string;
  description: string;
  handleCancel: Function;
  handleSubmit: Function;
}) {
  return (
    <Modal open={open} onClose={() => handleCancel()}>
      <Stack
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "28.125rem",
          bgcolor: "background.paper",
          borderRadius: "0.75rem",
          p: 4,
        }}
      >
        <Grid container mb={1}>
          <Grid item xs={10}>
            <Typography
                component="div"
                sx={{
                    fontFamily: "Inter",
                    fontSize: "1.25rem",
                    fontWeight: 600,
                    lineHeight: "2rem",
                    color: "#000",
                    py: 1,
                }}
                >
                {title}
            </Typography>
          </Grid>

          <Grid xs={1} display="block" alignItems="center" justifyContent="end">
            <IconButton
              onClick={() => {
                handleCancel();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography
          component="div"
          sx={{
            fontFamily: "Inter",
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "1.375rem",
            letterSpacing: "0.00875rem",
            color: "#5C6674",
          }}
        >
          {description}
        </Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                mr: 1,
                textTransform: "none",
                bgcolor: "#0869FB",
                color: "#fff",
                borderRadius: "0.5rem",
                textAlign: "center",
                fontFamily: "Inter",
                fontSize: "0.875rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "1.375rem",
                letterSpacing: "0.01094rem",
              }}
              onClick={() => handleSubmit(data)}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Modal>
  );
}

export default ApproveModal;
