export const isValidWebsite = (website: string) => {
  const regex =
    /^((https?):\/\/)[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}\.[a-zA-Z]{2,}?$/;
  return regex.test(website);
};

export const isValidEmail = (email: string) => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
};

export const isStrongPassword = (string: string) => {
  const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return regex.test(string);
};
