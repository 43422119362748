import { Box, Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { CLUS_TYPE } from "../../constants/youtubeIndex";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontFamily: "Inter",
    fontWeight: 400,
  },
}));

const AudienceChip: React.FC<{
  type:
    | "Affinity"
    | "In Market"
    | "Life Events"
    | "In-Market Audiences"
    | "Affinity Audiences";
}> = ({ type }) => {
  const [typeInfo, setTypeInfo] = useState<any>({});

  useEffect(() => {
    setTypeInfo(CLUS_TYPE[type]);
  }, [type]);

  return (
    <Box
      className="d-flex justify-content-center align-items-center"
      sx={{
        padding: "4px 8px",
        gap: "8px",
        width: "fit-content",
        height: "24px",
        backgroundColor: typeInfo.background,
        color: typeInfo.textColor,
        borderRadius: "6px",
        border: `1px solid ${typeInfo.borderColor}`,
        fontFamily: "Inter",
      }}
    >
      <LightTooltip title={typeInfo.tooltipText} placement="top-start" arrow>
        <Typography sx={{ fontSize: "12px" }}>{typeInfo.name}</Typography>
      </LightTooltip>
    </Box>
  );
};

export default AudienceChip;
