import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

export const PrettoSlider = styled(Slider)({
  color: "#F0F0F0",
  height: 8,
  "& .MuiSlider-track": {
    color: "#0869FB",
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: "#fff",
    border: "2px solid #60C7BD",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
});

export const KeywordsBox = styled(Stack)({
  boxSizing: "border-box",
  border: "1px solid rgba(8, 105, 251, 0.1)",
  boxShadow: "0px 4px 18px rgba(17, 17, 17, 0.04)",
  borderRadius: "1rem",
  padding: "0.6rem",
  marginBottom: "1.5rem",
  height: "8.9rem",
});

export const KeywordValueBox = styled(Stack)({
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#FFFFFF",
  boxShadow: "0px 5.45406px 14.6286px rgba(23, 15, 73, 0.07)",
  marginBottom: "10px",
  color: "#0869FB",
  padding: "0.5rem",
  borderRadius: "0.5rem",
});

export const LeftGrid = styled(Grid)({
  height: "100%",
  justifyContent: "space-between",
  gap: "1rem",
  gridTemplateColumns: "repeat(3, 1fr)",
});
