import { Box } from "@mui/material";
import { StyledErrorMessage, StyledText } from "components/shared";
import InputLabel from "components/shared/InputLabel/InputLabel";
import { cloneDeep } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  FacebookInitialState,
  SetAIGroupPayloadType,
} from "store/types/CreativeAI_Revamp/facebook.types";
import { PxSelect } from "../PxSelect/PxSelect";
import { StyledAIModalDivider, StyledConfiguredBox } from "./styled";

export function AIGroupDropdownController({
  control,
  defaultValue,
  setSelectedAiGroup,
  selectedAiGroup,
  aiGroups,
  setAiGroups,
}: {
  control: any;
  defaultValue?: string;
  setSelectedAiGroup: any;
  selectedAiGroup: any;
  aiGroups: any;
  setAiGroups: any;
}) {
  const renderComponent = (value: any) => {
    return (
      <StyledText
        fontSize="0.75em"
        lineHeight="20px"
        fontWeight={400}
        textAlign="center"
        width="100%"
      >
        {aiGroups && aiGroups?.selection?.name}
      </StyledText>
    );
  };
  const trailingComponent = (trailingValue: any) => {
    return (
      <StyledConfiguredBox
        type={
          trailingValue?.[0].ElementCreativeAiData?.[0]?.id
            ? "configured"
            : "unconfigured"
        }
      >
        {trailingValue?.[0].ElementCreativeAiData?.[0]?.id
          ? "Configured"
          : "Unconfigured"}
      </StyledConfiguredBox>
    );
  };

  return selectedAiGroup ? (
    <Controller
      control={control}
      name="aiGroupName"
      defaultValue={{
        selection: selectedAiGroup?.selection,
      }}
      rules={{
        required: true,
      }}
      render={({
        field: { onChange, value },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => {
        return (
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <InputLabel
                label="Select AI Group"
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "0.75em",
                  lineHeight: "20px",
                  color: "#33333",
                }}
                tooltipText="AI Group to be configured"
              />
              <PxSelect
                dropDownContainerWidth="16.5em"
                dropDownContainerHeight="2.75em"
                options={aiGroups ? aiGroups.data : []}
                handlePropChange={(selection) => {
                  onChange({ selection });
                  setSelectedAiGroup({ selection });
                  setAiGroups((prev: any) => {
                    let local = prev;
                    if (local) {
                      local.selection = selection;
                      local.selectedAIGroup = selection;
                    }
                    return local;
                  });
                }}
                selection={value.selection}
                // selection={aiGroups ? aiGroups.selection : {}}
                renderComponent={renderComponent}
                hideSubtitle
                trailingComponent={trailingComponent}
                titleKey="name"
                subTitleKey="id"
                trailingKey="Elements"
              />
            </Box>
            <StyledAIModalDivider />
            {error && (
              <StyledErrorMessage>This field is required *</StyledErrorMessage>
            )}
          </Box>
        );
      }}
    />
  ) : (
    <Fragment />
  );
}
