import React from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import ImagesScrollLeft from "./ImagesScrollLeft";
import ImagesScrollRight from "./ImagesScrollRight";
import { triggerEmail } from "../API/api.triggerEmail";
import {
  firstIcon,
  firstPopupBody,
  firstPopupTitle,
  insightsBody,
  insightTitle,
  SecondIcon,
  secondPopupBody,
  secondPopupTitle,
} from "./platformIcons";
import { ReactComponent as Image } from "../assets/budget-planning-2.svg";

function HomePage({
  type,
  selectedBrand,
  selectedAdAcc,
  getCall,
}: {
  type: string;
  selectedBrand: any;
  selectedAdAcc: any;
  getCall: any;
}) {
  const unLockMagic = () => {
    triggerEmail(selectedBrand?.id, selectedAdAcc?.adAccountId, type).then(
      (data) => {
        getCall();
      }
    );
  };

  return (
    <Box style={{ height: "100%" }}>
      <Stack direction="row" style={{ height: "100%" }}>
        <Box sx={{ width: "47%" }}>
          <Stack
            className="insights-left-box-content"
            sx={{
              padding: "8rem 2.5rem",
              paddingLeft: "70px",
            }}
            spacing={5}
          >
            <Stack spacing={1}>
              <Typography className="font-lato font-30 font-700">
                {`What is ${insightTitle[type]} Insights`}
              </Typography>
              <Typography className="font-400 font-16" sx={{ color: "#666" }}>
                {insightsBody[type]}
              </Typography>
            </Stack>
            <Stack
              className="insights-left-box-subsection"
              spacing={1}
              style={{ paddingTop: "50px" }}
            >
              <Typography className="font-lato font-30 font-700">
                Use Case
              </Typography>
              <Stack direction="row" spacing={2}>
                <Box
                  sx={{
                    width: "320px",
                    height: "auto",
                    border: "1px solid #EFEFEF",
                    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.03)",
                    borderRadius: "12px",
                    padding: "0.5rem",
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box>{firstIcon[type]}</Box>
                    <Box>
                      <Stack spacing={1}>
                        <Typography className="font-14 font-600">
                          {firstPopupTitle[type]}
                        </Typography>
                        <Typography
                          className="font-12 font-400"
                          sx={{ color: "#000", opacity: "0.5" }}
                        >
                          {firstPopupBody[type]}
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    width: "320px",
                    height: "auto",
                    border: "1px solid #EFEFEF",
                    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.03)",
                    borderRadius: "12px",
                    padding: "0.5rem",
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box>{SecondIcon[type]}</Box>
                    <Box>
                      <Stack spacing={0.5}>
                        <Typography className="font-14 font-600">
                          {secondPopupTitle[type]}
                        </Typography>
                        <Typography
                          className="font-12 font-400"
                          sx={{ color: "#000", opacity: "0.5" }}
                        >
                          {secondPopupBody[type]}
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
            <Stack
              className="insights-left-box-cta"
              alignItems="center"
              style={{ paddingTop: "40px" }}
            >
              {selectedAdAcc?.isEmailTriggered ? (
                <Button
                  variant="contained"
                  sx={{
                    width: "50%",
                    textTransform: "none",
                    color: "#0869FB",
                    background: "rgba(8, 105, 251, 0.1);",
                    boxShadow: "none",
                    "&:hover": {
                      background: "rgba(8, 105, 251, 0.1)",
                      boxShadow: "none",
                    },
                  }}
                >
                  {`${insightTitle[type]} Insights Requested`}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    width: "50%",
                    textTransform: "none",
                    color: "white",
                    background: "#0869FB",
                    boxShadow: "none",
                    "&:hover": {
                      background: "#0869FB",
                      boxShadow: "none",
                    },
                  }}
                  onClick={unLockMagic}
                >
                  Unlock the magic
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
        <Box
          className="insights-right-box"
          sx={{
            width: "53%",
            paddingLeft: "100px",
            overflow: "hidden",
            display: type === "GAI" ? "flex" : "block",
          }}
        >
          {type === "GAI" ? (
            <div className="flex-center">
              <Image />
            </div>
          ) : (
            <Stack className="scroll">
              {type === "CAI" ? (
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{
                    overflow: "hidden",
                  }}
                >
                  <ImagesScrollLeft imagesType="creative" />
                  <ImagesScrollRight imagesType="creative" />
                </Stack>
              ) : (
                <Stack
                  spacing={1}
                  direction="row"
                  style={{ marginTop: "-50px" }}
                  sx={{
                    overflow: "hidden",
                  }}
                >
                  <ImagesScrollLeft imagesType="audience" />
                  <ImagesScrollRight imagesType="audience" />
                </Stack>
              )}
            </Stack>
          )}
        </Box>
      </Stack>
    </Box>
  );
}

export default HomePage;
