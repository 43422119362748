import {
  CreateImageAPIHash,
  CreateImageAPIHashResponse,
  CreateImageHashPayloadType,
  CREATE_IMAGE_API_HASH,
  CREATE_IMAGE_API_HASH_RESPONSE,
  DeleteImageAPIHash,
  DELETE_IMAGE_API_HASH,
  GetImageAPIHash,
  GetImageAPIHashResponse,
  GetMediaStudioForAd,
  GetMediaStudioForAdResponse,
  GetMediaStudioProcessing,
  GetMediaStudioProcessingPayloadType,
  GetMediaStudioProcessingReduxImage,
  GetMediaStudioProcessingReduxType,
  GETMediaStudioResponseType,
  GET_IMAGE_API_HASH,
  GET_IMAGE_API_HASH_RESPONSE,
  GET_MEDIA_STUDIO_FOR_AD,
  GET_MEDIA_STUDIO_FOR_AD_RESPONSE,
  GET_MEDIA_STUDIO_PROCESSING,
  GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
  MediaStudioImageProcessingLoader,
  MediaStudioProcessingPostPayloadType,
  MediaStudioVideoProcessingLoader,
  MEDIA_STUDIO_IMAGE_PROCESSING_LOADER,
  MEDIA_STUDIO_VIDEO_PROCESSING_LOADER,
  PostMediaStudioProcessing,
  PostMediaStudioProcessingReduxImage,
  PostMediaStudioProcessingReduxVideo,
  POST_MEDIA_STUDIO_PROCESSING,
  POST_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
  POST_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO,
  RequestMediaStudio,
  RequestMediaStudioPayload,
  RequestMediaStudioPayloadType,
  RequestMediaStudioStatus,
  REQUEST_MEDIA_STUDIO,
  REQUEST_MEDIA_STUDIO_STATUS,
  SetMediaStudioRequestStatus,
  SET_MEDIA_STUDIO_REQUEST_STATUS,
  SetImageVGSTimer,
  SET_IMAGE_VGS_TIMER,
  SetImagePreviewUrl,
  IMAGE_PREVIEW_URL,
  SetFrameThumbnailVGSData,
  FrameThumbnailVGSPayloadType,
  SET_FRAME_THUMBNAIL_DATA,
  SET_FRAME_VGS_DATA,
  GetFacebookImageUrl,
  GET_FACEBOOK_IMAGE_URL,
  GET_FACEBOOK_VIDEO_URL,
  GetFacebookVideoUrl,
  GetFacebookImageUrlPayloadType,
  GetFacebookVideoUrlPayloadType,
  GetFacebookImageUrlForAssetFeedSpecPayload,
  GET_FB_IMAGE_URL_ASSET_FEED_SPEC,
  GetFacebookImageUrlForAssetFeedSpec,
} from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";

export const createImageAPIHash = (
  payload: CreateImageHashPayloadType
): CreateImageAPIHash => {
  return {
    type: CREATE_IMAGE_API_HASH,
    payload,
  };
};

export const createImageAPIHashResponse = (
  payload: string
): CreateImageAPIHashResponse => {
  return {
    type: CREATE_IMAGE_API_HASH_RESPONSE,
    payload,
  };
};

export const getImageAPIHash = (payload: string[]): GetImageAPIHash => {
  return {
    type: GET_IMAGE_API_HASH,
    payload,
  };
};

export const getImageAPIHashResponse = (
  payload: string
): GetImageAPIHashResponse => {
  return {
    type: GET_IMAGE_API_HASH_RESPONSE,
    payload,
  };
};

export const deleteImageAPIHash = (payload: string[]): DeleteImageAPIHash => {
  return {
    type: DELETE_IMAGE_API_HASH,
    payload,
  };
};

export const requestMediaStudio = (
  payload: RequestMediaStudioPayloadType
): RequestMediaStudio => {
  return {
    type: REQUEST_MEDIA_STUDIO,
    payload,
  };
};

export const setImagePreviewUrl = (payload: string): SetImagePreviewUrl => {
  return {
    type: IMAGE_PREVIEW_URL,
    payload,
  };
};

export const getMediaStudioForAd = (
  payload: RequestMediaStudioPayload
): GetMediaStudioForAd => {
  return {
    type: GET_MEDIA_STUDIO_FOR_AD,
    payload,
  };
};

export const getMediaStudioForAdResponse = (payload: {
  data?: GETMediaStudioResponseType;
  isData: boolean;
  isLoading?: boolean;
}): GetMediaStudioForAdResponse => {
  return {
    type: GET_MEDIA_STUDIO_FOR_AD_RESPONSE,
    payload,
  };
};

export const requestMediaStudioStatus = (
  payload: RequestMediaStudioPayload
): RequestMediaStudioStatus => {
  return {
    type: REQUEST_MEDIA_STUDIO_STATUS,
    payload,
  };
};

export const setMediaStudioRequestStatus = (
  payload: boolean
): SetMediaStudioRequestStatus => {
  return {
    type: SET_MEDIA_STUDIO_REQUEST_STATUS,
    payload,
  };
};
export const postMediaStudioProcessing = (
  payload: MediaStudioProcessingPostPayloadType
): PostMediaStudioProcessing => {
  return {
    type: POST_MEDIA_STUDIO_PROCESSING,
    payload,
  };
};

export const getFacebookImageUrl = (
  payload: GetFacebookImageUrlPayloadType
): GetFacebookImageUrl => {
  return {
    type: GET_FACEBOOK_IMAGE_URL,
    payload,
  };
};

export const getFacebookVideoUrl = (
  payload: GetFacebookVideoUrlPayloadType
): GetFacebookVideoUrl => {
  return {
    type: GET_FACEBOOK_VIDEO_URL,
    payload,
  };
};

export const getFacebookImageUrlForAssetFeedSpec = (
  payload: GetFacebookImageUrlForAssetFeedSpecPayload
): GetFacebookImageUrlForAssetFeedSpec => {
  return {
    type: GET_FB_IMAGE_URL_ASSET_FEED_SPEC,
    payload,
  };
};

export const postMediaStudioProcessingReduxImage = (
  payload: string[]
): PostMediaStudioProcessingReduxImage => {
  return {
    type: POST_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
    payload,
  };
};

export const postMediaStudioProcessingReduxVideo = (
  payload: string[]
): PostMediaStudioProcessingReduxVideo => {
  return {
    type: POST_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO,
    payload,
  };
};

export const mediaStudioImageProcessingLoader = (
  payload: boolean
): MediaStudioImageProcessingLoader => {
  return {
    type: MEDIA_STUDIO_IMAGE_PROCESSING_LOADER,
    payload,
  };
};

export const mediaStudioVideoProcessingLoader = (
  payload: boolean
): MediaStudioVideoProcessingLoader => {
  return {
    type: MEDIA_STUDIO_VIDEO_PROCESSING_LOADER,
    payload,
  };
};

export const getMediaStudioProcessing = (
  payload: GetMediaStudioProcessingPayloadType,
  callback: any
): GetMediaStudioProcessing => {
  return {
    type: GET_MEDIA_STUDIO_PROCESSING,
    payload,
    callback,
  };
};

export const getMediaStudioProcessingReduxImage = (
  payload: GetMediaStudioProcessingReduxType[]
): GetMediaStudioProcessingReduxImage => {
  return {
    type: GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
    payload,
  };
};

export const setImageVGSTimer = (payload: any): SetImageVGSTimer => ({
  type: SET_IMAGE_VGS_TIMER,
  payload,
});

export const setFrameThumbnailData = (
  payload: FrameThumbnailVGSPayloadType
): SetFrameThumbnailVGSData => ({
  type: SET_FRAME_THUMBNAIL_DATA,
  payload,
});

export const setFrameVGSData = (payload: any): any => ({
  type: SET_FRAME_VGS_DATA,
  payload,
});
