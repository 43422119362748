import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";

interface ICustomSliderProps {
  minValue: number;
  maxValue: number;
  selectedValue: number;
  setSelectedValue: Function;
  step?: number;
  showTooltip?: boolean;
  disabled?: boolean;
  sliderFor?: string;
}
const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 100,
    label: "100",
  },
];
function CustomSlider(params: ICustomSliderProps) {
  const {
    minValue,
    maxValue,
    selectedValue,
    setSelectedValue,
    showTooltip,
    disabled,
    sliderFor,
  } = params;

  return (
    <Box
      sx={{
        width: "100%",
        background: "#FFFFFF",
        border: "0.7px solid #EFEFEF",
        boxShadow: "0px 0px 10.4927px rgba(0, 0, 0, 0.05)",
        borderRadius: "4px",
        padding: "39px 22px 8px 22px",
      }}
    >
      {sliderFor === "impressionSignificance" && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className="font-9 font-400">
            {100 - selectedValue}%
          </Typography>
          <Typography className="font-9 font-400">{selectedValue}%</Typography>
        </Box>
      )}
      <Slider
        disabled={disabled}
        min={minValue}
        max={maxValue}
        value={selectedValue}
        onChange={(event: any) => {
          setSelectedValue(event.target.value);
        }}
        valueLabelDisplay={showTooltip ? "on" : "off"}
        marks={sliderFor === "keywordCost" && marks}
        sx={{
          pb: 0,
          "& .MuiSlider-track": {
            background:
              "linear-gradient(90deg, #4BA2E9 3.51%, #6DDE9F 127.19%) !important",
            border: "transparent",
          },
          "& .MuiSlider-rail": {
            opacity: 1,
            background:
              "linear-gradient(90deg, #4BA2E9 3.51%, #6DDE9F 127.19%) !important",
            border: "transparent",
          },
          "& .MuiSlider-thumb": {
            height: "1rem",
            width: "1rem",
            background:
              "linear-gradient(#fff,#fff) padding-box, linear-gradient(90deg, #4BA2E9 3.51%, #6DDE9F 127.19%) border-box",
            border: "3px solid transparent",
            borderRadius: "0.75rem",
          },
          "& .MuiSlider-thumb::after": {
            height: "1rem",
            width: "1rem",
          },
          "& .MuiSlider-valueLabel": {
            background: "#1E2244",
            fontSize: "0.75rem",
            fontWeight: 400,
            borderRadius: "4px",
            color: "#fff",
            fontFamily: "Inter",
          },
        }}
      />
      {sliderFor === "impressionSignificance" && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className="font-9 font-400">Quantum</Typography>
          <Typography className="font-9 font-400">Quality</Typography>
        </Box>
      )}
    </Box>
  );
}

export default CustomSlider;
