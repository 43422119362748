import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Close } from "./ReactFlowComponents/Assets/svgs/close.svg";
import { TaiContext } from "./TaiContextProvider";
import IconButton from "@mui/material/IconButton";
import CohortType from "common/CohortType";
import { refreshCohorts, submitCohorts } from "store/actions/targetingAI";
import { CommonState } from "store/types/common";

const CohortInfoModel: React.FC<any> = ({
  index,
  graphData,
  setShowModel,
  shownClusterData,
  setShownClusterData,
  setSelectedCohortData,
  handleApplyCallback,
}) => {
  const dispatch = useDispatch();
  const { setNodes, cohortsJSON, cohortType } = useContext(TaiContext) as any;
  const adaccount = useSelector((state: any) => state.adaccount);
  const authState = useSelector((state: any) => state.auth);
  const { selectedAgency } = useSelector(
    (state: { common: CommonState }) => state.common
  );
  const { user } = authState;
  const { selectedBrand } = adaccount;
  const selectedCohortData = shownClusterData[index];
  const targetingsData =
    selectedCohortData.targetings !== ""
      ? selectedCohortData.facebook_json
      : [];
  const [targetings, setTargetings] = useState(targetingsData);
  const [applyLoading, setApplyLoading] = useState(false);
  const [relavance, setRelavance] = useState(
    selectedCohortData.relevance.toFixed(2)
  );
  const [audienceSize, setAudienceSize] = useState(
    selectedCohortData.audience_size
  );

  const handleRefreshClick = () => {
    dispatch(
      refreshCohorts(
        {
          data: {
            cohorts_json: cohortsJSON,
            updated_cohorts: [
              {
                cohort_name: selectedCohortData.cohort_name,
                cohort_targetings: targetings.map(
                  (targeting: { id: string }) => targeting.id
                ),
              },
            ],
          },
          user,
        },
        (res: any, err: boolean) => {
          if (!err) {
            setRelavance(res[0].relevance.toFixed(1));
            setAudienceSize(res[0].audience_size);
          }
        }
      )
    );
  };

  const handleApplyClick = () => {
    setApplyLoading(true);
    dispatch(
      submitCohorts(
        {
          data: {
            cohorts_json: cohortsJSON,
            updated_cohorts: [
              {
                cohort_name: selectedCohortData.cohort_name,
                cohort_targetings: targetings.map(
                  (targeting: { id: string }) => targeting.id
                ),
              },
            ],
          },
          user,
        },
        (res: any, err: boolean) => {
          setApplyLoading(false);
          if (!err) {
            return handleApplyCallback(res.result_file_url,res.result);
          }
          setShowModel(-1);
        }
      )
    );
  };

  const handleUpdateCohort = (kId: string, idx: any) => {
    const updatedTargetings = targetings.filter(
      (val: any, tIdx: number) => val.id !== kId
    );
    setTargetings(updatedTargetings);
    setAudienceSize("-");
    setRelavance("-");
    setSelectedCohortData(
      selectedCohortData.cohort_id,
      "threeDchart",
      idx,
      graphData,
      setNodes,
      selectedBrand,
      selectedAgency,
      cohortType
    );
  };

  return (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex focus:outline-none w-100">
        <div
          className="py-2 bg-white outline-none focus:outline-none"
          style={{
            maxHeight: "26rem",
            width: "27.063rem",
            boxShadow: "0px 8px 12px rgba(0, 31, 79, 0.08)",
            border: "1px solid #5E9EFE",
            borderRadius: "0.5rem",
          }}
        >
          <div
            className="px-3 pb-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="p-2 d-flex justify-content-between w-100">
              <div className="id-name">
                <div style={{ display: "flex", gap: "8px" }}>
                  <span
                    style={{
                      color: "#0869FB",
                      fontSize: "1rem",
                      fontWeight: "600",
                      fontFamily: "Inter",
                    }}
                  >
                    {`Cohort C${selectedCohortData.cohort_id}`} &nbsp;
                  </span>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CohortType type={selectedCohortData.clus_type} />
                  </div>
                  {selectedCohortData.edited_targetings_flag === true && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#82838F",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        fontFamily: "Inter",
                        fontStyle: "italic",
                      }}
                    >
                      (Edited)
                    </div>
                  )}
                </div>
                <p
                  style={{
                    color: "#666666",
                    fontStyle: "italic",
                    fontSize: "1rem",
                    fontWeight: "600",
                    fontFamily: "Inter",
                    marginTop: "5px",
                  }}
                >
                  {selectedCohortData.cohort_name}
                </p>
              </div>
            </div>

            <button
              className="text-black bg-transparent border-0 "
              onClick={() => setShowModel(-1)}
            >
              <Close />
            </button>
          </div>
          <div
            className="d-flex w-100"
            style={{
              borderTop: "1px solid #DDDDDD",
              borderBottom: "1px solid #DDDDDD",
            }}
          >
            <div
              className="px-3 py-2 break-words d-flex flex-column align-items-center"
              style={{ width: "50%", maxHeight: "33.33%" }}
            >
              <span className="text-lg font-weight-bold">{audienceSize}</span>
              <span
                className="d-flex align-items-center justify-content-center"
                style={{
                  fontSize: "0.75rem",
                  color: "#7D7D7D",
                }}
              >
                Audience size&nbsp;
                {audienceSize === "-" && (
                  <IconButton sx={{ p: 0 }} onClick={handleRefreshClick}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.17527 6.87921C5.5666 6.20142 6.13773 5.64504 6.82553 5.2716C7.51334 4.89815 8.29102 4.72218 9.07259 4.76314C9.85416 4.8041 10.6092 5.0604 11.2542 5.50369C11.8992 5.94699 12.409 6.56002 12.7274 7.275M4.19922 5.56118L4.58615 7.23723C4.65205 7.52269 4.93689 7.70068 5.22235 7.63478L6.8984 7.24784M12.5257 11.1229C12.1344 11.8007 11.5633 12.3571 10.8755 12.7305C10.1877 13.104 9.40998 13.28 8.62841 13.239C7.84683 13.198 7.09181 12.9417 6.44681 12.4984C5.80181 12.0551 5.29196 11.4421 4.97363 10.7271M13.5018 12.441L13.1148 10.7649C13.0489 10.4794 12.7641 10.3015 12.4786 10.3674L10.8026 10.7543"
                        stroke="#6D6E7C"
                        stroke-width="1.2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </IconButton>
                )}
              </span>
            </div>
            <div className="hstack border-end"></div>
            <div
              className="px-3 py-2 break-words d-flex flex-column align-items-center"
              style={{ width: "50%", maxHeight: "33.33%" }}
            >
              <span className="text-lg font-weight-bold">{relavance}</span>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  fontSize: "0.75rem",
                  color: "#7D7D7D",
                }}
              >
                Relevance Score&nbsp;
                {relavance === "-" && (
                  <IconButton sx={{ p: 0 }} onClick={handleRefreshClick}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.17527 6.87921C5.5666 6.20142 6.13773 5.64504 6.82553 5.2716C7.51334 4.89815 8.29102 4.72218 9.07259 4.76314C9.85416 4.8041 10.6092 5.0604 11.2542 5.50369C11.8992 5.94699 12.409 6.56002 12.7274 7.275M4.19922 5.56118L4.58615 7.23723C4.65205 7.52269 4.93689 7.70068 5.22235 7.63478L6.8984 7.24784M12.5257 11.1229C12.1344 11.8007 11.5633 12.3571 10.8755 12.7305C10.1877 13.104 9.40998 13.28 8.62841 13.239C7.84683 13.198 7.09181 12.9417 6.44681 12.4984C5.80181 12.0551 5.29196 11.4421 4.97363 10.7271M13.5018 12.441L13.1148 10.7649C13.0489 10.4794 12.7641 10.3015 12.4786 10.3674L10.8026 10.7543"
                        stroke="#6D6E7C"
                        stroke-width="1.2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </IconButton>
                )}
              </div>
            </div>
          </div>
          <div
            className="m-3 mb-2"
            style={{
              backgroundColor: "#E3EEFF",
              padding: "0.25rem 0.5rem",
              maxHeight: "14.5rem",
              overflow: "auto",
            }}
          >
            <div
              className="flex flex-wrap"
              style={{
                height: "100%",
                overflowY: "auto",
                justifyContent: "flex-start",
              }}
            >
              {targetings.map((item: any, idx: any) => (
                <div
                  className="m-1 border d-flex align-items-center text-primary border-primary rounded-pill justify-content-between"
                  style={{
                    padding: "0.2rem 0.725rem",
                    fontSize: "0.75rem",
                    fontWeight: "400",
                    backgroundColor: "white",
                    cursor: "pointer",
                  }}
                  key={idx}
                >
                  <p style={{ margin: "0px" }}>{item.name}</p>
                  <IconButton
                    sx={{ p: 0, pl: 0.5 }}
                    disableRipple
                    onClick={() => {
                      handleUpdateCohort(item.id, idx);
                    }}
                  >
                    <svg
                      width="8"
                      height="9"
                      viewBox="0 0 8 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 7.5L1 1.5M7 1.5L1 7.5"
                        stroke="#2471F7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
          <div
            className="d-flex justify-content-end w-100"
            style={{
              backgroundColor: "#FFFFFF",
              padding: "0.5rem 0.5rem 0.5rem 0.5rem",
              bottom: 0,
              height: "3.5rem",
              borderRadius: "0.5rem",
            }}
          >
            <button
              className="text-white btn primary d-flex align-items-center justify-content-center"
              style={{ padding: "0 !important" }}
              onClick={handleApplyClick}
              disabled={
                targetings.length === targetingsData.length || applyLoading
              }
            >
              {applyLoading && (
                <>
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ height: "1.25rem", width: "1.25rem" }}
                  ></div>
                  &nbsp; &nbsp;
                </>
              )}
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CohortInfoModel;
