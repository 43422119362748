import styled from "styled-components";

export const StyledParagraph1 = styled.p`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #262626;
  max-lines: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
  /* width: 70%; */
`;
