import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import CommonLoader from "../assets/Gif/CommonLoader.gif";
import { ReactComponent as Tick } from "../assets/svgs/tick.svg";

import "./LoaderContainer.scss";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  border: "none",
  boxShadow: "none",
}));

const LoaderContainer: React.FC<{
  steps: string[];
  loadingId: number;
  loaderGifProps: any;
  header: string;
  continueProps: {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    disabled: boolean;
    className: string;
  };
  children: React.ReactNode;
  closeProps: {
    className: string;
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  };
  isBrandOnboarded?:boolean;
}> = ({
  steps,
  loadingId,
  loaderGifProps,
  header,
  continueProps,
  children,
  closeProps,
  isBrandOnboarded=true
}) => {
  return (
    <div className="bg-white border border-2 rounded shadow focus:outline-none border-primary w-70">
      <Box
        sx={{ flexGrow: 1 }}
        className="loader-main-container h-100 focus:outline-none border-primary"
      >
        <Grid container className="h-100">
          <Grid item xs={4} className="loader-main-container-left h-100">
            <Item
              className="d-flex h-100 align-items-center justify-content-center"
              sx={{
                borderTopLeftRadius: 1,
                borderBottomLeftRadius: 1,
              }}
            >
              <Stack
                spacing={2}
                className="d-flex h-100 align-items-center justify-content-center"
              >
                <img {...loaderGifProps} alt={loaderGifProps.alt} />
                {steps.map((step, index) => (
                  <Grid container key={step} sx={{ p: 2 }}>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {loadingId >= index + 1 ? (
                        <Tick />
                      ) : (
                        <img
                          src={CommonLoader}
                          alt="CommonLoader"
                          height={24}
                          width={24}
                        />
                      )}
                    </Grid>
                    <Grid
                      className="text-black"
                      item
                      xs={10}
                      sx={{
                        ...(loadingId >= index + 1
                          ? {
                              fontWeight: 500,
                            }
                          : {
                              fontWeight: 400,
                              opacity: "0.5",
                            }),
                      }}
                    >
                      <Typography className="font-1">{step}</Typography>
                    </Grid>
                  </Grid>
                ))}
                {!isBrandOnboarded && <Box className="font-inter font-400 font-12 text-black" width="246px" position="absolute" bottom="78px" left="86px">
                  <span className="font-500 font-14">Note:</span> Cohort generation for this Brand will 
                   take more time as this is a new Brand.
                </Box>}
              </Stack>
            </Item>
          </Grid>

          <Grid item xs={8} className="h-100">
            <Stack
              className="bg-white h-100 b-0"
              sx={{
                pr: 2,
                borderTopRightRadius: 1,
                borderBottomRightRadius: 1,
              }}
            >
              <Typography
                sx={{
                  height: "10%",
                  fontWeight: "600",
                }}
                variant="h5"
                className="d-flex align-items-center justify-content-center w-100"
              >
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    className="d-flex align-items-center justify-content-center"
                    sx={{ width: "50%" }}
                  >
                    <span className="mr-1 text-black font-18">Next Step:</span>
                    <span className="font-18" style={{ color: "#666" }}>
                      {header}
                    </span>
                  </Grid>
                  <Grid item xs={1} className="ml-auto">
                    <IconButton
                      {...closeProps}
                      sx={{
                        background: "#F2F2F2",
                        borderRadius: 1,
                        padding: "0.25rem",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Typography>
              <Item
                sx={{
                  height: "80%",
                  pl: 0,
                  overflow: "auto",
                  pr: 2,
                }}
              >
                {children}
              </Item>
              <Item
                className="mt-auto d-flex align-items-center justify-content-end"
                sx={{
                  height: "10%",
                }}
              >
                <Button variant="contained" {...continueProps}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "10px",
                      lineHeight: "20px",
                    }}
                    variant="subtitle2"
                  >
                    Continue
                  </Typography>
                </Button>
              </Item>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default LoaderContainer;
