import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Typography, Select, MenuItem } from "@mui/material";
import {
  TILE_DROPDOWN_VALUES,
  OPTIONS_MAPPING,
  PLATFORM_URL_MAPPING,
  PLATFORM_VALUE_MAPPING,
  PLATFORM_AD_TYPE,
} from "../../constants/subheader";
import { AudienceManagerState } from "store/types/AudienceManager";
import { setSelectedPlatform } from "store/actions/AudienceManager";
import {
  getRecommendedCohorts,
  setYoutubeLoading,
} from "store/actions/AudienceManager/youtube";
import { AdAccountState } from "store/types/adaccount";

const GOOGLE_PLATFORM_ID = "aa59a6e2-843b-4633-5621-6fa93ce6c002";
interface ITileDropdownProps {
  label: string;
  Icon: any;
  isSelected?: boolean;
  deviation?: any;
  clickHandler?: Function;
}
function TileDropdown(props: ITileDropdownProps) {
  const { clickHandler } = props;
  const { selectedPlatform, selectedAiGroup } = useSelector(
    (state: { audienceManager: AudienceManagerState }) => state.audienceManager
  );
  const { platforms } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const dispatch = useDispatch();

  const isPlatformDisabled = (platform: "facebook" | "gSearch" | "youtube") => {
    let isDisabled = false;
    switch (platform) {
      case PLATFORM_VALUE_MAPPING.facebook:
        break;
      case PLATFORM_VALUE_MAPPING.gSearch:
        const googleSearchPlatformObj: any = platforms.find(
          (platform: any) => platform.type === PLATFORM_AD_TYPE.gSearch
        );
        isDisabled = !selectedAiGroup?.adAccounts?.some(
          (account: any) => account.platformId === googleSearchPlatformObj?.id
        );
        break;
      case PLATFORM_VALUE_MAPPING.youtube:
        const googleVideoPlatformObj: any = platforms.find(
          (platform: any) => platform.type === PLATFORM_AD_TYPE.youtube
        );
        isDisabled = !selectedAiGroup?.adAccounts?.some(
          (account: any) => account.platformId === googleVideoPlatformObj?.id
        );
        break;
    }
    return isDisabled;
  };

  /**
   * @param {string} platform
   * @returns {number | null} platformId
   */
  const getPlatformIdFromPlatformValue = (
    platform: "facebook" | "gSearch" | "youtube"
  ) => {
    switch (platform) {
      case PLATFORM_VALUE_MAPPING.gSearch:
        return platforms.find(
          (platform: any) => platform.type === PLATFORM_AD_TYPE.gSearch
        )?.id;
      case PLATFORM_VALUE_MAPPING.youtube:
        return platforms.find(
          (platform: any) => platform.type === PLATFORM_AD_TYPE.youtube
        )?.id;
      default:
        return null;
    }
  };
  return (
    <Card
      className="font-inter"
      sx={{
        borderRadius: "0.5rem",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.06);",
        backgroundColor: "#FFF",
        border: "2px solid #DDD",
        cursor: "pointer",
        width: "20%",
        height: "50%",
      }}
      onClick={() => {
        if (clickHandler) clickHandler();
      }}
    >
      <Select
        renderValue={(value: "facebook" | "youtube" | "gSearch") => (
          <Box display={"flex"} alignItems={"center"}>
            <Box
              sx={{
                width: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {OPTIONS_MAPPING[value].Icon}
            </Box>
            <Typography className="font-inter font-600 font-16">
              {OPTIONS_MAPPING[value].label}
            </Typography>
          </Box>
        )}
        sx={{
          width: "100%",
          height: "100%",
          border: "none",
          "& fieldset": { top: 0, border: "none" },
          "& .MuiSvgIcon-root": { height: 24, width: 24 },
        }}
        displayEmpty={true}
        onChange={(e: any) => {
          // debugger
          if (e.target.value === "facebook") {
            dispatch(setSelectedPlatform(e.target.value));
          } else {
            window.open(
              `${
                process.env.REACT_APP_BASE_URL_PIXIS2
              }/audience-manager?brandId=${
                selectedAiGroup?.brandId
              }&ai_group_id=${
                selectedAiGroup?.id
              }&platformId=${getPlatformIdFromPlatformValue(e.target.value)}`,
              "_blank"
            );
          }
        }}
        value={selectedPlatform}
      >
        {TILE_DROPDOWN_VALUES.map((el: any) => (
          <MenuItem
            disabled={isPlatformDisabled(el.value)}
            value={el.value}
            key={el.label}
          >
            <Box
              sx={{
                width: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {el.Icon}
            </Box>
            <Typography>{el.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Card>
  );
}

export default TileDropdown;
