import HttpService from "../../HttpService/HttpService";
import { crossPlatform } from "../../MappedAPI/MappedAPI";
import { call, takeEvery, takeLatest } from "redux-saga/effects";
import { GetAccountsStats, GET_ACCOUNTS_STATS, GET_STATS_ON_ADACCOUNT, DeleteToken, DELETE_TOKEN, UpdatePrimaryToken, UPDATE_PRIMARY_TOKEN, GetStatsOnAdaccount, GetBrandDetails, GET_BRAND_DETAILS } from "../../store/types/accountsManager";
import { CROSS_PLATFORM_URL } from "../../utils/path";

function* getAccountsStats(action: GetAccountsStats): any {
    let {
        payload: { brandId },
        callback
    } = action
    try {
        const urlParams = `${crossPlatform}/token/brand/${brandId}/token-stats`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}
function* deleteToken(action: DeleteToken): any {
    let {
        payload: { user, tokenUID, adAccountId },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/token/${tokenUID}/adAccount/${adAccountId}`;
        const response = yield call(HttpService.delete, CROSS_PLATFORM_URL, urlParams, user);
        if (response.data.code === "101") {
            return;
        }
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        console.log("function*deleteToken ~ error", error)
        callback && callback(error.response, true);
    }
}
function* updatePrimaryToken(action: UpdatePrimaryToken): any {
    let {
        payload: { user, tokenUID, adAccountId },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/token/${tokenUID}/adAccount/${adAccountId}/primary`;
        const response = yield call(HttpService.put, CROSS_PLATFORM_URL, urlParams, user, {});
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        console.log("function*updatePrimaryToken ~ error", error)
        callback && callback(error.response, true);
    }
}

function* getStatsOnAdaccount(action: GetStatsOnAdaccount): any {
    let {
        payload: { adaccountId },
        callback
    } = action
    try {
        const urlParams = `${crossPlatform}/token/adAccount/${adaccountId}/token-stats`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getBrandDetails(action: GetBrandDetails): any {
    let {
        payload: { brandId },
        callback
    } = action
    try {
        const urlParams = `${crossPlatform}/brand/${brandId}`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

export function* AccountsManager() {
    yield takeLatest(GET_ACCOUNTS_STATS, getAccountsStats);
    yield takeEvery(GET_STATS_ON_ADACCOUNT, getStatsOnAdaccount);
    yield takeLatest(DELETE_TOKEN, deleteToken);
    yield takeLatest(UPDATE_PRIMARY_TOKEN, updatePrimaryToken);
    yield takeLatest(GET_BRAND_DETAILS, getBrandDetails);
}