import { sub } from "date-fns";
import moment from "moment/moment";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DropDownArrow } from "../../assets/svg/dropdownarrow.svg";
import {
  getPhaseTimeline,
  setPhaseTimeline,
} from "../../store/actions/campaign";
import { LinkClicks } from "../../store/types/actionImpact";
import { CampaignState } from "../../store/types/campaign";
import { initialState } from "../../store/types/auth";
import DateRangePicker from "../../ui/DateRangePicker/DateRangePicker";
import PhaseTimeline from "../PhaseTimeline/PhaseTimeline";
import "./index.scss";
import { AdAccountState } from "../../store/types/adaccount";
import HandleClickOutside from "../common/HandleClickOutside";
import { getModuleName, mixPanelAdEvent } from "../../utils/mixpanel";
import { useHistory } from "react-router-dom";
import { useAccordionButton } from "react-bootstrap";
import { ReactComponent as DownIcon } from "assets/svg/down.svg";
import { getPlatformName } from "utils/commonFunctions";
import { useAuth0 } from "@auth0/auth0-react";
import RadioMenu from "components/shared/Dropdown/RadioMenu";
import { StyledBtnContainer } from "Pages/ActionDebugger/styled";
import { Button } from "@mui/material";
import { ReactComponent as DownArrowGrey } from "../../assets/svg/actionDebuggerDownArrow.svg";
import {
  updateElementAttributionWindow,
  setAttributionWindowLoader,
} from "../../store/actions/ActionDebugger/ActionDebugger";
import { ActionDebuggerInitStateType } from "store/types/ActionDebugger/ActionDebugger";

const ActionImpactHeader: React.FC<{
  title: string;
  localStorageKey?: string;
  linkClicks: LinkClicks;
  setLinkClicks: any;
}> = ({ title, localStorageKey, linkClicks, setLinkClicks }) => {
  const campaign = useSelector(
    (state: { campaign: CampaignState }) => state.campaign
  );
  const { phaseTimeline } = campaign;
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const {
    selectedBrand,
    selectedAdAccount,
    selectedGroup,
    selectedPlatform,
    platforms,
    selectedGroupAdaccounts,
  } = adaccount;
  const target = useRef(null);
  const dispatch = useDispatch();
  const [showPhaseTimline, setShowPhaseTimline] =
    React.useState<boolean>(false);
  const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
  const [dateSelection, setDateSelection] = React.useState([
    {
      startDate: sub(new Date(), { days: 30 }),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const history = useHistory();
  const [showHeader, setShowHeader] = React.useState(false);
  const [showAttributionMenu, setShowAttributionMenu] = React.useState(null);
  const { user } = useAuth0();
  const { attributionWindow, attributionWindowType } = useSelector(
    (state: { ActionDebuggerReducer: ActionDebuggerInitStateType }) =>
      state.ActionDebuggerReducer
  );

  React.useEffect(() => {
    if (selectedBrand?.name) {
      mixPanelAdEvent("Page View - Dashboard", {
        module_name:
          history.location.pathname === "/singlePlatform"
            ? "Action Impact Analysis - Single platforms"
            : "Action Impact Analysis - All platforms",
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        agency_id: selectedBrand?.id,
        agency_name: selectedBrand?.agency_name,
      });
    }
  }, [selectedBrand]);

  React.useEffect(() => {
    if (selectedBrand?.id) {
      dispatch(
        getPhaseTimeline(
          { brandId: selectedBrand.id, user: authState?.user },
          (response: any, error: boolean) => {
            if (!error) {
              dispatch(setPhaseTimeline(response));
            } else {
              dispatch(setPhaseTimeline({}));
              console.log(error);
            }
          }
        )
      );
      // if (localStorageKey && localStorage.getItem(localStorageKey)) {
      //   const date = JSON.parse(localStorage.getItem(localStorageKey)!);
      //   if (date) {
      //     setDateSelection([
      //       {
      //         startDate: moment(new Date(date?.start)).isBefore(
      //           sub(new Date(), { days: 24 })
      //         )
      //           ? sub(new Date(), { days: 24 })
      //           : new Date(date?.start),
      //         endDate: moment(new Date(date.end)).isBefore(
      //           sub(new Date(), { days: 24 })
      //         )
      //           ? sub(new Date(), { days: 18 })
      //           : new Date(date.end),
      //         key: "selection",
      //       },
      //     ]);
      //   }
      // }
    }
  }, [selectedBrand]);

  React.useEffect(() => {
    if (
      selectedPlatform?.type?.toLocaleLowerCase().includes("tiktok") &&
      history.location.pathname === "/singlePlatform"
    ) {
      setDateSelection([
        {
          startDate: sub(new Date(), { days: 24 }),
          endDate: new Date(),
          key: "selection",
        },
      ]);
      dispatch(
        setLinkClicks({
          start: sub(new Date(), { days: 24 }),
          end: new Date(),
        })
      );
    } else if (history.location.pathname === "/singlePlatform") {
      setDateSelection([
        {
          startDate: sub(new Date(), { days: 30 }),
          endDate: new Date(),
          key: "selection",
        },
      ]);
      dispatch(
        setLinkClicks({
          start: sub(new Date(), { days: 30 }),
          end: new Date(),
        })
      );
    }
  }, [selectedPlatform]);

  React.useEffect(() => {
    let platformID = platforms.find((platform: any) =>
      platform?.type?.toLowerCase().includes("tiktok")
    )?.id;
    if (
      platformID &&
      selectedGroupAdaccounts?.filter(
        (account: any) => account.platformId === platformID
      )?.length &&
      history.location.pathname === "/actionImpactAnalysis"
    ) {
      setDateSelection([
        {
          startDate: sub(new Date(), { days: 24 }),
          endDate: new Date(),
          key: "selection",
        },
      ]);
      dispatch(
        setLinkClicks({
          start: sub(new Date(), { days: 24 }),
          end: new Date(),
        })
      );
    } else if (history.location.pathname === "/actionImpactAnalysis") {
      setDateSelection([
        {
          startDate: sub(new Date(), { days: 30 }),
          endDate: new Date(),
          key: "selection",
        },
      ]);
      dispatch(
        setLinkClicks({
          start: sub(new Date(), { days: 30 }),
          end: new Date(),
        })
      );
    }
  }, [selectedGroupAdaccounts]);

  const onDateRangeChange = (value: any) => {
    const date = {
      start: moment(value.start).format("YYYY-MM-DD"),
      end: moment(value.end).format("YYYY-MM-DD"),
    };
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(date));
    }
    dispatch(setLinkClicks(value));
  };

  const onDateSelect = () => {
    onDateRangeChange({
      start: dateSelection[0].startDate,
      end: dateSelection[0].endDate,
    });
    setShowCalendar(false);
    mixPanelAdEvent("Date Range Selected", {
      module_name: getModuleName(history.location.pathname),
      feature_name: "Graph - Action Impact",
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ad_account_id:
        history.location.pathname === "/singlePlatform"
          ? selectedAdAccount?.id || null
          : "ALL",
      ad_account_name:
        history.location.pathname === "/singlePlatform"
          ? selectedAdAccount?.name || null
          : "ALL",
    });
  };

  const wrapperRef = React.useRef(null);
  HandleClickOutside(wrapperRef, [
    setShowCalendar,
    setShowPhaseTimline,
    setShowAttributionMenu,
  ]);
  const decoratedOnClick = useAccordionButton("performance_analysis");

  const handleOpen = (e: any) => {
    if (title === "Performance Analysis") {
      mixPanelAdEvent("Performance Analysis Toggled", {
        username: user?.name,
        agency_id: selectedBrand?.agencyId,
        agency_name: selectedBrand?.agency_name,
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        ad_account_id: selectedAdAccount?.id,
        ad_account_name: selectedAdAccount?.name,
        ai_group_name: selectedGroup?.name,
        platform_name: getPlatformName(selectedPlatform?.name || ""),
        new_status: showHeader ? "Off" : "On",
      });
      decoratedOnClick(e);
      e.preventDefault();
      e.stopPropagation();
      setShowHeader(!showHeader);
    }
  };

  const onAttributionWindowChange = (value: any) => {
    let payload: any = {
      attributionWindow: value,
    };
    if (value) {
      dispatch(
        updateElementAttributionWindow({
          elementId: selectedAdAccount?.elementId,
          payload: payload,
        })
      );
    }
  };

  const handleAttributionClick = (e: any) => {
    setShowAttributionMenu(e?.currentTarget);
  };

  const handleAttributionClose = () => {
    setShowAttributionMenu(null);
  };

  return (
    <div
      className="header-container ml-2"
      style={{
        cursor: title === "Performance Analysis" ? "pointer" : "auto",
        height: "56px",
      }}
      id={"performance-analysis-section"}
    >
      <h4
        className="campaigns_header fs-6 pt-3 ml-2 d-flex w-100"
        onClick={handleOpen}
      >
        {title}
      </h4>
      <div
        ref={wrapperRef}
        className="phase-container align-items-center py-2 pr-3"
      >
        <div
          className="flex-center"
          style={{
            display:
              title === "Performance Analysis"
                ? showHeader
                  ? "flex"
                  : "none"
                : "flex",
          }}
        >
          {title === "Performance Analysis" &&
            selectedPlatform?.name &&
            (selectedPlatform?.name as string)
              .toLowerCase()
              .includes("facebook") && (
              <RadioMenu
                show={showAttributionMenu}
                onHide={handleAttributionClose}
                menuData={attributionWindowType}
                contentStyle={{
                  width: "15.625em",
                  margin: "-.6em 0em",
                }}
                handelClick={onAttributionWindowChange}
                selectedField={attributionWindow}
              >
                <StyledBtnContainer>
                  <Button
                    variant="contained"
                    sx={{
                      padding: "0.8em 1em",
                      borderRadius: "0.5em !important",
                      justifyContent: "space-between",
                      width: "14em",
                      top: "0em",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAttributionClick(e);
                    }}
                    className="radio-dropdown-btn"
                  >
                    <div className="text-left">
                      <div
                        style={{
                          fontSize: "0.75em",
                          lineHeight: "1em",
                          color: "#000000",
                          textTransform: "lowercase",
                        }}
                      >
                        {
                          attributionWindowType.find(
                            (each: any) => each.value === attributionWindow
                          )?.label
                        }
                      </div>
                      <div
                        style={{
                          fontSize: "0.625em",
                          lineHeight: "0.75em",
                          color: "#999999",
                          marginTop: "0.6em",
                        }}
                      >
                        Attribution Window
                      </div>
                    </div>
                    <DownArrowGrey />
                  </Button>
                </StyledBtnContainer>
              </RadioMenu>
            )}
          <div className={`date-range-picker`}>
            <button
              className="btn secondary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowPhaseTimline(false);
                setShowCalendar(!showCalendar);
              }}
            >
              {`${moment(linkClicks.start).format("ll")} - ${moment(
                linkClicks.end
              ).format("ll")}`}
              <DropDownArrow
                style={{ marginLeft: 8, paddingLeft: 3, marginBottom: 3 }}
              />
            </button>
            {showCalendar && (
              <DateRangePicker
                className="performance-calendar"
                dateSelection={dateSelection}
                setDateSelection={setDateSelection}
                maxLimit={0}
                minLimit={60}
                onSelect={onDateSelect}
                isPlatformTikTok={
                  history.location.pathname === "/singlePlatform"
                    ? selectedPlatform?.type
                        ?.toLocaleLowerCase()
                        .includes("tiktok")
                    : selectedGroupAdaccounts?.filter(
                        (account: any) =>
                          account.platformId ===
                          platforms.find((platform: any) =>
                            platform?.type?.toLowerCase().includes("tiktok")
                          )?.id
                      )?.length > 0
                }
              />
            )}
          </div>
          <div className="phase-container" ref={wrapperRef}>
            <button
              className="btn secondary btn-relative"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowCalendar(false);
                setShowPhaseTimline(!showPhaseTimline);
              }}
            >
              Phase timeline
              {showPhaseTimline && <PhaseTimeline phaseData={phaseTimeline} />}
            </button>
          </div>
        </div>
        {title === "Performance Analysis" ? (
          <DownIcon
            onClick={handleOpen}
            style={{
              rotate: showHeader ? "180deg" : "0deg",
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ActionImpactHeader;
