import {
  GetAllBrandDetails,
  GetBrandMetrics,
  SetAllBrandDetails,
  SetBrandMetrics,
  SetBrandType,
  SetAllBrandTypes,
  GET_ALL_BRAND_DETAILS,
  GET_BRAND_METRICS,
  SET_ALL_BRAND_DETAILS,
  SET_BRAND_METRICS,
  SET_BRAND_TYPE,
  SET_ALL_BRAND_TYPES,
  ResetBrandMetrics,
  RESET_BRAND_METRICS,
  GET_EDIT_BRAND_DETAILS,
  GetEditBrandDetails,
  SetEditBrandDetails,
  SET_EDIT_BRAND_DETAILS,
  SET_TOTAL_BRANDS_LOADING,
  SetTotalBrandsLoading,
  RESET_BRAND_DETAILS,
  ResetBrandDetails,
} from "../../types/SmbDashboard/brand";

export const getAllBrandsDetails = (payload: {
  agencyId: string;
  adAccountSummaryScreen?:boolean
}): GetAllBrandDetails => ({
  type: GET_ALL_BRAND_DETAILS,
  payload,
});

export const getBrandMetrics = (payload: {
  brandIds: any;
  selectedMonth: string;
  selectedYear: string;
}): GetBrandMetrics => ({
  type: GET_BRAND_METRICS,
  payload,
});

export const setAllBrandsDetails = (payload: any): SetAllBrandDetails => ({
  type: SET_ALL_BRAND_DETAILS,
  payload,
});

export const setBrandMetrics = (payload: any): SetBrandMetrics => ({
  type: SET_BRAND_METRICS,
  payload,
});

export const setBrandType = (payload: {
  brandId: any;
  brandStatus: string;
}): SetBrandType => ({
  type: SET_BRAND_TYPE,
  payload,
});

export const setAllBrandTypes = (payload: any): SetAllBrandTypes => ({
  type: SET_ALL_BRAND_TYPES,
  payload,
});

export const resetBrandDetails = (): ResetBrandDetails => ({
  type: RESET_BRAND_DETAILS,
});

export const resetBrandMetrics = (payload: any): ResetBrandMetrics => ({
  type: RESET_BRAND_METRICS,
  payload,
});

export const getEditBrandDetails = (
  brandId: string,
  callback: Function
): GetEditBrandDetails => ({
  type: GET_EDIT_BRAND_DETAILS,
  payload: {
    brandId,
  },
  callback,
});

export const setEditBrandDetails = (
  brandId: string,
  data: any,
  callback: Function
): SetEditBrandDetails => ({
  type: SET_EDIT_BRAND_DETAILS,
  payload: { brandId, data },
  callback,
});

export const setTotalBrandsLoading = (
  payload: boolean
): SetTotalBrandsLoading => ({
  type: SET_TOTAL_BRANDS_LOADING,
  payload,
});
