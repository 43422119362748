import { useContext, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "react-flow-renderer";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/system/Stack";

import LoaderContainer from "../../../common/LoaderContainer";
import LoaderRightStrip from "../../../common/LoaderRightStrip";

import { ReactComponent as CustomKeywords } from "../../assets/svg/calibration/customKeywords.svg";
import { ReactComponent as DataRecency } from "../../assets/svg/calibration/dataRecency.svg";
import { ReactComponent as Exploit } from "../../assets/svg/calibration/exploit.svg";
import { ReactComponent as Explore } from "../../assets/svg/calibration/explore.svg";
import { ReactComponent as Sources } from "../../assets/svg/calibration/sources.svg";

import { ReactComponent as DividerLarge } from "../../../ReactFlowComponents/Assets/svgs/dividerLarge.svg";
import { ReactComponent as Line } from "../../../ReactFlowComponents/Assets/svgs/str-line.svg";

import { TaiContext } from "Pages/TargetingAIPlaygroundBeta/TaiContextProvider";
import { calibrationData } from "../../constants/loader";
import { TAIGSearchMixPanel } from "../../utils";
import { CommonState } from "store/types/common";
import { AdAccountState } from "store/types/adaccount";

export default function LoaderCalibration({
  data,
  isConnectable,
}: {
  data: any;
  isConnectable: boolean;
}) {
  const dispatch = useDispatch();
  const { setCenter } = useReactFlow();

  const { setNodes, setEdges } = useContext(TaiContext) as any;
  const aiInitiation = useSelector((state: any) => state.aiInitiation.apiData);
  const aiInitiationFormData = useSelector(
    (state: any) => state.aiInitiation.formData
  );
  const loaderState = useSelector((state: any) => state.taiCommon.loader);
  const currentPage = useSelector((state: any) => state.taiCommon.currentPage);
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { selectedAgency } = common;
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;
  const [loadingId, setLoadingId] = useState(0);

  const calibrationContinueClicked = () => {
    TAIGSearchMixPanel(
      "TAI-Gsearch Calibration Loader Clicked",
      selectedBrand?.name,
      selectedAgency?.name,
      {
        ai_group_id: aiInitiationFormData?.selectedAiGroup?.id,
        ai_group_name: aiInitiationFormData?.selectedAiGroup?.name,
        ad_account_name: aiInitiation?.adAccount?.name,
        ad_account_id: aiInitiation?.adAccount?.id,
        agency_id: selectedAgency.id,
        brand_id: selectedBrand.id,
        brand_type: selectedBrand.brandType,
      }
    );
    data.loaderContinue(dispatch, loaderState, setNodes, setEdges);
  };
  document.addEventListener("visibilitychange", function () {
    // this event will work for all the loaders
    if (document.visibilityState === "hidden") {
      if (loadingId < 4) {
        setTimeout(() => {
          setLoadingId(loadingId + 1);
        }, 2000);
      }
    }
  });

  useEffect(() => {
    if (aiInitiation) {
      setLoadingId(0);
    }
  }, [aiInitiation]);

  useEffect(() => {
    if (loadingId < 4 && currentPage === data.index) {
      setTimeout(() => {
        setLoadingId(loadingId + 1);
      }, 2000);
    } else {
      setLoadingId(5);
    }
  }, [loadingId, currentPage]);

  useEffect(() => {
    if (data.position) {
      setCenter(data.position.x + 300, data.position.y + 300, {
        zoom: 0.7,
        duration: 1000,
      });
    }
  }, []);

  return (
    <>
      <div
        className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex focus:outline-none w-100 h-100"
        style={{
          position: "absolute",
          zIndex: "100",
        }}
      >
        <Handle
          type="target"
          position={Position.Left}
          style={{ background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position={Position.Right}
          style={{ background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={isConnectable}
        />

        <LoaderContainer
          steps={calibrationData.steps}
          loadingId={loadingId}
          continueProps={{
            onClick: calibrationContinueClicked,
            disabled: loadingId < 4,
            className: `${
              loadingId < 4 ? "disable-continue" : "enable-continue"
            } ${currentPage === data.index ? "" : "d-none"}`,
          }}
          loaderGifProps={{
            src: calibrationData.gif,
            alt: "Calibration",
            style: { height: "15rem", width: "12rem" },
          }}
          closeProps={{
            className: currentPage > data.index ? "" : "d-none",
            onClick: () =>
              data.loaderContinue(dispatch, loaderState, setNodes, setEdges),
          }}
          header="Calibration"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <DividerLarge
              style={{
                height: "20rem",
              }}
            />
            <Stack spacing={10} width="300px">
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LoaderRightStrip
                  items={[
                    {
                      title: "Exploit",
                      text: "Exploit refers to the keywords recommendation by the AI, using historical performance.",
                      svg: <Exploit />,
                    },
                  ]}
                />
                <Line />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LoaderRightStrip
                  items={[
                    {
                      title: "Explore",
                      text: "Explore refers to the new keywords recommended by the AI, for better performance.",
                      svg: <Explore />,
                    },
                  ]}
                />
                <Line />
              </Stack>
            </Stack>
            <Stack spacing={8}>
              <LoaderRightStrip
                items={[
                  {
                    title: "Data Recency",
                    text: "Choose the date range for which you wish to compute the historic performance",
                    svg: <DataRecency />,
                  },
                ]}
              />
              <Stack>
                <LoaderRightStrip
                  items={[
                    {
                      title: "Sources",
                      text: "Select the supporting keyword sources used by AI for enhanced computation",
                      svg: <Sources />,
                    },
                    {
                      title: "Custom Keywords",
                      text: "Add custom keywords to support seasonal or topical campaigns",
                      svg: <CustomKeywords />,
                    },
                  ]}
                />
              </Stack>
            </Stack>
          </Box>
        </LoaderContainer>
      </div>
    </>
  );
}
