import {
  GetAllMetrics,
  GetAttributionWindowType,
  GetElementAttributionWindow,
  GetElementMetrics,
  GetMetricGraphData,
  GET_ALL_METRICS,
  GET_ATTRIBUTION_WINDOW_TYPE,
  GET_ELEMENT_ATTRIBUTION_WINDOW,
  GET_ELEMENT_METRICS,
  GET_METRIC_GRAPH_DATA,
  MetricsType,
  SaveMetricElement,
  SAVE_METRIC_ELEMENT,
  SelectedDateType,
  SetActionDebuggerSwitchType,
  SetAllMetrics,
  SetAttributionWindowLoader,
  SetCompareType,
  SetCurrentGraphLoader,
  SetCurrentPeriod,
  SetElementMetrics,
  SetGraphMetrics,
  SetGraphUpdateLoader,
  SetIsDefault,
  SetMetricGraphData,
  SetMetricLoader,
  SetMetricMapper,
  SetMetricOldGraphData,
  SetPreviousGraphLoader,
  SetPreviousPeriod,
  SET_ACTION_DEBUGGER_SWITCH,
  SET_ALL_METRICS,
  SET_ATTRIBUTION_WINDOW_LOADER,
  SET_COMPARE_TYPE,
  SET_CURRENT_PERIOD,
  SET_ELEMENT_METRICS,
  SET_GRAPH_METRICS,
  SET_GRAPH_UPDATE_LOADER,
  SET_IS_DEFAULT,
  SET_METRIC_GRAPH_DATA,
  SET_METRIC_GRAPH_DATA_LOADER,
  SET_METRIC_LOADER,
  SET_METRIC_MAPPER,
  SET_METRIC_OLD_GRAPH_DATA,
  SET_METRIC_OLD_GRAPH_DATA_LOADER,
  SET_PREVIOUS_PERIOD,
  ToggleCompareFlag,
  TOGGLE_COMPARE_FLAG,
  UpdateElementAttributionWindow,
  UpdateMetricPriority,
  UPDATE_ELEMENT_ATTRIBUTION_WINDOW,
} from "../../types/ActionDebugger/ActionDebugger";

export const setActionDebuggerSwitch = (
  payload?: any
): SetActionDebuggerSwitchType => {
  return {
    type: SET_ACTION_DEBUGGER_SWITCH,
    payload,
  };
};

export const toggleCompareFlag = (payload: boolean): ToggleCompareFlag => {
  return {
    type: TOGGLE_COMPARE_FLAG,
    payload,
  };
};

export const setCurrentPeriod = (
  payload: SelectedDateType
): SetCurrentPeriod => {
  return {
    type: SET_CURRENT_PERIOD,
    payload,
  };
};

export const setPreviousPeriod = (
  payload: SelectedDateType
): SetPreviousPeriod => {
  return {
    type: SET_PREVIOUS_PERIOD,
    payload,
  };
};

export const setCompareType = (payload: number): SetCompareType => {
  return {
    type: SET_COMPARE_TYPE,
    payload,
  };
};

export const getAllMetrics = (payload: {
  elementId: string;
}): GetAllMetrics => {
  return {
    type: GET_ALL_METRICS,
    payload,
  };
};

export const setAllMetrics = (payload: MetricsType[]): SetAllMetrics => {
  return {
    type: SET_ALL_METRICS,
    payload,
  };
};

export const getElementMetrics = (payload: {
  elementId: string;
}): GetElementMetrics => {
  return {
    type: GET_ELEMENT_METRICS,
    payload,
  };
};

export const setElementMetrics = (
  payload: MetricsType[]
): SetElementMetrics => {
  return {
    type: SET_ELEMENT_METRICS,
    payload,
  };
};

export const getMetricGraphData = (payload: {
  typeOfData: string;
  elementId: string;
  start_date: string;
  end_date: string;
  adsetIds?: any;
  campaignIds?: any;
  adMetricIds?: string;
  data?: any;
  callback?: any;
  loaderAction?: string;
  attributionWindow: string;
  resultMetricId?: string;
}): GetMetricGraphData => {
  return {
    type: GET_METRIC_GRAPH_DATA,
    payload,
  };
};

export const setMetricGraphData = (payload: any): SetMetricGraphData => {
  return {
    type: SET_METRIC_GRAPH_DATA,
    payload,
  };
};
export const setMetricOldGraphData = (payload: any): SetMetricOldGraphData => {
  return {
    type: SET_METRIC_OLD_GRAPH_DATA,
    payload,
  };
};
export const saveMetricElement = (
  payload: {
    params: UpdateMetricPriority[];
    elementId: string;
    value: MetricsType[];
  },
  callback: Function
): SaveMetricElement => {
  return {
    type: SAVE_METRIC_ELEMENT,
    payload,
    callback,
  };
};
export const setMetricMapper = (payload: any): SetMetricMapper => {
  return {
    type: SET_METRIC_MAPPER,
    payload,
  };
};

export const setGraphUpdateLoader = (payload: number): SetGraphUpdateLoader => {
  return {
    type: SET_GRAPH_UPDATE_LOADER,
    payload,
  };
};

export const setMetricLoader = (payload: boolean): SetMetricLoader => {
  return {
    type: SET_METRIC_LOADER,
    payload,
  };
};

export const setCurrentGraphLoader = (
  payload: boolean
): SetCurrentGraphLoader => {
  return {
    type: SET_METRIC_GRAPH_DATA_LOADER,
    payload,
  };
};
export const setPreviousGraphLoader = (
  payload: boolean
): SetPreviousGraphLoader => {
  return {
    type: SET_METRIC_OLD_GRAPH_DATA_LOADER,
    payload,
  };
};

export const setIsDefault = (payload: boolean): SetIsDefault => {
  return {
    type: SET_IS_DEFAULT,
    payload,
  };
};

export const setGraphMetrics = (payload: MetricsType[]): SetGraphMetrics => {
  return {
    type: SET_GRAPH_METRICS,
    payload,
  };
};

export const setAttributionWindowLoader = (
  payload: boolean
): SetAttributionWindowLoader => {
  return {
    type: SET_ATTRIBUTION_WINDOW_LOADER,
    payload,
  };
};

export const getAttributionWindowType = (payload: {
  elementId: string;
}): GetAttributionWindowType => {
  return {
    type: GET_ATTRIBUTION_WINDOW_TYPE,
    payload,
  };
};

export const getElementAttributionWindow = (
  payload: any
): GetElementAttributionWindow => {
  return {
    type: GET_ELEMENT_ATTRIBUTION_WINDOW,
    payload,
  };
};

export const updateElementAttributionWindow = (
  payload: any
): UpdateElementAttributionWindow => {
  return {
    type: UPDATE_ELEMENT_ATTRIBUTION_WINDOW,
    payload,
  };
};
