import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as InfoIcon } from "../../../../../assets/svg/infoIcon.svg";
import { ReactComponent as Setting } from "../../../../../assets/svg/Setting.svg";
import { ClusterState } from "../../../../../store/types/cluster";
import { Button } from "../../../../shared";
import { useComponentDidMount, usePublish, usePreview } from "./hooks";
import "./index.scss";
import { Header } from "./styles";
import AdSection from "../AdSection/index";
import CreateAdPreview from "../CreateAdPreview";
import NewVariant from "../../modals/NewVariant/NewVariant";
import Published, { PublishedFailed } from "../../modals/Published";
import SaveAdVariants from "./SaveAdVariant";
import { AdAccountState } from "store/types/adaccount";
import {
  getRolesHierarchy,
  isEditorRole,
  isViewerRole,
} from "utils/commonFunctions";
import { RolesWrapper } from "utils/rolesWrapper";
import { useSearchParams } from "react-router-dom-v5-compat";

let retryCount: number = 0;

const CreateAdVariants: React.FC<{
  adUnit: any;
  screen: number;
}> = ({ adUnit, screen }): JSX.Element => {
  const clusterState = useSelector(
    (state: { cluster: ClusterState }) => state.cluster
  );
  const adAccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { showDrawer } = clusterState;
  const { brandRoles } = adAccount;
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  const dispatch = useDispatch();

  useComponentDidMount();
  const {
    handlePublish,
    isCreate,
    modal,
    handleVariantClose,
    variantName,
    handleNameChange,
    adGroupName,
    handleAdGroupNameChange,
    handleIsCreateNew,
    adGroups,
    finalUrl,
  } = usePublish();

  const { headlines, descriptions, publishDisabled } = usePreview();

  return (
    <div hidden={screen === 0}>
      {modal === "published-success" ? (
        <Published adName={adUnit?.campaign?.name} />
      ) : null}
      {modal === "published-fail" ? (
        <PublishedFailed
          handleRetry={() => {
            retryCount += 1;
            if (retryCount < 3) {
              handlePublish(false);
            } else {
              dispatch({
                type: "UPDATE_GSAI_STORE",
                payload: {
                  screen: 0,
                  modal: "",
                },
              });
              // window.location.href = "/singlePlatform" + window.location.search;
            }
          }}
        />
      ) : null}
      {modal === "create-variant" ? (
        <NewVariant
          variantName={variantName}
          handleClose={handleVariantClose}
          handleNameChange={handleNameChange}
          handleConfirm={() => handlePublish(false)}
          adGroupName={adGroupName}
          handleAdGroupNameChange={handleAdGroupNameChange}
          handleIsCreateNew={handleIsCreateNew}
          adGroups={adGroups}
        />
      ) : null}
      {showDrawer && (
        <div
          id="drawer"
          className="variant-drawer"
          style={isViewedFromBeta?{position:'static', width:'auto', overflow:'auto'}:{}}
          aria-hidden={showDrawer ? "false" : "true"}
        >
          <div className="variant-drawer-container">
            <div className={"variant-drawer-container--body"}>
              <Header>
                <div className="drawer-header-title">
                  <span className="ad-title">
                    {isCreate ? "Create Variant " : "Refresh Ad "}:{" "}
                    {adUnit?.campaign?.name}
                  </span>
                  <InfoIcon />
                </div>
                <div className="drawer-header-action-bar">
                  <button
                    className="variant-configure-btn"
                    onClick={() => {
                      dispatch({
                        type: "UPDATE_GSAI_STORE",
                        payload: {
                          modal: "configure-ad",
                        },
                      });
                    }}
                  >
                    <Setting />
                  </button>
                  <SaveAdVariants adUnit={adUnit} />
                  <RolesWrapper
                    disabled={
                      getRolesHierarchy(brandRoles) === "viewer" ||
                      getRolesHierarchy(brandRoles) === "editor"
                    }
                  >
                    <Button
                      disabled={
                        headlines.length < 3 ||
                        descriptions.length < 2 ||
                        finalUrl.length < 1 ||
                        publishDisabled
                        //  ||
                        // isViewerRole(brandRoles) ||
                        // isEditorRole(brandRoles)
                      }
                      buttonVariant="contained"
                      className="publish-btn"
                      onClick={() => {
                        dispatch({
                          type: "UPDATE_GSAI_STORE",
                          payload: {
                            modal: "review-modal",
                          },
                        });
                      }}
                    >
                      Review / Publish
                    </Button>
                  </RolesWrapper>
                </div>
              </Header>
              <div className="createad-variant-drawer-body">
                <div className="drawer-left-panel drawer-panel">
                  <AdSection />
                </div>
                <div className="drawer-right-panel drawer-panel">
                  <CreateAdPreview />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAdVariants;
