import axios from "axios";
import { refreshToken } from "../store/actions/auth";
import { user_session_key } from "./constants";
import store from "../store/store";
import { getLocalStorageData } from "./commonFunctions";

// axios instance for making requests
const axiosInstance = axios.create();
let refreshCall: any = null;

export const fetchToken = () => {
  if (!refreshCall) {
    refreshCall = new Promise((res) => {
      store.dispatch(
        refreshToken((session?: any) => {
          if (session) {
            res(session.accessToken);
          } else {
            res(null);
          }
          refreshCall = null;
        })
      );
    });
    return refreshCall;
  }
  return refreshCall;
};

axiosInstance.interceptors.request.use(async (config) => {
  if (
    // window.location.pathname.includes("/review/cai/facebook/") ||
    window.location.pathname.includes("/review/cai/meta/") ||
    window.location.pathname.includes("/review/cai/google/") ||
    window.location.pathname.includes("/google-analytics") ||
    window.location.pathname.includes("/password/successfull") ||
    window.location.pathname.includes("/password/expired")
  ) {
    return config;
  }

  const session = getLocalStorageData(user_session_key);
  const tokenIsExpired =
    parseInt(session?.expiresAt || 0) < new Date().getTime();
  if (tokenIsExpired) {
    const token = await fetchToken();
    if (token === null) {
      window.location.replace(`${window.location.origin}/auth/login`);
      return;
    }
    config.headers.auth = token;
    return config;
  } else {
    config.headers.auth = session.accessToken;
  }

  // config.headers.auth = commonAuth;
  return config;
});

export default axiosInstance;
