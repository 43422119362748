import React from "react";
import { StyledVideoContainer } from "../../styled";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import Loading from "../Loading/Loading";

export default function VideoComponent({
  src,
  isLoading,
  isFailed,
}: {
  src?: string;
  isLoading?: boolean;
  isFailed?: boolean;
}) {
  return (
    <StyledVideoContainer
      sx={
        isFailed || !src
          ? {
              backgroundColor: "#FFFFFF",
              height: "60vh",
            }
          : {}
      }
    >
      {isFailed ? (
        <ErrorComponent />
      ) : isLoading || !src ? (
        <Loading />
      ) : (
        <video
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          controls
          autoPlay
          src={src}
        />
      )}
    </StyledVideoContainer>
  );
}
