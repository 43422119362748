import { keyBy } from 'lodash';
import { useEffect, useState } from 'react';

export default function useFacebookTargetingEnumMapping() {
  const [mappings, setMappings] = useState<Record<string, Record<string, { value: number, name: string }>>>({});

  useEffect(() => {
    (async function () {
      const response = await fetch("https://targeting-ai.s3.ap-south-1.amazonaws.com/icp_fb_targetings_recms/config/facebook_targetings_enum_mapping.json");
      const data = await response.json();
      const mappings = Object
        .entries(data)
        .reduce((acc, [key, arrayOfObjects]) => ({
          ...acc,
          [key]: keyBy(arrayOfObjects as Record<string, { value: number, name: string }>, "value")
        }), {});
      setMappings(mappings);
    })()
  }, []);

  return mappings;
}