export default function ConfigureKeywordInfoItem({
  title,
  desc,
  items,
}: {
  title: string;
  desc?: string;
  items?: {
    title?: string;
    description?: string;
  }[];
}) {
  return (
    <div className="target-keyword-info--container">
      <div className="target-keyword-info--title">{title}</div>
      <div className="target-keyword-info--desc">
        {desc}
        {items && (
          <ol>
            {items.map(({ title, description }, index) => (
              <li key={index}>
                <span className="desc-title">{title}:</span> {description}
              </li>
            ))}
          </ol>
        )}
      </div>
    </div>
  );
}
