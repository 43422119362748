import {
  GET_COHORT_LIST,
  GET_COHORT,
  GetRecommendationMetadataTemplate,
  GET_RECOMMENDATION_METADATA_TEMPLATE,
  SetRecommendationTimer,
  SET_RECOMMENDATION_TIMER,
  SetRecommendationMetadataTemplate,
  SET_RECOMMENDATION_METADATA_TEMPLATE,
} from "../../../../types/CreativeAI/Facebook/SelectCohort/selectCohortType";

export const getCohortList = (payload?: any) => {
  return {
    type: GET_COHORT_LIST,
    payload,
  };
};

export const getCohort = (payload?: any) => {
  return {
    type: GET_COHORT,
    payload,
  };
};

export const getRecommendationMetadataTemplateAPICall = (
  payload?: any,
  callback?: Function
): GetRecommendationMetadataTemplate => {
  return {
    type: GET_RECOMMENDATION_METADATA_TEMPLATE,
    payload,
    callback,
  };
};

export const setRecommendationMetadataTemplate = (
  payload?: any
): SetRecommendationMetadataTemplate => {
  return {
    type: SET_RECOMMENDATION_METADATA_TEMPLATE,
    payload,
  };
};

export const setRecommendationTimer = (
  payload: any
): SetRecommendationTimer => ({
  type: SET_RECOMMENDATION_TIMER,
  payload,
});
