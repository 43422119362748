import { Popover, styled as mUIstyled } from "@mui/material";
import styled from "styled-components";

export const StyledPopover = mUIstyled(Popover)`
  border-radius: 0.625em;
  font-size: 0.82vw;
  @media screen and (max-width: 1280px) {
    font-size: 10px;
  }
`;

export const StyledToolTipWithBlock = styled.span`
  position: relative;
  display: flex;
  width: 5px;
  margin-top: -0.25em;
`;
