import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2022;

  button {
    margin: 0;
  }

  button:disabled {
    opacity: 0.5;
  }

  button.close {
    margin: 0;
    background: #f2f2f2;
    border-radius: 8px;
    height: 32px;
    width: 32px;
    position: absolute;
    top: 12px;
    right: 12px;

    path {
      fill: #424242;
    }
  }
`
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2021;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  padding: 0 20px;

  .header-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1c1e21;
  }
`
const Footer = styled.div`
  width: 100%;
  border-top: 1px solid #ebebeb;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 72px;
  padding: 0 20px;

  .transparent {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3f64fa;
    padding: 8px 24px;
    border: none;
    background: transparent;
  }

  .primary {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    padding: 8px 24px;
    border: none;
    background: #3f64fa;
    border-radius: 8px;
  }
`

const Scrollable = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`

type Props = {
  header?: React.ReactNode
  footer?: React.ReactNode
  children: React.ReactNode
  className?: string
  style?: Record<string, any>
}

function Modal({ header, footer, children, className = '', style = {} }: Props) {
  return (
    <>
      <Backdrop />
      <Container className={`${className ? className : ''}`} style={style}>
        {header ? <Header>{header}</Header> : null}
        <Scrollable>{children}</Scrollable>
        {footer ? <Footer>{footer}</Footer> : null}
      </Container>
    </>
  )
}

export default Modal
