import { Box, CircularProgress } from "@mui/material";
import { Button } from "components/shared";
import { useSelector } from "react-redux";
import { InitialMediaStudioType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { ReactComponent as ReloadIcon } from "../../../../../../../assets/Reload-icon.svg";
import { ReactComponent as DangerCircle } from "../../../../../../../assets/svg/Danger-Circle.svg";
import { StyledErrorSubTitle, StyledHeadTitle } from "../../Preview/styled";
import { StyledFailedTitle, StyledSubTitle } from "./styeld";
import SuccessGif from "../../../../../../../assets/Gif/Loaded.gif";
import { useCallback, useEffect, useState } from "react";

const fixedHeight = "230px";

const absoluteStyle = {
  position: "absolute",
  top: 0,
};

const blurFilterSx = {
  ...absoluteStyle,
  background: "rgba(0, 0, 0, 0.7)",
  backdropFilter: "blur(5px)",
};

export default function RecommendationVideoImageComponent({
  url,
}: {
  url: string;
}) {
  const { frameVGSReqIdLoading, frameVGSReqIdFailure } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );

  const [imageHeightWidth, setImageHeightWidth] = useState({
    height: "100%",
    width: "60%",
  });

  useEffect(() => {
    const img = document.getElementById("video-image");
    setImageHeightWidth({
      height: img?.clientHeight ? img?.clientHeight + "px" : "100%",
      width: img?.clientWidth ? img?.clientWidth + "px" : "60%",
    });
  }, [url]);

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      display="flex"
      height={fixedHeight}
      sx={{
        position: "relative",
      }}
    >
      <FrameImageSuccess url={url} />
      {frameVGSReqIdLoading && (
        <FrameLoader imageHeightWidth={imageHeightWidth} />
      )}
      {frameVGSReqIdFailure && (
        <VideoFramePreviewFailed imageHeightWidth={imageHeightWidth} />
      )}
    </Box>
  );
}

const FrameLoader = ({ imageHeightWidth }: { imageHeightWidth: any }) => {
  return (
    <Box
      height={imageHeightWidth.height}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={blurFilterSx}
      width={imageHeightWidth.width}
    >
      <img src={SuccessGif} alt="" style={{ height: 35, width: 35 }} />
    </Box>
  );
};

const FrameImageSuccess = ({ url }: { url: string }) => {
  return (
    <Box
      sx={absoluteStyle}
      component="img"
      src={url}
      height="100%"
      id="video-image"
    />
  );
};

const VideoFramePreviewFailed = ({
  imageHeightWidth,
}: {
  imageHeightWidth: any;
}) => {
  return (
    <Box
      height={imageHeightWidth.height}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={blurFilterSx}
      width={imageHeightWidth.width}
    >
      <DangerCircle />
      <StyledFailedTitle
        style={{
          color: "#FFFFF",
        }}
      >
        Preview Failed
      </StyledFailedTitle>
      {/* <StyledSubTitle>Kindly refresh to see last preview</StyledSubTitle> */}
      {/* <Button
        buttonVariant="outlined"
        startIcon={
          <ReloadIcon
            style={{
              color: "#FFFFF !important",
            }}
          />
        }
        onClick={() => {}}
        sx={{
          color: "#FFFFF",
          marginTop: "20px",
        }}
      >
        <span
          style={{
            color: "#FFFFF",
          }}
        >
          Restart
        </span>
      </Button> */}
    </Box>
  );
};
