import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PublishModalClose } from "../../../assets/svg/PublishModalClose.svg";
import { ReactComponent as EditIcon } from "../../../assets/tai/editIcon.svg";
import { ReactComponent as SaveIcon } from "../../../assets/tai/saveIcon.svg";
import { setSelectedCohortsForPublish } from "store/actions/targetingAI";
import CustomAccordian from "../common/CustomAccordian";

import "./PublishModal.scss";
import { IconButton } from "@mui/material";
import { Modal } from "react-bootstrap";

function EditAdsetModal({
  showConfigureModal,
  selectedValues,
  setShowModal,
  setShowConfigureModal,
  setSelectedValues,
}) {
  const dispatch = useDispatch();
  const cohorts = useSelector((state) => state.cohorts);

  const [showEditIcon, setShowEditIcon] = useState(-1);
  const [editId, setEditId] = useState(-1);
  const [editValue, setEditValue] = useState("");

  const [showSaveIcon, setShowSaveIcon] = useState(-1);
  const {agencyAssets} = useSelector((state) => state.common);

  useEffect(() => {
    const cohortsData = cohorts.reduce((prev, cohort) => {
      prev[cohort.cohort_id] = cohort;
      return prev;
    }, {});

    const adsetCohortMapping = Object.keys(selectedValues).reduce(
      (prev, curr) => {
        let updatedPrev;
        if (selectedValues[curr].adset) {
          updatedPrev = {
            [curr]: {
              ...selectedValues[curr],
              adset: {
                ...selectedValues[curr].adset,
                cohorts: selectedValues[curr].adset.cohorts || cohortsData,
              },
            },
          };
        }
        return { ...prev, ...updatedPrev };
      },
      {}
    );
    setSelectedValues({ ...selectedValues, ...adsetCohortMapping });
  }, []);

  return (
    <div className="position-absolute translate-middle top-50 start-50">
      <div
        className="bg-white rounded"
        style={{
          border: "1px solid #5E9EFE",
          boxShadow: "0px 0.5rem 0.75rem rgba(0, 31, 79, 0.08)",
          width: "36.125rem",
          minHeight: "40rem",
          maxHeight: "44.375rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="px-3 py-2 heading d-flex align-items-center justify-content-between font-lato w-100"
          style={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
            borderRadius: "0.625rem 0.625rem 0px 0px",
            height: "10%",
          }}
        >
          <div className="d-flex flex-column align-items-start">
            <div
              className="weight-500 font-Lato"
              style={{
                fontSize: "1.125rem",
                color: "#333",
              }}
            >
              Edit Adset Name
            </div>
            <div
              className="font-lato"
              style={{
                color: "#999999",
                fontSize: "0.75rem",
                fontWeight: "400",
                fontStyle: "italic",
              }}
            >
              Note: Each cohort is published as an ad set with {agencyAssets.title || 'Pixis'}-defined
              suffix
            </div>
          </div>
          <button
            className="p-0 text-black bg-transparent border-0"
            onClick={(e) => {
              e.preventDefault();
              setShowModal(false);
              dispatch(setSelectedCohortsForPublish([]));
            }}
          >
            <PublishModalClose />
          </button>
        </div>
        <div className="px-3 mt-3 body" style={{ height: "80%" }}>
          <div
            className="publish-accordion"
            style={{
              fontSize: "0.875rem",
              color: "#666666",
              overflowY: "scroll",
              maxHeight: "35rem",
            }}
          >
            <CustomAccordian
              cards={(selectedValues ? Object.keys(selectedValues) : [])
                .filter((key) => selectedValues[key].adset)
                .map((key, index) => {
                  const data = selectedValues[key];
                  return {
                    label: (
                      <>
                        <div className="font-14 d-flex align-items-center mb-2">
                          <div
                            className="font-weight-600"
                            style={{
                              color: "#222",
                            }}
                          >
                            Selected Adset:
                          </div>
                          <div
                            className="font-weight-500"
                            style={{
                              color: "#999",
                            }}
                          >
                            {data.adset.name}
                          </div>
                        </div>
                        <hr className="mx-1 my-0" />
                      </>
                    ),
                    additional: (
                      <div
                        className="d-flex font-18 "
                        style={{
                          color: "#333",
                        }}
                      >
                        <div className="mb-2 font-Lato font-weight-600 pr-1">
                          Account Name:
                        </div>
                        <div className="font-weight-500">
                          {data.adAccountName}
                        </div>
                      </div>
                    ),
                    description: (
                      <div className="px-2 w-100 d-flex flex-column size-14 align-items-center">
                        {data.adset.cohorts &&
                          Object.values(data.adset.cohorts).map(
                            (item, index) => (
                              <React.Fragment key={cohorts}>
                                <button
                                  style={{
                                    background:
                                      showEditIcon === item.cohort_id
                                        ? "#FAFAFA"
                                        : "#FFFFFF",
                                    border:
                                      editId === item.cohort_id
                                        ? "1px solid #0869FB"
                                        : "none",
                                    borderBottom:
                                      editId === item.cohort_id
                                        ? "1px solid #0869FB"
                                        : "1px solid #d6e2e9",
                                    borderRadius:
                                      editId === item.cohort_id ? "8px" : "",
                                    padding: "1rem",
                                  }}
                                  key={item.cohort_id}
                                  className={`d-flex w-100 my-1 text-left`}
                                  onMouseEnter={() => {
                                    if (editId === -1) {
                                      setShowEditIcon(item.cohort_id);
                                    } else {
                                      setShowEditIcon(-1);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    setShowEditIcon(-1);
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "95%",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflowX: "hidden",
                                    }}
                                    className="font-12"
                                    title={`${
                                      editId !== item.cohort_id &&
                                      (item.new_adset_name &&
                                      item.new_adset_name !== ""
                                        ? item.new_adset_name
                                        : data.adset.name.replaceAll(" ", "_"))
                                    }_TAI_${item.cohort_name}`}
                                  >
                                    <div className="font-weight-600">
                                      New Ad Set #{index + 1} | Cohort:{" "}
                                      {item.cohort_name}
                                    </div>
                                    <div className="d-flex font-weight-400">
                                      <div style={{ color: "#343434" }}>
                                        {editId === item.cohort_id && (
                                          <input
                                            value={editValue}
                                            onChange={(e) => {
                                              setEditValue(e.target.value);
                                              setShowEditIcon(-1);
                                              setShowSaveIcon(item.cohort_id);
                                            }}
                                            style={{
                                              background:
                                                showEditIcon === item.cohort_id
                                                  ? "#FAFAFA"
                                                  : "#FFFFFF",
                                              border: "none",
                                              outline: "none",
                                            }}
                                            autoFocus
                                          />
                                        )}
                                        {editId !== item.cohort_id &&
                                          (item.new_adset_name &&
                                          item.new_adset_name !== ""
                                            ? item.new_adset_name
                                            : data.adset.name.replaceAll(
                                                " ",
                                                "_"
                                              ))}
                                      </div>
                                      <div style={{ color: "#999" }}>
                                        _TAI_{item.cohort_name}
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      width: "6%",
                                      cursor:
                                        editValue === ""
                                          ? "not-allowed"
                                          : "default",
                                    }}
                                  >
                                    {editId === item.cohort_id && (
                                      <IconButton
                                        onClick={() => {
                                          if (editValue !== item.cohort_name) {
                                            setSelectedValues({
                                              ...selectedValues,
                                              [key]: {
                                                ...selectedValues[key],
                                                adset: {
                                                  ...selectedValues[key].adset,
                                                  cohorts: {
                                                    ...selectedValues[key].adset
                                                      .cohorts,
                                                    [item.cohort_id]: {
                                                      ...selectedValues[key]
                                                        .adset.cohorts[
                                                        item.cohort_id
                                                      ],
                                                      new_adset_name: editValue,
                                                    },
                                                  },
                                                },
                                              },
                                            });
                                          }
                                          setEditValue("");
                                          setEditId(-1);
                                        }}
                                        disabled={editValue === ""}
                                        sx={
                                          editValue === ""
                                            ? {
                                                opacity: 0.5,
                                                p: 0,
                                              }
                                            : { p: 0 }
                                        }
                                        disableRipple
                                      >
                                        <SaveIcon />
                                      </IconButton>
                                    )}
                                    {showEditIcon === item.cohort_id &&
                                      editId <= -1 && (
                                        <IconButton
                                          onClick={() => {
                                            const valuePresent =
                                              selectedValues[key].adset.cohorts[
                                                item.cohort_id
                                              ].new_adset_name;
                                            setEditId(item.cohort_id);
                                            setEditValue(
                                              valuePresent
                                                ? valuePresent
                                                : data.adset.name
                                            );
                                          }}
                                          sx={{ p: 0 }}
                                          disableRipple
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      )}
                                  </div>
                                </button>
                                <hr className="mx-2 my-0 " />
                              </React.Fragment>
                            )
                          )}
                      </div>
                    ),
                    selected: (
                      <>
                        <div className="font-14 d-flex align-items-center">
                          <div
                            className="font-weight-600 pr-1"
                            style={{
                              color: "#222",
                            }}
                          >
                            Selected Adset:
                          </div>
                          <div
                            className="font-weight-500"
                            style={{
                              color: "#999",
                            }}
                          >
                            {data.adset.name}
                          </div>
                        </div>
                      </>
                    ),
                    key: key,
                  };
                })}
            />
          </div>
        </div>
        <div
          className="pt-2 pb-2 action-btns d-flex justify-content-between align-items-center"
          style={{
            boxShadow: "0px 0px 2px rgba(30, 21, 83, 0.2)",
            borderRadius: "0px 0px 0.625rem 0.625rem",
            marginTop: "auto",
            height: "10%",
          }}
        >
          <button
            className="btn link font-18 font-weight-600"
            style={{
              color: "#0869FB",
            }}
            onClick={() => {
              setEditValue("");
              setEditId(-1);
              setShowConfigureModal(false);
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditAdsetModal;
