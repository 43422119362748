import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";

import BrandRowLeft from "../Components/BrandRow/BrandRowLeft";
import BrandRowRight from "../Components/BrandRow/BrandRowRight";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

function BrandRow({
  searchTerm,
  handleBrandClick,
  showOptimizer,
  setShowOptimizer,
  showPlatform,
  setShowPlatform,
  setEditBrandOpen,
  activeTileKey,
}: any) {
  const brandState: any = useSelector((state: any) => state.smb.brand);
  const [searchedBrandState,setSearchedBrandState]=useState(Object.keys(brandState?.data ||[]))
  const [paginatedData, updatePaginatedData] = useState<any>([]);
  const [searchedPaginatedData, updateSearchedPaginatedData] = useState<any>(paginatedData);

  useEffect(()=>{
    if(searchTerm)
    {
      setSearchedBrandState(
        Object.keys(brandState.data).filter((item) =>
          brandState.data[item].details.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      );
      updatePaginatedData(
        [...Object.keys(brandState.data).slice(0,11)]
      );
    }
    else{
      setSearchedBrandState(Object.keys(brandState?.data ||[]))
      updatePaginatedData([...Object.keys(brandState.data).slice(0,11)]);
    }
  },[searchTerm,brandState])

  useEffect(() => {
    if (searchTerm) {
      updateSearchedPaginatedData(
        Object.keys(brandState.data).filter((item) =>
          brandState.data[item].details.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      );
    } else {
      updateSearchedPaginatedData(paginatedData);
    }
  }, [paginatedData]);

  return (
    <>
      <Box sx={{ cursor: "pointer" }}>
        <Grid container>
          <Grid
            item
            xs={1.75}
            gap={2}
            display={"flex"}
            flexDirection={"column"}
          >
            <InfiniteScroll
              style={{display:"flex", flexDirection:"column", gap:"16px"}}
              dataLength={paginatedData.length}
              next={() => {
                let brandIds = searchedBrandState.length?searchedBrandState:Object.keys(brandState?.data);
                if (brandIds.length - paginatedData.length < 10) {
                  updatePaginatedData([
                    ...paginatedData,
                    ...searchedBrandState.slice(
                      paginatedData.length,
                      paginatedData.length +
                      searchedBrandState.length -
                        paginatedData.length
                    ),
                  ]);
                } else {
                  updatePaginatedData([
                    ...paginatedData,
                    ...searchedBrandState.slice(
                      paginatedData.length,
                      paginatedData.length + 10
                    ),
                  ]);
                }
              }}
              hasMore={!(paginatedData.length === Object.keys(brandState.data).length)}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
            {searchedPaginatedData.map(
              (brandId: any) =>
                (false &&
                  brandState.data[brandId].details.name
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase())) ||
                (brandState.data[brandId].details &&
                  brandState.data[brandId].metrics &&
                  (activeTileKey === "total_brands" ||
                    brandState.types[activeTileKey].brandIds.includes(
                      brandId
                    )) && (
                    <BrandRowLeft
                      key={brandId}
                      brandId={brandId}
                      onClick={(e: any) =>
                        handleBrandClick(
                          e,
                          brandId,
                          brandState.data[brandId].details.name
                        )
                      }
                      showOptimizer={showOptimizer}
                      setShowOptimizer={setShowOptimizer}
                      showPlatform={showPlatform}
                      setShowPlatform={setShowPlatform}
                      setEditBrandOpen={setEditBrandOpen}
                    />
                  ))
            )}
            </InfiniteScroll>
          </Grid>
          <Grid
            item
            xs={10}
            gap={2}
            display={"flex"}
            flexDirection={"column"}
            overflow={"scroll"}
          >
            <InfiniteScroll
              style={{display:"flex", flexDirection:"column", gap:"16px"}}
              dataLength={paginatedData.length}
              next={() => {
                let brandIds = searchedBrandState.length?searchedBrandState:Object.keys(brandState?.data);
                if (brandIds.length - paginatedData.length < 10) {
                  updatePaginatedData([
                    ...paginatedData,
                    ...searchedBrandState.slice(
                      paginatedData.length,
                      paginatedData.length +
                      searchedBrandState.length -
                        paginatedData.length
                    ),
                  ]);
                } else {
                  updatePaginatedData([
                    ...paginatedData,
                    ...searchedBrandState.slice(
                      paginatedData.length,
                      paginatedData.length + 10
                    ),
                  ]);
                }
              }}
              hasMore={!(paginatedData.length === Object.keys(brandState.data).length)}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
            {searchedPaginatedData.map(
              (brandId: any) =>
                (false &&
                  brandState.data[brandId].details.name
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase())) ||
                (brandState.data[brandId].details &&
                  brandState.data[brandId].metrics &&
                  (activeTileKey === "total_brands" ||
                    brandState.types[activeTileKey].brandIds.includes(
                      brandId
                    )) && (
                    <BrandRowRight
                      key={brandId}
                      brandId={brandId}
                      onClick={(e: any) =>
                        handleBrandClick(
                          e,
                          brandId,
                          brandState.data[brandId].details.name
                        )
                      }
                      showOptimizer={showOptimizer}
                      setShowOptimizer={setShowOptimizer}
                      showPlatform={showPlatform}
                      setShowPlatform={setShowPlatform}
                      setEditBrandOpen={setEditBrandOpen}
                    />
                  ))
            )}
            </InfiniteScroll>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default BrandRow;
