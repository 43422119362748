import { InputLabel } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import styled from "styled-components";
import { InputLabelProps } from "./InputLabel";

export const StyledLabel = muiStyled(InputLabel)`
font-style: normal;
font-weight: 500;
display: flex;
align-items: center;
color: ${(props: InputLabelProps) => props.textColor ?? "#444444"} ;
font-family:'Inter';
font-size: 14px;
line-height: 20px;
.MuiInputLabel-asterisk{
    color:red
}
`;

export const StyledLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;
