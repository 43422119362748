import React, { memo, useContext, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "react-flow-renderer";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllCalibrationFormData,
  setCalibrationData,
  setCalibrationFormData,
  setPolling,
  storeTargetingAIKWS,
} from "../../../store/actions/targetingAI";
import { mixPanelAdEvent } from "../../../utils/mixpanel";
import { ReactComponent as Continue } from "../ReactFlowComponents/Assets/svgs/continue.svg";
import { TaiContext } from "../TaiContextProvider";
import { storeTargetingAIKWSICP } from "store/actions/targetingICP";
import useFocusToNode from "../useFocusToNode";
import { Tooltip } from "@mui/material";
import warningIcon from '../assets/svgs/warning-icon.svg';
import { resetAdditionalInputData } from "store/actions/gsTargetingAI";

export default memo(({ data, isConnectable }) => {
  const dispatch = useDispatch();
  const { checkStepAndComplete, setNodes, setEdges, cohortType } =
    useContext(TaiContext);
  const adaccount = useSelector((state) => state.adaccount);
  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;
  const authState = useSelector((state) => state.auth);
  const { user } = authState;
  const aiInitiationData = useSelector((state) => state.aiInitiation.apiData);
  const aiInitiationFormData = useSelector(
    (state) => state.aiInitiation.formData
  );
  const callibrationData = useSelector((state) => state.calibration.formData);
  const currentPage = useSelector((state) => state.taiCommon.currentPage);
  const loaderState = useSelector((state) => state.taiCommon.loader);
  const common = useSelector((state) => state.common);
  const { selectedAgency } = common;
  const [isDisabled,setisDisabled] = useState(false);
  let [currentData, setCurrentData] = useState(callibrationData);

  const convertToInt = (value) => {
    return value ? parseInt(value) : 0;
  };

  const kwsData = {
    name: aiInitiationData.name,
    website: aiInitiationData.website,
    ad_account_id: aiInitiationData.adAccount.id,
    setup: {
      brand_website_value: callibrationData.BrandWebsiteValueCheck
        ? convertToInt(callibrationData.BrandWebsiteValue)
        : 0,
      competitor_website_value: callibrationData.CompetitorWebsiteValueCheck
        ? convertToInt(callibrationData.CompetitorWebsiteValue)
        : 0,
      custom_keywords: callibrationData.CalibrationCustomKeywordsCheck
        ? callibrationData.CalibrationCustomKeywords
        : [],
      exploit_value: callibrationData.ExploitValueCheck
        ? convertToInt(callibrationData.ExploitValue)
        : 0,
      intent_value: 100, // WIll need to remove once it is done from Backend - @viral  //changed value from 0 to 100 - @vipin
      traffic_value: 100, // WIll need to remove once it is done from Backend - @viral  //changed value from 0 to 100 - @vipin
    },
    country: aiInitiationData.country.code,
  };

  const ICPKwsData = {
    // service: "icp_keywords_recms",
    // version: "v0.1.0",
    payload_data: {
      brand_details: {
        brand_name: aiInitiationData.name,
        brand_website: aiInitiationData.website,
      },
      geo_locations: {
        countries: aiInitiationData.country?.map((country)=>country.code),
        regions_ids:
          aiInitiationData.region.map((region) => region.key) ||
          [],
      },
      icp_context_description: callibrationData?.ICPContextDescription,
      existing_icps_data: [],
    },
  };

  const handleKWS = () => {
    if (cohortType === "ICP") {
      dispatch(
        storeTargetingAIKWSICP({ kwsData: ICPKwsData }, (response, error) => {
          if (!error && response?.data?.requestid) {
            dispatch(
              // setPolling({
              //   name: "icp",
              //   requestId: "985273a1-71b2-49c9-b8c3-0979e2cca3cc",
              // })
              setPolling({
                name: "icp",
                requestId: response?.data?.requestid,
              })
            );
          }
        })
      );
    } else {
      dispatch(
        storeTargetingAIKWS({ kwsData, user }, (response, error) => {
          if (!error && response?.data?.message?.requestid) {
            dispatch(
              setPolling({
                name: "calibration",
                requestId: response.data.message.requestid,
              })
            );
          }
        })
      );
    }

    setCurrentData(callibrationData);
    mixPanelAdEvent("TAI Calibration Step Done", {
      module_name: "Facebook Playground",
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedAgency?.id,
      agency_name: selectedAgency?.name,
      ai_group_id: aiInitiationFormData?.selectedAiGroup?.id,
      ai_group_name: aiInitiationFormData?.selectedAiGroup?.name,
      explore_value: callibrationData.ExploitValueCheck
        ? 100 - convertToInt(callibrationData.ExploitValue)
        : 0,
      ...kwsData.setup,
      ad_account_id: aiInitiationData?.adAccount?.id,
      ad_account_name: aiInitiationData?.adAccount?.name,
      tai_flow: cohortType,
    });
  };

  const focusToNode = useFocusToNode();
  useEffect(()=>{
    if(cohortType === "INTEREST"){
      if(callibrationData.ExploitValue>0){
        if(callibrationData.selectedCustomAudience.length){
           setisDisabled(false);
        }else{
          setisDisabled(true);
        }
       }else{
        setisDisabled(false);
       }
    }else{
        setisDisabled(false);
    }
    
  },[callibrationData.ExploitValue,callibrationData.selectedCustomAudience,cohortType]);
  return (
    <div>
      <Handle
        type="source"
        position="right"
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Right}
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div
        className="px-4 py-1 border-b d-flex justify-content-between border-zinc-300 align-items-center"
        style={{ height: "40px !important" }}
      >
        <div
          className="text-xl font-semibold d-flex"
          style={{
            marginBottom: "0 !important",
            padding: "10px",
            fontSize: "20px",
            width: "100%",
          }}
        >
          <div>Calibration</div>
          {currentPage > data.index && (
            <div
              style={{ marginLeft: "auto" }}
              onClick={() => {
                dispatch(setAllCalibrationFormData(currentData));
                data.onEditClick(
                  dispatch,
                  loaderState,
                  setNodes,
                  setEdges,
                  cohortType,
                  focusToNode
                );
              }}
            >
              <Continue />
            </div>
          )}
        </div>
        <Tooltip
          title={isDisabled ? "Please select at least 1 custom audience since Exploit lever is >0%" : ""}
          arrow
          placement="top"
        >
        <button
          className="px-4 py-2 m-1 text-sm text-white border border-blue-500 d-flex font-weight-bold"
          onClick={() => {
            checkStepAndComplete([1, 2], data.index, () => {
              dispatch(
                setCalibrationFormData({
                  key: "TypeOfCohort",
                  value: cohortType,
                })
              );
              dispatch(setCalibrationData({
                loadedKeywords: {
                    brandLoadedKeywords: 7,
                    lateralLoadedKeywords: 7,
                    genericLoadedKeywords: 7,
                  }
              }));
              dispatch(resetAdditionalInputData());
              handleKWS();
              data.nextPage(
                dispatch,
                loaderState,
                setNodes,
                setEdges,
                focusToNode
              );
            });
          }}
          style={{
            backgroundColor:isDisabled ? "#CEE2FF" : "#0769FB",
            borderRadius: "8px",
            position: "absolute",
            right: "10px",
            bottom: "10px",
          }}
          disabled={isDisabled}
        >
            <span className="flex items-center justify-center gap-1">
              <span>Run</span> 
              {isDisabled ? <img src={warningIcon} alt="warning"/> : <></>}
            </span>
          </button>
        </Tooltip>
       
      </div>
    </div>
  );
});
