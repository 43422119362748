import React from "react";
import ModalContainer from "components/Modal/ModalContainer";
import ModalWrapper from "components/Modal/ModalWrapper";
import {
  StyledReferenceContainer,
  StyledReferenceSubtitle,
  StyledReferenceTitle,
  StyledSubmitTextContainer,
} from "./styled";
import { ReactComponent as ReferenceModalIcon } from "../../../../assets/svg/referenceModal.svg";
import { useDispatch } from "react-redux";
import { payloadForSkip } from "components/CreativeAI/shared/commonFunctions";

const ReferenceModal: React.FC<{ adUnit: any }> = ({ adUnit }): JSX.Element => {
  const dispatch = useDispatch();
  return (
    <ModalWrapper>
      <ModalContainer
        submitText={
          <StyledSubmitTextContainer>
            <span>Go ahead</span>
          </StyledSubmitTextContainer>
        }
        cancelText="Skip"
        handleClose={() => {
          dispatch({
            type: "UPDATE_GSAI_STORE",
            payload: {
              modal: "",
              ...payloadForSkip(adUnit),
            },
          });
        }}
        handleSubmit={() => {
          dispatch({
            type: "UPDATE_GSAI_STORE",
            payload: {
              modal: "generating-recommendations",
            },
          });
        }}
        showHeader={false}
      >
        <StyledReferenceContainer>
          <ReferenceModalIcon />
          <StyledReferenceTitle>
            Your reference Ad has been updated. Click Go Ahead to refresh the
            current page and view the updates.
          </StyledReferenceTitle>
          <StyledReferenceSubtitle>
            NOTE: The refresh option is recommended only if your status is in
            Review or Rejected
          </StyledReferenceSubtitle>
        </StyledReferenceContainer>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default ReferenceModal;
