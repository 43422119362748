import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as Close } from "../../../../assets/cai/svg/close_solid_background.svg";
import { ReactComponent as Minimize } from "../../../../assets/cai/svg/minimize.svg";
import {
  StyledDialog,
  StyledMinimizeWrapper,
  StyledPxDialogTitle,
} from "./styled";
import { DialogActions, DialogProps } from "@mui/material";
import { ReactNode } from "react";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
  minimize?: boolean;
}

function PxDialogTitle(props: DialogTitleProps) {
  const { children, onClose, minimize, ...other } = props;

  return (
    <StyledPxDialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {minimize ? (
            <StyledMinimizeWrapper>
              <Minimize />
            </StyledMinimizeWrapper>
          ) : (
            <Close />
          )}
        </IconButton>
      ) : null}
    </StyledPxDialogTitle>
  );
}

interface PxDialogProps extends DialogProps {
  open: boolean;
  onClose?: () => void;
  children: any;
  titleComponent: any;
  actions?: ReactNode;
  minimize?: boolean;
  isFrom1920Screen?: boolean;
  hideTitle?: boolean;
}

export function PxDialog({
  open,
  onClose,
  children,
  titleComponent,
  actions,
  hideTitle,
  minimize,
  isFrom1920Screen,
  ...props
}: PxDialogProps) {
  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...props}
      isFrom1920Screen={isFrom1920Screen}
    >
      {!hideTitle && (
        <PxDialogTitle
          id="customized-dialog-title"
          onClose={onClose}
          minimize={minimize}
        >
          {titleComponent}
        </PxDialogTitle>
      )}
      <DialogContent
        sx={{
          padding: isFrom1920Screen ? "0px" : "20px 24p",
        }}
      >
        {children}
      </DialogContent>
      {actions && (
        <DialogActions
        // sx={{
        //   padding: "0px",
        // }}
        >
          {actions}
        </DialogActions>
      )}
    </StyledDialog>
  );
}
