import httpService from "HttpService/HttpService";
import { call, race, take, takeLatest } from "redux-saga/effects";
import {
  CANCEL_GET_BILLBOARD_API,
  GenerateBillboardLink,
  GENERATE_BILLBOARD_LINK,
  GetBillboardVideoUrl,
  GET_BILLBOARD_VIDEO_URL,
} from "store/types/CreativeAI/Facebook/CreateVariant/creativeAnimater";
import { BASE_URL } from "utils/path";

function* generateBillboardLink({
  payload: { body, callback },
}: GenerateBillboardLink): any {
  try {
    const url = `/v1/api/creative-studio/generate-link`;

    const { response, cancel } = yield race({
      response: call(httpService.post, BASE_URL, url, "", body),
      cancel: take(CANCEL_GET_BILLBOARD_API),
    });
    if (cancel) {
      callback &&
        callback({
          isLoading: false,
          isFailed: false,
          cancel: true,
        });
    }

    if (response) {
      if (response.data.success) {
        callback &&
          callback({
            isLoading: false,
            isFailed: false,
            data: response.data.data,
          });
      } else {
        callback &&
          callback({
            isLoading: false,
            isFailed: true,
          });
      }
    }
  } catch (error) {
    callback &&
      callback({
        isLoading: false,
        isFailed: true,
      });
  }
}

function* getBillboardVideoUrl({
  payload: { requestId, callback },
}: GetBillboardVideoUrl): any {
  try {
    const url = `v1/api/creative-studio/video-url/:${requestId}
    `;
    const response = yield call(httpService.get, BASE_URL, url, "");
    if (response.data.success) {
      callback &&
        callback({
          isLoading: false,
          isFailed: false,
          data: response.data.data,
        });
    } else {
      callback &&
        callback({
          isLoading: false,
          isFailed: true,
        });
    }
  } catch (error) {
    callback &&
      callback({
        isLoading: false,
        isFailed: true,
      });
  }
}

export function* creativeAnimaterWatcher() {
  yield takeLatest(GENERATE_BILLBOARD_LINK, generateBillboardLink);
  yield takeLatest(GET_BILLBOARD_VIDEO_URL, getBillboardVideoUrl);
}
