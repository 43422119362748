//@ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PyxisTable from "../../components/common/PyxisTable/PyxisTable";
import { ActionImpactState } from "../../store/types/actionImpact";
import { initialState } from "../../store/types/auth";
import useSortableData from "../../utils/useSortableData";
import "./index.scss";
import { CampaignState } from "../../store/types/campaign";
import { calcualteTimeOffset } from "utils/date.util";

import {
  getAllPlatfromRecommendation,
  setAllPlatformRecommendation,
  setAllPlatformspinner,
} from "../../store/actions/actionImpact";
import moment from "moment";

const AllActionImpactTable: React.FC = () => {
  const actionImpact = useSelector(
    (state: { actionImpact: ActionImpactState }) => state.actionImpact
  );
  const {
    allPlatformLinkClicks,
    allPlatformspinner,
    allPlatformRecommendation,
  } = actionImpact;
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedGroup } = adaccount;
  const [dateTime, _] = React.useState(allPlatformLinkClicks);
  const { items, requestSort } = useSortableData(
    allPlatformRecommendation || []
  );
  console.log("tables ", allPlatformRecommendation);
  const TableTitle = () => (
    <h4 className="p-0 pt-4 ml-4 campaigns_header fs-6 d-flex">
      Action Recommendations
    </h4>
  );

  const actionImpactTableHeader = [
    {
      name: "Recommendation Data",
      isSortable: true,
      sortKey: "recommendation_date",
      isEnabled: true,
    },
    {
      name: "Item to Change",
      isSortable: true,
      isEnabled: true,
      sortKey: "item_changed",
    },
    {
      name: "Recommended Action",
      isSortable: true,
      sortKey: "recommended_action",
      isEnabled: true,
    },
    // {
    //   name: "Action Basis",
    //   isSortable: true,
    //   isEnabled: true,
    //   sortKey: "action_basis",
    // },
    // {
    //   name: "Confidence Score",
    //   isSortable: true,
    //   isEnabled: true,
    //   sortKey: "confidence_score",
    // },
    {
      name: "Previous Budget",
      isSortable: true,
      isEnabled: false,
      sortKey: "previous_budget",
    },
    {
      name: "value Absolute",
      isSortable: true,
      isEnabled: false,
      sortKey: "value_absolute",
    },
    {
      name: "AI Group Name",
      isSortable: true,
      isEnabled: true,
      sortKey: "ai_group_name",
    },
    {
      name: "CPR",
      isSortable: true,
      isEnabled: true,
      sortKey: "cost_per_result",
    },
    {
      name: "Spend",
      isSortable: true,
      isEnabled: true,
      sortKey: "spends",
    },
    {
      name: "Results",
      isSortable: true,
      isEnabled: true,
      sortKey: "results",
    },
    {
      name: "Lookback Period",
      isSortable: true,
      isEnabled: false,
      sortKey: "lookback_period",
    },
  ];

  const [updatedTableHeader, setUpdatedTableHeader] = React.useState(
    actionImpactTableHeader
  );
  const [dateRange, setDateRange] = React.useState({
    start: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (dateRange.end && selectedGroup?.id) {
      dispatch(setAllPlatformspinner(true));
      dispatch(setAllPlatformRecommendation([]));
      dispatch(
        getAllPlatfromRecommendation(
          {
            groupId: selectedGroup.id,
            dates: dateRange,
            download: false,
          },
          (response: any, error: boolean) => {
            if (!error) {
              dispatch(setAllPlatformRecommendation(response.data));
            } else {
              console.log(error);
            }
            dispatch(setAllPlatformspinner(false));
          }
        )
      );
    }
  }, [dateRange, selectedGroup]);

  return (
    <div className="action_recommendations" style={{ flexGrow: 1 }}>
      <PyxisTable
        tableTitle={TableTitle}
        type={"actionImpact"}
        responsiveType={"md"}
        maxHeight={"300px"}
        showTableTopBar={false}
        showFilterAndSearch={false}
        showCustomiseColumn={false}
        linkClicks={dateRange.end ? dateRange : allPlatformLinkClicks}
        setDateRange={setDateRange}
        tableHeaderClass="all-action-recommendation-header"
        tableContainerClass="all-action-recommendation-table"
        tableClass={`mt-4 ml-4 overflow-scroll pyxis-table`}
        tableHeader={updatedTableHeader}
        data={
          (allPlatformRecommendation && items && items.length > 0 && items) ||
          []
        }
        setUpdatedTableHeader={setUpdatedTableHeader}
        requestSort={requestSort}
        loading={allPlatformspinner}
      />
    </div>
  );
};

export default AllActionImpactTable;
