import { Box, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import tipImage from "../../../assets/svg/attribution-metric.svg";

function WarningPopover(source: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div className="ml-3 flex-center">
      <img
        src={tipImage}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        width={18}
        className="attribute-metric-icon"
      />

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
        disableRestoreFocus
      >
        <Box pl="1.1em" display="flex">
          <Box
            sx={{
              background: "#FFF",
              padding: "1em",
              maxWidth: "17em",
              border: "1px solid #F0F0F0",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.05)",
              borderRadius: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "400",
                marginTop: "6px",
              }}
            >
              Source of this metric is
              <br />
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                “{source.source?.name}”
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              position: "relative",
              "&::before": {
                backgroundColor: "#FFF",
                content: '""',
                display: "block",
                position: "absolute",
                width: "2em",
                height: "2em",
                transform: "rotate(45deg)",
                zIndex: -1,
                left: "-185px",
                top: "20px",
              },
            }}
          />
        </Box>
      </Popover>
    </div>
  );
}

export default WarningPopover;
