import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SelectText from "Pages/SmbDashboard/common/SelectField";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import {
  resetAllData,
  setSelectedAiGroupAudienceManager,
} from "../../../store/actions/AudienceManager/index";
import { AudienceManagerState } from "../../../store/types/AudienceManager/index";
import { ReactComponent as Configure } from "../../../assets/svg/configure.svg";
import { isAdminRole } from "utils/commonFunctions";

interface Props {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CommonLoaderComponent = () => {
  return (
    <Box mr={1} mt={0.5}>
      <CircularProgress
        size={20}
        color="primary"
        sx={{ color: "#0869FB !important" }}
      />
    </Box>
  );
};

const Header: React.FC<Props> = ({
  modalOpen,
  setModalOpen,
}): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const adaccountState = useSelector((state: any) => state.adaccount);
  const brandId = adaccountState.selectedBrand.id;
  const audienceManagerState = useSelector(
    (state: { audienceManager: AudienceManagerState }) => state.audienceManager
  );
  const { userInfo } = useSelector((state: any) => state.userInfoReducer);
  const { brandRoles } = adaccountState;
  // const isAdmin = userInfo?.roles?.includes("super-admin");
  useEffect(() => {
    if (
      adaccountState?.selectedGroup &&
      Object.keys(adaccountState.selectedGroup).length > 0
    ) {
      dispatch(
        setSelectedAiGroupAudienceManager(adaccountState?.selectedGroup)
      );
    }
  }, [adaccountState?.selectedGroup]);

  return (
    <Grid container px={2}>
      <Grid item xs={8.25} className="font-lato font-22 font-700 ">
        Audience Manager
      </Grid>
      <Grid
        item
        xs={
          audienceManagerState.selectedPlatform === "facebook"
            ? 2
            : // : isAdmin
            isAdminRole(brandRoles)
            ? 3.35
            : 3.75
        }
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "100%",
        }}
      >
        <Grid item className="font-inter font-14 font-600 pr-3">
          AI Group
        </Grid>
        <Box
          sx={{
            // width: "75%",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "100%",
          }}
        >
          <SelectText
            selectedItem={audienceManagerState?.selectedAiGroup}
            setSelectedItem={(selectedAiGroup: any) => {
              dispatch(resetAllData());
              dispatch(setSelectedAiGroupAudienceManager(selectedAiGroup));
            }}
            valueKey="id"
            displayKey="name"
            sx={{
              width: "8.25rem",
              height: "2rem !important",
              borderRadius: 2,
              " *": {
                color: "#969696 !important",
                fontWeight: "600 !important",
              },

              "&:hover": {
                "&& fieldset": {
                  border: "1px solid #000",
                  opacity: 0.2,
                },
              },
            }}
            size="small"
            items={
              adaccountState && adaccountState.groups
                ? adaccountState.groups
                : []
            }
            menuProps={{
              anchorOrigin: {
                horizontal: "right",
                vertical: "bottom",
              },
              transformOrigin: {
                horizontal: "right",
                vertical: "bottom",
              },
              sx: {
                "& .MuiPaper-root": {
                  top: "2.5rem !important",
                  height: "20rem",
                  width: "2.5rem",
                },
              },
            }}
            IconComponent={
              audienceManagerState?.selectedAiGroup
                ? KeyboardArrowDownIcon
                : CommonLoaderComponent
            }
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={
          audienceManagerState.selectedPlatform === "facebook"
            ? 1.75
            : // : isAdmin
            isAdminRole(brandRoles)
            ? 0.4
            : 0
        }
        textAlign={"end"}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {audienceManagerState.selectedPlatform === "facebook" ? (
          <Button
            className="font-12 font-600 h-100"
            variant="contained"
            sx={{
              borderRadius: 2,
              textTransform: "none",
              background: "#0869FB",
              height: "2rem !important",
            }}
            onClick={() => {
              history.push(
                `/targeting-ai-playground-beta?brand_id=${brandId}`,
                {
                  aiGroup: audienceManagerState?.selectedAiGroup,
                  platform: "fb",
                }
              );
            }}
            disabled={!!!audienceManagerState?.selectedAiGroup}
          >
            Create New Audience
          </Button>
        ) : (
          <>
            {/* {isAdmin && ( */}
            {isAdminRole(brandRoles) && (
              <IconButton
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 0,
                }}
                onClick={() => setModalOpen(!modalOpen)}
              >
                <Configure />
              </IconButton>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Header;
