export const GET_AGENCIES_SMB = "GET_AGENCIES_SMB";
export const SET_AGENCIES_SMB = "SET_AGENCIES_SMB";
export const SET_SELECTED_AGENCY_SMB = "SET_SELECTED_AGENCY_SMB";

export type Agency = any;

export type AgencyId = string;

export type GetAgenciesSmb = {
  type: typeof GET_AGENCIES_SMB;
  payload: {
    user: any;
    agencyId: string;
  };
};

export type SetAgenciesSmb = {
  type: typeof SET_AGENCIES_SMB;
  payload: Agency[];
};

export type SetSelectedAgencySmb = {
  type: typeof SET_SELECTED_AGENCY_SMB;
  payload: AgencyId;
};

export type AgenciesState = {
  data: Agency[];
  selected: Agency | null;
  error: null;
};
