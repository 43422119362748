import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/close.svg";
import { ReactComponent as YoutubeUpload } from "../../../../assets/svg/YoutubeUpload.svg";

interface FileDropModalProps {
  open: boolean;
  onClose: () => void;
  onFileUpload: (files: File[]) => void;
}

function FileDropModal({ open, onClose, onFileUpload }: FileDropModalProps) {
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);

    const files = Array.from(e.dataTransfer.files);
    onFileUpload(files);
  };

  const handleSelectFiles = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 3,
          borderRadius: "1rem",
          width: "34rem",
        },
      }}
      sx={{
        "& *": {
          fontFamily: "Inter",
        },
      }}
    >
      <Box>
        <Grid container>
          <Grid item xs={11.25} mb={2}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontSize: "1.5rem",
                fontWeight: 500,
                lineHeight: "2rem",
              }}
            >
              Upload File (.xlsx)
            </Typography>
          </Grid>
          <Grid item xs={0.75}>
            <IconButton onClick={onClose} disableRipple disableTouchRipple>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent
          className={`${isDragOver ? "dragOver" : ""}`}
          sx={{
            border: "1px dashed #87898E",
            borderRadius: "0.75rem",
            textAlign: "center",
            padding: "20px",
            "&.dragOver": {
              border: "2px dashed #1976D2",
            },
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <YoutubeUpload />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="60%"
              mt={2}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                sx={{
                  fontSize: "0.75rem",
                  fontWeight: 400,
                  color: "#87898E",
                }}
              >
                Drop your file here to upload or select from storage
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={(e) => onFileUpload(Array.from(e.target.files || []))}
            ref={fileInputRef}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelectFiles}
            sx={{
              background: "#0869FB",
              textTransform: "none",
              borderRadius: "0.5rem",
              py: 1.5,
              px: 2.5,
            }}
          >
            Browse
          </Button>
        </div>
      </Box>
    </Dialog>
  );
}
export default FileDropModal;
