export const SET_LINK_CLICKS = "SET_LINK_CLICKS";
export const SET_ALL_PLATFORM_LINK_CLICKS = "SET_ALL_PLATFORM_LINK_CLICKS";
export const SET_ALL_PLATFORM_SPINNER = "SET_ALL_PLATFORM_SPINNER";
export const SET_ALL_PLATFORM_RECOMMENDATION =
  "SET_ALL_PLATFORM_RECOMMENDATION";
export const GET_ALL_PLATFROM_RECOMMENDATION =
  "GET_ALL_PLATFROM_RECOMMENDATION";
export const SET_TARGETING_LINK_CLICKS = "SET_TARGETING_LINK_CLICKS";
export const SET_ALL_PLATFORM_GRAPH_SPINNER = "SET_ALL_PLATFORM_GRAPH_SPINNER";
export const SET_ALL_PLATFORM_PIE_SPINNER = "SET_ALL_PLATFORM_PIE_SPINNER";
export const SET_ALL_PLATFORM_CHART_DATA = "SET_ALL_PLATFORM_CHART_DATA";
export const SET_ALL_PLATFORM_CHART_OPTION = "SET_ALL_PLATFORM_CHART_OPTION";
export const SET_ALL_PLATFORM_CHART_CORDS_DATA =
  "SET_ALL_PLATFORM_CHART_CORDS_DATA";
export const SET_ALL_PLATFORM_ADACCOUNT_DETAILS =
  "SET_ALL_PLATFORM_ADACCOUNT_DETAILS";
export const SET_CURRENCY_IDENTIFIER = "SET_CURRENCY_IDENTIFIER";
export const GET_CURRENCY_DETAILS = "GET_CURRENCY_DETAILS";
export const GET_PAGINATED_RECOMMENDATIONS = "GET_PAGINATED_RECOMMENDATIONS";
export const GET_EXPORT_RECOMMENDATIONS = "GET_EXPORT_RECOMMENDATIONS";
export const SET_STATUS= "SET_STATUS";
export const GET_SUPER_ADMIN_EMAILS= "GET_SUPER_ADMIN_EMAILS";

export type ActionImpactState = {
  linkClicks: LinkClicks;
  allPlatformLinkClicks: LinkClicks;
  targetingLinkClicks: LinkClicks;
  allPlatformspinner: boolean;
  allPlatformRecommendation: any;
  allPlatformGraphSpinner: boolean;
  pieSpinner: boolean;
  allPlatformChartData: ChartData;
  allPlatformChartOption: any[];
  allPlatformChartCordsData: ChartData;
  allPlatformAdaccountDetails: any[];
  currencyIdentifier: any[];
};

export type ChartData = {
  labels: string[];
  datasets: any;
};

export type LinkClicks = {
  start: any;
  end: any;
};

export type SetLinkClicks = {
  type: typeof SET_LINK_CLICKS;
  payload: LinkClicks;
};

export type SetAllPlatformLinkClicks = {
  type: typeof SET_ALL_PLATFORM_LINK_CLICKS;
  payload: LinkClicks;
};

export type SetTargetingLinkClicks = {
  type: typeof SET_TARGETING_LINK_CLICKS;
  payload: LinkClicks;
};

export type SetAllPlatformRecommendation = {
  type: typeof SET_ALL_PLATFORM_RECOMMENDATION;
  payload: any;
};

export type SetAllPlatformspinner = {
  type: typeof SET_ALL_PLATFORM_SPINNER;
  payload: boolean;
};

export type SetAllPlatformGraphSpinner = {
  type: typeof SET_ALL_PLATFORM_GRAPH_SPINNER;
  payload: boolean;
};

export type SetAllPlatformPieSpinner = {
  type: typeof SET_ALL_PLATFORM_PIE_SPINNER;
  payload: boolean;
};

export type SetAllPlatformChartData = {
  type: typeof SET_ALL_PLATFORM_CHART_DATA;
  payload: ChartData;
};

export type SetAllPlatformChartOption = {
  type: typeof SET_ALL_PLATFORM_CHART_OPTION;
  payload: any[];
};

export type SetAllPlatformChartCordsData = {
  type: typeof SET_ALL_PLATFORM_CHART_CORDS_DATA;
  payload: ChartData;
};

export type SetAllPlatformAdaccountDetails = {
  type: typeof SET_ALL_PLATFORM_ADACCOUNT_DETAILS;
  payload: any[];
};

export type SetCurrencyIdentifier = {
  type: typeof SET_CURRENCY_IDENTIFIER;
  payload: any[];
};

export type GetPaginatedRecommendations = {
  type: typeof GET_PAGINATED_RECOMMENDATIONS;
  payload: {
    elementId: string;
    dates: LinkClicks;
    user: any;
    activeHeader: string;
    pageNumber: any;
    sort_by: any;
    sort_order: any;
    pageSize?: number;
    isSuperAdmin?:boolean;
  };
  callback?: Function;
};

export type GetAllPlatfromRecommendation = {
  type: typeof GET_ALL_PLATFROM_RECOMMENDATION;
  payload: {
    groupId: string;
    dates: LinkClicks;
    download: boolean;
  };
  callback?: Function;
};

export type GetCurrencyDetails = {
  type: typeof GET_CURRENCY_DETAILS;
  payload: {
    currencyName: string;
  };
  callback?: Function;
};

export type SetStatus = {
  type: typeof SET_STATUS;
  payload: any;
  callback?: Function;
};
