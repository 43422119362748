import { Box, Radio, RadioProps } from "@mui/material";
import {
  RadioGroup as MUIRadioGroup,
  RadioGroupProps as MUIRadioGroupProps,
} from "@mui/material";
import InputLabel from "components/shared/InputLabel/InputLabel";
import { StyledMediaRadioIcon, StyledMediaRadioCheckedIcon } from "./styled";

interface MediaAdminRadioButtonProps {
  onChange: (...event: any[]) => void;
  value: string;
}

export default function MediaAdminRadioButton({
  onChange,
  value,
}: MediaAdminRadioButtonProps) {
  function MediaRadio(props: RadioProps) {
    return (
      <Radio
        sx={{
          marginTop: "-8.5px",
          "&:hover": {
            bgcolor: "transparent",
          },
        }}
        size="medium"
        disableRipple
        color="default"
        checkedIcon={<StyledMediaRadioCheckedIcon />}
        icon={<StyledMediaRadioIcon />}
        {...props}
      />
    );
  }
  const globalLabelStyle = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "17px",
    color: "#444444",
  };
  return (
    <MUIRadioGroup onChange={onChange} value={value}>
      <Box display="flex" ml="-10px">
        <Box display="flex" alignItems="start" justifyContent="center">
          <MediaRadio value="Partial Sentence" />
          <InputLabel label="Partial Sentence" sx={globalLabelStyle} />
        </Box>
        <Box
          display="flex"
          alignItems="start"
          justifyContent="center"
          pl="10px"
        >
          <MediaRadio value="Full Sentence" />
          <InputLabel label="Full Sentence" sx={globalLabelStyle} />
        </Box>
      </Box>
    </MUIRadioGroup>
  );
}
