import React, { useState } from "react";
import Box from "@mui/material/Box";
import { ReactComponent as ErrorCloseIcon } from "../../../../assets/svg/warning-close.svg";

function ErrorMeesage() {
  const [show, setShow] = useState(true);
  return (
    <Box className="w-100 error-message-box">
      {show && (
        <div className="d-flex justify-content-between error-message align-items-center">
          <div className="title">
            Your Business Unit details seem to be missing! Add your details here
            to generate Ad Copy recommendations.
          </div>
          <ErrorCloseIcon
            className="error-close-btn"
            onClick={() => setShow(false)}
          />
        </div>
      )}
    </Box>
  );
}

export default ErrorMeesage;
