import React from "react";
import { Box, Stack } from "@mui/material";

import { ReactComponent as AudienceScrollRight } from "../assets/audience-scroll/scroll-right.svg";
import { ReactComponent as CreativeScrollRight } from "../assets/creative-scroll/scroll-right.svg";

function ImagesScrollRight({ imagesType }: { imagesType: string }) {
  return (
    <Stack spacing={2} className="scroll-item2">
      {imagesType === "creative" ? (
        <>
          <CreativeScrollRight /> <CreativeScrollRight />
        </>
      ) : (
        <>
          <AudienceScrollRight /> <AudienceScrollRight />
        </>
      )}
    </Stack>
  );
}

export default ImagesScrollRight;
