import styled from "styled-components";

export const StyledEditorHeaderContainer = styled.div`
  background: #ffffff;
  border-radius: 0.3125em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.1em;
  border-bottom: 0.0625em solid #e5e5e5;
`;

export const StyledHeading = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25em;
  line-height: 1.2em;
  color: #000000;
  max-lines: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 70%;
`;

export const StyledHeadingContainer = styled.div`
  display: flex;
  width: 70%;
`;

export const StyledAnchor = styled.a`
  margin-left: 5px;
  font-size: 15px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
`;
export const StyledViewAdSetDetail = styled.a`
  margin-left: 0.3125em;
  font-size: 1em;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  line-height: 1.5em;
  color: #0869fb !important;
  border-bottom: 0.0625em solid #0869fb;
  cursor: pointer;
`;
