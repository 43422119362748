import React, { useEffect, useState } from "react";
import { ReactComponent as Search } from "../Assets/search.svg";
import loader from "../Assets/loader.gif";
import {
  Box,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
  RadioGroup,
  Radio,
} from "@mui/material";
import Container from "./Container";
import { getEligibilityOfAccounts } from "store/actions/thirdPartyChannels";
import { useDispatch, useSelector } from "react-redux";

const SelectAccount: React.FC<{
  onClickNext?: any;
  platform: any;
  code: string;
  tokenUID: string;
  brandName:any
}> = ({ onClickNext, platform, code, tokenUID,brandName }) => {
  const brandAccounts = useSelector(
    (state: any) => state.thirdPartyChannels?.eligibilityOfAccount?.accounts
  );
  const [accounts, setAccounts] = useState(brandAccounts);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [searchVal, setSearchVal] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    setAccounts(brandAccounts);
  }, [brandAccounts]);

  useEffect(() => {
    if (platform.brand_id && platform.platform && tokenUID) {
      dispatch(
        getEligibilityOfAccounts({
          tokenUID,
          brandId: platform.brand_id,
          platform: platform.platform,
        })
      );
    }
  }, [dispatch, platform.brand_id, platform.platform, tokenUID]);

  useEffect(() => {
    if (searchVal && brandAccounts) {
      setAccounts([
        ...brandAccounts.filter((item: any) =>
          item.accountName.toLowerCase().includes(searchVal.toLowerCase())
        ),
      ]);
    } else setAccounts(brandAccounts);
  }, [searchVal]);
  return (
    <Container
      saveButtonText="Next"
      onClickNext={() => onClickNext(selectedAccount)}
      disable={selectedAccount}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              height: "115px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "28px",
                  fontWeight: "500",
                  margin: "auto",
                  fontFamily: "Inter",
                }}
              >
                {brandName}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginTop: "8px",
                  fontFamily: "Inter",
                  color: "#6D6D6D",
                }}
              >
                Select one account to continue.
              </Typography>
            </Box>
          </Box>

          <Box>
            <Box sx={{ height: "100px", borderBottom: "1px solid #DDDDDD" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  color: "#666666",
                }}
              >
                Choose Google Analytics Account
              </Typography>
              <TextField
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
                disabled={!accounts}
                sx={{
                  "&:disabled": {
                    cursor: 'not-allowed'
                },
                  width: "100%",
                  marginTop: "8px",
                  "& .MuiOutlinedInput-input": {
                    padding: 0,
                    height: "36px",
                    borderRadius: "10px",
                  },
                  "& .MuiOutlinedInput-input::placeholder": {
                    color: "#5F6771",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    letterSpacing: "0px",
                    textAlign: "left",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#DDDDDD !important",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                placeholder={"Search"}
              />
            </Box>
            <Box
              sx={{
                maxHeight: "332px",
                display: "block",
                overflowY: "auto",
                overflowX: "hidden",
                padding: "20px 10px",
                height:'332px'
              }}
            >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedAccount?.accadAccountIdId}
                name="radio-buttons-group"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.preventDefault();
                  setSelectedAccount(
                    accounts.find(
                      (element: any) =>
                        element.accountId ===
                        (event.target as HTMLInputElement).value
                    )
                  );
                }}
              >
                {!accounts ? (
                  <Box
                    sx={{
                      height: "300px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ height: "130px", width: "130px" }}
                      src={loader}
                      alt="loader"
                    />
                  </Box>
                ) : (
                  <>
                    {accounts.length===0 ? <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%",height:"65px"}}><Typography sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginTop: "8px",
                  fontFamily: "Inter",
                  color: "#6D6D6D",
                }}>No Acccounts Found</Typography></Box> : accounts.map((item: any) => (
                      <FormControlLabel
                        sx={{ width: "100%" }}
                        label={
                          <Box
                            sx={{
                              height: "65px",
                              width: "400px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  fontFamily: "Inter",
                                }}
                              >
                                {item.accountName}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Inter",
                                }}
                              >
                                {item.accountId}
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "10px",
                                fontWeight: "400",
                                fontFamily: "Inter",
                                color: "#a19f9f",
                              }}
                            >
                              {item.alreadyConnectedtoAnotherBrand &&
                                "Already Connected To Another Brand"}
                              {item.alreadyConnectedBySelf &&
                                "Already Connected By You"}
                            </Typography>
                          </Box>
                        }
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                height: "20px",
                                width: "20px",
                                fill: `${
                                  selectedAccount?.accountId === item.accountId
                                    ? "#0869FB"
                                    : "#CCCCCC"
                                }`,
                              },
                            }}
                          />
                        }
                        value={item.accountId}
                      />
                    ))}
                  </>
                )}
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SelectAccount;
