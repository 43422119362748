import { Box, styled } from "@mui/material";

export const StyledHomeDivider = styled(Box)`
  height: 2.9em;
  width: 0px;
  border: 1px solid #eeeeee;
  margin: 0px 0.75em;
`;

export const StyledDateRangeBox = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledDateRangeBoxContainer = styled(Box)`
  position: relative;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 0.375em 0.75em;
  .daterangeSelector {
    box-shadow: 0em 0.0625em 0.75em rgb(0 0 0 / 30%);
    border-radius: 0.625em;
    z-index: 9999999999999999;
    position: absolute;
    padding: 20px;
    top: 3.2em;
    right: 0px;
    background-color: white;
    font-size: 14px;
  }
`;
