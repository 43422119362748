export const GET_TAI_KWS = "GET_TAI_KWS";
export const GET_TAI_CLUSTER = "GET_TAI_CLUSTER";
export const STORE_TAI_KWS = "STORE_TAI_KWS";
export const STORE_TAI_CLUSTER = "STORE_TAI_CLUSTER";
export const GET_BRAND_AIGROUP_META = "GET_BRAND_AIGROUP_META";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_REGIONS = "GET_REGIONS";
export const GET_AUDIENCES = "GET_AUDIENCES";
export const SET_CAMPAIGN_AUDIENCES = "SET_CAMPAIGN_AUDIENCES";
export const SET_CAMPAIGN_AUDIENCE_DETAILS = "SET_CAMPAIGN_AUDIENCE_DETAILS";
export const SAVE_CAMPAIGN_AUDIENCE_DETAILS = "SAVE_CAMPAIGN_AUDIENCE_DETAILS";
export const SAVE_ACCOUNT_DETAILS = "SAVE_AUDIENCE_DETAILS";
export const SET_AI_INITIATION_DATA = "SET_AI_INITIATION_DATA";
export const SET_CALIBRATION_DATA = "SET_CALIBRATION_DATA";
export const SET_LOADED_KEYWORDS = "SET_LOADED_KEYWORDS";
export const SET_KEYWORD_TUNING_DATA = "SET_KEYWORD_TUNING_DATA";
export const SET_KEYWORD_TUNING_JSON = "SET_KEYWORD_TUNING_JSON";
export const STORE_TAI_COHORT = "STORE_TAI_COHORT";
export const STORE_KEYWORDS = "STORE_KEYWORDS";
export const STORE_RECOMMENDED_KEYWORDS = "STORE_RECOMMENDED_KEYWORDS";
export const DELETE_RECOMMENDED_KEYWORDS = "DELETE_RECOMMENDED_KEYWORDS";
export const SET_CALIBRATION_FORM_DATA = "SET_CALIBRATION_FORM_DATA";
export const RESET_CALIBRATION_FORM_DATA = "RESET_CALIBRATION_FORM_DATA";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const GET_AD_ACCOUNTS = "GET_AD_ACCOUNTS";
export const SET_SELECTED_COHORTS = "SET_SELECTED_COHORTS";
export const SET_POLLING = "SET_POLLING";
export const SET_DROPDOWN_ID = "SET_DROPDOWN_ID";
export const SET_AI_INITIATION_VALUE = "SET_AI_INITIATION_VALUE";
export const RESET_AI_INITIATION = "RESET_AI_INITIATION";
export const GET_CAMPAIGN_AUDIENCES = "GET_CAMPAIGN_AUDIENCES";
export const SET_TYPE_OF_COHORT = "SET_TYPE_OF_COHORT";

export const SET_NODE_COLLAPSE = "SET_NODE_COLLAPSE";
export const RESET_NODES_LOADER_STATE = "RESET_NODES_LOADER_STATE";
export const SET_ALL_CALIBRATION_FORM_DATA = "SET_ALL_CALIBRATION_FORM_DATA";
export const GET_CAMPAIGN_DETAILS_TAI = "GET_CAMPAIGN_DETAILS_TAI";
export const SET_CAMPAIGN_DETAILS_TAI = "SET_CAMPAIGN_DETAILS_TAI";

export const STORE_FAVOURITE_COHORT = "STORE_FAVOURITE_COHORT";
export const DELETE_FAVOURITE_COHORT = "DELETE_FAVOURITE_COHORT";
export const GET_DRAFTED_RECOMMENDED_COHORTS =
  "GET_DRAFTED_RECOMMENDED_COHORTS";

export const UPDATE_RECOMMENDED_EXCLUSIONS_STATE =
  "UPDATE_RECOMMENDED_EXCLUSIONS_STATE";
export const SET_UPDATED_RECOMMENDED_EXCLUSIONS_STATE =
  "SET_UPDATED_RECOMMENDED_EXCLUSIONS_STATE";
export const SET_ADDITIONAL_INPUTS = "SET_ADDITIONAL_INPUTS";
export const SET_NEGATE_TARGETING = "SET_NEGATE_TARGETING";
export const SET_ENABLE_CUSTOM_KEYWORDS = "SET_ENABLE_CUSTOM_KEYWORDS";
export const MERGE_COHORTS = "MERGE_COHORTS";
export const REFRESH_COHORTS = "REFRESH_COHORTS";
export const SUBMIT_COHORTS = "SUBMIT_COHORTS";
export const GET_CUSTOM_AUDIENCES = "GET_CUSTOM_AUDIENCES";

export type GetCampaignDetailsTAI = {
  type: typeof GET_CAMPAIGN_DETAILS_TAI;
  payload: {
    adAccountId: "";
  };
};

export type SetCampaignDetailsTAI = {
  type: typeof SET_CAMPAIGN_DETAILS_TAI;
  payload: {
    campaigns: any[];
  };
};

export type GetBrandAIGroupsMeta = {
  type: typeof GET_BRAND_AIGROUP_META;
  payload: {
    brandId: string;
    platformId: string;
  };
  callback?: Function;
};

export type GetCountries = {
  type: typeof GET_COUNTRIES;
  callback?: Function;
};

export type GetRegions = {
  type: typeof GET_REGIONS;
  payload:string[];
  callback?: Function;
};

export type GetAudiences = {
  type: typeof GET_AUDIENCES;
  payload: {
    aiGroupId: string;
  };
  callback?: Function;
};

export type GetCustomAudiences = {
  type: typeof GET_CUSTOM_AUDIENCES;
  payload:{
    "ad_account_id": string, 
    "brand_name": string,
    "brand_id": string, 
    "brand_website": string
  };
  callback?:Function;

}


export type GetCampaignAudience = {
  type: typeof GET_CAMPAIGN_AUDIENCES;
  payload: {
    custom_audience_details: any;
    website: string;
    brand: SVGStringList;
  };
  user: any;
  aiGroupId: string;
  callback?: Function;
};

export type SetCampaignAudienceDetails = {
  type: string;
  payload: any;
};

export type SaveCampaignAudienceDetail = {
  type: string;
  payload: any;
};

export type SaveAccountDetails = {
  type: string;
  payload: any;
};

export type GetAdAccounts = {
  type: typeof GET_AD_ACCOUNTS;
  payload: {
    aiGroupId: string;
    platformId: string;
  };
  callback?: Function;
};

export type SetAiInitiationData = {
  type: typeof SET_AI_INITIATION_DATA;
  payload: any;
};

export type SetCampaignAudience = {
  type: typeof SET_CAMPAIGN_AUDIENCES;
  payload: any;
};

export type Downloading = {
  [key: number]: boolean;
};

export type StoreTAIKWS = {
  type: typeof STORE_TAI_KWS;
  payload: {
    kwsData: {
      name: string;
      website: string;
      setup: any;
      country: string;
      ad_account_id?:string;
    };
    user: any;
  };
  callback?: Function;
};

export type GetTAIKWS = {
  type: typeof GET_TAI_KWS;
  payload: {
    request_ids: string;
  };
  callback?: Function;
};

export type SetCalibrationData = {
  type: typeof SET_CALIBRATION_DATA | typeof SET_CALIBRATION_FORM_DATA;
  payload: any;
};

export type SetLoadedKeywords = {
  type: typeof SET_LOADED_KEYWORDS
  payload: any;
};

export type ResetCalibrationData = {
  type: typeof RESET_CALIBRATION_FORM_DATA;
};

export type GetTAICluster = {
  type: typeof GET_TAI_CLUSTER;
  payload: {
    request_ids: string;
  };
  callback?: Function;
};

export type SaveCampaignAudience = {
  type: typeof GET_TAI_CLUSTER;
  payload: any;
  aiGroupId: any;
  callback?: Function;
  user: any;
};

export type StoreTAICluster = {
  type: typeof STORE_TAI_CLUSTER;
  payload: {
    clusterData: {
      keywords: any;
      geo_locations: any;
      brand: string;
      custom_audience_details: any;
    };
    user: any;
    aiGroupId: any;
    platformId: any;
  };
  callback?: Function;
};

export type SetKeywordTuningData = {
  type: typeof SET_KEYWORD_TUNING_DATA;
  payload: any;
};

export type SetKeywordTuningJson = {
  type: typeof SET_KEYWORD_TUNING_JSON;
  payload: any;
};

interface FacebookJson {
  id: string;
  name: string;
}
export interface Cohort {
  data: {
    facebook_json: FacebookJson[];
  };
  budget: number;
  fb_ad_account_id: string;
  reference_adset_id: string;
  reference_campaign_id: string;
  adAccountName: string;
  adsetName: string;
  adset_campaign_id: string;
  cohort_name: string;
  cohort_id: string;
}

export type StoreTAICohort = {
  type: typeof STORE_TAI_COHORT;
  payload: {
    action_list: Cohort[];
    email_data: any;
    delete_cohorts: string[];
    user: any;
  };
  callback?: Function;
};

export type SetCurrentPage = {
  type: typeof SET_CURRENT_PAGE;
  payload: any;
};

export type SetSelectedCohorts = {
  type: typeof SET_SELECTED_COHORTS;
  payload: any;
};

export type SetPolling = {
  type: typeof SET_POLLING;
  payload: {
    name: string;
    requestId: string;
  };
};

export type SetDropdownId = {
  type: typeof SET_DROPDOWN_ID;
  payload: number;
};

export type SetTypeOfCohort = {
  type: typeof SET_TYPE_OF_COHORT;
  payload: string;
};

export type SetAiInitiationValue = {
  type: typeof SET_AI_INITIATION_VALUE;
  payload: { key: string; value: any };
};

export type ResetAiInitiation = {
  type: typeof RESET_AI_INITIATION;
};

export type UpdatedRecommendedExclusions = {
  type: typeof UPDATE_RECOMMENDED_EXCLUSIONS_STATE;
  payload: {
    adAccountId?: any;
    data: any;
    user?: any;
  };
  callback?: Function;
};

export type SetUpdatedRecommendedExclusions = {
  type: typeof SET_UPDATED_RECOMMENDED_EXCLUSIONS_STATE;
  payload: any;
};

export type SetAllCalibrationFormData = {
  type: typeof SET_ALL_CALIBRATION_FORM_DATA;
  payload: any;
};

export type SetNodeCollapse = {
  type: typeof SET_NODE_COLLAPSE;
  payload: {
    type: string;
    collapse: boolean;
  };
};
export type StoreKeywords = {
  type: typeof STORE_KEYWORDS;
  payload: any;
  callback?: Function;
};

export type StoreRecommendedKeywords = {
  type: typeof STORE_RECOMMENDED_KEYWORDS;
  payload: any;
  callback?: Function;
};

export type DeleteRecommendedKeywords = {
  type: typeof DELETE_RECOMMENDED_KEYWORDS;
  payload: any;
  callback?: Function;
};

export type StoreFavouriteCohort = {
  type: typeof STORE_FAVOURITE_COHORT;
  payload: {
    favouriteCohort: [{}];
    user: any;
    aiGroupId: any;
  };
  callback?: Function;
};

export type DeleteFavouriteCohort = {
  type: typeof DELETE_FAVOURITE_COHORT;
  payload: {
    deleteFavouriteCohortIds: any[];
    user?: any;
    aiGroupId: any;
  };
  callback?: Function;
};

export type GetDraftedAndRecommendedCohorts = {
  type: typeof GET_DRAFTED_RECOMMENDED_COHORTS;
  payload: {
    type: string;
    aiGroupId: any;
  };
  callback?: Function;
};

export type SetAdditionalInputs = {
  type: typeof SET_ADDITIONAL_INPUTS;
  payload: {
    locationFilter: boolean;
    specialAdCategories: string[];
  };
};

export type SetNegateTargeting = {
  type: typeof SET_NEGATE_TARGETING;
  payload: {
    negateTargeting: boolean;
  };
};

export type SetEnableCustomKeywords = {
  type: typeof SET_ENABLE_CUSTOM_KEYWORDS;
  payload: {
    enableCustomKeywords: boolean;
  };
};

export type RefreshCohorts = {
  type: typeof REFRESH_COHORTS;
  payload: {
    data: {
      cohorts_json: string;
      updated_cohorts: any[];
    };
    user: any;
  };
  callback?: Function;
};

export type SubmitCohorts = {
  type: typeof SUBMIT_COHORTS;
  payload: {
    data: {
      cohorts_json: string;
      updated_cohorts: any[];
    };
    user: any;
  };
  callback?: Function;
};
