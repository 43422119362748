import HttpService from "../../../HttpService/HttpService";
import {
  GetAllPlatformsSmb,
  GET_ALL_PLATFORMS_SMB,
  GetAgencyDetialByBrand,
  GET_AGENCY_DETAIL_BY_BRAND,
} from "../../types/SmbDashboard/common";
import { call, put, takeLatest } from "redux-saga/effects";
import { CROSS_PLATFORM_URL } from "../../../utils/path";
import { crossPlatform } from "MappedAPI/MappedAPI";
import { setAllPlatforms } from "store/actions/SmbDashboard/common";
import { smbDashboardApi } from "MappedAPI/MappedAPI";

function* getAllPlatforms(action: GetAllPlatformsSmb): any {
  try {
    const urlParams = `${crossPlatform}/platform`;
    const response: any = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams
    );
    const platformsPayload = response.data.data.reduce(
      (prev: any, next: any) => {
        prev.platforms[next.id] = next;
        prev.parentPlatforms[next.parentPlatformId] = next.type.split(" ")[0];
        return prev;
      },
      {
        parentPlatforms: {},
        platforms: {},
      }
    );
    yield put(setAllPlatforms(platformsPayload));
    if (response.data.error) {
      console.log("LL: response.data.error", response.data.error);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
  }
}

function* getAgencyDetialByBrand(action: GetAgencyDetialByBrand): any {
  let {
    payload: { brandId },
    callback,
  } = action;
  try {
    const urlParams = `${smbDashboardApi}/brands/${brandId}/agency-detail`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    callback && callback(error.response, true);
  }
}
export function* PlatformsWatcher() {
  yield takeLatest(GET_ALL_PLATFORMS_SMB, getAllPlatforms);
  yield takeLatest(GET_AGENCY_DETAIL_BY_BRAND, getAgencyDetialByBrand);
}
