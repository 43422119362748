import { useContext, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import { ReactComponent as ForwardArrow } from "../assests/forwardArrow.svg";

import RangeSlider from "./RangeSlider";
import { KeywordContext } from "../KeywordContextProvider";
import { METRIC_NAMES } from "../../../constants/KeywordExplorer";
import { ReactComponent as Filter } from "../assests/filter.svg";
import { amountValue } from "../../../../../../utils/commonFunctions";

const AdvancedFilter = ({
  min,
  max,
  name,
  onFilterChange,
  selected,
  selectedKeywordsBucket,
  isAvgCpc = false,
}: any) => (
  <Grid container className="w-100">
    <Grid item xs={6}>
      <Typography className="font-10" sx={{ color: "#999999" }}>
        {METRIC_NAMES[selectedKeywordsBucket][name]}
      </Typography>
    </Grid>
    <Grid item xs={6} justifyContent="end" display="flex" alignItems="center">
      <Typography
        className="font-10"
        sx={{
          color: "#4CA3EA",
          px: "0.25rem",
          py: "0,063rem",
          borderRadius: "0.25rem",
          background: "#E2EBFA",
        }}
      >
        {amountValue(Math.floor(isAvgCpc ? min / 1000000 : min))}
      </Typography>
      <Typography className="font-10" sx={{ color: "#4CA3EA" }}>
        &nbsp;-&nbsp;
      </Typography>
      <Typography
        className="font-10"
        sx={{
          color: "#4CA3EA",
          px: "0.25rem",
          py: "0,063rem",
          borderRadius: "0.25rem",
          background: "#E2EBFA",
        }}
      >
        {amountValue(Math.ceil(isAvgCpc ? max / 1000000 : max))}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <RangeSlider
        min={min}
        max={max}
        selectedValue={selected}
        onChange={(values: [number, number]) => {
          onFilterChange(name, values);
        }}
        isAvgCpc={isAvgCpc}
      />
    </Grid>
    <Grid item xs={6}>
      <Typography className="font-10" sx={{ color: "#4CA3EA" }}>
        Min
      </Typography>
    </Grid>
    <Grid item xs={6} justifyContent="end" display="flex">
      <Typography className="font-10" sx={{ color: "#4CA3EA" }}>
        Max
      </Typography>
    </Grid>
  </Grid>
);
const CheckboxFilter = ({
  name,
  values,
  onFilterChange,
  selectedKeywordsBucket,
}: any) => (
  <Stack>
    <Box>
      <Typography className="font-10" sx={{ color: "#999999" }}>
        {METRIC_NAMES[selectedKeywordsBucket][name]}
      </Typography>
    </Box>
    <FormGroup
      className="flex-row d-flex"
      sx={{
        "& *": {
          fontSize: "14px !important",
        },
        p: 1,
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            sx={{ p: 0, pr: 0.5 }}
            checked={values["LOW"]}
            onChange={() => {}}
            name={name + "Low"}
          />
        }
        label="Low"
        sx={{ pr: 0.5 }}
      />
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            sx={{ p: 0, pr: 0.5 }}
            checked={values["MEDIUM"]}
            onChange={() => {}}
            name={name + "Medium"}
          />
        }
        label="Medium"
        sx={{ pr: 0.5 }}
      />
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            sx={{ p: 0, pr: 0.5 }}
            checked={values["HIGH"]}
            onChange={() => {
              onFilterChange(
                name,
                {
                  ...values,
                  HIGH: !values["HIGH"],
                },
                "check"
              );
            }}
            name={name + "High"}
          />
        }
        label="High"
        sx={{ pr: 0.5 }}
      />
    </FormGroup>
  </Stack>
);
const FilterModal = ({
  filterId,
  filterOpen,
  filterAnchorEl,
  handleFilterClose,
}: any) => {
  const {
    selectedFilters,
    selectedKeywordsBucket,
    onFilterChange,
    applyFilters,
  } = useContext(KeywordContext);

  return (
    <>
      <Popover
        id={filterId}
        open={filterOpen}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          zIndex: "20000",
        }}
        PaperProps={{
          sx: { overflow: "visible" },
        }}
      >
        <Stack
          sx={{
            width: "15rem",
            "& *": {
              fontFamily: "Inter !important",
            },
          }}
        >
          <Grid container px={2} py={0.5} className="w-100" alignItems="center">
            <Grid item xs={10}>
              <Typography className="font-12 font-weight-600">
                Filters
              </Typography>
            </Grid>
            <Grid item xs={2} className="d-flex justify-content-end">
              <IconButton
                onClick={() => {
                  handleFilterClose();
                }}
              >
                <ForwardArrow />
              </IconButton>
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            sx={{
              mx: 2,
            }}
          />
          <Stack p={2}>
            <Box className="d-flex align-items-center">
              <Typography className="font-10 font-weight-600">
                Metrices to be shown
              </Typography>
            </Box>
            <Stack
              justifyContent={"space-between"}
              gap={1}
              height={"10rem"}
              overflow={"auto"}
            >
              <FormGroup>
                {Object.keys(METRIC_NAMES[selectedKeywordsBucket]).map(
                  (metric) => (
                    <Grid
                      container
                      className="w-100 align-items-center"
                      sx={{ height: "1.75rem" }}
                      key={metric}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              !!selectedFilters[selectedKeywordsBucket][metric]
                            }
                            onChange={() => {}}
                            name={metric}
                            size="small"
                          />
                        }
                        label={
                          <Typography className="font-10">
                            {METRIC_NAMES[selectedKeywordsBucket][metric]}
                          </Typography>
                        }
                        disabled={
                          (Object.keys(selectedFilters[selectedKeywordsBucket])
                            .length <= 1 &&
                            !!selectedFilters[selectedKeywordsBucket][
                              metric
                            ]) ||
                          (Object.keys(selectedFilters[selectedKeywordsBucket])
                            .length >= 3 &&
                            !!!selectedFilters[selectedKeywordsBucket][metric])
                        }
                      />
                    </Grid>
                  )
                )}
              </FormGroup>
            </Stack>
          </Stack>
          <Divider
            variant="middle"
            sx={{
              mx: 2,
            }}
          />
          <Stack p={2} spacing={1}>
            <Typography className="font-black font-10">
              Advanced Filter
            </Typography>
            {Object.keys(selectedFilters[selectedKeywordsBucket]).map(
              (filter) => {
                const selectedFilter =
                  selectedFilters[selectedKeywordsBucket][filter];

                if (typeof selectedFilter.min === "number") {
                  return (
                    <AdvancedFilter
                      key={filter}
                      name={filter}
                      min={
                        selectedFilter.min === parseInt(selectedFilter.min, 10)
                          ? selectedFilter.min
                          : selectedFilter.min.toFixed(2)
                      }
                      max={
                        selectedFilter.max === parseInt(selectedFilter.max, 10)
                          ? selectedFilter.max
                          : selectedFilter.max.toFixed(2)
                      }
                      onFilterChange={onFilterChange}
                      selected={selectedFilter.selected}
                      selectedKeywordsBucket={selectedKeywordsBucket}
                      isAvgCpc={filter === "metrics.averageCpc"}
                    />
                  );
                }
                return (
                  <CheckboxFilter
                    key={filter}
                    name={filter}
                    values={selectedFilter}
                    onFilterChange={onFilterChange}
                    selectedKeywordsBucket={selectedKeywordsBucket}
                  />
                );
              }
            )}
          </Stack>
          <Box
            className="d-flex justify-content-end"
            sx={{
              px: 2,
              py: 1,
              boxShadow: "0px -1px 12px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Button
              variant="contained"
              disableRipple
              sx={{
                background: "#0869FB",
                padding: "0.25rem 1rem",
                borderRadius: "0.5rem",
                borderColor: "#0869FB",
              }}
              onClick={() => {
                applyFilters();
                handleFilterClose();
              }}
            >
              <Typography
                className="font-14 font-weight-600"
                sx={{ color: "#fff", textTransform: "none" }}
              >
                Apply
              </Typography>
            </Button>
          </Box>
        </Stack>
      </Popover>
    </>
  );
};
const FilterPopover = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filterAnchorEl);
  const filterId = filterOpen ? "filter-simple-popover" : undefined;

  const handleFilterClick = (event: any) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="Filter">
        <IconButton
          disableRipple
          sx={{
            background: "#0869FB",
            borderRadius: "4px",
            ml: 2,
          }}
          aria-describedby={filterId}
          onClick={handleFilterClick}
        >
          <Filter />
        </IconButton>
      </Tooltip>
      <FilterModal
        filterId={filterId}
        filterOpen={filterOpen}
        filterAnchorEl={filterAnchorEl}
        handleFilterClose={handleFilterClose}
      />
    </>
  );
};
export default FilterPopover;
