import { Checkbox, FormGroup } from "@mui/material";
import { StyledFormControlLabel } from "./styled";
import { OptionType } from "..";

interface PxCheckGroupProps {
  options: OptionType[];
  onChange: (item: any, checked: boolean) => void;
  selected?: OptionType[];
}

export function PxCheckGroup({
  options,
  onChange,
  selected,
}: PxCheckGroupProps) {
  return (
    <FormGroup>
      {options.map((item, index) => {
        const checked =
          selected && selected.find((item2) => item2?.value === item?.value);
        return (
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={checked}
                size="small"
                onChange={() => {
                  onChange(item, checked);
                }}
                name={item?.value}
              />
            }
            key={item?.value}
            label={item?.desc}
          />
        );
      })}
    </FormGroup>
  );
}
