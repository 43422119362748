import * as React from "react";
import { Tooltip as MUITooltip, TooltipProps } from "@mui/material";

interface CustomizedTooltipProps extends TooltipProps {
  textTruncate: number;
  title: string | number;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  children: any;
}

const Tooltip: React.FC<CustomizedTooltipProps> = ({
  textTruncate,
  title,
  placement,
  children,
  ...props
}) => {
  return title && title.toString().length > textTruncate ? (
    <MUITooltip title={title} placement={placement} {...props}>
      <span>{children.toString().substring(0, textTruncate) + "..."}</span>
    </MUITooltip>
  ) : (
    <span>{children}</span>
  );
};

export default Tooltip;
