import styled from "styled-components";

export const StyledFormLeversContainer = styled.div`
  .MuiAccordion-root {
    box-shadow: 0px 4px 6px rgb(0 0 0 / 6%);
    border: 1px solid #ebebeb;
    border-radius: 0.625em;
  }
`;
export const StyledAccordionText = styled.div`
  font-family: "lato";
  font-style: normal;
  font-weight: 800;
  font-size: 1em;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: #000000;
`;
