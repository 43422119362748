import { buildMonthDaysListFlat } from "Pages/BudgetPlanner/BudgetPlannerHolder/helpers";
import { Days, Months } from "Pages/BudgetPlanner/BudgetPlannerHolder/Types";
import React, { useEffect, useMemo, useState } from "react";
import { DayCell } from "./DayCell/DayCell";
import "./index.scss";

export interface IDayCell {
  fullDateString: string;
  fullDateNumber: number;
  day: number;
}
interface IDateRangePickerProps {
  currentDate: string;
  value: any;
  onSelection: Function;
  disableDates: string[];
  disablePast: boolean;
  disableToday: boolean;
  timeZoneOffSet: number;
}
export const DateRangePicker = ({
  currentDate,
  value,
  onSelection,
  disableDates,
  disablePast,
  disableToday,
  timeZoneOffSet,
}: IDateRangePickerProps) => {
  const [month, setMonth] = useState<number>(
    parseInt((value && value[0] ? value[0] : currentDate).split("/")[1]) - 1
  );
  const [startDate, setStartDate] = useState(value[0]);
  const [endDate, setEndDate] = useState(value[1]);
  const [year, setYear] = useState<number>(
    parseInt((value && value[0] ? value[0] : currentDate).split("/")[0])
  );
  const [hoveredCell, setHoveredCell] = useState<IDayCell | null>(null);
  const calendarDays = useMemo(
    () => buildMonthDaysListFlat(month, year, timeZoneOffSet),
    [month, year]
  );

  const prevMonth = () => {
    if (month - 1 < 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const nextMonth = () => {
    if (month + 1 === 12) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const onDayClick = (dayCell: IDayCell) => {
    if (startDate !== null && endDate === null) {
      // hovering is happening
      if (dayCell.fullDateNumber >= parseInt(startDate.split("/").join(""))) {
        setEndDate(dayCell.fullDateString);
        onSelection([startDate, dayCell.fullDateString]);
      } else setStartDate(dayCell.fullDateString);
    } else {
      //if (value[0] === null && value[1] === null) {
      setStartDate(dayCell.fullDateString);
      setEndDate(null);
      // }
    }
  };
  // const onSelectDate = (day: any) => {
  //   if (range?.startDate) {
  //     setRange({ startDate: range?.startDate, endDate: day?.fullDateString });
  //   } else {
  //     setRange({ startDate: day?.fullDateString, endDate: null });
  //   }
  // };
  const onHover = (dayCell: IDayCell) => {
    if (startDate && endDate === null && dayCell && dayCell.fullDateString) {
      setHoveredCell(dayCell);
    } else {
      setHoveredCell(null);
    }
  };
  return (
    <div
      className="new-daterange-calendar-view"
      style={{
        width: "330px",
      }}
    >
      <div className="headers">
        <div className="navigation-items">
          <div className="display-dates">
            <span className="display-item">
              <input
                readOnly
                placeholder="Early"
                value={startDate ? startDate : "    /  /  "}
              />
            </span>
            <span className="display-item">
              <input
                readOnly
                placeholder="Continuous"
                value={endDate ? endDate : "    /  /  "}
              />
            </span>
          </div>
          <div className="month-year-wrapper">
            <button
              type="button"
              className="next-prev-button prev-button"
              onClick={prevMonth}
            >
              <i></i>
            </button>
            <span className="month-year-picker">
              <span className="month-picker">
                {Months[month]}
                {/* <select
                  value={month}
                  onChange={(e: any) => {
                    setMonth(parseInt(e.target.value));
                  }}
                >
                  {Months.map((eachMonth: string, index: number) => {
                    return <option value={index}>{eachMonth}</option>;
                  })}
                </select> */}
              </span>
              <span></span>
              <span className="year-picker">
                {year}
                {/* <select
                  value={year}
                  onChange={(e: any) => {
                    setYear(parseInt(e.target.value));
                  }}
                >
                  <option value="2026">2029</option>
                  <option value="2025">2028</option>
                  <option value="2024">2027</option>
                  <option value="2026">2026</option>
                  <option value="2025">2025</option>
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                  <option value="2026">2022</option>
                  <option value="2025">2021</option>
                  <option value="2024">2020</option>
                  <option value="2026">2019</option>
                  <option value="2025">2018</option>
                </select> */}
              </span>
            </span>
            <button
              type="button"
              className="next-prev-button next-button"
              onClick={nextMonth}
            >
              <i></i>
            </button>
          </div>
        </div>
        <div className="week-days flex-center">
          {Days.map((eachDay: any) => {
            return <div className="each-cell">{eachDay}</div>;
          })}
        </div>
      </div>
      <div
        className="calendar-body flex-center"
        style={{
          flexWrap: "wrap",
        }}
      >
        {calendarDays.length > 0 &&
          calendarDays.map((day: any, index) => {
            return (
              <DayCell
                key={index}
                day={day}
                isDisabled={
                  disableDates.includes(day && day.fullDateString) ||
                  (disablePast &&
                    day &&
                    day.fullDateNumber <
                      parseInt(currentDate.split("/").join(""))) ||
                  (disableToday &&
                    day &&
                    day.fullDateNumber ===
                      parseInt(currentDate.split("/").join("")))
                }
                onSelectDate={onDayClick}
                value={[startDate, endDate]}
                onHover={onHover}
                hoveredCell={hoveredCell}
                isToday={day?.fullDateString === currentDate}
              />
            );
          })}
        <div></div>
      </div>
    </div>
  );
};
