import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Container from "./Container";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getConfigsOfGAAccount,
  getPropertiesOfGAAccount,
  updateConfigDetailsFromAccountId,
} from "store/actions/thirdPartyChannels";
import { ReactComponent as CaretDown } from "assets/tai/caretDown.svg";
import CommonLoader from "assets/Gif/CommonLoader.gif";
import { useSearchParams } from "react-router-dom-v5-compat";

const SelectProperties: React.FC<{
  selectedAccount: any;
  brandId: string;
  tokenUID: string;
  platformId: string;
  platform: any;
  brandName: any;
}> = ({
  selectedAccount,
  brandId,
  tokenUID,
  platform,
  platformId,
  brandName,
}) => {
  const [propertiesData, updatePropertiesData] = useState(null);
  const [viewData, updateViewData] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState<any>(null);
  const [selectedView, setSelectedView] = useState<any>(null);
  const [searchParams] = useSearchParams();
    const isViewedFromBeta = searchParams.get("beta");
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedAccount?.accountId) {
      dispatch(
        getPropertiesOfGAAccount(
          { gaAccountId: selectedAccount.accountId },
          (resp: any) => {
            // if (resp.data.length)
            updatePropertiesData(
              resp.data.map((item: any) => ({
                id: item.propertyId,
                name: item.propertyName,
                type: item.type,
              }))
            );
          }
        )
      );
    }
  }, [dispatch, selectedAccount.accountId]);
  useEffect(() => {
    if (selectedProperty?.id && selectedAccount.accountId) {
      updateViewData(null);
      setSelectedView(null);
      dispatch(
        getConfigsOfGAAccount(
          {
            gaAccountId: selectedAccount.accountId,
            propertyId: selectedProperty?.id,
          },
          (resp: any) => {
            // if (resp.data.length)
            updateViewData(
              resp.data.map((item: any) => {
                if (item.viewId)
                  return {
                    id: item.viewId,
                    name: item.viewName,
                  };
              })
            );
          }
        )
      );
    }
  }, [selectedAccount.accountId, selectedProperty?.id]);
  return (
    <Container
      saveButtonText="Finish"
      onClickNext={() => {
        dispatch(
          updateConfigDetailsFromAccountId(
            {
              brandId: platform.brand_id,
              gaAccountId: selectedAccount.accountId,
            },
            {
              propertyName: selectedProperty.name,
              viewName: selectedView?.name || undefined,
              propertyId: selectedProperty.id,
              viewId: selectedView?.id || undefined,
            },
            () => {
              if (isViewedFromBeta) {
                window.parent.postMessage(
                  {
                    type: 'PUSH-HISTORY',
                    url: `/accounts-manager?brandId=${platform.brand_id}`
                  },
                  '*'
                );
              }
              history.push(`/accounts-manager?brand_id=${platform.brand_id}`);
            }
          )
        );
      }}
      disable={
        selectedProperty &&
        (selectedProperty?.type !== "UA"
          ? selectedProperty?.type !== "UA"
          : selectedView)
      }
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              height: "200px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "28px",
                  fontWeight: "500",
                  margin: "auto",
                  fontFamily: "Inter",
                }}
              >
                {brandName}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  marginTop: "8px",
                  fontFamily: "Inter",
                  color: "#6D6D6D",
                }}
              >
                Account Name : {selectedAccount.accountName}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  marginTop: "8px",
                  fontFamily: "Inter",
                  color: "#6D6D6D",
                }}
              >
                Account Id : {selectedAccount.accountId}
              </Typography>
            </Box>
          </Box>

          <Box>
            <SelectField
              heading="Property"
              data={propertiesData}
              selectedValue={selectedProperty}
              changeSelectedValue={setSelectedProperty}
              onChange={() => {
                updateViewData(null);
              }}
            />
            {selectedProperty && selectedProperty?.type === "UA" && (
              <SelectField
                heading="View"
                data={viewData}
                selectedValue={selectedView}
                changeSelectedValue={setSelectedView}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SelectProperties;

export const SelectField: React.FC<{
  heading: string;
  data: any;
  selectedValue: any;
  changeSelectedValue: any;
  onChange?: any;
}> = ({ heading, data, selectedValue, changeSelectedValue, onChange }) => {
  function CustomSvgIcon() {
    return (
      <>
        {data?.length > -1 ? (
          <CaretDown
            style={{ height: "5px", width: "12px", marginRight: "14px" }}
          />
        ) : (
          <img
            style={{ height: "1em", width: "1em", marginRight: "14px" }}
            src={CommonLoader}
            alt="Loader"
          />
        )}
      </>
    );
  }

  return (
    <Box sx={{ height: "80px" }}>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Inter",
          color: "#6D6D6D",
        }}
      >
        {heading}
      </Typography>
      <Select
        disabled={!data}
        IconComponent={CustomSvgIcon}
        value={selectedValue?.name || `Select ${heading}`}
        displayEmpty={true}
        sx={{
          marginTop: "4px",
          borderRadius: "8px",
          width: "100%",
          height: "42px",
          color: "#999999",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DDDDDD !important",
          },
          "& .Mui-disabled": { cursor: "not-allowed", opacity: "0.5" },
        }}
        renderValue={(value) => value}
      >
        {data?.length === 0 ? (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              minHeight: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ color: "#999999" }}
            >{`No ${heading} Found`}</Typography>
          </Box>
        ) : (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            sx={{ paddingLeft: "20px" }}
            value={selectedValue}
            name="radio-buttons-group"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
              if (onChange) onChange();
              changeSelectedValue(
                data.find(
                  (element: any) =>
                    element.id === (event.target as HTMLInputElement).value
                )
              );
            }}
          >
            {data?.map(
              (item: any) =>
                item && (
                  <FormControlLabel
                    sx={{ width: "100%" }}
                    checked={selectedValue?.id === item.id}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              fontFamily: "Inter",
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              fontFamily: "Inter",
                              color: "#666666",
                            }}
                          >
                            {item.id}
                          </Typography>
                        </Box>
                      </Box>
                    }
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            height: "20px",
                            width: "20px",
                            fill: `${
                              selectedValue?.id === item.id
                                ? "#0869FB"
                                : "#CCCCCC"
                            }`,
                          },
                        }}
                      />
                    }
                    value={item.id}
                  />
                )
            )}
          </RadioGroup>
        )}
      </Select>
    </Box>
  );
};
