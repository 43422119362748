import { Box } from "@mui/material";
import { StyledDynamicFilterOverflowBox } from "./styled";
import { StyledText } from "components/shared";
import DynamicFilterPopover from "./DynamicFilterPopover";
import { DynamicFilterItemType } from "./types";
import { memo, useEffect, useState } from "react";
import { PxSelect } from "components/CreativeAI_Revamp/shared";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { getFiltersUpdated } from "store/actions/CreativeAI_Revamp/facebook.actions";
import { useCaiFbMixPanel } from "components/CreativeAI_Revamp/Facebook/HomeScreen/hooks/useCaiFbMixPanel";

function DynamicFilterBox({
  dynamicFilterArray,
  handleFilterChange,
}: {
  dynamicFilterArray: DynamicFilterItemType[];
  handleFilterChange: any;
}) {
  const [filterState, setFilterState] =
    useState<DynamicFilterItemType[]>(dynamicFilterArray);
  const { mixPanelFilterAdded, mixPanelFilterRemoved } = useCaiFbMixPanel();
  const allSelected = filterState.filter((item) => item.selected);
  const handleChange = (
    value: any,
    item: DynamicFilterItemType,
    index: number
  ) => {
    const localFilterState = cloneDeep(filterState);
    const added =
      localFilterState[index].type === "radio"
        ? true
        : localFilterState[index]?.selected.length <= value.length;
    if (!added) {
      mixPanelFilterRemoved(
        localFilterState[index].name,
        localFilterState[index].selected
          .filter(
            (val: any) => !Boolean(value.find((v: any) => v.desc === val.desc))
          )
          .map((i: any) => i.desc)
      );
    } else {
      mixPanelFilterAdded(
        localFilterState[index].name,
        localFilterState[index].type === "radio"
          ? [value.desc]
          : value.map((val: any) => val.desc)
      );
    }
    localFilterState[index].selected = Array.isArray(value)
      ? value.length === 0
        ? undefined
        : value
      : value;
    setFilterState(localFilterState);
  };
  useEffect(() => {
    handleFilterChange(filterState);
  }, [filterState]);

  const onFilterClose = (index: number) => {
    mixPanelFilterRemoved(
      filterState[index].name,
      filterState?.[index]?.type === "radio"
        ? [filterState?.[index]?.selected?.desc]
        : filterState?.[index]?.selected.map((item: any) => item?.desc)
    );
    const localFilterState = cloneDeep(filterState);
    localFilterState[index].selected = undefined;
    setFilterState(localFilterState);
  };

  return (
    <Box display="flex" flexDirection="column" flex={1} minWidth={0}>
      <StyledText
        fontSize="0.6875em"
        lineHeight="13px"
        color="#666666"
        pb=".3em"
      >
        Filters
      </StyledText>
      <Box display="flex" flex={1} minWidth={0}>
        <StyledDynamicFilterOverflowBox
          mr={allSelected.length > 0 ? ".6em" : "0em"}
        >
          {filterState.map(
            (item, index) =>
              item.selected && (
                <Box p="0em .25em" key={item.name}>
                  <PxSelect
                    onFilterClose={() => {
                      onFilterClose(index);
                    }}
                    heading={item.name}
                    options={item.options}
                    multiple={item.type === "check"}
                    dropDownContainerWidth={item.width ?? "10.375em"}
                    dropDownContainerHeight="1.9em"
                    selection={item.selected}
                    handlePropChange={(value) =>
                      handleChange(value, item, index)
                    }
                    hideSubtitle
                    sx={{
                      fontSize: "1em",
                    }}
                  />
                </Box>
              )
          )}
        </StyledDynamicFilterOverflowBox>
        <DynamicFilterPopover
          mixPanelFilterAdded={mixPanelFilterAdded}
          mixPanelFilterRemoved={mixPanelFilterRemoved}
          filterState={filterState}
          setFilterState={setFilterState}
        />
      </Box>
    </Box>
  );
}

export const DynamicFilterBoxWithMemo = memo(DynamicFilterBox);
