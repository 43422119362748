import { Box } from "@mui/material";
import { actionContainerList } from "../../shared/constFile";
import { StyledTitle } from "../styled";
import ActionSummaryItem from "./ActionSummaryItem";
import { ReactComponent as InfoCircle } from "../../../../assets/svg/info_circle.svg";
import { DisclaimerText, StyledInfoText } from "./styled";
import ActionSummarySkeleton from "./ActionSummarySkeleton";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getActionSummary,
  setActionSummaryLoader,
  setPreviousActionSummaryLoader,
} from "../../../../store/actions/ActionDebugger/ActionSummary";
import { ActionDebuggerInitStateType } from "../../../../store/types/ActionDebugger/ActionDebugger";
import {
  ActionSummaryInitStateType,
  ActionSummaryData,
} from "../../../../store/types/ActionDebugger/ActionSummary";
import { calculatePercentageValue } from "../../shared/utils/common.utils";
import { ActionDebuggerHelperInitState } from "../../../../store/types/ActionDebugger/ActionDebuggerHelper";
import { yyyyMMDDFormat } from "../../../../utils/date.util";

export default function ActionSummary({
  parentLoading,
}: {
  parentLoading?: boolean;
}) {
  const dispatch = useDispatch();
  const { currentPeriod, previousPeriod, isCompare } = useSelector(
    (state: { ActionDebuggerReducer: ActionDebuggerInitStateType }) =>
      state.ActionDebuggerReducer
  );

  const {
    actionSummary,
    previousActionSummary,
    actionSummaryLoader,
    previousActionSummaryLoader,
  } = useSelector(
    (state: { ActionSummaryReducer: ActionSummaryInitStateType }) =>
      state.ActionSummaryReducer
  );
  const { selectedAdAccount, selectedAIGroup } = useSelector(
    (state: { ActionDebuggerHelperReducer: ActionDebuggerHelperInitState }) =>
      state.ActionDebuggerHelperReducer
  );

  const getCurrentActionSummary = () => {
    if (selectedAdAccount?.selectedAdAcc?.elementId) {
      dispatch(setActionSummaryLoader(true));
      dispatch(
        getActionSummary({
          elementId: selectedAdAccount?.selectedAdAcc?.elementId,
          start_date: yyyyMMDDFormat(currentPeriod.startDate),
          end_date: yyyyMMDDFormat(currentPeriod.endDate),
          typeOfData: "SET_ACTION_SUMMARY",
          typeOfLoader: "SET_ACTION_SUMMARY_LOADER",
        })
      );
    }
  };

  const getPreviousActionSummary = () => {
    if (isCompare && selectedAdAccount?.selectedAdAcc?.elementId) {
      dispatch(setPreviousActionSummaryLoader(true));
      dispatch(
        getActionSummary({
          elementId: selectedAdAccount?.selectedAdAcc?.elementId,
          start_date: yyyyMMDDFormat(previousPeriod.startDate),
          end_date: yyyyMMDDFormat(previousPeriod.endDate),
          typeOfData: "SET_PREVIOUS_ACTION_SUMMARY",
          typeOfLoader: "SET_PREVIOUS_ACTION_SUMMARY_LOADER",
        })
      );
    }
  };

  useEffect(() => {
    if (selectedAdAccount?.selectedAdAcc?.elementId) {
      getCurrentActionSummary();
    }
  }, [currentPeriod, selectedAdAccount]);

  useEffect(() => {
    if (selectedAdAccount?.selectedAdAcc?.elementId && isCompare) {
      getPreviousActionSummary();
    }
  }, [previousPeriod, selectedAdAccount]);

  const actionSkeletonArray = useMemo(() => Array.apply(null, Array(5)), []);

  useEffect(() => {
    if (!selectedAIGroup?.id) {
      dispatch({ type: "SET_ACTION_SUMMARY", payload: [] });
      dispatch({ type: "SET_PREVIOUS_ACTION_SUMMARY", payload: [] });
    } else if (!selectedAdAccount?.selectedAdAcc?.elementId) {
      dispatch({ type: "SET_ACTION_SUMMARY", payload: [] });
      dispatch({ type: "SET_PREVIOUS_ACTION_SUMMARY", payload: [] });
    } else if (
      selectedAdAccount?.platform?.name &&
      (!selectedAdAccount.platform.name.toLowerCase().includes("google") ||
        !selectedAdAccount.platform.name.toLowerCase().includes("facebook") ||
        !selectedAdAccount.platform.name.toLowerCase().includes("tiktok"))
    ) {
      dispatch({ type: "SET_ACTION_SUMMARY", payload: [] });
      dispatch({ type: "SET_PREVIOUS_ACTION_SUMMARY", payload: [] });
    }
  }, [selectedAIGroup, selectedAdAccount]);

  return (
    <Box width="100%">
      <StyledTitle>AI Action Summary</StyledTitle>
      {actionSummaryLoader || previousActionSummaryLoader || parentLoading ? (
        actionSkeletonArray.map(() => <ActionSummarySkeleton />)
      ) : actionSummary.length &&
        actionSummary.filter((each) => each.value > 0).length ? (
        actionSummary.map((item: ActionSummaryData, index: number) => {
          const percentageValue = calculatePercentageValue(
            item.value,
            previousActionSummary[index]?.value
          );
          if (item.value > 0) {
            if (
              selectedAdAccount?.platform?.type
                .toLowerCase()
                .includes("facebook")
            ) {
              if (item.name !== "Creative Rotation Actions") {
                return (
                  <ActionSummaryItem
                    item={item}
                    comparingData={
                      isCompare
                        ? {
                            loss: percentageValue < 0,
                            isZero: percentageValue === 0,
                            value: `${Math.abs(percentageValue)}%`,
                            previousPeriod: previousPeriod,
                            previousValue: previousActionSummary[index]?.value,
                            difference: Math.abs(
                              item.value - previousActionSummary[index]?.value
                            ),
                          }
                        : undefined
                    }
                    actionContainerListItem={actionContainerList[item.type]}
                    currentPeriod={currentPeriod}
                  />
                );
              }
            } else {
              return (
                <ActionSummaryItem
                  item={item}
                  comparingData={
                    isCompare
                      ? {
                          loss: percentageValue < 0,
                          isZero:
                            Math.abs(
                              item.value - previousActionSummary[index]?.value
                            ) === 0,
                          value: `${Math.abs(percentageValue)}%`,
                          previousPeriod: previousPeriod,
                          previousValue: previousActionSummary[index]?.value,
                          difference: Math.abs(
                            item.value - previousActionSummary[index]?.value
                          ),
                        }
                      : undefined
                  }
                  actionContainerListItem={actionContainerList[item.type]}
                  currentPeriod={currentPeriod}
                />
              );
            }
          }
        })
      ) : (
        <Box
          height="12em"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <InfoCircle height="2.625em" width="2.625em" />
          <StyledInfoText>No Actions were taken in this period</StyledInfoText>
        </Box>
      )}
      {actionSummary.length &&
      actionSummary.filter((each) => each.value > 0).length ? (
        <DisclaimerText>
          *Disclaimer: Currently the AI action summary is available on a
          platform level only.
        </DisclaimerText>
      ) : (
        ""
      )}
    </Box>
  );
}
