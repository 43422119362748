import { Box, Skeleton } from "@mui/material";
import React from "react";
import { StyledActionItemCard } from "./styled";

export default function ActionSummarySkeleton() {
  return (
    <StyledActionItemCard width="100%">
      <Skeleton
        variant="text"
        width="2em"
        height="2.5em"
        sx={{
          margin: ".2em 0.2em .2em 1em",
        }}
      />
      <Box flex="1" pl="1em" pr="1.2em">
        <Skeleton variant="text" height="2.8em" />
      </Box>
    </StyledActionItemCard>
  );
}
