import { Card, CardMedia, Typography } from "@mui/material";
import BasicModal from "common/modal";
import React from "react";
import PeriodicPacing from "../../../assets/periodic_pacing.svg";
import Daily_BudgetPacing from "../../../assets/daily_budget_pacing.svg";

const CardStyle = {
  backgroundColor: "#E7F0FF",
  border: "2px solid #0869FB",
  m: 1,
  p: 2,
  mb: 3,
  width: "50%",
  boxSizing: "content-box",
};

const initialCardStyle = {
  m: 1,
  p: 2,
  mb: 3,
  width: "50%",
  backgroundColor: "white",
  border: "2px solid white",
  boxSizing: "content-box",
};

const BudgetType: React.FC<any> = ({ open, setOpen, setPacingType }) => {
  const [cardStyle, setCardStyle] = React.useState("periodic");

  return (
    <>
      <BasicModal
        open={open}
        setOpen={setOpen}
        title="Choose Pacing Type"
        height="32em"
        width="48em"
        showButton={false}
        titleHeight="12%"
        hideCloseButton={true}
      >
        <Typography
          id="modal-modal-description"
          sx={{
            display: "flex",
            justifyContent: "space-around",
            cursor: "pointer",
            width: "100%",
          }}
        >
          <Card
            onClick={() => {
              setCardStyle("periodic");
              setPacingType("PERIODIC");
              setOpen(false);
            }}
            sx={
              cardStyle === "periodic"
                ? { ...CardStyle }
                : { ...initialCardStyle }
            }
          >
            <CardMedia
              component="img"
              image={PeriodicPacing}
              alt="periodic_pacing"
              sx={{ borderRadius: "0.5em", height: "12em" }}
            />
            <Typography
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: 600,
                fontSize: "1em",
                mt: 3,
              }}
            >
              Periodic Pacing
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "0.75em",
                textAlign: "justify",
              }}
            >
              The budget is configured for a selected period and will be paced
              everyday. The daily budget recommendations will then be applied on
              campaigns based on the platform and performance.
            </Typography>
          </Card>
          <Card
            onClick={() => {
              setCardStyle("dailyBudget");
              setPacingType("DAILY");
              setOpen(false);
            }}
            sx={
              cardStyle === "dailyBudget"
                ? { ...CardStyle }
                : { ...initialCardStyle }
            }
          >
            <CardMedia
              component="img"
              image={Daily_BudgetPacing}
              alt="daily_budget_pacing"
              sx={{ borderRadius: "0.5em", height: "12em" }}
            />
            <Typography
              component="p"
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "1em",
                mt: 3,
              }}
            >
              Daily Budget Optimisation
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "0.75em",
                textAlign: "justify",
              }}
            >
              The daily budget is fixed for each day and is maintained for the
              selected period of time. Budget is redistributed based on the
              platform and campaign performance.
            </Typography>
          </Card>
        </Typography>
      </BasicModal>
    </>
  );
};

export default BudgetType;
