import React from "react";
import { useSelector } from "react-redux";
import { ClusterState } from "../../../store/types/cluster";
import "./index.scss";

const DrawerHeader: React.FC = (): JSX.Element => {

  const clusterState = useSelector((state: { cluster: ClusterState }) => state.cluster);
  const { targetKeyword } = clusterState;

  return (
    <div className="createad-drawer-header">
      <div className="drawer-header-title">Create ad: {targetKeyword.title}</div>
      <div className="drawer-header-keyword-container">
        <div className="keyword-title">Keywords: </div>
        <div className="keywords">
          <span>
            {targetKeyword.keywords && targetKeyword.keywords.slice(0, 150)}
            {targetKeyword.keywords &&  targetKeyword.keywords.length > 150 && (
              <span className="view-more noselect">
                {` View More`}
                <div className="keywords-popup keywords">
                  <div className="keyword-popup-title">Keywords: </div>
                  {targetKeyword.keywords}
                </div>
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DrawerHeader;
