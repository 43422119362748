import React from "react";

const HandleClickOutside = ( ref: any, params:any ) => {
    React.useEffect(() => {
        function handleClickOutside(event:any) {
            if (ref.current && !ref.current.contains(event.target)) {
                if( params.length > 0) {
                    params.map((param:any) => {
                        param(false);
                    })
                }
            }
        }
    
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

export default HandleClickOutside;
