import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "../../../store/types/auth";
import {
  generatData as generatDataAction,
  getData,
  getCreativeReplacement,
} from "../../../store/actions/CreativeAI/GsaiPlayground";
import type { GsaiState } from "../../../store/types/CreativeAI/GsaiPlayground";
import { mixPanelAdEvent } from "utils/mixpanel";
import { AdAccountState } from "store/types/adaccount";

let headlineIntervals: any = null;
let descriptionIntervals: any = null;

export default function useHeadlinesAndDescription() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: { auth: initialState }) => state.auth);
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const gsaiPlayground = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );
  const {
    headlines,
    descriptions,
    recommendations,
    screen,
    modal,
    isCreate,
    adUnit,
    dnd,
    businessUnitLoader,
    keywordLoader,
    keywordFailer,
    headlinedesLoader,
    descriptionLoader,
  } = gsaiPlayground;

  const isReplacementsLoadedRef = useRef(false);

  useEffect(() => {
    if (
      screen &&
      adUnit?.adGroupAd?.ad?.id &&
      !isReplacementsLoadedRef.current &&
      !isCreate
    ) {
      isReplacementsLoadedRef.current = true;
      dispatch(
        getCreativeReplacement({ user, adId: adUnit.adGroupAd.ad.id, dnd })
      );
    }
  }, [screen, user, adUnit, dnd]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        finalUrl: adUnit?.adGroupAd?.ad?.finalUrls
          ? adUnit.adGroupAd.ad.finalUrls[0]
          : "https://www.example.com",
        path1: adUnit?.adGroupAd?.ad?.responsiveSearchAd?.path1,
        path2: adUnit?.adGroupAd?.ad?.responsiveSearchAd?.path2,
      },
    });
  }, [adUnit]);

  useEffect(() => {
    clearInterval(headlineIntervals);

    if (headlines.status === "polling" && headlines.requestId) {
      dispatch(
        getData({ requestId: headlines.requestId, user, type: "headlines" })
      );

      headlineIntervals = setInterval(() => {
        if (headlines.status === "completed") {
          clearInterval(headlineIntervals);
        } else {
          dispatch(
            getData({ requestId: headlines.requestId, user, type: "headlines" })
          );
        }
      }, 5000);
    }
  }, [headlines.requestId, headlines.status]);

  useEffect(() => {
    clearInterval(descriptionIntervals);

    if (descriptions.status === "polling" && descriptions.requestId) {
      dispatch(
        getData({
          requestId: descriptions.requestId,
          user,
          type: "descriptions",
        })
      );

      descriptionIntervals = setInterval(() => {
        if (descriptions.status === "completed") {
          clearInterval(descriptionIntervals);
        } else {
          dispatch(
            getData({
              requestId: descriptions.requestId,
              user,
              type: "descriptions",
            })
          );
        }
      }, 5000);
    }
  }, [descriptions.requestId, descriptions.status]);

  useEffect(() => {
    if (headlines.status === "failed" || descriptions.status === "failed") {
      clearInterval(headlineIntervals);
      clearInterval(descriptionIntervals);
      dispatch({
        type: "UPDATE_GSAI_STORE",
        payload: {
          modal: "generating-hnd-failed",
        },
      });
    }

    if (
      headlines.status === "completed" &&
      descriptions.status === "completed"
    ) {
      dispatch({
        type: "UPDATE_GSAI_STORE",
        payload: {
          modal: "",
        },
      });
    }
  }, [descriptions.status, headlines.status]);

  function generateData(headlines: any, descriptions: any) {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        modal: "generating-hnd",
      },
    });
    dispatch(
      generatDataAction({ settings: headlines, user, type: "headlines" })
    );
    dispatch(
      generatDataAction({ settings: descriptions, user, type: "descriptions" })
    );
  }

  useEffect(() => {
    if (recommendations.status === "completed") {
      const headlines: Record<string, any> = {
        bu_name: recommendations.data?.recommended_business_units?.bu_name,
        bu_detail: recommendations.data?.recommended_business_units?.bu_detail,
        interest_keyword: [recommendations.data?.recommended_interest_keyword],
        reference_headline: recommendations.data?.recommended_headlines,
        generation_type: recommendations?.data?.generation_type?.headlines || [
          "interest",
          "brand",
          "generic",
        ],
        no_of_outputs: 15,
        benefit: recommendations.data.benefit,
        brand_id: selectedBrand?.id,
      };
      if (
        headlines.generation_type.indexOf("benefit") > -1 &&
        recommendations?.data?.benefit?.headlines?.length
      ) {
        headlines.benefit = {
          benefit_used: recommendations.data.benefit.headlines,
        };
      } else {
        delete headlines.benefit;
      }

      const descriptions: Record<string, any> = {
        bu_name: recommendations.data?.recommended_business_units?.bu_name,
        bu_detail: recommendations.data?.recommended_business_units?.bu_detail,
        interest_keyword: [recommendations.data?.recommended_interest_keyword],
        reference_description: recommendations.data?.recommended_descriptions,
        generation_type: recommendations?.data?.generation_type
          ?.descriptions || ["interest", "generic"],
        no_of_outputs: 10,
        benefit: recommendations?.data?.benefit,
        usp: recommendations?.data?.usp,
        brand_id: selectedBrand?.id,
      };
      if (
        descriptions.generation_type.indexOf("benefit") > -1 &&
        recommendations?.data?.benefit?.descriptions?.length
      ) {
        descriptions.benefit = {
          benefit_used: recommendations.data.benefit.descriptions,
        };
      } else {
        delete descriptions.benefit;
      }
      if (
        descriptions.generation_type.indexOf("usp") > -1 &&
        recommendations?.data?.usp?.descriptions?.length
      ) {
        descriptions.usp = {
          usp_used: recommendations.data.usp.descriptions,
        };
      } else {
        delete descriptions.usp;
      }
      generateData(headlines, descriptions);
    }
    if (recommendations.status === "failed") {
      dispatch({
        type: "UPDATE_GSAI_STORE",
        payload: {
          modal: "configure-ad",
        },
      });
    }
  }, [recommendations]);

  function closeAdConfig() {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        modal: "",
      },
    });
  }

  const showClose = !(
    headlines.status === "initial" || descriptions.status === "initial"
  );

  function saveAdConfig(configData: any) {
    mixPanelAdEvent(
      isCreate
        ? "CAI GS Create Variant : New Configuration Used"
        : "CAI GS Refresh Ad : New Configuration Used",
      {
        module_name: "Google Search Playground",
        sub_module_name: "Create Variant",
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        brand_id: selectedBrand?.id,
        agency_name: selectedBrand?.agency_name,
        agency_id: selectedBrand?.agencyId,
        reference_ad_id: adUnit?.adGroupAd?.ad?.id,
        reference_ad_group_id: adUnit?.adGroup?.id,
        reference_campaign_id: adUnit?.campaign?.id,
        reference_account_id: gsaiPlayground?.adAccount?.adAccountId,
        ai_group_name: gsaiPlayground?.aiGroup?.selected?.name,
        ai_group_id: gsaiPlayground?.aiGroup?.selected?.id,
        initial_interest_keyword:
          gsaiPlayground?.recommendations?.data?.recommended_interest_keyword,
        initial_reference_Headlines:
          gsaiPlayground?.recommendations?.data?.recommended_headlines,
        initial_reference_description:
          gsaiPlayground?.recommendations?.data?.recommended_descriptions,
        initial_headline_generation_types:
          gsaiPlayground?.recommendations?.data?.generation_type?.headlines,
        initial_descriptions_generation_types:
          gsaiPlayground?.recommendations?.data?.generation_type?.descriptions,
        initial_headline_benefit_input_by_user:
          gsaiPlayground?.recommendations?.data?.generation_type?.headlines
            ?.benefitValue,
        initial_description_benefit_input_by_user:
          gsaiPlayground?.recommendations?.data?.generation_type?.descriptions
            ?.benefitValue,
        final_interest_keyword: configData?.recommended_interest_keyword,
        final_reference_headlines: configData?.recommended_headlines,
        final_reference_description: configData?.recommended_descriptions,
        final_headline_generation_types: configData?.generation_type?.headlines,
        final_descriptions_generation_types:
          configData?.generation_type?.descriptions,
        final_headline_benefit_input_by_user:
          configData?.generation_type?.headlines?.benefitValue,
        final_description_benefit_input_by_user:
          configData?.generation_type?.descriptions?.benefitValue,
      }
    );
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        recommendations: {
          ...recommendations,
          data: configData,
        },
      },
    });
  }

  return {
    screen,
    modal,
    closeAdConfig,
    adUnit,
    recommendations,
    showClose,
    saveAdConfig,
    dnd,
    businessUnitLoader,
    keywordLoader,
    keywordFailer,
    headlinedesLoader,
    descriptionLoader,
    isCreate,
  };
}

export function useGenerateMore() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: { auth: initialState }) => state.auth);
  const { recommendations } = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const headlines = {
    bu_name: recommendations.data?.recommended_business_units?.bu_name,
    bu_detail: recommendations.data?.recommended_business_units?.bu_detail,
    interest_keyword: [recommendations.data?.recommended_interest_keyword],
    reference_headline: recommendations.data?.recommended_headlines,
    generation_type: recommendations?.data?.generation_type?.headlines || [
      "interest",
      "brand",
      "generic",
    ],
    brand_id: selectedBrand?.id,
    no_of_outputs: 10,
  };

  const descriptions = {
    bu_name: recommendations.data?.recommended_business_units?.bu_name,
    bu_detail: recommendations.data?.recommended_business_units?.bu_detail,
    interest_keyword: [recommendations.data?.recommended_interest_keyword],
    reference_description: recommendations.data?.recommended_descriptions,
    generation_type: recommendations?.data?.generation_type?.descriptions || [
      "interest",
      "generic",
    ],
    brand_id: selectedBrand?.id,
    no_of_outputs: 6,
  };

  function generateHeadlines() {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        modal: "generating-hnd",
        headlinedesLoader: true,
      },
    });
    dispatch(
      generatDataAction({ settings: headlines, user, type: "headlines" })
    );
  }

  function generateDescriptions() {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        modal: "generating-hnd",
        descriptionLoader: true,
      },
    });
    dispatch(
      generatDataAction({ settings: descriptions, user, type: "descriptions" })
    );
  }

  return {
    generateHeadlines,
    generateDescriptions,
    isLoading: recommendations.status === "polling",
  };
}
