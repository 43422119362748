import React, { useEffect } from "react";
import "./styles.scss";
import MontageAdsListing from "../components/MontageAds/MontageAdsListing";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "../store/types/auth";
import { AdAccountState } from "../store/types/adaccount";
import {
  getMontageCompaigns,
  setMontageCampaigns,
} from "../store/actions/montageAds";
import { MontageState } from "../store/types/montageAds";
import { NavLink } from "react-router-dom";
import { ReactComponent as PathRightArrow } from "../assets/svg/PathRightArrow.svg";
import Loader from "../components/common/Loader";
import { mixPanelAdEvent } from "../utils/mixpanel";
const MontageAds: React.FC = (): JSX.Element => {
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const montageAds = useSelector(
    (state: { montageAds: MontageState }) => state.montageAds
  );
  const [loader, setLoader] = React.useState<Boolean>(false);
  const { montageCampaigns } = montageAds;
  const { selectedBrand } = adaccount;
  const dispatch = useDispatch();
  useEffect(() => {
    mixPanelAdEvent("Page View - Dashboard", {
      module_name: "Creative AI - Montage Ads",
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_name: selectedBrand?.agency_name,
      agency_id: selectedBrand?.agencyId,
    });
  }, []);
  useEffect(() => {
    setLoader(true);
    dispatch(
      getMontageCompaigns(
        { brandId: selectedBrand.id, user: auth.user },
        (response: any, error: any) => {
          if (!error) {
            dispatch(setMontageCampaigns(response.data));
            setLoader(false);
          }
        }
      )
    );
  }, [selectedBrand]);
  return (
    <>
      {loader && (
        <div className="montage">
          <Loader />
        </div>
      )}
      {!loader && montageCampaigns?.length === 0 && (
        <div className="montage">
          <div className="heading">
            <span>Create content with</span>
            <span className="heading-with-diff-color"> the creator</span>
            <p>
              <small>
                Reimagine your content marketing with the best in the business.
              </small>
            </p>
          </div>
          <div className="montage-video">
            <video controls>
              <source
                src={
                  "https://performance-targeting.s3.ap-south-1.amazonaws.com/fa059c96-f74a-47db-82b8-d428195e9ee7.mp4"
                }
                type="video/mp4"
              ></source>
            </video>
          </div>
          <NavLink className="campaign-button" to={"/outline-screen"}>
            <small>Setup Campaign</small>
            <PathRightArrow className="setup-campaign-icon" />
          </NavLink>
        </div>
      )}
      {!loader && montageCampaigns?.length > 0 && <MontageAdsListing />}
    </>
  );
};

export default MontageAds;
