export const mediaStudioTableHead = [
  {
    name: "S.No",
    align: "left",
  },
  {
    name: "Tag",
    align: "left",
    tooltipText: "Give your entries an identifier tag",
  },
  { name: "Usage", align: "left" },
  {
    name: "Frame(s) Info",
    align: "left",
    tooltipText: "Total number of frames in the Media Studio content file",
  },
  {
    name: "Layer(s) Info",
    align: "left",
    tooltipText: "Total number of layers in the Media Studio content file",
  },
  { name: "Action", align: "left" },
];
