import { useSearchParams } from "react-router-dom-v5-compat";
import { SupportedPlatforms } from "../hooks";

interface Props {
  platform: SupportedPlatforms;
  buttonText?: string;
  href: any;
}
function Option({
  platform,
  buttonText = "Connect",
  href,
}: Props): JSX.Element {
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  return (
    <div className="content-option">
      <div className={`option-logo ${platform.key}`}>{platform.logo}</div>
      <span className="option-title">
        {platform.title === "Facebook" ? "Meta" : platform.title} Ads
      </span>
      <span className="option-message">
        Connect with your{" "}
        {platform.title === "Facebook" ? "Meta" : platform.title} account.
        <br /> To access all your ad accounts easily
      </span>
      <a href={href[platform.key]} target={isViewedFromBeta?'_parent':''}>{buttonText}</a>
    </div>
  );
}

export default Option;
