import {
  SET_SELECTED_COHORT_TYPE,
  SET_COMMON_FILTERS,
  SET_PUBLISHED_COHORTS,
  SET_DRAFTED_COHORTS,
  SET_RECOMMENDED_COHORTS,
  SET_DRAFTED_COHORT_FILTERS,
  SET_PUBLISHED_COHORTS_FILTER,
  SET_RECOMMENDED_COHORTS_FILTERS,
  SET_LOADING_AUDIENCE_MANAGER,
  SET_SELECTED_SORT_TYPE,
  SET_SELECTED_AI_GROUP_AUDIENCE_MANAGER,
  SET_BUCKET_SKIP,
  SET_BUCKET_LENGTH,
  RESET_ALL_AUDIENCE_MANAGER_DATA,
  SET_SELECTED_PLATFORM,
  AudienceManagerState,
  SetCommonFilters,
  SetCommonFiltersBrand,
  SetCommonFiltersBrandWebsite,
  SetPublishedCohorts,
  SetPublishedCohortsFilters,
  SetDraftedCohorts,
  SetDraftedCohortsFilters,
  SetRecommendedCohorts,
  SetRecommendedCohortsFilters,
  SetSelectedCohortType,
  SetLoading,
  SetSelectedSortType,
  SetSelectedAiGroupAudienceManager,
  SetBucketSkip,
  SetBucketLength,
  ResetAllAudienceManegerData,
  SetSelectedPlatform,
} from "../../types/AudienceManager/index";

import { subtractMonths } from "Pages/AudienceManager/utils";
import {
  BRAND_NAME_SORT_DEFAULT,
  BRAND_WEBSITE_SORT_DEFAULT,
  COHORT_TYPES,
} from "Pages/AudienceManager/constants";
import { SORT_OPTIONS } from "Pages/AudienceManager/constants/subheader";

const initialState: AudienceManagerState = {
  selectedAiGroup: null,
  selectedCohort: null,
  selectedPlatform: "facebook",
  publishedCohortsData: [],
  draftedCohortsData: [],
  recommendedCohortsData: [],
  commonFilterData: {
    date_range: {
      startDate: subtractMonths(6),
      endDate: new Date(),
    },
    brands: [],
    brand_websites: [],
    cohorts_type: COHORT_TYPES,
    selectedBrand: BRAND_NAME_SORT_DEFAULT,
    selectedCohortTypes: COHORT_TYPES,
    selectedBrandWebsite: BRAND_WEBSITE_SORT_DEFAULT,
    selectedDateRange: {
      startDate: subtractMonths(6),
      endDate: new Date(),
    },
    type: ["ICP", "INTEREST"],
  },
  publishedCohortsFilterData: {},
  draftedCohortsFiltersData: {},
  recommendedCohortsFilterData: {},
  selectedCohortType: "live",
  isLoading: {
    live: false,
    recommended: false,
    drafted: false,
  },
  length: {
    live: 0,
    recommended: 0,
    drafted: 0,
  },
  skip: {
    live: 0,
    recommended: 0,
    drafted: 0,
  },
  selectedSortType: {
    live: SORT_OPTIONS.live[0],
    drafted: SORT_OPTIONS.drafted[0],
    recommended: SORT_OPTIONS.recommended[0],
  },
};

export default function audienceManager(
  state = initialState,
  action:
    | SetPublishedCohorts
    | SetDraftedCohorts
    | SetRecommendedCohorts
    | SetDraftedCohortsFilters
    | SetPublishedCohortsFilters
    | SetRecommendedCohortsFilters
    | SetSelectedCohortType
    | SetSelectedSortType
    | SetLoading
    | SetSelectedAiGroupAudienceManager
    | SetCommonFilters
    | SetCommonFiltersBrand
    | SetCommonFiltersBrandWebsite
    | SetBucketLength
    | SetBucketSkip
    | ResetAllAudienceManegerData
    | SetSelectedPlatform
): AudienceManagerState {
  // console.log("LL: action", action);

  switch (action.type) {
    case SET_PUBLISHED_COHORTS: {
      return { ...state, publishedCohortsData: [...action.payload] };
    }
    case SET_DRAFTED_COHORTS: {
      return { ...state, draftedCohortsData: [...action.payload] };
    }
    case SET_RECOMMENDED_COHORTS: {
      return { ...state, recommendedCohortsData: [...action.payload] };
    }
    case SET_DRAFTED_COHORT_FILTERS: {
      return { ...state, draftedCohortsFiltersData: { ...action.payload } };
    }
    case SET_PUBLISHED_COHORTS_FILTER: {
      return { ...state, publishedCohortsFilterData: { ...action.payload } };
    }
    case SET_RECOMMENDED_COHORTS_FILTERS: {
      return { ...state, recommendedCohortsFilterData: { ...action.payload } };
    }
    case SET_SELECTED_COHORT_TYPE: {
      action.payload.callback(action.payload.cohortType);
      return { ...state, selectedCohortType: action.payload.cohortType };
    }
    case SET_SELECTED_SORT_TYPE: {
      return { ...state, selectedSortType: action.payload };
    }
    case SET_LOADING_AUDIENCE_MANAGER: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          ...action.payload,
        },
      };
    }
    case SET_SELECTED_AI_GROUP_AUDIENCE_MANAGER: {
      return { ...state, selectedAiGroup: action.payload };
    }
    // case SET_SELECTED_COMMON_FILTERS: {
    //   return { ...state, com };
    // }
    case SET_BUCKET_LENGTH: {
      return {
        ...state,
        length: {
          ...state.length,
          [action.payload.type]: action.payload.length,
        },
      };
    }
    case SET_BUCKET_SKIP: {
      return {
        ...state,
        length: {
          ...state.length,
          [action.payload.type]: action.payload.length,
        },
      };
    }
    case SET_COMMON_FILTERS: {
      const payloadToSet = action.payload.key.reduce(
        (prev: any, key, index) => {
          prev[key] = [
            ...new Set([
              ...((Array.isArray(state.commonFilterData[key])
                ? state.commonFilterData[key]
                : []) as any),
              ...(action.payload.value[index] as any),
            ]),
          ];
          return prev;
        },
        {}
      );
      return {
        ...state,
        commonFilterData: {
          ...state.commonFilterData,
          ...payloadToSet,
        },
      };
    }

    case RESET_ALL_AUDIENCE_MANAGER_DATA: {
      return {
        ...state,
        publishedCohortsData: [],
        draftedCohortsData: [],
        recommendedCohortsData: [],
        commonFilterData: {
          date_range: {
            startDate: subtractMonths(6),
            endDate: new Date(),
          },
          brands: [],
          brand_websites: [],
          selectedBrand: BRAND_NAME_SORT_DEFAULT,
          selectedBrandWebsite: BRAND_WEBSITE_SORT_DEFAULT,
          selectedCohortTypes: COHORT_TYPES,
          cohorts_type: COHORT_TYPES,
          // selectedBrand: ["all"],
          // selectedBrandWebsite: ["all"],
          selectedDateRange: {
            startDate: subtractMonths(6),
            endDate: new Date(),
          },
          type: ["ICP", "INTEREST"],
        },
        publishedCohortsFilterData: {},
        draftedCohortsFiltersData: {},
        recommendedCohortsFilterData: {},
        selectedCohortType: "live",
        isLoading: {
          live: false,
          recommended: false,
          drafted: false,
        },
        length: {
          live: 0,
          recommended: 0,
          drafted: 0,
        },
        skip: {
          live: 0,
          recommended: 0,
          drafted: 0,
        },
        selectedSortType: {
          live: SORT_OPTIONS.live[0],
          drafted: SORT_OPTIONS.drafted[0],
          recommended: SORT_OPTIONS.recommended[0],
        },
      };
    }

    case SET_SELECTED_PLATFORM: {
      return {
        ...state,
        selectedPlatform: action.payload,
        selectedCohortType: "recommended",
      };
    }

    default:
      return state;
  }
}
