import React from "react";

const Tooltip: React.FC<{ tooltipContainer: any; className?: string }> = ({
  tooltipContainer,
  children,
  className,
}) => {
  return (
    <div className={`tooltip-container ${className}`}>
      {tooltipContainer}
      <div className="custom-tooltip-layout tooltip">
        <div className="tootltip-wrapper">{children}</div>
      </div>
    </div>
  );
};

export default Tooltip;
