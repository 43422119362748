import React from "react";
import styled from "styled-components";

import PinPosition0 from "../../../../../assets/png/pin-position-0.png";
import PinPosition1 from "../../../../../assets/png/pin-position-1.png";
import PinPosition2 from "../../../../../assets/png/pin-position-2.png";
import PinPosition3 from "../../../../../assets/png/pin-position-3.png";
import { ReactComponent as CloseIcon } from "../../../../../assets/svg/close.svg";
import { ReactComponent as New } from "../../../../../assets/svg/gsai-new.svg";
import PinBlue from "../../../../../assets/png/pin-blue.png";
import { ReactComponent as DeleteBlue } from "../../../../../assets/svg/deleteBlue.svg";
import ButtonUI from "../../../../../ui/ButtonUI/ButtonUI";
import { Dragable } from "../../dnd";
import { DeletConfirmation } from "../../modals/DeleteConfirmation";
import "./index.scss";
import { useGenerateMore } from "../../../../../Pages/CreativeAI/GsaiPlayground/hooks";
import { Popover } from "./Popover/PopoverMetrics";
import { Box } from "@mui/system";
import { StyledMetricInfo } from "./styled";
import { ReactComponent as Impressions } from "../../../../../assets/svg/impressions.svg";
import { ReactComponent as Clicks } from "../../../../../assets/svg/clicks.svg";
import { ReactComponent as CostPerConversion } from "../../../../../assets/svg/cost-per-conversion.svg";
import { ReactComponent as ClickThroughRatio } from "../../../../../assets/svg/click-through-ratio.svg";
import { useSelector } from "react-redux";
import { initialState } from "store/types/auth";
import { getCurrencySymbol } from "utils/commonFunctions";
import { GsaiState } from "store/types/CreativeAI/GsaiPlayground";
import { AdStrengthColor } from "utils/constants/GSAI";
import { mixPanelAdEvent } from "utils/mixpanel";
import { AdAccountState } from "store/types/adaccount";
import TextAreaLabel from "ui/TextareaLabel/TextareaLabel";

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  opacity: 0;
  min-width: 50px;
  position: relative;

  &.active {
    opacity: 1;
  }

  .pin-icon {
    height: 18px;
    position: relative;
    top: 2px;
    cursor: pointer;
  }

  .pinned-position {
    color: #5185ec;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  .delete-icon {
    min-height: 18px;
    min-width: 16px;
    cursor: pointer;
  }
`;

const PinningContainer = styled.div`
  position: fixed;
  z-index: 2022;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: #fff;
  width: 507px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  .pin-content {
    .tile {
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      cursor: pointer;
      border-left: 5px solid transparent;

      .tile-title {
        padding-top: 12px;
        padding-left: 12px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        align-items: center;
        color: #333333;

        .choose-one {
          color: #3c7d40;
        }
      }

      img {
        width: 246px;
      }

      &:hover,
      &.active {
        border-left: 5px solid #5185ec;
        background: #e9f4ff;
      }
    }
  }

  .pin-header {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

    .header-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
      }

      .subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #666666;
      }
    }

    .pc-close {
      background: #f2f2f2;
      border-radius: 4px;
      border: none;
      padding: 10px;
      line-height: 1;

      svg {
        width: 14px;
      }
    }
  }
`;

export function PinningPopup({
  selected = "",
  handleClose,
  handlePinning,
  source,
  id,
  type,
}: any) {
  return (
    <PinningContainer>
      <div className="pin-header">
        <div className="header-text">
          <div className="title">Pin this headline</div>
          <div className="subtitle">Choose a position</div>
        </div>
        <button className="pc-close" onClick={handleClose}>
          <CloseIcon />
        </button>
      </div>
      <div className="pin-content" onClick={() => handleClose()}>
        <div
          className={`tile ${selected === "" ? "active" : ""}`}
          onClick={() => handlePinning(0, id, source)}
        >
          <div className="tile-title">
            <div>Show in any unpined position</div>
            {selected === "" ? (
              <div className="choose-one">Choose a position</div>
            ) : null}
          </div>
          <img src={PinPosition0} alt="PinPosition 1" />
        </div>
        <div
          className={`tile ${selected === 1 ? "active" : ""}`}
          onClick={() => handlePinning(1, id, source)}
        >
          <div className="tile-title">Show only in position 1</div>
          <img src={PinPosition1} alt="PinPosition 1" />
        </div>
        <div
          className={`tile ${selected === 2 ? "active" : ""}`}
          onClick={() => handlePinning(2, id, source)}
        >
          <div className="tile-title">Show only in position 2</div>
          <img src={PinPosition2} alt="PinPosition 2" />
        </div>
        {type === "headlines" ? (
          <div
            className={`tile ${selected === 3 ? "active" : ""}`}
            onClick={() => handlePinning(3, id, source)}
          >
            <div className="tile-title">Show only in position 3</div>
            <img src={PinPosition3} alt="PinPosition 3" />
          </div>
        ) : null}
      </div>
    </PinningContainer>
  );
}

const Selection: React.FunctionComponent<{
  data: any;
  limit: number;
  onDragStart: (key: string, source: GSAI_SOURCE) => void;
  source: GSAI_SOURCE;
  handlePinning: any;
  type: "headlines" | "descriptions";
  handleDelete: any;
  handleInputChange: any;
  more?: any;
}> = ({
  data,
  limit,
  onDragStart,
  source,
  handlePinning,
  type,
  handleDelete,
  more,
  handleInputChange,
}) => {
  const [popup, setPopup] = React.useState<any>({ show: false });
  const [deleteD, setDelete] = React.useState<any>({ show: false });
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const gsaiPlayground = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );

  const sourceType =
    source === "recommended" ? "Recommended" : "To Be Published";
  const dataType = type === "headlines" ? "Headline" : "Description";

  const { generateHeadlines, generateDescriptions, isLoading } =
    useGenerateMore();
  const { adUnit } = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );
  const capitalizeFirstLetter = (value: string) => {
    return value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    });
  };

  let commonKeys = {
    module_name: "Google Search Playground",
    sub_module_name: gsaiPlayground.isCreate ? "Create Variant" : "Refresh Ad",
    brand_name: selectedBrand?.name,
    brand_id: selectedBrand?.id,
    agency_id: selectedBrand?.agencyId,
    agency_name: selectedBrand?.agency_name,
    reference_ad_id: gsaiPlayground?.adUnit?.adGroupAd?.ad?.id,
    reference_ad_group_id: gsaiPlayground?.adUnit?.adGroup?.id,
    reference_campaign_id: gsaiPlayground?.adUnit?.campaign?.id,
    reference_account_id: gsaiPlayground?.adAccount?.adAccountId,
    ai_group_name: gsaiPlayground?.aiGroup?.selected?.name,
    ai_group_id: gsaiPlayground?.aiGroup?.selected?.id,
    brand_type: selectedBrand?.brandType,
  };

  const metricPopupComponent = (value: any) => {
    const label: string = value.value.assetPerformanceLabel;
    let color = AdStrengthColor[label] || "#FF8D08";
    return (
      <div className="flex-center">
        {source === "toBePublished" && !gsaiPlayground.isCreate && (
          <>
            <div
              style={{
                color: color,
              }}
              className="ml-2 mr-1"
            >
              {value.value.assetPerformanceLabel
                ? capitalizeFirstLetter(value.value.assetPerformanceLabel)
                : ""}
            </div>
            {value?.value?.impression && (
              <Popover>
                <Box mt="1.1em" display="flex" className="metric-popup">
                  <Box
                    sx={{
                      position: "relative",
                      "&::before": {
                        background: "#FFF",
                        content: '""',
                        display: "block",
                        position: "absolute",
                        width: "4em",
                        height: "2em",
                        left: "5.1em",
                        transform: "rotate(45deg)",
                        zIndex: 0,
                        boxShadow: "0 0px 2px rgb(0 0 0 / 20%)",
                      },
                    }}
                  />
                  <StyledMetricInfo className="metric-popover">
                    <div className="flex-center justify-content-between individual-item">
                      <div className="flex">
                        <Impressions className="mr-2 icon-fix-width" />{" "}
                        Impressions
                      </div>
                      <span>{value?.value?.impression || "-"}</span>
                    </div>
                  </StyledMetricInfo>
                </Box>
              </Popover>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {popup.show ? (
        <PinningPopup
          type={type}
          handleClose={() => setPopup({ show: false })}
          handlePinning={handlePinning}
          source={source}
          {...popup}
        />
      ) : null}
      {deleteD.show ? (
        <DeletConfirmation
          handleCancel={() => setDelete({ show: false })}
          handleConfirm={() => {
            mixPanelAdEvent(
              `CAI GS Create Variant : ${type} deleted from ${sourceType}`,
              type === "headlines"
                ? {
                    ...commonKeys,
                    deleted_headline_text: deleteD.name,
                  }
                : {
                    ...commonKeys,
                    deleted_description_text: deleteD.name,
                  }
            );
            handleDelete(deleteD.id, source);
            setDelete({ show: false });
          }}
          message={`Are you sure you want to delete ${
            type === "headlines" ? "headline" : "description"
          } ‘${deleteD.name}’?`}
          header={`Delete ${sourceType} ${dataType}`}
        />
      ) : null}
      {data &&
        data.map((val: any, index: number) => {
          if (val.isNewVal) {
            return (
              <div className="new-data-icon" key={val.id}>
                <New />
              </div>
            );
          }
          return (
            <div className="variant-selection-container flex" key={val.id}>
              <Dragable
                className="flex"
                key={val.id}
                value={val.id}
                onDragStart={onDragStart}
                source={source}
              >
                <TextAreaLabel
                  label={val.type || "Existing"}
                  limit={limit}
                  onInputChange={(e) => {
                    handleInputChange(val.id, source, e.target.value);
                  }}
                  value={val.value}
                  metricComponent={metricPopupComponent}
                  data={val}
                />
              </Dragable>

              <IconContainer
                className={`icons-container ${
                  popup.id === val.id || getSelected(val.pinnedField)
                    ? "active"
                    : ""
                }`}
              >
                {source === "recommended" ? null : (
                  <div className="pinned-position">
                    <img
                      src={PinBlue}
                      alt="Pin"
                      className="pin-icon"
                      onClick={() => {
                        setPopup({
                          index,
                          show: true,
                          id: val.id,
                          selected: getSelected(val.pinnedField),
                        });
                      }}
                    />
                    <span>{getSelected(val.pinnedField)}</span>
                  </div>
                )}
                <DeleteBlue
                  className="delete-icon"
                  onClick={() => {
                    setDelete({
                      show: true,
                      id: val.id,
                      source,
                      name: val.value,
                    });
                  }}
                />
              </IconContainer>
            </div>
          );
        })}

      {/* Generate more button */}
      {source === "recommended" ? (
        <div className="create-variant-selection--footer">
          <ButtonUI
            disabled={isLoading}
            type="button"
            className="secondary btn-generate-more"
            onClick={() => {
              if (type === "headlines") {
                generateHeadlines();
              } else {
                generateDescriptions();
              }
              mixPanelAdEvent("CAI GS Generate More", {
                ...commonKeys,
                section_name: type,
                ad_account_id:
                  gsaiPlayground?.adAccounts?.selected?.adAccountId,
                ad_account_name: gsaiPlayground?.adAccounts?.selected?.name,
                ai_group_name: gsaiPlayground?.aiGroups?.selected?.name,
                ai_group_id: gsaiPlayground?.aiGroups?.selected?.id,
              });
            }}
          >
            Generate more
          </ButtonUI>
        </div>
      ) : null}
    </>
  );
};

function getSelected(position: string) {
  const lastChar = position[position.length - 1];
  if (lastChar === "1" || lastChar === "2" || lastChar === "3")
    return +lastChar;
  return "";
}

export default Selection;
