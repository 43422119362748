import React from "react";
import { ReactComponent as SVG } from "../../../../assets/cai/svg/NoFilterAds.svg";
import {
  StyledMainContainer,
  StyledMessageContainer,
  StyledSvgContainer,
} from "./styled";
interface NoFilteredAds {
  type: "Ad" | "Variant";
}
function NoFilteredAds({ type }: NoFilteredAds) {
  return (
    <StyledMainContainer>
      <StyledSvgContainer>
        <SVG />
      </StyledSvgContainer>
      <StyledMessageContainer type={type} style={{ color: "#979797" }}>
        {`Oops, no Ad${type == "Ad" ? "s" : " Variants"} match the selected `}
      </StyledMessageContainer>
      <StyledMessageContainer type={type}>
        Filter Criterion !
      </StyledMessageContainer>
    </StyledMainContainer>
  );
}

export default NoFilteredAds;
