import { Box, CheckboxProps } from "@mui/material";
import { useState, useEffect } from "react";
import InputLabel from "../../InputLabel/InputLabel";
import { CheckBox } from "./CheckBox";
import { LabelContainer } from "./styled";

interface CheckBoxFormControlProps extends CheckboxProps {
  label: string;
  required?: boolean;
  tooltipText?: string;
  setValue: any;
  getValues: (checkedFieldName: string) => boolean;
  checkedFieldName: string;
  helperFunction?: (registeredName: string) => void;
}

export function CheckBoxFormControl({
  label,
  required,
  tooltipText,
  onChange,
  setValue,
  getValues,
  checkedFieldName,
  helperFunction,
  ...props
}: CheckBoxFormControlProps) {
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    setChecked(!checked);
    helperFunction && helperFunction(checkedFieldName);
    setValue(checkedFieldName, !checked);
  };

  useEffect(() => {
    if (getValues(checkedFieldName)) {
      setChecked(true);
    } else {
      setValue(checkedFieldName, false);
    }
  }, []);
  return (
    <LabelContainer>
      <Box mt="-10px">
        <CheckBox checked={checked} onClick={handleClick} {...props} />
      </Box>
      <InputLabel required={required} label={label} tooltipText={tooltipText} />
    </LabelContainer>
  );
}
