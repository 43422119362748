export const GET_ADS_LIST = "GET_ADS_LIST";
export const SET_ADS_LIST = "SET_ADS_LIST";

export const GET_ADSETS_LIST = "GET_ADSETS_LIST";
export const SET_ADSETS_LIST = "SET_ADSETS_LIST";

export const GET_AIGROUP_LIST = "GET_AIGROUP_LIST";
export const SET_AIGROUP_LIST = "SET_AIGROUP_LIST";
export const GET_AIGROUP_BY_ID = "GET_AIGROUP_BY_ID";
export const SET_AIGROUP_BY_ID = "SET_AIGROUP_BY_ID";

export const GET_CREATIVE_ROTATION_LOGS = "GET_CREATIVE_ROTATION_LOGS";
export const SET_CREATIVE_ROTATION_LOGS = "SET_CREATIVE_ROTATION_LOGS";
export const DOWNLOAD_CREATIVE_ROTATION_LOGS =
  "DOWNLOAD_CREATIVE_ROTATION_LOGS";

export const GET_CREATIVE_VARIANTS_LOGS = "GET_CREATIVE_VARIANTS_LOGS";
export const SET_CREATIVE_VARIANTS_LOGS = "SET_CREATIVE_VARIANTS_LOGS";
export const DOWNLOAD_CREATIVE_VARIANTS_LOGS =
  "DOWNLOAD_CREATIVE_VARIANTS_LOGS";

export const AIGROUP_CREATIVE_ROTATION_STATUS_UPDATE =
  "AIGROUP_CREATIVE_ROTATION_STATUS_UPDATE";

export const ADD_ADSET = "ADD_ADSET";
export const GET_ADSET_BY_ID = "GET_ADSET_BY_ID";
export const SET_ADSET_BY_ID = "SET_ADSET_BY_ID";

export const GET_AD_BY_ADSET_ID = "GET_AD_BY_ADSET_ID";
export const GET_AD_PREVIEW_LINK = "GET_AD_PREVIEW_LINK";
export const GET_AD_SPECIFICATION = "GET_AD_SPECIFICATION";
export const GENERATE_AD_PREVIEW = "GENERATE_AD_PREVIEW";

export const ADD_ELEMENT = "ADD_ELEMENT";
export const GET_ELEMENT_BY_AD_ID = "GET_ELEMENT_BY_AD_ID";
export const GET_ALL_ELEMENTS = "GET_ALL_ELEMENTS";

export const CREATE_VARIANT = "CREATE_VARIANT";
export const GET_VARIANT = "GET_VARIANT";
export const GET_VARIANT_BY_AD_ID = "GET_VARIANT_BY_AD_ID";
export const GET_VARIANT_BY_VARIANT_ID = "GET_VARIANT_BY_VARIANT_ID";
export const UPDATE_VARIANT_BY_ID = "UPDATE_VARIANT_BY_ID";
export const DELETE_VARIANT = "DELETE_VARIANT";
export const DELETE_ALL_VARIANT_BY_ADSET_ID = "DELETE_ALL_VARIANT_BY_ADSET_ID";
export const PUBLISH_VARIANT = "PUBLISH_VARIANT";

export const GET_ALL_BUSINESS_UNITS = "GET_ALL_BUSINESS_UNITS";
export const SET_ALL_BUSINESS_UNITS = "SET_ALL_BUSINESS_UNITS";
export const ADD_BUSINESS_UNIT = "ADD_BUSINESS_UNIT";
export const UPDATE_BUSINESS_UNIT = "UPDATE_BUSINESS_UNIT";
export const GET_BUSINESS_UNIT_BY_ID = "GET_BUSINESS_UNIT_BY_ID";
export const SET_BUSINESS_UNIT_BY_ID = "SET_BUSINESS_UNIT_BY_ID";
export const DELETE_BUSINESS_UNIT_BY_ID = "DELETE_BUSINESS_UNIT_BY_ID";

export const GET_DEFAULT_LEVERS = "GET_DEFAULT_LEVERS";

export const SET_SELECTED_ADACCOUNT_AND_PLATFORM =
  "SET_SELECTED_ADACCOUNT_AND_PLATFORM";
export const SET_SELECTED_AIGROUP = "SET_SELECTED_AIGROUP";
export const SELECTED_AIGROUP_OBJECT = "SELECTED_AIGROUP_OBJECT";

export const GET_USER_INFO = "GET_USER_INFO";
export const SET_USER_INFO = "SET_USER_INFO";

export const ADD_BUSINESS_UNIT_STATE = "ADD_BUSINESS_UNIT_STATE";
export const UPDATE_BUSINESS_UNIT_STATE = "UPDATE_BUSINESS_UNIT_STATE";
export const DELETE_BUSINESS_UNIT_STATE = "DELETE_BUSINESS_UNIT_STATE";

export const SET_CREATIVE_ROTATION_LOGS_DATE_RANGE =
  "SET_CREATIVE_ROTATION_LOGS_DATE_RANGE";

export const SET_CREATIVE_VARIATION_LOGS_DATE_RANGE =
  "SET_CREATIVE_VARIATION_LOGS_DATE_RANGE";

export type SetCreativeRotationDateRange_CreativeAi = {
  type: typeof SET_CREATIVE_ROTATION_LOGS_DATE_RANGE;
  payload: any;
};
export type SetCreativeVariationDateRange_CreativeAi = {
  type: typeof SET_CREATIVE_VARIATION_LOGS_DATE_RANGE;
  payload: any;
};
export type AddBusinessUnitState_CreativeAi = {
  type: typeof ADD_BUSINESS_UNIT_STATE;
  payload: any;
};
export type UpdateBusinessUnitState_CreativeAi = {
  type: typeof UPDATE_BUSINESS_UNIT_STATE;
  payload: any;
};
export type deleteBusinessUnitState_CreativeAi = {
  type: typeof DELETE_BUSINESS_UNIT_STATE;
  payload: any;
};
export type GetAdsList_CreativeAi = {
  type: typeof GET_ADS_LIST;
  payload: any;
};
export type SetAdsList_CreativeAi = {
  type: typeof SET_ADS_LIST;
  payload: any;
};
export type GetAdsetsList_CreativeAi = {
  type: typeof GET_ADSETS_LIST;
  payload: any;
};
export type SetAdsetsList_CreativeAi = {
  type: typeof SET_ADSETS_LIST;
  payload: any;
};
export type GetAigroupList_CreativeAi = {
  type: typeof GET_AIGROUP_LIST;
  payload: any;
};
export type SetAigroupList_CreativeAi = {
  type: typeof SET_AIGROUP_LIST;
  payload: any;
};
export type GetAigroupById_CreativeAi = {
  type: typeof GET_AIGROUP_BY_ID;
  payload: any;
};
export type SetAigroupById_CreativeAi = {
  type: typeof SET_AIGROUP_BY_ID;
  payload: any;
};
export type GetCreativeRotationLogs_CreativeAi = {
  type: typeof GET_CREATIVE_ROTATION_LOGS;
  payload: any;
};
export type DownloadCreativeRotationLogs_CreativeAi = {
  type: typeof DOWNLOAD_CREATIVE_ROTATION_LOGS;
  payload: any;
};
export type SetCreativeRotationLogs_CreativeAi = {
  type: typeof SET_CREATIVE_ROTATION_LOGS;
  payload: any;
};
export type GetCreativeVariantsLogs_CreativeAi = {
  type: typeof GET_CREATIVE_VARIANTS_LOGS;
  payload: any;
};
export type DownloadCreativeVariantsLogs_CreativeAi = {
  type: typeof DOWNLOAD_CREATIVE_VARIANTS_LOGS;
  payload: any;
};
export type SetCreativeVariantsLogs_CreativeAi = {
  type: typeof SET_CREATIVE_VARIANTS_LOGS;
  payload: any;
};
export type AddAdset_CreativeAi = {
  type: typeof ADD_ADSET;
  payload: any;
};
export type GetAdsetById_CreativeAi = {
  type: typeof GET_ADSET_BY_ID;
  payload: any;
};
export type SetAdsetById_CreativeAi = {
  type: typeof SET_ADSET_BY_ID;
  payload: any;
};
export type GetAdByAdsetId_CreativeAi = {
  type: typeof GET_AD_BY_ADSET_ID;
  payload: any;
};
export type GetAdPreviewLink_CreativeAi = {
  type: typeof GET_AD_PREVIEW_LINK;
  payload: any;
};
export type GetAdSpecification_CreativeAi = {
  type: typeof GET_AD_SPECIFICATION;
  payload: any;
};
export type GenerateAdPreview_CreativeAi = {
  type: typeof GENERATE_AD_PREVIEW;
  payload: any;
};
export type AddElement_CreativeAi = {
  type: typeof ADD_ELEMENT;
  payload: any;
};
export type GetElementByAdId_CreativeAi = {
  type: typeof GET_ELEMENT_BY_AD_ID;
  payload: any;
};
export type GetAllElements_CreativeAi = {
  type: typeof GET_ALL_ELEMENTS;
  payload: any;
};
export type CreateVariant_CreativeAi = {
  type: typeof CREATE_VARIANT;
  payload: any;
};
export type GetVariant_CreativeAi = {
  type: typeof GET_VARIANT;
  payload: any;
};
export type GetVariantByAdId_CreativeAi = {
  type: typeof GET_VARIANT_BY_AD_ID;
  payload: any;
};
export type GetVariantByVariantId_CreativeAi = {
  type: typeof GET_VARIANT_BY_VARIANT_ID;
  payload: any;
};
export type UpdateVariantById_CreativeAi = {
  type: typeof UPDATE_VARIANT_BY_ID;
  payload: any;
};
export type DeleteVariant_CreativeAi = {
  type: typeof DELETE_VARIANT;
  payload: any;
};
export type DeleteAllVariantByAdsetId_CreativeAi = {
  type: typeof DELETE_ALL_VARIANT_BY_ADSET_ID;
  payload: any;
};
export type PublishVariant_CreativeAi = {
  type: typeof PUBLISH_VARIANT;
  payload: any;
};
export type GetAllBusinessUnits_CreativeAi = {
  type: typeof GET_ALL_BUSINESS_UNITS;
  payload: any;
};
export type SetAllBusinessUnits_CreativeAi = {
  type: typeof SET_ALL_BUSINESS_UNITS;
  payload: any;
};
export type AddBusinessUnit_CreativeAi = {
  type: typeof ADD_BUSINESS_UNIT;
  payload: any;
};
export type UpdateBusinessUnit_CreativeAi = {
  type: typeof UPDATE_BUSINESS_UNIT;
  payload: any;
};
export type GetBusinessUnitById_CreativeAi = {
  type: typeof GET_BUSINESS_UNIT_BY_ID;
  payload: any;
};
export type SetBusinessUnitById_CreativeAi = {
  type: typeof SET_BUSINESS_UNIT_BY_ID;
  payload: any;
};
export type DeleteBusinessUnitById_CreativeAi = {
  type: typeof DELETE_BUSINESS_UNIT_BY_ID;
  payload: any;
};
export type GetDefaultLevers_CreativeAi = {
  type: typeof GET_DEFAULT_LEVERS;
  payload: any;
};

export type AigroupCreativeRotationStatusUpdate_CreativeAi = {
  type: typeof AIGROUP_CREATIVE_ROTATION_STATUS_UPDATE;
  payload: any;
};

export type SetSelectedAdaccountAndPlatform_CreativeAi = {
  type: typeof SET_SELECTED_ADACCOUNT_AND_PLATFORM;
  payload: any;
};
export type SetSelectedAigroup = {
  type: typeof SET_SELECTED_AIGROUP;
  payload: any;
};
export type SetSelectedAigroupObject = {
  type: typeof SELECTED_AIGROUP_OBJECT;
  payload: any;
};

export type GetUserInfo = {
  type: typeof GET_USER_INFO;
  payload: any;
};
export type SetUserInfo = {
  type: typeof SET_USER_INFO;
  payload: any;
};
