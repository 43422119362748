import CustomizedDialogs from "components/shared/Modal/CustomizedDialogs";
import React, { Fragment, useEffect } from "react";
import { StyledAdvancedConfiguration, StyledModalButton } from "../styled";
import CustomizedButton from "components/shared/Button/CustomizedButton";
import { useForm } from "react-hook-form";
import { Button, InputFormControl, SelectFormControl } from "components/shared";
import { useDispatch, useSelector } from "react-redux";
import { CreativeRotationInitialState } from "store/types/ActionDebugger/CreativeRotation/creativeRotation.types";
import { Box, CircularProgress } from "@mui/material";
import { ActionDebuggerHelperInitState } from "store/types/ActionDebugger/ActionDebuggerHelper";
import {
  getAdvanceSetting,
  postAdvanceSetting,
} from "store/actions/ActionDebugger/CreativeRotation/creativeRotation.actions";
import { AdAccountState } from "store/types/adaccount";
import {
  getRolesHierarchy,
  isEditorRole,
  isViewerRole,
} from "utils/commonFunctions";
import { RolesWrapper } from "utils/rolesWrapper";

function AdvancedConfiguration({
  showAdvanceSettings,
  onAdvanceSettingClose,
}: {
  showAdvanceSettings: boolean;
  onAdvanceSettingClose: () => void;
}) {
  const {
    control,

    reset,
    handleSubmit,
  } = useForm();

  const { advanceSettings } = useSelector(
    (state: { creativeRotationReducer: CreativeRotationInitialState }) => {
      return state.creativeRotationReducer;
    }
  );

  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;

  useEffect(() => {
    reset(advanceSettings.data);
  }, [advanceSettings.data]);

  const {
    selectedAdAccount: {
      selectedAdAcc: { elementId },
    },
  } = useSelector(
    (state: { ActionDebuggerHelperReducer: ActionDebuggerHelperInitState }) =>
      state.ActionDebuggerHelperReducer
  );

  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    dispatch(
      postAdvanceSetting({
        elementId,
        body: data,
      })
    );
    setTimeout(() => {
      onAdvanceSettingClose();
    }, 500);
  };

  useEffect(() => {
    if (elementId) {
      dispatch(getAdvanceSetting(elementId));
    }
  }, [elementId]);

  return (
    <CustomizedDialogs
      type="modal"
      title="Advanced Configuration"
      maxWidth="md"
      isHeightRequired={false}
      show={showAdvanceSettings}
      handleClose={onAdvanceSettingClose}
      titleSize={20}
      subTitleSize={16}
      buttons={
        <RolesWrapper disabled={getRolesHierarchy(brandRoles) === "viewer"}>
          <Button
            variant="contained"
            sx={{
              background: "#3F64FA",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "0.875em",
              lineHeight: "24px",
              borderRadius: "0.5em",
              height: "3em",
              margin: "1em",
              opacity:
                advanceSettings?.isLoading || advanceSettings?.isUpdating
                  ? 0.5
                  : 1,
              pointerEvents:
                advanceSettings?.isLoading || advanceSettings?.isUpdating
                  ? "none"
                  : "all",
            }}
            // disabled={isViewerRole(brandRoles)}
            onClick={handleSubmit(onSubmit)}
          >
            {advanceSettings.isUpdating ? "Submitting..." : "Submit"}
          </Button>
        </RolesWrapper>
      }
    >
      <StyledAdvancedConfiguration>
        {/* <SelectFormControl
          options={["Active ADs", "All ADs"]}
          // field="name"
          // valueKey="value"
          tooltipText="Business unit refers to an independent vertical of the brand. For example, if the brand is a bank, then, personal loan or credit card is a business unit."
          placeholder=""
          fullWidth
          control={control}
          registeredName="ad_type"
          label="Ad Type"
          height="40px"
          sx={{
            fontSize: "0.75em",
          }}
          isBorder
          backgroundColor="#fffffff"
        /> */}
        {advanceSettings?.isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Fragment>
            <InputFormControl
              label="Lookback Period (In Days)"
              tooltipText="The time-period for which you would like to analyse performance data."
              disableUnderline
              control={control}
              registeredName="lookbackPeriod"
              fullWidth
              defaultValue={7}
              placeholder=""
              sx={{ height: "3em", fontSize: "0.75em" }}
              isBorder
              backgroundColor="#fffffff"
              inputType="int"
            />
            <InputFormControl
              label="Maximum Number of Test Ads"
              tooltipText="The total number of test ads (new ads + ads with low data) that you want active at any given point"
              disableUnderline
              control={control}
              registeredName="maxTestAds"
              fullWidth
              defaultValue={3}
              placeholder=""
              sx={{ height: "3em", fontSize: "0.75em" }}
              isBorder
              backgroundColor="#fffffff"
              inputType="int"
            />
            <InputFormControl
              label="Maximum Number of Ads to be Optimised"
              tooltipText="The total number of mature ads (ads with enough data) that you want active at any given point"
              disableUnderline
              control={control}
              registeredName="maxOptimisedAds"
              fullWidth
              defaultValue={3}
              placeholder=""
              sx={{ height: "3em", fontSize: "0.75em" }}
              isBorder
              backgroundColor="#fffffff"
              inputType="int"
            />
          </Fragment>
        )}
      </StyledAdvancedConfiguration>
    </CustomizedDialogs>
  );
}

export default AdvancedConfiguration;
