import * as React from "react";

import { Stack } from "@mui/material";
import BuPlaceHolderImage from "../../../../assets/svg/buPlaceHolder.svg";
import { StyledPlaceholderContainer } from "./styled.MediaStudioPlaceHolder";

const MediaStudioPlaceHolder: React.FC<{}> = () => {
  return (
    <Stack
      direction="row"
      height="30vh"
      justifyContent="center"
      alignItems="center"
    >
      <StyledPlaceholderContainer
        direction="column"
        width="auto"
        justifyContent="center"
        alignItems="center"
      >
        <img src={BuPlaceHolderImage} />
        <h3 className="title">No Media Studio added for this Brand.</h3>
        <p className="description">
          Please click on “Add Media Studio File” on top right to add a new
          Media Studio Unit.
        </p>
      </StyledPlaceholderContainer>
    </Stack>
  );
};
export default MediaStudioPlaceHolder;
