import { ReactComponent as NoData } from "../../../assets/svg/FileNotFound.svg";
import {
  StyledHeadMessageContainer,
  StyledMainMessageContainer,
  StyledNoDataContainer,
} from "./styled";

function NoDataScreen() {
  return (
    <StyledNoDataContainer>
      <NoData height="30em" width="30em" />
      <StyledHeadMessageContainer>
        Oops nothing to show!
      </StyledHeadMessageContainer>
      <StyledMainMessageContainer>
        The ad varinats might have been published or deleted. Kindly check with
        the requester !{" "}
      </StyledMainMessageContainer>
    </StyledNoDataContainer>
  );
}

export default NoDataScreen;
