import {
  DeleteToken,
  EnableMasterAccessToAccounts,
  ENABLE_MASTER_ACCESS_TO_ACCOUNTS,
  GenerateAccessAndRefreshToken,
  GENERATE_ACCESS_AND_REFRESH_TOKEN,
  GetClientIdForSpecificPlatform,
  GetConfigDetailsOfThirdParty,
  GetConfigsOfGAAccount,
  GetEligibilityOfAccounts,
  GetPropertiesOfGAAccount,
  GetThirdPartyAccounts,
  GetTokenStatsSpecificForAdAccounts,
  GetTokenStatusBasedOnBrandId,
  GET_CLIENT_ID_FOR_SPECIFIC_PLATFORM,
  GET_CONFIGS_OF_GA_ACCOUNT,
  GET_CONFIG_DETAILS_OF_THIRD_PARTY_ACCOUNT,
  GET_ELIGIBILITY_OF_ACCOUNTS,
  GET_TOKEN_STATS_SPECIFIC_FOR_AD_ACCOUNTS,
  GET_TOKEN_STATUS_BASED_ON_BRAND_ID,
  LinkAdAccountsToBrands,
  LINK_AD_ACCOUNTS_TO_BRANDS,
  SET_PROPERTIES_OF_GA_ACCOUNT,
  SET_ERROR,
  SET_THIRD_PARTY_ACCOUNTS,
  UpdateConfigDetailsFromAccountId,
  UpdatePrimaryTokenForThirdParty,
  UPDATE_CONFIG_DETAILS_FROM_ACCOUNT_ID,
  UPDATE_PRIMARY_TOKEN_FOR_THIRD_PARTY,
  UUIDTOKEN,
} from "../types/thirdPartyChannels";

const initialState = {};

export default function thirdPartyChannels(
  state = initialState,
  action:
    | GetClientIdForSpecificPlatform
    | GetEligibilityOfAccounts
    | GetTokenStatsSpecificForAdAccounts
    | GetTokenStatusBasedOnBrandId
    | GetPropertiesOfGAAccount
    | GetConfigsOfGAAccount
    | GetConfigDetailsOfThirdParty
    | GenerateAccessAndRefreshToken
    | LinkAdAccountsToBrands
    | EnableMasterAccessToAccounts
    | UpdatePrimaryTokenForThirdParty
    | UpdateConfigDetailsFromAccountId
    | DeleteToken
    | UUIDTOKEN
    | GetThirdPartyAccounts
) {
  switch (action.type) {
    case GET_CLIENT_ID_FOR_SPECIFIC_PLATFORM: {
      return { ...state, clientIdForSpecificPlatform: action.payload };
    }
    case GET_ELIGIBILITY_OF_ACCOUNTS: {
      return { ...state, eligibilityOfAccount: action.payload };
    }
    case GET_TOKEN_STATS_SPECIFIC_FOR_AD_ACCOUNTS: {
      return { ...state, tokenStatsForAdAccounts: action.payload };
    }
    case GET_TOKEN_STATUS_BASED_ON_BRAND_ID: {
      return { ...state, tokenStatusOfBrandId: action.payload };
    }
    case SET_PROPERTIES_OF_GA_ACCOUNT: {
      return { ...state, propertisOfGaAccount: action.payload };
    }
    case GET_CONFIGS_OF_GA_ACCOUNT: {
      return { ...state, configsOfGaAccount: action.payload };
    }
    case GET_CONFIG_DETAILS_OF_THIRD_PARTY_ACCOUNT: {
      return { ...state, configDetailsOfThirdPartyAccount: action.payload };
    }
    case GENERATE_ACCESS_AND_REFRESH_TOKEN: {
      return { ...state, generateAccessAndRefreshToken: action.payload };
    }
    case LINK_AD_ACCOUNTS_TO_BRANDS: {
      return { ...state, linkAdAccountsToBrand: action.payload };
    }
    case ENABLE_MASTER_ACCESS_TO_ACCOUNTS: {
      return { ...state, enableMasterAccessToAccounts: action.payload };
    }
    case UPDATE_PRIMARY_TOKEN_FOR_THIRD_PARTY: {
      return { ...state, updatePrimaryToken: action.payload };
    }
    case UPDATE_CONFIG_DETAILS_FROM_ACCOUNT_ID: {
      return { ...state, updateConfigDetailsFromAccountId: action.payload };
    }
    case "UIDTOKEN": {
      return { ...state, UIDTOKEN: action.payload };
    }

    case SET_THIRD_PARTY_ACCOUNTS: {
      return { ...state, googleAnalyticsData: action.payload };
    }
    case SET_ERROR: {
      return { ...state, error: action.payload };
    }
    default:
      return state;
  }
}
