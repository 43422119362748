import Modal from ".";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/close.svg";
import Button from "../../../../ui/ButtonUI/ButtonUI";
import { Content, StyledHeaderTitle } from "./NewVariant/styled";

export function DeletConfirmation({
  message,
  header = "",
  handleCancel,
  handleConfirm,
}: any) {
  return (
    <Modal
      header={
        <>
          <StyledHeaderTitle>{header}</StyledHeaderTitle>
          <Button className="close" onClick={handleCancel}>
            <CloseIcon />
          </Button>
        </>
      }
      footer={
        <>
          <button onClick={handleCancel} className="transparent">
            Cancel
          </button>
          <button onClick={handleConfirm} className="primary">
            Delete
          </button>
        </>
      }
    >
      <Content>{message}</Content>
    </Modal>
  );
}
