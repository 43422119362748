import React, { useEffect } from "react";
import { Handle, useReactFlow } from "react-flow-renderer";
import { ReactComponent as LoaderCollapsed } from "./Assets/svgs/loaderCollapsed.svg";

function LoaderKeywordTuningCollapsed({ data }) {
  return (
    <div className="loaderCollapse">
      <Handle type="target" position="left" />
      <Handle type="source" position="right" />
      <div>
        <LoaderCollapsed />
      </div>
    </div>
  );
}

export default LoaderKeywordTuningCollapsed;
