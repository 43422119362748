import moment, { Moment } from "moment-timezone";

export const ddMMYYDisplayDate = (date: Date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const dateFormatDayAndMonth = (date: Date) => {
  return moment(date).format("MMM DD");
};
export const ddMMNDisplayDate = (date: Date) => {
  return moment(date).format("MMM DD");
};

export const dayDateMonYearDisplayDate = (date: Date) => {
  return moment(date).format("dddd, MMM DD YYYY");
};

export const yyyyMMDDFormat = (date: Date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const getStatusBetweenDates = (startDate: Date, endDate: Date) => {
  const currentDate = moment();
  return currentDate.isBetween(moment(startDate), moment(endDate));
};

export const localTimeFormat = (date: Date) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment(date).tz(timeZone).format("Do MMM YYYY, h:mm a z");
};

export const localTimeFormatNew = (date: Date) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment(date).tz(timeZone).format("Do MMM YYYY");
};

export const MMMDDYYYYFormat = (date: Date | string) => {
  return moment(date).format("MMM DD,YYYY");
};

export const getFirstSequentialDaysRange = (
  startDate: Date,
  blockedList: Date[],
  noofDaysRequired: number
) => {
  let AvailableDates = [];
  while (AvailableDates.length < noofDaysRequired) {
    let nextDate = moment(startDate).add(1, "days").toDate();
    if (
      blockedList.filter((d) => nextDate.toDateString() === d.toDateString())
        .length
    ) {
      AvailableDates = [];
    } else {
      AvailableDates.push(nextDate);
    }
    startDate = nextDate;
  }
  return AvailableDates;
};

export const checkDifferenceBetweenDates = (
  startDate: Date | undefined | string,
  endDate: Date | undefined | string,
  range: number
) => {
  const start = moment(startDate);
  const end = moment(endDate);
  let difference = end.diff(start, "days");
  return difference >= range ? true : false;
};

export const checkIsBeforeToday = (date: Moment, today: Moment) => {
  return date.diff(today, "days") <= 0;
};

export const isLastDate = (date: Moment, endDate: Moment) => {
  return endDate.diff(date, "days") === 1;
};

export const calcualteTimeOffset = (seconds: number) => {
  let hours = parseInt((seconds / 60 / 60).toString());
  let minutes = Math.abs(seconds / 60 - hours * 60);
  return `${
    hours > 0
      ? "+" + (hours < 10 ? "0" + hours : hours)
      : hours < 10
      ? "-0" + Math.abs(hours)
      : hours
  }:${minutes < 10 ? "0" + minutes : minutes}`;
};

export const crTimezone = (date: Date | string) => {
  return moment(date).format("DD MMM, YYYY HH:mm");
};
