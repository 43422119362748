import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setModal } from "../../store/actions/form";
import { ClusterState } from "../../store/types/cluster";
import { FormState } from "../../store/types/form";
import { CreateAdAndPublish, keywordsClustering, uploadKeywordPath } from "../../utils/constants/GSAI";
import Loader from "../common/Loader";
import ModalContainer from "../Modal/ModalContainer";
import ModalWrapper from "../Modal/ModalWrapper";
import "./index.scss";

const StepwiseFormContainer: React.FC<{
  handleClose?: () => void;
  handleSubmit?: () => void;
  steps: string[];
  loading?: boolean;
  initialActive?: number;
  disabled?: boolean;
}> = ({
  children,
  handleSubmit,
  handleClose,
  steps,
  loading,
  initialActive = 1,
  disabled,
}): JSX.Element => {
  const [activeStep, setActiveStep] = React.useState(initialActive);
  const history = useHistory();
  const dispatch = useDispatch();
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { modal } = formState;
  const clusterState = useSelector((state: { cluster: ClusterState }) => state.cluster);
  const { clusterTable } = clusterState;



  const handleChange = useCallback(
    (value) => {
      setActiveStep(value);
      let path = "";
      switch (value) {
        case 1:
          path = `/googlesearchai/${keywordsClustering}${history.location.search}`;
          break;
        case 2:
          path = `/googlesearchai/${CreateAdAndPublish}${history.location.search}`;
          break;
        default:
          path = `/googlesearchai/${keywordsClustering}${history.location.search}`;
          break;
      }
      history.push(path);
    },
    [history]
  );

  return (
    <>
      <div className="stepwise-form d-flex flex-column p-4 bg-white message-container mb-2">
        <div className="steps-data d-flex justify-content-center align-items-center">
          {steps.map((step, stepIndex) => (
            <div key={stepIndex}
              className={`step d-flex justify-content-around ${
                stepIndex !== steps.length - 1 ? "dash-lines" : ""
              }
                ${activeStep >= stepIndex + 1 ? "active-step" : ""}`}
              onClick={() => {
                if(stepIndex === 1) return
                if (activeStep - 2 === stepIndex) {
                  dispatch(setModal({ key: "reverseStep", title: "Cluster Step Change",stepIndex: stepIndex }));
                } else {
                  if ((stepIndex + 1 === activeStep + 1 || stepIndex + 1 === activeStep - 1) && clusterTable?.length>0){
                  handleChange(stepIndex + 1);
                  }
                }
              }}
            >
              <div className="step-number">
                <span className="number">{stepIndex + 1}</span>
              </div>
              <div className="step-name">{step}</div>
            </div>
          ))}
        </div>
      </div>
      {loading && (
        <div className="position-absolute d-flex align-items-center justify-content-center w-100 h-100">
          <Loader />
        </div>
      )}
      {children}
      <div className="stepwise-submit d-flex p-4 bg-white message-container mt-2 align-items-end">
        <button
          type="button"
          className="btn secondary ml-auto"
          onClick={handleClose}
        >
          Close
        </button>
        <button
          type="button"
          className="btn primary width-secondary"
          disabled={loading || disabled}
          onClick={handleSubmit}
        >
          {activeStep === 2 ? "Publish" : "Continue"}
        </button>
      </div>
      {/* Modal opens on closing drawer */}
      {modal && modal.key === "reverseStep" && (
        <ModalWrapper>
          <ModalContainer
            title={modal?.title || "Close cluster creator"}
            formDisable={false}
            submitText={"Yes"}
            handleSubmit={() => {
              dispatch(setModal(null));
              if (modal.stepIndex + 1 === activeStep + 1 || modal.stepIndex + 1 === activeStep - 1)
                  handleChange(modal.stepIndex + 1);
            }}
            handleClose={() => {
              dispatch(setModal(null));
            }}
          >
            <p>
              All data in the current step will be deleted. Are you sure you want proceed?
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
    </>
  );
};

export default StepwiseFormContainer;
