import { Box } from "@mui/material";
import { Button } from "components/shared";
import { ReactComponent as ArrowLeft } from "../../../../assets/svg/arrowLeft.svg";
import { ReactComponent as Image } from "../../../../assets/svg/refresh.svg";

interface MediaStudioTestingButtonsProps {
  setIsRunTest: (val: boolean) => void;
  isError?: boolean;
  onRefreshClick: any;
}
function MediaStudioTestingButtons({
  setIsRunTest,
  isError,
  onRefreshClick,
}: MediaStudioTestingButtonsProps) {
  return (
    <Box display={"flex"} minWidth="100%" justifyContent="space-between">
      <Button
        onClick={() => {
          setIsRunTest(false);
        }}
        buttonVariant="text"
        sx={{
          padding: ".75rem 0.5rem .75rem 0.5rem",
          fontFamily: "Inter",
          fontWeight: "600",
          color: "#0869FB",
        }}
      >
        <ArrowLeft style={{ marginRight: "0.5em" }} /> Go Back
      </Button>{" "}
      {isError && (
        <Button
          variant="outlined"
          onClick={onRefreshClick}
          sx={{
            padding: "0em 1em",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "0.875em",
            color: "#0869FB",
            border: `0.125em solid #0869FB`,
            borderRadius: "0.5em",
            ":hover": {
              border: `0.125em solid #0869FB`,
              borderRadius: "0.5em",
            },
          }}
        >
          <Image className="mr-1" />
          Refresh
        </Button>
      )}
    </Box>
  );
}

export default MediaStudioTestingButtons;
