import { styled } from "@mui/material";
import { Box } from "@mui/system";

export const StyledPerformanceSummaryItemCard = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 0px 3px #d9e4f4;
  border-radius: 0.625em;
  padding: 1em 0.8125em 0.875em 0.5em;
  flex: 1;
  width: 31%;
`;

export const StyledPerformanceSummaryText = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 0.9375em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #257afb;
  max-lines: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledPerformanceMetricText = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 0.875em;
  line-height: 1.05875em;
  color: #454256;
`;
