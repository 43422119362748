import { mixPanelAdEvent } from "../../../utils/mixpanel";

export const mixPanelInputCommonFunction = (
  eventName: string,
  brandName: string,
  agencyName: string,
  params?: any
) => {
  mixPanelAdEvent(eventName, {
    brand_name: brandName,
    agency_name: agencyName,
    module_name: "Facebook Playground",
    ...params,
  });
};
