import { Box } from "@mui/material";
import moment from "moment";
import { Cell_Day, Days, PacingType } from "../Types";
import { BudgetPlannerDay } from "./BudgetPlannerDay/BudgetPlannerDay";
import "./style.scss";

export const BudgetPlannerCalendar: React.FC<{
  calendarDays: Cell_Day[][];
  selectDate: Function;
  selectedPacing: PacingType | null;
  updateCalendarDay: Function;
  selectedMode: number;
  selectMultipleDates: Function;
  calDifference: Function;
  handleUnlockBuchgetDistribution: (
    weekIndex: number,
    dayIndex: number
  ) => void;
  getAllCalendarDaysForSelectedPacing: any;
  showNew: any;
  festivalDays: any;
  selectedBrand: any;
}> = ({
  calendarDays,
  selectDate,
  selectedPacing,
  updateCalendarDay,
  selectedMode,
  selectMultipleDates,
  calDifference,
  handleUnlockBuchgetDistribution,
  getAllCalendarDaysForSelectedPacing,
  showNew,
  festivalDays,
  selectedBrand,
}): JSX.Element => {
  return (
    <Box style={{ width: "958px", maxWidth: "958px", margin: "0 auto" }}>
      <div
        className="budget-planner-calendar"
        style={{
          overflow: "scroll",
          maxHeight: "70vh",
          border: "1.22159px solid #F0F0F0",
        }}
      >
        <div>
          <div>
            <div
              className="light-bg flex-center justify-content-between"
              style={{ maxWidth: "958px" }}
            >
              <div className="border-y empty-cell">NAN</div>
              {Days.map((day: string, dayIndex: number) => (
                <div
                  className="border-y text-center header-cell"
                  onClick={() => {
                    selectMultipleDates("days", dayIndex);
                  }}
                >
                  {day}
                </div>
              ))}
            </div>
          </div>
          <div>
            {calendarDays.map((week: any, weekIndex: number) => {
              return (
                <div className="flex-center">
                  <div
                    className="border-y vertical-text"
                    onClick={() => {
                      selectMultipleDates("week", weekIndex);
                    }}
                  >
                    {" "}
                    <span>
                      Week{" "}
                      {moment(
                        week.find((day: any) => (day ? day.fullDateString : ""))
                          .fullDateString,
                        "YYYY-MM-DD"
                      ).week()}
                    </span>
                  </div>
                  {week.map((day: any, dayIndex: number) => {
                    return (
                      <BudgetPlannerDay
                        day={day}
                        isPacingNameShow={
                          day?.name
                            ? (dayIndex === 0 && day) ||
                              day?.startDate?.substr(0, 10) === day?.date ||
                              day?.day === 1
                            : false
                        }
                        selectDate={selectDate}
                        dayIndex={dayIndex}
                        weekIndex={weekIndex}
                        selectedPacing={selectedPacing}
                        updateCalendarDay={updateCalendarDay}
                        selectedMode={selectedMode}
                        calDifference={calDifference}
                        handleUnlockBuchgetDistribution={
                          handleUnlockBuchgetDistribution
                        }
                        getAllCalendarDaysForSelectedPacing={
                          getAllCalendarDaysForSelectedPacing
                        }
                        showNew={showNew}
                        festivalDays={festivalDays}
                        selectedBrand={selectedBrand}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Box>
  );
};
