import {
  GET_OPEN_SEARCH_AI_DATA,
  SubmitManualAction,
  SUBMIT_MANUAL_ACTION_FORM_DATA,
  SubmitFileManualAction,
  SUBMIT_FILE_MANUAL_ACTION,
  SUBMIT_FORM_DATA_STATE,
  SubmitFileForRecommendationDV360,
  SUBMIT_FILE_RECOMMENDATION_FOR_DV360,
  DownloadCSVForRecommendationsDV360,
  DOWNLOAD_CSV_RECOMMENDATION_FOR_DV360,
} from "../types/manualaction";

export const submitManualActionData = (payload: any): SubmitManualAction => {
  return {
    type: SUBMIT_MANUAL_ACTION_FORM_DATA,
    payload,
  };
};
export const getOpenSearchAIData = (payload: any): any => {
  return {
    type: GET_OPEN_SEARCH_AI_DATA,
    payload,
  };
};
export const submitFileManualAction = (
  payload: any
): SubmitFileManualAction => ({
  type: SUBMIT_FILE_MANUAL_ACTION,
  payload,
});



export const resetFormDataState = (): any => ({
  type: SUBMIT_FORM_DATA_STATE,
  payload: {
    loading: false,
    success: "",
    error: "",
  },
});

//Upload CSV file for actions in upload csv for platform dv360 yt partner video
export const submitFileForRecommendationDV360 = (
  payload:any,
  callback:Function
): SubmitFileForRecommendationDV360 => ({
  type:SUBMIT_FILE_RECOMMENDATION_FOR_DV360,
  payload,
  callback
})

//Download CSV file for action in download csv for platform dv360 yt partner video
export const downloadFileForRecommendationDV360 = (payload:any,callback:Function): DownloadCSVForRecommendationsDV360 => ({
  type:DOWNLOAD_CSV_RECOMMENDATION_FOR_DV360,
  payload,
  callback
})