import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Handle, Position, useReactFlow } from "react-flow-renderer";

import Box from "@mui/material/Box";

import LoaderContainer from "../../common/LoaderContainer";
import LoaderRightStrip from "../../common/LoaderRightStrip";

import { ReactComponent as BrandKeyword } from "../Assets/svgs/keywordTuning/BrandKeyword.svg";
import { ReactComponent as GenericKeyword } from "../Assets/svgs/keywordTuning/GenericKeyword.svg";
import { ReactComponent as LateralKeyword } from "../Assets/svgs/keywordTuning/LateralKeyword.svg";
import { ReactComponent as CustomKeywordsKeyword } from "../Assets/svgs/keywordTuning/CustomKeywordsKeyword.svg";
import { ReactComponent as KeywordTuningKeyword } from "../Assets/svgs/keywordTuning/KeywordTuningKeyword.svg";
import { ReactComponent as Line } from "../Assets/svgs/str-line.svg";
import { ReactComponent as PersonaTunerTarget } from "../Assets/svgs/personaTunerTarget.svg";
import { ReactComponent as PersonaTunerSingleIcon } from "../Assets/svgs/personaTunerSingleIcon.svg";

import { keywordTuningData, personaTuningData } from "../constants/loader";
import { mixPanelInputCommonFunction } from "../../utils/MixpanelInputCommonFunction";
import { usePolling } from "../../hooks/usePolling";
import {
  getTargetingAIKWS,
  setCalibrationData,
  setPolling,
} from "../../../../store/actions/targetingAI";
import { TaiContext } from "Pages/TargetingAIPlaygroundBeta/TaiContextProvider";
import PersonaTunerLoaderStep from "Pages/TargetingAIPlaygroundBeta/common/PersonaTunerLoaderStep";
import { getTargetingAIKWSICP } from "store/actions/targetingICP";
import { getJSONDataFromURL } from "utils/jsonFromURL";
import useFocusToNode from "Pages/TargetingAIPlaygroundBeta/useFocusToNode";
import { CommonState } from "store/types/common";

export default function LoaderKeywordTuning({
  data,
  isConnectable,
}: {
  data: any;
  isConnectable: boolean;
}) {
  const dispatch = useDispatch();
  const { setNodes, setEdges, callibrationData, cohortType } = useContext(
    TaiContext
  ) as any;

  const calibrationData = useSelector(
    (state: any) => state.calibration.apiData
  );
  const polling = useSelector((state: any) => state.taiCommon.polling);
  const adaccount = useSelector((state: any) => state.adaccount);
  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;
  const { selectedAgency } = useSelector(
    (state: { common: CommonState }) => state.common
  );
  const currentPage = useSelector((state: any) => state.taiCommon.currentPage);
  const loaderState = useSelector((state: any) => state.taiCommon.loader);
  const { TypeOfCohort } = useSelector(
    (state: any) => state.calibration.formData
  );
  const aiInitiation = useSelector((state: any) => state.aiInitiation.apiData);
  const aiInitiationFormData = useSelector(
    (state: any) => state.aiInitiation.formData
  );

  const [loadingId, setLoadingId] = useState(0);

  const focusToNode = useFocusToNode();

  const { setStartPolling } = usePolling(2000, (error: boolean) => {
    if (error) {
      //error
      return;
    }
    const cloneCalibrationData={...calibrationData}
    delete cloneCalibrationData.loadedKeywords
    if (polling.requestId !== "" && Object.keys(cloneCalibrationData).length === 0) {
      if (TypeOfCohort === "ICP") {
        delete calibrationData.loadedKeywords
        dispatch(
          getTargetingAIKWSICP(
            polling.requestId,
            async (res: any, err: any) => {
              const status = res?.data?.status_list[0].status;
              if (status === "failed") {
                setStartPolling(false);
                dispatch(setPolling({ name: "", requestId: "" }));
                data.onClick("failed");
              }
              if (!err && status === "completed") {
                setStartPolling(false);
                dispatch(setPolling({ name: "", requestId: "" }));
                // let jsonData = await getJSONDataFromURL(
                //   res.data.status_list[0].result?.json_url
                // );
                const jsonData = res.data.status_list[0].result_json_decoded;
                dispatch(
                  setCalibrationData({
                    ...jsonData,
                    ...aiInitiationFormData.apiData,
                  })
                );
              }
              // let url =
              //   "https://targeting-ai.s3.ap-south-1.amazonaws.com/icp_keywords_recms/v0_1_0/requests/985273a1-71b2-49c9-b8c3-0979e2cca3cc.json";
              // setStartPolling(false);
              // dispatch(setPolling({ name: "", requestId: "" }));
              // let jsonData = await getJSONDataFromURL(url);
              // dispatch(setCalibrationData(jsonData));
            }
          )
        );
      } else {
        dispatch(
          getTargetingAIKWS(polling.requestId, (res: any, err: any) => {
            const status = res?.data?.message?.status_list[0].status;
            if (status === "FAILED") {
              setStartPolling(false);
              dispatch(setPolling({ name: "", requestId: "" }));
              data.onClick("failed");
            }
            if (!err && status === "COMPLETED") {
              setStartPolling(false);
              dispatch(setPolling({ name: "", requestId: "" }));
              dispatch(
                setCalibrationData({...res.data.message.status_list[0].result,
                  Brand:res.data.message.status_list[0].result.all_keywords.Brand.slice(0,7),
                  Lateral:res.data.message.status_list[0].result.all_keywords.Lateral.slice(0,7),
                  Generic:res.data.message.status_list[0].result.all_keywords.Generic.slice(0,7),
                  Custom:res.data.message.status_list[0].result.all_keywords.Custom,
                  loadedKeywords: {
                    brandLoadedKeywords: 7,
                    lateralLoadedKeywords: 7,
                    genericLoadedKeywords: 7,
                  }
                  })
              );
            }
          })
        );
      }
    }
  });

  useEffect(() => {
    if (polling.name === "calibration" || polling.name === "icp") {
      setStartPolling(true);
    }
  }, [polling, setStartPolling]);

  useEffect(() => {
    const cloneCalibrationData={...calibrationData}
    delete cloneCalibrationData.loadedKeywords
    if (currentPage > data.index) {
      setLoadingId(4);
    } else {
      if (loadingId < 3 && currentPage === data.index) {
        setTimeout(() => {
          setLoadingId(loadingId + 1);
        }, 2000);
      } else if (Object.keys(cloneCalibrationData).length > 0 && loadingId === 3) {
        setLoadingId(4);
      }
    }
  }, [loadingId, calibrationData]);

  useEffect(() => {
    if (currentPage <= data.index) setLoadingId(0);
  }, [callibrationData]);

  const KWcontinueClicked = () => {
    // focus to next node only on continue click
    data.loaderContinue(dispatch, loaderState, setNodes, setEdges, focusToNode);
    mixPanelInputCommonFunction(
      "TAI Keyword Loader Clicked",
      selectedBrand?.name,
      selectedAgency?.name,
      {
        brand_id: selectedBrand?.id,
        agency_id: selectedBrand?.agencyId,
        ai_group_id: aiInitiationFormData?.selectedAiGroup?.id,
        ai_group_name: aiInitiationFormData?.selectedAiGroup?.name,
        ad_account_name: aiInitiation?.adAccount?.name,
        ad_account_id: aiInitiation?.adAccount?.id,
        tai_flow: cohortType,
      }
    );
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div
        className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex focus:outline-none w-100 h-100"
        style={{
          position: "absolute",
          zIndex: "100",
        }}
      >
        <LoaderContainer
          steps={
            TypeOfCohort === "ICP"
              ? personaTuningData.steps
              : keywordTuningData.steps
          }
          loadingId={loadingId}
          continueProps={{
            className: currentPage === data.index ? "" : "d-none",
            onClick: KWcontinueClicked,
            disabled: loadingId < 4,
          }}
          closeProps={{
            className: currentPage > data.index ? "" : "d-none",
            onClick: () =>
              data.loaderContinue(dispatch, loaderState, setNodes, setEdges),
          }}
          loaderGifProps={{
            src:
              TypeOfCohort === "ICP"
                ? personaTuningData.gif
                : keywordTuningData.gif,
            alt: "KeywordTuining",
            style: { height: "14rem", width: "12.5rem" },
          }}
          header={TypeOfCohort === "ICP" ? "Persona Tuner" : "Keyword Tuning"}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Line />
            {TypeOfCohort === "ICP" ? (
              <>
                <PersonaTunerLoaderStep
                  gap={4}
                  width={"100%"}
                  mainItem={[
                    {
                      title: "Ideal Customer Profile",
                      text: "An Ideal Customer Persona is a detailed representation of a business's perfect customer, helping tailor products, marketing, and customer experience to meet their specific needs and preferences.In Persona Tuner you can edit suggested persona customised to you. It consists following ",
                      svg: <PersonaTunerTarget />,
                    },
                  ]}
                  items={[
                    {
                      title: "Age and Gender",
                      text: "Configure the extent of exploring Compeititor intelligence-driven audiences on a scale of 0-100%",
                      svg: <PersonaTunerSingleIcon />,
                    },
                    {
                      title: "Interest Keywords",
                      text: "Configure the extent of exploring Compeititor intelligence-driven audiences on a scale of 0-100%",
                      svg: <PersonaTunerSingleIcon />,
                    },
                    {
                      title: "Demographics",
                      text: "Configure the extent of exploring Compeititor intelligence-driven audiences on a scale of 0-100%",
                      svg: <PersonaTunerSingleIcon />,
                    },
                    {
                      title: "Behaviour",
                      text: "Configure the extent of exploring Compeititor intelligence-driven audiences on a scale of 0-100%",
                      svg: <PersonaTunerSingleIcon />,
                    },
                  ]}
                />
                <Line />
              </>
            ) : (
              <>
                <LoaderRightStrip
                  gap={4}
                  items={[
                    {
                      title: "Brand",
                      text: "This bucket will contain Keywords related to brand and their competitors.",
                      svg: <BrandKeyword />,
                    },
                    {
                      title: "Generic",
                      text: "This bucket will contain Keywords related to brand offerings and industry of the brand.",
                      svg: <GenericKeyword />,
                    },
                    {
                      title: "Lateral",
                      text: "This bucket will contain keywords that are not directly related to your brand and industry.",
                      svg: <LateralKeyword />,
                    },
                    {
                      title: "Custom Keywords",
                      text: "You can set keywords around a specific event to get the recommendations based on it.",
                      svg: <CustomKeywordsKeyword />,
                    },
                  ]}
                />
                <Line />

                <Box className="d-flex justify-content-center align-items-center h-100">
                  <LoaderRightStrip
                    width="18rem"
                    items={[
                      {
                        title: "Keyword Tuning",
                        text: "Slide the bar to give weightage to the data points fetched from the competitor website audience.",
                        svg: <KeywordTuningKeyword />,
                      },
                    ]}
                  />
                </Box>
              </>
            )}
          </Box>
        </LoaderContainer>
      </div>
    </>
  );
}
