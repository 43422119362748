import {
  StyledFooter,
  StyledLink,
  StyledMediaCard,
  StyledMediaCardContent,
  StyledMediaStudioHeader,
  StyledMediaStudioImage,
  StyledMediaStudioSubTitle,
} from "./styled";
import NoRecommendationsImg from "../../../../../../../src/assets/png/NoRecommendations.png";
import { Box } from "@mui/material";
// import NoRecommendationsImg from "../../../../../../../src/assets/png/NoRecommendations.png";

interface NoRecommendationsProps {}

const NoRecommendations = ({}: NoRecommendationsProps) => {
  return (
    <Box
      sx={{
        height: "75vh",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <StyledMediaCard>
        <StyledMediaCardContent>
          <StyledMediaStudioImage
            src={NoRecommendationsImg}
            style={{ alignSelf: "center" }}
          />
          <StyledMediaStudioHeader>
            {" "}
            Seems like there are no recommendations to be shown, Sorry !
          </StyledMediaStudioHeader>
          <StyledMediaStudioSubTitle>
            Please try again after a while and if the issue persists, please
            reach out to our customer support team.
          </StyledMediaStudioSubTitle>
        </StyledMediaCardContent>
      </StyledMediaCard>
    </Box>
  );
};

export default NoRecommendations;
