export const GENERATE_BILLBOARD_LINK = "GENERATE_BILLBOARD_LINK";
export const GET_BILLBOARD_VIDEO_URL = "GET_BILLBOARD_VIDEO_URL";
export const CANCEL_GET_BILLBOARD_API = "CANCEL_GET_BILLBOARD_API";

export interface GenerateBillboardLink {
  type: typeof GENERATE_BILLBOARD_LINK;
  payload: GenerateBillboardLinkPayloadType;
}

export interface GetBillboardVideoUrl {
  type: typeof GET_BILLBOARD_VIDEO_URL;
  payload: GetBillboardVideoUrlPayloadType;
}

export interface GenerateBillboardLinkPayloadType {
  body: {
    adId?: string;
    adSetId?: string;
    adAccountId?: string;
    creativeImage: string;
    brandId: string;
    ctaText: string;
    headline: string;
    description: string;
  };
  callback: (response: BillboardLinkCallbackResponseType) => void;
}

export interface GetBillboardVideoUrlPayloadType {
  requestId: string;
  callback: (response: GetBillboardVideoUrlResponseType) => void;
}

export interface BillboardLinkCallbackResponseType {
  data?: {
    id: string;
    redirectUrl: string;
  };
  isLoading: boolean;
  isFailed: boolean;
  cancel?: boolean;
}

export interface GetBillboardVideoUrlResponseType {
  data?: {
    id: string;
    videoUrl: string;
  };
  isLoading: boolean;
  isFailed: boolean;
}
