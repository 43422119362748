export const SET_ERROR = "SET_ERROR";
export const RESET_ERROR = "RESET_ERROR";
export const SET_ERROR_SELECTED_BRAND = "SET_ERROR_SELECTED_BRAND";
export const RESET_ERROR_SELECTED_BRAND = "RESET_ERROR_SELECTED_BRAND";

export const errorTypes = {
  GET_AGENCIES: "GET_AGENCIES",
  GET_ALL_BRAND_DETAILS: "GET_ALL_BRAND_DETAILS",
  GET_BRAND_METRICS: "GET_BRAND_METRICS",
  GET_OPTIMIZER_DETAILS: "GET_OPTIMIZER_DETAILS",
};

export type SetError = {
  type: typeof SET_ERROR;
  payload: any;
};

export type SetErrorForAgencies = {
  type: typeof SET_ERROR;
  payload: { type: typeof errorTypes.GET_AGENCIES; message: any };
};

export type SetErrorForAllBrandDetails = {
  type: typeof SET_ERROR;
  payload: { type: typeof errorTypes.GET_ALL_BRAND_DETAILS; message: any };
};

export type SetErrorForBrandMetrics = {
  type: typeof SET_ERROR;
  payload: { type: typeof errorTypes.GET_BRAND_METRICS; message: any };
};

export type ResetError = {
  type: typeof RESET_ERROR;
};

export type SetErrorForGetOptimizerDetails = {
  type: typeof SET_ERROR_SELECTED_BRAND;
  payload: { type: typeof errorTypes.GET_OPTIMIZER_DETAILS; message: any };
};
