import React from 'react'
import CheckBox from './Checkbox'

function GenerationType({ dataList, values, name, register, errors, type }: any) {
  return (
    <>
      <div>
        {(type==="descriptions" ? 'Description' : 'Headline') + ' Generation Type '}
        {((errors?.generation_type?.descriptions && type==="descriptions")
          || (errors?.generation_type?.headlines && type==="headlines")) ? (
          <span>
            (<span className='error'>Select atleast one</span>)
          </span>
        ) : null}
      </div>
      {dataList.map(({ key, label, placeholder = '' }: Record<string, string>) => {
        let hasError = false;
        if (errors?.[key]?.[type]) {
          hasError = true;
        }

        const className = 'type-input ' + (hasError ? 'type-input-error' : '');

        return (
          <div className='generation-type' key={key}>
            <CheckBox name={`${name}.${key}`} register={register} label={label} />
            {placeholder && values && values[key] ? (
              <input
                {...register(`${name}.${key}Value`)}
                type='text'
                className={className}
                placeholder={placeholder}
              />
            ) : null}
          </div>
        )
      })}
    </>
  )
}

export default GenerationType
