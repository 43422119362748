import { Box } from "@mui/material";
import { StyledText } from "components/shared";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPresignedUrl } from "store/actions/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import { ThumbnailItemType } from "../../MediaStudioTesting";
import ImageComponent from "../ImageComponent/ImageComponent";

export default function ThumbnailComponent({
  item,
  index,
}: {
  item: ThumbnailItemType;
  index?: number;
}) {
  // do not remove it is a alternate solution

  // const [loading, setLoading] = useState(true);
  // const [mediaUrl, setMediaUrl] = useState("");
  // const [isFailed, setIsFailed] = useState(false);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(
  //     getPresignedUrl({
  //       mediaId: id,
  //       type: "preview",
  //       finalAction: (data: any, error: boolean) => {
  //         if (error) {
  //           setIsFailed(true);
  //         }

  //         setMediaUrl(data?.url);
  //         setLoading(false);
  //       },
  //     })
  //   );
  // }, []);

  return (
    <Box display="flex" flexDirection="column" pb="24px">
      {index !== undefined && (
        <StyledText lineHeight="20px" fontSize="14px" pb="8px">
          Frame {index + 1}
        </StyledText>
      )}
      <ImageComponent {...item} />
    </Box>
  );
}
