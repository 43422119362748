import React from "react";
import { useDispatch } from "react-redux";
import { setAiInitiationValue } from "store/actions/targetingAI";
import taiDropdownCheckmark from "../../../assets/tai/taiDropdownCheckmark.svg";
import SearchComponent from "Pages/SmbDashboard/common/SearchComponent";

function TAIDropdown({
  optionsList,
  dropdown,
  selectedAiGroup,
  selectedCountry,
  selectedRegion,
  searchBar = true,
  multiselect = false,
  classes,
  loading,
  customDropdown = false,
  selectedAudience,
  setSelectedAdAccount,
}) {
  const dispatch = useDispatch();
  const [dropdownOptions, setDropdownOptions] = React.useState(optionsList);
  const [filterText, setFilterText] = React.useState("");

  const handleSearch = (filterText) => {
    setFilterText(filterText);
    const filteredOptionsList = {};
    for (let item in optionsList) {
      if (
        optionsList[item]["name"]
          .toLowerCase()
          .includes(filterText.toLowerCase())
      ) {
        filteredOptionsList[item] = optionsList[item];
      }
    }
    setDropdownOptions(filteredOptionsList);
  };

  const handleClickOutside = (e) => {
    const classList = e.target.classList;
    if (
      !classList.contains("MuiInputBase-input") &&
      !classList.contains("select-option-aigroup") &&
      !classList.contains("btn-aigroup")
    ) {
      dispatch(
        setAiInitiationValue({
          value: -1,
          key: "dropdown",
        })
      );
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const updateData = (e, option, selectAll) => {
    if (dropdown === 1) {
      dispatch(
        setAiInitiationValue({
          value: option,
          key: "selectedAiGroup",
        })
      );
      if (setSelectedAdAccount) setSelectedAdAccount(null);
    } else if (dropdown === 2) {
      dispatch(
        setAiInitiationValue({
          value: option,
          key: "selectedCountry",
        })
      );
      if (JSON.stringify(option) !== JSON.stringify(selectedCountry)) {
        dispatch(
          setAiInitiationValue({
            value: null,
            key: "regionOptions",
          })
        );
        dispatch(
          setAiInitiationValue({
            value: [],
            key: "selectedRegion",
          })
        );
      }
    } else if (dropdown === 3) {
      if (Object.values(dropdownOptions).length === selectedRegion.length) {
        dispatch(
          setAiInitiationValue({
            value: option ? [option] : [],
            key: "selectedRegion",
          })
        );
      } else {
        const selectedRegionCheck =
          option &&
          selectedRegion.find((region) => region.name === option.name);
        if (selectedRegionCheck) {
          dispatch(
            setAiInitiationValue({
              value: selectedRegion.filter(
                (region) => region.name !== option.name
              ),
              key: "selectedRegion",
            })
          );
        } else {
          dispatch(
            setAiInitiationValue({
              value: selectAll
                ? [...Object.values(dropdownOptions)]
                : [...selectedRegion, option],
              key: "selectedRegion",
            })
          );
        }
      }
    } else {
      selectedAiGroup(option);
    }
  };

  return (
    <>
      <div
        className={`cursor-pointer ${classes} scroll-bar-hidden`}
        style={{
          backgroundColor: "#FAFAFA",
          zIndex: "50",
          border: "1px solid #F0F0F0",
          borderTop: "0px",
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
          borderRadius: "8px",
          maxWidth: "95%",
          maxHeight: `${loading ? "5.5rem" : "12.5rem"}`,
          height: "100%",
          overflowY: "auto",
        }}
      >
        {searchBar ? (
          <div
            style={{
              backgroundColor: "#FAFAFA",
            }}
          >
            <SearchComponent
              searchTerm={filterText}
              setSearchTerm={handleSearch}
              placeholder={"Search Term"}
              width="16rem"
              custom={true}
              autoFocus
            />
          </div>
        ) : (
          <></>
        )}
        {(!dropdownOptions || Object.keys(dropdownOptions).length <= 0) && (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            No Data Found
          </div>
        )}
        {dropdownOptions && Object.keys(dropdownOptions).length > 0 && (
          <ul
            className="p-1 w-100"
            style={{ listStyle: "none", height: "100%" }}
          >
            {multiselect && (
              <li
                id="select-option-all"
                className="p-2 text-sm select-option w-100 select-option-aigroup"
                data-name={"all"}
                key={"all"}
                onClick={(e) => {
                  updateData(e, null, true);
                  dispatch(
                    setAiInitiationValue({
                      value: -1,
                      key: "dropdown",
                    })
                  );
                }}
                role="presentation"
              >
                Select All
                {dropdownOptions &&
                  selectedRegion.length ===
                    Object.values(dropdownOptions).length && (
                    <img
                      src={taiDropdownCheckmark}
                      alt="checkmark"
                      style={{
                        position: "absolute",
                        right: "7%",
                      }}
                    />
                  )}
              </li>
            )}
            {customDropdown &&
              dropdownOptions &&
              dropdownOptions.map(({ id, account_name, name, account_id }) => (
                <li
                  id={`select-option-${id}`}
                  className="p-2 text-sm select-option w-100 select-option-aigroup"
                  data-name={id}
                  key={id}
                  onClick={(e) => {
                    selectedAudience({ id, account_name, name, account_id });
                  }}
                  role="presentation"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #F0F0F0",
                    }}
                  >
                    <div style={{ maxWidth: "64%" }} className="overflow">
                      <p
                        className="overflow"
                        style={{ fontSize: "12px", marginBottom: "0px" }}
                        title={name}
                      >
                        {name}
                      </p>
                      <p
                        style={{ fontSize: "10px", color: "#999999" }}
                        title={id}
                      >
                        {id}
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "12px" }} title={account_name}>
                        {account_name}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            {!customDropdown &&
              dropdownOptions &&
              Object.keys(dropdownOptions).map((option, index) => (
                <li
                  id={`select-option-${index}`}
                  className="p-2 text-sm select-option w-100 select-option-aigroup"
                  data-name={option}
                  key={option}
                  onClick={(e) => {
                    updateData(e, dropdownOptions[option]);
                    dispatch(
                      setAiInitiationValue({
                        value: -1,
                        key: "dropdown",
                      })
                    );
                  }}
                  role="presentation"
                >
                  {dropdownOptions[option].name}
                  {selectedAiGroup &&
                    dropdownOptions[option].id === selectedAiGroup.id && (
                      <img
                        src={taiDropdownCheckmark}
                        alt="checkmark"
                        style={{
                          position: "absolute",
                          right: "7%",
                        }}
                      />
                    )}
                  {/*selectedCountry &&
                    dropdownOptions[option].code === selectedCountry.code && (
                      <img
                        src={taiDropdownCheckmark}
                        alt="checkmark"
                        style={{
                          position: "absolute",
                          right: "7%",
                        }}
                      />
                      )*/}
                  {/*optionsList &&
                    selectedRegion &&
                    selectedRegion.includes(optionsList[option]) &&
                    selectedRegion.length !==
                      Object.values(dropdownOptions).length && (
                      <img
                        src={taiDropdownCheckmark}
                        alt="checkmark"
                        style={{
                          position: "absolute",
                          right: "7%",
                        }}
                      />
                      )*/}
                </li>
              ))}
          </ul>
        )}
      </div>
    </>
  );
}

export default TAIDropdown;
