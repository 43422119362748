import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';
import { ReactComponent as IconClose } from "../../../assets/svg/close.svg";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "42.25em",
  bgcolor: 'background.paper',
  borderRadius: ".75em",
  boxShadow: 24,
  p: "1.5em",
  fontSize: "16px",
};

type Recommendation = {
  id: number
  text: string
  approval_status: string
}

type Detail = {
  recommended_new: Recommendation[]
  recommended_paused: Recommendation[]
  recommended_retained: Recommendation[]
}
export type AssetGroupViewDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  itemToChange: string; 
  details: {
    TEXT?: {
      HEADLINE?: Detail,
      DESCRIPTION?: Detail,
      LONG_HEADLINE?: Detail,
    }
  }
};

const detailsHasData = (details: Detail | undefined) => {
  return (
    (details?.recommended_new?.length ?? 0) > 0 ||
    (details?.recommended_paused?.length ?? 0) > 0 ||
    (details?.recommended_retained?.length ?? 0) > 0
  );
}

const AssetGroupViewDetailsModal = ({
  isOpen,
  onClose,
  details,
  itemToChange,
}: AssetGroupViewDetailsModalProps) => {
  const data = [
    detailsHasData(details?.TEXT?.HEADLINE) ? { label: "Headline", ...details?.TEXT?.HEADLINE } : null!,
    detailsHasData(details?.TEXT?.LONG_HEADLINE) ? { label: "Long Headline", ...details?.TEXT?.LONG_HEADLINE } : null!,
    detailsHasData(details?.TEXT?.DESCRIPTION) ? { label: "Description", ...details?.TEXT?.DESCRIPTION } : null!,
  ].filter(Boolean);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={style}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "1.25em",
              fontWeight: 600,
              lineHeight: "2em",
              letterSpacing: "-0.015em",
              textAlign: "left"
            }}
          >
            Item to Change : {itemToChange}
          </Typography>
          <Button
            sx={{ backgroundColor: "#F6F7F8", p: "0.7em", minWidth: "fit-content" }}
            aria-label='close'
            onClick={onClose}
          >
            <IconClose />
          </Button>
        </Stack>
        <Divider color="#E0E3E5" sx={{ my: "0.5em" }} />
        <Stack sx={{ maxHeight: "80vh", overflowY: "auto", paddingRight: "1rem" }}>
          {data.map((item, index) => (
            <Fragment key={index}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "1.125em",
                    fontWeight: 600,
                    lineHeight: "1.6em",
                    letterSpacing: "-0.01em",
                    textAlign: "left"
                  }}
                >
                  {item.label}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: ".875em",
                    fontWeight: 400,
                    lineHeight: "1.375em",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                  }}
                >
                  ({(item.recommended_new?.length ?? 0) + (item.recommended_paused?.length ?? 0)} changes)
                </Typography>
              </Stack>
              {!!item.recommended_paused?.length && (
                <Stack>
                  <Typography sx={{
                    fontFamily: "Inter",
                    fontSize: "1em",
                    fontWeight: 600,
                    lineHeight: "1.5em",
                    letterSpacing: "-0.0313em",
                    textAlign: "left",
                    color: "#5C6674"
                  }}>To be removed ({item.recommended_paused.length})</Typography>
                  <Box component="ol" sx={{ color: "#5C6674", pl: "1.2em" }}>
                    {item.recommended_paused.map(({ text }) => <li>{text}</li>)}
                  </Box>
                </Stack>
              )}
              {!!item.recommended_new?.length && (
                <Stack>
                  <Typography sx={{
                    fontFamily: "Inter",
                    fontSize: "1em",
                    fontWeight: 600,
                    lineHeight: "1.5em",
                    letterSpacing: "-0.0313em",
                    textAlign: "left",
                    color: "#5C6674"
                  }}>To be added ({item.recommended_new.length})</Typography>
                  <Box component="ol" sx={{ color: "#5C6674", pl: "1.2em" }}>
                    {item.recommended_new.map(({ text }) => <li>{text}</li>)}
                  </Box>
                </Stack>
              )}
              {!!item.recommended_retained?.length && (
                <Stack>
                  <Typography sx={{
                    fontFamily: "Inter",
                    fontSize: "1em",
                    fontWeight: 600,
                    lineHeight: "1.5em",
                    letterSpacing: "-0.0313em",
                    textAlign: "left",
                    color: "#5C6674"
                  }}>To be retained ({item.recommended_retained.length})</Typography>
                  <Box component="ol" sx={{ color: "#5C6674", pl: "1.2em" }}>
                    {item.recommended_retained.map(({ text }) => <li>{text}</li>)}
                  </Box>
                </Stack>
              )}
              {index !== data.length - 1 && <Divider color="#E0E3E5" sx={{ mb: "0.5em" }} />}
            </Fragment>
          ))}
        </Stack>
      </Box>
    </Modal>
  )
};

export default AssetGroupViewDetailsModal;