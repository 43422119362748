export const USERSTABLEHEADERS = [
  "S.No.",
  "Name",
  "Email",
  "Brands",
  "Created On",
  "Action",
];

export const BRANDSTABLEHEADERS = [
  "S.No.",
  "Name",
  "Website",
  "Details",
  "Created On",
  "Action",
];

export const BRANDACCESSTABLEHEADERS = ["S.No", "Brands", "Access Given On"];
