import styled from "styled-components";

export const StyledNoGraphHeading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125em;
  line-height: 1.375em;
  padding-top: 1em;

  color: #34343f;
`;

export const StyledNoGraphParagraph = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.0625em;
  text-align: center;

  color: #464665;
  padding-top: 0.5em;
`;
