import { combineReducers } from "redux";
import agencies from "./agencies";
import brand from "./brand";
import common from "./common";
import selectedBrand from "./selectedBrand";
import error from "./error";
import adAccount from "./adAccount";

const smb = combineReducers({
  agencies,
  brand,
  common,
  selectedBrand,
  error,
  adAccount
});

export default smb;
