import { Box, CircularProgress, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { StyledTextInput, TextInputProps } from "./styled";

interface CommentProps extends TextInputProps {
  isBorder?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  onBlurFunction: (value: string) => void;
  loading?: boolean;
  localValue: string;
}

export function Comment({
  isBorder,
  minRows = 5,
  onBlurFunction,
  loading,
  localValue,
  ...props
}: CommentProps) {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(localValue);
  }, [localValue]);

  return (
    <StyledTextInput
      multiline
      minRows={minRows}
      maxRows={minRows}
      fullWidth
      disableUnderline
      placeholder="Enter Comment Here.."
      onBlur={() => onBlurFunction(value)}
      onChange={(e) => setValue(e.target.value)}
      {...props}
      isBorder={isBorder}
      value={value}
      endAdornment={
        loading ? (
          <InputAdornment
            position="start"
            sx={{ position: "absolute", top: 13, right: 0 }}
          >
            <CircularProgress size="1.2em" thickness={7} />
          </InputAdornment>
        ) : (
          <Box />
        )
      }
    />
  );
}
