import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { BASE_URL, CROSS_PLATFORM_URL } from "../../../../utils/path";
import httpService from "HttpService/HttpService";
import {
  GET_UTG_BUNDLE,
  GetUtgBundle,
} from "store/types/CreativeAI_Revamp/UniTextGen/uniTextGen";

const Base_Variants_API = "/v1/api/creative-variants";

function* getUtgBundle(action: GetUtgBundle): any {
  const {
    payload: { user, data, finalAction },
    callback,
  } = action;
  try {
    const urlParams = `${Base_Variants_API}/utg`;
    const response = yield call(
      httpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      data
    );
    if (response.data?.success) {
      // yield put({ type: finalAction, payload: response.data.data });
      callback && callback(response.data.data, false);
    } else {
      callback && callback(response.data, true);
      console.log("error at generate UTG");
      // yield put({ type: SET_AUTOGENERATE_LOADING, payload: false });
      // yield put({ type: SET_FAKEGENERATE_LOADING, payload: false });
    }
  } catch (error) {
    console.log("error at generate UTG", error);
    callback && callback({}, true);
    // yield put({ type: SET_AUTOGENERATE_LOADING, payload: false });
    // yield put({ type: SET_FAKEGENERATE_LOADING, payload: false });
  }
}

export function* UTGbundleWatcher() {
  yield takeLatest(GET_UTG_BUNDLE, getUtgBundle);
}
