export const currencyUrl =
  "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/";

export const productsCategory = [
  {
    name: "Food",
    value: "Food",
  },
  {
    name: "Beverages",
    value: "Beverages",
  },
  {
    name: "Home Furnishings",
    value: "Home Furnishings",
  },
  {
    name: "Apparel",
    value: "Apparel",
  },
  {
    name: "Beauty",
    value: "Beauty",
  },
  {
    name: "Wellness",
    value: "Wellness",
  },
  {
    name: "Sports",
    value: "Sports",
  },
  {
    name: "Outdoor",
    value: "Outdoor",
  },
  {
    name: "Consumer Electronics",
    value: "Consumer Electronics",
  },
  {
    name: "Travel",
    value: "Travel",
  },
  {
    name: "Fitness and Nutrition",
    value: "Fitness and Nutrition",
  },
  {
    name: "Baby Product",
    value: "Baby Product",
  },
  {
    name: "Pet Supplies",
    value: "Pet Supplies",
  },
  {
    name: "Groceries",
    value: "Groceries",
  },
];

export const currency = [
  {
    name: "USD",
    value: "USD",
  },
  {
    name: "EURO",
    value: "EURO",
  },
  {
    name: "INR",
    value: "INR",
  },
  {
    name: "GBP",
    value: "GBP",
  },
];

export const interestsOptions = [
  {
    label: "Health",
  },
  {
    label: "Fashion",
  },
  {
    label: "Beauty",
  },
  {
    label: "Lifestyle",
  },
  {
    label: "Entertainment",
  },
  {
    label: "Art & Books",
  },
  {
    label: "Food",
  },
  {
    label: "Sports & Fitness",
  },
  {
    label: "Education",
  },
  {
    label: "Gadgets & Tech",
  },
  {
    label: "Blogging & Writing",
  },
  {
    label: "Luxury",
  },
];

export const sliderMarks = [
  {
    value: 16,
    label: "16",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 90,
    label: "90",
  },
  {
    value: 100,
    label: "100",
  },
];

export const languageOption = [
  { label: "English" },
  { label: "Hindi" },
  { label: "Spanish" },
  { label: "French" },
];
