import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as ThunderIcon } from "../../../../assets/svg/thunder.svg";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


const IntroPopup = ({
  heading,
  subHeading,
  intro,
  children,
  open,
  anchorEl,
  onButtonClick,
  placement,
}: {
  heading?: string;
  subHeading?: string;
  intro?: React.ReactNode;
  open: boolean;
  children:any;
  anchorEl?: Element | null;
  onButtonClick?: () => void;
  placement?: any;
}) => {
  return (
      <LightTooltip
      open={open}
      placement={placement || 'top'}
      arrow
      title={<Box
        sx={{ margin:"10px", background: "white", borderRadius: "8px" }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "600",textAlign:"center" }}>
          {heading}
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "400", color: "#5C6674",textAlign:"center" }}
        >
          {subHeading}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "10px",
            textAlign:"center"
          }}
        >
          <ThunderIcon />
        </Box>
        {intro}
        <Box
          sx={{
            width: "100%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={onButtonClick}
            sx={{
              padding: "6px 16px",
              border: "1.5px solid #E0E3E5",
              borderRadius: "6px",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Got it
          </Button>
        </Box>
      </Box>}
      >
        {children}
      </LightTooltip>
      
  );
};

export default IntroPopup;

const LightTooltip = styled(({ open, className, ...props }: TooltipProps) => {
  const {title,children}=props;
  if(!title) return children;
  return (
      <Tooltip {...props} classes={{ popper: className }} open={open} />
)})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: '#242424',
    boxShadow: theme.shadows[1],
    fontSize:12,
    padding:'6px 12px',
    maxWidth:"unset !important",
    marginRight:"0px !important"
  },
  [`& .${tooltipClasses.arrow}`]: {
      '&::before': {
          backgroundColor: '#FFFFFF',
          boxShadow: theme.shadows[1],
      }
    },
}));
