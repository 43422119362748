import { useState } from "react";
import { TextField, Typography } from "@mui/material";
import BasicModal from "common/modal";
import { isValidWebsite } from "../utils";

function EditBrand({
  open,
  setOpen,
  selectedBrandData,
  editBrand,
  getBrands,
  page,
  limit,
}: {
  open: boolean;
  setOpen: Function;
  selectedBrandData: any;
  editBrand: Function;
  getBrands: Function;
  page: number;
  limit: number;
}) {
  const [editBrandName, setEditBrandName] = useState(selectedBrandData.name);
  const [editBrandDetails, setEditBrandDetails] = useState(
    selectedBrandData.brandDetails
  );
  const [editBrandWebsite, setEditBrandWebsite] = useState(
    selectedBrandData.brandWebsite
  );
  const [isBrandNameError, setIsBrandNameError] = useState(false);
  const [isBrandDetailsError, setIsBrandDetailsError] = useState(false);
  const [isBrandWebsiteError, setIsBrandWebsiteError] = useState(false);
  const resetBrandFormData = () => {
    setEditBrandName("");
    setEditBrandDetails("");
    setEditBrandWebsite("");
  };

  const onEditButtonSubmit = () => {
    let hasError = false;
    if (editBrandName.startsWith(" ") || editBrandName.length === 0) {
      setIsBrandNameError(true);
      hasError = true;
    } else {
      setIsBrandNameError(false);
    }
    if (editBrandDetails.startsWith(" ") || editBrandDetails.length === 0) {
      setIsBrandDetailsError(true);
      hasError = true;
    } else {
      setIsBrandDetailsError(false);
    }

    if (isValidWebsite(editBrandWebsite)) {
      setIsBrandWebsiteError(false);
    } else {
      setIsBrandWebsiteError(true);
      hasError = true;
    }
    const brandId = selectedBrandData.id;

    const editBrandPayload = {
      name: editBrandName,
      competitors: selectedBrandData?.competitors ?? "",
      brandDetails: editBrandDetails,
      brandWebsite: editBrandWebsite,
    };
    if (!hasError) {
      editBrand(editBrandPayload, brandId).then(() => {
        getBrands(page + 1, limit);
        setOpen(false);
        resetBrandFormData();
      });
    }
  };

  return (
    <>
      <BasicModal
        title="Edit Brand"
        open={open}
        setOpen={setOpen}
        showButton={true}
        height={480}
        width={523}
        onSubmit={onEditButtonSubmit}
      >
        <Typography>Name</Typography>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          sx={{ width: "100%", mb: "10px" }}
          value={editBrandName}
          onChange={(event: any) => {
            setEditBrandName(event.target.value);
          }}
        />
        {isBrandNameError && (
          <Typography
            className="font-12"
            sx={{
              color: "#FF2E2E",
              marginTop: "-8px",
            }}
          >
            Enter a valid brand name
          </Typography>
        )}
        <Typography>Brand Details</Typography>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          sx={{ width: "100%", mb: "10px" }}
          value={editBrandDetails}
          onChange={(event: any) => {
            setEditBrandDetails(event.target.value);
          }}
        />
        {isBrandDetailsError && (
          <Typography
            className="font-12"
            sx={{
              color: "#FF2E2E",
              marginTop: "-8px",
            }}
          >
            Enter valid brand details
          </Typography>
        )}
        <Typography>Brand Website</Typography>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          sx={{ width: "100%", mb: "10px" }}
          value={editBrandWebsite}
          onChange={(event: any) => {
            setEditBrandWebsite(event.target.value);
          }}
        />
        {isBrandWebsiteError && (
          <Typography
            className="font-12"
            sx={{
              color: "#FF2E2E",
              marginTop: "-8px",
            }}
          >
            Enter a valid brand website (website must start with 'https://')
          </Typography>
        )}
      </BasicModal>
    </>
  );
}

export default EditBrand;
