import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle, Position, useReactFlow } from "react-flow-renderer";

import Box from "@mui/material/Box";

import LoaderContainer from "../../../common/LoaderContainer";
import LoaderRightStrip from "../../../common/LoaderRightStrip";

import { ReactComponent as NewKeywords } from "../../assets/svg/keywordExplorer/newKeywords.svg";
import { ReactComponent as PositiveKeywords } from "../../assets/svg/keywordExplorer/positiveKeywords.svg";
import { ReactComponent as NegativeKeywords } from "../../assets/svg/keywordExplorer/negativeKeywords.svg";
import { ReactComponent as AdGroup } from "../../assets/svg/keywordExplorer/adGroup.svg";
import { ReactComponent as ClusterLevel } from "../../assets/svg/keywordExplorer/clusterLevel.svg";

import { ReactComponent as Line } from "../../../ReactFlowComponents/Assets/svgs/str-line.svg";

import { keywordExplorerData } from "../../constants/loader";
import { TaiContext } from "../../../TaiContextProvider";
import { usePolling } from "../../../hooks/usePolling";
import { getGSTargetingAIGeneratedKeywords } from "store/actions/gsTargetingAI";
import { TAIGSearchMixPanel } from "../../utils";
import { CommonState } from "store/types/common";
import { AdAccountState } from "store/types/adaccount";

export default function LoaderKeywordExplorer({ data }: { data: any }) {
  const dispatch = useDispatch();
  const loaderState = useSelector((state: any) => state.taiCommon.loader);
  const currentPage = useSelector((state: any) => state.taiCommon.currentPage);
  const { setCenter } = useReactFlow();
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { selectedAgency } = common;
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;

  const [loadingId, setLoadingId] = useState(0);
  const [isPolledDataFetched, setPolledDataFetched] = useState(false);
  const polling = useSelector((state: any) => state.taiCommon.polling);
  const aiInitiation = useSelector((state: any) => state.aiInitiation.apiData);
  const aiInitiationFormData = useSelector(
    (state: any) => state.aiInitiation.formData
  );
  const { setNodes, setEdges, changePublishModal, setKeywordsJsonTai } =
    useContext(TaiContext) as any;

  const getKWSDataGS = (result: string) => {
    setKeywordsJsonTai(result);
    setPolledDataFetched(true);
  };
  useEffect(() => {
    if (loadingId < 4) {
      if (isPolledDataFetched) {
        setPolledDataFetched(false);
      }
    }
  }, [loadingId]);

  const { setStartPolling } = usePolling(2000, (error: any) => {
    if (error) {
      //error
      return;
    }
    if (polling.requestId !== "") {
      dispatch(
        getGSTargetingAIGeneratedKeywords(
          polling.requestId,
          (res: any, err: boolean) => {
            const status = res?.status_list[0]?.status;
            if (status === "failed" || err) {
              setPolledDataFetched(true);
              setStartPolling(false);
              changePublishModal("failed");
            }
            if (!err && status === "completed") {
              setStartPolling(false);
              getKWSDataGS(res.status_list[0].result);
            }
          }
        )
      );
    }
  });

  useEffect(() => {
    if (loadingId < 3 && currentPage === data.index) {
      setTimeout(() => {
        setLoadingId(loadingId + 1);
      }, 2000);
    }
    if (currentPage > data.index) {
      setLoadingId(4);
    }
  }, [loadingId, currentPage]);

  useEffect(() => {
    if (polling.name === "keywordTuningGS" && currentPage === data.index) {
      setLoadingId(0);
      setStartPolling(true);
    }
  }, [polling, currentPage]);

  useEffect(() => {
    if (isPolledDataFetched) {
      setLoadingId(4);
    }
  }, [isPolledDataFetched]);

  const keywordExplorerContinueClicked = () => {
    TAIGSearchMixPanel(
      "TAI-Gsearch Audience Loader Clicked",
      selectedBrand?.name,
      selectedAgency?.name,
      {
        ai_group_id: aiInitiationFormData?.selectedAiGroup?.id,
        ai_group_name: aiInitiationFormData?.selectedAiGroup?.name,
        ad_account_name: aiInitiation?.adAccount?.name,
        ad_account_id: aiInitiation?.adAccount?.id,
        agency_id: selectedAgency.id,
        brand_id: selectedBrand.id,
        brand_type: selectedBrand.brandType,
      }
    );
    data.loaderContinue(dispatch, loaderState, setNodes, setEdges);
  };
  useEffect(() => {
    if (data.position) {
      setCenter(data.position.x + 200, data.position.y + 400, {
        zoom: 0.7,
        duration: 1000,
      });
    }
  }, []);
  return (
    <>
      <div
        className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex focus:outline-none w-100 h-100"
        style={{
          position: "absolute",
          zIndex: "100",
        }}
      >
        <Handle
          type="target"
          position={Position.Left}
          style={{ background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          // isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position={Position.Right}
          style={{ background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          // isConnectable={isConnectable}
        />
        <LoaderContainer
          steps={keywordExplorerData.steps}
          loadingId={loadingId}
          continueProps={{
            onClick: keywordExplorerContinueClicked,
            disabled: !isPolledDataFetched,
            className: `${
              loadingId < 4 ? "disable-continue" : "enable-continue"
            } ${currentPage === data.index ? "" : "d-none"}`,
          }}
          loaderGifProps={{
            src: keywordExplorerData.gif,
            alt: "Keyword Tuning",
            style: { height: "15rem", width: "12rem" },
          }}
          closeProps={{
            className: currentPage > data.index ? "" : "d-none",
            onClick: () =>
              data.loaderContinue(dispatch, loaderState, setNodes, setEdges),
          }}
          header="Keyword Explorer"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Line />
            <LoaderRightStrip
              items={[
                {
                  title: "New Keywords Recommended",
                  text: "Explore & tune the new keywords based on the quality indicators generated by AI system",
                  svg: <NewKeywords />,
                },
                {
                  title: "Positive Keywords",
                  text: "Explore & tune the high potential keywords based on the performance indicators",
                  svg: <PositiveKeywords />,
                },
                {
                  title: "Negative Keywords",
                  text: "Explore & negate the low performing keywords to reduce your wasted spends",
                  svg: <NegativeKeywords />,
                },
              ]}
            />
            <Line />
            <LoaderRightStrip
              items={[
                {
                  title: "Recommended Ad Group",
                  text: "View keywords grouped by their recommended ad group",
                  svg: <AdGroup />,
                },
                {
                  title: "Cluster Level",
                  text: "View keywords grouped by semantic clustering",
                  svg: <ClusterLevel />,
                },
              ]}
            />
          </Box>
        </LoaderContainer>
      </div>
    </>
  );
}
