import { useEffect, useState } from "react";
import CustomizedDialogs from "components/shared/Modal/CustomizedDialogs";
import {
  Button,
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import imagePlaceholder from "../../../../assets/png/no_image_placeholder.png";
import { ReactComponent as SearchIcon } from "../../../../assets/svg/search.svg";
import { ReactComponent as FilterIcon } from "../../../../assets/svg/Filter-new.svg";
import DropdownUI from "../../../../ui/Dropdown/Dropdown";
import { CheckBox } from "components/shared";
import { useDispatch, useSelector } from "react-redux";
import {
  getNoRecommendationList,
  setNonRecommendedAds,
} from "store/actions/ActionDebugger/CreativeRotation/creativeRotation.actions";
import { CreativeRotationInitialState } from "store/types/ActionDebugger/CreativeRotation/creativeRotation.types";
import InHousePreviewModal from "./InHousePreviewModal";
import { getPreviewImage } from "../common.utils";
import { AdAccountState } from "store/types/adaccount";
import { getRolesHierarchy, isViewerRole } from "utils/commonFunctions";
import { RolesWrapper } from "utils/rolesWrapper";

const NoRecommendationModal = ({
  showNoRecommendation,
  handleClose,
  elementId,
}: any) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredValues, setfilteredValues] = useState<any>({
    adset: [],
    campaign: {},
  });
  const [filterData, setFilterData] = useState([]);
  const [allAds, setAllAds] = useState([]);
  const { noRecommendationList, nonRecommendedAds }: any = useSelector(
    (state: { creativeRotationReducer: CreativeRotationInitialState }) => {
      return state.creativeRotationReducer;
    }
  );
  const [checkedAdsets, setCheckedAdsets] = useState<any>(nonRecommendedAds);
  const [selectedPrew, setSelectedPrew] = useState("");
  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;
  console.log("LL: brandRoles", brandRoles);

  useEffect(() => {
    const initialFilteredData: any = { ads: [], campaign: {} };
    const newAllAdsets: any = [];
    if (noRecommendationList) {
      const newFilterData: any = [];
      noRecommendationList.forEach((item: any, index: number) => {
        newFilterData.push({
          campaignName: item.campaign.name,
          id: item.campaign.campaignId,
          [item.campaign.campaignId]: {},
        });
        initialFilteredData.campaign[item.campaign.campaignId] = [];

        item.adSets.forEach((adSet: any) => {
          initialFilteredData.campaign[item.campaign.campaignId].push(
            adSet.adSetId
          );
          newFilterData[index][item.campaign.campaignId][adSet.adSetId] = {
            adsetName: adSet.adSetName,
            adsetId: adSet.adSetId,
            ads: [],
          };
          adSet.ads.forEach((ad: any) => {
            newAllAdsets.push(ad);
            newFilterData[index][item.campaign.campaignId][
              adSet.adSetId
            ].ads.push({ id: ad.adId, adName: ad.adSetName });
            initialFilteredData.ads.push(ad.adId);
          });
        });
      });
      setFilterData(newFilterData);
      setfilteredValues(initialFilteredData);
      setAllAds(newAllAdsets);
    }
  }, [noRecommendationList]);

  const handleAdCheck = (adId: string) => {
    let newCheckedList = [];
    if (checkedAdsets.includes(adId))
      newCheckedList = checkedAdsets.filter((i: string) => i != adId);
    else newCheckedList = [...checkedAdsets, adId];

    setCheckedAdsets(newCheckedList);
  };

  const handleSubmit = () => {
    dispatch(setNonRecommendedAds({ data: checkedAdsets, elementId }));
    handleClose();
  };

  return (
    <CustomizedDialogs
      type="modal"
      title="No-Recommendation List"
      subTitle="Search and select ads for which recommendations will not be generated and shown as per your business requirements"
      isHeightRequired={false}
      show={showNoRecommendation}
      handleClose={handleClose}
      titleSize={20}
      subTitleSize={16}
      buttons={
        <Stack direction={"row"} sx={{ width: "100%" }}>
          <Button
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "0.875em",
              lineHeight: "24px",
              borderRadius: "0.5em",
              height: "3em",
              margin: "1em",
              color: "#394455",
              border: "1.5px solid #E0E3E5",
              width: "45%",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <RolesWrapper
            parentDivStyle={{ width: "45%" }}
            disabled={getRolesHierarchy(brandRoles) === "viewer"}
          >
            <Button
              variant="contained"
              sx={{
                // width: "45%",
                width: "100%",
                background: "#3F64FA",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "0.875em",
                lineHeight: "24px",
                borderRadius: "0.5em",
                height: "3em",
                margin: "1em",
                // opacity:
                // batchList?.isLoading
                //     ? 0.5
                //     : 1,
                // pointerEvents:
                // batchList?.isLoading
                //     ? "none"
                //     : "all",
              }}
              // disabled={isViewerRole(brandRoles)}
              onClick={handleSubmit}
            >
              {"Submit"}
            </Button>
          </RolesWrapper>
        </Stack>
      }
    >
      {!noRecommendationList ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          minHeight="30vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <SearchWithFilter
            setSearchTerm={setSearchTerm}
            filterData={filterData}
            filteredValues={filteredValues}
            setfilteredValues={setfilteredValues}
            noRecommendationList={noRecommendationList}
          />
          <Box sx={{ minHeight: "50vh" }}>
            {allAds
              .filter(
                (item: any) =>
                  (item.adId.includes(searchTerm) ||
                    item.adName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    item.adSetName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())) &&
                  filteredValues.ads.includes(item.adId)
              )
              .map((ad: any, index: number) => {
                return (
                  <Box
                    sx={{
                      width: "100%",
                      padding: "1rem 1rem 1rem 0",
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    <CheckBox
                      checked={checkedAdsets.includes(ad.adId)}
                      onClick={() => handleAdCheck(ad.adId)}
                    />
                    <InHousePreviewModal
                      previewLink={ad?.adPreview?.[0]?.body}
                      setSelectedPrew={setSelectedPrew}
                      index={index}
                    >
                      <Box
                        ml=".7em"
                        component="img"
                        src={
                          getPreviewImage(ad?.adcreatives?.data[0]) ??
                          imagePlaceholder
                        }
                        sx={{
                          height: "3em",
                          width: "3em",
                          objectFit: "cover",
                          border: "0.133564px solid #E5E5E5",
                          borderRadius: "1.55981px",
                          zIndex:
                            selectedPrew === ad?.adPreview?.[0]?.body
                              ? 1000
                              : undefined,
                        }}
                      />
                    </InHousePreviewModal>
                    {/*                 
                  {ad.adcreatives?.data?.[0]?.image_url ? <img
                  style={{height: "70.6px",
                  width: "78px",}}
                  src={ad.adcreatives?.data?.[0]?.image_url}
                  /> : <iframe
                  style={{
                    display: true ? "block" : "none",
                    height: "70.6px",
                    width: "78px",
                    overflow: "hidden",
                    border: "0.133564px solid #E5E5E5",
                    borderRadius: "1.55981px",
                  }}
                  scrolling="no"
                  src={getPreview(ad.adPreview?.[0]?.body)||
                    imagePlaceholder}
                  />} */}
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: "400",
                          color: "#5C6674",
                          maxWidth: "370px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "pre",
                        }}
                      >
                        Ad Name :
                        <Tooltip title={ad.adName} placement="top-start">
                          <span
                            style={{
                              fontSize: "0.75rem",
                              fontWeight: "500",
                              color: "#000000",
                            }}
                          >
                            {" " + ad.adName}
                          </span>
                        </Tooltip>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: "400",
                          color: "#5C6674",
                        }}
                      >
                        Ad ID :
                        <span
                          style={{
                            fontSize: "0.75rem",
                            fontWeight: "500",
                            color: "#000000",
                          }}
                        >
                          {" " + ad.adId}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: "400",
                          color: "#5C6674",
                          maxWidth: "370px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "pre",
                        }}
                      >
                        Adset Name :
                        <Tooltip title={ad.adSetName} placement="top-start">
                          <span
                            style={{
                              fontSize: "0.75rem",
                              fontWeight: "500",
                              color: "#000000",
                            }}
                          >
                            {" " + ad.adSetName}
                          </span>
                        </Tooltip>
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      )}
    </CustomizedDialogs>
  );
};

export default NoRecommendationModal;

const SearchWithFilter = ({
  setSearchTerm,
  filterData,
  filteredValues,
  setfilteredValues,
}: any) => {
  const [showFilter, setShowFilter] = useState(false);
  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  return (
    <TextField
      onChange={(e) => handleSearch(e)}
      sx={{
        width: "100%",
        float: "right",
        "& .MuiOutlinedInput-input": {
          padding: 0,
          height: "36px",
        },
        "& .MuiOutlinedInput-input::placeholder": {
          fontFamily: "Inter",
          fontSize: "12px",
          fontStyle: "italic",
          fontWeight: "500",
          lineHeight: "20px",
          letterSpacing: "0px",
          textAlign: "left",
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Filter
              filterData={filterData}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filteredValues={filteredValues}
              setfilteredValues={setfilteredValues}
            />
          </InputAdornment>
        ),
      }}
      placeholder={"Search Ads by Ad Name, Ad ID or Adset Name"}
    />
  );
};

const Filter = ({
  filterData,
  showFilter,
  setShowFilter,
  setfilteredValues,
  filteredValues,
}: any) => {
  const [checkedValues, setCheckedValues] = useState(
    structuredClone(filteredValues)
  );

  useEffect(() => {
    setCheckedValues(structuredClone(filteredValues));
  }, [showFilter]);

  const handleApply = () => {
    setfilteredValues(checkedValues);
    setShowFilter(false);
  };

  const handleAdSetCheckbox = (
    campaignId: string,
    adsetId: string,
    index: any
  ) => {
    if (checkedValues.campaign[campaignId].includes(adsetId)) {
      const allAdsInAdset: any = [];
      filterData[index][campaignId][adsetId].ads.forEach((ad: any) =>
        allAdsInAdset.push(ad.id)
      );
      const newAdsVal = checkedValues.ads.filter(
        (item: any) => !allAdsInAdset.includes(item)
      );
      const newCampVal = checkedValues.campaign[campaignId].filter(
        (i: string) => i !== adsetId
      );
      setCheckedValues({
        ads: [...newAdsVal],
        campaign: { ...checkedValues.campaign, [campaignId]: newCampVal },
      });
    } else {
      const newAdsVal = [...checkedValues.ads];
      filterData[index][campaignId][adsetId].ads.forEach((item: any) => {
        if (!newAdsVal.includes(item.id)) newAdsVal.push(item.id);
      });

      const newCampVal = [...checkedValues.campaign[campaignId], adsetId];
      setCheckedValues({
        ads: [...newAdsVal],
        campaign: { ...checkedValues.campaign, [campaignId]: newCampVal },
      });
    }
  };

  const handleCampaignCheckbox = (campaignId: string, index: number) => {
    if (
      checkedValues.campaign?.[campaignId]?.length ==
      Object.keys(filterData[index]?.[campaignId]).length
    ) {
      const adsVal = [...checkedValues.ads];
      const allAdsInCampaign: any = [];
      Object.keys(filterData[index][campaignId]).forEach((adsetId: any) => {
        filterData[index][campaignId][adsetId].ads.forEach((item: any) => {
          allAdsInCampaign.push(item.id);
        });
      });
      const filteredAds = adsVal.filter(
        (item) => !allAdsInCampaign.includes(item)
      );
      setCheckedValues({
        ads: [...filteredAds],
        campaign: { ...checkedValues.campaign, [campaignId]: [] },
      });
    } else {
      const newAdsVal = [...checkedValues.ads];
      const newCampValues = [...checkedValues.campaign[campaignId]];
      Object.keys(filterData[index][campaignId]).forEach((adsetId: any) => {
        if (!newCampValues.includes(adsetId)) newCampValues.push(adsetId);
        filterData[index][campaignId][adsetId].ads.forEach((item: any) => {
          if (!newAdsVal.includes(item.id)) newAdsVal.push(item.id);
        });
      });
      setCheckedValues({
        ads: [...newAdsVal],
        campaign: {
          ...checkedValues.campaign,
          [campaignId]: [...newCampValues],
        },
      });
    }
  };

  return (
    <DropdownUI
      className="ml-2 dropdown-up-ui hoverable customise-column"
      isDropDownVisible={showFilter}
      setIsDropDownVisible={setShowFilter}
      dropdownItemStyle={{ right: "0", maxHeight: "50vh", minHeight: "30vh" }}
      toggelButton={<FilterIcon style={{ cursor: "pointer" }} />}
    >
      <Box
        sx={{
          padding: "0.75rem",
          width: "400px",
          minHeight: "30vh",
          height: "100%",
        }}
      >
        <Box
          sx={{
            minHeight: "30vh",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Box sx={{ maxHeight: "40vh", overflow: "auto" }}>
            {filterData.map((item: any, ind: number) => (
              <Accordion
                sx={{
                  boxShadow: "unset",
                  ":before": {
                    height: "0",
                    "& AccordionStyled": {
                      margin: "0px !important",
                    },
                    "&Mui-expanded": {
                      margin: "0",
                    },
                  },
                }}
              >
                <AccordionSummary
                  style={{ margin: 0 }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  sx={{
                    width: "100%",
                    margin: "0 !important",
                    "& AccordionStyled": {
                      margin: "0px !important",
                    },
                    "&Mui-expanded": {
                      margin: "0",
                    },
                  }}
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckBox
                      checked={
                        checkedValues.campaign?.[item.id]?.length ==
                        Object.keys(filterData?.[ind]?.[item.id]).length
                      }
                      onClick={() => handleCampaignCheckbox(item.id, ind)}
                    />
                    <Typography
                      sx={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.campaignName}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0 0 0 40px" }}>
                  {Object.keys(item[item.id])?.map(
                    (adsetId: any, index: number) => (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CheckBox
                          checked={checkedValues.campaign?.[item.id].includes(
                            adsetId
                          )}
                          onClick={() =>
                            handleAdSetCheckbox(item.id, adsetId, ind)
                          }
                        />
                        <Typography
                          sx={{
                            maxWidth: "370px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "pre",
                          }}
                        >
                          {item[item.id][adsetId].adsetName}
                        </Typography>
                      </Box>
                    )
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{
                fontSize: "0.75rem",
                fontWeight: "600",
                padding: "14pxrem 24pxrem",
                borderRadius: "0.375rem",
                border: "1.5px solid #E0E3E5",
                color: "#000",
              }}
              onClick={() => setShowFilter(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{
                fontSize: "0.75rem",
                fontWeight: "600",
                padding: "14pxrem 24pxrem",
                background: "#0869FB",
                color: "#fff",
                ":hover": { background: "#0869FB" },
              }}
              onClick={handleApply}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
    </DropdownUI>
  );
};

// function getIframeSrc(iframeHtml:any) {
//   const parser = new DOMParser();
//   const htmlDoc = parser.parseFromString(iframeHtml, 'text/html');
//   const iframe = htmlDoc.querySelector('iframe');
//   return iframe ? iframe.getAttribute('src') : null;
// }

const getPreview = (previewLink: any) => {
  var doc: any = new DOMParser().parseFromString(previewLink ?? "", "text/xml");
  const iframSrc = doc?.firstChild.getAttribute("src");
  return iframSrc;
};
