import { ReactComponent as NoReffrenceAD } from "../../../../../assets/cai/svg/no_reffrence_ad.svg";
import { StyledHomeScreenRightBox } from "../styled";

export default function NoRefferenceAD() {
  return (
    <StyledHomeScreenRightBox
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <NoReffrenceAD />
    </StyledHomeScreenRightBox>
  );
}
