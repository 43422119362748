import React from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import {
  ACTIVE_COLOR,
  COMPLETED_COLOR,
  phaseDetail,
} from "../../utils/commonFunctions";
import { initialState } from "../../store/types/auth";
import "./Journey.scss";

function getColor(val: number, enabled: boolean): string {
  if (!enabled) return "";
  switch (val) {
    case 0:
      return "";
    case 1:
      return ACTIVE_COLOR;
    case 2:
      return COMPLETED_COLOR;
    default:
      return "";
  }
}

function getActivePhase(phases: any) {
  const { phase1, phase2, phase3, phase4, phase5 } = phases || {};
  if (phase1 === 1) return 1;
  if (phase2 === 1) return 2;
  if (phase3 === 1) return 3;
  if (phase4 === 1) return 4;
  if (phase5 === 1) return 5;
  return 0;
}

const getProgressChip = (
  phase: any,
  key: any,
  showToolTip: any,
  setActiveChip: any,
  activePhaseKey: any
) => {
  const selectedDetails = showToolTip
    ? {
        className: "progress-chip",
        key: key,
      }
    : {
        className: "progress-chip active-chip",
        key: activePhaseKey,
      };

  return (
    <div
      className={selectedDetails.className}
      style={{ background: getColor(phase, true), width: "20%" }}
      key={key}
      onMouseOver={() => {
        setActiveChip(selectedDetails.key);
      }}
      onMouseOut={() => {
        setActiveChip("");
      }}
    ></div>
  );
};

const Journey: React.FC<{ enabled: boolean; phases: any }> = ({
  enabled,
  phases,
}) => {
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const [phaseTimeLine, setPhaseTimeLine] = React.useState({} as any);
  const { phase1, phase2, phase3, phase4, phase5 } = phases || {};
  const activePhase = getActivePhase(phases);
  const activePhaseKey = `phase${activePhase}`;
  const [activeChip, setActiveChip] = React.useState<any>("");
  const {agencyAssets} = useSelector((state:any) => state.common);

  React.useEffect(() => {
    setPhaseTimeLine(phaseDetail(authState?.accountDetails?.platform,agencyAssets));
  }, [authState?.accountDetails?.platform,agencyAssets]);

  return (
    <Box className="journey w-100" sx={{ background: "transparent", pt: 0 }}>
      {activeChip && (
        <Box
          className="d-flex flex-column b-1 w-100"
          sx={{
            mb: 1,
            overflow: "visible",
            border: 1,
            borderColor: "#E7E9F1",
            borderRadius: 2,
            p: 1,
            lineHeight: "1",
            background: "white",
          }}
        >
          <Box
            className="w-100"
            sx={{
              overflow: "hidden",
              WebkitLineClamp: 5,
              WebkitBoxOrient: "vertical",
              whiteSpace: "normal",
              display: "-webkit-box",
              fontSize: "0.7rem",
            }}
            title={phaseTimeLine[activeChip]?.description}
          >
            {phaseTimeLine[activeChip]?.description}
          </Box>
        </Box>
      )}
      {!!activePhase && (
        <Box sx={{ mb: 1 }}>
          <Box
            style={{
              fontSize: "1rem",
              color: "#333333",
              fontWeight: "500",
            }}
          >
            {enabled && activePhase
              ? `Phase ${activePhase}: ${phaseTimeLine[activePhaseKey]?.title}`
              : phaseTimeLine?.disabled?.title}
          </Box>
        </Box>
      )}

      {!!enabled && !!activePhase && (
        <>
          <div className="progress-container w-100">
            <div className="progress" style={{ width: "100%" }}>
              {[phase1, phase2, phase3, phase4, phase5].map((element, index) =>
                getProgressChip(
                  element,
                  `phase${index + 1}`,
                  activePhase !== index + 1,
                  setActiveChip,
                  activePhaseKey
                )
              )}
            </div>
          </div>
        </>
      )}
    </Box>
  );
};

export default Journey;
