import { Box, CircularProgress } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfigurationState,
  updateConfigureState,
} from "../../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  CreateVariantsState,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
} from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { Button, CheckBox } from "../../../../../shared";
import { validateLeverSelection } from "../../../utils/filterLevers.utils";
import { getFormValues } from "../../../utils/form.utils";
import { ReactComponent as ArrowLeft } from "../../../../../../assets/svg/arrowLeft.svg";
import {
  StyledFooterDiv,
  StyledRightElement,
  StyledTextVariant1,
} from "./styled";
import { checkAdType } from "components/CreativeAI_Revamp/Facebook/utils/common.utils_old";
import { getRolesHierarchy } from "utils/commonFunctions";
import { RolesWrapper } from "utils/rolesWrapper";
import { AdAccountState } from "store/types/adaccount";

export interface ConfigureFooterProps {
  setIsConfiguring: any;
  onGenerate: (data: any, setIsConfiguring: any) => void;
  handleSubmit: any;
  getValues: any;
  isUpdateAISettingChecked: boolean;
  onUpdateAISettingCheckClick: any;
  watch: any;
  mixPanelCreativeStudioConfigureGenerateClicked: any;
  variantItem: any;
}

export default function ConfigureFooter({
  setIsConfiguring,
  handleSubmit,
  onGenerate,
  getValues,
  isUpdateAISettingChecked,
  onUpdateAISettingCheckClick,
  watch,
  mixPanelCreativeStudioConfigureGenerateClicked,
  variantItem,
}: ConfigureFooterProps) {
  const {
    isGeneratingMetaData,
    isUpdatingAIgroup,
    changedFieldList,
    configureMetaData,
    createSpec,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;
  const dispatch = useDispatch();
  const { CREATIVE_ELEMENT_AI, BUSINESS_UNIT_List } = useSelector(
    (state: any) => ({
      CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi.data,
      BUSINESS_UNIT_List: state.MainReducer.AllBusinessUnitList,
    })
  );
  const businessUnits = useMemo(
    () =>
      BUSINESS_UNIT_List.map((bu: any) => ({
        id: bu.id,
        name: bu.businessUnit,
      })),
    [BUSINESS_UNIT_List]
  );
  const mixPanelCall = () => {
    let primarychecked: any = {};
    let headlinechecked: any = {};
    let descriptionchecked: any = {};
    Object.keys(getValues("primary_text")).forEach(
      (key: any) =>
        (primarychecked[key] = getValues("primary_text")?.[key]?.isChecked)
    );
    Object.keys(getValues("headline")).forEach(
      (key: any) =>
        (headlinechecked[key] = getValues("headline")?.[key]?.isChecked)
    );
    Object.keys(getValues("description")).forEach(
      (key: any) =>
        (descriptionchecked[key] = getValues("description")?.[key]?.isChecked)
    );
    mixPanelCreativeStudioConfigureGenerateClicked(
      variantItem,
      businessUnits.find((item: any) => item?.id == getValues("businessUnitId"))
        ?.name,
      getValues("brandName"),
      getValues("interestKeyword"),
      headlinechecked,
      descriptionchecked,
      primarychecked,
      checkAdType(createSpec) === "video_data"
        ? getValues("video")?.paraphrased_video_text?.isChecked
        : getValues("image")?.paraphrased_image_text?.isChecked,
      checkAdType(createSpec) === "video_data"
        ? "video_checked"
        : "image_checked"
    );
  };
  const onBackClick = () => {
    setIsConfiguring(false);
    dispatch(setConfigurationState(false));
    dispatch(updateConfigureState(getValues()));
    const data = configureMetaData ?? getFormValues(CREATIVE_ELEMENT_AI, true);
    for (const changedField of changedFieldList) {
      let changedValue = data;
      changedField.split(".").forEach((field: string) => {
        changedValue = changedValue[field];
      });
      if (changedValue !== getValues(changedField)) {
        dispatch({
          type: UPDATE_RECOMMENDATION_SELECTION_STATE,
          payload: {
            isConfigureChanged: true,
          },
        });
        break;
      }
    }
  };
  const [disableFlag, setDisableFlag] = useState(false);
  let watchAllFields = watch();

  useEffect(() => {
    setDisableFlag(validateLeverSelection(getValues()));
  }, [watchAllFields]);

  return (
    <StyledFooterDiv>
      <Button
        buttonVariant="text"
        onClick={onBackClick}
        sx={{
          padding: ".75rem 0.5rem .75rem 0.5rem",
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: "Inter",
          color: "#0869FB",
        }}
      >
        <ArrowLeft style={{ marginRight: "0.5em" }} /> Go Back
      </Button>
      <StyledRightElement>
        <CheckBox
          checked={isUpdateAISettingChecked}
          size="small"
          onClick={onUpdateAISettingCheckClick}
        />
        <StyledTextVariant1>
          Update these settings for the AI Group
        </StyledTextVariant1>
        <RolesWrapper
          show={true}
          // disabled={isViewerRole(brandRoles) || isEditorRole(brandRoles)}
          disabled={
            getRolesHierarchy(brandRoles) === "viewer"
          }
        >
          <Button
            type="submit"
            buttonVariant="contained"
            disabled={disableFlag}
            onClick={handleSubmit((data: any) => {
              onGenerate(data, setIsConfiguring);
              mixPanelCall();
            })}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "Inter",
            }}
          >
            {isGeneratingMetaData || isUpdatingAIgroup ? (
              <Box width="90px" height="25px">
                <CircularProgress
                  size={25}
                  sx={{
                    color: "white",
                  }}
                  thickness={5}
                />
              </Box>
            ) : (
              "Generate"
            )}
          </Button>
        </RolesWrapper>
      </StyledRightElement>
    </StyledFooterDiv>
  );
}
