import React from 'react';

const TableElement:React.FC<{
    type: string;
} & React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>> = ({ 
    type, children, ...props 
}) => {
    const CustomTagName:HTMLTableDataCellElement | any = type;

    return <CustomTagName {...props}>
        {children}
    </CustomTagName>
}

export default TableElement;