
export const userDetailForm = [
    {
      type: "text",
      label: "Authorised reprensentative full name",
      key: "authorisedFullName",
      show: true,
      initialvalue: "",
    },
];

export const viewInvoices = "https://agreement-dashboard.web.app/";

export const UserDetailsFormValidationFields = [
    "userEmail",
    "authorisedFullName",
];