export const GET_ADACCOUNT_SUMMARY = "GET_ADACCOUNT_SUMMARY";
export const GET_BRAND_LEVEL_METRICS = "GET_BRAND_LEVEL_METRICS";
export const UPDATE_RESULT_METRICS_AND_BUDGET = "UPDATE_RESULT_METRICS_AND_BUDGET";
export const SET_AD_ACCOUNT_METRICS="SET_AD_ACCOUNT_METRICS"
export const GET_AD_ACCOUNT_METRICS="GET_AD_ACCOUNT_METRICS"
export const RESET_AD_ACCOUNT_METRICS="RESET_AD_ACCOUNT_METRICS"

export type GetAdAccountSummary = {
  type: typeof GET_ADACCOUNT_SUMMARY;
  payload: {
    auth: any;
    brandId: string;
  };
};

export type GetBrandLevelMetrics = {
  type: typeof GET_BRAND_LEVEL_METRICS;
  payload: {
    auth: any;
    brandId: string;
    month: number;
    year: number;
  };
};

export type UpdateResultMetricsAndBudget = {
    type: typeof UPDATE_RESULT_METRICS_AND_BUDGET;
    payload: any;
  };
  export type SetAdAccountMetrics = {
    type: typeof SET_AD_ACCOUNT_METRICS;
    payload: any;
  };

  export type ResetAdAccountMetrics = {
    type: typeof RESET_AD_ACCOUNT_METRICS;
    payload: any;
  };
