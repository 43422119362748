import * as React from "react";

import { Stack } from "@mui/material";
import BuPlaceHolderImage from "../../../../assets/svg/buPlaceHolder.svg";
import { StyledPlaceholderContainer } from "./styled.BuPlaceHolder";

const BuPlaceHolder: React.FC<{}> = () => {
  return (
    <Stack
      direction="row"
      height="40vh"
      justifyContent="center"
      alignItems="center"
    >
      <StyledPlaceholderContainer
        direction="column"
        width="auto"
        justifyContent="center"
        alignItems="center"
      >
        <img src={BuPlaceHolderImage} />
        <h3 className="title">No Business Unit added for this Brand.</h3>
        <p className="description">
          Please click on "Add Business Unit" on top right to add a new Business
          Unit
        </p>
      </StyledPlaceholderContainer>
    </Stack>
  );
};
export default BuPlaceHolder;
