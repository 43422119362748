import { SORT_FIELDS, SORT_FIELDS_FOR_LIVE } from "../constants/subheader";
import { DraftedCohortFilters } from "../../../store/types/AudienceManager/index";
import {
  PublishedCohortsFilter,
  CommonFilters,
} from "../../../store/types/AudienceManager/index";
import { COHORT_TYPES } from "../constants";
export const handlePayloadForDrafted = (
  sort: string,
  type: string,
  filterData: CommonFilters,
  advancedFilterData: DraftedCohortFilters,
  isFilterSet:boolean
) => {
  const payload: any = {};
  if (filterData.type.length < 2) {
    payload.type = filterData.type[0];
  }
  payload.status = type === "drafted" ? "isDrafted" : "isRecommended";
  if (sort) {
    let field: string = sort.split(":")[0].toLowerCase().replaceAll(" ", "");
    let order = sort.split(":")[1].toLowerCase().replaceAll(" ", "");
    if (order === "oldestfirst" || order === "lowtohigh") order = "asc";
    else order = "desc";

    payload.sortBy = { field: SORT_FIELDS[field], order };
  }
  if(!isFilterSet) return payload;
  payload.brand_name = JSON.stringify(filterData.selectedBrand);
  payload.brand_website = JSON.stringify(filterData.selectedBrandWebsite);
  payload.cohort_type = JSON.stringify(
    filterData.selectedCohortTypes.map(
      (i: string) => {
        if(i===COHORT_TYPES[1] || i===COHORT_TYPES[2])
          return i.replace(" ","_")
        if(i===COHORT_TYPES[0])
          return i.split(" ")[0]

        return i.toLowerCase().split(" ")[0]}
    )
  );
  payload.date_range = {
    start: filterData.selectedDateRange.startDate,
    end: filterData.selectedDateRange.endDate,
  };

  if (Object.keys(advancedFilterData).length === 0) return;

  payload.relevance_score = {
    min: advancedFilterData?.relevance_score?.selected
      ? advancedFilterData?.relevance_score?.selected[0]
      : advancedFilterData?.relevance_score?.min,
    max: advancedFilterData?.relevance_score?.selected
      ? advancedFilterData?.relevance_score?.selected[1]
      : advancedFilterData?.relevance_score?.max,
  };
  payload.audience_size = {
    min: advancedFilterData?.audience_size?.selected
      ? advancedFilterData?.audience_size?.selected[0]
      : advancedFilterData?.audience_size?.min,
    max: advancedFilterData?.audience_size?.selected
      ? advancedFilterData?.audience_size?.selected[1]
      : advancedFilterData?.audience_size?.max,
  };
  return payload;
};

export const handlePayloadForLive = (
  sort: string,
  filterData: CommonFilters,
  advancedFilterData: PublishedCohortsFilter,
  isFilterSet:boolean,
) => {
  const payload: any = {};
  if (filterData.type.length < 2) {
    payload.type = filterData.type[0];
  }
  if (sort) {
    let field = sort.split(":")[0].toLowerCase().replaceAll(" ", "");
    if (field === "publisheddate") field = "createdAt";

    let order = sort.split(":")[1].toLowerCase().replaceAll(" ", "");
    if (order === "oldestfirst" || order === "lowtohigh") order = "asc";
    else order = "desc";
    payload.sortBy = { field: SORT_FIELDS_FOR_LIVE[field], order };
  }
  // if (!filterData && !advancedFilterData) return payload;
  if(!isFilterSet) return payload;
  payload.brand_name = JSON.stringify(filterData.selectedBrand);
  payload.brand_website = JSON.stringify(filterData.selectedBrandWebsite);
  payload.cohort_type = JSON.stringify(
    filterData.selectedCohortTypes.map(
      (i: string) => {
        if(i===COHORT_TYPES[1] || i===COHORT_TYPES[2])
        return i.replace(" ","_")
        if(i===COHORT_TYPES[0])
          return i.split(" ")[0]

        return i.toLowerCase().split(" ")[0]}
    )
  );
  payload.date_range = {
    start: filterData.selectedDateRange.startDate,
    end: filterData.selectedDateRange.endDate,
  };

  if (Object.keys(advancedFilterData).length === 0) return;

  if (advancedFilterData.selectedAdAccount)
    payload.ad_account_id = advancedFilterData.selectedAdAccount;

  payload.cpr = {
    min: advancedFilterData?.cpr?.selected
      ? advancedFilterData?.cpr?.selected[0]
      : advancedFilterData?.cpr?.min,
    max: advancedFilterData?.cpr?.selected
      ? advancedFilterData?.cpr?.selected[1]
      : advancedFilterData?.cpr?.max,
  };
  payload.spends = {
    min: advancedFilterData?.spends?.selected
      ? advancedFilterData?.spends?.selected[0]
      : advancedFilterData?.spends?.min,
    max: advancedFilterData?.spends?.selected
      ? advancedFilterData?.spends?.selected[1]
      : advancedFilterData?.spends?.max,
  };
  payload.results = {
    min: advancedFilterData?.results?.selected
      ? advancedFilterData?.results?.selected[0]
      : advancedFilterData?.results?.min,
    max: advancedFilterData?.results?.selected
      ? advancedFilterData?.results?.selected[1]
      : advancedFilterData?.spends?.max,
  };
  return payload;
};
