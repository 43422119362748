import React, { Fragment, useEffect, useState } from "react";
import {
  StyledHomeScreenBodyBox,
  StyledHomeScreenBodyBoxEmpty,
} from "../styled";
import HomeScreenLeftPanel from "../HomeScreenLeftPanel";
import HomeScreenRightPanel from "../HomeScreenRightPanel";
import Editor from "components/CreativeAI/Facebook/CreateVariants/Editor/Editor";
import { useDispatch, useSelector } from "react-redux";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { setSelectedAd } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import NoRefferenceAD from "../HelperUIS/NoRefferenceAD";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { useCaiFbMixPanel } from "../hooks/useCaiFbMixPanel";
import NoAdsAvailable from "components/CreativeAI/GsaiPlayground/Shared/GsaiLandingPageCard/NoAdsAvailable";
import noAIGroup from "../../../../../assets/svg/no_ai_group.svg";

export default function HomeScreenBody() {
  const [selectedAdIndex, setSelectedAdIndex] = useState(0);
  const [adsSortFilterLoading, setAdsSortFilterLoading] = useState(false);
  const [filteredAdLength, setFilteredAdLength] = useState(0);
  const dispatch = useDispatch();
  const { mixPanelReferenceAdSelected } = useCaiFbMixPanel();
  const handelAdsSelect = (item: any, index: number) => {
    mixPanelReferenceAdSelected(item);
    dispatch(setSelectedAd(item));
    setSelectedAdIndex(index);
  };

  const {
    ads,
    adsOtherState,
    extraFields: { showEditor },
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );

  const { adsList, aiGroupList } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );

  return (
    <Fragment>
      {aiGroupList.data.length > 0 || aiGroupList.isLoading ? (
        <StyledHomeScreenBodyBox>
          <HomeScreenLeftPanel
            adsSortFilterLoading={adsSortFilterLoading}
            setFilteredAdLength={setFilteredAdLength}
            setAdsSortFilterLoading={setAdsSortFilterLoading}
            handelAdsSelect={handelAdsSelect}
            selectedAdIndex={selectedAdIndex}
            setSelectedAdIndex={setSelectedAdIndex}
          />
          {!adsOtherState?.isLoading &&
          (filteredAdLength === 0 || ads?.length === 0) ? (
            <NoRefferenceAD />
          ) : (
            <HomeScreenRightPanel adsSortFilterLoading={adsSortFilterLoading} />
          )}
        </StyledHomeScreenBodyBox>
      ) : (
        <StyledHomeScreenBodyBoxEmpty>
          <NoAdsAvailable
            imageSrc={noAIGroup}
            title="No Data Present"
            subTitle="Oops! Looks like you have not linked your Facebook ad account, to start using this feature please contact the customer support team. 
"
            paddingNeeded
          />
        </StyledHomeScreenBodyBoxEmpty>
      )}
      {showEditor && <Editor />}
    </Fragment>
  );
}
