import { ReactComponent as LiveAudience } from "../assets/LiveAudience.svg";
import { ReactComponent as Draft } from "../assets/Draft.svg";
import { ReactComponent as Recommended } from "../assets/Recommended.svg";
import { ReactComponent as Facebook } from "../../../components/SideBar/assets/Facebook.svg";
import { ReactComponent as GoogleNew } from "../../../components/SideBar/assets/GoogleNew.svg";
import {
  TilesProps,
  SelectedCohortTypeEnum,
  PublishSortTypeEnum,
  DraftedSortTypeEnum,
} from "../../../store/types/AudienceManager/index";
import { AgencyAssets } from "store/types/common";

export const getTileOptions = (agencyAssets: AgencyAssets): TilesProps[] => [
  {
    text: "Live Audience",
    icon: <LiveAudience />,
    key: SelectedCohortTypeEnum.live,
  },
  {
    text: `${agencyAssets?.title || "Pixis"} Drafts`,
    icon: <Draft />,
    key: SelectedCohortTypeEnum.drafted,
  },
  {
    text: `${agencyAssets?.title || "Pixis"} Recommended`,
    icon: agencyAssets.logo ? (
      <div
        style={{
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#0869FB1A",
          borderRadius: "8px",
        }}
      >
        <img
          src={agencyAssets.logo}
          style={{ width: "19px", height: "19px" }}
          alt="logo-icon"
        />
      </div>
    ) : (
      <Recommended />
    ),
    key: SelectedCohortTypeEnum.recommended,
  },
];

export const getTileOptionsYoutube = (
  agencyAssets: AgencyAssets
): TilesProps[] => [
  {
    text: `${agencyAssets?.title || "Pixis"} Recommended`,
    icon: agencyAssets.logo ? (
      <div
        style={{
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#0869FB1A",
          borderRadius: "8px",
        }}
      >
        <img
          src={agencyAssets.logo}
          style={{ width: "19px", height: "19px" }}
          alt="logo-icon"
        />
      </div>
    ) : (
      <Recommended />
    ),
    key: SelectedCohortTypeEnum.recommended,
  },
];

export const SORT_OPTIONS = {
  [SelectedCohortTypeEnum.live]: Object.values(PublishSortTypeEnum),
  [SelectedCohortTypeEnum.drafted]: Object.values(DraftedSortTypeEnum),
  [SelectedCohortTypeEnum.recommended]: Object.values(DraftedSortTypeEnum),
};

export const PLATFORM_VALUE_MAPPING = {
  facebook: "facebook",
  gSearch: "gSearch",
  youtube: "youtube",
};

export const PLATFORM_AD_TYPE = {
  gSearch: "GOOGLE SEARCH AD",
  youtube: "GOOGLE VIDEO AD",
  facebook: "FACEBOOK AD",
};
export const TILE_DROPDOWN_VALUES = [
  {
    label: "Meta",
    Icon: <Facebook />,
    isDisabled: false,
    value: PLATFORM_VALUE_MAPPING.facebook,
  },
  {
    label: "G Search",
    Icon: <GoogleNew />,
    isDisabled: false,
    value: PLATFORM_VALUE_MAPPING.gSearch,
  },
  {
    label: "YouTube",
    Icon: <GoogleNew />,
    isDisabled: false,
    value: PLATFORM_VALUE_MAPPING.youtube,
  },
];

export const PLATFORM_URL_MAPPING: any = {
  gSearch: "google-search",
  youtube: "google-video",
};

export const SORT_FIELDS: any = {
  publisheddate: "createdAt",
  "saved/recommendationdate": "createdAt",
  potentialaudiencesize: "audienceSize",
  relevancescore: "relevance",
  performancescore: "performanceScore",
};

export const SORT_FIELDS_FOR_LIVE: any = {
  createdAt: "createdAt",
  spends: "spends",
  results: "results",
  cpr: "cpr",
};

export const OPTIONS_MAPPING = {
  facebook: TILE_DROPDOWN_VALUES[0],
  gSearch: TILE_DROPDOWN_VALUES[1],
  youtube: TILE_DROPDOWN_VALUES[2],
};
