import React, { useEffect, useState } from "react";
import { getBrandsAPI } from "./API/getBrands";
import { addBrandAPI } from "./API/addBrand";
import { editBrandAPI } from "./API/editBrand";
import { useSelector } from "react-redux";

export const useManageAgencyBrandsState = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [brandsListData, setBrandsListData] = useState([]);
  const agency = useSelector((state: any) => state?.smb?.agencies?.selected);
  const agencyId = agency?.id;
  const [page, setPage] = useState(0);
  const [limit, setLimit] = React.useState(50);
  const [totalBrandsCount, setTotalBrandsCount] = useState();

  useEffect(() => {
    agencyId && getBrands(page + 1, limit);
  }, [page, agencyId]);

  const getBrands = (page: any, limit: any) => {
    setIsLoading(true);
    getBrandsAPI(agencyId, page, limit, "", "DESC", "createdAt").then(
      (data: any) => {
        if (data.success) {
          setIsError(false);
          setBrandsListData(data?.data?.brands);
          setTotalBrandsCount(data?.data?.totalCount);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setIsError(true);
        }
      }
    );
  };

  const addBrand = async (addBrandPayload: any) => {
    return addBrandAPI(addBrandPayload);
  };

  const editBrand = async (editBrandPayload: any, brandId: string) => {
    return editBrandAPI(editBrandPayload, brandId).then((data: any) => {});
  };

  return {
    isLoading,
    isError,
    brandsListData,
    getBrands,
    addBrand,
    editBrand,
    page,
    setPage,
    limit,
    setLimit,
    totalBrandsCount,
  };
};
