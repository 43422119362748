import React from "react";
import { Box, Button } from "@mui/material";
import { ReactComponent as Logo } from "../Assets/logo.svg";

const Container: React.FC<{
  saveButtonText?: string;
  onClickNext?: any;
  disable: any;
}> = ({
  children,
  onClickNext,
  disable,
  saveButtonText = "Next",
}): JSX.Element => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        background: "#efefef",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: "69%",
          width: "27%",
          minHeight: "682px",
          maxWidth: "520px",
          minWidth: "509px",
          background: "white",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "85%" }}>
          <Box
            sx={{
              height: "11%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Logo />
          </Box>
          <Box sx={{ height: "80%", width: "100%" }}>{children}</Box>
          <Box
            sx={{
              height: "9%",
              width: "100%",
              borderTop: "1px solid #DDDDDD",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              onClick={onClickNext}
              disabled={!disable}
              sx={{
                height: "40px",
                width: "80px",
                background: "#0869FB",
                color: "white",
                fontSize: "14px",
                borderRadius: "8px",
                fontWeight: "600",
                fontFamily: "inter",
                textTransform: "unset",
                "&:hover": { background: "#0869FB" },
                ":disabled": { opacity: "0.5", color: "white" },
              }}
            >
              {saveButtonText}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Container;
