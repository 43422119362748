import React, { memo, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle } from "react-flow-renderer";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CircularProgress from "../common/CircularProgress";
import { ReactComponent as Close } from "../ReactFlowComponents/Assets/svgs/close.svg";
import { ReactComponent as EditTai } from "../ReactFlowComponents/Assets/svgs/EditTai.svg";
import "./BasicSetup.scss";
import { TaiContext } from "../TaiContextProvider";
import useFocusToNode from '../useFocusToNode';
import { convertToKFormat } from "../utils/facebook";
import CustomAudienceSection from "./CustomAudienceSection";

export default memo(({ data, isConnectable }) => {
  const dispatch = useDispatch();
  const { setNodes, setEdges, setPopover, setPopoverFlag, cohortType } =
    useContext(TaiContext);

  const callibrationData = useSelector((state) => state.calibration);
  const { formData } = callibrationData;
  const loaderState = useSelector((state) => state.taiCommon.loader);

  const [show, setShow] = useState(false);
  const [showCustomAudience,setShowCustomAudience] = useState(false);
  function listener(e) {
    const tooltip = document.querySelector("[role=tooltip]");
    const eyeButton = document.querySelector(".custom-popover-btn");
    if (
      show &&
      tooltip &&
      !tooltip.contains(e.target) &&
      eyeButton !== e.target
    ) {
      setShow(false);
      document.body.removeEventListener("click", listener);
    }
  }

  useEffect(() => {
    if (show) {
      setPopoverFlag(true);
      document.querySelector("body").addEventListener("click", listener);
    } else {
      document.body.removeEventListener("click", listener);
    }
  }, [show, setShow]);

  const focusToNode = useFocusToNode();

  return (
    <div className="callibration-collapsed">
      <Handle
        type="target"
        position="left"
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="right"
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div className="collapsed-header">
        <div className="collapsed-heading">Calibration</div>
        <div
          className="header-icon"
          onClick={() =>
            data.onEditClick(
              dispatch,
              loaderState,
              setNodes,
              setEdges,
              cohortType,
              focusToNode
            )
          }
        >
          <EditTai />
        </div>
      </div>
      <hr />
      <div className="collapsed-content ">
        <div
          className={`collapsed-strip strip-column ${
            formData.ExploitValueCheck ? "" : "half-opacity"
          }`}
        >
          <div className="strip-half">
            <div>Explore</div>
            <div className="strip-progress">
              {formData.ExploitValueCheck ? 100 - formData?.ExploitValue : 0}%
            </div>
          </div>
          <div className="strip-half">
            <div>Exploit</div>
            <div className="strip-progress">
              {formData.ExploitValueCheck ? formData?.ExploitValue : 0}%
            </div>
          </div>
        </div>
        {formData?.TypeOfCohort === "ICP" ? (
          <div className={`collapsed-strip`}>
            <div className="strip-full-width">
              <div>Description</div>
              <div className="details">{formData?.ICPContextDescription}</div>
            </div>
          </div>
        ) : (
          <>
            <div className="collapsed-strip" style={{
              padding:showCustomAudience? "4px":"0px"
            }}>
              <div
                className={`${showCustomAudience ? "" : "p-0"}`}
                style={{
                  display:'flex',
                  justifyContent:'space-between',
                }}
              >
                <div style={{
                  paddingLeft:showCustomAudience ? "6px" : "10px"
                }}>Custom Audience</div>
                {!showCustomAudience && <button type="button" className="btn btn-link underline underline-offset-0"  style={{
                  color:'#0869FB',
                  textDecoration:'none',
                  marginRight:0
                }} onClick={()=>setShowCustomAudience(true)}>View</button>}
              </div>
              {showCustomAudience && <CustomAudienceSection selectedCustomAudience={callibrationData.formData.selectedCustomAudience}/>}              
            </div>
          </>
        )}
      </div>
    </div>
  );
});
