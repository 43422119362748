import { styled } from "@mui/material";
import { Box } from "@mui/system";

export const StyledLayerTitleText = styled(Box)`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
`;

export const StyledLayerBox = styled(Box)`
  background: #f2f2f2;
  border-radius: 6px;
  padding: 10px 14px;
`;
