import httpService from "HttpService/HttpService";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  setAIGroups,
  setAdAccounts,
  setAdSetsFB,
  setAdsFB,
} from "store/actions/CreativeAI_Revamp/facebook.actions";
import {
  GET_ADSETS_FB,
  GET_ADS_FB,
  GET_AD_ACCOUNTS_FB,
  GET_AI_GROUPS,
  GetAIGroups,
  GetAdAccounts,
  GetAdSetsFB,
  GetAdsFB,
} from "store/types/CreativeAI_Revamp/facebook.types";
import { LooseObject } from "types/GlobalTypes";
import { BASE_URL } from "utils/path";

const appendParam = (params: LooseObject) => {
  return (
    "?" +
    Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")
  );
};

function* fetchAIgroups({ payload }: GetAIGroups): any {
  const { brandId, params } = payload;

  try {
    let url = `/v1/api/brand/${brandId}/aigroups`;
    if (params) {
      url += appendParam(params);
    }
    const response = yield call(httpService.get, BASE_URL, url);
    if (response.data.success) {
      const selection = response.data.data.aiGroups.find(
        (item: any) => item?.Elements?.[0]?.ElementCreativeAiData?.[0]?.id
      );

      yield put(
        setAIGroups({
          isFailed: false,
          isLoading: false,
          data: response.data.data.aiGroups,
          selection: selection ?? response.data.data.aiGroups[0],
          selectedAIGroup: selection ?? response.data.data.aiGroups[0],
          isSearching: false,
        })
      );
    } else {
      yield put(
        setAIGroups({
          isFailed: true,
          isLoading: false,
          isSearching: false,
          data: [],
        })
      );
    }
  } catch (error) {
    yield put(
      setAIGroups({
        isFailed: true,
        isLoading: false,
        isSearching: false,
        data: [],
      })
    );
  }
}

function* fetchAdAccounts({ payload }: GetAdAccounts): any {
  const { aiGroupId, params } = payload;

  try {
    let url = `/v2/api/ai-groups/${aiGroupId}/ad-accounts`;
    if (params) {
      url += appendParam(params);
    }
    const response = yield call(httpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put(
        setAdAccounts({
          isFailed: false,
          isLoading: false,
          data: response.data.data.elements.map((item: any) => ({
            ...item,
            adAccountId: item.AdAccount.adAccountId,
            name: item.AdAccount.name,
          })),
        })
      );
    } else {
      yield put(
        setAdAccounts({
          isFailed: true,
          isLoading: false,
          data: [],
        })
      );
    }
  } catch (error) {
    yield put(
      setAdAccounts({
        isFailed: true,
        isLoading: false,
        data: [],
      })
    );
  }
}

function* fetchAdSets({ payload }: GetAdSetsFB): any {
  const { elementId, params } = payload;

  try {
    let url = `/v1/api/adset-creative-ai/elements/${elementId}/adsets`;
    if (params) {
      url += appendParam(params);
    }
    const response = yield call(httpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put(
        setAdSetsFB({
          isFailed: false,
          isLoading: false,
          data: response.data.data.adsets,
        })
      );
    } else {
      yield put(
        setAdSetsFB({
          isFailed: true,
          isLoading: false,
          data: [],
        })
      );
    }
  } catch (error) {
    yield put(
      setAdSetsFB({
        isFailed: true,
        isLoading: false,
        data: [],
      })
    );
  }
}

function* fetchAds({ payload }: GetAdsFB): any {
  const { adSetId, params } = payload;

  try {
    let url = `/v1/api/adset-creative-ai/adsets/${adSetId}/ads`;
    if (params) {
      url += appendParam(params);
    }
    const response = yield call(httpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put(
        setAdsFB({
          isFailed: false,
          isLoading: false,
          data: response.data.data.data,
          filteredData: response.data.data.data,
        })
      );
    } else {
      yield put(
        setAdsFB({
          isFailed: true,
          isLoading: false,
          data: [],
          filteredData: [],
        })
      );
    }
  } catch (error) {
    yield put(
      setAdsFB({
        isFailed: true,
        isLoading: false,
        data: [],
        filteredData: [],
      })
    );
  }
}

export function* facebookSageWatcher() {
  yield takeLatest(GET_AI_GROUPS, fetchAIgroups);
  yield takeLatest(GET_AD_ACCOUNTS_FB, fetchAdAccounts);
  yield takeLatest(GET_ADSETS_FB, fetchAdSets);
  yield takeLatest(GET_ADS_FB, fetchAds);
}
