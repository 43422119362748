export const GET_ADSET_BY_ID = "GET_ADSET_BY_ID";
export const SET_ADSET_DETAILS = "SET_ADSET_DETAILS";
export const TRIGGER_CONTACT_US = "TRIGGER_CONTACT_US";
export const TRIGGER_CONTACT = "TRIGGER_CONTACT";

export type GetAdsetById = {
  type: typeof GET_ADSET_BY_ID;
  payload: any;
  callback?: Function;
};

export type SetAdsetById = {
  type: typeof SET_ADSET_DETAILS;
  payload: any;
  callback?: Function;
};
export type TriggerContactUs = {
  type: typeof TRIGGER_CONTACT_US;
  payload: {
    user: any;
    data: {
      adAccountId: string;
      aiGroupId: string;
      adId: string;
      adType: string;
    };
  };
  callback?: Function;
};
