import { aiGroups, parentAiGroups } from "MappedAPI/MappedAPI";
import HttpService from "HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "utils/path";
import { GroupType } from "types/GlobalTypes";

export const getPacingsList = async (aiGroupId: string, type: string) => {
  let response = {
    success: false,
    message: "",
    data: [],
  };
  try {
    let urlParams = `${
      type === GroupType.group ? aiGroups : parentAiGroups
    }${aiGroupId}/budget-planners?status=ACTIVE,SCHEDULE,COMPLETED,UNCONFIGURED,ABORTED`;
    response = await HttpService.get(CROSS_PLATFORM_URL, urlParams).then(
      (data: any) => {
        return data.data;
      }
    );
    return response;
  } catch (error: any) {
    console.log("getPacingsList => ", error.message);
  }
  return response;
};
