import React, { useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import Toggle from "react-toggle";
import HeadingClibrationsGS from "./HeadingClibrationsGS";
import { useSelector, useDispatch } from "react-redux";
import { setGSCalibrationData } from "store/actions/gsTargetingAI";
import { Handle, Position } from "react-flow-renderer";

function ExploreGS({ isConnectable }: any) {
  const dispatch = useDispatch();
  const exploreValueCheck = useSelector(
    (state: any) => state.gsTargetingAI.gsCalibration.data.ExploreValueCheck
  );
  return (
    <Box
      className="node-container-common-style"
      sx={{
        position: "absolute",
        top: "55%",
        left: "3%",
        width: "18.37rem",
        height: "8rem",
        px: 2,
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
        id="explore"
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 1 }}
      >
        <HeadingClibrationsGS heading="Explore" subHeading="" />
        <Toggle
          className="right-0 custom-react-toggle"
          icons={false}
          id="biscuit-status"
          checked={exploreValueCheck}
          aria-labelledby="biscuit-label"
          onChange={() => {
            if (exploreValueCheck) {
              dispatch(
                setGSCalibrationData({
                  ExploreValueCheck: false,
                  CalibrationCustomKeywords: [],
                  GoogleKwsPlannerCheck: false,
                  CalibrationCustomKeywordsCheck: false,
                })
              );
            } else {
              dispatch(
                setGSCalibrationData({
                  ExploreValueCheck: true,
                })
              );
            }
          }}
        />
      </Stack>
      <hr style={{ margin: "0.5rem 0" }} />

      <Typography
        className="font-inter"
        sx={{
          fontWeight: 400,
          fontSize: 10,
          lineHeight: "0.75rem",
          width: "15.7rem",
        }}
      >
        <span>
          Explore refers to the new keywords recommended by the AI, for better
          performance.
        </span>
        <br />
        <br />
        <span>
          Calibrate the keyword sources and add custom keywords to match your
          requirements.
        </span>
      </Typography>
    </Box>
  );
}

export default ExploreGS;
