import React from "react";
import { Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

function InsightsNavigation(props: {
  selectedAdAcc: { adAccountId: string; adAccountName: string };
  parentPlatforms: any[];
  setSelectedAdAcc: Function;
}) {
  return (
    <Row className="dropdown-row">
      <Col lg={4} className="d-flex align-items-center">
        <h4 className="w-auto m-0 font-weight-bold text-xl-left fs-6 navigation--header">
          Ad Account
        </h4>
      </Col>
      <Col lg={8}>
        <span className="w-auto">
          <div>
            <Dropdown className="hoverable position-relative navigation-dropdown-btn">
              <Dropdown.Toggle>
                <div className="text-left text-dark text-truncate">
                  {(props.selectedAdAcc?.adAccountId &&
                    (props.selectedAdAcc?.adAccountName
                      ? props.selectedAdAcc?.adAccountName + " - "
                      : "") + props.selectedAdAcc?.adAccountId) ||
                    ` No Adaccount Found`}
                </div>
                <span className="dropdown_arrow">
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ minWidth: "220px" }}>
                {props.parentPlatforms.map((platform: any) => {
                  if (platform.addAccounts?.length) {
                    return (
                      <>
                        <Dropdown.Item
                          disabled={true}
                          className="text-lg"
                          style={{
                            fontSize: "0.9rem !important",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {platform.name}{" "}
                        </Dropdown.Item>
                        {platform.addAccounts.map((data: any) => {
                          return (
                            <Dropdown.Item className={"py-0 px-3"}>
                              <label
                                className="flex-center"
                                onClick={(e) => {
                                  const adacc = platform.addAccounts.find(
                                    (g: any) =>
                                      g.adAccountId === data.adAccountId
                                  );
                                  props.setSelectedAdAcc(adacc);
                                }}
                              >
                                <input
                                  type="radio"
                                  name="paltform-nav"
                                  value={data.selectedElementId}
                                  checked={
                                    props.selectedAdAcc?.adAccountId ===
                                    data.adAccountId
                                  }
                                  defaultChecked={
                                    props.selectedAdAcc?.adAccountId ===
                                    data.adAccountId
                                  }
                                />
                                <div className="radio-label text-md ml-1">
                                  <div className="account-name">
                                    {data.adAccountName !== "NA" &&
                                    data.adAccountName !== null
                                      ? data.adAccountName
                                      : data.adAccountId}
                                  </div>
                                  <div className="account-id">
                                    {data.adAccountId}
                                  </div>
                                </div>
                              </label>
                            </Dropdown.Item>
                          );
                        })}
                      </>
                    );
                  }
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </span>
      </Col>
    </Row>
  );
}

export default InsightsNavigation;
