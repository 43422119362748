import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CheckBox, StyledText } from "components/shared";
import { StyledPaper, StyledTableContainer } from "../styled";
import TableMetricComponent from "./TableMetricComponet";
import { Fragment } from "react";
import InHousePreviewModal from "./InHousePreviewModal";

import CRTooltipWithAdDetails from "./CRTooltipWithAdDetails";
import { LooseObject } from "types/GlobalTypes";
import { Skeleton } from "@mui/material";
import {
  BatchItemType,
  CreativeRotationInitialState,
  GetBatchDetailsItemType,
  SetPreviewItemPayload,
  SetPreviewPayload,
} from "store/types/ActionDebugger/CreativeRotation/creativeRotation.types";
import { crTimezone } from "utils/date.util";
import CRTableRow from "./CRTableRow";
import { useSelector } from "react-redux";
import { getAssetColorMapping } from "../common.utils";

export interface HeadCell {
  id: string;
  label: string;
  numeric?: boolean;
  showDivider?: boolean;
  tooltip?: LooseObject;
  width?: string;
}

// const headCells: readonly HeadCell[] = [
//   {
//     id: "name",
//     label: "Items to Change",
//     showDivider: true,
//     width: "22.2em",
//   },
//   {
//     id: "recommendation",
//     label: "Recommendation",
//     showDivider: true,
//     width: "1em",
//   },
//   {
//     id: "action",
//     label: "Action Basis",
//     width: "11.7em",
//     tooltip: {
//       component: (
//         <Fragment>
//           <li>
//             These numbers reflect the variation in results for different metrics
//             that led to this recommendation
//           </li>
//           <li>
//             This comparison is between the current and previous batch of
//             recommendations.
//           </li>
//         </Fragment>
//       ),
//     },
//   },
//   {
//     id: "cpa",
//     numeric: true,
//     label: "CPR",
//     width: "10em",
//   },
//   {
//     id: "spend",
//     numeric: true,
//     label: "Spend",
//     width: "11.3em",
//   },
//   {
//     id: "frequency",
//     numeric: true,
//     label: "Frequency",
//     width: "11em",
//   },

//   {
//     id: "engagement",
//     numeric: true,
//     label: "Engagement",
//     showDivider: true,
//     width: "10.85em",
//   },
//   {
//     id: "time",
//     label: "Execution Time",
//     showDivider: true,
//     width: "10.8em",
//   },
//   {
//     id: "status",
//     numeric: false,
//     label: "Status",
//   },
// ];

export function CRAccordionTableItemLoader() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      minWidth={0}
      gap="1.8em"
      width="100%"
      p="1em 1.5em"
      pb=".5em"
    >
      {Array(3)
        .fill(0)
        .map(() => (
          <Skeleton
            variant="rectangular"
            height="4em"
            sx={{
              borderRadius: "6px",
            }}
          />
        ))}
    </Box>
  );
}

function EnhancedTableHead({ metricType, batchItem }: { metricType: string, batchItem? : BatchItemType }) {
  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      label: "Items to Change",
      showDivider: true,
      width: "17%",
    },
    {
      id: "recommendation",
      label: "Recommendation",
      showDivider: true,
      width: "12%",
    },
    {
      id: "action",
      label: "Action Basis",
      width: "12%",
      tooltip: {
        component: (
          <Fragment>
            <li>
              These numbers reflect the variation in results for different metrics
              that led to this recommendation
            </li>
            <li>
              This comparison is between the current and previous batch of
              recommendations.
            </li>
          </Fragment>
        ),
      },
    },
    {
      id: "spend",
      numeric: true,
      label: "Ad Daily Avg. Spends",
      width: "12%",
    },
    {
      id: "cpa",
      numeric: true,
      label: metricType,
      width: "9%",
    },
    {
      id: "frequency",
      numeric: true,
      label: "Frequency",
      width: "9%",
    },

    {
      id: "ctr",
      numeric: true,
      label: "CTR",
      showDivider: true,
      width: "9%",
    },
    {
      id: "time",
      label: "Execution Time",
      showDivider: true,
      width: "10%",
    },
    {
      id: "status",
      numeric: false,
      label: "Status",
      width: "10%",
    },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell, index: number) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "center" : "left"}
              padding="none"
              sx={{
                borderRight: headCell?.showDivider
                  ? "1px solid #e5e5e5"
                  : "none",
                width: headCell.width+' !important' || '',
                minWidth: headCell.width+' !important' || '',
                maxWidth: headCell.width+' !important' || '',
              }}
            >
              {headCell.tooltip ? (
                <Box display="flex" alignItems="center">
                  <StyledText
                    fontSize="0.875em"
                    fontWeight={600}
                    lineHeight="24px"
                    color="#333333"
                    p="1em .8em"
                  >
                    {headCell.label}
                  </StyledText>

                  <CRTooltipWithAdDetails
                    withInfoIcon
                    width="22em"
                    component={headCell?.tooltip?.component}
                  />
                </Box>
              ) : (
                <StyledText
                  fontSize="0.875em"
                  fontWeight={600}
                  lineHeight="24px"
                  color="#333333"
                  p="1em .8em"
                >
                  {headCell.label}
                  <br/>
                  {headCell.id==='recommendation' && `Turn ${batchItem?.ad_action_counts?.on} ON | ${batchItem?.ad_action_counts?.off} OFF`}
                </StyledText>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default function CRAccordionItemTable({
  handleSelectAllClick,
  handleClick,
  selected,
  rows,
  isExpired,
  imageLoading,
  imageData,
  metricType,
  batchItem
}: {
  handleSelectAllClick: any;
  handleClick: any;
  selected: string[];
  rows: GetBatchDetailsItemType[];
  isExpired?: boolean;
  imageLoading?: boolean;
  imageData?: SetPreviewItemPayload;
  metricType: string,
  batchItem?:BatchItemType,
}) {
  const assetColorMappingObject = getAssetColorMapping(
    rows.map((row) => row.parent.asset_l1_name)
  );
  return (
    <Fragment>
      {/* <StyledPaper elevation={0}> */}
        <StyledTableContainer sx={{padding:"0 16px"}}>
          <Table
            sx={{ minWidth: "100%", tableLayout:'fixed' }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead metricType={metricType} batchItem={batchItem} />

            <TableBody>
              {rows.map((row: GetBatchDetailsItemType, index: number) => {
                return (
                  <CRTableRow
                    row={row}
                    isItemSelected={selected.includes(row.recommendation_id)}
                    handleClick={handleClick}
                    index={index}
                    key={row.recommendation_id}
                    isExpired={isExpired}
                    imageLoading={imageLoading}
                    imageInfo={imageData ? imageData[row.asset_l0_ad_id] : undefined}
                    assetColor={assetColorMappingObject[row.parent.asset_l1_name]}
                  />
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      {/* </StyledPaper> */}
    </Fragment>
  );
}
