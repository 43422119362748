import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import { Controller } from "react-hook-form";
import { StyledErrorMessage } from "../../../../shared";
import Listicle from "./Listicle";

export default function ListicleFormControl({
  control,
  registeredName,
  maxValue,
  minValue,
  field,
  required,
  helperFunction,
}: any) {
  const customValidation = (listicleState: any) => {
    if (!required) return true;
    if (!listicleState || listicleState.length === 0) return false;
    const check = listicleState.filter((item: string) => !isEmpty(item));
    if (check.length < +minValue) {
      return false;
    }
    return true;
  };
  return (
    <Controller
      control={control}
      name={registeredName + ".value"} // need to be there
      rules={{
        validate: customValidation,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box mb="10px">
          <Listicle
            maxValue={+maxValue}
            minValue={+minValue}
            onChange={(value: any) => {
              helperFunction && helperFunction(registeredName);
              onChange(value);
            }}
            stateValue={value}
            field={field}
          />
          {error && (
            <StyledErrorMessage
              style={{
                marginTop: "-10px",
              }}
            >
              Above all fields are required*
            </StyledErrorMessage>
          )}
        </Box>
      )}
    />
  );
}
