import React, { useEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import info from "../../../assets/svg/smallinfo.svg";
import { setTargetingForm } from "../../../store/actions/form";
import { AdAccountState } from "../../../store/types/adaccount";
import { initialState } from "../../../store/types/auth";
import { FormState } from "../../../store/types/form";
import CheckBox from "../../../ui/CheckBox/CheckBox";
import { getFBAdsLink } from "../../../utils/commonFunctions";
import { tableData } from "../../../utils/constants";
import "./index.scss";

type TableProps = {
  data: any;
  type: "group" | "adset";
  onInputChange: (key: string, value: string | number | string[], groupId?: string) => void;
  modalProps: any;
  inputKey: string;
};

const MiniTable: React.FC<TableProps> = ({ data, type, onInputChange, inputKey, modalProps }) => {
  const target = useRef(null);
  const [checked, setChecked] = React.useState<string[]>([]);
  const [checkAll, setCheckAll] = React.useState(true);
  const auth = useSelector((state: { auth:initialState }) => state.auth);
  
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { targetingGroups, selectedTargetingAdaccount } = adaccount;
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { targetingForm } = formState;
  const dispatch = useDispatch();
  const groupId: string = modalProps?.groupId ? (modalProps.groupId as string) : "";
  const handleChecked = (inputKey: string, value: any) => {
    let newChecked: any;
    if (checked.includes(value)) {
      newChecked = checked.filter((v) => v !== value);
      setChecked(newChecked);
    } else {
      newChecked = [...checked, value];
      setChecked(newChecked);
    }
    if (newChecked.length === targetingGroups.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
    onInputChange(inputKey, newChecked);
  };

  const selectAll = () => {
    if (checkAll) {
      dispatch(setTargetingForm({ ...targetingForm, elementIds: [] }))
      setChecked([]);
    } else if (!checkAll && targetingGroups) {
      let groupIds = targetingGroups.map((a: any) => a.targtingElementID);
      dispatch(setTargetingForm({ ...targetingForm, elementIds: groupIds }));
      setChecked(groupIds);
    }
  };

  useEffect(() => {
    if (targetingGroups.length > 0 && type === "group") {
      const groupIds:any = [];
      targetingGroups.forEach((a: any) => a.targtingElementID ? groupIds.push(a.targtingElementID) : "");
      setChecked(groupIds);
    }
  }, [targetingGroups]);

  return (
    <div className="mini-table">
      <div className="table-title">
        <span className="tagline">
          Select the reference ad set for the setup details to publish new cohorts
        </span>
        <div className="tooltip-container">
          <i ref={target}>
            <img className="ml-2" src={info} alt="info_style" />
          </i>
          <div className="custom-tooltip-layout tooltip">
            <div className="tootltip-wrapper">
              <div className="tooltip-header">Performance Analysis</div>
              This shows AI-recommended actions to be taken on individual elements like ad sets and
              campaigns to optimize their performance.
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <Table responsive="md" id="myTable">
          <thead>
            <tr>
              {type === "group" && (
                <th className="position-relative pl-3">
                  <CheckBox
                    value={checkAll}
                    onChange={(value) => {
                      selectAll();
                      setCheckAll(!checkAll);
                    }}
                  />
                </th>
              )}
              {type === "group" &&
                tableData.group.header.map((element) => <th className="text-md">{element}</th>)}
              {type === "adset" &&
                tableData.adset.header.map((element) => (
                  <th className="text-md pl-2">{element}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {type === "group" &&
              data.map((element: any, index: number) => (
                <tr className="tableData" key={index}>
                  <td className="position-relative pl-3">
                    <CheckBox
                      value={checked.includes(element.targtingElementID) || checkAll}
                      onChange={() => handleChecked(inputKey, element.targtingElementID)}
                    />
                  </td>
                  <td title={element.name} className="text-sm">
                    {element.name}
                  </td>
                  <td className="text-sm">{element.facebookAdsetsCount}</td>
                </tr>
              ))}
            {type === "adset" &&
              data.map((element: any, index: number) => (
                <tr className="tableData" key={index}>
                  <td>
                    <input
                      type="radio"
                      name="adset"
                      checked={
                        (targetingForm && targetingForm[inputKey]) ===
                        element.adsetId
                      }
                      value={element.adsetId}
                      onChange={(e) => onInputChange(inputKey, e.target.value, groupId || "")}
                    />
                  </td>
                  <td title={element.name} className="mw-25 text-truncate text-sm">
                    {element.name}
                  </td>
                  <td className="mw-25 text-sm">{element.adsetId}</td>
                  <td className="mw-25 text-sm">
                    <a
                      target="_blank"
                      href={getFBAdsLink(selectedTargetingAdaccount?.adAccountId, [element.adsetId])}
                      rel="noreferrer"
                    >
                      View in ad manager
                    </a>
                  </td>
                </tr>
              ))}
            {data.length === 0 && (
              <tr className="tableData">
                <td
                  colSpan={type === "adset" ? 3 : 2}
                  className="text-md text-center font-weight-bold mt-auto"
                >
                  <span>No Data Found</span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default MiniTable;
