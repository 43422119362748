import {
  StyledCountText,
  StyledCurrentPeriodInfo,
  StyledDivider,
  StyledIndicatorComponent,
  StyledResultInfo,
  StyledSubTitle,
  StyledToolTipTitle,
} from "./styled";
import { CompareDataType } from "../sharedTypes";
import { Box } from "@mui/system";
import ComparingMetricsText from "../../SummaryBoard/ComparingMetricsText/ComparingMetricsText";
import {
  ActionDebuggerInitStateType,
  SelectedDateType,
} from "../../../../store/types/ActionDebugger/ActionDebugger";
import { dateFormatDayAndMonth } from "../../../../utils/date.util";
import { Popover } from "../../../shared";
import { useSelector } from "react-redux";
import { ReactComponent as ArrowKey } from "../../../../assets/svg/arrow-key-indicator.svg";

interface TooltipActionDebuggerProps {
  title: string;
  currentRecord?: number | string;
  comparingData?: CompareDataType;
  style?: any;
  currentPeriod: SelectedDateType;
  isValuePositive?: boolean;
  isArrow?: boolean;
  isPercentage?: boolean;
}

const TooltipActionDebugger = ({
  title,
  comparingData,
  style,
  currentPeriod,
  currentRecord,
  isValuePositive,
  isArrow,
  isPercentage = false,
}: TooltipActionDebuggerProps) => {
  const { isCompare } = useSelector(
    (state: { ActionDebuggerReducer: ActionDebuggerInitStateType }) =>
      state.ActionDebuggerReducer
  );
  return (
    <Popover style={style}>
      <Box pr="1.1em" display="flex">
        <StyledResultInfo>
          <StyledToolTipTitle>{title}</StyledToolTipTitle>
          <StyledCurrentPeriodInfo>
            <StyledIndicatorComponent backgroundColor="#0869fb" />
            <StyledSubTitle>
              {dateFormatDayAndMonth(currentPeriod.startDate)} -{" "}
              {dateFormatDayAndMonth(currentPeriod.endDate)}:
            </StyledSubTitle>

            <StyledCountText>
              {/* {isArrow && (
                <div className="pr-2">
                  {currentRecord != "-" &&
                    (isValuePositive ? (
                      <ArrowKey />
                    ) : (
                      <ArrowKey style={{ transform: "rotate(180deg)" }} />
                    ))}
                </div>
              )} */}
              {currentRecord}
              {isPercentage ? "%" : ""}
            </StyledCountText>
          </StyledCurrentPeriodInfo>
          {comparingData && (
            <Box pt=".6em">
              <StyledCurrentPeriodInfo>
                <StyledIndicatorComponent backgroundColor="#fda92b" />
                <StyledSubTitle>
                  {dateFormatDayAndMonth(
                    comparingData.previousPeriod.startDate
                  )}{" "}
                  -{" "}
                  {dateFormatDayAndMonth(comparingData.previousPeriod.endDate)}:
                </StyledSubTitle>

                <StyledCountText>
                  {/* {isArrow && (
                    <div className="pr-2">
                      {comparingData.previousValue != "-" &&
                        (comparingData.growthFlagPreviousPeriod ? (
                          <ArrowKey />
                        ) : (
                          <ArrowKey style={{ transform: "rotate(180deg)" }} />
                        ))}
                    </div>
                  )} */}
                  {comparingData.previousValue}
                  {isPercentage ? "%" : ""}
                </StyledCountText>
              </StyledCurrentPeriodInfo>
              <StyledDivider />
              <Box
                display="flex"
                pt="1em"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <StyledIndicatorComponent backgroundColor="#3ed583" />
                  <StyledSubTitle>Change :</StyledSubTitle>
                  <StyledCountText>{comparingData.difference} </StyledCountText>
                </Box>
                {comparingData && comparingData.previousValue ? (
                  <ComparingMetricsText
                    comparingData={{
                      loss: comparingData.loss,
                      value: comparingData.value,
                      previousPeriod: comparingData.previousPeriod,
                      isZero: comparingData.isZero,
                      metricGrowthFlag: comparingData.metricGrowthFlag,
                    }}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Box>
          )}
        </StyledResultInfo>

        <Box
          sx={{
            position: "relative",
            "&::before": {
              backgroundColor: "#e4ebfa",
              content: '""',
              display: "block",
              position: "absolute",
              width: "4em",
              height: "2em",
              top:
                isCompare &&
                (comparingData?.previousValue ||
                  comparingData?.previousValue === 0)
                  ? "34%"
                  : "23%",
              right: "-1.3em",
              transform: "rotate(45deg)",
              zIndex: -1,
            },
          }}
        />
      </Box>
    </Popover>
  );
};

export default TooltipActionDebugger;
