import React, { useCallback } from "react";
import "./index.scss";
// import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CloseIcon } from "../../assets/svg/CloseIcon.svg";

export type DataListOptions = {
  label: string;
};

const DataList: React.FC<{
  languageOption: DataListOptions[];
  currentValue: string;
  handelOnChange: (value: any) => void;
  selectedValues: any;
  removeItem: any;
}> = ({
  languageOption,
  currentValue,
  handelOnChange,
  selectedValues,
  removeItem,
}) => {
  const [dataValue, setDataValue] = React.useState("");

  const handleChange = useCallback(
    (event: any, val: string) => {
      setDataValue(event.target.value);
      var options: any = (
        document.getElementById("custom-datalist") as HTMLInputElement
      ).getElementsByTagName("option");

      let optionVals = [],
        i = 0;
      for (i; i < options?.length; i += 1) {
        optionVals.push(options[i].value);
      }

      if (optionVals.indexOf(event.target.value) > -1) {
        if (val.includes(event.target.value)) {
          alert(`${event.target.value} already added`);
          setDataValue("");
        } else {
          handelOnChange(event.target.value);
          setDataValue("");
        }
      }
    },
    [handelOnChange]
  );

  return (
    <div>
      <div className="data-list-container">
        <div className="data-list list">
          {selectedValues &&
            selectedValues.length !== 0 &&
            selectedValues.map((item: any, index: any) => {
              return (
                <span className="data-list-chip" key={index}>
                  {/* <span style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"72px"}}> */}
                  <p className="mb-0">{item}</p>
                  <CloseIcon
                    onClick={() => removeItem(index)}
                    style={{
                      fontSize: "18px",
                      marginLeft: "10px",
                      cursor: "pointer",
                      marginRight:"10px"
                    }}
                  />
                  {/* </span> */}
                </span>
              );
            })}

          <div className="data-list-search-container">
            <input
              list="custom-datalist"
              id="skill-input"
              className="data-list-input"
              name="dataValue"
              value={dataValue}
              onChange={(e) => handleChange(e, currentValue)}
              autoComplete="off"
              placeholder={
                languageOption?.length !== 0 ? "Start typing..." : "No option"
              }
              readOnly={languageOption?.length === 0}
              style={
                languageOption?.length === 0 ? { cursor: "not-allowed" } : {}
              }
              title={languageOption?.length === 0 ? "No option available" : ""}
            />

            <datalist
              id="custom-datalist"
              style={{
                width: "100%",
              }}
            >
              {languageOption &&
                languageOption?.length !== 0 &&
                languageOption?.map((item: any, index) => {
                  return <option key={index} value={item?.label} />;
                })}
            </datalist>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataList;
