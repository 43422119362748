import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";

interface ICustomRangeSliderProps {
  minValue: number;
  maxValue: number;
  selectedValue: number[];
  setSelectedValue: Function;
  step?: number;
  showTooltip?: boolean;
  disabled?: boolean;
  showMarks?: boolean;
}

function CustomRangeSlider(params: ICustomRangeSliderProps) {
  const {
    minValue,
    maxValue,
    selectedValue,
    setSelectedValue,
    showTooltip,
    disabled,
    showMarks,
  } = params;

  return (
    <Box
      sx={{
        width: "100%",
        background: "#FFFFFF",
        border: "0.7px solid #EFEFEF",
        boxShadow: "0px 0px 10.4927px rgba(0, 0, 0, 0.05)",
        borderRadius: "4px",
        p: 2,
      }}
    >
      <Slider
        disabled={disabled}
        min={minValue}
        max={maxValue}
        value={selectedValue}
        onChange={(event: any) => {
          setSelectedValue(event.target.value);
        }}
        getAriaValueText={(value) =>
          value >= 10000 ? "10000+" : value.toString()
        }
        valueLabelDisplay={showTooltip ? "on" : "off"}
        valueLabelFormat={(value) =>
          value >= 10000 ? "10000+" : value.toString()
        }
        marks={
          showMarks
            ? [
                { value: minValue, label: "10" },
                { value: maxValue, label: "10000+" },
              ]
            : []
        }
        sx={{
          pb: 0,
          "& .MuiSlider-track": {
            background:
              "linear-gradient(90deg, #4BA2E9 3.51%, #6DDE9F 127.19%) !important",
            border: "transparent",
          },
          "& .MuiSlider-rail, & .MuiSlider-mark": {
            opacity: 1,
            background: "#F2F2F2",
          },
          "& .MuiSlider-thumb": {
            height: "1rem",
            width: "1rem",
            background:
              "linear-gradient(#fff,#fff) padding-box, linear-gradient(90deg, #4BA2E9 3.51%, #6DDE9F 127.19%) border-box",
            border: "3px solid transparent",
            borderRadius: "0.75rem",
          },
          "& .MuiSlider-thumb::after": {
            height: "1rem",
            width: "1rem",
          },
          "& .MuiSlider-valueLabel": {
            background: "#1E2244",
            fontSize: "0.75rem",
            fontWeight: 400,
            borderRadius: "4px",
            color: "#fff",
            fontFamily: "Inter",
          },
        }}
      />
    </Box>
  );
}

export default CustomRangeSlider;
