import React, { useRef, useState } from "react";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { getPlatformName } from "utils/commonFunctions";
import { allowedPlatforms } from "utils/constants/tokenManagement";
import { ReactComponent as DownIcon } from "../../../assets/svg/down.svg";
import WarningMessage from "../../../Pages/TokenManagementSystem/WarningMessage";
import Loader from "../../common/Loader";
import { ReactComponent as WarningIcon } from "../../../assets/svg/warning-icon-gsai.svg";
import { Typography } from "@mui/material";

const AdAccountAccordion: React.FC<{
  data: any;
  index: number;
  children?: any;
  isAdvance?: boolean;
  onOpen: (callback: any) => void;
  platformName: string;
  disableBudgetPlanner?: boolean;
  selectedGroup?: any;
  handleClose?: () => void;
}> = ({
  data,
  index,
  children,
  isAdvance,
  onOpen,
  platformName,
  disableBudgetPlanner,
  selectedGroup,
  handleClose,
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const decoratedOnClick = useAccordionButton(
    !isAdvance && data.elementId ? data.elementId : index.toString()
  );
  const count = useRef(1);

  const handleOpen = (e: any) => {
    if (count.current % 2 !== 0) {
      onOpen(setLoading);
    } else {
      handleClose && handleClose();
    }
    count.current += 1;
    decoratedOnClick(e);
  };

  return (
    <div className="rounded box-shadow">
      <div
        className={`w-100 d-flex justify-content-between align-items-center p-3 px-4 rounded bg-white ${
          disableBudgetPlanner ? "pe-none" : ""
        }${selectedGroup?.parentAiGroupId ? " opacity-50 pe-none" : ""}`}
        onClick={handleOpen}
        id={isAdvance ? "budget-planner-group" : ""}
      >
        <div className="d-flex flex-column">
          {isAdvance ? (
            <Typography variant="h6" className="d-flex align-items-center">
              Budget Planner
              {!disableBudgetPlanner && !selectedGroup?.parentAiGroupId && (
                <Typography
                  sx={{
                    color: "#0869FB",
                    fontSize: "1rem",
                    fontWeight: 600,
                  }}
                  className="ml-2"
                >
                  New
                </Typography>
              )}
              {(disableBudgetPlanner || selectedGroup?.parentAiGroupId) && (
                <span className="disclaimer-warning-text text-md ml-4">
                  <WarningIcon
                    style={{
                      width: "1.806875em",
                      height: "1.681875em",
                      cursor: "pointer",
                      marginRight: "0.3em",
                    }}
                  />
                  Disclaimer :{" "}
                  {selectedGroup?.parentAiGroupId
                    ? "This AI group is a part of a Parent AI group."
                    : "Configure optimization settings to enable Budget Planner for this AI Group."}
                </span>
              )}
            </Typography>
          ) : (
            <>
              <span className="fw-bold text-md mb-1">
                {data.name !== `NA` ? data.name : data.id}
              </span>
              <span className="text-sm mb-1 text-muted">{data.id}</span>
            </>
          )}
        </div>
        <div className="cursor-pointer" role="button">
          <DownIcon />
        </div>
      </div>
      {data.id && allowedPlatforms.includes(getPlatformName(platformName)) && (
        <WarningMessage adAccountId={data.id} type={"multiple"} />
      )}
      <Accordion.Collapse
        eventKey={
          !isAdvance && data.elementId ? data.elementId : index.toString()
        }
      >
        {loading ? (
          <div className="w-100 h-25">
            <Loader />
          </div>
        ) : (
          children
        )}
      </Accordion.Collapse>
    </div>
  );
};

export default AdAccountAccordion;
