import { Box } from "@mui/material";
import {
  StyledKeyIndicatorItemCard,
  StyledKeyIndicatorTitleText,
  StyledMetricText,
} from "./styled";
import { ReactComponent as Delta } from "../../../../assets/svg/delta.svg";
import { ReactComponent as ArrowKey } from "../../../../assets/svg/arrow-key-indicator.svg";
import ComparingMetricsText from "../ComparingMetricsText/ComparingMetricsText";
import { CompareDataType } from "../../shared/sharedTypes";
import TooltipActionDebugger from "../../shared/TooltipActionDebugger/TooltipActionDebugger";
import { ActionDebuggerInitStateType } from "../../../../store/types/ActionDebugger/ActionDebugger";
import { useSelector } from "react-redux";

interface KeyIndicatorItemsProps {
  backgroundColor: string;
  keyName: string;
  comparingData?: CompareDataType;
  metricValue: string;
  hideTooltip?: boolean;
  isValuePositive?: boolean;
  isPercentage?: boolean;
}

export default function KeyIndicatorItems({
  backgroundColor,
  keyName,
  comparingData,
  metricValue,
  isValuePositive,
  hideTooltip = false,
  isPercentage = false,
}: KeyIndicatorItemsProps) {
  const { currentPeriod } = useSelector(
    (state: { ActionDebuggerReducer: ActionDebuggerInitStateType }) =>
      state.ActionDebuggerReducer
  );
  return (
    <StyledKeyIndicatorItemCard backgroundColor={backgroundColor}>
      <Box display="flex" alignItems="center">
        {/* <Delta /> */}
        <StyledKeyIndicatorTitleText>{keyName}</StyledKeyIndicatorTitleText>
      </Box>
      <Box display="flex" alignItems="center">
        {/* {!hideTooltip &&
          metricValue != "-" &&
          (isValuePositive ? (
            <ArrowKey />
          ) : (
            <ArrowKey style={{ transform: "rotate(180deg)" }} />
          ))} */}
        <StyledMetricText>
          {metricValue}
          {isPercentage ? "%" : ""}
        </StyledMetricText>
        {comparingData && (
          <Box pl="0.625em">
            <ComparingMetricsText comparingData={comparingData} />
          </Box>
        )}
        {!hideTooltip ? (
          <TooltipActionDebugger
            title={keyName}
            comparingData={comparingData}
            style={{
              margin: "0.6em 1.5em 0.8em 0.8em",
            }}
            currentPeriod={currentPeriod}
            currentRecord={metricValue}
            isValuePositive={isValuePositive}
            isArrow={true}
            isPercentage={isPercentage}
          />
        ) : (
          <div
            style={{
              margin: "1.3em 0.5em",
            }}
          />
        )}
      </Box>
    </StyledKeyIndicatorItemCard>
  );
}
