import {
  GENERATE_DATA,
  GET_DATA,
  UPDATE_GSAI_STORE,
  GET_BUNITS,
  GET_HEADLINES_AND_DESCRIPTIONS,
  GET_KEYWORDS,
  GENERATE_RECOMMENDATIONS,
  GET_RECOMMENDATIONS,
  PUBLISH_AD,
  SET_NO_ADS_AVAILABLE,
  GET_CREATIVE_REPLACEMENT,
  SetFilteredAdsLoader,
  SET_FILTERED_ADS_LOADER,
  CreateDraftAds,
  CREATE_DRAFT_ADS,
  UpdateDraftAds,
  UPDATE_DRAFT_ADS,
  DeleteDraftAds,
  DELETE_DRAFT_ADS,
  DraftAdsInterface,
} from "../../../types/CreativeAI/GsaiPlayground";
import type {
  GeneratData,
  GetData,
  UpdateGsaiStore,
  GetBussinessUnits,
  GetHeadlinesAndDescription,
  GetKeywords,
  GeneratRecommendations,
  GetRecommendations,
  PublishAd,
  GetCreativeReplacement,
} from "../../../types/CreativeAI/GsaiPlayground";

export function generatData(payload: any): GeneratData {
  return {
    type: GENERATE_DATA,
    payload,
  };
}

export function getData(payload: any): GetData {
  return {
    type: GET_DATA,
    payload,
  };
}

export function updateGsaiStore(payload: any): UpdateGsaiStore {
  return {
    type: UPDATE_GSAI_STORE,
    payload,
  };
}

export function getBussinessUnits(payload: any): GetBussinessUnits {
  return {
    type: GET_BUNITS,
    payload,
  };
}

export function getHeadlinesAndDescription(
  payload: any
): GetHeadlinesAndDescription {
  return {
    type: GET_HEADLINES_AND_DESCRIPTIONS,
    payload,
  };
}

export function getKeyWords(payload: any): GetKeywords {
  return {
    type: GET_KEYWORDS,
    payload,
  };
}

export function generatRecommendations(payload: any): GeneratRecommendations {
  return {
    type: GENERATE_RECOMMENDATIONS,
    payload,
  };
}

export function getRecommendation(payload: any): GetRecommendations {
  return {
    type: GET_RECOMMENDATIONS,
    payload,
  };
}

export function publishAd(payload: any): PublishAd {
  return {
    type: PUBLISH_AD,
    payload,
  };
}

export const setNoAdsAvailable = (payload?: any) => {
  return {
    type: SET_NO_ADS_AVAILABLE,
    payload,
  };
};

export const getCreativeReplacement = (
  payload: any
): GetCreativeReplacement => {
  return {
    type: GET_CREATIVE_REPLACEMENT,
    payload,
  };
};

export const setFilteredAdsLoader = (
  payload: boolean
): SetFilteredAdsLoader => {
  return {
    type: SET_FILTERED_ADS_LOADER,
    payload,
  };
};

export const createDraftAds = (payload: {
  params: DraftAdsInterface;
  callback: any;
}): CreateDraftAds => {
  return {
    type: CREATE_DRAFT_ADS,
    payload,
  };
};

export const updateDraftAds = (payload: {
  id: string;
  params: DraftAdsInterface;
  callback: any;
}): UpdateDraftAds => {
  return {
    type: UPDATE_DRAFT_ADS,
    payload,
  };
};

export const deleteDraftAds = (payload: {
  id: string;
  callback: any;
}): DeleteDraftAds => {
  return {
    type: DELETE_DRAFT_ADS,
    payload,
  };
};
