import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { getDecimalValues } from "utils/commonFunctions";

export const BudgetPlannerProgressbar: React.FC<{
  colors: string;
  value: number;
  day: any;
  percentageValue: number;
  backgroundColor: string;
  selectedBrand: any;
}> = ({
  colors,
  value,
  percentageValue,
  backgroundColor,
  day,
  selectedBrand,
}): JSX.Element => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#FFF",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colors,
    },
  }));

  return (
    <Box
      className="budgetPlanner-progress-bar"
      sx={{
        "&:hover": {
          background: value != 0 ? backgroundColor : "transparent",
        },
      }}
    >
      <div className="text-on-hover invisible">
        <div className="flex-center justify-content-between px-1">
          {value != 0 && !isNaN(value) ? (
            <div
              style={{
                fontSize: "9px",
                fontWeight: "500",
                color: "#333333",
              }}
            >
              {selectedBrand?.currencySymbol}
              {getDecimalValues(value)}
            </div>
          ) : (
            <div
              style={{
                fontSize: "9px",
                visibility: "hidden",
              }}
            >
              NA
            </div>
          )}

          {!isNaN(percentageValue) && percentageValue != 0 ? (
            <div
              style={{
                fontSize: "9px",
                fontWeight: "500",
                lineHeight: "1em",
                color: "#333333",
              }}
            >
              {percentageValue > 120
                ? ">120"
                : getDecimalValues(percentageValue)}
              %
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Box sx={{ flexGrow: 1 }} className="px-1 pb-1">
        <BorderLinearProgress
          variant="determinate"
          value={
            percentageValue
              ? percentageValue > 100
                ? 100
                : getDecimalValues(percentageValue)
              : 10
          }
        />
      </Box>
    </Box>
  );
};
