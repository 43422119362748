import AIInitialitation from "../../ReactFlowComponents/Assets/Gif/AIInitialitation.svg";

export const calibrationData = {
  gif: AIInitialitation,
  steps: [
    "Fetching historical performance of AI Group",
    "Extracting brand details",
    "Analyzing the ad account",
    "Setting up the Calibration system",
  ],
};

export const keywordTuningData = {
  gif: AIInitialitation,
  steps: [
    "Analysing historical performance for selected date range",
    "Using selected sources as an input for new recommendations",
    "Analyzing custom keywords",
    "Setting up keyword tuner",
  ],
};

export const keywordExplorerData = {
  gif: AIInitialitation,
  steps: [
    "Analysing all inputs",
    "Generating the most relevant keywords",
    "Identifying relevant ad groups and clusters",
    "Calculating the performance of each keyword",
  ],
};
