import HttpService from "../../../HttpService/HttpService";
import {
  GET_PARENT_AI_GROUP_ITEMS,
  GET_PARENT_AI_GROUP_ITEM,
  DELETE_PARENT_AI_GROUP_ITEM,
  GET_PARENT_AI_GROUP_PLANNERS,
  GET_PARENT_AI_GROUP_PLANNER_DETAILS,
  GET_PARENT_AIGROUP_PLANNER_DETAILS_FAKE,
  CREATE_PARENT_AI_GROUP,
  CREATE_PARENT_AI_GROUP_PLANNER,
  UPDATE_PARENT_AI_GROUP_PLANNER,
  DELETE_BUDGET_PLANNER,
} from "../../types/parentAiGroup/index";
import { call, takeLatest } from "redux-saga/effects";
import { CROSS_PLATFORM_URL } from "../../../utils/path";
import { parentAiGroups } from "MappedAPI/MappedAPI";
import moment from "moment";

function* getParentGroupAiItems(action: any): any {
  let {
    payload: { brandId },
    callback,
  } = action;
  try {
    const urlParams = `/v1/api/brand/${brandId}/parent-ai-groups`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getParentGroupAiItem(action: any): any {
  let {
    payload: { parentAiGroupId },
    callback,
  } = action;
  try {
    const urlParams = `${parentAiGroups}/${parentAiGroupId}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* deleteParentAiItem(action: any): any {
  let {
    payload: { parentAiGroupId, user },
    callback,
  } = action;
  try {
    const urlParams = `${parentAiGroups}${parentAiGroupId}`;
    const response = yield call(
      HttpService.delete,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* deleteBudgetPlanner(action: any): any {
  let {
    payload: { parentAiGroupId, budgetPlannerId, user },
    callback,
  } = action;
  try {
    const urlParams = `${parentAiGroups}${parentAiGroupId}/budget-planners/${budgetPlannerId}`;
    const response = yield call(
      HttpService.delete,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getParentAiGroupPlanners(action: any): any {
  let {
    payload: { parentAiGroupId },
    callback,
  } = action;
  try {
    const urlParams = `${parentAiGroups}${parentAiGroupId}/budget-planners?status=ACTIVE,SCHEDULE,UNCONFIGURED`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getParentAiGroupPlannerDetails(action: any): any {
  let {
    payload: { parentAiGroupId, budgetPlannerId },
    callback,
  } = action;
  try {
    const urlParams = `${parentAiGroups}${parentAiGroupId}/budget-planners/${budgetPlannerId}/constraints`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getParentAiGroupPlannerDetailsFake(action: any): any {
  let {
    payload: { parentAiGroupId },
    callback,
  } = action;
  try {
    const urlParams = `${parentAiGroups}${parentAiGroupId}/budget-planners/fake/constraints`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* createParentAiGroup(action: any): any {
  let {
    payload: { payload, user, brandId },
    callback,
  } = action;
  try {
    const urlParams = `/v1/api/brand/${brandId}/parent-ai-group`;
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      { name: payload.name, ai_groups: payload.aiGroups }
    );
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*postCampaignFactors ~ error", error);
    callback && callback(error.response, true);
  }
}

function* createParentAiGroupPlanner(action: any): any {
  let {
    payload: { payload, user, parentAiGroupId },
    callback,
  } = action;
  try {
    let newPayload = { ...payload };
    let startDate = moment(payload.startDate).format("YYYY-MM-DD");
    let endDate = moment(payload.endDate).format("YYYY-MM-DD");

    newPayload.startDate = moment(startDate).format("YYYY-MM-DD").toString();
    newPayload.endDate = moment(endDate).format("YYYY-MM-DD").toString();
    const urlParams = `${parentAiGroups}${parentAiGroupId}/budget-planners`;
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      newPayload
    );
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*postCampaignFactors ~ error", error);
    callback && callback(error.response, true);
  }
}

function* updateParentAiGroupPlanner(action: any): any {
  let {
    payload: { budgetPlannerId, parentAiGroupId, payload, user },
    callback,
  } = action;
  try {
    const urlParams = `${parentAiGroups}${parentAiGroupId}/budget-planners/${budgetPlannerId}/constraints`;

    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      payload
    );
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*postCampaignFactors ~ error", error);
    callback && callback(error.response, true);
  }
}
export function* ParentAiGroupWatcher() {
  yield takeLatest(GET_PARENT_AI_GROUP_ITEMS, getParentGroupAiItems);
  yield takeLatest(GET_PARENT_AI_GROUP_ITEM, getParentGroupAiItem);
  yield takeLatest(DELETE_PARENT_AI_GROUP_ITEM, deleteParentAiItem);
  yield takeLatest(DELETE_BUDGET_PLANNER, deleteBudgetPlanner);
  yield takeLatest(GET_PARENT_AI_GROUP_PLANNERS, getParentAiGroupPlanners);
  yield takeLatest(
    GET_PARENT_AI_GROUP_PLANNER_DETAILS,
    getParentAiGroupPlannerDetails
  );
  yield takeLatest(
    GET_PARENT_AIGROUP_PLANNER_DETAILS_FAKE,
    getParentAiGroupPlannerDetailsFake
  );
  yield takeLatest(CREATE_PARENT_AI_GROUP, createParentAiGroup);
  yield takeLatest(CREATE_PARENT_AI_GROUP_PLANNER, createParentAiGroupPlanner);
  yield takeLatest(UPDATE_PARENT_AI_GROUP_PLANNER, updateParentAiGroupPlanner);
}
