import { Box } from "@mui/system";
import { Button } from "../Button/Button";
import CustomizedDialogs from "../Modal/CustomizedDialogs";
import {
  StyledActionMessageContainer,
  StyledButtonWithOutline,
  StyledButtonWithText,
  StyledMessageContainer,
} from "./styled";
import SVG from "../../../assets/svg/Danger-Circle.svg";
import { ReactComponent as Image } from "../../../assets/svg/refresh.svg";
interface ErrorScreenProps {
  mainMessage: string;
  actionMessage: string;
  cancelText: string;
  actionText: string;
  show: boolean;
  onClose: () => void;
  handleSubmit: () => void;
}

function ErrorScreen({
  mainMessage,
  actionMessage,
  cancelText,
  actionText,
  show,
  onClose,
  handleSubmit,
}: ErrorScreenProps) {
  return (
    <CustomizedDialogs
      show={show}
      type="ConfirmDialog"
      footer={false}
      maxWidth="xs"
      dialogStyleProp={{
        borderRadius: "900px",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        sx={{ height: "20.93em", width: "20em" }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ height: "14em", width: "20em", borderRadius: "0.5em" }}
        >
          {/* <Image height={"40em"} /> */}
          <img src={SVG} height="50em" />
          <StyledMessageContainer>{mainMessage}</StyledMessageContainer>
          <StyledActionMessageContainer>
            {actionMessage}
          </StyledActionMessageContainer>
          <Box
            display={"flex"}
            justifyContent="space-between"
            sx={{ width: "15em" }}
          >
            <Button
              variant="text"
              onClick={onClose}
              sx={{
                width: "40%",
                padding: "0em 1em",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                height: "2.5em",
                fontSize: "0.875em",
                color: `#0869FB`,
                ":hover": {
                  background: `#FFF`,
                },
              }}
            >
              {cancelText}
            </Button>
            <Button
              variant="outlined"
              onClick={handleSubmit}
              sx={{
                width: "55%",
                padding: "0em 1em",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "0.875em",
                color: "#0869FB",
                border: `0.125em solid #0869FB`,
                borderRadius: "0.5em",
                ":hover": {
                  border: `0.125em solid #0869FB`,
                  borderRadius: "0.5em",
                },
              }}
            >
              <Image className="mr-1" />
              {actionText}
            </Button>
          </Box>
        </Box>
      </Box>
    </CustomizedDialogs>
  );
}

export default ErrorScreen;
