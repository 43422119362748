import { ActionContainerList } from "./sharedTypes";

export const actionContainerList: ActionContainerList = {
  GAI: {
    color: "#0869FB",
    backgroundColor: "rgba(8, 105, 251, 0.1)",
  },
  CAI: {
    color: "#C971FF",
    backgroundColor: "rgba(201, 113, 255, 0.1)",
  },
  TAI: {
    color: "#0FBAA6",
    backgroundColor: "rgba(18, 200, 178, 0.1)",
  },
  PAI: {
    color: "#0869FB",
    backgroundColor: "rgba(8, 105, 251, 0.1)",
  },
};

export const defaultSummaryItems = ["Spends", "Results", "CPR"];
