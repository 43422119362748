import React, { Fragment, useMemo, useState } from "react";
import CustomizedDialogs from "../../../shared/Modal/CustomizedDialogs";
import CustomizedButton from "../../../shared/Button/CustomizedButton";
import {
  StyledReviewModalFooter,
  StyledVariantItemPara,
  StyledReviewSpan,
  StyledReviewBtn,
  StyledReviewModalTitle,
} from "./styled";
import CustomizedSwitch from "../../../shared/Switch/CustomizedSwitch";
import CustomizedTable from "../../../shared/Table/CustomizedTable";
import { reviewVariantTableHead } from "../../../../utils/constants/creativeAI";
import { StyledTableCell, StyledTableRow } from "../../../shared/Table/styled";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CreateVariantsState } from "../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { Button } from "../../../shared";
import {
  publishVariant,
  submitForReview,
} from "../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { initialState } from "../../../../store/types/auth";
import { textTruncateMore } from "../utils/text.utils";
import CloseIcon from "@mui/icons-material/Close";
import { AdAccountState } from "store/types/adaccount";
import TooltipWithInfo from "components/CreativeAI/Facebook/shared/TooltipWithInfo/TooltipWithInfo";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { brandViewerAccessText } from "utils/constants/commonConsts";
import { LightTooltip } from "components/shared/LightTooltip";
import {
  getRolesHierarchy,
  isEditorRole,
  // isViewerRole,
} from "utils/commonFunctions";
import { RolesWrapper } from "utils/rolesWrapper";

const ReviewModal: React.FC<{
  show: boolean;
  onHide: any;
  setOpenReviewFlow: any;
  refreshAds: any;
  mixPanelReview: any;
  mixPanelPublish: any;
}> = ({
  show,
  onHide,
  setOpenReviewFlow,
  refreshAds,
  mixPanelReview,
  mixPanelPublish,
}) => {
  //hooks
  // All State
  const { ads, totalSelectedVariant, isPublishingVariant, approverLink } =
    useSelector(
      (state: { createVariantReducer: CreateVariantsState }) =>
        state.createVariantReducer
    );
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const dispatch = useDispatch();

  const { adAccountsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const { adsetDetails } = useSelector(
    (state: { caiFBReducer: any }) => state.caiFBReducer
  );

  const adCount = (ads?.length ?? 0) + totalSelectedVariant?.length;
  const disabled =
    adsetDetails?.facebookData?.campaign_objective == "OUTCOME_SALES"
      ? false
      : adCount > 50;

  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;

  // const isBrandViewer = useMemo(
  //   () => brandRoles?.length === 1 && brandRoles[0]?.role === "brand-viewer",
  //   [brandRoles]
  // );

  const onSubmitForReview = () => {
    onHide(false);
    mixPanelReview("Review - Publish Modal");
    dispatch(
      submitForReview({
        params: {
          platform: "FACEBOOK",
          type: "CREATIVE",
          brandId: selectedBrand?.id,
          variants: totalSelectedVariant.map((item) => {
            return { id: item };
          }),
          user: auth.user,
          adAccountId: adAccountsSelection?.selectedAdAccountId,
        },
      })
    );
    setOpenReviewFlow(true);
    refreshAds();
  };

  const onPublish = () => {
    mixPanelPublish();
    dispatch(
      publishVariant({
        variantIds: totalSelectedVariant,
        user: auth.user,
        adAccountId: adAccountsSelection?.selectedAdAccountId,
      })
    );
    onHide(false);
    refreshAds();
  };

  return (
    <CustomizedDialogs
      show={show || isPublishingVariant}
      handleClose={() => onHide(false)}
      type="ConfirmDialog"
      footer={false}
      maxWidth="md"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        pb="16px"
        mt="-8px"
        alignItems="center"
      >
        <StyledReviewModalTitle>Review Selection</StyledReviewModalTitle>
        <IconButton onClick={() => onHide("close")}>
          <CloseIcon />
        </IconButton>
      </Box>
      <div>
        <CustomizedTable
          tableHeadValues={reviewVariantTableHead}
          tableWidth={600}
          tableType="evenColoredTable"
        >
          {ads?.map((item: any, index: any) => {
            const addToBePublish = item?.draftVariants
              ? (item?.variantList
                  ? item?.variantList?.filter((element: any) => element.checked)
                      ?.length
                  : 0) +
                "/" +
                item?.draftVariants
              : "--";
            return (
              <Fragment key={index}>
                <StyledTableRow>
                  {/* Will Need to discuss for condition base styling */}
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="left"
                    title={item?.name}
                  >
                    {textTruncateMore(item?.name, 30)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {addToBePublish}
                  </StyledTableCell>
                </StyledTableRow>
              </Fragment>
            );
          })}
        </CustomizedTable>

        <StyledReviewModalFooter>
          {/* <div>
            <StyledVariantItemPara
              color="#7A7A7A"
              fontSize={14}
              fontWeight={500}
              fontFamily="Inter"
            >
              Creative Rotation
              <TooltipWithInfo
                top={"-20px"}
                info="Creative Rotation will be enabled/disabled for all the ad sets in the selected AI Group."
              />
            </StyledVariantItemPara>
            <StyledReviewSpan className="d-flex justify-content-start align-item-center">
              <CustomizedSwitch checked={selectedAiGroupObject?.toggle} />

              <StyledVariantItemPara
                color="#000000"
                fontSize={12}
                fontWeight={400}
                fontFamily="Lato"
              >
                (AI group level)
              </StyledVariantItemPara>
            </StyledReviewSpan>
          </div> */}
          <StyledReviewBtn>
            <Button
              variant="outlined"
              onClick={() => {
                onSubmitForReview();
              }}
              sx={{
                color: "#0869FB",
                height: "100%",
                fontSize: "inherit",
                borderRadius: "10px",
                padding: "16px",
                marginRight: "16px",
              }}
              disabled={approverLink.isLoading}
            >
              {approverLink.isLoading ? (
                <Box width="60px" height="22px">
                  <CircularProgress
                    size={25}
                    sx={{
                      color: "white",
                    }}
                    thickness={10}
                  />
                </Box>
              ) : (
                `Submit For Review`
              )}
            </Button>
            <RolesWrapper
              disabled={
                getRolesHierarchy(brandRoles) === "viewer" ||
                getRolesHierarchy(brandRoles) === "editor"
              }
            >
              <LightTooltip
                title={
                  // isBrandViewer
                  // isViewerRole(brandRoles)
                  //   ? brandViewerAccessText
                  //   :
                  disabled
                    ? `The ad limit of 50 has been reached; Kindly delete some ads or uncheck at least ${
                        adCount - 50
                      } ad variants`
                    : ""
                }
              >
                <span>
                  <CustomizedButton
                    // style={{
                    //   // cursor: isBrandViewer ? "not-allowed" : "pointer",
                    //   cursor: isViewerRole(brandRoles)
                    //     ? "not-allowed"
                    //     : "pointer",
                    // }}
                    buttonText={
                      isPublishingVariant ? (
                        <Box width="60px" height="22px">
                          <CircularProgress
                            size={25}
                            sx={{
                              color: "white",
                            }}
                            thickness={5}
                          />
                        </Box>
                      ) : (
                        <Box>Publish ({totalSelectedVariant.length})</Box>
                      )
                    }
                    buttonClass="publish-button"
                    variant="contained"
                    disabled={
                      totalSelectedVariant.length === 0 || disabled
                      //  ||
                      // isViewerRole(brandRoles) ||
                      // isEditorRole(brandRoles)
                    }
                    sx={{
                      ":disabled": {
                        opacity: 0.5,
                      },
                    }}
                    // onClick={isBrandViewer ? undefined : () => onPublish()}
                    onClick={
                      // isViewerRole(brandRoles) ? undefined : () => onPublish()
                      () => onPublish()
                    }
                  />
                </span>
              </LightTooltip>
            </RolesWrapper>
            {/* <Button
              buttonVariant="contained"
              disabled={totalSelectedVariant.length === 0}
              onClick={onPublish}
              // class="publish-button"
            >
              {isPublishingVariant ? (
                <Box width="60px" height="22px">
                  <CircularProgress
                    size={25}
                    sx={{
                      color: "white",
                    }}
                    thickness={5}
                  />
                </Box>
              ) : (
                ` Publish ${totalSelectedVariant.length}`
              )}
            </Button> */}
          </StyledReviewBtn>
        </StyledReviewModalFooter>
      </div>
    </CustomizedDialogs>
  );
};

export default ReviewModal;
