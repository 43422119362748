import { Box } from "@mui/material";
import { styled as muiStyled } from "@mui/system";
import styled from "styled-components";
import { TextContainerProps } from "./ActionSummaryItem";

export const StyledActionItemCard = muiStyled(Box)`
  width: 100%;
  background: rgba(242, 242, 242, 0.5);
  border-radius: .5em;
  justify-content: space-between;
  display: flex;
  margin-top:.5em;
  padding-right:  0.3em ;

`;

export const StyledTextContainer = styled.div`
  width: 1.1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props: TextContainerProps) => props.textContainerColor};
  border-radius: 4px;
  padding: 0.38em;
`;

export const StyledTextItem = styled.div`
  color: ${(props: TextContainerProps) => props.textContainerItemColor};
  font-size: 0.75em;
  line-height: 0.9em;
  padding-top: 0.125em;
`;

export const StyledSummaryText = styled.div`
  padding-left: 0.75em;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.1875em;
  display: flex;
  align-items: center;
  color: #333333;
`;

export const StyledInfoText = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.1875em;
  display: flex;
  align-items: center;
  color: #000000;
  padding-top: 0.5em;
`;

export const DisclaimerText = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 0.9375em;
  color: #555555;
  margin-top: 1em;
`;
