import { Box } from "@mui/material";
import React from "react";
import { StyledApproverSectionBox } from "./styled";
import { ReactComponent as PixisLogo } from "../../../assets/svg/pixisLogo.svg";

export default function SideBar() {
  return (
    <StyledApproverSectionBox>
      <Box pt="2em" />
      <PixisLogo />
    </StyledApproverSectionBox>
  );
}
