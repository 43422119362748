import React, { useEffect, useState } from "react";
import sort from "../../assets/Sort.svg";
import {
  Popover,
  Button,
  Box,
  Stack,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Divider,
} from "@mui/material";
import exit from "../../assets/cut.svg";
import { useSelector } from "react-redux";
import { AudienceManagerState } from "store/types/AudienceManager";
interface Props {
  selectedOption: string;
  optionsList: string[];
  updateOption: (val: string) => void;
  disabled?: boolean;
}

const Sort: React.FC<Props> = ({
  selectedOption,
  optionsList,
  updateOption,
  disabled,
}) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState<any>(null);
  const actionsOpen = Boolean(actionsAnchorEl);
  const actionsId = actionsOpen ? "actions-simple-popover" : null;
  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };
  const handleFilterClick = (event: any) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const {
    selectedAiGroup,
  } = useSelector(
    (state: { audienceManager: AudienceManagerState }) => state.audienceManager
  );

  useEffect(()=>{updateOption(optionsList[0])},[selectedAiGroup])

  return (
    <>
      <Button
        startIcon={
          <img src={sort} alt="sort-icon" style={{ stroke: "#999" }} />
        }
        sx={{
          opacity: disabled ? 0.5 : 1,
          background: "rgba(221, 221, 221, 0.1)",
          border: "1px solid #DDDDDD",
          color: "#333333",
          textTransform: "capitalize",
          px: 1.5,
          py: 1,
          width: "17rem",
          height: "2.5rem",
          "& .MuiButton-startIcon": {
            mr: 0,
            width: "1rem",
          },
        }}
        onClick={handleFilterClick}
        className="font-12 font-500"
        disabled={disabled}
      >
        <Typography
          sx={{
            mx: 0.5,
            width: "16rem",
          }}
          noWrap={true}
          component="div"
        >
          <Typography
            component="span"
            className="font-12 font-500"
            color="#999"
          >
            Sort by&nbsp;-&nbsp;
          </Typography>
          <Typography
            component="span"
            className="font-12 font-500"
            color="#333"
            title={selectedOption}
          >
            {selectedOption}
          </Typography>
        </Typography>
      </Button>
      {!disabled && (
        <Popover
          sx={{
            borderRadius: "1rem",
          }}
          id={actionsId || undefined}
          open={actionsOpen}
          anchorEl={actionsAnchorEl}
          onClose={handleActionsClose}
          anchorOrigin={{
            horizontal: "left",
            vertical: "top",
          }}
        >
          <Stack className="font-inter" sx={{ width: "15rem" }}>
            <Grid
              container
              className="d-flex  align-items-center"
              sx={{
                px: 2,
                py: 1,
              }}
            >
              <Grid item xs={10}>
                <Typography
                  className="m-0 sort-title font-14 font-600 "
                  sx={{ color: "#000" }}
                >
                  Sort
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign={"end"}>
                <IconButton
                  disableRipple={true}
                  onClick={handleActionsClose}
                  sx={{ p: 0 }}
                >
                  <img src={exit} alt="exit-button" />
                </IconButton>
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
            <Box>
              {optionsList.map((item: string) => (
                <MenuItem
                  sx={{ py: 1.5 }}
                  key={item}
                  onClick={() => {
                    updateOption(item);
                    handleActionsClose();
                  }}
                  className="font-10"
                >
                  {item}
                </MenuItem>
              ))}
            </Box>
          </Stack>
        </Popover>
      )}
    </>
  );
};

export default Sort;
