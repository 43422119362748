import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatsOnAdaccount } from "../../../store/actions/accountsManager";
import { AdAccountState } from "../../../store/types/adaccount";
import { getWarningMessage } from "../utils";

type TokenWarningProp = {
  className: string;
  message: any;
  availableTokens?: number;
};

export default function useWarningMessage(adaccountId: string) {
  const dispatch = useDispatch();
  const [viewToggle, setViewToggle] = useState(true);
  const [platform, setPlatform] = useState("");
  const [tokenWarnings, setTokenWarnings] = useState<TokenWarningProp>();
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  useEffect(() => {
    if (adaccountId) {
      setPlatform("");
      dispatch(
        getStatsOnAdaccount(
          { adaccountId },
          (response: any, error: boolean) => {
            if (!error) {
              const { tokens } = response.data;
              setTokenWarnings(getWarningMessage(tokens));
              setPlatform(response.data.parentPlatformName);
            } else {
              setTokenWarnings({ className: "", message: "" });
            }
          }
        )
      );
    }
  }, [adaccountId]);

  return {
    viewToggle,
    setViewToggle,
    tokenWarnings,
    setTokenWarnings,
    platform,
    selectedBrand,
  };
}
