import { Box } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRequestedVariant,
  updateSelectedVariantId,
} from "store/actions/CreativeAI/shared/Review/review";
import { ReviewInitialState } from "store/types/CreativeAI/shared/Review/review";
import PreviewSection from "./PreviewSection/PreviewSection";
import ReviewContainer from "./ReviewContainer/ReviewContainer";
import { StyledGoogleApproverBox } from "./styles";

const GoogleApprover: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    hashId,
    requestedVariantsMetaData: { data: { variants = [] } = {} },
    selectedVariantRequestedId,
  } = useSelector(
    (state: { reviewReducer: ReviewInitialState }) => state.reviewReducer
  );

  React.useEffect(() => {
    console.log(hashId, variants.length, variants);
    if (hashId && variants.length) {
      dispatch(
        getRequestedVariant({
          hashId,
          variantId: variants[0]?.id,
        })
      );
    }
  }, [hashId, variants]);

  React.useEffect(() => {
    if (variants.length && selectedVariantRequestedId === undefined) {
      dispatch(updateSelectedVariantId(0));
    }
  }, [variants]);

  return (
    <StyledGoogleApproverBox>
      <PreviewSection />
      <ReviewContainer />
    </StyledGoogleApproverBox>
  );
};

export default GoogleApprover;
