import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { MainTitle, SubHeading, DialogContainer, StyledDialog } from "./styled";
import { ReactComponent as CloseSvg } from "../../../assets/svg/CloseSvgNew.svg";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  height?: string;
}

const DialogHeader = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0 }}
      {...other}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "7px 20px",
      }}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            backgroundColor: "#F2F2F2",
            borderRadius: "4px",
          }}
          style={{ width: "35px", height: "35px" }}
        >
          <CloseSvg />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CustomizedDialogs: React.FC<{
  show: boolean;
  handleClose?: any;
  children?: React.ReactNode;
  type: String;
  footer?: boolean;
  title?: String;
  titleSize?: number;
  fullScreen?: boolean;
  subTitle?: String;
  subTitleSize?: number;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  buttons?: any;
  headerHeight?: string;
  dialogStyleProp?: any;
  isHeightRequired?: any;
  isTopAlign?: boolean;
}> = ({
  show,
  handleClose,
  children,
  type,
  footer = true,
  title,
  fullScreen,
  subTitle,
  maxWidth,
  buttons,
  titleSize,
  subTitleSize,
  headerHeight,
  dialogStyleProp,
  isHeightRequired,
  isTopAlign,
}) => {
  const dialogStyle =
    isHeightRequired == false
      ? {
          borderRadius: "10px",
        }
      : { borderRadius: "10px", height: "90vh" };
  return (
    <div>
      {type === "modal" ? (
        <DialogContainer>
          <Dialog
            open={show}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown={true}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            PaperProps={{
              style: dialogStyleProp ? dialogStyleProp : dialogStyle,
            }}
          >
            <DialogHeader
              id="customized-dialog-title"
              onClose={handleClose}
              height={headerHeight}
            >
              <span>
                <MainTitle size={titleSize} fontWeight={700}>
                  {title ? title : ""}
                </MainTitle>
                {subTitle && (
                  <SubHeading size={subTitleSize} color={"#818182"}>
                    {subTitle}
                  </SubHeading>
                )}
              </span>
            </DialogHeader>
            <DialogContent dividers>{children}</DialogContent>

            {footer && <DialogActions>{buttons}</DialogActions>}
          </Dialog>
        </DialogContainer>
      ) : type === "ConfirmDialog" ? (
        <StyledDialog
          open={show}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEscapeKeyDown={false}
          fullScreen={fullScreen}
          maxWidth={maxWidth}
          isTopAlign={isTopAlign}
        >
          <DialogContent>{children}</DialogContent>
        </StyledDialog>
      ) : null}
    </div>
  );
};
export default CustomizedDialogs;
