import styled from "styled-components";
import { Button } from "../../../../../shared";

export const StyledRecommendationTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  font-family: Lato;
  font-weight: 700;
`;

export const StyledRecommendationSubTitle = styled.div`
  color: #868686;
  font-size: 0.75rem;
  text-align: center;
`;

export const StyledMultiOptionMainContanier = styled.div`
  /* overflow: scroll; */
  width: 100%;
  /* flex: 1; */
  /* min-height: 0px; */
  /* max-width: 843px; */
  display: flex;
  flex-direction: column;
`;
export const StyledMultiOptionsContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x:scroll;
`;
export const StyledRecommendationTitle = styled.h3`
  font-family: Lato;
  font-weight: 700;
  padding: 12px 16px;
`;

export const StyledIntroText = styled.p`
  font-family: "Lato";
  font-style: normal;
  // font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-bottom: 3px;
  margin-top: 2rem;
  position: relative;
  left: calc(38px + 0.25rem);
  width: calc(100% - (1.75rem + 102px));
`;

export const StyledNewIcon = styled.div`
  margin-top: 1rem;
`;

export const StyledButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 28px;
`;

export const StyledShowMore = styled.a`
  margin-left: 5px;
  font-size: 15px;
  font-family: "Lato";
  font-style: normal;
  // font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: #0869fb !important;
  border-bottom: 1px solid #0869fb;
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  font-size: 0.875rem;
`;

//----imageComponent

// export const StyledImageHolder = styled.div`
// // margin-top: 5rem;
// // padding-bottom: 0.5rem;
// height: 50%;
// `;

export const StyledText = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  color: rgb(0, 0, 0);
  padding: 1rem 0rem 0rem 0rem;
`;

export const StyledImageContainer = styled.div`
  aspect-ratio: 2/1;
  background-color: rgba(242, 242, 242, 0.5);
  flex-direction: column;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: relative;
  left: calc(38px + 0.25rem);
  width: calc(100% - (1.75rem + 102px));
  padding: 24px;
`;

export const StyledOnboardingScreenContainer = styled.div`
  padding:0px 50px;
`
