import React from "react";
import { ButtonProps } from "@mui/material";
import { ButtonContainer } from "./styled";

interface CustomizedButtonProps extends ButtonProps {
  buttonText: any;
  buttonClass?: String;
  style?: any;
}

const CustomizedButton = ({
  buttonText = "Text",
  buttonClass,
  style,
  ...props
}: CustomizedButtonProps) => {
  return (
    <ButtonContainer
      {...props}
      className={`${buttonClass} danger`}
      style={style}
    >
      {buttonText}
    </ButtonContainer>
  );
};

export default CustomizedButton;
