import { useEffect, useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import UTGTextField from "./UTGTextField";
import {
  StyledSuggestDropdown,
  StyledSuggestDropdownBody,
  StyledSuggestDropdownHeader,
  StyledUTGDropdownMainContainer,
} from "./styled";
import { utgPlaceholders } from "../../utils/common.utils";

function UTGTextFieldWithPopup({
  active,
  setActive,
  text,
  setText,
  onGenerate,
  disabled,
}: any) {
  //states
  const [placeholderIndex, setPlaceholderIndex] = useState<number>(0);
  const [indexSelection, setIndexSelection] = useState<any>({});

  //useeffects
  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prev) =>
        prev == utgPlaceholders.length - 1 ? 0 : prev + 1
      );
    }, 3000);
    if (active) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [active]);
  useEffect(() => {
    utgPlaceholders.forEach((item, index) => {
      if (!text.includes(item)) {
        setIndexSelection((prev: any) => {
          return { ...prev, [index]: false };
        });
      }
    });
  }, [text]);
  //actions
  const onSuggestSelection = (index: number) => {
    if (!indexSelection[index]) {
      setText((prev: string) => {
        let tempStr = prev + (prev.length ? " " : "") + utgPlaceholders[index];
        return tempStr.substring(0, 500);
      });
      setIndexSelection((prev: any) => {
        return { ...prev, [index]: true };
      });
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setActive(false)}>
      <StyledUTGDropdownMainContainer active={active}>
        <UTGTextField
          active={active}
          setActive={setActive}
          text={text}
          setText={setText}
          placeholder={utgPlaceholders[placeholderIndex]}
          onGenerate={onGenerate}
          disabled={disabled}
        />
        {active && (
          <StyledSuggestDropdown>
            <StyledSuggestDropdownHeader>Suggested</StyledSuggestDropdownHeader>
            {utgPlaceholders?.map((item, index) => (
              <StyledSuggestDropdownBody
                active={indexSelection[index] ?? false}
                onClick={() => {
                  onSuggestSelection(index);
                }}
              >
                {item}
              </StyledSuggestDropdownBody>
            ))}
          </StyledSuggestDropdown>
        )}
      </StyledUTGDropdownMainContainer>
    </ClickAwayListener>
  );
}

export default UTGTextFieldWithPopup;
