import styled from "styled-components";
import { ReactComponent as BetaTitleLogo } from "../../../assets/svg/betaTitleLogo.svg";
import { devices } from "../../../scss/StyledComponent/Styled";
interface Props {
  height?: number;
}

export const StyledMainGsaiDiv = styled.div`
  @media ${devices.tablet} {
    font-size: 10px;
  }
  @media ${devices.laptop} {
    font-size: 13px;
  }
  @media ${devices.laptopL} {
    font-size: 14px;
  }

  @media ${devices.desktop} {
    font-size: 16px;
  }
`;

export const StyledTitle = styled.p`
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375em;
  line-height: 1.625em;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 0em;
`;

export const StyledGsaiHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledCardHeader = styled.h5`
  margin-top: 14px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
`;

export const StyledBetaLogo = styled(BetaTitleLogo)`
  margin-left: 0.625em;
`;
export const StyledGSAIContainer = styled.div<Props>`
  display: flex;
  position: relative;
  flex-direction: column;
  background: #ffffff;
  padding: 0em 1em 2.1875em 1em;
  height: calc(100vh - ${(props) => props.height}px);
  border-radius: 0.5em;
  margin-top: 1.25em;
`;
export const StyledErrorBanner = styled.div`
  width: 100%;
  height: 10em;
  display: flex;
  align-items: center;
  padding-left: 1em;
  background: #fdeeee;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.1875em;
  border-radius: 0.75em;
  margin-top: 1.56em;
`;
export const StyledErrorSVG = styled.div`
  padding-right: 0.5em;
`;
export const StyledCardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-height: 100%;
  overflow: auto;
  overflow-y: scroll;
  gap: 0.5em;
  margin-top: 1.56em;
`;

export const StyledNoAdsImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledGSAILoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledGSAILoaderGif = styled.img`
  width: 36vw;
  height: 50vh;
  margin-bottom: 1vh;
`;

export const StyledGSAILoaderStep = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.825em;
`;

export const StyledGSAICommonLoader = styled.img`
  height: 1.3rem;
  width: 1.3rem;
`;

export const StyledGSAILoader = styled.div`
  padding-right: 0.5em;
`;

export const StyledGSAIText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 1.375em;
  color: #808080;
`;
export const StyledErrorFail = styled.span`
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 1.375em;
  color: red;
`;

export const StyledGSAIStepContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledHeaderContainer = styled.div`
  position: relative;
  z-index: 1202;
`;

export const StyledMetricInfo = styled.div`
  position: relative;
  padding: 1em;
  width: 24.25em;
  border-radius: 0.625em;
  background: #ffffff;
`;

export const StyledFooterBox = styled.div`
  background-color: white;
  position: absolute;
  bottom: 0em;
  left: 0em;
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 0.625em;
  line-height: 3.2em;
  text-align: left;
  padding-left: 1em;
  height: 4.3 em;
  width: 100%;
  border-radius: 
  z-index: 10;
  box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);
`;
