import React, { memo, useState } from "react";
import { Handle } from "react-flow-renderer";
import Toggle from "react-toggle";
import "../index.scss";
import "./customInputNode.scss";
// import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCalibrationFormData } from "../../../store/actions/targetingAI";

export default memo(({ data, isConnectable }) => {
  const [input, setInput] = useState("");
  const {
    CalibrationCustomKeywords: customKeywords,
    CalibrationCustomKeywordsCheck,
  } = useSelector((state) => state.calibration.formData);
  const dispatch = useDispatch();

  const [deleteTag, setDeleteTag] = useState(false);

  return (
    <div className="p-3 bg-white card-style ">
      <Handle
        type="target"
        position="left"
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div className="text-sm d-flex justify-content-between font-weight-bold">
        {data.data[0].label}
        <Toggle
          className="right-0 custom-react-toggle"
          icons={false}
          id="biscuit-status"
          defaultChecked={CalibrationCustomKeywordsCheck}
          aria-labelledby="biscuit-label"
          onChange={(e) => {
            dispatch(
              setCalibrationFormData({
                key: "CalibrationCustomKeywordsCheck",
                value: e.target.checked,
              })
            );
          }}
        />
      </div>
      <div>
        <input
          className="p-2 mt-2 text-sm bg-white border rounded-lg w-100 bg border-slate-200"
          id="text"
          name="text"
          placeholder="Enter keywords"
          onChange={(e) => setInput(e.target.value)}
          disabled={!CalibrationCustomKeywordsCheck}
          value={input}
        />
      </div>
      <div
        className="flex flex-wrap px-0 py-2 overflow-auto justify-content-start"
        style={{ maxHeight: "60px" }}
      >
        {customKeywords?.map((element, index) => {
          return (
            <div
              key={index}
              className="m-1 overflow-scroll border d-flex align-items-center text-primary border-primary rounded-pill"
            >
              <p className="p-1 chip-custom-style">{element}</p>
              <button
                className="m-0 chip-remove-custom-style text-primary"
                onClick={() => {
                  const filteredKeywords = customKeywords.filter(
                    (keyword, keywordIdx) => index !== keywordIdx
                  );
                  dispatch(
                    setCalibrationFormData({
                      key: "CalibrationCustomKeywords",
                      value: filteredKeywords,
                    })
                  );
                  setDeleteTag(!deleteTag);
                }}
              >
                X
              </button>
            </div>
          );
        })}
      </div>
      <div className="mt-1 d-flex justify-content-end">
        <button
          className="button-styling primary font-weight-bold"
          onClick={() => {
            if (input.length > 0) {
              const newKeywords = [input, ...customKeywords];
              dispatch(
                setCalibrationFormData({
                  key: "CalibrationCustomKeywords",
                  value: newKeywords,
                })
              );
              setInput("");
            }
          }}
          disabled={!CalibrationCustomKeywordsCheck && input.length > 0}
        >
          + Add
        </button>
      </div>
      <Handle
        type="source"
        position="right"
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
    </div>
  );
});
