import React from "react";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";

function MultipleSelectCheckbox({
  sx,
  items,
  selectedItems,
  handleChange,
}: any) {
  selectedItems = selectedItems === "all" ? items : selectedItems;
  return (
    <Select
      multiple
      size="small"
      sx={{
        cursor: "pointer",
        border: "1px solid #DDD !important",
        borderRadius: "8px !important",
        "& legend": { display: "none" },
        "& fieldset": { top: 0, border: "none" },
        "& .MuiSelect-select": {
          fontSize: "0.75rem !important",
          fontFamily: "Inter !important",
          fontWeight: "400 !important",
          border: "none !important",
        },
        "& input": {
          border: "none !important",
          fontSize: "0.75rem",
          fontFamily: "Inter",
          fontWeight: 400,
        },
        "& ul": {
          left: "0px",
        },
        "& svg": {
          color: "#000",
        },

        ...sx,
      }}
      className="select-options"
      onChange={handleChange}
      defaultValue={items === 0 ? ["NO DATA FOUND"] : selectedItems}
      value={items === 0 ? ["NO DATA FOUND"] : selectedItems}
      renderValue={(selected) => {
        // if (selected.includes(0)) {
        //   return items[0];
        // } else {
        return selected.join();
        // }
      }}
      disabled={items.length === 0}
    >
      {items.length > 0 && (
        <MenuItem
          value={0}
          sx={{
            fontSize: "0.75rem",
            fontFamily: "Inter",
            fontWeight: 400,
          }}
          key={0}
          disabled={items.length === 1}
        >
          <Checkbox
            checked={selectedItems.length === items.length}
            disabled={items.length === 1}
            sx={{ p: 0, pr: 0.5 }}
          />
          <Box
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "12rem",
            }}
          >
            Select All
          </Box>
        </MenuItem>
      )}
      {items.map((item: string) => (
        <MenuItem
          key={item}
          sx={{
            fontSize: "0.75rem",
            fontFamily: "Inter",
            fontWeight: 400,
          }}
          value={item}
          disabled={selectedItems.includes(item) && selectedItems.length === 1}
        >
          <Checkbox
            checked={selectedItems.includes(item)}
            sx={{ p: 0, pr: 0.5 }}
            disabled={
              selectedItems.includes(item) && selectedItems.length === 1
            }
          />
          <Box
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "12rem",
            }}
          >
            {item}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
}

export default MultipleSelectCheckbox;
