
import { cloneDeep } from "lodash";
import { creativeReplacement, editCluster, loadHeadlineDescription, saveCluster, staticTargetKeyword } from "../../utils/constants/GSAI";
import { setSaveCluster } from "../actions/cluster";
import { CREATE_DESCRIPTION_ERROR, CREATE_HEADLINE_ERROR, GENERATE_DESCRIPTION_TIMESTAMP, GENERATE_HEADLINE_TIMESTAMP, GSAI_DROPDOWN_DISABLED, SetActiveClusterStep, SetBrand, SetClusterDetails, SetClusterKeywords, SetClusterLoader, SetClusterName, SetClusters, SetClusterSpinner, SetClusterTable, SetCreateAdAdGroup, SetCreateAdAds, SetCreateAdCampaign, SetCreateAdDesc, SetCreateAdHeadline, SetCreateDescError, SetCreateDescParam, SetCreateGroupForm, SetCreateHeadlineError, SetCreateHeadlineParam, SetCreativeReplacement, SetDescLoader, SetDescriptionID, SetEditCluster, SetGenerateDescriptionTimeStamp, SetGenerateHeadlineTimestamp, SetGetDetails, SetGroupsLoader, SetGSAIDropdownDisabled, SetHeaderLoader, SetHeadlineID, SetKeyWordsFile, SetLoadHeadLineDesc, SetPinningPositions, SetPublishCluster, SetSaveCluster, SetSelectedTabIndex, SetShowDrawer, SetTargetKeyword, SetTimer, SET_ACTIVE_CLUSTER_STEP, SET_BRAND, SET_CLUSTERS, SET_CLUSTER_DETAILS, SET_CLUSTER_KEYWORDS, SET_CLUSTER_LOADER, SET_CLUSTER_NAME, SET_CLUSTER_SPINNER, SET_CLUSTER_TABLE, SET_CREATEAD_ADS, SET_CREATEAD_CAMPAIGN, SET_CREATEAD_GROUP, SET_CREATE_AD_DESC, SET_CREATE_AD_HEADLINE, SET_CREATE_DESC_PARAM, SET_CREATE_GROUP_FORM, SET_CREATE_HEADLINE_PARAM, SET_CREATIVE_REPLACEMENT, SET_DESCRIPTION_ID, SET_DESC_LOADER, SET_EDIT_CLUSTER, SET_GET_DETAILS, SET_GROUPS_LOADER, SET_HEADER_LOADER, SET_HEADLINE_ID, SET_KEYWORD_FILE, SET_LOAD_HEADLINE_DESC, SET_PINNING_POSITIONS, SET_PUBLISH_CLUSTER, SET_SAVE_CLUSTER, SET_SELECTED_TAB_INDEX, SET_SHOW_DRAWER, SET_TARGET_KEYWORD, SET_TIMER } from "../types/cluster";

const initialState = {
    createAdCampaign: null,
    clusterSpinner: false,
    selectedTabIndex:0,
    activeClusterStep: 1,
    showDrawer: true,
    createGroupForm: null,
    clusterName: null,
    clusterTable: null,
    publishCluster: null,
    editCluster:editCluster,
    saveCluster:saveCluster,
    createAdAdGroup: null,
    createAdAds: null,
    clusterKeywords: null,
    getDetails: null,
    headlineID: null,
    descriptionID: null,
    brand: null,
    clusterLoader: null,
    groupsLoader:null,
    clusters: null,
    headerLoader: null,
    loadHeadlineDesc:loadHeadlineDescription,
    descLoader: null,
    clusterDetails: null,
    keyWordsFile: null,
    targetKeyword: cloneDeep(staticTargetKeyword),
    createAdHeadline: [],
    createAdDesc: [],
    createHeadlineParam: null,
    createDescParam: null,
    pinningPositions:null,
    creativeReplacement:{...creativeReplacement},
    timer: null,
    createHeadlineError: false,
    createDescError: false,
    generateHeadlineTimestamp: null,
    generateDescriptionTimestamp: null,
    gsaiDropdownDisabled: false,
};

const clusterReducer = (state = initialState, action: SetCreateAdCampaign
    | SetClusterSpinner
    | SetSelectedTabIndex
    | SetActiveClusterStep
    | SetShowDrawer
    | SetCreateGroupForm
    | SetClusterName
    | SetClusterTable
    | SetPublishCluster
    | SetEditCluster
    | SetSaveCluster
    | SetCreateAdAdGroup
    | SetCreateAdAds
    | SetClusterKeywords
    | SetGetDetails
    | SetHeadlineID
    | SetDescriptionID
    | SetBrand
    | SetClusterLoader
    | SetGroupsLoader
    | SetHeaderLoader
    | SetLoadHeadLineDesc
    | SetDescLoader
    | SetClusters
    | SetClusterDetails
    | SetKeyWordsFile
    | SetTargetKeyword
    | SetCreateAdHeadline
    | SetCreateAdDesc
    | SetCreateHeadlineParam
    | SetCreateDescParam
    | SetPinningPositions
    | SetCreativeReplacement
    | SetTimer
    | SetCreateHeadlineError
    | SetCreateDescError
    | SetGenerateHeadlineTimestamp
    | SetGenerateDescriptionTimeStamp
    | SetGSAIDropdownDisabled
) => {
    switch (action.type) {
        case SET_CREATEAD_CAMPAIGN: {
            return {
                ...state,
                createAdCampaign: action.payload
            }
        }
        case SET_CLUSTER_SPINNER: {
            return {
                ...state,
                clusterSpinner: action.payload
            }
        }
        case SET_SELECTED_TAB_INDEX: {
            return {
                ...state,
                selectedTabIndex: action.payload
            }
        }
        case SET_ACTIVE_CLUSTER_STEP: {
            return {
                ...state,
                activeClusterStep: action.payload
            }
        }
        case SET_SHOW_DRAWER: {
            return {
                ...state,
                showDrawer: action.payload
            }
        }
        case SET_CREATE_GROUP_FORM: {
            return {
                ...state,
                createGroupForm: action.payload
            }
        }
        case SET_CLUSTER_NAME: {
            return {
                ...state,
                clusterName: action.payload
            }
        }
        case SET_CLUSTER_TABLE: {
            return {
                ...state,
                clusterTable: action.payload
            }
        }
        case SET_PUBLISH_CLUSTER: {
            return {
                ...state,
                publishCluster: action.payload
            }
        }
        case SET_EDIT_CLUSTER: {
            return {
                ...state,
                editCluster: action.payload
            }
        }
        case SET_SAVE_CLUSTER: {
            return {
                ...state,
                saveCluster: action.payload
            }
        }
        case SET_CREATEAD_GROUP: {
            return {
                ...state,
                createAdAdGroup: action.payload
            }
        }
        case SET_CREATEAD_ADS: {
            return {
                ...state,
                createAdAds: action.payload
            }
        }
        case SET_CLUSTER_KEYWORDS: {
            return {
                ...state,
                clusterKeywords: action.payload
            }
        }
        case SET_GET_DETAILS: {
            return {
                ...state,
                getDetails: action.payload
            }
        }
        case SET_HEADLINE_ID: {
            return {
                ...state,
                headlineID: action.payload
            }
        }
        case SET_DESCRIPTION_ID: {
            return {
                ...state,
                descriptionID: action.payload
            }
        }
        case SET_BRAND: {
            return {
                ...state,
                brand: action.payload
            }
        }
        case SET_CLUSTER_LOADER: {
            return {
                ...state,
                clusterLoader: action.payload
            }
        }
        case SET_GROUPS_LOADER: {
            return {
                ...state,
                groupsLoader: action.payload
            }
        }
        case SET_HEADER_LOADER: {
            return {
                ...state,
                headerLoader: action.payload
            }
        }
        case SET_LOAD_HEADLINE_DESC: {
            return {
                ...state,
                loadHeadlineDesc: action.payload
            }
        }
        case SET_DESC_LOADER: {
            return {
                ...state,
                descLoader: action.payload
            }
        }
        case SET_CLUSTERS: {
            return {
                ...state,
                clusters: action.payload
            }
        }
        case SET_CLUSTER_DETAILS: {
            return {
                ...state,
                clusterDetails: action.payload
            }
        }
        case SET_KEYWORD_FILE: {
            return {
                ...state,
                keyWordsFile: action.payload
            }
        }
        case SET_TARGET_KEYWORD: {
            return {
                ...state,
                targetKeyword: action.payload
            }
        }
        case SET_CREATE_AD_HEADLINE: {
            return {
                ...state,
                createAdHeadline: action.payload
            }
        }
        case SET_CREATE_AD_DESC: {
            return {
                ...state,
                createAdDesc: action.payload
            }
        }
        case SET_CREATE_HEADLINE_PARAM: {
            return {
                ...state,
                createHeadlineParam: action.payload
            }
        }
        case SET_CREATE_DESC_PARAM: {
            return {
                ...state,
                createDescParam: action.payload
            }
        }
        case SET_PINNING_POSITIONS: {
            return {
                ...state,
                pinningPositions: action.payload
            }
        }
        case SET_CREATIVE_REPLACEMENT: {
            return {
                ...state,
                creativeReplacement: action.payload
            }
        }
        case SET_TIMER: {
            return {
                ...state,
                timer: action.payload
            }
        }

        case CREATE_HEADLINE_ERROR: {
          return {
            ...state,
            createHeadlineError: action.payload,
          };
        }
        case CREATE_DESCRIPTION_ERROR: {
          return {
            ...state,
            createDescError: action.payload,
          };
        }
        case GENERATE_HEADLINE_TIMESTAMP: {
          return {
            ...state,
            generateHeadlineTimestamp: action.payload,
          };
        }
        case GENERATE_DESCRIPTION_TIMESTAMP: {
          return {
            ...state,
            generateDescriptionTimestamp: action.payload,
          };
        }
        case GSAI_DROPDOWN_DISABLED: {
            return {
              ...state,
              gsaiDropdownDisabled: action.payload,
            };
          }

        default:
            return state;
    }
};

export default clusterReducer;