import Box from "@mui/material/Box";
import GroupRow from "../common/GroupRow";
import Header from "../Components/Header";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SecondaryTilesRow from "../Components/SecondaryTilesRow";
import { getAllOptimizersDetails } from "../../../store/actions/SmbDashboard/selectedBrand";
import { useLocation } from "react-router-dom";
import { getBrandMetrics } from "../../../store/actions/SmbDashboard/brand";
import GreyLoader from "../assets/gif/GreyLoader.gif";
import { getAllPlatforms } from "../../../store/actions/SmbDashboard/common";
import { mixPanelAdEvent } from "utils/mixpanel";

function BrandSummary() {
  const dispatch = useDispatch();
  const selectedBrandState = useSelector(
    (state: any) => state.smb.selectedBrand
  );
  const commonState = useSelector((state: any) => state.smb.common);
  const agenciesState: any = useSelector((state: any) => state.smb.agencies);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const brandId = selectedBrandState?.id || params.get("brand_id");
  const [searchTerm, setSearchTerm] = useState("");

  let brandName = selectedBrandState?.name || "";

  const getOptimizerMetricsCallBack = useCallback(() => {
    dispatch(getBrandMetrics({ brandIds: [brandId], ...commonState.calendar }));
    dispatch(getAllOptimizersDetails({ brandId }));
  }, [brandId, commonState.calendar]);

  useEffect(() => {
    getOptimizerMetricsCallBack();
  }, [commonState.calendar]);

  useEffect(() => {
    dispatch(getAllOptimizersDetails({ brandId }));
    if (!commonState.platforms) {
      dispatch(getAllPlatforms());
    }
  }, []);

  return (
    <Box className="font-inter" sx={{ height: "100vh" }}>
      <Header
        type="Brand"
        brandName={brandName}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />

      <Box
        style={{
          background: "#FFFFFF",
          borderRadius: "9px",
          padding: "10px",
          margin: "20px",
          height: "86vh",
          overflow: "auto",
        }}
      >
        <SecondaryTilesRow />
        <Box
          sx={
            selectedBrandState.optimizers.isLoading
              ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "85%",
                background: "#FFFFFF",
                borderRadius: "9px",
                p: 2,
                m: 2,
              }
              : {
                // overflowY: "scroll",
                height: "85%",
              }
          }
        >
          {selectedBrandState.optimizers.isLoading && (
            <img
              src={GreyLoader}
              alt="Loading"
              style={{ height: "10rem", width: "auto" }}
            />
          )}
          {searchTerm !== "" ? (
            <GroupRow
              optimizer={
                selectedBrandState.optimizers.isLoading
                  ? {}
                  : Object.keys(selectedBrandState?.optimizers)
                    .filter((el) =>
                      selectedBrandState.optimizers[el].name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .reduce((acc: any, el: any) => {
                      acc[el] = selectedBrandState.optimizers[el];
                      return acc;
                    }, {})
              }
            />
          ) : (
            <GroupRow
              optimizer={
                selectedBrandState.optimizers.isLoading
                  ? {}
                  : selectedBrandState.optimizers
              }
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default BrandSummary;
