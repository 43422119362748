import {
  SET_OPEN_SEARCH_AI_DATA,
  SUBMIT_FORM_DATA_STATE,
} from "../types/manualaction";

const initialState = {
  openSearchAiData: {},
  openSearchAiDataState: {
    loading: false,
    success: "",
    error: "",
  },
  submitManualActionState: {
    loading: false,
    success: "",
    error: "",
  },
};

const manualActionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_OPEN_SEARCH_AI_DATA: {
      return {
        ...state,
        openSearchAiData: action.payload,
      };
    }
    case "SET_OPEN_SEARCH_AI_DATA_STATE": {
      return {
        ...state,
        openSearchAiDataState: action.payload,
      };
    }
    case "CLEAR_OPEN_SEARCH_AI_DATA": {
      return {
        ...state,
        openSearchAiData: {},
        openSearchAiDataState: {
          loading: false,
          success: "",
          error: "",
        },
        submitManualActionState: {
          loading: false,
          success: "",
          error: "",
        },
      };
    }
    case SUBMIT_FORM_DATA_STATE: {
      return {
        ...state,
        submitManualActionState: action.payload,
      };
    }

    default:
      return state;
  }
};
export default manualActionReducer;
