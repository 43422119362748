import styled from "styled-components";

interface Props {
  type: "Ad" | "Variant";
}
export const StyledMainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export const StyledSvgContainer = styled.div`
  margin-bottom: 1.1em;
`;
export const StyledMessageContainer = styled.div<Props>`
  font-size: 1.125em;
  width: ${(props) => (props.type == "Ad" ? "16.944em" : "20em")};
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  line-height: 1.22em;
  text-align: center;
`;
