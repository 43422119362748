import { PerformanceScoreType } from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";

export const SET_HASH_ID = "SET_HASH_ID";
export const GET_REQUESTED_VARIANTS_DATA = "GET_REQUESTED_VARIANTS_DATA";
export const SET_REQUESTED_VARIANTS_DATA = "SET_REQUESTED_VARIANTS_DATA";
export const GET_REQUESTED_VARIANT = "GET_REQUESTED_VARIANT";
export const SET_REQUESTED_VARIANT = "SET_REQUESTED_VARIANT";
export const GET_AD_DATA = "GET_AD_DATA";
export const SET_AD_DATA = "SET_AD_DATA";
export const POST_REVIEW_RESPONSE = "POST_REVIEW_RESPONSE";
export const SET_POST_REVIEW_FLAG = "SET_POST_REVIEW_FLAG";
export const UPDATE_COMMENTS = "UPDATE_COMMENTS";
export const UPDATE_SELECTED_VARIANT_ID = "UPDATE_SELECTED_VARIANT_ID";
export const UPDATE_REVIEW_STATUS = "UPDATE_REVIEW_STATUS";

// initial state types
export interface ReviewInitialState {
  hashId?: string;
  requestedVariantsMetaData: RequestedVariantsType;
  requestedVariantData: RequestedVariantResponseType;
  adData: GetAdDataResponseType;
  selectedVariantRequestedId?: number;
}

// setting hash id
export interface SetHashID {
  type: typeof SET_HASH_ID;
  payload: string;
}

// requested variants metadata
export interface GetRequestedVariants {
  type: typeof GET_REQUESTED_VARIANTS_DATA;
  payload: {
    hashId: string;
  };
}

export interface SetRequestedVariants {
  type: typeof SET_REQUESTED_VARIANTS_DATA;
  payload: RequestedVariantsType;
}

export interface RequestedVariantsType {
  isLoading: boolean;
  isFailed: boolean;
  data?: {
    type: string;
    platform: string;
    reviewRequestedBy: string;
    reviewRequestedAt: string;
    variants: RequestedVariantType[];
  };
}
export interface RequestedVariantType {
  id: string;
  status: string;
  reviewStatus: ReviewStatusType;
  variantInfo: {
    ad: string;
    brandName: string;
    adAccountId: string;
    adset: string;
    adAccountName: string;
    campaign: string;
    adgroup: string;
  };
  createdAt: string;
  updatedAt: string;
}

// variant level data
export interface GetRequestedVariant {
  type: typeof GET_REQUESTED_VARIANT;
  payload: GetRequestedVariantPayload;
}

export interface GetRequestedVariantPayload {
  hashId: string;
  variantId: string;
}

export interface SetRequestedVariant {
  type: typeof SET_REQUESTED_VARIANT;
  payload: RequestedVariantResponseType;
}

export interface RequestedVariantResponseType {
  isLoading: boolean;
  isFailed: boolean;
  data?: RequestedVariantResponseDataType;
}

export interface RequestedVariantResponseDataType {
  id: string;
  adsetId: string;
  adId: string;
  status: string;
  mediaUrl: string;
  billboardMediaUrl?: string;
  reviewStatus: ReviewStatusType;
  isCreate?: boolean;
  variantType?: string;
  variantInfo: {
    adId: string;
    adAccountName: string;
    adAccountId: string;
    campaign: string;
    adset: string;
    adgroup: string;
    brandName: string;
  };
  createdAt: string;
  updatedAt: string;
  payload: any;
  performanceScore: PerformanceScoreType;
  variantMetaInfo: {
    primaryText: {
      name: string;
    };
    headline: {
      name: string;
    };
    description: {
      name: string;
    };
    mediaId: string;
    headlineReviewComments: CommentType;
    descriptionReviewComments: CommentType;
    primaryTextReviewComments: CommentType;
    mediaReviewComments: CommentType;
    createdAt: string;
    updatedAt: string;
    data?: any;
    meta_data?: any;
    billboardMediaId?: string;
  };
}

export interface CommentType {
  reviewedBy: string;
  text: string;
  createdAt: string;
  isLoading?: boolean;
}

// ad level data

export interface GetAdData {
  type: typeof GET_AD_DATA;
  payload: {
    adId: string;
    hashId: string;
    adAccountId: string;
  };
}

export interface SetAdData {
  type: typeof SET_AD_DATA;
  payload: GetAdDataResponseType;
}

export interface GetAdDataResponseType {
  isLoading: boolean;
  isFailed: boolean;
  data?: GetAdDataResponse;
}

export interface GetAdDataResponse {
  id: string;
  name: string;
  previews: any;
  creative: {
    id: string;
  };
}

// post review response
export interface PostReviewResponse {
  type: typeof POST_REVIEW_RESPONSE;
  payload: ResponsePayload;
}

export interface ResponsePayload {
  payload: {
    reviewStatus: ReviewStatusType;
    headlineReviewComments: string;
    descriptionReviewComments: string;
    primaryTextReviewComments: string;
    mediaReviewComments: string;
  };
  hashId: string;
  variantId: string;
  commentKey: CommentKey | "reviewStatus";
}

export interface UpdateComments {
  type: typeof UPDATE_COMMENTS;
  payload: UpdateCommentsPayload;
}

export interface UpdateCommentsPayload {
  key: CommentKey;
  payloadData: {
    text?: string;
    isLoading?: boolean;
  };
}

export type ReviewStatusType = "APPROVED" | "REJECTED" | "UNDER_REVIEW";

export type CommentKey =
  | "headlineReviewComments"
  | "descriptionReviewComments"
  | "primaryTextReviewComments"
  | "mediaReviewComments";

// update local states
export interface UpdateSelectedVariantId {
  type: typeof UPDATE_SELECTED_VARIANT_ID;
  payload?: number;
}

export interface UpdateReviewStatus {
  type: typeof UPDATE_REVIEW_STATUS;
  payload: ReviewStatusType;
}
