import {
  GetAgenciesSmb,
  GET_AGENCIES_SMB,
} from "../../types/SmbDashboard/agencies";
import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../../HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "../../../utils/path";
import { smbDashboardApi } from "MappedAPI/MappedAPI";
import {
  setAgenciesSmb,
  setSelectedAgencySmb,
} from "../../actions/SmbDashboard/agencies";
import { setErrorForAgencies } from "../../actions/SmbDashboard/error";
import { resetBrandDetails } from "../../actions/SmbDashboard/brand";

function* getAgenciesSmb(action: GetAgenciesSmb): any {
  let {
    payload: { user, agencyId },
  } = action;

  try {
    const urlParams = `${smbDashboardApi}/agencies?smb=true`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      yield put(resetBrandDetails());
      yield put(setAgenciesSmb(response.data.data));
      yield put(
        setSelectedAgencySmb(
          agencyId
            ? response.data.data.find((agency: any) => agency.id === agencyId)
            : response.data.data[0]
        )
      );
    } else {
      yield put(
        setErrorForAgencies({
          type: GET_AGENCIES_SMB,
          message: response.data.error,
        })
      );
    }
  } catch (error: any) {
    yield put(
      setErrorForAgencies({
        type: GET_AGENCIES_SMB,
        message: error,
      })
    );
  }
}

export function* AgenciesWatcher() {
  yield takeLatest(GET_AGENCIES_SMB, getAgenciesSmb);
}
