import {
  GetCohortListValues,
  GET_COHORT,
  GetCohortValues,
  SetCohortList,
  SET_COHORT_LIST,
  SET_RECOMMENDATION_METADATA_TEMPLATE,
  SetRecommendationMetadataTemplate,
  SET_RECOMMENDATION_TIMER,
  SetRecommendationTimer,
  GET_RECOMMENDATION_METADATA_TEMPLATE,
  GetRecommendationMetadataTemplate,
} from "../../../../types/CreativeAI/Facebook/SelectCohort/selectCohortType";

const initialState = {
  cohortList: [],
  cohortListCount: 0,
  cohortGetStart: false,
  cohortData: {},
  recommendationMetadataTemplate: undefined,
  recommendationTimer: null,
  loadingRecommendation: false,
};

const selectCohortReducer = (
  state = initialState,
  action:
    | GetCohortListValues
    | GetCohortValues
    | SetCohortList
    | SetRecommendationMetadataTemplate
    | SetRecommendationTimer
    | GetRecommendationMetadataTemplate
    | any
) => {
  switch (action.type) {
    case SET_COHORT_LIST: {
      return {
        ...state,
        cohortList: [...state.cohortList, ...action.payload?.adsets],
        cohortListCount: action.payload?.totalCount,
      };
    }
    case "RESET_COHORT_LIST": {
      return {
        ...state,
        cohortList: action.payload?.adsets,
        cohortListCount: action.payload?.totalCount,
      };
    }
    case "GET_COHORT_START": {
      return {
        ...state,
        cohortGetStart: action.payload,
      };
    }
    case GET_COHORT: {
      return {
        ...state,
        cohortData: action.payload,
      };
    }
    case GET_RECOMMENDATION_METADATA_TEMPLATE: {
      return {
        ...state,
        loadingRecommendation: true,
      };
    }

    case SET_RECOMMENDATION_METADATA_TEMPLATE: {
      return {
        ...state,
        recommendationMetadataTemplate: action.payload,
      };
    }
    case SET_RECOMMENDATION_TIMER: {
      return {
        ...state,
        recommendationTimer: action.payload,
      };
    }
    default:
      return state;
  }
};
export default selectCohortReducer;
