import {
  GET_AGENCIES_SMB,
  GetAgenciesSmb,
  SET_AGENCIES_SMB,
  SetAgenciesSmb,
  SET_SELECTED_AGENCY_SMB,
  SetSelectedAgencySmb,
} from "../../types/SmbDashboard/agencies";

export const getAgenciesSmb = (payload: any): GetAgenciesSmb => ({
  type: GET_AGENCIES_SMB,
  payload,
});

export const setAgenciesSmb = (payload: any): SetAgenciesSmb => ({
  type: SET_AGENCIES_SMB,
  payload,
});

export const setSelectedAgencySmb = (payload: any): SetSelectedAgencySmb => ({
  type: SET_SELECTED_AGENCY_SMB,
  payload,
});
