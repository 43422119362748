import { InputProps } from "@mui/material";
import { StyledTextInput } from "./styled";

export interface TextInputProps extends InputProps {
  isBorder?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  height?: string;
}

export function TextInput({
  isBorder,
  backgroundColor,
  borderColor,
  height,
  ...props
}: TextInputProps) {
  return (
    <StyledTextInput
      {...props}
      isBorder={isBorder}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      height={height}
    />
  );
}
