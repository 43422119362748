import { Card } from "@mui/material";
import styled from "styled-components";

export const StyledCreativeAIListingWrapper = styled(Card)`
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 24px;
`;
export const StyledTabsContainer = styled.div`
  .MuiTab-root {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    outline: 0;
    font-family: "Inter";
    color: #000000;
  }
`;
