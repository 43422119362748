import {
  SetActivePlatformDebugger,
  SetAdSet,
  SetAdSetLoading,
  SetAIGroups,
  SetAIGroupsLoading,
  SetCampaignDebuggerLoading,
  SetSelectedAdAccount,
  SetSelectedAdAccountLoading,
  SetSelectedCampaignDebugger,
  SET_ACTIVE_PLATFORM_DEBUGGER,
  SET_AD_ACCOUNT_LOADING,
  SET_AD_SET,
  SET_AD_SET_LOADING,
  SET_AI_GROUP,
  SET_AI_GROUP_LOADING,
  SET_SELECTED_AD_ACCOUNT,
  SET_SELECTED_CAMPAIGN_DEBUGGER,
  SET_SELECTED_CAMPAIGN_DEBUGGER_LOADING,
} from "../../types/ActionDebugger/ActionDebuggerHelper";
import { groups } from "../../types/adaccount";

export const setAIGroupsLoading = (payload: boolean): SetAIGroupsLoading => ({
  type: SET_AI_GROUP_LOADING,
  payload,
});

export const setAIGroups = (payload: groups): SetAIGroups => ({
  type: SET_AI_GROUP,
  payload,
});

export const setSelectedAdAccountLoading = (
  payload: boolean
): SetSelectedAdAccountLoading => ({
  type: SET_AD_ACCOUNT_LOADING,
  payload,
});

export const setSelectedAdAccount = (payload: any): SetSelectedAdAccount => ({
  type: SET_SELECTED_AD_ACCOUNT,
  payload,
});

export const setActivePlatformDebugger = (
  payload: any
): SetActivePlatformDebugger => ({
  type: SET_ACTIVE_PLATFORM_DEBUGGER,
  payload,
});

export const setAdSet = (payload: any): SetAdSet => ({
  type: SET_AD_SET,
  payload,
});

export const setAdSetLoading = (payload: boolean): SetAdSetLoading => ({
  type: SET_AD_SET_LOADING,
  payload,
});

export const setSelectedCampaignDebugger = (
  payload: any
): SetSelectedCampaignDebugger => ({
  type: SET_SELECTED_CAMPAIGN_DEBUGGER,
  payload,
});

export const setCampaignDebuggerLoading = (
  payload: boolean
): SetCampaignDebuggerLoading => ({
  type: SET_SELECTED_CAMPAIGN_DEBUGGER_LOADING,
  payload,
});
