import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { StyledCRSelect, StyledDivider } from "../styled";
import { Button, CheckBox, StyledText } from "components/shared";
import {
  LightTooltip,
  PxDialog,
  TextWithTooltip,
} from "components/CreativeAI_Revamp/shared";
import {
  Box,
  CircularProgress,
  MenuItem,
  SelectProps,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { HeadCell } from "./CRAccordionItemTable";
import ImageCollection from "./ImageCollection";

import CRTooltipWithAdDetails from "./CRTooltipWithAdDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  CreativeRotationInitialState,
  PostExecutionItemPayload,
  SetPreviewItem,
} from "store/types/ActionDebugger/CreativeRotation/creativeRotation.types";
import {
  postExecution,
  setNonRecommendedAds,
} from "store/actions/ActionDebugger/CreativeRotation/creativeRotation.actions";
import { ActionDebuggerHelperInitState } from "store/types/ActionDebugger/ActionDebuggerHelper";

import imagePlaceholder from "../../../../assets/png/no_image_placeholder.png";
import { ReactComponent as InfoIcon } from "../../../../assets/svg/info_grey.svg";
import { getAssetColorMapping, getPreviewImage } from "../common.utils";
import { AdAccountState } from "store/types/adaccount";
import { brandViewerAccessText } from "utils/constants/commonConsts";
import { mixPanelAdEvent } from "utils/mixpanel";
import { initialState } from "store/types/auth";
import { getPlatformName, isViewerRole } from "utils/commonFunctions";
import { useAuth0 } from "@auth0/auth0-react";

// modal head

const RejectionModalTitle = () => {
  return (
    <Box display="flex" flexDirection="column" gap=".25em" ml="-.75em">
      <StyledText
        fontWeight={700}
        fontSize="1.25em"
        lineHeight="24px"
        color="#1C1E21"
        fontFamily="Lato"
      >
        Select Reason for Rejection
      </StyledText>
      <StyledText
        fontWeight={400}
        fontSize="0.75em"
        lineHeight="15px"
        color="#919191"
      >
        This will help us to improve our recommendations
      </StyledText>
    </Box>
  );
};

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    label: "Items to Change",
    showDivider: true,
  },
  {
    id: "recommendation",
    label: "Recommendation",
    showDivider: true,
  },

  {
    id: "reason",
    numeric: true,
    label: "Reason for Rejection",
  },
  {
    id: "no-recommendation",
    label: " Add to No-Recommendation List",
    numeric: true,
    tooltip: {
      tooltipText:
        "Pause future recommendations for ads. You can edit this list using ‘no recommendation list’ in advanced settings",
    },
  },
];

function RejectionModalTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell, index: number) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "center" : "left"}
              padding="none"
              sx={{
                borderRight: "1px solid #e5e5e5",
                borderTop: "1px solid #e5e5e5",
                paddingLeft: index === 0 ? ".5em" : "0px",
              }}
            >
              <StyledText
                fontSize="0.75em"
                fontWeight={600}
                lineHeight="24px"
                color="#333333"
                p="1em .8em"
              >
                {headCell.label}
                {headCell.tooltip?.tooltipText && (
                  <Tooltip
                    title={headCell.tooltip?.tooltipText}
                    placement="top-end"
                    arrow
                  >
                    <InfoIcon
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                  </Tooltip>
                )}
              </StyledText>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

function RejectionModal({
  showRejectModal,
  onClose,
  selected,
  setBatchSelection,
}: {
  showRejectModal: boolean;
  onClose: () => void;
  selected: string[];
  setBatchSelection: any;
}) {
  const [anchorTooltipEl, setAnchorTooltipEl] =
    React.useState<HTMLElement | null>(null);

  const [batchRejectionReason, setBatchRejectionReason] = useState("");
  const [itemRejectionPayloadList, setItemRejectionPayloadList] = useState<
    PostExecutionItemPayload[]
  >([]);

  const { batchList, isRejectExecution, nonRecommendedAds } = useSelector(
    (state: { creativeRotationReducer: CreativeRotationInitialState }) => {
      return state.creativeRotationReducer;
    }
  );
  const {
    selectedAdAccount: {
      selectedAdAcc: { elementId },
    },
  } = useSelector(
    (state: { ActionDebuggerHelperReducer: ActionDebuggerHelperInitState }) =>
      state.ActionDebuggerHelperReducer
  );
  const { user } = useAuth0();
  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand, selectedAdAccount, selectedGroup, selectedPlatform } =
    adaccount;

  const batchItem = batchList.data[0];

  useEffect(() => {
    setItemRejectionPayloadList(
      selected.map((item) => ({
        recommendationId: item,
        userAction: "CLIENT_REJECTED",
        reason: "",
      }))
    );
  }, []);
  const dispatch = useDispatch();

  const { brandRoles } = adaccount;

  // const isBrandViewer = useMemo(
  //   () => brandRoles?.length === 1 && brandRoles[0]?.role === "brand-viewer",
  //   [brandRoles]
  // );

  const selectionArray: any = [];

  for (const item of batchItem.detailsList?.data ?? []) {
    if (selected.includes(item.recommendation_id)) {
      selectionArray.push(item);
    }
  }

  const assetColorMappingObject = getAssetColorMapping(
    selectionArray.map((row: any) => row.parent.asset_l1_name)
  );
  const [noRecommendationList, setNoRecommendationList] = useState<any>([
    ...(selectionArray.map((i: any) => i.asset_l0_ad_id) || []),
  ]);
  return (
    <Fragment>
      <PxDialog
        open={showRejectModal}
        onClose={onClose}
        titleComponent={<RejectionModalTitle />}
        maxWidth="md"
        fullWidth
        isFrom1920Screen
        actions={
          // <LightTooltip title={isBrandViewer ? brandViewerAccessText : ""}>
          // <LightTooltip
          //   title={isViewerRole(brandRoles) ? brandViewerAccessText : ""}
          // >
          <Button
            variant="contained"
            style={{
              // cursor: isBrandViewer ? "not-allowed" : "pointer",
              // cursor: isViewerRole(brandRoles) ? "not-allowed" : "pointer",
              cursor: "pointer",
            }}
            sx={{
              background: "#3F64FA",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "0.875em",
              lineHeight: "24px",
              borderRadius: "0.5em",
              width: "6em",
              height: "3em",
              opacity:
                (batchRejectionReason.length === 0 &&
                  itemRejectionPayloadList.some(
                    (item) => item.reason?.length === 0
                  )) ||
                isRejectExecution.isLoading
                  ? 0.5
                  : 1,
              pointerEvents:
                (batchRejectionReason.length === 0 &&
                  itemRejectionPayloadList.some(
                    (item) => item.reason?.length === 0
                  )) ||
                isRejectExecution.isLoading
                  ? "none"
                  : "all",
            }}
            onClick={
              // isBrandViewer
              // isViewerRole(brandRoles)
              //   ? undefined
              //   :
              () => {
                dispatch(
                  setNonRecommendedAds({
                    data: [...nonRecommendedAds, ...noRecommendationList],
                    elementId,
                  })
                );

                dispatch(
                  postExecution({
                    elementId,
                    body: itemRejectionPayloadList,
                    userAction: "CLIENT_REJECTED",
                  })
                );
                mixPanelAdEvent(
                  "Creative Rotation -Recommendations Rejected",
                  {
                    username: user?.email,
                    agency_id: selectedBrand?.agencyId,
                    agency_name: selectedBrand?.agency_name,
                    brand_id: selectedBrand?.id,
                    brand_name: selectedBrand?.name,
                    brand_type: selectedBrand?.brandType,
                    ad_account_id: selectedAdAccount?.id,
                    ad_account_name: selectedAdAccount?.name,
                    ai_group_name: selectedGroup?.name,
                    platform_name: getPlatformName(
                      selectedPlatform?.name
                    ),
                    number_of_actions: itemRejectionPayloadList?.length,
                  }
                );
                setTimeout(() => {
                  onClose();
                  setBatchSelection([]);
                }, 1000);
              }
            }
            // style={{ backgroundColor: "#B5D2FE", color: "#ffffff" }}
            // disabled={}
          >
            {isRejectExecution.isLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress
                  sx={{
                    color: "white",
                  }}
                  size={20}
                />
              </Box>
            ) : (
              "Submit"
            )}
          </Button>
          // </LightTooltip>
        }
      >
        <Box
          sx={{
            padding: "1em 0em",
            width: "100%",
          }}
          display="flex"
          flexDirection="column"
        >
          <StyledText
            fontWeight={700}
            fontSize="0.875em"
            lineHeight="17px"
            fontFamily="Lato"
            pl="1.25em"
            pb="0.875em"
          >
            Select common reason for rejection
          </StyledText>
          <Table
            sx={{ minWidth: "100%" }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <RejectionModalTableHead />
            <TableBody>
              <TableRow
                sx={{
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
              >
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    borderRight: "1px solid #e5e5e5",
                    maxWidth: "18em",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    display="flex"
                    sx={{
                      width: "95%",
                      paddingLeft: ".5em",
                    }}
                  >
                    {!batchItem?.imageData ||
                    batchItem?.imageData?.isLoading ||
                    !batchItem?.imgArray ? (
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          height: "3em",
                          width: "3em",
                          borderRadius: "1.55981px",
                        }}
                      />
                    ) : (
                      <Box
                        ml=".7em"
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        { (
                          <ImageCollection
                            imgArray={
                              batchItem.imageData?.data
                                ? selectionArray.map(
                                    (item: any) =>
                                      batchItem?.imageData?.data?.[
                                        item?.asset_l0_ad_id ?? ""
                                      ]?.adcreatives?.data?.[0]?.image_url ??
                                      imagePlaceholder
                                  )
                                : [imagePlaceholder]
                            }
                          />
                        )}
                      </Box>
                    )}
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      width="85%"
                    >
                      <StyledText
                        fontSize="0.75em"
                        color="#33343D"
                        pl=".75em"
                        // lineHeight="141.52%"
                      >
                        <span
                          style={{
                            color: "#0869FB",
                          }}
                        >
                          {" "}
                          {selectionArray.length} AD Creatives
                        </span>{" "}
                        with Recommendations
                      </StyledText>
                      <TextWithTooltip
                        text={`ID : ${batchList.data[0].batch_id}`}
                        fontSize="0.75em"
                        color="#33343D"
                        lineHeight="141.52%"
                        boxProps={{
                          marginLeft: ".75em",
                          width: "90%",
                        }}
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #e5e5e5",
                    width: "9.1em",
                  }}
                  align="center"
                >
                  <Box display="flex" flexDirection="column">
                    <StyledText
                      fontSize="0.75em"
                      color="#33343D"
                      lineHeight="141.52%"
                    >
                      {
                        selectionArray.filter(
                          (item: any) => item.action_to_do === "Turn OFF"
                        ).length
                      }{" "}
                      Turn Off Ad
                    </StyledText>
                    <StyledText
                      fontSize="0.75em"
                      color="#33343D"
                      lineHeight="141.52%"
                    >
                      {
                        selectionArray.filter(
                          (item: any) => item.action_to_do === "Turn ON"
                        ).length
                      }{" "}
                      Turn On Ad
                    </StyledText>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #e5e5e5",
                    width: "9.1em",
                  }}
                  align="center"
                >
                  <CRSelect
                    value={batchRejectionReason}
                    onChange={({ target: { value } }) => {
                      console.log("value", value);
                      setBatchRejectionReason(value as string);
                      setItemRejectionPayloadList(
                        selected.map((item) => ({
                          recommendationId: item,
                          userAction: "CLIENT_REJECTED",
                          reason: value as string,
                        }))
                      );
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #e5e5e5",
                  }}
                  align="center"
                >
                  <CheckBox
                    checked={
                      noRecommendationList.length === selectionArray.length
                    }
                    onClick={() => {
                      if (noRecommendationList.length === selectionArray.length)
                        setNoRecommendationList([]);
                      else
                        setNoRecommendationList([
                          ...(selectionArray.map((i: any) => i.asset_l0_ad_id) || []),
                        ]);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <StyledText
                  fontWeight={700}
                  fontSize="0.875em"
                  lineHeight="17px"
                  fontFamily="Lato"
                  ml="1.6em"
                  mb="1em"
                  mt="1em"
                >
                  Or, select reason individually
                </StyledText>
              </TableRow>
              {selectionArray.map((row: any, ind: number) => {
                // const index = batchItem.detailsList?.data?.findIndex(
                //   (row) => row.recommendation_id === item
                // );
                // const row = batchItem.detailsList?.data[index ?? 0];

                return (
                  <RejectTableItem
                    index={0}
                    assetColor={assetColorMappingObject[row?.parent?.asset_l1_name]}
                    key={row.recommendation_id}
                    data={{
                      adName: row?.asset_l0_ad_name ?? "",
                      adId: row?.asset_l0_ad_id ?? "",
                      adsetName: row?.parent.asset_l1_name ?? "",
                      campaign: row?.parent.asset_l2_name ?? "",
                      campaignId: row?.parent.asset_l2_id ?? "",
                      adsetId: row?.parent.asset_l1_id ?? "",
                    }}
                    noRecommendationList={noRecommendationList}
                    setNoRecommendationList={setNoRecommendationList}
                    actionToDo={row.action_to_do}
                    rejectionDetail={itemRejectionPayloadList[ind]}
                    onChange={({ target: { value } }: any) => {
                      const localItemRejectionPayloadList = [
                        ...itemRejectionPayloadList,
                      ];
                      localItemRejectionPayloadList[ind] = {
                        ...localItemRejectionPayloadList[ind],
                        reason: value,
                      };

                      setItemRejectionPayloadList(
                        localItemRejectionPayloadList
                      );
                      if (
                        localItemRejectionPayloadList.some(
                          (item) => item.reason !== batchRejectionReason
                        )
                      ) {
                        setBatchRejectionReason("");
                      }
                    }}
                    imageInfo={
                      batchItem?.imageData
                        ? batchItem?.imageData?.data?.[row?.asset_l0_ad_id ?? ""]
                        : undefined
                    }
                    imageLoading={
                      !batchItem?.imageData || batchItem?.imageData?.isLoading
                    }
                  />
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PxDialog>
    </Fragment>
  );
}

export default RejectionModal;

// commmon compo

const CRSelect = ({ ...props }: SelectProps) => {
  const selectOptions = [
    "This recommendation is irrelevant in this scenario",
    "Our brand requirements do not allow for us to accept this recommendation",
  ];
  return (
    <StyledCRSelect
      variant="standard"
      displayEmpty
      disableUnderline
      MenuProps={{
        PaperProps: {
          sx: {
            marginTop: ".5em",
            width: "15.875em",
            padding: ".25em 0em",
            borderRadius: "6px",
          },
        },
        MenuListProps: {
          sx: {
            padding: "0em",
          },
        },
      }}
      renderValue={(value: any) => {
        if (!value || (value && value.length === 0)) {
          return (
            <StyledText
              fontWeight={400}
              fontSize="0.75em"
              color="#585969"
              lineHeight="20px"
            >
              Select Reason
            </StyledText>
          );
        }
        return (
          <TextWithTooltip
            text={value}
            fontWeight={400}
            fontSize="0.75em"
            color="#585969"
            lineHeight="20px"
          />
        );
      }}
      {...props}
    >
      <MenuItem
        value={selectOptions[0]}
        sx={{
          whiteSpace: "normal",
        }}
      >
        <StyledText fontSize="0.75em" color="#333333" lineHeight="16px">
          {selectOptions[0]}
        </StyledText>
      </MenuItem>
      <StyledDivider m=".25em 5%" width="90%%" />
      <MenuItem
        value={selectOptions[1]}
        sx={{
          whiteSpace: "normal",
        }}
      >
        <StyledText fontSize="0.75em" color="#333333" lineHeight="16px">
          {selectOptions[1]}
        </StyledText>
      </MenuItem>
    </StyledCRSelect>
  );
};

/// item

const RejectTableItem = ({
  index,
  onChange,
  data,
  noRecommendationList,
  setNoRecommendationList,
  rejectionDetail,
  imageLoading,
  imageInfo,
  actionToDo,
  assetColor,
}: {
  index: number;
  assetColor: string;
  onChange: any;
  actionToDo: string;
  data?: {
    adName: string;
    adId: string;
    adsetName: string;
    campaign: string;
    campaignId: string;
    adsetId: string;
  };
  noRecommendationList: Array<any>;
  setNoRecommendationList: React.Dispatch<React.SetStateAction<any>>;
  rejectionDetail: PostExecutionItemPayload;
  imageInfo?: SetPreviewItem;
  imageLoading?: boolean;
}) => {
  return (
    <TableRow
      sx={{
        marginBottom: "0px",
        paddingBottom: "0px",
      }}
    >
      <TableCell
        // component="th"
        scope="row"
        padding="none"
        sx={{
          borderRight: "1px solid #e5e5e5",
          width: "17em",
          borderTop: index === 0 ? "1px solid #e5e5e5" : "none",
          paddingLeft: ".5em",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: ".5em 0em",
            width: "17em",
            overflow: "hidden",
          }}
        >
          {imageLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                height: "3em",
                width: "3em",
                borderRadius: "1.55981px",
              }}
            />
          ) : (
            <Box
              ml=".7em"
              component="img"
              src={
                getPreviewImage(imageInfo?.adcreatives?.data[0]) ??
                imagePlaceholder
              }
              sx={{
                height: "3em",
                width: "3em",
                objectFit: "cover",
                border: "0.133564px solid #E5E5E5",
                borderRadius: "1.55981px",
              }}
            />
          )}
          <CRTooltipWithAdDetails data={data} assetColor={assetColor} />
        </Box>
      </TableCell>
      <TableCell
        sx={{
          borderRight: "1px solid #e5e5e5",
          width: "1em",
          borderTop: index === 0 ? "1px solid #e5e5e5" : "none",
        }}
      >
        <StyledText fontSize="0.75em" lineHeight="15px" color="#33343D">
          {actionToDo}
        </StyledText>
      </TableCell>
      <TableCell
        sx={{
          borderRight: "1px solid #e5e5e5",
          width: "9.1em",
          borderTop: index === 0 ? "1px solid #e5e5e5" : "none",
        }}
        align="center"
      >
        <CRSelect value={rejectionDetail.reason} onChange={onChange} />
      </TableCell>
      <TableCell
        sx={{
          borderRight: "1px solid #e5e5e5",
          borderTop: index === 0 ? "1px solid #e5e5e5" : "none",
        }}
        align="center"
      >
        <CheckBox
          checked={noRecommendationList.includes(data?.adId)}
          onClick={() => {
            if (noRecommendationList.includes(data?.adId))
              setNoRecommendationList([
                ...noRecommendationList.filter((i) => i != data?.adId),
              ]);
            else setNoRecommendationList([...noRecommendationList, data?.adId]);
          }}
        />
      </TableCell>
    </TableRow>
  );
};
