import { Box, Input } from "@mui/material";
import { styled } from "@mui/system";

export const StyledTimeFrameBox = styled(Box)`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;
  height: 28px;
  display: flex;
`;

export const StyledInput = styled(Input)`
  background: transparent;
  width: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #333333;
`;

export const StyledTimeText = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #333333;
`;

export const StyledDivider = styled(Box)`
  border: 1px solid #dddddd;
`;
