import { Box, IconButton } from "@mui/material";
import React from "react";
import {
  StyledDivider,
  StyledInput,
  StyledTimeFrameBox,
  StyledTimeText,
} from "./styled";
import { ReactComponent as ArrowDown } from "../../../../../../assets/svg/down_arrow.svg";
import { ReactComponent as ArrowUP } from "../../../../../../assets/svg/up_arrow.svg";
import { Valuetype } from "./TimeFrameInputController";

export default function TimeFrameInput({
  onChange,
  value,
}: {
  onChange: (...event: any[]) => void;
  value: Valuetype;
}) {
  const integerInput = (event: any) => {
    if (event) {
      var char = String.fromCharCode(event.which);
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    }
  };

  const onMinuteChange = (e: any) => {
    const val = e.target.value ? parseInt(e.target.value) : 0;
    onChange({ ...value, minutes: val });
  };

  const onMinuteArrowChange = (addSubValue: number) => {
    onChange({ ...value, minutes: value.minutes + addSubValue });
  };
  const onSecondChange = (e: any) => {
    const val = e.target.value ? parseInt(e.target.value) : 0;
    onChange({ ...value, seconds: val });
  };

  const onSecondArrowChange = (addSubValue: number) => {
    onChange({ ...value, seconds: value.seconds + addSubValue });
  };

  return (
    <StyledTimeFrameBox ml="12px" alignItems="center" pl="4px" pr="8px">
      <ArrowHandler onArrowChange={onMinuteArrowChange} />
      <StyledInput
        disableUnderline
        onKeyPress={(event) => integerInput(event)}
        value={value.minutes}
        onChange={onMinuteChange}
        inputProps={{
          min: 0,
          max: 59,
        }}
      />
      <StyledTimeText>Min</StyledTimeText>
      <StyledDivider ml="8px" mr="6px" height="16px" />
      <ArrowHandler onArrowChange={onSecondArrowChange} />
      <StyledInput
        disableUnderline
        onKeyPress={(event) => integerInput(event)}
        value={value.seconds}
        onChange={onSecondChange}
        inputProps={{
          min: 0,
          max: 59,
        }}
      />
      <StyledTimeText>Sec</StyledTimeText>
    </StyledTimeFrameBox>
  );
}

const ArrowHandler = ({
  onArrowChange,
}: {
  onArrowChange: (addSubValue: number) => void;
}) => {
  return (
    <Box display="flex" flexDirection="column" pl="3px" pr="3px">
      <IconButton
        // disableRipple
        onClick={() => onArrowChange(1)}
        sx={{
          padding: "5px 2px 1.8px 2px",
        }}
      >
        <ArrowUP />
      </IconButton>
      <IconButton
        // disableRipple
        onClick={() => onArrowChange(-1)}
        sx={{
          padding: "1.8px 2px 5px 2px",
        }}
      >
        <ArrowDown />
      </IconButton>
    </Box>
  );
};
