import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVariant } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { setUTGData } from "store/actions/CreativeAI_Revamp/facebook.actions";
import { fetchPerformanceScore, fetchPerformanceScoreNew } from "store/actions/CreativeAI_Revamp/HomeScreen/Editor/editor.actions";
import { AdAccountState } from "store/types/adaccount";
import { initialState } from "store/types/auth";
import {
  Bundle,
  StatusList,
} from "store/types/CreativeAI/Facebook/BundleGeneration/bundleGeneration";
import { SET_AUTOGENERATE_RESPONSE } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { NavigationInitialState } from "store/types/Navigation/navigation.types";

export const useBGUTG = () => {
  const { utgData } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );

  const { variantWithId } = useSelector(
    (state: { selectCohortReducer: any; MainReducer: any }) => ({
      variantWithId: state.MainReducer.variantWithId,
    })
  );

  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { socket } = useSelector(
    (state: { navigationReducer: NavigationInitialState }) =>
      state.navigationReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  const dispatch = useDispatch(); 

  const addingVariant = (item: any, adId: string, res?: any) => {
    dispatch(
      addVariant({
        user: auth.user,
        params: {
          headline: { name: item.headline },
          description: { name: item.description },
          primaryText: { name: item.primary_text },
          adId: item?.adId,
          mediaFileId: item.mediaFileId,
          adsetId: item?.adsetId,
          elementId: item?.elementId,
          adAccountId: item?.adAccountId,
          performanceScore: item?.performanceScore,
          payload: {
            ...item.payload,
            video: 0,
            isNew: true,
            userPromptText: utgData[adId]?.input_text,
          },
          variantType: "UTG",
        },
        adAccountId: item?.adAccountId,
        finalAction: SET_AUTOGENERATE_RESPONSE,
        callback: (response: any, error: boolean) => {
          res && res(response.success);
        },
      })
    );
  };

  //utg flow
  const generateUtgPayload = (bundle: any, adId: string, num: number = 3) => {
    const promises: any[] = [];
    let isMedia = false;
    let plain: any = [];
    const performancePromises: any[] = [];
    const tempData = bundle?.bundles?.sort(
      (dataItemA: any, dataItemB: any) =>
        dataItemB?.metaData?.performance_score -
        dataItemA?.metaData?.performance_score
    );
    const usableBundles = tempData?.filter((bundle: Bundle) => bundle !== null);
    const arr = usableBundles.slice(0, num);
    const temp = {
      ...bundle,
      bundles: usableBundles.slice(num),
    };
    dispatch(
      setUTGData({
        path: adId,
        value: { bundleRecommendations: temp },
      })
    );
    if (usableBundles?.length == 0) {
      dispatch(
        setUTGData({
          path: [adId, "status"],
          value: { isError: true, inProgress: false },
        })
      );
      return;
    }
    arr.forEach((item: any, index: number) => {
      const recommendation = {
        headline: item?.headline
          ? {
              data: [
                item.headline,
                ...bundle.bundles
                  .filter(
                    (d: any) => d?.headline?.text !== item?.headline?.text
                  )
                  .map((e: any) => e.headline),
              ],
              selected: item.headline,
              noOfElementToShow:
                bundle.bundles.length + 1 > 4 ? 4 : bundle.bundles.length + 1,
              showMore: bundle.bundles.length + 1 > 4,
            }
          : {},
        description: item?.description
          ? {
              data: [
                item.description,
                ...bundle.bundles
                  .filter(
                    (d: any) => d?.description?.text !== item?.description?.text
                  )
                  .map((e: any) => e.description),
              ],
              selected: item.description,
              noOfElementToShow:
                bundle.bundles.length + 1 > 4 ? 4 : bundle.bundles.length + 1,
              showMore: bundle.bundles.length + 1 > 4,
            }
          : {},
        primary_text: item?.primary_text
          ? {
              data: [
                item.primary_text,
                ...bundle.bundles
                  .filter(
                    (d: any) =>
                      d?.primary_text?.text !== item?.primary_text?.text
                  )
                  .map((e: any) => e.primary_text),
              ],
              selected: item.primary_text,
              noOfElementToShow:
                bundle.bundles.length + 1 > 4 ? 4 : bundle.bundles.length + 1,
              showMore: bundle.bundles.length + 1 > 4,
            }
          : {},
      };
      const settings = {
        variantName:
          utgData[adId]?.settings?.adName +
          "_CAI_cv" +
          ((utgData[adId]?.inDraft ?? 0) + index),
        ...utgData[adId]?.settings,
      };

      performancePromises.push(
        new Promise((res) => {
          dispatch(
            fetchPerformanceScoreNew({
              body: {
                ad_id: adId,
                account_id: adaccount?.selectedAdAccount?.id,
                ad_type: utgData[adId]?.settings?.adType,
                bu_name: variantWithId.businessUnit,
                bu_detail: variantWithId.businessUnitDetail,
                industry: "",
                is_bundle: true,

                assets: [
                  {
                    asset_type: "primary_text",
                    asset_text: item.primary_text?.text
                  },
                  {
                    asset_type: "headline",
                    asset_text: item.headline?.text
                  },
                  {
                    asset_type: "description",
                    asset_text: item.description?.text
                  }
                ]
              },
              callback: ({ error, performanceScore }) => {
                if (!error) {
                  res({
                    primary_text: item.primary_text?.text,
                    headline: item.headline?.text,
                    description: item.description?.text,
                    adId,
                    adsetId: utgData?.[adId]?.adsetId,
                    elementId: utgData?.[adId]?.elementId,
                    adAccountId: utgData?.[adId]?.adAccountId,
                    performanceScore,
                    payload: {
                      previewExpiry: +new Date(),
                      recommendationMetaData: {
                        ...recommendation,
                      },
                      settings,
                    },
                  });
                } else {
                  res({
                    primary_text: item.primary_text?.text,
                    headline: item.headline?.text,
                    description: item.description?.text,
                    adId,
                    adsetId: utgData?.[adId]?.adsetId,
                    elementId: utgData?.[adId]?.elementId,
                    adAccountId: utgData?.[adId]?.adAccountId,
                    performanceScore: item?.metaData,
                    payload: {
                      previewExpiry: +new Date(),
                      recommendationMetaData: {
                        ...recommendation,
                      },
                      settings,
                    },
                  });
                }
              },
            })
          );
        })
      );
    });

    let variantPromise: any = [];
    Promise.all(performancePromises).then((variants) => {
      variants?.forEach((item) => {
        variantPromise.push(
          new Promise((res) => {
            addingVariant(item, adId, res);
          })
        );
      });
      Promise.all(variantPromise).then((data) => {
        dispatch(
          setUTGData({
            path: adId,
            value: {
              status: {
                ...utgData?.[adId]?.status,
                inProgress: false,
                progress: 0,
                step: 0,
              },
            },
          })
        );
      });
    });
  };

  useEffect(() => {
    if (socket) {
      socket.on("CaiFbUTG", function (data) {
        setTimeout(() => {
          let adId = Object.keys(utgData).find(
            (val: any) => utgData?.[val]?.requestId === data[0].request_id
          );
          if (data[0]?.status === "failed") {
            if (adId) {
              dispatch(
                setUTGData({
                  path: [adId, "status"],
                  value: { isError: true, inProgress: false },
                })
              );
            }
          } else if (
            data[0]?.result?.bundles?.filter(
              (bundle: Bundle) => bundle !== null
            ).length == 0
          ) {
            if (adId) {
              dispatch(
                setUTGData({
                  path: [adId, "status"],
                  value: { isError: true, inProgress: false },
                })
              );
            }
          } else {
            if (adId) {
              generateUtgPayload(data[0].result, adId);
              if (utgData[adId]?.generateBulkVideos?.length) {
                // generateAutogeneratePayload(
                //   data[0].result,
                //   2,
                //   autogenerate[adId]?.count,
                //   adId
                // );
                // generateBillBoardPayload(adId, 2);
              } else {
                // generateAutogeneratePayload(
                //   data[0].result,
                //   3,
                //   autogenerate[adId]?.count,
                //   adId
                // );
              }
              dispatch(
                setUTGData({
                  path: adId,
                  value: { requestId: "" },
                })
              );
            }
          }
        }, 0);
      });
    } else {
      //   here socket fallback case will come
    }
    return () => {
      if (socket) {
        socket.off("CaiFbUTG");
      }
    };
  }, [socket, variantWithId, adaccount]);
};
