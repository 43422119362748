import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
    const {title,children}=props;
    if(!title) return children;
    return (
        <Tooltip {...props} classes={{ popper: className }} />
  )})(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',//can be made into a prop
      color: '#242424',
      boxShadow: theme.shadows[1],
      fontSize:12,
      padding:'6px 12px'
    },
    [`& .${tooltipClasses.arrow}`]: {
        '&::before': {
            backgroundColor: '#FFFFFF',
            boxShadow: theme.shadows[1],
        }
      },
  }));