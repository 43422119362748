import { styled } from "@mui/material";
import Select, { SelectProps } from "@mui/material/Select";

export interface StyledPxSelectProps extends SelectProps {
  dropDownContainerWidth?: string;
  dropDownContainerHeight?: string;
}

export const StyledPxSelect = styled(Select)<StyledPxSelectProps>`
  border: 1px solid #e5e5e5;
  background: #ffffff;
  border-radius: 6px;
  height: ${({ dropDownContainerHeight }) => dropDownContainerHeight ?? "2em"};
  color: #000000;
  align-items: center;
  /* background: green; */
  width: ${({ dropDownContainerWidth }) =>
    dropDownContainerWidth ?? "9.3125em"};
  .MuiSelect-iconOpen {
    color: #000000;
  }
  .MuiSelect-icon {
    color: #000000;
  }
  .MuiSelect-standard {
    /* background: green; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1112vw;
    @media screen and (max-width: 1280px) {
      font-size: 11px;
    }
  }
  .MuiSelect-select {
    /* background: red; */
  }
`;
