import { Box, CircularProgress } from "@mui/material";
import { Button, StyledErrorMessage } from "components/shared";
import { LightTooltip } from "components/shared/LightTooltip";
import { useState } from "react";
import { useSelector } from "react-redux";
import { MediaStudioAdminState } from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
interface MediaStudioFormButtonsProps {
  onSubmit: () => void;
  onRunTest: () => void;
  errors: any;
  isEdit: boolean;
  addingMediaStudio: boolean;
}
function MediaStudioFormButtons({
  onSubmit,
  onRunTest,
  isEdit,
  addingMediaStudio,
  errors,
}: MediaStudioFormButtonsProps) {
  const { isMediaUploading } = useSelector(
    (state: { mediaStudioAdminReducer: MediaStudioAdminState }) =>
      state.mediaStudioAdminReducer
  );
  return (
    <Box display="flex" justifyContent="space-between" minWidth={"100%"}>
      {Object.keys(errors).length > 0 ? (
        <StyledErrorMessage>
          *Please resolve above errors before saving
        </StyledErrorMessage>
      ) : (
        <StyledErrorMessage></StyledErrorMessage>
      )}
      <Box display="flex" justifyContent="space-between">
        <LightTooltip
          sx={{ width: "230px" }}
          arrow
          title="Test your Media Studio entry here. Ensure that all mandatory fields are entered for testing."
        >
          <div>
            <Button
              variant="outlined"
              onClick={onRunTest}
              sx={{
                borderRadius: "12px",
                padding: "12px 18px",
                width: "130px",
                fontSize: "16px",
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#0869FB",
                border: "2px solid #0869FB",
                mr: "15px",
                ":hover": { border: "2px solid #0869FB" },
              }}
              disabled={
                Object.keys(errors).length > 0 ||
                isMediaUploading ||
                addingMediaStudio
              }
            >
              {addingMediaStudio ? (
                <Box width="90px" height="25px">
                  <CircularProgress
                    size={25}
                    sx={{
                      color: "white",
                    }}
                    thickness={5}
                  />
                </Box>
              ) : (
                "Run a Test"
              )}
            </Button>
          </div>
        </LightTooltip>
        <Button
          variant="contained"
          onClick={onSubmit}
          sx={{
            background: "#0869FB",
            borderRadius: "12px",
            padding: "12px 18px",
            width: "90px",
            fontSize: "16px",
            fontFamily: "Inter",
            fontWeight: 600,
          }}
          disabled={
            Object.keys(errors).length > 0 ||
            isMediaUploading ||
            addingMediaStudio
          }
        >
          {addingMediaStudio ? (
            <Box width="90px" height="25px">
              <CircularProgress
                size={25}
                sx={{
                  color: "white",
                }}
                thickness={5}
              />
            </Box>
          ) : isEdit ? (
            "Update"
          ) : (
            "Save"
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default MediaStudioFormButtons;
