import React, { useState } from "react";
import Toggle from "react-toggle";
import { Box, Button, Stack, Typography } from "@mui/material";
import { setGSCalibrationData } from "store/actions/gsTargetingAI";
import { useDispatch, useSelector } from "react-redux";
import HeadingClibrationsGS from "./HeadingClibrationsGS";

function CustomKeywordGS() {
  const dispatch = useDispatch();
  const [input, setInput] = useState("");

  const {
    ExploreValueCheck,
    CalibrationCustomKeywordsCheck,
    CalibrationCustomKeywords,
  } = useSelector((state: any) => state.gsTargetingAI.gsCalibration.data);
  return (
    <Box
      className="node-container-common-style"
      sx={{
        opacity: `${!ExploreValueCheck && "0.5"}`,
        position: "absolute",
        top: "64%",
        right: "3%",
        width: "21rem",
        height: "12.2rem",
        px: 2,
      }}
    >
      <Box sx={{ pt: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <HeadingClibrationsGS
              heading="Custom Keywords"
              subHeading="You can enter your keywords below"
            />
          </Box>
          <Toggle
            disabled={!ExploreValueCheck}
            checked={CalibrationCustomKeywordsCheck}
            className="right-0 custom-react-toggle"
            icons={false}
            id="biscuit-status"
            aria-labelledby="biscuit-label"
            onChange={(e) => {
              dispatch(
                setGSCalibrationData({
                  CalibrationCustomKeywordsCheck:
                    !CalibrationCustomKeywordsCheck,
                  CalibrationCustomKeywords: CalibrationCustomKeywordsCheck
                    ? []
                    : CalibrationCustomKeywords,
                })
              );
            }}
          />
        </Stack>
      </Box>
      <hr style={{ margin: "0.5rem 0" }} />
      <Box>
        <input
          className="p-2 mt-2 text-sm"
          style={{
            border: "0.06rem solid #EFEFEF",
            width: "100%",
            borderRadius: "0.313rem",
          }}
          id="text"
          name="text"
          placeholder="Enter keywords"
          onChange={(e) => setInput(e.target.value)}
          disabled={!ExploreValueCheck || !CalibrationCustomKeywordsCheck}
          value={input}
        />
        <Box
          className="flex flex-wrap px-0 py-2 overflow-auto justify-content-start"
          style={{ maxHeight: "3rem" }}
        >
          {CalibrationCustomKeywords?.map((element: any, index: number) => {
            return (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key={index}
                className="overflow-scroll"
                sx={{
                  height: "1.25rem",
                  borderRadius: "0.25rem",
                  padding: 0.5,
                  backgroundColor: "#ECF3FF",
                  margin: "0.25rem",
                }}
              >
                <Typography sx={{ color: "#0869FB", fontSize: "0.625rem" }}>
                  {element}
                </Typography>
                <button
                  className="m-0 chip-remove-custom-style text-primary"
                  onClick={() => {
                    dispatch(
                      setGSCalibrationData({
                        CalibrationCustomKeywords:
                          CalibrationCustomKeywords.filter(
                            (el: any, i: number) => index !== i
                          ),
                      })
                    );
                  }}
                >
                  X
                </button>
              </Stack>
            );
          })}
        </Box>
        <Box className="mt-1 d-flex justify-content-end">
          <Button
            variant="outlined"
            sx={{
              padding: 0,
              width: "4.5rem",
              height: "2rem",
              textTransform: "none",
              borderRadius: "0.5rem",
              border: "1px solid #0869FB",
              color: "#0869FB",
              "&:hover": {
                backgroundColor: "transparent",
                border: "1px solid #0869FB",
              },
            }}
            disabled={!CalibrationCustomKeywordsCheck}
            onClick={() => {
              if (input.length > 0) {
                dispatch(
                  setGSCalibrationData({
                    CalibrationCustomKeywords: CalibrationCustomKeywords.concat(
                      input as any
                    ),
                  })
                );
                setInput("");
              }
            }}
          >
            + Add
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default CustomKeywordGS;
