import Button from "@mui/material/Button";
import Stack from "@mui/system/Stack";
import Typography from "@mui/material/Typography";
import ResetPasswordSuccess from "../../assets/png/ResetPasswordSuccess.png";
import { useLocation } from "react-router-dom";

import { useHistory } from "react-router-dom";
function PasswordSuccessful() {
  const history = useHistory();
  const search = useLocation().search;
  const isInvite = new URLSearchParams(search).get('invite') === 'true';
  console.log("isInvite",isInvite)
  return (
    <Stack
      className="font-name-inter"
      sx={{
        background: "url(../static/onboarding/images/LoginSuccessfulBg.svg)",
        backgroundSize: "cover",
        backgroundPositionY: "-120px",
        backgroundColor: "white",
        height: "100vh",
        width: "100vw",
        "& *": {
          fontFamily: "Inter",
        },
      }}
      alignItems="center"
      pt={4}
    >
      <img src={ResetPasswordSuccess} alt="success" />

      <Typography
        sx={{ fontSize: "40px", fontWeight: 700, color: "#535353", mt: 4 }}
      >
        {isInvite ? "Password setup complete" : "Password Updated!"}
      </Typography>
      
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          color: "#6B6B6B",
          mt: 2,
        }}
        align="center"
      >
        {isInvite ? 
        <>You’ve successfully setup the password, <br />please go to the login page</> :
        <>You have successfully updated your password.<br />Click below to go back to login screen.</>
        }
      </Typography>
      <Button
        className="text-white border-0 rounded btn-primary "
        variant="contained"
        sx={{
          background: "#0869FB",
          textTransform: "none",
          mt: 2,
          fontSize: "14px",
          fontWeight: 600,
          width: "auto",
          px: 4,
          py: 2,
        }}
        onClick={() => history.push("/auth/login")}
        disableRipple
      >
        Go back to Login
      </Button>
    </Stack>
  );
}

export default PasswordSuccessful;
