// batch related types

export const Get_BATCH_DATA = "Get_BATCH_DATA";
export const Set_BATCH_DATA = "Set_BATCH_DATA";

export interface GlobalAPIInterface {
  isLoading?: boolean;
  isFailed?: boolean;
}

export interface GetBatch {
  type: typeof Get_BATCH_DATA;
  payload: GetBatchPayload;
}

export interface SetBatch {
  type: typeof Set_BATCH_DATA;
  payload: BatchListPayloadType;
}

export interface GetBatchPayload {
  elementId: string;
  pageNumber: number;
  adIds: number[]
}

export interface BatchItemType {
  batch_id: string;
  pipeline_id: string;
  asset_type: string;
  asset_l1_id: string;
  asset_l1_name: string;
  metric_type: 'cpr'|'roas';
  ad_ids: BatchItemAdDetailType[];
  asset_l0_details: {asset_l0_ad_id:string;ad_account_id:string}[]
  ad_count: number;
  result_metrix: string;
  updated_at: string;
  created_at: string;
  ad_action_counts: {
    on: number;
    off: number;
    total: number
  };
  status_counts: {
    Executed?: number;
    Rejected?: number;
    Failed?: number;
    Recommended?: number;
    Processing?: number;
  };
  detailsList: GetBatchDetailsItemPayloadType;
  imageData?: SetPreviewPayload;
  imgArray?: string[];
  batch_metrics: {
    ctr_pct_chg: string,
    ctr_3day_avg: string,
    ctr_7day_avg: string,
    frequency_pct_chg: string,
    frequency_3day_avg: string,
    frequency_7day_avg: string,
    spend_pct_chg: string,
    spend_3day_avg: string,
    spend_7day_avg: string,
    cpr_pct_chg: string,
    cpr_3day_avg: string,
    cpr_7day_avg: string,
    roas_pct_chg: string,
    roas_3day_avg: string,
    roas_7day_avg: string
  }
}

export type StatusType =
  | "Executed"
  | "Rejected"
  | "Failed"
  | "Recommended"
  | "Processing";

export interface BatchItemAdDetailType {
  ad_id: string;
  ad_account_id: string;
}

export interface BatchItemActionType {
  cpr_abs: string;
  cpr_rel: string;
  roas_abs: string;
  roas_rel: string;
  spend_abs: string;
  spend_rel: string;
  counts: number;
}

// Batch Details api

export const Get_BATCH_DATA_DETAILS = "Get_BATCH_DATA_DETAILS";
export const Set_BATCH_DATA_DETAILS = "Set_BATCH_DATA_DETAILS";
export const CANCEL_BATCH_DATA_DETAILS = "CANCEL_BATCH_DATA_DETAILS";

export interface SetBatchDetails {
  type: typeof Set_BATCH_DATA_DETAILS;
  payload: SetBatchDetailsPayload;
}

export interface GetBatchDetails {
  type: typeof Get_BATCH_DATA_DETAILS;
  payload: GetBatchDetailsPayload;
}

export interface GetBatchDetailsPayload {
  elementId: string;
  batchId: string;
  asset_l1_id: string;
}

export interface SetBatchDetailsPayload {
  batchId: string;
  details: GetBatchDetailsItemPayloadType;
  asset_l1_id : string;
}

export interface GetBatchDetailsItemType {
  recommendation_id: string;
  asset_l0_ad_id: string;
  asset_l0_ad_name: string;
  concept_name: string;
  action_to_do: string;
  ad_account_id: string;
  metric_type: 'cpr'|'roas';
  parent: {
    asset_l1_id: string;
    asset_l1_name: string;
    asset_l2_id: string;
    asset_l2_name: string;
  };
  metrics: {
    ctr_pct_chg: string,
    ctr_3day_avg: string,
    ctr_7day_avg: string,
    frequency_pct_chg: string,
    frequency_3day_avg: string,
    frequency_7day_avg: string,
    spend_pct_chg: string,
    spend_3day_avg: string,
    spend_7day_avg: string,
    cpr_pct_chg: string,
    cpr_3day_avg: string,
    cpr_7day_avg: string,
    roas_pct_chg: string,
    roas_3day_avg: string,
    roas_7day_avg: string
  };
  updated_at: string;
  action_basis: string;
  status: string;
  message?: string;
  phase?: string;
}

export interface GetBatchDetailsItemPayloadType {
  data: GetBatchDetailsItemType[];
  isLoading?: boolean;
  isFailed?: boolean;
}

// initstate

export interface CreativeRotationInitialState {
  batchList: BatchListPayloadType;
  advanceSettings: SetAdvanceSettingPayload;
  isApproveExecution: GlobalAPIInterface;
  isRejectExecution: GlobalAPIInterface;
  auto_execution: boolean;
  noRecommendationList:null|[],
  nonRecommendedAds: string[],
  adToBatchMapping: any
}

export interface BatchListPayloadType {
  details?: {data:[]} 
  data: BatchItemType[];
  isLoading?: boolean;
  isFailed?: boolean;
  haveNextPage?: boolean;
  totalPage?: number;
  auto_execution?:boolean;
  nonRecommendedAds?: string[];
}

//get advance setting

export const GET_ADVANCE_SETTINGS = "GET_ADVANCE_SETTINGS";
export const SET_ADVANCE_SETTINGS = "SET_ADVANCE_SETTINGS";
export const POST_ADVANCE_SETTINGS = "POST_ADVANCE_SETTINGS";

export interface GetAdvanceSetting {
  type: typeof GET_ADVANCE_SETTINGS;
  payload: string;
}
export interface SetAdvanceSetting {
  type: typeof SET_ADVANCE_SETTINGS;
  payload: SetAdvanceSettingPayload;
}

export interface PostAdvanceSetting {
  type: typeof POST_ADVANCE_SETTINGS;
  payload: PostAdvanceSettingPayload;
}

export interface PostAdvanceSettingPayload {
  elementId: string;
  body: GetAdvanceSettingData;
}
export interface SetAdvanceSettingPayload extends GlobalAPIInterface {
  data?: GetAdvanceSettingData;
  isUpdating?: boolean;
}
export interface GetAdvanceSettingData {
  adType: string;
  lookbackPeriod: number;
  maxTestAds: number;
  maxOptimisedAds: number;
}

// post execution

export const POST_EXECUTION = "POST_EXECUTION";
export const POST_EXECUTION_LOADER = "POST_EXECUTION_LOADER";

export interface PostExecution {
  type: typeof POST_EXECUTION;
  payload: PostExecutionPayload;
}
export interface PostExecutionLoader {
  type: typeof POST_EXECUTION_LOADER;
  payload: PostExecutionLoaderPayload;
}

export interface PostExecutionLoaderPayload {
  batchId?: string;
  userAction: userActionType;
  isLoading?: boolean;
  isFailed?: boolean;
}

export interface PostExecutionPayload {
  elementId: string;
  body: PostExecutionItemPayload[];
  userAction: userActionType;
  batchId?: string;
}

export type userActionType = "APPROVED" | "CLIENT_REJECTED";
export interface PostExecutionItemPayload {
  recommendationId: string;
  userAction: userActionType;
  reason?: string;
}

export const RESET_CR_REDUCER = "RESET_CR_REDUCER";

export interface CrReducerReset {
  type: typeof RESET_CR_REDUCER;
  payload: any;
}

export const GET_PREVIEW_DATA = "GET_PREVIEW_DATA";
export const SET_PREVIEW_DATA = "SET_PREVIEW_DATA";
export const CANCEL_PREVIEW_DATA = "CANCEL_PREVIEW_DATA";

export interface GetPreviewData {
  type: typeof GET_PREVIEW_DATA;
  payload: GetPreviewDataPayload;
}

export interface SetPreviewData {
  type: typeof SET_PREVIEW_DATA;
  payload: SetPreviewPayload;
}

export interface GetPreviewDataPayload {
  elementId: string;
  data: BatchItemAdDetailType[];
  batchId: string;
  asset_l1_id:string;
}

export interface SetPreviewPayload extends GlobalAPIInterface {
  data?: SetPreviewItemPayload;
  batchId?: string;
  asset_l1_id:string;
}

export interface SetPreviewItemPayload {
  [key: string]: SetPreviewItem;
}

export interface SetPreviewItem {
  adcreatives: {
    data: any;
  };
  adPreview: [
    {
      body: string;
    }
  ];
}

// auto execution types

export const SET_AUTO_EXECUTION = 'SET_AUTO_EXECUTION'
export const GET_NO_RECOMMENDATION_LIST = "GET_NO_RECOMMENDATION_LIST"
export const SET_NO_RECOMMENDATION_LIST = "SET_NO_RECOMMENDATION_LIST"
export const SET_NON_RECOMMENDED_ADS = "SET_NON_RECOMMENDED_ADS"
export interface SetAutoExecution {
  type: typeof SET_AUTO_EXECUTION;
  payload: {value: boolean, elementId:string};
}
export interface GetNoRecommendationList {
  type: typeof GET_NO_RECOMMENDATION_LIST;
  payload: { elementId:string};
}
export interface SetNoRecommendationList {
  type: typeof SET_NO_RECOMMENDATION_LIST;
  payload: any;
}

export interface SetNonRecommendedAds {
  type: typeof SET_NON_RECOMMENDED_ADS;
  payload: any;
}