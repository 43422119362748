import React, { useEffect, useState } from "react";
import ModalContainer from "components/Modal/ModalContainer";
import ModalWrapper from "components/Modal/ModalWrapper";
import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import { ReactComponent as Circle } from "../assets/Circle.svg";
import StatusChip from "../StatusChip";
import { useDispatch, useSelector } from "react-redux";
import { getGoogleSheetsSyncHistory } from "store/actions/thirdPartyChannels";
import LoaderGif from "../../../../ThirdPartyAttribution/Assets/loader.gif";
import NoData from "../NoData";

function SyncHistoryModal({
  title,
  subtitle,
  closeModal,
  sheetData,
}: {
  title: string;
  subtitle: string;
  closeModal: Function;
  sheetData: any;
}) {
  const adaccountState = useSelector((state: any) => state.adaccount);
  const brandId = adaccountState.selectedBrand.id;

  const dispatch = useDispatch();
  const [syncHistoryData, setSyncHistoryData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hideLoadMore, setHideLoadMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getGoogleSheetsSyncHistory(
        {
          brandId,
          sheetId: sheetData.id,
          page,
          pageSize: 5,
          sheetName: sheetData.name,
        },
        (response: any) => {
          setIsLoading(false);
          if (response?.success && response?.data?.syncHistory?.length > 0) {
            setSyncHistoryData(response.data.syncHistory);
            if (response?.data?.syncHistory?.length <= 5) {
              setHideLoadMore(true);
            } else {
              setHideLoadMore(false);
            }
            if (page > 1) {
              if (response.data.syncHistory.length > 0) {
                setSyncHistoryData([
                  ...syncHistoryData,
                  ...response.data.syncHistory,
                ]);
              }
            }
          } else {
            setHideLoadMore(true);
          }
        }
      )
    );
  }, [page]);

  return (
    <ModalWrapper>
      <ModalContainer
        className="third-party-modal"
        width="520px"
        title={title}
        subtitle={subtitle}
        handleClose={() => {
          closeModal();
        }}
      >
        <Stack>
          <Stack sx={{ maxHeight: "250px", overflow: "auto" }}>
            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={LoaderGif}
                  alt="loading"
                  style={{ height: "32px", width: "32px" }}
                />
              </Box>
            ) : !syncHistoryData.length ? (
              <NoData type="sync" />
            ) : (
              syncHistoryData.map((item: any, index) => (
                <Stack
                  key={index}
                  direction="row"
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: "-2px",
                  }}
                >
                  <Stack direction="row" sx={{ alignItems: "baseline" }}>
                    <Stack sx={{ marginRight: "7px", alignItems: "center" }}>
                      <Circle />
                      {syncHistoryData.length !== index + 1 && (
                        <Box
                          style={{
                            height: "43px",
                            width: "1px",
                            backgroundColor: "#2BB3F4",
                          }}
                        ></Box>
                      )}
                    </Stack>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: "500",
                          fontFamily: "Inter",
                        }}
                      >
                        {item.dateTime
                          ? moment(item.dateTime).format("DD, MMM YYYY HH:mm A")
                          : "-"}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack sx={{ textAlign: "end" }}>
                    {<StatusChip type={item.status} />}
                  </Stack>
                </Stack>
              ))
            )}
          </Stack>
          {!hideLoadMore && (
            <Stack
              onClick={() => {
                setPage(page + 1);
              }}
            >
              <Typography
                sx={{ cursor: "pointer" }}
                marginLeft="1rem"
                fontSize="12px"
                fontWeight="600"
                fontFamily="Inter"
                color="#0869FB"
                marginTop="13px"
                marginBottom="2px"
              >
                Show Older
              </Typography>
            </Stack>
          )}
        </Stack>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default SyncHistoryModal;
