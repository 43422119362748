import { SET_ACCOUNT_DETAILS } from "store/types/auth";
import { RESET_ADDITIONAL_INPUT_DATA } from "store/types/gsTargetingAI";
import {
  SetCurrentPage,
  SET_CURRENT_PAGE,
  SetPolling,
  SET_POLLING,
  SET_CAMPAIGN_AUDIENCES,
  SET_CAMPAIGN_AUDIENCE_DETAILS,
  SetCampaignAudience,
  SetCampaignAudienceDetails,
  SetNodeCollapse,
  SET_NODE_COLLAPSE,
  SET_CAMPAIGN_DETAILS_TAI,
  SetCampaignDetailsTAI,
  SaveCampaignAudienceDetail,
  SAVE_CAMPAIGN_AUDIENCE_DETAILS,
  SaveAccountDetails,
  SET_ADDITIONAL_INPUTS,
  SetAdditionalInputs,
  RESET_NODES_LOADER_STATE,
  SET_NEGATE_TARGETING,
  SET_ENABLE_CUSTOM_KEYWORDS,
} from "../../types/targetingAI";

export default function taiCommon(
  state = {
    currentPage: 1,
    polling: { name: "", requestId: "" },
    loader: {},
    campaigns: [],
    campaignAudienceDetail: { selectedAudience: null, selectedGroup: null, selectedControlGroup: null },
    campaignAudience: false,
    additionalInputs: {
      locationFilter: false,
      specialAdCategories: null,
      negateTargeting: false,
      enableCustomKeywords: false,
    },
  },
  action:
    | SetCurrentPage
    | SetPolling
    | SetNodeCollapse
    | SetCampaignDetailsTAI
    | SetCampaignAudience
    | SetCampaignAudienceDetails
    | SaveCampaignAudienceDetail
    | SaveAccountDetails
    | SetAdditionalInputs
) {
  switch (action.type) {
    case SET_CURRENT_PAGE: {
      const newState = {
        ...state,
        currentPage: action.payload,
      };
      return newState;
    }
    case SET_POLLING: {
      const newState = {
        ...state,
        polling: action.payload,
      };
      return newState;
    }
    case SET_CAMPAIGN_AUDIENCES: {
      const newState = {
        ...state,
        campaignAudience: action.payload,
      };
      return newState;
    }
    case SET_NODE_COLLAPSE: {
      const newState = {
        ...state,
        loader: {
          ...state.loader,
          [action.payload.type]: action.payload.collapse,
        },
      };
      return newState;
    }
    case SET_CAMPAIGN_DETAILS_TAI: {
      return {
        ...state,
        campaigns: action.payload,
      };
    }
    case SAVE_CAMPAIGN_AUDIENCE_DETAILS: {
      return {
        ...state,
        campaignAudienceDetail: action.payload,
      };
    }
    case SET_ACCOUNT_DETAILS: {
      return {
        ...state,
        Account_Details: action.payload,
      };
    }
    case SET_ADDITIONAL_INPUTS: {
      return {
        ...state,
        additionalInputs: {
          ...state.additionalInputs,
          ...action.payload,
        },
      };
    }
    case SET_NEGATE_TARGETING: {
      return {
        ...state,
        additionalInputs: {
          ...state.additionalInputs,
          ...action.payload,
        },
      };
    }
    case SET_ENABLE_CUSTOM_KEYWORDS: {
      return {
        ...state,
        additionalInputs: {
          ...state.additionalInputs,
          ...action.payload,
        },
      };
    }
    case RESET_ADDITIONAL_INPUT_DATA:{
      return{
        ...state,
        additionalInputs: {
          locationFilter: false,
          specialAdCategories: null,
          negateTargeting: false,
          enableCustomKeywords: false
        },
      }
    }
    case RESET_NODES_LOADER_STATE: {
      return {
        ...state,
        loader: {},
      };
    }
    default:
      return state;
  }
}
