import { crossPlatform } from "MappedAPI/MappedAPI";
import { call, takeLatest } from "redux-saga/effects";
import HttpService from "../../HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "../../utils/path";
import {
  STORE_GS_TAI_GENERATE_KEYWORDS,
  GET_GS_TAI_GENERATED_KEYWORDS,
  StoreGSTaiGenerateKeywords,
  GetGSTaiGeneratedKeywords,
} from "../types/gsTargetingAI";

function* storeGSTaiGenerateKeywords(action: StoreGSTaiGenerateKeywords): any {
  let { payload, callback } = action;
  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/google-ads/generate-keyword`;

    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      payload.user,
      payload.payload
    );
    if (response.data.success) {
      callback && callback(response.data.data, false);
    } else {
      callback && callback(response.data, true);
    }
  } catch (error: any) {
    console.log("function*storeGSTaiGenerateKeywords ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getGSTaiGeneratedKeywords(action: GetGSTaiGeneratedKeywords): any {
  let { payload: request_ids, callback } = action;
  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/google-ads/generate-keyword/status/${request_ids}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (response.data.success) {
      callback && callback(response.data.data, false);
    } else {
      callback && callback(response.data.message, true);
    }
  } catch (error: any) {
    console.log("function*getGSTaiGeneratedKeywords ~ error", error);
    callback && callback(error.response, true);
  }
}

export function* GsTargetingAiWatcher() {
  yield takeLatest(STORE_GS_TAI_GENERATE_KEYWORDS, storeGSTaiGenerateKeywords);
  yield takeLatest(GET_GS_TAI_GENERATED_KEYWORDS, getGSTaiGeneratedKeywords);
}
