import {
  StyledFooter,
  StyledLink,
  StyledMediaCard,
  StyledMediaCardContent,
  StyledMediaStudioHeader,
  StyledMediaStudioImage,
  StyledMediaStudioSubTitle,
} from "./styled";
import WelcomeMediaStudioImage from "../../../../../../../src/assets/png/request-mediaStudio.png";

import { Button } from "../../../../../shared";
import { useDispatch, useSelector } from "react-redux";
import { requestMediaStudio } from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { checkAdType } from "components/CreativeAI/Facebook/utils/common.utils";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";

interface WelcomeMediaStudioProps {
  selectedAd: any;
  selectedBrand: any;
}

const WelcomeMediaStudio = ({
  selectedAd,
  selectedBrand,
}: WelcomeMediaStudioProps) => {
  const { createSpec } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const dispatch = useDispatch();
  const { aiGroupList } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );

  const { adAccountsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const requestMediaStudioForAd = () => {
    dispatch(
      requestMediaStudio({
        adId: selectedAd?.id,
        brandId: selectedBrand?.id,
        payload: {
          brandName: selectedBrand.name,
          aiGroupId: aiGroupList.selectedAIGroup?.id ?? "",
          aiGroupName: aiGroupList.selectedAIGroup?.name ?? "",
          adAccountId: adAccountsSelection?.selectedAdAccountId ?? "",
          adAccountName: adAccountsSelection?.selection?.name ?? "",
          creativeType:
            checkAdType(createSpec) == "link_data" ? "image" : "video",
        },
      })
    );
  };
  return (
    <StyledMediaCard>
      <StyledMediaCardContent>
        <StyledMediaStudioImage src={WelcomeMediaStudioImage} />
        <StyledMediaStudioHeader>
          Welcome to Media Studio
        </StyledMediaStudioHeader>
        <StyledMediaStudioSubTitle>
          Customize texts within images, hone creatives with our system
          recommendations, review and publish. To enable this feature for this
          ad, request Media Studio today !
        </StyledMediaStudioSubTitle>
        <StyledFooter>
          <Button
            buttonVariant="contained"
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "Inter",
            }}
            onClick={requestMediaStudioForAd}
          >
            Request Media Studio
          </Button>
        </StyledFooter>
      </StyledMediaCardContent>
    </StyledMediaCard>
  );
};

export default WelcomeMediaStudio;
