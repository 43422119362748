import {
  convertVideoFrameData,
  getImageData,
  layerRecommendationMapping,
} from "components/CreativeAI/Facebook/CreateVariants/Editor/MediaStudio/shared/mediaCommon.utils";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVariant } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { postMediaStudioProcessing } from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import {
  setAutogenerateAdId,
  setAutogenerateData,
} from "store/actions/CreativeAI_Revamp/facebook.actions";
import { fetchPerformanceScoreNew } from "store/actions/CreativeAI_Revamp/HomeScreen/Editor/editor.actions";
import { AdAccountState } from "store/types/adaccount";
import { initialState } from "store/types/auth";
import {
  Bundle,
  StatusList,
} from "store/types/CreativeAI/Facebook/BundleGeneration/bundleGeneration";
import { SET_AUTOGENERATE_RESPONSE } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { NavigationInitialState } from "store/types/Navigation/navigation.types";

export const useBGAutoGenerate = () => {
  const { autogenerate, triggerAutogenerateAdId } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );

  const { variantWithId } = useSelector(
    (state: { selectCohortReducer: any; MainReducer: any }) => ({
      variantWithId: state.MainReducer.variantWithId,
    })
  );
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { socket } = useSelector(
    (state: { navigationReducer: NavigationInitialState }) =>
      state.navigationReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  const dispatch = useDispatch();

  const addingVariant = (item: any, res?: any) => {
    dispatch(
      addVariant({
        user: auth.user,
        params: {
          headline: { name: item.headline },
          description: { name: item.description },
          primaryText: { name: item.primary_text },
          adId: item?.adId,
          mediaFileId: item.mediaFileId,
          adsetId: item?.adsetId,
          elementId: item?.elementId,
          adAccountId: item?.adAccountId,
          performanceScore: item?.performanceScore,
          payload: { ...item.payload, video: 0, isNew: true },
        },
        adAccountId: item?.adAccountId,
        finalAction: SET_AUTOGENERATE_RESPONSE,
        callback: (response: any, error: boolean) => {
          res && res(response.success);
          if (error) {
            //error handling
          } else {
            console.log(response);
          }
        },
      })
    );
  };
  // billboard flow to come
  const adVariantsBillBoard = (
    adId: string,
    index: number,
    data: { id: string; url: string; type?: string; metaData: any },
    res?: any
  ) => {
    dispatch(
      addVariant({
        user: "",
        params: {
          headline: { name: autogenerate?.[adId]?.headline },
          description: {
            name: autogenerate?.[adId]?.description,
          },
          primaryText: {
            name: autogenerate?.[adId]?.primaryText,
          },
          adId,
          adsetId: autogenerate?.[adId]?.adsetId,
          elementId: autogenerate?.[adId]?.elementId,
          adAccountId: autogenerate?.[adId]?.adAccountId,
          variantType: data?.metaData?.type?.toLowerCase() ?? "billboard",
          billboardMediaId: data?.id,
          performanceScore: autogenerate?.[adId]?.defaultScore ?? null,
          payload: {
            isNew: true,
            settings: {
              variantName:
                autogenerate[adId]?.settings?.adName +
                "_CAI_cv" +
                ((autogenerate[adId]?.inDraft ?? 0) + index),
              ...autogenerate[adId]?.settings,
            },
          },
        },
        finalAction: SET_AUTOGENERATE_RESPONSE,
        adAccountId: autogenerate?.[adId]?.adAccountId,
        callback: (response: any, error: boolean) => {
          res && res(response.success);
          if (error) {
            //error handling
          } else {
            console.log(response);
          }
        },
      })
    );
  };
  const generateBillBoardPayload = (adId: string, num: number = 2) => {
    const promise: any = [];
    if (autogenerate[adId]?.generateBulkVideos) {
      const arr = autogenerate[adId]?.generateBulkVideos.slice(0, num);
      //   setAutoRecommendationCount((prev) => prev + (num ?? 2));
      dispatch(
        setAutogenerateData({
          path: adId,
          value: {
            generateBulkVideos: [
              ...autogenerate[adId]?.generateBulkVideos.slice(num),
            ],
          },
        })
      );
      arr.forEach((data: any, index: number) => {
        promise.push(
          new Promise(() => {
            adVariantsBillBoard(adId, index, data);
          })
        );
      });
    }
    // execute all promises
    Promise.all(promise).then((data) => {
      dispatch(
        setAutogenerateData({
          path: adId,
          value: {
            status: {
              ...autogenerate?.[adId]?.status,
              inProgress: false,
              progress: 0,
              step: 0,
            },
          },
        })
      );
    });
    // setShowFakeBillboard(false);
    // setCommonFakeLoader(0);
  };
  //autogenerate flow
  const generateAutogeneratePayload = (
    bundle: StatusList["result"],
    num: number,
    count: number,
    adId: string
  ) => {
    console.log(bundle, num, count, adId);
    // if (count <= 3) {
    const promises: any[] = [];
    let isMedia = false;
    let plain: any = [];
    const performancePromises: any[] = [];
    // if (bundle?.bundles?.length >= num) {
    //   setAutoRecommendationCount((prev) => prev + num);
    // } else {
    //   setAutoRecommendationCount((prev) => prev + bundle?.bundles?.length);
    // }
    const usableBundles = bundle.bundles?.filter(
      (bundle: Bundle) => bundle !== null
    );
    const arr = usableBundles.slice(0, num);
    const temp = {
      ...bundle,
      bundles: usableBundles.slice(num),
    };
    dispatch(
      setAutogenerateData({
        path: adId,
        value: { bundleRecommendations: temp },
      })
    );
    if (usableBundles?.length == 0) {
      dispatch(
        setAutogenerateData({
          path: [adId, "status"],
          value: { isError: true, inProgress: false },
        })
      );
      return;
    }
    arr.forEach((item, index: number) => {
      const recommendation = {
        headline: item.headline
          ? {
              data: [
                item.headline,
                ...bundle.headline.filter((d) => d !== item.headline),
              ],
              selected: item.headline,
              noOfElementToShow:
                bundle.headline.length + 1 > 4 ? 4 : bundle.headline.length + 1,
              showMore: bundle.headline.length + 1 > 4,
            }
          : {},
        description: item.description
          ? {
              data: [
                item.description,
                ...bundle.description.filter((d) => d !== item.description),
              ],
              selected: item.description,
              noOfElementToShow:
                bundle.description.length + 1 > 4
                  ? 4
                  : bundle.description.length + 1,
              showMore: bundle.description.length + 1 > 4,
            }
          : {},
        primary_text: item.primary_text
          ? {
              data: [
                item.primary_text,
                ...bundle.primary_text.filter((d) => d !== item.primary_text),
              ],
              selected: item.primary_text,
              noOfElementToShow:
                bundle.primary_text.length + 1 > 4
                  ? 4
                  : bundle.primary_text.length + 1,
              showMore: bundle.primary_text.length + 1 > 4,
            }
          : {},
      };
      const settings = {
        variantName:
          autogenerate[adId]?.settings?.adName +
          "_CAI_cv" +
          ((autogenerate[adId]?.inDraft ?? 0) + index),
        ...autogenerate[adId]?.settings,
      };

      const recommendationMediaData =
        autogenerate[adId]?.adType === "link_data"
          ? item?.image &&
            autogenerate?.[adId]?.bundleRecommendations?.image && {
              image: getImageData(
                layerRecommendationMapping(
                  item.image,
                  autogenerate?.[adId]?.bundleRecommendations?.image
                )
              ),
            }
          : autogenerate[adId]?.adType === "video_data"
          ? item?.video &&
            autogenerate?.[adId]?.bundleRecommendations?.video && {
              video: {
                data: convertVideoFrameData(
                  layerRecommendationMapping(
                    item.video,
                    autogenerate?.[adId]?.bundleRecommendations?.video
                  ),
                  autogenerate?.[adId]?.mediaStudioData
                ),
                selected: convertVideoFrameData(
                  layerRecommendationMapping(
                    item.video,
                    autogenerate?.[adId]?.bundleRecommendations?.video
                  ),
                  autogenerate?.[adId]?.mediaStudioData
                )[0],
              },
            }
          : [];

      if (
        autogenerate[adId]?.isMediaStudioAvailable === true &&
        ((item?.image && item.image.length > 0) ||
          (item?.video && item.video.length > 0))
      ) {
        isMedia = true;
        performancePromises.push(
          new Promise((res) => {
            dispatch(
              fetchPerformanceScoreNew({
                body: {
                  bu_name: selectedBrand?.name,
                  bu_detail: variantWithId.businessUnitDetail,
                  ad_id: adId,
                  account_id: autogenerate?.[adId]?.adAccountId,
                  ad_type: autogenerate?.[adId]?.adType === "link_data" ? "SINGLE_IMAGE_AD" : "VIDEO_AD",
                  is_bundle: true,
                  industry:"",
                  assets : [
                    {
                      asset_type : "primary_text",
                      asset_text : item.primary_text?.text
                    },
                    {
                      asset_type : "headline",
                      asset_text : item.headline?.text
                    },
                    {
                      asset_type : "description",
                      asset_text : item.description?.text
                    }
                  ]
        
                },
                callback: ({ error, performanceScore }) => {
                  if (!error) {
                    res({
                      primary_text: item.primary_text?.text,
                      headline: item.headline?.text,
                      description: item.description?.text,
                      adId,
                      adsetId: autogenerate?.[adId]?.adsetId,
                      elementId: autogenerate?.[adId]?.elementId,
                      adAccountId: autogenerate?.[adId]?.adAccountId,
                      performanceScore,
                      payload: {
                        previewExpiry: +new Date(),
                        recommendationMetaData: {
                          ...recommendation,
                          ...recommendationMediaData,
                        },
                        settings,
                      },
                      variations:
                        autogenerate[adId]?.adType === "link_data"
                          ? item.image
                            ? item.image.map((gen) => {
                                return {
                                  text: gen.layer_text,
                                  variation_type: "text",
                                  layer_name: gen.layer_name,
                                };
                              })
                            : [
                                {
                                  text: "",
                                  layer_name: "",
                                  variation_type: "text",
                                },
                              ]
                          : item.video
                          ? item.video.map((gen) => {
                              return {
                                text: gen.layer_text,
                                variation_type: "text",
                                layer_name: gen.layer_name,
                              };
                            })
                          : [
                              {
                                text: "",
                                layer_name: "",
                                variation_type: "text",
                              },
                            ],
                    });
                  } else {
                    res({
                      primary_text: item.primary_text?.text,
                      headline: item.headline?.text,
                      description: item.description?.text,
                      adId,
                      adsetId: autogenerate?.[adId]?.adsetId,
                      elementId: autogenerate?.[adId]?.elementId,
                      adAccountId: autogenerate?.[adId]?.adAccountId,
                      performanceScore: null,
                      payload: {
                        previewExpiry: +new Date(),
                        recommendationMetaData: {
                          ...recommendation,
                          ...recommendationMediaData,
                        },
                        settings,
                      },
                      variations:
                        autogenerate[adId]?.adType === "link_data"
                          ? item.image
                            ? item.image.map((gen) => {
                                return {
                                  text: gen.layer_text,
                                  variation_type: "text",
                                  layer_name: gen.layer_name,
                                };
                              })
                            : [
                                {
                                  text: "",
                                  layer_name: "",
                                  variation_type: "text",
                                },
                              ]
                          : item.video
                          ? item.video.map((gen) => {
                              return {
                                text: gen.layer_text,
                                variation_type: "text",
                                layer_name: gen.layer_name,
                              };
                            })
                          : [
                              {
                                text: "",
                                layer_name: "",
                                variation_type: "text",
                              },
                            ],
                    });
                  }
                },
              })
            );
          })
        );
      } else {
        performancePromises.push(
          new Promise((res) => {
            dispatch(
              fetchPerformanceScoreNew({
                body: {
                  bu_name: selectedBrand?.name,
                  bu_detail: variantWithId.businessUnitDetail,
                  ad_id: adId,
                  account_id: autogenerate?.[adId]?.adAccountId,
                  ad_type: autogenerate?.[adId]?.adType === "link_data" ? "SINGLE_IMAGE_AD" : "VIDEO_AD",
                  is_bundle: true,
                  industry:"",
                  assets : [
                    {
                      asset_type : "primary_text",
                      asset_text : item.primary_text?.text
                    },
                    {
                      asset_type : "headline",
                      asset_text : item.headline?.text
                    },
                    {
                      asset_type : "description",
                      asset_text : item.description?.text
                    }
                  ]
        
                },
                callback: ({ error, performanceScore }) => {
                  if (!error) {
                    res({
                      primary_text: item.primary_text?.text,
                      headline: item.headline?.text,
                      description: item.description?.text,
                      adId,
                      adsetId: autogenerate?.[adId]?.adsetId,
                      elementId: autogenerate?.[adId]?.elementId,
                      adAccountId: autogenerate?.[adId]?.adAccountId,
                      performanceScore,
                      payload: {
                        previewExpiry: +new Date(),
                        recommendationMetaData: {
                          ...recommendation,
                        },
                        settings,
                      },
                    });
                  } else {
                    res({
                      primary_text: item.primary_text?.text,
                      headline: item.headline?.text,
                      description: item.description?.text,
                      adId,
                      adsetId: autogenerate?.[adId]?.adsetId,
                      elementId: autogenerate?.[adId]?.elementId,
                      adAccountId: autogenerate?.[adId]?.adAccountId,
                      performanceScore: null,
                      payload: {
                        previewExpiry: +new Date(),
                        recommendationMetaData: {
                          ...recommendation,
                        },
                        settings,
                      },
                    });
                  }
                },
              })
            );
          })
        );
      }
    });

    if (isMedia) {
      let mediaPromies: any[] = [];
      Promise.all(performancePromises).then((data) => {
        console.log(data);
        data.forEach((variant, index) => {
          mediaPromies.push(
            new Promise((res, rej) => {
              dispatch(
                postMediaStudioProcessing({
                  adId,
                  failureReduxType: "MEDIA_STUDIO_BUNDLE_PROCESSIONG_FAILURE",
                  reduxActionType: "MEDIA_STUDIO_BUNDLE_PROCESSIONG_LOADING",
                  reduxStoreType: "POST_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE",
                  variations: variant?.variations,
                  finalAction: (id: string, error: any) => {
                    if (!error) {
                      res({
                        ...variant,
                        mediaFileId: id[0] || "",
                      });
                    } else {
                      rej({ error });
                    }
                  },
                })
              );
            })
          );
        });
        Promise.allSettled(mediaPromies).then((results) => {
          const payload: any = [];
          results.forEach((result) => {
            if (result?.status == "fulfilled") {
              payload.push(result.value);
            }
          });
          if (payload.length) {
            dispatch(
              setAutogenerateData({
                path: adId,
                value: {
                  mediaStudioPayload: [...payload],
                  mediaStudioFinalPayload: [],
                },
              })
            );
          } else {
            dispatch(
              setAutogenerateData({
                path: [adId, "status"],
                value: { isError: true, inProgress: false },
              })
            );
          }
        });
      });
    } else {
      console.log("called add variants");
      let variantPromise: any = [];
      Promise.all(performancePromises).then((variants) => {
        variants?.forEach((item) => {
          variantPromise.push(
            new Promise((res) => {
              addingVariant(item, res);
            })
          );
        });
        Promise.all(variantPromise).then((data) => {
          console.log("adding completed");
          console.log(data);
          dispatch(
            setAutogenerateData({
              path: adId,
              value: {
                status: {
                  ...autogenerate?.[adId]?.status,
                  inProgress: false,
                  progress: 0,
                  step: 0,
                },
              },
            })
          );
        });
      });
    }
  };

  useEffect(() => {
    console.log("socket", socket);
    if (socket) {
      socket.on("CaiFbBundleGeneration", function (data) {
        setTimeout(() => {
          let adId = Object.keys(autogenerate).find(
            (val: any) => autogenerate?.[val]?.requestId === data[0].request_id
          );
          console.log("caiFbData", data, adId);
          if (data[0]?.status === "failed") {
            if (adId) {
              dispatch(
                setAutogenerateData({
                  path: [adId, "status"],
                  value: { isError: true, inProgress: false },
                })
              );
              if (autogenerate[adId]?.generateBulkVideos?.length) {
              }
            }
          } else if (
            data[0]?.result?.bundles?.filter(
              (bundle: Bundle) => bundle !== null
            ).length == 0
          ) {
            if (adId) {
              dispatch(
                setAutogenerateData({
                  path: [adId, "status"],
                  value: { isError: true, inProgress: false },
                })
              );
            }
          } else {
            if (adId) {
              if (autogenerate[adId]?.generateBulkVideos?.length) {
                generateAutogeneratePayload(
                  data[0].result,
                  2,
                  autogenerate[adId]?.count,
                  adId
                );
                generateBillBoardPayload(adId, 2);
              } else {
                generateAutogeneratePayload(
                  data[0].result,
                  3,
                  autogenerate[adId]?.count,
                  adId
                );
              }
              dispatch(
                setAutogenerateData({
                  path: adId,
                  value: { requestId: "" },
                })
              );
            }
          }
        }, 0);
      });
    } else {
      //   here socket fallback case will come
    }
    return () => {
      if (socket) {
        socket.off("CaiFbBundleGeneration");
      }
    };
  }, [socket, variantWithId ]);

  useEffect(() => {
    console.log("socket", socket);
    if (socket) {
      socket.on("VGSImageVideoUrl", function (data) {
        console.log("VGSImageVideoUrl vgs data", data);
        if (data?.mediaStudioFiles?.status === "FAILED") {
          dispatch(
            setAutogenerateData({
              path: [data?.mediaStudioFiles?.adId, "status"],
              value: {
                isError: true,
                inProgress: false,
              },
            })
          );
        } else {
          console.log(
            autogenerate?.[data?.mediaStudioFiles?.adId]?.mediaStudioPayload,
            Array.isArray(
              autogenerate?.[data?.mediaStudioFiles?.adId]?.mediaStudioPayload
            ),
            autogenerate?.[
              data?.mediaStudioFiles?.adId
            ]?.mediaStudioPayload.find(
              (item: any) => item?.mediaFileId === data?.mediaStudioFiles?.id
            )
          );
          if (
            Array.isArray(
              autogenerate?.[data?.mediaStudioFiles?.adId]?.mediaStudioPayload
            )
          ) {
            const payload = autogenerate?.[
              data?.mediaStudioFiles?.adId
            ]?.mediaStudioPayload.find(
              (item: any) => item?.mediaFileId === data?.mediaStudioFiles?.id
            );

            if (payload) {
              dispatch(
                setAutogenerateData({
                  path: data?.mediaStudioFiles?.adId,
                  value: {
                    mediaStudioFinalPayload: [
                      ...autogenerate?.[data?.mediaStudioFiles?.adId]
                        ?.mediaStudioFinalPayload,
                      payload,
                    ],
                  },
                })
              );
            }
            // promises.push(new Promise((res)=>{addingVariant(payload,res)}));
            if (
              autogenerate?.[data?.mediaStudioFiles?.adId]
                ?.mediaStudioFinalPayload?.length ===
              autogenerate?.[data?.mediaStudioFiles?.adId]?.mediaStudioPayload
                ?.length
            ) {
              let promises: any = [];
              autogenerate?.[
                data?.mediaStudioFiles?.adId
              ]?.mediaStudioPayload?.forEach((obj: any) => {
                promises.push(
                  new Promise((res) => {
                    addingVariant(obj, res);
                  })
                );
              });
              Promise.all(promises).then((response) =>
                dispatch(
                  setAutogenerateData({
                    path: [data?.mediaStudioFiles?.adId, "status"],
                    value: {
                      inProgress: false,
                      progress: 0,
                      step: 0,
                    },
                  })
                )
              );
              dispatch(
                setAutogenerateData({
                  path: data?.mediaStudioFiles?.adId,
                  value: {
                    mediaStudioFinalPayload: [],
                    mediaStudioPayload: [],
                  },
                })
              );
            }
          }
        }
      });
    } else {
      //   here socket fallback case will come
    }
    // return () => {
    //   if (socket) {
    //     socket.off("VGSImageVideoUrl");
    //   }
    // };
  }, [socket]);

  useEffect(() => {
    if (triggerAutogenerateAdId) {
      if (
        autogenerate[triggerAutogenerateAdId] &&
        autogenerate[triggerAutogenerateAdId]?.bundleRecommendations?.bundles
          ?.length
      ) {
        if (autogenerate[triggerAutogenerateAdId]?.generateBulkVideos?.length) {
          generateAutogeneratePayload(
            autogenerate[triggerAutogenerateAdId]?.bundleRecommendations,
            2,
            0,
            triggerAutogenerateAdId
          );
          generateBillBoardPayload(triggerAutogenerateAdId, 2);
        } else {
          generateAutogeneratePayload(
            autogenerate[triggerAutogenerateAdId]?.bundleRecommendations,
            3,
            0,
            triggerAutogenerateAdId
          );
        }
        dispatch(setAutogenerateAdId(""));
      } else if (
        autogenerate[triggerAutogenerateAdId]?.generateBulkVideos?.length
      ) {
        generateBillBoardPayload(triggerAutogenerateAdId, 3);
      }
    }
  }, [triggerAutogenerateAdId]);
};
