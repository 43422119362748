import React from 'react'
import { HelpGlossary as Container } from './styles.ConfigureAd'

function HelpGlossary() {
  return (
    <Container className='glossary-container'>
      <div className='block'>
        <div className='title'>Brand Details</div>
        <div className='description'>
          Explain your brand’s purpose and product in a couple of lines.
        </div>
      </div>

      <div className='block'>
        <div className='title'>What is target interest keyword?</div>
        <div className='description'>
          A target interest keyword is a word or phrase, usually the cluster name, that describes
          the user’s search intent or interest. Target interest keywords are used to deliver
          targeted responsive search or display ads to users. Keywords closely matching the search
          query are considered an exact match and are more likely to have positive outcomes.
        </div>
      </div>

      <div className='block'>
        <div className='title'>What is reference headline/description? </div>
        <div className='description'>
          A reference headline/description is the starting point for responsive ad creation. Like a
          typical headlines, it summarizes the product benefits, offers, and can include the brand
          name. Using reference text, the system can create personalized headlines or descriptions
          depending on the product type and active offers.
        </div>
      </div>

      <div className='block'>
        <div className='title'>What is generation type?</div>
        <div className='description'>
          Using reference headline, headlines are generated by the system. These headlines are
          categorized as:
          <ul>
            <li>
              <strong>Interest specific</strong>: Headlines that capture the interest or intent of the
              user.
            </li>
            <li>
              <strong>USP specific</strong>: Headlines that focus on the Unique Selling Point of the
              Brand.
            </li>

            <li>
              <strong>Brand specific</strong>: Headlines that focus on the building brand awareness by
              having brand specific keywords.
            </li>

            <li>
              <strong>Benefit specifc</strong>: Headlines that highlight the benefit of the product.
            </li>

            <li>
              <strong>Generic</strong>: Headlines that are follow the ‘one-size-fits-all’ rule.
            </li>
          </ul>
        </div>
      </div>
    </Container>
  )
}

export default HelpGlossary
