import {
  GENERATE_DATA_SUCCEEDED,
  GENERATE_DATA_FAILED,
  GET_DATA_SUCCEEDED,
  GET_DATA_FAILED,
  UPDATE_GSAI_STORE,
  UPDATE_RECOMMENDATIONS,
  SET_NO_ADS_AVAILABLE,
  SetNoAdsAvailable,
  BusinnessUnitLoader,
  KeywordLoader,
  HeadlinedesLoader,
  BUSINESSUNIT_LOADER,
  KEYWORD_LOADER,
  HEADLINEDES_LOADER,
  DescriptionLoader,
  DESCRIPTION_LOADER,
  SET_AD_GROUPS,
  SetAdGroups,
  SetFilteredAdsLoader,
  SET_FILTERED_ADS_LOADER,
  KEYWORD_FAILER,
  KeywordFailer,
  GET_KEYWORDS,
  GetKeyWords,
} from "../../../types/CreativeAI/GsaiPlayground";

import type {
  GsaiState,
  GeneratData,
  GetData,
  UpdateGsaiStore,
  GeneratRecommendations,
} from "../../../types/CreativeAI/GsaiPlayground";

export const initialState: GsaiState = {
  platform: { isLoading: true, id: null },
  aiGroups: { isLoading: true, data: [], selected: {} },
  adAccounts: { isLoading: true, data: [], selected: {} },
  campaigns: { isLoading: true, data: [], selected: {} },
  adGroups: { isLoading: true, data: [], selected: {} },
  path1: "",
  path2: "",
  variantName: "",
  adGroupName: "",
  isNewAdGroup: false,
  selectedCampaign: "",
  finalUrl: "",
  screen: 0,
  isCreate: false,
  ads: [],
  adUnit: {},
  error: null,
  filteredAds: [],
  filteredAdsLoader: true,
  aiGroup: {},
  adAccount: {},
  campaign: {},
  adGroup: {},
  bUnits: [],
  initialState: {},
  hnd: {
    headlines: [],
    descriptions: [],
  },
  keywords: [],
  modal: "",
  recommendations: {
    requestId: "",
    error: "",
    status: "initial",
    data: [],
  },
  headlines: {
    requestId: "",
    error: "",
    status: "initial",
    data: [],
  },
  descriptions: {
    requestId: "",
    error: "",
    status: "initial",
    data: [],
  },
  existingHeadlines: "",
  existingDescriptions: "",
  dnd: {
    headlines: {
      recommended: [],
      toBePublished: [],
      replacements: [],
      more: [],
      toBePublishedError: false,
      creativeReplacementError: false,
    },
    descriptions: {
      recommended: [],
      toBePublished: [],
      replacements: [],
      more: [],
      toBePublishedError: false,
      creativeReplacementError: false,
    },
  },
  noAdsAvailable: false,
  businessUnitLoader: true,
  keywordLoader: true,
  keywordFailer: false,
  headlinedesLoader: true,
  descriptionLoader: true,
  replacementConfig: {
    isEnabled: false,
    headlines: {
      lowPerformance: { isChecked: false, value: 0 },
      goodPerformance: { isChecked: false, value: 0 },
      bestPerformance: { isChecked: false, value: 0 },
    },
    descriptions: {
      lowPerformance: { isChecked: false, value: 0 },
      goodPerformance: { isChecked: false, value: 0 },
      bestPerformance: { isChecked: false, value: 0 },
    },
  },
};

const ConfigureAd = (
  state = initialState,
  action:
    | GeneratData
    | GetData
    | UpdateGsaiStore
    | GeneratRecommendations
    | SetNoAdsAvailable
    | BusinnessUnitLoader
    | KeywordLoader
    | KeywordFailer
    | HeadlinedesLoader
    | DescriptionLoader
    | SetAdGroups
    | SetFilteredAdsLoader
    | GetKeyWords
) => {
  switch (action.type) {
    case GET_KEYWORDS: {
      return {
        ...state,
        keywordFailer: false,
      };
    }
    case GENERATE_DATA_SUCCEEDED: {
      const type: "headlines" | "descriptions" = action.payload.type;
      return { ...state, [type]: { ...state[type], ...action.payload } };
    }
    case GENERATE_DATA_FAILED: {
      const type: "headlines" | "descriptions" = action.payload.type;
      return {
        ...state,
        [type]: {
          requestId: "",
          status: "failed",
          error: action.payload,
          data: [],
        },
      };
    }
    case GET_DATA_SUCCEEDED: {
      const type: "headlines" | "descriptions" = action.payload.type;
      return { ...state, [type]: { ...state[type], ...action.payload } };
    }
    case GET_DATA_FAILED: {
      const type: "headlines" | "descriptions" = action.payload.type;
      return {
        ...state,
        [type]: {
          requestId: "",
          status: "failed",
          error: action.payload,
          data: [],
        },
      };
    }
    case UPDATE_RECOMMENDATIONS: {
      return {
        ...state,
        ...action.payload.state,
        recommendations: {
          ...state.recommendations,
          ...action.payload.recommendations,
        },
      };
    }
    case UPDATE_GSAI_STORE: {
      return { ...state, ...action.payload };
    }

    case SET_NO_ADS_AVAILABLE: {
      return {
        ...state,
        noAdsAvailable: action.payload,
      };
    }
    case BUSINESSUNIT_LOADER: {
      return {
        ...state,
        businessUnitLoader: action.payload,
      };
    }
    case KEYWORD_LOADER: {
      return {
        ...state,
        keywordLoader: action.payload,
      };
    }
    case KEYWORD_FAILER: {
      return {
        ...state,
        keywordFailer: action.payload,
      };
    }
    case HEADLINEDES_LOADER: {
      return {
        ...state,
        headlinedesLoader: action.payload,
      };
    }
    case DESCRIPTION_LOADER: {
      return {
        ...state,
        descriptionLoader: action.payload,
      };
    }
    case SET_AD_GROUPS: {
      return {
        ...state,
        adGroups: action.payload,
      };
    }
    case SET_FILTERED_ADS_LOADER: {
      return {
        ...state,
        filteredAdsLoader: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ConfigureAd;
