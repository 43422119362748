import {
  Accordion,
  Box,
  MenuItem,
  Paper,
  Popover,
  Select,
  Tooltip,
} from "@mui/material";
import styled from "styled-components";

export const StyledCreativeRotationMainBox = styled(Box)`
  padding: 1.25em;
  padding-top: 0em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0px;
  min-width: 0px;
  .rotated180 {
    svg {
      transform: rotate(180deg); /* Equal to rotateZ(45deg) */
    }
  }
  .MuiAccordion-root {
    box-shadow: 0px 4px 6px rgb(0 0 0 / 6%);
    border: 1px solid #ebebeb;
    border-radius: 10px;
  }

  font-size: 0.839vw;
  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1700px) {
    margin: 0.8125em 1em 0em 0em;
  }
`;

export const StyledImageMainBox = styled(Box)`
  img {
    border: 0.0682078px solid #e5e5e5;
    border-radius: 2px;
    object-fit: cover;
  }
`;

export const StyledVerticalDivider = styled(Box)`
  border-left: 1px solid #e5e5e5;
  /* border-right: 1px solid #e5e5e5; */
`;

export const StyledTableContainer = styled(Box)`
  .MuiTableCell-root {
    font-size: 0.839vw;

    @media screen and (max-width: 1280px) {
      font-size: 12px;
    }
    @media screen and (min-width: 1700px) {
      margin: 0.8125em 1em 0em 0em;
    }
  }
`;

export const StyledAdvancedConfiguration = styled(Box)`
  height: 24em;
  width: 25em;
  border-radius: 0px;
`;
export const StyledModalButton = styled.div`
  .save-btn {
    padding: 16px 24px !important;
    color: #ffffff;
    margin-right: 12px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 24px;
  }
`;

export const StyledRejectionContainer = styled.div`
  width: 39.8125em;
  height: 41.8125em;
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
  ::before {
    /* background-color: white; */
  }
`;

export const StyledOverflowBox = styled(Box)`
  min-height: 0;
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 1.6875em;
`;

export const StyledCRSelect = styled(Select)`
  border: 1px solid #e5e5e5;
  width: 12em;
  height: 2.5em;
  border-radius: 6px;
  text-align: left;
  padding: 0em 0.5em;

  // will change this
  .MuiSelect-standard .MuiInput-root .MuiInput-input.MuiInputBase-root {
    overflow: auto;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0.25em;
      padding-bottom: -1em;
      margin-bottom: -1em;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      padding-bottom: -1em;
      margin-bottom: -1em;
    }
  }

  .MuiMenu-paper {
    background: red;
    font-size: 0.839vw;

    @media screen and (max-width: 1280px) {
      font-size: 12px;
    }
    @media screen and (min-width: 1700px) {
      margin: 0.8125em 1em 0em 0em;
    }
  }
`;

export const StyledDivider = styled(Box)`
  border-top: 1px solid #e5e5e5;
`;

export const StyledPaper = styled(Paper)`
  font-size: 0.839vw;

  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1700px) {
    padding: 0em 0.9em;
  }
  padding: 0em 1.25em;
  width: 100%;
`;

export const StyledMenuItem = styled(MenuItem)``;

export const StyledTag = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(37, 122, 251, 0.1);
  border-radius: 6px;
  /* height: 100%; */
  padding: 0em 0.5em;
`;

export const StyledSelectBox = styled(Box)`
  font-size: 0.839vw;

  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1700px) {
    margin: 0.8125em 1em 0em 0em;
  }
  .MuiMenuItem-root {
    font-size: 0.839vw;

    @media screen and (max-width: 1280px) {
      font-size: 12px;
    }
    @media screen and (min-width: 1700px) {
      margin: 0.8125em 1em 0em 0em;
    }
  }
`;

export const StyledSelectOverflowBox = styled(Box)`
  overflow: auto;
  display: flex;
  gap: 0.25em;
  font-size: 0.839vw;
  padding: 0.5em 0em;
  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1700px) {
    margin: 0.8125em 1em 0em 0em;
  }
  svg {
    color: blue;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 0.2em;
    padding-bottom: -2em;
    margin-bottom: -1em;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    padding-bottom: -3em;
    margin-bottom: -1em;
  }
`;

export const StyledMetricsBox = styled(Box)`
  .rotated180 {
    svg {
      transform: rotate(180deg); /* Equal to rotateZ(45deg) */
      margin-bottom: 0.2em;
    }
  }
`;

export const StyledBackDrop = styled.div`
  position: relative;
  z-index: 9999999999999999999999999999999;
  background-color: #fff;
  width: 370px;
  max-width: 500px;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  min-height: 450px;
  height: 100%;
  justify-content: center;
  /* border-radius: 20px; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
