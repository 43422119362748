import { takeLatest, call, takeEvery } from "redux-saga/effects";
import { BASE_URL, CROSS_PLATFORM_URL } from "../../utils/path";
import HttpService from "../../HttpService/HttpService";
import { adAccountsV1API, groupV2API,groupV1API } from "../../MappedAPI/MappedAPI";
import { CreateCluster, CreateDescription, CreateHeadline, CREATE_CLUSTER, CREATE_DESCRIPTION, CREATE_HEADLINE, GetAllClusters, GetArticleDescription, GetArticleHeadline, GetBrand, GetClusterDetails, GetClusterLogs, GetCreateAdAds, GetCreateAdCampaign, GetCreateAdGroup, GetDescription, GetElementLogs, GetGPT3Reference, GetHeadline, GetPublishClusters, GetPublishedClusterDetails, GET_ALL_CLUSTER, GET_ARTICLE_DESCRIPTION, GET_ARTICLE_HEADLINE, GET_BRAND, GET_CLUSTER_DETAILS, GET_CLUSTER_LOGS, GET_CREATEAD_ADS, GET_CREATEAD_CAMPAIGN, GET_CREATEAD_GROUP, GET_DESCRIPTION, GET_ELEMENT_LOGS, GET_GPT3_REFERENCE, GET_HEADLINE, GET_PUBLISHED_CLUSTER_DETAILS, GET_PUBLISH_CLUSTER, PublishClusterData, PUBLISH_CLUSTER_DATA, UpdateAdRotation, UpdateCreativeReplacement, UpdateGPT3Reference, UpdatePublishedCluster, UPDATE_AD_ROTATION, UPDATE_CREATIVE_REPLACEMENT, UPDATE_GPT3_REFERENCE, UPDATE_PUBLISHED_CLUSTER } from "../types/cluster";
import axios from "axios";

function* getCreateAdCampaign(action: GetCreateAdCampaign): any {
    let {
        payload: { accountID, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/ad-account/${accountID}/campaigns`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        // const urlParams = `https://mocki.io/v1/d37438a3-43ba-4487-9bfa-cfebda620bb4`;
        // const response = yield call(HttpService.get, "", urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getCreateAdGroup(action: GetCreateAdGroup): any {
    let {
        payload: { accountID, campaignId, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/ad-account/${accountID}/${campaignId}/ad-group/all`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        // const urlParams = `https://mocki.io/v1/ccaaa5fc-cf3c-4965-9c25-c8b4a1d348b1`;
        // const response = yield call(HttpService.get, "", urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getCreateAdAds(action: GetCreateAdAds): any {
    let {
        payload: { accountID, campaignId, adGroupId, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/ad-account/${accountID}/${adGroupId}/ad-group-ad/all`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        // const urlParams = `https://mocki.io/v1/bbe25269-b696-4338-89bd-c377c8b03974`;
        // const response = yield call(HttpService.get, "", urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* createCluster(action: CreateCluster): any {
    let {
        payload: { accountID, file, user },
        callback,
    } = action;
    try {
        const formData = new FormData();
        formData.append("keyword_file", file);
        const urlParams = `${adAccountsV1API}/${accountID}/cluster/`;
        const response = yield call(HttpService.post, BASE_URL, `${urlParams}`, user, formData);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}


function* getAllClusters(action: GetAllClusters): any {
    let {
        payload: { accountID, user },
        callback,
    } = action;
    try {
        const urlParams = `${adAccountsV1API}/${accountID}/cluster`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getBrand(action: GetBrand): any {
    let {
        payload: { accountID, user },
        callback,
    } = action;
    try {
        const urlParams = `${adAccountsV1API}/${accountID}/gpt3_brand`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getGPT3Reference(action: GetGPT3Reference): any {
    let {
        payload: { platformId, brandId, accountID, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/reference/brand/${brandId}/adAccount/${accountID}/platform/${platformId}`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* updateGPT3Reference(action: UpdateGPT3Reference): any {
    let {
        payload: { accountID, user, params },
        callback,
    } = action;
    try {
        const urlParams = `${adAccountsV1API}/${accountID}/gpt3_reference`;
        const response = yield call(HttpService.post, BASE_URL, `${urlParams}`, user, params);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getArticleHeadline(action: GetArticleHeadline): any {
    let {
        payload: { brandId, platformId, accountID, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/article-headline/brand/${brandId}/adAccount/${accountID}/platform/${platformId}`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        if (!response.data.error) {
            //console.log("response if",response);
            callback && callback(response.data, false);
        } else {
            //console.log("response else",response);
            callback && callback(response, true);
        }
    } catch (error: any) {
        //console.log("catch error",error);
        callback && callback(error.response, true);
    }
}

function* getArticleDescription(action: GetArticleDescription): any {
    let {
        payload: { brandId, platformId, accountID, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/article-description/brand/${brandId}/adAccount/${accountID}/platform/${platformId}`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* createHeadline(action: CreateHeadline): any {
    let {
        payload: { accountID, clusterID, user, params },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/gpt3/generate-headline/${accountID}`;
        const response = yield call(HttpService.post, BASE_URL, `${urlParams}`, user, params);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* createDescription(action: CreateDescription): any {
    let {
        payload: { accountID, clusterID, user, params },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/gpt3/generate-description/${accountID}`;
        const response = yield call(HttpService.post, BASE_URL, `${urlParams}`, user, params);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getHeadline(action: GetHeadline): any {
    let {
        payload: { accountID, headlineId, clusterID, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/gpt3/headline/${headlineId}`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getDescription(action: GetDescription): any {
    let {
        payload: { accountID, clusterID, descriptionId, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/gpt3/description/${descriptionId}`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getElementLogs(action: GetElementLogs): any {
    let {
        payload: { elementId, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/recommendation/get-element-logs/${elementId}`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getClusterLogs(action: GetClusterLogs): any {
    let {
        payload: { publishClusterId, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/publish-cluster/get-cluster-logs/${publishClusterId}`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getClusterDetails(action: GetClusterDetails): any {
    let {
        payload: { url },
        callback,
    } = action;
    try {
        const response = yield call(axios.get, url);
        if (!response.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getPublishedClusterDetails(action: GetPublishedClusterDetails): any {
    let {
        payload: { id, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/publish-cluster/get-publish-cluster/${id}`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getPublishClusters(action: GetPublishClusters): any {
    let {
        payload: { accountID, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/publish-cluster/get-all-publish-cluster/${accountID}`;
        const response = yield call(HttpService.get, BASE_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        console.log(error);
        callback && callback(error.response, true);
    }
}

function* publishClusterData(action: PublishClusterData): any {
    let {
        payload: { accountID, user, params },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/publish-cluster/publish-cluster`;
        const response = yield call(HttpService.post, CROSS_PLATFORM_URL, `${urlParams}`, user, params);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* updatePublishedCluster(action: UpdatePublishedCluster): any {
    let {
        payload: { accountID, user, params },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/publish-cluster/update-publish-cluster`;
        const response = yield call(HttpService.put, CROSS_PLATFORM_URL, `${urlParams}`, user, params);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* updateCreativeReplacement(action: UpdateCreativeReplacement): any {
    let {
        payload: { user, params },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/publish-cluster/update-creative-replacement`;
        const response = yield call(HttpService.put, CROSS_PLATFORM_URL, urlParams, user, params);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* updateAdRotation(action: UpdateAdRotation): any {
    let {
        payload: { user, params },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/publish-cluster/update-group-creative-replacement`;
        const response = yield call(HttpService.put, CROSS_PLATFORM_URL, `${urlParams}`, user, params);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

export function* clusterWatcher() {
    yield takeLatest(GET_CREATEAD_CAMPAIGN, getCreateAdCampaign);
    yield takeLatest(GET_CREATEAD_GROUP, getCreateAdGroup);
    yield takeLatest(GET_CREATEAD_ADS, getCreateAdAds);
    yield takeLatest(CREATE_CLUSTER, createCluster);
    yield takeLatest(GET_ALL_CLUSTER, getAllClusters);
    yield takeLatest(GET_BRAND, getBrand);
    yield takeLatest(GET_GPT3_REFERENCE, getGPT3Reference);
    yield takeLatest(UPDATE_GPT3_REFERENCE, updateGPT3Reference);
    yield takeLatest(GET_ARTICLE_HEADLINE, getArticleHeadline);
    yield takeLatest(GET_ARTICLE_DESCRIPTION, getArticleDescription);
    yield takeLatest(CREATE_HEADLINE, createHeadline);
    yield takeLatest(CREATE_DESCRIPTION, createDescription);
    yield takeLatest(GET_HEADLINE, getHeadline);
    yield takeLatest(GET_DESCRIPTION, getDescription);
    yield takeLatest(GET_ELEMENT_LOGS, getElementLogs);
    yield takeLatest(GET_CLUSTER_LOGS, getClusterLogs);
    yield takeLatest(GET_CLUSTER_DETAILS, getClusterDetails);
    yield takeLatest(GET_PUBLISHED_CLUSTER_DETAILS, getPublishedClusterDetails);
    yield takeLatest(GET_PUBLISH_CLUSTER, getPublishClusters);
    yield takeLatest(PUBLISH_CLUSTER_DATA, publishClusterData);
    yield takeLatest(UPDATE_PUBLISHED_CLUSTER, updatePublishedCluster);
    yield takeLatest(UPDATE_CREATIVE_REPLACEMENT, updateCreativeReplacement);
    yield takeLatest(UPDATE_AD_ROTATION, updateAdRotation);
}