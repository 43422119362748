import { Box, CircularProgress } from "@mui/material";
import React from "react";

export function Loader({ width, height }: { width?: string; height?: string }) {
  return (
    <Box
      width={width}
      height={height}
      display="flex"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
    >
      <CircularProgress thickness={5} />
    </Box>
  );
}
