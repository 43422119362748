import FBIcon from "../../assets/svg/platforms/icons8-facebook.svg";
import GoogleIcon from "../../assets/svg/platforms/icons8-google.svg";
import LinkedInIcon from "../../assets/svg/platforms/icons8-linkedin.svg";

const paths = {
  google: GoogleIcon,
  facebook: FBIcon,
  linkedin: LinkedInIcon,
  tiktok: LinkedInIcon,
};

export const platformMapper = {
  "FACEBOOK AD": {
    icon: paths.facebook,
    shortName: "",
  },
  "GOOGLE SHOPPING AD": {
    icon: paths.google,
    shortName: "shopping",
  },
  "GOOGLE VIDEO AD": {
    icon: paths.google,
    shortName: "video",
  },
  "GOOGLE SEARCH AD": {
    icon: paths.google,
    shortName: "search",
  },
  "GOOGLE DISPLAY AD": {
    icon: paths.google,
    shortName: "display",
  },
  "GOOGLE DISCOVERY AD": {
    icon: paths.google,
    shortName: "discovery",
  },
  "GOOGLE APP AD": {
    icon: paths.google,
    shortName: "app",
  },
  "GOOGLE SMART AD": {
    icon: paths.google,
    shortName: "smart",
  },
  "GOOGLE LOCAL AD": {
    icon: paths.google,
    shortName: "local",
  },
  "GOOGLE PERFORMANCE MAX AD": {
    icon: paths.google,
    shortName: "performance",
  },
  "LINKEDIN AD": {
    icon: paths.linkedin,
    shortName: "",
  },
  "TikTok": {
    icon: paths.tiktok,
    shortName: "",
  }
} as any;
