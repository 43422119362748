import styled from "styled-components";

const statusTheme = {
  processing: {
    background: "#FEF3EA",
    color: "#F8862D",
    borderColor: "#F8862D",
  },
  published: {
    background: "#E7F9F7",
    color: "#12C8B2",
    borderColor: "#71DED1",
  },
  failed: {
    background: "#FFF6F5",
    color: "#E84B47",
    borderColor: "#F17F7C",
  },
};

export const StyledTableContainer = styled.div`
  overflow: auto;
  height: 100%;
  display: flex;
`;

export const StyledLogScreenTable = styled.table`
  width: 100% !important;
  height: fit-content;
`;

export const StyledTableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const StyledTableHeaderColumn = styled.th`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 1.25em;
  color: #000000;
  padding: 0 15px;
`;

export const StyledTableRow = styled.tr<{ greyBg?: boolean }>`
  height: 3.625em;
  background: ${(props) => (props.greyBg ? "#f5f6f7" : "#fff")};
  cursor: pointer;
`;

export const StyledTableData = styled.td`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 0.9375em;
  color: #333333;
  padding: 0 1em;
`;

export const StyledReviewStatus = styled.span<{
  background?: string;
}>`
  padding: 0.25em;
  background: ${(props) => props.background ?? "rgba(16, 171, 153, 0.1)"};
  border-radius: 0.375em;
  margin-left: 0.5em;
  width: 1.7em;
  height: 1.7em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPublishedVariantData = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledStatus = styled.span<{
  type: string;
}>`
  box-sizing: border-box;
  padding: 0.25em 0.5em;
  // height: 1.3125em;
  border: 0.0625em solid #f8862d;
  border-radius: 0.25em;
  background: ${(props) =>
    statusTheme[props.type as keyof typeof statusTheme].background};
  color: ${(props) =>
    statusTheme[props.type as keyof typeof statusTheme].color};
  border-color: ${(props) =>
    statusTheme[props.type as keyof typeof statusTheme].borderColor};
`;
