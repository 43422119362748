import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { StyledSelect } from "./Select.styled";
import { SelectProps as MUISelectProps } from "@mui/material/Select";
import InputLabel from "../../InputLabel/InputLabel";
import { StyledErrorMessage } from "../../Typography/Heading/styled";

export interface SelectProps extends MUISelectProps {
  label?: string;
  backgroundColor?: string;
  isBorder?: boolean;
  borderColor?: string;
  tooltipText?: string;
  options?: Array<string | number>;
  height?: string;
  menuItemStyle?: Object;
  children?: any;
}
//refactoring needed

export function Select({
  label,
  required,
  tooltipText,
  value,
  options,
  placeholder,
  error,
  menuItemStyle,
  children,
  ...props
}: SelectProps) {
  return (
    <>
      {label && (
        <InputLabel
          required={required}
          label={label}
          tooltipText={tooltipText}
        />
      )}
      <StyledSelect
        value={value}
        variant="standard"
        disableUnderline
        displayEmpty
        renderValue={(value: any) => {
          if (!value) {
            return <em>{placeholder}</em>;
          }
          return value;
        }}
        {...props}
      >
        {children
          ? children
          : options &&
            options.map((element: string | number) => (
              <MenuItem style={menuItemStyle} value={element}>
                {element}
              </MenuItem>
            ))}
      </StyledSelect>
      {error && (
        <StyledErrorMessage>This field is required *</StyledErrorMessage>
      )}
    </>
  );
}
