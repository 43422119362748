import { combineReducers } from "redux";
import common from "./common";
import actionImpact from "./actionImpact";
import campaign from "./campaign";
import form from "./form";
import cluster from "./cluster";
import auth from "./auth";
import adaccount from "./adaccount";
import creativeAi from "./creativeAi";
import montageAds from "./montageAds";
import aiGroupReducer from "./CreativeAI/Facebook/AiGroupListing/aiGroupReducer";
import businessUnitsReducer from "./CreativeAI/Facebook/BusinessUnits/businessUnitsReducer";
import activityLogsReducer from "./CreativeAI/Facebook/ActivityLogs/activityLogsReducer";
import createVariantReducer from "./CreativeAI/Facebook/CreateVariant/createVariantReducer";
import selectCohortReducer from "./CreativeAI/Facebook/SelectCohot/selectCohortReducer";
import MainReducer from "./CreativeAI/Facebook/Main/MainReducer";
import userInfoReducer from "./CreativeAI/Facebook/UserInfo/userInfoReducer";
import gsaiPlayground from "./CreativeAI/GsaiPlayground";
import bundleGenerationReducer from "./CreativeAI/Facebook/BundleGeneration/bundleGeneration";
import manualActionReducer from "./manualaction";
import aiInitiation from "./TargetingAI/aiInitiation";
import calibration from "./TargetingAI/calibration";
import keywordTuning from "./TargetingAI/keywordTuning";
import taiCommon from "./TargetingAI/common";
import audienceManager from "./AudienceManager/index";
import audienceManagerYoutube from "./AudienceManager/youtube";

import ActionDebuggerReducer from "./ActionDebugger/ActionDebugger";
import ActionSummaryReducer from "./ActionDebugger/ActionSummary";
import ActionDebuggerHelperReducer from "./ActionDebugger/ActionDebuggerHelper";
import accountsManager from "./accountsManager";
import cohorts from "./TargetingAI/cohorts";
import { mediaStudioReducer } from "./CreativeAI/Facebook/CreateVariant/mediaStudio";
import mediaStudioAdminReducer from "./CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import smb from "./SmbDashboard/index";
import ParentAiGroup from "./ParentAiGroup/index";
import BudgetPlannerAiGroup from "./BudgetPlannerAiGroup/index";
import reviewReducer from "./CreativeAI/shared/Review/review";
import gsTargetingAI from "./GsTargetingAI";
import { facebookReducer } from "./CreativeAI_Revamp/facebook.reducer";
import { mediaStudioTestReducer } from "./CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";
import { fbHomeScreenReducer } from "./CreativeAI_Revamp/HomeScreen/homeScreen.reducer";
import thirdPartyChannels from "./thirdPartyChannels";
import caiFBReducer from "./CreativeAI_Revamp/facebookReducer";
import { fbActivityLogReducer } from "./CreativeAI_Revamp/LogScreen/logscreen.reducers";
import { navigationReducer } from "./Navigation/navigation.reducer";
import { creativeRotationReducer } from "./ActionDebugger/CreativeRotation/creativeRotation.reducer";
import keywordTuningJson from "./TargetingAI/keywordTuningJson";

const rootReducer = combineReducers({
  common,
  adaccount,
  form,
  cluster,
  actionImpact,
  campaign,
  auth,
  creativeAi,
  montageAds,
  selectCohortReducer,
  createVariantReducer,
  aiGroupReducer,
  businessUnitsReducer,
  activityLogsReducer,
  MainReducer,
  userInfoReducer,
  accountsManager,
  manualActionReducer,
  bundleGenerationReducer,
  gsaiPlayground,
  aiInitiation,
  calibration,
  keywordTuning,
  keywordTuningJson,
  mediaStudioReducer,
  taiCommon,
  ActionDebuggerReducer,
  ActionSummaryReducer,
  ActionDebuggerHelperReducer,
  cohorts,
  mediaStudioAdminReducer,
  smb,
  ParentAiGroup,
  BudgetPlannerAiGroup,
  gsTargetingAI,
  reviewReducer,
  facebookReducer,
  fbHomeScreenReducer,
  mediaStudioTestReducer,
  caiFBReducer,
  fbActivityLogReducer,
  audienceManager,
  navigationReducer,
  thirdPartyChannels,
  creativeRotationReducer,
  audienceManagerYoutube,
});

export default rootReducer;
