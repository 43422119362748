import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#F2F2F2",
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#507AD7",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#ffffff",
  },
  ".MuiSwitch-thumb::before": {
    position: "absolute",
    width: "3.625em",
    left: "-0.625em",
    height: "1.25em",
    zIndex: 99999999,
    content: '""',
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#A1A1A1",
    borderRadius: 20 / 2,
  },
}));

interface CustomizedButtonProps extends SwitchProps {}

const CustomizedSwitch: React.FC<CustomizedButtonProps> = ({ ...props }) => {
  return <StyledSwitch {...props} />;
};

export default CustomizedSwitch;
