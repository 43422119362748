import { Box } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AdAccountState } from "store/types/adaccount";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { InitialMediaStudioType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { HashType, ItemType } from "../Editor.types";
import RecommendationImageComponent from "../Recommendations/RecommendationImageComponent";
import RequestMediaStudio from "../Recommendations/RequestMediaStudio";
import { StyledRecommendationTitle } from "../Recommendations/styled";
import WelcomeMediaStudio from "../Recommendations/WelcomeMediaStudio";
import ImageTextComponent from "./ImgeTextComponent";
import {
  StyledOnboardingScreenContainer,
  StyledRecommendationSubTitle,
  StyledRecommendationTitleContainer,
} from "./styled";
import VideoTextComponent from "./VideoTextComponent/VideoTextComponent";

interface MediaStudioProps {
  selectedTool: ItemType;
  toggleDirty: any;
  mixPanelCreativeStudioSeeMore: any;
}

export default function MediaStudio({
  selectedTool,
  toggleDirty,
  mixPanelCreativeStudioSeeMore,
}: MediaStudioProps) {
  const { mediaStudioRequestStatus } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );
  const { recommendationMetaData, selectedAd } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  return (
    <>
      {selectedAd?.isMediaStudioAvailable ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={
            recommendationMetaData?.[selectedTool.hash]?.data
              ? "flex-start"
              : "center"
          }
          height="100%"
          width="100%"
        >
          {recommendationMetaData?.[selectedTool.hash]?.data && (
            <StyledRecommendationTitleContainer>
              <StyledRecommendationTitle>
                Recommendations
              </StyledRecommendationTitle>
              <StyledRecommendationSubTitle>
                {selectedTool.hash === "image"
                  ? "Add Custom text to your images or choose from recommendations"
                  : "Let your users see what they really want to, using our AI Based recommendations !"}
              </StyledRecommendationSubTitle>
            </StyledRecommendationTitleContainer>
          )}
          {selectedTool.hash === "image" ? (
            <ImageTextComponent
              selectedTool={selectedTool}
              mixPanelCreativeStudioSeeMore={mixPanelCreativeStudioSeeMore}
            />
          ) : (
            <VideoTextComponent toggleDirty={toggleDirty} />
          )}
        </Box>
      ) : (
        <StyledOnboardingScreenContainer>
          {!mediaStudioRequestStatus ? (
            <WelcomeMediaStudio
              selectedAd={selectedAd}
              selectedBrand={selectedBrand}
            />
          ) : (
            <RequestMediaStudio />
          )}
        </StyledOnboardingScreenContainer>
      )}
    </>
  );
}
