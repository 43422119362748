import { GET_AD_ACCOUNT_METRICS } from '../../types/SmbDashboard/adAccount';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import HttpService from '../../../HttpService/HttpService';
import { CROSS_PLATFORM_URL } from '../../../utils/path';
import { smbDashboardApi } from 'MappedAPI/MappedAPI';
import { getStatus } from 'Pages/SmbDashboard/utils';
import {
  resetAdAccountsData,
  setAdAccountMetrics,
} from 'store/actions/SmbDashboard/adAccount';

function* getAdAccountSummary(data: any): any {
  try {
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      data.urlParams
    );
    const metricsPayload = {
      brandId: data.brandId,
      accountDetails: response.data.data,
      brandName: data.brandName,
    };
    let adAccountTypes: any = {};
    if (response?.data?.data?.length) {
      for (let i = 0; i < metricsPayload.accountDetails.length; i++) {
        const brandStatus: string =
          getStatus(
            metricsPayload?.accountDetails[i]?.spends?.deviation,
            metricsPayload?.accountDetails[i]?.metricPayload?.deviation
          ) || '';
        metricsPayload.accountDetails[i].brandStatus = brandStatus;
        if (brandStatus) {
          if (adAccountTypes[brandStatus]) {
            adAccountTypes[brandStatus] = adAccountTypes[brandStatus] + 1;
          } else {
            adAccountTypes[brandStatus] = 1;
          }
        }
      }
    }
    yield put(setAdAccountMetrics({ ...metricsPayload, adAccountTypes }));
  } catch (error) {
    yield put(
      setAdAccountMetrics({
        error: true,
        brandId: data.brandId,
        name: data.name,
      })
    );
  }
}

function* getAdAccountMetricsData(action: any): any {
  let {
    payload: { brandIds, selectedMonth, selectedYear },
  } = action;
  // yield put(resetAdAccountsData());
  for (let index = 0; index < brandIds.length; index++) {
    const brandId = brandIds[index]?.details?.id || '';
    const urlParams = `${smbDashboardApi}/brands/${brandId}/account-summary/metrics-data?month=${selectedMonth}&year=${selectedYear}`;
    yield fork(getAdAccountSummary, {
      urlParams,
      brandName: brandIds[index]?.details?.name || '',
      brandId,
    });
  }
}

export function* adAccountsWatcher() {
  yield takeLatest(GET_AD_ACCOUNT_METRICS, getAdAccountMetricsData);
}
