import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSingleParentAiGroup,
  getParentGroupAiItems,
  setParentAiGroupItems,
  setSelectedParentAiGroup,
} from "store/actions/ParentAiGroup";
import BasicModal from "common/modal/index";
import MultipleSelectChip from "common/MultipleSelectChip/index";
import InputField from "common/modal/InputField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { createParentAiGroup } from "../../../store/actions/ParentAiGroup/index";
import { initialState } from "../../../store/types/auth";
import { ParentAiGroupState } from "store/types/parentAiGroup";
import { useHistory } from "react-router-dom";
import { AiGroupState } from "store/types/BudgetPlannerAiGroup";
import { setGroups } from "store/actions/adaccount";
import { updateSearchParams } from "utils/commonFunctions";
const CreateParentModal: React.FC<any> = ({
  open,
  setOpen,
  setShowSuccess,
  setSuccessMeta,
  setIsParentGroupView,
}) => {
  const [value, setValue] = useState("");
  const [groupMapping, setGroupMapping] = useState({
    ids: [],
    names: [] as any,
  });
  const [error, setError] = useState("");
  const [selected, setSelected] = React.useState<
    { key: string; value: string }[]
  >([]);

  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const adaccount = useSelector((state: { adaccount: any }) => state.adaccount);
  const parentAiGroup = useSelector(
    (state: { ParentAiGroup: ParentAiGroupState }) => state.ParentAiGroup
  );
  const budgetAiGroup = useSelector(
    (state: { BudgetPlannerAiGroup: AiGroupState }) =>
      state.BudgetPlannerAiGroup
  );
  const dispatch = useDispatch();
  const { groups, selectedBrand } = adaccount;
  const history = useHistory();

  const handleSubmit = () => {
    dispatch(
      createParentAiGroup(
        {
          user: authState?.user,
          brandId: selectedBrand.id,
          payload: {
            aiGroups: selected.map((selectedGroup) => selectedGroup.key),
            name: value,
          },
        },
        (response: any, error: boolean) => {
          if (!error) {
            let grpArr = selected.map((selectedGroup) => selectedGroup.key);
            let newGroups = groups.map((group: any) => {
              if (grpArr.includes(group.id)) {
                group.parentAiGroupId = response.data.id;
              }
              return group;
            });
            dispatch(setGroups(newGroups));
            setSuccessMeta({
              heading: "Parent AI Group Created",
              subHeading: `${value} can now be accessed from the AI Group dropdown on the top `,
              onSuccess: () => {
                dispatch(
                  addSingleParentAiGroup({
                    id: response.data.id,
                    name: response.data.name,
                    aiGroups: selected.map((group) => ({
                      id: group.key,
                      name: group.value,
                    })),
                  })
                );
                updateSearchParams("parent_ai_group", response.data.id);
                setIsParentGroupView(true);
              },
            });
            setShowSuccess(true);
            setOpen(false);
          } else {
            setError("Something Went Wrong");
          }
        }
      )
    );
  };

  useEffect(() => {
    const keys = Object.keys(parentAiGroup.data.parentAiGroups);

    if (keys.length === 0) {
      dispatch(
        getParentGroupAiItems(
          { brandId: selectedBrand.id },
          (res: any, err: boolean) => {
            if (!err && res.data.length > 0) {
              const data = res.data.reduce((pre: any, cur: any) => {
                pre[cur.id] = cur;
                return pre;
              }, {});
              dispatch(setParentAiGroupItems(data));
            }
          }
        )
      );
    } else {
      const mappingValues = keys.reduce((prev: any, curr: any) => {
        const currData = parentAiGroup.data.parentAiGroups[curr];
        prev.names.push(currData.name);
        prev.ids.push(
          ...(currData.aiGroups
            ? currData.aiGroups.map((element: any) => element.id)
            : [])
        );
        return prev;
      }, groupMapping);
      setGroupMapping(mappingValues);
    }
  }, [parentAiGroup]);

  useEffect(() => {
    if (budgetAiGroup.data.AiGroups) {
      const keys = Object.keys(budgetAiGroup.data.AiGroups);
      const mappingValues = keys.reduce((prev: any, curr: any) => {
        const currData = budgetAiGroup.data.AiGroups[curr];
        if (!currData.optimisationSettingExist) {
          prev.names.push(currData.name);
          prev.ids.push(currData.id);
        }
        return prev;
      }, groupMapping);
      setGroupMapping(mappingValues);
    }
  }, [budgetAiGroup.data.AiGroups]);

  return (
    <>
      <BasicModal
        open={open}
        setOpen={setOpen}
        title="Create Parent AI Group"
        height={400}
        width={450}
        onSubmit={handleSubmit}
        disabledButton={
          value === "" ||
          selected.length === 0 ||
          groupMapping.names.includes(value as string)
        }
      >
        <Stack spacing={2}>
          <Typography>Name of Parent AI Group</Typography>
          <Box>
            <InputField
              value={value}
              placeholder={"Parent AI Group Name"}
              setValue={setValue}
            />
            <Typography variant="caption" sx={{ color: "red" }}>
              {groupMapping.names.includes(value as string)
                ? "Name already present"
                : " "}
            </Typography>
          </Box>
          <Typography>Select AI Group</Typography>

          <Box style={{ width: "100%" }}>
            <MultipleSelectChip
              selected={selected}
              setSelected={setSelected}
              items={
                groups
                  ? groups.map((group: any) => ({
                      key: group.id,
                      value: group.name,
                      config: group.optimisationSettingExist,
                      parentkey: group.parentAiGroupId,
                    }))
                  : []
              }
              disabled={groups.length === 0}
              disabledIds={groupMapping.ids}
            />
          </Box>
          <Typography variant="caption" sx={{ color: "red" }}>
            {error}
          </Typography>
        </Stack>
      </BasicModal>
    </>
  );
};

export default CreateParentModal;
