import React from "react";
import "./index.scss";
import { ReactComponent as DropDownArrow } from "../../assets/svg/dropdownarrow.svg";

const Select: React.FC<{
  defaultDisableOption?: any;
  name?: string;
  options: any;
  disable?: boolean;
  defaultSelected?: any;
  selectedName?:any;
  onChange?: (value: any) => void;
  value?:string
  placeholder?:boolean
}> = ({ defaultDisableOption, name, options, disable, defaultSelected, selectedName, onChange,value,placeholder=false }) => {
  const props: Record<string,string>={}
  if(value) props.value = value
  return (
    <select className="form-control form-select text-md" name={name} disabled={disable} value={defaultDisableOption} onChange={onChange} required {...props}>
      <span className="dropdown_arrow">
        <DropDownArrow />
      </span>
     {placeholder ? <option value="" selected >Select {selectedName}</option>:null}
      
      {options.map((value: any) => {
        return (
          <option value={value.value}>
            {value.name}
          </option>
        );
      })}
    </select>
  );
};

export default Select;
