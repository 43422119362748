import { Box, Skeleton } from "@mui/material";
import { PxSelect, PxSelectProps } from "components/CreativeAI_Revamp/shared";
import { StyledText } from "components/shared";

interface DropDownProps extends PxSelectProps {
  title: string;
  isLoading?: boolean;
  isFailed?: boolean;
}

export function DropDown({ title, isLoading, ...props }: DropDownProps) {
  return (
    <Box display="flex" flexDirection="column">
      <StyledText
        fontSize="0.6875em"
        lineHeight="13px"
        color="#666666"
        pb=".3em"
      >
        {title}
      </StyledText>

      {isLoading ? (
        <Skeleton
          width="9.3125em"
          height="1.9em"
          variant="rectangular"
          sx={{
            borderRadius: "6px",
            marginTop: ".1em",
          }}
        />
      ) : (
        <PxSelect
          {...props}
          sx={{
            fontSize: "1em",
          }}
        />
      )}
    </Box>
  );
}
