export const SET_AI_GROUP_PLANNERS = "SET_AI_GROUP_PLANNERS";
export const SET_AI_GROUP_PLANNERS_DETAILS = "SET_AI_GROUP_PLANNERS_DETAILS";
export const SET_AI_GROUP_ITEMS = "SET_AI_GROUP_ITEMS";
export const GET_AI_GROUP_ITEMS = "GET_AI_GROUP_ITEMS";
export const GET_AI_GROUP_ITEM = "GET_AI_GROUP_ITEM";
export const DELETE_AI_GROUP_ITEM = "DELETE_AI_GROUP_ITEM";
export const DELETE_AI_GROUP_BUDGET_PLANNER = "DELETE_AI_GROUP_BUDGET_PLANNER";
export const GET_AI_GROUP_PLANNERS = "GET_AI_GROUP_PLANNERS";
export const GET_AI_GROUP_PLANNER_DETAILS = "GET_AI_GROUP_PLANNER_DETAILS";
export const GET_AIGROUP_PLANNER_DETAILS_FAKE =
  "GET_AIGROUP_PLANNER_DETAILS_FAKE";
export const CREATE_AI_GROUP_PLANNER = "CREATE_AI_GROUP_PLANNER";
export const UPDATE_AI_GROUP_PLANNER = "UPDATE_AI_GROUP_PLANNER";
export const SET_SELECTED_PLANNER_ID = "SET_SELECTED_PLANNER_ID";
export const SET_SELECTED_AI_GROUP = "SET_SELECTED_AI_GROUP";
export const GET_GROUP_CONFIG = "GET_GROUP_CONFIG";
export const SET_AI_GROUP_LOADER = "SET_AI_GROUP_LOADER";

export type AiGroupState = {
  data: {
    AiGroups: any;
    selectedAiGroup: null | string;
    selectedPlannerId: null | string;
    loader: boolean;
  };
};

export type SetAiGroupPlanners = {
  type: typeof SET_AI_GROUP_PLANNERS;
  payload: any;
};

export type SetAiGroupItems = {
  type: typeof SET_AI_GROUP_ITEMS;
  payload: any;
};

export type SetAiGroupPlannerDetails = {
  type: typeof SET_AI_GROUP_PLANNERS_DETAILS;
  payload: any;
};

export type GetAiGroupItems = {
  type: typeof GET_AI_GROUP_ITEMS;
  payload: any;
  callback: Function;
};
export type GetAiGroupItem = {
  type: typeof GET_AI_GROUP_ITEM;
  payload: any;
  callback: Function;
};
export type DeleteAiGroupItem = {
  type: typeof DELETE_AI_GROUP_ITEM;
  payload: any;
  callback: Function;
};

export type DeleteAiGroupBudgetPlanner = {
  type: typeof DELETE_AI_GROUP_BUDGET_PLANNER;
  payload: any;
  callback: Function;
};

export type GetAiGroupPlanners = {
  type: typeof GET_AI_GROUP_PLANNERS;
  payload: any;
  callback: Function;
};
export type GetAiGroupPlannerDetails = {
  type: typeof GET_AI_GROUP_PLANNER_DETAILS;
  payload: any;
  callback: Function;
};
export type GetAiGroupPlannerDetailsFake = {
  type: typeof GET_AIGROUP_PLANNER_DETAILS_FAKE;
  payload: any;
  callback: Function;
};
export type CreateAiGroupPlanner = {
  type: typeof CREATE_AI_GROUP_PLANNER;
  payload: any;
  callback: Function;
};
export type UpdateAiGroupPlanner = {
  type: typeof UPDATE_AI_GROUP_PLANNER;
  payload: any;
  callback: Function;
};

export type SetSelectedPlannerId = {
  type: typeof SET_SELECTED_PLANNER_ID;
  payload: any;
};

export type SetSelectedAiGroup = {
  type: typeof SET_SELECTED_AI_GROUP;
  payload: any;
};
export type GetGroupConfig = {
  type: typeof GET_GROUP_CONFIG;
  payload: any;
  callback: Function;
};
export type SetAiGroupLoader = {
  type: typeof SET_AI_GROUP_LOADER;
  payload: any;
};
