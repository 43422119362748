import { Box } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { ReactComponent as SearchImg } from "../assets/search.svg";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(0.2em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

interface ISearchProps {
  searchTerm: string;
  setSearchTerm: Function;
  placeholder: string;
  width?: string;
  custom?: boolean;
  autoFocus?: boolean;
}

function SearchComponent(props: ISearchProps) {
  const { searchTerm, setSearchTerm, placeholder, width='9rem', custom=false, autoFocus = false } = props;

  return (
    <Box sx={{ width: "15.6rem", height: "2.5rem" }}>
      <Search style={custom ? {marginLeft:"7px"}:{}}>
        <SearchIconWrapper sx={{ pl: 1 }}>
          <SearchImg style={{ height: "1rem", width: "1rem" }} />
        </SearchIconWrapper>
        <StyledInputBase
        style={custom ? {width:"233px"}:{}}
          value={searchTerm}
          onChange={(event: any) => {
            setSearchTerm(event.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
          placeholder={placeholder}
          inputProps={{ "aria-label": "search" }}
          sx={{
            borderRadius: 2,
            border: "1px solid #D4D4D4",
            width: {width},
            height: "2.5rem",
            fontWeight: 600,
            fontSize: "0.75rem",
          }}
          autoFocus={autoFocus}
        />
      </Search>
    </Box>
  );
}

export default SearchComponent;
