import React from "react";
import { Popover } from "../../../shared";
import { StyledResultInfo, StyledSubTitle, StyledToolTipTitle } from "./styled";

export default function DropDownTooltip({
  title,
  subTitle,
}: {
  title: string;
  subTitle: string;
}) {
  return (
    <Popover
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
    >
      <StyledResultInfo>
        <StyledToolTipTitle>{title}</StyledToolTipTitle>
        <StyledSubTitle>{subTitle}</StyledSubTitle>
      </StyledResultInfo>
    </Popover>
  );
}
