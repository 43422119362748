import React from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Circle } from "../../../assets/svg/circle.svg";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import { ReactComponent as Preview } from "../../../assets/svg/preview.svg";
import { ClusterState } from "../../../store/types/cluster";
import "./index.scss";

const CreateAdPreview: React.FC = (): JSX.Element => {
  const [headlineSlots, setHeadlineSlots] = React.useState<any>([null,null,null]);
  const [descriptionSlots, setDescriptionSlots] = React.useState<any>([null,null]);
  const clusterState = useSelector((state: { cluster: ClusterState }) => state.cluster);
  const { createAdHeadline, createAdDesc, targetKeyword} = clusterState;

  React.useEffect(() => {
    
      let selectedHeadline:Array<any> = [];
      let pinnedHeadline:Array<any> = [];
      let selectedDescription:Array<any> = [];
      let pinnedDescription:Array<any> = [];
      
    if(createAdHeadline){
      selectedHeadline = createAdHeadline
        .filter((h: any) => (h.selected && h.pinningPositionId == 0 && h.isLocked))
        .sort((a: any, b: any) => {
          return (parseInt(a.priority) - parseInt(b.priority))
        })

      pinnedHeadline = createAdHeadline
        .filter((h: any) => (h.selected && h.pinningPositionId != 0 && h.isLocked))
        .sort((a: any, b: any) => {
          return (parseInt(a.pinningPositionId) - parseInt(b.pinningPositionId))
        })
        .reduce((obj: any, item: any) => (obj[item.pinningPositionId] = item, obj), {})
    }
    if (createAdDesc) {
      selectedDescription = createAdDesc
        .filter((d: any) => (d.selected && d.pinningPositionId == 0 && d.isLocked))
        .sort((a: any, b: any) => {
          return (parseInt(a.priority) - parseInt(b.priority))
        })

      pinnedDescription = createAdDesc
        .filter((h: any) => (h.selected && h.pinningPositionId != 0 && h.isLocked))
        .sort((a: any, b: any) => {
          return (parseInt(a.pinningPositionId) - parseInt(b.pinningPositionId))
        })
        .reduce((obj: any, item: any) => (obj[item.pinningPositionId] = item, obj), {})
    }

    let hs = headlineSlots.map((h:any,index:number) => {
      let posIndex = index + 1;
      if(pinnedHeadline[posIndex]){
        return pinnedHeadline[posIndex].value;
      }else if(selectedHeadline.length>0){
        let sh = selectedHeadline.shift();
        return sh.value;
      }else{
        return null;
      }
    })

    let ds = descriptionSlots.map((h:any,index:number) => {
      let posIndex = index + 1;
      if(pinnedDescription[posIndex]){
        return pinnedDescription[posIndex].value;
      }else if(selectedDescription.length>0){
        let sh = selectedDescription.shift();
        return sh.value;
      }else{
        return null;
      }
    })
   
   
   setHeadlineSlots(hs);
   setDescriptionSlots(ds);
  }, [createAdHeadline,createAdDesc]);

  return (
    <div className="create-ad-preview">
      {/* Header */}
      <div className="create-ad-preview--header">
        <div className="perview">Preview</div>
        <Preview />
      </div>

      {/* Preview */}
      <div className="create-ad-preview--body">
        <div className="create-ad-preview-container">
          <div className="preview--header">
            <div className="preview--header--title">
              <div>
                <b>Ad</b> <Circle /> <span>{targetKeyword.finalURL}</span>
              </div>
            </div>
            <div className="preview--header--heading">
              {headlineSlots.map((position:number,index:number) => {
                return (
                  `${position?position:`Headline ${index+1}`} ${index<2?' | ':''}`
                )
              })}
            </div>
            <div className="preview--header--desc">
            {descriptionSlots.map((position:number,index:number) => {
                return (
                  `${position?position:`Description ${index+1}`} ${index<1?' | ':''}`
                )
              })}
            </div>
          </div>
        </div>

        {/* Description */}
        <div className="preview-description">
          This preview shows potential ads assembled using your assets. Not all combinations are
          shown. Assets can be shown in any order, so make sure that they make sense individually or
          in combination, and don’t violate our policies or local law. Some shortening may also
          occur in some formats. You can make sure certain text appears in your ad.{" "}
          <a>Learn more</a>
        </div>
      </div>
    </div>
  );
};

export default CreateAdPreview;
