import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StyledPreviewSectionBody = styled(Box)`
  background: #fafafa;
  border: 0.0625rem dashed #f0f0f0;
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  padding: 1rem;
  width: 25%;
  height: 100%;
`;

export const StyledPreviewHeader = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 1.2rem;
`;

export const StyledPreviewContainer = styled(Box)`
  background: #ffffff;
  box-shadow: 0rem 0.625rem 1.5rem rgba(35, 40, 60, 0.05);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  padding: 0.5rem 0.5rem 0.25rem;
`;

export const StyledPreviewText = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-bottom: 0.25rem;
`;

export const StyledPreviewDetails = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
  border-radius: 8px;
  padding: 0rem 1rem;
`;
