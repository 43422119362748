import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import HttpService from "../../../../../HttpService/HttpService";
import {
  BUNDLE_GENERATE,
  BUNDLE_GENERATE_STATUS,
  GetBillboardBundle,
  GetBundleGenerate,
  GetBundleGenerateStatus,
  GET_BILLBOARD_BUNDLE,
  SET_FAKEGENERATE_LOADING,
} from "../../../../types/CreativeAI/Facebook/BundleGeneration/bundleGeneration";
import { crossPlatform, groupV2API } from "../../../../../MappedAPI/MappedAPI";
import { SET_AUTOGENERATE_LOADING } from "../../../../types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { BASE_URL, CROSS_PLATFORM_URL } from "../../../../../utils/path";

const Base_Variants_API = "/v1/api/creative-variants";

function* GetBundleGeneration(action: GetBundleGenerate): any {
  const {
    payload: { user, data, finalAction },
    callback,
  } = action;
  try {
    const urlParams = `${Base_Variants_API}/v1/bundle-generate`;
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      data
    );
    if (response.data?.success) {
      yield put({ type: finalAction, payload: response.data.data });
      callback && callback(response.data.data, false);
    } else {
      callback && callback(response.data, true);
      console.log("error at generate bundle");
      yield put({ type: SET_AUTOGENERATE_LOADING, payload: false });
      yield put({ type: SET_FAKEGENERATE_LOADING, payload: false });
    }
  } catch (error) {
    console.log("error at generate bundle");
    callback && callback({}, true);
    yield put({ type: SET_AUTOGENERATE_LOADING, payload: false });
    yield put({ type: SET_FAKEGENERATE_LOADING, payload: false });
  }
}

function* getBillboardBundle(action: GetBillboardBundle): any {
  console.log("called here");
  const {
    payload: { user, data, finalAction, payload },
    callback,
  } = action;
  try {
    const urlParams = `/v1/api/creative-studio/generate-bulk-videos`;
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      payload
    );
    if (response.data.success) {
      callback && callback(response.data.data, false);
    } else {
      callback && callback({}, true);
    }
  } catch (error) {
    console.log("error at bundle status");
    callback && callback({}, true);
  }
}

function* GetBundleGenerationStatus(action: GetBundleGenerateStatus): any {
  const {
    payload: { user, data, finalAction, requestid },
    callback,
  } = action;
  try {
    const urlParams = `${Base_Variants_API}/v1/bundle-generate/status?request_ids=${requestid}`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (response.data.success) {
      callback && callback(response.data.data, false);
    } else {
      callback && callback(response.data, true);
      yield put({ type: SET_AUTOGENERATE_LOADING, payload: false });
      yield put({ type: SET_FAKEGENERATE_LOADING, payload: false });
    }
  } catch (error) {
    console.log("error at bundle status");
    yield put({ type: SET_AUTOGENERATE_LOADING, payload: false });
    yield put({ type: SET_FAKEGENERATE_LOADING, payload: false });
  }
}

export function* bundleWatcher() {
  yield takeLatest(BUNDLE_GENERATE, GetBundleGeneration);
  yield takeEvery(BUNDLE_GENERATE_STATUS, GetBundleGenerationStatus);
  yield takeEvery(GET_BILLBOARD_BUNDLE, getBillboardBundle);
}
