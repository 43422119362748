import { Box, styled, Tab, Tabs } from "@mui/material";

export const StyledOverflowedText = styled(Box)`
  display: block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledLabelText = styled(Box)`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 16px;
  padding-top: 4px;
`;

export const StyledLoaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 692px;
  height: 700px;
`;

export const StyledTabs = styled(Tabs)`
  .MuiButtonBase-root {
    color: #000000 !important;
  }
  .MuiTabs-indicator {
    background-color: #0869fb;
    height: 3px;
  }
  .Mui-selected {
    opacity: 1 !important;
    color: #000000;
    font-family: Lato !important;
  }
`;
export const StyledTab = styled(Tab)`
  text-transform: none;
  font-weight: 700;
  font-size: 22px;
  line-height: 26.4px;
  color: #000000;
  opacity: 0.3;
  margin-left: 6px;
  font-family: Lato !important;
`;

export const StyledLoaderText = styled(Box)`
  width: 100%;
  height: 60px;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

export const StyledImageContainer = styled(Box)`
  height: 55vh;
  width: 55vh;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const StyledVideoContainer = styled(Box)`
  height: 50vh;
  width: 50vh;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 8px;
`;
export const StyledFrameWrapper = styled(Box)`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const StyledErrorHeading = styled(Box)`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 8px;
`;

export const StyledErrorAction = styled(Box)`
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #6f6f6f;
`;
