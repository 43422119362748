import { FormControlLabel, RadioGroup, styled } from "@mui/material";

export const StyledFormControlLabel = styled(FormControlLabel)`
  /* background: red; */
  margin: 0em;
  padding: 0em;

  .MuiFormControlLabel-label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 0.75em;
    color: #333333;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  font-size: 1.1112vw;
  @media screen and (max-width: 1280px) {
    font-size: 11px;
  }
`;
