import {
  Pacing,
  PacingDescription,
  PacingHeading,
  StyledAddPacingButton,
  StyledPacingEmptyContainer,
  StyledPacingSvg,
} from "./EmptyPacing.styled";
import { ReactComponent as PixisLogo } from "assets/svg/pixisLogo.svg";
import { useSelector } from "react-redux";
import { ParentAiGroupState } from "store/types/parentAiGroup";
import { useEffect } from "react";

export const EmptyPacing: React.FC<{
  handlePacing: any;
  handleTypeShow: any;
  showType: any;
  type: "group" | "parentGroup";
}> = ({ handlePacing, handleTypeShow, showType, type }) => {
  const { data } = useSelector(
    (state: { ParentAiGroup: ParentAiGroupState; BudgetPlannerAiGroup: any }) =>
      type === "parentGroup" ? state.ParentAiGroup : state.BudgetPlannerAiGroup
  );
  const {agencyAssets} = useSelector((state:any) => state.common);

  const selectedGroup =
    type === "parentGroup" ? data.selectedParentAiGroup : data.selectedAiGroup;

  useEffect(() => {
    if (showType) handlePacing();
  }, [showType]);

  return (
    <StyledPacingEmptyContainer>
      {agencyAssets?.logo ?  <img src={agencyAssets?.logo} style={{height:'30px', width:'30px'}} /> : <PixisLogo />}
      <PacingHeading>
        Budget <Pacing>Pacing</Pacing>
      </PacingHeading>
      <PacingDescription>
        Pace your total budget for all your ad accounts in one go! Configure
        your budget constraints, and our AI system will optimize it on a daily
        basis.
      </PacingDescription>
      <StyledPacingSvg />
      <StyledAddPacingButton
        disabled={selectedGroup ? false : true}
        onClick={() => {
          handleTypeShow();
        }}
      >
        + Add pacing
      </StyledAddPacingButton>
    </StyledPacingEmptyContainer>
  );
};
