export const GET_BUSINESS_UNIT_WITH_ID = "GET_BUSINESS_UNIT_WITH_ID";
export const SET_BUSINESS_UNIT_WITH_ID = "SET_BUSINESS_UNIT_WITH_ID";
export const SET_ELEMENT_CREATIVE_AI = "SET_ELEMENT_CREATIVE_AI";
export const GET_ELEMENT_CREATIVE_AI = "GET_ELEMENT_CREATIVE_AI";
export const ADD_ELEMEMT_CREATIVE_AI = "ADD_ELEMEMT_CREATIVE_AI";
export const SET_AI_GROUP_LIST = "SET_AI_GROUP_LIST";
export const GET_AI_GROUP_LIST = "GET_AI_GROUP_LIST";
export const NAVIGATE_VARIANT = "NAVIGATE_VARIANT";
export const GENERATE_LEVERS = "GENERATE_LEVERS";
export const SET_GENERATE_LEVERS = "SET_GENERATE_LEVERS";
export const SET_ALL_BUSINESS_UNIT_LIST = "SET_ALL_BUSINESS_UNIT_LIST";
export const GET_ALL_BUSINESS_UNIT_LIST = "GET_ALL_BUSINESS_UNIT_LIST";

export const SET_AD_ADSET_FIELD_LIST = "SET_AD_ADSET_FIELD_LIST";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const TOGGLE_CREATIVE_ANIMATER = "TOGGLE_CREATIVE_ANIMATER";
export const GET_ELEMENT_RESULT_METRICS = "GET_ELEMENT_RESULT_METRICS";
export const SET_ELEMENT_RESULT_METRICS = "SET_ELEMENT_RESULT_METRICS";

export type GetElementResultMetrics = {
  type: typeof GET_ELEMENT_RESULT_METRICS;
  payload: any;
};
export type SetElementResultMetrics = {
  type: typeof SET_ELEMENT_RESULT_METRICS;
  payload: any;
};
export type GetBusinessUnitWithId = {
  type: typeof GET_BUSINESS_UNIT_WITH_ID;
  payload: any;
};

export type SetAdAdSetFieldList = {
  type: typeof SET_AD_ADSET_FIELD_LIST;
  payload: any;
};
export type SetBusinessUnitWithId = {
  type: typeof SET_BUSINESS_UNIT_WITH_ID;
  payload: any;
};
export type SetElementCreativeAi = {
  type: typeof SET_ELEMENT_CREATIVE_AI;
  payload: any;
};
export type GetElementCreativeAi = {
  type: typeof GET_ELEMENT_CREATIVE_AI;
  payload: any;
};
export type SetAiGroupListing = {
  type: typeof SET_AI_GROUP_LIST;
  payload: any;
};
export type GetAIGroupList = {
  type: typeof GET_AI_GROUP_LIST;
  payload: any;
};
export type AddElementCReativeAI = {
  type: typeof ADD_ELEMEMT_CREATIVE_AI;
  payload: any;
};
export type NavigateVariant = {
  type: typeof NAVIGATE_VARIANT;
  payload: any;
};
export type SetGenerateLevers = {
  type: typeof SET_GENERATE_LEVERS;
  payload: any;
};
export type GenerateLevers = {
  type: typeof GENERATE_LEVERS;
};
export type SetAllBusinessUnitList = {
  type: typeof SET_ALL_BUSINESS_UNIT_LIST;
  payload: any;
};
export type GetAllBusinessUnitList = {
  type: typeof GET_ALL_BUSINESS_UNIT_LIST;
};

// creative animater toggle

export type ToggleCreativeAnimater = {
  type: typeof TOGGLE_CREATIVE_ANIMATER;
  payload: ToggleCreativeAnimaterPayloadType;
};

export interface ToggleCreativeAnimaterPayloadType {
  brandId: string;
  isCreativeAnimator: boolean;
}
