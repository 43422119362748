import { Stack } from "@mui/material";
import { Button } from "components/shared";
import { useEffect, useState } from "react";
import { StyledButtonContainer } from "./styled";

interface ReviewApprovalActionProps {
  initialState: "APPROVED" | "REJECTED" | "UNDER_REVIEW";
  handleClick: (action: "APPROVED" | "REJECTED" | "UNDER_REVIEW") => void;
}

function ReviewApprovalAction({
  handleClick,
  initialState,
}: ReviewApprovalActionProps) {
  const [action, setAction] = useState<
    "APPROVED" | "REJECTED" | "UNDER_REVIEW"
  >("UNDER_REVIEW");
  const constants = {
    color: { rejected: "#E84B47", approved: "#12C8B2" },
  };
  useEffect(() => {
    setAction(initialState);
  }, [initialState]);

  return (
    <StyledButtonContainer>
      <Button
        variant={action !== "REJECTED" ? "outlined" : "contained"}
        onClick={() => {
          handleClick(action !== "REJECTED" ? "REJECTED" : "UNDER_REVIEW");
          setAction((prev) =>
            prev !== "REJECTED" ? "REJECTED" : "UNDER_REVIEW"
          );
        }}
        sx={{
          padding: "0em 1em",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "0.625em",
          width: "9.7em",
          mr: "0.5em",
          color: action == "REJECTED" ? "#FFF" : constants.color.rejected,
          border: `0.0625em solid ${constants.color.rejected}`,
          backgroundColor:
            action !== "REJECTED" ? "" : constants.color.rejected,
          boxShadow: "none",
          ":hover": {
            background: action == "REJECTED" ? constants.color.rejected : "",
            border: `0.0625em solid ${constants.color.rejected}`,
          },
        }}
      >
        {action == "REJECTED" ? `Rejected` : `Reject`}
      </Button>
      <Button
        variant={action !== "APPROVED" ? "outlined" : "contained"}
        onClick={() => {
          handleClick(action !== "APPROVED" ? "APPROVED" : "UNDER_REVIEW");
          setAction((prev) =>
            prev !== "APPROVED" ? "APPROVED" : "UNDER_REVIEW"
          );
        }}
        sx={{
          padding: "0em 1em",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "0.625em",
          width: "9.7em",
          color: action == "APPROVED" ? "#FFF" : constants.color.approved,
          border: `0.0625em solid ${constants.color.approved}`,
          backgroundColor:
            action !== "APPROVED" ? "" : constants.color.approved,
          boxShadow: "none",
          ":hover": {
            background: action == "APPROVED" ? constants.color.approved : "",
            border: `0.0625em solid ${constants.color.approved}`,
          },
        }}
      >
        {action == "APPROVED" ? `Approved` : `Approve`}
      </Button>
    </StyledButtonContainer>
  );
}

export default ReviewApprovalAction;
