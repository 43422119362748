import ModalContainer from "components/Modal/ModalContainer";
import ModalWrapper from "components/Modal/ModalWrapper";
import React, { Fragment, useMemo } from "react";
import {
  StyledCloseContainer,
  StyledRevieButtonContainer,
  StyledReviewModal,
  StyledReviewModalTitle,
  StyledSendMessage,
} from "./styled";
import { Button } from "components/shared";
import { usePublish } from "components/CreativeAI/GsaiPlayground/CreateVariants/CreateAdVariants/hooks";
import { useDispatch, useSelector } from "react-redux";
import { submitForReview } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { initialState } from "store/types/auth";
import { AdAccountState } from "store/types/adaccount";
import { payloadOfCreateDraft } from "components/CreativeAI/shared/commonFunctions";
import { createDraftAds } from "store/actions/CreativeAI/GsaiPlayground";
import { GsaiState } from "store/types/CreativeAI/GsaiPlayground";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { LightTooltip } from "components/shared/LightTooltip";
import { brandViewerAccessText } from "utils/constants/commonConsts";
import { PxDialog } from "components/CreativeAI_Revamp/shared";
import { Box } from "@mui/system";
import { mixPanelAdEvent } from "utils/mixpanel";
import { isViewerRole } from "utils/commonFunctions";

const ReviewModal: React.FC<{ adUnit: any }> = ({ adUnit }): JSX.Element => {
  const {
    handlePublish,
    isCreate,
    adAccount,
    campaign,
    headlines,
    descriptions,
    recommendations,
    replacementConfig,
    existingHeadlines,
    existingDescriptions,
  } = usePublish();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const gsaiPlayground = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );

  const { finalUrl, path1, path2 } = gsaiPlayground;
  const dispatch = useDispatch();
  const {
    selectedAdAccount: { adAccountId },
  } = useSelector((state: any) => state.aiGroupReducer);

  const { approverLink } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );

  const handleCreateDraft = () => {
    const payload = payloadOfCreateDraft(
      isCreate,
      adAccount.adAccountId,
      campaign.id,
      adUnit,
      { headlines, descriptions },
      replacementConfig,
      recommendations,
      existingHeadlines,
      existingDescriptions,
      finalUrl,
      path1,
      path2
    );

    dispatch(
      createDraftAds({
        params: payload,
        callback: (response: any) => {
          handleSubmitForReview(response.data.data.id);
        },
      })
    );
  };

  const displayLink = () => {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        modal: "review-flow-modal",
      },
    });
  };

  const handleSubmitForReview = (variantId?: string) => {
    dispatch(
      submitForReview({
        params: {
          platform: "GOOGLE",
          type: "CREATIVE",
          brandId: selectedBrand?.id,
          variants: [
            {
              id: variantId ? variantId : adUnit.variant_id,
            },
          ],
          user: auth.user,
          adAccountId,
        },
      })
    );
    displayLink();
  };

  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;

  // const isBrandViewer = useMemo(
  //   () => brandRoles?.length === 1 && brandRoles[0]?.role === "brand-viewer",
  //   [brandRoles]
  // );

  return (
    <PxDialog
      open={true}
      onClose={() => {
        dispatch({
          type: "UPDATE_GSAI_STORE",
          payload: {
            modal: "",
          },
        });
      }}
      titleComponent={<Fragment />}
      maxWidth="sm"
      // fullWidth
      hideTitle
      PaperProps={{
        sx: {
          borderRadius: "6px",
          width: "40vw",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p="0em 10px"
      >
        <StyledCloseContainer>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              dispatch({
                type: "UPDATE_GSAI_STORE",
                payload: {
                  modal: "",
                },
              });
            }}
          ></button>
        </StyledCloseContainer>
        <StyledSendMessage />
        <StyledReviewModalTitle>
          Send changes for Review before Publishing or Publish Now?
        </StyledReviewModalTitle>
        <StyledRevieButtonContainer>
          <Button
            buttonVariant="outlined"
            className="review-btn"
            onClick={() => {
              mixPanelAdEvent("CAI GS Variant Submitted for Reiview", {
                module_name: "Google Search Playground",
                sub_module_name: gsaiPlayground.isCreate
                  ? "Create Variant"
                  : "Refresh Ad",
                brand_name: selectedBrand?.name,
                brand_type: selectedBrand?.brandType,
                brand_id: selectedBrand?.id,
                agency_name: selectedBrand?.agency_name,
                agency_id: selectedBrand?.agencyId,
                reference_ad_id: gsaiPlayground?.adUnit?.adGroupAd?.ad?.id,
                reference_ad_group_id: gsaiPlayground?.adUnit?.adGroup?.id,
                reference_campaign_id: gsaiPlayground?.adUnit?.campaign?.id,
                reference_account_id: gsaiPlayground?.adAccount?.adAccountId,
                ai_group_name: gsaiPlayground?.aiGroups?.selected?.name,
                ai_group_id: gsaiPlayground?.aiGroups?.selected?.id,
                ad_account_id:
                  gsaiPlayground?.adAccounts?.selected?.adAccountId,
                ad_account_name: gsaiPlayground?.adAccounts?.selected?.name,
              });
              if (adUnit.variant_id) {
                handleSubmitForReview();
              } else {
                handleCreateDraft();
              }
            }}
          >
            Submit for Review
          </Button>
          {/* <LightTooltip title={isBrandViewer ? brandViewerAccessText : ""}> */}
          {/* <LightTooltip
            // title={isViewerRole(brandRoles) ? brandViewerAccessText : ""}
           
          > */}
          <Button
            buttonVariant="contained"
            className="review-btn"
            onClick={
              // isBrandViewer
              // isViewerRole(brandRoles)
              //   ? undefined
              //   :
              () => {
                handlePublish(isCreate);
                mixPanelAdEvent("CAI GS Variant Published", {
                  module_name: "Google Search Playground",
                  sub_module_name: gsaiPlayground.isCreate
                    ? "Create Variant"
                    : "Refresh Ad",
                  brand_name: selectedBrand?.name,
                  brand_type: selectedBrand?.brandType,
                  brand_id: selectedBrand?.id,
                  agency_name: selectedBrand?.agency_name,
                  agency_id: selectedBrand?.agencyId,
                  reference_ad_id:
                    gsaiPlayground?.adUnit?.adGroupAd?.ad?.id,
                  reference_ad_group_id:
                    gsaiPlayground?.adUnit?.adGroup?.id,
                  reference_campaign_id:
                    gsaiPlayground?.adUnit?.campaign?.id,
                  reference_account_id:
                    gsaiPlayground?.adAccount?.adAccountId,
                  ai_group_name: gsaiPlayground?.aiGroups?.selected?.name,
                  ai_group_id: gsaiPlayground?.aiGroups?.selected?.id,
                  ad_account_id:
                    gsaiPlayground?.adAccounts?.selected?.adAccountId,
                  ad_account_name:
                    gsaiPlayground?.adAccounts?.selected?.name,
                });
              }
            }
            style={{
              // cursor: isBrandViewer ? "not-allowed" : "pointer",
              // cursor: isViewerRole(brandRoles) ? "not-allowed" : "pointer",
              cursor: "pointer",
            }}
          >
            Publish Now
          </Button>
          {/* </LightTooltip> */}
        </StyledRevieButtonContainer>
      </Box>
    </PxDialog>
  );
};

export default ReviewModal;
