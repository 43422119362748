import React, { useState, useContext } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Position, useReactFlow } from "react-flow-renderer";
import { Handle } from "react-flow-renderer";
import { ReactComponent as Continue } from "../../assets/svg/Continue.svg";

import ExploreGS from "./ExploreGS";
import ExploitGS from "./ExploitGS";
import DataRegencyGS from "./DataRegencyGS";
import SourcesGS from "./SourcesGS";
import CustomKeywordGS from "./CustomKeywordGS";
import { useDispatch, useSelector } from "react-redux";
import { TaiContext } from "Pages/TargetingAIPlaygroundBeta/TaiContextProvider";
// import { setAllCalibrationFormData } from '../../../../../store/actions/targetingAI';
import { setGSKeywordTuningData } from "store/actions/gsTargetingAI";
import { setPolling } from "store/actions/targetingAI";
import { TAIGSearchMixPanel } from "../../utils";
import { CommonState } from "store/types/common";
import { AdAccountState } from "store/types/adaccount";

function CalibrationGS({
  data,
  isConnectable,
}: {
  data: any;
  isConnectable: boolean;
}) {
  const [toggleSwitch, setToggleSwitch] = useState(true);
  const dispatch = useDispatch();
  const { setCenter } = useReactFlow();
  const { setNodes, setEdges, checkStepAndComplete } = useContext(
    TaiContext
  ) as any;
  const loaderState = useSelector((state: any) => state.taiCommon.loader);
  const currentPage = useSelector((state: any) => state.taiCommon.currentPage);
  const { GoogleKwsPlannerCheck, CalibrationCustomKeywordsCheck } = useSelector(
    (state: any) => state.gsTargetingAI.gsCalibration.data
  );
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { selectedAgency } = common;
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;
  const gsCalibrationData = useSelector(
    (state: any) => state.gsTargetingAI.gsCalibration.data
  );
  const aiInitiation = useSelector((state: any) => state.aiInitiation.apiData);
  const aiInitiationFormData = useSelector(
    (state: any) => state.aiInitiation.formData
  );

  const handleKWTuningCompetationIndexDefault = () => {
    TAIGSearchMixPanel(
      "TAI-Gsearch Calibration Step Done",
      selectedBrand?.name,
      selectedAgency?.name,
      {
        data_recency: gsCalibrationData?.LookbackPeriod,
        explore_value_check: gsCalibrationData?.ExploreValueCheck,
        google_kws_planner_check: gsCalibrationData?.GoogleKwsPlannerCheck,
        custom_keywords: gsCalibrationData?.CalibrationCustomKeywords,
        calibration_custom_keywords_check:
          gsCalibrationData?.CalibrationCustomKeywordsCheck,
        Platform: "Google search",
        ai_group_id: aiInitiationFormData?.selectedAiGroup?.id,
        ai_group_name: aiInitiationFormData?.selectedAiGroup?.name,
        ad_account_name: aiInitiation?.adAccount?.name,
        ad_account_id: aiInitiation?.adAccount?.id,
        agency_id: selectedAgency.id,
        brand_id: selectedBrand.id,
        brand_type: selectedBrand.brandType,
      }
    );
    if (!GoogleKwsPlannerCheck && !CalibrationCustomKeywordsCheck) {
      dispatch(
        setGSKeywordTuningData({
          monthlyAvgSearchCheck: false,
          competationIndexCheck: false,
        })
      );
    } else {
      dispatch(
        setGSKeywordTuningData({
          monthlyAvgSearchCheck: true,
          competationIndexCheck: true,
        })
      );
    }
  };
  return (
    <Box
      className="node-container-common-style"
      sx={{
        width: "47rem",
        height: "45rem",
        position: "absolute",
        top: "1.25rem",
        left: "1.25rem",
        backgroundColor: "#fff",
      }}
    >
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box sx={{ height: "90%" }}>
          <Grid container sx={{ width: "100%", p: 2 }}>
            <Grid item xs={10}>
              <Typography className="font-16 font-700">Calibration</Typography>
            </Grid>
            <Grid item xs={2} textAlign="end">
              {currentPage > data.index && (
                <Box
                  onClick={() => {
                    // dispatch(setAllCalibrationFormData(currentData));
                    data.nextPage(
                      dispatch,
                      loaderState,
                      setNodes,
                      setEdges,
                      true
                    );
                  }}
                >
                  <Continue />
                </Box>
              )}
            </Grid>
          </Grid>
          <ExploitGS isConnectable={isConnectable} />
          <ExploreGS isConnectable={isConnectable} />
          <DataRegencyGS />
          <SourcesGS />
          <CustomKeywordGS />
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "10%",
            justifySelf: "center",
            alignSelf: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{ mr: 2, display: "flex" }}
            onClick={() => {
              handleKWTuningCompetationIndexDefault();
              checkStepAndComplete([1, 2], data.index, () => {
                // dispatch(setCalibrationData({}));
                // handleKWS();
                dispatch(
                  setPolling({
                    name: "calibrationGS",
                    requestId: Math.random().toString(),
                  })
                );
                data.nextPage(dispatch, loaderState, setNodes, setEdges);
              });
            }}
            style={{
              width: "71.52px",
              height: "32px",
              background: "#0869FB",
              borderRadius: "7.9038px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default CalibrationGS;
