import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useMemo, useState } from "react";
import "../index.scss";
import BudgetPlanTable from "./BudgetPlanTable/BudgetPlanTable";
import {
  StyledHeader,
  LightStyledHeader,
  StackBox,
  StyledBodyContainer,
  Inputlabel,
} from "../styled";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ButtonComponent from "../../../../common/modal/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getParentAiGroupPlannerDetails,
  getParentAigroupPlannerDetailsFake,
  updateParentAiGroupPlanner,
} from "store/actions/ParentAiGroup";
import SuccessModel from "./SuccessModal/SuccessModal";
import {
  getAiGroupPlannerDetails,
  getAiGroupPlannerDetailsFake,
  setAiGroupLoader,
  updateAiGroupPlanner,
} from "../../../../store/actions/BudgetPlannerAiGroup";
import BudgetPacingLoader from "../../loader";
import { setParentAiGroupLoader } from "../../../../store/actions/ParentAiGroup";
import { useHistory } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "assets/svg/calendar.svg";
import { ReactComponent as GraphIcon } from "assets/svg/graph-icon.svg";
import { ReactComponent as EditIcon } from "assets/svg/editIcon.svg";
import { getPacingsDayWise } from "Pages/BudgetPlanner/BudgetPlannerHolder/API/api.getPacingsDayWise";
import { GroupType } from "types/GlobalTypes";
import moment from "moment";
import { ReactComponent as InfoIcon } from "assets/svg/info_grey.svg";
import { calcualteTimeOffset } from "utils/date.util";
import {
  getDecimalValues,
  getSearchParams,
  isViewerRole,
  isEditorRole,
  updateSearchParams,
  getRolesHierarchy,
} from "utils/commonFunctions";
import { AdAccountState } from "store/types/adaccount";
import BudgetConstraintType from "./BudgetConstraintType/BudgetConstraintType";
import SetupChangedModal from "./SetupChangedModal";
import { RolesWrapper } from "utils/rolesWrapper";

const schema = yup
  .object({
    spends: yup.number().positive().required(),
  })
  .required();

function BudgetConstraints({
  isEdit,
  type,
  isAccordionOpen = false,
}: {
  isEdit: boolean;
  type: GroupType.group | GroupType.parentGroup;
  isAccordionOpen?: boolean;
}) {
  const history = useHistory();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    // mode: onBlur,
    resolver: yupResolver(schema),
  });

  const [totalSpends, setTotalSpends] = useState(0);
  const dispatch = useDispatch();
  const [modal, setModal] = useState({ key: "", props: {} });
  // 0 - Default, 1- Manual
  const [configurationType, setConfigurationType] = useState(0);
  const [todayPlannedSpends, setTodayPlannedSpends] = useState(0);
  const [dirty, setDirty] = useState(false);
  const [showBudgetPlannerSection, setShowBudgetPlannerSection] =
    useState(false);
  const [maximumAllowedBudget, setMaximumAllowedBudget] = useState(0);
  const [subPacingType, setSubPacingType] = useState("PERIODIC");
  const { selectedBrand, brandRoles } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { data } = useSelector(
    (state: { ParentAiGroup: any; BudgetPlannerAiGroup: any }) =>
      type === "parentGroup" ? state.ParentAiGroup : state.BudgetPlannerAiGroup
  );

  const groups = type === "parentGroup" ? data.parentAiGroups : data.AiGroups;
  const selectedGroup =
    type === "parentGroup" ? data.selectedParentAiGroup : data.selectedAiGroup;
  const keyName = type === "parentGroup" ? "parentAiGroupId" : "aiGroupId";
  const loader = type === "parentGroup" ? data?.loader : data?.loader;

  const { fields, replace } = useFieldArray({
    control,
    name: "optimizers",
  });
  const [optimizerData, setOptimizerData] = useState([]);
  const [isSetupChanged, setIsSetupChanged] = useState(false);

  const actions =
    type === "parentGroup"
      ? {
          updateAiGroupPlanner: updateParentAiGroupPlanner,
          getPlannerDetails: getParentAiGroupPlannerDetails,
          getFakePlannerDetails: getParentAigroupPlannerDetailsFake,
          setLoader: setParentAiGroupLoader,
        }
      : {
          updateAiGroupPlanner: updateAiGroupPlanner,
          getPlannerDetails: getAiGroupPlannerDetails,
          getFakePlannerDetails: getAiGroupPlannerDetailsFake,
          setLoader: setAiGroupLoader,
        };

  useEffect(() => {
    replace(optimizerData);
  }, [optimizerData]);

  const checkURLParameters = () => {
    if (!getSearchParams("brand_id")) {
      updateSearchParams("brand_id", selectedBrand?.id);
    }
    if (type === "parentGroup") {
      if (!getSearchParams("parent_ai_group")) {
        updateSearchParams("parent_ai_group", selectedGroup);
      }
    } else {
      if (!getSearchParams("group_id")) {
        updateSearchParams("group_id", selectedGroup);
      }
    }
  };

  const onSubmit = (payloaddData: any, e: any) => {
    let spends = Number(payloaddData.spends);
    if (
      maximumAllowedBudget &&
      spends &&
      spends < maximumAllowedBudget &&
      groups[selectedGroup].planners[data?.selectedPlannerId].pacingType ===
        "PERIODIC"
    ) {
      alert(
        `Minimum Budget of ${maximumAllowedBudget} is required because Budget is modified in Budget Planner.`
      );
      setDirty(false);
      return;
    }
    let notSatisfiedMinMaxEmpty = null;
    if (type === "group") {
      notSatisfiedMinMaxEmpty = payloaddData?.optimizers.filter(
        (o: any) =>
          o.subOptimizers?.filter(
            (so: any) =>
              isNaN(parseFloat(so.maxBudget)) || isNaN(parseFloat(so.minBudget))
          ).length
      ).length;
    } else {
      notSatisfiedMinMaxEmpty = payloaddData?.optimizers.filter(
        (o: any) =>
          isNaN(parseFloat(o.maxBudget)) ||
          isNaN(parseFloat(o.minBudget)) ||
          o.subOptimizers?.filter(
            (so: any) =>
              isNaN(parseFloat(so.maxBudget)) || isNaN(parseFloat(so.minBudget))
          ).length
      ).length;
    }

    if (notSatisfiedMinMaxEmpty) {
      // validation error found
      alert("Minimum Budget value or Maximum Budget value can not be empty");
      setDirty(false);
      return;
    }
    let notSatisfied = payloaddData?.optimizers.filter(
      (o: any) => o.maxBudget > spends || o.minBudget > spends
    ).length;
    if (notSatisfied) {
      // validation error found
      alert("Entered Value is greater than Total Daily Spends");
      setDirty(false);
      return;
    }
    let notSatisfiedMinMax = payloaddData?.optimizers.filter(
      (o: any) =>
        o.minBudget > o.maxBudget ||
        o.subOptimizers?.filter((so: any) => so.minBudget > so.maxBudget).length
    ).length;
    if (notSatisfiedMinMax) {
      // validation error found
      alert(
        "Minimum Budget value should not be greater than Maximum Budget Value"
      );
      setDirty(false);
      return;
    }
    if (
      type === "group" &&
      groups[selectedGroup]?.planners[data?.selectedPlannerId]?.status ===
        "SCHEDULE"
    ) {
      let totalMax = payloaddData?.optimizers?.[0]?.subOptimizers
        ?.map((i: any) => i.maxBudget)
        .reduce((a: any, b: any) => a + b);
      let totalMin = payloaddData?.optimizers?.[0]?.subOptimizers
        ?.map((i: any) => i.minBudget)
        .reduce((a: any, b: any) => a + b);
      let totaldays = moment(
        groups[selectedGroup].planners[data?.selectedPlannerId].endDate
      ).diff(
        groups[selectedGroup].planners[data?.selectedPlannerId].startDate,
        "days"
      );
      if (
        groups[selectedGroup].planners[data?.selectedPlannerId].pacingType ===
        "PERIODIC"
      ) {
        if (subPacingType === "PERIODIC") {
          if (totalMax < spends) {
            alert(
              "Sum of Maximum Budget constraints against each asset should be >= Total Planned Spends"
            );
            setDirty(false);
            return;
          }
          if (totalMin > spends) {
            alert(
              "Sum of Minimum Budget constraints against each asset should be <= Total Planned Spends"
            );
            setDirty(false);
            return;
          }
        } else {
          if (totalMax < spends / (totaldays + 1)) {
            alert(
              "Sum of Maximum Budget constraints against each asset should be >= Total Planned Spends/Total number of days of Pacing"
            );
            setDirty(false);
            return;
          }
          if (totalMin > spends / (totaldays + 1)) {
            alert(
              "Sum of Minimum Budget constraints against each asset should be <= Total Planned Spends/Total number of days of Pacing"
            );
            setDirty(false);
            return;
          }
        }
      } else {
        if (totalMax < spends) {
          alert(
            "Sum of Maximum Budget constraints against each asset should be >= Total Daily Spends"
          );
          setDirty(false);
          return;
        }
        if (totalMin > spends) {
          alert(
            "Sum of Minimum Budget constraints against each asset should be <= Total Daily Spends"
          );
          setDirty(false);
          return;
        }
      }
    }

    checkURLParameters();
    if (
      groups[selectedGroup]?.planners[data?.selectedPlannerId]?.status ===
      "SCHEDULE"
    ) {
      dispatch(actions.setLoader(true));
      dispatch(
        actions.updateAiGroupPlanner(
          {
            payload: payloaddData,
            [keyName]: selectedGroup,
            budgetPlannerId: data?.selectedPlannerId,
          },
          (response: any, error: boolean) => {
            if (!error) {
              dispatch(actions.setLoader(false));
              setModal({
                key: "success",
                props: {
                  message:
                    groups[selectedGroup]?.planners[data?.selectedPlannerId]
                      .name + " has been saved",
                },
              });
              history.push(
                "/budget-planner/" +
                  data?.selectedPlannerId +
                  "/" +
                  type +
                  window.location.search
              );
            } else {
              dispatch(actions.setLoader(false));
            }
          }
        )
      );
    } else {
      history.push(
        "/budget-planner/" +
          data?.selectedPlannerId +
          "/" +
          type +
          window.location.search
      );
    }
  };

  useEffect(() => {
    setDirty(false);
    if (data?.selectedPlannerId && selectedGroup) {
      dispatch(
        actions.getPlannerDetails(
          {
            [keyName]: selectedGroup,
            budgetPlannerId: data?.selectedPlannerId,
          },
          (response: any, error: false) => {
            if (!error) {
              setOptimizerData(response.data.optimizers);
              setValue("spends", getDecimalValues(response.data.spends));
              setValue("subPacingType", response.data.subPacingType);
              setSubPacingType(response.data.subPacingType);
              setTotalSpends(getDecimalValues(response.data.spends));
              if (response.data.spends && response.data.spends > 0) {
                setShowBudgetPlannerSection(true);
              } else {
                setShowBudgetPlannerSection(false);
              }
            } else {
              setShowBudgetPlannerSection(false);
            }
          }
        )
      );
    } else {
      getFakeConstraints();
    }
  }, [selectedGroup, data?.selectedPlannerId]);

  const getFakeConstraints = () => {
    setDirty(false);
    if (selectedGroup) {
      dispatch(
        actions.getFakePlannerDetails(
          {
            [keyName]: selectedGroup,
          },
          (response: any, error: false) => {
            if (!error) {
              setOptimizerData(response.data.optimizers);
              setValue("spends", null);
              setTotalSpends(0);
            }
          }
        )
      );
    }
  };

  const getIfPlannerIsActive: boolean = useMemo(() => {
    if (data && data.parentAiGroups && data.selectedPlannerId) {
      let planner =
        data.parentAiGroups[data.selectedParentAiGroup]?.planners?.[
          data.selectedPlannerId
        ];
      return planner?.status === "ACTIVE";
    } else {
      return false;
    }
  }, [data]);

  useEffect(() => {
    if (selectedGroup) {
      let d = new Date();
      let todate = new Date(
        d.setMinutes(
          d.getMinutes() +
            d.getTimezoneOffset() +
            (groups[selectedGroup]?.timeZoneOffSet || -18000) / 60
        )
      );
      let activeTodayDate = moment(todate).format("YYYY-MM-DD");
      getPacingsDayWise(selectedGroup, type).then((resultData: any) => {
        let value =
          resultData.data
            .find((pacing: any) => pacing.status === "ACTIVE")
            ?.dayWiseSplit.find((day: any) => day?.date === activeTodayDate)
            ?.value || 0;
        setTodayPlannedSpends(value);

        resultData.data.map((pacing: any) => {
          if (pacing.id === data?.selectedPlannerId) {
            let totalValue = 0;
            pacing.dayWiseSplit.map((eachDay: any) => {
              if (eachDay.status === "LOCKED") {
                totalValue = totalValue + +eachDay.value;
              }
            });
            setMaximumAllowedBudget(totalValue);
            let findIndex = pacing.dayWiseSplit.findIndex(function (
              daywiseData: any
            ) {
              return daywiseData.status === "LOCKED";
            });
            if (findIndex >= 0) {
              setConfigurationType(1);
            } else {
              setConfigurationType(0);
            }
          }
        });
      });
    }
  }, [selectedGroup]);

  const onConstraintTypeChange = (subPacingType: string) => {
    setDirty(true);
    dispatch(
      actions.getFakePlannerDetails(
        {
          [keyName]: selectedGroup,
        },
        (response: any, error: false) => {
          if (!error) {
            setOptimizerData(response.data.optimizers);
          }
        }
      )
    );
  };

  useEffect(() => {
    if (
      groups?.[selectedGroup]?.planners?.[data?.selectedPlannerId]?.status ===
        "ABORTED" &&
      isAccordionOpen
    ) {
      setTimeout(() => {
        setIsSetupChanged(true);
      }, 0);
    } else {
      setIsSetupChanged(false);
    }
  }, [data?.selectedPlannerId, isAccordionOpen]);

  return (
    <>
      {loader && <BudgetPacingLoader />}
      {type !== "parentGroup" && (
        <SetupChangedModal
          isAccordionOpen={isAccordionOpen}
          isSetupChanged={isSetupChanged}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
        <Box sx={{ height: "90%", overflow: "auto" }}>
          <Box padding={"1.5em 1.65em"}>
            <Box className="mb-3 flex-center justify-content-between">
              <StyledHeader className="text-left">
                Configure Budget Constraints
              </StyledHeader>
              {showBudgetPlannerSection ? (
                <Box className="flex-center">
                  <Box className="mr-2 flex-center constraint-box-shadow">
                    <Typography className="pr-2 budget-constraints-tags border-separator-right">
                      <CalendarIcon className="mr-1 calendar-icon" />
                      Daily Budget Planner
                    </Typography>
                    <Typography className="pl-2 budget-constraints-tags">
                      Configuration Type:{" "}
                      {configurationType ? "Manual" : "Default"}
                    </Typography>
                  </Box>
                  <Box className="mr-3 constraint-box-shadow">
                    <Typography className="budget-constraints-tags">
                      Today’s Planned Spends : {selectedBrand?.currencySymbol}
                      {todayPlannedSpends ? todayPlannedSpends.toFixed(2) : "-"}
                      <GraphIcon className="graph-icon" />
                    </Typography>
                  </Box>
                  <ButtonComponent
                    label="Edit Planner"
                    variant="contained"
                    Icon={EditIcon}
                    disabled={
                      groups?.[selectedGroup]?.planners?.[
                        data?.selectedPlannerId
                      ]?.status === "UNCONFIGURED"
                    }
                    type="submit"
                    sx={{
                      px: 1,
                      ml: 0,
                      py: 1,
                    }}
                  />
                </Box>
              ) : (
                ""
              )}
            </Box>

            <StackBox>
              <LightStyledHeader>
                {groups[selectedGroup]?.name || "<Parent AI Group Name>"}
              </LightStyledHeader>
              <StyledBodyContainer>
                Pacing will now operate at a{" "}
                {type === "parentGroup" ? "Parent" : ""} AI Group level. The
                following constraints will be considered while recommending
                daily budgets for this period. After this scheduled period ends,
                the last action taken will persist on your campaigns.
              </StyledBodyContainer>
            </StackBox>
            <Grid item xs={4}>
              <Box margin={"1.5rem 0"}>
                <Inputlabel className="text-md">
                  Total{" "}
                  {groups[selectedGroup]?.planners?.[data?.selectedPlannerId]
                    ?.pacingType === "DAILY"
                    ? "Daily"
                    : "Planned"}{" "}
                  Spends*
                </Inputlabel>
                <Box className="position-relative planned-spends">
                  <OutlinedInput
                    className={
                      getIfPlannerIsActive || !isEdit
                        ? "section-disable form-control planned-spends-input"
                        : "form-control planned-spends-input"
                    }
                    placeholder="Enter Value"
                    type="number"
                    inputProps={{ step: 0.01, inputMode: "decimal" }}
                    required={true}
                    {...register("spends")}
                    size="small"
                    sx={{
                      "& fieldset": { top: 0 },
                      "& legend": { display: "none" },
                    }}
                    onChange={(e) => {
                      setValue("spends", getDecimalValues(e.target.value));
                      setTotalSpends(getDecimalValues(e.target.value));
                      setDirty(true);
                    }}
                    startAdornment={
                      <InputAdornment
                        className="currency-prefix"
                        position="start"
                        sx={{
                          fontSize: "1.3216125em !important",
                          lineHeight: "1.625em",
                          color: "#616161",
                        }}
                      >
                        {selectedBrand?.currencySymbol}
                      </InputAdornment>
                    }
                    label="Amount"
                  />

                  <Box>{errors?.spends?.message}</Box>
                </Box>
              </Box>
            </Grid>
          </Box>
          <Divider
            sx={{
              borderBottomWidth: "thin",
              borderColor: "#E7E7E7",
              opacity: "1",
            }}
          />
          <Box padding={"1.5em 1.65em"}>
            <StyledHeader className="mb-3 text-left">
              {groups[selectedGroup]?.planners?.[data?.selectedPlannerId]
                ?.pacingType === "DAILY"
                ? type === "group"
                  ? "Platformwise Daily Budget Breakdown"
                  : "AI group daily budget breakdown"
                : type === "group"
                ? "Platformwise Total Budget Breakdown"
                : "AI group Total Budget breakdown"}
            </StyledHeader>
            {groups[selectedGroup]?.planners?.[data?.selectedPlannerId]
              ?.pacingType === "PERIODIC" && (
              <BudgetConstraintType
                control={control}
                subPacingType={subPacingType}
                setSubPacingType={setSubPacingType}
                setValue={setValue}
                status={
                  groups[selectedGroup]?.planners?.[data?.selectedPlannerId]
                    ?.status
                }
                onConstraintTypeChange={onConstraintTypeChange}
              />
            )}

            {optimizerData.length > 0 && (
              <BudgetPlanTable
                isActive={getIfPlannerIsActive}
                isEdit={isEdit}
                fields={fields}
                register={register}
                replace={replace}
                control={control}
                Controller={Controller}
                setDirty={setDirty}
                type={type}
                selectedBrand={selectedBrand}
                subPacingType={subPacingType}
              />
            )}
            <Stack className="mt-3 please-note-text">
              *These are planned budgets
            </Stack>
          </Box>
        </Box>
        <Box
          className={`d-flex justify-content-between align-items-center `}
          sx={{
            height: "8%",
            boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.05)",
            padding: "1.5em 1.625em",
          }}
        >
          <Typography
            style={{
              fontSize: "0.875em",
              lineHeight: "1.0625em",
              fontWeight: "400",
              color: "#7B7B7B",
              display: "flex",
              alignItems: "center",
            }}
          >
            <InfoIcon className="mr-2" />
            This feature is working in{" "}
            <span
              className="ml-1"
              style={{
                fontWeight: "500",
                color: "#000000",
              }}
            >
              {type === GroupType.group
                ? groups[selectedGroup]?.timeZone
                : "EST"}{" "}
              : UTC{" "}
              {type === GroupType.group
                ? calcualteTimeOffset(groups[selectedGroup]?.timeZoneOffSet)
                : "-05:00"}
            </span>
          </Typography>
          <RolesWrapper
            disabled={
              getRolesHierarchy(brandRoles) === "viewer" ||
              getRolesHierarchy(brandRoles) === "editor"
            }
          >
            <ButtonComponent
              label={showBudgetPlannerSection ? "Save" : "Save and Continue"}
              variant="contained"
              type="submit"
              sx={{
                px: 3,
                ml: 0,
              }}
              disabled={
                !dirty ||
                // isViewerRole(brandRoles) ||
                // isEditorRole(brandRoles) ||
                groups?.[selectedGroup]?.planners?.[data?.selectedPlannerId]
                  ?.status === "UNCONFIGURED"
              }
            />
          </RolesWrapper>
        </Box>
        {modal.key === "success" && (
          <SuccessModel
            setModal={setModal}
            modal={modal}
            props={modal.props}
            title={"New Pacing Saved!"}
            key={modal.key}
          />
        )}
      </form>
    </>
  );
}

export default BudgetConstraints;
