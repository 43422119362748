import React, { useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import Checkbox from "@mui/material/Checkbox";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Divider from "@mui/material/Divider";

import CustomChip from "./Chip";
import { amountValue, getCurrencySymbol } from "utils/commonFunctions";
import {
  LIVE_CARD_LABELS,
  LIVE_CARD_SVGS,
  DRAFTED_CARD_LABELS,
  DRAFTED_CARD_SVGS,
} from "../constants/index";
import { Months } from "../../../utils/constants/index";
import { handleAdsmanagerRedirection } from "../utils";
import CohortType from "common/CohortType";
import { genderMapper } from "Pages/TargetingAIPlaygroundBeta/utils";
import type useFacebookTargetingEnumMapping from 'utils/useFacebookTargetingsEnumMapping';
import getDemographicsKeywordsFromFlexibleSpecObject from 'utils/getDemographicsKeywordsFromFlexibleSpecObject';

interface ICardProps {
  checked?: boolean;
  type: string;
  adSetName: string;
  adSetId?: any;
  adAccountName?: string;
  adAccountId?: any;
  publishedDate: string;
  kwsArray: Array<string>;
  metrics: Array<string | number>;
  onClick: any;
  currency?: string | null;
  cohortType?: "Direct" | "merged" | "Insights_Lateral" | "Insights_Direct";
  publishType: "INTEREST" | "ICP";
  icpTargettings: any;
  facebookTargetingEnumMapping: ReturnType<typeof useFacebookTargetingEnumMapping>
}

function IcpProperties({
  label,
  value,
}: {
  label: string;
  value: number | string;
}) {
  return (
    <Box display="flex" width="100%" py={0.5}>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "0.75rem",
          lineHeight: "1.25rem",
          color: "#AEB6C4",
        }}
      >
        {label}:&nbsp;
      </Typography>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "0.75rem",
          lineHeight: "1.25rem",
          color: "#000",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
}

function Card(props: ICardProps) {
  const {
    checked = false,
    type,
    adSetName,
    adAccountName,
    publishedDate,
    metrics,
    kwsArray,
    onClick,
    adSetId,
    adAccountId,
    currency = null,
    cohortType,
    publishType,
    icpTargettings,
    facebookTargetingEnumMapping,
  } = props;
  const checkBoxRef = useRef(null);
  let dataLabels: string[] = [],
    dataSvgs: typeof LIVE_CARD_SVGS | typeof DRAFTED_CARD_SVGS = [];
  if (type === "live") {
    dataLabels = LIVE_CARD_LABELS;
    dataSvgs = LIVE_CARD_SVGS;
  } else {
    dataLabels = DRAFTED_CARD_LABELS;
    dataSvgs = DRAFTED_CARD_SVGS;
  }

  const getDate = () => {
    const publishDate = new Date(publishedDate);
    return (
      publishDate.getDate() +
      " " +
      Months[publishDate.getMonth() + 1] +
      ", " +
      publishDate.getFullYear()
    );
  };
  const renderAmount = (amount: string | number, type: string) => {
    let formatedAmount = amountValue(amount);
    if (
      (type === "Spends" || type === "CPR") &&
      formatedAmount !== "-" &&
      currency
    ) {
      return getCurrencySymbol(currency) + " " + formatedAmount;
    }
    return formatedAmount;
  };

  return (
    <Box
      component="div"
      onClick={(e: any) => {
        onClick(e, checkBoxRef);
      }}
      className="font-inter"
      sx={{
        width: "19.25rem",
        height: "19rem",
        border: "0.06rem solid #CCECFE",
        boxShadow: "0 0.625rem 1.5rem rgba(35, 40, 60, 0.05)",
        borderRadius: "0.5rem",
        background: "#fff",
      }}
    >
      <Stack>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              padding: "0.5rem 0.75rem 0.75rem",
              width: "70%",
              background: publishType === "ICP" ? "#F9F6FF" : "#F1F7FF",
              borderRadius: "0.5rem 0 3.28rem 0",
            }}
          >
            <Tooltip title={adSetName.length > 18 ? adSetName : ""}>
              <Typography
                className={`font-600 font-14 ${
                  adSetName.length > 18 ? "overflow" : ""
                }`}
                sx={{
                  color: publishType === "ICP" ? "#2A0877" : "#0869FB",
                  minWidth: "10.1rem",
                  maxWidth: "10.1rem",
                  marginBottom: "5px",
                }}
              >
                {adSetName}
              </Typography>
            </Tooltip>

            <Tooltip
              title={
                adAccountName && adAccountName.length > 24 ? adAccountName : ""
              }
            >
              <Typography
                className={`font-400 font-10 ${
                  adAccountName && adAccountName.length > 24 ? "overflow" : ""
                }`}
                sx={{ color: "#383838", minWidth: "8rem", maxWidth: "8rem" }}
              >
                {type !== "live" && cohortType && (
                  <CohortType type={cohortType} />
                )}
                {type === "live" && adAccountName && <p>{adAccountName}</p>}
              </Typography>
            </Tooltip>
          </Box>
          <Box
            sx={{
              padding: `${type === "live" ? "0.75rem" : ""}`,
            }}
          >
            {type === "live" ? (
              <Tooltip title="View in Ads Manager">
                <OpenInNewOutlinedIcon
                  onClick={() => {
                    handleAdsmanagerRedirection(adAccountId, adSetId);
                  }}
                  ref={checkBoxRef}
                  sx={{
                    zIndex: "2",
                    color: "#0869FB",
                    background: "#E6F6FF",
                    padding: "0.21rem 0.25rem",
                    borderRadius: "0.25rem",
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                />
              </Tooltip>
            ) : (
              <Checkbox
                ref={checkBoxRef}
                className="cohort-card-checkbox"
                size="small"
                checked={checked}
              />
            )}
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ padding: 1 }}
          alignItems="center"
        >
          <CalendarTodayOutlinedIcon
            fontSize="small"
            sx={{ height: "0.75rem", width: "0.75rem" }}
          />
          <Typography className="font-10 font-400">
            {type === "live" ? "Published On : " : "Saved On : "}
            {getDate()}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
          <Box sx={{ minWidth: "50%", maxWidth: "50%" }}>
            <Stack
              spacing={1.2}
              divider={
                <Divider
                  sx={{
                    border: "0.063rem solid #EFEFEF",
                    marginTop: "0.625rem",
                  }}
                />
              }
            >
              {metrics.map((metric, index) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  key={index}
                >
                  <Box
                    sx={{
                      padding: "0.25rem 0.5rem",
                      background: "#F5FFF7",
                      borderRadius: "0.21rem",
                    }}
                  >
                    {dataSvgs[index]}
                  </Box>
                  <Stack sx={{ margin: "0px 0px 0px 8px" }}>
                    <Typography
                      sx={{ color: "#333" }}
                      className="font-10 font-400"
                    >
                      {dataLabels[index]}
                    </Typography>
                    <Typography
                      title={metric.toString()}
                      sx={{ color: "#00261C" }}
                      className="font-14 font-500"
                    >
                      {renderAmount(metric, dataLabels[index])}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Box>
          <Box
            sx={{
              background: "#F7F7F7",
              borderRadius: 1,
              minWidth: "50%",
              maxWidth: "50%",
              padding: "0.25rem 0 0 0.25rem",
            }}
          >
            {publishType === "ICP" && (
              <Box sx={{ px: 1 }}>
                <IcpProperties
                  label="Age"
                  value={`${icpTargettings.age_min} - ${icpTargettings.age_max} yrs`}
                />
                <IcpProperties
                  label="Gender"
                  value={
                    icpTargettings?.genders?.length > 0
                      ? genderMapper[icpTargettings?.genders]
                      : "ALL"
                  }
                />

                <IcpProperties
                  label="Interests"
                  value={icpTargettings.flexible_spec.reduce(
                    (length: number, spec: { interests: string[] }) =>
                      spec?.interests?.length ?? 0 + length,
                    0
                  )}
                />
                <IcpProperties
                  label="Demographics"
                  value={icpTargettings.flexible_spec.reduce(
                    (length: number, spec: { demographics: string[] }) => {
                      const data = getDemographicsKeywordsFromFlexibleSpecObject(spec, facebookTargetingEnumMapping);
                      return data?.length ?? 0 + length;
                    },
                    0
                  )}
                />
                <IcpProperties
                  label="Behaviour"
                  value={icpTargettings.flexible_spec.reduce(
                    (length: number, spec: { behaviors: string[] }) =>
                      spec?.behaviors?.length ?? 0 + length,
                    0
                  )}
                />
              </Box>
            )}
            {publishType === "INTEREST" && (
              <>
                {Array.isArray(kwsArray) ? (
                  <>
                    <Box sx={{ minHeight: "8rem", overflowX: "hidden", pr: 1 }}>
                      {kwsArray
                        .slice(0, 5)
                        .map((element: string, index: number) => {
                          return <CustomChip label={element} key={index} />;
                        })}
                    </Box>
                    {kwsArray.length > 5 && (
                      <Typography
                        className="font-10 font-500"
                        sx={{
                          color: "#0869FB",
                          float: "right",
                          margin: "0.2rem 0.5rem",
                        }}
                      >
                        +{kwsArray.length - 5}&nbsp;More
                      </Typography>
                    )}
                  </>
                ) : (
                  kwsArray
                )}
              </>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Card;
