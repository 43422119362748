import React, { Fragment } from "react";
import { StyledErrorAction, StyledErrorHeading } from "../../styled";
import SVG from "../../../../../../assets/svg/Danger-Circle.svg";

export default function ErrorComponent() {
  return (
    <Fragment>
      <img
        src={SVG}
        height="50em"
        style={{ marginBottom: "12px" }}
        alt="failed"
      />
      <StyledErrorHeading>Testing Failed</StyledErrorHeading>
      <StyledErrorAction>Click Refresh to re-run the test.</StyledErrorAction>
    </Fragment>
  );
}
