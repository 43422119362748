import {
  GET_ADSETS_FB,
  GET_ADS_FB,
  GET_AD_ACCOUNTS_FB,
  GET_AI_GROUPS,
  GetAIGroups,
  GetAIGroupsPayloadType,
  GetAdAccounts,
  GetAdAccountsPayloadType,
  GetAdSetsFB,
  GetAdSetsPayloadType,
  GetAdsFB,
  GetAdsPayloadType,
  SET_ADSETS_FB,
  SET_ADS_FB,
  SET_AD_ACCOUNTS,
  SET_AI_GROUPS,
  SetAIGroupPayloadType,
  SetAIGroups,
  SetAdAccounts,
  SetAdAccountsPayloadType,
  SetAdSetsFB,
  SetAdSetsPayloadType,
  SetAdsFB,
  SetAdsPayloadType,
  GetFiltersUpdated,
  GET_FILTERS_UPDATED,
  SetFilteredData,
  SET_FILTERED_DATA,
  SetAutogenerateData,
  SET_AUTOGENERATE_DATA,
  SetAutogenerateAdId,
  SET_AUTOGENERATE_AD_ID,
  SetUTGData,
  SET_UTG_DATA,
  SetUTGAdId,
  SET_UTG_AD_ID,
} from "store/types/CreativeAI_Revamp/facebook.types";
import {
  SetAdAccountDetails,
  SET_AD_ACCOUNT_DETAILS,
} from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";

export const getAIGroups = (payload: GetAIGroupsPayloadType): GetAIGroups => ({
  type: GET_AI_GROUPS,
  payload,
});

export const setAIGroups = (payload: SetAIGroupPayloadType): SetAIGroups => ({
  type: SET_AI_GROUPS,
  payload,
});

export const getAdAccounts = (
  payload: GetAdAccountsPayloadType
): GetAdAccounts => ({
  type: GET_AD_ACCOUNTS_FB,
  payload,
});

export const setAdAccounts = (
  payload: SetAdAccountsPayloadType
): SetAdAccounts => ({
  type: SET_AD_ACCOUNTS,
  payload,
});

export const getAdSetsFB = (payload: GetAdSetsPayloadType): GetAdSetsFB => ({
  type: GET_ADSETS_FB,
  payload,
});

export const setAdSetsFB = (payload: SetAdSetsPayloadType): SetAdSetsFB => ({
  type: SET_ADSETS_FB,
  payload,
});

export const getAdsFB = (payload: GetAdsPayloadType): GetAdsFB => ({
  type: GET_ADS_FB,
  payload,
});

export const setAdsFB = (payload: SetAdsPayloadType): SetAdsFB => ({
  type: SET_ADS_FB,
  payload,
});

export const getFiltersUpdated = (payload: any): GetFiltersUpdated => ({
  type: GET_FILTERS_UPDATED,
  payload,
});

export const setFileredData = (payload: any): SetFilteredData => ({
  type: SET_FILTERED_DATA,
  payload,
});

export const setAdAccountDetails = (payload: any): SetAdAccountDetails => ({
  type: SET_AD_ACCOUNT_DETAILS,
  payload,
});

export const setAutogenerateData = (payload: any): SetAutogenerateData => ({
  type: SET_AUTOGENERATE_DATA,
  payload,
});

export const setAutogenerateAdId = (payload: any): SetAutogenerateAdId => ({
  type: SET_AUTOGENERATE_AD_ID,
  payload,
});

export const setUTGData = (payload: any): SetUTGData => ({
  type: SET_UTG_DATA,
  payload,
});

export const setUTGAdId = (payload: any): SetUTGAdId => ({
  type: SET_UTG_AD_ID,
  payload,
});
