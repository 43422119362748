import React, { useEffect, useState } from "react";
import {
  StyledHeaderMessage,
  StyledMainContainer,
  StyledMessageContainer,
  StyledMessageMainContainer,
  StyledSvgContainer,
} from "./styled";
import { ReactComponent as SVG } from "../../../../assets/cai/svg/AdNotSupported.svg";
import { Button } from "components/shared";
import { Box, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "store/types/auth";
import { triggerContactUs } from "store/actions/CreativeAI_Revamp/facebookActions";
import { useCaiFbMixPanel } from "../HomeScreen/hooks/useCaiFbMixPanel";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { CustomizedSnackBars } from "components/shared/AlertSnackBar/AlertSnackBar";
function AdNotSupportedScreen() {
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { contactUs } = useSelector(
    (state: { caiFBReducer: any }) => state.caiFBReducer
  );
  const { adAccountsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const { aiGroupList } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );
  const { selectedAd } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const data = {
    adAccountId: adAccountsSelection?.selectedAdAccountId,
    aiGroupId: aiGroupList?.selectedAIGroup?.id,
    adId: selectedAd?.id,
    adType: selectedAd?.adType,
  };
  const handleSnackBarClose = () => {
    setShowToast(false);
    dispatch({
      type: "TRIGGER_CONTACT",
      payload: { isLoading: false, isFailed: false, message: "" },
    });
  };
  useEffect(() => {
    handleSnackBarClose();
  }, [selectedAd]);

  useEffect(() => {
    if (!contactUs?.isLoading && (contactUs?.message || contactUs?.isFailed)) {
      setShowToast(true);
    }
  }, [contactUs]);

  const { mixPanelContactUsClicked } = useCaiFbMixPanel();
  return (
    <StyledMainContainer>
      <StyledSvgContainer>
        <SVG width="100%" height="100%" />
      </StyledSvgContainer>
      <StyledMessageMainContainer>
        <StyledHeaderMessage style={{ color: "#666666" }}>
          We're sorry,
        </StyledHeaderMessage>
        <StyledHeaderMessage>Your ad isn't supported</StyledHeaderMessage>
        <StyledMessageContainer>
          We do not support this Ad of yours, Contact Support Team for more
          information
        </StyledMessageContainer>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Inter",
            backgroundColor: "#0869FB",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "0.875em",
            lineHeight: "1.43em",
            width: "8.28em",
            height: "2.85em",
            padding: "0.714em 1.43em",
          }}
          disabled={contactUs?.isLoading}
          onClick={() => {
            dispatch(triggerContactUs({ data, user: auth.user }));
            mixPanelContactUsClicked();
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            {contactUs?.isLoading ? (
              <CircularProgress
                size={25}
                thickness={5}
                sx={{ color: "#0869FB" }}
              />
            ) : (
              "Contact Us"
            )}
          </Box>
        </Button>
        <CustomizedSnackBars
          handleClose={handleSnackBarClose}
          open={showToast}
          severity={contactUs?.isFailed ? "error" : "success"}
          msg={
            contactUs?.isFailed
              ? "Failed to send mail"
              : "Kindly wait, we have been notified"
          }
        />
      </StyledMessageMainContainer>
    </StyledMainContainer>
  );
}

export default AdNotSupportedScreen;
