export const options = () => {
  let isPreProd = window.origin.includes("app-pre-prod");
  return {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ["bam.eu01.nr-data.net"] },
    },
    loader_config: {
      accountID: process.env.REACT_APP_NEWRELIC_ACCOUNT_ID,
      trustKey: process.env.REACT_APP_NEWRELIC_TRUST_KEY,
      agentID: isPreProd
        ? process.env.REACT_APP_NEWRELIC_PREPROD_AGENT_ID
        : process.env.REACT_APP_NEWRELIC_AGENT_ID,
      licenseKey: process.env.REACT_APP_NEWRELIC_LICENCE_KEY,
      applicationID: isPreProd
        ? process.env.REACT_APP_NEWRELIC_PREPROD_APPLICATION_ID
        : process.env.REACT_APP_NEWRELIC_APPLICATION_ID,
    },
    info: {
      beacon: "bam.eu01.nr-data.net",
      errorBeacon: "bam.eu01.nr-data.net",
      licenseKey: process.env.REACT_APP_NEWRELIC_LICENCE_KEY,
      applicationID: isPreProd
        ? process.env.REACT_APP_NEWRELIC_PREPROD_APPLICATION_ID
        : process.env.REACT_APP_NEWRELIC_APPLICATION_ID,
      sa: 1,
    },
  };
};
