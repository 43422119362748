import { useRef } from "react";
import tipImage from "../../../../../assets/svg/smallinfo.svg";
import { StyledContent, StyledToolTipContainer, StyledIImage } from "./styled";

const TooltipWithInfo: React.FC<{
  info: any;
  top?: string;
  left?: any;
  right?: any;
  bottom?: any;
}> = ({ info, top, left }) => {
  const target = useRef(null);

  return (
    <StyledToolTipContainer className="">
      {/* <i ref={target}> */}
      <StyledIImage
        ref={target}
        className="ml-1"
        src={tipImage}
        alt="info_style"
      />
      {/* </i> */}
      <StyledContent
        top={top}
        left={left}
        className="content"
        style={{ zIndex: 100000 }}
      >
        <div className="" style={{ zIndex: 100000 }}>
          {info}
        </div>
      </StyledContent>
    </StyledToolTipContainer>
  );
};

export default TooltipWithInfo;
