import { Control, Controller, FieldValues } from "react-hook-form";
import TimeFrameInput from "./TimeFrameInput";

interface ChoiceButtonFormControllerProps {
  control: Control<FieldValues, object>;
  registeredName: string;
  required?: boolean;
}

export interface Valuetype {
  minutes: number;
  seconds: number;
}

export default function TimeFrameInputController({
  control,
  registeredName,
  required,
}: ChoiceButtonFormControllerProps) {
  return (
    <Controller
      control={control}
      name={registeredName}
      defaultValue={{
        minutes: 0,
        seconds: 0,
      }}
      rules={{
        required,
      }}
      render={({
        field: { onChange, value },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => <TimeFrameInput onChange={onChange} value={value} />}
    />
  );
}
