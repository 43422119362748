import CollapsibleVariantsPreview from "./CollapsibleVariantsPreview/CollapsibleVariantsPreview";
import LogScreenFilterBox from "./LogScreenFilterBox/LogScreenFilterBox";
import LogScreenTable from "./LogScreenTable/LogScreenTable";
import { useLogScreen } from "./hooks/useLogScreen";
import {
  StyledLogScreenBodyBox,
  StyledLogScreenBox,
  StyledLogScreenTable,
  StyledTableTitleBar,
  StyledTableTitleBox,
} from "./styled";
import { ReactComponent as TimeCircle } from "../../../../assets/cai/svg/time_circle.svg";
import { ReactComponent as Download } from "../../../../assets/cai/svg/download.svg";
import { StyledText } from "components/shared";
import { IconButton, Skeleton } from "@mui/material";
import { useHandlePreview } from "./hooks/useHandlePreview";
import { memo, useState } from "react";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { useSelector } from "react-redux";
import { CustomizedSnackBars } from "components/shared/AlertSnackBar/AlertSnackBar";

function LogScreen({ selectedTabValue }: { selectedTabValue: "home" | "log" }) {
  const {
    handleDownload,
    isLogDownloading,
    fetchActivityLog,
    showToast,
    handleSnackBarClose,
    ...props
  } = useLogScreen(selectedTabValue);
  const { handleItemClick, ...previewProps } = useHandlePreview();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { adsListLog } = useSelector(
    (state: { fbActivityLogReducer: any }) => state.fbActivityLogReducer
  );
  const { adAccountList, adSetList } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );
  const toggleDrawer = (value?: boolean) => {
    setDrawerOpen((drawerOpen: boolean) => value ?? !drawerOpen);
  };
  return (
    <StyledLogScreenBox id="log" hidden={selectedTabValue !== "log"}>
      <LogScreenFilterBox {...props} />
      <StyledLogScreenBodyBox>
        <StyledLogScreenTable>
          <StyledTableTitleBar>
            <StyledTableTitleBox>
              <TimeCircle width="1em" height="1em" />
              &nbsp;<StyledText lineHeight="1.5em">Activity Log</StyledText>
            </StyledTableTitleBox>
            {isLogDownloading ||
            adAccountList?.isLoading ||
            adSetList.isLoading ||
            adsListLog?.isLoading ? (
              <Skeleton
                variant="circular"
                height="1.8em"
                width="2em"
                sx={{
                  borderRadius: "4px",
                }}
              />
            ) : (
              <IconButton onClick={handleDownload}>
                <Download width="1em" height="1em" />
              </IconButton>
            )}
          </StyledTableTitleBar>
          <LogScreenTable
            handleItemClick={handleItemClick}
            fetchActivityLog={fetchActivityLog}
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            selectedAdData={previewProps.selectedAdData}
            selectedAdIndex={previewProps.selectedAdIndex}
          />
        </StyledLogScreenTable>
        <CollapsibleVariantsPreview
          {...previewProps}
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
        />
      </StyledLogScreenBodyBox>
      <CustomizedSnackBars
        handleClose={handleSnackBarClose}
        open={showToast}
        msg="Sorry! there are no logs to download"
        severity="error"
      />
    </StyledLogScreenBox>
  );
}

export const MemoizedLogScreen = memo(LogScreen);
