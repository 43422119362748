import { ReactComponent as VideoSvg1 } from "../assets/youtube/VideoSvg1.svg";
import { ReactComponent as VideoSvg2 } from "../assets/youtube/VideoSvg2.svg";
import { ReactComponent as VideoSvg3 } from "../assets/youtube/VideoSvg3.svg";

export const VIDEO_CARD_SVGS = [<VideoSvg1 />, <VideoSvg2 />, <VideoSvg3 />];

export const VIDEO_CARD_LABELS = [
  "Relevance Score",
  "Total Videos",
  "View Count",
];

export const CHANNEL_CARD_LABELS = [
  "Relevance Score",
  "Total Subscribers",
  "Total Videos & Views",
];

export const AUDIENCE_CARD_LABELS = ["Relevance Score", "Parent Segment"];

export const CLUS_TYPE = {
  Affinity: {
    background: "#FDEFF7",
    textColor: "#EF64B3",
    borderColor: "#F9C1E1",
    name: "Affinity",
  },
  "In Market": {
    background: "#EEF6F6",
    textColor: "#55A8A0",
    borderColor: "#BBDCD9",
    name: "In Market",
  },
  "In-Market Audiences": {
    background: "#EEF6F6",
    textColor: "#55A8A0",
    borderColor: "#BBDCD9",
    name: "In Market",
  },
  "Life Events": {
    background: "#FEF3EA",
    textColor: "#F8862D",
    borderColor: "#FDDBC0",
    name: "Life Events",
  },
  "Affinity Audiences": {
    background: "#FDEFF7",
    textColor: "#EF64B3",
    borderColor: "#F9C1E1",
    name: "Affinity",
  },
};

export const TABSHEADING = [{ value: "summary", label: "Summary" }];
