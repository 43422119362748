import React from "react";
import { Box, Modal, Typography, Stack, Button } from "@mui/material";
import { ReactComponent as Success } from "../../../assets/tai/success.svg";
import { ReactComponent as KeepExploring } from "../../../assets/tai/keepExploringIcon.svg";

interface ISuccessModalProps {
  startAgainClick?: React.MouseEventHandler<any>;
  keepExploringClick?: React.MouseEventHandler<any>;
  handleOutsideClick?: React.MouseEventHandler<any>;
  mainText?: string;
  subText?: string;
  buttonText?: string;
  secondaryButtonText?: string;
}

function AudienceManagerSuccessModal(props: ISuccessModalProps) {
  const {
    startAgainClick = undefined,
    keepExploringClick = undefined,
    handleOutsideClick = undefined,
    mainText = "Success",
    subText = "Audiences are published",
    buttonText = "Start Again",
    secondaryButtonText = "Keep Exploring",
  } = props;
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "24.25rem",
    height: "25.8rem",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "0.5rem",
  };
  return (
    <Modal
      open={true}
      onClose={handleOutsideClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Stack sx={style} alignItems="center">
          <Success />
          <Typography
            className="font-30 font-weight-700 font-lato"
            sx={{ color: "#333" }}
          >
            {mainText}
          </Typography>
          <Typography
            paddingTop={2}
            paddingBottom={6}
            className="font-14 font-weight-500"
            sx={{ color: "#727272" }}
          >
            {subText}
          </Typography>
          <Button
            onClick={startAgainClick}
            disableRipple
            variant="contained"
            className="font-14 font-600"
            sx={{
              color: "#FFF",
              width: "11.75rem",
              backgroundColor: "#0869FB",
              boxShadow: "none",
              border: "none",
              borderRadius: "0.5rem",
              textTransform: "none",
            }}
          >
            {buttonText}
          </Button>
          <Box
            onClick={keepExploringClick}
            marginTop={3}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              height: "1.25rem",
            }}
          >
            <KeepExploring />
            <Typography
              paddingX={1}
              className="font-14 font-600"
              sx={{ color: "#0869FB" }}
            >
              {secondaryButtonText}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}

export default AudienceManagerSuccessModal;
