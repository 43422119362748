import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/close.svg";
import { Divider, IconButton } from "@mui/material";
import ButtonComponent from "../ButtonComponent";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 700,
  backgroundColor: "white",
  boxShadow: 24,
  borderRadius: "8px",
};

const BasicModal: React.FC<{
  title: string;
  children: any;
  open: boolean;
  setOpen: any;
  showButton?: boolean;
  buttonProps?: any;
  formProps?: any;
  additionalStyle?: any;
}> = ({
  title,
  children,
  showButton = true,
  open,
  setOpen,
  buttonProps,
  formProps,
  additionalStyle = {},
}) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <form>
        <Stack sx={{ ...style, ...additionalStyle }}>
          <Box sx={{ height: "10%" }}>
            <Typography variant="h6" component="h2">
              <Box
                className="d-flex justify-content-between"
                sx={{
                  h: 9,
                  fontWeight: "700",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                  borderRadius: "8px 8px 0px 0px",
                  padding: "22px 25px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Lato",
                    fontSize: "20px",
                  }}
                >
                  {title}
                </span>
                <IconButton
                  sx={{
                    w: 5,
                    h: 6,
                    background: "#F2F2F2",
                    borderRadius: "0.25rem",
                    textAlign: "center",
                  }}
                  color="primary"
                  aria-label="Close"
                  component="label"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Typography>
          </Box>
          <Box sx={{ height: "80%", mb: 2 }}>
            <Typography
              sx={{
                height: "100%",
                px: 4,
                mt: 2,
                pb: 4,
                overflowY: "scroll",
              }}
              component="div"
            >
              {children}
            </Typography>
          </Box>

          {showButton && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                height: "10%",
                pr: 2,
                py: 1,
                m: 0,
                borderTop: "1px solid #F0F0F0",
                background: "#fff",
                zIndex: 999,
              }}
            >
              <ButtonComponent
                variant="contained"
                label="Submit"
                sx={{
                  px: 3,
                  "& span": { fontSize: "16px" },
                }}
                {...buttonProps}
              />
            </Box>
          )}
        </Stack>
      </form>
    </Modal>
  );
};

export default BasicModal;
