export const filterVariantData = (leversData: any, arr: Array<string>) => {
  let updatedData = leversData.map((it: any, i: number) => {
    return {
      ...it,
      levers: it.levers.map((lever: any) => {
        return {
          ...lever,
          fields: lever.fields.filter((field: any) =>
            arr.includes(field.fetch_value_from)
          ),
        };
      }),
    };
  });
  return updatedData;
};

export const filterLevers = (leversData: any, arr: Array<string>) => {
  let updatedLeversData: any = [];
  leversData.forEach((generationItem: any) => {
    let levers: any = [];
    generationItem.levers.forEach((lever: any) => {
      const fields = lever.fields.filter(
        (field: any) =>
          arr.includes(field.fetch_value_from) &&
          field.is_visible_in_UI === true
      );
      if (fields.length) {
        levers.push({
          ...lever,
          fields,
        });
      }
    });
    updatedLeversData.push({
      ...generationItem,
      levers,
    });
  });
  return updatedLeversData;
};
const checkValidationLeverLevel = (leverData: any) => {
  if (leverData && Object.keys(leverData).length) {
    for (const item in leverData) {
      if (leverData[item]?.isChecked) {
        return true;
      }
    }
    return false;
  }
  return false;
};
export const validateLeverSelection = (data: any) => {
  return !(
    checkValidationLeverLevel(data?.primary_text) ||
    checkValidationLeverLevel(data?.headline) ||
    checkValidationLeverLevel(data?.description)
  );
};

// filterVariantData(leversDataArray, ["ad", "super_user"]);
