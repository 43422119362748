import { ReactComponent as Spends } from "../assets/spends.svg";
import { ReactComponent as Results } from "../assets/results.svg";
import { ReactComponent as CPR } from "../assets/cpr.svg";
import { ReactComponent as RelevanceScore } from "../assets/relevanceScore.svg";
import { ReactComponent as PotentialAudience } from "../assets/potentialAudience.svg";
import { AgencyAssets } from "store/types/common";
import { ISetDraftedCohortsFilter, ISetPublishedCohortsFilter, SetDraftedCohortsFilters, SetRecommendedCohortsFilters } from "store/types/AudienceManager";
export const TABSHEADING = [
  { value: "summary", label: "Summary" },
  { value: "configuration", label: "Configuration" },
];

export const LIVE_CARD_LABELS = ["Spends", "Results", "CPR"];
export const DRAFTED_CARD_LABELS = ["Potential Audience", "Relevance Score"];

export const LIVE_CARD_SVGS = [<Spends />, <Results />, <CPR />];
export const DRAFTED_CARD_SVGS = [<PotentialAudience />, <RelevanceScore />];

export const MIN_AUDIENCE_SIZE = 100;
export const MIN_RELEVANCE_SCORE = 0;
export const MAX_RELEVANCE_SCORE = 1;

type Keyword = {
  id: string;
  name: string;
};

type Summary = {
  saved_on: string;
  potential_audience_size: number;
  relevance_score: number;
  interest_keywords: Keyword[];
};

type Configuration = {
  userInputBrandName: string;
  userInputBrandWebsite: string;
  explore: number;
  exploit: number;
  brand_website_config: number;
  competitor_website: number;
  intent: number;
  traffic: number;
  custom_keywords: Keyword[];
  icp_context_description: string | null;
};

export type CohortDetails = {
  id: string;
  cohort_id: string;
  cohort_name: string;
  cohort_type: string;
  saved_on: string;
  potential_audience_size: number;
  relevance_score: number;
  keywords: Keyword[];
  type: string;
  icp_targeting: string | null;
  isDrafted: boolean;
  isRecommended: boolean;
  brand_id: string;
  brand_name: string;
  brand_website: string | null;
  summary: Summary;
  configuration: Configuration;
};

export type TDetailsPayload = {
  aiGroupId: string;
  date_range: {
      start: Date;
      end: Date;
  };
  brand_name: string;
  brand_website: string;
}

export type TFiltersPayload ={
  aiGroupId: string;
}
export type TSetDraftedCohortsFilters =  (
  payload: ISetDraftedCohortsFilter
) => SetDraftedCohortsFilters

export type TSetRecommendedCohortsFilters =  (
  payload: ISetPublishedCohortsFilter
) => SetRecommendedCohortsFilters

export const getNoDataProps = (agencyAssets: AgencyAssets) => ({
  live: {
    heading: "You haven't Published any cohort yet",
    subHeading:
      "Start publishing cohorts, click on create new audience to start generating cohorts",
  },
  drafted: {
    heading: "You haven't Saved any cohort yet",
    subHeading:
      "Start saving cohorts, click on create new audience to start generating cohorts",
  },
  recommended: {
    heading: `${agencyAssets?.title || 'Pixis'} hasn't recommended any cohort yet`,
    subHeading: "Click on create new audience to start generating cohorts",
  },
});

export const COHORT_TYPES = ["Direct (Knowledge Graph)", "Insights Direct", "Insights Lateral", "Direct", "Lateral", "Merged", "Insights Driven"];

export const NUMBER_OF_MONTHS = 6;
export const BRAND_NAME_SORT_DEFAULT = "all";
export const BRAND_WEBSITE_SORT_DEFAULT = "all";
export const SELECTED_COHORT_TYPES = "all";
export const YOUTUBE_SUB_TABS = ["Videos", "Channels", "Audience Segments"];
export const YOUTUBE_SUB_TAB_VALUES = ["video", "channel", "audience"];