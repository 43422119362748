import {
  SetSelectedMonthYear,
  SET_SELECTED_MONTH_YEAR,
  GetAllPlatformsSmb,
  GET_ALL_PLATFORMS_SMB,
  SetAllPlatformsSmb,
  SET_ALL_PLATFORMS_SMB,
  SetSelectedOptimizerSmb,
  SET_SELECTED_OPTIMIZER_SMB,
  GetAgencyDetialByBrand,
  GET_AGENCY_DETAIL_BY_BRAND,
} from "../../types/SmbDashboard/common";

export const setSelectedMonthYear = (payload: any): SetSelectedMonthYear => ({
  type: SET_SELECTED_MONTH_YEAR,
  payload,
});

export const getAllPlatforms = (): GetAllPlatformsSmb => ({
  type: GET_ALL_PLATFORMS_SMB,
});

export const setAllPlatforms = (payload: any): SetAllPlatformsSmb => ({
  type: SET_ALL_PLATFORMS_SMB,
  payload,
});

export const setSelectedOptimizer = (
  payload: any
): SetSelectedOptimizerSmb => ({
  type: SET_SELECTED_OPTIMIZER_SMB,
  payload,
});

export const getAgencyDetialByBrand = (
  brandId: string,
  callback: Function
): GetAgencyDetialByBrand => ({
  type: GET_AGENCY_DETAIL_BY_BRAND,
  payload: {
    brandId,
  },
  callback,
});
