import {
  CreateImageAPIHashResponse,
  GetMediaStudioForAdResponse,
  GetMediaStudioProcessingReduxImage,
  GET_MEDIA_STUDIO_FOR_AD_RESPONSE,
  GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
  InitialMediaStudioType,
  SetMediaStudioRequestStatus,
  SET_MEDIA_STUDIO_REQUEST_STATUS,
  MediaStudioImageProcessingFailure,
  MediaStudioImageProcessingLoader,
  MediaStudioVideoProcessingFailure,
  MediaStudioVideoProcessingLoader,
  MEDIA_STUDIO_IMAGE_PROCESSING_FAILURE,
  MEDIA_STUDIO_IMAGE_PROCESSING_LOADER,
  MEDIA_STUDIO_VIDEO_PROCESSING_FAILURE,
  MEDIA_STUDIO_VIDEO_PROCESSING_LOADER,
  PostMediaStudioProcessingReduxImage,
  PostMediaStudioProcessingReduxVideo,
  POST_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
  POST_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO,
  SetImageVGSTimer,
  SET_IMAGE_VGS_TIMER,
  IMAGE_PREVIEW_URL,
  SetImagePreviewUrl,
  GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO,
  GetMediaStudioProcessingReduxVideo,
  POST_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE,
  MEDIA_STUDIO_BUNDLE_PROCESSIONG_FAILURE,
  GET_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE,
  PostMediaStudioProcessingReduxBundle,
  GetMediaStudioProcessingReduxBundle,
  MediaStudioBundleProcessingFailure,
  MEDIA_STUDIO_BUNDLE_PROCESSIONG_LOADING,
  MediaStudioBundleProcessingLoader,
  SetFrameThumbnailVGSData,
  GET_FRAME_VGS_REQUEST_ID_REDUX,
  GET_FRAME_VGS_REQUEST_ID_LOADER,
  GET_FRAME_VGS_REQUEST_ID_FAILURE,
  SET_VIDEO_VGS_TIMER,
  SET_FRAME_THUMBNAIL_VGS_DATA_REDUX,
  SET_FRAME_THUMBNAIL_DATA,
  SET_FRAME_VGS_DATA,
  RESTORE_INITIAL_MEDIA,
  MEDIA_STUDIO_IMAGE_PROCESSING_SUCCESS,
  GET_MEDIA_STUDIO_FOR_AD,
} from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";

const initialState: InitialMediaStudioType = {
  imageHash: [],
  reqImageIds: [],
  frameVGSReqId: [],
  mediaStudioRequestStatus: false,
  reqVideoIds: [],
  isImageMediaStudioProcessing: false,
  isVideoMediaStudioProcessing: false,
  isVideoMediaStudioProcessingFailure: false,
  isImageMediaStudioProcessingFailure: false,
  imageVgsTimer: null,
  reqBundleIds: [],
  videoVgsTimer: null,
  videoFrameRelatedData: {},
  videoVGSFrame: {},
  frameVGSReqIdLoading: false,
  frameVGSReqIdFailure: false,
  isImageProcessedSuccessfully: false,
};

export const mediaStudioReducer = (
  state = initialState,
  action:
    | CreateImageAPIHashResponse
    | SetImagePreviewUrl
    | GetMediaStudioForAdResponse
    | SetMediaStudioRequestStatus
    | MediaStudioImageProcessingLoader
    | MediaStudioVideoProcessingLoader
    | MediaStudioVideoProcessingFailure
    | MediaStudioImageProcessingFailure
    | PostMediaStudioProcessingReduxImage
    | GetMediaStudioProcessingReduxImage
    | SetImageVGSTimer
    | PostMediaStudioProcessingReduxVideo
    | GetMediaStudioProcessingReduxVideo
    | PostMediaStudioProcessingReduxBundle
    | GetMediaStudioProcessingReduxBundle
    | MediaStudioBundleProcessingFailure
    | MediaStudioBundleProcessingLoader
    | SetFrameThumbnailVGSData
    | any //will remove once get padding
) => {
  switch (action.type) {
    case GET_MEDIA_STUDIO_FOR_AD: {
      return {
        ...state,
        getMediaStudioData: {
          ...state.getMediaStudioData,
          isLoading: true,
        },
      };
    }
    case GET_MEDIA_STUDIO_FOR_AD_RESPONSE: {
      return {
        ...state,
        getMediaStudioData: action.payload,
      };
    }
    case SET_FRAME_THUMBNAIL_VGS_DATA_REDUX: {
      const localVideoVGSFrame = { ...state.videoVGSFrame };
      if (state.selectedFrameIndex !== undefined) {
        localVideoVGSFrame[state.selectedFrameIndex] = {
          isEditing: false,
          vgsPreviewUrl: action.payload,
        };
      }

      return {
        ...state,
        videoVGSFrame: localVideoVGSFrame,
      };
    }

    case SET_FRAME_THUMBNAIL_DATA: {
      const localVideoFrameRelatedData = { ...state.videoFrameRelatedData };

      if (localVideoFrameRelatedData[action.payload.index]) {
        localVideoFrameRelatedData[action.payload.index] = {
          ...localVideoFrameRelatedData[action.payload.index],
          ...action.payload.data,
        };
      }
      localVideoFrameRelatedData[action.payload.index] = action.payload.data;

      return {
        ...state,
        videoFrameRelatedData: localVideoFrameRelatedData,
      };
    }

    case SET_FRAME_VGS_DATA: {
      const localVideoVGSFrame = { ...state.videoVGSFrame };
      let payload = action.payload.data;
      if (localVideoVGSFrame[action.payload.index]) {
        payload = {
          ...localVideoVGSFrame[action.payload.index],
          ...payload,
        };
      }
      localVideoVGSFrame[action.payload.index] = payload;
      return {
        ...state,
        videoVGSFrame: localVideoVGSFrame,
      };
    }

    case MEDIA_STUDIO_IMAGE_PROCESSING_LOADER: {
      return {
        ...state,
        isImageMediaStudioProcessing: action.payload,
      };
    }
    case MEDIA_STUDIO_IMAGE_PROCESSING_SUCCESS: {
      return {
        ...state,
        isImageProcessedSuccessfully: action.payload,
      };
    }
    case MEDIA_STUDIO_VIDEO_PROCESSING_LOADER: {
      return {
        ...state,
        isVideoMediaStudioProcessing: action.payload,
      };
    }
    case MEDIA_STUDIO_BUNDLE_PROCESSIONG_LOADING: {
      return {
        ...state,
        isBundleMediaStudioProcessing: action.payload,
      };
    }
    case MEDIA_STUDIO_VIDEO_PROCESSING_FAILURE: {
      return {
        ...state,
        isVideoMediaStudioProcessingFailure: action.payload,
      };
    }
    case MEDIA_STUDIO_IMAGE_PROCESSING_FAILURE: {
      return {
        ...state,
        isImageMediaStudioProcessingFailure: action.payload,
      };
    }
    case MEDIA_STUDIO_BUNDLE_PROCESSIONG_FAILURE: {
      return {
        ...state,
        isBundleMediaStudioProcessingFailure: action.payload,
      };
    }
    case POST_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE: {
      return {
        ...state,
        reqImageIds: action.payload,
      };
    }
    case POST_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO: {
      return {
        ...state,
        reqVideoIds: action.payload,
      };
    }
    case POST_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE: {
      return {
        ...state,
        reqBundleIds: [...state.reqBundleIds, ...action.payload],
      };
    }
    case SET_IMAGE_VGS_TIMER: {
      return {
        ...state,
        imageVgsTimer: action.payload,
      };
    }

    case SET_VIDEO_VGS_TIMER: {
      return {
        ...state,
        videoVgsTimer: action.payload,
      };
    }

    case GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE: {
      return {
        ...state,
        imageContext: action.payload,
      };
    }

    case GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO: {
      return {
        ...state,
        videoContext: action.payload,
      };
    }
    case GET_MEDIA_STUDIO_PROCESSING_REDUX_BUNDLE: {
      return {
        ...state,
        bundleContext: action.payload,
      };
    }
    case SET_MEDIA_STUDIO_REQUEST_STATUS: {
      return {
        ...state,
        mediaStudioRequestStatus: action.payload,
      };
    }
    case IMAGE_PREVIEW_URL: {
      return {
        ...state,
        imagePreviewUrl: action.payload,
      };
    }

    case GET_FRAME_VGS_REQUEST_ID_REDUX: {
      const { frameIndex, ids } = action.payload;
      return {
        ...state,
        selectedFrameIndex: frameIndex,
        frameVGSReqId: ids,
      };
    }

    case GET_FRAME_VGS_REQUEST_ID_LOADER: {
      return {
        ...state,
        frameVGSReqIdLoading: action.payload,
      };
    }

    case GET_FRAME_VGS_REQUEST_ID_FAILURE: {
      return {
        ...state,
        frameVGSReqIdFailure: action.payload,
      };
    }

    case RESTORE_INITIAL_MEDIA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
