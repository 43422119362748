import React, { useContext, useEffect, useMemo, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ReactComponent as Close } from "../assests/close.svg";
import { KeywordContext } from "../KeywordContextProvider";
import { useSelector, useDispatch } from "react-redux";

import { AdAccountState } from "../../../../../../store/types/adaccount";
import { storeKeywords } from "../../../../../../store/actions/targetingAI";
import { TaiContext } from "../../../../TaiContextProvider";
import {
  BUCKET_NAMES,
  SEARCH_METRIC_NAME,
} from "../../../constants/KeywordExplorer";
import { LightTooltip } from "components/CreativeAI_Revamp/shared";
import { brandViewerAccessText } from "utils/constants/commonConsts";
import { TAIGSearchMixPanel } from "Pages/TargetingAIPlaygroundBeta/GoogleSearch/utils";
import { CommonState } from "store/types/common";
import {
  getRolesHierarchy,
  isEditorRole,
  isViewerRole,
} from "utils/commonFunctions";
import { RolesWrapper } from "utils/rolesWrapper";

const Strip: React.FC<{ type: string }> = ({ type, children }) => {
  const types: any = {
    negative_kw: {
      background: "#FFF4F4",
      border: "0.749479px solid #F98181",
      borderRadius: 1,
      color: "#F98181",
    },
    positive_kw: {
      background: "rgba(8, 105, 251, 0.1)",
      borderRadius: 2,
      color: "#0869FB",
    },
    kw_ideas: {
      background: "rgba(8, 105, 251, 0.1)",
      borderRadius: 2,
      color: "#0869FB",
    },
  };
  return (
    <Box
      sx={{
        maxHeight: "2.25rem",
        p: 1,
        ...types[type],
        borderRadius: 2,
        lineHeight: 1,
        width: "fit-content",
        mr: 1,
        mb: 1,
      }}
    >
      {children}
    </Box>
  );
};

const Publish: React.FC<{ publishOpen: boolean; setPublishOpen: Function }> = ({
  publishOpen,
  setPublishOpen,
}) => {
  const { getDisplayObjectForPublish, getPublishPayload } =
    useContext(KeywordContext);
  const [keywordIdeas, positive, negetive] = BUCKET_NAMES;

  const [displayObject, setDisplayObject] = useState({}) as any;
  const aiInitiationData = useSelector(
    (state: any) => state.aiInitiation.apiData
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { selectedAgency } = common;
  const { setPublishModalData, setShowPublishModal, changePublishModal } =
    useContext(TaiContext) as any;
  const { selectedBrand, brandRoles } = adaccount;
  // const isBrandViewer = useMemo(
  //   () => brandRoles?.length === 1 && brandRoles[0]?.role === "brand-viewer",
  //   [brandRoles]
  // );
  const aiInitiationFormData = useSelector(
    (state: any) => state.aiInitiation.formData
  );

  const dispatch = useDispatch();
  const onPublishClick = () => {
    const payload = {
      ad_account_id: aiInitiationData.adAccount.adAccountId,
      brand_name: selectedBrand.name,
      brand_id: selectedBrand.id,
      ai_group_id: aiInitiationData.aiGroup.id,
      ai_group_name: aiInitiationData.aiGroup.name,
      ad_account_name: aiInitiationData.adAccount.name,
    };
    const keywords = getPublishPayload();
    const data = {
      ...payload,
      ...keywords,
    };
    dispatch(
      storeKeywords(
        {
          validate: true,
          data,
        },
        (res: any, err: any) => {
          if (!err) {
            dispatch(
              storeKeywords(
                {
                  validate: false,
                  data,
                },
                (res: any, err: any) => {
                  if (!err) {
                    TAIGSearchMixPanel(
                      "TAI-Gsearch Cohorts Published",
                      selectedBrand?.name,
                      selectedAgency?.name,
                      {
                        ...res?.data,
                        keywords: keywords,
                        number_of_keywords:
                          keywords?.negative_kn?.length ??
                          0 + keywords?.positive_kn?.length ??
                          0,
                        ad_account_id: aiInitiationData.adAccount.adAccountId,
                        ad_account_name: aiInitiationData.adAccount.name,
                        ai_group_id: aiInitiationData?.aiGroup?.id,
                        ai_group_name: aiInitiationData?.aiGroup?.name,
                        agency_id: selectedAgency.id,
                        brand_id: selectedBrand.id,
                        brand_type: selectedBrand.brandType,
                      }
                    );
                    setPublishModalData("success");
                    setShowPublishModal(true);
                    setPublishOpen(false);
                  } else {
                    TAIGSearchMixPanel(
                      "TAI-Gsearch Cohorts Failed",
                      selectedBrand?.name,
                      selectedAgency?.name,
                      {
                        ad_account_id: aiInitiationData?.adAccount?.adAccountId,
                        ai_group_id: aiInitiationData?.aiGroup?.id,
                        ai_group_name: aiInitiationData?.aiGroup?.name,
                        ad_account_name: aiInitiationData?.adAccount?.name,
                        agency_id: selectedAgency.id,
                        brand_id: selectedBrand.id,
                        brand_type: selectedBrand.brandType,
                      }
                    );
                    changePublishModal("failed");
                    setPublishOpen(false);
                  }
                }
              )
            );
          } else {
            changePublishModal("failed");
            setPublishOpen(false);
          }
        }
      )
    );
  };
  useEffect(() => {
    setDisplayObject(getDisplayObjectForPublish());
  }, []);

  return (
    <>
      {publishOpen && (
        <Modal open={publishOpen} onClose={() => setPublishOpen(false)}>
          <Stack
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40rem",
              bgcolor: "white",
              boxShadow: 24,
              "& *": {
                fontFamily: "inter",
              },
            }}
          >
            <Grid container sx={{ background: "white", p: 2 }}>
              <Grid item xs={11}>
                <Typography className="font-20 font-lato">
                  Publish Keywords
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                justifyContent="end"
                alignItems="end"
                display="flex"
              >
                <IconButton
                  className="d-flex align-items-end justify-content-end"
                  sx={{
                    background: "#F2F2F2",
                    borderRadius: "0.25rem",
                  }}
                  onClick={() => setPublishOpen(false)}
                >
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Box sx={{ maxHeight: "30rem", overflow: "auto" }}>
              {Object.keys(displayObject).map((key) => {
                const campaignDetails: any = displayObject[key];
                const adGroupDetails: any = campaignDetails.adGroups;

                return (
                  <Accordion disableGutters sx={{ mx: 2 }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                      >
                        <Stack>
                          <Typography
                            className="font-18 font-600 font-lato"
                            sx={{ color: "#333" }}
                          >
                            {campaignDetails.name}
                          </Typography>
                          <Typography
                            className="font-12"
                            sx={{ color: "#999" }}
                          >
                            {campaignDetails.id}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography
                            className="font-18 font-600 font-lato"
                            sx={{ color: "#333" }}
                          >
                            {campaignDetails.total}
                          </Typography>
                          <Typography
                            className="font-12"
                            sx={{ color: "#999" }}
                          >
                            Total Keywords
                          </Typography>
                        </Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.keys(adGroupDetails).map((key) => {
                        const adGroup = adGroupDetails[key];
                        return (
                          <Accordion
                            disableGutters
                            sx={{ maxHeight: "16rem", overflow: "auto" }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Stack
                                direction="row"
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                                spacing={2}
                              >
                                <Stack>
                                  <Typography
                                    className="font-14"
                                    sx={{ color: "#333" }}
                                  >
                                    {adGroup.name}
                                  </Typography>
                                  <Typography
                                    className="font-10"
                                    sx={{ color: "#999" }}
                                  >
                                    {adGroup.id}
                                  </Typography>
                                </Stack>
                                <Stack>
                                  <Typography
                                    className="font-14"
                                    sx={{ color: "#333" }}
                                  >
                                    {adGroup.items.length}
                                  </Typography>
                                  <Typography
                                    className="font-10"
                                    sx={{ color: "#999" }}
                                  >
                                    Total Keywords
                                  </Typography>
                                </Stack>
                              </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Stack
                                direction="row"
                                flexWrap="wrap"
                                sx={{
                                  maxHeight: "10rem",
                                  overflow: "auto",
                                }}
                              >
                                {adGroup.items.map((item: any) => (
                                  <Strip type={item.bucket || "positive_kw"}>
                                    <Typography className="font-12">
                                      {item[SEARCH_METRIC_NAME[item.bucket]]}
                                    </Typography>
                                  </Strip>
                                ))}
                              </Stack>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
            {/* <LightTooltip
              // title={isBrandViewer ? brandViewerAccessText : ""}
              title={isViewerRole(brandRoles) ? brandViewerAccessText : ""}
              placement="top-end"
            > */}
            <RolesWrapper
              disabled={
                getRolesHierarchy(brandRoles) === "viewer" ||
                getRolesHierarchy(brandRoles) === "editor"
              }
            >
              <Box
                className="d-flex justify-content-end align-items-center"
                sx={{
                  p: 2,
                  boxShadow: "0px 0px 2px rgba(30, 21, 83, 0.2)",
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                <Button
                  variant="contained"
                  // onClick={() => {
                  //   if (
                  //     // !isBrandViewer ||
                  //     !isViewerRole(brandRoles) ||
                  //     !isEditorRole(brandRoles)
                  //   ) {
                  //     onPublishClick();
                  //   }
                  // }}
                  onClick={() => {
                    {
                      onPublishClick();
                    }
                  }}
                  // style={{
                  //   // cursor: isBrandViewer
                  //   cursor:
                  //     isViewerRole(brandRoles) || isEditorRole(brandRoles)
                  //       ? "not-allowed !important"
                  //       : "pointer",
                  // }}
                  // disabled={
                  //   isViewerRole(brandRoles) || isEditorRole(brandRoles)
                  // }
                >
                  Publish
                </Button>
              </Box>
            </RolesWrapper>
            {/* </LightTooltip> */}
          </Stack>
        </Modal>
      )}
    </>
  );
};

const PublishModal = () => {
  const [publishOpen, setPublishOpen] = useState(false);
  const { isAnythingSelected } = useContext(KeywordContext);

  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { selectedAgency } = common;
  const { selectedBrand } = adaccount;
  const isSelected = isAnythingSelected();
  const aiInitiationData = useSelector(
    (state: any) => state.aiInitiation.apiData
  );
  const aiInitiationFormData = useSelector(
    (state: any) => state.aiInitiation.formData
  );
  const { getPublishPayload } = useContext(KeywordContext);
  const aiGroupData = {
    ai_group_id: aiInitiationData.aiGroup.id,
    ai_group_name: aiInitiationData.aiGroup.name,
    ad_account_name: aiInitiationFormData?.selectedAdAccount?.name,
    ad_account_id: aiInitiationFormData?.selectedAdAccount?.id,
  };
  const keywords = getPublishPayload();

  return (
    <>
      <Button
        className="text-white font-600 font-14"
        sx={{ px: 1, py: 1 }}
        style={{
          background: "#0869FB",
          borderRadius: "0.5rem",
          opacity: !isSelected ? 0.5 : 1,
          textTransform: "none",
        }}
        variant="contained"
        onClick={() => {
          TAIGSearchMixPanel(
            "TAI-Gsearch Review and Publish Clicked",
            selectedBrand?.name,
            selectedAgency?.name,
            {
              ...aiGroupData,
              ...keywords,
              ad_account_id: aiInitiationData?.adAccount?.id,
              ad_account_name: aiInitiationData?.adAccount?.name,
              agency_id: selectedAgency.id,
              brand_id: selectedBrand.id,
              brand_type: selectedBrand.brandType,
            }
          );
          setPublishOpen(true);
        }}
        disabled={!isSelected}
      >
        Review
      </Button>
      {publishOpen && (
        <Publish publishOpen={publishOpen} setPublishOpen={setPublishOpen} />
      )}
    </>
  );
};

export default PublishModal;
