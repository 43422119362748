import { get, set } from "lodash";
import {
  FacebookInitialState,
  GET_ADSETS_FB,
  GET_ADS_FB,
  GET_AD_ACCOUNTS_FB,
  GET_AI_GROUPS,
  GetAIGroups,
  GetAdAccounts,
  GetAdSetsFB,
  GetAdsFB,
  SET_ADSETS_FB,
  SET_ADS_FB,
  SET_AD_ACCOUNTS,
  SET_AI_GROUPS,
  SetAIGroups,
  SetAdAccounts,
  SetAdSetsFB,
  SetAdsFB,
  GET_FILTERS_UPDATED,
  GetFiltersUpdated,
  SET_FILTERED_DATA,
  SetFilteredData,
  SET_AUTOGENERATE_DATA,
  SetAutogenerateData,
  SET_AUTOGENERATE_AD_ID,
  SetAutogenerateAdId,
  SET_UTG_DATA,
  SET_UTG_AD_ID,
  SetUTGAdId,
  SetUTGData,
} from "store/types/CreativeAI_Revamp/facebook.types";

const initialState: FacebookInitialState = {
  aiGroupList: {
    isFailed: false,
    isLoading: true,
    data: [],
  },
  adAccountList: {
    isFailed: false,
    isLoading: true,
    data: [],
  },
  adSetList: {
    isFailed: false,
    isLoading: true,
    data: [],
  },
  adsList: {
    isFailed: false,
    isLoading: true,
    data: [],
    filteredData: [],
  },
  filter: [],
  autogenerate: {},
  utgData: {},
  triggerAutogenerateAdId: "",
  triggerUTGAdId: "",
};

export const facebookReducer = (
  state = initialState,
  {
    type,
    payload,
  }:
    | GetAIGroups
    | SetAIGroups
    | GetAdAccounts
    | SetAdAccounts
    | GetAdSetsFB
    | SetAdSetsFB
    | SetAdsFB
    | GetAdsFB
    | GetFiltersUpdated
    | SetFilteredData
    | SetAutogenerateData
    | SetAutogenerateAdId
    | SetUTGAdId
    | SetUTGData
) => {
  switch (type) {
    case GET_AI_GROUPS:
      return {
        ...state,
        aiGroupList: {
          ...state.aiGroupList,
          isLoading: payload.isSearching ? false : true,
          isSearching: payload.isSearching,
        },
        parentPlatformId: payload.params?.parentPlatformId,
      };
    case SET_AI_GROUPS:
      return {
        ...state,
        aiGroupList: { ...payload },
      };

    case GET_AD_ACCOUNTS_FB:
      return {
        ...state,
        adAccountList: { isFailed: false, isLoading: true, data: [] },
      };

    case SET_AD_ACCOUNTS:
      return {
        ...state,
        adAccountList: { ...payload },
      };

    case GET_ADSETS_FB:
      return {
        ...state,
        adSetList: { isFailed: false, isLoading: true, data: [] },
      };

    case SET_ADSETS_FB:
      return {
        ...state,
        adSetList: { ...payload },
      };

    case GET_ADS_FB:
      return {
        ...state,
        adsList: {
          isFailed: false,
          isLoading: true,
          data: [],
          filteredData: [],
        },
      };

    case SET_ADS_FB:
      return {
        ...state,
        adsList: { ...payload },
      };
    case GET_FILTERS_UPDATED:
      return {
        ...state,
        filter: payload,
      };
    case SET_FILTERED_DATA:
      return {
        ...state,
        adsList: { ...state.adsList, filteredData: payload },
      };
    case SET_AUTOGENERATE_DATA:
      const local = state.autogenerate;
      set(local, payload.path, {
        ...get(local, payload.path),
        ...payload.value,
      });
      return {
        ...state,
        autogenerate: local,
      };
    case SET_AUTOGENERATE_AD_ID:
      return {
        ...state,
        triggerAutogenerateAdId: payload,
      };
    case SET_UTG_DATA:
      const localUTGData = state.utgData;
      set(localUTGData, payload.path, {
        ...get(localUTGData, payload.path),
        ...payload.value,
      });
      return {
        ...state,
        utgData: localUTGData,
      };
    case SET_UTG_AD_ID:
      return {
        ...state,
        triggerUTGAdId: payload,
      };
    default:
      return state;
  }
};
