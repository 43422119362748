export const GET_FORMS = "GET_FORMS";
export const SET_FORMS = "SET_FORMS";
export const SET_FORMS_LOADING = "SET_FORMS_LOADING";
export const SET_TARGETING_FORM = "SET_TARGETING_FORM";
export const UPDATE_FORMS = "UPDATE_FORMS";
export const STORE_FORM = "STORE_FORM";
export const UPDATE_ONBOARDING = "UPDATE_ONBOARDING";
export const UPDATE_GOOGLE_ONBOARDING = "UPDATE_GOOGLE_ONBOARDING";
export const SET_ONBOARDING = "SET_ONBOARDING";
export const GET_ONBOARDING = "GET_ONBOARDING";
export const GET_GOOGLE_ONBOARDING = "GET_GOOGLE_ONBOARDING";
export const GET_TARGETING_AI = "GET_TARGETING_AI";
export const UPDATE_TARGETING_AI = "UPDATE_TARGETING_AI";
export const GET_CLIENT_PDF = "GET_CLIENT_PDF";
export const GET_AGREEMENT = "GET_AGREEMENT";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const SET_PDF_FILE = "SET_PDF_FILE";
export const SET_TARGETING_AI = "SET_TARGETING_AI";
export const SET_GOOGLE_ONBOARDING = "SET_GOOGLE_ONBOARDING";
export const SET_MODAL = "SET_MODAL";
export const SET_FORM_LOADING = "SET_FORM_LOADING";
export const SET_TARGETING_LOADING = "SET_TARGETING_LOADING";
export const SET_CREATIVEAI_LOADING = "SET_CREATIVEAI_LOADING";
export const SET_ONBOARDING_LOADING = "SET_ONBOARDING_LOADING";
export const SET_GOOGLE_ONBOARDING_LOADING = "SET_GOOGLE_ONBOARDING_LOADING";
export const SET_CREATIVE_AI = "SET_CREATIVE_AI";
export const GET_CREATIVE_AI = "GET_CREATIVE_AI";
export const UPDATE_CREATIVE_AI = "UPDATE_CREATIVE_AI";
export const GET_BRAND_DATA = "GET_BRAND_DATA";
export const GET_PLATFORM_ADACCOUNTS = "GET_PLATFORM_ADACCOUNTS";
export const ENABLE_CREATIVE_ROTATION = "ENABLE_CREATIVE_ROTATION";
export const DISABLE_CREATIVE_ROTATION = "DISABLE_CREATIVE_ROTATION";
export const GET_CREATIVE_ROTATION_TOGGLE = "GET_CREATIVE_ROTATION_TOGGLE";
export const SET_CREATIVE_ROTATION_TOGGLE = "SET_CREATIVE_ROTATION_TOGGLE";
export const GET_ACTIVITY_LOGS = "GET_ACTIVITY_LOGS";

export type FormState = {
    targetingForm: any,
    formDetails: any,
    forms: FormData[],
    formLoading: boolean,
    isOnBoarding: boolean,
    pdfFile: any,
    targetingAI: boolean,
    creativeAI:boolean,
    googleOnboarding:boolean,
    modal: any,
    targetingLoading: boolean,
    creativeAILoading:boolean,
    onBoardingLoading: boolean,
    googleOnboardingLoading: boolean,
    fetchedGoolgeOnboardingStatus: boolean
}



export type FormData = {
    ad_account_id?: string,
    adset_ids?: [],
    audienceRange?: string,
    brandKeywords: string,
    brandName: string,
    brandWebsite: string,
    competitor_urls?: string,
    group_id?: string,
    industrySubtype: string,
    industryType: string,
    modeOfApplication: string,
    objective_metric?: string,
    other_keywords?: string,
    recommendationFrequency: string,
    referenceAdsetId?: string,
    relevanceRange?: string,
    id: string,
    previously_published_adset_ids?: []
}

export type TargetingForm = {
    ad_account_id?: string,
    adset_ids?: [],
    audienceRange?: string,
    brandKeywords?: string,
    brandName?: string,
    brandWebsite?: string,
    competitor_urls?: string,
    group_id?: string,
    industrySubtype: string,
    industryType: string,
    modeOfApplication: string,
    objective_metric?: string,
    other_keywords?: string,
    recommendationFrequency?: string,
    referenceAdsetId?: string,
    relevanceRange?: string,
    id?: string,
    elementIds?: [],
    previously_published_adset_ids?: []
}

export type SetForms = {
    type: typeof SET_FORMS;
    payload: any;
};

export type SetFormsLoading = {
    type: typeof SET_FORMS_LOADING;
    payload: boolean;
};

export type GetFormData = {
    type: typeof GET_FORMS;
    payload: {
        elementId: string;
        user: any;
    };
    callback?: Function;
};

export type StoreFormData = {
    type: typeof STORE_FORM;
    payload: {
        user: any;
        params: any;
        elementId?: string;
    };
    callback?: Function;
};

export type UpdateFormData = {
    type: typeof UPDATE_FORMS;
    payload: {
        formId: string;
        elementId: string;
        user: any;
        params: any;
    };
    callback?: Function;
};

export type GetOnBoarding = {
    type: typeof GET_ONBOARDING;
    payload: {
        brandId: string;
        accountId: string;
        user: any;
    };
    callback?: Function;
};

export type GetGoogleOnBoarding = {
    type: typeof GET_GOOGLE_ONBOARDING;
    payload: {
        brandId: string;
        user: any;
    };
    callback?: Function;
};

export type UpdateOnBoarding = {
    type: typeof UPDATE_ONBOARDING;
    payload: {
        brandId: string;
        accountId: string;
        user: any;
        enable: boolean;
    };
    callback?: Function;
};

export type UpdateGoogleOnBoarding = {
    type: typeof UPDATE_GOOGLE_ONBOARDING;
    payload: {
        brandId: string;
        user: any;
        enable: boolean;
    };
    callback?: Function;
};

export type SetTargetingForm = {
    type: typeof SET_TARGETING_FORM;
    payload: TargetingForm;
};

export type SetIsOnboarding = {
    type: typeof SET_ONBOARDING;
    payload: boolean;
};

export type GetTargetingAI = {
    type: typeof GET_TARGETING_AI;
    payload: {
        brandId: string;
        user: any;
    };
    callback?: Function;
};

export type GetCreativeAI = {
    type: typeof GET_CREATIVE_AI;
    payload: {
        brandId: string;
        user: any;
    };
    callback?: Function;
};

export type UpdateTargetingAI = {
    type: typeof UPDATE_TARGETING_AI;
    payload: {
        brandId: string;
        user: any;
        enable: boolean;
    };
    callback?: Function;
};

export type UpdateCreativeAI = {
    type: typeof UPDATE_CREATIVE_AI;
    payload: {
        brandId: string;
        user: any;
        enable: boolean;
    };
    callback?: Function;
};

export type GetBrandData = {
    type: typeof GET_BRAND_DATA;
    payload: {
        brandId: string;
        user: any;
    }
    callback: Function;
}

export type GetPlatformAdaccounts = {
    type: typeof GET_PLATFORM_ADACCOUNTS;
    payload: {
        adaccountId: string;
        platformId: string;
        user: any;
    }
    callback: Function;
}

export type GetClientPDF = {
    type: typeof GET_CLIENT_PDF;
    payload: {
        agencyId: string;
        userType: any;
    };
    callback?: Function;
};

export type GetAgreement = {
    type: typeof GET_AGREEMENT;
    payload: {
        agencyId: string;
        user: any;
    };
    callback?: Function;
};

export type UpdateUserDetails = {
    type: typeof UPDATE_USER_DETAILS;
    payload: {
        agencyId: string;
        user: any;
        param: any;
        userType: any;
    };
    callback?: Function;
};

export type EnableCreativeRotation = {
    type: typeof ENABLE_CREATIVE_ROTATION;
    payload: {
        elementId: string;
        user: any;
    };
    callback?:Function;
}

export type DisableCreativeRotation = {
    type: typeof DISABLE_CREATIVE_ROTATION;
    payload: {
        elementId: string;
        user: any;
    };
    callback?:Function;
}

export type GetCreativeRotationToggle = {
    type: typeof GET_CREATIVE_ROTATION_TOGGLE;
    payload: {
        elementId: string;
        user: any;
    };
    callback?:Function;
}

export type SetPdfFile = {
    type: typeof SET_PDF_FILE;
    payload: any;
};

export type SetTargetingAI = {
    type: typeof SET_TARGETING_AI;
    payload: boolean;
};

export type SetCreativeAI = {
    type: typeof SET_CREATIVE_AI;
    payload: boolean;
};

export type SetGoogleOnboarding = {
    type: typeof SET_GOOGLE_ONBOARDING;
    payload: boolean;
};

export type SetModal = {
    type: typeof SET_MODAL;
    payload: any;
};

export type SetFormLoading = {
    type: typeof SET_FORM_LOADING;
    payload: boolean;
};

export type SetTargetingAILoading = {
    type: typeof SET_TARGETING_LOADING;
    payload: boolean;
};

export type SetCreativeAILoading = {
    type: typeof SET_CREATIVEAI_LOADING;
    payload: boolean;
};

export type SetOnBoardingLoading = {
    type: typeof SET_ONBOARDING_LOADING;
    payload: boolean;
};

export type SetGoogleOnBoardingLoading = {
    type: typeof SET_GOOGLE_ONBOARDING_LOADING;
    payload: boolean;
};

export type SetCreativeRotationToggle = {
    type: typeof SET_CREATIVE_ROTATION_TOGGLE;
    payload: {elementID:any,status:boolean};
};

export type GetActivityLogs = {
    type: typeof GET_ACTIVITY_LOGS;
    payload: {elementId:any, original_ad_account_id:any,start_date:any, end_date:any,download:boolean,page:Number,page_size:Number,user:any}
    callback?:Function
}