import React, { useEffect } from "react";
import { ReactComponent as FilterIcon } from "../../../assets/svg/Filter.svg";
import { ReactComponent as DownArrow } from "../../../assets/svg/dropdown_arrow.svg";
import { ReactComponent as UpArrow } from "../../../assets/svg/up_dropdown_arrow.svg";
import { StyledContainer } from "./styled";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { Button } from "../Button/Button";
import { cloneDeep } from "lodash";
export interface listProps {
  key: string;
  label: string;
  value: boolean;
}
interface FilterMultiSelectProps {
  fields: listProps[];
  handleSave: (options: listProps[]) => void;
  filterName: string;
}
function FilterMultiSelect({
  fields,
  filterName,
  handleSave,
}: FilterMultiSelectProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [optionsList, setOptionsList] = React.useState<listProps[]>([]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setOptionsList((prev: listProps[]) => {
      let local = [...prev];
      local[index].value = event.target.checked;
      return local;
    });
  };

  useEffect(() => {
    if (fields && isOpen) {
      setOptionsList(cloneDeep(fields));
    }
  }, [fields, isOpen]);
  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false);
      }}
    >
      <StyledContainer
        onClick={(event: any) => {
          event.stopPropagation();
        }}
      >
        <div
          className="selected-value"
          onClick={(event: any) => {
            event.stopPropagation();
            setIsOpen((value) => !value);
          }}
        >
          <>
            <div>
              <i>
                <FilterIcon height={"16px"} />{" "}
              </i>{" "}
              <span>Filter</span>
            </div>
            {isOpen ? (
              <i>
                <UpArrow />
              </i>
            ) : (
              <i>
                <DownArrow />
              </i>
            )}
          </>
        </div>
        {isOpen ? (
          <div className="list-container">
            <div className="name-container">{filterName}</div>
            <Box sx={{ display: "flex" }}>
              <FormControl
                sx={{
                  ml: 3,
                  mt: 1,
                  mb: 5,
                  maxHeight: "50vh",
                  overflowY: "scroll",
                }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  {optionsList &&
                    optionsList.map((item, index: number) => (
                      <FormControlLabel
                        key={index}
                        sx={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "10px",
                          userSelect: "none",
                        }}
                        control={
                          <Checkbox
                            sx={{
                              color: "#C7CAD6",
                              height: "20px",
                              width: "40px",
                            }}
                            checked={item.value}
                            onChange={(e) => handleChange(e, index)}
                            name={item.label}
                          />
                        }
                        label={
                          <span style={{ fontSize: "14px" }}>{item.label}</span>
                        }
                      />
                    ))}
                </FormGroup>
              </FormControl>
            </Box>
            {optionsList && Object.keys(optionsList).length > 0 && (
              <div className="footer">
                <Button
                  buttonVariant="outlined"
                  onClick={() => {
                    setIsOpen(false);
                    handleSave(optionsList);
                  }}
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#0869FB",
                    border: "1px solid #0869FB",
                    borderRadius: "6px",
                  }}
                >
                  Apply
                </Button>
              </div>
            )}
          </div>
        ) : null}
      </StyledContainer>
    </ClickAwayListener>
  );
}

export default FilterMultiSelect;
