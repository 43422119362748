import { Box, styled } from "@mui/system";

interface StyledTabItemProps {
  active?: boolean;
}

export const StyledCreativeAIMainBox = styled(Box)`
  height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin: 0.8125em 1em 0em 1.5em;
  font-size: 1.1112vw;
  @media screen and (max-width: 1280px) {
    font-size: 11px;
  }
  @media screen and (min-width: 1700px) {
    margin: 0.8125em 1em 0em 0em;
  }
  overflow: hidden;
`;

export const StyledCreativeAIHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75em;
  height: 2.5em;
`;

export const StyledCreativeAITabBox = styled(Box)`
  display: flex;
  min-height: 0;
  height: 100%;
`;

export const StyledTabItem = styled(Box)<StyledTabItemProps>`
  background: #ffffff;
  border: ${({ active }) => (active ? "1px solid #0869FB" : "none")};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  cursor: pointer;
  // padding: 0.8em 0.790625em 0.77875em 0.8em;
  height: 2.5em;
  width: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      stroke: ${({ active }) => (active ? "#0869FB" : "#333333")};
    }
  }
`;
export const StyledTabSVGContainer = styled(Box)<StyledTabItemProps>`
  height: 1.5em;
  width: 1.5em;
`;
export const StyledCreativeAIGroupSettings = styled(Box)`
  display: flex;
  background: #fafafa;
  border: 1px solid #e6e6e6;
  border-radius: 0px 8px 8px 0px;
  height: 2.5em;
  width: 2.5em;
  align-items: center;
  justify-content: center;
  /* margin-left: -0.1em; */
`;
