import React from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as CaretDown } from "assets/tai/caretDown.svg";
import { ReactComponent as Search } from "../assets/Search.svg";
import CommonLoader from "assets/Gif/CommonLoader.gif";

function SelectDropdown({
  heading,
  data,
  selectedValue,
  changeSelectedValue,
  onChange,
  disabled,
}: {
  heading: string;
  data: any;
  selectedValue: any;
  changeSelectedValue: any;
  disabled?: boolean;
  onChange?: any;
}) {
  const [searchString, setSearchString] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const styles = {
    selectBoxHeading: {
      borderRadius: "8px",
      width: "310px",
      height: "42px",
      color: "#999999",
      maxHeight: "327px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#DDDDDD !important",
      },
      "& .Mui-disabled": { cursor: "not-allowed", opacity: "0.5" },
    },
    noDataBox: {
      height: "100%",
      width: "100%",
      minHeight: "150px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  function CustomSvgIcon() {
    return (
      <>
        {data?.length > -1 ? (
          <CaretDown
            style={{ height: "5px", width: "12px", marginRight: "14px" }}
          />
        ) : (
          <img
            style={{ height: "1em", width: "1em", marginRight: "14px" }}
            src={CommonLoader}
            alt="Loader"
          />
        )}
      </>
    );
  }
  return (
    <>
      <Select
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          heading === "Data Source" && setSearchString("");
          setOpen(false);
        }}
        disabled={!data || disabled}
        IconComponent={CustomSvgIcon}
        value={
          heading === "Time Zone"
            ? selectedValue?.timezone || `Select ${heading}`
            : selectedValue?.name || `Select ${heading}`
        }
        displayEmpty={true}
        style={styles.selectBoxHeading}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DDDDDD !important",
            opacity: disabled ? 0.4 : 1,
            cursor: disabled ? "not-allowed" : "pointer",
          },
          "& .Mui-disabled": { cursor: "not-allowed", opacity: "0.5" },
        }}
        renderValue={(value: any) => value}
      >
        {data?.length === 0 ? (
          <Box style={styles.noDataBox}>
            <Typography
              sx={{ color: "#999999" }}
            >{`No ${heading} Found`}</Typography>
          </Box>
        ) : heading === "Data Source" ? (
          <Box sx={{ maxHeight: "327px" }}>
            <Box
              sx={{
                height: "40px",
                width: "246px",
                margin: "18px 32px -4px 32px",
                border: "1px solid #EAEAEC",
                borderRadius: "6px",
              }}
            >
              <Stack direction="row" sx={{ margin: "11px 14px" }}>
                <Search />
                <TextField
                  onChange={(event: any) => {
                    setSearchString(event.target.value);
                  }}
                  placeholder="Search"
                  sx={{
                    width: "200px",
                    marginLeft: "10px",
                    "& fieldset": { border: "none" },
                    "& .MuiInputBase-input": {
                      padding: 0,
                    },
                    "& .MuiInputBase-root": {
                      fontSize: "12px",
                      fontFamily: "Inter",
                      fontWeight: 400,
                    },
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </Stack>
            </Box>
            {data
              ?.filter((element: any) => {
                if (searchString === "") {
                  return element;
                } else if (
                  element.name
                    .toLowerCase()
                    .includes(searchString.toLowerCase())
                ) {
                  return element;
                }
              })
              .map((item: any) => {
                return item ? (
                  <Stack
                    sx={{ cursor: "pointer" }}
                    direction="row"
                    onClick={(event) => {
                      event.preventDefault();
                      onChange(item);
                      setOpen(false);
                    }}
                    alignItems="center"
                    margin="22px 0"
                  >
                    <Box
                      sx={{
                        height: "38px",
                        width: "4px",
                        borderRadius: "36px",
                        backgroundColor:
                          selectedValue?.id === item.id ? "#0869FB" : "none",
                      }}
                    ></Box>
                    <Stack
                      direction="row"
                      sx={{ padding: "0 44px" }}
                      alignItems="center"
                    >
                      <Box sx={{ marginRight: "10px" }}>
                        <img src={item.logoUrl} alt={item.name} />
                      </Box>
                      <Typography
                        fontSize="12px"
                        fontWeight={
                          selectedValue?.id === item.id ? "600" : "400"
                        }
                        fontFamily="Inter"
                        color={
                          selectedValue?.id === item.id ? "#0869FB" : "#2E3044"
                        }
                      >
                        {item.name}
                      </Typography>
                    </Stack>
                  </Stack>
                ) : (
                  <>
                    <Typography>No DATA</Typography>
                  </>
                );
              })}
          </Box>
        ) : heading === "Time Zone" ? (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            sx={{ paddingLeft: "20px" }}
            value={selectedValue?.timezone}
            name="radio-buttons-group"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
              const value = data.find(
                (item: any) => item.timezone === event.target.value
              );
              if (onChange) onChange(value);
              changeSelectedValue(value);
              setOpen(false);
            }}
          >
            {data?.map(
              (item: any) =>
                item && (
                  <FormControlLabel
                    sx={{ width: "100%" }}
                    checked={selectedValue?.timezone === item.timezone}
                    value={item.timezone}
                    label={item.timezone}
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            height: "20px",
                            width: "20px",
                            fill: `${
                              selectedValue?.timezone === item.timezone
                                ? "#0869FB"
                                : "#CCCCCC"
                            }`,
                          },
                        }}
                      />
                    }
                  />
                )
            )}
          </RadioGroup>
        ) : (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            sx={{ paddingLeft: "20px" }}
            value={selectedValue}
            name="radio-buttons-group"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
              const value = data.find((item: any) => {
                return heading === "Sheet Name"
                  ? item.name === event.target.value
                  : item.id.toString() === event.target.value;
              });
              if (onChange) onChange(value);
              changeSelectedValue(value);
            }}
          >
            {data?.map(
              (item: any) =>
                item && (
                  <FormControlLabel
                    sx={{ width: "100%" }}
                    checked={
                      heading === "Sheet Name"
                        ? selectedValue?.name === item.name
                        : selectedValue?.id === item.id
                    }
                    value={heading === "Sheet Name" ? item.name : item.id}
                    label={item.name}
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            height: "20px",
                            width: "20px",
                            fill: `${
                              selectedValue?.id === item.id ||
                              selectedValue?.name === item.name
                                ? "#0869FB"
                                : "#CCCCCC"
                            }`,
                          },
                        }}
                      />
                    }
                  />
                )
            )}
          </RadioGroup>
        )}
      </Select>
    </>
  );
}

export default SelectDropdown;
