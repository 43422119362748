import { Box, styled } from "@mui/system";

export const StyledItalicText = styled(Box)`
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
`;

export const StyledTimeStampText = styled(Box)`
  position: absolute;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  right: 3px;
  bottom: 3px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 19px;
  padding: 1.5px 5px 2px;
`;

export const StyledPreviewVideoItem = styled(Box)`
  position: relative;
  /* width: 107px; */
  height: 63.6px;
  border: ${({ selected }: { selected?: boolean }) =>
    selected ? "1.8px solid #0869FB" : "none"};
  box-shadow: ${({ selected }: { selected?: boolean }) =>
    selected ? "1px 5px 12px 3px rgba(0,0,0,0.5)" : "none"};
  ::before {
    content: "";
    display: block;
    position: absolute;
    right: 45px;
    top: -7.5px;
    display: ${({ selected }: { selected?: boolean }) =>
      selected ? "block" : "none"};
    border-bottom: 7.5px solid #0869fb;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
  }
`;

export const StyledVideoRecommendationContainer = styled(Box)`
  background: #f4f4f4;
  padding: 8px 0px 18px 18px;
  display: flex;
  flex-direction: column;
`;

export const StyledVideoRecommendationItemContainer = styled(Box)`
  display: flex;
  z-index: 999;
  width: 100%;
  /* position: absolute; */
  /* top: 0; */
  /* height: 30vh; */

  /* flex-direction: column; */
`;

export const StyledArrowBox = styled(Box)`
  border-bottom: 7.5px solid #0869fb;
  border-left: 7.5px solid transparent;
  border-right: 7.5px solid transparent;
`;

export const StyledInfoCharText = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #999999;
  padding-top: "2px";
`;

export const StyledInfoErrorText = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: red;
  padding: "2px 0px";
`;

export const StyledVerticalDivider = styled(Box)`
  position: absolute;
  top: 68px;
  left: 10px;
  background-image: repeating-linear-gradient(
      0deg,
      #2bb3f4,
      #2bb3f4 12px,
      transparent 12px,
      transparent 16px,
      #2bb3f4 16px
    ),
    repeating-linear-gradient(
      90deg,
      #2bb3f4,
      #2bb3f4 12px,
      transparent 12px,
      transparent 16px,
      #2bb3f4 16px
    ),
    repeating-linear-gradient(
      180deg,
      #2bb3f4,
      #2bb3f4 12px,
      transparent 12px,
      transparent 16px,
      #2bb3f4 16px
    ),
    repeating-linear-gradient(
      270deg,
      #2bb3f4,
      #2bb3f4 12px,
      transparent 12px,
      transparent 16px,
      #2bb3f4 16px
    );
  background-size: 1px 100%, 0px, 0px, 0px;
  /* background-position: 0 0, 0 0, 100% 0, 0 100%; */
  background-repeat: no-repeat;
  height: 200px;
`;

export const StyledFailedTitle = styled(Box)`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  padding-top: 12px;

  color: #ffffff;
`;

export const StyledSubTitle = styled(Box)`
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding-top: 6px;

  text-align: center;

  color: #ffffff;
`;
