import moment from "moment";
import { Token } from "./types";

export function getWarningMessage(tokens: Token[]) {
  if (tokens?.length === 0) {
    return {
      className: "warning",
      message: "No tokens present",
      availableTokens: 0,
    };
  }

  let meta = {
    aboutToExpire: 0,
    accessLost: 0,
    daysLeft: 15,
    expired: 0,
  };
  let className = "";
  const messages = [];
  let availableTokens = tokens?.length;

  for (const token of tokens) {
    const seconds = moment(moment.utc(token.setToExpireOn).local()).diff(
      moment(),
      "seconds"
    );
    if (seconds <= 0) {
      meta.expired += 1;
    } else if (token.adAccountTokenExpired) {
      meta.accessLost += 1;
    } else {
      const daysLeft = Math.floor(seconds / (3600 * 24));
      if (daysLeft < 15) {
        meta.aboutToExpire += 1;
        if (daysLeft < meta.daysLeft) meta.daysLeft = daysLeft;
      }
    }
  }

  if (meta.expired || meta.aboutToExpire || meta.accessLost) {
    if (tokens?.length === 1) {
      availableTokens = 0;
      // className = "error";
      if (meta.expired) {
        className = "expired";
        messages.push("Token Expired");
      } else if (meta.accessLost) {
        className = "accessLost";
        messages.push("Access Lost");
      } else {
        className = "daysLeft";
        if (meta.daysLeft === 0) {
          messages.push("Expiring Today");
        } else {
          messages.push(
            `${meta.daysLeft} day${meta.daysLeft > 1 ? "s" : ""} left`
          );
        }
      }
    } else {
      const sum = meta.expired + meta.aboutToExpire + meta.accessLost;
      availableTokens -= sum;
      // className = sum === tokens?.length ? "error" : "warning";
      if (meta.expired) {
        className = "expired";
        messages.push(
          `${meta.expired} token${meta.expired > 1 ? "s" : ""} expired`
        );
      }
      if (meta.accessLost) {
        className = "accessLost";
        if (messages.length) {
          messages.push(`${meta.accessLost} lost access`);
        } else {
          messages.push(
            `${meta.accessLost} token${
              meta.accessLost > 1 ? "s" : ""
            } lost access`
          );
        }
      }
      if (meta.aboutToExpire) {
        className = "aboutToExpire";
        if (messages.length) {
          messages.push(`${meta.aboutToExpire} about to expire`);
        } else {
          messages.push(
            `${meta.aboutToExpire} ${
              meta.aboutToExpire > 1 ? "tokens" : "token is"
            } about to expire`
          );
        }
      }
    }
  }

  return {
    className,
    message: messages.join(", "),
    availableTokens,
  };
}

export function handleSort(data: any, key: string, order: boolean) {
  const _sort = [...data].sort((a, b) => {
    return a[key] > b[key] === order ? -1 : 1;
  });
  return _sort;
}

export const queryParamsMaker = (object: any) =>
  Object.keys(object)
    .map(
      (key) =>
        encodeURIComponent(key) +
        "=" +
        encodeURIComponent(
          typeof object[key] === "object"
            ? JSON.stringify(object[key])
            : object[key]
        )
    )
    .join("&");
