import { Box } from "@mui/material";
import { Button } from "components/shared";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { ReactComponent as AddIcon } from "../../../../../../../../assets/svg/add_icon.svg";

import { StyledGenerateMoreText, StyledPagination } from "./styled";

interface PaginationComponentProps {
  length: number;
  onClick: (index: number) => void;
  currentPage: number;
}

export default function PaginationComponent({
  length,
  onClick,
  currentPage,
}: PaginationComponentProps) {
  const [paginationCount, setPaginationCount] = useState(0);
  const {
    extraFields: { selectedVariantIndex },
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  useEffect(() => {
    if (length > 4 && currentPage <= 4) {
      setPaginationCount(4);
    } else if (length > 4) {
      setPaginationCount(currentPage);
    } else {
      setPaginationCount(length);
    }
  }, [selectedVariantIndex]);

  const onGenerateMoreClick = () => {
    const nextAdd = 4 - (paginationCount % 4);
    if (paginationCount + nextAdd <= length) {
      setPaginationCount(paginationCount + nextAdd);
    } else {
      setPaginationCount(length);
    }
  };

  return (
    <Box display="flex" width="100%" pt="24px" justifyContent="center">
      <StyledPagination
        count={paginationCount}
        variant="outlined"
        shape="rounded"
        page={currentPage}
        onChange={(event: React.ChangeEvent<unknown>, page: number) => {
          onClick(page);
        }}
      />
      {paginationCount !== length ? (
        <Button
          variant="text"
          sx={{
            marginLeft: "8px",
          }}
          onClick={onGenerateMoreClick}
        >
          <Box display="flex" alignItems="center">
            <AddIcon />
            <StyledGenerateMoreText pl="8px">
              {" "}
              Generate More
            </StyledGenerateMoreText>
          </Box>
        </Button>
      ) : (
        <Box />
      )}
    </Box>
  );
}
