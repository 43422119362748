import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Box, Stack, TableCell, TableRow, Typography } from "@mui/material";
import {
  CheckBox,
  SelectFormControl,
  InputFormControl,
  Button,
  StyledText,
} from "../../../shared";
import CustomizedButton from "../../../shared/Button/CustomizedButton";
import CustomizedDialogs from "../../../shared/Modal/CustomizedDialogs";
import SelectableTable from "../../../shared/Table/SelectableTable";
import { selectableTableHead } from "../../../../utils/constants/creativeAI";
import {
  StyledCheckboxItem,
  StyledPlaceholderContainer,
  StyledCreateVariantModalContainer,
  StyledRestoredDefault,
  StyledCVButton,
  StyledButtonFooter,
  StyledCreateVariantSettingTxt,
  StyleAiGroupDuplicatePara,
  StyledFooter,
} from "./styled";
import BusinessUnitPlaceHolder from "../../../../assets/svg/businessUnitPlaceholder.svg";
import { APIGenerationType } from "../shared/SharedTypes";
import { useForm } from "react-hook-form";
import FormLevers from "../shared/FormLevers/FormLevers";
import {
  expandData,
  getBUFormValues,
  getFormValues,
  mergeData,
} from "../utils/form.utils";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessUintById } from "../../../../store/actions/CreativeAI/Facebook/Main/Main";
import { initialState } from "../../../../store/types/auth";
import { cloneDeep } from "lodash";
import Loader from "../../../common/Loader";
import { AlertConfirm } from "../../../shared/Modal/AlertConfiem";
import TooltipWithInfo from "../shared/TooltipWithInfo/TooltipWithInfo";
import { filterLevers } from "../utils/filterLevers.utils";
import { styled } from "@mui/material/styles";
import { AdAccountState } from "../../../../store/types/adaccount";
import {
  AIGroupDropdownController,
  PxDialog,
} from "components/CreativeAI_Revamp/shared";
import {
  AIgroupMetaDataType,
  FacebookInitialState,
  SetAIGroupPayloadType,
} from "store/types/CreativeAI_Revamp/facebook.types";
import { setAIGroups } from "store/actions/CreativeAI_Revamp/facebook.actions";
import { mixPanelAdEvent } from "utils/mixpanel";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { useCaiFbMixPanel } from "components/CreativeAI_Revamp/Facebook/HomeScreen/hooks/useCaiFbMixPanel";
import { getRolesHierarchy, isViewerRole } from "utils/commonFunctions";
import { RolesWrapper } from "utils/rolesWrapper";

const BusinessUnitSettingModalTitle = ({
  selected,
  total,
}: {
  selected: number;
  total: number;
}) => {
  return (
    <Box display="flex" flexDirection="column" gap=".25em">
      <StyledText
        fontWeight={600}
        fontSize="1"
        lineHeight="20px"
        color="#333333"
      >
        Business Unit Setup
      </StyledText>
      <StyledText
        fontWeight={500}
        fontSize="0.625em"
        lineHeight="12px"
        color="#949494"
      >
        {`${selected}/${total} AI Groups Configured`}
      </StyledText>
    </Box>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // // hide last border
  "&:first-child td, &:first-child th": {
    // border: "none",
  },
  border: "1px solid #DDDDDD",
  borderTop: "none",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid #E5E5E5",
  fontWeight: 400,
  fontSize: "14px",
  fontFamily: "Inter",
  color: "#000000",
  lineHeight: "17px",
  fontStyle: "normal",
}));

const CreateVariantModal: React.FC<{
  onHide: () => void;
  variantModalType?: string;
  setShowUniteForm?: any;
  showUnitForm: any;
}> = ({ onHide, variantModalType, setShowUniteForm, showUnitForm }) => {
  // some imports
  const dispatch = useDispatch();
  const {
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm();

  // redux states

  const { LEVERS, BUSINESS_UNIT_List } = useSelector((state: any) => ({
    LEVERS: state.MainReducer.defaultLevers,
    BUSINESS_UNIT_List: state.MainReducer.AllBusinessUnitList,
  }));

  const { aiGroupList } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );

  const auth = useSelector((state: { auth: initialState }) => state.auth);

  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand, brandRoles } = adaccount;

  // local states
  const [AiGroupList, setAiGroupList] = useState<any>([]);
  const [masterCheck, setMasterCheck] = useState(false);
  const [businessUnitNameList, setBusinessUnitNameList] = useState<any>([]);
  const [showAIGroupUi, setShowUiGroupAi] = useState(false);
  const [show, setShow] = useState(false);
  const [showFields, setShowFields] = useState(true);
  const [alertConfirmEvent, setAlertConfirmEvent] = useState(false);
  const [selectedAiGroup, setSelectedAiGroup] = useState<any>();
  const [localElEmentUI, setLocalElementUI] = useState<any>();
  const [localBusinessUIData, setLocalBusinessUIData] = useState<any>();
  const [isFetchingElementUI, setIsFetchingElementUI] = useState(true);
  const [mandatoryCheck, setMandatoryCheck] = useState(
    localElEmentUI === null ? false : true
  );
  const [aiGroups, setAiGroups] = useState<SetAIGroupPayloadType>();
  const [savingElement, setSavingElement] = useState(false);
  // local variables
  const businessUnitID = watch("businessUnitId");

  const { minPanelBUFormSubmit } = useCaiFbMixPanel();
  // local functions
  const handleMasterCheckbox = (e: any) => {
    let tempList = AiGroupList;
    tempList.map((user: any) => (user.isChecked = !masterCheck));
    setMasterCheck(!masterCheck);
    setAiGroupList(tempList);
  };

  const handleSingleCheckbox = (e: any, item: any) => {
    let tempList: any = AiGroupList;
    tempList = tempList.map((user: any) => {
      if (user.id === item.id) {
        user.isChecked = e.target.checked;
      }
      return user;
    });

    const totalItems = AiGroupList.length;
    const totalCheckedItems = tempList.filter((e: any) => e.isChecked).length;
    setMasterCheck(totalItems === totalCheckedItems);
    setAiGroupList(tempList);
  };

  const onGenerate = (data: any) => {
    setSavingElement(true);
    const { headlineDescription, ...payload } = data;
    const aiGroupIds: any = [data.aiGroupName?.selection?.Elements?.[0]?.id];
    AiGroupList?.forEach((element: any) => {
      if (element?.isChecked) {
        aiGroupIds.push(element?.Elements?.[0]?.id);
      }
    });
    payload["elementId"] = aiGroupIds;
    const finalData = {
      brandName: payload.brandName,
      interestKeyword: payload.interestKeyword,
      businessUnitId: payload.businessUnitId,
      elementId: payload.elementId,
      primaryText: payload.primaryText,
    };

    const selectedAIGroupId = data.aiGroupName?.selection?.id;

    dispatch({
      type: "ADD_ELEMEMT_CREATIVE_AI",
      payload: {
        user: auth.user,
        elementData: {
          ...finalData,
          ...expandData(headlineDescription, generationLevers),
        },
        callback: (data: any) => {
          const aiGroupData: AIgroupMetaDataType[] = [];
          let newAiGroup;

          AiGroupList.forEach((element: any, index: number) => {
            if (element?.isChecked) {
              delete element?.isChecked;
              const id = data.find(
                (item: any) => item.elementId === element?.Elements[0]?.id
              )?.creativeAiId;
              aiGroupData.push({
                ...element,

                Elements: [
                  {
                    ...element?.Elements?.[0],
                    ElementCreativeAiData: [
                      {
                        id,
                      },
                    ],
                  },
                ],
              });
            } else if (element.id === selectedAIGroupId) {
              delete element?.isChecked;
              const id = data.find(
                (item: any) => item.elementId === element?.Elements[0]?.id
              )?.creativeAiId;
              newAiGroup = {
                ...element,

                Elements: [
                  {
                    ...element?.Elements?.[0],
                    ElementCreativeAiData: [
                      {
                        id,
                      },
                    ],
                  },
                ],
              };
              aiGroupData.push(newAiGroup);
            } else {
              delete element?.isChecked;
              aiGroupData.push(element);
            }
          });

          // here logic for comparing ai group will come
          if (aiGroupList.selectedAIGroup?.id === selectedAIGroupId) {
            dispatch(
              setAIGroups({
                ...aiGroupList,
                selectedAIGroup: cloneDeep(newAiGroup),
                data: aiGroupData,
              })
            );
          } else {
            dispatch(
              setAIGroups({
                ...aiGroupList,
                data: aiGroupData,
              })
            );
          }
          setSavingElement(false);
        },
      },
    });
    minPanelBUFormSubmit(selectedAiGroup, localBusinessUIData);
    setShowUniteForm(false);
  };

  const toggleAiGroupAi = () => {
    setShowUiGroupAi(!showAIGroupUi);
  };

  const handelAlertConfirm = (event: any) => {
    setAlertConfirmEvent(event);
  };

  const restoreDefaultSetting = () => {
    setShowFields(false);
    if (localBusinessUIData) {
      setTimeout(() => {
        reset({
          ...getBUFormValues(localBusinessUIData),
          aiGroupName: selectedAiGroup,
        });
        watch();
        setShowFields(true);
      }, 0);
    }
  };

  // memo and effects

  const data: APIGenerationType[] = useMemo(
    () => filterLevers(LEVERS, ["user"]),
    [LEVERS]
  );

  const headlineDescriptionLevers = useMemo(() => {
    return mergeData(cloneDeep(data));
  }, []);

  const generationLevers = useMemo(() => {
    return mergeData(cloneDeep(LEVERS));
  }, []);

  useEffect(() => {
    watch();
    dispatch({
      type: "GET_ALL_BUSINESS_UNIT_LIST",
      payload: {
        brandId: selectedBrand.id,
      },
    });

    localElEmentUI ? setMandatoryCheck(false) : setMandatoryCheck(true);
  }, []);

  useEffect(() => {
    if (BUSINESS_UNIT_List.length !== 0) {
      const temp: any = [];
      BUSINESS_UNIT_List?.map((item: any) => {
        if (item.status === "readyToUse") {
          temp.push({ value: item?.id, name: item?.businessUnit });
        }
      });
      setBusinessUnitNameList(temp);
    }
  }, [BUSINESS_UNIT_List.length]);

  useEffect(() => {
    if (selectedAiGroup) {
      if (
        selectedAiGroup.selection?.Elements?.[0]?.ElementCreativeAiData?.[0]
          ?.id &&
        selectedAiGroup.selection?.Elements?.[0]?.id
      ) {
        setShow(false);
        setIsFetchingElementUI(true);
        dispatch({
          type: "GET_ELEMENT_CREATIVE_AI",
          payload: {
            elementId: selectedAiGroup.selection?.Elements?.[0]?.id,
            callback: (data: any) => {
              setLocalElementUI(data.data);
              setIsFetchingElementUI(false);
            },
          },
        });
      } else {
        reset({
          businessUnitId: undefined,
          aiGroupName: selectedAiGroup,
        });
        setShow(false);
        setLocalElementUI(undefined);
        setIsFetchingElementUI(false);
        setMandatoryCheck(false);
      }
    }
  }, [selectedAiGroup]);

  useEffect(() => {
    if (!isFetchingElementUI) {
      if (localElEmentUI) {
        reset({
          ...getFormValues(localElEmentUI),
          aiGroupName: selectedAiGroup,
        });
        watch();
        setShow(true);
      } else {
        setShow(true);
      }
    }
  }, [localElEmentUI, isFetchingElementUI]);

  useEffect(() => {
    if (businessUnitID) {
      setShowFields(false);
      dispatch(
        getBusinessUintById({
          businessUnitId: businessUnitID,
          callback: (data: any) => {
            setLocalBusinessUIData(data);
          },
        })
      );
      setMandatoryCheck(true);
    } else {
      setLocalBusinessUIData(undefined);
    }
  }, [businessUnitID]);

  useEffect(() => {
    if (localBusinessUIData) {
      if (localElEmentUI?.BusniessUnit?.id !== businessUnitID) {
        reset({
          ...getBUFormValues(localBusinessUIData),
          aiGroupName: selectedAiGroup,
        });
      } else {
        reset({
          ...getFormValues(localElEmentUI),
          aiGroupName: selectedAiGroup,
        });
      }
      watch();
      setShow(true);
      setShowFields(true);
    }
  }, [localElEmentUI, localBusinessUIData]);

  // useEffect(() => {
  //   setAiGroupList(cloneDeep(aiGroupList.data));
  // }, [aiGroupList]);

  useEffect(() => {
    if (aiGroupList && aiGroupList?.data) {
      const arr: any = [];
      aiGroupList.data.forEach((item) => {
        arr.push({ ...item, isChecked: false });
      });

      setAiGroupList(arr);
    }
  }, [aiGroupList]);

  useEffect(() => {
    setAiGroups(cloneDeep(aiGroupList));
    setSelectedAiGroup({
      selection: aiGroupList?.selectedAIGroup,
    });
  }, [aiGroupList]);

  return (
    <PxDialog
      open={showUnitForm}
      onClose={() => {
        setValue("brandName", "");
        setValue("interestKeyword", "");
        setValue("businessUnitId", "");
        onHide();
      }}
      titleComponent={
        <BusinessUnitSettingModalTitle
          selected={
            aiGroupList.data.filter(
              (item) => item.Elements?.[0]?.ElementCreativeAiData?.[0]?.id
            ).length
          }
          total={aiGroupList.data.length}
        />
      }
      maxWidth="sm"
      fullWidth
      actions={
        <StyledFooter>
          <Button
            sx={{
              color: "#0869FB",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "0.75em",
              lineHeight: "20px",
            }}
            onClick={() => {
              restoreDefaultSetting();
            }}
          >
            Restore to Default Settings
          </Button>
          <RolesWrapper disabled={getRolesHierarchy(brandRoles) === "viewer"}>
            <Button
              variant="contained"
              sx={{
                background: "#0869FB",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "0.875em",
                lineHeight: "20px",
                borderRadius: "0.5em",
                padding: "10px 20px",
              }}
              onClick={handleSubmit(onGenerate)}
              style={
                !showFields || !businessUnitID
                  ? { backgroundColor: "#B5D2FE", color: "#ffffff" }
                  : {}
              }
              disabled={
                !showFields || !businessUnitID || savingElement
                // ||
                // isViewerRole(brandRoles)
              }
            >
              Save
            </Button>
          </RolesWrapper>
        </StyledFooter>
      }
    >
      <Box
        sx={{
          padding: "1.5em 0em",
        }}
        id="businessForm"
      >
        <StyledCreateVariantModalContainer>
          <StyledCreateVariantSettingTxt>
            Creative variants recommendation will depend on these settings:
          </StyledCreateVariantSettingTxt>
          <AIGroupDropdownController
            control={control}
            setSelectedAiGroup={setSelectedAiGroup}
            selectedAiGroup={selectedAiGroup}
            aiGroups={aiGroups}
            setAiGroups={setAiGroups}
          />
          {BUSINESS_UNIT_List.length !== 0 && show && (
            <SelectFormControl
              options={businessUnitNameList}
              field="name"
              valueKey="value"
              tooltipText="Business unit refers to an independent vertical of the brand. For example, if the brand is a bank, then, personal loan or credit card is a business unit."
              placeholder="Select Business Unit"
              required
              fullWidth
              control={control}
              registeredName="businessUnitId"
              label="Business Unit"
              height="40px"
              sx={{
                fontSize: "12px",
              }}
              isBorder
              backgroundColor="#fffffff"
            />
          )}

          {showFields && show ? (
            <>
              {mandatoryCheck ? (
                <>
                  <InputFormControl
                    label="Display Brand Name"
                    tooltipText="Display brand name refers to how you want the brand name to be displayed in the ad copy."
                    disableUnderline
                    control={control}
                    registeredName="brandName"
                    required
                    fullWidth
                    placeholder="Enter Brand Name"
                    sx={{ height: "40px", fontSize: "12px" }}
                    isBorder
                    backgroundColor="#fffffff"
                  />
                  <InputFormControl
                    label="Interest Keyword"
                    tooltipText="Interest keyword refers to the crux of the ad generations. It indicates what the ad should deliver based on the product/service category and what the ad generations should be based on."
                    disableUnderline
                    control={control}
                    registeredName="interestKeyword"
                    required
                    fullWidth
                    placeholder="Enter Interest Keyword"
                    sx={{ height: "40px", fontSize: "12px" }}
                    isBorder
                    backgroundColor="#fffffff"
                  />

                  <div className="d-flex justify-content-between align-item-center">
                    {/* <Link
                      to="#"
                      onClick={() => {
                        restoreDefaultSetting();
                      }}
                    >
                      Restore BU Default Settings
                    </Link> */}
                  </div>
                  <FormLevers
                    data={data}
                    headlineDescriptionLevers={headlineDescriptionLevers}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    errors={cloneDeep(errors)}
                    setError={setError}
                  ></FormLevers>
                  <Stack
                    marginTop={2}
                    direction="row"
                    display="flex"
                    alignItems="center"
                  >
                    <Box>
                      <CheckBox
                        checked={showAIGroupUi}
                        onClick={() => toggleAiGroupAi()}
                      />
                    </Box>
                    <Box>
                      <StyleAiGroupDuplicatePara>
                        Select AI Groups to duplicate this setup{" "}
                        <TooltipWithInfo
                          top={!showAIGroupUi ? "-90px" : ""}
                          info="All the settings will be duplicated for the selected AI Groups. You can still edit them individually by clicking on the setup icon against each AI Group."
                        />
                      </StyleAiGroupDuplicatePara>
                    </Box>
                  </Stack>
                  {showAIGroupUi && (
                    <div className="">
                      <SelectableTable
                        tableHead={selectableTableHead}
                        checkboxVisible={true}
                        masterCheck={masterCheck}
                        handleMasterCheckbox={handleMasterCheckbox}
                      >
                        {AiGroupList &&
                          AiGroupList.length !== 0 &&
                          AiGroupList.filter(
                            (item: any) =>
                              item?.id !==
                              getValues("aiGroupName")?.selection?.id
                          ).map((item: any) => {
                            return (
                              <Fragment key={item?.id}>
                                <StyledTableRow key={item?.id}>
                                  <StyledTableCell>
                                    <StyledCheckboxItem
                                      onChange={(e) =>
                                        handleSingleCheckbox(e, item)
                                      }
                                      checked={item?.isChecked}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {item?.name ?? "-"}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {item?.adsetsCount ?? "-"}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </Fragment>
                            );
                          })}
                      </SelectableTable>
                    </div>
                  )}
                </>
              ) : (
                <StyledPlaceholderContainer>
                  <img src={BusinessUnitPlaceHolder} alt="" />
                  <Typography fontWeight={900} variant="body1">
                    {BUSINESS_UNIT_List.length !== 0
                      ? "Select a business unit to view all options"
                      : "Please contact support to add a business unit"}
                  </Typography>
                </StyledPlaceholderContainer>
              )}
            </>
          ) : (
            <Loader />
          )}
        </StyledCreateVariantModalContainer>
      </Box>
      {alertConfirmEvent && (
        <AlertConfirm
          bodyText="Failed please try again"
          dialogType="alert" //"confirm" || "alert" || dialog
          headerText="Failed"
          completeButtonText="Ok"
          onHandel={handelAlertConfirm}
        />
      )}
    </PxDialog>
  );
};

export default CreateVariantModal;
