import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StyledReviewSection = styled(Box)`
  padding-top: 0.8rem;
  width: 50%;
`;

export const StyledReviewHeader = styled.span`
  display: block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #0869fb;
  padding-bottom: 1.2rem;
`;

export const StyledReviewContainer = styled(Box)`
  background: #fafafa;
  padding: 0.8rem 1.2rem;
  height: 100%;
  position: relative;
`;

export const StyledCommentContainer = styled.div`
  position: absolute;
  bottom: 2%;
  width: 94%;
  left: 2%;
`;

export const StyledReview = styled(Box)`
  background: #ffffff;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 1rem 0rem;
`;

export const StyledReviewDetails = styled.div`
  max-height: 57.5vh;
  overflow: scroll;
`;
