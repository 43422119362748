import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AdAccountState } from "store/types/adaccount";

export const useActionImpact = ({ setCreativeRotationToggle }: any) => {
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedPlatform } = adaccount;
  const { selectedAdAccount } = adaccount;

  useEffect(() => {
    if (!selectedPlatform?.name?.toLowerCase().includes("facebook")) {
      setCreativeRotationToggle(false);
    } else if (selectedAdAccount?.selectedAdAcc?.elementId === undefined) {
      setCreativeRotationToggle(false);
    }
  }, [selectedPlatform, selectedAdAccount?.selectedAdAcc?.elementId]);
};
