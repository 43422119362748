import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMediaStudioProcessing,
  setImageVGSTimer,
} from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import {
  GetMediaStudioProcessingPayloadType,
  GetMediaStudioProcessingReduxType,
  InitialMediaStudioType,
  SET_VIDEO_VGS_TIMER,
} from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";

export const useVGSPollingHook = () => {
  const dispatch = useDispatch();
  const timerRef = useRef<any>(null);
  const VideoTimerRef = useRef<any>(null);

  const { imageVgsTimer, videoVgsTimer } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );

  const getVGSProcessingResponse = useCallback(
    (payload: GetMediaStudioProcessingPayloadType, pollingExpireTime) => {
      dispatch(
        getMediaStudioProcessing(
          payload,
          (response: GetMediaStudioProcessingReduxType[]) => {
            const statusFailed = response.some(
              (item) => item.status === "FAILED"
            );
            const statusInitiated = response.some(
              (item) => item.status === "INITIATED"
            );

            if (statusFailed) {
              dispatch({
                type: payload.reduxActionType,
                payload: false,
              });
              dispatch({
                type: payload.failureReduxType,
                payload: true,
              });
            } else if (statusInitiated) {
              if (+new Date() - pollingExpireTime < 120000) {
                let timer = setTimeout(() => {
                  getVGSProcessingResponse(payload, pollingExpireTime);
                }, 8000);
                dispatch({
                  type: payload.timerRedux,
                  payload: timer,
                });
              } else {
                dispatch({
                  type: payload.timerRedux,
                  payload: true,
                });
                dispatch({
                  type: payload.failureReduxType,
                  payload: true,
                });
                dispatch({
                  type: payload.reduxActionType,
                  payload: false,
                });
              }
            } else {
              // logic for handling image data will come here
              dispatch({
                type: payload.storeReduxType,
                payload: response.length === 1 ? response[0] : response,
              });
              dispatch({
                type: payload.reduxActionType,
                payload: false,
              });
              dispatch({
                type: payload.failureReduxType,
                payload: false,
              });
            }
          }
        )
      );
    },
    []
  );

  useEffect(() => {
    timerRef.current = imageVgsTimer;
  }, [imageVgsTimer]);

  useEffect(() => {
    VideoTimerRef.current = videoVgsTimer;
  }, [videoVgsTimer]);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        dispatch(setImageVGSTimer(null));
      }
    },
    []
  );

  useEffect(
    () => () => {
      if (VideoTimerRef.current) {
        clearTimeout(VideoTimerRef.current);
        dispatch({
          type: SET_VIDEO_VGS_TIMER,
          payload: null,
        });
      }
    },
    []
  );
  return { getVGSProcessingResponse };
};
