import { ItemType } from "./Editor.types";
import { StyledToolBox, StyledToolbarItem } from "./Recommendations/styled";
import { ReactComponent as PrimaryTextIcon } from "../../../../../assets/svg/primaryText.svg";
import { ReactComponent as HeadlineIcon } from "../../../../../assets/svg/headline.svg";
import { ReactComponent as DescriptionIcon } from "../../../../../assets/svg/description.svg";
import { ReactComponent as ImageTextIcon } from "../../../../../assets/svg/imageText.svg";
import { ReactComponent as VideoIcon } from "../../../../../assets/svg/video.svg";
import { ReactComponent as Setting } from "../../../../../assets/svg/BlueSetting.svg";
import { setConfigurationState } from "../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { get } from "lodash";
import { LightTooltip } from "../../../../shared/LightTooltip";
import { StyledToolTip } from "./styled";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { checkAdType } from "../../utils/common.utils";
import { InitialMediaStudioType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";

export const ToolbarItems: ItemType[] = [
  {
    hash: "primary_text",
    label: "Primary Text",
    titleText: "Select Primary Text:",
    icon: () => <PrimaryTextIcon />,
    disabled: false,
    heading: "Text Studio",
  },
  {
    hash: "headline",
    label: "Headline",
    titleText: "Select Headline:",
    icon: () => <HeadlineIcon />,
    disabled: false,
  },
  {
    hash: "description",
    label: "Description",
    titleText: "Select Description:",
    icon: () => <DescriptionIcon />,
    disabled: false,
  },
  {
    hash: "image",
    label: "Image Text",
    titleText:
      "Select the recommendation bundle that best matches your creative:",
    icon: () => <ImageTextIcon />,
    disabled: false,
    heading: "Media Studio",
  },
  {
    hash: "video",
    label: "Video Text",
    titleText: "Select Video Text:",
    icon: () => <VideoIcon />,
    disabled: false,
  },
];
interface ToolbarProp {
  selected: ItemType | null;
  defaultValue: ItemType | null;
  onClick: (value: ItemType) => void;
  onConfigClick: () => void;
}
export const Toolbar = ({
  selected,
  onClick,
  onConfigClick,
  defaultValue,
}: ToolbarProp) => {
  const dispatch = useDispatch();
  const { createSpec } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );

  const {
    getMediaStudioData,
    isImageMediaStudioProcessing,
    isImageMediaStudioProcessingFailure,
    isVideoMediaStudioProcessing,
    isVideoMediaStudioProcessingFailure,
  } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );

  return (
    <StyledToolBox>
      <div className="flex-grow-1">
        {ToolbarItems.map((t) => {
          const { label, hash, icon, disabled, heading } = t;
          const adType = checkAdType(createSpec);

          return (
            <>
              {heading && <h3>{heading}</h3>}
              {(adType === "link_data" && hash === "video") ||
              (adType === "video_data" && hash === "image") ||
              ((hash === "video" || hash === "image") &&
                adType === "video_data" &&
                getMediaStudioData?.data?.usageType === "IMAGE_TEXT") ||
              ((hash === "video" || hash === "image") &&
                adType === "link_data" &&
                getMediaStudioData?.data?.usageType === "VIDEO_TEXT") ? (
                <div className="tooltip-container">
                  <StyledToolbarItem
                    disabled
                    active={
                      get(selected ? selected : defaultValue, "hash", "") ===
                      hash
                    }
                    key={hash}
                  >
                    {icon()}
                    <h6>{label}</h6>
                  </StyledToolbarItem>
                </div>
              ) : (
                <StyledToolbarItem
                  disabled={
                    ((!isImageMediaStudioProcessingFailure &&
                      isImageMediaStudioProcessing) ||
                      (!isVideoMediaStudioProcessingFailure &&
                        isVideoMediaStudioProcessing))??disabled
                  }
                  active={
                    get(selected ? selected : defaultValue, "hash", "") === hash
                  }
                  key={hash}
                  onClick={() => onClick(t)}
                >
                  {icon()}
                  <h6>{label}</h6>
                </StyledToolbarItem>
              )}
            </>
          );
        })}
      </div>
      <Button
        variant="outlined"
        startIcon={<Setting />}
        onClick={() => {
          onConfigClick();
          dispatch(setConfigurationState(true));
        }}
        className="configurationButton"
        disabled={((!isImageMediaStudioProcessingFailure &&
          isImageMediaStudioProcessing) ||
          (!isVideoMediaStudioProcessingFailure &&
            isVideoMediaStudioProcessing))}
      >
        <span className="configurationButton-label"> Configure</span>
      </Button>
    </StyledToolBox>
  );
};
