import { useState } from "react";
import { TextField, Typography } from "@mui/material";
import BasicModal from "common/modal";
import { isValidWebsite } from "../utils";
import { useSelector } from "react-redux";
import { mixPanelAdEvent } from "utils/mixpanel";

function AddBrand({
  open,
  setOpen,
  addBrand,
  getBrands,
  page,
  limit,
}: {
  open: boolean;
  setOpen: Function;
  addBrand: Function;
  getBrands: Function;
  page: number;
  limit: number;
}) {
  const [brandName, setBrandName] = useState("");
  const [brandDetails, setBrandDetails] = useState("");
  const [brandWebsite, setBrandWebsite] = useState("");
  const [isBrandNameError, setIsBrandNameError] = useState(false);
  const [isBrandDetailsError, setIsBrandDetailsError] = useState(false);
  const [isBrandWebsiteError, setIsBrandWebsiteError] = useState(false);
  const agency = useSelector((state: any) => state?.smb?.agencies?.selected);
  const resetBrandFormData = () => {
    setBrandName("");
    setBrandDetails("");
    setBrandWebsite("");
  };
  const onAddBrandSubmit = () => {
    let hasError = false;
    if (brandName.startsWith(" ") || brandName.length === 0) {
      setIsBrandNameError(true);
      hasError = true;
    } else {
      setIsBrandNameError(false);
    }

    if (brandDetails.startsWith(" ") || brandDetails.length === 0) {
      setIsBrandDetailsError(true);
      hasError = true;
    } else {
      setIsBrandDetailsError(false);
    }
    if (isValidWebsite(brandWebsite)) {
      setIsBrandWebsiteError(false);
    } else {
      setIsBrandWebsiteError(true);
      hasError = true;
    }

    const addBrandPayload = {
      name: brandName,
      agencyId: agency?.id,
      competitors: "",
      brandWebsite,
      brandDetails,
      brandType: "SMB",
    };
    if (!hasError) {
      addBrand(addBrandPayload).then((res: any) => {
        mixPanelAdEvent("SMB Brand Added", {
          agency_name: agency?.name,
          agency_id: agency?.id,
          brand_name: addBrandPayload.name,
          brand_type: addBrandPayload?.brandType,
          brand_details: addBrandPayload.brandDetails,
          brand_website: addBrandPayload.brandWebsite,
          brand_id: res?.data?.brand?.id,
        });
        getBrands(page + 1, limit);
        setOpen(false);
        resetBrandFormData();
      });
    }
  };
  return (
    <>
      <BasicModal
        title="Add Brand"
        open={open}
        setOpen={setOpen}
        showButton={true}
        height={480}
        width={523}
        onSubmit={onAddBrandSubmit}
      >
        <Typography>Name</Typography>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          sx={{ width: "100%", mb: "10px" }}
          value={brandName}
          onChange={(event: any) => {
            setBrandName(event.target.value);
          }}
        />
        {isBrandNameError && (
          <Typography
            className="font-12"
            sx={{
              color: "#FF2E2E",
              marginTop: "-8px",
            }}
          >
            Enter a valid brand name
          </Typography>
        )}
        <Typography>Brand Details</Typography>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          sx={{ width: "100%", mb: "10px" }}
          value={brandDetails}
          onChange={(event: any) => {
            setBrandDetails(event.target.value);
          }}
        />
        {isBrandDetailsError && (
          <Typography
            className="font-12"
            sx={{
              color: "#FF2E2E",
              marginTop: "-8px",
            }}
          >
            Enter valid brand details
          </Typography>
        )}
        <Typography>Brand Website</Typography>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          sx={{ width: "100%", mb: "10px" }}
          value={brandWebsite}
          onChange={(event: any) => {
            setBrandWebsite(event.target.value);
          }}
        />
        {isBrandWebsiteError && (
          <Typography
            className="font-12"
            sx={{
              color: "#FF2E2E",
              marginTop: "-8px",
            }}
          >
            Enter a valid brand website (website must start with 'https://')
          </Typography>
        )}
      </BasicModal>
    </>
  );
}

export default AddBrand;
