import {
  GET_ADACCOUNTS_LOG,
  GET_ADSET_LOG,
  GET_ADS_LOG,
  GET_AD_DETAILS,
  GET_FB_ACTIVITY_LOGS,
  SET_ACTIVITY_LOG_ADS,
  SET_ACTIVITY_LOG_ADSETS,
  SET_ACTIVITY_LOG_AD_ACCOUNTS,
  SET_ADACCOUNTS_LOG,
  SET_ADSET_LOGS,
  SET_ADS_LOGS,
  SET_FB_ACTIVITY_LOGS,
} from "store/types/CreativeAI_Revamp/LogScreen/logscreen.types";

export const getFbActivityLogs = (payload: any): any => ({
  type: GET_FB_ACTIVITY_LOGS,
  payload,
});

export const setFbActivityLogs = (payload: any): any => ({
  type: SET_FB_ACTIVITY_LOGS,
  payload,
});

export const setLogAdAccountsSelection = (payload: any): any => ({
  type: SET_ACTIVITY_LOG_AD_ACCOUNTS,
  payload,
});

export const setLogAdSetSelection = (payload: any): any => ({
  type: SET_ACTIVITY_LOG_ADSETS,
  payload,
});

export const setLogAdsSelection = (payload: any): any => ({
  type: SET_ACTIVITY_LOG_ADS,
  payload,
});

export const getAdAccountsLog = (payload: any): any => ({
  type: GET_ADACCOUNTS_LOG,
  payload,
});

export const setAdAccountsLog = (payload: any): any => ({
  type: SET_ADACCOUNTS_LOG,
  payload,
});

export const getAdSetsFBLog = (payload: any): any => ({
  type: GET_ADSET_LOG,
  payload,
});

export const setAdSetsFBLog = (payload: any): any => ({
  type: SET_ADSET_LOGS,
  payload,
});

export const getAdsFBLog = (payload: any): any => ({
  type: GET_ADS_LOG,
  payload,
});

export const setAdsFBLog = (payload: any): any => ({
  type: SET_ADS_LOGS,
  payload,
});

export const getAdDetails = (payload: any): any => ({
  type: GET_AD_DETAILS,
  payload,
});
