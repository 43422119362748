import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, { useMemo, useState } from "react";
import { ReactComponent as Gravtar } from "../../../../assets/svg/GravatarTAIICP.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/CloseIcon.svg";
import SelectUI from "./SelectUI/SelectUI";
import IndividualKeywords from "./IndividualKeywords/IndividualKeywords";
import { currencyFormatter } from "components/ActionDebugger/shared/utils/common.utils";
import { useDispatch, useSelector } from "react-redux";
import { refreshCohortICP, updateCohortICP } from "store/actions/targetingICP";
import { gravtar } from "../constants/gravtar";
import {
  getAgeList,
  getgravtarValue,
} from "Pages/TargetingAIPlaygroundBeta/utils";
import isObject from 'lodash/isObject';
import getDemographicsKeywordsFromFlexibleSpecObject from 'utils/getDemographicsKeywordsFromFlexibleSpecObject';
import type useFacebookTargetingEnumMapping from 'utils/useFacebookTargetingsEnumMapping';

const style = {
  fontSize: "0.75em",
  lineHeight: "1.25em",
  fontFamily: "Inter",
  fontWeight: "500",
  color: "#AEB6C4",
};

const MediumFont = {
  fontSize: "0.875em",
  lineHeight: "1.25em",
  fontWeight: "600",
  font: "Inter",
};

function CohortsModal({
  open,
  setOpen,
  cohortData,
  handleUpdateCohort,
  cohortIndex,
  facebookTargetingEnumMapping,
}: {
  open: boolean;
  setOpen: Function;
  cohortData: any;
  handleUpdateCohort: any;
  cohortIndex: number;
  facebookTargetingEnumMapping: ReturnType<typeof useFacebookTargetingEnumMapping>;
}) {
  const ageList = useMemo(() => getAgeList(), []);
  const [ageRange, setAgeRange] = useState({
    first: cohortData?.age?.age_min,
    second: cohortData?.age?.age_max,
  });
  const [gender, setGender] = useState<any>(
    cohortData?.gender?.genders !== null
      ? cohortData?.gender?.genders[0]
      : "all"
  );
  const keywordTuningData = useSelector((state: any) => state.keywordTuning);
  const [currentCohort, setCurrentCohort] = useState(cohortData);
  const dispatch = useDispatch();
  const handleAgeChange = (value: any, ageType: string) => {
    let newData = { ...currentCohort };
    if (ageType === "first") {
      setAgeRange({ ...ageRange, first: value });
      newData.age.age_min = value;
    } else {
      let newAgerange = { ...ageRange };
      newAgerange.second = value;
      newData.age.age_max = value;
      if (value < ageRange?.first) {
        newAgerange.first = value;
        newData.age.age_min = value;
      }
      setAgeRange(newAgerange);
    }
    newData.cohortChangeNotSaved = true;
    newData.targeting_spec={...newData.targeting_spec,...newData.age}
    setCurrentCohort(newData);
    setIsCohortUpdated(true);
  };
  const [isCohortUpdated, setIsCohortUpdated] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false);

  const handleDelete = (key: any, typeKey: string) => {
    let index = currentCohort[typeKey].findIndex(
      (value: any) => value.id === key
    );
    if (index > -1) {
      let newData = { ...currentCohort };
      newData[typeKey] = currentCohort[typeKey].filter(
        (value: any) => value.id !== key
      );
      let new_flexible_Spec:any=[...newData.targeting_spec.flexible_spec.map((item:any)=>{
        if(Object.keys(item)[0]===typeKey)
        {
          return {[typeKey]: [...item[typeKey].filter(
            (value: any) => value.id !== key
          )]}
        }
        else 
        return {...item}
       })]
       newData.targeting_spec={...newData.targeting_spec,flexible_spec:new_flexible_Spec}
      newData.cohortChangeNotSaved = true;
      setCurrentCohort(newData);
      setIsCohortUpdated(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCohortRefresh = () => {
    let newCohortData = { ...currentCohort };
    newCohortData.cohort_targeting_spec = currentCohort?.targeting_spec;
    dispatch(
      refreshCohortICP(
        {
          payloadData: {
            cohorts_json: keywordTuningData,
            updated_cohorts: [newCohortData],
          },
        },
        (response: any, error: boolean) => {
          if (!error) {
            let newCohortData = { ...currentCohort };
            newCohortData.audience_size = response[0]?.audience_size;
            newCohortData.relevance = response[0]?.relevance;
            newCohortData.cohortChangeNotSaved = true;
            setCurrentCohort(newCohortData);
            setIsCohortUpdated(false);
          }
        }
      )
    );
  };

  const onGenderChange = (value: any) => {
    setGender(value);
    let newData = { ...currentCohort };
    if (value !== "all") {
      newData.gender = {};
      newData.gender.genders = [value];
    } else {
      newData.gender.genders = null;
    }
    newData.cohortChangeNotSaved = true;
    newData.targeting_spec={...newData.targeting_spec,genders:[value]}
    setCurrentCohort(newData);
    setIsCohortUpdated(true);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        {warningPopup && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "transparent",
              padding: 2,
              zIndex: "12000",
            }}
          >
            <Typography
              sx={{
                fontSize: "30px",
                lineHeight: "36px",
                fontWeight: "700",
                fontFamily: "Lato",
                color: "#333333",
                textAlign: "center",
                mb: 1,
              }}
            >
              Are you sure?
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "500",
                fontFamily: "Inter",
                color: "#727272",
                textAlign: "center",
                mb: 1,
              }}
            >
              The changes are not saved
            </Typography>
            <Box display={"flex"} alignItems={"center"}>
              <Button
                variant="outlined"
                onClick={() => setWarningPopup(false)}
                sx={{
                  textTransform: "capitalize",
                  mr: 2,
                  border: "1px solid #D9D9D9",
                  color: "#A6A6A6",
                  "&:hover": {
                    border: "1px solid #D9D9D9",
                    color: "#A6A6A6",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setWarningPopup(false);
                  setOpen(false);
                }}
                sx={{
                  textTransform: "capitalize",
                  background: "#EE4E4A",
                  color: "#FFF",
                  "&:hover": {
                    background: "#EE4E4A",
                    color: "#FFF",
                  },
                }}
              >
                Close anyway
              </Button>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: 24,
            borderRadius: "8px",
            width: "80%",
            padding: 2,
            filter: warningPopup ? "blur(5px)" : "none",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"} alignItems={"center"}>
              <img
                width={"35px"}
                src={gravtar[getgravtarValue(ageRange, cohortData?.gender)]}
              />
              {/* <Gravtar /> */}
              <Typography
                sx={{
                  fontSize: "1em",
                  lineHeight: "1.5em",
                  fontWeight: "600",
                  font: "Inter",
                  color: "#000000",
                  ml: 1,
                }}
              >
                {`Cohort C${currentCohort.cohort_id} : `}
                <span
                  style={{
                    ...MediumFont,
                    color: "#5C6674",
                    marginLeft: "0.5em",
                  }}
                >
                  {currentCohort?.cohort_name}
                </span>
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                if (currentCohort?.cohortChangeNotSaved) {
                  setWarningPopup(true);
                } else {
                  setOpen(false);
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            display={"flex"}
            sx={{
              backgroundColor: "#F6F7F8",
              mt: 2,
              padding: 1.5,
              gap: "1%",
            }}
          >
            <Box
              display={"flex"}
              sx={{
                backgroundColor: "#FFFFFF",
                p: 1.5,
                flex: "0 0 49.5%",
              }}
            >
              <Box
                sx={{
                  flex: isCohortUpdated ? "0 0 40%" : "0 0 49.5%",
                }}
              >
                <Typography
                  sx={{
                    ...style,
                    mb: 1,
                  }}
                >
                  Potential Audience size
                </Typography>
                <Typography
                  sx={{
                    ...MediumFont,
                  }}
                >
                  {isCohortUpdated
                    ? "-"
                    : currencyFormatter(currentCohort?.audience_size)}
                </Typography>
              </Box>
              <Box
                sx={{
                  flex: isCohortUpdated ? "0 0 40%" : "0 0 49.5%",
                }}
              >
                <Typography
                  sx={{
                    ...style,
                    mb: 1,
                  }}
                >
                  Relevance Score
                </Typography>
                <Typography
                  sx={{
                    ...MediumFont,
                  }}
                >
                  {isCohortUpdated ? "-" : currentCohort?.relevance}
                </Typography>
              </Box>
              <Box
                sx={{
                  flex: isCohortUpdated ? "0 0 18%" : "0 0 49.5%",
                }}
              >
                {isCohortUpdated && (
                  <Button
                    variant="outlined"
                    onClick={() => handleCohortRefresh()}
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Refresh
                  </Button>
                )}
              </Box>
            </Box>
            <Box
              display={"flex"}
              sx={{
                backgroundColor: "#FFFFFF",
                p: 1.5,
                flex: "0 0 49.5%",
              }}
            >
              <Box
                sx={{
                  flex: "0 0 49.5%",
                }}
              >
                <Typography
                  sx={{
                    ...style,
                    mb: 1,
                  }}
                >
                  Age
                </Typography>
                <Box>
                  <SelectUI
                    data={ageList}
                    value={ageRange?.first}
                    handleChange={(event: any) =>
                      handleAgeChange(event?.target?.value, "first")
                    }
                  />
                  <span
                    style={{
                      ...style,
                      fontWeight: 700,
                      color: "#0869FB",
                      margin: "0 0.8em",
                    }}
                  >
                    -
                  </span>
                  <SelectUI
                    data={ageList}
                    value={ageRange?.second}
                    handleChange={(event: any) =>
                      handleAgeChange(event?.target?.value, "second")
                    }
                  />
                  <span
                    style={{
                      ...style,
                      fontWeight: 700,
                      color: "#0869FB",
                      marginLeft: "0.4em",
                    }}
                  >
                    yrs
                  </span>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: "0 0 49.5%",
                }}
              >
                <Typography
                  sx={{
                    ...style,
                    mb: 1,
                  }}
                >
                  Gender
                </Typography>
                <SelectUI
                  value={gender}
                  data={[
                    { label: "All", value: "all" },
                    { label: "Male", value: 1 },
                    { label: "Female", value: 2 },
                  ]}
                  handleChange={(event: any) => {
                    onGenderChange(event?.target?.value);
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            display={"flex"}
            sx={{
              gap: 1.5,
              mt: 1.5,
            }}
          >
            <IndividualKeywords
              title={"Interests Keywords"}
              typeKey={"interests"}
              chipData={currentCohort?.interests}
              handleDelete={handleDelete}
            />
            <IndividualKeywords
              title={"Demographics Keywords"}
              typeKey={"demographics"}
              chipData={isObject(currentCohort?.demographics)
                ? getDemographicsKeywordsFromFlexibleSpecObject(currentCohort?.demographics, facebookTargetingEnumMapping)
                : currentCohort?.demographics}
              handleDelete={handleDelete}
            />
            <IndividualKeywords
              title={"Behaviour Keywords"}
              typeKey={"behaviors"}
              chipData={currentCohort?.behaviors}
              handleDelete={handleDelete}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2.5,
            }}
          >
            <Button
              sx={{
                fontFamily: "0.875em",
                lineHeight: "1em",
                color: "#FFFFFF",
                textTransform: "capitalize",
                padding: "0.875em 1.5em",
                boxShadow: "none",
                borderRadius: "6px",
                fontWeight: 500,
                backgroundColor: "#0869FB",
                "&: hover": {
                  color: "#FFFFFF",
                  boxShadow: "none",
                  backgroundColor: "#0869FB",
                },
              }}
              variant="contained"
              onClick={() => {
                handleUpdateCohort(currentCohort);
                setOpen(false);
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  );
}

export default CohortsModal;
