import GoogleApprover from "components/CreativeAI/GsaiPlayground/Review/GoogleApprover";
import ReviewLayout from "components/ReviewLayout/ReviewLayout";
import React from "react";

const GoogleReview: React.FC<{}> = (): JSX.Element => {
  return (
    <ReviewLayout type="google">
      <GoogleApprover />
    </ReviewLayout>
  );
};

export default GoogleReview;
