import { Box } from "@mui/material";
import ThirdPartyTable from "./ThirdPartyTable";

const ThirdParty = (props: any) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <ThirdPartyTable {...props} />
    </Box>
  );
};

export default ThirdParty;
