import { useState } from "react";
import ButtonUI from "../../../ui/ButtonUI/ButtonUI";
import Dropdown from "../../../ui/Dropdown/Dropdown";
import { ReactComponent as DropDownArrow } from "../../../assets/svg/dropdownarrow.svg";
import NavigationDropDownItem from "../../Navigation/NavigationDropDownItem";

interface CreateAdGroupDropDownProps {
  clusterObj: any;
  selectAdGroup: any;
  index: number;
}

export default function CreateAdGroupDropDown({
  clusterObj,
  selectAdGroup,
  index,
}: CreateAdGroupDropDownProps) {
  const [showAdGroupDropdown, setShowAdGroupDropdown] = useState(false);

  return (
    <Dropdown
      isDropDownVisible={showAdGroupDropdown}
      setIsDropDownVisible={setShowAdGroupDropdown}
      className="ad-dropdown"
      toggelButton={
        <ButtonUI className="btn secondary select-ad-dropdown-btn">
          <div className="btn-container">
            <label>
              {clusterObj?.isCreate === true
                ? "New Ad Group"
                : clusterObj?.isCreate === false
                ? "Existing Ad Group"
                : "Select Ad Group"}
            </label>
            <DropDownArrow />
          </div>
        </ButtonUI>
      }
    >
      <NavigationDropDownItem
        onSelect={(value: any) => {
          selectAdGroup(value, clusterObj, index);
        }}
        data={[
          { name: "New Ad Group", value: "createAdGroup", id: "createAdGroup" },
          {
            name: "Add to existing Ad Group",
            value: "existingAdGroup",
            id: "existingAdGroup",
          },
        ]}
        setVisible={setShowAdGroupDropdown}
      />
    </Dropdown>
  );
}
