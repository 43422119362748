import styled from "styled-components";
import { Box, Button } from "@mui/material";
import { styled as MUIstyled } from "@mui/material";
import { ReactComponent as PacingSvg } from "assets/svg/pacing.svg";

export const PacingHeading = styled.span`
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 0.02rem;
  color: #333333;
  padding-top: 1rem;
  word-spacing: 0.2rem;
`;

export const Pacing = styled.span`
  color: #0869fb;
`;

export const PacingDescription = styled.span`
  padding-top: 1.25rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.6rem;
  color: #5a5a5a;
  margin: 0px 3.75rem;
  text-align: center;
`;

export const StyledPacingEmptyContainer = MUIstyled(Box)`
    background: #FFFFFF;
    border-radius: .625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const StyledPacingSvg = styled(PacingSvg)`
  width: 16.25rem;
`;

export const StyledAddPacingButton = MUIstyled(Button)`
    background: #EDF4FF;
    border-radius: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    color: #0869FB;
    text-transform: capitalize;
    &:disabled {
      color: #0869FB;
      opacity: 0.5;
    }
`;
