import React from "react";
import constants from "../constants/index";
import { Box, Card, Typography } from "@mui/material";
import GreyLoader from "../assets/gif/GreyLoader.gif";

const {
  TILE_BACKGROUND_COLOR,
  TILE_CONTENT_COLOR,
  TILE_BORDER_COLOR,
  SELECTED_BG_COLOR,
  SELECTED_TEXT_COLOR,
} = constants;

interface ISingleTileProps {
  value: any;
  label: string;
  Icon: any;
  tileKey: string;
  isSelected?: boolean;
  deviation?: any;
  clickHandler?: Function;
  isSecondaryTile?: boolean;
  currencyType?: string;
  isLoading?: boolean;
  selectedClassName?: string;
}
function SingleTile(props: ISingleTileProps) {
  const {
    value,
    label,
    Icon,
    tileKey,
    isSelected,
    clickHandler,
    deviation,
    isSecondaryTile,
    currencyType,
    isLoading,
    selectedClassName,
  } = props;

  return (
    <Card
      className={`${selectedClassName && isSelected ? selectedClassName : ""}`}
      sx={{
        m: 2,
        borderRadius: 1,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.06);",
        backgroundColor: `${
          !isSelected
            ? TILE_BACKGROUND_COLOR[tileKey] || "#FFF"
            : SELECTED_BG_COLOR[tileKey] || "#FFF"
        }`,
        border: `${
          isSelected
            ? `2px solid ${TILE_BORDER_COLOR[tileKey]}`
            : "2px solid transparent"
        }`,
        cursor: isSecondaryTile ? "default" : "pointer",
      }}
      onClick={() => {
        clickHandler?.(tileKey);
      }}
    >
      <Box
        className="d-flex align-items-center justify-content-between"
        sx={{ p: 1.5, width: "100%" }}
      >
        <Box
          sx={{
            width: "80%",
            maxWidth: "80%",
            color: `${
              !isSelected
                ? TILE_CONTENT_COLOR[tileKey] || "#000"
                : SELECTED_TEXT_COLOR[tileKey] || "#000"
            }`,
          }}
        >
          <Typography
            className="d-flex align-items-center "
            variant="h5"
            sx={{ fontWeight: 600, mb: 0.5, wordBreak: "break-word" }}
          >
            {currencyType}&nbsp;
            {isLoading ? (
              <img
                src={GreyLoader}
                alt="Loading"
                style={{ height: "2rem", width: "auto" }}
              ></img>
            ) : (
              value
            )}
            {isSecondaryTile && deviation}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              lineHeight: "1rem",
              textTransform: "capitalize",
              wordBreak: "break-word",
            }}
          >
            {label}
          </Typography>
        </Box>
        <Box>
          {isSecondaryTile ? (
            <Box
              className="rounded-circle"
              style={{
                backgroundColor: "rgba(135, 135, 135, 0.1)",
                padding: "0.6rem",
              }}
            >
              {Icon}
            </Box>
          ) : (
            Icon
          )}
        </Box>
      </Box>
    </Card>
  );
}

export default SingleTile;
