import styled from "styled-components";
import { Box, styled as muiStyled } from "@mui/system";

export const StyledPaginatedText = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 0.875em;
  color: #999999;
`;

export const StyledKeyIndicatorItemCard = muiStyled(Box)`
background: ${({ backgroundColor }: { backgroundColor: string }) =>
  backgroundColor};
border-radius: .5em;
padding: .7em 0.3em .7em 1em ;
display: flex;
align-items: center;
justify-content: space-between;
`;

export const StyledKeyIndicatorTitleText = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.1875em;
  display: flex;
  align-items: center;
  color: #454256;
  padding-left: 0.3em;
`;

export const StyledMetricText = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.1875em;
  display: flex;
  align-items: center;
  text-align: right;
  color: #454256;
  margin-left: 0.5625em;
`;
