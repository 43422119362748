import React from "react";
import { Typography, Grid } from "@mui/material";
import { amountValue } from "../../../../utils/commonFunctions";
import RangeSlider from "../../../TargetingAIPlaygroundBeta/GoogleSearch/components/KeywordExplorerNode/components/RangeSlider";

const DoubleSlider = ({ min, max, heading, onChange, selected, step }: any) => (
  <Grid container className="w-100" key={heading}>
    <Grid item xs={6}>
      <Typography className="font-10" sx={{ color: "#999999" }}>
        {heading}
      </Typography>
    </Grid>
    <Grid item xs={6} justifyContent="end" display="flex" alignItems="center">
      <Typography
        className="font-10"
        sx={{
          color: "#4CA3EA",
          px: "0.25rem",
          py: "0,063rem",
          borderRadius: "0.25rem",
          background: "#E2EBFA",
        }}
      >
        {amountValue(Math.floor(min))}
      </Typography>
      <Typography className="font-10" sx={{ color: "#4CA3EA" }}>
        &nbsp;-&nbsp;
      </Typography>
      <Typography
        className="font-10"
        sx={{
          color: "#4CA3EA",
          px: "0.25rem",
          py: "0,063rem",
          borderRadius: "0.25rem",
          background: "#E2EBFA",
        }}
      >
        {amountValue(Math.ceil(max))}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <RangeSlider
        min={min}
        max={max}
        step={step}
        selectedValue={selected}
        onChange={(values: [number, number]) => {
          onChange(values);
        }}
      />
    </Grid>
    <Grid item xs={6}>
      <Typography className="font-10" sx={{ color: "#4CA3EA" }}>
        Min
      </Typography>
    </Grid>
    <Grid item xs={6} justifyContent="end" display="flex">
      <Typography className="font-10" sx={{ color: "#4CA3EA" }}>
        Max
      </Typography>
    </Grid>
  </Grid>
);

export default DoubleSlider;
