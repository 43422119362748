import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PlusIcon } from "../../assets/svg/plus.svg";
import {
  setAttributionMetric,
  setChartTabs,
  updateMetric,
  updateMetricList,
} from "../../store/actions/campaign";
import { CampaignState, Metrics } from "../../store/types/campaign";
import { initialState } from "../../store/types/auth";
import CheckBox from "../../ui/CheckBox/CheckBox";
import MultiSelect, {
  MultiSelectedItem,
} from "../../ui/MultiSelect/MultiSelect";
import Tooltip from "../../ui/Tooltip/Tooltip";
import { getCurrencySymbol } from "../../utils/commonFunctions";
import "./actionImpaceMetrics.scss";
import { AdAccountState } from "../../store/types/adaccount";
import { FACEBOOK_PLATFORM_NAME } from "../../utils/constants";

const ActionImpaceMetrics: React.FC = () => {
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const campaignState = useSelector(
    (state: { campaign: CampaignState }) => state.campaign
  );
  const { chartTabs, metricCumulative } = campaignState;
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedPlatform, selectedAdAccount, brandSources } = adaccount;
  const dispatch = useDispatch();
  const [addedChart, setAddedChart] = React.useState([]);
  const [updateMetricLoader, setUpdateMetricLoader] = React.useState(false);
  const chartTabMultiSelect: any = React.useMemo(
    () =>
      chartTabs.map((tab: any) => ({
        group_id: tab.group_id,
        name: tab.label,
        value: tab.id,
        isSelected: tab.uiVisible,
        color: tab.color,
        filter: tab.filter,
        isAttribuationMetric: tab?.isAttribuationMetric,
        sourceId: tab?.sourceId,
      })),
    [chartTabs]
  );

  const onCheckboxChange = (
    value: any[] | boolean,
    item?: MultiSelectedItem,
    type?: string
  ) => {
    let chartTabsData: any = [...(chartTabs || [])];
    const index = chartTabsData.findIndex(
      (c: any) => c.label === (type === undefined ? item?.name : item?.label)
    );
    if (type === "select") {
      chartTabsData[index].isSelected = value;
      dispatch(setChartTabs(chartTabsData));
    } else if (item?.isAttribuationMetric) {
      dispatch(
        updateMetricList(
          {
            elementId: selectedAdAccount?.elementId,
            payload: {
              uiVisible: !chartTabsData[index]?.uiVisible,
              filter:
                type === "delete" ? item?.filter : chartTabsData[index]?.filter,
              label:
                type === "delete" ? item?.label : chartTabsData[index]?.label,
              isAttribuationMetric: true,
            },
          },
          (response: any, error: boolean) => {
            if (!error) {
              chartTabsData[index].isSelected = value;
              chartTabsData[index].uiVisible = value;
              dispatch(setChartTabs(chartTabsData));
              setUpdateMetricLoader(false);
              dispatch(setAttributionMetric(true));
            } else {
              console.log(error);
              setUpdateMetricLoader(false);
              dispatch(setChartTabs(chartTabsData));
            }
          }
        )
      );
    } else {
      const charttab = chartTabsData[index];
      if (type === "select") {
        chartTabsData[index].isSelected = value;
        dispatch(setChartTabs(chartTabsData));
      } else if (type === "delete") {
        setUpdateMetricLoader(true);
        dispatch(
          updateMetric(
            {
              metricId: charttab?.id,
              payload: {
                uiVisible: value,
              },
            },
            (response: any, error: boolean) => {
              if (!error) {
                chartTabsData[index].isSelected = value;
                chartTabsData[index].uiVisible = value;
                dispatch(setChartTabs(chartTabsData));
                setUpdateMetricLoader(false);
              } else {
                console.log(error);
                setUpdateMetricLoader(false);
                dispatch(setChartTabs(chartTabsData));
              }
            }
          )
        );
      } else {
        const value = !charttab.uiVisible;
        setUpdateMetricLoader(true);
        dispatch(
          updateMetric(
            {
              metricId: charttab?.id,
              payload: {
                uiVisible: value,
              },
            },
            (response: any, error: boolean) => {
              if (!error) {
                chartTabsData[index].uiVisible = value;
                chartTabsData[index].isSelected = value;
                dispatch(setChartTabs(chartTabsData));
                setUpdateMetricLoader(false);
              } else {
                console.log(error);
                setUpdateMetricLoader(false);
                dispatch(setChartTabs(chartTabsData));
              }
            }
          )
        );
      }
    }
  };

  React.useEffect(() => {
    let checkIsAdded = [] as any;
    checkIsAdded = chartTabs
      .filter((c: any) => c.uiVisible)
      .map((res: any) => ({
        ...res,
        value: res.id,
      }));
    setAddedChart(checkIsAdded);
  }, [chartTabs]);

  return (
    <div className="mt-2 ml-4 chart-tabs">
      {addedChart.map((c: any, index: number) => (
        <div className="chart-tab-header-container" key={c.id}>
          <CheckBox
            style={{ color: c.color }}
            className="campaign-checkbox"
            value={c.isSelected}
            onChange={(value) => {
              onCheckboxChange(value, c, "select");
            }}
          />
          <div>
            <div>
              {" "}
              <div className="campaign-label">
                <Tooltip className="metric-tooltip" tooltipContainer={c.label}>
                  {c.label}
                </Tooltip>
              </div>
            </div>

            <div className="campaign-cumulative">
              {metricCumulative && metricCumulative[c.label]
                ? (c.showCurrency && authState?.accountDetails?.currency
                    ? `${
                        getCurrencySymbol(
                          authState?.accountDetails?.currency
                        ) || ""
                      } `
                    : "") +
                  (selectedPlatform?.name &&
                  selectedPlatform.name.toLowerCase().includes("google") &&
                  c.showCurrency
                    ? (
                        parseFloat(metricCumulative[c.label]) / 1_000_000
                      ).toFixed(2)
                    : c.metricType === "PERCENTAGE" && !selectedPlatform.name.toLowerCase().includes("apple")
                    ? (parseFloat(metricCumulative[c.label]) * 100).toFixed(2)
                    : parseFloat(metricCumulative[c.label]).toFixed(2))
                : "00.00"}
            </div>
          </div>
          {chartTabs.filter((c: any) => c.uiVisible).length! > 1 && (
            <div
              className="chart-tab-delete-container"
              onClick={() => {
                onCheckboxChange(false, c, "delete");
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.04232 7.01432L10.2852 4.77147L11.2519 5.73821L9.00907 7.98107L11.5502 10.5222L10.5227 11.5497L7.98156 9.00858L5.7387 11.2514L4.77195 10.2847L7.01481 8.04183L4.66699 5.69402L5.69451 4.6665L8.04232 7.01432Z"
                  fill="#999999"
                />
                <circle cx="8" cy="8" r="7.5" stroke="#C4C4C4" />
              </svg>
            </div>
          )}
        </div>
      ))}
      <MultiSelect
        value={chartTabMultiSelect}
        onChange={onCheckboxChange}
        isSingleMetric={true}
        loader={updateMetricLoader}
        isIconForMetric={true}
        brandSources={brandSources}
        className={`
        ${
          addedChart.length > 0
            ? "add-metrics-dropdown"
            : "add-metrics-dropdown add-metrics-empty"
        } 
        ${addedChart.length > 4 ? "add-metrics-dropdown-left" : ""}
        ${addedChart.length > 5 ? "add-metrics-dropdown-disabled" : ""}
        `}
        children={
          <div className="tooltip-container">
            <PlusIcon />
            {addedChart.length === 6 && (
              <div className="custom-tooltip-layout tooltip">
                <div className="tootltip-wrapper">
                  You can add maximum 6 metrics
                </div>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default ActionImpaceMetrics;
