import React, { useRef } from "react";
import Select from "../../ui/Select/Select";
import ImageVideoCard from "../../ui/ImageVideoCard/ImageVideoCard";
import InputText from "../../ui/InputText/InputText";
import UploadFileButton from "../../ui/UploadFileButton/UploadFileButton";
import OutlineModal from "./OutlineModal";
import info from "../../assets/svg/smallinfo.svg";
import { ReactComponent as ProductDetails } from "../../assets/svg/productDetails.svg";
import { ReactComponent as MontageCampaignSvg } from "../../assets/svg/montageCampaign.svg";
import { ReactComponent as Vector } from "../../assets/svg/Vector.svg";
import { ReactComponent as Download } from "../../assets/svg/download.svg";
import { ReactComponent as Delete } from "../../assets/svg/delete.svg";
import { MontageCampaigns } from "../../store/types/montageAds";
import { productsCategory, currency } from "../../utils/constants/motageAds";
import { useDispatch, useSelector } from "react-redux";
import { getS3Url, uploadToS3Url } from "../../store/actions/montageAds";
import { initialState } from "../../store/types/auth";

const MontageSetup: React.FC<{
  handleInputChange: (value: any) => void;
  handleChange: (value: any) => void;
  montageAds: MontageCampaigns;
  setMontageAds: any;
  montageValidation: any;
  setMontageValidation: any;
  showModal: any;
  setShowModal: any;
}> = ({
  handleInputChange,
  handleChange,
  montageAds,
  setMontageAds,
  montageValidation,
  setMontageValidation,
  showModal,
  setShowModal,
}) => {
  const target = useRef(null);
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const { user } = authState;
  const dispatch = useDispatch();
  const [deleteCreative, setDeleteCreative] = React.useState(false);
  const [deleteID, setDeleteID] = React.useState<any>();
  const [imageLoader, setImageLoader] = React.useState(false);

  const files: any[] = [];
  const handelFileSelect = (value: any) => {
    setImageLoader(true);
    const fileData = value.map((v: any) => {
      const extension = v.name.split(".").pop();
      return {
        name: v.name,
        type: extension == "mkv" ? "video/x-matroska" : v.type,
        file: v,
      };
    });
    Promise.all(
      fileData.map((file: any) => {
        return new Promise((res) => {
          dispatch(
            getS3Url(
              { mimetype: file.type },
              (response: any, error: boolean) => {
                if (!error) {
                  res({ name: [file.name], url: response.data.url });
                }
              }
            )
          );
        });
      })
    )
      .then((data) => {
        Promise.all(
          data.map((d: any) => {
            const file = fileData.find((f: any) => f.name == d.name).file;
            return new Promise((res) => {
              dispatch(
                uploadToS3Url(
                  { url: d.url, file, user },
                  (response: any, error: boolean) => {
                    if (!error) {
                      res({ url: d.url.split("?")[0], name: file?.name });
                    }
                  }
                )
              );
            });
          })
        )
          .then((data) => {
            setMontageAds((montageAds: MontageCampaigns) => ({
              ...montageAds,
              productFiles: [...montageAds.productFiles, ...data],
            }));
            setImageLoader(false);
          })
          .catch(() => {
            setImageLoader(false);
          });
      })
      .catch(() => {
        setImageLoader(false);
      });
  };

  const handleDownloadEvent = (id: string, item: any) => {
    window.location.href = item?.url;
  };

  const handleDeleteCreative = (id: any) => {
    setShowModal(true);
    setDeleteCreative(true);
    setDeleteID(id);
  };
  const handleDeleteEvent = () => {
    const localFiles = [...montageAds.productFiles];
    localFiles.splice(deleteID, 1);
    setMontageAds((montageAds: MontageCampaigns) => ({
      ...montageAds,
      productFiles: localFiles,
    }));
    setDeleteCreative(false);
  };

  const handleNumberInput = (e: any) => {
    if (e.target.value.trim() == "") {
      setMontageValidation({
        ...montageValidation,
        creativesCountEmpty: true,
        creativesCountNegative: false,
        creativesCountDecimal: false,
      });
    }
    if (e.target.value > 0) {
      setMontageValidation({
        ...montageValidation,
        creativesCountEmpty: false,
        creativesCountNegative: false,
        creativesCountDecimal: false,
      });
    }
    if (e.target.value.includes(".") || e.target.value.includes("e")) {
      setMontageValidation({
        ...montageValidation,
        creativesCountDecimal: true,
        creativesCountEmpty: false,
        creativesCountNegative: false,
      });
    }
    if (
      e.target.value.includes("-") ||
      (e.target.value == 0 && e.target.value !== "")
    ) {
      setMontageValidation({
        ...montageValidation,
        creativesCountNegative: true,
        creativesCountDecimal: false,
        creativesCountEmpty: false,
      });
    }
    setMontageAds((montageAds: MontageCampaigns) => ({
      ...montageAds,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDecimalInput = (e: any) => {
    if (e.target.value.trim() == "") {
      setMontageValidation({
        ...montageValidation,
        productValueEmpty: true,
        productValueNegative: false,
      });
    }
    if (e.target.value > 0) {
      setMontageValidation({
        ...montageValidation,
        productValueEmpty: false,
        productValueNegative: false,
      });
    }
    if (
      e.target.value.includes("-") ||
      e.target.value < 0 ||
      (e.target.value == 0 && e.target.value !== "")
    ) {
      setMontageValidation({
        ...montageValidation,
        productValueNegative: true,
        productValueEmpty: false,
      });
    }

    setMontageAds((montageAds: MontageCampaigns) => ({
      ...montageAds,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div className="first-row setup">
      <div className="montage-setup">
        <div className="setup-heading">
          <div className="heading-icon">
            <MontageCampaignSvg />
          </div>
          <h6>Montage Setup</h6>
        </div>
        <div className="montage-details">
          <div
            key={`montage_campaign`}
            className="form-group px-3 montageDetails"
          >
            <label className="text-md">
              Montage Campaign*
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip montage-tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">Montage Campaign</div>
                    <span> A unique name to identify your campaign.</span>
                  </div>
                </div>
              </div>
            </label>
            <InputText
              className="montage-input"
              name="name"
              placeholder={`Add Campaign Name`}
              value={montageAds.name}
              onChange={(e: any) => handleInputChange(e)}
              required={true}
              maxlength="99"
            />
            {montageValidation?.name && (
              <span style={{ color: "red" }}>
                <small>*This field is required</small>
              </span>
            )}
          </div>

          <div
            key={`montage_content`}
            className="form-group px-3 montageDetails"
          >
            <label className="text-md">
              No of content(s)*
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip montage-tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">Number of contents</div>
                    <span>
                      {" "}
                      Total number of creatives with that you need to run your
                      campaign.
                    </span>
                  </div>
                </div>
              </div>
            </label>
            <InputText
              type="number"
              className="montage-input"
              name="creativesCount"
              placeholder={`10`}
              value={montageAds.creativesCount}
              onChange={(e: any) => handleNumberInput(e)}
              required={true}
            />
            {montageValidation?.creativesCountEmpty && (
              <span style={{ color: "red" }}>
                <small>*This field is required</small>
              </span>
            )}
            {montageValidation?.creativesCountDecimal && (
              <span style={{ color: "red" }}>
                <small>*Please only enter numeric values</small>
              </span>
            )}
            {montageValidation?.creativesCountNegative && (
              <span style={{ color: "red" }}>
                <small>*Please enter values greater than 0</small>
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="product-details">
        <div className="setup-heading">
          <div className="heading-icon">
            <ProductDetails />
          </div>
          <h6>Product Details</h6>
        </div>
        <div className="product-info">
          <div key={`product-name`} className="form-group px-3 productDetails">
            <label className="text-md">
              Product Name*
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">Product Name</div>
                    <span>
                      {" "}
                      A primary focus or main product of your campaign.
                    </span>
                  </div>
                </div>
              </div>
            </label>
            <InputText
              className="montage-input"
              name="productName"
              placeholder={`Add Product Name`}
              value={montageAds.productName}
              onChange={(e: any) => handleInputChange(e)}
              required={true}
              maxlength="99"
            />
            {montageValidation?.productName && (
              <span style={{ color: "red" }}>
                <small>*This field is required</small>
              </span>
            )}
          </div>

          <div
            key={`product_category`}
            className="form-group px-3 productCategoryDetails"
          >
            <label className="text-md">
              Product Category*
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">Product Category</div>
                    <span>
                      {" "}
                      A niche for your product. This information helps us match
                      you with creators in the same or similar category.
                    </span>
                  </div>
                </div>
              </div>
            </label>
            <Select
              name="productCategory"
              options={productsCategory}
              defaultSelected="Food"
              onChange={(e: any) => handleChange(e)}
            />
          </div>

          <div key={`product_value`} className="form-group px-3 productDetails">
            <label className="text-md">
              Product Value*
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">Product Value</div>
                    <span>
                      {" "}
                      An approximate estimate of the cost of the product.
                    </span>
                  </div>
                </div>
              </div>
            </label>
            <div className="currency-dropdown">
              <Select
                name="productCurrency"
                options={currency}
                defaultSelected="USD"
                onChange={(e: any) => handleChange(e)}
              />
              <InputText
                className="montage-input1"
                name="productValue"
                placeholder={`50.00`}
                type={"Number"}
                value={montageAds.productValue}
                onChange={(e: any) => handleDecimalInput(e)}
              />
            </div>

            {montageValidation?.productValueEmpty && (
              <span style={{ color: "red" }}>
                <small>*This field is required</small>
              </span>
            )}
            {montageValidation?.productValueNegative && (
              <span style={{ color: "red" }}>
                <small>*Please enter values greater than 0</small>
              </span>
            )}
          </div>

          <div key={`product_url`} className="form-group px-3 productDetails">
            <label className="text-md">
              Product URL*
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">Product URL</div>
                    <span>
                      {" "}
                      A link to the product on your website. Ideally, one with
                      details and features of the product.
                    </span>
                  </div>
                </div>
              </div>
            </label>
            <InputText
              className="montage-input"
              name="productUrl"
              placeholder={`www.website.com`}
              type={"url"}
              value={montageAds.productUrl}
              onChange={(e: any) => handleInputChange(e)}
              required={true}
            />
            {montageValidation?.productUrl && (
              <span style={{ color: "red" }}>
                <small>*This field is required</small>
              </span>
            )}
          </div>

          <div key={`product_image`} className="form-group px-3 productImage">
            <label className="text-md">Product Image/Video</label>
            {montageAds.productFiles.length > 0 && (
              <ImageVideoCard
                selectedFile={montageAds.productFiles}
                dropdownData={[
                  {
                    name: "Download",
                    value: "downloadGroup",
                    id: "downloadGroup",
                    dropDownIcon: <Download />,
                    clickEvent: handleDownloadEvent,
                  },
                  {
                    name: "Delete",
                    value: "deleteGroup",
                    id: "deleteGroup",
                    dropDownIcon: <Delete />,
                    clickEvent: handleDeleteCreative,
                  },
                ]}
              />
            )}
            {deleteCreative && (
              <OutlineModal
                text="Are you sure you want to delete the creative?"
                title="Delete Creative"
                handleSubmit={() => handleDeleteEvent()}
                handleClose={() => {
                  setDeleteCreative(false);
                  setShowModal(false);
                }}
                showModal={showModal}
              />
            )}

            <div style={{ display: "flex", alignItems: "center" }}>
              <UploadFileButton
                handelOnChange={handelFileSelect}
                buttonText="Add Image Or Video"
                buttonIcon={<Vector />}
                buttonStyle={{ backgroundColor: "#F2F2F2" }}
                buttonContainerStyle={{
                  border: "1px solid #F2F2F2",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "4px",
                  padding: "10px",
                }}
              />
              {imageLoader && (
                <div style={{ paddingLeft: "10px", fontWeight: "bold" }}>
                  Uploading...
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MontageSetup;
