import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "../../../../../store/types/auth";
import { AdAccountState } from "../../../../../store/types/adaccount";
import type { GsaiState } from "../../../../../store/types/CreativeAI/GsaiPlayground";
import {
  getBussinessUnits,
  getHeadlinesAndDescription,
  getKeyWords,
  generatRecommendations,
  getRecommendation,
  publishAd,
} from "../../../../../store/actions/CreativeAI/GsaiPlayground";
import { mixPanelAdEvent } from "utils/mixpanel";

let interval: any = null;

export function useComponentDidMount() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: { auth: initialState }) => state.auth);
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const gsaiPlayground = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );

  const {
    adUnit,
    adAccount,
    bUnits,
    hnd,
    keywords,
    recommendations,
    isCreate,
    modal,
  } = gsaiPlayground;

  useEffect(() => {
    if (modal === "generating-recommendations") {
      const {
        adGroupAd: { ad: { id: adId = "" } = {} } = {},
        adGroup: { id: adgroupId = "" } = {},
      } = adUnit;
      const { adAccountId: adaccountId = "" } = adAccount;
      dispatch(getKeyWords({ adgroupId, user, adaccountId }));
      dispatch(getBussinessUnits({ brandId: selectedBrand.id, user }));
      dispatch(
        getHeadlinesAndDescription({ adgroupId, user, adaccountId, adId })
      );
    }
  }, [modal]);

  useEffect(() => {
    if (
      !recommendations.requestId &&
      bUnits.length &&
      keywords.length &&
      hnd?.headlines?.length &&
      hnd?.descriptions?.length
    ) {
      const { adGroupAd: { ad: { id: ad_id = "" } = {} } = {} } = adUnit;
      const payload = {
        ad_id,
        ad_headlines: hnd.headlines,
        ad_descriptions: hnd.descriptions,
        ad_keywords: keywords.map((item: any) => ({
          keyword_text: item.keyword,
        })),
        business_units: bUnits.map((item: any) => {
          return {
            bu_id: item.id,
            bu_name: item.businessUnit,
            brand_name: item.brandName,
            bu_detail: item.businessUnitDetail,
          };
        }),
        isCreate,
      };
      dispatch(generatRecommendations({ user, payload }));
    }
  }, [bUnits, hnd, keywords, recommendations.requestId]);

  useEffect(() => {
    clearInterval(interval);

    if (recommendations.status === "polling" && recommendations.requestId) {
      dispatch(
        getRecommendation({ requestId: recommendations.requestId, user })
      );

      interval = setInterval(() => {
        if (recommendations.status === "completed") {
          clearInterval(interval);
        } else {
          dispatch(
            getRecommendation({ requestId: recommendations.requestId, user })
          );
        }
      }, 5000);
    }
  }, [recommendations.requestId, recommendations.status]);

  useEffect(() => {
    let commonKeys = {
      module_name: "Google Search Playground",
      brand_name: selectedBrand?.name,
      brand_id: selectedBrand?.id,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      reference_ad_id: adUnit?.adGroupAd?.ad?.id,
      reference_ad_group_id: adUnit?.adGroup?.id,
      reference_campaign_id: adUnit?.campaign?.id,
      reference_account_id: gsaiPlayground?.adAccount?.adAccountId,
      ai_group_name: gsaiPlayground?.aiGroup?.selected?.name,
      ai_group_id: gsaiPlayground?.aiGroup?.selected?.id,
      recommended_headline: gsaiPlayground?.dnd?.headlines?.recommended,
      recommended_description: gsaiPlayground?.dnd?.descriptions?.recommended,
    };
    if (
      gsaiPlayground?.dnd?.headlines?.recommended?.length > 0 &&
      gsaiPlayground?.dnd?.descriptions?.recommended?.length > 0
    ) {
      mixPanelAdEvent(
        `${isCreate ? "Create Variant Page" : "Refresh Ad Page"}`,
        isCreate
          ? {
              ...commonKeys,
              sub_module_name: "Create Variant Page",
            }
          : {
              ...commonKeys,
              sub_module_name: `Refresh Ad Page`,
              initial_to_be_published_section: {
                headlines: gsaiPlayground?.dnd?.headlines?.toBePublished,
                descriptions: gsaiPlayground?.dnd?.descriptions?.toBePublished,
              },
            }
      );
    }
  }, [gsaiPlayground.dnd.headlines, gsaiPlayground.dnd.descriptions]);
}

export function usePublish() {
  const dispatch = useDispatch();
  const isInitialStateSavedRef = useRef(false);
  const { user } = useSelector((state: { auth: initialState }) => state.auth);
  const gsaiPlayground = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );

  const {
    adAccount,
    adUnit,
    campaign,
    recommendations,
    finalUrl = "",
    isCreate,
    isCreateVariant,
    modal,
    variantName,
    descriptionLoader,
    initialState,
    dnd: { headlines, descriptions },
    replacementConfig,
    path1,
    path2,
    isNewAdGroup,
    adGroupName,
    selectedCampaign,
    adGroups,
    existingHeadlines,
    existingDescriptions,
  } = gsaiPlayground;

  const headlineIntervals = [];
  const descriptionIntervals = [];
  let keys: Array<"lowPerformance" | "goodPerformance" | "bestPerformance"> = [
    "lowPerformance",
    "goodPerformance",
    "bestPerformance",
  ];
  const map = {
    lowPerformance: "LOW",
    goodPerformance: "GOOD",
    bestPerformance: "BEST",
  };
  for (let index = 0; index < keys.length; index += 1) {
    const key = keys[index];
    if (replacementConfig?.headlines[key].isChecked) {
      headlineIntervals.push({
        interval: replacementConfig?.headlines[key].value,
        performance: map[key],
      });
    }

    if (replacementConfig?.descriptions[key].isChecked) {
      descriptionIntervals.push({
        interval: replacementConfig?.descriptions[key].value,
        performance: map[key],
      });
    }
  }

  const currentState = {
    to_be_published: {
      headlines: headlines?.toBePublished,
      descriptions: descriptions?.toBePublished,
    },
    recommended: {
      headlines: headlines?.recommended,
      descriptions: descriptions?.recommended,
    },
    replacements: {
      headlines: headlines?.replacements,
      descriptions: descriptions?.replacements,
    },
    reference_headline: recommendations?.data?.recommended_headlines,
    reference_description: recommendations?.data?.recommended_descriptions,
    ad_account_id: adAccount.adAccountId,
    ad_group_id: adUnit?.adGroup?.id,
    ad_id: adUnit?.adGroupAd?.ad?.id,
    campaign_id: campaign.id,
    replacementConfig,
    email: user?.email,
    action: isCreate ? "Create Variant" : "Refresh Ad",
    bu_name: recommendations?.data?.recommended_business_units?.bu_name,
    bu_detail: recommendations?.data?.recommended_business_units?.bu_detail,
  };
  // Final payload sent to API
  const payload = {
    isCreate: isCreate,
    ad_account_id: adAccount.adAccountId,
    ad_group_id: isCreate
      ? isNewAdGroup
        ? adUnit?.adGroup?.id
        : selectedCampaign
      : adUnit?.adGroup?.id,
    ad_id: adUnit?.adGroupAd?.ad?.id,
    is_new_ad_group: isNewAdGroup,
    draft_id: isCreateVariant ? null : adUnit?.variant_id ?? null,
    data: {
      ad: {
        type: adUnit?.adGroupAd?.ad?.type,
        responsiveSearchAd: {
          headlines: headlines?.toBePublished?.map((item: any) => ({
            text: item.value,
            pinnedField: item.pinnedField,
          })),
          descriptions: descriptions?.toBePublished?.map((item: any) => ({
            text: item.value,
            pinnedField: item.pinnedField,
          })),
          path1,
          path2,
        },
        finalUrls: [finalUrl],
        name: adGroupName,
      },
      keywords: [],
      ad_group: {
        update_name: variantName,
      },
    },
    trackingDetails: {
      initialState,
      finalState: { ...currentState },
    },
    creativeReplacement: replacementConfig?.isEnabled,
    creativeReplacementHeadlines: headlines?.replacements?.map((item: any) => ({
      text: item.value,
      pinnedField: item.pinnedField,
    })),
    headlineIntervals,
    creativeReplacementDescription: descriptions.replacements?.map(
      (item: any) => ({
        text: item.value,
        pinnedField: item.pinnedField,
      })
    ),
    descriptionIntervals,
  };
  function handlePublish(create: boolean) {
    if (create) {
      dispatch({
        type: "UPDATE_GSAI_STORE",
        payload: {
          modal: "create-variant",
        },
      });
    } else {
      dispatch({
        type: "UPDATE_GSAI_STORE",
        payload: {
          modal: "publishing-ad",
        },
      });
      dispatch(publishAd({ user, payload }));
    }
  }

  // Save the initial state in store.
  useEffect(() => {
    // description loader is loader for last api call after which only we
    // want to save initialState.
    if (!descriptionLoader && !isInitialStateSavedRef.current) {
      dispatch({
        type: "UPDATE_GSAI_STORE",
        payload: {
          initialState: { ...currentState },
        },
      });
      isInitialStateSavedRef.current = true;
    }
  }, [descriptionLoader]);

  function handleVariantClose() {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        variantName: "",
        adGroupName: "",
        modal: "",
      },
    });
  }

  function handleNameChange(e: any) {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        variantName: e.target.value,
      },
    });
  }

  function handleAdGroupNameChange(e: any) {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        adGroupName: e.target.value,
      },
    });
  }

  function handleIsCreateNew(isNew: boolean) {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        isNewAdGroup: isNew,
      },
    });
  }

  return {
    handlePublish,
    isCreate,
    modal,
    handleVariantClose,
    variantName,
    adGroupName,
    adGroups,
    handleNameChange,
    handleAdGroupNameChange,
    handleIsCreateNew,
    finalUrl,
    campaign,
    adAccount,
    headlines,
    descriptions,
    recommendations,
    replacementConfig,
    existingHeadlines,
    existingDescriptions,
  };
}

function getPosition(position: string) {
  const lastChar = position[position.length - 1];
  if (lastChar === "1" || lastChar === "2" || lastChar === "3")
    return +lastChar;
  return "";
}

function getTopData(data: any) {
  const toBePublished = data.toBePublished || [];
  const results: any = {
    length: toBePublished.length,
    random: [],
    1: [],
    2: [],
    3: [],
  };
  for (const item of toBePublished) {
    const position = getPosition(item.pinnedField);
    if (position) results[position].push(item);
    else results.random.push(item);
  }

  return results;
}

function getPreviewData(data: any, count: number) {
  if (!data || !data.toBePublished || !data.toBePublished.length) return [];
  let randCount = 0;
  const results: any = [];
  const topData = getTopData(data);
  const iterations = Math.min(count, topData.length);
  for (let i = 0; i < iterations; i++) {
    if (topData[i + 1].length) results.push(topData[i + 1][0].value);
    else if (topData.random[randCount]) {
      results.push(topData.random[randCount].value);
      randCount += 1;
    }
  }

  return results;
}

export function usePreview() {
  const { dnd } = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );
  const headlines = getPreviewData(dnd.headlines, 3);
  const descriptions = getPreviewData(dnd.descriptions, 2);
  const publishDisabled =
    dnd?.descriptions?.toBePublishedError ||
    dnd?.headlines?.toBePublishedError ||
    dnd?.descriptions?.creativeReplacementError ||
    dnd?.headlines?.creativeReplacementError;
  return { headlines, descriptions, publishDisabled };
}
