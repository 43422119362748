import { ReactComponent as Good } from "../../../assets/smb/Good.svg";
import { ReactComponent as Inline } from "../../../assets/smb/Inline.svg";
import { ReactComponent as Alarming } from "../../../assets/smb/Alarming.svg";
import { ReactComponent as Escalation } from "../../../assets/smb/Escalation.svg";
import { ReactComponent as Total } from "../../../assets/smb/TotalBrands.svg";
import { ReactComponent as GoogleIcon } from "../assets/icons8-google.svg";
import { ReactComponent as LinkedInIcon } from "../assets/icons8-linkedin.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons8-facebook.svg";
import { ReactComponent as TiktokIcon } from "../assets/tiktokIcon.svg";
import { ReactComponent as GoodIcon } from "../assets/goodIcon.svg";
import { ReactComponent as InlineIcon } from "../assets/inlineIcon.svg";
import { ReactComponent as AlarmingIcon } from "../assets/alarmingIcon.svg";
import { ReactComponent as EscalationIcon } from "../assets/escalationIcon.svg";
import { ReactComponent as TargetSpends } from "../assets/targetSpends.svg";
import { ReactComponent as TargetDailyBudget } from "../assets/targetDailyBudget.svg";
import { ReactComponent as ActualDailyBudget } from "../assets/actualDailyBudget.svg";
import { ReactComponent as TargetCostPerMetric } from "../assets/targetCostPerMetric.svg";
import { ReactComponent as ActualCostPerMetric } from "../assets/actualCostPerMetric.svg";
import { ReactComponent as MicrosoftIcon } from "../assets/microsoftIcon.svg";
import { ReactComponent as DV360Icon } from "../assets/dv360Icon.svg";
import { ReactComponent as PinterestIcon } from "../../../assets/svg/pinterest-logo.svg";

export const TILES_DATA = [
  {
    key: "total_brands",
    label: "Total Brands",
    Icon: (
      <div
        className="rounded-circle"
        style={{
          backgroundColor: "rgba(135, 135, 135, 0.1)",
          padding: "0.6rem",
        }}
      >
        <Total />
      </div>
    ),
  },
  { key: "good", label: "Good", Icon: <Good /> },
  { key: "inline", label: "Inline", Icon: <Inline /> },
  { key: "alarming", label: "Alarming", Icon: <Alarming /> },
  { key: "escalation", label: "Escalation", Icon: <Escalation /> },
];

export const MOCK_RESPONSE: Record<string, number> = {
  total_brands: 40,
  good: 10,
  inline: 10,
  alarming: 10,
  escalation: 10,
};

export default {
  TILE_BACKGROUND_COLOR: {
    total_brands: "#FAFAFA",
    good: "#D8E0FF",
    inline: "#DAFFD4",
    alarming: "#FFEBB6",
    escalation: "#FCCFD2",
    explorer: "#FFF",
  },
  TILE_CONTENT_COLOR: {
    total_brands: "#000000",
    good: "#002CDC",
    inline: "#006C04",
    alarming: "#ED3900",
    escalation: "#A81E26",
    explorer: "#333",
  },
  TILE_BORDER_COLOR: {
    total_brands: "#B3B3B3",
    good: "#002CDC",
    inline: "#006C04",
    alarming: "#977212",
    escalation: "#A81E26",
    explorer: "#0869FB",
  },
  SELECTED_BG_COLOR: {
    explorer: "rgba(8, 105, 251, 0.1);",
  },
  SELECTED_TEXT_COLOR: {
    explorer: "#0869FB",
  },
} as any;

export const PLATFORMS: any = {
  Google: (style = {}) => <GoogleIcon style={style} />,
  LinkedIn: (style = {}) => <LinkedInIcon style={style} />,
  Facebook: (style = {}) => <FacebookIcon style={style} />,
  Microsoft: (style = {}) => <MicrosoftIcon style={style} />,
  DV360: (style = {}) => <DV360Icon style={style} />,
  PINTEREST: (style = {}) => <PinterestIcon style={style} />,
};

export const TAGS: any = {
  "": {
    text: "-",
    bgcolor: "#F4F4F4",
    color: "#000000",
  },
  good: {
    text: "Good",
    bgcolor: "#D8E0FF",
    color: "#002CDC",
    svg: (style = {}) => <GoodIcon style={style} />,
  },
  inline: {
    text: "Inline",
    bgcolor: "#DAFFD4",
    color: "#006C04",
    svg: (style = {}) => <InlineIcon style={style} />,
  },
  alarming: {
    text: "Alarming",
    bgcolor: "#FFEBB6",
    color: "#ED3900",
    svg: (style = {}) => <AlarmingIcon style={style} />,
  },
  escalation: {
    text: "Escalation",
    bgcolor: "#FCCFD2",
    color: "#B20B15",
    svg: (style = {}) => <EscalationIcon style={style} />,
  },
};

export const dropdownValues = [
  { value: "Select a Metric", label: "Select a Metric" },
  { value: "USD", label: "USD" },
  { value: "EURO", label: "EURO" },
  { value: "INR", label: "INR" },
  { value: "POUND", label: "POUND" },
];

export const SECONDARY_TILES_DATA_LABEL_MAP: Record<string, string> = {
  target_spends: "Target Spends",
  actual_spends: "Actual Spends",
  target_daily_budget: "Target Daily Budget",
  actual_daily_budget: "Actual Daily Budget",
  target_cost_per_metric: "Taregt Cost Per <Result Metric>",
  actual_cost_per_metric: "Actual Cost Per <Result Metric>",
};

export const SECONDARY_TILES_DATA_ICON_MAP: Record<string, any> = {
  target_spends: <TargetSpends />,
  actual_spends: <Total />,
  target_daily_budget: <TargetDailyBudget />,
  actual_daily_budget: <ActualDailyBudget />,
  target_cost_per_metric: <TargetCostPerMetric />,
  actual_cost_per_metric: <ActualCostPerMetric />,
};

export const SECONDARY_TILES_DATA_CURRENCY_MAP: Record<string, string> = {
  INR: "₹",
  USD: "$",
};

export const MONTHS: any = {
  jan: "01",
  feb: "02",
  mar: "03",
  apr: "04",
  may: "05",
  jun: "06",
  jul: "07",
  aug: "08",
  sep: "09",
  oct: "10",
  nov: "11",
  dec: "12",
};

const paths = {
  google: <GoogleIcon />,
  facebook: <FacebookIcon />,
  linkedin: <LinkedInIcon />,
  tiktok: <TiktokIcon />,
  microsoft: <MicrosoftIcon />,
  dv360: <DV360Icon />,
  pinterest: <PinterestIcon />,
};

export const PLATFORMS_MAPPER = {
  "FACEBOOK AD": {
    icon: paths.facebook,
    shortName: "",
  },
  "GOOGLE SHOPPING AD": {
    icon: paths.google,
    shortName: "shopping",
  },
  "GOOGLE VIDEO AD": {
    icon: paths.google,
    shortName: "video",
  },
  "GOOGLE SEARCH AD": {
    icon: paths.google,
    shortName: "search",
  },
  "GOOGLE DISPLAY AD": {
    icon: paths.google,
    shortName: "display",
  },
  "GOOGLE DISCOVERY AD": {
    icon: paths.google,
    shortName: "discovery",
  },
  "GOOGLE APP AD": {
    icon: paths.google,
    shortName: "app",
  },
  "GOOGLE SMART AD": {
    icon: paths.google,
    shortName: "smart",
  },
  "GOOGLE LOCAL AD": {
    icon: paths.google,
    shortName: "local",
  },
  "GOOGLE PERFORMANCE MAX AD": {
    icon: paths.google,
    shortName: "PMax",
  },
  "LINKEDIN AD": {
    icon: paths.linkedin,
    shortName: "",
  },
  TikTok: {
    icon: paths.tiktok,
    shortName: "",
  },
  PINTEREST: {
    icon: paths.pinterest,
  },
} as any;

export const PARENT_PLATFORMS_MAPPER = {
  FACEBOOK: paths.facebook,
  GOOGLE: paths.google,
  LINKEDIN: paths.linkedin,
  MICROSOFT: paths.microsoft,
  DV360: paths.dv360,
  TikTok: paths.tiktok,
  PINTEREST: paths.pinterest,
} as any;

export const AD_ACCOUNT_MOCK_DATA = [
  {
    brandId: "1",
    accountType: "total_brands",
  },
  {
    brandId: "2",
    accountType: "total_brands",
  },
  {
    brandId: "3",
    accountType: "total_brands",
  },
];
