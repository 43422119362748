import {
  ActionDebuggerHelperInitState,
  SetActivePlatformDebugger,
  SetAdSet,
  SetAdSetLoading,
  SetAIGroups,
  SetAIGroupsLoading,
  SetCampaignDebuggerLoading,
  SetSelectedAdAccount,
  SetSelectedAdAccountLoading,
  SetSelectedCampaignDebugger,
  SET_ACTIVE_PLATFORM_DEBUGGER,
  SET_AD_ACCOUNT_LOADING,
  SET_AD_SET,
  SET_AD_SET_LOADING,
  SET_AI_GROUP,
  SET_AI_GROUP_LOADING,
  SET_SELECTED_AD_ACCOUNT,
  SET_SELECTED_CAMPAIGN_DEBUGGER,
  SET_SELECTED_CAMPAIGN_DEBUGGER_LOADING,
} from "../../types/ActionDebugger/ActionDebuggerHelper";

const initialState: ActionDebuggerHelperInitState = {
  aiGroupLoading: true,
  selectedAIGroup: undefined,
  adAccountLoading: true,
  selectedAdAccount: undefined,
  activePlatform: undefined,
  selectedAdSet: [],
  adSetLoading: false,
  selectedCampaignLoading: false,
  selectedCampaign: undefined,
};

const ActionDebuggerHelperReducer = (
  state = initialState,
  action:
    | SetAIGroupsLoading
    | SetSelectedAdAccountLoading
    | SetAIGroups
    | SetSelectedAdAccount
    | SetActivePlatformDebugger
    | SetAdSet
    | SetAdSetLoading
    | SetCampaignDebuggerLoading
    | SetSelectedCampaignDebugger
) => {
  switch (action.type) {
    case SET_AI_GROUP_LOADING: {
      return {
        ...state,
        aiGroupLoading: action.payload,
      };
    }

    case SET_AI_GROUP: {
      return {
        ...state,
        selectedAIGroup: action.payload,
      };
    }

    case SET_AD_ACCOUNT_LOADING: {
      return {
        ...state,
        adAccountLoading: action.payload,
      };
    }

    case SET_SELECTED_AD_ACCOUNT: {
      return {
        ...state,
        selectedAdAccount: action.payload,
      };
    }

    case SET_ACTIVE_PLATFORM_DEBUGGER: {
      return {
        ...state,
        activePlatform: action.payload,
      };
    }

    case SET_AD_SET: {
      return {
        ...state,
        selectedAdSet: action.payload,
      };
    }

    case SET_AD_SET_LOADING: {
      return {
        ...state,
        adSetLoading: action.payload,
      };
    }
    case SET_SELECTED_CAMPAIGN_DEBUGGER_LOADING: {
      return {
        ...state,
        selectedCampaignLoading: action.payload,
      };
    }

    case SET_SELECTED_CAMPAIGN_DEBUGGER: {
      return {
        ...state,
        selectedCampaign: action.payload,
      };
    }
    default:
      return state;
  }
};
export default ActionDebuggerHelperReducer;
