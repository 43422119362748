import { useContext } from "react";
import success from "../../../assets/tai/success.svg";
import failed from "../../../assets/tai/failed.svg";
import { TaiContext } from "../TaiContextProvider";

function SuccessModal({ showSuccessModal }) {
  const { setShowPublishModal, startAgainFlow, selectedPlatform } =
    useContext(TaiContext);

  return (
    <div
      className="container-overlay"
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0,0,0,0.7)",
        zIndex: "1032",
        top: "0",
        left: "0",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex flex-column focus:outline-none"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            width: "21.875rem",
            height: "24.375rem",
            padding: "2.5rem",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            {showSuccessModal ? (
              <img
                src={success}
                style={{ height: "6.25rem", width: "6.25rem" }}
                alt="success"
              />
            ) : (
              <img src={failed} style={{ height: "6.25rem" }} alt="failed" />
            )}
            <h3
              style={{
                fontWeight: "700",
                fontSize: "1.875rem",
                textAlign: "center",
                color: "#333333",
                padding: "0.5rem",
              }}
            >
              {showSuccessModal ? "Success" : "Uh Oh!"}
            </h3>
            <span
              style={{
                fontWeight: "500",
                fontSize: "0.875rem",
                color: "#727272",
                padding: "0.5rem",
                textAlign: "center",
                marginBottom: "0.87rem",
              }}
            >
              {showSuccessModal
                ? `${
                    selectedPlatform === "Facebook"
                      ? "Audiences will be published in the next 24 hours"
                      : "Keywords are published"
                  }`
                : "Something went wrong and we could not process your request. Please recheck your inputs and try again."}
            </span>
            <button
              className="text-white btn primary"
              style={{ width: "11.75rem" }}
              onClick={startAgainFlow}
            >
              Start Again
            </button>
            {showSuccessModal && (
              <span
                onClick={() => {
                  setShowPublishModal(false);
                }}
                style={{
                  fontWeight: "600",
                  fontSize: "0.875rem",
                  color: "#0869FB",
                  padding: "1rem 0.5rem",
                  textAlign: "center",
                  width: "11.75rem",
                  cursor: "pointer",
                }}
              >
                <svg
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "0.3rem" }}
                >
                  <path
                    d="M6.61807 1.11355L3.49097 0.173676C3.06377 0.0540014 2.61951 0.29335 2.4828 0.703374C2.36313 1.13057 2.60248 1.57483 3.0125 1.71154L4.17443 2.05327C2.05557 3.30078 0.620117 5.60758 0.620117 8.25622C0.620117 11.2124 2.43142 13.7586 4.99465 14.8522C5.52435 15.0744 6.12249 14.6813 6.12249 14.1175C6.12249 13.7928 5.93452 13.5023 5.64403 13.3828C3.62758 12.5282 2.22642 10.5632 2.22642 8.25634C2.22642 6.1716 3.38835 4.34318 5.09715 3.40334L4.6871 4.78744C4.56742 5.21464 4.80677 5.6589 5.2168 5.79561C5.644 5.91528 6.08825 5.67593 6.22496 5.26591L7.16483 2.1388C7.2845 1.69454 7.04528 1.25019 6.61807 1.11358V1.11355Z"
                    fill="#0869FB"
                  />
                  <path
                    d="M12.495 14.801L11.3331 14.4593C13.469 13.2118 14.9045 10.905 14.9045 8.25635C14.9045 5.30015 13.0932 2.75401 10.53 1.66037C10.0003 1.43819 9.40211 1.8313 9.40211 2.3951C9.40211 2.7198 9.59009 3.01027 9.88058 3.12982C11.897 3.98435 13.2982 5.94941 13.2982 8.25623C13.2982 10.341 12.1363 12.1694 10.4275 13.1092L10.8376 11.7251C10.9573 11.2979 10.7179 10.8537 10.3079 10.717C9.8807 10.5973 9.43644 10.8366 9.29974 11.2467L8.35986 14.3738C8.24019 14.801 8.47954 15.2452 8.90674 15.3819L12.0335 16.3388C12.4607 16.4585 12.905 16.2192 13.0417 15.8091C13.1614 15.3819 12.9222 14.9205 12.495 14.801L12.495 14.801Z"
                    fill="#0869FB"
                  />
                </svg>
                Keep Exploring
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessModal;
