import { LightTooltip } from "components/CreativeAI_Revamp/shared";
import React from "react";
import { useCaiFbMixPanel } from "../hooks/useCaiFbMixPanel";
import {
  StyledSelectCard,
  StyledSelectCardContainer,
  StyledSelectCardMessage,
} from "./styled";
interface CardProps {
  selected: boolean;
  setSelected: any;
  children?: React.ReactNode;
  title: string;
  subTitle: string;
  index: number;
  onClick?: () => void;
  disabled?: number;
}
function SelectMethodCard({
  selected,
  children,
  title,
  subTitle,
  index,
  setSelected,
  onClick,
  disabled,
}: CardProps) {
  const { mixPanelPreferredMethodSelected } = useCaiFbMixPanel();
  return (
    <LightTooltip
      title={
        disabled == 1
          ? "Use Creative Animator to explore variations for your media files. Contact Customer Support to enable the feature"
          : disabled == 2
          ? "Creative Animator for Video Ads is coming soon"
          : disabled == 3
          ? "Please wait till the current process ends"
          : ""
      }
      arrow
    >
      <StyledSelectCardContainer
        selected={selected}
        disabled={disabled != 0}
        onClick={() => {
          setSelected(index);
          mixPanelPreferredMethodSelected(title);
          if (!disabled) {
            onClick && onClick();
          }
        }}
      >
        <StyledSelectCard>
          {children}
          <StyledSelectCardMessage>
            {title}
            <span>{subTitle}</span>
          </StyledSelectCardMessage>
        </StyledSelectCard>
      </StyledSelectCardContainer>
    </LightTooltip>
  );
}

export default SelectMethodCard;
