import * as React from "react";
import Slider from "@mui/material/Slider";

function calculateValue(value: number) {
  switch (value) {
    case 1:
      return 7;
    case 2:
      return 14;
    case 3:
      return 30;
    case 4:
      return 90;
    default:
      return 0;
  }
}

const marks = [1, 2, 3, 4].map((value) => ({
  value,
}));
export default function CustomSlider({ getSelectedDays, sliderValue }: any) {
  const [value, setValue] = React.useState(sliderValue);

  return (
    <Slider
      className="custom-slider"
      sx={{
        pb: 0,
        "& .MuiSlider-track": {
          background:
            "linear-gradient(90deg, #4BA2E9 3.51%, #6DDE9F 127.19%) !important",
          border: "transparent",
        },
        "& .MuiSlider-rail": {
          opacity: 1,
          background:
            "linear-gradient(90deg, #4BA2E9 3.51%, #6DDE9F 127.19%) !important",
          border: "transparent",
        },
        "& .MuiSlider-thumb": {
          height: "1rem",
          width: "1rem",
          background:
            "linear-gradient(#fff,#fff) padding-box, linear-gradient(90deg, #4BA2E9 3.51%, #6DDE9F 127.19%) border-box",
          border: "3px solid transparent",
          borderRadius: "0.75rem",
        },
        "& .MuiSlider-thumb::after": {
          height: "1rem",
          width: "1rem",
        },
        "& .MuiSlider-valueLabel": {
          background: "#1E2244",
          fontSize: "0.75rem",
          fontWeight: 400,
          borderRadius: "4px",
          color: "#fff",
          fontFamily: "Inter",
        },
      }}
      defaultValue={1}
      step={null}
      value={value}
      onChange={(_, newValue) => {
        if (newValue !== value) {
          getSelectedDays(newValue);
          setValue(newValue as number);
        }
      }}
      valueLabelDisplay="auto"
      marks={marks}
      scale={calculateValue}
      min={1}
      max={4}
    />
  );
}
