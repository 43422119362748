import React from "react";
import "./styles.scss";

type ChipProps = {
  index: number;
  chip: string;
  deleteChip: (index: number) => void;
};
const Chip: React.FC<ChipProps> = ({ index, chip, deleteChip }) => (
  <span className="chip" key={index}>
    <span className="chip-value">{chip}</span>
    <button type="button" className="chip-delete-button" onClick={() => deleteChip(index)}>
      x
    </button>
  </span>
);

export default Chip;
