export const AD_ACCOUNT_SUMMARY = [
  { type:"target",key: 'roas', name: 'Target ROAS' },
  { type:"actual",key: 'roas', name: 'Actual ROAS' },
  { type:"targetCost",key: 'metricPayload', name: 'Target Cost per ',currencySymbol:true },
  { type:"actualCost",key: 'metricPayload', name: 'Actual Cost per ',currencySymbol:true },
  { type:"target",key: 'spends', name: 'Target Spends',currencySymbol:true },
  { type:"actual",key: 'spends', name: 'Actual Spends ',currencySymbol:true },
  { type:"target",key: 'dailyBudget', name: 'Target Daily Budget ',currencySymbol:true },
  { type:"actual",key: 'dailyBudget', name: 'Actual Daily budget',currencySymbol:true },
];
