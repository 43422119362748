import React, { useEffect } from 'react'
import {ReactComponent as NoVideo} from '../../assets/svg/novideo.svg'
import {ReactComponent as BackPath} from '../../assets/svg/backPath.svg'
import {ReactComponent as DownloadVideo} from '../../assets/svg/downloadButton.svg'
import {ReactComponent as PlayVideo} from '../../assets/svg/playVideo.svg'
import { useDispatch, useSelector } from 'react-redux'
import { initialState } from '../../store/types/auth'
import { AdAccountState } from '../../store/types/adaccount'
import { Downloading, MontageState } from '../../store/types/montageAds'
import { toggleVideoPanelView } from '../../store/actions/montageAds'
import ModalContainer from '../Modal/ModalContainer'
import Loader from '../common/Loader'

function VideoListing() {
  const auth = useSelector((state: { auth:initialState }) => state.auth);
    const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
    const montageAds = useSelector((state:{ montageAds:MontageState}) => state.montageAds)
    const {montageCampaigns, toggleVideoPanel, selectedMontageCampaign}= montageAds;
    const dispatch = useDispatch()
    const [downloading,setDownloading]= React.useState<Downloading>({})
    const [logNumber, setLogNumber] = React.useState<Number>(-1);
    const [selectedVideo, setSelectedVideo]= React.useState<any>()
    const [videos, setVideos]= React.useState<any>([])
    useEffect(() => {
        if(selectedMontageCampaign?.videos){
            setVideos([...selectedMontageCampaign?.videos])
        }
        else{
            setVideos([])
        }
    }, [selectedMontageCampaign])

    function forceDownload(blob:any, filename:any) {
      var a = document.createElement('a');
      a.download = filename;
      a.href = blob;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    
    function downloadResource(url:any, filename:any,index:any) {
      if (!filename) filename = url.split('\\').pop().split('/').pop();
      if(filename && url.split('\\').pop().split('/').pop().includes(".mkv")){
        filename+=".mkv"
      }
      setDownloading((previousState:any)=>({...previousState,[index]:true}))
      fetch(url, {
          headers: new Headers({
            'Origin': document.location.origin
          }),
          mode: 'cors'
        })
        .then(response => response.blob())
        .then(blob => {
          let blobUrl = window.URL.createObjectURL(blob);
          forceDownload(blobUrl, filename);
          setDownloading((previousState:Downloading)=>({...previousState,[index]:false}))
        })
        .catch(e => console.error(e));
    }

  return (
   <>
      <div className='montage-video-panel-nav'>
       <i onClick={()=>{dispatch(toggleVideoPanelView(!toggleVideoPanel ))}}>
          <BackPath/>
       </i>
        <h6>{selectedMontageCampaign?.name || `Campaign Name`} - Creative Library</h6>
      </div>
      {(videos?.length===0) && <div className='montage-no-video-panel container'>
        <div className='montage-no-videos'>
          <NoVideo/>
          <h5>Creativity takes time!</h5>
          <p>Your favorite creators have received the brief and will fill this space<br/> with creatives shortly!</p>
        </div>
      </div>}
      {(videos?.length>0)&& 
      <div className='montage-video-panel'>
         <div className='video-container row'>
          {videos?.map((video:any, index:any)=>{
           return (
            <>
            <div className='video-tile-montage col-lg-3'>
              <video src={video} muted></video>
              <i className='buttons-montage' onClick={()=>{downloadResource(video,`video-${index+1}`,index)}}>{(downloading?.[index])?<Loader height='36px' width="36px" size='sm'/>:<DownloadVideo />}</i>
              <i className='buttons-montage2' onClick={()=>{setLogNumber(1);setSelectedVideo(video)}}><PlayVideo height={"30px"} /></i>
            </div> 
          </>
           )
          })}
        </div>
      </div>}
      {logNumber!==-1 && 
        <ModalContainer
        title='Video Player'
        handleClose={()=>{setLogNumber(-1)}}
        submitText={""}
        cancelText={""}
        width={"80%"}
        height={"90%"}
        >
            <video id="motage_ads_video" className="video-js vjs-default-skin"
                controls autoPlay preload="auto" width="100%" height="100%">
                <source src={selectedVideo}/>
            </video>
        </ModalContainer>
      }
    </>
  )
}

export default VideoListing