import {
  GetAdGroups,
  GET_AD_GROUPS,
  SetAdGroups,
  SET_AD_GROUPS,
} from "store/types/CreativeAI/GsaiPlayground";
import {
  GetRecommendation,
  GET_RECOMMENDATION,
  SetRecommendation,
  SET_RECOMMENDATION,
} from "../../types/campaign";
import {
  GenerateRecommendation,
  GENERATE_RECOMMENDATION,
  GetBussinessUnits,
  GetKeywords,
  GET_BUSSINESS_UNITS,
  GET_KEYWORDS,
  SetBussinessUnits,
  SetKeywords,
  SetRequestId,
  SET_BUSSINESS_UNITS,
  SET_KEYWORDS,
  SET_REQUEST_ID,
} from "../../types/CreativeAI/Facebook/GsaiPlayground/CreateAdVariant";

export const getBussinessUnits = (payload: any): GetBussinessUnits => {
  return {
    type: GET_BUSSINESS_UNITS,
    payload,
  };
};

export const setBussinessUnits = (payload: any): SetBussinessUnits => {
  return {
    type: SET_BUSSINESS_UNITS,
    payload,
  };
};

export const getAdGroups = (payload: any): GetAdGroups => {
  return {
    type: GET_AD_GROUPS,
    payload,
  };
};

export const setAdGroups = (payload: any): SetAdGroups => {
  return {
    type: SET_AD_GROUPS,
    payload,
  };
};

export const getKeywords = (payload: any): GetKeywords => {
  return {
    type: GET_KEYWORDS,
    payload,
  };
};

export const setKeywords = (payload: any): SetKeywords => {
  return {
    type: SET_KEYWORDS,
    payload,
  };
};

export const setRequestId = (payload: any): SetRequestId => {
  return {
    type: SET_REQUEST_ID,
    payload,
  };
};

export const getRecommendation = (payload: any): GetRecommendation => {
  return {
    type: GET_RECOMMENDATION,
    payload,
  };
};

export const generateRecommendation = (
  payload: any
): GenerateRecommendation => {
  return {
    type: GENERATE_RECOMMENDATION,
    payload,
  };
};

export const setRecommendation = (payload: any): SetRecommendation => {
  return {
    type: SET_RECOMMENDATION,
    payload,
  };
};
