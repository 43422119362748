import styled from "styled-components";

export const StyledHeader = styled.div`
  font-weight: 600;
  font-size: 1.125em;
  line-height: 1.375em;
  color: #000000;
  @media screen and (max-width: 1500px) {
    font-size: 1em;
    line-height: 1.2em;
  }
  @media screen and (max-width: 1350px) {
    font-size: 0.7em;
  }
`;

export const LightStyledHeader = styled.div`
  font-weight: 500;
  font-size: 1em;
  line-height: 1.25em;
  color: #000000;
  padding: 0.5625em 1.1875em;
  background: #f8f9fe;
`;

export const StackBox = styled.div`
  border: 1px solid rgba(43, 113, 250, 0.1);
  box-shadow: 0px 10px 24px rgb(35 40 60 / 5%);
  border-radius: 8px;
  text-align: left;
`;

export const StyledBodyContainer = styled.div`
  font-weight: 500;
  font-size: 0.8em;
  line-height: 1.25em;
  padding: 0.625em 1.1875em;
`;

export const Inputlabel = styled.div`
  font-weight: 500;
  font-size: 1em !important;
  line-height: 1.1875em;
  color: #000000;
  margin-bottom: 0.625em;
`;
