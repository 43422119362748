import React from 'react'
import './styles.scss'

interface Props {
  children: React.ReactNode
}

function Container({ children }: Props) {
  return (
    <div className='tms-container'>
      {children}
    </div>
  )
}

export default Container
