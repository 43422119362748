import { FC, ReactElement } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

type StripItem = {
  title: string;
  text: string;
  svg: ReactElement<any, any>;
  borderToBeApplied?:boolean;
};
type StripContent = {
  items: StripItem[];
  width?: string;
  gap?: number;

};

const Strip: FC<StripItem> = ({ title, text, svg,borderToBeApplied }) => (
  <Grid container spacing={2} style={{
    borderBottom: borderToBeApplied ? '1px solid #EFEFEF' : '0px',
    paddingBottom:16
  }}>
    <Grid
      item
      xs={2}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: 1,
        
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {svg}
      </Box>
    </Grid>
    <Grid item xs={10} style={{
      paddingLeft:8
    }}>
      <Stack spacing={1}>
        <Box
          sx={{
            fontFamily: "Lato",
            fontSize: "0.925rem",
            color: "#000",
          }}
        >
          {title}
        </Box>
        <Box
          sx={{
            fontSize: "0.925rem",
            color: "#5A5A5A",
          }}
        >
          {text}
        </Box>
      </Stack>
    </Grid>
  </Grid>
);

const LoaderRightStrip: FC<StripContent> = ({ items, width, gap }) => {
  return (
    <Stack
      sx={{
        py: 3,
        px: 1,
        border: "1px solid #EFEFEF",
        // boxShadow: "0px 0.25rem 0.875rem rgba(0, 0, 0, 0.05)",
        gap: gap ?? 5,
        width: width ? width : "22.5rem",
        height: "fit-content",
        borderRadius: "10px",
        boxShadow: "0px 4px 14px 0px #0000000D",
        padding: "26px 16px"
      }}
    >
      {items.map((item,index) => (
        <Strip {...item} key={item.title} borderToBeApplied={items?.length>1 && index === 0}/>
      ))}
    </Stack>
  );
};

export default LoaderRightStrip;
