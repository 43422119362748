import { Button } from "@mui/material";
import styled from "styled-components";
interface Props {
  disabled?: boolean;
  show?: boolean;
}
export const RefreshBtn = styled(Button)<Props>`
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  text-transform: capitalize !important;
  background-color: ${({ disabled }) => (disabled ? "" : "#EFF5FF!important")};
  visibility: ${({ show }) => (show ? "visible" : "collapse")};
  color: ${({ disabled }) => (disabled ? "" : "#0869FB!important")};
  border-radius: 0px !important;
  svg {
    path {
      fill: ${({ disabled }) => (disabled ? "#00000042" : "")};
    }
  }
`;

export const StyledDisclaimerText = styled.div`
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #000000;
`;

export const StyledPreviewDiv = styled.div`
  width: 100%;
  height: 100%;
  padding: 2%;
  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: flex-start;
  /* position: relative; */
  /* max-width: 550px; */
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledPreviewHeadingCard = styled.div`
  background: white;
  // border: 1px solid #dadcde;
  border-radius: 10px 10px 0px 0px;
  padding: 10px 10px 5px 0px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
`;

export const StyledErrorSubTitle = styled.p`
  text-align: center;
  font-family: Lato;
  color: #6f6f6f;
  font-size: 14px;
`;
export const StyledHeadTitle = styled.h5`
  margin-top: 1rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
`;
interface Props {
  disabled?: boolean;
  show?: boolean;
}
