import React from 'react'
import styled from 'styled-components'

const DropableContainer = styled.div``

type DropableProps = {
  target: GSAI_SOURCE
  className?: string
  children: React.ReactNode
  handleDrop: (key: string | number, source: GSAI_SOURCE) => void,
  isDroppable: boolean
}

export function Dropable({ target, className = '', children, handleDrop, isDroppable }: DropableProps) {
  function allowDrop(event: any) {
    event.preventDefault()
  }

  function onDrop(event: any) {
    if(!isDroppable) return
    const key = event.target.getAttribute('data-value')
    handleDrop(key, target)
  }

  return (
    <DropableContainer className={className} onDrop={onDrop} onDragOver={allowDrop}>
      {children}
    </DropableContainer>
  )
}

const DragableContainer = styled.div`
  cursor: move;
  width: 100%;
`

type DragableProps = {
  className?: string
  children: React.ReactNode
  onDragStart: (key: string, source: GSAI_SOURCE) => void
  source: GSAI_SOURCE
  value: string
}

export function Dragable({ className = '', children, onDragStart, source, value }: DragableProps) {
  return (
    <DragableContainer
      id={`dragable-element-${value}`}
      className={className}
      draggable='true'
      data-value={value}
      onDragStart={() => {
        onDragStart(value, source)
      }}
    >
      {children}
    </DragableContainer>
  )
}
