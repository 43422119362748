import { InputProps } from "@mui/material";
import InputLabel from "components/shared/InputLabel/InputLabel";
import React, { useEffect, useState } from "react";
import {
  StyledInputContainer,
  StyledTagContainer,
  StyledTags,
  StyledTextInputWithTag,
} from "./styled";
import { ReactComponent as CrossIcon } from "../../../../assets/svg/crossSVG.svg";
import { StyledErrorMessage } from "components/shared/Typography/Heading/styled";

interface InputWithLabelProps extends InputProps {
  label: string | undefined;
  required: boolean | undefined;
  tooltipText: string | undefined;
  value: string[];
  onChange: any;
  error: any;
  inputProps: any;
  placeholder: string;
}
export default function InputComponent({
  value,
  onChange,
  error,
  label,
  required,
  tooltipText,
  inputProps,
  placeholder,
  ...props
}: InputWithLabelProps) {
  const [adIds, setAdIds] = useState<string[]>([]);
  const [inputVal, setInputVal] = useState<string>("");
  const [focus, setFocus] = useState<boolean>(false);

  const deleteAdId = (index: number, onChange: (value: string[]) => void) => {
    onChange(adIds.filter((v, i) => i != index));
    setAdIds(adIds.filter((v, i) => i != index));
  };
  useEffect(() => {
    if (value?.length > 0 && !focus) {
      setInputVal(" ");
      setAdIds(value);
    }
    if (value?.length == 0) {
      setAdIds([]);
      setInputVal("");
    }
  }, [value]);

  useEffect(() => {
    if (focus) {
      setInputVal(adIds.join(","));
    } else {
      setInputVal(adIds.length > 0 ? " " : "");
    }
  }, [focus]);
  return (
    <StyledInputContainer>
      {label && (
        <InputLabel
          required={required}
          label={label}
          tooltipText={tooltipText}
        />
      )}
      <StyledTextInputWithTag
        id="mediaAdIds"
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onChange={(value) => {
          const localVal = value.target.value
            .split(",")
            .filter((item) => item.trim() != "");
          setAdIds(localVal);
          setInputVal(value.target.value);
          onChange(localVal);
        }}
        value={inputVal}
        placeholder={adIds?.length > 0 ? "" : placeholder}
        onKeyPress={(event: any) => {
          if (event) {
            var char = String.fromCharCode(event.which);
            if (!/^([a-zA-Z0-9,])/.test(char)) {
              event.preventDefault();
            }
            if (/^\r/.test(char)) {
              document.getElementById("mediaAdIds")?.blur();
            }
          }
        }}
        {...props}
      />
      <StyledTagContainer>
        {!focus &&
          adIds?.length > 0 &&
          adIds?.map((item, index) => {
            return (
              <StyledTags>
                {item}{" "}
                <CrossIcon
                  className="cross-tag"
                  onClick={() => deleteAdId(index, onChange)}
                />
              </StyledTags>
            );
          })}
      </StyledTagContainer>
      {error?.type === "required" && (
        <StyledErrorMessage>This field is required *</StyledErrorMessage>
      )}
      {error?.type === "maxLength" && (
        <StyledErrorMessage>{`Max length should <= ${
          inputProps && inputProps["maxLength"]
        }`}</StyledErrorMessage>
      )}
      {error?.type === "minLength" && (
        <StyledErrorMessage>{`Min length should >= ${
          inputProps && inputProps["minLength"]
        }`}</StyledErrorMessage>
      )}
      {error?.type === "min" && (
        <StyledErrorMessage>{`Min value should be >= ${
          inputProps && inputProps["min"]
        }`}</StyledErrorMessage>
      )}
      {error?.type === "max" && (
        <StyledErrorMessage>{`Max value should be <= ${
          inputProps && inputProps["max"]
        }`}</StyledErrorMessage>
      )}
      {error?.type === "apiFailure" && (
        <StyledErrorMessage>{error.message} *</StyledErrorMessage>
      )}
    </StyledInputContainer>
  );
}
