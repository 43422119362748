import { memo, useContext } from "react";
import { Handle } from "react-flow-renderer";
import Toggle from "react-toggle";
import "./explore-exploit.scss";
import "../index.scss";
import { useDispatch, useSelector } from "react-redux";
import { TaiContext } from "../TaiContextProvider";

export default memo(({ data, isConnectable }) => {
  const dispatch = useDispatch();
  const { setNodes, setEdges, setCohortType, cohortType} =
    useContext(TaiContext);

  return (
    <div className="p-3 bg-white card-style">
      <Handle
        type="target"
        position="left"
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      {data.data.map((element, index) => {
        return (
          <div key={index}>
            
            <div className={`flex justify-between font-weight-bold ${index === 0 ? 'border-separator' : ''}`} style={{ paddingTop: index === 0 ? '0': '1em', paddingBottom: index === 0 ? '1em': '0'}}>
              <div className="text-lg">{element.label}</div>
              <Toggle
                id="biscuit-status"
                aria-labelledby="biscuit-label"
                className="custom-react-toggle"
                icons={false}
                onChange={(e) => {
                  const value = cohortType === 'INTEREST' ? 'ICP' : 'INTEREST';
                  data.onTypeChange(dispatch, setEdges, setNodes, value);
                  setCohortType(value);
                }}
                checked={index === 0 ? cohortType === "INTEREST" : cohortType === "ICP"}
                defaultChecked={index === 0 ? cohortType === "INTEREST" : cohortType === "ICP"}
              />
            </div>
          </div>
        );
      })}
      {data.targetHandle && (
        <Handle
          type="source"
          position="right"
          style={{ background: "#0869FB" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={isConnectable}
        />
      )}
    </div>
  );
});
