import { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import {
  HighchartsStockChart,
  Chart,
  HighchartsProvider,
  XAxis,
  YAxis,
  Tooltip,
  SplineSeries,
  RangeSelector,
} from "react-jsx-highstock";

import { useSelector } from "react-redux";
import "./Chart.scss";

import {
  dayDateMonYearDisplayDate,
  ddMMNDisplayDate,
} from "../../../../utils/date.util";
import {
  currencyFormatter,
  getTooltipDate,
  valueFormatter,
} from "../../shared/utils/common.utils";

function Charts(props) {
  const {
    isCompare,
    currentPeriod,
    additionalDateInfo: { differenceInCalenderDay },
    compareType,
  } = useSelector((state) => state.ActionDebuggerReducer);
  function ChartTooltip() {
    var s = [];
    if (props.tooltipExtraInfo[this.x]?.phase) {
      s.push(
        `<p class="phase-wrapper"><span>Phase: ${
          props.tooltipExtraInfo[this.x]?.phase
        }</span></p>`
      );
    }
    this.points.forEach(function (point, index) {
      s.push(`<p key="${index}" class="first-title"><span style="color: ${point.series.color}; margin-right: 0.1em; font-size: 2em;vertical-align: middle;">\u25A0</span> ${dayDateMonYearDisplayDate(point.series.color === "#FDA92B" ? getTooltipDate(compareType, props.data[point.point.index][0], differenceInCalenderDay) : props.data[point.point.index][0])}</p>
       <p class="second-title"><span style="margin-right: 0.7em">${
         point.series.name
       }</span><span style="margin-right: 0.7em">:</span>${props?.currency + currencyFormatter(point.y) + (props?.isPercentage ? "%" : "")}</p>
      `);
    });
    return s.join("");
  }

  useEffect(() => {
    Highcharts.Point.prototype.highlight = function (event) {
      this.onMouseOver(); // Show the hover marker
      this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
  }, []);

  // keeping this for future refferance
  const plotOptions = {
    spline: {
      // lineWidth: 4,
      // states: {
      //   hover: {
      //     lineWidth: 5,
      //   },
      // },
      // marker: {
      //   enabled: false,
      // },
      // pointInterval: 3600000 * 24 * 1, // one day
      // pointStart: +currentPeriod.startDate,
      // pointEnd: +currentPeriod.endDate,
    },
  };

  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <HighchartsStockChart
        className="custom-component-chart"
        plotOptions={plotOptions}
      >
        <Chart
          height={props.isFirst ? "170" : "150"} // first should have more height
          marginTop={20}
          marginBottom={10}
          marginLeft={45}
          spacingTop={0}
          backgroundColor={props.bgColor}
          type="spline"
        />

        <XAxis
          type="linear"
          opposite={true}
          lineWidth={0}
          tickLength={0}
          // minorGridLineWidth={0}
          // gridLineWidth={0}
          // startOnTick={true}
          // endOnTick={true}
          // showLastLabel={true}
          // categories={props.dateRange}
          labels={{
            useHTML: true,
            enabled: props.RangeItem,
            allowOverlap: true,
            align: "center",
            formatter: function () {
              const chartDate = props.data[this.value]
                ? ddMMNDisplayDate(props.data[this.value][0])
                : "";
              const className =
                ddMMNDisplayDate(currentPeriod.startDate) === chartDate ||
                ddMMNDisplayDate(currentPeriod.endDate) === chartDate
                  ? "selected-label"
                  : "non-selected-label";
              return `<span
              class=${className}
                >
                  ${chartDate}
                </span>`;
            },

            y: 5,
            x: 0,
          }}
        ></XAxis>
        <Tooltip
          useHTML={true}
          formatter={ChartTooltip}
          shared={true}
          backgroundColor={"#E4EBFA"}
          borderColor={"#E4EBFA"}
          borderRadius={10}
          hideDelay={10}
          // backgroundColor={null}
          // shadow={false}
          // borderWidth={0}
          className="highcharts-tooltip"
          // style={{
          //   padding: 0,
          // }}
        />
        <YAxis
          gridLineWidth={0}
          minorGridLineWidth={0}
          distance={0}
          lineColor="transparent"
          opposite={false}
          labels={{
            overflow: "allow",
            formatter: function () {
              return valueFormatter(this.value);
            },
          }}
        >
          <YAxis.Title></YAxis.Title>
          <SplineSeries
            id="profit"
            data={props.data?.map((data) => data[1])}
            name={props.name}
            currency={props.currency}
            type="area"
            fillColor="#0869FB"
            threshold={null}
            color="#0869FB"
          />
          {isCompare && (
            <SplineSeries
              id="profit"
              data={props.oppositeData?.map((data) => data[1])}
              name={props.name}
              valuePrefix={props.currency}
              currency={props.currency}
              type="area"
              fillColor="#FDA92B"
              threshold={null}
              color="#FDA92B"
            />
          )}
        </YAxis>
        {props.RangeItem && false && (
          <RangeSelector>
            <RangeSelector.Button count={1} type="day">
              1d
            </RangeSelector.Button>
            <RangeSelector.Button count={7} type="day">
              7d
            </RangeSelector.Button>
            <RangeSelector.Button count={1} type="month">
              1m
            </RangeSelector.Button>
            <RangeSelector.Button type="all">All</RangeSelector.Button>
          </RangeSelector>
        )}
      </HighchartsStockChart>
    </HighchartsProvider>
  );
}

export default Charts;
