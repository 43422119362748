import { Box, CircularProgress } from "@mui/material";
import CustomizedDialogs from "./CustomizedDialogs";
import { DialogTitle } from "./styled";
import { Button } from "../Button/Button";

interface ConfirmDialogProps {
  show: boolean;
  onClose?: () => void;
  onComplete?: () => void;
  title?: string;
  completeButtonText?: string;
  closeButtonText?: string;
  loading?: boolean;
}

export const ConfirmDialog = ({
  show,
  onClose,
  onComplete,
  title,
  completeButtonText,
  closeButtonText,
  loading = false,
}: ConfirmDialogProps) => {
  return (
    <CustomizedDialogs
      show={show || loading}
      handleClose={onClose}
      type="ConfirmDialog"
      fullScreen={false}
      maxWidth="sm"
    >
      <Box width="350px">
        <DialogTitle className="mb-0">{title}</DialogTitle>
        <Box
          display="flex"
          justifyContent="flex-end"
          mt="32px"
          alignItems="center"
        >
          <Box mr="30px">
            <Button onClick={onClose}>{closeButtonText ?? "Cancel"}</Button>
          </Box>
          <Button
            buttonVariant="contained"
            className="button-base-mui"
            onClick={onComplete}
          >
            {loading ? (
              <Box width="60px" height="22px">
                <CircularProgress
                  size={25}
                  sx={{
                    color: "white",
                  }}
                  thickness={5}
                />
              </Box>
            ) : (
              completeButtonText ?? "Discard"
            )}
          </Button>
        </Box>
      </Box>
    </CustomizedDialogs>
  );
};
