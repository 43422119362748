import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export default function DateTimePickerValue({
  setSelectedDateTime,
  selectedDateTime,
  disabled,
}: {
  selectedDateTime: any;
  setSelectedDateTime: any;
  disabled: boolean;
}) {
  const [value, setValue] = React.useState<Dayjs | null>();

  React.useEffect(() => {
    setValue(selectedDateTime);
  }, [selectedDateTime]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
        <DateTimePicker
          disabled={disabled}
          className="dt-picker"
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#DDDDDD !important",
            },
            "& .Mui-disabled": { cursor: "not-allowed", opacity: "0.5" },
            "& .MuiInputBase-root:focus": {
              outline: "none !important",
              border: "1px solid red",
            },
            "& .MuiInputBase-root:hover": {
              outline: "none !important",
            },
            "& .MuiInputBase-root": {
              height: "42px",
              width: "310px",
              color: "#999",
              borderRadius: "8px",
              marginTop: "-8px",
              outline: "none",
            },
            "& .MuiSvgIcon-root": {
              color: "#999",
            },
          }}
          // value={value}
          value={selectedDateTime}
          // onChange={(newValue: any) => setValue(newValue)}
          onChange={(newValue: any) => {
            // setValue(newValue);
            setSelectedDateTime(newValue);
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
