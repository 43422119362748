import React, { useEffect, useRef, useState } from "react";
import InputText from "../../../ui/InputText/InputText";
import info from "../../../assets/svg/smallinfo.svg";
import { ReactComponent as Icon } from "../../../assets/svg/attribution-metric.svg";
// import Select from "../../../ui/Select/Select";
import { getCurrencySymbol } from "../../../utils/commonFunctions";
import { getOptimizationEventDetails } from "../../../store/actions/campaign";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "../../../store/types/auth";
import { SET_RESULT_METRICS } from "../../../store/types/campaign";
import { MenuItem, OutlinedInput, Select } from "@mui/material";
import InfoPopup from "./InfoPopup";
import { AdAccountState } from "store/types/adaccount";

const AIGroupSettingForm: React.FC<{
  data: any;
  handleChanage: (
    key: string,
    value: any,
    elementId: string,
    adAccountId?: string
  ) => void;
  adAccountId: string;
  elementId: string;
  metricList?: any;
  platform: string;
  currency: any;
  platformId: any;
  disableOptimize: boolean;
}> = ({
  data,
  handleChanage,
  adAccountId,
  elementId,
  metricList,
  platform,
  currency,
  platformId,
  disableOptimize,
}) => {
  const dispatch = useDispatch();

  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const { resultMetrics } = useSelector((state: any) => state.campaign);
  useEffect(() => {
    dispatch({ type: SET_RESULT_METRICS, payload: {} });
  }, []);
  const { user } = authState;
  const { brandSources } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const target = useRef(null);
  const [optimisationEventList, setOptimisationEventList] = useState([]);
  const [optimizationEventOption, setOptimizationEventOption] = useState([]);

  const options = React.useMemo(() => {
    return resultMetrics;
  }, [platform, resultMetrics]);

  useEffect(() => {
    if (metricList && metricList.length > 0) {
      metricList.map((list: any, index: number) => {
        list.name = list.label;
        list.value = list.label;
      });
      setOptimisationEventList(metricList);
    }
  }, [metricList]);

  useEffect(() => {
    dispatch(
      getOptimizationEventDetails(
        { adAccountId: adAccountId, platformId: platformId, user },
        (response: any, error: boolean) => {
          if (!error) {
            const temp: any = [];
            response.data.map((item: any) => {
              temp.push({ name: item.metricName, value: item.metricName });
            });
            setOptimizationEventOption(temp);
          } else {
            console.log(error);
          }
        }
      )
    );
  }, [adAccountId, platformId]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          opacity: disableOptimize ? "0.5" : "1",
          pointerEvents: disableOptimize ? "none" : "auto",
        }}
      >
        <p className="formName">AI Model Configuration</p>
        <div style={{ display: "flex" }}>
          <div
            key={`optimisationEvents`}
            className="form-group px-3 w-25 optimisationEvents"
          >
            <label className="text-md">
              Optimisation Events *
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">Optimisation Events</div>
                    <span> Optimisation Events</span>
                  </div>
                </div>
              </div>
            </label>
            <Select
              id="optimisation-events-dropdown"
              value={data?.result_metric_key}
              onChange={(e: any) =>
                handleChanage(
                  "result_metric_key",
                  e.target.value,
                  elementId,
                  adAccountId
                )
              }
              input={<OutlinedInput />}
              renderValue={(value) => {
                if (value == -1) {
                  return "Select Optimisation Events";
                }
                return options?.[adAccountId]?.find(
                  (item: any) => item?.metricKey === value
                )?.metricLabel;
              }}
            >
              <MenuItem disabled value="">
                Select Optimisation Events
              </MenuItem>
              {options?.[adAccountId]?.map((list: any) => (
                <MenuItem
                  key={list.metricKey}
                  value={list.metricKey}
                  sx={{
                    fontFamily: "Inter",
                  }}
                >
                  {list.metricLabel}{" "}
                  {list.isAttribuationMetric ? (
                    <InfoPopup
                      source={brandSources.find(
                        (each: any) => each.id === list?.sourceId
                      )}
                    />
                  ) : (
                    ""
                  )}
                </MenuItem>
              ))}
            </Select>

            {/* <Select
              name="optimisationEvents"
              options={
                options?.[adAccountId]?.map((v: any, i: any) => {
                  return {
                    name: v?.metricLabel,
                    value: v?.metricKey,
                  };
                }) || []
              }
              defaultDisableOption={
                data &&
                options?.[adAccountId]?.find(
                  (m: any) => m.value === data?.result_metric_key
                )?.value
              }
              value={data.result_metric_key}
              selectedName="Optimisation Events"
              onChange={(e: any) =>
                handleChanage("result_metric_key", e.target.value, elementId)
              }
              placeholder
            /> */}
          </div>

          <div key={`target_cpr`} className="form-group px-3 w-25">
            <label className="text-md">
              Target Cost Per Optimisation Event *
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">
                      Target Cost Per Optimisation Event
                    </div>
                    <span> Target Cost Per Optimisation Event</span>
                  </div>
                </div>
              </div>
            </label>
            <div className="currency-input">
              <InputText
                className="currency-symbol"
                name="currency"
                value={getCurrencySymbol(currency[elementId])}
                disabled={true}
              ></InputText>
              <InputText
                name={`target_cpr`}
                value={data.target_cpr}
                step={"any"}
                onChange={(e: any) => {
                  if (!e.target.value) {
                    e.target.value = 0;
                  } else if (e.target.value.endsWith(".")) {
                    if (e.target.value.toString().split(".").length > 2) {
                      e.target.value = parseFloat(
                        e.target.value.toString().split(".")[0] +
                          "." +
                          e.target.value.toString().split(".")[1]
                      );
                    } else {
                      e.target.value = e.target.value;
                    }
                  } else if (e.target.value.indexOf(".") != -1) {
                    if (e.target.value.toString().split(".")[1].length >= 2) {
                      e.target.value = parseFloat(e.target.value).toFixed(2);
                    } else {
                      e.target.value = parseFloat(e.target.value);
                    }
                  } else {
                    e.target.value = parseFloat(e.target.value);
                  }
                  if (isNaN(e.target.value)) {
                    e.target.value = 0;
                  }
                  handleChanage("target_cpr", e.target.value, elementId);
                }}
                required
              />
            </div>
          </div>
          <div key={`target_result`} className="form-group px-3 w-25">
            <label className="text-md">
              Target Daily Results (Optimisation Event) *
              <div className="tooltip-container">
                <i ref={target}>
                  <img className="ml-2" src={info} alt="info_style" />
                </i>
                <div className="custom-tooltip-layout tooltip">
                  <div className="tootltip-wrapper">
                    <div className="tooltip-header">
                      Target Daily Results (Optimisation Event)
                    </div>
                    <span> Target Daily Results (Optimisation Event)</span>
                  </div>
                </div>
              </div>
            </label>
            <div className="currency-input">
              <InputText
                name={`target_result`}
                value={data.target_result}
                step={"any"}
                onChange={(e: any) => {
                  if (!e.target.value) {
                    e.target.value = 0;
                  } else if (e.target.value.endsWith(".")) {
                    if (e.target.value.toString().split(".").length > 2) {
                      e.target.value = parseFloat(
                        e.target.value.toString().split(".")[0] +
                          "." +
                          e.target.value.toString().split(".")[1]
                      );
                    } else {
                      e.target.value = e.target.value;
                    }
                  } else if (e.target.value.indexOf(".") != -1) {
                    if (e.target.value.toString().split(".")[1].length >= 2) {
                      e.target.value = parseFloat(e.target.value).toFixed(2);
                    } else {
                      e.target.value = parseFloat(e.target.value);
                    }
                  } else {
                    e.target.value = parseFloat(e.target.value);
                  }
                  if (isNaN(e.target.value)) {
                    e.target.value = 0;
                  }
                  handleChanage("target_result", e.target.value, elementId);
                }}
                required
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIGroupSettingForm;
