import styled from "styled-components";

import Button from "../../../../ui/ButtonUI/ButtonUI";
import { ReactComponent as SuccessIcon } from "../../../../assets/svg/gsai-publish-success.svg";
import { ReactComponent as FailedIcon } from "../../../../assets/svg/gsai-publish-failed.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/close.svg";
import Modal from "./index";
import { useDispatch, useSelector } from "react-redux";
import { AdAccountState } from "store/types/adaccount";

type Props = {
  adName: string;
};

const Content = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button.close {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #333333;
  }

  .description {
    margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #727272;
  }
`;

function PublishedSuccess({ adName }: Props) {
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const dispatch = useDispatch();

  return (
    <Modal style={{ width: "412px", height: "348.41px" }}>
      <Content>
        <Button
          className="close"
          onClick={() => {
            dispatch({
              type: "UPDATE_GSAI_STORE",
              payload: {
                screen: 0,
                modal: "",
              },
            });
            // window.location.href = `/google-search-playground?brand_id=${selectedBrand?.id}`;
          }}
        >
          <CloseIcon />
        </Button>
        <SuccessIcon />
        <div className="title">Published</div>
        <div className="description">Your Ad '{adName}' has been published</div>
      </Content>
    </Modal>
  );
}

const FailedContent = styled(Content)`
  margin-top: 30px;

  svg.fail-icon {
    width: 72px;
  }

  .title {
    margin-top: 27px;
  }

  button.try-again {
    margin-top: 44px;
    background: #0869fb;
    color: #fff;
  }
`;

export function PublishedFailed({ handleRetry }: any) {
  const dispatch = useDispatch();

  return (
    <Modal style={{ width: "412px", height: "348.41px" }}>
      <FailedContent>
        <Button
          className="close"
          onClick={() => {
            dispatch({
              type: "UPDATE_GSAI_STORE",
              payload: {
                modal: "",
              },
            });
          }}
        >
          <CloseIcon />
        </Button>
        <FailedIcon className="fail-icon" />
        <div className="title">Publishing Failed</div>
        <div className="description">
          An error occured while publishing the ad
        </div>
        <Button className="try-again" onClick={handleRetry}>
          Try Again
        </Button>
      </FailedContent>
    </Modal>
  );
}

export default PublishedSuccess;
