export const PrivacyPolicy = () => (
  <div
    style={{
      position: "fixed",
      bottom: "0px",
      width: "98%",
      fontSize:"14px",
      textAlign: "left",
      background: "white",
      color: "white",
      padding: "10px",
      paddingLeft: "50px", borderTop:"1px solid #CCC",
    }}
  >
    <a target={"_blank"} href="https://pixis.ai/privacy-policy/">Privacy Policy</a>
  </div>
);
