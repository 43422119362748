import { styled as MUIStyled } from "@mui/system";
import styled from "styled-components";
import checkSVG from "../../../../assets/svg/check.svg";

export const StyledIcon = MUIStyled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 18,
  height: 18,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#ffffff",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

export const StyledCheckedIcon = MUIStyled(StyledIcon)({
  backgroundColor: "#0869FB",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 18,
    height: 18,
    backgroundImage: `url(${checkSVG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#0869FB",
  },
});

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
