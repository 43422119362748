import React from "react";
import ModalContainer from "../../components/Modal/ModalContainer";
import ModalWrapper from "../../components/Modal/ModalWrapper";

const OutlineModal: React.FC<{width?:any;text: any; title: string; handleSubmit: (value: any) => void; handleClose: (value: any) => void; showModal:any;}> = ({

    text,
    title,
    handleSubmit,
    handleClose,
    showModal,
    width

  }) => {

    return(
        <>
            {showModal && (
            <ModalWrapper>
              <ModalContainer
                title={title}
                submitText={"Continue"}
                cancelText="Cancel"
                width={width?width:"25vw"}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
              >
                <div>{text}</div>
              </ModalContainer>
            </ModalWrapper>
          )}
        </>
    )
}
export default OutlineModal;