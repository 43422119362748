// import styled from "styled-components";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, SelectFormControl } from "../shared";

export const ManualAction = styled(Button)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875em;
  height: 2.625em;
  border-radius: 8px;
  background-color: #0869fb;
  min-width: 10rem;
  margin-left: -17px;
  
`;
export const Publish = styled(Button)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875em;
  height: 2.5em;
  border-radius: 8px;
  line-height: 24px;
  background-color: #0869fb;
  /* min-width: 10rem; */
  padding: 16px 24px;
`;
export const ActionButton = styled(Button)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  height: 2.5em;
  border-radius: 8px;
  line-height: 24px;
  background-color: #0869fb;
  width: 172px;
  /* min-width: 10rem; */
  padding: 16px 24px;
  position: relative;
  transition: all 1.4s;
`;
export const ArrowDown = styled(Box)`
  position: absolute;
  height: 10.88px;
  width: 13.88px;
  /* transform: rotate(45deg); */
  transform: matrix(1, 0, 0, -1, 0, 0);
  line-height: 24px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background-color: #0869fb;

  top: 33px;
`;
export const ActiveActionButton = styled(Button)`
  color: #000;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 2.5em;
  width: 172px;
  border-radius: 8px;
  transition: all 1.4s;
  line-height: 24px;
  background-color: #fff;
  border: 1px solid #0869fb;
  /* min-width: 10rem; */
  padding: 16px 24px;
`;
export const SearchItem = styled(Box)`
  display: flex;
  justify-content: flex-start;
  border: 1px solid #ddd;
  border-radius: 8px;
  height: 56px;
  align-items: center;
  position: relative;
  img {
    position: absolute;
    z-index: 0;
    right: 1rem;
  }
  input {
    border: none;
    outline: none;
    padding-left: 1rem;
    width: 100%;
  }
  input:focus {
    border: none;
  }
`;
