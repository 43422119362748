import { styled } from "@mui/material";
import { Box } from "@mui/system";

export interface TextInputProps {
  fontWeight?: number | string;
  fontSize?: number | string;
  lineHeight?: number | string;
  color?: string;
  fontFamily?: string;
  fontStyle?: string;
  height?: number | string;
}

export const StyledText = styled(Box)`
  font-family: ${({ fontFamily }: TextInputProps) => fontFamily ?? "Inter"};
  font-style: ${({ fontStyle }: TextInputProps) => fontStyle ?? "normal"};
  font-weight: ${({ fontWeight }: TextInputProps) => fontWeight ?? 500};
  font-size: ${({ fontSize }: TextInputProps) => fontSize ?? "0.875em"};
  line-height: ${({ lineHeight }: TextInputProps) => lineHeight ?? "1.2143em"};
  color: ${({ color }: TextInputProps) => color ?? "#000000"};
`;
