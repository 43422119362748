export const GET_FB_ACTIVITY_LOGS = "GET_FB_ACTIVITY_LOGS";
export const SET_FB_ACTIVITY_LOGS = "SET_FB_ACTIVITY_LOGS";
export const SET_ACTIVITY_LOG_ADS = "SET_ACTIVITY_LOG_ADS";
export const SET_ACTIVITY_LOG_ADSETS = "SET_ACTIVITY_LOG_ADSETS";
export const SET_ACTIVITY_LOG_AD_ACCOUNTS = "SET_ACTIVITY_LOG_AD_ACCOUNTS";
export const GET_ADACCOUNTS_LOG = "GET_ADACCOUNTS_LOG";
export const SET_ADACCOUNTS_LOG = "SET_ADACCOUNTS_LOG";
export const GET_ADSET_LOG = "GET_ADSET_LOG";
export const SET_ADSET_LOGS = "SET_ADSET_LOGS";
export const GET_ADS_LOG = "GET_ADS_LOG";
export const SET_ADS_LOGS = "SET_ADS_LOGS";
export const GET_AD_DETAILS = "GET_AD_DETAILS";
