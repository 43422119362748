import { Box } from "@mui/material";
import styled from "styled-components";
import { styled as MUIstyled } from "@mui/material";

export const StyledPacingContainer = MUIstyled(Box)`
  background: rgba(239, 246, 255, 0.6);
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const StyledPacingHeader = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  color: #000000;
  padding-bottom: 0.875rem;
`;
