import {
  CommonState,
  SetSelectedMonthYear,
  SET_SELECTED_MONTH_YEAR,
  SetAllPlatformsSmb,
  SET_ALL_PLATFORMS_SMB,
  SetSelectedOptimizerSmb,
  SET_SELECTED_OPTIMIZER_SMB,
} from "../../types/SmbDashboard/common";

const initialCommonState: CommonState = {
  calendar: {
    selectedMonth: new Date().getMonth() + 1,
    selectedYear: new Date().getFullYear(),
  },
  platforms: null,
  selectedOptimizerToRedirect: null,
};

export default function commonReducer(
  state = initialCommonState,
  action: SetSelectedMonthYear | SetAllPlatformsSmb | SetSelectedOptimizerSmb
) {
  switch (action.type) {
    case SET_SELECTED_MONTH_YEAR: {
      return {
        ...state,
        calendar: action.payload,
      };
    }
    case SET_ALL_PLATFORMS_SMB: {
      return {
        ...state,
        platforms: action.payload,
      };
    }
    case SET_SELECTED_OPTIMIZER_SMB: {
      return {
        ...state,
        selectedOptimizerToRedirect: action.payload,
      };
    }
    default:
      return state;
  }
}
