import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useManageAgencyUserRolesState } from "./UserRoles/useManageAgencyUserRolesState";
import { getMyRolesAPI } from "./UserRoles/API/getMyRoles";
import { useSelector } from "react-redux";
import { mixPanelAdEvent } from "utils/mixpanel";
import { isAdminRole } from "utils/commonFunctions";
import { AdAccountState } from "store/types/adaccount";

function ManageAgencyButton() {
  const history = useHistory();
  const [myRolesListData, setMyRolesListData] = useState([]);
  // const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const agenciesState = useSelector((state: any) => state.smb.agencies);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;
  useEffect(() => {
    getMyRolesAPI().then((data: any) => {
      if (data.success) {
        setMyRolesListData(data?.data?.userRoles);
        // setIsSuperAdmin(data?.data?.isSuperAdmin);
      }
    });
  }, []);
  // useEffect(() => {
  //   const isAgencyAdmin = myRolesListData.some(
  //     (element: any) => element.role === "agency-admin"
  //   );
  //   if (isSuperAdmin) {
  //     setShowButton(true);
  //   } else if (isAgencyAdmin) {
  //     setShowButton(true);
  //   } else {
  //     setShowButton(false);
  //   }
  // }, [myRolesListData, isSuperAdmin]);

  useEffect(() => {
    if (isAdminRole(brandRoles)) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [myRolesListData]);

  return (
    <>
      {showButton && (
        <Box
          sx={{
            marginRight: "-48px",
          }}
        >
          <Button
            className="d-flex align-items-center w-100 justify-content-space-between"
            onClick={() => {
              mixPanelAdEvent("Page View - Dashboard", {
                module_name: "Manage Agency",
                agency_name: agenciesState.selected.name,
                agency_id: agenciesState.selected.id,
              });
              history.push("/manage-agency" + window.location.search);
            }}
            disableRipple
            variant="contained"
            sx={{
              minWidth: "165px",
              width: "auto",
              height: "auto",
              padding: 1,
              borderRadius: 2,
              gap: 1,
              boxShadow: "none",
              background: "#0869FB",
              textTransform: "none",
              "&:hover": {
                boxShadow: "none",
                background: "#0869FB",
              },
            }}
          >
            Manage Agency
          </Button>
        </Box>
      )}
    </>
  );
}

export default ManageAgencyButton;
