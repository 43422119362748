import React from "react";
import { useSelector } from "react-redux";

import "./TAISideBar.scss";
import { SIDEBAR_STEPS } from "./constants/index";
import taiCheckmark from "../../../assets/tai/taiCheckmark.svg";
import CommonLoader from "../../../assets/Gif/CommonLoader.gif";

function TAISideBar() {
  const currentPage = useSelector((state) => state.taiCommon.currentPage);

  return (
    <div className="m-2 d-flex">
      <div
        className="main d-flex flex-column"
        style={{ width: "100%", marginLeft: "5%" }}
      >
        {SIDEBAR_STEPS.map((step) => {
          const isCurrentPage = currentPage === step.index;
          const isLoader = currentPage / 2 === step.id;
          const isCompleted = currentPage > step.index;
          return (
            <div
              className={`sidebar-container  ${
                isCurrentPage
                  ? `active-step`
                  : isCompleted && !isLoader
                  ? "completed-step"
                  : `${isLoader ? "loader-step" : ""}`
              }`}
              key={step.id}
            >
              <div className={`${step.id !== 4 ? "last" : ""}`}>
                <div className="text-sm circle">
                  {isLoader ? (
                    <img
                      src={CommonLoader}
                      height={20}
                      width={20}
                      alt="loader"
                    />
                  ) : isCompleted ? (
                    <img
                      src={taiCheckmark}
                      height={10}
                      width={10}
                      alt="checkmark"
                    />
                  ) : (
                    step.id
                  )}
                </div>
                {step.id !== 4 && (
                  <div className="dotted-container">
                    <div className="dotted"></div>
                  </div>
                )}
              </div>
              <div className="text-container">
                <div className="main-text">{step.text}</div>
                <div className="sub-text">{step.subText}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TAISideBar;
