import { useState, createContext, useEffect, useContext } from "react";
import KeywordExplorer from "./KeywordExplorer";
import {
  tobeFixedNumber,
  findMinMax,
  TAIGSearchMixPanel,
} from "../../utils/index";
import { TaiContext } from "../../../TaiContextProvider";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { MICRON_VALUE } from "../../constants/KeywordExplorer";
import {
  KeywordDataTypes,
  KeywordContextType,
  BucketIds,
  KeywordsData,
  AdGroupDetailsType,
  KeywordsType,
  ClusterDetailsType,
  StarType,
  NewKeywordType,
  MonthlyAvgSearch,
  CompetitionIndex,
  CompetitionIndexEnum,
} from "./types/index";
import {
  CLUSTER_ID_KEY,
  CLUSTER_NAME_KEY,
  ADGROUP_ID_KEY,
  ADGROUP_NAME_KEY,
  BUCKET_NAMES,
  METRIC_NAMES,
  CAMPAIGN_ID_KEY,
  CAMPAIGN_NAME_KEY,
  SELECTED_METRICS,
  SEARCH_METRIC_NAME,
  MICRON_METRICS,
} from "../../constants/KeywordExplorer";
import { AdAccountState } from "store/types/adaccount";
import { storeRecommendedKeywords } from "store/actions/targetingAI";
import { formatMetricValue } from "./components/Keywords";
import { CommonState } from "store/types/common";

export const KeywordContext = createContext<KeywordContextType>({
  keywords: {},
  campaignDetails: {},
  setKeywords: () => {},
  starredItems: {},
  setStarredItems: () => {},
  setPositiveAdGroup: () => {},
  setPositiveCluster: () => {},
  setNegativeAdGroup: () => {},
  setNegativeCluster: () => {},
  setNewKwsAdGroup: () => {},
  setNewKwsCluster: () => {},
  selectedKeywordsBucket: "positive_kw",
  isClusterView: false,
  setIsClusterView: () => {},
  setSelectedKeywordsBucket: () => {},
  getSelectedBucketData: () => ({}),
  getLengthForBucket: () => 0,
  adGroupDetails: {},
  clusterDetails: {},
  selectedFilters: {},
  setSelectedFilters: () => {},
  getTotalLength: () => 0,
  handleDeleteClick: () => {},
  handleDuplicateClick: () => {},
  changeSingleProperty: () => {},
  handleSelectKeywords: () => {},
  checkSelectAll: () => 0,
  getBucketSelectdItemsLength: () => 0,
  checkSelectAllBucket: () => false,
  handleSelectAllKeywords: () => {},
  checkDeselectAllBucket: () => false,
  handleDeselectAllKeywords: () => {},
  deleteBulkClick: () => {},
  saveBulkClick: () => {},
  duplicateBulkClick: () => {},
  changeBulkProperty: () => {},
  behaviourBulkClick: () => {},
  moveBulkClick: () => {},
  isAnythingSelected: () => false,
  getDisplayObjectForPublish: () => {},
  getPublishPayload: () => {},
  filteredIds: null,
  filterText: "",
  setFilterText: () => "",
  onFilterChange: () => {},
  applyFilters: () => {},
  kSensitivity: 50,
  handleSensitivityChange: () => {},
  positiveLoader: true,
  negativeLoader: true,
  newKwsLoader: true,
  getLoaderForBucket: () => true,
  selectedIds: {},
  exportToCsv: () => {},
  wastedSpends: 0,
  appliedSelectedFilters: {},
  setAppliedSelectedFilters: () => {},
});

function KeywordContextProvider({
  data,
  isConnectable,
}: {
  data: any;
  isConnectable: boolean;
}) {
  const dispatch = useDispatch();
  const { ExploreValueCheck } = useSelector(
    (state: any) => state.gsTargetingAI.gsCalibration.data
  );
  const { monthlyAvgSearch, competationIndex, competationIndexCheck, monthlyAvgSearchCheck }: { monthlyAvgSearch : MonthlyAvgSearch, competationIndex: CompetitionIndex, competationIndexCheck:boolean, monthlyAvgSearchCheck: boolean } = useSelector(
    (state: any) => state.gsTargetingAI.gsKeywordTuning.data
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
    const aiInitiationData = useSelector(
    (state: any) => state.aiInitiation.apiData
  );
  const { selectedBrand } = adaccount;
  const { selectedAgency } = useSelector(
    (state: { common: CommonState }) => state.common
  );
  const [keywordIdeas, positive, negetive] = BUCKET_NAMES;
  const [kSensitivity, setKSensitivity] = useState(70);
  const [selectedFilters, setSelectedFilters] = useState({
    ...SELECTED_METRICS,
  }) as any;
  const [appliedSelectedFilters, setAppliedSelectedFilters] = useState({
    ...SELECTED_METRICS,
  }) as any;
  const [filterText, setFilterText] = useState("");
  const [isClusterView, setIsClusterView] = useState(false);
  const [selectedKeywordsBucket, setSelectedKeywordsBucket] =
    useState<KeywordDataTypes>(positive.type);

  const [keywords, setKeywords] = useState<KeywordsType>({});
  const [filteredIds, setFilterIds] = useState<(number | string)[] | null>(
    null
  );
  const [starredItems, setStarredItems] = useState<StarType>({});

  const [clusterDetails, setClusterDetails] = useState<ClusterDetailsType>({});
  const [adGroupDetails, setAdGroupDetails] = useState<AdGroupDetailsType>({});

  const [positiveAdGroup, setPositiveAdGroup] = useState<BucketIds>({});
  const [positiveCluster, setPositiveCluster] = useState<BucketIds>({});
  const [positiveLength, setPositiveLength] = useState<number>(0);
  const [positiveLoader, setPositiveLoader] = useState<boolean>(true);

  const [negativeAdGroup, setNegativeAdGroup] = useState<BucketIds>({});
  const [negativeCluster, setNegativeCluster] = useState<BucketIds>({});
  const [negativeLength, setNegativeLength] = useState<number>(0);
  const [negativeLoader, setNegativeLoader] = useState<boolean>(true);

  const [newKwsAdGroup, setNewKwsAdGroup] = useState<BucketIds>({});
  const [newKwsCluster, setNewKwsCluster] = useState<BucketIds>({});
  const [newKwsLength, setNewKwsLength] = useState<number>(0);
  const [newKwsLoader, setNewKwsLoader] = useState<boolean>(true);

  const [campaignDetails, setCampaignDetails] = useState<any>({});

  const [nextIndex, setNextIndex] = useState<number>(1);
  const [selectedIds, setSelectedIds] = useState<any>({
    [positive.type]: {},
    [negetive.type]: {},
    [keywordIdeas.type]: {},
  });
  const [wastedSpends, setWastedSpends] = useState<number>(0);
  const { keywordsJsonTai } = useContext(TaiContext) as any;

  const areAllChecksEnabled =()=>{
    const { min, max } = monthlyAvgSearch;
    const { high, medium, low } = competationIndex;
    if (min === 10 && max === 10000 && high && low && medium) {
      return true
    }else{
      return false
    }
  }

  const filterByCriteria = (
    data: NewKeywordType[],
  ): NewKeywordType[] => {
    const { min, max } = monthlyAvgSearch;
    const { high, medium, low } = competationIndex;

    let filteredKeywords:NewKeywordType[]= []

    if(monthlyAvgSearchCheck && competationIndexCheck){
      if (areAllChecksEnabled()) {
        filteredKeywords = data
      }else{
        if (max === 10000) {
          filteredKeywords = data.filter(item => {
            //== null comparison checks for both null and undefined, making it a convenient way to handle both cases.
            const isWithinMonthlyAvgSearch =  (min && item.avgMonthlySearches >= min)
      
            const isWithinCompetitionIndex =
              (high && item.competition === CompetitionIndexEnum.HIGH) ||
              (medium && item.competition === CompetitionIndexEnum.MEDIUM) ||
              (low && item.competition === CompetitionIndexEnum.LOW);
              return isWithinMonthlyAvgSearch && isWithinCompetitionIndex;
          });
        } else {
          filteredKeywords = data.filter(item => {
            //== null comparison checks for both null and undefined, making it a convenient way to handle both cases.
            const isWithinMonthlyAvgSearch =
              (min == null || item.avgMonthlySearches >= min) &&
              (max == null || item.avgMonthlySearches <= max);
      
            const isWithinCompetitionIndex =
              (high && item.competition === CompetitionIndexEnum.HIGH) ||
              (medium && item.competition === CompetitionIndexEnum.MEDIUM) ||
              (low && item.competition === CompetitionIndexEnum.LOW);
              return isWithinMonthlyAvgSearch && isWithinCompetitionIndex;
          });
        }
      }
    }else if (!monthlyAvgSearchCheck && competationIndexCheck) {
      filteredKeywords = data.filter(item => {
  
        const isWithinCompetitionIndex =
          (high && item.competition === CompetitionIndexEnum.HIGH) ||
          (medium && item.competition === CompetitionIndexEnum.MEDIUM) ||
          (low && item.competition === CompetitionIndexEnum.LOW);
          return isWithinCompetitionIndex;
      });
    }else if(monthlyAvgSearchCheck && !competationIndexCheck){
      if (max === 10000) {
        filteredKeywords = data.filter(item => {
          const isWithinMonthlyAvgSearch = (min != null && item.avgMonthlySearches >= min);
          return isWithinMonthlyAvgSearch;
        });
      } else {
        filteredKeywords = data.filter(item => {
          //== null comparison checks for both null and undefined, making it a convenient way to handle both cases.
          const isWithinMonthlyAvgSearch =
            (min == null || item.avgMonthlySearches >= min) &&
            (max == null || item.avgMonthlySearches <= max);
          return isWithinMonthlyAvgSearch;
        });
      }
    }else if(!monthlyAvgSearchCheck && !competationIndexCheck){
      filteredKeywords = data
    }
    return filteredKeywords
    };
  /**
   * Handles keywords intially
   * @param keywords keywords json from the link
   * @author Yash Vaghvani
   */
  const handleKeywords = (keywords: any) => {
    let typesArray = [];
    let startId = nextIndex;
    const adGroupDetailsTemp: AdGroupDetailsType = {};
    const clusterDetailsTemp: ClusterDetailsType = {};
    const campaignDetailsNew: any = {};
    const selectedMetrics: any = {};
    if (ExploreValueCheck) {
      typesArray = [positive.type, keywordIdeas.type, negetive.type];
    } else {
      setNewKwsLoader(false);
      typesArray = [positive.type, negetive.type];
    }
    const adGroupIds: any[] = [];
    typesArray.reduce((prev: KeywordsType, next: KeywordDataTypes) => {
      let currentBucket = keywords[next];
      if (next === 'kw_ideas') {
        const rawKWIdeasList = keywords[next]
        currentBucket= filterByCriteria(rawKWIdeasList)
      }      const currentAdGroupData: BucketIds = {};
      const currentClusterData: BucketIds = {};
      let newSelectedFilters = { ...selectedFilters };
      let sum = 0;
      currentBucket.forEach((element: any) => {
        if (!adGroupIds.includes(element[ADGROUP_ID_KEY]))
          adGroupIds.push(element[ADGROUP_ID_KEY]);
        if (next === negetive.type) {
          sum += element["metrics.costMicros"];
        }
        const metrics: any = METRIC_NAMES[next];
        Object.keys(metrics).forEach((key: any) => {
          const value = MICRON_METRICS.includes(key)
            ? element[key] * MICRON_VALUE
            : element[key];
          element[key] = value;
          if (
            next === keywordIdeas.type &&
            adGroupDetailsTemp[element[ADGROUP_ID_KEY]]
          ) {
            const adGroup = adGroupDetailsTemp[element[ADGROUP_ID_KEY]];
            element[ADGROUP_NAME_KEY] = adGroup.name;
            element[CAMPAIGN_ID_KEY] = adGroup[CAMPAIGN_ID_KEY];
            element[CAMPAIGN_NAME_KEY] = adGroup[CAMPAIGN_NAME_KEY];
          }

          const valueToCompare = tobeFixedNumber(value);
          if (!selectedMetrics[key]) {
            selectedMetrics[key] =
              typeof valueToCompare === "number"
                ? {
                    min: valueToCompare,
                    max: valueToCompare,
                    selected: [valueToCompare, valueToCompare],
                  }
                : {
                    LOW: true,
                    MEDIUM: true,
                    HIGH: true,
                  };
          } else {
            findMinMax(valueToCompare as number, selectedMetrics[key]);
          }
          if (selectedMetrics[key]) {
            newSelectedFilters[next][key] = selectedMetrics[key];
          }
          return true;
        });
        // Per score for new keywords
        if (element.per_score) {
          if (!selectedMetrics.per_score) selectedMetrics.per_score = {};
          if (selectedMetrics.per_score[next]) {
            findMinMax(
              element.per_score as number,
              selectedMetrics.per_score[next]
            );
          } else {
            selectedMetrics.per_score = {
              ...selectedMetrics.per_score,
              [next]: {
                min: element.per_score,
                max: element.per_score,
                selected: [element.per_score, element.per_score],
              },
            };
          }
        }
        if (!currentAdGroupData[element[ADGROUP_ID_KEY]]) {
          currentAdGroupData[element[ADGROUP_ID_KEY]] = [];
          if (
            !adGroupDetailsTemp[element[ADGROUP_ID_KEY]] &&
            element[ADGROUP_NAME_KEY]
          ) {
            adGroupDetailsTemp[element[ADGROUP_ID_KEY]] = {
              id: element[ADGROUP_ID_KEY],
              name: element[ADGROUP_NAME_KEY],
              [CAMPAIGN_ID_KEY]: element[CAMPAIGN_ID_KEY],
              [CAMPAIGN_NAME_KEY]: element[CAMPAIGN_NAME_KEY],
            };
          }
        }

        if (!currentClusterData[element[CLUSTER_ID_KEY]]) {
          currentClusterData[element[CLUSTER_ID_KEY]] = [];
          if (!clusterDetailsTemp[element[CLUSTER_ID_KEY]]) {
            clusterDetailsTemp[element[CLUSTER_ID_KEY]] = {
              id: element[CLUSTER_ID_KEY],
              name: element[CLUSTER_NAME_KEY],
            };
          }
        }
        if (!campaignDetailsNew[element[CAMPAIGN_ID_KEY]]) {
          campaignDetailsNew[element[CAMPAIGN_ID_KEY]] = {
            id: element[CAMPAIGN_ID_KEY],
            name: element[CAMPAIGN_NAME_KEY],
            adGroups: {},
          };
        }
        campaignDetailsNew[element[CAMPAIGN_ID_KEY]].adGroups[
          element[ADGROUP_ID_KEY]
        ] = {
          [ADGROUP_ID_KEY]: element[ADGROUP_ID_KEY],
          [ADGROUP_NAME_KEY]: element[ADGROUP_NAME_KEY],
          per_score: element.per_score,
        };
        currentAdGroupData[element[ADGROUP_ID_KEY]].push(startId);
        currentClusterData[element[CLUSTER_ID_KEY]].push(startId);
        prev[startId] = { ...element, index: startId, bucket: next };
        startId += 1;
      });
      setWastedSpends(sum > 0 ? sum * MICRON_VALUE : sum);
      setCampaignDetails(campaignDetailsNew);
      setKeywords(prev);
      setSelectedFilters(newSelectedFilters);
      console.log(
        "LL: handleKeywords -> currentAdGroupData",
        currentAdGroupData,
        next
      );
      if (next === positive.type) {
        setPositiveLength(currentBucket.length);
        setAdGroupDetails({ ...adGroupDetails, ...adGroupDetailsTemp });
        setClusterDetails({ ...clusterDetails, ...clusterDetailsTemp });
        setPositiveAdGroup(currentAdGroupData);
        setPositiveCluster(currentClusterData);
        setPositiveLoader(false);
      } else if (next === negetive.type) {
        setNegativeLength(currentBucket.length);
        setAdGroupDetails({ ...adGroupDetails, ...adGroupDetailsTemp });
        setClusterDetails({ ...clusterDetails, ...clusterDetailsTemp });
        setNegativeAdGroup(currentAdGroupData);
        setNegativeCluster(currentClusterData);
        setNegativeLoader(false);
      } else {
        setNewKwsLength(currentBucket.length);
        setAdGroupDetails({ ...adGroupDetails });
        setClusterDetails({ ...clusterDetails, ...clusterDetailsTemp });
        setNewKwsAdGroup(currentAdGroupData);

        setNewKwsCluster(currentClusterData);
        setNewKwsLoader(false);
      }

      return prev;
    }, {});

    setNextIndex(startId);
  };
  /**
   * Fetches keywords from the json link
   * @returns A Function to handle the keywords
   * @author Yash Vaghvani
   */
  const fetchKeywordsJson = async () => {
    try {
      const result: { data: KeywordsData } = await axios.get(keywordsJsonTai, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      return handleKeywords(result.data);
    } catch (error) {
      console.log("LL: fetchKeywordsJson -> error", error);
    }
  };

  /***
   * Gets Selected bucket data
   * @returns Array of clusterview and adgroupview elements of selected bucket
   * @author Yash Vaghvani
   */
  const getSelectedBucketData = () => {
    if (selectedKeywordsBucket === positive.type) {
      return isClusterView ? positiveCluster : positiveAdGroup;
    } else if (selectedKeywordsBucket === negetive.type) {
      return isClusterView ? negativeCluster : negativeAdGroup;
    }
    return isClusterView ? newKwsCluster : newKwsAdGroup;
  };

  useEffect(() => {
    fetchKeywordsJson();
  }, []);

  const selectedKey = isClusterView ? "clusterDetails" : "adGroupDetails";

  const checkSelectAll = (indices: string[]) => {
    const filteredIndices = indices.filter((index: string) => {
      return Object.keys(selectedIds[selectedKeywordsBucket]).includes(
        index?.toString()
      );
    });
    return filteredIndices.length;
  };

  const checkSelectAllBucket = () => {
    const selectedBucketData = getSelectedBucketData();

    const indices = Object.keys(selectedBucketData).reduce(
      (prev: (number | string)[], next: string) => {
        const nextCheck = isClusterView ? next : next;
        prev.push(...selectedBucketData[nextCheck]);
        return prev;
      },
      []
    );
    return indices.length !== checkSelectAll(indices as any);
  };
  const checkDeselectAllBucket = (bucket?: undefined | string) => {
    return (
      Object.keys(selectedIds[bucket ? bucket : selectedKeywordsBucket])
        .length > 0
    );
  };

  const getLengthForBucket = (key: string) => {
    if (key === positive.type) {
      return positiveLength;
    } else if (key === negetive.type) {
      return negativeLength;
    }
    return newKwsLength;
  };

  const getLoaderForBucket = (key: string) => {
    if (key === positive.type) {
      return positiveLoader;
    } else if (key === negetive.type) {
      return negativeLoader;
    }
    return newKwsLoader;
  };
  const getTotalLength = () => {
    return positiveLength + negativeLength + newKwsLength;
  };

  const handleSelectKeywords = (indices: any) => {
    const indexes = Object.keys(indices);
    const isSelected = checkSelectAll(indexes);
    if (isSelected !== indexes.length) {
      setSelectedIds({
        ...selectedIds,
        [selectedKeywordsBucket]: {
          ...selectedIds[selectedKeywordsBucket],
          ...indices,
        },
      });
    } else {
      const tempSelectedIds = { ...selectedIds };
      indexes.forEach(
        (index) => delete selectedIds[selectedKeywordsBucket][index]
      );
      setSelectedIds({
        ...tempSelectedIds,
      });
    }
  };
  const handleSelectAllKeywords = () => {
    const selectedBucketData = getSelectedBucketData();
    const indices = Object.keys(selectedBucketData).reduce(
      (prev: any, next: string) => {
        const intNext = isClusterView ? next : next;
        selectedBucketData[intNext].forEach((idx) => {
          prev[idx] = {
            index: idx,
            [ADGROUP_ID_KEY]: keywords[idx][ADGROUP_ID_KEY],
            [CLUSTER_ID_KEY]: keywords[idx][CLUSTER_ID_KEY],
          };
        });
        return prev;
      },
      {}
    );
    handleSelectKeywords(indices);
  };

  const handleDeselectAllKeywords = () => {
    setSelectedIds({ ...selectedIds, [selectedKeywordsBucket]: {} });
  };

  const getBucketSelectdItemsLength = (bucket: KeywordDataTypes) =>
    Object.keys(selectedIds[bucket]).length;

  const applySearch = () => {
    const newFilterd: number[] = [];
    Object.keys(keywords).forEach((element: any) => {
      if (
        keywords[element]["new_kw.text"]?.includes(filterText) ||
        keywords[element]["searchTermView.searchTerm"]?.includes(filterText)
      ) {
        newFilterd.push(element);
      }
    });
    setFilterIds(newFilterd);
  };

  const handleDeleteClick = (
    adGroupId: number,
    clusterId: number | string,
    indices: (number | string)[],
    type?: KeywordDataTypes
  ) => {
    const selectedType = type ? type : selectedKeywordsBucket;
    const tempKeywords = { ...keywords };
    if (!type) {
      const tempSelectedIds = { ...selectedIds };
      indices.forEach((index) => {
        delete tempSelectedIds[selectedType][index];
        delete tempKeywords[index];
      });
      setSelectedIds(tempSelectedIds);
    }
    if (selectedType === positive.type) {
      setPositiveAdGroup({
        ...positiveAdGroup,
        [adGroupId]: positiveAdGroup[adGroupId].filter(
          (idx) => !indices.includes(idx)
        ),
      });
      setPositiveCluster({
        ...positiveCluster,
        [clusterId]: positiveCluster[clusterId].filter(
          (idx) => !indices.includes(idx)
        ),
      });
      setPositiveLength(positiveLength - indices.length);
      setKeywords(tempKeywords);
      return;
    } else if (selectedType === negetive.type) {
      setNegativeAdGroup({
        ...negativeAdGroup,
        [adGroupId]: negativeAdGroup[adGroupId].filter(
          (idx) => !indices.includes(idx)
        ),
      });
      setNegativeCluster({
        ...negativeCluster,
        [clusterId]: negativeCluster[clusterId].filter(
          (idx) => !indices.includes(idx)
        ),
      });
      setNegativeLength(negativeLength - indices.length);
      setKeywords(tempKeywords);
      return;
    } else {
      setNewKwsAdGroup({
        ...newKwsAdGroup,
        [adGroupId]: newKwsAdGroup[adGroupId].filter(
          (idx) => !indices.includes(idx)
        ),
      });
      setNewKwsCluster({
        ...newKwsCluster,
        [clusterId]: newKwsCluster[clusterId].filter(
          (idx) => !indices.includes(idx)
        ),
      });
      setNewKwsLength(newKwsLength - indices.length);
      setKeywords(tempKeywords);
      return;
    }
  };

  const handleDuplicateClick = (
    adGroupId: number,
    clusterId: number | string,
    index: number
  ) => {
    setKeywords({
      ...keywords,
      [nextIndex]: { ...keywords[index], index: nextIndex },
    });
    if (selectedKeywordsBucket === positive.type) {
      setPositiveAdGroup({
        ...positiveAdGroup,
        [adGroupId]: [...positiveAdGroup[adGroupId], nextIndex],
      });
      setPositiveCluster({
        ...positiveCluster,
        [clusterId]: [...positiveCluster[clusterId], nextIndex],
      });
      setPositiveLength(positiveLength + 1);
    } else if (selectedKeywordsBucket === negetive.type) {
      setNegativeAdGroup({
        ...negativeAdGroup,
        [adGroupId]: [...negativeAdGroup[adGroupId], nextIndex],
      });
      setNegativeCluster({
        ...negativeCluster,
        [clusterId]: [...negativeCluster[clusterId], nextIndex],
      });
      setNegativeLength(negativeLength + 1);
    } else {
      setNewKwsAdGroup({
        ...newKwsAdGroup,
        [adGroupId]: [...newKwsAdGroup[adGroupId], nextIndex],
      });
      setNewKwsCluster({
        ...newKwsCluster,
        [clusterId]: [...newKwsCluster[clusterId], nextIndex],
      });
      setNewKwsLength(newKwsLength + 1);
    }
    if (filteredIds) {
      setFilterIds([...filteredIds, nextIndex]);
    }
    setNextIndex(nextIndex + 1);
  };

  const changeSingleProperty = (
    indices: string[],
    prop: string,
    value: string
  ) => {
    setKeywords(
      indices.reduce(
        (prev: any, index) => {
          prev[index][prop] = value;
          return prev;
        },
        { ...keywords }
      )
    );
  };

  const deleteFromAdGroupOrCluster = (
    id: string,
    bucket: KeywordDataTypes,
    selectedAdGroupBucket: BucketIds,
    selectedClusterBucket: BucketIds
  ) => {
    const selectedElement = selectedIds[bucket][id];
    selectedAdGroupBucket[selectedElement[ADGROUP_ID_KEY]] =
      selectedAdGroupBucket[selectedElement[ADGROUP_ID_KEY]].filter(
        (index: number | string) => id.toString() !== index.toString()
      );
    if (selectedAdGroupBucket[selectedElement[ADGROUP_ID_KEY]].length === 0)
      delete selectedAdGroupBucket[selectedElement[ADGROUP_ID_KEY]];
    selectedClusterBucket[selectedElement[CLUSTER_ID_KEY]] =
      selectedClusterBucket[selectedElement[CLUSTER_ID_KEY]].filter(
        (index: number | string) => id.toString() !== index.toString()
      );
    if (selectedClusterBucket[selectedElement[CLUSTER_ID_KEY]].length === 0)
      delete selectedClusterBucket[selectedElement[CLUSTER_ID_KEY]];
  };
  const deleteBulkClick = () => {
    const tempKeywords = { ...keywords };
    Object.keys(selectedIds).forEach((bucket: any) => {
      const tempIds = Object.keys(selectedIds[bucket]);

      if (tempIds.length > 0) {
        let selectedAdGroupBucket: any = null;
        let selectedClusterBucket: any = null;
        let setSelectedAdGroupBucket: any = null;
        let setSelectedClusterBucket: any = null;
        if (bucket === positive.type) {
          selectedAdGroupBucket = { ...positiveAdGroup };
          selectedClusterBucket = { ...positiveCluster };
          setSelectedAdGroupBucket = setPositiveAdGroup;
          setSelectedClusterBucket = setPositiveCluster;
        } else if (bucket === negetive.type) {
          selectedAdGroupBucket = { ...negativeAdGroup };
          selectedClusterBucket = { ...negativeCluster };
          setSelectedAdGroupBucket = setNegativeAdGroup;
          setSelectedClusterBucket = setNegativeCluster;
        } else {
          selectedAdGroupBucket = { ...newKwsAdGroup };
          selectedClusterBucket = { ...newKwsCluster };
          setSelectedAdGroupBucket = setNewKwsAdGroup;
          setSelectedClusterBucket = setNewKwsCluster;
        }
        tempIds.forEach((id) => {
          deleteFromAdGroupOrCluster(
            id,
            bucket,
            selectedAdGroupBucket,
            selectedClusterBucket
          );
          delete tempKeywords[id];
        });
        setSelectedAdGroupBucket(selectedAdGroupBucket);
        setSelectedClusterBucket(selectedClusterBucket);
      }
    });
    setPositiveLength(
      positiveLength - Object.keys(selectedIds[positive.type]).length
    );
    setNegativeLength(
      negativeLength - Object.keys(selectedIds[negetive.type]).length
    );
    setNewKwsLength(
      newKwsLength - Object.keys(selectedIds[keywordIdeas.type]).length
    );
    setSelectedIds({
      [positive.type]: {},
      [negetive.type]: {},
      [keywordIdeas.type]: {},
    });
    setKeywords(tempKeywords);
  };

  const saveBulkClick = () => {
    const tempKeywords = { ...keywords };
    const tempStarredItems = { ...starredItems };
    const basePayload = {
      adAccountId: aiInitiationData.adAccount.adAccountId,
      adAccountName: aiInitiationData.adAccount.name,
      brandName: selectedBrand.name,
      brandId: selectedBrand.id,
      aiGroupId: aiInitiationData.aiGroup.id,
      aiGroupName: aiInitiationData.aiGroup.name,
    };

    const payloadData: any = [];

    Object.keys(selectedIds).forEach((bucket: any) => {
      const tempIds = Object.keys(selectedIds[bucket]);

      if (tempIds.length > 0) {
        tempIds.forEach((id: any) => {
          const adgroupsAlreadyPresent = payloadData.find(
            (data: any) =>
              data.campaignId === keywords[id]["campaign.id"] &&
              data.adGroupId === keywords[id]["adGroup.id"]
          );
          if (adgroupsAlreadyPresent) {
            adgroupsAlreadyPresent.keywords.push({
              matchType:
                keywords[id]["segments.keyword.info.matchType"] || "EXACT",
              negative: keywords[id].bucket === "negative_kw",
              text:
                keywords[id]["searchTermView.searchTerm"] ||
                keywords[id]["new_kw.text"],
              new: keywords[id].bucket === "kw_ideas",
              // new: true,
              metadata: {
                ...keywords[id],
              },
            });
          } else {
            const adGroupsPayloadObject: any = {
              ...basePayload,
              campaignName: keywords[id]["campaign.name"],
              campaignId: keywords[id]["campaign.id"],
              // adGroupId: keywords[id]["adGroup.id"],
              adGroupName: keywords[id]["adGroup.name"],
              keywords: [
                {
                  matchType:
                    keywords[id]["segments.keyword.info.matchType"] || "EXACT",
                  negative: keywords[id].bucket === "negative_kw",
                  text:
                    keywords[id]["searchTermView.searchTerm"] ||
                    keywords[id]["new_kw.text"],
                  new: keywords[id].bucket === "kw_ideas",
                  // new: true,
                  metadata: {
                    ...keywords[id],
                  },
                },
              ],
            };

            if (!keywords[id]["isNew"]) {
              adGroupsPayloadObject.adGroupId = keywords[id]["adGroup.id"];
            }

            payloadData.push(adGroupsPayloadObject);
          }
        });
      }
    });

    dispatch(
      storeRecommendedKeywords(
        {
          data: payloadData,
          brandId: selectedBrand.id,
          aigroupId: aiInitiationData.aiGroup.id,
        },
        (res: any, err: any) => {
          if (!err) {
            for (let adgroup of res.data) {
              for (let keyword of adgroup) {
                tempKeywords[keyword.metadata.index].saveId = keyword.id;
                tempStarredItems[keyword.metadata.index] = true;
              }
            }
            // setPositiveLength(
            //   positiveLength - Object.keys(selectedIds[positive.type]).length
            // );
            // setNegativeLength(
            //   negativeLength - Object.keys(selectedIds[negetive.type]).length
            // );
            // setNewKwsLength(
            //   newKwsLength - Object.keys(selectedIds[keywordIdeas.type]).length
            // );
            setSelectedIds({
              [positive.type]: {},
              [negetive.type]: {},
              [keywordIdeas.type]: {},
            });
            setKeywords(tempKeywords);
            setStarredItems(tempStarredItems);
          }
        }
      )
    );
  };

  const duplicateBulkClick = () => {
    let newKeywords = { ...keywords };
    let newIdx = nextIndex;
    const newFilterIds = filteredIds ? [...filteredIds] : [];
    Object.keys(selectedIds).forEach((bucket: any) => {
      const tempIds = Object.keys(selectedIds[bucket]);

      if (tempIds.length > 0) {
        let selectedAdGroupBucket: any = null;
        let selectedClusterBucket: any = null;
        let setSelectedAdGroupBucket: any = null;
        let setSelectedClusterBucket: any = null;
        if (bucket === positive.type) {
          selectedAdGroupBucket = { ...positiveAdGroup };
          selectedClusterBucket = { ...positiveCluster };
          setSelectedAdGroupBucket = setPositiveAdGroup;
          setSelectedClusterBucket = setPositiveCluster;
        } else if (bucket === negetive.type) {
          selectedAdGroupBucket = { ...negativeAdGroup };
          selectedClusterBucket = { ...negativeCluster };
          setSelectedAdGroupBucket = setNegativeAdGroup;
          setSelectedClusterBucket = setNegativeCluster;
        } else {
          selectedAdGroupBucket = { ...newKwsAdGroup };
          selectedClusterBucket = { ...newKwsCluster };
          setSelectedAdGroupBucket = setNewKwsAdGroup;
          setSelectedClusterBucket = setNewKwsCluster;
        }
        tempIds.forEach((id) => {
          const selectedElement = selectedIds[bucket][id];
          newKeywords[newIdx] = {
            ...newKeywords[id],
            index: newIdx,
          };
          if (newFilterIds?.includes(id)) {
            newFilterIds?.push(newIdx);
          }
          selectedAdGroupBucket[selectedElement[ADGROUP_ID_KEY]] =
            selectedAdGroupBucket[selectedElement[ADGROUP_ID_KEY]].reduce(
              (prev: number[], index: number) => {
                prev.push(index);
                if (id === index?.toString()) {
                  prev.push(newIdx);
                }
                return prev;
              },
              []
            );
          selectedClusterBucket[selectedElement[CLUSTER_ID_KEY]] =
            selectedClusterBucket[selectedElement[CLUSTER_ID_KEY]].reduce(
              (prev: number[], index: number) => {
                prev.push(index);
                if (id.toString() === index?.toString()) {
                  prev.push(newIdx);
                }
                return prev;
              },
              []
            );
          newIdx += 1;
        });
        if (filteredIds && filteredIds.length > 0) setFilterIds(newFilterIds);
        setSelectedAdGroupBucket(selectedAdGroupBucket);
        setSelectedClusterBucket(selectedClusterBucket);
      }
    });
    setKeywords(newKeywords);
    setNextIndex(newIdx);
    setPositiveLength(
      positiveLength + Object.keys(selectedIds[positive.type]).length
    );
    setNegativeLength(
      negativeLength + Object.keys(selectedIds[negetive.type]).length
    );
    setNewKwsLength(
      newKwsLength + Object.keys(selectedIds[keywordIdeas.type]).length
    );
  };
  const changeBulkProperty = (key: string, value: any) => {
    Object.keys(selectedIds).forEach((bucket: any) => {
      const keys = Object.keys(selectedIds[bucket]);
      if (keys.length > 0) {
        changeSingleProperty(keys, key, value);
      }
    });
  };
  const moveBulkClick = (
    campaignId: number,
    campaignName: string,
    adGroupId: number,
    adGroupName: string,
    isNew: boolean | undefined,
    toNew: boolean | undefined
  ) => {
    const newPositiveAdGroups = { ...positiveAdGroup };
    const newNegativeAdGroups = { ...negativeAdGroup };
    const newKeywordIdeasGroup = { ...newKwsAdGroup };

    const newKws = Object.keys(selectedIds).reduce(
      (prev: any, bucket: string) => {
        const indices = Object.keys(selectedIds[bucket]);
        const indicesNumber: (number | string)[] = [];
        indices.forEach((index: string | number) => {
          indicesNumber.push(index);
          index = parseInt(index as string);
          if (bucket === positive.type) {
            newPositiveAdGroups[prev[index][ADGROUP_ID_KEY]] =
              newPositiveAdGroups[prev[index][ADGROUP_ID_KEY]].filter(
                (idx) => idx.toString() !== index.toString()
              );
            if (newPositiveAdGroups[prev[index][ADGROUP_ID_KEY]].length === 0)
              delete newPositiveAdGroups[prev[index][ADGROUP_ID_KEY]];
          } else if (bucket === negetive.type) {
            newNegativeAdGroups[prev[index][ADGROUP_ID_KEY]] =
              newNegativeAdGroups[prev[index][ADGROUP_ID_KEY]].filter(
                (idx) => idx.toString() !== index.toString()
              );
            if (newNegativeAdGroups[prev[index][ADGROUP_ID_KEY]].length === 0)
              delete newNegativeAdGroups[prev[index][ADGROUP_ID_KEY]];
          } else {
            newKeywordIdeasGroup[prev[index][ADGROUP_ID_KEY]] =
              newKeywordIdeasGroup[prev[index][ADGROUP_ID_KEY]].filter(
                (idx) => idx.toString() !== index.toString()
              );
            if (newKeywordIdeasGroup[prev[index][ADGROUP_ID_KEY]].length === 0)
              delete newKeywordIdeasGroup[prev[index][ADGROUP_ID_KEY]];
          }
          prev[index][CAMPAIGN_ID_KEY] = campaignId;
          prev[index][CAMPAIGN_NAME_KEY] = campaignName;
          prev[index][ADGROUP_ID_KEY] = adGroupId;
          prev[index][ADGROUP_NAME_KEY] = adGroupName;
          prev[index].isNew = isNew || toNew;
        });
        if (indicesNumber.length > 0) {
          if (bucket === positive.type) {
            if (newPositiveAdGroups[adGroupId]) {
              newPositiveAdGroups[adGroupId].push(...indicesNumber);
            } else {
              newPositiveAdGroups[adGroupId] = [...indicesNumber];
            }
            setPositiveAdGroup(newPositiveAdGroups);
          } else if (bucket === negetive.type) {
            if (newNegativeAdGroups[adGroupId]) {
              newNegativeAdGroups[adGroupId].push(...indicesNumber);
            } else {
              newNegativeAdGroups[adGroupId] = [...indicesNumber];
            }
            setNegativeAdGroup(newNegativeAdGroups);
          } else {
            if (newKeywordIdeasGroup[adGroupId]) {
              newKeywordIdeasGroup[adGroupId].push(...indicesNumber);
            } else {
              newKeywordIdeasGroup[adGroupId] = [...indicesNumber];
            }
            setNewKwsAdGroup(newKeywordIdeasGroup);
          }
        }

        return prev;
      },
      { ...keywords }
    );
    if (isNew) {
      setAdGroupDetails({
        ...adGroupDetails,
        [adGroupId]: {
          id: adGroupId,
          name: adGroupName,
          [CAMPAIGN_ID_KEY]: campaignId,
          [CAMPAIGN_NAME_KEY]: campaignName,
        },
      });
      setCampaignDetails({
        ...campaignDetails,
        [campaignId]: {
          ...campaignDetails[campaignId],
          adGroups: {
            ...campaignDetails[campaignId].adGroups,
            [adGroupId]: {
              [ADGROUP_ID_KEY]: adGroupId,
              [ADGROUP_NAME_KEY]: adGroupName,
              isNew: true,
            },
          },
        },
      });
    }
    setKeywords(newKws);
    setSelectedIds({
      [positive.type]: {},
      [negetive.type]: {},
      [keywordIdeas.type]: {},
    });
  };
  const behaviourBulkClick = (behaviour: KeywordDataTypes) => {
    const tempKeywords = { ...keywords };
    if (behaviour === positive.type) {
      Object.keys(selectedIds[negetive.type]).forEach((id) => {
        const selectedElement = selectedIds[negetive.type][id];
        tempKeywords[id].bucket = positive.type;
        if (positiveAdGroup[selectedElement[ADGROUP_ID_KEY]]) {
          positiveAdGroup[selectedElement[ADGROUP_ID_KEY]].push(id);
        } else {
          positiveAdGroup[selectedElement[ADGROUP_ID_KEY]] = [id];
        }
        if (positiveCluster[selectedElement[CLUSTER_ID_KEY]]) {
          positiveCluster[selectedElement[CLUSTER_ID_KEY]].push(id);
        } else {
          positiveCluster[selectedElement[CLUSTER_ID_KEY]] = [id];
        }

        deleteFromAdGroupOrCluster(
          id,
          negetive.type,
          negativeAdGroup,
          negativeCluster
        );
      });
      setNegativeAdGroup(negativeAdGroup);
      setNegativeCluster(negativeCluster);
      setNegativeLength(
        negativeLength - Object.keys(selectedIds[negetive.type]).length
      );

      Object.keys(selectedIds[keywordIdeas.type]).forEach((id) => {
        const selectedElement = selectedIds[keywordIdeas.type][id];
        tempKeywords[id].bucket = positive.type;
        if (positiveAdGroup[selectedElement[ADGROUP_ID_KEY]]) {
          positiveAdGroup[selectedElement[ADGROUP_ID_KEY]].push(id);
        } else {
          positiveAdGroup[selectedElement[ADGROUP_ID_KEY]] = [id];
        }
        if (positiveCluster[selectedElement[CLUSTER_ID_KEY]]) {
          positiveCluster[selectedElement[CLUSTER_ID_KEY]].push(id);
        } else {
          positiveCluster[selectedElement[CLUSTER_ID_KEY]] = [id];
        }
        deleteFromAdGroupOrCluster(
          id,
          keywordIdeas.type,
          newKwsAdGroup,
          newKwsCluster
        );
      });
      setNewKwsAdGroup(newKwsAdGroup);
      setNewKwsCluster(newKwsCluster);
      setNewKwsLength(
        newKwsLength - Object.keys(selectedIds[keywordIdeas.type]).length
      );
      setPositiveAdGroup(positiveAdGroup);
      setPositiveCluster(positiveCluster);
      setPositiveLength(
        positiveLength +
          Object.keys(selectedIds[keywordIdeas.type]).length +
          Object.keys(selectedIds[negetive.type]).length
      );
    } else if (behaviour === negetive.type) {
      Object.keys(selectedIds[positive.type]).forEach((id) => {
        const selectedElement = selectedIds[positive.type][id];
        tempKeywords[id].bucket = negetive.type;
        if (negativeAdGroup[selectedElement[ADGROUP_ID_KEY]]) {
          negativeAdGroup[selectedElement[ADGROUP_ID_KEY]].push(id);
        } else {
          negativeAdGroup[selectedElement[ADGROUP_ID_KEY]] = [id];
        }
        if (negativeCluster[selectedElement[CLUSTER_ID_KEY]]) {
          negativeCluster[selectedElement[CLUSTER_ID_KEY]].push(id);
        } else {
          negativeCluster[selectedElement[CLUSTER_ID_KEY]] = [id];
        }
        deleteFromAdGroupOrCluster(
          id,
          positive.type,
          positiveAdGroup,
          positiveCluster
        );
      });
      const length = Object.keys(selectedIds[positive.type]).length;
      setPositiveAdGroup(positiveAdGroup);
      setPositiveCluster(positiveCluster);
      setPositiveLength(positiveLength - length);
      setNegativeAdGroup(negativeAdGroup);
      setNegativeCluster(negativeCluster);
      setNegativeLength(negativeLength + length);
    }
    setSelectedIds({
      [positive.type]: {},
      [negetive.type]: {},
      [keywordIdeas.type]: {},
    });
  };

  const isAnythingSelected = () => {
    return (
      Object.keys(selectedIds[positive.type]).length > 0 ||
      Object.keys(selectedIds[negetive.type]).length > 0 ||
      Object.keys(selectedIds[keywordIdeas.type]).length > 0
    );
  };

  const getDisplayObjectForPublish = () => {
    return Object.keys(selectedIds).reduce((prev: any, bucket: string) => {
      const indices = Object.keys(selectedIds[bucket]);
      indices.forEach((index: string | number) => {
        const selectedObject = keywords[index as number];
        selectedObject.bucket = bucket;
        if (selectedObject[CAMPAIGN_ID_KEY]) {
          const campaign = prev[selectedObject[CAMPAIGN_ID_KEY] as number];
          if (campaign) {
            if (
              campaign.adGroups &&
              campaign.adGroups[selectedObject[ADGROUP_ID_KEY]]
            ) {
              campaign.adGroups[selectedObject[ADGROUP_ID_KEY]].items.push(
                selectedObject
              );
            } else {
              campaign.adGroups[selectedObject[ADGROUP_ID_KEY]] = {
                id: selectedObject[ADGROUP_ID_KEY],
                name: selectedObject[ADGROUP_NAME_KEY],
                items: [selectedObject],
              };
            }
            campaign.total = campaign.total + 1;
          } else {
            prev[selectedObject[CAMPAIGN_ID_KEY] as number] = {
              id: selectedObject[CAMPAIGN_ID_KEY],
              name: selectedObject[CAMPAIGN_NAME_KEY],
              total: 1,
              adGroups: {
                [selectedObject[ADGROUP_ID_KEY]]: {
                  id: selectedObject[ADGROUP_ID_KEY],
                  name: selectedObject[ADGROUP_NAME_KEY],
                  items: [selectedObject],
                },
              },
            };
          }
        }
      });
      return prev;
    }, {});
  };
  const getPublishPayload = () => {
    return Object.keys(selectedIds).reduce(
      (prev: any, bucket: any) => {
        Object.keys(selectedIds[bucket]).forEach((key: string | number) => {
          const tempPayload: any = { ...keywords[key as number] };
          if (starredItems[key] && tempPayload.saveId) {
            prev.deletionAdGroups.kwIds.push(tempPayload.saveId);
          }
          prev[bucket === keywordIdeas.type ? positive.type : bucket].push({
            customer_id: tempPayload["customer.id"],
            campaign_id: tempPayload[CAMPAIGN_ID_KEY],
            campaign_name: tempPayload[CAMPAIGN_NAME_KEY],
            keyword_matchType:
              tempPayload["segments.keyword.info.matchType"] || "EXACT",
            keyword_text: tempPayload[SEARCH_METRIC_NAME[bucket]],
            ad_group_id: tempPayload.isNew
              ? undefined
              : tempPayload[ADGROUP_ID_KEY],
            ad_group_name: tempPayload[ADGROUP_NAME_KEY],
            create: tempPayload.isNew,
          });
        });
        return prev;
      },
      {
        [negetive.type]: [],
        [positive.type]: [],
        // this is to deleted saved keywords after publishing those keywords in backend
        deletionAdGroups: {
          kwIds: [],
        },
      }
    );
  };
  const onFilterChange = (key: string, values: any, type = "range") => {
    setSelectedFilters(
      selectedKeywordsBucket === keywordIdeas.type
        ? {
            ...selectedFilters,
            [selectedKeywordsBucket]: {
              ...selectedFilters[selectedKeywordsBucket],
              [key]:
                type === "range"
                  ? {
                      ...selectedFilters[selectedKeywordsBucket][key],
                      selected: values,
                    }
                  : { ...values },
            },
          }
        : {
            ...selectedFilters,
            [positive.type]: {
              ...selectedFilters[positive.type],
              [key]:
                type === "range"
                  ? {
                      ...selectedFilters[positive.type][key],
                      selected: values,
                    }
                  : { ...values },
            },
            [negetive.type]: {
              ...selectedFilters[negetive.type],
              [key]:
                type === "range"
                  ? {
                      ...selectedFilters[negetive.type][key],
                      selected: values,
                    }
                  : { ...values },
            },
          }
    );
  };
  const applyFilters = () => {
    setAppliedSelectedFilters(
      JSON.parse(JSON.stringify({ ...selectedFilters }))
    );
  };
  const handleSensitivityChange = (event: any, newValue: number | number[]) => {
    setKSensitivity(newValue as number);
  };

  useEffect(() => {
    if (filterText !== "") {
      applySearch();
    } else {
      applySensitivity();
    }
  }, [filterText]);

  const applySensitivity = () => {
    if (Object.keys(keywords).length > 0) {
      const positiveArray = Object.values(positiveAdGroup).reduce(
        (prev, ids) => {
          prev.push(...ids);
          return prev;
        },
        []
      );
      const negetiveArray = Object.values(negativeAdGroup).reduce(
        (prev, ids) => {
          prev.push(...ids);
          return prev;
        },
        []
      );
      const newKwsArray = Object.values(newKwsAdGroup).reduce((prev, ids) => {
        prev.push(...ids);
        return prev;
      }, []);
      const words = [positiveArray, negetiveArray].map(
        (arr: (number | string)[]) => {
          return arr
            .sort((a: number | string, b: number | string) => {
              return (
                (keywords[b].per_score as number) -
                (keywords[a].per_score as number)
              );
            })
            .slice(
              0,
              Math.ceil(((100 - 0.99 * kSensitivity) * arr.length) / 100)
            )
            .map((item: any) => item);
        }
      );
      setFilterIds([...words[0], ...words[1], ...newKwsArray]);
    }
  };

  useEffect(() => {
    applySensitivity();
  }, [kSensitivity]);

  const csvDownload = () => {
    if (Object.keys(keywords).length === 0) {
      return [];
    }
    return Object.values(keywords).reduce((prev: any, element: any) => {
      if (element.bucket === keywordIdeas.type) {
        prev.push(
          `${element["new_kw.text"]},New Keywords Recommended,${
            element["adGroup.name"]
          },${element["cluster_name"]},${
            element["segments.keyword.info.matchType"] ?? "EXACT"
          },${element["avgMonthlySearches"]},${element["3m_change"]},${
            element["competitionIndex"]
          },-,-,-`
        );
      } else {
        const type = element.bucket === positive.type ? "Positive" : "Negative";
        prev.push(
          `${element["searchTermView.searchTerm"]},${type} Keywords,${
            element["adGroup.name"]
          },${element["cluster_name"]},${
            element["segments.keyword.info.matchType"]
          },-,-,-,${element["metrics.impressions"]},${formatMetricValue({
            key: "metrics.averageCpc",
            value: element["metrics.averageCpc"],
            currencySymbol: "",
          })},${element["metrics.costPerConversion"]}`
        );
      }
      return prev;
    }, []);
  };

  const downloadFile = ({
    data,
    fileName,
    fileType,
  }: {
    data: any;
    fileName: string;
    fileType: string;
  }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e: any) => {
    e.preventDefault();
    let headers = [
      "Keyword name,Keyword category,Ad group name,Cluster name,Match type,Avg monthly search,Three month change,Competition index,Impressions,Avg CPC,Cost per conversion",
    ];
    const keywordData = csvDownload();
    const date = new Date();
    downloadFile({
      data: [...headers, ...keywordData].join("\n"),
      fileName: `Total Keywords_${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}.csv`,
      fileType: "text/csv",
    });
    TAIGSearchMixPanel(
      `TAI-Gsearch Export Clicked`,
      selectedBrand?.name,
      selectedAgency?.name,
      {
        module_name: "Google Search Playground",
        ad_account_id: aiInitiationData.adAccount.adAccountId,
        ai_group_id: aiInitiationData.aiGroup.id,
        ai_group_name: aiInitiationData.aiGroup.name,
        ad_account_name: aiInitiationData.adAccount.name,
        agency_id: selectedAgency.id,
        brand_id: selectedBrand.id,
        brand_type: selectedBrand.brandType,
      }
    );
  };

  return (
    <KeywordContext.Provider
      value={{
        keywords,
        setKeywords,
        starredItems,
        setStarredItems,
        setPositiveAdGroup,
        setPositiveCluster,
        setNegativeAdGroup,
        setNegativeCluster,
        setNewKwsAdGroup,
        setNewKwsCluster,
        selectedKeywordsBucket,
        setSelectedKeywordsBucket,
        getSelectedBucketData,
        isClusterView,
        setIsClusterView,
        adGroupDetails,
        clusterDetails,
        getLengthForBucket,
        getTotalLength,
        selectedFilters,
        setSelectedFilters,
        handleDeleteClick,
        handleDuplicateClick,
        changeSingleProperty,
        handleSelectKeywords,
        checkSelectAll,
        getBucketSelectdItemsLength,
        checkSelectAllBucket,
        handleSelectAllKeywords,
        checkDeselectAllBucket,
        handleDeselectAllKeywords,
        deleteBulkClick,
        saveBulkClick,
        duplicateBulkClick,
        changeBulkProperty,
        behaviourBulkClick,
        moveBulkClick,
        campaignDetails,
        isAnythingSelected,
        getDisplayObjectForPublish,
        getPublishPayload,
        filteredIds,
        filterText,
        setFilterText,
        onFilterChange,
        applyFilters,
        kSensitivity,
        handleSensitivityChange,
        positiveLoader,
        negativeLoader,
        newKwsLoader,
        getLoaderForBucket,
        selectedIds,
        exportToCsv,
        wastedSpends: Number(wastedSpends),
        appliedSelectedFilters,
        setAppliedSelectedFilters,
      }}
    >
      <KeywordExplorer data={data} isConnectable={isConnectable} />
    </KeywordContext.Provider>
  );
}
export default KeywordContextProvider;