import { Box } from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";
import MediaAdminRadioButton from "./MediaAdminRadioButton";

interface MediaAdminRadioButtonControllerProps {
  control: Control<FieldValues, object>;
  registeredName: string;
  required?: boolean;
}

export default function MediaAdminRadioButtonController({
  control,
  registeredName,
  required,
}: MediaAdminRadioButtonControllerProps) {
  return (
    <Controller
      control={control}
      name={registeredName}
      defaultValue="Partial Sentence"
      rules={{
        required,
      }}
      render={({
        field: { onChange, value },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <Box>
          <MediaAdminRadioButton onChange={onChange} value={value} />
        </Box>
      )}
    />
  );
}
