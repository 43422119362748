import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import StatusTag from "../../common/StatusTag";
import GreyLoader from "../../assets/gif/GreyLoader.gif";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";

const Item = styled(Paper)(() => ({
  backgroundColor: "#fff",
  width: "100%",
  textAlign: "left",
  color: "#464646",
  boxShadow: "none",
  margin: "2px",
  padding: "2px",
  fontSize: "12px",
  fontWeight: "500",
}));

function BrandRowLeft({ onClick, setEditBrandOpen, brandId }: any) {
  const brandState: any = useSelector((state: any) => state.smb.brand);

  let brandDetails: any, brandType: any;
  if (brandState.data && brandState.data[brandId]) {
    brandDetails = brandState.data[brandId]?.details;
    brandType = brandState.data[brandId]?.type;
  }
  return (
    <Stack
      sx={{
        minHeight: "12vh",
        maxHeight: "12vh",
        background: "#FFFFFF",
        borderTop: "1px solid #E7E7E7",
        borderBottom: "1px solid #E7E7E7",
        borderLeft: "1px solid #E7E7E7",
        boxShadow: "0px 3.71014px 16.0773px #F2F2F2",
        borderTopLeftRadius: "11px",
        borderBottomLeftRadius: "11px",
        alignItems: "center",

        // borderRight: "1px solid #E7E7E7",
      }}
      direction="row"
      onClick={onClick}
    >
      <Box sx={{ px: 1 }}>
        <Tooltip
          title={brandDetails.name}
          children={
            <Item
              sx={{
                width: "10vw",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "20px",
                color: "#333333",
                cursor: "pointer",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "block",
              }}
              onClick={onClick}
            >
              {brandDetails.name}
            </Item>
          }
        />

        <Item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {typeof brandType === "string" ? (
            <StatusTag type={brandType} />
          ) : (
            <Box sx={{ px: 3, width: "auto" }}>
              <img
                src={GreyLoader}
                alt="Loading"
                style={{ height: "2rem", width: "auto" }}
              />
            </Box>
          )}
          <Box
            style={{
              width: "1px",
              background: "#DDDDDD",
              margin: "5px",
            }}
          ></Box>
          <IconButton
            className="handle-click"
            style={{ padding: 0 }}
            onClick={() => setEditBrandOpen(brandDetails.id)}
            disableRipple
          >
            <EditIcon className="handle-click" />
          </IconButton>
        </Item>
      </Box>
      <Divider
        orientation="vertical"
        variant="middle"
        sx={{
          height: "70%",
          marginLeft: "auto",
          mr: 1,
        }}
      />
    </Stack>
  );
}

export default BrandRowLeft;
