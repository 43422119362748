import { Box, styled } from "@mui/material";

export const StyledBodyBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 0.5em;
  padding: 1em 1.5em;
  margin: 0.7em 1.4em 0.7em 0.7em;
  flex: 1;
  height: calc(100vh - 1.4em);
  min-width: 0;
`;
