import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledChoiceButtonBox = styled(Box)`
  box-sizing: border-box;
  background: #f4f9ff;
  border-radius: 8px;
  display: flex;
  padding: 6px 10px;
  align-items: center;
`;

export const StyledChoiceSelectionBox = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${({ selected }) => (selected ? "#0869fb" : "#000000")};
  background: ${({ selected }: { selected?: boolean }) =>
    selected ? "#ffffff" : ""};
  border: ${({ selected }) => (selected ? "1px solid #0869fb" : "none")};
  border-radius: ${({ selected }) => (selected ? "6px" : "0px")};
  padding: 6px 18px;
  box-sizing: border-box;
  cursor: pointer;
`;
