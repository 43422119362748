import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./EditBrand.scss";

import BasicModal from "Pages/SmbDashboard/common/Modal-material-ui";
import InputField from "Pages/SmbDashboard/common/InputField";
import SelectText from "Pages/SmbDashboard/common/SelectField";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { Grid, Stack, Typography, Button } from "@mui/material";
import { MONTHS, MONTHS_MAPPER } from "Dummy/JSON/ConstantData";
import { ReactComponent as Tick } from "./assets/tick.svg";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getEditBrandDetails,
  setEditBrandDetails,
} from "../../store/actions/SmbDashboard/brand";
import { getYearsArray } from "./utils/index";
import GreyLoader from "./assets/gif/GreyLoader.gif";
import { PARENT_PLATFORMS_MAPPER } from "./constants";
import SearchableSelectText from "./common/SearchableSelectField";
import { AdAccountState } from "store/types/adaccount";
import { mixPanelAdEvent } from "utils/mixpanel";

const Item = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textAlign: "left",
  color: "black",
  boxShadow: "none",
}));
const GridItem = styled(Paper)(() => ({
  padding: "0.5rem",
  textAlign: "left",
  color: "black",
  boxShadow: "none",
  width: "12rem",
  height: "3.5rem",
  border: "1px solid #DDDDDD",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
}));
const schema = yup
  .object({
    name: yup.string().required(),
    resultMetricName: yup.string().required(),
    budgets: yup.object(),
    adAccounts: yup
      .array()
      .of(
        yup.object().shape({
          adAccountId: yup.string().required(),
          selectedMetricId: yup.string(),
        })
      )
      .required()
      .min(1),
  })
  .required();
const disableEOnNumericInput =
  () => (event: React.KeyboardEvent<HTMLInputElement>) =>
    (event.key === "e" ||
      event.key === "+" ||
      event.key === "-" ||
      event.key === ".") &&
    event.preventDefault();

const checkNullOrUndefined = (value: any) => {
  if (typeof value === "undefined" || value === null) {
    return "";
  }
  return value;
};

function EditBudgetContainer({
  selectedMonth,
  selectedYear,
  getValue,
  adAccountsDetails,
  brandDetails,
  control,
  commonState,
}: any) {
  return (
    <Stack
      sx={{
        borderRadius: "0.5rem",
        background: "#F5F5F5",
        p: 2,
        mb: 6,
        "& *": {
          fontFamily: "Inter !important",
          fontWeight: 500,
        },
      }}
    >
      <Box
        sx={{
          fontSize: "1rem",
          fontWeight: "600 !important",
          color: "#444",
          mb: 2,
        }}
      >
        Brand Level
      </Box>
      <Grid container>
        <Grid
          item
          xs={2.5}
          sm={2.5}
          md={2.5}
          lg={2.5}
          xl={2.5}
          sx={{
            fontSize: "0.875rem",
          }}
        >
          Budget
        </Grid>
        <Grid
          item
          xs={2.5}
          sm={2.5}
          md={2.5}
          lg={2.5}
          xl={2.5}
          sx={{
            fontSize: "0.875rem",
          }}
        >
          Target Cost Per {getValue("resultMetricName")}
        </Grid>
        <Grid
          item
          xs={2.5}
          sm={2.5}
          md={2.5}
          lg={2.5}
          xl={2.5}
          sx={{
            fontSize: "0.875rem",
          }}
        >
          Target ROAS
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={2.5} pr={2}>
          <Controller
            name={"budgets"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                type="number"
                otherProps={{
                  startAdornment: (
                    <Typography paddingLeft="8px" fontSize="12px">
                      $
                    </Typography>
                  ),
                }}
                variant="outlined"
                sx={{
                  width: "12rem",
                  h: 5,
                  "& .MuiInputBase-input": {
                    padding: "11px 8px",
                  },
                }}
                size="small"
                placeholder="Please enter budget"
                value={checkNullOrUndefined(
                  value?.[selectedYear]?.[selectedMonth]?.budget
                )}
                setValue={(textValue: any) => {
                  let updatedValue = {
                    ...value,
                    [selectedYear]: {
                      ...value?.[selectedYear],
                      [selectedMonth]: {
                        ...value?.[selectedYear]?.[selectedMonth],
                        budget: textValue ? parseInt(textValue) : "",
                      },
                    },
                  };
                  onChange(updatedValue);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={2.5} pr={2}>
          <Controller
            name={"budgets"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                type="number"
                otherProps={{
                  startAdornment: (
                    <Typography paddingLeft="8px" fontSize="12px">
                      $
                    </Typography>
                  ),
                }}
                variant="outlined"
                sx={{
                  width: "12rem",
                  h: 5,
                  "& .MuiInputBase-input": {
                    padding: "11px 8px",
                  },
                }}
                size="small"
                placeholder={`Please enter target cost per ${getValue(
                  "resultMetricName"
                )}`}
                value={checkNullOrUndefined(
                  value?.[selectedYear]?.[selectedMonth]?.targetCostMetricBudget
                )}
                setValue={(textValue: any) => {
                  let updatedValue = {
                    ...value,
                    [selectedYear]: {
                      ...value?.[selectedYear],
                      [selectedMonth]: {
                        ...value?.[selectedYear]?.[selectedMonth],
                        targetCostMetricBudget: textValue
                          ? parseInt(textValue)
                          : "",
                      },
                    },
                  };
                  onChange(updatedValue);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={2.5} pr={2}>
          <Controller
            name={"budgets"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputField
                type="number"
                variant="outlined"
                sx={{
                  width: "12rem",
                  h: 5,
                  "& .MuiInputBase-input": {
                    padding: "11px 8px",
                  },
                }}
                size="small"
                placeholder="Please enter target ROAS"
                value={checkNullOrUndefined(
                  value?.[selectedYear]?.[selectedMonth]?.targetRoas
                )}
                setValue={(textValue: any) => {
                  let updatedValue = {
                    ...value,
                    [selectedYear]: {
                      ...value?.[selectedYear],
                      [selectedMonth]: {
                        ...value?.[selectedYear]?.[selectedMonth],
                        targetRoas: textValue ? parseInt(textValue) : "",
                      },
                    },
                  };
                  onChange(updatedValue);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          fontSize: "1rem",
          fontWeight: "600 !important",
          color: "#444",
          my: 2,
        }}
      >
        Ad Account Level
      </Box>
      {adAccountsDetails.map((item: any, index: any) => (
        <>
          <Grid container>
            <Grid
              item
              xs={2.5}
              sm={2.5}
              md={2.5}
              lg={2.5}
              xl={2.5}
              sx={{
                fontSize: "0.875rem",
              }}
            >
              Account
            </Grid>
            <Grid
              item
              xs={2.5}
              sm={2.5}
              md={2.5}
              lg={2.5}
              xl={2.5}
              sx={{
                fontSize: "0.875rem",
              }}
            >
              Budget
            </Grid>
            <Grid
              item
              xs={2.5}
              sm={2.5}
              md={2.5}
              lg={2.5}
              xl={2.5}
              sx={{
                fontSize: "0.875rem",
              }}
            >
              Target Cost Per {getValue("resultMetricName")}
            </Grid>
            <Grid
              item
              xs={2.5}
              sm={2.5}
              md={2.5}
              lg={2.5}
              xl={2.5}
              sx={{
                fontSize: "0.875rem",
              }}
            >
              Target ROAS
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "16px" }}>
            <GridItem>
              <Controller
                name={`adAccounts.${index}.adAccountId`}
                defaultValue={brandDetails.details.name || "-"}
                control={control}
                render={({ field: { value } }) => (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: "20%",
                        "& svg": {
                          height: "2rem",
                          width: "2rem",
                        },
                      }}
                    >
                      {
                        PARENT_PLATFORMS_MAPPER[
                          commonState.platforms.parentPlatforms[
                            getValue(`adAccounts.${index}.parentPlatform`)
                          ]
                        ]
                      }
                    </Box>
                    <Box
                      sx={{
                        flexDirection: "column",
                        fontSize: "12px",
                        ml: 1,
                        display: "flex",
                        width: "80%",
                      }}
                    >
                      <Typography
                        component={"div"}
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: "500",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                        }}
                        title={
                          (adAccountsDetails[index] &&
                            adAccountsDetails[index].adAccountName) ||
                          "-"
                        }
                      >
                        {(adAccountsDetails[index] &&
                          adAccountsDetails[index].adAccountName) ||
                          "-"}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "10px",
                          fontWeight: "300",
                        }}
                      >
                        {value}
                      </Typography>
                    </Box>
                  </Box>
                )}
              />
            </GridItem>
            <Grid
              ml="16px"
              item
              display="flex"
              alignItems="center"
              xs={2.5}
              sm={2.5}
              md={2.5}
              lg={2.5}
              xl={2.5}
              pr={2}
            >
              <Controller
                name={"adAccountBudgets"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    otherProps={{
                      startAdornment: (
                        <Typography paddingLeft="8px" fontSize="12px">
                          $
                        </Typography>
                      ),
                    }}
                    type="number"
                    variant="outlined"
                    sx={{
                      width: "12rem",
                      h: 5,
                      "& .MuiInputBase-input": {
                        padding: "11px 8px",
                      },
                    }}
                    placeholder="Please enter budget"
                    value={checkNullOrUndefined(
                      value?.[item.adAccountId]?.[selectedYear]?.[selectedMonth]
                        ?.budget
                    )}
                    setValue={(textValue: any) => {
                      let updatedValue = {
                        ...value,
                        [item.adAccountId]: {
                          ...value[item.adAccountId],
                          [selectedYear]: {
                            ...value?.[item.adAccountId]?.[selectedYear],
                            [selectedMonth]: {
                              ...value?.[item.adAccountId]?.[selectedYear]?.[
                                selectedMonth
                              ],
                              budget: textValue ? parseInt(textValue) : "",
                            },
                          },
                        },
                      };
                      onChange(updatedValue);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              display="flex"
              alignItems="center"
              xs={2.5}
              sm={2.5}
              md={2.5}
              lg={2.5}
              xl={2.5}
              pr={2}
            >
              <Controller
                name={"adAccountBudgets"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    type="number"
                    otherProps={{
                      startAdornment: (
                        <Typography paddingLeft="8px" fontSize="12px">
                          $
                        </Typography>
                      ),
                    }}
                    variant="outlined"
                    sx={{
                      width: "12rem",
                      h: 5,
                      "& .MuiInputBase-input": {
                        padding: "11px 8px",
                      },
                    }}
                    placeholder={`Please enter target cost per ${getValue(
                      "resultMetricName"
                    )}`}
                    value={checkNullOrUndefined(
                      value?.[item.adAccountId]?.[selectedYear]?.[selectedMonth]
                        ?.targetCostMetricBudget
                    )}
                    setValue={(textValue: any) => {
                      let updatedValue = {
                        ...value,
                        [item.adAccountId]: {
                          ...value[item.adAccountId],
                          [selectedYear]: {
                            ...value?.[item.adAccountId]?.[selectedYear],
                            [selectedMonth]: {
                              ...value?.[item.adAccountId]?.[selectedYear]?.[
                                selectedMonth
                              ],
                              targetCostMetricBudget: textValue
                                ? parseInt(textValue)
                                : "",
                            },
                          },
                        },
                      };
                      onChange(updatedValue);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              display="flex"
              alignItems="center"
              xs={2.5}
              sm={2.5}
              md={2.5}
              lg={2.5}
              xl={2.5}
              pr={2}
            >
              <Controller
                name={"adAccountBudgets"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    type="number"
                    variant="outlined"
                    sx={{
                      width: "12rem",
                      h: 5,
                      "& .MuiInputBase-input": {
                        padding: "11px 8px",
                      },
                    }}
                    placeholder="Please enter target ROAS"
                    value={checkNullOrUndefined(
                      value?.[item.adAccountId]?.[selectedYear]?.[selectedMonth]
                        ?.targetRoas
                    )}
                    setValue={(textValue: any) => {
                      let updatedValue = {
                        ...value,
                        [item.adAccountId]: {
                          ...value[item.adAccountId],
                          [selectedYear]: {
                            ...value?.[item.adAccountId]?.[selectedYear],
                            [selectedMonth]: {
                              ...value?.[item.adAccountId]?.[selectedYear]?.[
                                selectedMonth
                              ],
                              targetRoas: textValue ? parseInt(textValue) : "",
                            },
                          },
                        },
                      };
                      onChange(updatedValue);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      ))}
    </Stack>
  );
}

export default function EditBrand({ setOpen, open, setSuccess }: any) {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });
  const brandState: any = useSelector((state: any) => state.smb.brand);
  const agenciesState: any = useSelector((state: any) => state.smb.agencies);

  const onSubmit = (data: any) => {
    // const adAccounts = data.adAccounts.map(
    //   ({ adAccountId, selectedMetricId }: any) => ({
    //     adAccountId,
    //     selectedMetricId:
    //       typeof selectedMetricId === 'string'
    //         ? selectedMetricId
    //         : selectedMetricId?.id,
    //   })
    // );
    let budgets: any[] = [];
    Object.keys(data.budgets).forEach((year: any) => {
      Object.keys(data?.budgets?.[year]).forEach((month: any) => {
        if (data.budgets?.[year][month].budget === "") {
          delete data.budgets?.[year][month].budget;
        }
        if (data.budgets?.[year][month].targetCostMetricBudget === "") {
          delete data.budgets?.[year][month].targetCostMetricBudget;
        }
        if (data.budgets?.[year][month].targetRoas === "") {
          delete data.budgets?.[year][month].targetRoas;
        }
        budgets = [...budgets, { year, month, ...data.budgets?.[year][month] }];
      });
    });
    let adAccountsData = data.adAccounts.map((item: any) => {
      item.isAttribuationMetric = item.availableMetrics.find(
        (attr: any) => attr.id === item.selectedMetricId
      )?.isAttribuationMetric;
      let oldBudgetData = data.adAccountBudgets[item.adAccountId];
      let newBudgetData: any[] = [];
      Object.keys(oldBudgetData).forEach((year: any) => {
        Object.keys(oldBudgetData?.[year]).forEach((month: any) => {
          if (oldBudgetData?.[year][month].budget === "") {
            delete oldBudgetData?.[year][month].budget;
          }
          if (oldBudgetData?.[year][month].targetCostMetricBudget === "") {
            delete oldBudgetData?.[year][month].targetCostMetricBudget;
          }
          if (oldBudgetData?.[year][month].targetRoas === "") {
            delete oldBudgetData?.[year][month].targetRoas;
          }
          newBudgetData = [
            ...newBudgetData,
            { year, month, ...oldBudgetData?.[year][month] },
          ];
        });
      });
      return { ...item, budgets: newBudgetData };
    });

    mixPanelAdEvent("SMB Brand Details Submitted", {
      agency_name: agenciesState.selected.name,
      agency_id: agenciesState.selected.id,
      brand_id: brandDetails.details.id,
      brand_name: brandDetails.details.name,
    });

    dispatch(
      setEditBrandDetails(
        open,
        {
          name: data?.name || "",
          budgets,
          adAccounts: adAccountsData,
          resultMetricName: data.resultMetricName,
        },
        (res: any, err: boolean) => {
          if (!err) {
            setSuccess(true);
            setOpen(null);
          }
        }
      )
    );
  };
  const onError = (data: any) => {
    console.log("LL: onError -> data", data);
  };

  const adAccounts = useFieldArray({
    control,
    name: "adAccounts",
  });

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const commonState: any = useSelector((state: any) => state.smb.common);

  const brandDetails = brandState.data[open];
  const [adAccountsDetails, setAdAccountsDetails] = useState([]) as any;
  const [loading, setLoading] = useState(true) as any;
  const { brandSources } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getEditBrandDetails(open, (res: any, err: boolean) => {
        setValue(
          "budgets",
          res.data.budgets.reduce(
            (
              prev: any,
              { year, month, targetCostMetricBudget, budget, targetRoas }: any
            ) => {
              if (!prev[year]) {
                prev[year] = {};
              }
              if (!prev[year][month]) {
                prev[year] = {
                  ...prev[year],
                  [month]: {},
                };
              }
              prev[year][month] = {
                targetCostMetricBudget,
                budget,
                isSelected: isMarked(
                  year,
                  month,
                  targetCostMetricBudget,
                  budget,
                  targetRoas,
                  res.data.adAccounts
                ),
                targetRoas,
              };
              return prev;
            },
            {}
          )
        );
        const handleAdAccountBudget = () => {
          let data = {};
          for (let i = 0; i < res.data.adAccounts.length; i++) {
            let adBudValue = res.data.adAccounts[i].budgets.reduce(
              (
                prev: any,
                { year, month, targetCostMetricBudget, budget, targetRoas }: any
              ) => {
                if (!prev[year]) {
                  prev[year] = {};
                }
                if (!prev[year][month]) {
                  prev[year] = {
                    ...prev[year],
                    [month]: {},
                  };
                }
                prev[year][month] = {
                  targetCostMetricBudget,
                  budget,
                  targetRoas,
                };
                return prev;
              },
              {}
            );
            data = {
              ...data,
              [res.data.adAccounts[i].adAccountId]: adBudValue,
            };
          }
          return data;
        };
        setValue("adAccountBudgets", handleAdAccountBudget());

        setValue("resultMetricName", res.data.resultMetricName);
        setValue("adAccounts", res.data.adAccounts);
        setLoading(false);
        setAdAccountsDetails(res.data.adAccounts);
      })
    );
  }, []);

  return (
    <form
      className="smb-edit-brand"
      style={{ width: "100%", height: "100%" }}
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <BasicModal
        title={"Edit Brand"}
        setOpen={setOpen}
        open={!!open}
        buttonProps={{ type: "submit" }}
        additionalStyle={{ width: "70rem" }}
      >
        <Box style={{ fontSize: "14px", height: "100%", width: "100%" }}>
          <Stack spacing={1} sx={{ height: "100%", width: "100%" }}>
            <Item>
              <div className="d-flex">
                <Box sx={{ width: "50%", mr: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ mb: 1, fontFamily: "Inter" }}
                  >
                    Brand Name
                  </Typography>
                  <Controller
                    name={"name"}
                    defaultValue={brandDetails.details.name || "-"}
                    control={control}
                    render={({ field: { value } }) => (
                      <Box
                        sx={{
                          fontSize: "1rem",
                          color: "#333",
                          fontWeight: 600,
                          fontFamily: "Inter !Important",
                          pt: 1,
                        }}
                      >
                        {value}
                      </Box>
                    )}
                  />
                  <Box sx={{ fontSize: "0.75rem", color: "red" }}>
                    {errors?.name?.message}
                  </Box>
                </Box>
              </div>
            </Item>
            <hr style={{ border: "1px solid #F0F0F0" }} />
            <Item className="d-flex align-items-center justify-content-between">
              <Typography
                component={"p"}
                sx={{
                  fontWeight: "600",
                  mb: 0,
                  fontSize: "16px",
                }}
              >
                Result Metric
              </Typography>
            </Item>
            <hr style={{ border: "1px solid #F0F0F0" }} />
            <Item>
              <Box
                className="d-flex align-items-center"
                sx={{
                  mb: 1,
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                <Typography component="p" sx={{ m: 0, fontFamily: "Inter" }}>
                  Platform Wise Result Metric&nbsp;
                </Typography>
                <Typography
                  component={"small"}
                  sx={{
                    color: "#848484",
                    fontFamily: "Inter",
                    fontSize: "10px",
                  }}
                >
                  (Metric will be added for all platform)
                </Typography>
              </Box>
              {!adAccounts.fields ||
                (loading && (
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "10rem", width: "100%", display: "flex" }}
                  >
                    <img
                      src={GreyLoader}
                      alt="Loading"
                      style={{ height: "auto", width: "4rem" }}
                    />
                  </Box>
                ))}
              {adAccounts.fields.map((element: any, index) => (
                <Grid container key={element.id}>
                  <Grid item xs={2.5} md={2.5} lg={2.5} sx={{ mb: 1 }}>
                    <GridItem>
                      <Controller
                        name={`adAccounts.${index}.adAccountId`}
                        defaultValue={brandDetails.details.name || "-"}
                        control={control}
                        render={({ field: { value } }) => (
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                minWidth: "20%",
                                "& svg": {
                                  height: "2rem",
                                  width: "2rem",
                                },
                              }}
                            >
                              {
                                PARENT_PLATFORMS_MAPPER[
                                  commonState.platforms.parentPlatforms[
                                    getValues(
                                      `adAccounts.${index}.parentPlatform`
                                    )
                                  ]
                                ]
                              }
                            </Box>
                            <Box
                              sx={{
                                flexDirection: "column",
                                fontSize: "12px",
                                ml: 1,
                                display: "flex",
                                width: "80%",
                              }}
                            >
                              <Typography
                                component={"div"}
                                sx={{
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  overflow: "hidden",
                                  WebkitLineClamp: "1",
                                  WebkitBoxOrient: "vertical",
                                }}
                                title={
                                  (adAccountsDetails[index] &&
                                    adAccountsDetails[index].adAccountName) ||
                                  "-"
                                }
                              >
                                {(adAccountsDetails[index] &&
                                  adAccountsDetails[index].adAccountName) ||
                                  "-"}
                              </Typography>
                              <Typography
                                component={"span"}
                                sx={{
                                  fontFamily: "Inter",
                                  fontSize: "10px",
                                  fontWeight: "300",
                                }}
                              >
                                {value}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      />
                    </GridItem>
                  </Grid>
                  <Grid item xs={6} sx={{ mb: 1 }}>
                    <Controller
                      name={`adAccounts.${index}.selectedMetricId`}
                      defaultValue={
                        (adAccounts.fields[index] as any).availableMetrics
                          .length > 0
                          ? (adAccounts.fields[index] as any) &&
                            (adAccounts.fields[index] as any)
                              .availableMetrics[0]?.id
                          : undefined
                      }
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <SearchableSelectText
                          selectedItem={
                            typeof value === "string"
                              ? {
                                  ...getValues(
                                    `adAccounts.${index}.availableMetrics`
                                  ).find(
                                    (metric: any) =>
                                      metric.id ===
                                      getValues(
                                        `adAccounts.${index}.selectedMetricId`
                                      )
                                  ),
                                }
                              : value
                          }
                          setSelectedItem={(item: any) => {
                            onChange(item.id);
                          }}
                          sx={{
                            height: "100%",
                            width: "60%",
                          }}
                          size="small"
                          items={getValues(
                            `adAccounts.${index}.availableMetrics`
                          )}
                          valueKey="id"
                          displayKey="label"
                          searchingWidth="250px"
                          showIcon={true}
                          sources={brandSources}
                          className={"icon-based-data"}
                        />
                      )}
                    />
                  </Grid>
                  <Box sx={{ fontSize: "0.75rem", color: "red" }}>
                    {
                      (errors?.adAccounts as any)?.[index]?.selectedMetricId
                        ?.message
                    }
                  </Box>
                </Grid>
              ))}
              <Box sx={{ fontSize: "0.75rem", color: "red" }}>
                {errors?.adAccounts?.message}
              </Box>
            </Item>

            <Item sx={{ py: 0 }}>
              <Typography
                component={"p"}
                sx={{
                  mb: 1,
                  fontFamily: "Inter !important",
                  fontSize: "0.875rem",
                  color: "#333",
                  fontWeight: 500,
                }}
              >
                Aggregated Result Metric Name (Brand Level only)
              </Typography>
              <Controller
                name={"resultMetricName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    variant="outlined"
                    sx={{
                      width: "22rem",
                      h: 5,
                    }}
                    size="small"
                    placeholder="Please enter metric name"
                    value={value || ""}
                    setValue={onChange}
                  />
                )}
              />
              <Box sx={{ fontSize: "0.75rem", color: "red" }}>
                {errors?.resultMetricName?.message}
              </Box>
            </Item>
            <Item sx={{ mb: 0, py: 0 }}>
              <Typography
                component={"p"}
                sx={{
                  mb: 1,
                  fontFamily: "Inter !important",
                  fontSize: "0.875rem",
                  color: "#333",
                  fontWeight: 500,
                }}
              >
                Year & Month
              </Typography>
              <SelectText
                sx={{
                  width: "12%",
                  height: "2.7rem",
                }}
                size="small"
                items={getYearsArray()}
                selectedItem={selectedYear}
                setSelectedItem={(item: any) => {
                  setSelectedYear(item);
                }}
              />
            </Item>
            <Controller
              name={"budgets"}
              control={control}
              render={({ field: { value } }) => {
                return (
                  <Grid container sx={{ width: "100%", py: 2 }}>
                    {MONTHS.map((month) => {
                      const isPresent =
                        (value &&
                          value[selectedYear] &&
                          value[selectedYear][month.id] &&
                          value[selectedYear][month.id].isSelected) ||
                        false;
                      let color =
                        selectedMonth !== month.id
                          ? isPresent
                            ? "#1ABFA1"
                            : "#333"
                          : "#0869FB";

                      return (
                        <Grid item sm={1} key={month.id}>
                          <Button
                            sx={{
                              p: 0,
                              textTransform: "none",
                              "& *": {
                                fontFamily: "Inter !important",
                                fontSize: "12px",
                                color,
                              },
                              "&:hover": {
                                background: "white",
                              },
                            }}
                            variant={"text"}
                            onClick={() => setSelectedMonth(month.id)}
                            disableRipple={true}
                          >
                            <Box
                              sx={{
                                padding: "0px 8px",
                                height: "27px",
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                                borderBottom: `${
                                  selectedMonth === month.id
                                    ? isPresent
                                      ? "1px solid #1ABFA1"
                                      : "1px solid #0869FB"
                                    : ""
                                }`,
                              }}
                            >
                              {isPresent && (
                                <Tick style={{ marginRight: "0.125rem" }} />
                              )}
                              <Box>{month.name}</Box>
                            </Box>
                          </Button>
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              }}
            />
            <EditBudgetContainer
              adAccountsDetails={adAccountsDetails}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              getValue={getValues}
              brandDetails={brandDetails}
              control={control}
              commonState={commonState}
            />
            <Box
              sx={{
                fontFamily: "Inter !important",
                fontSize: "0.625rem",
                fontWeight: 500,
                color: "#333",
                pt: 2,
                pb: 3,
              }}
            >
              Note : Sum of all the budgets at brand level should summarise to
              the budgets at all its ad account level.
            </Box>
            {/* <Item sx={{ width: "100%", py: 0 }}>
              {budgets.fields.map((item, index) => (
                <Stack sx={{ mt: 2, color: "#444" }} key={item.id}>
                  <Stack
                    direction="row"
                    sx={{ width: "100%", mb: 1 }}
                    spacing={1}
                  >
                    <Typography
                      component={"p"}
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        width: "22%",
                      }}
                    >
                      Month
                    </Typography>
                    <Typography
                      component={"p"}
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        width: "22%",
                      }}
                    >
                      Year
                    </Typography>

                    <Typography
                      component={"p"}
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        width: "30%",
                      }}
                    >
                      Budget
                    </Typography>
                    <Typography
                      component={"p"}
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        width: "36%",
                      }}
                    >
                      Target Cost Per{" "}
                      <Typography
                        component={"em"}
                        sx={{
                          fontWeight: "400",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          textOverflow: "ellipsis",
                          width: "100%",
                        }}
                      >
                        {` ${watch("resultMetricName") || ""}`}
                      </Typography>
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{ width: "100%" }}
                    spacing={1}
                    key={item.id}
                  >
                    <Controller
                      name={`budgets.${index}.month`}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        const selectedMonthValue =
                          typeof value === "number"
                            ? { id: value, name: MONTHS_MAPPER[value] }
                            : value;
                        return (
                          <SelectText
                            sx={{
                              width: "22%",
                              height: "2.7rem",
                            }}
                            size="small"
                            items={MONTHS}
                            selectedItem={selectedMonthValue}
                            setSelectedItem={(item: any) => {
                              onChange(item.id);
                            }}
                            valueKey="id"
                            displayKey="name"
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`budgets.${index}.year`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <SelectText
                          sx={{
                            width: "22%",
                            height: "2.7rem",
                          }}
                          size="small"
                          items={getYearsArray()}
                          selectedItem={value}
                          setSelectedItem={onChange}
                        />
                      )}
                    />
                    <Box>
                      <Controller
                        name={`budgets.${index}.budget`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            variant="outlined"
                            sx={{
                              width: "100%",
                              height: "2.7rem",
                            }}
                            size="small"
                            value={value}
                            setValue={onChange}
                            type="number"
                            otherProps={{
                              onKeyDown: disableEOnNumericInput(),
                            }}
                          />
                        )}
                      />
                      <Box sx={{ fontSize: "0.75rem", color: "red" }}>
                        {(errors?.budgets as any)?.[index]?.budget?.message}
                      </Box>
                    </Box>
                    <Box>
                      <Controller
                        name={`budgets.${index}.targetCostMetricBudget`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            variant="outlined"
                            sx={{
                              width: "100%",
                              height: "2.7rem",
                            }}
                            size="small"
                            value={value}
                            setValue={onChange}
                            type="number"
                            otherProps={{
                              onKeyDown: disableEOnNumericInput(),
                            }}
                          />
                        )}
                      />
                      <Box sx={{ fontSize: "0.75rem", color: "red" }}>
                        {
                          (errors?.budgets as any)?.[index]
                            ?.targetCostMetricBudget?.message
                        }
                      </Box>
                    </Box>
                    <IconButton
                      className="d-flex align-items-center justify-content-center"
                      sx={{
                        height: "100%",
                        background: "#F8F8F8",
                        borderRadius: "6px",
                        p: 1.5,
                      }}
                      onClick={() => budgets.remove(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              ))}
              {!budgets.fields ||
                (loading && (
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "10rem", width: "100%", display: "flex" }}
                  >
                    <img
                      src={GreyLoader}
                      alt="Loading"
                      style={{ height: "auto", width: "4rem" }}
                    />
                  </Box>
                ))}
            </Item>
            <Box sx={{ fontSize: "0.75rem", color: "red" }}>
              {errors?.budgets?.message}
            </Box>
            <Button
              className="d-flex justify-content-start align-items-center"
              sx={{
                fontFamily: "Inter",
                width: "25%",
                ml: 1,
                "&:hover": { background: "none" },
              }}
              disableRipple
              variant="text"
              onClick={(e) => {
                e.preventDefault();
                appendMetric();
              }}
            >
              <Box
                className="text-white rounded-circle d-flex align-items-center justify-content-center"
                sx={{
                  width: "1.2rem",
                  height: "1.2rem",
                  background: "#0869FB",
                }}
              >
                +
              </Box>
              <Typography
                component={"p"}
                sx={{
                  color: "#0869FB",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  textTransform: "none",
                }}
              >
                &nbsp;Add Month
              </Typography>
            </Button> */}
          </Stack>
        </Box>
      </BasicModal>
    </form>
  );
}

const isMarked = (
  year: any,
  month: any,
  targetCostMetricBudget: any,
  budget: any,
  targetRoas: any,
  accountsData: any
) => {
  let isBrandTrue =
    budget ||
    budget == 0 ||
    targetRoas ||
    budget == 0 ||
    targetCostMetricBudget ||
    targetCostMetricBudget == 0;
  let isAdAccountTrue = false;
  if (accountsData) {
    for (let i = 0; i < accountsData.length; i++) {
      const accountDataOfSelectedMonth = accountsData[i].budgets?.find(
        (item: any) => {
          return item.year == year && item.month == month;
        }
      );
      if (
        accountDataOfSelectedMonth?.budget ||
        accountDataOfSelectedMonth?.budget == 0 ||
        accountDataOfSelectedMonth?.targetCostMetricBudget ||
        accountDataOfSelectedMonth?.targetCostMetricBudget == 0 ||
        accountDataOfSelectedMonth?.targetRoas ||
        accountDataOfSelectedMonth?.targetRoas == 0
      ) {
        isAdAccountTrue = true;
        break;
      }
    }
  }
  return isBrandTrue && isAdAccountTrue;
};
