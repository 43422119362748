export const GET_ACCOUNTS_STATS = 'GET_ACCOUNTS_STATS'
export const GET_STATS_ON_ADACCOUNT = 'GET_STATS_ON_ADACCOUNT'
export const DELETE_TOKEN = 'DELETE_TOKEN'
export const UPDATE_PRIMARY_TOKEN = 'UPDATE_PRIMARY_TOKEN'
export const GET_BRAND_DETAILS = 'GET_BRAND_DETAILS'
export const SET_TOKEN_STATS = 'SET_TOKEN_STATS'

export type AccountsManagerState = {
  isAppDisabled: boolean
  tokenStats: any
}

export type GetAccountsStats = {
  type: typeof GET_ACCOUNTS_STATS
  payload: {
    brandId: string
  }
  callback?: Function
}

export type GetStatsOnAdaccount = {
  type: typeof GET_STATS_ON_ADACCOUNT
  payload: {
    adaccountId: string
  }
  callback?: Function
}
export type DeleteToken = {
  type: typeof DELETE_TOKEN
  payload: {
    user: any
    tokenUID: any
    adAccountId: any
  }
  callback?: Function
}
export type UpdatePrimaryToken = {
  type: typeof UPDATE_PRIMARY_TOKEN
  payload: {
    user: any
    tokenUID: any
    adAccountId: any
    payload: any
  }
  callback?: Function
}

export type GetBrandDetails = {
  type: typeof GET_BRAND_DETAILS
  payload: {
    brandId: string
  }
  callback?: Function
}
