import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVariants,
  resetState,
  setCreateSpec,
  setVariants,
  setVariantsNewTag,
  updateAds,
  updateVariant,
} from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  getMediaStudioForAd,
  requestMediaStudioStatus,
} from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import {
  CreateVariantsState,
  SET_AD_PREVIEW_LINK,
  SET_FILTERED_VARIANTS_LOADING,
} from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { AdAccountState } from "store/types/adaccount";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { NavigationInitialState } from "store/types/Navigation/navigation.types";
import { InitialMediaStudioType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { initialState } from "store/types/auth";
import {
  setAutogenerateData,
  setUTGData,
} from "store/actions/CreativeAI_Revamp/facebook.actions";

export const useHomeRightPanel = () => {
  const dispatch = useDispatch();
  const {
    selectedAd,
    ads,
    fetchingVariants,
    filteredVariantList,
    createSpec,
    totalSelectedVariant,
    variantList,
    adsOtherState,
    autogenerate,
    utgData,
    filteredVariantLoading,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const { adsetDetails } = useSelector(
    (state: { caiFBReducer: any }) => state.caiFBReducer
  );

  const { adSetSelection, adAccountsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;
  const { socket } = useSelector(
    (state: { navigationReducer: NavigationInitialState }) =>
      state.navigationReducer
  );

  const { reqImageIds, reqVideoIds, frameVGSReqId } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );
  const auth = useSelector((state: { auth: initialState }) => state.auth);

  // local states

  const [socketData, setSocketData] = useState<any>(undefined);
  const [newVariants, setNewVariants] = useState<string[]>([]);
  useEffect(() => {
    if (selectedAd?.id) {
      let initState: any = {
        loadingRecommendation: true,
        isConfiguringMetaData: false,
        executionIdVariant: undefined,
        extraFields: {
          isVariantEditing: undefined,
          selectedVariantIndex: undefined,
          showEditor: undefined,
        },
        generateRecommendationError: false,
        generateRecommendationTimestamp: undefined,
        configureMetaData: undefined,
        recommendationMetaData: {},
        recommendationSelectionState: {
          primary_text: "",
          headline: "",
          description: "",
          adPreviewLink: "",
          variantName: "",
          isChanged: false,
          isConfigureChanged: false,
        },
      };
      dispatch(
        requestMediaStudioStatus({
          adId: selectedAd?.id,
          brandId: selectedBrand?.id,
        })
      );
      dispatch(
        getMediaStudioForAd({
          adId: selectedAd?.id,
          brandId: selectedBrand?.id,
        })
      );

      dispatch(resetState(initState));
      dispatch(setCreateSpec(selectedAd?.adcreatives?.data[0]));
      dispatch({
        type: SET_AD_PREVIEW_LINK,
        payload: selectedAd?.previews?.data[0]?.body,
      });
    }
  }, [selectedAd?.id]);

  useEffect(() => {
    if (selectedAd?.id) {
      dispatch({ type: SET_FILTERED_VARIANTS_LOADING, payload: true });
      const selectedAdIndex = ads.findIndex(
        (item: any) => item?.id == selectedAd?.id
      );
      if (ads[selectedAdIndex]?.variantList) {
        dispatch(setVariants({ data: ads[selectedAdIndex]?.variantList }));
      } else {
        dispatch(getVariants({ adId: selectedAd.id }));
      }
    }
    return () => {
      if (selectedAd?.id && autogenerate?.[selectedAd?.id]) {
        dispatch(
          setAutogenerateData({
            path: [selectedAd?.id, "status"],
            value: { isError: false },
          })
        );
      }
      if (selectedAd?.id && utgData?.[selectedAd?.id]) {
        dispatch(
          setUTGData({
            path: [selectedAd?.id, "status"],
            value: { isError: false },
          })
        );
      }
      if (selectedAd?.id) {
        dispatch(setVariantsNewTag({ adId: selectedAd?.id, isNew: false }));
      }
      setNewVariants([]);
    };
  }, [selectedAd]);

  useEffect(() => {
    if (ads) {
      const selectedAdIndex = ads.findIndex(
        (item: any) => item?.id == selectedAd?.id
      );
      const localAds = [...ads];
      localAds[selectedAdIndex] = { ...localAds[selectedAdIndex], variantList };
      dispatch(updateAds(localAds));
      let promises: any = [];
      variantList.forEach((variant: any) => {
        if (variant?.payload?.isNew) {
          if (newVariants && newVariants.includes(variant?.id) === false) {
            promises.push(
              new Promise(() =>
                dispatch(
                  updateVariant({
                    user: auth.user,
                    params: {
                      headline: variant?.CreativeAiData?.headline,
                      description: variant?.CreativeAiData?.description,
                      primaryText: variant?.CreativeAiData?.primaryText,
                      adId: selectedAd?.id,
                      mediaFileId: variant?.CreativeAiData?.mediaFileId,
                      payload: { ...variant?.payload, isNew: false },
                      performanceScore: variant?.performanceScore,
                    },
                    variantId: variant?.id,
                  })
                )
              )
            );
            setNewVariants((prev: any) => [...prev, variant?.id]);
          }
        }
      });
      Promise.all(promises);
    }
  }, [variantList]);

  useEffect(() => {
    if (socket) {
      // for vgs image and video
      socket.on("VGSImageVideoUrl", function (data) {
        setSocketData(data?.mediaStudioFiles);
      });
    } else {
      // here socket fallback case will come
    }

    // return () => {
    //   if (socket) {
    //     socket.off("VGSImageVideoUrl");
    //   }
    // };
  }, [socket]);

  useEffect(() => {
    if (socketData && socketData?.id === reqImageIds[0]) {
      handleVgsResponse({
        response: socketData,
        failureReduxType: "MEDIA_STUDIO_IMAGE_PROCESSING_FAILURE",
        reduxActionType: "MEDIA_STUDIO_IMAGE_PROCESSING_LOADER",
        storeReduxType: "GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE",
      });
    }
  }, [reqImageIds, socketData]);

  useEffect(() => {
    if (socketData && socketData?.id === reqVideoIds[0]) {
      handleVgsResponse({
        response: socketData,
        failureReduxType: "MEDIA_STUDIO_VIDEO_PROCESSING_FAILURE",
        reduxActionType: "MEDIA_STUDIO_VIDEO_PROCESSING_LOADER",
        storeReduxType: "GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO",
      });
    }
  }, [socketData, reqVideoIds]);

  useEffect(() => {
    if (socketData && socketData?.id === frameVGSReqId[0]) {
      handleVgsResponse({
        response: socketData,
        failureReduxType: "GET_FRAME_VGS_REQUEST_ID_FAILURE",
        reduxActionType: "GET_FRAME_VGS_REQUEST_ID_LOADER",
        storeReduxType: "SET_FRAME_THUMBNAIL_VGS_DATA_REDUX",
      });
    }
  }, [socketData, frameVGSReqId]);

  const handleVgsResponse = ({
    response,
    failureReduxType,
    reduxActionType,
    storeReduxType,
  }: {
    response: any;
    failureReduxType: string;
    reduxActionType: string;
    storeReduxType: string;
  }) => {
    if (response?.status === "FAILED") {
      dispatch({
        type: reduxActionType,
        payload: false,
      });
      dispatch({
        type: failureReduxType,
        payload: true,
      });
    } else {
      dispatch({
        type: storeReduxType,
        payload: response.length === 1 ? response[0] : response,
      });
      dispatch({
        type: reduxActionType,
        payload: false,
      });
      dispatch({
        type: failureReduxType,
        payload: false,
      });
    }
  };

  return {
    fetchingVariants,
    filteredVariantList,
    variantList,
    createSpec,
    selectedAd,
    totalSelectedVariant,
    ads,
    adSetSelection,
    adAccountsSelection,
    adsOtherState,
    filteredVariantLoading,
    adsetDetails,
  };
};
