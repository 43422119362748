import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTestMediaStudioProcessing,
  setTestMediaStudioTimer,
  testMediaStudioPostResponse,
} from "store/actions/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";

import {
  MediaStudioTestReducerInitialState,
  RunTestPostResponseType,
  TestMediaStudioResponseType,
} from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";

export const useMediaIdPolling = () => {
  const dispatch = useDispatch();
  const timerRef = useRef<any>(null);

  const { testMediaStudioTimer } = useSelector(
    (state: { mediaStudioTestReducer: MediaStudioTestReducerInitialState }) =>
      state.mediaStudioTestReducer
  );

  const onError = (runTestPostResponse: RunTestPostResponseType) => {
    const updateRunTestPostResponseData = runTestPostResponse.data.map(
      (item) => ({
        ...item,
        status: "FAILED",
      })
    );
    dispatch(
      testMediaStudioPostResponse({
        ...runTestPostResponse,
        data: updateRunTestPostResponseData,
        readyForPolling: false,
      })
    );
  };
  const getSortedData = (
    payload: string[],
    data: TestMediaStudioResponseType[]
  ) => {
    return payload.map(
      (str) =>
        data.find((obj) => obj.id == str) || {
          id: "",
          url: "",
          status: "FAILED",
        }
    );
  };
  const getVGSProcessingResponse = useCallback(
    (
      payload: string[],
      pollingExpireTime,
      runTestPostResponse: RunTestPostResponseType
    ) => {
      dispatch(
        getTestMediaStudioProcessing(
          payload,
          ({
            error,
            data,
          }: {
            error: Boolean;
            data: TestMediaStudioResponseType[];
          }) => {
            const statusInitiated = data.some(
              (item) => item?.status === "INITIATED"
            );

            if (error) {
              // will handle all error
              onError(runTestPostResponse);
            } else if (statusInitiated) {
              if (+new Date() - pollingExpireTime < 120000) {
                let timer = setTimeout(() => {
                  getVGSProcessingResponse(
                    payload,
                    pollingExpireTime,
                    runTestPostResponse
                  );
                }, 8000);
                dispatch(
                  testMediaStudioPostResponse({
                    ...runTestPostResponse,
                    data: getSortedData(payload, data),
                    readyForPolling: false,
                  })
                );
                dispatch(setTestMediaStudioTimer(timer));
              } else {
                dispatch(setTestMediaStudioTimer(true));
                const updateRunTestPostResponseData = data.map(
                  (item, index) => ({
                    ...item,
                    status: data[index]?.url ? undefined : "FAILED",
                  })
                );
                dispatch(
                  testMediaStudioPostResponse({
                    ...runTestPostResponse,
                    data: getSortedData(payload, updateRunTestPostResponseData),
                    readyForPolling: false,
                  })
                );
              }
            } else {
              // logic for handling  data will come here

              dispatch(
                testMediaStudioPostResponse({
                  ...runTestPostResponse,
                  data: getSortedData(payload, data),
                  readyForPolling: false,
                })
              );
            }
          }
        )
      );
    },
    []
  );

  useEffect(() => {
    timerRef.current = testMediaStudioTimer;
  }, [testMediaStudioTimer]);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        dispatch(setTestMediaStudioTimer(null));
      }
    },
    []
  );

  return { getVGSProcessingResponse };
};
