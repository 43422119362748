import {
  SET_SOCKET_INSTANCE,
  SetSocketInstance,
  SetSocketInstancePayload,
} from "store/types/Navigation/navigation.types";

export const setSocketInstance = (
  payload: SetSocketInstancePayload
): SetSocketInstance => ({
  type: SET_SOCKET_INSTANCE,
  payload,
});
