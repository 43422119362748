import { Socket } from "socket.io-client";

export const SET_SOCKET_INSTANCE = "SET_SOCKET_INSTANCE";

export interface SetSocketInstance {
  type: typeof SET_SOCKET_INSTANCE;
  payload: SetSocketInstancePayload;
}

export interface SetSocketInstancePayload {
  socket?: Socket;
}

export interface NavigationInitialState {
  socket?: Socket;
}
