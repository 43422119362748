import {
  GET_ADACCOUNTS_LOG,
  GET_ADSET_LOG,
  GET_ADS_LOG,
  GET_FB_ACTIVITY_LOGS,
  SET_ACTIVITY_LOG_ADS,
  SET_ACTIVITY_LOG_ADSETS,
  SET_ACTIVITY_LOG_AD_ACCOUNTS,
  SET_ADACCOUNTS_LOG,
  SET_ADSET_LOGS,
  SET_ADS_LOGS,
  SET_FB_ACTIVITY_LOGS,
} from "store/types/CreativeAI_Revamp/LogScreen/logscreen.types";

const initialState = {
  activityLogsList: {
    isFailed: false,
    isLoading: true,
    data: [],
  },
  adsSelection: {
    selection: [],
    selectedAds: [],
  },
  adSetSelection: {
    selection: [],
    selectedAdSets: [],
  },
  adAccountListLog: {
    isFailed: false,
    isLoading: true,
    data: [],
  },
  adSetListLog: {
    isFailed: false,
    isLoading: true,
    data: [],
  },
  adsListLog: {
    isFailed: false,
    isLoading: true,
    data: [],
  },
};

export const fbActivityLogReducer = (
  state = initialState,
  { type, payload }: any
) => {
  switch (type) {
    case GET_FB_ACTIVITY_LOGS: {
      if (!payload?.params?.type) {
        return {
          ...state,
          activityLogsList: {
            isFailed: false,
            isLoading: true,
            data: [],
          },
        };
      } else {
        return { ...state };
      }
    }

    /// please keep it as it

    // case GET_AD_ACCOUNTS_FB: {
    //   return {
    //     ...state,

    //     adSetListLog: {
    //       isFailed: false,
    //       isLoading: true,
    //       data: [],
    //     },
    //     adsListLog: {
    //       isFailed: false,
    //       isLoading: true,
    //       data: [],
    //     },

    //     adsSelection: {
    //       selection: [],
    //       selectedAds: [],
    //     },
    //     adSetSelection: {
    //       selection: [],
    //       selectedAdSets: [],
    //     },
    //   };
    // }

    // case GET_ADSETS_FB: {
    //   return {
    //     ...state,
    //     adsListLog: {
    //       isFailed: false,
    //       isLoading: true,
    //       data: [],
    //     },

    //     adsSelection: {
    //       selection: [],
    //       selectedAds: [],
    //     },
    //   };
    // }

    case SET_FB_ACTIVITY_LOGS:
      return {
        ...state,
        activityLogsList: {
          ...payload,
        },
      };

    case SET_ACTIVITY_LOG_ADS:
      return { ...state, adsSelection: payload };

    case SET_ACTIVITY_LOG_ADSETS:
      return { ...state, adSetSelection: payload };

    case SET_ACTIVITY_LOG_AD_ACCOUNTS:
      return { ...state, adAccountsSelection: payload };

    case GET_ADACCOUNTS_LOG:
      return {
        ...state,
        adAccountListLog: { ...state.adAccountListLog, isLoading: true },
        adSetListLog: {
          isFailed: false,
          isLoading: true,
          data: [],
        },
        adsListLog: {
          isFailed: false,
          isLoading: true,
          data: [],
        },
      };

    case SET_ADACCOUNTS_LOG:
      return {
        ...state,
        adAccountListLog: { ...payload },
      };

    case GET_ADSET_LOG:
      return {
        ...state,
        adSetListLog: { ...state.adSetListLog, isLoading: true },
      };

    case SET_ADSET_LOGS:
      return {
        ...state,
        adSetListLog: { ...payload },
      };

    case GET_ADS_LOG:
      return {
        ...state,
        adsListLog: { ...state.adsListLog, isLoading: true },
      };

    case SET_ADS_LOGS:
      return {
        ...state,
        adsListLog: { ...payload },
      };

    default:
      return state;
  }
};
