import { StyledImageContainer } from "../../styled";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import Loading from "../Loading/Loading";

export default function ImageComponent({
  src,
  isLoading,
  isFailed,
}: {
  src?: string;
  isLoading?: boolean;
  isFailed?: boolean;
}) {
  return (
    <StyledImageContainer>
      {isFailed ? (
        <ErrorComponent />
      ) : isLoading || !src ? (
        <Loading />
      ) : (
        <img
          src={src}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          alt="test-images"
        />
      )}
    </StyledImageContainer>
  );
}
