import { Box, Button, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import { DateRangePicker as DateRangePickerUI } from "react-date-range";
import Calendar from "../../assets/Calendar.svg";
import DownFill from "../../assets/DownFill.svg";

interface Props {
  heading?: string;
  subHeading?: string;
  onChange: (args: any) => void;
  startDate: Date;
  endDate: Date;
}

const DateRangePicker: React.FC<Props> = ({
  heading,
  subHeading,
  onChange: onSelectClick,
  startDate,
  endDate,
}): React.ReactElement => {
  const [datePickerEl, setDatePickerEl] = useState(null) as any;
  const formatTimeForShow = (date: Date) => {
    let formatedDate =
      date.getDate() +
      " " +
      date.toLocaleDateString("en-us", { year: "numeric", month: "short" });
    return formatedDate;
  };

  const handleDatePickerClick = (event: any) => {
    setDatePickerEl(event.currentTarget);
  };

  const [date, updateDate] = useState([
    {
      startDate,
      endDate,
      key: "date_range",
    },
  ]);

  const datePickerOpen = Boolean(datePickerEl);
  const datePickerId = datePickerOpen ? "filter-simple-popover" : undefined;
  return (
    <Box className="font-inter" key={heading}>
      {heading && (
        <Typography sx={{ color: "#000" }} className="font-10 font-500">
          {heading}
        </Typography>
      )}
      {subHeading && (
        <Typography sx={{ color: "#999" }} className="font-8 font-500">
          {subHeading}
        </Typography>
      )}
      <Box
        onClick={(e) => {
          handleDatePickerClick(e);
        }}
        sx={{ my: 1 }}
      >
        <Box
          className="d-flex"
          sx={{
            border: "1px solid #F0F0F0",
            p: 1,
            py: 0.5,
            borderRadius: "0.5rem",
          }}
        >
          <Box className="d-flex align-items-center w-100">
            <img
              src={Calendar}
              alt="Calendar"
              style={{
                height: "1rem",
                width: "1rem",
                marginRight: "0.25rem",
              }}
            />
            <Typography sx={{ color: "#999999" }} className="font-10 font-400">
              {`${formatTimeForShow(startDate)} - ${formatTimeForShow(
                endDate
              )} `}
            </Typography>
          </Box>
          <Box sx={{ mr: 1 }}>
            <img
              src={DownFill}
              style={{
                height: "0.625rem",
                width: "0.625rem",
              }}
              alt="down-icon"
            />
          </Box>
        </Box>
      </Box>
      {datePickerOpen && (
        <Popover
          sx={{ top: "12px" }}
          id={datePickerId}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={datePickerOpen}
          anchorEl={datePickerEl}
          onClose={() => setDatePickerEl(null)}
        >
          <DateRangePickerUI
            onChange={(val: any) => {
              const endDate=new Date(val.date_range.endDate)
              endDate.setHours(23,59)
              updateDate([
                {
                  startDate: new Date(val.date_range.startDate),
                  endDate,
                  key: "date_range",
                },
              ]);
            }}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={date}
            direction="horizontal"
          />
          <Box
            className="d-flex align-items-center justify-content-end w-100"
            sx={{ p: 2 }}
          >
            <Button
              onClick={() => {
                onSelectClick(date);
                setDatePickerEl(null);
              }}
              variant="contained"
            >
              Select
            </Button>
          </Box>
        </Popover>
      )}
    </Box>
  );
};

export default DateRangePicker;
