import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import {
  getPublishedClusterDetails,
  setClusterDetails,
  setClusterTable,
  setEditCluster,
  setTargetKeyword,
} from "../../../store/actions/cluster";
import { initialState } from "../../../store/types/auth";
import TableElement from "../../../ui/Table/TableElement";

interface AdTableElementProps {
  cluster: any;
  targetKeyword: any;
}
export default function AdTableElement({
  cluster,
  targetKeyword,
}: AdTableElementProps) {
  const [loaderEditButton, setLoaderEditButton] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state: { auth: initialState }) => state.auth);

  const reformatClusterData = (data: any) => {
    return {
      cluster_label: data.clusterLabel,
      cluster_type: data.clusterType,
      cluster_sv: data.clusterSv,
      cluster_id: data.id,
      cluster_keywords: data.clusterKeywords,
      keywords: data.Keyword,
      ad_account_id: data.adAccountId,
      ad: {
        type: "RESPONSIVE_SEARCH_AD",
        responsiveSearchAd: {
          headlines: data.Headlines,
          descriptions: data.Descriptions,
        },
        finalUrls: data.finalUrls,
        name: data.name,
        creative_replacement: data.creativeReplacement,
        headlineIntervals: data.headlineIntervals,
        descriptionIntervals: data.descriptionIntervals,
      },
      isCreate: false,
      ref_ad_group: data.refAdGroup,
      ref_ad_id: data.refAdId,
      ref_campaign: data.refCampaign,
      ref_ad_group_id: data.refAdGroupId,
      ad_group_name: data.adGroupName,
      campaign_name: data.campaignName,
      ai_group: data.aiGroup,
      elementId: data.elementId,
      selectedReferenceDescription:
        parseInt(data.selectedReferenceDescription) ?? 0,
      selectedReferenceHeadline: parseInt(data.selectedReferenceHeadline) ?? 0,
      selectedGenerationType:
        data.selectedGenerationType ?? targetKeyword.generationType,
    };
  };

  return (
    <TableElement type="td">
      <div>
        <div className="table-headline text-truncate">
          {cluster?.Headlines.map((h: any) => h.headline).join(" | ")}
        </div>
        <div className="table-desc text-truncate">
          {cluster?.Descriptions.map((h: any) => h.description).join(" | ")}
        </div>
      </div>
      {cluster?.Headlines && (
        <Link
          to={""}
          onClick={(e) => {
            setLoaderEditButton(true);
            e.preventDefault();
            dispatch(setEditCluster({ ...{ ext_id: cluster.id } }));
            dispatch(
              setClusterDetails({ url: "", group_id: cluster.group_id })
            );
            dispatch(
              getPublishedClusterDetails(
                { id: cluster.id, user: auth?.user },
                (response: any, error: boolean) => {
                  if (response && response.data && !error) {
                    const clusterTableData: any = reformatClusterData(
                      response.data
                    );
                    dispatch(
                      setTargetKeyword({
                        ...targetKeyword,
                        title: response.data.clusterLabel,
                        activeIndex: 0,
                        keywords: response.data.clusterKeywords?.join(" , "),
                        selectedReferenceDescription:
                          parseInt(
                            response.data.selectedReferenceDescription
                          ) ?? 0,
                        selectedReferenceHeadline:
                          parseInt(response.data.selectedReferenceHeadline) ??
                          0,
                        generationType:
                          response.data?.selectedGenerationType ??
                          targetKeyword.generationType,
                      })
                    );
                    dispatch(setClusterTable([clusterTableData]));
                    history.push(
                      `/googlesearchai/create-ad-and-publish${history.location.search}`
                    );
                  } else {
                    //need to handle error here
                  }
                  setLoaderEditButton(false);
                }
              )
            );
          }}
        >
          {loaderEditButton ? (
            <Loader height="30px" width="30px" size="sm" />
          ) : (
            "Edit"
          )}
        </Link>
      )}
    </TableElement>
  );
}
