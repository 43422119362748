import {
  SET_CREATIVE_ROTATION_LOGS,
  SET_CREATIVE_VARIANTS_LOGS,
  SET_USER_INFO,
} from "../../../../types/CreativeAI/Facebook/CreateVariant/createVariantType";

const initialState = { userInfo: {} };

const userInfoReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_INFO: {
      return {
        ...state,
        userInfo: action.payload,
      };
    }

    default:
      return state;
  }
};
export default userInfoReducer;
