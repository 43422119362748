import { usePostResponseHook } from "components/CreativeAI/Facebook/Review/hooks/postHook";
import { Comment } from "components/shared";
import React from "react";
import { useDispatch } from "react-redux";
import { updateComments } from "store/actions/CreativeAI/shared/Review/review";
import { CommentKey } from "store/types/CreativeAI/shared/Review/review";
import { StyledPreviewIndex } from "../PreviewSection/PreviewDetails/styles";
import {
  StyledCommentContainer,
  StyledReview,
  StyledReviewContainer,
  StyledReviewDetails,
  StyledReviewHeader,
  StyledReviewSection,
} from "./styles";

const ReviewSection: React.FC<{
  title: string;
  data: any;
  commentKey: CommentKey;
  comment: any;
}> = ({ title, data, commentKey, comment }): JSX.Element => {
  const dispatch = useDispatch();
  const { updatePostComments } = usePostResponseHook();
  return (
    <StyledReviewSection>
      <StyledReviewHeader>{title}</StyledReviewHeader>
      <StyledReviewContainer>
        <StyledReviewDetails>
          {data.map((d: any, index: number) => (
            <StyledReview>
              <StyledPreviewIndex>
                {`${title.charAt(0).toUpperCase()}${index + 1} : ${d}`}
              </StyledPreviewIndex>
            </StyledReview>
          ))}
        </StyledReviewDetails>
        <StyledCommentContainer>
          <Comment
            onBlurFunction={(value) => {
              if (value !== comment) {
                dispatch(
                  updateComments({
                    key: commentKey,
                    payloadData: {
                      text: value,
                      isLoading: true,
                    },
                  })
                );
                updatePostComments({
                  commentKey,
                  value,
                });
              }
            }}
            localValue={comment?.text ?? ""}
            loading={comment?.isLoading}
          />
        </StyledCommentContainer>
      </StyledReviewContainer>
    </StyledReviewSection>
  );
};

export default ReviewSection;
