import styled from "styled-components";

export const StyledFlowTitle = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  padding-top: 16px;
`;

export const StyledFlowSubTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #727272;
  padding-top: 8px;
`;

export const StyledFlowSubTitle2 = styled.div`
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #444444;
`;
