import { FC, useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SearchComponent from "./SearchComponent";
import {
  FormControl,
  SelectChangeEvent,
  SxProps,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Box } from "@mui/system";
import InfoPopup from "components/AIGroupSettings/AIGroupSettingForm/InfoPopup";

const SearchableSelectText: FC<{
  items: any[];
  selectedItem: any;
  setSelectedItem: any;
  size?: "small" | "medium" | undefined;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  valueKey?: string;
  displayKey?: string;
  menuProps?: any;
  IconComponent?: any;
  searchingWidth?: string;
  showIcon?: boolean;
  sources?: any;
  className?: string;
}> = ({
  items,
  selectedItem,
  setSelectedItem,
  size,
  sx,
  valueKey,
  displayKey,
  disabled = false,
  menuProps,
  IconComponent,
  searchingWidth,
  showIcon = false,
  sources,
  className,
}) => {
  const [selectedItemDD, setSelectedItemDD] = useState(selectedItem || "");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedItems, setSearchedItems] = useState(items);

  const handleSearch = (searchTerm: any) => {
    if (searchTerm)
      setSearchedItems([
        ...items.filter((item) =>
          item?.[displayKey || ""]
            ? item?.[displayKey || ""]
                .toString()
                .toLowerCase()
                .includes(searchTerm.toString().toLowerCase())
            : item
                .toString()
                .toLowerCase()
                .includes(searchTerm.toString().toLowerCase())
        ),
      ]);
    else setSearchedItems(items);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const changedItem = valueKey
      ? items.find((item) => item[valueKey] === event.target.value)
      : event.target.value;
    setSelectedItemDD(changedItem);
    setSelectedItem(changedItem);
  };

  useEffect(() => {
    let selectedValue;
    if (valueKey && selectedItem) {
      selectedValue =
        selectedItem && selectedItem[valueKey]
          ? selectedItem[valueKey]
          : items.length > 0
          ? items[0][valueKey]
          : "";
      setSelectedItemDD(selectedValue);
    } else {
      selectedValue = selectedItem || (items.length > 0 ? items[0] : "");
      setSelectedItemDD(selectedValue);
    }
  }, [selectedItem]);

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);

  return (
    <FormControl fullWidth>
      <Select
        MenuProps={{ ...menuProps, autoFocus: false }}
        defaultValue={selectedItemDD}
        value={selectedItemDD}
        onChange={(e) => handleChange(e)}
        onClose={() => setSearchTerm("")}
        size={size}
        disabled={disabled || items.length === 0}
        IconComponent={IconComponent}
        renderValue={(value) =>
          valueKey
            ? items.find((item) => item[valueKey] === value)?.[displayKey || ""]
            : value
        }
        sx={{
          cursor: "pointer",
          border: "1px solid #DDD !important",
          borderRadius: "8px !important",
          "& legend": { display: "none" },
          "& fieldset": { top: 0, border: "none" },
          "& .MuiSelect-select": {
            fontSize: "0.75rem !important",
            fontFamily: "Inter !important",
            fontWeight: "400 !important",
            border: "none !important",
          },
          "& input": {
            border: "none !important",
            fontSize: "0.75rem",
            fontFamily: "Inter",
            fontWeight: 400,
          },
          "& ul": {
            left: "0px",
          },
          "& svg": {
            color: "#000",
          },
          "& p": {
            fontSize: "0.75rem",
            fontFamily: "Inter",
            fontWeight: 400,
          },
          ...sx,
        }}
        className={`select-options ` + className}
        autoFocus={false}
      >
        <Box
          marginBottom="10px"
          onKeyDown={(e) => e.stopPropagation()}
          onKeyUp={(e) => e.stopPropagation()}
        >
          <SearchComponent
            placeholder="Search"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            width={searchingWidth}
          />
        </Box>
        {searchedItems.map((item) => (
          <MenuItem
            title={item?.name?.length >= 15 && item?.name}
            key={valueKey ? item[valueKey] : item}
            sx={{
              "& p": {
                fontSize: "0.75rem",
                fontFamily: "Inter",
                fontWeight: 400,
                display: "flex",
                justifyContent: "space-between",
              },
            }}
            autoFocus={false}
            value={valueKey ? item[valueKey] : item}
          >
            <Typography
              noWrap={true}
              title={displayKey ? item[displayKey] : item}
            >
              {displayKey ? item[displayKey] : item}
              {showIcon && item?.isAttribuationMetric ? (
                <InfoPopup
                  source={sources.find(
                    (each: any) => each.id === item?.sourceId
                  )}
                />
              ) : (
                ""
              )}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SearchableSelectText;
