import { Box } from "@mui/material";
import styled from "styled-components";

export const StyledLogScreenBox = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 3.25em);
`;

export const StyledLogScreenFilterBox = styled(Box)`
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: 0em 0em 0.625em 0.3125em rgba(0, 0, 0, 0.02);
  border-radius: 0.5em;
  margin-bottom: 1.375em;
  height: 4.5em;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.1875em;
`;

export const StyledLogScreenBodyBox = styled(Box)`
  display: flex;
  flex: 1;
  min-height: 0;
  justify-content: space-between;
`;

export const StyledLogScreenTable = styled(Box)`
  height: 100%;
  background: #ffffff;
  box-shadow: 0em 0em 0.625em 0.3125em rgba(0, 0, 0, 0.02);
  border-radius: 0.5em;
  margin-right: 1.25em;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledTableTitleBar = styled(Box)`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.0625em solid #f0f0f0;
  color: #333333;
`;

export const StyledTableTitleBox = styled.span`
  display: flex;
  align-items: center;
`;
