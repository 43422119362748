import { useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ClickAwayListener, tooltipClasses, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import OptimizerDetails from "../../common/OptimizerDetails";
import GreyLoader from "../../assets/gif/GreyLoader.gif";
import { ReactComponent as TAI } from "../../assets/TAI.svg";
import { ReactComponent as CAI } from "../../assets/CAI.svg";
import { ReactComponent as ViewIcon } from "../../assets/viewIcon.svg";
import { PARENT_PLATFORMS_MAPPER } from "../../constants";
import {
  getActualCostDeviationColor,
  getActualSpendsAndBudgetDeviationColor,
  getDeviationArrow,
  getCurrency,
} from "../../utils/index";
import { getRoundedValue } from "utils/commonFunctions";

const Item = styled(Paper)(() => ({
  backgroundColor: "#fff",
  width: "100%",
  textAlign: "left",
  color: "#464646",
  boxShadow: "none",
  margin: "2px",
  padding: "2px",
  fontSize: "12px",
  fontWeight: "500",
}));

const DaviationItem = styled(Box)(() => ({
  backgroundColor: "#fff",
  textAlign: "left",
  color: "#464646",
  boxShadow: "none",
  margin: "2px",
  padding: "2px",
  fontSize: "12px",
  fontWeight: "500",
}));

const Loader = () => (
  <Item sx={{ px: 2.5 }}>
    <img
      src={GreyLoader}
      alt="Loading"
      style={{ height: "4rem", width: "auto" }}
    />
  </Item>
);
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "10px",
    fontWeight: 500,
    border: "0.753125px solid #F0F0F0",
    boxShadow: "0px 7.53125px 18.075px rgba(35, 40, 60, 0.05)",
    padding: "8px",
  },
}));

function BrandRowRight({
  brandId,
  onClick,
  showOptimizer,
  setShowOptimizer,
  showPlatform,
  setShowPlatform,
}: any) {
  const brandState: any = useSelector((state: any) => state.smb.brand);
  const commonState = useSelector((state: any) => state.smb.common);

  const [showOptimizerId, setShowOptimizerId] = useState(-1);
  const [showPlatformId, setShowPlatformId] = useState(-1);

  let brandDetails: any, brandMetrics: any;
  if (brandState.data && brandState.data[brandId]) {
    brandDetails = brandState.data[brandId]?.details;
    brandMetrics = brandState.data[brandId]?.metrics;
  }

  const brandPlatforms: any = brandDetails && brandDetails.parentPlatforms;
  const platforms: any = commonState?.platforms;
  const activeOptimizersLength = brandDetails?.gaiSummary?.optimizers?.filter(
    (optimizer: any) => optimizer.gaiActive
  ).length;

  const openOptimizer = Boolean(showOptimizer);
  const optimizerViewId = openOptimizer ? "view-popover" : undefined;

  const openPlatform = Boolean(showPlatform);
  const platformId = openPlatform ? "platform-popover" : undefined;

  const handleViewClick = (event: any, id: number) => {
    setShowOptimizer(event.currentTarget);
    setShowOptimizerId(id);
  };

  const handleViewClose = (id: number) => {
    if (id === showOptimizerId) {
      setShowOptimizer(null);
      setShowOptimizerId(-1);
    }
  };

  const handlePlatformsClick = (target: any, id: number) => {
    setShowPlatform(target);
    setShowPlatformId(id);
  };

  const handlePlatformsClose = (id: number) => {
    if (id === showPlatformId) {
      setShowPlatform(null);
      setShowPlatformId(-1);
    }
  };

  const currency =
    brandMetrics && brandMetrics.currency
      ? getCurrency(brandMetrics.currency)
      : " ";
  return (
    <Stack
      direction="row"
      divider={
        <Divider
          orientation="vertical"
          flexItem
          style={{ height: "50px", alignSelf: "center" }}
        />
      }
      sx={{
        minHeight: "12vh",
        maxHeight: "12vh",
        background: "#FFFFFF",
        borderTop: "1px solid #E7E7E7",
        borderBottom: "1px solid #E7E7E7",
        borderRight: "1px solid #E7E7E7",
        boxShadow: "0px 3.71014px 16.0773px #F2F2F2",
        borderTopRightRadius: "11px",
        borderBottomRightRadius: "11px",
        alignItems: "center",
        px: 1,
        width: "fit-content",
      }}
      spacing={1}
      onClick={onClick}
    >
      <Stack sx={{ minWidth: "5vw", maxWidth: "5vw" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box style={{ display: "flex", width: "5rem" }}>
            {brandPlatforms.length === 0 && (
              <Item sx={{ dispaly: "flex", alignItems: "center" }}>-</Item>
            )}
            {brandPlatforms.length > 0 && brandPlatforms.length > 2 ? (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Item
                  sx={{
                    "& svg": {
                      height: "2rem",
                      width: "2rem",
                    },
                  }}
                >
                  {platforms &&
                    platforms.parentPlatforms &&
                    PARENT_PLATFORMS_MAPPER[
                      platforms.parentPlatforms[brandPlatforms[0]]
                    ]}
                </Item>
                <Button
                  variant="text"
                  className="handle-click"
                  aria-describedby={platformId}
                  onClick={(event: any) =>
                    handlePlatformsClick(event.currentTarget, brandId)
                  }
                  style={{
                    minWidth: "1.5rem",
                    margin: 0,
                    padding: "3px",
                    background: "#E9F2FF",
                    color: "#0869FB",
                    fontWeight: "600",
                  }}
                >
                  +{brandPlatforms.length - 1}
                </Button>
                <Popper
                  id={platformId}
                  open={openPlatform && showPlatformId === brandId}
                  anchorEl={showPlatform}
                  placement="bottom-start"
                >
                  <ClickAwayListener
                    onClickAway={() => handlePlatformsClose(brandId)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        m: 1,
                        background: "#FFFFFF",
                        boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.14)",
                      }}
                    >
                      {brandPlatforms
                        .slice(1)
                        .map((platform: any, index: number) => (
                          <Item
                            key={index}
                            sx={{
                              "& svg":
                                platform === "MICROSOFT" || platform === "DV360"
                                  ? {
                                      height: "1.5rem",
                                      width: "1.5rem",
                                    }
                                  : {
                                      height: "2rem",
                                      width: "2rem",
                                    },
                            }}
                          >
                            {platforms &&
                              platforms.parentPlatforms &&
                              PARENT_PLATFORMS_MAPPER[
                                platforms.parentPlatforms[platform]
                              ]}
                          </Item>
                        ))}
                    </Box>
                  </ClickAwayListener>
                </Popper>
              </Box>
            ) : (
              brandPlatforms.map((platform: any, index: number) => (
                <Item
                  key={index}
                  sx={{
                    "& svg": {
                      height: "2rem",
                      width: "2rem",
                    },
                  }}
                >
                  {platforms &&
                    platforms.parentPlatforms &&
                    PARENT_PLATFORMS_MAPPER[
                      platforms.parentPlatforms[platform]
                    ]}
                </Item>
              ))
            )}
          </Box>
          <Item>Platforms</Item>
        </Box>
      </Stack>
      <Stack sx={{ minWidth: "8vw", maxWidth: "8vw" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Item
              sx={{
                color: activeOptimizersLength > 0 ? "#24B632" : "#ED3900",
              }}
            >
              {activeOptimizersLength} /
              {brandDetails.gaiSummary.optimizers.length}
              <span style={{ fontSize: "0.625rem", marginLeft: "0.313rem" }}>
                (AI Groups)
              </span>
            </Item>
            <Grid container>
              <Grid item xs={3.5}>
                <Item>PAI</Item>
              </Grid>
              <Grid item>
                <IconButton
                  className="handle-click"
                  aria-describedby={optimizerViewId}
                  onClick={(event: any) => {
                    handleViewClick(event, brandDetails.id);
                  }}
                  sx={{ padding: 0, minWidth: 0 }}
                >
                  <ViewIcon className="handle-click" />
                </IconButton>
                <Popper
                  id={optimizerViewId}
                  open={openOptimizer && showOptimizerId === brandDetails.id}
                  anchorEl={showOptimizer}
                  placement="bottom-start"
                >
                  <ClickAwayListener
                    onClickAway={() => handleViewClose(brandDetails.id)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 1,
                        maxHeight: "30rem",
                        overflow: "auto",
                        background: "#FFFFFF",
                        boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.14)",
                      }}
                    >
                      {brandDetails.gaiSummary.optimizers.length > 0 ? (
                        brandDetails.gaiSummary.optimizers.map(
                          (optimizer: any) => (
                            <OptimizerDetails
                              key={optimizer.id}
                              optimizer={optimizer}
                            />
                          )
                        )
                      ) : (
                        <Box>No data found</Box>
                      )}
                    </Box>
                  </ClickAwayListener>
                </Popper>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Stack>
      <Stack
        sx={{
          minWidth: "7.5vw",
          maxWidth: "7.5vw",
          px: 1,
          py: 1,
          background: "#FAFAFA",
        }}
        direction={"row"}
        divider={
          <Divider
            orientation="vertical"
            flexItem
            style={{ height: "20px", alignSelf: "center" }}
          />
        }
      >
        <Stack sx={{ px: 1 }}>
          <Box
            sx={{
              fontSize: "12px",
              color: brandDetails.caiSummary.caiOnBoarding
                ? "#24B632"
                : "#ED3900",
              "& path": brandDetails.caiSummary.caiOnBoarding
                ? undefined
                : {
                    stroke: "#ED3900",
                  },
            }}
            className="handle-click"
          >
            <LightTooltip
              title={
                brandDetails.caiSummary.caiOnBoarding
                  ? "Configured"
                  : "Not Configured"
              }
              className="handle-click"
            >
              <CAI className="handle-click" />
            </LightTooltip>

            {/* {brandDetails.taiSummary.taiOnBoarding ? "Active" : "Inactive"} */}
          </Box>
          <Box
            sx={{
              fontSize: "12px",
            }}
            className="d-flex justify-content-center"
          >
            CAI
          </Box>
        </Stack>
        <Stack sx={{ px: 1, borderRadius: "4px" }}>
          <Box
            sx={{
              color: brandDetails.taiSummary.taiOnBoarding
                ? "#24B632"
                : "#ED3900",
              "& path": brandDetails.taiSummary.taiOnBoarding
                ? undefined
                : {
                    stroke: "#ED3900",
                  },
            }}
            className="handle-click"
          >
            <LightTooltip
              title={
                brandDetails.taiSummary.taiOnBoarding
                  ? "Configured"
                  : "Not Configured"
              }
              className="handle-click"
            >
              <TAI className="handle-click" />
            </LightTooltip>
          </Box>
          <Box
            className="d-flex justify-content-center"
            sx={{
              fontSize: "12px",
            }}
          >
            TAI
          </Box>
        </Stack>
      </Stack>
      {!brandMetrics?.roas || brandMetrics.isLoading ? (
        <Loader />
      ) : (
        <Stack
          sx={{
            minWidth: "5.5vw",
            maxWidth: "5.5vw",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Item>
            {brandMetrics?.roas?.target
              ? `${getRoundedValue(brandMetrics?.roas?.target)}`
              : "--"}
          </Item>
          <Item>Target ROAS</Item>
        </Stack>
      )}
      {!brandMetrics?.roas || brandMetrics.isLoading ? (
        <Loader />
      ) : (
        <Stack
          sx={{
            minWidth: "6vw",
            maxWidth: "6vw",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DaviationItem>
              {brandMetrics?.roas?.actual
                ? getRoundedValue(brandMetrics?.roas?.actual)
                : "0.00"}
            </DaviationItem>
            {brandMetrics.roas.deviation ? (
              <>
                {getDeviationArrow(brandMetrics.roas.deviation) === "up" ? (
                  <ArrowUpwardIcon
                    sx={{
                      fontSize: "1rem",
                      color: getActualSpendsAndBudgetDeviationColor(
                        brandMetrics.roas.deviation || 0
                      ),
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    sx={{
                      fontSize: "1rem",
                      color: getActualSpendsAndBudgetDeviationColor(
                        brandMetrics.roas.deviation || 0
                      ),
                    }}
                  />
                )}
                <Typography
                  fontSize="0.625rem"
                  sx={{
                    color: getActualSpendsAndBudgetDeviationColor(
                      brandMetrics.roas.deviation || 0 // ADD Deviation key here
                    ),
                  }}
                >
                  {/* ADD Deviation key here*/}
                  {getRoundedValue(brandMetrics.roas.deviation || 0)}%
                </Typography>
              </>
            ) : null}
          </Box>
          <Item>Actual ROAS</Item>
        </Stack>
      )}
      {!brandMetrics || brandMetrics.isLoading ? (
        <Loader />
      ) : (
        brandMetrics?.metrics?.map((metric: any) => {
          return (
            <Stack
              key={brandId + metric.name}
              style={{ display: "flex" }}
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ height: "50px", alignSelf: "center" }}
                />
              }
              sx={{
                minWidth: "17vw",
                maxWidth: "17vw",
                justifyContent: "center",
              }}
            >
              <Stack
                sx={{
                  minWidth: "8vw",
                  maxWidth: "8vw",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Item>
                  {metric.targetCost
                    ? `${currency}${getRoundedValue(metric.targetCost)}`
                    : "--"}
                </Item>
                <Item>Target Cost Per {metric.name}</Item>
              </Stack>
              <Stack
                sx={{
                  minWidth: "9vw",
                  maxWidth: "9vw",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DaviationItem>
                    {currency}
                    {metric.actualCost
                      ? getRoundedValue(metric.actualCost)
                      : "0.00"}
                  </DaviationItem>
                  {metric.deviation ? (
                    <>
                      {getDeviationArrow(metric.deviation) === "up" ? (
                        <ArrowUpwardIcon
                          sx={{
                            fontSize: "1rem",
                            color: getActualCostDeviationColor(
                              metric.deviation
                            ),
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            fontSize: "1rem",
                            color: getActualCostDeviationColor(
                              metric.deviation
                            ),
                          }}
                        />
                      )}
                      <Typography
                        fontSize="0.625rem"
                        sx={{
                          color: getActualCostDeviationColor(metric.deviation),
                        }}
                      >
                        {getRoundedValue(metric.deviation)}%
                      </Typography>
                    </>
                  ) : null}
                </Box>
                <Item>Actual Cost Per {metric.name}</Item>
              </Stack>
            </Stack>
          );
        })
      )}
      <Stack
        sx={{
          minWidth: "6vw",
          maxWidth: "6vw",
          justifyContent: "center",
        }}
      >
        {!brandMetrics || brandMetrics.isLoading ? (
          <Loader />
        ) : (
          <Item>
            <Item>
              {brandMetrics?.spends?.target
                ? `${currency}${getRoundedValue(brandMetrics?.spends?.target)}`
                : "--"}
            </Item>
            <Item>Target Spends</Item>
          </Item>
        )}
      </Stack>
      <Stack
        sx={{
          minWidth: "9vw",
          maxWidth: "9vw",
          justifyContent: "center",
        }}
      >
        {!brandMetrics || brandMetrics.isLoading ? (
          <Loader />
        ) : (
          <Item>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DaviationItem>
                {currency}
                {brandMetrics?.spends?.actual
                  ? getRoundedValue(brandMetrics?.spends?.actual)
                  : "0.00"}
              </DaviationItem>
              {brandMetrics?.spends?.deviation ? (
                <>
                  {getDeviationArrow(brandMetrics?.spends?.deviation) ===
                  "up" ? (
                    <ArrowUpwardIcon
                      sx={{
                        fontSize: "1rem",
                        color: getActualSpendsAndBudgetDeviationColor(
                          brandMetrics?.spends?.deviation
                        ),
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{
                        fontSize: "1rem",
                        color: getActualSpendsAndBudgetDeviationColor(
                          brandMetrics?.spends?.deviation
                        ),
                      }}
                    />
                  )}
                  <Typography
                    fontSize="0.625rem"
                    sx={{
                      color: getActualSpendsAndBudgetDeviationColor(
                        brandMetrics?.spends?.deviation
                      ),
                    }}
                  >
                    {getRoundedValue(brandMetrics?.spends?.deviation)}%
                  </Typography>
                </>
              ) : null}
            </Box>
            <Item>Actual Spends</Item>
          </Item>
        )}
      </Stack>
      <Stack
        sx={{
          minWidth: "6vw",
          maxWidth: "6vw",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!brandMetrics || brandMetrics.isLoading ? (
          <Loader />
        ) : (
          <Item>
            <Item>
              {brandMetrics?.dailyBudget?.target
                ? `${currency}${getRoundedValue(
                    brandMetrics?.dailyBudget?.target
                  )}`
                : "--"}
            </Item>
            <Item>Target Daily Budget</Item>
          </Item>
        )}
      </Stack>
      <Stack
        sx={{
          minWidth: "9vw",
          maxWidth: "9vw",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!brandMetrics || brandMetrics.isLoading ? (
          <Loader />
        ) : (
          <Item>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DaviationItem>
                {currency}
                {brandMetrics?.dailyBudget?.actual
                  ? getRoundedValue(brandMetrics?.dailyBudget?.actual)
                  : "0.00"}
              </DaviationItem>
              {brandMetrics?.dailyBudget?.deviation ? (
                <>
                  {getDeviationArrow(brandMetrics?.dailyBudget?.deviation) ===
                  "up" ? (
                    <ArrowUpwardIcon
                      sx={{
                        fontSize: "1rem",
                        color: getActualSpendsAndBudgetDeviationColor(
                          brandMetrics?.dailyBudget?.deviation
                        ),
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{
                        fontSize: "1rem",
                        color: getActualSpendsAndBudgetDeviationColor(
                          brandMetrics?.dailyBudget?.deviation
                        ),
                      }}
                    />
                  )}
                  <Typography
                    fontSize="0.625rem"
                    sx={{
                      color: getActualSpendsAndBudgetDeviationColor(
                        brandMetrics?.dailyBudget?.deviation
                      ),
                    }}
                  >
                    {getRoundedValue(brandMetrics?.dailyBudget?.deviation)}%
                  </Typography>
                </>
              ) : null}
            </Box>
            <Item>Actual Daily Budget</Item>
          </Item>
        )}
      </Stack>
    </Stack>
  );
}

export default BrandRowRight;
