import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import Toggle from "react-toggle";
import "../index.scss";
import { useDispatch, useSelector } from "react-redux";
import { setCalibrationFormData } from "../../../store/actions/targetingAI";

export default memo(({ data, isConnectable }) => {
  const formData = useSelector((state) => state.calibration.formData);
  const dispatch = useDispatch();
  const setStateFunctions = {
    Website: {
      1: {
        key: "BrandWebsiteValue",
        functionName: "setBrandWebsiteValue",
      },
      2: {
        key: "CompetitorWebsiteValue",
        functionName: "setCompetitorWebsiteValue",
      },
    },
    IntentTraffic: {
      1: {
        key: "IntentValue",
        functionName: "setIntentValue",
      },
      2: {
        key: "TrafficValue",
        functionName: "setTrafficValue",
      },
    },
  };

  return (
    <div className="p-3 bg-white card-style ">
      {data.data.map((element, index) => {
        const elementKey = setStateFunctions[element.id][element.index];
        return (
          <div key={index} className="mb-3">
            <Handle
              type="target"
              position="left"
              style={{ background: "#0869FB" }}
              onConnect={(params) => console.log("handle onConnect", params)}
              isConnectable={isConnectable}
            />
            <div className="flex justify-between text-sm font-weight-bold">
              {element.label}
              <Toggle
                id="biscuit-status"
                aria-labelledby="biscuit-label"
                className="custom-react-toggle"
                icons={false}
                onChange={(e) => {
                  dispatch(
                    setCalibrationFormData({
                      key: `${elementKey.key}Check`,
                      value: e.target.checked,
                    })
                  );
                }}
                defaultChecked={formData[`${elementKey.key}Check`]}
              />
            </div>
            <div className="flex justify-between py-2 my-2">
              <div
                className={`${
                  !formData[`${elementKey.key}Check`]
                    ? "disabled-ui text-lg font-weight-light"
                    : "text-lg font-weight-light"
                }`}
              >
                Selected Value
              </div>
              <button className="text-lg border text-primary">
                {formData[elementKey.key]}%
              </button>
            </div>
            <div className="w-full p-2 my-1 border rounded shadow-sm">
              <div className="slider-parent w-100">
                <input
                  aria-valuetext=""
                  className="w-100"
                  type="range"
                  disabled={!formData[`${elementKey.key}Check`]}
                  min="1"
                  max="100"
                  defaultValue={formData[elementKey.key]}
                  onChange={({ target: { value: radius } }) => {
                    dispatch(
                      setCalibrationFormData({
                        key: elementKey.key,
                        value: radius,
                      })
                    );
                  }}
                />
              </div>
              <div className="flex justify-between w-100">
                <div
                  className={`${
                    !formData[`${elementKey.key}Check`]
                      ? "disabled-ui text-md"
                      : "text-md"
                  }`}
                >
                  0%
                </div>
                <div
                  className={`${
                    !formData[`${elementKey.key}Check`]
                      ? "disabled-ui text-md"
                      : "text-md"
                  }`}
                >
                  100%
                </div>
              </div>
            </div>
            {element.id !== "IntentTraffic" && (
              <Handle
                type="source"
                position="right"
                style={{ background: "#0869FB" }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
              />
            )}
          </div>
        );
      })}
    </div>
  );
});
