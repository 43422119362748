import React, { useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Divider from "@mui/material/Divider";

import CustomChip from "./Chip";
import {
  CHANNEL_CARD_LABELS,
  VIDEO_CARD_SVGS,
} from "../../constants/youtubeIndex";
import { Months } from "../../../../utils/constants/index";
import { amountValue } from "utils/commonFunctions";

interface ICardProps {
  id: string;
  type: "CHANNEL";
  aiGroupId: string;
  createdAt: string;
  updatedAt: string;
  channel_id: string;
  channel_title: string;
  channel_view_count: number;
  channel_description: string;
  channel_video_count: number;
  selected_video_tags: string;
  channel_subscriber_count: number;
  channel_topic_categories: string;
  selected_video_relevance: number;
  checked: boolean;
  onClick: Function;
}

function ChannelCard(props: ICardProps) {
  const {
    // id,
    selected_video_relevance,
    // video_ids,
    // cluster_id,
    channel_title,
    channel_description,
    channel_subscriber_count,
    channel_video_count,
    channel_view_count,
    selected_video_tags,
    // video_links,
    // mixed_clus_ind,
    // aiGroupId,
    // createdAt,
    updatedAt,
    checked,
    onClick,
  } = props;
  const checkBoxRef = useRef(null);

  const getDate = () => {
    const publishDate = new Date(updatedAt);
    return (
      publishDate.getDate() +
      " " +
      Months[publishDate.getMonth() + 1] +
      ", " +
      publishDate.getFullYear()
    );
  };

  const kwsArray = Object.keys(
    JSON.parse(
      typeof selected_video_tags === "string"
        ? selected_video_tags.replaceAll(`'`, `"`)
        : "{}"
    )
  );

  return (
    <Box
      component="div"
      onClick={(e: any) => {
        onClick(e, checkBoxRef);
      }}
      className="font-inter"
      sx={{
        width: "19.25rem",
        height: "19rem",
        border: "0.06rem solid #CCECFE",
        boxShadow: "0 0.625rem 1.5rem rgba(35, 40, 60, 0.05)",
        borderRadius: "0.5rem",
        background: "#fff",
      }}
    >
      <Stack>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              padding: "0.5rem 0.75rem 0.75rem",
              width: "70%",
              background: "#F1F7FF",
              borderRadius: "0.5rem 0 3.28rem 0",
            }}
          >
            <Tooltip
              title={channel_title.length > 18 ? channel_title : channel_title}
            >
              <Typography
                className={`font-600 font-14 ${
                  channel_title.length > 18 ? "overflow" : ""
                }`}
                sx={{
                  color: "#0869FB",
                  minWidth: "10.1rem",
                  maxWidth: "10.1rem",
                  marginBottom: "5px",
                }}
              >
                {channel_title}
              </Typography>
            </Tooltip>

            <Tooltip
              title={
                channel_description && channel_description.length > 24
                  ? channel_description
                  : ""
              }
            >
              <Typography
                className={`font-400 font-10 ${
                  channel_description && channel_description.length > 24
                    ? "overflow"
                    : ""
                }`}
                sx={{ color: "#383838", minWidth: "8rem", maxWidth: "8rem" }}
              >
                {channel_description}
              </Typography>
            </Tooltip>
          </Box>
          <Box>
            <Checkbox
              ref={checkBoxRef}
              className="cohort-card-checkbox"
              size="small"
              checked={checked}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ padding: 1 }}
          alignItems="center"
        >
          <CalendarTodayOutlinedIcon
            fontSize="small"
            sx={{ height: "0.75rem", width: "0.75rem" }}
          />
          <Typography className="font-10 font-400">
            Recommended on&nbsp;
            {getDate()}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
          <Box sx={{ minWidth: "50%", maxWidth: "50%" }}>
            <Stack
              spacing={1.2}
              divider={
                <Divider
                  sx={{
                    border: "0.063rem solid #EFEFEF",
                    marginTop: "0.625rem",
                  }}
                />
              }
            >
              {[
                selected_video_relevance.toFixed(2),
                channel_subscriber_count,
                `${channel_video_count} (${amountValue(channel_view_count)})`,
              ].map((metric, index) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  key={index}
                >
                  <Box
                    sx={{
                      padding: "0.25rem 0.5rem",
                      background: "#F5FFF7",
                      borderRadius: "0.21rem",
                    }}
                  >
                    {VIDEO_CARD_SVGS[index]}
                  </Box>
                  <Stack sx={{ margin: "0px 0px 0px 8px" }}>
                    <Typography
                      sx={{ color: "#333" }}
                      className="font-10 font-400"
                    >
                      {CHANNEL_CARD_LABELS[index]}
                    </Typography>
                    <Typography
                      title={metric.toString()}
                      sx={{ color: "#00261C" }}
                      className="font-14 font-500"
                    >
                      {metric}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Box>
          <Box
            sx={{
              background: "#F7F7F7",
              borderRadius: 1,
              minWidth: "50%",
              maxWidth: "50%",
              padding: "0.25rem 0 0 0.25rem",
            }}
          >
            <Box sx={{ minHeight: "8rem", overflowX: "hidden", pr: 1 }}>
              {kwsArray.slice(0, 5).map((element: string, index: number) => {
                return <CustomChip label={element} key={index} />;
              })}
            </Box>
            {kwsArray.length > 5 && (
              <Typography
                className="font-10 font-500"
                sx={{
                  color: "#0869FB",
                  float: "right",
                  margin: "0.2rem 0.5rem",
                }}
              >
                +{kwsArray.length - 5}&nbsp;More
              </Typography>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default ChannelCard;
