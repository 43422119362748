import styled, { css } from "styled-components";
import { devices } from "../../../../../scss/StyledComponent/Styled";
import { ReactComponent as ReplacementIcon } from "../../../../../assets/svg/replacement.svg";

export const StyledCardContainer = styled.div`
  position: relative;
  height: 23.5em;
  background: #ffffff;
  border: 0.0625em solid #e6e6e6;
  box-shadow: 0.0625em 0.9375em 1.25em rgba(118, 118, 118, 0.05);
  border-radius: 0.5em;
  @media ${devices.tablet} {
    width: 26.5em;
    /* margin-right: 0.3em;
    margin-left: 0.3em;
    margin-bottom: 0.6em; */
  }
  @media ${devices.laptop} {
    width: 22.1em;
    /* margin-right: 0.3em;
    margin-left: 0.3em;
    margin-bottom: 1.4em; */
  }
  @media ${devices.laptopL} {
    width: 22.357142857142858em;
    /* margin-right: 0.5em;
    margin-left: 0.5em;
    margin-bottom: 1em; */
  }

  @media ${devices.desktop} {
    width: 19.5625em;
    margin-right: 1.4375em;
    // margin-left:1.4375em;
    // margin-bottom 2.875em;
    /* margin-bottom: 1.4375em; */
  }
`;

export const StyledHeaderContent = styled.div`
  padding: 0.625em;
  display: flex;
`;

export const StyledTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledTitleIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledHeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  svg {
    cursor: pointer;
  }
`;

export const StyledReplacementIcon = styled(ReplacementIcon)`
  height: 1rem;
  cursor: pointer;
  &.active {
    path {
      fill: #fff;
      stroke: #0869fb !important;
    }
  }
`;

export const StyledCardHeadTitle = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.5em;
  display: flex;
  align-items: center;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 17.5em;
  margin-bottom: 0.1em;
`;
export const StyledCardHeadSubTitle = styled.p`
  margin-bottom: 0.4em;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1em;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
`;
export const StyledCenterContent = styled.div`
  background: #ffffff;
  border-top:0.065740625em solid #f3f3f3;
  border-radius: 0em;
  height:auto;
  width: 100%;
  border-bottom 0.065740625em solid #f3f3f3;

`;
export const StyledParaContent = styled.div`
  padding: 0.625em;
  margin-top: 0.3125em;
`;

export const StyledFirstPara = styled.p`
  margin-bottom: 0em !important;
  margin-bottom: 0.3125em;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1em;
  display: flex;
  align-items: center;
  color: #5049bb;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 17rem;
`;
export const StyledSecondPara = styled.p`
  margin-bottom: 0em !important;
  margin-bottom: 0.3125em;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1em;
  display: flex;
  align-items: center;
  color: #356b35;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
export const StyledThirdPara = styled.p`
  margin-bottom: 0.3125em;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1em;
  display: flex;
  align-items: center;
  color: #737474;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const StyledViewPara = styled.p`
  margin-bottom: 0em !important;
  margin-bottom: 0.3125em;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1em;
  display: flex;
  align-items: center;
  color: #5385e4;
`;
export const StyledPriceContent = styled.div`
background: #ffffff;
border-radius: 0em;
width: 100%;
border-bottom 0.065740625em solid #f3f3f3;`;

export const StyledPriceSpan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.6875em;
`;

export const StyledPriceDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.125em;
  border-right: 0.0625em solid #f1f1f1;
  width: 100%;
  flex-direction: column;
`;
export const StyledPriceDivLast = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5em;
  width: 100%;
  flex-direction: column;
`;
export const StyledPriceNo = styled.p`
  margin-bottom: 0.1875em !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.1875em;
  display: flex;
  align-items: center;
  color: #000000;
`;

export const StyledPriceText = styled.p`
  margin-bottom: 0em !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8125em;
  line-height: 0.9375em;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
`;

export const StyledQualityText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.5em;
  display: flex;
  align-items: center;
  color: #7a7a7a;
`;
export const StyledToolTipContent = styled.span`
  cursor: pointer;
`;

export const StyledAverageContaner = styled.div`
  width: 7.249375em;
  height: 1.6875em;
  background: #fffbe5;
  border-radius: 1.4375em;
  text-align: center;
`;

export const StyledAvarageText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.25em;
  text-transform: capitalize !important;
  color: #ff8d08;
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625em;
  margin-top: 6px;
  .gsaiCreateVariantButton {
    @media ${devices.tablet} {
      width: 100px !important;
    }
    @media ${devices.laptop} {
      width: 122px !important;
    }
    @media ${devices.laptopS} {
      width: 100px !important;
    }
    @media ${devices.laptopL} {
      width: 122px !important;
    }

    @media ${devices.desktop} {
      width: 122px !important;
    }
    border-radius: 0.375em !important;
    color: #0869fb !important;
    border-color: #0869fb !important;
    font-weight: 500 !important;
    font-size: 0.875em !important;
    font-family: "Inter";
    padding: 0.3125em 0.1875em !important;
    box-shadow: none !important;
  }
  .gsaiRefreshAdButton {
    @media ${devices.tablet} {
      width: 100px !important;
      padding: 0.4em 0.1875em !important;
    }
    @media ${devices.laptop} {
      width: 122px !important ;
      padding: 0.4em 0.1875em !important;
    }
    @media ${devices.laptopS} {
      width: 100px !important;
      padding: 0.4em 0.1875em !important;
    }
    @media ${devices.laptopL} {
      width: 122px !important;
      padding: 0.42857142857142855em 0.1875em !important;
    }

    @media ${devices.desktop} {
      width: 122px !important;
      padding: 0.375em 0.1875em !important;
    }
    background: #0869fb;
    border-radius: 0.375em !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    font-size: 0.875em !important;
    font-family: "Inter";
    sup {
      top: -0.1em;
      right: -0.2em;
      font-size: 1.3em;
    }
  }
  .gsaiDeleteAdButton {
    color: #e84b47;
    font-size: 0.8rem;
  }
`;

export const StyledNoAdsAvailableImage = styled.img`
  width: 20rem;
  height: 20rem;
`;
export const StyledNoAdsPara = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 1.475rem;
  line-height: 1.275rem;
  color: #000000;
  margin-bottom: 1rem;
`;
export const StyledNoAdsSubPara = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.15rem;
  line-height: 1.425rem;
  text-align: center;
  color: #5f5f5f;
  margin-bottom: 0px;
  width: 500px;
`;
export const StyledNoAdsAvailableMainDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;
export const StyledNoAdsAvailableContentMainDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledStatusIconContainer = styled.div`
  padding-right: 4px;
  cursor: pointer;
`;

export const StyledQualityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.6rem;
`;
