import ExploreExploite from "../ReactFlowComponents/Explore-Exploite";
import CustomAudience from "../ReactFlowComponents/CustomAudience";
import Allnodes from "../ReactFlowComponents/Allnodes";
import CustomInputNode from "../ReactFlowComponents/CustomInputNode";
import BasicSetup from "../components/BasicSetup";
import KeywordSetup from "../components/KeywordSetup";
import KeywordSetupCollpased from "../components/KeywordSetupCollpased";
import BasicHeader from "../components/BasicHeader";
import LoaderCalibration from "../ReactFlowComponents/Loaders/LoaderCalibration";
import LoaderCalibrationCollapse from "../ReactFlowComponents/LoaderCalibrationCollapse";
import LoaderKeywordTuning from "../ReactFlowComponents/Loaders/LoaderKeywordTuning";
import LoaderKeywordTuningCollapsed from "../ReactFlowComponents/loaderKeywordTuningCollapsed";
import LoaderAudienceExplorer from "../ReactFlowComponents/Loaders/LoaderAudienceExplorer";
import LoaderAudienceExplorerCollapsed from "../ReactFlowComponents/LoaderAudienceExplorerCollapsed";
import CohortProcessInfo from "../components/CohortProcessInfo";
import CalibrationOptions from "../ReactFlowComponents/Calibration-Options";
import LookingFor from "../ReactFlowComponents/LookingFor.js";
import { setCurrentPage, setNodeCollapse } from "store/actions/targetingAI";
import { mixPanelInputCommonFunction } from "./MixpanelInputCommonFunction";
import ThreeDChart from "../ReactFlowComponents/ThreeDChart";
import PersonaTuner from "../ReactFlowComponents/PersonaTuner/PersonaTuner";
import cloneDeep from "lodash/cloneDeep";
import { FocusToNode } from "../useFocusToNode";
import isNumber from "lodash/isNumber";
import uniqBy from "lodash/uniqBy";
import {
  SetEdges,
  SetNodes,
  cleanupEdgesGeneric,
  cleanupNodesGeneric,
} from ".";

export const FB_NODE_TYPES: any = {
  ExploreExploite: ExploreExploite,
  CustomAudience: CustomAudience,
  threeDchart: ThreeDChart,
  allnodes: Allnodes,
  customnode: CustomInputNode,
  basicSetup: BasicSetup,
  keywordSetup: KeywordSetup,
  basicHeader: BasicHeader,
  loaderCalibration: LoaderCalibration,
  loaderCalibrationCollapse: LoaderCalibrationCollapse,
  loaderKeywordTuning: LoaderKeywordTuning,
  LoaderAudienceExplorer: LoaderAudienceExplorer,
  loaderKeywordTuningCollapsed: LoaderKeywordTuningCollapsed,
  LoaderAudienceExplorerCollapsed: LoaderAudienceExplorerCollapsed,
  keywordSetupCollapsed: KeywordSetupCollpased,
  cohortProcessInfo: CohortProcessInfo,
  CalibrationOptions: CalibrationOptions,
  LookingFor: LookingFor,
  PersonaTuner: PersonaTuner,
};

const NODE_EXPANDED_GUTTER = 200;
const NODE_COLLAPSED_GUTTER = 150;
const DEFAULT_OFFSET = 296;

export function getHtmlFontSizeInPx() {
  return +window
    .getComputedStyle(document.querySelector("html")!)
    .fontSize.replace("px", "");
}

export function resolveDimentionToPixel(dimention: string | number) {
  if (isNumber(dimention)) return dimention;
  if (dimention.endsWith("px")) return +dimention.replace("px", "");
  if (dimention.endsWith("vh"))
    return window.innerHeight * (+dimention.replace("vh", "") / 100);
  if (dimention.endsWith("vw"))
    return window.innerWidth * (+dimention.replace("vw", "") / 100);
  const remVal = getHtmlFontSizeInPx();
  return typeof dimention === "string"
    ? +dimention.replace("rem", "") * remVal
    : dimention * remVal;
}

const getXandY = (key: string, offset: number) => {
  return {
    x: offset + NODE_COLLAPSED_GUTTER,
    y: -resolveDimentionToPixel(FB_NODES_LIST[key].style?.height || FB_NODES_LIST[key].style?.minHeight) / 2,
  };
};

type LoaderState = {
  loaderCollapsed: boolean;
  BasicSetupParentCollapsed: boolean;
  basicSetupLoaderCollapsed: boolean;
  keywordSetupCollapsed: boolean;
  keywordSetupLoaderCollapsed: boolean;
};

const calculateOffset = (nodesList: string[]) => {
  return nodesList.reduce((sum, nodeId) => {
    return (
      sum +
      resolveDimentionToPixel(FB_NODES_LIST[nodeId].style.width) +
      (nodeId.includes("Collapsed")
        ? NODE_COLLAPSED_GUTTER
        : NODE_EXPANDED_GUTTER)
    );
  }, DEFAULT_OFFSET);
};

const nodePosition: any = {
  aiInitiationParent: function () {
    return { x: 0, y: -230 };
  },
  aiInitiation: function () {
    return { x: 0, y: -230 };
  },
  loaderExpanded: function () {
    return {
      x: DEFAULT_OFFSET + NODE_EXPANDED_GUTTER,
      y:
        -resolveDimentionToPixel(FB_NODES_LIST["loaderExpanded"].style.height) /
        2,
    };
  },
  loaderCollapsed: () => {
    return {
      x: DEFAULT_OFFSET + NODE_COLLAPSED_GUTTER,
      y:
        -resolveDimentionToPixel(
          FB_NODES_LIST["loaderCollapsed"].style.height
        ) / 2,
    };
  },
  BasicSetupParentExpanded: (loaderState: LoaderState) => {
    const nodesList = [
      loaderState.loaderCollapsed ? "loaderCollapsed" : "loaderExpanded",
    ];
    const offset = calculateOffset(nodesList);
    return getXandY("BasicSetupParentExpanded", offset);
  },
  BasicSetupParentCollapsed: (loaderState: LoaderState) => {
    const nodesList = [
      loaderState.loaderCollapsed ? "loaderCollapsed" : "loaderExpanded",
    ];
    const offset = calculateOffset(nodesList);
    return getXandY("BasicSetupParentCollapsed", offset);
  },
  basicSetupLoaderExpanded: (loaderState: LoaderState) => {
    const nodesList = [
      loaderState.loaderCollapsed ? "loaderCollapsed" : "loaderExpanded",
      loaderState.BasicSetupParentCollapsed
        ? "BasicSetupParentCollapsed"
        : "BasicSetupParentExpanded",
    ];
    const offset = calculateOffset(nodesList);
    return getXandY("basicSetupLoaderExpanded", offset);
  },
  basicSetupLoaderCollapsed: (loaderState: LoaderState) => {
    const nodesList = [
      loaderState.loaderCollapsed ? "loaderCollapsed" : "loaderExpanded",
      loaderState.BasicSetupParentCollapsed
        ? "BasicSetupParentCollapsed"
        : "BasicSetupParentExpanded",
    ];
    const offset = calculateOffset(nodesList);
    return getXandY("basicSetupLoaderCollapsed", offset);
  },
  KeywordSetupExpanded: (loaderState: LoaderState) => {
    const nodesList = [
      loaderState.loaderCollapsed ? "loaderCollapsed" : "loaderExpanded",
      loaderState.BasicSetupParentCollapsed
        ? "BasicSetupParentCollapsed"
        : "BasicSetupParentExpanded",
      loaderState.basicSetupLoaderCollapsed
        ? "basicSetupLoaderCollapsed"
        : "basicSetupLoaderExpanded",
    ];
    const offset = calculateOffset(nodesList);
    return getXandY("KeywordSetupExpanded", offset);
  },
  keywordSetupCollapsed: (loaderState: LoaderState) => {
    const nodesList = [
      loaderState.loaderCollapsed ? "loaderCollapsed" : "loaderExpanded",
      loaderState.BasicSetupParentCollapsed
        ? "BasicSetupParentCollapsed"
        : "BasicSetupParentExpanded",
      loaderState.basicSetupLoaderCollapsed
        ? "basicSetupLoaderCollapsed"
        : "basicSetupLoaderExpanded",
    ];
    const offset = calculateOffset(nodesList);
    return getXandY("keywordSetupCollapsed", offset);
  },
  keywordSetupLoaderExpanded: (loaderState: LoaderState) => {
    const nodesList = [
      loaderState.loaderCollapsed ? "loaderCollapsed" : "loaderExpanded",
      loaderState.BasicSetupParentCollapsed
        ? "BasicSetupParentCollapsed"
        : "BasicSetupParentExpanded",
      loaderState.basicSetupLoaderCollapsed
        ? "basicSetupLoaderCollapsed"
        : "basicSetupLoaderExpanded",
      loaderState.keywordSetupCollapsed
        ? "keywordSetupCollapsed"
        : "KeywordSetupExpanded",
    ];
    const offset = calculateOffset(nodesList);
    return getXandY("keywordSetupLoaderExpanded", offset);
  },
  keywordSetupLoaderCollapsed: (loaderState: LoaderState) => {
    const nodesList = [
      loaderState.loaderCollapsed ? "loaderCollapsed" : "loaderExpanded",
      loaderState.BasicSetupParentCollapsed
        ? "BasicSetupParentCollapsed"
        : "BasicSetupParentExpanded",
      loaderState.basicSetupLoaderCollapsed
        ? "basicSetupLoaderCollapsed"
        : "basicSetupLoaderExpanded",
      loaderState.keywordSetupCollapsed
        ? "keywordSetupCollapsed"
        : "KeywordSetupExpanded",
    ];
    const offset = calculateOffset(nodesList);
    return getXandY("keywordSetupLoaderCollapsed", offset);
  },
  chartCohortParent: (loaderState: LoaderState) => {
    const nodesList = [
      loaderState.loaderCollapsed ? "loaderCollapsed" : "loaderExpanded",
      loaderState.BasicSetupParentCollapsed
        ? "BasicSetupParentCollapsed"
        : "BasicSetupParentExpanded",
      loaderState.basicSetupLoaderCollapsed
        ? "basicSetupLoaderCollapsed"
        : "basicSetupLoaderExpanded",
      loaderState.keywordSetupCollapsed
        ? "keywordSetupCollapsed"
        : "KeywordSetupExpanded",
      loaderState.keywordSetupLoaderCollapsed
        ? "keywordSetupLoaderCollapsed"
        : "keywordSetupLoaderExpanded",
    ];
    const offset = calculateOffset(nodesList);
    return getXandY("chartCohortParent", offset);
  },
  threeDchart: function () {
    return { x: 10, y: 10 };
  },
};

export const changePosition = (
  page: number,
  setNodes: Function,
  loaderState: LoaderState
) => {

  setNodes((prevNodes: any) => {
    let newNodes = cloneDeep(prevNodes).map((node: any) => {
      if (
        (node.data.page < page || node.parentNode) &&
        node.id !== "threeDchart"
      ) {
        return node;
      } else {
        const { x, y } = nodePosition[node.id](loaderState);
        node.position = { x, y };
        node.data.position = { x, y };
        if (node.id === "threeDchart") {
          node.data.parentPosition =
            nodePosition["chartCohortParent"](loaderState);
        }
        return node;
      }
    });
    return newNodes;
  });
};

const changeModalWIthData = (
  data: any,
  setModalWithData: Function,
  setShowModelWithData: Function
) => {
  setModalWithData(data);
  setShowModelWithData(true);
};

const setFocusedCohort = (
  nodeId: any,
  value: any,
  setNodes: Function,
  selectedBrand: any,
  selectedAgency: any,
  cohortType: any
) => {
  console.log("LL: nodeId", nodeId);

  setNodes((nds: any) => {
    mixPanelInputCommonFunction(
      "TAI Graph Cohort Clicked",
      selectedBrand?.name,
      selectedAgency?.name,
      {
        brand_id: selectedBrand?.id,
        agency_id: selectedBrand?.agencyId,
        tai_flow: cohortType,
      }
      // {
      //   ad_account_id: selectedAdAccount?.id,
      //   ai_group_id: selectedGroup?.id,
      //   ai_group_name: selectedGroup?.name,
      //   ad_account_name: selectedAdAccount?.name,
      // }
    );
    return nds.map((node: any) => {
      if (node.id !== value) {
        return node;
      }
      return {
        ...node,
        data: {
          ...node.data,
          focusedCohort: nodeId,
        },
      };
    });
  });
};

const setSelectedCohortData = (
  selectedCohort: any,
  value: any,
  keywordsToDelete: any,
  graphData: any,
  setNodes: Function,
  selectedBrand: any,
  selectedAgency: any,
  cohortType: any
) => {
  setNodes((nds: any) => {
    mixPanelInputCommonFunction(
      "TAI Graph Cohort Clicked",
      selectedBrand?.name,
      selectedAgency?.name,
      {
        brand_id: selectedBrand?.id,
        agency_id: selectedBrand?.agencyId,
        tai_flow: cohortType,
      }
      // {
      //   ad_account_id: selectedAdAccount?.id,
      //   ai_group_id: selectedGroup?.id,
      //   ai_group_name: selectedGroup?.name,
      //   ad_account_name: selectedAdAccount?.name,
      // }
    );
    return nds.map((node: any) => {
      if (node.id !== value) {
        return node;
      }
      return {
        ...node,
        data: {
          ...node.data,
          deleteKeywordDetails: {
            selectedCohort,
            keywordsToDelete,
            graphData,
          },
        },
      };
    });
  });
};

const setGraphNodeLength = (length: number, setNodes: Function) => {
  setNodes((nds: any) => {
    return nds.map((node: any) => {
      if (node.id !== "threeDchart") {
        return node;
      }

      node.data.graphNodeLength = length;
      return node;
    });
  });
};

export const FB_EDGES_LIST: any = {
  aiGroupToLoaderExpanded: {
    id: "aiGroupToLoaderExpanded",
    source: "aiInitiationParent",
    target: "loaderExpanded",
    data: {
      page: 1,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  aiGroupToLoaderCollapsed: {
    id: "aiGroupToLoaderCollapsed",
    source: "aiInitiationParent",
    target: "loaderCollapsed",
    data: {
      page: 1,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderExpandedToBasicSetupCollapsed: {
    id: "loaderExpandedToBasicSetupCollapsed",
    source: "loaderExpanded",
    target: "BasicSetupParentCollapsed",
    data: {
      page: 1,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderCollapsedToBasicSetupCollapsed: {
    id: "loaderCollapsedToBasicSetupCollapsed",
    source: "loaderCollapsed",
    target: "BasicSetupParentCollapsed",
    data: {
      page: 1,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderExpandedToOptions: {
    id: "loaderExpandedToOptions",
    source: "loaderExpanded",
    target: "OptionCalibration",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderCollapsedToOption: {
    id: "loaderCollapsedToOption",
    source: "loaderCollapsed",
    target: "OptionCalibration",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  loaderCollapsedToExplore: {
    id: "loaderCollapsedToExplore",
    source: "OptionCalibration",
    target: "Explore",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  OptionCalibrationToLookingFor: {
    id: "OptionCalibrationToLookingFor",
    source: "OptionCalibration",
    target: "LookingFor",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  OptionCalibrationToCustomAudience: {
    id: "OptionCalibrationToCustomAudience",
    source: "OptionCalibration",
    target: "CustomAudience",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  exploreToBasicSetup: {
    id: "exploreToBasicSetup",
    source: "Explore",
    target: "BasicSetupParentExpanded",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    type: "simplebezier",
    zIndex: 99,
  },
  customAudienceToBasicSetup:{
    id: "customAudienceToBasicSetup",
    source: "CustomAudience",
    target: "BasicSetupParentExpanded",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    type: "simplebezier",
    zIndex: 99,
  },
  lookingForToBasicSetup: {
    id: "lookingForToBasicSetup",
    source: "LookingFor",
    target: "BasicSetupParentExpanded",
    data: {
      page: 2,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    type: "simplebezier",
    zIndex: 99,
  },
  basicSetupCollapsedTobasicSetupLoaderExpanded: {
    id: "basicSetupCollapsedTobasicSetupLoaderExpanded",
    source: "BasicSetupParentCollapsed",
    target: "basicSetupLoaderExpanded",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  basicSetupCollapsedTobasicSetupLoaderCollapsed: {
    id: "basicSetupCollapsedTobasicSetupLoaderCollapsed",
    source: "BasicSetupParentCollapsed",
    target: "basicSetupLoaderCollapsed",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  basicSetupToLoaderExpanded: {
    id: "basicSetupToLoaderExpanded",
    source: "BasicSetupParentExpanded",
    target: "basicSetupLoaderExpanded",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  basicSetupLoaderToKeywordSetupExpanded: {
    id: "basicSetupLoaderToKeywordSetupExpanded",
    source: "basicSetupLoaderExpanded",
    target: "KeywordSetupExpanded",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  basicSetupLoaderExpandedToKeywordSetupCollapsed: {
    id: "basicSetupLoaderExpandedToKeywordSetupCollapsed",
    source: "basicSetupLoaderExpanded",
    target: "keywordSetupCollapsed",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  basicSetupToLoaderCollapsed: {
    id: "basicSetupToLoaderCollapsed",
    source: "BasicSetupParentExpanded",
    target: "basicSetupLoaderCollapsed",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  basicSetupLoaderToKeywordSetupCollapsed: {
    id: "basicSetupLoaderToKeywordSetupCollapsed",
    source: "basicSetupLoaderCollapsed",
    target: "KeywordSetupExpanded",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  basicSetupLoaderCollapsedToKeywordSetupCollapsed: {
    id: "basicSetupLoaderCollapsedToKeywordSetupCollapsed",
    source: "basicSetupLoaderCollapsed",
    target: "keywordSetupCollapsed",
    data: {
      page: 3,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  keywordSetupToLoaderExpanded: {
    id: "keywordSetupToLoaderExpanded",
    source: "KeywordSetupExpanded",
    target: "keywordSetupLoaderExpanded",
    data: {
      page: 4,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  keywordSetupCollapsedToLoaderExpanded: {
    id: "keywordSetupCollapsedToLoaderExpanded",
    source: "keywordSetupCollapsed",
    target: "keywordSetupLoaderExpanded",
    data: {
      page: 4,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  keywordSetupCollapsedToLoaderCollapsed: {
    id: "keywordSetupCollapsedToLoaderCollapsed",
    source: "keywordSetupCollapsed",
    target: "keywordSetupLoaderCollapsed",
    data: {
      page: 4,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  keywordSetupLoaderToChartExpanded: {
    id: "keywordSetupLoaderToChartExpanded",
    source: "keywordSetupLoaderExpanded",
    target: "threeDchart",
    data: {
      page: 4,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  keywordSetupToLoaderCollapsed: {
    id: "keywordSetupToLoaderCollapsed",
    source: "KeywordSetupExpanded",
    target: "keywordSetupLoaderCollapsed",
    data: {
      page: 4,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  keywordSetupLoaderToChartCollapsed: {
    id: "keywordSetupLoaderToChartCollapsed",
    source: "keywordSetupLoaderCollapsed",
    target: "threeDchart",
    data: {
      page: 4,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
  chartToCohort: {
    id: "chartToCohort",
    source: "threeDchart",
    target: "cohortNode",
    data: {
      page: 4,
    },
    animated: true,
    style: { stroke: "#0869FB", strokeWidth: "2px" },
    zIndex: 99,
  },
};

type CohortType = "INTEREST" | "ICP";

const onTypeChange = (
  dispatch: any,
  setEdges: SetEdges,
  setNodes: SetNodes,
  value = "INTEREST"
) => {
  const nodeIdsToRemove = [
    "CustomAudience",
    "LookingFor",
  ];
  const nodeIdsToInsert = [
    ...(value === "ICP" ? ["LookingFor"] : ["CustomAudience"]),
  ];
  cleanupNodes(nodeIdsToInsert, nodeIdsToRemove, setNodes);
  cleanupEdges(nodeIdsToInsert, nodeIdsToRemove, setEdges);
};

export const FB_NODES_LIST: any = {
  loaderExpanded: {
    id: "loaderExpanded",
    type: "loaderCalibration",
    data: {
      index: 2,
      label: "LoaderTest",
      page: 1,
      loaderContinue: (
        dispatch: Function,
        loaderState: LoaderState,
        setNodes: SetNodes,
        setEdges: SetEdges,
        cohortType: CohortType,
        focusToNode?: FocusToNode
      ) => {
        const activePageLocal = localStorage.getItem("currentPage") as string;
        const activePageLocalInt = parseInt(activePageLocal);

        const nodeIdsToInsert = [
          "loaderCollapsed",
          ...(loaderState.BasicSetupParentCollapsed
            ? []
            : [
                "BasicSetupParentExpanded", // duplicate shall be removed
                "OptionCalibration", // duplicate shall be removed
                "Explore", // duplicate shall be removed
                ...(cohortType === "ICP"
                  ? ["LookingFor"]
                  : ["CustomAudience"]),
              ]),
        ];
        const nodeIdsToRemove = [
          "loaderExpanded",
          // conflicting nodes for icp and intrest
          "LookingFor",
          // "Website",
          "CustomAudience",
        ];
        cleanupNodes(nodeIdsToInsert, nodeIdsToRemove, setNodes);
        cleanupEdges(nodeIdsToInsert, nodeIdsToRemove, setEdges);

        dispatch(setNodeCollapse({ type: "loaderCollapsed", collapse: true }));
        changePosition(1, setNodes, {
          ...loaderState,
          loaderCollapsed: true,
        });
        dispatch(
          setCurrentPage(activePageLocalInt > 2 ? activePageLocalInt : 3)
        );
        focusToNode?.("BasicSetupParentExpanded");
      },
      textBtn: "Continue",
      position: { x: DEFAULT_OFFSET + NODE_EXPANDED_GUTTER, y: -160 },
    },
    position: { x: DEFAULT_OFFSET + NODE_EXPANDED_GUTTER, y: -160 },
    className: "light",
    style: {
      display: "flex",
      width: 1200,
      height: 786,
      borderRadius: "20px",
    },
    sourcePosition: "right",
    targetPosition: "left",
    draggable: false,
  },
  loaderCollapsed: {
    id: "loaderCollapsed",
    type: "loaderCalibrationCollapse",
    data: {
      page: 1,
      position: { x: 600, y: 410 },
    },
    position: { x: 600, y: 410 },
    className: "light",
    style: {
      display: "flex",
      width: "2.90rem",
      height: "2.90rem",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50px",
      backgroundColor: "#fff",
      boxShadow: "0px 4.83018px 16.9056px rgba(0, 0, 0, 0.05)",
    },
    targetPosition: "left",
    sourcePosition: "right",
    draggable: false,
  },
  BasicSetupParentExpanded: {
    id: "BasicSetupParentExpanded",
    type: "basicHeader",
    data: {
      index: 3,
      page: 1,
      label: "Basic Setup",
      nextPage: (
        dispatch: Function,
        loaderState: any,
        setNodes: SetNodes,
        setEdges: SetEdges,
        focusToNode?: FocusToNode
      ) => {
        if (loaderState["loaderCollapsed"]) {
          FB_NODES_LIST["basicSetupLoaderCollapsed"].position = {
            x: 900,
            y: 100,
          };
          FB_NODES_LIST["basicSetupLoaderCollapsed"].data.position = {
            x: 900,
            y: 100,
          };
        }
        dispatch(
          setNodeCollapse({
            type: "loaderCollapsed",
            collapse: loaderState["loaderCollapsed"],
          })
        );
        const nodeIdsToRemove = [
          "BasicSetupParentExpanded",
          "OptionCalibration",
          "Explore",
          // "Website",
          // "custom",
          "CustomAudience",
          "LookingFor",
          "basicSetupLoaderExpanded",
          "basicSetupLoaderCollapsed",
          "KeywordSetupExpanded",
          "keywordSetupCollapsed",
          "keywordSetupLoaderExpanded",
          "keywordSetupLoaderCollapsed",
          "chartCohortParent",
          "threeDchart",
          "cohortNode",
        ];
        const nodeIdsToInsert = [
          "BasicSetupParentCollapsed",
          "basicSetupLoaderExpanded",
        ];
        cleanupNodes(nodeIdsToInsert, nodeIdsToRemove, setNodes);
        cleanupEdges(nodeIdsToInsert, nodeIdsToRemove, setEdges);

        dispatch(
          setNodeCollapse({
            type: "BasicSetupParentCollapsed",
            collapse: true,
          })
        );
        changePosition(1, setNodes, {
          ...loaderState,
          BasicSetupParentCollapsed: true,
        });
        focusToNode?.("basicSetupLoaderExpanded");
      },
      onEditClick: (
        dispatch: Function,
        loaderState: any,
        setNodes: SetNodes,
        setEdges: SetEdges,
        cohortType: string,
        focusToNode?: FocusToNode
      ) => {
        let trimId = "BasicSetupParent";

        const nodeIdsToInsert = [`${trimId}Collapsed`];
        const nodeIdsToRemove = [
          `${trimId}Expanded`,
          "OptionCalibration",
          "Explore",
          "CustomAudience",
          // "Website",
          // "custom",
          "LookingFor",
        ];

        cleanupNodes(nodeIdsToInsert, nodeIdsToRemove, setNodes);
        cleanupEdges(nodeIdsToInsert, nodeIdsToRemove, setEdges);

        dispatch(
          setNodeCollapse({
            type: "BasicSetupParentCollapsed",
            collapse: true,
          })
        );
        changePosition(1, setNodes, {
          ...loaderState,
          BasicSetupParentCollapsed: true,
        });
        focusToNode?.(`BasicSetupParentCollapsed`);
      },
      position: { x: 2000, y: 10 },
    },
    position: { x: 2000, y: 10 },
    className: "light",
    style: {
      width: 1000,
      height: 820,
      backgroundColor: "#FFFFFF",
      zIndex: 0,
      borderRadius: "20px",
    },
    draggable: false,
    selectable: true,
  },
  OptionCalibration: {
    id: "OptionCalibration",
    type: "CalibrationOptions",
    data: {
      page: 1,
      data: [
        {
          label: (
            <div>
              <div style={{ fontSize: "16px" }}>
                Interest based&nbsp;&nbsp;
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#767676",
                }}
              >
                Generate cohort with interest recommendations
              </div>
            </div>
          ),
          checkBoxValue: true,
          index: 1,
          id: "OptionCalibration",
        },
        {
          label: (
            <div>
              <div style={{ fontSize: "16px" }}>
                Ideal Customer Persona &nbsp;&nbsp;
                <span
                  style={{
                    fontSize: "10px",
                    color: "#0350C3",
                    padding: "2px, 6px, 2px, 6px",
                    borderRadius: "4px",
                    background: "#E3EEFF",
                  }}
                >
                  New
                </span>
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#767676",
                }}
              >
                Generate persona that is specific for your brand
              </div>
            </div>
          ),
          checkBoxValue: true,
          index: 2,
          id: "OptionCalibration",
        },
      ],
      onTypeChange,
      targetHandle: true,
    },
    position: { x: 30, y: 300 },
    className: "light",
    style: {
      height: 200,
      width: "350px",
      zIndex: 1000,
    },
    parentNode: "BasicSetupParentExpanded",
    draggable: false,
    connectable: true,
    selectable: true,
  },
  Explore: {
    id: "Explore",
    type: "ExploreExploite",
    data: {
      page: 1,
      data: [
        {
          label: (
            <div>
              <div style={{ fontSize: "16px" }}>
                Explore/Exploit&nbsp;&nbsp;
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#767676",
                }}
              >
                Explore new audiences or exploit historic performance
              </div>
            </div>
          ),
          checkBoxValue: true,
          slidervalue: 50,
          id: "Explore",
        },
      ],
      targetHandle: true,
    },
    position: { x: 520, y: 50 },
    className: "light",
    style: {
      height: 200,
      width: "350px",
    },
    parentNode: "BasicSetupParentExpanded",
    draggable: false,
    connectable: true,
    selectable: true,
  },
  LookingFor: {
    id: "LookingFor",
    type: "LookingFor",
    data: {
      page: 1,
      data: [
        {
          label: (
            <div>
              <div style={{ fontSize: "16px" }}>
                What are you looking for&nbsp;&nbsp;
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#767676",
                }}
              >
                Describe your product and persona
              </div>
            </div>
          ),
          id: "LookingFor",
        },
      ],
    },
    position: { x: 520, y: 220 },
    className: "light",
    style: {
      height: 200,
      width: "350px",
      zIndex: 1000,
    },
    parentNode: "BasicSetupParentExpanded",
    draggable: false,
    connectable: true,
    selectable: true,
  },
  CustomAudience:{
    id: "CustomAudience",
    type: "CustomAudience",
    data: {
      page: 1,
      data: [
        {
          label: (
            <div>
              <div style={{ fontSize: "16px" }}>
                Custom Audience&nbsp;&nbsp;
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#767676",
                }}
              >
                Select custom audience with larger audience size to
                generate good quality and high confidence cohorts
              </div>
            </div>
          ),
          id: "CustomAudience",
        },
      ],
      targetHandle: true,
    },
    position: { x: 520, y: 260 },
    className: "light",
    style: {
      height: 411,
      width: "350px",
    },
    parentNode: "BasicSetupParentExpanded",
    draggable: false,
    connectable: true,
    selectable: true,
  },
  BasicSetupParentCollapsed: {
    id: "BasicSetupParentCollapsed",
    type: "basicSetup",
    data: {
      label: "Basic Setup",
      page: 3,
      popover: true,
      onClick: changeModalWIthData,
      onEditClick: (
        dispatch: Function,
        loaderState: any,
        setNodes: SetNodes,
        setEdges: SetEdges,
        cohortType: string,
        focusToNode?: FocusToNode
      ) => {
        let trimId = "BasicSetupParentCollapsed".split("Collapsed")[0];

        const nodeIdsToInsert = [
          `${trimId}Expanded`,
          "OptionCalibration",
          "Explore",
          ...(cohortType === "ICP" ? ["LookingFor"] : ["CustomAudience"]),
        ];
        const nodeIdsToRemove = ["BasicSetupParentCollapsed"];

        cleanupNodes(nodeIdsToInsert, nodeIdsToRemove, setNodes);
        cleanupEdges(nodeIdsToInsert, nodeIdsToRemove, setEdges);

        dispatch(
          setNodeCollapse({
            type: "BasicSetupParentCollapsed",
            collapse: false,
          })
        );
        changePosition(1, setNodes, {
          ...loaderState,
          BasicSetupParentCollapsed: false,
        });
        focusToNode?.("BasicSetupParentExpanded");
      },
      position: { x: 900, y: 240 },
    },
    position: { x: 900, y: 240 },
    className: "light",
    style: {
      display: "flex",
      width: 250,
      minHeight: 184,
      backgroundColor: "#FFF",
      borderRadius: "20px",
    },
    sourcePosition: "right",
    draggable: false,
  },
  basicSetupLoaderExpanded: {
    id: "basicSetupLoaderExpanded",
    type: "loaderKeywordTuning",
    data: {
      index: 4,
      page: 2,
      label: "basicSetupLoaderExpanded",
      loaderContinue: (
        dispatch: Function,
        loaderState: LoaderState,
        setNodes: SetNodes,
        setEdges: SetEdges,
        focusToNode?: FocusToNode
      ) => {
        const nodeIdsToInsert = ["basicSetupLoaderCollapsed"];
        const nodeIdsToRemove = ["basicSetupLoaderExpanded"];

        // custom inplementation on cleanupNotes due to a different case
        setNodes((nodes: any[]) => {
          // if step not progressed before insert next node on continue
          if (
            !nodes.some((node) =>
              ["keywordSetupCollapsed", "KeywordSetupExpanded"].includes(
                node.id
              )
            )
          ) {
            nodeIdsToInsert.push("KeywordSetupExpanded");
          }
          return uniqBy(
            [
              ...nodes.filter((node) => !nodeIdsToRemove.includes(node.id)), // remove unwanted nodes
              ...nodeIdsToInsert.map((nodeId) => FB_NODES_LIST[nodeId]),
            ],
            "id"
          );
        });
        cleanupEdges(nodeIdsToInsert, nodeIdsToRemove, setEdges);

        const activePageLocal = localStorage.getItem("currentPage") as string;
        const activePageLocalInt = parseInt(activePageLocal);
        dispatch(
          setNodeCollapse({
            type: "basicSetupLoaderCollapsed",
            collapse: true,
          })
        );
        changePosition(2, setNodes, {
          ...loaderState,
          basicSetupLoaderCollapsed: true,
        });
        dispatch(
          setCurrentPage(activePageLocalInt > 4 ? activePageLocalInt : 5)
        );
        focusToNode?.("KeywordSetupExpanded");
      },
      textBtn: "Continue",
      position: { x: 1100, y: 20 },
    },
    position: { x: 1100, y: 15 },
    className: "light",
    style: {
      display: "flex",
      width: 1120,
      height: 880,
      borderRadius: "20px",
    },
    sourcePosition: "right",
    targetPosition: "left",
    draggable: false,
  },
  basicSetupLoaderCollapsed: {
    id: "basicSetupLoaderCollapsed",
    type: "loaderKeywordTuningCollapsed",
    data: {
      page: 2,
      position: { x: 3300, y: 400 },
    },
    position: { x: 3300, y: 400 },
    className: "light",
    style: {
      display: "flex",
      width: "2.90rem",
      height: "2.90rem",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50px",
      backgroundColor: "#fff",
      boxShadow: "0px 4.83018px 16.9056px rgba(0, 0, 0, 0.05)",
    },
    targetPosition: "left",
    sourcePosition: "right",
    draggable: false,
  },
  KeywordSetupExpanded: {
    id: "KeywordSetupExpanded",
    type: "keywordSetup", // need to add condition to handle the view of Keywordsetup of PersonaTuner
    position: { x: 4700, y: 10 },
    data: {
      index: 5,
      position: { x: 4700, y: 10 },
      page: 3,
      id: "KeywordSetup",
      disabledFields: false,
      nextPage: (
        dispatch: Function,
        loaderState: any,
        setNodes: SetNodes,
        setEdges: SetEdges,
        focusToNode?: FocusToNode
      ) => {
        const nodeIdsToInsert = [
          "keywordSetupCollapsed",
          "keywordSetupLoaderExpanded",
        ];
        const nodeIdsToRemove = [
          "KeywordSetupExpanded",
          "keywordSetupCollapsed",
          "keywordSetupLoaderExpanded",
          "keywordSetupLoaderCollapsed",
          "chartCohortParent",
          "threeDchart",
          "cohortNode",
        ];

        cleanupNodes(nodeIdsToInsert, nodeIdsToRemove, setNodes);
        cleanupEdges(nodeIdsToInsert, nodeIdsToRemove, setEdges);

        dispatch(
          setNodeCollapse({ type: "keywordSetupCollapsed", collapse: true })
        );
        changePosition(3, setNodes, {
          ...loaderState,
          keywordSetupCollapsed: true,
        });
        focusToNode?.("keywordSetupLoaderExpanded");
      },
      onEditClick: (
        dispatch: Function,
        loaderState: any,
        setNodes: SetNodes,
        setEdges: SetEdges,
        focusToNode?: FocusToNode
      ) => {
        const nodeIdsToInsert = ["keywordSetupCollapsed"];
        const nodeIdsToRemove = ["KeywordSetupExpanded"];

        cleanupNodes(nodeIdsToInsert, nodeIdsToRemove, setNodes);
        cleanupEdges(nodeIdsToInsert, nodeIdsToRemove, setEdges);

        dispatch(
          setNodeCollapse({ type: "keywordSetupCollapsed", collapse: true })
        );
        changePosition(3, setNodes, {
          ...loaderState,
          keywordSetupCollapsed: true,
        });
        focusToNode?.("keywordSetupCollapsed");
      },
    },
    draggable: false,
    style: {
      width: 1000,
      height: 770,
      justifyContent: "center",
      alignItems: "center",
      zIndex: 0,
      backgroundColor: "#FFFFFF",
      borderRadius: "20px",
    },
    connectable: "true",
  },
  keywordSetupCollapsed: {
    id: "keywordSetupCollapsed",
    type: "keywordSetupCollapsed",
    data: {
      page: 3,
      position: { x: 4700, y: 10 },
      popover: true,
      onClick: changeModalWIthData,
      onEditClick: (
        dispatch: Function,
        loaderState: any,
        setNodes: Function,
        setEdges: Function,
        focusToNode?: FocusToNode
      ) => {
        setNodes((nodes: any[]) => {
          let index = nodes.findIndex((e) => e?.id === "keywordSetupCollapsed");
          if (index === -1) return nodes;
          const filtered = nodes.filter(
            (node) => !["keywordSetupCollapsed"].includes(node.id)
          );
          filtered.splice(index, 0, FB_NODES_LIST["KeywordSetupExpanded"]);
          return [...filtered];
        });
        let addEdges: any = Object.values(FB_EDGES_LIST).filter((e: any) =>
          [e?.source, e?.target].includes("KeywordSetupExpanded")
        );
        setEdges((edges: any[]) => {
          const filteredEdges = edges.filter(
            (edge) =>
              ![edge?.source, edge?.target].includes("keywordSetupCollapsed") &&
              ![
                "basicSetupLoaderToKeywordSetupCollapsed",
                "basicSetupLoaderToKeywordSetupExpanded",
                "keywordSetupToLoaderExpanded",
                "keywordSetupToLoaderCollapsed",
              ].includes(edge?.id)
          );
          return [...addEdges, ...filteredEdges];
        });
        dispatch(
          setNodeCollapse({
            type: "keywordSetupCollapsed",
            collapse: false,
          })
        );
        changePosition(3, setNodes, {
          ...loaderState,
          keywordSetupCollapsed: false,
        });
        console.log({ focusToNode });
        focusToNode?.("KeywordSetupExpanded");
      },
    },
    position: { x: 4700, y: 10 },
    className: "light",
    targetPosition: "left",
    sourcePosition: "right",
    draggable: false,
    style: {
      width: 330,
      height: 310,
    },
  },
  keywordSetupLoaderExpanded: {
    id: "keywordSetupLoaderExpanded",
    type: "LoaderAudienceExplorer",
    data: {
      index: 6,
      page: 4,
      label: "LoaderTest ",
      loaderContinue: (
        dispatch: Function,
        loaderState: any,
        setNodes: SetNodes,
        setEdges: SetEdges
      ) => {
        const nodeIdsToInsert = [
          "keywordSetupLoaderCollapsed",
          "chartCohortParent",
          "threeDchart",
          "cohortNode",
        ];
        const nodeIdsToRemove = ["keywordSetupLoaderExpanded"];

        // custom inplementation on cleanupNotes due to a different case
        setNodes((nodes: any[]) => {
          const nextNodesList = uniqBy(
            [
              ...nodes.filter((node) => !nodeIdsToRemove.includes(node.id)), // remove unwanted nodes
              ...nodeIdsToInsert.map((nodeId) => FB_NODES_LIST[nodeId]),
            ],
            "id"
          );

          const threeDChartIndex = nextNodesList.findIndex(
            (e) => e?.id === "threeDchart"
          );
          if (threeDChartIndex !== -1)
            nextNodesList[threeDChartIndex].data.graphNodeLength = 4;
          return nextNodesList;
        });
        cleanupEdges(nodeIdsToInsert, nodeIdsToRemove, setEdges);

        const activePageLocal = localStorage.getItem("currentPage") as string;
        const activePageLocalInt = parseInt(activePageLocal);
        dispatch(
          setNodeCollapse({
            type: "keywordSetupLoaderCollapsed",
            collapse: true,
          })
        );
        changePosition(4, setNodes, {
          ...loaderState,
          keywordSetupLoaderCollapsed: true,
        });
        dispatch(
          setCurrentPage(activePageLocalInt > 6 ? activePageLocalInt : 7)
        );
      },
      textBtn: "Continue",
      position: { x: 6000, y: 20 },
    },
    position: { x: 6000, y: 20 },
    className: "light",
    style: {
      display: "flex",
      width: 1000,
      height: 750,
      borderRadius: "20px",
    },
    sourcePosition: "right",
    targetPosition: "left",
    draggable: false,
  },
  keywordSetupLoaderCollapsed: {
    id: "keywordSetupLoaderCollapsed",
    type: "LoaderAudienceExplorerCollapsed",
    data: {
      page: 4,
      position: { x: 6000, y: 200 },
    },
    position: { x: 6000, y: 200 },
    className: "light",
    style: {
      display: "flex",
      width: "2.90rem",
      height: "2.90rem",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50px",
      backgroundColor: "#fff",
      boxShadow: "0px 4.83018px 16.9056px rgba(0, 0, 0, 0.05)",
    },
    targetPosition: "left",
    sourcePosition: "right",
    draggable: false,
  },
  chartCohortParent: {
    id: "chartCohortParent",
    position: { x: 7300, y: 10 },
    data: {
      page: 4,
      position: { x: 7300, y: 10 },
    },
    className: "light",
    style: {
      width: "65rem",
      height: "80vh",
      zIndex: 0,
      borderRadius: "20px",
      border: "1px solid #DDDDDD",
      display: "none",
    },
    draggable: false,
    selectable: true,
  },
  threeDchart: {
    id: "threeDchart",
    type: "threeDchart",
    position: { x: 10, y: 10 },
    data: {
      position: { x: 10, y: 10 },
      parentPosition: { x: 10, y: 10 },
      index: 7,
      page: 4,
      graphNodeLength: 4,
      dropdownValue: "",
      textBoxValue: "",
      id: "threeDchart",
      data: [],
      disabledFields: true,
      setFocusedCohort,
    },
    draggable: false,
    style: {
      width: "40vw",
      height: "76vh",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      border: "1px solid #DDDDDD",
      borderRadius: "20px",
    },
    parentNode: "chartCohortParent",
  },
  cohortNode: {
    id: "cohortNode",
    type: "cohortProcessInfo",
    data: {
      page: 4,
      id: "cohortNode",
      focusedCohort: "",
      setFocusedCohort,
      setSelectedCohortData,
      setGraphNodeLength,
    },
    position: { x: window.innerWidth * 0.4 + 10, y: 10 },
    draggable: false,
    style: {
      height: "76vh",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 0,
      backgroundColor: "white",
      borderRadius: "20px",
    },
    connectable: "false",
    parentNode: "chartCohortParent",
  },
};

export enum granularInsightsValues{
  NOT_TRIGGERED="NOT_TRIGGERED",
  SYNCED="SYNCED",
  FAILED="FAILED"
}
export const convertToKFormat = (number:number) => {
  // Check if the input is a valid number
  if (typeof number !== 'number' || isNaN(number)) {
      throw new Error('Invalid input: not a number');
  }

  // Use Intl.NumberFormat to format the number in a compact form
  const formatter = new Intl.NumberFormat('en', {
      notation: 'compact',
      compactDisplay: 'short'
  });

  return formatter.format(number);
}
export const cleanupEdges = cleanupEdgesGeneric(FB_EDGES_LIST);
export const cleanupNodes = cleanupNodesGeneric(FB_NODES_LIST);
