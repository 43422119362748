import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HttpService from "../../../../HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "../../../../utils/path";
import { initialState } from "../../../../store/types/auth";
import { getAccountDetails } from "../../../../store/actions/auth";
import { AdAccountState } from "../../../../store/types/adaccount";
import {
  setFilteredAdsLoader,
  updateGsaiStore,
} from "../../../../store/actions/CreativeAI/GsaiPlayground";

import {
  GsaiState,
  SET_AD_GROUPS,
} from "../../../../store/types/CreativeAI/GsaiPlayground";
import { adStrength } from "utils/constants/GSAI";

function creatAdGroups(ads: any): [any, Record<string, boolean>] {
  const adGroups: any = {};
  const selectedAdGroups: Record<string, boolean> = {};
  for (const ad of ads) {
    adGroups[ad.adGroup.id] = {
      id: ad.adGroup.id,
      label: ad.adGroup.name,
      selected: true,
    };
    selectedAdGroups[ad.adGroup.id] = true;
  }
  return [Object.values(adGroups), selectedAdGroups];
}

export function useGsaiDropdowns() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: { auth: initialState }) => state.auth);
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const ads = useSelector((state: any) => state.gsaiPlayground.ads);
  const platform = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground.platform
  );
  const aiGroups = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground.aiGroups
  );
  const adAccounts = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground.adAccounts
  );
  const campaigns = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground.campaigns
  );
  const screen = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground.screen
  );

  const [adGroups, setAdGroups] = useState<any>({ isLoading: true, data: [] });
  const [selectedAdGroups, setSelectedAdGroups] = useState<
    Record<string, boolean>
  >({});

  // FETCH PLATFORM ID
  useEffect(() => {
    if (!platform || !platform.id) {
      HttpService.get(CROSS_PLATFORM_URL, "/v1/api/platform", user)
        .then((response) => {
          const { data: { data = [] } = {} } = response;
          for (const item of data) {
            if (item.type === "GOOGLE SEARCH AD") {
              dispatch({
                type: "UPDATE_GSAI_STORE",
                payload: {
                  platform: { isLoading: false, id: item.id },
                },
              });
              break;
            }
          }
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_GSAI_STORE",
            payload: {
              platform: { isLoading: false, error: e.message },
            },
          });
        });
    }
  }, []);

  // FETCH AI GROUPS

  useEffect(() => {
    if (selectedBrand.id && platform.id) {
      dispatch({
        type: "UPDATE_GSAI_STORE",
        payload: {
          aiGroups: { ...aiGroups, isLoading: true },
          filteredAdsLoader: true,
        },
      });
      HttpService.get(
        CROSS_PLATFORM_URL,
        `/v1/api/brand/${selectedBrand.id}/ai-group?platformId=${platform.id}`,
        user
      )
        .then((response) => {
          const { data: { data = [] } = {} } = response;
          dispatch({
            type: "UPDATE_GSAI_STORE",
            payload: {
              aiGroups: { isLoading: false, data, selected: data[0] },
            },
          });
          dispatch({
            type: "SET_NO_ADS_AVAILABLE",
            payload: false,
          });
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_GSAI_STORE",
            payload: {
              aiGroups: {
                isLoading: false,
                data: [],
                error: e.message,
                selected: {},
              },
            },
          });
          dispatch({
            type: "SET_NO_ADS_AVAILABLE",
            payload: true,
          });
        });
    }
  }, [platform.id, selectedBrand.id]);

  // FETCH AD ACCOUNTS
  useEffect(() => {
    if (aiGroups.selected?.id) {
      dispatch({
        type: "UPDATE_GSAI_STORE",
        payload: {
          adAccounts: { ...adAccounts, isLoading: true },
          campaigns: { ...campaigns, isLoading: true },
          filteredAdsLoader: true,
        },
      });
      setAdGroups({ isLoading: true, data: [] });
      HttpService.get(
        CROSS_PLATFORM_URL,
        `/v1/api/ai-group/${aiGroups.selected.id}/ad-accounts?platformId=${platform.id}`,
        user
      )
        .then((response) => {
          const { data: { data = [] } = {} } = response;
          dispatch({
            type: "UPDATE_GSAI_STORE",
            payload: {
              adAccounts: { isLoading: false, data, selected: data[0] },
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_GSAI_STORE",
            payload: {
              adAccounts: {
                isLoading: false,
                data: [],
                error: e.message,
                selected: {},
              },
            },
          });
          dispatch({
            type: "SET_NO_ADS_AVAILABLE",
            payload: true,
          });
        });
    }
  }, [platform?.id, aiGroups?.selected?.id]);

  // FETCH CAMPAIGNS
  useEffect(() => {
    if (adAccounts.selected?.adAccountId) {
      dispatch({
        type: "UPDATE_GSAI_STORE",
        payload: {
          campaigns: { ...campaigns, isLoading: true },
        },
      });
      dispatch(getAccountDetails(adAccounts.selected?.adAccountId));
      HttpService.get(
        CROSS_PLATFORM_URL,
        `/v1/api/element/${adAccounts.selected.elementId}/campaigns/platform/details?adAccountId=${adAccounts.selected.adAccountId}&not_in_status=REMOVED`,
        user
      )
        .then((response) => {
          const { data: { data = [] } = {} } = response;
          const formattedDdata = data.map((d: any) => d.campaign);
          dispatch({
            type: "UPDATE_GSAI_STORE",
            payload: {
              campaigns: {
                isLoading: false,
                data: formattedDdata,
                selected: formattedDdata[0],
              },
            },
          });
          if (!data.length) {
            dispatch({
              type: "SET_NO_ADS_AVAILABLE",
              payload: true,
            });
          } else {
            dispatch({
              type: "SET_NO_ADS_AVAILABLE",
              payload: false,
            });
          }
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_GSAI_STORE",
            payload: {
              campaigns: {
                isLoading: false,
                data: [],
                error: e.message,
                selected: {},
              },
            },
          });
          dispatch({
            type: "SET_NO_ADS_AVAILABLE",
            payload: true,
          });
        });
    }
  }, [adAccounts.selected.elementId, adAccounts.selected.adAccountId]);

  // FETCH ADS
  useEffect(() => {
    if (screen == 0) {
      if (campaigns.selected?.id) {
        setAdGroups({ isLoading: true, data: [] });
        dispatch({
          type: SET_AD_GROUPS,
          payload: { isLoading: true, data: [] },
        });
        HttpService.get(
          CROSS_PLATFORM_URL,
          `/v2/api/gsai/ads/${adAccounts.selected.adAccountId}/${campaigns.selected.id}`,
          user
        )
          .then((response) => {
            const { data: { data = [] } = {} } = response;
            const newData = data.map((eachData: any, index: number) => {
              if (eachData.isDraft && eachData.isCreate) {
                return {
                  ...eachData.meta_data,
                  editorData: eachData.data,
                  isDraft: eachData.isDraft,
                  reviewStatus: eachData.reviewStatus,
                  isCreate: eachData.isCreate,
                  variant_id: eachData.variant_id,
                  urlHash: eachData.urlHash,
                  isVisible: true,
                };
              } else {
                return {
                  ...eachData,
                  metrics: {
                    ...eachData.metrics,
                    quality: adStrength.indexOf(eachData.adGroupAd.adStrength),
                  },
                  isVisible: true,
                  editorData: eachData.data,
                };
              }
            });

            const [adGroups, selected] = creatAdGroups(newData);
            setAdGroups({ isLoading: false, data: adGroups });
            dispatch({
              type: SET_AD_GROUPS,
              payload: { isLoading: false, data: adGroups },
            });
            setSelectedAdGroups(selected);
            dispatch(
              updateGsaiStore({
                ads: newData,
                filteredAds: newData,
                aiGroup: aiGroups.selected,
                adAccount: adAccounts.selected,
                campaign: campaigns.selected,
              })
            );
            dispatch(setFilteredAdsLoader(false));
            dispatch({
              type: "SET_NO_ADS_AVAILABLE",
              payload: Boolean(!newData.length),
            });
          })
          .catch((e) => {
            dispatch({
              type: "SET_NO_ADS_AVAILABLE",
              payload: true,
            });
          });
      } else if (!campaigns.isLoading) {
        dispatch({
          type: "SET_NO_ADS_AVAILABLE",
          payload: true,
        });
        setAdGroups({ isLoading: false, data: [] });
        dispatch({
          type: SET_AD_GROUPS,
          payload: { isLoading: false, data: [] },
        });
        setSelectedAdGroups({});
        dispatch(
          updateGsaiStore({
            ads: [],
            filteredAds: [],
            aiGroup: aiGroups.selected,
            adAccount: adAccounts.selected,
            campaign: campaigns.selected,
          })
        );
      }
    }
  }, [campaigns?.selected?.id, screen]);

  function handleSave() {
    const filteredAds = ads.filter(
      (ad: any) => selectedAdGroups[ad.adGroup.id]
    );
    dispatch(updateGsaiStore({ filteredAds }));
  }

  function setAiGroups(payload: any) {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        aiGroups: { ...aiGroups, ...payload },
      },
    });
  }

  function setAdAccounts(payload: any) {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        adAccounts: { ...adAccounts, ...payload },
      },
    });
  }

  function setCampaigns(payload: any) {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        campaigns: { ...campaigns, ...payload },
      },
    });
  }

  return {
    aiGroups,
    adAccounts,
    campaigns,
    adGroups,
    selectedAdGroups,
    setAiGroups,
    setAdAccounts,
    setCampaigns,
    setSelectedAdGroups,
    handleSave,
  };
}
