import React from "react";
import { StyledTag, StyledTagMainBox } from "./styled";
import { TextWithTooltip } from "../TextWithTooltip/TextWithTooltip";
import { ReactComponent as Cross } from "../../../../assets/cai/svg/cross.svg";
import { IconButton } from "@mui/material";
import { StyledText } from "components/shared";

export default function TagWithTitle({
  text,
  onClose,
  title,
}: {
  text: string;
  onClose?: () => void;
  title?: string;
}) {
  return (
    <StyledTagMainBox>
      {title && (
        <StyledText fontSize="0.625em" lineHeight="12px" pr="0.375em">
          {title}
        </StyledText>
      )}
      <StyledTag>
        <TextWithTooltip fontSize="0.5em" text={text} lineHeight="10px" />
        {onClose && (
          <IconButton onMouseDown={onClose}>
            <Cross />
          </IconButton>
        )}
      </StyledTag>
    </StyledTagMainBox>
  );
}
