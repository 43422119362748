import { Button } from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";

interface Props {
  height?: number;
}

export const StyledContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 13px;
  margin-bottom: 8px;
  height: 88vh;
`;

export const StyledTitle = styled.p`
  font-family: Lato;
  // margin-bottom: 10px;
  font-size: 32px;
  font-weight: 700;
  color: #000000;
`;
export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
export const StyledHeadTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  font-family: Inter;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledLinkItem = styled(Button)`
  color: #0869fb;
  font-size: 16px;
  text-transform: capitalize !important;
  // font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
`;

export const StyledLaunchButtonText = styled(Button)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0869fb;
  padding-left: 8px;
  text-transform: none;
`;

export const StyledAnimaterToggle = styled(Box)`
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 14px 16px;
  align-items: center;
  height: 48px;
`;
