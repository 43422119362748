import React, { Fragment, useEffect, useState } from "react";

import { StyledText } from "components/shared";
import {
  Backdrop,
  Box,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { PreviewFailed } from "components/CreativeAI/Facebook/CreateVariants/Editor/Preview/Preview";
import { StyledBackDrop } from "../styled";
import Loader from "components/common/Loader";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
  const { title, children } = props;
  if (!title) return children;
  return (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      sx={{
        background: "red",
      }}
    />
  );
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF", //can be made into a prop
    color: "#242424",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: "6px 12px",
    borderRadius: "8px",
    maxWidth: 500,
    minWidth: 330,
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      backgroundColor: "#FFFFFF",
      boxShadow: theme.shadows[1],
    },
  },
}));

export default function InHousePreviewModal({
  children,
  previewLink,
  setSelectedPrew,
}: {
  children: any;
  previewLink?: string;
  setSelectedPrew: any;
  index: number;
}) {
  const [iframeSrc, setIframeSrc] = useState("");
  const [previewDelay, setPreviewDelay] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    var doc: any = new DOMParser().parseFromString(
      previewLink ?? "",
      "text/xml"
    );
    const iframSrc = doc?.firstChild.getAttribute("src");
    setIframeSrc(iframSrc);
  }, [previewLink]);

  useEffect(() => {
    if (open) {
      setPreviewDelay(false);
      loadingFunction();
    }
  }, [open]);

  const loadingFunction = () => {
    setTimeout(() => {
      setPreviewDelay(true);
    }, 2000);
  };

  return (
    <Fragment>
      <Backdrop
        open={open}
        sx={{
          zIndex: 3,
        }}
      />
      <LightTooltip
        placement="right"
        arrow
        onOpen={() => {
          setSelectedPrew(previewLink);
          setOpen(true);
        }}
        onClose={() => {
          setSelectedPrew("");

          setOpen(false);
        }}
        title={
          <Box
            // display="flex"
            // flexDirection="column"
            padding=".25em"
            width="370px"
            maxWidth="500px"
            position="relative"
          >
            <StyledText fontSize="1.125em" lineHeight="19px" pb=".8em">
              Ad Preview
            </StyledText>
            <StyledBackDrop>
              {previewLink ? (
                <iframe
                  style={{
                    display: previewDelay ? "block" : "none",
                    width: "370px",
                    minHeight: "450px",
                  }}
                  src={iframeSrc}
                  title="preview"
                />
              ) : (
                <PreviewFailed />
              )}

              {!previewDelay && previewLink && (
                <Box position="absolute">
                  <Loader />
                </Box>
              )}
            </StyledBackDrop>
          </Box>
        }
      >
        {children}
      </LightTooltip>
    </Fragment>
  );
}
