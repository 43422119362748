import React, { useState, useEffect, useMemo } from "react";
import DownFill from "../../assets/DownFill.svg";
import UpFill from "../../assets/UpFill.svg";
import DoubleSlider from "../DoubleSlider/DoubleSlider";
import { Box, Stack, Typography } from "@mui/material";
import SelectText from "Pages/SmbDashboard/common/SelectField";
import Checkbox from "Pages/AudienceManager/components/Checkbox";
import MultipleSelectCheckbox from "common/MultipleSelectCheckbox";

interface Props {
  renderList?: any[];
}

const AdvanceFilter: React.FC<Props> = ({ renderList = [] }) => {
  const [showAdvancedFilterOptions, setShowAdvancedFilterOptions] =
    useState(true);
  const renderHandler = (type: string, props: any) => {
    switch (type) {
      case "Checkbox":
        return <Checkbox {...props} />;
      case "RangeSlider":
        return <DoubleSlider {...props} />;
      case "Dropdown":
        return (
          <Box key={props.heading}>
            <Stack>
              {props.heading && (
                <Typography sx={{ color: "#000" }} className="font-10 font-500">
                  {props.heading}
                </Typography>
              )}
              {props.subHeading && (
                <Typography sx={{ color: "#999" }} className="font-8 font-500">
                  {props.subHeading}
                </Typography>
              )}
              <SelectText
                {...props}
                sx={{
                  my: 1,
                  p: 0,
                  "& .MuiSelect-select": {
                    p: 0,
                    px: 1,
                    py: 0.5,
                    fontSize: "0.625rem",
                    color: "#999",
                  },
                }}
              />
            </Stack>
          </Box>
        );
      case "MultipleCheckbox":
        return (
          <Box key={props.heading}>
            <Stack>
              {props.heading && (
                <Typography sx={{ color: "#000" }} className="font-10 font-500">
                  {props.heading}
                </Typography>
              )}
              {props.subHeading && (
                <Typography sx={{ color: "#999" }} className="font-8 font-500">
                  {props.subHeading}
                </Typography>
              )}
              <MultipleSelectCheckbox
                items={props.items}
                selectedItems={props.selectedItems}
                handleChange={(e: any) => props.setSelectedItem(e.target.value)}
                sx={{
                  my: 1,
                  p: 0,
                  "& .MuiSelect-select": {
                    p: 0,
                    px: 1,
                    py: 0.5,
                    fontSize: "0.625rem",
                    color: "#999",
                  },
                }}
              />
            </Stack>
          </Box>
        );
    }
  };

  return (
    <Box className="m-auto w-224 min-height-288">
      <Box
        className="w-85p m-30-auto-0-auto b-t cur-pointer"
        onClick={() => {
          setShowAdvancedFilterOptions(!showAdvancedFilterOptions);
        }}
      >
        <Box className="w-108 d-flex align-items-center justify-content-center">
          <Typography className="mr-1 font-10 font-600">
            Advanced Filter
          </Typography>
          <img src={showAdvancedFilterOptions ? DownFill : UpFill} alt="any" />
        </Box>
      </Box>
      {showAdvancedFilterOptions && (
        <>
          {renderList.length !== 0 && (
            <Box className="mt-5px">
              {renderList.map((item: any) => renderHandler(item.type, item))}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default AdvanceFilter;
