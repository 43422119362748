import { Box } from "@mui/material";
import {
  ActionContainerListItem,
  CompareDataType,
} from "../../shared/sharedTypes";

import {
  StyledActionItemCard,
  StyledSummaryText,
  StyledTextContainer,
  StyledTextItem,
} from "./styled";
import ComparingMetricsText from "../ComparingMetricsText/ComparingMetricsText";
import TooltipActionDebugger from "../../shared/TooltipActionDebugger/TooltipActionDebugger";
import { SelectedDateType } from "../../../../store/types/ActionDebugger/ActionDebugger";
import { ActionSummaryData } from "../../../../store/types/ActionDebugger/ActionSummary";

export interface ActionSummaryItemProps {
  actionContainerListItem: ActionContainerListItem;
  comparingData?: CompareDataType;
  item: ActionSummaryData;
  currentPeriod: SelectedDateType;
}

export interface TextContainerProps {
  textContainerColor?: string;
  textContainerItemColor?: string;
}

export default function ActionSummaryItem({
  actionContainerListItem,
  comparingData,
  item,
  currentPeriod,
}: ActionSummaryItemProps) {
  const { backgroundColor, color } = actionContainerListItem;

  return (
    <StyledActionItemCard width="100%">
      <Box display="flex" alignItems="center">
        <StyledTextContainer textContainerColor={backgroundColor}>
          {(item.type === "GAI" ? "PAI" : item.type)
            .split("")
            .map((item: string) => (
              <StyledTextItem textContainerItemColor={color}>
                {item}
              </StyledTextItem>
            ))}
        </StyledTextContainer>
        <StyledSummaryText>
          {item.value} {item.name}
        </StyledSummaryText>
      </Box>
      <Box display="flex" alignItems="center">
        {comparingData && comparingData.previousValue ? (
          <ComparingMetricsText comparingData={comparingData} />
        ) : (
          ""
        )}

        <TooltipActionDebugger
          title={item.name ?? ""}
          comparingData={comparingData}
          style={{
            margin: ".8em 1.5em .8em .8em",
          }}
          currentPeriod={currentPeriod}
          currentRecord={item.value}
        />
      </Box>
    </StyledActionItemCard>
  );
}
