import styled from "styled-components";
import { styled as MUIStyled } from "@mui/system";
import { ReactComponent as SendMessage } from "../../../../assets/svg/sendMessage.svg";
import ModalContainer from "components/Modal/ModalContainer";

export const StyledReviewModalTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 30px;
  text-align: center;
  padding-top: 30px;
`;

export const StyledReviewModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledCloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  button {
    :focus {
      box-shadow: none;
    }
  }
`;

export const StyledSendMessage = MUIStyled(SendMessage)`
    height:90px;
    width:90px;
    margin-top:8px;
`;

export const StyledRevieButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0px;
  .review-btn {
    border-radius: 10px;
    width: 180px;
    margin-left: 12px;
  }
`;
