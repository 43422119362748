import { NUMBER_OF_MONTHS } from "../constants/index";
export const handleAdsmanagerRedirection = (
  adAccountId: string,
  adSetId: string
) => {
  const adsManagerLink = `${
    adSetId !== null
      ? `https://business.facebook.com/adsmanager/manage/adsets?act=${adAccountId}&filter_set=SEARCH_BY_CAMPAIGN_IDS-STRING_SET%1EANY%1E[%22${adSetId}%22]`
      : `https://business.facebook.com/adsmanager/manage/adsets?act=${adAccountId}`
  }`;
  window.open(adsManagerLink, "_blank");
};

export const subtractMonths = (
  numOfMonths = NUMBER_OF_MONTHS,
  date = new Date()
) => {
  date.setMonth(date.getMonth() - numOfMonths);
  date.setUTCHours(23, 59, 59, 999);
  return date;
};
