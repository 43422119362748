import {
  Box,
  Radio as MUIRadio,
  RadioProps as MUIRadioProps,
} from "@mui/material";
import InputLabel from "../../InputLabel/InputLabel";

interface RadioProps extends MUIRadioProps {
  label: string;
  tooltipText?: string;
}

export function Radio({ label, tooltipText, required, ...props }: RadioProps) {
  return (
    <Box display="flex" pb="15px">
      <Box mt="-10px">
        <MUIRadio size="small" className="mr-1" {...props} />
      </Box>
      {label && (
        <InputLabel
          label={label}
          tooltipText={tooltipText}
          required={required}
        />
      )}
    </Box>
  );
}
