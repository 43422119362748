import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer from "../../../../Modal/ModalContainer";
import ModalWrapper from "../../../../Modal/ModalWrapper";
import { ClusterState } from "../../../../../store/types/cluster";

import {
  a11yProps,
  TabPanel,
} from "../../../Facebook/Main/ActivityLog/ActivityLog";
import { StyledTabsContainer } from "../../../Facebook/Main/AiGroupListing/styled";
import { DescAdsContainer } from "./DescAdsContainer";
import { HeadlineAdsContainer } from "./HeadlineAdsContainer";
import "./index.scss";
import {
  setCreateAdDesc,
  setCreateAdHeadline,
} from "../../../../../store/actions/cluster";
import { GsaiState } from "store/types/CreativeAI/GsaiPlayground";
import { mixPanelAdEvent } from "utils/mixpanel";
import { AdAccountState } from "store/types/adaccount";

const AdSection: React.FC = (): JSX.Element => {
  const [value, setValue] = React.useState(0);
  const [deleteId, setDeleteId] = React.useState({
    type: "",
    id: -1,
  });
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const clusterState = useSelector(
    (state: { cluster: ClusterState }) => state.cluster
  );
  const { recommendations, adUnit, adAccounts, aiGroups, isCreate } =
    useSelector((state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground);
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { createAdHeadline, createAdDesc } = clusterState;
  const dispatch = useDispatch();

  // Delete headline/description
  const handleDelete = () => {
    if (deleteId.type === "headline") {
      const createAdData = createAdHeadline.filter(
        (h: any) => h.id !== deleteId.id
      );
      dispatch(setCreateAdHeadline(createAdData));
    } else {
      const createAdData = createAdDesc.filter(
        (h: any) => h.id !== deleteId.id
      );
      dispatch(setCreateAdDesc(createAdData));
    }
    closeDeleteModal();
  };

  const closeDeleteModal = () => {
    setDeleteId({
      type: "",
      id: -1,
    });
  };

  useEffect(() => {
    if (
      recommendations?.data?.recommended_headlines?.length > 0 ||
      recommendations?.data?.recommended_descriptions?.length > 0
    ) {
      mixPanelAdEvent(
        `CAI GS ${isCreate ? "Create Variant" : "Refresh Ad"} Step Done`,
        {
          module_name: "Google Search Playground",
          brand_name: selectedBrand?.name,
          brand_type: selectedBrand?.brandType,
          brand_id: selectedBrand?.id,
          agency_id: selectedBrand?.agencyId,
          agency_name: selectedBrand?.agency_name,
          reference_ad_id: adUnit?.adGroupAd?.ad?.id,
          reference_ad_group_id: adUnit?.adGroup?.id,
          reference_campaign_id: adUnit?.campaign?.id,
          reference_account_id: adAccounts?.selected?.adAccountId,
          ai_group_name: aiGroups?.selected?.name,
          ai_group_id: aiGroups?.selected?.id,
          initial_interest_keyword:
            recommendations?.data?.recommended_interest_keyword,
          initial_reference_Headlines:
            recommendations?.data?.recommended_headlines,
          initial_reference_description:
            recommendations?.data?.recommended_descriptions,
          initial_headline_generation_types:
            recommendations?.data?.generation_type?.headlines,
          initial_descriptions_generation_types:
            recommendations?.data?.generation_type?.descriptions,
          initial_headline_benefit_input_by_user:
            recommendations?.data?.generation_type?.headlines?.benefitValue,
          initial_description_benefit_input_by_user:
            recommendations?.data?.generation_type?.descriptions?.benefitValue,
        }
      );
    }
  }, [recommendations]);

  return (
    <>
      <Box sx={{ width: "100%" }} className="create-variant-tab-container">
        <StyledTabsContainer>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Ad variant["
            textColor="inherit"
            centered
            className="create-ad-variant-tab"
          >
            <Tab
              className="text-capitalize"
              label="Headlines"
              {...a11yProps(0)}
            />
            <Tab
              className="text-capitalize"
              label="Description"
              {...a11yProps(1)}
            />
          </Tabs>
        </StyledTabsContainer>
        <div>
          <TabPanel value={value} index={0}>
            <HeadlineAdsContainer />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DescAdsContainer />
          </TabPanel>
        </div>
      </Box>
      {/* Delete modal */}
      {deleteId.id > -1 && (
        <ModalWrapper>
          <ModalContainer
            title="Delete Keyword"
            submitText="Delete"
            handleSubmit={handleDelete}
            handleClose={closeDeleteModal}
          >
            <p>Are you sure you want to delete this keyword ?</p>
          </ModalContainer>
        </ModalWrapper>
      )}
    </>
  );
};

export default AdSection;
