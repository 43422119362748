import React from 'react'

const ConfirmationModalText: React.FC<{
  platform: string
}> = ({ platform }) => {
  return (
    <div>
      {platform === 'google' && (
        <>
          <p className='confirm-text'>
            These ad accounts need to be added to our Google Ads Manager account
            to give better visibility to our internal team.
          </p>
          <p className='confirm-text'>
            Please confirm to proceed with linking these accounts. <br />
            After confirming, check your email and accept the connection request
          </p>
        </>
      )}
      {platform === 'tiktok' && (
        <>
          <p className='confirm-text'>
            These ad accounts need to be added to our Tiktok Ads Manager account
            to give better visibility to our internal team.
          </p>
          <p className='confirm-text'>
            Please confirm to proceed with linking these accounts. <br />
            After confirming, check your email and accept the connection request
          </p>
        </>
      )}
      {platform === 'linkedin' && (
        <>
          <p className='confirm-text'>
            Pixis user needs to be added to ad accounts to give better
            visibility to our internal team. User would be added to only those
            ad accounts for which you have accounts manager permission.
          </p>
          <p className='confirm-text'>
            <strong>
              Please confirm to proceed with linking these accounts.
            </strong>
            <br /> After confirming, accept the request at LinkedIn Manager.
          </p>
        </>
      )}
      {platform === 'facebook' && (
        <>
          <p className='confirm-text'>
            These Ad Accounts need to be added to our Business Manager to give
            better visibility to our internal team.
          </p>
          <p className='confirm-text'>
            {' '}
            Please confirm to proceed with linking these accounts.
          </p>
        </>
      )}
    </div>
  )
}

export default ConfirmationModalText
