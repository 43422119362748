import { Box } from "@mui/material";
import InputLabel from "../../../../../shared/InputLabel/InputLabel";

export default function AdSetDetailsModalItem({
  heading,
  subHeading,
  tooltipText,
}: {
  heading: string;
  subHeading: string[];
  tooltipText: string;
}) {
  return (
    <Box display="flex" flexDirection="column" mr="90px">
      <InputLabel
        label={heading}
        tooltipText={tooltipText}
        textColor="#7A7A7A"
      />
      <p
        style={{
          maxWidth: heading === "Ad-set" ? "400px" : "150px",
          minWidth: "150px",
        }}
      >
        {subHeading.join(", ")}
      </p>
    </Box>
  );
}
