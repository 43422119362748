import {
  GET_ADACCOUNT_SUMMARY,
  GetAdAccountSummary,
  GET_BRAND_LEVEL_METRICS,
  GetBrandLevelMetrics,
  UPDATE_RESULT_METRICS_AND_BUDGET,
  UpdateResultMetricsAndBudget,
  SET_AD_ACCOUNT_METRICS,
  GET_AD_ACCOUNT_METRICS,
  RESET_AD_ACCOUNT_METRICS,
} from '../../types/SmbDashboard/adAccount';

export const getAdAccountSummary = (payload: any): GetAdAccountSummary => ({
  type: GET_ADACCOUNT_SUMMARY,
  payload,
});

export const getBrandLevelMetrics = (payload: any): GetBrandLevelMetrics => ({
  type: GET_BRAND_LEVEL_METRICS,
  payload,
});

export const updateResultMetricsAndBudget = (
  payload: any
): UpdateResultMetricsAndBudget => ({
  type: UPDATE_RESULT_METRICS_AND_BUDGET,
  payload,
});

export const setAdAccountMetrics = (payload: any): any => ({
  type: SET_AD_ACCOUNT_METRICS,
  payload,
});

export const getAdAccountMetrics = (payload: any): any => ({
  type: GET_AD_ACCOUNT_METRICS,
  payload,
});

export const resetAdAccountsData = () => ({
  type: RESET_AD_ACCOUNT_METRICS,
});
