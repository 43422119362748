import HttpService from "HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "utils/path";

export const getBrandsAPI = async (
  agencyId: string,
  page?: number,
  limit?: number,
  name?: string,
  order?: "DESC" | "ASC",
  orderBy?: "name" | "createdAt"
) => {
  let response = {
    success: false,
    message: "",
    data: {},
  };

  try {
    let urlParams = `/v4/api/agencies/${agencyId}/brands?${
      page ? "page=" + page : "page=1"
    }${limit ? "&limit=" + limit : "limit=50"}${name ? "&name=" + name : ""}${
      order ? "&order=" + order : ""
    }${orderBy ? "&orderBy=" + orderBy : ""}`;
    response = await HttpService.get(CROSS_PLATFORM_URL, urlParams).then(
      (data: any) => {
        return data.data;
      }
    );
    return response;
  } catch (error) {
    console.log("LL: getBrandsAPI -> error", error);
  }
  return response;
};
