import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import BasicTabs from "./BasicTabs";
import { ReactComponent as BackIcon } from "./assets/backIcon.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./index.scss";

export default function ManageAgency() {
  const agency = useSelector((state: any) => state?.smb?.agencies?.selected);
  const history = useHistory();

  return (
    <Box className="manageAgency font-inter">
      <Box
        className="bg-white border d-flex align-items-center justify-content-start"
        sx={{
          p: 2,
          m: 2,
          borderRadius: 2,
        }}
      >
        <BackIcon
          color="#303030"
          width={18}
          height={16}
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/smb-dashboard/agency" + window.location.search);
          }}
        />
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "1.25rem",
            fontWeight: 600,
            ml: "0.75rem",
            color: "#303030",
          }}
        >
          Manage Agency: {agency?.name}
        </Typography>
      </Box>

      <Box
        className="bg-white border d-flex align-items-center justify-content-start"
        sx={{
          p: 2,
          m: 2,
          borderRadius: 2,
          flexDirection: "column",
        }}
      >
        <BasicTabs />
      </Box>
    </Box>
  );
}
