/* eslint-disable @typescript-eslint/ban-ts-comment */

// @ts-nocheck
import React from "react";
import { sub } from "date-fns";
import moment from "moment";
import {
  colors,
  commonDataOptions,
  commonYAxisOptions,
} from "./constants/actionImpact";
import { currencyConverter } from "./currencyConverter";
export const sortTable: void = (tableHeaderIndex: number) => {
  let rows;
  let switching;
  let i;
  let x;
  let y;
  let shouldSwitch;
  let dir;
  let switchcount = 0;
  const table = document.getElementById("myTable");
  switching = true;
  dir = "asc";
  while (switching) {
    switching = false;
    rows = table?.getElementsByTagName("TR");
    for (i = 1; i < rows?.length - 1; i += 1) {
      shouldSwitch = false;
      x = rows[i].getElementsByTagName("TD")[tableHeaderIndex];
      y = rows[i + 1].getElementsByTagName("TD")[tableHeaderIndex];
      if (dir === "asc") {
        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
          shouldSwitch = true;
          break;
        }
      } else if (dir === "desc") {
        if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
          shouldSwitch = true;
          break;
        }
      }
    }
    if (shouldSwitch) {
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      switchcount += 1;
    } else if (switchcount === 0 && dir === "asc") {
      dir = "desc";
      switching = true;
    }
  }
};

export const getSearchParams = (key: string): string => {
  const { searchParams } = new URL(window.location.href);
  return searchParams.get(key);
};

export const base64ToUtf = (str: string): string =>
  decodeURIComponent(escape(window.atob(str)));

export const ACTIVE_COLOR: string = "#0869FB";
export const COMPLETED_COLOR: string = "#63BE09";
export const phaseDetail = (platform: string, agencyAssets) => ({
  disabled: {
    title: "Review AI Groups",
    description: `An AI Group is a collection of your existing Ad Sets in your ${platform} Ad Account. \n\n AI Groups below are created on the basis of campaign objectives and performance. \n\n You can modify any existing AI Group or create a new one. \n\n To start optimization, click on Enable AI.`,
  },
  phase1: {
    title: "Observation",
    description: `During this phase, the ${
      agencyAssets?.longTitle || "Pixis AI"
    } analyzes and compares data from every AI Group to identify the most suitable Optimization AI Model with the highest Model Confidence Score.`,
  },
  phase2: {
    title: "Recommendation",
    description:
      "During this phase, the AI Optimization model recommends actions with higher Action Confidence Score. This phase concludes when at least one action in each AI group is validated and executed with a high Confidence Score (~0.8).",
  },
  phase3: {
    title: "Calibration",
    description: `During this phase, the ${
      agencyAssets?.longTitle || "Pixis AI"
    } automatically executes all the recommended actions with an improved Action Confidence Score on every AI Group of your ${
      platform === "facebook" ? "meta" : platform
    } Ad Account.`,
  },
  phase4: {
    title: "Incubation",
    description: `During this phase, the ${
      agencyAssets?.longTitle || "Pixis AI"
    } focuses on stabilizing the improved effectiveness and performance of your Ad account by auto optimizing daily actions on Ad Sets.`,
  },
  phase5: {
    title: "Exploration",
    description: `During this phase, the ${
      agencyAssets?.longTitle || "Pixis AI"
    } unlocks a wider scope of unconventional optimization. Explore newer target audience segments and boost conversion rate using advanced AI tools.`,
  },
});

export const downloadCsv = (csvFile, filename) => {
  let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const exportToCsv = (filename, rows) => {
  let processRow = function (row) {
    let finalVal = "";
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? "" : row[j]?.toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue?.replace(/"/g, '""');
      if (result?.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  downloadCsv(csvFile, filename);
};

export const getChartData = (active, chartData) => {
  if (chartData) {
    return {
      label: `${active}`,
      data: [
        ...chartData.map((d) => {
          return {
            primary: new Date(new Date(d["Date Stop"]).setHours(0, 0, 0, 0)),
            secondary: d[active] || "0",
          };
        }),
      ],
    };
  } else {
    return {};
  }
};

export const generateChipText = (searchFilter: any) => {
  if (searchFilter.firstStep !== "confidence_score") {
    return searchFilter.secondStep;
  } else {
    const searchParams = searchFilter.secondStep.split(" ");
    switch (searchParams[0]) {
      case "gt":
        return `Confidence Score is greater than ${searchParams[1]}`;
      case "lt":
        return `Confidence Score is less than ${searchParams[1]}`;
      case "ib":
        return `Confidence Score is between ${searchParams[1]} and ${searchParams[2]}`;
      case "nib":
        return `Confidence Score is not between ${searchParams[1]} and ${searchParams[2]}`;
    }
  }
};

export const filterConfidenceData = (element: any, tableData: any) => {
  const confType = element.secondStep.split(" ");

  switch (confType[0]) {
    case "gt":
      return tableData.filter(
        (elem: any) => elem.confidence_score > confType[1]
      );
    case "lt":
      return tableData.filter(
        (elem: any) => elem.confidence_score < confType[1]
      );
    case "ib":
      return tableData.filter(
        (elem: any) =>
          elem.confidence_score > confType[1] &&
          elem.confidence_score < confType[2]
      );
    case "nib":
      return tableData.filter(
        (elem: any) =>
          elem.confidence_score < confType[1] ||
          elem.confidence_score > confType[2]
      );
  }
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const formatChatData = (chartData, getAllLable, dateRange) => {
  return (getAllLable = getAllLable.map((label) => {
    let data = [];
    dateRange.forEach((date) => {
      let obj = chartData.find(
        (o) => o["Date Stop"] === moment(date).format("YYYY-MM-DD")
      );
      if (obj && obj[label]) {
        data.push(obj[label]);
      } else {
        data.push(0);
      }
    });
    return {
      label: `${label}`,
      data,
    };
  }));
};

export const getAllLabels = (chartData: any) => {
  const result = [];
  chartData.forEach((chart) => {
    for (let [key] of Object.entries(chart)) {
      if (!result.includes(key)) {
        result.push(key);
      }
    }
  });

  return result.filter((item) => item !== "Date Stop");
};

export const formatChartInfo = (chartData, dateRange) => {
  if (chartData) {
    // Get array of all labels
    let getAllLable = getAllLabels(chartData);
    let labels = [];
    let datasets = [];

    labels = getDaysBetweenDates(dateRange);

    datasets = formatChatData(chartData, getAllLable, labels);
    let color = colors;
    const data = getAllLable.map((label: any, index: number) => {
      return {
        ...commonDataOptions,
        data: datasets.find((d: any) => d.label === label).data,
        label,
        yAxisID: label,
        backgroundColor: color[index],
        borderColor: color[index],
      };
    });
    return {
      labels,
      datasets: data,
    };
  }
  return {
    lables: [],
    datasets: [],
  };
};

export const getDaysBetweenDates = (dateRange) => {
  let startDate = moment(dateRange.start).format("YYYY-MM-DD");
  let endDate = moment(dateRange.end).format("YYYY-MM-DD");
  var now = moment(startDate).clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("MMM DD,YYYY"));
    now.add(1, "days");
  }
  return dates;
};

export const formatChartOptions = (chartData) => {
  if (chartData) {
    // Get array of all labels
    let getAllLable = getAllLabels(chartData);
    return getAllLable.map((label: any) => ({
      ...commonYAxisOptions,
      id: label,
    }));
  }
  return [];
};

export const getDateRange = (value: string) => {
  if (value === "last7") {
    return {
      start: new Date(moment().subtract(6, "days")),
      end: new Date(moment().toDate()),
    };
  }
  return {
    start: new Date(moment().subtract(13, "days").toDate()),
    end: new Date(moment().toDate()),
  };
};

export const updateSearchParams = (key: string, value: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.pushState(null, "", url);
};

export const getLinkClicksRange = () => {
  // if (localStorage.getItem("graph-date")) {
  //   const date = JSON.parse(localStorage.getItem("graph-date")!);
  //   return {
  //     start: moment(new Date(date.start)).isBefore(
  //       sub(new Date(), { days: 24 })
  //     )
  //       ? moment().subtract(24, "days").format("YYYY-MM-DD")
  //       : moment(date.start),
  //     end: moment(new Date(date.end)).isBefore(sub(new Date(), { days: 24 }))
  //       ? moment().subtract(18, "days").format("YYYY-MM-DD")
  //       : moment(date.end),
  //   };
  // }
  return {
    start: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  };
};

export const getTargetingLinkClicksRange = () => {
  return {
    start: new Date(moment().subtract(6, "days").toDate()),
    end: new Date(moment().toDate()),
  };
};

export const getAllPlatformLinkClicksRange = () => {
  if (localStorage.getItem("all-platform-graph-date")) {
    const date = JSON.parse(localStorage.getItem("all-platform-graph-date")!);
    return {
      start: moment(new Date(date.start)).isBefore(
        sub(new Date(), { days: 24 })
      )
        ? moment().subtract(24, "days").format("YYYY-MM-DD")
        : moment(date.start),
      end: moment(new Date(date.end)).isBefore(sub(new Date(), { days: 24 }))
        ? moment().subtract(18, "days").format("YYYY-MM-DD")
        : moment(date.end),
    };
  }
  return {
    start: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  };
};

export const getCurrencySymbol = (currencyName: string) => {
  var currency_symbols = {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    VND: "₫", // Vietnamese Dong
  };
  return currency_symbols[currencyName] || currencyName;
};

export const getFBAdsLink = (user: string | undefined, adId: string[]) => {
  return `https://business.facebook.com/adsmanager/manage/adsets?act=${user}&filter_set=SEARCH_BY_CAMPAIGN_IDS-STRING_SET%1EANY%1E[${adId
    .map((id) => `"${id}"`)
    .join(",")}]&selected_adset_ids=${adId.join(",")}`;
};

export const getFormatedCPR = (cpr) => {
  const { cpr_other, cpr_targeting } = cpr;

  const other_data =
    cpr_other.data?.metrics &&
    cpr_other.data?.metrics?.length &&
    cpr_other.data?.metrics[0].columns.find((obj) => obj.type === "CPR");
  const targeting_data =
    cpr_targeting.data?.metrics &&
    cpr_targeting.data?.metrics?.length &&
    cpr_targeting.data?.metrics[0].columns.find((obj) => obj.type === "CPR");
  return {
    ...cpr,
    cpr_other: other_data ? other_data : { label: "", value: null },
    cpr_targeting: targeting_data ? targeting_data : { label: "", value: null },
  };
};

export const getBarChartData = ({ range, data }) => {
  let others = [];
  let targetingAI = [];
  for (let i = 1; i <= range / 7; i++) {
    const start_date = moment()
      .subtract(`${i * 7}`, "days")
      .format("YYYY-MM-DD");
    const end_date = (
      i === 1 ? moment() : moment().subtract(`${(i - 1) * 7}`, "days")
    ).format("YYYY-MM-DD");
    const key = `${start_date}-${end_date}`;
    if (data && key in data) {
      others.push({
        primary: `Week ${i}`,
        secondary: parseFloat(data[key]["cpr_other"].value || "0").toFixed(2),
      });
      targetingAI.push({
        primary: `Week ${i}`,
        secondary: parseFloat(data[key]["cpr_targeting"].value || "0").toFixed(
          2
        ),
      });
    }
  }
  return [
    {
      label: "Targeting AI ad sets",
      data: targetingAI,
    },
    {
      label: "Others ad sets",
      data: others,
    },
  ];
};

export const postMessage = (data) => {
  window.postMessage(data, "*");
};

export const getLocalStorageData = (key) => {
  const data = localStorage.getItem(key);
  return JSON.parse(data);
};

export const setLocalStorageData = (key, value) => {
  const data = typeof value === "string" ? value : JSON.stringify(value);
  localStorage.setItem(key, data);
};

export function downloadFile(file: File, fileName: string) {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  // the filename you want
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

export function removeSessionData() {
  const key = Object.keys(localStorage).find((key) =>
    key.startsWith("@@auth0")
  );
  localStorage.removeItem(key);
}

export function getSessionData() {
  let value = null;
  const key = Object.keys(localStorage).forEach((key) => {
    if (key.startsWith("@@auth0")) {
      if (!value) {
        value = {};
      }
      value = { ...value, ...JSON.parse(localStorage.getItem(key) || "") };
      // localStorage.removeItem(key);
    }
  });
  return value;
}

export const formatAllPlatformChartInfo = (
  chartData: any,
  dates: any,
  selectedGroupAdaccounts: any,
  platforms: any,
  currencyIdentifier: any
) => {
  if (chartData) {
    // Get array of all labels
    let getAllLable = getAllPlatformLabels(chartData);

    let labels = [];

    labels = getDaysBetweenDates(dates);

    if (labels.length > 0) {
      let formatedData = formatAllPlatformChatData(
        chartData,
        getAllLable,
        labels
      );
      let currencyConvertedData = currencyConverter(
        formatedData,
        getAllLable,
        selectedGroupAdaccounts,
        platforms,
        currencyIdentifier
      );
      let percentageData = calculatePercentageData(
        currencyConvertedData,
        labels
      );
      let sorted = percentageData.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      );
      let sortedLabel = getAllLable.sort();
      const data = sortedLabel.map((label: any, index: number) => {
        let color = colors;
        return {
          ...commonDataOptions,
          data: sorted.find((d: any) => d.label === label)?.data,
          label,
          yAxisID: label,
          backgroundColor: color[index],
          borderColor: color[index],
        };
      });
      return {
        labels,
        datasets: data,
      };
    } else {
      return {
        labels: [],
        datasets: [],
      };
    }
  } else {
    return {
      lables: [],
      datasets: [],
    };
  }
};

export const getAllPlatformLabels = (chartData: any) => {
  const result = [];
  for (let [key] of Object.entries(chartData)) {
    result.push(key);
  }
  return result;
};

export const formatAllPlatformChatData = (
  chartData,
  getAllLable,
  dateRange
) => {
  return getAllLable.map((label) => {
    let data = [];
    for (const date of dateRange) {
      let newDate = moment(date).format("YYYY-MM-DD");
      if (chartData[label][newDate]) {
        data.push(
          chartData[label][newDate].spend ||
            chartData[label][newDate].spend === 0
            ? chartData[label][newDate].spend
            : chartData[label][newDate].Spend ||
              chartData[label][newDate].Spend === 0
            ? chartData[label][newDate].Spend
            : chartData[label][newDate].localSpend ||
              chartData[label][newDate].localSpend === 0
            ? chartData[label][newDate].localSpend
            : chartData[label][newDate].metric_total_media_cost_advertiser ||
              chartData[label][newDate].metric_total_media_cost_advertiser === 0
            ? chartData[label][newDate].metric_total_media_cost_advertiser
            : chartData[label][newDate].costInLocalCurrency ||
              chartData[label][newDate].costInLocalCurrency === 0
            ? chartData[label][newDate].costInLocalCurrency
            : parseFloat(chartData[label][newDate].cost_micros / 1_000_000)
        );
      } else {
        data.push(0);
      }
    }
    return {
      label: `${label}`,
      data,
    };
  });
};

export const formatAllPlatformChartOptions = (chartData) => {
  if (chartData) {
    // Get array of all labels
    let getAllLable = getAllPlatformLabels(chartData);
    return getAllLable.map((label: any) => ({
      ...commonYAxisOptions,
      id: label,
    }));
  }
  return [];
};

export const calculatePercentageData = (chartdata: any, getAllLabel: any) => {
  let chartDataLength = chartdata.length;
  let labelLength = getAllLabel.length;
  let updatedChartData = [...chartdata];
  for (let i = 0; i < labelLength; i++) {
    let sum = 0;
    for (let j = 0; j < chartDataLength; j++) {
      sum += chartdata[j].data[i];
    }
    for (let k = 0; k < chartDataLength; k++) {
      updatedChartData[k].data[i] =
        chartdata[k].data[i] > 0
          ? parseFloat(((chartdata[k].data[i] * 100) / sum).toFixed(2))
          : 0;
    }
  }
  return updatedChartData;
};

export const getPlatformName = (platform: string) => {
  if (platform.toLowerCase().includes("linkedin")) {
    return "Linkedin";
  } else if (platform.toLowerCase().includes("facebook")) {
    return "Facebook";
  } else if (platform.toLowerCase().includes("microsoft")) {
    return "Microsoft";
  } else if (platform.toLowerCase().includes("google")) {
    return "Google";
  } else if (platform.toLowerCase().includes("dv360")) {
    return "Dv360";
  } else if (platform.toLowerCase().includes("tiktok")) {
    return "TikTok";
  } else if (platform.toLowerCase().includes("apple")) {
    return "Apple";
  } else if (platform.toLowerCase().includes("pinterest")) {
    return "Pinterest";
  } else {
    return "";
  }
};

export const checkPlatformSubType = (platform: string) => {
  if (platform) {
    if (
      platform.toLowerCase().includes("facebook") ||
      platform.toLowerCase().includes("tiktok") ||
      platform.toLowerCase().includes("pinterest")
    ) {
      return "adset";
    } else {
      return "campaign";
    }
  }
};

export const getInitials = (string: string) => {
  let seperators = [" ", "_", "-"];
  const firstCharacter = string.charAt(0);
  for (let index = 0; index < seperators.length; index++) {
    const seperator = seperators[index];
    const indexOfSeperator = string.indexOf(seperator);
    if (indexOfSeperator > -1) {
      return `${
        firstCharacter + string.charAt(indexOfSeperator + 1)
      }`.toUpperCase();
    }
  }
  for (let index = 1; index < string.length; index++) {
    const charCode = string.charCodeAt(index);
    if (charCode > 64 || charCode < 91) {
      return `${firstCharacter + string.charAt(index)}`.toUpperCase();
    }
  }
  return `${firstCharacter + string.charAt(1)}`.toUpperCase();
};

export const getRoundedValue = (value: number | string) => {
  return value && value.toString()
    ? parseFloat(value.toString()).toFixed(2)
    : value;
};

const isInt = (n) => n % 1 === 0;

export const amountValue = (num) => {
  if (num < 1) {
    return getRoundedValue(num);
  } else if (num < 1000) return `${num.toFixed(2)}`;
  else if (num >= 1000 && num < 1000000)
    return `${parseInt(num / 1000)}.${parseInt((num % 1000) / 100)}K`;
  else if (num >= 1000000 && num < 1000000000) {
    return `${parseInt(num / 1000000)}.${parseInt((num % 1000000) / 10000)}M`;
  } else if (num >= 1000000000)
    return `${parseInt(num / 1000000000)}.${parseInt(
      (num % 1000000000) / 10000000
    )}B`;
  return num;
};

export const getDecimalValues = (number: any) => {
  if (number % 1 > 0) {
    if (number.toString().split(".")[1].length > 1) {
      return Number(parseFloat(number).toFixed(2));
    } else {
      return Number(parseFloat(number).toFixed(1));
    }
  } else {
    return Number(number);
  }
};

export const equalsCheckArray = (a, b) =>
  JSON.stringify(a) === JSON.stringify(b);

// TODO: all the below 4 functions (isAdminRole/isPublisherRole/isEditorRole/isViewerRole)
// is replaced by 'getRolesHierarchy' function but used in some places hence keeping it
export const isAdminRole = (rolesData) => {
  if (
    rolesData.map((role) => role.role).includes("global-admin") ||
    rolesData.map((role) => role.role).includes("brand-admin") ||
    rolesData.map((role) => role.role).includes("agency-admin")
  ) {
    return true;
  }
};

export const isPublisherRole = (rolesData) => {
  if (
    rolesData.map((role) => role.role).includes("global-publisher") ||
    rolesData.map((role) => role.role).includes("brand-publisher") ||
    rolesData.map((role) => role.role).includes("agency-publisher")
  ) {
    return true;
  }
};

export const isEditorRole = (rolesData) => {
  if (
    rolesData.map((role) => role.role).includes("global-editor") ||
    rolesData.map((role) => role.role).includes("brand-editor") ||
    rolesData.map((role) => role.role).includes("agency-editor")
  ) {
    return true;
  }
};

export const isViewerRole = (rolesData) => {
  if (
    rolesData.map((role) => role.role).includes("global-viewer") ||
    rolesData.map((role) => role.role).includes("brand-viewer") ||
    rolesData.map((role) => role.role).includes("agency-viewer")
  ) {
    return true;
  }
};
export const isBrandAdminRole = (rolesData) => {
  // if (rolesData.map((role) => role.role).includes("brand-admin")) {
  //   return true;
  // }
  const roles = rolesData.map((role) => role.role);
  console.log(
    "line 776",
    roles.includes("brand-admin") &&
      !roles.includes("agency-admin") &&
      !roles.includes("global-admin")
  );
};
export const getAdminRoleHierarchy = (rolesData) => {
  return rolesData
    ?.map((role) => role?.role)
    .find((item) => item === "global-admin")
    ? "global"
    : rolesData
        ?.map((role) => role?.role)
        .find((item) => item === "agency-admin")
    ? "agency"
    : rolesData
        ?.map((role) => role?.role)
        .find((item) => item === "brand-admin")
    ? "brand"
    : "";
};
export const getRolesHierarchy = (rolesData) => {
  return (
    rolesData
      ?.map((role) => role?.role)
      // .find((item) => item.endsWith("-admin"))
      .find(
        (item) =>
          item === "global-admin" ||
          item === "agency-admin" ||
          item === "brand-admin"
      )
      ? "admin"
      : rolesData
          ?.map((role) => role?.role)
          // .find((item) => item.endsWith("-publisher"))
          .find(
            (item) =>
              item === "global-publisher" ||
              item === "agency-publisher" ||
              item === "brand-publisher"
          )
      ? "publisher"
      : rolesData
          ?.map((role) => role?.role)
          // .find((item) => item.endsWith("-editor"))
          .find(
            (item) =>
              item === "global-editor" ||
              item === "agency-editor" ||
              item === "brand-editor"
          )
      ? "editor"
      : rolesData
          ?.map((role) => role?.role)
          // .find((item) => item.endsWith("-viewer"))
          .find(
            (item) =>
              item === "global-viewer" ||
              item === "agency-viewer" ||
              item === "brand-viewer"
          )
      ? "viewer"
      : ""
  );
};

export const hasFinancePermission = (rolesData) => {
  // do not render 'billing dashboard' option if api is not loaded
  if (!rolesData) return false;
  if (rolesData.some((role) => role.role.includes("finance"))) {
    return true;
  }
};

export const hasAISandboxPermission = (rolesData) => {
  if (!rolesData) return false;
  if (rolesData.some((role) => role.role.includes("admin"))) {
    return true;
  }
};
