import { Box, IconButton, Skeleton } from "@mui/material";
import React from "react";
import { StyledVariantBottom, StyledVariantHeader } from "./styled";
import { CheckBox } from "components/shared";
import { ReactComponent as Edit } from "../../../../../assets/cai/svg/edit_icon.svg";
import { ReactComponent as Delete } from "../../../../../assets/cai/svg/delete_icon.svg";
import { ReactComponent as Copy } from "../../../../../assets/cai/svg/copy_icon.svg";

export default function VariantCardLoading() {
  return (
    <Box display="flex" flexDirection="column" minWidth={0}>
      <StyledVariantHeader>
        <Skeleton
          sx={{
            width: "2.6875em",
            height: "2.6875em",
            borderRadius: "5.75em",
          }}
          variant="circular"
        />
        <Box display="flex" flexDirection="column" flex={1} minWidth={0}>
          <Skeleton height="1.25em" width="100%" variant="text" />
          <Box pt="0.125em" />
          <Skeleton height="1.25em" width="60%" variant="text" />
        </Box>
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: "6px",
            height: "1.4em",
            width: "1.4em",
            margin: "0em .4em",
          }}
        />
      </StyledVariantHeader>
      <StyledVariantBottom>
        <Box flex={1} minHeight={0} width="95%" p=".5em 0em .8em 0em">
          <Skeleton
            height="100%"
            width="100%"
            variant="rectangular"
            sx={{
              borderRadius: "8px",
            }}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          pt=".35em"
          mb=".5em"
          gap="1em"
        >
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: "6px",
              height: "2em",
              width: "2em",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: "6px",
              height: "2em",
              width: "2em",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: "6px",
              height: "2em",
              width: "2em",
            }}
          />
        </Box>
      </StyledVariantBottom>
    </Box>
  );
}
