import "../../index.scss";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { AdAccountState } from "store/types/adaccount";
import { useSelector } from "react-redux";
import { platformMapper } from "utils/constants/platforms";
import { GroupType } from "types/GlobalTypes";
import DailyPacingText from "./DailyPacingText";
import { getDecimalValues } from "utils/commonFunctions";

const SmallValue = styled.div`
  font-weight: 500;
  font-size: 0.625em;
  line-height: 0.75em;
  color: #838383;
  margin-top: 0.3em;
`;

type BudgetTableType = {
  fields: any;
  isActive: any;
  replace: any;
  register: any;
  Controller: any;
  control: any;
  setDirty: Function;
  type: GroupType.group | GroupType.parentGroup;
  isEdit: boolean;
  selectedBrand: any;
  subPacingType: string;
};

function TableHeaders(
  formatedHeaderData: any,
  type: GroupType,
  subPacingType: string
) {
  const { platforms } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  let headerArray: any = [];
  let secondaryHeaderArray: any =
    type === GroupType.parentGroup
      ? [
          <>
            <TableCell
              align="center"
              className="border-right-separator cell-value"
              style={{ top: 35 }}
            >
              Min Budget
              <DailyPacingText subPacingType={subPacingType} />
            </TableCell>
            <TableCell
              align="center"
              className="border-right-separator cell-value"
              style={{ top: 35 }}
            >
              Max Budget
              <DailyPacingText subPacingType={subPacingType} />
            </TableCell>
          </>,
        ]
      : [];
  Object.keys(formatedHeaderData).forEach((key: string) => {
    const platform = platforms.find(
      (platform) => platform.id === formatedHeaderData[key].platformId
    );
    headerArray.push(
      <TableCell
        align="center"
        colSpan={2}
        className="backgroud-light border-right-separator border-bottom-none common-header"
      >
        <div className="flex-center">
          {platform && (
            <Stack justifyContent="center" alignItems="center">
              <img
                src={platformMapper[platform.name]?.icon}
                style={{ width: "1rem", height: "1rem" }}
              />
              {platformMapper[platform.name]?.shortName && (
                <Box
                  sx={{
                    fontSize: "0.5rem",
                    lineHeight: "0.5rem",
                  }}
                >
                  {platformMapper[platform.name].shortName}
                </Box>
              )}
            </Stack>
          )}

          {/* <FBIcon /> */}
          <div className="text-left ml-2">
            <div className="text-truncate">
              {formatedHeaderData[key].adAccountName}
            </div>
            <SmallValue>{formatedHeaderData[key].adAccountId}</SmallValue>
          </div>
        </div>
      </TableCell>
    );
    secondaryHeaderArray.push(
      <>
        <TableCell
          align="center"
          className="border-right-separator cell-value"
          style={{ top: 35 }}
        >
          Min Budget
          <DailyPacingText subPacingType={subPacingType} />
        </TableCell>
        <TableCell
          align="center"
          className="border-right-separator cell-value"
          style={{ top: 35 }}
        >
          Max Budget
          <DailyPacingText subPacingType={subPacingType} />
        </TableCell>
      </>
    );
  });

  return (
    <>
      <TableRow>
        <TableCell
          align="center"
          className="backgroud-light border-right-separator border-bottom-none common-header left-sticky z-30"
          style={{ minWidth: "10em" }}
        >
          AI Group Name
        </TableCell>
        {type === GroupType.parentGroup ? (
          <TableCell
            align="center"
            colSpan={2}
            className="backgroud-light border-right-separator border-bottom-none common-header"
          >
            Overall
          </TableCell>
        ) : (
          " "
        )}
        {headerArray}
      </TableRow>
      <TableRow>
        <TableCell
          align="center"
          className="border-right-separator left-sticky z-30"
          style={{ top: 35 }}
        ></TableCell>
        {secondaryHeaderArray}
      </TableRow>
    </>
  );
}

function BudgetPlanTable({
  replace,
  fields,
  register,
  Controller,
  control,
  isActive,
  setDirty,
  type,
  isEdit,
  selectedBrand,
  subPacingType,
}: BudgetTableType) {
  const formatedHeaderData: any = fields.reduce(
    (previous: any, eachData: any) => {
      eachData?.subOptimizers?.forEach(
        ({ adAccountId, adAccountName, platformId }: any) => {
          previous[platformId + "_" + adAccountId] = {
            adAccountId,
            adAccountName,
            platformId,
          };
        }
      );
      return previous;
    },
    {}
  );

  return (
    <TableContainer sx={{ maxHeight: 400 }} className="table-budget-breakdown">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="overflow-auto w-100"
      >
        <TableHead>
          {TableHeaders(formatedHeaderData, type, subPacingType)}
        </TableHead>
        <TableBody className={isActive || !isEdit ? "section-disable" : ""}>
          {(fields as any).map((eachData: any, index: number) => {
            return (
              <TableRow key={eachData.id}>
                <TableCell className="border-right-separator border-bottom-none common-header left-sticky bg-white z-20">
                  {eachData.name}
                </TableCell>
                {type === GroupType.parentGroup ? (
                  <>
                    <TableCell className="border-right-separator border-bottom-none cell-value">
                      <Box className="position-relative">
                        <span className="currency-prefix">
                          {selectedBrand?.currencySymbol}
                        </span>
                        <Controller
                          name={`optimizers.${index}.minBudget`}
                          control={control}
                          render={({ field }: any) => {
                            // sending integer instead of string.
                            return (
                              <input
                                type="number"
                                className=" form-control form-control-md text-md value-item"
                                {...field}
                                step={"any"}
                                value={getDecimalValues(field.value)}
                                defaultValue={getDecimalValues(field.value)}
                                onChange={(e) => {
                                  setDirty(true);
                                  field.onChange(
                                    getDecimalValues(e.target.value)
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className="border-right-separator border-bottom-none cell-value">
                      <Box className="position-relative">
                        <span className="currency-prefix">
                          {selectedBrand?.currencySymbol}
                        </span>
                        <Controller
                          name={`optimizers.${index}.maxBudget`}
                          control={control}
                          render={({ field }: any) => {
                            // sending integer instead of string.
                            return (
                              <input
                                type="number"
                                className=" form-control form-control-md text-md value-item"
                                {...field}
                                step={"any"}
                                value={getDecimalValues(field.value)}
                                defaultValue={getDecimalValues(field.value)}
                                onChange={(e) => {
                                  setDirty(true);
                                  field.onChange(
                                    getDecimalValues(e.target.value)
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </Box>
                    </TableCell>
                  </>
                ) : (
                  " "
                )}
                {Object.keys(formatedHeaderData).map((eachSubData: string) => {
                  const subOptimizers = eachData.subOptimizers.reduce(
                    (previous: any, each: any, index: number) => {
                      previous[each.platformId + "_" + each.adAccountId] = {
                        ...each,
                        index,
                      };
                      return previous;
                    },
                    {}
                  );
                  const subIndex = subOptimizers[eachSubData]?.index;
                  const subdata = fields[index].subOptimizers[subIndex];
                  return (
                    <>
                      <TableCell
                        key={`${eachData.id}${eachSubData}MinBudget`}
                        className="border-right-separator border-bottom-none cell-value"
                      >
                        <Box className="position-relative">
                          {subIndex >= 0 ? (
                            <>
                              <span className="currency-prefix">
                                {selectedBrand?.currencySymbol}
                              </span>
                              <Controller
                                name={`optimizers.${index}.subOptimizers.${subIndex}.minBudget`}
                                control={control}
                                render={({ field }: any) => {
                                  // sending integer instead of string.
                                  return (
                                    <input
                                      type="number"
                                      className=" form-control form-control-md text-md value-item"
                                      {...field}
                                      step={"any"}
                                      value={getDecimalValues(field.value)}
                                      defaultValue={getDecimalValues(
                                        field.value
                                      )}
                                      onChange={(e) => {
                                        setDirty(true);
                                        field.onChange(
                                          getDecimalValues(e.target.value)
                                        );
                                      }}
                                    />
                                  );
                                }}
                              />
                            </>
                          ) : (
                            "NA"
                          )}
                        </Box>
                      </TableCell>
                      <TableCell
                        key={`${eachData.id}${eachSubData}MaxBudget`}
                        className="border-right-separator border-bottom-none cell-value"
                      >
                        <Box className="position-relative">
                          {subIndex >= 0 ? (
                            <>
                              <span className="currency-prefix">
                                {selectedBrand?.currencySymbol}
                              </span>
                              <Controller
                                name={`optimizers.${index}.subOptimizers.${subIndex}.maxBudget`}
                                control={control}
                                render={({ field }: any) => {
                                  // sending integer instead of string.
                                  return (
                                    <input
                                      type="number"
                                      className=" form-control form-control-md text-md value-item"
                                      {...field}
                                      step={"any"}
                                      value={getDecimalValues(field.value)}
                                      defaultValue={getDecimalValues(
                                        field.value
                                      )}
                                      onChange={(e) => {
                                        setDirty(true);
                                        field.onChange(
                                          getDecimalValues(e.target.value)
                                        );
                                      }}
                                    />
                                  );
                                }}
                              />
                            </>
                          ) : (
                            "NA"
                          )}
                        </Box>
                      </TableCell>
                    </>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BudgetPlanTable;
