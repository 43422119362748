import HttpService from "../../../HttpService/HttpService";
import { aiGroups } from "MappedAPI/MappedAPI";
import { call, takeLatest } from "redux-saga/effects";
import { CROSS_PLATFORM_URL } from "../../../utils/path";
import moment from "moment";
import {
  CREATE_AI_GROUP_PLANNER,
  DELETE_AI_GROUP_BUDGET_PLANNER,
  GET_AI_GROUP_PLANNERS,
  GET_AI_GROUP_PLANNER_DETAILS,
  UPDATE_AI_GROUP_PLANNER,
} from "store/types/BudgetPlannerAiGroup";
import {
  GET_AIGROUP_PLANNER_DETAILS_FAKE,
  GET_GROUP_CONFIG,
} from "../../types/BudgetPlannerAiGroup";

function* getAiGroupPlanners(action: any): any {
  let {
    payload: { aiGroupId },
    callback,
  } = action;
  try {
    const urlParams = `${aiGroups}${aiGroupId}/budget-planners?status=ACTIVE,SCHEDULE,UNCONFIGURED,ABORTED`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getAiGroupPlannerDetails(action: any): any {
  let {
    payload: { aiGroupId, budgetPlannerId },
    callback,
  } = action;
  try {
    const urlParams = `${aiGroups}${aiGroupId}/budget-planners/${budgetPlannerId}/constraints`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getAiGroupPlannerDetailsFake(action: any): any {
  let {
    payload: { aiGroupId, budgetPlannerId },
    callback,
  } = action;
  try {
    const urlParams = `${aiGroups}${aiGroupId}/budget-planners/fake/constraints`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* deleteAiGroupBudgetPlanner(action: any): any {
  let {
    payload: { aiGroupId, budgetPlannerId, user },
    callback,
  } = action;
  try {
    const urlParams = `${aiGroups}${aiGroupId}/budget-planners/${budgetPlannerId}`;
    const response = yield call(
      HttpService.delete,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* createAiGroupPlanner(action: any): any {
  let {
    payload: { payload, user, aiGroupId },
    callback,
  } = action;
  try {
    let newPayload = { ...payload };
    let startDate = moment(payload.startDate).format("YYYY-MM-DD");
    let endDate = moment(payload.endDate).format("YYYY-MM-DD");

    newPayload.startDate = moment(startDate).format("YYYY-MM-DD").toString();
    newPayload.endDate = moment(endDate).format("YYYY-MM-DD").toString();
    const urlParams = `${aiGroups}${aiGroupId}/budget-planners`;
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      newPayload
    );
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*postAiGroupBudgetPlanner ~ error", error);
    callback && callback(error.response, true);
  }
}

function* updateAiGroupPlanner(action: any): any {
  let {
    payload: { budgetPlannerId, aiGroupId, payload, user },
    callback,
  } = action;
  try {
    const urlParams = `${aiGroups}${aiGroupId}/budget-planners/${budgetPlannerId}/constraints`;

    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      payload
    );
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*postCampaignFactors ~ error", error);
    callback && callback(error.response, true);
  }
}
function* getAiGroupConfig(action: any): any {
  let {
    payload: { aiGroupId },
    callback,
  } = action;
  try {
    const urlParams = `${aiGroups}${aiGroupId}/optimisation-setting-check`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}
export function* AiGroupWatcher() {
  yield takeLatest(DELETE_AI_GROUP_BUDGET_PLANNER, deleteAiGroupBudgetPlanner);
  yield takeLatest(GET_AI_GROUP_PLANNERS, getAiGroupPlanners);
  yield takeLatest(GET_AI_GROUP_PLANNER_DETAILS, getAiGroupPlannerDetails);
  yield takeLatest(
    GET_AIGROUP_PLANNER_DETAILS_FAKE,
    getAiGroupPlannerDetailsFake
  );
  yield takeLatest(CREATE_AI_GROUP_PLANNER, createAiGroupPlanner);
  yield takeLatest(UPDATE_AI_GROUP_PLANNER, updateAiGroupPlanner);
  yield takeLatest(GET_GROUP_CONFIG, getAiGroupConfig);
}
