import styled from "styled-components";



export const StyledFooterDiv = styled.div`
  background: #ffffff;
  position: relative;
  left:-24px;
  width:calc(100% + 64px);
  height: 64px;
  padding: 12px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top:1px solid #E5E5E5;
  .footer-btn {
    border-radius: 12px;
    font-weight: 600;
    /* need to find another way instead of putting !important */
    font-size: 16px !important;
    line-height: 24px;
  }
`;

export const StyledRecommendationFooterDiv=styled.div`
background: #ffffff;
position: relative;
// width:calc(100% + 64px);
height: 64px;
padding: 12px 32px;
display: flex;
justify-content: space-between;
align-items: center;
border-top:1px solid #E5E5E5;
.footer-btn {
  border-radius: 12px;
  font-weight: 600;
  /* need to find another way instead of putting !important */
  font-size: 16px !important;
  line-height: 24px;
}
`

export const StyledRightElement = styled.div`
  display: flex;
  align-items: center;
  height:100%;
  justify-content:flex-end;
`;

export const StyledMiddleElement = styled.div`
  align-items: center;
  display: flex;
  margin-left: 40%;
`;

export const StyledPaginatedText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
`;

export const StyledTextVariant1 = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-right: 24px;
  color: #333333;
`;
