import { Box, styled } from "@mui/system";

export const StyledApproverSectionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0.7em 0.5em;
`;

export const StyledApproverSubSectionBox = styled(Box)`
  display: flex;
  background: #f7f7f7;
  border-radius: 0.5em;
  margin-top: 0.5em;
  /* padding: 0.5em; */
`;

export const StyledApproverDivider = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0.5em;
`;
