import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StyledApproverBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const StyledApproverBodyBox = styled(Box)`
  display: flex;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 0.5em;
  padding: 1.5em;
  overflow: hidden;
`;

export const StyledLeftPanelBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-left: 0.5em;
`;

export const StyledRightPanelBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-right: 0.5em;
  min-width: 372px;
`;

export const StyledSubApproverBox = styled(Box)`
  display: flex;
  background: #ffffff;
  border-radius: 0.5625em;
  padding: 1.5em 0.2em;
  margin-top: 1.5em;
  overflow: hidden;
`;

export const StyledSubApproverMainBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const StyledMediaBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0em 1em;
`;

export const StyledMainMediaBox = styled(Box)`
  border: 1px dashed #d1d1d1;
  border-radius: 0.5em;
  margin: 0.9em 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 26em;
  height: 26em;
`;

export const StyledImageWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledIframeBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 0.5625em;
  padding: 1.5em;
  margin-top: 1.5em;
  height: 100%;
`;

export const StyledPlaceHolderBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0em 1em;
  justify-content: space-between;
  height: 100%;
`;

export const StyledPlaceHolderImageBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0em 1em;
  flex: 1;
  justify-content: center;
`;
