import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Comment, StyledText, StyledVerticalDivider } from "components/shared";
import { useDispatch, useSelector } from "react-redux";
import { updateComments } from "store/actions/CreativeAI/shared/Review/review";
import {
  CommentKey,
  ReviewInitialState,
} from "store/types/CreativeAI/shared/Review/review";
import { usePostResponseHook } from "../hooks/postHook";

import {
  StyledApproverSectionBox,
  StyledApproverSubSectionBox,
} from "./styled";
import { useState } from "react";
import PerformanceTooltip from "components/CreativeAI_Revamp/shared/PerformanceTooltip/PerformanceTooltip";
import PerformanceScoreInfo from "../../shared/PerformanceScore/PerformanceScoreInfo";

interface FBApproverSectionsProps {
  title: string;
  subtitle: string;
  commentKey: CommentKey;
  mainKey: string;
}

export default function FBApproverSections({
  title,
  subtitle,
  commentKey,
  mainKey,
}: FBApproverSectionsProps) {
  const dispatch = useDispatch();
  const { updatePostComments } = usePostResponseHook();
  const {
    requestedVariantData: { data, isLoading },
  } = useSelector(
    (state: { reviewReducer: ReviewInitialState }) => state.reviewReducer
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePerformancePopoverOpen = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };

  const handlePerformancePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPerformanceTooltip = Boolean(anchorEl);

  return (
    <StyledApproverSectionBox>
      <StyledText
        fontSize="0.875em"
        fontWeight={600}
        lineHeight="17px"
        color="#444444"
      >
        {title}
      </StyledText>
      <StyledApproverSubSectionBox>
        <Box display="flex" flexDirection="column" flex={5}>
          {data?.payload?.recommendationMetaData?.[mainKey]?.data?.[0]
            ?.performance_labels && (
            <PerformanceScoreInfo
              handlePerformancePopoverOpen={handlePerformancePopoverOpen}
              handlePerformancePopoverClose={handlePerformancePopoverClose}
              infoItem={
                data?.payload?.recommendationMetaData?.[mainKey]?.data?.[0]
              }
            />
          )}
          {isLoading ? (
            <SkeletonFbSharedLoading />
          ) : (
            <StyledText
              fontSize="0.75em"
              fontWeight={400}
              lineHeight="15px"
              color="#333333"
              p=".75em .5em 1em 1em"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              {subtitle}
            </StyledText>
          )}
        </Box>

        <StyledVerticalDivider
          height="100%"
          sx={{
            margin: "0em 2%",
          }}
        />
        <Box flex={5} p=".5em .5em .5em 0em">
          {isLoading ? (
            <Box
              sx={{
                background: "white",
                padding: ".3em .4em ",
                borderRadius: "0.25em",
              }}
            >
              <SkeletonFbSharedLoading />
            </Box>
          ) : (
            <Comment
              onBlurFunction={(value) => {
                if (value !== data?.variantMetaInfo?.[commentKey]?.text) {
                  dispatch(
                    updateComments({
                      key: commentKey,
                      payloadData: {
                        text: value,
                        isLoading: true,
                      },
                    })
                  );
                  updatePostComments({ commentKey, value });
                }
              }}
              disabled={!!data?.variantMetaInfo?.billboardMediaId}
              localValue={data?.variantMetaInfo?.[commentKey]?.text ?? ""}
              loading={data?.variantMetaInfo?.[commentKey]?.isLoading}
            />
          )}
        </Box>
      </StyledApproverSubSectionBox>
      {data?.payload?.recommendationMetaData?.[mainKey]?.data?.[0]
        ?.performance_labels && (
        <PerformanceTooltip
          open={openPerformanceTooltip}
          anchorEl={anchorEl}
          infoItem={data?.payload?.recommendationMetaData?.[mainKey]?.data?.[0]}
          place="editor"
        />
      )}
    </StyledApproverSectionBox>
  );
}

function SkeletonFbSharedLoading() {
  return (
    <Box
      flex={5}
      sx={{
        padding: ".2em .3em",
      }}
    >
      <Typography variant="caption">
        <Skeleton width="100%" />
      </Typography>
      <Typography variant="caption">
        <Skeleton width="80%" />
      </Typography>
      <Typography variant="caption">
        <Skeleton width="60%" />
      </Typography>
    </Box>
  );
}
