import HttpService from "HttpService/HttpService";
import { BASE_URL } from "utils/path";

export const resetLinks = async (brandId: string, type: string) => {
  let response = {
    success: false,
    message: "",
    data: [],
  };
  try {
    let urlParams = `/v1/api/brands/${brandId}/adaccounts/insights/reset?type=${type}`;
    response = await HttpService.get(BASE_URL, urlParams).then((data: any) => {
      return data.data;
    });
    return response;
  } catch (error: any) {
    console.log("resetLinks => ", error.message);
  }
  return response;
};
