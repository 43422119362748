import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { STATUS } from "./GoogleSheets/constants";

function StatusChip({ type }: { type: string }) {
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: STATUS[type]?.background,
        padding: "4px 8px",
        borderRadius: "6px",
        justifyContent: "center",
        alignItems: "center",
        height: "24px",
        width: "fit-content",
      }}
    >
      <Box sx={{ marginRight: "4px" }}>{STATUS[type]?.svg}</Box>
      <Typography
        fontSize="10px"
        fontWeight="500px"
        fontFamily="Inter"
        color={STATUS[type]?.color}
      >
        {STATUS[type]?.title}
      </Typography>
    </Stack>
  );
}

export default StatusChip;
