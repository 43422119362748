import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPerformanceSummary,
  setPerformanceSummaryLoader,
  setPreviousPerformanceSummaryLoader,
} from "../../../../store/actions/ActionDebugger/ActionSummary";
import { ActionDebuggerInitStateType } from "../../../../store/types/ActionDebugger/ActionDebugger";
import { ActionDebuggerHelperInitState } from "../../../../store/types/ActionDebugger/ActionDebuggerHelper";
import { ActionSummaryInitStateType } from "../../../../store/types/ActionDebugger/ActionSummary";
import { initialState } from "../../../../store/types/auth";
import { getCurrencySymbol } from "../../../../utils/commonFunctions";
import { yyyyMMDDFormat } from "../../../../utils/date.util";
import {
  calculatePercentageValue,
  currencyFormatter,
} from "../../shared/utils/common.utils";
import { CustomizedDivider, StyledTitleVariant2 } from "../styled";
import PerformanceSummaryCard from "./PerformanceSummaryCard";
import { Skeleton } from "@mui/material";
import { StyledPerformanceSummaryItemCard } from "./styled";
import { defaultSummaryItems } from "../../shared/constFile";

export default function PerformanceSummary({
  parentLoading,
}: {
  parentLoading?: boolean;
}) {
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useDispatch();
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const {
    previousPeriod,
    isCompare,
    currentPeriod,
    metricMapper,
    elementMetrics,
    metricLoader,
    attributionWindow,
  } = useSelector(
    (state: { ActionDebuggerReducer: ActionDebuggerInitStateType }) =>
      state.ActionDebuggerReducer
  );

  const {
    performanceSummary,
    previousPerformanceSummary,
    performanceSummaryLoader,
    previousPerformanceSummaryLoader,
  } = useSelector(
    (state: { ActionSummaryReducer: ActionSummaryInitStateType }) =>
      state.ActionSummaryReducer
  );
  const {
    selectedAdAccount,
    selectedCampaign,
    selectedAdSet,
    selectedAIGroup,
  } = useSelector(
    (state: { ActionDebuggerHelperReducer: ActionDebuggerHelperInitState }) =>
      state.ActionDebuggerHelperReducer
  );

  const getPreviousPerformanceSummary = () => {
    dispatch(setPreviousPerformanceSummaryLoader(true));
    dispatch(
      getPerformanceSummary({
        elementId: selectedAdAccount?.selectedAdAcc?.elementId,
        start_date: yyyyMMDDFormat(previousPeriod.startDate),
        end_date: yyyyMMDDFormat(previousPeriod.endDate),
        typeOfData: "SET_PREVIOUS_PERFORMANCE_SUMMARY",
        typeOfLoader: "SET_PREVIOUS_PERFORMANCE_SUMMARY_LOADER",
        adsetIds:
          selectedAdAccount?.platform?.name &&
          (selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") ||
            selectedAdAccount?.platform.name?.toLowerCase().includes("tiktok"))
            ? selectedAdSet
            : [],
        campaignIds:
          selectedAdAccount?.platform?.name &&
          !selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") &&
          !selectedAdAccount?.platform.name?.toLowerCase().includes("tiktok")
            ? selectedCampaign
            : [],
        attributionWindow:
          selectedAdAccount?.platform?.name &&
          selectedAdAccount?.platform.name?.toLowerCase().includes("facebook")
            ? attributionWindow
            : "",
      })
    );
  };

  const getCurrentPerformanceSummary = () => {
    dispatch(setPerformanceSummaryLoader(true));
    dispatch(
      getPerformanceSummary({
        elementId: selectedAdAccount?.selectedAdAcc?.elementId,
        start_date: yyyyMMDDFormat(currentPeriod.startDate),
        end_date: yyyyMMDDFormat(currentPeriod.endDate),
        typeOfData: "SET_PERFORMANCE_SUMMARY",
        typeOfLoader: "SET_PERFORMANCE_SUMMARY_LOADER",
        adsetIds:
          selectedAdAccount?.platform?.name &&
          (selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") ||
            selectedAdAccount?.platform.name?.toLowerCase().includes("tiktok"))
            ? selectedAdSet
            : [],
        campaignIds:
          selectedAdAccount?.platform?.name &&
          !selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") &&
          !selectedAdAccount?.platform.name?.toLowerCase().includes("tiktok")
            ? selectedCampaign
            : [],
        attributionWindow:
          selectedAdAccount?.platform?.name &&
          selectedAdAccount?.platform.name?.toLowerCase().includes("facebook")
            ? attributionWindow
            : "",
      })
    );
  };

  useEffect(() => {
    if (selectedAdAccount?.selectedAdAcc?.elementId && isInitialized) {
      getCurrentPerformanceSummary();
    }
  }, [currentPeriod]); //

  useEffect(() => {
    if (
      isCompare &&
      selectedAdAccount?.selectedAdAcc?.elementId &&
      isInitialized
    ) {
      getPreviousPerformanceSummary();
    }
  }, [previousPeriod]); //

  useEffect(() => {
    let platformValue = (selectedAdAccount?.platform?.name as string) ?? "";
    platformValue = platformValue.toLowerCase();
    if (
      platformValue.includes("facebook") &&
      selectedAdSet.length > 0 &&
      attributionWindow !== ""
    ) {
      getCurrentPerformanceSummary();
      getPreviousPerformanceSummary();

      setIsInitialized(true);
    }
  }, [selectedAdSet, attributionWindow]);

  useEffect(() => {
    let platformValue = (selectedAdAccount?.platform?.name as string) ?? "";
    platformValue = platformValue.toLowerCase();
    if ((platformValue.includes("tiktok") ) && selectedAdSet.length > 0) {
      getCurrentPerformanceSummary();
      getPreviousPerformanceSummary();

      setIsInitialized(true);
    }
  }, [selectedAdSet]);

  useEffect(() => {
    let platformValue = (selectedAdAccount?.platform?.name as string) ?? "";
    platformValue = platformValue.toLowerCase();

    if ((platformValue.includes("google")||platformValue.includes("apple")|| platformValue.includes("dv360")) && selectedCampaign?.length > 0) {
      getCurrentPerformanceSummary();
      getPreviousPerformanceSummary();
      setIsInitialized(true);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedAdSet.length === 0 && selectedCampaign?.length === 0) {
      dispatch({ type: "SET_PERFORMANCE_SUMMARY", payload: [] });
      dispatch({ type: "SET_PREVIOUS_PERFORMANCE_SUMMARY", payload: [] });
    }
  }, [selectedAdSet, selectedCampaign]);

  const performanceSkeletonArray = useMemo(
    () => Array.apply(null, Array(3)),
    []
  );

  useEffect(() => {
    if (!selectedAIGroup?.id) {
      dispatch({ type: "SET_PERFORMANCE_SUMMARY", payload: [] });
      dispatch({ type: "SET_PREVIOUS_PERFORMANCE_SUMMARY", payload: [] });
    } else if (!selectedAdAccount?.selectedAdAcc?.elementId) {
      dispatch({ type: "SET_PERFORMANCE_SUMMARY", payload: [] });
      dispatch({ type: "SET_PREVIOUS_PERFORMANCE_SUMMARY", payload: [] });
    } else if (
      selectedAdAccount?.platform?.name &&
      (!selectedAdAccount.platform.name.toLowerCase().includes("google") ||
        !selectedAdAccount.platform.name.toLowerCase().includes("facebook") ||
        !selectedAdAccount.platform.name.toLowerCase().includes("tiktok"))
    ) {
      dispatch({ type: "SET_PERFORMANCE_SUMMARY", payload: [] });
      dispatch({ type: "SET_PREVIOUS_PERFORMANCE_SUMMARY", payload: [] });
    }
  }, [selectedAIGroup, selectedAdAccount]);

  return (
    <Box pb="1em" pt=".25em">
      <StyledTitleVariant2>Performance Summary</StyledTitleVariant2>
      <CustomizedDivider />
      <Box display="flex" width="100%" gap="1em">
        {performanceSummaryLoader ||
        previousPerformanceSummaryLoader ||
        metricLoader ||
        parentLoading
          ? performanceSkeletonArray.map(() => (
              <StyledPerformanceSummaryItemCard>
                <Box>
                  <Skeleton variant="text" />
                  <Skeleton
                    variant="rectangular"
                    height={10}
                    sx={{
                      marginTop: ".2em",
                      paddingTop: "1em",
                    }}
                  />
                </Box>
              </StyledPerformanceSummaryItemCard>
            ))
          : metricMapper && Object.keys(performanceSummary).length
          ? Object.keys(performanceSummary).map((_key) => {
              let key = parseInt(_key);
              if (performanceSummary[key]) {
                const percentageValue = calculatePercentageValue(
                  Number(
                    (metricMapper[key]?.metricFormat === "Percentage" &&
                    selectedAdAccount?.platform?.name
                                  .toLowerCase()
                                  .includes("google")
                      ? performanceSummary[key] * 100
                      : performanceSummary[key]
                    )?.toFixed(2)
                  ),
                  Number(
                    (metricMapper[key]?.metricFormat === "Percentage" &&
                    selectedAdAccount?.platform?.name
                                  .toLowerCase()
                                  .includes("google")
                      ? previousPerformanceSummary[key] * 100
                      : previousPerformanceSummary[key]
                    )?.toFixed(2)
                  )
                );
                const currency =
                  metricMapper[key]?.metricFormat === "Currency"
                    ? authState?.accountDetails?.currency
                      ? getCurrencySymbol(authState?.accountDetails?.currency)
                      : ""
                    : "";
                return (
                  <PerformanceSummaryCard
                    summaryText={metricMapper[key]?.name}
                    value={
                      currency +
                      currencyFormatter(
                        metricMapper[key]?.metricFormat === "Percentage" &&
                        selectedAdAccount?.platform?.name
                                  .toLowerCase()
                                  .includes("google")
                          ? performanceSummary[key] * 100
                          : performanceSummary[key]
                      )
                    }
                    comparingData={
                      isCompare && previousPerformanceSummary[key]
                        ? {
                            metricGrowthFlag: metricMapper[key]?.growth,
                            loss: !(percentageValue > 0),
                            isZero: percentageValue === 0,
                            value: `${Math.abs(percentageValue)}%`,
                            previousPeriod: previousPeriod,
                            previousValue:
                              currency +
                              currencyFormatter(
                                metricMapper[key]?.metricFormat ===
                                  "Percentage" &&
                                  selectedAdAccount.platform.name
                                    .toLowerCase()
                                    .includes("google")
                                  ? previousPerformanceSummary[key] * 100
                                  : previousPerformanceSummary[key]
                              ),
                            difference:
                              currency +
                              currencyFormatter(
                                Math.abs(
                                  Number(
                                    (metricMapper[key]?.metricFormat ===
                                      "Percentage" &&
                                    selectedAdAccount.platform.name
                                      .toLowerCase()
                                      .includes("google")
                                      ? performanceSummary[key] * 100
                                      : performanceSummary[key]
                                    ).toFixed(2)
                                  ) -
                                    Number(
                                      (metricMapper[key]?.metricFormat ===
                                        "Percentage" &&
                                      selectedAdAccount.platform.name
                                        .toLowerCase()
                                        .includes("google")
                                        ? previousPerformanceSummary[key] * 100
                                        : previousPerformanceSummary[key]
                                      ).toFixed(2)
                                    )
                                )
                              ),
                          }
                        : undefined
                    }
                  />
                );
              } else if (
                performanceSummary[key] === 0 ||
                performanceSummary[key] === null
              ) {
                return (
                  <PerformanceSummaryCard
                    summaryText={metricMapper[key]?.name}
                    value="-"
                    hideTooltip
                  />
                );
              }
            })
          : defaultSummaryItems.map((item) => (
              <PerformanceSummaryCard
                summaryText={item}
                value="-"
                hideTooltip
              />
            ))}
      </Box>
    </Box>
  );
}
