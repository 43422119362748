import React, { useEffect, useState } from "react";
import TextArea from "../../../../../shared/Input/TextArea/TextArea";
import {
  StyledCharContainer,
  StyledCharCount,
  StyledInputWithCharCount,
  StyledMainContainer,
  StyledMainMultiContainer,
  StyledMultiInputContainer,
  StyledSuccessLoaderContainer,
  StyledTextAreaContainer,
  StyledTextContainer,
} from "./styled";
import { ReactComponent as Save } from "../../../../../../assets/svg/save.svg";
import { ReactComponent as Edit } from "../../../../../../assets/svg/edit_.svg";
import { IconButton, Radio, ClickAwayListener } from "@mui/material";
import {
  CreateVariantsState,
  Image_Recommendation,
  RecommendationMetaData,
} from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Bin } from "../../../../../../assets/svg/bin.svg";
import LoadGif from "../../../../../../assets/Gif/blueCircularLoader.gif";
import SuccessGif from "../../../../../../assets/Gif/success.gif";
import { LightTooltip } from "../../../../../shared/LightTooltip";
import TextContainer from "../MediaStudio/TextContainer";
import { updateRecommendationMetadata } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import _ from "lodash";
import ToolTip from "../../../../../../ui/Tooltip/Tooltip";
import {
  InitialMediaStudioType,
  MEDIA_STUDIO_IMAGE_PROCESSING_SUCCESS,
} from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
interface EditableTextRadioProps {
  onSave?: (
    index: number,
    value: Image_Recommendation,
    isSelected: boolean
  ) => void;
  onEdit?: (i: number) => void;
  defaultValue?: string;
  isSelected: boolean;
  backgroundColor?: string;
  borderColor?: string;
  value: Image_Recommendation;
  radioValue: string;
  hash: string;
  index: number;
  layersToShow?: Image_Recommendation;
  disabled?: boolean;
  isLoading?: boolean;
  setDirty?: () => void;
  isDeleteDisabled: boolean;
  onDelete?: (index: number, isSelected: boolean) => void;
}

export default function EditableMultiTextRadio({
  onEdit,
  onSave,
  defaultValue,
  isSelected,
  value,
  radioValue,
  hash,
  index,
  isLoading,
  setDirty,
  disabled,
  isDeleteDisabled,
  onDelete,
}: EditableTextRadioProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [showSuccessGif, toggleShowSuccessGif] = useState(false);
  const [inputState, setInputState] = useState<Image_Recommendation["data"]>(
    []
  );
  const [errorState, setErrorState] = useState<boolean[]>([]);
  const [focusIndex, setFocusIndex] = useState(-1);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { recommendationMetaData } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const setDirtyFlag = () => {
    if (setDirty) setDirty();
    onDelete && onDelete(index, isSelected);
  };
  const dispatch = useDispatch();
  const resetShowSuccess = () => {
    toggleShowSuccessGif(false);
    dispatch({ type: MEDIA_STUDIO_IMAGE_PROCESSING_SUCCESS, payload: false });
  };
  const {
    getMediaStudioData,
    isImageMediaStudioProcessing,
    isImageProcessedSuccessfully,
  } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );
  const imgLayerData = getMediaStudioData?.data?.frames[0].layers;
  const onChange = (value: string, i: number) => {
    if (imgLayerData && value.length < imgLayerData?.[i]?.minCharacters) {
      setErrorState((prev) => {
        if (prev.length) prev[i] = true;
        return prev;
      });
    } else {
      setErrorState((prev) => {
        if (prev.length) prev[i] = false;
        return prev;
      });
    }
    // if (value.length <= imgLayerData[i].max_char_limit) {
    setInputState((prev) => {
      prev[i].layer_text = value.substring(0, imgLayerData?.[i]?.maxCharacters);
      return [...prev];
    });
    // }
  };
  useEffect(() => {
    if (inputState.length > 0) {
      let localrecommendationMetaData: RecommendationMetaData = {
        ...recommendationMetaData,
      };
      localrecommendationMetaData.image.data[index].data = inputState;
      // dispatch(
      //   updateRecommendationMetadata({ data: localrecommendationMetaData })
      // );
    }
  }, [inputState]);
  useEffect(() => {
    setShowLoader((isImageMediaStudioProcessing && isSelected) || false);
    toggleShowSuccessGif((isSelected && isImageProcessedSuccessfully) || false);
  }, [
    isImageMediaStudioProcessing,
    isImageProcessedSuccessfully,
    isSelected,
    isLoading,
  ]);

  useEffect(() => {
    if (
      !recommendationMetaData?.isEditing ||
      recommendationMetaData?.isDeleting ||
      inputState.length === 0
    ) {
      setInputState(recommendationMetaData[hash].data[index]?.data);
      setErrorState(
        Array(recommendationMetaData[hash].data[index]?.data.length).fill(false)
      );
    }
  }, [recommendationMetaData]);

  const Loader = () => {
    return (
      <LightTooltip
        arrow={true}
        placement="top"
        title="Preview under generation"
      >
        <img src={LoadGif} alt="" style={{ height: 20, width: 20 }} />
      </LightTooltip>
    );
  };

  const Success = ({ cb }: { cb: () => void }) => {
    return (
      <LightTooltip arrow={true} placement="top" title="Preview available">
        <ClickAwayListener onClickAway={cb}>
          <img src={SuccessGif} alt="" style={{ height: 20, width: 20 }} />
        </ClickAwayListener>
      </LightTooltip>
    );
  };
  return (
    <>
      {inputState && inputState?.length > 0 ? (
        <StyledMainMultiContainer id={hash + index}>
          <Radio
            size="small"
            className="mr-1"
            value={radioValue}
            checked={isSelected}
            disabled={isImageMediaStudioProcessing || disabled}
          />
          <StyledInputWithCharCount>
            <StyledMultiInputContainer
              style={
                isSelected
                  ? { border: "1px solid #0869FB" }
                  : disabled
                  ? { opacity: 10, border: "1px solid #DDDDDD" }
                  : { border: "1px solid #DDDDDD" }
              }
            >
              {inputState &&
                inputState?.length > 0 &&
                inputState.map((item, i) => (
                  <>
                    <StyledTextAreaContainer
                      style={{ width: `calc(96%/${inputState?.length})` }}
                    >
                      L{i + 1}:
                      <LightTooltip
                        arrow={true}
                        placement="top"
                        title={inputState[i].layer_text}
                      >
                        {isEditing ? (
                          <TextArea
                            value={inputState[i].layer_text}
                            minRows={1}
                            maxRows={1}
                            onFocus={() => setFocusIndex(i)}
                            onBlur={() => setFocusIndex(-1)}
                            multiline={false}
                            readOnly={!isEditing}
                            disableUnderline
                            borderColor={"none"}
                            backgroundColor={"none"}
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              fontFamily: "Inter",
                              padding: "4px 5px",
                              textOverflow: "ellipsis",
                            }}
                            height="unset"
                            onChange={(e) => {
                              setIsDirty(true);
                              onChange(e.target.value, i);
                            }}
                            // endAdornment={
                            //   showLoader
                            //     ? i == inputState.length - 1 && <Loader />
                            //     : i == inputState.length - 1 &&
                            //       showSuccessGif && (
                            //         <Success cb={resetShowSuccess} />
                            //       )
                            // }
                          />
                        ) : (
                          <StyledSuccessLoaderContainer>
                            <StyledTextContainer>
                              {inputState[i].layer_text}
                            </StyledTextContainer>{" "}
                            {showLoader
                              ? i == inputState.length - 1 && <Loader />
                              : i == inputState.length - 1 &&
                                showSuccessGif && (
                                  <Success cb={resetShowSuccess} />
                                )}
                          </StyledSuccessLoaderContainer>
                        )}
                      </LightTooltip>
                    </StyledTextAreaContainer>
                    {i != value?.data.length - 1 && "|"}
                  </>
                ))}
            </StyledMultiInputContainer>
            {isEditing && focusIndex != -1 && (
              <StyledCharContainer>
                {inputState.map((_, i) => (
                  <StyledCharCount
                    style={focusIndex != i ? { opacity: 0 } : {}}
                  >
                    {_.layer_text.length}/{imgLayerData?.[i]?.maxCharacters}{" "}
                    Characters
                  </StyledCharCount>
                ))}
              </StyledCharContainer>
            )}
            {isEditing && focusIndex != -1 && (
              <StyledCharContainer>
                {errorState?.reduce((p, c) => p || c) &&
                  errorState[focusIndex] &&
                  inputState.map((_, i) => (
                    <StyledCharCount
                      style={
                        focusIndex != i ? { opacity: 0 } : { color: "#E84B47" }
                      }
                    >
                      {errorState[i] &&
                        `Min ${imgLayerData?.[i]?.minCharacters} characters required`}
                    </StyledCharCount>
                  ))}
              </StyledCharContainer>
            )}
          </StyledInputWithCharCount>
          <IconButton
            color="primary"
            aria-label="save"
            className="ml-3 p-0"
            disabled={
              isImageMediaStudioProcessing ||
              disabled ||
              (errorState?.length > 0 && errorState?.reduce((p, c) => p || c))
            }
            onClick={() => {
              if (isEditing) {
                setIsEditing(false);
                onEdit && onEdit(-1);
                isDirty &&
                  onSave &&
                  onSave(
                    index,
                    { data: inputState, id: radioValue },
                    isSelected &&
                      inputState !== recommendationMetaData[hash].data[index]
                  );
                setIsDirty(false);
              } else {
                setIsEditing(true);
                onEdit && onEdit(index);
              }
            }}
          >
            {isEditing ? (
              <Save style={{ color: "#46494E" }} />
            ) : (
              <Edit style={{ color: "#46494E" }} />
            )}
          </IconButton>
          <IconButton
            color="primary"
            aria-label="save"
            className="ml-2 p-0"
            disabled={isImageMediaStudioProcessing || isDeleteDisabled}
            onClick={setDirtyFlag}
          >
            <Bin />
          </IconButton>
        </StyledMainMultiContainer>
      ) : (
        <></>
      )}
    </>
  );
}
