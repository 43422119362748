import {
  ActionRecommendations,
  ACTION_RECOMMENDATIONS,
  DisableDashboard,
  DISABLE_DASHBOARD,
  GetAccountDetails,
  GetAgency,
  GetCPRDetails,
  GetCPRRecommendations,
  GetIndustry,
  GetKeywordRecommendations,
  GetLearningPhaseFlag,
  GetModelText,
  GetRecommendationDetails,
  GetRecommendations,
  GET_ACCOUNT_DETAILS,
  GET_AGENCY,
  GET_CPR_DETAILS,
  GET_CPR_RECOMMENDATIONS,
  GET_INDUSTRY,
  GET_KEYWORD_RECOMMENDATIONS,
  GET_LEARNING_PHASE_FLAG,
  GET_MODEL_TEXT,
  GET_RECOMMENDATIONS,
  GET_RECOMMENDATION_DETAILS,
  GET_AGENCY_ASSETS,
  GetAgencyAssets,
} from "./../types/common";
import { takeLatest, call, takeEvery } from "redux-saga/effects";
import { BASE_URL, CROSS_PLATFORM_URL } from "../../utils/path";
import {
  crossPlatform,
  groupV1API,
  groupV2API,
} from "../../MappedAPI/MappedAPI";
import HttpService from "../../HttpService/HttpService";

function* getLearningPhaseFlag(action: GetLearningPhaseFlag): any {
  let {
    payload: { brandId, accountId, user },
    callback,
  } = action;
  try {
    const urlParams = `/v1/api/brand-ad-account-settings/${brandId}/${accountId}/google-learning-phase`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, user);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getModelText(action: GetModelText): any {
  let {
    payload: { user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/ai-model/`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getModelText ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAccountDetails(action: GetAccountDetails): any {
  let {
    payload: { account_id },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/ad-account/${account_id}/platform/google/details`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      account_id
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAccountDetails ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getIndustry(action: GetIndustry): any {
  let {
    payload: { user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/targeting-ai-form/industry`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getIndustry ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getRecommendations(action: GetRecommendations): any {
  let {
    payload: { elementId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/${elementId}`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getRecommendations ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getKeywordRecommendations(action: GetKeywordRecommendations): any {
  let {
    payload: { adAccountId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/recommendation/get-recommendation/${adAccountId}`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getKeywordRecommendations ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getRecommendationDetails(action: GetRecommendationDetails): any {
  let {
    payload: { elementId, recommendation_id, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/${elementId}/${recommendation_id}`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getRecommendationDetails ~ error", error);
    callback && callback(error.response, true);
  }
}

function* actionRecommendations(action: ActionRecommendations): any {
  let {
    payload: { elementId, recommendation_ids, user, recommendation_action },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/${elementId}/${recommendation_action}`;
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      recommendation_ids
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getRecommendationDetails ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getCPRDetails(action: GetCPRDetails): any {
  let {
    payload: { elementId, start_date, end_date, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/metrics/elements/${elementId}/tai/cpr/data?start_date=${start_date}&end_date=${end_date}`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getCPRDetails ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getCPRRecommendations(action: GetCPRRecommendations): any {
  let {
    payload: { elementId, start_date, end_date, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/metrics/elements/${elementId}/tai/all/data?start_date=${start_date}&end_date=${end_date}`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getCPRRecommendations ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAgency(action: GetAgency): any {
  let {
    payload: { agencyId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/agency/${agencyId}`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAgency ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAgencyAssets(action: GetAgencyAssets): any {
  let {
    payload: { agencyId, brandId },
    callback,
  } = action;
  try {
    const urlParams =  `/v4/api/white-label-asset-settings/${agencyId}/get-assets?brandId=${brandId}`;
    const response = yield call(
      HttpService.get,
      CROSS_PLATFORM_URL,
      urlParams
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAgency ~ error", error);
    callback && callback(error.response, true);
  }
}

function* disableDashboard(action: DisableDashboard): any {
  let {
    payload: { isDisableDashboard, user, brandId },
    callback,
  } = action;
  let payloadData = { isDisableDashboard: isDisableDashboard, enabled: true };
  try {
    const urlParams = `/v4/api/brands/${brandId}/dashboard/toggle`;
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      payloadData
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*disableDashboard ~ error", error);
    callback && callback(error.response, true);
  }
}

export function* commonWatcher() {
  yield takeEvery(GET_LEARNING_PHASE_FLAG, getLearningPhaseFlag);
  yield takeLatest(GET_MODEL_TEXT, getModelText);
  yield takeLatest(GET_ACCOUNT_DETAILS, getAccountDetails);
  yield takeLatest(GET_INDUSTRY, getIndustry);
  yield takeLatest(GET_RECOMMENDATIONS, getRecommendations);
  yield takeLatest(GET_KEYWORD_RECOMMENDATIONS, getKeywordRecommendations);
  yield takeLatest(GET_RECOMMENDATION_DETAILS, getRecommendationDetails);
  yield takeLatest(ACTION_RECOMMENDATIONS, actionRecommendations);
  yield takeEvery(GET_CPR_DETAILS, getCPRDetails);
  yield takeLatest(GET_CPR_RECOMMENDATIONS, getCPRRecommendations);
  yield takeLatest(GET_AGENCY, getAgency);
  yield takeLatest(GET_AGENCY_ASSETS, getAgencyAssets);
  yield takeLatest(DISABLE_DASHBOARD, disableDashboard);
}
