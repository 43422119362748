import { Box, styled } from "@mui/material";

export const StyledApproverSectionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-height: 100vh;
  width: 4em;
  align-items: center;
  margin-right: 0.7em;
  border-top-right-radius: 0.8em;
`;
