import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import {
  StyledFrameWrapper,
  StyledImageContainer,
  StyledTab,
  StyledTabs,
} from "./styled";

import { MediaStudioTestReducerInitialState } from "store/types/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";
import { useDispatch, useSelector } from "react-redux";
import { useMediaIdPolling } from "./hooks/useMediaIdPolling";
import ImageComponent from "./components/ImageComponent/ImageComponent";
import Loading from "./components/Loading/Loading";
import FrameImageComponent from "./components/FrameImageComponent/FrameImageComponent";
import VideoComponent from "./components/VideoComponent/VideoComponent";
import ThumbnailComponent from "./components/ThumbnailComponent/ThumbnailComponent";
import { getPresignedUrl } from "store/actions/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import {
  setErrorPresignedUrl,
  testMediaStudioPostResponse,
} from "store/actions/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";

interface MediaStudioTestingProps {
  usageType: "VIDEO_TEXT" | "IMAGE_TEXT";
  frames: any;
}

export interface ThumbnailItemType {
  isLoading: boolean;
  isFailed: boolean;
  src: string;
}
function MediaStudioTesting({ usageType, frames }: MediaStudioTestingProps) {
  const [value, setValue] = useState(0);
  const [thumbnailData, setThumbnailData] = useState<ThumbnailItemType[]>(
    frames.map((item: any) => ({
      isLoading: true,
      isFailed: false,
      src: "",
    }))
  );

  const dispatch = useDispatch();

  const { runTestPostResponse } = useSelector(
    (state: { mediaStudioTestReducer: MediaStudioTestReducerInitialState }) =>
      state.mediaStudioTestReducer
  );

  const { getVGSProcessingResponse } = useMediaIdPolling();

  const imageTabs = [
    {
      label: "Preview Image",
    },
    {
      label: "Rendered Image",
    },
  ];
  const videoTabs = [
    {
      label: "Preview Frames",
    },
    {
      label: "Rendered Frames",
    },
    {
      label: "Rendered Video",
    },
  ];

  useEffect(() => {
    if (runTestPostResponse.readyForPolling) {
      const payload = runTestPostResponse.data.map((item) => item.id);
      if (!payload.every((str) => str == "")) {
        getVGSProcessingResponse(payload, +new Date(), runTestPostResponse);
      } else {
        const payload = runTestPostResponse.data.map((item) => {
          item.status = "FAILED";
          return item;
        });
        dispatch(
          testMediaStudioPostResponse({
            ...runTestPostResponse,
            data: payload,
            readyForPolling: false,
          })
        );
      }
      //for preview images
      for (const index in frames) {
        dispatch(
          getPresignedUrl({
            mediaId: frames?.[index]?.previewFile?.value,
            type: "preview",
            finalAction: (data: any, error: boolean) => {
              if (error) {
                thumbnailData[Number(index)] = {
                  isLoading: false,
                  isFailed: true,
                  src: "",
                };
                dispatch(setErrorPresignedUrl(true));
              } else {
                thumbnailData[Number(index)] = {
                  isLoading: false,
                  isFailed: false,
                  src: data?.url,
                };
              }
              setThumbnailData(thumbnailData);
            },
          })
        );
      }
    }
  }, [runTestPostResponse]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems="center"
      justifyContent={"center"}
    >
      <StyledTabs
        sx={{ position: "sticky", top: "-18px", backgroundColor: "white" }}
        value={value}
        indicatorColor="secondary"
        onChange={(e, j) => {
          setValue(j);
        }}
      >
        {usageType !== "VIDEO_TEXT"
          ? imageTabs.map((obj: any, i: number) => (
              <StyledTab label={obj.label} tabIndex={i} />
            ))
          : videoTabs.map((obj: any, i: number) => (
              <StyledTab label={obj.label} tabIndex={i} />
            ))}
      </StyledTabs>
      <Box
        height={runTestPostResponse.isLoading ? "65vh" : "inherit"}
        sx={{ marginTop: "2em" }}
        display="flex"
        justifyContent="center"
        alignItems={"center"}
      >
        {runTestPostResponse.isLoading ? (
          <Loading />
        ) : usageType === "IMAGE_TEXT" ? (
          <StyledImageContainer>
            {value === 0 ? (
              <ThumbnailComponent item={thumbnailData[0]} />
            ) : (
              <ImageComponent
                isFailed={runTestPostResponse.data?.[0]?.status === "FAILED"}
                isLoading={
                  runTestPostResponse.data?.[0]?.status === "INITIATED"
                }
                src={runTestPostResponse.data?.[0]?.url}
              />
            )}
          </StyledImageContainer>
        ) : value < 2 ? (
          <StyledFrameWrapper>
            {value === 0
              ? thumbnailData.map((item: ThumbnailItemType, index: number) => (
                  <ThumbnailComponent index={index} item={item} key={index} />
                ))
              : frames.map((_: any, index: number) => (
                  <FrameImageComponent
                    index={index}
                    item={runTestPostResponse.data[index]}
                    key={index}
                  />
                ))}
          </StyledFrameWrapper>
        ) : (
          <VideoComponent
            src={
              runTestPostResponse.data?.[runTestPostResponse.data.length - 1]
                ?.url
            }
            isFailed={
              runTestPostResponse.data?.[runTestPostResponse.data.length - 1]
                ?.status === "FAILED"
            }
            isLoading={
              runTestPostResponse.data?.[runTestPostResponse.data.length - 1]
                ?.status === "INITIATED"
            }
          />
        )}
      </Box>
    </Box>
  );
}

export default MediaStudioTesting;
