import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import ButtonComponent from "common/modal/ButtonComponent";
import { ReactComponent as SetupChangedIcon } from "./../../assets/SetupChangedIcon.svg";

function SetupChangedModal({
  isAccordionOpen,
  isSetupChanged,
}: {
  isAccordionOpen: boolean;
  isSetupChanged: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (isAccordionOpen && isSetupChanged) {
      document.getElementById("disable-changes-popover")?.click();
    }
  }, [isAccordionOpen, isSetupChanged]);

  const handleClose = () => {
    // if (!isSetupChanged) {
    //   setAnchorEl(null);
    // }
  };

  useEffect(() => {
    if (!isAccordionOpen || !isSetupChanged) {
      setAnchorEl(null);
    }
  }, [isAccordionOpen]);

  const open = Boolean(anchorEl);
  const id = open ? "setup-changed-popover" : undefined;

  return (
    <>
      {isSetupChanged && (
        <Box
          sx={{
            background: "rgba(0, 0, 0, 0.5)",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "absolute",
            zIndex: 40,
          }}
        >
          <span
            aria-owns="setup-changed-popover"
            aria-haspopup="true"
            id="disable-changes-popover"
            onClick={handleClick}
          ></span>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            slotProps={{
              root: {
                style: {
                  transform: "translate(15%, 25%)",
                },
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box
              sx={{
                background: "#FFF",
                padding: "1.5em",
                borderRadius: "0.5em",
                textAlign: "center",
                maxWidth: "32.5em",
              }}
            >
              <SetupChangedIcon />
              <Typography
                my={2}
                sx={{
                  fontSize: "1.5em",
                  lineHeight: "2em",
                  color: "#0869FB",
                  fontWeight: "500",
                  fontFamily: "Inter",
                }}
              >
                Setup changed
              </Typography>
              <Typography
                sx={{
                  fontSize: "1em",
                  lineHeight: "1.375em",
                  color: "#5C6674",
                  maxWidth: "75%",
                  margin: "0 auto",
                  fontFamily: "Inter",
                }}
              >
                We have updated your Optimisation Strategy for this AI group.
                Please contact support to enable Budget Planner
              </Typography>
              <ButtonComponent
                label={"Please contact our Support Team"}
                variant="text"
                sx={{
                  px: 3,
                  ml: 0,
                  mt: 2,
                  pointerEvents: "none !important",
                }}
                disabled={false}
              />
            </Box>
          </Popover>
        </Box>
      )}
    </>
  );
}

export default SetupChangedModal;
