import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Recommended } from "../../../../../assets/svg/recommended.svg";
import { ReactComponent as ToBePublished } from "../../../../../assets/svg/toBePublished.svg";
import { ReactComponent as CREnabled } from "../../../../../assets/svg/gsai-cr-enabled.svg";
import { ReactComponent as WarningIcon } from "../../../../../assets/svg/warning-icon-gsai.svg";
import { initialState } from "../../../../../store/types/auth";
import { Dropable } from "../../dnd";
import useDataList from "./DataList";
import EmptyToBePublished from "./EmptyToBePublished";
import "./index.scss";
import Selection from "./Selection";
import CreativeReplacement from "./CreativeReplacement";
import {
  StyledContent,
  StyledErrorMsgContainer,
  StyledToolTipContainer,
} from "./styled";
import { usePreview } from "../CreateAdVariants/hooks";

export const HeadlineAdsContainer: React.FunctionComponent<{}> = ({}) => {
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const {
    onDragStart,
    onDrop,
    handlePinning,
    handleDelete,
    handleInputChange,
    dnd,
  } = useDataList("headlines");
  const { headlines } = usePreview();
  const dispatch = useDispatch();

  useEffect(() => {
    let val: boolean = false;
    dnd?.headlines?.toBePublished?.forEach((e: any, index: number) => {
      const idx = dnd?.headlines?.toBePublished?.findIndex(
        (k: any, i: number) => index != i && e?.value == k?.value
      );
      if (idx >= 0) {
        val = true;
      }
    });
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        dnd: {
          ...dnd,
          headlines: { ...dnd.headlines, toBePublishedError: val },
        },
      },
    });
  }, [dnd?.headlines?.toBePublished]);
  useEffect(() => {
    let val: boolean = false;
    dnd?.headlines?.replacements?.forEach((e: any, index: number) => {
      const idx = dnd?.headlines?.replacements?.findIndex(
        (k: any, i: number) => index != i && e?.value == k?.value
      );
      if (idx >= 0) {
        val = true;
      }
    });
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        dnd: {
          ...dnd,
          headlines: { ...dnd.headlines, creativeReplacementError: val },
        },
      },
    });
  }, [dnd?.headlines?.replacements]);
  React.useEffect(() => {
    const crConst = {
      creative_replacement: false,
      headline: [
        {
          selected: false,
          label: "Best Performance",
          performance: "BEST",
          interval: "",
        },
        {
          selected: false,
          label: "Good Performance",
          performance: "GOOD",
          interval: "",
        },
        {
          selected: false,
          label: "Low Performance",
          performance: "LOW",
          interval: "",
        },
      ],
    };
  }, []);

  const selectionProps = {
    onDragStart,
    handlePinning,
    handleDelete,
    handleInputChange,
    type: "headlines" as "headlines" | "descriptions",
    limit: 30,
  };

  return (
    <div className="create-variant-ads-container">
      <div className="variant-info">
        <div className="recommended-variant ads-container-header">
          <Recommended className="variant-header-svg" />
          <span className="container-header">Recommended</span>
        </div>
        <Dropable
          handleDrop={onDrop}
          target="recommended"
          className="ad-container-body"
          isDroppable={true}
        >
          <div className="create-ad-variant">
            <Selection
              data={dnd.headlines.recommended}
              source="recommended"
              more={dnd.headlines.more}
              {...selectionProps}
            />
          </div>
        </Dropable>
      </div>
      <div className="variant-info">
        <div className="ads-container-header">
          <div className="text-center flex-1">
            <ToBePublished className="variant-header-svg" />
            <span className="container-header">To Be Published</span>
            <span className="container-header-count">
              {" "}
              {dnd.headlines.toBePublished?.length} / 15{" "}
            </span>
          </div>
          {headlines.length < 3 && (
            <StyledToolTipContainer className="warning-for-publish">
              <WarningIcon
                style={{ width: 26, height: 26, cursor: "pointer" }}
              />
              <StyledContent
                top={"29px"}
                right={"0px"}
                className="content"
                style={{ zIndex: 100000 }}
              >
                <div className="" style={{ zIndex: 100000 }}>
                  Not enough assets. Add unpinned assets or make sure that you
                  pin assets to every position.
                </div>
              </StyledContent>
            </StyledToolTipContainer>
          )}
        </div>
        <div className="to-be-published">
          <Dropable
            handleDrop={onDrop}
            target="toBePublished"
            className="ad-container-body tbp"
            isDroppable={dnd.headlines.toBePublished.length < 15}
          >
            {dnd.headlines.toBePublished?.length ? (
              <div className="create-ad-variant">
                <Selection
                  data={dnd.headlines.toBePublished}
                  source="toBePublished"
                  {...selectionProps}
                />
                {dnd?.headlines?.toBePublishedError && (
                  <StyledErrorMsgContainer>
                    Headlines are identical. Enter a different headline.
                  </StyledErrorMsgContainer>
                )}
              </div>
            ) : (
              <EmptyToBePublished />
            )}
          </Dropable>
          <CreativeReplacement type="headlines">
            <Dropable
              handleDrop={onDrop}
              target="replacements"
              className="ad-container-body tbp"
              isDroppable={true}
            >
              {dnd.headlines.replacements?.length ? (
                <div className="create-ad-variant">
                  <Selection
                    data={dnd.headlines.replacements}
                    source="replacements"
                    {...selectionProps}
                  />
                  {dnd?.headlines?.creativeReplacementError && (
                    <StyledErrorMsgContainer>
                      Headlines are identical. Enter a different headline.
                    </StyledErrorMsgContainer>
                  )}
                </div>
              ) : (
                <div className="cr-content">
                  <CREnabled />
                </div>
              )}
            </Dropable>
          </CreativeReplacement>
        </div>
      </div>
    </div>
  );
};
