
import { Button } from '@mui/material';
import { ReactComponent as IconArrowBack } from "./assets/arrow-back.svg";
import { useDispatch } from 'react-redux';

export default function BackButton({
  onClick
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}) {
  const dispatch = useDispatch();

  const _onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    dispatch({ type: "CLEAR_OPEN_SEARCH_AI_DATA" });
    onClick?.(e);
  };

  return (
    <Button
      onClick={_onClick}
      startIcon={<IconArrowBack />}
      sx={{
        color: "var(--color-palette-gray-700, #394455)",
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "22px",
        letterSpacing: "0.175px",
        textTransform: "capitalize",
      }}
    >
      Back
    </Button>
  )
}