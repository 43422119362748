import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Popover,
  Button,
  IconButton,
} from "@mui/material";
import { ReactComponent as LeftArrow } from "assets/smb/ArrowWithRoundLeft.svg";
import { ReactComponent as RightArrow } from "assets/smb/ArrowWithRoundRight.svg";

import { ReactComponent as PreviousIcon } from "assets/svg/calendar-previous.svg";
import { Months } from "../../Types";

interface IMonthPickerProps {
  year: any;
  month: any;
  setMonth: Function;
  setYear: Function;
  toPrevMonth: () => void;
  toNextMonth: () => void;
  onSelectMonth: (month: number, year: number) => void;
  selectedDatesFiltered: any;
  showWarningModalOnMonthChange: (
    month: number,
    year: number,
    funtion?: any
  ) => void;
  isCalendarUpdated: boolean;
}

function MonthPicker(props: IMonthPickerProps) {
  const {
    year,
    month,
    toPrevMonth,
    toNextMonth,
    setMonth,
    setYear,
    onSelectMonth,
    selectedDatesFiltered,
    showWarningModalOnMonthChange,
    isCalendarUpdated,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-month" : undefined;

  return (
    <>
      <span
        style={{
          border: "1px solid #EFEFEF",
          padding: "0.15em 0.65em",
          borderRadius: "1em",
          cursor: "pointer",
        }}
        onClick={() => {
          if (selectedDatesFiltered.length > 0 && isCalendarUpdated) {
            showWarningModalOnMonthChange(parseInt(month), year, "toPrevMonth");
          } else {
            toPrevMonth();
          }
        }}
      >
        <PreviousIcon />
      </span>
      <Typography
        className="mx-3 px-5 py-2"
        sx={{
          fontSize: "1em",
          width: "240px",
          lineHeight: "1.625em",
          fontWeight: "500",
          color: "#000",
          backgroundColor: "#EDF3FE",
          borderRadius: "0.4375em",
        }}
        onClick={handleClick}
      >
        {Months[month]}, {year}
      </Typography>
      <span
        style={{
          border: "1px solid #EFEFEF",
          padding: "0.15em 0.65em",
          borderRadius: "1em",
          cursor: "pointer",
        }}
        onClick={() => {
          if (selectedDatesFiltered.length > 0 && isCalendarUpdated) {
            showWarningModalOnMonthChange(parseInt(month), year, "toNextMonth");
          } else {
            toNextMonth();
          }
        }}
      >
        <PreviousIcon
          style={{
            rotate: "180deg",
          }}
        />
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          mt: 1,
          //   ml: -2,
          p: 0,
        }}
      >
        <Box
          className="border"
          sx={{
            height: "12.75rem",
            width: "19.93rem",
            borderRadius: 2,
            p: 0,
          }}
        >
          <Stack spacing={1} sx={{ p: 0 }}>
            <Box
              className="d-flex justify-content-around align-items-center"
              sx={{ padding: 1.5, backgroundColor: "#ECECEC" }}
            >
              <IconButton
                disableRipple
                onClick={() => {
                  setYear(year - 1);
                }}
              >
                <LeftArrow />
              </IconButton>
              <Typography
                className="text-center w-75"
                sx={{ fontWeight: 600, fontSize: 18, fontFamily: "Inter" }}
              >
                {year}
              </Typography>
              <IconButton
                disableRipple
                onClick={() => {
                  setYear(year + 1);
                }}
              >
                <RightArrow />
              </IconButton>
            </Box>
            <Box
              className="flex-wrap d-flex justify-content-center w-100 "
              sx={{ mx: 2 }}
            >
              {Object.keys(Months).map((key) => (
                <Button
                  disableRipple
                  variant="text"
                  key={key}
                  onClick={() => {
                    if (selectedDatesFiltered.length > 0 && isCalendarUpdated) {
                      showWarningModalOnMonthChange(parseInt(key), year);
                      handleClose();
                    } else {
                      setMonth(parseInt(key));
                      onSelectMonth(parseInt(key), year);
                      handleClose();
                    }
                  }}
                  className="text-center cursor-pointer "
                  sx={{
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: `${
                      parseInt(key) === month ? "#ECECEC" : ""
                    }`,
                    color: "#000",
                    "&:hover":
                      parseInt(key) === month ? { background: "#ECECEC" } : {},
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Inter !important",
                      fontWeight: "400",
                      textTransform: "none",
                    }}
                  >
                    {Months[parseInt(key)].substring(0, 3)}
                  </Typography>
                </Button>
              ))}
            </Box>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}

export default MonthPicker;
