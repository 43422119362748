import { FormControlLabel, Menu, MenuItem, Radio } from "@mui/material";
import styled from "styled-components";

export const StyledFormControlLabel = styled(FormControlLabel)`
  font-size: 0.82vw;
  @media screen and (max-width: 1280px) {
    font-size: 11px;
  }
  .MuiFormControlLabel-label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 1.5em;
    color: #000000;
  }
`;

export const StyledMenu = styled(Menu)`
  font-size: 0.82vw;
  @media screen and (max-width: 1280px) {
    font-size: 11px;
  }
`;

export const StyledRadio = styled(Radio)``;
export const StyledMenuItem = styled(MenuItem)`
  .Mui-selected {
    background: #f1f4fe;
  }
`;
