import React, { useContext, useEffect, useState } from "react";
import { Handle, useReactFlow } from "react-flow-renderer";
import { useDispatch, useSelector } from "react-redux";
import {
  setCalibrationData,
  setKeywordTuningData,
  setPolling,
  storeTargetingAICluster,
  setAdditionalInputs,
  setNegateTargeting,
  setLoadedKeywords,
  setEnableCustomKeywords,
} from "../../../store/actions/targetingAI";
import addIcon from "../../../assets/tai/addIcon.svg";
import closeIcon from "../../../assets/tai/closeIcon.svg";
import infoIcon from "../../../assets/tai/infoIcon.svg";
import { mixPanelAdEvent } from "../../../utils/mixpanel";
import { ReactComponent as Continue } from "../ReactFlowComponents/Assets/svgs/continue.svg";
import { ReactComponent as Refresh } from "../../../assets/svg/Refresh_Grey.svg";

import "./KeywordSetup.scss";
import { TaiContext } from "../TaiContextProvider";
import AdditionalInputs from "./subcomponents/AdditionalInputs";
import PersonaTuner from "../ReactFlowComponents/PersonaTuner/PersonaTuner";
import { generateCohortsICP } from "store/actions/targetingICP";
import useFocusToNode from "../useFocusToNode";
import { Switch, Tooltip, styled } from "@mui/material";

const KeywordSetup = ({ data, isConnectable }) => {
  const dispatch = useDispatch();
  const { setCenter } = useReactFlow();
  const {
    checkStepAndComplete,
    setNodes,
    setEdges,
    changePublishModal,
    cohortType,
  } = useContext(TaiContext);

  const authState = useSelector((state) => state.auth);
  const { user } = authState;
  const adaccount = useSelector((state) => state.adaccount);
  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;
  const {brandLoadedKeywords,lateralLoadedKeywords,genericLoadedKeywords} = useSelector((state) => state.calibration.apiData?.loadedKeywords || {})
  const aiInitiationData = useSelector((state) => state.aiInitiation.apiData);
  const aiInitiationFormData = useSelector(
    (state) => state.aiInitiation.formData
  );
  const calibrationData = useSelector((state) => state.calibration.apiData);
  const calibrationFormData = useSelector(
    (state) => state.calibration.formData
  );
  const {all_keywords} = calibrationData || {}
  const currentPage = useSelector((state) => state.taiCommon.currentPage);
  const loaderState = useSelector((state) => state.taiCommon.loader);
  const { selectedAgency } = useSelector((state) => state.common);
  const { locationFilter, specialAdCategories, negateTargeting, enableCustomKeywords } = useSelector(
    (state) => state.taiCommon.additionalInputs || {}
  );
  const [advanceSwitches, setAdvanceSwitches] = useState({
    countrySpecificTargeting: locationFilter,
    specialAdCategories:
      specialAdCategories && specialAdCategories.length > 0 ? true : false,
  });

  const [specialAdCategorySelection, setSpecialAdCategorySelection] = useState(
    specialAdCategories ?? []
  );

  const handleSpecialAdCategorySelection = () => {
    if (!advanceSwitches.specialAdCategories) {
      return [];
    }
    return specialAdCategorySelection;
  };

  const [input, setinput] = useState({
    custom: "",
    branddirect: "",
    brandindirect: "",
    competitordirect: "",
    competitorindirect: "",
  });
  const [brandKeywords, setBrandKeywords] = useState(calibrationData.Brand);
  const [lateralKeywords, setLateralKeywords] = useState(
    calibrationData.Lateral
  );
  const [genericKeywords, setGenericKeywords] = useState(
    calibrationData.Generic
  );
  const [customKeywords, setCustomKeywords] = useState(calibrationData.Custom);

  const [showInput, setShowInput] = useState("");

  const aiGroupId = aiInitiationData?.aiGroup?.id;

  const platformId = "1c59a6e2-843b-4634-92a0-6fa93ce6c761";
  const { selectedBrand: selectedbrand } = useSelector(
    (state) => state.adaccount
  );
  const { selectedAudience } = useSelector(
    (state) => state.taiCommon?.campaignAudienceDetail || {}
  );

  const getClusterData=()=>{
    const brandCustomKeywords=[]
    const lateralCustomKeywords=[]
    const genericCustomKeywords=[]

    const brand=[]
    const lateral=[]
    const generic=[]

    brandKeywords.forEach((item)=>{
      if(item.link_type==='Custom')
       {brandCustomKeywords.push(item)}
      else
        {brand.push(item) } 
      })
    lateralKeywords.forEach((item)=>{
      if(item.link_type==='Custom')
        {lateralCustomKeywords.push(item)}
      else
        {lateral.push(item)}  
      })
    genericKeywords.forEach((item)=>{
      if(item.link_type==='Custom')
        {genericCustomKeywords.push(item)}
      else
        {generic.push(item)}  
      })

    return {
      brand_name: aiInitiationData.name,
      brand_website: aiInitiationData.website,
      ad_account_id: aiInitiationData.adAccount.adAccountId,
      custom_audiences: {
        ad_account_id: aiInitiationData.adAccount.adAccountId,
        custom_audience_ids: calibrationFormData?.selectedCustomAudience?.map((audience)=>audience.id) 
      },
      keywords: {
        Custom: [...(enableCustomKeywords?customKeywords:[]),...brandCustomKeywords,...lateralCustomKeywords,...genericCustomKeywords],
        Brand: brand,
        Lateral: lateral,
        Generic: generic,
      },
      all_keywords,
      industries: calibrationData.industries,
      geo_locations: {
          countries: aiInitiationData.region.length ? [] : aiInitiationData.country.map((country)=>country.code),
          regions_ids: aiInitiationData.region.map((region) => region.key)
      },
      additional_inputs: {
        location_filter: locationFilter,
        special_ads_categories: handleSpecialAdCategorySelection(),
        negate_targetings: negateTargeting
      },
    }
  }

  const getICPKwsData = () => {

    const IcpDataWithoutIsNewKey = calibrationData?.icps.map((icp)=>{
      const icpClone={...icp}

      if('isNew' in icpClone )
      delete icpClone.isNew
    
      return icpClone
    })

    return {
    // service: "icp_keywords_recms",
    // version: "v0.1.0",

    payload_data: {
      brand_details: {
        brand_name: aiInitiationData.name,
        brand_website: aiInitiationData.website,
        brand_description: calibrationData?.brand_details?.brand_description,
      },
      geo_locations: {
        countries: aiInitiationData.region.length ? [] : aiInitiationData.country.map((country)=>country.code),
        regions_ids:
          aiInitiationData.region.map((region) =>region.key) ||
          [],
      },
      icps: IcpDataWithoutIsNewKey,
    },
  }};

  const setData = (name, value) => {
    setinput((previousVal) => {
      return {
        ...previousVal,
        [name]: value,
      };
    });
  };

  const handleClusterData = () => {
    if (calibrationFormData?.TypeOfCohort === "ICP") {
      dispatch(
        generateCohortsICP(
          {
            aiGroupId: aiGroupId,
            kwsData: getICPKwsData(),
            platformId,
          },
          (response, error) => {
            // dispatch(
            //   setPolling({
            //     name: "icpKeywordTuning",
            //     requestId: "04cd32c5-0ec9-41a6-8cb6-d6f2101b946c",
            //   })
            // );
            if (
              !error &&
              (response?.data?.requestId || response?.data?.requestid)
            ) {
              dispatch(
                setPolling({
                  name: "icpKeywordTuning",
                  requestId:
                    response?.data?.requestId || response?.data?.requestid,
                })
              );
            }
          }
        )
      );
    } else {
      dispatch(
        storeTargetingAICluster(
          {
            clusterData:getClusterData(),
            user,
            aiGroupId,
            platformId,
            brand: selectedAudience ? selectedbrand.name : undefined,
          },
          (response, error) => {
            if (!error && response?.data?.message?.requestid) {
              dispatch(
                setPolling({
                  name: "keywordTuning",
                  requestId: response.data.message.requestid,
                })
              );
            }
          }
        )
      );
    }

    // TODO: Verify the current keys and update them accordingly.
    // mixPanelAdEvent("TAI Keyword Tuning Step Done", { 
    //   module_name: "Facebook Playground",
    //   brand_id: selectedBrand?.id,
    //   brand_name: selectedBrand?.name,
    //   brand_type: selectedBrand?.brandType,
    //   agency_name: selectedAgency?.name,
    //   agency_id: selectedAgency?.id,
    //   brand_direct_keywords: brandKeywords,
    //   brand_indirect_keywords: lateralKeywords,
    //   competitor_direct_keywords: genericKeywords,
    //   competitor_indirect_keywords: competitorIndirect,
    //   custom_keywords: custom,
    //   ai_group_id: aiInitiationFormData?.selectedAiGroup?.id,
    //   ai_group_name: aiInitiationFormData?.selectedAiGroup?.name,
    //   ad_account_name: aiInitiationData?.adAccount?.name,
    //   ad_account_id: aiInitiationData?.adAccount?.id,
    //   tai_flow: cohortType,
    // });
  };

  const focusToNode = useFocusToNode();

  const handleSubmit = () => {
    checkStepAndComplete([1, 2, 3], data.index, () => {
      dispatch(
        setCalibrationData({
          ...calibrationData,
          Brand: brandKeywords,
          Lateral: lateralKeywords,
          Generic: genericKeywords,
          Custom: customKeywords,
        })
      );
      dispatch(setKeywordTuningData({}));
      dispatch(
        setAdditionalInputs({
          locationFilter,
          specialAdCategories: handleSpecialAdCategorySelection(),
        })
      );
      handleClusterData();
      data.nextPage(dispatch, loaderState, setNodes, setEdges, focusToNode);
    });
  };
  const isCustomKeywordsAdded = enableCustomKeywords && customKeywords?.length>0;
  const hasBrandKeywords = brandKeywords?.length > 0;
  const hasLateralKeywords = lateralKeywords?.length > 0;
  const hasGenericKeywords = genericKeywords?.length > 0;
  const generateAudienceButtonDisable = !(hasBrandKeywords || hasLateralKeywords || hasGenericKeywords || isCustomKeywordsAdded);
  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="right"
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      {calibrationFormData?.TypeOfCohort === "ICP" ? (
        <PersonaTuner
          handleSubmit={handleSubmit}
          currentPage={currentPage}
          data={data}
          loaderState={loaderState}
          setNodes={setNodes}
          setEdges={setEdges}
          isConnectable={isConnectable}
        />
      ) : (
        <div className="p-4">
          <div className="p-2 d-flex justify-content-between align-items-center">
            <h4 style={{ fontWeight: "bold" }}>Keyword Tuning</h4>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <AdditionalInputs
                specialAdCategorySelection={specialAdCategorySelection}
                setSpecialAdCategorySelection={setSpecialAdCategorySelection}
                advanceSwitches={advanceSwitches}
                setAdvanceSwitches={setAdvanceSwitches}
                locationFilter={locationFilter}
              />
              {currentPage > data.index && (
                <Continue
                  onClick={() =>
                    data.onEditClick(
                      dispatch,
                      loaderState,
                      setNodes,
                      setEdges,
                      focusToNode
                    )
                  }
                />
              )}
            </div>
          </div>
          <div
            style={{
              height: "620px",
              overflow: "auto",
            }}
          >
            <div className="mt-4 text-sm rounded-pill">
              <div
                style={{ borderRadius: "10px" }}
                className={`${
                  !data.disabledFields
                    ? "bg-white rounded-t-xl shadow-sm border"
                    : "rounded-t-xl shadow-sm border"
                }`}
              >
                <div style={{ padding: "0.25rem", lineHeight: "8px" }}>
                  <div className="d-flex align-items-baseline justify-content-between">
                    <div>
                      <h1
                        className={`${
                          data.disabledFields ? "text-sm" : "h5"
                        } pt-1 ml-2 font-semibold`}
                      >
                        Brand
                      </h1>
                    <div
                    className="ml-2"
                    style={{ color: "#767676", fontSize: "12px" }}
                  >
                    Keywords related to brand and their competitors
                  </div>
                  </div>
                  <div className="d-flex" style={{gap:'0.75rem', height:'100%', alignItems:'center'}}>
                    <div
                      style={{display:'flex', alignItems:'center', padding:'0.375rem 0.75rem', gap:'0.5rem', borderRadius:'0.375rem', border:'1.5px solid #E0E3E5',fontSize:'0.75rem', fontWeight:'500', color:'#394455', 
                      cursor:'pointer',
                      opacity: brandKeywords?.length ? 1 : 0.5,}}
                      onClick={() => setBrandKeywords([])}
                    >
                      Clear All
                    </div>
                    <button 
                    onClick={()=>{
                      const moreKeywords=all_keywords?.Brand?.slice(brandLoadedKeywords,brandLoadedKeywords+7) 
                      setBrandKeywords([...brandKeywords,...moreKeywords])
                      dispatch(setLoadedKeywords({keywordType: 'brandLoadedKeywords', loadedKeywords: brandLoadedKeywords+7}))
                    }}
                    disabled={brandLoadedKeywords>=all_keywords?.Brand?.length}
                    className="d-flex" 
                    style={{ opacity: brandLoadedKeywords>=all_keywords?.Brand?.length ? 0.5 : 1, alignItems:'center', gap:'0.5rem', padding:'0.375rem 0.75rem', borderRadius:'0.375rem', border:'1.5px solid #E0E3E5', background:'white'}}>
                      <Refresh/>
                      <p style={{fontSize:'0.75rem', fontWeight:'500', margin:'0', color:'#394455'}}>
                        Load more keywords {`(${brandKeywords?.length}/${all_keywords?.Brand?.length})`}
                      </p>
                    </button>
                  </div>
                  </div>
                </div>
                <hr style={{ margin: "8px 0 2px 0" }} />
                {!data.disabledFields && (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-wrap overflow-auto d-flex h-25 w-100">
                      {brandKeywords?.map((element, index) => {
                        return (
                          <div
                            key={index}
                            className="m-2 border d-flex align-items-center text-primary border-primary rounded-pill "
                          >
                            <div className="kw-chip-custom-style">
                              {element.keyword}
                            </div>
                            <button
                              className="m-0 chip-remove-custom-style text-primary"
                              onClick={() => {
                                setBrandKeywords((vals) =>
                                  vals.filter((val, idx) => idx !== index)
                                );
                              }}
                            >
                              <div className="chip-remove-custom-style">
                                <img
                                  src={closeIcon}
                                  height={8}
                                  width={8}
                                  alt="close"
                                />
                              </div>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    {showInput === "branddirect" && (
                      <input
                        className="w-10 p-2 border border-primary rounded-pill"
                        id="text"
                        name="branddirect"
                        value={input.branddirect}
                        style={{ outline: "0" }}
                        onChange={(e) => {
                          setData("branddirect", e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.target?.value) {
                            const newKeyword={
                              keyword: input.branddirect,
                              Brand_keyword_similarity_score: 1, // hardcoded to 1 for custom keywords
                              Normalized_Brand_keyword_similarity_score: 1, // hardcoded to 1 for custom keywords
                              source: "Custom", // fixed to Custom
                              link_type: "Custom" // fixed to Custom
                            }
                            setBrandKeywords([...brandKeywords, newKeyword]);
                            setData("branddirect", "");
                            setShowInput("");
                          }
                        }}
                      />
                    )}
                    <div className="add-button">
                      <button
                        className="p-2 m-1 text-lg text-white border border-blue-500 rounded-pill d-flex font-weight-bold h-75"
                        onClick={() => {
                          setData("branddirect", "");
                          setShowInput("branddirect");
                        }}
                        style={{ backgroundColor: "#0769FB" }}
                      >
                        <img src={addIcon} height={12} width={12} alt="add" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="my-4 text-sm rounded-pill">
              <div
                style={{ borderRadius: "10px" }}
                className={`${
                  !data.disabledFields
                    ? "bg-white rounded-t-xl shadow-sm border"
                    : "rounded-t-xl shadow-sm border"
                }`}
              >
                <div style={{ padding: "0.25rem", lineHeight: "8px" }}>
                  <div className="d-flex align-items-baseline justify-content-between">
                    <div>
                      <h1
                        className={`${
                          data.disabledFields ? "text-sm" : "h5"
                        } pt-1 ml-2 font-semibold`}
                      >
                        Generic
                      </h1>
                    <div
                      className="ml-2"
                      style={{ color: "#767676", fontSize: "12px" }}
                    >
                      Keywords related to brand offerings and industry of the brand
                    </div>
                    </div>
                    <div  className="d-flex" style={{gap:'0.75rem', height:'100%', alignItems:'center'}}>
                      <div
                        style={{
                          display:'flex', alignItems:'center', padding:'0.375rem 0.75rem', gap:'0.5rem', borderRadius:'0.375rem', border:'1.5px solid #E0E3E5',fontSize:'0.75rem', fontWeight:'500', color:'#394455',
                          cursor: "pointer",
                          opacity: genericKeywords?.length ? 1 : 0.5,
                        }}
                        onClick={() => setGenericKeywords([])}
                      >
                        Clear All
                      </div>
                      <button
                       onClick={()=>{
                        const moreKeywords=all_keywords?.Generic?.slice(genericLoadedKeywords,genericLoadedKeywords+7) 
                        setGenericKeywords([...genericKeywords,...moreKeywords])
                        dispatch(setLoadedKeywords({keywordType: 'genericLoadedKeywords', loadedKeywords: genericLoadedKeywords+7})) 
                      }}
                      disabled={genericLoadedKeywords>=all_keywords?.Generic?.length}
                      className="d-flex" 
                      style={{ opacity: genericLoadedKeywords>=all_keywords?.Generic?.length ? 0.5 : 1, alignItems:'center', gap:'0.5rem', padding:'0.375rem 0.75rem', borderRadius:'0.375rem', border:'1.5px solid #E0E3E5', background:'white'}}>
                      <Refresh/>
                      <p style={{fontSize:'0.75rem', fontWeight:'500', margin:'0', color:'#394455'}}>
                        Load more keywords {`(${genericKeywords?.length}/${all_keywords?.Generic?.length})`}
                      </p>
                    </button>
                    </div>
                  </div>
                </div>
                <hr style={{ margin: "8px 0 2px 0" }} />
                {!data.disabledFields && (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-wrap pt-1 space-x-2 overflow-auto d-flex h-25 w-100">
                      {genericKeywords?.map((element, index) => {
                        return (
                          <div
                            key={index}
                            className="m-2 border d-flex align-items-center text-primary border-primary rounded-pill "
                          >
                            <div className="kw-chip-custom-style">
                              {element.keyword}
                            </div>
                            <button
                              className="m-0 chip-remove-custom-style text-primary"
                              onClick={() => {
                                setGenericKeywords((vals) =>
                                  vals.filter((val, idx) => idx !== index)
                                );
                              }}
                            >
                              <div className="chip-remove-custom-style">
                                <img
                                  src={closeIcon}
                                  height={8}
                                  width={8}
                                  alt="close"
                                />
                              </div>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    {showInput === "competitordirect" && (
                      <input
                        className="w-10 p-2 border border-primary rounded-pill"
                        id="text"
                        name="competitordirect"
                        value={input.competitordirect}
                        onChange={(e) => {
                          setData("competitordirect", e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.target?.value) {
                            const newKeyword={
                              keyword: input.competitordirect,
                              Brand_keyword_similarity_score: 1, // hardcoded to 1 for custom keywords
                              Normalized_Brand_keyword_similarity_score: 1, // hardcoded to 1 for custom keywords
                              source: "Custom", // fixed to Custom
                              link_type: "Custom" // fixed to Custom
                            }
                            setGenericKeywords([
                              ...genericKeywords,
                              newKeyword
                            ]);
                            setData("competitordirect", "");
                            setShowInput("");
                          }
                        }}
                      />
                    )}
                    <div className="add-button">
                      <button
                        className="p-2 m-1 text-lg text-white border border-blue-500 rounded-pill d-flex font-weight-bold h-75"
                        onClick={() => {
                          setData("competitordirect", "");
                          setShowInput("competitordirect");
                        }}
                        style={{
                          backgroundColor: "#0769FB",
                        }}
                      >
                        <img src={addIcon} height={12} width={12} alt="add" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="my-4 text-sm rounded-pill">
              <div
                style={{ borderRadius: "10px" }}
                className={`${
                  !data.disabledFields
                    ? "bg-white rounded-t-xl shadow-sm border"
                    : "rounded-t-xl shadow-sm border"
                }`}
              >
                <div style={{ padding: "0.25rem", lineHeight: "8px" }}>
                  <div className="d-flex align-items-baseline justify-content-between">
                    <div>
                      <h1
                        className={`${
                          data.disabledFields ? "text-sm" : "h5"
                        } pt-1 ml-2 font-semibold`}
                      >
                        Lateral
                      </h1>
                    <div
                    className="ml-2"
                    style={{ color: "#767676", fontSize: "12px" }}
                  >
                    Keywords that are not directly related to your brand and industry
                  </div>
                    </div>
                    <div className="d-flex" style={{gap:'0.75rem',  height:'100%', alignItems:'center'}}>
                    <div
                      style={{
                        display:'flex', alignItems:'center', padding:'0.375rem 0.75rem', gap:'0.5rem', borderRadius:'0.375rem', border:'1.5px solid #E0E3E5',fontSize:'0.75rem', fontWeight:'500', color:'#394455',
                        cursor: "pointer",
                        opacity: lateralKeywords?.length ? 1 : 0.5,
                      }}
                      onClick={() => setLateralKeywords([])}
                    >
                      Clear All
                    </div>
                    <button 
                    onClick={()=>{
                      const moreKeywords=all_keywords?.Lateral?.slice(lateralLoadedKeywords,lateralLoadedKeywords+7) 
                      setLateralKeywords([...lateralKeywords,...moreKeywords])
                      dispatch(setLoadedKeywords({keywordType: 'lateralLoadedKeywords', loadedKeywords: lateralLoadedKeywords+7}))
                    }}
                    disabled={lateralLoadedKeywords>=all_keywords?.Lateral?.length}
                    className="d-flex" 
                    style={{ opacity: lateralLoadedKeywords>=all_keywords?.Lateral?.length ? 0.5 : 1, alignItems:'center', gap:'0.5rem', padding:'0.375rem 0.75rem', borderRadius:'0.375rem', border:'1.5px solid #E0E3E5', background:'white'}}>
                      <Refresh/>
                      <p style={{fontSize:'0.75rem', fontWeight:'500', margin:'0', color:'#394455'}}>
                        Load more keywords {`(${lateralKeywords?.length}/${all_keywords?.Lateral?.length})`}
                      </p>
                    </button>
                    </div>
                  </div>
                  
                </div>
                <hr style={{ margin: "8px 0 2px 0" }} />
                {!data.disabledFields && (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-wrap pt-1 space-x-2 overflow-auto d-flex h-25 w-100">
                      {lateralKeywords?.map((element, index) => {
                        return (
                          <div
                            key={index}
                            className="m-2 border d-flex align-items-center text-primary border-primary rounded-pill "
                          >
                            <div className="kw-chip-custom-style">
                              {element.keyword}
                            </div>
                            <button
                              className="m-0 chip-remove-custom-style text-primary"
                              onClick={() => {
                                setLateralKeywords((vals) =>
                                  vals.filter((val, idx) => idx !== index)
                                );
                              }}
                            >
                              <div className="chip-remove-custom-style">
                                <img
                                  src={closeIcon}
                                  height={8}
                                  width={8}
                                  alt="close"
                                />
                              </div>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    {showInput === "brandindirect" && (
                      <input
                        className="w-10 p-2 border border-primary rounded-pill"
                        id="text"
                        name="brandindirect"
                        value={input.brandindirect}
                        onChange={(e) => {
                          setData("brandindirect", e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.target?.value) {
                            const newKeyword={
                              keyword: input.brandindirect,
                              Brand_keyword_similarity_score: 1, // hardcoded to 1 for custom keywords
                              Normalized_Brand_keyword_similarity_score: 1, // hardcoded to 1 for custom keywords
                              source: "Custom", // fixed to Custom
                              link_type: "Custom" // fixed to Custom
                            }
                            setLateralKeywords([
                              ...lateralKeywords,
                              newKeyword
                            ]);
                            setData("brandindirect", "");
                            setShowInput("");
                          }
                        }}
                      />
                    )}
                    <div className="add-button">
                      <button
                        className="p-2 m-1 text-lg text-white border border-blue-500 rounded-pill d-flex font-weight-bold h-75"
                        onClick={() => {
                          setData("brandindirect", "");
                          setShowInput("brandindirect");
                        }}
                        style={{
                          backgroundColor: "#0769FB",
                        }}
                      >
                        <img src={addIcon} height={12} width={12} alt="add" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>


            <div className="mt-4 text-sm rounded-pill">
              <div
                style={{ borderRadius: "10px" }}
                className={`${
                  !data.disabledFields
                    ? "bg-white rounded-t-xl shadow-sm border"
                    : "rounded-t-xl shadow-sm border"
                }`}
              >
                <div style={{ padding: "0.25rem", lineHeight: "8px" }}>
                  <div className="d-flex align-items-baseline justify-content-between">
                    <div className="d-flex align-items-baseline">
                      <h1
                        style={{opacity:!enableCustomKeywords && '0.5',pointerEvents:!enableCustomKeywords && 'none'}}
                        className={`${
                          data.disabledFields ? "text-sm" : "h5"
                        } pt-1 ml-2 mr-1 font-semibold`}
                      >
                        Custom Keywords
                      </h1>
                      <div style={{marginLeft:'0.1rem'}} onClick={()=>dispatch(setEnableCustomKeywords({enableCustomKeywords:!enableCustomKeywords}))}>
                      <AntSwitch  checked={enableCustomKeywords} 
                      inputProps={{ 'aria-label': 'ant design' }} 
                      sx={{marginLeft:'5px', cursor:'pointer'}}
                      />
                      </div>
                    </div>
                    <div
                      style={{
                        display:'flex', alignItems:'center', padding:'0.375rem 0.75rem', gap:'0.5rem', borderRadius:'0.375rem', border:'1.5px solid #E0E3E5',fontSize:'0.75rem', fontWeight:'500', color:'#394455',
                        cursor: "pointer",
                        opacity: (customKeywords?.length && enableCustomKeywords) ? 1 : 0.5,
                        pointerEvents:!enableCustomKeywords && 'none'
                      }}
                      onClick={() => setCustomKeywords([])}
                    >
                      Clear All
                    </div>
                  </div>
                  <div
                    className="ml-2"
                    style={{ color: "#767676", fontSize: "12px", opacity:!enableCustomKeywords && '0.5', pointerEvents:!enableCustomKeywords && 'none' }}                  
                  >
                    You can set keywords around a specific event to get the recommendations around it
                  </div>
                </div>
                <hr style={{ margin: "8px 0 2px 0" }} />
                {!data.disabledFields && (
                  <div className="d-flex align-items-center justify-content-between" style={{opacity:!enableCustomKeywords && '0.5',pointerEvents:!enableCustomKeywords && 'none'}}>
                    <div className="flex-wrap pt-1 space-x-2 overflow-auto d-flex h-25 w-100">
                      {customKeywords?.map((element, index) => {
                        return (
                          <div
                            key={index}
                            className="m-2 border d-flex align-items-center text-primary border-primary rounded-pill"
                          >
                            <div className="kw-chip-custom-style">
                              {element.keyword}
                            </div>
                            <button
                              className="m-0 chip-remove-custom-style text-primary"
                              onClick={() => {
                                setCustomKeywords((vals) =>
                                  vals.filter((val, idx) => idx !== index)
                                );
                              }}
                            >
                              <div>
                                <img
                                  src={closeIcon}
                                  height={8}
                                  width={8}
                                  alt="close"
                                />
                              </div>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    {showInput === "custom" && (
                      <input
                        className="w-10 p-2 border border-primary rounded-pill"
                        id="text"
                        name="custom"
                        value={input.custom}
                        onChange={(e) => {
                          setData("custom", e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.target?.value) {
                            const newKeyword={
                              keyword:  input.custom,
                              Brand_keyword_similarity_score: 1, // hardcoded to 1 for custom keywords
                              Normalized_Brand_keyword_similarity_score: 1, // hardcoded to 1 for custom keywords
                              source: "Custom", // fixed to Custom
                              link_type: "Custom" // fixed to Custom
                            }
                            setCustomKeywords([...customKeywords,newKeyword]);
                            setData("custom", "");
                            setShowInput("");
                          }
                        }}
                      />
                    )}
                    <div className="add-button">
                      <button
                        className="p-2 m-1 text-lg text-white border border-blue-500 rounded-pill d-flex font-weight-bold h-75"
                        onClick={() => {
                          setData("custom", "");
                          setShowInput("custom");
                        }}
                        style={{
                          backgroundColor: "#0769FB",
                        }}
                      >
                        <img src={addIcon} height={12} width={12} alt="add" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!data.disabledFields && (
            <div className="p-2 border-b d-flex justify-content-between align-items-center">

            <div className='d-flex' style={{marginLeft:'0.5rem'}}>
              <div className='d-flex' style={{fontSize:'0.875rem', fontWeight:'400', gap:'0.25rem', alignItems:'center'}}>
                Exclude active targetings in this AI group
                <Tooltip title='On enabling this option, all active targetings of adsets present in this AI group will be excluded/negated while generating cohort recommendations' arrow>
                <img
                  style={{height:'1rem', width:'1rem', cursor:'pointer'}}
                  src={infoIcon}
                  alt="info"
                />
                </Tooltip>
              </div>
              <Switch 
                checked={negateTargeting}
                onClick={()=>dispatch(setNegateTargeting({negateTargeting:!negateTargeting}))}
              />
              </div>

              <button
                className="p-2 px-4 m-1 text-lg text-white border border-blue-500 d-flex font-weight-bold"
                onClick={() => {
                  handleSubmit();
                }}
                style={{
                  backgroundColor: "#0769FB",
                  borderRadius: "8px",
                  opacity:generateAudienceButtonDisable ? 0.5 : 1
                }}
                disabled={generateAudienceButtonDisable}
              >
                Generate Audiences
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default KeywordSetup;

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));