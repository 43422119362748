export const SET_SELECTED_MONTH_YEAR = "SET_SELECTED_MONTH_YEAR";
export const GET_ALL_PLATFORMS_SMB = "GET_ALL_PLATFORMS_SMB";
export const SET_ALL_PLATFORMS_SMB = "SET_ALL_PLATFORMS_SMB";
export const SET_SELECTED_OPTIMIZER_SMB = "SET_SELECTED_OPTIMIZER_SMB";
export const GET_AGENCY_DETAIL_BY_BRAND = "GET_AGENCY_DETAIL_BY_BRAND";

export type MonthYear = {
  selectedMonth: number;
  selectedYear: string;
};

export type SetSelectedMonthYear = {
  type: typeof SET_SELECTED_MONTH_YEAR;
  payload: MonthYear;
};

export type CommonState = {
  calendar: {
    selectedMonth: number;
    selectedYear: number;
  };
  platforms: any;
  selectedOptimizerToRedirect: any;
};

export type GetAllPlatformsSmb = {
  type: typeof GET_ALL_PLATFORMS_SMB;
};

export type SetAllPlatformsSmb = {
  type: typeof SET_ALL_PLATFORMS_SMB;
  payload: any;
};

export type SetSelectedOptimizerSmb = {
  type: typeof SET_SELECTED_OPTIMIZER_SMB;
  payload: any;
};

export type GetAgencyDetialByBrand = {
  type: typeof GET_AGENCY_DETAIL_BY_BRAND;
  payload: { brandId: string };
  callback: Function;
};
