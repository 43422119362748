import { Box, styled } from "@mui/system";

interface TextFieldProps {
  active: boolean;
}
export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1em;
  justify-content: center;
  flex-direciton: column;
  align-items: center;
  //   background: rgba(227, 238, 255, 0.75);
  background-image: linear-gradient(
    to right top,
    #eaf0ff,
    #ecf1ff,
    #eef3ff,
    #eff4ff,
    #f1f5ff,
    #f0f4ff,
    #eff3fe,
    #eef2fe,
    #ebeffd,
    #e8ebfc,
    #e6e8fa,
    #e4e4f9
  );
  //   backdrop-filter: blur(40px);
`;

export const StyledOnBoardingHeaderMainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
`;

export const StyledOnBoardingHeaderContainerSmall = styled(Box)`
  font-family: Inter;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4286em;
  letter-spacing: 0.015em;
`;

export const StyledOnBoardingHeaderContainerLarge = styled(Box)`
  font-family: Inter;
  font-size: 2em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3125em;
  letter-spacing: -0.025em;
  text-align: center;
  width: 17.21875em;
  height: auto;
`;

export const StyledOnBoardingOtherMethodsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.62em;
`;

export const StyledTextFieldMainContainer = styled(Box)`
  border-radius: 1em;
  background-image: linear-gradient(
    to right,
    #d3e6fc,
    #e1ebfd,
    #edf1fe,
    #f7f8fe,
    #ffffff
  );
  display: flex;
  width: 48.0625em;
  height: 7.8125em;
  justify-content: center;
  align-items: center;
  gap: 0.96875em;
  flex-shrink: 0;
`;
export const StyledTextFieldSubContainer = styled(Box)`
  border-radius: 1em;
  background: linear-gradient(
    91deg,
    rgba(37, 130, 239, 0.35) 2.08%,
    rgba(92, 117, 248, 0.9) 98.08%
  );

  display: flex;
  width: 47.9375em;
  height: 7.6875em;
  justify-content: center;
  align-items: center;
  gap: 0.96875em;
  flex-shrink: 0;
`;
export const StyledTextFieldContainer = styled(Box)`
  border-radius: 1em;
  background: white;
  display: flex;
  width: 47.8125em;
  height: 7.5625em;
  padding: 0.96875em 1.09375em 1.25em 1.0625em;
  justify-content: center;
  align-items: flex-start;
  gap: 0.96875em;
  flex-shrink: 0;
  border: 2px solid rgba(0, 100, 251, 0.1);
  background: #f6f7f8;
`;

export const StyledUTGImgCOntainer = styled(Box)`
  background: #e3eeff;
  width: 2.5em;
  height: 2.5em;
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledUTGDropdownMainContainer = styled(Box)<TextFieldProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 49.0625em;
  border-radius: 1.5em;
  padding: 0.75em 0.5em;
  min-height: auto;
  max-height: ${(props) => (props.active ? "20.5625em" : "auto")};
  background: ${(props) => (props.active ? "white" : "")};
`;

export const StyledSuggestDropdown = styled(Box)`
  margin-top: 1em;
  margin-left: 1.5em;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  color: #000;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375em;
  letter-spacing: 0.00875em;
  gap: 0.25em;
  width: -webkit-fill-available;
`;

export const StyledSuggestDropdownHeader = styled(Box)`
  color: #aeb6c4;
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1.25em;
  letter-spacing: 0.0075em;
`;
export const StyledSuggestDropdownBody = styled(Box)<TextFieldProps>`
  width: 100%;
  border-radius: 0.5em;
  padding: 0.25em 0.5em;
  cursor: pointer;
  background: ${(props) => (props.active ? "#F3F8FF" : "")};
`;
