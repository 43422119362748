import styled from "styled-components";

export const StyledAvatar = styled.img`
  border-radius: 50%;
  height: 30.62px;
  width: 30.62px;
`;
export const StyledPreviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 7px 5px 0 10px;
  margin-left: -5px;
  div iframe {
    display: block;
  }
`;

export const StyledAvatarContainer = styled.div`
  padding-right: 6px;
  margin-bottom: 9px;
`;
export const StyledProfileName = styled.div`
  font-weight: 700;
`;
export const StyledProfile = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
export const StyledProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
`;
export const StyledPrimaryText = styled.div`
  padding: 0px 10px 0px 10px;
  margin: 0 0px 8px 0px;
  margin-left: -5px;
  line-height: 13px;
  width: 250px;
  font-weight: 600px;
  font-family: Roboto, Arial, Sans-serif;
  font-size: 11px;
`;
export const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 6px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 6px;
  color: #616770;
  font-weight: 600;
  background: white;
  border-radius: 8px;
`;
export const StyledFooterItem = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StyledCTAButton = styled.button`
  border: none;
  border-radius: 4px;
  white-space: nowrap;
  height: 27px;
  padding: 0 12px;
  line-height: 19.9px;
  font-weight: 600;
  font-size: 11px;
  font-family: system-ui, Roboto, Arial, sans-serif;
`;
export const StyledCTAButtonContainer = styled.div`
  margin-left: 12px;
  display: flex;
  flex: 1.5;
  align-items: center;
`;
export const StyledFacebookIcon = styled.div`
  margin-right: 5px;
`;

export const StyledContentContainer = styled.div`
  padding: 12px;
  background-color: #f7f8fa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledDescription = styled.div`
  line-height: 20px;
  color: rgb(70, 73, 80);
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
`;
export const StyledHeadline = styled.div`
  font-weight: bold;
  font-family: "Roboto", Arial, sans-serif;
  max-height: 40px;
  font-size: 11.4px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
`;

export const StyledSeeMore = styled.a`
  // position: absolute;
  float: right;
  //  right: 3.6rem;
  text-decoration: none;
  color: grey;
  &:hover {
    text-decoration: underline;
    color: grey;
  }
`;
export const StyleDivider = styled.div`
  margin-top: 10px;
  // background-color:#CEDOD4;
  background-color: lightgray;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  width: 230px;
`;
export const StyledPreviewFrame = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  line-height: 1.34;
  color: #1c1e21;
  display: flex;
  align-items: center;
  flex: 1;
  min-height: ${({ origin }: { origin: "homescreen" | "editor" }) =>
    origin === "editor" ? "444px" : "0px"};
  max-height: ${({ origin }: { origin: "homescreen" | "editor" }) =>
    origin === "editor" ? "444px" : undefined};
  overflow: hidden;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  border-radius: 8px;
  /* margin-top: 12px; */
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 1px solid rgb(0, 0, 0, 0.05);
  font-size: 12px;
`;

export const StyledDisplayURL = styled.div`
  color: rgb(70, 73, 80);
  font-size: 9.9px;
`;
export const GlobalIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const HeaderIconContainer = styled.div`
  width: 18.75px;
  height: 18.75px;
`;

export const SponsoredContainer = styled.div`
  font-size: 9.3px;
  color: #90949c;
  display: flex;
`;
export const PreviewFrameContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  transform-origin: center center;
`;
export const StyledMidSectionContainer = styled.div`
  padding: 8px;
  background-color: #f7f8fa;
  border-bottom: 1px solid lightgrey;
`;

export const StyledMediaSection = styled.div`
  background: white;
  // padding-bottom: 9px;
`;

export const StyledMidSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledSponsoredText = styled.div`
  margin-right: 2px;
  display: flex;
  align-items: end;
`;
