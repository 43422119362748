import { Box, styled } from "@mui/material";

export const StyledVariantHeader = styled(Box)`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 39px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em 0.5em 1em 0.75em;
  gap: 0.6875em;
  width: 18.75em;
  height: 4.6875em;
  border-radius: 8px 8px 0px 0px;
`;

export const StyledVariantBottom = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 0px 39px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.625em;
  height: 27.25em;
  border-radius: 0px 0px 8px 8px;
  margin-top: 3px;
  min-height: 0;
  width: 18.75em;
`;
export const StyledAnimatorTagContainer = styled(Box)`
  height: 2em;
  width: 2em;
  position: absolute;
  top: -0.625em;
  left: -0.625em;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #d9e8ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledVariantTag = styled(Box)`
  width: 2.6875em;
  height: 2.6875em;
  position: relative;
`;

export const StyledNewTagContainer = styled("span")``;

export const StyledNewTag = styled("a")`
  background: #0869fb;
  border-radius: 3px 0 0 3px;
  color: white;
  font-weight: bold;
  display: inline-block;
  height: 18px;
  width: 30px;
  font-size: 10px;
  line-height: 18px;
  padding: 0 5px 0 6px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  animation: left-to-right-animate 1s ease 5;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  pointer-events: none;

  ::after {
    border-bottom: 8.5px solid transparent;
    border-left: 9px solid #0869fb;
    border-top: 8.5px solid transparent;
    content: "";
    position: absolute;
    right: -8px;
    top: 0.5px;
  }

  :hover {
    text-decoration: none;
    color: white;
  }

  @keyframes left-to-right-animate {
    0% {
      width: 0px;
    }
    100% {
      transform: 15px;
    }
  }
`;
