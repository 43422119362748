import React, { Fragment } from "react";
import DropdownUI from "../Dropdown/Dropdown";
import { Col } from "react-bootstrap";
import { ReactComponent as CharmMenuMeatballNew } from "../../assets/svg/charm_menu-meatball-new.svg";
import NavigationDropDownItem from "../../components/Navigation/NavigationDropDownItem";
import videoPlay from "../../assets/png/videoPlay.png";
import { ImageVideoCardItemType } from "./ImageVideoCard.types";

export default function ImageVideoCardItem({
  item,
  showVideo,
  handelViewVideo,
  dropdownData,
  index,
}: {
  item: ImageVideoCardItemType;
  showVideo: boolean;
  handelViewVideo: (item: ImageVideoCardItemType) => void;
  dropdownData: any;
  index: number;
}) {
  const [isDropdwonVisible, setIsDropdwonVisible] = React.useState(false);

  return (
    <Fragment>
      {item?.url?.split(".").pop() === "jpeg" ||
      item?.url?.split(".").pop() === "png" ? (
        <Col lg={3} className="image-video-content ">
          <div className="image-video-content-div">
            <div className="image-video-content-head">
              <DropdownUI
                isDropDownVisible={isDropdwonVisible}
                setIsDropDownVisible={setIsDropdwonVisible}
                toggelButton={
                  <span
                    className={
                      showVideo
                        ? "image-video-content-head-dot-modal-view"
                        : "image-video-content-head-dott"
                    }
                  >
                    <CharmMenuMeatballNew />
                  </span>
                }
                className={
                  showVideo
                    ? "image-video-content-dropdown-modal-open"
                    : "image-video-content-dropdown"
                }
              >
                {dropdownData && (
                  <NavigationDropDownItem
                    onSelect={(id: any) => {
                      const result = dropdownData.find((g: any) => g.id === id);
                      result?.clickEvent(index, item);
                    }}
                    data={dropdownData}
                    setVisible={setIsDropdwonVisible}
                  />
                )}
              </DropdownUI>
            </div>
            <img src={item?.url} alt="" className="image-video-card-file" />
          </div>
          <p className="image-video-para" title={item?.name}>
            {item?.name}
          </p>
        </Col>
      ) : (
        <Col lg={3} className="image-video-content ">
          <div className="image-video-content-div">
            <div className="image-video-content-head">
              <DropdownUI
                setIsDropDownVisible={setIsDropdwonVisible}
                isDropDownVisible={isDropdwonVisible}
                className={
                  showVideo
                    ? "image-video-content-dropdown-modal-open"
                    : "image-video-content-dropdown"
                }
                toggelButton={
                  <span
                    className={
                      showVideo
                        ? "image-video-content-head-dot-modal-view"
                        : "image-video-card-content-head-dot"
                    }
                  >
                    <CharmMenuMeatballNew />
                  </span>
                }
              >
                {dropdownData && (
                  <NavigationDropDownItem
                    onSelect={(id: any) => {
                      const result = dropdownData.find((g: any) => g.id === id);
                      result?.clickEvent(index, item);
                    }}
                    data={dropdownData}
                    setVisible={setIsDropdwonVisible}
                  />
                )}
              </DropdownUI>
            </div>

            <video className="image-video-card-file" src={item?.url} />
            <span
              className="video-play-btn"
              onClick={() => handelViewVideo(item)}
            >
              <img src={videoPlay} alt="" />{" "}
            </span>
          </div>
          <p
            className="image-video-para"
            style={{ marginTop: "-7px" }}
            title={item?.name}
          >
            {item?.name}
          </p>
        </Col>
      )}
    </Fragment>
  );
}
