import { RecommendationDataType } from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";

export const GET_ADS_LIST = "GET_ADS_LIST";
export const GET_VARIANT_LIST = "GET_VARIANT_LIST";
export const SET_VARIANT_LIST = "SET_VARIANT_LIST";
export const GET_RECOMMENDATION_METADATA = "GET_RECOMMENDATION_METADATA";
export const SET_RECOMMENDATION_METADATA = "SET_RECOMMENDATION_METADATA";
export const UPDATE_RECOMMENDATION_METADATA = "UPDATE_RECOMMENDATION_METADATA";
export const ADD_VARIANT = "ADD_VARIANT";
export const ADD_VARIANT_BUTTON = "ADD_VARIANT_BUTTON";
export const SET_ADD_VARIANT_RESPONSE = "SET_ADD_VARIANT_RESPONSE";
export const GET_CREATE_SPEC = "GET_CREATE_SPEC";
export const SET_CREATE_SPEC = "SET_CREATE_SPEC";
export const SET_ADS = "SET_ADS";
export const SET__SELECTED_ADS = "SET__SELECTED_ADS";
export const GET_AD_PREVIEW_LINK = "GET_AD_PREVIEW_LINK";
export const SET_AD_PREVIEW_LINK = "SET_AD_PREVIEW_LINK";
export const DELETE_VARIANT_API_CALL = "DELETE_VARIANT_API_CALL";
export const DELETE_VARIANT_STATE_UPDATE = "DELETE_VARIANT_STATE_UPDATE";
export const UPDATE_EXTRA_FIELD = "UPDATE_EXTRA_FIELD";
export const UPDATE_VARIANT = "UPDATE_VARIANT";
export const UPDATE_VARIANT_STATE = "UPDATE_VARIANT_STATE";
export const ADD_UPDATE_VARIANT_LOADING_STATE =
  "ADD_UPDATE_VARIANT_LOADING_STATE";
export const UPDATE_RECOMMENDATION_SELECTION_STATE =
  "UPDATE_RECOMMENDATION_SELECTION_STATE";
export const GENERATE_AD_PREVIEW = "GENERATE_AD_PREVIEW";
export const COPY_VARIANT = "COPY_VARIANT";
export const COPY_VARIANT_STATE = "COPY_VARIANT_STATE";

export const UPDATE_ADS = "UPDATE_ADS";
export const UPDATE_TOTAL_VARIANT_SELECTED = "UPDATE_TOTAL_VARIANT_SELECTED";
export const UNSELECT_TOTAL_VARIANTS = "UNSELECT_TOTAL_VARIANTS";
export const DISCARD_DRAFT_VARIANT = "DISCARD_DRAFT_VARIANT";
export const PUBLISH_VARIANT = "PUBLISH_VARIANT";
export const RE_PUBLISH_VARIANT = "RE_PUBLISH_VARIANT";
export const PUBLISH_VARIANT_STATE = "PUBLISH_VARIANT_STATE";
export const RE_PUBLISH_VARIANT_STATE = "RE_PUBLISH_VARIANT_STATE";
export const GENERATE_VARIANT_METADATA = "GENERATE_VARIANT_METADATA";
export const RESET_STATE = "RESET_STATE";
export const UPDATE_CREATE_SPEC = "UPDATE_CREATE_SPEC";
export const DISCARD_DRAFT_VARIANT_STATE = "DISCARD_DRAFT_VARIANT_STATE";
export const SET_RECOMMENDATION_LOADING = "SET_RECOMMENDATION_LOADING";
export const GENERATE_VARIANT_METADATA_STATE =
  "GENERATE_VARIANT_METADATA_STATE";
export const IS_CONFIGURING_STATE = "IS_CONFIGURING_STATE";
export const Is_Show_SaveVariant_Prompt = "Is_Show_SaveVariant_Prompt";

export const UPDATE_AI_GROUP = "UPDATE_AI_GROUP";
export const UPDATE_AI_GROUP_STATE = "UPDATE_AI_GROUP_STATE";
export const SET_UPDATE_AI_GROUP_PROMPT = "SET_UPDATE_AI_GROUP_PROMPT";
export const GENERATE_VARIANT_METADATA_STATE_VARIANT =
  "GENERATE_VARIANT_METADATA_STATE_VARIANT";
export const SET_RECOMMENDATION_METADATA_ERROR =
  "SET_RECOMMENDATION_METADATA_ERROR";

export const SET_TIMESTAMP = "SET_TIMESTAMP";
export const UPDATE_CONFIGURE_STATE = "UPDATE_CONFIGURE_STATE";
export const GENERATE_AD_PREVIEW_VARIANT = "GENERATE_AD_PREVIEW_VARIANT";
export const GENERATE_AD_PREVIEW_VARIANT_STATE =
  "GENERATE_AD_PREVIEW_VARIANT_STATE";
export const GENERATE_AD_PREVIEW_BUNDLE = "GENERATE_AD_PREVIEW_BUNDLE";
export const SET_CHANGED_FIELD_LIST = "SET_CHANGED_FIELD_LIST";
export const GENERATE_AD_PREVIEW_VARIANT_LOADING_STATE =
  "GENERATE_AD_PREVIEW_VARIANT_LOADING_STATE";
export const SET_AUTOGENERATE_LOADING = "SET_AUTOGENERATE_LOADING";
export const SET_LOADING_COMPLETE = "SET_LOADING_COMPLETE";
export const SET_FETCHING_COMPLETE = "SET_FETCHING_COMPLETE";
export const SUBMIT_FOR_REVIEW = "SUBMIT_FOR_REVIEW";
export const SUBMIT_FOR_REVIEW_STATE = "SUBMIT_FOR_REVIEW_STATE";
export const APPROVER_LINK = "APPROVER_LINK";
export const SUBMIT_FOR_REVIEW_ERROR = "SUBMIT_FOR_REVIEW_ERROR";
export const DELETE_SELECTED_VARIANT = "DELETE_SELECTED_VARIANT";
export const SET_DELETE_SELECTED_VARIANT = "SET_DELETE_SELECTED_VARIANT";

export const SET_FILTERED_VARIANT_LIST = "SET_FILTERED_VARIANT_LIST";
export const SET_ADS_OTHER_STATE = "SET_ADS_OTHER_STATE";
export const SET_AUTOGENERATE_RESPONSE = "SET_AUTOGENERATE_RESPONSE";
export const SET_VARIANTS_NEW_TAGS = "SET_VARIANTS_NEW_TAGS";
export const SET_FILTERED_VARIANTS_LOADING = "SET_FILTERED_VARIANTS_LOADING";

export interface CreateVariantsState {
  adsList: any;
  variantList: VariantItem[];
  filteredVariantList: VariantItem[];
  recommendationMetaData: any;
  recommendationMetaDataTemplate: any;
  addVariantResponse?: AddVariantResponse;
  createSpec?: any;
  totalSelectedVariant: string[];
  isPublishingVariant: boolean;
  isRePublishingVariant?: string | false;
  isSubmittingReviewVariant: boolean;
  approverLink: {
    isLoading: boolean;
    isError: boolean;
    isData: string;
  };
  adsSelected: number[];
  submitReviewError: boolean;
  isUpdatingVariant: boolean;
  isDeletingVariant: boolean;
  isDiscardingDraft: boolean;
  fetchingAds: boolean;
  isUpdateAds: boolean;
  isConfiguringMetaData: boolean;
  isGeneratingMetaData: boolean;
  loadingRecommendation: boolean;
  isShowSaveVariantPrompt: boolean;
  showUpdateAISuccessMsg: boolean;
  ads?: any;
  selectedAd?: any;
  adPreviewLink?: any;
  extraFields: {
    isVariantEditing?: boolean;
    selectedVariantIndex?: number;
    showEditor?: boolean;
    isCloned?: boolean;
  };
  addUpdateVariantLoading: boolean;
  recommendationSelectionState: {
    primary_text: string;
    headline: string;
    description: string;
    image: string;
    video: string;
    adPreviewLink: any;
    variantName: string;
    isChanged: boolean;
    isConfigureChanged: boolean;
  };
  generateMetaData: any;
}

export type ResetState = {
  type: typeof RESET_STATE;
  payload: any;
};

export type SetChangedFieldList = {
  type: typeof SET_CHANGED_FIELD_LIST;
  payload: any;
};

export type GetAdsListValues = {
  type: typeof GET_ADS_LIST;
  payload: any;
};
export type AddVarianButton = {
  type: typeof ADD_VARIANT_BUTTON;
  payload: any;
};

export type GetVariantList = {
  type: typeof GET_VARIANT_LIST;
  payload: {
    adId: string;
  };
};

export type SetVariantList = {
  type: typeof SET_VARIANT_LIST;
  payload: {
    data: VariantItem[];
  };
};
export type SetApproverLink = {
  type: typeof APPROVER_LINK;
  payload: { urlHash: string };
};

export type GetRecommendationMetadata = {
  type: typeof GET_RECOMMENDATION_METADATA;
  payload: any;
};

export type SetRecommendationMetadata = {
  type: typeof SET_RECOMMENDATION_METADATA;
  payload: {
    data: RecommendationMetaData;
  };
};
export type UpdateRecommendationMetadata = {
  type: typeof UPDATE_RECOMMENDATION_METADATA;
  payload: {
    data: RecommendationMetaData;
  };
};
export type AddVariant = {
  type: typeof ADD_VARIANT;
  payload: {
    user: any;
    params: AddVariantPayload;
    adAccountId: string;
    creativeSpec: any;
    finalAction: typeof SET_ADD_VARIANT_RESPONSE | typeof COPY_VARIANT;
    callback?: any;
  };
};

export type SetAddVariantResponse = {
  type: typeof SET_ADD_VARIANT_RESPONSE;
  payload: AddVariantResponse;
};

export type GetCreateSpec = {
  type: typeof GET_CREATE_SPEC;
  payload: any;
};
export type SetCreateSpec = {
  type: typeof SET_CREATE_SPEC;
  payload: any;
};

export type SetAds = {
  type: typeof SET_ADS;
  payload: any;
};

export type DiscardDraftVariantState = {
  type: typeof DISCARD_DRAFT_VARIANT_STATE;
  payload: any;
};

export type UpdateAds = {
  type: typeof UPDATE_ADS;
  payload: any;
};

export type SetSelectedAds = {
  type: typeof SET__SELECTED_ADS;
  payload: any;
};

export type GetAdPreviewLink = {
  type: typeof GET_AD_PREVIEW_LINK;
  payload: any;
};

export type SetAdPreviewLink = {
  type: typeof SET_AD_PREVIEW_LINK;
  payload: any;
};

export type DeleteVariantAPICall = {
  type: typeof DELETE_VARIANT_API_CALL;
  payload: any;
};

export type DeleteVariantStateUpdate = {
  type: typeof DELETE_VARIANT_STATE_UPDATE;
  payload: any;
};

export type UpdateExtraField = {
  type: typeof UPDATE_EXTRA_FIELD;
  payload: any;
};

export type UpdateVariant = {
  type: typeof UPDATE_VARIANT;
  payload: any;
};
export type CopyVariant = {
  type: typeof COPY_VARIANT;
  payload: any;
};

export type UpdateVariantState = {
  type: typeof UPDATE_VARIANT_STATE;
  payload: any;
};
export type AddUpdateVariantLoadingState = {
  type: typeof ADD_UPDATE_VARIANT_LOADING_STATE;
  payload: any;
};

export type UpdateRecommendationSelectionState = {
  type: typeof UPDATE_RECOMMENDATION_SELECTION_STATE;
  payload: any;
};

export type GenerateAdPreview = {
  type: typeof GENERATE_AD_PREVIEW;
  payload: any;
};
export type GenerateAdPreviewBundle = {
  type: typeof GENERATE_AD_PREVIEW_BUNDLE;
  payload: any;
};
export type UpdateTotalVariantSelected = {
  type: typeof UPDATE_TOTAL_VARIANT_SELECTED;
  payload: any;
};
export type DiscardDraftVariant = {
  type: typeof DISCARD_DRAFT_VARIANT;
  payload: any;
};

export type PublishVariant = {
  type: typeof PUBLISH_VARIANT;
  payload: any;
};
export type RePublishVariant = {
  type: typeof RE_PUBLISH_VARIANT;
  payload: any;
};
export type PublishVariantState = {
  type: typeof PUBLISH_VARIANT_STATE;
  payload: any;
};
export type SubmitForReview = {
  type: typeof SUBMIT_FOR_REVIEW;
  payload: {
    params: any;
  };
};
export type SubmitForReviewError = {
  type: typeof SUBMIT_FOR_REVIEW_ERROR;
  payload: any;
};

export type UpdateCreateSpec = {
  type: typeof UPDATE_CREATE_SPEC;
  payload: any;
};

// response types
export interface VariantItem {
  id: string;
  adId: string;
  creativeAiId: string;
  status: string;
  payload: any;
  createdAt: string;
  updatedAt: string;
  checked?: boolean;
  CreativeAiData: {
    id: string;
    primaryText: {
      name: string;
    };
    headline: {
      name: string;
    };
    description: {
      name: string;
    };
    createdAt: string;
    updatedAt: string;
    billboardMediaData?: any;
    billboardMediaId?: any;
  };
}
export interface RecommendationMetaData {
  headline: RecommendationMetaDataItem;
  description: RecommendationMetaDataItem;
  primary_text: RecommendationMetaDataItem;
  image: Image_Recommendation_List;
  video: RecommendationMetaDataItem;
}
export interface RecommendationMetaDataItem {
  data: Array<RecommendationDataType>;
  selected: string;
  noOfElementToShow: number;
  showMore: boolean;
}

export interface Image_Recommendation {
  data: ImageLayerType[];
  id: string;
}

export interface ImageLayerType {
  layer_name: string;
  layer_text: string;
}

export interface VideoLayerType {
  layer_name: string;
  layer_text: string;
  frame_name: string;
}

export interface VideoRecommendation {
  data: VideoLayerType[];
  noOfElements: number;
}
export type VideoRecommendationItem = VideoLayerType[];

export interface Image_Recommendation_List {
  data: Image_Recommendation[];
  selected: string;
  noOfElementToShow: number;
  showMore: boolean;
}
export interface AddVariantPayload {
  headline: { name: string };
  description: { name: string };
  primaryText: { name: string };
  adId: string;
  payload: Object;
}

export interface AddVariantResponse {
  id: string;
  status: string;
  adId: string;
  payload: Object;
  creativeAiId: string;
  updatedAt: string;
  createdAt: string;
}

export type GenerateVariantMatadata = {
  type: typeof GENERATE_VARIANT_METADATA;
  payload: any;
  callback: any;
};

export type SetAutoGenerateLoading = {
  type: typeof SET_AUTOGENERATE_LOADING;
  payload: Boolean;
};

export type SetLoadingComplete = {
  type: typeof SET_LOADING_COMPLETE;
  payload: boolean;
};

export type SetFetchingComplete = {
  type: typeof SET_FETCHING_COMPLETE;
  payload: boolean;
};

// delete selected variants
export interface DeleteSelectedVariants {
  type: typeof DELETE_SELECTED_VARIANT;
  payload: DeleteSelectedVariantsPayload;
}

export interface DeleteSelectedVariantsPayload {
  variantIds: string[];
  callback: any;
}

export interface SetDeleteSelectedVariants {
  type: typeof SET_DELETE_SELECTED_VARIANT;
  payload: DeleteSelectedVariantsPayload;
}

export interface SetDeleteSelectedVariantsPayload {
  variantIds: string[];
  callback: any;
}
export type SetFilteredVariants = {
  type: typeof SET_FILTERED_VARIANT_LIST;
  payload: any;
};

export type SetUnselectTotalVariants = {
  type: typeof UNSELECT_TOTAL_VARIANTS;
};

export type SetVariantsNewTag = {
  type: typeof SET_VARIANTS_NEW_TAGS;
  payload: { adId: string; isNew: boolean };
};
