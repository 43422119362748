import HttpService from "../../../HttpService/HttpService";
import { call, put } from "redux-saga/effects";
import {
  DELETE_YOUTUBE_COHORTS,
  GET_YOUTUBE_RECOMMENDED_COHORTS,
  UPLOAD_YOUTUBE_DATA,
} from "store/types/AudienceManager/youtube";
import { CROSS_PLATFORM_URL } from "utils/path";
import { takeEvery } from "@redux-saga/core/effects";
import {
  setRecommendedAudienceCohorts,
  setRecommendedChannelCohorts,
  setRecommendedVideosCohorts,
  setYoutubeLoading,
} from "../../actions/AudienceManager/youtube";

function* getRecommendedCohortsDetails(action: any): any {
  let {
    payload: { aiGroupId, type },
  } = action;
  yield put(setRecommendedChannelCohorts([]));
  yield put(setRecommendedVideosCohorts([]));
  yield put(setRecommendedAudienceCohorts([]));

  try {
    const urlParams = `/v1/api/targeting-ai-recommendation/youtube-audiences/ai-groups/${aiGroupId}/cohorts?type=${type.toUpperCase()}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);

    if (response.data.success) {
      if (type === "video") {
        yield put(setRecommendedVideosCohorts(response.data.data));
      } else if (type === "channel") {
        yield put(setRecommendedChannelCohorts(response.data.data));
      } else if (type === "audience") {
        yield put(setRecommendedAudienceCohorts(response.data.data));
      }
    }
    yield put(setYoutubeLoading(false));
  } catch (error: any) {
    yield put(setYoutubeLoading(false));
    console.log("getPublishedCohortsDetails ~ error", error);
  }
}

function* uploadCohorts(action: any): any {
  try {
    let {
      payload: { aiGroupId, user, file, callback },
    } = action;
    let data = new FormData();
    data.append("file", file);
    const urlParams = `/v1/api/targeting-ai-recommendation/youtube-audiences/ai-groups/${aiGroupId}/upload-cohorts`;
    const response = yield call(
      HttpService.post,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      data
    );
    if (response.data.success) {
      callback && callback({}, false);
    } else {
      callback && callback({}, true);
    }
  } catch (error) {
    console.log("LL: error", error);
  }
}

function* deleteYoutubeCohorts(action: any): any {
  let {
    payload: { cohortIds, aiGroupId, user },
    callback,
  } = action;
  try {
    const urlParams = `/v1/api/targeting-ai-recommendation/youtube-audiences/ai-groups/${aiGroupId}/cohorts?cohortIds=${cohortIds}`;
    const response = yield call(
      HttpService.delete,
      CROSS_PLATFORM_URL,
      urlParams,
      user
    );
    if (response.data.success) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

export function* AudienceManagerYoutubeWatcher() {
  yield takeEvery(
    GET_YOUTUBE_RECOMMENDED_COHORTS,
    getRecommendedCohortsDetails
  );
  yield takeEvery(UPLOAD_YOUTUBE_DATA, uploadCohorts);
  yield takeEvery(DELETE_YOUTUBE_COHORTS, deleteYoutubeCohorts);
}
