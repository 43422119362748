import React from "react";
import { ReactComponent as InfoIcon } from "../../assets/svg/infoIcon.svg";
import { Typography } from "@mui/material";

function HeadingClibrationsGS({
  heading,
  subHeading,
}: {
  heading: string;
  subHeading: string;
}) {
  return (
    <>
      <Typography variant="caption" sx={{ fontWeight: 700 }}>
        {heading}&nbsp;
        <InfoIcon />
      </Typography>
      <Typography
        sx={{ fontSize: "0.62rem", fontWeight: 400, color: "#767676" }}
      >
        {subHeading}
      </Typography>
    </>
  );
}

export default HeadingClibrationsGS;
