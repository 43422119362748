import React from 'react'
import { convertToKFormat } from "../utils/facebook";
import { Tooltip } from '@mui/material';
function CustomAudienceSection({selectedCustomAudience}) {
    return (
        <div className="d-block" style={{
            paddingLeft: 0,
            paddingTop: 0,
            marginTop: "-6px",
        }}>
            <div style={{
                color: '#5C6674',
                marginBottom: '-12px'
            }}>
                <div className="w-25 d-block px-2">Name</div>
                <div className="w-25 d-block px-2 ml-1">ID</div>
                <div className="w-25 d-block px-2" style={{
                    marginLeft: -10
                }}>Size</div>
                <div className="w-25 d-block pl-0" style={{
                    marginLeft: -5
                }}>Country</div>
            </div>
            <div className="d-block" style={{
                minHeight: "180px",
                maxHeight: "180px",
                overflowY: "scroll",
                padding:0
            }}>
                {selectedCustomAudience?.map((audience) => {
                    return (
                        <div className="m-2" style={{
                            border: '1px solid #ECEEF1',
                            borderRadius: 6,
                            padding:"0px 4px"
                        }} key={audience.id}>
                            <Tooltip
                                title={audience.name}
                                arrow
                                placement="bottom"
                            >
                                <div className="w-25 text-truncate d-block" title={audience.name}>{audience.name}</div>
                            </Tooltip>
                            <Tooltip
                                title={audience.id}
                                arrow
                                placement="bottom"
                            >
                                <div className="w-25 text-truncate d-block" title={audience.id}>{audience.id}</div>
                            </Tooltip>
                            <Tooltip title={audience.audience_size}
                                arrow
                                placement="bottom">
                                <div className="w-25 text-truncate d-block" title={audience.audience_size}>{convertToKFormat(audience.audience_size)}</div>
                            </Tooltip>

                            <div className="w-25 text-truncate d-block" title={audience.countries.length > 0 ? audience.countries.join(",") : ""}>{audience.countries.length > 0 ? audience.countries.join(","): <span className="pl-2">-</span>}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CustomAudienceSection
