import Box from "@mui/material/Box";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ReactComponent as SettingsIcon } from "../assets/settingsIcon.svg";
import IconButton from "@mui/material/IconButton";

function Configure({ value, label, isActive, isConfigurable, onClick }: any) {
  return (
    <>
      <Card
        sx={{
          display: "flex",
          backgroundColor: "#F6FAFF",
          borderRadius: "0.5rem",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 10px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.03)",
        }}
      >
        <Box sx={{ display: "flex", textAlign: "left" }}>
          <CardContent sx={{ flex: "1 0 auto", p: "0 !important" }}>
            <Typography
              variant="subtitle1"
              color={isActive ? "#24B632" : "#ED3900"}
              component="div"
            >
              {value}
            </Typography>
            <Typography variant="caption" color="#464646" component="div">
              {label}
            </Typography>
          </CardContent>
        </Box>
        {isConfigurable && (
          <IconButton
            disableRipple
            sx={{ display: "flex", ml: 2, p: 0 }}
            onClick={onClick}
          >
            <SettingsIcon />
          </IconButton>
        )}
      </Card>
    </>
  );
}

export default Configure;
