import { RESET_SUBOPTIMIZER_METRICS } from "../../types/SmbDashboard/selectedBrand";
import {
  RESET_ERROR_SELECTED_BRAND,
  SET_ERROR_SELECTED_BRAND,
} from "store/types/SmbDashboard/error";
import {
  SelectedBrandState,
  SetSubOptimizerMetrics,
  SetAllOptimizersDetails,
  SetSelectedBrand,
  ResetSelectedBrand,
  SET_SUBOPTIMIZER_METRICS,
  SET_ALL_OPTIMIZERS_DETAILS,
  SET_SELECTED_BRAND,
  RESET_SELECTED_BRAND,
} from "../../types/SmbDashboard/selectedBrand";

const initialSelectedBrandState: SelectedBrandState = {
  id: "",
  name: "",
  optimizers: { isLoading: true, data: null },
  error: null,
};

const actions: any = {
  [SET_ALL_OPTIMIZERS_DETAILS]: (state: any, payload: any) => ({
    ...state,
    optimizers: payload.optimizers,
    name: payload.name,
  }),

  [SET_SUBOPTIMIZER_METRICS]: (state: any, payload: any) => ({
    ...state,
    optimizers: {
      ...state.optimizers,
      [payload.optimizerId]: {
        ...state.optimizers[payload.optimizerId],
        subOptimizers: {
          ...state.optimizers[payload.optimizerId].subOptimizers,
          [payload.subOptimizerId]: {
            ...state.optimizers[payload.optimizerId].subOptimizers[
              payload.subOptimizerId
            ],
            metrics: payload.metrics,
          },
        },
      },
    },
  }),

  [SET_SELECTED_BRAND]: (state: any, payload: any) => ({
    ...state,
    id: payload.id,
    name: payload.name,
  }),

  [SET_ERROR_SELECTED_BRAND]: (state: any, payload: any) => ({
    ...state,
    error: payload,
  }),

  [RESET_ERROR_SELECTED_BRAND]: (state: any, payload: any) => ({
    ...state,
    error: payload,
  }),

  [RESET_ERROR_SELECTED_BRAND]: (state: any) => ({
    ...state,
    error: null,
  }),

  [RESET_SELECTED_BRAND]: () => ({
    ...initialSelectedBrandState,
  }),
  [RESET_SUBOPTIMIZER_METRICS]: (state: any) => ({
    ...state,
    optimizers: { isLoading: true, data: null },
  }),
};

export default function selectedBrandReducer(
  state = initialSelectedBrandState,
  action:
    | SetAllOptimizersDetails
    | SetSubOptimizerMetrics
    | SetSelectedBrand
    | ResetSelectedBrand
) {
  if (actions[action.type]) return actions[action.type](state, action.payload);
  return state;
}
