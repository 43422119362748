//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import ActionImpactHeader from "../components/ActionImpactHeader/ActionImpactHeader";
import ActionImpaceMetrics from "../components/ActionImpactMetrics/ActionImpaceMetrics";
import ActionImpactTable from "../components/ActionImpactTable/ActionImpactTable";
import Loader from "../components/common/Loader";
import NoData from "../components/common/NoData";
import {
  getCampaignMetrics,
  getGroupMetrics,
  getMetricsCumulative,
  getRecommendation,
  setChartCordsData,
  setChartData,
  setChartOption,
  setChartTabs,
  setMetricCumulative,
  setRecommendation,
  setSelectedOption,
  setSettingPostspinner,
  setSpinner,
  setAttributionMetric,
} from "../store/actions/campaign";
import { ActionImpactState } from "../store/types/actionImpact";
import { CampaignState } from "../store/types/campaign";
import {
  formatChartInfo,
  formatChartOptions,
  getCurrencySymbol,
  getPlatformName,
  getRandomColor,
} from "../utils/commonFunctions";
import { getAllowRejectRecommendation } from "../store/actions/auth";
import { initialState } from "../store/types/auth";
import {
  FACEBOOK_PLATFORM_NAME,
  TIKTOK_PLATFORM_NAME,
  DV360_PLATFORM_NAME,
} from "../utils/constants";
import "./actionImpact.scss";
import { AdAccountState } from "../store/types/adaccount";
import {
  getAdsetDetails,
  getCampaignDetails,
  setGroups,
  setGroupsLoading,
  setLoadCampaignsData,
  setSelectedGroupAdset,
  setSelectedGroupCampaign,
} from "../store/actions/adaccount";
import { useHistory } from "react-router-dom";
import { setLinkClicks } from "../store/actions/actionImpact";
import { commonOptions } from "../utils/constants/actionImpact";
import { mixPanelAdEvent } from "../utils/mixpanel";
import WarningMessage from "./TokenManagementSystem/WarningMessage";
import { allowedPlatforms } from "utils/constants/tokenManagement";
import ActionDebugger from "./ActionDebugger/ActionDebugger";
import {
  setAdSet,
  setAdSetLoading,
  setCampaignDebuggerLoading,
} from "../store/actions/ActionDebugger/ActionDebuggerHelper";
import { Accordion } from "react-bootstrap";
import { Box } from "@mui/material";
import {
  getAttributionWindowType,
  getElementAttributionWindow,
} from "../store/actions/ActionDebugger/ActionDebugger";
import { ActionDebuggerInitStateType } from "store/types/ActionDebugger/ActionDebugger";
import { CommonState } from "store/types/common";
import DisableDashboard from "components/DisableDashboard/DisableDashboard";

const ActionImpactAnalysis: React.FC<unknown> = () => {
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { isDisableDashboard } = common;
  const {
    selectedAdSet,
    selectedBrand,
    selectedPlatform,
    selectedAdAccount,
    selectedGroup,
    groups,
    selectedCampaign,
    loadCampaignsData
  } = adaccount;
  const campaign = useSelector(
    (state: { campaign: CampaignState }) => state.campaign
  );
  const {
    chartTabs,
    chartData,
    chartOption,
    spinner,
    selectedOption,
    chartCordsData,
    isAttribuationMetrics,
  } = campaign;
  const actionImpact = useSelector(
    (state: { actionImpact: ActionImpactState }) => state.actionImpact
  );
  const { linkClicks } = actionImpact;
  const { user, ...authState } = useSelector(
    (state: { auth: initialState }) => state.auth
  );
  const dispatch = useDispatch();
  const { attributionWindow } = useSelector(
    (state: { ActionDebuggerReducer: ActionDebuggerInitStateType }) =>
      state.ActionDebuggerReducer
  );
  const [open, setOpen] = useState(isDisableDashboard);

  useEffect(() => {
    if (selectedBrand?.name) {
      mixPanelAdEvent("Page View - Dashboard", {
        module_name: "Action Impact Analysis - Action Debugger",
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        agency_name: selectedBrand?.agency_name,
        agency_id: selectedBrand?.agencyId,
      });
    }
  }, [selectedBrand]);

  const getAnalysisData = () => {
    dispatch(
      getMetricsCumulative(
        {
          elementId: selectedAdAccount.elementId,
          dates: linkClicks,
          user,
          selectedAdSet:
            selectedPlatform?.name &&
            (selectedPlatform?.name === FACEBOOK_PLATFORM_NAME ||
              selectedPlatform?.name === TIKTOK_PLATFORM_NAME ||
              selectedPlatform?.name === DV360_PLATFORM_NAME)
              ? selectedAdSet
              : [],
          selectedCampaign:
            selectedPlatform?.name &&
            selectedPlatform?.name !== FACEBOOK_PLATFORM_NAME
              ? selectedCampaign
              : [],
          attributionWindow:
            selectedPlatform?.name &&
            selectedPlatform?.name === FACEBOOK_PLATFORM_NAME
              ? attributionWindow
              : "",
        },
        (response: any, error: boolean) => {
          if (!error) {
            dispatch(
              setMetricCumulative(
                selectedPlatform?.name &&
                  selectedPlatform?.name?.toLowerCase().includes("facebook")
                  ? response.data.metrics[0]
                  : response.data.metrics
              )
            );
            dispatch(setAttributionMetric(false));
          } else {
            dispatch(setMetricCumulative([]));
            console.log(error);
            dispatch(setAttributionMetric(false));
          }
        }
      )
    );
    dispatch(setSpinner(true));
    dispatch(
      getCampaignMetrics(
        {
          elementId: selectedAdAccount.elementId,
          dates: linkClicks,
          user,
          selectedAdSet:
            (selectedPlatform?.name &&
              selectedPlatform?.name === FACEBOOK_PLATFORM_NAME) ||
            selectedPlatform?.name === TIKTOK_PLATFORM_NAME ||
            selectedPlatform?.name === DV360_PLATFORM_NAME
              ? selectedAdSet
              : [],
          selectedCampaign:
            selectedPlatform?.name &&
            selectedPlatform?.name !== FACEBOOK_PLATFORM_NAME
              ? selectedCampaign
              : [],
          attributionWindow:
            selectedPlatform?.name &&
            selectedPlatform?.name === FACEBOOK_PLATFORM_NAME
              ? attributionWindow
              : "",
        },
        (response: any, error: boolean) => {
          dispatch(setSpinner(false));
          dispatch(setLoadCampaignsData(false))
          if (!error) {
            if (
              response.data.metrics &&
              Object.keys(response.data.metrics).length > 0
            ) {
              dispatch(
                setChartData(
                  formatChartInfo(
                    Object.keys(response.data.metrics).map((key) => ({
                      "Date Stop": key,
                      ...response.data.metrics[key],
                    })),
                    linkClicks
                  )
                )
              );
              dispatch(
                setChartOption(
                  formatChartOptions(
                    Object.keys(response.data.metrics).map((key) => ({
                      "Date Stop": key,
                      ...response.data.metrics[key],
                    }))
                  )
                )
              );
            } else {
              dispatch(setChartData(formatChartInfo(response.data.metrics[0])));
              dispatch(
                setChartOption(formatChartOptions(response.data.metrics[0]))
              );
            }
          } else {
            dispatch(setSpinner(false));
            console.log(error);
          }
        }
      )
    );
  };

  useEffect(() => {
    dispatch(setChartData({}));
    dispatch(setChartOption([]));
    dispatch(setSelectedOption([]));
    if (
      linkClicks &&
      selectedBrand &&
      selectedAdAccount &&
      selectedAdAccount.elementId &&
      ((selectedAdSet.length > 0 && attributionWindow !== "") ||
        selectedCampaign.length > 0)
    ) {
      getAnalysisData();
    } else {
      dispatch(setMetricCumulative([]));
      dispatch(setChartData({}));
      dispatch(setChartOption([]));
    }
  }, [linkClicks, selectedAdSet, attributionWindow]);

  useEffect(() => {
    dispatch(setChartData({}));
    dispatch(setChartOption([]));
    dispatch(setSelectedOption([]));
    if (
      linkClicks &&
      selectedBrand &&
      selectedAdAccount &&
      selectedAdAccount.elementId &&
      ((selectedAdSet.length > 0 && attributionWindow !== "") ||
        selectedCampaign.length > 0) && loadCampaignsData
    ) {
      getAnalysisData();
    } else {
      dispatch(setMetricCumulative([]));
      dispatch(setChartData({}));
      dispatch(setChartOption([]));
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedAdAccount?.elementId && isAttribuationMetrics) {
      getAnalysisData();
    }
  }, [isAttribuationMetrics]);

  useEffect(() => {
    if (selectedAdAccount?.elementId) {
      dispatch(
        getGroupMetrics(
          { elementId: selectedAdAccount.elementId, user },
          (response: any, error: boolean) => {
            if (!error) {
              const data = response.data.map((res: any) => ({
                ...res,
                isSelected: res.label === "Spends" ? true : res.uiVisible,
                uiVisible: res.label === "Spends" ? true : res.uiVisible,
                color: getRandomColor(),
              }));
              dispatch(setChartTabs(data));
            } else {
              dispatch(setChartTabs([]));
              console.log(error);
            }
          }
        )
      );
      dispatch(
        getAttributionWindowType({
          elementId: selectedAdAccount.elementId,
        })
      );
      dispatch(
        getElementAttributionWindow({
          elementId: selectedAdAccount.elementId,
        })
      );
    } else {
      dispatch(setChartTabs([]));
    }
  }, [selectedAdAccount]);

  useEffect(() => {
    dispatch(getAllowRejectRecommendation());
  }, []);

  useEffect(() => {
    if (chartData && chartTabs) {
      const selectMetric: [] = chartTabs.reduce(
        (selected, { isSelected, label }) => {
          if (isSelected) {
            selected.push(label);
          }
          return selected;
        },
        []
      );
      let datasets = chartData?.datasets?.map((d: any) => {
        const metric = chartTabs.find((tab: any) => tab.label === d.label);
        // Currency is used in tooltip (currency is added to tooltip from constant file -> commonOptions -> tooltips)
        const currency =
          metric?.showCurrency && authState?.accountDetails?.currency
            ? getCurrencySymbol(authState?.accountDetails?.currency)
            : "";
        const data =
          selectedPlatform?.name &&
          selectedPlatform?.name?.toLowerCase().includes("google")
            ? metric?.showCurrency
              ? d.data.map((d: any) => d / 1_000_000)
              : metric?.filter === "interaction_rate"
              ? d.data.map((d: any) => d * 100)
              : metric?.metricType === "PERCENTAGE"
              ? d.data.map((d: any) => d * 100)
              : d.data
            : d.data;
        return {
          ...d,
          data,
          backgroundColor: metric?.color,
          borderColor: metric?.color,
          currency,
          hidden: !selectMetric.includes(d.label),
        };
      });
      dispatch(setChartCordsData({ ...chartData, datasets }));
    } else {
      dispatch(setChartCordsData({ ...chartData, datasets: [] }));
    }
  }, [chartData, chartTabs]);

  useEffect(() => {
    if (chartOption && chartTabs) {
      const selectMetric: [] = chartTabs.reduce(
        (selected, { isSelected, label }) => {
          if (isSelected) {
            selected.push(label);
          }
          return selected;
        },
        []
      );
      let selectedOptions = chartOption?.filter((o: any) =>
        selectMetric.includes(o.id)
      );
      let options = chartOption?.map((o: any, index: number) => ({
        ...o,
        position: selectedOptions.length === 1 ? "left" : "right",
        display:
          selectedOptions.length === 1
            ? selectedOptions[0].id === o.id
            : index === 0,
        ticks: {
          display:
            selectedOptions.length === 1
              ? selectedOptions[0].id === o.id
              : false,
          beginAtZero: true,
        },
        gridLines: {
          display: selectedOptions.length === 1 ? true : index === 0,
        },
      }));
      dispatch(
        setSelectedOption({
          ...commonOptions,
          maintainAspectRatio: false,
          scales: {
            yAxes: options,
            xAxes: [
              {
                type: "time",
                time: {
                  unit: "day",
                  unitStepSize: 1,
                  displayFormats: {
                    day: "MMM DD",
                  },
                },
              },
            ],
          },
        })
      );
    }
  }, [chartOption, chartTabs]);

  useEffect(() => {
    if (
      selectedPlatform &&
      selectedPlatform.id &&
      selectedGroup?.id &&
      selectedAdAccount?.id
    ) {
      dispatch(setSelectedGroupCampaign([]));
      dispatch(setCampaignDebuggerLoading(true));
      dispatch(
        getCampaignDetails(
          { group_id: selectedGroup.id, user: user },
          (campRes: any, campErr: boolean) => {
            if (!campErr && campRes.data.length > 0) {
              let filteredData: any = campRes.data.filter(
                (campaign: any) =>
                  campaign.platformId === selectedPlatform.id &&
                  campaign.adAccountId === selectedAdAccount.id
              );
              dispatch(setSelectedGroupCampaign(filteredData));
            }
            dispatch(setCampaignDebuggerLoading(false));
          }
        )
      );
    }
  }, [selectedAdAccount]);

  useEffect(() => {
    if (selectedGroup && selectedAdAccount.id && groups.length > 0) {
      if (selectedGroup?.id) {
        dispatch(setAdSetLoading(true));
        dispatch(
          getAdsetDetails(
            { group_id: selectedGroup.id, user: user },
            (adsetRes: any, adsetErr: boolean) => {
              if (!adsetErr && adsetRes.data.length > 0) {
                let newAdSets = adsetRes.data.filter(
                  (adset: any) => adset.adAccountId === selectedAdAccount.id
                );
                dispatch(setSelectedGroupAdset(newAdSets));
                dispatch(setAdSetLoading(false));
              } else {
                console.log(adsetErr);
                dispatch(setAdSetLoading(false));
              }
            }
          )
        );
      }
    }
  }, [selectedAdAccount]);

  const { switchStatus } = useSelector((state: any) => ({
    switchStatus: state.ActionDebuggerReducer.actionDebuggerSwitch,
  }));

  return (
    <>
      {selectedPlatform?.name &&
        allowedPlatforms.includes(getPlatformName(selectedPlatform?.name)) && (
          <WarningMessage type="single" adAccountId={selectedAdAccount?.id} />
        )}
      {!switchStatus ? (
        <ActionDebugger />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            overflow: "hidden",
            height: "calc(100vh - 70px)",
          }}
        >
          <div className="performance_analysis">
            <Accordion
              key={"performance_analysis"}
              style={{
                boxShadow: "0px 0px 2px rgb(30 21 83 / 20%)",
                borderRadius: "4px",
                margin: "1.4em 1em",
              }}
              id={1}
            >
              <>
                <ActionImpactHeader
                  title="Performance Analysis"
                  localStorageKey="graph-date"
                  linkClicks={linkClicks}
                  setLinkClicks={setLinkClicks}
                />
                <Accordion.Collapse eventKey={"performance_analysis"}>
                  <>
                    {!spinner && <ActionImpaceMetrics />}
                    <div className="chartbox">
                      {spinner && <Loader />}
                      {!spinner &&
                        chartCordsData &&
                        chartData?.datasets?.length > 0 &&
                        chartCordsData?.datasets?.length > 0 &&
                        chartTabs.length > 0 && (
                          <div className="chartbox-container">
                            <div className="ml-4 mt-2"></div>
                            {chartCordsData?.datasets?.length &&
                            selectedOption?.scales?.yAxes?.length &&
                            chartCordsData?.datasets?.length ===
                              selectedOption?.scales?.yAxes?.length ? (
                              <Line
                                data={chartCordsData}
                                options={selectedOption}
                              />
                            ) : null}
                          </div>
                        )}
                      {!spinner && !chartData?.datasets?.length > 0 && (
                        <NoData />
                      )}
                    </div>
                  </>
                </Accordion.Collapse>
              </>
            </Accordion>
          </div>
          <ActionImpactTable />
        </Box>
      )}
      {selectedBrand?.isDisableDashboard && (
        <DisableDashboard open={true} setOpen={setOpen} />
      )}
    </>
  );
};

export default ActionImpactAnalysis;
