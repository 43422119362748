export const getAdSetList = {
  success: true,
  message: "Get adset successfully.",
  data: {
    adsets: [
      {
        adset: {
          id: "67ddbf7c-96b0-4e0e-b457-2ec0051bd744",
          adsetId: "23849380190380171",
          name: "Adset1-CBO/Link-Clicks",
        },
        facebookData: {
          name: "Adset1-CBO/Link-Clicks",
          targeting: {
            age_max: 65,
            age_min: 18,
            geo_locations: {
              countries: ["IN"],
              location_types: ["home", "recent"],
            },
            targeting_optimization: "none",
            brand_safety_content_filter_levels: [
              "FACEBOOK_STANDARD",
              "AN_STANDARD",
            ],
          },
          id: "23849380190380171",
        },
        publishedVariants: 1,
      },
      {
        adset: {
          id: "c7c0ae2e-3edf-4b08-b932-d6512b1f0c5e",
          adsetId: "23849380190370171",
          name: "Adset4-ABO/Link-Clicks",
        },
        facebookData: {
          name: "Adset4-ABO/Link-Clicks",
          targeting: {
            age_max: 65,
            age_min: 18,
            geo_locations: {
              countries: ["IN"],
              location_types: ["home", "recent"],
            },
            targeting_optimization: "none",
            brand_safety_content_filter_levels: [
              "FACEBOOK_STANDARD",
              "AN_STANDARD",
            ],
          },
          daily_budget: "7600",
          id: "23849380190370171",
        },
        publishedVariants: 0,
      },
      {
        adset: {
          id: "fb80c29d-f10d-4e88-872d-db1f5bd9081a",
          adsetId: "23849380190360171",
          name: "Adset4-ABO/Reach",
        },
        facebookData: {
          name: "Adset4-ABO/Reach",
          targeting: {
            age_max: 65,
            age_min: 18,
            geo_locations: {
              countries: ["IN"],
              location_types: ["home", "recent"],
            },
            brand_safety_content_filter_levels: [
              "FACEBOOK_STANDARD",
              "AN_STANDARD",
            ],
          },
          daily_budget: "7959",
          id: "23849380190360171",
        },
        publishedVariants: 0,
      },
    ],
    totalCount: 3,
  },
};

export const getAdList = {
  success: true,
  message: "Get ads successfully.",
  data: {
    data: [
      {
        id: "23849380190490171",
        name: "Ad3-CBO/Link-Clicks",
        creative: {
          id: "23849597491590171",
        },
        publishedVariants: 1,
        draftVariants: 2,
        image_url:
          "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
        preview_ads: [
          {
            id: 1,
            preview_name: "Main Creative",
            name: "Ad3-CBO/Link-Clicks",
            body: "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
          },
          {
            id: 2,
            preview_name: "Variant # 2",
            name: "Ad3-CBO/Link-Clicks",
            body: "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
          },
          {
            id: 3,
            preview_name: "Variant # 3",
            name: "Ad3-CBO/Link-Clicks",
            body: "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
          },
          {
            id: 4,
            preview_name: "Variant # 3",
            name: "Ad3-CBO/Link-Clicks",
            body: "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
          },
        ],
      },
      {
        id: "23849380190490172",
        name: "Ad3-CBO/Link-Clicks",
        creative: {
          id: "23849597491590171",
        },
        publishedVariants: 5,
        draftVariants: 2,
        image_url:
          "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
      },
      {
        id: "23849380190490173",
        name: "Ad3-CBO/Link-Clicks",
        creative: {
          id: "23849597491590171",
        },
        publishedVariants: 4,
        draftVariants: 3,
        image_url:
          "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
        preview_ads: [
          {
            id: 1,
            preview_name: "Main Creative",
            name: "Ad3-CBO/Link-Clicks",
            body: "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
          },
        ],
      },
      {
        id: "23849380190490174",
        name: "Ad3-CBO/Link-Clicks",
        creative: {
          id: "23849597491590171",
        },
        publishedVariants: 3,
        draftVariants: 5,
        image_url:
          "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
      },
      {
        id: "23849380190490175",
        name: "Ad3-CBO/Link-Clicks",
        creative: {
          id: "23849597491590171",
        },
        publishedVariants: 4,
        draftVariants: 2,
        image_url:
          "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
        preview_ads: [
          {
            id: 1,
            preview_name: "Main Creative",
            name: "Ad3-CBO/Link-Clicks",
            body: "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
          },
          {
            id: 2,
            preview_name: "Variant # 2",
            name: "Ad3-CBO/Link-Clicks",
            body: "https://scontent.fdel1-6.fna.fbcdn.net/v/t45.1600-4/272520571_23849450977870522_8476949598872985389_n.png?_nc_cat=1&ccb=1-7&_nc_sid=2aac32&_nc_ohc=bE1HkvEC_k0AX99DT5C&_nc_ht=scontent.fdel1-6.fna&edm=AAT1rw8EAAAA&oh=00_AT_ma-hhqFNDdSfFDxJxZdabNDn3NMOxCA5PF6JhHM7Bng&oe=62927252",
          },
        ],
      },
    ],
    paging: {
      cursors: {
        before:
          "QVFIUjJ5MkxhdnpkOGl1c2VGZAHVQdVdfTGJQUzBQTXFhc2ZAzajN1YUZAUZAVNZAV29MQUkwMWRvSnJILUZAJRl9yWUxyVjNPNWZApQXJDdy1MazFWTF9kS3Nqb0ZAB",
        after:
          "QVFIUjJ5MkxhdnpkOGl1c2VGZAHVQdVdfTGJQUzBQTXFhc2ZAzajN1YUZAUZAVNZAV29MQUkwMWRvSnJILUZAJRl9yWUxyVjNPNWZApQXJDdy1MazFWTF9kS3Nqb0ZAB",
      },
    },
  },
};

export const businessUnitList = {
  success: true,
  data: {
    count: 1,
    rows: [
      {
        id: "d2b10db7-654b-40e5-b7b8-39aeecce173d",
        creativeAiId: "b846955e-58a7-4a74-b09d-a360cb20f5fe",
        brandName: "Krishan",
        businessUnit: "mg",
        businessUnitDetail: "router",
        interestKeyword: "same",
        status: "readyToUse",
        createdAt: "2022-05-10T11:55:17.956Z",
        updatedAt: "2022-05-18T05:59:58.501Z",
        primaryText: {
          name: "router",
        },
        headline: {},
        description: {},
      },
      {
        id: "d2b10dbw7-654b-40e5-b7b8-39aeecce173d",
        creativeAiId: "b846955e-58a7-4a74-b09d-a360cb20f5fe",
        brandName: "Krishan",
        businessUnit: "mg",
        businessUnitDetail: "router",
        interestKeyword: "same",
        status: "readyToUse",
        createdAt: "2022-05-10T11:55:17.956Z",
        updatedAt: "2022-05-18T05:59:58.501Z",
        primaryText: {
          name: "router",
        },
        headline: {},
        description: {},
      },
      {
        id: "d2b10gdb7-654b-40e5-b7b8-39aeecce173d",
        creativeAiId: "b846955e-58a7-4a74-b09d-a360cb20f5fe",
        brandName: "Krishan",
        businessUnit: "mg",
        businessUnitDetail: "router",
        interestKeyword: "same",
        status: "readyToUse",
        createdAt: "2022-05-10T11:55:17.956Z",
        updatedAt: "2022-05-18T05:59:58.501Z",
        primaryText: {
          name: "router",
        },
        headline: {},
        description: {},
      },
      {
        id: "d2bs10db7-654b-40e5-b7b8-39aeecce173d",
        creativeAiId: "b846955e-58a7-4a74-b09d-a360cb20f5fe",
        brandName: "Krishan",
        businessUnit: "mg",
        businessUnitDetail: "router",
        interestKeyword: "same",
        status: "readyToUse",
        createdAt: "2022-05-10T11:55:17.956Z",
        updatedAt: "2022-05-18T05:59:58.501Z",
        primaryText: {
          name: "router",
        },
        headline: {},
        description: {},
      },
      {
        id: "d2b10dfb7-654b-40e5-b7b8-39aeecce173d",
        creativeAiId: "b846955e-58a7-4a74-b09d-a360cb20f5fe",
        brandName: "Krishan",
        businessUnit: "mg",
        businessUnitDetail: "router",
        interestKeyword: "same",
        status: "readyToUse",
        createdAt: "2022-05-10T11:55:17.956Z",
        updatedAt: "2022-05-18T05:59:58.501Z",
        primaryText: {
          name: "router",
        },
        headline: {},
        description: {},
      },
      {
        id: "ds2b10db7-654b-40e5-b7b8-39aeecce173d",
        creativeAiId: "b846955e-58a7-4a74-b09d-a360cb20f5fe",
        brandName: "Krishan",
        businessUnit: "mg",
        businessUnitDetail: "router",
        interestKeyword: "same",
        status: "readyToUse",
        createdAt: "2022-05-10T11:55:17.956Z",
        updatedAt: "2022-05-18T05:59:58.501Z",
        primaryText: {
          name: "router",
        },
        headline: {},
        description: {},
      },
    ],
  },
};

export const variantsLogs = {
  success: true,
  data: {
    success: true,
    data: {
      count: 6,
      rows: [
        {
          id: "559e4a7e-0e69-44be-9a4b-965f70cdab44",
          executionId: "345345",
          variantId: "2c0a20f1-b212-44e3-8780-3bdbfba31390",
          status: "processing",
          publishedAt: "2022-05-25T06:28:44.651Z",
          data: null,
          createdAt: "2022-05-25T06:28:44.652Z",
          updatedAt: "2022-05-25T06:28:44.652Z",
        },
        {
          id: "651688e2-9d1f-49e0-a44c-c0de8cea94ca",
          executionId: "345345",
          variantId: "4b0c2ca1-2ff5-4f52-9857-4514ca3b9f7b",
          status: "processing",
          publishedAt: "2022-05-20T13:42:22.356Z",
          data: null,
          createdAt: "2022-05-20T13:42:22.357Z",
          updatedAt: "2022-05-20T13:42:22.357Z",
        },
        {
          id: "6cdc4514-cd45-4d6e-b908-2a928f38fb75",
          executionId: "345345",
          variantId: "b2475534-c394-4cd4-8ac8-bf5412159287",
          status: "processing",
          publishedAt: "2022-05-25T06:32:15.726Z",
          data: null,
          createdAt: "2022-05-25T06:32:15.727Z",
          updatedAt: "2022-05-25T06:32:15.727Z",
        },
        {
          id: "7988c0f1-604b-4189-ae8d-a59ece272071",
          executionId: "345345",
          variantId: "4b0c2ca1-2ff5-4f52-9857-4514ca3b9f7b",
          status: null,
          publishedAt: null,
          data: {
            name: "jeevan",
          },
          createdAt: "2022-05-16T09:43:56.781Z",
          updatedAt: "2022-05-16T09:46:10.488Z",
        },
        {
          id: "8200288f-4c86-4c3a-a46d-d6495e84010a",
          executionId: "345345",
          variantId: "4b0c2ca1-2ff5-4f52-9857-4514ca3b9f7b",
          status: null,
          publishedAt: null,
          data: {
            name: "jeevan",
          },
          createdAt: "2022-05-16T09:43:11.184Z",
          updatedAt: "2022-05-16T09:46:10.488Z",
        },
        {
          id: "d02438c9-7265-435e-8716-96d212961a55",
          executionId: "345345",
          variantId: "4b0c2ca1-2ff5-4f52-9857-4514ca3b9f7b",
          status: null,
          publishedAt: null,
          data: {
            name: "jeevan",
          },
          createdAt: "2022-05-16T09:42:34.347Z",
          updatedAt: "2022-05-16T09:46:10.488Z",
        },
      ],
    },
  },
};

export const creativeVariantsLogsDummy = {
  success: true,
  data: {
    count: 6,
    rows: [
      {
        id: "559e4a7e-0e69-44be-9a4b-965f70cdab44",
        executionId: "345345",
        variantId: "2c0a20f1-b212-44e3-8780-3bdbfba31390",
        status: "processing",
        publishedAt: "2022-05-25T06:28:44.651Z",
        data: null,
        createdAt: "2022-05-25T06:28:44.652Z",
        updatedAt: "2022-05-25T06:28:44.652Z",
      },
      {
        id: "651688e2-9d1f-49e0-a44c-c0de8cea94ca",
        executionId: "345345",
        variantId: "4b0c2ca1-2ff5-4f52-9857-4514ca3b9f7b",
        status: "processing",
        publishedAt: "2022-05-20T13:42:22.356Z",
        data: null,
        createdAt: "2022-05-20T13:42:22.357Z",
        updatedAt: "2022-05-20T13:42:22.357Z",
      },
      {
        id: "6cdc4514-cd45-4d6e-b908-2a928f38fb75",
        executionId: "345345",
        variantId: "b2475534-c394-4cd4-8ac8-bf5412159287",
        status: "processing",
        publishedAt: "2022-05-25T06:32:15.726Z",
        data: null,
        createdAt: "2022-05-25T06:32:15.727Z",
        updatedAt: "2022-05-25T06:32:15.727Z",
      },
      {
        id: "7988c0f1-604b-4189-ae8d-a59ece272071",
        executionId: "345345",
        variantId: "4b0c2ca1-2ff5-4f52-9857-4514ca3b9f7b",
        status: null,
        publishedAt: null,
        data: {
          name: "jeevan",
        },
        createdAt: "2022-05-16T09:43:56.781Z",
        updatedAt: "2022-05-16T09:46:10.488Z",
      },
      {
        id: "8200288f-4c86-4c3a-a46d-d6495e84010a",
        executionId: "345345",
        variantId: "4b0c2ca1-2ff5-4f52-9857-4514ca3b9f7b",
        status: null,
        publishedAt: null,
        data: {
          name: "jeevan",
        },
        createdAt: "2022-05-16T09:43:11.184Z",
        updatedAt: "2022-05-16T09:46:10.488Z",
      },
      {
        id: "d02438c9-7265-435e-8716-96d212961a55",
        executionId: "345345",
        variantId: "4b0c2ca1-2ff5-4f52-9857-4514ca3b9f7b",
        status: null,
        publishedAt: null,
        data: {
          name: "jeevan",
        },
        createdAt: "2022-05-16T09:42:34.347Z",
        updatedAt: "2022-05-16T09:46:10.488Z",
      },
    ],
  },
};

export const creativeRotationsLogsDummy = {
  error: false,
  data: {
    pages: 1,
    page_size: "2",
    current_page: "1",
    count: 2,
    results: [
      {
        action: "START_AD",
        action_basis: "",
        creative_phase: "Learning",
        ad_creative_performance_score: null,
        ad_name: "Ad4-ABO/Link-Clicks",
        adset_name: "Adset3-ABO/Link-Clicks",
        datetime: "2022-02-10 21:21:09",
        status: "WAITING",
        story_id: 1000000,
        ad_id: "23849380190480171",
      },
      {
        action: "START_AD",
        action_basis: "",
        creative_phase: "Learning",
        ad_creative_performance_score: null,
        ad_name: "duplicate Ad4-ABO/Link-Clicks",
        adset_name: "Adset3-ABO/Link-Clicks",
        datetime: "2022-02-10 21:21:09",
        status: "WAITING",
        story_id: 1000001,
        ad_id: "23849380190480171",
      },
    ],
  },
};
export const MONTHS = [
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];

export const MONTHS_MAPPER: any = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};
