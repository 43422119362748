import React, { useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import Toggle from "react-toggle";
import HeadingClibrationsGS from "./HeadingClibrationsGS";
import { useSelector, useDispatch } from "react-redux";
import { setGSCalibrationData } from "store/actions/gsTargetingAI";

function SourcesGS() {
  const dispatch = useDispatch();
  const { ExploreValueCheck, GoogleKwsPlannerCheck } = useSelector(
    (state: any) => state.gsTargetingAI.gsCalibration.data
  );
  return (
    <Box
      className="node-container-common-style"
      sx={{
        opacity: `${!ExploreValueCheck && "0.5"}`,
        position: "absolute",
        top: "41%",
        right: "3%",
        width: "21rem",
        height: "8.6rem",
        px: 2,
      }}
    >
      <Box sx={{ pt: 1 }}>
        <HeadingClibrationsGS
          heading="Sources"
          subHeading="Generate keywords from desired sources"
        />
      </Box>
      <hr style={{ margin: "0.5rem 0" }} />
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ py: 1 }}
        >
          <Typography variant="caption">Google Keywords Planner</Typography>
          <Toggle
            disabled={!ExploreValueCheck}
            checked={GoogleKwsPlannerCheck}
            className="right-0 custom-react-toggle"
            icons={false}
            id="biscuit-status"
            aria-labelledby="biscuit-label"
            onChange={(e) => {
              dispatch(
                setGSCalibrationData({
                  GoogleKwsPlannerCheck: !GoogleKwsPlannerCheck,
                })
              );
            }}
          />
        </Stack>
        <Typography
          variant="caption"
          sx={{ fontWeight: 400, color: "#767676" }}
        >
          3rd Party Sources <i> (Coming soon)</i>
        </Typography>
      </Box>
    </Box>
  );
}

export default SourcesGS;
